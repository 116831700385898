import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {SpinnerState} from '../../behavior/spinner-state.enum';
import {UserModel} from '../../models/user.model';
import {UsersService} from '../../../users/services/users.service';
import {DriversService} from '../../services/drivers.service';
import {HubsService} from '../../services/hubs.service';
import {USERS_CONSTANTS} from '../../../users/services/constants';
import {HubTypes} from '../../services/shared_constants/enums';
import {TranslateService} from '@ngx-translate/core';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {PackageModel} from '../../models/package.model';

@Component({
    selector: 'app-users-list-dropdown-component',
    templateUrl: 'users-list-dropdown.component.html',
    styleUrls: ['users-list-dropdown.component.scss']
})
export class UserListDropdownComponent implements OnInit, OnChanges {
    public isSubmitting = false;
    public searchContent = '';
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;
    public users: UserModel[];
    public package: PackageModel[];
    private PAGE_SIZE = 20;
    @Input() innerPlaceHolder = 'ADMINISTRATION.CUSTOMER_LIST_DROPDOWN_PLACEHOLDER';
    @Input() selectedUser;
    @Input() placeHolder: string;
    @Input() isDriver = false;
    @Input() isAsnDriver = false;
    @Input() isHub = false;
    @Input() isOnShelf = false;
    @Input() isGetAllHubs = false;
    @Input() isPartner = false;
    @Input() isCodMismatchCustomer = false;
    @Input() showResetButton = false;
    @Input() panelWidth = '250px';
    @Input() user = null;
    @Input() inputStyleClass = '';
    @Input() appendTo = '';
    @Input() isDisabled = false;
    @Input() getDriverById = true;
    @Input() hubType: HubTypes;
    @Input() hubId: number;
    @Input() excludeOwnHub = false;
    @Input() isThirdParty = false;
    @Input() notOnlyTypical = false;
    @Output() userChanged = new EventEmitter();
    currentLang;
    @Input() hideDropdownWhenSelected = false;
    @Input() label: string;
    @Input() showLabel = false;
    @Input() isFilter = false;
    @Input() isForm = false;
    @Input() showOldAutoComplete = false ;
    @Input() customizeDefaultValue = false;

    constructor(private usersService: UsersService,
                private driversList: DriversService,
                public translateService: TranslateService,
                private hubsService: HubsService,
                private fulfilmentService: FulfilmentService
    ) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        if (this.isDriver) {
            this.innerPlaceHolder = '';
            this.getDrivers('');
        } else if (this.isOnShelf) {
            this.innerPlaceHolder = 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY';
            this.getShelves('', this.hubId);
        } else {
            if (this.isHub) {
                this.innerPlaceHolder = 'ADMINISTRATION.HUB_LIST_DROPDOWN_PLACEHOLDER';
                this.getHubs();
            } else {
                if (this.isPartner) {
                    this.innerPlaceHolder = 'PARTNER.PARTNER_LIST_DROPDOWN_PLACEHOLDER';
                    this.getPartners();
                } else {
                    this.PAGE_SIZE = 50;
                    // this.getCustomers();
                }
            }
        }
        if (this.placeHolder) {
            this.innerPlaceHolder = this.placeHolder;
        }

    }


    search($event) {
        if (this.isDriver) {
            if (this.isThirdParty) {
                this.innerPlaceHolder = 'ADMINISTRATION.THIRD_PARTY_LIST_DROPDOWN_PLACEHOLDER';
            } else {
                this.innerPlaceHolder = 'ADMINISTRATION.DRIVER_LIST_DROPDOWN_PLACEHOLDER';
            }
            this.getDrivers($event.query);
        } else if (this.isOnShelf) {
            this.innerPlaceHolder = 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY';
            this.getShelves($event.query, this.hubId);
        } else {
            if (this.isHub) {
                this.innerPlaceHolder = 'ADMINISTRATION.HUB_LIST_DROPDOWN_PLACEHOLDER';
                this.getHubs($event.query);
            } else {
                if (this.isPartner) {
                    this.innerPlaceHolder = 'PARTNER.PARTNER_LIST_DROPDOWN_PLACEHOLDER';
                    this.getPartners($event.query);
                } else {
                    this.getCustomers($event.query, this.hubId);
                }
            }
        }
    }

    getCustomers(search?: string, hubId?: number) {
        this.spinnerState = SpinnerState.LOADING;
        let query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        if (hubId) {
            query += '&hubId=' + hubId;
        }
        (this.isCodMismatchCustomer ?
            this.usersService.getCustomersDropDown(query, USERS_CONSTANTS.GET_COD_MISMATCH_CUSTOMERS_DROP_DOWN) :
            this.usersService.getCustomersDropDown(query))
            .subscribe(
                (response: any) => {
                    this.users = response.map(user => {
                        return this.getCustomerObject(user);
                    });
                    if (this.users && this.users.length) {
                        this.spinnerState = SpinnerState.LOADED;
                    } else {
                        this.spinnerState = SpinnerState.EMPTY;
                    }
                }, (error) => {
                    this.spinnerState = SpinnerState.FAILED;
                }
            );

    }

    public getCustomerObject(user) {
        return this.isCodMismatchCustomer ? {label: user.name, value: user.id} : {
            label: user.firstName + (user.middleName ? (' ' + user.middleName + ' ') : ' ') + user.lastName +
                (user.businessName ? (' (' + user.businessName + ')') : ''),
            value: user.id,
        };
    }

    getDrivers(search?: string) {
        this.spinnerState = SpinnerState.LOADING;
        let query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        if (this.isAsnDriver) {
            query += '&isForShippingPlan=true';
        }
        if (this.isThirdParty) {
            query += '&driverType=THIRD_PARTY';
        } else if(!this.notOnlyTypical) {
            query += '&driverType=TYPICAL';
        }
        this.driversList.getDrivers(query).subscribe(
            (response: any) => {
                this.users = response.map(user => {
                    return {
                        label: user.firstName + (user.middleName ? (' ' + user.middleName + ' ') : ' ') + user.lastName,
                        value: user.id,
                    };
                });
                if (this.users && this.users.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    getHubs(search?: string) {
        this.spinnerState = SpinnerState.LOADING;
        const query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE +
            (this.hubType ? '&type=' + this.hubType : '') + '&is-exclude-own-hub=' + this.excludeOwnHub;
        this.hubsService.getHubs(query, '', false, this.isGetAllHubs).subscribe(
            (response: any) => {
                this.users = response.hubs.map(hub => {
                    return {
                        label: hub.name,
                        value: hub.id,
                    };
                });
                if (this.users && this.users.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    getShelves(search?: string, hubId?: number) {
        const query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;

        this.spinnerState = SpinnerState.LOADING;
        this.fulfilmentService.getShelves(query, hubId).subscribe(
            (response: any) => {
                this.users = response.data.map(shelf => {
                    return {
                        label: shelf.label,
                        value: shelf.id,
                    };
                });
                if (this.users && this.users.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
                console.error(error);
            }
        );
    }

    public getPartners(search?: string) {
        this.spinnerState = SpinnerState.LOADING;
        let query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        query += '&status=ACTIVE';
        this.usersService.getPartners(query).subscribe(
            (response: any) => {
                this.users = response.data.map(partner => {
                    return {
                        label: partner.name,
                        value: partner.id,
                    };
                });
                if (this.users && this.users.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }


    public onSearch() {
        this.spinnerState = SpinnerState.LOADING;
        if (this.isDriver) {
            this.getDrivers(this.searchContent);
        } else {
            if (this.isHub) {
                this.getHubs(this.searchContent);
            } else {
                if (this.isPartner) {
                    this.getPartners(this.searchContent);
                } else {
                    this.getCustomers(this.searchContent, this.hubId);
                }
            }
        }
    }

    public chooseUser(event = this.selectedUser) {
        this.userChanged.emit(event?.value);
    }

    public resetUser(skipEmit = false) {
        this.selectedUser = null;
        if (!skipEmit) {
            this.userChanged.emit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const user: SimpleChange = changes.user;
        if (this.isDriver) {
            if (this.isThirdParty) {
                this.innerPlaceHolder = 'ADMINISTRATION.THIRD_PARTY_LIST_DROPDOWN_PLACEHOLDER';
            } else {
                this.innerPlaceHolder = 'ADMINISTRATION.DRIVER_LIST_DROPDOWN_PLACEHOLDER';
            }
        }
        if (user === undefined) {
            return;
        }
        if (user.currentValue === 0 || user.currentValue === undefined) {
            this.selectedUser = null;
        } else {
            if (this.selectedUser === undefined || !this.selectedUser) {
                // this.selectedUser.value = user.currentValue;
                if (this.isDriver) {
                    this.getDriverName(user.currentValue);
                } else {
                    if (this.isHub) {
                        this.getHubName(user.currentValue);
                    } else {
                        if (this.isPartner) {
                            this.getPartnerName(user.currentValue);
                        } else {
                            this.getCustomerName(user.currentValue);
                        }
                    }
                }
            }
        }
    }

    public getPartnerName(id) {
        this.spinnerState = SpinnerState.LOADING;
        if (this.users && this.users.length) {
            const selectedUser = this.selectedUser = this.users.filter((user) => {
                return user.id === id;
            })[0];
            this.selectedUser = {
                label: selectedUser.name,
                value: selectedUser.id
            };
            this.spinnerState = SpinnerState.LOADED;
        }
    }

    public getCustomerName(id) {
        this.spinnerState = SpinnerState.LOADING;
        this.usersService.getCustomer(id).subscribe(
            (user: any) => {
                this.selectedUser = this.getCustomerObject(user);
                this.spinnerState = SpinnerState.LOADED;
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    public getDriverName(id) {
        if (!this.getDriverById) {
            return;
        }
        this.spinnerState = SpinnerState.LOADING;
        this.usersService.getDriver(id).subscribe(
            (user: any) => {
                this.selectedUser = {
                    label: user.firstName + ' ' + user.lastName,
                    value: user.id
                };
                this.spinnerState = SpinnerState.LOADED;
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    public getHubName(id) {
        this.spinnerState = SpinnerState.LOADING;
        this.hubsService.getHub(id).subscribe(
            (user: any) => {
                this.selectedUser = {
                    label: user.name,
                    value: user.id
                };
                this.spinnerState = SpinnerState.LOADED;
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    getObjectKeys(item) {
        return Object.keys(item);
    }
}
