import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FaqModel} from '../models/faq.model';
import {ADMINISTRATION_CONSTANTS} from '../../administration/administration-constants';
import {FAQ_CONSTANTS} from '../faq-constants';

@Injectable({
  providedIn: 'root'
})
export class FaqService {


  constructor(private http: HttpClient) {
  }

  loadFaq(params, isSuperAdmin: boolean): Observable<any> {
    return this.http.get(params && params.search && params.search.length ? FAQ_CONSTANTS.GET_FAQ_API_WITH_SEARCH : FAQ_CONSTANTS.GET_FAQ_API, {params});
  }

  removeFaq(id): Observable<any>  {
    return this.http.delete(FAQ_CONSTANTS.DELETE_FAQ_API.replace('{id}', id));
  }

  updateFaq(faq: FaqModel, id): Observable<any> {
    return this.http.put(FAQ_CONSTANTS.UPDATE_FAQ_API.replace('{id}', id), faq);
  }

  createFaq(faq: any): Observable<any> {
    return this.http.post(FAQ_CONSTANTS.CREATE_FAQ_API, faq);
  }
}
