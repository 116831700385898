import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {UpdateModel} from '../../models/update.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user.service';

@Component({
    selector: 'app-update-info-pdf-viewer',
    templateUrl: './update-info-pdf-viewer.component.html',
    styleUrls: ['./update-info-pdf-viewer.component.scss']
})
export class UpdateInfoPdfViewerComponent implements OnInit {
    pdfSrc = '';
    pdfName = '';
    zoom = 1.0;
    private updateId: any;
    private isSuperAdmin: boolean;
    videoUrl;
    language;
    showAll = true;
    showLoader = true;


    constructor(private router: Router,
                private sanitizer: DomSanitizer,
                private userService: UserService,
                private route: ActivatedRoute, private systemUpdatesService: SystemUpdatesService,
                private authenticationService: AuthenticationService,
                private translate: TranslateService) {
        this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
        this.language = this.translate.currentLang;
    }

    ngOnInit() {
        this.updateId = this.route.snapshot.params.id;
        this.systemUpdatesService.loadSystemUpdateById(this.updateId, this.isSuperAdmin).subscribe((res: UpdateModel) => {
            this.showAll = false;
            this.showLoader = false;
            this.userService.initSystemUpdatesBadge();

            this.pdfSrc = this.language === 'ar' ? res.arabicUpdateUrl : res.updateUrl;
            this.pdfName = this.language === 'ar' ? res.arabicName : res.name;
            this.videoUrl = this.language === 'ar' ? res.arabicVideoLink : res.videoLink;
            if (this.videoUrl) {
                this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
                return;
            }
        });

    }

    incrementZoom(amount: number) {
        this.zoom += amount;
    }

    getURL() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    }

    hideSpinner() {
        this.showLoader = false;
    }

    print() {
        window.open(this.pdfSrc, '_blank');
    }
}
