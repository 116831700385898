<p-toast [baseZIndex]="toastZIndex"> </p-toast>

<div class="receive-table-view d-flex flex-column h-100">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner> </p-progressSpinner>
    </div>

    <div>
        <div class="receive-header d-flex flex-row" style="align-items: center; flex-wrap: wrap; justify-content: flex-end;">
            <div class="break"></div>
            <div *ngIf="!isFromPartner" class="cod-title flex-1">
                {{"ACCOUNTING.DRIVER_NAME" | translate}}: {{driverName}}
            </div>
            <div *ngIf="!isPickup" class="cod-title flex-1">
                {{"MANAGE_SHIPMENTS.HUB_NAME" | translate | hubTitle}}: {{hubName}}
            </div>
            <div class="cod-title flex-1">
                {{"SHIPMENT.NUMBER_OF_PACKAGES" | translate | packageTitle}}: {{totalPackages}}
            </div>
            <div class="cod-title flex-1">
                {{"SHIPMENT.NUMBER_OF_SCANNED_PACKAGES" | translate | packageTitle}}: {{scannedPackages.length}}
            </div>
        </div>
    </div>

    <div class="driver-receive-table flex-1 overflow-auto">
        <p-table
            #packagesTable
            appPTableRtlFix
            dataKey="id"
            #table
            [value]="packages"
            styleClass="customers-table flex-table"
            [rowsPerPageOptions]="rowsPerPageOptions"
            [scrollable]="true"
            scrollHeight="auto"
            [lazy]="true"
            (onLazyLoad)="loadPackagesLazy($event)"
            [paginator]="true"
            [responsive]="true"
            [rows]="pageSize"
            [totalRecords]="totalPackages"
        >
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component> </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th *ngIf="!isPickup">{{"ADD_SHIPMENT.SENDER_NAME" | translate}}</th>
                    <th *ngIf="isPickup">{{"MANAGE_COD_CUSTOMERS.CUSTOMER_NAME" | translate }}</th>
                    <th *ngIf="!isPickup">{{"ADD_SHIPMENT.RECEIVER_NAME" | translate}}</th>
                    <th *ngIf="!isPickup">{{"ADD_SHIPMENT.RECEIVER_PHONE" | translate }}</th>
                    <th>{{"ADD_SHIPMENT.INVOICE_NUMBER" | translate}}</th>
                    <th *ngIf="!isPickup">{{"MANAGE_SHIPMENTS.COD" | translate}}</th>
                    <th *appPermission="permissions.includes('{EDIT_COST') && !isPickup">{{"MANAGE_SHIPMENTS.COST" | translate}}</th>
                    <th *ngIf="!isPickup">{{"MANAGE_SHIPMENTS.NOTES" | translate}}</th>
                    <th *ngIf="!isPickup">{{"MANAGE_SHIPMENTS.STATUS" | translate}}</th>
                    <th *ngIf="isPickup">{{"MANAGE_SHIPMENTS.PICKUP_DATE"|translate}}</th>
                    <th *ngIf="isScanning">{{"ACCOUNTING.SCAN_PACKAGE"|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr [ngClass]="{'scanned-row':isPackageScanned(package.id)}">
                    <td style="width: 150px; text-align: center;">
                        <img height="50px" class="barcode-image" [src]="package.barcodeImage" />
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display" (click)="copyText(package.barcode)"></i>
                        </span>
                    </td>
                    <td *ngIf="isPickup">{{package.customerName}}</td>
                    <td *ngIf="!isPickup">{{package.senderFirstName}} {{(package.senderMiddleName ? package.senderMiddleName : '')}} {{package.senderLastName}}</td>
                    <td *ngIf="!isPickup">{{package.receiverFirstName}} {{(package.receiverMiddleName ? package.receiverMiddleName : '')}} {{package.receiverLastName}}</td>
                    <td *ngIf="!isPickup">{{package.receiverPhone}}</td>
                    <td>{{package.invoiceNumber}}</td>
                    <td *ngIf="!isPickup">{{package.cod}}</td>
                    <td *appPermission="permissions.includes('{EDIT_COST') && !isPickup" pEditableColumn [pEditableColumn]="package" [pEditableColumnField]="'cost'">
                        <app-edit-cost [package]="package"></app-edit-cost>
                    </td>
                    <td *ngIf="!isPickup">{{package.notes}}</td>
                    <td *ngIf="!isPickup">
                        {{package.status | translate}}
                        <i class="cursor-pointer edit-status fas fa-pencil-alt" *appPermission="permissions.includes('{CHANGE_PACKAGE_STATUS')" (click)="onChangePackageStatus(package)"></i>
                        <div>
                            <ng-container [ngSwitch]="package.status">
                                <div *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                </div>
                                <div *ngSwitchCase="'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                </div>
                                <div *ngSwitchCase="'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                </div>
                                <div *ngSwitchCase="'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                </div>
                                <div *ngSwitchCase="'SCANNED_BY_HANDLER_AND_UNLOADED'">
                                    {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                </div>
                                <div *ngSwitchCase="'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'">
                                    {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                </div>
                                <div *ngSwitchCase="'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'">
                                    {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                </div>
                                <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                    <ng-container [ngSwitch]="package.returnedPackageLocation">
                                        <div *ngSwitchCase="'IN_HUB'">
                                            <br />
                                            {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}} : {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                        </div>
                                        <div *ngSwitchCase="'IN_CAR'">
                                            <br />
                                            {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{package.driverName}}
                                        </div>
                                        <div *ngSwitchCase="'DELIVERED_TO_SENDER'">
                                            <br />
                                            {{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER'|translate}}
                                        </div>
                                        <span *ngSwitchDefault></span>
                                    </ng-container>
                                </div>
                                <span *ngSwitchDefault></span>
                            </ng-container>
                        </div>
                        <div *ngIf="package.isFailed" style="color: red; font-weight: 800; text-decoration: underline;" class="cursor-pointer" (click)="openMessagesHistory(package.id)">
                            <br />
                            {{'SHIPMENT.FAILED'| translate}}
                            <br />
                            {{'SHIPMENT.NUMBER_OF_ATTEMPTS'|translate}} : {{package.failuresNumber}}
                        </div>
                    </td>
                    <td *ngIf="isPickup">{{package.pickupDate | date:'dd/MM/yyyy'}}</td>
                    <td *ngIf="isScanning && !isPackageScanned(package.id)">
                        <div class="table-row-actions-container">
                            <div class="scan-action action cursor-pointer" (click)="scanPackage(package)">
                                <i class="fas fa-barcode"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isScanning && isPackageScanned(package.id)">
                        <div class="table-row-actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackage(package)">
                                <i class="fas fa-minus-circle"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="bottom-actions-container">
        <div *ngIf="!isScanning" [ngClass]="{'disabled-btn': !totalPackages}" class="add-to-container secondary-action-btn cursor-pointer" (click)="startScanning()">
            {{"ACCOUNTING.START_SCANNING" | translate}}
        </div>
        <div *ngIf="isScanning" class="secondary-action-btn remove-action cursor-pointer" (click)="resetScanning()">
            {{"ACCOUNTING.RESET" | translate}}
        </div>
        <div *ngIf="isScanning" class="add-to-driver-container secondary-action-btn cursor-pointer" (click)="enterBarcode()">
            {{"ACCOUNTING.ENTER_BARCODE" | translate}}
        </div>
        <div *ngIf="isScanning" [ngClass]="{'disabled-btn': !this.scannedPackages.length}" class="add-to-driver-container secondary-action-btn cursor-pointer" (click)="finishScanning()">
            {{"ACCOUNTING.FINISH_SCANNING" | translate}}
        </div>
    </div>
</div>
