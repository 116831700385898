import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
export const MapType = {
  NORMAL: 'NORMAL',
  W3W: 'What3World'
};

@Component({
  selector: 'app-choose-map',
  templateUrl: './choose-map.component.html',
  styleUrls: ['./choose-map.component.scss']
})

export class ChooseMapComponent implements OnInit {

  @Input() location;

  @Input() showInitialMarker = false;

  @Input() flyToMarkerAdded = false;

  @Input() defaultZoom = 8;

  @Input() multipleMarkers = false;

  @Input() readOnly = false;

  @Output() clickMap = new EventEmitter<{ lat: number; lng: number }>();
  mapType = MapType;

  type: string;

  constructor() {
  }

  ngOnInit() {
  }
}
