<div class="h-100 single-report-page">
  <app-admin-report [columns]="partnersReports"
                    [apiUrl]="partnersReportsAPI"
                    [startFromIndex] = 1
                    [isPdfRemove] = "false"
                    [hasFilters] = true
                    [filterPartners] = "true"
                    [filterDate] = false
                    [TYPE] = "'partners_Reports'"
                    reportTitle="ADMINISTRATION.PARTNERS_REPORTS"></app-admin-report>
</div>
