import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    ElementRef,
    OnDestroy,
    ViewChild, AfterViewInit
} from '@angular/core';
import {SideMenuItemInterface} from './models/side-menu-item.interface';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-side-menu-item-component',
    templateUrl: 'side-menu-item.component.html',
    styleUrls: ['side-menu-item.component.scss']
})
export class SideMenuItemComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     *
     */
    @Input() item: SideMenuItemInterface;
    @Input() level = 0;
    @Input() isSubItem = false;
    @Output() subMenuShow = new EventEmitter();
    @Output() onSelectSubMenu = new EventEmitter();
    @ViewChildren('subItemsRef') subItemsRef: QueryList<SideMenuItemComponent>;
    @ViewChild('itemLinkRef', {static: true}) linkRef: ElementRef;
    private routingSubscription: Subscription;

    public show = false;
    public hasActiveChild = false;
    public translationLoaded = false;

    constructor(private translate: TranslateService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private userService: UserService) {

    }

    ngAfterViewInit(): void {
        for (const el of this.subItemsRef.toArray()) {
            if (el.isActiveV2()) {
                this.openParents(this.item);
                break;
            }
        }
    }

    ngOnInit() {
        this.item.htmlRef = this;
        if (this.item.subItems) {
            // @ts-ignore
            this.routingSubscription = this.router.events.subscribe((routerEvent: RouterEvent) => {
                    if (routerEvent instanceof NavigationEnd) {
                        this.hasActiveChild = false;
                        for (const el of this.subItemsRef.toArray()) {
                            if (el.isActiveV2()) {
                                this.openParents(this.item);
                                break;
                            }
                        }
                    }
                }
            );
        }
        this.translationResponse();
    }
    public  translationResponse() {
        this.translate.get('MANAGE_SHIPMENTS.TITLE').subscribe(
            (response) => {
                this.translationLoaded = true;
            }
        );
    }
    ngOnDestroy() {
        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }
    }

    onSubMenuShow(event) {
        if (event) {
            this.subMenuShow.emit(true);
            this.hasActiveChild = this.show = true;
        }
    }
    toggleMenuVisibility() {
        this.show = !this.show;
    }

    calculateSubItemsHeight(item) {
        let height = 60;
        if (item.subItems) {
            // tslint:disable-next-line:forin
             for (const subItem in item.subItems) {
                height += (60 + this.calculateSubItemsHeight(subItem));
            }
        }
        return height;
    }

    public getPaddingStyles() {
        const padding = (this.translate.currentLang === 'en' ? 'padding-left' : 'padding-right');
        // if (this.item.subItems) {
        //     return {
        //         [padding]: 3 + this.level + 'px!important',
        //         'margin': 'auto'
        //     };
        // }
        return {
            [padding]: 15 + this.level + 'px!important',
            'margin': 'auto'
        };
    }

    public selectItem(item) {
        this.onSelectSubMenu.emit(item);
    }
    public getImageSrc(imageName) {
        return '../../../assets/icons/side-menu/' + imageName;
    }
    public isActive() {
        return this.activatedRoute.snapshot.firstChild.url[0].path === this.item.path;
    }

    public isActiveV2() {
        if (this.router.url.endsWith(this.item.pathSoFar) && !this.item.fakePath && this.item.pathSoFar !== '') {
            return true;
        }
        return false;
    }

    public getRouterLinkActive(isSubItem) {
        if (!isSubItem) {
            return 'active';
        }
        return 'active-sub-item';
    }

    private openParents(item: SideMenuItemInterface) {
        if (item) {
            item.htmlRef.hasActiveChild = item.htmlRef.show = true;
            this.openParents(item.parent);
        }
    }

    Console = console;
}
