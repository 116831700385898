import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../shared/services/user.service';
import {AuthenticationService} from '../shared/services/authentication.service';
import {MessagingService} from '../shared/services/messaging.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserModel} from '../shared/models/user.model';
import {ShipmentModel} from '../shared/models/shipment.model';
import {AddShipmentQuickComponent} from '../shipment/add-shipment-quick/add-shipment-quick.component';
import {RolesService} from '../shared/services/roles/roles.service';
import {ROUTE_SHARED, SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {CompaniesService} from '../companies/services/companies.service';
import {ShipmentsService} from '../shipment/services/shipments.service';
import {ApplicationStateService} from '../shared/services/application-state.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {SubscriptionTrackerComponent} from '../shared/behavior/subscription-tracker.component';
import {ROLES} from '../shared/services/roles/roles.constants';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy   {

    public menuItems: MenuItem[];
    public selectedLanguage;
    public isLoading = true;
    public nitiIndex;
    public notiId;
    public panel;
    public isEnglishSelected: boolean;
    private settingsPath = 'home/account-settings';
    public user: UserModel;
    public canAdd = false;
    public company: any = {};
    public favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    public displaySideMenuMobile = false;
    public isSideMenuMinimzedFlag = false;
    public logo = '';
    public companyName = '';
    public isSideMenuMinimizedFlag = false;
    public searchText = '';
    public isDisplayTracking = false;
    public displayAddPackage = false;
    public showAWBPrintModal = false;
    public linkAWB = '';
    public titleCode = '';
    public showPrintPackagesButton = false;
    public userRole = '';
    /**
     * prevent searching if the field is empty.
     */
    public isSearchValid = false;
    public selectedPackageBarcode: number;
    public selectedPackageId: number;

    /**
     * track the visibility of sidebar menu.
     * when the device is mobile.
     * @type {boolean}
     */
    public displaySidebarMenu = false;

    /**
     * Notifications
     */
    public notifications = [];
    public notificationsNum = 0;
    public isSettingAllAsRead = false;
    public notificationQueryParams;
    public hasMoreNotification;
    public isLoadingMoreNotification = false;
    public isLoadingNotification = false;
    public newRequestsNo = 0;
    public defaultAddPackageForm  = true;
    public showNewRequestsBtn: boolean;
    displayTicketDetails = false;
    triggeredTicketId = 0;
    @ViewChild('notificationPanel') notificationPanel;
    companiesHadStationsInsteadOfBranches = [256, 22]; //SAR for Production and mohanad for testing

    constructor(
        private userService: UserService,
        private authService: AuthenticationService,
        private translateService: TranslateService,
        private router: Router,
        public translate: TranslateService,
        public messagingService: MessagingService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private rolesService: RolesService,
        private companiesService: CompaniesService,
        private shipmentService: ShipmentsService,
        private applicationStateService: ApplicationStateService
    ) {
        super();
    }

    ngOnInit() {

        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.isEnglishSelected = this.translate.getDefaultLang() === 'en';
        this.initUserInfo();
        this.userService.getFollowUpPackageCount();
        this.userService.getUnreadMessagesCount();
        this.selectedLanguage = this.translateService.currentLang;
        this.initSidebarListener();
        this.setTablePageSize();
        this.getCompany();
        this.initializeNotificationParams();
        this.getNotifications();
        this.initBadgeNumber();
        this.handleCLoseSideMenu();
        // this.userRole = this.userService.userInfo.role;
    }
    ngOnDestroy () {
        super.ngOnDestroy();
    }

    handleUrlCompanyName(sessionCompanyName: string) {
        if (['SUPER_ADMIN_AS_ADMIN', 'SUPER_ADMIN', 'MONITOR'].indexOf(this.userService.userInfo.role) < 0) {
            const writtenInUrl = decodeURIComponent((window.location.href.split('//').join('').split('/'))[1].toLowerCase());
            const companyName = sessionCompanyName.toLowerCase();
            if (companyName !== writtenInUrl) {
                this.router.navigate([companyName + '/home/dashboard']);
            }
        }
    }
    handleCLoseSideMenu() {
        const subscription = this.router.events.subscribe(
            () => {
                if (this.applicationStateService.getIsMobileResolution()) {
                    this.hideSideMenuMobile(false);
                }
            }
        );
        this.subscriptions.push(subscription);
    }


    /**
     * listen to router changing,
     * in order to close the side bar after the user navigate.
     */
    public initSidebarListener() {
        this.router.events.subscribe(
            () => {
                this.displaySidebarMenu = false;
            }
        );
    }
    public getCompany() {
        if (this.authService.companyId !== 79) {
            return;
        }
        this.companiesService.getCompany().subscribe(
            (res: any) => {
                this.company = res;
                this.changeFavIcon();
            }
        );
    }
    public initUserInfo() {
        this.isLoading = true;
        this.userService.getUserInfo(true).then(
            (user) => {
                this.user = user;
                this.companyName = user.companyName;
                this.handleUrlCompanyName(this.companyName);
                this.logo = this.getLogoImage();
                this.isLoading = false;
                this.handleCustomerCustomizationRequests();
                this.initMenuItems();
                this.userRole = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
                this.canAdd = this.rolesService.getIsAuthorizedAction('ADD_SHIPMENT', this.userRole) ||
                    (this.userService.userInfo.isAllowAddingPackages && this.userService.userInfo.role !== 'FULFILLMENT_USER');
                this.showNewRequestsBtn = (this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', this.userService.userInfo.role))
                    .includes('{SHOW_PENDING_APPROVAL_REQUESTS') && !this.user.isMapViewOnly;
                if (this.showNewRequestsBtn) {
                    this.getNewShipmentsRequests();
                    this.autoRefreshNewShipmentsRequests();
                }
                this.defaultAddPackageForm = !this.user.isDistributor;
                if (this.userRole === 'HUB_MANAGER' || this.userRole === 'MULTIPLE_HUBS_MANAGER') {
                    this.canAdd = this.user.isHubManagerAddPackage;
                }
                this.route.url.subscribe((data) => {
                    const superAdminPath = data.find(element => element.path === 'super-admin');
                    if (superAdminPath !== undefined) {
                        this.canAdd = false;
                    }
                });
            }
        );
    }

    onClickNotification(id, searchValue, byId = false, type: 'PACKAGE' | 'TICKET' = 'PACKAGE', notification) {
        if (!notification.isRead) {
            this.setAsRead(id);
            notification.isRead = true;
        }
        if (notification.packageBarcode) {
            this.notificationPanel.hide();
            this.selectedPackageBarcode = notification.packageBarcode;
            this.selectedPackageId = notification.packageId;
            this.isDisplayTracking = true;
        }
        if (type === 'PACKAGE') {
            if (searchValue) {
                this.notificationPanel.hide();
                this.onSearch(searchValue, byId);
            }
        } else if (type === 'TICKET') {
            if (notification.ticketId) {
                this.notificationPanel.hide();
                this.openTicket(notification);
            }
        }
    }

    public onSearch(searchValue, byId = false) {
        if (byId) {
            this.isDisplayTracking = true;
            this.selectedPackageId = searchValue;
        } else {
            if (searchValue && searchValue !== null) {
                this.isDisplayTracking = true;
                this.isSearchValid = true;
                this.doTrack(searchValue);
            } else {
                this.isSearchValid = false;
                this.selectedPackageBarcode = null;
            }
        }

    }

    // If key was Enter, track
    public onKeypress(keyCode, searchValue) {
        if (keyCode === 13) {
            this.onSearch(searchValue);
        }
    }

    public onKeypressUp(keyCode) {
        if ((keyCode === 8 || keyCode === 46) && this.searchText.length === 0) {
            this.clearSearch();
        }
    }

    public doTrack(trackedNumber) {
        if (this.isSearchValid) {
            this.selectedPackageBarcode = trackedNumber;
            this.isDisplayTracking = true;
        }
    }
    public clearSearch() {
        this.searchText = '';
        this.selectedPackageBarcode = null;
    }
    public initMenuItems() {

        const companyName = this.route.snapshot.params['companyName'];

        this.userService.getUserMenuItems(companyName).then(
            (menuItems) => {
                this.menuItems = menuItems;
                if (this.companiesHadStationsInsteadOfBranches.indexOf(this.authService.companyId) !== -1) {
                    this.menuItems = this.menuItems.map(menu => {
                       if (menu['role_id'] === 'MANAGE_BRANCHES') {
                           menu.label = 'BRANCHES.STATIONS';
                           menu['imgSrc'] = 'manage-stations.svg';
                       }

                       return menu;
                    });
                }
            }
        );
    }
    public setTablePageSize() {
        if (this.authService.companyId === 93) {
            SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE = 50;
        }
    }

    public logout() {
        this.menuItems = [];
        this.userService.deleteUserInfo();
        this.userService.userInfo = null;
        this.authService.logout();
    }

    public onLangChange(dropdown) {
        dropdown.hide();
        this.translateService.resetLang(this.selectedLanguage);
        this.translateService.use(this.selectedLanguage);
    }

    public getNotifications() {
        if (this.notificationQueryParams.page === 1) {
            this.notifications = [];
            this.isLoadingNotification = true;
        } else {
            this.isLoadingMoreNotification = true;
        }
        this.messagingService.getNotifications(this.notificationQueryParams).subscribe((res: any) => {
                if (this.notificationQueryParams.page > 1) {
                    this.notifications = this.notifications.concat(res);
                    if (res.length < this.notificationQueryParams.pageSize) {
                        this.hasMoreNotification = false;
                    }
                } else {
                    this.notifications = res;
                }
                this.isLoadingMoreNotification = false;
            },
            error => {
                console.log(error);
                this.isLoadingMoreNotification = false;
            },
            () => {
                this.isLoadingNotification = false;
                this.isLoadingMoreNotification = false;
            });
    }

    public markAllAsRead() {
        this.isSettingAllAsRead = true;
        this.messagingService.setAllAsRead().subscribe(() => {
                this.initBadgeNumber();
                this.initializeNotificationParams();
                this.getNotifications();
                this.isSettingAllAsRead = false;
            },
            error => {
                console.log(error);
                this.isSettingAllAsRead = false;
            });
    }

    public initializeNotificationParams() {
        this.notificationQueryParams = {
            page: 1,
            pageSize: 20
        };
        this.hasMoreNotification = true;
    }

    public loadMoreNotifications() {
        const currentPage = this.notificationQueryParams.page || 0;
        this.notificationQueryParams = {
            page: currentPage + 1,
            pageSize: 20
        };
        this.getNotifications();
    }

    public setAsRead(id) {
        this.messagingService.setAsRead(id).subscribe(res => {
            this.initBadgeNumber();
        },
        error => {
            console.log(error);
        });
    }

    public initBadgeNumber() {
        this.messagingService.numberOfUnreadNotification().subscribe((res: any) => {
            // this.messagingService.notificationNumber = res;
            this.messagingService.notificationNumber = res.notificationsNumber;
            this.notificationsNum = res.notificationsNumber;
        }, error => {
            console.log(error);
        });
    }

    public notificationOptions(event, panel: OverlayPanel, id, index) {
        panel.toggle(event);
        this.notiId = id;
        this.nitiIndex = index;

    }

    public deleteNotification(notification, index) {

        this.messagingService.deletNotification(notification.id).subscribe(res => {
            if (index > -1) {
                this.notifications.splice(index, 1);
            }
        }, error => {
            console.log(error);
        });
    }

    public notificationTrigger(event, panel: OverlayPanel) {
        this.getNotifications();
        panel.toggle(event);
        this.panel = event;

    }

    public profileTrigger(event, panel: OverlayPanel) {
        panel.toggle(event);
        this.panel = event;

    }

    public goToSettings() {
        const companyName = this.authService.getCompanyName();
        this.router.navigate([`${companyName}${this.settingsPath}`]);
    }

    public openQuickAdd() {
        const modal = this.modalService.open(AddShipmentQuickComponent, {
            backdrop: 'static',
            windowClass: 'create-new-package',
            size: 'lg'
        });
        modal.result.then(
            (data: { isSuccess: boolean, shipment: ShipmentModel }) => {
                if (data.isSuccess) {
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public showAddPackage() {
        this.displayAddPackage = true;
    }

    public onHideAddPackage (event) {
        this.displayAddPackage = event;
    }

    public getLogoImage() {
        const dynameicLogo = (this.user) ? this.user.companyLogo : '';
        return (dynameicLogo ? dynameicLogo : '../../assets/icons/logestechs.png');
    }

    public changeFavIcon() {
        if (this.authService.companyId === 79 && this.company) {
            this.favIcon.href = this.company.favIcon;
        }
    }
    public getPoweredByImage() {
        if (this.authService.companyId === 79) {
            return  (this.user) ? this.user.companyLogo : '';
        } else {
            return '../../assets/icons/logestechs.png';
        }
    }
    public hideSideMenuMobile($event) {
        this.displaySideMenuMobile = $event;
    }
    public isSideMenuMinimizedHandler($event) {
        this.isSideMenuMinimizedFlag = $event;
    }

    public showAWBDialog($event) {
        this.downloadLastAddedPackages($event);
    }

    public onHidePrintAWB($event) {
        this.showAWBPrintModal = $event;
    }

    public downloadLastAddedPackages(ids) {
        if (ids.length === 0) {
            return;
        }
        const typePrint = null;

        this.shipmentService.downloadMultiple(ids, typePrint)
            .subscribe(
                (data: { url: string }) => {
                    this.linkAWB = data.url;
                    this.titleCode = 'PRINT_INVOICE';
                    this.showAWBPrintModal = true;
                    if (!this.applicationStateService.getIsMobileResolution()) {
                        this.showPrintPackagesButton = true;
                    }
                },
                (error) => {
                    console.error(error);
                }
            );
    }
    public getNewShipmentsRequests() {
        if (!this.authService.isUserActive()) {
            return;
        }
        this.shipmentService.getNewPackagesRequests().subscribe(
            (res: any) => {
                // set newRequestsNo value;
                this.newRequestsNo = res;
            } , error => {
                console.error(error);
            }
        );
        // this.autoRefreshNewShipmentsRequests();
    }
   public autoRefreshNewShipmentsRequests() {
        setInterval(() => {
            // call new requests api
           this.getNewShipmentsRequests();
        }, 180000);
    }
    public goToManageShipments() {
        this.router.navigate([this.authService.companyName + ROUTE_SHARED.HOME_SHIPMENT],
            {queryParams: {status: 'PENDING_CUSTOMER_CARE_APPROVAL'}});
        return;
    }

    public closeTracker($event) {
        this.isDisplayTracking = $event;
    }
    public isDistributor() {
        return this.user && this.user.isDistributor;
    }
    isCustomerAccountManager() {
        return this.userRole === 'CUSTOMER_ACCOUNT_MANAGER';
    }

    private openTicket(notification) {
        this.displayTicketDetails = true;
        this.triggeredTicketId = notification.ticketId;
        this.notificationPanel.hide();
    }

    closeTicketDetails($event: any) {
        this.displayTicketDetails = false;
        this.triggeredTicketId = null;
    }

    private handleCustomerCustomizationRequests() {
        if (this.user.companyId === 286 || this.user.companyId === 1) {
            ROLES['DISPATCHER']['VIDEOS_SYSTEM_UPDATES'] = [];
        }
    }
}
