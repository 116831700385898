<div class="export-shipment-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
   <div class="header">
       <div class="close-icon cursor-pointer" (click)="closeModal()">
           <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
       </div>
       <div class="title">
           <span>{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}</span>
           <span *ngIf="!isEnableExportingBetweenHubs || !isAuthorizedExportToHub">{{' ' + ('GENERAL.PARTNER' | translate)}}</span>
       </div>
   </div>

    <div *ngIf="isEnableExportingBetweenHubs && isAuthorizedExportToHub" class="tabs-container">
        <div class="tabs d-flex">
            <div class="tab cursor-pointer flex-2d-center"
                 (click)="onChangeTab('PARTNER')"
                 [ngClass]="{'active-tab' : selectedTab === 'PARTNER'}">{{'GENERAL.PARTNER' | translate}}</div>
            <div class="tab cursor-pointer flex-2d-center"
                 (click)="onChangeTab('HUB')"
                 [ngClass]="{'active-tab' : selectedTab === 'HUB'}">{{'GENERAL.HUB' | translate}}</div>
        </div>
    </div>
    <div class="drop-down-list">
        <app-users-list-dropdown-component
            #userFilter
            [isHub]="selectedTab==='HUB'"
            [showLabel]="true"
            [hubType]="HubTypes.MAIN"
            [excludeOwnHub]="true"
            [isGetAllHubs]="true"
            [isPartner]="selectedTab==='PARTNER'"
            [label]="'MANAGE_COD_CUSTOMERS.' + selectedTab + '_NAME'"
            [innerPlaceHolder]="'MANAGE_COD_CUSTOMERS.' + selectedTab + '_NAME'"
            (userChanged)="onUserFilterChanged($event)"
        ></app-users-list-dropdown-component>
    </div>
    <div class="footer">
        <div class="cancel cursor-pointer flex-2d-center" (click)="closeModal()">{{'ACTIONS.CANCEL' | translate}}</div>
        <div class="submit cursor-pointer flex-2d-center" [ngClass]="{'disabled-btn disabled': !id}" (click)="closeModal({id: id, type: selectedTab})">{{'ACTIONS.EXPORT' | translate}}</div>
    </div>
</div>
