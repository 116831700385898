import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RolesService} from '../services/roles/roles.service';
import {UserService} from '../services/user.service';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({providedIn: 'root'})
export class RoleGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService,
        private rolesService: RolesService,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const path = route.routeConfig.path;

        const observable = new Observable<boolean>(
            (observer) => {
                // get the user information to know his role.
                this.userService.getUserInfo().then(
                    (userInfo) => {
                        if (userInfo == null) {
                            observer.next(false);
                            return;
                        }
                        const userRole = userInfo.role;
                        const isAuthorized = this.rolesService.isAuthorizedPath(path, userInfo);

                        // Redirect user if he is not authorized.
                        if (isAuthorized) {
                            if (path === 'redirect') {
                                const companyName = this.authenticationService.companyName;
                                if (!companyName) {
                                    this.router.navigate(['/super-admin']);
                                } else {
                                    this.router.navigate([`/${companyName}home`]);
                                }
                                observer.next(false);
                            } else {
                                observer.next(true);
                            }
                        } else {
                            const companyName = this.authenticationService.getCompanyName();
                            const isSuperAdmin = this.authenticationService.getIsSuperAdmin();
                            if (isSuperAdmin) {
                                this.router.navigate(['/super-admin']);
                            } else {
                                this.router.navigate([`/${companyName}home`]);
                            }

                            observer.next(false);
                        }
                    }
                );
            }
        );
        return observable;
    }
}
