import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WarehouseAreaModel, WarehouseRowModel} from '../../models/warehouse.model';
import {HubModel} from '../../../shared/models/hub.model';
import {FulfilmentService} from '../../services/fulfilment.service';
import {FULFILMENT_CONSTANTS} from '../../services/constants';
import {MessageService} from 'primeng/api';
import {CustomizationCompanyService} from '../../../shared/services/customization-company.service';
import {Companies} from '../../../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-new-row',
    templateUrl: './new-row.component.html',
    styleUrls: ['./new-row.component.scss']
})
export class NewRowComponent implements OnInit {

    currentLang = 'en';
    isEditMode = false;
    viewOnly = false;
    rowForm: FormGroup;
    area: WarehouseAreaModel;
    warehouse: HubModel;
    row: WarehouseRowModel;
    isLoading = false;
    rowCallback;
    locationTypes = [];
    companies = Companies;


    constructor(private translateService: TranslateService,
                private formBuilder: FormBuilder,
                private fulfilmentService: FulfilmentService,
                private messageService: MessageService,
                private activeModal: NgbActiveModal,
                public customizationCompaniesService: CustomizationCompanyService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.rowForm = this.formBuilder.group({
            name: [{value : this.row ? this.row.name : '', disabled: this.viewOnly}, Validators.required],
            locationType: [{value : this.row ? this.row.locationType : null, disabled: this.viewOnly}, Validators.required],
            columnsNumber: [{value: (this.row ? this.row.totalCols : 0), disabled: this.viewOnly || this.isEditMode}, [
                Validators.required,
                Validators.max((this.customizationCompaniesService.checkCompanies(this.companies.FAST_ACCESS, this.companies.LOGESTECHS) ? FULFILMENT_CONSTANTS.VALIDATION.MAX_COLUMNS_FAST_ACCESS : FULFILMENT_CONSTANTS.VALIDATION.MAX_COLUMNS)),
                Validators.min(FULFILMENT_CONSTANTS.VALIDATION.MIN_COLUMNS)
            ]],
            shelvesNumber: [{value: (this.row ? this.row.totalShelves : 0),  disabled: this.viewOnly || this.isEditMode}, [
                Validators.required,
                Validators.max((this.customizationCompaniesService.checkCompanies(this.companies.FAST_ACCESS, this.companies.LOGESTECHS) ? FULFILMENT_CONSTANTS.VALIDATION.MAX_SHELVES_FAST_ACCESS : FULFILMENT_CONSTANTS.VALIDATION.MAX_SHELVES)),
                Validators.min(FULFILMENT_CONSTANTS.VALIDATION.MIN_SHELVES)
            ]],
            locationsNumber: [{value: (this.row ? this.row.totalLocations : 0),  disabled: this.viewOnly || this.isEditMode}, [
                Validators.required,
                Validators.max(FULFILMENT_CONSTANTS.VALIDATION.MAX_LOCATIONS),
                Validators.min(FULFILMENT_CONSTANTS.VALIDATION.MIN_LOCATIONS)
            ]],
            warehouseName: [{value: this.warehouse.name, disabled: true}, Validators.required],
            areaName: [{value: this.area.name, disabled: true}, Validators.required]
        });
        this.initLocationTypes();
        // this.rowForm.valueChanges.subscribe(
        //     (value) => {
        //         console.log(this.rowForm);
        //     }
        // );
    }

    submitForm() {
        if (!this.rowForm.valid) {
            return;
        }
        this.messageService.add({key: 'c', sticky: true, severity: 'custom', summary: this.translateService.instant('ALERTS.LOCATIONS_CREATING')});
        this.isLoading = true;
        const formValue = this.rowForm.getRawValue();
        this.closeModal(true);
        this.fulfilmentService.newRowWithLocations(this.area.id, {
            name: formValue['name'],
            columnsNumber: formValue['columnsNumber'],
            shelvesNumber: formValue['shelvesNumber'],
            locationsNumber: formValue['locationsNumber'],
            locationType: formValue['locationType'],
            hubId: this.warehouse.id
        }).subscribe(
            (locationsResponse) => {
                this.messageService.clear('c');
                this.translateService.get('ALERTS.LOCATIONS_CREATED_SUCCESSFULLY').subscribe(
                    (translateValue) => {
                        this.messageService.add({severity: 'success', detail: translateValue});
                        if (this.rowCallback) {
                            this.rowCallback(true);
                        }
                    }
                );
            }, (error) => {
                this.messageService.clear('c');
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    isInvalidDirtyTouched(formName) {
        return this.rowForm.get(formName).invalid && (this.rowForm.get(formName).dirty
            || this.rowForm.get(formName).touched);
    }

    isInvalidWithError(formName, errorName) {
        return this.rowForm.get(formName).invalid && (this.rowForm.get(formName).dirty
            || this.rowForm.get(formName).touched) && this.rowForm.get(formName).errors
            && this.rowForm.get(formName).errors[errorName];
    }

    closeModal(isSuccess?) {
        if (isSuccess) {
            this.activeModal.close({isSuccess: isSuccess});
        }
        this.activeModal.close();
    }

    private initLocationTypes() {
        this.locationTypes = FULFILMENT_CONSTANTS.LOCATION_TYPES.map(l => {
            return {
                label: this.translateService.instant('FULFILMENT.WAREHOUSE.LOCATION_TYPES.' + l),
                value: l,
            };
        });
    }
}
