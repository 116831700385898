import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {PackageModel} from '../../shared/models/package.model';
import {ShipmentsService} from '../services/shipments.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DriversService} from '../../shared/services/drivers.service';

@Component({
    selector: 'app-postpone-package',
    templateUrl: './postpone-package.component.html',
    styleUrls: ['./postpone-package.component.scss']
})
export class PostponePackageComponent implements OnInit {
    @Input() shipment: PackageModel;
    @Input() notesOnly = false;
    @Input() isAdminNotes = false;
    @Input() title = 'SHIPMENT.POSTPONE_PACKAGE';
    public form;
    public isLoading = false;
    public today = new Date();
    public drivers: { label: string, value: any }[] = [];
    public selectedPackagesIds = []

    constructor(private formBuilder: FormBuilder,
                private shipmentService: ShipmentsService,
                private activeModal: NgbActiveModal,
                private driversService: DriversService) {}

    ngOnInit() {
        this.initForm();
    }
    public initForm() {
        if (this.notesOnly) {
                this.form = this.formBuilder.group({
                    note: [ !this.isAdminNotes ? this.shipment.note  : '', Validators.required]
                });
        } else {
            this.form = this.formBuilder.group({
                driverId: [this.shipment.driverId, Validators.required],
                postponedDate: [this.shipment.postponedDate ? new Date(this.shipment.postponedDate) : null, Validators.required],
                note: [this.shipment.note, Validators.required]
            });
        }
    }

    public saveButton() {
        if (this.selectedPackagesIds.length) {
            this.sendNoteToMultiplePackages();
        } else {
            this.onSaveButton();
        }
    }


    private onSaveButton() {
        let body;
        if (this.notesOnly) {
            body = { note: this.form.value.note };

        } else {
            body = {
                driverId: this.form.value.driverId.value,
                postponedDeliveryDate: this.form.value.postponedDate,
                note: this.form.value.note
            };
        }
        this.isLoading = true;
        this.shipmentService.postponePackage(body, this.shipment.id, this.notesOnly, this.isAdminNotes).subscribe(
            (response) => {
                Object.keys(body).forEach(key => {
                    this.shipment[key] = body[key];
                });
                this.activeModal.close({isSuccess: true});
            }, () => {
                this.activeModal.close({isSuccess: false});
            }
        );
    }

    private sendNoteToMultiplePackages() {
        const body = {
            note: this.form.value.note,
            ids: this.selectedPackagesIds
        };

        this.isLoading = true;
        this.shipmentService.noteMultiPackages(body).subscribe(
            (response) => {
                this.activeModal.close({isSuccess: true});
            }, () => {
                this.activeModal.close({isSuccess: false});
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }

    getDrivers(event) {
        let query = '?page=1&pageSize=20';
        if (event.query) {
            query += '&search=' + event.query;
        }
        return this.driversService.getDrivers(query).subscribe(
            (res: any) => {
                this.drivers = res.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {label: name, value: driver.id};
                    }
                );
            });
    }
}
