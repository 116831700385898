<div class="third-party-card">
    <div class="third-party-card-header">
        <div class="logo">
            <img alt="company name" src="../../../../assets/icons/third-parties/{{card.thirdPartyType}}.svg" class="card-logo" />
        </div>
    </div>
    <div class="third-party-card-details">
        <div class="third-party-card-type">
            <span [innerHtml]="'THIRD_PARTY.TYPE.' + card.thirdPartyType | translate"></span>
        </div>
        <div class="third-party-card-name">
            <b>{{'THIRD_PARTY.AUTHORIZED_PERSON' | translate}}: </b>
            <span [innerHtml]="card.firstName + ' ' + card.lastName"></span>
        </div>
        <div class="third-party-card-phone">
            <b>{{'THIRD_PARTY.PHONE' | translate}}: </b>
            <span [innerHtml]="card.phone"></span>
        </div>
        <div class="third-party-card-address">
            <b>{{'THIRD_PARTY.ADDRESS' | translate}}: </b>
            <span> {{card.address.village }} - {{ card.address.city }} - {{ card.address.region }}</span>
        </div>
    </div>
</div>
