import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ShipmentsService} from '../services/shipments.service';
import {UserService} from '../../shared/services/user.service';
import {playError} from '../../shared/behavior/sounds';

@Component({
    selector: 'app-enter-barcode',
    templateUrl: './enter-barcode.component.html',
    styleUrls: ['./enter-barcode.component.scss']
})
export class EnterBarcodeComponent implements OnInit {
    public isLoading = false;
    public form: FormGroup;
    public isAdmin = false;
    public customerID = 0;
    private driverId = 0;
    private isCarried = false;
    public isReturned = false;
    public isBin = false;
    public onlyReturnedBundle = false;
    public partnerId = 0;
    public isGetPackageStatus = false;
    public isPickup = false;
    public onlyReturnBarcode = false;
    public isReceivePartnerPackage = false;
    public isAddToContainer = false;
    public isHub = false;
    public isPartner = false;
    public showOriginalStatus = false;
    currentLang;
    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private messageService: MessageService,
                private shipmentsService: ShipmentsService,
                private userService: UserService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isLoading = false;
        this.initInfo();
    }

    public closeModal() {
        this.activeModal.close();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            barcode: ['', Validators.required],
        });

    }
    public scanPackageBarcode() {
        const {barcode} = this.form.getRawValue();
        if (!barcode) {
            return;
        }
        this.isLoading = true;
        if (this.customerID) {
            this.getCustomerPackages(barcode);
            return;
        }
        if (this.isReturned) {
            this.shipmentsService.getReturnedPakcageByBarcode(barcode).subscribe((shipment) => {
                if (this.isReturnedPackages(shipment)) {
                    this.activeModal.close({isSuccess: true, package: shipment});
                } else {
                    this.showConfirmationMessage(shipment);
                }
                this.isLoading = false;
            }, error => {
                console.error(error);
                playError();
                this.isLoading = false;
            });
            return;
        }
        if (this.partnerId && !this.isReceivePartnerPackage) {
            this.shipmentsService.getPackageByBarcodeCancel(barcode, this.partnerId).subscribe((shipment) => {
                this.activeModal.close({isSuccess: true, package: shipment});
            }, () => {
                playError();
                this.activeModal.close({isSuccess: false});
                this.isLoading = false;
            });
            return;
        }
        if (this.isCarried) {
            let getPackage;
            // check receive driver or partner package
            if (this.isReceivePartnerPackage) {
                getPackage = this.shipmentsService.getPartnerPackageByBarcode(barcode, this.partnerId);
            } else { // driver package
                getPackage = this.shipmentsService.getDriverPackageByBarcode(barcode, this.driverId);
            }
            getPackage.subscribe((shipment) => {
                this.activeModal.close({isSuccess: true, package: shipment});
            }, () => {
                playError();
                this.activeModal.close({isSuccess: false});
                this.isLoading = false;
            });
            return;
        }
        const role = this.isAdmin ? 'ADMIN' : 'ACCOUNTANT';
        this.shipmentsService.getPackageByBarcode(barcode, role, this.driverId, this.isGetPackageStatus, {}, this.isHub, false, true).subscribe((shipment) => {
            this.activeModal.close({isSuccess: true, package: shipment});
        }, () => {
            playError();
            this.activeModal.close({isSuccess: false});
        });
    }
    public scanPickupBarcode() {
        const {barcode} = this.form.getRawValue();
        if (!barcode) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.getPickupByBarcode(barcode, this.driverId).subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.activeModal.close({isSuccess: true, package: shipment});
                    }
                );
        }, () => {
            this.activeModal.close({isSuccess: false});
            this.isLoading = false;
        });
    }
    private getPackageId(barcode) {
        this.isLoading = true;
        this.shipmentsService.getPackageIdBybarcode(barcode).subscribe(
            (pkgId: {id: number}) => {
                this.activeModal.close({isSuccess: true, id: pkgId.id});
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
                this.confirmationService.confirm({
                    message: error.error.error,
                    rejectVisible: false,
                    accept: () => {
                    },
                    acceptLabel: 'OK'
                });
            }
        );
    }
    public scanBarcode() {
        const {barcode} = this.form.getRawValue();
        if (this.isBin || this.onlyReturnedBundle) {
            this.activeModal.close({isSuccess: true, barcode: barcode});
            return;
        }
        if (!this.isAddToContainer) {
          if (this.onlyReturnBarcode) {
              this.getPackageId(barcode);
          } else {
              if (this.isPickup) {
                  this.scanPickupBarcode();
              } else { // package
                  this.scanPackageBarcode();
              }
          }
      } else {
          this.activeModal.close({containerNumber: barcode});

      }
    }

    public getCustomerPackages(barcode) {
        this.shipmentsService.getPackageByBarcode(barcode, '', this.customerID, false, false, false, false, true).subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.activeModal.close({isSuccess: true, package: shipment});
                    }
                );
        }, () => {
            playError();
            this.activeModal.close({isSuccess: false});
            this.isLoading = false;
        });
    }

    private isReturnedPackages(shipment: any) {
        return shipment.status === 'RETURNED_BY_RECIPIENT';
    }

    private showConfirmationMessage(shipment: Object) {
        this.confirmationService.confirm({
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
            message: this.translateService.instant('ACTIONS.PACKAGE_NOT_RETURNED_MESSAGE'),
            accept: () => {
                this.activeModal.close({isSuccess: true, package: shipment});
            },
            reject: () => {
            }
        });

    }
}
