<div class="third-party">
    <div class="third-party-header">
        <div class="title">
            {{"THIRD_PARTY.TITLE" | translate}}
        </div>
        <div class="third-party-button"  *ngIf="canAddNewThirdParty" #btn (click)="addNewThirdParty()">
            {{"THIRD_PARTY.ADD_NEW" | translate}}
        </div>
    </div>
    <div class="cards-list">
        <ng-container *ngFor="let card of cards">
            <app-third-party-card [card]="card"></app-third-party-card>
        </ng-container>
    </div>
</div>
