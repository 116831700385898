import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {DriverModel} from '../../../shared/models/driver.model';
import {ContainersService} from '../../../containers/services/containers.service';
import {UserModel} from '../../../shared/models/user.model';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {AddCustomersListComponent} from '../../../shippings-rate/components/pricing-lists/add-customers-list/add-customers-list.component';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {AttachmentsViewComponent} from '../../../shared/components/attachments-view/attachments-view.component';
import {TrackingUserPasswordComponent} from '../tracking-user-password/tracking-user-password.component';
import {CustomizationCompanyService} from '../../../shared/services/customization-company.service';
import {Companies} from '../../../../customCompanies/companies.pal-ship';
import {SharedService} from '../../../shared/services/shared-service';
import {SubCustomersForCustomerComponent} from '../sub-customers-for-customer/sub-customers-for-customer.component';

@Component({
    selector: 'app-user-card-component',
    templateUrl: 'user-card.component.html',
    styleUrls: ['user-card.component.scss']
})
export class UserCardComponent implements OnInit {
    @ViewChild('rowActionsPopOver', {static: false}) rowActionsPopOver: NgbPopover;
    // Inputs
    @Input() user: UserModel;
    @Input() canRemove: boolean;
    @Input() canEdit: boolean;
    @Input() canResetPassword: boolean;
    @Input() canTrackingPassword: boolean;
    @Input() isNewRequest = false;
    @Input() isCustomer: boolean;
    @Input() isDriver: boolean;
    @Input() isArchived: boolean;
    @Input() canAddAttachment: boolean;
    @Input() canDownloadExcelTemplate: boolean;
    @Input() downloadExcelLoader: boolean;
    @Input() selectedTab: string;
    permissions;
    // Outputs
    @Output() onRemoveUser: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{ user: UserModel }>();
    // Outputs
    @Output() onRemoveUserForever: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{ user: UserModel }>();
    @Output() onEditUser: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{ user: UserModel }>();
    @Output() onEditHubs: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{ user: UserModel }>();
    @Output() onResetPassword: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{ user: UserModel }>();
    @Output() onShowDetails: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{ user: UserModel }>();
    @Output() onAutoApproveUser: EventEmitter<{checked: boolean, user: UserModel }> =
        new EventEmitter<{ checked: boolean, user: UserModel }>();
    @Output() onAddAttachment: EventEmitter<{user: UserModel }> =
        new EventEmitter<{ checked: boolean, user: UserModel }>();
    @Output() onDownloadExcelTemplate: EventEmitter<{user: UserModel }> =
        new EventEmitter<{ checked: boolean, user: UserModel }>();
    @Output() onRetrieve: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{user: UserModel}>();
    @Output() verifyUser: EventEmitter<{ user: UserModel, complete?: any }> =
        new EventEmitter<{ user: UserModel, complete?: any }>();
    @Output() uploadUserImage: EventEmitter<{ user: UserModel, imageFormData: FormData }> =
        new EventEmitter<{ user: UserModel; imageFormData: FormData }>();
    @Output() showDriverCheckIn: EventEmitter<{ user: UserModel }> =
        new EventEmitter<{user: UserModel}>();

    public currentLang = '';
    canVerify = false;
    canDeleteForever = false;
    companies = Companies;
    constructor(public router: Router,
                public authService: AuthenticationService,
                private translateService: TranslateService,
                private modalService: NgbModal,
                private userService: UserService,
                private rolesService: RolesService,
                private containerService: ContainersService,
                private customizationCompanyService: CustomizationCompanyService,
                private sharedService: SharedService) {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_USERS', role);
        this.canDeleteForever = this.permissions.includes('FINAL_DELETE');
    }
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.handleVisibleComponent();
    }
    public getAddress(address) {
        return address ?  address.village + ' - ' + address.city + ' - ' + address.region : '-';
    }
    public getDriverName(driverObject: DriverModel) {
        return this.containerService.getDriverName(driverObject);
    }
    public removeUser() {
        this.closeRowActionsPopOver();
        this.onRemoveUser.emit({user: this.user});
    }
    public editUser() {
        this.closeRowActionsPopOver();
        this.onEditUser.emit({user: this.user});
    }
    public editHubs() {
        this.closeRowActionsPopOver();
        this.onEditHubs.emit({user: this.user});
    }
    public resetPassword() {
        this.closeRowActionsPopOver();
        this.onResetPassword.emit({user: this.user});
    }

    public trackingPassword() {
        const modal = this.modalService.open(TrackingUserPasswordComponent, <any>{
            backdrop: 'static',
            windowClass: this.currentLang === 'ar' ? 'container-tracking-password' : 'container-tracking-password-en',
            size: <any>'xs'
        });
        modal.componentInstance.userId = this.user.id;
        modal.componentInstance.isCustomer = this.selectedTab === 'customers';
    }

    public displayCustomers() {
        const modal = this.modalService.open(SubCustomersForCustomerComponent, <any>{
            backdrop: 'static',
            windowClass: this.currentLang === 'ar' ? 'sub-customers-for-customer-component' : 'sub-customers-for-customer-component-en',
            size: <any>'xs'
        });
        modal.componentInstance.userId = this.user.id;
        modal.componentInstance.isCustomer = this.selectedTab === 'customers';
    }

    public closeRowActionsPopOver() {
        if (this.rowActionsPopOver && this.rowActionsPopOver.isOpen()) {
            this.rowActionsPopOver.close();
        }
    }
    public showUserDetails() {
        this.onShowDetails.emit({user: this.user});
    }
    public autoApproveUser(checked) {
        this.onAutoApproveUser.emit({checked: checked, user: this.user});
    }
    public addCustomerAttachment() {
        this.onAddAttachment.emit({user: this.user});
    }
    public downloadExcelTemplate() {
        this.onDownloadExcelTemplate.emit({user: this.user});
    }
    public retrieve() {
        this.onRetrieve.emit({user: this.user});
    }
    public onVerifyUser() {
        this.verifyUser.emit({ user: this.user });
    }
    public onUploadImage(imageFormData) {
        this.uploadUserImage.emit({ user: this.user, imageFormData: imageFormData });
    }

    onShowDriverCheckIn() {
        this.showDriverCheckIn.emit({
            user: this.user
        });
    }
    public showCustomers(user) {
        const modal = this.modalService.open(AddCustomersListComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.customerAccountManagerId = user.id;
        modal.componentInstance.isCustomerAccountManage = true;
    }
    public isCustomerAccountManager() {
        return ['CUSTOMER_ACCOUNT_MANAGER', 'DISPATCHER', 'HUB_MANAGER', 'MULTIPLE_HUBS_MANAGER'].indexOf(this.userService.userInfo.role) !== -1;
    }

    private handleVisibleComponent() {
        this.canVerify = (['ADMIN', 'SUPER_ADMIN', 'ACCOUNTANT', 'OPERATION_MANAGER'].indexOf(this.userService.userInfo.role) > -1);
    }

    openAttachments() {
        this.closeRowActionsPopOver();
        const modal = this.modalService.open(AttachmentsViewComponent, {
            backdrop: 'static',
            size: 'md'
        });
        modal.componentInstance.user = this.user;
    }

    removeUserForever() {
        this.closeRowActionsPopOver();
        this.onRemoveUserForever.emit({user: this.user});
    }

    canEditRoles() {
        return !(['CUSTOMER_CARE'].indexOf(this.userService.userInfo.role) > -1 && this.selectedTab === 'drivers');
    }

    public copyBarcode($event, barcode) {
        this.sharedService.copyMessage($event, null, barcode, 'تم نسخ باركود الفرع بنجاح');
    }
}
