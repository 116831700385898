import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import {UserService} from '../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscriptionTrackerComponent} from '../../behavior/subscription-tracker.component';
import {COUNTRY_CODE_CURRENCY_MAP} from '../../services/locale_constants/constants';
import {AuthenticationService} from '../../services/authentication.service';
import {Country} from 'ngx-intl-tel-input/lib/model/country.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() formControlNamePass: any;
    @Input() formControlPass;
    @Input() formGroupPass: any;
    @Input() value: any;
    @Input() cssClassPass: any;
    @Input() component;
    @Input() label: string;

    @ViewChild('intlTelInput') intlTelInput: NgxIntlTelInputComponent;

    private currency: string;
    private companyId: any;
    private oldValue: any;
    public selectedCountry: any;
    public CountryISO = CountryISO;
    public internationalPhone = true;
    public SearchCountryField = SearchCountryField;
    public form: FormGroup;
    public selectedLanguage: any;

    constructor(private userService: UserService,
                private auth: AuthenticationService,
                private changeDetectionRef: ChangeDetectorRef,
                private translateService: TranslateService,
                @Inject(FormBuilder) private formsBuilder: FormBuilder) {
        super();
        this.selectedLanguage = this.translateService.currentLang;
    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            if (this.intlTelInput && this.intlTelInput.writeValue) {
                this.intlTelInput.writeValue({number: this.formControlPass.value});
                this.formControlPass.markAsUntouched();
            }
            this.changeDetectionRef.detectChanges();
        }, 300);

    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    ngOnInit(): void {
        this.currency = this.userService.getCurrency();
        this.companyId = this.auth.companyId;
        this.form = this.formsBuilder.group({
            phone: [{value: {number: this.formControlPass.value}}],
        });
        this.value = this.formControlPass.value;
        this.internationalPhone = this.userService.userInfo.isSupportInternationalPhoneNumbers;
        this.selectedCountry = null;

        // tslint:disable-next-line:forin
        for (const key in COUNTRY_CODE_CURRENCY_MAP) {
            const value = COUNTRY_CODE_CURRENCY_MAP[key];

            if (value === this.currency) {
                this.selectedCountry = key.toString().toLocaleLowerCase();
                break;
            }
        }
    }

    onValueChange(value) {
        try {
        const validatorsR = [];
        if (this.formControlPass && this.formControlPass._rawValidators && this.formControlPass._rawValidators.length) {
            this.formControlPass._rawValidators.forEach(f => {
                if (f.name && f.name !== 'bound checkPhonePattern') {
                    validatorsR.push(f);
                }
            });
        }
        if (value && value.number) {
            this.oldValue = value;
            let newValue = value.e164Number || value.number;
            const nationalNumber = COUNTRY_CODE_CURRENCY_MAP[value.countryCode] === this.currency;
            if (nationalNumber) {
                if (!value.number) {
                    this.formControlPass.patchValue('');
                    return;
                }
                newValue = (value.number + '').replace(value.dialCode, '');
            }
            validatorsR.push(this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144 || !nationalNumber, COUNTRY_CODE_CURRENCY_MAP[value.countryCode], this.component)));

            this.formControlPass.setValidators(validatorsR);
            this.formControlPass.patchValue(newValue);
            this.formControlPass.markAsTouched();
            this.formControlPass.updateValueAndValidity();
            this.formGroupPass.updateValueAndValidity();
        } else if (!value && this.oldValue != null) {
            this.oldValue = {};
            this.formControlPass.patchValue(value);
        } else {
            this.oldValue = {};
            validatorsR.push(this.userService.checkPhonePattern.bind(this,this.userService.getPhonePattern(false, undefined, this.component)));
            this.formControlPass.setValidators(validatorsR);
            if (value && value.target && value.target.value) {
                this.formControlPass.patchValue(value.target.value);
                this.formControlPass.markAsTouched();
                this.formControlPass.updateValueAndValidity();
                this.formGroupPass.updateValueAndValidity();
            }
        }} catch (e) {
        }
    }

    onCountryChanged($event: Country) {
        if (this.oldValue) {
            const newValue = (this.oldValue.e164Number || '').replace(this.oldValue.dialCode, '+' + $event.dialCode);
            this.formControlPass.patchValue(newValue);
            this.intlTelInput.writeValue({number: newValue});
        }
    }

    clear() {
        if (this.internationalPhone) {
            this.intlTelInput.writeValue({number: ''});
            this.formControlPass.updateValueAndValidity();
            this.formControlPass.markAsUntouched();
        }
    }

    focusout() {
        this.formControlPass.markAsTouched();
        this.formControlPass.updateValueAndValidity();
    }
}
