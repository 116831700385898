import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {NgModule} from '@angular/core';
import {TableModule} from 'primeng/table';

import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {SharedModule} from '../shared/modules/shared.module';
import {DistributorPackageFormComponent} from './distributor-package-form.component';
import {PackageContentsListComponent} from './package-contents-list/package-contents-list.component';


@NgModule({
    declarations: [
        DistributorPackageFormComponent,
        PackageContentsListComponent
    ],
    imports: [
        SharedModule,
        

        DropdownModule,
        CheckboxModule,
        CalendarModule,
        InputSwitchModule,
        TableModule,
    ],
    exports: [
        DistributorPackageFormComponent,
        PackageContentsListComponent
    ],
    providers: []
})
export class DistributorPackageFormModule {}
