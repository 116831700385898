import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HubModel} from '../../../shared/models/hub.model';

@Component({
    selector: 'app-branch-item-component',
    templateUrl: 'branch-item.component.html',
    styleUrls: ['branch-item.component.scss']
})
export class BranchItemComponent implements OnInit{
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
    // Inputs
    @Input() hub: HubModel;
    @Input() selectedHubs = {};

    // Outputs
    @Output() onRemoveHub: EventEmitter<{ hub: HubModel }> = new EventEmitter<{ hub: HubModel }>();
    @Output() onEditHub: EventEmitter<{ hub: HubModel }> = new EventEmitter<{ hub: HubModel }>();
    @Output() manageShelf: EventEmitter<{ hub: HubModel }> = new EventEmitter<{ hub: HubModel }>();

    public removeHub() {
        this.onRemoveHub.emit({hub: this.hub});
    }

    public modifyHub() {
        this.onEditHub.emit({hub: this.hub});
    }

    public manageshelf() {
        this.manageShelf.emit({hub: this.hub});
    }
    public showBarcodeImage() {
        window.open(this.hub.barcodeImage);
    }
}
