import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/modules/shared.module';

import {AddPackageFormComponent} from './add-package-form.component';
import {PackagePrintDialogComponent} from '../shared/components/package-print-dialog/package-print-dialog.component';
import {AddLclItemsComponent} from './add-lcl-items/add-lcl-items.component';
import {TableModule} from 'primeng/table';
import {DistributorPackageFormModule} from '../distributor-package-form/distributor-package-form.module';
import { CreateSupplierComponent } from './create-supplier/create-supplier.component';
import {ToastModule} from 'primeng/toast';
import { PackageContentLastMileComponent } from './package-content-last-mile/package-content-last-mile.component';
import { AddPackageFormItemComponent } from './add-package-form-item/add-package-form-item.component';

@NgModule({
    declarations: [
        AddPackageFormComponent,
        PackagePrintDialogComponent,
        AddLclItemsComponent,
        CreateSupplierComponent,
        PackageContentLastMileComponent,
        AddPackageFormItemComponent
    ],
    imports: [
        SharedModule,
        TableModule,
        DistributorPackageFormModule,
        ToastModule
    ],
    exports: [
        AddPackageFormComponent,
        PackagePrintDialogComponent
    ]
})
export class AddPackageFormModule {}
