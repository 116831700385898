import {Component, Input, OnInit} from '@angular/core';
import {PackageModel} from '../../shared/models/package.model';
import {ShipmentsService} from '../services/shipments.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-cod-request-change-component',
    templateUrl: 'cod-request-change.component.html',
    styleUrls: ['cod-request-change.component.scss']
})
export class CodRequestChangeComponent implements OnInit{
    @Input() pkg: PackageModel;
    public isSubmitting = false;
    public currentLang = '';

    constructor(private shipmentService: ShipmentsService,
        public activeModal: NgbActiveModal,
        private translateService: TranslateService) {
    }
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }

    public approveCodChange() {
        this.isSubmitting = true;
        this.shipmentService.approveCodChange(this.pkg.codChangeRequest.id).subscribe(
            (response) => {
                this.isSubmitting = false;
                this.pkg.codChangeRequest.status = 'APPROVED';
                this.activeModal.close({isSuccess: true, state: 'accept'});
            }, (error) => {
                this.isSubmitting = false;
                this.activeModal.close({isSuccess: false, state: 'accept'});
            }
        );
    }

    public rejectCodChange() {
        this.isSubmitting = true;
        this.shipmentService.rejectCodChange(this.pkg.codChangeRequest.id).subscribe(
            (response) => {
                this.isSubmitting = false;
                this.pkg.codChangeRequest.status = 'REJECTED';
                this.activeModal.close({isSuccess: true, state: 'reject'});
            }, (error) => {
                this.isSubmitting = false;
                this.activeModal.close({isSuccess: false, state: 'reject'});
            }
        );
    }
    public getMessage(message, oldCod, newCod) {
        return message.replace('{OldCod}', oldCod).replace('{NewCod}', newCod);
    }
}
