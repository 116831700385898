import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PackageModel} from '../../shared/models/package.model';
import {HubsService} from '../../shared/services/hubs.service';
import {DriversService} from '../../shared/services/drivers.service';
import {ShipmentsService} from '../services/shipments.service';
import {TranslateService} from '@ngx-translate/core';
import {
    IS_TO_FINAL_DESTINATION,
    OPTIONAL_IS_FAILED, REMOVE_HUBS_AND_DRIVERS,
    REQUIRE_DELIVERY_DATE,
    REQUIRE_DRIVER,
    REQUIRE_HUB,
    REQUIRE_HUB_DRIVER_SELECTION, REQUIRE_IS_DELIVERED_SELECTION,
    REQUIRE_POSTPONED_DELIVERY_DATE,
    REQUIRE_SHELF, SPECIAL_STATUS_FOR_HUBS_ACCOUNTING
} from '../services/constants';
import {UserService} from '../../shared/services/user.service';
import {ChangePackageStatusService} from '../services/change-package-status.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import * as _ from 'lodash';

@Component({
    selector: 'app-change-status-form',
    templateUrl: './change-status-form.component.html',
    styleUrls: ['./change-status-form.component.scss']
})
export class ChangeStatusFormComponent implements OnInit {
    @Input() shipment: PackageModel = null;
    @Input() shipments: PackageModel[] = [];
    @Input() form: FormGroup;
    @Output() formChange = new EventEmitter();
    @ViewChild('driversAc') driversAc;
    public requireHub = REQUIRE_HUB;
    public requireDriver = REQUIRE_DRIVER;
    public requireHubDriverSelection = REQUIRE_HUB_DRIVER_SELECTION;
    public requireShelf = REQUIRE_SHELF;
    public requirePostponedDeliveryDate = REQUIRE_POSTPONED_DELIVERY_DATE;
    public requireDeliveryDate = REQUIRE_DELIVERY_DATE;
    public requireIsDeliveredSelection = REQUIRE_IS_DELIVERED_SELECTION;
    public requireIsFailedSelection = OPTIONAL_IS_FAILED;
    public isToFinalDestinationStatus = IS_TO_FINAL_DESTINATION;
    public removeHubsAndDrivers = REMOVE_HUBS_AND_DRIVERS;
    public isLoading = false;
    public statusOptions: any[];
    public today = new Date();
    public hubsOptions: { value: string, label: string }[];
    public drivers: { label: string, value: any }[] = [];
    public shelves: { label: string, value: any }[] = [];
    public enumNote: { label: string, value: string }[] = [];
    public currentLang = '';
    public isHideDeliveredToSenderInChangeStatus = false;
    public isLcl = false;
    public companyHadStations = [256, 22];
    public companyId;
    public isSaudiCompanies = false;
    public maxDate = new Date();
    attachment: any = false;
    Companies = Companies;
    isSupportDeliveringPackageItemsPartially = false;
    companies = Companies;
    digitFormat = '1.0-2';

    constructor(private hubsService: HubsService,
                private driversService: DriversService,
                private changePackageStatusService: ChangePackageStatusService,
                private authService: AuthenticationService,
                @Inject(FormBuilder) private formBuilder: FormBuilder,
                private shipmentService: ShipmentsService,
                private translate: TranslateService,
                public customizationCompaniesService: CustomizationCompanyService,
                private userService: UserService) {
        this.isSupportDeliveringPackageItemsPartially = this.userService.userInfo.isSupportDeliveringPackageItemsPartially;

    }

    onClickRadioButton(e) {
        this.form.controls.notes.setValue(e.label);
    }

    ngOnInit() {
        this.companyId = this.authService.companyId;
        this.isSaudiCompanies = this.userService.userInfo.currency === 'SAR';
        this.getFailurResonse();
        this.isHideDeliveredToSenderInChangeStatus = this.userService.userInfo.isHideDeliveredToSenderInChangeStatus;
        this.currentLang = this.translate.currentLang;
        this.isLcl = this.userService.userInfo.isLcl;
        if ((this.shipment == null) && this.shipments.length !== 0) {
            this.initShipment();
        }
        this.initializeForm();
        if (this.shipment) {
            this.updateForm(this.shipment.status);
            this.getShelves(this.shipment.hubId);
        }

        this.isLoading = true;
        this.formChange.emit(this.form);
        Promise.all([this.fetchPackageStats(), this.initHubs()]).then(() => {
            this.isLoading = false;
        }).catch(() => {
            this.isLoading = false;
        });
        if (this.isSupportDeliveringPackageItemsPartially) {
            this.shipment?.packageItemsToDeliverList.forEach(x => {
                x.isSelected = x.status === 'DELIVERED';
            });
        }
    }

    private initializeForm() {
        if (this.shipment) {
            this.form = this.formBuilder.group({
                status: [this.shipment.status, Validators.required],
                driverId: this.shipment.driverId,
                hubId: this.shipment.hubId ? this.shipment.hubId : null,
                shelfId: this.shipment.shelfId,
                postponedDate: this.shipment.postponedDate ? new Date(this.shipment.postponedDate) : new Date(),
                deliveryDate: this.shipment.deliveryDate ? new Date(this.shipment.deliveryDate) : null,
                deliveredType: this.shipment.driverId ? 'driver' : 'hub',
                isDelivered: this.shipment.isDelivered ? this.shipment.isDelivered : false,
                notes: '',
                barcodes: [null],
                isFailed: [false],
                isToFinalDestination: [false],
                failuresNumber: [0],
                failureReasonStatus: '',
                isReceiverPaidCost: false,
                attachment: []
            });
        } else {
            this.form = this.formBuilder.group({
                status: [null, Validators.required],
                driverId: null,
                hubId: null,
                shelfId: null,
                postponedDate: new Date(),
                deliveryDate: null,
                deliveredType: 'hub',
                notes: '',
                failureReasonStatus: '',
                isDelivered: false,
                barcodes: [null, Validators.compose([Validators.required])],
                isFailed: [false],
                isToFinalDestination: [false],
                failuresNumber: [0],
                isReceiverPaidCost: false,
                attachment: []
            });
        }
    }

    private initShipment() {
        this.shipment = this.shipments[0];
    }

    private fetchPackageStats() {
        const excludeStatus = ['ALL', 'TRANSFERRED_OUT', 'SWAPPED',
            'DRAFT', 'BROUGHT', 'COMPLETED', ...SPECIAL_STATUS_FOR_HUBS_ACCOUNTING];
        if (this.userService.userInfo.isLcl && !this.userService.userInfo.isReceivingPkgsWithoutScanItems) {
            excludeStatus.push(...['CONTAINER_UNLOADED_AND_CLEARED_OUT',
                'SCANNED_BY_HANDLER_AND_UNLOADED']);
        }
        if (this.authService.companyId === 20) { // ghost company
            excludeStatus.push('REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT');
        }

        if (this.userService.userInfo.hasThirdParty) {
            excludeStatus.push('EXPORTED_TO_THIRD_PARTY');
        }
        this.statusOptions = _.cloneDeep(this.changePackageStatusService.getPackageStatusList('', excludeStatus, this.shipment?.isBundle && this.customizationCompaniesService.checkCompanies(this.companies.SPRINT)));
    }

    public updateForm(status) {
        if (REQUIRE_SHELF.indexOf(status) !== -1) {
            this.setRequired(['hubId', 'shelfId']);
        } else if (REQUIRE_HUB.indexOf(status) !== -1) {
            this.setRequired(['hubId']);
        } else if (this.companyHadStations.indexOf(this.companyId) === -1 && REQUIRE_DRIVER.indexOf(status) !== -1 && !this.isLcl) {
            this.setRequired(['driverId']);
        } else if (REQUIRE_HUB_DRIVER_SELECTION.indexOf(status) !== -1) {
            if (status === 'RETURNED_BY_RECIPIENT' && this.form.value.isDelivered) {
                this.setRequired(['notes', 'deliveryDate']);
            } else {
                if (this.form.value.deliveredType === 'hub') {
                    this.setRequired(['notes', 'hubId']);
                    this.form.get('driverId').clearValidators();
                    this.form.get('driverId').reset();
                } else {
                    this.setRequired(['notes', 'driverId']);
                    this.form.get('hubId').clearValidators();
                    this.form.get('hubId').reset();
                }
            }
        } else if (REQUIRE_POSTPONED_DELIVERY_DATE.indexOf(status) !== -1) {
            if (this.form.value.deliveredType === 'hub') {
                this.setRequired(['postponedDate', 'notes', 'hubId']);
            } else {
                this.setRequired(['postponedDate', 'notes', 'driverId']);
            }
        } else if (REQUIRE_DELIVERY_DATE.indexOf(status) !== -1 && status !== 'RETURNED_BY_RECIPIENT') {
            this.setRequired(['deliveryDate']);
            if (this.isCod) {
                if (this.form.value.deliveredType === 'hub' && this.companyHadStations.indexOf(this.companyId) !== -1) {
                    this.setRequired(['deliveryDate']);
                } else if (this.form.value.deliveredType === 'hub' && this.companyHadStations.indexOf(this.companyId) === -1) {
                    this.setRequired(['deliveryDate', 'hubId']);
                } else {
                    this.setRequired(['deliveryDate', 'driverId']);
                }
            } else {
            }
        } else {
            this.setRequired([]);
        }
        this.form.updateValueAndValidity();
    }

    get isCod() {
        return this.shipment && this.shipment.shipmentType === 'COD';
    }

    setRequired(keys) {
        keys.map(key => {
            this.form.controls[key].setValidators([Validators.required]);
            this.form.controls[key].updateValueAndValidity();
        });

        ['hubId', 'shelfId', 'driverId', 'postponedDate', 'deliveryDate', 'notes'].map(key => {
            if (keys.indexOf(key) === -1) {
                this.form.controls[key].setValidators([]);
            }
            if (['postponedDate', 'deliveryDate'].indexOf(key) !== -1) {
                if (this.shipment && this.shipment[key]) {
                    this.form.controls[key].setValue(new Date(this.shipment[key]));
                } else if (!this.form.controls[key].value) {
                    this.form.controls[key].setValue(new Date());
                }
            }
            this.form.controls[key].updateValueAndValidity();
        });
    }

    public initHubs() {
        return this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }
        );
    }

    public getShelves(hubId) {
        if (hubId) {
            this.hubsService.getShelves(hubId, '?page=1&pageSize=500').subscribe((res: any) => {
                this.shelves = res.data.map(shelf => {
                    const name = [shelf.destinationRegion, shelf.destinationCity, shelf.label].filter(label => !!label).join(', ');
                    return {label: name, value: shelf.id};
                });
            });
        }
    }

    public getFailurResonse() {
        this.driversService.getDriverReturnedStatus('?is-returned=true').subscribe(
            (res: any) => {
                Object.keys(res).forEach(
                    (key: string) => {
                        this.enumNote.push({value: key, label: res[key]});
                    });
            }
        );
    }

    public getBackgroundColor(type) {
        if (type) {
            return this.shipmentService.getShipmentStatusColor(type.toUpperCase());
        }
    }

    public setDeliveredTypeTo(deliveredType) {
        this.form.controls['deliveredType'].patchValue(deliveredType);
        this.updateForm(this.form.value.status);
    }
    getDrivers(event) {
        let query = '?page=1&pageSize=20';
        if (event.query) {
            query += '&search=' + event.query;
        }

        if (this.customizationCompaniesService.checkCompanies(this.Companies.JETT) && this.userService.userInfo.role === 'CLERK') {
            query += '&isShowHubDrivers=true';
        }

        return this.driversService.getDrivers(query).subscribe(
            (res: any) => {
                this.drivers = res.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {label: name, value: driver.id};
                    }
                );
                if (!this.driversAc.overlayVisible) {
                    this.driversAc.overlayVisible = true;
                }

            });
    }
    hideDriversList() {
        if (this.driversAc.overlayVisible) {
            this.driversAc.overlayVisible = false;
        }
    }

    removeAttachment(attachment: any) {
        this.attachment = false;
        this.form.get('attachment').patchValue(this.attachment);
    }

    fileChange($event: any) {
        this.attachment = $event.target.files[0];
        this.form.get('attachment').patchValue(this.attachment);
    }

    onChange(content: any, $event: any) {
        content.isSelected = !content.isSelected;
    }
}
