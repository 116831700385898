import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';
import {ReportsService} from '../../../services/reports';

@Component({
    selector: 'app-undelivered-packages-detailed-report',
    templateUrl: './undelivered-packages-detailed-report.component.html',
    styleUrls: ['./undelivered-packages-detailed-report.component.css']
})
export class UndeliveredPackagesDetailedReportComponent {
    public undeliveredPackagesReport = REPORTS_CONSTANTS.DETAILED_REPORTS.UNDELIVERED_PACKAGES_REPORT;
    public undeliveredPackagesReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.UNDELIVERED_PACKAGES_REPORT_API;
    public formatUndeliveredPackagesData;

    constructor(public reportsService: ReportsService) {
        this.formatUndeliveredPackagesData = this.reportsService.formatUndeliveredPackagesData.bind(this);
    }
}
