import {IdleCustomersReportComponent} from './components/detailed-reports/idle-customers-report/idle-customers-report.component';
import {SaasThemeComponent} from './components/saas-theme/saas-theme.component';
import {HubsMassCodReportsComponent} from './components/hubs-accounting/hubs-mass-cod-reports/hubs-mass-cod-reports.component';
import {RoleGuard} from '../shared/guards/role.guard';
import {CodCustomerComponent} from './components/cod-customer/cod-customer.component';
import {PricingTypeComponent} from '../shippings-rate/components/pricing-type/pricing-type.component';
import {PricingsComponent} from '../pricings/pricings.component';
import {CustomersPricingTableComponent} from '../shippings-rate/components/customers-pricing-table/customers-pricing-table.component';
import {ZonesFromPackagesSummaryReportComponent} from './components/summary-reports/zones-from-packages-summary-report/zones-from-packages-summary-report.component';
import {AllCodCustomersComponent} from './components/all-cod-customers/all-cod-customers.component';
import {FinancialReportComponent} from '../financial-report/financial-report.component';
import {DriverPackagesSummaryReportComponent} from './components/summary-reports/driver-packages-summary-report/driver-packages-summary-report.component';
import {RouterModule, Routes} from '@angular/router';
import {ZonesToPackagesSummaryReportComponent} from './components/summary-reports/zones-to-packages-summary-report/zones-to-packages-summary-report.component';
import {DriversEarningsComponent} from '../shippings-rate/components/drivers-earnings/drivers-earnings.component';
import {ShippingLinesComponent} from './components/shipping-lines/shipping-lines.component';
import {CustomerPackagesSummaryReportComponent} from './components/summary-reports/customer-packages-summary-report/customer-packages-summary-report.component';
import {WorkZoneComponent} from '../shippings-rate/components/work-zone/work-zone.component';
import {HubsAccountingDashboardComponent} from './components/hubs-accounting/hubs-accounting-dashboard/hubs-accounting-dashboard.component';
import {ManageZonesComponent} from './components/manage-zones/manage-zones.component';
import {CompanyConfigurationNewComponent} from '../companies/company-configuration-new/company-configuration-new.component';
import {DriverPackagesDetailedReportComponent} from './components/detailed-reports/driver-packages-detailed-report/driver-packages-detailed-report.component';
import {ManageParcelComponent} from './components/manage-parcel/manage-parcel.component';
import {TimePeriodPackagesAdministrativeReportComponent} from './components/administrative-reports/time-period-packages-administrative-report/time-period-packages-administrative-report.component';
import {PrintedReportsComponent} from '../users/printed-reports/printed-reports.component';
import {CodCustomersComponent} from './components/cod-customers/cod-customers.component';
import {AccountComponent} from '../account/account.component';
import {SortPackagesTableComponent} from '../shipment/sort-packages-table/sort-packages-table.component';
import {ZonesPackagesSummaryReportComponent} from './components/summary-reports/zones-packages-summary-report/zones-packages-summary-report.component';
import {UndeliveredPackagesDetailedReportComponent} from './components/detailed-reports/undelivered-packages-detailed-report/undelivered-packages-detailed-report.component';
import {SummaryReportsComponent} from './components/summary-reports/summary-reports.component';
import {ShippedPackagesDetailedReportComponent} from './components/detailed-reports/shipped-packages-detailed-report/shipped-packages-detailed-report.component';
import {ReceivePackagesTableComponent} from '../shipment/receive-packages-table/receive-packages-table.component';
import {NotificationsSettingsComponent} from '../company-notifications/notifications-settings/notifications-settings.component';
import {CustomerActivityReportComponent} from './components/administrative-reports/customer-activity-report/customer-activity-report.component';
import {PackageHistoryComponent} from '../shipment/package-history/package-history.component';
import {CodMismatchComponent} from '../shipment/cod-mismatch/cod-mismatch.component';
import {HubsCustodyComponent} from '../shipment/hubs-custody/hubs-custody.component';
import {CityToUndeliveredCodSummaryReportComponent} from './components/summary-reports/city-to-undelivered-cod-summary-report/city-to-undelivered-cod-summary-report.component';
import {PricingListsComponent} from '../shippings-rate/components/pricing-lists/pricing-lists.component';
import {AdministrativeReportsComponent} from './components/administrative-reports/administrative-reports.component';
import {DelayedPackagesReportComponent} from '../shipment/delayed-packages-report/delayed-packages-report.component';
import {NewCompanyComponent} from '../companies/new-company/new-company.component';
import {UndelivredPackagesSummaryReportComponent} from './components/summary-reports/undelivered-packages-summary-report/undelivered-packages-summary-report.component';
import {ActivityReportComponent} from './components/administrative-reports/activity-report/activity-report.component';
import {CustomerPackagesDetailedReportComponent} from './components/detailed-reports/customer-packages-detailed-report/customer-packages-detailed-report.component';
import {BankTransfersComponent} from './bank-transfers/bank-transfers.component';
import {ExpensesComponent} from '../shipment/expenses/expenses.component';
import {ShippingsRateComponent} from '../shippings-rate/shippings-rate.component';
import {ManageCompanyComponent} from './components/manage-company/manage-company.component';
import {DetailedReportsComponent} from './components/detailed-reports/detailed-reports.component';
import {DistributorPackagesContentsReportComponent} from './components/distributor-packages-contents-report/distributor-packages-contents-report.component';
import {DriversEarningsReportComponent} from './components/drivers-earnings-report/drivers-earnings-report.component';
import {DamagedPackagesReportComponent} from './components/damaged-packages-report/damaged-packages-report.component';
import {TripsBetweenCitiesComponent} from '../trips-between-cities/trips-between-cities.component';
import {TripsBetweenCitiesV1Component} from '../trips-between-cities/trips-between-cities-v1/trips-between-cities-v1.component';
import {CodMismatchAllPackageComponent} from '../shipment/cod-mismatch-all-package/cod-mismatch-all-package.component';

import {ReceiveCodCustomersComponent} from './components/receive-cod-customers/receive-cod-customers.component';
import {ExportedCodComponent} from './components/exported-cod/exported-cod.component';
import {InvoicesComponent} from './components/invoices/invoices.component';
import {DeliveredCodComponent} from './delivered-cod/delivered-cod.component';
import {InCarCodComponent} from './components/in-car-cod/in-car-cod.component';
import {NgModule} from '@angular/core';
import {CustomersWalletsComponent} from './components/customers-wallets/customers-wallets.component';
import {MassInvoicesComponent} from './components/mass-invoices/mass-invoices.component';
import {MassDetailsInvoicesComponent} from './components/mass-details-invoices/mass-details-invoices.component';
import {AdditionalFeesComponent} from '../fulfilment/additional-fees/additional-fees/additional-fees.component';

const routes: Routes = [
    {
        path: 'manage-zones',
        component: ManageZonesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'shipping-lines',
        component: ShippingLinesComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'packages-contents-report',
        component: DistributorPackagesContentsReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-cod-customers',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'damaged-packages',
        component: DamagedPackagesReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-cod-customers/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports',
        component: DetailedReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'detailed-reports/idle-customers-report',
        component: IdleCustomersReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports/delayed-packages-report',
        component: DelayedPackagesReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports/driver-packages-detailed-report',
        component: DriverPackagesDetailedReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports',
        component: SummaryReportsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'administrative-reports',
        component: AdministrativeReportsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'account-settings',
        component: AccountComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-parcel',
        component: ManageParcelComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'company-settings',
        canActivate: [RoleGuard],
        component: ManageCompanyComponent,
        children: [
            {
                path: '',
                redirectTo: 'basic-info',
                pathMatch: 'full'
            },
            {
                path: 'basic-info',
                component: NewCompanyComponent,
                data: {
                    isEdit: true,
                    isGuest: false,
                    isModal: false,
                    isAuth: true,
                }
            },
            {
                path: 'theme',
                component: SaasThemeComponent,
            },
            {
                path: 'notification-settings',
                component: NotificationsSettingsComponent
            }
        ]
    },
    {
        path: 'company-configuration',
        component: CompanyConfigurationNewComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-cod',
        component: ReceiveCodCustomersComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'exported-packages',
        component: ExportedCodComponent,
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'exported-packages',
    //     component: CodCustomersComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'exported-packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'sort-cod',
        component: ReceiveCodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'sort-cod/:id',
        component: SortPackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'sort-cod/packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'delivered-packages',
        component: DeliveredCodComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'archived-mass-cod-report',
        component: CodCustomersComponent,
        canActivate: [RoleGuard],
        data: {
            isArchived: true
        }
    },
    {
        path: 'delivered-packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'all-cod-customers',
        component: AllCodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'in-car-packages',
        component: InCarCodComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-without-custody',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'in-car-packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-mismatch/packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'printed-reports',
        component: PrintedReportsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'printed-reports-new',
        component: PrintedReportsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-mismatch',
        component: CodMismatchComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-mismatch-all-packages',
        component: CodMismatchAllPackageComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'hubs-accounting-dashboard',
        component: HubsAccountingDashboardComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'expenses',
        component: ExpensesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'financial-report',
        component: FinancialReportComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'bank-transfers',
        component: BankTransfersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'bank-transfers/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path : 'pricing-service-types',
        component: PricingsComponent,
        canActivate: [RoleGuard],
    },
    {
        path : 'additional-fees-insurance',
        component: AdditionalFeesComponent,
        canActivate: [RoleGuard],
        data: {
            type: 'INSURANCE',
        }
    },
    {
        path : 'additional-fees-cod',
        component: AdditionalFeesComponent,
        canActivate: [RoleGuard],
        data: {
            type: 'COD',
        }
    },
    {
        path : 'pricing-service-types/:id',
        component: PricingsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'pricing-type',
        canActivate: [RoleGuard],
        component: PricingTypeComponent,
        children: [
            {
                path: '',
                redirectTo: 'customers-pricing',
                pathMatch: 'full'
            },
            {
                path: 'customers-pricing',
                component: CustomersPricingTableComponent,
                canActivate: [RoleGuard],
            },
            {
                path: 'pricing-lists',
                component: PricingListsComponent
            }
        ]
    },
    {
        path : 'trips-between-cities-v1',
        component: TripsBetweenCitiesV1Component,
        canActivate: [RoleGuard],
    },
    {
        path : 'trips-between-cities',
        component: TripsBetweenCitiesComponent,
        canActivate: [RoleGuard],
    },
    {
        path : 'customers-wallets',
        component: CustomersWalletsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'hubs-mass-cod-reports',
        component: HubsMassCodReportsComponent,
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                redirectTo: 'received',
                pathMatch: 'full'
            },
            {
                path: 'received',
                component: HubsMassCodReportsComponent
            },
            {
                path: 'exported',
                component: HubsMassCodReportsComponent
            },
            {
                path: 'delivered',
                component: HubsMassCodReportsComponent
            }
        ]
    },
    {
        path: 'drivers-earnings',
        component: DriversEarningsComponent,
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                redirectTo: 'work-zone',
                pathMatch: 'full'
            },
            {
                path: 'shipping-rates',
                component: ShippingsRateComponent,
                data: {
                    isDriverWorkArea: true,
                }
            },
            {
                path: 'work-zone',
                component: WorkZoneComponent
            }
        ]
    },
    {
        path: 'summary-reports/customer-packages-summary-report',
        component: CustomerPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/driver-packages-summary-report',
        component: DriverPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/zones-from-packages-summary-report',
        component: ZonesFromPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/zones-to-packages-summary-report',
        component: ZonesToPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/city-to-cod-summary-report',
        component: CityToUndeliveredCodSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/zones-packages-summary-report',
        component: ZonesPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/undelivered-packages-summary-report',
        component: UndelivredPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports/shipped-packages-detailed-report',
        component: ShippedPackagesDetailedReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports/undelivered-packages-detailed-report',
        component: UndeliveredPackagesDetailedReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'administrative-reports/time-period-packages-administrative-report',
        component: TimePeriodPackagesAdministrativeReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'administrative-reports/customer-activity-administrative-report',
        component: CustomerActivityReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'administrative-reports/activity-administrative-report',
        component: ActivityReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'drivers-pricing',
        component: CustomersPricingTableComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'drivers-earnings-report',
        component: DriversEarningsReportComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'receive-cod/packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-cod/:barcode',
        component: ReceivePackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-cod/package-history/:id',
        component: PackageHistoryComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'invoices/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'mass-invoices',
        component: MassInvoicesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'mass-invoices/:id',
        component: MassDetailsInvoicesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports',
        component: DetailedReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'detailed-reports/customer-packages-detailed-report',
        component: CustomerPackagesDetailedReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports/idle-customers-report',
        component: IdleCustomersReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'hub-custody',
        component: HubsCustodyComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'hub-custody/:barcode',
        component: ReceivePackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'shipping-rates',
        component: ShippingsRateComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'shipping-rates/:id',
        component: ShippingsRateComponent,
        canActivate: [RoleGuard],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdministrationRoutingModule {}
