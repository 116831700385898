<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="cod-customers-view d-flex flex-column h-100">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div>
        <div class="header-filters d-flex flex-row"
             style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
            <div class="cod-title flex-1">
                {{"ACCOUNTING.RECEIVED_MONEY" | translate}}: {{receivedMoney}}
            </div>
            <div class="flex-1 action-button add-button cursor-pointer cod-button"
                 (click)="addToHub()">
                {{"ACCOUNTING.ADD_TO_HUB_CUSTODY" | translate | hubTitle}}
            </div>
            <div class="">
                <div class="date-filter">
                    <div class="from-container cursor-pointer">
                        <i class="pi pi-calendar date-picker-icon"></i>
                        <p-calendar styleClass="date-picker" [(ngModel)]="fromDate" (onSelect)="onSearch()"
                                    placeholder="{{'MANAGE_SHIPMENTS.FROM' | translate}}"
                                    dateFormat="dd/mm/yy"></p-calendar>
                    </div>

                    <span> - </span>
                    <div class="to-container cursor-pointer">
                        <i class="pi pi-calendar date-picker-icon"></i>
                        <p-calendar styleClass="date-picker" [(ngModel)]="toDate" (onSelect)="onSearch()"
                                    placeholder="{{'MANAGE_SHIPMENTS.TO' | translate}}"
                                    dateFormat="dd/mm/yy"></p-calendar>
                    </div>

                </div>
            </div>
            <div class="">
                <div class="search-bar">
                    <div class="search-icon">
                        <i class="fas fa-search" [ngClass]="{'fa-rotate-90': selectedLanguage==='ar'}"></i>
                    </div>
                    <div class="search-input">
                        <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent"
                               (input)="onSearch()">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cod-customers-table flex-1 overflow-auto">
        <p-table #packagesTable dataKey="id" #table [value]="packages"
                 styleClass="customers-table flex-table" [rowsPerPageOptions]="rowsPerPageOptions"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="10" [totalRecords]="totalRecords">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;"> {{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th style="width: 150px;"> {{"ACCOUNTING.COD_BARCODE" | translate}}</th>
                    <th> {{"MANAGE_SHIPMENTS.INVOICE" | translate}}</th>
                    <th> {{"ACCOUNTING.SENDER_ADDRESS" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.SENDER_NAME" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.RECEIVER_NAME" | translate}}</th>
                    <th> {{"ADD_SHIPMENT.RECEIVER_ADDRESS" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.NOTES" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.COD" | translate}} </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr>
                    <td style="width: 150px;text-align: center" class="cursor-pointer">
                        <img height="50px" class="barcode-image" [src]="package.barcodeImage">
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode ? package.barcode : package.packageBarcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.barcode?package.barcode:package.packageBarcode)"></i>
                        </span>
                    </td>
                    <td style="width: 150px;text-align: center">
                        <img height="50px" class="barcode-image" [src]="package.codBarcodeImage">
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.codBarcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.codBarcode)"></i>
                        </span>
                    </td>
                    <td>{{package.invoiceNumber}}</td>
                    <td>{{package.senderCity}}</td>
                    <td>{{package.senderName}}</td>
                    <td>{{package.receiverName ? package.receiverName : package.receiver}}</td>
                    <td>{{package.destination ? package.destination : package.receiverCity}}</td>
                    <td>{{package.notes}}</td>
                    <td> {{package.cod }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
