import {Component, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-sub-customers-for-customer',
  templateUrl: './sub-customers-for-customer.component.html',
  styleUrls: ['./sub-customers-for-customer.component.scss']
})
export class SubCustomersForCustomerComponent implements OnInit {
  currentLang;
  historyData = [];
  isLoading = false;
  page = 0;
  pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
  rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
  first = 0;
  totalRecords = 0;
  @ViewChild('table') table;
  userId;
  isCustomer;

  constructor(private activeModal: NgbActiveModal,
              private translateService: TranslateService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
  }

  reset() {
    this.table.reset();
    this.historyData = [];
    this.page = 0;
  }

  public closeModal() {
    this.activeModal.close();
  }

  createParams() {
    return {pageSize: this.pageSize, page: this.page + 1};
  }

  getHistoryData() {
    this.isLoading = true;
    this.userService.getMembers({customerId: this.userId}).subscribe(
        (res: any) => {
          this.historyData = res.customers;
          this.isLoading = false;
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );

  }

  loadReportsLazy(event) {
    this.page = event.first / event.rows;
    this.pageSize = event.rows;
    this.first = event.first;
    this.getHistoryData();
  }

  getLocalTime(dateTime) {
    if (!dateTime) {
      return;
    }
    return new Date(dateTime).toLocaleTimeString();

  }
}
