import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {LoginService} from '../../../login/services/login.service';

@Component({
  selector: 'app-tracking-user-password',
  templateUrl: './tracking-user-password.component.html',
  styleUrls: ['./tracking-user-password.component.scss']
})
export class TrackingUserPasswordComponent implements OnInit {
  currentLang;
  historyData = [];
  isLoading = false;
  page = 0;
  pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
  rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
  first = 0;
  totalRecords = 0;
  @ViewChild('table') table;
  userId;
  isCustomer;

  constructor(private activeModal: NgbActiveModal,
              private translateService: TranslateService,
              private loginService: LoginService) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
  }

  reset() {
    this.table.reset();
    this.historyData = [];
    this.page = 0;
  }

  public closeModal() {
    this.activeModal.close();
  }

  createParams() {
    const params = {pageSize: this.pageSize, page: this.page + 1};
    return params;
  }

  getHistoryData() {
    this.isLoading = true;
    console.log(this.isCustomer);
    (this.isCustomer ? this.loginService.trackingCustomerPassword(this.userId, this.createParams()) : this.loginService.trackingUserPassword(this.userId, this.createParams())).subscribe(
        (res: any) => {
          this.historyData = res;
          this.isLoading = false;
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );

  }
  loadReportsLazy(event) {
    this.page = event.first / event.rows;
    this.pageSize = event.rows;
    this.first = event.first;
    this.getHistoryData();
  }

  getLocalTime (dateTime) {
    if (!dateTime) {
      return;
    }
    return new Date(dateTime).toLocaleTimeString();

  }

}
