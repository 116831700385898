import {Component, Input, OnInit} from '@angular/core';
import {Message} from 'primeng/api';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() messages: Message[];
  @Input() showTransitionOptions = '300ms ease-out';
  @Input() hideTransitionOptions = '200ms cubic-bezier(0.86, 0, 0.07, 1)';
  @Input() enableService = false;
  @Input() closable = true;
  @Input() style: string;
  @Input() styleClass: string;
  @Input() escape = true;
  @Input() key: string;
  @Input() lang = 'ar';

  constructor() { }

  ngOnInit(): void {
  }

}
