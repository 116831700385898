<div class="category-form-container" [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div class="close-icon-container cursor-pointer" (click)="onClose()">
        <img src="../../assets/icons/cancel.svg" alt="icon" />
    </div>
    <div class="category-form-header">
        <div class="title-header">
            <span>{{'TICKETING_SYSTEM.CATEGORIES.ADD_CATEGORY_GROUP' | translate}}</span>
        </div>
    </div>

    <div class="form-inputs-actions d-flex">
        <form [formGroup]="categoryForm" *ngIf="this.categoryForm">
            <div class="inputs-group-container">
                <div class="form-input-container category-name">
                    <div class="label">
                        <span>{{'TICKETING_SYSTEM.CATEGORIES.TABLE.CATEGORY_NAME' | translate}}</span>
                    </div>
                    <div class="input-value">
                        <input formControlName="name" type="text">
                    </div>
                </div>

                <div class="form-input-container description">
                    <div class="label">
                        <span>{{'TICKETING_SYSTEM.CATEGORIES.TABLE.DESCRIPTION' | translate}}</span>
                    </div>
                    <div class="input-value">
                        <textarea formControlName="description" class="category-description-textarea" [rows]="6" [cols]="30" pInputTextarea></textarea>
                    </div>
                </div>
            </div>
            <div class="flex-column">
                <div class="client-name-container">
                    <div class="client-name d-flex">
                        <div class="client-name-title">
                            <span>{{'TICKETING_SYSTEM.CATEGORIES.TABLE.CLIENT' | translate}}</span>
                        </div>
                        <div class="clients-drop-down flex-1">
                            <ng-select appendTo="body" [loading]="false" appDebounceSelectQuery
                                       maxSelectedLabels="0"
                                       [items]="usersList" bindValue="id" bindLabel="fullName" [multiple]="true"
                                       [closeOnSelect]="false" (add)="onSelect($event, true)" (remove)="onSelect($event, false)"
                                       loadingText="{{ 'TICKETING_SYSTEM.CATEGORIES.TABLE.CATEGORY_SEARCH' | translate }}"
                                       notFoundText="{{ 'TICKETING_SYSTEM.CATEGORIES.TABLE.CATEGORY_NOT_FOUND' | translate }}"
                                       (inputDebounced)="getClients($event)" formControlName="usersIds">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="client-name-container">
                    <div class="client-name d-flex">
                        <div class="client-name-title">
                            <span>{{'TICKETING_SYSTEM.CATEGORIES.TABLE.CLIENT_MANAGER' | translate}}</span>
                        </div>
                        <div class="clients-drop-down flex-1">
                            <p-dropdown
                                    styleClass="add-ticket-drop-down"
                                    [options]="selectedUsersList"
                                    [style]="{'width':'100%'}"
                                    appendTo="body"
                                    formControlName="selectedUserManagerId"
                                    filter="true">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="category-form-actions d-flex">
            <div class="cancel-action flex-2d-center cursor-pointer" (click)="onClose()">
                <span>{{'ACTIONS.CANCEL' | translate}}</span>
            </div>
            <div [ngClass]="{'disabled-btn disabled': isSubmittingForm || categoryForm?.invalid}" class="submit-action flex-2d-center cursor-pointer" (click)="submitForm()">
                <span>{{'ACTIONS.DONE' | translate}}</span>
            </div>
            <div *ngIf="isSubmittingForm" class="submit-loader flex-2d-center cursor-pointer">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
        </div>

    </div>
</div>
