import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
@Component({
    selector: 'app-mobile-cards-list',
    templateUrl: 'mobile-cards-list.component.html',
    styleUrls: ['mobile-cards-list.component.scss']
})
export class MobileCardsListComponent implements OnInit {
    @Input() list: any[];
    @Input() keysObj: {};
    @Input() actions: any[];
    currentLang;
    constructor(private datePipe: DatePipe, private translate: TranslateService) {}

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
    }
    getObjectKeys(item) {
        return Object.keys(item);
    }
    handleValue(key, value) {
        if (key.includes('date') || key.includes('Date')) {
            return this.transformDate(value);
        }
        return value;
    }

    transformDate(date) {
        if (date) {
            return this.datePipe.transform(date , 'dd/MM/yyyy');
        }
    }
}
