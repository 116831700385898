import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ContainerModel} from '../../../shared/models/container.model';
import {Router} from '@angular/router';
import {CONTAINERS_CONSTANTS} from '../../services/constants';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {DriverModel} from '../../../shared/models/driver.model';
import {ContainersService} from '../../services/containers.service';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-container-item-component',
    templateUrl: 'container-item.component.html',
    styleUrls: ['container-item.component.scss']
})
export class ContainerItemComponent implements OnInit {

    // Inputs
    @Input() container: ContainerModel;
    @Input() selectedContainers = {};

    @Input() removeCardLoader = false;
    @Input() getPackagesCountLoader = false;
    @Input() singlePrintLoader = false;

    // Outputs
    @Output() onRemoveContainer: EventEmitter<{ container: ContainerModel }> =
        new EventEmitter<{ container: ContainerModel }>();

    @Output() onModifyContainer: EventEmitter<{ container: ContainerModel }> =
        new EventEmitter<{ container: ContainerModel }>();

    @Output() onPrintContainer: EventEmitter<{ container: ContainerModel }> =
        new EventEmitter<{ container: ContainerModel }>();

    @Output() onToggleSelectState: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() onGetCount: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickCarriedPackages: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickDetails: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('rowActionsPopOver', {static: false}) rowActionsPopOver: NgbPopover;

    public currentLang = '';

    constructor(public router: Router,
                public authService: AuthenticationService,
                private translateService: TranslateService,
                private containerService: ContainersService) {
    }
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }
    public getDriverName(driverObject: DriverModel) {
        return this.containerService.getDriverName(driverObject);
    }
    public onPrint() {
        this.onPrintContainer.emit({container: this.container});
    }
    public onRemove() {
        this.onRemoveContainer.emit({container: this.container});
    }

    public onModify() {
        if (this.rowActionsPopOver && this.rowActionsPopOver.isOpen()) {
            this.rowActionsPopOver.close();
        }
        this.onModifyContainer.emit({container: this.container});
    }

    public viewContainerPackages() {
        this.router.navigate([this.authService.companyName + CONTAINERS_CONSTANTS.ROUTES.CONTAINER_PACKAGES, this.container.id]);
    }

    public onToggleSelect(state: boolean) {
        this.onToggleSelectState.emit(state);
    }
    public  getPackagesCount() {
        this.onGetCount.emit({container: this.container});
    }
    public onClickContainerCarriedPackages() {
        this.onClickCarriedPackages.emit({container: this.container});
    }
    public onClickContainerDetails() {
        this.onClickDetails.emit({container: this.container});
    }
}
