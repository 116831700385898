import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {ToastModule} from 'primeng/toast';
import {SharedModule} from '../shared/modules/shared.module';
import {RouterModule} from '@angular/router';

import {MenuItemComponent} from './menu-item/menu-item.component';
import {MessagingService} from '../shared/services/messaging.service';
import {ShippingsRateModule} from '../shippings-rate/shippings-rate.module';
import {HomeService} from './services/home.service';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {SideMenuItemComponent} from './side-menu/side-menu-item/side-menu-item.component';
import {AddPackageFormModule} from '../add-package-form/add-package-form.module';
import {ShipmentModule} from '../shipment/shipment.module';
import {DistributorPackageFormModule} from '../distributor-package-form/distributor-package-form.module';
import {SidebarModule} from 'primeng/sidebar';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputSwitchModule} from 'primeng/inputswitch';
import {NotificationsComponent} from './notifications/notifications.component';
import {AdministrationModule} from '../administration/administration.module';
import {TicketingSystemModule} from '../ticketing-system/ticketing-system.module';
import {HomeRoutingModule} from './routes/home.routes';

@NgModule({
    declarations: [
        NotificationsComponent,
        HomeComponent,
        MenuItemComponent,
        SideMenuComponent,
        SideMenuItemComponent
    ],
    exports: [
        HomeComponent
    ],
    imports: [
        HomeRoutingModule,
        AdministrationModule,
        SharedModule,
        
        ToastModule,
        RouterModule,
        ScrollPanelModule,
        ShippingsRateModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        SidebarModule,
        ConfirmDialogModule,
        InputSwitchModule,
        AddPackageFormModule,
        ShipmentModule,
        DistributorPackageFormModule,
        SidebarModule,
        TicketingSystemModule
    ],
    providers: [
        MessagingService,
        HomeService
    ]
})
export class HomeModule {
}
