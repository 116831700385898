import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipping-mechanisms',
  templateUrl: './shipping-mechanisms.component.html',
  styleUrls: ['./shipping-mechanisms.component.scss']
})
export class ShippingMechanismsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
