<app-accounting-template
        [title]="title"
        [apiUrl]="apiUrl"
        [tableInfo]="info"
        [type]="'MASS_INVOICE'"
        [rowActionsSettings]="rowActionsSettings"
        [topActionsSettings]="topActionsSettings"
        [filtersSettings]="filtersSettings"
        [userSumValueAPI]="false"
        [listValueKey]="'data'"
></app-accounting-template>
