import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UsersService} from '../../../users/services/users.service';
import {TicketingSystemService} from '../../services/ticketing-system.service';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NewUserComponent} from '../../../users/create-new-user/new-user.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as Compressor from 'compressorjs';
import {InputAsStringValidator} from '../../../shared/behavior/input-as-string.validator';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';


@Component({
    selector: 'app-add-ticket-form',
    templateUrl: 'add-ticket-form.component.html',
    styleUrls: ['add-ticket-form.component.scss']
})
export class AddTicketFormComponent implements OnInit {
    isLoading = false;
    spinnerState: SpinnerState;
    spinnerStates = SpinnerState;
    currentLang;
    isSubmittingTicket = false;
    uploadingAttachments = false;
    isEditMode = false;

    storesList = [];
    usersList = [];
    categoriesList = [];

    @Input() display: boolean;

    @Output() closeWidget = new EventEmitter();
    @Output() completeAddTicket = new EventEmitter();

    @ViewChild('StoreName') storeName;
    @ViewChild('user') user;
    @ViewChild('categoryName') categoryName;

    addTicketForm: FormGroup;

    selectedStoreInfo: any = {};
    selectedClient: any = {};
    selectedCategory: any = {};

    inValidStoreInfo = false;
    invalidCategory = false;
    inValidUser = false;

    editStore = false;
    editClient = false;
    editCategory = false;

    ticketPrioritiesList: { value: string, label: string }[];

    continueAdding = false;
    isShowCustomerFieldWhileAddingTicket;

    ticketAttachmentsFiles: File[] = [];

    minDueDate = new Date();
    canAddUser = true;
    companyId;

    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private usersService: UsersService,
                private ticketingSystemService: TicketingSystemService,
                private datePipe: DatePipe,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private rolesService: RolesService,
                private userService: UserService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.companyId = this.userService.userInfo.companyId;
        this.isShowCustomerFieldWhileAddingTicket = this.userService.userInfo.isShowCustomerFieldWhileAddingTicket;
        this.currentLang = this.translateService.currentLang;
        this.canAddUser = this.rolesService.getIsAuthorizedAction('MANAGE_USERS', this.userService.userInfo.role);
        this.initAddTicketForm();
        this.initDropDowns();
    }

    initDropDowns() {
        this.ticketPrioritiesList = this.ticketingSystemService.setTicketPriorities();
        if (!this.isEditMode) {
            this.initializeFormWithPreselectedData();
        }
    }

    initAddTicketForm(addMore = false) {
        this.addTicketForm = this.formBuilder.group({
            storeName: [''],
            categoryName: ['', Validators.required],
            title: ['', [Validators.required, InputAsStringValidator.ignoreBlankSpace]],
            priority: ['', Validators.required],
            user: [''],
            description: ['', [Validators.required, InputAsStringValidator.ignoreBlankSpace]],
            resolveDue: [''],
            continueAdding: [addMore]
        });

        if (this.isShowCustomerFieldWhileAddingTicket) {
            this.addTicketForm.get('storeName').setValidators([Validators.required]);
        }
    }

    initializeFormWithPreselectedData() {
        this.addTicketForm.patchValue({
            priority: 'LOW'
        });
    }
    disableSubmitAction() {
        return this.isSubmittingTicket || this.uploadingAttachments
            ||  this.addTicketForm.invalid
            || (!(this.selectedStoreInfo && this.selectedStoreInfo.id) && this.isShowCustomerFieldWhileAddingTicket)
            || !(this.selectedCategory && this.selectedCategory.id);
    }
    submitForm(attachments = []) {
        if (this.isSubmittingTicket) {
            return;
        }

        this.isSubmittingTicket = true;

        if (this.addTicketForm.invalid) {
            this.isSubmittingTicket = false;
            return;
        }

        const data = this.addTicketForm.getRawValue();
        const body = {
            title: data.title,
            description: data.description,
            priority: data.priority,
            categoryId: this.selectedCategory.id,
            resolveDue: data.resolveDue ? this.transformDate(moment(data.resolveDue, 'DD/MM/yyyy HH:mm').toDate(), 'yyyy-MM-dd HH:mm') : null,
            customerId: this.selectedStoreInfo.id,
            assigneeId: this.selectedClient.id,
            attachments: attachments
        };
        this.ticketingSystemService.addNewTicket(body).subscribe(
            (response: any) => {
                this.isSubmittingTicket = false;
                if (this.addTicketForm.get('continueAdding').value) {
                    this.addTicketForm.reset();
                    this.ticketAttachmentsFiles = [];
                    this.initAddTicketForm(true);
                    this.initializeFormWithPreselectedData();
                } else {
                    this.onClose();
                }
                this.messageService.add(
                    {severity: 'success', detail: this.translateService.instant('TICKETING_SYSTEM.ALARMS.TICKET_ADDED_SUCCESSFULLY')}
                );
                this.completeAddTicket.emit();
            }, error => {
                console.error(error);
                this.isSubmittingTicket = false;
            }
        );
    }

    editStoreName() {
        if (!this.selectedStoreInfo) {
            this.storeName.focusInput();
            this.storeName.search(null, '');
            return;
        }
        this.editStore = true;
        this.storeName.focusInput();
        const search_term = this.addTicketForm.get('storeName').value;
        this.storeName.search(null, search_term);
    }

    getUsers($event) {
        this.selectedClient = null;
        let query = '?page=1&pageSize=100';
        if ($event.query) {
            query += '&search=' + $event.query;
        }
        if(this.selectedCategory && this.selectedCategory.id) {
            query += '&categoryId=' + this.selectedCategory.id;
        }
        this.usersService.getUserDropDown(query).subscribe(
            (response: any) => {
                this.usersList = response;
            }, (error) => {
                console.error(error);
            }
        );
    }

    editClientName() {
        if (!this.selectedClient) {
            this.user.focusInput();
            this.user.search(null, '');
            return;
        }
        this.editClient = true;
        this.user.focusInput();
        const search_term = this.addTicketForm.get('user').value;
        this.user.search(null, search_term);
    }

    getStores($event) {
        this.selectedStoreInfo = null;
        const query = '?page=1&pageSize=20&search=' + $event.query;
        this.usersService.getCustomers(query).subscribe(
            (response: any) => {
                this.storesList = response.customers;
            }, (error) => {
                console.error(error);
            }
        );
    }

    chooseUser($event) {
        const user = $event;
        const userName = user.name;
        this.updateFormValue('user', userName);
        this.selectedClient = JSON.parse(JSON.stringify(user));
        this.editClient = false;
        this.checkAcValidity('user');
    }

    chooseStore($event) {
        const store = $event;
        this.updateFormValue('storeName', store.fullName);
        this.selectedStoreInfo = JSON.parse(JSON.stringify(store));
        this.selectedStoreInfo['address'].label = this.selectedStoreInfo['address'].village + ' - ' + this.selectedStoreInfo['address'].city +
            ' - ' + this.selectedStoreInfo['address'].region;
        this.editStore = false;
        this.checkAcValidity('storeName');
    }
    resetStore() {
        this.selectedStoreInfo = null;
        this.editStore = false;
        this.updateFormValue('storeName', '');
    }

    updateFormValue(controlName, value) {
        this.addTicketForm.controls[controlName].setValue(value);
        this.addTicketForm.controls[controlName].updateValueAndValidity();
    }

    getCategories($event) {
        this.selectedCategory = null;
        const query = '?search=' + $event.query + '&isDropdown=true';
        this.ticketingSystemService.getAllCategories(query).subscribe(
            (response: any) => {
                this.categoriesList = response;
            }, (error) => {
                console.error(error);
            }
        );
    }

    chooseCategory($event) {
        const category = $event;
        this.updateFormValue('categoryName', category.name);
        this.selectedCategory = JSON.parse(JSON.stringify(category));
        this.checkAcValidity('categoryName');
        this.updateFormValue('user', '');
    }

    public editCategoryName() {
        if (!this.selectedCategory) {
            this.categoryName.focusInput();
            this.categoryName.search(null, '');
            return;
        }
        this.editCategory = true;
        this.categoryName.focusInput();
        const search_term = this.addTicketForm.get('categoryName').value;
        this.categoryName.search(null, search_term);
    }

    addCustomer() {

        const modal = this.modalService.open(NewUserComponent, <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
        });
        modal.componentInstance.type = 'customer';
        modal.result.then(
            (status) => {
                if (!status) {
                    return;
                }
                if (status.isSuccess) {
                    this.chooseStore(status.customer);
                } else {// error adding customer
                    this.confirmationService.confirm({
                        message: this.translateService.instant('ALERTS.ADD_CUSTOMER_FAILED') + ': ' + status.error,
                        rejectVisible: false,
                        acceptLabel: 'OK'
                    });
                }
            });
    }

    transformDate(date, Dateformat = null) {
        const format = Dateformat ? Dateformat : 'yyyy-MM-dd';
        return this.datePipe.transform(date, format);
    }
    onClose() {
        this.closeWidget.emit(false);
    }

    resetClient() {
        this.selectedClient = null;
        this.editClient = false;
        this.updateFormValue('user', '');
    }

    resetCategory() {
        this.selectedCategory = null;
        this.editCategory = false;
        this.updateFormValue('categoryName', '');
    }


    onToggleContinueAdding(event) {
        this.updateFormValue('continueAdding', event.checked);
    }

    addAttachment(event: Event, fileChooser) {
        event.stopPropagation();
        fileChooser.click();
    }
    removeAttachment(attachment) {
        for (let i = 0; i < this.ticketAttachmentsFiles.length; i++) {
            if (this.ticketAttachmentsFiles[i] === attachment) {
                this.ticketAttachmentsFiles.splice(i, 1);
            }
        }
    }
    onFilesSelected($event: any) {
        for (let i = 0; i < $event.target.files.length; i++) {
            this.ticketAttachmentsFiles.push($event.target.files[i]);
        }
    }

    convertToMb(size) {
        return Number((size / (1024 * 1024)).toFixed(2));
    }
    uploadTicketAttachments() {
        const replyAttachments = [];
        let addAttachmentsCount = 0;
        this.uploadingAttachments = true;

        if (this.ticketAttachmentsFiles.length) {
            const compressionQuality = 0.3;
            const uploadFile = (formData, folder) => {
                this.ticketingSystemService.uploadFile(formData, folder).subscribe(
                    (res: any) => {
                        replyAttachments.push(res.fileUrl);
                        addAttachmentsCount++;
                        if (addAttachmentsCount === this.ticketAttachmentsFiles.length) {
                            this.submitForm(replyAttachments);
                            this.uploadingAttachments = false;
                        }
                    }, error => {
                        this.uploadingAttachments = false;
                        console.error(error);
                    }
                );
            };

            for (let i = 0; i < this.ticketAttachmentsFiles.length; i++) {
                if (this.convertToMb(this.ticketAttachmentsFiles[i].size) >  10) {
                    this.messageService.add({severity: 'error', detail: 'uploaded file is too large'});
                    continue;
                }
                if (!this.ticketAttachmentsFiles[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico|pdf|xlsx|xlsm|xlsb|xltx|csv|txt)$/i)) {
                    this.messageService.add({severity: 'error', detail: 'Not Supported File Format'});
                    continue;
                }
                const formData = new FormData();
                if (this.ticketAttachmentsFiles[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {

                    const comp = new Compressor.default(this.ticketAttachmentsFiles[i], {
                        quality: compressionQuality,
                        success(compFile) {
                            formData.append('file', compFile);
                            // if (compressedAttach[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {
                            uploadFile(formData, 'tickets');

                            // }
                        }
                    });
                } else {
                    formData.append('file', this.ticketAttachmentsFiles[i]);
                    uploadFile(formData, 'tickets');
                }
            }
        }
    }
    ticketRequest() {
        if (this.ticketAttachmentsFiles.length) {
            this.uploadTicketAttachments();
        } else {
            this.submitForm();
        }
    }

    checkAcValidity(controlName) {
        const isDirty = this.addTicketForm.get(controlName).dirty;
        // this.inValidStoreInfo =  this.addTicketForm.get('storeName').dirty && !(this.selectedStoreInfo && this.selectedStoreInfo.id);
        switch (controlName) {
            case 'storeName' :
                this.inValidStoreInfo = isDirty && !(this.selectedStoreInfo && this.selectedStoreInfo.id);
                break;
            case 'categoryName' :
                this.invalidCategory = isDirty && !(this.selectedCategory && this.selectedCategory.id);
                break;
        }
    }
}
