import { Component, OnInit } from '@angular/core';
import {REPORTS_CONSTANTS} from '../../administration/reports-constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-delayed-packages-report',
  templateUrl: './delayed-packages-report.component.html',
  styleUrls: ['./delayed-packages-report.component.scss']
})
export class DelayedPackagesReportComponent implements OnInit {
  public delayedReports: any = REPORTS_CONSTANTS.DETAILED_REPORTS.DELAYED_PACKAGES_REPORT;
  public delayedReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DELAYED_PACKAGES_REPORT_API;
  public delayedReportStatics = REPORTS_CONSTANTS.DETAILED_REPORTS.DELAYED_PACKAGES_REPORT_STATISTICS;
  public IdleCustomerRawDataUrl = REPORTS_CONSTANTS.DETAILED_REPORTS.DELAYED_PACKAGES_EXCEL;
  triggeredShipment: any = {};
  isTrackingPackage = false;

  constructor() {
    this.delayedReports = _.clone(REPORTS_CONSTANTS.DETAILED_REPORTS.DELAYED_PACKAGES_REPORT);
    this.delayedReports.find(value => {
      return value.field === 'barcode';
    }).click = ($event) => {
      this.trackShipment($event);
    };
    this.delayedReports.find(value => {
      return value.field === 'status';
    }).type = 'manageShipments';
  }

  ngOnInit() {
  }

  trackShipment(shipment) {
    if (shipment) {
      this.triggeredShipment = shipment;
    }
    this.isTrackingPackage = true;
  }
  closeTracker($event) {
    this.isTrackingPackage = $event;
  }

}
