import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UsersService} from '../services/users.service';

@Component({
    selector: 'app-driver-packges',
    templateUrl: './driver-packages.component.html',
    styleUrls: ['./driver-packages.component.scss']
})
export class DriverPackagesComponent implements OnInit {

    public userId;
    public packagesAPI;

    constructor(private route: ActivatedRoute,
                private usersService: UsersService) {
    }

    ngOnInit() {
        this.userId = this.route.snapshot.paramMap.get('id');
        this.packagesAPI = this.usersService.getDriverPackagesAPI(this.userId);
    }

    public downloadPdf() {
        this.usersService.downloadDriverPackagesPdf(this.userId).subscribe((res: any) => {
                window.open(res.url, '_blank');
            },
            error => {
                console.log(error);
            });
    }
}
