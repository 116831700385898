import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {SharedService} from '../../shared/services/shared-service';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {PackageModel} from '../../shared/models/package.model';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {StorageService} from '../../shared/services/storage/storage.service';
import {ChoosePrintTypeComponent} from '../choose-print-type/choose-print-type.component';
import {
    IS_TO_FINAL_DESTINATION,
    READ_BY_BARCODE_ORDER_BY_LIST,
    SHIPMENT_STATUS_ALL,
    SHIPMENT_STATUS_LIST,
    SHIPMENT_WITH_DRIVER_STATUS_LIST
} from '../services/constants';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {ShipmentsValidationResultsComponent} from '../shipments-validation-results/shipments-validation-results.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BehaviorSubject, Subscription} from 'rxjs';
import {playError} from '../../shared/behavior/sounds';
import {ReportConfirmationComponent} from '../receive-driver/report-confirmation/report-confirmation.component';
import {FaliureHistoryComponent} from '../faliure-history/faliure-history.component';
import {UsersService} from '../../users/services/users.service';
import {ExportShipmentsComponent} from '../export-shipments/export-shipments.component';
import {UserService} from '../../shared/services/user.service';
import {DriversService} from '../../shared/services/drivers.service';
import {HubsService} from '../../shared/services/hubs.service';
import {ShipmentsService} from '../services/shipments.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {AssignPackageComponent} from '../assign-package/assign-package.component';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {ChooseHubDriverPartnerComponent} from '../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {ConfirmMessageComponent} from '../../shared/components/confirm-message/confirm-message.component';
import {
    CustomConfirmationMessageComponent
} from '../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {PrintPackageOptionsComponent} from '../../users/print-package-options/print-package-options.component';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ChoosePaymentTypeComponent} from '../../users/choose-payment-type/choose-payment-type.component';
import {ChooseDriverComponent} from '../choose-driver/choose-driver.component';
import * as moment from 'moment';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {SelectShelfDialogComponent} from '../receive-driver/select-shelf-dialog/select-shelf-dialog.component';


@Component({
    selector: 'app-receive-driver-and-read-by-barcode',
    templateUrl: './receive-driver-and-read-by-barcode.component.html',
    styleUrls: ['./receive-driver-and-read-by-barcode.component.scss']
})
export class ReceiveDriverAndReadByBarcodeComponent implements OnInit {
    public driverId;
    public partnerId;
    public hubId;

    public isLoading = false;
    public isScanning = false;
    public isReading = false;
    public totalPackages = 0;
    public scannedPackages: { [barcode: number]: PackageModel } = {};
    public packages: { [barcode: number]: PackageModel } = {};
    public originalPackages: { [barcode: number]: PackageModel } = {};
    public triggeredPackage: any;
    private fetchedData: any[] = [];
    public permissions = '';
    public pageNumber = 0;
    public pageSize = 20;
    public hubsPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public PartnersListPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public driverName = '';
    public currentLang;
    public hubs: { id: number, name: string }[] = [];
    public hubsPageIndex = 1;
    public hubSearch = '';
    public partnersListPageIndex = 1;
    public totalHubsNo: number;
    public totalPartnersNo: number;
    public drivers: { id: number, name: string, countOfCarriedPackages: number }[] = [];
    public driversPageIndex = 1;
    public driverSearch = '';
    public totalDriversNo: number;
    public spinnerState = SpinnerState.EMPTY;
    public spinnerStates = SpinnerState;
    public isAllSelected = false;
    public selectedShipmentStatus = 'ALL';
    public selectedStatusFilterList: any[] = [];
    public shipmentWithDriverStatusList;
    public statusFilterList;
    public isThereSelection = false;
    public continueReceiveFromDriver = true;
    public isReceiverFromDriver = false;
    public isReadByBarcode = false;
    public isReceiveReturnedPackages = false;
    public isReceiveFromPartner = false;
    public noDataAvailableMessage = 'RECEIVE_FROM_DRIVER.CHOOSE_HUB_AND_DRIVER_ALERT';
    public enteredBarcode: any;
    public shipmentStatusAll = SHIPMENT_STATUS_ALL;
    public currency = '';
    public partnersList: any[] = [];
    public loadingDrivers = false;
    public loadingHubs = false;
    public loadingPartners = false;
    public isReturnedWith = false;
    public isUpdateReceiverInfo = false;
    public isLcl: boolean;
    public isReturned = false;
    public isFromThirdParty = false;
    public isEnableExportingBetweenHubs = false;
    public companyHadStations = [256, 22]; //SAR for Production and mohanad for testing
    public isToFinalDestinationStatus = IS_TO_FINAL_DESTINATION;

    private packagesSubscription: Subscription;

    @ViewChild('changePackageStatusPopOver') changePackageStatusPopOver: NgbPopover;

    // @ViewChild('changeStatusForSinglePackagePopOver') changeStatusForSinglePackagePopOver: NgbPopover;
    companyId;

    displayAddPackage = false;
    selectedPackageBarcode: any;
    editSelectedPackageBarcode: any;
    editSelectedPackage: any;
    displayAddPackageOb = new BehaviorSubject(false);
    private needRefreshPackages: boolean;
    userInfo;
    transferToList = [];
    orderBy: any;
    orderByOption;
    multipleBarcode = '';
    startReadGroupOfBarcodes;

    public showPickWithDriver = false;
    public showCreateInvoice = false;
    public showDeliver = false;
    public returnedModal = false;
    public exportCODModal = false;
    public hideDeliverToSenderBtn = false;
    selectedType = 'ALL';
    public inCar = false;
    private partiallyDelivered: any;
    private type: any;
    tableInfo;
    public companies = Companies;

    constructor(private route: ActivatedRoute,
                private userService: UserService,
                private usersService: UsersService,
                private shipmentsService: ShipmentsService,
                private rolesService: RolesService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private modalService: NgbModal,
                private activeModal: NgbActiveModal,
                private hubsService: HubsService,
                private driversService: DriversService,
                private storageService: StorageService,
                private authenticationService: AuthenticationService,
                private sharedService: SharedService,
                public customizationCompaniesService: CustomizationCompanyService) {
    }

    ngOnInit() {
        this.userInfo = this.userService.userInfo;
        this.currentLang = this.translateService.currentLang;
        this.isLcl = this.userService.userInfo.isLcl;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.currency = this.userService.getCurrency();
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        if (this.isReturnedWith) {
            this.selectedShipmentStatus = 'RETURNED_BY_RECIPIENT';
        }
        if (this.isReadByBarcode) { // read by barcode
            this.noDataAvailableMessage = 'READ_BY_BARCODE.START_READ_BY_BARCODE';
        }
        this.initButtons();
        this.initHubs();
        // if (this.isReceiverFromDriver || this.isReceiveFromPartner || this.isReceiveReturnedPackages) {

        if (this.isReceiverFromDriver) {
            this.initDrivers();
        }

        if (this.isReceiveFromPartner) {
            this.initPartnersList();
        }
        this.shipmentWithDriverStatusList = this.setShipmentWithDriverStatus();
        if (this.isReadByBarcode) {
            this.setStatusFilterList();
        }
        // }
        this.setOrderByOptions();

        this.isReading = true;
        this.companyId = this.userService.userInfo.companyId;
        if (this.companyId === 256) {
            this.driverId = 5144;
        } else if (this.companyId === 22) {
            this.driverId = 5143;
        }
    }

    onStatusFilter() {
        this.packages = [];
        if (this.selectedStatusFilterList.length === this.statusFilterList.length && this.statusFilterList.length) { // if select all no need to filter
            this.packages = this.originalPackages;
        } else {
            Object.keys(this.originalPackages).forEach((key: string) => {
                if (this.selectedStatusFilterList.indexOf(this.originalPackages[key].status) !== -1) {
                    this.packages[key] = this.originalPackages[key];
                }
            });
        }
    }

    public resetPackages(partialReset = true) {
        if (partialReset) {
            Object.keys(this.scannedPackages).forEach(key => {
                delete this.packages[key];
                delete this.originalPackages[key];
            });
            this.scannedPackages = [];
            return;
        }
        this.packages = [];
        this.originalPackages = [];
        this.scannedPackages = [];
    }

    finish(isCLoseModal = false) {
        let confirmMessage;
        if (this.isReadByBarcode) {
            confirmMessage = 'ALERTS.READ_BY_BARCODE_CONFIRM';
            if (this.isUpdateReceiverInfo) {
                confirmMessage = 'ALERTS.EDIT_RECEIVER_INFO_CONFIRM';
            }
        } else { // receive mode
            confirmMessage = 'ALERTS.RECEIVE_CONFIRM';
        }
        this.translateService.get(
            [confirmMessage, 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe(
            (data) => {
                this.confirmationService.confirm({
                    message: data[confirmMessage],
                    accept: () => {
                        if (isCLoseModal) {
                            this.closeModal();
                        } else {
                            this.resetPackages(false);
                        }
                    },
                    acceptLabel: data['GENERAL.YES'],
                    rejectLabel: data['GENERAL.NO']
                });
            }
        );
    }

    public closeModal() {
        this.activeModal.close({needRefreshPackages: this.needRefreshPackages});
    }

    public setShipmentWithDriverStatus() {
        return SHIPMENT_WITH_DRIVER_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    setStatusFilterList() {
        this.statusFilterList = SHIPMENT_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
        const allIndex = this.statusFilterList.findIndex(status => (status.value === this.shipmentStatusAll));
        if (allIndex !== -1) { // remove 'ALL' item for multi select filter
            this.statusFilterList.splice(allIndex, 1);
        }
        this.statusFilterList.map(status => {
            this.selectedStatusFilterList.push(status.value);
        });
    }

    @HostListener('scroll', ['$event'])
    onScrollPartnersList(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.lazyLoadPartnersList();
        }
    }

    public lazyLoadPartnersList(search?: string) {
        this.partnersListPageIndex++;
        this.initPartnersList(search);
    }

    public initPartnersList(search?: string) {
        let query = (search) ? '?page=' + this.partnersListPageIndex + '&pageSize=' + this.PartnersListPageSize + '&search=' + search
            : '?page=' + this.partnersListPageIndex + '&pageSize=' + this.PartnersListPageSize;
        query += '&status=ACTIVE';
        if (this.partnersList.length !== this.totalPartnersNo) {
            this.loadingPartners = true;
            this.usersService.getPartners(query).subscribe(
                (response: any) => {
                    this.partnersList = [...this.partnersList, ...response.data];
                    this.totalPartnersNo = response.totalRecordsNo;
                    this.loadingPartners = false;
                }, error => {
                    console.error(error);
                    this.loadingPartners = false;
                }
            );
        }
    }


    @HostListener('scroll', ['$event'])
    onScrollHubs(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.lazyLoadHubs(this.hubSearch);
        }
    }

    public lazyLoadHubs(search?: string, forceFetch = false) {
        this.hubsPageIndex++;
        this.initHubs(search, forceFetch);
    }

    public initHubs(search?: string, forceFetch = false) {
        let query = '?pageSize=' + this.hubsPageSize + '&page=' + this.hubsPageIndex;
        if (search) {
            query += '&search=' + search;
        }

        if (this.hubs.length !== this.totalHubsNo || search || forceFetch) {
            this.loadingHubs = true;
            return this.hubsService.getHubs(query).subscribe(
                (response: { hubs: { id: number, name: string }[], totalRecordsNo: number }) => {
                    this.hubs = this.hubsPageIndex > 1 ? [...this.hubs, ...response.hubs] : response.hubs;
                    this.totalHubsNo = response.totalRecordsNo;
                    this.loadingHubs = false;
                }, error => {
                    console.error(error);
                    this.loadingHubs = false;
                }
            );
        }
    }

    public onSearchHubs(search) {
        this.hubsPageIndex = 0;
        this.lazyLoadHubs(search, true);
    }

    @HostListener('scroll', ['$event'])
    onScrollDrivers(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.lazyLoadDrivers(this.driverSearch);
        }
    }

    public lazyLoadDrivers(search?: string, forceFetch = false) {
        this.driversPageIndex++;
        this.initDrivers(search, forceFetch);
    }

    public initDrivers(search?: string, forceFetch = false) {
        let query = '?pageSize=' + this.hubsPageSize + '&page=' + this.driversPageIndex;
        if (search) {
            query += '&search=' + search;
        }

        if (this.isReturnedWith) {
            query += '&status=RETURNED_BY_RECIPIENT';
            query += '&carried-type=RETURN';
        }
        if (this.isFromThirdParty) {
            query += '&driverType=THIRD_PARTY';
        } else {
            query += '&driverType=TYPICAL';
        }
        if (this.customizationCompaniesService.checkCompanies(Companies.SPRINT)) {
            query += '&isShowAllHubsDrivers=true';
        }
        if (this.drivers.length !== this.totalDriversNo || search || forceFetch) {
            this.loadingDrivers = true;
            return this.driversService.getDriversWithPackagesCount(query).subscribe(
                (response: {
                    data: { id: number, firstName: string, lastName: string, packagesCount: number }[],
                    totalRecordsNo: number
                }) => {
                    this.totalDriversNo = response.totalRecordsNo;
                    const fetchedDrivers = response.data.map(driver => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {id: driver.id, name: name, countOfCarriedPackages: driver.packagesCount};
                    });
                    this.drivers = this.driversPageIndex > 1 ? [...this.drivers, ...fetchedDrivers] : fetchedDrivers;
                    this.loadingDrivers = false;
                }, error => {
                    console.error(error);
                    this.loadingDrivers = false;
                }
            );
        }

    }

    public onSearchDrivers(search) {
        this.driversPageIndex = 0;
        this.lazyLoadDrivers(search, true);
    }

    public onSearchPartners(search) {
        this.partnersList = [];
        this.partnersListPageIndex = 0;
        if (search) {
            this.lazyLoadPartnersList(search);
        } else {
            this.lazyLoadPartnersList();

        }
    }

    public onSelectDriver(driverId) {
        this.driverId = driverId;
        this.isThereSelection = false;
        this.scannedPackages = {};
        this.packages = {};
        if (!this.isReturnedWith) {
            this.selectedShipmentStatus = 'ALL';
        }
        this.pageNumber = 0;
        this.getPackages(true);
    }

    public onSelectPartner(partnerId) {
        this.partnerId = partnerId;
        this.isThereSelection = false;
        this.scannedPackages = {};
        this.packages = {};
        if (!this.isReturnedWith) {
            this.selectedShipmentStatus = 'ALL';
        }
        this.pageNumber = 0;
        this.getPackages(true);
    }

    public onSelectHub(hubId) {
        this.hubId = hubId;
    }

    public onToggleSelectAll() {
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
            this.isThereSelection = true;
        } else {
            this.isThereSelection = false;
            this.scannedPackages = {};
        }
        Object.values(this.packages).forEach((pkg: PackageModel) => {
            pkg.isSelected = selected;
            if (pkg.isSelected) {
                this.scannedPackages[pkg.barcode] = pkg;
            }
        });

    }

    public onToggleSelection(event: Event, pkg) {
        // if (event) {
        //     event.stopPropagation();
        // }
        this.checkIfWeReceivedInTheSameHub(pkg);
        if (pkg.isSelected) {
            if (!this.scannedPackages[pkg.barcode]) {
                this.scannedPackages[pkg.barcode] = pkg;
            }
        } else {
            this.cancelScanPackage(pkg);
        }

        const selectedPackages = this.getSelectedPackages();
        this.isThereSelection = selectedPackages.length !== 0;
        this.isAllSelected = selectedPackages.length === Object.values(this.packages).length && this.isThereSelection;
    }

    public checkIfWeReceivedInTheSameHub(pkg) {
        if (this.companyHadStations.indexOf(this.companyId) !== -1 &&
            !this.scannedPackages[pkg.barcode] &&
            pkg.toHub !== this.userInfo.hubId) {
            this.messageService.add({severity: 'warn', detail: this.translateService.instant('ALERTS.RECEIVED_PACKAGES_ON_OTHER_HUB')});
        }
    }

    public copyMessage($event, list?, barcode?) {
        $event.stopPropagation();
        this.sharedService.copyMessage($event, list, barcode, 'ALERTS.COPIED');
    }

    public onStatusChanged() {
        this.packages = {};
        this.pageNumber = 0;
        this.getPackages(true);
    }

    public createQuery() {
        let result = `?pageSize=${this.pageSize}&page=` + (this.pageNumber + 1);
        if (this.selectedShipmentStatus !== 'ALL') {
            result += '&status=' + this.selectedShipmentStatus;
        }
        if (this.isFromThirdParty) {
            result += '&driverType=THIRD_PARTY';
        } else {
            result += '&driverType=TYPICAL';
        }
        return result;
    }

    public barcodeReader(barcode) {
        if (this.startReadGroupOfBarcodes) {
            return;
        }
        let getPackage;
        if (this.isReadByBarcode) {
            if (this.isReading && barcode !== '' && barcode !== null && barcode !== undefined && !this.originalPackages[barcode]) {
                this.isLoading = true;
                this.shipmentsService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true).subscribe((pkg) => {
                    this.scanPackage(pkg);
                    this.isLoading = false;
                }, () => {
                    playError();
                    this.isLoading = false;
                });
            } else if (this.originalPackages[barcode]) {
                this.getExistPackageMessage();
            }
        } else {
            if (this.isReading && barcode !== '' && barcode !== null && barcode !== undefined && !this.scannedPackages[barcode]) {
                this.isLoading = true;
                if (this.isReceiveFromPartner) {
                    getPackage = this.shipmentsService.getPartnerPackageByBarcode(barcode, this.partnerId);
                } else if (this.isReceiverFromDriver) {
                    getPackage = this.shipmentsService.getDriverPackageByBarcode(barcode, this.driverId);
                } else {
                    if (this.isReturned) {
                        getPackage = this.shipmentsService.getReturnedPakcageByBarcode(barcode);
                    } else {
                        getPackage = this.shipmentsService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true);
                    }
                }
                getPackage.subscribe((pkg) => {
                    this.scanDriverPackage(pkg);
                    this.isLoading = false;
                }, () => {
                    playError();
                    this.isLoading = false;
                });
            } else if (this.scannedPackages[barcode]) {
                this.getExistPackageMessage();
            }
        }
    }

    public searchBarcodesList(searchValue) {
        for (const pkgBarcode in this.originalPackages) {
            if (!this.originalPackages[pkgBarcode].barcode.includes(searchValue)) {
                this.packages[pkgBarcode] = undefined;
                delete this.packages[pkgBarcode];
            } else if (!this.packages[pkgBarcode]) {
                this.packages[pkgBarcode] = this.originalPackages[pkgBarcode];
            }
        }
    }

    removeFromBarcodesList(pkg) {
        delete this.packages[pkg.barcode];
        delete this.originalPackages[pkg.barcode];
        delete this.scannedPackages[pkg.barcode];
    }

    public onClearSearchBarcodesList(search) {
        this.packages = this.originalPackages;
        this.searchBarcodesList(search);
    }

    public scanBarcode(barcode) {
        if (barcode === '' || barcode === null || barcode === undefined || this.startReadGroupOfBarcodes) {
            return;
        }
        if (this.packages[barcode]) {
            this.getExistPackageMessage();
            this.enteredBarcode = null;
            return;
        }
        this.isLoading = true;
        if (!this.returnedModal && !this.exportCODModal) {
            this.shipmentsService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true).subscribe((pkg: any) => {
                this.translateService.get('ALERTS.BARCODE_SCANNED_SUCCESSFULLY')
                    .subscribe(
                        () => {
                            this.scanPackage(pkg);
                            this.isLoading = false;
                            this.enteredBarcode = null;
                        }
                    );
            }, () => {
                playError();
                this.enteredBarcode = null;
                this.isLoading = false;
            });
        } else {
            let service;
            if (this.returnedModal) {
                service = this.shipmentsService.getReturnPackageByBarcodes(barcode);
            } else if (this.exportCODModal) {
                service = this.shipmentsService.getExportedCODByBarcodes(barcode);
            }

            service.subscribe((items) => {
                items.map(item => {
                    this.scanPackage(item);
                });
                this.isLoading = false;
                this.enteredBarcode = null;
            }, () => {
                playError();
                this.enteredBarcode = null;
                this.isLoading = false;
            });
        }

    }

    public showSuccessMessage() {
        this.translateService.get('ALERTS.PACKAGE_SCANNED_SUCCESSFULLY').subscribe((res: any) => {
            this.messageService.add({
                severity: 'success',
                detail: res
            });
        });
    }

    public getExistPackageMessage() {
        this.translateService.get('ALERTS.PACKAGE_ALREADY_SCANNED').subscribe((res: any) => {
            this.messageService.add({
                severity: 'info',
                detail: res
            });
        });
    }

    public scanDriverPackage(pkg) {
        this.checkIfWeReceivedInTheSameHub(pkg);
        if (pkg) {
            if (this.isReceiveFromPartner || this.isReceiverFromDriver) {
                if (this.scannedPackages[pkg.barcode]) {
                    this.getExistPackageMessage();
                } else if (this.packages[pkg.barcode]) {
                    pkg.isSelected = true;
                    delete this.packages[pkg.barcode];
                    let newPackages = {};
                    newPackages[pkg.barcode] = pkg;
                    newPackages = {...newPackages, ...this.packages};
                    this.packages = newPackages;
                    this.scannedPackages[pkg.barcode] = this.packages[pkg.barcode];
                    this.showSuccessMessage();
                } else {
                    pkg.isSelected = true;
                    const newPackage = {};
                    newPackage[pkg.barcode] = pkg;
                    this.packages = {...newPackage, ...this.packages};
                    this.scannedPackages[pkg.barcode] = pkg;
                    this.showSuccessMessage();
                }
            } else if (this.isReadByBarcode || this.isReceiveReturnedPackages) {
                if (!this.packages[pkg.barcode]) {
                    pkg.isSelected = true;
                    this.packages[pkg.barcode] = pkg;
                    if (this.isReceiveReturnedPackages) {
                        this.scannedPackages[pkg.barcode] = pkg;
                    }
                } else {
                    this.getExistPackageMessage();
                }
            }
        }
    }

    scanPackage(pkg) {
        if (this.returnedModal) {
            this.packages[pkg.massReturnedPackagesReportBarcode] = pkg;
            this.originalPackages[pkg.massReturnedPackagesReportBarcode] = pkg;
            this.scannedPackages[pkg.massReturnedPackagesReportBarcode] = pkg;

        } else {
            this.packages[pkg.barcode] = pkg;
            this.originalPackages[pkg.barcode] = pkg;
            this.scannedPackages[pkg.barcode] = pkg;
        }

        pkg.isSelected = true;
        this.isAllSelected = Object.values(this.scannedPackages).length === Object.values(this.packages).length;
    }

    public isPackageScanned(pkg) {
        if (this.returnedModal) {
            return this.scannedPackages[pkg.massReturnedPackagesReportBarcode] !== undefined;
        } else {
            return this.scannedPackages[pkg.barcode] !== undefined;
        }
    }

    public cancelScanPackage(pkg) {
        pkg.isSelected = false;
        if (this.returnedModal) {
            this.scannedPackages[pkg.massReturnedPackagesReportBarcode] = undefined;
            delete this.scannedPackages[pkg.massReturnedPackagesReportBarcode];
        } else {
            this.scannedPackages[pkg.barcode] = undefined;
            delete this.scannedPackages[pkg.barcode];
        }
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    removePackage(pkg) {
        if (this.returnedModal) {
            delete this.packages[pkg.massReturnedPackagesReportBarcode];
            delete this.scannedPackages[pkg.massReturnedPackagesReportBarcode];
            delete this.originalPackages[pkg.massReturnedPackagesReportBarcode];

        } else {
            delete this.packages[pkg.barcode];
            delete this.scannedPackages[pkg.barcode];
            delete this.originalPackages[pkg.barcode];
        }
    }

    public startScanning() {
        this.isScanning = true;
    }

    public resetScanning(showConfirmation = true) {
        if (!showConfirmation) {
            this.isScanning = false;
            this.packages = {};
            this.scannedPackages = {};
        } else {
            this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.isScanning = false;
                        this.packages = {};
                        this.scannedPackages = {};
                        this.driverId = null;
                        this.partnerId = null;
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO'],
                });
            });
        }
    }

    enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isCarried = true;
        // modal.componentInstance.isPickup = this.isPickup;
        if (this.isReceiveFromPartner) {
            modal.componentInstance.isReceivePartnerPackage = true;
            modal.componentInstance.partnerId = this.partnerId;
        } else if (this.isReceiverFromDriver) {
            modal.componentInstance.isReceiverFromDriver = true;
            modal.componentInstance.driverId = this.driverId;
        } else if (this.isReceiveReturnedPackages) {
            modal.componentInstance.isReturned = true;
        }
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.scanDriverPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public receiveFromDriver() {
        this.isLoading = true;

        const translateService = this.translateService.get(['ALERTS.RECEIVE_DRIVER_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']);
        translateService.subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.RECEIVE_DRIVER_CONFIRMATION'],
                accept: () => {
                    if (this.customizationCompaniesService.checkCompanies(this.companies.JETT)) {
                        const modal1 = this.modalService.open(SelectShelfDialogComponent, {
                            backdrop: 'static',
                            windowClass: 'select-shelf-dialog',
                            size: 'xl'
                        });
                        modal1.componentInstance.shipments = Object.values(this.scannedPackages);
                        modal1.result.then(
                            (data1: { isSuccess }) => {
                                if (data1.isSuccess) {
                                    this.needRefreshPackages = true;
                                    this.closeModal();
                                }
                            }).catch(
                            (error) => {
                                this.isLoading = false;
                                console.error(error);
                            }
                        );
                        return;
                    }
                    const modal = this.modalService.open(ReportConfirmationComponent, {
                        backdrop: 'static',
                        windowClass: 'edit-template',
                        size: 'lg'
                    });
                    modal.result.then(
                        (data: { printType: string }) => {
                            this.isLoading = false;
                            if (data.printType !== '') {
                                this.receivePackages(data.printType);
                            }

                        }
                    ).catch(
                        (error) => {
                            this.isLoading = false;
                            console.error(error);
                        }
                    );
                    this.isLoading = false;
                    if (!this.continueReceiveFromDriver) {
                        this.closeModal();
                    }
                },
                reject: () => {
                    this.isScanning = false;
                    this.isLoading = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public unloadPackages() {
        const body = this.hubId ? {hubId: this.hubId} : {};
        body['ids'] = this.isReceiveReturnedPackages ? Object.values(this.scannedPackages).map(pkg => pkg.id) : Object.values(this.packages).map(pkg => pkg.id);
        this.shipmentsService.unloadReturnedPackages(body).subscribe(
            () => {
                this.translateService.get(['ALERTS.CHANGED_STATUS', 'ACTIONS.OK'])
                    .subscribe(
                        (res) => {
                            this.confirmationService.confirm({
                                message: res['ALERTS.CHANGED_STATUS'],
                                rejectVisible: false,
                                acceptLabel: res['ACTIONS.OK']
                            });
                        }
                    );
                this.resetScanning(false);
            }, () => {
            }
        );
    }

    public receiveReturnedPackages() {
        if (!this.scannedPackages || !Object.keys(this.scannedPackages).length) {
            return;
        }
        this.translateService.get(['ACCOUNTING.FINISH_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'])
            .subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.FINISH_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.unloadPackages();
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO']
                });
            });
    }

    public finishScanning() {
        if (this.isReceiverFromDriver) {
            this.receiveFromDriver();
        } else if (this.isReceiveFromPartner) {
            this.receiveFromPartner();
        } else if (this.isReceiveReturnedPackages) { // receive returned packages
            this.receiveReturnedPackages();
        }
    }

    public receivePackages(printType = null) {
        this.isLoading = true;
        this.shipmentsService.receiveDriverPackages(
            this.driverId, this.hubId, Object.values(this.scannedPackages).map(pkg => pkg.id), printType).subscribe((data: any) => {
            if (data !== undefined && data && data.url !== undefined) {
                window.open(data.url, '_blank');
            }
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS', 'ACTIONS.OK']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS'],
                    rejectVisible: false,
                    acceptLabel: res['ACTIONS.OK'],
                });
                this.isLoading = false;
                this.scannedPackages = {};
                this.getPackages(true);
            });
        }, (error) => {
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_FAILED', 'ACTIONS.OK']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RECEIVE_FROM_DRIVER_FAILED'] + ': ' + error.err.err,
                    rejectVisible: false,
                    acceptLabel: res['ACTIONS.OK'],
                });
                this.isLoading = false;
            });

        });
    }

    public onChangePackageStatus(pkg = null) {
        const modal = this.modalService.open(ChangePackageStatusComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.isModal = true;
        if (pkg) {
            modal.componentInstance.shipment = pkg;
        } else {
            modal.componentInstance.shipments = this.getSelectedPackages();
        }
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.getPackages(true);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public openMessagesHistory(id) {
        const modal = this.modalService.open(FaliureHistoryComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.shipmentId = id;
        modal.result.then(
        ).catch(
            () => {
            }
        );

    }

    private getSelectedPackages() {
        const selectedPackages = [];
        if (!this.packages) {
            return;
        }
        Object.values(this.packages).forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }

    public isAllowedEditCost() {
        return (this.permissions.includes('{EDIT_COST')) ||
            (this.permissions.includes('{CONDITIONAL_COST') && this.userService.userInfo.isNonAdminChangeCost);
    }

    public getPackages(isFromFilter = false, isEdit = false, barcode = '') {
        if (!this.driverId && !this.partnerId) {
            return;
        }
        if (isFromFilter) {
            this.pageNumber = 0;
            this.packages = {};
        }
        let URLQuery = this.createQuery();
        if (barcode) {
            URLQuery += '&search=' + barcode;
        }
        this.fetchedData = [];
        // if (this.packages.length === this.totalPackages) {
        //     return;
        // }
        this.spinnerState = SpinnerState.LOADING;
        let getPackages;
        if (this.packagesSubscription) {
            this.packagesSubscription.unsubscribe();
        }
        if (this.isReceiverFromDriver) {
            getPackages = this.usersService.getDriverPackages(this.driverId, URLQuery, {});
        } else if (this.isReceiveFromPartner) {
            getPackages = this.usersService.getPartnerPackages(this.partnerId, URLQuery, {});
        }
        this.packagesSubscription = getPackages.subscribe(
            (response: any) => {
                if (this.isReceiverFromDriver) {
                    this.fetchedData = response.data;
                } else if (this.isReceiveFromPartner) {
                    this.fetchedData = response.pkgs;
                }
                if (isFromFilter) {
                    this.packages = this.packagesListToMap(this.fetchedData);
                } else {
                    this.pushPackagesToMap(this.fetchedData, this.packages);
                }
                this.totalPackages = response.totalRecordsNo;
                if (this.totalPackages > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, error => {
                console.error(error);
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    public loadPackagesLazy() {
        this.getPackages();
    }

    public onScrollTable(event) {
        if (this.spinnerState === SpinnerState.LOADING) {
            return;
        }
        if (Object.keys(this.packages).length === this.totalPackages) {
            return;
        }
        if (event.target.offsetHeight + event.target.scrollTop + 20 >= event.target.scrollHeight) {
            this.pageNumber++;
            this.loadPackagesLazy();
        }
    }

    public approvePackages() {
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.shipmentsService.approvePackages(selectedPackages).subscribe(
            () => {
                this.translateService.get(['ALERTS.PACKAGES_APPROVED', 'ACTIONS.OK']).subscribe(
                    (data) => {
                        this.confirmationService.confirm({
                            message: data['ALERTS.PACKAGES_APPROVED'],
                            rejectVisible: false,
                            acceptLabel: data['ACTIONS.OK']
                        });
                    }
                );
                this.isLoading = false;
                this.resetPackages();
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    private assignPackageTo(assignTo: 'driver' | 'container') {
        if (Object.keys(this.scannedPackages).length > 0) {
            const modal = this.modalService.open(AssignPackageComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.assignTo = assignTo;
            modal.componentInstance.ids = Object.values(this.scannedPackages).map(pkg => pkg.id);
            modal.result.then(
                (result: { isSuccess: boolean }) => {
                    if (result.isSuccess) {
                        this.translateService.get(['ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY', 'ACTIONS.OK']).subscribe(
                            (data) => {
                                this.confirmationService.confirm({
                                    message: data['ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY'],
                                    rejectVisible: false,
                                    acceptLabel: data['ACTIONS.OK']
                                });
                            }
                        );
                        this.resetPackages();
                    }
                }
            ).catch(
                () => {
                }
            );
        }
    }

    public assignToDriver() {
        this.assignPackageTo('driver');
    }

    public assignToContainer() {
        this.assignPackageTo('container');
    }

    public downloadMultiplePackages(printType = null) {
        if (!this.scannedPackages || !Object.keys(this.scannedPackages).length) {
            return;
        }
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.shipmentsService.downloadMultiple(selectedPackages, printType).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public printRunSheet(isWithSignature = false) {
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleChangeStatus(selectedPackages.reverse(), null, isWithSignature).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public openPrintDialog() {
        const modal = this.modalService.open(ChoosePrintTypeComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { type: any }) => {
                if (data) {
                    if (data.type === 'RUNSHEET') {
                        this.printRunSheet();
                    } else if (data.type === 'RUNSHEET_WITH_SIGNATURE') {
                        this.printRunSheet(true);
                    } else {
                        this.downloadMultiplePackages(data.type);
                    }
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    transformToDistributor(result) {
        if (this.userService.userInfo.isDistributor && result) {
            result = result.replace(/(Shipment|Package)/, 'Order');
            result = result.replace(/(shipment|package)/, 'order');
            result = result.replace('طرد', 'طلبية');
            result = result.replace('شحنة', 'طلبية');
            result = result.replace('الطرد', 'الطلبية');
            result = result.replace('طرود', 'طلبيات');
            result = result.replace('شحنات', 'طلبيات');
        }
        return result;
    }

    exportPackagesTo() {
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        const modal = this.modalService.open(ExportShipmentsComponent, {backdrop: 'static', windowClass: 'export-packages-to', size: 'lg'});
        modal.result.then(
            (result: { id?: number, type?: string }) => {
                if (result && result.id) {
                    this.isLoading = true;
                    let exportPackages = this.shipmentsService.transferPackageByIds(result.id, selectedPackages); // export to partner api
                    if (result.type === 'HUB') { // call transfer to hub api
                        exportPackages = this.shipmentsService.exportPackagesToHub(result.id, selectedPackages);
                    }
                    exportPackages.subscribe((res: any) => {
                        if (res && res.length && result.type === 'HUB') {
                            const modalDialog = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modalDialog.componentInstance.results = res;
                            modalDialog.componentInstance.label = 'ACCOUNTING.EXPORT_TO_HUB';
                        } else {
                            const toastMessage = result.type === 'PARTNER' ? 'ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY' : 'ALERTS.PACKAGES_TRANSFERRED_TO_HUB_SUCCESSFULLY';
                            this.messageService.add({
                                severity: 'success',
                                detail: this.transformToDistributor(this.translateService.instant(toastMessage))
                            });
                            this.resetPackages();
                        }
                        this.isLoading = false;

                    }, (err) => {
                        this.download('errors.txt', err.error.error);
                        this.isLoading = false;
                    });
                }
            }
        );
    }

    public closeChangePackageStatusPopOver(popOver, event) {
        popOver.close();
        if (event.isSuccess) {
            this.translateService.get(['ALERTS.CHANGED_STATUS', 'ACTIONS.OK']).subscribe(
                (data) => {
                    this.confirmationService.confirm({
                        message: data['ALERTS.CHANGED_STATUS'],
                        rejectVisible: false,
                        acceptLabel: data['ACTIONS.OK']
                    });
                }
            );
            this.needRefreshPackages = true;
            if (this.triggeredPackage) {
                this.removePackage(this.triggeredPackage);
            } else {
                this.resetPackages();
            }
        }
    }

    public assignTriggeredPackage(shipment) {
        this.triggeredPackage = shipment;
    }

    public disableFinishScanningBtn() {
        let length;
        if (this.isReceiverFromDriver || this.isReceiveFromPartner || this.isReceiveReturnedPackages) {
            length = Object.keys(this.scannedPackages).length;
        } else { // receive returned packages
            length = Object.keys(this.packages).length;
        }
        return !length || !this.hubId;
    }

    public disableResetScanningBtn() {
        if (this.isReceiverFromDriver) {
            return !Object.keys(this.scannedPackages).length;
        } else { // receive returned packages
            return !Object.keys(this.packages).length;
        }
    }

    public actualReceivePartnerPackages() {
        this.isLoading = true;
        const body = {
            ids: Object.values(this.scannedPackages).map(pkg => pkg.id),
            hubId: this.hubId,
            isReceiveFromPartner: true
        };
        this.shipmentsService.receivePartnerPackages(body).subscribe((data: any) => {
            if (data !== undefined && data && data.url !== undefined) {
                window.open(data.url, '_blank');
            }
            this.isLoading = false;
            this.translateService.get(['ALERTS.RECEIVE_FROM_PARTNER_SUCCESS', 'ACTIONS.OK']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ALERTS.RECEIVE_FROM_PARTNER_SUCCESS'],
                    rejectVisible: false,
                    acceptLabel: res['ACTIONS.OK'],
                });
                this.isScanning = false;
                this.scannedPackages = {};
                this.getPackages(true);
            });
        }, (error) => {
            this.isLoading = false;
            this.translateService.get(['ALERTS.RECEIVE_FROM_PARTNER_FAILED', 'ACTIONS.OK']).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RECEIVE_FROM_PARTNER_FAILED'] + ': ' + error.err.err,
                    rejectVisible: false,
                    acceptLabel: res['ACTIONS.OK'],
                });
                this.isScanning = false;
            });
        });
    }

    public receiveFromPartner() {
        this.isLoading = true;
        this.translateService.get(['ALERTS.RECEIVE_PARTNER_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.RECEIVE_PARTNER_CONFIRMATION'],
                accept: () => {
                    this.actualReceivePartnerPackages();
                    // this.isLoading = false;
                    // if (!this.continueReceiveFromDriver) {
                    //     this.activeModal.close();
                    // }
                },
                reject: () => {
                    this.isScanning = false;
                    this.isLoading = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public changeReceiverPhone(receiverPhone, pkgId) {
        if (this.userService.getPhonePattern(this.userService.userInfo.companyId === 144).test(receiverPhone)) {
            const body = {};
            body['receiverPhone'] = receiverPhone;
            this.shipmentsService.editReceiverInfo(pkgId, body, 'PHONE').subscribe(() => {
                this.translateService.get('ALERTS.RECEIVER_PHONE_SUCCESSFULLY').subscribe(
                    (res) => {
                        this.messageService.add({severity: 'success', detail: res});
                    }
                );
            }, () => {
            });
        } else {
            this.translateService.get('ALERTS.RECEIVER_PHONE_ERROR').subscribe(
                (res) => {
                    this.messageService.add({severity: 'error', detail: res});
                }
            );
        }
    }

    public changeReceiverName(receiverName, pkgId) {
        if (receiverName && receiverName !== '') {
            const body = {};
            body['receiverName'] = receiverName;
            this.shipmentsService.editReceiverInfo(pkgId, body, 'NAME').subscribe(() => {
                this.translateService.get('ALERTS.RECEIVER_NAME_SUCCESSFULLY').subscribe(
                    (res) => {
                        this.messageService.add({severity: 'success', detail: res});
                    }
                );
            });
        }
    }

    private packagesListToMap(packagesList: PackageModel[]) {
        const map = {};
        packagesList.forEach(pkg => {
            map[pkg.barcode] = pkg;
            if (this.scannedPackages[pkg.barcode]) {
                map[pkg.barcode].isSelected = true;
            }
        });
        return map;
    }

    private pushPackagesToMap(packagesList: PackageModel[], packagesMap: { [barcode: number]: PackageModel }) {
        packagesList.forEach(pkg => {
            packagesMap[pkg.barcode] = pkg;
            if (this.scannedPackages[pkg.barcode]) {
                packagesMap[pkg.barcode].isSelected = true;
            }
        });
    }

    trackPackageById(index: number, item: PackageModel) {
        return item.id;
    }

    objectValues(obj: any) {
        return Object.values(obj);
    }

    enterContainerNumber() {
        this.isLoading = true;
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isAddToContainer = true;
        modal.result.then(
            (data: { containerNumber: number }) => {
                if (data && data.containerNumber) {
                    this.getContainerInfo(data.containerNumber);
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    getContainerInfo(containerNumber) {
        this.shipmentsService.getContainerInfo(containerNumber).subscribe(
            (container: any) => {
                if (container.id) {
                    this.addPackagesToContainer(container.id);
                } else {
                    this.isLoading = false;
                }
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    addPackagesToContainer(containerId) {
        this.shipmentsService.addPackagesToContainer(containerId, {ids: Object.values(this.packages).map(pkg => pkg.id)}).subscribe(
            (res: any) => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('LCL.ALERTS.ITEM_ADDED_TO_CONTAINER_SUCCESSFULLY')
                });
                this.resetPackages();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    unloadFromContainer(packageId) {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.unloadFromContainer(packageId).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('LCL.ALERTS.ITEM_UNLOADED_SUCCESSFULLY')
                });
                this.resetPackages();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    transferToStorm() {
        this.isLoading = true;
        const body = {ids: Object.values(this.packages).map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_STORM_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToStorm(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToMughrabe() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_MUGHRABE_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToMughrabe(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToOustol() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_OUSTOL_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToOustol(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToAutobahn() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_AUTOBAHN_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToAutobahn(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    async genericTransferTo(btn) {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: await btn.confirmation.toPromise(),
            accept: () => {
                this.shipmentsService.genericTransferTo(btn.from, btn.to, body, btn.customUrl).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToAlkhal() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_ALKHAL_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToAlkhal(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToHiExpress() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_HI_EXPRESS_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToHiExpress(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToAman() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_AMAN_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToAman(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToIMile() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_IMILE_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToIMile(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_IMILE';
                        } else {
                            this.resetPackages();
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToSMB() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_SMB_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToSMB(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_SMB';
                        } else {
                            this.resetPackages();
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }
    transferToHolol() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_HOLOL_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToHolol(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_HOLOL';
                        } else {
                            this.resetPackages();
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }
    transferToTorod() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_TOROD_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToTorod(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_TOROD';
                        } else {
                            this.resetPackages();
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToAramex() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_ARAMEX_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToAramex(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_ARAMEX';
                        } else {
                            this.resetPackages();
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    onHideAddPackage($event: any) {
        this.editSelectedPackage = null;
        this.displayAddPackage = !!event;
        this.displayAddPackageOb.next(false);

        const preSelectedBarcode = this.selectedPackageBarcode;
        this.selectedPackageBarcode = null;
        this.pageNumber = 0;
        this.getPackages(true, false, preSelectedBarcode);
        if (!this.driverId && !this.partnerId) {

            this.shipmentsService.getPackageByBarcode(this.editSelectedPackageBarcode, this.userService.userInfo.role, null, true).subscribe((pkg) => {
                this.scanPackage(pkg);
                this.isLoading = false;
            }, () => {
                playError();
                this.isLoading = false;
            });
        }
    }

    openEditPackage(shipment: any) {
        this.editSelectedPackageBarcode = shipment.barcode;
        this.editSelectedPackage = shipment;
        this.displayAddPackage = true;
        this.displayAddPackageOb.next(true);
    }

    findCodSum(scannedPackages: { [p: number]: PackageModel }) {
        let cod = 0;
        for (const pkgKey in scannedPackages) {
            cod += scannedPackages[pkgKey].cod;
        }
        return cod;
    }

    findCostSum(scannedPackages: { [p: number]: PackageModel }) {
        let cost = 0;
        for (const pkgKey in scannedPackages) {
            cost += scannedPackages[pkgKey].cost;
        }
        return cost;
    }

    private initButtons() {
        this.userService.getTransferToList().subscribe(res => {
            this.transferToList = res;
        });
    }

    objectValuesSortedDesc(packages) {
        return this.isReadByBarcode ? this.objectValues(packages).reverse() : this.objectValues(packages);
    }

    onOrderByChange() {

        let pkgs = this.objectValues(this.packages);
        pkgs = _.sortBy(pkgs, (e) => {
            if (this.orderBy && this.orderBy.value) {
                if (this.orderBy.value === 'RECEIVER_CITY') {
                    return e.destinationAddress.city;
                } else if (this.orderBy.value === 'RECEIVER_VILLAGE') {
                    return e.destinationAddress.village;
                } else if (this.orderBy.value === 'SENDER_NAME') {
                    return e.fullSenderName;
                }
            }
            return e.val;
        });
        this.packages = [];
        pkgs.forEach((e: any) => {

            const pkgBarcode = e.barcode;
            this.packages[pkgBarcode] = this.originalPackages[pkgBarcode];
        });
    }

    public setOrderByOptions() {
        this.orderByOption = READ_BY_BARCODE_ORDER_BY_LIST.map((value) => {
            const label = this.translateService.instant(`READ_BY_BARCODE.SORT_BY.${value}`);
            return {label, value};
        });
    }

    printSmbReport() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.shipmentsService.printSmbReport(body).subscribe(
            (res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;

            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    printJandTReport() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.shipmentsService.printJAndTReport(body).subscribe(
            (res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;

            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    readGroupOfBarcode() {
        const value = this.multipleBarcode;
        this.startReadGroupOfBarcodes = false;
        this.isLoading = true;

        let service;
        if (this.returnedModal) {
            service = this.shipmentsService.getReturnPackageByBarcodes(value);
        } else if (this.exportCODModal) {
            service = this.shipmentsService.getExportedCODByBarcodes(value);
        } else {
            service = this.shipmentsService.getPackageByBarcodes(value);
        }

        service.subscribe((items) => {
            items.map(item => {
                this.scanPackage(item);
            });
            this.isLoading = false;
        }, () => {
            playError();
            this.isLoading = false;
        });
        this.multipleBarcode = '';
    }

    public pickReturnedPackagesWithDriver() {
        this.isLoading = true;
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {
            backdrop: 'static',
            windowClass: 'verify-driver',
            size: 'lg'
        });
        modal.componentInstance.titleKey = 'ACCOUNTING.PICK_WITH_DRIVER';
        modal.componentInstance.showHubs = false;
        modal.componentInstance.isPickWithDriver = true;
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, driverId?: number }) => {
                if (status.isSuccess) {
                    const body = {};
                    body['massReturnedPackagesReportData'] = this.getSelectedPackages().map((pkg) => {
                        return {id: pkg.customerId, barcode: pkg.massReturnedPackagesReportBarcode};
                    });
                    this.shipmentsService.unloadCustomersReturnedPackages(body, status.driverId).subscribe(
                        (response) => {
                            this.translateService.get(
                                'ALERTS.PICK_PACKAGES_BY_DRIVER'
                            )
                                .subscribe(
                                    (message) => {
                                        this.confirmationService.confirm({
                                            message: this.userService.getCurrency() === 'SAR' ? message.replace('الرواجع', 'الرجيع') : message,
                                            rejectVisible: false,
                                            acceptLabel: 'ok'
                                        });
                                    }
                                );
                            this.getSelectedPackages().forEach(pkg => {
                                this.removePackage(pkg);
                            });
                            this.isLoading = false;
                        }, () => {
                            this.isLoading = false;
                        }
                    );
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            (error) => {
                this.isLoading = false;
            }
        );
    }

    public pickWithDriver() {
        this.isLoading = true;
        let pkgIds = [];
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.componentInstance.showDates = false;
        modal.componentInstance.showSortBy = false;
        modal.componentInstance.showConfig = false;
        modal.componentInstance.isPickWithDriver = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number }) => {
                this.isLoading = false;
                if (status.isSuccess) {
                    if (this.getSelectedPackages().length > 0) {
                        pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
                    }
                    this.usersService.pickWithDriver(status.driverId, pkgIds).subscribe(() => {
                        this.getSelectedPackages().forEach(pkg => {
                            this.removePackage(pkg);
                        });
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public confirmMessage() {
        this.isLoading = true;
        const modal = this.modalService.open(ConfirmMessageComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.text = 'RETURNED_PACKAGES.DELIVER_TO_SENDER_MESSAGE';
        modal.result.then(
            (status: { isSuccess: boolean, isPrintReport: boolean }) => {
                if (status.isSuccess) {
                    if (status.isPrintReport) {
                        this.print(null, null, this.getSelectedCustomerIdsAndBarcodes(), true);
                    } else {
                        this.deliverToSender();
                    }
                }
                this.isLoading = false;
            }
        ).catch(
            (error) => {
                this.isLoading = false;
            }
        );
    }

    public deliverToSender(body?) {
        this.isLoading = true;
        const myBody = {
            ids: (body ? body : this.getSelectedCustomerIdsAndBarcodes())
        };
        this.shipmentsService.deliverCustomersReturnedPackages(myBody).subscribe(() => {
            this.translateService.get(
                'ALERTS.PACKAGES_DELIVERED_SUCCESSFULLY'
            ).subscribe((data) => {
                    this.messageService.add({severity: 'success', detail: data});
                }
            );

            this.isLoading = false;
            this.isAllSelected = false;
            this.isThereSelection = false;
            this.getSelectedPackages().forEach(pkg => {
                this.removePackage(pkg);
            });
        }, () => {
            this.isLoading = false;
        });

    }

    public print(customer, event, customerIds = null, deliverToSender = false) {
        this.isLoading = true;
        const params = <any>{
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            returnPackageStatus: this.inCar ? 'DRIVER' : 'HUB'
        };
        if (this.selectedType && this.selectedType !== 'ALL') {
            params['type'] = this.selectedType;
        }
        if (this.inCar) {
            params['isReturnedWithDriver'] = true;
        }
        if (this.partiallyDelivered) {
            params['isPartiallyDelivered'] = true;
        }
        let body = [];
        if (!customerIds) {
            body = [{id: customer.customerId, barcode: customer.massReturnedPackagesReportBarcode}];
        } else {
            body = customerIds;
        }
        this.shipmentsService.printCustomerReturnedPackages(body, params)
            .subscribe((res: any) => {
                this.isLoading = false;
                if (deliverToSender) {
                    this.deliverToSender();
                }
                window.open(res.url, '_blank');
            }, () => {
                this.isLoading = false;
            });
        if (event !== null) {
            event.stopPropagation();
        }
    }

    createInvoiceConfirmation(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let title, confirmationMessage;
        if (shipment) {
            title = 'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICE';
            confirmationMessage = 'MANAGE_COD_CUSTOMERS.INVOICES.ALARMS.CREATE_INVOICE_CONFIRMATION';
        } else {
            title = 'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES';
            confirmationMessage = 'MANAGE_COD_CUSTOMERS.INVOICES.ALARMS.CREATE_MULTIPLE_INVOICE_CONFIRMATION';
        }

        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', size: 'sm'});

        modal.componentInstance.title = title;
        modal.componentInstance.confirmMessage = confirmationMessage;
        modal.componentInstance.confirmButtonLabel = 'ACTIONS.CREATE';
        modal.componentInstance.styleClass = 'logestechs-theme';
        if (shipment) {
            modal.componentInstance.switchButtonLabel = 'ACTIONS.PRINT';
        }
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    // create invoice api;
                    this.createInvoice(shipment, event, response.switchButtonValue);

                } else {
                    this.isLoading = false;
                }
            }
        );
    }

    createInvoice(shipment, event, isPrint = false) {
        const ids = shipment ? [shipment.id] : this.getSelectedPackages().map(pkg => pkg.id);
        const body = {ids: ids};

        this.usersService.createInvoice(body).subscribe(
            () => {
                this.getPackages();
                this.isAllSelected = false;
                this.isLoading = false;
                if (isPrint) {
                    this.printPackage(shipment, event);
                }
                this.getSelectedPackages().forEach(pkg => {
                    this.removePackage(pkg);
                });
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    actuallyPrintPackage(shipment, printType?: string, orderBy?) {
        const pkgId = shipment.id;
        this.shipmentsService.downloadMassPackagePdf(pkgId, printType, orderBy).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.error(error);
                this.isLoading = false;
            });
    }

    printPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        if (['EXPORTED_BY_ACCOUNTANT', 'WITH_DRIVER', 'DELIVERED'].indexOf(this.type) > -1) {
            const modal = this.modalService.open(PrintPackageOptionsComponent, {
                backdrop: 'static',
                windowClass: 'verify-driver',
                size: 'lg'
            });
            modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93;
            modal.result.then(
                (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                    if (status.isSuccess) {
                        this.actuallyPrintPackage(shipment, status.printType, status.orderBy);
                    } else {
                        this.isLoading = false;
                    }
                }
            );
        } else {
            this.actuallyPrintPackage(shipment);
        }
    }

    deliverMultiplePackages() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-payment-type-dialog', size: 'md'});
        modal.componentInstance.isInvoices = this.type === 'INVOICE';
        const successMessage = this.type === 'INVOICE' ? (selectedPackages.length > 1 ? 'ALERTS.INVOICES_PAID_SUCCESSFULLY' : 'ALERTS.INVOICE_PAID_SUCCESSFULLY') : 'ALERTS.MASS_COD_REPORT_DELIVERED_SUCCESSFULLY';
        modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, transferFees: number }) => {
                if (data.paymentType) {
                    this.usersService
                        .deliverMassPackages(selectedPackages, data.paymentType, data.documentNumber, data.deliveryDate, data.transferFees)
                        .subscribe(() => {
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant(successMessage)
                            });
                            this.isLoading = false;
                            this.getSelectedPackages().forEach(pkg => {
                                this.removePackage(pkg);
                            });
                        }, (err) => {
                            console.error(err);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
                this.isAllSelected = false;
            }
        );
    }

    getConcat(row: any, field: any) {
        return _.get(row, field);
    }

    getDeepData(row, keys) {
        let data = row[keys[0]];
        for (const key in keys) {
            if (key !== '0') {
                data = data[keys[key]];
            }
        }
        return data;
    }

    getLocalTime(dateTime) {
        if (dateTime) {
            return new Date(dateTime).toLocaleTimeString();
        }
        return;
    }

    private getSelectedCustomerIdsAndBarcodes() {
        const selectedCustomer = this.getSelectedPackages();
        return selectedCustomer.map(value => {
            return {id: value.customerId, barcode: (value.customerBarcode || value.barcode || value.massReturnedPackagesReportBarcode)};
        });
    }

    public canCreateInvoice() {
        return this.getSelectedPackages().length === 0 || this.getSelectedPackages().findIndex(pkg => !pkg.isCustomerBilled) !== -1;
    }

    public exportAsExcel() {
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.isLoading = true;
        this.shipmentsService.exportReadByBarcodePackages(selectedPackages).subscribe((response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    protected readonly IS_TO_FINAL_DESTINATION = IS_TO_FINAL_DESTINATION;
}
