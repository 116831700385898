<div class="receive-packages-container">
    <div class="header">
        {{('SHIPMENT.IMPORT_FROM_EXCEL') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
    </div>

    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div class="input-fields inputs table-actions">
        <div class="row" style="margin-bottom: 30px!important;">
            <div class="add-button cursor-pointer " style="margin: auto;font-size: 14px"
                 (click)="downloadExcelTemplate()">
                <i class="far fa-save"></i>
                {{'ACTIONS.DOWNLOAD_EXCEL_TEMPLATE' | translate }}
            </div>
        </div>
        <div class="row">
            <div class="add-to-container green cursor-pointer" (click)="uploadFile(fileChooser)"
                 [ngClass]="{'disabled-button disabled': isFileSelected}"
                 style="margin: auto;font-size: 14px">
                <i class="fas fa-file-csv"></i>
                {{"SHIPMENT.IMPORT_FROM_EXCEL" | translate}}
                <input type="file"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                       style="width: 0px; height: 0px; opacity: 0; position: absolute" #fileChooser
                       (change)="uploadExcel($event,false,true)">
            </div>
        </div>
    </div>
    <div class="row col-12">
        <div class="flex-1"></div>
        <div style="padding: 10px 15px">
            <div class="reset-button cursor-pointer " (click)="closeModal()">
                <i class="fas fa-times"></i>
                {{"ACTIONS.CANCEL" | translate}}
            </div>
        </div>
    </div>
</div>
