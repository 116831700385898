import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import {Subject} from 'rxjs';
import {getNumericHeaders, getNumericHeadersForNaouri, IExcelShipmentRow} from '../../shared/models/excel-shipment.model';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {isArray} from 'chart.js/helpers';

@Injectable({
    providedIn: 'root'
})
export class ShipmentsExcelService {

    private fileReader = new FileReader();

    private readSubject = new Subject<IExcelShipmentRow[]>();

    private englishHeaders;
    private arabicHeaders;

    private requiredFields = [
        'senderEmail',
        'receiverName',
        'receiverMobile',
        ['receiverVillage', 'receiverCity'],
        'receiverStreetAddress',
        'cod'
    ];
    private customTranslationCompanyNaouri = [255];

    constructor(
        protected translateService: TranslateService,
        private userService: UserService
    ) {
        this.fileReader.onload = (e) => {
            const arrayBuffer = this.fileReader.result;
            const data = new Uint8Array(arrayBuffer as ArrayBuffer);
            const arr = [];
            for (let i = 0; i !== data.length; ++i) {
                arr[i] = String.fromCharCode(data[i]);
            }
            const bstr = arr.join('');
            const workbook = XLSX.read(bstr, {type: 'binary'});
            const firstSheetName = workbook.SheetNames[0];
            const shipments = this.parseWorkSheet(workbook.Sheets[firstSheetName]);
            if (shipments.length === 0) {
                this.subjectError('ALERTS.EXCEL_IMPORT_NO_DATA');
            } else {
                if (this.customTranslationCompanyNaouri.indexOf(this.userService.userInfo.companyId) >= 0 ) {
                    const secondSheetName = workbook.SheetNames[1];
                    const shipmentSet = this.parseWorkSheet(workbook.Sheets[secondSheetName], true);
                    this.mergeSheetsForNaouri(shipments, shipmentSet);
                }

            }

            this.readSubject.next(shipments);


        };
        this.initHeadersTranslations();
    }


    private initHeadersTranslations() {
        this.userService.getTranslateLanguageForExcels('en').subscribe(
            (data: any) => {
                this.englishHeaders = data.ADD_SHIPMENT.EXCEL_HEADERS;
                if (this.customTranslationCompanyNaouri.indexOf(this.userService.userInfo.companyId) >= 0) {
                    this.englishHeaders = data.ADD_SHIPMENT.EXCEL_HEADERS.NAOURI_GROUP;

                }
            }
        );

        this.userService.getTranslateLanguageForExcels('ar').subscribe(
            (data: any) => {
                this.arabicHeaders = data.ADD_SHIPMENT.EXCEL_HEADERS;
                if (this.customTranslationCompanyNaouri.indexOf(this.userService.userInfo.companyId) >= 0) {
                    this.arabicHeaders = data.ADD_SHIPMENT.EXCEL_HEADERS.NAOURI_GROUP;
                }
            }
        );
    }

    public exportShipments(shipments) {
        const workSheet = XLSX.utils.json_to_sheet([
            this.getSecondHeader(),
            ...shipments.map(shipment => this.toErrorShipment(shipment))
        ]);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Errors');
        XLSX.writeFile(workBook, 'Import errors.xlsx');
    }

    public importShipments(file): Subject<IExcelShipmentRow[]> {
        this.fileReader.readAsArrayBuffer(file);
        return this.readSubject;
    }

    private getSecondHeader() {
        const headers = this.translateService.currentLang === 'en' ? this.englishHeaders : this.arabicHeaders;
        const secondHeader = this.translateService.currentLang === 'ar' ? this.englishHeaders : this.arabicHeaders;
        return {
            [headers['ROW_ID']]: secondHeader['ROW_ID'],
            [headers['SENDER_EMAIL']]: secondHeader['SENDER_EMAIL'],
            [headers['RECEIVER_NAME']]: secondHeader['RECEIVER_NAME'],
            [headers['RECEIVER_MOBILE']]: secondHeader['RECEIVER_MOBILE'],
            [headers['RECEIVER_VILLAGE']]: secondHeader['RECEIVER_VILLAGE'],
            [headers['RECEIVER_CITY']]: secondHeader['RECEIVER_CITY'],
            [headers['RECEIVER_STREET']]: secondHeader['RECEIVER_STREET'],
            [headers['RECEIVER_BUILDING']]: secondHeader['RECEIVER_BUILDING'],
            [headers['RECEIVER_FLAT']]: secondHeader['RECEIVER_FLAT'],
            [headers['RECEIVER_NOTES']]: secondHeader['RECEIVER_NOTES'],
            [headers['RECEIVER_BUSINESS_NAME']]: secondHeader['RECEIVER_BUSINESS_NAME'],
            [headers['COD']]: secondHeader['COD'],
            [headers['SHIPMENT_TYPE']]: secondHeader['SHIPMENT_TYPE'],
            [headers['SERVICE_TYPE']]: secondHeader['SERVICE_TYPE'],
            [headers['VEHICLE_TYPE']]: secondHeader['VEHICLE_TYPE'],
            [headers['DESCRIPTION']]: secondHeader['DESCRIPTION'],
            [headers['INVOICE_NUMBER']]: secondHeader['INVOICE_NUMBER'],
            [headers['SUPPLIER_INVOICE']]: secondHeader['SUPPLIER_INVOICE'],
            [headers['SENDER_NAME']]: secondHeader['SENDER_NAME'],
            [headers['SENDER_BUSINESS_NAME']]: secondHeader['SENDER_BUSINESS_NAME'],
        };
    }

    private toErrorShipment(shipment): any {
        const headers = this.translateService.currentLang === 'en' ? this.englishHeaders : this.arabicHeaders;
        return {
            [headers['ROW_ID']]: shipment.rowId,
            [headers['SENDER_EMAIL']]: shipment.senderEmail,
            [headers['RECEIVER_NAME']]: shipment.receiverName,
            [headers['RECEIVER_MOBILE']]: shipment.receiverMobile,
            [headers['RECEIVER_VILLAGE']]: shipment.receiverVillageName,
            [headers['RECEIVER_CITY']]: shipment.receiverCityName,
            [headers['RECEIVER_STREET']]: shipment.receiverStreetAddress,
            [headers['RECEIVER_BUILDING']]: shipment.receiverBuilding,
            [headers['RECEIVER_FLAT']]: shipment.receiverFlat,
            [headers['RECEIVER_NOTES']]: shipment.notes,
            [headers['RECEIVER_BUSINESS_NAME']]: shipment.businessReceiverName,
            [headers['COD']]: shipment.cod,
            [headers['SHIPMENT_TYPE']]: shipment.shipmentType,
            [headers['SERVICE_TYPE']]: shipment.serviceType,
            [headers['VEHICLE_TYPE']]: shipment.vehicleType,
            [headers['DESCRIPTION']]: shipment.description,
            [headers['INVOICE_NUMBER']]: shipment.invoiceNumber,
            [headers['SUPPLIER_INVOICE']]: shipment.supplierInvoice,
            [headers['SENDER_NAME']]: shipment.senderName,
            [headers['SENDER_BUSINESS_NAME']]: shipment.senderBusinessName,
        };
    }

    private subjectError(error: string) {
        this.readSubject.error(error);
        this.readSubject.unsubscribe();
        this.readSubject = new Subject();
    }

    private parseWorkSheet(worksheet, ignoreHeaderValidation = false) {
        const shipments = [];
        const headerMap = {};

        let sheetJSON;
        let nonBlankSheetJSON;
        if (this.customTranslationCompanyNaouri.indexOf(this.userService.userInfo.companyId) < 0) {
            sheetJSON = XLSX.utils.sheet_to_json(worksheet, {header: getNumericHeaders(), raw: true, blankrows: true});
            nonBlankSheetJSON = XLSX.utils.sheet_to_json(worksheet, {header: getNumericHeaders(), raw: true});
        } else {
            sheetJSON = XLSX.utils.sheet_to_json(worksheet, {header: getNumericHeadersForNaouri(150), raw: true, blankrows: true});
            nonBlankSheetJSON = XLSX.utils.sheet_to_json(worksheet, {header: getNumericHeadersForNaouri(150), raw: true});
        }

        let ContentCollection = [];

        if (nonBlankSheetJSON.length <= 1) {
            this.subjectError('ALERTS.EXCEL_IMPORT_NO_DATA');
            return;
        } else if (Object.keys(nonBlankSheetJSON[0]).length < this.requiredFields.length
            && Object.keys(nonBlankSheetJSON[1]).length < this.requiredFields.length ) {
            this.subjectError('ALERTS.EXCEL_IMPORT_INVALID_FORMAT');
            return;
        } else {

            let headerFound = false;
            let rowIndex = 0;
            for (const key of Object.keys(nonBlankSheetJSON[0])) {
                let fieldName = this.getFieldName(nonBlankSheetJSON[0][`${key}`]);
                if (fieldName !== 'undefined') {
                    headerMap[fieldName] = key;
                    headerFound = true;
                    rowIndex = 0;
                } else {
                    fieldName = this.getFieldName(nonBlankSheetJSON[1][`${key}`]);
                    if (fieldName !== 'undefined') {
                        headerMap[fieldName] = key;
                        headerFound = true;
                        rowIndex = 1;
                    }
                }
            }
            ContentCollection = this.handleContentCollection(nonBlankSheetJSON[rowIndex]);
            if (!this.isHeaderValid(headerMap) && !ignoreHeaderValidation) {
                this.subjectError('ALERTS.EXCEL_IMPORT_INVALID_FORMAT');
                return;
            }

            for (let i = 2; i < sheetJSON.length; ++i) {
                if (Object.keys(sheetJSON[i]).length > 1) {
                    shipments.push({
                        rowId: (i + 1),
                        shipmentInfo: {
                            senderEmail: sheetJSON[i][headerMap['senderEmail']] ? String(sheetJSON[i][headerMap['senderEmail']]) : null,
                            receiverName: sheetJSON[i][headerMap['receiverName']],
                            receiverMobile: sheetJSON[i][headerMap['receiverMobile']],
                            receiverVillage: sheetJSON[i][headerMap['receiverVillage']] ? String(sheetJSON[i][headerMap['receiverVillage']]) : null,
                            receiverCity: sheetJSON[i][headerMap['receiverCity']] ? String(sheetJSON[i][headerMap['receiverCity']]) : null,
                            receiverStreetAddress: sheetJSON[i][headerMap['receiverStreetAddress']] ? String(sheetJSON[i][headerMap['receiverStreetAddress']]) : null,
                            receiverBuilding: sheetJSON[i][headerMap['receiverBuilding']] ? String(sheetJSON[i][headerMap['receiverBuilding']]) : null,
                            receiverFlat: sheetJSON[i][headerMap['receiverFlat']] ? String(sheetJSON[i][headerMap['receiverFlat']]) : null,
                            businessReceiverName: sheetJSON[i][headerMap['businessReceiverName']] ? String(sheetJSON[i][headerMap['businessReceiverName']]) : null,
                            notes: headerMap['notes'] ? sheetJSON[i][headerMap['notes']] : null,
                            cod: sheetJSON[i][headerMap['cod']],
                            shipmentType: sheetJSON[i][headerMap['shipmentType']] ? sheetJSON[i][headerMap['shipmentType']].toUpperCase() : 'COD',
                            serviceType: this.userService.userInfo.isPricingPerServiceTypeEnabled ? sheetJSON[i][headerMap['serviceType']]
                                : sheetJSON[i][headerMap['serviceType']] ? sheetJSON[i][headerMap['serviceType']].toUpperCase() : 'STANDARD',
                            vehicleType: sheetJSON[i][headerMap['vehicleType']],
                            invoiceNumber: headerMap['invoiceNumber'] ? sheetJSON[i][headerMap['invoiceNumber']] : null,
                            supplierInvoice: headerMap['supplierInvoice'] ? sheetJSON[i][headerMap['supplierInvoice']] : null,
                            description: headerMap['description'] ? sheetJSON[i][headerMap['description']] : null,
                            senderName: headerMap['senderName'] ? sheetJSON[i][headerMap['senderName']] : null,
                            senderBusinessName: headerMap['senderBusinessName'] ? sheetJSON[i][headerMap['senderBusinessName']] : null,
                            quantity: headerMap['quantity'] ? sheetJSON[i][headerMap['quantity']] : 1,
                            packageContent: this.getPackageContent(sheetJSON[i], headerMap, ContentCollection),
                            codCollectionMethod: headerMap['codCollectionMethod'] ? sheetJSON[i][headerMap['codCollectionMethod']] : null,
                            weight: headerMap['weight'] ? sheetJSON[i][headerMap['weight']] : null,
                            codCollectionType: headerMap['codCollectionType'] ? sheetJSON[i][headerMap['codCollectionType']] : null,
                        }
                    });
                }
            }

            return shipments;
        }
    }

    private isHeaderValid(headerMap) {
        if (Object.keys(headerMap).length < this.requiredFields.length) {
            return false;
        }
        let isValid = true;
        if (this.customTranslationCompanyNaouri.indexOf(this.userService.userInfo.companyId) >= 0) {// skip validation
            return true;
        }
        this.requiredFields.forEach(field => {
            if (typeof field === 'string') {
                isValid = isValid && (headerMap[field] !== undefined);
            } else if (isArray(field)) {
                isValid = isValid && field.some(subField => {
                    return headerMap[subField] !== undefined;
                });
            }
        });
        return isValid;
    }

    private getFieldName(header: string): string {
        if (typeof header !== 'string') {
            return 'undefined';
        }
        switch (header.toLowerCase().trim()) {
            case this.englishHeaders['SENDER_EMAIL'].toLowerCase().trim():
            case this.arabicHeaders['SENDER_EMAIL']:
                return 'senderEmail';
            case this.englishHeaders['RECEIVER_NAME'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_NAME']:
                return 'receiverName';
            case this.englishHeaders['RECEIVER_MOBILE'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_MOBILE']:
                return 'receiverMobile';
            case this.englishHeaders['RECEIVER_VILLAGE'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_VILLAGE']:
                return 'receiverVillage';
            case this.englishHeaders['RECEIVER_CITY'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_CITY']:
                return 'receiverCity';
            case this.englishHeaders['RECEIVER_STREET'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_STREET']:
                return 'receiverStreetAddress';
            case this.englishHeaders['RECEIVER_BUILDING'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_BUILDING']:
                return 'receiverBuilding';
            case this.englishHeaders['RECEIVER_FLAT'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_FLAT']:
                return 'receiverFlat';
            case this.englishHeaders['RECEIVER_NOTES'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_NOTES']:
                return 'notes';
            case this.englishHeaders['COD'].toLowerCase().trim():
            case this.arabicHeaders['COD']:
                return 'cod';
            case this.englishHeaders['SHIPMENT_TYPE'].toLowerCase().trim():
            case this.arabicHeaders['SHIPMENT_TYPE']:
                return 'shipmentType';
            case this.englishHeaders['SERVICE_TYPE'].toLowerCase().trim():
            case this.arabicHeaders['SERVICE_TYPE']:
                return 'serviceType';
            case this.englishHeaders['VEHICLE_TYPE'].toLowerCase().trim():
            case this.arabicHeaders['VEHICLE_TYPE']:
                return 'vehicleType';
            case this.englishHeaders['INVOICE_NUMBER'].toLowerCase().trim():
            case this.arabicHeaders['INVOICE_NUMBER']:
            case this.englishHeaders['INVOICE_NUMBER_1'].toLowerCase().trim():
            case this.arabicHeaders['INVOICE_NUMBER_1']:
                return 'invoiceNumber';
            case this.englishHeaders['SUPPLIER_INVOICE'].toLowerCase().trim():
            case this.arabicHeaders['SUPPLIER_INVOICE']:
                return 'supplierInvoice';
            case this.englishHeaders['DESCRIPTION'].toLowerCase().trim():
            case this.arabicHeaders['DESCRIPTION']:
                return 'description';
            case this.englishHeaders['SENDER_NAME'].toLowerCase().trim():
            case this.arabicHeaders['SENDER_NAME']:
                return 'senderName';
            case this.englishHeaders['SENDER_BUSINESS_NAME'].toLowerCase().trim():
            case this.arabicHeaders['SENDER_BUSINESS_NAME']:
                return 'senderBusinessName';
            case this.englishHeaders['QUANTITY'].toLowerCase().trim():
            case this.arabicHeaders['QUANTITY']:
                return 'quantity';
            case this.englishHeaders['COD_COLLECTION_METHOD'].toLowerCase().trim():
            case this.arabicHeaders['COD_COLLECTION_METHOD']:
                return 'codCollectionMethod';
            case this.englishHeaders['RECEIVER_BUSINESS_NAME'].toLowerCase().trim():
            case this.arabicHeaders['RECEIVER_BUSINESS_NAME']:
                return 'businessReceiverName';
            case this.englishHeaders['WEIGHT'].toLowerCase().trim():
            case this.arabicHeaders['WEIGHT']:
                return 'weight';
            case this.englishHeaders['COD_COLLECTION_TYPE'].toLowerCase().trim():
            case this.arabicHeaders['COD_COLLECTION_TYPE']:
                return 'codCollectionType';
            default:
                return 'undefined';
        }
    }

    private handleContentCollection(sheet) {
        let list = [];
        const ContentCollection = [];
        if (this.customTranslationCompanyNaouri.indexOf(this.userService.userInfo.companyId) >= 0) {
            list = ['item:', 'order qty:'];

        }
        for (const key of Object.keys(sheet)) {
            if (list.indexOf(sheet[`${key}`].toLowerCase().trim()) >= 0) {
                ContentCollection.push(key);
            }
        }
        return ContentCollection;
    }

    private getPackageContent(sheetJSONElement: any, headerMap: {}, ContentCollection: any[]) {
        let baseContent = '';
        if (headerMap['packageContent']) {
            baseContent = sheetJSONElement[headerMap['packageContent']];
        }

        for (const key of ContentCollection) {
            if (sheetJSONElement[key]) {
                baseContent += ' ' + sheetJSONElement[key];
            }
        }

        if (!!baseContent) {
            return baseContent;
        }
        return null;
    }

    private mergeSheetsForNaouri(shipments: any[], shipmentSet: any[]) {
        if (!shipments && !shipments.length || !shipmentSet && !shipmentSet.length) {
            return;
        }
        shipments.forEach(value => {
            const description = shipmentSet.filter(value1 => value1.shipmentInfo.invoiceNumber === value.shipmentInfo.invoiceNumber)
                .map(value1 => value1.shipmentInfo.packageContent).join(', ');
            if (value.shipmentInfo.description && !!value.shipmentInfo.description) {
                value.shipmentInfo.description += ', ' + description;
            } else {
                value.shipmentInfo.description = description;
            }
        });
    }
}
