import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APIS} from './shared_constants/apis';
import {PARTNER_CONSTANTS} from '../../partners/partner-constants';

@Injectable({providedIn: 'root'})
export class DriversService {

    constructor(
        private http: HttpClient
    ) {
    }
    public getNameAndIdDriversList(query) {
        return this.http.get(APIS.GET_DRIVERS_NAME_AND_ID + query);
    }

    public getDrivers(query) {
        return this.http.get(APIS.GET_DRIVERS + query);
    }
    public getDriversWithPackagesCount(query) {
        return this.http.get(APIS.GET_DRIVERS_WITH_COUNT + query);
    }

    public getDriversWithoutPackagesCount(query) {
        return this.http.get(APIS.GET_DRIVERS_WITHOUT_PACKAGES + query);
    }

    public getDriverOptimizedRoute(driverId, params) {
        return this.http.get(APIS.GET_DRIVER_OPTIMIZED_ROUTE.replace('{driverId}', driverId), {
            params: params
        });
    }

    public getDriver(id) {
        return this.http.get(APIS.GET_DRIVER.replace('{driverId}', id));
    }

    public assignToDriver(driverId, ids) {
        const url = APIS.ASSIGN_TO_DRIVER.replace('{driverId}', driverId);
        return this.http.put(url, ids);
    }

    public getDriversLocations(query = '') {
        return this.http.get(APIS.GET_DRIVERS_LOCATIONS_WITH_STATUS + query);
    }
    public assignPickupsToDriver(driverId, ids) {
        const url = APIS.ASSIGN_PICKUPS_TO_DRIVER.replace('{driverId}', driverId);
        return this.http.put(url, ids);
    }
    public getDriverReturnedStatus(query) {
        const url = APIS.GET_DRIVERS_FAILURE_REASONS + query;
        return this.http.get(url);
    }
    public detectDriverTrack(ids) {
        return this.http.put(APIS.DETECT_DRIVER_TRACK, ids);
    }
    public getDriverCheckIns(driverId: number, params) {
        return this.http.get(APIS.GET_DRIVER_CHECK_INS.replace('{driverId}', `${driverId}`), {
            params: params
        });
    }

    public  getDriversTimeTracking(params) {
        return this.http.get(APIS.GET_DRIVERS_TIME_TRACKING, {
            params: params
        });
    }

    getDriversEarningsDetails(driverId, params) {
        return this.http.get(APIS.GET_DRIVERS_EARNINGS_DETAILS.replace('{driverId}', driverId), {
            params: params
        });
    }

    downloadDriversEarningsDetails(driverId, params, type) {
        return this.http.get(APIS.GET_DRIVERS_EARNINGS_DETAILS.replace('{driverId}', driverId) + '/' + type, {
            params: params
        });
    }

    public exportDriverCodPackagesToExcel(body, params) {
        return this.http.post(APIS.EXPORT_DRIVER_COD_PACKAGES_TO_EXCEL, body, {params});
    }
}
