<div class="products-container">
    <div class="products-list">
        <table>
            <tr>
                <th>
                    {{"PRODUCT.NAME" | translate}}
                </th>
                <th>
                    {{"PRODUCT.WEIGHT"| translate}}
                </th>
                <th>
                    {{"PRODUCT.QUANTITY"| translate}}
                </th>
                <th>
                    {{"PRODUCT.EACH_COST"| translate}}
                </th>
                <th>
                    {{"PRODUCT.TOTAL_COST"| translate}}
                </th>
                <th>
                    {{"ACTIONS.REMOVE"| translate}}
                </th>
            </tr>
            <tr *ngFor="let product of products">
                <td>
                    <input class="text-input" type="text" [disabled]="disabled" [(ngModel)]="product.name">
                </td>
                <td>
                    <input class="text-input" type="number" min="1" [disabled]="disabled" [(ngModel)]="product.weight" (input)="productChange(product)">
                </td>
                <td>
                    <input class="text-input" type="number" min="1" [disabled]="disabled" [(ngModel)]="product.quantity" (input)="productChange(product)">
                </td>
                <td>
                    <input class="text-input" type="number" min="1" [disabled]="disabled" [(ngModel)]="product.eachCost" (input)="productChange(product)">
                </td>
                <td>
                    <input class="text-input" type="number" min="1" disabled [(ngModel)]="product.totalCost">
                </td>
                <td class="remove-button-container">
                    <div class="remove-button cursor-pointer" (click)="removeProduct(product)">
                        <i class="fas fa-minus"></i>
                        {{"ACTIONS.REMOVE"| translate}}
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="add-new-product">
        <div class="add-button cursor-pointer" (click)="addProduct()">
            <i class="fas fa-plus"></i>
            {{"PRODUCT.ADD_PRODUCT"| translate}}
        </div>
    </div>
</div>