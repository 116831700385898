import {USERS_CONSTANTS} from '../users/services/constants';
import {DELIVERED_PACKAGES_DATE_FILTERS_LIST, EXPORTED_PACKAGES_DATE_FILTERS_LIST} from '../shipment/services/constants';

export enum CustodyTypes {
    CUSTOMER = 'CUSTOMER',
    HUB = 'HUB',
    DRIVER = 'DRIVER',
    PARTNER = 'PARTNER'

}

export enum CustomerTypes {
    ALL = 'ALL',
    NORMAL = 'NORMAL',
    EXPRESS = 'EXPRESS',
}

export const ACCOUNTING_CONSTANTS = {


    CUSTODY_TYPES_LIST: [
        {label: 'GENERAL.BY_CUSTOMERS', value: CustodyTypes.CUSTOMER},
        {label: 'GENERAL.BY_HUB', value: CustodyTypes.HUB},
        {label: 'GENERAL.BY_DRIVER', value: CustodyTypes.DRIVER},
        {label: 'GENERAL.BY_PARTNER', value: CustodyTypes.PARTNER}
    ],

    CUSTOMERS_TYPES_LIST: [
        {value: CustomerTypes.ALL, label: 'ALL'},
        {value: CustomerTypes.NORMAL, label: 'CUSTOMER_TYPES.NORMAL'},
        {value: CustomerTypes.EXPRESS, label: 'CUSTOMER_TYPES.EXPRESS'}
    ],

    EXPORTED_COD: {
        TITLE: 'ACCOUNTING.EXPORTED_COD_MENUITEM',
        API: USERS_CONSTANTS.GET_EXPORTED_COD_CUSTOMERS,
        INFO: [
            {header: 'GENERAL.BARCODE', field: 'barcode', type: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME',  field: 'customerName'},
            {header: 'ACCOUNTING.CUSTOMER_ADDRESS', field: 'customerCity'},
            {header: 'ACCOUNTING.CUSTOMER_HUB', field: 'customerHubName', hubFilter: true},
            {header: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', field: 'totalCodWithoutCost'},
            {header: 'MANAGE_COD_CUSTOMERS.COST_SUM', field: 'totalCost'},
            {header: 'MANAGE_COD_CUSTOMERS.COD_SUM', field: 'totalCod'},
            {header: 'ACCOUNTING.SORTED_COD_PACKAGES', field: 'codPackagesNumber'},
            {header: 'ACCOUNTING.EXPORTED_BY', field: 'exportedBy'},
            {header: 'ACCOUNTING.PAYMENT_TYPE', field: 'paymentType', type: 'translate' , withFilter: true}
        ],
        ROW_ACTIONS_SETTINGS: {
            deliverCod: true,
            print: true,
            printAsEnvelop: true,
            exportExcel: true,
            createInvoice: true,
            history: true
        },
        TOP_ACTIONS_SETTINGS: {
            pickWithDriver: true,
            deliver: true,
            exportSelectedToExcel: true,
            printMultiple: true,
            printAll: false,
            printList: true,
            createInvoice: true,
            createInvoiceForCustomers: true,
            exportPerCustomer: true,
            showReadByBarcode: true,
            printEnvelope: true
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showTypeFilter: true,
            showCustomerTypeFilter: true,
            showCodValueFilter: true
        },
        DATE_FILTERS_LIST: EXPORTED_PACKAGES_DATE_FILTERS_LIST
    },

    INVOICES: {
        TITLE: 'MANAGE_COD_CUSTOMERS.INVOICES.INVOICES',
        API: USERS_CONSTANTS.GET_EXPORTED_COD_CUSTOMERS + '?is-invoice=true',
        INFO: [
            {header: 'GENERAL.BARCODE', field: 'barcode', type: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME',  field: 'customerName'},
            {header: 'ACCOUNTING.CUSTOMER_ADDRESS', field: 'customerCity'},
            {header: 'ACCOUNTING.CUSTOMER_HUB', field: 'customerHubName', hubFilter: true},
            {header: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', field: 'totalCodWithoutCost'},
            {header: 'MANAGE_COD_CUSTOMERS.COST_SUM', field: 'totalCost'},
            {header: 'MANAGE_COD_CUSTOMERS.COD_SUM', field: 'totalCod'},
            {header: 'ACCOUNTING.SORTED_COD_PACKAGES', field: 'codPackagesNumber'},
            {header: 'ACCOUNTING.EXPORTED_BY', field: 'exportedBy'},
            {header: 'ACCOUNTING.PAYMENT_TYPE', field: 'paymentType', type: 'translate'}
        ],
        ROW_ACTIONS_SETTINGS: {
            deliverCod: true,
            print: true,
            printAsEnvelop: true,
            exportExcel: true,
            history: true
        },
        TOP_ACTIONS_SETTINGS: {
            deliver: true,
            printList: true,
            exportSelectedToExcel: true,
            printMultiple: true
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showTypeFilter: true,
            showCustomerTypeFilter: true,
            showCodValueFilter: true
        },
        DATE_FILTERS_LIST: EXPORTED_PACKAGES_DATE_FILTERS_LIST
    },
    MASS_INVOICES: {
        TITLE: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.TITLE',
        API: USERS_CONSTANTS.GET_MASS_INVOICES_REPORT,
        INFO: [
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.CUSTOMER_NAME',  field: 'customerName'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.COD_SUM',  field: 'codSum'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.COST_SUM',  field: 'costSum'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.INSURANCE_FEES_SUM',  field: 'insuranceFeesSum', isInsurance: true},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.ADDITIONAL_COD_FEES_SUM',  field: 'additionalCodFeesSum', isAdditionalFees: true},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.MASS_COD_REPORTS_TRANSFER_FEES_SUM',  field: 'massCodReportsTransferFeesSum'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.MASS_COD_PACKAGES_COUNT',  field: 'massCodReportsCount'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.PACKAGES_COUNT',  field: 'pkgsCount'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.STATUS',  field: 'status', type: 'translate'}
        ],
        ROW_ACTIONS_SETTINGS: {
            deliverCod: false,
            print: true,
            printAsEnvelop: false,
            pay: true,
            exportExcel: false,
            history: false
        },
        TOP_ACTIONS_SETTINGS: {
            deliver: false,
            printList: false,
            exportSelectedToExcel: false,
            printMultiple: false
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showTypeFilter: false,
            showCustomerTypeFilter: false,
            showCodValueFilter: false,
            showStatusInvoiceFilter: true
        },
        DATE_FILTERS_LIST: EXPORTED_PACKAGES_DATE_FILTERS_LIST
    },
    MASS_INVOICES_DETAILS: {
        TITLE: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.TITLE',
        API: USERS_CONSTANTS.GET_MASS_INVOICES_PACKAGES,
        INFO: [
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.BARCODE',  field: 'massReportBarcode', type: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.PACKAGES_COUNT',  field: 'pkgsCount'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.DELIVERY_DATE',  field: 'massReportDeliveryDate', type: 'date'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.MASS_COD_REPORTS_TRANSFER_FEES_SUM',  field: 'massCodReportsTransferFeesSum'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.INSURANCE_FEES_SUM',  field: 'insuranceFeesSum', isInsurance: true},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.ADDITIONAL_COD_FEES_SUM',  field: 'additionalCodFeesSum', isAdditionalFees: true},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.COST_SUM',  field: 'costSum'},
            {header: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.COD_SUM',  field: 'codSum'}
        ],
        ROW_ACTIONS_SETTINGS: {
            deliverCod: false,
            print: false,
            printAsEnvelop: false,
            exportExcel: false,
            history: false
        },
        TOP_ACTIONS_SETTINGS: {
            deliver: false,
            printList: false,

            exportSelectedToExcel: false,
            printMultiple: false
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showTypeFilter: false,
            showCustomerTypeFilter: false,
            showCodValueFilter: false
        },
        DATE_FILTERS_LIST: EXPORTED_PACKAGES_DATE_FILTERS_LIST
    },
    DELIVERED_COD: {
        TITLE: 'ACCOUNTING.DELIVERED_COD_MENUITEM',
        API: USERS_CONSTANTS.GET_DELIVERED_COD_CUSTOMERS,
        INFO: [
            {header: 'GENERAL.BARCODE', field: 'barcode', type: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME',  field: 'customerName'},
            {header: 'ACCOUNTING.CUSTOMER_ADDRESS', field: 'customerCity'},
            {header: 'ACCOUNTING.CUSTOMER_HUB', field: 'customerHubName', hubFilter: true},
            {header: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', field: 'totalCodWithoutCost'},
            {header: 'ACCOUNTING.DELIVERED_COD', field: 'totalCod'},
            {header: 'ACCOUNTING.DELIVERED_COD_PACKAGES', field: 'codPackagesNumber'},
            {header: 'ACCOUNTING.EXPORTED_BY', field: 'exportedBy'},
            {header: 'ACCOUNTING.PAYMENT_TYPE', field: 'paymentType', type: 'translate', withFilter: true},
            {header: 'ACCOUNTING.DELIVERED_BY', field: 'driverName'},
            {header: 'ADD_SHIPMENT.DELIVERY_DATE', field: 'deliveryDate', type: 'date'},
            {header: 'ADD_SHIPMENT.TRANSFER_FEES', field: 'transferFees'}
        ],
        ROW_ACTIONS_SETTINGS: {
            print: true,
            printAsEnvelop: true,
            exportExcel: true,
            history: true,
            signature: true,
            attachments: true
        },
        TOP_ACTIONS_SETTINGS: {
            printMultiple: true,
            printList: true,
            exportSelectedToExcel: true
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showTypeFilter: false,
            showCustomerTypeFilter: true,
            showCodValueFilter: true
        },
        DATE_FILTERS_LIST: DELIVERED_PACKAGES_DATE_FILTERS_LIST
    },

    IN_CAR_COD: {
        TITLE: 'ACCOUNTING.IN_CAR_COD_MENUITEM',
        API: USERS_CONSTANTS.GET_IN_CAR_COD_CUSTOMERS,
        INFO: [
            {header: 'GENERAL.BARCODE', field: 'barcode', type: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME',  field: 'customerName'},
            {header: 'ACCOUNTING.CUSTOMER_ADDRESS', field: 'customerCity'},
            {header: 'ACCOUNTING.CUSTOMER_HUB', field: 'customerHubName', hubFilter: true},
            {header: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', field: 'totalCodWithoutCost'},
            {header: 'ACCOUNTING.DELIVERED_COD', field: 'totalCod'},
            {header: 'ACCOUNTING.DELIVERED_COD_PACKAGES', field: 'codPackagesNumber'},
            {header: 'ACCOUNTING.PAYMENT_TYPE', field: 'paymentType', type: 'translate', withFilter: true},
            {header: 'ACCOUNTING.EXPORTED_BY', field: 'exportedBy'},
            {header: 'ACCOUNTING.PICKED_BY', field: 'pickedBy'},
            {header: 'ACCOUNTING.DELIVERED_BY_DRIVER_NAME', field: 'userName'},
            {header: 'ADD_SHIPMENT.DELIVERY_DATE', field: 'deliveryDate', type: 'date'}
        ],
        ROW_ACTIONS_SETTINGS: {
            print: true,
            printAsEnvelop: true,
            exportExcel: true,
            history: true
        },
        TOP_ACTIONS_SETTINGS: {
            printList: true,
            printDriverReports: true,
            changeStatusToExported: true,
            printEnvelope: true,
            changeDriver: true
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showCustomerTypeFilter: true,
            showCodValueFilter: true,
            showDriverFilter: true
        }
    },
    COD_PENDING_CUSTOMERS_APPROVAL: {
        TITLE: 'MANAGE_COD_CUSTOMERS.COD_PENDING_CUSTOMERS_APPROVALS',
        API: USERS_CONSTANTS.GET_EXPORTED_COD_CUSTOMERS + '?needs-confirmation=true',
        INFO: [
            {header: 'GENERAL.BARCODE', field: 'barcode', type: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME',  field: 'customerName'},
            {header: 'ACCOUNTING.CUSTOMER_ADDRESS', field: 'customerCity'},
            {header: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', field: 'totalCodWithoutCost'},
            {header: 'MANAGE_COD_CUSTOMERS.COST_SUM', field: 'totalCost'},
            {header: 'MANAGE_COD_CUSTOMERS.COD_SUM', field: 'totalCod'},
            {header: 'ACCOUNTING.SORTED_COD_PACKAGES', field: 'codPackagesNumber'},
            {header: 'ACCOUNTING.PAYMENT_TYPE', field: 'paymentType', type: 'translate'}
        ],
        ROW_ACTIONS_SETTINGS: {
            print: true,
            printAsEnvelop: true,
            exportExcel: true,
            history: true
        },
        TOP_ACTIONS_SETTINGS: {
            printMultiple: true,
            printList: true,
            exportSelectedToExcel: true
        },
        FILTERS_SETTINGS: {
            showCustomerFilter: true,
            showCustomerTypeFilter: true,
            showCodValueFilter: true
        }
    }
};
