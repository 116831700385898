import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../../users/services/users.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {DatePipe} from '@angular/common';
import {UserService} from '../../../shared/services/user.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {CompaniesService} from '../../../companies/services/companies.service';
import {ChooseExistingComponent} from '../../../shipment/choose-existing/choose-existing.component';
import {USERS_CONSTANTS} from '../../../users/services/constants';
import {CodCustomer} from '../../../shared/models/cod-customer';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {RECEIVE_DATE_FILTERS_LIST} from '../../../shipment/services/constants';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {MobComponent} from '../../../users/mob/mob.component';
import {ChooseDriverComponent} from '../../../shipment/choose-driver/choose-driver.component';
import {EnterBarcodeComponent} from '../../../shipment/enter-barcode/enter-barcode.component';
import {ImageSliderComponent} from '../../../shared/components/image-slider/image-slider.component';
import {SortCodComponent} from '../sort-cod/sort-cod.component';
import {ReceiveCodDialogComponent} from '../receive-cod-dialog/receive-cod-dialog.component';
import {ACCOUNTING_CONSTANTS} from '../../accounting-constants';
import {ChoosePaymentTypeComponent} from '../../../users/choose-payment-type/choose-payment-type.component';
import {UserListDropdownComponent} from '../../../shared/components/users-list-dropdown/users-list-dropdown.component';
import {
  CustomConfirmationMessageComponent
} from '../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';

@Component({
  selector: 'app-receive-cod-customers',
  templateUrl: './receive-cod-customers.component.html',
  styleUrls: ['./receive-cod-customers.component.scss']
})
export class ReceiveCodCustomersComponent implements OnInit {

  /**
   *
   */
  public customers: CodCustomer[];
  public custodyTypeList = ACCOUNTING_CONSTANTS.CUSTODY_TYPES_LIST;
  public paymentMethod: any;
  public customerHubId: string;
  public paymentsMethod;
  public paymentValue = [
    'ALL',
    'CASH',
    'CHEQUE',
    'BANK_TRANSFER',
    'PREPAID',
    'DIGITAL_WALLET',
    'CARD'
  ];
  public optionCustody = this.custodyTypeList[0];
  public spinnerState = SpinnerState.LOADING;
  public spinnerStates = SpinnerState;
  public isLoading = false;
  public customerId;
  public selectedPackages;
  // is all selected;
  public isAllSelected = false;
  public isThereSelection = false;
  public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
  public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
  permissions = '';
  public first = 0; // firstElement in paginator
  public totalGreaterThan = null;
  public pageNumber = 0;
  public totalRecords = 0;
  public searchContent = '';
  public fromDate;
  public toDate;
  public status;
  public receive = false;
  public sort = false;
  public isExport = false;
  public totalPackages = 0;
  public totalCod = 0;
  public totalCostSum = 0;
  public totalCodWithoutCost = 0;
  public totalPercentageFee = 0;
  public receivedMoney = 0;
  public mobReceivedMoney = 0;
  public selectedLanguage = '';
  public mobAvailable = false;
  public excludeSort = false;
  public params = {};
  public isSupportReceiveWithoutReleasingCustody = false;
  public isOnlyShowSignature = false;
  public receiveWithoutReleasingCustody = false;
  @ViewChild('table') table;
  public dateFiltersList;
  public selectedDateFilter = '';
  public selectedFilter = '';
  public selectedEntity = '';
  public currentLanguage = '';
  public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
  public digitFormat = '1.0-2';
  public dateRangPickerOptions;
  public sortByType;
  public customerTypes = ACCOUNTING_CONSTANTS.CUSTOMERS_TYPES_LIST;
  customerType;
  showCodFees = false;
  showSearchContainer = false;
  totalCostWithoutReceiveCustody = 0;
  isOperationManger = false;

  filterFormOn = false;
  @ViewChild('filtersFormOverLay') filtersFormOverLay;
  @ViewChild('selectedEntityComponent') selectedEntityComponent: UserListDropdownComponent;
  public totalLessThan = null;
  userRole: string;
  public totalCODFees = 0;

  sortType = null;
  sortField = null;
  // params: {
  //   type: { label: string, value: string },
  //   customerId: string,
  //   customerType: { label: string, value: string },
  //   threshold: string, terminus: string, fromDate: string, toDate: string, search: string
  // };
  isEnableVat: boolean;

  constructor(
      private router: Router,
      private usersService: UsersService,
      private shipmentsService: ShipmentsService,
      private dateRangePickerService: DateRangePickerService,
      private messageService: MessageService,
      public translateService: TranslateService,
      private modalService: NgbModal,
      private rolesService: RolesService,
      private route: ActivatedRoute,
      private datePipe: DatePipe,
      private userService: UserService,
      public authenticationService: AuthenticationService,
      private companyService: CompaniesService,
      private authService: AuthenticationService,
      private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.showCodFees = this.userService.getCurrency() === 'SAR';
    this.mobAvailable = this.userService.userInfo.isUseBankTransfer;
    this.userRole = this.userService.userInfo.role;
    this.isEnableVat = this.userService.userInfo.isEnableVat;
    this.isSupportReceiveWithoutReleasingCustody = this.userService.userInfo.isSupportReceiveWithoutReleasingCustody;
    this.currentLanguage = this.translateService.currentLang;
    this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
      this.dateRangPickerOptions = options;
    });
    this.getPermissions();
    this.selectedLanguage = this.translateService.currentLang;
    this.route.url.subscribe((data) => {
      switch (data[0].path) {
        case 'receive-cod':
          this.receive = true;
          this.getCompanyBillingNotification();
          break;
        case 'sort-cod':
          this.sort = true;
          break;
      }
    });
    this.dateFiltersList = this.setDateFiltersList();
    this.initDateFilter();
    this.initCustomersSumValues();
    this.paymentsMethod = this.getPaymentsMethod();
  }

  showFiltersMenu(event) {
    this.filtersFormOverLay.toggle(event);
    this.filterFormOn = true;
    event.stopPropagation();
  }


  public getPaymentsMethod() {
    return this.paymentValue.map((value) => {
      let label = value;
      this.translateService.get(value)
          .subscribe((data) => {
            label = data;
          });
      return {label, value};
    });
  }

  /**
   * Initialize the permissions allowed by the logged in user.
   */
  getPermissions() {
    const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
    this.isOperationManger = role == 'OPERATION_MANAGER';
    this.permissions = this.rolesService.getUserPermissions('ACCOUNTING', role);
  }

  public getCompanyBillingNotification() {
    this.companyService.getCompanyBillingNotification().subscribe(
        (response: { isNotify: boolean, isBlock: boolean, notificationMessage: string }) => {
          if (response.isNotify) {
            this.messageService.add({severity: 'error', detail: response.notificationMessage});
          }
        }, error => {
          console.error(error);
        }
    );
  }

  /**
   *
   */
  initCustomers() {
    this.spinnerState = SpinnerState.LOADING;
    const params = this.createParams();
    let type = 'COD';
    if (this.receive) {
      type = 'RECEIVE';
      if (this.receiveWithoutReleasingCustody) {
        type = 'is-hold-custody';
      }
    } else if (this.sort) {
      type = 'SORT';
    }
    this.usersService.getCodCustomers(params, type).subscribe(
        (response: {
          codCustomers?: any[], massCodPackages: any[], totalRecordsNo: number, totalPackagesNumber: number,
          totalCod: number, receivedMoney?: number, mobReceivedMoney?: number, costSum?: number, isExcludeSort?: any,
          totalCodWithoutCost?: number, id?: number
        }) => {
          this.customers = response.codCustomers ? response.codCustomers : response.massCodPackages;
          this.totalRecords = response.totalRecordsNo;
          if (response.mobReceivedMoney) {
            this.mobReceivedMoney = response.mobReceivedMoney;
          }
          if (response.receivedMoney) {
            this.receivedMoney = response.receivedMoney;
          }
          if (response.isExcludeSort) {
            this.excludeSort = response.isExcludeSort;
          }
          if (this.totalRecords > 0) {
            this.spinnerState = SpinnerState.LOADED;
          } else {
            this.spinnerState = SpinnerState.EMPTY;
            this.isThereSelection = false;
          }
        }, () => {
          this.spinnerState = SpinnerState.EMPTY;
          this.isThereSelection = false;
        }
    );
  }

  public createParams() {
    const params = {
      pageSize: this.pageSize,
      page: this.pageNumber + 1,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone

    };

    if (this.searchContent !== '') {
      params['search'] = this.searchContent;
    }
    if (this.fromDate) {
      params['fromDate'] = this.transformDate(this.fromDate);
    }
    if (this.toDate) {
      params['toDate'] = this.transformDate(this.toDate);
    }
    if (this.totalGreaterThan !== null) {
      params['threshold'] = this.totalGreaterThan;
    }
    if (this.customerId) {
      params[(this.receiveWithoutReleasingCustody ? 'entityId' : 'customerId')] = this.customerId;
    }
    if (this.status) {
      params['type'] = this.status;
    }
    if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
      params['dateFilterType'] = this.selectedDateFilter;
    }
    if (this.receiveWithoutReleasingCustody) {
      params['type'] = this.optionCustody.value;
    }
    if (this.paymentMethod && this.paymentMethod !== 'ALL') {
      params['paymentMethod'] = this.paymentMethod;
    }
    if (this.customerHubId) {
      params['customerHubId'] = this.customerHubId;
    }
    if (this.totalGreaterThan !== null) {
      params['threshold'] = this.totalGreaterThan;
    }
    if (this.totalLessThan !== null) {
      params['terminus'] = this.totalLessThan;
    }
    if (this.sortByType) {
      params['sortByType'] = this.sortByType;
    }
    if (!!this.customerType && this.customerType.value !== 'ALL') {
      params['customerType'] = this.customerType.value;
    }

    if (this.sortType) {
      params['sortBy'] = `${this.sortField}_${this.sortType}`;
    }
    return params;
  }

  public setDateFiltersList() {
    const dateFiltersList = RECEIVE_DATE_FILTERS_LIST;
    return dateFiltersList.map((value) => {
      let label = value;
      this.translateService.get(value)
          .subscribe((data) => {
            label = data;
          });
      return {label, value};
    });
  }

  public initDateFilter() {
      this.selectedDateFilter = undefined;
  }

  private deleteDatesParams() {
    const queryParams = {...this.params};
    delete queryParams['fromDate'];
    delete queryParams['toDate'];
    if (queryParams['fromDate']) {
      delete queryParams['fromDate'];
    }
    if (queryParams['toDate']) {
      delete queryParams['toDate'];
    }
  }

  resetFilters() {
    this.selectedEntity = '';
    if (this.selectedEntityComponent && this.selectedEntityComponent.resetUser) {
      this.selectedEntityComponent.resetUser();
    }
    this.customerType = null;
    this.totalGreaterThan = this.totalLessThan = null;
    this.optionCustody = this.custodyTypeList[0];
    this.onSearch();
  }

  public onDateFilterChange() {
    if (this.selectedDateFilter === 'NONE') {
      this.fromDate = '';
      this.toDate = '';
      this.deleteDatesParams();
      this.setQueryParams();
      this.initCustomers();
      this.initCustomersSumValues();
    } else {
        this.setQueryParams();
        this.initCustomers();
        this.initCustomersSumValues();
    }
  }

  public loadCustomersLazy($event) {
    this.pageNumber = $event.first / $event.rows;
    this.pageSize = $event.rows;
    this.first = $event.first;
    this.initCustomers();
  }


  public onSearch() {
    this.pageNumber = 0;
    this.first = 0;
    this.setQueryParams();
    this.table.reset();
    this.initCustomersSumValues();
    this.filtersFormOverLay.hide();
  }

  public copyText(barcode) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = barcode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.translateService.get('ALERTS.COPIED').subscribe(
        (translateValue) => {
          this.messageService.add({severity: 'success', detail: translateValue});
        }
    );
  }

  public goToCustomerCOD(customer) {
    // if (this.receive) {
    let name = '';
    if (customer && customer.customerName) {
      const index = customer.customerName.indexOf('(');
      if (index > -1) {
        name = customer.customerName.slice(0, index).trim();
      } else {
        name = customer.customerName.trim();
      }
    }
      const id = (customer.id) ? customer.id : customer.customerId;
      this.openSortCodDialog(id, name);
    // }
  }

  public receivePackages() {
    const modal = this.modalService.open(ReceiveCodDialogComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'xl'});
    modal.result.then(
        (res) => {
          if (res && res.needReload) {
            this.onSearch();
          }
        });
  }

  public exportToPdf() {
    this.isLoading = true;
    this.usersService.exportToPdf(this.createParams()).subscribe((res: any) => {
      window.open(res.url, '_blank');
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
  public mob() {
    const modal = this.modalService.open(MobComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
    modal.result.then((status: { isSuccess: boolean }) => {
      if (status.isSuccess) {
        // here
      }
    });

  }

  public sortPackages() {
    const modal = this.modalService.open(ChooseExistingComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
    modal.componentInstance.customersURL = USERS_CONSTANTS.GET_RECEIVED_COD_CUSTOMERS;
    modal.componentInstance.customersKey = 'codCustomers';
    modal.componentInstance.isFullName = true;
    modal.result.then(
        (status: { isSuccess: boolean, user: any }) => {
          if (status.isSuccess) {
            this.router.navigate([this.authenticationService.companyName + 'home/sort-cod/'
            + status.user.customerId]);
          }
        }
    ).catch(
        () => {
        }
    );
  }

  public exportPackage(shipment, event) {
    event.stopPropagation();

    const modal = this.modalService.open(CustomConfirmationMessageComponent,
        <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', size: 'sm'});
    modal.componentInstance.title = this.translateService.instant('ACCOUNTING.EXPORT');
    modal.componentInstance.confirmMessage = this.translateService.instant('ACCOUNTING.CONFIRM_EXPORTING_COD');
    modal.componentInstance.confirmButtonLabel = 'GENERAL.YES';
    modal.componentInstance.styleClass = 'logestechs-theme';
    if (shipment) {
      modal.componentInstance.switchButtonLabel = 'ACTIONS.PRINT';
    }
    modal.result.then(
        (response) => {
          if (response.confirm) {
            // create invoice api;
            this.isLoading = true;
            let pkgIds = [];
            if (shipment.id) {
              pkgIds.push(shipment.id);
            } else {
              if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
              }
            }

            if (pkgIds.length === 0) {
              return;
            }
            this.isAllSelected = false;
            this.usersService.exportMassPakcage(pkgIds).subscribe(() => {
              this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.MASS_PACKAGE_EXPORTED_SUCCESSFULLY')});
              this.isLoading = false;
              this.initCustomers();
              this.initCustomersSumValues();

              if (response.switchButtonValue){
                this.printSinglePackageWhenExported(pkgIds);
              }
            }, () => {
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }
        }
    );

  }

  public printSinglePackageWhenExported(pkgIds) {
        this.shipmentsService.downloadMultipleMassPackages(pkgIds).subscribe(
            () => {
              this.translateService.get(
                  'ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'
              ).subscribe(
                  (message) => {
                    this.messageService.add({severity: 'success', detail: message});
                    this.isLoading = false;
                  }, () => {
                    this.isLoading = false;
                  }
              );
            }, (error) => {
              console.error(error);
              this.isLoading = false;
            }
        );
  }


  private transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  public printPackage(shipment, event) {
    event.stopPropagation();
    this.actuallyPrintPackage(shipment);
  }

  private actuallyPrintPackage(shipment, printType?: string, orderBy?) {
    const pkgId = shipment.id;
    this.shipmentsService.downloadMassPackagePdf(pkgId, printType, orderBy).subscribe((res: any) => {
          window.open(res.url, '_blank');
          this.isLoading = false;
        },
        error => {
          console.log(error);
          this.isLoading = false;
        });
  }

  public sortPackage(shipment, event) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('ACCOUNTING.CONFIRM_SORTING_COD'),
      accept: () => {
        setTimeout(() => {
          acceptAction();
        }, 30);
      }
    });
    const acceptAction = () => {
      this.isLoading = true;
      let pkgIds = [];
      if (shipment.customerId) {
        pkgIds.push(shipment.customerId);
      } else {
        if (this.getSelectedPackages().length > 0) {
          pkgIds = this.getSelectedPackages().map(pkg => pkg.customerId);
        }
      }

      if (pkgIds.length === 0) {
        return;
      }
      this.isAllSelected = false;
      const params = {};
      this.shipmentsService.sortUserPackages(pkgIds, params, this.receiveWithoutReleasingCustody).subscribe(() => {
            this.translateService.get(
                'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                      this.messageService.add({severity: 'success', detail: data});
                      this.initCustomers();
                      this.initCustomersSumValues();
                    }
                );
            this.isLoading = false;
          },
          error => {
            console.log(error);
            this.translateService.get(
                'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                      this.messageService.add({severity: 'error', detail: data});
                      this.initCustomers();
                      this.initCustomersSumValues();
                    }
                );
            this.isLoading = false;
          });

    };
  }

  public onToggleSelectAll() {
    if (this.totalRecords === 0) {
      return;
    }
    let selected = false;
    if (this.isAllSelected) {
      selected = true;
    }

    this.isThereSelection = false;
    this.customers.forEach((pkg: any) => {
      if (selected) {
        pkg.isSelected = selected;
        this.isThereSelection = true;

      } else {
        pkg.isSelected = selected;
      }
    });
  }

  public onToggleSelection() {
    const selectedPackages = this.getSelectedPackages();
    if (selectedPackages) {
      this.isThereSelection = selectedPackages.length !== 0;
      this.isAllSelected = selectedPackages.length === this.customers.length;
    }
    this.disableCreateMultipleInvoices();
  }

  private getSelectedPackages() {
    const selectedPackages = [];
    if (!this.customers) {
      return;
    }

    this.customers.forEach(
        (pkg) => {
          if (pkg.isSelected) {
            selectedPackages.push(pkg);
          }
        }
    );
    return selectedPackages;
  }

  public downloadMultiplePackages(all = false) {
    this.isLoading = true;
      if (all) {
        this.DownloadAllPackages();
      } else {
        this.actuallyDownloadPackages();
      }
  }

  private actuallyDownloadPackages(printType?: string, orderBy?) {
    this.isLoading = true;
    this.translateService.get(
        'ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'
    ).subscribe(
        (message) => {
          this.messageService.add({severity: 'success', detail: message});
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        }
    );
    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
    this.shipmentsService.downloadMultipleMassPackages(selectedPackages, printType, orderBy).subscribe(
        () => {
        }, (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  private DownloadAllPackages(printType?: string, orderBy?) {
    this.isLoading = true;
    this.translateService.get(
        'ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'
    ).subscribe(
        (message) => {
          this.messageService.add({severity: 'success', detail: message, life: 10000});
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        }
    );
    this.shipmentsService.downloadAllMassPackages(printType).subscribe(
        () => {
        }, (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  public deliverMultiplePackages() {
    this.isLoading = true;
    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
    const modal = this.modalService.open(ChoosePaymentTypeComponent,
        {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
    const successMessage = 'ALERTS.MASS_PACKAGE_DELIVERED_SUCCESSFULLY';
    modal.result.then(
        (data: { paymentType: any, documentNumber: any, deliveryDate: any, transferFees: number }) => {
          if (data.paymentType) {
            this.usersService
                .deliverMassPackages(selectedPackages, data.paymentType, data.documentNumber, data.deliveryDate, data.transferFees)
                .subscribe(() => {
                  this.translateService.get(successMessage)
                      .subscribe(
                          (message) => {
                            this.messageService.add({severity: 'success', detail: message});
                            this.isLoading = false;
                            this.initCustomers();
                            this.initCustomersSumValues();
                          }
                      );
                }, (err) => {
                  console.log(err);
                  this.isLoading = false;
                });
          } else {
            this.isLoading = false;
          }
          this.isAllSelected = false;
        }
    ).catch(
        () => {
        }
    );
  }

  public stopProp(event) {
    event.stopPropagation();
  }

  public setPaginatorQueryParam() {
    this.setQueryParams();

  }

  private setQueryParams() {
    const queryParams = {...this.params};
    if (this.totalGreaterThan !== null) {
      queryParams['threshold'] = this.totalGreaterThan;
    } else {
      delete queryParams['threshold'];
    }
    if (this.totalLessThan !== null) {
      queryParams['terminus'] = this.totalLessThan;
    } else {
      delete queryParams['terminus'];
    }
    if (this.searchContent) {
      queryParams['search'] = this.searchContent;
    } else {
      delete queryParams['search'];
    }

    if (this.selectedDateFilter) {
      queryParams['dateFilterType'] = this.selectedDateFilter;
    } else {
      delete queryParams['dateFilterType'];
    }

    if (this.fromDate) {
      queryParams['fromDate'] = this.fromDate;
    } else {
      delete queryParams['fromDate'];
    }
    if (this.toDate) {
      queryParams['toDate'] = this.toDate;
    } else {
      delete queryParams['toDate'];
    }
    if (this.checkNeedsReset()) {
      this.first = 0;
      this.pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    }
    queryParams['first'] = this.first;
    queryParams['pageSize'] = this.pageSize;
    this.router.navigate([], {queryParams: queryParams}).then(() => {

    });
  }

  private checkNeedsReset() {
    const queryParams = {...this.params};
    let boolFromDate = false;
    let boolToDate = false;
    if (queryParams['fromDate'] !== undefined && this.fromDate) {
      boolFromDate = this.fromDate.getTime() !== new Date(queryParams['fromDate']).getTime();
    }
    if (queryParams['toDate'] !== undefined && this.toDate) {
      boolToDate = this.toDate.getTime() !== new Date(queryParams['toDate']).getTime();
    }
    return (queryParams['search'] !== undefined && this.searchContent !== queryParams['search']) ||
        boolFromDate || boolToDate;

  }

  public printDriverMassReports() {
    this.isLoading = true;
    const modal = this.modalService.open(ChooseDriverComponent, <any>{
      backdrop: 'static',
      windowClass: 'assign-package-to-modal',
      size: 'md'
    });
    modal.componentInstance.showHubs = false;
    modal.componentInstance.showDates = false;
    modal.componentInstance.isExportInCarPkgs = true;
    modal.result.then(
        (status: { isSuccess: boolean, driverId?: number }) => {
          if (status.isSuccess) {
            this.usersService.printDriverMassReports(status.driverId).subscribe((res: any) => {
                  window.open(res.url, '_blank');
                  this.isLoading = false;
                },
                error => {
                  console.log(error);
                  this.isLoading = false;
                });
          } else {
            this.isLoading = false;
          }
        }
    ).catch(
        () => {
        }
    );
  }

  public changeStatusToExported(shipment) {
    this.isLoading = true;
    let pkgIds = [];
    if (shipment.id) {
      pkgIds.push(shipment.id);
    } else {
      if (this.getSelectedPackages().length > 0) {
        pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
      }
    }

    if (pkgIds.length === 0) {
      return;
    }
    this.isAllSelected = false;
    this.isThereSelection = false;
    this.usersService.changeStatusToExported(pkgIds).subscribe(() => {
      this.translateService.get(
          'ALERTS.CHANGED_STATUS'
      )
          .subscribe(
              (data) => {
                this.messageService.add({severity: 'success', detail: data});
                this.isLoading = false;
                this.initCustomers();
                this.initCustomersSumValues();
              }
          );
    }, () => {
      this.isLoading = false;
    });
  }

  public initCustomersSumValues() {
    const params = {};
    let getSumValues;

    if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
      params['dateFilterType'] = this.selectedDateFilter;
    } else {
      delete params['dateFilterType'];
    }

    if (!!this.customerType && this.customerType.value !== 'ALL') {
      params['customerType'] = this.customerType.value;
    }

    if (this.customerId) {
      params['customerId'] = this.customerId;
    } else {
      delete params['customerId'];
    }

    if (this.searchContent) {
      params['search'] = this.searchContent;
    } else {
      delete params['search'];
    }

    if (this.fromDate) {
      params['fromDate'] = this.transformDate(this.fromDate);
    } else {
      delete params['fromDate'];
    }

    if (this.toDate) {
      params['toDate'] = this.transformDate(this.toDate);
    } else {
      delete params['toDate'];
    }
    if (this.totalGreaterThan !== null) {
      params['threshold'] = this.totalGreaterThan;
    }
    if (this.totalLessThan !== null) {
      params['terminus'] = this.totalLessThan;
    }
    if (this.fromDate || this.toDate) {
      params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      delete params['timezone'];
    }

    if (this.paymentMethod && this.paymentMethod !== 'ALL') {
      params['paymentMethod'] = this.paymentMethod;
    }

    if (this.customerHubId) {
      params['customerHubId'] = this.customerHubId;
    }

    if (this.receive) {
      if (this.receiveWithoutReleasingCustody) {
        return;
      }
      this.spinnerState = SpinnerState.LOADING;
      getSumValues = this.usersService.getReciveCustomerSumValues(params);
    } else {
      if (this.sort) {
        params['status'] = 'SORTED_BY_ACCOUNTANT';
      }
      this.spinnerState = SpinnerState.LOADING;
      getSumValues = this.usersService.getCostumersSumValues(params);
    }
    getSumValues.subscribe(
        (response: any) => {
          this.totalPackages = response.totalPackagesNumber;
          this.totalCod = response.totalCod;
          this.totalCodWithoutCost = response.totalCodWithoutCost;
          this.totalCostSum = response.totalCost;
          this.totalCODFees = response.codFeesSum;
          if (this.isSupportReceiveWithoutReleasingCustody && this.sort) {
            this.totalCostWithoutReceiveCustody = response.codSumWithoutReleasingCustody;
          }
          if ((response.totalPercentageFee !== undefined && response.totalPercentageFee &&
              (this.authenticationService.companyId === 37 || this.authenticationService.companyId === 1) &&
              this.sort || this.receive)) {
            this.totalPercentageFee = response.totalPercentageFee;
          }
          this.spinnerState = SpinnerState.LOADED;
        }, (error) => {
          this.spinnerState = SpinnerState.LOADED;
          console.error(error);
        }
    );

  }

  public pickWithDriver() {
    this.isLoading = true;
    let pkgIds = [];
    const modal = this.modalService.open(ChooseDriverComponent, <any>{
      backdrop: 'static',
      windowClass: 'assign-package-to-modal',
      size: 'md'
    });
    modal.componentInstance.showHubs = false;
    modal.componentInstance.showDates = false;
    modal.componentInstance.isPickWithDriver = true;
    modal.result.then(
        (status: { isSuccess: boolean, driverId?: number }) => {
          this.isLoading = false;
          if (status.isSuccess) {
            if (this.getSelectedPackages().length > 0) {
              pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
            }
            this.usersService.pickWithDriver(status.driverId, pkgIds).subscribe(() => {
              this.initCustomers();
              this.initCustomersSumValues();
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }
        }
    ).catch(
        () => {
        }
    );
  }

  public exportToExcel(shipment, event, receive = false) {
    event.stopPropagation();
    this.isLoading = true;
    if (receive) {
      this.shipmentsService.exportToExcelForReceivedCod(shipment.customerId, this.createParams()).subscribe((res: any) => {
        window.open(res.url, '_blank');
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      });
    } else {
      const pkgId = shipment.id;
      this.shipmentsService.exportToExcel(pkgId, this.createParams()).subscribe((res: any) => {
        window.open(res.url, '_blank');
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      });
    }
  }

  disableCreateMultipleInvoices() {
    const selectedPackages = this.getSelectedPackages() ? this.getSelectedPackages() : [];
    let enabledCustomersBilling = false;
    if (selectedPackages.length) {
      let customersWithOutBilling = [];
      customersWithOutBilling = this.getSelectedPackages().filter(customer => customer.isCustomerBilled === false);
      enabledCustomersBilling = !customersWithOutBilling.length;
    }
    return !enabledCustomersBilling || !this.isThereSelection || this.selectedDateFilter !== 'NONE';
  }

  returnFromCustody() {
    this.customers = [];
    this.receiveWithoutReleasingCustody = false;
    this.searchContent = '';
    this.totalGreaterThan = null;
    this.totalLessThan = null;
    this.params = '';
    this.customerId = undefined;
    this.selectedFilter = '';
    this.selectedEntity = '';
    if(this.selectedEntityComponent && this.selectedEntityComponent.resetUser) {
      this.selectedEntityComponent.resetUser();
    }
    this.initCustomers();
  }

  onCustodyTypeChange(val) {
    this.customers = [];
    this.receiveWithoutReleasingCustody = true;
    this.optionCustody = (val === null ? this.custodyTypeList[0] : val.value);
    this.searchContent = '';
    this.totalGreaterThan = null;
    this.totalLessThan = null;
    this.params = '';
    this.customerId = undefined;
    this.selectedFilter = '';
    this.selectedEntity = '';
    if(this.selectedEntityComponent && this.selectedEntityComponent.resetUser) {
      this.selectedEntityComponent.resetUser();
    }
    this.initCustomers();
  }

  public onFilterSelectedChange(id) {
    this.customerId = (id === undefined ? '' : id);
  }

  public findPackageByBarcode() {
    const modal = this.modalService.open(EnterBarcodeComponent,
        <any>{size: 'md', windowClass: 'enter-barcode-popover', keyboard: true});
    modal.componentInstance.onlyReturnBarcode = true;
    modal.result.then(
        (data: { isSuccess: boolean, id: number }) => {
          if (data && data.isSuccess) {
            this.showPackageHistory(data.id);
          }
        }
    ).catch(
        (error) => {
          console.error(error);
        }
    );
  }

  public showPackageHistory(id = 0) {
    this.router.navigate([this.authService.companyName + 'home/receive-cod/package-history/', id]);
  }

  openExcel() {
    this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
    ).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
        accept: () => {
          this.isLoading = true;
          const selectedPackages = this.getSelectedPackages().map(pkg => pkg.customerId);
          const params = this.createParams();
          params['ids'] = selectedPackages;
          const exportedPackages = this.shipmentsService.downloadExcelForSortCod(params);
          exportedPackages.subscribe((response: any) => {
            window.open(response.url, '_blank');
            this.isLoading = false;
          });
        },
        reject: () => {
          this.isLoading = false;
        },
        acceptLabel: res['GENERAL.YES'],
        rejectLabel: res['GENERAL.NO'],
      });
    });
  }

  onPaymentSelected() {
    this.initCustomers();
  }

  public openSignatureImages($event, deliveryProofUrlList) {
    this.stopProp($event);
    const modal = this.modalService.open(ImageSliderComponent, <any>{
      backdrop: 'static',
      windowClass: 'assign-package-to-modal',
      size: 'md'
    });
    modal.componentInstance.isModal = true;
    modal.componentInstance.images = deliveryProofUrlList;
  }

  printList() {
    this.isLoading = true;
    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
    this.shipmentsService.downloadMultipleMassReports(selectedPackages).subscribe(
        (response: any) => {
          window.open(response.url, '_blank');
          this.isLoading = false;
        }, (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  onDateSelected(value) {
    this.fromDate = new Date(value.start);
    this.toDate = new Date(value.end);
    this.onSearch();
  }

  triggerMobileActions(event, dropdown) {
    dropdown.toggle(event);
  }

  getMobileKeys() {
    const obj = {};

    if (this.sort) {
      obj['barcode'] =  {label: 'MANAGE_SHIPMENTS.BARCODE', icon: '../../../assets/icons/package_card/box.svg'};
    }
    if (!this.receiveWithoutReleasingCustody) {
      obj['name'] = 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME';
    }
    if (this.receiveWithoutReleasingCustody) {
      const customerLabel =  this.receiveWithoutReleasingCustody ? ('MANAGE_COD_CUSTOMERS.' +  this.optionCustody.value + '_NAME') : 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME';
      if (this.receiveWithoutReleasingCustody) {
        obj['name'] = {label: customerLabel, icon: '../../../assets/icons/package_card/user.svg'};
      } else {
        obj['customerName'] = {label: customerLabel, icon: '../../../assets/icons/package_card/user.svg'};
      }
    }

    if (this.receiveWithoutReleasingCustody && this.optionCustody.value === 'CUSTOMER') {
      obj['city'] = {label: 'ACCOUNTING.CUSTOMER_ADDRESS', icon: '../../../assets/icons/package_card/location.svg'};
    }
    if (this.receive || this.sort || this.receiveWithoutReleasingCustody) {
      obj['totalCodWithoutCost'] = {label: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', icon: '../../../assets/icons/package_card/invoice.svg'};
    }
    if (this.receive || this.receiveWithoutReleasingCustody) {
      obj['costSum'] = {label: 'MANAGE_COD_CUSTOMERS.COST_SUM', icon: '../../../assets/icons/package_card/invoice.svg'};
    }
    if (this.sort) {
      obj['totalCost'] = {label: 'MANAGE_COD_CUSTOMERS.COST_SUM', icon: '../../../assets/icons/package_card/invoice.svg'};
    }

    if (this.receive || this.receiveWithoutReleasingCustody) {
      obj['totalReceivedCod'] = {
        // tslint:disable-next-line:max-line-length
        label: 'ACCOUNTING.COD_SUM',
        icon: '../../../assets/icons/package_card/invoice.svg'};
    }
    if (this.sort) {
      obj['totalCod'] = {label: 'MANAGE_COD_CUSTOMERS.COD_SUM', icon: '../../../assets/icons/package_card/invoice.svg'};
    }
    if (this.receive || this.receiveWithoutReleasingCustody) {
      obj['receivedCodPackagesNumber'] = {label: this.receiveWithoutReleasingCustody ? 'ACCOUNTING.RECEIVED_COD_PACKAGES_CUSTODY' : 'ACCOUNTING.RECEIVED_COD_PACKAGES',
        icon: '../../../assets/icons/package_card/invoice.svg'};
    }
    if (this.sort) {
      obj ['codPackagesNumber'] = {label: 'ACCOUNTING.SORTED_COD_PACKAGES', icon: '../../../assets/icons/package_card/invoice.svg'};
    }

    return obj;
  }
  onPaginate(page) {
    this.setPaginatorQueryParam();
    this.pageNumber = page;
    this.initCustomers();
  }

  sortOnReceivingDate() {
    if (this.sortByType === 'DELIVERY_DATE_DESC') {
      this.sortByType = 'DELIVERY_DATE_ASC';
    } else {
      this.sortByType = 'DELIVERY_DATE_DESC';
    }
    this.onSearch();
  }

  exportCodToExcel() {
    this.isLoading = true;
    this.usersService.exportCodToExcel(this.createParams()).subscribe((res: any) => {
      window.open(res.url, '_blank');
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
  private openSortCodDialog(customerId, customerName = '') {
    const modal = this.modalService.open(SortCodComponent, {
      size: 'xl'
    });
    modal.componentInstance.customerId = customerId;
    modal.componentInstance.isHoldCustody = this.receiveWithoutReleasingCustody;
    modal.componentInstance.type = this.sort ? 'EXPORT' : 'RECEIVE';
    modal.componentInstance.export = this.sort;
    modal.componentInstance.receive = this.receive;
    modal.componentInstance.isExport = this.isExport;
    modal.componentInstance.sort = this.sort;
    modal.componentInstance.driverSearch = customerName;
    modal.componentInstance.loadSpecificCustomer = true;
    // modal.componentInstance.searchContent = this.searchContent;
    const params = this.createParams();
    if (params['fromDate'] && params['toDate']) {
      modal.componentInstance.fromDate = params['fromDate'];
      modal.componentInstance.toDate = params['toDate'];
      modal.componentInstance.selectedDateFilterCustomer = this.selectedDateFilter ? this.selectedDateFilter : 'CREATED_DATE';
    }
    modal.result.then(res => {
      this.initCustomers();
      this.initCustomersSumValues();
    });
  }

  closeSearchField() {
    this.showSearchContainer = !this.showSearchContainer;
    if (!!this.searchContent) {
      this.searchContent = '';
      this.onSearch();
    }
  }

  exportReceivedCodToExcel() {
    this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
    ).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
        accept: () => {
          this.isLoading = true;
          if (this.receiveWithoutReleasingCustody) {
            this.shipmentsService.downloadExcelForNotReceivedCod(this.createParams()).subscribe((response: any) => {
              window.open(response.url, '_blank');
              this.isLoading = false;
            });
          } else {
            this.shipmentsService.downloadExcelForReceivedCod(this.createParams()).subscribe((response: any) => {
              window.open(response.url, '_blank');
              this.isLoading = false;
            });
          }
        },
        reject: () => {
          this.isLoading = false;
        },
        acceptLabel: res['GENERAL.YES'],
        rejectLabel: res['GENERAL.NO'],
      });
    });

  }

  sortByCodSum(field) {
    this.sortField = field;
    if (this.sortType === 'ASC') {
      this.sortType = 'DESC';
    } else if (this.sortType === 'DESC') {
      this.sortType = null;
    } else {
      this.sortType = 'ASC';
    }

    this.onSearch();
  }

  onChangePaymentMethod() {
    this.setQueryParams();
    this.initCustomers();
    this.initCustomersSumValues();
  }

  onChangeHubMethod(event) {
    this.customerHubId = event;
    this.setQueryParams();
    this.initCustomers();
    this.initCustomersSumValues();
  }
}
