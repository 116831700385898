import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild} from '@angular/core';

import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {SHIPMENT_CONSTANTS} from '../../../shipment/shipment-constants';
import {ADMINISTRATION_CONSTANTS} from '../../administration-constants';
import {ZonesService} from '../../services/zones.service';
import {ItemModel} from '../../models/item.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AddZoneComponent} from '../add-zone/add-zone.component';
import {TranslateService} from '@ngx-translate/core';
import { ChooseZoneLocationComponent } from 'src/app/shared/components/choose-zone-location/choose-zone-location.component';
import {UserService} from '../../../shared/services/user.service';
import {EditCityPrefixDialogComponent} from '../edit-city-prefix-dialog/edit-city-prefix-dialog.component';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ChooseHubAndDriverComponent} from '../../../shipment/choose-hub-and-driver/choose-hub-and-driver.component';
import {GenericDialogComponent} from '../../../shared/components/generic-dialog/generic-dialog.component';
import {of, Subject} from 'rxjs';

@Component({
    selector: 'app-zones',
    templateUrl: './zones.component.html',
    styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit, OnChanges {
    @Input() allowEdit = true;
    @Input() allowAssign = true;
    @Input() allActions = false;
    @Input() title: string;
    @Input() dataURL: string;
    @Input() addURL: string;
    @Input() parentID: number;
    @Input() zonesURL: string;
    @Input() addParentKey: string;
    @Input() assignURL: string;
    @Input() selectURL: string;
    @Input() unselectURL: string;
    @Input() locationURL: string;
    @Input() deleteURL: string;
    @Input() selectedItemsList: [];
    @Input() dataKey = '';
    @Input() showArabic = true;
    @Input() allowAdd = true;
    @Input() allowMultipleSelection = false;
    @Input() showFullAddress = false;
    @Input() reset = false;
    @Input() locationEnabled = false;
    @Input() receivingPointEnabled = false;
    @Input() isExportToExcel = false;
    @Output() onItemChecked: EventEmitter<{ id: number, checked: boolean }> = new EventEmitter();
    @Output() onSelectAll: EventEmitter<{}> = new EventEmitter();
    @Output() onItemChosen: EventEmitter<{ id: number }> = new EventEmitter();
    @Output() onResetZones: EventEmitter<{}> = new EventEmitter();
    @ViewChild('selectHub') selectHub;
    isSupportVillageHub = false;
    showPrefix = false;
    showCityColor = false;
    public isExporting = false;
    public selectedItem = 0;
    public highlitedItems = [];
    public selectedItems = [];

    public checkedItems: ItemModel[] = [];

    items: ItemModel[];
    public pageNumber = 0;
    public totalRecords = 0;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;

    public isLoading = false;
    public searchContent = '';
    public selectedLanguage = 'en';

    @Input() shippingLineId;
    @Input() cityId;
    @Input() isManageZones = false;
    @Input() type = '';
    isSuperAdmin: boolean;
    villageHubId;

    constructor(public zonesService: ZonesService, public modalService: NgbModal,
                private confirmService: ConfirmationService, public authenticationService: AuthenticationService,
                private messageService: MessageService, public translateService: TranslateService,
                private userService: UserService,
                public zone: NgZone) {
    }

    ngOnInit() {
        this.isSuperAdmin = this.userService.userInfo.role === 'SUPER_ADMIN';
        this.isSupportVillageHub = this.userService.userInfo.isSupportVillageHub;
        this.items = [];
        this.selectedLanguage = this.translateService.currentLang;
        this.showPrefix = this.userService.userInfo.isShowCityPrefixInAwb;
        this.showCityColor = this.userService.userInfo.isShowCityColor;
    }

    ngOnChanges(changes: SimpleChanges) {
        const dataURL: SimpleChange = changes.dataURL;
        const selectedItemsList: SimpleChange = changes.selectedItemsList;
        if (dataURL) {
            if (dataURL.currentValue === '') {
                this.pageNumber = 0;
                this.totalRecords = 0;
                this.items = [];
            }
            this.zone.run(() => this.fetchData());
        }
        if (selectedItemsList && selectedItemsList.previousValue !== selectedItemsList.currentValue) {
            this.fetchData();
            this.zone.run(() => this.selectedItems = selectedItemsList.currentValue);
        }
    }

    public fetchData() {
        if (this.dataURL) {
            this.isLoading = true;
            const query = this.createParams();
            this.zonesService.getZones(this.dataURL, query).subscribe(
                (response: any) => {
                    this.isLoading = false;
                    if (this.dataKey) {
                        this.items = response[this.dataKey];
                    } else {
                        this.items = response.data;
                        this.totalRecords = response.totalRecordsNo;
                    }
                    this.spinnerState = SpinnerState.LOADED;
                    if (!(this.items && this.items.length > 0)) {
                        this.spinnerState = SpinnerState.EMPTY;
                    } else {
                        if (!this.allowMultipleSelection) {// No Default for multiple selection
                            for (const item of this.items) {// Choose first selected item
                                if (item.isSelected) {
                                    this.selectItem(item.id);
                                    break;
                                }
                            }
                        }
                    }
                    if (this.selectURL && this.unselectURL) {
                        this.setSelectedItems();
                    }
                }, (error) => {
                    this.spinnerState = SpinnerState.FAILED;
                    this.isLoading = false;
                    if (this.selectURL && this.unselectURL) {
                        this.setSelectedItems();
                    }
                }, () => {
                }
            );
        }
    }

    public setSelectedItems() {
        this.selectedItems = [];
        this.checkedItems = [];
        this.items.forEach(
            (item) => {
                if (item.isSelected) {
                    this.selectedItems.push(item.id);
                    this.checkedItems.push(item);
                }
            }
        );
    }

    public onSearch() {
        this.fetchData();
    }

    public onSearchDebounced($event) {
        this.fetchData();
    }

    public createParams() {
        const params = {
            pageSize: ADMINISTRATION_CONSTANTS.ZONES_PAGE_SIZE,
            page: this.pageNumber + 1,
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        return params;
    }

    public selectItem(id) {
        if (this.allowMultipleSelection) {
            const index = this.highlitedItems.findIndex((element) => element === id);
            if (index !== -1) {
                this.highlitedItems.splice(index, 1);
            } else {
                this.highlitedItems.push(id);
            }
        } else {
            this.selectedItem = id;
        }
        this.onItemChosen.emit({id: id});
    }

    itemCheckedStateChanged(checked, id) {

        if (checked && checked.checked) {
            checked = checked.checked.indexOf(id) > -1;
        } else {
            checked = false;
        }
        if (this.onItemChecked) {
            this.onItemChecked.emit({id, checked});
        }
        if (!(this.selectURL && this.unselectURL)) {
            return;
        }
        this.isLoading = true;
        let url = checked ? this.selectURL : this.unselectURL;
        url = url.replace('{id}', id);
        this.zonesService.selectZone(url).subscribe(
            (response: { data: ItemModel[], totalRecordsNo: number }) => {
                this.isLoading = false;
                this.fetchData();
            }, (error) => {
                this.isLoading = false;
            }, () => {
            }
        );
    }

    public addNewZone() {
        const modal = this.modalService.open(AddZoneComponent, <any>{
            backdrop: 'static',
            windowClass: 'add-shelf-container',
            size: 'md'
        });
        modal.componentInstance.parentID = this.parentID;
        modal.componentInstance.addURL = this.addURL;
        modal.componentInstance.zonesURL = this.zonesURL;
        modal.componentInstance.addParentKey = this.addParentKey;
        modal.componentInstance.assignURL = this.assignURL;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    this.messageService.add({severity: 'success', detail: 'Zone Added Successfully'});
                    this.fetchData();
                    this.selectedItems = [];
                }
            }
        ).catch(
            (error) => {
            }
        );

    }

    changeItemLocation(item: ItemModel) {
        const modal = this.modalService.open(ChooseZoneLocationComponent, <any>{
            backdrop: 'static',
            size: 'md'
        });
        modal.componentInstance.selectedItem = item;

        modal.result.then((response) => {
            if (response.isSuccess) {
                this.translateService.get('ALERTS.VILLAGE_LOCATION_SET_SUCCESSFULLY').subscribe(
                    (value) => {
                        this.messageService.add({severity: 'success', detail: value});
                    }
                );
            }
        }, (reason => {

        }));
    }

    chooseLocations() {
        const modal = this.modalService.open(ChooseZoneLocationComponent, <any>{
            backdrop: 'static',
            size: 'md'
        });
        modal.componentInstance.multipleItems = true;
        modal.componentInstance.items = this.checkedItems;

        modal.result.then((response) => {
            if (response.isSuccess) {
                this.translateService.get('ALERTS.VILLAGE_LOCATION_SET_SUCCESSFULLY').subscribe(
                    (value) => {
                        this.messageService.add({severity: 'success', detail: value});
                    }
                );
            }
        }, (reason => {

        }));
    }

    public editItem() {

    }

    changeIsReceivingPoint(item) {
        this.zonesService.changeVillageIsReceivingPoint(item.id).subscribe(
            (response) => {}, (error) => {
                item.isReceivingPoint = !item.isReceivingPoint;
            }
        );
    }

    deleteItem() {
        if (this.deleteURL) {
            this.isLoading = true;
            if (this.deleteURL.includes('regions')) {
                const deleteURL = this.deleteURL.replace('{id}', String(this.selectedItem));
                this.zonesService.deleteRegion(deleteURL).subscribe((res) => {
                    this.isLoading = false;
                    this.fetchData();
                }, () => {
                    this.isLoading = false;
                });
            } else {
                if (this.allowMultipleSelection) {
                    this.zonesService.unassignMultipleZones(this.deleteURL, this.highlitedItems).subscribe((res) => {
                        this.isLoading = false;
                        this.fetchData();
                    }, () => {
                        this.isLoading = false;
                    });
                } else {
                    this.zonesService.unassignZone(this.deleteURL, [this.selectedItem]).subscribe((res) => {
                        this.isLoading = false;
                        this.fetchData();
                    }, () => {
                        this.isLoading = false;
                    });
                }
            }
        }
    }

    public resetZones() {
        this.onResetZones.emit({});
    }

    onScroll() {
    }
    public exportToExcel() {
        this.isExporting = true;
        this.zonesService.exportExcelVillages().subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isExporting = false;
        }, error => {
            this.isExporting = false;
        });
    }

    openEditPrefix($event, item) {
        $event.stopPropagation();
        const modal = this.modalService.open(EditCityPrefixDialogComponent , <any>{
            backdrop: 'static',
            windowClass: 'add-shelf-container',
            size: 'md'
        });
        modal.componentInstance.city = item;
        modal.componentInstance.showPrefix = this.showPrefix;
        modal.componentInstance.showCityColor = this.showCityColor;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                }
            }
        ).catch(
            (error) => {
            }
        );

    }

    selectAllVillages(selection: boolean) {
        if (!this.allActions) {
            return;
        }
        const message = selection ? 'ALERTS.ADD_ALL_VILLAGES_CONFIRMATION' : 'ALERTS.DELETE_ALL_VILLAGES_CONFIRMATION';
        this.confirmService.confirm({
            message: this.translateService.instant(message),
            accept: () => {
                this.isLoading = true;
                this.zonesService.addAllVillagesToCity(this.isManageZones ? null : this.shippingLineId, this.cityId, selection).subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                        });
                        if (!this.isManageZones) {
                            this.onSelectAll.emit({});
                        } else {
                            this.zone.run(() => this.fetchData());
                        }
                        this.isLoading = false;
                    }, error => {
                        console.error(error);
                        this.isLoading = false;
                    }
                );
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO')
        });
    }
    public uploadFile(fileChooser) {
        fileChooser.click();
    }

    public importCitiesAndVillages($event: any, regionId) {
        this.isLoading = true;
        const selectedFile = $event.target.files[0];
        $event.target.value = '';
        if (!selectedFile) {
            this.isLoading = false;
            return;
        }
        if (selectedFile.name.toLowerCase().match(/.(xlsx)$/i)) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            this.zonesService.importCitiesAndVillagesFormExcel(formData, regionId, this.authenticationService.companyId)
                .subscribe((res) => {
                    this.isLoading = false;
                }, (error) => {
                    this.isLoading = false;
                });
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.isLoading = false;
        }
    }

    changeLocationHub(item: ItemModel) {
        this.zonesService.getZoneHub(item.id).subscribe((res: any) => {
            const modal = this.modalService.open(GenericDialogComponent, <any>{size: 'md'});
            this.villageHubId = res?.id;
            modal.componentInstance.body = this.selectHub;
            modal.componentInstance.onDone = () => {

                const ob = new Subject();

                this.zonesService.updateVillageHub(item.id, {hubId: this.villageHubId}).subscribe(res => {
                    this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    ob.next(true);
                });
                return ob;
            };
            modal.result.then(
                (res: { isSuccess, res? }) => {

                }
            ).catch(
                (error) => {
                }
            );
        });
    }

    onHubSelected($event: any) {
        this.villageHubId = $event;
    }
}
