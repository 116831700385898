import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PRINT_OPTIONS, SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UsersService} from '../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {ACCOUNTANT_REPORT_ORDER_BY} from '../../ticketing-system/services/constants';

@Component({
    selector: 'app-print-packages-options',
    templateUrl: './print-package-options.component.html',
    styleUrls: ['./print-package-options.component.scss']
})
export class PrintPackageOptionsComponent implements OnInit {
    public isLoading = false;
    public form: FormGroup;
    public orderByOptions;
    public isShowPrintType = false;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    currentLang;
    printOptions :  { label: string, value: string }[];

    constructor(private activeModal: NgbActiveModal,
                @Inject(FormBuilder) private formBuilder: FormBuilder,
                private translateService: TranslateService,
                public usersService: UsersService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.setPrintOptions();
        this.initInfo();
        this.getOrderByOptions();
    }

    setPrintOptions() {
        this.printOptions = PRINT_OPTIONS.map(type => {
            return {label: this.translateService.instant(`ACCOUNTING.${type}`), value: type}
        });
    }
    public initInfo() {
        this.form = this.formBuilder.group({
            PrintType: ['NET_REPORT', Validators.required],
            selectedOrderByOption: []
                });
    }

    printReports() {
        this.activeModal.close({isSuccess: true, printType: this.form.value.PrintType,
            orderBy: this.form.value.selectedOrderByOption.value});
    }
    public closeModal() {
        this.activeModal.close({isSuccess: false});
    }
    getOrderByOptions() {
        this.translateService.get(ACCOUNTANT_REPORT_ORDER_BY).subscribe((labels) => {
            this.orderByOptions = ACCOUNTANT_REPORT_ORDER_BY.map((value) => ({
                label: labels[value],
                value
            }));
            this.form.controls['selectedOrderByOption'].setValue(this.orderByOptions[0]);
            this.form.controls['selectedOrderByOption'].updateValueAndValidity();
        });
    }
}
