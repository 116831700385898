<div class="bulk-print-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{"SHIPMENT.PRINT_PACKAGES" | translate}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal()"></i>
        </div>
    </div>
    <form [formGroup]="form" *ngIf="form">
        <div class="body">
            <app-input-field-component [form]="form" label="SHIPMENT.ENTER_BARCODE_LIST"
                                       controlName="barcodes" controlType="textarea"
                                       [isRequired]="true" styleClass="ui-table-customers">
            </app-input-field-component >
            <div class="col-12">
                <div class="inputs row radio-container">
                    <div>
                        <input type="radio" formControlName="printType"
                               value="REPORT">
                        <span class="print-radio-label">{{"SHIPMENT.PRINT_REPORT" | translate}}</span>
                    </div>
                    <div style="margin-left: 20px;margin-right: 20px">
                        <input type="radio" formControlName="printType"
                               value="RUNSHEET">
                        <span class="print-radio-label">{{"SHIPMENT.PRINT_RUN_SHEET" | translate}}</span>
                    </div>
                </div>
            </div>
            <!--<div>Radio AWB OR RUNSHEET</div>-->
        </div>
        <div class="footer d-flex">
            <div class="flex-1"></div>
            <button class="btn btn-sm button save-button" type="submit" (click)="printPackages()"
                    [disabled]="form.invalid">
                {{'ACTIONS.PRINT' | translate}}
            </button>
            <button class="btn btn-sm button save-button" type="submit" (click)="closeModal()">
                {{'ACTIONS.CANCEL' | translate}}
            </button>
        </div>
    </form>
</div>
