<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="cod-mismatch-view d-flex flex-column h-100" [ngClass]="{'page-ltr': selectedLanguage === 'en'}">
    <div class="header-filters d-flex flex-wrap">
        <div class="page-header-title">
            <div class="title">
                {{"ACCOUNTING.EXPENSES" | translate}}
            </div>
            <div class="total-number-badge">
                {{totalRecords}}
            </div>
        </div>
        <div class="header-group d-flex flex-wrap">
<!--            <div class="date-picker-filter filter">-->
<!--                <img class="accounting-date-picker-icon" src="../../../../assets/icons/calendar.svg" (click)="datepicker.click()" alt="icon"/>-->
<!--                <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                       #datepicker-->
<!--                       daterangepicker [options]="dateRangPickerOptions"-->
<!--                       (selected)="onDateSelected($event)"/>-->
<!--                <i class="fas fa-caret-down"></i>-->
<!--            </div>-->
            <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

            <div class="filter search main-search-filter">
                <i class="fas fa-search"></i>
                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent"
                       pInputText appInputDebounced (inputDebounced)="onSearch()">
            </div>
        </div>
    </div>

    <div class="expenses-header-actions d-flex align-items-center flex-wrap">
        <div class="main-actions d-flex">
            <div class="action gradient-action"
                 (click)="addExpensesType()">
                {{"EXPENSES.ADD_TYPE_EXPENSES" | translate}}
            </div>

            <div class="action gradient-action"
                 (click)="addExpenses()">
                {{"EXPENSES.ADD_EXPENSES" | translate}}
            </div>
        </div>
        <div class="secondary-actions d-flex">
            <div class="action secondary-action-btn"
                 (click)="printReport('EXCEL')">
                {{"EXPENSES.EXPORT_TO_EXCEL" | translate}}
            </div>

            <div class="action secondary-action-btn"
                 (click)="printReport('PDF')">
                {{"EXPENSES.EXPORT_TO_PDF" | translate}}
            </div>
        </div>
    </div>

    <div class="cod-mismatch-table flex-1 overflow-auto">
        <p-table #expenses_table appPTableRtlFix dataKey="id" #table [value]="expenses"
                 styleClass="customers-table flex-table" paginatorDropdownAppendTo="body"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [paginator]="true" [responsive]="true" [lazy]="true" (onLazyLoad)="loadExpensesLazy($event)"
                 [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr styel="text-align: center;">
                    <th>
                        <div class="header-filter">
                            <div class="label">{{'ACCOUNTING.EXPENSE_TYPE' | translate}}</div>
                            <div class="value">
                                <app-input-field-component [form]="form"
                                                           controlName="expenseTypeId" field="label" [dataKey]="'value'"
                                                           [controlType]="'autoComplete'"
                                                           [searchSuggestions]="expensesType"
                                                           (onSearch)="getExpensesTypes($event)"
                                                           [forceSelection]="false"
                                                           [displayDropDown]="true"
                                                           (onItemChosen)="initExpenses()"
                                                           (onAutoCompleteClear)="initExpenses()">
                                </app-input-field-component >
                            </div>
                        </div>
                    </th>
                    <th>{{"EXPENSES.VALUE" | translate}}</th>
                    <th>{{"EXPENSES.CREATED_DATE" | translate}}</th>
                    <th>{{"EXPENSES.LAST_UPDATED_DATE" | translate}}</th>
                    <th>
                        <div class="header-filter">
                            <div class="label">{{'EXPENSES.USER_NAME' | translate}}</div>
                            <div class="value">
                                <app-users-list-dropdown-component
                                        [isCodMismatchCustomer]="true"
                                        [placeHolder]="' '"
                                        (userChanged)="onReceiverSelectedChange($event)"
                                        [panelWidth]="90"
                                ></app-users-list-dropdown-component>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="header-filter">
                            <div class="label">{{'EXPENSES.BENEFICIARY' | translate}}</div>
                            <div class="value">
                                <app-input-field-component [form]="form"
                                                           controlName="userId" field="label" [dataKey]="'value'"
                                                           [controlType]="'autoComplete'"
                                                           [searchSuggestions]="userType"
                                                           (onSearch)="getUserType($event)"
                                                           [forceSelection]="false"
                                                           [displayDropDown]="true" (onItemChosen)="initExpenses()"
                                                           (onAutoCompleteClear)="initExpenses()">
                                </app-input-field-component >
                            </div>
                        </div>
                    </th>
                    <th>{{"EXPENSES.BENEFICIARY_OTHERS" | translate}}</th>
                    <th>{{"EXPENSES.NOTES" | translate}}</th>
                    <th style="text-align: center; width: 7%"> {{"MANAGE_COD_CUSTOMERS.HISTORY" | translate}}</th>
                    <th style="text-align: center; width: 7%">{{"ACTIONS.EDIT" |translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-expenses let-expanded="expanded">
                <tr class="cursor-pointer">
                    <td>{{expenses.expenseTypeName}}</td>
                    <td>{{expenses.moneyAmount}}</td>
                    <td>{{expenses.createdDate | date:'dd/MM/yyyy'}}</td>
                    <td>{{expenses.updateDate | date:'dd/MM/yyyy'}}</td>
                    <td>{{expenses.accountantName}}</td>
                    <td>{{expenses.holderName}}</td>
                    <td>{{expenses.holderName2}}</td>
                    <td>{{expenses.notes}}</td>
                    <td style="width: 7%">
                        <div class="row-action">
                            <div class="edit-action action cursor-pointer"
                                 (click)="showHistory($event, expenses)">
                                <i class="fas fa-history"></i>
                            </div>
                        </div>
                    </td>
                    <td style="width: 7%">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer" (click)="updateExpenses(expenses)">
                                <i class="fas fa-edit"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="sum-values d-flex justify-content-center align-items-center">
        <div class="sum-value-item d-flex flex-column justify-content-center">
            <div class="value"><span class="currency">{{currency}}</span>{{totalMoneyAmount}}</div>
            <div class="label">{{'EXPENSES.TOTAL_COD_EXPENSES' | translate}}</div>
        </div>
    </div>
</div>
