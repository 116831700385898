import {Injectable} from '@angular/core';
import {StorageService} from './storage/storage.service';
import {STORAGE_KEYS} from './storage/storage.keys';
import {LoginResponseInterface} from '../../login/models/login-response.interface';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {APIS} from './shared_constants/apis';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    public companyId = environment.companyId;
    public _companyName;
    private _companyLogoOb = new BehaviorSubject(null);
    public set companyName(val) {
        this._companyName = val;
    }

    public get companyName() {
        if (this._companyName) {
            return this._companyName + '/';
        }
        return '';
    }

    constructor(private storageService: StorageService,
                private router: Router,
                private title: Title,
                private translateService: TranslateService,
                private http: HttpClient) {
    }

    /**
     *
     */
    public getUserAuthToken() {
        return this.storageService.get(STORAGE_KEYS.TOKEN_KEY);
    }

    public saveLoginData(data: LoginResponseInterface, companyName, isSuperAdmin) {
        this.setUserAuthToken(data.authToken);
        this.setUserId(data.user.id);
        this.setCompanyName(companyName);
        this.setIsSuperAdmin(isSuperAdmin);
        this.setDeviceUUID(data.device.UUID);
        this.setCurrency(data.user.currency);
    }

    /**
     *
     * @param token
     */
    public setUserAuthToken(token) {
        this.storageService.set(STORAGE_KEYS.TOKEN_KEY, token);
    }

    /**
     *
     * @param customerId
     */
    public setUserId(userId) {
        this.storageService.set(STORAGE_KEYS.CUSTOMER_ID, userId);
    }

    public setCompanyName(CompanyName) {
        this.storageService.set(STORAGE_KEYS.COMPANY_NAME, CompanyName);
    }

    public getCompanyName() {
        return this.storageService.get(STORAGE_KEYS.COMPANY_NAME);
    }

    public setIsSuperAdmin(isSuperAdmin) {
        this.storageService.set(STORAGE_KEYS.IS_SUPER_ADMIN, isSuperAdmin);
    }

    public setCurrency(currency) {
        this.storageService.set(STORAGE_KEYS.CURRENCY, currency);
    }

    public setDeviceUUID(UUID) {
        this.storageService.set(STORAGE_KEYS.UUID, UUID);
    }

    public getIsSuperAdmin() {
        return (/true/i).test(this.storageService.get(STORAGE_KEYS.IS_SUPER_ADMIN));
    }

    public getDeviceUUID() {
        return (this.storageService.get(STORAGE_KEYS.UUID));
    }

    public getCurrency() {
        return this.storageService.get(STORAGE_KEYS.CURRENCY);
    }

    /**
     *
     * @return {boolean}
     */
    public isUserActive(): boolean {
        return this.getUserAuthToken() ? true : false;
    }

    /**
     * clear the information related to the customer.
     */
    public removeUserCredintials() {
        this.storageService.remove(STORAGE_KEYS.TOKEN_KEY);
        this.storageService.remove(STORAGE_KEYS.USERNAME);
        this.storageService.remove(STORAGE_KEYS.CUSTOMER_ID);
        this.storageService.remove(STORAGE_KEYS.COMPANY_NAME);
        this.storageService.remove(STORAGE_KEYS.IS_SUPER_ADMIN);
    }

    public logout() {
        const isSuperAdmin = this.getIsSuperAdmin();
        const companyName = this.getCompanyName();
        this.http.delete(APIS.LOGOUT).subscribe();
        this.removeUserCredintials();
        if (isSuperAdmin) {
            this.router.navigate(['login']);
        } else {
            this.router.navigate([`${companyName}login`]);
        }

    }

    public getCompanyId(params) {

        if (params.name) {
            this.companyName = params.name;
        } else {
            params = {id: environment.companyId};
        }

        return this.http.get(APIS.GET_COMPANY_ID, {params}).toPromise().then((company: any) => {
            this.companyId = company.companyId;
            // TODO default language for AgaTurkey
            if (this.companyId === 177) {
                this.translateService.use('en');
            }
            this.setCompanyLogo(company.logo);
            this.handleCompanyCustomization(company);
            // if (company.companyThemes) {
            //     company.companyThemes.forEach(color => {
            //         document.body.style.setProperty(color.colorKey, color.colorValue);
            //     });
            // }
            return company;
        });
    }

    public setCompanyLogo(val) {
        this._companyLogoOb.next(val);
    }

    public getCompanyLogo() {
        return this._companyLogoOb.asObservable();
    }

    private handleCompanyCustomization(company) {
        if (!company) {
            return;
        }

        if ([263].indexOf(company.companyId) > -1) {
            this.title.setTitle(company.name || 'وصلني اكسبرس');
            const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
            favIcon.href = company.favIcon || 'https://elasticbeanstalk-eu-central-1-640066223797.s3-accelerate.amazonaws.com/263/company-fav-icon1676314152435.png';
        }

    }

    getLanguageForCurrency(lang): Observable<any> {
        const r = Math.random().toString(36).substring(7);
        return this.http.get('./assets/i18n/custom-per-currency/' + this.getCurrency().toLowerCase() + '/' + lang + '.json?cacheBuster=' + r);
    }
}
