<div class="h-100 d-flex flex-column">
    <div class="top-actions">
        <div class="group-tabs-container">
            <div class="pending-tab cursor-pointer tab" routerLink="notifications-settings" routerLinkActive="selectedTab">
                {{'COMPANY.NOTIFICATIONS_SETTINGS' | translate}}
            </div>
            <div class="accepted-tab cursor-pointer tab" routerLink="company-sms" routerLinkActive="selectedTab">
                {{'COMPANY.SMS' | translate}}
            </div>
        </div>
    </div>
    <div class="flex-1 overflow-auto main-container">
        <router-outlet> </router-outlet>
    </div>
</div>
