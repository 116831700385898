import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../shared/services/user.service';
import {HubsService} from '../../shared/services/hubs.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../services/users.service';
import {UserModel} from '../../shared/models/user.model';
import {GeographyService} from '../../shared/services/geography.service';
import {ContainersService} from '../../containers/services/containers.service';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Address} from '../../shared/models/address.class';
import {ZonesService} from '../../administration/services/zones.service';
import {PricingService} from '../../shippings-rate/services/pricing.service';
import {TicketingSystemService} from '../../ticketing-system/services/ticketing-system.service';
import {StorageService} from '../../shared/services/storage/storage.service';
import {ServiceTypesService} from '../../administration/services/service-types.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-create-new-user-custom1',
  templateUrl: './create-new-user-custom1.component.html',
  styleUrls: ['./create-new-user-custom1.component.scss']
})
export class CreateNewUserCustom1Component extends Address implements OnInit, OnDestroy {

  public isEdit = false;
  public isLoading = false;
  public isSubmitting = false;
  public form: FormGroup;
  public disableForm;
  public isSuperAdmin = false;
  public currentLang;
  public doneActionStyleClass = 'done';

  loggedInUserRole;

  @Input() user: UserModel;

  companyId: number;

  subscriptions: Subscription[] = [];
  userInfo: any;
  showPhone2 = false;
  isAdmin = false;

  constructor(
      private userService: UserService,
      private serviceTypesService: ServiceTypesService,
      private usersService: UsersService,
      private hubsService: HubsService,
      private containersService: ContainersService,
      @Inject(FormBuilder) private formsBuilder: FormBuilder,
      private activeModal: NgbActiveModal,
      private ticketingSystemService: TicketingSystemService,
      private geographyService: GeographyService,
      private containerService: ContainersService,
      private confirmationService: ConfirmationService,
      private translate: TranslateService,
      private translateService: TranslateService,
      private storageService: StorageService,
      private pricingService: PricingService,
      private zonesService: ZonesService
  ) {
    super(zonesService, translate, true);
    this.allVillages = true;
    this.userInfo = this.userService.userInfo;
  }

  ngOnInit() {
    this.loggedInUserRole = this.userInfo.role;
    this.currentLang = this.translate.currentLang;
    this.isSuperAdmin = this.userInfo.role === 'SUPER_ADMIN';
    this.companyId = this.userInfo.companyId;

    if (this.user) {
      this.isEdit = true;
    }
    this.initForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription instanceof Subscription) {
        subscription.unsubscribe();
      }
    });
  }

  updateFormValue(controlName, value) {
    this.form.controls[controlName].setValue(value);
    this.form.controls[controlName].updateValueAndValidity();
    this.form.controls[controlName].markAsTouched();
  }

  private initForm() {
    let info = {
      name: '',
      middleName: '',
      firstName: '',
      lastName: '',
      phone: '',
      phone2: '',
    };

    if (this.isEdit) {
      this.showPhone2 = !!this.user.phone2;

      info = <any>{
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        phone: this.user.phone,
        phone2: this.user.phone2
      };
    }

    this.form = this.formsBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      phone: ['', [
        Validators.required,
       this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
      phone2: ['', [
       this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
    });
    this.form.patchValue(info);
  }

  public exitAddNewUser() {
    this.activeModal.close();
  }

  private onAddCustomer(data) {
    delete data.role;

    this.usersService.addCustomer(data).subscribe(
        (response: any) => {
          this.activeModal.close({isSuccess: true});
        }, (error) => {
          this.activeModal.close({isSuccess: false, error: error.error.error});
          this.isLoading = false;
        }, () => {
        }
    );
  }

  public onAddUser() {
    this.isLoading = true;

    const data = this.form.getRawValue();
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      phone: data.phone,
      phone2: data.phone2,
    };

    if (this.isEdit) {
      this.editUser(body);
      return;
    } else {
      body['password'] = data.password;
    }

    this.onAddCustomer(body);
    return;

  }


  public editUser(data) {
    const reqBody = Object.assign(this.user, data);
    this.usersService.editUser(this.user, reqBody).subscribe(
        (response) => {

            this.activeModal.close({isSuccess: true});
        }, (error) => {
          this.isLoading = false;
        }
    );
  }

  public getHeaderTitle() {
    return this.isEdit ? 'USERS_MANAGEMENT.ACTIONS.EDIT_CUSTOMER' : 'USERS_MANAGEMENT.ACTIONS.ADD_CUSTOMER';
  }


  isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
    const formControl = this.form.get(formControlName);
    if (formControl) {
      return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
          : formControl.invalid && (formControl.touched || formControl.dirty);
    }
    return false;
  }



  isHidePackagesInDriversApps() {
    return this.userInfo.isHidePackagesInDriversApps;
  }
  toggleExtraPhone() {
    this.showPhone2 = !this.showPhone2;
    if (!this.showPhone2) {
      this.form.get('phone2').patchValue('');
      this.form.get('phone2').setValidators([]);
    } else {
      this.form.get('phone2').setValidators([Validators.compose([Validators.required,
       this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())])]);
    }
    this.form.get('phone2').updateValueAndValidity();
    this.form.get('phone2').markAsTouched();
  }
}

