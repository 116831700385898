import {Injectable, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService implements OnInit {
    public alert$ = new Subject();
    private generalErrorMessage;


    constructor(
        private translateService: TranslateService,
        private messagesService: MessageService
    ) {
        this.translateService.get(
            ['ALERTS.GENERAL_ERROR', 'ALERT.SUCCESS_TITLE', 'ALERT.GENERAL_ERROR', 'ALERT.GENERAL_SUCCESS']
        )
            .subscribe(
                (data) => {
                    this.generalErrorMessage = data['ALERTS.GENERAL_ERROR'];
                }
            );
    }

    ngOnInit() {
    }

    public alertError(error) {
        const errorMessage = error.error ? (error.error.error ? error.error.error : this.generalErrorMessage) : this.generalErrorMessage;
        this.messagesService.add({severity: 'error', detail: errorMessage});
    }

    public addMessage(alert) {
        this.alert$.next(alert);
    }
}
