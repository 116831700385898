import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {Companies} from '../../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-choose-print-type',
    templateUrl: './choose-print-type.component.html',
    styleUrls: ['./choose-print-type.component.scss']
})
export class ChoosePrintTypeComponent implements OnInit {
    public isLoading = false;
    public form: FormGroup;
    public printTypes = [
        {
            label: 'SHIPMENT.PRINT_REPORT',
            value: 'REPORT',
            imageSrc: 'xls.svg',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_RUN_SHEET',
            value: 'RUNSHEET',
            imageSrc: 'print_as_menu.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_RUN_SHEET_WITH_SIGNATURE',
            value: 'RUNSHEET_WITH_SIGNATURE',
            imageSrc: 'print_as_menu.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_STICKER',
            value: 'STICKER',
            imageSrc: 'print_as_sticker.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_STICKER_2',
            value: 'STICKER_2',
            imageSrc: 'print_as_sticker.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_STICKER_3',
            value: 'STICKER_3',
            imageSrc: 'print_as_sticker.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_STICKER_10*15',
            value: 'STICKER_4',
            imageSrc: 'print_as_sticker.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_STICKER_16_8',
            value: 'STICKER_8_16',
            imageSrc: 'print_as_sticker.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_STICKER_A6',
            value: 'A6',
            imageSrc: 'print_as_sticker.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_AS_QR',
            value: 'QR',
            imageSrc: 'qr-code.png',
            isSelected: false
        },
        {
            label: 'SHIPMENT.PRINT_INVOICES',
            value: 'INVOICES',
            imageSrc: 'print.svg',
            isSelected: false
        }
    ];

    @Output() choosePrintType: EventEmitter<{ printType: string }> =
        new EventEmitter<{ printType: string }>();
    @Input() isModal = false;
    public printType: string;
    showSticker5 = false;

    SHOW_STICKER_5_FOR = [15, 1];
    companyId;

    selectedLang;
    companies = Companies;
    constructor(private activeModal: NgbActiveModal,
                private userService: UserService,
                private translateService: TranslateService,
                private formBuilder: FormBuilder,
                private customizationCompanyService: CustomizationCompanyService) {
    }

    ngOnInit() {
        this.isLoading = false;
        this.selectedLang = this.translateService.currentLang;
        this.companyId = this.userService.userInfo.companyId;
        this.showSticker5 = this.SHOW_STICKER_5_FOR.indexOf(this.companyId) >= 0;
        if (this.showSticker5 && this.printTypes.filter(printType => (printType.value === 'STICKER_5')).length === 0) {
            // this.printTypes.push(
            //     {
            //         label: 'SHIPMENT.PRINT_STICKER_10*10',
            //         value: 'STICKER_5',
            //         imageSrc: 'print_as_sticker.png',
            //         isSelected: false
            //     });
        }
        if (this.customizationCompanyService.checkCompanies(this.companies.JETT)){
            this.printTypes = [
                {
                    label: 'SHIPMENT.PRINT_RUN_SHEET',
                    value: 'RUNSHEET',
                    imageSrc: 'print_as_menu.png',
                    isSelected: false
                },
                {
                    label: 'SHIPMENT.PRINT_RUN_SHEET_WITH_SIGNATURE',
                    value: 'RUNSHEET_WITH_SIGNATURE',
                    imageSrc: 'print_as_menu.png',
                    isSelected: false
                },
                {
                    label: 'SHIPMENT.PRINT_STICKER_10*15',
                    value: 'STICKER_4',
                    imageSrc: 'print_as_sticker.png',
                    isSelected: false
                },
                {
                    label: 'SHIPMENT.PRINT_RECEIVE_REPORT',
                    value: 'STICKER',
                    imageSrc: 'print_as_sticker.png',
                    isSelected: false
                }
            ]
        }
        this.initInfo();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            printType: [this.printTypes[0].value, Validators.required],
        });
    }

    public closeModal() {
        this.activeModal.close();
    }


    public choose(printType = null) {
        if (this.isModal) {
            this.activeModal.close({type: this.printType});
        } else {
            if (printType) {
               this.choosePrintType.emit({printType: printType});
            }
        }
    }
    public getImageSrc(imageName) {
        if (imageName) {
            return '../../../assets/icons/printTypes/' + imageName;
        }
        return '../../../assets/icons/printTypes/print.svg';
    }

    public setPrintType(printType) {
        this.printTypes.forEach(
            (type: any) => {
                if (type.value !== printType) {
                    type.isSelected = false;
                } else {
                    this.printType = type.value;
                    type.isSelected = true;
                }
            }
        );
    }

}
