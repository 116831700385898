<div class="mob-container">
  <div class="header">
    {{('COMPANY.ADD_COMPANY_BILLING_PAYMENT') | translate }}
    <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
  </div>
  <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
    <p-progressSpinner>
    </p-progressSpinner>
  </div>

  <form [formGroup]="form" *ngIf="form">
    <div class="input-fields row">
      <div class="col-12">
        <div class="inputs row" style="padding: 15px; margin-bottom: 5px;">
          <div class="flex-1">
            <app-input-field-component [form]="form"
                                       controlName="company" field="name"
                                       [controlType]="'inputgroup'"
                                       [searchSuggestions]="companiesList"
                                       (onSearch)="getCompanies($event)"
                                       [forceSelection]="false"
                                       [displayDropDown]="true"
                                       label="QUICK_ADD.NAME" [isRequired]="true">
            </app-input-field-component >
          </div>
          <div>
            <app-input-field-component  label="QUICK_ADD.COD"
                                       [isRequired]="true" [form]="form" controlName="paymentAmount"
                                       controlType="number">
            </app-input-field-component >
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12">
      <div class="flex-1"></div>
      <div style="padding: 10px 30px">
        <div class="add-button cursor-pointer "
             (click)="finish()"
             [ngClass]="{'disabled-button disabled': !form.valid}">
          <i class="far fa-save"></i>
          {{'ACTIONS.OK' | translate }}
        </div>
        <div class="reset-button cursor-pointer " (click)="closeModal()">
          <i class="fas fa-times"></i>
          {{"ACTIONS.CANCEL" | translate}}
        </div>
      </div>
    </div>
  </form>
</div>
