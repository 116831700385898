<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="d-flex flex-column h-100">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div>

        <div class="header">
            <div class="header-title">
                <span *ngIf="isCOD || receiveWithoutReleasingCustody">
                    {{(receiveWithoutReleasingCustody ? "ACCOUNTING.RECEIVE_CUSTODY" : "MANAGE_COD_CUSTOMERS.TITLE") | translate }}
                </span>
            </div>

            <div class="filters-container d-flex flex-wrap align-items-center">
                <div class="filter" *ngIf="inCar">
                    <app-users-list-dropdown-component
                            [isDriver]="true"
                            [placeHolder]="'MANAGE_COD_CUSTOMERS.DRIVER_NAME'"
                            (userChanged)="onSelectedDriverChange($event)"
                            [selectedUser]="selectedDriver"
                    ></app-users-list-dropdown-component>
                </div>
                <div class="filter" *ngIf="receiveWithoutReleasingCustody">
                    <app-drop-down
                            [options]="custodyTypeList"
                            [(ngModel)]="optionCustody"
                            filter="true"
                            (whenChange)="onCustodyTypeChange($event)">
                        <ng-template pTemplate="item" let-item>
                            {{item.label | translate}}
                        </ng-template>
                        <ng-template let-selectedItem pTemplate="selectedItem">
                            {{selectedItem.label | translate}}
                        </ng-template>
                    </app-drop-down>
                </div>
                <div class="filter" *ngIf="!receive && !custody || receiveWithoutReleasingCustody">
                    <app-users-list-dropdown-component
                            [isDriver]="optionCustody.value==='DRIVER'"
                            [isHub]="optionCustody.value==='HUB'"
                            [isPartner]="optionCustody.value==='PARTNER'"
                            [placeHolder]="'MANAGE_COD_CUSTOMERS.' + optionCustody.value + '_NAME'"
                            (userChanged)="onFilterSelectedChange($event)"
                            [isDisabled]="optionCustody.value === ''"
                            [selectedUser]="selectedEntity"
                    ></app-users-list-dropdown-component>
                </div>
                <div class="filter">
                    <app-drop-down [options]="customerTypes"
                                   [(ngModel)]="customerType"
                                   optionLabel='label'
                                   [showLabel]="false"
                                   [placeholder]="'CUSTOMER_TYPES.CUSTOMER_TYPE' | translate"
                                   (whenChange)="onSearch()">
                        <ng-template pTemplate="item" let-item>
                            {{item.label | translate}}
                        </ng-template>
                        <ng-template let-selectedItem pTemplate="selectedItem">
                            {{selectedItem.label | translate}}
                        </ng-template>
                    </app-drop-down>
                </div>
                <div class="filter"
                     *ngIf="sort || exportedPackages || deliver || (receive && !receiveWithoutReleasingCustody)">
                    <app-drop-down
                            [(ngModel)]="selectedDateFilter"
                            placeholder="{{ 'ACCOUNTING.CHOOSE_DATE_TYPE' | translate }}"
                            [showLabel]="false"
                            [options]="dateFiltersList" filter="true"
                            (whenChange)="onDateFilterChange()">
                    </app-drop-down>
                </div>
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                <ng-container *ngIf="exportedPackages || deliver || sort|| receive">
                    <app-field-input [inputValidation]="fieldValidation.DECIMAL"
                               class="width-170"
                               (input)="onSearch()"
                               [showLabel]="false"
                               [showIcon]="true"
                               (iconClicked)="totalGreaterThan !== null ? onSearch() : null"
                               icon="fas fa-funnel-dollar"
                               [placeholder]="'PRICING.TOTAL_REPORT_GREATER_THAN'"
                               [(ngModel)]="totalGreaterThan"></app-field-input>
                    <app-field-input [inputValidation]="fieldValidation.DECIMAL"
                               class="width-170"
                               (input)="onSearch()"
                               [showIcon]="true"
                               icon="fas fa-funnel-dollar"
                               (iconClicked)="totalLessThan !== null ? onSearch() : null"
                               [showLabel]="false"
                               [placeholder]="'PRICING.TOTAL_REPORT_LESS_THAN'" [(ngModel)]="totalLessThan"></app-field-input>
                </ng-container>
                <app-field-input [placeholder]="'GENERAL.SEARCH'"
                           class="width-170"
                           (input)="onSearch()"
                           [showIcon]="true"
                           icon="fas fa-search"
                           [showLabel]="false"
                           [(ngModel)]="searchContent">
                </app-field-input>
            </div>
        </div>


        <p-overlayPanel #mobileActions class="mobile-actions">
            <div class="mobile-actions-container">
                <div *ngIf=" deliver && inCar && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || permissions === 'ACCOUNTING_MANAGER')"
                     class="mobile-action"
                     [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                     (click)="printDriverMassReports()">
                    <i class="fas fa-print"></i>
                    {{"ACCOUNTING.PRINT_DRIVER_REPORTS" | translate}}
                </div>

                <div *ngIf="receive && mobAvailable && (permissions==='ACCOUNTANT' || permissions==='ADMIN')"
                     class="mobile-action add-button"
                     (click)="mob()">
                    {{"ACCOUNTING.MOB" | translate}}
                </div>

                <div *ngIf="isCOD && !status && !receiveWithoutReleasingCustody" class="action-button add-button"
                     (click)="exportToPdf()">
                    {{"ACCOUNTING.EXPORT" | translate}}
                </div>


                <div *ngIf="(!receiveWithoutReleasingCustody && receive && excludeSort && permissions !== 'OPERATION_MANAGER') || (receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER')"
                     class="mobile-action"
                     (click)="sortPackage(customers, $event)"
                     [ngClass]="{'disabled-button disabled': !isThereSelection || isBlock}">
                    {{"ACCOUNTING.SORT_ALL" | translate}}
                </div>

                <div *ngIf="canExport && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || this.permissions === 'ACCOUNTING_MANAGER')"
                     class="mobile-action"
                     (click)="exportPackage(customers,$event)"
                     [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                    {{"ACCOUNTING.EXPORT_ALL" | translate}}
                </div>

                <div *ngIf="deliver && inCar && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || permissions === 'ACCOUNTING_MANAGER')"
                     class="mobile-action"
                     (click)="changeStatusToExported(customers,$event)"
                     [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                    {{"ACCOUNTING.CHANGE_STATUS_TO_EXPORTED" | translate}}
                </div>

                <div *ngIf="receive && isSupportReceiveWithoutReleasingCustody && !receiveWithoutReleasingCustody"
                     [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE' || isBlock}"
                     class="mobile-action"
                     (click)="onCustodyTypeChange(null)">
                    {{"ACCOUNTING.RECEIVE_WITHOUT_RELEASING_CUSTODY" | translate}}
                </div>

                <div *ngIf="receive && !receiveWithoutReleasingCustody"
                     [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE' || isBlock}"
                     class="mobile-action"
                     (click)="receivePackages()">
                    {{"ACCOUNTING.RECEIVE" | translate}}
                </div>

                <div *ngIf="sort && !canExport && !isInvoices" class="mobile-action"
                     (click)="pickWithDriver()"
                     [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                    {{"ACCOUNTING.PICK_WITH_DRIVER" | translate}}
                </div>

                <div *ngIf="sort && !canExport" class="mobile-action"
                     (click)="deliverMultiplePackages()"
                     [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                    {{(isInvoices ? "MANAGE_COD_CUSTOMERS.INVOICES.PAY_THE_BILLS" : "ACCOUNTING.DELIVER") | translate}}
                </div>

                <div *ngIf="allowPrintPackages && !isHubUserRole" class="mobile-action"
                     (click)="printSelectedPkgs('multiple')"
                     [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                    {{"SHIPMENT.PRINT_MULTIPLE" | translate}}
                </div>

                <div *ngIf="exportedPackages && !isHubUserRole" class="mobile-action"
                     (click)="downloadMultiplePackages(true)"
                     [ngClass]="{'disabled-btn disabled': selectedDateFilter !=='NONE'}">
                    {{"SHIPMENT.PRINT_ALL" | translate}}
                </div>

                <div *ngIf="custody" class="mobile-action"
                     (click)="receivePackages()">
                    {{"ACCOUNTING.HUB_CUSTODY" | translate | hubTitle}}
                </div>

                <div *ngIf="sort && canExport && !mobAvailable"
                     class="mobile-action"
                     [ngClass]="{'disabled-btn disabled': selectedDateFilter !== 'NONE'}"
                     (click)="sortPackages()">
                    {{"ACCOUNTING.SORT" | translate}}
                </div>

                <div *ngIf="exportedPackages || deliver || inCar" class="mobile-action"
                     (click)="printList()"
                     [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                    {{"ACCOUNTING.PRINT_LIST" | translate}}
                </div>

                <div *ngIf="!isInvoices && (exportedPackages) && showCreateInvoice()"
                     class="mobile-action"
                     (click)="createInvoiceConfirmation(null, $event)"
                     [ngClass]="{'disabled-btn disabled': disableCreateMultipleInvoices() }">
                    {{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES" | translate}}
                </div>

                <div *ngIf="((canExport && sort) || exportedPackages) && (['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN', 'ACCOUNTING_MANAGER'].indexOf(permissions) > -1)"
                     class="mobile-action"
                     [ngClass]="{'disabled-button disabled': !isThereSelection && exportedPackages}"
                     (click)="openExcel()">
                    {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                </div>
            </div>
        </p-overlayPanel>

        <div class="mobile-header-actions hidden-md-up">
            <div *ngIf="receive  && (permissions==='ACCOUNTANT' || permissions==='ADMIN')"
                 class="package-history" tooltipPosition="top"
                 pTooltip="{{'SHIPMENT.FIND_PACKAGE_BY_BARCODE_TOOLTIP'| translate}}"
                 (click)="findPackageByBarcode()">
                <div class="">
                    <img style="width: 30px ;height: 30px" src="../../../assets/icons/find-package.png">
                </div>
            </div>
<!--            <div class="actions-list-btn flex-2d-center" (click)="triggerMobileActions($event, mobileActions)">-->
<!--                <img src="../../../assets/icons/horizantal-dots.svg" alt=""/>-->
<!--            </div>-->
        </div>

        <div class="header-actions hidden-sm-down" *ngIf="['HUB_OPERATION_MANAGER'].indexOf(permissions) === -1">
            <div *ngIf=" deliver && inCar && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || permissions === 'ACCOUNTING_MANAGER')"
                 class="action-button add-button"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                 (click)="printDriverMassReports()">
                <i class="fas fa-print"></i>
                {{"ACCOUNTING.PRINT_DRIVER_REPORTS" | translate}}
            </div>

            <div *ngIf="receive  && (permissions==='ACCOUNTANT' || permissions==='ADMIN')"
                 class="package-history" tooltipPosition="top"
                 pTooltip="{{'SHIPMENT.FIND_PACKAGE_BY_BARCODE_TOOLTIP'| translate}}"
                 [ngClass]="{'padding-left-40': currentLanguage === 'en'}"
                 (click)="findPackageByBarcode()">
                <div class="">
                    <img style="width: 30px ;height: 30px" src="../../../assets/icons/find-package.png">
                </div>
            </div>

            <div *ngIf="receive && mobAvailable && (permissions==='ACCOUNTANT' || permissions==='ADMIN')"
                 class="action-button add-button"
                 (click)="mob()">
                {{"ACCOUNTING.MOB" | translate}}
            </div>

            <div *ngIf="isCOD && !status && !receiveWithoutReleasingCustody" class="action-button"
                 (click)="exportToPdf()">
                <div class="pdf-button">
                    <i class="fas fa-file-pdf"></i>
                    {{"ACTIONS.EXPORT_TO_PDF" | translate}}
                </div>
            </div>

            <div *ngIf="isCOD && !status && !receiveWithoutReleasingCustody" class="action-button"
                 (click)="exportCodToExcel()">
                <div class="pdf-button">
                    <i class="fas fa-file-excel"></i>
                    {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                </div>
            </div>


            <div *ngIf="['OPERATION_MANAGER', 'HUB_OPERATION_MANAGER'].indexOf(permissions) === -1 && ((!receiveWithoutReleasingCustody && receive && excludeSort) || (receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER'))"
                 class="action-button add-button"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || isBlock}"
                 (click)="sortPackage(customers, $event)">
                <i class="fas fa-pencil-alt"></i>
                {{"ACCOUNTING.SORT_ALL" | translate}}
            </div>

            <div *ngIf="canExport && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || this.permissions === 'ACCOUNTING_MANAGER')"
                 class="action-button add-button"
                 (click)="exportPackage(customers,$event)"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                <i class="fas fa-pencil-alt"></i>
                {{"ACCOUNTING.EXPORT_ALL" | translate}}
            </div>

            <div *ngIf="deliver && inCar && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || permissions === 'ACCOUNTING_MANAGER')"
                 class="action-button add-button"
                 (click)="changeStatusToExported(customers)"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                <i class="fas fa-pencil-alt"></i>
                {{"ACCOUNTING.CHANGE_STATUS_TO_EXPORTED" | translate}}
            </div>

            <div *ngIf="receive && isSupportReceiveWithoutReleasingCustody && !receiveWithoutReleasingCustody"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE' || isBlock}"
                 class="action-button add-button"
                 (click)="onCustodyTypeChange(null)">
                {{"ACCOUNTING.RECEIVE_WITHOUT_RELEASING_CUSTODY" | translate}}
            </div>

            <div *ngIf="receive && !receiveWithoutReleasingCustody"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE' || isBlock}"
                 class="action-button add-button"
                 (click)="receivePackages()">
                <i class="fas fa-check"></i>
                {{"ACCOUNTING.RECEIVE" | translate}}
            </div>

            <div *ngIf="receive && !receiveWithoutReleasingCustody && (['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN', 'ACCOUNTING_MANAGER'].indexOf(permissions) > -1)"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE' || isBlock}"
                 class="excel-button"
                 (click)="exportReceivedCodToExcel()">
                <i class="fas fa-file-excel"></i>
                {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}
            </div>

            <div *ngIf="sort && !canExport && !isInvoices && !isCodPendingCustomersApproval" class="action-button add-button"
                 (click)="pickWithDriver()"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                <i class="fas fa-truck"></i>
                {{"ACCOUNTING.PICK_WITH_DRIVER" | translate}}
            </div>

            <div *ngIf="sort && !canExport && !isCodPendingCustomersApproval" class="action-button add-button"
                 (click)="deliverMultiplePackages()"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                <i class="fas fa-check"></i>
                {{(isInvoices ? "MANAGE_COD_CUSTOMERS.INVOICES.PAY_THE_BILLS" : "ACCOUNTING.DELIVER") | translate}}
            </div>

            <div *ngIf="allowPrintPackages && !isHubUserRole" class="action-button secondary-action-btn"
                 (click)="printSelectedPkgs('multiple')"
                 [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                <i class="fas fa-print"></i>
                {{"SHIPMENT.PRINT_MULTIPLE" | translate}}
            </div>

            <div *ngIf="exportedPackages && !isHubUserRole" class="action-button secondary-action-btn"
                 (click)="downloadMultiplePackages(true)"
                 [ngClass]="{'disabled-btn disabled': selectedDateFilter !=='NONE'}">
                <i class="fas fa-print"></i>
                {{"SHIPMENT.PRINT_ALL" | translate}}
            </div>

            <div *ngIf="custody" class="action-button secondary-action-btn"
                 (click)="receivePackages()">
                <i class="fas fa-check"></i>
                {{"ACCOUNTING.HUB_CUSTODY" | translate | hubTitle}}
            </div>

            <div *ngIf="sort && canExport && !mobAvailable"
                 class="action-button add-button"
                 [ngClass]="{'disabled-btn disabled': selectedDateFilter !== 'NONE'}"
                 (click)="sortPackages()">
                <i class="fas fa-check"></i>
                {{"ACCOUNTING.SORT" | translate}}
            </div>

            <div *ngIf="exportedPackages || deliver || inCar" class="action-button secondary-action-btn"
                 (click)="printList()"
                 [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                <i class="fas fa-print"></i>
                {{"ACCOUNTING.PRINT_LIST" | translate}}
            </div>

            <div *ngIf="!isInvoices && (exportedPackages) && showCreateInvoice() && !isCodPendingCustomersApproval"
                 class="action-button secondary-action-btn"
                 (click)="createInvoiceConfirmation(null, $event)"
                 [ngClass]="{'disabled-btn disabled': disableCreateMultipleInvoices() }">
                <i class="fas fa-print"></i>
                {{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES" | translate}}
            </div>

            <div *ngIf="!isInvoices && (exportedPackages) && showCreateInvoice() && !isCodPendingCustomersApproval"
                 class="excel-button"
                 (click)="exportCustomersCodToExcel()">
                <i class="fas fa-file-excel"></i>
                {{"ACCOUNTING.EXPORT_PER_CUSTOMERS" | translate}}
            </div>

            <div *ngIf="((canExport && sort) || exportedPackages || (deliver && !inCar)) && (['ACCOUNTANT', 'ADMIN', 'SUPER_ADMIN', 'ACCOUNTING_MANAGER'].indexOf(permissions) > -1)"
                 class="excel-button"
                 [ngClass]="{'disabled-button disabled': !isThereSelection}"
                 (click)="openExcel()">
                <i class="fas fa-file-excel"></i>
                {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
            </div>
        </div>

    </div>

    <div class="cod-customers-table flex-1 overflow-auto hidden-sm-down">
        <p-table #customersTable appPTableRtlFix dataKey="id" #table [value]="customers"
                 paginatorDropdownAppendTo="body"
                 styleClass="customers-table flex-table" (onPage)="setPaginatorQueryParam()"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadCustomersLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="(receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER') || allowPrintPackages || (!receiveWithoutReleasingCustody && receive && excludeSort && permissions !== 'OPERATION_MANAGER') || (( (deliver && inCar) || canExport) && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || this.permissions === 'ACCOUNTING_MANAGER'))"
                        style="width: 4%">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                    (onChange)="onToggleSelectAll($event)"
                                    [disabled]="selectedDateFilter!=='NONE'"></p-checkbox>
                    </th>
                    <th *ngIf="sort || deliver" style="width: 150px;"> {{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th> {{(receiveWithoutReleasingCustody ? "MANAGE_COD_CUSTOMERS." + optionCustody.value + "_NAME" : "MANAGE_COD_CUSTOMERS.CUSTOMER_NAME") | translate }}</th>
                    <th *ngIf="isCOD && !receiveWithoutReleasingCustody"> {{"MANAGE_COD_CUSTOMERS.BUSINESS_NAME" | translate}}</th>
                    <th *ngIf="!isCOD || (receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER')"> {{"ACCOUNTING.CUSTOMER_ADDRESS" | translate }}</th>
                    <th *ngIf="!receiveWithoutReleasingCustody && isCOD && (status === 'COD_AT_HAND' || !status)"> {{"MANAGE_COD_CUSTOMERS.COD_AT_HAND" | translate}}</th>
                    <th *ngIf="!receiveWithoutReleasingCustody && isCOD && (status === 'COD_WITH_CUSTOMER' || !status)"> {{"MANAGE_COD_CUSTOMERS.COD_WITH_CUSTOMER" | translate}}</th>
                    <th *ngIf="!receiveWithoutReleasingCustody && isCOD && (status === 'FUTURE_COD' || !status)"> {{"MANAGE_COD_CUSTOMERS.COD_FUTURE" | translate}}</th>
                    <th *ngIf="receive || custody || sort || receiveWithoutReleasingCustody || deliver"> {{"MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST" | translate}}</th>
                    <th *ngIf="deliver"> {{"ACCOUNTING.DELIVERED_COD" | translate}}</th>
                    <th *ngIf="receive || custody || sort || receiveWithoutReleasingCustody"> {{"MANAGE_COD_CUSTOMERS.COST_SUM" | translate}}</th>
                    <th *ngIf="receive || custody || sort || receiveWithoutReleasingCustody"> {{"MANAGE_COD_CUSTOMERS.COD_SUM" | translate}}</th>
                    <th *ngIf="!receiveWithoutReleasingCustody && isCOD && !status"> {{"MANAGE_COD_CUSTOMERS.READY_COD_TO_DELIVER" | translate}}</th>
                    <th *ngIf="receiveWithoutReleasingCustody || receive || custody"> {{(receiveWithoutReleasingCustody ? 'ACCOUNTING.RECEIVED_COD_PACKAGES_CUSTODY' : 'ACCOUNTING.RECEIVED_COD_PACKAGES') | translate}}</th>
                    <th *ngIf="sort"> {{"ACCOUNTING.SORTED_COD_PACKAGES" | translate}}</th>
                    <th *ngIf="deliver"> {{"ACCOUNTING.DELIVERED_COD_PACKAGES" | translate}}</th>
                    <th *ngIf="showCodFees"> {{"ACCOUNTING.COD_FEES" | translate}}</th>
                    <th *ngIf="isShowCommissionFees" style="width: 150px;"> {{"PRICING.COMMISSION_FEE" | translate}}</th>
                    <th *ngIf="((exportedPackages || deliver) && !isExportedCod) || showCustomerPaymentMethod" class="payment-method-cell">
                        <ng-container *ngIf="showCustomerPaymentMethod">
                            {{'ACCOUNTING.CUSTOMER_PAYMENT_TYPE' | translate}}
                        </ng-container>
                        <div class="table-row-actions-container" *ngIf="!showCustomerPaymentMethod">
                            <div style="width: 150px;">
                                <p-dropdown styleClass="paymentMethodFilter"
                                        [(ngModel)]="paymentMethod" optionLabel="label"
                                        [options]="paymentsMethod" filter="true"
                                        (onChange)="onPaymentSelected()"
                                            placeholder="{{((isExportedCod || sort) ? 'ACCOUNTING.CUSTOMER_PAYMENT_TYPE' : 'ACCOUNTING.PAYMENT_TYPE' )|translate}}">
                                </p-dropdown>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="deliver"> {{(inCar ? 'ACCOUNTING.DELIVERED_BY_DRIVER_NAME' : "ACCOUNTING.DELIVERED_BY") | translate}}</th>
                    <th *ngIf="deliver">
                        <div class="d-inline-flex" style="gap: 5px;">
                            <div class="default-filter">
                                {{"ADD_SHIPMENT.DELIVERY_DATE" | translate}}
                            </div>
                            <div *ngIf="!inCar">
                                <i class="fas fa-sort-amount-down" style="cursor: pointer;" (click)="sortOnReceivingDate()"></i>
                            </div>
                        </div>
                    </th>
                    <!--                    <th *ngIf="((this.authenticationService.companyId === 37 || this.authenticationService.companyId === 1) && sort || receive || deliver || exportedPackages)"> {{"ACCOUNTING.COST_PERCENTAGE" | translate}}</th>-->
                    <th *ngIf="canExport && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || permissions === 'ACCOUNTING_MANAGER' || permissions === 'HUB_ADMIN')"> {{"ACCOUNTING.EXPORT" | translate}}</th>
                    <th *ngIf="sort && !canExport && !isCodPendingCustomersApproval" class="icon-cell"> {{(isInvoices ? "MANAGE_COD_CUSTOMERS.INVOICES.PAY_THE_BILL" : "ACCOUNTING.DELIVER") | translate}}</th>
                    <th *ngIf="deliver && !inCar">
                        <div class="d-inline-flex">
                            <div class="default-filter">
                                {{ 'MANAGE_SHIPMENTS.SIGNATURE' | translate }}
                            </div>
                            <div style="width: 10px;"></div>
                            <div class="toggle-btn">
                                <p-inputSwitch [(ngModel)]="isOnlyShowSignature"
                                               (onChange)="initCustomers()"></p-inputSwitch>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="(sort&& !canExport) || deliver" class="icon-cell"> {{"MANAGE_CONTAINERS.PRINT" | translate}}</th>
                    <th *ngIf="(sort&& !canExport) || deliver" class="icon-cell"> {{"MANAGE_CONTAINERS.PRINT_ENVELOPE" | translate}}</th>
                    <th *ngIf="(receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER') || !receiveWithoutReleasingCustody && receive && excludeSort && (permissions !== 'OPERATION_MANAGER' || this.permissions === 'ACCOUNTING_MANAGER')"
                        class="icon-cell"> {{"ACCOUNTING.SORT" | translate}}</th>
                    <th *ngIf="exportedPackages || deliver || receive" class="icon-cell"> {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}</th>
                    <th *ngIf="!isInvoices && (exportedPackages) && showCreateInvoice() && !isCodPendingCustomersApproval" class="icon-cell"> {{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICE" | translate}}</th>
                    <th *ngIf="showEditHistory" class="icon-cell"> {{"MANAGE_COD_CUSTOMERS.HISTORY" | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-expanded="expanded">
                <tr class="cursor-pointer" (click)="goToCustomerCOD(customer)">
                    <td *ngIf="(receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER') || allowPrintPackages || (!receiveWithoutReleasingCustody && receive && excludeSort && permissions !== 'OPERATION_MANAGER') || (( (deliver && inCar) || canExport) && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || this.permissions === 'ACCOUNTING_MANAGER'))"
                        style="width: 4%">
                        <p-checkbox [(ngModel)]="customer.isSelected" binary="true"
                                    (onChange)="onToggleSelection($event)" (click)="stopProp($event)"
                                    [disabled]="selectedDateFilter!=='NONE'"
                        ></p-checkbox>
                    </td>
                    <td *ngIf="sort || deliver" style="width: 150px; text-align: center">
                       <div class="barcode-image-container flex-2d-center">
                         <div>
                             <img height="50px" class="barcode-image" [src]="customer.barcodeImage">
                             <span class="barcode-label hover-display-parent">
                            <span>{{customer.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(customer.barcode);$event.stopPropagation()"></i>
                        </span>
                         </div>
                       </div>
                    </td>
                    <td *ngIf="isCOD && !receiveWithoutReleasingCustody"> {{customer.name}} {{customer.middleName}} {{customer.lastName}}</td>
                    <td *ngIf="!isCOD || receiveWithoutReleasingCustody"> {{(receiveWithoutReleasingCustody ? customer.name : customer.customerName)}}</td>
                    <td *ngIf="isCOD && !receiveWithoutReleasingCustody"> {{ customer.businessName }}</td>
                    <td *ngIf="!isCOD || (receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER')"> {{customer.city ? customer.city : customer.customerCity }}</td>
                    <td *ngIf="!receiveWithoutReleasingCustody && isCOD && (status === 'COD_AT_HAND' || !status)"> {{customer.codAtHand | number : digitFormat}}</td>
                    <td *ngIf="!receiveWithoutReleasingCustody && isCOD &&(status === 'COD_WITH_CUSTOMER' || !status)"> {{customer.codWithCustomer | number : digitFormat}}</td>
                    <td *ngIf="!receiveWithoutReleasingCustody && isCOD && (status === 'FUTURE_COD' || !status)"> {{customer.futureCod | number : digitFormat}}</td>
                    <!--                    <td *ngIf="receive ||custody"> {{customer.totalReceivedCod }}</td>-->
                    <!--                    <td *ngIf="receive ||custody"> {{customer.totalCost }}</td>-->
                    <td *ngIf="receive || custody || sort || receiveWithoutReleasingCustody || deliver"> {{customer.totalCodWithoutCost | number : digitFormat}}</td>
                    <td *ngIf="receive || custody || receiveWithoutReleasingCustody"> {{customer.costSum | number : digitFormat}}</td>
                    <td *ngIf="sort"> {{customer.totalCost | number : digitFormat}}</td>
                    <td *ngIf="!receiveWithoutReleasingCustody && isCOD && !status"> {{customer.codReadyToDeliver | number : digitFormat}}
                    <td *ngIf="receive ||custody || receiveWithoutReleasingCustody"> {{customer.totalReceivedCod | number : digitFormat}}</td>
                    <td *ngIf="sort || deliver"> {{customer.totalCod | number : digitFormat}}</td>
                    <td *ngIf="receive || custody || receiveWithoutReleasingCustody"> {{customer.receivedCodPackagesNumber | number : digitFormat}}</td>
                    <td *ngIf="sort || deliver"> {{customer.codPackagesNumber | number : digitFormat}}</td>
                    <td *ngIf="showCodFees">{{customer.codFees}}</td>
                    <td *ngIf="isShowCommissionFees">{{customer.commissionFeeSum}}</td>
                    <td *ngIf="((exportedPackages || deliver) && !isExportedCod) || showCustomerPaymentMethod" class="payment-method-cell" style="text-align: center">
                        <div
                                class="table-row-actions-container">
                            {{customer.paymentType|translate}}<br>
                            {{(customer.paymentType !== 'CASH' && customer.documentNumber) ?
                            ('BANK_TRANSFERS.DOCUMENT'|translate) +
                            ' : ' + customer.documentNumber : ''}}
                            {{((customer.paymentType === 'BANK_TRANSFER' || customer.paymentType === 'DIGITAL_WALLET') && customer.iban) ?
                            ', ' + customer.iban : ''}}
                            {{((customer.paymentType === 'BANK_TRANSFER' || customer.paymentType === 'DIGITAL_WALLET') && customer.transferFees) ?
                            ', ' + ('BANK_TRANSFERS.TRANSFER_FEES' | translate) + ': ' + customer.transferFees : ''}}
                        </div>
                    </td>
                    <td *ngIf="deliver"> {{customer.userName }}</td>
                    <td *ngIf="deliver"> {{customer.deliveryDate | date:'dd/MM/yyyy' }}
                    </td>
                    <!--                    <td *ngIf="((this.authenticationService.companyId === 37 || this.authenticationService.companyId === 1) && sort || receive || deliver || exportedPackages)"> {{customer.percentageFee}}</td>-->
                    <td *ngIf="canExport && (permissions==='ACCOUNTANT' || permissions==='ADMIN' || this.permissions === 'SUPER_ADMIN' || permissions === 'ACCOUNTING_MANAGER' || permissions === 'ACCOUNTING_MANAGER' || permissions === 'HUB_ADMIN')">
                        <div
                                class="table-row-actions-container"
                                [tooltipDisabled]="selectedDateFilter ==='NONE'"
                                tooltipPosition="right"
                                pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                                 class="edit-action action cursor-pointer" (click)="exportPackage(customer,$event)">
                                <i class="fas fa-file-export"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="sort && !canExport && !isCodPendingCustomersApproval" class="icon-cell">
                        <div class="table-row-actions-container"
                             [tooltipDisabled]="selectedDateFilter ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': selectedDateFilter !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="deliverPackage(customer,$event)">
                                <i class="fas fa-file-export"></i>
                            </div>
                        </div>
                    <td *ngIf="deliver && !inCar">
                        <div *ngIf="isSignatureExist(customer.deliveryProofUrlList)">
                            <img (click)="openSignatureImages($event, customer.deliveryProofUrlList)"
                                 style="width: 18px; height: 18px; cursor: pointer;"
                                 src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                        </div>
                    </td>
                    <td *ngIf="(sort&& !canExport) || deliver" class="icon-cell">
                        <div class="table-row-actions-container"
                             [tooltipDisabled]="selectedDateFilter ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': selectedDateFilter !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="$event.stopPropagation();printSelectedPkgs('single', printPackage.bind(this,customer, $event), customer)">
                                <i class="fas fa-print"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="(sort&& !canExport) || deliver" class="icon-cell">
                        <div class="table-row-actions-container"
                             [tooltipDisabled]="selectedDateFilter ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': selectedDateFilter !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="$event.stopPropagation();printAsEnvelopPkg(customer)">
                                <i class="fas fa-print"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="(receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER') || !receiveWithoutReleasingCustody && receive && excludeSort && permissions !== 'OPERATION_MANAGER'"
                        class="icon-cell">
                        <div class="table-row-actions-container"
                             [tooltipDisabled]="selectedDateFilter ==='NONE' || receiveWithoutReleasingCustody"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div class="edit-action action cursor-pointer"
                                 [ngClass]="{'disabled-button disabled': (selectedDateFilter !== 'NONE' && !receiveWithoutReleasingCustody)}"
                                 (click)="sortPackage(customer,$event)">
                                <i class="fas fa-bookmark"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="exportedPackages || deliver || receive" class="icon-cell">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer"
                                 (click)="exportToExcel(customer,$event, receive)">
                                <i class="fas fa-file-excel"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="!isInvoices && (exportedPackages) && showCreateInvoice() && !isCodPendingCustomersApproval" class="icon-cell">
                        <div class="table-row-actions-container"
                             [ngClass]="{'disabled-btn disabled': !customer.isCustomerBilled}">
                            <div class="edit-action action cursor-pointer"
                                 (click)="createInvoiceConfirmation(customer,$event)">
                                <i class="fas fa-file-invoice"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="showEditHistory">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer"
                                 (click)="showHistory($event, customer)">
                                <i class="fas fa-history"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="mobile-cards-list hidden-md-up">
        <app-mobile-cards-list [list]="customers" [keysObj]="getMobileKeys()"></app-mobile-cards-list>
       <div class="paginator-container">
           <p-paginator
               #paginator
               [rows]="12"
               [totalRecords]="totalRecords"
               (onPageChange)="onPaginate($event.page)"></p-paginator>
       </div>
    </div>
    <div class="bottom-values" *ngIf="showCodTotals">
        <div *ngIf="deliver || exportedPackages" class="cod-title">
            <b>{{ totalRecords | number : digitFormat }}</b><br>
            {{"ACCOUNTING.MASS_REPORTS_NUMBER" | translate}}
        </div>
        <div *ngIf="(sort || receive) && !exportedPackages" class="cod-title">
            <b>{{totalRecords | number : digitFormat}}</b>
            <br>
            {{(receiveWithoutReleasingCustody ? "ACCOUNTING.TOTAL_" + optionCustody.value + "S" : "ACCOUNTING.TOTAL_CUSTOMERS") | translate }}
            :
        </div>
        <div *ngIf="!isCOD && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalPackages | number : digitFormat}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_PACKAGES" | translate}}
        </div>
        <div *ngIf="isCOD" class="cod-title">
            <b>{{totalRecords | number : digitFormat}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_CUSTOMERS" | translate }}
        </div>
        <div *ngIf="deliver " class="cod-title">
            <b>{{totalCod | number : digitFormat | currencyValue}}</b>
            <br>
            {{"ACCOUNTING.COD_SUM" | translate}}
        </div>
        <div *ngIf="(receive || sort || deliver) && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalCostSum | number : digitFormat | currencyValue}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_COST_SUM" | translate}}
        </div>
        <div *ngIf="(receive || sort || deliver) && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalCodWithoutCost | number : digitFormat | currencyValue}}</b>
            <br>
            {{"MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST" | translate}}
        </div>
        <div *ngIf="(sort || receive || custody) && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalCod | number : digitFormat | currencyValue}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_COD" | translate}}
        </div>
        <div *ngIf="receive && mobAvailable && (permissions==='ACCOUNTANT' || permissions==='ADMIN') && !receiveWithoutReleasingCustody"
             class="cod-title">
            <b>{{receivedMoney | number : digitFormat | currencyValue}}</b>
            <br>
            {{"BANK_TRANSFERS.RECEIVED_MONEY_AMOUNT" | translate}}
        </div>
        <div *ngIf="receive && mobAvailable && (permissions==='ACCOUNTANT' || permissions==='ADMIN') && !receiveWithoutReleasingCustody"
             class="cod-title">
            <b>{{mobReceivedMoney | number : digitFormat | currencyValue}}</b>
            <br>
            {{"BANK_TRANSFERS.TOTAL_MOB_MONEY" | translate}}
        </div>
        <div *ngIf="isSupportReceiveWithoutReleasingCustody && (sort && !deliver && !isInvoices)"
             class="cod-title">
            <b>{{totalCostWithoutReceiveCustody | number : digitFormat | currencyValue}}</b>
            <br>
            {{"BANK_TRANSFERS.TOTAL_RECEIVE_WITHOUT_CUSTODY" | translate}}
        </div>
        <div *ngIf="((this.authenticationService.companyId === 37 || this.authenticationService.companyId === 1) && sort || receive || deliver || exportedPackages)"
             class="cod-title">
            <b>{{totalPercentageFee | number : digitFormat}}%</b>
            <br>
            {{"ACCOUNTING.COST_PERCENTAGE" | translate}}
        </div>
    </div>
</div>
