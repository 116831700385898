<div class="receive-packages-container">
    <div *ngIf="mismatchId" class="header">
        {{('ACCOUNTING.RECEIVE_PACKAGES') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

    </div>
    <div *ngIf="!mismatchId" class="header">
        {{('ACCOUNTING.COD_MISMATCH') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div style="display: contents;text-align-last: center;">
                <div *ngIf="!isDriverEarning" class="col-12">{{('ACCOUNTING.EXPECTED_COD') | translate }}: {{expectedCod}}</div>
                <div *ngIf="isDriverEarning" class="col-4">{{('ACCOUNTING.EXPECTED_COD') | translate }}: {{expectedCod}}</div>
                <div *ngIf="isDriverEarning" class="col-8">
                    <div class="driver-cost d-flex">
                        <div class="label">{{('DRIVERS_EARNINGS.TOTAL_DRIVER_COST') | translate }}</div>
                        <div class="value">
                            <app-input-field-component  [form]="form"
                                                       controlName="driverEarningSum"
                                                       [isRequired]="false" controlType="number">
                            </app-input-field-component >
<!--                            {{totalDriversEarning}}-->
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-12 packages-container" style="margin-top: 5px;">
                <div class="inputs row">
                    <app-input-field-component class="col-12" label="ACCOUNTING.RECEIVED_COD" [form]="form"
                                               controlName="cod"
                                               [isRequired]="true" controlType="number">
                    </app-input-field-component >
                </div>
            </div>
            <div *ngIf="!mismatchId" class="col-12 packages-container">
                <div class="inputs row">
                    <app-input-field-component class="col-12" label="ACCOUNTING.EXPENSES" [form]="form"
                                               controlName="expenses"
                                               [isRequired]="false" controlType="number">
                    </app-input-field-component >
                </div>
            </div>
            <div class="expense-type">
                 <div class="row expense-type-ar col-12 packages-container" [ngClass]="{'expense-type-en': translateService.currentLang === 'en'}">
                          <app-input-field-component [form]="form"
                                                    controlName="expensesType" field="name"
                                                    [controlType]="'autoCompleteWithActions'"
                                                    [searchSuggestions]="expensesTypeSuggestion"
                                                    (onSearch)="getExpensesTypes($event)"
                                                    [forceSelection]="false"
                                                    [displayDropDown]="true" #expenseType
                                                    [addedExpenseType]="addedExpenseType"
                                                    (onDeleteExpenseTypeClicked) = "deleteExpenseType($event)"
                                                    (onAddExpenceClicked) = "addExpenseType($event)"
                                                    label="ACCOUNTING.EXPENSE_TYPE" [isRequired]="false">
                         </app-input-field-component >
                     </div>
                  </div>
        </div>
<!--        <div class="row is-received-ar" style="justify-content: space-between;" [ngClass]="{'is-received-en': translateService.currentLang === 'en'}">-->

<!--            <div class="is-received-field-input-ar" [ngClass]="{'is-received-field-input-en': translateService.currentLang === 'en'}">-->
<!--                <label class="is-received-field-input-ar-label" [ngClass]="{'is-received-field-input-en-label': translateService.currentLang === 'en'}">{{'ACCOUNTING.IS_RECEIVED'|translate}}</label>-->
<!--                <p-checkbox [formControl]="form?.controls['isReceived']" binary="true"></p-checkbox>-->

<!--            </div>-->
<!--            <div class="cursor-pointer">-->
<!--                <label class="receive-date-ar" [ngClass]="{'receive-date-en': translateService.currentLang === 'en'}">{{'ACCOUNTING.RECEIVE_DATE'|translate}}</label>-->
<!--                <p-calendar  styleClass="date-picker"-->
<!--                             formControlName="receiveDate"-->
<!--                             (onSelect)="setReceivedDate()"-->
<!--                             placeholder="{{'ACCOUNTING.RECEIVE_DATE' | translate}}"-->
<!--                             [disabled]="!form.controls.isReceived.value"-->
<!--                             dateFormat="dd/mm/yy"></p-calendar>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row is-print-report-ar" [ngClass]="{'is-print-report-en': translateService.currentLang === 'en'}">
            <div *ngIf="!mismatchId && !isEditMode">
                <label class="is-print-report-label-ar" [ngClass]="{'is-print-report-label-en': translateService.currentLang === 'en'}" >{{'ACCOUNTING.PRINT_RECEIVE_REPORT'|translate}}</label>
                <p-checkbox [formControl]="form?.controls['printReport']" binary="true"
                            (onChange)="togglePrintReport($event)"></p-checkbox>
            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div style="padding: 10px 30px">
                <div class="add-button cursor-pointer "
                     (click)="finishScanning()"
                     [ngClass]="{'disabled-button disabled': !form.valid || isLoading}">
                    <i class="far fa-save"></i>
                    {{'ACTIONS.VERIFY' | translate }}
                </div>
                <div class="reset-button cursor-pointer " (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
