<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="cod-request-container">
        <div class="row">
            <div class="close-modal">
                <img (click)="activeModal.close()" src="../../assets/icons/cancel.svg" alt="" />
            </div>
        </div>
        <div id="submitting-container" *ngIf="isSubmitting">
            <p-progressSpinner>
            </p-progressSpinner>
        </div>
        <div class="request-card">
            {{getMessage('MANAGE_SHIPMENTS.COD_REQUEST_CHANGE' | translate,pkg?.cod, pkg?.codChangeRequest.newCodValue )}}
        </div>
        <div class="actions">
            <div class="approve-action action-btn" (click)="approveCodChange()">
                {{'ACTIONS.APPROVE' | translate}}
            </div>
            <div class="reject-action action-btn" (click)="rejectCodChange()">
                {{'ACTIONS.REJECT' | translate}}
            </div>
        </div>
    </div>
</div>
