import {Component, OnInit} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from "../../accounting-constants";
import {TemplateTopActionsSettingsModel} from "../../models/template-top-actions-settings.model";
import {AccountingTemplateFiltersModel} from "../../models/accounting-template-filters.model";
import {AuthenticationService} from "../../../shared/services/authentication.service";
@Component({
    selector: 'app-exported-cod',
    templateUrl: './exported-cod.component.html',
    styleUrls: ['./exported-cod.component.scss']
})
export class ExportedCodComponent implements OnInit {

    title = ACCOUNTING_CONSTANTS.EXPORTED_COD.TITLE;
    info = ACCOUNTING_CONSTANTS.EXPORTED_COD.INFO;
    apiUrl = ACCOUNTING_CONSTANTS.EXPORTED_COD.API;
    AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.EXPORTED_COD.ROW_ACTIONS_SETTINGS;
    topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.EXPORTED_COD.TOP_ACTIONS_SETTINGS
    filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.EXPORTED_COD.FILTERS_SETTINGS;
    dateFiltersList = ACCOUNTING_CONSTANTS.EXPORTED_COD.DATE_FILTERS_LIST;
    showPrintAllForCompanies = [64];
    constructor(private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.topActionsSettings.printAll = this.showPrintAllForCompanies.indexOf(this.authService.companyId) > -1 ;
    }
}
