<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
  <div class="header d-inline-flex justify-content-between">
    <div class="title" *ngIf="!viewOnly && !isEditMode">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.NEW_ROWS' | translate }}</div>
    <div class="title" *ngIf="!viewOnly && isEditMode">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.EDIT_ROW' | translate }}</div>
    <div class="title" *ngIf="viewOnly">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.ROW_DETAILS' | translate }}</div>
    <div class="close-icon" (click)="closeModal()">
      <img src="../../../../assets/icons/cancel.svg" alt="icon" />
    </div>
  </div>

  <div class="loader" *ngIf="isLoading">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <form [formGroup]="rowForm">

    <div class="inputs-container">
      <div class="row row-input">
        <div class="col-6 input">
          <div class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME' | translate }}</div>
          <div class="input-value">
            <input type="text" size="3" pInputText formControlName="warehouseName"/>
          </div>
        </div>

        <div class="col-6 input">
          <div class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.AREAS.NAME' | translate }}</div>
          <div class="input-value">
            <input type="text" size="3" pInputText formControlName="areaName"/>
          </div>
        </div>
      </div>

      <div class="row row-input">
        <div class="col-6 input">
          <div [ngClass]="isInvalidDirtyTouched('name') ? 'invalid-message' : ''"
               class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.ROWS.NAME' | translate }}</div>
          <div class="input-value">
            <input type="text" size="3" pInputText formControlName="name" />
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('name', 'required')">
              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.ROW_NAME_REQUIRED' | translate }}
            </span>
          </div>
        </div>

        <div class="col-6 input">
          <div [ngClass]="isInvalidDirtyTouched('columnsNumber') ? 'invalid-message' : ''"
               class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.TOTAL_COLUMNS' | translate }}</div>
          <div class="input-value">
            <input type="number" size="3" pInputText formControlName="columnsNumber" />
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('columnsNumber', 'required')
              || isInvalidWithError('columnsNumber', 'min')">
              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.COLUMNS_NUMBER_REQUIRED' | translate }}
            </span>
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('columnsNumber', 'max')">
              {{( customizationCompaniesService.checkCompanies(companies.FAST_ACCESS, companies.LOGESTECHS) ? 'FULFILMENT.WAREHOUSE.VALIDATION.COLUMNS_NUMBER_MAX_FAST_ACCESS' : 'FULFILMENT.WAREHOUSE.VALIDATION.MAX_COLUMNS') | translate }}
            </span>
          </div>
        </div>
      </div>

      <div class="row row-input">
        <div class="col-6 input">
          <div [ngClass]="isInvalidDirtyTouched('shelvesNumber') ? 'invalid-message' : ''"
               class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.SHELVES_PER_COLUMN' | translate }}</div>
          <div class="input-value">
            <input type="number" size="3" pInputText formControlName="shelvesNumber" />
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('shelvesNumber', 'required')
              || isInvalidWithError('shelvesNumber', 'min')">
              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.SHELVES_NUMBER_REQUIRED' | translate }}
            </span>
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('shelvesNumber', 'max')">
              {{ (customizationCompaniesService.checkCompanies(companies.FAST_ACCESS, companies.LOGESTECHS)) ? ('FULFILMENT.WAREHOUSE.VALIDATION.SHELVES_NUMBER_MAX_FAST_ACCESS' | translate) : ('FULFILMENT.WAREHOUSE.VALIDATION.SHELVES_NUMBER_MAX' | translate) }}
            </span>
          </div>
        </div>

        <div class="col-6 input">
          <div [ngClass]="isInvalidDirtyTouched('locationsNumber') ? 'invalid-message' : ''"
               class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.LOCATIONS_PER_SHELF' | translate }}</div>
          <div class="input-value">
            <input type="number" size="3" pInputText formControlName="locationsNumber"  />
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('locationsNumber', 'required')
              || isInvalidWithError('locationsNumber', 'min')">
              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.LOCATIONS_NUMBER_REQUIRED' | translate }}
            </span>
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('locationsNumber', 'max')">
              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.LOCATIONS_NUMBER_MAX' | translate }}
            </span>
          </div>
        </div>

        <div class="col-6 input">
          <div [ngClass]="isInvalidDirtyTouched('locationType') ? 'invalid-message' : ''"
               class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.LOCATION_TYPE' | translate }}</div>
          <div class="input-value dropdown-container">
            <p-dropdown
                    class="d-flex w-100"
                    formControlName="locationType"
                    [options]="locationTypes"
                    styleClass="flex-1"
                    placeholder=" "></p-dropdown>
          </div>
          <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('locationType', 'required')">
              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.LOCATION_TYPE_REQUIRED' | translate }}
            </span>
          </div>
        </div>

      </div>
    </div>

    <div class="bottom-actions">
      <div *ngIf="!viewOnly" class="done-action" (click)="submitForm()" [ngClass]="{'disabled-btn disabled': !rowForm.valid || isLoading}">
        <span>{{'ACTIONS.SUBMIT' | translate}}</span>
      </div>
      <div *ngIf="viewOnly" class="done-action" (click)="closeModal()">
        <span>{{'ACTIONS.DONE' | translate}}</span>
      </div>
    </div>
  </form>
</div>
