<div class="new-hub-container d-flex flex-column h-100">
    <div id="submitting-container" *ngIf="isSubmitting">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div *ngIf="isLoading; else loaded" class="flex-1">
        <div style="text-align: center">
            <p-progressSpinner>
            </p-progressSpinner>
        </div>
    </div>

    <ng-template #loaded>
        <div class="header-container">
            {{"BRANCHES.SHELVES" | translate}}
            <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
        </div>
        <div class="top-buttons-container" style="margin-top: 20px">
            <div class="create-new-branch-container">
                <div class="create-new-branch-button action-button cursor-pointer" (click)="onNewShelf()"
                     style="margin: 0 10px">
                    {{"ACTIONS.CREATE_NEW_SHELF" | translate}}
                </div>
                <div class="create-new-branch-button action-button cursor-pointer" (click)="print()">
                    {{"MANAGE_CONTAINERS.PRINT" | translate}}
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isLoading && shelves?.length > 0">
            <div class=" row flex-1">
                <app-shelf-item class="col-4" *ngFor="let shelf of shelves" [shelf]="shelf"
                                [selectedShelves]="selectedShelves"
                                (onRemoveShelf)="removeShelf($event)" (onEditShelf)="modifyShelf($event)">
                </app-shelf-item>
            </div>
        </ng-container>

    </ng-template>
    <div class="flex-1" *ngIf="!isLoading && shelves?.length == 0" style="position: relative">
        <app-no-data-available-component>
        </app-no-data-available-component>
    </div>
    <div class="hubs-paginator" [ngStyle]="{'opacity':  shelves?.length == 0 ? 0 : 1}">
        <p-paginator
            #paginator
            [rows]="6"
            [totalRecords]="totalRecords"
            (onPageChange)="onPaginate($event.page)"></p-paginator>
    </div>
</div>
