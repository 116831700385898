<div class="h-100 d-flex flex-column main-container">

    <div class="d-flex flex-row header">
        <div class="header-filters d-flex flex-row"
             style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
            <div class="cod-title flex-1">
                {{"COMPANY.TOTAL_PLACED_PACKAGES_NO" | translate}}: {{totalPlacedPackagesNo}}
            </div>
        </div>
        <div class="flex-1 p-2">

        </div>

        <div *ngIf="!isPending" class="cursor-pointer disabled-company-filter-button" style="margin-right: 10px">
            <p-checkbox label="{{'COMPANY.SHOW_DISABLED_COMPANIES' | translate}}" binary="true"
                        [(ngModel)]="isDisabled" (onChange)="initShipments()"></p-checkbox>
        </div>
       <div class="actions-btns">
           <div  class="cursor-pointer export-button" style="margin-right: 10px" (click)="exportCompanies()">
               <i  class="fas fa-file-excel fa-2x"></i>
           </div>
           <div [ngClass]="{'disabled-btn disabled': !searchContent.length}" class="cursor-pointer date-filter-button" style="margin-right: 10px" (click)="resetDate()">
               <i class="far fa-calendar-alt fa-2x"></i>
           </div>
       </div>

        <div class="from-container cursor-pointer p-2" style="min-width: 120px">
            <i class="pi pi-calendar date-picker-icon"></i>
            <p-calendar styleClass="date-picker" [(ngModel)]="fromDate" (onSelect)="onSearch()"
                        placeholder="{{'MANAGE_SHIPMENTS.FROM' | translate}}" dateFormat="dd/mm/yy"></p-calendar>
        </div>

        <div class="cursor-pointer p-2" style="min-width: 120px">
            <i class="pi pi-calendar date-picker-icon"></i>
            <p-calendar styleClass="date-picker" [(ngModel)]="toDate" (onSelect)="onSearch()"
                        placeholder="{{'MANAGE_SHIPMENTS.TO' | translate}}" dateFormat="dd/mm/yy"></p-calendar>
        </div>

        <div class="currency-filter flex-2d-center">
            <label>{{'GENERAL.COUNTRY' | translate}}</label>
            <p-dropdown styleClass="shipment-status-dropdown"
                        [(ngModel)]="selectedCurrencyFilter"
                        [options]="currenciesList" filter="true"
                        (onChange)="initShipments()">
            </p-dropdown>
        </div>
        <div class="search-input p-2" style="min-width: 120px">
            <input class="ui-inputtext ui-corner-all" type="text" style="width: 100%"
                   appInputDebounced (inputDebounced)="onSearch()"
                   placeholder="{{'GENERAL.SEARCH' | translate}}..."
                   [(ngModel)]="searchContent">
        </div>
        <div class="p-2 d-flex" *ngIf="isPending">
            <div class="add-new-company cursor-pointer" (click)="addNewCompany()">
                {{'COMPANY.ADD_COMPANY'| translate}}
            </div>
        </div>
        <div class="p-2 d-flex" *ngIf="isPending">
            <p-checkbox [(ngModel)]="showRejected" binary="true" label="{{'COMPANY.SHOW_REJECTED_COMPANY'|translate}}"
                        (onChange)="onSearch()"></p-checkbox>
        </div>

    </div>
    <div class="flex-1 overflow-auto">
        <p-table dataKey="id" #table [value]="companies" styleClass="packages-table flex-table"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="false" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th> {{"COMPANY.NAME" | translate}}</th>
                    <th> {{"COMPANY.PHONE" | translate }}</th>
                    <th> {{"COMPANY.ADDRESS" | translate}}</th>
                    <th> {{"COMPANY.PLACED_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.CANCELLED_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.ACCOUNTABLE_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.PICKED_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.DELIVERED_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.RETURNED_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.TRANSFERRED_BY_PARTNERS_PACKAGES" | translate}}</th>
                    <th> {{"COMPANY.NUMBER_OF_RETURNED_PACKAGES_BEFORE_ARRIVAL" | translate}}</th>
                    <th> {{"COMPANY.PACKAGES_NUMBER_EXCLUDING_SUB-BUNDLES" | translate}}</th>
                    <th style="width: 200px" *ngIf="!isMonitor()"> {{"COMPANY.MANAGE" | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company let-columns="columns">
                <tr>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"COMPANY.NAME" | translate}}
                            </div>
                            <div class="value">
                                {{company.name}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.PHONE" | translate }}</div>
                            <div class="value">{{company.phone}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.ADDRESS" | translate}}</div>
                            <div class="value">{{company.city}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.PLACED_PACKAGES" | translate}}</div>
                            <div class="value">{{company.placedPackagesNumber}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.CANCELLED_PACKAGES" | translate}}</div>
                            <div class="value">{{company.cancelledPackagesNumber}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.ACCOUNTABLE_PACKAGES" | translate}}</div>
                            <div class="value">{{company.accountablePackagesNumber}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.PICKED_PACKAGES" | translate}}</div>
                            <div class="value">{{company.pickedPackagesNumber}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.DELIVERED_PACKAGES" | translate}}</div>
                            <div class="value">{{company.deliveredPackagesNumber }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.RETURNED_PACKAGES" | translate}}</div>
                            <div class="value">{{company.returnedPackagesNumber}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.TRANSFERRED_BY_PARTNERS_PACKAGES" | translate}}</div>
                            <div class="value">{{company.transerredByPartnersPackagesNumber}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.NUMBER_OF_RETURNED_PACKAGES_BEFORE_ARRIVAL" | translate}}</div>
                            <div class="value">{{company.numberOfReturnedPackagesBeforeArrival}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.PACKAGES_NUMBER_EXCLUDING_SUB-BUNDLES" | translate}}</div>
                            <div class="value">{{company.packagesNumberExcludingSubBundles}}</div>
                        </div>
                    </td>
                    <td *ngIf="!isMonitor()">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.MANAGE" | translate}}</div>
                            <div class="value">
                                <div class="button-group d-flex">
                                    <ng-container *ngIf="isPending; else approve">
                                        <button type="button" class="button-small btn fa fas fa-times"
                                                [ngClass]="{'btn-outline-danger':company.status !== 'REJECTED', 'btn-danger':company.status === 'REJECTED'}"
                                                [disabled]="company.status === 'REJECTED'"
                                                (click)="discardCompany(company)"></button>
                                        <button type="button" class="button-small fa btn btn-outline-success fas fa-check"
                                                (click)="approveCompany(company)"></button>
                                        <button type="button" class="button-small fa btn btn-outline-primary fas fa-pencil"
                                                (click)="editCompany(company)"></button>
                                    </ng-container>
                                    <ng-template #approve>
                                        <button type="button" style="padding: 2px" class="button-small btn btn-outline-info fas fa fa-globe"
                                                [routerLink]="company.link"></button>
                                        <button type="button" style="padding: 2px" class="button-small btn btn-outline-primary fas fa fa-pencil"
                                                (click)="editCompany(company)"></button>
                                        <p-toggleButton class="button-small-toggle" [ngModel]="company.status === 'ENABLED'"
                                                        (ngModelChange)="enableCompany($event, company)"
                                                        onLabel="{{'COMPANY.ENABLED' | translate}}"
                                                        offLabel="{{'COMPANY.DISABLED' | translate}}" onIcon="pi pi-check"
                                                        offIcon="pi pi-times"
                                                        [style]="{'width':'100px'}"></p-toggleButton>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="header-filters d-flex flex-row"
         style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
        <div class="cod-title flex-1">
            {{"COMPANY.NUMBER_OF_COMPANIES" | translate}}: {{totalRecords}}
        </div>
        <div class="cod-title flex-1">
            {{"COMPANY.NUMBER_OF_ACTIVE_COMPANIES" | translate}}: {{activeCompaniesNumber}}
        </div>
    </div>
</div>
