import {Component, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../reports-constants';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportsService} from '../../services/reports';

@Component({
    selector: 'app-summary-reports',
    templateUrl: './summary-reports.component.html',
    styleUrls: ['./summary-reports.component.scss']
})
export class SummaryReportsComponent implements OnInit {

    public report = '';
    public isDelivered = '';
    public customerReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.CUSTOMER_REPORT;
    public customerReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.CUSTOMER_REPORT_API;

    public driverReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.DRIVER_REPORT;
    public driverReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.DRIVER_REPORT_API;
    public zonesToReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_TO_REPORT;
    public zonesToReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_TO_REPORT_API;
    public cityToUndeliveredCODSumAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.CITY_UNDELIVERED_TO_COD_SUM_REPORT_API;
    public cityTuUndeliveredCODSumReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.CITY_UNDELIVERED_TO_COD_SUM_REPORT;

    public zonesFromReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_FROM_REPORT;
    public zonesFromReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_FROM_REPORT_API;

    public zonesReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_REPORT;
    public zonesReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_REPORT_API;

    public undeliveredPackagesReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.UNDELIVERED_PACKAGES_REPORT;
    public undeliveredPackagesReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.UNDELIVERED_PACKAGES_REPORT_API;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.report = params.report;
            this.isDelivered = params.isDelivered;
        });
    }
}
