
<ng-template #tabsList>
    <div class="row-tabs-container">
        <div class="tab customers-tab" (click)="onChangeTab('videos-system-updates')">{{ 'SYSTEM_UPDATES.VIDEOS_SYSTEM_UPDATES' | translate }}</div>
        <div class="tab customers-tab" (click)="onChangeTab('documents-system-updates')">{{ 'SYSTEM_UPDATES.DOCUMENTS_SYSTEM_UPDATES' | translate }}</div>
    </div>
</ng-template>
<div class="documents-system-updates h-100">
    <div class="documents-updates-header d-flex justify-content-between align-items-center">
        <div class="title-container d-flex align-items-center">
            <div class="title-text part-1">{{"SYSTEM_UPDATES.BREADCRUMB.KNOWLEDGE_CENTER" | translate}}</div>
            <div class="title-splitter">/</div>
            <div class="tab d-flex justify-content-center align-items-center tabs-list-container">
                <div class="tabs-list d-inline-flex align-items-center" placement="bottom" autoClose="outside" [ngbPopover]="tabsList">
                    <div class="title-text">{{ ("SYSTEM_UPDATES.BREADCRUMB." + (videos ? "VIDEOS" : "DOCUMENTS")) | translate}}</div>
                    <div class="total-records-badge flex-2d-center">{{totalNumberOfUpdates}}</div>
                    <div class="caret-down">
                        <i class="fas fa-caret-down"></i>
                    </div>
                </div>
            </div>
            <div class="mr-3"></div>
            <ng-container *ngIf="isSuperAdmin">
                <div class="add-update d-flex" *ngIf="videos"
                     (click)="openAddUpdateDialog('VIDEO')">
                    <div class="icon">
                        <img src="../../../assets/icons/plus.svg" alt=""/>
                    </div>
                    <div class="label">
                        <span>{{'SYSTEM_UPDATES.ADD_SYSTEM_UPDATE_WITH_VIDEO' | translate}}</span>
                    </div>
                </div>
                <div class="add-update d-flex" *ngIf="documents"
                     (click)="openAddUpdateDialog('PDF')">
                    <div class="icon">
                        <img src="../../../assets/icons/plus.svg" alt=""/>
                    </div>
                    <div class="label">
                        <span>{{'SYSTEM_UPDATES.ADD_SYSTEM_UPDATE' | translate}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="search-documents d-flex align-items-center">
            <div class="search-icon">
                <img src="../../../../assets/icons/search.svg" alt=""/>
            </div>
            <div class="document-search-input"><input type="text" placeholder="{{'GENERAL.SEARCH' |translate}}"
                                                      [(ngModel)]="searchContent"
                                                      appInputDebounced
                                                      (inputDebounced)="onSearch()" ></div>

        </div>
    </div>
    <div class="documents-updates-main-container">
        <ng-container *ngIf="documents">
            <app-documents-system-updates [filter]="filterOb.asObservable()" [addedOrUpdateEvent]="updateAddedOrUpdated.asObservable()"
                                          (totalChange)="totalChange($event)"></app-documents-system-updates>
        </ng-container>

        <ng-container *ngIf="videos">
            <app-video-system-updates [filter]="filterOb.asObservable()" [addedOrUpdateEvent]="updateAddedOrUpdated.asObservable()"
                                      (totalChange)="totalChange($event)"></app-video-system-updates>
        </ng-container>

    </div>
</div>
