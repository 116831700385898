import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TicketingSystemService} from '../../../services/ticketing-system.service';

@Component({
    selector: 'app-ticket-history',
    templateUrl: 'ticket-history.component.html',
    styleUrls: ['ticket-history.component.scss']
})
export class TicketHistoryComponent implements OnInit {
    selectedLang;
    isLoading = false;
    data;
    @Input() TicketId;
    actions = [];

    constructor(private translateService: TranslateService,
                private ticketingSystemService: TicketingSystemService) {}

    ngOnInit() {
        this.selectedLang = this.translateService.currentLang;
        this.isLoading = true;
        this.ticketingSystemService.getHistory(this.TicketId).subscribe((res:any) => {
            this.data = res.ticket;
            this.actions = res.items && res.items.length ? res.items[0].actions:[];
            this.isLoading = false;
        });

    }
}
