<p-toast [baseZIndex]="toastZIndex"> </p-toast>
<ng-template #rowActions let-close="close">
    <div class="row-actions-container">
        <div class="row action" (click)="modifyHub(triggeredHub)">
            <div class="icon">
                <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) === -1" class="label">{{ 'BRANCHES_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</div>
            <div *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1" class="label">{{ 'STATIONS_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</div>
            <div *ngIf="isWarehouse" class="label">{{ 'FULFILMENT.WAREHOUSE.EDIT_WAREHOUSE' | translate }}</div>
        </div>
        <div *ngIf="!isWarehouse && permissions.includes('DELETE')" class="row action remove-action" (click)="removeHub(triggeredHub)">
            <div class="icon">
                <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngIf="companyHadStationsInsteadOfBranches.indexOf(companyId) === -1" class="label">{{ 'BRANCHES_MANAGEMENT.ACTIONS.DELETE_BRANCH' | translate }}</div>
            <div *ngIf="companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1" class="label">{{ 'STATIONS_MANAGEMENT.ACTIONS.DELETE_BRANCH' | translate }}</div>

        </div>
    </div>
</ng-template>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="hubs-management-container" [@simpleFadeAnimation]="'in'">
        <div class="hub-details-container overflow-auto">
           <app-branch-details
                   [isWarehouse]="isWarehouse"
                   [isStations] = "companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1"
                   [isShow]="isShowHubDetail"
                   [permissions]="permissions"
                   [hub]="triggeredHub"
                   (onCopyBarcode)="copyMessage(null, null, $event.barcode)"
                   (onModifyHub)="modifyHub($event.hub)"
                   (onRemoveHub)="removeHub($event.hub)"
                   (onCloseHubDetails)="onHideHubDetails($event)"
                   class="component">
           </app-branch-details>
        </div>
        <div class="d-flex top-side">
            <div class="d-inline-flex header">
                <div class="hubs-management-title">
                    <span *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) === -1">{{ 'BRANCHES_MANAGEMENT.MANAGE_BRANCHES' | translate }} <span class="pl-2 pr-2">\</span> </span>
                    <span *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1">{{ 'STATIONS_MANAGEMENT.MANAGE_BRANCHES' | translate }} <span class="pl-2 pr-2">\</span> </span>

                    <div class="page-header-title"  *ngIf="isWarehouse">
                        <div class="title">{{ 'FULFILMENT.WAREHOUSE.MANAGE_WAREHOUSES' | translate }}</div>
                        <div class="total-number-badge">{{totalRecords}}</div>
                    </div>

                    <ng-container  *ngIf="!isWarehouse">

                        <div class="tabs-list-container header-btn d-inline-block">
                            <div class="tabs-list d-inline-flex" placement="bottom" autoClose="true" [ngbPopover]="tabsList">
                                <div class="current-tab">{{getTabName() | translate }}</div>
                                <div class="total-number-badge ml-2 mr-2"><span>{{totalRecords}}</span></div>
                                <div class="caret-down">
                                    <i class="fas fa-caret-down"></i>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="flex-1 hidden-sm-down"></div>
            <div *appPermission="permissions.includes('{ADD') && !archivedBranches" class="d-flex add-action" (click)="onNewHub()">
                <div class="icon">
                    <i class="fas fa-plus"></i>
                </div>
                <div class="print-hidden-space"></div>
                <div class="label">
                    <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.NEW_WAREHOUSE' | translate }}</span>
                    <span *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) === -1">{{ 'BRANCHES_MANAGEMENT.NEW_BRANCH' | translate }}</span>
                    <span *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1">{{ 'STATIONS_MANAGEMENT.NEW_BRANCH' | translate }}</span>
                </div>
            </div>
            <div class="d-flex print-action" [ngClass]="{'disabled-btn disabled': !(getSelectedHubs().length)}" (click)="print()">
                <div class="icon" *ngIf="!printLoader">
                    <img src="../../assets/icons/containers-management/print.svg" alt="" />
                </div>
                <div class="loading" *ngIf="printLoader">
                    <i class="fas fa-spinner fa-pulse"></i>
                </div>
                <div class="print-hidden-space"></div>
                <div class="label">{{ 'ACTIONS.PRINT' | translate }}</div>
            </div>

            <div class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>
           <div class="show-mode-container hidden-sm-down">
               <div class="d-inline-flex show-mode">
                   <div class="top-side-label show-mode-label hidden-sm-down">{{ 'BRANCHES_MANAGEMENT.VIEW_MODE' | translate }}</div>
                   <div class="show-mode-hidden-space hidden-sm-down"></div>
                   <div class="d-inline-flex show-mode-options">
                       <div class="table-mode mode" [ngClass]="{'active-view-mode': viewMode === 'TABLE_MODE'}" (click)="onSetShowMode('TABLE_MODE')">
                           <img *ngIf="viewMode === 'TABLE_MODE'" src="../../assets/icons/containers-management/list_selected.svg" alt="icon" />
                           <img *ngIf="viewMode === 'CARDS_MODE'" src="../../assets/icons/containers-management/list_unselected.svg" alt="icon" />
                       </div>
                       <div class="cards-mode mode" [ngClass]="{'active-view-mode': viewMode === 'CARDS_MODE'}" (click)="onSetShowMode('CARDS_MODE')">
                           <img *ngIf="viewMode === 'CARDS_MODE'" src="../../assets/icons/containers-management/grid_selected.svg" alt="icon" />
                           <img *ngIf="viewMode === 'TABLE_MODE'" src="../../assets/icons/containers-management/grid_unselected.svg" alt="icon" />
                       </div>
                   </div>
               </div>
           </div>
            <div class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>
            <div class="search-toggle cursor-pointer">
                <div class="manage-shipments-search-filter search-filter-container d-flex">
                    <div class="d-flex">
                        <div class="search-filter-toggle" (click)="toggleIsSearchContent(true)">
                            <img style="width: 100%;" src="../../assets/icons/search.png" alt="search-Image" />
                            <!--                            <i class="fas fa-search" [ngClass]="{'fa-rotate-90': selectedLanguage==='ar'}"></i>-->
                        </div>
                        <div class="search-input"  *ngIf="isSearchContent">
                            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent" (input)="onSearch()" />
                        </div>
                    </div>
                    <div *ngIf="isSearchContent" class="hide-search-filter" (click)="toggleIsSearchContent(false)" >
                        <span>{{'ACTIONS.CANCEL' | translate}}</span>
                    </div>
                </div>
            </div>
<!--            <div class="search-filter d-flex" style="margin-inline-start: 15px">-->
<!--                <div class="search-icon-2">-->
<!--                    <i class="fas fa-search" [ngClass]="{'fa-rotate-90': currentLang==='ar'}"></i>-->
<!--                </div>-->
<!--                <div class="search-field-input">-->
<!--                    <field-input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent" (field-input)="onSearch()" />-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="data-table hubs-data-table" *ngIf="viewMode === 'TABLE_MODE'">
            <div class="containers-body flex-1 overflow-auto" (scroll)="onScrollTable($event)">
                <p-table [value]="hubs" [responsive]="true" scrollHeight="auto" styleClass="data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                <p-checkbox [(ngModel)]="isAllSelected" binary="true" (onChange)="onToggleSelectAll()"> </p-checkbox>
                            </th>
                            <th>
                                <span *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) === -1">{{ 'BRANCHES_MANAGEMENT.TABLE.BRANCH_NAME' | translate }}</span>
                                <span *ngIf="!isWarehouse && companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1">{{ 'STATIONS_MANAGEMENT.TABLE.BRANCH_NAME' | translate }}</span>
                                <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME' | translate }}</span>
                            </th>
                            <th>
                                <span *ngIf="companyHadStationsInsteadOfBranches.indexOf(companyId) === -1">
                                    {{ 'BRANCHES_MANAGEMENT.TABLE.BRANCH_BARCODE' | translate }}
                                </span>
                                <span *ngIf="companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1">
                                    {{ 'STATIONS_MANAGEMENT.TABLE.BRANCH_BARCODE' | translate }}
                                </span>
                            </th>
                            <th>{{ 'BRANCHES_MANAGEMENT.TABLE.CONTACT' | translate }}</th>
                            <th>{{ 'BRANCHES_MANAGEMENT.TABLE.PHONE' | translate  }}</th>
                            <th>{{ 'BRANCHES_MANAGEMENT.TABLE.ADDRESS' | translate }}</th>
                            <th></th>
                            <th *ngIf="isWarehouse"></th>
                            <th *ngIf="isWarehouse"></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content">
                            <app-no-data-available-component> </app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-hub>
                        <tr class="shipment-row" [ngClass]="{'scanned-row':hub.isSelected}">

                            <td>
                                <p-checkbox [(ngModel)]="hub.isSelected" binary="true"> </p-checkbox>
                            </td>
                            <td (click)="showHubDetails(hub)">
                                <div class="td-text">{{hub.name}}</div>
                            </td>
                            <td>
                                <div class="d-inline-flex container-number">
                                    <div class="number barcode-number" (click)="showHubDetails(hub)">#{{hub.barcode}}</div>
                                    <div class="container-number-hidden-space"></div>
                                    <div class="copy-icon cursor-pointer">
                                        <img src="../../assets/icons/track_package/copy.svg" alt="" (click)="copyMessage($event,null, hub.barcode)" data-toggle="tooltip" data-placement="top" title="Copy" class="copy_icon" />
                                    </div>
                                </div>
                            </td>
                            <td (click)="showHubDetails(hub)"><div class="number">022903023</div></td>
                            <td (click)="showHubDetails(hub)"><div class="number">{{hub.phone}}</div></td>
                            <td (click)="showHubDetails(hub)">
                                <div class="td-address">
                                    {{getAddress(hub.address)}}
                                </div>
                            </td>
                            <td>
                                <div *ngIf="isWarehouse" class="d-inline-flex shelfs-details"
                                     (click)="manageWarehouse({hub: hub, type: 'AREAS'})">
                                    <div class="hub-details-icon">
                                        <img class="margin-left-10 img-icon" src="../../../assets/icons/branches-management/warehouse.png" alt="icon" />
                                    </div>
                                    <div class="container-detail-hidden-space"></div>
                                    <div class="label">
                                        <span>{{ 'FULFILMENT.WAREHOUSE.AREAS.TITLE' | translate }}</span>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="isWarehouse">
                                <div  class="d-inline-flex shelfs-details"
                                     (click)="manageWarehouse({hub: hub, type: 'ROWS'})">
                                    <div class="hub-details-icon">
                                        <img class="margin-left-10 img-icon" src="../../../assets/icons/branches-management/row.png" alt="icon" />
                                    </div>
                                    <div class="container-detail-hidden-space"></div>
                                    <div class="label">
                                        <span>{{ 'FULFILMENT.WAREHOUSE.ROWS.TITLE' | translate }}</span>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="isWarehouse">
                                <div class="d-inline-flex shelfs-details"
                                     (click)="manageWarehouse({hub: hub, type: 'LOCATIONS'})">
                                    <div class="hub-details-icon">
                                        <img class="margin-left-10 img-icon" src="../../../assets/icons/branches-management/locations.png" alt="icon" />
                                    </div>
                                    <div class="container-detail-hidden-space"></div>
                                    <div class="label">
                                        <span>{{ 'FULFILMENT.WAREHOUSE.LOCATIONS.TITLE' | translate }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row-actions">
                                    <img
                                            (click)="setTriggeredHub(hub)"
                                            style="padding: 10px"
                                            placement="{{currentLang === 'en' ? 'left': 'right'}}"
                                            autoClose="outside"
                                            #rowActionsPopOver="ngbPopover"
                                            [ngbPopover]="rowActions"
                                            src="../../assets/icons/containers-management/menu.svg"
                                            alt=""
                                    />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader *ngIf="isLoading" [type]="1"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <!--        cards mode-->
        <div class="cards-view-mode-container" *ngIf="viewMode === 'CARDS_MODE'">
            <div class="row" style="height: 100%; position: relative;">
                <div class="hubs-list-container">
                    <div class="select-all-toggle-row">
                        <div class="row select-all-hubs">
                            <div class="select-all-checkBox">
                                <p-checkbox [(ngModel)]="isAllSelected" binary="true" (onChange)="onToggleSelectAll()"> </p-checkbox>
                            </div>
                            <div style="width: 20px;"></div>
                            <div class="select-all-label">
                                <span>{{ 'GENERAL.SELECT_ALL' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="containers-list row" (scroll)="onScrollTable($event)">
                        <ng-container *ngIf="hubs.length">
                            <app-branch-card-component
                                    *ngFor="let hub of hubs"
                                    [hub]="hub"
                                    [permissions]="permissions"
                                    [isWarehouse]="isWarehouse"
                                    [isStations]="companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1"
                                    (onModifyHub)="modifyHub($event.hub)"
                                    (onRemoveHub)="removeHub($event.hub)"
                                    (onClickShowDetails)="showHubDetails($event.hub)"
                                    (onManageWarehouse)="manageWarehouse($event)"
                                    (onRetrieve)="onRetrieveHub($event)"
                                    (onCopyBarcode)="copyMessage(null,null, $event.barcode)">
                            </app-branch-card-component>
                        </ng-container>

                        <ng-container *ngIf="isLoading">
                            <app-skeleton-loader *ngFor="let loader of [0, 1, 2, 3, 4, 5, 6, 7, 8]" [isSingleLoader]="true" [type]="0"></app-skeleton-loader>
                        </ng-container>
                        <ng-container *ngIf="!hubs.length && !isLoading">
                            <app-no-data-available-component></app-no-data-available-component>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>
    </div>
<!--    <div *ngIf="isLoading" class="cards-loader">-->
<!--        <i class="fas fa-spinner fa-pulse"></i>-->
<!--    </div>-->
</div>

<ng-template #tabsList>
    <div class="row-tabs-container" *ngIf="companyHadStationsInsteadOfBranches.indexOf(companyId) === -1">
        <div class="tab hub-tab" (click)="onChangeTab(false)">{{ 'BRANCHES_MANAGEMENT.BRANCHES' | translate }}</div>
        <div class="tab hub-tab archived-hub-tab" (click)="onChangeTab(true)">{{ 'BRANCHES_MANAGEMENT.ARCHIVED_BRANCHES' | translate }}</div>
    </div>
    <div class="row-tabs-container" *ngIf="companyHadStationsInsteadOfBranches.indexOf(companyId) !== -1">
        <div class="tab hub-tab" (click)="onChangeTab(false)">{{ 'STATIONS_MANAGEMENT.BRANCHES' | translate }}</div>
        <div class="tab hub-tab archived-hub-tab" (click)="onChangeTab(true)">{{ 'STATIONS_MANAGEMENT.ARCHIVED_BRANCHES' | translate }}</div>
    </div>
</ng-template>
