<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="receive-table-view d-flex flex-column h-100">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div>
        <div class="receive-header d-flex flex-row"
             style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
            <div class="cod-title flex-1" *ngIf="driverName">
                {{"ACCOUNTING.DRIVER_NAME" | translate}}: {{driverName}}
            </div>
            <div class="cod-title flex-1">
                {{"SHIPMENT.NUMBER_OF_SCANNED_PACKAGES" | translate}}: {{totalRecords}}
            </div>
        </div>
    </div>

    <div class="driver-receive-table flex-1 overflow-auto">
        <p-table #packagesTable dataKey="id" #table [value]="packages"
                 styleClass="customers-table flex-table" [rowsPerPageOptions]="rowsPerPageOptions"
                 [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="codPackagesCount">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;"> {{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th *appPermission="permissions.includes('{EDIT_COST')"
                        style="width: 80px"> {{"MANAGE_SHIPMENTS.COST" | translate}} </th>
                    <th>{{"ADD_SHIPMENT.INVOICE_NUMBER" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.SENDER_CITY" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.RECEIVER_CITY" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.SENDER_NAME" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.RECEIVER_NAME" | translate}}</th>
                    <th style="overflow-wrap: break-word">{{"MANAGE_COD_CUSTOMERS.NOTES" | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.COD" | translate}}</th>
                    <th *ngIf="isScanning">{{"ACCOUNTING.REMOVE_PACKAGE"|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr [ngClass]="{'scanned-row':isPackageScanned(package.id)}">
                    <td style="width: 150px; text-align: center">
                        <img height="50px" class="barcode-image" [src]="package.barcodeImage">
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.barcode)"></i>
                        </span>
                    </td>
                    <td *appPermission="permissions.includes('{EDIT_COST')" pEditableColumn style="width: 80px;"
                        [pEditableColumn]="package"
                        [pEditableColumnField]="'cost'">
                        <app-edit-cost [package]="package"></app-edit-cost>
                    </td>
                    <td>{{package.invoiceNumber}}</td>
                    <td>{{package.originAddress.city}}</td>
                    <td>{{package.destinationAddress.city}}</td>
                    <td>{{package.senderFirstName + " " + ((package.senderMiddleName) ? package.senderMiddleName : "") +
                    " " + package.senderLastName}}</td>
                    <td>{{package.receiverFirstName + " " + ((package.receiverMiddleName) ? package.receiverMiddleName : "") +
                    " " + package.receiverLastName}}</td>
                    <td>{{package.notes}}</td>
                    <td>{{package.cod}}</td>
                    <td *ngIf="isScanning && !isPackageScanned(package.id)">
                        <div class="actions-container">
                            <div class="scan-action action cursor-pointer" (click)="scanPackage(package)">
                                <i class="fas fa-barcode"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isScanning && isPackageScanned(package.id)">
                        <div class="actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackage(package)">
                                <i class="fas fa-minus-circle"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="table-actions">
        <div class="add-to-container green cursor-pointer"
             (click)="downloadMultiplePackages()"
             [ngClass]="{'disable': (!scannedPackages.length)}">
            <i class="fas fa-pencil-alt"></i>
            {{"SHIPMENT.PRINT_PACKAGES" | translate}}
        </div>
        <div *ngIf="!isScanning" class="add-to-container green cursor-pointer" (click)="startScanning()">
            {{"ACCOUNTING.START_SCANNING" | translate}}
        </div>
        <div *ngIf="isScanning" class="add-to-driver-container red cursor-pointer" (click)="resetScanning()">
            {{"ACCOUNTING.RESET" | translate}}
        </div>
        <div *ngIf="isScanning" class="add-to-driver-container green cursor-pointer" (click)="enterBarcode()">
            {{"ACCOUNTING.ENTER_BARCODE" | translate}}
        </div>
        <div *ngIf="isScanning" class="add-to-driver-container green cursor-pointer" (click)="finishScanning()">
            {{"SHIPMENT.UNLOAD_PACKAGES" | translate}}
        </div>
    </div>
</div>
