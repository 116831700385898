<p-toast [baseZIndex]="toastZIndex"></p-toast>

<div class="cod-customers-view d-flex flex-column h-100">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="header-filters filters-container d-flex flex-row" [ngClass]="selectedLanguage === 'en' ? 'page-ltr' : 'page-rtl'">

        <div class="cod-title flex-1">
            <i routerLink="../cod-mismatch" class="fas fa-arrow-right cursor-pointer"></i>
            <span>{{"MANAGE_COD_CUSTOMERS.COD_MISMATCH_ALL_PACKAGES" | translate }}</span>
        </div>

<!--        <div class="dropdown-filters-container">-->
<!--            <div class="order-by">-->
<!--                <div class="field-input-label">{{'TICKETING_SYSTEM.SORT_BY' | translate}}</div>-->
<!--                <div class="order-field-input-value">-->
<!--                    <ng-select-->
<!--                            styleClass="tickets-sort-by-list"-->
<!--                            [(ngModel)]="selectedOrderByOption"-->
<!--                            [items]="orderByOptions" filter="true"-->
<!--                            (change)="onChangeOrderBy()"-->
<!--                            [style]="{'width':'100%'}">-->
<!--                    </ng-select>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div *ngIf="!mismatch && !isHoldCustody && (receive||sort || export)" class="date-filter">-->
<!--                <div class="date-label">-->
<!--                    <p-dropdown styleClass="date-filter-dropdown"-->
<!--                                [(ngModel)]="selectedDateFilter"-->
<!--                                [options]="dateFiltersList" filter="true"-->
<!--                                (onChange)="onDateFilterChange()">-->
<!--                    </p-dropdown>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="date-picker-filter">-->
<!--            <i class="fas fa-calendar" (click)="datepicker.click()"></i>-->
<!--            <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                   #datepicker-->
<!--                   daterangepicker [options]="dateRangPickerOptions"-->
<!--                   (selected)="onDateSelected($event)"/>-->
<!--            <i class="fas fa-caret-down"></i>-->
<!--        </div>-->
        <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

        <div class="search-container">
            <i class="fas fa-search"></i>
            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                   [(ngModel)]="searchContent" appInputDebounced (inputDebounced)="onSearch()"/>
        </div>
    </div>


    <div class="header-actions pl-4 pr-4">
        <div class="excel-button"
             (click)="openExcel()">
            <i class="fas fa-file-excel"></i>
            {{ 'ACTIONS.EXPORT_TO_EXCEL' | translate}}
        </div>
    </div>

    <div class="cod-customers-table flex-1 overflow-auto">
        <p-table
            #packagesTable
            appPTableRtlFix
            dataKey="id"
            #table
            [value]="packages"
            (onPage)="setPaginatorQueryParam()"
            styleClass="customers-table flex-table"
            [rowsPerPageOptions]="rowsPerPageOptions"
            [loading]="spinnerState === spinnerStates.LOADING"
            [scrollable]="true"
            [lazy]="true"
            scrollHeight="auto"
            (onLazyLoad)="loadPackagesLazy($event)"
            [paginator]="true"
            [responsive]="true"
            [rows]="pageSize"
            [totalRecords]="totalRecords"
            [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component></app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
<!--                    <th style="width: 150px;">{{"ACCOUNTING.COD_BARCODE" | translate}}</th>-->
                    <th>{{"MANAGE_SHIPMENTS.COST" | translate}}</th>
<!--                    <th *appPermission="showHubCost && permissions.includes('VIEW_HUB_COST')">{{"MANAGE_SHIPMENTS.HUB_COST" | translate}}</th>-->
                    <th style="width: 80px"> {{"MANAGE_SHIPMENTS.COD" | translate}} </th>
                    <th *ngIf="currency === 'SAR'">{{"ACCOUNTING.COD_FEES" | translate}}</th>
                    <!--                    <th> {{"ADD_SHIPMENT.INVOICE_NUMBER" | translate}} </th>-->
                    <th>{{"MANAGE_COD_CUSTOMERS.SENDER_NAME" | translate}}</th>
                    <th>{{"MANAGE_COD_CUSTOMERS.RECEIVER_NAME" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.RECEIVER_ADDRESS" | translate | addressSystem}}</th>
                    <th >{{'MANAGE_SHIPMENTS.STATUS' | translate}}</th>
                    <th class="line-break-anywhere">{{"MANAGE_COD_CUSTOMERS.NOTES" | translate}}</th>
<!--                    <th>{{'DRIVERS_EARNINGS.DRIVER_COST' | translate}}</th>-->

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr>
                    <td style="width: 150px; text-align: center;" class="cursor-pointer">
                        <div class="barcode-image-container flex-2d-center">
                            <div>
                                <img
                                        height="50px"
                                        class="barcode-image"
                                        [src]="package.barcodeImage"
                                        (click)="showPackage(package.barcode ? package.barcode : package.packageBarcode)"
                                />
                                <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode ? package.barcode : package.packageBarcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.barcode?package.barcode:package.packageBarcode)"></i>
                        </span>
                            </div>
                        </div>
                    </td>
<!--                    <td style="width: 150px; text-align: center;" class="cursor-pointer">-->
<!--                        <div class="barcode-image-container flex-2d-center">-->
<!--                            <div>-->
<!--                                <img-->
<!--                                        height="50px"-->
<!--                                        class="barcode-image"-->
<!--                                        [src]="package.codBarcodeImage"-->
<!--                                        (click)="showPackage(package.codBarcode)"-->
<!--                                />-->
<!--                                <span class="barcode-label hover-display-parent">-->
<!--                            <span>{{package.codBarcode }}</span>-->
<!--                            <i class="fas fa-copy cursor-pointer hover-display"-->
<!--                               (click)="copyText(package.codBarcode)"></i>-->
<!--                        </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </td>-->

                    <td style="width: 80px">
                        {{package.cost | number : digitFormat}}
                    </td>
<!--                    <td *ngIf="showHubCost">-->
<!--                        <span> {{(package.hubCost) | number : digitFormat}} </span>-->
<!--                    </td>-->
                    <td style="width: 80px">
                        {{package.cod | number : digitFormat}}
                    </td>
                    <td *ngIf="currency === 'SAR'">{{package.codFees}}</td>

                    <!--                    <td>{{package.invoiceNumber}}</td>-->
                    <td>{{package.senderName}}</td>
                    <td>{{package.receiverName ? package.receiverName : package.receiver}}</td>
                    <td>{{package.destination ? package.destination : package.receiverCity}}</td>
                    <td>{{package.status | translate}}</td>
                    <td>{{package.notes}}</td>
<!--                    <td>{{package.driverEarning | number: '1.0-2' }}</td>-->

                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="header-filters d-flex flex-row">
        <div class="cod-title flex-1">
            {{'SHIPMENT.NUMBER_OF_PACKAGES'|translate | packageTitle}}: {{totalRecords | number : digitFormat}}
        </div>
    </div>
</div>
<app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                [packageBarcode]="selectedPackageBarcode"
                                (closeWidget)="onHideAddPackage($event)">
</app-add-package-form-component>
