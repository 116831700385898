import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ACCOUNT_CONSTANTS} from './constants';
@Injectable({providedIn: 'root'})
export class AccountService {

    constructor(private http: HttpClient) {
    }

    public updateImage(formData) {
        const url = ACCOUNT_CONSTANTS.UPLOAD_IMG;
        return this.http.post(url, formData, {});
    }
}
