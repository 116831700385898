import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TicketingSystemService} from '../../../services/ticketing-system.service';
import {UsersService} from '../../../../users/services/users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralNoteModel} from '../../../models/general-note-model';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {NOTE_TYPES_LIST} from '../../../services/constants';

@Component({
    selector: 'app-general-note-form',
    templateUrl: 'general-note-form.component.html',
    styleUrls: ['general-note-form.component.scss']
})
export class GeneralNoteFormComponent implements OnInit {
    selectedLang;
    generalNoteForm: FormGroup;
    isSubmittingForm: boolean;
    isEditMode = false;
    generalNote: GeneralNoteModel;
    minDueDate = new Date();
    noteTypesList: { value: string, label: string }[];
    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private ticketingSystemService: TicketingSystemService,
                private translateService: TranslateService,
                private usersService: UsersService,
                private datePipe: DatePipe,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.selectedLang = this.translateService.currentLang;
        if (this.generalNote) {
            this.isEditMode = true;
        }
        this.noteTypesList = this.setNoteTypes();
        this.initGeneralNoteForm();
    }

    initGeneralNoteForm() {
        let info = {
            description: '',
            resolveDue: null,
            noteType: 'PUBLIC'
        };

        if (this.isEditMode) {
            info = <any>{
                description: this.generalNote.text,
                resolveDue: this.generalNote.dueDate ? new Date(this.generalNote.dueDate) : null,
                noteType: this.generalNote.noteType
            };
        }
        this.generalNoteForm = this.formBuilder.group({
            description: [info.description, Validators.required],
            resolveDue: [info.resolveDue],
            noteType: [info.noteType, Validators.required]
        });
    }

    editGeneralNote(body) {
        this.ticketingSystemService.editGeneralNote(body).subscribe(
            (res: any) => {
                this.isSubmittingForm = false;
                this.activeModal.close({
                    isSuccess: true,
                    message: 'TICKETING_SYSTEM.GENERAL_NOTES.ALARMS.GENERAL_NOTE_UPDATED_SUCCESSFULLY'
                });
            }, error => {
                this.activeModal.close({isSuccess: false});
            }
        );
    }

    submitForm() {
        if (this.isSubmittingForm) {
            return;
        }

        this.isSubmittingForm = true;
        if (this.generalNoteForm.invalid) {
            this.isSubmittingForm = false;
            return;
        }
        const data = this.generalNoteForm.getRawValue();

        const body = {
            text: data.description,
            dueDate: data.resolveDue ? this.transformDate(moment(data.resolveDue, 'DD/MM/yyyy').toDate()) : null,
            noteType: data.noteType
        };
        if (this.isEditMode) {
            body['id'] = this.generalNote.id;
            this.editGeneralNote(body);
            return;
        }
        this.ticketingSystemService.addGeneralNote(body).subscribe(
            (response: any) => {
                this.isSubmittingForm = false;
                this.activeModal.close({isSuccess: true, message: 'TICKETING_SYSTEM.GENERAL_NOTES.ALARMS.GENERAL_NOTE_ADDED_SUCCESSFULLY'});
            }, error => {
                console.error(error);
                this.activeModal.close({isSuccess: false});
                this.isSubmittingForm = false;
            }
        );
    }

    public onClose() {
        this.isSubmittingForm = false;
        this.activeModal.close({});
    }

    private transformDate(date, Dateformat = null) {
        const format = Dateformat ? Dateformat : 'yyyy-MM-dd';
        return this.datePipe.transform(new Date(date), format);
    }

    private setNoteTypes() {
        return NOTE_TYPES_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }
}
