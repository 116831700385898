import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubscriptionTrackerComponent} from '../../../../shared/behavior/subscription-tracker.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FulfilmentService} from '../../../../fulfilment/services/fulfilment.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {HubsService} from '../../../../shared/services/hubs.service';
import {UsersService} from '../../../../users/services/users.service';
import {MessageService} from 'primeng/api';
import {FULFILMENT_CONSTANTS} from '../../../../fulfilment/services/constants';
import {SHARED_CONSTANTS} from '../../../../shared/services/shared_constants/constants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WalletService} from '../../../services/wallet.service';
import {UserService} from '../../../../shared/services/user.service';

@Component({
    selector: 'app-add-receipt-dialog',
    templateUrl: './add-receipt-dialog.component.html',
    styleUrls: ['./add-receipt-dialog.component.scss']
})
export class AddReceiptDialogComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {
    form: FormGroup;
    currentLang = 'en';
    isLoading = false;
    paymentTypes: { label: any; value: string }[];
    storesLoading = false;
    storesSubject = new Subject<string>();
    storeOptions = [];
    data: any = {};
    isEdit = false;
    disableCustomer = false;
    receiptId;

    constructor(private fulfilmentService: FulfilmentService,
                private activeModal: NgbActiveModal,
                private translateService: TranslateService,
                private hubsService: HubsService,
                private usersService: UsersService,
                private userService: UserService,
                private walletService: WalletService,
                private messageService: MessageService,
                private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.setPaymentTypesList();
        this.initForm();

        this.subscriptions.push(this.storesSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.getStores({query: search});
            }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    setPaymentTypesList() {
        this.paymentTypes = this.userService.getCodCollectionMethods().filter(type => (type !== 'PREPAID')).map(type => {
            return {label: this.translateService.instant(`BANK_TRANSFERS.${type}`), value: type};
        });
    }

    private initForm() {
        this.form = this.formBuilder.group({
            amount: [this.data.amount, [Validators.required, Validators.min(0)]],
            customer: [this.data.customer, Validators.required],
            paymentType: [this.data.paymentType, Validators.required],
        });
    }

    submitForm() {
        this.isLoading = true;
        const formData = this.form.getRawValue();
        formData.customerId = formData.customer.id;
        console.log(formData);
        let reserveBinReq = this.walletService.createReceipt(formData);
        if(this.isEdit){
            reserveBinReq = this.walletService.updateReceipt(this.receiptId, formData);
        }
        reserveBinReq.subscribe(
            () => {
                this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                this.activeModal.close({isSuccess: true});
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    closeModal(isSuccess?) {
        if (isSuccess) {
            this.activeModal.close({isSuccess: isSuccess});
        }
        this.activeModal.close();
    }
    getStores(event?) {
        this.storesLoading = true;
        const storeQuery = this.createQuery(event ? event.query : null);
        this.usersService.getCustomers(storeQuery).subscribe(
            (response: any) => {
                this.storeOptions = response.customers;
                this.storesLoading = false;
            }, (error) => {
                console.error(error);
                this.storesLoading = false;
            }
        );
    }

    private createQuery(search?) {
        let query = '?page=1&pageSize=20&status=VERIFIED';
        if (search) {
            query += '&search=' + search;
        }
        return query;
    }

    onStoreChanged() {

    }

    preventWheelDefault($event: Event) {
        try {
            $event.preventDefault();
        } catch (e) {

        }
    }
}
