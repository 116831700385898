import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {SpinnerState} from '../shared/behavior/spinner-state.enum';
import {UserService} from '../shared/services/user.service';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ShipmentsService} from '../shipment/services/shipments.service';
import {GeographyService} from '../shared/services/geography.service';
import {UtilitiesService} from '../shared/services/utilities.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../shared/services/authentication.service';
import {Router} from '@angular/router';
import {UsersService} from '../users/services/users.service';
import {HubsService} from '../shared/services/hubs.service';
import {ParcelService} from '../administration/services/parcel.service';
import {DriversService} from '../shared/services/drivers.service';
import {DatePipe} from '@angular/common';
import {NewUserComponent} from '../users/create-new-user/new-user.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ServiceTypesService} from '../administration/services/service-types.service';
import {Address} from '../shared/models/address.class';
import {FileUploaderComponent} from '../shared/components/file-uploader/file-uploader.component';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {UserModel} from '../shared/models/user.model';
import {AddLclItemsComponent} from './add-lcl-items/add-lcl-items.component';
import {LOCALE_CONSTANTS} from '../shared/services/locale_constants/constants';
import {MapComponent} from '../shared/components/map/map.component';
import {ZonesService} from '../administration/services/zones.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {PackageContentModel} from '../distributor-package-form/models/package-content.model';
import {PackageTitlePipe} from '../shared/pipes/package-title.pipe';
import {PhoneNumberInputComponent} from '../shared/components/phone-number-input/phone-number-input.component';
import {UserStatus} from '../shared/enums/UserStatus';
import {HubModel} from '../shared/models/hub.model';
import {ChooseMapComponent} from '../administration/choose-map/choose-map.component';
import {CustomizationCompanyService} from '../shared/services/customization-company.service';
import {Companies} from '../../customCompanies/companies.pal-ship';
import {FieldType, FieldValidation} from '../shared/components/form-components/field-input/field-input.component';
import {CreateNewUserCustom1Component} from '../users/create-new-user-custom1/create-new-user-custom1.component';

declare let L;
function replaceAll(inputString, search, replacement) {
    const regex = new RegExp(search, 'g');
    return inputString.replace(regex, replacement);
}
@Component({
    selector: 'app-add-package-form-component',
    templateUrl: './add-package-form.component.html',
    styleUrls: ['./add-package-form.component.scss']
})
export class AddPackageFormComponent extends Address implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    companies = Companies;

    @Input() display: boolean;
    @Input() originPkg = {};
    @Input() packageBarcode: number;
    @Output() closeWidget = new EventEmitter();
    @Output() showAWBDialogEvent = new EventEmitter();
    @Output() showPackageDetailsEvent = new EventEmitter();
    @ViewChild('receiverAddressBook') receiverAddressBook;
    @ViewChild('AddPackageSideBar') addPackageSideBar;
    @ViewChild('serviceTypeAC') serviceTypeAC;
    @ViewChild('vehicleTypeAC') vehicleTypeAC;
    @ViewChild('attachmentsUploader') attachmentsUploader: FileUploaderComponent;
    @ViewChild('phoneNumberInputComponent') phoneNumberInputComponent: PhoneNumberInputComponent;

    public subscriptions: Subscription[] = [];
    public isLoading: SpinnerState;
    public spinnerStates = SpinnerState;
    public companyInfo: UserModel;
    public companyId: number;
    public showExtraPhoneField = false;
    public isLoadingReceiverAddressBook: SpinnerState = SpinnerState.LOADING;
    public receiverAddresses: any[];
    public selectedServiceType: any;
    public selectedVehicleType: any;
    public selectedReceiverAddress: any;
    public isArabic = false;
    public tempSelectedReceiverAddressIndex = -1;
    public isReceiverHasBusiness = false;
    public selectedTab = 'MAIN_TAB';
    public addPackageForm: FormGroup;
    public editedPackageId: number;
    public parcelTypeId: number;
    public isEditMode: boolean;
    public isSubmittingPackage: boolean;
    public isSubmittingSenderAddress: boolean;
    public isRemovingSenderAddress: boolean;
    public isEditAddressBook: boolean;
    public isReceivingPointEnabled: boolean;
    public isLcl: boolean;
    public isPricingPerServiceTypeEnabled: boolean;
    public hasRouteOptimization: boolean;
    public addExtraPackages: boolean;
    public editAddressId: number;
    public addressType: string;
    declare public villageOptions: { value: string, label: string }[];
    public addressOptions: { value: string, label: string }[];
    public locationOptions: { value: any, label: string }[];
    declare public originVillageOptions;
    public storesList = [];
    public serviceTypesAcList = [];
    public vehicleTypesList = [];
    public shipmentTypesOptions: { value: string, label: string }[];
    public codCollectionMethods: { value: string, label: string }[];
    public currencies: { value: string, label: string }[];
    public shipmentServicesOptions: { value: string, label: string }[];
    public packagesIds = [];
    public geocodeSuggest;
    public geocode;
    public geocodeBounds;
    public preventSubmitWhenPressEnter = false;
    public swapOrBring = 'COLLECT';
    public swapSenderAndReceiver = false;
    public deliveryPrice = -1;
    public editSenderAddress = false;
    public editServiceType = false;
    public editVehicleType = false;
    public showVehicleTypesList = false;
    public packageLocations = {
        HUB: 'FROM_HUB',
        DRIVER: 'WITH_DRIVER',
        CUSTOMER: 'CUSTOMER_ADDRESS',
    };
    public directPrint;
    public onlySave;
    public saveAndContinue;
    public hasSavedPackages = false;
    public hasAdditionalAddressDetails = false;

    public defaultHub: any = '';
    public hubsOptions: { value: string, label: string }[];
    public driversOptions: { value: string, label: string }[];
    public packageLocation = this.packageLocations.HUB;
    public selectedHub;
    public selectedDriverId;
    public parcelsOptions;

    public senderInfoLcl;
    public digitFormat = '1.0-2';

    public isTrucking = false;
    public receiverLocationForm: FormGroup;

    receiverLoading = false;
    receiverSubject = new Subject<string>();
    public packageContents: PackageContentModel[] = [];
    public selectedPackageContents: PackageContentModel[] = [];
    showCodFees = false;
    companiesWithOutCodCollectionMethod = [209]; // sare3 company (209)
    companiesWithDeliveryAndPickupTime = [209]; // sare3 company (209)
    companiesWithCooling = [223, 1]; // Mashaweer company (223)
    companiesWithMeasurement = [223, 1]; // Mashaweer company (223)
    companiesWithPreview = [223, 1]; // Mashaweer company (223)
    companiesWithStations = [256, 22]; // SAR for Production and mohanad for testing
    isHubUserRole = false;

    optionalToPayAndToReceiveInputs = [194];
    isHideDetailInAddingPackage = false;
    @Input() isCloning = false;
    isLoadingHubs = false;
    fromCityList: { value: string, label: string}[] = [];
    toCityList: { value: string, label: string, address: {}}[] = [];
    tripNumbersList = [];
    shipmentType: string;
    chooseMaps = false;
    isDeliverToReceivingPoint = false;
    files = [];
    numberOfFiles = 0;
    @ViewChild('fileChooser') fileChooser;
    @ViewChild('pc') pc;
    public cannotAddFiles: string[];
    public calculateCostPerWeight = false;
    public jettShipmentType: { value: string, label: string }[] = [];
    public fieldValidation = FieldValidation;
    public FieldType = FieldType;
    isSupportAdditionalFees: any;
    insuranceValue = 0;
    codValue = 0;
    public isSupportDeliveringPackageItemsPartially;
    public isSupportVillageHub;
    villageHub;

    constructor(
        private geographyService: GeographyService,
        private usersService: UsersService,
        private userService: UserService,
        private utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private shipmentService: ShipmentsService,
        private serviceTypesService: ServiceTypesService,
        private parcelService: ParcelService,
        private messageService: MessageService,
        private auth: AuthenticationService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private hubsService: HubsService,
        private driversService: DriversService,
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private zonesService: ZonesService,
        private packageTitlePipe: PackageTitlePipe,
        private translateService: TranslateService,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        public customizationCompaniesService: CustomizationCompanyService
    ) {
        super(zonesService, translate, true);
        this.allVillages = true;
        // super(geographyService, translate);
        this.cannotAddFiles = [
            this.translateService.instant('ALERTS.NUMBER_OF_FILES_EXCEED'),
            this.translateService.instant('ALERTS.MAXIMUM_NUMBER_OF_FILES') + ': ' + (5 - this.numberOfFiles)
        ];
    }

    ngOnInit() {
        this.isHubUserRole = this.userService.isHubUserRole;
        this.companyInfo = this.userService.userInfo;
        this.calculateCostPerWeight = this.companyInfo.isEnablePricingPerkg;
        this.chooseMaps = this.companyInfo.what3wordsKey !== null && this.companyInfo.what3wordsKey !== undefined;
        this.companyInfo.address = {};
        this.companyInfo.name = this.auth._companyName;
        this.companyId = this.auth.companyId;
        this.isArabic = this.translate.currentLang === 'ar';
        this.isReceivingPointEnabled = this.userService.userInfo.isReceivingPointEnabled;
        this.isSupportDeliveringPackageItemsPartially = this.userService.userInfo.isSupportDeliveringPackageItemsPartially;
        this.isSupportVillageHub = this.userService.userInfo.isSupportVillageHub;

        this.isSupportAdditionalFees = this.userService.userInfo.isSupportAdditionalFees;
        this.isLcl = this.userService.userInfo.isLcl;
        this.isTrucking = this.userService.userInfo.isTrucking;
        this.isHideDetailInAddingPackage = this.userService.userInfo.isHideDetailInAddingPackage;
        this.isPricingPerServiceTypeEnabled = this.userService.userInfo.isPricingPerServiceTypeEnabled;
        this.hasRouteOptimization = this.userService.userInfo.hasRouteOptimization;
        this.hasAdditionalAddressDetails = this.userService.hasAdditionalAddressDetails();
        const currency = this.userService.getCurrency();
        this.addressType = LOCALE_CONSTANTS.ADDRESS_TYPES[currency]
            ? LOCALE_CONSTANTS.ADDRESS_TYPES[currency]
            : LOCALE_CONSTANTS.ADDRESS_TYPES.DEFAULT;
        this.initAddPackageForm();
        this.createReceiverLocationForm();
        this.initLists();
        this.watchPaymentTypeValue();
        this.init();
        this.showCodFees = !this.isLcl && !this.isTrucking && currency === 'SAR' && !this.isSupportAdditionalFees;
    }

    ngOnChanges() {
        if (this.display && this.addPackageForm) {
            this.initializeFormWithPreselectedData();
        }
        this.getDeliveryPrice();
    }

    ngAfterViewInit() {
        this.initGeocoding();
        this.addConfirmationBeforeClose();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            if (subscription instanceof Subscription) {
                subscription.unsubscribe();
            }
        });
    }

    private getJettShipmentType () {
        this.jettShipmentType = SHARED_CONSTANTS.SHIPMENT_TYPES.JETT_SHIPMENT_TYPES.map(type =>  {
            return {
                label: this.translateService.instant(`JETT.${type}`),
                value: type
            };
        });
    }

    private getPackageSignature(id) {
        this.shipmentService.getPackageSigniture(id).subscribe(
            (response: any) => {
            if (response !== null) {
                this.numberOfFiles = response.deliveryProofUrlList ? response.deliveryProofUrlList.length : 0;
                this.cannotAddFiles[1] = this.translateService.instant('ALERTS.MAXIMUM_NUMBER_OF_FILES') + ': ' + (5 - this.numberOfFiles);
            }
        }, error => {
                console.error(error);
        });
    }

    private initLists() {
        this.getToHubsList();
        this.getFromHubsList();
        if (this.customizationCompaniesService.checkCompanies(this.companies.JETT)) {
            this.getJettShipmentType();
        }
    }

    getFromHubsList(event?) {
        let query = '?page=1&pageSize=20';
        if (!this.companyInfo.isAllowAddingPackagesOnAllHub) {
            query += '&is-adding-package=true';
        }
        const search = event?.query;
        this.hubsService.getHubs(query, search).subscribe(
            (response: any) => {
                this.fromCityList  = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }, (error) => {
            }
        );
    }

    getToHubsList(event?) {
        let query = '?page=1&pageSize=20';
        if (!this.companyInfo.isAllowAddingPackagesOnAllHub) {
            query += '&is-adding-package=true';
        }
        if (this.isJettCompany() && this.addPackageForm?.controls.fromHub.value) {
            query += '&fromHub=' + this.addPackageForm.controls.fromHub.value;
        }
        const search = event?.query;
        this.hubsService.getHubs(query, search, false, this.isJettCompany()).subscribe(
            (response: any) => {
                this.toCityList = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id, address: hub.address};
                    }
                );
            }, (error) => {
                console.error(error);
            }
        );
    }

    private initGeocoding() {
        this.geocodeSuggest = L.esri.Geocoding.suggest();
        this.geocodeSuggest.apikey(SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY);
        this.geocodeBounds = this.userService.getCountryBoundaries();
        if (this.geocodeBounds) {
            this.geocodeSuggest.within(this.geocodeBounds);
        }

        this.geocode = L.esri.Geocoding.geocode({
            apikey: SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY
        });
    }

    public init() {
        this.isLoading = SpinnerState.LOADING;
        let query = '?page=1&pageSize=20';
        if (!this.companyInfo.isAllowAddingPackagesOnAllHub) {
            query += '&is-adding-package=true';
        }
        const promises = [
            this.parcelService.getParcels(),
            this.hubsService.getHubs(query, null, true)
        ];

        if (this.packageBarcode) {
            this.isEditMode = true;
            promises.push(this.shipmentService.getPackageHistoryByBarcode(this.packageBarcode));
        }

        if (this.isLcl) {
            promises.push(this.userService.getCompanyAddress());
        }

        forkJoin(promises).subscribe(
            (response: any) => {
                const parcelsList = response[0].data;
                const hubsList = response[1].hubs;

                if (this.isLcl) {
                    this.companyInfo.address = response[promises.length - 1];
                    this.fillLclSenderInfo();
                }

                this.initDefaultShipmentServiceType();


                this.initDefaultShipmentType();


                        this.codCollectionMethods = this.userService.getCodCollectionMethods().map(type => {
                            return {
                                label: this.translate.instant(`COD_COLLECTION_METHODS_LIST.${type}`),
                                value: type
                            };
                        });

                if (hubsList.length === 1) {
                    response[1].defaultHub = hubsList[0];
                }
                this.hubsOptions = hubsList.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
                if (response[1].defaultHub && this.packageLocation === this.packageLocations.HUB) {
                    this.defaultHub = {label: response[1].defaultHub.name, value: response[1].defaultHub.id};
                    this.selectedHub = this.defaultHub;
                    this.updateFormValue('hubId', this.defaultHub);
                }

                this.parcelsOptions = parcelsList.map((parcel) => {
                    return {
                        ...parcel,
                        label: this.translate.currentLang === 'ar' ? parcel.arabicType : parcel.type,
                        value: parcel.id
                    };
                });

                if (this.isEditMode) {
                    this.addPackageForm.controls['freight'].enable();
                    const packageDetails = response[2].pkg;
                    this.editedPackageId = packageDetails.id;
                    if (this.isCloning) {
                        this.packageLocation = null;
                        this.changePackageLocation(this.packageLocations.CUSTOMER);
                        this.packageBarcode = null;
                    } else {
                        this.getPackageSignature(this.editedPackageId);
                    }
                    this.preFillData(packageDetails);
                    if (packageDetails.receiverPhone2) {
                        this.showExtraPhoneField = true;
                    }
                } else {
                    this.initializeFormWithPreselectedData();
                }
                this.companyId = this.auth.companyId;
                this.isLoading = SpinnerState.LOADED;
            }, (error) => {
                console.error(error);
                this.isLoading = SpinnerState.FAILED;
            }
        );

        this.subscriptions.push(this.receiverSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.getStores({query: search});
            }));
    }

    public initializeFormWithPreselectedData() {
        this.addPackageForm.patchValue({
            paymentType: this.isTrucking ? 'BRING' : 'COD',
            service: this.isLcl ? 'AIR' : 'STANDARD',
            quantity: 1
        });
        if (this.companiesWithOutCodCollectionMethod.indexOf(this.companyId) === -1) {
            this.addPackageForm.get('codCollectionMethod').patchValue('CASH');
        }

        if (this.isJettCompany() && this.addPackageForm.get('orderId').value === 'STORE_TO_STORE') {
            this.addPackageForm.get('paymentType').setValue(this.shipmentTypesOptions[0].value);
            this.addPackageForm.updateValueAndValidity();
        }
        this.swapSenderAndReceiver = this.isTrucking;
        this.onServiceTypeChange({value: 'STANDARD'});
    }


    @HostListener('document:keydown.escape', ['$event']) handleEscButton(event: KeyboardEvent) {
        if (this.addPackageSideBar.visible) {
            this.addPackageSideBar.close(event);
        }
    }

    @HostListener('document:keydown.enter', ['$event']) handleEnterButton() {
        if (this.addPackageSideBar.visible && !this.preventSubmitWhenPressEnter && !this.isLcl) {
            this.submitForm();
        }
    }

    public showAddCustomer() {
        return this.userService.userInfo.isAddCustomerFromQuickAddShipmentForm &&
            (this.isJettCompany() && this.userService.userInfo.role === 'CLERK' || this.userService.userInfo.role !== 'CLERK') &&
            this.userService.userInfo.role !== 'HUB_CLERK' &&
            this.userService.userInfo.role !== 'CUSTOMER_ACCOUNT_MANAGER';
    }
    public allowAddingPackageWithFingerprint() {
        return this.userService.userInfo.isPackageFingerprintRequired;
    }
    public addCustomer() {
        let errorMessage = '';
        this.translate.get(
            ['ALERTS.ADD_CUSTOMER_FAILED']
        ).subscribe(
            (data) => {
                errorMessage = data['ALERTS.ADD_CUSTOMER_FAILED'];
                let modal;
                if (this.customizationCompaniesService.checkCompanies(this.companies.JETT) ) {
                    modal = this.modalService.open(CreateNewUserCustom1Component, <any>{
                        backdrop: 'static',
                        windowClass: 'create-new-user',
                        size: 'md'
                    });
                } else {
                    modal = this.modalService.open(
                        NewUserComponent,
                        <any>{
                            backdrop: 'static',
                            windowClass: 'create-new-user',
                            size: 'md'
                        }
                    );
                }
                modal.componentInstance.type = 'customer';
                modal.result.then(
                    (status) => {
                        if (status.isSuccess) {
                            this.addPackageForm.get('customerInfo').patchValue(status.customer);
                        } else {// error adding customer
                            this.confirmationService.confirm({
                                message: errorMessage + ': ' + status.error,
                                rejectVisible: false,
                                acceptLabel: 'OK'
                            });
                        }
                    });
            }
        );
    }

    // override close method to allow showing confirmation popup
    public addConfirmationBeforeClose() {
        this.addPackageSideBar.close = (event) => {
            this.addPackageSideBar.preventVisibleChangePropagation = true;
            event.preventDefault();
            return this.confirmationService.confirm({
                message: this.translate.instant('ACTIONS.CONFIRM_CANCEL'),
                acceptLabel: this.translate.instant('GENERAL.YES'),
                rejectLabel: this.translate.instant('GENERAL.NO'),
                accept: () => {
                    this.addPackageSideBar.hide();
                    this.addPackageSideBar.visibleChange.emit(false);
                }
            });
        };
    }

    public onClose() {
        this.closeWidget.emit(false);
        if (this.hasSavedPackages) {
            this.shipmentService.shipmentAdded();
            this.hasSavedPackages = false;
        }
        this.clearFormData();
        if (this.companyId === 64) {
            this.selectSavingOption('onlySave');
        }
        if (this.saveAndContinue) {
            // this.showAWBDialogEvent.emit(JSON.parse(JSON.stringify(this.packagesIds)));
        } else if (this.directPrint) {
            const id = this.packagesIds[0];
            this.printReport(id);
        }

        this.villageHub = null;
        this.packagesIds = [];
        this.deliveryPrice = -1;
        this.isSubmittingPackage = false;
    }

    public clearFormData() {
        this.addPackageForm.reset();
        this.phoneNumberInputComponent.clear();
        if (this.isLcl) {
            this.attachmentsUploader.clearUploader();
        }
        this.packageBarcode = null;
    }

    public toggleExtraPhone() {
        this.showExtraPhoneField = !this.showExtraPhoneField;
        if (!this.showExtraPhoneField) {
            this.updateFormValue('receiverPhone2', '');
        }
    }

    public increasePackageCount(inputName) {
        this.addPackageForm.patchValue({
            [inputName]: this.addPackageForm.controls[inputName].value + 1
        });
    }


    public decreasePackageCount(inputName) {
        if (this.addPackageForm.controls[inputName].value <= 1) {
            this.addPackageForm.patchValue({
                [inputName]: 1
            });
        } else {
            this.addPackageForm.patchValue({
                [inputName]: this.addPackageForm.controls[inputName].value - 1
            });
        }
    }

    public printReport(pkgId) {
        if (!pkgId) {
            return;
        }

        this.shipmentService.downloadMultiple([pkgId]).subscribe((res: any) => {
            this.shipmentService.getPackagePDFBlob(res.url)
                .subscribe(
                    (response) => { // download file
                        const blobUrl = URL.createObjectURL(response);
                        const iframe = document.createElement('iframe');
                        iframe.style.display = 'none';
                        iframe.src = blobUrl;
                        document.body.appendChild(iframe);
                        iframe.contentWindow.print();
                    });
        },(error) => {
                console.log(error);
        });
    }


    public getCreatedDate() {
        if (this.companyId === 64 && !this.isEditMode) {
            return new Date();
        } else {
            return null;
        }
    }


    isReceiverNameNotRequired() {
        const notRequiredForCompanies = [221]; // 221 alhadaf company
        return notRequiredForCompanies.indexOf(this.companyId) !== -1;
    }
    // Package form
    public initAddPackageForm() {
        this.addPackageForm = this.formBuilder.group({
            // Receiver

            receiverName: [this.getInitReceiverName(), Validators.required], // done
            receiverPhone: [this.getInitReceiverPhoneNumber(), [Validators.required,
                this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144)),
                Validators.maxLength(14),
                Validators.minLength(8)]],
            receiverPhone2: ['', [this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144)),
                Validators.maxLength(14),
                Validators.minLength(8)]],
            receiverAddress: ['', Validators.required],
            receiverAddressLine1: [this.getInitReceiverAddressLine1(), Validators.required],
            receiverLocation: [''],
            receiverAddressWords: [''],
            building: [''],
            flat: [''],
            toPayToReceiver: [0],
            toCollectFromReceiver: [0],
            receiverBusinessName: [''],
            receiverAuthorizedGovRegistrationNumber: [''],
            swapOrBring: ['COLLECT'],
            paymentType: ['', Validators.required],
            cod: [{value: '', disabled: this.isJettCompany()}],
            isReceiverPayCost: [''],
            isSenderPayCost: [''],
            service: [''],
            vehicleType: [''],
            serviceType: [''],
            invoiceNumber: ['', this.userService.userInfo.isForceAddingInvoiceNumberWhenAddingPackages ? Validators.required :  []],
            isDeliverToReceivingPoint: [false],
            isInsured: [false],

            // packageDetailsList: this.formBuilder.array([]),

            notes: ['', [Validators.maxLength(300)]],
            description: ['', this.userService.userInfo.isDescriptionMandatoryInAddPackageForm && !this.userService.userInfo.isSupportDeliveringPackageItemsPartially ? [Validators.required, Validators.maxLength(200)] : [Validators.maxLength(200)]],
            weight: ['', Validators.min(0)],
            adminNotes: [''],
            isFragile: [''],
            isflammable: [''],
            isNeedPackaging: [''],
            isFingerprintRequired: [''],
            addExtraPackages: [''],
            isPreventMeasuring: [''],


            //////// Should be kept here //////////
            customerInfo: ['', Validators.required],
            hubId: [this.isHubUserRole && this.userService.userInfo.hubId ? this.userService.userInfo.hubId : '', Validators.required],
            driverId: [''],
            quantity: [1],
            freight: [{value: '', disabled: (this.userService.userInfo.isDisableCostInAddPackage && !this.isEditMode) || this.isJettCompany()}],
            parcelId: [],
            expectedDeliveryDate: [null],
            expectedPickupDate: [null],
            createdDate: [this.getCreatedDate()],
            directPrint: [!this.isLcl && !(this.companyId === 64)],
            onlySave: [this.isLcl || this.companyId === 64],
            saveAndContinue: [false],
            codFees: [''],
            isDestructible: [false],
            isPreventOpen: [false],
        });

        if (this.calculateCostPerWeight) {
            this.addPackageForm.get('weight').setValidators(Validators.required);
        }
        if (this.companiesWithOutCodCollectionMethod.indexOf(this.companyId) === -1) {
            this.addPackageForm.addControl('codCollectionMethod', this.formBuilder.control([], [Validators.required]));
        }
        if (this.isPricingPerServiceTypeEnabled) {
            this.addPackageForm.get('serviceType').setValidators([Validators.required]);
        }
        if (this.isTrucking || this.isLcl || this.userService.userInfo.role === 'SUPER_ADMIN') {
            this.addPackageForm.get('hubId').clearValidators();
        }
        if (this.isReceiverNameNotRequired()) {
            this.addPackageForm.get('receiverName').clearValidators();
            this.addPackageForm.get('receiverName').updateValueAndValidity();
        }
        if (this.isTrucking) {
            this.addPackageForm.addControl('packageContents', this.formBuilder.control([]));
        }
        if (this.isLcl) {
            this.addPackageForm.addControl('packageItems', this.formBuilder.control({}));
            this.addPackageForm.addControl('currency', this.formBuilder.control(this.userService.getCurrency(), [Validators.required]));
            this.initCurrenciesDropdownList();
        }

        if (this.isJettCompany()) {
            this.addPackageForm.addControl('isSenderPayCost', this.formBuilder.control(false));
            this.addPackageForm.addControl('orderId', this.formBuilder.control('STORE_TO_STORE', [Validators.required]));
            this.addPackageForm.addControl('fromHub', this.formBuilder.control(this.userService.userInfo.hubId, [Validators.required]));
            this.addPackageForm.addControl('toHub', this.formBuilder.control(null, [Validators.required]));
            this.addPackageForm.addControl('width', this.formBuilder.control(null, [Validators.required]));
            this.addPackageForm.addControl('length', this.formBuilder.control(null, [Validators.required]));
            this.addPackageForm.addControl('height', this.formBuilder.control(null, [Validators.required]));

            if (this.addPackageForm.controls.orderId.value === 'STORE_TO_STORE') {
                this.addPackageForm.get('isSenderPayCost').setValue(true);
            }
        }

        if (this.companiesWithCooling.indexOf(this.companyId) !== -1) {
            this.addPackageForm.addControl('isNeedCooling', this.formBuilder.control(false));
        }
        if (this.companiesWithMeasurement.indexOf(this.companyId) !== -1) {
            this.addPackageForm.addControl('isNeedMeasurement', this.formBuilder.control(false));
        }
        if (this.companiesWithPreview.indexOf(this.companyId) !== -1) {
            this.addPackageForm.addControl('isNeedPreview', this.formBuilder.control(false));
        }
        if (this.companiesWithStations.indexOf(this.companyId) !== -1) {
            this.addPackageForm.addControl('fromHub', this.formBuilder.control(''));
            this.addPackageForm.get('fromHub').setValidators(Validators.required);
            this.addPackageForm.addControl('toHub', this.formBuilder.control(''));
            this.addPackageForm.get('toHub').setValidators(Validators.required);
            this.addPackageForm.addControl('trip', this.formBuilder.control(''));
            this.addPackageForm.get('expectedPickupDate').setValidators(Validators.required);
        }

        this.subscriptions.push(this.addPackageForm.get('customerInfo').valueChanges.subscribe(() => {
                this.chooseStore();
            }));
    }

    private preFillData(data) {
        // preFill package form
        let senderAddress = 'originAddress';
        let receiverAddress = 'destinationAddress';
        if (!this.isLcl && (data.shipmentType === 'BRING')) {
            this.swapSenderAndReceiver = true;
            senderAddress = 'destinationAddress';
            receiverAddress = 'originAddress';
        }

        if (data.shipmentType === 'BRING' || data.shipmentType === 'SWAP') {
            if (+data.toPayToReceiver > 0) {
                this.swapOrBring = 'PAY';
            } else if (+data.toCollectFromReceiver > 0) {
                this.swapOrBring = 'COLLECT';
            }
            this.changeSwapOrBringInput();
        }

        this.handleServiceAndShipmentTypePreFill(data.customerId);
        this.insuranceValue = data.insuranceFee;
        this.codValue = data.additionalCodFee;
        this.addPackageForm.patchValue({
            customerInfo: {
                id: data.customerId,
                fullName: this.swapSenderAndReceiver ? data.receiverName : data.fullSenderName || data.customerName,
                phone: this.swapSenderAndReceiver ? data.receiverPhone : data.senderPhone,
                phone2: this.swapSenderAndReceiver ? data.receiverPhone2 : data.senderPhone2,
                email: data.senderEmail,
                businessName: this.swapSenderAndReceiver ? data.businessReceiverName : data.businessSenderName,
                authorizedGovRegistrationNumber: this.swapSenderAndReceiver ? data.receiverAuthorizedGovRegistrationNumber : data.senderAuthorizedGovRegistrationNumber,

                address: {
                    value: data[senderAddress].villageId,
                    villageId: data[senderAddress].villageId,
                    label: `${data[senderAddress].village} - ${data[senderAddress].city} - ${data[senderAddress].region}`,
                    region: data[senderAddress].region,
                    village: data[senderAddress].village,
                    city: data[senderAddress].city,
                    cityId: data[senderAddress].cityId,
                    regionId: data[senderAddress].regionId,
                    country: data[senderAddress].country,
                    addressLine1: data[senderAddress].addressLine1,
                    addressLine2: data[senderAddress].addressLine2
                },
            },
            receiverName: this.swapSenderAndReceiver ? `${data.senderFirstName} ${data.senderLastName}` : `${data.receiverFirstName} ${data.receiverLastName}`,
            receiverPhone: this.swapSenderAndReceiver ? data.senderPhone : data.receiverPhone,
            receiverPhone2: this.swapSenderAndReceiver ? data.senderPhone2 : data.receiverPhone2,
            receiverAddress: {
                value: data[receiverAddress].villageId,
                id: data[receiverAddress].villageId,
                label: `${data[receiverAddress].village} - ${data[receiverAddress].city} - ${data[receiverAddress].region}`,
                cityId: data[receiverAddress].cityId,
                regionId: data[receiverAddress].regionId,
                country: data[receiverAddress].country
            },
            receiverAddressLine1: data[receiverAddress].latitude && data[receiverAddress].longitude && this.hasRouteOptimization ? {
                label: data[receiverAddress].addressLine1,
                value: {
                    latlng: {
                        lat: data[receiverAddress].latitude,
                        lng: data[receiverAddress].longitude
                    }
                }
            } : this.hasRouteOptimization ? {label: data[receiverAddress].addressLine1} : data[receiverAddress].addressLine1,
            receiverLocation: data[receiverAddress].latitude && data[receiverAddress].longitude ? {
                value: {
                    latlng: {
                        lat: data[receiverAddress].latitude,
                        lng: data[receiverAddress].longitude
                    }
                }
            } : null,
            building: data[receiverAddress].buildingNo,
            flat: data[receiverAddress].floorNo,
            receiverBusinessName: this.swapSenderAndReceiver
                ? data.businessSenderName
                : data.businessReceiverName,
            receiverAuthorizedGovRegistrationNumber: this.swapSenderAndReceiver
                ? data.senderAuthorizedGovRegistrationNumber
                : data.receiverAuthorizedGovRegistrationNumber,

            paymentType: this.isJettCompany() && data.isSenderPayCost && data.shipmentType === 'COD'? 'COD_V2' : data.shipmentType,
            codCollectionMethod: data.paymentType,
            cod: data.cod,
            service: data.serviceType,
            invoiceNumber: data.invoiceNumber,
            isReceiverPayCost: data.isReceiverPayCost,
            isSenderPayCost: data.isSenderPayCost,
            isDeliverToReceivingPoint: data.isDeliverToReceivingPoint,
            isInsured: data.isInsured,

            quantity: data.quantity,

            notes: data.notes,
            weight: data.weight,
            description: data.description,
            adminNotes: data.adminNotes,
            isFragile: data.isFragile,
            isflammable: data.isflammable,
            isNeedPackaging: data.isNeedPackaging,
            isNeedCooling: data.isNeedCooling,
            isNeedMeasurement: data.isNeedMeasurement,
            isNeedPreview: data.isNeedPreview,
            isDestructible: data.isDestructible,
            isPreventOpen: data.isPreventOpen,
            isPreventMeasuring: data.isPreventMeasuring,
            isFingerprintRequired: data.isFingerprintRequired,
            hubId: data.hubId ? {label : data.hubName , value: data.hubId} : data.hubId,
            freight: data.cost,
            createdDate: data.createdDate,
            expectedDeliveryDate: data.expectedDeliveryDate ? new Date(data.expectedDeliveryDate) : null,
            expectedPickupDate: data.expectedPickupDate ? new Date(data.expectedPickupDate) : null,
            serviceType: data.serviceTypeName,
            vehicleType: data.vehicleTypeName,
            packageItems: data.packageItems,
            currency: data.currency,
            codFees: data.codFees,
            parcelId: data.parcelTypeId,
            trip: !this.isCloning ? data.trip : null,

            toPayToReceiver: +data.toPayToReceiver,
            toCollectFromReceiver: +data.toCollectFromReceiver
        });
        // prefill Sender Info
        this.shipmentType = data.shipmentType;
        this.parcelTypeId = data.parcelTypeId;
        this.selectedServiceType = {};

        this.selectedReceiverAddress = {
            addressLine1: data[receiverAddress].addressLine1,
            arabicCityName: data[receiverAddress].arabicCityName,
            arabicVillageName: data[receiverAddress].arabicVillageName,
            buildingNo: data[receiverAddress].buildingNo,
            city: data[receiverAddress].city,
            cityId: data[receiverAddress].cityId,
            floorNo: data[receiverAddress].floorNo,
            latitude: data[receiverAddress].latitude,
            locationAccuracy: data[receiverAddress].locationAccuracy,
            longitude: data[receiverAddress].longitude,
            region: data[receiverAddress].region,
            regionId: data[receiverAddress].regionId,
            village: data[receiverAddress].village,
            villageId: data[receiverAddress].villageId
        };

        this.handleVillageChanged({id: data[receiverAddress].villageId});
        if (this.isLcl) {
            this.fillLclReceiverInfo(data);
            if (this.addPackageForm.get('paymentType').value === 'REGULAR') {
                this.addPackageForm.get('paymentType').patchValue('NON_COD');
            }
        }

        if (this.companiesWithStations.indexOf(this.companyId) !== -1) {
            this.addPackageForm.get('fromHub').setValue({label: data.fromHubName, value: data.fromHub});
            this.addPackageForm.get('toHub').setValue({label: data.toHubName, value: data.toHub});
        }
        if (this.isTrucking) {
            this.addPackageForm.get('packageContents').patchValue(data.packageContents);
            if (data.packageContents) {
                this.selectedPackageContents = data.packageContents;
            }
        }

        if (this.isSupportDeliveringPackageItemsPartially) {
            this.selectedPackageContents = data.packageItemsToDeliverList;
        }
        if (this.isJettCompany()) {
            this.addPackageForm.get('fromHub').setValue(data.fromHub);
            this.addPackageForm.get('isSenderPayCost').setValue(data.isSenderPayCost);
            this.addPackageForm.get('toHub').setValue(data.toHub);
            if (data.orderId === 'STORE_TO_DOOR_B') {
                this.addPackageForm.get('toHub').removeValidators(Validators.required);
                this.addPackageForm.removeControl('toHub');
            } else if (data.orderId === 'OUTBOUND') {
                this.addPackageForm.get('toHub').removeValidators(Validators.required);
            } else {
                this.addPackageForm.get('toHub').setValidators(Validators.required);
            }

            this.addPackageForm.get('orderId').setValue(data.orderId);
            this.addPackageForm.get('length').setValue(data.length);
            this.addPackageForm.get('height').setValue(data.height);
            this.addPackageForm.get('width').setValue(data.width);
            this.addPackageForm.updateValueAndValidity();
        }

        if (data.shipmentType === 'BRING' || data.shipmentType === 'SWAP') {
            if (this.swapOrBring === 'PAY') {
                this.addPackageForm.get('toPayToReceiver').patchValue(+data.toPayToReceiver);
            } else if (this.swapOrBring === 'COLLECT') {
                this.addPackageForm.get('toCollectFromReceiver').patchValue(+data.toCollectFromReceiver);
            }
        }

        if (this.userService.userInfo.isPricingPerServiceTypeEnabled) {
            if (data.serviceTypeId && data.serviceTypeName) {
                this.selectedServiceType = {
                    name: data.serviceTypeName,
                    id: data.serviceTypeId
                };
                this.chooseServiceType(this.selectedServiceType, false);
            }
            if (data.vehicleTypeName && data.vehicleTypeId) {
                this.selectedVehicleType = {
                    name: data.vehicleTypeName,
                    id: data.vehicleTypeId
                };
                this.chooseVehicleType(this.selectedVehicleType);
            }
        }

        if (this.companiesWithStations.indexOf(this.companyId) !== -1) {
            this.getSuggestionTripNumber();
        }
    }


    private watchPaymentTypeValue() {
        this.addPackageForm.get('paymentType').valueChanges
            .subscribe(value => {
                if (value === 'COD' && !this.isLcl) {
                    this.addPackageForm.get('cod').setValidators([Validators.required]);
                    if (this.customizationCompaniesService.checkCompanies(this.companies.SEND, this.companies.MOHANAD)) {
                        this.addPackageForm.get('cod').addValidators([Validators.min(1)]);
                    }
                } else {
                    this.addPackageForm.get('cod').clearValidators();
                }
                if (value === 'SWAP' || value === 'BRING') {
                    this.changeSwapOrBringInput();
                } else {
                    this.addPackageForm.controls['toCollectFromReceiver'].setValue('');
                    this.addPackageForm.controls['toPayToReceiver'].setValue('');
                    this.addPackageForm.controls['toPayToReceiver'].disable();
                    this.addPackageForm.controls['toCollectFromReceiver'].disable();
                }
                this.addPackageForm.get('cod').updateValueAndValidity();
            });
    }

    changeSwapOrBringInput() {
        this.addPackageForm.controls['toCollectFromReceiver'].setValue('');
        this.addPackageForm.controls['toPayToReceiver'].setValue('');
        if (this.swapOrBring === 'COLLECT') {
            this.addPackageForm.controls['swapOrBring'].setValue('COLLECT');
            this.addPackageForm.controls['toCollectFromReceiver'].enable();
            if (this.optionalToPayAndToReceiveInputs.indexOf(this.companyId) > -1) {
                this.addPackageForm.controls['toCollectFromReceiver'].setValidators([Validators.min(0)]);
            } else {
                this.addPackageForm.controls['toCollectFromReceiver'].setValidators([Validators.required, Validators.min(0)]);
            }

            this.addPackageForm.controls['toPayToReceiver'].clearValidators();
            this.addPackageForm.controls['toPayToReceiver'].disable();
        } else if (this.swapOrBring === 'PAY') {
            this.addPackageForm.controls['swapOrBring'].setValue('PAY');
            this.addPackageForm.controls['toPayToReceiver'].enable();
            if (this.optionalToPayAndToReceiveInputs.indexOf(this.companyId) > -1) {
                this.addPackageForm.controls['toPayToReceiver'].setValidators([Validators.min(0)]);
            } else {
                this.addPackageForm.controls['toPayToReceiver'].setValidators([Validators.required, Validators.min(0)]);
            }
            this.addPackageForm.controls['toCollectFromReceiver'].clearValidators();
            this.addPackageForm.controls['toCollectFromReceiver'].disable();
        }
        this.addPackageForm.controls['toPayToReceiver'].updateValueAndValidity();
        this.addPackageForm.controls['toCollectFromReceiver'].updateValueAndValidity();
    }

    public changeSwapOrBring() {
        this.swapOrBring = this.addPackageForm.value.swapOrBring;
        this.changeSwapOrBringInput();
    }

    getServiceTypes(event) {
        const customerInfo = this.addPackageForm.get('customerInfo').value;
        if (!customerInfo || !customerInfo.id) {
            return;
        }
        const query = '?page=1&pageSize=20&search=' + event.query + '&customerId=' + this.addPackageForm.get('customerInfo').value.id;
        this.serviceTypesService.getServiceTypesDropdown(query).subscribe(
            (res: any) => {
                this.serviceTypesAcList = res;
            }, error => {
                console.error(error);
            }
        );
    }

    getVehicleTypes(event = null) {
        if (!this.selectedServiceType.id) {
            return;
        }
        let query = '?page=1&pageSize=20';
        if (event) {
            query += '&search=' + event.query;
        }

        this.serviceTypesService.getVehicleTypes(this.selectedServiceType.id, query).subscribe(
            (res: any) => {
                this.showVehicleTypesList = true;
                this.vehicleTypesList = res;
                const defaultVehicleType = this.vehicleTypesList.filter(vehicleType => vehicleType.isDefault);
                if (defaultVehicleType.length && !this.selectedVehicleType) {
                    this.selectedVehicleType = JSON.parse(JSON.stringify(defaultVehicleType[0]));
                    this.chooseVehicleType(defaultVehicleType[0], true);
                }
            }, error => {
                console.error(error);
            }
        );
    }

    public chooseVehicleType($event, getDeliveryPrice = false) {
        this.updateFormValue('vehicleType', $event.name);
        this.addPackageForm.get('vehicleType').updateValueAndValidity();
        this.selectedVehicleType = JSON.parse(JSON.stringify($event));
        this.editVehicleType = false;
        if (getDeliveryPrice) {
            this.getDeliveryPrice();
        }
    }

    public chooseServiceType($event, setDates = true, getDeliveryPrice = false) {
        const serviceType = $event;
        let name;
        if (serviceType.arabicName) {
            name = this.isArabic ? serviceType.arabicName : serviceType.name;
        } else {
            name = serviceType.name;
        }
        this.updateFormValue('serviceType', name);
        this.selectedServiceType = JSON.parse(JSON.stringify(serviceType));
        this.editServiceType = false;
        this.showVehicleTypesList = true;
        this.getVehicleTypes();
        this.addPackageForm.controls['vehicleType'].setValidators([Validators.required]);
        this.addPackageForm.get('vehicleType').updateValueAndValidity();

        if (getDeliveryPrice) {
            this.getDeliveryPrice();
        }

        if (setDates) {
            this.setDate('expectedDeliveryDate', this.selectedServiceType.expectedDeliveryHours);
            this.setDate('expectedPickupDate', this.selectedServiceType.expectedPickupHours);
        }
    }

    setDate(dateFormControl, numberOfHours) {
        const date = new Date();
        const totalTime = numberOfHours / 24;
        const numberOfDays = Math.floor(totalTime);
        const hoursNumber = Math.round(Math.abs((totalTime - numberOfDays) * 24));
        date.setDate(date.getDate() + numberOfDays);
        date.setHours(date.getHours() + hoursNumber);
        date.setSeconds(0);
        this.addPackageForm.controls[dateFormControl].setValue(new Date(date));
        this.addPackageForm.controls[dateFormControl].updateValueAndValidity();
    }

    public editSelectedVehicleType() {

        if (!this.selectedVehicleType) {
            this.vehicleTypeAC.focusInput();
            this.vehicleTypeAC.search(null, '');
            return;
        }

        this.editVehicleType = true;
        this.vehicleTypeAC.focusInput();
        const search_term = this.addPackageForm.get('vehicleType').value;
        this.vehicleTypeAC.search(null, search_term);
    }

    resetVehicleType() {
        this.selectedVehicleType = null;
        this.updateFormValue('vehicleType', null);
        this.editVehicleType = false;
    }

    public editSelectedServiceType() {

        if (!this.selectedServiceType) {
            this.serviceTypeAC.focusInput();
            this.serviceTypeAC.search(null, '');
            return;
        }

        this.editServiceType = true;
        this.serviceTypeAC.focusInput();
        const search_term = this.addPackageForm.get('serviceType').value;
        this.serviceTypeAC.search(null, search_term);
    }

    resetServiceType() {
        this.selectedServiceType = null;
        this.updateFormValue('serviceType', null);
        this.editServiceType = false;
        this.showVehicleTypesList = false;
        this.addPackageForm.controls['vehicleType'].clearValidators();
        this.addPackageForm.get('vehicleType').updateValueAndValidity();
        this.resetVehicleType();

    }

    public getStores(event?) {
        let query = '?page=1&pageSize=20&status=' + UserStatus.VERIFIED;

        if (event && event.query) {
            query += `&search=${event.query}`;
        }
        this.receiverLoading = true;
        this.usersService.getCustomers(query).subscribe(
            (response: any) => {
                this.storesList = response.customers;
                this.receiverLoading = false;
            }, () => {
                this.receiverLoading = false;
            }
        );
    }

    public chooseReceiverAddress(address) {
        this.selectedReceiverAddress = JSON.parse(JSON.stringify(address));
        if (this.customizationCompaniesService.checkCompanies(this.companies.SAR)) {
            this.getStationsData();
        }
        this.handleVillageChanged(address);
        this.getDeliveryPrice();
        const village = this.addPackageForm.get('receiverAddress').value;
        if (village.latitude && village.longitude) {
            this.addPackageForm.get('receiverLocation').patchValue({
                value: {
                    latlng: {
                        lat: village.latitude,
                        lng: village.longitude
                    }
                }
            });
        }
    }

    public getStationsData() {
        const selectedCustomerInfo = this.addPackageForm.get('customerInfo').value;
        const expectedPickupDate = this.addPackageForm.get('customerInfo').value;
        if (selectedCustomerInfo?.address && this.selectedReceiverAddress) {
            const queryParam = {
                fromCity: selectedCustomerInfo.address.cityId,
                toCity: this.selectedReceiverAddress.cityId
            };
            this.shipmentService.getStations(queryParam).subscribe(
                (response: any) => {
                    this.addPackageForm.get('fromHub').setValue({label: response.fromHubCity, value: +response.fromHub});
                    this.addPackageForm.get('toHub').setValue({label: response.toHubCity, value: +response.toHub});
                    this.getSuggestionTripNumber();
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }

    triggerPanel(event, panel) {
        panel.toggle(event);
    }

    getSuggestionTripNumber() {
        if (this.addPackageForm.value.expectedPickupDate && this.addPackageForm.value.fromHub && this.addPackageForm.value.toHub) {
            const querySuggestionParams = {
                fromHub: this.addPackageForm.value.fromHub.value,
                toHub: this.addPackageForm.value.toHub.value,
                pickupDate: this.datePipe.transform(this.addPackageForm.value.expectedPickupDate, 'yyyy-MM-dd HH:mm:ss'),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            this.shipmentService.getSuggestionTripNumber(querySuggestionParams).subscribe(
                (suggestResponse: any) => {
                    this.addPackageForm.get('trip').patchValue(null);
                    this.tripNumbersList = suggestResponse.map(suggest => {
                        return {label: suggest.tripNumber, value: suggest.id};
                    });

                    if (this.tripNumbersList.length === 1) {
                        this.addPackageForm.get('trip').patchValue(this.tripNumbersList[0].value);
                    }
                }, (error) => {
                    console.error(error);
                }
            );
        }
    }

    public chooseStore(getDeliveryPrice = false) {
        if (this.companiesWithStations.indexOf(this.companyId) !== -1) {
            this.getStationsData();
        }
        if (this.isLcl) {
            this.fillLclReceiverInfo(this.addPackageForm.get('customerInfo').value);
        }
        if (getDeliveryPrice) {
            this.getDeliveryPrice();
        }
        const data = this.addPackageForm.get('customerInfo').value;

        if (!this.isLcl && !this.isPricingPerServiceTypeEnabled) {
            if (data && data.serviceTypes) {
                this.translate.get(data.serviceTypes.filter(n => n)).subscribe(values => {
                    this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                        return {
                            label: values[translationKey],
                            value: translationKey
                        };
                    });
                });
            } else {
                this.initDefaultShipmentServiceType();
            }
        }
        if (data && data.shipmentTypes && this.companyId !== 209 && !this.isJettCompany()) { // always set the default for sare3 company
            this.translate.get(data.shipmentTypes).subscribe(values => {
                this.shipmentTypesOptions = Object.keys(values).map(translationKey => {
                    return {
                        label: values[translationKey],
                        value: translationKey
                    };
                });
            });
            if (this.shipmentTypesOptions.map(type => (type.value)).indexOf('COD') !== -1) {
                this.addPackageForm.get('paymentType').setValue('COD');
                this.addPackageForm.get('paymentType').updateValueAndValidity();
            } else {
                this.addPackageForm.get('paymentType').setValue('');
                this.addPackageForm.get('paymentType').updateValueAndValidity();
            }

            this.shipmentType = this.addPackageForm.get('paymentType').value;

        } else {
            this.initDefaultShipmentType();
        }
        this.initCodFees();
        this.updateInsuranceCod({});

    }

    public updateFormValue(controlName, value) {
        this.addPackageForm.controls[controlName].setValue(value);
        this.addPackageForm.controls[controlName].updateValueAndValidity();
    }

    public updateFormValidator(controlName, validatorArray) {
        this.addPackageForm.controls[controlName].setValidators(validatorArray);
        this.addPackageForm.controls[controlName].updateValueAndValidity();
    }

    public clearFormValidator(controlName) {
        this.addPackageForm.controls[controlName].clearValidators();
        this.addPackageForm.controls[controlName].updateValueAndValidity();
    }


    public onTypeChanged(event) {
        this.shipmentType = event.value;
        this.swapSenderAndReceiver = event.value === 'BRING';

        if (event.value === 'COD' || event.value === 'NON_COD') {
            this.addPackageForm.controls.cod.setValue('');
        } else {
            this.addPackageForm.controls.cod.setValue('0');
        }

        this.addPackageForm.controls.isReceiverPayCost.setValue(false);

        if (this.isJettCompany() && event.value === 'COD_V2') {
            this.addPackageForm.controls.isSenderPayCost.setValue(true);
        } else if (this.isJettCompany() && event.value === 'COD') {
            this.addPackageForm.controls.isSenderPayCost.setValue(false);
        }


        this.getDeliveryPrice();
    }

    public getDeliveryPrice() {
        if (!this.addPackageForm || !this.addPackageForm.get('customerInfo').value || !this.selectedReceiverAddress) {
            return;
        }
        let queryParams = {};

            const selectedCustomerInfo = this.addPackageForm.get('customerInfo').value;
            this.deliveryPrice = -1;

            queryParams = {
                originRegionId: selectedCustomerInfo.address.regionId,
                destinationRegionId: this.selectedReceiverAddress.regionId,
                customerId: selectedCustomerInfo.id
            };
            if (this.selectedServiceType) {
                queryParams['serviceTypeId'] = this.selectedServiceType.id;
            }
            if (this.selectedVehicleType) {
                queryParams['vehicleTypeId'] = this.selectedVehicleType.id;
            }
            if (this.isEditMode && !this.isCloning && this.editedPackageId) {
                queryParams['packageId'] = this.editedPackageId;
            }
            if (this.parcelTypeId) {
                queryParams['parcelTypeId'] = this.parcelTypeId;
            }
            if (this.shipmentType) {
                queryParams['shipmentType'] = this.shipmentType;
            }
            const isDeliverToReceivingPoint = this.addPackageForm?.get('isDeliverToReceivingPoint')?.value;
            if (isDeliverToReceivingPoint) {
                queryParams['isDeliverToReceivingPoint'] = isDeliverToReceivingPoint;
            }
            if (this.addPackageForm.get('weight').value) {
                queryParams['weight'] = this.addPackageForm.get('weight').value;
            }
        if (this.isJettCompany()) {
            if (!this.addPackageForm.get('weight').value ||
                !this.addPackageForm.get('width').value ||
                !this.addPackageForm.get('length').value ||
                !this.addPackageForm.get('height').value) {
                return;
            }
            if (this.addPackageForm.get('weight').value) {
                queryParams['weight'] = this.addPackageForm.get('weight').value;
            }
            if (this.addPackageForm.get('width').value) {
                queryParams['width'] = this.addPackageForm.get('width').value;
            }
            if (this.addPackageForm.get('length').value) {
                queryParams['length'] = this.addPackageForm.get('length').value;
            }
            if (this.addPackageForm.get('height').value) {
                queryParams['depth'] = this.addPackageForm.get('height').value;
            }
            queryParams['jettShippingRate'] = {
                packageType: this.addPackageForm.get('orderId').value,
                isWithVat: true
            };
        }

        this.shipmentService.getDeliveryPrice(queryParams).subscribe(
            (response: any) => {
                this.deliveryPrice = response.cost;
                this.updateFormValue('freight', this.deliveryPrice);
                this.addPackageForm.get('freight').updateValueAndValidity();
                if (this.isJettCompany()) {
                    this.updateFormValue('cod', this.deliveryPrice);
                    this.addPackageForm.get('cod').updateValueAndValidity();
                }
            }, (error) => {
                console.error(error);
            }
        );
    }


    public swapSenderDataAndRecieverData(data: any) {

        const swappedData = JSON.parse(JSON.stringify(data));

        // Reciver
        swappedData['pkg']['receiverName'] = data.pkg.senderName;
        swappedData['pkg']['businessReceiverName'] = data.pkg.businessSenderName ? data.pkg.businessSenderName : '';
        swappedData['pkg']['receiverPhone'] = data.pkg.senderPhone;
        swappedData['pkg']['receiverPhone2'] = data.pkg.senderPhone2;
        swappedData['pkg']['receiverAuthorizedGovRegistrationNumber'] = data.pkg.senderAuthorizedGovRegistrationNumber;

        // Sender
        swappedData['pkg']['senderName'] = data.pkg.receiverName;
        swappedData['pkg']['senderEmail'] = this.addPackageForm.get('customerInfo').value.email;
        swappedData['pkg']['senderPhone'] = data.pkg.receiverPhone;
        swappedData['pkg']['senderPhone2'] = data.pkg.receiverPhone2;
        swappedData['pkg']['businessSenderName'] = data.pkg.businessReceiverName ? data.pkg.businessReceiverName : '';
        swappedData['pkg']['senderAuthorizedGovRegistrationNumber'] = data.pkg.receiverAuthorizedGovRegistrationNumber;

        // origin address
        swappedData['originAddress'] = {...data.destinationAddress};

        // origin address
        swappedData['destinationAddress'] = {...data.originAddress};

        return swappedData;
    }

    // Submitting our PackageForm

    public changePackageLocation(location: string) {

        if (location === this.packageLocation) {
            return;
        }
        this.packageLocation = location;

        if (this.packageLocation === this.packageLocations.HUB) {
            this.selectedHub = this.defaultHub;
            this.updateFormValue('hubId', this.defaultHub);
            this.updateFormValidator('hubId', [Validators.required]);
            // console.log('hubId', this.addPackageForm.get('hubId').validator({} as AbstractControl));

            this.updateFormValue('driverId', '');
            this.clearFormValidator('driverId');
        } else if (this.packageLocation === this.packageLocations.DRIVER) {
            // const driverId = this.selectedDriverId ? this.selectedDriverId : '';
            this.updateFormValue('driverId', '');
            this.updateFormValidator('driverId', [Validators.required]);
            // console.log('driverId', this.addPackageForm.get('driverId').validator({} as AbstractControl));
            this.updateFormValue('hubId', '');
            this.clearFormValidator('hubId');
        } else {
            this.updateFormValue('hubId', '');
            this.clearFormValidator('hubId');
            this.updateFormValue('driverId', '');
            this.clearFormValidator('driverId');
        }
    }

    public onHubChange(event) {
        this.selectedHub = event.value;
    }

    public onDriverChange(event) {
        this.selectedDriverId = event.value;
    }


    public getAddresses(event) {
        let query;
        if (event && (event.query || event.query === '')) {
            query = event.query;
        } else {
            query = event;
        }
        this.geographyService.getvillages(query, this.addPackageForm.get('isDeliverToReceivingPoint').value).subscribe(
            (response: any) => {
                this.addressOptions = response.data.map(address => {
                    const label = address.arabicName + ' - ' + address.cityName + ' - ' + address.regionName;
                    return {
                        ...address,
                        label
                    };
                });
            }, (error) => {
                console.error(error);
            }
        );

    }

    public onServiceTypeChange(event) {
        const serviceType = event.value;
        const exptectedDeliveryDate = new Date();
        let expectedPickupDate = null;
        exptectedDeliveryDate.setHours(0);
        exptectedDeliveryDate.setMinutes(0);
        exptectedDeliveryDate.setSeconds(0);
        switch (serviceType) {
            case 'EXPRESS':
                exptectedDeliveryDate.setDate(exptectedDeliveryDate.getDate() + 1);
                break;
            case 'STANDARD':
                if (this.companyId === 139) {
                    expectedPickupDate = new Date();
                    exptectedDeliveryDate.setDate(exptectedDeliveryDate.getDate() + 1);
                } else {
                    exptectedDeliveryDate.setDate(exptectedDeliveryDate.getDate() + 2);
                }
                break;
            case 'THREE_TO_FIVE_DAYS':
                exptectedDeliveryDate.setDate(exptectedDeliveryDate.getDate() + 3);
                break;
        }
        this.addPackageForm.controls.expectedPickupDate.setValue(expectedPickupDate);
        this.addPackageForm.controls.expectedPickupDate.updateValueAndValidity();
        this.addPackageForm.controls.expectedDeliveryDate.setValue(new Date(exptectedDeliveryDate));
        this.addPackageForm.controls.expectedDeliveryDate.updateValueAndValidity();
    }

    public showCreatedDate() {
        return this.companyId === 64 && !this.isEditMode;
    }

    public selectSavingOption(inputName) {
        this.addPackageForm.patchValue({
            ['directPrint']: inputName === 'directPrint',
            ['onlySave']: inputName === 'onlySave',
            ['saveAndContinue']: inputName === 'saveAndContinue'
        });
    }

    public submitForm() {
        if (this.isSubmittingPackage) {
            return;
        }

        this.isSubmittingPackage = true;

        let invalidContent = false;
        if (this.isSupportDeliveringPackageItemsPartially) {
            invalidContent = this.pc.packageContents.some(x => {
                return !x.cod && x.cod !== 0 || !x.name;
            });
            if(invalidContent){
                this.pc.markAllAsTouched();
            }
        }
        if (this.addPackageForm.invalid || invalidContent) {
            this.utilitiesService.validateAllFormFields(this.addPackageForm);
            this.isSubmittingPackage = false;
            return;
        }

        const data = this.addPackageForm.getRawValue();
        let body = {
            pkg: {
                // Sender
                customerId: data.customerInfo.id,
                senderName: this.isLcl ? this.companyInfo.name : data.customerInfo.fullName,
                senderEmail: data.customerInfo.email,
                senderPhone: this.isLcl ? this.companyInfo.phone : data.customerInfo.phone,
                senderPhone2: this.isLcl ? null : data.customerInfo.phone2,
                businessSenderName: this.isLcl ? this.companyInfo.businessName : data.customerInfo.businessName,

                // Reciever
                receiverName: data.receiverName,
                businessReceiverName: data.receiverBusinessName,
                receiverAuthorizedGovRegistrationNumber: data.receiverAuthorizedGovRegistrationNumber,
                receiverPhone: data.receiverPhone,
                receiverPhone2: data.receiverPhone2,


                // pakcage details


                shipmentType: data.paymentType === 'NON_COD' ? 'REGULAR' : (data.paymentType ? data.paymentType.toUpperCase() : ''),
                paymentType: data.codCollectionMethod,
                // products: data.packageDetailsList,
                cod: data.cod ? data.cod : null,
                cost: data.freight,
                isFragile: data.isFragile,
                isflammable: data.isflammable,
                isNeedPackaging: data.isNeedPackaging,
                isFingerprintRequired: data.isFingerprintRequired,
                invoiceNumber: data.invoiceNumber,
                isReceiverPayCost: data.isReceiverPayCost,
                isSenderPayCost: data.isSenderPayCost,
                notes: data.notes,
                weight: data.weight,
                description: data.description,
                adminNotes: data.adminNotes,
                quantity: data.quantity,
                toCollectFromReceiver: (data.toCollectFromReceiver && data.toCollectFromReceiver !== '') ? data.toCollectFromReceiver : 0,
                toPayToReceiver: (data.toPayToReceiver && data.toPayToReceiver !== '') ? data.toPayToReceiver : 0,
                createdDate: data.createdDate,
                expectedDeliveryDate: !this.isLcl && data.expectedDeliveryDate ?
                    new Date(data.expectedDeliveryDate).toISOString() : null,
                // hubId: data.hubId
                codFees: data.codFees,
                isDestructible: data.isDestructible,
                isPreventOpen: data.isPreventOpen,
                isPreventMeasuring: data.isPreventMeasuring,
                trip: data.trip ? {id: data.trip} : null,
                fromHub: data.fromHub?.value,
                toHub: data.toHub?.value,
                insuranceFee: this.insuranceValue,
                additionalCodFee: this.codValue
            },
            originAddress: {
                country: 'Palestine',
                regionId: this.isLcl ? this.companyInfo.address.regionId : data.customerInfo.address.regionId,
                cityId: this.isLcl ? this.companyInfo.address.cityId : data.customerInfo.address.cityId,
                villageId: this.isLcl ? this.companyInfo.address.villageId : data.customerInfo.address.villageId,
                addressLine1: this.isLcl ? this.companyInfo.address.addressLine1 : data.customerInfo.address.addressLine1,
                addressLine2: this.isLcl ? null : data.customerInfo.address.addressLine2,
                longitude: this.isLcl ? null : data.customerInfo.address.longitude,
                latitude: this.isLcl ? null : data.customerInfo.address.latitude,
            },

            destinationAddress: {
                country: 'Palestine',
                regionId: data.receiverAddress.regionId,
                cityId: data.receiverAddress.cityId,
                villageId: data.receiverAddress.id,
                addressLine1: typeof data.receiverAddressLine1 === 'string' ? data.receiverAddressLine1 : data.receiverAddressLine1.label,
                buildingNo: data.building,
                floorNo: data.flat,
                words: data.words
            },
        };
        if (this.isSupportDeliveringPackageItemsPartially) {
            body.pkg['packageItemsToDeliverList'] = this.pc.packageContents;
        }

        if (body.pkg.shipmentType === 'COD_V2') {
            body.pkg.shipmentType = 'COD';
        }

        if (this.isJettCompany()) {
            body.pkg['fromHub'] = data.fromHub;
            body.pkg['toHub'] = data.toHub;
            body.pkg['width'] = data.width;
            body.pkg['height'] = data.height;
            body.pkg['length'] = data.length;
            body.pkg['orderId'] = data.orderId;
            body.pkg['isSenderPayCost'] = data.isSenderPayCost;
        }


        body.pkg['expectedPickupDate'] = data.expectedPickupDate;
        if (['SWAP', 'BRING'].indexOf(data.paymentType) !== -1) {
            if (data.swapOrBring === 'COLLECT') {
                body.pkg['cod'] = data.isReceiverPayCost ? data.freight + data.toCollectFromReceiver : data.toCollectFromReceiver;
            } else if (data.swapOrBring === 'PAY') {
                body.pkg['cod'] = data.isReceiverPayCost ? data.freight - data.toPayToReceiver : -data.toPayToReceiver;
            }
        }

        if (this.isLcl) {
            if (data.packageItems && data.packageItems.length) {
                body.pkg['packageItems'] = data.packageItems.map(item => {
                    const newItem = item;
                    newItem.supplier = item.supplier.value ? item.supplier.value : item.supplier;
                    return newItem;
                });
            } else {
                body.pkg['packageItems'] = [];
            }
            body.pkg['currency'] = data.currency;
        }

        if (this.userService.userInfo.isPricingPerServiceTypeEnabled) {
            if (this.selectedVehicleType) {
                body.pkg['vehicleTypeId'] = this.selectedVehicleType.id;
            }
            if (this.selectedServiceType) {
                body.pkg['serviceTypeId'] = this.selectedServiceType.id;
            }
        } else {
            body.pkg['serviceType'] = data.service ? data.service.toUpperCase() : '';
        }
        if (this.companiesWithCooling.indexOf(this.companyId) !== -1) {
            body.pkg['isNeedCooling'] = data.isNeedCooling;

        }
        if (this.companiesWithMeasurement.indexOf(this.companyId) !== -1) {
            body.pkg['isNeedMeasurement'] = data.isNeedMeasurement;
        }
        if (this.companiesWithPreview.indexOf(this.companyId) !== -1) {
            body.pkg['isNeedPreview'] = data.isNeedPreview;
        }
        if (data.receiverLocation && data.receiverLocation.value) {
            body.destinationAddress['latitude'] = data.receiverLocation.value.latlng.lat;
            body.destinationAddress['longitude'] = data.receiverLocation.value.latlng.lng;
        }
        if (data.receiverAddressWords) {
            body.destinationAddress['words'] = data.receiverAddressWords;
        }

        if (this.isReceivingPointEnabled) {
            body.pkg['isDeliverToReceivingPoint'] = data.isDeliverToReceivingPoint;
        }
        if (this.isSupportAdditionalFees) {
            body.pkg['isInsured'] = data.isInsured;
        }

        if (data.hubId && data.hubId !== '' && !this.isTrucking) {
            body.pkg['hubId'] = data.hubId;
            const selectedHub = this.hubsOptions.filter(hub => hub.value === data.hubId);
            if (selectedHub.length) {
                body.pkg['hubName'] = selectedHub[0].label;
            }
        }

        if (data.driverId && data.driverId !== '') {
            body.pkg['driverId'] = data.driverId.value;
            body.pkg['driverName'] = data.driverId.label;
        }
        if (data.hubId && typeof data.hubId === 'object') {
            body.pkg['hubId'] = data.hubId.value;
        }

        if (this.isTrucking) {
            body.pkg['packageContent'] = data.packageContents;
        }

        if (body.pkg.receiverPhone) {
            let value = body.pkg.receiverPhone;
            value = value.trim();
            body.pkg.receiverPhone = value;
        }
        // this.isSubmittingPackage = false;
        // console.log(body.pkg);
        // return;

        // Parcels
        if (data.parcelId && typeof data.parcelId === 'number') {
            const selectedParcel = this.parcelsOptions.find(parcel => parcel.id === data.parcelId);
            const parcelDetails = {
                weight: this.isJettCompany() ? +data.weight : selectedParcel.weight,
                length: selectedParcel.length,
                width: selectedParcel.width,
                height: selectedParcel.height,
                parcelTypeId: selectedParcel.id,
            };

            body.pkg = {...body.pkg, ...parcelDetails};
        }

        if (this.swapSenderAndReceiver) {
            body = this.swapSenderDataAndRecieverData(body);
        }


        if (this.isEditMode && !this.isCloning) {
            const editBody = {...body.pkg};
            editBody['destinationAddress'] = body.destinationAddress;
            editBody['originAddress'] = body.originAddress;
            // editBody['expectedDeliveryDate'] =  this.transformDate(new Date(body.expectedDeliveryDate).toLocaleDateString();
            this.shipmentService.editShipment(editBody, this.editedPackageId).subscribe(
                (res) => {
                    this.uploadAttachments({id: this.editedPackageId});
                    this.deliveryPrice = -1;
                    this.translate.get('ALERTS.SHIPMENT_EDITED_SUCCESSFULLY').subscribe(
                        (translateValue) => {
                            this.messageService.add({severity: 'success', detail: this.packageTitlePipe.transform(translateValue)});
                        }
                    );
                    this.clearFormData();
                    this.addPackageSideBar.hide();
                    this.packagesIds.push(this.editedPackageId);
                    this.shipmentService.shipmentAdded(this.isEditMode);
                    this.isSubmittingPackage = false;
                    if (this.originPkg) {
                        Object.assign(this.originPkg, editBody);
                    }
                }, (error) => {
                    console.error(error);
                    this.isSubmittingPackage = false;
                }
            );
        } else {
            this.shipmentService.addNewShipment(body).subscribe(
                (response) => {
                    this.uploadAttachments(response);
                    this.translate.get('ALERTS.SHIPMENT_ADDED_SUCCESSFULLY').subscribe(
                        (translateValue) => {
                            this.messageService.add({severity: 'success', detail: this.packageTitlePipe.transform(translateValue)});
                        }
                    );
                    if (this.isLcl && this.attachmentsUploader.files.length) {
                        this.shipmentService.uploadShipmentAttachments(response['id'], this.attachmentsUploader.files).subscribe(() => {
                            this.postAddPackage(data, response);
                        }, () => {
                            this.postAddPackage(data, response);
                        });
                    } else {
                        this.postAddPackage(data, response);
                    }
                }, (error) => {
                    console.error(error);
                    this.isSubmittingPackage = false;
                }
            );

        }
        if (data.onlySave) {
            this.addPackageSideBar.hide();
        }
    }

    postAddPackage(data, response) {
        this.clearFormData();
        this.packagesIds.push(response['id']);
        this.directPrint = data.directPrint;
        this.onlySave = data.onlySave;
        this.saveAndContinue = data.saveAndContinue;
        if (!data.saveAndContinue) {
            this.addPackageSideBar.hide();
            this.shipmentService.shipmentAdded();
        } else {
            this.initializeFormWithPreselectedData();
            this.addPackageForm.patchValue({
                customerInfo: this.companyId === 64 ? '' : data.customerInfo,
                hubId: data.hubId ? this.selectedHub : null,
                driverId: data.driverId,
                saveAndContinue: true,
                receiverAddressLine1: this.getInitReceiverAddressLine1(),
                receiverName: this.getInitReceiverName()
            });

            this.hasSavedPackages = true;

        }
        this.deliveryPrice = -1;
        this.isSubmittingPackage = false;
    }

    fillLclSenderInfo() {
        const regionName = this.isArabic ? this.companyInfo.address.arabicRegionName : this.companyInfo.address.region;
        const cityName = this.isArabic ? this.companyInfo.address.arabicCityName : this.companyInfo.address.city;
        const villageName = this.isArabic ? this.companyInfo.address.arabicVillageName : this.companyInfo.address.village;
        this.senderInfoLcl = {
            senderName: this.companyInfo.name,
            senderBusinessName: this.companyInfo.businessName,
            senderEmail: this.companyInfo.email,
            senderPhone: this.companyInfo.phone,
            address: {
                label: `${villageName} - ${cityName} - ${regionName}`,
                villageId: this.companyInfo.address.villageId,
                cityId: this.companyInfo.address.cityId,
                regionId: this.companyInfo.address.regionId,
                country: this.companyInfo.country,
                addressLine1: this.companyInfo.addressLine1
            }
        };
    }

    private fillLclReceiverInfo(data) {
        if (!data) {
            return;
        }
        const receiverAddress = data.address ? data.address : data.destinationAddress;
        const receiverInfo = {
            receiverName: data.fullName ? data.fullName : `${data.receiverFirstName} ${data.receiverLastName}`,
            receiverBusinessName: data.businessName ? data.businessName : data.businessReceiverName,
            receiverEmail: data.email,
            receiverPhone: data.phone ? data.phone : data.receiverPhone,
            receiverPhone2: data.phone1,
            receiverAddress: {
                ...receiverAddress,
                id: receiverAddress.villageId,
                label: this.isArabic && receiverAddress.arabicVillageName
                    ? `${receiverAddress.arabicVillageName} - ${receiverAddress.arabicCityName} - ${receiverAddress.arabicRegionName}`
                    : `${receiverAddress.village} - ${receiverAddress.city} - ${receiverAddress.region}`
            },
            receiverAddressLine1: data.addressLine1 ? data.addressLine1 : receiverAddress.addressLine1,
            receiverAuthorizedGovRegistrationNumber: data.authorizedGovRegistrationNumber
        };
        this.addPackageForm.patchValue(receiverInfo);
    }

    public getInitReceiverName() {
        if ([157, 64, 275, 293, 230].indexOf(this.companyId) >= 0) {
            return '.';
        } else {
            return '';
        }
    }

    public getInitReceiverAddressLine1() {
        if (this.customizationCompaniesService.checkCompanies(
            this.companies.RAWABI_BISAN,
            this.companies.SPEED_LIGHT_DELIVERY,
            this.companies.THE_TRANSPORTER_LOGISTICS,
            this.companies.LOGESTECHS,
            this.companies.PAL_POST)) {
            if(this.hasRouteOptimization) {
                return {
                    label: '.',
                    value: '.'
                };
            } else {
                return '.';
            }
        } else {
            return '';
        }
    }

    private getInitReceiverPhoneNumber() {
        if (this.customizationCompaniesService.checkCompanies(this.companies.PAL_POST, this.companies.LOGESTECHS)) {
            return '0599000000';
        } else {
            return '';
        }
    }

    addLclItems() {
        const modal = this.modalService.open(AddLclItemsComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'lcl-items-modal'
        });
        if (this.addPackageForm.get('packageItems').value && Object.keys(this.addPackageForm.get('packageItems').value).length > 0) {
            modal.componentInstance.items = this.addPackageForm.get('packageItems').value;
        }
        modal.result.then(result => {
            if (result && result.isSuccess) {
                this.addPackageForm.controls['packageItems'].patchValue(result.items);
            }
        });
    }

    isLocationChosen() {
        const receiverLocation = this.addPackageForm.get('receiverLocation').value;
        return receiverLocation && receiverLocation.value;
    }

    onSelectGeocodeSuggestion() {
        // this.addPackageForm.get('receiverLocation').patchValue(this.addPackageForm.get('receiverAddressLine1').value);
        this.geocode.key(this.addPackageForm.get('receiverAddressLine1').value.value).run(
            (err, results: { results: any[] }) => {
                if (results && results.results.length) {
                    const result = {
                        label: results.results[0].properties.LongLabel,
                        value: results.results[0]
                    };
                    this.addPackageForm.get('receiverLocation').patchValue(result);
                    this.addPackageForm.get('receiverLocation').patchValue(result);
                }
            });
    }

    onGeocodeSearch(query: string) {
        if (query) {
            this.geocodeSuggest.text(query).run((err, results: { suggestions: any[] }) => {
                if (err) {
                    this.translate.get('ALERT.GEOCODING_FAILED').subscribe(transValue => {
                        this.messageService.add({
                            severity: 'error',
                            detail: transValue
                        });
                    });
                    return;
                }

                this.locationOptions = results.suggestions.map(result => {
                    return {
                        label: result.text,
                        value: result.magicKey
                    };
                });
            });
        }
    }

    showMap() {
        this.preventSubmitWhenPressEnter = true;
        const modal = this.modalService.open(MapComponent, {
            backdrop: 'static',
            backdropClass: 'map-component-backdrop',
            windowClass: 'map-component',
            size: 'lg'
        });

        if (this.addPackageForm.get('receiverLocation').value) {
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.location = this.addPackageForm.get('receiverLocation').value.value;
        } else if (this.addPackageForm.get('receiverAddressLine1').value && this.addPackageForm.get('receiverAddressLine1').value.value) {
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.location = this.addPackageForm.get('receiverAddressLine1').value.value;
        }

        modal.result.then(result => {
            if (result && result.words) {
                this.addPackageForm.get('receiverAddressWords').patchValue(result.words);
                this.addPackageForm.get('receiverAddressLine1').patchValue({
                    label: result.words,
                    value: result.words
                });
            } else if (result) {
                this.addPackageForm.get('receiverLocation').patchValue({
                    label: result.label || (result.address ? result.address.address.LongLabel : ''),
                    value: result.location
                });
                if (result.label ||  result.address && result.address.address.LongLabel) {
                    this.addPackageForm.get('receiverAddressLine1').patchValue({
                        label: result.label || (result.address ? result.address.address.LongLabel : ''),
                        value: result.location
                    });
                }
            }
            this.preventSubmitWhenPressEnter = false;
        });
    }
    showMaps(type) {
        this.preventSubmitWhenPressEnter = true;
        const modal = this.modalService.open(ChooseMapComponent, {
            backdrop: 'static',
            backdropClass: 'map-component-backdrop',
            windowClass: 'map-component',
            size: 'lg'
        });

        modal.componentInstance.type = type;

        if (this.addPackageForm.get('receiverLocation').value) {
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.location = this.addPackageForm.get('receiverLocation').value.value;
        } else if (this.addPackageForm.get('receiverAddressLine1').value && this.addPackageForm.get('receiverAddressLine1').value.value) {
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.location = this.addPackageForm.get('receiverAddressLine1').value.value;
        }

        modal.result.then(result => {
            if (result && result.words) {
                this.addPackageForm.get('receiverAddressWords').patchValue(result.words);
                this.addPackageForm.get('receiverAddressLine1').patchValue({
                    label: result.words,
                    value: result.words
                });
            } else if (result) {
                this.addPackageForm.get('receiverLocation').patchValue({
                    label: result.label || (result.address ? result.address.address.LongLabel : ''),
                    value: result.location
                });
                if (result.label ||  result.address && result.address.address.LongLabel) {
                    this.addPackageForm.get('receiverAddressLine1').patchValue({
                        label: result.label || (result.address ? result.address.address.LongLabel : ''),
                        value: result.location
                    });
                }
            }
            this.preventSubmitWhenPressEnter = false;
        });
    }


    showReceiverLocationMap() {
        this.preventSubmitWhenPressEnter = true;
        const modal = this.modalService.open(MapComponent, {
            backdrop: 'static',
            backdropClass: 'map-component-backdrop',
            windowClass: 'map-component',
            size: 'lg'
        });
        if (this.addPackageForm.get('receiverLocation').value) {
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.location = this.addPackageForm.get('receiverLocation').value.value;
        } else if (this.addPackageForm.get('receiverAddressLine1').value && this.addPackageForm.get('receiverAddressLine1').value.value) {
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.location = this.addPackageForm.get('receiverAddressLine1').value.value;
        }

        modal.result.then(result => {
            if (result) {
                this.addPackageForm.get('receiverLocation').patchValue({
                    label: result.label || (result.address ? result.address.address.LongLabel : ''),
                    value: result.location
                });
                if (result.label ||  result.address && result.address.address.LongLabel) {
                    this.addPackageForm.get('receiverAddressLine1').patchValue({
                        label: result.label || (result.address ? result.address.address.LongLabel : ''),
                        value: result.location
                    });
                }
            }
            this.preventSubmitWhenPressEnter = false;
        });
    }

    loadAddressBook() {
        this.isLoadingReceiverAddressBook = SpinnerState.LOADING;
        this.usersService.getAddressBook({
            page: 1,
            pageSize: 1000,
            type: this.swapSenderAndReceiver ? 'SENDER' : 'RECEIVER'
        }).subscribe(
            (response: any) => {
                this.receiverAddresses = response;
                if (this.receiverAddresses && this.receiverAddresses.length) {
                    this.isLoadingReceiverAddressBook = SpinnerState.LOADED;
                } else {
                    this.isLoadingReceiverAddressBook = SpinnerState.EMPTY;
                }
            }, (error) => {
                console.error(error);
                this.isLoadingReceiverAddressBook = SpinnerState.FAILED;
            }
        );
    }

    onHideReceiverAddressBook() {
        this.receiverLocationForm.reset();
        this.tempSelectedReceiverAddressIndex = -1;
        this.isEditAddressBook = false;
    }

    toggleSelectedTab() {
        if (this.selectedTab === 'MAIN_TAB') {
            this.receiverLocationForm.reset();
            this.selectedTab = 'SECONDARY_TAB';
        } else {
            this.selectedTab = 'MAIN_TAB';
            this.isEditAddressBook = false;
        }
    }

    showEditAddress(addressIndex) {
        this.selectedTab = 'SECONDARY_TAB';
        this.isEditAddressBook = true;
        const data = this.receiverAddresses[addressIndex];
        this.editAddressId = data.id;
        this.receiverLocationForm.patchValue({
            fullName: data.fullName,
            phone1: data.phone1,
            businessName: data.businessName,
            email: data.email,
            address: {
                value: data.address.villageId,
                label: data.address.village + ' - ' + data.address.city + ' - ' + data.address.region,
                cityId: data.address.cityId,
                regionId: data.address.regionId,
                country: data.address.country
            },
            addressLine1: data.address.addressLine1
        });
    }

    removeAddress(addressIndex) {
        this.translate.get(['ALERTS.CONFIRM_ADDRESS_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
            this.confirmationService.confirm({
                message: values['ALERTS.CONFIRM_ADDRESS_DELETE'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    const addressId = this.receiverAddresses[addressIndex].id;
                    this.receiverAddresses[addressIndex].isRemovingSenderAddress = true;

                    this.shipmentService.removeSenderAddressBook(addressId).subscribe(
                        () => {
                            this.loadAddressBook();
                        }, (error) => {
                            console.error(error);
                        }, () => {
                            this.receiverAddresses[addressIndex].isRemovingSenderAddress = false;
                        }
                    );
                }
            });
        });
    }

    setReceiverInfoFromAddressBook(data) {
        this.addPackageForm.get('receiverName').setValue(data.fullName);
        this.addPackageForm.get('receiverPhone').setValue(data.phone1);

        this.updateFormValue('receiverAddress', {
            value: data.address.villageId,
            label: data.address.village + ' - ' + data.address.city + ' - ' + data.address.region,
            cityId: data.address.cityId,
            regionId: data.address.regionId,
            country: data.address.country
        });

        this.addPackageForm.get('receiverAddress').updateValueAndValidity();

        if (this.hasRouteOptimization) {
            this.updateFormValue('receiverAddressLine1', {
                label: data.address.addressLine1,
            });
        } else {
            this.addPackageForm.get('receiverAddressLine1').setValue(data.address.addressLine1);
        }
        this.addPackageForm.get('receiverAddressLine1').updateValueAndValidity();
        this.selectedReceiverAddress = data.address;
        this.getDeliveryPrice();
    }

    setNewReceiverInfo() {
        if (this.tempSelectedReceiverAddressIndex >= 0) {
            this.setReceiverInfoFromAddressBook(this.receiverAddresses[this.tempSelectedReceiverAddressIndex]);
            this.receiverAddressBook.hide();
        }
    }

    closeReceiverInfo() {
        this.receiverAddressBook.hide();
    }

    addEditReceiverAddress() {

        if (this.receiverLocationForm.invalid) {
            this.utilitiesService.validateAllFormFields(this.receiverLocationForm);
            return;
        }

        this.isSubmittingSenderAddress = true;

        const data = this.receiverLocationForm.getRawValue();
        const body = {
            phone1: data.phone1,
            addressId: data['address'].id,
            address: {
                regionId: data['address'].regionId,
                cityId: data['address'].cityId,
                villageId: data['address'].value,
                addressLine1: data['addressLine1']
            },
            firstName: data.firstName,
            lastName: '.',
            email: data.email,
            businessName: data.businessName,
            type: this.swapSenderAndReceiver ? 'SENDER' : 'RECEIVER'
        };

        if (!this.isEditAddressBook) { // Add new
            this.shipmentService.AddSenderAddressBook(body).subscribe(
                () => {
                    this.toggleSelectedTab();
                    this.loadAddressBook();
                }, (error) => {
                    this.isSubmittingSenderAddress = false;
                    console.error(error);
                }, () => {
                    this.isSubmittingSenderAddress = false;
                }
            );
        } else { // Edit existing
            this.shipmentService.editSenderAddressBook(this.editAddressId, body).subscribe(
                () => {
                    this.toggleSelectedTab();
                    this.loadAddressBook();
                }, (error) => {
                    this.isSubmittingSenderAddress = false;
                    console.error(error);
                }, () => {
                    this.isSubmittingSenderAddress = false;
                }
            );
        }
    }

    showReceiverInfo(event) {
        this.selectedTab = 'MAIN_TAB';
        this.receiverAddressBook.toggle(event);
        this.loadAddressBook();
    }

    createReceiverLocationForm() {

        this.receiverLocationForm = this.formBuilder.group({
            firstName: ['', [Validators.required]],
            phone1: ['', [Validators.required,
               this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144)),
                Validators.maxLength(14),
                Validators.minLength(8)]],
            businessName: [''],
            email: ['', Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')],
            address: ['', Validators.required],
            addressLine1: ['', Validators.required]
        });
    }
    setPackageContents(event) {
        this.addPackageForm.get('packageContents').patchValue(event.contents);
    }

    isInvalidDirtyTouched(formName: string) {
        const formControl = this.addPackageForm.get(formName);
        if (formControl) {
            return formControl.invalid && formControl.dirty && formControl.touched;
        } else {
            return false;
        }
    }

    isInvalidWithError(formName: string, errorName: string) {
        const formControl = this.addPackageForm.get(formName);
        if (formControl && formControl.errors) {
            return this.isInvalidDirtyTouched(formName) && formControl.errors[errorName] !== undefined;
        } else {
            return false;
        }
    }

    getDrivers(event) {
        let query = '?page=1&pageSize=20&driverType=TYPICAL';
        if (event.query) {
            query += '&search=' + event.query;
        }
        return this.driversService.getDrivers(query).subscribe(
            (res: any) => {
                this.driversOptions = res.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {label: name, value: driver.id};
                    }
                );

            });
    }

    private initCurrenciesDropdownList() {
        this.currencies = LOCALE_CONSTANTS.CURRENCIES;
    }

    private initDefaultShipmentServiceType() {
        if (this.companyId === 139 || this.companyId === 221) { //  139 vee company, 221 alhadaf company
            const serviceTypes = this.companyId === 139 ? SHARED_CONSTANTS.STATIC_SERVICE_TYPES.VEE_SERVICE_TYPES : SHARED_CONSTANTS.STATIC_SERVICE_TYPES.ALHADAF_SERVICE_TYPES;
            this.translate.get(serviceTypes).subscribe(values => {
                this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                    return {
                        label: values[translationKey],
                        value: translationKey
                    };
                });
            });
        } else {
            this.translate.get(this.isLcl ? SHARED_CONSTANTS.STATIC_SERVICE_TYPES.LCL_SERVICE_TYPES
                : SHARED_CONSTANTS.STATIC_SERVICE_TYPES.GENERAL_SERVICE_TYPES).subscribe(values => {
                this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                    return {
                        label: values[translationKey],
                        value: translationKey
                    };
                });
            });
        }

    }

    private initDefaultShipmentType() {
        let ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.GENERAL_SHIPMENT_TYPES;
        let value = '';
        if (this.companyId === 209) { // sare3 company
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.SARE3_SHIPMENT_TYPES;
        }
        if (this.isLcl) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.LCL_SHIPMENT_TYPES;
        } else if (this.isTrucking) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.TRUCKING_SHIPMENT_TYPES;
            value = 'TRUCKING.SHIPMENT_TYPE.';
        }

        if (this.isJettCompany() && this.addPackageForm.get('orderId').value === 'STORE_TO_STORE') {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.JETT_STORE_TO_STORE;
        } else if (this.isJettCompany()) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.JETT_OTHER_SHIPMENTS_TYPES;
        }
        this.translate.get(ShipmentTypes.map(type => `${value}${type}` ))
            .subscribe(values => {
                this.shipmentTypesOptions = ShipmentTypes.map(type => {
                    return {
                        label: values[`${value}${type}`],
                        value: type
                    };
                });
        });
    }

    private handleServiceAndShipmentTypePreFill(customerId) {
        this.usersService.getCustomer(customerId).subscribe(
            (response: any) => {
                if (response.serviceTypes) {
                    this.translate.get(response.serviceTypes).subscribe(values => {
                        this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                            return {
                                label: values[translationKey],
                                value: translationKey
                            };
                        });
                    });
                }
                if (this.isJettCompany()) {
                    this.initDefaultShipmentType();
                } else {
                    this.translate.get(response.shipmentTypes).subscribe(values => {
                        this.shipmentTypesOptions = Object.keys(values).filter(key => (!this.isLcl && key !== 'PAID_COD') || this.isLcl).map(translationKey => {
                            return {
                                label: values[translationKey],
                                value: translationKey
                            };
                        });
                    });
                }
            }, () => {
                this.receiverLoading = false;
            }
        );
    }

    private initCodFees() {
        const customer = this.addPackageForm.get('customerInfo').value;
        if (this.userService.getCurrency() === 'SAR') {
            this.addPackageForm.get('codFees').patchValue(customer?.codFees);
        }
    }
    getSidebarClassName() {
        if (this.isHideDetailInAddingPackage) {
            return 'add-package-sidebar hidden-package-details-sidebar';
        }
        return 'add-package-sidebar';
    }

    getHubs(event: any) {
        this.isLoadingHubs = true;
        let query = '?page=1&pageSize=20';
        if (!this.companyInfo.isAllowAddingPackagesOnAllHub) {
            query += '&is-adding-package=true';
        }
        this.hubsService.getHubs(query, event && event.query ? event.query : '').subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
                this.isLoadingHubs = false;
            }, () => {
                this.isLoadingHubs = false;
            }
        );
    }

    removeAttachment(attachment: any) {
        for (let i = 0; i < this.files.length; i++) {
            if (this.files[i] === attachment) {
                this.files.splice(i, 1);
            }
        }
        this.fileChooser.nativeElement.value = '';
    }

    fileChange($event: Event) {
        // @ts-ignore
        for (let i = 0; i < $event.target.files.length && this.files.length < 5 - this.numberOfFiles; i++) {
            // @ts-ignore
            if (i < (5 - this.numberOfFiles) && $event.target.files[i].type.includes('image/')) {
                // @ts-ignore
                this.files.push($event.target.files[i]);
            }
        }
    }

    uploadAttachments(pkg) {
        if (!this.files.length) {
            return;
        }

        const data = new FormData();
        for (let x = 0; x < this.files.length; x++) {
            data.append('files', this.files[x]);
        }
        this.usersService.uploadPackageAttachments(pkg.id, data, (this.isEditMode && !this.isCloning) ? 'EDIT_PACKAGE' : 'CREATE_PACKAGE').subscribe(
            (response: any) => {
            }, (error) => {
                console.error(error);
            }
        );
    }

    showTotalCOD() {
        return ['SWAP', 'BRING'].indexOf(this.addPackageForm.controls.paymentType.value) !== -1 && this.addPackageForm.controls.isReceiverPayCost.value;
    }

    isJettCompany() {
        return this.customizationCompaniesService.checkCompanies(this.companies.JETT);
    }

    changeOrderId() {
        if (this.addPackageForm.controls.paymentType.value !== 'COD_V2') {
            this.addPackageForm.get('isSenderPayCost').setValue(false);
        }
        this.initDefaultShipmentType();
        if (this.addPackageForm.controls.orderId.value === 'STORE_TO_DOOR_B') {
            this.addPackageForm.get('toHub').removeValidators(Validators.required);
            this.addPackageForm.removeControl('toHub');
        } else if (this.addPackageForm.controls.orderId.value === 'OUTBOUND') {
            this.addPackageForm.get('toHub').removeValidators(Validators.required);
        } else {
            this.addPackageForm.get('toHub').setValidators(Validators.required);
        }
        this.addPackageForm.get('toHub').reset();

        if (this.addPackageForm.controls.orderId.value === 'STORE_TO_STORE') {
            const address = this.toCityList.filter(hub => hub.value === this.addPackageForm.controls.toHub.value)[0]?.address;
            if (address) {
                this.setReceiverInfoFromAddressBook({ address });
            }
            this.addPackageForm.get('isSenderPayCost').setValue(true);
        }

        this.getDeliveryPrice();
    }

    public changePreventSubmitWhenPressEnter(value: boolean) {
        this.preventSubmitWhenPressEnter = value;
    }

    public changeJettToHub(event) {
        if (this.addPackageForm.controls.orderId.value === 'STORE_TO_STORE') {
            this.setReceiverInfoFromAddressBook({
                address : this.toCityList.filter(hub => hub.value === this.addPackageForm.controls.toHub.value)[0].address,
                fullName: this.addPackageForm.value.receiverName,
                phone1: this.addPackageForm.value.receiverPhone
            });
        }
    }

    updateInsuranceCod($event) {
        if (this.isSupportAdditionalFees) {
            this.usersService.getInsuranceValue(this.addPackageForm?.get('customerInfo')?.value?.id, this.addPackageForm?.get('cod')?.value, this.addPackageForm?.get('isInsured')?.value).subscribe((res: any) => {
                console.log(res);
                if (this.isSupportAdditionalFees && this.addPackageForm?.controls?.isInsured?.value) {
                    this.insuranceValue = res.insurance || '0';
                } else {
                    this.insuranceValue = 0;
                }
                this.codValue = res.cod || '0';
            });
        }
    }

    handleVillageChanged($event: any) {
        if (this.isSupportVillageHub) {
            this.zonesService.getZoneHub($event.id).subscribe(value => {
                this.villageHub = value;
            });
        }
    }

    clearDestinationHub($event: any) {
        this.villageHub = null;
    }
}

// use senderName, receiverName when adding package.
