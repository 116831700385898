import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TicketingSystemService} from '../../../services/ticketing-system.service';
import {UsersService} from '../../../../users/services/users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CategoryModel} from '../../../models/category-model';
import {$e} from 'codelyzer/angular/styles/chars';
import {UserModel} from '../../../models/ticket-model';

@Component({
    selector: 'app-category-form',
    templateUrl: 'category-form.component.html',
    styleUrls: ['category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {
    selectedLang;
    usersList: any[] = [];
    selectedUsersList: { value: string, label: string }[] = [];
    categoryForm: FormGroup;
    editClientName = false;
    selectedClientInfo: any;
    isSubmittingForm: boolean;
    isEditMode: boolean;
    category: CategoryModel;
    @ViewChild('clientName') clientName;
    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private ticketingSystemService: TicketingSystemService,
                private translateService: TranslateService,
                private usersService: UsersService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.selectedLang = this.translateService.currentLang;
        if (this.category) {
            this.ticketingSystemService.getUsersByCategoryId(this.category.id).subscribe(
                (response: UserModel[]) => {
                    if (response &&  response.length > 0 && response[0].isManager) {
                        this.category.selectedUserManagerId = response[0].id;
                    }
                    this.category.users = response;
                    this.initCategoryForm();
                }, error => {
                }
            );
            this.isEditMode = true;
        } else {
            this.initCategoryForm();
        }
        this.getClients('');
    }

    initCategoryForm() {
        let info = {
            name: '',
            description: '',
            level: false
        };
        if (this.isEditMode) {
            // this.disableRest = true;
            info = <any>{
               name: this.category.name,
               description: this.category.description,
               level: this.category.level,
               usersIds: this.getUsers(this.category.users),
               selectedUserManagerId: this.category.selectedUserManagerId ? this.category.selectedUserManagerId : null
            };
            this.selectedClientInfo = this.category.users;
            for (const user of this.selectedClientInfo) {
                this.onSelect(user, true);
            }
        }
        this.categoryForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: [''],
            // level: ['', Validators.required],
            level: [''],
            usersIds: [[]],
            selectedUserManagerId: []
        });
        this.categoryForm.patchValue(info);
    }
    editCategory(body) {
        this.ticketingSystemService.editCategory(body).subscribe(
            (res: any) => {
                this.isSubmittingForm = false;
                this.activeModal.close({isSuccess: true, message: 'TICKETING_SYSTEM.CATEGORIES.ALARMS.CATEGORY_UPDATED_SUCCESSFULLY'});
            }, error => {
                this.activeModal.close({isSuccess: false});
            }
        );
    }
     submitForm() {
        if (this.isSubmittingForm) {
            return;
        }

        this.isSubmittingForm = true;
        if (this.categoryForm.invalid) {
            this.isSubmittingForm = false;
            return;
        }
        const data = this.categoryForm.getRawValue();

        const body = {
            name : data.name,
            description : data.description,
            // level: data.level,
            level: 'PRIMARY',
            usersIds: data.usersIds,
            selectedUserManagerId: data.selectedUserManagerId ? data.selectedUserManagerId : 0
        };
        if (this.isEditMode) {
            body['id'] = this.category.id;
            this.editCategory(body);
            return;
        }
        this.ticketingSystemService.addCategory(body).subscribe(
            (response: any) => {
                this.isSubmittingForm = false;
                this.activeModal.close({isSuccess: true, message: 'TICKETING_SYSTEM.CATEGORIES.ALARMS.CATEGORY_ADDED_SUCCESSFULLY'});
            }, error => {
                console.error(error);
                this.activeModal.close({isSuccess: false});
                this.isSubmittingForm = false;
            }
        );
    }
    public getClients(search: string) {
        let query = '?page=1&pageSize=100&search=' + search;
        if (this.isEditMode) {
            query += '&categoryId=' + this.category.id;
        }
        this.usersService.getUsersForCategory(query).subscribe(
            (response: any) => {
                this.usersList = response;
            }, (error) => {
                console.error(error);
            }
        );
    }

    public clearFormData() {
        this.categoryForm.reset();
        this.selectedClientInfo = null;
    }
    public onClose() {
        this.clearFormData();
        this.isSubmittingForm = false;
        this.activeModal.close({});
    }
    public getUsers(users) {
        return users.map(hub => hub.id);
    }
    public onSelect($event, isAdd) {
        let obj;
        if (isAdd) {
            obj = {value: $event.id, label: $event.fullName};
            this.selectedUsersList.push(obj);
        } else {
            obj = {value: $event.value.id, label: $event.value.fullName};
            if (this.categoryForm.controls['selectedUserManagerId'].value === obj.value) {
                this.categoryForm.controls['selectedUserManagerId'].setValue(null);
                this.categoryForm.controls['selectedUserManagerId'].updateValueAndValidity();
            }
            const index = this.selectedUsersList.findIndex((element) => element.value === obj.value);
            this.selectedUsersList.splice(index, 1);
        }
    }
}
