<div>
  <div class="header">
    <i class="fas fa-times cursor-pointer close-icon" (click)="closeModal()"></i>
    {{"ACTIONS.VERIFY" | translate }}
  </div>
  <div class="col" style="margin: 5px">
    <div style="margin: 10px">
      <span>{{"GENERAL.PRINT_REPORT" | translate}}</span>
    </div>
    <div style="margin: 10px">
      <p-dropdown [options]="printOptions" [(ngModel)]="printType" >

      </p-dropdown>
    </div>
  </div>
  <div class="row col-12">
    <div class="flex-1"></div>
    <div style="padding: 10px">
      <div class="reset-button cursor-pointer " (click)="closeModal()">
        <i class="fas fa-times"></i>
        {{"ACTIONS.CANCEL" | translate}}
      </div>
      <div class="add-button cursor-pointer "
           (click)="choose()">
        <i class="far fa-save"></i>
        {{'ACTIONS.OK' | translate }}
      </div>
    </div>
  </div>
</div>
