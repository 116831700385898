export const SHIPPING_RATES_CONSTANTS = {
        shipmentsTabs: [
            {label: 'SHIPPING.QUOTES', value: 'quotes'},
            {label: 'SHIPPING.PRICING_CATEGORIES', value: 'pricing-categories'},
            {label: 'SHIPPING.SHIPPING_MECHANISMS', value: 'shipping-mechanisms'},
            {label: 'SHIPPING.SHIPMENT_TYPES', value: 'shipment-types'}
        ],
        GET_COMPANY_PRICES: ':api/accountant/pricing/company-prices',
        GET_JETT_PRICES: ':api/accountant/pricing/jett-prices',
        GET_HUBS_PRICES: ':api/accountant/pricing/hub-prices',
        GET_CUSTOMER_PRICES: ':api/accountant/pricing/customers/{customerId}',
        EXPORT_CUSTOMER_PRICES: ':api/accountant/pricing/customers/{customerId}/excel',
        SAVE_COMPANY_PRICE: ':api/accountant/pricing/company-price',
        SAVE_CUSTOMER_PRICE: ':api/accountant/pricing/customer-price',
        SAVE_ADDITION_FEE: ':api/accountant/pricing/additional-package-fees',
        SAVE_ADDITION_FEE_RANGE: ':api/accountant/pricing/additional-package-fees/{feeId}/details',
        EDIT_ADDITION_FEE: ':api/accountant/pricing/additional-package-fees/{feeId}',
        EDIT_ADDITION_FEE_DETAILS: ':api/accountant/pricing/additional-package-fees/details/{detailId}',
        DELETE_CUSTOMER_PRICE: ':api/accountant/pricing/customers/{customerId}',
        DELETE_HUB_PRICE: ':api/accountant/pricing/hubs/{hubId}',
        DELETE_PRICE_LIST: ':api/accountant/pricing/pricing-list/{pricingListId}',
        DELETE_SERVICE_PRICE: ':api/accountant/pricing/{priceId}',
        EDIT_COMPANY_PRICE: ':api/accountant/pricing/{priceId}',
        DELETE_COMPANY_PRICE: ':api/accountant/pricing/{priceId}',
        GET_CUSTOMERS_PRICES: ':api/accountant/pricing/customers',
        GET_DRIVER_EARNING: ':api/accountant/pricing/driver-earnings',
        GET_SERVICE_TYPES_PRICES: ':api/accountant/pricing/company-prices/by-service-type',
        GET_ADDITIONAL_FEES: ':api/accountant/pricing/additional-package-fees',
        GET_PRICING_LIST_PRICES: ':api/accountant/pricing/pricing-list/{pricingListId}',
        GET_HUB_PRICING_LIST: ':api/accountant/pricing/hubs/{hubId}',
        SAVE_PRICING_LIST_PRICE: ':api/accountant/pricing/pricing-list-price',
        GET_PRICING_LIST: ':api/accountant/pricing/pricing-lists',
        ADD_PRICING_LIST: ':api/accountant/pricing/pricing-lists',
        GET_DRIVERS_PRIVATE_PRICING: ':api/accountant/pricing/driver-earnings/drivers',
        DELETE_DRIVER_EARNING_BY_ID: ':api/accountant/pricing/driver-earnings/{driverEarningId}',
        PUT_DRIVER_EARNING_BY_ID: ':api/accountant/pricing/driver-earnings/{driverEarningId}',
        GET_SHIPPING_RATES_HISTORY: ':api/accountant/pricing/history'

    }
;
