import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';

@Injectable({
    providedIn: 'root'
})
export class ShippingLinesService {

    constructor(private httpClient: HttpClient) {
    }

    public getShippingLines(type) {
        return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_SHIPPING_LINES, {params: {type}});
    }

    public getShippingLine(id) {
        return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_SHIPPING_LINES + '/' + id);
    }

    public editShippingLine(id, selectedVillages) {
        const villages = selectedVillages.map((element) => {
            return {'id': element.id};
        });
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.EDIT_SHIPPING_LINE.replace('{shippingLineId}', id), {
            'villages': selectedVillages
        });
    }

    public addVillageToLine(shippingLineId, villageId, type) {
        return this.httpClient.post(ADMINISTRATION_CONSTANTS.ADD_VILLAGE_TO_LINE.replace('{shippingLineId}',
            shippingLineId).replace('{villageId}', villageId), {}, {params: {type}});
    }

    public removeVillageFromLine(shippingLineId, villageId, type) {
        return this.httpClient.delete(ADMINISTRATION_CONSTANTS.ADD_VILLAGE_TO_LINE.replace('{shippingLineId}',
            shippingLineId).replace('{villageId}', villageId), {});
    }

    public addShipmentLine(line_name, selectedVillages, type) {
        const villages = selectedVillages.map((element) => {
            return {'id': element.id};
        });
        return this.httpClient.post(ADMINISTRATION_CONSTANTS.ADD_SHIPPING_LINE, {
            'name': line_name,
            'villages': selectedVillages,
            type
        });
    }

    public editShipmentLine(id, data, type) {
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.EDIT_SHIPPING_LINE.replace('{shippingLineId}', id), data);
    }

    public setDriverIdForShippingLine(driverId, shippingLineId) {
        const url = ADMINISTRATION_CONSTANTS.SET_DRIVER_ID_FO_SHIPPING_LINE.replace('{driverId}', driverId);
        return this.httpClient.put(url.replace('{shippingLineId}', shippingLineId), {});
    }
}
