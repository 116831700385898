import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricingsComponent} from './pricings.component';
import {SharedModule} from '../shared/modules/shared.module';

import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {TreeTableModule} from 'primeng/treetable';
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';

@NgModule({
    imports: [
        SharedModule,
        
        CommonModule,
        TableModule,
        ToastModule,
        InputTextModule,
        TreeTableModule,
        AccordionModule,
        FieldsetModule,
        DropdownModule,
        OverlayPanelModule
    ],
    declarations: [PricingsComponent]
})
export class PricingsModule {
}
