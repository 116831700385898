import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-pricing-type',
  templateUrl: './pricing-type.component.html',
  styleUrls: ['./pricing-type.component.scss']
})
export class PricingTypeComponent implements OnInit {
  isHubsPricing = false;
  role = '';

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
    this.isHubsPricing = this.userService.userInfo.isEnableExportingBetweenHubs && this.role === 'HUB_ADMIN';
  }

}
