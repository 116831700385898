import {Component, OnInit, ViewChild} from '@angular/core';
import {ADMINISTRATION_CONSTANTS} from '../../administration-constants';
import {UserService} from '../../../shared/services/user.service';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-manage-zones',
    templateUrl: './manage-zones.component.html',
    styleUrls: ['./manage-zones.component.scss']
})
export class ManageZonesComponent implements OnInit {

    public isReceivingPointEnabled: boolean;
    public regionID: number = 0;
    public cityID: number = 0;
    public villageID: number = 0;
    public initialRegion = true;
    public initialCity = true;
    @ViewChild('regions', {static: false}) regions;
    @ViewChild('cities', {static: false}) cities;

    public regionsURL = ADMINISTRATION_CONSTANTS.GET_REGIONS;
    public citiesURL = '';
    public villagesURL = '';

    public addRegionURL = ADMINISTRATION_CONSTANTS.ADD_NEW_REGION;
    public addCityURL = ADMINISTRATION_CONSTANTS.ADD_NEW_CITY;
    public addVillageURL = ADMINISTRATION_CONSTANTS.ADD_NEW_VILLAGE;
    public getCitiesUnassigned = ADMINISTRATION_CONSTANTS.GET_CITIES_NOT_ASSIGNED;
    public getVillagesUnassigned = ADMINISTRATION_CONSTANTS.GET_VILLAGES_NOT_ASSIGNED;
    public assignCityURL = ADMINISTRATION_CONSTANTS.ASSIGN_CITY;
    public assignVillageURL = ADMINISTRATION_CONSTANTS.ASSIGN_VILLAGE;
    public selectRegion = ADMINISTRATION_CONSTANTS.SELECT_REGION;
    public unselectRegion = ADMINISTRATION_CONSTANTS.UNSELECT_REGION;
    public selectCity = ADMINISTRATION_CONSTANTS.SELECT_CITY;
    public unselectCity = ADMINISTRATION_CONSTANTS.UNSELECT_CITY;
    public selectVillage = ADMINISTRATION_CONSTANTS.SELECT_VILLAGE;
    public unselectVillage = ADMINISTRATION_CONSTANTS.UNSELECT_VILLAGE;
    public villageLocation = ADMINISTRATION_CONSTANTS.VILLAGE_LOCATION;
    public unassignCityURL = ADMINISTRATION_CONSTANTS.UNASSIGN_CITY;
    public unassignVillageURL = ADMINISTRATION_CONSTANTS.UNASSIGN_VILLAGE;
    public deleteRegionURL = ADMINISTRATION_CONSTANTS.DELETE_REGION;
    public allVillagesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES;
    public unassignMultipleVillagesURL = ADMINISTRATION_CONSTANTS.UNASSIGN_MULTIPLE_VILLAGE;
    public unassignMultipleCitiesURL = ADMINISTRATION_CONSTANTS.UNASSIGN_MULTIPLE_CITIES;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    isExcludeSystemZones = false;
    constructor(private userService: UserService) {
    }

    ngOnInit() {
        this.isReceivingPointEnabled = this.userService.userInfo.isReceivingPointEnabled;
        this.isExcludeSystemZones = this.userService.userInfo.isExcludeSystemZones;
    }

    onRegionChosen($event: any) {
        const id = $event.id;
        if (id) {
            this.citiesURL = ADMINISTRATION_CONSTANTS.GET_REGION_CITIES.replace('{regionId}', id);
            this.assignCityURL = ADMINISTRATION_CONSTANTS.ASSIGN_CITY.replace('{regionId}', id);
            this.villagesURL = '';
            this.regionID = id;
            if (this.initialRegion) {
                this.initialRegion = false;
            } else {
                this.allVillagesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES + '?regionId=' + this.regionID;
            }
        }
    }

    onCityChosen($event: any) {
        const id = $event.id;
        if (id) {
            this.villagesURL = ADMINISTRATION_CONSTANTS.GET_CITY_VILLAGES.replace('{cityId}', id);
            this.assignVillageURL = ADMINISTRATION_CONSTANTS.ASSIGN_VILLAGE.replace('{cityId}', id);
            this.cityID = id;
            if (this.initialCity) {
                this.initialCity = false;
            } else {
                this.allVillagesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES + '?regionId=' + this.regionID +
                    '&cityId=' + this.cityID;
            }
        }
    }

    onResetZones() {
        this.villagesURL = '';
        this.citiesURL = '';
        this.regionID = 0;
        this.cityID = 0;
        this.villageID = 0;
        this.regions.selectedItem = 0;
        this.cities.selectedItem = 0;
        this.allVillagesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES;
    }

    onVillageChosen($event: any) {

    }

}
