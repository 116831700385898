<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="export-to-external-file">
        <div class="header d-inline-flex">
            <div class="title">
                <span>{{ 'DISTRIBUTOR_REPORTS.SELECT_A_REPORT' | translate }}</span>
            </div>
            <div class="flex-1"></div>
            <div class="close-icon" (click)="closeModal()">
                <img src="../../assets/icons/cancel.svg" alt="icon"/>
            </div>
        </div>

        <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
            <p-progressSpinner></p-progressSpinner>
        </div>

        <div id="submitting-container" *ngIf="isSubmitting">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <form>
            <div class="dialog-body p-4">
                <div class="field-checkbox">
                    <p-checkbox name="group1" value="REQUEST_ORDER" [(ngModel)]="selectedPrintType" id="ORDER_REQUEST_REPORT"></p-checkbox>
                    <label for="ORDER_REQUEST_REPORT">{{'DISTRIBUTOR_REPORTS.ORDER_REQUEST_REPORT' | translate}}</label>
                </div>
                <div class="field-checkbox">
                    <p-checkbox name="group1" value="PREPARE_ORDER" [(ngModel)]="selectedPrintType"
                                id="REPORT_OF_ORDER_PREPARATION_AND_DELIVERY_OF_THE_DRIVER"></p-checkbox>
                    <label for="REPORT_OF_ORDER_PREPARATION_AND_DELIVERY_OF_THE_DRIVER">{{'DISTRIBUTOR_REPORTS.REPORT_OF_ORDER_PREPARATION_AND_DELIVERY_OF_THE_DRIVER' | translate}}</label>
                </div>
                <div class="field-checkbox">
                    <p-checkbox name="group1" value="DELIVER_ORDER" [(ngModel)]="selectedPrintType" id="KITCHEN_DELIVERY_REPORT"></p-checkbox>
                    <label for="KITCHEN_DELIVERY_REPORT">{{'DISTRIBUTOR_REPORTS.KITCHEN_DELIVERY_REPORT' | translate}}</label>
                </div>
            </div>

        </form>
        <div>
            <div class="row bottom-actions">
                <div class="row actions d-inline-flex">
                    <div class="cancel-action col-xs-12 d-flex" (click)="closeModal()">
                        <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.CANCEL' | translate}}</span>
                    </div>
                    <div class="done-action d-flex col-xs-12" (click)="onSubmit()"
                         [ngClass]="{'disabled-btn disabled': isSubmitting || selectedPrintType.length == 0}">
                        <span>{{'ACTIONS.PRINT' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
