<ng-template #editGuidelinePopOver>
    <div class="line">{{'RECEIVE_FROM_DRIVER.GUIDELINE_DETAILS.LINE_1' | translate}}</div>
    <div class="line">{{'RECEIVE_FROM_DRIVER.GUIDELINE_DETAILS.LINE_2' | translate | hubTitle}}</div>
    <div class="line">{{'RECEIVE_FROM_DRIVER.GUIDELINE_DETAILS.LINE_3' | translate | hubTitle}}</div>
    <div class="line">{{'RECEIVE_FROM_DRIVER.GUIDELINE_DETAILS.LINE_4' | translate | hubTitle}}</div>
</ng-template>
<ng-template #enterBarcodesGuidelinePopOver>
    <div class="add-barcodes-guideline-title">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.TITLE' | translate}}</div>
    <div class="add-barcodes-guideline-line d-inline-flex">
        <div class="tick-icon">
            <img src="../../../assets/icons/tick.svg" alt="" />
        </div>
        <div style="width: 10px;"></div>
        <div class="line">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.ENTER_BARCODES' | translate}}</div>
    </div>
    <div class="add-barcodes-guideline-line d-inline-flex">
        <div class="tick-icon">
            <img src="../../../assets/icons/tick.svg" alt="" />
        </div>
        <div style="width: 10px;"></div>
        <div class="line">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.SCAN_BARCODES' | translate}}</div>
    </div>
</ng-template>
<ng-template #changePackageStatusDialog>
    <div>
    <app-change-package-status-component
            [isPrintReportAfterChangeStatus] = "true"
            [shipments]="objectValues(scannedPackages)"
            [isShowCurrentStatus]="false"
            (onFinish)="closeChangePackageStatusPopOver(changePackageStatusPopOver, $event)"
            (onCancel)="closeChangePackageStatusPopOver(changePackageStatusPopOver, $event)"
    >
    </app-change-package-status-component>
    </div>
</ng-template>

<div class="close-icon" (click)="finish(true)"><img src="../../../assets/icons/close-icon.svg" alt="" /></div>
<!--<div class="row d-inline-flex main-container" [ngClass]="{'read-by-barcode-main-container': !isReceiverFromDriver}">s</div>-->
<div [ngClass]="{'page-ltr': currentLang === 'en'}"
     appBarcodeScanner (barcodeRead)="barcodeReader($event)">
    <div class="row d-inline-flex main-container" [ngClass]="{'read-by-barcode-main-container': isReadByBarcode}">
        <div id="submitting-container" *ngIf="isLoading">
            <p-progressSpinner> </p-progressSpinner>
        </div>
        <div *ngIf="isReadByBarcode" class="barcodes-menu">
            <div class="read-by-barcode-label row" [ngClass]="{'receive-driver-label-en' : currentLang === 'en'}">
                <div class="read-by-barcode-label-hidden-space"></div>
                <div *ngIf="!isUpdateReceiverInfo" class="label">{{'READ_BY_BARCODE.READ_BY_BARCODE_TITLE' | translate}}</div>
                <div *ngIf="isUpdateReceiverInfo" class="label">{{'READ_BY_BARCODE.EDIT_RECEIVER_INFO_TITLE' | translate}}</div>
            </div>
            <div class="list-and-action">
                <div class="row d-inline-flex barcodes-list-label">
                    <div class="label">{{'READ_BY_BARCODE.BARCODES_LIST' | translate}}</div>
                    <div style="width: 10px;"></div>
                    <div class="icon" placement="left" autoClose="outside" [ngbPopover]="enterBarcodesGuidelinePopOver">
                        <img style="width: 100%;" src="../../../assets/icons/info.svg" alt="info-Image" />
                    </div>
                </div>
                <div class="barcodes-list">
                    <div class="add-barcode">
                        <div class="row d-inline-flex enter-barcode">
                            <div class="icon-and-number d-inline-flex">
                                <div class="scan-icon">
                                    <img style="width: 100%;" src="../../../assets/icons/barcode-scanner.svg" alt="search-Image" />
                                </div>
                                <div style="width: 10px;"></div>
                                <div class="barcode-number" [ngClass]="{'empty-barcode-number': !enteredBarcode}">
                                    <input type="text" (keydown.enter)="scanBarcode(enteredBarcode)" [(ngModel)]="enteredBarcode" placeholder="{{'READ_BY_BARCODE.ENTER_NUMBERS_OF_BARCODES' | translate}}" />
                                </div>
                            </div>
                            <div class="add-action" *ngIf="enteredBarcode" (click)="scanBarcode(enteredBarcode)">{{'READ_BY_BARCODE.ACTIONS.ADD' | translate}}</div>
                        </div>
                        <div class="row d-inline-flex search">
                            <div class="search-icon">
                                <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image" />
                            </div>
                            <div style="width: 10px;"></div>
                            <div class="search-input">
                                <input type="number" #searchBarcodesListInput (input)="searchBarcodesList(searchBarcodesListInput.value)" placeholder="{{'READ_BY_BARCODE.ACTIONS.SEARCH' | translate}}" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="true || !objectValues(packages).length" class="bulk">
                        <div class="bulk-add-barcodes-guideline">
                            <div class="info" *ngIf="!startReadGroupOfBarcodes">
                                <div class="add-barcodes-guideline-title line">
                                    {{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.TITLE' | translate}}
                                </div>
                                <div class="icon-and-label">
                                    <div class="add-barcodes-guideline-line d-inline-flex">
                                        <div class="tick-icon">
                                            <img src="../../../assets/icons/tick.svg" alt="" />
                                        </div>
                                        <div style="width: 10px;"></div>
                                        <div class="line">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.ENTER_BARCODES' | translate}}</div>
                                    </div>
                                </div>
                                <div class="icon-and-label">
                                    <div class="add-barcodes-guideline-line d-inline-flex">
                                        <div class="tick-icon">
                                            <img src="../../../assets/icons/tick.svg" alt="" />
                                        </div>
                                        <div style="width: 10px;"></div>
                                        <div class="line">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.SCAN_BARCODES' | translate}}</div>
                                    </div>
                                </div>
                                <div class="icon-and-label">
                                    <div class="add-barcodes-guideline-line d-inline-flex">
                                        <div class="tick-icon">
                                            <img src="../../../assets/icons/tick.svg" alt="" />
                                        </div>
                                        <div style="width: 10px;"></div>
                                        <div class="line">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.ENTER_BARCODES_SEPARATED_BY_SPACE' | translate}} <a class="clickable-text" (click)="startReadGroupOfBarcodes = true">{{'READ_BY_BARCODE.ADD_BARCODE_GUIDELINE.ENTER_GROUP' | translate}}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="multiple-barcode p-2" *ngIf="startReadGroupOfBarcodes">
                                <textarea class="w-100" rows="10" [(ngModel)]="multipleBarcode">

                                </textarea>
                                <div>
                                    <div class="finish-read-by-barcode" (click)="readGroupOfBarcode()" [ngClass]="{'disabled-btn disabled': !multipleBarcode}">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.READ' | translate}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="false && objectValues(packages).length" class="scanned-barcodes-list-container">
                        <div class="row" style="width: 100% !important; justify-content: center !important;">
                            <div class="splitter" style="width: 90%; height: 1px; background-color: rgba(151, 151, 151, 0.14); margin-top: 8px !important;"></div>
                        </div>
                        <div class="scanned-barcodes-list">
                            <div class="list-of-barcodes">
                                <div class="item row" *ngFor="let pkg of objectValuesSortedDesc(packages); trackBy: trackPackageById">
                                    <div class="item-barcode-number"><span>#{{pkg.barcode}}</span></div>
                                    <div class="delete-icon" (click)="removeFromBarcodesList(pkg)">
                                        <img src="../../../assets/icons/delete.svg" alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add-bulk-list row">
                    <!--                    <div class="add-list-action">{{'READ_BY_BARCODE.ACTIONS.ADD' | translate}}</div>-->
                </div>
            </div>
        </div>
        <div *ngIf="isReceiverFromDriver || isReceiveReturnedPackages || isReceiveFromPartner" class="drivers-hubs-menu" [ngClass]="{'right-border': currentLang === 'en'}">
            <div class="receive-driver-label row" [ngClass]="{'receive-driver-label-en' : currentLang === 'en'}">
                <label *ngIf="isReceiverFromDriver">
                    <ng-container *ngIf="!isFromThirdParty">{{'RECEIVE_FROM_DRIVER.TITLE' | translate | packageTitle}}</ng-container>
                    <ng-container *ngIf="isFromThirdParty">{{'RECEIVE_FROM_THIRD_PARTY.TITLE' | translate | packageTitle}}</ng-container>
                </label>
                <label *ngIf="isReceiveReturnedPackages">{{'RETURNED_PACKAGES.UNLOAD_RETURNED_PACKAGES' | translate | packageTitle}}</label>
                <label *ngIf="isReceiveFromPartner">{{'RECEIVE_FROM_PARTNER.TITLE' | translate | packageTitle}}</label>
            </div>
            <div class="row hubs-header-label" [ngClass]="{'hubs-header-label-en': currentLang === 'en'}">{{'RECEIVE_FROM_DRIVER.CHOOSE_HUB' | translate | packageTitle | hubTitle}}</div>
            <div class="hubs-container" [ngClass]="[isReceiveReturnedPackages ? 'hubs-container-returned-packages' : '', companyId === 22 || companyId === 256 ? 'hubs-container-without-drivers' : '']">
                <div class="search-hubs row">
                    <div class="search-input-value">
                        <input style="width: 100%;" appInputDebounced (inputDebounced)="onSearchHubs($event)" [(ngModel)]="hubSearch"
                               class="search" placeholder="{{'GENERAL.SEARCH' | translate}}..." type="text" />
                    </div>
                </div>
                <div class="hubs" [ngClass]="{'hubs-en': currentLang === 'en'}" (scroll)="onScrollHubs($event)">
                    <div *ngFor="let hub of hubs" class="hub-name cursor-pointer row" (click)="onSelectHub(hub.id)" [ngClass]="{'selected-hub': hubId === hub.id}">{{hub.name}}</div>
                    <div class="spinner-stage" *ngIf="loadingHubs">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="isReceiverFromDriver && companyId !== 22 && companyId !== 256" class="row driver-header-label" [ngClass]="{'driver-header-label-en': currentLang === 'en'}">
                <ng-container *ngIf="!isFromThirdParty">{{'RECEIVE_FROM_DRIVER.CHOOSE_DRIVER' | translate}}</ng-container>
                <ng-container *ngIf="isFromThirdParty">{{'RECEIVE_FROM_THIRD_PARTY.CHOOSE_THIRD_PARTY' | translate | packageTitle}}</ng-container>
            </div>
            <div *ngIf="isReceiverFromDriver && companyId !== 22 && companyId !== 256" class="drivers-container">
                <div class="search-drivers row">
                    <div class="search-input-value">
                        <input appInputDebounced (inputDebounced)="onSearchDrivers($event)" [(ngModel)]="driverSearch"
                               class="search" placeholder="{{'GENERAL.SEARCH' | translate}}..." type="text" />
                    </div>
                </div>
                <div class="drivers" (scroll)="onScrollDrivers($event)">
                    <div *ngFor="let driver of drivers" class="driver row cursor-pointer align-items-center" (click)="onSelectDriver(driver.id)" [ngClass]="{'selected-driver': driverId === driver.id}">
                        <div class="d-flex align-items-center">
                            <div class="driver-image margin-left-12" [ngClass]="{'margin-right-12': currentLang === 'en'}">
                                <img style="width: 100%;" class="driver-icon" src="../../../assets/icons/taxi-driver.png" alt="driver-Image" />
                            </div>
                            <div class="driver-name" [ngClass]="{'driver-name-en': currentLang === 'en'}">{{driver.name}}</div>
                        </div>
                        <div class="driver-carried-packages d-inline-flex" [ngClass]="{'driver-carried-packages-en': currentLang === 'en'}">
                            <div class="number">{{driver.countOfCarriedPackages}}</div>
                            <div style="width: 5px;"></div>
                            <div class="label">{{'RECEIVE_FROM_DRIVER.PACKAGE' | translate  | packageTitle}}</div>
                        </div>
                    </div>
                    <div class="spinner-stage" *ngIf="loadingDrivers">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <!--            partners list-->

            <div *ngIf="isReceiveFromPartner" class="row driver-header-label" [ngClass]="{'driver-header-label-en': currentLang === 'en'}">{{'RECEIVE_FROM_PARTNER.CHOOSE_PARTNER' | translate}}</div>
            <div *ngIf="isReceiveFromPartner" class="drivers-container">
                <div class="search-drivers row">
                    <div class="search-input-value">
                        <input #searchPartners (keyup)="onSearchPartners(searchPartners.value)" (keyup.enter)="onSearchPartners(searchPartners.value)" class="search" placeholder="{{'GENERAL.SEARCH' | translate}}..." type="text" />
                    </div>
                </div>
                <div class="drivers" [ngClass]="{'drivers-en': currentLang === 'en'}" (scroll)="onScrollPartnersList($event)">
                    <div *ngFor="let partner of partnersList" class="partner row cursor-pointer" (click)="onSelectPartner(partner.id)" [ngClass]="{'selected-driver': partnerId === partner.id}">
                        <div class="driver-image margin-left-12" [ngClass]="{'margin-right-12': currentLang === 'en'}">
                            <img style="width: 100%;" class="driver-icon" src="../../../assets/icons/taxi-driver.png" alt="driver-Image" />
                        </div>
                        <div class="driver-name" [ngClass]="{'driver-name-en': currentLang === 'en'}">{{partner.name}}</div>
                    </div>
                    <div class="spinner-stage" *ngIf="loadingDrivers">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
            </div>
            <!--            -->

            <div *ngIf="isReceiverFromDriver" class="row edit-packages-guideline">
                <div class="guideline-label">{{'RECEIVE_FROM_DRIVER.EDIT_PACKAGE_GUIDELINE_LABEL' | translate | packageTitle}}</div>
                <div class="guideline-icon" placement="top" autoClose="outside" [ngbPopover]="editGuidelinePopOver">
                    <img src="../../../assets/icons/info.svg" alt="" />
                </div>
            </div>
        </div>
        <div class="drivers-hub-container">
            <div *ngIf="isReadByBarcode && !isUpdateReceiverInfo && !returnedModal && !exportCODModal" class="row read-by-barcode-top-actions">
                <div class="top-button-action cursor-pointer approve-package" *appPermission="permissions.includes('{APPROVE_PACKAGES')" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}" (click)="approvePackages()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.APPROVAL' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.OF_THE_PACKAGES' | translate | packageTitle}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer assign-to-driver" *appPermission="permissions.includes('{ASSIGN_TO_DRIVER') && !isLcl && companyId !== 22 && companyId !== 256" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}" (click)="assignToDriver()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_DRIVER' | translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer assign-to-container" *appPermission="permissions.includes('{ASSIGN_TO_CONTAINER') && !isLcl && companyId !== 22 && companyId !== 256" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}" (click)="assignToContainer()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_VEHICLE' | translate}}
                    </span>
                </div>
                <div class="change-status-popover">
                    <div
                            class="top-button-action cursor-pointer"
                            *appPermission="permissions.includes('{CHANGE_PACKAGE_STATUS') || ((companyId === 22 || companyId === 256) && userInfo.role === 'CLERK')"
                            [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                            #changePackageStatusPopOver="ngbPopover"
                            placement="bottom-left"
                            autoClose="outside"
                            [ngbPopover]="changePackageStatusDialog"
                            [placement]="'bottom'"
                    >
                        <span>
                            {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.CHANGE' | translate}}
                            <br />
                            {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.STATUS' | translate}}
                        </span>
                    </div>
                </div>
                <div class="top-button-action cursor-pointer" (click)="openPrintDialog()" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.REPORTS' | translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer export-to-partner" (click)="exportPackagesTo()" *appPermission="permissions.includes('{TRANSFER') && !isLcl" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                    <span *ngIf="!isEnableExportingBetweenHubs">
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_PARTNER' | translate}}
                    </span>
                    <div *ngIf="isEnableExportingBetweenHubs" class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_SHIPMENT_TO'| translate}}</div>
                </div>

                <div class="top-button-action cursor-pointer export-to-partner" (click)="transferToStorm()" *ngIf="companyId === 41" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.STORM' | translate}}
                    </span>
                </div>

                <div *ngIf="isLcl" class="top-button-action cursor-pointer export-to-partner" (click)="enterContainerNumber()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'LCL.ACTIONS.ADD_TO_CONTAINER'| translate}}
                    </span>
                </div>

                <div *ngIf="companyId === 153" class="top-button-action cursor-pointer export-to-partner" (click)="transferToMughrabe()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_MUGHRABE'| translate}}
                    </span>
                </div>
                <div *ngIf="customizationCompaniesService.checkCompanies(companies.SEND, companies.LOGESTECHS, companies.DRB)"
                     class="top-button-action cursor-pointer export-to-partner" (click)="printJandTReport()"
                     [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_J_AND_T'| translate}}
                    </span>
                </div>
                <div *ngIf="customizationCompaniesService.checkCompanies(companies.SEND, companies.DRB, companies.LOGESTECHS)"
                     class="top-button-action cursor-pointer export-to-partner" (click)="printSmbReport()"
                     [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_SMB_REPORT'| translate}}
                    </span>
                </div>

                <div *ngIf="companyId === 160" class="top-button-action cursor-pointer export-to-partner" (click)="transferToOustol()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_OUSTOL'| translate}}
                    </span>
                </div>
                <div *ngIf="companyId === 160" class="top-button-action cursor-pointer export-to-partner" (click)="transferToAutobahn()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_AUTOBAHN'| translate}}
                    </span>
                </div>
                <ng-container *ngFor="let btn of transferToList">
                    <div class="top-button-action cursor-pointer export-to-partner"
                         (click)="genericTransferTo(btn)"
                         [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{btn.label | async}}
                    </span>
                    </div>
                </ng-container>

                <div *ngIf="companyId === 236" class="top-button-action cursor-pointer export-to-partner" (click)="transferToAlkhal()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_ALKHAL'| translate}}
                    </span>
                </div>

                <div *ngIf="companyId === 153" class="top-button-action cursor-pointer export-to-partner" (click)="transferToHiExpress()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_HI_EXPRESS'| translate}}
                    </span>
                </div>
                <div *ngIf="companyId === 254" class="top-button-action cursor-pointer export-to-partner" (click)="transferToAman()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_AMAN'| translate}}
                    </span>
                </div>
                <div  *ngIf="userInfo && userInfo.isEnableImileTransfer" class="top-button-action cursor-pointer export-to-partner" (click)="transferToIMile()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_IMILE'| translate}}
                    </span>
                </div>

                <div  *ngIf="userInfo && customizationCompaniesService.checkCompanies(companies.SEND, companies.LOGESTECHS)" class="top-button-action cursor-pointer export-to-partner" (click)="transferToSMB()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_SMB'| translate}}
                    </span>
                </div>
                <div  *ngIf="userInfo && customizationCompaniesService.checkCompanies(companies.SEND)" class="top-button-action cursor-pointer export-to-partner" (click)="transferToHolol()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_HOLOL'| translate}}
                    </span>
                </div>
                <div *ngIf="userInfo && customizationCompaniesService.checkCompanies(companies.FAST_ACCESS, companies.NAWEL, companies.LOGESTECHS)"
                     class="top-button-action cursor-pointer export-to-partner" (click)="transferToTorod()"
                     [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_TOROD'| translate}}
                    </span>
                </div>
                <div *ngIf="customizationCompaniesService.checkCompanies(companies.SEND, companies.BLINK)" class="top-button-action cursor-pointer export-to-partner" (click)="transferToAramex()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_ARAMEX'| translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer export-to-partner" (click)="exportAsExcel()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.Excel' | translate}}
                    </span>
                </div>

                <div class="order-by-container d-none">
<!--                    <div class="label d-flex align-self-center">{{'READ_BY_BARCODE.SORT_BY.LABEL' | translate}}</div>-->

                    <div  class="d-flex align-self-center">
                        <p-dropdown styleClass="read-by-barcode-order-by"
                                    [(ngModel)]="orderBy" optionLabel="label"
                                    [placeholder]="'READ_BY_BARCODE.SORT_BY.LABEL' | translate"
                                    [options]="orderByOption" filter="true"
                                    (onChange)="onOrderByChange()">
                        </p-dropdown>
                    </div>
                </div>

            </div>
            <div *ngIf="returnedModal" class="row read-by-barcode-top-actions">
                <div class="top-button-action cursor-pointer approve-package" *ngIf="showPickWithDriver">
                    <div class="secondary-action-btn action"
                         [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                         (click)="pickReturnedPackagesWithDriver()">
                        <span>
                             {{'RETURNED_PACKAGES.ACTIONS.PICK_RETURNED_PACKAGES' | translate}}
                            <br />
                            {{'RETURNED_PACKAGES.ACTIONS.WITH_DRIVER' | translate}}
                        </span>
                    </div>
                </div>
                <div class="top-button-action cursor-pointer approve-package" *ngIf="!hideDeliverToSenderBtn">
                    <div class="secondary-action-btn action" (click)="confirmMessage()" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.DELIVER_TO_SENDER' | translate}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="exportCODModal" class="row read-by-barcode-top-actions">
                <div class="top-button-action cursor-pointer approve-package" *ngIf="showPickWithDriver">
                    <div class="secondary-action-btn action"
                         (click)="pickWithDriver()"
                         [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                        <span>{{"ACCOUNTING.PICK_WITH_DRIVER" | translate}}</span>
                    </div>
                </div>
                <div class="top-button-action cursor-pointer approve-package" *ngIf="showCreateInvoice">
                    <div class="secondary-action-btn action"
                         (click)="createInvoiceConfirmation(null, $event)"
                         [ngClass]="{'disabled-btn disabled': canCreateInvoice()}">
                        <span>{{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES" | translate}}</span>
                    </div>
                </div>

                <div class="top-button-action cursor-pointer approve-package" *ngIf="showDeliver">
                    <div class="secondary-action-btn action"
                         (click)="deliverMultiplePackages()"
                         [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                        <span>{{(type === 'INVOICE' ? "MANAGE_COD_CUSTOMERS.INVOICES.PAY_THE_BILLS" : "ACCOUNTING.DELIVER") | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="packages-table" [ngClass]="isReadByBarcode ? (isUpdateReceiverInfo ? 'update-receiver-info-by-barcode-packages-table': 'read-by-barcode-packages-table') : ''">
                <div class="hidden-sm-down" [ngClass]="isUpdateReceiverInfo ? 'update-receiver-info-body' : 'shipment-body'" (scroll)="onScrollTable($event)">
                    <p-table [value]="objectValuesSortedDesc(packages)" [responsive]="true" scrollHeight="auto" styleClass="shipments-table receive-from-driver-table" [loading]="spinnerState === spinnerStates.LOADING">
                        <ng-template pTemplate="header">
                            <tr [ngClass]="{'receive-returned-packages-th': isReceiveReturnedPackages}">
                                <th class="checkbox-th" *ngIf="isReceiverFromDriver || isReceiveReturnedPackages || isReceiveFromPartner || isReadByBarcode">
                                    <div class="check-all-box">
                                        <p-checkbox [(ngModel)]="isAllSelected" binary="true" [disabled]="!driverId && !hubId && !isReadByBarcode" (onChange)="onToggleSelectAll()"></p-checkbox>
                                    </div>
                                </th>
                                    <ng-container *ngIf="returnedModal">
                                        <th>{{'GENERAL.BARCODE' | translate }}</th>
                                        <th>{{'RETURNED_PACKAGES.CUSTOMER_NAME' | translate }}</th>
                                        <th>{{'RETURNED_PACKAGES.CITY' | translate}}</th>
                                        <th *ngIf="inCar">{{'RETURNED_PACKAGES.DRIVER_NAME' | translate}}</th>
                                        <th>{{'RETURNED_PACKAGES.PACKAGES_NUMBER' | translate}}</th>
                                        <th *appPermission="permissions.includes('{EDIT_COST')">{{'RETURNED_PACKAGES.COST' | translate}}</th>
                                    </ng-container>
                                    <ng-container *ngIf="exportCODModal">
                                        <th *ngFor="let col of tableInfo">
                                            {{col.header | translate}}
                                        </th>
                                        <th *ngIf="currency === 'SAR'"> {{"ACCOUNTING.COD_FEES" | translate}}</th>
                                    </ng-container>
                                    <ng-container *ngIf="!returnedModal && !exportCODModal">
                                        <!--                                <th *ngIf="(isReceiverFromDriver || isReceiveFromPartner) && scannedPackages.length"></th>-->
                                        <th class="barcode-th">{{"SHIPMENT.TABLE.NUMBER" | translate | packageTitle}}</th>
                                        <th *ngIf="isReceiveReturnedPackages">{{ 'ADD_SHIPMENT.SENDER_ADDRESS' | translate }}</th>
                                        <th *ngIf="isReceiveReturnedPackages">{{ 'ADD_SHIPMENT.RECEIVER_ADDRESS' | translate | addressSystem }}</th>
                                        <th *ngIf="!isUpdateReceiverInfo">{{"MANAGE_COD_CUSTOMERS.CUSTOMER_NAME" | translate }}</th>
                                        <th *ngIf="!isUpdateReceiverInfo">{{"SHIPMENT.TABLE.SENDER_NAME" | translate}}</th>
                                        <th [ngClass]="{'update-receiver-info': isUpdateReceiverInfo}" >{{"SHIPMENT.TABLE.RECEIVER_NAME" | translate}}</th>
                                        <th *ngIf="!isReceiveReturnedPackages">{{"ADD_SHIPMENT.RECEIVER_PHONE" | translate }}</th>
                                        <th *ngIf="isReceiveReturnedPackages">{{"SHIPMENT.TABLE.NOTES" | translate}}</th>
                                        <th *ngIf="isAllowedEditCost()">
                                            {{"MANAGE_SHIPMENTS.COST" | translate}}
                                            <span class="currency" *ngIf="currency !== 'NIS'">{{currency}}</span>
                                            <span *ngIf="currency === 'NIS'" style="font-size: 10px !important;"><i class="fas fa-shekel-sign"></i></span>
                                        </th>
                                        <th>{{"ADD_SHIPMENT.QUANTITY" | translate}}</th>
                                        <th>
                                            {{"MANAGE_SHIPMENTS.COD" | translate}}
                                            <span class="currency" *ngIf="currency !== 'NIS'">{{currency}}</span>
                                            <span *ngIf="currency === 'NIS'" style="font-size: 10px !important;"><i class="fas fa-shekel-sign"></i></span>
                                        </th>
                                        <th>{{"SHIPMENT.TABLE.DELIVERY_DATE" | translate}}</th>
                                        <th>{{"SHIPMENT.TABLE.INVOICE" | translate}}</th>
                                        <th *ngIf="isReceiverFromDriver || isReadByBarcode" style="width: 140px!important;">{{(isReceiverFromDriver ? "MANAGE_SHIPMENTS.NOTES" : "SHIPMENT.TABLE.RETURNED_REASON") | translate}}</th>
                                        <th *ngIf="isLcl">{{'LCL.CONTAINER_NUMBER' | translate}}</th>
                                        <th *ngIf="isLcl">{{'LCL.UNLOAD' | translate}}</th>
                                        <th *ngIf="isReceiverFromDriver || isReceiveFromPartner || isReadByBarcode" class="status-dropdown">
                                            <div class="header-filter">
                                                <span class="status-title">{{"SHIPMENT.TABLE.STATUS" | translate}}</span>
                                                <div class="manage-shipment-status-filter" style="z-index: 3 !important;">
                                                    <app-drop-down *ngIf="isReceiverFromDriver || isReceiveFromPartner"
                                                                [(ngModel)]="selectedShipmentStatus"
                                                                [options]="shipmentWithDriverStatusList"
                                                                [disabled]="isReturnedWith"
                                                                [isFilter]="true"
                                                                filter="true"
                                                                (whenChange)="onStatusChanged()">
                                                    </app-drop-down>
                                                    <app-multi-select *ngIf="isReadByBarcode"
                                                                      [options]="statusFilterList"
                                                                      [filter]="false"
                                                                      (whenChange)="onStatusFilter()"
                                                                      [(ngModel)]="selectedStatusFilterList"
                                                                      maxSelectedLabels="0"
                                                                      selectedItemsLabel="{0} {{'GENERAL.ITEMS' | translate}}">
                                                    </app-multi-select>
                                                </div>
                                            </div>
                                        </th>
                                        <th *ngIf="isReceiverFromDriver">
                                            {{"SHIPMENT.TABLE.NUMBER_OF_ATTEMPTS" | translate}}
                                        </th>
                                        <!--                                <th *ngIf="isReadByBarcode">{{"SHIPMENT.TABLE.STATUS" | translate}}</th>-->
                                        <th *ngIf="!isReceiveReturnedPackages && !isUpdateReceiverInfo"></th>
                                        <th *ngIf="isReceiveFromPartner">
                                            {{"SHIPMENT.TABLE.PARTNER_STATUS" | translate}}
                                        </th>
                                        <th *ngIf="isReadByBarcode && !isUpdateReceiverInfo">{{"MANAGE_COD_CUSTOMERS.DESTINATION_ADDRESS" | translate}}</th>
                                        <th *ngIf="isReadByBarcode">{{'GENERAL.DELETE' | translate}}</th>
                                        <th *ngIf="permissions.includes('{EDIT_PACKAGE')"></th>
                                        <th *ngIf="customizationCompaniesService.checkCompanies(companies.JETT)">{{'GENERAL.CONTENTS' | translate}}</th>
                                    </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <div class="empty-content">
                                <app-no-data-available-component [message]="noDataAvailableMessage"> </app-no-data-available-component>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="body" let-shipment>
                            <tr class="shipment-row" [ngClass]="{'scanned-row':isPackageScanned(shipment)}">
                                <td *ngIf="isReceiverFromDriver || isReceiveReturnedPackages || isReceiveFromPartner || isReadByBarcode">
                                    <p-checkbox [(ngModel)]="shipment.isSelected" binary="true" (onChange)="onToggleSelection($event, shipment)"></p-checkbox>
                                </td>
                                <ng-container *ngIf="returnedModal">
                                    <td>
                                        <div class="barcode-container flex-2d-center flex-column" *ngIf="shipment.massReturnedPackagesReportBarcode">
                                            <div class="barcode-value flex-2d-center">
                                                <div class="value">{{shipment.massReturnedPackagesReportBarcode}}</div>
                                                <div class="copy-icon">
                                                    <img src="../../assets/icons/track_package/copy.svg" alt=""
                                                         (click)="copyMessage($event,null, shipment.massReturnedPackagesReportBarcode)"
                                                         data-toggle="tooltip" data-placement="top" title="Copy"
                                                         class="copy_icon"/>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{shipment.customerName}}</td>
                                    <td>{{shipment.city}}</td>
                                    <td  *ngIf="inCar">{{shipment.driverName}}</td>
                                    <td>{{shipment.packagesNumber}}</td>
                                    <td *appPermission="permissions.includes('{EDIT_COST')">{{shipment.cost}}</td>
                                </ng-container>
                                <ng-container *ngIf="exportCODModal">
                                    <td *ngFor="let col of tableInfo">
                                        <ng-container [ngSwitch]="col.type">
                                            <ng-container *ngSwitchCase="'barcode'">
                                                <div class="d-flex barcode-container justify-content-center">
                                                    <div class="barcode-value">{{shipment[col.field]}}</div>
                                                    <div class="copy-icon">
                                                        <img src="../../assets/icons/track_package/copy.svg" alt=""
                                                             (click)="copyMessage($event,null, shipment[col.field])"
                                                             data-toggle="tooltip" data-placement="top" title="Copy"
                                                             class="copy_icon"/>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'dateTime'">
                                                {{shipment[col.field] | date:'dd/MM/yyyy'}}  {{getLocalTime(shipment[col.field])}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'date'">
                                                {{shipment[col.field] | date:'dd/MM/yyyy'}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'concat'">
                                                <ng-container *ngFor="let field of col.field">
                                                    {{getConcat(shipment, field)}}
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'translate'">
                                                {{shipment[col.field] | translate}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'concatKeys'">
                                                <ng-container>
                                                    {{getDeepData(shipment, col.field)}}
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{shipment[col.field]}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td *ngIf="currency === 'SAR'"> {{ shipment.codFees}}</td>

                                </ng-container>
                                <ng-container  *ngIf="!returnedModal && !exportCODModal">

                                    <td class="barcode-number">
                                        <div style="display: flex; justify-content: center; gap: 10px;">
                                            <div class="item-barcode-number"><span>#{{shipment.barcode}}</span></div>
                                            <div>
                                                <img src="../../../assets/icons/track_package/copy.svg"
                                                     alt=""
                                                     (click)="copyMessage($event,null, shipment.barcode)"
                                                     data-toggle="tooltip"
                                                     data-placement="top"
                                                     title="Copy"
                                                     class="copy_icon" />
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="isReceiveReturnedPackages">{{shipment.senderCity}}</td>
                                    <td *ngIf="isReceiveReturnedPackages">{{shipment.receiverCity}}</td>
                                    <td *ngIf="!isUpdateReceiverInfo">
                                        <div>
                                            {{shipment?.customerName}}
                                        </div>
                                        <div *ngIf="shipment?.customerBusinessName" class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}">
                                            {{'USERS.BUSINESS_NAME' | translate}}: {{shipment.customerBusinessName}}
                                        </div>
                                    </td>
                                    <td *ngIf="!isUpdateReceiverInfo">
                                        <div>
                                            {{shipment?.senderFirstName + " " + (shipment.senderMiddleName ? shipment?.senderMiddleName : "") + " " + shipment?.senderLastName}}
                                        </div>
                                        <div *ngIf="shipment.businessSenderName" class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}">
                                            {{'USERS.BUSINESS_NAME' | translate}}: {{shipment.businessSenderName}}
                                        </div>
                                    </td>
                                    <td pEditableColumn>
                                        <p-cellEditor *ngIf="isUpdateReceiverInfo">
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="shipment.receiverName" required
                                                       (keydown.enter)="changeReceiverName(shipment.receiverName, shipment.id)"
                                                       (focusout)="changeReceiverName(shipment.receiverName, shipment.id)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div class="inplace-container">
                                                    <div [ngClass]="{'edit-output-name': true}">{{shipment.receiverName}}</div>
                                                    <i class="fas fa-pencil-alt"></i>
                                                </div>
                                            </ng-template>
                                        </p-cellEditor>
                                        <div *ngIf="!isUpdateReceiverInfo">
                                            <span class="ui-column-title">{{"SHIPMENT.TABLE.RECIPIENT_NAME" | translate}}</span>
                                            {{shipment?.receiverFirstName + " " + (shipment.receiverMiddleName ? shipment?.receiverMiddleName : "") + " " + shipment?.receiverLastName}}
                                        </div>
                                        <div  *ngIf="isReceiverFromDriver && !isUpdateReceiverInfo" class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}">
                                            {{'ADMINISTRATION.ADDRESS' | translate}}: {{currentLang === 'en' ? shipment.destinationAddress.village : shipment.destinationAddress.arabicVillageName}}
                                        </div>
                                    </td>
                                    <td pEditableColumn *ngIf="!isReceiveReturnedPackages">
                                        <p-cellEditor *ngIf="isUpdateReceiverInfo">
                                            <ng-template pTemplate="input">
                                                <input [ngClass]="{'error': !this.userService.getPhonePattern().test(shipment.receiverPhone)}"
                                                       pInputText type="tel" [(ngModel)]="shipment.receiverPhone" required
                                                       (keydown.enter)="changeReceiverPhone(shipment.receiverPhone, shipment.id)"
                                                       (focusout)="changeReceiverPhone(shipment.receiverPhone, shipment.id)">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div class="inplace-container">
                                                    <div [ngClass]="{'error': !this.userService.getPhonePattern().test(shipment.receiverPhone), 'edit-output-phone' : true}">{{shipment.receiverPhone}}</div>
                                                    <i class="fas fa-pencil-alt"></i>
                                                </div>
                                            </ng-template>
                                        </p-cellEditor>
                                        <div *ngIf="!isUpdateReceiverInfo">{{shipment.receiverPhone}}</div>
                                    </td>
                                    <td *ngIf="isReceiveReturnedPackages">{{"SHIPMENT.TABLE.NOTES" | translate}}</td>
                                    <td *ngIf="isAllowedEditCost()" class="default" pEditableColumn style="width: 80px;" [pEditableColumn]="shipment" [pEditableColumnField]="'cost'">
                                        <app-edit-cost [package]="shipment"></app-edit-cost>
                                    </td>
                                    <td>{{shipment.quantity}}</td>
                                    <td>{{shipment.cod}}</td>
                                    <td>{{shipment.deliveryDate | date:'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{shipment.invoiceNumber}}</td>
                                    <td *ngIf="isReceiverFromDriver || isReadByBarcode" style="max-width: 300px; min-width: 110px">{{isReceiverFromDriver ? shipment.notes : (('RETURNED_REASONS.'+shipment.failedReason) | translate: {default: shipment.failedReason})}}</td>
                                    <td *ngIf="isLcl">{{shipment.containerNumber}}</td>
                                    <td *ngIf="isLcl">
                                        <div [ngClass]="{'disabled-btn disabled': !shipment.bundleId}" class="unload-package-action" (click)="unloadFromContainer(shipment.id)">
                                            <img src="../../../assets/icons/unload.png" alt="" />
                                        </div>
                                    </td>
                                    <td *ngIf="isReceiverFromDriver || isReceiveFromPartner || isReadByBarcode">
                                        <div class="d-inline-flex">
                                            <!--                                        <span class="ui-column-title">{{"SHIPMENT.STATUS" | translate}}</span>-->
                                            <app-shipment-status [shipment]="shipment"> </app-shipment-status>

                                        </div>

                                        <div *ngIf="isToFinalDestinationStatus.indexOf(shipment.status) !== -1 && shipment.isToFinalDestination">
                                            {{'SHIPMENT.TO_FINAL_DESTINATION'|translate}}
                                        </div>
                                        <div>
                                            <ng-container [ngSwitch]="shipment.status">
                                                <div *ngSwitchCase="'TRANSFERRED_OUT'">
                                                    <div *ngIf="!partnerId">
                                                        {{'PARTNER.PARTNER_NAME' | translate}}: {{shipment.partnerName}}
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="shipment.driverName && !customizationCompaniesService.checkCompanies(companies.SAR, companies.MOHANAD)">
                                                    <div *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">
                                                        {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{shipment.driverName}}
                                                    </div>
                                                    <div *ngSwitchCase="'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'">
                                                        {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{shipment.driverName}}
                                                    </div>
                                                    <div *ngSwitchCase="'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'">
                                                        {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{shipment.driverName}}
                                                    </div>
                                                    <div *ngSwitchCase="'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'">
                                                        {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{shipment.driverName}}
                                                    </div>
                                                </ng-container>
                                                <div *ngSwitchCase="'SCANNED_BY_HANDLER_AND_UNLOADED'">
                                                    <div>
                                                        {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}: {{shipment.hubName}}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'">
                                                    {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}: {{shipment.hubName}}
                                                </div>
                                                <div *ngSwitchCase="'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'">
                                                    {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}: {{shipment.hubName}}
                                                </div>
                                                <div *ngSwitchCase="'POSTPONED_DELIVERY'">
                                                    <div *ngIf="shipment.hubName">
                                                        {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}: {{shipment.hubName}}
                                                    </div>
                                                    <div *ngIf="shipment.driverName">
                                                        {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{shipment.driverName}}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                                    <ng-container [ngSwitch]="shipment.returnedPackageLocation">
                                                        <div class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}" *ngSwitchCase="'IN_HUB'">
                                                            <div *ngIf="shipment.hubName">
                                                                {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}: {{shipment.hubName}}
                                                            </div>
                                                        </div>
                                                        <div class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}" *ngSwitchCase="'IN_CAR'">
                                                            <div *ngIf="shipment.driverName">
                                                                {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{shipment.driverName}}
                                                            </div>
                                                        </div>
                                                        <div class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}" *ngSwitchCase="'DELIVERED_TO_SENDER'">
                                                            {{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER'|translate}}
                                                        </div>
                                                        <div *ngSwitchCase="'WITH_PARTNER'">
                                                            {{'ACCOUNTING.WITH_PARTNER'|translate}}
                                                        </div>
                                                        <span *ngSwitchDefault></span>
                                                    </ng-container>
                                                </div>
                                                <span *ngSwitchDefault></span>
                                            </ng-container>
                                            <!--keep this line commented-->
                                            <!--                                        <ng-container *ngIf="shipment.codStatus" [ngSwitch]="shipment.codStatus">-->
                                            <!--                                            <div class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}" *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">-->
                                            <!--                                                <div *ngIf="shipment.codDriverName">{{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{shipment.codDriverName}}</div>-->
                                            <!--                                            </div>-->
                                            <!--                                            <div class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}" *ngSwitchCase="'SCANNED_BY_HANDLER_AND_UNLOADED'">-->
                                            <!--                                                <div *ngIf="shipment.codHubName">{{"MANAGE_SHIPMENTS.HUB_NAME" | translate | hubTitle}}: {{shipment.codHubName}}</div>-->
                                            <!--                                            </div>-->
                                            <!--                                            <div class="driver-hub-name" *ngSwitchDefault>{{shipment.codStatus | translate}}</div>-->
                                            <!--                                        </ng-container>-->
                                        </div>
                                    </td>
                                    <td *ngIf="isReceiverFromDriver">{{shipment.failuresNumber }}</td>
                                    <td *ngIf="!isReceiveReturnedPackages">
                                        <ng-template #changeStatusDialogForSinglePackage let-close="close">
                                            <div class="popover-container">
                                                <app-change-package-status-component
                                                        [shipment]="triggeredPackage"
                                                        [isShowCurrentStatus]="false"
                                                        (onFinish)="closeChangePackageStatusPopOver(changeStatusForSinglePackagePopOver, $event)"
                                                        (onCancel)="closeChangePackageStatusPopOver(changeStatusForSinglePackagePopOver, $event)"
                                                >
                                                </app-change-package-status-component>
                                            </div>
                                        </ng-template>
                                        <div class="edit-package-status">
                                            <div
                                                    class="edit-action"
                                                    (click)="assignTriggeredPackage(shipment)"
                                                    #changeStatusForSinglePackagePopOver="ngbPopover"
                                                    placement="bottom-left top-left"
                                                    autoClose="outside"
                                                    container="body"
                                                    [ngbPopover]="changeStatusDialogForSinglePackage"
                                                    [ngClass]="{'edit-action-en': currentLang === 'en'}"
                                            >
                                                <i class="fas fa-pencil-alt"></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="isReceiveFromPartner">
                                        <div class="d-inline-flex">
                                            <span class="ui-column-title">{{"SHIPMENT.TABLE.PARTNER_STATUS" | translate}}</span>
                                            <app-shipment-status [shipment]="shipment" [isPartnerStatus] = true> </app-shipment-status>
                                        </div>
                                        <div>
                                            <ng-container [ngSwitch]="shipment.partnerPackageStatus">
                                                <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                                    <div class="driver-hub-name" [ngClass]="{'margin-right-30': currentLang === 'en'}">
                                                        {{shipment.returnedPartnerPackageLocation|translate | packageTitle}}
                                                    </div>
                                                </div>
                                                <span *ngSwitchDefault></span>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td *ngIf="isReadByBarcode && !isUpdateReceiverInfo">
                                        <div>
                                            {{shipment?.receiverCity + " " + (shipment?.destinationVillage ? shipment?.destinationVillage : "") + " " }}
                                            <br>
                                            <br>
                                            {{shipment?.destinationAddressLine1}}
                                        </div>
                                    </td>
                                    <td *ngIf="isReadByBarcode">
                                        <div class="remove-action" (click)="removePackage(shipment)">
                                            <img src="../../../assets/icons/delete.svg" alt="" />
                                        </div>
                                    </td>
                                    <td *ngIf="permissions.includes('{EDIT_PACKAGE')">
                                        <div class="edit-package-btn edit-action action cursor-pointer" (click)="openEditPackage(shipment)">
                                            <i class="fas fa-edit"></i>
                                        </div>
                                    </td>

                                    <td *ngIf="customizationCompaniesService.checkCompanies(companies.JETT)">
                                        {{shipment.description}}
                                    </td>

                                </ng-container>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row bottom-actions d-inline-flex justify-content-end"
                     [ngClass]="{'justify-content-between': objectValues(packages).length && !returnedModal && !exportCODModal }"
                     style="justify-content: space-between" *ngIf="isReadByBarcode && !isUpdateReceiverInfo">
                    <div *ngIf="objectValues(packages).length && !returnedModal && !exportCODModal" class="d-flex align-items-center justify-content-start">
                        <div class="number-of-scanned-packages d-flex ml-3 mr-3">
                            <div class="icon">
                                <img src="../../../assets/icons/containers-management/packages_number.svg" alt=""/>
                            </div>
                            <div class="label">
                                <span>{{'RECEIVE_FROM_DRIVER.NUMBER_OF_SELECTED_PACKAGES' | translate | packageTitle}}</span>
                            </div>
                            <div class="number">
                                <span>{{objectValues(scannedPackages).length}}</span>
                            </div>
                        </div>

                        <div class="number-of-scanned-packages d-flex ml-3 mr-3">
                            <div class="label">
                                <span>{{'RECEIVE_FROM_DRIVER.COD' | translate | packageTitle}}</span>
                            </div>
                            <div class="number">
                                <span>{{findCodSum(scannedPackages)}}</span>
                            </div>
                        </div>

                        <div class="number-of-scanned-packages d-flex ml-3 mr-3">
                            <div class="label">
                                <span>{{'RECEIVE_FROM_DRIVER.COST' | translate | packageTitle}}</span>
                            </div>
                            <div class="number">
                                <span>{{findCostSum(scannedPackages)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="finish-read-by-barcode" (click)="finish()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">{{'READ_BY_BARCODE.ACTIONS.RESET' | translate}}</div>
                </div>
                <div class="row bottom-actions" *ngIf="isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages">
                    <div class="bottom-right col-4 d-inline-flex">
                        <div (click)="resetScanning()" [ngClass]="{'disabled-btn': disableResetScanningBtn()}" class="cancel-receive cursor-pointer">{{'RECEIVE_FROM_DRIVER.CANCEL_RECEIVE' | translate}}</div>
                        <div class="separate"><div class="separator"></div></div>
                        <div class="enter-by-barcode cursor-pointer p-0"
                             [ngClass]="{'disabled-btn': !objectValues(packages).length && (!this.driverId && !this.partnerId && !isReceiveReturnedPackages)}"
                             (click)="enterBarcode()">
                            <span>{{'RECEIVE_FROM_DRIVER.ENTER_BARCODE' | translate | packageTitle}}</span>
                            <img style="width: 50px;margin-inline: 15px;" src="../../../assets/icons/barcode-scanner.png" alt="" />
                        </div>
                    </div>
                    <div class="bottom-left col-8 d-inline-flex">
                        <div *ngIf="isReceiverFromDriver && companyId !== 22 && companyId !== 256" class="receive-another-drivers-switch d-inline-flex">
                            <div class="label">

                                <ng-container *ngIf="!isFromThirdParty">{{'RECEIVE_FROM_DRIVER.CONTINUE_RECEIVING' | translate}}</ng-container>
                                <ng-container *ngIf="isFromThirdParty">{{'RECEIVE_FROM_THIRD_PARTY.CONTINUE_RECEIVING' | translate }}</ng-container>
                            </div>
                            <div class="switch-input continue-receive-switch" [ngClass]="{'disabled-continue-receive-switch': !continueReceiveFromDriver}">
                                <p-inputSwitch [(ngModel)]="continueReceiveFromDriver"></p-inputSwitch>
                            </div>
                        </div>
                        <div *ngIf="isReceiveReturnedPackages" [ngClass]="{'disabled-btn': !objectValues(packages).length}" (click)="downloadMultiplePackages()" class="print-returned-packages d-flex cursor-pointer">
                            <div class="icon">
                                <img src="../../../assets/icons/containers-management/print.svg" alt="" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="action-label">{{'SHIPMENT.PRINT_PACKAGES' | translate | packageTitle}}</div>
                        </div>
                        <div style="width: 10px;"></div>
                        <div class="separate"><div class="separator"></div></div>
                        <div style="width: 25px;"></div>
                        <div [ngClass]="{'disabled-btn': disableFinishScanningBtn()}">
                            <div (click)="finishScanning()" class="receive-done d-inline-flex" [ngClass]="{'receive-done-en': currentLang === 'en'}">
                                <div style="width: 40px;"></div>
                                <div class="receive-done-label">{{'RECEIVE_FROM_DRIVER.RECEIVE' | translate}}</div>
                                <span class="number" [ngClass]="{'number-en': currentLang === 'en'}" style="color: black; font-family: 'roboto bold';">
                                    {{isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages ? objectValues(scannedPackages).length : objectValues(packages).length}} <span style="width: 3px !important;"></span>
                                    <span style="font-family: 'almarai light';">{{'RECEIVE_FROM_DRIVER.PACKAGE' | translate | packageTitle}}</span>
                                </span>
                                <div class="packages-number" [ngClass]="{'packages-number-en': currentLang === 'en'}"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-add-package-form-component *ngIf="displayAddPackageOb | async" [display]="displayAddPackage"
                                [packageBarcode]="editSelectedPackageBarcode"
                                [originPkg]="editSelectedPackage"
                                (closeWidget)="onHideAddPackage($event)">
</app-add-package-form-component>
