<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="drivers-earnings-report h-100">
    <div class="header d-flex justify-content-between flex-wrap">
        <div class="header-title page-header-title">
            <div class="title">{{'DRIVERS_EARNINGS.DRIVERS_EARNINGS_REPORT' | translate}}</div>
            <div class="total-number-badge">{{totalRecords}}</div>
        </div>
        <div class="actions-and-filter d-flex flex-wrap align-items-center">


            <div class="add-business-tab">
                <button (click)="setShowProductsAddByUser('')"
                        [ngClass]="{'active': createdByFilter === ''}">
                    <div>{{ 'GENERAL.PACKAGES' | translate }}</div>
                </button>
                <button (click)="setShowProductsAddByUser('MISMATCH')"
                        [ngClass]="{'active': createdByFilter === 'MISMATCH'}">
                    <div>{{ "ACCOUNTING.REPORTS" | translate }}</div>
                </button>
            </div>

            <div class="filters d-flex justify-content-between align-items-center flex-wrap">
                <div class="excel-button"
                     [ngClass]="{'disabled-btn disabled': isDownloading}"
                     (click)="printReport('EXCEL')">
                    <i class="fas fa-file-excel"></i>
                    {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                </div>
                <div class="pdf-button" *ngIf="createdByFilter === 'MISMATCH'"
                     [ngClass]="{'disabled-btn disabled': isDownloading}"
                     (click)="printReport('PDF')">
                    <i class="fas fa-file-pdf"></i>
                    {{"ACTIONS.EXPORT_TO_PDF" | translate}}
                </div>
                <div class="customer">
                    <app-users-list-dropdown-component
                            #userDropdown
                            [placeHolder]="'ADMINISTRATION.DRIVER_LIST_DROPDOWN_PLACEHOLDER'"
                            [isDriver] = true
                            [showResetButton]="true"
                            (userChanged)="onSelectDriver($event)"
                    ></app-users-list-dropdown-component>
                </div>

<!--                <div class="date date-filter">-->
<!--                    <div class="date-range">-->
<!--                        <span (click)="datepicker.click()">-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </span>-->
<!--                        <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                               [options]="dateRangPickerOptions" (selected)="onDateSelected($event)"/>-->
<!--                        <img class="arrow-down" src="../../../assets/icons/arrow-down.png">-->
<!--                    </div>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                <!--                <div class="search main-search-filter">-->
<!--                    <i class="fas fa-search"></i>-->
<!--                    <field-input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." appInputDebounced-->
<!--                           (inputDebounced)="onFilter()" [(ngModel)]="search">-->
<!--                </div>-->
            </div>
        </div>
    </div>
    <div class="main-table drivers-earnings-report-table">
        <p-table appPTableRtlFix dataKey="id" #table [value]="reportData"
                 paginatorDropdownAppendTo="body"
                 styleClass="drivers-earnings-report-p-table flex-table"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>

            <ng-template pTemplate="header">
<!--                <th>-->
<!--                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"-->
<!--                                (onChange)="onToggleSelectAll()">-->
<!--                    </p-checkbox>-->
<!--                </th>-->
                <th  *ngIf="createdByFilter == 'MISMATCH'">{{'GENERAL.BARCODE' | translate}}</th>
                <th>{{'DRIVERS_EARNINGS.TABLE.DRIVER_NAME' | translate}}</th>
                <th>{{'DRIVERS_EARNINGS.TABLE.COST_SUM' | translate}}</th>
                <th>{{'DRIVERS_EARNINGS.TABLE.DRIVER_EARNINGS_SUM' | translate}}</th>
                <th *ngIf="createdByFilter !== 'MISMATCH'">{{'DRIVERS_EARNINGS.TABLE.COMPANY_REVENUE' | translate}}</th>
                <th>{{'DRIVERS_EARNINGS.TABLE.PACKAGES_NUMBER' | translate}}</th>
                <ng-container *ngIf="createdByFilter == 'MISMATCH'">
                    <th>{{'DRIVERS_EARNINGS.TABLE.TOTAL_RECEIVED_COD' | translate}}</th>
                    <th>{{'DRIVERS_EARNINGS.TABLE.COST_DIFFERENCE' | translate}}</th>
                    <th>{{'DRIVERS_EARNINGS.TABLE.COD_NET' | translate}}</th>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr (click)="openDriverEarningsDetails(report)">
<!--                    <td>-->
<!--                        <p-checkbox [(ngModel)]="report.isSelected" binary="true"-->
<!--                                    (click)="$event.stopPropagation()"-->
<!--                        ></p-checkbox>-->
<!--                    </td>-->
                    <td *ngIf="createdByFilter == 'MISMATCH'">
                        <div class="flex-2d-center flex-column">
                            <img [src]="report.barcodeImageUrl" alt="{{ report.barcode }}">
                            <div class="d-flex">
                                <div class="barcode">{{ report.barcode }}</div>
                                <div class="copy-barcode ml-2 mr-2">
                                    <img src="../../../assets/icons/package_card/copy.svg" alt=""
                                         (click)="copyMessage($event,null, report.barcode);$event.stopPropagation();" data-toggle="tooltip"
                                         data-placement="top" title="Copy" class="copy_icon"/>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{{createdByFilter !== 'MISMATCH' ? report.driverName : report.holderName}}</td>
                    <td>{{(createdByFilter !== 'MISMATCH' ? report.costSum: report.cost) | number: '1.0-2' }}</td>
                    <td>{{(createdByFilter !== 'MISMATCH' ? report.driverEarningSum: report.driverEarning)| number: '1.0-2' }}</td>
                    <td *ngIf="createdByFilter !== 'MISMATCH'">{{(report.costSum - report.driverEarningSum)| number: '1.0-2' }}</td>
                    <td>{{ report.packagesCount}}</td>
                    <ng-container *ngIf="createdByFilter == 'MISMATCH'">

                        <td>{{(createdByFilter !== 'MISMATCH' ? report.receivedCodSum : report.carriedCod) | number: '1.0-2' }}</td>
                        <td>{{report.costDifference | number: '1.0-2' }}</td>
                        <td>{{(createdByFilter !== 'MISMATCH' ? report.customerNet : report.codNet)| number: '1.0-2'}}</td>
                    </ng-container>

                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
