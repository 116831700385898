import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {ShippingLinesService} from '../../services/shipping-lines.service';

@Component({
    selector: 'app-add-shipping-line',
    templateUrl: './add-shipping-line.component.html',
    styleUrls: ['./add-shipping-line.component.scss']
})
export class AddShippingLineComponent implements OnInit {

    public isLoading = false;
    public form: FormGroup;
    public shippingLineId = 0;
    public shippingLineName = '';
    public expectedDeliveryDaysNumber = null;
    public type = '';
    public drivers = [];

    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private messageService: MessageService,
                private shippingLinesService: ShippingLinesService) {
    }

    ngOnInit() {
        if (this.shippingLineId) {
            this.isLoading = true;
            this.fetchLineInfo();
        } else {
            this.initInfo();
        }
    }

    private fetchLineInfo() {
        this.shippingLinesService.getShippingLine(this.shippingLineId).subscribe((shippingLine: any) => {
            this.shippingLineName = shippingLine.name;
            this.expectedDeliveryDaysNumber = shippingLine.expectedDeliveryDaysNumber;
            this.drivers = shippingLine.drivers;
            this.isLoading = false;
            this.initInfo();
        }, () => {
            this.isLoading = false;
        });
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            name: [this.shippingLineName, Validators.required],
            expectedDeliveryDaysNumber: [this.expectedDeliveryDaysNumber]
        });

    }

    public closeModal() {
        this.activeModal.close();
    }

    public createLine() {
        const {name} = this.form.getRawValue();
        this.isLoading = true;
        this.shippingLinesService.addShipmentLine(name, [], this.type).subscribe(() => {
            this.translateService.get(
                'ALERTS.SHIPPING_LINE_ADDED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.activeModal.close({isSuccess: true, name: name});
                    }
                );
        }, (error) => {
            this.isLoading = false;
        });
    }

    public editLine() {
        const {...data} = this.form.getRawValue();
        data.type = this.type;
        data.drivers = this.drivers;
        this.isLoading = true;
        this.shippingLinesService.editShipmentLine(this.shippingLineId, data, this.type).subscribe(() => {
            this.translateService.get(
                'ALERTS.SHIPPING_LINE_EDITED_SUCCESSFULLY'
            )
                .subscribe(
                    (msg) => {
                        this.messageService.add({severity: 'success', detail: msg});
                        this.activeModal.close({isSuccess: true, name: data.name});
                    }
                );
        }, (error) => {
            this.isLoading = false;
        });
    }

}
