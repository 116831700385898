import {Component} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../shared/services/user.service';


@Component({
    selector: 'app-customer-packages-detailed-report',
    templateUrl: './customer-packages-detailed-report.component.html',
    styleUrls: ['./customer-packages-detailed-report.component.css']
})
export class CustomerPackagesDetailedReportComponent {
    public customerReport = REPORTS_CONSTANTS.DETAILED_REPORTS.CUSTOMER_REPORT;
    public reportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_CUSTOMERS_REPORT_API;
    public customerRawDataUrl = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_CUSTOMER_RAW_DATA_API;
    public customerId = 0;
    dateTypeFilterList = REPORTS_CONSTANTS.DETAILED_REPORTS.DATE_TYPE_FILTER_LIST;

    constructor(public translateService: TranslateService,
                public userService: UserService) {
        /*this.onCustomerSelected(66);*/
    }

    onCustomerSelected(customerId) {
        if (customerId) {
            this.reportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_CUSTOMER_REPORT_API.replace('{customerId}', customerId);
        } else {
            this.reportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_CUSTOMERS_REPORT_API;
        }
        this.customerId = customerId;
    }

    hideHubsFilter() {
        return ['HUB_MANAGER', 'HUB_ADMIN', 'HUB_OPERATION_MANAGER'].indexOf(this.userService.userInfo.role) === -1;
    }

}
