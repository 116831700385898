import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DriverModel} from '../../shared/models/driver.model';
import {DriversService} from '../../shared/services/drivers.service';
import {UserService} from '../../shared/services/user.service';
import {DriverCheckInModel} from '../../shared/models/driver-check-in.model';
import {UserModel} from '../../shared/models/user.model';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {DatePipe} from '@angular/common';

declare let L;

@Component({
    selector: 'app-driver-check-in-list',
    templateUrl: './driver-check-in-list.component.html',
    styleUrls: ['./driver-check-in-list.component.scss']
})
export class DriverCheckInListComponent implements OnInit {
    currentLang: string;

    isLoading = false;
    isLoadingDrivers = false;
    selectedDriver: DriverModel;
    drivers: DriverModel[] = [];
    totalDriversNo = 0;

    selectedCheckIn: DriverCheckInModel;
    // checkInList: DriverCheckInModel[] = [];
    dataList: any[] = [];
    totalRecordsNo = 0;
    map;
    marker;
    initialMapLocation;

    filterParams = {
        drivers: {
            page: 1,
            pageSize: 20,
            search: ''
        },
        driversTimeTracking: {
            page: 1,
            pageSize: 20,
            fromDate: null,
            toDate: null,
            driverId: null
        },
        checkIns: {
            page: 1,
            pageSize: 20
        }
    };
    isDriversTimeTracking = false;
    dateRangPickerOptions;
    fromDate;
    toDate;

    @ViewChild('datepicker') datepicker;

    constructor(private activeModal: NgbActiveModal,
                private driversService: DriversService,
                private userService: UserService,
                private datePipe: DatePipe,
                private dateRangePickerService: DateRangePickerService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.getDrivers(true);
       if (!this.isDriversTimeTracking) {
           if (this.selectedDriver) {
               this.getCheckInList(true);
           }
           setTimeout(() => {
               this.initMap();
           }, 500);
       }
    }

    initMap() {
        this.initialMapLocation = this.userService.getInitialMapView();
        const mapTile = (this.translateService.currentLang === 'en') ?
            'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.map = new L.map('map', {
            fullscreenControl: true
        });
        this.map.invalidateSize();

        L.tileLayer(mapTile, {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        this.map.setView([this.initialMapLocation.lat, this.initialMapLocation.lng], this.initialMapLocation.zoom);
    }

    closeModal() {
        this.activeModal.close();
    }

    private getCheckInList(forceFetch = false) {
        if (this.totalRecordsNo === this.dataList.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams.checkIns.page = 1;
        }
        this.isLoading = true;
        this.driversService.getDriverCheckIns(this.selectedDriver.id, this.filterParams.checkIns).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                const fetchedCheckIns = response.data;
                this.totalRecordsNo = response.totalRecordsNo;
                if (forceFetch) {
                    this.dataList = fetchedCheckIns;
                } else {
                    this.dataList.push(...fetchedCheckIns);
                }
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }

    private getDrivers(forceFetch = false) {
        if (this.totalDriversNo === this.drivers.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams.drivers.page = 1;
        }
        this.isLoadingDrivers = true;
        this.driversService.getDrivers(this.createParams(this.filterParams.drivers)).subscribe(
            (driversResponse: UserModel[]) => {
                const fetchedDrivers = driversResponse;
                this.totalDriversNo = driversResponse.length;
                if (forceFetch) {
                    this.drivers = fetchedDrivers;
                } else {
                    this.drivers.push(...fetchedDrivers);
                }
                this.isLoadingDrivers = false;
            }, () => {
                this.isLoadingDrivers = false;
            }
        );
    }

    searchDriver(search: string) {
        this.filterParams.drivers.search = search;
        this.getDrivers(true);
    }

    onDriversScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyDrivers();
        }
    }

    trackDriverById(index: number, item: DriverModel) {
        return item.id;
    }

    selectDriver(driver: DriverModel) {
        this.selectedDriver = driver;
        if (!this.isDriversTimeTracking) {
            this.selectedCheckIn = null;
            if (this.marker) {
                this.marker.remove();
            }
            this.map.flyTo([this.initialMapLocation.lat, this.initialMapLocation.lng], this.initialMapLocation.zoom);
            this.getCheckInList(true);
        } else { // Drivers Time Tracking
            this.filterParams.driversTimeTracking.driverId = driver.id;
            this.driversTimeTrackingData(true);
        }
    }

    private loadLazyDrivers() {
        this.filterParams.drivers.page++;
        this.getDrivers();
    }

    private createParams(filterParams) {
        if (!filterParams) {
            return '';
        }
        const filterKeyValues = Object.entries(filterParams);
        let result = '';
        filterKeyValues.forEach((keyValuePair, index) => {
            if (keyValuePair[1]) {
                if (index === 0) {
                    result += `?${keyValuePair[0]}=${keyValuePair[1]}`;
                } else {
                    result += `&${keyValuePair[0]}=${keyValuePair[1]}`;
                }
            }

        });
        return result;
    }
    @HostListener('scroll', ['$event'])
    onTableScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyCheckInList();
        }
    }

    private loadLazyCheckInList() {
        if (!this.isDriversTimeTracking) {
            this.filterParams.checkIns.page++;
            this.getCheckInList();
        } else {
            this.filterParams.driversTimeTracking.page++;
            this.driversTimeTrackingData();
        }
    }

    selectCheckIn(checkIn: DriverCheckInModel) {
        if (this.selectedCheckIn === checkIn) {
            return;
        }
        this.selectedCheckIn = checkIn;
        if (this.marker) {
            this.marker.remove();
        }
        this.marker = L.marker([checkIn.latitude, checkIn.longitude]);
        this.marker.addTo(this.map);
        this.map.flyTo([checkIn.latitude, checkIn.longitude], 15);
    }
    onDateSelected(value) {
        const startDate = new Date(value.start);
        const endDate = new Date(value.end);
        this.filterParams.driversTimeTracking.fromDate = this.transformDate(startDate);
        this.filterParams.driversTimeTracking.toDate = this.transformDate(endDate);
        this.driversTimeTrackingData(true);
        const {start, end} = value;
        if ((start && end) || value.label) {
            if (value.label) {
                if (value.label === this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }


    private driversTimeTrackingData(forceFetch = false) {
        if (this.totalRecordsNo === this.dataList.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams.driversTimeTracking.page = 1;
        }
        this.isLoading = true;
        const params = {};
        Object.keys(this.filterParams.driversTimeTracking).forEach(
            (key: string) => {
                if (this.filterParams.driversTimeTracking[key] !== null) {
                    params[key] = this.filterParams.driversTimeTracking[key];
                }
            }
        );
        this.driversService.getDriversTimeTracking(params).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                const fetchedData = response.data;
                this.totalRecordsNo = response.totalRecordsNo;
                if (forceFetch) {
                    this.dataList = fetchedData;
                } else {
                    this.dataList.push(...fetchedData);
                }
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }
}
