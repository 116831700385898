<div [ngClass]="{'page-ltr': currentLang === 'en'}" style="z-index: 1111111">
    <div class="add-new-user-container">
        <div class="row" style="justify-content: space-between; padding-bottom: 20px !important;">
            <div class="title">
                <span>{{getHeaderTitle() | translate }}</span>
            </div>
            <div class="close-icon" (click)="exitAddNewUser()">
                <img src="../../../assets/icons/close-x.svg" alt=""/>
            </div>
        </div>
        <div *ngIf="user && isEdit" class="row justify-content-center">
            <div class="upload-user-image">
                <app-upload-image-component [(url)]="user.imageUrl" [api]="userImageApi"
                                            [defaultLogo]="'../../../../assets/icons/user-avatar.svg'">

                </app-upload-image-component>
            </div>
        </div>
        <ng-container *ngIf="isLoading;else loaded">
            <div style="text-align: center;">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </ng-container>

        <ng-template #loaded>
            <div class="flex-1 input-fields">
                <div class="h-100 d-flex flex-column">
                    <form *ngIf="form" class="add-user-form row" [formGroup]="form" (submit)="onAddUser()">
                        <div class="inputs-container d-flex flex-wrap">
                            <div class="input-item">
                                <div class="first-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.FIRST_NAME' | translate}}</div>
                                    <div class="input-value">
                                        <input id="f-name-input" type="text" size="30" pInputText
                                               formControlName="firstName"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('firstName')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.FIRST_NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="last-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.FAMILY_NAME' | translate}}</div>
                                    <div class="input-value">
                                        <input id="l-name-input" type="text" size="30" pInputText
                                               formControlName="lastName"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('lastName')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.LAST_NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="type == 'customer'">
                                <div class="input-item">
                                    <div class="business-name input-filed-container">
                                        <div class="input-label">{{(isLcl ? 'USERS_MANAGEMENT.ADD_USER_FORM.LCL_BUSINESS_NAME' : 'USERS_MANAGEMENT.ADD_USER_FORM.BUSINESS_NAME') | translate}}</div>
                                        <div class="input-value">
                                            <input id="1l-name-input" type="text" size="30" pInputText
                                                   formControlName="businessName"/>
                                        </div>
                                        <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('businessName')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.CUSTOMER_CODE_VALIDATION_LCL' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item">
                                    <div class="auth-gov-registration-number input-filed-container">
                                        <div class="input-label">
                                            {{ ((companyId === 222 && type === 'customer') ? 'USERS_MANAGEMENT.ADD_USER_FORM.TALABY_GOV_REGISTRATION_NUMBER' : (isLcl ? 'USERS_MANAGEMENT.ADD_USER_FORM.VAT_NUMBER' :
                                            'USERS_MANAGEMENT.ADD_USER_FORM.GOV_REGISTRATION_NUMBER')) | translate }}
                                        </div>
                                        <div class="input-value">
                                            <input id="11l-name-input" type="text" size="30" pInputText
                                                   formControlName="authorizedGovRegistrationNumber"/>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="input-item">
                                <div class="user-name input-filed-container">
                                    <div class="input-label">
                                        {{getEmailLabel()| translate}}
                                    </div>
                                    <div class="input-value">
                                        <input (keyup)="validateUserNameAndEmail(form.get('email').value)"
                                               id="email-input" type="email" size="30" pInputText
                                               formControlName="email"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('email', 'required')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.EMAIL_REQUIRED' | translate }}
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyAndTouched('email', 'minlength')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.EMAIL_MIN_LENGTH' | translate:{min: form.get('email').getError('minlength').requiredLength} }}
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="password input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.PASSWORD' | translate}}</div>
                                    <div class="input-value password-value">
                                        <input id="password-input" type="password" size="30" pInputText
                                               formControlName="password" appPassword [removeEyeIcon]="isEdit" autocomplete="new-password"/>
                                    </div>
                                    <div class="invalid-message"
                                         *ngIf="isInvalidDirtyOrTouched('password', 'required')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.PASSWORD_REQUIRED' | translate }}
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('password', 'pattern')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.PASSWORD_PATTERN' | translate:{min: PASSWORD_MIN_LENGTH} }}
                                    </div>
                                </div>
                            </div>

                            <div class="input-item" *ngIf="type === 'customer'">
                                <div class="user-name input-filed-container">
                                    <div class="input-label">
                                        {{'USERS_MANAGEMENT.ADD_USER_FORM.OPTIONAL_NUMBER'| translate}}
                                    </div>
                                    <div class="input-value">
                                        <input id="optional-number" pInputText formControlName="optionalNumber"/>
                                    </div>
                                    <div class="invalid-message"
                                         *ngIf="isInvalidDirtyOrTouched('optionalNumber', 'pattern')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.OPTIONAL_NUMBER_PATTERN' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="type === 'customer'" class="input-item">
                                <div class="expected-monthly-packages-number input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.EXPECTED_PACKAGES_NUMBER' | translate}}</div>
                                    <div class="input-value">
                                        <p-dropdown [options]="expectedMonthlyPackagesNumberList"
                                                    [autoZIndex]="true"
                                                    [baseZIndex]="1100"
                                                    placeholder="{{'USERS_MANAGEMENT.CHOOSE_EXPECTED_PACKAGES_NUMBER' | translate}}"
                                                    formControlName="expectedMonthlyPackagesNumber">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>

                            <div class="input-item phone-number-container">
                                <div class="input-filed-container">
                                    <div class="input-label">{{(companyId === 222 && type === 'customer' ? 'USERS_MANAGEMENT.ADD_USER_FORM.TALABY_PHONE' : 'USERS_MANAGEMENT.ADD_USER_FORM.PHONE') | translate }}</div>
                                    <app-phone-number-input [formGroupPass]="form" [formControlNamePass]="'phone'" [component]="'NewUserComponent'"
                                                            [formControlPass]="form.get('phone')"></app-phone-number-input>
                                    <div>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('phone', 'required')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_REQUIRED' | translate  }}
                                        </div>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('phone', 'pattern')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_PATTERN' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="input-item phone-number-container">
                                <ng-container *ngIf="showPhone2">
                                    <div class="input-filed-container d-flex">
                                        <div class="flex-1">
                                            <div class="input-label">{{(companyId === 222 && type === 'customer' ? 'USERS_MANAGEMENT.ADD_USER_FORM.TALABY_PHONE' : 'USERS_MANAGEMENT.ADD_USER_FORM.PHONE') | translate }}
                                            </div>
                                            <app-phone-number-input [formGroupPass]="form"
                                                                    [component]="'NewUserComponent'"
                                                                    [formControlNamePass]="'phone2'"
                                                                    [formControlPass]="form.get('phone2')"></app-phone-number-input>
                                            <div>
                                                <div class="invalid-message"
                                                     *ngIf="isInvalidDirtyOrTouched('phone2', 'required')">
                                                    {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_REQUIRED' | translate  }}
                                                </div>
                                                <div class="invalid-message"
                                                     *ngIf="isInvalidDirtyOrTouched('phone2', 'pattern')">
                                                    {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_PATTERN' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="remove-phone d-flex align-items-center cursor-pointer"
                                             (click)="toggleExtraPhone()">
                                            <img class="pl-3 pr-3" src="../../assets/icons/add_package/remove.svg"
                                                 alt="">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!showPhone2">
                                    <div class="add-phone align-items-center d-flex h-100 cursor-pointer">
                                        <img class=" pl-3 pr-3" src="../../assets/icons/add_package/add_phone.svg"
                                             class="active"
                                             (click)="toggleExtraPhone()">
                                    </div>

                                </ng-container>
                            </div>

                            <div *ngIf="isCustomerFulfillment()" class="input-item">
                                <div class="user-name input-filed-container">
                                    <div class="input-label">{{'ADD_SHIPMENT.ACCESS' | translate}}</div>
                                    <div class="input-value">

                                        <ng-select [items]="accessTypeOptions"
                                                   bindValue="value"
                                                   formControlName="customerFulfillmentType"
                                                   [closeOnSelect]="true"
                                                   [dropdownPosition]="'bottom'"
                                                   [required]="true">
                                        </ng-select>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('shipmentTypes')">
                                            {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="isLcl" class="input-item">
                                <div class="phone-number input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.PHONE2' | translate }}</div>
                                    <div class="input-value">
                                        <input id="phone-input-2" type="text" size="30" pInputText
                                               formControlName="phone2"/>
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="address input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.ADDRESS' | translate}}</div>
                                    <div class="input-value address-input-value">
                                        <app-input-field-component
                                                [form]="form"
                                                field="label"
                                                [dataKey]="'value'"
                                                [forceSelection]="true"
                                                [displayDropDown]="true"
                                                (onAutoCompleteClear)="updateZoneForm($event,'village')"
                                                [searchSuggestions]="combinedOptions"
                                                (onSearch)="initVillage(0, 0,$event.query)"
                                                controlName="combined"
                                                [options]="combinedOptions"
                                                [isRequired]="true"
                                                controlType="autoComplete"
                                        >
                                        </app-input-field-component>
                                    </div>
                                </div>
                            </div>

                            <div class="input-item">
                                <div class="address-line1 input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.ADDRESS_DETAILS' | translate}}</div>
                                    <div class="input-value password-value">
                                        <input id="address-input2" type="text" size="30" pInputText
                                               formControlName="addressLine1"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('addressLine1')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.ADDRESS_LINE_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="type === 'customer' && !isLcl">
                                <div *ngIf="userInfo.role != 'HUB_MANAGER'||userInfo.role != 'MULTIPLE_HUBS_MANAGER'" class="input-item">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'PRICING.PRICING_LISTS' | translate}}</div>
                                        <div class="input-value">
                                            <app-input-field-component [form]="form"
                                                                       controlName="pricingListId" field="label"
                                                                       [dataKey]="'value'"
                                                                       [controlType]="'autoComplete'"
                                                                       [searchSuggestions]="pricingListType"
                                                                       (onSearch)="getPricingLists($event)"
                                                                       [forceSelection]="false"
                                                                       [displayDropDown]="true"
                                                                       (onItemChosen)="onItemSelected()"
                                                                       (onAutoCompleteClear)="initPricingLists()"
                                                                        [disabled]="disablePricingListForCustomers()">
                                            </app-input-field-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'ACCOUNTING.CUSTOMER_PAYMENT_TYPE' | translate}}</div>
                                        <div class="input-value">
                                            <app-input-field-component [form]="form"
                                                                       controlName="paymentMethod"
                                                                       controlType="select"
                                                                       [options]="paymentTypeOptions">
                                            </app-input-field-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'CUSTOMER_TYPES.CUSTOMER_TYPE' | translate}}</div>
                                        <div class="input-value">
                                            <app-input-field-component [form]="form"
                                                                       controlName="type"
                                                                       controlType="select"
                                                                       [options]="customerTypes">
                                            </app-input-field-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'ADD_SHIPMENT.SERVICE_TYPE' | translate}}</div>
                                        <div class="input-value">

                                            <ng-select [items]="shipmentServicesOptions"
                                                       [typeahead]="serviceTypesSubject"
                                                       (open)="!shipmentServicesOptions.length && isDynamicServiceTypes ? getDynamicServiceTypes() : null"
                                                       (reset)="getDynamicServiceTypes()"
                                                       [loading]="serviceTypesLoader"
                                                       typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                                       loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                                       notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate}}"
                                                       bindValue="value"
                                                       formControlName="serviceTypes"
                                                       [multiple]="true"
                                                       [closeOnSelect]="false"
                                                       [dropdownPosition]="'bottom'"
                                                       [required]="true"
                                                       appendTo="body"
                                            >
                                            </ng-select>
                                            <div class="invalid-message"
                                                 *ngIf="isInvalidDirtyOrTouched('serviceTypes')">
                                                {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showShipmentTypesInput()" class="input-item">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'ADD_SHIPMENT.SHIPMENT_TYPE' | translate}}</div>
                                        <div class="input-value">

                                            <ng-select [items]="shipmentTypesOptions"
                                                       bindValue="value"
                                                       formControlName="shipmentTypes"
                                                       [multiple]="true"
                                                       [closeOnSelect]="false"
                                                       [dropdownPosition]="'bottom'"
                                                       [required]="true"
                                            >
                                            </ng-select>
                                            <div class="invalid-message"
                                                 *ngIf="isInvalidDirtyOrTouched('shipmentTypes')">
                                                {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="type === 'customer' && (form.controls.paymentMethod?.value === 'BANK_TRANSFER' || form.controls.paymentMethod?.value === 'DIGITAL_WALLET')"
                                     class="input-item">
                                    <div class="business-name input-filed-container">
                                        <div class="input-label">{{(form.controls.paymentMethod?.value === 'BANK_TRANSFER' ? 'USERS.IBAN' : 'USERS.DIGITAL_WALLET_INFO') | translate}}</div>
                                        <div class="input-value">
                                            <input id="iban" type="text" size="30" pInputText formControlName="iban"/>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showCodFees" class="input-item">
                                    <div class="business-name input-filed-container">
                                        <div class="input-label">{{'USERS.COD_CASH_ON_DELIVERY_FEES' | translate}}</div>
                                        <div class="input-value">
                                            <input type="number" pInputText formControlName="codFees"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.HUB' | translate}}</div>
                                        <div class="input-value password-value">
                                            <app-input-field-component [isRequired]="true" controlName="hubId"
                                                                       [form]="form" controlType="select"
                                                                       [options]="hubsOptions"></app-input-field-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item" *ngIf="type==='customer' && isFulfilmentEnabled && (form.get('customerFulfillmentType').value == 'FULFILLMENT' || form.get('customerFulfillmentType').value == 'ALL')">
                                    <div class="user-name input-filed-container">
                                        <div class="input-label">{{'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME' | translate}}</div>
                                        <div class="input-value">
                                            <app-input-field-component
                                                    [form]="form"
                                                    field="label"
                                                    [dataKey]="'value'"
                                                    [forceSelection]="true"
                                                    [displayDropDown]="true"
                                                    (onAutoCompleteClear)="onClearWarehouses($event)"
                                                    [searchSuggestions]="warehousesOptions"
                                                    (onSearch)="getWarehouses($event.query)"
                                                    controlName="warehouseId"
                                                    [options]="warehousesOptions"
                                                    controlType="autoComplete"
                                            >
                                            </app-input-field-component>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-item" [ngClass]="{'col-12 pl-0 pr-0': files.length}">
                                    <div class="user-name input-filed-container" style="min-height: 30px">
                                        <div class="input-label" [ngClass]="{'d-flex justify-content-between': files.length}"><div>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ADD_ATTACHMENTS' | translate }}</div>

                                            <div *ngIf="files.length" class="uploaded-attachments-number flex-2d-center pl-4 pr-4">

                                            </div>
                                        </div>
                                        <div class="input-value">
                                            <div class="uploaded-attachments-container d-flex" *ngIf="files.length">
                                                <div class="view-add-reply-attachments d-flex" *ngIf="!files.length">
                                                    <div class="uploaded-attachments-number flex-2d-center">
                                                        <div class="attachments-label">
                                                            <span>{{'GENERAL.ATTACHMENTS' | translate}}</span>
                                                        </div>
                                                        <div class="attachments-number flex-2d-center">
                                                            <span>{{files.length}}</span>
                                                        </div>

                                                    </div>
                                                    <div class="flex-1 attachment-line-container">
                                                        <div class="attachment-line"></div>
                                                    </div>
                                                </div>
                                                <div class="uploaded-attachments-list d-flex">
                                                    <div class="uploaded-attachment d-flex cursor-pointer" *ngFor="let attachment of files">
                                                        <div class="attachment-type-and-size d-flex">
                                                            <div class="attachment-type">
                                                                <span>{{attachment.name}}</span>
                                                            </div>
                                                            <div class="attachment-size">
                                                                <span>({{attachment.size}} KB)</span>
                                                            </div>
                                                        </div>
                                                        <div class="remove-attachment" (click)="removeAttachment(attachment)">
                                                            <img src="../../assets/icons/ticketing-system/ticket-details/x-icon.png"
                                                                 alt=""/>
                                                        </div>
                                                    </div>
                                                    <div class="icon" style="position: initial;top:initial" *ngIf="files.length < 5 && files.length > 0">
                                                        <img (click)="fileChooser.click()" src="../../../../assets/icons/attachments.svg" alt="icon"  style="margin: 0"/>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="middle" *ngIf="!files.length">
                                                <!--                                                <input pInputText  [ngModel]="attachments"/>-->
                                                <div class="icon" *ngIf="files.length < 5">
                                                    <img (click)="fileChooser.click()" src="../../../../assets/icons/attachments.svg" alt="icon" />
                                                </div>
                                            </div>

                                            <input type="file" multiple (change)="fileChange($event)" class="d-none" #fileChooser>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="type !== 'customer'">
                                <div *ngIf="!userRole && !hideEditUserRole && userRole !== 'HUB_MANAGER'||userRole !== 'MULTIPLE_HUBS_MANAGER'" class="input-item">
                                    <div class="user-role input-filed-container">
                                        <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.USER_ROLE' | translate}}</div>
                                        <div class="input-value password-value">
                                            <app-input-field-component
                                                    [isRequired]="true"
                                                    controlName="role"
                                                    [form]="form"
                                                    (changed)="checkIsDriver($event)"
                                                    controlType="select"
                                                    [options]="userRolesOptions"></app-input-field-component>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isHubUserRole && form.controls['role'].value !== 'FULFILLMENT_USER'"  class="input-item">
                                    <div class="hub-name input-filed-container"
                                         [ngClass]="{'col-lg-12': userRole && !isAdmin}">
                                        <div class="input-label">{{(form.get('role').value === 'STOCKING_AND_PACKING_EMPLOYEE' ? 'USERS_MANAGEMENT.ADD_USER_FORM.WAREHOUSE': 'USERS_MANAGEMENT.ADD_USER_FORM.HUB') | translate}}</div>
                                        <div class="input-value d-flex align-items-center">
                                            <div class="w-100">
                                                <app-input-field-component [isRequired]="true" controlName="hubId" [form]="form" controlType="select" [options]="hubsOptions"> </app-input-field-component>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="{'d-none': !((form.controls['role'].value === 'TICKETING_USER' && userRole != 'TICKETING_USER') || isAdmin || (form.controls['isEnabledAsTicketingUser'].value))}" class="input-item">
                                    <div class="hub-name input-filed-container">
                                        <div class="input-label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.TICKETING_HUB' | translate}}</span>
                                        </div>
                                        <div class="input-value password-value">
                                            <ng-select [loading]="false" appDebounceSelectQuery
                                                       [items]="categories" bindValue="id" bindLabel="name" [multiple]="false"
                                                       [closeOnSelect]="false" (click)="getCategories('')" [required]="((form.controls['role'].value === 'TICKETING_USER' && userRole != 'TICKETING_USER') || (form.controls['isEnabledAsTicketingUser'].value))"
                                                       loadingText="{{ 'TICKETING_SYSTEM.CATEGORIES.TABLE.SECTION_SEARCH' | translate }}"
                                                       notFoundText="{{ 'TICKETING_SYSTEM.CATEGORIES.TABLE.SECTION_NOT_FOUND' | translate }}"
                                                       formControlName="categoryId" (inputDebounced)="getCategories($event)">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                            <div *ngIf="showVehicles()" class="input-item">
                                <div class="vehicle-type-container input-filed-container">
                                    <div class="input-label">{{'CONTAINERS_MANAGEMENT.VEHICLE' | translate}}</div>
                                    <div class="input-value vehicle-value">
                                        <p-autoComplete formControlName="vehicleId"
                                                        type="text"
                                                        [forceSelection]="true"
                                                        [suggestions]="vehicles"
                                                        (completeMethod)="getVehicles($event)"
                                                        (onSelect)="chooseVehicle($event)"
                                                        styleClass="auto-complete-filter-selection"
                                                        #vehiclesAc
                                        >
                                            <ng-template let-vehicle pTemplate="item">
                                                <div class="address-item">
                                                    <div class="store-name text-ellipsis">{{vehicle.label}}</div>
                                                    <!--                                            <div class="store-name text-ellipsis">{{vehicle.name + ' - ' + address.cityName + ' - ' + address.regionName}}</div>-->
                                                </div>
                                            </ng-template>
                                        </p-autoComplete>
                                        <div class="close-edit-icons">
                                            <i class="fas fa-chevron-down arrow-icon" *ngIf="!editVehicle"
                                               (click)="editVehicleInput()"></i>
                                            <i class="fas fa-times close-refresh-soup" *ngIf="editVehicle"
                                               (click)="resetVehicle()"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="type == 'customer' && ((userInfo.isFulfilmentEnabled && form.get('customerFulfillmentType').value !== 'FULFILLMENT') || !userInfo.isFulfilmentEnabled)" class="customer-switches-container toggle-buttons-container">
                            <div class="d-flex toggles-container">
                                <div class="toggle-button-container" *ngIf="showAddDraftPackagesSetting()">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isAddDraftPackages"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ADD_PACKAGES_DRAFTS' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showRequiredAttachmentsSetting()" class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isAllowNoAttachment"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ATTACHMENTS_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="isAllowToCreateBundles()" class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isAllowAddBundles"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ALLOW_TO_CREATE_BUNDLES' | translate}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="!isLcl" class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch
                                                    formControlName="isShowSenderAddressInPackageReport"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.SHOW_SENDER_ADDRESS' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-button-container" *ngIf="showIsBilledSwitchSetting()">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isBilled"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'MANAGE_COD_CUSTOMERS.INVOICES.ENABLE_BILLING_SYSTEM' | translate}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="!isLcl" class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isHideCostForMembers"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.HIDE_COST_FOR_MEMBERS' | translate }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="isAllowAddingTelecomeDevices" class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isTelecom"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_TELECOM' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isShowMemberPackagesOnly"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_MEMBER_PACKAGES_ONLY' | translate}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isShowSenderNameInPackageReport"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label"><span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_SENDER_NAME_IN_PACKAGE_REPORT' | translate}}</span></div>
                                    </div>
                                </div>
                                <div *ngIf="isEnableCustomerConfirmationForReceivingMassCodPkg && type === 'customer'"
                                     class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch
                                                    formControlName="isEnableConfirmationForReceivingMassCodPkg"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_ENABLE_CONFIRMATION_FOR_RECEIVING_MASS_COD_PKG' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-button-container">
                                    <div class="toggle-button d-inline-flex">
                                        <div class="toggle-input">
                                            <p-inputSwitch formControlName="isShowCodSumInCustomerPortalCodReports"></p-inputSwitch>
                                        </div>
                                        <div class="toggle-btn-hidden-space"></div>
                                        <div class="label">
                                            <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_COD_SUM_IN_CUSTOMER_PORTAL_COD_REPORTS' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="type === 'user'" class="row toggle-buttons-container">
                            <ng-container *ngIf="form.controls['role'].value === 'DRIVER'">
                                <div class="toggle-button w-50 d-inline-flex" *ngIf="isAllowDriverToReturnPackages">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isReturnPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_RETURN_PACKAGES' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isPostponePackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_POSTPONE_PACKAGES' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isDeliverPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_DELIVER_PACKAGES' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isDeliverReturnedPackagesToSender"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_DELIVER_RETURNED_PACKAGES_TO_SENDER' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isHideSenderInfo"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_HIDE_SENDER_INFO' | translate}}</span>
                                    </div>
                                </div>
                                <div *ngIf="this.form.controls['isHideSenderInfo'].value"
                                     class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isShowSenderPhone"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_SENDER_PHONE' | translate }}</span>
                                    </div>
                                </div>
                                <div *ngIf="this.form.controls['isHideSenderInfo'].value"
                                     class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isShowSenderName"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_SENDER_NAME' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isDeliverMassCodPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_DELIVER_MASS_COD_PACKAGES' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isHideReceiverInfo"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_HIDE_RECEIVER_INFO' | translate}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex" *ngIf="isEdit">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isBlocked"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.BLOCK' | translate}}</span></div>
                                </div>

                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isDriverCanFailPackageDisabled"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_DRIVER_CAN_FAIL_PACKAGE_DISABLED' | translate}}</span>
                                    </div>
                                </div>

                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isSignatureOnPackageDeliveryDisabled"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SIGNATURE_ON_PACKAGE_DELIVERY_DISABLED' | translate}}</span>
                                    </div>
                                </div>

                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isDriverPickupPackagesByScanDisabled"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_DRIVER_PICKUP_PACKAGES_BY_SCAN_DISABLED' | translate}}</span>
                                    </div>
                                </div>

                                <div *ngIf="isHidePackagesInDriversApps()" class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isShowPackagesInDriverApp"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_PACKAGES_IN_DRIVER_APP' | translate}}</span>
                                    </div>
                                </div>

                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isAllowDriverRejectingOrders"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'COMPANY.IS_ALLOW_DRIVER_REJECTING_ORDERS' | translate}}</span></div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex" *ngIf="userInfo.isFulfilmentEnabled">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isPickupFulfillmentPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'COMPANY.PICK_WAREHOUSE_PACKAGES' | translate}}</span></div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isAllowAddingPackagesOnAllHub() || (form.controls['role'].value === 'CLERK')">
                                <div *ngIf="isAllowAddingPackagesOnAllHub()" class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isAllowAddingPackagesOnAllHub"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_ALLOW_ADDING_PACKAGES_ON_ALL_HUB' | translate | hubTitle}}</span>
                                    </div>
                                </div>
                                <div *ngIf="type === 'user' && (form.controls['role'].value === 'CLERK' || form.controls['role'].value === 'HUB_CLERK')"
                                     class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isAllowClerkToAddCustomers"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_ALLOW_CLERK_TO_ADD_CUSTOMERS' | translate}}</span>
                                    </div>
                                </div>
                            </ng-container>
<!---->
                            <ng-container>
                            <div *ngIf="type === 'user' && (form.controls['role'].value === 'CLERK')"
                                 class="toggle-button w-50 d-inline-flex">
                                <div class="toggle-input">
                                    <p-inputSwitch formControlName="isAllowClerkChangeStatus"></p-inputSwitch>
                                </div>
                                <div class="toggle-btn-hidden-space"></div>
                                <div class="label">
                                    <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_ALLOW_TO_CHANGE_PACKAGE_STATUS' | translate}}</span>
                                </div>
                            </div>
                            </ng-container>
<!---->


                            <ng-container
                                    *ngIf="type === 'user' && (form.controls['role'].value === 'CUSTOMER_CARE')">
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isReturnPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ACCESS_FOR_RETURNED_PACKAGES' | translate | hubTitle}}</span>
                                    </div>
                                </div>
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isSwapSenderAndReceiver"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.SWAP_SENDER_AND_RECEIVER' | translate}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="form.controls['role'].value === 'CLERK'">
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch formControlName="isReturnPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ACCESS_FOR_RETURNED_PACKAGES' | translate | hubTitle}}</span>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="isNotifyCCWhenDeliveryPackageAndChangeCod()">
                                <div class="toggle-button d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isDisableNotifyCCWhenDeliveryPkgAndChangeCOD"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_DISABLE_NOTIFY_CC_WHEN_DELIVERY_PKG_AND_CHANGE_COD' | translate}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="form.controls['role'].value !== 'TICKETING_SYSTEM_ADMIN' &&
                                        form.controls['role'].value !== 'DRIVER' &&
                                        form.controls['role'].value !== 'TICKETING_USER' &&
                                        form.controls['role'].value !== 'ADMIN' &&
                                        form.controls['role'].value !== 'STOCKING_AND_PACKING_EMPLOYEE' &&
                                        form.controls['role'].value !== 'HANDLER' &&
                                        form.controls['role'].value !== 'FULFILLMENT_USER' &&
                                        isTicketingSystemEnabled"
                                 class="toggle-button w-50 d-inline-flex">
                                <div class="toggle-input">
                                    <p-inputSwitch formControlName="isEnabledAsTicketingUser"></p-inputSwitch>
                                </div>
                                <div class="toggle-btn-hidden-space"></div>
                                <div class="label">
                                    <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ENABLE_AS_TICKETING_USER' | translate}}</span>
                                </div>
                            </div>
                            <ng-container *ngIf="form.controls['role'].value === 'DISPATCHER'">
                                <div class="toggle-button d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isMapViewOnly"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.SHOW_MAPS_ONLY' | translate}}</span>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="form.controls['role'].value === 'DISPATCHER'">
                                <div class="toggle-button w-50 d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isAllowAddingPackages"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.ADD_PACKAGES' | translate}}</span>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="form.controls['role'].value === 'DISPATCHER'">
                                <div class="toggle-button d-inline-flex">
                                    <div class="toggle-input">
                                        <p-inputSwitch
                                                formControlName="isShowCurrentReports"></p-inputSwitch>
                                    </div>
                                    <div class="toggle-btn-hidden-space"></div>
                                    <div class="label">
                                        <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.SHOW_CURRENT_REPORTS' | translate}}</span>
                                    </div>
                                </div>
                            </ng-container>


                        </div>
                        <div *ngIf="isAutoAssignDriversToPackages && type === 'customer' && ((userInfo.isFulfilmentEnabled && form.get('customerFulfillmentType').value !== 'FULFILLMENT') || !userInfo.isFulfilmentEnabled)"
                             class="row toggle-buttons-container">
                            <div class="toggle-button d-inline-flex">
                                <div class="toggle-input">
                                    <p-inputSwitch formControlName="isAutoAssignPkgsToDriver"
                                                   [disabled]="isEnableAutoAssignForAllCustomers"></p-inputSwitch>
                                </div>
                                <div class="toggle-btn-hidden-space"></div>
                                <div class="label">
                                    <span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_AUTO_ASSIGN_PKGS_TO_DRIVER' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row splitter-container w-100 mt-5">
                            <div class="splitter"></div>
                        </div>
                        <div class="row actions" style="width: 100%;" [ngClass]="{'actions-user': type !== 'customer'}">
                            <div class="cancel cursor-pointer" (click)="exitAddNewUser()">
                                <span>{{'ACTIONS.CANCEL' | translate}}</span>
                            </div>
                            <div *ngIf="isLoading" class="loading">
                                <span><i class="fa fa-spinner fa-pulse"></i></span>
                            </div>
                            <div *ngIf="!isLoading"
                                 class="{{doneActionStyleClass +  ' cursor-pointer' + ' add-user-btn'}}"
                                 (click)="onAddUser()"
                                 [ngClass]="{'disabled-btn disabled': !form?.valid || disableForm}">
                                <span *ngIf="!isEdit">{{'ACTIONS.DONE' | translate}}</span>
                                <span *ngIf="isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
    </div>
</div>
