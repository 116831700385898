<p-toast baseZIndex="1000">
</p-toast>
<div class="add-shipment-container">
    <div id="submitting-container" *ngIf="isSubmitting">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form *ngIf="form; else spinner" [formGroup]="form">
        <div class="card-div">
            <div class="route-information row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 sender-information ">
                    <div class="col-12 card" style="padding-bottom: 15px">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{"ADD_SHIPMENT.SENDER_INFO" | translate}}
                            </div>
                            <div style="font-size: 1.7em" class="information-title-action cursor-pointer"
                                 (click)="chooseExisting(false)">
                                {{"ACTIONS.CHOOSE_EXISTING" | translate}}
                            </div>
                        </div>
                        <div class="input-fields row">
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4 "
                                                       controlName="senderFirstName"
                                                       label="USERS.FIRST_NAME" [isRequired]="true" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       controlName="senderMiddleName"
                                                       label="USERS.MIDDLE_NAME" [isRequired]="false"
                                                       controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       controlName="senderLastName"
                                                       label="USERS.LAST_NAME" [isRequired]="true"
                                                       controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       controlName="businessSenderName"
                                                       label="USERS.BUSINESS_NAME"
                                                       controlType="text">
                            </app-input-field-component >

                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       controlName="senderPhone"
                                                       label="ADD_SHIPMENT.SENDER_PHONE" [isRequired]="true"
                                                       controlType="number">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       controlName="senderPhone2"
                                                       label="ADD_SHIPMENT.SENDER_PHONE2" [isRequired]="false"
                                                       controlType="number">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-6" controlName="senderEmail"
                                                       label="ADD_SHIPMENT.SENDER_EMAIL" [isRequired]="true"
                                                       controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-6"
                                                       controlName="senderAuthorizedGovRegistrationNumber"
                                                       label="USERS.REGISTRATION_NUMBER"
                                                       controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 receiver-information ">
                    <div class="col-12 card" style="padding-bottom: 15px">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{"ADD_SHIPMENT.RECEIVER_INFO" | translate}}
                            </div>
                            <div style="font-size: 1.7em" class="information-title-action cursor-pointer"
                                 (click)="chooseExisting(true)">
                                {{"ACTIONS.CHOOSE_EXISTING" | translate}}
                            </div>
                        </div>
                        <div class="input-fields row">
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       label="USERS.FIRST_NAME"
                                                       controlName="receiverFirstName"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       label="USERS.MIDDLE_NAME"
                                                       controlName="receiverMiddleName"
                                                       [isRequired]="false" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       label="USERS.LAST_NAME"
                                                       controlName="receiverLastName"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-lg-4 col-md-6 col-4"
                                                       label="USERS.BUSINESS_NAME"
                                                       controlName="businessReceiverName"
                                                       controlType="text">
                            </app-input-field-component >

                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_PHONE" [form]="form"
                                                       controlName="receiverPhone"
                                                       [isRequired]="true" controlType="number">
                            </app-input-field-component >

                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_PHONE2" [form]="form"
                                                       controlName="receiverPhone2"
                                                       [isRequired]="false" controlType="number">
                            </app-input-field-component >
                            <app-input-field-component class="col-6" label="ADD_SHIPMENT.RECEIVER_EMAIL" [form]="form"
                                                       controlName="receiverEmail"
                                                       [isRequired]="false" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component class="col-6" label="USERS.REGISTRATION_NUMBER" [form]="form"
                                                       controlName="receiverAuthorizedGovRegistrationNumber"
                                                       controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 travel-information">
                    <div class="col-12 card" style="padding-bottom: 15px">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{"ADD_SHIPMENT.TRAVEL_INFO" | translate}}
                            </div>
                            <div *ngIf="!isEditMode"
                                 class="information-title-action travel-information-radio-button d-flex"
                                 style="flex-wrap: wrap;">
                                <div>
                                    <input type="radio" [value]="false" formControlName="addressFromIsHub"
                                           (change)="changeAddressType()">
                                    <span>{{"ADD_SHIPMENT.ADDRESS_FROM_SENDER_ADDRESS" | translate}}</span>

                                </div>
                                <div>
                                    <input type="radio" [value]="true" formControlName="addressFromIsHub"
                                           (change)="changeAddressType()">
                                    <span>{{"ADD_SHIPMENT.ADDRESS_FROM_HUB" | translate | hubTitle}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="input-fields row">
                            <ng-container *ngIf="!form.value.addressFromIsHub;else hubList">
                                <app-input-field-component class="col-lg-7 col-sm-12 col-7"
                                                           label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originVillage')"
                                                           [searchSuggestions]="combinedOptions"
                                                           (onSearch)="initVillage(0, 0,$event.query)"
                                                           controlName="originCombined" [options]="combinedOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >
                                <div class="show-more-container col-lg-5 col-sm-12 col-5">
                                    <div class="controller-button show-more-button next cursor-pointer"
                                         (click)="toggleshowDetails(true)">
                                        <i *ngIf="!showDetailsSender"
                                           class="fas fa-plus receiver-more-option"></i>
                                        <i *ngIf="showDetailsSender"
                                           class="fas fa-minus receiver-more-option"></i>
                                        <span style="margin-left: 15px">
                                                    {{ (showDetailsSender ? "ACTIONS.LESS" : "ACTIONS.MORE") | translate }}
                                                    </span>
                                    </div>
                                </div>
                                <!--<app-field-input-field-component  class="col-lg-4 col-md-6 col-4"-->
                                                           <!--label="ADD_SHIPMENT.SENDER_COUNTRY"-->
                                                           <!--[form]="form" *ngIf="showDetailsSender"-->
                                                           <!--controlName="originCountry"-->
                                                           <!--[options]="[{label: 'Palestine', value: 'Palestine'}]"-->
                                                           <!--[isRequired]="true" controlType="select">-->
                                <!--</app-field-input-field-component >-->
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_REGION"
                                                           *ngIf="showDetailsSender"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           [searchSuggestions]="regionOptions"
                                                           (onSearch)="initRegions($event.query)"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originRegion')"
                                                           controlName="originRegion" [options]="regionOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_CITY"
                                                           *ngIf="showDetailsSender"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           (onSearch)="initCities(0, form.controls['originRegion'].value.value,$event.query)"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originCity')"
                                                           [searchSuggestions]="citiesOptions"
                                                           controlName="originCity" [options]="citiesOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >

                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_VILLAGE"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           *ngIf="showDetailsSender"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originVillage')"
                                                           [searchSuggestions]="villageOptions"
                                                           (onSearch)="initVillage(0, form.controls['originCity']?.value?.value,$event.query)"
                                                           controlName="originVillage" [options]="villageOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_STREET"
                                                           [form]="form"
                                                           controlName="originAddressLine1"
                                                           [isRequired]="true" controlType="text">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_MORE"
                                                           [form]="form"
                                                           controlName="originAddressLine2"
                                                           [isRequired]="false" controlType="text">
                                </app-input-field-component >
                            </ng-container>
                            <ng-template #hubList>
                                <app-input-field-component class="col-lg-7 col-sm-12 col-7"
                                                           label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originVillage')"
                                                           [searchSuggestions]="combinedOptions"
                                                           (onSearch)="initVillage(0, 0,$event.query)"
                                                           controlName="originCombined" [options]="combinedOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >
                                <div class="show-more-container col-lg-5 col-sm-12 col-5">
                                    <div class="controller-button show-more-button next cursor-pointer"
                                         (click)="toggleshowDetails(true)">
                                        <i *ngIf="!showDetailsSender"
                                           class="fas fa-plus receiver-more-option"></i>
                                        <i *ngIf="showDetailsSender"
                                           class="fas fa-minus receiver-more-option"></i>
                                        <span style="margin-left: 15px">
                                                    {{ (showDetailsSender ? "ACTIONS.LESS" : "ACTIONS.MORE") | translate }}
                                                    </span>
                                    </div>
                                </div>
                                <!--<app-field-input-field-component  class="col-lg-4 col-md-6 col-4"-->
                                                           <!--label="ADD_SHIPMENT.SENDER_COUNTRY"-->
                                                           <!--[form]="form"-->
                                                           <!--*ngIf="showDetailsSender"-->
                                                           <!--controlName="originCountry"-->
                                                           <!--[options]="[{label: 'Palestine', value: 'Palestine'}]"-->
                                                           <!--[isRequired]="true" controlType="select">-->
                                <!--</app-field-input-field-component >-->
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_REGION"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           [searchSuggestions]="regionOptions"
                                                           (onSearch)="initRegions($event.query)"
                                                           [forceSelection]="true"
                                                           *ngIf="showDetailsSender"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originRegion')"
                                                           controlName="originRegion" [options]="regionOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_CITY"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           *ngIf="showDetailsSender"
                                                           (onSearch)="initCities(0, form.controls['originRegion'].value.value,$event.query)"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originCity')"
                                                           [searchSuggestions]="citiesOptions"
                                                           controlName="originCity" [options]="citiesOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >

                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_VILLAGE"
                                                           *ngIf="showDetailsSender"
                                                           [form]="form" field="label" [dataKey]="'value'"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'originVillage')"
                                                           [searchSuggestions]="villageOptions"
                                                           (onSearch)="initVillage(0, form.controls['originCity']?.value?.value,$event.query)"
                                                           controlName="originVillage" [options]="villageOptions"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_STREET"
                                                           [form]="form"
                                                           controlName="originAddressLine1"
                                                           [isRequired]="true" controlType="text">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_MORE" [form]="form"
                                                           controlName="originAddressLine2"
                                                           [isRequired]="false" controlType="text">
                                </app-input-field-component >
                                <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                           label="ADD_SHIPMENT.SENDER_HUB" [form]="form"
                                                           controlName="hubId" [disabledDefaultOption]="false"
                                                           [options]="hubsOptions"
                                                           [isRequired]="true" controlType="select">
                                </app-input-field-component >
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 travel-information">
                    <div class="col-12 card" style="padding-bottom: 15px">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{"ADD_SHIPMENT.TRAVEL_INFO" | translate}}
                            </div>
                            <div class="information-title-action">
                            </div>
                        </div>
                        <div class="input-fields row">
                            <app-input-field-component class="col-lg-7 col-sm-12 col-7"
                                                       label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                       [form]="form" field="label" [dataKey]="'value'"
                                                       [forceSelection]="true"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationVillage')"
                                                       [searchSuggestions]="combinedOptions1"
                                                       (onSearch)="initVillage(1, 0,$event.query)"
                                                       controlName="destinationCombined" [options]="combinedOptions1"
                                                       [isRequired]="true" controlType="autoComplete">
                            </app-input-field-component >
                            <div class="show-more-container col-lg-5 col-sm-12 col-5">
                                <div class="controller-button show-more-button next cursor-pointer"
                                     (click)="toggleshowDetails(false)">
                                    <i *ngIf="!showDetailsReceiver"
                                       class="fas fa-plus receiver-more-option"></i>
                                    <i *ngIf="showDetailsReceiver"
                                       class="fas fa-minus receiver-more-option"></i>
                                    <span style="margin-left: 15px">
                                                    {{ (showDetailsReceiver ? "ACTIONS.LESS" : "ACTIONS.MORE") | translate }}
                                                    </span>
                                </div>
                            </div>
                            <!--<app-field-input-field-component  class="col-lg-4 col-md-6 col-4"-->
                                                       <!--label="ADD_SHIPMENT.RECEIVER_COUNTRY" [form]="form"-->
                                                       <!--*ngIf="showDetailsReceiver"-->
                                                       <!--controlName="destinationCountry"-->
                                                       <!--[options]="[{label: 'Palestine', value: 'Palestine'}]"-->
                                                       <!--[isRequired]="true" controlType="select">-->
                            <!--</app-field-input-field-component >-->
                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_REGION" [form]="form"
                                                       controlName="destinationRegion" [options]="regionOptions1"
                                                       [isRequired]="true" controlType="autoComplete"
                                                       (onSearch)="initRegions($event.query)"
                                                       field="label" [dataKey]="'value'"
                                                       *ngIf="showDetailsReceiver"
                                                       [forceSelection]="true" [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationRegion')"
                                                       [searchSuggestions]="regionOptions1">
                            </app-input-field-component >
                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_CITY" [form]="form"
                                                       controlName="destinationCity" [options]="citiesOptions1"
                                                       [isRequired]="true" controlType="autoComplete"
                                                       field="label" [dataKey]="'value'"
                                                       *ngIf="showDetailsReceiver"
                                                       (onSearch)="initCities(1, form.controls['destinationRegion'].value.value,$event.query)"
                                                       [forceSelection]="true" [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationCity')"
                                                       [searchSuggestions]="citiesOptions1">
                            </app-input-field-component >

                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_VILLAGE" [form]="form"
                                                       controlName="destinationVillage" [options]="villageOptions1"
                                                       [isRequired]="true" controlType="autoComplete"
                                                       field="label" [dataKey]="'value'"
                                                       *ngIf="showDetailsReceiver"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationVillage')"
                                                       (onSearch)="initVillage(1, form.controls['destinationCity']?.value?.value,$event.query)"
                                                       [forceSelection]="true" [displayDropDown]="true"
                                                       [searchSuggestions]="villageOptions1">
                            </app-input-field-component >
                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_STREET" [form]="form"
                                                       controlName="destinationAddressLine1"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component class="col-lg-4 col-md-6 col-4"
                                                       label="ADD_SHIPMENT.RECEIVER_MORE" [form]="form"
                                                       controlName="destinationAddressLine2"
                                                       [isRequired]="false" controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-information">
                <div class="information-title-container">
                    <div class="information-title-label">
                        {{"ADD_SHIPMENT.PRODUCT_INFORMATION" | translate}}
                    </div>
                    <div class="information-title-action">
                    </div>
                </div>
                <div class="products-list">
                    <app-product-table-component [disabled]="canEdit('products') || isDisabled" [(products)]="products"
                                                 (onProductsChange)="onProductsChange($event)">
                    </app-product-table-component>
                </div>
            </div>
            <div class="row ">
                <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 shipment-information card"
                     style="padding-bottom: 20px;">
                    <div class="information-title-container">
                        <div class="information-title-label">
                            {{"ADD_SHIPMENT.SHIPMENT_INFORMATION" | translate}}
                        </div>
                        <div class="information-title-action">
                        </div>
                    </div>
                    <div class="input-fields row">
                        <div class="col-4 cod-input d-flex" style="align-items: center;">
                            <p-radioButton
                                    value="METRIC"
                                    name="pkgUnitType"
                                    formControlName="pkgUnitType"
                                    label="{{ 'UNITS.METRIC' | translate }}"
                                    (onClick)="distanceUnit = 'CM'; weightUnit = 'KG'"></p-radioButton>
                        </div>

                        <div class="col-4 cod-input d-flex" style="align-items: center;">
                            <p-radioButton
                                    name="pkgUnitType"
                                    value="INCHES"
                                    formControlName="pkgUnitType"
                                    label="{{ 'UNITS.INCHES' | translate }}"
                                    (onClick)="distanceUnit = 'INCH'; weightUnit = 'POUND'"></p-radioButton>
                        </div>
                        <app-input-field-component class="col-4" label="PARCEL.PARCEL_TYPE"
                                                   [form]="form"
                                                   controlName="parcelId"
                                                   controlType="select" [disabledDefaultOption]="false"
                                                   [options]="parcelOptions">
                        </app-input-field-component >
                        <app-input-field-component class="col-4" label="ADD_SHIPMENT.TOTAL_WEIGHT" [isRequired]="true"
                                                   [form]="form"
                                                   controlName="weight"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-4" label="ADD_SHIPMENT.TYPE_SHIPMENT" [isRequired]="false"
                                                   [form]="form" controlName="type" controlType="select"
                                                   [options]="shipmentTypesOptions">
                        </app-input-field-component >
                        <input *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                               style="margin-right: 190px;margin-top: 44px"
                               type="radio" [value]="'COLLECT'"
                               name="swapOrBring"
                               formControlName="swapOrBring"
                               (change)="changeSwapOrBring()">
                        <app-input-field-component *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                                                   class="col-3" label="ADD_SHIPMENT.TO_COLLECT_FROM_RECEIVER"
                                                   [isRequired]="true" [form]="form" controlName="toCollectFromReceiver" controlType="number">
                        </app-input-field-component >
                        <input *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                               type="radio" [value]="'PAY'"
                               name="swapOrBring"
                               style="margin-top: 44px"
                               formControlName="swapOrBring"
                               (change)="changeSwapOrBring()">
                        <app-input-field-component *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                                                   class="col-3" label="ADD_SHIPMENT.TO_PAY_TO_RECEIVER"
                                                   [isRequired]="true" [form]="form" controlName="toPayToReceiver"
                                                   controlType="number">
                        </app-input-field-component >

                        <app-input-field-component class="col-4 cod-input" label="ADD_SHIPMENT.COD"
                                                   [ngClass]="{'hide': form.controls.type.value !== 'COD'}"
                                                   [isRequired]="true" [form]="form" controlName="cod"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-4" label="ADD_SHIPMENT.LENGTH" [isRequired]="true"
                                                   [form]="form"
                                                   controlName="length"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-4" label="ADD_SHIPMENT.WIDTH" [isRequired]="true"
                                                   [form]="form"
                                                   controlName="width"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-4" label="ADD_SHIPMENT.HEIGHT" [isRequired]="true"
                                                   [form]="form"
                                                   controlName="height"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-4" label="PRODUCT.QUANTITY" [isRequired]="true"
                                                   [form]="form"
                                                   controlName="quantity"
                                                   controlType="number">
                        </app-input-field-component >
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 timings-information ">
                    <div class="'col-12 card">

                        <div class="information-title-container" style="margin: 0 10px;">
                            <div class="information-title-label">
                                {{"ADD_SHIPMENT.TIMINGS" | translate}}
                            </div>
                            <div class="information-title-action">
                            </div>
                        </div>
                        <div class="row" *ngIf="showCreatedDate()">
                            <app-input-field-component class="col" label="ADD_SHIPMENT.CREATED_DATE"
                                                       [isRequired]="true"
                                                       [form]="form"
                                                       controlName="createdDate"
                                                       controlType="date">
                            </app-input-field-component >
                        </div>
                        <div class="input-fields row">
                            <app-input-field-component class="col-6" [note]="deliveryDateNote"
                                                       label="ADD_SHIPMENT.DELIVERY_DATE" [isRequired]="true"
                                                       [form]="form"
                                                       controlName="deliveryDate"
                                                       controlType="date">
                            </app-input-field-component >
                            <app-input-field-component class="col-6" label="ADD_SHIPMENT.SERVICE_TYPE"
                                                       [isRequired]="true"
                                                       [form]="form"
                                                       controlName="service"
                                                       controlType="select" [options]="shipmentServicesOptions">
                            </app-input-field-component >
                        </div>
                        <div class="row">
                            <div class="col-6" *appPermission="isAllowedEditCost()">
                                <app-input-field-component class="col-4" label="ADD_SHIPMENT.TOTAL_FREIGHT"
                                                           [form]="form"
                                                           controlName="freight"
                                                           controlType="number">
                                </app-input-field-component >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px!important;">
                <app-input-field-component class="col-6" label="ADD_SHIPMENT.INVOICE_NUMBER"
                                           [isRequired]="false"
                                           [form]="form"
                                           controlName="invoice">
                </app-input-field-component >
                <app-input-field-component class="col-6" label="ADD_SHIPMENT.SUPPLIER_INVOICE"
                                           [isRequired]="false"
                                           [form]="form" *ngIf="isEditMode"
                                           controlName="supplierInvoice">
                </app-input-field-component >
            </div>

            <div class="row">
                    <span class="col-lg-3 col-md-3 col-sm-6">
                        <p-checkbox label="{{'ADD_SHIPMENT.FRAGILE' | translate}}" value="isFragile"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                <span class="col-lg-4 col-md-4 col-sm-6">
                        <p-checkbox label="{{'ADD_SHIPMENT.H&M' | translate}}" value="isflammable"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                <span class="col-lg-3 col-md-3 col-sm-6">
                        <p-checkbox label="{{'ADD_SHIPMENT.NEED_PACKING' | translate}}" value="isNeedPackaging"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                <span *ngIf="allowAddingPackageWithFingerprint()" class="col-lg-3 col-md-3 col-sm-6">
                        <p-checkbox label="{{'ADD_SHIPMENTS.FINGER_PRINT' | translate}}" value="isFingerprintRequired"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="notes-container">
                        <span>{{'GENERAL.NOTES' | translate}}</span>
                        <textarea formControlName="notes" placeholder="{{'GENERAL.NOTES' | translate}}..." value="">
                        </textarea>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="notes-container">
                        <span>{{'GENERAL.CONTENTS' | translate}}</span>
                        <textarea formControlName="description" placeholder="{{'GENERAL.CONTENTS' | translate}}..." value="">
                        </textarea>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                    <div class="notes-container">
                        <span>{{'GENERAL.PRIVATE_NOTES' | translate}}</span>
                        <textarea formControlName="adminNotes" placeholder="{{'GENERAL.PRIVATE_NOTES' | translate}}..." value="">
                        </textarea>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div class="row form-buttons">
                        <div *ngIf="!isDisabled" class="col-5 add-shipment-button cursor-pointer"
                             (click)="addShipment()"
                             [ngClass]="{'disable': !form.valid}">
                            <i class="far fa-save"></i>
                            {{(isEditMode ? 'ACTIONS.EDIT' : 'ACTIONS.ADD') | translate}}
                        </div>
                        <div *ngIf="!isDisabled" class="col-5 reset-fields-button cursor-pointer" (click)="resetForm()">
                            <i class="fas fa-times"></i>
                            {{"ACTIONS.RESET_FIELDS" | translate}}
                        </div>
                        <div *ngIf="isDisabled" class="col-5 add-shipment-button cursor-pointer"
                             (click)=" activeModal.close({});">
                            <i class="fas fa-times"></i>
                            {{"ACTIONS.CLOSE" | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template #spinner>
        <div style="text-align: center;">
            <p-progressSpinner>
            </p-progressSpinner>
        </div>
    </ng-template>
</div>
