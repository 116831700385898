import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ProductModel} from '../../shared/models/product.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PackageModel} from '../../shared/models/package.model';
import {ShipmentsService} from '../services/shipments.service';
import {DatePipe} from '@angular/common';
import {GeographyService} from '../../shared/services/geography.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Params} from '@angular/router';
import {ZonesService} from '../../administration/services/zones.service';
import {HubsService} from '../../shared/services/hubs.service';
import {UtilitiesService} from '../../shared/services/utilities.service';
import {ParcelService} from '../../administration/services/parcel.service';
import {ChooseExistingComponent} from '../choose-existing/choose-existing.component';
import {UserModel} from '../../shared/models/user.model';
import {Address} from '../../shared/models/address.class';
import * as _ from 'lodash';
import {UsersService} from '../../users/services/users.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NewUserComponent} from '../../users/create-new-user/new-user.component';
import {DriversService} from '../../shared/services/drivers.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-add-shipment-quick',
    templateUrl: './add-shipment-quick.component.html',
    styleUrls: ['./add-shipment-quick.component.scss']
})
export class AddShipmentQuickComponent extends Address implements OnInit {

    public products: ProductModel[] = [
        {
            name: '',
            quantity: 1,
            weight: 1,
            eachCost: 10,
            totalCost: 10
        }
    ];

    /**
     * Fields which can be edited.
     * some fields are forbidden to be edited by some user roles.
     */
    public editedFields = 'all';

    public shipmentServicesOptions: { value: string, label: string }[];
    public shipmentTypesOptions: { value: string, label: string }[];
    public hubsOptions: { value: string, label: string }[];
    public driversOptions: { value: string, label: string }[];

    public form: FormGroup;
    public isEditMode = false;
    public edittedPkg: PackageModel;
    public isLoading = false;
    public deliveryDateNote = '';
    public isSubmitting = false;
    public extraOptions = [];
    public parcelOptions = [];
    public parcelList = [];
    private pkgId = 0;
    public isDisabled = false;
    public isArabic = false;
    public users = [];
    public isHubManager = false;
    public printType = 'PRINT';
    public swapOrBring = 'COLLECT';
    public userHubId = 0;
    public userHub = null;
    public userDriver = null;
    public userDriverId = 0;
    public permissions = '';
    public showDetailsSender = false;
    public showDetailsReceiver = false;
    public addressFromIsHub = true;
    public holdedCreatedDate: string;
    public isPickup = false;
    public customerId;
    public pickup;
    public imageUrl = '';
    public pickupIndex: number;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public pageNumber = 1;
    public totalRecordNumber;
    public pickupsToConvert = [];
    public numberOfConvertedPickups = 0;
    public isMultiPickups = false;
    public label = 'SHIPMENT.ADD';
    public submitLabel = 'ACTIONS.ADD';
    @ViewChild('receiverName', {static: false}) receiverName;
    @ViewChild('destinationAddressLine1', {static: false}) destinationAddressLine1;

    constructor(private formBuilder: FormBuilder,
                private shipmentService: ShipmentsService,
                private datePipe: DatePipe,
                private geographyService: GeographyService,
                private modalService: NgbModal,
                public translateService: TranslateService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private route: ActivatedRoute,
                private zonesService: ZonesService,
                private hubsService: HubsService,
                private utilitiesService: UtilitiesService,
                private parcelService: ParcelService,
                private activeModal: NgbActiveModal,
                private usersService: UsersService,
                private rolesService: RolesService,
                private authenticationService: AuthenticationService,
                private driversService: DriversService) {
        super(zonesService, translateService, true);
        this.allVillages = true;
        this.holdedCreatedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    ngOnInit() {

        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.userHubId = this.userService.userInfo.hubId;
        if (this.userHubId === 0) {
            this.userHubId = undefined;
        }
        this.isHubManager = (role === 'HUB_MANAGER' || role==='MULTIPLE_HUBS_MANAGER');
        this.isArabic = this.translateService.currentLang === 'ar';
        this.printType = this.isPickup ? 'SAVE' : 'PRINT';
        this.route.queryParams.subscribe(
            (params: Params) => {
                if (this.pkgId) {
                    // this.isDisabled = true;
                } else {
                    const pkgId = params.pkgId;
                    this.pkgId = pkgId;
                }
                if (this.pkgId) {
                    this.isEditMode = true;
                    this.fetchEditedPkg(this.pkgId);
                    if (params.edit) {
                        this.editedFields = params.edit;
                    }
                } else {
                    this.initOptions();
                }
            }
        );

        this.translateService.onLangChange.subscribe((event) => {
            this.parseParcels();
        });

        if (this.isMultiPickups) {
            this.getPickupsToConvertCount();
            this.pickupIndex = 0;
            this.getPickupsToConvert(this.pageSize, this.pageNumber++);
            this.label = 'SHIPMENT.CONVERT_MULTI_PICKUPS';
        } else if (this.isPickup) {
            this.label = 'SHIPMENT.ADD_PICKUP';
        } else if (this.pickup) {
            this.label = 'SHIPMENT.CONVERT_TO_PACKAGE';
        }

        if (this.isEditMode) {
            this.submitLabel = 'ACTIONS.EDIT';
        } else if (this.isMultiPickups || this.pickup) {
            this.submitLabel = 'SHIPMENT.CONVERT';
        }
    }

    private fetchEditedPkg(pkgId) {
        this.shipmentService.getPackageById(pkgId).subscribe(
            (response: any) => {
                this.edittedPkg = response;
                this.initOptions();
            }
        );
    }

    private initOptions() {
        this.disableRest = true;
        Promise.all([this.initRegionsPromise, this.initShipmentServices(), this.initShipmentTypes(),
            this.initMetrics(), this.initHubs(), this.initDrivers(), this.initParcels()]).then(() => {
            this.isLoading = false;
            this.initForm();
            this.initRegions();
            this.disableRest = false;
            this.disableForm();
        });
    }

    /**
     *
     */
    private initShipmentTypes() {
        this.isLoading = true;
        return this.shipmentService.getShipmentTypes().toPromise().then(
            (types: string[]) => {
                this.shipmentTypesOptions = types.map(
                    (type) => {
                        let typeLabel = type;
                        this.translateService.get(
                            type
                        )
                            .subscribe(
                                (data) => {
                                    typeLabel = data;
                                }
                            );
                        return {value: type, label: typeLabel};
                    }
                );
            }
        );
    }

    public initHubs() {
        this.isLoading = true;
        return this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        if (hub.id === this.userHubId) {
                            this.userHub = {label: hub.name, value: hub.id};
                        }
                        return {label: hub.name, value: hub.id};
                    }
                );
            }
        );
    }

    public initDrivers() {
        this.isLoading = true;
        return this.driversService.getDrivers('?page=1&pageSize=500').subscribe(
            (response: any) => {
                this.driversOptions = response.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        if (driver.id === this.userDriverId) {
                            this.userDriver = {label: name, value: driver.id};
                        }
                        return {label: name, value: driver.id};
                    }
                );
            }
        );
    }

    /**
     *
     */
    private initShipmentServices() {
        this.isLoading = true;
        return this.shipmentService.getShipmentServices().toPromise().then(
            (response: string[]) => {
                this.shipmentServicesOptions = response.map(
                    (service) => {
                        let serviceLabel = service;
                        this.translateService.get(
                            service
                        )
                            .subscribe(
                                (data) => {
                                    serviceLabel = data;
                                }
                            );
                        return {value: service, label: serviceLabel};
                    }
                );
            }, (error) => {
            }
        );
    }

    private initParcels() {
        this.parcelService.getParcels().subscribe((response: any) => {
            this.parcelList = response.data || [];
            this.parseParcels();
        });
    }

    parseParcels() {
        this.parcelOptions = this.parcelList.map((parcel) => {
            return {
                label: this.translateService.currentLang === 'ar' ? parcel.arabicType : parcel.type,
                value: parcel.id
            };
        });
    }

    setParcel(parcelId) {
        parcelId = parseInt(parcelId, 10);
        const parcel = this.parcelList.find(({id}) => id === parcelId);
        if (parcel) {
            const {weight, height, length, width} = parcel;
            this.form.patchValue({weight, height, length, width});
            this.form.controls['weight'].disable();
            this.form.controls['height'].disable();
            this.form.controls['length'].disable();
            this.form.controls['width'].disable();
        } else {
            if (!this.isDisabled) {
                this.form.controls['weight'].enable();
                this.form.controls['height'].enable();
                this.form.controls['length'].enable();
                this.form.controls['width'].enable();
                this.initMetrics();
            }
        }
    }

    private initMetrics() {
        if (this.form && this.form.controls.originRegion && this.form.controls.originRegion.value != null &&
            this.form.controls.originRegion.value.value != null && this.form.controls.pkgUnitType &&
            typeof this.form.controls.pkgUnitType.value === 'string') {
            this.isLoading = true;
            const query = this.createMetricsParams();
            return this.shipmentService.getShipmentDefaultMetrics(query).toPromise().then(
                (response: any) => {
                    this.initFormValue('weight', response.weight);
                    this.initFormValue('height', response.height);
                    this.initFormValue('length', response.length);
                    this.initFormValue('width', response.width);
                }, (error) => {
                }
            );
        } else {
            return new Promise((resolve) => {
                resolve(null);
            });
        }

    }

    public createMetricsParams() {
        const params = {
            'regionId': this.form.controls.originRegion.value.value,
            'unit-type': this.form.controls.pkgUnitType.value
        };
        return params;
    }

    private initFormValue(controlName, newValue) {
        this.form.controls[controlName].setValue(newValue);
        this.form.controls[controlName].updateValueAndValidity();
    }

    private initForm() {
        let info;
        if (this.isPickup) {
            info = {
                senderName: '',
                senderFirstName: '',
                senderEmail: '',
                businessSenderName: '',
                senderPhone: '',
                senderPhone2: '',
                senderAuthorizedGovRegistrationNumber: '',
                printType: this.printType,
                swapOrBring: this.swapOrBring,
                originAddressLine1: '',
                originRegion: null,
                originCombined: '',
                originVillage: '',
                originAddressLine2: '',
                originCity: '',
                destinationCity: '',
                originCountry: this.countriesOptions[0].value,
                notes: '',
                description: '',
                adminNotes: '',
                invoice: '',
                supplierInvoice: '',
                deliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                createdDate: this.holdedCreatedDate,
                // hubId: this.userHubId,
                driverId: ''
            };
        } else {
            if (this.pickup) {
                if (this.pickup.imageUrl) {
                    this.imageUrl = this.pickup.imageUrl;
                }
                info = <any>{
                    senderName: {name: this.pickup.customerName},
                    senderMiddleName: this.pickup.customerName,
                    senderEmail: this.pickup.customerEmail,
                    businessSenderName: '',
                    businessReceiverName: '',
                    senderLastName: this.pickup.customerName,
                    receiverAuthorizedGovRegistrationNumber: '',
                    senderAuthorizedGovRegistrationNumber: '',
                    senderPhone2: '',
                    senderPhone: {phone: this.pickup.customerPhone},
                    addressFromIsHub: this.addressFromIsHub,
                    originAddressLine1: this.pickup.address.addressLine1,
                    originAddressLine2: this.pickup.address.addressLine2,
                    receiverName: '',
                    receiverFirstName: '',
                    receiverLastName: '',
                    receiverMiddleName: '',
                    receiverPhone: '',
                    receiverPhone2: '',
                    destinationAddressLine1: '',
                    destinationAddressLine2: '',
                    originRegion: {
                        value: this.pickup.address.regionId,
                        label: this.pickup.address.region
                    },
                    originCity: {
                        value: this.pickup.address.cityId,
                        label: this.pickup.address.city
                    },
                    originVillage: {
                        value: this.pickup.address.villageId,
                        label: this.pickup.address.village
                    },
                    originCombined: {
                        value: this.pickup.address.villageId,
                        label: this.pickup.address.village + '-' + this.pickup.address.city + '-' +
                            this.pickup.address.region
                    },
                    destinationRegion: null,
                    destinationCity: null,
                    destinationVillage: null,
                    destinationCombined: '',
                    originCountry: this.pickup.address.country,
                    destinationCountry: '',
                    freight: null,
                    toPayToReceiver: null,
                    toCollectFromReceiver: null,
                    type: this.shipmentTypesOptions[1].value,
                    service: this.shipmentServicesOptions[0].value,
                    cod: null,
                    weight: 1,
                    width: 1,
                    length: 1,
                    height: 1,
                    pkgUnitType: 'METRIC',
                    printType: this.printType,
                    swapOrBring: this.swapOrBring,
                    // parcelId: '',
                    notes: this.pickup.notes,
                    description: this.pickup.description,
                    adminNotes: this.pickup.adminNotes,
                    invoice: this.pickup.invoiceNumber,
                    supplierInvoice: null,
                    expectedDeliveryDate: null,
                    deliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                    extraOptions: [],
                    hubId: this.pickup.hubId,
                    driverId: null,
                    quantity: 1
                };
            } else {
                if (this.isHubManager) {
                    this.addressFromIsHub = false;
                }
                info = {
                    senderName: '',
                    senderFirstName: '',
                    senderEmail: '',
                    businessSenderName: '',
                    businessReceiverName: '',
                    senderPhone: '',
                    senderPhone2: '',
                    senderAuthorizedGovRegistrationNumber: '',
                    receiverAuthorizedGovRegistrationNumber: '',
                    addressFromIsHub: this.addressFromIsHub,
                    printType: this.printType,
                    swapOrBring: this.swapOrBring,
                    originAddressLine1: '',
                    originRegion: null,
                    originCombined: '',
                    originVillage: '',
                    originAddressLine2: '',
                    receiverName: '',
                    receiverFirstName: '',
                    receiverLastName: '',
                    receiverMiddleName: '',
                    receiverPhone: '',
                    receiverPhone2: '',
                    destinationAddressLine1: '',
                    destinationVillage: '',
                    destinationRegion: null,
                    destinationCombined: '',
                    destinationAddressLine2: '',
                    originCity: '',
                    destinationCity: '',
                    originCountry: this.countriesOptions[0].value,
                    destinationCountry: this.countriesOptions[0].value,
                    freight: null,
                    toPayToReceiver: null,
                    toCollectFromReceiver: null,
                    type: this.shipmentTypesOptions[1].value,
                    service: this.shipmentServicesOptions[0].value,
                    cod: null,
                    weight: 1,
                    width: 1,
                    length: 1,
                    height: 1,
                    pkgUnitType: 'METRIC',
                    notes: '',
                    description: '',
                    adminNotes: '',
                    invoice: '',
                    supplierInvoice: '',
                    expectedDeliveryDate: null,
                    deliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                    createdDate: this.holdedCreatedDate,
                    extraOptions: [],
                    hubId: this.userHubId,
                    driverId: '',
                    quantity: 1
                };
                if (this.authenticationService.companyId === 64 && !this.isEditMode) {
                    info.destinationAddressLine1 = '.';
                }
            }

            if (this.isEditMode) {
                this.disableRest = true;
                const pkgExtraOptions = [];
                ['isInsurance', 'isNeedPacking', 'isAnimalOrPits', 'isDangerousOrChemical', 'isflammable', 'isBreakable']
                    .forEach(
                        (option) => {
                            if (this.edittedPkg[option]) {
                                option = option.charAt(0).toLowerCase() + option.substring(3);
                                pkgExtraOptions.push(option);
                            }
                        }
                    );
                info = <any>{
                    senderName: {
                        name: this.checkIfBring(true) ? this.edittedPkg.receiverName ? this.edittedPkg.receiverName :
                            this.edittedPkg.receiverFirstName
                            + (this.edittedPkg.receiverMiddleName ? ' '
                            + this.edittedPkg.receiverMiddleName + ' ' : ' ')
                            + this.edittedPkg.receiverLastName
                            : this.edittedPkg.senderFirstName
                            + (this.edittedPkg.senderMiddleName ? ' '
                                + this.edittedPkg.senderMiddleName + ' ' : ' ')
                            + this.edittedPkg.senderLastName
                    },
                    senderMiddleName: this.checkIfBring(true) ? this.edittedPkg.receiverMiddleName : this.edittedPkg.senderMiddleName,
                    senderEmail: this.edittedPkg.senderEmail,
                    businessSenderName: this.checkIfBring(true) ? this.edittedPkg.businessReceiverName : this.edittedPkg.businessSenderName,
                    businessReceiverName: this.checkIfBring(true) ? this.edittedPkg.businessSenderName : this.edittedPkg.businessReceiverName,
                    senderLastName: this.checkIfBring(true) ? this.edittedPkg.receiverLastName : this.edittedPkg.senderLastName,
                    receiverAuthorizedGovRegistrationNumber: this.checkIfBring(true) ? this.edittedPkg.senderAuthorizedGovRegistrationNumber
                        : this.edittedPkg.receiverAuthorizedGovRegistrationNumber,
                    senderAuthorizedGovRegistrationNumber: this.checkIfBring(true) ? this.edittedPkg.receiverAuthorizedGovRegistrationNumber
                        : this.edittedPkg.senderAuthorizedGovRegistrationNumber,
                    senderPhone2: this.checkIfBring(true) ? this.edittedPkg.receiverPhone2 : this.edittedPkg.senderPhone2,
                    senderPhone: this.checkIfBring(true) ? {phone: this.edittedPkg.receiverPhone} : {phone: this.edittedPkg.senderPhone},
                    addressFromIsHub: this.addressFromIsHub,
                    originAddressLine1: this.checkIfBring(true) ?  this.edittedPkg.destinationAddress.addressLine1
                        : this.edittedPkg.originAddress.addressLine1,
                    originAddressLine2: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.addressLine2
                        : this.edittedPkg.originAddress.addressLine2,
                    receiverName: this.checkIfBring(true) ? this.edittedPkg.senderFirstName
                        + (this.edittedPkg.senderMiddleName ? ' '
                            + this.edittedPkg.senderMiddleName + ' ' : ' ')
                        + this.edittedPkg.senderLastName
                        : this.edittedPkg.receiverName ? this.edittedPkg.receiverName :
                        this.edittedPkg.receiverFirstName
                        + (this.edittedPkg.receiverMiddleName ? ' '
                        + this.edittedPkg.receiverMiddleName + ' ' : ' ')
                        + this.edittedPkg.receiverLastName,
                    receiverFirstName: this.checkIfBring(true) ? this.edittedPkg.senderFirstName : this.edittedPkg.receiverFirstName,
                    receiverLastName: this.checkIfBring(true) ? this.edittedPkg.senderLastName : this.edittedPkg.receiverLastName,
                    receiverMiddleName: this.checkIfBring(true) ? this.edittedPkg.senderMiddleName : this.edittedPkg.receiverMiddleName,
                    receiverPhone: this.checkIfBring(true) ? this.edittedPkg.senderPhone : this.edittedPkg.receiverPhone,
                    receiverPhone2: this.checkIfBring(true) ? this.edittedPkg.senderPhone2 : this.edittedPkg.receiverPhone2,
                    destinationAddressLine1: this.checkIfBring(true) ? this.edittedPkg.originAddress.addressLine1
                        : this.edittedPkg.destinationAddress.addressLine1,
                    destinationAddressLine2: this.checkIfBring(true) ? this.edittedPkg.originAddress.addressLine2
                        : this.edittedPkg.destinationAddress.addressLine2,
                    originRegion: {
                        value: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.regionId : this.edittedPkg.originAddress.regionId,
                        label: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.region : this.edittedPkg.originAddress.region
                    },
                    originCity: {
                        value: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.cityId : this.edittedPkg.originAddress.cityId,
                        label: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.city : this.edittedPkg.originAddress.city
                    },
                    originVillage: {
                        value: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.villageId : this.edittedPkg.originAddress.villageId,
                        label: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.village : this.edittedPkg.originAddress.village
                    },
                    originCombined: {
                        value: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.villageId : this.edittedPkg.originAddress.villageId,
                        label: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.village + '-' + this.edittedPkg.destinationAddress.city + '-' +
                            this.edittedPkg.destinationAddress.region
                            : this.edittedPkg.originAddress.village + '-' + this.edittedPkg.originAddress.city + '-' +
                            this.edittedPkg.originAddress.region
                    },
                    destinationRegion: {
                        value: this.checkIfBring(true) ? this.edittedPkg.originAddress.regionId : this.edittedPkg.destinationAddress.regionId,
                        label: this.checkIfBring(true) ? this.edittedPkg.originAddress.region : this.edittedPkg.destinationAddress.region
                    },
                    destinationCity: {
                        value: this.checkIfBring(true) ? this.edittedPkg.originAddress.cityId : this.edittedPkg.destinationAddress.cityId,
                        label: this.checkIfBring(true) ? this.edittedPkg.originAddress.city : this.edittedPkg.destinationAddress.city
                    },
                    destinationVillage: {
                        value: this.checkIfBring(true) ? this.edittedPkg.originAddress.villageId : this.edittedPkg.destinationAddress.villageId,
                        label: this.checkIfBring(true) ? this.edittedPkg.originAddress.village : this.edittedPkg.destinationAddress.village
                    },
                    destinationCombined: {
                        value: this.checkIfBring(true) ? this.edittedPkg.originAddress.villageId : this.edittedPkg.destinationAddress.villageId,
                        label: this.checkIfBring(true) ? this.edittedPkg.originAddress.village + '-' + this.edittedPkg.originAddress.city + '-' +
                            this.edittedPkg.originAddress.region
                            : this.edittedPkg.destinationAddress.village + '-' + this.edittedPkg.destinationAddress.city + '-' +
                            this.edittedPkg.destinationAddress.region
                    },
                    originCountry: this.checkIfBring(true) ? this.edittedPkg.destinationAddress.country
                        : this.edittedPkg.originAddress.country,
                    destinationCountry: this.checkIfBring(true) ? this.edittedPkg.originAddress.country
                        : this.edittedPkg.destinationAddress.country,
                    freight: this.edittedPkg.cost,
                    type: this.edittedPkg.shipmentType,
                    service: this.edittedPkg.serviceType,
                    cod: this.edittedPkg.cod,
                    toPayToReceiver: this.edittedPkg.toPayToReceiver,
                    toCollectFromReceiver: this.edittedPkg.toCollectFromReceiver,
                    weight: this.edittedPkg.weight,
                    width: this.edittedPkg.width,
                    length: this.edittedPkg.length,
                    height: this.edittedPkg.height,
                    pkgUnitType: 'METRIC',
                    printType: this.printType,
                    swapOrBring: this.swapOrBring,
                    parcelId: this.edittedPkg.parcelTypeId,
                    notes: this.edittedPkg.notes,
                    description: this.edittedPkg.description,
                    adminNotes: this.edittedPkg.adminNotes,
                    invoice: this.edittedPkg.invoiceNumber,
                    supplierInvoice: this.edittedPkg.supplierInvoice,
                    expectedDeliveryDate: (this.edittedPkg.expectedDeliveryDate === undefined || this.edittedPkg.expectedDeliveryDate === null)
                        ? '' : new Date(this.edittedPkg.expectedDeliveryDate),
                    deliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                    extraOptions: pkgExtraOptions,
                    hubId: this.edittedPkg.hubId,
                    driverId: this.edittedPkg.driverId,
                    quantity: this.edittedPkg.quantity
                };
            }
        }
        if (this.isPickup) {
            this.form = this.formBuilder.group({
                senderName: [{
                    value: info.senderName,
                    disabled: this.canEdit('senderName')
                }, Validators.compose([Validators.required])],
                senderAuthorizedGovRegistrationNumber: [{
                    value: info.senderAuthorizedGovRegistrationNumber,
                    disabled: this.canEdit('senderName')
                }],
                senderEmail: [{value: info.senderEmail, disabled: this.canEdit('senderEmail')}, Validators.compose([
                    Validators.required, Validators.minLength(6)
                ])],
                receiverAuthorizedGovRegistrationNumber: [{
                    value: info.receiverAuthorizedGovRegistrationNumber,
                    disabled: this.canEdit('senderName')
                }],
                businessSenderName: [{value: info.businessSenderName, disabled: this.canEdit('senderName')}],
                businessReceiverName: [{value: info.businessReceiverName, disabled: this.canEdit('senderName')}],
                senderPhone: [{
                    value: info.senderPhone,
                    disabled: this.canEdit('senderPhone')
                },
                    [Validators.required, Validators.maxLength(this.userService.userInfo.maxPhoneNumberValidationDigits),
                        Validators.minLength(this.userService.userInfo.minPhoneNumberValidationDigits)]],
                senderPhone2: [{disabled: this.canEdit('senderPhone')}],
                addressFromIsHub: [{disabled: this.canEdit('hubId')}, Validators.required],
                // hubId: [{value: info.hubId, disabled: this.canEdit('hubId')}, Validators.required],
                driverId: [{value: info.driverId, disabled: this.canEdit('driverId')}],
                originAddressLine1: [{disabled: this.canEdit('originAddressLine1')}, Validators.required],
                originAddressLine2: [{disabled: this.canEdit('originAddressLine1')}],
                originCity: [{disabled: this.canEdit('originCity')}, Validators.required],
                originRegion: [{disabled: this.canEdit('originCity')}, Validators.required],
                originVillage: [{disabled: this.canEdit('originCity')}, Validators.required],
                originCombined: [{disabled: this.canEdit('originCity')}, Validators.required],
                originCountry: [{disabled: this.canEdit('originCountry')}],
                // pkgUnitType: [{disabled: this.canEdit('pkgUnitType')}],
                printType: [{disabled: this.canEdit('pkgUnitType')}],
                swapOrBring: [{disabled: this.canEdit('swapOrBring')}],
                notes: [{disabled: this.canEdit('notes')}],
                description: [{disabled: this.canEdit('description')}],
                adminNotes: [{disabled: this.canEdit('adminNotes')}],
                invoice: [{disabled: this.canEdit('invoice')}],
                supplierInvoice: [{disabled: this.canEdit('supplierInvoice')}],
                deliveryDate: [{disabled: this.canEdit('deliveryDate')}],
                expectedDeliveryDate: [{disabled: this.canEdit('expectedDeliveryDate')}]
            });
        } else {
            this.form = this.formBuilder.group({
                senderName: [{
                    value: info.senderName,
                    disabled: this.canEdit('senderName') || !this.notRelatedToPickup()
                }, Validators.compose([Validators.required
                    // Validators.pattern('(\\w.+\\s).+')
                ])],
                senderAuthorizedGovRegistrationNumber: [{
                    value: info.senderAuthorizedGovRegistrationNumber,
                    disabled: this.canEdit('senderName') || !this.notRelatedToPickup()
                }],
                senderEmail: [{
                    value: info.senderEmail,
                    disabled: this.canEdit('senderEmail') || !this.notRelatedToPickup()
                },
                    Validators.compose([
                        Validators.required,
                        // Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')
                        Validators.minLength(6)
                    ])],
                receiverAuthorizedGovRegistrationNumber: [{
                    value: info.receiverAuthorizedGovRegistrationNumber,
                    disabled: this.canEdit('senderName')
                }],
                businessSenderName: [{
                    value: info.businessSenderName,
                    disabled: this.canEdit('senderName') || !this.notRelatedToPickup()
                }],
                businessReceiverName: [{value: info.businessReceiverName, disabled: this.canEdit('senderName')}],
                senderPhone: [{
                    value: info.senderPhone,
                    disabled: this.canEdit('senderPhone') || !this.notRelatedToPickup()
                }, [Validators.required, Validators.maxLength(this.userService.userInfo.maxPhoneNumberValidationDigits),
                    Validators.minLength(this.userService.userInfo.minPhoneNumberValidationDigits)]],
                senderPhone2: [{disabled: this.canEdit('senderPhone') || !this.notRelatedToPickup()}],
                addressFromIsHub: [{disabled: this.canEdit('hubId')}, Validators.required],
                hubId: [{value: info.hubId, disabled: this.canEdit('hubId')}, Validators.required],
                driverId: [{value: info.driverId, disabled: this.canEdit('driverId')}],
                originAddressLine1: [{disabled: this.canEdit('originAddressLine1') || !this.notRelatedToPickup()}, Validators.required],
                originAddressLine2: [{disabled: this.canEdit('originAddressLine2') || !this.notRelatedToPickup()}],
                receiverName: [{value: info.receiverName, disabled: this.canEdit('receiverName')}, Validators.compose([Validators.required
                    // Validators.pattern('(\\w.+\\s).+')
                ])],
                receiverPhone: [{disabled: this.canEdit('receiverPhone')},
                    [Validators.required, Validators.maxLength(this.userService.userInfo.maxPhoneNumberValidationDigits),
                        Validators.minLength(this.userService.userInfo.minPhoneNumberValidationDigits)]],
                receiverPhone2: [{disabled: this.canEdit('receiverPhone')}],
                destinationAddressLine1: [{
                    value: info.destinationAddressLine1,
                    disabled: this.canEdit('destinationAddressLine1')
                }, Validators.required],
                destinationAddressLine2: [{
                    disabled: this.canEdit('destinationAddressLine1')
                }],
                // airwayBill: ['', Validators.required],
                originCity: [{disabled: this.canEdit('originCity')}, Validators.required],
                originRegion: [{disabled: this.canEdit('originCity')}, Validators.required],
                originVillage: [{disabled: this.canEdit('originCity')}, Validators.required],
                originCombined: [{disabled: this.canEdit('originCity')}, Validators.required],
                destinationCity: [{disabled: this.canEdit('destinationCity')}, Validators.required],
                destinationRegion: [{disabled: this.canEdit('destinationCity')}, Validators.required],
                destinationVillage: [{disabled: this.canEdit('destinationCity')}, Validators.required],
                destinationCombined: [{disabled: this.canEdit('destinationCity')}, Validators.required],
                originCountry: [{disabled: this.canEdit('originCountry')}],
                destinationCountry: [{disabled: this.canEdit('destinationCountry')}],
                freight: [{disabled: this.canEdit('freight')}],
                service: [{disabled: this.canEdit('service')}, Validators.required],
                type: [{disabled: this.canEdit('type')}, Validators.required],
                cod: [{disabled: this.canEdit('cod')}],
                toPayToReceiver: [{disabled: this.canEdit('toPayToReceiver')}],
                toCollectFromReceiver: [{disabled: this.canEdit('toCollectFromReceiver')}],
                weight: [{disabled: this.canEdit('weight')}, Validators.required],
                width: [{disabled: this.canEdit('width')}, Validators.required],
                length: [{disabled: this.canEdit('length')}, Validators.required],
                height: [{disabled: this.canEdit('height')}, Validators.required],
                pkgUnitType: [{disabled: this.canEdit('pkgUnitType')}],
                printType: [{disabled: this.canEdit('pkgUnitType')}],
                swapOrBring: [{disabled: this.canEdit('swapOrBring')}],
                parcelId: [{value: null, disabled: this.canEdit('parcelId')}],
                notes: [{disabled: this.canEdit('notes')}],
                description: [{disabled: this.canEdit('description')}],
                adminNotes: [{disabled: this.canEdit('adminNotes')}],
                invoice: [{value: info.invoice, disabled: this.canEdit('invoice') || !this.notRelatedToPickup()}],
                supplierInvoice: [{disabled: this.canEdit('supplierInvoice')}],
                deliveryDate: [{disabled: this.canEdit('deliveryDate')}],
                expectedDeliveryDate: [{disabled: this.canEdit('expectedDeliveryDate')}],
                extraOptions: [{disabled: this.canEdit('extraOptions')}], // Extra options
                quantity: [{value: info.quantity, disabled: this.canEdit('quantity')}],
            });
            if (this.showCreatedDate() && !this.isEditMode && !this.pickup) {
                this.form.addControl(
                    'createdDate', new FormControl({value: info.createdDate, disabled: this.canEdit('createdDate')}, Validators.required));
            }
            this.form.controls['parcelId'].valueChanges.subscribe(data => {
                this.setParcel(data);
            });
            this.form.controls['type'].valueChanges.subscribe(data => {
                if (data === 'COD') {
                    this.form.controls['cod'].setValidators([Validators.required]);
                } else {
                    this.form.controls['cod'].setValidators([]);
                }
                if (data === 'SWAP' || data === 'BRING') {
                    this.changeSwapOrBringInput();
                } else {
                    this.form.controls['toCollectFromReceiver'].setValue('');
                    this.form.controls['toPayToReceiver'].setValue('');
                    this.form.controls['toCollectFromReceiver'].disable();
                    this.form.controls['toPayToReceiver'].disable();
                }
                this.form.controls['cod'].updateValueAndValidity();
                this.form.controls['toCollectFromReceiver'].updateValueAndValidity();
                this.form.controls['toPayToReceiver'].updateValueAndValidity();
            });
            this.initFormEvent(this.form, 1, {
                country: '',
                region: 'destinationRegion',
                city: 'destinationCity',
                village: 'destinationVillage',
                combined: 'destinationCombined'
            });
            this.initFormEvent(this.form, 0, {
                country: '',
                region: 'originRegion',
                city: 'originCity',
                village: 'originVillage',
                combined: 'originCombined'
            });
        }

        this.form.patchValue(info);
        this.changeAddressType();
        this.disableRest = false;
        this.form.updateValueAndValidity({emitEvent: true});
        this.utilitiesService.markFormGroupTouched(this.form);
        if (!this.isPickup) {
            this.initInputNotes();
            if (!this.isEditMode && !this.pickup) {
                this.initMetricValueChanges();
            }
        }
    }
    public changeSwapOrBringInput() {
        this.form.controls['toPayToReceiver'].reset(null);
        this.form.controls['toCollectFromReceiver'].reset(null);
        if (this.swapOrBring === 'COLLECT') {
            this.form.controls['toPayToReceiver'].disable();
            this.form.controls['toCollectFromReceiver'].enable();
            this.form.controls['toCollectFromReceiver'].markAsTouched();
            this.form.controls['toCollectFromReceiver'].setValidators([Validators.required]);
        } else if (this.swapOrBring === 'PAY') {
            this.form.controls['toCollectFromReceiver'].disable();
            this.form.controls['toPayToReceiver'].enable();
            this.form.controls['toPayToReceiver'].markAsTouched();
            this.form.controls['toPayToReceiver'].setValidators([Validators.required]);
        }
        this.form.controls['toCollectFromReceiver'].updateValueAndValidity();
        this.form.controls['toPayToReceiver'].updateValueAndValidity();
    }

    public changeSwapOrBring() {
        this.swapOrBring = this.form.value.swapOrBring;
        this.changeSwapOrBringInput();
    }
    public initMetricValueChanges() {
        if (this.form.controls['originRegion']) {
            this.form.controls['originRegion'].valueChanges.subscribe(data => {
                if (!this.form.controls['parcelId'].value) {
                    this.initMetrics();
                }

            });
        }
        if (this.form.controls['pkgUnitType']) {
            this.form.controls['pkgUnitType'].valueChanges.subscribe(data => {
                if (!this.form.controls['parcelId'].value) {
                    this.initMetrics();
                }
            });
        }
    }

    public initInputNotes() {
        this.setDeliveryDateNote();

        this.form.controls.service.valueChanges.subscribe(
            (changedData) => {
                this.setDeliveryDateNote();
            }
        );
    }

    public canEdit(controlName) {
        // special case, if control name was COD and user role was 'Customer Care'
        // then it will be enable if the creater of the shipment is the same as logged in user.
        if (controlName === 'cod' && this.userService.userInfo.role.toLowerCase() === 'customer care'
            && this.isEditMode) {
            if (this.edittedPkg.customerId === this.userService.userInfo.id) {
                return true;
            } else {
                return false;
            }
        }

        if (this.editedFields === 'all') {
            return false;
        }
        return !this.editedFields.includes(controlName);
    }

    public setDeliveryDateNote() {
        const serviceType = this.form.controls.service.value;
        const newDateValue = new Date();

        switch (serviceType) {
            case 'Express':
                this.deliveryDateNote = 'Same date';
                break;
            case 'Standard':
                this.deliveryDateNote = 'two-three days';
                newDateValue.setDate(newDateValue.getDate() + 2);
                break;
            case 'THREE_TO_FIVE_DAYS':
                this.deliveryDateNote = 'three-five days';
                newDateValue.setDate(newDateValue.getDate() + 3);
                break;
        }

        this.form.controls.deliveryDate.setValue(this.datePipe.transform(newDateValue, 'yyyy-MM-dd'));
        this.form.controls.deliveryDate.updateValueAndValidity();
    }

    public removeProduct(removedProduct: ProductModel) {
        this.products = this.products.filter((product) => {
            return product !== removedProduct;
        });
    }
    public checkDisappearValue(value) {
        return (value === undefined || value === null || value === '' ? 0 : value);
    }
    public checkIfBring(editMode = false) {
        if (this.isPickup) {
            return false;
        } else {
            if (editMode) {
                return (this.edittedPkg.shipmentType === 'BRING');
            }
            return (this.form.controls['type'].value === 'BRING');
        }
    }
    public addShipment(isContinue?: boolean) {
        if (!this.form.valid) {
            return;
        }
        this.isSubmitting = true;

        const data = this.form.getRawValue();
        const products = this.processProducts();
        const extraOptions = this.getSelectedExtraOptions(data.extraOptions);
        const body = {
            pkg: {
                cost: data.freight,
                cod: this.checkDisappearValue(data.cod),
                toPayToReceiver: this.checkDisappearValue(data.toPayToReceiver),
                toCollectFromReceiver: this.checkDisappearValue(data.toCollectFromReceiver),
                deliveryDate: data.deliveryDate,
                expectedDeliveryDate: this.datePipe.transform(data.expectedDeliveryDate, 'yyyy-MM-dd'),
                createdDate: this.datePipe.transform(data.createdDate, 'yyyy-MM-dd'),
                isInsurance: extraOptions.has('insurance'),
                isBreakable: extraOptions.has('breakable'),
                isflammable: extraOptions.has('flammable'),
                isDangerousOrChemical: extraOptions.has('dangerousOrChemical'),
                isAnimalOrPit: extraOptions.has('animalOrPits'),
                isNeedPacking: extraOptions.has('needPacking'),
                notes: data.notes,
                description: data.description,
                adminNotes: data.adminNotes,
                invoiceNumber: data.invoice,
                weight: data.weight,
                length: data.length,
                width: data.width,
                height: data.height,
                parcelTypeId: parseInt(data.parcelId, 0),
                senderName: this.checkIfBring() ? (data.receiverName.name ? data.receiverName.name : data.receiverName)
                    : (data.senderName.name ? data.senderName.name : data.senderName),
                senderLastName: '.',
                businessSenderName: this.checkIfBring() ? data.businessReceiverName : data.businessSenderName,
                businessReceiverName: this.checkIfBring() ? data.businessSenderName : data.businessReceiverName,
                receiverAuthorizedGovRegistrationNumber: this.checkIfBring() ? data.senderAuthorizedGovRegistrationNumber
                    : data.receiverAuthorizedGovRegistrationNumber,
                senderAuthorizedGovRegistrationNumber: this.checkIfBring() ? data.receiverAuthorizedGovRegistrationNumber
                    : data.senderAuthorizedGovRegistrationNumber,
                receiverName: this.checkIfBring() ? (data.senderName.name ? data.senderName.name : data.senderName)
                    : (data.receiverName.name ? data.receiverName.name : data.receiverName),
                receiverLastName: '.',
                senderPhone: this.checkIfBring() ? data.receiverPhone.phone ? data.receiverPhone.phone : data.receiverPhone
                    : data.senderPhone.phone ? data.senderPhone.phone : data.senderPhone,
                senderPhone2: this.checkIfBring() ? data.receiverPhone2 : data.senderPhone2,
                receiverPhone: this.checkIfBring() ? data.senderPhone.phone ? data.senderPhone.phone : data.senderPhone
                    : data.receiverPhone.phone ? data.receiverPhone.phone : data.receiverPhone,
                receiverPhone2: this.checkIfBring() ? data.senderPhone2 : data.receiverPhone2,
                serviceType: data.service.toUpperCase(),
                shipmentType: data.type.toUpperCase(),
                products: products,
                quantity: data.quantity,
                supplierInvoice: data.supplierInvoice,
                senderEmail: data.senderEmail,
                receiverEmail: '.'
            },
            destinationAddress: {
                addressLine1: this.checkIfBring() ? data.originAddressLine1 : data.destinationAddressLine1,
                addressLine2: this.checkIfBring() ? data.originAddressLine2 : data.destinationAddressLine2,
                cityId: this.checkIfBring() ? data.originCity.value : data.destinationCity.value,
                villageId: this.checkIfBring() ? data.originVillage.value : data.destinationVillage.value,
                regionId: this.checkIfBring() ? data.originRegion.value : data.destinationRegion.value,
                country: this.checkIfBring() ? data.originCountry : data.destinationCountry
            },
            pkgUnitType: 'METRIC'
        };
        body['originAddress'] = {
            addressLine1: this.checkIfBring() ? data.destinationAddressLine1 : data.originAddressLine1,
            addressLine2: this.checkIfBring() ? data.destinationAddressLine2 : data.originAddressLine2,
            cityId: this.checkIfBring() ? data.destinationCity.value : data.originCity.value,
            country: this.checkIfBring() ? data.destinationCountry : data.originCountry,
            regionId: this.checkIfBring() ? data.destinationRegion.value : data.originRegion.value,
            villageId: this.checkIfBring() ? data.destinationVillage.value : data.originVillage.value
        };

        if (data.hubId) {
            body.pkg['hubId'] = data.hubId;
        }

        if (data.driverId) {
            body.pkg['driverId'] = data.driverId;
            this.userDriverId = data.driverId;
        }
        this.addressFromIsHub = data.addressFromIsHub;

        if (this.isEditMode) {
            this.submitEditting(body);
            return;
        }
        if (body.pkg.cod > 999) {
            this.translateService.get(
                ['ALERTS.COD_GREATER_THAN_3_DIGITS_WARNING', 'GENERAL.YES', 'GENERAL.NO']
            )
                .subscribe(
                     (next: any) => {
                        const message = next['ALERTS.COD_GREATER_THAN_3_DIGITS_WARNING'];
                        const yes = next['GENERAL.YES'];
                        const no = next['GENERAL.NO'];
                        this.confirmationService.confirm({
                            message: message,
                            accept: () => {
                                if (this.pickup) {
                                    body['customerId'] = this.pickup.customerId;
                                    this.convertToPackage(this.pickup.id, body);
                                } else {
                                    this.addShipmentRequest(body);
                                }
                            },
                            acceptLabel: yes,
                            rejectLabel: no,
                            reject: () => {
                                this.isSubmitting = false;
                            }
                        });
                    }
                );
        } else {
            if (this.pickup) {
                body.pkg['customerId'] = this.pickup.customerId;
                this.convertToPackage(this.pickup.id, body , isContinue);
            } else {
                this.addShipmentRequest(body);
            }
        }
    }

    private convertToPackage(pickupId, body , isContinue?: boolean) {
        this.shipmentService.convertToPackage(pickupId, body).subscribe(
            (response: any) => {
                this.translateService.get(
                    'ALERTS.SHIPMENT_ADDED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                        }
                    );
                this.form.reset();
                this.initOptions();
                if (!this.isMultiPickups || !isContinue) {
                    this.activeModal.close({isSuccess: true});
                }
            }, (error) => {
                console.error(error);
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    private addPickupRequest(body) {

        this.shipmentService.requestPickup(body).subscribe(
            (response: any) => {
                this.translateService.get(
                    'ALERTS.SHIPMENT_ADDED_SUCCESSFULLY'
                ).subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                    }
                );
                this.handlePackagePrint(this.form.value.printType, null);
                this.printType = this.form.value.printType;
                this.form.reset();
                this.initOptions();
            }, (error) => {
                console.error(error);
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    private addShipmentRequest(body) {
        if (this.authenticationService.companyId === 64
            || this.authenticationService.companyId === 1
            || this.authenticationService.companyId === 18) {
            this.shipmentService.requestPackage(body).subscribe(
                (response: any) => {
                    this.translateService.get(
                        'ALERTS.SHIPMENT_ADDED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                            }
                        );
                    this.shipmentService.addShipmentSubject.next(null);

                }, (error) => {
                    console.error(error);
                },
                () => {
                }
            );
            // Handle Print
            // this.handlePackagePrint(this.form.value.printType, response.id);
            this.printType = this.form.value.printType;
            this.swapOrBring = this.form.value.swapOrBring;
            this.holdedCreatedDate = this.form.value.createdDate;
            this.form.reset();
            this.initOptions();
            this.isSubmitting = false;
        } else {
            this.shipmentService.requestPackage(body).subscribe(
                (response: any) => {
                    this.translateService.get(
                        'ALERTS.SHIPMENT_ADDED_SUCCESSFULLY'
                    ).subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                            }
                        );
                    // Handle Print
                    this.handlePackagePrint(this.form.value.printType, response.id);
                    this.printType = this.form.value.printType;
                    this.holdedCreatedDate = this.form.value.createdDate;

                    this.form.reset();
                    this.initOptions();
                    this.isSubmitting = false;
                    this.shipmentService.addShipmentSubject.next(null);

                }, (error) => {
                    console.error(error);
                    this.isSubmitting = false;
                },
                () => {
                    this.isSubmitting = false;
                }
            );
        }
    }

    public handlePackagePrint(printType, id) {
        switch (printType) {
            case 'PRINT':
                this.printReport(id);
                break;
            case 'SAVE':
                if (!this.isPickup) {
                    this.downloadPdf(id);
                }
                this.closeModal();
                break;
            case 'SAVE_AND_CONTINUE':
                break;

        }
    }

    public submitEditting(body) {
        const reqBody = _.merge(this.edittedPkg, body.pkg);
        reqBody.originAddress = body.originAddress;
        reqBody.destinationAddress = body.destinationAddress;
        this.shipmentService.requestPackageChanges(this.edittedPkg.id, reqBody).subscribe(
            (response) => {
                this.translateService.get(
                    'ALERTS.SHIPMENT_EDITED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                            this.activeModal.close({isSuccess: true});
                        }
                    );
            }, (error) => {
                console.error(error);
                this.isSubmitting = false;

            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    public processProducts() {
        const result = [];

        this.products.forEach(
            (product) => {
                if (product.name !== '' && product.weight && product.eachCost && product.quantity) {
                    result.push(product);
                }
            }
        );

        return result;
    }

    public getSelectedExtraOptions(extraOptions: string[]): Set<string> {
        const result = new Set<string>();
        if (!extraOptions) {
            return result;
        }
        extraOptions.forEach(
            (option) => {
                result.add(option);
            }
        );
        return result;
    }

    public resetForm() {
        if (this.userService.userInfo.role.toLowerCase() === 'dispatcher') {
            return;
        }
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.RESET_FORM', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.RESET_FORM'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.form.reset();
                if (this.pkgId) {
                    this.isEditMode = true;
                    this.fetchEditedPkg(this.pkgId);
                } else {
                    this.initOptions();
                }
                // this.form.reset();
                // this.deliveryDateNote = '';
                // this.products = [
                //     {
                //         name: '',
                //         quantity: 1,
                //         weight: 1,
                //         eachCost: 10,
                //         totalCost: 10
                //     }
                // ];
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }

    public onProductsChange($event: { weight: number, cost: number }) {
        this.form.controls.weight.setValue($event.weight);
        this.form.controls.cod.setValue($event.cost);
        this.form.controls.weight.updateValueAndValidity();
        this.form.controls.cod.updateValueAndValidity();
    }

    public chooseExistingMenu(rec) {
        const modal = this.modalService.open(ChooseExistingComponent, <any>{windowClass: 'assign-package-to-modal', size: 'md'});
        modal.result.then(
            (data: { isSuccess: boolean, user: UserModel }) => {
                if (data.isSuccess) {
                    const user = data.user;
                    this.chooseExisting(user, rec);
                }
            }
        );
    }

    public addCustomer() {
        let errorMessage = '';
        this.translateService.get(
            ['ALERTS.ADD_CUSTOMER_FAILED']
        ).subscribe(
            (data) => {
                errorMessage = data['ALERTS.ADD_CUSTOMER_FAILED'];
                const modal = this.modalService.open(NewUserComponent, <any>{
                    backdrop: 'static',
                    windowClass: 'create-new-user',
                    size: 'md'
                });
                const type = 'customer';
                modal.componentInstance.type = type;
                modal.result.then(
                    (status) => {
                        if (status.isSuccess) {
                            this.chooseExisting(status.customer, false);
                        } else {// error adding customer
                            this.confirmationService.confirm({
                                message: errorMessage + ': ' + status.error,
                                rejectVisible: false,
                                acceptLabel: 'OK'
                            });
                        }
                    });
            }
        );
    }

    public chooseExisting(user, rec) {
        const updateFormValue = (controlName, value) => {
            this.form.controls[controlName].setValue(value);
            this.form.controls[controlName].updateValueAndValidity();
        };
        if (!rec) {
            updateFormValue('senderName', {name: user.firstName + (user.middleName ? ' ' + user.middleName + ' ' : ' ') + user.lastName});
            updateFormValue('senderPhone', {phone: user.phone});
            updateFormValue('senderEmail', user.email);
            updateFormValue('originAddressLine1', user.address.addressLine1);
            updateFormValue('originAddressLine2', user.address.addressLine2);
            updateFormValue('businessSenderName', user.businessName);
            updateFormValue('businessSenderName', user.businessName);
            updateFormValue('originRegion', {
                value: user.address.regionId,
                label: user.address.region
            });
            updateFormValue('originCity', {
                value: user.address.cityId,
                label: user.address.city
            });
            updateFormValue('originVillage', {
                value: user.address.villageId,
                label: user.address.village
            });
            updateFormValue('originCombined', {
                value: user.address.villageId,
                label: user.address.village + '-' + user.address.city + '-' + user.address.region
            });
            if (!this.isPickup) {
                setTimeout(() => {
                    this.receiverName.text.nativeElement.focus();
                }, 100);
            } else {
                this.customerId = user.id;
            }
        } else {
            updateFormValue('receiverName', {
                name: user.firstName + (user.middleName ? ' ' +
                    user.middleName + ' ' : ' ') + user.lastName
            });
            updateFormValue('receiverPhone', {phone: user.phone});
            updateFormValue('destinationAddressLine1', user.address.addressLine1);
            updateFormValue('destinationAddressLine2', user.address.addressLine2);
            updateFormValue('businessReceiverName', user.businessName);
            updateFormValue('toPayToReceiver', user.toPayToReceiver);
            updateFormValue('toCollectFromReceiver', user.toCollectFromReceiver);
            updateFormValue('destinationRegion', {
                value: user.address.regionId,
                label: user.address.region
            });
            updateFormValue('destinationCity', {
                value: user.address.cityId,
                label: user.address.city
            });
            updateFormValue('destinationVillage', {
                value: user.address.villageId,
                label: user.address.village
            });
            updateFormValue('destinationCombined', {
                value: user.address.villageId,
                label: user.address.village + '-' + user.address.city + '-' + user.address.region
            });
        }
    }

    public changeAddressType() {
        if (!this.isPickup) {
            this.disableRest = true;
            if (this.form.value.addressFromIsHub) {
                this.form.controls['hubId'].setValidators([Validators.required]);
                this.form.controls['hubId'].updateValueAndValidity();
                if (this.userHubId) {
                    this.form.controls['hubId'].setValue(this.userHubId);
                    this.form.controls['hubId'].updateValueAndValidity();
                }
                this.form.controls['driverId'].setValue(undefined);
                this.form.controls['driverId'].updateValueAndValidity();
            } else {
                this.form.controls['hubId'].setValidators([]);
                this.form.controls['hubId'].setValue(undefined);
                this.form.controls['hubId'].updateValueAndValidity();
                if (this.userDriverId) {
                    this.form.controls['driverId'].setValue(this.userDriverId);
                    this.form.controls['driverId'].updateValueAndValidity();
                }
            }
            this.disableRest = false;
        }
    }

    updateZoneForm($event, controlName) {
        this.form.controls[controlName].setValue(null);
        this.form.controls[controlName].updateValueAndValidity();
    }

    public closeModal() {
        this.activeModal.close({isSuccess: false});
    }

    getCustomers($event) {
        const query = '?page=1&pageSize=20&search=' + $event.query;
        this.usersService.getCustomers(query).subscribe(
            (response: any) => {
                this.users = response.customers;
                this.users = this.users.map((user) => {
                    const name = user.firstName + (user.middleName ? ' ' + user.middleName + ' ' : ' ') +
                        user.lastName + (user.businessName ? ' ( ' + user.businessName + ' ) ' : '');
                    user['name'] = name;
                    return user;
                });
            }, (error) => {
            }
        );
    }

    chooseReceiver($event) {
        this.chooseExisting($event.item, true);
    }

    chooseSender($event) {
        if (this.isPickup) {
            this.customerId = $event.item.id;
        }
        this.chooseExisting($event.item, false);
    }

    public downloadPdf(pkgId) {
        this.shipmentService.downloadPdf(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
            },
            error => {
                console.log(error);
            });
    }

    public printReport(pkgId) {
        this.shipmentService.downloadPdf(pkgId).subscribe((res: any) => {
                this.shipmentService.getPackagePDFBlob(res.url)
                    .subscribe(
                        (response) => { // download file
                            const blobUrl = URL.createObjectURL(response);
                            const iframe = document.createElement('iframe');
                            iframe.style.display = 'none';
                            iframe.src = blobUrl;
                            document.body.appendChild(iframe);
                            iframe.contentWindow.print();
                        });
            },
            error => {
                console.log(error);
            });
    }

    private disableForm() {
        ['originRegion', 'originCity', 'originVillage', 'originAddressLine1', 'originCombined', 'senderEmail'].forEach(key => {
            this.form.controls[key].disable();
        });
    }

    public checkOriginAddress() {
        const data = this.form.getRawValue();
        return data.originRegion && data.originRegion.value && data.originCity && data.originCity.value;
    }

    public isAllowedEditCost() {
        return this.permissions.includes('{EDIT_COST') ||
            (this.permissions.includes('{CONDITIONAL_COST') && this.userService.userInfo.isNonAdminChangeCost);
    }

    public toggleshowDetails(isSender = true) {
        if (isSender) {
            this.showDetailsSender = !this.showDetailsSender;
        } else {
            this.showDetailsReceiver = !this.showDetailsReceiver;
        }
    }

    public keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.addShipment();
        }
    }

    public showCreatedDate() {
        return (this.authenticationService.companyId === 64);
    }

    public allowAddCustomerFromQuickAddShipmentForm() {
        return this.userService.userInfo.isAddCustomerFromQuickAddShipmentForm;
    }
    public allowAddingPackageWithFingerprint() {
        return this.userService.userInfo.isPackageFingerprintRequired;
    }

    public onSelectDestinationCombinedAddress() {
        setTimeout(() => {
            this.destinationAddressLine1.text.nativeElement.focus();
        }, 100);
    }

    public addPickup() {
        if (!this.form.valid) {
            return;
        }
        this.isSubmitting = true;
        const data = this.form.getRawValue();
        const body = {customerId: this.customerId, notes: data.notes};
        if (data.driverId) {
            body['driverId'] = data.driverId;
            this.userDriverId = data.driverId;
        }
        this.addPickupRequest(body);
    }
    public getPickupsToConvertCount() {
        this.shipmentService.getToConvertPickupsCount().subscribe(
            (toConvertPickupsNo: number) => {
                this.totalRecordNumber = toConvertPickupsNo;
            }
        );
    }
    public getPickupsToConvert(pageSize , pageNumber) {
        this.isLoading = true;
        const query = `?pageSize=${pageSize}&page=` + (pageNumber);
        this.shipmentService.getToConvertPickups(query).subscribe(
            (pickups: {data: any[]}) => {
                this.pickupsToConvert = pickups.data;
                this.isLoading = false;
                if (pageNumber === 1) {
                    this.nextPickup();
                }
            }
        );
    }
    public nextPickup() {
        if (this.form.valid) {
            this.addShipment( true);
        }
            if ((this.numberOfConvertedPickups !== this.totalRecordNumber && this.pickupIndex === this.pageSize)) {
            this.pickupIndex = 0;
            this.getPickupsToConvert(this.pageSize, this.pageNumber++);
        }
        if (this.pickupsToConvert[this.pickupIndex]) {
            this.isPickup = false;
            this.pickup = this.pickupsToConvert[this.pickupIndex++];
            this.initForm();
        }
        this.numberOfConvertedPickups++;
        if ((this.numberOfConvertedPickups) === (this.totalRecordNumber + 1)) {
            this.translateService.get(
                ['SHIPMENT.COMPLETE_CONVERT_MULTI_MESSAGE' , 'ACTIONS.OK']
            )
                .subscribe(
                    (data) => {
                        const message = data['SHIPMENT.COMPLETE_CONVERT_MULTI_MESSAGE'];
                        const ok = data['ACTIONS.OK'];
                        this.confirmationService.confirm({
                            message: message,
                            rejectVisible: false,
                            acceptLabel: ok,
                            accept: () => {
                                this.isLoading = false;
                            }
                        });
                    }
                );
            this.activeModal.close();
        }

    }
    public showRadioButtons() {
        return !this.pickup &&  !this.isMultiPickups;
    }
    public notRelatedToPickup() {
        return !this.isPickup && !this.pickup && !this.isMultiPickups;
    }
}
