import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FaliureHistoryComponent} from '../faliure-history/faliure-history.component';
import {FADE_ANIMATION} from '../../shared/animations/fade-animation';
import {NgbActiveModal, NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {UtilitiesService} from '../../shared/services/utilities.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ShipmentsService} from '../services/shipments.service';
import {
    COD_STATUS_LIST,
    COMPLETED_SHIPMENTS_STATUS_LIST,
    EXPORTING_HUB_PACKAGES_FILTER_LIST,
    FAILED_PACKAGE_STATUS_LIST,
    IS_TO_FINAL_DESTINATION,
    PACKAGE_PER_RECEIVER_CITY_STATUS_LIST,
    PICKUP_TYPE_LIST,
    RETURNED_SHIPMENTS_STATUS_LIST,
    SHIPMENT_CONSTANTS,
    SHIPMENT_FILTERS_LIST,
    SHIPMENT_STATUS_ALL,
    SPECIAL_STATUS_FOR_HUBS_ACCOUNTING
} from '../services/constants';
import {ChooseHubAndDriverComponent} from '../choose-hub-and-driver/choose-hub-and-driver.component';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {ChooseDriverComponent} from '../choose-driver/choose-driver.component';
import {UsersService} from '../../users/services/users.service';
import * as _ from 'lodash';
import {BulkUpdateComponent} from '../bulk-update/bulk-update.component';
import {AssignPackageComponent} from '../assign-package/assign-package.component';
import {ShippingLinesService} from '../../administration/services/shipping-lines.service';
import {PostponePackageComponent} from '../postpone-package/postpone-package.component';
import {CustomerRegionFilterComponent} from '../customer-region-filter/customer-region-filter.component';
import {ReceiveDriverAndReadByBarcodeComponent} from '../recieve-driver-and-read-by-barcode/receive-driver-and-read-by-barcode.component';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {ExportComponent} from '../export/export.component';
import {PARTNER_CONSTANTS} from '../../partners/partner-constants';
import {StorageService} from '../../shared/services/storage/storage.service';
import {ImageSliderComponent} from '../../shared/components/image-slider/image-slider.component';
import {PackageModel} from '../../shared/models/package.model';
import {CodRequestChangeComponent} from '../cod-request-change/cod-request-change.component';
import {ApplicationStateService} from '../../shared/services/application-state.service';
import {first} from 'rxjs/operators';
import {ShipmentsExcelService} from '../services/shipments-excel.service';
import {ExcelValidationDialogComponent} from '../excel-validation-dialog/excel-validation-dialog.component';
import {CompaniesService} from '../../companies/services/companies.service';
import {SharedService} from '../../shared/services/shared-service';
import {
    COD_STATUS_FILTER,
    CUSTOMERS_FILTER,
    QUERY_PARAMS_FILTERS,
    SEARCH_QUERY_LIST
} from '../services/storages/manage-shipments-storage.keys';
import {CarriedPackagesComponent} from '../../containers/carried-packages/carried-packages.component';
import {ServiceTypesService} from '../../administration/services/service-types.service';
import {OptimizedRoutesComponent} from '../optimized-routes/optimized-routes.component';
import {ScanUnlistedPackagesComponent} from '../scan-unlisted-packages/scan-unlisted-packages.component';
import {AddPackageAttachmentsComponent} from '../add-package-attachments/add-package-attachments.component';
import {ReceivePackingListComponent} from '../receive-packing-list/receive-packing-list.component';
import {Subscription, timer} from 'rxjs';
import {ChangePackageStatusService} from '../services/change-package-status.service';
import {UnloadContainerComponent} from '../../unload-container/unload-container.component';
import * as moment from 'moment';
import {SubscriptionTrackerComponent} from '../../shared/behavior/subscription-tracker.component';
import {Paginator} from 'primeng/paginator';
import {ShipmentsValidationResultsComponent} from '../shipments-validation-results/shipments-validation-results.component';
import {ExportShipmentsComponent} from '../export-shipments/export-shipments.component';
import {ACCOUNTANT_REPORT_ORDER_BY} from '../../ticketing-system/services/constants';
import {ZonesService} from '../../administration/services/zones.service';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {SystemUpdatesService} from '../../system-updates/services/system-updates.service';
import {CancelJntPrintDialogComponent} from '../cancel-jnt-print-dialog/cancel-jnt-print-dialog.component';
import {MapComponent} from '../../shared/components/map/map.component';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {GenerateCustomBarcodesComponent} from '../../administration/components/generate-custom-barcodes/generate-custom-barcodes.component';

declare var window: any;

@Component({
    selector: 'app-manage-shipments-component',
    templateUrl: 'manage-shipments.component.html',
    styleUrls: ['manage-shipments.component.scss'],
    animations: [FADE_ANIMATION]
})
export class ManageShipmentsComponent extends SubscriptionTrackerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    public filterForm: FormGroup;
    public formInitiated = false;
    public totalNumberOfPackages = 10;
    public dateRange: any = {};
    public displayAddPackage = false;
    public displayExcelValidation = false;
    public selectedPackageBarcode: number;
    public debounceUpdateCost;
    public currentPage = 0;
    public isAllSelected = false;
    public isThereSelection = false;
    public showSuccessChangeCodMsg = false;
    public numberOfSelectedPackages = 0;
    public newCodValue: number;
    public isLoadingHistory: boolean;
    public failuresHistory: [];
    public isExportingExcel = false;
    public isDownloadingExcelTemplate = false;
    public isUploadingExcel = false;
    public showAWBPrintModal = false;
    public linkAWB = '';
    public titleCode = '';
    public mobileSearchText = '';
    public showPrintPackagesButton = false;
    public shipmentTypes;
    public shipmentBundleStatuses;
    public dateTypesFilterOptions;
    public exportingHubPackagesFilterList;
    public showShipmentTypes = false;
    isShowRouteOptimizationMessage = true;
    hasRouteOptimization: boolean;
    public isOnlyShowSignature = false;
    public permissions = '';

    public isLoading = false;
    public fromDate;
    public toDate;

    public userRole = '';
    public isDeliveredToSender = false;
    public isFailedPackages = false;
    public partnerId = 0;
    public isCompleted = false;
    public pageNumber = 0;
    public totalRecords = 0;
    public searchContent = '';
    @ViewChild('table') table;
    @ViewChild('loadingTrigger') loadingTrigger;
    @ViewChild('moreActionsPopOver') moreActionsPopOver: NgbPopover;
    @ViewChild('mobileMoreActionsPopOver') mobileMoreActionsPopOver: NgbPopover;
    @ViewChild('shipmentOptions') shipmentOptions;
    @ViewChild('hubFilter') hubFilter;
    public shipmentStatusList;
    public returnedShipmentStatusList;
    public completedShipmentStatusList;
    public pickupTypeList;
    pkgsPerReceiverCityStatusList = [];
    showPkgsPerReceiverCity;
    public shipmentCodStatusList;
    public serviceTypesList;
    public sourceTypesList;
    public shipmentTypesList;
    public selectedShipmentStatus = 'ALL';
    public filterByPartnerId = 0;
    public selectedReturnedShipmentStatus = 'ALL';
    public selectedCompletedShipmentStatus = 'ALL';
    public selectedPickupType = 'ALL';
    public selectedAttachmentsFilter = 'ALL';
    public selectedLocationFilter = 'ALL';
    public selectedDeliveryLocationFilter = 'ALL';
    public selectedSignatureFilter = 'ALL';
    public shipmentStatusAll = SHIPMENT_STATUS_ALL;
    public isFailed = false;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public status = '';
    public driverID = 0;
    public hubId = 0;
    public toHubId = 0;
    public fromHub = 0;
    public toHub = 0;
    public shelfId = 0;
    public customerID = 0;
    public shippingLine;
    public destinationShippingLine;
    public destinationRegion;
    public destinationCity;
    public transferCompany;
    public shipmentLines: any[] = [];
    public DestinationShipmentLines: any[] = [];
    public destinationRegions = [];
    public destinationCities = [];
    public transferCompanies = [];
    public customersList: any[] = [];
    public TripList: any[] = [];
    public shipmentMethodList: any[] = [];
    public codStatusList: any[] = [];
    public isBlock = true;
    public isTrackingPackage;
    public isNotesAdded = false;
    public hasMore = true;
    public isTrucking = false;
    public loadingPackages = false;
    public tableColumnCount = 16;
    public isOnlyAttachmentPackages = false;
    public isOnlyReceiverLocations = false;
    public isOnlyShipmentWithCostZero = false;
    @ViewChild('fileChooserShipments') fileChooserShipments: ElementRef;
    @ViewChild('fileChooserShipmentCosts') fileChooserShipmentCosts: ElementRef;
    @ViewChild('changePackageStatusPopOver') changePackageStatusPopOver: NgbPopover;
    @ViewChild('datepicker') datepicker;

    @Input() archivedMode = false;
    @Input() isDeleted = false;


    public filtersList;
    public selectedLanguage = '';
    public params = null;
    public first = 0; // firstElement in paginator

    public searchParameters: any = {};
    public countResponse = false;

    // booleans for table customization
    public isShowPrice = true;
    public isShowCOD = true;
    public isShowSource = true;
    public isShowCustomer = true;
    public isShowReceiverPhone = true;
    public isShowSender = true;
    public isShowWeight = true;
    public isShowDriverEarning = true;
    public isShowThirdPartyTrackingNo = true;
    public isShowReceiver = true;
    public isShowBookedOnDate = true;
    public isShowFirstPickupDate = true;
    public isShowDeliveryDate = true;
    public isShowExpectedDeliveryDate = true;
    public isShowExpectedPickupDate = false;
    public isShowNotes = true;
    public isShowAttachments = true;
    public isShowReceiverLocation = true;
    public isShowDeliveryLocation = true;
    public isShowServiceType = false;
    public isShowShipmentType = false;
    public isShowCustomerType = false;
    public isShowLastPickupDate = false;
    public isShowPostponedDate = false;
    public isShowInvoice = false;
    public isShowCodStatus = true;
    public isShowVehicleType = true;
    public isShowSignature = true;
    public isShowOriginalCod = true;
    public isShowDescription = true;
    public companyId;
    public companyHadTripNumbers = [256, 22]; // SAR for Production and mohanad for testing

    public senderRegionData: { customerName: string, originRegion: any, originCity: any, originVillage: any } = {
        customerName: null,
        originRegion: null,
        originCity: null,
        originVillage: null
    };

    public packageWeightData: { weightFrom: number, weightTo: number } = {
        weightFrom: null,
        weightTo: null
    };

    public receiverRegionData: { customerName: string, destinationRegion: any, destinationCity: any, destinationVillage: any } = {
        customerName: null,
        destinationRegion: null,
        destinationCity: null,
        destinationVillage: null
    };
    public translationLoaded = false;

    sortByList = [];

    public vehicleTypesList = [];
    public selectedVehicleType: any = null;
    selectedVehicleTypeName: string;

    public serviceTypesAcList = [];
    public receiverCityList = [];
    public selectedServiceType: any = null;
    public selectedServiceTypeName: string;
    public selectedReceiverCityName: string;
    public selectedReceiverCity: any;
    isHubUserRole = false;

    resolveMsg: string;
    isSendingMsg = false;
    failedStatusList;
    selectedFailedStatus;

    companyIdsToShowTransferButton = [137, 167, 94, 153, 128, 155, 207];

    @ViewChild('paginator') paginator: Paginator;
    @ViewChild('exportPanelOptions') exportPanelOptions;
    @ViewChild('shipmentMenu') shipmentMenu;
    @ViewChild('changeCodPanel') changeCodPanel;
    @ViewChild('resolveFailurePanel') resolveFailurePanel;
    @ViewChild('messagesPanel') messagesPanel;
    @ViewChild('selectedCategoriesDropDown') selectedCategoriesDropDown;
    @ViewChild('senderRegionPopOver') senderRegionPopOver: NgbPopover;
    @ViewChild('packageWeightPopOver') packageWeightPopOver: NgbPopover;
    @ViewChild('volumetricackageWeightPopOver') volumetricpackageWeightPopOver: NgbPopover;
    @ViewChild('receiverRegionPopOver') receiverRegionPopOver: NgbPopover;
    @ViewChild('validationDialog') validationDialog: ExcelValidationDialogComponent;

    /**
     *
     */
    public shipmentsCategories = [
        {label: 'SHIPMENT.CATEGORIES.PENDING', value: 'PENDING'},
        {label: 'SHIPMENT.CATEGORIES.IN_PREPARATION', value: 'BEING_PROCESSED'},
        {label: 'SHIPMENT.CATEGORIES.IN_PROCESS', value: 'IN_PROGRESS'},
        {label: 'SHIPMENT.CATEGORIES.DELIVERED', value: 'DELIVERED'},
        {label: 'SHIPMENT.CATEGORIES.POSTPONED', value: 'POSTPONED'},
        {label: 'SHIPMENT.CATEGORIES.RETURNED', value: 'RETURNED'},
        {label: 'SHIPMENT.CATEGORIES.FAILED', value: 'FAILED'},
        {label: 'SHIPMENT.CATEGORIES.CANCELED', value: 'CANCELLED'}
    ];

    public selectedCategories: {}[] = [];

    public selectedSenderShipmentLine: { label: string, value: number }[] = [];
    public selectedReceiverShipmentLine: { label: string, value: number }[] = [];
    public selectedCustomers: { label: string, value: number }[] = [];
    public selectedTrip: { label: string, value: number };
    public selectedShipmentMethod: { label: string, value: number };
    public customerTypes: { value: string, label: string }[];

    public shipmentsData: any[] = [];
    private fetchedData: any[] = [];

    public selectedShipment: any;

    /**
     *
     */
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;

    /**
     * Used to track the shipments which clicked options,
     * in order to do actions up on it.
     */
    public triggeredDropdownShipment: any = {};

    public dateRangPickerOptions: any = {};
    public excludeDatesFilter = false;

    public shipmentBarcode: number;
    public showShipmentDetails = false;

    public displayEditPackage = false;
    public menuNumber: number;
    public isShowTopActionButtons = true;
    public multiSelectFilterSettings = {};
    public selectedMultiSelectCustomers: { id: number, name: string }[] = [];
    public selectedMultiSelectCodStatus: string[] = [];
    public selectedRowIndex = 0;
    public currency = '';
    public isFollowUpChangeCod = false;
    public isBundlesFollowUps = false;
    public isSearchContent = false;
    public isPickupUnlistedPackagesEnabled = false;
    public isLcl = false;
    public isEnableAramexTransfer = false;
    public isEnableExportingBetweenHubs = false;
    public isDriverEarningEnabled = false;
    @Input() isFromLclContainersManagement = false;
    @Input() isFollowUpType;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;

    private getPackagesSubscription: Subscription;
    private getTotalSubscription: Subscription;
    public defaultAddPageForm = true;
    private updateCalled = false;
    private previousLabel = true;
    canRemovePkgs = false;
    isShowLastStatusDate = true;
    filterMenuOpen = false;
    @ViewChild('filtersMenu') filtersMenu;
    isClonePackage: any;
    markAllAsSeenRequest = false;
    isNotifyNewUpdate = false;
    isPostponedPackagesInCurrentDate = false;
    userInfo;
    transferToList = [];
    enableToast = true;
    companies = Companies;
    public isToFinalDestinationStatus = IS_TO_FINAL_DESTINATION;
    isBundleImport = false;
    isBundleSupported = false;

    constructor(
        @Inject(FormBuilder) private formsBuilder: FormBuilder,
        private modalService: NgbModal,
        private translateService: TranslateService,
        private router: Router,
        private shipmentService: ShipmentsService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private confirmService: ConfirmationService,
        private authenticationService: AuthenticationService,
        private utilitiesService: UtilitiesService,
        private dateRangePickerService: DateRangePickerService,
        private usersService: UsersService,
        private shippingLinesService: ShippingLinesService,
        public userService: UserService,
        private rolesService: RolesService,
        private storageService: StorageService,
        private shipmentsService: ShipmentsService,
        private applicationStateService: ApplicationStateService,
        private shipmentExcelService: ShipmentsExcelService,
        private companyService: CompaniesService,
        private changeDetectionRef: ChangeDetectorRef,
        private confirmationService: ConfirmationService,
        private sharedService: SharedService,
        private serviceTypesService: ServiceTypesService,
        private changePackageStatusService: ChangePackageStatusService,
        private zonesService: ZonesService,
        private systemUpdatesService: SystemUpdatesService,
        public customizationCompaniesService: CustomizationCompanyService
    ) {
        super();
        this.messageService.clear();
        this.selectedTrip = {label: this.translateService.instant('ALL'), value: 0};
        this.selectedShipmentMethod = {label: this.translateService.instant('ALL'), value: 0};
        this.customerTypes = [{value: this.translateService.instant('ALL'), label: ''}];
    }

    public geExistSearchQueryParams() {
        if (this.storageService.get(SEARCH_QUERY_LIST.BARCODE)) {
            this.searchParameters.barcode.value = JSON.parse(this.storageService.get(SEARCH_QUERY_LIST.BARCODE));
        }
        if (this.storageService.get(SEARCH_QUERY_LIST.RECEIVER_PHONE)) {
            this.searchParameters.receiverPhone.value = JSON.parse(this.storageService.get(SEARCH_QUERY_LIST.RECEIVER_PHONE));
        }
        if (this.storageService.get(SEARCH_QUERY_LIST.INVOICE_NUMBER)) {
            this.searchParameters.invoiceNumber.value = JSON.parse(this.storageService.get(SEARCH_QUERY_LIST.INVOICE_NUMBER));
        }
        if (this.storageService.get(CUSTOMERS_FILTER)) {
            this.selectedMultiSelectCustomers = JSON.parse(this.storageService.get(CUSTOMERS_FILTER));
        }
        if (this.storageService.get(COD_STATUS_FILTER)) {
            this.selectedMultiSelectCodStatus = JSON.parse(this.storageService.get(COD_STATUS_FILTER));
        }
    }

    public setExistQueryParams() {
        // this.storageService.remove(QUERY_PARAMS_FILTERS.STATUS);
        // this.storageService.remove(QUERY_PARAMS_FILTERS.SEARCH);
        if (this.selectedShipmentStatus) {
            this.storageService.set(QUERY_PARAMS_FILTERS.STATUS, JSON.stringify(this.selectedShipmentStatus));
        }
        if (this.searchContent) {
            this.storageService.set(QUERY_PARAMS_FILTERS.SEARCH, JSON.stringify(this.searchContent));
        }
    }

    public setExistSearchQueryParams() {
        this.storageService.remove(SEARCH_QUERY_LIST.BARCODE);
        this.storageService.remove(SEARCH_QUERY_LIST.RECEIVER_PHONE);
        this.storageService.remove(SEARCH_QUERY_LIST.INVOICE_NUMBER);
        if (this.searchParameters.barcode.value) {
            this.storageService.set(SEARCH_QUERY_LIST.BARCODE, JSON.stringify(this.searchParameters.barcode.value));
        }
        if (this.searchParameters.receiverPhone.value) {
            this.storageService.set(SEARCH_QUERY_LIST.RECEIVER_PHONE, JSON.stringify(this.searchParameters.receiverPhone.value));
        }
        if (this.searchParameters.invoiceNumber.value) {
            this.storageService.set(SEARCH_QUERY_LIST.INVOICE_NUMBER, JSON.stringify(this.searchParameters.invoiceNumber.value));
        }
        if (this.selectedMultiSelectCustomers) {
            this.storageService.set(CUSTOMERS_FILTER, JSON.stringify(this.selectedMultiSelectCustomers));
        }
        if (this.selectedMultiSelectCodStatus) {
            this.storageService.set(COD_STATUS_FILTER, JSON.stringify(this.selectedMultiSelectCodStatus));
        }
    }


    setOrderByOptions() {
        this.sortByList = ACCOUNTANT_REPORT_ORDER_BY.map((value) => {
            const label = this.translateService.instant(value);
            return {label, value};
        });
    }

    ngOnInit() {
        // this.storageService.set(QUERY_PARAMS_FILTERS.STATUS, 'ALL');
        // alert(this.storageService.get(QUERY_PARAMS_FILTERS.STATUS));
        this.userInfo = this.userService.userInfo;
        this.defaultAddPageForm = !this.userService.userInfo.isDistributor;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isDriverEarningEnabled = this.userService.userInfo.isDriverEarningEnabled;
        this.isBundleSupported = this.userService.userInfo.isBundleSupported;
        this.isHubUserRole = this.userService.isHubUserRole;

        this.route.url.subscribe((data) => {
            const isPartner = data.find(element => element.path === 'active-partners');
            if (isPartner !== undefined) {
                this.route.params.subscribe(
                    (params: Params) => {
                        this.partnerId = params.id;
                    }
                );
            }
        });
        this.initButtons();
        this.setOrderByOptions();
        this.hasMore = true;
        this.selectedLanguage = this.translateService.currentLang;
        this.currency = this.userService.getCurrency();
        this.hasRouteOptimization = this.userService.userInfo.hasRouteOptimization;
        this.isShowRouteOptimizationMessage = this.utilitiesService.isShowRouteOptimizationMessage;
        this.isPickupUnlistedPackagesEnabled = this.userService.userInfo.isPickupUnListedPackagesByAdmin;
        this.isLcl = this.userService.userInfo.isLcl;
        this.isEnableAramexTransfer = this.userService.userInfo.isEnableAramexTransfer;
        this.isTrucking = this.userService.userInfo.isTrucking;
        this.getCustomers();
        this.getAllTripNumbers();
        if (this.isJettCompany()) {
            this.getShipmentMethodList();
        }
        this.setTableCustomization();
        this.initCategories();
        this.initSearchParams();
        this.initForm();
        this.setShipmentFilters();
        this.initUserPermissions();
        this.translateCustomerTypes();
        this.companyId = this.authenticationService.companyId;
        if (this.isTrucking || this.userService.userInfo.isDistributor) {
            this.selectedShipmentStatus = 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER';
        }
        if (this.userRole === 'CLERK' || this.userRole === 'HUB_CLERK') {
            this.selectedShipmentStatus = 'SCANNED_BY_HANDLER_AND_UNLOADED';
        }
        if (this.selectedShipmentStatus === 'PENDING_CUSTOMER_CARE_APPROVAL') {
            if (this.authenticationService.companyId === 64 || this.authenticationService.companyId === 144
                || this.authenticationService.companyId === 143 || this.authenticationService.companyId === 163) {
                this.selectedShipmentStatus = 'ALL';
            } else {
                if (this.userRole === 'DISPATCHER') {
                    this.selectedShipmentStatus = 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER';
                } else {
                    if (this.userRole === 'CUSTOMER_CARE') {
                        this.selectedShipmentStatus = 'PENDING_CUSTOMER_CARE_APPROVAL';
                    } else {
                        if (this.userRole === 'HUB_MANAGER'||this.userRole === 'MULTIPLE_HUBS_MANAGER') {
                            this.selectedShipmentStatus = 'SCANNED_BY_HANDLER_AND_UNLOADED';
                        }
                    }
                }
            }
        }
        this.extractQueryParams();
        this.shipmentStatusList = this.setShipmentStatus();
        this.failedStatusList = this.setFailedStatusList();
        this.returnedShipmentStatusList = this.setReturnedShipmentStatusList();
        this.completedShipmentStatusList = this.setCompletedShipmentStatusList();
        this.pickupTypeList = this.setPickupTypeList();
        this.pkgsPerReceiverCityStatusList = this.setPkgsPerReceiverCityStatusList();
        this.setServiceTypesList();
        this.setSourceTypesList();
        this.setShipmentTypesList();
        this.fetchShippingLines();
        this.fetchDestinationShippingLines();
        this.shipmentTypes = [
            {label: this.translateService.instant('SHIPMENT.SENT_PACKAGES'), value: false},
            {label: this.translateService.instant('SHIPMENT.RECEIVED_PACKAGES'), value: true},
        ];

        this.dateTypesFilterOptions = [
            {label: this.translateService.instant('SHIPMENT.CREATED_DATE'), value: 'CREATED_DATE'},
            {label: this.translateService.instant('SHIPMENT.DELIVERY_DATE'), value: 'DELIVERY_DATE'},
            {label: this.translateService.instant('SHIPMENT.FIRST_PICKUP_DATE'), value: 'FIRST_PICKUP_DATE'},
            {label: this.translateService.instant('SHIPMENT.RETURN_DATE'), value: 'RETURN_DATE'},
            {label: this.translateService.instant('SHIPMENT.POSTPONED_DATE'), value: 'POSTPONED_DATE'},
            {label: this.translateService.instant('LAST_STATUS_DATE'), value: 'LAST_STATUS_DATE'},
        ];

        this.exportingHubPackagesFilterList = this.setExportingHubPackagesFilterList();
        if (this.authenticationService.companyId === 86) {
            this.showShipmentTypes = true;
        }

        this.notHidePanelsWhenResize();
        this.getCompanyBillingNotification();
        this.translationResponse();
        this.canRemovePkgs = this.userService.isLoggedInCanRemovePackage();

        this.subscriptions.push(this.shipmentService.shipmentAddedEvent.subscribe((result: {
            isShipmentAdded: boolean,
            isEditMode: boolean
        }) => {
            if (result.isShipmentAdded) {
                this.initShipments(true, result.isEditMode);
            }
        }));
        this.subscriptions.push(this.userService.getSystemUpdateCounterAsObservable().subscribe(value => {
            if (value) {
                this.isNotifyNewUpdate = true;
                this.markAllAsSeenRequest = false;

            } else {
                this.isNotifyNewUpdate = false;
            }
        }));

        this.userService.getPostponedPackagesInCurrentDate().subscribe((value: boolean) => {
            this.isPostponedPackagesInCurrentDate = value;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.storageService.remove(QUERY_PARAMS_FILTERS.SEARCH);
        this.setExistQueryParams();
        this.setExistSearchQueryParams();
    }

    public translationResponse() {
        this.translateService.get('MANAGE_SHIPMENTS.TITLE').subscribe(
            () => {
                this.translationLoaded = true;
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.hasMore = true;
        if ((changes['isFollowUpType'] && changes['isFollowUpType'].previousValue !== changes['isFollowUpType'].currentValue) &&
            ((changes['isDeleted'] && !changes['isDeleted'].currentValue) || !changes['isDeleted'])) {
            this.isFollowUpChangeCod = changes['isFollowUpType'].currentValue === 'ALL';
            this.isBundlesFollowUps = changes['isFollowUpType'].currentValue === 'BUNDLES';
            this.shipmentStatusList = this.setShipmentStatus();

            this.selectedShipmentStatus = changes['isFollowUpType'].currentValue;
            this.showPkgsPerReceiverCity = this.selectedShipmentStatus === 'PACKAGES_BASED_ON_RECIPIENT_CITY';
            this.initShipments(true);
        } else if (changes['isDeleted']?.currentValue) {
            this.isFollowUpType = 'ALL';
            this.isBundlesFollowUps = false;
            this.selectedShipmentStatus = 'ALL';
            this.initShipments(true);
        }
    }

    ngAfterViewInit() {
        this.getColumnCount();
        setTimeout(() => {
            if (this.table && this.table.wrapperViewChild) {
                const el = this.table.wrapperViewChild.nativeElement;
                el.onscroll = (event) => {
                    this.onsScrollShipmentTable(event, false);
                    // event.stopPropagation();
                };
            }
        }, 300);
    }

    public getCompanyBillingNotification() {
        this.companyService.getCompanyBillingNotification().subscribe(
            (response: { isNotify: boolean, isBlock: boolean, notificationMessage: string }) => {
                this.isBlock = response.isBlock;
                if (response.isNotify) {
                    this.messageService.add({severity: 'error', detail: response.notificationMessage});
                }
            }, error => {
                console.error(error);
            }
        );
    }

    public loadPackagesLazy() {
        this.initForm();
        this.initShipments(false, false, false);
    }

    @HostListener('scroll', ['$event'])
    onsScrollShipmentTable(event, isMobile = false) {

        if (isMobile) {
            this.handleMobilePackagesLoading(event, false);
        } else {
            this.handlePackagesLoading(event, false);
        }
    }

    public handleMobilePackagesLoading(event, getCount) {
        if (this.loadingPackages || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
            return;
        }

        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadingPackages = true;
            this.pageNumber++;
            this.initShipments(false, false, getCount);
        }
    }

    public handlePackagesLoading(event: any, getCount) {
        // Handle load packages lazy.

        if (this.loadingPackages || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
            return;
        }

        const loading_trigger_item = document.getElementById('loading_trigger');
        if (!loading_trigger_item) {
            return;
        }
        const loading_trigger_offset = loading_trigger_item.offsetTop;

        if (event.target.offsetHeight + event.target.scrollTop > loading_trigger_offset - 5) {
            this.loadingPackages = true;
            this.pageNumber++;
            this.initShipments(false, false, getCount);
        }
    }

    public initShipments(isFromFilter = false, isEdit = false, getCount = false, resetFilters = false) {
        if (isFromFilter) {
            this.pageNumber = 0;
            this.hasMore = true;
            this.shipmentsData = [];
            if (!resetFilters) {
                getCount = true;
            }
        }
        if (this.pageNumber == 0) {
            this.handleFillingTheScreen();
            this.onToggleSelection();
        }
        const URLQuery = this.createQuery(isEdit);
        const body = this.setBody();

        this.fetchedData = [];
        this.spinnerState = SpinnerState.LOADING;
        if (this.getPackagesSubscription) {
            this.getPackagesSubscription.unsubscribe();
        }
        if (this.partnerId) {
            this.getPackagesSubscription = this.shipmentsService.getPartnerPackages(URLQuery, body).subscribe(
                (response: { pkgs: any[], totalRecordsNo: number }) => {
                    this.getShipmentsResponse(response);
                }
            );
        } else {
            this.getPackagesSubscription = this.shipmentService.getPackages(URLQuery, body).subscribe(
                (response: { pkgs: any[] }) => {
                    this.fetchedData = response.pkgs;
                    const tableBodyScrollTopBeforeAddingValue = document.getElementsByClassName('shipment-body')[0].getElementsByClassName('p-datatable-wrapper')[0].scrollTop;
                    if (response.pkgs && response.pkgs.length < this.pageSize) {
                        this.hasMore = false;
                    }
                    if (isEdit) {
                        this.shipmentsData.splice(((Math.trunc(this.selectedRowIndex / 10))), 10);
                        const index = (Math.trunc((this.selectedRowIndex / 10) + 1) * 10);
                        this.fetchedData.forEach(
                            (shipment) => {
                                this.shipmentsData.splice(index, 0, shipment);
                            }
                        );
                    } else if (isFromFilter) {
                        this.shipmentsData = this.fetchedData;
                    } else {
                        this.shipmentsData = [...this.shipmentsData, ...this.fetchedData];
                    }
                    this.spinnerState = SpinnerState.LOADED;
                    this.changeDetectionRef.detectChanges();
                    document.getElementsByClassName('shipment-body')[0].getElementsByClassName('p-datatable-wrapper')[0].scrollTop = tableBodyScrollTopBeforeAddingValue;
                    this.loadingPackages = false;
                }, (err) => {
                    console.error(err);
                }
            );

            if (getCount && !isEdit) {
                if (this.getTotalSubscription) {
                    this.getTotalSubscription.unsubscribe();
                }
                this.getTotalSubscription = this.shipmentService.getPackagesTotalNo(URLQuery, body).subscribe(
                    (totalRecordNo: number) => {
                        this.totalRecords = totalRecordNo;
                        this.countResponse = true;
                    }
                );
            }
        }
    }

    private getShipmentsResponse(response) {
        if (this.driverID || this.partnerId) {
            this.totalRecords = response.totalRecordsNo;
        }
        const tableBodyScrollTopBeforeAddingValue = document.getElementsByClassName('shipment-body')[0].scrollTop;
        if (response.pkgs && response.pkgs.length < this.pageSize) {
            this.hasMore = false;
        }
        this.shipmentsData = [...this.shipmentsData, ...response.pkgs];
        if (this.driverID || this.partnerId) {
            if (this.totalRecords > 0) {
                this.spinnerState = SpinnerState.LOADED;
            } else {
                this.spinnerState = SpinnerState.EMPTY;
                this.isThereSelection = false;
            }
        }
        this.changeDetectionRef.detectChanges();
        document.getElementsByClassName('shipment-body')[0].scrollTop = tableBodyScrollTopBeforeAddingValue;
        this.loadingPackages = false;
    }

    public initUserPermissions() {
        // send the page name, to get the user permissions.
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.userRole = role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
    }


    public setCustomerDropDownSettings() {
        this.multiSelectFilterSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 0,
            allowSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: this.translateService.instant('GENERAL.SEARCH'),
            noFilteredDataAvailablePlaceholderText: this.translateService.instant('GENERAL.NO_RESULTS_FOUND'),

        };
    }

    public setCodStatusDropDownSettings() {
        this.multiSelectFilterSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'label',
            itemsShowLimit: 0,
            allowSearchFilter: false,
            enableCheckAll: true,
            selectAllText: this.selectedLanguage === 'en' ? 'Select ALL' : 'الكل',
            unSelectAllText: this.selectedLanguage === 'en' ? 'Unselect All' : 'الغاء'
        };
    }

    private setShipmentFilters() {
        this.filtersList = SHIPMENT_FILTERS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    public toggleIsSearchContent(value) {
        this.isSearchContent = value;
    }

    private setTableCustomization() {
        this.shipmentService.getGetManageShipmentTableCustomization().subscribe(
            (response: any) => {
                this.isShowPrice = response.isShowCost;
                this.isShowCOD = response.isShowCod;
                this.isShowSource = response.isShowSource;
                this.isShowCustomer = response.isShowCustomer;
                this.isShowReceiverPhone = response.isShowReceiverPhone;
                this.isShowSender = response.isShowSenderName;
                this.isShowWeight = response.isShowWeight;
                this.isShowDriverEarning = response.isShowDriverEarning;
                this.isShowThirdPartyTrackingNo = response.isShowThirdPartyTrackingNo;
                this.isShowReceiver = response.isShowReceiverName && !this.isLcl;
                this.isShowBookedOnDate = response.isShowCreatedDate;
                this.isShowFirstPickupDate = response.isShowFirstPickupDate;
                this.isShowDeliveryDate = response.isShowDeliveryDate;
                this.isShowExpectedDeliveryDate = response.isShowExpectedDeliveryDate;
                this.isShowExpectedPickupDate = response.isShowExpectedPickupDate;
                this.isShowNotes = response.isShowNotes;
                this.isShowPostponedDate = response.isShowPostponedDeliveryDate;
                this.isShowInvoice = response.isShowInvoiceNumber;
                this.isShowVehicleType = response.isShowVehicleType && !this.isLcl;
                this.isShowAttachments = response.isShowAttachment;
                this.isShowReceiverLocation = response.isShowReceiverLocation;
                this.isShowDeliveryLocation = response.isShowDeliveryLocation;
                this.isShowServiceType = response.isShowServiceType;
                // keep it for now will change after BE support it
                this.isShowShipmentType = response.isShowShipmentType || true;
                this.isShowCustomerType = response.isShowCustomerType;
                this.isShowLastStatusDate = response.isShowLastStatusDate;
                this.isShowLastPickupDate = response.isShowLastPickupDate;
                this.isShowCodStatus = response.isShowCodStatus;
                this.isShowSignature = response.isShowSignature;
                this.isShowDescription = response.isShowDescription;
                this.isShowOriginalCod = response.isShowOriginalCod && !this.isLcl;
                this.getColumnCount();
            }
        );
    }

    private fetchShippingLines() {
        this.shippingLinesService.getShippingLines('SENDER').subscribe((response: any) => {
            if (response && response.length) {

                this.shipmentLines = response.map(user => {
                    return {
                        label: user.name,
                        value: user.id,
                    };
                });
            }
        });
    }

    getShipmentMethodList() {
        this.shipmentMethodList = SHARED_CONSTANTS.SHIPMENT_TYPES.JETT_SHIPMENT_TYPES.map(type => {
            return {
                label: this.translateService.instant(`JETT.${type}`),
                value: type
            };
        });
        this.shipmentMethodList.unshift({label: this.translateService.instant('ALL'), value: 0});
    }

    getAllTripNumbers() {
        this.shipmentService.getTripsNumber().subscribe(
            (response: any) => {
                response.unshift({tripNumber: this.translateService.instant('ALL'), id: 0});
                this.TripList = response.map(
                    (trip) => {
                        return {value: trip.id, label: trip.tripNumber};
                    }
                );
            }, (error) => {

            }
        );
    }

    private fetchDestinationShippingLines() {
        this.shippingLinesService.getShippingLines('RECEIVER').subscribe((response: any) => {
            if (response && response.length) {
                this.DestinationShipmentLines = response.map(user => {
                    return {
                        label: user.name,
                        value: user.id,
                    };
                });
            }
        });
    }

    public onSearch() {
        if (!this.debounceUpdateCost) {
            this.debounceUpdateCost = _.debounce(() => {
                this.pageNumber = 0;
                this.shipmentsData = [];
                this.table.reset();
            }, 1000);
        }
        this.debounceUpdateCost();
    }

    public setServiceTypesList() {
        this.shipmentService.getShipmentServices().subscribe((response: any) => {
            response.unshift('ALL');
            this.serviceTypesList = response.map(
                (service) => {
                    let serviceLabel = service;
                    this.translateService.get(
                        service
                    )
                        .subscribe(
                            (data) => {
                                serviceLabel = data;
                            }
                        );
                    return {value: service, label: serviceLabel};
                }
            );
        });
    }

    public setSourceTypesList() {
        let sourceTypes;
        if (this.currency === 'SAR') {
            sourceTypes = SHARED_CONSTANTS.SOURCE_TYPES_SAR;
        } else {
            sourceTypes = SHARED_CONSTANTS.SOURCE_TYPES;
        }
        if (this.userInfo.role === 'FULFILLMENT_USER') {
            sourceTypes = ['FULFILLMENT'];
        }

        sourceTypes = _.clone(sourceTypes);
        if (!this.userInfo.isFulfilmentEnabled) {
            sourceTypes.splice(sourceTypes.indexOf('FULFILLMENT'), 1);
        }
        if (this.userInfo.role !== 'FULFILLMENT_USER') {
            sourceTypes.unshift('ALL');
        }
        this.sourceTypesList = sourceTypes.map(type => {
            return {
                label: this.translateService.instant('INTEGRATION_SOURCE.' + type),
                value: type
            };
        });
    }

    public setShipmentTypesList() {
        let ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.GENERAL_SHIPMENT_TYPES;
        if (this.companyId === 209) { // sare3 company
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.SARE3_SHIPMENT_TYPES;
        }
        if (this.isLcl) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.LCL_SHIPMENT_TYPES;
        } else if (this.isTrucking) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.TRUCKING_SHIPMENT_TYPES;
        }
        // if (this.isJettCompany()) {
            //     ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.JETT_OTHER_SHIPMENTS_TYPES;
            // }
        //

        ShipmentTypes = _.clone(ShipmentTypes);
        ShipmentTypes.unshift('ALL');
        this.shipmentTypesList = ShipmentTypes.map(type => {
            return {
                label: this.translateService.instant(this.isTrucking ? `TRUCKING.SHIPMENT_TYPE.${type}` : type),
                value: type
            };
        });

    }

    private translateCustomerTypes() {
        this.customerTypes = [
            {value: 'NORMAL', label: this.translateService.instant('CUSTOMER_TYPES.NORMAL')},
            {value: 'EXPRESS', label: this.translateService.instant('CUSTOMER_TYPES.EXPRESS')}
        ];
        this.customerTypes.unshift({value: 'ALL', label: this.translateService.instant('CUSTOMER_TYPES.ALL')});
    }

    public setShipmentCodStatus() {
        return COD_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    public setShipmentStatus() {
        const x = this.changePackageStatusService.getPackageStatusList(this.userRole, [], this.isBundlesFollowUps && this.customizationCompaniesService.checkCompanies(this.companies.SPRINT));
        if (!this.isBundlesFollowUps && this.customizationCompaniesService.checkCompanies(Companies.SPRINT)) {
            x.push({label: this.translateService.instant(`IN_TRANSIT`), value: 'IN_TRANSIT'});
            x.push({label: this.translateService.instant(`IN_TRANSIT_TO_CUSTOMER`), value: 'IN_TRANSIT_TO_CUSTOMER'});
        }
        if (['NIS', 'JOD'].indexOf(this.currency) < 0) {
            x.push({label: this.translateService.instant(`OUT_FOR_DELIVERY`), value: 'OUT_FOR_DELIVERY'});
        }
        return x;
    }

    setFailedStatusList() {
        return FAILED_PACKAGE_STATUS_LIST.map(status => {
            return {label: this.translateService.instant(`MANAGE_SHIPMENTS.FAILED_SHIPMENTS_STATUS_LIST.${status}`), value: status};
        });
    }

    setReturnedShipmentStatusList() {
        return RETURNED_SHIPMENTS_STATUS_LIST.filter(value => {
            if (this.customizationCompaniesService.checkCompanies(Companies.SPRINT) && ['PARTIALLY_DELIVERED', 'SWAPPED', 'COMPLETED'].indexOf(this.selectedShipmentStatus) >= 0) {
                return value !== 'DELIVERED_TO_SENDER';
            }
            return true;
        }).map(status => {
            return {label: this.translateService.instant(`MANAGE_SHIPMENTS.RETURNED_SHIPMENTS_STATUS_LIST.${status}`), value: status};
        });
    }

    setCompletedShipmentStatusList() {
        return COMPLETED_SHIPMENTS_STATUS_LIST.map(status => {
            return {
                label: this.translateService.instant(`MANAGE_SHIPMENTS.COMPLETED_SHIPMENTS_STATUS_LIST.${status}`),
                value: status
            };
        });
    }

    showReturnedShipmentStatus() {
        return this.selectedShipmentStatus === 'RETURNED_BY_RECIPIENT' || this.selectedShipmentStatus === 'SWAPPED' || this.selectedShipmentStatus === 'PARTIALLY_DELIVERED' ||
            (this.selectedShipmentStatus === 'COMPLETED' && this.selectedCompletedShipmentStatus !== 'ALL' && this.selectedCompletedShipmentStatus !== 'DELIVERED');
    }

    setPickupTypeList() {
        return PICKUP_TYPE_LIST.map(status => {
            return {
                label: this.translateService.instant(`MANAGE_SHIPMENTS.PICKUP_TYPE_LIST.${status}`),
                value: status
            };
        });
    }

    setPkgsPerReceiverCityStatusList() {
        const res = PACKAGE_PER_RECEIVER_CITY_STATUS_LIST.map(status => {
            return {
                label: this.translateService.instant(`MANAGE_SHIPMENTS.PACKAGE_PER_RECEIVER_CITY_STATUS_LIST.${status}`),
                value: status
            };
        });
        return res;
    }

    public extractQueryParams() {
        this.route.queryParams.subscribe(
            (params: Params) => {

                this.params = params;
                let loadShipments = false;
                if (params.status) {
                    const oldShipmentStatus = this.selectedShipmentStatus;
                    this.status = params.status;

                    this.selectedShipmentStatus = this.status;
                    if (oldShipmentStatus && oldShipmentStatus.length && (this.selectedShipmentStatus !== oldShipmentStatus)) {
                        loadShipments = true;
                    }
                }
                if (params.fromDate) {
                    // if (!this.fromDate) {
                    this.fromDate = new Date(Date.parse(params.fromDate));
                    this.dateRangPickerOptions.startDate = new Date(this.fromDate);
                    // this.filterForm.controls.fromDate.setValue(this.fromDate);
                    // }

                }
                if (params.toDate) {
                    // if (!this.toDate) {
                    this.toDate = new Date(Date.parse(params.toDate));
                    this.dateRangPickerOptions.endDate = new Date(this.toDate);
                    // this.filterForm.controls.toDate.setValue(this.toDate);


                    // }
                }
                this.dateRangPickerOptions = {...this.dateRangPickerOptions};
                if (params.hubId) {
                    this.hubId = params.hubId;
                }
                if (params.shelfId) {
                    this.shelfId = params.shelfId;
                }
                if (params.driverId) {
                    this.driverID = params.driverId;
                }
                if (params.customerId) {
                    this.customerID = params.customerId;
                }
                if (params.shippingLine) {
                    this.shippingLine = {id: params.shippingLine};
                }
                if (params.destinationShippingLine) {
                    this.destinationShippingLine = {id: params.destinationShippingLine};
                }
                if (params.destinationRegion) {
                    this.destinationRegion = {id: params.destinationRegion};
                }
                if (params.destinationCity) {
                    this.destinationCity = {id: params.destinationCity};
                }
                if (params.transferCompany) {
                    this.transferCompany = {id: params.transferCompany};
                }
                if (params.search) {
                    this.searchContent = params.search;
                }
                if (params.isDeliveredToSender) {
                    this.isDeliveredToSender = params.isDeliveredToSender;
                }
                if (params.isFailedPackages) {
                    this.isFailedPackages = params.isFailedPackages;
                }
                if (params.isCompleted) {
                    this.isCompleted = params.isCompleted;
                }
                if (params.first) {
                    this.first = params.first;
                }
                if (params.isCodChangeRequested) {
                    this.isFollowUpChangeCod = params.isCodChangeRequested;
                }
                const selectedShipment = [];
                if (params.selectedStatus) {
                    for (let i = 0; i < this.shipmentsCategories.length; i++) {
                        if (this.shipmentsCategories[i].value === params.selectedStatus) {
                            selectedShipment.push(this.shipmentsCategories[i]);
                            break;
                        }
                    }
                }

                if (selectedShipment.length) {
                    this.selectedCategories = selectedShipment;
                } else {
                    this.selectedCategories = [...this.shipmentsCategories];
                }
                if (loadShipments) {
                    setTimeout(() => {
                        this.initShipments(true);
                    }, 0);
                }
                this.translateService.onLangChange.subscribe(() => {
                    this.selectedLanguage = this.translateService.currentLang;
                    this.shipmentStatusList = this.setShipmentStatus();
                    this.initShipments(true);
                });
            }
        );
    }

    public isAllowedEditCost(shipment) {
        return ((this.permissions.includes('{EDIT_COST')) ||
                (this.permissions.includes('{CONDITIONAL_COST') && this.userService.userInfo.isNonAdminChangeCost))
            && shipment.status !== 'COMPLETED' && !this.archivedMode;
    }

    public notHidePanelsWhenResize() {
        // this.exportPanelOptions.onWindowResize = function () {};
        // this.shipmentMenu.onWindowResize = function () {};
        // this.changeCodPanel.onWindowResize = function () {};
        // this.resolveFailurePanel.onWindowResize = function () {};
        // this.messagesPanel.onWindowResize = function () {};
    }

    public onFilterChange() {
        this.paginator.changePage(0);
        this.currentPage = 0;
        this.pageSize = 10;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    private transformDateWithTime(date) {
        if (!isNaN(date.getTime())) {

            return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
        }
        return null;
    }

    public createQuery(isEdit = false, includePageAndPageSize = true) {
        const pageNumber = isEdit ? (Math.trunc(this.selectedRowIndex / 10) === 0 ? 1 : Math.trunc(this.selectedRowIndex / 10))
            : this.pageNumber + 1;
        let result = includePageAndPageSize ? `?pageSize=${this.pageSize}&page=${(pageNumber)}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}` : '?timezone='+Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.isBundlesFollowUps) {
            result += '&packageType=BUNDLE';
        } else {
            if (this.isLcl) {
                result += '&packageType=' + (this.isFromLclContainersManagement ? 'CONTAINER' : 'PACKAGE');
            }
        }

        if (this.archivedMode) {
            result += '&isArchived=true';
        }

        if (this.isDeleted) {
            result += '&isDeleted=true';
        }

        if (this.searchContent && this.searchContent !== '') {
            result += '&search=' + this.searchContent;
        }
        if (this.filterForm) {
            if (this.filterForm.get('fromDate').value) {
                result += '&fromDate=' + this.transformDate(this.filterForm.get('fromDate').value);
            }
            if (this.filterForm.get('toDate').value) {
                result += '&toDate=' + this.transformDate(this.filterForm.get('toDate').value);
            }
            const filterValue: any = this.filterForm.getRawValue();
            if (filterValue) {
                if (filterValue.selectedDateType) {
                    result += '&dateFilter=' + filterValue.selectedDateType;
                }
                if (filterValue.exportingHubPackagesFilter) {
                    result += '&exportingHubPackagesFilter=' + filterValue.exportingHubPackagesFilter.value;
                }
                if (filterValue.sortBy) {
                    result += '&sortBy=' + filterValue.sortBy.value;
                }
                if (filterValue.bundleType) {
                    result += '&bundleType=' + filterValue.bundleType.value;
                }
            }

            if (this.isFailed) {
                result += '&isFailed=' + this.isFailed;
            }

        }


        if (!this.isBundlesFollowUps) {
            if (this.isFollowUpType && this.isFollowUpType !== 'ALL') {
                result += '&status=' + this.isFollowUpType;
            } else if (this.selectedShipmentStatus && this.selectedShipmentStatus !== this.shipmentStatusAll) {
                result += '&status=' + this.selectedShipmentStatus;
            } else if (this.showPkgsPerReceiverCity && this.selectedShipmentStatus === 'ALL') {
                result += '&status=PACKAGES_BASED_ON_RECIPIENT_CITY';
            }
        } else if (this.customizationCompaniesService.checkCompanies(this.companies.SPRINT) && this.selectedShipmentStatus !== 'BUNDLES' && this.selectedShipmentStatus !== 'ALL') {
            let statusX = this.selectedShipmentStatus;
            // if (this.selectedShipmentStatus === 'CREATED') {
            //     statusX = 'PENDING_CUSTOMER_CARE_APPROVAL';
            // }
            // if (this.selectedShipmentStatus === 'DELIVERED') {
            //     statusX = 'COMPLETED';
            // }
            result += '&status=' + statusX;
        }

        if (this.selectedPickupType !== 'ALL') {
            result += '&pickupType=' + this.selectedPickupType;
        }

        if (this.selectedReturnedShipmentStatus && this.selectedReturnedShipmentStatus !== 'ALL') {
            result += '&returnedShipmentStatus=' + this.selectedReturnedShipmentStatus;
        }
        if (this.selectedCompletedShipmentStatus && this.selectedCompletedShipmentStatus !== 'ALL') {
            result += '&completedStatus=' + this.selectedCompletedShipmentStatus;
        }
        if (this.customerID) {
            result += '&customerId=' + this.customerID;
        }
        if (this.shippingLine) {
            result += '&originShippingLineId=' + this.shippingLine.id;
        }
        if (this.destinationShippingLine) {
            result += '&destinationShippingLineId=' + this.destinationShippingLine.id;
        }
        if (this.destinationRegion) {
            result += '&destinationRegionId=' + this.destinationRegion.id;
        }
        if (this.destinationCity) {
            result += '&destinationCityId=' + this.destinationCity.id;
        }
        if (this.transferCompany) {
            result += '&partnerId=' + this.transferCompany.id;
        }
        if (this.hubId) {
            result += '&hubId=' + this.hubId;
        }
        if (this.searchParameters.shelfId?.value) {
            result += '&shelfId=' + this.shelfId;
        }
        if (this.isDeliveredToSender) {
            result += '&isDeliveredToSender=' + this.isDeliveredToSender;
        }
        if (this.isFailedPackages) {
            result += '&isFailed=' + this.isFailedPackages;
        }
        if (this.isCompleted) {
            result += '&isCompleted=' + this.isCompleted;
        }
        if (this.isFollowUpChangeCod) {
            result += '&isCodChangeRequested=' + this.isFollowUpChangeCod;
        }
        if (this.partnerId) {
            result += '&partnerId=' + this.partnerId;
        }

        result += '&utcOffset=' + moment().utcOffset().toString();

        if (!!this.filterByPartnerId) {
            result += '&partnerId=' + this.filterByPartnerId;
        }
        if (this.userService.userInfo.isHideDeliveredMassCodReports !== undefined
            && this.userService.userInfo.isHideDeliveredMassCodReports) {
            result += '&isRemoveCompleted=' + this.userService.userInfo.isHideDeliveredMassCodReports;
        }
        if (this.isNotesAdded) {
            result += '&isNotesAdded=true';
        }
        if (this.selectedAttachmentsFilter !== 'ALL') {
            result += '&isOnlyAttachmentPackages=' + (this.selectedAttachmentsFilter === 'WITH');
        }
        if (this.selectedLocationFilter !== 'ALL') {
            result += '&isOnlyReceiverLocations=' + (this.selectedLocationFilter === 'WITH');
        }

        if (this.selectedDeliveryLocationFilter !== 'ALL') {
            result += '&isOnlyDeliveryLocations=' + (this.selectedDeliveryLocationFilter === 'WITH');
        }
        if (this.selectedSignatureFilter !== 'ALL') {
            result += '&isOnlyShowSignature=' + (this.selectedSignatureFilter === 'WITH');
        }

        if (this.searchParameters.cost?.value) {
            result += '&cost=' + this.searchParameters.cost.value;
        }
        if (this.selectedVehicleType) {
            result += '&vehicleTypeId=' + this.selectedVehicleType.id;
        }
        if (this.selectedReceiverCity && this.selectedReceiverCity.id) {
            result += '&destinationCityId=' + this.selectedReceiverCity.id;
        }
        return result;
    }

    public initSearchParams() {
        this.searchParameters = {
            barcode: {
                value: ''
            },
            receiverPhone: {
                value: ''
            },
            deliveryDate: {
                value: ''
            },
            expectedDeliveryDate: {
                from: '',
                to: ''
            },
            expectedPickupDate: {
                value: ''
            },
            invoiceNumber: {
                value: ''
            },
            postponedDeliveryDate: {
                value: ''
            },
            createdDate: {
                value: ''
            },
            firstPickupDate: {
                value: ''
            },
            notes: {
                value: ''
            },
            serviceType: {
                value: 'ALL'
            },
            sourceType: {
                value: 'ALL'
            },
            thirdPartyTrackingNo: {
                value: ''
            },
            shipmentType: {
                value: 'ALL'
            },
            customerType: {
                value: 'ALL'
            },
            cost: {
                value: ''
            },
            driverEarning: {
                value: ''
            },
            lastStatusDate: {
                value: ''
            },
            pickupDate: {
                value: ''
            },
            isCostEqualZero: {
                value: false
            },
            driverId: {
                value: 0
            },
            shelfId: {
                value: 0
            },
            deliveredBy: {
                value: 0
            },
            codDriverId: {
                value: 0
            },
            codHubId: {
                value: 0
            },
            fromHub: {
                value: 0
            },
            toHub: {
                value: 0
            },
            codShelfId: {
                value: 0
            },
            exportedHubId: {
                value: 0
            },
            serviceTypeId: {
                value: 0
            },
            description: {
                value: ''
            },
            isFailureResolved: {
                value: -1
            },
            fromWeight: {
                value: null
            },
            toWeight: {
                value: null
            },
            originRegionIds: {
                value: []
            },
            originCityIds: {
                value: []
            },
            originVillageIds: {
                value: []
            },
            destinationRegionIds: {
                value: []
            },
            destinationCityIds: {
                value: []
            },
            destinationVillageIds: {
                value: []
            }

        };
    }

    public prepareFiltersList() {
        const filtersList = [];
        const zeroMeaningKeys = ['cost'];
        const numberKeys = ['cost'];
        for (const [key, value] of Object.entries(this.searchParameters)) {
            if (value['from'] || value['to'] || value['value'] && value['value'].length || key === 'isFailureResolved' || (zeroMeaningKeys.indexOf(key) >= 0 && value['value'] === 0)) {
                if (key.includes('expectedDeliveryDate')) {
                    if (!isNaN(value['from'].getTime())) {
                        filtersList.push({fieldName: key + 'From', value: this.transformDateWithTime(value['from'])});
                    }
                    if (!isNaN(value['to'].getTime())) {
                        filtersList.push({fieldName: key + 'To', value: this.transformDateWithTime(value['to'])});
                    }
                } else if (key.includes('Date')) {
                    // @ts-ignore
                    filtersList.push({fieldName: key, ...value, value: this.transformDate(value['value'])});
                } else if (numberKeys.indexOf(key) >= 0) {
                    filtersList.push({fieldName: key, value: +value['value']});
                } else {
                    if (key === 'isFailureResolved' && value['value'] === -1) {

                    } else {
                        if (value['value'] !== 'ALL') {
                            // @ts-ignore
                            filtersList.push({fieldName: key, ...value});
                        }
                    }
                }
            }
        }
        return filtersList;
    }

    public resetAllFilters() {
        // searchParameters.serviceTypeId.value
        this.customerID = 0;
        this.selectedShipmentStatus = 'ALL';
        this.selectedFailedStatus = 'ALL';
        this.fromDate = '';
        this.toDate = '';
        this.selectedShipmentMethod = {label: this.translateService.instant('ALL'), value: 0};
        this.customerID = 0;
        this.searchContent = '';
        this.selectedMultiSelectCustomers = [];
        this.shippingLine = null;
        this.destinationShippingLine = null;
        this.destinationRegion = null;
        this.destinationCity = null;
        this.isNotesAdded = false;
        this.isOnlyAttachmentPackages = false;
        this.isOnlyReceiverLocations = false;
        this.selectedCustomers = [];
        this.selectedSenderShipmentLine = [];
        this.selectedReceiverShipmentLine = [];
        this.selectedMultiSelectCodStatus = [];
        this.hasMore = true;
        this.countResponse = false;
        this.onClearServiceTypeFilter(false);
        this.onClearVehicleTypeFilter(false);
        this.setAttachmentFilter('ALL', false);
        this.setLocationFilter('ALL', false);
        this.setDeliveryLocationFilter('ALL', false);
        this.setSignatureFilter('ALL', false);
        this.initSearchParams();
        this.initForm(true);
        this.onStatusChanged(false);
    }

    public onStatusChanged(resetFilters = false) {
        this.pageNumber = 0;
        this.selectedFailedStatus = 'ALL';
        this.selectedReturnedShipmentStatus = 'ALL';
        this.searchParameters.isFailureResolved.value = -1;
        this.hubId = 0;
        this.fromHub = 0;
        this.toHub = 0;
        this.selectedShipmentMethod.value = 0;
        this.searchParameters.driverId.value = 0;
        this.searchParameters.codHubId.value = 0;
        this.searchParameters.toHub.value = 0;
        this.searchParameters.fromHub.value = 0;
        this.searchParameters.exportedHubId.value = 0;
        this.isFailed = false;
        if (this.hubFilter && this.hubFilter.selectedUser) {
            this.hubFilter.selectedUser = null;
        }
        if (resetFilters) {
            this.resetAllFilters();
        }

        if (this.route.snapshot.queryParams['status'] === 'PENDING_CUSTOMER_CARE_APPROVAL') {
            const params = _.clone(this.route.snapshot.queryParams);
            delete params['status'];
            this.router.navigate([], {queryParams: params});
        }
        this.initShipments(true, false, true, resetFilters);
        this.returnedShipmentStatusList = this.setReturnedShipmentStatusList();

    }

    onFailedStatusChanged() {
        switch (this.selectedFailedStatus) {
            case 'ALL':
                this.searchParameters.isFailureResolved.value = -1;
                break;
            case 'RESOLVED_FAILURE':
                this.searchParameters.isFailureResolved.value = 1;
                break;
            case 'UNRESOLVED_FAILURE':
                this.searchParameters.isFailureResolved.value = 0;
                break;
        }
        this.initShipments(true, false, false);
    }

    private setBody() {
        const searchQueryList = this.prepareFiltersList();
        const selectedCustomers = this.selectedMultiSelectCustomers;
        const selectedCodStatus = this.selectedMultiSelectCodStatus;
        const body = {searchQueryList};
        if (this.selectedSenderShipmentLine.length) {
            body['originShippingLineIds'] = this.selectedSenderShipmentLine;
        } else {
            delete body['originShippingLineIds'];
        }
        if (this.selectedReceiverShipmentLine.length) {
            body['destinationShippingLineIds'] = this.selectedReceiverShipmentLine;
        } else {
            delete body['destinationShippingLineIds'];
        }
        if (selectedCustomers.length) {
            body['customerIds'] = selectedCustomers;
        } else {
            delete body['customerIds'];
        }
        if (this.selectedTrip?.value) {
            body['tripId'] = this.selectedTrip.value;
        } else {
            delete body['tripId'];
        }
        if (this.selectedShipmentMethod?.value) {
            body['orderId'] = this.selectedShipmentMethod.value;
        } else {
            delete body['orderId'];
        }
        if (this.selectedShipmentMethod?.value) {
            searchQueryList.push({fieldName: 'orderId', value: this.selectedShipmentMethod.value});
        }

        if (selectedCodStatus.length) {
            body['codStatus'] = selectedCodStatus;
        } else {
            delete body['codStatus'];
        }
        return body;
    }

    /**
     * Init categories language.
     */
    private initCategories() {
        // translate the categories label.
        this.shipmentsCategories.forEach(
            (value, index, array) => {
                this.subscriptions.push(this.translateService.stream(value.label).subscribe(
                    (label) => {
                        array[index].label = label;
                    }
                ));
            }
        );
    }

    private initForm(noDateRangeLabelValue = false) {
        // const date = new Date();
        // const date2 = new Date();
        // const fromDate: Date = new Date(date.setFullYear(date.getFullYear(), date.getMonth() - 1 , date.getDate()));
        // const toDate = new Date(date2.setDate(date2.getDate() + 1));
        this.filterForm = this.formsBuilder.group({
            searchText: [''],
            selectedShipmentsType: [{value: false}],
            selectedDateType: ['CREATED_DATE'],
            bundleType: [{value: ''}],
            sortBy: [{value: 'CREATED_DATE'}],
            exportingHubPackagesFilter: [{value: 'ALL_RELATED_TO_CURRENT_HUB'}],
            fromDate: [this.fromDate ? this.fromDate : moment().subtract(1, 'months')],
            toDate: [this.toDate ? this.toDate : moment()]
        });
        this.formInitiated = true;
        if (noDateRangeLabelValue) {
            // this.datepicker.nativeElement.value = this.translateService.instant('GENERAL.DATE_RANGE_PICKER.BEFORE_1_MONTH');
        }
    }

    public onDateSelected(value, dateRange) {
        // this.paginator.changePage(0);
        const startDate = new Date(value.start);
        const endDate = new Date(value.end);
        this.fromDate = startDate;
        this.toDate = endDate;
        this.filterForm.controls.fromDate.setValue(startDate);
        this.filterForm.controls.toDate.setValue(endDate);
        this.filterForm.controls.fromDate.updateValueAndValidity();
        this.filterForm.controls.toDate.updateValueAndValidity();
        this.excludeDatesFilter = false;
        this.initShipments(true);
        this.previousLabel = value.label;
        const {start, end} = value;
        if ((start && end) || value.label) {
            if (value.label) {
                if (value.label === this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }
    }

    public onShipmentsTypeChanged() {
        this.initShipments(true);
    }

    public goToBundle(packageId) {
        this.navigateTo('change-status', {isBundle: true, bundleId: packageId});
    }

    public trackShipment(shipment = null) {
        if (shipment.isBundle) {
            this.goToBundle(shipment.id);
            // this.createLCLContainer(true, shipment);
        } else {
            if (shipment) {
                this.triggeredDropdownShipment = shipment;
            }
            this.isTrackingPackage = true;
        }
    }

    public closeTracker($event) {
        this.isTrackingPackage = $event;
    }

    public isActiveSenderOrReceiverFilter(isSender) {
        if (isSender) {
            return this.searchParameters['senderName'] ? this.searchParameters['senderName'].value :
                this.searchParameters['originRegionIds'].value ? this.searchParameters['originRegionIds'].value.length :
                    this.searchParameters['originCityIds'].value ? this.searchParameters['originCityIds'].value.length :
                        this.searchParameters['originVillageIds'].value ? this.searchParameters['originVillageIds'].value.length : false;

        } else { // receiver
            return this.searchParameters['receiverName'] ? this.searchParameters['receiverName'].value :
                this.searchParameters['destinationRegionIds'] ? this.searchParameters['destinationRegionIds'].value.length :
                    this.searchParameters['destinationCityIds'] ? this.searchParameters['destinationCityIds'].value.length :
                        this.searchParameters['destinationVillageIds'] ? this.searchParameters['destinationVillageIds'].value.length : false;

        }
    }

    public isActiveWeighFilter() {
        return this.searchParameters['fromWeight'] ? this.searchParameters['fromWeight'].value : false
        || this.searchParameters['toWeight'] ? this.searchParameters['toWeight'].value : false;
    }

    public isActiveVolumetricWeighFilter() {
        return this.searchParameters['fromVolumetricWeight'] ? this.searchParameters['fromVolumetricWeight'].value : false
        || this.searchParameters['toVolumetricWeight'] ? this.searchParameters['toVolumetricWeight'].value : false;
    }

    public openSelectCustomerRegionDialog(index) {
        const modal = this.modalService.open(CustomerRegionFilterComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.isSender = !!index;
        if (index) {
            modal.componentInstance.customerName = this.searchParameters['senderName'] ? this.searchParameters['senderName'].value : '';
            modal.componentInstance.originRegion = this.searchParameters['originRegionIds'].value;
            modal.componentInstance.originCity = this.searchParameters['originCityIds'].value;
            modal.componentInstance.originVillage = this.searchParameters['originVillageIds'].value;
        } else {
            modal.componentInstance.customerName = this.searchParameters['receiverName'] ? this.searchParameters['receiverName'].value : '';
            modal.componentInstance.destinationRegion = this.searchParameters['destinationRegionIds'].value;
            modal.componentInstance.destinationCity = this.searchParameters['destinationCityIds'].value;
            modal.componentInstance.destinationVillage = this.searchParameters['destinationVillageIds'].value;
        }
        modal.result.then(
            (status: { isSuccess: boolean, customer?: number, regionId?: number, cityId?: number, villageId?: number }) => {
                if (index) {// sender
                    if (status.customer) {
                        this.searchParameters['senderName'] = {'value': status.customer};
                    } else {
                        delete this.searchParameters['senderName'];
                    }

                    if (status.regionId) {
                        this.searchParameters['originRegionId'] = {value: status.regionId};
                    } else {
                        delete this.searchParameters['originRegionId'];
                    }

                    if (status.cityId) {
                        this.searchParameters['originCityId'] = {value: status.cityId};
                    } else {
                        delete this.searchParameters['originCityId'];
                    }

                    if (status.villageId) {
                        this.searchParameters['originVillageId'] = {value: status.villageId};
                    } else {
                        delete this.searchParameters['originVillageId'];
                    }
                } else {// receiver
                    if (status.customer) {
                        this.searchParameters['receiverName'] = {'value': status.customer};
                    } else {
                        delete this.searchParameters['receiverName'];
                    }

                    if (status.regionId) {
                        this.searchParameters['destinationRegionIds'] = {value: status.regionId};
                    } else {
                        delete this.searchParameters['destinationRegionIds'];
                    }

                    if (status.cityId) {
                        this.searchParameters['destinationCityIds'] = {value: status.cityId};
                    } else {
                        delete this.searchParameters['destinationCityIds'];
                    }

                    if (status.villageId) {
                        this.searchParameters['destinationVillageIds'] = {value: status.villageId};
                    } else {
                        delete this.searchParameters['destinationVillageIds'];
                    }
                }
                this.onSearch();
            }
        ).catch(
            () => {
            }
        );
    }

    public resetField(name, refreshData = true) {
        if (name === 'fromDate') {
            this.fromDate = '';
        } else {
            if (name === 'toDate') {
                this.toDate = '';
            } else {
                this.searchParameters[name].value = '';
            }
        }
        this.setQueryParams();
        if (refreshData) {
            this.initShipments(true);
        }
    }

    private setQueryParams() {
        const queryParams = {...this.params};
        if (this.fromDate) {
            queryParams['fromDate'] = this.fromDate.toISOString();
        } else {
            delete queryParams['fromDate'];
        }
        if (this.toDate) {
            queryParams['toDate'] = this.toDate.toISOString();
        } else {
            delete queryParams['toDate'];
        }

        if (this.selectedShipmentStatus !== queryParams['status']) {
            // reset filters on change status
            this.resetFilters(queryParams);
        } else {
            if (this.shippingLine) {
                queryParams['shippingLine'] = this.shippingLine.id;
            } else {
                delete queryParams['shippingLine'];
            }
            if (this.destinationShippingLine) {
                queryParams['destinationShippingLine'] = this.destinationShippingLine.id;
            } else {
                delete queryParams['destinationShippingLine'];
            }
            if (this.destinationRegion) {
                queryParams['destinationRegion'] = this.destinationRegion.id;
            } else {
                delete queryParams['destinationRegion'];
            }
            if (this.destinationCity) {
                queryParams['destinationCity'] = this.destinationCity.id;
            } else {
                delete queryParams['destinationCity'];
            }
            if (this.customerID) {
                queryParams['customerId'] = this.customerID;
            } else {
                delete queryParams['customerId'];
            }
            if (this.transferCompany) {
                queryParams['transferCompany'] = this.transferCompany.id;
            } else {
                delete queryParams['transferCompany'];
            }
            if (this.driverID) {
                queryParams['driverId'] = this.driverID;
            } else {
                delete queryParams['driverId'];
            }
            if (this.hubId) {
                queryParams['hubId'] = this.hubId;
            } else {
                delete queryParams['hubId'];
            }
            if (this.isDeliveredToSender) {
                queryParams['isDeliveredToSender'] = this.isDeliveredToSender;
            } else {
                delete queryParams['isDeliveredToSender'];
            }
            if (this.isFailedPackages) {
                queryParams['isFailedPackages'] = this.isFailedPackages;
            } else {
                delete queryParams['isFailedPackages'];
            }
            if (this.isCompleted) {
                queryParams['isCompleted'] = this.isCompleted;
            } else {
                delete queryParams['isCompleted'];
            }
            if (this.first) {
                queryParams['first'] = this.first;
            } else {
                delete queryParams['first'];
            }
        }
        if (this.selectedShipmentStatus) {
            queryParams['status'] = this.selectedShipmentStatus;
        }
        if (this.isFollowUpChangeCod) {
            queryParams['isCodChangeRequested'] = this.isFollowUpChangeCod;
        } else {
            delete queryParams['isCodChangeRequested'];
        }
        if (this.searchContent) {
            queryParams['search'] = this.searchContent;
        } else {
            delete queryParams['search'];
        }
        this.router.navigate([], {queryParams: queryParams}).then(() => {
            if (this.shippingLine !== undefined && this.shippingLine && this.shipmentLines.length) {
                this.shippingLine = this.shipmentLines.find(element => element.id.toString() === this.shippingLine.id);
            }
            if (this.destinationShippingLine !== undefined && this.destinationShippingLine && this.DestinationShipmentLines.length) {
                this.destinationShippingLine = this.DestinationShipmentLines.find(
                    element => element.id.toString() === this.destinationShippingLine.id);
            }
            if (this.transferCompany !== undefined && this.transferCompany && this.transferCompanies.length) {
                this.transferCompany = this.transferCompanies.find(element => element.id.toString() === this.transferCompany.id);
            }
            if (this.destinationRegion !== undefined && this.destinationRegion && this.destinationRegions.length) {
                this.destinationRegion = this.destinationRegions.find(element => element.id.toString() === this.destinationRegion.id);
            }
            if (this.destinationCity !== undefined && this.destinationCity && this.destinationCities.length) {
                this.destinationCity = this.destinationCities.find(element => element.id.toString() === this.destinationCity.id);
            }
        });
    }

    public resetFilters(queryParams) {
        if (queryParams['shippingLine']) {
            delete queryParams['shippingLine'];
        }
        if (queryParams['destinationShippingLine']) {
            delete queryParams['destinationShippingLine'];
        }
        if (queryParams['destinationRegion']) {
            delete queryParams['destinationRegion'];
        }
        if (queryParams['destinationCity']) {
            delete queryParams['destinationCity'];
        }
        if (queryParams['transferCompany']) {
            delete queryParams['transferCompany'];
        }
        if (queryParams['driverId']) {
            delete queryParams['driverId'];
        }
        if (queryParams['deliveredBy']) {
            delete queryParams['deliveredBy'];
        }
        if (queryParams['hubId']) {
            delete queryParams['hubId'];
        }
        if (queryParams['isDeliveredToSender']) {
            delete queryParams['isDeliveredToSender'];
        }
        if (queryParams['isFailedPackages']) {
            delete queryParams['isFailedPackages'];
        }
        if (queryParams['isCompleted']) {
            delete queryParams['isCompleted'];
        }
        if (queryParams['search']) {
            delete queryParams['search'];
        }

    }

    public onHidePackageDetails(event) {
        this.showShipmentDetails = event;
        this.shipmentBarcode = null;
    }

    public onPageChange($event) {
        this.pageNumber = $event.page;
        this.pageSize = $event.rows;
        this.initShipments();
    }

    public clearSearch() {
        this.mobileSearchText = '';
        this.filterForm.patchValue({
            searchText: ''
        });
    }

    public removePackage(ids = null) {
        // *appPermission="permissions.includes('{REMOVE_PACKAGE') && !partnerId"
        const pkg = this.triggeredDropdownShipment;
        let message = '';
        this.translateService.get(
            ['ALERTS.DELETE_PACKAGE', 'GENERAL.YES', 'GENERAL.NO', 'ALERTS.DELETE_PACKAGES']
        )
            .subscribe(
                (data) => {
                    message = ids ? data['ALERTS.DELETE_PACKAGES'] : data['ALERTS.DELETE_PACKAGE'];
                    this.confirmService.confirm({
                        message: message,
                        accept: () => {
                            this.shipmentService.removePackage(ids ? ids : [pkg.id]).subscribe(
                                () => {
                                    this.initShipments(true);
                                    this.translateService.get(
                                        'ALERTS.SHIPMENT_REMOVED'
                                    )
                                        .subscribe(
                                            (translatedMessage) => {
                                                this.messageService.add({
                                                    severity: 'success',
                                                    detail: this.transformToDistributor(translatedMessage)
                                                });
                                            }
                                        );
                                }, (error) => {
                                    console.error(error);
                                }, () => {
                                }
                            );
                        },
                        acceptLabel: data['GENERAL.YES'],
                        rejectLabel: data['GENERAL.NO'],
                    });
                }
            );
    }

    public onPostponePackage(isAddNote = false, isMulti = false) {
        const pkg = this.triggeredDropdownShipment;
        const modal = this.modalService.open(PostponePackageComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.shipment = pkg;
        if (isAddNote) {
            modal.componentInstance.notesOnly = true;
            modal.componentInstance.title = 'SHIPMENT.NOTES_PACKAGE';
        }

        if (isMulti && this.isThereSelection) {
            modal.componentInstance.selectedPackagesIds = this.getSelectedPackages().map(pkg => pkg.id);
        }
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    let message = 'ALERTS.CHANGED_STATUS';
                    if (isAddNote) {
                        message = 'ALERTS.NOTE_ADDED_SUCCESSFULLY';
                    }
                    this.translateService.get(message).subscribe(
                        (translatedMessage) => {
                            this.messageService.add({severity: 'success', detail: translatedMessage});
                        }
                    );
                    this.initShipments(true);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public copyMessage($event, list?, barcode?) {
        $event.stopPropagation();
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    public onFilter() {
        this.paginator.changePage(0);
    }


    public onToggleSelectAll() {
        if (this.totalNumberOfPackages === 0) {
            return;
        }

        let selected = false;
        if (this.isAllSelected) {
            selected = true;
            this.isThereSelection = true;
            this.numberOfSelectedPackages = this.shipmentsData.length;
        } else {
            this.isThereSelection = false;
            this.numberOfSelectedPackages = 0;
        }
        this.shipmentsData.forEach((pkg: any) => {
            pkg.isSelected = selected;
        });
    }

    public selectAll() {
        if (this.totalNumberOfPackages === 0) {
            return;
        }

        this.isAllSelected = true;
        this.isThereSelection = true;
        this.numberOfSelectedPackages = this.shipmentsData.length;
        this.shipmentsData.forEach((pkg: any) => {
            pkg.isSelected = true;
        });
    }

    public onToggleSelection($event = null) {
        if (event) {
            event.stopPropagation();
        }

        const selectedPackages = this.getSelectedPackages();

        this.isThereSelection = selectedPackages.length !== 0;
        this.isAllSelected = selectedPackages.length === this.shipmentsData.length && this.shipmentsData.length > 0;
        this.numberOfSelectedPackages = selectedPackages.length;
    }

    getSelectedPackages() {
        const selectedPackages = [];
        if (!this.shipmentsData) {
            return [];
        }

        this.shipmentsData.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }

    public showMessages(shipment) {
        this.spinnerState = this.spinnerStates.LOADING;
        const modalRef = this.modalService.open(FaliureHistoryComponent, <any>{
            backdrop: 'static',
            size: 'md',
            windowClass: 'packages-history-modal'
        });
        modalRef.componentInstance.shipmentId = shipment.id;
        modalRef.result.then(
            () => {
                this.spinnerState = this.spinnerStates.EMPTY;
            }, () => {
                this.spinnerState = this.spinnerStates.EMPTY;
            }
        );
    }

    public fetchFailuresHistory() {
        this.isLoadingHistory = true;
        this.shipmentService.getFaliuresHistory(this.selectedShipment.id).subscribe((data: any) => {
            this.failuresHistory = data;
            this.isLoadingHistory = false;
        }, () => {
            this.isLoadingHistory = false;
        });
    }


    public editPackage(cloning = false) {
        // const pkg = this.triggeredDropdownShipment;
        // this.openQuickAdd(pkg);
        this.isClonePackage = cloning;
        const pkg = this.triggeredDropdownShipment;
        this.selectedPackageBarcode = pkg.barcode;
        this.displayAddPackage = true;
        this.shipmentOptions.hide();
    }

    public onHideAddPackage(event) {
        this.isClonePackage = false;
        this.displayAddPackage = event;
        this.selectedPackageBarcode = null;
    }

//  New Design

    public onShippingLineChanged() {
        this.initShipments(true);
    }

    public onSelectedCategoriesPanelShown() {
        if (this.shipmentMenu) {
            this.shipmentMenu.hide();
        }
    }

    public downloadCSV() {
        const selectedPackagesId = this.getSelectedPackages().map(selected => selected.id);
        let url = '';
        const tableFilters = this.setBody();
        tableFilters ['ids'] = selectedPackagesId;
        const params = this.createQuery(false, false);
        const modal = this.modalService.open(ExportComponent, <any>{size: 'lg', windowClass: 'bulk-update-popover'});
        modal.componentInstance.showCSV = true;
        modal.result.then((data: { type: any }) => {
            if (data.type) {
                if (data.type === 'CSV') {
                    if (this.driverID) {
                        url = SHIPMENT_CONSTANTS.DOWNLOAD_CSV_DRIVER.replace('{driverId}', this.driverID.toString());
                    } else {
                        if (this.partnerId) {
                            url = PARTNER_CONSTANTS.EXPORT_PARTNERS_CSV;
                        } else {
                            url = SHIPMENT_CONSTANTS.DOWNLOAD_CSV;
                        }
                    }
                    this.isLoading = true;
                    this.shipmentService.downloadCSV(url, params, tableFilters).subscribe((res: any) => {
                            window.open(res.url, '_self');
                            this.isLoading = false;
                        },
                        error => {
                            console.error(error);
                            this.isLoading = false;
                        });
                } else {
                    if (data.type === 'EXCEL') {
                        if (this.driverID) {
                            url = SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_DRIVER.replace('{driverId}', this.driverID.toString());
                        } else {
                            if (this.partnerId) {
                                url = PARTNER_CONSTANTS.EXPORT_PARTNERS_EXCEL;
                            } else {
                                url = SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL;
                            }
                        }
                    }
                    this.isLoading = true;
                    this.shipmentService.downloadExcel(url, params, tableFilters).subscribe((res: any) => {
                            window.open(res.url, '_blank');
                            this.isLoading = false;
                        },
                        error => {
                            this.isLoading = false;
                            console.error(error);
                        });
                }
            }
        });
        this.exportPanelOptions.hide();
    }

    public uploadExcel($event: any, invoiceSupplier = false, download = false, validate = false) {
        // this.isLoading = true;
        if (this.exportPanelOptions) {
            this.exportPanelOptions.hide();
        }
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        formData.append('file', selectedFile);
        $event.target.value = '';
        if (!selectedFile || this.isUploadingExcel) {
            this.isLoading = false;
            return;
        }
        this.isUploadingExcel = true;
        if (selectedFile.name.toLowerCase().match(/.(xlsx)$/i)) {
            if (validate) {
                this.importExcelWithValidation(selectedFile);
            } else {
                this.importExcel(formData, invoiceSupplier, download);
            }
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.isUploadingExcel = false;
        }
    }

    public importExcel(formData, invoiceSupplier, download) {
        let successMessage = '';
        let errorMessage = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.FILE_UPLOADED_SUCCESSFULLY', 'ALERTS.FAILED_TO_UPLOAD_FILE', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    successMessage = data['ALERTS.FILE_UPLOADED_SUCCESSFULLY'];
                    errorMessage = data['ALERTS.FAILED_TO_UPLOAD_FILE'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        // this.isFileSelected = true;
        this.shipmentsService.uploadExcel(formData, invoiceSupplier, 'manage-shipment').subscribe((data: any) => {
            if (!data.error) {
                this.messageService.add({severity: 'success', detail: successMessage});
                this.isLoading = false;
                // this.closeModal();
            } else {
                if (download) {
                    this.messageService.add({severity: 'error', detail: errorMessage});
                    this.isLoading = false;
                    this.download('errors.txt', data.error);
                    this.isLoading = false;
                } else {
                    this.confirmService.confirm({
                        message: (data.error),
                        rejectVisible: false,
                        accept: () => {
                        },
                        acceptLabel: 'OK'
                    });
                    this.isLoading = false;
                }
            }
            this.isUploadingExcel = false;
        });
    }

    public importExcelWithValidation(excelFile) {
        this.displayExcelValidation = true;
        this.shipmentExcelService.importShipments(excelFile)
            .pipe(first())
            .subscribe(
                (shipments) => {
                    this.enableToast = false;
                    this.validationDialog.loadDialog(shipments);
                    this.isUploadingExcel = false;
                }, (error) => {
                    this.displayExcelValidation = false;
                    console.error(error);
                    this.translateService.get(error)
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'error', detail: data});
                            }
                        );
                    this.isUploadingExcel = false;
                }
            );
    }

    public onCloseExcelValidationDialog() {
        this.enableToast = true;
        this.displayExcelValidation = false;
        this.isBundleImport = false;
    }

    public downloadExcelTemplate(summarizedTemplate = false) {
        this.isDownloadingExcelTemplate = true;
        this.shipmentsService.downloadExcelTemplate('manage-shipment', summarizedTemplate).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isDownloadingExcelTemplate = false;
                this.exportPanelOptions.hide();
            },
            error => {
                console.error(error);
                this.isDownloadingExcelTemplate = false;
            });
    }

    public uploadFile(fileChooser) {
        this.isBundleImport = false;
        if (this.exportPanelOptions) {
            this.exportPanelOptions.hide();
        }
        fileChooser.click();
    }

    public uploadFileForBundles(fileChooser) {
        this.isBundleImport = true;
        if (this.exportPanelOptions) {
            this.exportPanelOptions.hide();
        }
        fileChooser.click();
    }

    public triggerImportExportMenu($event, dropdown) {
        dropdown.toggle($event);
    }

    public triggerShipmentDropdown($event, dropdown, shipment, rowIndex) {
        this.selectedRowIndex = rowIndex;
        this.triggeredDropdownShipment = shipment;
        dropdown.toggle($event);
    }

    public showShipmentMenu($event, shipment) {
        $event.stopPropagation();
        this.selectedCategoriesDropDown.close($event);
        this.selectedShipment = shipment;
        this.shipmentMenu.toggle($event);
    }

    public showEditCod($event) {
        this.shipmentMenu.hide();
        this.newCodValue = this.selectedShipment.cod;
        this.showSuccessChangeCodMsg = false;
        this.changeCodPanel.toggle($event);
    }

    // Exist actions
    public receivePackagesFromDriverOrPartner(isFromPartner = false) {
        this.closeMoreActionsPopOver();
        let className = 'receive-driver-and-read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'receive-driver-and-read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        if (isFromPartner) {
            modal.componentInstance.isReceiveFromPartner = true;
        } else {
            modal.componentInstance.isReceiverFromDriver = true;
        }
        modal.result.then(
            (status: { isSuccess: boolean, needRefreshPackages }) => {
                if (status.needRefreshPackages) {
                    this.initShipments(true);
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public readByBarcode() {
        let className = 'read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isReadByBarcode = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number; needRefreshPackages }) => {
                if (status.needRefreshPackages) {
                    this.initShipments(true);
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public updateReceiverInfo() {
        this.closeMoreActionsPopOver();
        let className = 'read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isUpdateReceiverInfo = true;
        modal.componentInstance.isReadByBarcode = true;
        modal.result.then(
            (status: { isSuccess: boolean, needRefreshPackages }) => {
                if (status.needRefreshPackages) {
                    this.initShipments(true);
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public receivePackagesFromDriver2() {
        // APP_PERMISSION => this.permissions.includes('{IMPORT_PACKAGES') && !this.partnerId
        const modal = this.modalService.open(ChooseHubAndDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public onChangePackageStatus(pkg = null) {
        const modal = this.modalService.open(ChangePackageStatusComponent,
            <any>{size: 'md', windowClass: 'package-status-popover', backdrop: 'static'});
        modal.componentInstance.isModal = true;
        if (pkg) {
            modal.componentInstance.shipment = pkg;
        } else {
            modal.componentInstance.shipments = this.getSelectedPackages();
        }
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.initShipments(false, true);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }


    public downloadMultiplePackages(printType = null) {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);

        this.shipmentService.downloadMultiple(selectedPackages, printType, {isDeleted: this.isDeleted}).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }
    public printInvoices() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentService.downloadInvoices(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public printRunSheet(isWithSignature = false) {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentService.downloadMultipleChangeStatus(selectedPackages, null, isWithSignature, {isDeleted: this.isDeleted}).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    printPackage(pkg) {
        if (pkg.printLoader) {
            return;
        }
        pkg.printLoader = true;
        this.shipmentsService.downloadMultiple([pkg.id], 'REPORT').subscribe((res: any) => {
            window.open(res.url, '_blank');
            pkg.printLoader = false;
        }, (error) => {
            console.error(error);
            pkg.printLoader = false;
        });
    }

    public printPackagesBasedType(data, printPackagesOptions) {
        printPackagesOptions.hide();
        if (data) {
            if (data.printType === 'INVOICES') {
                this.printInvoices();
            } else if (data.printType === 'RUNSHEET') {
                this.printRunSheet();
            } else if (data.printType === 'RUNSHEET_WITH_SIGNATURE') {
                this.printRunSheet(true);
            } else {
                this.downloadMultiplePackages(data.printType);
            }
        }
    }

    public printDriverPackages() {
        this.closeMoreActionsPopOver();
        this.isLoading = true;
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.result.then(
            (status: {
                isSuccess: boolean, driverId?: number, fromDate?: string, toDate?: string, withoutSenderPhone?: boolean,
                withoutSenderName?: boolean, withoutReturnedPackages?: boolean, printType?: string, sortBy?: string,
                withPickupDate?: boolean, withSupplierInvoice?: boolean, withReceiverName?: boolean, withPartnerName?: boolean
            }) => {
                if (status.isSuccess) {
                    this.usersService.downloadDriverPackagesPdf(status.driverId, status.fromDate, status.toDate, status.withoutSenderName,
                        status.withoutSenderPhone, status.withoutReturnedPackages,
                        status.sortBy, (status.printType && status.printType === 'VERTICAL_TEMPLATE'), status.withPickupDate, status.withSupplierInvoice,
                        status.withReceiverName, status.withPartnerName)
                        .subscribe((res: any) => {
                                window.open(res.url, '_blank');
                                this.isLoading = false;
                            },
                            error => {
                                console.error(error);
                                this.isLoading = false;
                            });
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public bulkUpdate() {// Import
        // app permission => this.permissions.includes('{BULK_UPDATE') && !this.partnerId
        this.closeMoreActionsPopOver();
        const modal = this.modalService.open(BulkUpdateComponent, <any>{size: 'lg', windowClass: 'bulk-update-popover'});
        modal.componentInstance.titleLabel = 'GENERAL.CHANGE_BULK_BARCODES';
        modal.componentInstance.finishReceiveLabel = 'GENERAL.CHANGE_STATUS';
        modal.result.then(
            (data) => {
                if (data.isSuccess) {
                    this.onSearch();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    exportPackagesTo() {
        this.closeMoreActionsPopOver();
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        const modal = this.modalService.open(ExportShipmentsComponent, {backdrop: 'static', windowClass: 'export-packages-to', size: 'lg'});
        modal.componentInstance.isAuthorizedExportToHub = this.permissions.includes('EXPORT_PACKAGE_TO_HUB');
        modal.result.then(
            (result: { id?: number, type?: string }) => {
                if (result && result.id) {
                    this.isLoading = true;
                    let exportPackages = this.shipmentService.transferPackageByIds(result.id, selectedPackages); // export to partner api
                    if (result.type === 'HUB') { // call transfer to hub api
                        exportPackages = this.shipmentService.exportPackagesToHub(result.id, selectedPackages);
                    }
                    exportPackages.subscribe((res: any) => {
                        if (res && res.length && result.type === 'HUB') {
                            const modalDialog = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modalDialog.componentInstance.results = res;
                            modalDialog.componentInstance.label = 'ACCOUNTING.EXPORT_TO_HUB';
                        } else {
                            const toastMessage = result.type === 'PARTNER' ? 'ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY' : 'ALERTS.PACKAGES_TRANSFERRED_TO_HUB_SUCCESSFULLY';
                            this.messageService.add({
                                severity: 'success',
                                detail: this.transformToDistributor(this.translateService.instant(toastMessage))
                            });
                            this.initShipments(false, true);
                        }

                        this.isLoading = false;
                    }, (err) => {
                        this.download('errors.txt', err.error.error);
                        this.isLoading = false;
                    });
                }
            }
        );
    }

    public cancelPackages() {
        let confirmMessage = '';
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.translateService.get(
            ['ALERTS.CONFIRM_CANCEL']
        )
            .subscribe(
                (data) => {
                    confirmMessage = data['ALERTS.CONFIRM_CANCEL'];
                    this.confirmService.confirm({
                        message: confirmMessage,
                        rejectVisible: false,
                        accept: () => {
                            this.shipmentsService.cancelPackageByIds(this.partnerId, selectedPackages).subscribe(() => {
                                this.translateService.get('ALERTS.PACKAGES_CANCELLED_SUCCESSFULLY').subscribe((translatedMessage) => {
                                    this.messageService.add({severity: 'success', detail: this.transformToDistributor(translatedMessage)});
                                });
                                this.isLoading = false;
                                this.initShipments(true, false, true);
                            }, (err) => {
                                this.download('errors.txt', err.error.error);
                                // this.initShipments();
                                this.isLoading = false;
                            });
                        },
                        reject: () => {
                            this.isLoading = false;
                        },
                        acceptLabel: 'ok'
                    });
                }
            );
        // Call transfer API
    }


    public detectDriverTrack() {
        let className = 'container-carried-packages';
        if (this.selectedLanguage === 'en') {
            className = 'container-carried-packages-en';
        }
        const modal = this.modalService.open(CarriedPackagesComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        // modal.componentInstance.isReceiverFromDriver = true;
        modal.componentInstance.isDetectDriverTrack = true;
        this.enableToast = false;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
                this.enableToast = true;
            }
        ).catch(
            () => {
                this.enableToast = true;
            }
        );
    }

    public goToCancel() {
        this.router.navigate([this.authenticationService.companyName + 'home/' + 'change-status'], {
            queryParams: {
                is_cancel: true,
                partner_id: this.partnerId
            }
        });
    }

    public approvePackages() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentService.approvePackages(selectedPackages).subscribe(
            () => {
                this.translateService.get(
                    'ALERTS.PACKAGES_APPROVED'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: this.transformToDistributor(data)});
                        }
                    );
                this.isLoading = false;
                this.initShipments(false, true);
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public transformToDistributor(result) {
        if (this.userService.userInfo.isDistributor && result) {
            result = result.replace(/(Shipment|Package)/, 'Order');
            result = result.replace(/(shipment|package)/, 'order');
            result = result.replace('طرد', 'طلبية');
            result = result.replace('شحنة', 'طلبية');
            result = result.replace('الطرد', 'الطلبية');
            result = result.replace('طرود', 'طلبيات');
            result = result.replace('شحنات', 'طلبيات');
        }
        return result;
    }

    private assignPackageTo(assignTo: 'driver' | 'container') {
        const selectedPackages = this.getSelectedPackages();
        if (selectedPackages.length > 0) {
            const modal = this.modalService.open(AssignPackageComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.assignTo = assignTo;
            modal.componentInstance.ids = selectedPackages.map(pkg => pkg.id);
            modal.result.then(
                (result: { isSuccess: boolean }) => {
                    if (result.isSuccess) {
                        this.translateService.get(
                            'ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: this.transformToDistributor(data)});
                                }
                            );
                        this.initShipments(false, true);

                    }
                }
            ).catch(
                () => {
                }
            );
        }
    }

    public assignToDriver() {
        this.closeMoreActionsPopOver();
        this.assignPackageTo('driver');
    }

    importShipmentCosts() {
        this.fileChooserShipmentCosts.nativeElement.click();
    }

    public editCostFromExcel(event, download = false) {
        this.isLoading = true;
        const formData = new FormData();
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            this.isLoading = false;
            return;
        }

        formData.append('file', selectedFile);
        event.target.value = '';
        if (selectedFile.name.toLowerCase().match(/.(xlsx)$/i)) {
            this.shipmentService.uploadExcelShipmentCosts(formData).subscribe(() => {
                this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.FILE_UPLOADED_SUCCESSFULLY')});
                this.isLoading = false;
            }, (err) => {
                if (download) {
                    this.download('errors.txt', err.error.error);
                    this.isLoading = false;
                } else {
                    this.confirmService.confirm({
                        message: (err.error.error),
                        accept: () => {
                        },
                        acceptLabel: this.translateService.instant('GENERAL.YES')
                    });
                    this.isLoading = false;
                }
            });
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.isLoading = false;
        }
    }

    public uploadExceltoImport($event: any, invoiceSupplier = false, download = false) {
        this.isLoading = true;
        let successMessage = '';
        let errorMessage = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.FILE_UPLOADED_SUCCESSFULLY', 'ALERTS.FAILED_TO_UPLOAD_FILE', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    successMessage = data['ALERTS.FILE_UPLOADED_SUCCESSFULLY'];
                    errorMessage = data['ALERTS.FAILED_TO_UPLOAD_FILE'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        formData.append('file', selectedFile);
        $event.target.value = '';
        if (!selectedFile) {
            this.isLoading = false;
            return;
        }
        if (selectedFile.name.toLowerCase().match(/.(xlsx)$/i)) {
            this.shipmentService.uploadExcel(formData, invoiceSupplier, 'manage-users').subscribe(() => {
                this.messageService.add({severity: 'success', detail: successMessage});
                this.isLoading = false;
            }, (err) => {
                if (download) {
                    this.download('errors.txt', err.error.error);
                    this.isLoading = false;
                } else {
                    this.confirmService.confirm({
                        message: (err.error.error),
                        rejectVisible: false,
                        accept: () => {
                        },
                        acceptLabel: 'OK'
                    });
                    this.isLoading = false;
                }
            });
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.isLoading = false;
        }

    }

    importTwoColumnExcel() {
        this.fileChooserShipments.nativeElement.click();
    }

    public tableCustomization(type) {
        this.shipmentService.manageShipmentTableCustomization(type).subscribe(
            () => {
                this.translateService.get(
                    'UPDATED'
                ).subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.getColumnCount();
                    }
                );
            }, error => {
                console.error(error);
            }
        );
    }

    deleteSelectedPackages() {
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.removePackage(selectedPackages);
    }

    transferToOto() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_OTO_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToOto(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                        this.isLoading = false;
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    public setPaginatorQueryParam(event) {
        this.first = event.first;
        this.setQueryParams();
    }

    public getCustomerObject(customer) {
        return {
            name: customer.firstName + ' ' + customer.lastName + (customer.businessName ? ` (${customer.businessName})` : ''),
            id: customer.id,
        };
    }

    getCustomers(search?: string) {
        this.setCustomerDropDownSettings();
        const query = (search) ? '?page=1&pageSize=' + this.pageSize + '&search=' + search : '?page=1&pageSize=' + this.pageSize;
        this.usersService.getCustomersDropDown(query).subscribe(
            (response: any) => {
                if (response && response.length) {
                    const newList = response.map(customer => {
                        return this.getCustomerObject(customer);
                    });
                    this.customersList = this.selectedMultiSelectCustomers.concat(newList.filter((item) => this.selectedMultiSelectCustomers.map(a => a.id).indexOf(item.id) < 0));
                }
            }, (error) => {
                console.error(error);
            }
        );

    }

    public onClickCodStatusMultiSelect() {
        this.setCodStatusDropDownSettings();
        this.codStatusList = this.setShipmentCodStatus();
    }

    public filterCities(search) {
        this.getCustomers(search);
    }

    resetCustomersFilter = () => {
        this.selectedMultiSelectCustomers = [];
        this.initShipments(true);
    }

    public onSelectCustomers(selectAll = false, isCodStatus = false) {
        if (selectAll) {
            setTimeout(() => {
                this.initShipments(true);
            }, 1000);
        } else {
            if (isCodStatus) {
                this.onStatusCodChanged();
            } else {
                this.initShipments(true);
            }
        }
        // this.getShippingPerVillages();
    }

    public onStatusCodChanged() {
        for (const item in this.searchParameters) {
            if (item.includes('Id') || item === 'deliveredBy') {
                this.searchParameters[item].value = 0;
            } else {
                this.searchParameters[item].value = '';
            }
        }
        this.initShipments(true);
    }

    public stopProp(event) {
        event.stopPropagation();
    }

    public onFilterSenderReceiverRegion(data, isSender) {
        if (isSender) {
            if (data.customer) {
                this.searchParameters['senderName'] = {'value': data.customer};
            } else {
                delete this.searchParameters['senderName'];
            }

            if (data.regionId) {
                this.searchParameters['originRegionIds'] = {value: data.regionId};
            } else {
                delete this.searchParameters['originRegionIds'];
            }

            if (data.cityId) {
                this.searchParameters['originCityIds'] = {value: data.cityId};
            } else {
                delete this.searchParameters['originCityIds'];
            }

            if (data.villageId) {
                this.searchParameters['originVillageIds'] = {value: data.villageId};
            } else {
                delete this.searchParameters['originVillageIds'];
            }
            this.senderRegionData.customerName = this.searchParameters['senderName'] ? this.searchParameters['senderName'].value : '';
            this.senderRegionData.originRegion = this.searchParameters['originRegionIds']?.value;
            this.senderRegionData.originCity = this.searchParameters['originCityIds']?.value;
            this.senderRegionData.originVillage = this.searchParameters['originVillageIds']?.value;
            if (this.senderRegionPopOver && this.senderRegionPopOver.isOpen()) {
                this.senderRegionPopOver.close();
            }
        } else { // receiver
            if (data.customer) {
                this.searchParameters['receiverName'] = {value: data.customer};
            } else {
                delete this.searchParameters['receiverName'];
            }

            if (data.regionId) {
                this.searchParameters['destinationRegionIds'] = {value: data.regionId};
            } else {
                delete this.searchParameters['destinationRegionIds'];
            }

            if (data.cityId) {
                this.searchParameters['destinationCityIds'] = {value: data.cityId};
            } else {
                delete this.searchParameters['destinationCityIds'];
            }

            if (data.villageId) {
                this.searchParameters['destinationVillageIds'] = {value: data.villageId};
            } else {
                delete this.searchParameters['destinationVillageIds'];
            }
            this.receiverRegionData.customerName = this.searchParameters['receiverName'] ? this.searchParameters['receiverName'].value : '';
            this.receiverRegionData.destinationRegion = this.searchParameters['destinationRegionIds']?.value;
            this.receiverRegionData.destinationCity = this.searchParameters['destinationCityIds']?.value;
            this.receiverRegionData.destinationVillage = this.searchParameters['destinationVillageIds']?.value;
            if (this.receiverRegionPopOver && this.receiverRegionPopOver.isOpen()) {
                this.receiverRegionPopOver.close();
            }
        }
        this.initShipments(true);
    }

    public onFilterWeight(data, isWeight) {
        if (isWeight) {
            if (data.weightFrom) {
                this.searchParameters['fromWeight'] = {'value': Number(data.weightFrom)};
            } else {
                delete this.searchParameters['fromWeight'];
            }
            if (data.weightTo) {
                this.searchParameters['toWeight'] = {'value': Number(data.weightTo)};
            } else {
                delete this.searchParameters['toWeight'];
            }
            this.packageWeightData.weightFrom = this.searchParameters['fromWeight'] ? this.searchParameters['fromWeight'].value : '';
            this.packageWeightData.weightTo = this.searchParameters['toWeight'] ? this.searchParameters['toWeight'].value : '';
            if (this.packageWeightPopOver && this.packageWeightPopOver.isOpen()) {
                this.packageWeightPopOver.close();
            }
            if (this.volumetricpackageWeightPopOver && this.volumetricpackageWeightPopOver.isOpen()) {
                this.volumetricpackageWeightPopOver.close();
            }
            this.initShipments(true);
        }
    }

    public onFilterVolumetricWeight(data, isWeight) {
        if (isWeight) {
            if (data.weightFrom) {
                this.searchParameters['fromVolumetricWeight'] = {'value': Number(data.weightFrom)};
            } else {
                delete this.searchParameters['fromVolumetricWeight'];
            }
            if (data.weightTo) {
                this.searchParameters['toVolumetricWeight'] = {'value': Number(data.weightTo)};
            } else {
                delete this.searchParameters['toVolumetricWeight'];
            }
            this.packageWeightData.weightFrom = this.searchParameters['fromVolumetricWeight'] ? this.searchParameters['fromVolumetricWeight'].value : '';
            this.packageWeightData.weightTo = this.searchParameters['toVolumetricWeight'] ? this.searchParameters['toVolumetricWeight'].value : '';
            if (this.packageWeightPopOver && this.packageWeightPopOver.isOpen()) {
                this.packageWeightPopOver.close();
            }
            if (this.volumetricpackageWeightPopOver && this.volumetricpackageWeightPopOver.isOpen()) {
                this.volumetricpackageWeightPopOver.close();
            }
            this.initShipments(true);
        }
    }

    public closeSenderReceiverFilter(isSender) {
        if (isSender) {
            this.senderRegionPopOver.close();
        } else {
            this.receiverRegionPopOver.close();
        }
    }

    public closePackageWeightFilter() {
        this.packageWeightPopOver.close();
    }

    public closeVolumetricPackageWeightFilter() {
        this.volumetricpackageWeightPopOver.close();
    }

    public closeChangePackageStatusPopOver(popOver, filter) {
        popOver.close();
        if (filter) {
            this.initShipments(filter);
        }
    }

    addAttachment(fileChooser) {
        fileChooser.click();
    }

    public onFileSelected($event: any) {
        const selectedFile = $event.target.files[0];
        if (!selectedFile) {
            return;
        }
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (selectedFile.name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {
            this.shipmentService.addPackagesAttachments(this.triggeredDropdownShipment.id, formData).subscribe(() => {
                this.isLoading = false;
                this.translateService.get('ALERTS.ATTACHMENT_ADDED_SUCCESSFULLY').subscribe(
                    (message) => {
                        this.messageService.add({severity: 'success', detail: message});
                    }
                );
            }, error => {
                console.error(error);
                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
        }
    }

    public openImages(event, pkgId) {
        this.stopProp(event);
        this.shipmentsService.getPackageProofList(pkgId).subscribe((res: any) => {
            const modal = this.modalService.open(ImageSliderComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.isModal = true;
            if (res == null || res.urls === undefined) {
                res = {'urls': []};
            }
            modal.componentInstance.images = res.urls;
        });
    }

    public openMap(event, destinationAddress: any) {
        this.stopProp(event);
            const modal = this.modalService.open(MapComponent, {
                backdrop: 'static',
                backdropClass: 'map-component-backdrop',
                windowClass: 'map-component',
                size: 'lg'
            });
            modal.componentInstance.isModal = true;
            modal.componentInstance.location = {latlng: {lat: destinationAddress.latitude, lng: destinationAddress.longitude}};
            modal.componentInstance.showInitialMarker = true;
            modal.componentInstance.defaultZoom = 12;
            modal.componentInstance.readOnly = true;
    }


    public openSignatureImages(event, pkgId) {
        this.stopProp(event);
        this.shipmentsService.getPackageSignature(pkgId).subscribe((res: any) => {
            const modal = this.modalService.open(ImageSliderComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.isModal = true;
            modal.componentInstance.isSignature = true;
            modal.componentInstance.images = [res.url];
        });
    }

    public allowAddAttachments() {
        return this.triggeredDropdownShipment.status === 'DELIVERED_TO_RECIPIENT' ||
            this.triggeredDropdownShipment.status === 'PARTIALLY_DELIVERED' ||
            this.triggeredDropdownShipment.status === 'RETURNED_BY_RECIPIENT' ||
            this.triggeredDropdownShipment.status === 'BROUGHT' ||
            this.triggeredDropdownShipment.status === 'SWAPPED' || this.isLcl;
    }

    public navigateTo(navigateTo, queryParams = {}) {
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }

    public goToChangePackageStatus() {
        const queryParams = {isBundle: true};
        this.navigateTo('change-status', queryParams);
    }

    public onToggleCodRequest(pkg) {
        const modalRef = this.modalService.open(CodRequestChangeComponent, <any>{size: 'md', windowClass: 'cob-request-container'});
        modalRef.componentInstance.pkg = pkg;
        modalRef.result.then(
            (data: { isSuccess: boolean, state: 'accept' | 'reject' }) => {
                if (data.isSuccess) {
                    this.translateService.get(
                        ['ALERTS.COD_CHANGE_REQUEST_REJECT', 'ALERTS.COD_CHANGE_REQUEST_SUCCESSFULLY']
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({
                                    severity: 'success',
                                    detail: data.state === 'accept' ? message['ALERTS.COD_CHANGE_REQUEST_SUCCESSFULLY']
                                        : message['ALERTS.COD_CHANGE_REQUEST_REJECT']
                                });
                            }
                        );
                    this.initShipments(true);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public removeRedFlag(pkg: PackageModel) {
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.DELETE_FLAG', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.DELETE_FLAG'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmService.confirm({
            message: message,
            accept: () => {
                this.isLoading = true;
                this.shipmentsService.removeFlag(pkg.id).subscribe(
                    () => {
                        this.isLoading = false;
                        this.translateService.get(
                            'ALERTS.FLAG_REMOVED'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                }
                            );
                        this.initShipments(true);
                    }, (error) => {
                        this.isLoading = false;
                        console.error(error);
                    }
                );
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    public showAWBDialog($event) {
        this.downloadLastAddedPackages($event);
    }

    public onHidePrintAWB($event) {
        this.showAWBPrintModal = $event;
    }

    public downloadLastAddedPackages(ids) {
        if (ids.length === 0) {
            return;
        }
        const typePrint = null;

        this.shipmentService.downloadMultiple(ids, typePrint)
            .subscribe(
                (data: { url: string }) => {
                    this.linkAWB = data.url;
                    this.titleCode = 'PRINT_INVOICE';
                    this.showAWBPrintModal = true;
                    if (!this.applicationStateService.getIsMobileResolution()) {
                        this.showPrintPackagesButton = true;
                    }
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    public onHubSelected(hubId, isCodStatus = false) {
        if (isCodStatus) {
            this.searchParameters.codHubId.value = hubId;
        } else {
            if (this.selectedShipmentStatus === 'EXPORTED_TO_HUB') {
                this.searchParameters.exportedHubId.value = hubId;
            } else {
                this.hubId = hubId;
            }
        }
        this.initShipments(true);
    }
    public ontoHubSelected(hubId) {
        this.searchParameters.toHub.value = hubId;
        this.initShipments(true);
    }

    public onFromHubSelected(hubId) {
        this.searchParameters.fromHub.value = hubId;
        this.fromHub = hubId;
        this.initShipments(true);
    }

    public onToHubSelected(hubId) {
        this.searchParameters.toHub.value = hubId;
        this.toHub = hubId;
        this.initShipments(true);
    }

    public onDriverSelected(driverId, isCodStatus = false) {
        if (isCodStatus) {
            this.searchParameters.codDriverId.value = driverId;
        } else {
            this.searchParameters.driverId.value = driverId;
        }
        this.initShipments(true);
    }

    public onShelfSelected(shelfId, isCodStatus = false) {
        if (isCodStatus) {
            this.searchParameters.codShelfId.value = shelfId;
        } else {
            this.searchParameters.shelfId.value = shelfId;
            this.shelfId = shelfId;
        }
        this.initShipments(true);
    }

    public onWeightFilterSelected(from, to) {
        this.searchParameters.fromWeight = from ? from : null;
        this.searchParameters.toWeight = to ? to : null;
    }

    onTripIdSelected(tripId) {
        this.selectedTrip.value = tripId.value;
        this.initShipments(true);
    }

    onShipmentMethodSelected(method) {
        this.selectedShipmentMethod.value = method.value;
        this.initShipments(true);
    }

    onDeliveredBySelected(driverId) {
        this.searchParameters.deliveredBy.value = driverId;
        this.initShipments(true);
    }

    public showHubs() {
        return !this.partnerId && ((this.selectedShipmentStatus === 'SCANNED_BY_HANDLER_AND_UNLOADED' && !this.customizationCompaniesService.checkCompanies(this.companies.JETT))
            || this.selectedShipmentStatus === 'RETURNED_BY_RECIPIENT'
            || this.selectedShipmentStatus === 'POSTPONED_DELIVERY'
            || this.selectedShipmentStatus === 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'
            || SPECIAL_STATUS_FOR_HUBS_ACCOUNTING.indexOf(this.selectedShipmentStatus) > -1
            || this.selectedShipmentStatus === 'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT');
    }

    public showtoHub() {
        return this.userInfo.isSupportVillageHub;
    }

    public showHubsCodStatusFilter() {
        return !this.partnerId && this.isShowCodStatus && this.selectedMultiSelectCodStatus.length === 1
            && this.selectedMultiSelectCodStatus[0] === 'IN_HUB';
    }

    public showDriversCodStatusFilter() {
        return !this.partnerId && this.selectedMultiSelectCodStatus.length === 1
            && this.selectedMultiSelectCodStatus[0] === 'SCANNED_BY_DRIVER_AND_IN_CAR';
    }

    public showDriversFilter() {
        const allowedStatuses = ['ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL', 'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT', 'EXPORTED_TO_THIRD_PARTY',
            'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP', 'DELIVERED_TO_RECIPIENT', 'RETURNED_BY_RECIPIENT', 'POSTPONED_DELIVERY', 'SCANNED_BY_DRIVER_AND_IN_CAR'];
        return !this.partnerId && allowedStatuses.indexOf(this.selectedShipmentStatus) !== -1;
    }

    public showPickupTypeFilter() {
        const allowedStatuses = ['SCANNED_BY_DRIVER_AND_IN_CAR'];
        return !this.partnerId && allowedStatuses.indexOf(this.selectedShipmentStatus) !== -1;
    }

    public showShelfFilter() {
        const allowedStatuses = ['MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'];
        return !this.partnerId && allowedStatuses.indexOf(this.selectedShipmentStatus) !== -1 && this.hubId;
    }


    showDeliveredByFilter() {
        return ['COMPLETED'].indexOf(this.selectedShipmentStatus) !== -1;
    }

    public closeMoreActionsPopOver() {
        if (this.moreActionsPopOver && this.moreActionsPopOver.isOpen()) {
            this.moreActionsPopOver.close();
        }
        if (this.mobileMoreActionsPopOver && this.mobileMoreActionsPopOver.isOpen()) {
            this.mobileMoreActionsPopOver.close();
        }
    }

    getColumnCount() {
        setTimeout(() => {
            this.tableColumnCount = document.querySelectorAll('.shipments-table th').length;
        }, 300);
    }

    public swapTheSenderAndReceiver(shipment) {
        this.translateService.get(
            ['ALERTS.SWAP_SENDER_RECEIVER', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    const message = data['ALERTS.SWAP_SENDER_RECEIVER'];
                    const yes = data['GENERAL.YES'];
                    const no = data['GENERAL.NO'];
                    this.confirmationService.confirm({
                        message: message,
                        accept: () => {
                            this.shipmentsService.swapSenderAndReceiver(shipment.id).subscribe(() => {
                                this.initShipments(true);
                            });
                        },
                        acceptLabel: yes,
                        rejectLabel: no,
                        reject: () => {
                        }
                    });
                }
            );

    }

    getVehicleTypes(event) {
        const query = '?page=1&pageSize=20&search=' + event.query;
        this.serviceTypesService.getAllVehicleTypes(query).subscribe(
            (res: any) => {
                this.vehicleTypesList = res;
            }, error => {
                console.error(error);
            }
        );
    }

    public chooseVehicleType($event) {
        this.selectedVehicleType = JSON.parse(JSON.stringify($event));
        this.selectedVehicleTypeName = this.selectedLanguage === 'ar' ? this.selectedVehicleType.arabicName : this.selectedVehicleType.name;
        this.initShipments(true);
    }

    public onClearVehicleTypeFilter(initShipments = true) {
        this.selectedVehicleType = null;
        this.selectedVehicleTypeName = '';
        if (initShipments) {
            this.initShipments(initShipments);
        }
    }

    getServiceTypes(event) {
        const query = '?page=1&pageSize=20&search=' + event.query;
        this.serviceTypesService.getServiceTypes(query).subscribe(
            (res: any) => {
                this.serviceTypesAcList = res.data;
            }, error => {
                console.error(error);
            }
        );
    }

    getReceiverCity(event) {
        const params = {
            page: 1,
            pageSize: 20,
            search: event.query
        };
        this.zonesService.getCitiesList(params).subscribe(
            (res: any) => {
                this.receiverCityList = res.data;
            }, error => {
                console.error(error);
            }
        );
    }

    public chooseServiceType($event) {
        this.selectedServiceType = JSON.parse(JSON.stringify($event));
        this.searchParameters.serviceTypeId.value = this.selectedServiceType.id;
        this.selectedServiceTypeName = this.selectedLanguage === 'ar' ? this.selectedServiceType.arabicName : this.selectedServiceType.name;
        this.initShipments(true);
    }

    public chooseReceiverCity($event) {
        setTimeout(() => {
            this.selectedReceiverCity = $event;
            this.selectedReceiverCityName = this.selectedLanguage === 'ar' ? this.selectedReceiverCity.arabicName : this.selectedReceiverCity.name;
            this.initShipments(true);
        }, 10);

    }

    public onClearReceiverCity(initShipments = true) {
        this.selectedReceiverCity = null;
        this.selectedReceiverCityName = '';


        if (initShipments) {
            this.initShipments(initShipments);
        }
    }

    public onClearServiceTypeFilter(initShipments = true) {
        this.selectedServiceType = null;
        this.selectedServiceTypeName = '';
        this.searchParameters.serviceTypeId.value = 0;
        if (initShipments) {
            this.initShipments(initShipments);
        }
    }

    onDismissRouteOptimizationMessage() {
        this.isShowRouteOptimizationMessage = false;
        this.utilitiesService.isShowRouteOptimizationMessage = false;
    }

    onGenerateRouteOptimization() {
        this.isShowRouteOptimizationMessage = false;
        this.utilitiesService.isShowRouteOptimizationMessage = false;
        this.modalService.open(OptimizedRoutesComponent, {
            size: 'lg',
            backdrop: 'static',
            windowClass: 'optimized-routes'
        });
    }

    scanUnlistedPackages() {
        this.moreActionsPopOver.close();
        this.modalService.open(ScanUnlistedPackagesComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'scan-unlisted-packages'
        });
    }

    addAttachments(markAsLclPaid = false) {
        this.shipmentOptions.hide();
        this.moreActionsPopOver.close();
        const modal = this.modalService.open(AddPackageAttachmentsComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.packageId = this.triggeredDropdownShipment.id;
        modal.componentInstance.markAsLclPaid = markAsLclPaid;
        modal.result.then(result => {
            if (result && result.isSuccess) {
                this.translateService.get(markAsLclPaid ? 'ALERTS.ORDER_MARKED_PAID_SUCCESSFULLY' :
                    'ALERTS.ATTACHMENT_ADDED_SUCCESSFULLY').subscribe(transValue => {
                    this.messageService.add({
                        severity: 'success',
                        detail: transValue
                    });
                });
            }
        });
    }

    public transferToThirdPart() {
        this.closeMoreActionsPopOver();
        this.isLoading = true;
        let successMessage, errorMessage;
        this.translateService.get(
            ['ALERTS.BULK_EXPORT_SUCCESSFUL', 'ALERTS.BULK_EXPORT_FAILED']
        ).subscribe(
            (data) => {
                successMessage = data['ALERTS.BULK_EXPORT_SUCCESSFUL'];
                errorMessage = data['ALERTS.BULK_EXPORT_FAILED'];
            }
        );
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.transferPackagesToThirdPart(selectedPackages, this.companyId).subscribe(() => {
            this.messageService.add({severity: 'success', detail: this.transformToDistributor(successMessage)});
            this.initShipments(true);
            this.isLoading = false;
        }, () => {
            this.messageService.add({severity: 'error', detail: this.transformToDistributor(errorMessage)});
            this.isLoading = false;
        });
    }

    receivePackingList() {
        const modal = this.modalService.open(ReceivePackingListComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'receive-packing-list'
        });
    }

    createLCLContainer(editMode = false, container = null) {
        this.closeMoreActionsPopOver();
        let containerInfo = null;
        if (container) {
            this.shipmentService.getContainerSubBundles(container.barcode).subscribe(
                () => {
                },
                error => {
                    console.error(error);
                }
            );

            containerInfo = {
                containerNumber: container.containerNumber,
                // sourceHub: null,
                // destinationHub: null
                // get list of barcodes and pass them to ReceivePackingListComponent component to use it in readBarcode function (with loop)
                // ==> barcodes: [ // list of barcodes]
            };
        }

        const modal = this.modalService.open(ReceivePackingListComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'receive-packing-list'
        });

        modal.componentInstance.isCreateContainer = true;
        modal.componentInstance.isEditContainer = true;
        modal.componentInstance.containerInfo = containerInfo;
    }

    changeContainerStatus(isReceiveContainer = false) {
        this.closeMoreActionsPopOver();
        const modal = this.modalService.open(UnloadContainerComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'receive-packing-list'
        });

        modal.componentInstance.isReceiveContainer = isReceiveContainer;
    }

    isTransferOutEnable() {
        return this.companyIdsToShowTransferButton.indexOf(this.companyId) > 0;
    }

    canEditShipmentStatus() {
        return (this.userRole === 'CLERK' && this.userInfo.isAllowClerkChangeStatus) ||
            (this.userRole !== 'CLERK' && this.userRole !== 'HUB_CLERK' && !this.archivedMode);
    }

    isCustomerAccountManager() {
        return this.userRole === 'CUSTOMER_ACCOUNT_MANAGER';
    }

    setAttachmentFilter(filter: string, initShipments = true) {
        this.selectedAttachmentsFilter = filter;
        if (initShipments) {
            this.initShipments(true);
        }
    }

    setLocationFilter(filter: string, initShipments = true) {
        this.selectedLocationFilter = filter;
        if (initShipments) {
            this.initShipments(true);
        }
    }

    setDeliveryLocationFilter(filter: string, initShipments = true) {
        this.selectedDeliveryLocationFilter = filter;
        if (initShipments) {
            this.initShipments(true);
        }
    }

    setSignatureFilter(filter: string, initShipments = true) {
        this.selectedSignatureFilter = filter;
        if (initShipments) {
            this.initShipments(true);
        }
    }

    getSuppliers(suppliers: string[]) {
        if (!suppliers) {
            return '';
        }
        return suppliers.reduce((accumlator, supplier) => `${accumlator}, ${supplier}`);
    }

    onHideCalenderRangePicker($event: any) {
        this.updateCalled = false;
    }

    isShowSendPinCodeAction(shipment: PackageModel) {
        const statusList = ['POSTPONED_DELIVERY', 'SCANNED_BY_DRIVER_AND_IN_CAR'];
        const sendVerificationPinCodeForPkgWithCodGreaterThan = this.userService.userInfo.isEnableDeliveryVerificationPinCodeForPkgsWithCodGreaterThan;

        return this.userService.userInfo.isEnableDeliveryVerificationPinCodeForPkgs
            && (
                (statusList.indexOf(shipment.status) !== -1)
                && (
                    (shipment.shipmentType === 'REGULAR' || (shipment.shipmentType === 'COD' && shipment.cod === 0))
                    || (sendVerificationPinCodeForPkgWithCodGreaterThan !== null && shipment.cod >= sendVerificationPinCodeForPkgWithCodGreaterThan)
                )
            );
    }

    generateVerificationPinCode(pkgId) {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.SEND_VERIFICATION_PIN_CODE_CONFIRMATION'),
            accept: () => {
                this.shipmentService.sendVerificationPinCode(pkgId).subscribe(() => {
                    this.messageService.add(
                        {
                            severity: 'success',
                            detail: this.translateService.instant('ALERTS.VERIFICATION_PIN_CODE_SENT_SUCCESSFULLY')
                        });
                }, error => {
                    console.error(error);
                });
            },
            reject: () => {

            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    changeColorOfDraftPackages(isDraftPackageWithoutReceiverInfo) {
        return (this.companyId === 4 || this.companyId === 1) && isDraftPackageWithoutReceiverInfo;
    }

    partnerSelected($event: any) {
        this.filterByPartnerId = $event;
        this.initShipments(true);
    }

    transferToStorm() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_STORM_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToStorm(body, this.companyId).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });

    }

    transferToAramex() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_ARAMEX_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToAramex(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_ARAMEX';
                        } else {
                            this.initShipments(true);
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        this.isLoading = false;
                        console.error(error);
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToMughrabe() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_MUGHRABE_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToMughrabe(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToOustol() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_OUSTOL_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToOustol(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToAutobahn() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_AUTOBAHN_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToAutobahn(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    async genericTransferTo(btn) {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: await btn.confirmation.toPromise(),
            accept: () => {
                this.shipmentService.genericTransferTo(btn.from, btn.to, body, btn.customUrl).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToAlkhal() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_ALKHAL_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToAlkhal(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToSpeedLine() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_SPEED_LINE_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToSpeedLine(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToSonic() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_SONIC_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToSonic(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }


    transferToEveryWhere() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_EVERYWHERE_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToEveryWhere(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    private handleFillingTheScreen() {
        if (this.applicationStateService.getIsMobileResolution()) {
            return;
        }
        const ob = timer(1000, 1000);
        let stopTimer = false;
        const subscribe = ob.subscribe(res => {
            if (this.hasMore) {

                const hasVerticalScrollbar = (this.table.wrapperViewChild.nativeElement.scrollHeight - 20) > this.table.wrapperViewChild.nativeElement.clientHeight;

                if (!hasVerticalScrollbar) {
                    if (this.spinnerState === this.spinnerStates.LOADED) {
                        this.pageNumber++;
                        this.initShipments();
                    }
                } else {
                    stopTimer = true;
                }
            } else {
                stopTimer = true;
            }
            if (stopTimer) {
                subscribe.unsubscribe();
            }
            this.subscriptions.push(subscribe);
        });
    }

    notifyUnsetCost(pkg) {
        return [209].indexOf(this.companyId) !== -1 && pkg.cost === 0;
    }

    setExportingHubPackagesFilterList() {
        return EXPORTING_HUB_PACKAGES_FILTER_LIST.map(
            (filter: string) => {
                return {
                    label: this.translateService.instant(`MANAGE_SHIPMENTS.EXPORTING_HUB_PACKAGES_FILTER_LIST.${filter}`),
                    value: filter
                };
            }
        );
    }

    addPackageToBundle(bundleId) {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isAdmin = true;
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.shipmentService.addPackageToBundle(bundleId, data.package.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.initShipments(true);

                        }, error => {
                            console.error(error);
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    notifyResolveCodPanel(id) {
        this.shipmentService.sendRemainderForFailedAttempts(id).subscribe(
            (response: any) => {
                this.translateService.get('ALERTS.CUSTOMER_NOTIFIED_SUCCESSFULLY').subscribe(
                    (value) => {
                        this.messageService.add({severity: 'success', detail: value});
                    }
                );
            },
            (error) => {
                console.error(error);
            }
        );
    }

    showResolveCodPanel($event) {
        if (!this.triggeredDropdownShipment.isFailed) {
            return;
        }
        this.shipmentOptions.hide();
        this.resolveFailurePanel.toggle($event);
    }

    resolveFailedPackage() {
        if (!this.triggeredDropdownShipment.isFailed || !this.resolveMsg) {
            return;
        }
        this.isSendingMsg = true;
        const body = {note: this.resolveMsg};
        this.shipmentsService.resolveFailedPackage(this.triggeredDropdownShipment.id, body).subscribe(
            () => {
                this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                this.isSendingMsg = false;
                this.resolveMsg = '';
                this.resolveFailurePanel.hide();
                this.initShipments(true);
            }, error => {
                this.isSendingMsg = false;
                console.error(error);
            }
        );
    }

    isShowFailedStatus() {
        return ['SCANNED_BY_DRIVER_AND_IN_CAR', 'SCANNED_BY_HANDLER_AND_UNLOADED'].indexOf(this.selectedShipmentStatus) > -1
            && this.isFailed && !this.archivedMode;
    }

    isTransferToAmanEnabled() {
        return [254].indexOf(this.companyId) >= 0;
    }

    transferToToAman() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_AMAN_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToAman(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }


    transferToIMile() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_IMILE_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToIMile(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_IMILE';
                        } else {
                            this.initShipments(true);
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToSMB() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_SMB_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToSMB(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_SMB';
                        } else {
                            this.initShipments(true);
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }
    transferToHolol() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_HOLOL_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToHolol(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_HOLOL';
                        } else {
                            this.initShipments(true);
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }
    transferToTorod() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_TOROD_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToTorod(body).subscribe(
                    (res: any[]) => {
                        if (res.length) {
                            const modal = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'
                                });
                            modal.componentInstance.results = res;
                            modal.componentInstance.label = 'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_TOROD';
                        } else {
                            this.initShipments(true);
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToToHawk() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_HAWK_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToHawk(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    printCustodyPackages () {
        this.isLoading = true;
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.PRINT_CUSTODY_REPORTS'),
            accept: () => {
                this.shipmentService.printCustodyReport({isDeleted: this.isDeleted}).subscribe(
                    (data: any) => {
                        this.isLoading = false;
                        window.open(data.url, '_blank');
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    isTransferToHiExpressEnable() {
        return [153].indexOf(this.companyId) >= 0;
    }

    transferToToHiExpress() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_HI_EXPRESS_CONFIRMATION'),
            accept: () => {
                this.shipmentService.transferToHiExpress(body).subscribe(
                    () => {
                        this.initShipments(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    showFiltersMenu(event) {
        event.stopPropagation();
        this.filtersMenu.toggle(event);
        this.filterMenuOpen = true;
    }

    onHideFiltersMenu() {
        this.filterMenuOpen = false;
    }

    clearAllUpdatesNotification() {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.MARK_ALL_AS_SEEN_CONFIRMATION'),
            accept: () => {
                this.markAllAsSeenRequest = true;
                this.systemUpdatesService.markAllAsSeen().subscribe(res => {
                    this.userService.initSystemUpdatesBadge();
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    private initButtons() {
        this.userService.getTransferToList().subscribe(res => {
            this.transferToList = res;
        });
    }

    shipmentOptionClicked() {
        if (this.shipmentOptions && this.shipmentOptions.hide) {
            this.shipmentOptions.hide();
        }
    }

    printJNT() {
        const pkg = this.triggeredDropdownShipment;
        this.shipmentService.printJNT(pkg.id).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            },
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    printAramex() {
        const pkg = this.triggeredDropdownShipment;
        this.shipmentService.printAramex(pkg.id).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            },
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    cancelJNT() {
        const pkg = this.triggeredDropdownShipment;
        const modal = this.modalService.open(CancelJntPrintDialogComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.shipment = pkg;
        modal.result.then(
            (data) => {
                if (data && data.isSuccess) {
                    const message = 'ALERTS.JNT_CANCELED_SUCCESSFULLY';
                    this.translateService.get(message).subscribe(
                        (translatedMessage) => {
                            this.messageService.add({severity: 'success', detail: translatedMessage});
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    isShowLocation(pkg: any) {
        return (pkg.destinationAddress.longitude !== null && pkg.destinationAddress.longitude > 0)
            && (pkg.destinationAddress.latitude !== null && pkg.destinationAddress.latitude > 0);
    }

    isShowDeliveredLocation(pkg: any) {
        return pkg.deliveryLocation && (pkg.deliveryLocation.longitude !== null && pkg.deliveryLocation.longitude > 0)
            && (pkg.deliveryLocation.latitude !== null && pkg.deliveryLocation.latitude > 0);
    }

    isJettCompany() {
        return this.customizationCompaniesService.checkCompanies(this.companies.JETT);
    }

    getFromHub(data) {
        if (['OUTBOUND', 'STORE_TO_DOOR_B'].indexOf(data.orderId) > -1) {
            return data.hubName;
        }
        return '';
    }

    generateCustomBarcodes() {
        const pkg = this.triggeredDropdownShipment;
        const modal = this.modalService.open(GenerateCustomBarcodesComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.shipment = pkg;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
}
