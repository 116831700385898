import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {TicketingDashboardCardModel} from '../../models/ticketing-dashboard-card.model';
import {TicketingSystemService} from '../../services/ticketing-system.service';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {CategoryModel} from '../../models/category-model';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {GeneralNoteModel} from '../../models/general-note-model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralNoteFormComponent} from './general-note-form/general-note-form.component';
import {MessageService} from 'primeng/api';
import {CustomConfirmationMessageComponent} from '../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';

@Component({
    selector: 'app-ticketing-system-dashboard',
    templateUrl: 'ticketing-system-dashboard.component.html',
    styleUrls: ['ticketing-system-dashboard.component.css']
})
export class TicketingSystemDashboardComponent implements OnInit {
    filterForm: FormGroup;
    fromDate: Date;
    toDate: Date;
    dateRangPickerOptions;

    noteFilterForm: FormGroup;
    noteFromDate: Date;
    noteToDate: Date;
    displayAddTicket = false;

    dashboardCards: TicketingDashboardCardModel [] = [];
    gettingStats = false;

    categories: CategoryModel [] = [];
    categoriesDropDownList = [];
    gettingCategories = false;

    selectedLang: string;

    /** for general notes**/

    notesPage = 1;
    notesPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    generalNotes: GeneralNoteModel[] = [];
    totalGeneralNotes = 0;
    gettingGeneralNotes = false;
    fetchedGeneralNotes: GeneralNoteModel[] = [];

    categoryFilterSelected = -1;

    // test
    basicData: any;
    basicOptions: any;

    public permissions = '';

    //
    constructor(@Inject(FormBuilder) private formsBuilder: FormBuilder,
                private dateRangePickerService: DateRangePickerService,
                private ticketingSystemService: TicketingSystemService,
                private translateService: TranslateService,
                private datePipe: DatePipe,
                private modalService: NgbModal,
                private authenticationService: AuthenticationService,
                private router: Router,
                private userService: UserService,
                private messageService: MessageService,
                private rolesService: RolesService) {
    }

    ngOnInit() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('TICKETING_SYSTEM', role);
        this.selectedLang = this.translateService.currentLang;

        // test for achievement section
        // this.basicData = {
        //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //     datasets: [
        //         {
        //             label: 'First Dataset',
        //             data: [65, 59, 80, 81, 56, 55, 40],
        //             fill: false,
        //             borderColor: '#00AF4D',
        //             tension: .4
        //         },
        //         {
        //             label: 'Second Dataset',
        //             data: [28, 48, 40, 19, 86, 27, 90],
        //             fill: false,
        //             borderColor: '#F7685B',
        //             tension: .4
        //         }
        //     ]
        // };
        // this.basicOptions = {
        //     legend: {
        //         display: false
        //     }
        // };
        //
        this.selectedLang = this.translateService.currentLang;
        this.getDateRangePickerOptions();
        this.initForm();
        this.initDatesFilters();
        this.getDashboardStats();
        this.getCategories();
        this.getGeneralNotes(true);
    }

    createQuery() {

        let result = '';

        if (this.filterForm) {
            if (this.fromDate) {
                result += '?fromDate=' + this.transformDate(this.fromDate, true);
            }
            if (this.toDate) {
                result += '&toDate=' + this.transformDate(this.toDate, true);
            }
            if (this.categoryFilterSelected && this.categoryFilterSelected !== -1) {
                result += '&categoryId=' + this.categoryFilterSelected;
            }
        }
        return result;
    }

    transformDate(date, filterDateValue = false) {
        let format = 'dd/MM/yyyy';
        if (filterDateValue) {
            format = 'yyyy-MM-dd';
        }
        return this.datePipe.transform(date, format);
    }

    getDashboardStats() {
        this.gettingStats = true;
        const urlQuery = this.createQuery();
        this.ticketingSystemService.getDashboardStats(urlQuery).subscribe(
            (stats: any) => {
                this.dashboardCards = [];

                for (const stat in stats) {
                    if (stat !== 'rejectedTickets' &&
                        stat.toLocaleLowerCase() !== 'DUETODAYTICKETS'.toLocaleLowerCase() &&
                        stat.toLocaleLowerCase() !== 'DUETICKETS'.toLocaleLowerCase()) {
                    // if (stat !== 'rejectedTickets') {
                        if (!this.permissions.includes('SELECT_ANY_DEPARTMENT') || stats.hasOwnProperty(stat) && (this.categoryFilterSelected !== -1 ||
                                                            this.categoryFilterSelected === -1 &&
                                                                    (stat.toLocaleLowerCase() !== 'internalTickets'.toLocaleLowerCase() &&
                                                                        stat.toLocaleLowerCase() !== 'externalTickets'.toLocaleLowerCase()))) {
                            this.dashboardCards.push({label: stat.toUpperCase(), count: stats[stat]});
                        }
                    }

                }
                // remove the next line
                // this.dashboardCards.push({label: 'EXTERNALTICKET'.toUpperCase(), count: stats['dueTodayTickets']});

                this.gettingStats = false;
            }, error => {
                this.gettingStats = false;
                console.error(error);
            }
        );
    }

    getCategories() {
        this.gettingCategories = true;
        this.ticketingSystemService.getAllCategories().subscribe(
            (categories: CategoryModel[]) => {
                this.categories = categories;
                this.categoriesDropDownList = categories;
                if (this.permissions.includes('SELECT_ANY_DEPARTMENT')) {
                    this.categoriesDropDownList.unshift({
                        name: this.translateService.instant('TICKETING_SYSTEM.DASHBOARD.ALL_DEPARTMENT'),
                        label: 'ALL'
                    });
                }
                this.gettingCategories = false;
            }, error => {
                this.gettingCategories = false;
                console.error(error);
            }
        );
    }

    createGeneralNotesQuery() {
        let result = `?pageSize=${this.notesPageSize}&page=` + (this.notesPage);
        if (this.noteFilterForm) {
            if (this.noteFromDate) {
                result += '&fromDate=' + this.transformDate(this.noteFromDate, true);
            }
            if (this.noteToDate) {
                result += '&toDate=' + this.transformDate(this.noteToDate, true);
            }
            if (this.noteFromDate || this.noteToDate) {
                result += '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
            }
        }
        return result;
    }

    getGeneralNotes(reset = false) {
        const query = ''; // this.createGeneralNotesQuery(); //keep this line
        this.fetchedGeneralNotes = [];
        if (this.gettingGeneralNotes) {
            return;
        }
        if (reset) {
            this.generalNotes = [];
        }
        this.gettingGeneralNotes = true;

        this.ticketingSystemService.getGeneralNotes(query).subscribe(
            (response: {data:  GeneralNoteModel[] , totalRecordsNo: number}) => {
                this.fetchedGeneralNotes = response.data;
                this.generalNotes = [...this.generalNotes, ...this.fetchedGeneralNotes];
                this.totalGeneralNotes = response.totalRecordsNo;
                this.gettingGeneralNotes = false;
            }, error => {
                this.gettingGeneralNotes = false;
                console.error(error);
            }
        );
    }

    lazyLoadGeneralNotes(reset = false) {
        if (reset) {
            this.notesPage = 0;
        }
        if (!this.gettingGeneralNotes) {
            this.notesPage++;
            this.getGeneralNotes(reset);
        }
    }

    @HostListener('scroll', ['$event'])
    scrollGeneralNotes(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight
            && (this.generalNotes.length !== this.totalGeneralNotes) && !this.gettingGeneralNotes) {
            this.lazyLoadGeneralNotes();
        }
    }

    initForm() {
        const date = new Date();
        const date2 = new Date();
        this.fromDate = new Date(date.setDate(date.getDate() - 1));
        this.toDate = new Date(date2.setDate(date2.getDate()));
        this.noteFromDate = new Date(date);
        this.noteToDate = new Date(date2);
        this.filterForm = this.formsBuilder.group({
            categoryName: 'ALL',
            searchText: [''],
            fromDate: [this.fromDate],
            toDate: [this.toDate]
        });
        this.noteFilterForm = this.formsBuilder.group({
            noteFromDate: [this.noteFromDate],
            noteToDate: [this.noteToDate]
        });
    }
    initDatesFilters() {
        this.filterForm = this.formsBuilder.group({
            searchText: [''],
            fromDate: [this.fromDate],
            toDate: [this.toDate]
        });
        this.noteFilterForm = this.formsBuilder.group({
            noteFromDate: [this.noteFromDate],
            noteToDate: [this.noteToDate]
        });
    }

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.filterForm.controls.fromDate.setValue(this.fromDate);
        this.filterForm.controls.toDate.setValue(this.toDate);
        this.filterForm.controls.fromDate.updateValueAndValidity();
        this.filterForm.controls.toDate.updateValueAndValidity();
        this.getDashboardStats();
    }

    onNoteDateSelected(value) {
        this.noteFromDate = new Date(value.start);
        this.noteToDate = new Date(value.end);
        this.noteFilterForm.controls.noteFromDate.setValue(this.noteFromDate);
        this.noteFilterForm.controls.noteToDate.setValue(this.noteToDate);
        this.noteFilterForm.controls.noteFromDate.updateValueAndValidity();
        this.noteFilterForm.controls.noteToDate.updateValueAndValidity();
        this.notesPage = 1;
        this.getGeneralNotes(true);
    }

    getDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });
    }

    openGeneralNoteForm(generalNote = null) {
        const modal = this.modalService.open(GeneralNoteFormComponent, {
            backdrop: 'static',
            windowClass: 'general-note-form',
        });
        if (generalNote) {
            modal.componentInstance.generalNote = generalNote;
        }
        modal.result.then(
            (result) => {
                if (result && result.isSuccess) {
                    this.translateService.get(result.message).subscribe(
                        (message) => {
                            this.messageService.add({severity: 'success', detail: message});
                            this.lazyLoadGeneralNotes(true);
                        }
                    );
                }
            }
        );
    }

    deleteGeneralNote(noteId) {

        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', size: 'sm'});


        modal.componentInstance.title = 'TICKETING_SYSTEM.GENERAL_NOTES.CONFIRM_MESSAGE_TITLE';
        modal.componentInstance.confirmMessage = 'TICKETING_SYSTEM.GENERAL_NOTES.ALARMS.DELETE_GENERAL_NOTE_CONFIRMATION';
        modal.componentInstance.confirmButtonLabel = 'GENERAL.DELETE';
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    this.ticketingSystemService.deleteGeneralNote({id: noteId}).subscribe(
                        (res: any) => {
                            this.getGeneralNotes(true);
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('TICKETING_SYSTEM.GENERAL_NOTES.ALARMS.GENERAL_NOTE_DELETED_SUCCESSFULLY')
                            });
                        }, error => {
                            console.error(error);
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    showAddTicket() {
        this.displayAddTicket = true;
    }

    onHideAddTicket(event) {
        this.displayAddTicket = event;
    }

    public navigateTo(navigateTo, queryParams = {}, label = '') {
        if (label !== '') {
            if (label === 'ALLTICKETS') {
                queryParams['ticketStatusList'] = [label];
                if (this.permissions.includes('SELECT_ANY_DEPARTMENT') && this.categoryFilterSelected > 0) {
                    queryParams['sourceCategoryId'] = [this.categoryFilterSelected];
                    queryParams['categoryId'] = [this.categoryFilterSelected];
                } else {
                    queryParams['sourceCategoryId'] = [this.userService.userInfo.categoryId];
                    queryParams['categoryId'] = [this.userService.userInfo.categoryId];
                }
            } else if ((label === 'INPROGRESSTICKETS' || label === 'NEWTICKETS' || label === 'RESOLVEDTICKETS')) {
                queryParams['ticketStatusList'] = [label];
                queryParams['categoryId'] = this.permissions.includes('SELECT_ANY_DEPARTMENT') && this.categoryFilterSelected > 0 ? [this.categoryFilterSelected] : [this.userService.userInfo.categoryId];
            } else if ( label === 'EXTERNALTICKETS') {
                queryParams['sourceCategoryId'] = this.permissions.includes('SELECT_ANY_DEPARTMENT') ? [this.categoryFilterSelected] : [this.userService.userInfo.categoryId];
            } else if (label === 'INTERNALTICKETS' ) {
                queryParams['categoryId'] = this.permissions.includes('SELECT_ANY_DEPARTMENT') ? [this.categoryFilterSelected] : [this.userService.userInfo.categoryId];
            } else {
                return;
            }

        }
        if (this.fromDate) {
            queryParams['fromDate'] = this.fromDate;
        }
        if (this.toDate) {
            queryParams['toDate'] = this.toDate;
        }
        queryParams['fromCard'] = true;
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }

    onCategoryNameChanged(e) {
        if (e.value.id) {
           this.categoryFilterSelected = e.value.id;
        } else {
           this.categoryFilterSelected = -1;
        }

        this.getDashboardStats();
    }
}
