import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-form-group-component',
    templateUrl: 'form-group.component.html',
    styleUrls: ['form-group.component.scss']
})
export class FormGroupComponent {
    // Inputs
    @Input() controlType: 'text' | 'password' | 'submit' | 'textarea';
    @Input() label: string;
    @Input() placeholder: string;
    @Input() form: FormGroup;
    @Input() isSubmitting: boolean;
    @Input() disabled: boolean;
    @Input() controlName: string;

    // Outputs
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
}
