<div class="h-100  single-report-page">
    <app-admin-report [columns]="customerReport" [hasFilters]="true" [apiUrl]="reportAPI"
                      [dataInterceptor]="addCustomerName"
                      reportTitle="ADMINISTRATION.CUSTOMER_ACTIVITY_ADMINISTRATIVE_REPORT_TITLE">
        <ng-template>
            <div class="pl-2 pr-2">
                <app-users-list-dropdown-component
                        (userChanged)="onCustomerSelected($event)"></app-users-list-dropdown-component>
            </div>
        </ng-template>
    </app-admin-report>
</div>
