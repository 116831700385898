import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {DASHBOARD_CONSTANT} from '../../../dashboard/services/dashboard.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-shelf-item',
    templateUrl: './shelf-item.component.html',
    styleUrls: ['./shelf-item.component.scss']
})
export class ShelfItemComponent {
    // Inputs
    @Input() shelf: any;
    @Input() selectedShelves = {};

    // Outputs
    @Output() onRemoveShelf: EventEmitter<{ shelf: any }> = new EventEmitter<{ shelf: any }>();
    @Output() onEditShelf: EventEmitter<{ shelf: any }> = new EventEmitter<{ shelf: any }>();

    constructor(private router: Router,
                private athuenticationService: AuthenticationService,
                public activeModal: NgbActiveModal) {

    }

    public removeShelf() {
        this.onRemoveShelf.emit({shelf: this.shelf});
    }

    public modifyShelf() {
        this.onEditShelf.emit({shelf: this.shelf});
    }

    goToShelfPackages() {
        this.router.navigate([this.athuenticationService.companyName + 'home/manage-shelves/', this.shelf.id]);
        this.activeModal.close();

    }


}
