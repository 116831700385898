export const APIS = {
    GET_USER_INFO: ':api/admin/admin-info',
    LOGOUT: ':api/auth/user/logout',
    GET_HUBS: ':api/admin/hubs',
    GET_HUB: ':api/admin/hubs/{hubId}',
    GET_DRIVERS_NAME_AND_ID: ':api/admin/drivers/dropdown',
    GET_DRIVERS: ':api/admin/drivers',
    GET_DRIVERS_FAILURE_REASONS: ':api/driver/failure-reasons',
    GET_DRIVERS_WITH_COUNT: ':api/admin/drivers/with-count',
    GET_DRIVERS_WITHOUT_PACKAGES: ':api/admin/drivers/without-package',
    GET_DRIVER_OPTIMIZED_ROUTE: ':api/admin/drivers/{driverId}/carried-packages/optimized-route',
    GET_DRIVER: ':api/admin/drivers/{driverId}',
    ASSIGN_TO_DRIVER: ':api/admin/packages/assign/drivers/{driverId}',
    DETECT_DRIVER_TRACK: ':api/admin/packages/order-driver-packages',
    ASSIGN_PICKUPS_TO_DRIVER: ':api/admin/pickups/assign/drivers/{driverId}',
    NEW_HUB: ':api/admin/hubs/new',
    REMOVE_HUB: ':api/admin/hubs/{hubId}/delete',
    GET_CITIES: ':api/addresses/country/cities?countryName={countryName}',
    CHANGE_USER_INFO: ':api/users/change-info',
    UPDATE_HUB: ':api/admin/hubs/{hubId}/modify',
    GET_NOTIFICATIONS: ':api/users/notifications',
    SET_AS_READ: ':api/users/notifications/{notificationId}/set-as-read',
    SET_ALL_AS_READ: ':api/users/notifications/set-as-read',
    NUMBER_OF_UNREAD_NOTIFICATIONS: ':api/users/notifications/unread',
    DELETE_NOTIFICATION: ':api/users/notifications/{notificationId}/delete',
    GET_REGIONS: ':api/addresses/country/regions',
    GET_REGIONS_CITIES: ':api/addresses/region/cities',
    GET_VILLAGES: ':api/addresses/villages',
    GET_SHELF: ':api/admin/hubs/{hubId}/shelves',
    ADD_NEW_SHELF: ':api/admin/hubs/{hubId}/shelves/new',
    EDIT_SHELF: ':api/admin/hubs/shelves/{shelfId}/modify',
    DELETE_SHELF: ':api/admin/hubs/shelves/{shelfId}/delete',
    PRINT_HUBS: ':api/admin/reports/branches-cards/pdf',
    PRINT_SHELVES: ':api/admin/reports/shelves-cards/pdf',
    GET_COMPANY_ID: ':api/guests/companies/company/theme-and-id',
    GET_SHELF_PACKAGES: ':api/admin/shelves/{shelfId}/packages',
    GET_DRIVERS_LOCATION: ':api/geo-services/tracking/drivers/latest-locations',
    GET_SHELVE_CITIES: ':api/admin/hubs/shelf/{shelfId}/cities',
    GET_COMPANY_ADDRESS: ':api/admin/companies/address',
    PREVIEW_TEMPLATE: ':api/admin/companies/template/preview',
    GET_DRIVER_CHECK_INS: ':api/admin/packages/check-in/driver/{driverId}',
    GET_DRIVERS_LOCATIONS_WITH_STATUS: ':api/geo-services/tracking/drivers/latest-locations/with-count',
    GET_DRIVERS_TIME_TRACKING: ':api/admin/users/drivers/tracking',
    GET_DRIVERS_EARNINGS_DETAILS: ':api/accountant/drivers-earning/drivers/{driverId}',
    GET_HUB_SHIPMENTS: ':api/accountant/hubs/{hubId}/cod-packages/exported',
    RECEIVE_HUB_COD: ':api/accountant/hubs/{hubId}/exported/received-cod',
    RETRIEVE_HUB: ':api/admin/hubs/{hubId}/un-delete',
    GET_HUBS_RECEIVED_COD: ':api/accountant/hubs/{hubId}/cod-packages/exported',
    GET_HUBS_EXPORTED_COD: ':api/accountant/hub-mass-reports/{massReportId}/packages',
    UNSEEN_SYSTEM_UPDATES_FOR_USER: ':api/admin/companies/system-updates/unseen/count',
    LOAD_HUBS_RECEIVED_COD: ':api/accountant/hubs/exported/received-cod',
    EXPORT_DRIVER_COD_PACKAGES_TO_EXCEL: ':api/accountant/drivers/cod-packages/excel',
    EXPORT_HUB_COD_PACKAGES_TO_EXCEL: ':api/accountant/hubs/cod-packages/excel',
    CHECK_POSTPONED_PACKAGAES: ':api/admin/packages/check-postponed-packages',
    GET_MEMBERS: ':api/admin/customers/members'
};


/**
 *
 * DOCS:
 *
 *
 * GET_CUSTOMER_INFO: fetch the customer information, the customerId is added at the end of this API.
 */
