<div class="d-flex flex-column h-100 main-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>

    </div>
    <div class="d-flex flex-row">

        <div style="flex: 1" class="cod-title">
            {{'COMPANY.NOTIFICATION_SETTINGS'|translate}}
        </div>
    </div>
    <div class="events-table-container">
        <p-table dataKey="id" #table [value]="events" [autoLayout]="true"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 33%;">{{'COMPANY.EVENT'|translate}}</th>
                    <th style="width: 66%;">{{'COMPANY.TEMPLATE'|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-event let-columns="columns">
                <tr>
                    <td style="width: 33%;" *ngIf="!isArabic">
                        <div>{{event.action}}</div>
                    </td>
                    <td style="width: 33%;" *ngIf="isArabic">
                        <div>{{event.arabicAction}}</div>
                    </td>
                    <td style="width: 66%;">
                        <div>{{event.template}}
                        <i class="cursor-pointer edit-status fas fa-pencil-alt"
                           (click)="editNotificationSetting(event, event.id)"></i></div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col" style="padding-top: 20px">
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToReceiversWhenDriverPickup" (onChange)="confirmModify('PICKUP', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px" class="label-span">{{'COMPANY.IS_SEND_SMS_TO_RECEIVERS_WHEN_DRIVER_PICKUP'|translate | packageTitle}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToReceiversWhenDriverDeliver" (onChange)="confirmModify('DELIVER', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px" class="label-span">{{'COMPANY.IS_SEND_SMS_TO_RECEIVERS_WHEN_DRIVER_DELIVER'|translate | packageTitle}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToSenderWhenDriverDeliver" (onChange)="confirmModify('DELIVER_WITH_SEND_SMS_TO_SENDER', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px" class="label-span">{{'COMPANY.IS_SEND_SMS_TO_SENDER_WHEN_DRIVER_DELIVER'|translate | packageTitle}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToReceiverstoShareLocation" (onChange)="confirmModify('SEND_TO_SHARE_LOCATION', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px" class="label-span">{{'COMPANY.IS_SEND_SMS_TO_RECEIVERS_TO_SHARE_LOCATION'|translate}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToReceiversWhenRetuningPackages" (onChange)="confirmModify('RETURN', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px" class="label-span">{{'COMPANY.IS_SEND_SMS_TO_RECEIVERS_WHEN_RETURNING_PACKAGES'|translate | packageTitle}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToReceiversWhenPostponingPackages" (onChange)="confirmModify('POSTPONE', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px" class="label-span">{{'COMPANY.IS_SEND_SMS_TO_RECEIVERS_WHEN_POSTPONING_PACKAGES'|translate | packageTitle}}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 custom-switch">
            <div class="package-switch-container">
                <div class="toggle-container">
                    <p-inputSwitch [(ngModel)]="isSendSmsToReceiversWhenDriverFail"
                                   (onChange)="confirmModify('FAIL', $event)"></p-inputSwitch>
                    <span style="margin-right: 10px"
                          class="label-span">{{'COMPANY.IS_SEND_SMS_TO_RECEIVERS_WHEN_DRIVER_FAILS'|translate | packageTitle}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
