<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <p-overlayPanel #customDropDownList
                    [dismissable]="true"
                    class="custom-drop-down-list"
                    (onHide)="onHideList()"
                    styleClass="{{styleClass}}"
                    [showCloseIcon]="false">
        <div class="list-container">
            <div class="search-container" *ngIf="isSearchable">
                <input placeholder="{{placeHolder | translate}}" type="text" appInputDebounced
                       (inputDebounced)="onSearch($event)">
            </div>
            <ng-container *ngIf="isLoading">
                <div class="loader-container">
                    <i class="fas fa-spinner fa-pulse"></i>
                </div>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <div *ngFor="let item of list; let i = index" class="list-item"
                     [ngClass]="{'selected-item': selectedValue === item.value}" (click)="onSelect(item)">
                    <div class="row item-content d-flex cursor-pointer">
                        <div class="cursor-pointer label">{{item.label }}</div>
                        <div class="icon" *ngIf="selectedValue === item.value">
                            <img src="../../assets/icons/check.png" alt="check"/>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </p-overlayPanel>

    <div class="custom-drop-down-container">
        <div class="select-dropdown-item" (click)="showList($event)">
            <div class="label">
                <span *ngIf="!isSearchable">{{selectedValue | translate}}</span>
                <span *ngIf="isSearchable">{{selectedLabel}}</span>
            </div>
            <div class="caret-down-icon" *ngIf="!disabled">
                <img src="../../assets/icons/down-arrow.png" alt=""/>
            </div>
        </div>
    </div>

</div>
