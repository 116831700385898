<p-toast></p-toast>
<div class="close-icon" (click)="closeModal()"><img src="../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="row d-inline-flex main-container">
        <div class="d-flex flex-column w-100 table-container">
            <div class="shipment-body sub-customers-table">
                <p-table appPTableRtlFix dataKey="id" #table [value]="historyData"
                         paginatorDropdownAppendTo="body"
                         styleClass="h-100 history-table"
                         [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                         [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="false"
                         [responsive]="true"
                         [rows]="pageSize" [totalRecords]="totalRecords"
                         [rowsPerPageOptions]="rowsPerPageOptions"
                         [(first)]="first">
                    <ng-template pTemplate="emptymessage">
                        <app-no-data-available-component>
                        </app-no-data-available-component>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <th>{{'MEMBER.USER_NAME' | translate}}</th>
                        <th>{{'MEMBER.ADDRESS' | translate}}</th>
                        <th>{{'MEMBER.PHONE_NUMBER' | translate}}</th>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-expanded="expanded">
                        <tr>
                            <td>
                                <div class="text-value">
                                    {{item.username}}
                                </div>
                            </td>
                            <td>
                                <div class="date-value">
                                    {{item.address.city }} - {{item.address.village }}
                                    - {{item.address.addressLine1}} {{(item.address.addressLine2) ? ("- " + item.address.addressLine2) : ''}}
                                </div>
                            </td>
                            <td>
                                <div class="text-value">
                                    <ng-container *ngIf="item.phone1">
                                        <a href="tel: {{item.phone1}}"> {{item.phone1}} </a>
                                    </ng-container>
                                    <ng-container *ngIf="item.phone1 && item.phone2">/</ng-container>
                                    <ng-container *ngIf="item.phone2">
                                        <a href="tel: {{item.phone2}}"> {{item.phone2}} </a>
                                    </ng-container>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="row bottom-actions">
                <div class="actions d-inline-flex">
                    <div class="done-action flex-2d-center"
                         (click)="closeModal()">{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
