import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {UserService} from './user.service';


@Injectable()
export class DateRangePickerService {
    dateRangPickerAR = {
        direction: 'rtl',
        noValueSelected: 'التقويم',
        all: 'الكل',
        'format': 'DD/MM/YYYY',
        'separator': ' - ',
        'applyLabel': 'تم',
        'cancelLabel': 'إلغاء',
        'fromLabel': 'من',
        'toLabel': 'الى',
        'customRangeLabel': 'تخصيص',
        'weekLabel': 'W',
        'daysOfWeek': [
            'احد',
            'اثنين',
            'ثلاثاء',
            'اربعاء',
            'خمبس',
            'جمعة',
            'سبت'
        ],
        'monthNames': [
            'كانون ثاني',
            'شباط',
            'آذار',
            'نيسان',
            'أيار',
            'حزيران',
            'تموز',
            'آب',
            'أيلول',
            'تشرين أول',
            'تشرين ثاني',
            'كانون أول'
        ],
        'firstDay': 1
    };
    dateRangPickerSaudi = {
        direction: 'rtl',
        noValueSelected: 'التقويم',
        all: 'الكل',
        'format': 'DD/MM/YYYY',
        'separator': ' - ',
        'applyLabel': 'تم',
        'cancelLabel': 'إلغاء',
        'fromLabel': 'من',
        'toLabel': 'الى',
        'customRangeLabel': 'تخصيص',
        'weekLabel': 'W',
        'daysOfWeek': [
            'احد',
            'اثنين',
            'ثلاثاء',
            'اربعاء',
            'خمبس',
            'جمعة',
            'سبت'
        ],
        'monthNames': [
            'يناير',
            'فبراير',
            'مارس',
            'ابريل',
            'مايو',
            'يونيو',
            'يوليو',
            'اغسطس',
            'سبتمبر',
            'اكتوبر',
            'نوفمبر',
            'ديسمبر'
        ],
        'firstDay': 1
    };
    dateRangPickerEN = {
        'format': 'DD/MM/YYYY',
        noValueSelected: 'Date',
        all: 'All',
        'separator': ' - ',
        'applyLabel': 'Apply',
        'cancelLabel': 'Cancel',
        'fromLabel': 'From',
        'toLabel': 'To',
        'customRangeLabel': 'Custom',
        'weekLabel': 'W',
        'daysOfWeek': [
            'Su',
            'Mo',
            'Tu',
            'We',
            'Th',
            'Fr',
            'Sa'
        ],
        'monthNames': [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        'firstDay': 1
    };

    constructor(private translateService: TranslateService, private userService: UserService) {
    }

    getDateRangePickerRanges(originalRangeLabel = true, threeYearsAgo = true) {
        const options = [
            'GENERAL.DATE_RANGE_PICKER.ALL',
            'GENERAL.DATE_RANGE_PICKER.TODAY',
            'GENERAL.DATE_RANGE_PICKER.YESTERDAY',
            'GENERAL.DATE_RANGE_PICKER.LAST_2_DAYS',
            'GENERAL.DATE_RANGE_PICKER.LAST_7_DAYS',
            'GENERAL.DATE_RANGE_PICKER.LAST_30_DAYS',
            'GENERAL.DATE_RANGE_PICKER.BEFORE_1_MONTH'
        ];
        if (!originalRangeLabel) {
            options.push('GENERAL.DATE_RANGE_PICKER.CUSTOM');
        }
        let translationArray  = [];
        return this.translateService.get(options).toPromise().then(local => {
            this.translateService.get(options).subscribe(
                (translation) => {
                    translationArray = translation;
                }
            );
            const currentDate = new Date();
            const date = new Date();
            const fromDate: Date = threeYearsAgo ? new Date(date.setFullYear(date.getFullYear() - 3, date.getMonth(), date.getDate()))
                : new Date(0);
            const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            const lastWeekEdgeDates = this.getLastWeekEdgyDates();
            const result = [
                {
                    key: 'ALL',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.ALL'],
                    date: [moment().subtract(3, 'years'), moment()]
                },
                {
                    key: 'TODAY',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.TODAY'],
                    date: [moment(), moment()]
                },
                {
                    key: 'YESTERDAY',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.YESTERDAY'],
                    date: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
                },
                {
                    key: 'LAST_2_DAYS',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.LAST_2_DAYS'],
                    date: [moment().subtract(2, 'days'), moment()]
                },
                {
                    key: 'LAST_7_DAYS',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.LAST_7_DAYS'],
                    date: [moment(lastWeekEdgeDates[0]), moment(lastWeekEdgeDates[1])]
                },
                {
                    key: 'LAST_30_DAYS',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.LAST_30_DAYS'],
                    date: [moment(firstDayOfLastMonth), moment(lastDayOfLastMonth)]
                },
                {
                    key: 'BEFORE_1_MONTH',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.BEFORE_1_MONTH'],
                    date: [moment().subtract(1, 'months'), moment()]
                }
            ];
            if (!originalRangeLabel) {
                result.push({
                    key: 'CUSTOM',
                    label: translationArray['GENERAL.DATE_RANGE_PICKER.CUSTOM'],
                    date: [moment(), moment()]
                });
            }
            return result;
        });
    }

    getDateRangePickerOptions(before = 3, setMinDate = false, setMaxDate = false, autoUpdateInput = true, showCustomRangeLabel = true, beforeEnabled = true, range = '') {
        const companyCreatedDate = this.userService.userInfo.companyCreatedDate;
        const currency = this.userService.getCurrency();
        return this.getDateRangePickerRanges(showCustomRangeLabel).then(ranges => {
            const date = new Date();
            let fromDate, toDate = moment();
            if (range.length && range === 'TODAY') {
                fromDate = moment();
            } else if (range.length && range === 'MONTH') {
                fromDate = moment().subtract(1, 'months');
            } else {
                fromDate = beforeEnabled ? moment().subtract(before, 'years') : moment();
                if (beforeEnabled) {
                    toDate = moment().add(1, 'days');
                }
            }
            return {
                startDate: fromDate,
                endDate: toDate,
                'showCustomRangeLabel': showCustomRangeLabel,
                locale: this.translateService.currentLang === 'ar' && currency !== 'SAR' ? this.dateRangPickerAR : this.translateService.currentLang === 'ar' && currency === 'SAR' ? this.dateRangPickerSaudi : this.dateRangPickerEN,
                ranges,
                autoUpdateInput: autoUpdateInput,
                maxDate: setMinDate ? moment().add(1, 'days') : undefined,
                minDate: setMaxDate ? moment(companyCreatedDate).subtract(1, 'days') : undefined,
            };
        });
    }

    public getLastWeekEdgyDates() {
        const currentDate = new Date();
        const currentDayIndex = currentDate.getDay();
        let startDayOfLastWeek, lastDayOfLastWeek;
        if (currentDayIndex < 5 ) {
            const offset = currentDayIndex + 2;
            lastDayOfLastWeek = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - offset);
        } else if (currentDayIndex === 5 ) {
            lastDayOfLastWeek = new Date(currentDate);
        } else if (currentDayIndex > 5 ) {
            lastDayOfLastWeek = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        }

        startDayOfLastWeek = new Date(lastDayOfLastWeek.getFullYear(), lastDayOfLastWeek.getMonth(), lastDayOfLastWeek.getDate() - 6);

        return [startDayOfLastWeek, lastDayOfLastWeek];
    }
}
