import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {FADE_ANIMATION} from '../../shared/animations/fade-animation';
import {ContainersService} from '../services/containers.service';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {ContainerModel} from '../../shared/models/container.model';
import {NgbActiveModal, NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {NewContainerComponent} from '../new-container/new-container';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Paginator} from 'primeng/paginator';
import {OverlayPanel} from 'primeng/overlaypanel';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from '../../shared/services/shared-service';
import {CONTAINERS_ORDER_BY_LIST} from '../../shipment/services/constants';
import {DriverModel} from '../../shared/models/driver.model';
import {CarriedPackagesComponent} from '../carried-packages/carried-packages.component';
import * as _ from 'lodash';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-containers-management.component',
    templateUrl: 'containers-management.component.html',
    styleUrls: ['containers-management.component.scss'],
    animations: [FADE_ANIMATION]
})
export class ContainersManagementComponent implements OnInit {
    public containers: ContainerModel[] = [];
    public fetchedData: ContainerModel[];
    public page = 0;
    public totalRecords = 0;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;
    public selectedContainers = {};
    public selectedCount = 0;
    public currentLang = '';
    public searchContent = '';
    // public packages: any[];
    public isAllSelected = false;
    public orderByList;
    public triggeredContainer: ContainerModel;
    public printLoader = false;
    public singlePrintLoader = false;
    public getPackagesCountLoader = false;
    public deleteContainerLoader = false;
    public isShowContainerDetails = false;
    public isLoading = false;
    public orderBy = 'CREATED_DATE';
    public viewMode = 'CARDS_MODE';
    public pageSize = this.viewMode === 'TABLE_MODE' ? 14 : 16;
    public debounceUpdateCost;

    public isSearchContent = false;

    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    // public removeCardLoader = false;
    // public getPackagesCountCardLoader = false;


    @ViewChild('paginator', {static: false}) paginator: Paginator;
    @ViewChild('rowActionsPopOver', {static: false}) rowActionsPopOver: NgbPopover;

    constructor(private containerService: ContainersService,
                private modalService: NgbModal,
                private activeModal: NgbActiveModal,
                public translateService: TranslateService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private sharedService: SharedService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.orderByList = this.setOrderByOptions();
        this.loadLazyContainers();
    }
    public onSetShowMode(viewMode) {
        this.viewMode  = viewMode;
        this.pageSize = viewMode === 'TABLE_MODE' ? 14 : 16;
        this.reset();
        this.fetchContainers(true);
    }

    public setOrderByOptions() {
            return CONTAINERS_ORDER_BY_LIST.map((value) => {
                const label = this.translateService.instant(`CONTAINERS_MANAGEMENT.ORDER_BY_LIST.${value}`);
                return {label, value: value.replace('CONTAINERS_', '')};
            });
     }

    public initializePrint() {
        for (const container in this.containers) {
            this.selectedContainers[this.containers[container].id] = false;
        }
    }

    private createQuery() {
        let  result = '?pageSize=' + this.pageSize +  '&page=' + (this.page) + '&orderBy=' + this.orderBy;
        if (this.searchContent) {
            result += '&search=' + this.searchContent;
        }
        return result;
    }
    public onSearch() {
        if (!this.debounceUpdateCost) {
            this.debounceUpdateCost = _.debounce(() => {
                this.page = 0;
                this.containers = [];
                this.loadLazyContainers();
            }, 1000);
        }
        this.debounceUpdateCost();
    }
    public createNewContainer() {
        const modal = this.modalService.open(NewContainerComponent, {
            backdrop: 'static',
            windowClass: 'create-new-container',
            size: 'lg'
        });
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                if (status.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CONTAINER_ADDED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                            }
                        );
                    this.fetchContainers(true);
                }
            }
        ).catch(
            (error) => {
            }
        );
    }
    public removeContainer(containerIndex) {
        this.containers.splice(containerIndex, 1);
    }

    public onRemoveContainer(result) {
        let message = '';
        let yes = '';
        let no = '';
        this.deleteContainerLoader = true;
        this.translateService.get(
            ['ALERTS.REMOVE_CONTAINER', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.REMOVE_CONTAINER'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.containerService.removeContainer(result.container.id).subscribe(
                    (response) => {
                        this.translateService.get(
                            'ALERTS.CONTAINER_REMOVED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                }
                            );
                        this.deleteContainerLoader = false;
                        this.removeContainer(this.containers.indexOf(this.triggeredContainer));
                        this.reset();
                        this.fetchContainers(true);
                        // this.fetchContainers();
                        // this.paginator.changePage(0);
                    }, error => {
                        console.error(error);
                        this.deleteContainerLoader = false;
                    }
                );
            },
            reject: () => {
                this.deleteContainerLoader = false;
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    public onModifyContainer($event: { container: ContainerModel }) {
        this.closeRowActionsPopOver();
        const modal = this.modalService.open(NewContainerComponent, {
            backdrop: 'static',
            windowClass: 'create-new-container',
            size: 'lg'
        });
        modal.componentInstance.container = $event.container;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                if (status.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CONTAINER_UPDATED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                            }
                        );
                    this.reset();
                    this.fetchContainers(true);
                }
            }
        ).catch(
            (error) => {
            }
        );
    }
    public closeRowActionsPopOver() {
        if (this.rowActionsPopOver && this.rowActionsPopOver.isOpen()) {
            this.rowActionsPopOver.close();
        }
    }

    public onPaginate(page) {
        this.page = page;
        this.fetchContainers();
    }

    public onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }

        this.containers.forEach((pkg: any) => {
            pkg.isSelected = this.isAllSelected;
        });
    }
    public onToggleSelectAllContainersCards(state: boolean) {
        this.selectedCount = state ? this.containers.length : 0;

        for (const container in this.containers) {
            this.selectedContainers[this.containers[container].id] = state;
        }
    }
    public getSelectedContainers() {
        const selectedContainers = [];
        if (!this.containers) {
            return [];
        }

        this.containers.forEach(
            (container) => {
                if (container.isSelected) {
                    selectedContainers.push(container);
                }
            }
        );
        return selectedContainers;
    }


    public onToggleChildSelect(state: boolean) {
        if (state)
            this.selectedCount++;
        else
            this.selectedCount--;
    }
    public printCards() {
        this.printLoader = true;
        const containerToPrint = [];
        const containers = Object.keys(this.selectedContainers);
        for (const container of containers) {
            if (this.selectedContainers[container]) {
                containerToPrint.push(parseInt(container, 10));
            }
        }
        this.containerService.printContainers(containerToPrint).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.printLoader = false;
        }, error => {
            this.printLoader = false;
            console.error(error);
        });
    }
    public print(result = null) {
        let selectedContainers = [];
        if (result) {
            // print single
            this.singlePrintLoader = true;
            selectedContainers.push(result.container.id);
        } else {
            if (this.viewMode === 'CARDS_MODE') {
                this.printCards();
                return;
            }
             this.printLoader = true;
             selectedContainers = this.getSelectedContainers().map(containerItem => containerItem.id);
        }
        this.containerService.printContainers(selectedContainers).subscribe((res: any) => {
            window.open(res.url, '_blank');
            if (result) {
                this.singlePrintLoader = false;
            } else {  this.printLoader = false; }
        }, error => {
            if (result) {
                this.singlePrintLoader = false;
            } else {  this.printLoader = false; }
            console.error(error);
            }
        );
    }

    public checkSelectedContainers(): boolean {
        return this.selectedContainers && Object.keys(this.selectedContainers).length > 0;
    }

    public  getPackagesCount(result) {
        this.getPackagesCountLoader = true;
        this.containerService.getContainerPackagesNo(result.container.id).subscribe(
            (response: any) => {
                this.translateService.get(['MANAGE_CONTAINERS.NUMBER_OF_SHIPMENTS']
                ).subscribe((res: string) => {
                    this.confirmationService.confirm({
                        message: res['MANAGE_CONTAINERS.NUMBER_OF_SHIPMENTS'] + ' :' +  response.packagesNumber ,
                        rejectVisible: false,
                        acceptLabel: 'ok'
                    });
                    return;
                });
                this.getPackagesCountLoader = false;
            } , error => {
                this.getPackagesCountLoader = false;
                console.error(error);
            }
        );
    }
    public copyMessage($event, list?, containerNumber?) {
        this.sharedService.copyMessage($event, null, containerNumber, 'CONTAINERS_MANAGEMENT.COPY_CONTAINER_NUMBER_MESSAGE');
     }
    stopProp(event) {
        event.stopPropagation();
    }
    public reset() {
        this.containers = [];
        this.page = 0;
    }
    public onSortByChanged() {
        this.reset();
        this.fetchContainers(true);
    }

    public getDriverName(driverObject: DriverModel) {
        return this.containerService.getDriverName(driverObject);
    }
    public  setTriggeredContainer(container) {
        this.triggeredContainer = container;
    }
    public showContainerDetails(container) {
        this.triggeredContainer = this.viewMode === 'TABLE_MODE' ?  container : container.container;
        this.isShowContainerDetails = true;
    }
    public onHideContainerDetails(event) {
        this.isShowContainerDetails = event;
    }
    public openCarriedPackagesDialog(containerId) {
        let className = 'container-carried-packages';
        if (this.currentLang === 'en') {
            className = 'container-carried-packages-en';
        }
        const modal = this.modalService.open(CarriedPackagesComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isReceiverFromDriver = true;
        modal.componentInstance.containerId = containerId;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public fetchContainers(forceFetch = false) {
        if (forceFetch) {
            this.page = 1;
        }
        const query = this.createQuery();
        this.fetchedData = [];
        if (this.containers.length === this.totalRecords && this.page > 1 && !forceFetch) {
            return;
        }
        this.isLoading = true;
        this.spinnerState = SpinnerState.LOADING;
        this.containerService.fetchContainers(query).subscribe(
            (response: { vehicles: any[], totalRecordsNo: number }) => {
                this.fetchedData = response.vehicles;
                if (forceFetch) {
                    this.containers = this.fetchedData;
                } else {
                    this.containers = [...this.containers, ...this.fetchedData ];
                }
                // this.containers = response.vehicles;
                this.totalRecords = response.totalRecordsNo;
                this.spinnerState = SpinnerState.LOADED;
                if (!(this.containers && this.containers.length > 0)) {
                    this.spinnerState = SpinnerState.EMPTY;
                }
                this.initializePrint();
                this.isLoading = false;
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
                this.isLoading = false;
            }
        );

        // // if (this.packages.length === this.totalPackages) {
        // //     return;
        // // }
        // this.spinnerState = SpinnerState.LOADING;
        // this.usersService.getDriverPackages(this.driverId, URLQuery, {}).subscribe(
        //     (response: { data: any[], totalRecordsNo: number }) => {
        //         this.fetchedData = response.data;
        //         this.packages = [...this.packages, ...this.fetchedData ];
        //         this.totalPackages = response.totalRecordsNo;
        //         if (this.totalPackages > 0) {
        //             this.spinnerState = SpinnerState.LOADED;
        //         } else {
        //             this.spinnerState = SpinnerState.EMPTY;
        //         }
        //     }, error => {
        //         console.error(error);
        //         this.spinnerState = SpinnerState.FAILED;
        //     }
        // );
    }
    public loadLazyContainers() {
        this.page++;
        this.fetchContainers();
    }
    @HostListener('scroll', ['$event'])
    onScrollTable(event: any) {
        // if (event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight)
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadLazyContainers();
        }
    }


    showVehicleTypes(event, panel: OverlayPanel) {
        panel.toggle(event);
    }

    public toggleIsSearchContent(value) {
        this.isSearchContent = value;
    }
}
