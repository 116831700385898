export const STORAGE_KEYS = {
    LANGUAGE: 'lang',
    LANGUAGES: {
        ENGLISH: 'en',
        ARABIC: 'ar'
    },
    TOKEN_KEY: 'token',
    USERNAME: 'name',
    CUSTOMER_ID: 'id',
    COMPANY_NAME: 'company-name',
    IS_SUPER_ADMIN: 'is-super-admin',
    UUID: 'UUID',
    CURRENCY: 'Currency'
};
