<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="bank-transfers-view h-100 d-flex flex-column p-2">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="header-filters d-flex flex-row"
         style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
        <div class="cod-title flex-1">
            {{"BANK_TRANSFERS.BANK_TRANSFERS" | translate}}
        </div>
    </div>
    <div class="bank-transfers-table flex-1 overflow-auto">
        <p-table #transfersTable dataKey="id" #table [value]="transfers"
                 styleClass="customers-table flex-table" [rowsPerPageOptions]="rowsPerPageOptions"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto" paginatorDropdownAppendTo="body"
                 [lazy]="true" (onLazyLoad)="initTransfersLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="10" [totalRecords]="totalRecords">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 6%"> {{"BANK_TRANSFERS.ID" | translate}}</th>
                    <th style="width: 5%">{{"BANK_TRANSFERS.TYPE"|translate}}</th>
                    <th style="width: 6%">{{"BANK_TRANSFERS.MONEY_AMOUNT"|translate}}</th>
                    <th>{{"BANK_TRANSFERS.HOLDER_NAME"|translate}}</th>
                    <th>{{"BANK_TRANSFERS.APPROVED_BY"|translate}}</th>
                    <th style="width: 5%">{{"BANK_TRANSFER.CASH"|translate}}</th>
                    <th>{{"BANK_TRANSFERS.CHEQUE"|translate}}</th>
                    <th>{{"BANK_TRANSFERS.BANK_TRANSFER"|translate}}</th>
                    <th style="width: 8%">{{"BANK_TRANSFERS.CREATED_DATE"|translate}}</th>
                    <th style="width: 7%">{{"ACTIONS.APPROVE"|translate}}</th>
                    <th style="width: 7%">{{"ACTIONS.REJECT"|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transfer let-expanded="expanded">
                <tr class="cursor-pointer" (click)="goToCOD(transfer)">
                    <td style="width: 6%">{{transfer.id}}</td>
                    <td style="width: 5%">{{transfer.type}}</td>
                    <td style="width: 6%">{{transfer.moneyAmount}}</td>
                    <td>{{transfer.holderName}}</td>
                    <td>{{transfer.approverName}}</td>
                    <td style="width: 5%">{{transfer.cash}}</td>
                    <td>{{transfer.cheque}}<br>
                        {{'BANK_TRANSFERS.DOCUMENT'|translate}}:{{transfer.chequeDocumentNumber}}
                    </td>
                    <td>{{transfer.bankTransfer}} <br>
                        {{'BANK_TRANSFERS.DOCUMENT'|translate}}:{{transfer.bankTransferDocumentNumber}}
                    </td>
                    <td style="width: 8%">{{transfer.createdDate|date:'dd/MM/yyyy'}}</td>
                    <td style="text-align: center;width: 7%" class="cursor-pointer">
                        <i *ngIf="transfer.type==='MOB'"
                           (click)="approveTransfer(transfer,$event)"
                           class="fas fa-check-circle fa-2x"></i>
                    </td>
                    <td style="text-align: center;width: 7%" class="cursor-pointer">
                        <i *ngIf="transfer.type==='MOB'"
                           (click)="rejectTransfer(transfer,$event)"
                           class="fas fa-ban fa-2x"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
