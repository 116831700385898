import {Component, Input, OnInit} from '@angular/core';
import {EmailReplyModel} from '../models/email-reply';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-email-reply',
  templateUrl: './email-reply.component.html',
  styleUrls: ['./email-reply.component.scss']
})
export class EmailReplyComponent implements OnInit {
    @Input() emailReply: EmailReplyModel;
    public isMe = true;

    constructor(public userService: UserService) {
    }

    ngOnInit() {
        this.isMe = this.userService.userInfo.firstName === this.emailReply.senderName;
    }

}
