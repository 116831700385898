import {Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {UsersService} from '../../../users/services/users.service';
import {FormBuilder, Validators} from '@angular/forms';
import {CompaniesService} from '../../services/companies.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';


@Component({
    selector: 'app-company-billing-payment',
    templateUrl: './company-billing-payment.component.html',
    styleUrls: ['./company-billing-payment.component.scss']
})
export class CompanyBillingPaymentComponent implements OnInit{
    public isLoading = false;
    public form;
    public companiesList;
    public startNotificationDate;

    constructor(private activeModal: NgbActiveModal,
                private usersService: UsersService,
                private datePipe: DatePipe,
                private formBuilder: FormBuilder,
                private companyService: CompaniesService,
                private translateService: TranslateService,
                private messageService: MessageService) {
    }
    ngOnInit() {
        this.initInfo();
        this.fetchCompaniesList();
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.isLoading = false;
            this.activeModal.close({isSuccess: false});
        }
    }
    public closeModal() {
        this.isLoading = false;
        this.activeModal.close({isSuccess: false});
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            company: ['', Validators.required],
            paymentAmount: [0]
        });
    }
    public finish() {
        this.isLoading = true;
        const {company, paymentAmount} = this.form.getRawValue();
             this.companyService
                .addBillingPaymentForSpecificCompany(String(company.id), this.startNotificationDate , Number(paymentAmount))
                .subscribe(
                    (res: any) => {
                        this.translateService.get(
                            'COMPANY.BILLING_PAYMENT_ADDED_SUCCESSFULLY'
                        ).subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data + company.name});
                            }
                        );
                        this.isLoading = false;
                        this.activeModal.close({isSuccess: true});
                    }, error => {
                        this.isLoading = false;
                        this.closeModal();
                    }
                );
    }
    getCompanies($event) {
        const query = '?page=1&pageSize=100&search=' + $event.query;
        this.companyService.getCompaniesDropdown(query).subscribe(
            (response: any) => {
                this.companiesList = response;
                this.companiesList = this.companiesList.map((company) => {
                    company['name'] = company.name;
                    return company;
                });
            }, (error) => {
            }
        );
    }

    private fetchCompaniesList() {
        this.companyService.getCompaniesDropdown().subscribe((response: any) => {
        this.companiesList = response;
        });
    }

}
