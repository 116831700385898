import {Component} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from "../../accounting-constants";
import {AccountingRowActionsSettings} from "../../models/row-actions-setting.model";
import {TemplateTopActionsSettingsModel} from "../../models/template-top-actions-settings.model";
import {AccountingTemplateFiltersModel} from "../../models/accounting-template-filters.model";
@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {

    title = ACCOUNTING_CONSTANTS.INVOICES.TITLE;
    info = ACCOUNTING_CONSTANTS.INVOICES.INFO;
    apiUrl = ACCOUNTING_CONSTANTS.INVOICES.API;
    rowActionsSettings: AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.INVOICES.ROW_ACTIONS_SETTINGS;
    topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.INVOICES.TOP_ACTIONS_SETTINGS;
    filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.INVOICES.FILTERS_SETTINGS;
    dateFiltersList = ACCOUNTING_CONSTANTS.INVOICES.DATE_FILTERS_LIST;
}
