<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="shipping-lines-container">
    <h2>{{'SHIPPING_LINES.SHIPPING_LINES'|translate}}</h2>
    <div class="shipping-lines-inner-container">
        <p-tabView (onChange)="onTabChange($event)">
            <p-tabPanel [header]="'SHIPPING_LINES.SENDER_SHIPPING_LINES'|translate">
                <ng-template pTemplate="content">
                    <div class="row manage-lines-container existing-line-container">
                        <div class="col-lg-3 col-md-4 col-sm-12 col-12 lines-dropdown">
                            <p-dropdown #dropdownEl styleClass="shipping-lines-dropdown" [style]="{'width':'100%'}" (onClick)="handlePosition(dropdownEl)"
                                        [(ngModel)]="selectedSenderShipmentLine" optionLabel="name" [panelStyleClass]="'dropdown-fix-position'"
                                        [panelStyle]="{}" [options]="senderShipmentLines" filter="true"
                                        (onChange)="onLineSelected()">
                            </p-dropdown>
                        </div>
                        <div>

                        </div>
                        <button (click)="editLine()"
                                class="col-lg-2 col-md-3 col-sm-6 col-12 edit-line-button">{{'SHIPPING_LINES.EDIT_LINE'|translate}}</button>
                        <button (click)="addNewLineButton()"
                                class="col-lg-2 col-md-3 col-sm-6 col-12 new-line-button">{{'SHIPPING_LINES.NEW_LINE'|translate}}</button>
                        <div class="drivers-list-dropdown">
                            <app-users-list-dropdown-component
                                    [showOldAutoComplete]="true"
                                    *ngIf="!isAllowAddingMoreThanOneDriverToShippingLine"
                                    [user]="driver"
                                    [getDriverById]="false"
                                    [selectedUser]="driver"
                                    [isDriver]="true"
                                    (userChanged)="onDriverSelected($event)">
                            </app-users-list-dropdown-component>
                            <button *ngIf="isAllowAddingMoreThanOneDriverToShippingLine"
                                    [ngClass]="{'disabled-btn disabled': !senderShipmentLines || loadingShippingLine}"
                                    (click)="openManageDriverForShippingLine()"
                                    class="col-lg-2 col-md-3 col-sm-6 col-12 new-line-button">{{'ON_DEMAND.SHIPPING_LINES.MANAGE_DRIVERS'|translate}}</button>
                        </div>

<!--                        <div class="col-lg-3 col-md-2 col-sm-6 col-12"></div>-->
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="selectedVillagesUrl" [selectedItemsList]="selectedSenderVillages"
                                       (onItemChecked)="onVillageChecked($event)" [showArabic]="false"
                                       [title]="'SHIPPING_LINES.SELECTED_VILLAGES'" [allowEdit]="false"
                                       [allowAssign]="true" dataKey="villages"></app-zones>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="regionsURL" (onItemChosen)="onRegionChosen($event)"
                                       [title]="'ADMINISTRATION.REGIONS'" [allowEdit]="false"
                                       [allowAssign]="false"></app-zones>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="citiesURL" (onItemChosen)="onCityChosen($event)" [parentID]="regionID"
                                       [title]="'ADMINISTRATION.CITIES'" [allowEdit]="false"
                                       [allowAssign]="false"></app-zones>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="villagesURL" [parentID]="cityID" [title]="'ADMINISTRATION.VILLAGES'"
                                       [selectedItemsList]="selectedSenderVillages"
                                       [allActions] = "true"
                                       [cityId] = "cityID"
                                       [shippingLineId] = "selectedSenderShipmentLine?.id"
                                       (onSelectAll)="onLineSelected()"
                                       (onItemChecked)="onVillageChecked($event)"
                                       [allowEdit]="false"></app-zones>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel [header]="'SHIPPING_LINES.RECEIVER_SHIPPING_LINES'|translate">
                <ng-template pTemplate="content">
                    <div class="row manage-lines-container existing-line-container" style="text-align: center">
                        <div class="col-lg-3 col-md-4 col-sm-12 col-12 lines-dropdown">
                            <p-dropdown styleClass="shipping-lines-dropdown" [style]="{'width':'100%'}"
                                        [(ngModel)]="selectedReceiverShipmentLine" optionLabel="name"
                                        [panelStyle]="{}" [options]="receiverShipmentLines" filter="true"
                                        (onChange)="onLineSelected()">
                            </p-dropdown>
                        </div>
                        <div>
                        </div>
                        <h6 class="col-lg-2 col-md-3 col-sm-6 col-12" style="margin-left: 5px">{{'SHIPPING_LINES.EXPECTED_DELIVERY_DAYS_NUMBER'|translate}} : {{expectedDeliveryDaysNumber}}</h6>
                        <button (click)="editLine()"
                                class="col-lg-2 col-md-3 col-sm-6 col-12 edit-line-button">{{'SHIPPING_LINES.EDIT_LINE'|translate}}</button>
                        <button (click)="addNewLineButton()"
                                class="col-lg-2 col-md-3 col-sm-6 col-12 new-line-button">{{'SHIPPING_LINES.NEW_LINE'|translate}}</button>
                        <div class="col-lg-3 col-md-2 col-sm-6 col-12"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="selectedVillagesUrl" [selectedItemsList]="selectedReceiverVillages"
                                       (onItemChecked)="onVillageChecked($event)" [showArabic]="false"
                                       [title]="'SHIPPING_LINES.SELECTED_VILLAGES'" [allowEdit]="false"
                                       [allowAssign]="true" dataKey="villages"></app-zones>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="regionsURL" (onItemChosen)="onRegionChosen($event)"
                                       [title]="'ADMINISTRATION.REGIONS'" [allowEdit]="false"
                                       [allowAssign]="false"></app-zones>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="citiesURL" (onItemChosen)="onCityChosen($event)" [parentID]="regionID"
                                       [title]="'ADMINISTRATION.CITIES'" [allowEdit]="false"
                                       [allowAssign]="false"></app-zones>
                        </div>
                        <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="villagesURL" [parentID]="cityID" [title]="'ADMINISTRATION.VILLAGES'"
                                       [selectedItemsList]="selectedReceiverVillages"
                                       [allActions] = "true"
                                       [cityId] = "cityID"
                                       [shippingLineId] = "selectedReceiverShipmentLine?.id"
                                       (onSelectAll)="onLineSelected()"
                                       (onItemChecked)="onVillageChecked($event)"
                                       [allowEdit]="false"></app-zones>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
