<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="sort-table-view d-flex flex-column h-100">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div>
        <div class="sort-header d-flex flex-row"
             style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
            <div class="sort-title flex-1">
                {{"ACCOUNTING.SORT_PACKAGES" | translate}}
            </div>
            <div class="cod-title flex-1">
                {{"SHIPMENT.NUMBER_OF_SCANNED_PACKAGES" | translate}}: {{sortedPackages.length}}
            </div>
        </div>
    </div>

    <div class="customer-sort-table flex-1 overflow-auto">
        <p-table #packagesTable dataKey="id" #table [value]="sortedPackages"
                 styleClass="customers-table flex-table" [scrollable]="true" scrollHeight="auto" [responsive]="true">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;"> {{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th> {{"ADD_SHIPMENT.INVOICE_NUMBER" | translate}}</th>
                    <th> {{"MANAGE_SHIPMENTS.COD" | translate}}
                    <th> {{"MANAGE_SHIPMENTS.COST" | translate}} </th>
                    <th *ngIf="isSorting">{{"ACCOUNTING.REMOVE_PACKAGE"|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr>
                    <td style="width: 150px;text-align: center">
                        <img height="50px" class="barcode-image" [src]="package.barcodeImage">
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.barcode)"></i>
                        </span>
                    </td>
                    <td>{{package.invoiceNumber}}</td>
                    <td>{{package.cod}}</td>
                    <td pEditableColumn [pEditableColumn]="package"
                        [pEditableColumnField]="'cost'">
                        <app-edit-cost [package]="package"></app-edit-cost>
                    </td>
                    <td *ngIf="isSorting">
                        <div class="actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackage(package)">
                                <i class="fas fa-minus-circle"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="table-actions">
        <div *ngIf="!isSorting" class="add-to-container green cursor-pointer" (click)="bulkSort()">
            {{"ACCOUNTING.BULK_SORT" | translate}}
        </div>
        <div *ngIf="!isSorting" class="add-to-container green cursor-pointer" (click)="startSorting()">
            {{"ACCOUNTING.START_SORTING" | translate}}
        </div>
        <div *ngIf="isSorting" class="add-to-driver-container red cursor-pointer" (click)="resetSorting()">
            {{"ACCOUNTING.RESET" | translate}}
        </div>
        <div *ngIf="isSorting" class="add-to-driver-container green cursor-pointer" (click)="enterBarcode()">
            {{"ACCOUNTING.ENTER_BARCODE" | translate}}
        </div>
        <div *ngIf="isSorting" class="add-to-driver-container green cursor-pointer" (click)="finishSorting()">
            {{"ACCOUNTING.FINISH_SORTING" | translate}}
        </div>
    </div>
</div>
