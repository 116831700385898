<div class="branch-item-container">
    <div class="row branch-information">
        <div class="col-12 shelf-barcode" style="text-align: center">
            <div style="margin-bottom: 10px; text-align: unset">
                <input type="checkbox" [(ngModel)]="selectedShelves[shelf.id]"/>
            </div>
            <img style="margin: 0px -17px;" [src]="shelf.barcodeImage">
        </div>
        <div class="branch-info col-12" style="text-align: center">
            <div class="title-address">
                <div class="branch-name">
                    {{shelf?.barcode}} {{shelf.isSafe ? ('BRANCHES.IS_SAFE' | translate) : ''}}
                </div>
                <div class="branch-address">
                    {{shelf?.label}}
                </div>
                <div class="branch-address">
                    {{shelf?.destinationRegion}},{{shelf?.destinationCity}}
                </div>
            </div>
        </div>
        <div class="actions-buttons">
            <div class="call-button action-button cursor-pointer" (click)="modifyShelf()">
                {{"ACTIONS.MODIFY" | translate}}
            </div>
            <div class="call-button action-button cursor-pointer" (click)="goToShelfPackages()">
                {{"BRANCHES.PACKAGES" | translate}}
            </div>
            <div class="remove-button action-button cursor-pointer" (click)="removeShelf()">
                {{"ACTIONS.REMOVE" | translate}}
            </div>
        </div>
    </div>
</div>
