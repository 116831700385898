import {TripsBetweenCitiesService} from '../services/trips-between-cities.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ZonesService} from '../../administration/services/zones.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UserService} from '../../shared/services/user.service';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DAYS} from '../services/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {ServiceTypesService} from '../../administration/services/service-types.service';
import {ContainersService} from '../../containers/services/containers.service';
import {TranslateService} from '@ngx-translate/core';
import {PricingService} from '../../shippings-rate/services/pricing.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ParcelService} from '../../administration/services/parcel.service';


@Component({
    selector: 'app-trips-between-cities-v1',
    templateUrl: './trips-between-cities-v1.component.html',
    styleUrls: ['./trips-between-cities-v1.component.scss']
})

export class TripsBetweenCitiesV1Component implements OnInit {

    currentLang = '';
    isLoading = false;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    daysList = [];
    destinationsForm: FormGroup;
    tripForm: FormGroup;

    fromCity;
    toCity;

    trips: any[] = [];
    regionOptions: any;
    showNewTrip = false;


    showDetailsTable = true;
    filterFormOn = false;
    @ViewChild('tripsFiltersMenu') tripsFiltersMenu;
    filtersForm: FormGroup;

    addTripsForms = [];
    parcelsOptions = [];

    constructor(private translateService: TranslateService,
                private pricingService: PricingService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private containersService: ContainersService,
                private serviceTypesService: ServiceTypesService,
                private formBuilder: FormBuilder,
                private zonesService: ZonesService,
                private changeDetectorRef: ChangeDetectorRef,
                private route: ActivatedRoute,
                private router: Router,
                private parcelService: ParcelService,
                private datePipe: DatePipe,
                private tripsBetweenCitiesService: TripsBetweenCitiesService,
                private authenticationService: AuthenticationService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.setDaysList();
        this.setParcelsList();
        this.getTrips();
        this.currentLang = this.translateService.currentLang;
        this.initForms();
        this.initFiltersForm();

    }

    addSubTripAddForm() {
        this.addTripsForms.push(
            {form: this.formBuilder.group({
                    days: [null, Validators.required],
                    tripName: [null, Validators.required],
                    capacity: [null, Validators.required],
                    parcelType: [null, Validators.required],
                    fromTime: [null, Validators.required],
                    toTime: [null, Validators.required],
                    cost: [null, Validators.required]
                })}
        );
    }

    getTrips() {
        this.isLoading = true;
        this.trips = [];
        this.tripsBetweenCitiesService.getTrips(this.createQuery()).subscribe(
            (res: any) => {
                res.forEach(
                    trip => {
                        const subTrips = [];
                        trip.trips.forEach(
                            (subTrip) => {

                                subTrips.push({
                                    subTripForm: this.formBuilder.group({
                                        days: [subTrip.days, Validators.required],
                                        fromTime: [subTrip.fromTime ? moment(subTrip.fromTime, 'HH:mm').toDate() : null, Validators.required],
                                        toTime: [subTrip.toTime ? moment(subTrip.toTime, 'HH:mm').toDate() : null, Validators.required],
                                        cost: [subTrip.cost, Validators.required],
                                        tripName: [subTrip.tripName, Validators.required],
                                        capacity: [subTrip.capacity, Validators.required],
                                        parcelType: [subTrip.parcelTypeId, Validators.required]
                                    }), id: subTrip.id
                                });
                            }
                        );
                        const tripItem = {
                            ...trip,
                            destinationsForm: this.formBuilder.group({
                                fromCity: [{label: trip.fromCityName, value: trip.fromCityId}, Validators.required],
                                toCity: [{label: trip.toCityName, value: trip.toCityId}, Validators.required]
                            }),
                            trips: subTrips
                        };
                        this.trips.push(tripItem);
                    }
                );
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }


    private initForms() {
        this.addSubTripAddForm();
        this.destinationsForm = this.formBuilder.group({
            fromCity: [null, Validators.required],
            toCity: [null, Validators.required]
        });
    }


    getRegions(query) {
        let param = '?page=1&pageSize=20';
        if (query) {
            param += '&search=' + query;
        }
        const getRegions = this.zonesService.getCitiesList(param);
        getRegions.subscribe(
            response => {
                this.regionOptions = response['data'].map(region => {
                    return {
                        label: this.currentLang === 'en' ? region.name : region.arabicName,
                        value: region
                    };
                });
            }
        );
    }


    setDaysList() {
        this.daysList = DAYS.map(
            (day) => {
                return {label: this.translateService.instant(`TRIPS_BETWEEN_CITIES.DAYS.${day}`), value: day};
            }
        );
    }
    setParcelsList() {
        this.parcelService.getParcels().subscribe(
            (res: any) => {
                this.parcelsOptions = res.data.map((parcel) => {
                    return {
                        ...parcel,
                        label: this.translateService.currentLang === 'ar' ? parcel.arabicType : parcel.type,
                        value: parcel.id
                    };
                });
                this.parcelsOptions.unshift({label: '', value: null});
            }, error =>  {
                console.error(error);
            }
        );

    }


    addTrip(form) {
        this.isLoading = true;
        const destinations = this.destinationsForm.getRawValue();
        const tripData = form.getRawValue();
        const body = {
            fromCityId: destinations.fromCity.value.id,
            toCityId: destinations.toCity.value.id,
            fromTime: tripData.fromTime,
            toTime: tripData.toTime,
            days: tripData.days,
            cost: tripData.cost,
            tripName: tripData.tripName,
            capacity: tripData.capacity,
            parcelTypeId: tripData.parcelType,
        };
        this.tripsBetweenCitiesService.addTrip(body).subscribe(
            () => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
                this.addSubTripAddForm();

            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    editSubTrip(destinationsForm, editForm, Id) {
        this.isLoading = true;
        const destinationsData = destinationsForm.getRawValue();
        const subTripData = editForm.getRawValue();
        const body = {
            fromCityId: destinationsData.fromCity.value,
            toCityId: destinationsData.toCity.value,
            fromTime: subTripData.fromTime,
            toTime: subTripData.toTime,
            days: subTripData.days,
            cost: subTripData.cost,
            tripName: subTripData.tripName,
            capacity: subTripData.capacity,
            parcelTypeId: subTripData.parcelType
        };

        this.tripsBetweenCitiesService.editTrip(body, Id).subscribe(
            () => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
            }, err => {
                this.isLoading = false;
                console.error(err);
            }
        );
    }

    deleteSubTrip(Id) {
        this.tripsBetweenCitiesService.deleteSubTrip(Id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
            }, error => {
                console.error(error);
            }
        );
    }
    showFiltersMenu(event) {
        this.tripsFiltersMenu.toggle(event);
        this.filterFormOn = true;
        event.stopPropagation();
    }
    onHideFiltersMenu() {
        this.filterFormOn = false;
    }

    initFiltersForm() {
        this.filtersForm = this.formBuilder.group({
            fromCity: '',
            toCity: '',
            days: [],
            fromTime: '',
            toTime: '',
            cost: ''
        });
    }

    resetFilters($event) {
        this.filtersForm.reset();
        this.getTrips();
        this.tripsFiltersMenu.hide($event);
    }

    applyFilters($event) {
        this.getTrips();
    }
    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
    }

    private createQuery() {
        if (!this.filtersForm) {
            return {};
        }
        const reqParams = this.filtersForm.getRawValue();
        const newReq: any = {};
        if (reqParams.fromCity && reqParams.fromCity.value) {
            newReq.fromCity = reqParams.fromCity.value.id;
        }
        if (reqParams.toCity && reqParams.toCity.value) {
            newReq.toCity = reqParams.toCity.value.id;
        }
        if (reqParams.days) {
            newReq.days = reqParams.days;
        }
        if (reqParams.cost || reqParams.cost === 0) {
            newReq.cost = reqParams.cost;
        }
        if (reqParams.fromTime) {
            newReq.fromTime = this.transformDate(reqParams.fromTime);
        }
        if (reqParams.toTime) {
            newReq.toTime = this.transformDate(reqParams.toTime);
        }
        return newReq;

    }

    deleteMainTrip(form) {
        const data = form.getRawValue();
        const body = {fromCityId: data.fromCity.value , toCityId: data.toCity.value};
        this.tripsBetweenCitiesService.deleteMainTrip(body).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
            }, err => {
                console.error(err);
            }
        );
    }
}
