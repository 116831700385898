import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {COMPANY_NOTIFICATIONS_CONSTANT} from '../company-notifications-constants';
import {ADMINISTRATION_CONSTANTS} from '../../administration/administration-constants';


@Injectable({providedIn: 'root'})
export class CompanyNotificationsService {

    constructor(private httpClient: HttpClient) {
    }

    public editModemStatus(modemId) {
        return this.httpClient.put(COMPANY_NOTIFICATIONS_CONSTANT.CHANGE_MODEM_STATUS.replace('{modem-id}', modemId), {});
    }

    public getNotificationSettings() {
        return this.httpClient.get(COMPANY_NOTIFICATIONS_CONSTANT.GET_NOTIFICATIONS_EVENTS);
    }

    public updateEvents(template, settingId) {
        return this.httpClient.put(COMPANY_NOTIFICATIONS_CONSTANT.UPDATE_EVENT
            .replace('{settingId}', settingId), {template: template});
    }
    public getSiteTags() {
        return this.httpClient.get(COMPANY_NOTIFICATIONS_CONSTANT.GET_SITE_TAGS);
    }
    public setSMSGateWaySettings(body) {
        return this.httpClient.put(COMPANY_NOTIFICATIONS_CONSTANT.SMS_GATEWAY_SETTINGS,  body);
    }
    public getTemplate(event) {
        return this.httpClient.get(COMPANY_NOTIFICATIONS_CONSTANT.GET_SMS_TEMPLATE + '?event=' + event);
    }
    public sendSMSToReceiverOfDriverPkgs(driverId, template) {
         return this.httpClient.post(COMPANY_NOTIFICATIONS_CONSTANT.SEND_SMS_TO_RECEIVER_OF_DRIVER_PACKAGES.replace('{driverId}', driverId),
            {template: template});
    }
    public sendSMSToCustomers(ids, template,  isSendToAllCustomers) {
        let url = COMPANY_NOTIFICATIONS_CONSTANT.SEND_CUSTOMERS_SMS;
        if (isSendToAllCustomers) {
            url += '?is-send-to-all=' + isSendToAllCustomers;
        }
         return this.httpClient.post(url, {ids: ids, template: template});
    }
    public getSMSBalance() {
        return this.httpClient.get(COMPANY_NOTIFICATIONS_CONSTANT.GET_SMS_BALANCE);
    }
    public getSMSGateWayCurrentSettings() {
        return this.httpClient.get(COMPANY_NOTIFICATIONS_CONSTANT.GET_SMS_GATEWAY_CURRENT_SETTINGS);
    }
}
