
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from '../shared/modules/shared.module';

import {ManageZonesComponent} from './components/manage-zones/manage-zones.component';
import {ToastModule} from 'primeng/toast';
import {ZonesComponent} from './components/zones/zones.component';
import {ZonesService} from './services/zones.service';
import {AddZoneComponent} from './components/add-zone/add-zone.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AdministrativeReportsComponent} from './components/administrative-reports/administrative-reports.component';
import {SummaryReportsComponent} from './components/summary-reports/summary-reports.component';
import {CustomerPackagesDetailedReportComponent} from './components/detailed-reports/customer-packages-detailed-report/customer-packages-detailed-report.component';
import {DriverPackagesDetailedReportComponent} from './components/detailed-reports/driver-packages-detailed-report/driver-packages-detailed-report.component';

import {ManageCompanyComponent} from './components/manage-company/manage-company.component';
import {SaasThemeComponent} from './components/saas-theme/saas-theme.component';
import {RouterModule} from '@angular/router';
import {TableModule} from 'primeng/table';
import {ReportsService} from './services/reports';
import {SaasService} from './services/saas.service';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ManageParcelComponent} from './components/manage-parcel/manage-parcel.component';
import {ParcelService} from './services/parcel.service';
import {NewParcelComponent} from './components/manage-parcel/new-parcel/new-parcel.component';
import {ShippingLinesComponent} from './components/shipping-lines/shipping-lines.component';
import {AddShippingLineComponent} from './components/add-shipping-line/add-shipping-line.component';
import {BankTransfersComponent} from './bank-transfers/bank-transfers.component';
import {DetailedReportsComponent} from './components/detailed-reports/detailed-reports.component';

import {IdleCustomersReportComponent} from './components/detailed-reports/idle-customers-report/idle-customers-report.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import { ManageServiceTypesComponent } from './components/manage-service-types/manage-service-types.component';
import { NewServiceTypeComponent } from './components/manage-service-types/new-service-type/new-service-type.component';
import {ManagePackageContentsComponent} from './components/manage-package-contents/manage-package-contents.component';
import {PackageContentsService} from './services/package-contents.service';
import {PackageContentFormComponent} from './components/manage-package-contents/package-content-form/package-content-form.component';
import { ManagePaymentsComponent } from './components/manage-payments/manage-payments.component';
import { NewPaymentComponent } from './components/manage-payments/new-payment/new-payment.component';
import {PaymentsService} from './services/payments.service';
import { SuccessfulPaymentComponent } from './components/manage-payments/successful-payment/successful-payment.component';
import {ArchivedShipmentsComponent} from './components/archeived-shipments/archived-shipments.component';
import {ShipmentModule} from '../shipment/shipment.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {CheckboxModule} from 'primeng/checkbox';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {DropdownModule} from 'primeng/dropdown';
import {CardModule} from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule} from 'primeng/calendar';
import {AdministrationRoutingModule} from './administration.route';
import {
    CustomerPackagesSummaryReportComponent
} from './components/summary-reports/customer-packages-summary-report/customer-packages-summary-report.component';
import {
    ZonesPackagesSummaryReportComponent
} from './components/summary-reports/zones-packages-summary-report/zones-packages-summary-report.component';
import {
    ZonesToPackagesSummaryReportComponent
} from './components/summary-reports/zones-to-packages-summary-report/zones-to-packages-summary-report.component';
import {
    ZonesFromPackagesSummaryReportComponent
} from './components/summary-reports/zones-from-packages-summary-report/zones-from-packages-summary-report.component';
import {
    DriverPackagesSummaryReportComponent
} from './components/summary-reports/driver-packages-summary-report/driver-packages-summary-report.component';
import {
    UndelivredPackagesSummaryReportComponent
} from './components/summary-reports/undelivered-packages-summary-report/undelivered-packages-summary-report.component';
import {
    CityToUndeliveredCodSummaryReportComponent
} from './components/summary-reports/city-to-undelivered-cod-summary-report/city-to-undelivered-cod-summary-report.component';
import {
    UndeliveredPackagesDetailedReportComponent
} from './components/detailed-reports/undelivered-packages-detailed-report/undelivered-packages-detailed-report.component';
import {
    ShippedPackagesDetailedReportComponent
} from './components/detailed-reports/shipped-packages-detailed-report/shipped-packages-detailed-report.component';
import {TimePeriodPackagesAdministrativeReportComponent} from './components/administrative-reports/time-period-packages-administrative-report/time-period-packages-administrative-report.component';
import {
    CustomerActivityReportComponent
} from './components/administrative-reports/customer-activity-report/customer-activity-report.component';
import {ActivityReportComponent} from './components/administrative-reports/activity-report/activity-report.component';
import {HubsAccountingDashboardComponent} from './components/hubs-accounting/hubs-accounting-dashboard/hubs-accounting-dashboard.component';
import {HubCardComponent} from './components/hubs-accounting/hubs-accounting-dashboard/hub-card/hub-card.component';
import {HubsMassCodReportsComponent} from './components/hubs-accounting/hubs-mass-cod-reports/hubs-mass-cod-reports.component';
import { ManageShippingLineDriverComponent } from './components/manage-shipping-line-driver/manage-shipping-line-driver.component';
import {PickListModule} from 'primeng/picklist';

import { EditCityPrefixDialogComponent } from './components/edit-city-prefix-dialog/edit-city-prefix-dialog.component';
import {TooltipModule} from 'primeng/tooltip';
import {DistributorPackagesContentsReportComponent} from './components/distributor-packages-contents-report/distributor-packages-contents-report.component';
import {PackageContentsDialogComponent} from './components/distributor-packages-contents-report/package-contents/package-contents-dialog.component';
import { MassCodReportHistoryComponent } from './components/mass-cod-report-history/mass-cod-report-history.component';
import {DriversEarningsReportComponent} from './components/drivers-earnings-report/drivers-earnings-report.component';
import {DriverEarningsDetailsDialogComponent} from './components/drivers-earnings-report/driver-earnings-details-dialog/driver-earnings-details-dialog.component';
import { DamagedPackagesReportComponent } from './components/damaged-packages-report/damaged-packages-report.component';
import { ExportPackageContentToComponent } from './components/export-package-content-to/export-package-content-to.component';
import { ReceiveCodCustomersComponent } from './components/receive-cod-customers/receive-cod-customers.component';
import { SortCodComponent } from './components/sort-cod/sort-cod.component';
import { ReceiveCodDialogComponent } from './components/receive-cod-dialog/receive-cod-dialog.component';
import { FinishScanningV2Component } from './components/finish-scanning-v2/finish-scanning-v2.component';
import {AddPackageFormModule} from '../add-package-form/add-package-form.module';
import {AccountingTemplateComponent} from './components/accounting-template/accounting-template.component';
import {ExportedCodComponent} from './components/exported-cod/exported-cod.component';
import {InvoicesComponent} from './components/invoices/invoices.component';
import {DeliveredCodComponent} from './delivered-cod/delivered-cod.component';
import {InCarCodComponent} from './components/in-car-cod/in-car-cod.component';
import {CodPendingCustomersApprovalComponent} from './components/cod-pending-customers-approval/cod-pending-customers-approval.component';
import { ChooseMapComponent } from './choose-map/choose-map.component';
import { CreateInvoiceForMultiCustomerComponent } from './components/create-invoice-for-multi-customer/create-invoice-for-multi-customer.component';
import {RippleModule} from 'primeng/ripple';
import { CustomersWalletsComponent } from './components/customers-wallets/customers-wallets.component';
import { WalletListComponent } from './components/customers-wallets/wallet-list/wallet-list.component';
import { WalletCardComponent } from './components/customers-wallets/wallet-card/wallet-card.component';
import { ReceiptListComponent } from './components/customers-wallets/receipt-list/receipt-list.component';
import { WalletTransactionsComponent } from './components/customers-wallets/wallet-transactions/wallet-transactions.component';
import { AddReceiptDialogComponent } from './components/customers-wallets/add-receipt-dialog/add-receipt-dialog.component';
import { MassInvoicesComponent } from './components/mass-invoices/mass-invoices.component';
import { MassDetailsInvoicesComponent } from './components/mass-details-invoices/mass-details-invoices.component';
import { ExpensesHistoryComponent } from './components/expenses-history/expenses-history.component';
import { GenerateCustomBarcodesComponent } from './components/generate-custom-barcodes/generate-custom-barcodes.component';

@NgModule({
    declarations: [
        ManageZonesComponent,
        ZonesComponent,
        AddZoneComponent,
        AdministrativeReportsComponent,
        DetailedReportsComponent,
        SummaryReportsComponent,
        CustomerPackagesDetailedReportComponent,
        DriverPackagesDetailedReportComponent,
        ShippedPackagesDetailedReportComponent,
        UndeliveredPackagesDetailedReportComponent,
        CustomerPackagesSummaryReportComponent,
        IdleCustomersReportComponent,
        DriverPackagesSummaryReportComponent,
        ZonesFromPackagesSummaryReportComponent,
        ZonesToPackagesSummaryReportComponent,
        ZonesPackagesSummaryReportComponent,
        UndelivredPackagesSummaryReportComponent,
        TimePeriodPackagesAdministrativeReportComponent,
        CustomerActivityReportComponent,
        ActivityReportComponent,
        ManageCompanyComponent,
        SaasThemeComponent,
        ManageParcelComponent,
        NewParcelComponent,
        ShippingLinesComponent,
        AddShippingLineComponent,
        BankTransfersComponent,
        CityToUndeliveredCodSummaryReportComponent,
        ManageServiceTypesComponent,
        NewServiceTypeComponent,
        ManagePackageContentsComponent,
        PackageContentFormComponent,
        ManagePaymentsComponent,
        NewPaymentComponent,
        SuccessfulPaymentComponent,
        ArchivedShipmentsComponent,
        HubsAccountingDashboardComponent,
        HubCardComponent,
        HubsMassCodReportsComponent,
        ManageShippingLineDriverComponent,
        EditCityPrefixDialogComponent,
        DistributorPackagesContentsReportComponent,
        PackageContentsDialogComponent,
        MassCodReportHistoryComponent,
        DriversEarningsReportComponent,
        DamagedPackagesReportComponent,
        ExportPackageContentToComponent,
        DriverEarningsDetailsDialogComponent,
        HubsMassCodReportsComponent,
        ArchivedShipmentsComponent,
        AccountingTemplateComponent,
        ReceiveCodCustomersComponent,
        SortCodComponent,
        ReceiveCodDialogComponent,
        FinishScanningV2Component,
        ExportedCodComponent,
        InvoicesComponent,
        DeliveredCodComponent,
        InCarCodComponent,
        CodPendingCustomersApprovalComponent,
        ChooseMapComponent,
        CreateInvoiceForMultiCustomerComponent,
        CustomersWalletsComponent,
        WalletListComponent,
        WalletCardComponent,
        ReceiptListComponent,
        WalletTransactionsComponent,
        AddReceiptDialogComponent,
        MassInvoicesComponent,
        MassDetailsInvoicesComponent,
        ExpensesHistoryComponent,
        GenerateCustomBarcodesComponent
    ],
    exports: [
        ZonesComponent,
        PackageContentFormComponent,
        RouterModule
    ],
    imports: [
        AdministrationRoutingModule,
        SharedModule,
        ProgressSpinnerModule,
        CheckboxModule,
        ScrollPanelModule,
        ToastModule,
        InfiniteScrollModule,
        TableModule,
        ColorPickerModule,
        DropdownModule,
        CardModule,
        TabViewModule,
        InputSwitchModule,
        InputTextModule,
        MultiSelectModule,
        CalendarModule,
        ShipmentModule,
        PickListModule,
        TooltipModule,
        AddPackageFormModule,
        RippleModule

    ],
    providers: [ZonesService, ReportsService, SaasService, ParcelService, PaymentsService, PackageContentsService],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AdministrationModule {
}
