<form [formGroup]="form" *ngIf="controlType" (submit)="onSubmit.emit()">
    <div *ngIf="controlType === 'submit'" class="submit-container group cursor-pointer" [ngClass]="{'disabled': !form?.valid || disabled}">
        <button *ngIf="!isSubmitting" type="submit" [ngClass]="!form?.valid ? 'disabled' : ''" [disabled]="!form?.valid"
                class="button cursor-pointer">{{label | translate }}</button>
        <i *ngIf="isSubmitting" class="fas fa-spinner fa-spin" style="font-size:24px"></i>
    </div>

    <div class="group text-align-start" *ngIf="controlType === 'text' || controlType === 'password'">
        <label class="label"> {{ label | translate }} </label>
        <input  [formControlName]="controlName"
                placeholder="{{ placeholder | translate }}"
                [type]="controlType" class="input">
    </div>
    <div class="group text-align-start" *ngIf="controlType === 'textarea'">
        <label class="label"> {{ label | translate }}</label>
        <textarea [formControlName]="controlName"
                  type="text"
                  class="input">
        </textarea>
    </div>
</form>
