<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="receive-table-view d-flex flex-column h-100">
    <div class="container-details-container overflow-auto">
        <app-shipment-tracker [isShow]="isTrackingPackage" (onCloseTracker)="closeTracker($event)"
                              [packageBarcode]="triggeredDropdownShipment?.barcode"
                              [showHeaderActions] = "!isCustomerAccountManager()"
                              class="component"></app-shipment-tracker>
    </div>
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div class="header-actions">
        <div class="title" *ngIf="driverName">
            {{"SHIPMENT.DRIVER" | translate}}: {{driverName}}
        </div>
        <div class="title" *ngIf="partnerName">
            {{"PARTNER.PARTNER_NAME" | translate}}: {{partnerName}}
        </div>
        <div class="title" *ngIf="hubId">
            {{"MANAGE_COD_CUSTOMERS.HUB_NAME" | translate | hubTitle}}: {{hubName}}
        </div>
        <div class="title" *ngIf="containerName">
            {{"ACCOUNTING.CONTAINER" | translate}}: {{containerName}}
        </div>
        <div class="filters">
            <div class="date-filter">
                <div class="cod-title">
                    {{"MANAGE_SHIPMENTS.DELIVERY_DATE" | translate}}
                </div>
<!--                <div class="date-picker-filter" (click)="datepicker.click()">-->
<!--                    <i class="fas fa-calendar"></i>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                           #datepicker-->
<!--                           daterangepicker [options]="dateRangPickerOptions"-->
<!--                           (selected)="onDateSelected($event)"/>-->
<!--                    <i class="fas fa-caret-down"></i>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>
            </div>
            <div *ngIf="!isCustody" class="search-container">
                <i class="fas fa-search" [ngClass]="{'fa-rotate-90': currentLang ==='ar'}"></i>
                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                       class="search-input"
                       [(ngModel)]="searchContent"
                       appInputDebounced
                       (inputDebounced)="onSearch()">
            </div>
        </div>
    </div>

    <div class="sum-values">
        <div class="cod-title">
            <b>{{codPackagesCount}}</b>
            <br>
            {{"ACCOUNTING.NUMBER_OF_COD_PACKAGES" | translate}}
        </div>
        <div *ngIf="partnerName" class="cod-title">
            <b>{{codWithOutPartnerCost}}</b>
            <br>
            {{"ACCOUNTING.COD_WITHOUT_PARTNER_COST" | translate}}
        </div>
        <div class="cod-title">
            <b>{{codSum | decimalDigits: 3 }}</b>
            <br>
            {{"ACCOUNTING.TOTAL_COD_AMOUNT" | translate}}
        </div>

        <div *ngIf="isHubUserRole" class="cod-title">
            <b>{{hubCustomersCod | decimalDigits: 3 }}</b>
            <br>
            {{"ACCOUNTING.HUB_CUSTOMERS_COD" | translate}}
        </div>


        <div class="cod-title">
            <b>{{receivedCod}}</b>
            <br>
            {{"ACCOUNTING.RECEIVED_COD" | translate}}
        </div>
        <div *ngIf="showHubCodCustody()" class="cod-title">
            <b>{{codCustody}}</b>
            <br>
            {{"ACCOUNTING.HUB_COD_CUSTODY" | translate | hubTitle}}
        </div>
        <div class="cod-title">
            <b>{{scannedPackages.length}}</b>
            <br>
            {{"SHIPMENT.NUMBER_OF_SCANNED_PACKAGES" | translate}}
        </div>
        <div class="cod-title">
            <b>{{totalDeliveredPackages}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_DELIVERED_PACKAGES" | translate}}
        </div>
        <div class="cod-title">
            <b>{{totalReturnedPackages}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_RETURNED_PACKAGES" | translate}}
        </div>
        <div class="cod-title">
            <b>{{totalPartiallyDeliveredPackages}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_PARTIALLY_DELIVERED_PACKAGES" | translate}}
        </div>
        <div class="cod-title">
            <b>{{totalSwappedPackages}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_SWAPPED_PACKAGES" | translate}}
        </div>
        <div class="cod-title">
            <b>{{totalBroughtPackages}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_BROUGHT_PACKAGES" | translate}}
        </div>
        <div class="cod-title" *ngIf="driverName && isDriverEarningEnabled">
            <b>{{driverEarningSum}}</b>
            <br>
            {{"DRIVERS_EARNINGS.TOTAL_DRIVER_COST" | translate}}
        </div>
    </div>

    <div class="driver-receive-table flex-1 overflow-auto">
        <p-table #packagesTable dataKey="id" #table [value]="packages"
                 styleClass="customers-table flex-table" [rowsPerPageOptions]="rowsPerPageOptions"
                 [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="codPackagesCount">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;"> {{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.SENDER_NAME" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.RECEIVER_NAME" | translate}}</th>
                    <th *ngIf="showReceiverAddressInSeparatedCell"> {{"MANAGE_COD_CUSTOMERS.RECEIVER_ADDRESS" | translate}}</th>
                    <th> {{"ADD_SHIPMENT.RECEIVER_PHONE" | translate }}</th>
                    <th style="width: 7%"> {{"ADD_SHIPMENT.INVOICE_NUMBER" | translate}}</th>
                    <th style="width: 7%"> {{"MANAGE_SHIPMENTS.COD" | translate}}
                    <th *ngIf="partnerId"> {{"PARTNER.PARTNER_COST" | translate}}
                    <th *appPermission="permissions.includes('{EDIT_COST')"> {{"MANAGE_SHIPMENTS.COST" | translate}} </th>
                    <th style="width: 150px;"> {{"ACCOUNTING.STATUS" | translate}}</th>
                    <th>{{'ADD_SHIPMENTS.PAYMENT_METHOD' | translate}}</th>
                    <th style="width: 7%"> {{"MANAGE_SHIPMENTS.IS_PARTIAL_DELIVERY" | translate}}</th>
                    <th *ngIf="driverName && isDriverEarningEnabled" style="width: 7%"> {{"DRIVERS_EARNINGS.DRIVER_COST" | translate}}</th>
                    <th *ngIf="isDraftPackagesEnable()" class="th-attachments">
                        <div class="attachments-filter">
                            <div class="label">{{ 'GENERAL.ATTACHMENTS' | translate }}</div>
                            <div class="values">
                                <div class="item"
                                     (click)="setAttachmentFilter('ALL')"
                                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'ALL','b-radius-right': currentLang === 'ar' , 'b-radius-left': currentLang === 'en'}">{{'ALL' | translate}}</div>
                                <div class="item flex-2d-center"
                                     (click)="setAttachmentFilter('WITH')"
                                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                <div class="item"
                                     (click)="setAttachmentFilter('WITHOUT')"
                                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITHOUT','b-radius-left': currentLang === 'ar' , 'b-radius-right': currentLang === 'en'}">{{'WITHOUT' | translate}}</div>
                            </div>
                        </div>
                    </th>
                    <th style="width: 7%"> {{"MANAGE_SHIPMENTS.NOTES" | translate}}</th>
                    <th style="width: 7%"> {{"ACTIONS.UPDATE" | translate}}</th>
                    <th *ngIf="isScanning">{{"ACCOUNTING.SCAN_PACKAGE"|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr [ngClass]="{'scanned-row':isPackageScanned(package.barcode, package.codBarcode),
                    'change-color': changeColorForPartialDeliveryOrSwapsPackage(package),
                    'cod-received-without-custody-color': package.codReceivedWithoutCustody,
                    'is-draft-background-color' : changeColorOfDraftPackages(package.isDraftPackageWithoutReceiverInfo)}"
                    tooltipPosition="top" [tooltipDisabled]="!changeColorForPartialDeliveryOrSwapsPackage(package)"
                    pTooltip="{{PartialDeliveryOrSwapPackage(package)| translate}}">
                    <td style="width: 150px;text-align: center">
                        <img class="barcode-image" [src]="package.barcodeImage"
                             (click)="trackShipment(package)">
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.barcode)"></i>
                        </span>
                    </td>
                    <td>{{package.senderFirstName}} {{(package.senderMiddleName ? package.senderMiddleName : '')}}
                        {{package.senderLastName}}</td>
                    <td>
                        <div>{{package.receiverFirstName}} {{(package.receiverMiddleName ? package.receiverMiddleName : '')}}
                            {{package.receiverLastName}}
                        </div>
                        <div *ngIf="!showReceiverAddressInSeparatedCell" class="append-address" [ngClass]="{'margin-right-30': currentLang === 'en'}">
                            {{'ADMINISTRATION.ADDRESS' | translate}}: {{package.destinationVillage + ' - ' + package.destinationAddressLine1}}
                        </div>
                    </td>

                    <td *ngIf="showReceiverAddressInSeparatedCell">
                        <div class="editable-address" [pTooltip]="'GENERAL.CLICK_TO_EDIT' | translate" appendTo="body" tooltipPosition="top" (click)="openUpdateDestinationAddress(package)">
                            {{package.destinationVillage}}
                        </div>
                    </td>
                    <td>{{package.receiverPhone}}</td>
                    <td style="width: 7%">{{package.invoiceNumber}}</td>
                    <td style="width: 7%" pEditableColumn [pEditableColumn]="package">
                        <ng-container *ngIf="!isCodEditable">

                        {{package.cod}}
                        </ng-container>
                        <ng-container *ngIf="isCodEditable">
                            <app-edit-cost [package]="package" [attribute]="'cod'" successUpdateMsg="ALERTS.COD_UPDATED_SUCCESSFULLY"></app-edit-cost>
                        </ng-container>
                    </td>
                    <td *ngIf="partnerId">{{package.partnerPackageCost}}</td>
                    <td *appPermission="permissions.includes('{EDIT_COST')" pEditableColumn [pEditableColumn]="package"
                        [pEditableColumnField]="'cost'">
                        <app-edit-cost [package]="package"></app-edit-cost>
                    </td>
                    <td style="width: 150px;text-align: center">
                        <div class="d-flex flex-column">
                            <app-shipment-status [isEditEnabled]="false" [shipment]="package"></app-shipment-status>
                            <div class="pt-2">
                                {{('RETURNED_REASONS.'+package.failedReason | translate: {default: package.failedReason})}}
                            </div>
                        </div>
                    </td>
                    <td>{{package.paymentType | translate}}</td>
                    <td style="width: 7%">{{package.isPartiallyDelivered ? ('MANAGE_SHIPMENTS.IS_PARTIAL_DELIVERY' | translate) : ''}}</td>
                    <td style="width: 7%" *appPermission="driverName && isDriverEarningEnabled"
                        pEditableColumn [pEditableColumn]="package" [pEditableColumnDisabled]="!permissions.includes('{EDIT_COST')"
                        [pEditableColumnField]="'driverEarning'">
                        <div class="flex-2d-center">
                            <app-edit-driver-earning *ngIf="permissions.includes('{EDIT_COST')" [package]="package"></app-edit-driver-earning>
                            <div *ngIf="!permissions.includes('{EDIT_COST')">{{package.driverEarning}}</div>
                        </div>
                    </td>
                    <td *ngIf="isDraftPackagesEnable()" style="width: 115px">
                        <div *ngIf="package.isAttachmentExist" class="attachments-btn">
                            <img (click)="openImages($event, package.id)"
                                 src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                        </div>
                    </td>
                    <td style="width: 7%">{{package.notes}}</td>

                    <td style="width: 7%">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer" (click)="editPackage(package.barcode)">
                                <i class="fas fa-edit"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isScanning && !isPackageScanned(package.barcode, package.codBarcode)">
                        <div class="table-row-actions-container">
                            <div class="scan-action action cursor-pointer" (click)="scanPackage(package)">
                                <i class="fas fa-barcode"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isScanning && isPackageScanned(package.barcode, package.codBarcode)">
                        <div class="table-row-actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackage(package)">
                                <i class="fas fa-minus-circle"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="table-actions">
        <div class="flex-start-item title" style="padding: 0">
            {{'ACCOUNTING.TOTAL_PACKAGES'|translate}}: {{codPackagesCount}}
        </div>
        <div *ngIf="partnerId" class="excel-button" [ngClass]="{'disabled-btn': isExporting}"
             (click)="exportExcel()">
            <i class="fas fa-file-excel"></i>
            {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
        </div>
        <div *ngIf="!isCustody && !isScanning && this.driverId && this.driverId > 0" class="secondary-action-btn cursor-pointer" (click)="receiveInHubCustody()">
            {{"ACCOUNTING.RECEIVE_IN_HUB_CUSTODY" | translate}}
        </div>
        <div *ngIf="!isCustody && !isScanning" class="secondary-action-btn cursor-pointer" (click)="bulkReceive()">
            {{"ACCOUNTING.BULK_RECEIVE" | translate}}
        </div>
        <div *ngIf="!isScanning" [ngClass]="{'disabled-btn': !codPackagesCount}" class="secondary-action-btn cursor-pointer"  (click)="confirmReceiveAll()">
            {{"ACCOUNTING.RECEIVE_ALL" | translate}}
        </div>
        <div *ngIf="!isScanning" [ngClass]="{'disabled-btn': !codPackagesCount}" class="secondary-action-btn cursor-pointer" (click)="startScanning()">
            {{"ACCOUNTING.START_SCANNING" | translate}}
        </div>
        <div *ngIf="isScanning" class="add-to-driver-container red cursor-pointer" (click)="resetScanning()">
            {{"ACCOUNTING.RESET" | translate}}
        </div>
        <div *ngIf="isScanning" class="secondary-action-btn cursor-pointer" (click)="enterBarcode()">
            {{"ACCOUNTING.ENTER_BARCODE" | translate}}
        </div>
        <div *ngIf="isScanning" [ngClass]="{'disabled-btn': !this.scannedPackages.length}" class="gradient-action cursor-pointer" (click)="finishScanning(false)">
            {{"ACCOUNTING.FINISH_SCANNING" | translate}}
        </div>
    </div>
</div>
<ng-container>
    <app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                    [packageBarcode]="selectedPackageBarcode"
                                    (closeWidget)="onHideAddPackage($event)">
    </app-add-package-form-component>
</ng-container>
