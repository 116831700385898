<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <p-sidebar [(visible)]="display"
               (onHide)="onClose()"
               (onShow)="onShow()"
               [dismissible]="false"
               #ticketDetails
               [baseZIndex]="2"
               position="{{currentLang === 'en' ? 'right': 'left'}}"
               styleClass="ticket-details-sidebar">

        <!--        overlay to show all tickets -->
        <ng-template #ticketsListPopOver let-close="close">
            <div class="ticket-list-header">
                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ALL_TICKETS' | translate}}</span>
            </div>
            <div>
                <div class="tickets-list-container" (scroll)="onScrollTicketsList($event)">
                    <div class="ticket-item d-flex cursor-pointer"
                         [ngClass]="{'disabled-btn disabled': ticket.id === triggeredTicketId}"
                         *ngFor="let ticket of tickets" (click)="initialTicket(ticket.id)">
                        <div class="created-by-image">
                            <app-upload-image-component
                                    class="user-image-upload"
                                    [showClear]="false"
                                    [canUpload]="false"
                                    [(url)]="ticket.createdBy.imageUrl"
                                    [imageSize]="40"
                                    [defaultLogo]="'../../../../assets/icons/ticketing-system/user-avatar.png'"
                            >
                            </app-upload-image-component>
                        </div>
                        <div class="ticket-item-info">
                            <div class="priority-container d-flex">
<!--                                <div class="auto-status">-->
<!--                                    <span>جديدة</span>-->
<!--                                </div>-->
                                <div class="ticket-item-priority flex-2d-center">
                                    <div class="priority-color"><span></span></div>
                                    <div class="priority-label">
                                        <span>{{ticket.priority | translate}}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="title-container">
                                <p>{{ticket.title}}</p>
                            </div>
                            <div class="creator-by-and-date-container d-flex">
                                <div class="item-created-by-container d-flex">
                                    <div class="creator-by-and-date-label">
                                        <span>{{'TICKETING_SYSTEM.ADDED_BY' | translate}}</span>
                                    </div>
                                    <div class="item-created-by-name">
                                        <span>{{getUserName(ticket.createdBy)}}</span>
                                    </div>
                                </div>
                                <div class="item-created-date-container d-flex">
                                    <div class="creator-by-and-date-label">
                                        <span>{{'CREATEDDATE' | translate}}</span>
                                    </div>
                                    <div class="item-created-date">
                                        <span>{{transformDate(ticket.createdDate, 'dd/MM/yyyy')}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="getTicketsLoader" class="ticket-item">
                        <div class="main-spinner-container">
                            <div class="spinner-stage">
                                <div class="dot-flashing"></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </ng-template>
        <!--   end      -->
        <div class="main-spinner-container" *ngIf="isLoading">
            <div class="spinner-stage">
                <div class="dot-flashing"></div>
            </div>
        </div>
        <div *ngIf="ticketDetailsInfo && !isLoading" class="ticket-details-container">
            <div class="first-section">
                <div class="header-actions-container row">
                    <div class="header-actions d-flex" [ngClass]="{'disabled-btn disabled': viewHistoryMode}">
                        <div *ngIf="ticketDetailsInfo.status !== 'DONE'" class="header-action flex-2d-center reply-action" (click)="showReply('REPLY')"
                             [ngClass]="{'disabled-btn disabled': isExternal}"
                        >
                            <div class="icon">
                                <img *ngIf="currentLang === 'ar'"
                                     src="../../../../assets/icons/ticketing-system/ticket-details/reply.png"
                                     alt=""/>
                                <img *ngIf="currentLang === 'en'"
                                     src="../../../../assets/icons/ticketing-system/ticket-details/reply-en.png"
                                     alt=""/>
                            </div>
                            <div class="label">
                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.REPLY' | translate}}</span>
                            </div>
                        </div>

<!--                        <div *ngIf="ticketDetailsInfo.status !== 'DONE'" class="header-action flex-2d-center add-note-action" (click)="showReply('NOTE')">-->
<!--                            <div class="icon">-->
<!--                                <img src="../../../../assets/icons/ticketing-system/ticket-details/add-note.png"-->
<!--                                     alt=""/>-->
<!--                            </div>-->
<!--                            <div class="label">-->
<!--                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.ADD_NOTE' | translate}}</span></div>-->
<!--                        </div>-->

<!--                        <div class="header-action flex-2d-center send-action">-->
<!--                            <div class="icon">-->
<!--                                <img src="../../../../assets/icons/ticketing-system/ticket-details/send.png"-->
<!--                                     alt=""/>-->
<!--                            </div>-->
<!--                            <div class="label">-->
<!--                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.SEND' | translate}}</span></div>-->
<!--                        </div>-->

<!--                        <div class="header-action flex-2d-center close-action" (click)="displayConfirmation('CLOSE')">-->
<!--                            <div class="icon">-->
<!--                                <img src="../../../../assets/icons/ticketing-system/ticket-details/close.png"-->
<!--                                     alt=""/>-->
<!--                            </div>-->
<!--                            <div class="label">-->
<!--                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.CLOSE' | translate}}</span></div>-->
<!--                        </div>-->
                        <div class="header-action flex-2d-center close-action" (click)="displayConfirmation('NEW')"
                             [ngClass]="{'disabled-btn disabled': ((ticketStatus == 'NEW') || ((ticketStatus == 'DONE') && !(isAdmin||isCategoryAdmin)) || (isExternal && !(isAdmin||isCategoryAdmin))) }">

                        <div class="icon">
                                <img src="../../../../assets/icons/ticketing-system/ticket-details/close.png"
                                     alt=""/>
                            </div>
                            <div class="label">
                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.SET_AS_NEW' | translate}}</span></div>
                        </div>

                        <div class="header-action flex-2d-center close-action" (click)="displayConfirmation('DONE')"
                             [ngClass]="{'disabled-btn disabled': ticketStatus == 'DONE' || (isExternal && !isAdmin) }">
                            <div class="icon">
                                <img src="../../../../assets/icons/ticketing-system/ticket-details/close.png"
                                     alt=""/>
                            </div>
                            <div class="label">
                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.DONE' | translate}}</span>
                            </div>
                        </div>

                        <!--                        <div class="header-action flex-2d-center merge-action">-->
                        <!--                            <div class="icon">-->
                        <!--                                <img src="../../../../assets/icons/ticketing-system/ticket-details/merge.png"-->
                        <!--                                     alt=""/>-->
                        <!--                            </div>-->
                        <!--                            <div class="label"><span>دمج</span></div>-->
                        <!--                        </div>-->

<!--                        <div *ngIf="allowDeleteTicket()" class="header-action flex-2d-center remove-action" (click)="displayConfirmation('DELETE')">-->
<!--                            <div class="icon">-->
<!--                                <img src="../../../../assets/icons/ticketing-system/ticket-details/remove.png"-->
<!--                                     alt=""/>-->
<!--                            </div>-->
<!--                            <div class="label">-->
<!--                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.DELETE' | translate}}</span></div>-->
<!--                        </div>-->

<!--                        <div class="header-action flex-2d-center more-actions-btn">-->
<!--                            <div class="icon">-->
<!--                                <img src="../../../../assets/icons/ticketing-system/ticket-details/more.png"-->
<!--                                     alt=""/>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <div class="header-left-actions header-actions d-flex">
                        <div class="header-action flex-2d-center view-history-action"
                             [ngClass]="{'active-view-history': viewHistoryMode}"
                             (click)="toggleViewHistoryMode()">
                            <div class="icon">
                                <img *ngIf="!viewHistoryMode" src="../../../../assets/icons/ticketing-system/ticket-details/history.png"
                                     alt=""/>
                                <img *ngIf="viewHistoryMode" src="../../../../assets/icons/ticketing-system/ticket-details/active-history.png"
                                     alt=""/>
                            </div>
                            <div class="label">
                                <span *ngIf="!viewHistoryMode">{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.VIEW_HISTORY' | translate}}</span>
                                <span *ngIf="viewHistoryMode">{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.HIDE_HISTORY' | translate}}</span>
                            </div>
                        </div>
                        <div class="prev-next-container d-flex" [ngClass]="{'disabled-btn disabled': viewHistoryMode}">
                            <div [ngClass]="{'disabled-btn disabled': disableGoToArrow(true) && !viewHistoryMode }"
                                 class="go-to-arrow-icon cursor-pointer"
                                 (click)="goToTicket(-1)">
                                <img src="../../../../assets/icons/ticketing-system/ticket-details/right-arrow.png"
                                     alt=""/>
                            </div>
                            <div class="tickets-list-popover">
                                <div (click)="getTickets(true)" class="show-all-tickets flex-2d-center cursor-pointer"
                                     placement="bottom" autoClose="outside" [ngbPopover]="ticketsListPopOver">
                                    <img src="../../../../assets/icons/ticketing-system/ticket-details/more.png"
                                         alt=""/>
                                </div>
                            </div>
                            <div [ngClass]="{'disabled-btn disabled': disableGoToArrow(false) && !viewHistoryMode }"
                                 class="go-to-arrow-icon cursor-pointer"
                                 (click)="goToTicket(1)">
                                <img src="../../../../assets/icons/ticketing-system/ticket-details/left-arrow.png"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cards-container" *ngIf="!viewHistoryMode">
                    <div class="ticket-card ticket-details-info" *ngIf="ticketDetailsInfo">
                        <app-ticket-card [data]="ticketDetailsInfo"
                                         [editTitleMode]="editTitleMode"
                                         [editDescriptionMode]="editDescriptionMode"
                                         (updateTitle)="updateAttribute('ISSUE_TITLE', $event.title)"
                                         (deleteCardAttachment)="deleteReplyAttachment($event , false)"
                                         (updateDescription)="updateAttribute('DESCRIPTION', $event.description)"
                                         (updateAttachments)="updateCardAttachments($event)"
                        ></app-ticket-card>
                    </div>
                    <div class="ticket-replies-container">
                        <div *ngIf="ticketDetailsInfo.replies.length">
                            <app-ticket-card *ngFor="let reply of ticketDetailsInfo.replies"
                                             [isTicketReply]="true"
                                             (updateDescription)="updateAttribute('REPLY', $event.description , $event.replyId, $event.replyType)"
                                             (deleteReply)="confirmRemoveReply($event.replyId, $event.replyType)"
                                             (deleteCardAttachment)="deleteReplyAttachment($event , true)"
                                             (updateAttachments)="updateCardAttachments($event)"
                                             [data]="reply">
                            </app-ticket-card>
                        </div>
                        <div *ngIf="displayReplayArea" class="add-reply">
                            <div class="reply-info d-flex">
                                <div class="sender-image-and-reply-icon d-flex">
                                    <div class="sender-image">
                                        <img src="../../../../assets/icons/ticketing-system/user-avatar.png"
                                             alt=""/>
                                    </div>
                                    <div class="reply-icon">
                                        <img *ngIf="currentLang === 'ar'"
                                             src="../../assets/icons/ticketing-system/ticket-details/reply.png"
                                             alt=""/>
                                        <img *ngIf="currentLang === 'en'"
                                             src="../../assets/icons/ticketing-system/ticket-details/reply-en.png"
                                             alt=""/>
                                    </div>

                                </div>

                                <div class="sender-and-receiver d-flex">
                                    <div class="user-name d-flex">
                                        <div class="from">
                                            <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ADD_REPLY.FROM' | translate}}</span>
                                        </div>
                                        <div class="user-name-value"><span>{{getUserName(userService.userInfo)}}</span></div>
                                    </div>
                                    <div class="user-name d-flex">
                                        <div class="to">
                                            <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ADD_REPLY.TO' | translate}}</span>
                                        </div>
                                        <div class="user-name-value"><span>{{getUserName(ticketDetailsInfo.assignee)}}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="reply-input">
                                <textarea #replyTextArea [(ngModel)]="replyText"></textarea>
                            </div>
                            <div class="uploaded-attachments-container d-flex" *ngIf="replyAttachmentsFiles.length">
                                <div class="view-add-reply-attachments d-flex">
                                    <div class="uploaded-attachments-number flex-2d-center">
                                        <div class="attachments-label">
                                            <span>{{'GENERAL.ATTACHMENTS' | translate}}</span>
                                        </div>
                                        <div class="attachments-number flex-2d-center">
                                            <span>{{replyAttachmentsFiles.length}}</span>
                                        </div>

                                    </div>
                                    <div class="flex-1 attachment-line-container">
                                        <div class="attachment-line"></div>
                                    </div>
                                </div>
                                <div class="uploaded-attachments-list d-flex">
                                    <div class="uploaded-attachment d-flex cursor-pointer" *ngFor="let attachment of replyAttachmentsFiles">
                                        <div class="attachment-type-and-size d-flex">
                                            <div class="attachment-type">
                                                <span>{{attachment.name}}</span>
<!--                                                <span>صورة مرفقة.JPG</span>-->
                                            </div>
                                            <div class="attachment-size">
<!--                                                <span>(10 KB)</span>-->
                                                <span>({{attachment.size}} KB)</span>
                                            </div>
                                        </div>
                                        <div class="remove-attachment" (click)="removeAttachment(attachment)">
                                            <img src="../../assets/icons/ticketing-system/ticket-details/x-icon.png"
                                                 alt=""/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="reply-actions row">
                                <div class="reply-attachment flex-2d-center cursor-pointer" (click)="addAttachment($event, fileChooser)">
                                    <img src="../../../../assets/icons/ticketing-system/ticket-details/attachment.png"
                                         alt=""/>
                                    <input type="file" accept="image/x-png,image/gif,image/jpeg,image/x-icon"
                                           style="width: 0; height: 0; opacity: 0; position: absolute" #fileChooser
                                           (click)="$event.stopPropagation()"
                                           (change)="onFilesSelected($event)" multiple>
                                </div>
                                <div class="actions-btn d-flex">
                                    <div class="cancel-reply-btn flex-2d-center cursor-pointer" (click)="hideReply()">
                                        <img src="../../assets/icons/ticketing-system/categories-management/delete.png"
                                             alt=""/>
                                    </div>
                                    <div class="d-flex send-reply-and-loader">
                                        <div class="add-reply-btn d-flex cursor-pointer"
                                             [ngClass]="{'disabled-btn disabled': !replyText.length || sendingReply}"
                                             (click)="replyRequest()">
                                            <div class="add-reply-label">
                                                <span>{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.SEND' | translate}}</span>
                                            </div>
                                            <div class="add-reply-icon">
                                                <img *ngIf="currentLang === 'ar'"
                                                     src="../../assets/icons/ticketing-system/ticket-details/add-reply.png"
                                                     alt=""/>
                                                <img *ngIf="currentLang === 'en'"
                                                     src="../../assets/icons/ticketing-system/ticket-details/add-reply-en.png"
                                                     alt=""/>
                                            </div>
                                        </div>
                                        <div *ngIf="sendingReply" class="add-reply-loader flex-2d-center">
                                            <i class="fas fa-spinner fa-pulse"></i>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="ticket-history" *ngIf="viewHistoryMode">
                    <app-ticket-history [TicketId]="ticketDetailsInfo.id"></app-ticket-history>
                </div>
            </div>
            <div class="second-section" [ngClass]="{'disabled-btn disabled': viewHistoryMode}">
                <div class="ticket-info d-flex">
                    <div class="ticket-status d-flex">
                        <div class="status-value"><span>{{ticketDetailsInfo.status | translate}}</span></div>
<!--                        <div class="status-progress-level flex-2d-center"><span>جديدة</span></div>-->
                    </div>
                    <div class="ticket-dates-info d-flex">
                        <div class="created-date ticket-date d-flex">
                            <div class="date-label"><span>{{'TICKETING_SYSTEM.TABLE.CREATED_BY' | translate}}</span></div>
                            <!--                            <div class="date-value"><span>2 Nov, 2021 </span></div>-->
                            <div class="date-value"><span>{{getCreatedByName(ticketDetailsInfo.createdBy, ticketDetailsInfo.customer)}}</span></div>
                        </div>
                        <div class="created-date ticket-date d-flex">
                            <div class="date-label"><span>{{'TICKETING_SYSTEM.CREATED_DATE' | translate}}</span></div>
                            <!--                            <div class="date-value"><span>2 Nov, 2021 </span></div>-->
                            <div class="date-value"><span>{{transformDate(ticketDetailsInfo.createdDate, 'dd/MM/yyyy')}}</span></div>
                        </div>
                        <div class="due-date ticket-date d-flex">
                            <div class="date-label"><span>{{'TICKETING_SYSTEM.DUE_DATE' | translate}}</span></div>
                            <!--                            <div class="date-value"><span>5 Nov, 2021 </span></div>-->
                            <div class="date-value"><span>{{ticketDetailsInfo.resolveDue ? transformDate(ticketDetailsInfo.resolveDue, 'dd/MM/yyyy HH:mm') : ''}} </span></div>
                        </div>
                    </div>
                </div>
                <div class="update-form">
                    <form [formGroup]="updateTicketForm" [ngClass]="{'disabled-btn disabled': (isExternal && !(isAdmin))}">
                        <div class="update-form-menu-container">
                            <div class="form-inputs-group">
<!--                                <div class="due-date field-input-container">-->
<!--                                    <div class="label">-->
<!--                                        <span>{{'TICKETING_SYSTEM.FORM.DUE_DATE' | translate}}</span>-->
<!--                                    </div>-->
<!--                                    <div class="field-input">-->
<!--                                        <p-calendar formControlName="resolveDue" styleClass="due-date-date-picker"-->
<!--                                                    icon="pi pi-calendar" [showIcon]="true"-->
<!--                                                    dateFormat="dd/mm/yy"></p-calendar>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="ticket-client-field-input field-input-field-container">-->
<!--                                    <div class="label">-->
<!--                                        <span>{{'TICKETING_SYSTEM.TABLE.TYPE' | translate}}</span>-->
<!--                                    </div>-->
<!--                                    <div class="field-input">-->
<!--                                        <p-dropdown-->
<!--                                                formControlName="type"-->
<!--                                                styleClass="update-ticket-form-drop-down"-->
<!--                                                [options]="ticketTypesList"-->
<!--                                                [style]="{'width':'100%'}"-->
<!--                                                filter="true">-->
<!--                                        </p-dropdown>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="ticket-client-field-input field-input-field-container">-->
<!--                                    <div class="label">-->
<!--                                        <span>{{'TICKETING_SYSTEM.TABLE.STATUS' | translate}}</span>-->
<!--                                    </div>-->
<!--                                    <div class="field-input">-->
<!--                                        <p-dropdown formControlName="status"-->
<!--                                                    styleClass="update-ticket-form-drop-down"-->
<!--                                                    [options]="ticketStatusList"-->
<!--                                                    [style]="{'width':'100%'}"-->
<!--                                                    filter="true">-->
<!--                                        </p-dropdown>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="ticket-client-input input-field-container">
                                    <div class="label">
                                        <span>{{'TICKETING_SYSTEM.TABLE.PRIORITY' | translate}}</span>
                                    </div>
                                    <div class="input">
                                        <p-dropdown
                                                formControlName="priority"
                                                [options]="ticketPrioritiesList"
                                                styleClass="update-ticket-form-drop-down"
                                                [style]="{'width':'100%'}"
                                                filter="true">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="auto-complete-form-input-container">
                                    <div class="auto-complete-form-name d-flex">
                                        <div class="auto-complete-form-title">
                                            <span>{{'TICKETING_SYSTEM.TABLE.CATEGORY' | translate}}</span>
                                        </div>
                                        <div class="auto-complete-drop-down flex-1">
                                            <p-autoComplete formControlName="categoryName"
                                                            [ngClass]="{'disabled-btn disabled': isExternal}"
                                                            type="text"
                                                            [suggestions]="categoriesList"
                                                            (completeMethod)="getCategories($event)"
                                                            (onSelect)="chooseCategory($event)"
                                                            styleClass="auto-complete-filter-selection"
                                                            appendTo="body"
                                                            #categoryName
                                            >
                                                <ng-template let-category pTemplate="item">
                                                    <div class="store-item">
                                                        <div class="store-name text-ellipsis">{{category.name}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-autoComplete>
                                        </div>
                                        <div class="close-edit-icons">
                                            <i class="fas fa-chevron-down arrow-icon" *ngIf="!editCategory"
                                               (click)="editCategoryName()"></i>
                                            <i class="fas fa-times close-refresh-soup" *ngIf="editCategory"
                                               (click)="resetCategory()"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="auto-complete-form-input-container">
                                    <div class="auto-complete-form-name d-flex">
                                        <div class="auto-complete-form-title">
                                            <span>{{'TICKETING_SYSTEM.FORM.ASSIGN_TO' | translate}}</span>
                                        </div>
                                        <div class="auto-complete-drop-down flex-1">
                                            <p-autoComplete formControlName="clientName"
                                                            type="text"
                                                            [suggestions]="usersList"
                                                            (completeMethod)="getClients($event)"
                                                            (onSelect)="chooseClient($event)"
                                                            styleClass="auto-complete-filter-selection"
                                                            #clientName
                                            >
                                                <ng-template let-client pTemplate="item">
                                                    <div class="store-item">
                                                        <div class="store-name text-ellipsis">{{client.name}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-autoComplete>
                                        </div>
                                        <div class="close-edit-icons">
                                            <i class="fas fa-chevron-down arrow-icon" *ngIf="!editClient"
                                               (click)="editClientName()"></i>
                                            <i class="fas fa-times close-refresh-soup" *ngIf="editClient"
                                               (click)="resetClient()"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-menu-actions d-flex">
                                <div [ngClass]="{'disabled-btn disabled': isSubmittingForm || updateTicketForm.invalid}"
                                     class="edit-ticket-btn form-menu-action" (click)="updateTicket()">
                                    <span>{{'ACTIONS.EDIT' | translate}}</span>
                                </div>
                                <div *ngIf="isSubmittingForm" class="progress-loader d-flex">
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>
