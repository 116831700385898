import {Component, OnInit} from '@angular/core';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-generate-custom-barcodes',
  templateUrl: './generate-custom-barcodes.component.html',
  styleUrls: ['./generate-custom-barcodes.component.scss']
})
export class GenerateCustomBarcodesComponent implements OnInit {
  public form;
  public isLoading = false;
    public previewValue = null;

  constructor(private formBuilder: FormBuilder,
              private shipmentService: ShipmentsService,
              private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.initForm();
  }
  public initForm() {
      this.form = this.formBuilder.group({
          quantity: [null, [Validators.required, Validators.min(1), Validators.max(1000)]],
        prefix: [''],
          suffix: [''],
          preview: ['', Validators.maxLength(13)]
      });
  }

  public saveButton() {
    const query = this.form.getRawValue();
      delete query.preview;
    this.isLoading = true;
    this.shipmentService.generateBarcodes(query).subscribe(
        (response: any) => {
            window.open(response.url, '_blank');
            this.activeModal.close({isSuccess: true});
            this.isLoading = false;
        }, (err) => {
            this.isLoading = false;
            console.error(err);
        }
    );
  }

  public closeModal() {
    this.activeModal.close();
  }

    updatePreview() {
        let previewValue = '';
        const form = this.form.getRawValue();
        if (form.prefix) {
            previewValue += form.prefix;
        }
        if (form.quantity) {
            previewValue += form.quantity.toString();
        }
        if (form.suffix) {
            previewValue += form.suffix;
        }

        this.form.get('preview').patchValue(previewValue);
    }
}
