<div class="change-package-status-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="header row">
        <div class="change-status-title">
            {{'CHANGE_PACKAGE_STATUS.CHANGE_STATUS_FOR' | translate}}
        </div>
        <div class="header-hidden-space" style="width: 22px"></div>
        <div class="packages-count d-inline-flex">
            <div class="count">{{shipments ? shipments.length : 1}}</div>
            <div style="width: 5px"></div>
            <div class="label">{{'CHANGE_PACKAGE_STATUS.PACKAGE' | translate}}</div>
        </div>
    </div>
    <div class="body">
        <div *ngIf="isShowCurrentStatus" class="d-flex current-status" style="color: #676767">
            <div class="label">
                {{'MANAGE_SHIPMENTS.CURRENT_STATUS' | translate}}:
            </div>
            <div style="width: 30px"></div>
            <div class="status">
                <app-shipment-status [shipment]="shipment">
                </app-shipment-status>
            </div>
        </div>
        <app-change-status-form [shipment]="shipment" [shipments]="shipments" [(form)]="form"></app-change-status-form>
    </div>
    <div class="bottom-actions d-flex justify-content-center">
        <div (click)="onClose($event)" class="cancel-btn d-inline-flex justify-content-center">
            <span>{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.CANCEL' | translate}}</span>
        </div>
        <div style="width: 8px"></div>
        <div class="done-btn d-inline-flex justify-content-center"
             [ngClass]="{'disabled-btn disabled': !form || form.invalid || !validPartiallyDelivered()}"
             (click)="onSaveButton()">
            <span>{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.DONE' | translate}}</span>
        </div>
        <div *ngIf="isPrintReportAfterChangeStatus" class="print-switch flex-1 justify-content-center"
            style="display: inline-grid;">
            <div class="switch-input d-flex" style="gap: 10px;">
                <div class="switch-label" style="margin: 5px;">{{'ACTIONS.PRINT'|translate}}</div>
               <p-inputSwitch [(ngModel)]="isPrintList" binary="false"></p-inputSwitch>
            </div>
        </div>
    </div>
</div>
