import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShipmentsService} from '../services/shipments.service';
import {PackageModel} from '../../shared/models/package.model';
import {FormBuilder} from '@angular/forms';
import {
    IS_TO_FINAL_DESTINATION,
    OPTIONAL_IS_FAILED,
    REQUIRE_DELIVERY_DATE,
    REQUIRE_DRIVER,
    REQUIRE_HUB,
    REQUIRE_HUB_DRIVER_SELECTION,
    REQUIRE_POSTPONED_DELIVERY_DATE,
    REQUIRE_SHELF
} from '../services/constants';
import {DatePipe} from '@angular/common';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {forkJoin, of} from 'rxjs';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {UsersService} from '../../users/services/users.service';

@Component({
    selector: 'app-change-package-status-component',
    templateUrl: 'change-package-status.component.html',
    styleUrls: ['change-package-status.component.scss']
})
export class ChangePackageStatusComponent implements OnInit {
    @Input() shipment: PackageModel = null;
    @Input() shipments: PackageModel[];
    @Input() isShowCurrentStatus = true;
    @Input() isPrintReportAfterChangeStatus = false;
    public isPrintList = false;
    @Output() onFinish:  EventEmitter<{}> = new EventEmitter<{}>();
    @Output() onCancel:  EventEmitter<{}> = new EventEmitter<{}>();
    public form;
    public isLoading = false;
    public isModal = false;
    public isLcl = false;
    companyHadStations = [256, 22];
    companyId;
    private numberOfAttachments = 0;
    isSupportDeliveringPackageItemsPartially = false;
    @Input() propertyName = 'status';

    constructor(private shipmentService: ShipmentsService,
                private formBuilder: FormBuilder,
                private datePipe: DatePipe,
                private activeModal: NgbActiveModal,
                private messageService: MessageService,
                private usersService: UsersService,
                private shipmentsService: ShipmentsService,
                private userService: UserService,
                private translateService: TranslateService,
                public customizationCompaniesService: CustomizationCompanyService,
                private authService: AuthenticationService) {
        this.isLcl = this.userService.userInfo.isLcl;
        this.isSupportDeliveringPackageItemsPartially = this.userService.userInfo.isSupportDeliveringPackageItemsPartially;

    }

    ngOnInit(): void {
        this.companyId = this.authService.companyId;
        if (this.shipment === null && this.shipments !== null) {
            this.initShipment();
        }
    }

    private initShipment() {
        this.shipment = this.shipments[0];
    }


    public onSaveButton() {
        const status = this.form.value.status;

        let body = {};
        if (REQUIRE_SHELF.indexOf(status) !== -1) {
            body = {
                hubId: this.form.value.hubId,
                shelfId: this.form.value.shelfId
            };
        } else if (REQUIRE_HUB.indexOf(status) !== -1) {
            body = {
                hubId: this.form.value.hubId
            };
            if (OPTIONAL_IS_FAILED.indexOf(status) !== -1) {
                body['isFailed'] = this.form.value.isFailed;
                if (this.form.value.failuresNumber) {
                    body['failuresNumber'] = this.form.value.failuresNumber;
                }
            }
        } else if (this.companyHadStations.indexOf(this.companyId) === -1 && REQUIRE_DRIVER.indexOf(status) !== -1 && !this.isLcl) {
            body = {
                driverId: this.form.value.driverId.value,
            };
            if (OPTIONAL_IS_FAILED.indexOf(status) !== -1) {
                body['isFailed'] = this.form.value.isFailed;
                body['notes'] = this.form.value.notes;
                if (this.form.value.failuresNumber) {
                    body['failuresNumber'] = this.form.value.failuresNumber;
                }
            }
        } else if (REQUIRE_POSTPONED_DELIVERY_DATE.indexOf(status) !== -1) {
            body = {
                postponedDeliveryDate: this.transformDate(this.form.value.postponedDate),
                notes: this.form.value.notes
            };
            if (this.form.value.deliveredType === 'hub') {
                body['hubId'] = this.form.value.hubId;
            } else {
                body['driverId'] = this.form.value.driverId.value;
            }
        } else if (REQUIRE_DELIVERY_DATE.indexOf(status) !== -1 && status !== 'RETURNED_BY_RECIPIENT') {
            body = {
                deliveryDate: this.form.value.deliveryDate
            };
            if (this.form.value.deliveredType === 'hub') {
                body['hubId'] =  this.companyHadStations.indexOf(this.companyId) === -1 ? this.form.value.hubId : this.shipment.toHub;
            } else {
                body['driverId'] = this.form.value.driverId.value;
            }

        } else if (REQUIRE_HUB_DRIVER_SELECTION.indexOf(status) !== -1) {
            if (status === 'RETURNED_BY_RECIPIENT' && this.form.value.isDelivered) {
                body['deliveryDate'] = this.form.value.deliveryDate;
                body['isDelivered'] = true;
            } else {
                if (status !== 'PARTIALLY_DELIVERED') {
                    body['isDelivered'] = false;
                }
                if (status === 'PARTIALLY_DELIVERED') {
                    body['deliveryDate'] = this.form.value.deliveryDate;
                }
                if (this.form.value.deliveredType === 'hub') {
                    body['hubId'] = this.form.value.hubId;
                } else {
                    body['driverId'] = this.form.value.driverId.value;
                }
                body['driverReturnReason'] = this.form.controls.failureReasonStatus.value;
                body['isReceiverPaidCost'] = this.form.controls.isReceiverPaidCost.value;
            }
            body['notes'] = this.form.value.notes;
        } else if (this.companyHadStations.indexOf(this.companyId) !== -1 && status === 'SCANNED_BY_DRIVER_AND_IN_CAR') {
            body['driverId'] = this.companyId === 256 ? 5144 : 5143;
        } else if (this.companyHadStations.indexOf(this.companyId) !== -1 && status === 'RECEIVED_AT_DEPARTURE_STATION') {
            body = {
                hubId: this.shipment.fromHub
            };
        } else if (this.companyHadStations.indexOf(this.companyId) !== -1 && ['ARRIVED'].indexOf(status) !== -1) {
            body = {
                hubId: this.shipment.toHub
            };
        } else {
            body = {};
        }
        if (this.isSupportDeliveringPackageItemsPartially && ['PARTIALLY_DELIVERED'].indexOf(status) > -1) {
            body['packageItemsToDeliverList'] = (this.shipment.packageItemsToDeliverList || []).map(v => {
                return {
                    ...v,
                    status: v.isSelected ? 'DELIVERED' : 'RETURNED'
                };
            });
        }
        body['status'] = status;
        if (this.shipments) {
            body['ids'] = this.shipments.map((shipment) => {
                return shipment.id;
            });
        } else {
            body['ids'] = [this.shipment.id];
        }

        if (IS_TO_FINAL_DESTINATION.indexOf(status) !== -1) {
            body['isToFinalDestination'] = this.form.value.isToFinalDestination;
        }
        this.isLoading = true;
        const id = this.shipments ? 0 : this.shipment.id;
        const obList = [this.shipmentService.changePackageStatus(body, id)];
        if (this.customizationCompaniesService.checkCompanies(Companies.JETT)) {
            obList.push(this.uploadAttachments(this.shipment, this.form.get('attachment').value ? [this.form.get('attachment').value] : []));
        }

        forkJoin(obList).subscribe(
            (response: any) => {
                response = response[0];
                Object.keys(body).forEach(key => {
                    this.shipment[key] = body[key];
                });
                if (this.numberOfAttachments > 0) {
                    this.shipment.isAttachmentExist = true;
                }
                this.translateService.get('ALERTS.CHANGED_STATUS').subscribe(
                    (message) => {
                        this.messageService.add({severity: 'success', detail: message});
                    }
                );
                if (this.isPrintReportAfterChangeStatus && this.isPrintList) {
                    this.printRunSheet();
                    return;
                }
                this.isLoading = false;

                if (this.userService.userInfo.isSupportDeliveringPackageItemsPartially && ['SWAPPED', 'PARTIALLY_DELIVERED'].indexOf(status) >= 0) {
                    this.shipmentsService.shipmentAdded();
                }
                this.onFinish.emit({isSuccess: true});
                if (this.isModal) {
                    this.activeModal.close({isSuccess: true});
                }
            }, error => {
                console.error(error);
                this.onFinish.emit({isSuccess: false});
                this.isLoading = false;
                if (this.isModal) {
                    this.activeModal.close();
                }
            }
        );
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    public onClose(event) {
        event.stopPropagation();
        this.onCancel.emit({});
        if (this.isModal) {
            this.activeModal.close();
        }
    }
    public printRunSheet(isWithSignature = false) {
        let selectedPackages = this.shipments ? [0] : [this.shipment.id];
        if (this.shipments) {
            selectedPackages = this.shipments.map((shipment) => {
                return shipment.id;
            });
        }
        this.shipmentsService.downloadMultipleChangeStatus(selectedPackages, null, isWithSignature).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
                this.onFinish.emit({isSuccess: true});
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    uploadAttachments(pkg, files) {
        if (!files.length) {
            return of(true);
        }

        const data = new FormData();
        for (let x = 0; x < files.length; x++) {
            data.append('files', files[x]);
            this.numberOfAttachments++;
        }
        return this.usersService.uploadPackageAttachments(pkg.id, data);
    }

    validPartiallyDelivered() {
        if (this.isSupportDeliveringPackageItemsPartially && this.shipment.packageItemsToDeliverList > 0) {
            const status = this.form.value.status;
            if (status === 'PARTIALLY_DELIVERED') {
                const isOneSelected = this.shipment.packageItemsToDeliverList.some(v => v.isSelected);
                return isOneSelected;
            }
            return true;
        }
        return true;
    }
}
