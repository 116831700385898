import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CURRENCIES_LIST} from '../../companies/companies.constants';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class SharedService {

    constructor(private messageService: MessageService,
                private translateService: TranslateService) {}


   public copyMessage($event, list, value, severityMessage) {
        if (value) {
            if ($event) {
                $event.stopPropagation();
            }
            const val = value;
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = val;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
            if (list) {
                list.hide();
            }
            this.translateService.get(severityMessage).subscribe(
                (message) => {
                    this.messageService.add({severity: 'success', detail: message});
                }
            );
        }
   }

   getUtcOffset() {
        return moment().utcOffset().toString();
   }

   getCurrenciesList() {
      return CURRENCIES_LIST;
   }
}
