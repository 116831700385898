<div class="choose-driver-container">
  <div class="header">
    <div style="display: inline-block;">
      {{headerLabel | translate }}
    </div>
    <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

  </div>
  <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
    <p-progressSpinner>
    </p-progressSpinner>
  </div>
  <div class="input-fields row">
    <div class="col-12 partner-container">
      <div class="inputs row">
        <div class="input-fields row">

          <div  class="row" style="justify-content: space-between!important;">
            <div class="from-container cursor-pointer" style="margin-left: 70px;">
              <i class="pi pi-calendar date-picker-icon" (click)="openDateCalendar()"></i>
              <p-calendar #dateEl styleClass="date-picker" [(ngModel)]="date" (click)="openDateCalendar()"
                          placeholder="{{dateLabel | translate}}"
                          dateFormat="dd/mm/yy"></p-calendar>
            </div>
            <input class="number-input" type="number"  placeholder="{{numberLabel | translate}}" [(ngModel)]="number">
          </div>
         </div>
      </div>
    </div>
  </div>
  <div class="row col-12">
    <div class="flex-1"></div>
    <div style="padding: 10px 30px">
      <div class="add-button cursor-pointer "
           (click)="add()"
           [ngClass]="{'disabled-button disabled': !date || !number}">
        <i class="far fa-save"></i>
        {{ (isEditMode ? 'ACTIONS.EDIT' : 'ACTIONS.ADD') | translate }}
      </div>
      <div class="reset-button cursor-pointer " (click)="closeModal()">
        <i class="fas fa-times"></i>
        {{"ACTIONS.CANCEL" | translate}}
      </div>
    </div>
  </div>
</div>
