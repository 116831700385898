import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FileUploaderComponent} from '../../shared/components/file-uploader/file-uploader.component';
import {ShipmentsService} from '../services/shipments.service';

@Component({
    selector: 'app-add-package-attachments',
    templateUrl: './add-package-attachments.component.html',
    styleUrls: ['./add-package-attachments.component.scss']
})
export class AddPackageAttachmentsComponent implements OnInit {

    isLoading = false;
    markAsLclPaid = false;
    amount: number;

    packageId: number;

    @ViewChild('uploaderComponent')
    uploader: FileUploaderComponent;
    constructor(private activeModal: NgbActiveModal,
                private shipmentService: ShipmentsService) {
    }

    ngOnInit() {
    }

    close(isSuccess?) {
        if (isSuccess !== undefined) {
            this.activeModal.close({isSuccess: isSuccess});
        }
        this.activeModal.close();
    }

    onSubmit() {
        this.isLoading = true;
        if (this.markAsLclPaid) {
            this.shipmentService.markAsLclPaid(this.packageId, this.amount,  this.uploader.files).subscribe(() => {
                this.shipmentService.shipmentAdded();
                this.close(true);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
        } else {
            this.shipmentService.uploadShipmentAttachments(this.packageId, this.uploader.files, 'ADD_ATTACHMENT').subscribe((res) => {
                this.shipmentService.shipmentAdded();
                this.close(true);
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
            });
        }
    }
}
