import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PARTNER_CONSTANTS} from './partner-constants';

@Injectable({
    providedIn: 'root'
})
export class PartnersService {

    constructor(private httpClient: HttpClient) {
    }

    public getPartners(query) {
        return this.httpClient.get(PARTNER_CONSTANTS.GET_ACTIVE_PARTNERS, {params: query});
    }

    public generateCode() {
        return this.httpClient.post(PARTNER_CONSTANTS.GENERATE_PARTNER_CODE, {});
    }

    public addPartner(params) {
        return this.httpClient.post(PARTNER_CONSTANTS.ADD_PARTNER, {}, {params});
    }

    public getPartnerPackages(params) {
        return this.httpClient.get(PARTNER_CONSTANTS.GET_PARTNER_PACKAGES, {params});
    }

    public getPartnerCODPackages(params) {
        return this.httpClient.get(PARTNER_CONSTANTS.GET_PARTNER_COD_PACKAGES, {params});
    }

    public activatePartner(partnerId, customerId) {
        return this.httpClient.put(PARTNER_CONSTANTS.ACTIVATE_PARTNER, {customerId, partnerId});
    }

    public checkIfPartnerActive(partnerId) {
        return this.httpClient.get(PARTNER_CONSTANTS.GET_PARTNER_ACTIVATED.replace('{partnerId}', partnerId));
    }

    public cancelPartner(partnerId) {
        return this.httpClient.delete(PARTNER_CONSTANTS.CANCEL_PARTNER.replace('{partnerId}', partnerId));
    }

    exportPartnerCodPackagesToExcel(body, params) {
        return this.httpClient.post(PARTNER_CONSTANTS.EXPORT_PARTNER_COD_PACKAGES_TO_EXCEL, body, {params});
    }

    getUndeliveredReport(params: { pageSize: number; page: number }) {
        return this.httpClient.get(PARTNER_CONSTANTS.GET_UNDELIVERED_PARTNER_PACKAGES, {params});
    }

    downloadFileForReport(params: any, type: 'excel' | 'pdf') {
        return this.httpClient.get(PARTNER_CONSTANTS.GET_UNDELIVERED_PARTNER_PACKAGES + '/' + type, {params});
    }
}
