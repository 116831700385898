export const QUERY_PARAMS_FILTERS = {
    STATUS: 'ALL',
    SEARCH: 'SEARCH'
};
export const SEARCH_QUERY_LIST = {
    BARCODE: 'BARCODE',
    CUSTOMER_IDS: ['CUSTOMER_IDS'],
    RECEIVER_PHONE: 'RECEIVER_PHONE',
    INVOICE_NUMBER: 'INVOICE_NUMBER'
};
export const CUSTOMERS_FILTER = [];
export const COD_STATUS_FILTER = [];
