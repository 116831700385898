import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ParseServerConstantPipe} from '../pipes/parse-server-constant';
import {AddressFormatPipePipe} from '../pipes/address-format.pipe';
import {UploadImageComponent} from '../components/upload-image/upload-image.component';
import {SafeStylePipe} from '../pipes/safe-style.pipe';
import {SharedService} from '../services/shared-service';
import {TooltipModule} from 'primeng/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {DebounceSelectQueryDirective} from '../directives/debounce-select-query.directive';
import {BarcodeScannerDirective} from '../directives/barcode-scanner.directive';
import {InputDebouncedDirective} from '../directives/input-debounced.directive';
import { AddressSystemPipe } from '../pipes/address-system.pipe';
import {CurrencyValuePipe} from '../pipes/currency-value.pipe';
import {PackageTitlePipe} from '../pipes/package-title.pipe';
import {NumericInputDirective} from '../directives/numeric-input.directive';
import {ElapsedTimePipe} from '../pipes/elapsed-time.pipe';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DecimalDigitsPipe} from '../pipes/decimal-digits.pipe';
import {AppInputPositiveNumberDirective} from '../directives/positive-number-input.directive';
import {TableModule} from 'primeng/table';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ProgressBarModule} from 'primeng/progressbar';
import {InplaceModule} from 'primeng/inplace';
import {FileUploadModule} from 'primeng/fileupload';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DropdownModule} from 'primeng/dropdown';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SidebarModule} from 'primeng/sidebar';
import {CalendarModule} from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import { PaginatorModule} from 'primeng/paginator';
import {ToastModule} from 'primeng/toast';
import {AutoFocusDirective} from '../directives/auto-focus.directive';
import {HubTitlePipe} from '../pipes/hub-title-pipe';
import {TrimInputDirective} from '../directives/trim.directive';
import {ChangePackageStatusComponent} from '../../shipment/change-package-status/change-package-status.component';
import {ChangeStatusFormComponent} from '../../shipment/change-status-form/change-status-form.component';
import {MobileCardsListComponent} from '../components/mobile-cards-list/mobile-cards-list.component';
import {DateRangePickerComponent} from '../components/date-range-picker/date-range-picker.component';
import {MapW3wComponent} from '../components/map-w3w/map-w3w.component';
import {MessageComponent} from '../components/message/message.component';
import {AutoCompleteComponent} from '../components/form-components/auto-complete/auto-complete.component';
import {CalendarComponent} from '../components/form-components/calendar/calendar.component';
import {MultiSelectComponent} from '../components/form-components/multi-select/multi-select.component';
import {FieldErrorMessageComponent} from '../components/field-error-message/field-error-message.component';
import {FieldInputComponent} from '../components/form-components/field-input/field-input.component';
import {FieldSetFormComponent} from '../components/form-components/field-set-form/field-set-form.component';
import {SystemShuttingDownComponent} from '../components/system-shutting-down/system-shutting-down.component';
import {PermissionDirective} from '../directives/permission.directive';
import {PTableRowsCalculatorDirective} from '../directives/p-table-rows-calculator.directive';
import {AppPasswordDirective} from '../directives/app-password.directive';
import {StopPropagationDirective} from '../directives/stop-propagation';
import {FieldsetModule} from 'primeng/fieldset';
import {CardModule} from 'primeng/card';
import {HorizontalSpinnerComponent} from '../components/horizontal-spinner/horizontal-spinner-component';
import {SearchComponent} from '../components/search/search.component';
import {SkeletonLoaderComponent} from '../components/skeleton-loader/skeleton-loader.component';
import {RouterLink, RouterModule} from '@angular/router';
import {InputFieldComponent} from '../components/input-field/input-field.component';
import {FormGroupComponent} from '../components/form-group/form-group.component';
import {NoDataAvailableComponent} from '../components/no-data-available/no-data-available.component';
import {AlertComponent} from '../components/alert/alert.component';
import {PackagesTableViewComponent} from '../components/packages-table-view/packages-table-view.component';
import {AdminReportComponent} from '../components/admin-report/admin-report.component';
import {UserListDropdownComponent} from '../components/users-list-dropdown/users-list-dropdown.component';
import {TrackPackageLineComponent} from '../components/track-package-line/track-package-line.component';
import {Page404Component} from '../components/404/404.component';
import {ResetPasswordComponent} from '../components/reset-password/reset-password.component';

import {EnterValueComponent} from '../components/enter-value/enter-value.component';
import {EditCostComponent} from '../components/edit-cost/edit-cost.component';
import {ImageSliderComponent} from '../components/image-slider/image-slider.component';
import {ChooseZoneLocationComponent} from '../components/choose-zone-location/choose-zone-location.component';
import {FileUploaderComponent} from '../components/file-uploader/file-uploader.component';
import {CustomDropDownListComponent} from '../components/custom-drop-down-list/custom-drop-down-list.component';
import {CustomConfirmationMessageComponent} from '../components/custom-confirmation-message/custom-confirmation-message.component';
import {MapComponent} from '../components/map/map.component';
import {ShipmentStatusComponent} from '../../shipment/manage-shipments/shipment-status/shipment-status.component';
import {PaymentCardInformationComponent} from '../components/payment-card-information/payment-card-information.component';
import {GalleriaModule} from 'primeng/galleria';
import {PhoneNumberInputComponent} from '../components/phone-number-input/phone-number-input.component';
import {GenericTableComponent} from '../components/generic-table/generic-table.component';
import {MessageModule} from 'primeng/message';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {DropDownComponent} from '../components/form-components/drop-down/drop-down.component';
import {ButtonComponent} from '../components/form-components/button/button.component';

import {NgModule} from '@angular/core';

const SHARED_COMPONENTS = [
    GenericTableComponent,
    PhoneNumberInputComponent,
    HorizontalSpinnerComponent,
    SearchComponent,
    SkeletonLoaderComponent,
    InputFieldComponent,
    FormGroupComponent,
    NoDataAvailableComponent,
    AlertComponent,
    PackagesTableViewComponent,
    AdminReportComponent,
    UserListDropdownComponent,
    TrackPackageLineComponent,
    Page404Component,
    ResetPasswordComponent,
    EnterValueComponent,
    EditCostComponent,
    ImageSliderComponent,
    ChooseZoneLocationComponent,
    FileUploaderComponent,
    CustomDropDownListComponent,
    CustomConfirmationMessageComponent,
    MapComponent,
    PaymentCardInformationComponent,
    ShipmentStatusComponent,
    ChangePackageStatusComponent,
    ChangeStatusFormComponent,
    MobileCardsListComponent,
    DateRangePickerComponent,
    MapW3wComponent,
    MessageComponent,
    AutoCompleteComponent,
    CalendarComponent,
    DropDownComponent,
    MultiSelectComponent,
    FieldErrorMessageComponent,
    FieldInputComponent,
    FieldSetFormComponent,
    UploadImageComponent,
    SystemShuttingDownComponent,
    ButtonComponent
];

const SHARED_DIRECTIVES = [
    PermissionDirective,
    PTableRowsCalculatorDirective,
    // PTableRtlFixDirective,
    AppPasswordDirective,
    DebounceSelectQueryDirective,
    BarcodeScannerDirective,
    InputDebouncedDirective,
    TrimInputDirective,
    NumericInputDirective,
    AppInputPositiveNumberDirective,
    AutoFocusDirective,
    StopPropagationDirective
];

const SHARED_PIPE = [
    ParseServerConstantPipe,
    AddressFormatPipePipe,
    HubTitlePipe,
    SafeStylePipe,
    AddressSystemPipe,
    CurrencyValuePipe,
    PackageTitlePipe,
    ElapsedTimePipe,
    DecimalDigitsPipe,
];
@NgModule({
    declarations: [
        ...SHARED_PIPE,
        ...SHARED_DIRECTIVES,
        ...SHARED_COMPONENTS
    ],
    imports: [
        PaginatorModule,
        OverlayPanelModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule,
        AutoCompleteModule,
        InputTextModule,
        TooltipModule,
        DialogModule,
        NgSelectModule,
        InplaceModule,
        ProgressBarModule,
        FileUploadModule,
        MultiSelectModule,
        SelectButtonModule,
        ToggleButtonModule,
        TableModule,
        OverlayPanelModule,
        DropdownModule,
        ProgressSpinnerModule,
        SidebarModule,
        RadioButtonModule,
        CheckboxModule,
        CalendarModule,
        ConfirmDialogModule,
        InputSwitchModule,
        ToastModule,
        FieldsetModule,
        CardModule,
        InputTextareaModule,
        NgxIntlTelInputModule,
        RouterLink
    ],
    exports: [
        PaginatorModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        SidebarModule,
        RadioButtonModule,
        CheckboxModule,
        CalendarModule,
        ConfirmDialogModule,
        InputSwitchModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule,
        UploadImageComponent,
        AutoCompleteModule,
        TooltipModule,
        DialogModule,
        NgSelectModule,
        InplaceModule,
        InputTextModule,
        ProgressBarModule,
        FileUploadModule,
        MultiSelectModule,
        SelectButtonModule,
        ToggleButtonModule,
        TableModule,
        OverlayPanelModule,
        DropdownModule,
        ToastModule,
        ...SHARED_PIPE,
        ...SHARED_COMPONENTS,
        ...SHARED_DIRECTIVES
    ],
    providers: [
        SharedService,
        AddressSystemPipe,
        PackageTitlePipe,
        HubTitlePipe,
        RouterModule,
        SharedModule,
        TableModule,
        ToastModule,
        CalendarModule,
        DropdownModule,
        TooltipModule,
        ProgressSpinnerModule,
        AutoCompleteModule,
        CheckboxModule,
        GalleriaModule,
        AutoCompleteModule,
        CardModule,
        OverlayPanelModule,
        InputSwitchModule,
        RadioButtonModule,
        NgxIntlTelInputModule,
        MessageModule,
        FieldsetModule,
        InputTextareaModule
    ]
})
export class SharedModule {
}
