import {Component, OnInit, ViewChild} from '@angular/core';
import {LclItemModel} from './lcl-item.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Inplace} from 'primeng/inplace';
import {ConfirmationService, MessageService} from 'primeng/api';
import {of} from 'rxjs/index';
import {Subject} from 'rxjs';
import {LclSupplierService} from '../services/lcl-supplier.service';
import {CreateSupplierComponent} from '../create-supplier/create-supplier.component';

@Component({
    selector: 'app-add-lcl-items',
    templateUrl: './add-lcl-items.component.html',
    styleUrls: ['./add-lcl-items.component.scss']
})
export class AddLclItemsComponent implements OnInit {

    items: LclItemModel[] = [];

    newItemForm: FormGroup;
    lastTriggerredInplace: Inplace;
    triggerredInplaceValue: any;
    length;
    height;
    width;
    isNaN: any = isNaN;
    supplierLoading = false;
    supplierList = [];
    supplierSubject = new Subject<string>();
    viewOnly = false;
    CreateNewSupplierBinded = this.CreateNewSupplier.bind(this);

    @ViewChild('supplierElement') supplierElement;

    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private modalService: NgbModal,
                private messageService: MessageService,
                private translateService: TranslateService,
                private lclSupplierService: LclSupplierService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.initForm();
        this.supplierSubject.subscribe(res => {
            this.getSuppliers(res);
        });
    }

    initForm() {
        this.newItemForm = this.formBuilder.group({
            supplier: ['', Validators.required],
            productName: ['', Validators.required],
            description: [],
            quantity: ['', Validators.required],
            boxRange: [{value: '', disabled: true}],
            boxQuantity: ['', Validators.required],
            numberOfItems: [],
            width: [],
            length: [],
            height: [],
            weight: [],
            cbm: []
        });
    }

    closeModal(result?) {
        if (result) {
            this.activeModal.close(result);
        }
        this.activeModal.close();
    }
    setBoxNo() {
        if (this.newItemForm.get('boxQuantity').value > 100) {
            this.translateService.get('ACTIONS.CONFIRM_ADD_MORE_THAN_100_BOX').subscribe(
                (translateValue) => {
                    this.messageService.add({severity: 'error', detail: translateValue});
                }
            );
        } else {
            this.setBoxNoByIndex(this.items.length);
        }
    }

    setBoxNoByIndex(index) {
        if (index < 0 || index > this.items.length) {
            return;
        }
        let prevBoxNumber = 0;
        if (index - 1 >= 0) {
            const lastRowIdx = index - 1;
            const lastBoxValue = this.items[lastRowIdx].boxRange.toString().split('-');
            prevBoxNumber = Number(lastBoxValue.length === 1 ? lastBoxValue[0] : lastBoxValue[1]);
        }
        const boxQuantity = index === this.items.length ? this.newItemForm.get('boxQuantity').value : this.items[index].boxQuantity;
        const newVal = boxQuantity  > 1 ? (prevBoxNumber + 1) + '-' + (prevBoxNumber + (boxQuantity)) : prevBoxNumber + 1 + '';
        index === this.items.length ? this.newItemForm.get('boxRange').patchValue(newVal) : this.items[index].boxRange = newVal;
    }

    onDeleteItem(item: LclItemModel, index: number) {
        this.items.splice(index, 1);
        for (let i = index; i < this.items.length; i++) {
            this.setBoxNoByIndex(i);
        }
    }

    onSaveItem() {
        this.items.push(this.newItemForm.getRawValue());
        this.newItemForm.reset();
    }

    onActivateInplace(event: any, inplace: Inplace, value: any) {
        if (this.lastTriggerredInplace && this.lastTriggerredInplace.active) {
            this.lastTriggerredInplace.deactivate(event);
        }
        this.lastTriggerredInplace = inplace;
        this.triggerredInplaceValue = value;
    }

    onSaveInplace(event: MouseEvent | KeyboardEvent, item: LclItemModel, colName: string, required = false) {
        event.stopPropagation();
        if (required && !this.triggerredInplaceValue) {
            return;
        }
        item[colName] = this.triggerredInplaceValue;
        this.lastTriggerredInplace.deactivate(event);
    }

    onCancelInplace(event: MouseEvent | KeyboardEvent) {
        this.lastTriggerredInplace.deactivate(event);
    }

    onSubmit() {
        if (this.viewOnly) {
            this.closeModal({
                isSuccess: true,
                items: this.items
            });
            return;
        }
        this.translateService.get(['GENERAL.YES', 'GENERAL.NO', 'ACTIONS.CONFIRM_DONE']).subscribe(values => {
            this.confirmationService.confirm({
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                message: values['ACTIONS.CONFIRM_DONE'],
                accept: () => {
                    this.closeModal({
                        isSuccess: true,
                        items: this.items
                    });
                }
            });
        });
    }

    onCancel() {
        this.translateService.get(['GENERAL.YES', 'GENERAL.NO', 'ACTIONS.CONFIRM_CANCEL']).subscribe(values => {
            this.confirmationService.confirm({
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                message: values['ACTIONS.CONFIRM_CANCEL'],
                accept: () => {
                    this.closeModal();
                }
            });
        });
    }

    public getSuppliers(event?) {
        this.supplierLoading = true;
        this.lclSupplierService.getLclSupplier({search: event || ''}).subscribe(
            (response: any) => {
                this.supplierList = (response.data || []).map(o => {
                    return {label: o.brandName, value: o};
                });
                this.supplierLoading = false;
            }, () => {
                this.supplierList = [];
                this.supplierLoading = false;
            }
        );
    }

    chooseSupplier(supplier, item) {
        item.supplier = supplier;
    }

    CreateNewSupplier($event) {
        this.supplierElement.close();
        const modal = this.modalService.open(CreateSupplierComponent, {
            size: 'lg',
            windowClass: 'supplier-items-modal',
            backdrop: 'static',
            container: 'body',
            backdropClass: 'supplier-items-modal-backdrop'
        });
        modal.componentInstance.initialBrandName = $event;
        modal.result.then(
            (status) => {
                if (status.created) {
                    this.supplierElement.open();
                    this.getSuppliers($event);
                }
            }
        ).catch(
            (error) => {
            }
        );
    }
}
