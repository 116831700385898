<div class="bulk-update-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{'ACCOUNTING.RECEIVE_IN_HUB_CUSTODY' | translate | packageTitle}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal()"></i>
        </div>
    </div>
    <div class="body">
        <div class="label">{{'CHANGE_PACKAGE_STATUS.HUB_NAME' | translate | hubTitle}}</div>
        <div class="hub-hidden-space"></div>
        <div class="hub flex-1">
            <app-input-field-component [form]="form"
                                       controlName="hubId"
                                       [options]="hubsOptions"
                                       controlType="select"
                                       [isRequired]="true">
            </app-input-field-component >
        </div>
    </div>

    <div class="bottom-actions d-flex">
        <div class="flex-1"></div>
        <div (click)="closeModal()" class="cancel-btn d-inline-flex justify-content-center">
            <span>{{'ACTIONS.CANCEL' | translate}}</span>
        </div>
        <div  class="done-btn d-inline-flex justify-content-center"
              [ngClass]="{'disabled-btn disabled': form.invalid}"
              (click)="updatePackages()">
            <span>{{'ACTIONS.NEXT' | translate}}</span>
        </div>
    </div>

</div>
