<div class="page-rtl" [ngClass]="{'page-ltr': currentLang === 'en'}" style="z-index:2222222">
    <div class="main-container {{styleClass}}">
        <div class="close-custom-confirmation" (click)="closeModal(false)">
            <img src="../../assets/icons/close-x.svg" alt=""/>
        </div>
        <div class="confirmation-message-title-container">
            <div class="confirmation-message-title">
                <span>{{title | translate}}</span>
            </div>
        </div>
        <div class="confirmation-message">
            <span>{{confirmMessage | translate}}</span>
            <div *ngIf="switchButtonLabel.length"
                 class="switch-input-container d-flex">
                <div class="switch-input">
                    <p-inputSwitch [(ngModel)]="switchButton"></p-inputSwitch>
                </div>
                <div class="label">{{switchButtonLabel | translate}}</div>
            </div>
        </div>
        <div class="custom-confirmation-actions d-flex">
            <div class="cancel flex-2d-center cursor-pointer" (click)="closeModal(false)"><span>{{'ACTIONS.CANCEL' | translate}}</span></div>
            <div class="confirm flex-2d-center cursor-pointer" (click)="closeModal(true)"><span>{{confirmButtonLabel | translate}}</span></div>
        </div>
    </div>
</div>

