<div class="shared-close-icon" (click)="closeModal()"><img src="../../../../assets/icons/close-icon.svg" alt=""/></div>
<div class="bdf-viewer-container d-flex h-100 align-items-center justify-content-center">
    <iframe width="640" height="360" [src]="videoUrl" (load)="hideSpinner()"
            frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
</div>

<div class="loader-container" *ngIf="showLoader">
    <div class="loading">
        <span><i class="fas fa-spinner fa-pulse"></i></span>
    </div>
</div>

