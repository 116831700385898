<div class="documents-updates-main-container w-100 flex-1" (scroll)="onsScrollUpdateTable($event)" #tableContainer>
    <div class="added-recently documents-list-container">
        <div class="list-title">{{"SYSTEM_UPDATES.RECENTLY_ADDED" | translate}}</div>
        <div class="documents-list d-flex">
            <div *ngFor="let video of recentlyAdded" class="document-item d-flex flex-column cursor-pointer col-sm-2 col-md-4 col-lg-3" (click)="openVideoPlayer(video)">
                <div class="icon-container d-flex justify-content-center video-container">
                    <div class="iframe-overlay">
                        <i class="fa-play fas"></i>
                    </div>
                    <img [src]="getThumbnailImage(video)" alt>

                </div>
                <div class="d-flex justify-content-between">
                    <div class="desc d-flex flex-column">
                        <div class="document-name desc-text">
                            {{selectedLanguage === 'en' ? video.name : video.arabicName}}
                        </div>
                        <div class="document-sub-desc desc-text">
                            {{selectedLanguage === 'en' ? video.summary : video.arabicSummary}}
                        </div>
                        <div class="created-date">
                            {{ video.createdDate | date : 'dd/MM/yyyy' }}
                        </div>
                    </div>
                    <div>
                        <div class="category" *ngIf="video.category">{{'SYSTEM_UPDATES.DROPDOWN.'+video.category | translate}}</div>

                        <div *ngIf="isSuperAdmin">
                            <div class="d-flex justify-content-end">
                                <div class="cursor-pointer d-flex justify-content-center" style="width: 30px"
                                     (click)="triggerUpdateDropdown($event, updateOptions, video)">
                                    <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="spinnerState === SpinnerState.LOADING && recentlyAdded.length === 0">
            <div style="height: 200px" class="d-flex align-items-center">
                <div class="d-flex justify-content-center" style="width: 100%">
                    <div class="dot-flashing"></div>
                </div>
            </div>
        </div>

        <div class="d-flex h-100 position-relative w-100" *ngIf="spinnerState === SpinnerState.EMPTY && recentlyAdded.length === 0">
            <app-no-data-available-component>
            </app-no-data-available-component>
        </div>
    </div>

    <div class="all-documents documents-list-container">
        <div class="list-title">{{"SYSTEM_UPDATES.ALL_VIDEOS" | translate}}</div>
        <div class="d-flex categories-container flex-wrap">
            <div *ngFor="let category of categories" class="category cursor-pointer"  [ngClass]="{active: selectedCategory === category.value}" (click)="selectCategory(category)">{{category.label | translate}}</div>
        </div>
        <div class="documents-list d-flex flex-wrap">
            <div *ngFor="let video of updatesData" class="document-item d-flex flex-column cursor-pointer col-sm-2 col-md-4 col-lg-3" (click)="openVideoPlayer(video)">
                <div class="icon-container d-flex justify-content-center video-container">
                    <div class="iframe-overlay">
                        <i class="fa-play fas"></i>
                    </div>
                    <img [src]="getThumbnailImage(video)">

                </div>
                <div class="d-flex justify-content-between">
                    <div class="desc d-flex flex-column">
                        <div class="document-name desc-text">
                            {{selectedLanguage === 'en' ? video.name : video.arabicName}}
                        </div>
                        <div class="document-sub-desc desc-text">
                            {{selectedLanguage === 'en' ? video.summary : video.arabicSummary}}
                        </div>
                        <div class="created-date">
                            {{ video.createdDate | date : 'dd/MM/yyyy' }}
                        </div>
                    </div>
                    <div>
                        <div class="category" *ngIf="video.category">{{'SYSTEM_UPDATES.DROPDOWN.'+video.category | translate}}</div>

                        <div *ngIf="isSuperAdmin">
                            <div class="d-flex justify-content-end">
                                <div class="cursor-pointer d-flex justify-content-center" style="width: 30px"
                                     (click)="triggerUpdateDropdown($event, updateOptions, video)">
                                    <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="spinnerState === SpinnerState.LOADING">
            <div style="height: 200px" class="d-flex align-items-center">
                <div class="d-flex justify-content-center" style="width: 100%">
                    <div class="dot-flashing"></div>
                </div>
            </div>
        </div>
        <div class="d-flex h-100 position-relative w-100" *ngIf="spinnerState === SpinnerState.EMPTY">
            <app-no-data-available-component>
            </app-no-data-available-component>
        </div>
    </div>
</div>
<div class="totals d-flex w-100 justify-content-center align-items-center">

    <div class="info">
        <div class="info-value">{{totalNumberOfUpdates}}</div>
        <div class="info-title">{{"SYSTEM_UPDATES.VIDEO_SUM" | translate}}</div>
    </div>
    <div class="info">
        <div class="info-value">{{unseenVideos}}</div>
        <div class="info-title">{{"SYSTEM_UPDATES.UNSEEN_VIDEO_SUM" | translate}}</div>
    </div>

</div>
<p-overlayPanel #updateOptions [dismissable]="true" baseZIndex="999999" appendTo="body" styleClass="update-popover">
    <div class="row-action-container">
        <div
                class="action cursor-pointer more-action-item" (click)="editUpdate()">
            <img class="edit-button" src="../../../assets/icons/manage_shipments/edit.svg"
                 alt="icon"/>
            {{'SYSTEM_UPDATES.EDIT' | translate}}
        </div>
        <div
                class="action cursor-pointer more-action-item" (click)="removeUpdate()">
            <img class="remove-button" src="../../../assets/icons/manage_shipments/remove.svg"
                 alt="icon"/>
            {{'SYSTEM_UPDATES.REMOVE' | translate | packageTitle}}
        </div>
    </div>
</p-overlayPanel>
