<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div *ngIf="isModal" class="row close-modal" style="float: left;">
        <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt="" />
    </div>
    <div class="main-container" [ngClass]="{'is-modal': isModal}">
        <div *ngIf="showHeader" class="title-container d-inline-flex">
            <div class="icon">
                <img src="../../assets/icons/attachment.svg" alt="" />
            </div>
            <div style="width: 20px;"></div>
            <div class="row justify-content-between">
                <div class="label">{{(isSignature ? 'MANAGE_SHIPMENTS.SIGNATURE': 'GENERAL.ATTACHMENT') | translate}}</div>
            </div>
        </div>
        <div class="loader" *ngIf="isLoading">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
       <div class="images-container" [ngClass]="{'justify-content-center': isModal}">
           <ngb-carousel *ngIf="images.length && !isLoading" [interval]="0" [showNavigationArrows]="images.length > 1" [showNavigationIndicators]="images.length > 1">
               <ng-template ngbSlide *ngFor="let image of images">
                   <div class="picsum-img-wrapper">
                       <img [src]="image" />
                   </div>
               </ng-template>
           </ngb-carousel>
       </div>
    </div>
    <div *ngIf="!isLoading && isModal && showActions" class="upload-image-action-container d-inline-flex" (click)="addImage(fileChooser)" [ngClass]="{'remove-image': isRemoveMode()}">
        <div class="icon">
            <img src="assets\icons\upload-image.svg" />
        </div>
        <div style="width: 10px;"></div>
        <div class="label" *ngIf="!isRemoveMode()">Upload</div>
        <div class="label" *ngIf="isRemoveMode()">Remove</div>
        <input type="file" accept="image/x-png,image/gif,image/jpeg,image/x-icon" style="width: 0px; height: 0px; opacity: 0; position: absolute;" #fileChooser (change)="onFileSelected($event)" />
    </div>
</div>
