<div class="bulk-update-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{(!isCodMassPackage ? title : (titleLabel || "SHIPMENT.PACKAGES_TO_RECEIVE")) | translate | packageTitle}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal(sentOnce)"></i>
        </div>
    </div>
    <div class="body">
        <app-bulk-update-barcodes [isLoading]="isLoading" [barcodes]="barcodes" [finishUpdating]="finishUpdating"
                                  [sentOnce]="sentOnce" [form]="form"></app-bulk-update-barcodes>

        <div class="status">
            <app-change-status-form *ngIf="isChangeStatus" [(form)]="form"></app-change-status-form>
        </div>
        <app-input-field-component *ngIf="isReceive && isCodMassPackage" [form]="form" label="ACCOUNTING.RECEIVED_COD"
                                   controlName="cod" controlType="number"
                                   [isRequired]="true">
        </app-input-field-component >
        <app-input-field-component *ngIf="isReceive && isCodMassPackage" [form]="form" label="ACCOUNTING.EXPENSES"
                                   controlName="expenses" controlType="number"
                                   [isRequired]="false">
        </app-input-field-component >
        <app-input-field-component *ngIf="isReceive && isCodMassPackage" [form]="form" label="ACCOUNTING.EXPENSE_TYPE"
                                   controlName="expensesType" controlType="text"
                                   [isRequired]="false">
        </app-input-field-component >
    </div>
    <div class="bottom-actions d-flex">
        <div class="flex-1"></div>
        <div (click)="closeModal(sentOnce)" class="cancel-btn d-inline-flex justify-content-center">
            <span>{{'ACTIONS.CANCEL' | translate}}</span>
        </div>
        <div  *ngIf="!sentOnce && isCodMassPackage"
              class="done-btn d-inline-flex justify-content-center"
             [ngClass]="{'disabled-btn disabled': form.invalid}"
             (click)="updatePackages()">
            <span>{{(finishReceiveLabel || 'ACCOUNTING.FINISH_RECEIVE_BULK') | translate}}</span>
        </div>
        <div  *ngIf="!isCodMassPackage && !sentOnce && !receiveBulkBarcodes"
              class="done-btn d-inline-flex justify-content-center"
              (click)="updatePackages()">
            <span>{{'ACCOUNTING.FINISH_RECEIVE_PACKAGES' | translate}}</span>
        </div>
        <div  *ngIf="receiveBulkBarcodes && !sentOnce"
              class="done-btn d-inline-flex justify-content-center"
              (click)="updatePackages()">
            <span>{{'ACCOUNTING.SORT' | translate}}</span>
        </div>
        <div  *ngIf="sentOnce && !finishUpdating && isCodMassPackage"
              class="done-btn d-inline-flex justify-content-center"
              [ngClass]="{'disabled-btn disabled': form.invalid}"
              (click)="updatePackages()">
            <span>{{'ACTIONS.RETRY_FAILED_OPS' | translate}}</span>
        </div>
        <div  *ngIf="finishUpdating && isCodMassPackage"
              class="done-btn d-inline-flex justify-content-center"
              [ngClass]="{'disabled-btn disabled': form.invalid}"
              (click)="doneUpdating()">
            <span>{{'ACTIONS.DONE' | translate}}</span>
        </div>
    </div>
</div>
