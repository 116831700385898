<div class="d-flex flex-column h-100 side-menu-container" [ngClass]="{'min-menu': minimizeMenu}">
    <div style="height: 0; overflow: visible">
        <div style="overflow: hidden">
            <div class="top-background"></div>
        </div>
    </div>
    <div class="menu-top-image">
        <div class="image-container contain-image" *ngIf="logo"  [ngStyle]="{'background-image': 'url(' + logo + ')'}">
        </div>
    </div>
<!--    <div style="width: 50px;height: 50px;background-color: red">-->
<!--        <i class="fas fa-plus"></i>-->
<!--    </div>-->
    <div class="toggle-button">
        <span class="icon hidden-sm-down" (click)="toggleSideMenu()">
            <i *ngIf="currentLang === 'ar'" class="fas" [ngClass]="{'fa-chevron-right': !minimizeMenu, 'fa-chevron-left': minimizeMenu}"></i>
            <i *ngIf="currentLang === 'en'" class="fa" [ngClass]="{'fa-chevron-left': !minimizeMenu, 'fa-chevron-right': minimizeMenu}"></i>
        </span>
        <span class="icon hidden-md-up">
            <i class="fas fa-times" (click)="hideMenu()"></i>
        </span>
    </div>
    <div class="company-title" *ngIf="!minimizeMenu">
        {{companyName}}
    </div>
    <div class="items">
        <div  *ngFor="let item of menuItems; let i = index">
            <app-side-menu-item-component  [item]="item" (onSelectSubMenu)="onItemSelected($event)">
            </app-side-menu-item-component>
        </div>
        <div class="version" *ngIf="version">
            {{"VERSION" | translate}} : {{version}}
        </div>
    </div>
</div>
