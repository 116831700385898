import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {Calendar} from 'primeng/calendar';
import {UsersService} from '../../../users/services/users.service';


@Component({
    selector: 'app-company-billing-payment',
    templateUrl: './choose-date-and-number.component.html',
    styleUrls: ['./choose-date-and-number.component.scss']
})
export class ChooseDateAndNumberComponent implements OnInit{
    public isLoading = false;
    public date;
    public number;
    public headerLabel = '';
    public dateLabel = '';
    public numberLabel = '';
    public isEditMode = false;
    public dateString;
    public model = null;
    @ViewChild('dateEl', {static: false}) dateEl: Calendar;
    constructor(private activeModal: NgbActiveModal,
                private usersService: UsersService,
                private datePipe: DatePipe) {
    }
    ngOnInit() {
        if (this.isEditMode) {
            this.initData();
        }
    }

   public initData() {
        if (this.model) {
            this.date = new Date(this.model.startNotificationDate);
            this.number = this.model.daysNumber;
        }
    }
    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.isLoading = false;
            this.activeModal.close({isSuccess: false});
        }
    }
    public closeModal() {
        this.isLoading = false;
        this.activeModal.close({isSuccess: false});
    }


    public add() {
        this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
        this.activeModal.close({isSuccess: true, date: this.date, number: this.number});
    }

    public openDateCalendar() {
        this.dateEl.showOverlay();
        this.dateEl.inputfieldViewChild.nativeElement.dispatchEvent(new Event('focus'));
        this.dateEl.inputfieldViewChild.nativeElement.dispatchEvent(new Event('click'));
    }
}
