<p-toast [baseZIndex]="toastZIndex"></p-toast>

<div class="cod-customers-view d-flex flex-column h-100">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="header-filters filters-container d-flex flex-row">

        <div *ngIf="receive||sort || export" class="cod-title flex-1">
            {{(isHoldCustody ? "MANAGE_COD_CUSTOMERS." + holdCustodyBy + "_NAME" : "MANAGE_COD_CUSTOMERS.CUSTOMER_NAME") | translate }}
            : {{isHoldCustody ? entityName : customerName}}
        </div>

        <div *ngIf="!(receive||sort||export)" class="cod-title flex-1">
            {{ (mismatch ? "ADMINISTRATION.MISMATCH_COD" : "MANAGE_COD_CUSTOMERS.COD_REPORT") | translate}}
        </div>
        <div *ngIf="(!(receive||sort || export) && !mismatch)" class="cod-title flex-1">
            {{"MANAGE_COD_CUSTOMERS.COD_SUM" | translate}}: {{codSum | number : digitFormat}}
        </div>
        <div *ngIf="(!(receive||sort || export) && !mismatch)" class="cod-title flex-1">
            {{"MANAGE_COD_CUSTOMERS.COST_SUM" | translate}}:{{costSum | number : digitFormat}}
        </div>
        <div class="dropdown-filters-container">
            <div class="order-by">
                <div class="input-label">{{'TICKETING_SYSTEM.SORT_BY' | translate}}</div>
                <div class="order-input-value">
                    <ng-select
                            styleClass="tickets-sort-by-list"
                            [(ngModel)]="selectedOrderByOption"
                            [items]="orderByOptions" filter="true"
                            (change)="onChangeOrderBy()"
                            [style]="{'width':'100%'}">
                    </ng-select>
                </div>
            </div>
            <div *ngIf="!mismatch && !isHoldCustody && (receive||sort || export)" class="date-filter">
                <div class="date-label">
                    <p-dropdown styleClass="date-filter-dropdown"
                                [(ngModel)]="selectedDateFilter"
                                [options]="dateFiltersList" filter="true"
                                (onChange)="onDateFilterChange()">
                    </p-dropdown>
                </div>
            </div>
        </div>

<!--        <div class="date-picker-filter"-->
<!--             [ngClass]="{'disabled-btn disabled': !selectedDateFilter || selectedDateFilter === 'NONE' }">-->
<!--            <i class="fas fa-calendar" (click)="datepicker.click()"></i>-->
<!--            <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                   #datepicker-->
<!--                   daterangepicker [options]="dateRangPickerOptions"-->
<!--                   (selected)="onDateSelected($event)"/>-->
<!--            <i class="fas fa-caret-down"></i>-->
<!--        </div>-->
        <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

        <div class="search-container">
            <i class="fas fa-search"></i>
            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                   [(ngModel)]="searchContent" appInputDebounced (inputDebounced)="onSearch()"/>
        </div>
    </div>

    <p-overlayPanel #mobileActions class="mobile-actions">
        <div class="mobile-actions-container">
            <div *ngIf="showDeliverSelectedButton()" class="mobile-action" (click)="deliverSelected()"
                 [ngClass]="{'disabled-btn disabled': !isThereSelection}">
                {{"ACCOUNTING.DELIVER_SELECTED" | translate}}
            </div>
            <div *ngIf="sort && !isDeliveredPage && !inCar"
                 class="mobile-action"
                 [ngClass]="{'disabled-btn disabled': !isThereSelection}" (click)="partialExport()">
                {{"ACCOUNTING.PARTIAL_EXPORT" | translate}}
            </div>
            <div *ngIf="isHoldCustody" class="mobile-action" (click)="sortPackage($event)"
                 [ngClass]="{'disabled-btn disabled': !isThereSelection }">
                {{"ACCOUNTING.SORT_ALL_WITHOUT_CUSTODY" | translate}}
            </div>
            <div *ngIf="receive" class="mobile-action" (click)="sortPackage($event)"
                 [ngClass]="{'disabled-btn disabled': !isThereSelection }">
                {{"ACCOUNTING.SORT_ALL" | translate}}
            </div>
            <div *ngIf="sort && !isDeliveredPage && !inCar" class="mobile-action" (click)="addBarcodsToSoretedMass()">
                {{"GENERAL.ADD_BULK_BARCODES" | translate}}
            </div>
            <div *ngIf="receive" class="mobile-action" (click)="bulkSortByBarcode()">
                {{"GENERAL.SORT_BULK_BARCODES" | translate}}
            </div>
            <div *ngIf="(sort||export) && !enableEditCost" class="mobile-action-button" (click)="downloadPdf()"
                 [ngClass]="{'disabled-btn disabled':selectedDateFilter !== 'NONE'}">
                {{"ACTIONS.PRINT" | translate}}
            </div>
            <div *ngIf="sort && !isDeliveredPage && !inCar" class="mobile-action" (click)="openExcel()">
                {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
            </div>
        </div>
    </p-overlayPanel>

    <div class="header-group hidden-md-up">
        <div class="actions-list-btn flex-2d-center" (click)="triggerMobileActions($event, mobileActions)">
            <img src="../../../assets/icons/horizantal-dots.svg" alt=""/>
        </div>
        <div *ngIf="!mismatch" class="toggle-sum-values flex-2d-center"
             [ngClass]="{'toggle-toolbar-disabled': !showSumValues}">
            <span class="show-tools-toggle">{{'ACCOUNTING.SHOW_SUM_VALUES' | translate}}</span>
            <div class="package-switch">
                <p-inputSwitch [(ngModel)]="showSumValues"></p-inputSwitch>
            </div>
        </div>
    </div>
    <div class="header-actions hidden-sm-down pl-4 pr-4">
        <div *ngIf="showDeliverSelectedButton()" class="add-button" (click)="deliverSelected()"
             [ngClass]="{'disabled-btn disabled': !isThereSelection}">
            {{"ACCOUNTING.DELIVER_SELECTED" | translate}}
        </div>
        <div *ngIf="sort && !isDeliveredPage && !inCar"
             class="add-button"
             [ngClass]="{'disabled-btn disabled': !isThereSelection}" (click)="partialExport()">
            {{"ACCOUNTING.PARTIAL_EXPORT" | translate}}
        </div>
        <div *ngIf="isHoldCustody" class="add-button" (click)="sortPackage($event)"
             [ngClass]="{'disabled-btn disabled': !isThereSelection }">
            <i class="fas fa-pencil-alt"></i>
            {{"ACCOUNTING.SORT_ALL_WITHOUT_CUSTODY" | translate}}
        </div>
        <div *ngIf="receive && !isHoldCustody" class="add-button" (click)="sortPackage($event)"
             [ngClass]="{'disabled-btn disabled': !isThereSelection }">
            <i class="fas fa-pencil-alt"></i>
            {{"ACCOUNTING.SORT_ALL" | translate}}
        </div>
        <div *ngIf="sort && !isDeliveredPage && !inCar" class="add-button" (click)="addBarcodsToSoretedMass()">
            {{"GENERAL.ADD_BULK_BARCODES" | translate}}
        </div>
        <div *ngIf="receive && !isHoldCustody" class="add-button" (click)="bulkSortByBarcode()">
            {{"GENERAL.SORT_BULK_BARCODES" | translate}}
        </div>
        <div *ngIf="(sort||export) && !enableEditCost" class="add-button" (click)="downloadPdf()"
             [ngClass]="{'disabled-btn disabled':selectedDateFilter !== 'NONE'}">
            <i class="fas fa-print"></i>
            {{"ACTIONS.PRINT" | translate}}
        </div>
        <div *ngIf="sort && !isDeliveredPage && !inCar || customExport" class="excel-button" (click)="openExcel()">
            <i class="fas fa-file-excel"></i>
            {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
        </div>
    </div>

    <div class="cod-customers-table flex-1 overflow-auto">
        <p-table
            #packagesTable
            appPTableRtlFix
            dataKey="id"
            #table
            [value]="packages"
            (onPage)="setPaginatorQueryParam()"
            styleClass="customers-table flex-table"
            [rowsPerPageOptions]="rowsPerPageOptions"
            [loading]="spinnerState === spinnerStates.LOADING"
            [scrollable]="true"
            [lazy]="true"
            scrollHeight="auto"
            (onLazyLoad)="loadPackagesLazy($event)"
            [paginator]="true"
            [responsive]="true"
            [rows]="pageSize"
            [totalRecords]="totalRecords"
            [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component></app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="export || sort || isHoldCustody || receive" style="width: 4%;">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                    (onChange)="onToggleSelectAll()"></p-checkbox>
                    </th>
                    <th style="width: 150px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th *ngIf="receive||sort||export"
                        style="width: 150px;">{{"ACCOUNTING.COD_BARCODE" | translate}}</th>
                    <!--                    <th style="width: 80px"> {{"MANAGE_SHIPMENTS.COST" | translate}} </th>-->
                    <th>{{"MANAGE_SHIPMENTS.COST" | translate}}</th>
                    <th *appPermission="showHubCost && permissions.includes('VIEW_HUB_COST')">{{"MANAGE_SHIPMENTS.HUB_COST" | translate}}</th>
                    <th *ngIf="mismatch" style="width: 80px"> {{"MANAGE_SHIPMENTS.COD" | translate}} </th>
                    <th *ngIf="isShowCodFees" style="width: 80px"> {{"ACCOUNTING.COD_FEES" | translate}} </th>
                    <th *ngIf="isShowCommissionFees()">{{"PRICING.COMMISSION_FEE" | translate}}</th>
                    <th *ngIf="receive || sort || export">{{"MANAGE_SHIPMENTS.INVOICE" | translate}}</th>
                    <th *ngIf="receive || sort || export">{{"ACCOUNTING.SENDER_ADDRESS" | translate}}</th>
                    <th>{{"MANAGE_COD_CUSTOMERS.SENDER_NAME" | translate}}</th>
                    <th>{{"MANAGE_COD_CUSTOMERS.RECEIVER_NAME" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.RECEIVER_ADDRESS" | translate | addressSystem}}</th>
                    <th *ngIf="mismatch">{{'MANAGE_SHIPMENTS.STATUS' | translate}}</th>
                    <th class="line-break-anywhere">{{"MANAGE_COD_CUSTOMERS.NOTES" | translate}}</th>
                    <th *ngIf="export || sort">{{"MANAGE_SHIPMENTS.DELIVERY_DATE" | translate}}</th>
                    <th *ngIf="(type === 'COD_AT_HAND' || !type)&& !isBankTransfer">
                        {{"MANAGE_COD_CUSTOMERS.COD_AT_HAND" | translate}}
                    </th>
                    <th *ngIf="(type === 'COD_WITH_CUSTOMER' || !type) && !isBankTransfer">
                        {{"MANAGE_COD_CUSTOMERS.COD_WITH_CUSTOMER" | translate}}
                    </th>
                    <th *ngIf="(type === 'FUTURE_COD' || !type) && !isBankTransfer">
                        {{"MANAGE_COD_CUSTOMERS.COD_FUTURE" | translate}}
                    </th>
                    <th *ngIf="receive||sort|| export || isBankTransfer">{{"ADD_SHIPMENT.COD_ON_RECEIVE" | translate}}</th>
                    <th *ngIf="sort || export">{{'SHIPMENT.TABLE.STATUS' | translate}}</th>

                    <th *ngIf="isHoldCustody"> {{"ACCOUNTING.CUSTODY" | translate}}</th>
                    <th *ngIf="(mismatch || receive || export || sort || (sort && isDeliveredPage)) && this.userService.userInfo.isDriverEarningEnabled">
                        {{"DRIVERS_EARNINGS.DRIVER_COST" | translate}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr>
                    <td *ngIf="export || sort || isHoldCustody || receive" style="width: 4%">
                        <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                    (onChange)="onToggleSelection($event)"></p-checkbox>
                    </td>
                    <td style="width: 150px; text-align: center;" class="cursor-pointer">
                     <div class="barcode-image-container flex-2d-center">
                   <div>
                       <img
                               height="50px"
                               class="barcode-image"
                               [src]="package.barcodeImage"
                               (click)="showPackage(package.barcode ? package.barcode : package.packageBarcode)"
                       />
                       <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode ? package.barcode : package.packageBarcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.barcode?package.barcode:package.packageBarcode)"></i>
                        </span>
                   </div>
                     </div>
                    </td>
                    <td *ngIf="receive||sort||export" style="width: 150px; text-align: center;">
                        <div class="barcode-image-container flex-2d-center">
                            <div>
                                <img height="50px" class="barcode-image" [src]="package.codBarcodeImage"/>
                                <span class="barcode-label hover-display-parent">
                            <span>{{package.codBarcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(package.codBarcode)"></i>
                        </span>
                            </div>
                        </div>
                    </td>
                    <!--                    <td *ngIf="!(receive||sort||export)||!enableEditCost" style="width: 80px">-->
                    <td *ngIf="!(receive||sort||export)||!enableEditCost">
                        <span style="color: #00bd9f;"> {{(package.cost ? package.cost : package.packageCost) | number : digitFormat}} </span>
                    </td>

                    <td *ngIf="showHubCost">
                        <span> {{(package.hubCost) | number : digitFormat}} </span>
                    </td>
                    <!--                    <td *ngIf="(receive||sort||export)&&enableEditCost" pEditableColumn style="width: 80px;"-->
                    <td *ngIf="(receive||sort||export) && enableEditCost" pEditableColumn [pEditableColumn]="package"
                        [pEditableColumnField]="'cost'">
                        <app-edit-cost [package]="package" (onResetEvent)="initPackages()"></app-edit-cost>
                    </td>
                    <td *ngIf="mismatch" style="width: 80px">
                        {{package.cod | number : digitFormat}}
                    </td>
                    <td *ngIf="isShowCodFees">
                        {{package.codFees | number: digitFormat}}
                    </td>
                    <td *ngIf="isShowCommissionFees()">{{package.commissionFee}}</td>
                    <td *ngIf="receive || sort||export">{{package.invoiceNumber}}</td>
                    <td *ngIf="receive||sort||export">{{package.senderCity}}</td>
                    <td>{{package.senderName}}</td>
                    <td>{{package.receiverName ? package.receiverName : package.receiver}}</td>
                    <td>{{package.destination ? package.destination : package.receiverCity}}</td>
                    <td *ngIf="mismatch">{{package.status | translate}}</td>
                    <td>{{package.notes}}</td>
                    <td *ngIf="export || sort">{{package.deliveryDate | date:'dd/MM/yyyy'}}</td>
                    <td *ngIf="type === 'COD_AT_HAND' || !type">{{package.codAtHand | number : digitFormat}}</td>
                    <td *ngIf="type === 'COD_WITH_CUSTOMER' || !type">{{package.codWithCustomer | number : digitFormat}}</td>
                    <td *ngIf="type === 'FUTURE_COD' || !type">{{package.futureCod | number : digitFormat}}</td>
                    <td *ngIf="receive||sort||export||isBankTransfer">{{package.cod | number : digitFormat}}</td>
                    <td *ngIf="sort||export">{{package.status }}</td>
                    <td *ngIf="sort || export || receive">{{(sort? package.sortDate: (receive ? package.receiveDate : package.exportDate)) | date:'dd/MM/yyyy': utcOffset }}</td>
                    <td *ngIf="isHoldCustody">{{getCustody(package)}}
                    </td>
                    <td *ngIf="(mismatch || receive || export || sort || (sort && isDeliveredPage)) && this.userService.userInfo.isDriverEarningEnabled">
                        {{package.driverEarning | number : digitFormat}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="header-filters d-flex flex-row" *ngIf="mismatch || withoutType">
        <div class="cod-title flex-1">
            {{'SHIPMENT.NUMBER_OF_PACKAGES'|translate | packageTitle}}: {{totalRecords | number : digitFormat}}
        </div>
        <div *ngIf="!mismatch" class="cod-title flex-1">
            {{'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST'|translate}}: {{codSumWithoutCost | number : digitFormat}}
        </div>
        <div *ngIf="!mismatch" class="cod-title flex-1">
            {{'MANAGE_COD_CUSTOMERS.COST_SUM'|translate}}: {{totalCost | number : digitFormat}}
        </div>
        <div *ngIf="!mismatch" class="cod-title flex-1">
            {{'MANAGE_COD_CUSTOMERS.COD_SUM_WITH_COST'|translate}}: {{totalCOD | number : digitFormat}}
        </div>
    </div>
    <ng-container *ngIf="!isMobile() || showSumValues">
        <div *ngIf="export || sort || receive || isHoldCustody" class="header-filters sum-values-container d-flex flex-row" style="padding: 20px;">
            <div class="sum-values">
                <div class="shipment-summery all-shipment" style=" padding: 0px; min-width: 250px;">
                    <label class="label-border"
                           [ngStyle]="{'right': (selectedLanguage === 'ar' ? '15px' : 'none'), 'left': (selectedLanguage === 'en' ? '15px' : 'none')}">
                        {{'MANAGE_COD_CUSTOMERS.ALL_SHIPMENT' | translate}}</label>
                    <div class="row" style="justify-content: space-between">
                        <div class="cod-ship-title" style="justify-content: space-between">
                           {{'SHIPMENT.NUMBER_OF_PACKAGES'|translate | packageTitle}}: {{totalRecords | number : digitFormat}}
                        </div>
                        <div *ngIf="!isHoldCustody" class="cod-ship-title">
                            {{'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST'|translate}}
                            : {{codSumWithoutCost | number : digitFormat}}
                        </div>
                    </div>
                    <div *ngIf="!isHoldCustody" class="row" style="justify-content: space-between">
                        <div class="cod-ship-title" style="justify-content: space-between;">
                            {{'MANAGE_COD_CUSTOMERS.COST_SUM'|translate}}: {{totalCost | number : digitFormat}}
                        </div>
                        <div class="cod-ship-title">
                            {{'MANAGE_COD_CUSTOMERS.COD_SUM_WITH_COST'|translate}}: {{totalCOD | number : digitFormat}}
                        </div>
                    </div>
                </div>
                <div *ngIf="receive||sort || export" class="shipment-summery selected-shipment">
                    <label class="label-border"
                           [ngStyle]="{'right': (selectedLanguage === 'ar' ? '15px' : 'none'), 'left': (selectedLanguage === 'en' ? '15px' : 'none')}">
                        {{'MANAGE_COD_CUSTOMERS.SELECTED_SHIPMENT' | translate}}</label>
                    <div class="row" style="justify-content: space-between;">
                        <div class="cod-ship-title" style="justify-content: space-between;">
                            {{'SHIPMENT.NUMBER_OF_PACKAGES'|translate | packageTitle}}: {{selectedPkgNumber | number : digitFormat}}
                        </div>
                        <div class="cod-ship-title">
                            {{'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST'|translate}}
                            : {{selectedPkgCodWithoutCost | number : digitFormat}}
                        </div>
                    </div>
                    <div class="row" style="justify-content: space-between;">
                        <div class="cod-ship-title">
                            {{'MANAGE_COD_CUSTOMERS.COST_SUM'|translate}}: {{selectedPkgCost | number : digitFormat}}
                        </div>
                        <div class="cod-ship-title">
                            {{'MANAGE_COD_CUSTOMERS.COD_SUM_WITH_COST'|translate}}
                            : {{selectedPkgCod | number : digitFormat}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
</div>
<app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                [packageBarcode]="selectedPackageBarcode"
                                (closeWidget)="onHideAddPackage($event)">
</app-add-package-form-component>
