<div class="general-note-form-container" [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div class="close-icon-container cursor-pointer" (click)="onClose()">
        <img src="../../assets/icons/cancel.svg" alt="icon"/>
    </div>
    <div class="general-note-form-header">
        <div class="title-header">
            <span *ngIf="!isEditMode">{{'TICKETING_SYSTEM.GENERAL_NOTES.ADD_GENERAL_NOTE' | translate}}</span>
            <span *ngIf="isEditMode">{{'TICKETING_SYSTEM.GENERAL_NOTES.UPDATE_GENERAL_NOTE' | translate}}</span>
        </div>
    </div>

    <div class="form-inputs-actions d-flex">
        <form [formGroup]="generalNoteForm">
            <div class="inputs-group-container">
                <div class="input-field-container">
                    <div class="label">
                        <span>{{'TICKETING_SYSTEM.TABLE.NOTE_TYPE' | translate}}</span>
                    </div>
                    <div class="input">
                        <p-dropdown
                                formControlName="noteType"
                                styleClass="ticket-note-type-drop-down"
                                [options]="noteTypesList"
                                [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-input-container description">
                    <div class="label">
                        <span>{{'TICKETING_SYSTEM.FORM.DESCRIPTION' | translate}}</span>
                    </div>
                    <div class="input-value">
                        <textarea formControlName="description" class="category-description-textarea" [rows]="6"
                                  [cols]="30" pInputTextarea>
                        </textarea>
                    </div>
                </div>
                <div class="form-input-container resolve-due">
                    <div class="due-date input-container">
                        <div class="label">
                            <span>{{'TICKETING_SYSTEM.FORM.DUE_DATE' | translate}}</span>
                        </div>
                        <div class="input">
                            <p-calendar formControlName="resolveDue"
                                        styleClass="due-date-date-picker"
                                        icon="pi pi-calendar"
                                        [showIcon]="true"
                                        [minDate]="minDueDate"
                                        dateFormat="dd/mm/yy"></p-calendar>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="general-note-form-actions d-flex">
            <div class="cancel-action flex-2d-center cursor-pointer" (click)="onClose()">
                <span>{{'ACTIONS.CANCEL' | translate}}</span>
            </div>
            <div [ngClass]="{'disabled-btn disabled': isSubmittingForm || generalNoteForm.invalid}"
                 class="submit-action flex-2d-center cursor-pointer" (click)="submitForm()">
                <span>{{'ACTIONS.DONE' | translate}}</span>
            </div>
            <div *ngIf="isSubmittingForm" class="submit-loader flex-2d-center cursor-pointer">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
        </div>

    </div>
</div>
