import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UserService} from '../../shared/services/user.service';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {PackageModel} from '../../shared/models/package.model';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {ShipmentsService} from '../services/shipments.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {PostponePackageComponent} from '../postpone-package/postpone-package.component';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {DriverCheckInModel} from '../../shared/models/driver-check-in.model';
import {MapComponent} from '../../shared/components/map/map.component';
import {UserModel} from '../../shared/models/user.model';
import {SubscriptionTrackerComponent} from '../../shared/behavior/subscription-tracker.component';
import {AddLclItemsComponent} from '../../add-package-form/add-lcl-items/add-lcl-items.component';
import {TrackingStatus} from './models/package-tracker.model';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';


declare var window: any;

@Component({
    selector: 'app-shipment-tracker',
    templateUrl: 'shipment-tracker.component.html',
    styleUrls: ['shipment-tracker.component.scss']
})
export class ShipmentTrackerComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy, OnChanges {
    public spinnerStates = SpinnerState;
    public spinnerState = SpinnerState.LOADING;
    public currentLang = '';
    public permissions = '';
    public packagesHistory = [];
    public package: PackageModel;
    public pkgStatus = '';
    public driverCheckIn: DriverCheckInModel;
    public showPackageDetails = false;
    public detailsDivHeight: number;
    public printMassPackagesLoader = false;
    public printLoader = false;
    public imagesUrl: string[] = [];
    public currency = '';
    public displayAddPackage = false;
    public selectedPackageBarcode;
    public isLcl = false;
    public isDistributor = false;
    public isTrucking = false;
    public companyId;
    public role: string;

    @Input() packageBarcode: number;
    @Input() packageId: number;
    @Input() isShow: boolean;
    @Input() showHeaderActions = true;
    @Input() archivedMode = false;

    @Output() onCloseTracker = new EventEmitter();

    @ViewChild('packageInfo', {static: false}) packageInfo: any;
    @ViewChild('mainInfo', {static: false}) mainInfo: any;
    @ViewChild('changePackageStatusPopOver', {static: false}) changePackageStatusPopOver: NgbPopover;
    companiesWithCooling = [223, 1]; // Mashaweer company (223)
    companiesWithMeasurement = [223, 1]; // Mashaweer company (223)
    companiesWithPreview = [223, 1]; // Mashaweer company (223)
    companiesWithDestructible = [219, 1]; // Al-MAMLAKA company (219)
    companiesHadStationsAndTrips = [256, 22]; // Al-MAMLAKA company (219)
    isHubUserRole = false;
    selectedTrackingFormView = TrackingStatus.DETAILED;
    public packageTracker: any;
    trackingFormOptions;

    TrackingStatus = TrackingStatus;
    showPrePaidInsteadOfZero = false;
    SHOW_PRE_PAID_INSTEAD_OF_ZERO = [194];
    isEnableCustomerCommissionFee = false;
    showStudentsNumber = false;
    companies = Companies;
    isSupportAdditionalFees;
    digitFormat = '1.0-2';
    isSupportDeliveringPackageItemsPartially = false;
    packagesContent = [];

    constructor(
        private route: ActivatedRoute,
        private auth: AuthenticationService,
        private translateService: TranslateService,
        private shipmentsService: ShipmentsService,
        private modalService: NgbModal,
        private userService: UserService,
        private rolesService: RolesService,
        private messageService: MessageService,
        private confirmService: ConfirmationService,
        private customizationCompaniesService: CustomizationCompanyService
    ) {
        super();
        this.trackingFormOptions = [
            {
                label: this.translateService.instant('HISTORY.DETAILED_TRACKING'),
                value: TrackingStatus.DETAILED
            },
            {
                label: this.translateService.instant('HISTORY.STATUS_TRACKING'),
                value: TrackingStatus.STATUS
            }
        ];
    }

    ngOnInit() {
        this.userService.getUserInfo(true).then(
            (userInfo: UserModel) => {
                this.isLcl = userInfo.isLcl;
                this.isDistributor = userInfo.isDistributor;
                this.isTrucking = userInfo.isTrucking;
                this.isEnableCustomerCommissionFee = userInfo.isEnableCustomerCommissionFee;
                this.companyId = userInfo.companyId;
                this.showStudentsNumber = [185, 246].indexOf(this.companyId) >= 0;
                this.currency = this.userService.getCurrency();
                this.role = userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : userInfo.role;
                this.isHubUserRole = this.userService.isHubUserRole;
                this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', this.role);
                this.setShowPrePaidInsteadOfZero();
                this.isSupportAdditionalFees = userInfo.isSupportAdditionalFees;
                this.isSupportDeliveringPackageItemsPartially = this.userService.userInfo.isSupportDeliveringPackageItemsPartially;
            }
        );
        this.currentLang = this.translateService.currentLang;
        const sub = this.translateService.onLangChange.subscribe(() => {
            this.currentLang = this.translateService.currentLang;
        });
        this.addSubscription(sub);

        this.getPackagesHistory();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public editPackage() {
        // const pkg = this.triggeredDropdownShipment;
        // this.openQuickAdd(pkg);
        this.onClose();
        this.selectedPackageBarcode = this.package.barcode;
        this.displayAddPackage = true;
    }
    // public editPackage() {
    //     const modal = this.modalService.open(AddShipmentQuickComponent, {
    //         backdrop: 'static',
    //         windowClass: 'create-new-package',
    //         size: 'lg'
    //     });
    //     modal.componentInstance.pkgId = this.package.id;
    //     modal.componentInstance.isEditMode = true;
    //
    //     modal.result.then(
    //         (data: {isSuccess: boolean}) => {
    //             if (data.isSuccess) {
    //                 this.getPackagesHistory();
    //             }
    //         }
    //     );
    // }
    public downloadPdf(pkgId: number) {
        if (this.printLoader) {
            return;
        }
        this.printLoader = true;
        this.shipmentsService.downloadMultiple([pkgId], 'REPORT').subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.printLoader = false;
            }, (error) => {
                console.error(error);
                this.printLoader  = false;
            });
    }
    public downloadPdfTracking(pkgId: number) {
        this.printLoader = true;
        this.shipmentsService.downloadPdfTracking(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.printLoader = false;
            },
            error => {
                this.printLoader = false;
            });
    }
    public onChangePackageStatus() {
        const modal = this.modalService.open(ChangePackageStatusComponent,
            <any>{size: 'md', windowClass: 'package-status-popover', backdrop: 'static'});
        modal.componentInstance.shipment = this.package;
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.getPackagesHistory();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    public onPostponePackage() {
        const modal = this.modalService.open(PostponePackageComponent, <any>{size: 'md', windowClass: 'package-status-popover postpone-package-index', backdropClass: 'postpone-package-backdrop-index'});
        modal.componentInstance.shipment = this.package;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.getPackagesHistory();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes['packageBarcode'] && changes['packageBarcode'].previousValue !== changes['packageBarcode'].currentValue) ||
            (changes['isShow'] && changes['isShow'].currentValue === true)
        ) {
            this.showPackageDetails = false;
            this.getPackagesHistory();
        }
    }
    public onToggleDetails() {
        this.showPackageDetails = !this.showPackageDetails;
    }


    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.onClose();
    }

    public onClose() {
        this.packageTracker = null;
        this.selectedTrackingFormView = TrackingStatus.DETAILED;
        this.onCloseTracker.emit(false);
    }
    getPackagesHistory() {
        if (!this.packageBarcode && !this.packageId) {
            return;
        }
        let getPackageHistory;
        if (this.packageId) {
            getPackageHistory = this.shipmentsService.getPackageHistory(this.packageId, this.archivedMode);
        } else {
            getPackageHistory = this.shipmentsService.getPackageHistoryByBarcode(this.packageBarcode, this.archivedMode);
        }

        this.spinnerState = SpinnerState.LOADING;
        getPackageHistory.subscribe((response: any) => {
            this.trackingFormOptions = [
                {
                    label: this.translateService.instant('HISTORY.DETAILED_TRACKING'),
                    value: TrackingStatus.DETAILED
                },
                {
                    label: this.translateService.instant('HISTORY.STATUS_TRACKING'),
                    value: TrackingStatus.STATUS
                }
            ];
            this.packagesHistory = response.items;
            this.package = response.pkg;
            this.pkgStatus = response.massPackageStatus;
            this.driverCheckIn = response.driverCheckIn;
            this.getPackageSignature(response.pkg.id);
            this.spinnerState = SpinnerState.LOADED;
            this.getPackageStatus();
            if (this.isSupportDeliveringPackageItemsPartially && this.package.packageItemsToDeliverList?.length && !this.package.isBundle) {
                this.trackingFormOptions.push(
                    {
                        label: this.translateService.instant('HISTORY.ITEMS_DETAILS'),
                        value: TrackingStatus.ITEMS
                    }
                );
            }
        }, () => {
            this.spinnerState = SpinnerState.FAILED;
        });
    }

    public getPackageSignature(id) {
        this.spinnerState = SpinnerState.LOADING;
        this.shipmentsService.getPackageSigniture(id).subscribe((response: any) => {
            this.imagesUrl = [];
            if (response !== null) {
                if (response.signatureUrl !== undefined && response.signatureUrl !== null) {
                    this.imagesUrl.push(response.signatureUrl);
                }
                if (response.deliveryProofUrlList !== undefined && response.deliveryProofUrlList !== null) {
                    response.deliveryProofUrlList.forEach(
                        (imageUrl) => {
                            this.imagesUrl.push(imageUrl);
                        }
                    );
                }
                this.spinnerState = SpinnerState.LOADED;
            }
        }, error1 => {
            console.error(error1);
            this.spinnerState = SpinnerState.LOADED;
        });
    }
    public copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (response) => {
                this.messageService.add({severity: 'success', detail: response});
            }
        );
    }

    public getLocalTime (dateTime) {
        if (dateTime) {
            return new Date(dateTime).toLocaleTimeString();
        }
        return;
    }
    public printMassPackagePdf() {
        if (this.printMassPackagesLoader) {
            return;
        }
        this.printMassPackagesLoader = true;
        this.confirmPrintMassReport();
    }
    public confirmPrintMassReport() {
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.PRINT_MASS_REPORT', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.PRINT_MASS_REPORT'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmService.confirm({
            message: message,
            accept: () => {
                this.shipmentsService.downloadMassPackagePdf(this.package.massCodPackageId).subscribe((res: any) => {
                        window.open(res.url, '_blank');
                        this.printMassPackagesLoader = false;
                    }, () => {
                        this.printMassPackagesLoader = false;
                    });
            },
            reject: () => {
                this.printMassPackagesLoader = false;
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }
    public onChangeStatus(changePackageStatusPopOver) {
        changePackageStatusPopOver.close();
        this.getPackagesHistory();
    }
    public onHideAddPackage (event) {
        this.displayAddPackage = event;
        this.selectedPackageBarcode = null;
    }

    showDriverCheckIn() {
        const modal = this.modalService.open(MapComponent, {
            backdrop: 'static',
            size: 'lg',
        });
        modal.componentInstance.location = {
            latlng: {
                lat: this.driverCheckIn.latitude,
                lng: this.driverCheckIn.longitude
            }
        };
        modal.componentInstance.showInitialMarker = true;
        modal.componentInstance.readOnly = true;
    }

    openLclItems() {
        const modal = this.modalService.open(AddLclItemsComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'lcl-items-modal'
        });
        if (this.package.packageItems) {
            modal.componentInstance.items = this.package.packageItems;
            modal.componentInstance.viewOnly = true;
        }
    }

    printingPackageReceiptVoucher() {
        this.printLoader = true;

        this.shipmentsService.downloadMismatchPdf(this.package.codMismatchId).subscribe((res:any) => {
            window.open(res.url, '_blank');
            this.printLoader = false;
        }, error => {
            this.printLoader = false;
        });
    }

    triggerMobileActions(event, dropDown) {
        dropDown.toggle(event);
    }

    public getStatusColor(type = '') {
        return this.shipmentsService.getShipmentStatusColor(type.toUpperCase());
    }

    getPackageStatus() {
        this.shipmentsService.getPackageHistoryStatuses(this.package, this.archivedMode).subscribe(res => {
            this.packageTracker = res;
        });
    }

    private setShowPrePaidInsteadOfZero() {
        this.showPrePaidInsteadOfZero = this.SHOW_PRE_PAID_INSTEAD_OF_ZERO.indexOf(this.companyId) > -1;
    }

    isJettCompany() {
        return this.customizationCompaniesService.checkCompanies(this.companies.JETT);
    }
}
