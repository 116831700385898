<div class="slider-container">
    <div class="row title-container">
        <div class="new-requests-label row">{{'USERS_MANAGEMENT.NEW_REQUESTS' | translate}}</div>
        <div class="new-requests-badge-number"><span>{{totalRecordsNo}}</span></div>
    </div>
    <div class="main-spinner-container" *ngIf="isLoading">
        <div class="spinner-stage">
            <div class="dot-flashing"></div>
        </div>
    </div>
    <div *ngIf="!isLoading" class="d-flex" style="width: 100%;justify-content: center">
        <div class="prev-button" *ngIf="pageNumber > 1"   (click)="getCustomers(false)">
            <img *ngIf="currentLang === 'ar'" class="margin-left-10 edit-button" src="../../assets/icons/caret-right.svg" alt="icon" />
            <img *ngIf="currentLang === 'en'" class="margin-left-10 edit-button" src="../../assets/icons/caret-left.svg" alt="icon" />
        </div>
        <div *ngIf="pageNumber === 1">
            <img *ngIf="currentLang === 'ar'" class="margin-left-10 edit-button" src="../../assets/icons/white-arrow-right.png" alt="icon" />
            <img *ngIf="currentLang === 'en'" class="margin-left-10 edit-button" src="../../assets/icons/white-arrow-left.png" alt="icon" />
        </div>
        <div class="cards-items">
            <div class="new-requests-cards-container">
                <div class="card-item col-3-lg col-3-md col-4-md col-6-sm col-12-xs" style="display: inline-block;" *ngFor="let user of newCustomers">
                    <app-user-card-component
                            [isCustomer]="isCustomer"
                            [isDriver]="tabRoleParam==='DRIVER'"
                            [isNewRequest]="true"
                            (onRemoveUser)="onRemoveUser($event)"
                            (verifyUser)="verifyCustomer($event.user)"
                            [user]="user"> </app-user-card-component>
                </div>
            </div>
        </div>
            <div *ngIf="pageNumber < lastPage" class="next-button" (click)="getCustomers(true)">
                <img *ngIf="currentLang === 'ar'" class="margin-left-10 edit-button" src="../../assets/icons/caret-left.svg" alt="icon" />
                <img *ngIf="currentLang === 'en'" class="margin-left-10 edit-button" src="../../assets/icons/caret-right.svg" alt="icon" />
            </div>
        <div *ngIf="pageNumber === lastPage" class="next-button">
            <img *ngIf="currentLang === 'ar'" class="margin-left-10 edit-button" src="../../assets/icons/white-arrow-left.png" alt="icon" />
            <img *ngIf="currentLang === 'en'" class="margin-left-10 edit-button" src="../../assets/icons/white-arrow-right.png" alt="icon" />
        </div>

    </div>
</div>
