<div class="title-container">
    <div class="d-flex align-items-center">
        <div class="title">
            {{'ACCOUNTING.WALLET.WALLETS' | translate}}
        </div>
        <div class="title-badge">
            {{totalRecords}}
        </div>
    </div>
<!--    <div class="d-flex align-items-center">-->
<!--        <div class="search-icon">-->
<!--            <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image" />-->
<!--        </div>-->
<!--        <div class="search-field-input" style="width: 100%">-->
<!--            <field-input type="text" style="width: 100%" placeholder="{{'GENERAL.SEARCH' | translate}}..."-->
<!--                   [(ngModel)]="searchContent"-->
<!--                   appInputDebounced-->
<!--                   [debounceTime]="1000"-->
<!--                   (inputDebounced)="onSearchDebounced($event)">-->
<!--        </div>-->
<!--    </div>-->

    <div class="search-toggle cursor-pointer">
        <div class="manage-shipments-search-filter search-filter-container d-flex">
            <div class="d-flex">
                <div class="search-filter-toggle" (click)="toggleIsSearchContent(true)">
                    <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image" />
                </div>
                <div class="search-input"  *ngIf="isSearchContent">
                    <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent"
                           appInputDebounced
                           (inputDebounced)="onSearch()"/>
                </div>
            </div>
            <div *ngIf="isSearchContent" class="hide-search-filter" (click)="toggleIsSearchContent(false)" >
                <span>{{'ACTIONS.CANCEL' | translate}}</span>
            </div>
        </div>
    </div>
</div>
<div class="body-container h-100">
    <app-wallet-card
            *ngFor="let wallet of wallets"
            [wallet]="wallet"
            (refreshData)="refreshData($event)"
    >

    </app-wallet-card>
    <ng-container *ngIf="isLoading">
        <app-skeleton-loader *ngFor="let loader of [0, 1, 2, 3, 4, 5, 6, 7, 8]" [isSingleLoader]="true" [type]="0"></app-skeleton-loader>
    </ng-container>
    <ng-container *ngIf="!wallets.length && !isLoading">
        <app-no-data-available-component></app-no-data-available-component>
    </ng-container>
</div>
