import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqListComponent } from './components/faq-list/faq-list.component';
import {SharedModule} from '../shared/modules/shared.module';
import { AddFaqDialogComponent } from './components/add-faq-dialog/add-faq-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
  declarations: [FaqListComponent, AddFaqDialogComponent]
})
export class FaqModule { }
