<div class="main-container">
    <div class="info-container">
        <div class="row">
            <div class="col">
                <label for="card">{{ 'PAYMENT.CARD_INFORMATION' | translate }}</label>
                <div class="card-input" id="card"></div>
            </div>
        </div>
    </div>
</div>
