import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {USERS_CONSTANTS} from './constants';
import {UserModel} from '../../shared/models/user.model';
import * as moment from 'moment';
import {of} from 'rxjs';
import {isNumeric} from '../../shared/services/utilities.service';
import {ACCOUNTING_CONSTANTS} from '../../administration/accounting-constants';

@Injectable({ providedIn: 'root' })
export class UsersService {
    constructor(
        private http: HttpClient
    ) {
    }

    public getUsers(query) {
        return this.http.get(USERS_CONSTANTS.GET_USERS + query);
    }
    public getUserDropDown(query) {
        return this.http.get(':api/admin/users/drop-down' + query);
    }
    public getArchivedUsers(query) {
        return this.http.get(USERS_CONSTANTS.GET_USERS + query);
    }
    public getCustomers(query, url = USERS_CONSTANTS.GET_CUSTOMERS) {
        return this.http.get(url + query);
    }
    public searchCustomers(params, useCache = false, getAcceptedOnly = false) {
        const headers = useCache ? {
            'use-cache': 'true'
        } : {};
        if (getAcceptedOnly) {
            params['status'] = 'VERIFIED';
        }
        return this.http.get(USERS_CONSTANTS.GET_CUSTOMERS, {headers, params});
    }
    public getCustomersDropDown(query, url = USERS_CONSTANTS.GET_CUSTOMERS_DROP_DOWN) {
        return this.http.get(url + query);
    }

    public getCustomersDropDownForInvoice(params, url = USERS_CONSTANTS.GET_CUSTOMERS_DROP_DOWN_FOR_INVOICE) {
        return this.http.get(url, {params});
    }

    public getCustomer(id) {
        return this.http.get(USERS_CONSTANTS.GET_CUSTOMER.replace('{customerId}', id));
    }

    public getDrivers(query, url = USERS_CONSTANTS.GET_DRIVERS) {
        return this.http.get(url + query);
    }

    public getArchivedCustomers(query) {
        return this.http.get(USERS_CONSTANTS.GET_ARCHIVED_USERS + query);
    }

    public getCodCustomers(params, type) {
        let url = USERS_CONSTANTS.GET_COD_CUSTOMERS;
        switch (type) {
            case 'RECEIVE':
                url = USERS_CONSTANTS.GET_RECEIVED_COD_CUSTOMERS;
                break;
            case 'SORT':
                url = USERS_CONSTANTS.GET_SORTED_COD_CUSTOMERS;
                break;
            case 'EXPORT':
                url = USERS_CONSTANTS.GET_EXPORTED_COD_CUSTOMERS;
                break;
            case 'INVOICE':
                url = USERS_CONSTANTS.GET_EXPORTED_COD_CUSTOMERS + '?is-invoice=true';
                break;
            case 'COD_PENDING_CUSTOMER_APPROVAL':
                url = USERS_CONSTANTS.GET_EXPORTED_COD_CUSTOMERS + '?needs-confirmation=true';
                break;
            case 'DELIVER':
                url = USERS_CONSTANTS.GET_DELIVERED_COD_CUSTOMERS;
                break;
            case 'IN_CAR':
                url = USERS_CONSTANTS.GET_IN_CAR_COD_CUSTOMERS;
                break;
            case'is-hold-custody':
                url = USERS_CONSTANTS.GET_RECEIVED_COD_CUSTOMERS_WITHOUT_CUSTODY;
                break;
        }
        return this.http.get(url, {params});
    }

    public getAllCodCustomersReport(query) {
        return this.http.get(USERS_CONSTANTS.GET_ALL_CUSTOMERS_COD + query);
    }

    public getAllEmptyCodCustomersReport(query) {
        return this.http.get(USERS_CONSTANTS.GET_ALL_EMPTY_CUSTOMERS_COD + query);
    }
    public getCostumersSumValues(params) {
        const url = USERS_CONSTANTS.GET_CUSTOMERS_SUM_VALUES;
        return this.http.get(url, {params});
    }

    public getPrintedReports(params) {
        return this.http.get(USERS_CONSTANTS.GET_PRINTED_REPORTS, {params});
    }
    public deletePrintedReports(ids) {
        const body = {
            ids: ids
        };
        return this.http.post(USERS_CONSTANTS.DELETE_PRINTED_REPORTS, body);
    }

    public getCodPackages(id, params, type = '', isHoldCustody = false) {
        let url = USERS_CONSTANTS.GET_CUSTOMER_COD_PACKAGES.replace('{customerId}', id);
        if (type === 'RECEIVE') {
            if (isHoldCustody) {
                url = USERS_CONSTANTS.GET_CUSTOMER_SUM_VALUES_WITHOUT_RELEASING_CUSTODY;
            } else {
                url = USERS_CONSTANTS.GET_RECEIVED_COD_PACKAGES.replace('{customerId}', id);
            }
        } else {
            if (['SORT', 'EXPORT', 'IN_CAR', 'INVOICE', 'DELIVERED'].indexOf(type) > -1) {
                url = USERS_CONSTANTS.GET_SORTED_COD_PACKAGES.replace('{customerId}', id);
            }
        }
        if (type === 'MISMATCH_COD') {
            url = USERS_CONSTANTS.GET_CUSTOMER_MISMATCH_PACKAGES.replace('{codMismatchId}', id);
        }
        return this.http.get(url, {params: params});
    }


    public getMassInvoiceDetialsPackages (id, params) {
        return this.http.get(USERS_CONSTANTS.GET_MASS_INVOICES_PACKAGES.replace('{id}', id), {params});
    }

    public getReciveCustomerSumValues(params) {
        return this.http.get(USERS_CONSTANTS.GET_CUSTOMER_SUM_VALUES, {params: params});
    }

    public editCodCost(id, cost) {
        return this.http.put(USERS_CONSTANTS.EDIT_COD_COST.replace('{packageId}', id), {cost: cost});
    }

    public editPackageDriverEarnings(id, driverEarning) {
        return this.http.put(USERS_CONSTANTS.EDIT_PACKAGES_DRIVER_EARNING.replace('{packageId}', id), {driverEarning: driverEarning});
    }

    public editHubCost(id, cost) {
        return this.http.put(USERS_CONSTANTS.EDIT_HUB_COST.replace('{packageId}', id), {cost: cost});
    }

    public remove(user: UserModel) {
        const baseUrl = user.role.toLowerCase() === 'customer' ?
            USERS_CONSTANTS.REMOVE_CUSTOMER : USERS_CONSTANTS.REMOVE_USER;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.delete(url);
    }

    public removeForever(user: UserModel) {
        const baseUrl = user.role.toLowerCase() === 'customer' ?
            USERS_CONSTANTS.REMOVE_CUSTOMER_FOREVER : USERS_CONSTANTS.REMOVE_USER_FOREVER;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.delete(url);
    }

    public retrieve(user: UserModel, isCustomer = true) {
        const baseUrl = isCustomer ? USERS_CONSTANTS.RETRIEVE_CUSTOMER : USERS_CONSTANTS.RETRIEVE_USER;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.delete(url);
    }

    public retrieveUser(user: UserModel) {
        const baseUrl = USERS_CONSTANTS.RETRIEVE_USER;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.delete(url);
    }

    public verifyCustomer(user: UserModel) {
        const baseUrl = USERS_CONSTANTS.VERIFY_CUSTOMER;
        const url = baseUrl.replace('{customerId}', user.id + '');
        return this.http.put(url, {});
    }

    public addUser(body) {
        return this.http.post(USERS_CONSTANTS.ADD_USER, body);
    }

    public addCustomer(body) {
        return this.http.post(USERS_CONSTANTS.ADD_CUSTOMER, body);
    }

    public editUser(user: UserModel, body) {
        const baseUrl = user.role.toLowerCase() === 'customer' ?
            USERS_CONSTANTS.EDIT_CUSTOMER : USERS_CONSTANTS.EDIT_USER;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.put(url, body);
    }
    public editCustomerStatus(user: UserModel, status) {
        const baseUrl = USERS_CONSTANTS.EDIT_CUSTOMER_STATUS;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.put(url, {}, {params: {'newStatus': status}});
    }

    public editUserStatus(user: UserModel, status) {
        const baseUrl = USERS_CONSTANTS.EDIT_USER_STATUS;
        const url = baseUrl.replace('{userId}', user.id + '');
        return this.http.put(url, {}, {params: {'newStatus': status}});
    }

    public autoApproveCustomer(isAutoApprove, customerId) {
        const body = {isAutoApprovePackages: isAutoApprove};
        const url = USERS_CONSTANTS.AUTO_APPROVE_CUSTOMER.replace('{customerId}', customerId);
        return this.http.put(url, body);
    }


    public getVehicle(vehicleId) {
        return this.http.get(USERS_CONSTANTS.GET_VEHICLE.replace('{containerId}', vehicleId));
    }

    public getDriver(driverId, query = '') {
        return this.http.get(USERS_CONSTANTS.GET_DRIVER.replace('{userId}', driverId) + query);
    }

    public getDriverPackagesAPI(driverId) {
        return USERS_CONSTANTS.DRIVER_PACKAGES.replace('{driverId}', driverId);
    }
    public getPartnerPackagesAPI(partnerId) {
        return USERS_CONSTANTS.PARTNER_PACKAGES.replace('{partnerId}', partnerId);
    }
    public getDriverPackages(driverId: number, query: string, tableFilters = {}) {
        return this.http.post(this.getDriverPackagesAPI(driverId) + query, tableFilters);
    }

    public getDriversPackages(query, tableFilters: any = {}) {
        if (tableFilters && tableFilters.ids && tableFilters.ids.length) {
            return this.http.post(USERS_CONSTANTS.DRIVERS_PACKAGES + query, tableFilters);
        }
        return of({data: []});
    }
    public getDriverPickups(driverId, query, tableFilters = {}) {
        return this.http.get(USERS_CONSTANTS.DRIVER_PICKUPS.replace('{driverId}', driverId) + query);
    }
    public getPartnerPackages(partnerId, query, tableFilters = {}) {
        return this.http.post(this.getPartnerPackagesAPI(partnerId) + query, tableFilters);
    }
    public downloadDriverPackagesPdf(driverId, fromDate= '', toDate = '', withoutSenderName = false,
                                     withoutSenderPhone = false, withoutReturnedPackages = false,
                                     orderBy = null, isVerticalTemplate = false, withPickupDate = false,
                                     withSupplierInvoice = false, isWithReceiverName = false, withPartnerName = false) {
        const params = {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone};
        if (toDate !== '' && toDate) {
            params['toDate'] = toDate;
        }
        if (fromDate !== '' && fromDate) {
            params['fromDate'] = fromDate;
        }
        if (withPartnerName) {
            params['with-partner-name'] = withPartnerName;
        }
        if (isVerticalTemplate) {
            params['is-vertical-template'] = true;
            if (isWithReceiverName) {
                params['with-receiver-name'] = true;
            }
        } else {
            if (withoutSenderName) {
                params['without-sender-name'] = true;
            }
            if (withoutSenderPhone) {
                params['without-sender-phone'] = true;
            }
            if (withoutReturnedPackages) {
                params['without-returned-packages'] = true;
            }
            if (withPickupDate) {
                params['with-pickup-date'] = true;
            }
            if (withSupplierInvoice) {
                params['with-supplier-invoice'] = true;
            }
        }
        if (orderBy) {
            params['sort-by'] = orderBy;
        }
        return this.http.get(USERS_CONSTANTS.DRIVER_PACKAGES_PDF.replace('{driverId}', driverId), {
            params: params
        });
    }
    public exportDriverPackagesToExcel(driverId, fromDate= '', toDate = '', withoutSenderName = false,
                                       withoutSenderPhone = false, withoutReturnedPackages = false,
                                       sortBy = null) {
        const params = {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone};
        if (toDate !== '' && toDate) {
            params['toDate'] = toDate;
        }
        if (fromDate !== '' && fromDate) {
            params['fromDate'] = fromDate;
        }
        if (withoutSenderName) {
            params['without-sender-name'] = true;
        }
        if (withoutSenderPhone) {
            params['without-sender-phone'] = true;
        }
        if (withoutReturnedPackages) {
            params['without-returned-packages'] = true;
        }
        if (sortBy) {
            params['sort-by'] = sortBy;
        }
        return this.http.get(USERS_CONSTANTS.DRIVER_PACKAGES_EXCEL.replace('{driverId}', driverId), {
            params: params
        });
    }
    public exportDriverInCarPackagesToExcel(driverId) {
        const params = {
            utcOffset: moment().utcOffset().toString()
        };
        return this.http.get(USERS_CONSTANTS.DRIVER_IN_CAR_PACKAGES_EXCEL.replace('{driverId}', driverId), {params: params});
    }
    public downloadDriverPickupsPdf(picupIds) {
         // utcOffset: moment().utcOffset().toString(),
        return this.http.post(USERS_CONSTANTS.DRIVER_PICKUPS_PDF, {utcOffset: moment().utcOffset().toString(), ids: picupIds});
    }
    public printDriverMassReports(driverId) {
        const params = { utcOffset: moment().utcOffset().toString(), 'in-car': true };
        return this.http.get(USERS_CONSTANTS.DRIVER_MASS_REPORTS_PDF.replace('{driverId}', driverId), {params});
    }

    public verifyDriver(params) {
        return this.http.get(USERS_CONSTANTS.VERIFY_DRIVER, {params: params});
    }

    public verifyDriverPackages(params) {
        return this.http.get(USERS_CONSTANTS.VERIFY_DRIVER_PACKAGES, {params: params});
    }

    public getHubPackages(params) {
        return this.http.get(USERS_CONSTANTS.GET_HUB_PACKAGES, {params: params});
    }

    public exportMassPakcage(ids: number[]) {
        return this.http.put(USERS_CONSTANTS.EXPORT_MASS_PACKAGE, {ids: ids});
    }
    public changeStatusToExported(ids: number[]) {
        return this.http.put(USERS_CONSTANTS.CHANGE_STATUS_TO_EXPORTED, {ids: ids});
    }
    public pickWithDriver(driverId, ids: number[]) {
        const url = USERS_CONSTANTS.CHANGE_STATUS_TO_EXPORTED + '?driverId=' + driverId;
        return this.http.put(url, {ids: ids});
    }
    public deliverMassPackage(packageId, isAddToMassInvoice = false, paymentType, documentNumber, deliveryDate, transferFees, attachments: (File | Blob)[] = []) {
        const body = {paymentType: paymentType};
        if (documentNumber) {
            body['documentNumber'] = documentNumber;
        }
        if (deliveryDate) {
            body['deliveryDate'] = deliveryDate;
        }
        if (transferFees) {
            body['transferFees'] = transferFees;
        }
        if (attachments.length) {
            body['deliveryProofUrlList'] = attachments;
        }
        if (isAddToMassInvoice) {
            body['isAddToMassInvoice'] = isAddToMassInvoice;
        }
        return this.http.put(USERS_CONSTANTS.DELIVER_MASS_PACKAGE.replace('{massPackageId}', packageId),
            body);
    }

    public deliverMassPackages(packageIds, paymentType, documentNumber, deliveryDate, transferFees) {
        const body = {paymentType: paymentType, ids: packageIds};
        if (documentNumber) {
            body['documentNumber'] = documentNumber;
        }
        if (deliveryDate) {
            body['deliveryDate'] = deliveryDate;
        }
        if (transferFees) {
            body['transferFees'] = transferFees;
        }
        return this.http.put(USERS_CONSTANTS.DELIVER_MASS_PACKAGES,
            body);
    }

    public mob(body) {
        return this.http.post(USERS_CONSTANTS.MOB, body);
    }

    public getTransferCODPackages(id, params) {
        const url = USERS_CONSTANTS.GET_TRANSFER_COD_PACKAGES.replace('{​​bankTransferId}', id);
        return this.http.get(url, {params: params});
    }

    public getMassInvoiceDetails(id, params) {
        const url =  ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.API.replace('{id}', id);
        return this.http.get(url, {params: params});
    }

    public getPartners(query = '?page=1&pageSize=100', search?: string) {
        const url = USERS_CONSTANTS.GET_PARTNERS;
        if (search) {
            query += `&search=${search}`;
        }
        return this.http.get(url + query);
    }

    public getEntitiesForCod(entityType, params) {
        const url = USERS_CONSTANTS.GET_ENTITIES_FOR_COD.replace('{entityType}', entityType);

        return this.http.get(url, {params});
    }

    public exportToPdf(params) {
        params.utcOffset =  moment().utcOffset().toString();
        return this.http.get(USERS_CONSTANTS.EXPORT_COD_PDF, {params});
    }

    public deliverSelected(id, body) {
        const url = USERS_CONSTANTS.DELIVER_SELECTED.replace('{massPackageId}', id);
        return this.http.put(url, body);
    }

    public partialDeliver(id, body) {
        const url = USERS_CONSTANTS.DELIVER_PARTIAL.replace('{massPackageId}', id);
        return this.http.put(url, body);
    }
    public getExcelTemplateForCustomer(id) {
        return this.http.get(USERS_CONSTANTS.DOWNLOAD_EXCEL_PACKAGE_FOR_CUSTOMER.replace('{customerId}', id));
    }
    public addCustomerAttachment(customerId, data) {
        const url = USERS_CONSTANTS.UPLOAD_ATTACHMENT.replace('{customerId}', customerId);
        return this.http.post(url, data);
    }
    public editDriverEarning(id, driverEarning) {
        return this.http.put(USERS_CONSTANTS.EDIT_DRIVER_EARNING.replace('{packageId}', id), {driverEarning: driverEarning});
    }
    public addUserImage(userId, userType, data) {
        const url = USERS_CONSTANTS.UPLOAD_IMAGE_URL + '?id=' + userId + '&userType=' + userType;
        return this.http.post(url, data);
    }
    public setCustomerConfiguration(id, type) {
        return this.http.put(USERS_CONSTANTS.CUSTOMER_CONFIGURATIONS.replace('{customerId}', id) + '?type=' + type, {});
    }
    public getCustomersWithPricingList(pricingListId, params) {
        return this.http.get(USERS_CONSTANTS.GET_CUSTOMERS_LIST + `?pricingListId=${pricingListId}`, {params});
    }
    public getCustomersWithCustomerAccountManger(customerAccountManagerId, params) {
        return this.http.get(USERS_CONSTANTS.GET_CUSTOMERS_LIST + `?customerAccountManagerId=${customerAccountManagerId}`, {params});
    }

    public getCustomersWithAdditionFees(id, params) {
        return this.http.get(USERS_CONSTANTS.GET_CUSTOMERS_FOR_ADDITION_FEE.replace('{feeId}', id), {params});
    }
    public addCustomersToPricingListId(customers, pricingListId) {
        const body = {
            ids: customers
        };
        return this.http.put(USERS_CONSTANTS.ADD_CUSTOMERS_PRICING_LIST_ID.replace('{pricingListId}', pricingListId), body);
    }
    public addCustomersToCustomerAccountManagerId(customers, customerAccountManagerId, params) {
        const body = {
            ids: customers
        };
        return this.http.put(USERS_CONSTANTS.ADD_CUSTOMERS_CUSTOMER_ACCOUNT_MANAGER_ID
            .replace('{customerAccountManagerId}', customerAccountManagerId), body, {params});
    }

    public addCustomersToAdditionFee(customers, id, params) {
        const body = {
            ids: customers
        };
        return this.http.post(USERS_CONSTANTS.ADD_CUSTOMERS_CUSTOMER_ADDITION_FEE.replace('{feeId}', id), body, {params});
    }

    modifyPricingListId(id, body) {
        return this.http.put(USERS_CONSTANTS.MODIFY_PRICING_LIST_ID.replace('{customerId}', id), body);
    }
    deleteCustomerAccountManagerId(id) {
        return this.http.delete(USERS_CONSTANTS.MODIFY_CUSTOMER_ACCOUNT_ID.replace('{customerId}', id));
    }
    deleteCustomerAdditionFee(feeId, cId) {
        return this.http.delete(USERS_CONSTANTS.MODIFY_CUSTOMER_ADDITION_FEE.replace('{feeId}', feeId).replace('{customerId}', cId));
    }

    createInvoice(body) {
        return this.http.put(USERS_CONSTANTS.CREATE_INVOICE, body);
    }

    public getSenderAddressBook(query) {
        return this.http.get(USERS_CONSTANTS.SENDER_ADDRESS_BOOK + query);
    }

    public getAddressBook(params) {
        return this.http.get(USERS_CONSTANTS.GET_ADDRESS_BOOK, {
            params: params
        });
    }

    public getDelayedReports(params) {
        return this.http.get(USERS_CONSTANTS.GET_DELAYED_COD_REPORT + params);
    }

    getReceivedCodWithoutCustody(params) {
        return this.http.get(USERS_CONSTANTS.GET_RECEIVED_COD_WITHOUT_CUSTODY, {params});
    }
    getReceivedPackagesWithoutCustody(params) {
        return this.http.get(USERS_CONSTANTS.GET_RECEIVED_PACKAGES_WITHOUT_CUSTODY + params);
    }

    getExcelReceivedPackagesWithoutCustody(params) {
        return this.http.get(USERS_CONSTANTS.GET_EXCEL_RECEIVED_PACKAGES_WITHOUT_CUSTODY + params);
    }

    exportCodToExcel(params: any) {
        params.utcOffset =  moment().utcOffset().toString();
        return this.http.get(USERS_CONSTANTS.EXPORT_COD_EXCEL, {params});
    }

    exportCustomersPdf(paramsURL: any) {
        const timezone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.get(USERS_CONSTANTS.EXPORT_CUSTOMERS_PDF + paramsURL, {params: {timezone}});
    }

    exportCustomersExcel(paramsURL: any) {
        const timezone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.get(USERS_CONSTANTS.EXPORT_CUSTOMERS_EXCEL + paramsURL, {params: {timezone}});
    }

    exportUsersPdf(paramsURL: any) {
        const timezone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.get(USERS_CONSTANTS.EXPORT_USERS_PDF + paramsURL, {params: {timezone}});
    }

    exportUsersExcel(paramsURL: any) {
        const timezone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.http.get(USERS_CONSTANTS.EXPORT_USERS_EXCEL + paramsURL, {params: {timezone}});
    }
    public getHubsReceivedCod(query) {
        return this.http.get(USERS_CONSTANTS.GET_HUBS_RECEIVED_COD + query);
    }

    public getHubsMassCodReports(query) {
        return this.http.get(USERS_CONSTANTS.GET_HUBS_MASS_COD_REPORT + query);
    }
    public exportHubCod(hubId) {
        return this.http.put(USERS_CONSTANTS.EXPORT_HUB_COD.replace('{fromHubId}', hubId), {});
    }
    public deliverHubCod(packageId, paymentType, documentNumber, deliveryDate, transferFees, attachments: (File | Blob)[] = []) {
        const body = {paymentType: paymentType};
        if (documentNumber) {
            body['documentNumber'] = documentNumber;
        }
        if (deliveryDate) {
            body['deliveryDate'] = deliveryDate;
        }
        if (transferFees) {
            body['transferFees'] = transferFees;
        }
        if (attachments.length) {
            body['deliveryProofUrlList'] = attachments;
        }
        return this.http.put(USERS_CONSTANTS.DELIVER_HUB_COD.replace('{hubMassPackageId}', packageId),
            body);
    }

    public printHubCod(hubId) {
        const params = {'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone};
        return this.http.get(USERS_CONSTANTS.PRINT_HUB_COD.replace('{hubMassCodReportId}', hubId), {params});
    }

    public receiveCodFromHub(hubId, body) {
        return this.http.put(USERS_CONSTANTS.RECEIVE_COD_FROM_HUB.replace('{nextHubId}', hubId), body);
    }

    getDriversEarningsReport(params) {
        return this.http.get(USERS_CONSTANTS.GET_DRIVERS_EARNINGS_REPORT, {params});
    }
    getDriversEarningsReportMismatch(params) {
        return this.http.get(USERS_CONSTANTS.GET_DRIVERS_EARNINGS_REPORT_MISMATCH, {params});
    }

    downloadDriversEarningsReport(params, type) {
        return this.http.get(USERS_CONSTANTS.GET_DRIVERS_EARNINGS_REPORT + '/' + type, {params});
    }
    downloadDriversEarningsReportMismatch(params, type) {
        return this.http.get(USERS_CONSTANTS.GET_DRIVERS_EARNINGS_REPORT_MISMATCH + '/' + type, {params});
    }

    getUsersForCategory(query: string) {
        return this.http.get(USERS_CONSTANTS.GET_USERS_FOR_CATEGORY + query);
    }

    uploadUserAttachments(customerId, data) {
        return this.http.post(USERS_CONSTANTS.UPLOAD_CUSTOMER_ATTACHMENTS.replace('{customerId}', customerId), data);
    }

    uploadPackageAttachments(packageId, data, source?) {
        const params = {};
        if (source) {
            params['addingStage'] = source;
        }
        return this.http.post(USERS_CONSTANTS.UPLOAD_PACKAGE_ATTACHMENTS.replace('{packageId}', packageId), data, {params});
    }

    getUserAttachments(customerId) {
        return this.http.get(USERS_CONSTANTS.GET_UPLOAD_CUSTOMER_ATTACHMENTS.replace('{customerId}', customerId));
    }

    deleteUserAttachments(customerId, att: string) {
        return this.http.delete(USERS_CONSTANTS.UPLOAD_CUSTOMER_ATTACHMENTS.replace('{customerId}', customerId), {params: {fileUrl: att}});
    }

    getInsuranceValue(id: any, value: any, isInsured) {
        if (isNumeric(id) && isNumeric(value)) {
            return this.http.post(USERS_CONSTANTS.GET_INSURANCE_VALUE, {
                customerId: id,
                cod: value,
                isInsured: isInsured
            });
        }
    }
    getUserHubs(userId) {
        return this.http.get(USERS_CONSTANTS.GET_USER_HUBS.replace('{userId}', userId));
    }
    updateUserHubs(userId, body) {
        return this.http.put(USERS_CONSTANTS.UPDATE_USER_HUBS.replace('{userId}', userId), body);
    }
}
