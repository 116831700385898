<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="pricings-management-container">
        <div class="d-flex top-side">
            <div class="header">
                <div class="pricings-management-title">
                    <span>{{ 'PRICING.TITLE' | translate }}</span>
                </div>

                <div *ngIf="customerName" class="customer-badge">
                    <span>{{ (typeName | translate) + ': ' + customerName }}</span>
                </div>
            </div>

            <div class="actions">

                <div class="action main-action" *ngIf="typeName === 'COMPANY'" (click)="onCustomerCustomizePricing($event)">
                    <div class="icon">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="label">
                        <span>{{ 'PRICING.ACTIONS.CUSTOMIZE' | translate }}</span>
                    </div>
                </div>

                <div class="action main-action" (click)="onNewPricing($event)">
                    <div class="icon">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="label">
                        <span>{{ 'PRICING.ACTIONS.NEW' | translate }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="data-table pricings-data-table">
            <div class="table-body-container overflow-auto">
                <p-table [value]="pricings" scrollHeight="auto" dataKey="id" [loading]="isUpdating"
                         styleClass="table-container data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="row-toggler-th"></th>
                            <th *ngIf="!isEnableExportingBetweenHubs">{{ 'PRICING.TABLE.FROM' | translate }}</th>
                            <th>{{ (isEnableExportingBetweenHubs? 'PRICING.TO_CITY': 'PRICING.TABLE.TO') | translate }}</th>
                            <th>{{ 'PRICING.TABLE.DELIVER_COST' | translate }}</th>
                            <th>{{ 'PRICING.TABLE.RETURN_COST' | translate }}</th>
                            <th>{{ 'PRICING.TABLE.REJECTION_COST' | translate }}</th>
                            <th *ngIf="isReceivingPointEnabled">{{ 'PRICING.TABLE.RECEIVING_POINT_COST' | translate }}</th>
                            <th *ngIf="calculateCostPerWeight">{{ 'PRICING.THRESHOLD_WEIGHT' | translate }}</th>
                            <th *ngIf="calculateCostPerWeight">{{ 'PRICING.COST_PER_KG' | translate }}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content" *ngIf="!isShowNewPricing">
                            <app-no-data-available-component></app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-pricing let-expanded="expanded">
                        <tr [ngClass]="{'default-pricing-row': isDefaultPricing(pricing)}">
                            <td>
                                <span class="row-toggler" #rowToggler *ngIf="!isDefaultPricing(pricing)"
                                      [pRowToggler]="pricing" (click)="setExpandedRow(rowToggler)">
                                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : currentLang === 'en' ?
                                        'pi pi-chevron-right' : 'pi pi-chevron-left'"></i>
                                </span>
                            </td>
                            <td *ngIf="!isEnableExportingBetweenHubs">
                                <div class="row-toggler-container">
                                    <p-inplace #originRegionInplace [disabled]="isDefaultPricing(pricing)"
                                               (onActivate)="onActivateRegionInplace($event, originRegionInplace, pricing)">
                                    <span pInplaceDisplay>
                                        {{ pricing.originRegionName }}
                                    </span>
                                        <span pInplaceContent>
                                        <app-input-field-component
                                                [form]="editRegionForm"
                                                field="label"
                                                [dataKey]="'value'"
                                                [forceSelection]="true"
                                                [displayDropDown]="true"
                                                [searchSuggestions]="regionOptions"
                                                (onSearch)="getRegions($event.query)"
                                                controlName="originRegion"
                                                [options]="regionOptions"
                                                [isRequired]="true"
                                                [appendTo]="'body'"
                                                controlType="autoComplete">
                                        </app-input-field-component >
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleRegionInplace($event, originRegionInplace, pricing, true, true)"></i>
                                            <i class="fas fa-times" (click)="toggleRegionInplace($event, originRegionInplace)"></i>
                                        </span>
                                    </span>
                                    </p-inplace>
                                </div>
                            </td>
                            <td>
                                <p-inplace #destinationRegionInplace [disabled]="isDefaultPricing(pricing)"
                                           (onActivate)="onActivateRegionInplace($event, destinationRegionInplace, pricing, false)">
                                    <span pInplaceDisplay>
                                        {{ pricing.destinationRegionName }}
                                    </span>
                                    <span pInplaceContent>
                                        <app-input-field-component
                                                [form]="editRegionForm"
                                                field="label"
                                                [dataKey]="'value'"
                                                [forceSelection]="true"
                                                [displayDropDown]="true"
                                                [searchSuggestions]="regionOptions"
                                                (onSearch)="getRegions($event.query)"
                                                controlName="destinationRegion"
                                                [options]="regionOptions"
                                                [isRequired]="true"
                                                [appendTo]="'body'"
                                                controlType="autoComplete">
                                        </app-input-field-component >
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleRegionInplace($event, destinationRegionInplace, pricing, false, true)"></i>
                                            <i class="fas fa-times" (click)="toggleRegionInplace($event, destinationRegionInplace)"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>
                            <td>
                                <p-inplace #priceInplace [disabled]="isDefaultPricing(pricing)"
                                           (onDeactivate)="onDeactivateInplace($event, priceInplace)"
                                           (onActivate)="onActivateInplace($event, priceInplace, pricing.price)">
                                    <span pInplaceDisplay>
                                        {{ pricing.price }}
                                    </span>
                                    <span pInplaceContent>
                                        <input pInputText (keydown.escape)="toggleInplace($event, priceInplace)"
                                               (keydown.enter)="toggleInplace($event, priceInplace, pricing, 'price',true)"
                                               type="number" [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, priceInplace, pricing, 'price', true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, priceInplace)"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>
                            <td>
                                <p-inplace #returnedPriceInplace [disabled]="isDefaultPricing(pricing)"
                                           (onDeactivate)="onDeactivateInplace($event, returnedPriceInplace)"
                                           (onActivate)="onActivateInplace($event, returnedPriceInplace, pricing.returnedPrice)">
                                    <span pInplaceDisplay>
                                        {{ pricing.returnedPrice }}
                                    </span>
                                    <span pInplaceContent>
                                        <input pInputText (keydown.escape)="toggleInplace($event, returnedPriceInplace)"
                                               (keydown.enter)="toggleInplace($event, returnedPriceInplace, pricing, 'returnedPrice', true)"
                                               type="number" [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, returnedPriceInplace, pricing, 'returnedPrice', true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, returnedPriceInplace, pricing, 'returnedPrice')"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>
                            <td>
                                <p-inplace #rejectionBeforeArrivalCostInplace [disabled]="isDefaultPricing(pricing)"
                                           (onActivate)="onActivateInplace($event, rejectionBeforeArrivalCostInplace, pricing.rejectionBeforeArrivalCost)">
                                    <span pInplaceDisplay>
                                        {{ pricing.rejectionBeforeArrivalCost }}
                                    </span>
                                    <span pInplaceContent>
                                        <input pInputText (keydown.escape)="toggleInplace($event, rejectionBeforeArrivalCostInplace)"
                                               (keydown.enter)="toggleInplace($event, rejectionBeforeArrivalCostInplace, pricing, 'rejectionBeforeArrivalCost', true)"
                                               type="number" [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, rejectionBeforeArrivalCostInplace, pricing, 'rejectionBeforeArrivalCost', true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, rejectionBeforeArrivalCostInplace)"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>
                            <td *ngIf="isReceivingPointEnabled">
                                <p-inplace #receivingPointPriceInplace [disabled]="isDefaultPricing(pricing)"
                                           (onActivate)="onActivateInplace($event, receivingPointPriceInplace, pricing.receivingPointPrice)">
                                    <span pInplaceDisplay>
                                        {{ pricing.receivingPointPrice }}
                                    </span>
                                    <span pInplaceContent>
                                        <input (keydown.escape)="toggleInplace($event, receivingPointPriceInplace)"
                                               (keydown.enter)="toggleInplace($event, receivingPointPriceInplace, pricing, 'receivingPointPrice', true)"
                                               pInputText type="number" [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, receivingPointPriceInplace, pricing, 'receivingPointPrice', true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, receivingPointPriceInplace)"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>

                            <td *ngIf="calculateCostPerWeight">
                                <p-inplace #maxFreeWeightInplace [disabled]="isDefaultPricing(pricing)"
                                           (onActivate)="onActivateInplace($event, maxFreeWeightInplace, pricing.maxFreeWeight)">
                                    <span pInplaceDisplay>
                                        {{ pricing.maxFreeWeight }}
                                    </span>
                                    <span pInplaceContent>
                                        <input (keydown.escape)="toggleInplace($event, maxFreeWeightInplace)"
                                               (keydown.enter)="toggleInplace($event, maxFreeWeightInplace, pricing, 'maxFreeWeight', true)"
                                               pInputText type="number" [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, maxFreeWeightInplace, pricing, 'maxFreeWeight', true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, maxFreeWeightInplace)"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>

                            <td *ngIf="calculateCostPerWeight">
                                <p-inplace #additionalCostPerKgInplace [disabled]="isDefaultPricing(pricing)"
                                           (onActivate)="onActivateInplace($event, additionalCostPerKgInplace, pricing.additionalCostPerKg)">
                                    <span pInplaceDisplay>
                                        {{ pricing.additionalCostPerKg }}
                                    </span>
                                    <span pInplaceContent>
                                        <input (keydown.escape)="toggleInplace($event, additionalCostPerKgInplace)"
                                               (keydown.enter)="toggleInplace($event, additionalCostPerKgInplace, pricing, 'additionalCostPerKg', true)"
                                               pInputText type="number" [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, additionalCostPerKgInplace, pricing, 'additionalCostPerKg', true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, additionalCostPerKgInplace)"></i>
                                        </span>
                                    </span>
                                </p-inplace>
                            </td>
                            <td>
                                <div class="row-actions">
<!--                                    <i class="fas fa-trash" (click)="onDeletePricing(pricing)"></i>-->
                                    <i *ngIf="isDefaultPricing(pricing)" class="fas fa-save" (click)="onSaveDefaultPricing(pricing)"></i>
                                </div>
                                <div class="row-actions d-none">
                                    <i class="fas fa-trash" (click)="onDeletePricing(pricing)"></i>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr *ngIf="isShowNewPricing">
                            <td [attr.colspan]="(isReceivingPointEnabled ? 8 : 7)+(calculateCostPerWeight? 2 : 0)">
                            <div class="new-pricing-header">
                                    {{ 'PRICING.ACTIONS.NEW' | translate }}
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="isShowNewPricing" [formGroup]="newPricingForm">
                            <td></td>
                            <td>
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.TABLE.FROM' | translate }}
                                </div>
                                <app-input-field-component
                                        #newPricingOriginRegion
                                        [form]="newPricingForm"
                                        field="label"
                                        [dataKey]="'value'"
                                        [forceSelection]="true"
                                        [displayDropDown]="true"
                                        [searchSuggestions]="regionOptions"
                                        (onSearch)="getRegions($event.query)"
                                        controlName="originRegion"
                                        [options]="regionOptions"
                                        [isRequired]="true"
                                        controlType="autoComplete"
                                >
                                </app-input-field-component >
                            </td>
                            <td>
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.TABLE.TO' | translate }}
                                </div>
                                <app-input-field-component
                                        [form]="newPricingForm"
                                        field="label"
                                        [dataKey]="'value'"
                                        [forceSelection]="true"
                                        [displayDropDown]="true"
                                        [searchSuggestions]="regionOptions"
                                        (onSearch)="getRegions($event.query)"
                                        controlName="destinationRegion"
                                        [options]="regionOptions"
                                        [isRequired]="true"
                                        controlType="autoComplete"
                                >
                                </app-input-field-component >
                            </td>
                            <td>
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.TABLE.DELIVER_COST' | translate }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <input formControlName="price" type="number" pInputText>
                                </div>
                            </td>
                            <td>
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.TABLE.RETURN_COST' | translate }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <input formControlName="returnedPrice" type="number" pInputText>
                                </div>
                            </td>
                            <td>
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.TABLE.REJECTION_COST' | translate }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <input formControlName="rejectionBeforeArrivalCost" type="number" pInputText>
                                </div>
                            </td>
                            <td *ngIf="isReceivingPointEnabled">
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.TABLE.RECEIVING_POINT_COST' | translate }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <input formControlName="receivingPointPrice" type="number" pInputText>
                                </div>
                            </td>
                            <td *ngIf="calculateCostPerWeight">
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.THRESHOLD_WEIGHT' | translate }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <input formControlName="maxFreeWeight" type="number" pInputText>
                                </div>
                            </td>
                            <td *ngIf="calculateCostPerWeight">
                                <div class="new-pricing-field-label">
                                    {{ 'PRICING.COST_PER_KG' | translate }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <input formControlName="additionalCostPerKg" type="number" pInputText>
                                </div>
                            </td>
                            <td>
                                <div class="row-actions">
                                    <i [ngClass]="{'disabled-btn disabled': newPricingForm.invalid}" class="fas fa-save" (click)="onSavePricing()"></i>
                                    <i class="fas fa-times" (click)="onHideNewPricing()"></i>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-pricing>
                        <tr class="service-types-pricings">
                            <td [attr.colspan]="(isReceivingPointEnabled ? 8 : 7)+(calculateCostPerWeight? 2 : 0)">
                                <div *ngIf="serviceTypes.length" class="service-types-toggler action-anim" (click)="showServiceTypesPanel($event, pricing, serviceTypesPanel)">
                                    {{ 'PRICING.ACTIONS.ADD_SERVICE_TYPE' | translate }}
                                </div>
                                <div *ngIf="serviceTypes.length === 0" class="no-service-types">
                                    {{ 'PRICING.ALERTS.NO_SERVICE_TYPES' | translate }}
                                </div>
                                <div>
                                    <p-fieldset #fieldset *ngFor="let serviceTypePricing of pricing.serviceTypeIdToCompanyPricesMap | keyvalue"
                                                [styleClass]="incompletedPricingsForm
                                                    .get(pricing.id.toString())
                                                    .get(serviceTypePricing.key)
                                                    .get('vehicleTypesPricing').controls.length ? 'invalid-service-type' : ''"
                                                transitionOptions="300ms cubic-bezier(.42,.99,.64,1)"
                                                (onAfterToggle)="onToggleFieldSet($event, fieldset)"
                                                [collapsed]="true" [legend]="serviceTypesNames[serviceTypePricing.key]" [toggleable]="true">
<!--                                        <div class="service-type-actions">-->
<!--                                            <span class="action" (click)="onDeletePricing(serviceTypePricing)">-->
<!--                                                <i class="fas fa-trash"></i>-->
<!--                                                <span>{{ 'PRICING.ACTIONS.DELETE_SERVICE_TYPE' | translate }}</span>-->
<!--                                            </span>-->
<!--                                        </div>-->
                                        <div class="sub-table-container">
                                            <p-table [value]="serviceTypePricing.value">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>{{ 'PRICING.TABLE.VEHICLE_TYPE' | translate }}</th>
                                                        <th>{{ 'PRICING.TABLE.DELIVER_COST' | translate }}</th>
                                                        <th>{{ 'PRICING.TABLE.RETURN_COST' | translate }}</th>
                                                        <th>{{ 'PRICING.TABLE.REJECTION_COST' | translate }}</th>
                                                        <th *ngIf="isReceivingPointEnabled">{{ 'PRICING.TABLE.RECEIVING_POINT_COST' | translate }}</th>
                                                        <th *ngIf="calculateCostPerWeight">
                                                            {{"PRICING.THRESHOLD_WEIGHT" | translate }}
                                                        </th>
                                                        <th *ngIf="calculateCostPerWeight">
                                                            {{"PRICING.COST_PER_KG" | translate }}
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-vehicleTypePricing let-vehicleIndex="rowIndex">
                                                    <tr [ngClass]="{'default-pricing-row': isDefaultPricing(vehicleTypePricing)}">
                                                        <td>{{ vehicleTypePricing.vehicleTypeName }}</td>
                                                        <td>
                                                            <p-inplace #vehiclePrice [disabled]="isDefaultPricing(vehicleTypePricing)"
                                                                       (onActivate)="onActivateInplace($event,vehiclePrice,vehicleTypePricing.price)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.price }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehiclePrice)"
                                                                           (keydown.enter)="toggleInplace($event, vehiclePrice, vehicleTypePricing, 'price', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehiclePrice, vehicleTypePricing, 'price', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehiclePrice)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td>
                                                            <p-inplace #vehicleReturnedPrice [disabled]="isDefaultPricing(vehicleTypePricing)"
                                                                       (onActivate)="onActivateInplace($event,vehicleReturnedPrice,vehicleTypePricing.returnedPrice)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.returnedPrice }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehicleReturnedPrice)"
                                                                           (keydown.enter)="toggleInplace($event, vehicleReturnedPrice, vehicleTypePricing, 'returnedPrice', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehicleReturnedPrice, vehicleTypePricing, 'returnedPrice', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehicleReturnedPrice)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td>
                                                            <p-inplace #vehicleRejectionBeforeArrivalCost [disabled]="isDefaultPricing(vehicleTypePricing)"
                                                                       (onActivate)="onActivateInplace($event,vehicleRejectionBeforeArrivalCost,vehicleTypePricing.rejectionBeforeArrivalCost)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.rejectionBeforeArrivalCost }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehicleRejectionBeforeArrivalCost)"
                                                                           (keydown.enter)="toggleInplace($event, vehicleRejectionBeforeArrivalCost, vehicleTypePricing, 'rejectionBeforeArrivalCost', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehicleRejectionBeforeArrivalCost, vehicleTypePricing, 'rejectionBeforeArrivalCost', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehicleRejectionBeforeArrivalCost, vehicleTypePricing)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td *ngIf="isReceivingPointEnabled">
                                                            <p-inplace #vehicleReceivingPointPrice [disabled]="isDefaultPricing(vehicleTypePricing)"
                                                                       (onActivate)="onActivateInplace($event,vehicleReceivingPointPrice,vehicleTypePricing.receivingPointPrice)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.receivingPointPrice }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input type="number" (keydown.escape)="toggleInplace($event, vehicleReceivingPointPrice)"
                                                                           (keydown.enter)="toggleInplace($event, vehicleReceivingPointPrice, vehicleTypePricing, 'receivingPointPrice', true)"
                                                                           pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehicleReceivingPointPrice, vehicleTypePricing, 'receivingPointPrice', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehicleReceivingPointPrice, vehicleTypePricing)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>

                                                        <td *ngIf="calculateCostPerWeight">
                                                            <p-inplace #vehicleMaxFreeWeight [disabled]="isDefaultPricing(vehicleTypePricing)"
                                                                       (onActivate)="onActivateInplace($event,vehicleMaxFreeWeight,vehicleTypePricing.maxFreeWeight)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.maxFreeWeight }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehicleMaxFreeWeight)"
                                                                           (keydown.enter)="toggleInplace($event, vehicleMaxFreeWeight, vehicleTypePricing, 'maxFreeWeight', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehicleMaxFreeWeight, vehicleTypePricing, 'maxFreeWeight', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehicleMaxFreeWeight, vehicleTypePricing)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td *ngIf="calculateCostPerWeight">
                                                            <p-inplace #vehicleAdditionalCostPerKg [disabled]="isDefaultPricing(vehicleTypePricing)"
                                                                       (onActivate)="onActivateInplace($event,vehicleAdditionalCostPerKg,vehicleTypePricing.additionalCostPerKg)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.additionalCostPerKg }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehicleAdditionalCostPerKg)"
                                                                           (keydown.enter)="toggleInplace($event, vehicleAdditionalCostPerKg, vehicleTypePricing, 'additionalCostPerKg', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehicleAdditionalCostPerKg, vehicleTypePricing, 'additionalCostPerKg', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehicleAdditionalCostPerKg, vehicleTypePricing)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td>
                                                            <i *ngIf="isDefaultPricing(vehicleTypePricing)"
                                                               (click)="onSubmitVehicleTypePricing(pricing, serviceTypePricing.key, vehicleTypePricing, vehicleIndex)"
                                                               class="fas fa-save submit-icon"></i>
                                                            <div class="row-actions">
                                                                <i class="fas fa-trash" (click)="onDeletePricing(pricing)"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                    <tr *ngFor="let vehicleForm of incompletedPricingsForm.get(pricing.id.toString()).get(serviceTypePricing.key).get('vehicleTypesPricing').controls; trackBy: trackForm; let vehicleIndex = index"
                                                        [formGroup]="vehicleForm">
                                                        <td>{{ vehicleForm.get('vehicleTypeName').value }}</td>
                                                        <td>
                                                            <input type="number" pInputText formControlName="price">
                                                        </td>
                                                        <td>
                                                            <input type="number" pInputText
                                                                   formControlName="returnedPrice">
                                                        </td>
                                                        <td>
                                                            <input type="number" pInputText
                                                                   formControlName="rejectionBeforeArrivalCost">
                                                        </td>
                                                        <td *ngIf="isReceivingPointEnabled">
                                                            <input type="number" pInputText
                                                                   formControlName="receivingPointPrice">
                                                        </td>
                                                        <td *ngIf="calculateCostPerWeight">
                                                            <input type="number" pInputText
                                                                   formControlName="maxFreeWeight">
                                                        </td>
                                                        <td *ngIf="calculateCostPerWeight">
                                                            <input type="number" pInputText
                                                                   formControlName="additionalCostPerKg">
                                                        </td>
                                                        <td>
                                                            <i class="fas fa-save submit-icon"
                                                               [ngClass]="{'disabled-btn disabled': vehicleForm.invalid}"
                                                               (click)="onSubmitVehicleTypePricing(pricing, serviceTypePricing.key, vehicleForm, vehicleIndex)"></i>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="10">
                                                            <div class="d-flex flex-row-reverse">
                                                                <div class="row-actions d-none">
                                                                    <i class="fas fa-trash"
                                                                       (click)="onDeletePricing(serviceTypePricing)"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </p-fieldset>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader [type]="1" *ngIf="isLoading"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-overlayPanel #serviceTypesPanel>
    <div class="panel-header">
        {{ 'PRICING.ALERTS.SELECT_SERVICE_TYPE' | translate }}
    </div>
    <div class="panel-container">
        <div class="service-type-item"
             [ngClass]="{'service-type-disabled': triggeredPricing && triggeredPricing.serviceTypeIdToCompanyPricesMap
                && triggeredPricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()]}"
             (click)="addServiceType($event, serviceType, serviceTypesPanel)"
             *ngFor="let serviceType of serviceTypes">
            {{ currentLang === 'en' ? serviceType.name : serviceType.arabicName }}
        </div>
    </div>
</p-overlayPanel>
