import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {findIndex, pullAt} from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true
  }]
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {

  @ViewChild('pMultiSelect') pMultiSelect;
  @Output() whenClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() whenChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenSelectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenDeSelectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenDeSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenFilterChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() appendTo: string;
  @Input() ariaFilterLabel: string;
  @Input() ariaLabelledBy: string;
  @Input() autofocusFilter = false;
  @Input() dataKey: string;
  @Input() disabled = false;
  @Input() displaySelectedLabel = true;
  @Input() dropdownIcon = 'pi pi-chevron-down';
  @Input() emptyFilterMessage: string;
  @Input() filter = true;
  @Input() filterMatchMode: 'contains' | 'startsWith' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' = 'contains';
  @Input() filterValue: string;
  @Input() filterLocale: string;
  @Input() filterBy: string;
  @Input() filterPlaceHolder: string;
  @Input() hideTransitionOptions = '.1s linear';
  @Input() inputId:	string;
  @Input() itemSize: number;
  @Input() maxSelectedLabels = 3;
  @Input() name: string;

  @Input() set options(val) {
    this._options = this.mergeArrayOfObjects(val, this._options);
    this._options = this.removeDuplicateObjects(this._options);
  }
  get options() {
    return this._options;
  }

  @Input() optionLabel = 'label';
  @Input() optionValue = 'value';
  @Input() optionDisabled = 'disabled';
  @Input() optionGroupLabel = 'label';
  @Input() optionGroupChildren = 'items';
  @Input() group = false;
  @Input() overlayVisible = false;
  @Input() panelStyle:	object;
  @Input() placeholder = 'GENERAL.SEARCH_OR_SELECT';

  @Input() readonly = false;
  @Input() emptyMessage:	string;
  @Input() resetFilterOnHide = false;
  @Input() scrollHeight = '200px';
  @Input() selectedItemsLabel = 'ellipsis';
  @Input() selectionLimit:	number;
  @Input() showHeader = true;
  @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  @Input() showToggleAll = true;
  @Input() style: object;
  @Input() styleClass: string;
  @Input() tabindex: number;
  @Input() tooltip:	any;
  @Input() tooltipStyleClass: string;
  @Input() tooltipPosition: 'top' | 'left' | 'right' | 'bottom' = 'top';
  @Input() tooltipPositionStyle = 'absolute';
  @Input() virtualScroll = false;
  @Input() formControlName;
  @Input() formControlObj: FormControl;
  @Input() label: string;
  @Input() showLabel = true;
  @Input() cssStyle = {};
  @Input() isFilter = false;
  @Input() returnedObject = false;
  _options = [];
  _selectedItems: any[];
  private _previousSelectedItems = [];
  public currentLang = '';

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }
  mergeArrayOfObjects(arr1, arr2) {
      if (arr1 && arr2) {
          return arr1.concat(arr2);
      }

      return arr1;
  }
  removeDuplicateObjects(arr) {
    const seen = new Set();
      if (arr) {
          return arr.filter(item => {
              const objectString = JSON.stringify(item);
              if (!seen.has(objectString)) {
                  seen.add(objectString);
                  return true;
              }
              return false;
          });
      }
      return arr;
  }

  click(event) {
    this.whenClick.emit(event);
  }

  change(event) {
    // let data: any;
    // if (this.returnedObject) {
    //   data = this.options.filter(opt => {
    //     return event.itemValue === opt[this.optionValue];
    //   })[0];
    // } else {
    //   data = event;
    // }
        this.whenChange.emit(event);
    if (event.itemValue !== undefined) {
      if (this._previousSelectedItems.indexOf(event.itemValue) !== -1) {
        this.whenDeSelect.emit(event);
      } else {
        this.whenSelect.emit(event);
      }
    } else {
      if (this._previousSelectedItems.length === 0) {
        this.whenDeSelectAll.emit(event);
      } else {
        this.whenSelectAll.emit(event);
      }
    }
    this._previousSelectedItems = event.value;
  }

  onFilter(event) {
    if (this.whenFilterChange) {
      this.whenFilterChange.emit(event?.filter);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {
    this._selectedItems =  value;
  }

  get selectedItems() {
    // remove null items from the list
    pullAt(this._selectedItems, [findIndex(this._selectedItems, selectedItem => selectedItem.id === null)]);
    return this._selectedItems;
  }
  private propagateChange = (_: any) => {
  }

  @Input() set selectedItems(val: any[]) {
    this._selectedItems = val;
    if (this.propagateChange) {
      this.propagateChange(val);
    } else {
        this.whenChange.emit(val);
    }
  }

  resetOptions() {
    this._options = [];
  }

  removeItem(event: any) {
      const index = this.selectedItems?.indexOf(event.itemValue);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    }
    this.selectedItems = this.selectedItems;
    this.change(event);
    this.formControlObj?.markAsTouched();
  }

  getLabels(val) {
    return this.options?.filter(op => op[this.optionValue] === val) || [{}];
  }
}
