import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FileUploaderComponent} from '../../shared/components/file-uploader/file-uploader.component';
import {UsersService} from '../services/users.service';
import * as Compressor from 'compressorjs';
import {ShipmentsService} from '../../shipment/services/shipments.service';
import {UserService} from '../../shared/services/user.service';

@Component({
    selector: 'app-choose-payment-type',
    templateUrl: './choose-payment-type.component.html',
    styleUrls: ['./choose-payment-type.component.scss']
})
export class ChoosePaymentTypeComponent implements OnInit {

    public isLoading = false;
    public form;
    public showPrintReport = false;
    public isInvoices = false;
    public addAttachments = false;
    public massCodId;

    currentLang;
    paymentTypes :  { label: string, value: string }[];
    @ViewChild('attachmentsUploader') attachmentsUploader: FileUploaderComponent;

    constructor(private activeModal: NgbActiveModal,
                @Inject(FormBuilder) private formBuilder: FormBuilder,
                private shipmentService: ShipmentsService,
                private userService: UserService,
                private translateService: TranslateService,
                private messagesService: MessageService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.setPaymentTypesList();
        this.isLoading = false;
        this.initInfo();
    }

    setPaymentTypesList() {
        this.paymentTypes = this.userService.getCodCollectionMethods().filter(type => (type !== 'PREPAID')).map(type => {
            return {label: this.translateService.instant(`BANK_TRANSFERS.${type}`), value: type}
        });
    }

    public closeModal() {
        this.activeModal.close({paymentType: ''});
    }

    public finish() {
        const {paymentType, documentNumber, deliveryDate, printReport, transferFees} = this.form.getRawValue();
        const compFiles = [];
        this.isLoading = true;
        const result = { paymentType: paymentType,
            documentNumber: documentNumber,
            deliveryDate: deliveryDate,
            printReport: printReport,
            transferFees: transferFees
        };

        if (this.attachmentsUploader && this.attachmentsUploader.files && this.attachmentsUploader.files.length && this.massCodId) {
            let addAttachmentsCount = 0;
            const uploadFile = (formData) => {
                this.shipmentService.uploadDeliverCodAttachments(this.massCodId, formData).subscribe(
                    (res: any) => {
                        compFiles.push(res.url);
                        addAttachmentsCount++;
                        if (addAttachmentsCount === this.attachmentsUploader.files.length) {
                            result['attachments'] = compFiles;
                            this.activeModal.close(result);
                            this.isLoading = false;
                        }
                    }, error => {
                        this.isLoading = false;
                        console.error(error);
                    }
                );
            };

            this.attachmentsUploader.files.forEach((file: File) => {
                if (file.name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {
                    const formData = new FormData();
                    formData.append('file', file);
                    uploadFile(formData);
                } else {
                    this.messagesService.add({severity: 'error', detail: 'Not Supported File Format'});
                }
            });

        } else {
            this.activeModal.close(result);
            this.isLoading = false;
        }
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            paymentType: ['CASH', Validators.required],
            documentNumber: [''],
            transferFees: [''],
            deliveryDate: [new Date(), Validators.required],
            printReport: [this.showPrintReport]
        });
    }

    public changeDocumentRequired() {
        if (this.form.value.paymentType === 'CASH') {
            this.form.controls['documentNumber'].setValidators([]);
            this.form.controls['documentNumber'].setValue(undefined);
            this.form.controls['documentNumber'].updateValueAndValidity();
        } else {
            this.form.controls['documentNumber'].setValidators([Validators.required]);
            this.form.controls['documentNumber'].setValue(undefined);
            this.form.controls['documentNumber'].updateValueAndValidity();
        }
    }
    public togglePrintReport(event) {
        if (!event.checked) {
            this.translateService.get(
                ['ALERTS.CONFIRM_CANCEL_PRINT_DELIVER_REPORT', 'GENERAL.YES', 'GENERAL.NO']
            )
                .subscribe(
                    (data) => {
                        this.confirmationService.confirm({
                            message: data['ALERTS.CONFIRM_CANCEL_PRINT_DELIVER_REPORT'],
                            acceptLabel: data['GENERAL.YES'],
                            rejectLabel: data['GENERAL.NO'],
                            reject: () => {
                                this.form.controls['printReport'].setValue(true);
                                this.form.controls['printReport'].updateValueAndValidity();
                            }
                        });
                    }
                );
        }
    }

    showAddAttachments() {
        return this.addAttachments;
    }

    resetData() {
        this.form.controls.transferFees.setValue('');
    }
}
