import {AfterViewInit, Component, enableProdMode, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {PackageModel} from '../../../shared/models/package.model';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {
    EXPORTED_DATE_FILTERS_LIST, RECEIVE_COD_STATUS_LIST,
    SHIPMENT_STATUS_ALL,
    SHIPMENT_STATUS_LIST
} from '../../../shipment/services/constants';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {UsersService} from '../../../users/services/users.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HubsService} from '../../../shared/services/hubs.service';
import {DriversService} from '../../../shared/services/drivers.service';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {SharedService} from '../../../shared/services/shared-service';
import {playError} from '../../../shared/behavior/sounds';
import {EnterBarcodeComponent} from '../../../shipment/enter-barcode/enter-barcode.component';
import {ChangePackageStatusComponent} from '../../../shipment/change-package-status/change-package-status.component';
import {FaliureHistoryComponent} from '../../../shipment/faliure-history/faliure-history.component';
import {AssignPackageComponent} from '../../../shipment/assign-package/assign-package.component';
import {ChoosePrintTypeComponent} from '../../../shipment/choose-print-type/choose-print-type.component';
import {ACCOUNTANT_REPORT_ORDER_BY} from '../../../ticketing-system/services/constants';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import * as moment from 'moment';
import {PartnersService} from '../../../partners/partners.service';
import {FinishScanningV2Component} from '../finish-scanning-v2/finish-scanning-v2.component';
import {BulkUpdateComponent} from '../../../shipment/bulk-update/bulk-update.component';
import {DatePipe} from '@angular/common';
import {ChooseHubDriverPartnerComponent} from '../../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {Observable, Subscription} from 'rxjs';
import {ImageSliderComponent} from '../../../shared/components/image-slider/image-slider.component';
import {ReceiveInHubCustodyComponent} from '../../../shipment/receive-in-hub-custody/receive-in-hub-custody.component';
import {ChangePackageStatusService} from '../../../shipment/services/change-package-status.service';
import {CustomizationCompanyService} from '../../../shared/services/customization-company.service';
import {Companies} from '../../../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-receive-cod-dialog',
    templateUrl: './receive-cod-dialog.component.html',
    styleUrls: ['./receive-cod-dialog.component.scss']
})
export class ReceiveCodDialogComponent implements OnInit, AfterViewInit {


    // public driverId;
    // public partnerId;
    // public hubId;
    public Companies = Companies;
    public selectedPaymentMethod;
    public paymentsMethod;
    public paymentValue: string[] = SHARED_CONSTANTS.PAYMENT_METHODS;
    public dateFilterOptions;
    public selectedDateTypeFilter = 'DELIVERY_DATE';
    public dateFilterValues = [
        'CREATED_DATE',
        'DELIVERY_DATE'
    ];

    public isLoading = false;
    public isScanning = false;
    public isReading = false;
    public isSelectedPaymentMethod = false;
    public totalPackages = 0;
    public allCodWithOutPartnerCost = 0;
    public scannedPackages: { [barcode: number]: PackageModel } = {};
    public packages: any = {};
    public originalPackages: { [barcode: number]: PackageModel } = {};
    public triggeredPackage: any;
    public permissions = '';
    public pageNumber = 0;
    public pageSize = 15;
    public driverName = '';
    public codCollectionMethod: {};
    public codSelectedCollectionMethod: {};
    public codCollectionMethodKeys: string[] = [];
    public currentLang;
    public hubs: { id: number, name: string }[] = [];
    public entities: any [] = [];
    public hubSearch = '';
    public drivers: { id: number, name: string, countOfCarriedPackages: number }[] = [];
    public driverSearch = '';
    public spinnerState = SpinnerState.EMPTY;
    public spinnerStates = SpinnerState;
    public isAllSelected = false;
    public selectedShipmentStatus = 'ALL';
    public shipmentStatusList;
    public selectedStatusFilterList: any[] = [];
    public statusFilterList;
    public isThereSelection = false;
    public continueReceiveFromDriver = true;
    public isReceiverFromDriver = true;
    public isReadByBarcode = false;
    public isReceiveReturnedPackages = false;
    public isReceiveFromPartner = false;
    public noDataAvailableMessage = 'RECEIVE_FROM_DRIVER.CHOOSE_HUB_AND_DRIVER_ALERT';
    public shipmentStatusAll = SHIPMENT_STATUS_ALL;
    public currency = '';
    public loadingDrivers = false;
    public loadingHubs = false;
    public isReturnedWith = false;
    public isUpdateReceiverInfo = false;
    public isLcl: boolean;
    public isReturned = false;
    public receive = true;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public totalRecords = 0;
    public first = 0; // firstElement in paginator
    public params = {};
    public fromDate;
    public toDate;

    @ViewChild('changePackageStatusPopOver') changePackageStatusPopOver: NgbPopover;
    @ViewChild('table') table;

    companyId;
    storesList = [];
    numberOfCustomers = 0;
    public orderByOptions;
    public selectedOrderByOption;
    public selectedDateFilter = '';
    public dateFiltersList;
    searchContent: any;
    public dateRangPickerOptions;
    @ViewChild('datepicker') datepicker;

    type;
    public holdCustodyBy = '';
    enableEditCost = true;
    totalCod = 0;
    receivedCod = 0;
    totalCodWithoutCost = 0;
    customerId = 0;
    public selectionOptions: { label: string, value: string }[] = [];
    sourceType = 'DRIVER';
    loadingEntity = false;
    private lazyLoadCount = 0;
    entityName = '';
    selectedEntity;
    selectedPackageBarcode: any;
    displayAddPackage = false;
    isLoadingEntities = false;
    entityPage = 1;
    entityPageSize = 15;
    private entityDone = false;
    entityAddress;
    entityVehicle;
    private pkgsReceived = false;
    private startBulkReceive = false;
    private currentPkgsRequest: Subscription;
    private loadEntitiesRequest: Subscription;
    isDriverEarningEnabled = false;
    selectedAttachmentsFilter: string;
    private isReceiveInHubCustody: boolean;
    private hubCustodyName: any;
    private hubCustodyId: any;
    isExporting = false;
    public totalDeliveredPackages = 0;
    public totalReturnedPackages = 0;
    public returnedPackageBeforeArrival = 0;
    public returnedPackageAfterArrival = 0;
    public totalPartiallyDeliveredPackages = 0;
    public totalSwappedPackages = 0;
    public totalBroughtPackages = 0;
    public totalDriverCost = 0;

    public driverEarningSum = 0;
    isHubUserRole = false;
    public hubCustomersCod = 0;
    showDate = true;
    totalSatisfactionNumber = [1];
    selectedDeliveredPackages = 0;
    selectedReturnedPackagesBeforeArrival = 0;
    selectedReturnedPackagesAfterArrival = 0;
    selectedPartiallyDeliveredPackages = 0;
    selectedSwappedPackages = 0;
    selectedBroughtPackages = 0;
    selectCodWithOutPartnerCost = 0;

    showAllDetails = false;
    showSelectedDetails = false;
    showCodFees = false;
    private previousStatus: any;

    constructor(private datePipe: DatePipe,
                private route: ActivatedRoute,
                private userService: UserService,
                private usersService: UsersService,
                private shipmentsService: ShipmentsService,
                private rolesService: RolesService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private modalService: NgbModal,
                private activeModal: NgbActiveModal,
                private hubsService: HubsService,
                private driversService: DriversService,
                private storageService: StorageService,
                private dateRangePickerService: DateRangePickerService,
                private sharedService: SharedService,
                private partnersService: PartnersService,
                public customizationCompanyService: CustomizationCompanyService,
                private changePackageStatusService: ChangePackageStatusService) {
    }

    ngOnInit() {
        this.showCodFees = this.userService.getCurrency() === 'SAR';
        this.shipmentStatusList = this.setShipmentStatus();

        this.isDriverEarningEnabled = this.userService.userInfo.isDriverEarningEnabled;
        this.isHubUserRole = this.userService.isHubUserRole;
        this.currentLang = this.translateService.currentLang;
        this.isLcl = this.userService.userInfo.isLcl;
        this.currency = this.userService.getCurrency();
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        if (this.isReturnedWith) {
            this.selectedShipmentStatus = 'RETURNED_BY_RECIPIENT';
        }
        if (this.isReadByBarcode) { // read by barcode
            this.noDataAvailableMessage = 'READ_BY_BARCODE.START_READ_BY_BARCODE';
        }


        if (this.isReadByBarcode) {
            this.setStatusFilterList();
        }
        this.isReading = true;
        this.companyId = this.userService.userInfo.companyId;
        this.loadCustomersLazy({});
        this.getOrderByOptions();
        this.loadEntitiesV2();
        this.getPackages();
        this.initTitleLabel();
        this.setDateFiltersList();
        this.paymentsMethod = this.getPaymentsMethod();
        this.dateFilterOptions = this.getDateFilterOptions();
    }

    onStatusFilter() {
        this.packages = [];
        if (this.selectedStatusFilterList.length === this.statusFilterList.length && this.statusFilterList.length) {
            // if select all no need to filter
            this.packages = this.originalPackages;
        } else {
            Object.keys(this.originalPackages).forEach((key: string) => {
                if (this.selectedStatusFilterList.indexOf(this.originalPackages[key].status) !== -1) {
                    this.packages[key] = this.originalPackages[key];
                }
            });
        }
    }

    public setShipmentStatus() {
        return RECEIVE_COD_STATUS_LIST.map(status => {
            return {label: this.translateService.instant(status), value: status};
        });
    }

    public resetPackages(partialReset = true) {
        if (partialReset) {
            Object.keys(this.scannedPackages).forEach(key => {
                delete this.packages[key];
                delete this.originalPackages[key];
            });
            this.scannedPackages = [];
            return;
        }
        this.packages = [];
        this.originalPackages = [];
        this.scannedPackages = [];
        this.isThereSelection = false;
        this.isAllSelected = false;
        this.pageNumber = 0;
        this.resetTotalsAndSelected();
    }

    finish(isCLoseModal = false) {
        let confirmMessage;
        if (this.isReadByBarcode) {
            confirmMessage = 'ALERTS.READ_BY_BARCODE_CONFIRM';
            if (this.isUpdateReceiverInfo) {
                confirmMessage = 'ALERTS.EDIT_RECEIVER_INFO_CONFIRM';
            }
        } else { // receive mode
            confirmMessage = 'ALERTS.RECEIVE_CONFIRM';
        }
        this.translateService.get(
            [confirmMessage, 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe(
            (data) => {
                this.confirmationService.confirm({
                    message: data[confirmMessage],
                    accept: () => {
                        if (isCLoseModal) {
                            this.closeModal();
                        } else {
                            this.resetPackages(false);
                        }
                    },
                    acceptLabel: data['GENERAL.YES'],
                    rejectLabel: data['GENERAL.NO']
                });
            }
        );
    }

    public closeModal() {
        this.activeModal.close({needReload: this.pkgsReceived});
    }

    setStatusFilterList() {
        this.statusFilterList = SHIPMENT_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
        const allIndex = this.statusFilterList.findIndex(status => (status.value === this.shipmentStatusAll));
        if (allIndex !== -1) { // remove 'ALL' item for multi select filter
            this.statusFilterList.splice(allIndex, 1);
        }
        this.statusFilterList.map(status => {
            this.selectedStatusFilterList.push(status.value);
        });
    }

    public onSelectCustomer(entity) {
        this.selectedEntity = entity;
        this.entityName = entity.name;
        this.customerId = entity.id;
        this.searchContent = '';

        this.resetPackages(false);
        this.initPackages();
    }

    public onToggleSelectAll() {
        let selected = false;
        this.selectCodWithOutPartnerCost = 0;
        this.driverEarningSum = 0;
        this.receivedCod = 0;
        this.codCollectionMethodKeys.forEach(c => {
            this.codSelectedCollectionMethod[c] = 0;
        });
        this.selectedDeliveredPackages = 0;
        this.selectedPartiallyDeliveredPackages = 0;
        this.selectedBroughtPackages = 0;
        this.selectedReturnedPackagesBeforeArrival = 0;
        this.selectedReturnedPackagesAfterArrival = 0;
        this.selectedSwappedPackages = 0;
        if (this.isAllSelected) {
            selected = true;
            this.isThereSelection = true;
        } else {
            this.isThereSelection = false;
            this.scannedPackages = {};
        }
        Object.values(this.packages).forEach((pkg: PackageModel) => {
            pkg.isSelected = selected;
            if (pkg.isSelected) {
                this.scannedPackages[pkg.barcode] = pkg;
                this.receivedCod += pkg.cod;
                if (this.codCollectionMethodKeys.length) {
                    this.codSelectedCollectionMethod[pkg.paymentType] += pkg.cod;
                }
                this.selectCodWithOutPartnerCost += (pkg.cod - pkg.partnerPackageCost);
                this.driverEarningSum = Number((this.driverEarningSum + pkg.driverEarning).toFixed(2));
                if (pkg.status === 'DELIVERED_TO_RECIPIENT') {
                    this.selectedDeliveredPackages += 1;
                }
                if (pkg.isPartiallyDelivered) {
                    this.selectedPartiallyDeliveredPackages += 1;
                } else if (pkg.status === 'BROUGHT') {
                    this.selectedBroughtPackages += 1;
                } else if (pkg.status === 'SWAPPED') {
                    this.selectedSwappedPackages += 1;
                }

                if (pkg.status === 'RETURNED_BY_RECIPIENT' && pkg.failedReason === 'REJECTION_BEFORE_DRIVER_ARRIVAL' && !pkg.isPartiallyDelivered) {
                    this.selectedReturnedPackagesBeforeArrival += 1;
                }
                if (pkg.status === 'RETURNED_BY_RECIPIENT' && pkg.failedReason !== 'REJECTION_BEFORE_DRIVER_ARRIVAL' && !pkg.isPartiallyDelivered) {
                    this.selectedReturnedPackagesAfterArrival += 1;
                }
            }
        });

    }

    public onToggleSelection(pkg) {
        if (pkg.isSelected) {
            if (!this.scannedPackages[pkg.barcode]) {
                this.scannedPackages[pkg.barcode] = pkg;
            }
            this.receivedCod += pkg.cod;
            if (this.codCollectionMethodKeys.length) {
                this.codSelectedCollectionMethod[pkg.paymentType] += pkg.cod;
            }
            if (pkg.status === 'DELIVERED_TO_RECIPIENT') {
                this.selectedDeliveredPackages += 1;
            }
            if (pkg.isPartiallyDelivered) {
                this.selectedPartiallyDeliveredPackages += 1;
            } else if (pkg.status === 'BROUGHT') {
                this.selectedBroughtPackages += 1;
            } else if (pkg.status === 'SWAPPED') {
                this.selectedSwappedPackages += 1;
            }
            if (pkg.status === 'RETURNED_BY_RECIPIENT' && pkg.failedReason === 'REJECTION_BEFORE_DRIVER_ARRIVAL' && !pkg.isPartiallyDelivered) {
                this.selectedReturnedPackagesBeforeArrival += 1;
            }
            if (pkg.status === 'RETURNED_BY_RECIPIENT' && pkg.failedReason !== 'REJECTION_BEFORE_DRIVER_ARRIVAL' && !pkg.isPartiallyDelivered) {
                this.selectedReturnedPackagesAfterArrival += 1;
            }

            this.selectCodWithOutPartnerCost += (pkg.cod - pkg.partnerPackageCost);
            this.driverEarningSum = Number((this.driverEarningSum + pkg.driverEarning).toFixed(2));

        } else {
            this.cancelScanPackage(pkg);
            this.receivedCod -= pkg.cod;
            if (this.codCollectionMethodKeys.length) {
                this.codSelectedCollectionMethod[pkg.paymentType] -= pkg.cod;
            }
            if (pkg.status === 'DELIVERED_TO_RECIPIENT') {
                this.selectedDeliveredPackages -= 1;
            }
            if (pkg.isPartiallyDelivered) {
                this.selectedPartiallyDeliveredPackages -= 1;
            } else if (pkg.status === 'SWAPPED') {
                this.selectedSwappedPackages -= 1;
            } else if (pkg.status === 'BROUGHT') {
                this.selectedBroughtPackages -= 1;
            }
            if (pkg.status === 'RETURNED_BY_RECIPIENT' && pkg.failedReason === 'REJECTION_BEFORE_DRIVER_ARRIVAL' && !pkg.isPartiallyDelivered) {
                this.selectedReturnedPackagesBeforeArrival -= 1;
            }
            if (pkg.status === 'RETURNED_BY_RECIPIENT' && pkg.failedReason !== 'REJECTION_BEFORE_DRIVER_ARRIVAL' && !pkg.isPartiallyDelivered) {
                this.selectedReturnedPackagesAfterArrival -= 1;
            }
            this.selectCodWithOutPartnerCost -= (pkg.cod - pkg.partnerPackageCost);
            this.driverEarningSum = Number((this.driverEarningSum - pkg.driverEarning).toFixed(2));
        }

        const selectedPackages = this.getSelectedPackages();
        this.isThereSelection = selectedPackages.length !== 0;
        this.isAllSelected = selectedPackages.length === Object.values(this.packages).length && this.isThereSelection;
    }

    public copyMessage($event, list?, barcode?) {
        $event.stopPropagation();
        this.sharedService.copyMessage($event, list, barcode, 'ALERTS.COPIED');
    }

    public onStatusChanged() {
        this.packages = [];
        this.pageNumber = 0;
        this.getPackages();
    }

    public createQuery() {
        let result = `?pageSize=${this.pageSize}&page=` + (this.pageNumber + 1);
        if (this.selectedShipmentStatus !== 'ALL') {
            result += '&status=' + this.selectedShipmentStatus;
        }
        return result;
    }

    public barcodeReader(barcode) {
        if (this.startBulkReceive) {
            return;
        }
        this.shipmentsService.getPackageByBarcode(barcode,
            'ACCOUNTANT', this.sourceType === 'DRIVER' ? this.customerId : null, true, null, null, null, true).subscribe((pkg) => {
            this.scanPackage(pkg);
            this.isLoading = false;
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    public searchBarcodesList(searchValue) {
        for (const pkgBarcode in this.originalPackages) {
            if (!this.originalPackages[pkgBarcode].barcode.includes(searchValue)) {
                this.packages[pkgBarcode] = undefined;
                delete this.packages[pkgBarcode];
            } else if (!this.packages[pkgBarcode]) {
                this.packages[pkgBarcode] = this.originalPackages[pkgBarcode];
            }
        }
    }

    removeFromBarcodesList(pkg) {
        delete this.packages[pkg.barcode];
        delete this.originalPackages[pkg.barcode];
        delete this.scannedPackages[pkg.barcode];
    }

    public onClearSearchBarcodesList(search) {
        this.packages = this.originalPackages;
        this.searchBarcodesList(search);
    }

    public showSuccessMessage() {
        this.translateService.get('ALERTS.PACKAGE_SCANNED_SUCCESSFULLY').subscribe((res: any) => {
            this.messageService.add({
                severity: 'success',
                detail: res
            });
        });
    }

    public getExistPackageMessage() {
        this.translateService.get('ALERTS.PACKAGE_ALREADY_SCANNED').subscribe((res: any) => {
            this.messageService.add({
                severity: 'info',
                detail: res
            });
        });
    }

    public scanDriverPackage(pkg) {
        if (pkg) {
            if (this.isReceiveFromPartner || this.isReceiverFromDriver) {
                if (this.scannedPackages[pkg.barcode]) {
                    this.getExistPackageMessage();
                } else if (this.packages[pkg.barcode]) {
                    this.packages[pkg.barcode].isSelected = true;
                    this.scannedPackages[pkg.barcode] = this.packages[pkg.barcode];
                    this.showSuccessMessage();
                } else {
                    pkg.isSelected = true;
                    this.scannedPackages[pkg.barcode] = pkg;
                    this.showSuccessMessage();
                }
            } else if (this.isReadByBarcode || this.isReceiveReturnedPackages) {
                if (!this.packages[pkg.barcode]) {
                    pkg.isSelected = true;
                    this.packages[pkg.barcode] = pkg;
                    if (this.isReceiveReturnedPackages) {
                        this.scannedPackages[pkg.barcode] = pkg;
                    }
                } else {
                    this.getExistPackageMessage();
                }
            }
        }
    }

    scanPackage(pkg, showMessage = true) {
        if (pkg) {
            if (this.scannedPackages[pkg.barcode]) {
                this.getExistPackageMessage();
            } else if (this.isPackageExist(pkg)) {
                const index = this.getPackageIndex(pkg);
                pkg = this.packages[index];
                pkg.isSelected = true;
                this.packages.splice(index, 1);
                this.packages = [pkg, ...this.packages];
                this.onToggleSelection(pkg);
                if (showMessage) {
                    this.showSuccessMessage();
                }
            } else {
                pkg.isSelected = true;
                this.packages = [pkg, ...this.packages];
                this.onToggleSelection(this.packages[this.getPackageIndex(pkg)]);
                if (showMessage) {
                    this.showSuccessMessage();
                }
            }
        }
    }

    scanPackageAfterReload(pkg) {
        if (pkg) {
            if (this.scannedPackages[pkg.barcode]) {
                this.getExistPackageMessage();
            } else if (this.isPackageExist(pkg)) {
                const index = this.getPackageIndex(pkg);
                pkg = this.packages[index];
                pkg.isSelected = true;
                this.packages.splice(index, 1, pkg);
                this.onToggleSelection(pkg);

            } else if (!pkg.isSelected) {
                pkg.isSelected = true;
                this.packages = [pkg, ...this.packages];
                this.onToggleSelection(pkg);
            }
        }
    }

    public isPackageScanned(pkg) {
        return this.scannedPackages[pkg.barcode] !== undefined;
    }

    public cancelScanPackage(pkg) {
        pkg.isSelected = false;
        this.scannedPackages[pkg.barcode] = undefined;
        delete this.scannedPackages[pkg.barcode];
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    removePackage(pkg) {
        delete this.packages[pkg.barcode];
        delete this.scannedPackages[pkg.barcode];
        delete this.originalPackages[pkg.barcode];
    }

    public startScanning() {
        this.isScanning = true;
    }

    public resetScanning() {
        this.closeModal();
    }

    enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        switch (this.sourceType) {
            case 'DRIVER':
                modal.componentInstance.driverId = this.customerId;
                break;
            case 'PARTNER':
                modal.componentInstance.driverId = this.customerId;
                modal.componentInstance.isPartner = this.customerId;
                break;
            case 'HUB':
                modal.componentInstance.driverId = this.customerId;
                modal.componentInstance.isHub = true;
        }
        modal.componentInstance.showOriginalStatus = true;

        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.scanPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public unloadPackages() {
        const body = this.sourceType === 'HUB' && this.customerId ? {hubId: this.customerId} : {};
        body['ids'] = this.isReceiveReturnedPackages ? Object.values(this.scannedPackages).map(pkg => pkg.id) :
            Object.values(this.packages).map((pkg: any) => pkg.id);
        this.shipmentsService.unloadReturnedPackages(body).subscribe(
            () => {
                this.translateService.get(['ALERTS.CHANGED_STATUS', 'ACTIONS.OK'])
                    .subscribe(
                        (res) => {
                            this.confirmationService.confirm({
                                message: res['ALERTS.CHANGED_STATUS'],
                                rejectVisible: false,
                                acceptLabel: res['ACTIONS.OK']
                            });
                        }
                    );
                this.resetScanning();
            }, () => {
            }
        );
    }

    public receiveReturnedPackages() {
        if (!this.scannedPackages || !Object.keys(this.scannedPackages).length) {
            return;
        }
        this.translateService.get(['ACCOUNTING.FINISH_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'])
            .subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.FINISH_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.unloadPackages();
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO']
                });
            });
    }

    public finishScanning(expected: any = '', isReceiveAll = false) {
        const modal = this.modalService.open(FinishScanningV2Component, <any>{
            backdrop: 'static',
            // size: 'md',

            windowClass: 'finish-scanning-dialog'
        });
        if (this.sourceType === 'DRIVER' && this.userService.userInfo.isDriverEarningEnabled) {
            modal.componentInstance.isDriverEarning = true;
            let totalDriverEarning = 0;
            Object.keys(this.scannedPackages).forEach(key => {
                totalDriverEarning += this.scannedPackages[key].driverEarning;
            });
            modal.componentInstance.totalDriversEarning = totalDriverEarning;
        }
        modal.componentInstance.expectedCod = expected === '' ? this.receivedCod : expected;
        modal.componentInstance.isReceive = true;
        modal.componentInstance.packages = Object.keys(this.scannedPackages);

        switch (this.sourceType) {
            case 'DRIVER':
            case 'THIRD_PARTY':
                modal.componentInstance.driverId = this.customerId;
                break;
            case 'PARTNER':
                modal.componentInstance.partnerId = this.customerId;
                break;
            case 'HUB':
                modal.componentInstance.hubId = this.customerId;
        }
        if (this.isReceiveInHubCustody) {
            modal.componentInstance.driverId = this.customerId;
        }
        modal.componentInstance.receiveInHubCustody = this.isReceiveInHubCustody;
        if (this.isReceiveInHubCustody) {
            modal.componentInstance.hubId = this.hubCustodyId;
        }
        modal.componentInstance.isCustody = false;
        modal.componentInstance.isReceiveAll = isReceiveAll;
        modal.result.then(
            (data: { isSuccess: boolean, isReceiveAll: boolean }) => {
                this.pkgsReceived = true;
                this.hubCustodyId = false;
                this.hubCustodyName = '';
                this.isReceiveInHubCustody = false;
                if (data && data.isSuccess) {
                    this.receivedCod = 0;
                    this.codCollectionMethod = {};
                    this.codCollectionMethodKeys.forEach(c => {
                        this.codSelectedCollectionMethod[c] = 0;
                    });
                    this.codCollectionMethodKeys = [];
                    this.selectCodWithOutPartnerCost = 0;
                    this.driverEarningSum = 0;
                    this.selectedDeliveredPackages = 0;
                    this.selectedReturnedPackagesAfterArrival = 0;
                    this.selectedReturnedPackagesBeforeArrival = 0;
                    this.selectedPartiallyDeliveredPackages = 0;
                    this.selectedSwappedPackages = 0;
                    this.selectedBroughtPackages = 0;
                    this.selectCodWithOutPartnerCost = 0;
                    const keepSelection = this.scannedPackages && Object.keys(this.scannedPackages).length < this.totalPackages;
                    this.scannedPackages = [];
                    const selectedEntity = this.selectedEntity;
                    const entityName = this.entityName;
                    const customerId = this.customerId;
                    this.resetAndLoadEntities({});
                    if (keepSelection) {
                        this.selectedEntity = selectedEntity;
                        this.entityName = entityName;
                        this.customerId = customerId;
                    }
                    this.getDriverPackages();

                    if ((data && data.isReceiveAll) || this.packages.length === this.getSelectedPackages().length) {
                        this.resetTotalSums();
                    }
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    private resetTotalSums() {
        this.totalCod = 0;
        this.selectCodWithOutPartnerCost = 0;
        this.receivedCod = 0;
        this.codCollectionMethod = {};
        this.codCollectionMethodKeys.forEach(c => {
            this.codSelectedCollectionMethod[c] = 0;
        });
        this.codCollectionMethodKeys = [];
        this.showAllDetails = this.showSelectedDetails = false;
        this.hubCustomersCod = 0;
        this.totalPackages = 0;
        this.totalDeliveredPackages = 0;
        this.totalReturnedPackages = 0;
        this.returnedPackageBeforeArrival = 0;
        this.returnedPackageAfterArrival = 0;
        this.totalPartiallyDeliveredPackages = 0;
        this.totalSwappedPackages = 0;
        this.totalBroughtPackages = 0;
        this.driverEarningSum = 0;
        this.selectedDeliveredPackages = 0;
        this.selectedPartiallyDeliveredPackages = 0;
        this.selectedBroughtPackages = 0;
        this.selectedReturnedPackagesBeforeArrival = 0;
        this.selectedReturnedPackagesAfterArrival = 0;
        this.selectedSwappedPackages = 0;
    }

    public receivePackages(printType = null) {
        this.isLoading = true;
        this.shipmentsService.receiveDriverPackages(
            // tslint:disable-next-line:max-line-length
            this.sourceType === 'DRIVER' ? this.customerId : 0, this.sourceType === 'HUB' ? this.customerId : 0, Object.values(this.scannedPackages).map(pkg => pkg.id), printType).subscribe((data: any) => {
            if (data !== undefined && data && data.url !== undefined) {
                window.open(data.url, '_blank');
            }
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS', 'ACTIONS.OK']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS'],
                    rejectVisible: false,
                    acceptLabel: res['ACTIONS.OK'],
                });
                this.isLoading = false;
                this.scannedPackages = {};
                this.getPackages();
                this.resetTotalSums();
            });
        }, (error) => {
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_FAILED', 'ACTIONS.OK']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RECEIVE_FROM_DRIVER_FAILED'] + ': ' + error.err.err,
                    rejectVisible: false,
                    acceptLabel: res['ACTIONS.OK'],
                });
                this.isLoading = false;
            });

        });
    }

    public onChangePackageStatus(pkg = null) {
        const modal = this.modalService.open(ChangePackageStatusComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.isModal = true;
        if (pkg) {
            modal.componentInstance.shipment = pkg;
        } else {
            modal.componentInstance.shipments = this.getSelectedPackages();
        }
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.getPackages();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public openMessagesHistory(id) {
        const modal = this.modalService.open(FaliureHistoryComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.shipmentId = id;
        modal.result.then(
        ).catch(
            () => {
            }
        );

    }

    private getSelectedPackages() {
        const selectedPackages = [];
        if (!this.packages) {
            return;
        }
        Object.values(this.packages).forEach(
            (pkg: any) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }

    public isAllowedEditCost() {
        return (this.permissions.includes('{EDIT_COST')) ||
            (this.permissions.includes('{CONDITIONAL_COST') && this.userService.userInfo.isNonAdminChangeCost);
    }

    public getPackages() {
        this.initPackages();
        return;
    }

    public loadPackagesLazy() {
        this.getPackages();
    }

    public onScrollTable(event) {
        if (Object.keys(this.packages).length === this.totalPackages || this.isLoading) {
            return;
        }
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
            this.pageNumber++;
            this.getDriverPackages('loadLazy');
        }
    }

    public approvePackages() {
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.shipmentsService.approvePackages(selectedPackages).subscribe(
            () => {
                this.translateService.get(['ALERTS.PACKAGES_APPROVED', 'ACTIONS.OK']).subscribe(
                    (data) => {
                        this.confirmationService.confirm({
                            message: data['ALERTS.PACKAGES_APPROVED'],
                            rejectVisible: false,
                            acceptLabel: data['ACTIONS.OK']
                        });
                    }
                );
                this.isLoading = false;
                this.resetPackages();
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    private assignPackageTo(assignTo: 'driver' | 'container') {
        if (Object.keys(this.scannedPackages).length > 0) {
            const modal = this.modalService.open(AssignPackageComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.assignTo = assignTo;
            modal.componentInstance.ids = Object.values(this.scannedPackages).map(pkg => pkg.id);
            modal.result.then(
                (result: { isSuccess: boolean }) => {
                    if (result.isSuccess) {
                        this.translateService.get(['ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY', 'ACTIONS.OK']).subscribe(
                            (data) => {
                                this.confirmationService.confirm({
                                    message: data['ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY'],
                                    rejectVisible: false,
                                    acceptLabel: data['ACTIONS.OK']
                                });
                            }
                        );
                        this.resetPackages();
                    }
                }
            ).catch(
                () => {
                }
            );
        }
    }

    public assignToDriver() {
        this.assignPackageTo('driver');
    }

    public assignToContainer() {
        this.assignPackageTo('container');
    }

    public downloadMultiplePackages(printType = null) {
        if (!this.scannedPackages || !Object.keys(this.scannedPackages).length) {
            return;
        }
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.shipmentsService.downloadMultiple(selectedPackages, printType).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public printRunSheet(isWithSignature = false) {
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleChangeStatus(selectedPackages.reverse(), null, isWithSignature).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public openPrintDialog() {
        const modal = this.modalService.open(ChoosePrintTypeComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { type: any }) => {
                if (data) {
                    if (data.type === 'RUNSHEET') {
                        this.printRunSheet();
                    } else if (data.type === 'RUNSHEET_WITH_SIGNATURE') {
                        this.printRunSheet(true);
                    } else {
                        this.downloadMultiplePackages(data.type);
                    }
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    public transferPackages() {
        this.isLoading = true;
        const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent,
            {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.result.then((data: { isSuccess: boolean, partnerId?: number }) => {
                if (data && data.isSuccess) {
                    // Call transfer API
                    this.shipmentsService.transferPackageByIds(data.partnerId, selectedPackages).subscribe(() => {
                        this.translateService.get(['ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY', 'ACTIONS.OK']).subscribe((message) => {
                            this.confirmationService.confirm({
                                message: message['ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY'],
                                rejectVisible: false,
                                acceptLabel: message['ACTIONS.OK']
                            });
                        });
                        this.isLoading = false;
                        this.resetPackages();
                    }, (err) => {
                        this.download('errors.txt', err.error.error);
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            }, () => {
                this.isLoading = false;
            }
        ).catch(
            () => {
            }
        );
    }

    public closeChangePackageStatusPopOver(popOver, event) {
        popOver.close();
        if (event.isSuccess) {
            this.translateService.get(['ALERTS.CHANGED_STATUS', 'ACTIONS.OK']).subscribe(
                (data) => {
                    this.confirmationService.confirm({
                        message: data['ALERTS.CHANGED_STATUS'],
                        rejectVisible: false,
                        acceptLabel: data['ACTIONS.OK']
                    });
                }
            );
            this.resetPackages();
        }
    }

    public assignTriggeredPackage(shipment) {
        this.triggeredPackage = shipment;
    }

    public disableResetScanningBtn() {
        if (this.isReceiverFromDriver) {
            return !Object.keys(this.scannedPackages).length;
        } else { // receive returned packages
            return !Object.keys(this.packages).length;
        }
    }

    public changeReceiverPhone(receiverPhone, pkgId) {
        if (this.userService.getPhonePattern(this.userService.userInfo.companyId === 144).test(receiverPhone)) {
            const body = {};
            body['receiverPhone'] = receiverPhone;
            this.shipmentsService.editReceiverInfo(pkgId, body, 'PHONE').subscribe(() => {
                this.translateService.get('ALERTS.RECEIVER_PHONE_SUCCESSFULLY').subscribe(
                    (res) => {
                        this.messageService.add({severity: 'success', detail: res});
                    }
                );
            }, () => {
            });
        } else {
            this.translateService.get('ALERTS.RECEIVER_PHONE_ERROR').subscribe(
                (res) => {
                    this.messageService.add({severity: 'error', detail: res});
                }
            );
        }
    }

    public changeReceiverName(receiverName, pkgId) {
        if (receiverName && receiverName !== '') {
            const body = {};
            body['receiverName'] = receiverName;
            this.shipmentsService.editReceiverInfo(pkgId, body, 'NAME').subscribe(() => {
                this.translateService.get('ALERTS.RECEIVER_NAME_SUCCESSFULLY').subscribe(
                    (res) => {
                        this.messageService.add({severity: 'success', detail: res});
                    }
                );
            });
        }
    }

    objectValues(obj: any) {
        return Object.values(obj);
    }

    enterContainerNumber() {
        this.isLoading = true;
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isAddToContainer = true;
        modal.result.then(
            (data: { containerNumber: number }) => {
                if (data && data.containerNumber) {
                    this.getContainerInfo(data.containerNumber);
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    getContainerInfo(containerNumber) {
        this.shipmentsService.getContainerInfo(containerNumber).subscribe(
            (container: any) => {
                if (container.id) {
                    this.addPackagesToContainer(container.id);
                } else {
                    this.isLoading = false;
                }
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    addPackagesToContainer(containerId) {
        this.shipmentsService.addPackagesToContainer(containerId, {ids: Object.values(this.packages).map((pkg: any) => pkg.id)}).subscribe(
            (res: any) => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('LCL.ALERTS.ITEM_ADDED_TO_CONTAINER_SUCCESSFULLY')
                });
                this.resetPackages();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    unloadFromContainer(packageId) {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.unloadFromContainer(packageId).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('LCL.ALERTS.ITEM_UNLOADED_SUCCESSFULLY')
                });
                this.resetPackages();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    transferToStorm() {
        this.isLoading = true;
        const body = {ids: Object.values(this.packages).map((pkg: any) => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_STORM_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToStorm(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    transferToMughrabe() {
        this.isLoading = true;
        const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.TRANSFER_TO_MUGHRABE_CONFIRMATION'),
            accept: () => {
                this.shipmentsService.transferToMughrabe(body).subscribe(
                    () => {
                        this.resetPackages();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    }, (error) => {
                        console.error(error);
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    public loadCustomersLazy($event) {

        const query = '?page=1&pageSize=100&search=' + ($event.query ? $event.query : '');
        this.usersService.getCustomers(query).subscribe(
            (response: any) => {
                this.storesList = response.customers;
                this.numberOfCustomers = response.totalRecordsNo;
            }, (error) => {
                console.error(error);
            }
        );
    }

    getOrderByOptions() {
        this.translateService.get(ACCOUNTANT_REPORT_ORDER_BY).subscribe((labels) => {
            this.orderByOptions = ACCOUNTANT_REPORT_ORDER_BY.map((value) => ({
                label: labels[value],
                value
            }));
            this.selectedOrderByOption = this.orderByOptions[0];
        });
    }

    onDateFilterChange() {
        this.initPackages();

    }

    onSearch() {
        this.resetPackages(false);
        this.initPackages();
    }

    onDateSelected($event: any) {
        this.fromDate = $event.start;
        this.toDate = $event.end;
        this.resetPackages(false);
        this.initPackages();
    }

    onDatePickerHide($event: any) {
        this.fromDate = $event.startDate;
        this.toDate = $event.endDate;
        this.resetPackages(false);
        this.initPackages();
    }

    public setPaginatorQueryParam() {
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParams = {};
        if (this.searchContent) {
            queryParams['search'] = this.searchContent;
        } else {
            delete queryParams['search'];
        }

        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            queryParams['dateFilterType'] = this.selectedDateFilter;
        } else {
            delete queryParams['dateFilterType'];
        }

        delete queryParams['fromDate'];
        delete queryParams['toDate'];

        if (this.checkNeedsReset()) {
            this.first = 0;
            this.pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
        }
        queryParams['first'] = this.first;
        queryParams['pageSize'] = this.pageSize;

    }

    private checkNeedsReset() {
        const queryParams = {};
        return (queryParams['search'] !== undefined && this.searchContent !== queryParams['search']);
    }

    public setDateFiltersList() {
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });

        return EXPORTED_DATE_FILTERS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    initPackages() {
        this.spinnerState = SpinnerState.LOADING;
        this.getDriverPackages('');
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };

        if (this.searchContent) {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }

        if (this.customerId) {
            params[this.sourceType === 'HUB' ? 'hubId' : this.sourceType === 'PARTNER' ? 'partnerId' : 'id'] = this.customerId;
        }
        if (this.type) {
            params['type'] = this.type;
        }
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            params['dateFilterType'] = this.selectedDateFilter;
        }
        if (this.selectedDateTypeFilter) {
            params['dateFilter'] = this.selectedDateTypeFilter;
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
            params['dateFilter'] = this.selectedDateTypeFilter;
        }
        if (this.selectedOrderByOption) {
            params['order-by'] = this.selectedOrderByOption.value;
        }
        if (this.selectedAttachmentsFilter === 'WITHOUT' || this.selectedAttachmentsFilter === 'WITH') {
            params['isOnlyAttachmentPackages'] = (this.selectedAttachmentsFilter === 'WITH');
        }

        if (this.selectedShipmentStatus !== 'ALL') {
            params['status'] = this.selectedShipmentStatus;
        }
        if (this.selectedPaymentMethod !== 'ALL') {
            params['paymentType'] = this.selectedPaymentMethod;
        }
        params['utcOffset'] = moment().utcOffset().toString();

        return params;
    }


    private initTitleLabel() {
        this.selectionOptions.push({
            label: this.translateService.instant('ACCOUNTING.DRIVER'),
            value: 'DRIVER'
        });
        this.selectionOptions.push({
            label: this.translateService.instant('ACCOUNTING.HUB'),
            value: 'HUB'
        });
        this.selectionOptions.push({
            label: this.translateService.instant('ACCOUNTING.PARTNER'),
            value: 'PARTNER'
        });

        if (this.userService.userInfo.hasThirdParty) {
            this.selectionOptions.push({
                label: this.translateService.instant('ACCOUNTING.THIRD_PARTY'),
                value: 'THIRD_PARTY'
            });
        }
    }

    resetAndLoadEntities($event: any, customerId = null) {
        this.selectedPaymentMethod = 'ALL';
        this.selectedShipmentStatus = 'ALL';
        this.entityPage = 1;
        this.entityDone = false;
        this.entities = [];
        this.packages = [];
        this.pageNumber = 0;
        this.customerId = undefined;
        this.selectedEntity = null;
        this.entityName = '';
        this.entityAddress = '';
        this.entityVehicle = '';
        this.searchContent = '';
        this.resetPackages(false);
        this.loadEntitiesV2(null, customerId);
    }

    private loadEntitiesV2($event?, customerId = null) {
        if (this.entityDone) {
            return;
        }
        let sourceTypePath = '';

        switch (this.sourceType) {
            case 'DRIVER':
                sourceTypePath = 'driver';
                break;
            case 'PARTNER':
                sourceTypePath = 'partner';
                break;
            case 'HUB':
                sourceTypePath = 'hub';
                break;
            case 'THIRD_PARTY':
                sourceTypePath = 'driver';

        }
        this.isLoadingEntities = true;
        const params = {
            page: this.entityPage,
            pageSize: this.entityPageSize,
            search: !!this.driverSearch ? this.driverSearch : ''
        };
        if (this.sourceType === 'DRIVER') {
            params['driverType'] = 'TYPICAL';
        } else if (this.sourceType === 'THIRD_PARTY') {
            params['driverType'] = 'THIRD_PARTY';
        }
        if (this.loadEntitiesRequest) {
            this.loadEntitiesRequest.unsubscribe();
        }
        this.loadEntitiesRequest = this.usersService.getEntitiesForCod(sourceTypePath, params).subscribe(
            (response: any) => {
                this.entities = [...this.entities, ...response];
                this.isLoadingEntities = false;
                this.entityPage++;
                this.entityDone = response && response.length < this.entityPageSize;
                if ($event && $event.target) {
                    $event.target.scrollTop = $event.target.scrollTop - 20;
                }
                if (customerId) {
                    this.onSelectCustomer(this.entities.filter(e => e.id === customerId)[0]);
                }
            }, error => {
                console.error(error);
                this.isLoadingEntities = false;
            }
        );
    }

    public getDriverPackages(from = '') {
        if (!this.customerId) {
            return;
        }
        this.isLoading = true;
        if (from === 'loadLazy') {
            this.lazyLoadCount++;
        }
        if (this.currentPkgsRequest) {
            this.currentPkgsRequest.unsubscribe();
        }
        if (this.previousStatus) {
            let i = 0;
            for (; i < this.previousStatus.page; i++) {
                this.pageNumber = i;
                this.initialPackages();
            }
            this.pageNumber = i;
            // sent Previous Status to select packages after load all pages
            this.initialPackages(this.previousStatus);
        } else {
            this.initialPackages();
        }
    }

    private initialPackages(previousStatus = null) {
        const query = this.createParams();
        if (this.sourceType === 'HUB') {
            this.currentPkgsRequest = this.usersService.getHubPackages(query).subscribe(
                (response: any) => {
                    // if(this.packages.)
                    this.initCODCollection(response);
                    this.packages = [...this.packages, ...response.packages];
                    if (previousStatus) {
                        this.selectPackages(this.packages, previousStatus);
                    }
                    if (this.isAllSelected) {
                        this.onToggleSelectAll();
                    }
                    this.totalCodWithoutCost = response.codSum - response.codCustody;
                    this.hubCustomersCod = response.hubCustomersCod;
                    this.totalCod = response.codSum;
                    //this.selectCodWithOutPartnerCost = ;
                    this.isLoading = false;
                    this.setSumValues(response.packageTrackingStatusCount, response.driverEarningSum);
                }
                , () => {
                    this.isLoading = false;

                });
        } else {
            if (this.sourceType === 'PARTNER') {
                this.currentPkgsRequest = this.partnersService.getPartnerCODPackages(query).subscribe(
                    (response: any) => {
                        this.initCODCollection(response);
                        this.packages = [...this.packages, ...response.packages];
                        if (previousStatus) {
                            this.selectPackages(this.packages, previousStatus);
                        }
                        if (this.isAllSelected) {
                            this.onToggleSelectAll();
                        }
                        this.totalCod = response.codSum;
                        this.hubCustomersCod = response.hubCustomersCod;
                        this.allCodWithOutPartnerCost = this.packages.reduce((x, pkg) => x + (pkg.cod - pkg.partnerPackageCost), 0);
                        this.isLoading = false;
                        this.setSumValues(response.packageTrackingStatusCount, response.driverEarningSum);
                    }
                    , () => {
                        this.isLoading = false;
                    });
            } else if (this.sourceType === 'DRIVER' || this.sourceType === 'THIRD_PARTY') {
                this.currentPkgsRequest = this.usersService.verifyDriver(query).subscribe(
                    (response: any) => {
                        const filteredResponse = this.getUnSelectedPackages(response.driverPkgs);
                        this.initCODCollection(response);
                        this.driverName = response.firstName + ' ' + (response.middileName ? response.middileName : '')
                            + ' ' + response.lastName;
                        this.entityAddress = response.city || '';
                        this.entityVehicle = response.vehicle ? response.vehicle.brand + ' ' + response.vehicle.name : '';
                        this.packages = [...this.packages, ...filteredResponse];
                        if (previousStatus) {
                            this.selectPackages(this.packages, previousStatus);
                        }
                        if (this.isAllSelected) {
                            this.onToggleSelectAll();
                        }
                        this.customerId = response.id;
                        this.totalCod = response.codSum;
                        this.hubCustomersCod = response.hubCustomersCod;
                        this.isLoading = false;
                        this.setSumValues(response.packageTrackingStatusCount, response.driverEarningSum);

                    }
                    , () => {
                        this.isLoading = false;
                    });
            }
        }
    }

    initCODCollection(data) {
        this.codCollectionMethod = data.codCollectionMethod;
        if (this.codCollectionMethod) {
            this.codCollectionMethodKeys = Object.keys(this.codCollectionMethod);
            if (this.getSelectedPackages().length) {
                this.codCollectionMethodKeys.forEach(key => {
                    this.codCollectionMethod[key] = this.getSelectedPackages().filter(pkg => pkg.paymentType === key).reduce(function (total, num) {
                        return total + num.cod;
                    }, 0);
                });
            } else {
                this.codSelectedCollectionMethod = {};
                this.codCollectionMethodKeys.forEach(c => {
                    this.codSelectedCollectionMethod[c] = 0;
                });
            }

        }
    }

    selectPackages(packages, selected) {
        selected.selectedPackages.forEach(pkg => {
            if (packages.filter(pkg2 => pkg2.id === pkg.id)) {
                this.scanPackageAfterReload(pkg);
            }
        });
    }

    public bulkReceive() {
        this.startBulkReceive = true;
        const modal = this.modalService.open(BulkUpdateComponent, {backdrop: 'static', windowClass: 'create-new-container', size: 'lg'});
        modal.componentInstance.isChangeStatus = false;
        modal.componentInstance.isReceive = true;
        modal.componentInstance.driverId = this.sourceType === 'DRIVER' || this.sourceType === 'THIRD_PARTY' ? this.customerId : 0;
        modal.componentInstance.hubId = this.sourceType === 'HUB' ? this.customerId : 0;
        modal.componentInstance.partnerId = this.sourceType === 'PARTNER' ? this.customerId : 0;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                this.pkgsReceived = true;
                let successMessage = '';
                let errorMessage = '';
                this.translateService.get(
                    ['ALERTS.BULK_RECEIVE_SUCCESSFUL', 'ALERTS.BULK_RECEIVE_FAILED']
                ).subscribe(
                    (data) => {
                        successMessage = data['ALERTS.BULK_RECEIVE_SUCCESSFUL'];
                        errorMessage = data['ALERTS.BULK_RECEIVE_FAILED'];
                    }
                );
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: successMessage});
                    const selectedEntity = this.selectedEntity;
                    const entityName = this.entityName;
                    const customerId = this.customerId;
                    this.resetAndLoadEntities({});
                    this.selectedEntity = selectedEntity;
                    this.entityName = entityName;
                    this.customerId = customerId;
                    this.getDriverPackages();
                } else {
                    this.messageService.add({severity: 'error', detail: errorMessage});
                }
                this.startBulkReceive = false;
            }
        ).catch(
            () => {
                this.startBulkReceive = false;
            }
        );

    }

    public confirmReceiveAll() {
        this.translateService.get(['ACCOUNTING.RECEIVE_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.RECEIVE_ALL_CONFIRMATION'],
                accept: () => {
                    this.isAllSelected = true;
                    // this.receivedCod = this.totalCod;
                    this.finishScanning(this.totalCod, true);
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public getPaymentsMethod() {
        return this.paymentValue.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    public editPackage(barcode) {
        this.selectedPackageBarcode = barcode;
        this.displayAddPackage = true;
    }

    public onHideAddPackage(event) {
        this.displayAddPackage = event;
        this.selectedPackageBarcode = null;
        this.previousStatus = {
            selectedPackages: this.getSelectedPackages(),
            page: this.pageNumber
        };
        this.resetAndLoadEntities({}, this.customerId);
    }

    scroll($event: any) {
        if ($event.target.offsetHeight + $event.target.scrollTop + 10 >= $event.target.scrollHeight) {
            this.loadEntitiesV2($event);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.table && this.table.wrapperViewChild) {
                const el = this.table.wrapperViewChild.nativeElement;
                el.onscroll = (event) => {
                    this.onScrollTable(event);
                };
            }
        }, 300);
    }

    isPackageExist(pkg) {
        return !!this.packages.find((p) => {
            return p.barcode === pkg.barcode;
        });
    }

    getPackageIndex(pkg) {
        const index = this.packages.findIndex((p) => {
            return p.barcode === pkg.barcode;
        });
        return index;
    }

    private getUnSelectedPackages(driverPkgs) {
        return driverPkgs.filter(dpkg => !this.packages?.find(pkg => pkg.barcode === dpkg.barcode));
    }

    confirmFinishScanning(callBack) {
        if (this.scannedPackages && Object.keys(this.scannedPackages).length > 0) {
            this.confirmationService.confirm({
                message: this.translateService.instant('ALERTS.RECEIVE_CONFIRM'),
                accept: () => {
                    this.resetTotalsAndSelected();
                    this.resetDate(moment(), moment().add(1, 'days'));
                    callBack.apply();
                },
                acceptLabel: this.translateService.instant('GENERAL.YES'),
                rejectLabel: this.translateService.instant('GENERAL.NO')
            });
        } else {
            this.resetTotalsAndSelected();
            this.resetDate(moment(), moment().add(1, 'days'));
            this.selectedShipmentStatus = 'ALL';
            this.selectedPaymentMethod = 'ALL';
            callBack.apply();
        }
    }

    resetTotalsAndSelected() {
        this.totalCod = 0;
        this.receivedCod = 0;
        this.codCollectionMethod = {};
        this.codCollectionMethodKeys.forEach(c => {
            this.codSelectedCollectionMethod[c] = 0;
        });
        this.codCollectionMethodKeys = [];
        this.showAllDetails = this.showSelectedDetails = false;
        this.totalDeliveredPackages = 0;
        this.hubCustomersCod = 0;
        this.totalReturnedPackages = 0;
        this.returnedPackageBeforeArrival = 0;
        this.returnedPackageAfterArrival = 0;
        this.totalPartiallyDeliveredPackages = 0;
        this.totalSwappedPackages = 0;
        this.totalBroughtPackages = 0;
        this.totalDriverCost = 0;
        this.allCodWithOutPartnerCost = 0;
        this.selectCodWithOutPartnerCost = 0;
        this.driverEarningSum = 0;
        this.totalPackages = 0;
        this.scannedPackages = [];
        this.selectedDeliveredPackages = 0;
        this.selectedPartiallyDeliveredPackages = 0;
        this.selectedBroughtPackages = 0;
        this.selectedReturnedPackagesBeforeArrival = 0;
        this.selectedReturnedPackagesAfterArrival = 0;
        this.selectedSwappedPackages = 0;
    }

    resetSelected() {
        this.selectedDeliveredPackages = 0;
        this.selectedPartiallyDeliveredPackages = 0;
        this.selectedBroughtPackages = 0;
        this.selectedReturnedPackagesBeforeArrival = 0;
        this.selectedReturnedPackagesAfterArrival = 0;
        this.selectedSwappedPackages = 0;
    }

    resetDate(start, end) {
        this.fromDate = null;
        this.toDate = null;
    }

    isDraftPackagesEnable() {
        return this.companyId === 4 || this.companyId === 1;
    }

    setAttachmentFilter(filter: string, initShipments = true) {
        this.selectedAttachmentsFilter = filter;
        if (initShipments) {
            this.onSearch();
        }
    }

    public openImages(event, pkgId) {
        event.stopPropagation();
        this.shipmentsService.getPackageProofList(pkgId).subscribe((res: any) => {
            const modal = this.modalService.open(ImageSliderComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.isModal = true;
            if (res == null || res.urls === undefined) {
                res = {'urls': []};
            }
            modal.componentInstance.images = res.urls;
        });
    }

    receiveInHubCustody() {
        const modal = this.modalService.open(ReceiveInHubCustodyComponent, {});
        modal.result.then(
            (data) => {
                if (data && data.isSuccess) {
                    this.hubCustodyId = data.hubId;
                    this.hubCustodyName = data.hubName;
                    this.isReceiveInHubCustody = true;
                    this.finishScanning();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    exportExcel() {
        this.isExporting = true;
        let api: any;
        const ids = this.getSelectedPackages().map(pkg => pkg.id);
        switch (this.sourceType) {
            case 'PARTNER':
                api = this.partnersService.exportPartnerCodPackagesToExcel({ids} ,this.createParams());
                break;
            case 'HUB':
                api = this.hubsService.exportHubCodPackagesToExcel({ids}, this.createParams());
                break;
            case 'THIRD_PARTY':
            case 'DRIVER':
                api = this.driversService.exportDriverCodPackagesToExcel({ids},this.createParams());
                break;
        }
        api.subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isExporting = false;
        }, error => {
            this.isExporting = false;
        });
    }

    private setSumValues(values, driverEarning ) {
        if (values) {
            this.totalPackages = values.totalPackages;
            this.totalReturnedPackages = values.totalReturnedPackages;
            this.returnedPackageBeforeArrival = values.returnedPackageBeforeArrival;
            this.returnedPackageAfterArrival = values.returnedPackageAfterArrival;
            this.totalPartiallyDeliveredPackages = values.totalPartiallyDeliveredPackages;
            this.totalDeliveredPackages = values.totalDeliveredPackages;
            this.totalSwappedPackages = values.totalSwappedPackages;
            this.totalBroughtPackages = values.totalBroughtPackages;
            this.totalDriverCost = driverEarning;
        }
    }

    PartialDeliveryOrSwapPackage(pkg) {
        if (pkg.isPartiallyDelivered) {
            return 'PARTIALLY_DELIVERED';
        } else if (pkg.shipmentType === 'SWAP') {
            return 'SWAPPED';
        }
    }

    changeColorForPartialDeliveryOrSwapsPackage(pkg) {
        return pkg.isPartiallyDelivered || pkg.status === 'SWAPPED';
    }
    changeColorOfDraftPackages(isDraftPackageWithoutReceiverInfo) {
        return this.isDraftPackagesEnable() && isDraftPackageWithoutReceiverInfo;
    }

   onChangePaymentMethod() {
        this.isSelectedPaymentMethod = true;
        this.resetPackages(false);
        this.initPackages();
    }

    onChangeDateTypeFilter() {
        this.resetPackages(false);
        this.initPackages();
    }
    public getDateFilterOptions() {
        return this.dateFilterValues.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    public openExcel() {

    }
}
