import {Component, OnInit} from '@angular/core';
import {STORAGE_KEYS} from '../../../../../shared/services/storage/storage.keys';
import {UserService} from '../../../../../shared/services/user.service';
import {StorageService} from '../../../../../shared/services/storage/storage.service';

@Component({
    selector: 'app-hub-card',
    templateUrl: './hub-card.component.html',
    styleUrls: ['./hub-card.component.scss']
})
export class HubCardComponent implements OnInit {
    currency = '';
    constructor(private userService: UserService,
                private storageService: StorageService) {
    }

    ngOnInit() {
        this.currency = this.getCurrency();
    }

    getCurrency() {
        if (this.userService.userInfo && this.userService.userInfo.currency) {
            return this.userService.userInfo.currency;
        }
        return this.storageService.get(STORAGE_KEYS.CURRENCY);
    }
}
