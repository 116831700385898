import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    REQUIRE_DELIVERY_DATE,
    REQUIRE_DRIVER,
    REQUIRE_HUB,
    REQUIRE_HUB_DRIVER_SELECTION,
    REQUIRE_POSTPONED_DELIVERY_DATE,
    REQUIRE_SHELF
} from '../services/constants';
import {ShipmentsService} from '../services/shipments.service';
import {FormBuilder, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Component({
    selector: 'app-bulk-update',
    templateUrl: './bulk-update.component.html',
    styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {
    public isLoading = false;
    public form;
    public finishUpdating = false;
    public sentOnce = false;
    public barcodes = [];
    public isChangeStatus = true;
    public isReceive = false;
    public driverId = 0;
    public hubId = 0;
    public customerId = 0;
    public title = 'GENERAL.ADD_BULK_BARCODES';
    public partnerId = 0;
    public isCodMassPackage = true;
    public exportBulkBarcodes = false;
    public receiveBulkBarcodes = false;
    titleLabel = '';
    finishReceiveLabel = '';
    constructor(private activeModal: NgbActiveModal,
                private shipmentService: ShipmentsService,
                private formBuilder: FormBuilder,
                private datePipe: DatePipe) {
    }

    ngOnInit() {
        if (!this.isChangeStatus) {
            if (this.isReceive) {
                this.form = this.formBuilder.group({
                    barcodes: [null, Validators.compose([Validators.required])],
                    cod: [null, Validators.compose([Validators.required])],
                    expenses: [0],
                    expensesType: ['']
                });
            } else {
                this.form = this.formBuilder.group({
                    barcodes: [null, Validators.compose([Validators.required])]
                });
            }
        }
    }

    public closeModal(isSuccess = false) {
        if (this.exportBulkBarcodes) {
            this.activeModal.close({isSuccess: false});
        } else  if (this.receiveBulkBarcodes) {
            this.activeModal.close({isSuccess: isSuccess});
        } else {
            this.activeModal.close();
        }
    }

    public updatePackages() {
        const barcodes = this.sentOnce ? this.getFailedBarcodes() : this.form.value.barcodes;

        const body = this.prepareUpdateRequest(barcodes);
        if (!barcodes) {// Deleted invalid barcodes
            this.finishUpdating = true;
            this.disableForm();
            return;
        }
        this.isLoading = true;
        if (this.isChangeStatus) {
            this.shipmentService.updateBulkPackage(body).subscribe(
                (response) => {
                    this.handleUpdateResponse(response);
                }, () => {
                    this.isLoading = false;
                }
            );
        } else {
            if (this.receiveBulkBarcodes) {
                this.sortPackages(body);
            } else if (this.exportBulkBarcodes) {
                this.exportBulkPackagesByBarcodes(body);
            } else if (this.isReceive) {
                this.receivePackages(body);
            } else {
                this.sortPackages(body);
            }
        }
    }

    private handleUpdateResponse(response) {
        if (response === null) {
            this.finishUpdating = true;
            this.isLoading = false;
            this.doneUpdating();
            return;
        }
        this.sentOnce = true;
        this.updateBarcodesStatus(response);
        if (!this.getFailedBarcodes().length) {
            this.finishUpdating = true;
            this.disableForm();
        }
        this.isLoading = false;
    }

    private receivePackages(body) {
        this.shipmentService.receiveBulkPackages(body).subscribe(
            (response) => {
                this.handleUpdateResponse(response);
            }, () => {
                this.isLoading = false;
            }
        );
    }
    private sortPackages(body) {
        this.shipmentService.sortBulkPackages(body, this.customerId).subscribe(
            (response) => {
                this.handleUpdateResponse(response);
            }, () => {
                this.isLoading = false;
            }
        );
    }
    private exportBulkPackagesByBarcodes(body) {
        this.shipmentService.addBarcodes(body, this.customerId).subscribe(
            (response) => {
                this.handleUpdateResponse(response);
            }, () => {
                this.isLoading = false;
            }
        );
    }
    private updateBarcodesStatus(response) {
        this.barcodes = response;
    }

    private getFailedBarcodes() {
        let barcodes = '';
        for (const barcode in this.barcodes) {
            if (this.barcodes[barcode].status === 'FAILED') {
                if (this.barcodes[barcode].barcode) {
                    barcodes += (this.barcodes[barcode].barcode + ' ');
                } else {
                    this.barcodes.splice(parseInt(barcode), 1);
                }
            }
        }
        return barcodes;
    }

    public doneUpdating() {
        this.activeModal.close({isSuccess: true, });
    }

    prepareUpdateRequest(barcodes) {
        const status = this.form.value.status;
        let body: any = {};
        if (this.isChangeStatus) {
            if (REQUIRE_SHELF.indexOf(status) !== -1) {
                body = {
                    hubId: this.form.value.hubId,
                    shelfId: this.form.value.shelfId
                };
            } else if (REQUIRE_HUB.indexOf(status) !== -1) {
                body = {
                    hubId: this.form.value.hubId
                };
            } else if (REQUIRE_DRIVER.indexOf(status) !== -1) {
                body = {
                    driverId: this.form.value.driverId
                };
            } else if (REQUIRE_POSTPONED_DELIVERY_DATE.indexOf(status) !== -1) {
                body = {
                    postponedDeliveryDate: this.transformDate(this.form.value.postponedDate),
                    notes: this.form.value.notes
                };
                if (this.form.value.deliveredType === 'hub') {
                    body['hubId'] = this.form.value.hubId;
                } else {
                    body['driverId'] = this.form.value.driverId;
                }
            } else if (REQUIRE_DELIVERY_DATE.indexOf(status) !== -1 && status !== 'RETURNED_BY_RECIPIENT') {
                body = {
                    deliveryDate: this.form.value.deliveryDate
                };
                if (this.form.value.deliveredType === 'hub') {
                    body['hubId'] = this.form.value.hubId;
                } else {
                    body['driverId'] = this.form.value.driverId;
                }

            } else if (REQUIRE_HUB_DRIVER_SELECTION) {
                if (status === 'RETURNED_BY_RECIPIENT' && this.form.value.isDelivered) {
                    body['deliveryDate'] = this.form.value.deliveryDate;
                    body['isDelivered'] = true;
                } else {
                    body['isDelivered'] = false;
                    if (this.form.value.deliveredType === 'hub') {
                        body['hubId'] = this.form.value.hubId;
                    } else {
                        body['driverId'] = this.form.value.driverId;
                    }
                }
                body['notes'] = this.form.value.notes;
            } else {
                body = {};
            }
            body['status'] = status;
        } else {
            if (this.driverId) {
                body['driverId'] = this.driverId.toString();
            } else {
                if (this.hubId) {
                    body['hubId'] = this.hubId.toString();
                } else {
                    if (this.partnerId) {
                        body['partnerId'] = this.partnerId.toString();
                    }
                }
            }
            if (this.form.value.cod) {
                body['cod'] = this.form.value.cod.toString();
            }
            if (this.form.value.expenses) {
                body['expenses'] = this.form.value.expenses;
            }
            if (this.form.value.expensesType) {
                body['expensesType'] = this.form.value.expensesType;
            }
        }

        if (body && body.driverId && body.driverId.value) {
            body.driverId = body.driverId.value;
        }
        body['barcodes'] = barcodes;
        body['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return body;
    }

    public disableForm() {
        Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].disable();
        });
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

}
