import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ShipmentsService} from '../services/shipments.service';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {AssignPackageComponent} from '../assign-package/assign-package.component';
import {RolesService} from '../../shared/services/roles/roles.service';
import {UserService} from '../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {
    PICKUP_STATUS_ALL,
    PICKUP_STATUS_LIST
} from '../services/constants';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {UsersService} from '../../users/services/users.service';
import {ShippingLinesService} from '../../administration/services/shipping-lines.service';
import {CustomerRegionFilterComponent} from '../customer-region-filter/customer-region-filter.component';
import * as _ from 'lodash';
import {ChooseHubAndDriverComponent} from '../choose-hub-and-driver/choose-hub-and-driver.component';
import {AddShipmentQuickComponent} from '../add-shipment-quick/add-shipment-quick.component';
import {PickupsModel} from '../../shared/models/Pickups.model';
import {ServiceTypesService} from '../../administration/services/service-types.service';

@Component({
    selector: 'app-draft-pickups-component',
    templateUrl: 'draft-pickups.component.html',
    styleUrls: ['draft-pickups.component.scss']
})
export class DraftPickupsComponent implements OnInit {

    public pickups: PickupsModel[];
    public debounceUpdateCost;

    public permissions = '';

    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public fromDate;
    public toDate;

    // is all selected;
    public isAllSelected = false;
    public isThereSelection = false;
    public pageNumber = 0;
    public totalRecords = 0;
    public searchContent = '';
    @ViewChild('table', {static: false}) table;
    public typeOfSelection: 'all' | 'pending' = 'all';
    public pickupStatusList;
    public selectedPickupStatus: string;
    public pickupStatusAll = PICKUP_STATUS_ALL;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public status = '';
    public driverID = 0;
    public hubId = 0;
    public customerID = 0;
    public pickupLine;
    public pickupLines = [];
    // @ViewChild('fileChooserShipments', {static: false}) fileChooserShipments: ElementRef;
    public selectedLanguage = '';
    public params = null;
    public first = 0; // firstElement in paginator
    public searchParameters = {};
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;

    public vehicleTypesList = [];
    public selectedVehicleType: any;
    selectedVehicleTypeName: string;

    @ViewChild('vehicleTypeAC') vehicleTypeAC;
    @ViewChild('senderRegionPopOver') senderRegionPopOver: NgbPopover;

    senderRegionData: {customerName: string, originRegion: any, originCity: any, originVillage: any } = {
        customerName: null,
        originRegion: null,
        originCity: null,
        originVillage: null};

    constructor(
        private shipmentsService: ShipmentsService,
        private router: Router,
        private confirmService: ConfirmationService,
        private messageService: MessageService,
        public translateService: TranslateService,
        private modalService: NgbModal,
        private rolesService: RolesService,
        private userService: UserService,
        private datePipe: DatePipe,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private shippingLinesService: ShippingLinesService,
        private serviceTypesService: ServiceTypesService) {
    }

    ngOnInit() {
        this.extractQueryParams();
        this.fetchShippingLines();
        this.selectedLanguage = this.translateService.currentLang;
        this.selectedPickupStatus = 'PENDING';
        this.setPickupStatus();
        this.initSearchParams();
    }

    public initSearchParams() {
        this.searchParameters = {
            barcode: {
                value: ''
            },
            pickupDate: {
                value: ''
            },
            invoiceNumber: {
                value: ''
            },
            notes: {
                value: ''
            }
        };
    }
    getVehicleTypes(event) {
        const query = '?page=1&pageSize=20&search=' + event.query;
        this.serviceTypesService.getAllVehicleTypes(query).subscribe(
            (res: any) => {
                this.vehicleTypesList = res;
            }, error => {
                console.error(error);
            }
        );
    }
    public onClearVehicleTypeFilter() {
        this.selectedVehicleType = null;
        this.initPickups();
    }
    public chooseVehicleType($event) {
        this.selectedVehicleType = JSON.parse(JSON.stringify($event));
        this.selectedVehicleTypeName = this.selectedLanguage === 'ar' ? this.selectedVehicleType.arabicName : this.selectedVehicleType.name;
        this.initPickups();
    }
    public prepareFiltersList() {
        const filtersList = [];
        for (const [key, value] of Object.entries(this.searchParameters)) {
            if (value['value']) {
                if (key.includes('Date') && typeof value === 'object') {
                    filtersList.push({fieldName: key, ...value, value: this.transformDate(value['value'])});
                } else if (typeof value === 'object') {
                    filtersList.push({fieldName: key, ...value});
                }
            }
        }
        return filtersList;
    }

    public receivePickupsFromDriver() {
        const modal = this.modalService.open(ChooseHubAndDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.isPickup = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    public printPickups() {
        this.isLoading = true;
        const pickupIds = this.getSelectedPickups().map(pkg => pkg.id);
        this.usersService.downloadDriverPickupsPdf(pickupIds).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.isLoading = false;
            });
    }

    public extractQueryParams() {
        this.route.queryParams.subscribe(
            (params: Params) => {
                this.params = params;
                if (params.status) {
                    this.status = params.status;
                    this.selectedPickupStatus = this.status;
                }
                if (params.fromDate) {
                    if (!this.fromDate) {
                        this.fromDate = new Date(Date.parse(params.fromDate));
                    }
                }
                if (params.toDate) {
                    if (!this.toDate) {
                        this.toDate = new Date(Date.parse(params.toDate));
                    }
                }
                if (params.hubId) {
                    this.hubId = params.hubId;
                }
                if (params.driverId) {
                    this.driverID = params.driverId;
                }
                if (params.customerId) {
                    this.customerID = params.customerId;
                }
                if (params.shippingLine) {
                    this.pickupLine = {id: params.shippingLine};
                }
                if (params.search) {
                    this.searchContent = params.search;
                }
                this.translateService.onLangChange.subscribe(() => {
                    this.selectedLanguage = this.translateService.currentLang;
                    this.setPickupStatus();
                    this.initPickups();
                });
            }
        );
    }

    private initSelection() {
        this.isThereSelection = false;
        this.isAllSelected = false;
    }

    private initPickups() {
        this.setQueryParams();
        this.initSelection();
        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery();
        const tableFilters = this.prepareFiltersList();

        if (this.driverID) {
            this.usersService.getDriverPickups(this.driverID, URLQuery, {searchQueryList: tableFilters}).subscribe(
                (response: { pkgs: any[], totalRecordsNo: number }) => {
                    this.getPickupsReponse(response);
                }, error => {
                    console.error(error);
                    this.spinnerState = SpinnerState.FAILED;
                }
            );
        } else {
            this.shipmentsService.getPickups(URLQuery, tableFilters).subscribe(
                (response: { pkgs: any[], totalRecordsNo: number }) => {
                    this.getPickupsReponse(response);
                }, error => {
                    console.error(error);
                    this.spinnerState = SpinnerState.FAILED;
                }
            );
        }
    }

    private setQueryParams() {
        const queryParams = {...this.params};
        if (this.fromDate) {
            queryParams['fromDate'] = this.fromDate.toISOString();
        } else {
            delete queryParams['fromDate'];
        }
        if (this.toDate) {
            queryParams['toDate'] = this.toDate.toISOString();
        } else {
            delete queryParams['toDate'];
        }
        if (this.selectedPickupStatus !== queryParams['status']) {
            // reset filters on change status
            this.resetFilters(queryParams);
        } else {
            if (this.pickupLine) {
                queryParams['shippingLine'] = this.pickupLine.id;
            } else {
                delete queryParams['shippingLine'];
            }
            if (this.customerID) {
                queryParams['customerId'] = this.customerID;
            } else {
                delete queryParams['customerId'];
            }
            if (this.driverID) {
                queryParams['driverId'] = this.driverID;
            } else {
                delete queryParams['driverId'];
            }
            if (this.hubId) {
                queryParams['hubId'] = this.hubId;
            } else {
                delete queryParams['hubId'];
            }
        }
        if (this.selectedPickupStatus) {
            queryParams['status'] = this.selectedPickupStatus;
        }
        if (this.searchContent) {
            queryParams['search'] = this.searchContent;
        } else {
            delete queryParams['search'];
        }
        this.router.navigate([], {queryParams: queryParams}).then(() => {
            if (this.pickupLine !== undefined && this.pickupLine && this.pickupLines.length) {
                this.pickupLine = this.pickupLines.find(element => element.id.toString() === this.pickupLine.id);
            }
        });
    }

    public resetAllFilters() {
        this.customerID = 0;
        // tslint:disable-next-line:forin
        for (const item in this.searchParameters) {
            this.searchParameters[item].value = '';
        }
        this.fromDate = '';
        this.toDate = '';
        this.customerID = 0;
        this.searchContent = '';
        this.onStatusChanged();
    }

    public resetFilters(queryParams) {
        if (queryParams['shippingLine']) {
            delete queryParams['shippingLine'];
        }
        if (queryParams['driverId']) {
            delete queryParams['driverId'];
        }
        if (queryParams['hubId']) {
            delete queryParams['hubId'];
        }
        if (queryParams['search']) {
            delete queryParams['search'];
        }
    }

    private getPickupsReponse(response) {
        this.pickups = response.pkgs ? response.pkgs : response.data;
        this.totalRecords = response.totalRecordsNo;
        if (this.totalRecords > 0) {
            this.spinnerState = SpinnerState.LOADED;
        } else {
            this.spinnerState = SpinnerState.EMPTY;
            this.isThereSelection = false;
        }
    }

    public createQuery() {
        let result = `?pageSize=${this.pageSize}&page=` + (this.pageNumber + 1);

        if (this.searchContent !== '') {
            result += '&search=' + this.searchContent;
        }
        if (this.fromDate) {
            result += '&fromDate=' + this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            result += '&toDate=' + this.transformDate(this.toDate);
        }
        if (this.selectedPickupStatus && this.selectedPickupStatus !== this.pickupStatusAll) {
            result += '&status=' + this.selectedPickupStatus;
        }
        if (this.customerID) {
            result += '&customerId=' + this.customerID;
        }
        if (this.pickupLine) {
            result += '&originShippingLineId=' + this.pickupLine.id;
        }
        if (this.hubId) {
            result += '&hubId=' + this.hubId;
        }
        if (this.fromDate || this.toDate) {
            result += '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (this.selectedVehicleType) {
            result += '&vehicleTypeId=' + this.selectedVehicleType.id;
        }
        return result;
    }

    public onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }

        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }

        this.isThereSelection = false;
        this.pickups.forEach((pkg: PickupsModel) => {
            if (selected) {
                if (this.typeOfSelection === 'all') {
                    pkg.isSelected = selected;
                    this.isThereSelection = true;
                } else {
                    if (pkg.status === 'Pending customer care approval') {
                        pkg.isSelected = selected;
                        this.isThereSelection = true;
                    } else {
                        pkg.isSelected = false;
                    }
                }
            } else {
                pkg.isSelected = selected;
            }
        });
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.initPickups();
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    private getSelectedPickups() {
        const selectedPickups = [];
        if (!this.pickups) {
            return;
        }

        this.pickups.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPickups.push(pkg);
                }
            }
        );
        return selectedPickups;
    }
    public openQuickAdd(pickup, isMultiPickups?: boolean) {
        const modal = this.modalService.open(AddShipmentQuickComponent, {
            backdrop: 'static',
            windowClass: 'create-new-package',
            size: 'lg'
        });
        if (isMultiPickups) {
            modal.componentInstance.isMultiPickups = true;
            // modal.componentInstance.isPickup = false;
        }

        if (pickup || isMultiPickups) {
            modal.componentInstance.isPickup = false;
            modal.componentInstance.pickup = pickup;
        } else {
            modal.componentInstance.isEditMode = false;
            modal.componentInstance.isPickup = true;
        }

        modal.result.then(
            (data) => {
                    this.initPickups();
                    this.isLoading = false;
            }
        ).catch(
            (error) => {
                this.isLoading = false;
            }
        );

    }

    public onSearch() {
        if (!this.debounceUpdateCost) {
            this.debounceUpdateCost = _.debounce(() => {
                this.pageNumber = 0;
                this.first = 0;
                this.table.reset();
            }, 1000);
        }
        this.debounceUpdateCost();
    }

    public setPaginatorQueryParam(event) {
        this.first = event.first;
        this.setQueryParams();
    }

    public onToggleSelection() {
        const selectedPickups = this.getSelectedPickups();
        if (selectedPickups) {
            this.isThereSelection = selectedPickups.length !== 0;
            this.isAllSelected = selectedPickups.length === this.pickups.length;
        }
    }
    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get(
            'ALERTS.COPIED'
        )
            .subscribe(
                (data) => {
                    this.messageService.add({severity: 'success', detail: data});
                }
            );
    }

    public downloadPdf(pkgId: number) {
        this.shipmentsService.downloadPdf(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
            },
            error => {
                console.error(error);
            });
    }

    public setPickupStatus() {
        this.translateService.get(PICKUP_STATUS_LIST).subscribe(values => {
            this.pickupStatusList = PICKUP_STATUS_LIST.map(value => {
                return {
                    label: values[value],
                    value: value
                };
            });
        });
        // return PICKUP_STATUS_LIST.map((value) => {
        //     let label = value;
        //     this.translateService.get(value)
        //         .subscribe((data) => {
        //             label = data;
        //         });
        //     return {label, value};
        // });
    }

    public onStatusChanged() {
        this.driverID = 0;
        this.hubId = 0;
        this.pickupLine = null;
        this.initPickups();
    }

    public onDriverSelected(driverId) {
        this.driverID = driverId;
        this.initPickups();
    }

    public onCustomerSelected(customerID) {
        this.customerID = customerID;
        if (customerID === undefined) {
            this.customerID = 0;
        }
        this.initPickups();
    }

    private fetchShippingLines() {
        this.shippingLinesService.getShippingLines('SENDER').subscribe((response: any) => {
            this.pickupLines = response;
            if (response && response.length) {
                if (this.pickupLine === null) {
                    this.pickupLine = response[0];
                } else {
                    if (this.pickupLine !== undefined) {
                        this.pickupLine = response.find(element => element.id.toString() === this.pickupLine.id);
                    }
                }
            }
        });
    }
    public onLineSelected() {
        this.initPickups();
    }
    public openSelectCustomerRegionDialog(index) {
        const modal = this.modalService.open(CustomerRegionFilterComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.isSender = true;
        if (index) {
            modal.componentInstance.customerName = this.searchParameters['senderName'] ? this.searchParameters['senderName'].value : '';
            modal.componentInstance.originRegion = this.searchParameters['originRegionId'];
            modal.componentInstance.originCity = this.searchParameters['originCityId'];
            modal.componentInstance.originVillage = this.searchParameters['originVillageId'];
        }
        modal.result.then(
            (status: { isSuccess: boolean, customer?: number, regionId?: number, cityId?: number, villageId?: number }) => {
                if (index) {// sender
                    if (status.customer) {
                        this.searchParameters['senderName'] = {'value': status.customer};
                    } else {
                        delete this.searchParameters['senderName'];
                    }

                    if (status.regionId) {
                        this.searchParameters['originRegionId'] = status.regionId;
                    } else {
                        delete this.searchParameters['originRegionId'];
                    }

                    if (status.cityId) {
                        this.searchParameters['originCityId'] = status.cityId;
                    } else {
                        delete this.searchParameters['originCityId'];
                    }

                    if (status.villageId) {
                        this.searchParameters['originVillageId'] = status.villageId;
                    } else {
                        delete this.searchParameters['originVillageId'];
                    }
                }
                this.onSearch();
            }
        ).catch(
            (error) => {
            }
        );
    }
    public resetField(name) {
        if (name === 'fromDate') {
            this.fromDate = '';
        } else {
            if (name === 'toDate') {
                this.toDate = '';
            } else {
                this.searchParameters[name].value = '';
            }
        }
        this.setQueryParams();
        this.onSearch();
    }
    public removePickup(pickupId) {
        if (!pickupId) {
            return;
        }
            let message = '';
            this.translateService.get(
                ['ALERTS.DELETE_PACKAGE', 'GENERAL.YES', 'GENERAL.NO']
            ).subscribe(
                    (data) => {
                        message = data['ALERTS.DELETE_PACKAGE'];
                        this.confirmService.confirm({
                            message: message,
                            accept: () => {
                                this.shipmentsService.removePickups(pickupId).subscribe(
                                    (response) => {
                                        this.initPickups();
                                        this.translateService.get(
                                            'ALERTS.SHIPMENT_REMOVED'
                                        )
                                            .subscribe(
                                                (translatedMessage) => {
                                                    this.messageService.add({severity: 'success', detail: translatedMessage});
                                                }
                                            );
                                    }, (error) => {
                                        const errorMessage = error.error ? error.error : 'Something went wrong';
                                        console.error(error);
                                    }, () => {
                                    }
                                );
                            },
                            acceptLabel: data['GENERAL.YES'],
                            rejectLabel: data['GENERAL.NO'],
                        });
                    }
                );
    }
    public assignPickupsToDriver() {
        this.isLoading = true;
        const selectedPickups = this.getSelectedPickups();
        if (selectedPickups.length > 0) {
            const modal = this.modalService.open(AssignPackageComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.assignTo = 'driver';
            modal.componentInstance.ids = selectedPickups.map(pkg => pkg.id);
            modal.componentInstance.isPickup = true;
            modal.result.then(
                (result: { isSuccess: boolean }) => {
                    if (result.isSuccess) {
                        this.translateService.get(
                            'ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                }
                            );
                        this.initPickups();
                    }
                    this.isLoading = false;
                }
            ).catch(
                (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        }
    }
    public showConvertToPackage (status) {
        return status === 'IN_HUB' || status === 'IN_CAR';
    }
    public allowDelete (status) {
        return !(status === 'IN_HUB' || status === 'IN_CAR');
    }

    onFilterSenderRegion(data) {
            if (data.customer) {
                this.searchParameters['senderName'] = {'value': data.customer};
                this.senderRegionData.customerName = this.searchParameters['senderName'] ? this.searchParameters['senderName'].value : '';
            } else {
                delete this.searchParameters['senderName'];
            }

            if (data.regionId) {
                this.searchParameters['originRegionId'] = {'value': data.regionId.value};
                this.senderRegionData.originRegion = data.regionId;
            } else {
                delete this.searchParameters['originRegionId'];
            }

            if (data.cityId) {
                this.searchParameters['originCityId'] = {'value': data.cityId.value} ;
                this.senderRegionData.originCity = data.cityId;
            } else {
                delete this.searchParameters['originCityId'];
            }

            if (data.villageId) {
                this.searchParameters['originVillageId'] = {'value': data.villageId.value};
                this.senderRegionData.originVillage = data.villageId;
            } else {
                delete this.searchParameters['originVillageId'];
            }
            if (this.senderRegionPopOver && this.senderRegionPopOver.isOpen()) {
                this.senderRegionPopOver.close();
            }
        this.initPickups();
    }

    closeSenderFilter() {
            this.senderRegionPopOver.close();
        }

    selectDateRange(event: {start: Date; end: Date}) {
        this.fromDate = event.start;
        this.toDate = event.end;
        this.onSearch();
    }
}
