import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MessagingService} from '../../shared/services/messaging.service';
import {HubModel} from '../../shared/models/hub.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    notifications = [];
    isLoadingNotification = false;
    isSettingAllAsRead = false;
    isEnglishSelected = false;
    hasMoreNotification = false;
    isLoadingMoreNotification = false;

    @Output() changeNotificationsNum = new EventEmitter();
    private notificationQueryParams = {
        page: 1,
        pageSize: 20
    };
    private notificationsNum = 0;

    constructor(private messagingService: MessagingService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.isEnglishSelected = this.translateService.currentLang === 'en';
        this.initializeNotificationParams();
        this.getNotifications();
    }

    onMarkAllAsRead() {
        this.isSettingAllAsRead = true;
        this.messagingService.setAllAsRead().subscribe(res => {
                this.changeBadgeNumber();
                this.initializeNotificationParams();
                this.getNotifications();
                this.isSettingAllAsRead = false;
            },
            error => {
                console.log(error);
                this.isSettingAllAsRead = false;
            });
    }

    changeBadgeNumber() {
        this.messagingService.numberOfUnreadNotification().subscribe((res: any) => {
            // this.messagingService.notificationNumber = res;
            this.messagingService.notificationNumber = res.notificationsNumber;
            this.notificationsNum = res.notificationsNumber;
            this.changeNotificationsNum.emit(this.notificationsNum);
        }, error => {
            console.log(error);
        });
    }

    deleteNotification(notification: any, index: number) {
        this.messagingService.deletNotification(notification.id).subscribe(res => {
            if (index > -1) {
                this.notifications.splice(index, 1);
            }
        }, error => {
            console.log(error);
        });
    }

    loadMoreNotifications() {
        const currentPage = this.notificationQueryParams.page || 0;
        this.notificationQueryParams = {
            page: currentPage + 1,
            pageSize: 20
        };
        this.getNotifications();
    }

    private initializeNotificationParams() {
        this.notificationQueryParams = {
            page: 1,
            pageSize: 20
        };
        this.hasMoreNotification = true;
    }

    private getNotifications() {
        if (this.notificationQueryParams.page === 1) {
            this.notifications = [];
            this.isLoadingNotification = true;
        } else {
            this.isLoadingMoreNotification = true;
        }

        this.messagingService.getNotifications(this.notificationQueryParams).subscribe((res: any) => {
            console.log(res);
                if (this.notificationQueryParams.page > 1) {
                    this.notifications = this.notifications.concat(res);
                    if (res.length < this.notificationQueryParams.pageSize) {
                        this.hasMoreNotification = false;
                    }
                } else {
                    this.notifications = res;
                }
                this.isLoadingMoreNotification = false;
            },
            error => {
                console.log(error);
                this.isLoadingMoreNotification = false;
            },
            () => {
                this.isLoadingNotification = false;
                this.isLoadingMoreNotification = false;
            });
    }

    trackNotificationById(index: number, item: any) {
        return item.id;
    }
}
