import {Component, OnInit, ViewChild} from '@angular/core';
import {COLUMNS_TYPE} from '../../../../shared/components/generic-table/generic-table.component';
import {FULFILMENT_CONSTANTS} from '../../../../fulfilment/services/constants';
import {TranslateService} from '@ngx-translate/core';
import {AddReceiptDialogComponent} from '../add-receipt-dialog/add-receipt-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.scss']
})
export class ReceiptListComponent implements OnInit {
  isSearchContent;
  searchContent = '';
  columnsDef;
  tableConfig;
  @ViewChild('table') table;
  totalRecords = 0;

  constructor(private modalService: NgbModal,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.initColumnDef();
    this.tableConfig = {
      url: ':api/accountant/customer/receipt',
      preRequestHandler: (req: {
        params: any; body: any; url: any;
      }) => {
        if (this.searchContent) {
          req.params.search = this.searchContent;
        }
      },
      postRequestHandler: (res) => {
        this.totalRecords = this.table.totalRecords;
      }
    };
  }

  public toggleIsSearchContent(value) {
    this.isSearchContent = value;
    if (!value) {
      this.searchContent = '';
      this.onSearch();
    }
  }

  onSearch() {
    this.table.refreshTableData();
  }

  private initColumnDef() {
    this.columnsDef = [
      {
        header: 'GENERAL.BARCODE',
        field: 'barcode',
      },
      {
        header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME',
        field: 'customerName',
        columnType: COLUMNS_TYPE.WITH_MULTI_SELECT_FILTER,

      },
      {
        header: 'FULFILMENT.ORDER.TABLE.CREATED_DATE',
        field: 'createdDate',
        tdType: COLUMNS_TYPE.DATE,
        columnType: COLUMNS_TYPE.DATE_WITH_RANGE_FILTER,
      },
      {
        header: 'ACCOUNTING.WALLET.AMOUNT',
        field: 'amount',
        isCurrency: true
      },
      {
        header: 'ADD_SHIPMENTS.PAYMENT_METHOD',
        field: 'paymentType',
        // columnType: COLUMNS_TYPE.DROPDOWN_FILTER,
        // options: this.paymentStatusList
        tdType: COLUMNS_TYPE.CUSTOM,
        getHTML: (r) => {
          return this.translateService.instant(r.paymentType);
        }
      },
      {
        header: '',
        field: 'printBarcode',
        tdType: COLUMNS_TYPE.CUSTOM,
        tdClass: 'table-action',
        clickEvent:  (receipt) => {
          this.openAddReceipt(receipt);
        },
        getHTML: (bin) => {
          return `<span><img class="cursor-pointer" src="assets/icons//containers-management/edit.svg" alt=""></span>`;
        }
      },
    ];

  }


    openAddReceipt(receipt) {
      const modal = this.modalService.open(AddReceiptDialogComponent, {
        backdrop: 'static',
        size: 'lg'
      });
      if (receipt && receipt.customerId) {
        receipt.customer = {
          id: receipt.customerId,
          fullName: receipt.customerName
        };
        modal.componentInstance.isEdit = true;
        modal.componentInstance.receiptId = receipt.id;


      }
      modal.componentInstance.data = receipt;
      modal.result.then(
          (value) => {
            if (value && value.isSuccess) {
              this.table.refreshTableData();
            }
          }
      ).catch(
          (error) => {
            console.error(error);
          }
      );
    }
}
