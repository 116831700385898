import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

import {DatePipe} from '@angular/common';
import {CodCustomer} from '../../../shared/models/cod-customer';
import { SpinnerState } from 'src/app/shared/behavior/spinner-state.enum';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {UsersService} from '../../../users/services/users.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {UserService} from '../../../shared/services/user.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {CompaniesService} from '../../../companies/services/companies.service';
import {
    DELIVERED_PACKAGES_DATE_FILTERS_LIST, EXPORTED_DATE_FILTERS_LIST,
    EXPORTED_PACKAGES_DATE_FILTERS_LIST,
    RECEIVE_DATE_FILTERS_LIST
} from '../../../shipment/services/constants';
import {DASHBOARD_CONSTANT} from '../../../dashboard/services/dashboard.service';
import {ChooseHubDriverPartnerComponent} from '../../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {MobComponent} from '../../../users/mob/mob.component';
import {ChooseExistingComponent} from '../../../shipment/choose-existing/choose-existing.component';
import {USERS_CONSTANTS} from '../../../users/services/constants';
import {ChoosePaymentTypeComponent} from '../../../users/choose-payment-type/choose-payment-type.component';
import {PrintPackageOptionsComponent} from '../../../users/print-package-options/print-package-options.component';
import {ChooseDriverComponent} from '../../../shipment/choose-driver/choose-driver.component';
import {EnterBarcodeComponent} from '../../../shipment/enter-barcode/enter-barcode.component';
import {ImageSliderComponent} from '../../../shared/components/image-slider/image-slider.component';
import {
    CustomConfirmationMessageComponent
} from '../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {MassCodReportHistoryComponent} from '../mass-cod-report-history/mass-cod-report-history.component';
import {ADMINISTRATION_CONSTANTS} from '../../administration-constants';
import {FieldValidation} from '../../../shared/components/form-components/field-input/field-input.component';


@Component({
    selector: 'app-cod-customers',
    templateUrl: './cod-customers.component.html',
    styleUrls: ['./cod-customers.component.scss']
})
export class CodCustomersComponent implements OnInit {
    /**
     *
     */
    public customers: CodCustomer[];
    public custodyTypeList = [
        {label: 'GENERAL.BY_CUSTOMERS', value: 'CUSTOMER'},
        {label: 'GENERAL.BY_HUB', value: 'HUB'},
        {label: 'GENERAL.BY_DRIVER', value: 'DRIVER'},
        {label: 'GENERAL.BY_PARTNER', value: 'PARTNER'}
    ];
    public paymentMethod: { label: string, value: any };
    public paymentsMethod;
    public paymentValue = [
        'ALL',
        'CASH',
        'CHEQUE',
        'BANK_TRANSFER',
        'PREPAID',
        'DIGITAL_WALLET'
    ];
    public optionCustody = this.custodyTypeList[0];
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public customerId;
    public driverId;
    public allowPrintPackages = false;
    public selectedPackages;
    // is all selected;
    public isAllSelected = false;
    public isThereSelection = false;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    permissions = '';
    public first = 0; // firstElement in paginator
    public totalGreaterThan = null;
    public totalLessThan = null;
    public pageNumber = 0;
    public totalRecords = 0;
    public searchContent = '';
    public fromDate;
    public toDate;
    public status;
    public receive = false;
    public custody = false;
    public sort = false;
    public deliver = false;
    public inCar = false;
    public canExport = false;
    public isCOD = true;
    public totalPackages = 0;
    public totalCod = 0;
    public totalCostSum = 0;
    public totalCodWithoutCost = 0;
    public totalCostWithoutReceiveCustody = 0;
    public totalPercentageFee = 0;
    public receivedMoney = 0;
    public mobReceivedMoney = 0;
    public exportedPackages = false;
    public isInvoices = false;
    public isCodPendingCustomersApproval = false;
    public selectedLanguage = '';
    public mobAvailable = false;
    public excludeSort = false;
    public isBlock = false;
    public params = {};
    public isSupportReceiveWithoutReleasingCustody = false;
    public isOnlyShowSignature = false;
    public receiveWithoutReleasingCustody = false;
    @ViewChild('table') table;
    public dateFiltersList;
    public selectedDateFilter = '';
    public selectedFilter = '';
    public selectedEntity = '';
    public selectedDriver = '';
    public currentLanguage = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
    public digitFormat = '1.0-2';
    public dateRangPickerOptions;
    public sortByType;
    public isExportedCod = false;
    public customerTypes: {value: string, label: string}[];
    customerType;
    showCodFees = false;
    showCodTotalsForAdminOnly = [152]; // NEDCO company
    showCodTotals = false;
    companyId;
    isHubUserRole = false;

    isCodReport = false;
    showEditHistory = false;
    private isArchived: boolean;

    isEnableCustomerCommissionFee = false;

    isShowCommissionFees = false;
    showCustomerPaymentMethod;

    public fieldValidation = FieldValidation;


    constructor(
        private router: Router,
        private usersService: UsersService,
        private shipmentsService: ShipmentsService,
        private dateRangePickerService: DateRangePickerService,
        private messageService: MessageService,
        public translateService: TranslateService,
        private modalService: NgbModal,
        private rolesService: RolesService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private userService: UserService,
        public authenticationService: AuthenticationService,
        private companyService: CompaniesService,
        // private location: Location,
        private authService: AuthenticationService,
        private confirmationService: ConfirmationService
    ) {
        this.customerTypes = [
            {value: 'ALL', label: 'ALL'},
            {value: 'NORMAL', label: this.translateService.instant('CUSTOMER_TYPES.NORMAL')},
            {value: 'EXPRESS', label: this.translateService.instant('CUSTOMER_TYPES.EXPRESS')}
        ];
    }

    ngOnInit() {
        this.isArchived = (!!this.route.snapshot.data['isArchived']);
        this.showCodFees = this.userService.getCurrency() === 'SAR';
        this.mobAvailable = this.userService.userInfo.isUseBankTransfer;
        this.isEnableCustomerCommissionFee = this.userService.userInfo.isEnableCustomerCommissionFee;
        this.isSupportReceiveWithoutReleasingCustody = this.userService.userInfo.isSupportReceiveWithoutReleasingCustody;
        this.currentLanguage = this.translateService.currentLang;
        // this.excludeSort = this.userService.userInfo.isExcludeSort;
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });
        this.getPermissions();
        this.selectedLanguage = this.translateService.currentLang;
        this.route.url.subscribe((data) => {
            switch (data[0].path) {
                case 'receive-cod':
                    this.receive = true;
                    this.getCompanyBillingNotification();
                    break;
                case 'hub-custody':
                    this.custody = true;
                    break;
                case 'sort-cod':
                    this.sort = true;
                    this.canExport = true;
                    this.showCustomerPaymentMethod = true;
                    break;
                case 'exported-packages':
                    this.isExportedCod = true;
                    this.showCustomerPaymentMethod = true;
                case 'invoices':
                case 'cod-pending-customers-approval':
                    this.sort = true;
                    this.canExport = false;
                    this.exportedPackages = true;
                    this.allowPrintPackages = true;
                    if (data[0].path === 'invoices') {
                        this.isInvoices = true;
                    } else if (data[0].path === 'cod-pending-customers-approval') {
                        this.isCodPendingCustomersApproval = true;
                    }
                    break;
                case 'in-car-packages':
                    this.deliver = true;
                    this.inCar = true;
                    break;
                case 'delivered-packages':
                case 'archived-mass-cod-report':
                    this.deliver = true;
                    this.allowPrintPackages = true;
                    break;
                case 'manage-cod-customers':
                    this.isCodReport = true;
                    break;
            }
            this.isCOD = !this.sort && !this.receive && !this.deliver && !this.custody;
        });
        this.route.queryParams.subscribe(
            (params: Params) => {
                this.params = params;
                if (params.receive_without_releasing_custody) {
                    this.receiveWithoutReleasingCustody = params.receive_without_releasing_custody;
                }
                if (params.type) {
                    for (const obj in this.custodyTypeList) {
                        if (this.custodyTypeList[obj].value === params.type) {
                            this.optionCustody = this.custodyTypeList[obj];
                        }
                    }
                }
                if (params.dateFilterType) {
                    this.selectedDateFilter = params.dateFilterType;
                } else {
                    this.initDateFilter();
                }
                if (params.fromDate) {
                    this.fromDate = new Date(params.fromDate);
                } else {
                    this.fromDate = null;
                }
                if (params.toDate) {
                    this.toDate = new Date(params.toDate);
                } else {
                    this.toDate = null;
                }
                if (params.status) {
                    this.status = params.status;
                } else {
                    this.status = null;
                }
                if (params.search) {
                    this.searchContent = params.search;
                }
                if (params.threshold) {
                    this.totalGreaterThan = params.threshold;
                }

                if (params.terminus) {
                    this.totalLessThan = params.terminus;
                }
            }
        );
        this.isShowCommissionFees = this.isEnableCustomerCommissionFee && (this.sort || this.deliver || this.receive);
        this.dateFiltersList = this.setDateFiltersList();
        this.initDateFilter();
        if (!this.isCOD) {
            this.initCustomersSumValues();
        }
        this.paymentsMethod = this.getPaymentsMethod();
        this.companyId = this.authenticationService.companyId;
        this.handleVisibleComponent();
        this.showEditHistory = this.inCar || this.isInvoices || this.isExportedCod || this.isCodPendingCustomersApproval || this.deliver;
    }

    public getPaymentsMethod() {
        return this.paymentValue.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    /**
     * Initialize the permissions allowed by the logged in user.
     */
    getPermissions() {
        this.permissions = this.userService.userInfo.role;
        this.isHubUserRole = this.userService.isHubUserRole;
    }

    public getCompanyBillingNotification() {
        this.companyService.getCompanyBillingNotification().subscribe(
            (response: { isNotify: boolean, isBlock: boolean, notificationMessage: string }) => {
                this.isBlock = response.isBlock;
                if (response.isNotify) {
                    this.messageService.add({severity: 'error', detail: response.notificationMessage});
                }
            }, error => {
                console.error(error);
            }
        );
    }

    /**
     *
     */
    initCustomers() {
        this.spinnerState = SpinnerState.LOADING;
        const params = this.createParams();
        let type = 'COD';
        if (this.receive) {
            type = 'RECEIVE';
            if (this.receiveWithoutReleasingCustody) {
                type = 'is-hold-custody';
            }
        } else {
            if (this.sort) {
                if (this.canExport) {
                    type = 'SORT';
                } else if (this.isInvoices) {
                    type = 'INVOICE';
                } else if (this.isCodPendingCustomersApproval) {
                    type = 'COD_PENDING_CUSTOMER_APPROVAL';
                } else {
                    type = 'EXPORT';
                }
            } else {
                if (this.deliver) {
                    if (this.inCar) {
                        type = 'IN_CAR';
                    } else {
                        type = 'DELIVER';
                    }
                }
            }
        }
        this.usersService.getCodCustomers(params, type).subscribe(
            (response: {
                codCustomers?: any[], massCodPackages: any[], totalRecordsNo: number, totalPackagesNumber: number,
                totalCod: number, receivedMoney?: number, mobReceivedMoney?: number, costSum?: number, isExcludeSort?: any,
                totalCodWithoutCost?: number, id?: number
            }) => {
                this.customers = response.codCustomers ? response.codCustomers : response.massCodPackages;
                this.totalRecords = response.totalRecordsNo;
                if (response.mobReceivedMoney) {
                    this.mobReceivedMoney = response.mobReceivedMoney;
                }
                if (response.receivedMoney) {
                    this.receivedMoney = response.receivedMoney;
                }
                if (response.isExcludeSort) {
                    this.excludeSort = response.isExcludeSort;
                }
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                    this.isThereSelection = false;
                }
            }, () => {
                this.spinnerState = SpinnerState.EMPTY;
                this.isThereSelection = false;
            }
        );
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        if (this.totalGreaterThan !== null) {
            params['threshold'] = this.totalGreaterThan;
        }
        if (this.totalLessThan !== null) {
            params['terminus'] = this.totalLessThan;
        }
        if (this.customerId) {
            params[(this.receiveWithoutReleasingCustody ? 'entityId' : 'customerId')] = this.customerId;
        }
        if (this.driverId) {
            params['driverId'] = this.driverId;
        }
        if (this.status) {
            params['type'] = this.status;
        }
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            params['dateFilterType'] = this.selectedDateFilter;
        }
        if (this.receiveWithoutReleasingCustody) {
            params['type'] = this.optionCustody.value;
        }
        if (this.paymentMethod && this.paymentMethod.value !== 'ALL') {
            params['paymentMethod'] = this.paymentMethod.value;
        }
        if (this.deliver && this.isOnlyShowSignature) {
            params['isOnlyShowSignature'] = true;
        }
        if (this.sortByType) {
            params['sortByType'] = this.sortByType;
        }
        if (!!this.customerType && this.customerType != 'ALL') {
            params['customerType'] = this.customerType;
        }
        if (this.isArchived) {
            params['isArchived'] = true;
        }
        return params;
    }

    public setDateFiltersList() {
        let dateFiltersList;
        if (this.receive) {
            dateFiltersList = RECEIVE_DATE_FILTERS_LIST;
        } else if (this.exportedPackages) {
            dateFiltersList = EXPORTED_PACKAGES_DATE_FILTERS_LIST;
        } else if (this.deliver && !this.inCar) {
            dateFiltersList = DELIVERED_PACKAGES_DATE_FILTERS_LIST;
        } else {
            dateFiltersList = EXPORTED_DATE_FILTERS_LIST;
        }
        return dateFiltersList.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    public initDateFilter() {
        if (this.sort || this.exportedPackages || this.receive || this.deliver || this.receiveWithoutReleasingCustody) {
            this.selectedDateFilter = 'NONE';
        } else {
            this.selectedDateFilter = '';
        }
    }

    private deleteDatesParams() {
        const queryParams = {...this.params};
        delete queryParams['fromDate'];
        delete queryParams['toDate'];
        if (queryParams['fromDate']) {
            delete queryParams['fromDate'];
        }
        if (queryParams['toDate']) {
            delete queryParams['toDate'];
        }
    }

    public onDateFilterChange() {
        if (this.selectedDateFilter === 'NONE') {
            this.fromDate = '';
            this.toDate = '';
            this.deleteDatesParams();
            this.setQueryParams();
            this.initCustomers();
            this.initCustomersSumValues();
        } else {
            if (this.fromDate || this.toDate) {
                this.setQueryParams();
                this.initCustomers();
                this.initCustomersSumValues();
            }
        }
    }

    public loadCustomersLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.first = $event.first;
        this.initCustomers();
    }


    public onSearch() {
        this.pageNumber = 0;
        this.first = 0;
        this.setQueryParams();
        this.table.reset();
        this.initCustomersSumValues();
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    public goToCustomerCOD(customer) {
        const id = (customer.id) ? customer.id : customer.customerId;
        const params = {};
        let url = this.authenticationService.companyName + DASHBOARD_CONSTANT.ROUTE.HOME_COD_CUSTOMER + '/' + id;
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            params['dateFilterType'] = this.selectedDateFilter;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        if (this.receiveWithoutReleasingCustody) {
            params['type'] = 'RECEIVE';
            params['is_hold_custody'] = true;
            if (this.receiveWithoutReleasingCustody) {
                params['by'] = this.optionCustody.value;
            }
            url = this.authenticationService.companyName + 'home/receive-cod/packages/' + id;
        } else if (this.receive) {
            params['type'] = 'RECEIVE';
            url = this.authenticationService.companyName + 'home/receive-cod/packages/' + id;
        } else if (this.sort || this.deliver || this.inCar || this.exportedPackages) {
            params['type'] = 'SORT';
            if (this.exportedPackages) {
                params['type'] = 'EXPORT';
            }
            if (this.sort) {
                url = this.authenticationService.companyName + 'home/sort-cod/packages/' + id;
            }
            if (this.deliver && !this.inCar) {
                url = this.authenticationService.companyName + 'home/delivered-packages/' + id;
                params['type'] = 'SORT';
                params['page'] = 'DELIVERED';
                params['isArchived'] = this.isArchived;
                this.router.navigate([url], {
                    queryParams: params
                });
                return;
            }
            if (this.inCar) {
                params['page'] = 'IN_CAR';
                url = this.authenticationService.companyName + 'home/in-car-packages/' + id;
            }
            if (this.exportedPackages) {
                if (this.isInvoices) {
                    url = this.authenticationService.companyName + 'home/invoices/' + id;
                } else {
                    url = this.authenticationService.companyName + 'home/exported-packages/' + id;
                }
            }
        } else if (this.isCodReport) {
            if (this.status) {
                params['type'] = this.status;
            }
        }
        this.router.navigate([url], {
            queryParams: params
        });
    }

    public receivePackages() {
        this.verifyDriver();
    }

    public exportToPdf() {
        this.isLoading = true;
        this.usersService.exportToPdf(this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

    public verifyDriver() {
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.showPartners = true;
        modal.componentInstance.titleKey = 'ACCOUNTING.RECEIVE_COD_MENUITEM';
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, hubId?: number, partnerId?: number }) => {
                if (status.isSuccess) {
                    if (status.barcode) {
                        this.router.navigate([this.authenticationService.companyName + '/home/receive-cod/'
                        + status.barcode]);
                    } else {
                        if (status.hubId) {
                            this.router.navigate([this.authenticationService.companyName + '/home/receive-cod/'
                            + status.hubId], {queryParams: {'is_hub': true}});
                        } else { // partner
                            this.router.navigate([this.authenticationService.companyName + '/home/receive-cod/'
                            + status.partnerId], {queryParams: {'is_partner': true}});
                        }
                    }
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public mob() {
        const modal = this.modalService.open(MobComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.result.then((status: { isSuccess: boolean }) => {
            if (status.isSuccess) {
                // here
            }
        });

    }

    public sortPackages() {
        const modal = this.modalService.open(ChooseExistingComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.componentInstance.customersURL = USERS_CONSTANTS.GET_RECEIVED_COD_CUSTOMERS;
        modal.componentInstance.customersKey = 'codCustomers';
        modal.componentInstance.isFullName = true;
        modal.result.then(
            (status: { isSuccess: boolean, user: any }) => {
                if (status.isSuccess) {
                    this.router.navigate([this.authenticationService.companyName + 'home/sort-cod/'
                    + status.user.customerId]);
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public exportPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let pkgIds = [];
        if (shipment.id) {
            pkgIds.push(shipment.id);
        } else {
            if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
            }
        }

        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        this.usersService.exportMassPakcage(pkgIds).subscribe(() => {
            this.translateService.get(
                'ALERTS.MASS_PACKAGE_EXPORTED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.isLoading = false;
                        this.initCustomers();
                        this.initCustomersSumValues();
                    }
                );
        }, () => {
            this.isLoading = false;

        });
    }

    public deliverPackage(shipment, event) {
        this.isLoading = true;
        event.stopPropagation();
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.componentInstance.showPrintReport = true;
        modal.componentInstance.isInvoices = this.isInvoices;
        modal.componentInstance.addAttachments = true;
        modal.componentInstance.massCodId = shipment.id;
        const successMessage = this.isInvoices ? 'ALERTS.INVOICE_PAID_SUCCESSFULLY' : 'ALERTS.MASS_PACKAGE_DELIVERED_SUCCESSFULLY';

        modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, printReport: boolean, transferFees: number, attachments?: File[]}) => {
                if (data.paymentType) {
                    this.usersService.deliverMassPackage(shipment.id, data.paymentType, data.documentNumber,
                        this.transformDate(data.deliveryDate), data.transferFees, data.attachments)
                        .subscribe(() => {
                            this.translateService.get(successMessage)
                                .subscribe(
                                    (res: any) => {
                                        this.isLoading = false;
                                        this.messageService.add({severity: 'success', detail: res});
                                        this.initCustomers();
                                        this.initCustomersSumValues();
                                        if (data.printReport) {
                                            this.printPackage(shipment, event);
                                        }
                                    }
                                );
                        }, (err) => {
                            console.error(err);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            () => {
                this.isLoading = false;
            }
        );
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public printPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        if (this.exportedPackages || (this.deliver && !this.inCar)) {
            const modal = this.modalService.open(PrintPackageOptionsComponent, {
                backdrop: 'static',
                windowClass: 'verify-driver',
                size: 'lg'
            });
            modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93;
            modal.result.then(
                (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                    if (status.isSuccess) {
                        shipment.isPrintedByAccountant = true;
                        this.actuallyPrintPackage(shipment, status.printType, status.orderBy);
                    } else {
                        this.isLoading = false;
                    }
                }
            ).catch(
                () => {
                }
            );
        } else {
            this.actuallyPrintPackage(shipment);
        }
    }

    private actuallyPrintPackage(shipment, printType?: string, orderBy?) {
        const pkgId = shipment.id;
        this.shipmentsService.downloadMassPackagePdf(pkgId, printType, orderBy, null, this.isArchived).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.isLoading = false;
            });
    }

    public sortPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let pkgIds = [];
        if (shipment.customerId) {
            pkgIds.push(shipment.customerId);
        } else {
            if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.customerId);
            }
        }

        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        const params = {};
        this.shipmentsService.sortUserPackages(pkgIds, params, this.receiveWithoutReleasingCustody).subscribe(() => {
                this.translateService.get(
                    'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                            this.initCustomers();
                            this.initCustomersSumValues();
                        }
                    );
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.translateService.get(
                    'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'error', detail: data});
                            this.initCustomers();
                            this.initCustomersSumValues();
                        }
                    );
                this.isLoading = false;
            });

    }

    public onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }

        this.isThereSelection = false;
        this.customers.forEach((pkg: any) => {
            if (selected) {
                pkg.isSelected = selected;
                this.isThereSelection = true;

            } else {
                pkg.isSelected = selected;
            }
        });
    }

    public onToggleSelection() {
        const selectedPackages = this.getSelectedPackages();
        if (selectedPackages) {
            this.isThereSelection = selectedPackages.length !== 0;
            this.isAllSelected = selectedPackages.length === this.customers.length;
        }
        this.disableCreateMultipleInvoices();
    }

    private getSelectedPackages() {
        const selectedPackages = [];
        if (!this.customers) {
            return;
        }

        this.customers.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }

    public downloadMultiplePackages(all = false) {
        this.isLoading = true;
        if (this.exportedPackages || (this.deliver && !this.inCar)) {
            const modal = this.modalService.open(PrintPackageOptionsComponent, {
                backdrop: 'static',
                windowClass: 'verify-driver',
                size: 'lg'
            });
            modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93;
            modal.result.then(
                (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                    if (status.isSuccess) {
                        if (all) {
                            this.DownloadAllPackages(status.printType, status.orderBy);
                        } else {
                            this.actuallyDownloadPackages(status.printType, status.orderBy);
                        }
                    } else {
                        this.isLoading = false;
                    }
                }
            ).catch(
                () => {
                }
            );
        } else {
            if (all) {
                this.DownloadAllPackages();
            } else {
                this.actuallyDownloadPackages();
            }
        }
    }

    private actuallyDownloadPackages(printType?: string, orderBy?) {
        this.isLoading = true;
        this.translateService.get(
            'ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'
        ).subscribe(
            (message) => {
                this.messageService.add({severity: 'success', detail: message});
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleMassPackages(selectedPackages, printType, orderBy, this.isArchived).subscribe(
            () => {
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    private DownloadAllPackages(printType?: string, orderBy?) {
        this.isLoading = true;
        this.translateService.get(
            'ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'
        ).subscribe(
            (message) => {
                this.messageService.add({severity: 'success', detail: message, life: 10000});
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
        this.shipmentsService.downloadAllMassPackages(printType).subscribe(
            () => {
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public deliverMultiplePackages() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.componentInstance.isInvoices = this.isInvoices;
        const successMessage = this.isInvoices ? (selectedPackages.length > 1 ? 'ALERTS.INVOICES_PAID_SUCCESSFULLY' : 'ALERTS.INVOICE_PAID_SUCCESSFULLY') : 'ALERTS.MASS_PACKAGE_DELIVERED_SUCCESSFULLY';
         modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, transferFees: number }) => {
                if (data.paymentType) {
                    this.usersService
                        .deliverMassPackages(selectedPackages, data.paymentType, data.documentNumber, data.deliveryDate, data.transferFees)
                        .subscribe(() => {
                            this.translateService.get(successMessage)
                                .subscribe(
                                    (message) => {
                                        this.messageService.add({severity: 'success', detail: message});
                                        this.isLoading = false;
                                        this.initCustomers();
                                        this.initCustomersSumValues();
                                    }
                                );
                        }, (err) => {
                            console.log(err);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
                this.isAllSelected = false;
            }
        ).catch(
            () => {
            }
        );
    }

    public stopProp(event) {
        event.stopPropagation();
    }

    public setPaginatorQueryParam() {
        this.setQueryParams();

    }

    private setQueryParams() {
        const queryParams = {...this.params};
        if (this.totalGreaterThan !== null) {
            queryParams['threshold'] = this.totalGreaterThan;
        } else {
            delete queryParams['threshold'];
        }

        if (this.totalLessThan !== null) {
            queryParams['terminus'] = this.totalLessThan;
        } else {
            delete queryParams['terminus'];
        }
        if (this.searchContent) {
            queryParams['search'] = this.searchContent;
        } else {
            delete queryParams['search'];
        }

        if (this.selectedDateFilter) {
            queryParams['dateFilterType'] = this.selectedDateFilter;
        } else {
            delete queryParams['dateFilterType'];
        }

        if (this.fromDate) {
            queryParams['fromDate'] = this.fromDate;
        } else {
            delete queryParams['fromDate'];
        }
        if (this.toDate) {
            queryParams['toDate'] = this.toDate;
        } else {
            delete queryParams['toDate'];
        }
        if (this.checkNeedsReset()) {
            this.first = 0;
            this.pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
        }
        this.router.navigate([], {queryParams: queryParams}).then(() => {

        });
    }

    private checkNeedsReset() {
        const queryParams = {...this.params};
        let boolFromDate = false;
        let boolToDate = false;
        if (queryParams['fromDate'] !== undefined && this.fromDate) {
            boolFromDate = this.fromDate.getTime() !== new Date(queryParams['fromDate']).getTime();
        }
        if (queryParams['toDate'] !== undefined && this.toDate) {
            boolToDate = this.toDate.getTime() !== new Date(queryParams['toDate']).getTime();
        }
        return (queryParams['search'] !== undefined && this.searchContent !== queryParams['search']) ||
            boolFromDate || boolToDate;

    }

    public printDriverMassReports() {
        this.isLoading = true;
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.componentInstance.showDates = false;
        modal.componentInstance.showSortBy = false;
        modal.componentInstance.showConfig = false;
        modal.componentInstance.isExportInCarPkgs = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number }) => {
                if (status.isSuccess) {
                    this.usersService.printDriverMassReports(status.driverId).subscribe((res: any) => {
                            window.open(res.url, '_blank');
                            this.isLoading = false;
                        },
                        error => {
                            console.log(error);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public changeStatusToExported(shipment) {
        this.isLoading = true;
        let pkgIds = [];
        if (shipment.id) {
            pkgIds.push(shipment.id);
        } else {
            if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
            }
        }

        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        this.isThereSelection = false;
        this.usersService.changeStatusToExported(pkgIds).subscribe(() => {
            this.translateService.get(
                'ALERTS.CHANGED_STATUS'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.isLoading = false;
                        this.initCustomers();
                        this.initCustomersSumValues();
                    }
                );
        }, () => {
            this.isLoading = false;
        });
    }

    public initCustomersSumValues() {
        const params = {};
        let getSumValues;
        if (this.isArchived) {
            params['isArchived'] = true;
        }
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            params['dateFilterType'] = this.selectedDateFilter;
        } else {
            delete params['dateFilterType'];
        }

        if (!!this.customerType && this.customerType != 'ALL') {
            params['customerType'] = this.customerType;
        }

        if (this.customerId) {
            params['customerId'] = this.customerId;
        } else {
            delete params['customerId'];
        }

        if (this.driverId) {
            params['driverId'] = this.driverId;
        } else {
            delete params['driverId'];
        }

        if (this.searchContent) {
            params['search'] = this.searchContent;
        } else {
            delete params['search'];
        }

        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        } else {
            delete params['fromDate'];
        }

        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        } else {
            delete params['toDate'];
        }

        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else {
            delete params['timezone'];
        }

        if (this.totalGreaterThan !== null) {
            params['threshold'] = this.totalGreaterThan;
        }
        if (this.totalLessThan !== null) {
            params['terminus'] = this.totalLessThan;
        }
        if (this.paymentMethod && this.paymentMethod.value !== 'ALL') {
            params['paymentMethod'] = this.paymentMethod.value;
        }
        if (this.receive) {
            if (this.receiveWithoutReleasingCustody) {
                return;
            }
            this.spinnerState = SpinnerState.LOADING;
            getSumValues = this.usersService.getReciveCustomerSumValues(params);
        } else {
            if (this.sort && this.canExport) {
                params['status'] = 'SORTED_BY_ACCOUNTANT';
            } else if (this.exportedPackages) {
                if (this.isInvoices) {
                    params['status'] = 'INVOICE';
                } else if (this.isCodPendingCustomersApproval) {
                    params['status'] = 'PENDING_CUSTOMER_RECEIVING_CONFIRMATION';
                } else {
                    params['status'] = 'EXPORTED_BY_ACCOUNTANT';
                }
            } else if (this.deliver && !this.inCar) {
                params['status'] = 'DELIVERED';
            } else if (this.inCar) {
                params['status'] = 'WITH_DRIVER';
            } else {
                return;
            }
            this.spinnerState = SpinnerState.LOADING;
            getSumValues = this.usersService.getCostumersSumValues(params);
        }
        getSumValues.subscribe(
            (response: any) => {
                this.totalPackages = response.totalPackagesNumber;
                this.totalCod = response.totalCod;
                this.totalCodWithoutCost = response.totalCodWithoutCost;
                this.totalCostSum = response.totalCost;
                if (this.isSupportReceiveWithoutReleasingCustody && (this.sort || this.deliver)) {
                    this.totalCostWithoutReceiveCustody = response.codSumWithoutReleasingCustody;
                }
                if ((response.totalPercentageFee !== undefined && response.totalPercentageFee &&
                    (this.authenticationService.companyId === 37 || this.authenticationService.companyId === 1) &&
                    this.sort || this.receive || this.deliver || this.exportedPackages)) {
                    this.totalPercentageFee = response.totalPercentageFee;
                }
                this.spinnerState = SpinnerState.LOADED;
            }, (error) => {
                this.spinnerState = SpinnerState.LOADED;
                console.error(error);
            }
        );

    }

    public pickWithDriver() {
        this.isLoading = true;
        let pkgIds = [];
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.componentInstance.showDates = false;
        modal.componentInstance.showSortBy = false;
        modal.componentInstance.showConfig = false;
        modal.componentInstance.isPickWithDriver = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number }) => {
                this.isLoading = false;
                if (status.isSuccess) {
                    if (this.getSelectedPackages().length > 0) {
                        pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
                    }
                    this.usersService.pickWithDriver(status.driverId, pkgIds).subscribe(() => {
                            this.initCustomers();
                            this.initCustomersSumValues();
                            this.isLoading = false;
                        }, () => {
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public exportToExcel(shipment, event, receive = false) {
        event.stopPropagation();
        this.isLoading = true;
        if (receive) {
            this.shipmentsService.exportToExcelForReceivedCod(shipment.customerId, this.createParams()).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.log(error);
            });
        } else {
            const pkgId = shipment.id;
            const params = {};
            if(this.isArchived) {
                params['isArchived'] = this.isArchived;
            }
            this.shipmentsService.exportToExcel(pkgId, params).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.log(error);
            });
        }
    }

    disableCreateMultipleInvoices() {
        const selectedPackages = this.getSelectedPackages() ? this.getSelectedPackages() : [];
        let enabledCustomersBilling = false;
        if (selectedPackages.length) {
            let customersWithOutBilling = [];
             customersWithOutBilling = this.getSelectedPackages().filter(customer => customer.isCustomerBilled === false);
            enabledCustomersBilling = customersWithOutBilling.length ? false : true;
        }
        return !enabledCustomersBilling || !this.isThereSelection || this.selectedDateFilter !== 'NONE';
    }

    public createInvoiceConfirmation(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let title, confirmationMessage;
        if (shipment) {
            title = 'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICE';
            confirmationMessage = 'MANAGE_COD_CUSTOMERS.INVOICES.ALARMS.CREATE_INVOICE_CONFIRMATION';
        } else {
            title = 'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES';
            confirmationMessage = 'MANAGE_COD_CUSTOMERS.INVOICES.ALARMS.CREATE_MULTIPLE_INVOICE_CONFIRMATION';
        }

        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', backdropClass: 'custom-confirmation-message-backdrop', size: 'sm'});

        modal.componentInstance.title = title;
        modal.componentInstance.confirmMessage = confirmationMessage;
        modal.componentInstance.confirmButtonLabel = 'ACTIONS.CREATE';
        modal.componentInstance.styleClass = 'logestechs-theme';
        if (shipment) {
            modal.componentInstance.switchButtonLabel = 'ACTIONS.PRINT';
        }
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    // create invoice api;
                    this.createInvoice(shipment, event, response.switchButtonValue);

                } else { this.isLoading =  false; }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    createInvoice(shipment, event, isPrint = false) {
        const ids = shipment ? [shipment.id] : this.getSelectedPackages().map(pkg => pkg.id);
        const body = {ids: ids};

        this.usersService.createInvoice(body).subscribe(
            (response: any) => {
                this.initCustomers();
                this.isAllSelected = false;
                this.isLoading  = false;
                if (isPrint) {
                    this.printPackage(shipment, event);
                }
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    showCreateInvoice() {
        return this.userService.userInfo.isInvoicingEnabled;
    }

    onCustodyTypeChange(val) {
        this.customers = [];
        this.receiveWithoutReleasingCustody = true;
        this.optionCustody = (val === null ? this.custodyTypeList[0] : val.value);
        // this.location.replace(this.authenticationService.companyName + 'home/receive-cod' +
        //     '?receive_without_releasing_custody=true&type=' + this.optionCustody.value);
        this.searchContent = '';
        this.totalGreaterThan = null;
        this.totalLessThan = null;
        this.params = '';
        this.customerId = undefined;
        this.driverId = undefined;
        this.selectedFilter = '';
        this.selectedEntity = '';
        this.initCustomers();
    }

    public onFilterSelectedChange(id) {
        this.customerId = (id === undefined ? '' : id);
        this.initCustomers();
        this.initCustomersSumValues();
    }
    public onSelectedDriverChange(id) {
        this.driverId = (id === undefined ? '' : id);
        this.initCustomers();
        this.initCustomersSumValues();
    }

    public findPackageByBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent,
            <any>{size: 'md', windowClass: 'enter-barcode-popover', keyboard: true});
        modal.componentInstance.onlyReturnBarcode = true;
        modal.result.then(
            (data: { isSuccess: boolean, id: number }) => {
                if (data && data.isSuccess) {
                    this.showPackageHistory(data.id);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public showPackageHistory(id = 0) {
        this.router.navigate([this.authService.companyName + 'home/receive-cod/package-history/', id]);
    }

    openExcel() {
        this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
                accept: () => {
                    this.isLoading = true;
                    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
                    const params = this.createParams();
                    params['ids'] = selectedPackages;
                    if(this.isArchived) {
                        params['isArchived'] = this.isArchived;
                    }
                    let exportedPackages = this.shipmentsService.downloadExcelForSortCod(params);
                    if (this.exportedPackages || (this.deliver && !this.inCar)) {
                        exportedPackages = this.shipmentsService.downloadExcelForExportedCod({ids: selectedPackages}, this.isArchived);
                    }
                    exportedPackages.subscribe((response: any) => {
                        window.open(response.url, '_blank');
                        this.isLoading = false;
                    });
                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }


    exportReceivedCodToExcel() {
        this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
                accept: () => {
                    this.isLoading = true;
                    this.shipmentsService.downloadExcelForReceivedCod(this.createParams()).subscribe((response: any) => {
                        window.open(response.url, '_blank');
                        this.isLoading = false;
                    });
                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });

    }

    onPaymentSelected() {
        this.initCustomers();
        this.initCustomersSumValues();
    }

    public isSignatureExist(deliveryProofUrlList) {
        return deliveryProofUrlList && deliveryProofUrlList.length > 0;
    }
    public openSignatureImages($event, deliveryProofUrlList) {
        this.stopProp($event);
        const modal = this.modalService.open(ImageSliderComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.isModal = true;
        modal.componentInstance.images = deliveryProofUrlList;
    }

    printList() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleMassReports(selectedPackages, this.isArchived).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.onSearch();
    }

    triggerMobileActions(event, dropdown) {
        dropdown.toggle(event);
    }

    getMobileKeys() {
        const obj = {};

        if (this.sort || this.deliver) {
            obj['barcode'] =  {label: 'MANAGE_SHIPMENTS.BARCODE', icon: '../../../assets/icons/package_card/box.svg'};
        }
        if (this.isCOD && !this.receiveWithoutReleasingCustody) {
            obj['name'] = 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME';
        }
        if (!this.isCOD || this.receiveWithoutReleasingCustody) {
            const customerLabel =  this.receiveWithoutReleasingCustody ? ('MANAGE_COD_CUSTOMERS.' +  this.optionCustody.value + '_NAME') : 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME';
            if (this.receiveWithoutReleasingCustody) {
                obj['name'] = {label: customerLabel, icon: '../../../assets/icons/package_card/user.svg'};
            } else {
                obj['customerName'] = {label: customerLabel, icon: '../../../assets/icons/package_card/user.svg'};
            }
        }
        if (this.isCOD && !this.receiveWithoutReleasingCustody) {
            obj['name'] = {label: 'MANAGE_COD_CUSTOMERS.BUSINESS_NAME', icon: '../../../assets/icons/package_card/user.svg'};
        }
        if (!this.isCOD || (this.receiveWithoutReleasingCustody && this.optionCustody.value === 'CUSTOMER')) {
            obj['city'] = {label: 'ACCOUNTING.CUSTOMER_ADDRESS', icon: '../../../assets/icons/package_card/location.svg'};
        }
        if (!this.receiveWithoutReleasingCustody && this.isCOD && (this.status === 'COD_AT_HAND' || !this.status)) {
            obj['codAtHand'] = {label: 'MANAGE_COD_CUSTOMERS.COD_AT_HAND', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (!this.receiveWithoutReleasingCustody && this.isCOD && (this.status === 'COD_WITH_CUSTOMER' || !this.status)) {
            obj['codWithCustomer'] = {label: 'MANAGE_COD_CUSTOMERS.COD_WITH_CUSTOMER', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (!this.receiveWithoutReleasingCustody && this.isCOD && (this.status === 'FUTURE_COD' || !this.status)) {
            obj['futureCod'] = {label: 'MANAGE_COD_CUSTOMERS.COD_FUTURE', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.receive || this.custody || this.sort || this.receiveWithoutReleasingCustody) {
            obj['totalCodWithoutCost'] = {label: 'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.receive || this.custody || this.receiveWithoutReleasingCustody) {
            obj['costSum'] = {label: 'MANAGE_COD_CUSTOMERS.COST_SUM', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.sort) {
            obj['totalCost'] = {label: 'MANAGE_COD_CUSTOMERS.COST_SUM', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (!this.receiveWithoutReleasingCustody && this.isCOD && !status) {
            obj['codReadyToDeliver'] = {label: 'MANAGE_COD_CUSTOMERS.READY_COD_TO_DELIVER', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.receive || this.custody || this.receiveWithoutReleasingCustody) {
            obj['totalReceivedCod'] = {
                // tslint:disable-next-line:max-line-length
                label: 'ACCOUNTING.COD_SUM',
                icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.sort || this.deliver) {
            obj['totalCod'] = {label: 'MANAGE_COD_CUSTOMERS.COD_SUM', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.receive || this.custody || this.receiveWithoutReleasingCustody) {
            obj['receivedCodPackagesNumber'] = {label: this.receiveWithoutReleasingCustody ? 'ACCOUNTING.RECEIVED_COD_PACKAGES_CUSTODY' : 'ACCOUNTING.RECEIVED_COD_PACKAGES',
                icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.sort || this.deliver) {
            obj ['codPackagesNumber'] = {label: this.deliver ? 'ACCOUNTING.DELIVERED_COD_PACKAGES' : 'ACCOUNTING.SORTED_COD_PACKAGES', icon: '../../../assets/icons/package_card/invoice.svg'};
        }
        if (this.deliver) {
            obj['userName'] = {label: 'ACCOUNTING.DELIVERED_BY', icon: '../../../assets/icons/package_card/user.svg'};
            obj['deliveryDate'] = {label: 'ADD_SHIPMENT.DELIVERY_DATE', icon: '../../../assets/icons/package_card/calendar.svg'};
        }
        return obj;
    }
    onPaginate(page) {
        this.setPaginatorQueryParam();
        this.pageNumber = page;
        this.initCustomers();
    }

    getRowFunctions() {
        const list = [];
        if (this.canExport
            && (this.permissions === 'ACCOUNTANT'
                || this.permissions === 'ADMIN'
                || this.permissions === 'SUPER_ADMIN'
                || this.permissions === 'ACCOUNTING_MANAGER'
                || this.permissions === 'ACCOUNTING_MANAGER')) {
                list.push({label: 'export', icon: '', func: this.exportPackage});
        }
        if (this.sort && !this.canExport) {
            list.push({label: 'deliverPackage', icon: '', func: this.deliverPackage});
        }
        if (this.deliver && !this.inCar) {
            list.push({label: 'openSignatureImages', icon: '', func: this.openSignatureImages, param: 'deliveryProofUrlList'});
        }
        if ((this.sort && !this.canExport) || this.deliver) {
            list.push({label: 'printPackage', icon: '', func: this.printPackage});
        }

        if ((this.receiveWithoutReleasingCustody && this.optionCustody.value === 'CUSTOMER')
            || !this.receiveWithoutReleasingCustody && this.receive && this.excludeSort
            && this.permissions !== 'OPERATION_MANAGER') {
            list.push({label: 'sortPackage', icon: '', func: this.sortPackage});
        }
        if (this.exportedPackages || this.deliver || this.receive) {
            list.push({label: 'exportToExcel', icon: '', func: this.exportToExcel});
        }
        if (!this.isInvoices && (this.exportedPackages) && this.showCreateInvoice()) {
            list.push({label: 'createInvoiceConfirmation', icon: '', func: this.createInvoiceConfirmation});
        }
        return list;
    }
    sortOnReceivingDate() {
        if (this.sortByType === 'DELIVERY_DATE_DESC') {
            this.sortByType = 'DELIVERY_DATE_ASC';
        } else {
            this.sortByType = 'DELIVERY_DATE_DESC';
        }
        this.onSearch();
    }

    exportCodToExcel() {
        this.isLoading = true;
        this.usersService.exportCodToExcel(this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

    private handleVisibleComponent() {
        this.showCodTotals = (this.showCodTotalsForAdminOnly.indexOf(this.companyId) < 0 || this.userService.isLoggedInAdmin());
    }

    exportCustomersCodToExcel() {
        this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
                accept: () => {
                    this.isLoading = true;

                    this.shipmentsService.exportCustomersCod(this.createParams()).subscribe((response: any) => {
                        window.open(response.url, '_blank');
                        this.isLoading = false;
                    }, error => {
                        console.error(error);
                        this.isLoading = false;
                    });
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }
    printSelectedPkgs(type, callBack?, singlePackage?) {
        const selectedPkgs = this.getSelectedPackages();
        const previouslyPrintedPkgs: any = selectedPkgs.filter(pkg => pkg.isPrintedByAccountant);
        if (previouslyPrintedPkgs && previouslyPrintedPkgs.length || type === 'single' && singlePackage && singlePackage.isPrintedByAccountant) {
            this.confirmationService.confirm({
                message: this.translateService.instant(selectedPkgs.length > 1 && type !== 'single' ? 'ACCOUNTING.PREVIOUSLY_PRINTED_PACKAGES_REPORTS' :
                    'ACCOUNTING.SINGLE_PACKAGE_PREVIOUSLY_PRINTED_REPORTS'),
                accept: () => {
                    if (callBack) {
                        callBack.apply();
                    } else {
                        this.printPkgReport(type);
                    }
                },
                acceptLabel: this.translateService.instant('GENERAL.YES'),
                rejectLabel: this.translateService.instant('GENERAL.NO'),
            });
        } else {
            if (callBack) {
                callBack.apply();
            } else {
                this.printPkgReport(type);
            }
        }
    }
    printAsEnvelopPkg(event) {
        const pkgId = event.id;
        this.shipmentsService.printAsEnvelope(pkgId, this.isArchived).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        },
        error => {
            console.log(error);
            this.isLoading = false;
        });
    }

    printPkgReport(type) {
        switch (type) {
            case 'list':
                this.printList();
                break;
            case 'multiple':
                this.downloadMultiplePackages();
                break;
        }
    }
    showHistory($event, record) {
        $event.stopPropagation();
        const model = this.modalService.open(MassCodReportHistoryComponent, {
            backdrop: 'static'
        });
        model.componentInstance.report = record;
    }
}
