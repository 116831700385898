<div class="import-customers-dialog d-flex flex-column">

    <div class="d-flex">

        <div class="d-block">
            <div *ngIf="totalSubmitted > 0" class="alert alert-success ml-2 mr-2" role="alert">
                {{ ('ALERTS.EXCEL_USERS_IMPORTED_SUCCESSFULLY' | translate) + ' ' + totalSubmitted }}
            </div>

            <div *ngIf="totalFailed > 0" class="alert alert-danger ml-2 mr-2" role="alert">
                {{ ('ALERTS.EXCEL_USERS_IMPORTED_ERRORS' | translate) + ' ' + totalFailed }}
            </div>
        </div>
    </div>

    <div class="table-container">
        <p-table [value]="customersArray.controls" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 30px!important;">#</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER.CUSTOMER_NAME' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER.EMAIL' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER.PHONE' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.BUSINESS_NAME' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER.VILLAGE' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER.ADDRESS_LINE1' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER.PASSWORD' | translate}}</th>
                    <th>{{'ADD_SHIPMENT.SERVICE_TYPE' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.HUB' | translate}}</th>
                    <th>{{'CUSTOMER_TYPES.CUSTOMER_TYPE' | translate}}</th>
                    <th>{{'ACCOUNTING.CUSTOMER_PAYMENT_TYPE' | translate}}</th>
                    <th>{{'PRICING.PRICING_LISTS' | translate}}</th>
                    <th>{{'USERS_MANAGEMENT.ADD_USER_FORM.OPTIONAL_NUMBER' | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr [ngClass]="{'success-record':customer.get('customerDetails').get('submitted').value,
                 'failed-record':customer.get('customerDetails').get('failed').value}">
                    <td [formGroup]="customer">
                        <div class="d-flex">
                            <p-checkbox (onChange)="changeCustomersEnable(customer)" formControlName="enabled"
                                        binary="true"></p-checkbox>
                            <div *ngIf="customer.get('customerDetails').get('submitting').value">
                                <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                            </div>
                        </div>
                    </td>

                    <ng-container [formGroup]="customer.get('customerDetails')">
                        <td>
                            <input type="text" class="form-control" formControlName="name">
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="email"
                                   (keyup)="validateUserNameAndEmail(customer.get('customerDetails').get('email'))">
                        </td>
                        <td [formGroup]="customer.get('customerDetails')">
                            <app-phone-number-input [formGroupPass]="customer.get('customerDetails')"
                                                    [formControlNamePass]="'phone'"
                                                    [formControlPass]="customer.get('customerDetails').get('phone')"
                                                    cssClassPass="import-customers-from-excel add-package"></app-phone-number-input>
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="businessName">
                        </td>
                        <td [formGroup]="customer.get('customerDetails')">
                            <ng-select class="village-select excel-dialog-ng-select" appDebounceSelectQuery
                                       notFoundText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.ADDRESS_SEARCH_PLACEHOLDER' | translate | addressSystem }}"
                                       loadingText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.ADDRESS_LOADING_PLACEHOLDER' | translate }}"
                                       formControlName="village" [items]="villageOptions"
                                       [loading]="customer.get('customerDetails').get('villageLoading').value"
                                       appendTo="body" bindLabel="name" (close)="clearVillageOptions()"
                                       (open)="getVillageOptions(customer)"
                                       [formLoading]="customer.get('customerDetails').get('villageLoading')"
                                       [debounceTime]="1000" [observer]="villageSearchObserver">
                                <ng-option
                                        [value]="item"
                                        *ngFor="let item of villageOptions">
                                    {{ item.name }}
                                </ng-option>
                            </ng-select>
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="addressLine1">
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="password">
                        </td>

                        <td>
                            <ng-select [items]="shipmentServicesOptions"
                                       [typeahead]="serviceTypesSubject"
                                       (open)="!shipmentServicesOptions.length && isDynamicServiceTypes ? getDynamicServiceTypes() : null"
                                       (reset)="getDynamicServiceTypes()"
                                       [loading]="serviceTypesLoader"
                                       typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                       loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                       notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate}}"
                                       bindValue="value"
                                       formControlName="serviceTypes"
                                       [multiple]="true"
                                       [closeOnSelect]="false"
                                       [dropdownPosition]="'bottom'"
                                       [required]="true"
                                       appendTo="body"
                            >
                            </ng-select>
                        </td>

                        <td>
                            <app-input-field-component [isRequired]="true" controlName="hubId"
                                                       [form]="customer.get('customerDetails')" controlType="select"
                                                       [options]="hubsOptions"></app-input-field-component >
                        </td>

                        <td>
                            <app-input-field-component  [form]="customer.get('customerDetails')"
                                                       controlName="type"
                                                       controlType="select"
                                                       [options]="customerTypes">
                            </app-input-field-component >
                        </td>

                        <td>
                            <app-input-field-component  [form]="customer.get('customerDetails')"
                                                       controlName="paymentMethod"
                                                       controlType="select"
                                                       [options]="paymentTypeOptions">
                            </app-input-field-component>
                        </td>


                        <td>
                            <app-input-field-component [form]="customer.get('customerDetails')"
                                                       controlName="pricingListId" field="label"
                                                       [dataKey]="'value'"
                                                       [controlType]="'autoComplete'"
                                                       appendTo="body"
                                                       [searchSuggestions]="pricingListType"
                                                       (onSearch)="getPricingLists($event)"
                                                       [forceSelection]="false"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="initPricingLists({})">
                            </app-input-field-component >
                        </td>
                        <td>
                            <input pInputText formControlName="optionalNumber"/>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
            <p-progressSpinner>
            </p-progressSpinner>
        </div>
    </div>
    <p-footer>
        <div class="d-flex dialog-footer p-3">
            <button [disabled]="isLoading" [ngClass]="{'disabled-btn' : isLoading}"
                    (click)="hide()">
                <i class="fas fa-times ml-1 mr-1"></i>{{ 'ACTIONS.CANCEL' | translate }}
            </button>
            <button [disabled]="(customersArray.invalid || customersArray.controls.length === 0) || totalEnabledCustomers === 0 || isLoading"
                    [ngClass]="(customersArray.invalid || customersArray.controls.length === 0) || totalEnabledCustomers === 0 || isLoading ? 'disabled-btn' : ''"
                    (click)="submitForm()"><i class="fas fa-save ml-1 mr-1 "></i>{{ 'ACTIONS.SUBMIT' | translate }}
            </button>
        </div>
    </p-footer>
</div>
