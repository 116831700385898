import {Component, Inject, OnInit} from '@angular/core';
import {ProductModel} from '../../shared/models/product.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {ShipmentsService} from '../services/shipments.service';
import {DatePipe} from '@angular/common';
import {GeographyService} from '../../shared/services/geography.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChooseExistingComponent} from '../choose-existing/choose-existing.component';
import {UserModel} from '../../shared/models/user.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PackageModel} from '../../shared/models/package.model';
import {UserService} from '../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {Address} from '../../shared/models/address.class';
import {ZonesService} from '../../administration/services/zones.service';
import {ParcelService} from '../../administration/services/parcel.service';

import * as _ from 'lodash';
import {ADMINISTRATION_CONSTANTS} from '../../administration/administration-constants';
import {HubsService} from '../../shared/services/hubs.service';
import {UtilitiesService} from '../../shared/services/utilities.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Component({
    selector: 'app-add-shipment-component',
    templateUrl: 'add-shipment.component.html',
    styleUrls: ['add-shipment.component.scss']
})
export class AddShipmentComponent extends Address implements OnInit {

    public products: ProductModel[] = [
        {
            name: '',
            quantity: 1,
            weight: 1,
            eachCost: 10,
            totalCost: 10
        }
    ];

    /**
     * Fields which can be edited.
     * some fields are forbidden to be edited by some user roles.
     */
    public editedFields = 'all';

    public shipmentServicesOptions: { value: string, label: string }[];
    public shipmentTypesOptions: { value: string, label: string }[];
    public hubsOptions: { value: string, label: string }[];

    public form: FormGroup;
    public isEditMode = false;
    public edittedPkg: PackageModel;
    public isLoading = false;
    public deliveryDateNote = '';
    public isSubmitting = false;
    public extraOptions = [];
    public parcelOptions = [];
    public parcelList = [];
    private pkgId = 0;
    public isDisabled = false;
    public isArabic = false;
    public permissions = '';
    public showDetailsSender = false;
    public showDetailsReceiver = false;
    public companyId = 0;
    public userHubId = 0;
    public userHub = null;
    public customerId;
    public swapOrBring = 'COLLECT';
    constructor(private formBuilder: FormBuilder,
                private shipmentService: ShipmentsService,
                private datePipe: DatePipe,
                private geographyService: GeographyService,
                private modalService: NgbModal,
                public translateService: TranslateService,
                private auth: AuthenticationService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private route: ActivatedRoute,
                private zonesService: ZonesService,
                private hubsService: HubsService,
                private utilitiesService: UtilitiesService,
                private parcelService: ParcelService,
                private activeModal: NgbActiveModal,
                private rolesService: RolesService,
                private athuenticationService: AuthenticationService) {
        super(zonesService, translateService, true);
        this.allVillages = true;
    }

    ngOnInit() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.isArabic = this.translateService.currentLang === 'ar';
        this.companyId = this.auth.companyId;
        this.userHubId = this.userService.userInfo.hubId;
        this.route.queryParams.subscribe(
            (params: Params) => {
                if (this.pkgId) {
                    this.isDisabled = true;
                } else {
                    const pkgId = params.pkgId;
                    this.pkgId = pkgId;
                }
                if (this.pkgId) {
                    this.isEditMode = true;
                    this.fetchEditedPkg(this.pkgId);
                    if (params.edit) {
                        this.editedFields = params.edit;
                    }
                } else {
                    this.initOptions();
                }
            }
        );

        this.translateService.onLangChange.subscribe((event) => {
            this.parseParcels();
        });
    }

    private fetchEditedPkg(pkgId) {
        this.shipmentService.getPackageById(pkgId).subscribe(
            (response: any) => {
                this.edittedPkg = response;
                this.initOptions();
            }
        );
    }

    private initOptions() {
        this.disableRest = true;
        Promise.all([this.initRegionsPromise, this.initShipmentServices(), this.initShipmentTypes(),
            this.initMetrics(), this.initHubs(), this.initParcels()]).then(() => {
            this.isLoading = false;
            this.initForm();
            this.initRegions();
            this.disableRest = false;
            if (this.isDisabled) {
                this.disableForm();
            }
        });
    }

    /**
     *
     */
    private initShipmentTypes() {
        this.isLoading = true;
        return this.shipmentService.getShipmentTypes().toPromise().then(
            (types: string[]) => {
                this.shipmentTypesOptions = types.map(
                    (type) => {
                        let typeLabel = type;
                        this.translateService.get(
                            type
                        )
                            .subscribe(
                                (data) => {
                                    typeLabel = data;
                                }
                            );
                        return {value: type, label: typeLabel};
                    }
                );
            }
        );
    }
    public allowAddingPackageWithFingerprint() {
        return this.userService.userInfo.isPackageFingerprintRequired;
    }

    public initHubs() {
        this.isLoading = true;
        return this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        if (hub.id === this.userHubId) {
                            this.userHub = {label: hub.name, value: hub.id};
                        }
                        return {label: hub.name, value: hub.id};
                    }
                );
            }
        );
    }

    /**
     *
     */
    private initShipmentServices() {
        this.isLoading = true;
        return this.shipmentService.getShipmentServices().toPromise().then(
            (response: string[]) => {
                this.shipmentServicesOptions = response.map(
                    (service) => {
                        let serviceLabel = service;
                        this.translateService.get(
                            service
                        )
                            .subscribe(
                                (data) => {
                                    serviceLabel = data;
                                }
                            );
                        return {value: service, label: serviceLabel};
                    }
                );
            }, (error) => {
            }
        );
    }

    private initParcels() {
        this.parcelService.getParcels().subscribe((response: any) => {
            this.parcelList = response.data || [];
            this.parseParcels();
        });
    }

    parseParcels() {
        this.parcelOptions = this.parcelList.map((parcel) => {
            return {
                label: this.translateService.currentLang === 'ar' ? parcel.arabicType : parcel.type,
                value: parcel.id
            };
        });
    }

    setParcel(parcelId) {
        parcelId = parseInt(parcelId);
        const parcel = this.parcelList.find(({id}) => id === parcelId);
        if (parcel) {
            const {weight, height, length, width} = parcel;
            this.form.patchValue({weight, height, length, width});
            this.form.controls['weight'].disable();
            this.form.controls['height'].disable();
            this.form.controls['length'].disable();
            this.form.controls['width'].disable();
        } else {
            if (!this.isDisabled) {
                this.form.controls['weight'].enable();
                this.form.controls['height'].enable();
                this.form.controls['length'].enable();
                this.form.controls['width'].enable();
                this.initMetrics();
            }
        }
    }

    private initMetrics() {
        if (this.form && this.form.controls.originRegion && this.form.controls.originRegion.value != null &&
            this.form.controls.originRegion.value.value != null && this.form.controls.pkgUnitType &&
            typeof this.form.controls.pkgUnitType.value === 'string') {
            this.isLoading = true;
            const query = this.createMetricsParams();
            return this.shipmentService.getShipmentDefaultMetrics(query).toPromise().then(
                (response: any) => {
                    this.initFormValue('weight', response.weight);
                    this.initFormValue('height', response.height);
                    this.initFormValue('length', response.length);
                    this.initFormValue('width', response.width);
                }, (error) => {
                }
            );
        } else {
            return new Promise((resolve) => {
                resolve(null);
            });
        }

    }

    public showCreatedDate() {
        return this.companyId === 64 && !this.isEditMode;
    }

    public createMetricsParams() {
        const params = {
            'regionId': this.form.controls.originRegion.value.value,
            'unit-type': this.form.controls.pkgUnitType.value
        };
        return params;
    }

    private initFormValue(controlName, newValue) {
        this.form.controls[controlName].setValue(newValue);
        this.form.controls[controlName].updateValueAndValidity();
    }

    private initForm() {
        let info = {
            senderName: '',
            senderFirstName: '',
            senderLastName: '',
            senderMiddleName: '',
            businessSenderName: '',
            businessReceiverName: '',
            senderEmail: '',
            senderPhone: '',
            senderPhone2: '',
            senderAuthorizedGovRegistrationNumber: '',
            receiverAuthorizedGovRegistrationNumber: '',
            addressFromIsHub: true,
            originAddressLine1: '',
            originRegion: null,
            originVillage: '',
            originCombined: '',
            originAddressLine2: '',
            receiverName: '',
            receiverFirstName: '',
            receiverLastName: '',
            receiverMiddleName: '',
            receiverEmail: '',
            receiverPhone: '',
            receiverPhone2: '',
            swapOrBring: this.swapOrBring,
            destinationAddressLine1: '',
            destinationVillage: '',
            destinationRegion: null,
            destinationAddressLine2: '',
            originCity: '',
            destinationCity: '',
            destinationCombined: '',
            originCountry: this.countriesOptions[0].value,
            destinationCountry: this.countriesOptions[0].value,
            freight: null,
            type: this.shipmentTypesOptions[1].value,
            service: this.shipmentServicesOptions[0].value,
            cod: null,
            toCollectFromReceiver: null,
            toPayToReceiver: null,
            weight: 1,
            width: 1,
            length: 1,
            height: 1,
            pkgUnitType: 'METRIC',
            notes: '',
            description: '',
            adminNotes: '',
            invoice: '',
            supplierInvoice: '',
            deliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
            createdDate: this.showCreatedDate() ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : null,
            extraOptions: [],
            hubId: this.userHubId,
            quantity: 1
        };


        if (this.isEditMode) {
            this.disableRest = true;
            const pkgExtraOptions = [];
            ['isInsurance', 'isNeedPacking', 'isAnimalOrPits', 'isDangerousOrChemical', 'isflammable', 'isBreakable']
                .forEach(
                    (option) => {
                        if (this.edittedPkg[option]) {
                            option = option.charAt(0).toLowerCase() + option.substring(3);
                            pkgExtraOptions.push(option);
                        }
                    }
                );

            info = <any>{
                senderName: this.edittedPkg.senderName,
                senderFirstName: this.edittedPkg.senderFirstName,
                senderMiddleName: this.edittedPkg.senderMiddleName,
                businessSenderName: this.edittedPkg.businessSenderName,
                businessReceiverName: this.edittedPkg.businessReceiverName,
                senderLastName: this.edittedPkg.senderLastName,
                receiverAuthorizedGovRegistrationNumber: this.edittedPkg.receiverAuthorizedGovRegistrationNumber,
                senderAuthorizedGovRegistrationNumber: this.edittedPkg.senderAuthorizedGovRegistrationNumber,
                senderEmail: this.edittedPkg.senderEmail,
                senderPhone2: this.edittedPkg.senderPhone2,
                senderPhone: this.edittedPkg.senderPhone,
                addressFromIsHub: true,
                swapOrBring: this.swapOrBring,
                originAddressLine1: this.edittedPkg.originAddress.addressLine1,
                originAddressLine2: this.edittedPkg.originAddress.addressLine2,
                receiverName: this.edittedPkg.receiverName,
                receiverFirstName: this.edittedPkg.receiverFirstName,
                receiverLastName: this.edittedPkg.receiverLastName,
                receiverMiddleName: this.edittedPkg.receiverMiddleName,
                receiverEmail: this.edittedPkg.receiverEmail,
                receiverPhone: this.edittedPkg.receiverPhone,
                receiverPhone2: this.edittedPkg.receiverPhone2,
                destinationAddressLine1: this.edittedPkg.destinationAddress.addressLine1,
                destinationAddressLine2: this.edittedPkg.destinationAddress.addressLine2,
                originRegion: {
                    value: this.edittedPkg.originAddress.regionId,
                    label: this.edittedPkg.originAddress.region
                },
                originCity: {
                    value: this.edittedPkg.originAddress.cityId,
                    label: this.edittedPkg.originAddress.city
                },
                originVillage: {
                    value: this.edittedPkg.originAddress.villageId,
                    label: this.edittedPkg.originAddress.village
                },
                originCombined: {
                    value: this.edittedPkg.originAddress.villageId,
                    label: this.edittedPkg.originAddress.village + '-' + this.edittedPkg.originAddress.city + '-' +
                        this.edittedPkg.originAddress.region
                },
                destinationRegion: {
                    value: this.edittedPkg.destinationAddress.regionId,
                    label: this.edittedPkg.destinationAddress.region
                },
                destinationCity: {
                    value: this.edittedPkg.destinationAddress.cityId,
                    label: this.edittedPkg.destinationAddress.city
                },
                destinationVillage: {
                    value: this.edittedPkg.destinationAddress.villageId,
                    label: this.edittedPkg.destinationAddress.village
                },
                destinationCombined: {
                    value: this.edittedPkg.destinationAddress.villageId,
                    label: this.edittedPkg.destinationAddress.village + '-' + this.edittedPkg.destinationAddress.city + '-' +
                        this.edittedPkg.destinationAddress.region
                },
                originCountry: this.edittedPkg.originAddress.country,
                destinationCountry: this.edittedPkg.destinationAddress.country,
                freight: this.edittedPkg.cost,
                type: this.edittedPkg.shipmentType,
                service: this.edittedPkg.serviceType,
                cod: this.edittedPkg.cod,
                toCollectFromReceiver: this.edittedPkg.toCollectFromReceiver,
                toPayToReceiver: this.edittedPkg.toPayToReceiver,
                weight: this.edittedPkg.weight,
                width: this.edittedPkg.width,
                length: this.edittedPkg.length,
                height: this.edittedPkg.height,
                pkgUnitType: 'METRIC',
                parcelId: this.edittedPkg.parcelTypeId,
                notes: this.edittedPkg.notes,
                description: this.edittedPkg.description,
                adminNotes: this.edittedPkg.adminNotes,
                invoice: this.edittedPkg.invoiceNumber,
                supplierInvoice: this.edittedPkg.supplierInvoice,
                deliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
                createdDate: this.showCreatedDate() ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : null,
                extraOptions: pkgExtraOptions,
                hubId: this.edittedPkg.hubId,
                quantity: this.edittedPkg.quantity
            };
        }

        this.form = this.formBuilder.group({
            senderName: [{value: info.senderName, disabled: this.canEdit('senderName')}],
            senderFirstName: [{value: info.senderFirstName, disabled: this.canEdit('senderName')}, Validators.required],
            senderLastName: [{value: info.senderLastName, disabled: this.canEdit('senderName')}, Validators.required],
            senderAuthorizedGovRegistrationNumber: [{
                value: info.senderAuthorizedGovRegistrationNumber,
                disabled: this.canEdit('senderName')
            }],
            receiverAuthorizedGovRegistrationNumber: [{
                value: info.receiverAuthorizedGovRegistrationNumber,
                disabled: this.canEdit('senderName')
            }],
            businessSenderName: [{value: info.businessSenderName, disabled: this.canEdit('senderName')}],
            businessReceiverName: [{value: info.businessReceiverName, disabled: this.canEdit('senderName')}],
            senderMiddleName: [{value: info.senderMiddleName, disabled: this.canEdit('senderName')}],
            senderEmail: [{value: info.senderEmail, disabled: this.canEdit('senderEmail')}, Validators.compose([
                Validators.required,
                // Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')
                Validators.minLength(6)
            ])],
            senderPhone: [{disabled: this.canEdit('senderPhone')}, [Validators.required, Validators.maxLength(this.userService.userInfo.maxPhoneNumberValidationDigits),
                Validators.minLength(this.userService.userInfo.minPhoneNumberValidationDigits)]],
            senderPhone2: [{disabled: this.canEdit('senderPhone')}],
            addressFromIsHub: [{disabled: this.canEdit('hubId')}, Validators.required],
            hubId: [{value: info.hubId, disabled: this.canEdit('hubId')}, Validators.required],
            originAddressLine1: [{disabled: this.canEdit('originAddressLine1')}, Validators.required],
            originAddressLine2: [{disabled: this.canEdit('originAddressLine1')}],
            receiverName: [{disabled: this.canEdit('receiverName')}],
            receiverFirstName: [{disabled: this.canEdit('receiverName')}, Validators.required],
            receiverLastName: [{disabled: this.canEdit('receiverName')}, Validators.required],
            receiverMiddleName: [{disabled: this.canEdit('receiverName')}],
            receiverEmail: [{disabled: this.canEdit('receiverEmail')}],
            swapOrBring: [{disabled: this.canEdit('receiverEmail')}],
            receiverPhone: [{disabled: this.canEdit('swapOrBring')},
                [Validators.required,
                    Validators.maxLength(this.userService.userInfo.maxPhoneNumberValidationDigits),
                Validators.minLength(this.userService.userInfo.minPhoneNumberValidationDigits)]],
            receiverPhone2: [{disabled: this.canEdit('receiverPhone')}],
            destinationAddressLine1: [{
                disabled: this.canEdit('destinationAddressLine1')
            }, Validators.required],
            destinationAddressLine2: [{
                disabled: this.canEdit('destinationAddressLine1')
            }],
            // airwayBill: ['', Validators.required],
            originCity: [{disabled: this.canEdit('originCity')}, Validators.required],
            originRegion: [{disabled: this.canEdit('originCity')}, Validators.required],
            originVillage: [{disabled: this.canEdit('originCity')}, Validators.required],
            originCombined: [{disabled: this.canEdit('originCity')}, Validators.required],
            destinationCity: [{disabled: this.canEdit('destinationCity')}, Validators.required],
            destinationRegion: [{disabled: this.canEdit('destinationCity')}, Validators.required],
            destinationVillage: [{disabled: this.canEdit('destinationCity')}, Validators.required],
            destinationCombined: [{disabled: this.canEdit('destinationCity')}, Validators.required],
            originCountry: [{disabled: this.canEdit('originCountry')}],
            destinationCountry: [{disabled: this.canEdit('destinationCountry')}],
            freight: [{disabled: this.canEdit('freight')}],
            service: [{disabled: this.canEdit('service')}, Validators.required],
            type: [{disabled: this.canEdit('type')}, Validators.required],
            cod: [{disabled: this.canEdit('cod')}],
            toCollectFromReceiver: [{disabled: this.canEdit('toCollectFromReceiver')}],
            toPayToReceiver: [{disabled: this.canEdit('toPayToReceiver')}],
            weight: [{disabled: this.canEdit('weight')}, Validators.required],
            width: [{disabled: this.canEdit('width')}, Validators.required],
            length: [{disabled: this.canEdit('length')}, Validators.required],
            height: [{disabled: this.canEdit('height')}, Validators.required],
            pkgUnitType: [{disabled: this.canEdit('pkgUnitType')}],
            parcelId: [{value: null, disabled: this.canEdit('parcelId')}],
            notes: [{disabled: this.canEdit('notes')}],
            description: [{disabled: this.canEdit('description')}],
            adminNotes: [{disabled: this.canEdit('adminNotes')}],
            invoice: [{disabled: this.canEdit('invoice')}],
            supplierInvoice: [{disabled: this.canEdit('supplierInvoice')}],
            deliveryDate: [{disabled: this.canEdit('deliveryDate')}],
            extraOptions: [{disabled: this.canEdit('extraOptions')}], // Extra options
            quantity: [{value: info.quantity, disabled: this.canEdit('quantity')}],
            createdDate: [this.getCreatedDate()]
        });

        this.initFormEvent(this.form, 0, {
            country: '',
            region: 'originRegion',
            city: 'originCity',
            village: 'originVillage',
            combined: 'originCombined'
        });

        this.initFormEvent(this.form, 1, {
            country: '',
            region: 'destinationRegion',
            city: 'destinationCity',
            village: 'destinationVillage',
            combined: 'destinationCombined'
        });

        if (!this.isEditMode) {
            this.initMetricValueChanges();
        }

        this.form.controls['parcelId'].valueChanges.subscribe(data => {
            this.setParcel(data);
        });

        this.form.controls['type'].valueChanges.subscribe(data => {
            if (data === 'COD') {
                this.form.controls['cod'].setValidators([Validators.required]);
            } else {
                this.form.controls['cod'].setValidators([]);
            }
            if (data === 'SWAP' || data === 'BRING') {
                this.changeSwapOrBringInput();
            } else {
                this.form.controls['toCollectFromReceiver'].setValue('');
                this.form.controls['toPayToReceiver'].setValue('');
                this.form.controls['toCollectFromReceiver'].disable();
                this.form.controls['toPayToReceiver'].disable();
            }
            this.form.controls['cod'].updateValueAndValidity();
            this.form.controls['toCollectFromReceiver'].updateValueAndValidity();
            this.form.controls['toPayToReceiver'].updateValueAndValidity();
        });

        this.form.patchValue(info);
        this.changeAddressType();
        this.disableRest = false;
        this.form.updateValueAndValidity({emitEvent: true});
        this.utilitiesService.markFormGroupTouched(this.form);
        this.initInputNotes();
    }

    public getCreatedDate() {
        if (this.companyId === 64 && !this.isEditMode) {
            return new Date();
        } else {
            return null;
        }
    }

    public initMetricValueChanges() {
        if (this.form.controls['originRegion']) {
            this.form.controls['originRegion'].valueChanges.subscribe(data => {
                if (!this.form.controls['parcelId'].value) {
                    this.initMetrics();
                }

            });
        }
        if (this.form.controls['pkgUnitType']) {
            this.form.controls['pkgUnitType'].valueChanges.subscribe(data => {
                if (!this.form.controls['parcelId'].value) {
                    this.initMetrics();
                }
            });
        }
    }

    public initInputNotes() {
        this.setDeliveryDateNote();

        this.form.controls.service.valueChanges.subscribe(
            (changedData) => {
                this.setDeliveryDateNote();
            }
        );
    }

    public canEdit(controlName) {
        // special case, if control name was COD and user role was 'Customer Care'
        // then it will be enable if the creater of the shipment is the same as logged in user.
        if (controlName === 'cod' && this.userService.userInfo.role.toLowerCase() === 'customer care'
            && this.isEditMode) {
            if (this.edittedPkg.customerId === this.userService.userInfo.id) {
                return true;
            } else {
                return false;
            }
        }

        if (this.editedFields === 'all') {
            return false;
        }
        return !this.editedFields.includes(controlName);
    }

    public setDeliveryDateNote() {
        const serviceType = this.form.controls.service.value;
        const newDateValue = new Date();

        switch (serviceType) {
            case 'Express':
                this.deliveryDateNote = 'Same date';
                break;
            case 'Standard':
                this.deliveryDateNote = 'two-three days';
                newDateValue.setDate(newDateValue.getDate() + 2);
                break;
            case 'THREE_TO_FIVE_DAYS':
                this.deliveryDateNote = 'three-five days';
                newDateValue.setDate(newDateValue.getDate() + 3);
                break;
        }

        this.form.controls.deliveryDate.setValue(this.datePipe.transform(newDateValue, 'yyyy-MM-dd'));
        this.form.controls.deliveryDate.updateValueAndValidity();
    }

    public removeProduct(removedProduct: ProductModel) {
        this.products = this.products.filter((product) => {
            return product !== removedProduct;
        });
    }
    public checkDisappearValue(value) {
        return (value === undefined || value === null || value === '' ? 0 : value);
    }
    public addShipment() {
        if (!this.form.valid) {
            return;
        }
        this.isSubmitting = true;

        const data = this.form.getRawValue();
        const products = this.processProducts();
        const extraOptions = this.getSelectedExtraOptions(data.extraOptions);

        const body = {
            pkg: {
                customerId: this.customerId,
                cost: data.freight,
                cod: this.checkDisappearValue(data.cod),
                toPayToReceiver: this.checkDisappearValue(data.toPayToReceiver),
                toCollectFromReceiver: this.checkDisappearValue(data.toCollectFromReceiver),
                deliveryDate: data.deliveryDate,
                isInsurance: extraOptions.has('insurance'),
                isBreakable: extraOptions.has('breakable'),
                isflammable: extraOptions.has('flammable'),
                isDangerousOrChemical: extraOptions.has('dangerousOrChemical'),
                isAnimalOrPit: extraOptions.has('animalOrPits'),
                isNeedPacking: extraOptions.has('needPacking'),
                notes: data.notes,
                description: data.description,
                adminNotes: data.adminNotes,
                invoiceNumber: data.invoice,
                weight: data.weight,
                length: data.length,
                width: data.width,
                height: data.height,
                parcelTypeId: parseInt(data.parcelId, 0),
                senderName: data.senderName,
                senderFirstName: data.senderFirstName,
                senderLastName: data.senderLastName,
                senderMiddleName: data.senderMiddleName,
                businessSenderName: data.businessSenderName,
                businessReceiverName: data.businessReceiverName,
                receiverAuthorizedGovRegistrationNumber: data.receiverAuthorizedGovRegistrationNumber,
                senderAuthorizedGovRegistrationNumber: data.senderAuthorizedGovRegistrationNumber,
                senderEmail: data.senderEmail,
                receiverName: data.receiverName,
                receiverFirstName: data.receiverFirstName,
                receiverLastName: data.receiverLastName,
                receiverMiddleName: data.receiverMiddleName,
                senderPhone: data.senderPhone,
                senderPhone2: data.senderPhone2,
                receiverPhone: data.receiverPhone,
                receiverPhone2: data.receiverPhone2,
                receiverEmail: data.receiverEmail,
                serviceType: data.service.toUpperCase(),
                shipmentType: data.type.toUpperCase(),
                products: products,
                quantity: data.quantity,
                supplierInvoice: data.supplierInvoice,
                createdDate: data.createdDate
            },
            destinationAddress: {
                addressLine1: data.destinationAddressLine1,
                addressLine2: data.destinationAddressLine2,
                cityId: data.destinationCity.value,
                villageId: data.destinationVillage.value,
                regionId: data.destinationRegion.value,
                country: data.destinationCountry
            },
            pkgUnitType: 'METRIC'
        };
        body['originAddress'] = {
            addressLine1: data.originAddressLine1,
            addressLine2: data.originAddressLine2,
            cityId: data.originCity.value,
            country: data.originCountry,
            regionId: data.originRegion.value,
            villageId: data.originVillage.value
        };

        if (data.hubId) {
            body.pkg['hubId'] = data.hubId;
        }

        if (this.isEditMode) {
            this.submitEditting(body);
            return;
        }

        if (body.pkg.cod > 999) {
            this.translateService.get(
                ['ALERTS.COD_GREATER_THAN_3_DIGITS_WARNING', 'GENERAL.YES', 'GENERAL.NO']
            )
                .subscribe(
                    (data) => {
                        const message = data['ALERTS.COD_GREATER_THAN_3_DIGITS_WARNING'];
                        const yes = data['GENERAL.YES'];
                        const no = data['GENERAL.NO'];
                        this.confirmationService.confirm({
                            message: message,
                            accept: () => {
                                this.addShipmentRequest(body);
                            },
                            acceptLabel: yes,
                            rejectLabel: no,
                            reject: () => {
                                this.isSubmitting = false;
                            }
                        });
                    }
                );
        } else {
            this.addShipmentRequest(body);
        }
    }

    private addShipmentRequest(body) {
        if (this.athuenticationService.companyId === 64
            || this.athuenticationService.companyId === 18
            || this.athuenticationService.companyId === 1) {
            this.shipmentService.requestPackage(body).subscribe(
                (response) => {
                    this.translateService.get(
                        'ALERTS.SHIPMENT_ADDED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                            }
                        );
                }, (error) => {
                    console.error(error);
                },
                () => {
                }
            );
            this.form.reset();
            this.initOptions();
            this.isSubmitting = false;
        } else {
            this.shipmentService.requestPackage(body).subscribe(
                (response) => {
                    this.translateService.get(
                        'ALERTS.SHIPMENT_ADDED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                            }
                        );
                    this.form.reset();
                    this.initOptions();
                }, (error) => {
                    console.error(error);
                    this.isSubmitting = false;
                },
                () => {
                    this.isSubmitting = false;
                }
            );
        }
    }

    public submitEditting(body) {
        let reqBody = _.merge(this.edittedPkg, body.pkg);
        reqBody.originAddress = body.originAddress;
        reqBody.destinationAddress = body.destinationAddress;
        this.shipmentService.requestPackageChanges(this.edittedPkg.id, reqBody).subscribe(
            (response) => {
                this.translateService.get(
                    'ALERTS.SHIPMENT_EDITED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                        }
                    );
            }, (error) => {
                console.error(error);
                this.isSubmitting = false;

            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    public processProducts() {
        const result = [];

        this.products.forEach(
            (product) => {
                if (product.name !== '' && product.weight && product.eachCost && product.quantity) {
                    result.push(product);
                }
            }
        );

        return result;
    }

    public getSelectedExtraOptions(extraOptions: string[]): Set<string> {
        const result = new Set<string>();
        if (!extraOptions) {
            return result;
        }
        extraOptions.forEach(
            (option) => {
                result.add(option);
            }
        );
        return result;
    }

    public resetForm() {
        if (this.userService.userInfo.role.toLowerCase() === 'dispatcher') {
            return;
        }
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.RESET_FORM', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.RESET_FORM'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.form.reset();
                if (this.pkgId) {
                    this.isEditMode = true;
                    this.fetchEditedPkg(this.pkgId);
                } else {
                    this.initOptions();
                }
                // this.form.reset();
                // this.deliveryDateNote = '';
                // this.products = [
                //     {
                //         name: '',
                //         quantity: 1,
                //         weight: 1,
                //         eachCost: 10,
                //         totalCost: 10
                //     }
                // ];
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }

    public onProductsChange($event: { weight: number, cost: number }) {
        this.form.controls.weight.setValue($event.weight);
        this.form.controls.cod.setValue($event.cost);
        this.form.controls.weight.updateValueAndValidity();
        this.form.controls.cod.updateValueAndValidity();
    }

    public chooseExisting(rec) {
        const modal = this.modalService.open(ChooseExistingComponent, <any>{windowClass: 'assign-package-to-modal', size: 'md'});
        modal.result.then(
            (data: { isSuccess: boolean, user: UserModel }) => {
                if (data.isSuccess) {
                    const user = data.user;
                    const updateFormValue = (controlName, value) => {
                        this.form.controls[controlName].setValue(value);
                        this.form.controls[controlName].updateValueAndValidity();
                    };
                    if (!rec) {
                        this.customerId = data.user.id;
                        updateFormValue('senderFirstName', user.firstName);
                        updateFormValue('senderLastName', user.lastName);
                        updateFormValue('senderMiddleName', user.middleName);
                        updateFormValue('senderPhone', user.phone);
                        updateFormValue('senderEmail', user.email);
                        updateFormValue('originAddressLine1', user.address.addressLine1);
                        updateFormValue('originAddressLine2', user.address.addressLine2);
                        updateFormValue('businessSenderName', user.businessName);
                        updateFormValue('originRegion', {
                            value: user.address.regionId,
                            label: user.address.region
                        });
                        updateFormValue('originCity', {
                            value: user.address.cityId,
                            label: user.address.city
                        });
                        updateFormValue('originVillage', {
                            value: user.address.villageId,
                            label: user.address.village
                        });
                        updateFormValue('originCombined', {
                            value: user.address.villageId,
                            label: user.address.village + '-' + user.address.city + '-' + user.address.region
                        });
                    } else {
                        updateFormValue('receiverFirstName', user.firstName);
                        updateFormValue('receiverLastName', user.lastName);
                        updateFormValue('receiverMiddleName', user.middleName);
                        updateFormValue('receiverPhone', user.phone);
                        updateFormValue('receiverEmail', user.email);
                        updateFormValue('destinationAddressLine1', user.address.addressLine1);
                        updateFormValue('destinationAddressLine2', user.address.addressLine2);
                        updateFormValue('businessReceiverName', user.businessName);
                        updateFormValue('destinationRegion', {
                            value: user.address.regionId,
                            label: user.address.region
                        });
                        updateFormValue('destinationCity', {
                            value: user.address.cityId,
                            label: user.address.city
                        });
                        updateFormValue('destinationVillage', {
                            value: user.address.villageId,
                            label: user.address.village
                        });
                        updateFormValue('destinationCombined', {
                            value: user.address.villageId,
                            label: user.address.village + '-' + user.address.city + '-' + user.address.region
                        });
                    }

                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public changeAddressType() {
        this.disableRest = true;
        if (this.form.value.addressFromIsHub) {
            this.form.controls['hubId'].setValidators([Validators.required]);
            this.form.controls['hubId'].updateValueAndValidity();
        } else {
            this.form.controls['hubId'].setValidators([]);
            this.form.controls['hubId'].setValue(undefined);
            this.form.controls['hubId'].updateValueAndValidity();
        }
        this.disableRest = false;
    }

    private disableForm() {
        Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].disable();
        });
    }

    updateZoneForm($event, controlName) {
        this.form.controls[controlName].setValue(null);
        this.form.controls[controlName].updateValueAndValidity();
    }

    public isAllowedEditCost() {
        return this.permissions.includes('{EDIT_COST') ||
            (this.permissions.includes('{CONDITIONAL_COST') && this.userService.userInfo.isNonAdminChangeCost);
    }

    public toggleshowDetails(isSender = true) {
        if (isSender) {
            this.showDetailsSender = !this.showDetailsSender;
        } else {
            this.showDetailsReceiver = !this.showDetailsReceiver;
        }
    }
    public changeSwapOrBringInput() {
        this.form.controls['toPayToReceiver'].reset(null);
        this.form.controls['toCollectFromReceiver'].reset(null);
        if (this.swapOrBring === 'COLLECT') {
            this.form.controls['toPayToReceiver'].disable();
            this.form.controls['toCollectFromReceiver'].enable();
            this.form.controls['toCollectFromReceiver'].markAsTouched();
            this.form.controls['toCollectFromReceiver'].setValidators([Validators.required]);
        } else if (this.swapOrBring === 'PAY') {
            this.form.controls['toCollectFromReceiver'].disable();
            this.form.controls['toPayToReceiver'].enable();
            this.form.controls['toPayToReceiver'].markAsTouched();
            this.form.controls['toPayToReceiver'].setValidators([Validators.required]);
        }
        this.form.controls['toCollectFromReceiver'].updateValueAndValidity();
        this.form.controls['toPayToReceiver'].updateValueAndValidity();
    }

    public changeSwapOrBring() {
        this.swapOrBring = this.form.value.swapOrBring;
        this.changeSwapOrBringInput();
    }
}
