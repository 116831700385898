import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UserService} from '../../shared/services/user.service';
import {setBarcodeReader} from '../../shared/services/helpers';
import {ShipmentsService} from '../services/shipments.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UsersService} from '../../users/services/users.service';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FaliureHistoryComponent} from '../faliure-history/faliure-history.component';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {playError} from '../../shared/behavior/sounds';
import {ReportConfirmationComponent} from './report-confirmation/report-confirmation.component';

@Component({
    selector: 'app-receive-driver',
    templateUrl: './receive-driver.component.html',
    styleUrls: ['./receive-driver.component.scss']
})
export class ReceiveDriverComponent implements OnInit {
    public driverId;
    public hubId;

    public isLoading = false;
    public isScanning = false;
    public isReading = false;
    public isOnlyReturnedPackages = false;
    public totalPackages = 0;
    public scannedPackages = [];
    public packages = [];
    public permissions = '';
    public pageNumber = 0;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public isPickup: boolean;
    public driverName = '';
    public hubName = '';
    public isFromPartner: boolean;
    public partnerId: number;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private route: ActivatedRoute,
                private userService: UserService,
                private usersService: UsersService,
                private shipmentsService: ShipmentsService,
                private rolesService: RolesService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            this.isFromPartner = params.isFromPartner === 'true';
            if (this.isFromPartner) {
                this.partnerId = params.partnerId;
            } else {
                this.driverId = params.driverId;
                this.driverName = params.driverName;
            }
            this.hubId = params.hubId;
            this.isPickup = params.isPickup === 'true';
            if (params.hubName) {
                this.hubName = params.hubName;
            }
            if (params.isOnlyReturnedPackages) {
                this.isOnlyReturnedPackages = params.isOnlyReturnedPackages;
            }
            this.isScanning = false;
            this.isLoading = true;
            const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
            this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        });

        this.isReading = true;
        setBarcodeReader((barcode) => {
            if (this.isReading) {
                this.barcodeReader(barcode);
            }
        });
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        if (this.isFromPartner) {
            this.getPartnerPackages();
        } else {
            if (this.isPickup) {
                this.getDriverPickups();
            } else {
                this.getDriverPackages();

            }
        }
    }
    public getPartnerPackages() {
        this.isLoading = true;
        const URLQuery = this.createQuery();
        this.usersService.getPartnerPackages(this.partnerId, URLQuery, {}).subscribe(
            (response: { pkgs: any[], totalRecordsNo: number }) => {
                this.isLoading = false;
                this.packages = response.pkgs;
                this.totalPackages = response.totalRecordsNo;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public getDriverPackages() {
        this.isLoading = true;
        const URLQuery = this.createQuery();
        this.usersService.getDriverPackages(this.driverId, URLQuery, {}).subscribe(
            (response: { data: any[], totalRecordsNo: number , driverName: string}) => {
                this.isLoading = false;
                this.packages = response.data;
                this.driverName = response.driverName;
                this.totalPackages = response.totalRecordsNo;
            }
        );
    }
    public getDriverPickups() {
        this.isLoading = true;
        const URLQuery = this.createQuery();
        this.usersService.getDriverPickups(this.driverId, URLQuery).subscribe(
            (response: { data: any[], totalRecordsNo: number, driverName: string }) => {
                this.isLoading = false;
                this.driverName = response.driverName;
                this.packages = response.data;
                this.totalPackages = response.totalRecordsNo;
            }
        );
    }

    public createQuery() {
        let result = `?pageSize=${this.pageSize}&page=` + (this.pageNumber + 1);
        if (this.isOnlyReturnedPackages === true) {
            result += '&status=RETURNED_BY_RECIPIENT';
        }
        return result;
    }

    private barcodeReader(barcode) {
        if (!this.isScanning) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.getDriverPackageByBarcode(barcode, this.driverId).subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.scanPackage(shipment);
                        this.isLoading = false;
                    }
                );
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    scanPackage(shipment) {
        if (shipment) {
            if (!this.scannedPackages.includes(shipment.id)) {
                this.scannedPackages.unshift(shipment.id);
            }
            return;
        }
        if (this.driverId) {
            this.translateService.get(['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER'],
                    rejectVisible: false,
                    acceptLabel: 'OK'
                });
                return;
            });
        }
    }

    public isPackageScanned(id) {
        return this.scannedPackages.includes(id);
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    removePackage(shipment) {
        const index = this.scannedPackages.indexOf(shipment.id);
        this.scannedPackages.splice(index, 1);
    }

    public startScanning() {
        this.isScanning = true;
    }

    public resetScanning() {
        this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
                accept: () => {
                    this.isScanning = false;
                    this.scannedPackages = [];
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isCarried = true;
        modal.componentInstance.isPickup = this.isPickup;
        if (this.isFromPartner) {
            modal.componentInstance.isReceivePartnerPackage = true;
            modal.componentInstance.partnerId = this.partnerId;
        } else {
            modal.componentInstance.driverId = this.driverId;
        }
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.scanPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public finishScanning() {
        this.isLoading = true;
        if (this.isFromPartner) {
            this.receivePartnerPackages();
        } else {
            if (this.isPickup) {
                const translateService
                    = this.translateService.get(['ALERTS.RECEIVE_DRIVER_PICKUPS_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']);
                translateService.subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['ALERTS.RECEIVE_DRIVER_PICKUPS_CONFIRMATION'],
                        accept: () => {
                            this.receivePickups();
                            this.isLoading = false;
                        },
                        reject: () => {
                            this.isScanning = false;
                            this.isLoading = false;
                        },
                        acceptLabel: res['GENERAL.YES'],
                        rejectLabel: res['GENERAL.NO'],
                    });
                });
            } else {
                const modal = this.modalService.open(ReportConfirmationComponent, {
                    backdrop: 'static',
                    windowClass: 'edit-template',
                    size: 'lg'
                });
                modal.result.then(
                    (data: { printType: string }) => {
                        this.isLoading = false;
                        if (data.printType !== '') {
                            this.receivePackages(data.printType);

                        }
                    }
                ).catch(
                    (error) => {
                        this.isLoading = false;
                        console.error(error);
                    }
                );
            }
        }

    }
    public receivePackages(printType = 'NONE') {
        this.isLoading = true;
        this.shipmentsService.receiveDriverPackages(this.driverId, this.hubId, this.scannedPackages, printType).subscribe((data: any) => {
            if (data !== undefined && data && data.url !== undefined) {
                window.open(data.url, '_blank');
            }
            this.isLoading = false;
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS'],
                    rejectVisible: false,
                    acceptLabel: 'OK',
                });
                this.isScanning = false;
                this.scannedPackages = [];
                this.getDriverPackages();
            });
        }, (error) => {
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_FAILED']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RECEIVE_FROM_DRIVER_FAILED'] + ': ' + error.err.err,
                    rejectVisible: false,
                    acceptLabel: 'OK',
                });
                this.isScanning = false;
            });

        });
    }
    public receivePickups() {
        this.isLoading = true;
        this.shipmentsService.receiveDriverPickups(this.driverId, this.hubId, this.scannedPackages).subscribe((data) => {
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS'],
                    rejectVisible: false,
                    acceptLabel: 'OK',
                });
                this.isScanning = false;
                this.scannedPackages = [];
                this.getDriverPickups();
                this.isLoading = false;
            });
        }, (error) => {
            this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_FAILED']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RECEIVE_FROM_DRIVER_FAILED'] + ': ' + error.err.err,
                    rejectVisible: false,
                    acceptLabel: 'OK',
                });
                this.isScanning = false;
                this.isLoading = false;
            });

        });
    }
    public onChangePackageStatus(pkg = null) {
        const modal = this.modalService.open(ChangePackageStatusComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.isModal = true;
        if (pkg) {
            modal.componentInstance.shipment = pkg;
        } else {
            modal.componentInstance.shipments = this.getSelectedPackages();
        }
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    // this.initShipments();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    public openMessagesHistory(id) {
        const modal = this.modalService.open(FaliureHistoryComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.shipmentId = id;
        modal.result.then(
        ).catch(
            (error) => {
            }
        );

    }
    private getSelectedPackages() {
        const selectedPackages = [];
        if (!this.packages) {
            return;
        }

        this.packages.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }

    public receivePartnerPackages() {
        this.isLoading = true;
        const body = {
            ids: this.scannedPackages,
            hubId: this.hubId,
            isReceiveFromPartner: true
        };
        this.shipmentsService.receivePartnerPackages(body).subscribe((data: any) => {
            if (data !== undefined && data && data.url !== undefined) {
                window.open(data.url, '_blank');
            }
            this.isLoading = false;
            this.translateService.get(['ALERTS.RECEIVE_FROM_PARTNER_SUCCESS']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ALERTS.RECEIVE_FROM_PARTNER_SUCCESS'],
                    rejectVisible: false,
                    acceptLabel: 'ok',
                });
                this.isScanning = false;
                this.scannedPackages = [];
                this.getPartnerPackages();
            });
        }, (error) => {
            this.isLoading = false;
            this.translateService.get(['ALERTS.RECEIVE_FROM_PARTNER_FAILED']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RECEIVE_FROM_PARTNER_FAILED'] + ': ' + error.err.err,
                    rejectVisible: false,
                    acceptLabel: 'ok',
                });
                this.isScanning = false;
            });

        });
    }
}
