import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomDropdownListModel} from '../../models/custom-dropdown-list.model';

@Component({
    selector: 'app-custom-drop-down-list',
    templateUrl: './custom-drop-down-list.component.html',
    styleUrls: ['./custom-drop-down-list.component.scss']
})
export class CustomDropDownListComponent implements OnInit {
    public currentLang = '';

    @ViewChild('customDropDownList') customDropDownList;

    @Input() list: CustomDropdownListModel[];
    @Input() styleClass: string;
    @Input() selectedValue: string;
    @Input() selectedLabel: string;
    @Input() isSearchable = false;
    @Input() placeHolder = 'GENERAL.SEARCH';
    @Input() isRemoveNoneValue = true;
    @Input() isLoading = false;
    @Input() disabled = false;

    @Output() selectOption: EventEmitter<{}> = new EventEmitter<{}>();
    @Output() search: EventEmitter<{}> = new EventEmitter<{}>();
    @Output() onShowList: EventEmitter<{}> = new EventEmitter<{}>();


    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.removeNoneValue();
    }

    removeNoneValue() {
        if (this.isRemoveNoneValue) {
            this.list = this.list.filter(l => l.value !== 'NONE');
        }
    }

    onHideList() {
    }

    showList(event) {
        if(!this.disabled) {
            this.customDropDownList.toggle(event);
            this.onShowList.emit();
        }
    }

    onSelect(event: CustomDropdownListModel) {
        if (!this.disabled) {
            this.selectOption.emit({value: event.value});
        }
    }

    onSearch(event) {
        this.search.emit({query: event});
    }
}
