<div class="alerts-container">
  <div *ngFor="let alert of alerts" (click)="alert.action()"
       class="notification {{alert.severity}} {{alert.state}}">
    <span class="notification-close" (click)="removeAlert(alert, $event)">
      &times;
    </span>
    <h3 class="notification-title">
      {{alert.title}}
    </h3>
    <p class="notification-message">
      {{alert.message}}
    </p>
  </div>
</div>
