import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Sidebar} from 'primeng/sidebar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ZonesService} from '../../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {DAYS} from '../../services/constants';
import {ParcelService} from '../../../administration/services/parcel.service';
import {TripsBetweenCitiesService} from '../../services/trips-between-cities.service';
import {MessageService} from 'primeng/api';
import moment from 'moment';
import {HubsService} from '../../../shared/services/hubs.service';

@Component({
    selector: 'app-add-trip-form',
    templateUrl: './add-trip-form.component.html',
    styleUrls: ['./add-trip-form.component.scss']
})
export class AddTripFormComponent implements OnInit {
    @Input() display: boolean;
    @Input() isEditMode = false;
    @Output() closeWidget = new EventEmitter<boolean>();

    @ViewChild('newTripSideBarr', {static: true})
    newTripSideBarr: Sidebar;
    isLoading = false;
    currentLang;

    mainTripInfoForm: FormGroup;
    addTripsForms = [];

    regionOptions: any;

    daysList = [];
    parcelsOptions = [];

    mainTripId;
    isEditMainTrip = false;
    showAddSubTripBtn = true;

    constructor(private formBuilder: FormBuilder,
                private translate: TranslateService,
                private tripsBetweenCitiesService: TripsBetweenCitiesService,
                private messageService: MessageService,
                private hubsService: HubsService,
                private parcelService: ParcelService,
                private zonesService: ZonesService) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.setDropDowns();
        this.getRegions();
        this.addTripsForms = [];
        this.initForms();
    }

    setDropDowns() {
        this.setDaysList();
        this.setParcelsList();
    }

    setDaysList() {
        this.daysList = DAYS.map(
            (day) => {
                return {label: this.translate.instant(`TRIPS_BETWEEN_CITIES.DAYS.${day}`), value: day};
            }
        );
    }
    setParcelsList() {
        this.parcelService.getParcels().subscribe(
            (res: any) => {
                this.parcelsOptions = res.data.map((parcel) => {
                    return {
                        ...parcel,
                        label: this.translate.currentLang === 'ar' ? parcel.arabicType : parcel.type,
                        value: parcel.id
                    };
                });
                this.parcelsOptions.unshift({label: '', value: null});
            }, error =>  {
                console.error(error);
            }
        );

    }

    initForms(editMode = false) {
        this.mainTripInfoForm = this.formBuilder.group({
            tripNumber: [null, Validators.required],
            mainTripCapacity: [null, Validators.required]
        });
        if (!editMode) {
            this.addSubTripAddForm();
        }
    }

    addSubTripAddForm() {
        this.addTripsForms.push(
            {form: this.formBuilder.group({
                    days: [null, Validators.required],
                    fromHub: [null, Validators.required],
                    toHub: [null, Validators.required],
                    validFrom: [null, Validators.required],
                    validTo: [null, Validators.required],
                    parcelType: [null, Validators.required],
                    fromTime: [null, Validators.required],
                    toTime: [null, Validators.required],
                    cost: [null, Validators.required],
                    capacity: [null, Validators.required]
                })}
        );
        if (this.isEditMode && this.showAddSubTripBtn) {
            document.getElementById('new-sub-trip').scrollIntoView({behavior: 'smooth'});
            this.showAddSubTripBtn = false;
        }
    }

    getRegions(query = '') {
        let param = '?page=1&pageSize=20';
        if (query) {
            param += '&search=' + query;
        }
        const getRegions = this.hubsService.getHubs(param);
        getRegions.subscribe(
            response => {
                this.regionOptions = response['hubs'].map(region => {
                    return {label: region.name, value: region.id};
                });
            }
        );
    }

    onClose(isSuccess = false) {
        // this.clearForm();
        this.isEditMainTrip = false;
        this.display = false;
        this.closeWidget.emit(isSuccess);
    }


    addTrip(trip) {
        // add, edit  sub trip

        if (!this.mainTripId) {
            return;
        }
        trip.isLoading  = true;
        const tripData = trip.form.getRawValue();

        const body = {
            fromHubId: tripData.fromHub.value,
            toHubId: tripData.toHub.value,
            fromTime: tripData.fromTime,
            toTime: tripData.toTime,
            days: tripData.days,
            cost: tripData.cost,
            capacity: tripData.capacity,
            parcelTypeId: tripData.parcelType,
            tripName: tripData.tripName,
            validFrom: tripData.validFrom,
            validTo: tripData.validTo,
            mainCitiesTripsTimeScheduleId: this.mainTripId
        };
        if (!trip.id) {
            this.tripsBetweenCitiesService.addTrip(body).subscribe(
                (subTripId: number) => {
                    trip.id = subTripId;
                    trip.isLoading = false;
                    trip.addedDone = true;
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translate.instant('ALERTS.DONE_SUCCESSFULLY')
                    });
                    this.addSubTripAddForm();

                }, error => {
                    trip.isLoading = false;
                    console.error(error);
                }
            );
        } else {
            this.tripsBetweenCitiesService.editTrip(trip.id, body).subscribe(
                (res: any) => {
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translate.instant('ALERTS.DONE_SUCCESSFULLY')
                    });
                    trip.isLoading = false;
                }, error => {
                    trip.isLoading = false;
                    console.error(error);
                }
            );
        }

    }

    editTrip(subTrip) {

    }


    editSubTrip(trip) {
        trip.isLoading = true;
        const destinationsData = this.mainTripInfoForm.getRawValue();
        const subTripData = trip.form.getRawValue();
        const body = {
            fromCityId: destinationsData.fromCity.value,
            toCityId: destinationsData.toCity.value,
            fromTime: subTripData.fromTime,
            toTime: subTripData.toTime,
            days: subTripData.days,
            cost: subTripData.cost,
            tripName: subTripData.tripName,
            parcelTypeId: subTripData.parcelType,
            capacity: subTripData.capacity
        };

        this.tripsBetweenCitiesService.editTrip(body, trip.id).subscribe(
            () => {
                trip.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    detail: this.translate.instant('ALERTS.DONE_SUCCESSFULLY')
                });
            }, err => {
                trip.isLoading = false;
                console.error(err);
            }
        );
    }

    addMainTrip() {
        this.isLoading = true;
        if (!this.isEditMainTrip) {
            this.tripsBetweenCitiesService.addMainTrip({...this.mainTripInfoForm.getRawValue()}).subscribe(
                (mainTripId: number) => {
                    this.mainTripId = mainTripId;
                    this.isEditMainTrip = true;
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translate.instant('ALERTS.DONE_SUCCESSFULLY')
                    });
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        } else {
            this.tripsBetweenCitiesService.updateMainTrip(this.mainTripId, {...this.mainTripInfoForm.getRawValue()}).subscribe(
                () => {
                    this.isLoading = false;
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translate.instant('ALERTS.DONE_SUCCESSFULLY')
                    });
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        }

    }

    loadComponent(trip) {
        this.mainTripInfoForm.reset();
        this.addTripsForms = [];
        if (trip) {
            this.mainTripId = trip.id;
            this.isEditMainTrip = true;
            this.isEditMode = true;
        }
        this.initForms(trip !== null);
        if (trip) {
            this.mainTripInfoForm.patchValue({
                tripNumber: trip.tripNumber,
                mainTripCapacity: trip.mainTripCapacity
            });
            trip.citiiesTripsTimeSchedules?.forEach(
                (subTrip) => {
                    this.addTripsForms.push({
                        form: this.formBuilder.group({
                            days: [subTrip.days, Validators.required],
                            fromHub: [{label: subTrip.fromHubName, value: subTrip.fromHubId}, Validators.required],
                            toHub: [{label: subTrip.toHubName, value: subTrip.toHubId}, Validators.required],
                            validFrom: [subTrip.validFrom ? new Date(subTrip.validFrom) : null, Validators.required],
                            validTo: [subTrip.validTo ? new Date(subTrip.validTo) : null, Validators.required],
                            tripName: [subTrip.tripName, Validators.required],
                            fromTime: [subTrip.fromTime ? new Date(subTrip.fromTime) : null, Validators.required],
                            toTime: [subTrip.toTime ? new Date(subTrip.toTime) : null, Validators.required],
                            cost: [subTrip.cost, Validators.required],
                            capacity: [subTrip.capacity, Validators.required],
                            parcelType: [subTrip.parcelTypeId, Validators.required]
                        }), id: subTrip.id
                    });
                }
            );

            if (!this.isEditMode) {
                this.addSubTripAddForm();
            }
        }
        this.display = true;
    }

    deleteSubTrip(subTripId, index) {
        this.tripsBetweenCitiesService.deleteSubTrip(subTripId).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translate.instant('ALERTS.DONE_SUCCESSFULLY')
                });
                this.addTripsForms.splice(index, 1);
            }, error => {
                console.error(error);
            }
        );
    }
}
