<p-sidebar [(visible)]="displaySidebarMenu" position="{{selectedLanguage === 'en' ?'left' : 'right'}}">
    <app-menu-item-component *ngFor="let item of menuItems" [menuItem]="item">
    </app-menu-item-component>
</p-sidebar>
<app-alert-component>
</app-alert-component>
<p-overlayPanel #dropdown [dismissable]="true" [style]="{'top': '85px','margin-top': '15px'}"
                styleClass="profile-popover">
    <ul class="user-options">
        <li class="option cursor-pointer" (click)="goToSettings()">
            <i class="fas fa-user"></i>{{"ACCOUNT.SETTINGS" | translate}}
        </li>
        <i class="option language-switcher">
            <!-- Position fix -->
            {{'GENERAL.LANGUAGE' | translate}} :
            <select style="width: fit-content" [(ngModel)]="selectedLanguage" (change)="onLangChange(dropdown)">
                <option value="ar">
                    {{'GENERAL.AR' | translate}}
                </option>
                <option value="en">
                    {{'GENERAL.EN' | translate}}
                </option>
            </select>
        </i>
        <li class="option cursor-pointer" (click)="logout()">
            <i class="fas fa-sign-out"></i>
            {{"ACCOUNT.LOGOUT" | translate}}
        </li>
    </ul>
</p-overlayPanel>

<p-overlayPanel #notificationPanel [dismissable]="true" for="bell" my="right top" at="right top"
                styleClass="notification-popover" id="notification">
    <div class="notifications-list">
        <div class="main-header">
            <div class="row align-items-center">
                <div class="col-5 text-align-start">
                    <div class="title">{{ 'COMPANY.NOTIFICATIONS' | translate }}</div>
                </div>
                <div class="col-7 p-0 text-align-end">
                    <p class="action"
                       *ngIf="notifications.length > 0 && !isLoadingNotification && !isSettingAllAsRead"
                       (click)="markAllAsRead()">{{ 'ACTIONS.READ_ALL_NOTIFICATIONS' | translate }}</p>
                    <i class="fas fa-spinner fa-pulse" *ngIf="isSettingAllAsRead"></i>
                </div>
            </div>
        </div>
        <div class="loading-container" *ngIf="isLoadingNotification">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div *ngIf="notifications.length == 0 && !isLoadingNotification" class="empty-msg">
            {{ 'ALERTS.NO_NOTIFICATIONS' | translate }}
        </div>
        <ul class="notification-options" *ngIf="notifications.length > 0 && !isLoadingNotification">
            <li class="notification-item cursor-pointer"
                (click)="onClickNotification(item.id, item.packageId, true, item.ticketId ? 'TICKET' : 'PACKAGE', item)" *ngFor="let item of notifications; let i= index"
                [ngClass]="{'read' : item?.isRead}"
            >
                <div class="d-flex">
                    <div>
                        <div class="company-logo contain-image" [ngStyle]="{'background-image': 'url(' + logo + ')'}">
                        </div>
                    </div>
                    <div class="text-container">
                        <div class="notification-body">{{!isEnglishSelected && item.titleArabic ? item?.bodyArabic : item?.body}}</div>
                        <div class="notification-date">
                            {{ item?.createdDate | date:'EEEE d, MMM hh:mm'}}
                        </div>
                    </div>
                    <div>
                        <i class="fas fa-trash delete-icon cursor-pointer" (click)="deleteNotification(item, i)"></i>
                    </div>
                </div>
            </li>
        </ul>
        <div class="load-more" *ngIf="hasMoreNotification">
            <button (click)="loadMoreNotifications()" *ngIf="notifications.length > 0 && !isLoadingNotification && !isLoadingMoreNotification">
                {{ 'ACTIONS.SHOW_MORE' | translate }}
            </button>
            <div class="load-more-spinner" *ngIf="isLoadingMoreNotification">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
        </div>
    </div>
</p-overlayPanel>



<div class="app-container">
    <div class="side-menu-container" [ngClass]="{'wide-menu': !isSideMenuMinimzedFlag, 'narrow-menu': isSideMenuMinimzedFlag, 'shown': displaySideMenuMobile}">
        <app-side-menu-component [menuItems]="menuItems" [logo]="logo" [companyName]="companyName"
                                 (hideSideMenu) ="hideSideMenuMobile($event)"
                                 (isSideMenuMinimized) ="isSideMenuMinimizedHandler($event)">
        </app-side-menu-component>
    </div>
    <div class="content-container">
        <div class="header-menu">
            <div class="menu-icon-container hidden-md-up">
                <i class="fas fa-bars cursor-pointer" (click)="displaySideMenuMobile = true"></i>
            </div>
            <div class="hidden-sm-down" style="width: 26px"></div>
            <div class="package-icon hidden-md-up">
                <span class="fas fa-search"
                      *ngIf="searchText"
                      (click)="onSearch(searchText)"></span>
                <span class="lg lg-track-package" *ngIf="!searchText"></span>
            </div>
            <div class="package-icon flex-2d-center hidden-sm-down">
                <img src="../../assets/icons/package-icon.svg">
            </div>
            <div class="search-field flex-1" [ngClass]="{'has-text': searchText}">
                <input  type="text"
                        placeholder="{{(isDistributor() ? 'PLACEHOLDER.TRACK_ORDER_NUMBER' : 'PLACEHOLDER.TRACK_PACKAGE_NUMBER' ) | translate}}"
                        class="search" #trackerField
                        [(ngModel)]="searchText"
                        (keydown)="onKeypress($event.keyCode, trackerField.value)"
                        (keyup)="onKeypressUp($event.keyCode)"
                />
                <span class="fas fa-times close-icon" *ngIf="searchText" (click)="clearSearch()"></span>
            </div>
            <div class="new-shipments-request-container" *appPermission="showNewRequestsBtn">
                <app-button class="button ghost" [badge]="newRequestsNo" [label]="'USERS_MANAGEMENT.NEW_REQUESTS'" (onClick)="goToManageShipments()"></app-button>
            </div>

            <div class="header-v-line-separator hidden-sm-down" *ngIf="!searchText && canAdd"> </div>
            <div class="header-button hidden-sm-down">
                <app-button class="button primary"  *ngIf="!searchText && canAdd"
                            icon="fas fa-plus"
                            [label]="((user && user.isLcl) ? 'ADD_SHIPMENTS.ADD_ORDER'
                                    : (user && user.isTrucking) ? 'SHIPMENT.ADD_SHIPMENT'
                                    : isDistributor() ? 'ADD_SHIPMENTS.ADD_ORDER' : 'SHIPMENT.ADD')"
                            (onClick)="showAddPackage()"></app-button>

                <a class="d-flex flex-row tracking" (click)="onSearch(searchText)" *ngIf="searchText">
                    <img src="../../assets/icons/track_package/track_icon.svg" alt="">
                    <span class="title flex-1">{{'ADD_SHIPMENTS.PACKAGE_DETAILS' | translate}}</span>
                </a>
            </div>
            <div class="add-package-mobile hidden-md-up">
                <button class="export-option" (click)="showAddPackage()" *ngIf="!searchText && canAdd">
                    <img src="../../assets/icons/add_package/add_mobile.svg" alt="">
                </button>
            </div>
            <div class="header-v-line-separator"></div>
            <div class="header-notification">
                <img src="../../assets/icons/notification.svg"
                     alt="notification"
                     (click)="notificationTrigger($event, notificationPanel)">
                <span class="notification-badge" *ngIf="notificationsNum > 0">{{notificationsNum}}</span>
            </div>
<!--            <div class="header-v-line-separator hidden-sm-down"></div>-->
<!--            <div class="inbox-avatar" *ngIf="canAdd">-->
<!--                <i class="far fa-envelope inbox-avatar-span cursor-pointer" routerLink="inbox"></i>-->
<!--            </div>-->
            <div class="header-v-line-separator hidden-sm-down"></div>
            <div *ngIf="userRole" class="user-role">
                <span>{{userRole | translate}}</span>
            </div>
            <div class="header-menu">
                <div class="min-logo cover-image"
                     [style.backgroundImage]="'url('+ user?.imageUrl + ')'" (click)="profileTrigger($event, dropdown)"></div>
            </div>
            <div class="header-v-line-separator"></div>
            <div class="header-menu tripple-dots-container hidden-md-up">
                <img src="../../assets/icons/tripple_dots.svg" alt="">
            </div>
        </div>
        <div class="header-under-line"></div>
        <div class="page-content">
            <ng-container *ngIf="!isLoading">
                <router-outlet></router-outlet>
            </ng-container>
            <div *ngIf="isLoading">
                <div class="main-spinner-container">
                    <div class="spinner-stage">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--<div class="d-flex flex-column h-100">-->

<!--    <div class="flex-1 overflow-auto">-->
<!--        <div class="body-container h-100 d-flex flex-row">-->
<!--            <div class=" side-bar-container full-height" [ngClass]="{'shown': displaySideMenuMobile}">-->
<!--               <div>-->

<!--               </div>-->
<!--            </div>-->


<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--    <div class="header-menu">-->
<!--        <div class="app-logo">-->
<!--            <div class="menu-icon-container hidden-md-up">-->
<!--                <i class="fas fa-bars cursor-pointer" (click)="displaySidebarMenu = true"></i>-->
<!--            </div>-->
<!--            <div class="powered-by">-->
<!--                <a> <img [src]="getLogoImage()" class="logestechs-img"> </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="app-operations row">-->
<!--            <img *ngIf="canAdd" src="../../assets/icons/shipment-icon.png" class="shipment-icon cursor-pointer"-->
<!--                 (click)="openQuickAdd()">-->
<!--            <div class="inbox-avatar words-first-char-capitalize">-->
<!--                {{user?.role | parseServerConstant}}-->
<!--            </div>-->
<!--            <div class="inbox-avatar">-->
<!--                <i class="fas fa-envelope cursor-pointer" routerLink="inbox"></i>-->
<!--            </div>-->
<!--            <div class="inbox-avatar">-->
<!--                <i class="fas fa-bell cursor-pointer" (click)="notificationTrigger($event, notifucation)"></i>-->
<!--            </div>-->

<!--            <div class="account-avatar" (click)="profileTrigger($event, dropdown)">-->
<!--                <i class="fas fa-user-tie cursor-pointer"></i>-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->

<p-overlayPanel #optionsDropdown [dismissable]="true" styleClass="profile-popover">
    <ul class="user-options">
        <li class="option cursor-pointer" >
            {{"GENERAL.DELETE" | translate}}
        </li>
    </ul>
</p-overlayPanel>


<ng-container *ngIf="!defaultAddPackageForm">
    <app-distributor-package-form *ngIf="displayAddPackage"
                               [display]="displayAddPackage"
                               (showAWBDialogEvent)="showAWBDialog($event)"
                               [packageBarcode] ="selectedPackageBarcode"
                               (closeWidget)="onHideAddPackage($event)">
    </app-distributor-package-form>
</ng-container>
<ng-container *ngIf="defaultAddPackageForm">
    <app-add-package-form-component *ngIf="displayAddPackage"
                                    [display]="displayAddPackage"
                                    (showAWBDialogEvent)="showAWBDialog($event)"
                                    [packageBarcode] ="selectedPackageBarcode"
                                    (closeWidget)="onHideAddPackage($event)">
    </app-add-package-form-component>
</ng-container>


<app-package-print-dialog-component [showAWBModal]="showAWBPrintModal"
                                    [url]="linkAWB"
                                    [titleCode]="titleCode"
                                    [showPrintButton]="showPrintPackagesButton"
                                    (closeDialogEvent)="onHidePrintAWB($event)">
</app-package-print-dialog-component>
<app-shipment-tracker
        [isShow]="isDisplayTracking"
        (onCloseTracker)="closeTracker($event)"
        [packageBarcode]="selectedPackageBarcode"
        [packageId]="selectedPackageId"
        [showHeaderActions] = "!isCustomerAccountManager()"
        class="component">
</app-shipment-tracker>

<app-ticket-details
        [display]="displayTicketDetails"
        [(triggeredTicketId)]="triggeredTicketId"
        (closeWidget)="closeTicketDetails($event)">
</app-ticket-details>
