import {Component, Inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {UpdateModel} from '../../models/update.model';
import {CATEGORIES_OPTIONS} from '../../system-updates-constant';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-add-update-dialog',
    templateUrl: './add-update-dialog.component.html',
    styleUrls: ['./add-update-dialog.component.scss']
})
export class AddUpdateDialogComponent implements OnInit {
    form: any;
    @Input() update: UpdateModel;
    @Input() type;
    isLoading: any;
    isEdit = false;
    currentLang;
    fileName: any;
    arabicFileName: any;
    private file: any;
    private arabicFile: any;
    fileTouched: any;
    arabicFileTouched: any;
    sizeLimitExceeded = false;
    arabicFilesizeLimitExceeded = false;
    readonly MAX_FILE_SIZE = (1024 * 1024 * 10); // 10MB
    currentForm = 'ARABIC';
    categoriesList = CATEGORIES_OPTIONS;
    categoryModel = null;

    constructor(private activeModal: NgbActiveModal,
                private translate: TranslateService,
                private systemUpdatesService: SystemUpdatesService,
                @Inject(FormBuilder) private formsBuilder: FormBuilder) {

    }

    ngOnInit() {

        this.currentLang = this.translate.currentLang;

        this.form = this.formsBuilder.group({
            name: ['', Validators.required],
            summary: ['', Validators.required],
            arabicName: ['', Validators.required],
            arabicSummary: ['', Validators.required],
            category: ['']
        });

        if (this.update) {
            this.isEdit = true;
            this.form.patchValue(this.update);
            if (this.update.videoLink || this.update.arabicVideoLink) {
                this.type = 'VIDEO';
            }
            this.fileName = this.update.fileName;
            this.arabicFileName = this.update.arabicFileName;
        }
        if (this.type == 'VIDEO') {
            this.form.addControl('videoLink', this.formsBuilder.control('', [Validators.required]));
            this.form.addControl('arabicVideoLink', this.formsBuilder.control('', [Validators.required]));
            if(this.isEdit){
                this.categoryModel = this.update.category;
                this.form.patchValue(this.update);
            }
        }
    }

    close(result = false) {
        const queryParams = {};
        for (const field in this.form.controls) {
            const control = this.form.get(field);
            queryParams[field] = control.value;

        }
        this.activeModal.close({added: result});
    }

    isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
        const formControl = this.form.get(formControlName);
        if (formControl) {
            return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
                : formControl.invalid && (formControl.touched || formControl.dirty);
        }
        return false;
    }

    addAttachment(event: Event, fileChooser) {
        event.stopPropagation();
        fileChooser.click();
    }

    onFilesSelected($event: any) {
        if (this.currentForm === 'ENGLISH') {
            this.fileTouched = true;
            this.file = $event.target.files && $event.target.files.length ? $event.target.files[0] : null;
            this.sizeLimitExceeded = false;

            if (this.file) {
                this.fileName = $event.target.files[0].name;
                const size = this.file.size;
                if (size > this.MAX_FILE_SIZE) {
                    this.sizeLimitExceeded = true;
                }
            } else {
                this.fileName = null;
            }
        } else { // Ar form
            this.arabicFileTouched = true;
            this.arabicFile = $event.target.files && $event.target.files.length ? $event.target.files[0] : null;
            this.arabicFilesizeLimitExceeded = false;

            if (this.arabicFile) {
                this.arabicFileName = $event.target.files[0].name;
                const size = this.arabicFile.size;
                if (size > this.MAX_FILE_SIZE) {
                    this.arabicFilesizeLimitExceeded = true;
                }
            } else {
                this.arabicFileName = null;
            }
        }
    }

    addUpdate() {
        this.isLoading = true;
        const queryParams = {};
        for (const field in this.form.controls) {
            const control = this.form.get(field);
            if (!(field === 'category' && (control.value === 'WITHOUT' || control.value == null))) {
                queryParams[field] = control.value;
            }

        }
        if (this.isEdit) {
            forkJoin({
                file: this.systemUpdatesService.uploadUpdateFile(this.file),
                arabicFile: this.systemUpdatesService.uploadUpdateFile(this.arabicFile)}).subscribe((res:any) => {
                queryParams['arabicUpdateUrl'] = res.arabicFile.url || this.update.arabicUpdateUrl;
                queryParams['updateUrl'] = res.file.url || this.update.updateUrl;
                this.systemUpdatesService.updateSystemUpdate( this.update.id, queryParams).subscribe(res1 => {
                    this.close(true);
                }, (error => {
                    this.isLoading = false;
                }));
            });
        } else {
            forkJoin({
                file: this.systemUpdatesService.uploadUpdateFile(this.file),
                arabicFile: this.systemUpdatesService.uploadUpdateFile(this.arabicFile)}).subscribe((res:any) => {
                    queryParams['arabicUpdateUrl'] = res.arabicFile.url;
                    queryParams['updateUrl'] = res.file.url;
                this.systemUpdatesService.createSystemUpdate(queryParams).subscribe(res1 => {
                    this.close(true);
                }, (error) => {
                    this.isLoading = false;

                });
            });

        }
    }
}
