import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

@Directive({
    selector: '[appBarcodeScanner]'
})
export class BarcodeScannerDirective implements AfterViewInit, OnDestroy {
    @Output()
    barcodeRead = new EventEmitter<string>();

    private newUPC = '';
    private keyDownListener;

    constructor(public element: ElementRef) {
    }

    ngAfterViewInit() {
        this.newUPC = '';
        this.keyDownListener = (e: KeyboardEvent) => {
            const textInput = e.key || String.fromCharCode(e.keyCode);
            const a: any = e.target;
            const targetName = a.localName;
            if ((targetName !== 'input' ||
                (a.attributes && Object.values(a.attributes).some((attr: any) => attr.localName === 'type' && attr.value === 'checkbox'))) &&
                (textInput === 'Enter' || textInput === 'Tab' || textInput.match(/^[a-zA-Z0-9]*[/]*[-]?[a-zA-Z0-9]*$/))) {
                if (textInput === 'Enter' || textInput === 'Tab') {
                    if (this.newUPC.startsWith('|')) {
                        this.newUPC = this.newUPC.substring(1);
                    }
                    // newUPC = newUPC.replace(/\D/g, '');
                    this.barcodeRead.emit(this.newUPC);
                    this.newUPC = '';
                }
                if (textInput && textInput.length === 1) {
                    this.newUPC = this.newUPC + textInput;
                }
            }
        };
        document.addEventListener('keydown', this.keyDownListener);
    }

    ngOnDestroy() {
        document.removeEventListener('keydown', this.keyDownListener);
    }

}
