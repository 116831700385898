<div class="hubs-accounting-dashboard h-100">
    <div class="flex-container d-flex h-100">
        <div class="header d-flex">
            <div class="title-tab flex-2d-center">
                <div class="title">المحاسبة/ تحصيلات التصدير والتسليم</div>
                <div class="tabs">الفروع</div>
            </div>
            <div class="filters">
<!--                <div class="date-range-filter d-flex">-->
<!--                        <span (click)="datepicker.click()">-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </span>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                           (showCalendarDaterangepicker)="onShowCalenderRangePicker($event)"-->
<!--                           (hideDaterangepicker)="onHideCalenderRangePicker($event)"-->
<!--                           [options]="dateRangPickerOptions" (selected)="onDateSelected($event, dateRange)"/>-->
<!--                    &lt;!&ndash;                    <img class="arrow-down"  src="../../../assets/icons/arrow-down.png">&ndash;&gt;-->
<!--                    <i class="fas fa-caret-down"></i>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event, dateRange)"></app-date-range-picker>

            </div>
        </div>
        <div class="details d-flex h-100">
            <div class="hubs-list h-100" (scroll)="onLazyLoadHubs($event)">
                <ng-container *ngIf="!isGettingHubs">
                    <div class="hub-item cursor-pointer d-flex" (click)="onSelectHub(hub)"
                         *ngFor="let hub of hubs"
                         [ngClass]="{'active-hub-item': selectedHub && selectedHub.id === hub.id}">
                        <div class="icon">
                            <img class="arrow-down" src="../../../assets/icons/pin.svg">
                        </div>
                        <div class="label">{{hub.name}}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isGettingHubs">
                    <div class="loading-hubs d-flex justify-content-center"
                         *ngFor="let hubLoader of [1,2,3,4,5,6,7,8,9,10]">
                        <app-skeleton-loader [type]="2"></app-skeleton-loader>
                    </div>
                </ng-container>
            </div>
            <div class="packages-and-summery d-flex">
                <div class="packages-container">
                    <div class="packages d-flex h-100">
                        <div class="exported-packages packages-section">
                            <div class="packages-title">طرود مصدرة</div>

                            <div class="packages-cards-container">
                                <ng-container *ngIf="!isGettingExportedPackages">
                                    <app-hub-card *ngFor="let hub of [1, 2, 3, 4, 5]"></app-hub-card>
                                </ng-container>
                                <ng-container *ngIf="isGettingExportedPackages">
                                    <app-skeleton-loader *ngFor="let loader of [1, 2, 3, 4, 5, 6]"
                                                         [dashboardCard]="true" [isSingleLoader]="true"
                                                         [type]="0"></app-skeleton-loader>
                                </ng-container>
                            </div>
                        </div>
                        <div class="imported-packages packages-section">
                            <div class="packages-title">طرود مستقبلة</div>
                            <div class="packages-cards-container">
                                <ng-container *ngIf="!isGettingImportedPackages">
                                    <app-hub-card *ngFor="let hub of [1,2,3,4,5]"></app-hub-card>
                                </ng-container>

                                <ng-container *ngIf="isGettingImportedPackages">
                                    <app-skeleton-loader *ngFor="let loader of [1, 2, 3, 4, 5, 6]"
                                                         [dashboardCard]="true" [isSingleLoader]="true"
                                                         [type]="0"></app-skeleton-loader>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summery flex-2d-center">
                    <ng-container *ngIf="isGettingSummery">
                        <app-skeleton-loader [type]="2"></app-skeleton-loader>
                    </ng-container>
                    <ng-container *ngIf="!isGettingSummery">
                        <div class="summery-item">
                            <div class="value"><span class="currency">{{currency}}</span>2,890</div>
                            <div class="label">مجموع التحصيلات من الافرع</div>
                        </div>

                        <div class="summery-item">
                            <div class="value"><span class="currency">{{currency}}</span>2500</div>
                            <div class="label">مجموع المبالغ للدفع</div>
                        </div>

                        <div class="summery-item">
                            <div class="value">451</div>
                            <div class="label">عدد الطرود المصدرة</div>
                        </div>

                        <div class="summery-item">
                            <div class="value">120</div>
                            <div class="label">عدد الطرود المستوردة</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
