import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {MenuItemModel} from '../models/menu-item.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountComponent} from '../../account/account.component';
import {HomeService} from '../services/home.service';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';

@Component({
    selector: 'app-menu-item-component',
    templateUrl: 'menu-item.component.html',
    styleUrls: ['menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
    @Input() menuItem: MenuItemModel;
    @Input() level = 0;
    @Output() subMenuShow = new EventEmitter();
    public show = false;
    public hasActiveChild = false;
    constructor(private modalService: NgbModal,
                private homeService: HomeService,
                private router: Router,
                private userService: UserService) {
        // todo: close all opened menu when is a new one opened
        /*homeService.menuChange.subscribe(menuItem => {
         });*/
    }

    ngOnInit() {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(e => {
                if (!this.menuItem.subItems) {
                    this.isActiveMenu();
                } else {
                    this.hasActiveChild = this.show = false;
                }
            });
        this.isActiveMenu();
    }

    isActiveMenu() {
        const routes = this.router.url.split('/');
        let route = routes.find(route => {
            return route.startsWith(this.menuItem.path);
        });
        if (route) {
            this.subMenuShow.emit(true);
        }
    }

    onSubMenuShow(event) {
        if (event) {
            this.subMenuShow.emit(true);
            this.hasActiveChild = this.show = true;
        }
    }

    public openModal() {
        let component;
        switch (this.menuItem.component) {
            case 'AccountComponent':
                component = AccountComponent;
        }
        const modalRef = this.modalService.open(component, <any>{size: 'md'});
    }

    toggleMenuVisibility() {
        this.show = !this.show;
        //this.subMenuShow.emit(this.show);
        //todo: notifay all menu about the new opened menu item
        /*if (this.show) {
         this.homeService.menuChange.next(this.menuItem);
         }*/
    }

    getMenuHeight(menuItem) {
        let height = 75;
        if (menuItem.subItems) {
            for (const subItem in menuItem.subItems) {
                height += this.getMenuHeight(menuItem.subItems[subItem]);
            }
        }
        return height;
    }
}
