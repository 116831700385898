<div class="d-flex flex-column h-100">
    <div class="d-flex flex-row">
        <div style="flex: 1"></div>
        <div style="padding: 5px 10px" class="d-flex flex-row">
           <!-- <button type="button" class="btn-sm btn btn-primary" (click)="addColor()">
                {{"THEME.ADD_NEW_COLOR" | translate}}
            </button>-->
            <div style="width: 10px"></div>
            <div [ngClass]="{'disabled-btn disabled': Form.invalid || disableSave}"
                 (click)="save()"
                 class="save-btn">
                {{"THEME.SAVE" | translate}}
            </div>
        </div>
    </div>
    <form #Form="ngForm" class="shipments-table flex-1 overflow-auto" appPTableRowsCalculator [(pageSize)]="pageSize">
        <p-table dataKey="id" #table [value]="colors" styleClass="packages-table flex-table {{Form.valid ? '' : 'table-disabled-paginator' }}"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [paginator]="true" [responsive]="true" [rows]="pageSize" [totalRecords]="totalRecords">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th> {{"THEME.COLOR_NAME" | translate}} </th>
                    <th> {{"THEME.COLOR_VALUE" | translate}} </th>
                    <th> {{"THEME.COLOR_DESCRIPTION" | translate}} </th>
                    <!--<th  style="width: 60px"> </th>-->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-color let-columns="columns">
                <tr>
                    <td>
                       {{color.colorKey}}
                    </td>
                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <div class="d-flex flex-row">
                                    <p-colorPicker name="colorValue" [(ngModel)]="color.colorValue"></p-colorPicker>
                                    <input required style="flex: 1" name="colorValue" pInputText type="text" pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" [(ngModel)]="color.colorValue">
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{color.colorValue}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText required name="description" type="text" [(ngModel)]="color.description">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{color.description}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <!--<td style="width: 60px">
                        <div class="button-group d-flex">
                            <button type="button" class="button-small btn fas fa-times btn-outline-danger"
                                    (click)="discardColor(color)"></button>
                        </div>
                    </td>-->
                </tr>
            </ng-template>
        </p-table>
    </form>
</div>
