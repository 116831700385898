import {Routes} from '@angular/router';
import {ManageCompaniesComponent} from '../manage-companies/manage-companies.component';

export const COMPANY_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'pending',
        pathMatch: 'full'
    },
    {
        path: 'pending',
        component: ManageCompaniesComponent,
        data: {
            isPending: true
        }
    },
    {
        path: 'accepted',
        component: ManageCompaniesComponent,
        data: {
            isPending: false
        }
    }
];
