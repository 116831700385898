<div class="expenses-type-add d-flex flex-column" [ngClass]="{'page-ltr': currentLang === 'en'}">

    <div class="close-icon cursor-pointer">
        <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt=""/>
    </div>
    <div class="title">{{"EXPENSES.ADD_TYPE_EXPENSES" | translate }}</div>

    <div class="form-container">
        <form [formGroup]="form">
            <div class="expense-name d-flex w-100">
                <div class="label">{{'EXPENSES.NAME' | translate}}</div>
                <div class="input-value w-100">
                    <app-input-field-component [form]="form"
                                               class="w-100"
                                               controlName="name"
                                               controlType="text">
                    </app-input-field-component >
                </div>

            </div>
            <div class="bottom-actions d-flex justify-content-end">
                <div class="reset-button cursor-pointer " (click)="closeModal()">
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
                <div class="add-button cursor-pointer "
                     (click)="choose()"
                     [ngClass]="{'disabled-button disabled': !form.valid}">
                    {{'ACTIONS.DONE' | translate }}
                </div>
            </div>
        </form>
    </div>
</div>
