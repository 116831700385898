<div class="close-icon" (click)="closeModal()"><img src="../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="row d-inline-flex main-container flex-column w-100">
        <div class="content-header-container d-flex justify-content-start">
            <div class="page-header-title">
                <div class="title">{{'DISTRIBUTOR_REPORTS.ORDER_CONTENTS' | translate}}</div>
                <div class="total-number-badge">{{totalRecordsNo}}</div>
            </div>
        </div>
        <div class="table-container d-flex w-100"
             (scroll)="onTableScroll($event)">
            <p-table [value]="dataList" [loading]="isLoading" class="entities-table-component w-100"
                     [responsive]="true" scrollHeight="auto" styleClass="entities-table w-100">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.ITEM' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.BOXES' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_BOXES' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.CARTOONS' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_CARTOONS' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.PACKAGES' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_PACKAGES' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.PALLETS' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_PALLETS' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.UNIT' | translate}}</th>
                        <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_UNIT' | translate}}</th>
                        <th>
                            <div>{{'DISTRIBUTOR_REPORTS.TABLE.WEIGHT' | translate}}</div>
                            <div>({{'UNITS.KG' | translate}})</div>
                        </th>
                        <th>
                            <div>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_WEIGHT' | translate}}</div>
                            <div>({{'UNITS.KG' | translate}})</div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            <div class="d-flex pl-2 pr-2">
                                {{item.itemName}}
                            </div>
                        </td>
                        <td>{{item.boxes}}</td>
                        <td>{{item.originalBoxes}}</td>
                        <td>{{item.cartons}}</td>
                        <td>{{item.originalCartons}}</td>
                        <td>{{item.packages}}</td>
                        <td>{{item.originalPackages}}</td>
                        <td>{{item.pallets}}</td>
                        <td>{{item.originalPallets}}</td>
                        <td>{{item.unit}}</td>
                        <td>{{item.originalUnit}}</td>
                        <td>{{item.weight}}</td>
                        <td>{{item.originalWeight}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
