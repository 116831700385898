import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {UserService} from '../services/user.service';

@Pipe({
    name: 'hubTitle'
})
@Injectable()
export class HubTitlePipe implements PipeTransform {

    constructor(private userService: UserService) {
    }
    public transform(value: string, args?: any): any {
        let result = value;
        if (this.userService.userInfo.isLcl) {
            result = result.replace('Hub', 'Branch');
            result = result.replace('hub', 'branch');
            result = result.replace('Hubs', 'Branches');
            result = result.replace('hubs', 'branches');
        }
        return result;
    }
}
