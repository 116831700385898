<div class="shelves-container">
    <div class="row actions-and-total-records" style="justify-content: space-between; width: 100%;">
        <div class="title-number-container d-flex">
            <div class="shelves-title">{{ 'SHELVES_MANAGEMENT.TITLE' | translate }}</div>
            <div style="width: 12px;"></div>
            <div class="shelves-count">
                <span>{{totalRecords}}</span>
            </div>
        </div>
        <div class="shelves-actions-container d-flex">
            <div class="action print-shelve d-inline-flex" [ngClass]="{'disabled-btn disabled': !(getSelectedShelves().length)}" (click)="print()">
                <div *ngIf="!printLoader" class="icon">
                    <img src="../../assets/icons/containers-management/print.svg" alt="" />
                </div>
                <div class="loading" *ngIf="printLoader">
                    <i class="fas fa-spinner fa-pulse"></i>
                </div>
                <div style="width: 10px;"></div>
                <div class="label">{{ 'ACTIONS.PRINT' | translate }}</div>
            </div>
            <div style="width: 18px;"></div>
            <div class="action add-shelve d-inline-flex" (click)="onNewShelf()">
                <div class="icon">
                    <img src="../../assets/icons/plus.svg" alt="" />
                </div>
                <div style="width: 17px;"></div>
                <div class="label">{{ 'SHELVES_MANAGEMENT.ACTIONS.NEW_SHELF' | translate }}</div>
            </div>
        </div>
    </div>
</div>
<div class="shelves-list-container" (scroll)="onScrollCards($event)" [ngClass]="{'shelves-list-container-full-details' : isShowFullHubDetails}">
    <div *ngIf="!shelves.length" class="d-flex no-data-available-container" style="display: flex; place-items: center;">
        <span>{{ 'SHELVES_MANAGEMENT.NO_SHELVES' | translate }}</span>
    </div>
    <div *ngIf="shelves.length" class="shelves-cards">
        <app-branch-card-component
                [hub]="hub"
                [isShelve]="true"
                [shelve]="shelve"
                (onSelectShelf)="updateSelectedShelves($event)"
                (onCopyBarcode)="copyBarcode($event.barcode)"
                (onModifyShelve)="modifyShelve($event)"
                (onRemoveShelve)="removeShelf($event)"
                *ngFor="let shelve of shelves">
        </app-branch-card-component>
    </div>
</div>
