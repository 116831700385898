import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {UpdateModel} from '../../models/update.model';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {Observable} from 'rxjs';
import {AddUpdateDialogComponent} from '../add-update-dialog/add-update-dialog.component';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
    selector: 'app-documents-system-updates',
    templateUrl: './documents-system-updates.component.html',
    styleUrls: ['./documents-system-updates.component.scss']
})
export class DocumentsSystemUpdatesComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {
    pageSize = 10;
    totalNumberOfUpdates: any;
    isSuperAdmin: boolean;
    private pageNumber = 0;
    public spinnerState = SpinnerState.LOADING;
    public tableColumnCount = 4;
    public updatesData: UpdateModel[] = [];
    public recentlyAdded: UpdateModel[] = [];
    public spinnerStates = SpinnerState;
    public selectedLanguage = 'ar';

    private hasMore: Boolean | boolean = true;
    private isMobileResolution: boolean;
    searchContent: any;
    permissions = '';
    markAllAsSeenRequest = false;
    disableMarkAllAsRead = false;
    loadingUpdates = false;
    @Input('filter') filter: Observable<any>;
    @Input('addedOrUpdateEvent') addedOrUpdateEvent: Observable<any>;
    @Output('totalChange') totalChange = new EventEmitter<any>();
    @ViewChild('updateOptions') updateOptions;
    private editedUpdateIndex: number;

    gettingRecentlyAdded = false;
    private update: any;

    constructor(private modalService: NgbModal,
                private authenticationService: AuthenticationService,
                private router: Router,
                public translateService: TranslateService,
                private confirmService: ConfirmationService,
                private systemUpdatesService: SystemUpdatesService,
                private userService: UserService,
                private rolesService: RolesService,
                private route: ActivatedRoute,
                private applicationStateService: ApplicationStateService) {
        super();
        this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
        this.tableColumnCount = this.isSuperAdmin ? 4 : 3;
        this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
        this.permissions = this.rolesService.getUserPermissions('SYSTEM_UPDATES', this.userService.userInfo.role);
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        this.getRecentlyAdded();
        this.filter.subscribe(filter => {
            this.searchContent = filter.search;
            this.loadUpdatesLazy(true);
        });
        if (this.addedOrUpdateEvent) {
            this.subscriptions.push(this.addedOrUpdateEvent.subscribe(val => {
                this.getRecentlyAdded();
                this.loadUpdatesLazy(true);
            }));
        }
    }

    @HostListener('scroll', ['$event'])
    onScrollDocuments(event) {
        this.handleUpdatesLoading(event);
    }

    handleUpdatesLoading(event) {
        if (this.loadingUpdates || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
            return;
        }

        if (event.target.offsetHeight + event.target.scrollTop > event.target.scrollHeight - 20) {
            this.pageNumber++;
            this.loadUpdatesLazy();
            this.loadingUpdates = true;

        }
    }

    loadUpdatesLazy(forceFetch = false) {
        if (forceFetch) {
            this.pageNumber = 0;
            this.updatesData = [];
        }
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
            type: 'DOCUMENT'
        };
        if (this.searchContent) {
            params['search'] = this.searchContent;
        }

        this.spinnerState = SpinnerState.LOADING;
        this.systemUpdatesService.loadSystemUpdate(params, this.isSuperAdmin).subscribe((res: any) => {
                this.totalNumberOfUpdates = res.totalRecordsNo;
                this.totalChange.emit(this.totalNumberOfUpdates);
                this.updatesData.push(...res.data);
                this.hasMore = this.updatesData && (this.updatesData.length < res.totalRecordsNo);
                this.loadingUpdates = false;

                this.spinnerState = SpinnerState.LOADED;
                if (this.updatesData.length === 0) {
                    this.spinnerState = SpinnerState.EMPTY;
                }

            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    getRecentlyAdded() {
        this.gettingRecentlyAdded = true;
        this.systemUpdatesService.getRecentlyAdded().subscribe(
            (res: any) => {
                this.recentlyAdded = res.data;
                this.gettingRecentlyAdded = false;
            }, err => {
                this.gettingRecentlyAdded = false;
                console.error(err);
            }
        );
    }

    openPdf(update) {
        this.router.navigate([update.id], {relativeTo: this.route});
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    editUpdate() {
        this.updateOptions.hide();
        const modal = this.modalService.open(AddUpdateDialogComponent, <any>{
            size: 'md',
            data: this.update
        });
        this.editedUpdateIndex = this.updatesData.findIndex((value) => {
            return value.id === this.update.id;
        });
        modal.componentInstance.update = this.update;
        modal.componentInstance.type = 'PDF';
        modal.result.then(result => {
            if (result && result.added) {
                this.recentlyAdded = [];
                this.getRecentlyAdded();
                this.loadUpdatesLazy(true);
            }
        });

    }

    removeUpdate() {
        this.updateOptions.hide();
        this.translateService.get(
            ['ALERTS.CONFIRM_DELETE_UPDATE', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    const message = data['ALERTS.CONFIRM_DELETE_UPDATE'];
                    const yes = data['GENERAL.YES'];
                    const no = data['GENERAL.NO'];
                    this.confirmService.confirm({
                        message: message,
                        accept: () => {
                            this.systemUpdatesService.removeSystemUpdate(this.update.id).subscribe(r => {
                                this.recentlyAdded = [];
                                this.getRecentlyAdded();
                                this.loadUpdatesLazy(true);
                            });
                        },
                        reject: () => {

                        },
                        acceptLabel: yes,
                        rejectLabel: no
                    });
                }
            );

    }

    triggerUpdateDropdown($event: MouseEvent, dropdown: OverlayPanel, update) {
        this.update = update;
        dropdown.toggle($event);
        $event.stopPropagation();
    }
}
