import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ContainersService} from '../../../containers/services/containers.service';
import {HubModel} from '../../../shared/models/hub.model';

@Component({
    selector: 'app-branch-details',
    templateUrl: 'branch-details.component.html',
    styleUrls: ['branch-details.component.scss']
})
export class BranchDetailsComponent implements OnInit {
    public currentLang = '';
    public deleteHubLoader = false;
    public showTableDetails = true;
    @Input() isWarehouse = false;
    @Input() isStations = false;
    @Input() isShow: boolean;
    @Input() hub: HubModel;
    @Input() permissions;

    @Output() onCloseHubDetails = new EventEmitter();
    @Output() openEditDialog = new EventEmitter();
    // Outputs
    @Output() onModifyHub: EventEmitter<{ hub: HubModel }> =
        new EventEmitter<{ hub: HubModel }>();
    @Output() onRemoveHub: EventEmitter<{ hub: HubModel }> =
        new EventEmitter<{ hub: HubModel }>();
    @Output() onCopyBarcode: EventEmitter<{ barcode: string }> =
        new EventEmitter<{ barcode: string }>();

    constructor(private containerService: ContainersService,
                private translateService: TranslateService) {}
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }
    public modifyHub() {
        this.onClose();
        this.onModifyHub.emit({hub: this.hub});
    }
    public removeHub() {
        this.onClose();
        this.onRemoveHub.emit({hub: this.hub});
    }
    public copyBarcode(barcode) {
        this.onCopyBarcode.emit({barcode: barcode});
    }

    public getAddress(address) {
        return address ?  address.village + ' - ' + address.city + ' - ' + address.region : '-';
    }
    public onClose() {
        this.onCloseHubDetails.emit(false);
    }
    public onToggleDetails() {
        this.showTableDetails = !this.showTableDetails;
    }
}
