export const TRIPS_BETWEEN_CITIES_CONSTANTS = {
    MAIN_TRIPS: ':api/admin/cities/trips/v2',
    ADD_SUB_TRIP: ':api/admin/cities/trips',
    DELETE_MAIN_TRIP: ':api/admin/cities/mainTrips/{tripId}',
    DELETE_SUB_TRIP: ':api/admin/cities/trips/{tripId}',
    UPDATE_SUB_TRIP: ':api/admin/cities/trips/{tripId}',
    ADD_MAIN_TRIP: ':api/admin/cities/mainTrips',
    UPDATE_MAIN_TRIP: ':api/admin/cities/updateMainTrips/{tripId}'
};
export const DAYS = [
    'SATURDAY', 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'
];
