import {Component, OnInit, ViewChild} from '@angular/core';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {UpdateModel} from '../../models/update.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {updatesBadgeOp} from '../../../shared/services/shared_constants/constants';
import {AddUpdateDialogComponent} from '../add-update-dialog/add-update-dialog.component';

@Component({
  selector: 'app-system-updates-v2',
  templateUrl: './system-updates-v2.component.html',
  styleUrls: ['./system-updates-v2.component.scss']
})
export class SystemUpdatesV2Component implements OnInit {

  pageSize = 10;
  totalNumberOfUpdates: any;
  isSuperAdmin: boolean;
  private rowIndex: any;
  private update: any;
  private pageNumber = 0;
  public spinnerState = SpinnerState.LOADING;
  public tableColumnCount = 4;
  public updatesData: UpdateModel[] = [];
  public spinnerStates = SpinnerState;
  public selectedLanguage = 'ar';
  @ViewChild('loadingTrigger') loadingTrigger;
  @ViewChild('tableContainer') tableContainer;

  private isMobileResolution: boolean;
  isSearchContent: any;
  searchContent: any;
  selectedTab = 'VIDEO';
  tabs = ['VIDEO', 'DOCUMENT'];
  permissions = '';
  markAllAsSeenRequest = false;
  disableMarkAllAsRead = false;
  documents: any;
  videos: any;
  private filter = {
    search: ''
  };
  filterOb = new BehaviorSubject(this.filter);
  badgeObservable = updatesBadgeOp.asObservable() ;
  updateAddedOrUpdated = new Subject();

  constructor(private modalService: NgbModal,
              private authenticationService: AuthenticationService,
              private router: Router,
              public translateService: TranslateService,
              private confirmService: ConfirmationService,
              private systemUpdatesService: SystemUpdatesService,
              private userService: UserService,
              private rolesService: RolesService,
              private route: ActivatedRoute,
              private applicationStateService: ApplicationStateService) {
    this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
    this.tableColumnCount = this.isSuperAdmin ? 4 : 3;
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.permissions = this.rolesService.getUserPermissions('SYSTEM_UPDATES', this.userService.userInfo.role);
  }

  ngOnInit() {
    this.selectedLanguage = this.translateService.currentLang;
    this.documents = this.route.snapshot.data['documents'];
    this.videos = this.route.snapshot.data['videos'];
    // updatesBadgeOp.subscribe(val => {
    //     this.disableMarkAllAsRead = val === 0;
    // });
  }

    onSearch() {
      this.filter.search = this.searchContent;
      this.filterOb.next(this.filter);
    }

    onChangeTab(path: string) {
      this.router.navigate([this.authenticationService.companyName + 'home/' + path]);

    }

  openAddUpdateDialog(type) {

    const modal = this.modalService.open(AddUpdateDialogComponent, <any>{
      size: 'md'
    });
    modal.componentInstance.type = type;

    modal.result.then(result => {
      if (result && result.added) {
        // this.filterOb.next({...this.filter});
        this.updateAddedOrUpdated.next(true);
      }
    });

  }

  totalChange($event: any) {
    this.totalNumberOfUpdates = $event;
  }
}
