import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyNotificationsService} from '../services/company-notifications.service';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {UsersService} from '../../users/services/users.service';
import {SMSGatewaySettingsComponent} from './sms-gateway-settings/sms-gateway-settings.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {StorageService} from '../../shared/services/storage/storage.service';
import {STORAGE_KEYS} from '../../shared/services/storage/storage.keys';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UserService} from '../../shared/services/user.service';
@Component({
    selector: 'app-company-sms',
    templateUrl: './company-sms.component.html',
    styleUrls: ['./company-sms.component.scss']
})

export class CompanySmsComponent implements OnInit {

    public templateText = '';
    public siteTags = [];
    public customers: {id: number, name: String}[];
    public selectedCustomers: {id: number, name: String}[];
    public customerDropdownSettings = {};
    public spinnerState = SpinnerState.LOADING;
    public PAGE_SIZE = 50;
    public driverId = 0;
    public SMSType = 'CUSTOMERS';
    public title = 'Releans SMS';
    public headerMessage = 'COMPANY.RELEANS_MESSAGE';
    public isLoading = false;
    public isSendToAllCustomer = false;
    public currentLang = '';
    public currency;
    public SMSBalance = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;

     @ViewChild('textArea', {static: false}) textArea: ElementRef;
    constructor(private activeModal: NgbActiveModal,
                private companyNotificationsService: CompanyNotificationsService,
                private usersService: UsersService,
                private userService: UserService,
                private modalService: NgbModal,
                private translateService: TranslateService,
                private messageService: MessageService,
                private storageService: StorageService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.currentLang =  this.translateService.currentLang;
        this.currency =  this.userService.getCurrency();
        if (this.currency === 'NIS') {
            this.title = 'COMPANY.HOT_SMS';
            this.headerMessage = 'COMPANY.HOT_SMS_MESSAGE';
        } else if (this.currency === 'BHD') {
            this.title = 'GatewayAPI';
        }
        this.getSiteTags();
        this.getTemplate();
        this.setCustomerDropDownSettings();
        this.getSMSBalance();
    }
    private getSMSBalance() {
        this.companyNotificationsService.getSMSBalance().subscribe(
            (response: {balance: any}) => {
                   this.SMSBalance = response.balance;
            }
        );
    }
    public onClickCustomersList() {
        this.getCustomers();
    }
    public setCustomerDropDownSettings() {
        this.customerDropdownSettings =  {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            enableCheckAll: false
        };
    }
    public confirmSendSMS() {
        this.translateService.get(['ALERTS.SEND_SMS_CONFIRMATION_MESSAGE', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.SEND_SMS_CONFIRMATION_MESSAGE'],
                accept: () => {
                    this.sendSMS();
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    private sendSMS() {
        this.isLoading = true;
        let sendSms;
        if (this.SMSType === 'CUSTOMERS') {
            let ids = [];
            if (!this.isSendToAllCustomer) {
                ids = this.selectedCustomers.map(
                    (cus: {id: number , name: String } ) => {
                        return cus.id;
                    }
                );
            }
            sendSms =  this.companyNotificationsService.sendSMSToCustomers(ids, this.templateText, this.isSendToAllCustomer);
        } else {
            sendSms = this.companyNotificationsService.sendSMSToReceiverOfDriverPkgs(this.driverId, this.templateText);
        }
        sendSms.subscribe(
            (response: any) => {
                this.translateService.get(
                    'ALERTS.MESSAGES_SENT_SUCCESSFULLY'
                ).subscribe(
                    (data) => {
                        this.isLoading = false;
                        this.messageService.add({severity: 'success', detail: data});
                        this.getSMSBalance();
                    }
                );
            }, err => {
                this.isLoading = false;
                console.error(err);
            }
        );
    }

    public setTag(tag) {
        const myField = this.textArea.nativeElement;
        if (myField.selectionStart || myField.selectionStart === '0') {
            const startPos = myField.selectionStart;
            const endPos = myField.selectionEnd;
            myField.value = myField.value.substring(0, startPos)
                + tag
                + myField.value.substring(endPos, myField.value.length);
        } else {
            myField.value += tag;
        }
        this.templateText = myField.value;
    }

    public getSiteTags() {
        this.companyNotificationsService.getSiteTags().subscribe((data: any[]) => {
            this.siteTags = data;
        });
    }
    public getCustomers(search?: string) {
        this.spinnerState = SpinnerState.LOADING;
        const query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=20';
        this.usersService.getCustomersDropDown(query).subscribe(
            (response: any) => {
                this.customers = response.map(user => {
                    return this.getCustomerObject(user);
                });
                if (this.customers && this.customers.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (err) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );

    }
    public getCustomerObject(user) {
        return {
            name: user.firstName + (user.middleName ? (' ' + user.middleName + ' ') : ' ') + user.lastName +
                (user.businessName ? (' (' + user.businessName + ')') : ''),
            id: user.id,
        };
    }
    public onDriverSelected(driverId) {
        this.driverId = driverId;
    }

    public addSettingsOfSMS() {
        this.isLoading = true;
        const modal = this.modalService.open(SMSGatewaySettingsComponent, {
            backdrop: 'static',
            windowClass: 'edit-template',
            size: 'lg'

        });
         modal.result.then(
            (status: any) => {
                if (status.isSuccess) {
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                }
            }
        );
    }

    public getTemplate() {
        const event = this.SMSType === 'CUSTOMERS' ? 'CUSTOMERS_MESSAGE' : 'DISTRIBUTE_BY_DRIVER' ;
        this.companyNotificationsService.getTemplate(event).subscribe(
            (response: any) => {
                if (response.template !== undefined && response.template !== null) {
                    this.templateText = response.template;
                }
             }
        );
    }
    public isDisableSendSMSBtn() {
       if (this.SMSType === 'CUSTOMERS') {
           if (this.isSendToAllCustomer) {
               return this.templateText.length === 0;
           } else {
               return this.selectedCustomers === undefined || this.selectedCustomers.length === 0 || this.templateText.length === 0;
           }
       } else {return this.driverId === 0 || this.templateText.length === 0; }
    }
    public filterCustomers(customersEl) {
         this.getCustomers(customersEl.filter.text);
    }
    public onToggleSendToAll() {
        if (this.isSendToAllCustomer) {
           this.selectedCustomers = [];
        }
    }
}
