import {Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UsersService} from '../services/users.service';
import {CodCustomer} from '../../shared/models/cod-customer';
import {DELAYED_COD_REPORT_DATE_FILTERS_LIST} from '../../shipment/services/constants';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {SharedService} from '../../shared/services/shared-service';
import {DatePipe} from '@angular/common';
import {UserService} from '../../shared/services/user.service';

@Component({
    selector: 'app-delayed-cod-report',
    templateUrl: './delayed-cod-report.component.html',
    styleUrls: ['./delayed-cod-report.component.scss']
})
export class DelayedCodReportComponent implements OnInit {

    isLoading = false;
    isAllSelected = false;
    currentLang;
    reports: CodCustomer[] = [];
    fetchedReports: CodCustomer[] = [];
    totalRecords: number;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    currentPage = 0;
    pageSize = 20;
    delayedDaysNo = 2;
    selectedDateFilter = '';
    dateFiltersList;
    dateRangPickerOptions;
    fromDate;
    toDate;
    customerId;
    searchContent;
    debounceUpdateCost;
    isSearchContent = false;
    isTrackingPackage;
    triggeredDropdownShipment: any = {};
    driverId;

    @ViewChild('filtersFormOverLay') filtersFormOverLay;
    @ViewChild('userFilter') userFilter;
    filterFormOn = false;

    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private datePipe: DatePipe,
                private usersService: UsersService,
                private userService: UserService,
                private sharedService: SharedService,
                private dateRangePickerService: DateRangePickerService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.dateFiltersList = this.setDateFiltersList();
        this.loadLazyReports();
    }

    onDateFilterChange() {
        if (this.selectedDateFilter === 'NONE') {
            this.fromDate = '';
            this.toDate = '';
        }
        this.loadLazyReports(true);
    }
    showFiltersMenu(event) {
        this.filtersFormOverLay.toggle(event);
        this.filterFormOn = true;
        event.stopPropagation();
    }

    resetFilters() {
        this.delayedDaysNo = 2;
        this.userFilter.selectedUser = null;
        this.customerId = null;
        this.loadLazyReports(true);
    }
    onFilter() {}

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.loadLazyReports(true);
    }

    setDateFiltersList() {
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });

        return DELAYED_COD_REPORT_DATE_FILTERS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    createQuery() {
        let query = '?pageSize=' + this.pageSize + '&page=' + (this.currentPage) + '&delayed-days-no=' + this.delayedDaysNo;
        if (this.searchContent) {
            query += '&search=' + this.searchContent;
        }
        if (this.customerId) {
            query += '&customerId=' + this.customerId;
        }
        if (this.driverId) {
            query += '&driverId=' + this.driverId;
        }
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            query += '&dateFilterType=' + this.selectedDateFilter;
        }
        if (this.fromDate) {
            query += '&fromDate=' + this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            query += '&toDate=' + this.transformDate(this.toDate);
        }
        return query;
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    fetchDelayedReports() {
        const query = this.createQuery();
        this.fetchedReports = [];
        if (this.reports.length === this.totalRecords && this.currentPage > 1) {
            return;
        }
        this.isLoading = true;
        this.usersService.getDelayedReports(query).subscribe(
            (response: any) => {
                this.fetchedReports = response.data;
                this.reports = [...this.reports, ...this.fetchedReports];
                this.totalRecords = response.totalRecordsNo;
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    onApplyFilter() {
        this.loadLazyReports(true);
        this.filtersFormOverLay.hide();
    }

    loadLazyReports(forceFetch = false) {
        if (forceFetch) {
            this.currentPage = 0;
            this.reports = [];
        }
        if (!this.isLoading) {
            this.currentPage++;
            this.fetchDelayedReports();
        }
    }

    @HostListener('scroll', ['$event'])
    onScrollTable(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 20) {
            this.loadLazyReports();
        }
    }

    setTriggeredReport() {
    }

    onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }

        this.reports.forEach((report: any) => {
            report.isSelected = this.isAllSelected;
        });
    }

    copyMessage(event, barcodeNumber) {
        this.sharedService.copyMessage(event, null, barcodeNumber, this.translateService.instant('ALERTS.COPIED'));
    }

    updateReceivedDaysNumber(number) {
        this.delayedDaysNo += number;
        if (this.delayedDaysNo <= 0) {
            this.delayedDaysNo = 1;
        }
    }

    isCustomerAccountManager() {
        return this.userService.userInfo.role === 'CUSTOMER_ACCOUNT_MANAGER';
    }

    trackShipment(shipment = null) {
        if (!shipment) {
            return;
        }
        this.triggeredDropdownShipment = shipment;
        this.isTrackingPackage = true;
    }

    public closeTracker($event) {
        this.isTrackingPackage = $event;
    }

    onSelectCustomer(id) {
        this.customerId = (id === undefined ? '' : id);
    }

    onSelectDriver(id) {
        this.driverId = id;
        this.loadLazyReports(true);
    }

    isShowCommissionFees() {
        return this.userService.userInfo.isEnableCustomerCommissionFee;
    }
}
