import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SideMenuItemInterface} from './side-menu-item/models/side-menu-item.interface';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-side-menu-component',
    templateUrl: 'side-menu.component.html',
    styleUrls: ['side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
    @Input() menuItems: SideMenuItemInterface[];
    @Input() logo = '';
    @Input() companyName = '';
    @Output() hideSideMenu: EventEmitter<boolean> = new EventEmitter();
    @Output() isSideMenuMinimized: EventEmitter<boolean> = new EventEmitter();
    minimizeMenu = false;
    currentLang;
    version = environment['version'];
    constructor(private translate: TranslateService) {
    }
    ngOnInit() {
        this.currentLang = this.translate.currentLang;
    }

    public hideMenu() {
        this.hideSideMenu.emit(false);
    }

    public toggleSideMenu() {
        this.minimizeMenu = !this.minimizeMenu;
        this.isSideMenuMinimized.emit(this.minimizeMenu);
    }
    public getIsMobileResolution(): boolean {
        return true;
    }
    public onItemSelected(event) {
        if (this.getIsMobileResolution()) {
            this.hideMenu();
        }
     }
}
