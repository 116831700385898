import { Component, HostListener, Input, OnInit} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {UserModel} from '../../../shared/models/user.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UserStatus} from '../../../shared/enums/UserStatus';

@Component({
    selector: 'app-cards-slider-component',
    templateUrl: 'cards-slider.component.html',
    styleUrls: ['cards-slider.component.scss']
})
export class CardsSliderComponent implements OnInit {
    @Input() isCustomer: boolean;
    @Input() method: string;
    @Input() query: string;
    @Input() tabRoleParam: string;
    @Input() onStatusUpdate;
    public pageSize;
    public pageNumber = 1;
    public newCustomers = [];
    public totalRecordsNo = 0;
    public isLoading = false;
    public lastPage;
    public currentLang = '';
    constructor(private usersService: UsersService,
                private confirmService: ConfirmationService,
                private translateService: TranslateService,
                private messageService: MessageService) {}
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.pageSize = this.setPageSize();
        this.initNewRequests();
    }
    @HostListener('window:resize', ['$event'])
    onResize() {
        const newPageSize  = this.setPageSize();
        if (newPageSize !== this.pageSize) {
            this.pageSize = newPageSize;
            this.newCustomers = [];
            this.initNewRequests();
        }
    }
    public setPageSize() {
        if (window.innerWidth > 1650) {
            return 4;
        } else {
            if (window.innerWidth < 1650 && window.innerWidth > 1200) {
                return  3;
            } else if ( window.innerWidth <= 1200) {
                return 4;
            }
        }
    }
    private createURLQuery() {
        let query = `?pageSize=${this.pageSize}&page=` + (this.pageNumber) + '&status=' + UserStatus.PENDING;
        if (this.tabRoleParam) {
            query += '&userRole=' + this.tabRoleParam;
        }
        return query;
    }
    public initNewRequests() {
        this.isLoading = true;
        this.usersService[this.method](this.createURLQuery()).subscribe(
            (response:  { customers?: any[], users?: any[], totalRecordsNo: number }) => {
                this.newCustomers = this.tabRoleParam === 'DRIVER' ? response.users : response.customers;
                this.totalRecordsNo = response.totalRecordsNo;
                this.lastPage = Math.ceil(this.totalRecordsNo / this.pageSize);
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }
    public getCustomers(isNext = false) {
        if (isNext) {
            this.pageNumber += 1;
        } else { // prev
            this.pageNumber -= 1;
        }
        this.initNewRequests();
    }
    public verifyCustomer(user: UserModel, complete?: any) {
        if (!user.role) {
            user.role = 'CUSTOMER';
        }

        const isDriver = this.tabRoleParam === 'DRIVER';
        const message = this.translateService.instant(isDriver ? 'ALERTS.VERIFY_DRIVER' : 'ALERTS.VERIFY_CUSTOMER');
        const success = this.translateService.instant(isDriver ? 'ALERTS.DRIVER_VERIFIED_SUCCESSFULLY_1' : 'ALERTS.USER_VERIFIED_SUCCESSFULLY');
        const yes = this.translateService.instant('GENERAL.YES');
        const no = this.translateService.instant('GENERAL.NO');
        this.confirmService.confirm({
            message: message,
            accept: () => {
                (isDriver ? this.usersService.editUserStatus(user, UserStatus.VERIFIED) :
                    this.usersService.editCustomerStatus(user, UserStatus.VERIFIED)).subscribe(
                    (response: any) => {
                        this.initNewRequests();
                        this.messageService.add({severity: 'success', detail: success});
                        if (this.onStatusUpdate) {
                            this.onStatusUpdate();
                        }
                    }, (error) => {
                        const errorMessage = error.error ? error.error.error : 'Something went wrong';
                        console.error(error);
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                complete();
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }

    public onRemoveUser($event: { user: UserModel, complete?: any }) {
        this.removeUser($event.user, $event.complete);
    }

    public removeUser(user: UserModel, complete?: any) {

        user.isDeleting = true;
        if (!user.role) {
            user.role = 'CUSTOMER';
        }
        const isDriver = this.tabRoleParam === 'DRIVER';
        this.confirmService.confirm({
            message: this.translateService.instant(isDriver ? 'ALERTS.REJECT_DRIVER' : 'ALERTS.REJECT_USER'),
            accept: () => {
                (isDriver ? this.usersService.editUserStatus(user, UserStatus.REJECTED) :
                    this.usersService.editCustomerStatus(user, UserStatus.REJECTED)).subscribe(
                    (response: any) => {
                        this.initNewRequests();
                        this.messageService.add({severity: 'success', detail: this.translateService.instant(isDriver ? 'ALERTS.DRIVER_REJECTED_SUCCESSFULLY' : 'ALERTS.USER_REJECTED_SUCCESSFULLY')});
                    }, (error) => {
                        user.isDeleting = false;
                        console.error(error);
                        if (complete) {
                            complete();
                        }
                    }, () => {
                        if (complete) {
                            complete();
                        }
                    }
                );
            },
            reject: () => {
                user.isDeleting = false;
                complete();
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO')
        });
    }
}
