import {SpinnerState} from '../behavior/spinner-state.enum';
import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';


@Injectable({ providedIn: 'root' })
export class UtilitiesService {

    public isShowRouteOptimizationMessage = true;

    /**
     * Reset the spinner state according to the data state.
     */
    public resetSpinnerState(data: any[], isError?): SpinnerState {
        if (isError) {
            return SpinnerState.FAILED;
        } else if (!data) {
            return SpinnerState.LOADING;
        } else if (data.length === 0) {
            return SpinnerState.EMPTY;
        } else {
            return SpinnerState.LOADED;
        }
    }

    public markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    public validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                for (let i = 0; i < control.controls.length; i++) {
                    this.validateAllFormFields((<FormGroup>control.controls[i]));
                }
            }
        });
    }

    public validateAllFormArrayFields(formArray: FormArray) {
        for (let i = 0; i < formArray.controls.length; i++) {
            this.validateAllFormFields((<FormGroup>formArray.controls[i]));
        }
        if (formArray.invalid) {
            formArray.markAsTouched({onlySelf: true});
        }
    }

    public getImageRandomReload() {
        return '?random=' + Math.random();
    }

    public decodePathString(encodedPath: string) {
        const strLen = encodedPath.length;

        const path = [];
        let index = 0;
        let lat = 0;
        let lng = 0;

        // tslint:disable:no-bitwise
        while (index < strLen) {
            let result = 1;
            let shift = 0;
            let b;
            do {
                b = encodedPath.charCodeAt(index++) - 63 - 1;

                result += (b << shift);
                shift += 5;
            } while (b >= 0x1F);
            lat += (result & 1) !== 0 ? ~(result >> 1) : (result >> 1);

            result = 1;
            shift = 0;
            do {
                b = encodedPath.charCodeAt(index++) - 63 - 1;
                result += (b << shift);
                shift += 5;
            } while (b >= 0x1F);
            lng += (result & 1) !== 0 ? ~(result >> 1) : (result >> 1);

            path.push([lat * 1e-5, lng * 1e-5]);
        }
        // tslint:enable:no-bitwise

        return path;
    }
}

export function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}
