<p-dialog [baseZIndex]="100000000"
          [(visible)]="showAWBModal"
          [dismissableMask]="true"
          [modal]="true"
          styleClass="download-awb"
          (onHide)="onCloseDialog()"
          [closeOnEscape]="true">
    <p-header>
        <div class="main-title">
            <span *ngIf="titleCode === 'DOWNLOAD_AS_FILE'">{{ 'ACTIONS.DOWNLOAD_AS_FILE' | translate }}</span>
            <span *ngIf="titleCode === 'PRINT_FILE'">{{ 'ACTIONS.PRINT_FILE' | translate }}</span>
            <span *ngIf="titleCode === 'PRINT_INVOICES'">{{ 'ACTIONS.PRINT_INVOICES' | translate }}</span>
            <span *ngIf="titleCode === 'PRINT_INVOICE'">{{ 'ACTIONS.PRINT_INVOICE' | translate }}</span>
            <span *ngIf="titleCode === 'EXPORT_TO_EXCEL'">{{ 'ACTIONS.EXPORT_TO_EXCEL' | translate }}</span>
            <span *ngIf="titleCode === 'DOWNLOAD_EXCEL_TEMPLATE'">{{ 'ACTIONS.DOWNLOAD_EXCEL_TEMPLATE' | translate }}</span>
        </div>
    </p-header>
    <div class="content">
        <div class="title">
            <span>{{ 'GENERAL.DO_YOU_WANT_TO' | translate }} </span>

            <span *ngIf="titleCode === 'DOWNLOAD_AS_FILE'">{{ 'ACTIONS.DOWNLOAD_AS_FILE' | translate }}</span>
            <span *ngIf="titleCode === 'PRINT_FILE'">{{ 'ACTIONS.PRINT_FILE' | translate }}</span>
            <span *ngIf="titleCode === 'PRINT_INVOICES'">{{ 'ACTIONS.PRINT_INVOICES' | translate }}</span>
            <span *ngIf="titleCode === 'PRINT_INVOICE'">{{ 'ACTIONS.PRINT_INVOICE' | translate }}</span>
            <span *ngIf="titleCode === 'EXPORT_TO_EXCEL'">{{ 'ACTIONS.EXPORT_TO_EXCEL' | translate }}</span>
            <span *ngIf="titleCode === 'DOWNLOAD_EXCEL_TEMPLATE'">{{ 'ACTIONS.DOWNLOAD_EXCEL_TEMPLATE' | translate }}</span>

            <span> {{ 'GENERAL.NOW' | translate }} </span>
        </div>
        <div class="link">
            {{"GENERAL.OR_WITH" |translate }} <a href="{{url}}" target="_blank">{{"GENERAL.CLICK_LINK" |translate }}</a>
            <img src="../../assets/icons/track_package/copy.svg" alt=""
                 (click)="copyURL()"
                 data-toggle="tooltip" data-placement="top" title="{{ 'ACTIONS.COPY' | translate }}"
                 class="copy_icon">
        </div>
    </div>
    <p-footer>
        <div class="button-group">
            <button class="main-btn" (click)="downloadReport()">{{ 'ACTIONS.DOWNLOAD' | translate }}</button>
            <button class="secondary-button hidden-sm-down" *ngIf="showPrintButton" (click)="printReport()">{{ 'ACTIONS.PRINT' | translate }}</button>
        </div>
    </p-footer>
</p-dialog>
