import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {INTERCEPTORS} from './shared/intercepters/interceptors.index';
import {APP_SERVICES} from './shared/services/services.index';
import {SharedModule} from './shared/modules/shared.module';
import {AppRoutingModule} from './app.routes';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {HomeModule} from './home/home.module';
import {ShipmentModule} from './shipment/shipment.module';
import {BranchesModule} from './branches/branches.module';
import {AccountModule} from './account/account.module';
import {InboxModule} from './inbox/inbox.module';
import {environment} from '../environments/environment';
import {CompaniesModule} from './companies/companies.module';
import {CompanyNotificationsModule} from './company-notifications/company-notifications.module';
import { HubsReportsComponent } from './current-reports/hubs-reports/hubs-reports.component';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import {PartnersReportsComponent} from './current-reports/partners-reports/partners-reports.component';
import {FinancialReportComponent} from './financial-report/financial-report.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {LoginComponent} from './login/login.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {fontAwesomeIcons} from './shared/components/font-awesome-icons';
import {PricingsModule} from './pricings/pricings.module';
import {TicketingSystemModule} from './ticketing-system/ticketing-system.module';
import {SystemUpdatesModule} from './system-updates/system-updates.module';
import {FaqModule} from './faq/faq.module';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ToastModule} from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {GoogleChartsModule} from 'angular-google-charts';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {COMPANIES_WITH_CUSTOM_TRANSLATIONS} from './shared/services/shared_constants/constants';
import {TripsBetweenCitiesModule} from './trips-between-cities/trips-between-cities.module';
import { AttachmentsViewComponent } from './shared/components/attachments-view/attachments-view.component';
import { ActiveCustomerReportsComponent } from './current-reports/active-customer-reports/active-customer-reports.component';
import { CheckLoginComponent } from './shared/components/check-login/check-login.component';

import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';

import { ThirdPartyListComponent } from './third-parties/components/third-party-list/third-party-list.component';
import { ThirdPartyCardComponent } from './third-parties/components/third-party-card/third-party-card.component';
import { ThirdPartyDialogComponent } from './third-parties/components/third-party-dialog/third-party-dialog.component';
import { GenericDialogComponent } from './shared/components/generic-dialog/generic-dialog.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

function getCompanyNameByUrl() {
    return window.location.href.split('//').join('').split('/')[1].toLowerCase(); // company name
}


// translation factory.
export function HttpLoaderFactory(http: HttpBackend) {
    const r = Math.random().toString(36).substring(7);
    const resources = [{prefix: './assets/i18n/', suffix: '.json?cacheBuster=' + r}];
    if (COMPANIES_WITH_CUSTOM_TRANSLATIONS.indexOf(getCompanyNameByUrl()) > -1) {
        resources.push({prefix: './assets/i18n/' + getCompanyNameByUrl() + '/', suffix: '.json?cacheBuster=' + r});
    }
    return new MultiTranslateHttpLoader(http, resources);
}
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (params.interpolateParams) {
            return params.interpolateParams['default'] || params.key;
        }
        return params.key;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        HubsReportsComponent,
        PendingApprovalComponent,
        PartnersReportsComponent,
        FinancialReportComponent,
        LoginComponent,
        AttachmentsViewComponent,
        ActiveCustomerReportsComponent,
        CheckLoginComponent,
        ThirdPartyListComponent,
        ThirdPartyCardComponent,
        ThirdPartyDialogComponent,
        GenericDialogComponent
    ],
    imports: [
        AppRoutingModule,
        NgxIntlTelInputModule,
        GoogleChartsModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FontAwesomeModule,
        // Views Modules
        HomeModule,
        ShipmentModule,
        BranchesModule,
        AccountModule,
        CompaniesModule,
        PricingsModule,
        ScrollPanelModule,
        InboxModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        CompanyNotificationsModule,
        TicketingSystemModule,
        ToastModule,
        TabViewModule,
        TableModule,
        CheckboxModule,
        TooltipModule,
        SystemUpdatesModule,
        FaqModule,
        TripsBetweenCitiesModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpBackend]
            },
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
        }),
    ],
    providers: [
        ...INTERCEPTORS,
        ...APP_SERVICES
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
    constructor(iconLibrary: FaIconLibrary) {
        iconLibrary.addIcons(...fontAwesomeIcons);
    }
}
