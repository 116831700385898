<div class="summary-reports-container">
    <div class="summary-report" *ngIf="report==='customer-packages-summary-reports'">
        <app-admin-report [columns]="customerReport" expandLink="customer-packages-summary-report"
                          [apiUrl]="customerReportAPI"
                          reportTitle="ADMINISTRATION.CUSTOMER_PACKAGES_SUMMARY_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='driver-packages-summary-reports'">
        <app-admin-report [columns]="driverReport" expandLink="driver-packages-summary-report"
                          [apiUrl]="driverReportAPI" [isExportToExcel]="true"
                          [queryParams]="{'driverType': 'TYPICAL'}"
                          reportTitle="ADMINISTRATION.DRIVER_PACKAGES_SUMMARY_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='zones-to-packages-summary-reports'">
        <app-admin-report [columns]="zonesToReport" expandLink="zones-to-packages-summary-report"
                          [apiUrl]="zonesToReportAPI"
                          [columnsToSkipInTotal]="1"
                          [calculateTotalLocally]="true"
                          [forceViewTotal]="true"
                          [isPdfRemove]="false"
                          reportTitle="ADMINISTRATION.ZONE_TO_PACKAGES_SUMMARY_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='city-to-cod-summary-reports' && isDelivered==='true'">
        <app-admin-report [columns]="cityTuUndeliveredCODSumReport" expandLink="city-to-cod-summary-report"
                          [queryParams]="{'isDelivered':isDelivered}"
                          [apiUrl]="cityToUndeliveredCODSumAPI" [isExportToExcel]="true"
                          [columnsToSkipInTotal]="1"
                          [calculateTotalLocally]="true"
                          [forceViewTotal]="true"
                          reportTitle="ADMINISTRATION.CITY_TO_DELIVERED_COD_SUM"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='city-to-cod-summary-reports' && isDelivered==='false'">
        <app-admin-report [columns]="cityTuUndeliveredCODSumReport" expandLink="city-to-cod-summary-report"
                          [queryParams]="{'isDelivered':isDelivered}"
                          [apiUrl]="cityToUndeliveredCODSumAPI" [isExportToExcel]="true"
                          [columnsToSkipInTotal]="1"
                          [calculateTotalLocally]="true"
                          [forceViewTotal]="true"
                          reportTitle="ADMINISTRATION.CITY_TO_UNDELIVERED_COD_SUM"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='zones-from-packages-summary-reports'">
        <app-admin-report [columns]="zonesFromReport" expandLink="zones-from-packages-summary-report"
                          [apiUrl]="zonesFromReportAPI"
                          [columnsToSkipInTotal]="1"
                          [calculateTotalLocally]="true"
                          [forceViewTotal]="true"
                          reportTitle="ADMINISTRATION.ZONE_FROM_PACKAGES_SUMMARY_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='zones-packages-summary-reports'">
        <app-admin-report [columns]="zonesReport" expandLink="zones-packages-summary-report"
                          [apiUrl]="zonesReportAPI"
                          [columnsToSkipInTotal]="1"
                          [calculateTotalLocally]="true"
                          [forceViewTotal]="true"
                          reportTitle="ADMINISTRATION.ZONE_PACKAGES_SUMMARY_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="summary-report" *ngIf="report==='undelivered-packages-summary-reports'">
        <app-admin-report [columns]="undeliveredPackagesReport" expandLink="undelivered-packages-summary-report"
                          [apiUrl]="undeliveredPackagesReportAPI"
                          reportTitle="ADMINISTRATION.UNDELIVERED_PACKAGES_SUMMARY_REPORT_TITLE"></app-admin-report>
    </div>
</div>
