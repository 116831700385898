import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Paginator} from 'primeng/paginator';
import {NewShelfComponent} from '../../../manage-shelf/new-shelf/new-shelf.component';
import {HubModel} from '../../../../shared/models/hub.model';
import {HubsService} from '../../../../shared/services/hubs.service';
import {ShelveModel} from '../../../../shared/models/shelve.model';

@Component({
    selector: 'app-shelves-management',
    templateUrl: './shelves-management.component.html',
    styleUrls: ['./shelves-management.component.scss']
})
export class ShelvesManagementComponent implements OnInit, OnChanges {

    @Input() hub: HubModel;
    @Input() shelf: ShelveModel;
    @Input() isShowFullHubDetails: true;
    @ViewChild('paginator', {static: false}) paginator: Paginator;

    @Output() copyShelveBarcode: EventEmitter<{barcode: string }> =
        new EventEmitter<{ barcode: string }>();
    public currentPage = 0;
    public totalRecords = 0;
    public isSubmitting = false;
    public isLoading = false;
    public shelves = [];
    public selectedShelves = {};
    public printLoader = false;
    public fetchedShelves: ShelveModel[] = [];

    constructor(public activeModal: NgbActiveModal,
                private hubsService: HubsService,
                private modalService: NgbModal,
                private messageService: MessageService,
                public translateService: TranslateService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.loadLazyShelves();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['hub'].currentValue && changes['hub'].currentValue !== changes['hub'].previousValue) {
            this.resetShelvesAndCurrentPage();
            this.loadLazyShelves();
        }
    }
    public resetShelvesAndCurrentPage() {
        this.currentPage = 0;
        this.shelves = [];
    }

    private createQuery() {
        const query = '?pageSize=6&page=' + (this.currentPage);
        return query;
    }
    public removeShelf($event: {shelve: ShelveModel}) {
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.REMOVE_IT', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.REMOVE_IT'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.hubsService.deleteShelf($event.shelve.id).subscribe(
                    (response: any) => {
                        this.messageService.add({severity: 'success', detail: 'shelf Deleted Successfully'});
                        // this.shelves.splice(this.shelves.indexOf($event.shelve), 1);
                        this.resetShelvesAndCurrentPage();
                        this.loadLazyShelves();
                        // this.getShelves();
                    }, (error) => {
                        console.error(error);
                    }
                );
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    public modifyShelve($event: { shelve: ShelveModel }) {
        const modal = this.modalService.open(NewShelfComponent, <any>{backdrop: 'static', windowClass: 'add-shelf-container', size: 'md'});
        modal.componentInstance.shelf = $event.shelve;
        modal.componentInstance.hub = this.hub;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    this.messageService.add({severity: 'success', detail: 'Shelf Edited Successfully'});
                    this.resetShelvesAndCurrentPage();
                    this.loadLazyShelves();
                }
            }).catch(
            (error) => {}
        );
    }

    public copyBarcode(barcode) {
        this.copyShelveBarcode.emit({barcode: barcode});
    }

    public onNewShelf() {
        const modal = this.modalService.open(NewShelfComponent, <any>{backdrop: 'static', windowClass: 'add-shelf-container', size: 'md'});
        modal.componentInstance.hub = this.hub;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    this.translateService.get('ALERTS.SHELF_ADDED_SUCCESSFULLY').subscribe(
                        (translatedMessage: any) => {
                            this.messageService.add({severity: 'success', detail: translatedMessage});
                    });
                    this.resetShelvesAndCurrentPage();
                    this.loadLazyShelves();
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public initializePrint() {
        this.shelves.forEach(
            (shelve) => {
                this.selectedShelves[this.shelves[shelve].id] = true;
            }
        );
    }

    public print() {
        this.printLoader = true;
        this.hubsService.printShelves(this.getSelectedShelves()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.printLoader = false;
        }, error => {
            console.error(error);
            this.printLoader = false;
        });
    }

    public getSelectedShelves() {
        const selectedShelves = [];
        this.shelves.forEach(
            (shelve) => {
                if (shelve.isSelected) {
                    selectedShelves.push(shelve.id);
                }
            }
        );
        return selectedShelves;
    }
    public updateSelectedShelves(event) {
        this.shelves[this.shelves.indexOf(event.shelve)].isSelected = event.event;
    }

    public getShelves() {

        if (!this.hub || (this.shelves.length === this.totalRecords && this.currentPage > 1)) {
            return;
        }

        this.fetchedShelves = [];
        this.isLoading = true;

        const query = this.createQuery();

        this.hubsService.getShelves(this.hub.id, query).subscribe((res: any) => {
            this.fetchedShelves = res.data;
            this.shelves = [...this.shelves, ...this.fetchedShelves ];
            this.totalRecords = res.totalRecordsNo;
            this.isLoading = false;
        }, error => {
            console.error(error);
            this.isLoading = false;
        });
    }
    public loadLazyShelves() {
    if (!this.isLoading) {
        this.currentPage++;
        this.getShelves();
    }
    }
    @HostListener('scroll', ['$event'])
    onScrollCards(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadLazyShelves();
        }
    }
}
