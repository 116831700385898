<div class="message-dialog">
    <div class="message-title">
        {{"INBOX.SEND_MESSAGE"| translate}}
        <i class="close-icon fas fa-times" (click)="activeModal.close({normalDismiss: true})"></i>
    </div>

    <div class="message-form">
        <div class="select-user">
            <p-radioButton [(ngModel)]="selectedType" [value]="'Users'" (ngModelChange)="changeUsers()" [label]="'INBOX.USERS' | translate"></p-radioButton>
            <p-radioButton [(ngModel)]="selectedType" [value]="'Customers'" (ngModelChange)="changeUsers()" [label]="'INBOX.CUSTOMERS' | translate"></p-radioButton>
        </div>
        <div class="multiSelect">
            <ng-select [items]="selectedLabels"
                       bindValue="value"
                       placeholder="{{'INBOX.SEND_MESSAGE_TO' | translate}}"
                       [(ngModel)]="selectedIds"
                       [multiple]="true"
                       [virtualScroll]="true"
                       [dropdownPosition]="'bottom'"
                       (search)="search($event)"
                       (scrollToEnd)="lazyLoadUsers()">
            </ng-select>
        </div>
        <form [formGroup]="contactUsForm">
            <app-form-group-component
                    controlType="text"
                    label="{{'INBOX.TITLE' | translate}}"
                    controlName="title"
                    [form]="contactUsForm"
                    placeholder="{{'INBOX.TITLE' | translate}}">
            </app-form-group-component>


            <app-form-group-component
                    controlType="textarea"
                    label="{{'INBOX.CONTENT' | translate}}"
                    controlName="content"
                    [form]="contactUsForm"
                    placeholder="Content">
            </app-form-group-component>
            <app-form-group-component
                    controlType="submit"
                    label="{{'ACTIONS.SEND' | translate}}"
                    [form]="contactUsForm"
                    (onSubmit)="onSendFeedback()"
                    [disabled]="selectedIds.length == 0"
                    [isSubmitting]="isSubmitting">
            </app-form-group-component>
        </form>
    </div>

</div>
