import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {SpinnerState} from './../../behavior/spinner-state.enum';
import {PackageModel} from '../../models/package.model';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {SHARED_CONSTANTS} from '../../services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-packages-table-view',
    templateUrl: './packages-table-view.component.html',
    styleUrls: ['./packages-table-view.component.scss']
})
export class PackagesTableViewComponent {

    @Input() packagesAPI;
    @Input() canPrintPdf;
    @Output() printPdf = new EventEmitter();
    public packages: PackageModel[];
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public pageNumber = 0;
    public totalRecords = 0;
    public userId;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private httpClient: HttpClient,
                private translateService: TranslateService,
                private messageService: MessageService) {
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.initShipments();
    }

    private initShipments() {
        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery();
        this.httpClient.get(this.packagesAPI + URLQuery).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                this.packages = response.data;
                this.totalRecords = response.totalRecordsNo;

                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }
        );
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    public createQuery() {
        let result = '?pageSize=10&page=' + (this.pageNumber + 1);

        return result;
    }

    downloadPdf() {
        this.printPdf.emit();
    }
}
