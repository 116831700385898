import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-expenses-type-add',
  templateUrl: './expenses-type-add.component.html',
  styleUrls: ['./expenses-type-add.component.scss']
})
export class ExpensesTypeAddComponent implements OnInit {
  public form: FormGroup;
  currentLang;

  constructor(private activeModal: NgbActiveModal,
              private translate: TranslateService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.initInfo();
  }
  public closeModal() {
      this.activeModal.close();
  }
  public initInfo() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }
  public choose() {
    const body = this.form.getRawValue();
    this.activeModal.close({name: body.name});
  }
}
