import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-shelve-cities.component',
    templateUrl: 'shelve-cities.component.html',
    styleUrls: ['shelve-cities.component.scss']
})
export class ShelveCitiesComponent implements OnInit {
    public currentLang = '';
    @Input() cities;

    constructor(private activeModal: NgbActiveModal, private translateService: TranslateService) {}

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }

    closeModal() {
        this.activeModal.close();
    }

}
