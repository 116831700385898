<div class="edit-template-container">
    <div class="header">
        <div style="display: inline-block;">
            {{('COMPANY.NOTIFICATION_TEMPLATE') | translate }}
        </div>
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
    </div>
    <div class="input-fields">
        <div style="margin: auto;text-align: center">
                <div class="d-flex flex-column form-container" style="margin: auto">
                    <textarea #textArea [(ngModel)]="templateText" rows="4" style="width: 100%">
                    </textarea>
                    <div class="tags-container">
                        <div *ngFor="let siteTag of siteTags" class="site-tag-container cursor-pointer"
                             (click)="setTag(siteTag)">
                            {{siteTag}}
                        </div>
                    </div>
                </div>
        </div>
        <div class="bottom-actions d-flex flex-wrap justify-content-end">
            <div class="add-button cursor-pointer "
                 (click)="finishEditing()">
                <i class="far fa-save"></i>
                {{'ACTIONS.DONE' | translate }}
            </div>
            <div class="reset-button cursor-pointer " (click)="closeModal()">
                <i class="fas fa-times"></i>
                {{"ACTIONS.CANCEL" | translate}}
            </div>
        </div>
    </div>
</div>
