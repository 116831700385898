export const SHIPMENT_CONSTANTS = {
    ADD_SHIPMENT: ':api/ship/guest/request',
    GET_PACKAGES: ':api/admin/packages/v2',
    GET_RETURNED_PACKAGES_ADMIN: ':api/admin/packages/returned-packages',
    GET_RETURNED_PACKAGES: ':api/admin/customers/{customerId}/returned-packages',
    GET_PACKAGE_ID_BY_BARCODE: ':api/admin/packages/id',
    GET_PICKUPS: ':api/admin/pickups',
    GET_TO_CONVERT_PICKUPS: ':api/admin/pickups/to-convert',
    GET_TO_CONVERT_PICKUPS_COUNT: ':api/admin/pickups/to-convert/count',
    GET_PACKAGES_COUNT: ':api/admin/packages/v2/count',
    REMOVE_PACKAGE: ':api/admin/packages/delete',
    REMOVE_PICKUPS: ':api/admin/pickups/{pickupId}',
    CREATE_BUNDLE: ':api/admin/bundles/customers/collect',
    CREATE_BUNDLE_FOR_RETURNED: ':api/admin/bundles/customers/collect?isReturned=1',
    EDIT_BUNDLE_COD: ':api/admin/packages/{packageId}/edit-cod',
    GET_BUNDLES: ':api/admin/bundles/{bundleId}/packages',
    GET_NUMBER_OF_NEW_SHIPMENTS_REQUESTS: ':api/admin/packages/pending-approval-count',
    GET_BUNDLE_COD: ':api/admin/packages/{packageId}/cod',
    ADD_BUNDLE: ':api/admin/bundles/{bundleId}/packages/{packageId}/add',
    REMOVE_PACKAGE_FROM_BUNDLE: ':api/admin/bundles/{bundleId}/packages/{packageId}/remove',
    ASSIGN_TO_CONTAINER: ':api/admin/packages/assign/containers/{containerId}',
    SHIPMENT_SERVICE: ':api/shipments/services',
    SHIPMENT_METRICS: ':api/ship/region/package-default',
    GET_SHIPMENT_TYPES: ':api/shipments/types',
    APPROVE_PACKEGES: ':api/admin/packages/approve',
    UPDATE_PACKAGE_PROOF_LIST: ':api/admin/packages/{packageId}/add-attachment',
    REQUEST_PACKAGE: ':api/admin/ship/request',
    REQUEST_PACKAGE_SUMMARIZED: ':api/admin/ship/request/summarized',
    CONVERT_TO_PACKAGE: ':api/admin/pickups/{pickupId}/convert-to-package',
    REQUEST_PICKUP: ':api/admin/pickups/add',
    GET_PACKAGE_INFO: ':api/admin/packages/{packageId}',
    REQUEST_PACKAGE_MODIFY: ':api/admin/packages/{packageId}/modify',
    GET_PACKAGE_STATS: ':api/admin/packages/status/values',
    GET_PACKAGE_HISTORY_STATS: ':api/admin/packages/status',
    ADD_PACKAGE_TO_BUNDLE: ':api/admin/bundles/{bundleId}/packages/{packageId}/add',
    EXTRACT_BUNDLE: ':api/admin/bundles/{bundleId}/extract',
    GET_TRANSFERRED_PACKAGE_STATS: ':api/admin/packages/{packageId}/transferred-status',
    CHANGE_PACKAGE_STATUS: ':api/admin/packages/{packageId}/change',
    CHANGE_PACKAGES_STATUS: ':api/admin/packages/change',
    APPROVE_COD_CHANGE: ':api/admin/cod/request/{requestId}/confirm',
    REJECT_COD_CHANGE: ':api/admin/cod/request/{requestId}/reject',
    REMOVE_FLAG: ':api/admin/packages/{packageId}/unflag',
    DOWNLOAD_PDF: ':api/admin/packages/{packageId}/pdf-report',
    DOWNLOAD_PDF_tracking: ':api/admin/packages/history/pdf',
    DOWNLOAD_CSV: ':api/admin/packages/v2/csv',
    DOWNLOAD_EXCEL: ':api/admin/packages/v2/excel',
    DOWNLOAD_CSV_DRIVER: ':api/admin/drivers/{driverId}/carried-packages/csv',
    DOWNLOAD_EXCEL_DRIVER: ':api/admin/drivers/{driverId}/carried-packages/excel',
    DOWNLOAD_MASS_PDF: ':api/accountant/sorted/mass-packages/{massPackageId}/cod-packages/pdf',
    DOWNLOAD_MASS_INVOICE_PDF: ':api/accountant/mass-invoices/{massInvoiceId}/mass-reports/pdf',
    DOWNLOAD_MISMATCH_PDF: ':api/accountant/cod-mismatch/{codMismatchId}/print',
    EXPORT_TO_EXCEL: ':api/accountant/sorted/mass-packages/{massPackageId}/cod-packages/excel',
    EXPORT_TO_EXCEL_RECEIVED_COD: ':api/accountant/customer/{customerId}/received/excel',
    EXPENSES: ':api/accountant/expenses',
    EXPENSES_SUM: ':api/accountant/expenses/sum',
    EXPENSES_TYPE: ':api/accountant/expense-types',
    EXPENSES_UPDATE: ':api/accountant/expenses/{expensesId}',
    SORT_USER_PACKAGES: ':api/accountant/customers/sort',
    SORT_USER_PACKAGES_WITHOUT_RELEASING_CUSTODY: ':api/accountant/customers/sort-not-received-cod',
    UPLOAD_EXCEL: ':api/admin/ship/excel/import',
    UPLOAD_CUSTOMERS_EXCEL: ':api/admin/customers/excel/import',
    UPLOAD_SHIPMENT_COSTS_EXCEL: ':api/admin/packages/excel/update-cost',
    UPLOAD_EXCEL_SUPPLIER: ':api/admin/packages/excel/update-invoice-supplier',
    POSTPONE_PACKAGE: ':api/admin/packages/{packageId}/postpone',
    NOTES_PACKAGE: ':api/driver/packages/{packageId}/notes',
    NOTES_MULTI_PACKAGES: ':api/admin/packages/notes',
    RECEIVE_PACKAGES: ':api/accountant/cod-packages/receive',
    RECEIVE_PACKAGES_WITHOUT_RELEASE: ':api/accountant/cod-packages/sort-without-release-custody',
    UPDATE_COD_MISMATCH: ':api/accountant/cod-mismatch/{mismatchId}',
    SORT_PACKAGES: ':api/accountant/customers/{customerId}/cod-packages/sort',
    GET_PACKAGE_BY_BARCODE_ADMIN: ':api/admin/package/info',
    GET_PACKAGE_BY_BARCODES: ':api/admin/packages/get-by-barcodes',
    GET_EXPORT_COLLECTION_BY_BARCODES: ':api/accountant/mass-packages/exported',
    GET_RETURNED_COLLECTION_BY_BARCODES: ':api/admin/customers/mass-returned',
    GET_PICKUP_BY_BARCODE_ADMIN: ':api/admin/pickups/drivers/{driverId}/scan-pickup',
    GET_PACKAGE_BY_BARCODE_ACCOUNTANT: ':api/accountant/package/info',
    GET_RETURNED_PACKAGE_BY_BARCODE: ':api/admin/packages/to-return',
    GET_PACKAGE_BY_BARCODE_ACCOUNTANT_CUSTOMER: ':api/accountant/{customerId}/package-info/to-sort',
    GET_COD_MISMATCHES: ':api/accountant/cod-mismatch',
    GET_COD_MISMATCHES_FOR_ALL_PACKAGE: ':api/accountant/cod-mismatch/packages',
    GET_COD_MISMATCHES_FOR_ALL_PACKAGE_EXCEL: ':api/accountant/cod-mismatch/packages/excel',
    GET_ACCOUNTANT_ADMIN: ':api/admin/users/cod-receivers',
    HANDLE_COD_MISMATCH: ':api/accountant/drivers/cod-mismatch/{mismatchId}/handle',
    GET_PACKAGE_HISTORY: ':api/admin/packages/history',
    GET_PACKAGE_SIGNATURE: ':api/admin/packages/{packageId}/signature',
    GET_PACKAGE_ONLY_SIGNATURE: ':api/admin/packages/{packageId}/signature-image',
    GET_PACKAGE_PROOF_LIST: ':api/admin/packages/{packageId}/proofList',
    UPDATE_BULK_PACKAGE: ':api/admin/packages/change-status-by-barcodes',
    RECEIVE_BULK_PACKAGES: ':api/accountant/cod-packages/bulk-receive',
    SORT_BULK_PACKAGES: ':api/accountant/customers/{customerId}/cod-packages/bulk-sort',
    DOWNLOAD_MULTIPLE: ':api/admin/packages/reports',
    DOWNLOAD_INVOICES: ':api/admin/packages/invoice-numbers/pdf',
    DOWNLOAD_MULTIPLE_MASS: ':api/accountant/mass-packages/pdf',
    DOWNlOAD_MULTIPLE_MASS_REPORTS: ':api/accountant/mass-reports/pdf',
    DOWNLOAD_ALL_MASS: ':api/accountant/mass-packages/exported/pdf',
    DOWNLOAD_MULTIPLE_CHANGE_STATUS: ':api/admin/packages/scanned-packages/pdf',
    GET_CUSTODY_PACKAGES: ':api/hub-manager/hub-custody/cod-packages',
    ADD_TO_CUSTODY: ':api/hub-manager/cod-packages/add-to-hub-custody',
    UNLOAD_PACKAGES: ':api/hub-manager/unload',
    UNLOAD_CUSTOMERS_RETURNED_PACKAGES: ':api/admin/customers/packages/drivers/{driverId}/load',
    LOAD_CUSTOMERS_RETURNED_PACKAGES: ':api/admin/bundles/drivers/{driverId}/load',
    UNLOAD_RETURNED_PACKAGES: ':api/admin/packages/unload-returned-packages',
    GET_FOLLOW_UP_PACKAGES_COUNT: ':api/admin/packages/follow-up-count',
    GET_RETURNED_PACKAGES_SHIPMENTS: ':api/admin/customers/with-returned',
    GET_RETURNED_BUNDLES: ':api/admin/bundles/returned',
    DELIVER_CUSTOMER_RETURNED_PACKAGES: ':api/admin/packages/deliver-to-sender',
    DELIVER_CUSTOMERS_RETURNED_PACKAGES: ':api/admin/packages/customers/deliver-to-sender',
    DELIVER_CUSTOMERS_RETURNED_BUNDLE: ':api/admin/bundles/returned/deliver-to-sender',
    EXPENSES_PRINT: ':api/accountant/expenses/print',
    PRINT_CUSTOMER_RETURNED_PACKAGES: ':api/admin/packages/returned-packages/pdf',
    PRINT_CUSTOMER_RETURNED_BUNDLE: ':api/admin/bundles/{id}/packages/pdf',
    EXPORT_CUSTOMER_RETURNED_PACKAGES: ':api/admin/packages/returned-packages/excel',
    EXPORT_CUSTOMER_RETURNED_BUNDLE: ':api/admin/bundles/{id}/packages/excel',
    GET_FAILED_PACKAGES_HISTORY: ':api/admin/packages/{packageId}/failure-notes',
    TRANSFER_PACKAGES_BY_BARCODE: ':api/admin/packages/transfer-out-by-ids',
    EXPORT_PACKAGES_TO_HUB: ':api/admin/packages/export/{toHubId}',
    TRANSFER_PACKAGES_TO_THIRD_PART: ':api/admin/packages/zigzag/transfer',
    TRANSFER_PACKAGES_COMPANY_ID_153: ':api/admin/packages/arabi-express/retex/transfer',
    TRANSFER_PACKAGES_COMPANY_ID_128: ':api/admin/packages/rise/transfer',
    TRANSFER_PACKAGES_COMPANY_ID_155: ':api/admin/packages/na3san/transfer',
    GET_PARTNER_PACKAGES: ':api/admin/packages/partners',
    CANCEL_PACKAGES_BY_IDS: ':api/admin/packages/partners/{partnerId}/cancel',
    GET_EXPECTED_DELIVERY_DAYS: ':api/shipments/service-type/{serviceTypeId}/expected-delivery-days',
    GET_PACKAGE_BY_BARCODE_CANCEL: ':api/admin/packages/partners/{partnerId}/to-cancel',
    DOWNLOAD_EXCEL_TEMPLATE: ':api/guests/import-excel-template',
    DOWNLOAD_EXCEL_TEMPLATE_SUMMARIZED: ':api/admin/packages/import-excel-template/summarized',
    DOWNLOAD_EXCEL_RECIPT: ':api/accountant/cod-mismatch/excel',
    PRINT_MULTI_COD_MISMATCH: ':api/accountant/cod-mismatch/pdf',
    DOWNLOAD_EXCEL_SORT_COD: ':api/accountant/sorted/mass-packages/excel',
    DOWNLOAD_EXCEL_RECEIVED_COD: ':api/accountant/customers/received-cod/excel',
    DOWNLOAD_EXCEL_NOT_RECEIVED_COD: ':api/accountant/customers/not-received-cod/excel',
    EXPORT_CUSTOMERS_COD: ':api/accountant/export/mass-packages/customers/excel',
    DOWNLOAD_EXCEL_EXPORTED_COD: ':api/accountant/export/mass-packages/excel',
    DOWNLOAD_CUSTOMERS_EXCEL_TEMPLATE: ':api/guests/import-customers-template',
    PRINT_BY_BARCODES: ':api/admin/packages/print-by-barcodes',
    RECEIVE_ALL: ':api/accountant/cod-packages/receive-all',
    GET_DRIVER_PACKAGE_BY_BARCODE: ':api/admin/drivers/{driverId}/scan-package',
    GET_PARTNER_PACKAGE_BY_BARCODE: ':api/admin/packages/partners/{partnerId}/returned-package/scan',
    RECEIVE_DRIVER_PACKAGES: ':api/admin/packages/drivers/{driverId}/hubs/{hubId}/unload',
    RECEIVE_DRIVER_PICKUPS: ':api/admin/pickups/drivers/{driverId}/hubs/{hubId}/receive',
    RECEIVE_PARTNER_PACKAGES: ':api/admin/packages/unload-returned-packages',
    GET_EXPENCES_TYPE: ':api/accountant/expense-types',
    DELETE_EXPENSE_TYPE: ':api/accountant/expense-types/{expenseTypeId}',
    ADD_EXPENSE_TYPE: ':api/accountant/expense-types',
    PUT_MASS_PACKAGE_BT_BARCODE: ':api/accountant/mass-packages/{massPackageId}/partial-export-by-barcodes',
    MANAGE_SHIPMENTS_TABLE_CUSTOMIZATION: ':api/admin/packages/settings',
    EDIT_RECEIVER_INFO: ':api/admin/packages/{packageId}/edit-receiver-info',
    TRANSFER_PACKAGES_HEBRON: ':api/admin/packages/hebron-logistics/transfer',
    TRANSFER_PACKAGES_EVA_TO_ALKHAL: ':api/admin/packages/eva/alkhal/transfer',
    TRANSFER_PACKAGES_HFD: ':api/admin/packages/smart-delivery/transfer',
    SWAP_SENDER_RECEIVER: ':api/admin/packages/{packageId}/swap-sender-receiver',
    GET_PACKAGE_DELIVERY_PRICE: ':api/admin/packages/cost',
    MARK_AS_LCL_PAID: ':api/admin/packages/{packageId}/mark-as-paid',
    RECEIVE_LCL_ITEMS: ':api/admin/packages/{packageId}/receive-lcl-items',
    ADD_SENDER_ADDRESS_BOOKS: ':api/admin/ship/address-books',
    EDIT_SENDER_ADDRESS_BOOKS: ':api/admin/ship/address-books/{addressId}',
    DELETE_SENDER_ADDRESS_BOOKS: ':api/admin/ship/address-books/{addressId}',
    GET_CONTAINER_INFO: ':api/admin/bundles/container/{barcode}',
    RESOLVE_FAILED_PACKAGE: ':api/admin/packages/{packageId}/resolve',
    ADD_PACKAGES_TO_CONTAINER: ':api/admin/bundles/container/{containerId}',
    CREATE_CONTAINER_BUNDLE: ':api/admin/bundles/container',
    UNLOAD_PACKAGE_FROM_CONTAINER: ':api/admin/bundles/container/package/{pkgId}',
    GET_CONTAINER_PACKAGES: ':api/admin/bundles/container/{barcode}/deep',
    SEND_VERIFICATION_PIN_CODE: ':api/admin/packages/{packageId}/pin-code',
    TRANSFER_TO_OTO: ':api/admin/packages/transfer-to-oto',
    TRANSFER_TO_STORM: ':api/admin/packages/wasle/storm/transfer',
    TRANSFER_PEGASO__TO_STORM: ':api/admin/packages/pegaso/mugrabi/transfer',
    TRANSFER_TO_STORM_ROLOI: ':api/admin/packages/roloi/storm/transfer',
    TRANSFER_TO_MUGHRABE: ':api/admin/packages/arabi-express/mughrabi/transfer',
    TRANSFER_TO_OUSTOL: ':api/admin/packages/sadrad/oustol/transfer',
    TRANSFER_TO_AUTOBAHN: ':api/admin/packages/sadrad/autobahn/transfer',
    GENERIC_TRANSFER_TO: ':api/admin/packages/{from}/{to}/transfer',
    TRANSFER_TO_ALKHAL: ':api/admin/packages/saare3/alkhal/transfer',
    TRANSFER_TO_SPEED_LINE: ':api/admin/packages/live-urgent/speed-line/transfer',
    TRANSFER_TO_SONIC: ':api/admin/packages/live-urgent/speed-line/transfer',
    TRANSFER_TO_EVERYWHERE: ':api/admin/packages/everywhere/transfer',
    TRANSFER_TO_HI_EXPRESS: ':api/admin/packages/arabi-express/hiexpress/transfer',
    TRANSFER_TO_AMAN: ':api/admin/packages/hawk/aman/transfer',
    TRANSFER_TO_HAWK: ':api/admin/packages/hawk/speed-line/transfer',
    TRANSFER_TO_ARAMEX: ':api/aramex/transfer',
    TRANSFER_TO_IMILE: ':api/mile/transfer',
    TRANSFER_TO_SMB: ':api/admin/packages/transfer-to-smb',
    TRANSFER_TO_TOROD: ':api/torod/transfer',
    TRANSFER_TO_HOLOL: ':api/holol/transfer',
    EXPORT_COD_FOR_CUSTOMER_TO_EXCEL: ':api/admin/customers/{customerId}/cod/excel',
    MODIFY_PACKAGE_DESTINATION_ADDRESS: ':api/admin/packages/{packageId}/destination-address',
    RECEIVE_IN_HUB_CUSTODY: ':api/accountant/cod-packages/hubs/{hubId}/add-to-hub-custody',
    GET_PACKAGES_CONTENT_REPORTS_BY_BARCODE: ':api/admin/contents/packages',
    GET_PACKAGE_CONTENTS: ':api/admin/contents/packages',
    GET_PACKAGES_CONTENT_REPORTS_BY_CUSTOMER: ':api/admin/contents/customers',
    MASS_COD_HISTORY: ':api/accountant/mass-cod-reports/{massCodReportId}/history',
    EXPENSES_HISTORY: ':api/accountant/expenses/{expenseId}/history',
    DISTRIBUTOR_RETURNED_PACKAGES_REPORT: ':api/admin/distributor/returned-packages',
    EXPORT_PARTIALLY: ':api/admin/packages/returned-packages/{customerId}/collect-by-ids',
    PRINT_AS_ENVELOP: ':api/accountant/sorted/mass-packages/{massPackageId}/cod-packages/envelope',
    PRINT_JNT: ':api/jnt/packages/{packageId}/waybill',
    PRINT_ARAMEX: ':api/aramex/packages/{packageId}/waybill',
    PRINT_JNT_REPORT: ':api/jnt/packages/waybill',
    PRINT_SMB_REPORT: ':api/admin/packages/smb/waybill',
    CANCEL_JNT: ':api/jnt/packages/{packageId}/cancel',
    GET_ALL_TRIP_NUMBER: ':api/admin/cities/allTrips',
    GET_HUBS_BY_CITIES: ':api/admin/packages/getHubsByCity',
    GET_SUGGESTION_TRIP_NUMBER: ':api/admin/cities/trips/suggestions',
    SEND_NOTIFICATION_FAILED_PENDING_SORT: ':api/admin/packages/{packageId}/resolve-reminder',
    CREATE_INVOICE: ':api/accountant/customers/invoice/create',
    COLLECT_RETURNED_PACKAGE: ':api/admin/customers/collect',
    COLLECT_RETURNED_PACKAGE_WITH_DRIVER: ':api/admin/customers/collect-with-driver',
    PRINT_AS_ENVELOPE: ':api/accountant/sorted/mass-packages/cod-packages/envelope',
    PAY_MASS_INVOICE: ':api/accountant/mass-invoices/{massInvoiceId}/pay',
    EXPORT_READ_BY_BARCODE_PACKAGES: ':api/admin/package/by-ids/excel',
    PRINT_CUSTODY_REPORTS: ':api/admin/packages/scanned-packages/pdf',
    GENERATE_BARCODES: ':api/admin/barcode/pdf',
    GET_RETURNED_PACKAGE_BY_BARCODE_FOR_BUNDLE: ':api/admin/packages/bundle/{customerId}/to-return',
    CHANGE_DRIVER_MASS_REPORT: ':api/accountant/mass-reports/driver'
};
export const PACKAGE_TRACKING_CONSTANTS = {
    GET_PACKAGE_HISTORY: ':api/admin/packages/history'
};
export const SHIPMENT_STATUS_ALL = 'ALL';
export const PICKUP_STATUS_ALL = 'ALL';
export const SERVICE_TYPES_ALL = 'ALL';

export const SHIPMENT_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'PENDING_CUSTOMER_CARE_APPROVAL',
    'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER',
    'CANCELLED',
    'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL',
    'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT',
    'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP',
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'SCANNED_BY_HANDLER_AND_UNLOADED',
    'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY',
    'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT',
    'RETURNED_BY_RECIPIENT',
    'POSTPONED_DELIVERY',
    'DELIVERED_TO_RECIPIENT',
    'PARTIALLY_DELIVERED',
    'COMPLETED',
    'DELAYED',
    'DRAFT',
    'TRANSFERRED_OUT',
    'SWAPPED',
    'BROUGHT'
];
export const ORDER_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'CREATED',
    'PICKED',
    'PARTIALLY_PICKED',
    'PACKED',
    'DELIVERED',
    'CANCELED',
    'RETURNED'
];
export const SAR_SHIPMENT_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER',
    'PENDING_CUSTOMER_CARE_APPROVAL',
    'RECEIVED_AT_DEPARTURE_STATION',
    'SCANNED_BY_HANDLER_AND_UNLOADED',
    'ARRIVED',
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'DELIVERED_TO_RECIPIENT',
    'CANCELLED',
    'POSTPONED_DELIVERY'
];
export const JETT_SHIPMENT_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'SCANNED_BY_HANDLER_AND_UNLOADED',
    'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY',
    'DELIVERED_TO_RECIPIENT',
    'COMPLETED',
    'TRANSFERRED_OUT',
    'SWAPPED',
    'BROUGHT'
];
export const RETURNED_SHIPMENTS_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'DELIVERED_TO_SENDER',
    'IN_CAR',
    'IN_HUB',
    'WITH_PARTNER'
];
export const FAILED_PACKAGE_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'RESOLVED_FAILURE',
    'UNRESOLVED_FAILURE'
];
export const COMPLETED_SHIPMENTS_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'DELIVERED',
    'RETURNED',
    'PARTIALLY_DELIVERED',
    'SWAPPED',
    'BROUGHT'
];
export const PICKUP_TYPE_LIST = [
    SHIPMENT_STATUS_ALL,
    'PICKUP',
    'DELIVERY'
];
export const UNRECEIVED_PARTNER_SHIPMENTS_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'DELIVERED_TO_RECIPIENT',
    'RETURNED_BY_RECIPIENT',
    'SWAPPED',
    'BROUGHT'
];
export const PACKAGE_PER_RECEIVER_CITY_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'SCANNED_BY_HANDLER_AND_UNLOADED',
    'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY',
    'POSTPONED_DELIVERY'
];
export const LCL_SHIPMENT_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'PENDING_CUSTOMER_CARE_APPROVAL',
    'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER',
    'SCANNED_BY_HANDLER_AND_UNLOADED',
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'DELIVERED_TO_RECIPIENT',
    'CONTAINER_UNLOADED_AND_CLEARED_OUT'
];
export const BUNDLE_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'CREATED',
    'IN_TRANSIT',
    'PARTIALLY_DELIVERED',
    'DELIVERED'
];
export const COD_STATUS_LIST = [
    'IN_HUB',
    'WITH_PARTNER',
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'COMPLETED',
    'EXPORTED',
    'COD_SORTED_BY_ACCOUNTANT',
    'COD_RECEIVED_BY_ACCOUNTANT',
    'INVOICE'
];
export const COD_STATUS_LIST_WITHOUT_RELEASING_CUSTODY = [
    'WITH_DRIVER',
    'SORTED_BY_ACCOUNTANT',
    'EXPORTED_BY_ACCOUNTANT',
    'DELIVERED'
];
export const  COD_STATUS_LIST_WITHOUT_RELEASING_CUSTODY_CUSTOMER_CONFIRMATION_ENABLED = [
    'INVOICE',
    'PENDING_CUSTOMER_RECEIVING_CONFIRMATION'
];

export const SHIPMENT_WITH_DRIVER_STATUS_LIST = [
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'RETURNED_BY_RECIPIENT',
    'POSTPONED_DELIVERY',
    'FAILED',
    SHIPMENT_STATUS_ALL
];
export const HUB_MANAGER_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'PENDING_CUSTOMER_CARE_APPROVAL',
    'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER',
    'SCANNED_BY_HANDLER_AND_UNLOADED',
    'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL',
    'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT',
    'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP',
    'SCANNED_BY_DRIVER_AND_IN_CAR',
    'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY',
    'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT',
    'RETURNED_BY_RECIPIENT',
    'POSTPONED_DELIVERY',
    'DELIVERED_TO_RECIPIENT',
    'TRANSFERRED_OUT'
];
export const RECEIVE_COD_STATUS_LIST = [
    SHIPMENT_STATUS_ALL,
    'RETURNED_BY_RECIPIENT',
    'DELIVERED_TO_RECIPIENT',
    'PARTIALLY_DELIVERED',
    'SWAPPED',
    'BROUGHT'
];

export const SPECIAL_STATUS_FOR_HUBS_ACCOUNTING = ['EXPORTED_TO_HUB', 'RECEIVED_IN_HUB'];
export const SHIPMENT_FILTERS_LIST = [
    'SHIPMENT_STATUS',
    'CUSTOMER'
];
export const FINANCIAL_REPORT_LIST = [
    'allPackagesCodSum',
    'allPackagesCostSum',
    'deliveredPackagesCodSum',
    'deliveredPackagesCostSum',
    'returnedPackagesCostSum',
    'driverEarningsSum',
    'partnerCostSum',
    'driverExpenses',
    'hubExpenses',
    'partnerExpenses',
    'customersCod',
    'profit',
    'otherExpenses'
];
export const FINANCIAL_REPORT_MAP = {
    'allPackagesCodSum': {label: 'ALL_PACKAGES_COD_SUM'},
    'allPackagesCostSum': {label: 'ALL_PACKAGES_COST_SUM'},
    'customersCod' : {label: 'CUSTOMER_COD'},
    'deliveredPackagesCodSum' : {label: 'DELIVERED_PACKAGES_COD_SUM'},
    'deliveredPackagesCostSum' : {label: 'DELIVERED_PACKAGES_COST_SUM'},
    'driverEarningsSum': {label: 'DRIVER_EARNINGS_SUM'},
    'partnerCostSum': {label: 'PARTNER_COST'},
    'driverExpenses': {label: 'DRIVER_EXPENSES'},
    'hubExpenses': {label: 'HUB_EXPENSES'},
    'partnerExpenses': {label: 'PARTNER_EXPENSES'},
    'returnedPackagesCostSum': {label: 'RETURNED_PACKAGES_COST_SUM'},
    'profit': {label: 'PROFIT'},
    'otherExpenses': {label: 'OTHER_EXPENSES'}
};
export const REQUIRE_HUB = ['SCANNED_BY_HANDLER_AND_UNLOADED', 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY',
    'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'];
export const REQUIRE_DRIVER = ['ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL', 'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT',
    'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP', 'SCANNED_BY_DRIVER_AND_IN_CAR'];
export const REQUIRE_HUB_DRIVER_SELECTION = ['RETURNED_BY_RECIPIENT', 'PARTIALLY_DELIVERED'];
export const REQUIRE_SHELF = ['MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'];
export const REQUIRE_POSTPONED_DELIVERY_DATE = ['POSTPONED_DELIVERY'];
export const REQUIRE_DELIVERY_DATE = ['DELIVERED_TO_RECIPIENT', 'RETURNED_BY_RECIPIENT'];
export const REQUIRE_IS_DELIVERED_SELECTION = ['RETURNED_BY_RECIPIENT'];
export const OPTIONAL_IS_FAILED = ['SCANNED_BY_DRIVER_AND_IN_CAR', 'SCANNED_BY_HANDLER_AND_UNLOADED'];
export const IS_TO_FINAL_DESTINATION = ['SCANNED_BY_DRIVER_AND_IN_CAR', 'SCANNED_BY_HANDLER_AND_UNLOADED', 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY', 'POSTPONED_DELIVERY'];
export const REMOVE_HUBS_AND_DRIVERS = ['DELIVERED_TO_RECIPIENT'];

export const PICKUP_STATUS_LIST = [
    PICKUP_STATUS_ALL,
    'PENDING',
    'ASSIGNED_TO_DRIVER',
    'ACCEPTED_BY_DRIVER',
    'REJECTED_BY_DRIVER',
    'IN_CAR',
    'IN_HUB'
];

export const EXPORTED_DATE_FILTERS_LIST = [
    'NONE',
    'SORT_DATE',
    'RECEIVE_DATE',
    'CREATED_DATE',
    'DELIVERY_DATE'
];

export const ACCOUNTING_SHARED_DATE_FILTER_LIST = [
    'NONE',
    'CREATED_DATE',
    'INVOICE_NUMBER_ASC',
    'INVOICE_NUMBER_DESC',
    'SORT_DATE',
    'RECEIVE_DATE'
];
export const DELAYED_COD_REPORT_DATE_FILTERS_LIST = [
    'NONE',
    'SORT_DATE',
    'CREATED_DATE'
];
export const EXPORTED_PACKAGES_DATE_FILTERS_LIST = [
    'NONE',
    'SORT_DATE',
    'RECEIVE_DATE',
    'CREATED_DATE',
    'DELIVERY_DATE',
    'EXPORT_DATE'
];
export const DELIVERED_PACKAGES_DATE_FILTERS_LIST = [
    'NONE',
    'SORT_DATE',
    'RECEIVE_DATE',
    'CREATED_DATE',
    'DELIVERY_DATE',
    'EXPORT_DATE',
    'COD_DELIVERY_DATE'
];
export const RECEIVE_DATE_FILTERS_LIST = [
    'NONE',
    'RECEIVE_DATE',
    'CREATED_DATE',
    'DELIVERY_DATE'
];
export const DELAYED_DATE_FILTERS_LIST = [
    'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DATE',
    'ADMINISTRATION.PACKAGE_PICKUP_DATE',
    'ADMINISTRATION.PACKAGE_RETURN_DATE'
];
export const CONTAINERS_ORDER_BY_LIST = [
    'CREATED_DATE',
    'LICENCE_EXPIRY_DATE',
    'INSURANCE_EXPIRY_DATE'
];

export const READ_BY_BARCODE_ORDER_BY_LIST = [
    'RECEIVER_CITY',
    'RECEIVER_VILLAGE',
    'SENDER_NAME',
];
export const ALL_MASS_COD_REPORT_DATE_TYPES = [
    'COD_DELIVERY_DATE',
    'EXPORT_DATE',
];
export const EXCEL_IMPORT_CONSTANTS = {
    ADDRESS_PAGE_SIZE: 100,
    GENERAL_CUSTOMER_PAGE_SIZE: 20,
    GENERAL_ADDRESS_PAGE_SIZE: 20,
    MAX_OPTIONS_TO_SELECT_ADDRESS: 20
};
export const SERVICE_TYPES_LIST = [
    SERVICE_TYPES_ALL,
    'STANDARD',
    'EXPRESS',
    'THREE_TO_FIVE_DAYS'
];

export const RETURNED_TYPES_LIST = [
    'ALL',
    'EXCHANGE',
    'PICKUP',
    'RETURNED',
];

export const EXPORTING_HUB_PACKAGES_FILTER_LIST = [
    'ALL_RELATED_TO_CURRENT_HUB',
    'CURRENT_HUB_CUSTOMERS_PACKAGES',
    'IN_CURRENT_HUB',
    'EXPORTED_FROM_OTHER_HUBS'
];

export const DISTRIBUTOR_RETURNED_AND_EMPTY_STATUS = [
    SHIPMENT_STATUS_ALL,
    'IN_CAR',
    'DELIVERED_TO_QC'
];

export const DISTRIBUTOR_RETURNED_TYPES = [
    SHIPMENT_STATUS_ALL,
    'DAMAGED',
    'EMPTY'
];
