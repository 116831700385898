<div class="change-package-status-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{'GENERAL.GENERATE_BARCODE.TITLE' | translate}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal()"></i>
        </div>
    </div>
    <div class="body">
        <form [formGroup]="form">
            <app-input label="GENERAL.GENERATE_BARCODE.QUANTITY"
                       formControlName="quantity"
                       [formControlObj]="form.get('quantity')"
                       (change)="updatePreview()" [required]="true">
            </app-input>
            <app-input label="GENERAL.GENERATE_BARCODE.PREFIX"
                       (change)="updatePreview()"
                       [formControlObj]="form.get('prefix')"
                       formControlName="prefix">
            </app-input>
            <app-input label="GENERAL.GENERATE_BARCODE.SUFFIX"
                       (change)="updatePreview()"
                       [formControlObj]="form.get('suffix')"
                       formControlName="suffix">
            </app-input>
            <app-input label="GENERAL.GENERATE_BARCODE.PREVIEW"
                       [formControlObj]="form.get('preview')"
                       formControlName="preview"
                       [fieldDisabled]="true">
            </app-input>
        </form>
    </div>
    <div class="footer d-flex bottom-actions-container">
        <div class="flex-1"></div>
        <input class="btn btn-sm button cancel-button" type="submit" (click)="closeModal()"
               value="{{'ACTIONS.CANCEL' | translate}}">
        <button class="btn-sm action-btn" type="submit" (click)="saveButton()"
                [ngClass]="{'disabled-btn disabled': form.invalid}">
            {{'GENERAL.GENERATE_BARCODE.GENERATE' | translate}}
        </button>
    </div>
</div>
