<div class="choose-driver-container"  [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="title-container row">
        <div class="title d-flex" style="position: relative; padding: 0;">
            <div class="driver-icon">
                <img src="../../assets/icons/manage_shipments/assign-driver.png" alt="" />
            </div>
            <div class="label" style="margin-left: 20px;">
                <span>{{('MANAGE_SHIPMENTS.CHOOSE_DRIVER') | translate }}</span>
            </div>
        </div>
        <div class="close-icon cursor-pointer">
            <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt="" />
        </div>
    </div>
    <div class="main-spinner-container" *ngIf="isLoading">
        <div class="spinner-stage">
            <div class="dot-flashing"></div>
        </div>
    </div>
    <div class="input-fields">
        <div class="row-input dates-input" *ngIf="showDates">
            <div class="date-filter-label">{{'MANAGE_SHIPMENTS.PICKUP_DATE' | translate}}</div>
            <div class="dates-filters-container row">
                <div class="col-6 date-input">
                    <div class="input-value">
                        <input style="visibility: hidden; display: none;"/>
                        <p-calendar #fromDateCalendar styleClass="date-picker" [(ngModel)]="fromDate" (click)="openDateCalendar('fromDate')" placeholder="{{'MANAGE_SHIPMENTS.FROM' | translate}}" dateFormat="dd/mm/yy"> </p-calendar>
                    </div>
                </div>
                <div class="col-6 date-input">
                    <div class="input-value">
                        <p-calendar #toDateCalendar styleClass="date-picker" [(ngModel)]="toDate" (click)="openDateCalendar('toDate')" placeholder="{{'MANAGE_SHIPMENTS.TO' | translate}}" dateFormat="dd/mm/yy"> </p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-input input-container" *ngIf="showSortBy">
            <div class="col-12 driver-input">
                <div class="input-label driver-label">{{'TICKETING_SYSTEM.SORT_BY' | translate}}</div>
                <div class="input-value">
                    <p-dropdown
                            styleClass="tickets-sort-by-list"
                            [(ngModel)]="selectedOrderByOption"
                            [options]="orderByOptions" filter="true"
                            (onChange)="chooseSortByOption($event)"
                            [style]="{'width':'100%'}"
                            filter="true">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="row row-input input-container">
            <div class="col-12 driver-input">
                <div class="input-label driver-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.THE_DRIVER' | translate}}</div>
                <div class="input-value">
                    <app-users-list-dropdown-component [user]="initialUser" [isDriver]="true" [isAsnDriver]="isAsnDriver" [inputStyleClass]="'width95'" (userChanged)="driverSelected($event)"> </app-users-list-dropdown-component>
                </div>
            </div>
        </div>
        <ng-container *ngIf="showConfig">
            <div class="input-container row">
                <div class="col-12">
                    <div class="switch-input inputs row d-flex" style="justify-content: space-evenly;">
                        <div class="switch-label">
                            <input style="margin-left: 10px; margin-right: 10px;" type="radio" [(ngModel)]="printType"
                                   value="HORIZONTAL_TEMPLATE">
                            <span>{{"TEMPLATE.HORIZONTAL_TEMPLATE" | translate}}</span>
                        </div>
                        <div class="switch-label">
                            <input style="margin-left: 10px; margin-right: 10px" type="radio" [(ngModel)]="printType"
                                   value="VERTICAL_TEMPLATE">
                            <span>{{"TEMPLATE.VERTICAL_TEMPLATE" | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="printType === 'HORIZONTAL_TEMPLATE'"
                 class="row row-input switch-buttons-container input-container">
                    <div class="switch-input d-flex">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.WITHOUT_SENDER_PHONE'|translate }}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withoutSenderPhone" binary="true"></p-inputSwitch>
                        </div>
                </div>
                    <div class="switch-input d-flex without-sender-name">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.WITHOUT_SENDER_NAME'|translate}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withoutSenderName" binary="true"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="switch-input d-flex">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.WITHOUT_RETURNED_PACKAGES' | translate | packageTitle}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withoutReturnedPackages" binary="true"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="switch-input d-flex without-sender-name">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.WITH_PICKUP_DATE' | translate | packageTitle}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withPickupDate" binary="true"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="switch-input d-flex">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.WITH_SUPPLIER_INVOICE' | translate | packageTitle}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withSupplierInvoice" binary="true"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="switch-input d-flex">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.IS_SHOW_PARTNER_NAME_IN_DRIVER_REPORT'|translate}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withPartnerName" binary="true"></p-inputSwitch>
                        </div>
                    </div>
            </div>
            <div *ngIf="printType === 'VERTICAL_TEMPLATE'"
                 class="row row-input switch-buttons-container input-container">
                    <div class="switch-input d-flex">
                        <div class="switch-label">{{'MANAGE_SHIPMENTS.WITH_RECEIVER_NAME'|translate}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withReceiverName" binary="true"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="switch-input d-flex">
                        <div class="switch-label">{{'COMPANY.IS_SHOW_PARTNER_NAME_IN_DRIVER_REPORT'|translate}}</div>
                        <div>
                            <p-inputSwitch [(ngModel)]="withPartnerName" binary="true"></p-inputSwitch>
                        </div>
                    </div>
            </div>
        </ng-container>
        <div class="row horizontal-splitter">
            <div class="splitter"></div>
        </div>
    </div>
    <div class="row bottom-actions">
        <div class="actions row d-inline-flex">
            <div class="cancel-action action d-inline-flex col-xs-12" (click)="closeModal()">
                <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.CANCEL' | translate}}</span>
            </div>
            <div class="splitter-container hidden-sm-down">
                <div class="splitter"></div>
            </div>
            <div *ngIf="showExportToExcel" class="export-btn-action action cursor-pointer col-xs-12" (click)="exportToExcel()" [ngClass]="{'disabled-btn disabled': !driverId || isExporting}">
                <div class="icon">
                    <i *ngIf="!isExporting" class="fas fa-file-excel"></i>
                    <i *ngIf="isExporting" class="fas fa-spinner fa-pulse"></i>
                </div>
                <div class="action-label">{{"SHIPMENT.EXCEL" | translate}}</div>
            </div>
            <div class="print-btn-action action-btn cursor-pointer col-xs-12" (click)="chooseDriver()" [ngClass]="{'disabled-btn disabled': !driverId || isLoading}">
                <div class="action-label">{{buttonLabel | translate }}</div>
            </div>
        </div>
    </div>
</div>
