import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent implements OnInit {
  public text = 'default';
  public isPrintReport = true;
  public printReport = false;
  public isLoading = false;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  public closeModal() {
    this.isLoading = false;
    this.activeModal.close({isSuccess: false});
  }
  public confirm() {
    this.activeModal.close({isSuccess: true, isPrintReport: this.printReport});
  }
}
