<div class="search-container">
    <div class="search-icon-container">
        <i class="fas fa-search"  [ngClass]="{'fa-rotate-90': selectedLanguage==='ar'}"></i>
    </div>
    <div class="input-field">
        <input type="search"
               #search
               placeholder="search for your project..."
               (change)="onInputChange(search.value)">
    </div>
</div>
