<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="default-shipping-rate pricing-container">
    <div *ngIf="!customerID" class="customize-pricing d-flex">
        <div class="add-button cursor-pointer" (click)="customizePricing()">
            <i class="fas fa-plus"></i>
            {{"PRICING.CUSTOMIZE" | translate }}
        </div>
        <div class="show-history cursor-pointer flex-2d-center" (click)="showHistory()">{{'TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.VIEW_HISTORY' | translate}}</div>
    </div>
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div *ngIf="customerID" class="customize-pricing">
        <div class="cod-title">
            {{name}}
        </div>
    </div>
    <div class="companyRegionsContainer">
        <app-region-regular-prices-table [pricingList] = isPricingList
                                         [driverPricing] = isDriverWorkArea
                                         [regions]="regions"
                                         [companyRegions]="companyRegions"
                                         [customerID]="customerID"
                                         [isHubsPricingList]="isHubsPricingList"
                                         (onRegionPriceChange)="onPriceChange($event)">
        </app-region-regular-prices-table>
    </div>

    <div>
        <p-accordion>
            <p-accordionTab *ngFor="let parcel of parcelPrices" header={{parcel.name}}>
                <app-region-regular-prices-table [driverPricing] = isDriverWorkArea
                                                 [regions]="regions" [companyRegions]="parcel.parcels"
                                                 [customerID]="customerID" [parcelID]="parcel.id"
                                                 [isHubsPricingList] = "isHubsPricingList"
                                                 (onRegionPriceChange)="onPriceChange($event)">
                </app-region-regular-prices-table>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
