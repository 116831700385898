<div class="documents-updates-main-container">
   <div class="documents-sections-container">
       <div class="added-recently documents-list-container">
           <div class="list-title">{{'SYSTEM_UPDATES.RECENTLY_ADDED' | translate}}</div>
               <div class="documents-list d-flex">
                   <ng-container *ngIf="!gettingRecentlyAdded; else recentlyAddedLoader">
                   <div *ngFor="let document of recentlyAdded" class="document-item d-flex flex-column cursor-pointer" (click)="openPdf(document)">
                       <div class="icon-container d-flex justify-content-center">
                           <img src="../../../../assets/icons/document-pdf.png" alt=""/>
                       </div>
                       <div class="desc d-flex justify-content-between">
                           <div class="desc d-flex flex-column">
                               <div class="document-name desc-text">
                                   {{selectedLanguage === 'ar' ? document.arabicName: document.name}}
                               </div>
                               <div class="document-sub-desc desc-text">
                                   {{selectedLanguage === 'ar' ? document.arabicSummary: document.summary}}
                               </div>
                               <div class="created-date">
                                   {{document.createdDate | date:'dd/MM/yyyy'}}
                               </div>
                           </div>
                           <div *ngIf="isSuperAdmin">
                               <div class="d-flex justify-content-end">
                                   <div class="cursor-pointer d-flex justify-content-center" style="width: 30px"
                                        (click)="triggerUpdateDropdown($event, updateOptions, document)">
                                       <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
           </ng-container>

               <ng-template #recentlyAddedLoader>
                   <div *ngFor="let loader of [1, 2, 3, 4, 5]" class="document-item d-flex flex-column cursor-pointer">
                       <app-skeleton-loader [isSingleLoader]="true"
                                            [type]="3">
                       </app-skeleton-loader>
                   </div>
               </ng-template>
           </div>
       </div>

       <div class="all-documents documents-list-container">
           <div class="list-title">{{'SYSTEM_UPDATES.ALL_DOCUMENTS' | translate}}</div>
           <div class="documents-list d-flex flex-wrap" (scroll)="onScrollDocuments($event)">
               <div *ngFor="let document of updatesData" class="document-item d-flex flex-column cursor-pointer" (click)="openPdf(document)">
                   <div class="icon-container d-flex justify-content-center">
                       <img src="../../../../assets/icons/document-pdf.png" alt=""/>
                   </div>
                   <div class="desc d-flex justify-content-between">
                       <div class="desc d-flex flex-column">
                           <div class="document-name desc-text">
                               {{selectedLanguage === 'ar' ? document.arabicName: document.name}}
                           </div>
                           <div class="document-sub-desc desc-text">
                               {{selectedLanguage === 'ar' ? document.arabicSummary: document.summary}}
                           </div>
                           <div class="created-date">
                               {{document.createdDate | date:'dd/MM/yyyy'}}
                           </div>
                       </div>
                       <div *ngIf="isSuperAdmin">
                           <div class="d-flex justify-content-end">
                               <div class="cursor-pointer d-flex justify-content-center" style="width: 30px"
                                    (click)="triggerUpdateDropdown($event, updateOptions, document)">
                                   <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               <ng-container *ngIf="loadingUpdates">
                   <div *ngFor="let loader of [1, 2, 3, 4, 5]" class="document-item d-flex flex-column cursor-pointer">
                       <app-skeleton-loader [isSingleLoader]="true"
                                            [type]="3">
                       </app-skeleton-loader>
                   </div>
               </ng-container>
           </div>
       </div>
   </div>
</div>

<p-overlayPanel #updateOptions [dismissable]="true" styleClass="update-popover">
    <div class="row-action-container">
        <div
                class="action cursor-pointer more-action-item" (click)="editUpdate()">
            <img class="edit-button" src="../../../assets/icons/manage_shipments/edit.svg"
                 alt="icon"/>
            {{'SYSTEM_UPDATES.EDIT' | translate}}
        </div>
        <div
                class="action cursor-pointer more-action-item" (click)="removeUpdate()">
            <img class="remove-button" src="../../../assets/icons/manage_shipments/remove.svg"
                 alt="icon"/>
            {{'SYSTEM_UPDATES.REMOVE' | translate | packageTitle}}
        </div>
    </div>
</p-overlayPanel>
