<div class="main-container">
    <p-progressSpinner *ngIf="loading" ></p-progressSpinner>
    <p-progressBar [value]="uploadProgress"></p-progressBar>
    <p-fileUpload
            #uploader
            chooseLabel="{{(isLcl ? 'ACTIONS.ADD_PHOTO' : 'ACTIONS.CHOOSE') | translate}}"
            cancelLabel="{{ 'ACTIONS.CANCEL' | translate }}"
            (onSelect)="onSelectImages($event)"
            (onRemove)="onRemoveImage($event)"
            (onClear)="onClear()"
            [disabled]="loading || disabled"
            [showUploadButton]="false"
            [maxFileSize]="maxFileSizeBytes"
            [accept]="acceptType"
            [showCancelButton]="!loading"
            [styleClass]="loading ? 'loading' : ''"
            invalidFileSizeMessageDetail="{{ 'ALERTS.UPLOAD_MAX_SIZE' | translate:{max: (maxFileSizeBytes / 1000000)} }}"
            invalidFileTypeMessageDetail="{{ 'ALERTS.UNSUPPORTED_FILE_FORMAT' | translate }}"
            [multiple]="multiple">

    </p-fileUpload>
</div>
