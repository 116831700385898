import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LclItemModel} from '../../add-package-form/add-lcl-items/lcl-item.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HubsService} from '../../shared/services/hubs.service';
import {createQueryParams} from '../../shared/services/helpers';
import {PackageModel} from '../../shared/models/package.model';
import {ShipmentsService} from '../services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BARCODE_PATTERN} from '../../shared/services/shared_constants/constants';
import {UserService} from '../../shared/services/user.service';
import {ChooseExistingComponent} from '../choose-existing/choose-existing.component';
import {UserModel} from '../../shared/models/user.model';
import {ContainerSize} from '../../shared/enums/ContainerSize.enum';

@Component({
    selector: 'app-receive-packing-list',
    templateUrl: './receive-packing-list.component.html',
    styleUrls: ['./receive-packing-list.component.scss']
})
export class ReceivePackingListComponent implements OnInit {
    currentLang: string;
    isReading = false;
    isLoading = false;

    selectedHub: any;
    hubs: any[] = [];
    fetchedHubs: any[] = [];
    totalHubsNo = 0;
    isLoadingHubs = false;

    selectedDestinationHub: any;
    destinationHubs: any[] = [];
    fetchedDestinationHubs: any[] = [];
    totalDestinationHubsNo = 0;
    isLoadingDestinationHubs = false;

    selectedPackage: PackageModel;

    filterParams = {};

    Object = Object;
    items: { [barcode: number]: LclItemModel } = {};
    totalItemsNo = 0;
    totalScannedItems = 0;
    inputBarcode: string;
    containerNumber: string;

    // Fields for Create Container Mode
    objectValues = Object.values;
    isCreateContainer = false;
    packages: {[barcode: string]: {pkg: PackageModel, scannedItems: number}} = {};
    filteredPackages: PackageModel[] = [];
    barcodeSearch: string;
    isReceivingPkgsWithoutScanItems = false;
    isNaN: any = isNaN;
    readonly containerSizes = [
        {
            name: '20 DC',
            value: ContainerSize.DRY_CONTAINER_20
        },
        {
            name: '40 DC',
            value: ContainerSize.DRY_CONTAINER_40
        },
        {
            name: '40 HC',
            value: ContainerSize.HIGH_CUBE_40
        },
        {
            name: '40 RF',
            value: ContainerSize.REEFER_40
        },
        {
            name: '20 RF',
            value: ContainerSize.REEFER_20
        },
        {
            name: '20 OT',
            value: ContainerSize.OPEN_TOP_20
        },
        {
            name: '40 OT',
            value: ContainerSize.OPEN_TOP_40
        },
    ];
    containerSize: any;

    constructor(private translateService: TranslateService,
                private hubsService: HubsService,
                private shipmentService: ShipmentsService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private userService: UserService,
                private modalService: NgbModal,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isReceivingPkgsWithoutScanItems = this.userService.userInfo.isReceivingPkgsWithoutScanItems;
        this.initFilterParams();
        this.getHubs(true);
    }

    private initFilterParams() {
        this.filterParams = {
            HUBS: {
                page: 1,
                pageSize: 20,
                search: ''
            },
            DESTINATION_HUBS: {
                page: 1,
                pageSize: 20,
                search: ''
            }
        };
    }

    private getHubs(forceFetch = false) {
        this.fetchedHubs = [];
        if (this.totalHubsNo === this.hubs.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams['HUBS'].page = 1;
        }
        this.isLoadingHubs = true;
        this.hubsService.getHubs(createQueryParams(this.filterParams['HUBS'])).subscribe(
            (hubsResponse: any) => {
                this.fetchedHubs = hubsResponse['hubs'];
                this.totalHubsNo = hubsResponse['totalRecordsNo'];
                if (forceFetch) {
                    this.hubs = this.fetchedHubs;
                } else {
                    this.hubs.push(...this.fetchedHubs);
                }
                this.isLoadingHubs = false;
            }, () => {
                this.isLoadingHubs = false;
            }
        );
    }

    private getDestinationHubs(forceFetch = false) {
        this.fetchedDestinationHubs = [];
        if (this.totalDestinationHubsNo === this.destinationHubs.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams['DESTINATION_HUBS'].page = 1;
        }
        this.isLoadingDestinationHubs = true;
        this.hubsService.getHubs(createQueryParams(this.filterParams['DESTINATION_HUBS'])).subscribe(
            (hubsResponse: any) => {
                this.fetchedDestinationHubs = hubsResponse['hubs'];
                this.totalDestinationHubsNo = hubsResponse['totalRecordsNo'];
                if (forceFetch) {
                    this.destinationHubs = this.fetchedDestinationHubs;
                } else {
                    this.destinationHubs.push(...this.fetchedDestinationHubs);
                }
                this.isLoadingDestinationHubs = false;
            }, () => {
                this.isLoadingDestinationHubs = false;
            }
        );
    }

    private loadLazyHubs() {
        this.filterParams['HUBS'].page++;
        this.getHubs();
    }

    closeModal() {
        this.translateService.get(['GENERAL.YES', 'GENERAL.NO', 'ACTIONS.CONFIRM_CANCEL']).subscribe(values => {
            this.confirmationService.confirm({
                message: values['ACTIONS.CONFIRM_CANCEL'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.activeModal.close();
                }
            });
        });
    }

    readBarcode(barcode: string) {
        if (this.isReading) {
            return;
        }
        this.isReading = true;
        if (!barcode.match(BARCODE_PATTERN.LCL_ITEM)) {
            if (this.isCreateContainer) {
                if (this.packages[barcode]) {
                    this.translateService.get('ALERTS.PACKAGE_ALREADY_SCANNED').subscribe(value => {
                        this.messageService.add({
                            severity: 'success',
                            detail: value
                        });
                    });
                    this.isReading = false;
                    this.inputBarcode = '';
                } else {
                    this.shipmentService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true)
                        .subscribe((response: PackageModel) => {
                        this.packages[response.barcode] = {
                            pkg: response,
                            scannedItems: 0
                        };
                        if ((this.barcodeSearch && response.barcode.startsWith(this.barcodeSearch)) || !this.barcodeSearch) {
                            this.filteredPackages.push(response);
                        }
                        this.selectPackage(response);
                        if (response.packageItems) {
                            this.totalItemsNo += response.packageItems.length;
                        }
                        this.isReading = false;
                        this.inputBarcode = '';
                    }, () => {
                        this.isReading = false;
                    });
                }
            } else {
                if (!this.selectedPackage || this.inputBarcode) {
                    this.shipmentService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true)
                        .subscribe((response: PackageModel) => {
                        this.selectPackage(response);
                        this.isReading = false;
                        this.inputBarcode = '';
                    }, () => {
                        this.isReading = false;
                    });
                }
            }
        } else {
            this.readItemBarcode(barcode);
        }
    }

    readItemBarcode(barcode: string) {
        if (this.items[barcode]) {
            if (this.items[barcode].scanned) {
                this.translateService.get('LCL.ALERTS.ITEM_ALREADY_SCANNED').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
            } else {
                this.items[barcode].scanned = true;
                if (this.isCreateContainer) {
                    this.packages[this.selectedPackage.barcode].scannedItems++;
                }
                this.totalScannedItems++;
            }
        } else {
            this.translateService.get('LCL.ALERTS.ITEM_NOT_FOUND').subscribe(value => {
                this.messageService.add({
                    severity: 'error',
                    detail: value
                });
            });
        }
        this.isReading = false;
        this.inputBarcode = '';
    }

    searchHub(search: string) {
        this.filterParams['HUBS'].search = search;
        this.getHubs(true);
    }

    searchDestinationHub(search: string) {
        this.filterParams['DESTINATION_HUBS'].search = search;
        this.getDestinationHubs(true);
    }

    onHubsScroll(scrollEvent: any) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyHubs();
        }
    }

    selectHub(hub: any) {
        this.selectedHub = hub;
    }

    trackHubById(index: number, item: LclItemModel) {
        return item.id;
    }
    disableSubmitButton() {
        const  condition = this.isLoading || !this.totalItemsNo  || !this.selectedPackage || !this.selectedHub;
        return this.isCreateContainer ? this.isLoading || !this.selectedHub || !this.containerNumber || !this.selectedDestinationHub || !this.containerSize : condition;
    }
    disableSubmitTooltip() {
        if (!this.isReceivingPkgsWithoutScanItems) {
            return !this.selectedPackage || this.totalScannedItems === this.totalItemsNo;
        }
        return true;
    }
    onSubmit() {
        // const extraCondition = !this.isReceivingPkgsWithoutScanItems && this.isCreateContainer && this.totalScannedItems !== this.totalItemsNo;
        // if (this.isLoading || !this.totalItemsNo || !this.selectedPackage ||
        //     !this.selectedHub || !this.selectedDestinationHub || !this.containerNumber || extraCondition) {
        //     return;
        // }
        if (!this.disableSubmitTooltip()) {
            return;
        }


        this.translateService.get(['GENERAL.YES', 'GENERAL.NO', 'LCL.ALERTS.CONFIRM_CREATE_CONTAINER', 'LCL.ALERTS.CONTAINER_CREATED_SUCCESSFULLY',
            'LCL.ALERTS.CONFIRM_RECEIVE_ITEMS', 'LCL.ALERTS.ITEMS_RECEIVED_SUCCESSFULLY']).subscribe(values => {
            this.confirmationService.confirm({
                message: this.isCreateContainer ? values['LCL.ALERTS.CONFIRM_CREATE_CONTAINER'] : values['LCL.ALERTS.CONFIRM_RECEIVE_ITEMS'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.isLoading = true;
                    if (this.isCreateContainer) {
                        const body = {
                            sourceHubId: this.selectedHub.id,
                            destinationHubId: this.selectedDestinationHub.id,
                            pkgIds : this.objectValues(this.packages).map(pkg => pkg.pkg.id),
                            containerNumber : this.containerNumber,
                            containerSize: this.containerSize
                        };
                        this.shipmentService.createContainerBundle(body).subscribe(() => {
                            this.reset();
                            this.isLoading = false;
                        }, () => {
                            this.isLoading = false;
                        });

                    } else {
                        const body = {
                            ids: [this.selectedPackage.id],
                            hubId: this.selectedHub.id,
                            status: 'SCANNED_BY_HANDLER_AND_UNLOADED'
                        };
                        this.shipmentService.changePackageStatus(body).subscribe(() => {
                            this.messageService.add({
                                severity: 'success',
                                detail: values['LCL.ALERTS.ITEMS_RECEIVED_SUCCESSFULLY']
                            });
                            this.reset();
                            this.isLoading = false;
                        }, () => {
                            this.isLoading = false;
                        });
                    }
                }
            });
        });
    }

    reset() {
        this.packages = {};
        this.filteredPackages = [];
        this.items = {};
        this.selectedPackage = null;
        this.inputBarcode = '';
        this.barcodeSearch = '';
        this.totalItemsNo = 0;
        this.totalScannedItems = 0;
        this.containerNumber = null;
        this.selectedHub = null;
        this.selectedDestinationHub = null;
    }

    trackPackageById(index: number, item: PackageModel) {
        return item.id;
    }

    selectPackage(pkg: PackageModel) {
        this.selectedPackage = pkg;
        if (!this.isCreateContainer && this.selectedPackage.packageItems) {
            this.totalItemsNo = this.selectedPackage.packageItems ? this.selectedPackage.packageItems.length : 0;
        }
        this.items = {};
        if (this.selectedPackage.packageItems) {
            this.selectedPackage.packageItems.forEach((packageItem) => {
                this.items[packageItem.barcode] = packageItem;
            });
        }
    }

    deletePackage(pkg: PackageModel) {
        if (this.selectedPackage && this.selectedPackage.id === pkg.id) {
            this.selectedPackage = null;
        }
        if (pkg.packageItems) {
            this.totalItemsNo -= pkg.packageItems.length;
        }
        this.totalScannedItems -= this.packages[pkg.barcode].scannedItems;
        delete this.packages[pkg.barcode];
        const pkgIndex = this.filteredPackages.findIndex(filteredPackage => filteredPackage.id === pkg.id);
        if (pkgIndex >= 0) {
            this.filteredPackages.splice(pkgIndex, 1);
        }
    }

    filterPackages() {
        if (this.barcodeSearch) {
            this.filteredPackages = Object.values(this.packages)
                .filter(pkg => pkg.pkg.barcode.startsWith(this.barcodeSearch))
                .map(pkg => pkg.pkg);
        } else {
            this.filteredPackages = Object.values(this.packages).map(pkg => pkg.pkg);
        }
    }
}
