import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-map-w3w',
  templateUrl: './map-w3w.component.html',
  styleUrls: ['./map-w3w.component.scss']
})
export class MapW3wComponent implements OnInit {
  what3wordsAddress = '';
  coordinates: any = {};
  @Input() readOnly = false;
  mapKey = null;
  apiKey = null;
  words = null;

  constructor(private userService: UserService,
              private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.mapKey = this.userService.userInfo.googleMapKey;
    this.apiKey = this.userService.userInfo.what3wordsKey;
    this.words = 'driftwood.innovate.deflated';
  }

  saveLocation() {
    const w3wMap = document.getElementById('map-key');
    const value = w3wMap['value'].substring(3);
    this.activeModal.close({
      words: value
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
