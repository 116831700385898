import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-mob',
    templateUrl: './mob.component.html',
    styleUrls: ['./mob.component.scss']
})
export class MobComponent implements OnInit {
    public isLoading = false;
    public form: FormGroup;

    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private usersService: UsersService,
                private translateService: TranslateService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.isLoading = false;
        this.initInfo();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            cash: ['', Validators.required],
            bankTransfer: [0],
            cheque: [0],
            bankTransferDocumentNumber: [''],
            chequeDocumentNumber: [''],
        });
    }

    public closeModal() {
        this.activeModal.close();
    }

    public mob() {
        const body = this.form.getRawValue();
        this.usersService.mob(body).subscribe(
            (response: any) => { // redirect to  receive page
                this.translateService.get(
                    'ALERTS.MOB_ADDED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                            this.activeModal.close({isSuccess: true});
                        }
                    );
            }
            , (error) => { // Driver not found
                this.translateService.get(
                    'ALERTS.FAILED_TO_ADD_MOB'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'error', detail: data});
                            this.isLoading = false;
                        }
                    );
            });
    }

    public changeDocumentRequired() {
        if (this.form.value.bankTransfer === '') {
            this.form.controls['bankTransfer'].setValue(0);
            this.form.controls['bankTransfer'].updateValueAndValidity();
        }
        if (this.form.value.cheque === '') {
            this.form.controls['cheque'].setValue(0);
            this.form.controls['bankTransfer'].updateValueAndValidity();
        }
        // Bank transfer
        if (this.form.value.bankTransfer === 0) {
            this.form.controls['bankTransferDocumentNumber'].setValidators([]);
            this.form.controls['bankTransferDocumentNumber'].setValue(undefined);
            this.form.controls['bankTransferDocumentNumber'].updateValueAndValidity();
        } else {
            this.form.controls['bankTransferDocumentNumber'].setValidators([Validators.required]);
            this.form.controls['bankTransferDocumentNumber'].setValue(undefined);
            this.form.controls['bankTransferDocumentNumber'].updateValueAndValidity();
        }

        // Cheque
        if (this.form.value.cheque === 0) {
            this.form.controls['chequeDocumentNumber'].setValidators([]);
            this.form.controls['chequeDocumentNumber'].setValue(undefined);
            this.form.controls['chequeDocumentNumber'].updateValueAndValidity();
        } else {
            this.form.controls['chequeDocumentNumber'].setValidators([Validators.required]);
            this.form.controls['chequeDocumentNumber'].setValue(undefined);
            this.form.controls['chequeDocumentNumber'].updateValueAndValidity();
        }
    }

}
