<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="returned-packages-view d-flex flex-column" [ngClass]="{'page-ltr': selectedLanguage === 'en'}"
     appBarcodeScanner (barcodeRead)="readByBarcodeScanner($event)">
    <div id="submitting-container" *ngIf="isPageLoading">
        <p-progressSpinner> </p-progressSpinner>
    </div>
    <ng-template #CustomerRegionDialog let-close="close">
        <app-customer-region-filter [isSender]="false" [senderData]="senderRegionData"
                                    (onCancel)="closeSenderFilter()"
                                    (onFinish)="onFilterSenderReceiverRegion($event)"></app-customer-region-filter>
    </ng-template>

    <div class="header-row-container">
        <div class="d-flex flex-row">
            <div class="number-of-customers d-flex">
                <div class="label">{{'RETURNED_PACKAGES.PACKAGES_NUMBER'|translate}}</div>
                <div style="width: 12px;"></div>
                <div class="badge-number">
                    <span>{{totalRecords}}</span>
                </div>
            </div>
            <div class="header-filters d-flex flex-row return-packages-header">
                <div class="hidden-sm-down">
                    <div class="toggle-show-actions d-flex">
                        <div class="label">{{ 'MANAGE_SHIPMENTS.TOOLBAR' | translate }}</div>
                        <div style="width: 12px;"></div>
                        <div class="show-actions-switch">
                            <p-inputSwitch [(ngModel)]="showActions"></p-inputSwitch>
                        </div>
                    </div>
                </div>
                <div *ngIf="!deliverToSender" class="receive-returned-packages" (click)="unloadPackages(false)" [ngClass]="{'disabled-btn': !isThereSelection || isScanning}">
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.DELIVER_TO_SENDER' | translate}}</div>
                </div>
                <div *ngIf="!deliverToSender && !inCar" class="return-packages-action" (click)="pickWithDriver()" [ngClass]="{'disabled-btn': !isThereSelection || isScanning}">
                    <div class="sub-label" align="center">{{"ACCOUNTING.PICK_WITH_DRIVER" | translate}}</div>
                </div>
                <div *ngIf="!deliverToSender && !inCar" class="return-packages-action print-action d-flex" [ngClass]="{'disabled-btn': !isThereSelection || isScanning || isPrinting }" (click)="printReport()">
                    <div class="print-icon">
                        <img *ngIf="!isPrinting" src="../../assets/icons/containers-management/print.svg" alt="" />
                        <i *ngIf="isPrinting" class="fas fa-spinner fa-pulse"></i>
                    </div>
                    <div style="width: 12px"></div>
                    <div class="sub-label d-flex" style="justify-content:center; align-items: center" align="center">
                        {{ 'ACTIONS.PRINT' | translate }}
                    </div>
                </div>
                <div class="toggle-filters-btn" (click)="showFiltersMenu($event)">
                    <div class="icon">
                        <img *ngIf="!filterMenuOpen" src="../../../../assets/icons/filter-icon.png"/>
                        <img *ngIf="filterMenuOpen" src="../../../../assets/icons/active-filter.png"/>
                    </div>
                </div>
                <div class="hidden-sm-down">
                    <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>
                </div>
                <div *ngIf="!withoutCustomerFilter" class="customer-filter d-flex">
                    <app-users-list-dropdown-component
                            [isDriver] = "false"
                            [isHub]="false"
                            [isPartner]="false"
                            [placeHolder] = "'RETURNED_PACKAGES.CUSTOMER_NAME'"
                            (userChanged)="onCustomerFilterChange($event)"
                            [panelWidth] = "120">
                    </app-users-list-dropdown-component>
                </div>

                <div class="types-list-container">
                    <p-dropdown styleClass="date-filter-dropdown"
                                [(ngModel)]="selectedType"
                                [options]="typesList" filter="true"
                                (onChange)="onSearch()">
                    </p-dropdown>
                </div>

                <div class="search-container">
                    <i class="fas fa-search"></i>
                    <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                           [(ngModel)]="searchContent"
                           appInputDebounced
                           (inputDebounced)="onSearch()">
                </div>
            </div>
        </div>

        <div class="hidden-sm-down" *ngIf="!deliverToSender">
            <div *ngIf="showActions" class="d-flex flex-wrap actions-container">
                <div class="action-container">
                    <div class="gradient-action action" (click)="receiveReturnedPackages()">
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RETURNED_PACKAGES' | translate}}</div>
                    </div>
                </div>
                <div class="actions-splitter"></div>
                <div class="action-container">
                    <div class="secondary-action-btn action" (click)="receivePackagesFromDriverOrPartner()">
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.FROM_DRIVER' | translate}}</div>
                    </div>
                </div>
                <div class="action-container" *ngIf="hasThirdParty">
                    <div class="secondary-action-btn action" (click)="receivePackagesFromDriverOrPartner(false, true)">
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.FROM_THIRD_PARTY' | translate}}</div>
                    </div>
                </div>
                <div class="action-container">
                    <div class="secondary-action-btn action" (click)="receivePackagesFromDriverOrPartner(true)">
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.FROM_PARTNER' | translate}}</div>
                    </div>
                </div>
                <div class="action-container" *ngIf="showPartiallyExport">
                    <div class="secondary-action-btn action" [ngClass]="{'disabled-btn disabled': !isThereSelection}" (click)="partialExport()">
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.EXPORT' | translate}}</div>
                        <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.PARTIALLY_REPORT' | translate}}</div>
                    </div>
                </div>
                <div class="action-container d-flex">
                    <div class="excel-button m-auto"
                         (click)="exportExcel()">
                        <i class="fas fa-file-excel"></i>
                        {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                    </div>
                </div>
                <ng-container *ngIf="allReturned && isSupportReturnedBundles">
                    <div class="action-container" *ngIf="customerId">
                        <div class="secondary-action-btn action" (click)="createBundleFromSelected()">
                            <div class="sub-label" align="center">{{'SHIPMENT.ADD_BUNDLE_FOR_CUSTOMER' | translate}}</div>
                        </div>
                    </div>

                    <div class="action-container">
                        <div class="secondary-action-btn action" (click)="createBundle()">
                            <div class="sub-label" align="center">{{'SHIPMENT.ADD_BUNDLE' | translate}}</div>
                        </div>
                    </div>

                </ng-container>

            </div>
        </div>
    </div>
    <div class="cod-mismatch-table flex-1 overflow-auto" (scroll)="onScrollTable($event)" #tableContainer>
        <p-table
            #returnedTable
            appPTableRtlFix
            dataKey="id"
            [value]="returnedPackages"
            [loading]="isLoading"
            [scrollable]="true"
            scrollHeight="auto"
            [lazy]="true"
            [responsive]="true"
            [styleClass]="'h-100'"
            [rows]="10"
            [totalRecords]="totalRecords">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component> </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="!deliverToSender" style="width: 4%;">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true" (onChange)="onToggleSelectAll()"></p-checkbox>
                    </th>
                    <th style="width: 140px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th *appPermission="permissions.includes('{EDIT_COST')" style="width: 5%;">{{"MANAGE_SHIPMENTS.COST" | translate}}</th>
                    <th style="width: 6%;">{{"MANAGE_SHIPMENTS.COD" | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.CUSTOMER" | translate }}</th>
                    <th>{{"MANAGE_SHIPMENTS.PHONE" | translate }}</th>
                    <th style="width: 125px !important;height: 64px" class="header-multiselect receiver-th pt-0">
                         <span class="sender-receiver-filter-label active-sender-receiver-filter-label" > {{"MANAGE_SHIPMENTS.TO" | translate}}</span>
                        <div
                                class="filter-button cursor-pointer active-filter-button"
                                #senderRegionPopOver="ngbPopover"
                                placement="auto"
                                autoClose="outside"
                                [ngbPopover]="CustomerRegionDialog"
                                container="body"
                        >
                            <div class="row justify-content-start customize-sender-receiver-filter">
                                <div class="icon-customize"
                                     [ngClass]="{'icon-customize-en': selectedLanguage === 'en'}">
                                    <i style="margin-left: 5px;" class="fas fa-pencil-alt"></i>
                                </div>
                                <div class="customize-label"
                                     [ngClass]="{'customize-label-en': selectedLanguage === 'en'}">{{"DASHBOARD.CUSTOMIZE" | translate}}</div>
                            </div>
                        </div>
                    </th>
                    <th>{{"MANAGE_SHIPMENTS.BOOKED_ON" | translate}}</th>
                    <th>{{'RETURNED_PACKAGES.RETURNED_RECEIVED_DATE' | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.STATUS" | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.INVOICE" | translate}}</th>
                    <th *ngIf="!inCar && !deliverToSender">
                        <div class="header-filter">
                            <span class="shipment-status-title"
                                  [ngClass]="{'active-status-title': selectedHub}">
                                {{"USERS.HUB" | translate | hubTitle}}
                            </span>
                            <div class="manage-shipment-status-filter"
                                 [ngClass]="{'active-shipment-status-filter': selectedHub}"
                                 style="z-index: 3 !important;">
                                <app-users-list-dropdown-component [isHub]="true" [user]="selectedHub"
                                                                   #hubFilter
                                                                   [appendTo]="'body'"
                                                                   [panelWidth]="'100%'"
                                                                   (userChanged)="onSelectedHub($event)"></app-users-list-dropdown-component>
                            </div>
                        </div>
                    </th>
                    <th>{{"MANAGE_SHIPMENTS.NOTES"|translate}}</th>
                    <th *ngIf="deliverToSender">{{'RETURNED_PACKAGES.CONFIRMATION_DATE' | translate}}</th>
                    <th *ngIf="isScanning">{{"ACCOUNTING.SCAN_PACKAGE"|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr [ngClass]="{'scanned-row':isPackageScanned(package.id)}" (click)="trackShipment(package)" [ngClass]="{'bundle-row': package.bundleId}">
                    <td *ngIf="!deliverToSender" (click)="$event.stopPropagation()">
                        <p-checkbox [(ngModel)]="package.isSelected" binary="true" (onChange)="onToggleSelection($event)"></p-checkbox>
                    </td>
                    <td class="barcode-td" style="text-align: center; width: 140px;">
                        <div class="barcode-container d-flex">
                            <div class="barcode-value"><span>{{package.barcode}}</span></div>
                            <div style="width: 12px;"></div>
                            <div class="copy-icon cursor-pointer">
                                <img src="../../assets/icons/track_package/copy.svg" alt="" (click)="copyMessage($event,null, package.barcode, 'BARCODE')" data-toggle="tooltip" data-placement="top" title="Copy" class="copy_icon" />
                            </div>
                        </div>
                    </td>
                    <td *appPermission="permissions.includes('{EDIT_COST')" style="width: 5%; font-family: Roboto;">{{package.cost}}</td>
                    <td style="width: 6%;">
                        <span style="color: #00bd9f; font-family: Roboto;"> {{package.cod}} </span>
                    </td>
                    <td>{{package.customerName}}</td>
                    <td>
                        <div class="phone-section flex-2d-center">
                            <div>
                                <img class="phone-image" src="../../../assets/icons/phone.svg" />
                            </div>
                           <div>
                               <p>
                                   <a href="tel:+{{package?.receiverPhone}} "> {{ package?.receiverPhone }} </a>
                               </p>
                           </div>
                        </div>
                    </td>
                    <td style="width: 125px !important;">
                        {{package.receiverFirstName}} {{package.receiverMiddleName}} {{package.receiverLastName}}<br />
                        {{package.destinationAddress.city }} - {{package.destinationAddress.village }} - {{package.destinationAddress.addressLine1}} {{(package.destinationAddress.addressLine2) ? ("- " +
                        package.destinationAddress.addressLine2) : ''}}
                    </td>
                    <td>{{package.createdDate | date:'dd/MM/yyyy'}}</td>
                    <td>{{package.returnReceiveDate | date:'dd/MM/yyyy'}}</td>
                    <td>
                        {{package.status | translate}}
                        <ng-container [ngSwitch]="package.status">
                            <!-- the same view can be shown in more than one case -->
                            <div *ngIf="customizationCompaniesService.checkCompanies(companies.SPRINT) && package.bundleId">
                                {{"SHIPMENT.ADDED_TO_BUNDLE"| translate}}
                            </div>
                            <div *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">
                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                            </div>
                            <div *ngSwitchCase="'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'">
                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                            </div>
                            <div *ngSwitchCase="'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'">
                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                            </div>
                            <div *ngSwitchCase="'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'">
                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                            </div>
                            <div *ngSwitchCase="'SCANNED_BY_HANDLER_AND_UNLOADED'">
                                {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                            </div>
                            <div *ngSwitchCase="'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'">
                                {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                            </div>
                            <div *ngSwitchCase="'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'">
                                {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                            </div>
                            <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                <ng-container [ngSwitch]="package.returnedPackageLocation">
                                    <div *ngSwitchCase="'IN_HUB'">
                                        <br />
                                        {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}} : {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                    </div>
                                    <div *ngSwitchCase="'IN_CAR'">
                                        <br />
                                        {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{package.driverName}}
                                    </div>
                                    <div *ngSwitchCase="'DELIVERED_TO_SENDER'">
                                        <br />
                                        {{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER'|translate}}
                                    </div>
                                    <span *ngSwitchDefault></span>
                                </ng-container>
                            </div>
                            <span *ngSwitchDefault></span>
                        </ng-container>
                    </td>
                    <td>{{package.invoiceNumber}}</td>
                    <td *ngIf="!inCar && !deliverToSender">{{package.hubName}}</td>
                    <td>{{package.notes}}</td>
                    <td *ngIf="deliverToSender">{{package.returnConfirmDate | date:'dd/MM/yyyy'}}</td>
                    <td *ngIf="isScanning && !isPackageScanned(package.id)">
                        <div class="table-actions-container">
                            <div class="scan-action action cursor-pointer" (click)="scanPackage(package); $event.stopPropagation()">
                                <i class="fas fa-barcode"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isScanning && isPackageScanned(package.id)">
                        <div class="table-actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackage(package); $event.stopPropagation()">
                                <i class="fas fa-minus-circle"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr *ngIf="isLoading">
                    <td [attr.colspan]="12">
                        <div class="shipment-table-loader">
                            <div class="loader drivers-list-loader">
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                            </div>
                            <div class="loader" *ngIf="returnedPackages.length === 0">
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                <app-skeleton-loader type="2"></app-skeleton-loader>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<app-shipment-tracker [isShow]="isTrackingPackage" (onCloseTracker)="closeTracker($event)"
                      [packageBarcode]="triggeredShipment?.barcode"
                      class="component">
</app-shipment-tracker>

<p-overlayPanel #filtersMenu
                class="tickets-filters-menu"
                [dismissable]="true"
                (onHide)="onHideFiltersMenu()"
                [showCloseIcon]="false">
    <div class="filter-container" *ngIf="formInitiated" [ngClass]="{'page-ltr': selectedLanguage === 'en'}">
        <div class="filters-menu-actions">
            <div class="reset-action filter-menu-action mb-4">
                <span>{{'GENERAL.FILTER_INFORMATION_BY' | translate}}</span>
            </div>
        </div>
        <div  class="d-flex flex-column filter filters-group">
            <div [formGroup]="datesFilterForm">
                <div class="filter-item">
                    <label class="label">
                        {{'GENERAL.SORT_BY' | translate}}
                    </label>
                    <p-dropdown [options]="sortByList" styleClass="shipment-type"
                                formControlName="sortBy" (onChange)="onShipmentsTypeChanged()"
                                dataKey="value" optionLabel="label"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="filters-menu-actions">
            <div class="reset-action filter-menu-action" (click)="resetAllFilters()">
                <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
            </div>
        </div>
    </div>

</p-overlayPanel>

