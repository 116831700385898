<div class="h-100 d-flex flex-column">
  <div class="top-actions">
    <div class="group-tabs-container">
      <div *ngFor="let tab of tabsList"
           class="pending-tab cursor-pointer tab"
           (click)="onTabChange(tab)"
           [ngClass]="{'selectedTab':selectedTab === tab}">
        {{('PENDING_APPROVAL_TABS.' + tab.label) | translate}}
      </div>
    </div>
  </div>
  <div class="flex-1 overflow-auto">
    <app-manage-shipments-component [isFollowUpType] = "selectedTab.status" [isDeleted]="selectedTab.label === 'DELETED'"></app-manage-shipments-component>
  </div>
</div>
