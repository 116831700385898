import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appPassword]'
})
export class AppPasswordDirective implements OnInit{
    @Input() removeEyeIcon;
    private _shown = false;

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.setup();
    }

    toggle(span: HTMLElement) {
        this._shown = !this._shown;
        if (this._shown) {
            this.el.nativeElement.setAttribute('type', 'text');
        } else {
            this.el.nativeElement.setAttribute('type', 'password');
        }
    }

    setup() {
        const parent = this.el.nativeElement.parentNode;
        // parent.querySelector('#label').style.top = '30%';
        const span = document.createElement('i');
        if (this.removeEyeIcon) {
            span.className = 'show-password-icon cursor-pointer';
        } else {
            span.className = 'far fa-eye show-password-icon cursor-pointer';
        }
        span.addEventListener('click', (event) => {
            this.toggle(span);
        });
        parent.appendChild(span);
    }

}
