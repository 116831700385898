import {Component, Input, OnChanges} from '@angular/core';
import {EmailModel} from '../models/email.model';
import {EmailReplyModel} from '../models/email-reply';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {InboxService} from '../services/inbox.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.scss']
})
export class EmailDetailsComponent implements OnChanges {
    /**
     *
     */
    @Input() email: EmailModel;

    /**
     *
     */
    public emailReplies: EmailReplyModel[];

    /**
     *
     * @type {SpinnerState.LOADING}
     */
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;

    public isSendingReply = false;
    public replyContent = '';
    public lang;

    constructor(
        private inboxService: InboxService,
        private translate: TranslateService
    ) {
        this.lang = this.translate.currentLang;
    }

    ngOnChanges() {
        this.getAllReplies();
    }

    public getAllReplies() {
        this.spinnerState = SpinnerState.LOADING;
        this.inboxService.getEmailReplies(this.email.id).subscribe(
            (replies: any) => {
                this.emailReplies = replies;
                this.spinnerState = SpinnerState.LOADED;
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    public sendReply() {
        // if there is no content. return.
        if (this.replyContent.trim().length === 0) {
            return;
        }

        this.isSendingReply = true;

        this.inboxService.sendReply(this.email.id, this.replyContent).subscribe(
            (response) => {
                this.replyContent = '';
                this.isSendingReply = false;
                this.getAllReplies();
            }, (error) => {
                this.isSendingReply = false;
            }, () => {
                this.isSendingReply = false;
            }
        );
    }

    /**
     * @param $event: Event Object,
     * if the key was Enter and the message body was not empty,
     * send the message
     */
    public onKeypress($event) {
        if ($event.keyCode === 13 && this.replyContent.trim() !== '') {
            this.sendReply();
        }
    }
}
