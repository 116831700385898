import {Pipe, PipeTransform} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {UserService} from '../services/user.service';

@Pipe({
    name: 'currencyValue'
})
export class CurrencyValuePipe implements PipeTransform {

    constructor(private userService: UserService) {
    }

    transform(value: any, currency?, args?: any): any {
        if (!currency) {
            currency = this.userService.getCurrency();
        }
        switch (currency) {
            case 'NIS':
                return value + ' ₪';
            case 'USD':
                return value + ' $';
            case 'EUR':
                return value + ' €';
            case 'TRY':
                return value + ' ₺';
            default:
                return value + ' ' + currency;
        }
    }
}

