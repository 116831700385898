<div class="email-container row cursor-pointer {{class}}" [ngClass]="{'opened': email?.isOpened}">
  <div class="email-icon">
    <img *ngIf="!email?.isOpened" src="../../../assets/icons/inbox/envelope-regular.svg">
    <img *ngIf="email?.isOpened" src="../../../assets/icons/inbox/envelope-open-regular.svg">
  </div>
  <div class="email-title">
    <div class="row-1">
      <div class="email-owner" ellipsis [ellipsis-content]="getSenderName()" [tooltip]="receiverName"></div>
      <div class="email-date">
        {{email?.createdDate | date: 'hh:mm dd/MM/yyyy'}}
      </div>
    </div>

    <div class="email-header" ellipsis [ellipsis-content]="email?.header"></div>

    <div class="email-body" ellipsis [ellipsis-content]="email?.body"></div>
  </div>
</div>
