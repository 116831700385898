<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <p-sidebar [(visible)]="display"
               (onHide)="onClose()"
               [dismissible]="false"
               #AddTicketSideBar
               [baseZIndex]="2"
               position="{{currentLang === 'en' ? 'right': 'left'}}"
               styleClass="add-ticket-sidebar">
        <div class="spinner-drape" *ngIf="isLoading">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <!--    <form [formGroup]="addTicketForm" *ngIf="isLoading === spinnerStates.LOADED">-->
        <form *ngIf="!isLoading" [formGroup]="addTicketForm">
            <div class="add-ticket-container">
                <div class="first-section">
                    <div class="row header-title-container">
                        <div class="header-title">
                            <span>{{'TICKETING_SYSTEM.FORM.TITLE' | translate}}</span>
                        </div>
                    </div>
                    <div class="section-inputs-container" [ngStyle]="{'margin-top': isShowCustomerFieldWhileAddingTicket ? '24px' : '59px'}">
                        <ng-container *ngIf="isShowCustomerFieldWhileAddingTicket">
                            <div class="add-new-customer-btn row d-flex justify-content-end" *ngIf="canAddUser">
                                <div class="add-customer d-flex" (click)="addCustomer()">
                                    <div class="label">
                                        <span>+ {{'TICKETING_SYSTEM.FORM.NEW_CUSTOMER' | translate}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="store-name-container">
                                <div class="store-name d-flex">
                                    <div class="store-name-title">
                                        <span>{{(companyId === 25 ? 'TICKETING_SYSTEM.TABLE.BRANCH_NAME' : 'TICKETING_SYSTEM.TABLE.CUSTOMER_NAME') | translate }}</span>
                                    </div>
                                    <div class="stores-drop-down flex-1">
                                        <p-autoComplete formControlName="storeName"
                                                        type="text"
                                                        [forceSelection]="true"
                                                        placeholder="{{ 'ADD_SHIPMENTS.STORE_NAME_PLACEHOLDER' | translate}}"
                                                        [suggestions]="storesList"
                                                        (completeMethod)="getStores($event)"
                                                        (onSelect)="chooseStore($event)"
                                                        (focusout)="checkAcValidity('storeName')"
                                                        styleClass="souq-selection"
                                                        #StoreName
                                        >
                                            <ng-template let-user pTemplate="item">
                                                <div class="store-item">
                                                    <div class="text-ellipsis">{{user.fullName}}{{user.businessName ? ' - ' + user.businessName : ''}}</div>
                                                    <div class="phone-number">{{user.phone1}}</div>
                                                </div>
                                            </ng-template>
                                        </p-autoComplete>
                                    </div>
                                    <div class="close-edit-icons">
                                        <i class="fas fa-chevron-down arrow-icon" *ngIf="!editStore"
                                           (click)="editStoreName()"></i>
                                        <i class="fas fa-times close-refresh-soup" *ngIf="editStore"
                                           (click)="resetStore()"></i>
                                    </div>
                                </div>
                                <div *ngIf="inValidStoreInfo" class="error-message">
                                    <span>{{'TICKETING_SYSTEM.ERRORS.STORE_INPUT_ERROR' | translate}}</span>
                                </div>
                            </div>
                        </ng-container>

                        <div class="issue-title-input input-field-container">
                            <div class="label">
                                <span>{{'TICKETING_SYSTEM.TABLE.ISSUE_TITLE' | translate}}</span>
                            </div>
                            <div class="input">
                                <input formControlName="title" type="text" size="30"/>
                            </div>
                        </div>


                        <div class="ticket-priority-input input-field-container">
                            <div class="label">
                                <span>{{'TICKETING_SYSTEM.TABLE.PRIORITY' | translate}}</span>
                            </div>
                            <div class="input">
                                <p-dropdown
                                        formControlName="priority"
                                        styleClass="add-ticket-drop-down"
                                        [options]="ticketPrioritiesList"
                                        [style]="{'width':'100%'}"
                                        filter="true">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="store-name-container">
                            <div class="store-name d-flex">
                                <div class="store-name-title">
                                    <span>{{'TICKETING_SYSTEM.TABLE.CATEGORY' | translate}}</span>
                                </div>
                                <div class="stores-drop-down flex-1">
                                    <p-autoComplete formControlName="categoryName"
                                                    type="text"
                                                    [forceSelection]="true"
                                                    [suggestions]="categoriesList"
                                                    (completeMethod)="getCategories($event)"
                                                    (onSelect)="chooseCategory($event)"
                                                    (focusout)="checkAcValidity('categoryName')"
                                                    styleClass="souq-selection"
                                                    #categoryName
                                    >
                                        <ng-template let-category pTemplate="item">
                                            <div class="store-item">
                                                <div class="text-ellipsis">{{category.name}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div class="close-edit-icons">
                                    <i class="fas fa-chevron-down arrow-icon" *ngIf="!editCategory"
                                       (click)="editCategoryName()"></i>
                                    <i class="fas fa-times close-refresh-soup" *ngIf="editCategory"
                                       (click)="resetCategory()"></i>
                                </div>
                            </div>
                            <div *ngIf="invalidCategory" class="error-message">
                                <span>{{'TICKETING_SYSTEM.ERRORS.CATEGORY_INPUT_ERROR' | translate}}</span>
                            </div>
                        </div>

                        <div class="store-name-container">
                            <div class="store-name d-flex">
                                <div class="store-name-title">
                                    <span>{{'TICKETING_SYSTEM.FORM.ASSIGN_TO' | translate}}</span>
                                </div>
                                <div class="stores-drop-down flex-1">
                                    <p-autoComplete formControlName="user"
                                                    type="text"
                                                    [forceSelection]="true"
                                                    [suggestions]="usersList"
                                                    (completeMethod)="getUsers($event)"
                                                    (onSelect)="chooseUser($event)"
                                                    (focusout)="checkAcValidity('user')"
                                                    styleClass="souq-selection"
                                                    #user
                                    >
                                        <ng-template let-client pTemplate="item">
                                            <div class="store-item">
                                                <div class="text-ellipsis">{{client.name}}</div>
                                                <!--                                            <div class="phone-number">{{user.phone1}}</div>-->
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div class="close-edit-icons">
                                    <i class="fas fa-chevron-down arrow-icon" *ngIf="!editClient"
                                       (click)="editClientName()"></i>
                                    <i class="fas fa-times close-refresh-soup" *ngIf="editClient"
                                       (click)="resetClient()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="second-section">
                    <div class="section-inputs-container">
                        <div class="due-date input-container" [ngClass]="{'has-error': (addTicketForm.get('resolveDue').touched && addTicketForm.get('resolveDue').errors)}">
                            <div class="label">
                                <span>{{'TICKETING_SYSTEM.FORM.DUE_DATE' | translate}}</span>
                            </div>
                            <div class="input">
                                <p-calendar formControlName="resolveDue" styleClass="due-date-date-picker"
                                            icon="pi pi-calendar"
                                            [showIcon]="true"
                                            [minDate]="minDueDate"
                                            [showTime]="true"
                                            dateFormat="dd/mm/yy">
                                </p-calendar>
                            </div>
                        </div>
                        <div class="ticket-description input-container">
                            <div class="description-label"><span>{{'TICKETING_SYSTEM.FORM.DESCRIPTION' | translate}}</span></div>
                            <div class="description-input">
                                <textarea formControlName="description"
                                          class="ticket-description-textarea"
                                          [rows]="12"
                                          [cols]="30" pInputTextarea>
                                </textarea>
                            </div>
                            <div class="ticket-attachments cursor-pointer">
                               <div class="add-attachment-action flex-2d-center cursor-pointer" (click)="addAttachment($event, fileChooser)">
                                   <img src="../../../../assets/icons/ticketing-system/add-attachment.png"
                                        alt=""/>
                                   <input type="file" accept="image/x-png,image/gif,image/jpeg,image/x-icon"
                                          style="width: 0; height: 0; opacity: 0; position: absolute" #fileChooser
                                          (click)="$event.stopPropagation()"
                                          (change)="onFilesSelected($event)" multiple>
                               </div>
                               <div class="uploaded-attachments-container" *ngIf="ticketAttachmentsFiles.length">
                                   <div class="attachments-title d-flex">
                                       <div class="attachments-label">
                                           <span class="label">{{'GENERAL.ATTACHMENTS' | translate}}</span>
                                           <span class="attachment-count-badge">{{ticketAttachmentsFiles.length}}</span>
                                       </div>
                                       <div class="attachments-splitter-container flex-2d-center flex-1">
                                           <div class="attachments-splitter">
                                           </div>
                                       </div>
                                   </div>
                                   <div class="uploaded-attachments-list d-flex">
                                       <div class="uploaded-attachment d-flex cursor-pointer" *ngFor="let attachment of ticketAttachmentsFiles">
                                           <div class="attachment-type-and-size d-flex">
                                               <div class="attachment-type">
                                                   <span>{{attachment.name}}</span>
                                               </div>
                                               <div class="attachment-size">
                                                   <span>({{attachment.size}} KB)</span>
                                               </div>
                                           </div>
                                           <div class="remove-attachment" (click)="removeAttachment(attachment)">
                                               <img src="../../assets/icons/ticketing-system/ticket-details/x-icon.png"
                                                    alt=""/>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                            </div>
                        </div>

<!--                        <div class="ticket-tags">-->
<!--                            <div class="section-field-input-label">-->
<!--                                <span>{{'TICKETING_SYSTEM.FORM.TAGS' | translate}}</span>-->
<!--                            </div>-->
<!--                            <div class="tickets-tags-list">-->
<!--                                <p-autoComplete formControlName="storeName"-->
<!--                                                type="text"-->
<!--                                                [suggestions]="storesList"-->
<!--                                                [multiple]="true"-->
<!--                                                (completeMethod)="getStores($event)"-->
<!--                                                (onSelect)="chooseStore($event)"-->
<!--                                                styleClass="tickets-tags-selection"-->
<!--                                                #StoreName-->
<!--                                >-->
<!--                                    <ng-template let-user pTemplate="item">-->
<!--                                        <div class="store-item">-->
<!--                                            <div class="store-name text-ellipsis">{{user.fullName}}{{user.businessName ? ' - ' + user.businessName : ''}}</div>-->
<!--                                            <div class="phone-number">{{user.phone1}}</div>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                </p-autoComplete>-->
<!--                            </div>-->
<!--                        </div>-->

                    </div>
                    <div class="ticket-actions-container">
                        <div class="ticket-actions row d-flex justify-content-between">
                            <div class="add-more-btn d-flex">
                                <div class="add-more-label">
                                    <span>{{'TICKETING_SYSTEM.FORM.ADD_MORE' | translate}}</span>
                                </div>
                                <div class="add-more-switch">
                                    <p-inputSwitch formControlName="continueAdding" (onChange)="onToggleContinueAdding($event)"></p-inputSwitch>
                                </div>
                            </div>
                            <div [ngClass]="{'disabled-btn disabled': disableSubmitAction()}"
                                 class="submit-action flex-1" (click)="ticketRequest()">
                                <span>{{'TICKETING_SYSTEM.FORM.ADD' | translate}}</span>
                            </div>
                            <div *ngIf="isSubmittingTicket || uploadingAttachments" class="submit-loader flex-2d-center cursor-pointer">
                                <i class="fas fa-spinner fa-pulse"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </p-sidebar>

</div>
