import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drivers-earnings',
  templateUrl: './drivers-earnings.component.html',
  styleUrls: ['./drivers-earnings.component.scss']
})
export class DriversEarningsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
