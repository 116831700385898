<div class="close-icon" (click)="cancel()"><img src="../../../../assets/icons/close-icon.svg" alt=""/></div>
<div class="custom-modal-header">
    <h4>{{ ('ROUTE_OPTIMIZATION.DRIVER_ROUTE' | translate) }}</h4>
    <div>{{ 'ROUTE_OPTIMIZATION.SHOW_ROUTE' | translate }}</div>
    <div class="filter-options" [formGroup]="filterForm">
        <p-toggleButton formControlName="isShowDeliver"
                        offLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.DELIVER' | translate }}"
                        onLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.DELIVER' | translate }}">
        </p-toggleButton>
        <p-toggleButton formControlName="isShowPickup"
                        offLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.PICKUP' | translate }}"
                        onLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.PICKUP' | translate }}">

        </p-toggleButton>
    </div>

</div>

<div class="modal-body" [ngClass]="{'page-ltr': currentLang !== 'ar'}">
    <div class="map-container">
        <div class="map-container">
            <div id="submitting-container" *ngIf="isLoading">
                <p-progressSpinner></p-progressSpinner>
            </div>

            <span *ngIf="!routePolyline">{{ 'ROUTE_OPTIMIZATION.ALERTS.CHOOSE_STARTING_POINT' | translate }}</span>

            <div id="map"></div>
        </div>
        <div class="mockup-container"></div>
    </div>
</div>
