import {Component, OnInit, ViewChild} from '@angular/core';
import {FADE_ANIMATION} from '../../shared/animations/fade-animation';
import {UsersService} from '../services/users.service';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {UserModel} from '../../shared/models/user.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {NewUserComponent} from '../create-new-user/new-user.component';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {TranslateService} from '@ngx-translate/core';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {USERS_CONSTANTS} from '../services/constants';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ResetPasswordComponent} from '../../shared/components/reset-password/reset-password.component';
import {DownloadExcelComponent} from '../../shipment/download-excel/download-excel.component';
import {ImageSliderComponent} from '../../shared/components/image-slider/image-slider.component';
import * as _ from 'lodash';
import {UtilitiesService} from '../../shared/services/utilities.service';
import {ShipmentsService} from '../../shipment/services/shipments.service';
import {DriverCheckInListComponent} from '../driver-check-in-list/driver-check-in-list.component';
import {StorageService} from '../../shared/services/storage/storage.service';
import {UserStatus} from '../../shared/enums/UserStatus';
import {ImportCustomersFromExcelComponent} from './import-customers-from-excel/import-customers-from-excel.component';
import {ImportCustomersFromExcelService} from '../services/import-customers-from-excel.service';
import {CreateNewUserCustom1Component} from '../create-new-user-custom1/create-new-user-custom1.component';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {Subject} from 'rxjs';
import {GenericDialogComponent} from '../../shared/components/generic-dialog/generic-dialog.component';
import {HubsService} from '../../shared/services/hubs.service';

@Component({
    selector: 'app-users-management-component',
    templateUrl: 'users-management.component.html',
    styleUrls: ['users-management.component.scss'],
    animations: [FADE_ANIMATION]
})
export class UsersManagementComponent implements OnInit {
    @ViewChild('rowActionsPopOver', {static: false}) rowActionsPopOver: NgbPopover;
    @ViewChild('importExportPanelOptions', {static: false}) importExportPanelOptions;
    @ViewChild('selectHubs') selectHubs;
    public users: UserModel[] = [];
    public currentPage = 1;
    public totalRecords = 0;
    public newRequestsTotalNo = 0;
    public searchContent = '';
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;
    public apiPath = '';

    public permissions = '';

    public isLoading;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    // public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public selectedLanguage = '';
    public selectedTab = '';
    public selectedUserRole;
    public userRolesOptions = [];
    public viewMode = 'CARDS_MODE';
    public pageSize = this.viewMode === 'TABLE_MODE' ? 14 : 16;
    public fetchedData: UserModel[];
    public triggeredUser: UserModel;
    public isShowNewRequests = false;
    public isShowSubCustomerOnly = false;
    public isShowUserDetails = false;
    public isDistributor = false;
    public deleteLoader = false;
    public downloadExcelLoader = false;
    public debounceUpdateCost;
    public downloadTemplateLoader = false;
    public uploadExcelLoader = false;
    public userType: string;

    public isSearchContent = false;

    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    isLoadingReport = false;
    isLoadingExcelReport = false;
    isAdmin = false;
    private roleParam: string;
    showDriverTab = false;
    isCustomerCare = false;
    isHubCustomerCare = false;
    isAuthShowUsers = false;
    tabRoleParam: string;
    hubFilter;

    userRole;
    companyId;
    companies = Companies;

    public hubs = [];
    public selectedHub;
    private hubId: any;
    private multiHubUserDialog;
    constructor(
        private usersService: UsersService,
        private confirmService: ConfirmationService,
        private authenticationService: AuthenticationService,
        private messageService: MessageService,
        private modalService: NgbModal,
        public translateService: TranslateService,
        private userService: UserService,
        private storageService: StorageService,
        private roleService: RolesService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        private utilitiesService: UtilitiesService,
        private shipmentsService: ShipmentsService,
        private importCustomersFromExcelService: ImportCustomersFromExcelService,
        private customizationCompanyService: CustomizationCompanyService,
        private hubsService: HubsService,
    ) {
        this.isCustomerCare = ['CUSTOMER_CARE'].indexOf(this.userService.userInfo.role) > -1;
        this.isHubCustomerCare = ['HUB_CUSTOMER_CARE'].indexOf(this.userService.userInfo.role) > -1;
        this.showDriverTab = this.isCustomerCare;
    }

    ngOnInit() {
        this.fetchUserInfo();
        this.companyId = this.userService.userInfo.companyId;
    }

    public setUserType() {
        this.userType = this.apiPath === 'getCustomers' ? 'CUSTOMER' : 'USER';
    }

    public showUserDetails(user) {
        this.triggeredUser = user;
        this.isShowUserDetails = true;
    }

    public onHideUserDetails(event) {
        this.isShowUserDetails = event;
    }

    public onSetShowMode(viewMode) {
        this.viewMode = viewMode;
        this.pageSize = viewMode === 'TABLE_MODE' ? 14 : 16;
        this.users = [];
        this.currentPage = 1;
        this.fetchUsers(true);
    }

    public toggleShowNewRequests(isChecked) {
        this.isShowNewRequests = isChecked;
    }

    public toggleShowHasSubCustomersOnly(isChecked) {
        this.isShowSubCustomerOnly = isChecked;
        this.fetchUsers(true);
    }

    public getAddress(address) {
        return address.village + ' - ' + address.city + ' - ' + address.region;
    }

    public getUsersPath() {
        const path = this.route.routeConfig.path;
        if (path === 'users' && !this.isAuthShowUsers) {
            this.apiPath = 'getCustomers';
            this.onChangeTab('customers');
            return;
        }
        switch (path) {
            case 'users':
            case 'drivers':
                this.apiPath = 'getUsers';
                if (path === 'drivers') {
                    this.roleParam = 'DRIVER';
                    this.tabRoleParam = 'DRIVER';
                }
                break;
            case 'archived-users':
            case 'archived-drivers':
                this.apiPath = 'getArchivedUsers';
                if (path === 'archived-drivers') {
                    this.roleParam = 'DRIVER';
                    this.tabRoleParam = 'DRIVER';
                }
                break;
            case 'customers':
                this.apiPath = 'getCustomers';
                break;
            case 'archived':
                this.apiPath = 'getArchivedCustomers';
                break;
        }
    }

    private fetchUserRolesOptions() {
        const userRoles = this.userService.getRules();
        this.translateService.get(userRoles).subscribe(
            (rolesTranslation) => {
                // tslint:disable-next-line:forin
                for (const roleKey in rolesTranslation) {
                    this.userRolesOptions.push(
                        {
                            label: this.transformBasedOnCurrency(rolesTranslation[roleKey]),
                            value: roleKey
                        }
                    );
                }
            }
        );
    }
    public transformBasedOnCurrency(result: string) {
        if (this.userService.getCurrency() === 'SAR') {
            result = result.replace('زبون', 'عميل');
            result = result.replace('زبائن', 'عملاء');
            result = result.replace('المشتغل المرخص', 'السجل التجاري');
        }
        return result;
    }

    private fetchUserInfo() {
        this.userService.getUserInfo().then(
            (user: UserModel) => {
                this.initPermissions();
                this.isAuthShowUsers = this.permissions.includes('SHOW_USERS');
                this.selectedLanguage = this.translateService.currentLang;
                this.isDistributor = user.isDistributor;
                this.getUsersPath();
                this.selectedTab = this.route.routeConfig.path;
                this.fetchUsers(true);
                this.fetchUserRolesOptions();
                this.setUserType();
                this.isLoading = true;
                this.isAdmin = ['SUPER_ADMIN', 'SUPER_ADMIN_AS_ADMIN', 'ADMIN'].indexOf(this.userService.userInfo.role) > -1;
            }
        );
    }

    public initPermissions() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.userRole = role;
        this.permissions = this.roleService.getUserPermissions('MANAGE_USERS', role);
    }

    private createURLQuery() {
        let query = `?pageSize=${this.pageSize}&page=` + (this.currentPage);
        if (this.apiPath === 'getArchivedUsers' || this.apiPath === 'getArchivedCustomers') {
            query += '&is-deleted=' + true;
        }
        if (this.apiPath === 'getCustomers') {
            query += '&status=' + UserStatus.VERIFIED;
        }
        if (this.searchContent !== '') {
            query += '&search=' + this.searchContent;
        }
        if (this.tabRoleParam) {
            query += '&userRole=' + this.tabRoleParam;
        } else {
            if (this.isCustomerCare && this.roleParam) {
                query += '&userRole=' + this.roleParam;
            } else if (this.selectedUserRole) {
                query += '&userRole=' + this.selectedUserRole;
            }
        }
        if (this.hubFilter) {
            query += '&hubIds=' + this.hubFilter;
        }

        if (this.isShowSubCustomerOnly) {
            query += '&hasSubCustomers=' + this.isShowSubCustomerOnly;
        }

        query += '&driverType=TYPICAL';
        return query;
    }

    public onRemoveUser($event: { user: UserModel, complete?: any }) {
        this.removeUser($event.user, $event.complete);
    }

    public onManageUser($event: { user: UserModel, complete?: any }) {
        this.manageUser($event.user, $event.complete);
    }
    public onEditHubs($event: { user: UserModel, complete?: any }) {
        this.editHubs($event.user, $event.complete);
    }

    public onRetrieveUser($event: { user: UserModel, complete: any }) {
        this.retrieveCustomer($event.user, $event.complete, this.isCustomerOrArchived());
    }

    public onVerifyUser($event: { user: UserModel, complete: any }) {
        this.verifyCustomer($event.user, $event.complete);
    }

    public onResetPassword($event: { user: UserModel, complete?: any }) {
        this.resetPassword($event.user, $event.complete);
    }

    public resetPassword(user: UserModel, complete?: any) {
        this.closeRowActionsPopOver();
        const modal = this.modalService.open(
            ResetPasswordComponent, <any>{backdrop: 'static', windowClass: 'reset-password-modal', size: 'sm'});
        modal.componentInstance.imageUrl = user.imageUrl;
        modal.componentInstance.userName = user.firstName + '' + user.middleName ? user.middleName : '' + '' + user.lastName;
        if (this.apiPath === 'getUsers') {
            modal.componentInstance.apiURl = USERS_CONSTANTS.CHANGE_USER_PASSWORD.replace('{userId}', user.id + '');
        } else {
            modal.componentInstance.apiURl = USERS_CONSTANTS.CHANGE_CUSTOMER_PASSWORD.replace('{customerId}', user.id + '');
        }
    }

    public removeUser(user: UserModel, complete?: any) {
        let message = '';
        let yes = '';
        let no = '';
        // this.deleteLoader = true;
        user.isDeleting = true;
        if (!user.role) {
            user.role = 'CUSTOMER';
        }
        this.translateService.get(
            ['ALERTS.DELETE_USER', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.DELETE_USER'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmService.confirm({
            message: message,
            accept: () => {
                this.usersService.remove(user).subscribe(
                    (response: any) => {
                        this.reset();
                        this.fetchUsers(true);
                        user.isDeleting = false;
                        this.onHideUserDetails(false);
                        this.translateService.get('ALERTS.USER_DELETED_SUCCESSFULLY').subscribe(
                            (translatedMessage) => {
                                this.messageService.add({severity: 'success', detail: translatedMessage});
                            }
                        );

                    }, (error) => {
                        user.isDeleting = false;
                        this.onHideUserDetails(false);
                        const errorMessage = error.error ? error.error.error : 'Something went wrong';
                        console.error(error);
                        if (complete) {
                            complete();
                        }
                    }, () => {
                        if (complete) {
                            complete();
                        }
                    }
                );
            },
            reject: () => {
                user.isDeleting = false;
                complete();
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }
    public removeUserForever(user: UserModel, complete?: any) {
        let message = '';
        let yes = '';
        let no = '';
        // this.deleteLoader = true;
        user.isDeleting = true;
        if (!user.role) {
            user.role = 'CUSTOMER';
        }
        this.translateService.get(
            ['ALERTS.DELETE_USER_FOREVER', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.DELETE_USER_FOREVER'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmService.confirm({
            message: message,
            accept: () => {
                this.usersService.removeForever(user).subscribe(
                    (response: any) => {
                        this.reset();
                        this.fetchUsers(true);
                        user.isDeleting = false;
                        this.onHideUserDetails(false);
                        this.translateService.get('ALERTS.USER_DELETED_SUCCESSFULLY').subscribe(
                            (translatedMessage) => {
                                this.messageService.add({severity: 'success', detail: translatedMessage});
                            }
                        );

                    }, (error) => {
                        user.isDeleting = false;
                        this.onHideUserDetails(false);
                        const errorMessage = error.error ? error.error.error : 'Something went wrong';
                        console.error(error);
                        if (complete) {
                            complete();
                        }
                    }, () => {
                        if (complete) {
                            complete();
                        }
                    }
                );
            },
            reject: () => {
                user.isDeleting = false;
                complete();
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }

    public retrieveCustomer(user: UserModel, complete?: any, isCustomer?: boolean) {
        let successMessage = '';
        let message = '';
        let yes = '';
        let no = '';
        if (!user.role && isCustomer) {
            user.role = 'CUSTOMER';
        }
        const isDriver = user.role === 'DRIVER';
        this.translateService.get(
            [isCustomer ? 'ALERTS.RETRIEVE_CUSTOMER' : isDriver ? 'ALERTS.RETRIEVE_DRIVER' : 'ALERTS.RETRIEVE_USER',
                isCustomer ? 'ALERTS.CUSTOMER_RETRIEVED_SUCCESSFULLY' : isDriver ? 'ALERTS.DRIVER_RETRIEVED_SUCCESSFULLY' : 'ALERTS.USER_RETRIEVED_SUCCESSFULLY',
                'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    successMessage = isCustomer ? data['ALERTS.CUSTOMER_RETRIEVED_SUCCESSFULLY'] : data[isDriver ? 'ALERTS.DRIVER_RETRIEVED_SUCCESSFULLY' : 'ALERTS.USER_RETRIEVED_SUCCESSFULLY'];
                    message = isCustomer ? data['ALERTS.RETRIEVE_CUSTOMER'] : data[isDriver ? 'ALERTS.RETRIEVE_DRIVER' : 'ALERTS.RETRIEVE_USER'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmService.confirm({
            message: message,
            accept: () => {
                this.usersService.retrieve(user, isCustomer).subscribe(
                    (response: any) => {
                        this.reset();
                        this.fetchUsers(true);
                        this.messageService.add({severity: 'success', detail: successMessage});
                    }, (error) => {
                        const errorMessage = error.error ? error.error.error : 'Something went wrong';
                        console.error(error);
                        if (complete) {
                            complete();
                        }
                    }, () => {
                        if (complete) {
                            complete();
                        }
                    }
                );
            },
            reject: () => {
                complete();
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }

    public verifyCustomer(user: UserModel, complete?: any) {
        let message = '';
        let success = '';
        let yes = '';
        let no = '';
        if (!user.role) {
            user.role = 'CUSTOMER';
        }
        this.translateService.get(
            ['ALERTS.VERIFY_CUSTOMER', 'ALERTS.USER_VERIFIED_SUCCESSFULLY', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.VERIFY_CUSTOMER'];
                    success = data ['ALERTS.USER_VERIFIED_SUCCESSFULLY'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmService.confirm({
            message: message,
            accept: () => {
                this.usersService.verifyCustomer(user).subscribe(
                    (response: any) => {
                        this.reset();
                        this.fetchUsers(true);
                        this.messageService.add({severity: 'success', detail: success});
                    }, (error) => {
                        const errorMessage = error.error ? error.error.error : 'Something went wrong';
                        console.error(error);
                        if (complete) {
                            complete();
                        }
                    }, () => {
                        if (complete) {
                            complete();
                        }
                    }
                );
            },
            reject: () => {
                complete();
            },
            acceptLabel: yes,
            rejectLabel: no
        });

    }

    public manageUser(user: UserModel, complete?: any) {
        this.closeRowActionsPopOver();
        if (!user.role) {
            user.role = 'CUSTOMER';
        }
        const type = (this.apiPath === 'getUsers') ? 'user' : 'customer';
        let modal;
        if (this.customizationCompanyService.checkCompanies(this.companies.JETT) && type === 'customer') {
            modal = this.modalService.open(CreateNewUserCustom1Component, <any>{
                backdrop: 'static',
                windowClass: 'create-new-user',
                size: 'md'
            });
        } else {
            modal = this.modalService.open(
                NewUserComponent,
                <any>{
                    backdrop: 'static',
                    windowClass: 'create-new-user',
                    size: 'md'
                }
            );
        }
        modal.componentInstance.user = user;
        modal.componentInstance.type = type;
        const editedFields = this.roleService.getEditedFields(this.permissions, 'EDIT_CUSTOMER');

        // send the allowed to edit fields.
        if (editedFields) {
            modal.componentInstance.editedFields = editedFields;
        }
        let success = '';
        this.translateService.get(
            ['ALERTS.USER_UPDATED_SUCCESSFULLY']
        )
            .subscribe(
                (data) => {
                    success = data ['ALERTS.USER_UPDATED_SUCCESSFULLY'];
                }
            );
        modal.result.then(
            (status) => {
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: success});
                    this.reset();
                    this.fetchUsers(true);
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public editHubs(user: UserModel, complete?: any) {
        this.closeRowActionsPopOver();
        this.selectedHub = [];
        this.getHubs();
        this.usersService.getUserHubs(user.id).subscribe((res: any) => {
            console.log(res);
            this.hubs = (res || []).map(user => {
                return {
                    label: user.name,
                    value: user.id
                };
            });
            this.selectedHub = (res || []).map(user => user.id);
            const modal = this.modalService.open(GenericDialogComponent, <any>{size: 'md'});
            this.multiHubUserDialog = modal;
            modal.componentInstance.body = this.selectHubs;
            modal.componentInstance.topLabel = 'USERS_MANAGEMENT.ACTIONS.EDIT_HUBS';
            modal.componentInstance.onDone = () => {
                const ob = new Subject();
                this.usersService.updateUserHubs(user.id, {ids: this.selectedHub}).subscribe(res => {
                    this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    ob.next(true);
                });
                return ob;
            };
            modal.result.then(
                (res: { isSuccess, res? }) => {
                }
            ).catch(
                (error) => {
                }
            );
        });
    }

    public uploadUserImage(user: UserModel, imageFormData) {
        this.usersService.addUserImage(user.id, this.userType, imageFormData).subscribe(
            (imageResponse) => {
                user.imageUrl = imageResponse['logo_path'] + this.utilitiesService.getImageRandomReload();
            }, (error) => {
                console.error(error);
            }
        );
    }

    canAddDriver(): boolean {
        return this.selectedTab === 'drivers' && this.permissions.includes('ADD_DRIVER');
    }

    public canAddUser(): boolean {
        if (this.selectedTab === 'drivers' && this.tabRoleParam === 'DRIVER') {
            return  this.permissions.includes('{CONFIRM_DRIVERS');
        }
        if (this.userService.userInfo.role === 'OPERATION_MANAGER') {
            return (this.selectedTab === 'customers' || this.selectedTab === 'users')
                && this.checkIsAllowOperationManagerAddCustomer()
                && this.permissions.includes('{ADD_CUSTOMER')
                && this.permissions.includes('{ADD_USER');
        } else if ((this.userService.userInfo.role === 'HUB_MANAGER'||this.userService.userInfo.role === 'MULTIPLE_HUBS_MANAGER') && this.selectedTab === 'customers') {
            return this.userService.userInfo.isShowCustomersToHubManager;
        } else {
            if (this.apiPath === 'getUsers') {
                return this.permissions.includes('{ADD_USER') && this.selectedTab === 'users';
            } else {
                return this.permissions.includes('{ADD_CUSTOMER') && this.selectedTab === 'customers';
            }
        }
    }

    public openImportDialog() {
        const modal = this.modalService.open(DownloadExcelComponent, <any>{size: 'lg', windowClass: 'bulk-update-popover'});
        modal.componentInstance.fromPage = 'manage-users';
        modal.result.then(
            () => {
                this.onSearch();
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public downloadExcelTemplate() {
        this.downloadTemplateLoader = true;
        this.shipmentsService.downloadExcelTemplate('manage-users').subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.downloadTemplateLoader = false;
        }, error => {
            console.error(error);
            this.downloadTemplateLoader = false;
        });
    }

    public triggerImportExportMenu($event, dropdown) {
        dropdown.toggle($event);
    }

    public uploadFile(fileChooser) {
        fileChooser.click();
    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    public uploadExcel($event: any, invoiceSupplier = false, download = false, openDialog = false) {
        this.uploadExcelLoader = true;
        let successMessage = '';
        let errorMessage = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.FILE_UPLOADED_SUCCESSFULLY', 'ALERTS.FAILED_TO_UPLOAD_FILE', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    successMessage = data['ALERTS.FILE_UPLOADED_SUCCESSFULLY'];
                    errorMessage = data['ALERTS.FAILED_TO_UPLOAD_FILE'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        formData.append('file', selectedFile);
        $event.target.value = '';
        if (!selectedFile) {
            this.uploadExcelLoader = false;
            return;
        }
        if (openDialog) {
            this.openImportCustomersDialog(selectedFile);
            return;
        }
        if (selectedFile.name.toLowerCase().match(/.(xlsx)$/i)) {
            this.shipmentsService.uploadExcel(formData, invoiceSupplier, 'manage-users').subscribe((data: any) => {
                    if (!data || !data.error) {
                        this.messageService.add({severity: 'success', detail: successMessage});
                        this.uploadExcelLoader = false;
                    } else {
                        if (download) {
                            this.messageService.add({severity: 'error', detail: errorMessage});
                            this.download('errors.txt', data.error);
                            this.uploadExcelLoader = false;
                        } else {
                            this.confirmService.confirm({
                                message: (data.error),
                                rejectVisible: false,
                                accept: () => {
                                },
                                acceptLabel: 'ok'
                            });
                            this.uploadExcelLoader = false;
                        }
                    }
                }, error => {
                    this.uploadExcelLoader = false;
                }
            );
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.uploadExcelLoader = false;
        }

    }

    openImportCustomersDialog(selectedFile: any) {

        this.importExportPanelOptions.hide();
        this.uploadExcelLoader = false;
        const modalRef = this.modalService.open(ImportCustomersFromExcelComponent, {
            size: 'xl',
            backdrop: 'static'
        });

        modalRef.componentInstance.file = selectedFile;
        modalRef.result.then(res => {
            if (res && res.addedCustomers > 0) {
                this.fetchUsers(true);
            }
        });
    }

    public createUser() {
        const type = (this.apiPath === 'getUsers') ? 'user' : 'customer';
        let modal;
        if (this.customizationCompanyService.checkCompanies(this.companies.JETT) && type === 'customer') {
            modal = this.modalService.open(CreateNewUserCustom1Component, <any>{
                backdrop: 'static',
                windowClass: 'create-new-user',
                size: 'md'
            });
        } else {
            modal = this.modalService.open(
                NewUserComponent,
                <any>{
                    backdrop: 'static',
                    windowClass: 'create-new-user',
                    size: 'md'
                }
            );
        }
        modal.componentInstance.type = type;
        modal.componentInstance.userRole = (type === 'user' && (this.userRole === 'HUB_MANAGER' || this.userRole === 'MULTIPLE_HUBS_MANAGER')) ? 'DRIVER' : '';

        modal.result.then(
            (status) => {
                if (status.isSuccess) {
                    this.translateService.get(
                        'ALERTS.USER_ADDED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                                this.reset();
                                this.fetchUsers(true);
                            }
                        );
                }
            }
        ).catch((error) => {
        });
    }

    public onChangeTab(path) {
        this.router.navigate([this.authService.companyName + 'home/manage-users/' + path]);
    }

    public onPaginate(page) {
        this.currentPage = page;
        this.fetchUsers();
    }

    public onSearch() {
        if (!this.debounceUpdateCost) {
            this.debounceUpdateCost = _.debounce(() => {
                this.currentPage = 0;
                this.users = [];
                this.loadLazyUsers();
            }, 1000);
        }
        this.debounceUpdateCost();
    }

    public onChangeUserRolesFilter(event) {
        this.fetchUsers(true);
    }

    public autoApproveUser(autoApprove, user) {
        this.isLoading = true;
        this.usersService.autoApproveCustomer(autoApprove, user.id).subscribe(() => {
            user.isAutoApprovePackages = autoApprove;
            this.isLoading = false;
            this.translateService.get(['ALERTS.PACKAGES_APPROVED_SUCCESSFULLY', 'ALERTS.PACKAGES_DECLINED_SUCCESSFULLY']).subscribe(
                (messages) => {
                    const message = user.isAutoApprovePackages ? messages['ALERTS.PACKAGES_APPROVED_SUCCESSFULLY'] : messages['ALERTS.PACKAGES_DECLINED_SUCCESSFULLY'];
                    this.messageService.add({severity: 'success', detail: message});
                }
            );
        }, (error) => {
            console.error(error);
            this.isLoading = false;
            user.isAutoApprovePackages = !user.isAutoApprovePackages;
        });
    }

    public checkIsAllowOperationManagerAddCustomer() {
        return this.userService.userInfo.isAllowOperationManagerAddCustomer;
    }

    public canEdit() {
        if (this.userService.userInfo.role === 'CUSTOMER_CARE' && this.triggeredUser && this.triggeredUser.role === 'DRIVER') {
            return false;
        }
        if (this.userService.userInfo.role === 'OPERATION_MANAGER') {
            return this.permissions.includes('{EDIT_CUSTOMER') && this.selectedTab === 'customers';
        } else {
            return (this.permissions.includes('{EDIT_CUSTOMER')
                || (this.permissions.includes('{EDIT_USER')))
                && this.selectedTab !== 'archived' && this.selectedTab !== 'archived-users';
        }
    }

    public canResetPassword() {
        const resetPasswordPermission = this.selectedTab === 'customers' ? 'RESET_CUSTOMER_PASSWORD' : 'RESET_USER_PASSWORD';
        return (this.userService.userInfo.role === 'OPERATION_MANAGER' && this.userService.userInfo.isAllowOperationManagerAddCustomer)
            ||  this.permissions.includes(resetPasswordPermission) && this.apiPath !== 'getArchivedCustomers' && this.apiPath !== 'getArchivedUsers'
            || this.userService.userInfo.role === 'HUB_OPERATION_MANAGER' ;
    }

    public canTrackingPassword() {
        const trackingPasswordPermission = this.selectedTab === 'customers' ? 'TRACKING_CUSTOMER_PASSWORD' : 'TRACKING_USER_PASSWORD';
        return this.permissions.includes(trackingPasswordPermission);
    }

    public canRemoveUser() {
        const removeUserPermission = this.selectedTab === 'customers' ? 'REMOVE_CUSTOMER' : 'REMOVE_USER';
        return this.permissions.includes(removeUserPermission);
    }

    public canRetrieveCustomer() {
        return (this.permissions.includes('{REMOVE_CUSTOMER'))
            && (this.apiPath === 'getArchivedCustomers' || this.apiPath === 'getArchivedUsers');
    }

    canAddAttachments(user) {
        return (this.permissions.includes('{EDIT_CUSTOMER') && this.selectedTab === 'customers' &&
            !user.isAllowNoAttachment && this.userService.userInfo.isAllowAddingAttachment);
    }

    public canShow(entity) {
        return this.permissions.includes(`{SHOW_${entity}`);
    }

    public hubManagerConfig() {
        if (this.userService.userInfo.role === 'HUB_MANAGER' || this.userService.userInfo.role === 'MULTIPLE_HUBS_MANAGER') {
            return this.userService.userInfo.isShowCustomersToHubManager;
        } else {
            return true;
        }
    }
    public showAttachment(user, complete?: any) {
        const modal = this.modalService.open(ImageSliderComponent,
            <any>{backdrop: 'static', windowClass: 'attachment-container', size: 'md'});
        modal.componentInstance.url = user.attachment;
        modal.componentInstance.customerId = user.id;
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { success: boolean, url: string }) => {
                if (data.success) {
                    user['attachment'] = data.url;
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public reset() {
        this.users = [];
        this.currentPage = 0;
    }

    public fetchUsers(forceFetch = false) {
        if (forceFetch) {
            this.currentPage = 1;
        }
        const query = this.createURLQuery();
        this.fetchedData = [];
        if (this.users.length === this.totalRecords && this.currentPage > 1 && !forceFetch || this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.spinnerState = SpinnerState.LOADING;
        const method = this.apiPath;
        this.usersService[method](query).subscribe(
            (response: { customers?: any[], users?: any[], totalRecordsNo: number, notVerifiedCount: number }) => {
                // this.users = this.apiPath === 'getUsers' ? response.users : response.customers;
                this.fetchedData = this.isCustomerOrArchived() ? response.customers : response.users;
                this.fetchedData.forEach(user => {
                    if (user.imageUrl) {
                        user.imageUrl += this.utilitiesService.getImageRandomReload();
                    }
                });
                if (forceFetch) {
                    this.users = this.fetchedData;
                } else {
                    this.users = [...this.users, ...this.fetchedData];
                }
                // this.containers = response.vehicles;
                this.totalRecords = response.totalRecordsNo;
                if (this.apiPath === 'getCustomers' || this.tabRoleParam === 'DRIVER') {
                    this.newRequestsTotalNo = response.notVerifiedCount;
                }
                this.spinnerState = SpinnerState.LOADED;
                if (!(this.users && this.users.length > 0)) {
                    this.spinnerState = SpinnerState.EMPTY;
                }
                this.isLoading = false;
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
                this.isLoading = false;
            }
        );
    }

    public loadLazyUsers() {
        if (!this.isLoading) {
            this.currentPage++;
            this.fetchUsers();
        }
    }

    public onScrollTable(event) {
        // if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        if (this.totalRecords === this.users.length && this.currentPage > 1) {
            return;
        }
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
            this.loadLazyUsers();
        }
    }

    public setTriggeredUser(user) {
        this.triggeredUser = user;
    }

    public closeRowActionsPopOver() {
        if (this.rowActionsPopOver && this.rowActionsPopOver.isOpen()) {
            this.rowActionsPopOver.close();
        }
    }

    public isCustomerOrArchived() {
        return this.apiPath === 'getCustomers' || this.apiPath === 'getArchivedCustomers';
    }

    public isArchived() {
        return this.apiPath === 'getArchivedUsers' || this.apiPath === 'getArchivedCustomers';
    }

    public getTabName() {
        const path = this.route.routeConfig.path;
        switch (this.apiPath) {
            case 'getCustomers':
                return 'USERS_MANAGEMENT.CUSTOMERS_TAB';
            case 'getArchivedCustomers':
                return 'USERS_MANAGEMENT.ARCHIVED_CUSTOMERS_TAB';
            case 'getUsers':
                if (path === 'drivers') {
                    return 'USERS_MANAGEMENT.DRIVERS';
                } else {
                    return 'USERS_MANAGEMENT.USERS_TAB';
                }
            case 'getArchivedUsers':
                if ( path === 'archived-drivers') {
                    return 'USERS_MANAGEMENT.ARCHIVED_DRIVERS_TAB';
                } else {
                    return 'USERS_MANAGEMENT.ARCHIVED_USERS_TAB';
                }
        }
    }

    public showMoreActions() {
        return (this.canEdit()
            || this.canResetPassword()
            || this.canRemoveUser() ||
            this.canRemoveUser())
            && (this.apiPath === 'getCustomers' || this.apiPath === 'getUsers');
    }

    public showExcel() {
        return this.permissions.includes('{EDIT_CUSTOMER}') && this.selectedTab === 'customers';
    }

    public downloadExcel(user, complete?: any) {
        // const oldUser = this.users;
        // this.users = [];
        this.downloadExcelLoader = true;
        this.usersService.getExcelTemplateForCustomer(user.id).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.downloadExcelLoader = false;
            // this.users = oldUser;
        }, error => {
            console.error(error);
        });
    }

    public stopProp(event) {
        event.stopPropagation();
    }

    public toggleIsSearchContent(value) {
        this.isSearchContent = value;
    }

    showDriverCheckIns(driver?: UserModel) {
        const modal = this.modalService.open(DriverCheckInListComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'driver-check-in-list'
        });

        if (driver) {
            modal.componentInstance.selectedDriver = driver;
        }
    }
    public isCustomerAccountManager() {
        return this.userService.userInfo.role === 'CUSTOMER_ACCOUNT_MANAGER';
    }

    exportToPdf($event: MouseEvent) {
        if (this.isLoadingReport) {
            return;
        }
        this.isLoadingReport = true;
        const query = this.createURLQuery();
        const type = (this.apiPath === 'getUsers' || this.apiPath === 'getArchivedUsers') ? 'user' : 'customer';
        (type === 'user' ?
            this.usersService.exportUsersPdf(query) :
            this.usersService.exportCustomersPdf(query)
        ).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoadingReport = false;
        }, () => {
            this.isLoadingReport = false;
        });
    }

    exportToExcel($event: MouseEvent) {
        if (this.isLoadingExcelReport) {
            return;
        }
        this.isLoadingExcelReport = true;
        const query = this.createURLQuery();
        const type = (this.apiPath === 'getUsers' || this.apiPath === 'getArchivedUsers') ? 'user' : 'customer';
        (type === 'user' ?
            this.usersService.exportUsersExcel(query) :
            this.usersService.exportCustomersExcel(query)
        ).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoadingExcelReport = false;
        }, () => {
            this.isLoadingExcelReport = false;
        });
    }

    openDriversTimeTracking() {
        const modal = this.modalService.open(DriverCheckInListComponent, {
            backdrop: 'static',
            size: 'lg',
            windowClass: 'driver-check-in-list'
        });
        modal.componentInstance.isDriversTimeTracking = true;
    }

    onHubSelected($event: any) {
        this.hubFilter = $event;
        this.currentPage = 0;
        this.users = [];
        this.loadLazyUsers();
    }
    qq($event: any) {
        this.hubFilter = $event;
        this.currentPage = 0;
        this.users = [];
        this.loadLazyUsers();
    }
    getHubs(search?: string, select?) {
        const query = '?page=1&pageSize=20';
        this.hubsService.getHubs(query, search ? search : '').subscribe(
            (response: any) => {
                this.hubs = response.hubs.map(user => {
                    return {
                        label: user.name,
                        value: user.id
                    };
                });
                setTimeout(() => {
                    if (select && select.pMultiSelect && select.pMultiSelect.cd) {
                        select.pMultiSelect.activateFilter();
                        select.pMultiSelect.cd.detectChanges();
                    }
                }, 300);
            }, () => {
            }
        );
    }

    public onSelectGetIncomeCustomer(selectAll = false) {
        if (selectAll) {
            setTimeout(() => {
                this.onHubSelect(this.selectedHub);
            }, 1000);
        }
        this.onHubSelect(this.selectedHub);

        if (this.multiHubUserDialog) {
            this.multiHubUserDialog.componentInstance.disableDone.next(this.selectedHub.length === 0);
        }
    }

    public onHubSelect(hubId) {
        this.hubId = hubId;
    }

}
