import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-customer-activity-report',
    templateUrl: './customer-activity-report.component.html',
    styleUrls: ['./customer-activity-report.component.css']
})
export class CustomerActivityReportComponent {
    public customerReport = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.CUSTOMERS_ACTIVITY_REPORT;
    public reportAPI;

    constructor() {
        this.addCustomerName = this.addCustomerName.bind(this);
    }

    onCustomerSelected(customerId) {
        this.reportAPI = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.CUSTOMER_ACTIVITY_REPORT_API.replace('{customerId}', customerId);
    }

    public addCustomerName(response) {
        if (response && response.data) {
            response.data.forEach(row => row.customerName = response.customerName)
        }
        return response.data;
    }
}
