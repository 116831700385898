import {
    faArrowLeft,
    faAsterisk,
    faBan,
    faBars,
    faBell,
    faBook,
    faCalendarAlt,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faEyeSlash,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSearch,
    faSignOutAlt,
    faSignInAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimes,
    faTrashAlt,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faArrowCircleRight,
    faArrowCircleLeft,
    faClipboardList,
    faBox,
    faBoxes,
    faPen,
    faCog,
    faHandPaper,
    faBuilding,
    faMinus,
    faShippingFast,
    faKey, faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeIcons = [
    faArrowCircleRight,
    faArrowCircleLeft,
    faArrowLeft,
    faAsterisk,
    faBan,
    faBars,
    faBell,
    faBook,
    faCalendarAlt,
    faCheck,
    faChevronDown,
    faChevronUp,
    faBoxes,
    faUsers,
    faCloud,
    faCogs,
    faDatabase,
    faTachometerAlt,
    faClipboardList,
    faBox,
    faEye,
    faEyeSlash,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faRoad,
    faSave,
    faSearch,
    faSignOutAlt,
    faSignInAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimes,
    faTrashAlt,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faPen,
    faCog,
    faHandPaper,
    faBuilding,
    faMinus,
    faShippingFast,
    faKey,
];
