<p-toast [baseZIndex]="999999">
</p-toast>
<div class="page-body d-flex flex-column h-100">

    <div class="header-actions-container d-flex flex-row w-100 p-2 justify-content-end">
        <div class="header-actions">
            <div class="action flex-2d-center justify-content-end pl-2 pr-2" (click)="parcelModal()">
                <i class="fas fa-plus"></i>
                <span>{{'PARCEL.ADD_PARCEL' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="flex-1 overflow-auto">
        <app-generic-table [columns]="columnsDef" [config]="tableConfig" #table [customTemplates]="{setting:setting}">
            <div class="image-container">
                <ng-template #setting let-parcel>
                    <div class="button-group d-flex justify-content-center">
                        <button type="button" class="button-small btn fas fa fa-times btn-outline-danger"
                                (click)="deleteParcel(parcel)"></button>
                        <button type="button" class="button-small btn fa btn-outline-primary fas fa-pencil"
                                (click)="parcelModal(parcel)"></button>
                    </div>
                </ng-template>
            </div>
        </app-generic-table>
    </div>
</div>
