import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './auth.interceptor';
import {CacheInterceptor} from './cache.interceptor';

export const INTERCEPTORS = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true
    }
];
