<p-toast [baseZIndex]="999999999"></p-toast>
<div class="top-actions">
    <div class="group-tabs-container">
        <div class="pending-tab cursor-pointer tab" [ngClass]="{'selectedTab':selectedTab === TABS.WALLET}"
             (click)="onTabChange(TABS.WALLET)">
            {{'ACCOUNTING.WALLET.WALLETS' | translate}}
        </div>
        <div class="accepted-tab cursor-pointer tab" [ngClass]="{'selectedTab':selectedTab === TABS.RECEIPT}"
             (click)="onTabChange(TABS.RECEIPT)">
            {{'ACCOUNTING.WALLET.RECEIPT' | translate}}
        </div>
    </div>
</div>

<div class="d-flex flex-column p-3 flex-1" style="max-height: calc(100% - 45px)">
    <div class="h-100" *ngIf="selectedTab === TABS.WALLET">
        <app-wallet-list class="h-100"></app-wallet-list>
    </div>
    <div class="h-100" *ngIf="selectedTab === TABS.RECEIPT">
        <app-receipt-list class="h-100"></app-receipt-list>
    </div>
</div>
