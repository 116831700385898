<app-field-set-form [label]="label"
                    [cssStyle]="cssStyle"
                    [isFilter]="isFilter"
                    [filterActive]="!!value"
                    [disabled]="fieldDisabled"
                    [formControlObj]="formControlObj"
                    [showLabel]="showLabel">
    <div [ngSwitch]="fieldType" class="input-wrapper {{ showIcon ? iconPlace : ''}} {{currentLang}}"
         [ngClass]="{'filter': isFilter}">
        <i *ngIf="showIcon" class="{{icon}}" (click)="iconClicked.emit($event)"></i>
        <input *ngSwitchCase="fieldTypeEnum.INPUT_TEXT"
               [type]="inputType" pInputText
               (blur)="onBlur($event)"
               [(ngModel)]="value"
               style="{{style}}"
               [id]="id"
               (keypress)="inputValidator($event)"
               [disabled]="fieldDisabled"
               autocomplete="off"
               (focus)="whenFocus.emit($event)"
               [size]="size"
               [placeholder]="placeholder ? (placeholder | translate) : ''"
               (change)="whenChange.emit($event)"
               appInputDebounced
               (inputDebounced)="input.emit()"/>

        <textarea *ngSwitchCase="fieldTypeEnum.TEXT_AREA"
                  [id]="id"
                  [rows]="rows"
                  [cols]="cols"
                  autoResize="false"
                  pInputTextarea
                  style="{{style}}"
                  [placeholder]="placeholder | translate"
                  (blur)="onBlur($event)"
                  [disabled]="fieldDisabled"
                  (change)="whenChange.emit($event)"
                  [(ngModel)]="value">
        </textarea>
    </div>
</app-field-set-form>
