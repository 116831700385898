import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {HubsService} from '../../shared/services/hubs.service';
import {UserService} from '../../shared/services/user.service';
import {ShipmentsService} from '../../shipment/services/shipments.service';
import {UsersService} from '../../users/services/users.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {DriversService} from '../../shared/services/drivers.service';

@Component({
    selector: 'app-choose-hub-and-driver',
    templateUrl: './choose-hub-and-driver.component.html',
    styleUrls: ['./choose-hub-and-driver.component.scss']
})

export class ChooseHubAndDriverComponent implements OnInit {
    public isLoading = false;
    public form: FormGroup;
    public hubsOptions: { value: string, label: string }[];
    public driversOptions: { value: string, label: string }[];
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public driverId = 0;
    public isPickup = false;
    public partnerId: number;
    public isFromPartner = false;
    public isFromReturnPackageTab = false;
    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                public usersService: UsersService,
                private translateService: TranslateService,
                private messageService: MessageService,
                private hubsService: HubsService,
                private userService: UserService,
                private shipmentService: ShipmentsService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private driversService: DriversService) {
    }

    ngOnInit() {
        this.isLoading = false;
        this.initHubs();
        this.initInfo();
    }

    public initHubs() {
        return this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            hubId: ['', Validators.required],
            driverId: ['']
        });
        if (!this.isFromPartner) {
            this.form.controls['driverId'].setValidators([Validators.required]);
        }
    }
    public notValidForm() {
        if (this.isFromPartner) {
            return !this.form.valid || !this.partnerId;
        } else {
            return !this.form.valid;
        }
    }

    receiveFromDriver() {
        const data = this.form.getRawValue();
        if (this.isPickup) {
            let driverName = '';
            this.driversOptions.forEach(
                (driver: {label: string , value: string}) => {
                    if (Number(data.driverId) === Number(driver.value)) {
                        driverName = driver.label;
                    }
                }
            );
            this.router.navigate([this.authenticationService.companyName + 'home/draft-pickups/receive-driver/'], {
                queryParams: {
                    driverId: data.driverId.value,
                    hubId: data.hubId,
                    isPickup: true,
                    driverName: driverName
                }
            });
        } else {
            let hubName = '';
            this.hubsOptions.forEach(
                (hub: {label: string , value: string}) => {
                    if (Number(data.hubId) === Number(hub.value)) {
                        hubName = hub.label;
                    }
                }
            );
            if (this.isFromPartner) {
                if (this.partnerId && data.hubId) {
                    const queryParams = {
                        partnerId: this.partnerId,
                        hubId: data.hubId,
                        isPickup: false,
                        isFromPartner: true,
                        hubName: hubName
                    };

                    this.router.navigate([this.authenticationService.companyName + (this.isFromReturnPackageTab ?
                        'home/receive-from-partner/' : 'home/manage-shipments/receive-from-partner/')], {
                        queryParams: queryParams
                    });
                    // this.navigateTo('home/manage-shipments/receive-from-partner', queryParams);
                }
                // tslint:disable-next-line:comment-format
            } else { //package
                this.router.navigate([this.authenticationService.companyName + (this.isFromReturnPackageTab ?
                    'home/receive-driver/' : 'home/manage-shipments/receive-driver/')], {
                    queryParams: {
                        driverId: data.driverId.value,
                        hubId: data.hubId,
                        isPickup: false,
                        isOnlyReturnedPackages: this.isFromReturnPackageTab,
                        hubName: hubName
                    }
                });
            }
        }
        this.activeModal.close({isSuccess: true});

    }

    public partnerSelected(partner) {
        this.partnerId = partner;
    }

    getDrivers(event) {
        let query = '?page=1&pageSize=20';
        if (event.query) {
            query += '&search=' + event.query;
        }
        query += '&driverType=TYPICAL';

        return this.driversService.getDrivers(query).subscribe(
            (res: any) => {
                this.driversOptions = res.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {label: name, value: driver.id};
                    }
                );
            });
    }
}
