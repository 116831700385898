import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DriversService} from '../../shared/services/drivers.service';
import {ContainersService} from '../../containers/services/containers.service';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {DriverModel} from '../../shared/models/driver.model';
import {ContainerModel} from '../../shared/models/container.model';

@Component({
    selector: 'app-assign-package-component',
    templateUrl: 'assign-package.component.html',
    styleUrls: ['assign-package.component.scss']
})
export class AssignPackageComponent implements OnInit {
    @Input() assignTo: 'driver' | 'container';
    @Input() ids;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;
    public drivers: DriverModel[];
    public containers: ContainerModel[];
    public displayedDrivers: DriverModel[];
    public displayedContainers: ContainerModel[];
    public isSubmitting = false;
    public searchContent = '';
    public isPickup = false;

    constructor(
        private activeModal: NgbActiveModal,
        private driverService: DriversService,
        private containerService: ContainersService
    ) {
    }

    ngOnInit() {
        this.fetchList();
    }

    private fetchList() {
        if (this.assignTo === 'driver') {
            this.fetchDrivers();
        } else {
            this.fetchContainers();
        }
    }

    private fetchDrivers() {
        let searchQuery = '';
        if (this.searchContent) {
            searchQuery = '&search=' + this.searchContent;
        }
        this.driverService.getDrivers('?page=1&pageSize=500&driverType=TYPICAL' + searchQuery).subscribe(
            (response: any) => {
                if (response.length > 0) {
                    this.drivers = response;
                    this.displayedDrivers = this.drivers;
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
            }, () => {
            }
        );
    }

    private fetchContainers() {
        let searchQuery = '';
        if (this.searchContent) {
            searchQuery = '&search=' + this.searchContent;
        }
        this.containerService.fetchAssignedContainers('?page=1&pageSize=500' + searchQuery).subscribe(
            (response: any) => {
                if (response.totalRecordsNo > 0) {
                    this.containers = response.vehicles;
                    this.displayedContainers = this.containers;
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
            }, () => {
            }
        );
    }

    public onAssignContainer(container: ContainerModel) {
        this.isSubmitting = true;
        this.containerService.assignToContainer(container.id, {ids: this.ids}).subscribe(
            (response) => {
                this.activeModal.close({isSuccess: true});
            }, (error) => {
                this.activeModal.close({isSuccess: false});
            }
        );
    }

    public onAssignDriver(driver) {
        this.isSubmitting = true;
      if (this.isPickup) {
          this.driverService.assignPickupsToDriver(driver.id, {ids: this.ids}).subscribe(
              (response) => {
                  this.activeModal.close({isSuccess: true});
              }, (error) => {
                  this.activeModal.close({isSuccess: false});
              });
      } else {// packages
          this.driverService.assignToDriver(driver.id, {ids: this.ids}).subscribe(
              (response) => {
                  this.activeModal.close({isSuccess: true});
              }, (error) => {
                  this.activeModal.close({isSuccess: false});
              }
          );
      }
    }

    public onSearch() {
        if (this.assignTo === 'driver') {
            this.fetchDrivers();
        } else {
            this.fetchContainers();
        }
    }

    public closeModal() {
        this.activeModal.close();
    }
}
