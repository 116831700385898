import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PACKAGE_TRACKING_CONSTANTS, SHIPMENT_CONSTANTS} from './constants';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {USERS_CONSTANTS} from '../../users/services/constants';
import {PackageModel} from '../../shared/models/package.model';

@Injectable()
export class ShipmentsService {
    /**
     * Flag used to allow the user to access New Package view.
     * the same view is used to edit Package.
     * some users are allowed to edit package but forbidden to "new Package" view.
     * @type {boolean}
     */
    private _canAccessEditPackage = false;
    public addShipmentSubject = new Subject();
    public set canAccessEditPackage(isEditing) {
        this._canAccessEditPackage = isEditing;
        setTimeout(() => {
            this._canAccessEditPackage = false;
        }, 250);
    }

    @Output() shipmentAddedEvent = new EventEmitter<any>();

    public get canAccessEditPackage() {
        return this._canAccessEditPackage;
    }

    constructor(private httpClient: HttpClient) {
    }

    public getPackages(query, body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_PACKAGES + query, body);
    }
    public getContainerCarriedPackages(query, body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_PACKAGES + query, body);
    }
    public getPackagesTotalNo(query, body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_PACKAGES_COUNT + query, body);
    }
    public getNewPackagesRequests() {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_NUMBER_OF_NEW_SHIPMENTS_REQUESTS ,  {});
    }

    public collectReturnedPackages(query = '', body, inCar) {
        let url = SHIPMENT_CONSTANTS.COLLECT_RETURNED_PACKAGE;

        if (inCar) {
            url = SHIPMENT_CONSTANTS.COLLECT_RETURNED_PACKAGE_WITH_DRIVER
        }
        return this.httpClient.post( url + query, body);
    }

    public getPackagesWithFilters(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGES, {params: params});
    }

    public getPackageById(id) {
        const url = SHIPMENT_CONSTANTS.GET_PACKAGE_INFO.replace('{packageId}', id + '');
        return this.httpClient.get(url);
    }
    public getPackageIdBybarcode(barcode) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_ID_BY_BARCODE, {params: {barcode: barcode}});
    }
    public getPickups(query, searchQueryList) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_PICKUPS + query, {searchQueryList});
    }
    public createBundle(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.CREATE_BUNDLE, body);
    }

    public createBundleForReturned(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.CREATE_BUNDLE_FOR_RETURNED, body, {params: {isReturned: 1}});
    }
    public editBundleCod(packageId, body) {
        const url = SHIPMENT_CONSTANTS.EDIT_BUNDLE_COD.replace('{packageId}', packageId);
        return this.httpClient.put(url, body);
    }
    public getBundles(id) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_BUNDLES.replace('{bundleId}', id), {});
    }
    public getBundleCod(id) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_BUNDLE_COD.replace('{packageId}', id), {});
    }
    public removePackageFromBundle(bundleId, pkgId) {
        const url = SHIPMENT_CONSTANTS.REMOVE_PACKAGE_FROM_BUNDLE.replace('{bundleId}', bundleId).replace('{packageId}', pkgId);
        return this.httpClient.delete(url);
    }
    public getToConvertPickups(query) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_TO_CONVERT_PICKUPS + query, {});
    }
    public getToConvertPickupsCount() {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_TO_CONVERT_PICKUPS_COUNT , {});
    }

    public removePackage(ids) {
        const body = {
            ids : ids
        };
        const url = SHIPMENT_CONSTANTS.REMOVE_PACKAGE;
        return this.httpClient.post(url, body);
    }
    public removePickups(pickupId) {
      return this.httpClient.delete(SHIPMENT_CONSTANTS.REMOVE_PICKUPS.replace('{pickupId}', pickupId));
    }

    public getShipmentServices() {
        return this.httpClient.get(SHIPMENT_CONSTANTS.SHIPMENT_SERVICE);
    }

    public getShipmentDefaultMetrics(query) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.SHIPMENT_METRICS, {params: query});
    }

    public getShipmentTypes() {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_SHIPMENT_TYPES);
    }

    public approvePackages(ids) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.APPROVE_PACKEGES, {ids: ids});
    }
    public addPackagesAttachments(Id, data) {
        const url = SHIPMENT_CONSTANTS.UPDATE_PACKAGE_PROOF_LIST.replace('{packageId}', Id.toString());
        return this.httpClient.post(url, data, {});
    }

    public addBundle(bundleId, pkgId) {
        const url = SHIPMENT_CONSTANTS.ADD_BUNDLE.replace('{bundleId}', bundleId).replace('{packageId}', pkgId);
        return this.httpClient.put(url, {});
    }

    public requestPackage(body) {
        let url = SHIPMENT_CONSTANTS.REQUEST_PACKAGE;
        url += '?utcOffset=' + moment().utcOffset().toString();
        body.pkg.integrationSource = 'MANUAL';
        return this.httpClient.post(url, body);
    }
    public requestPackageSummarized(body) {
        let url = SHIPMENT_CONSTANTS.REQUEST_PACKAGE_SUMMARIZED;
        url += '?utcOffset=' + moment().utcOffset().toString();
        body.pkg.integrationSource = 'EXCEL';
        return this.httpClient.post(url, body);
    }
    public convertToPackage(pickupId, body) {
        body.pkg.integrationSource = 'MANUAL';
        return this.httpClient.post(SHIPMENT_CONSTANTS.CONVERT_TO_PACKAGE.replace('{pickupId}', pickupId), body);
    }

    public requestPickup(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.REQUEST_PICKUP, body);
    }

    public requestPackageChanges(id, body) {
        let url = SHIPMENT_CONSTANTS.REQUEST_PACKAGE_MODIFY.replace('{packageId}', id + '');
        url += '?utcOffset=' + moment().utcOffset().toString();
        return this.httpClient.put(url, body);
    }

    public getPackageStats() {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_STATS);
    }

    public getTransferredPackageStatus(id) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_TRANSFERRED_PACKAGE_STATS.replace('{packageId}', id));
    }

    public changePackageStatus(body, id = 0) {
        const url = SHIPMENT_CONSTANTS.CHANGE_PACKAGES_STATUS;
        return this.httpClient.put(url, body, {
            params: {
                utcOffset: moment().utcOffset().toString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        });
    }

    public unloadPackages(body) {
        const url = SHIPMENT_CONSTANTS.UNLOAD_PACKAGES;
        return this.httpClient.put(url, body);
    }

    public postponePackage(body, id, justNotes = false, adminNotes = false) {
        let url = (justNotes ? SHIPMENT_CONSTANTS.NOTES_PACKAGE : SHIPMENT_CONSTANTS.POSTPONE_PACKAGE).
        replace('{packageId}', id + '');
        if (adminNotes) {
            url += '?adminNotes=' + adminNotes;
        }
        return this.httpClient.put(url, body);
    }

    public generateBarcodes(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GENERATE_BARCODES, { params });
    }

    public noteMultiPackages (body, params = '') {
        return this.httpClient.put(SHIPMENT_CONSTANTS.NOTES_MULTI_PACKAGES + params, body);
    }

    public approveCodChange(id) {
        const url = SHIPMENT_CONSTANTS.APPROVE_COD_CHANGE.replace('{requestId}', id + '');
        return this.httpClient.put(url, {});
    }

    public rejectCodChange(id) {
        const url = SHIPMENT_CONSTANTS.REJECT_COD_CHANGE.replace('{requestId}', id + '');
        return this.httpClient.put(url, {});
    }

    public removeFlag(pkgId) {
        const body = {
            ids: [pkgId]
        };
        const url = SHIPMENT_CONSTANTS.REMOVE_PACKAGE;
        return this.httpClient.post(url, body);
    }

    public downloadPdf(pkgId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_PDF.replace('{packageId}', pkgId), {
            params: {
                utcOffset: moment().utcOffset().toString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        });
    }
    public downloadPdfTracking(pkgId) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.DOWNLOAD_PDF_tracking, {
            ids: [pkgId]
        }, {
            params: {
                utcOffset: moment().utcOffset().toString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }});
    }
    public addNewShipment(body) {
        body.pkg.integrationSource = 'MANUAL';
        return this.httpClient.post(SHIPMENT_CONSTANTS.REQUEST_PACKAGE, body);
    }

    public editShipment(body, id) {
        const url = SHIPMENT_CONSTANTS.REQUEST_PACKAGE_MODIFY.replace('{packageId}', id + '');
        return this.httpClient.put(url, body);
    }

    public getDeliveryPrice(formData) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_PACKAGE_DELIVERY_PRICE, formData, {});
    }


    public shipmentAdded(isEditMode = false) {
        this.shipmentAddedEvent.emit({isShipmentAdded: true, isEditMode: isEditMode});
    }

    public downloadMultiple(pkgIds, printType = null, params = {}) {
        params = {
            ...params,
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (printType && printType !== 'NET_REPORT' && printType !== 'REPORT') {
            params['type'] = printType;
        }
        return this.httpClient.post(SHIPMENT_CONSTANTS.DOWNLOAD_MULTIPLE, {
            ids: pkgIds
        }, {
            params: params
        });
    }

    public downloadInvoices(pkgIds) {
        const params = {
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        return this.httpClient.post(SHIPMENT_CONSTANTS.DOWNLOAD_INVOICES, {
            ids: pkgIds
        }, {
            params: params
        });
    }

    public downloadMultipleMassReports(pkgIds, isArchived = false) {
        const params = {
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        if(isArchived) {
            params['isArchived'] = isArchived;
        }
        return this.httpClient.post(SHIPMENT_CONSTANTS.DOWNlOAD_MULTIPLE_MASS_REPORTS, {
            ids: pkgIds
        }, {
            params
        });
    }

    public downloadMultipleMassPackages(pkgIds, printType?, orderBy?, isArchive = false) {
        const url = SHIPMENT_CONSTANTS.DOWNLOAD_MULTIPLE_MASS;
        const params = {
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (printType && printType !== 'NET_REPORT') {
            params['type'] = printType;
        }
        if (orderBy) {
            params['order-by'] = orderBy;
        }
        if (isArchive) {
            params['isArchived'] = true;
        }
        return this.httpClient.post(url, {
            ids: pkgIds
        }, {params});
    }

    public downloadAllMassPackages(printType, orderBy?) {
        const url = SHIPMENT_CONSTANTS.DOWNLOAD_ALL_MASS;
        const params = {
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (printType && printType !== 'NET_REPORT') {
            params['type'] = printType;
        }
        if (orderBy) {
            params['order-by'] = orderBy;
        }
        return this.httpClient.post(url, {}, {params});
    }

    public downloadMultipleChangeStatus(pkgIds, id = null, isWithSignature = false, params = {}) {
        params = {
            ...params,
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            is_with_signature: isWithSignature ? 'true' : 'false'
        };
        if (id) {
            params['customerId'] = id;
        }
        return this.httpClient.post(SHIPMENT_CONSTANTS.DOWNLOAD_MULTIPLE_CHANGE_STATUS, {
            ids: pkgIds
        }, {
            params: params
        });
    }

    public downloadCSV(url, params, tableFilters = {}) {
        return this.httpClient.post(url + params, tableFilters);
    }

    public downloadExcel(url, params, tableFilters = {}) {
        return this.httpClient.post(url + params, tableFilters);
    }
    public downloadMismatchPdf(mismachId, printType = 'PDF') {
        let url = SHIPMENT_CONSTANTS.DOWNLOAD_MISMATCH_PDF;
        url += '?printing-type=' + printType;
        url +=  '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.httpClient.get(url.replace('{codMismatchId}', mismachId));
    }

    public downloadMassPackagePdf(pkgId, printType?: string, orderBy?, datesFilters?, isArchived = false, isEmptyReport = false) {
        const url = SHIPMENT_CONSTANTS.DOWNLOAD_MASS_PDF;
        const params = {'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone};
        if (datesFilters) {
            if (datesFilters.from) {
                params['fromDate'] = datesFilters.from;
            }
            if (datesFilters.to) {
                params['toDate'] = datesFilters.to;
            }
        }
        if (printType && printType !== 'NET_REPORT') {
            params['type'] = printType;
        }
        if (orderBy) {
            params['order-by'] = orderBy;
        }
        if (isArchived) {
            params['isArchived'] = isArchived;
        }
        if (isEmptyReport) {
            params['isEmptyReport'] = isEmptyReport;
        }
        return this.httpClient.get(url.replace('{massPackageId}', pkgId), {params});
    }
    public downloadMassInvoicePackagePdf(pkgId, printType?: string, orderBy?, datesFilters?, isArchived = false) {
        const url = SHIPMENT_CONSTANTS.DOWNLOAD_MASS_INVOICE_PDF;
        const params = {'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone};
        if (datesFilters) {
            if (datesFilters.from) {
                params['fromDate'] = datesFilters.from;
            }
            if (datesFilters.to) {
                params['toDate'] = datesFilters.to;
            }
        }
        if (printType && printType !== 'NET_REPORT') {
            params['type'] = printType;
        }
        if (orderBy) {
            params['order-by'] = orderBy;
        }
        return this.httpClient.get(url.replace('{massInvoiceId}', pkgId), {params});
    }

    public getExpenses(query) {
        const url = SHIPMENT_CONSTANTS.EXPENSES;
        return this.httpClient.get(url, {params: query});
    }
    public getTotalExpensesCost(query) {
        const url = SHIPMENT_CONSTANTS.EXPENSES_SUM;
        return this.httpClient.get(url, {params: query});
    }
    public postExpenses(body) {
        const url = SHIPMENT_CONSTANTS.EXPENSES;
        return this.httpClient.post(url, body);
    }
    public putExpenses(body, id) {
        const url = SHIPMENT_CONSTANTS.EXPENSES_UPDATE;
        return this.httpClient.put(url.replace('{expensesId}', id), body);
    }
    public getExpensesType(query = '') {
        const url = SHIPMENT_CONSTANTS.EXPENSES_TYPE;
        return this.httpClient.get(url + query);
    }
    public exportToExcel(pkgId, params?) {
         return this.httpClient.get(SHIPMENT_CONSTANTS.EXPORT_TO_EXCEL.replace('{massPackageId}', pkgId), {params});
    }
    public exportToExcelForReceivedCod(customerId, params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPORT_TO_EXCEL_RECEIVED_COD.replace('{customerId}', customerId), {params: params});
    }
    public sortUserPackages(ids: number[], params= {}, isHoldCustody = false) {
        params['utcOffset'] = moment().utcOffset().toString();
        return this.httpClient.put(isHoldCustody ?
            SHIPMENT_CONSTANTS.SORT_USER_PACKAGES_WITHOUT_RELEASING_CUSTODY :
            SHIPMENT_CONSTANTS.SORT_USER_PACKAGES, {
            ids: ids
        }, {
            params
        });
    }

    public uploadExcel(formData, invoiceSupplier = false, fromPage) {
        if (fromPage !== null) {
            switch (fromPage) {
                case 'manage-shipment': {
                    if (invoiceSupplier) {
                        return this.httpClient.post(SHIPMENT_CONSTANTS.UPLOAD_EXCEL_SUPPLIER, formData, {});
                    } else {
                        return this.httpClient.post(SHIPMENT_CONSTANTS.UPLOAD_EXCEL, formData, {});
                    }
                }
                case 'manage-users': {
                    return this.httpClient.post(SHIPMENT_CONSTANTS.UPLOAD_CUSTOMERS_EXCEL, formData);
                }
            }
        }

    }

    public uploadExcelShipmentCosts(formData) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.UPLOAD_SHIPMENT_COSTS_EXCEL, formData);
    }

    public downloadExcelTemplate(fromPage, summarizedTemplate = false) {
        if (fromPage !== null) {
            switch (fromPage) {
                case 'manage-shipment': {
                    if (summarizedTemplate) {
                        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_TEMPLATE_SUMMARIZED);
                    } else {
                        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_TEMPLATE);
                    }
                }
                case 'manage-users': {
                    return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_CUSTOMERS_EXCEL_TEMPLATE);
                }
            }
        } else {
        }

    }
    public downloadExcelForRecipt(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_RECIPT, {params: params});
    }
    public printMultiCodMismatch(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.PRINT_MULTI_COD_MISMATCH, {params: params});
    }
    public downloadExcelForSortCod(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_SORT_COD, {params: params});
    }

    downloadExcelForReceivedCod(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_RECEIVED_COD, {params: params});
    }

    downloadExcelForNotReceivedCod(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_NOT_RECEIVED_COD, {params: params});
    }

    public exportCustomersCod(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPORT_CUSTOMERS_COD, {params: params});
    }

    public downloadExcelForExportedCod(body, isArchived = false, isEmptyReport = false) {
        const params = {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone};
        if(isArchived) {
            params['isArchived'] = isArchived;
        }
        if (isEmptyReport) {
            params['isEmptyReport'] = isEmptyReport;
        }
        return this.httpClient.post(SHIPMENT_CONSTANTS.DOWNLOAD_EXCEL_EXPORTED_COD, body, {params});
    }
    public receivePackagesWithoutReleasingCustody(pkgIds) {
        const body = {'barcodes': pkgIds};
        return this.httpClient.put(SHIPMENT_CONSTANTS.RECEIVE_PACKAGES_WITHOUT_RELEASE, body);
    }
    public receivePackages(packages, cod, driverId = 0,
                           hubId = 0, partnerId = 0,
                           expenses = '0', driverEarningSum = '0', expensesType = '', expenseTypeId = 0,
                           printReceipt = false, isReceived, receiveDate) {
        let url = SHIPMENT_CONSTANTS.RECEIVE_PACKAGES;
        const body = {carriedCod: cod, barcodeList: undefined};
        body.barcodeList = packages;
        if (driverId) {
            body['driverId'] = driverId.toString();
        }
        if (hubId) {
            body['hubId'] = hubId;
        }
        if (partnerId) {
            body['partnerId'] = partnerId;
        }
        body['expenses'] = expenses.toString();
        body['driverEarningSum'] = driverEarningSum.toString();
        if (expensesType) {
            body['expensesType'] = expensesType;
        }
        if (expenseTypeId !== 0) {
            body['expenseTypeId'] = expenseTypeId;
        }
        if (isReceived) {
            body['isReceived'] = isReceived;
        }
        if (receiveDate) {
            body['receiveDate'] = receiveDate;
        }
        const params = <any>{
            'is-print-receipt': printReceipt,
        };
        url = url + '?utcOffset=' + moment().utcOffset().toString();
        url = url + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.httpClient.put(url, body, {params});
    }

    public sortPackages(packages, customerId) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.SORT_PACKAGES.replace('{customerId}', customerId), {barcodeList: packages});
    }

    public getPackageByBarcode(barcode, role = 'ADMIN', id = null, isGetStatus = false, params = {}, isHub = false, isPartner = false, showOriginalStatus = false) {
        let url = SHIPMENT_CONSTANTS.GET_PACKAGE_BY_BARCODE_ADMIN;
        if (role === 'ACCOUNTANT') {
            url = SHIPMENT_CONSTANTS.GET_PACKAGE_BY_BARCODE_ACCOUNTANT;
        } else if (id) {
            url = SHIPMENT_CONSTANTS.GET_PACKAGE_BY_BARCODE_ACCOUNTANT_CUSTOMER.replace('{customerId}', id);
        }
        url = url + '?barcode=' + barcode;
        if (role === 'ACCOUNTANT' && id && isHub) {
            url += '&hubId=' + id;
        } else if (role === 'ACCOUNTANT' && id && isPartner) {
            url += '&partnerId=' + id;
        } else if (role === 'ACCOUNTANT' && id) {
            url += '&driverId=' + id;
        }

        if (isGetStatus) {
            url += '&is-get-status=' + isGetStatus;
        }
        if (showOriginalStatus) {
            url += '&showOriginalStatus=' + showOriginalStatus;
        }
        return this.httpClient.get(url,
            {params});
    }
    public getPackageByBarcodes(barcodes) {
        const url = SHIPMENT_CONSTANTS.GET_PACKAGE_BY_BARCODES;
        return this.httpClient.post(url, {barcodes});
    }

    public getReturnPackageByBarcodes(barcodes) {
        const url = SHIPMENT_CONSTANTS.GET_RETURNED_COLLECTION_BY_BARCODES;
        return this.httpClient.post(url, {barcodes});
    }

    public getExportedCODByBarcodes(barcodes) {
        const url = SHIPMENT_CONSTANTS.GET_EXPORT_COLLECTION_BY_BARCODES;
        return this.httpClient.post(url, {barcodes});
    }
    public getPickupByBarcode(barcode, driverId = null) {
        const url = SHIPMENT_CONSTANTS.GET_PICKUP_BY_BARCODE_ADMIN.replace('{driverId}', driverId) + '?barcode=' + barcode;
        return this.httpClient.get(url,
            {});
    }

    public getDriverPackageByBarcode(barcode, driverId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_DRIVER_PACKAGE_BY_BARCODE.replace('{driverId}', driverId) +
            '?barcode=' + barcode);
    }
    public getPartnerPackageByBarcode(barcode, partnerId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PARTNER_PACKAGE_BY_BARCODE.replace('{partnerId}', partnerId) +
            '?barcode=' + barcode);
    }
    public getPackageByBarcodeCancel(barcode, partnerId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_BY_BARCODE_CANCEL.replace('{partnerId}',
            partnerId), {params: {barcode}});
    }

    public getReturnedPakcageByBarcode(barcode) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGE_BY_BARCODE + '?barcode=' + barcode);
    }
    public getReturnedPackageByBarcodeWithParams(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGE_BY_BARCODE , {params});
    }

    private prepareBarcodes(barcodes) {
        let shipments = 'barcodes=';
        for (const shipment in barcodes) {
            shipments += barcodes[shipment] + ',';
        }
        shipments = shipments.slice(0, -1);
        return shipments;

    }
    public getAccountantAndAdmin(params = {}) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_ACCOUNTANT_ADMIN, {params: params});
    }
    public getCODMismatches(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_COD_MISMATCHES, {params: params});
    }
    public getCODMismatchesForAllPackages(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_COD_MISMATCHES_FOR_ALL_PACKAGE, {params: params});
    }
    public handleCodMismatch(mismatchId, cod) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.HANDLE_COD_MISMATCH.replace('{mismatchId}', mismatchId),
            {}, {params: {'paidCod': cod}});
    }

    public getPackageHistory(id, isArchived = false) {
        let url = SHIPMENT_CONSTANTS.GET_PACKAGE_HISTORY + `?packageId=${id}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        if (isArchived) {
            url += '&isArchived=true';
        }
        return this.httpClient.get(url);
    }

    public getPackageSigniture(id) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_SIGNATURE.replace('{packageId}', id));
    }
    public getPackageHistoryByBarcode(barcode, isArchived = false) {
        let url = PACKAGE_TRACKING_CONSTANTS.GET_PACKAGE_HISTORY + `?barcode=${barcode}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

        if (isArchived) {
            url += '&isArchived=true';
        }
        return this.httpClient.get(url);
    }
    public getPackageProofList(id) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_PROOF_LIST.replace('{packageId}', id));
    }
    public getPackageSignature(id) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_ONLY_SIGNATURE.replace('{packageId}', id));
    }

    public updateBulkPackage(body) {
        const url = SHIPMENT_CONSTANTS.UPDATE_BULK_PACKAGE;
        return this.httpClient.put(url, body, {
            params: {
                utcOffset: moment().utcOffset().toString(),
                'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        });
    }

    public addBarcodes(body, massPackageId) {
        const url = SHIPMENT_CONSTANTS.PUT_MASS_PACKAGE_BT_BARCODE;
        return this.httpClient.put(url.replace('{massPackageId}', massPackageId), body);
    }
    public receiveBulkPackages(body) {
        const url = SHIPMENT_CONSTANTS.RECEIVE_BULK_PACKAGES;
        return this.httpClient.put(url, body);
    }
    public receiveAll(cod, driverId, hubId, partnerId, expenses, driverEarningSum, expensesType, expenseTypeId,
                      printReceipt = false, isReceived, receiveDate, selectedAttachmentsFilter, searchParams?) {
        let url = SHIPMENT_CONSTANTS.RECEIVE_ALL;
        const body = {carriedCod : cod};
        if (driverId) {
            url += '?' + 'driverId=' + driverId;
            body['driverId'] = driverId;
        }
        if (hubId) {
            url += '?' + 'hubId=' + hubId;
            body['hubId'] = hubId;
        }
        if (partnerId) {
            url += '?' + 'partnerId=' + partnerId;
            body['partnerId'] = partnerId;
        }
        body['expenses'] = expenses.toString();
        body['driverEarningSum'] = driverEarningSum.toString();
        if (expensesType) {
            body['expensesType'] = expensesType;
        }
        if (expenseTypeId !== undefined && expenseTypeId) {
            body['expenseTypeId'] = expenseTypeId;
        }
        if (isReceived) {
            body['isReceived'] = isReceived;
        }
        if (receiveDate) {
            body['receiveDate'] = receiveDate;
        }
        if (selectedAttachmentsFilter !== 'ALL') {
            body['isOnlyAttachmentPackages'] = (selectedAttachmentsFilter === 'WITH');
        }
        const params = <any>{
            'is-print-receipt': printReceipt,
            'utcOffset': moment().utcOffset().toString(),
            'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (isReceived) {
            params['isReceived'] = isReceived;
        }

        if (searchParams) {
            if (searchParams['paymentType']) {
                params['paymentType'] = searchParams['paymentType'];
            }

            if (searchParams['fromDate']) {
                params['fromDate'] = searchParams['fromDate'];
            }

            if (searchParams['toDate']) {
                params['toDate'] = searchParams['toDate'];
            }
        }

        return this.httpClient.put(url, body, {params});
    }

    public sortBulkPackages(body, customerId) {
        const url = SHIPMENT_CONSTANTS.SORT_BULK_PACKAGES.replace('{customerId}', customerId);
        return this.httpClient.put(url, body);
    }

    public getCustodyPackages(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_CUSTODY_PACKAGES, {params: params});
    }
    public addToCustody(packages, cod, driverId = 0, expenses = '0', expensesType = '', expenseTypeId = 0,
                        printReceipt, isReceived, receiveDate, isReceiveAll = false, hubId: any = false) {
        const url = (hubId ? SHIPMENT_CONSTANTS.RECEIVE_IN_HUB_CUSTODY.replace('{hubId}', hubId) : SHIPMENT_CONSTANTS.ADD_TO_CUSTODY) + '?' + this.prepareBarcodes(packages);
        const body = {carriedCod : cod};
        if (driverId) {
            body['driverId'] = driverId.toString();
        }
        body['expenses'] = expenses.toString();
        if (expensesType) {
            body['expensesType'] = expensesType;
        }
        body['isReceived'] = isReceived;
        if (receiveDate) {
            body['receiveDate'] = receiveDate;
        }
        if (expenseTypeId !== 0) {
            body['expenseTypeId'] = expenseTypeId;
        }
        const params = <any>{
            'is-print-receipt': printReceipt,
            'is-receive-all': isReceiveAll,
            'utcOffset': moment().utcOffset().toString()
        };
        return this.httpClient.put(url, body, {params});
    }

    public getReturnedPackagesCustomers(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGES_SHIPMENTS, {params: params});
    }
    public getReturnedBundles(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_RETURNED_BUNDLES, {params: params});
    }

    public getReturnedPackagesCustomersExcel(body, params) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGES_SHIPMENTS + '/v2/excel', body, {params: params});
    }
    public getReturnedPackagesWithoutCustomer(query: string, searchQueryList: any[]) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGES_ADMIN + query, {searchQueryList});
    }
    public getReturnedPackages(query) {
        let url = SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGES.replace('{customerId}', query.customerId)
            + '?pageSize=' + query.pageSize + '&page=' + query.page;

        if (query.isDeliveredToSender !== 'undefined') {
            url += '&isDeliveredToSender=' + query.isDeliveredToSender;
        }
        if (query.status && query.status !== 'undefined') {
            url += '&status=' + query.status;
        }
        if (query.fromDate && query.fromDate !== 'undefined') {
            url += '&fromDate=' + query.fromDate;
        }
        if (query.toDate && query.toDate !== 'undefined') {
            url += '&toDate=' + query.toDate;
        }
        if (query.search && query.search !== 'undefined') {
            url += '&search=' + query.search;
        }
        if (query.type && query.type !== 'ALL') {
            url += '&type=' + query.type;
        }

        return this.httpClient.get(url, {});
    }

    public unloadReturnedPackages(body) {
        const url = SHIPMENT_CONSTANTS.UNLOAD_RETURNED_PACKAGES;
        return this.httpClient.put(url, body);
    }
    public unloadCustomersReturnedPackages(body, driverId, query = '') {
        const url = SHIPMENT_CONSTANTS.UNLOAD_CUSTOMERS_RETURNED_PACKAGES.replace('{driverId}', driverId);
        return this.httpClient.put(url + query, body);
    }
    public unloadCustomersReturnedBundle(body, driverId, query = '') {
        const url = SHIPMENT_CONSTANTS.LOAD_CUSTOMERS_RETURNED_PACKAGES.replace('{driverId}', driverId);
        return this.httpClient.put(url + query, body);
    }
    public getFollowUpPackagesCount() {
        const url = SHIPMENT_CONSTANTS.GET_FOLLOW_UP_PACKAGES_COUNT;
        return this.httpClient.get(url);
    }

    public printExpensesReport(params) {
        if (params && !params.timezone) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPENSES_PRINT, {params: params});
    }
    public deliverCustomerReturnedPackages(body) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.DELIVER_CUSTOMER_RETURNED_PACKAGES, body);
    }
    public deliverCustomersReturnedPackages(body, myQuery = '') {
        return this.httpClient.put(SHIPMENT_CONSTANTS.DELIVER_CUSTOMERS_RETURNED_PACKAGES + myQuery, body);
    }
    public deliverCustomersReturnedBundle(body, myQuery = '') {
        return this.httpClient.put(SHIPMENT_CONSTANTS.DELIVER_CUSTOMERS_RETURNED_BUNDLE + myQuery, body);
    }

    public printCustomerReturnedPackages(ids, params = {}) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.PRINT_CUSTOMER_RETURNED_PACKAGES, {ids: ids}, {
            params
        });
    }

    public printCustomerReturnedBundles(id) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.PRINT_CUSTOMER_RETURNED_BUNDLE.replace('{id}', id), {},
            {params: {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}});
    }

    public exportCustomerReturnedPackages(params = {}) {

        return this.httpClient.post(SHIPMENT_CONSTANTS.EXPORT_CUSTOMER_RETURNED_PACKAGES, {}, {
            params
        });
    }

    public exportCustomerReturnedBundles(id) {

        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPORT_CUSTOMER_RETURNED_BUNDLE.replace('{id}', id),
            {params: {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}});
    }
    public getPackagePDFBlob(url) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.httpClient.get(url, {headers: headers, responseType: 'blob'});
    }

    public getFaliuresHistory(id) {
        const url = SHIPMENT_CONSTANTS.GET_FAILED_PACKAGES_HISTORY.replace('{packageId}', id);
        return this.httpClient.get(url);
    }

    public transferPackageByIds(partnerId, packageIds) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_BY_BARCODE, {
            partnerId: partnerId,
            ids: packageIds
        });
    }

    public exportPackagesToHub(hubId, packageIds) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.EXPORT_PACKAGES_TO_HUB.replace('{toHubId}', hubId), {ids: packageIds});
    }

    public getPartnerPackages(query, tableFilters = {}) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_PARTNER_PACKAGES + query, tableFilters);
    }

    public cancelPackageByIds(partnerId, packageIds) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.CANCEL_PACKAGES_BY_IDS.replace('{partnerId}', partnerId), {
            partnerId: partnerId,
            ids: packageIds
        });
    }

    public getExpectedDeliveryDays(serviceTypeId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_EXPECTED_DELIVERY_DAYS.replace('{serviceTypeId}', serviceTypeId));
    }

    public printByBarcodes(barcodes, printType) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.PRINT_BY_BARCODES,
            {barcodes}, {params: {type: printType, utcOffset: moment().utcOffset().toString(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}});
    }
    public receiveDriverPackages(driverId, hubId, ids , printType = null) {
        let url = SHIPMENT_CONSTANTS.RECEIVE_DRIVER_PACKAGES.replace('{driverId}',
               driverId).replace('{hubId}', hubId) ;
        if (printType) {
            url += '?printType=' + printType;
        }
        return this.httpClient.put(url, {ids});
    }
    // public receiveDriverPackages(driverId, hubId, ids, printType) {
    //     return this.httpClient.put(SHIPMENT_CONSTANTS.RECEIVE_DRIVER_PACKAGES.replace('{driverId}',
    //         driverId).replace('{hubId}', hubId) + '?printType=' + printType, {ids});
    // }

    public receiveDriverPickups(driverId, hubId, ids) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.RECEIVE_DRIVER_PICKUPS.replace('{driverId}',
            driverId).replace('{hubId}', hubId), {ids});
    }
    public receivePartnerPackages(body) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.RECEIVE_PARTNER_PACKAGES , body);
    }
    public getExpensesTypes(query) {
    const url = SHIPMENT_CONSTANTS.GET_EXPENCES_TYPE;
        return this.httpClient.get(url + query);
    }
    public deleteExpensesTypes(id) {
        const url = SHIPMENT_CONSTANTS.DELETE_EXPENSE_TYPE.replace('{expenseTypeId}', id);
        return this.httpClient.delete(url);
    }
    public addExpensesTypes(body) {
        const url = SHIPMENT_CONSTANTS.ADD_EXPENSE_TYPE;
        return this.httpClient.post(url, body);
    }
    public transferToThirdPart(packageIds) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_TO_THIRD_PART, {
             ids: packageIds
        });
    }

    public updateCodMismatch(id, cod, driverId = 0,
                           hubId = 0, partnerId = 0,
                           expenses = '0', driverEarningSum = '0', expensesType = '', expenseTypeId = 0,
                           printReceipt = false, isReceived, receiveDate) {
        const url = SHIPMENT_CONSTANTS.UPDATE_COD_MISMATCH.replace('{mismatchId}', id);
        const body = {carriedCod : cod};
        if (driverId) {
            body['driverId'] = driverId.toString();
        }
        if (hubId) {
            body['hubId'] = hubId;
        }
        if (partnerId) {
            body['partnerId'] = partnerId;
        }
        if (expenses) {
            body['expenses'] = expenses.toString();
        }
        if (driverEarningSum) {
            body['driverEarningSum'] = driverEarningSum.toString();
        }
        if (expensesType) {
            body['expensesType'] = expensesType;
        }
        if (expenseTypeId !== 0) {
            body['expenseTypeId'] = expenseTypeId;
        }
        if (isReceived) {
            body['isReceived'] = isReceived;
        }
        if (receiveDate) {
            body['receiveDate'] = receiveDate;
        }
        const params = <any>{
            'is-print-receipt': printReceipt
        };
        return this.httpClient.put(url, body, {params});
    }

    public getShipmentStatusColor(shipmentStatus) {
        switch (shipmentStatus) {
            case 'PENDING_CUSTOMER_CARE_APPROVAL':
            case 'PARTIALLY_PICKED':
                return '#B772B8';
            case 'CANCELLED':
            case 'CANCELED':
                return '#F24645';
            case 'SCANNED_BY_DRIVER_AND_IN_CAR':
            case 'PICKED':
                return '#00E2FF';
            case 'SCANNED_BY_HANDLER_AND_UNLOADED':
            case 'CREATED':
                return '#FF914D';
            case 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY':
            case 'PACKED':
                return '#913463';
            case 'POSTPONED_DELIVERY':
                return '#5EB4FF';
            case 'DELIVERED_TO_RECIPIENT':
            case 'DELIVERED':
            case 'SORTED':
                return '#2ED47A';
            case 'DELAYED':
                return '#B3404A';
            case 'TRANSFERRED_OUT':
                return '#B3404A';
            case 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER':
                return '#FAD16A';
            case 'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP':
                return '#6A9CFA';
            case 'BROUGHT':
                return '#72b8a8';
            case 'SWAPPED':
                return '#FF9980';
            case 'DRAFT':
                return '#59788E';
            case 'COMPLETED':
                return '#808000';
            case 'RETURNED_PARTIALLY':
                return '#0026a1';
            case 'RETURNED_BY_RECIPIENT':
                return '#CC7C68';
            case 'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT':
                return '#a10500';
            case 'REJECTED_BY_DRIVER':
            case 'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT':
                return '#EC7063';
            case 'ASSIGNED_TO_DRIVER':
                return '#ff00cc';
            default:
                return '#ccc';
        }
    }
    public manageShipmentTableCustomization(type) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.MANAGE_SHIPMENTS_TABLE_CUSTOMIZATION + '?type=' +  type, {});
    }
    public swapSenderAndReceiver(pkgId) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.SWAP_SENDER_RECEIVER.replace('{packageId}', pkgId), {});
    }
    public getGetManageShipmentTableCustomization() {
        return this.httpClient.get(SHIPMENT_CONSTANTS.MANAGE_SHIPMENTS_TABLE_CUSTOMIZATION);
    }

    uploadShipmentAttachments(packageId: number, files: (File | Blob)[], source?) {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        const params = {};
        if (source) {
            params['addingStage'] = source;
        }
        return this.httpClient.post(SHIPMENT_CONSTANTS.UPDATE_PACKAGE_PROOF_LIST.replace('{packageId}', packageId.toString()), formData, {params});
    }

    public markAsLclPaid(packageId, amount = null, files: (File | Blob)[]) {
        let formData = null;
        if (files && files.length) {
            formData = new FormData();
            files.forEach(file => {
                formData.append('files', file);
            });
            let url = SHIPMENT_CONSTANTS.MARK_AS_LCL_PAID.replace('{packageId}', packageId);
            if (amount) {
                url += '?paidAmount=' + amount;
            }
            return this.httpClient.put(url, formData);
        }
        return this.httpClient.put(SHIPMENT_CONSTANTS.MARK_AS_LCL_PAID.replace('{packageId}', packageId), {});
    }
    public editReceiverInfo(packageId, pkg, receiverInfo) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.EDIT_RECEIVER_INFO.replace('{packageId}', packageId), pkg,
            {params: {receiverInfo: receiverInfo}});
    }
    public transferPackagesToThirdPart(ids, companyId) {
        let url = '';
        if (companyId === 137) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_HFD;
        } else if (companyId === 167) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_HEBRON;
        } else if (companyId === 94) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_TO_THIRD_PART;
        } else if (companyId === 153) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_COMPANY_ID_153;
        } else if (companyId === 128) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_COMPANY_ID_128;
        } else if (companyId === 155) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_COMPANY_ID_155;
        } else if (companyId === 207) {
            url = SHIPMENT_CONSTANTS.TRANSFER_PACKAGES_EVA_TO_ALKHAL;
        }
        if (url === '') {
            return null;
        }
        return this.httpClient.post(url, {ids: ids});
    }
    public receiveLclItems(packageId: number, hubId: number) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.RECEIVE_LCL_ITEMS.replace('{packageId}', `${packageId}`), {
            hubId: hubId
        });
    }
    public AddSenderAddressBook(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.ADD_SENDER_ADDRESS_BOOKS, body);
    }
    public editSenderAddressBook(id, body) {
        const url = SHIPMENT_CONSTANTS.EDIT_SENDER_ADDRESS_BOOKS.replace('{addressId}', id);
        return this.httpClient.put(url, body, {});
    }
    public removeSenderAddressBook(id) {
        const url = SHIPMENT_CONSTANTS.DELETE_SENDER_ADDRESS_BOOKS.replace('{addressId}', id);
        return this.httpClient.delete(url);
    }

    public uploadDeliverCodAttachments(id, formData) {
        return this.httpClient.post(USERS_CONSTANTS.UPLOAD_DELIVER_COD_ATTACHMENTS.replace('{massCodPackageId}', id), formData);
    }
    public getContainerInfo(containerNumber) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_CONTAINER_INFO.replace('{barcode}', containerNumber));
    }
    public addPackagesToContainer(containerId, body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.ADD_PACKAGES_TO_CONTAINER.replace('{containerId}', containerId), body);
    }
    public createContainerBundle(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.CREATE_CONTAINER_BUNDLE, body);
    }
    public unloadFromContainer(pkgId) {
        return this.httpClient.delete(SHIPMENT_CONSTANTS.UNLOAD_PACKAGE_FROM_CONTAINER.replace('{pkgId}', pkgId));
    }

    public getContainerSubBundles(containerBarcode) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_CONTAINER_PACKAGES.replace('{barcode}', containerBarcode));
    }

    public sendVerificationPinCode(pkgId) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.SEND_VERIFICATION_PIN_CODE.replace('{packageId}', pkgId), {});
    }

    public transferToOto(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_OTO, body);
    }
    public transferToStorm(body, companyId = 0) {
        return this.httpClient.post(companyId === 199 ? SHIPMENT_CONSTANTS.TRANSFER_TO_STORM_ROLOI : SHIPMENT_CONSTANTS.TRANSFER_TO_STORM, body);
    }

    public transferToMughrabe(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_MUGHRABE, body);
    }
    public transferToOustol(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_OUSTOL, body);
    }
    public transferToAutobahn(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_AUTOBAHN, body);
    }
    public genericTransferTo(from, to, body, customUrl?) {
        return this.httpClient.post(customUrl ? customUrl : SHIPMENT_CONSTANTS.GENERIC_TRANSFER_TO.replace('{from}', from).replace('{to}', to), body);
    }
    public transferToAlkhal(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_ALKHAL, body);
    }

    public transferToSpeedLine(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_SPEED_LINE, body);
    }

    public transferToSonic(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_SONIC, body);
    }

    public transferToEveryWhere(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_EVERYWHERE, body);
    }

    public transferToHiExpress(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_HI_EXPRESS, body);
    }

    public transferToAman(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_AMAN, body);
    }
    public transferToIMile(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_IMILE, body);
    }
    public transferToSMB(body) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.TRANSFER_TO_SMB, body);
    }
    public transferToTorod(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_TOROD, body);
    }
    public transferToHolol(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_HOLOL, body);
    }
    public transferToHawk(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.TRANSFER_TO_HAWK, body);
    }

    public printCustodyReport(params: {}) {
        params = {
            ...params,
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        return this.httpClient.get(SHIPMENT_CONSTANTS.PRINT_CUSTODY_REPORTS , {params});
    }

    public transferToAramex(body) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.TRANSFER_TO_ARAMEX, body);
    }
    public printJAndTReport(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.PRINT_JNT_REPORT, body);
    }
    public printSmbReport(body) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.PRINT_SMB_REPORT, body);
    }

    exportCodReportForCustomer(customerId, params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPORT_COD_FOR_CUSTOMER_TO_EXCEL.replace('{customerId}', customerId), {params});
    }
    exportCodReportAllCustomers(customerId, params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_COD_MISMATCHES_FOR_ALL_PACKAGE_EXCEL, {params});
    }

    modifyPackageDestinationAddress(body: any, packageId) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.MODIFY_PACKAGE_DESTINATION_ADDRESS.replace('{packageId}', packageId), body);
    }

    receiveInHubCustody(hubId, body: { ids: any[] }) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.RECEIVE_IN_HUB_CUSTODY.replace('{hubId}', hubId), body);

    }

    downloadDeliveredToSender(url: string, body: any, params: any) {
        return this.httpClient.post(url, body, {params});
    }

    getPackageHistoryStatuses(pkg: PackageModel, isArchived = false) {
        const params = {
            packageId: pkg.id,
            barcode: pkg.barcode
        };
        if (isArchived) {
            params['isArchived'] = isArchived;
        }
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_HISTORY_STATS, {params});
    }

    addPackageToBundle(bundleId, packageId) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.ADD_PACKAGE_TO_BUNDLE.replace('{bundleId}', bundleId).replace('{packageId}', packageId), {});
    }

    extractBundle(bundleId, body) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.EXTRACT_BUNDLE.replace('{bundleId}', bundleId), body);
    }

    resolveFailedPackage(pkgId, body) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.RESOLVE_FAILED_PACKAGE.replace('{packageId}', pkgId), body);
    }

    getPackageContentsReport(type: string, params) {
        let  url = SHIPMENT_CONSTANTS.GET_PACKAGES_CONTENT_REPORTS_BY_BARCODE;
        if (type === 'BY_CUSTOMER') {
            url = SHIPMENT_CONSTANTS.GET_PACKAGES_CONTENT_REPORTS_BY_CUSTOMER;
        }
        return this.httpClient.get(url, {params: params});
    }

    getPackageContents(pkgId, params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_CONTENTS.replace('{packageId}', pkgId), {params: params});
    }
    getMassCodPkgHistory(id, params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.MASS_COD_HISTORY.replace('{massCodReportId}', id), {params: params});
    }

    getExpensesHistory(id, params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPENSES_HISTORY.replace('{expenseId}', id), {params: params});
    }

    getDistributorPackagesReport(params: any) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DISTRIBUTOR_RETURNED_PACKAGES_REPORT, {params: params});
    }

    getDistributorFileForReport(params: any, type) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.DISTRIBUTOR_RETURNED_PACKAGES_REPORT + '/' + type, {params: params});
    }

    exportPackageContentsReport(value: any, pkgId, type) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_CONTENTS + '/' + type, {params: {distributorTemplate: value, packageId: pkgId}});
    }

    printJNT(packageId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.PRINT_JNT.replace('{packageId}', packageId));
    }
    printAramex(packageId) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.PRINT_ARAMEX.replace('{packageId}', packageId));
    }

    cancelJNT(packageId, params: {reason}) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.CANCEL_JNT.replace('{packageId}', packageId), {reason: params.reason});
    }

    getTripsNumber() {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_ALL_TRIP_NUMBER, {});
    }

    getStations(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_HUBS_BY_CITIES, {params});
    }

    getSuggestionTripNumber(params) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_SUGGESTION_TRIP_NUMBER, {params});
    }

    sendRemainderForFailedAttempts(pkgId) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.SEND_NOTIFICATION_FAILED_PENDING_SORT.replace('{packageId}', pkgId), {});
    }

    createInvoices(body: { ids: any }, params) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.CREATE_INVOICE, body, {params});
    }

    public exportExcelReceivingPackages(body, params) {
        return this.httpClient.post(SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGES_ADMIN + '/excel', body, {params: params});
    }

    public printAsEnvelope(pkgIds, isArchived = false) {
        const params =  {
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            massPackageIds: pkgIds
        };

        if(isArchived) {
            params['isArchived'] = isArchived;
        }
        return this.httpClient.get(SHIPMENT_CONSTANTS.PRINT_AS_ENVELOPE, {
            params
        });
    }

    public payMassInvoice(pkgId, params) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.PAY_MASS_INVOICE.replace('{massInvoiceId}', pkgId), {}, {
            params
        });
    }

    public exportReadByBarcodePackages(pkgIds) {
        return this.httpClient.get(SHIPMENT_CONSTANTS.EXPORT_READ_BY_BARCODE_PACKAGES, {
            params: {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                packageIds : pkgIds
            }
        });

    }

    public partiallyExport(id, body) {
        const url = SHIPMENT_CONSTANTS.EXPORT_PARTIALLY.replace('{customerId}', id);
        return this.httpClient.post(url, body);
    }

    public exportExcelReceiveCod() {

    }

    getReturnedPackageByBarcodeForBundle(barcode, customerId: boolean) {
        const url = SHIPMENT_CONSTANTS.GET_RETURNED_PACKAGE_BY_BARCODE_FOR_BUNDLE.replace('{customerId}', customerId + '');

        return this.httpClient.get(url,
            {params: {barcode}});
    }

    public changeDriver(driverId, ids) {
        return this.httpClient.put(SHIPMENT_CONSTANTS.CHANGE_DRIVER_MASS_REPORT, {ids},
            {params: {driverId}});
    }
}
