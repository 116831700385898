<app-field-set-form [label]="label"
                    [cssStyle]="cssStyle"
                    [isFilter]="isFilter"
                    [filterActive]="!!selectedItems?.length"
                    [formControlObj]="formControlObj"
                    [showLabel]="showLabel" [ngClass]="currentLang === 'ar' ? 'multiselect-rtl' : 'multiselect-ltr'">
    <p-multiSelect
            #pMultiSelect
            [appendTo]="appendTo"
            [dropdownIcon]="dropdownIcon"
            [ariaFilterLabel]="ariaFilterLabel"
            [ariaLabelledBy]="ariaLabelledBy"
            [autofocusFilter]="autofocusFilter"
            [dataKey]="dataKey"
            [disabled]="disabled"
            [displaySelectedLabel]="displaySelectedLabel"
            [emptyFilterMessage]="emptyFilterMessage"
            [filter]="filter"
            [filterMatchMode]="filterMatchMode"
            [filterValue]="filterValue"
            [filterLocale]="filterLocale"
            [filterBy]="filterBy"
            [filterPlaceHolder]="filterPlaceHolder"
            [inputId]="inputId"
            [maxSelectedLabels]="maxSelectedLabels"
            [name]="name"
            [options]="options"
            [optionLabel]="optionLabel"
            [optionValue]="optionValue"
            [optionDisabled]="optionDisabled"
            [optionGroupLabel]="optionGroupLabel"
            [optionGroupChildren]="optionGroupChildren"
            [group]="group"
            [overlayVisible]="overlayVisible"
            [panelStyle]="panelStyle"
            [placeholder]="placeholder | translate"
            [readonly]="readonly"
            [emptyMessage]="emptyMessage"
            [resetFilterOnHide]="resetFilterOnHide"
            [scrollHeight]="scrollHeight"
            [selectedItemsLabel]="selectedItemsLabel"
            [selectionLimit]="selectionLimit"
            [showHeader]="showHeader"
            [showToggleAll]="showToggleAll"
            [style]="style"
            [styleClass]="styleClass"
            [tabindex]="tabindex"
            [tooltip]="tooltip"
            [tooltipStyleClass]="tooltipStyleClass"
            [tooltipPosition]="tooltipPosition"
            [tooltipPositionStyle]="tooltipPositionStyle"
            [virtualScroll]="virtualScroll"
            [(ngModel)]="selectedItems"
            (onClick)="click($event)"
            (onFilter)="onFilter($event)"
            (onChange)="change($event)">
        <ng-template let-items pTemplate="selectedItems">
            <ng-container *ngFor="let item of items; let i = index">
                        <span class="chip-container" *ngIf="i < maxSelectedLabels">
                            <span class="chip-label text-ellipsis">{{getLabels(item)[0][this.optionLabel]}}</span>
                            <span class="chip-icon pi pi-fw pi-times" (click)="removeItem({itemValue: item, value: selectedItems})"></span>
                        </span>
            </ng-container>
            <span class="other-elements" *ngIf="items && items.length >= maxSelectedLabels && items.length - maxSelectedLabels !== 0"> +{{ items.length - maxSelectedLabels }}</span>
            <span *ngIf="!items || items.length === 0">{{placeholder | translate}}</span>
        </ng-template>
    </p-multiSelect>
</app-field-set-form>
