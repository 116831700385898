import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {ReceiveDriverAndReadByBarcodeComponent} from '../recieve-driver-and-read-by-barcode/receive-driver-and-read-by-barcode.component';
import {ChooseHubDriverPartnerComponent} from '../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmMessageComponent} from '../../shared/components/confirm-message/confirm-message.component';
import {RETURNED_TYPES_LIST} from '../services/constants';
import {ApplicationStateService} from '../../shared/services/application-state.service';
import {SHIPMENT_CONSTANTS} from '../shipment-constants';
import {SHIPMENT_CONSTANTS as APIS} from '../../shipment/services/constants';
import {SharedService} from '../../shared/services/shared-service';
import * as moment from 'moment';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {ColumnDef, COLUMNS_TYPE, GenericTableComponent} from '../../shared/components/generic-table/generic-table.component';

@Component({
    selector: 'app-returned-packages',
    templateUrl: './returned-packages.component.html',
    styleUrls: ['./returned-packages.component.scss']
})
export class ReturnedPackagesComponent implements OnInit {
    public isLoading = false;
    public isPageLoading = false;
    public fromDate;
    public toDate;
    public selectedLanguage = '';
    public searchContent = '';
    public totalRecords;
    public numberOfCustomers;
    // public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public permissions = '';
    public dateRangPickerOptions;
    public datesFilterForm: FormGroup;
    public isThereSelection = false;
    public isAllSelected = false;
    public showActions = true;
    public getPackagesLoader = false;
    public customerId;

    selectedType = 'ALL';
    typesList: any;
    inCar: any;
    deliverToSenderFlag: any;
    private partiallyDelivered: boolean;
    receiveReturned = false;
    receivePackagesPartner = false;
    receivePackagesFromDrive = false;
    showPickWithDriver = false;
    hideDeliverToSenderBtn = true;
    showMoveActions = true;
    @ViewChild('tableContainer') tableContainer;
    @ViewChild('tt') tt: GenericTableComponent;
    hideStatusTypeFilter = false;
    private hasMore = true;

    @ViewChild('returnedTable') table;
    isExportingExcel = false;
    isExportingPdf = false;
    isExporting = false;
    isDownloading = false;
    public driverId = 0;

    showReadByBarcode = false;
    selectedBarcodeFilter = 'ALL';
    isBundle;
    columnsDef: ColumnDef[];
    tableConfig;
    filterParams = {};

    returnedPackages = [];
    constructor(@Inject(FormBuilder) private formsBuilder: FormBuilder,
                private translateService: TranslateService,
                private shipmentsService: ShipmentsService,
                private datePipe: DatePipe,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef,
                private userService: UserService,
                private modalService: NgbModal,
                private authenticationService: AuthenticationService,
                private rolesService: RolesService,
                private dateRangePickerService: DateRangePickerService,
                private route: ActivatedRoute,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private applicationStateService: ApplicationStateService,
                private sharedService: SharedService) {

}

    ngOnInit() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.selectedLanguage = this.translateService.currentLang;
        this.initSelectedType();
        this.typesList = this.setTypesList();
        // this.lazyLoadHubs();

        this.initColumnDef();
        this.tableConfig = {
            url: this.isBundle ? APIS.GET_RETURNED_BUNDLES : APIS.GET_RETURNED_PACKAGES_SHIPMENTS,
            trClickEvent: this.goToCustomerPackages.bind(this),
            preRequestHandler: (req: {
                params: any; body: any; url: any;
            }) => {
                req.params = {
                    ...req.params,
                    ...this.createParams()
                };
            },
            prepareResponse: (res) => {
                res.data = (this.isBundle ? res.data : res.customers);
                this.totalRecords = res.totalRecordsNo;
                this.numberOfCustomers = res.numberOfCustomers;
                this.returnedPackages = res.data;
                return res;
            }

        };
        this.initDatesFilterForm();
        this.setDateRangePickerOptions();
    }

    private initColumnDef() {
        this.columnsDef = [
            {
                header: '',
                field: 'isSelected',
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                templateName: 'checkbox',
                templateHeaderName: 'top',
                columnType: COLUMNS_TYPE.CUSTOM_TEMPLATE
            },
            {
                header: '',
                field: this.isBundle ? 'barcode' : 'massReturnedPackagesReportBarcode',
                templateHeaderName: 'barcodeFilter',
                tdType: COLUMNS_TYPE.BARCODE,
                columnType: COLUMNS_TYPE.CUSTOM_TEMPLATE
            },
            {
                header: 'MANAGE_SHIPMENTS.STATUS',
                field: 'massReturnedPackagesReportBarcode',
                tdType: COLUMNS_TYPE.STATUS,
                hidden: !(this.isBundle && !this.deliverToSenderFlag)
            },
            {
                header: 'RETURNED_PACKAGES.CUSTOMER_NAME',
                field: 'customerName',
            },
            {
                header: 'RETURNED_PACKAGES.CITY',
                field: this.isBundle ? 'cityName' : 'city'
            },
            {
                header: 'RETURNED_PACKAGES.DRIVER_NAME',
                field: 'driverName',
                hidden: !this.inCar
            },
            {
                header: 'RETURNED_PACKAGES.PACKAGES_NUMBER',
                field: 'packagesNumber',
            },
            {
                header: 'RETURNED_PACKAGES.COST',
                field: this.isBundle ? 'costSum' : 'cost',
                hidden: !this.permissions.includes('{EDIT_COST')
            },
            {
                header: 'RETURNED_PACKAGES.EXPORT',
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                templateName: 'exportAction',
                hidden: this.deliverToSenderFlag
            },
            {
                header: 'RETURNED_PACKAGES.PRINT',
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                templateName: 'printAction'
            },
            {
                header: 'RETURNED_PACKAGES.COLLECT',
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                hidden: !(this.showMoveActions && !this.isBundle),
                templateName: 'collectAction'
            }
        ];
    }


    public setDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions(3, false, false, true, true, true, 'MONTH').then(options => {
            this.dateRangPickerOptions = options;

            this.route.queryParams.subscribe(params => {
                if (this.authenticationService.companyId === 93) {
                    this.datesFilterForm.patchValue({fromDate: new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 20))});
                    this.dateRangPickerOptions.startDate = new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 20));
                } else {
                    if (params.fromDate) {
                        this.datesFilterForm.patchValue({fromDate: new Date(params.fromDate)});
                        this.dateRangPickerOptions.startDate = new Date(params.fromDate);
                    }
                }
                if (params.toDate) {
                    this.datesFilterForm.patchValue({toDate: new Date(params.toDate)});
                    this.dateRangPickerOptions.endDate = new Date(params.toDate);
                }
            });

        });
    }

    public onSearch() {
        this.reset();
        this.totalRecords  = null;
        this.numberOfCustomers  = null;
        this.tt.refreshTableData();
    }
    public reset() {
        this.totalRecords = null;
        this.numberOfCustomers = null;
        this.hasMore = true;
        this.isThereSelection = false;
    }
    public onCustomerFilterChange(id) {
        this.customerId = (id === undefined ? null  : id);
        this.reset();
        this.tt.refreshTableData();
    }
    public createParams() {
        const params = {};

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.selectedType && this.selectedType !== 'ALL') {
            params['type'] = this.selectedType;
        }
        if (this.customerId !== undefined && this.customerId !== null) {
            params['customerId'] = this.customerId;
        }
        if (this.datesFilterForm) {
            if (this.datesFilterForm.controls.fromDate.value) {
                params['fromDate'] = this.transformDate(this.datesFilterForm.controls.fromDate.value);
            }
            if (this.datesFilterForm.controls.toDate.value) {
                params['toDate'] = this.transformDate(this.datesFilterForm.controls.toDate.value);
            }
        }
        if (params['fromDate'] || params['toDate']) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (this.inCar) {
            params['isReturnedWithDriver'] = true;
        }
        if (this.deliverToSenderFlag) {
            params['isDeliveredToSender'] = true;
        }
        // if (this.partiallyDelivered) {
        //     params['isPartiallyDelivered'] = true;
        // }

        if (this.driverId) {
            params['driverId'] = this.driverId;
        }

        if (this.selectedBarcodeFilter !== 'ALL') {
            params['returnPackageType'] = this.selectedBarcodeFilter;
        }
        return params;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public print(customer, event, customerIds = null, deliverToSender = false) {
        this.isLoading = true;
        let body = [];
        if (!customerIds) {
            body = [{id: customer.customerId, barcode: customer.massReturnedPackagesReportBarcode}];
        } else {
            body = customerIds;
        }
        const params = <any>{
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (this.selectedType && this.selectedType !== 'ALL') {
            params['type'] = this.selectedType;
        }
        if (this.inCar) {
            params['isReturnedWithDriver'] = true;
            params['driverId'] = customer.driverId;
        }
        if (this.partiallyDelivered) {
            params['isPartiallyDelivered'] = true;
        }

        if (this.deliverToSenderFlag) {
            params['isWithMassReturnedBarcode'] = customer.massReturnedPackagesReportBarcode ? 'true' : 'false';
            params['isDeliveredToSender'] = true;
            params['customerId'] = customer.customerId;
        } else {
            params['returnPackageStatus'] =  this.inCar ? 'DRIVER' : 'HUB';
        }

        (this.isBundle ? this.shipmentsService.printCustomerReturnedBundles(customer.id) : this.shipmentsService.printCustomerReturnedPackages(body, params)).subscribe((res: any) => {
            this.isLoading = false;
            if (deliverToSender) {
                this.deliverToSender(res.ids);
            }
            this.reset();
            window.open(res.url, '_blank');
        }, () => {
            this.isLoading = false;
        });
        if (event !== null) {
            event.stopPropagation();
        }
    }
    public export(customer, event) {
        this.isLoading = true;
        const params = <any>{
            // pageSize: this.pageSize,
            // page: this.pageNumber,
            utcOffset: moment().utcOffset().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            returnPackageStatus: this.inCar ? 'DRIVER' : 'HUB',
            customerId: customer.customerId,
        };
        if (customer.massReturnedPackagesReportBarcode !== undefined && customer.massReturnedPackagesReportBarcode !== null) {
            params['barcode'] = customer.massReturnedPackagesReportBarcode;
        }
        if (this.selectedType && this.selectedType !== 'ALL') {
            params['type'] = this.selectedType;
        }
        if (this.inCar) {
            params['isReturnedWithDriver'] = true;
            params['driverId'] = customer.driverId;
        }
        if (this.partiallyDelivered) {
            params['isPartiallyDelivered'] = true;
        }
        if (this.datesFilterForm) {
            if (this.datesFilterForm.controls.fromDate.value) {
                params['fromDate'] = this.transformDate(this.datesFilterForm.controls.fromDate.value);
            }
            if (this.datesFilterForm.controls.toDate.value) {
                params['toDate'] = this.transformDate(this.datesFilterForm.controls.toDate.value);
            }
        }

        (this.isBundle ? this.shipmentsService.exportCustomerReturnedBundles(customer.id) : this.shipmentsService.exportCustomerReturnedPackages(params)).subscribe((res: any) => {
            this.isLoading = false;
            window.open(res.url, '_blank');
        }, () => {
            this.isLoading = false;
        });
        if (event !== null) {
            event.stopPropagation();
        }
    }

    public goToCustomerPackages(customer) {
        let fromDate;
        let toDate;
        if (this.datesFilterForm) {
            if (this.datesFilterForm.controls.fromDate.value) {
                fromDate = this.transformDate(this.datesFilterForm.controls.fromDate.value);
            }
            if (this.datesFilterForm.controls.toDate.value) {
                toDate = this.transformDate(this.datesFilterForm.controls.toDate.value);
            }
        }

        const query = {
            queryParams: {
                withoutCustomerFilter: true,
                inCar: this.inCar,
                deliverToSender: this.deliverToSenderFlag,
                partiallyDelivered: this.partiallyDelivered,
                selectedType: this.selectedType,
                fillFiltersFromQuery: true,
                isWithMassReturnedBarcode: false,
                fromDate,
                toDate,
                showPartiallyExport: true
            }
        };
        if (customer.massReturnedPackagesReportBarcode) {
            query['queryParams']['barcode'] = customer.massReturnedPackagesReportBarcode;
        }
        if (this.inCar) {
            query['queryParams']['driverId'] = customer.driverId;
        }

        if (this.isBundle) {
            this.navigateTo('change-status', {isBundle: true, bundleId: customer.id});
        } else {
            this.router.navigate([this.authenticationService.companyName + 'home/shipments/returned-packages/' +
            customer.customerId], query);
        }

    }


    public navigateTo(navigateTo, queryParams = {}) {
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }
    public receiveReturnedPackages() {
        let className = 'receive-driver-and-read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'receive-driver-and-read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isReceiveReturnedPackages = true;
        modal.componentInstance.noDataAvailableMessage = 'RECEIVE_FROM_DRIVER.CHOOSE_HUB_ALERT';
        modal.componentInstance.isReturned = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public readByBarcode() {
        let className = 'read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isReadByBarcode = true;
        modal.componentInstance.returnedModal = true;
        modal.componentInstance.showPickWithDriver = this.showPickWithDriver;
        modal.componentInstance.selectedType = this.selectedType;
        modal.componentInstance.inCar = this.inCar;
        modal.componentInstance.partiallyDelivered = this.partiallyDelivered;
        modal.result.then(
            (status: any) => {
                this.tt.refreshTableData();
            }
        ).catch(
            () => {
            }
        );
    }
    public confirmMessage() {
        this.isPageLoading = true;
        const modal = this.modalService.open(ConfirmMessageComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.text = 'RETURNED_PACKAGES.DELIVER_TO_SENDER_MESSAGE';
        modal.componentInstance.isPrintReport = !this.isBundle;
        modal.result.then(
            (status: { isSuccess: boolean, isPrintReport: boolean }) => {
                if (status.isSuccess) {
                    if (status.isPrintReport) {
                        this.print(null, null,  this.getSelectedCustomerIdsAndBarcodes(), true);
                    } else {
                        this.deliverToSender();
                    }
                }
                this.isPageLoading = false;
            }
        ).catch(
            (error) => {
                this.isPageLoading = false;
            }
        );
    }
    public deliverToSender(body?) {
        this.isLoading = true;
        let query = '';
        if (this.partiallyDelivered) {
            query += `?isPartiallyDelivered=true`;
        } else if (this.selectedType) {
            query += `?type=${this.selectedType}`;
        }
        (this.isBundle ? this.shipmentsService.deliverCustomersReturnedBundle({ids: (body ? body : this.getSelectedCustomerIdsAndBarcodes())}, query) : this.shipmentsService.deliverCustomersReturnedPackages({ids: (body ? body : this.getSelectedCustomerIdsAndBarcodes())}, query)).subscribe(() => {
            this.translateService.get(
                'ALERTS.PACKAGES_DELIVERED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                            this.isLoading = false;
                            this.isAllSelected = false;
                            this.isThereSelection = false;
                            this.reset();
                        this.tt.refreshTableData();
                    }
                );
        }, () => {
            this.isLoading = false;
        });

    }
    public unloadPackages() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        if (role === 'HUB_MANAGER'||role==='MULTIPLE_HUBS_MANAGER') {
            this.router.navigate([this.authenticationService.companyName +
            'home/manage-shipments/unload-returned-packages']);
        } else {
            this.isPageLoading = true;
            const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
            modal.componentInstance.showDrivers = false;
            modal.result.then(
                (status: { isSuccess: boolean, barcode?: number, hubId?: number }) => {
                    if (status.isSuccess) {
                        if (status.hubId) {
                            this.router.navigate([this.authenticationService.companyName +
                                'home/returned-packages/unload-returned-packages'],
                                {queryParams: {'id': status.hubId}});
                        }
                    }
                    this.isPageLoading = false;
                }
            ).catch(
                (error) => {
                    this.isPageLoading = false;
                }
            );
        }
    }


    private initDatesFilterForm() {
        const date = new Date();
        const fromDate: Date = new Date(date.setFullYear(date.getFullYear(), date.getMonth() - 1, date.getDate()));
        const toDate = new Date();

        this.datesFilterForm = this.formsBuilder.group({
            searchText: [''],
            selectedShipmentsType: [{value: false}],
            fromDate: [fromDate],
            toDate: [toDate]
        });
    }
    public onDateSelected(value) {
        const startDate = new Date(value.start);
        const endDate = new Date(value.end);
        this.datesFilterForm.controls.fromDate.setValue(startDate);
        this.datesFilterForm.controls.toDate.setValue(endDate);
        this.datesFilterForm.controls.fromDate.updateValueAndValidity();
        this.datesFilterForm.controls.toDate.updateValueAndValidity();
        this.onSearch();
    }
    public receivePackagesFromDriverOrPartner(isFromPartner = false) {
        let className = 'receive-driver-and-read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'receive-driver-and-read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        if (isFromPartner) {
            modal.componentInstance.isReceiveFromPartner = true;
        } else {
            modal.componentInstance.isReceiverFromDriver = true;
        }
        modal.componentInstance.isReturnedWith = true;
        modal.componentInstance.isReturned = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {}
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    private getSelectedCustomers() {
        const selectedCustomer = [];
        if (!this.returnedPackages) {
            return;
        }

        this.returnedPackages.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedCustomer.push(pkg);
                }
            }
        );
        return selectedCustomer;
    }
    private getSelectedCustomerIds() {
        const selectedCustomer = [];
        if (!this.returnedPackages) {
            return;
        }

        this.returnedPackages.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedCustomer.push(pkg.customerId);
                }
            }
        );
        return selectedCustomer;
    }

    private getSelectedCustomerIdsAndBarcodes() {
        const selectedCustomer = this.getSelectedCustomers();
        if (!this.returnedPackages) {
            return;
        }
        if (this.isBundle) {
            return selectedCustomer.map(e => e.id);
        }
        return selectedCustomer.map(value => {
            return {
                id: value.customerId || value.id,
                barcode: (value.customerBarcode || value.barcode || value.massReturnedPackagesReportBarcode)
            };
        });
    }
    public pickWithDriver() {
        this.isPageLoading = true;
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.titleKey = 'ACCOUNTING.PICK_WITH_DRIVER';
        modal.componentInstance.showHubs = false;
        modal.componentInstance.isPickWithDriver = true;
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, driverId?: number}) => {
                if (status.isSuccess) {
                    const body = {};

                    if (this.isBundle) {
                        body['ids'] = this.getSelectedCustomers().map((pkg) => {
                            return pkg.id;
                        });
                    } else {
                        body['massReturnedPackagesReportData'] = this.getSelectedCustomers().map((pkg) => {
                            return {id: pkg.customerId, barcode: pkg.massReturnedPackagesReportBarcode};
                        });
                    }
                    let query = '';
                    if (this.partiallyDelivered) {
                        query += `?isPartiallyDelivered=true`;
                    } else if (this.selectedType !== 'ALL') {
                        query += `?type=${this.selectedType}`;
                    }
                    ((this.isBundle ? this.shipmentsService.unloadCustomersReturnedBundle(body, status.driverId, query) : this.shipmentsService.unloadCustomersReturnedPackages(body, status.driverId, query))).subscribe(
                        (response) => {
                            this.translateService.get(
                                'ALERTS.PICK_PACKAGES_BY_DRIVER'
                            )
                                .subscribe(
                                    (message) => {
                                        this.confirmationService.confirm({
                                            message: this.userService.getCurrency() === 'SAR' ?  message.replace('الرواجع', 'الرجيع') : message,
                                            rejectVisible: false,
                                            acceptLabel: 'ok'
                                        });
                                    }
                                );
                            this.isPageLoading = false;
                            this.reset();
                            this.tt.refreshTableData();
                        }, () => {
                            this.isPageLoading = false;
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                this.isPageLoading = false;
            }
        );
    }
    public onToggleSelection($event) {
        event.stopPropagation();
        const selectedCustomer = this.getSelectedCustomers();
        if (selectedCustomer) {
            this.isThereSelection = selectedCustomer.length !== 0;
            this.isAllSelected = selectedCustomer.length === this.returnedPackages.length;
        }
    }
    public onToggleSelectAll($event) {
        event.stopPropagation();
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }

        this.isThereSelection = false;
        this.returnedPackages.forEach((pkg: any) => {
            if (selected) {
                pkg.isSelected = selected;
                this.isThereSelection = true;

            } else {
                pkg.isSelected = selected;
            }
        });
    }

    setTypesList() {
        return RETURNED_TYPES_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    private initSelectedType() {
        this.hideStatusTypeFilter = false;

        const partiallyDelivered = !!this.route.snapshot.data['partiallyDelivered'];
        const brings = !!this.route.snapshot.data['brings'];
        const swapping = !!this.route.snapshot.data['swapping'];
        const allReturned = !!this.route.snapshot.data['allReturned'];
        this.isBundle = !!this.route.snapshot.data['bundle'];

        this.inCar = (this.route.snapshot.data['inCar'] === undefined
            ? false : this.route.snapshot.data['inCar']);
        this.deliverToSenderFlag = (this.route.snapshot.data['deliverToSender'] === undefined
            ? false : this.route.snapshot.data['deliverToSender']);
        if (partiallyDelivered) {
            this.hideDeliverToSenderBtn = false;
            this.receiveReturned = false;
            this.receivePackagesPartner = false;
            this.receivePackagesFromDrive = false;
            this.showPickWithDriver = true;
            this.partiallyDelivered = true;
            this.hideStatusTypeFilter = true;
            this.selectedType = 'PARTIALLY_DELIVERED';

        } else if (brings) {
            this.selectedType = 'PICKUP';
            this.hideDeliverToSenderBtn = false;
            this.receiveReturned = true;
            this.receivePackagesPartner = false;
            this.receivePackagesFromDrive = false;
            this.showPickWithDriver = true;
            this.hideStatusTypeFilter = true;
        } else if (swapping) {
            this.selectedType = 'EXCHANGE';
            this.hideDeliverToSenderBtn = false;
            this.receiveReturned = true;
            this.receivePackagesPartner = false;
            this.receivePackagesFromDrive = false;
            this.showPickWithDriver = true;
            this.hideStatusTypeFilter = true;
        } else if (allReturned) {
            this.hideDeliverToSenderBtn = true;
            this.receiveReturned = true;
            this.receivePackagesPartner = true;
            this.receivePackagesFromDrive = true;
            this.showPickWithDriver = false;        } else if (this.inCar) {
        } else if (this.deliverToSenderFlag) {
            this.hideDeliverToSenderBtn = false;
            this.showMoveActions = false;
            this.isBundle = this.userService.userInfo.isSupportReturnedBundles;
        } else if (this.inCar) {
            this.receiveReturned = true;
        } else {
            this.hideDeliverToSenderBtn = false;
            this.receiveReturned = false;
            this.receivePackagesPartner = false;
            this.receivePackagesFromDrive = false;
            this.showPickWithDriver = true;
            this.showReadByBarcode = true;
        }
    }

    downloadDeliveredToSender(type) {
        this.isExporting = true;
        let url = SHIPMENT_CONSTANTS.DELIVERED_TO_SENDER_DOWNLOAD_FILE;
        if (type === 'CSV') {
            this.isExportingExcel = true;
            url += 'v2/excel';
        } else if (type === 'PDF') {
            this.isExportingPdf = true;
            url += 'v2/pdf';
        }

        this.shipmentsService.downloadDeliveredToSender(url, this.getSelectedCustomerIdsAndBarcodes(),this.createParams()).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isExporting = false;
                this.isExportingExcel = false;
                this.isExportingPdf = false;
            },
            error => {
                this.isExporting = false;
                this.isExportingExcel = false;
                this.isExportingPdf = false;
                console.error(error);
            });
    }

    exportExcel() {
        this.confirmationService.confirm({
            message: this.translateService.instant('ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'),
            accept: () => {
                this.isDownloading = true;
                this.shipmentsService.getReturnedPackagesCustomersExcel(this.getSelectedCustomerIdsAndBarcodes(), this.createParams()).subscribe((response: any) => {
                    window.open(response.url, '_blank');
                    this.isDownloading = false;
                }, error => {
                    this.isDownloading = false;
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    public stopProp(event) {
        event.stopPropagation();
    }

    collect(event, row) {
        event.stopPropagation();
        event.preventDefault();
        this.confirmationService.confirm({
            message: this.translateService.instant('RETURNED_PACKAGES.COLLECT_BARCODE_MESSAGE'),
            accept: () => {
                this.collectAction(row);
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    collectAction(row) {
        let query = `?customerId=${row.customerId}`;
        if (this.inCar) {
            query += `&driverId=${row.driverId}`;
        } else {
            query += `&hubId=${row.hubId}`;
        }

        if (row.massReturnedPackagesReportBarcode) {
            query += `&barcode=${row.massReturnedPackagesReportBarcode}`;
        }

        if (this.partiallyDelivered) {
            query += `&isPartiallyDelivered=true`;
        } else if (this.selectedType) {
            query += `&type=${this.selectedType}`;
        }

        const body = {};

        this.shipmentsService.collectReturnedPackages(query, body, this.inCar).subscribe(
            res => {
                this.showSuccessMessage();
                this.reset();
                this.tt.refreshTableData();
            },
            err => {
                console.error(err);
            }
        );
    }

    public showSuccessMessage() {
        this.translateService.get('ALERTS.PACKAGE_COLLECTED_SUCCESSFULLY').subscribe((res: any) => {
            this.messageService.add({
                severity: 'success',
                detail: res
            });
        });
    }

    onDriverFilter(event) {
        this.driverId = event;
        this.reset();
        this.tt.refreshTableData();
    }

    setBarcodeFilter(filter: string) {
        this.selectedBarcodeFilter = filter;
        this.reset();
        this.tt.refreshTableData();
    }

    exportToPDF() {
        this.print(null, null,  this.getSelectedCustomerIdsAndBarcodes(), false);
    }

    protected readonly Companies = Companies;
}
