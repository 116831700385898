import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PackageModel} from '../../../shared/models/package.model';
import {ShipmentsService} from '../../services/shipments.service';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {UserService} from '../../../shared/services/user.service';


@Component({
    selector: 'app-shipment-status',
    templateUrl: './shipment-status.component.html',
    styleUrls: ['./shipment-status.component.scss']
})
export class ShipmentStatusComponent implements OnInit {

    @Input() shipment: PackageModel;
    @Input() isBundle: PackageModel;
    @Input() showText = false;
    @Input() public width = 160;
    @Input() isPartnerStatus = false;
    @Input() isEditEnabled = false;
    @Input() stopPropagation = true;
    @Input() propertyName = 'status';
    @Input() customPlacement = '';
    @Output() editStatus = new EventEmitter<any>();
    @ViewChild('changePackageStatusPopOver') changePackageStatusPopOver: NgbPopover;
    public isLcl = false;

    constructor(private shipmentService: ShipmentsService,
                private userService: UserService,
                private messageService: MessageService) {
    }

    ngOnInit() {
      this.isLcl = this.userService.userInfo.isLcl;
    }

    public getBackgroundColor(type) {
       if (type) {
           return this.shipmentService.getShipmentStatusColor(type.toUpperCase());
       }
    }

    onEditClick(event) {
        if (this.stopPropagation) {
            event.stopPropagation();
        }
    }

    public getStatus() {
        if (this.isPartnerStatus) {
            return this.shipment.partnerPackageStatus;
        } else {
            return this.shipment[this.propertyName];
        }
    }
    handlePopover(popover: NgbPopover) {
        if (popover.isOpen()) {
            popover.close();
        }
    }
}
