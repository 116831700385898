<app-field-set-form [label]="label"
                    [ngClass]="{'filter-autoComplete': isFilter}"
                    [filterActive]="(selectedItem && selectedItem !== defaultValue) || customizeDefaultValue "
                    [cssStyle]="cssStyle"
                    [isFilter]="isFilter"
                    [formControlObj]="formControlObj"
                    [showLabel]="showLabel">
  <p-autoComplete #autoCompleteComponent
                  [id]="id"
                  [(ngModel)]="selectedItem"
                  [suggestions]="suggestions"
                  [multiple]="multiple"
                  [field]="field"
                  [delay]="delay"
                  [dataKey]="dataKey"
                  [inputStyleClass]="inputStyleClass"
                  [disabled]="fieldDisabled"
                  [appendTo]="appendTo"
                  [forceSelection]="forceSelection"
                  (onClear)="clear()"
                  (completeMethod)="complete($event.query)"
                  (onSelect)="select($event)"
                  (onBlur)="isForm ? formControlObj.markAsTouched() : null"
                  [dropdown]="dropdown"
                  [completeOnFocus]="true"
                  [panelStyle]="{'width':'100%'}"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  [placeholder]="placeholder | translate">
    <ng-template *ngIf="selectedItemTemplate" let-dropdownItem pTemplate="selectedItem">
      <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: dropdownItem}">
      </ng-container>
    </ng-template>
    <ng-template *ngIf="itemTemplate" let-dropdownItem pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: dropdownItem}">
      </ng-container>
    </ng-template>
  </p-autoComplete>
</app-field-set-form>
