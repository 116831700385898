<div class="sms-gateway-settings-container">
    <div class="header">
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
        <div>
            {{('COMPANY.ADD_SMS_SETTINGS_BUTTON') | translate }}
        </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div class="col-12 driver-barcode-container">
                <div class="inputs row">
                    <div class="col-12">
                        <app-input-field-component [form]="form" class="col-6 col-lg-2 col-md-6"
                                                   controlName="userName"
                                                   label="AUTH.USERNAME"
                                                   controlType="text">
                        </app-input-field-component >
                    </div>
                    <div class="col-12">
                        <app-input-field-component [form]="form" class="col-6 col-lg-2 col-md-6"
                                                   controlName="sender"
                                                   label="COMPANY.SENDER"
                                                   controlType="text">
                        </app-input-field-component >
                    </div>
                    <div class="col-12">
                        <app-input-field-component [form]="form" class="col-6 col-lg-2 col-md-6"
                                                   controlName="session"
                                                   label="USERS.PASSWORD"
                                                   controlType="text">
                        </app-input-field-component >
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div style="padding: 10px 30px">
                <div class="add-button cursor-pointer"
                     (click)="setSMSGatewaySettings()"
                     [ngClass]="{'disabled-button disabled': !form.valid}">
                    <i class="far fa-save"></i>
                    {{'COMPANY.SET_GATEWAY_SETTINGS' | translate }}
                </div>
                <div class="reset-button cursor-pointer " (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
