<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <form #myForm="ngForm">
        <div class="main-container">
            <p-table [value]="packageContents" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.NAME' | translate}}</th>
                        <th>{{'MANAGE_SHIPMENTS.COST' | translate}}</th>
                        <th style="width: 80px"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-content let-index='rowIndex' let-isLast='last'>
                    <tr>
                        <td>
                            <input pInputText required [name]="'name_'+index"
                                   [(ngModel)]="content.name">
                        </td>

                        <td>
                            <input pInputText type="number" required [name]="'cost_'+index"
                                   [(ngModel)]="content.cod">
                        </td>
                        <td style="width: 80px">
                            <div class="sku-list-actions-container cursor-pointer">
                                <div class="sku-list-action delete-subform-action"
                                     (click)="deleteItem(index, content)">
                                    <i class="fas fa-trash"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-content>
                    <tr>
                        <td colspan="3">
                            <button class="add-content-btn" type="button" (click)="addPackageContent()"><i
                                    class="fas fa-plus"></i></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </form>
</div>
