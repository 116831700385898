import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../shared/services/user.service';
import {UserModel} from '../shared/models/user.model';
import {PasswordValidation} from '../shared/behavior/password-match.validator';
import {Router} from '@angular/router';
import {AccountService} from './services/account.service';
import {MessageService} from 'primeng/api';
import {AuthenticationService} from '../shared/services/authentication.service';

@Component({
    selector: 'app-account-component',
    templateUrl: 'account.component.html',
    styleUrls: ['account.component.scss']
})
export class AccountComponent implements OnInit {
    public isLoading = true;
    public user: UserModel;
    public form: FormGroup;
    // 0 => nothing, 1 => error, 2 => error
    public messageStatus: 0 | 1 | 2 = 0;
    public message = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
        private accountService: AccountService,
        private messageService: MessageService,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.initUser();
    }

    public initUser() {
        this.userService.getUserInfo().then(
            (info: UserModel) => {
                this.user = info;
                this.isLoading = false;
                this.initForm();
            }
        );
    }

    public initForm() {
        this.form = this.formBuilder.group({
            firstName: [this.user.firstName, Validators.required],
            lastName: [this.user.lastName, Validators.required],
            middleName: [this.user.middleName],
            phone: [this.user.phone, Validators.compose([Validators.required,
               this.userService.checkPhonePattern.bind(this.userService.getPhonePattern()),
                Validators.maxLength(this.userService.userInfo.maxPhoneNumberValidationDigits),
                Validators.minLength(this.userService.userInfo.minPhoneNumberValidationDigits),
            ])],
            email: [{value: this.user.email, disabled: true}, Validators.compose([
                Validators.required,
                Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')])],
            oldPassword: ['', Validators.required],
            password: ['', Validators.compose([Validators.required, Validators.pattern(/((?=.*[A-Za-z])(?=.*[0-9])(?=.{6,}))/)])],
            confirmPassword: ['', Validators.required],
        }, {
            validator: PasswordValidation.MatchPassword
        });
    }

    public onSaveChanges() {
        if (!this.form.valid) {
            return;
        }
        this.isLoading = true;

        const data = this.form.getRawValue();
        const reqBody = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            phone: data.phone,
            password: data.password,
            oldPassword: data.oldPassword
        };
        this.userService.changeUserInfo(reqBody).subscribe(
            (response) => {
                this.messageStatus = 2;
                this.userService.userInfo.firstName = data.firstName;
                this.userService.userInfo.middleName = data.middleName;
                this.userService.userInfo.lastName = data.lastName;
                this.message = 'Information Changed Successfully';
                this.isLoading = false;
            }, (error) => {
                this.messageStatus = 1;
                this.message = error.error.error;
                this.isLoading = false;
            }
        );
    }

    public onCancelEdit() {
        this.router.navigate([this.authenticationService.companyName + '/home/']);
    }

    public addimage(filechoser) {
        filechoser.click();
    }

    public onFileSelected($event: any) {
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        if (!selectedFile) {
            return;
        }

        formData.append('file', selectedFile);
        if (selectedFile.name.toLowerCase().match(/.(jpg|jpeg|png|gif)$/i)) {
            this.accountService.updateImage(formData).subscribe((res: any) => {
                this.userService.userInfo.imageUrl = res.fileUrl;
            }, error => {
                console.log(error);
            });
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
        }
    }
}
