export const COMPANY_NOTIFICATIONS_CONSTANT = {
    CHANGE_MODEM_STATUS: ':api/sms-modems/manager/modems/{modem-id}/status',
    GET_NOTIFICATIONS_EVENTS: ':api/admin/companies/notification-settings',
    UPDATE_EVENT: ':api/admin/companies/notification-settings/{settingId}/template',
    GET_SITE_TAGS: ':api/admin/companies/notification/template-tags',
    SMS_GATEWAY_SETTINGS: ':api/admin/companies/sms-gateway/',
    GET_SMS_TEMPLATE: ':api/admin/companies/notification/template-by-event',
    SEND_SMS_TO_RECEIVER_OF_DRIVER_PACKAGES: ':api/admin/sms-gateway/drivers/{driverId}/send',
    SEND_CUSTOMERS_SMS: ':api/admin/sms-gateway/customers/send',
    GET_SMS_BALANCE: ':api/admin/companies/sms-gateway/balance',
    GET_SMS_GATEWAY_CURRENT_SETTINGS: ':api/admin/companies/sms-gateway'
};
