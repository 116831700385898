export const Companies = {
    EPS: 4,
    LOGESTECHS: 1,
    SPRINT: 240,
    JETT: 236,
    SAR: 22, // this is ID for Mohanad, we need to create new site and change it to new ID.
    MOHANAD: 22,
    SEND: 10000000, // we need this changes in Production only
    DRB: 251, // we need this changes in Production only
    SEND_GET: 154,
    FAST_ACCESS: 1000000000,
    WHEEL: 143,
    PLANEX_JO: 157,
    ABU_ALTAWSEEL: 158,
    BLINK: 10000000000,
    RAWABI_BISAN: 241,
    BatumiExpress: 100000000000,
    NAWEL: 100000000000,
    W_DELIVERY: 100000000000,
    PAL_POST: 100000000000,
    SPEED_LIGHT_DELIVERY: 64,
    THE_TRANSPORTER_LOGISTICS: 100000000000
};
