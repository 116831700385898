<p-cellEditor *ngIf="isEdit">
    <ng-template pTemplate="input">
        <input type="number" [(ngModel)]="package[attribute]" (ngModelChange)="editCost(package)" />
    </ng-template>
    <ng-template pTemplate="output">
        <div class="d-flex flex-row cost-container" style="justify-content: center;position:relative;max-width: 156px !important;">
            <div class="editable-cost">
                <div class="plus-icon" [ngClass]="{'plus-icon-en' : currentLang === 'en', 'plus': companyId!=4}" (click)="updatePackageCost($event, package, +1)">
                    <i class="fas fa-plus"></i>
                </div>
                <div class="minus-icon" [ngClass]="{'minus-icon-en': currentLang === 'en', 'minus': companyId!=4}" (click)="updatePackageCost($event, package, -1)">
                    <i class="fas fa-minus"></i>
                </div>
                <input type="number" value="{{hideZeroValue ? '' : package[attribute]}}" placeholder="{{'GENERAL.SET_COST_PLACE_HOLDER' | translate}}" />
            </div>
        </div>
    </ng-template>
</p-cellEditor>
<span *ngIf="!isEdit" class="cod-data">{{hideZeroValue ? '' : package[attribute] | currencyValue : package.currency}}</span>

