<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="add-update-dialog-header d-flex justify-content-between align-items-center">
        <div class="title">
            {{(isEdit ? 'SYSTEM_UPDATES.ADD_SYSTEM_UPDATE' : 'SYSTEM_UPDATES.EDIT_SYSTEM_UPDATE') | translate}}
        </div>

        <div class="close-icon" (click)="close()">
            <img src="../../../assets/icons/close-x.svg" alt=""/>
        </div>
    </div>
    <div class="flex-1 input-fields add-update-body">
        <div class="h-100 d-flex flex-column">
            <form *ngIf="form" class="add-user-form row" [formGroup]="form">
            <ng-container *ngIf="currentForm === 'ARABIC'">
                <div class="row input-row w-100 mb-5">
                    <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
                        <div class="label">{{'SYSTEM_UPDATES.ADD_UPDATE_ARABIC_NAME' | translate}}</div>
                        <div class="input-value">
                            <input class="w-100" type="text" pInputText formControlName="arabicName"/>
                        </div>
                        <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('arabicName')">
                            {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                        </div>
                    </div>
                </div>

                <div class="row input-row w-100 mb-5" *ngIf="type =='VIDEO'">
                    <div class="no-padding col-lg-12 col-sm-12 category-dropdown input-filed-container">
                        <div class="label">{{'SYSTEM_UPDATES.ADD_UPDATE_CATEGORY' | translate}}</div>
                        <div class="input-value w-100 d-flex">

                            <p-dropdown
                                    [options]="categoriesList"
                                    class="w-100"
                                    [showClear]="categoryModel && categoryModel != 'WITHOUT'"
                                    [(ngModel)]="categoryModel"
                                    formControlName="category">
                                <ng-template pTemplate="item" let-item>
                                    <div style="height: 20px">{{'SYSTEM_UPDATES.DROPDOWN.'+item | translate}}</div>
                                </ng-template>
                                <ng-template let-selectedItem pTemplate="selectedItem">
                                    {{'SYSTEM_UPDATES.DROPDOWN.'+selectedItem | translate}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>

                <div class="row input-row w-100 mb-5">
                    <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
                        <div class="label">{{'SYSTEM_UPDATES.ARABIC_UPDATE_SUMMARY' | translate}}</div>
                        <div class="input-value">
                            <textarea class="w-100" [rows]="5" [cols]="30" pInputTextarea formControlName="arabicSummary" autoResize="true"></textarea>
                        </div>
                        <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('arabicSummary')">
                            {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                        </div>
                    </div>
                </div>

                <div class="row input-row w-100 mb-5">
                    <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
<!--                        <ng-container *ngIf="type =='PDF'">-->
                        <div class="middle">
                            <button pButton type="button" [label]="(type =='PDF' ? 'SYSTEM_UPDATES.UPLOAD_FILE' : 'SYSTEM_UPDATES.PREVIEW_IMAGES') | translate"
                                    (click)="fileChooser.click()"></button>
                            {{arabicFileName}}
                        </div>
                            <input type="file" accept="{{type === 'PDF'? 'application/pdf' : 'image/*'}}" style="display: none" #fileChooser
                                   (change)="onFilesSelected($event)">
                            <ng-container *ngIf="arabicFileTouched">
                                <div class="invalid-message" *ngIf="!arabicFileName">
                                    {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                                </div>
                                <div class="invalid-message" *ngIf="arabicFilesizeLimitExceeded">
                                    {{'ALERTS.UPLOAD_MAX_SIZE' | translate:{max: 10} }}
                                </div>
                            </ng-container>
<!--                        </ng-container>-->

                        <ng-container *ngIf="type =='VIDEO'">
                            <div class="label pt-3">{{'SYSTEM_UPDATES.ADD_UPDATE_ARABIC_VIDEO_LINK' | translate}}</div>
                            <div class="input-value">
                                <input class="w-100" type="text" pInputText formControlName="arabicVideoLink"/>
                            </div>
                            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('arabicVideoLink')">
                                {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

                <ng-container *ngIf="currentForm === 'ENGLISH'">
                    <div class="row input-row w-100 mb-5">
                        <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
                            <div class="label">{{'SYSTEM_UPDATES.ADD_UPDATE_NAME' | translate}}</div>
                            <div class="input-value">
                                <input class="w-100" type="text" pInputText formControlName="name"/>
                            </div>
                            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('name')">
                                {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="row input-row w-100 mb-5">
                        <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
                            <div class="label">{{'SYSTEM_UPDATES.ADD_UPDATE_SUMMARY' | translate}}</div>
                            <div class="input-value">
                                <textarea class="w-100" [rows]="5" [cols]="30" pInputTextarea formControlName="summary" autoResize="true"></textarea>
                            </div>
                            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('summary')">
                                {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="row input-row w-100 mb-5">
                        <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
<!--                            <ng-container *ngIf="type =='PDF'">-->

                                <div class="middle">
                                    <button pButton type="button" [label]="(type =='PDF' ? 'SYSTEM_UPDATES.UPLOAD_FILE' : 'SYSTEM_UPDATES.PREVIEW_IMAGES') | translate"
                                            (click)="fileChooser.click()"></button>
                                    {{fileName}}
                                </div>
                                <input type="file" accept="{{type === 'PDF'? 'application/pdf' : 'image/*'}}" style="display: none" #fileChooser
                                       (change)="onFilesSelected($event)">
                                <ng-container *ngIf="fileTouched">
                                    <div class="invalid-message" *ngIf="!fileName">
                                        {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                                    </div>
                                    <div class="invalid-message" *ngIf="sizeLimitExceeded">
                                        {{'ALERTS.UPLOAD_MAX_SIZE' | translate:{max: 10} }}
                                    </div>

                                </ng-container>
<!--                            </ng-container>-->
                            <ng-container *ngIf="type =='VIDEO'">
                                <div class="label pt-3">{{'SYSTEM_UPDATES.ADD_UPDATE_VIDEO_LINK' | translate}}</div>
                                <div class="input-value">
                                    <input class="w-100" type="text" pInputText formControlName="videoLink"/>
                                </div>
                                <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('videoLink')">
                                    {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

            </form>
        </div>
    </div>
    <div class="row actions action-container" style="width: 100%;">
        <div class="d-flex">
            <div *ngIf="currentForm === 'ENGLISH' && !isLoading" class="cursor-pointer add-update-button done" (click)="currentForm = 'ARABIC'">
                <span>{{'ACTIONS.PREVIOUS' | translate}}</span>
            </div>
            <div class="cancel cursor-pointer" *ngIf="!isLoading" (click)="close()">
                <span>{{'ACTIONS.CANCEL' | translate}}</span>
            </div>
            <div *ngIf="isLoading" class="loading">
                <span><i class="fas fa-spinner fa-pulse"></i></span>
            </div>
            <div *ngIf="currentForm === 'ARABIC' && !isLoading" class="cursor-pointer add-update-button done" (click)="currentForm = 'ENGLISH'">
                <span>{{'ACTIONS.NEXT' | translate}}</span>
            </div>
            <div *ngIf="!isLoading && currentForm === 'ENGLISH'" class="cursor-pointer add-update-button done" (click)="addUpdate()"
                 [ngClass]="{'disabled-btn disabled': !form?.valid || (type == 'PDF'&&(!fileName || !arabicFileName || sizeLimitExceeded || arabicFilesizeLimitExceeded))}">
                <span *ngIf="!isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</span>
                <span *ngIf="isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</span>
            </div>
        </div>
    </div>
</div>
