import {Component, Inject, Input, OnInit} from '@angular/core';
import {SHIPMENT_CONSTANTS} from '../services/constants';
import {ShipmentsService} from '../services/shipments.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
    public form: FormGroup;
    public isLoading = false;
    @Input() showPDF ;
    @Input() showCSV;
    constructor(private shipmentsService: ShipmentsService,
                private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.initInfo();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            type: ['', Validators.required]
        });

        if (this.showPDF) {
            this.form.get('type').setValue('PDF');
        } else if (this.showCSV) {
            this.form.get('type').setValue('CSV');
        }

    }

    public exportExcel() {
        const type = this.form.value;
        this.activeModal.close(type);
    }

    public closeModal() {
        this.activeModal.close({type: ''});
    }

}
