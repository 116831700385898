<p-toast [baseZIndex]="toastZIndex"></p-toast>

<div class="main-container">
    <div *ngIf="paymentStatus !== PaymentStatus.SUCCESSFUL" class="header">
        <div class="modal-title">
            {{ 'PAYMENT.MAKE_PAYMENT' | translate }}
        </div>
        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../../assets/icons/cancel.svg" alt="icon" />
        </div>
    </div>
    <div class="loading-overlay" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="info-container" [formGroup]="form" *ngIf="paymentStatus !== PaymentStatus.SUCCESSFUL">
        <div class="start-side">
            <div class="field">
                <label>
                    {{ 'PAYMENT.AMOUNT' | translate }}
                </label>
                <input formControlName="amount" type="number" pInputText>
            </div>

            <div class="field">
                <label>
                    {{ 'PAYMENT.CURRENCY' | translate }}
                </label>
                <ng-select formControlName="currency"
                           [disabled]="true"
                           [searchable]="false"
                           [items]="currencies">
                </ng-select>
            </div>

            <app-payment-card-information [clientSecret]="clientSecret" formControlName="cardElement"></app-payment-card-information>

            <div *ngIf="paymentStatus === PaymentStatus.FAILED" class="payment-error">
                <i class="fas fa-exclamation-circle"></i>
                {{ errorMessage | translate }}
            </div>
        </div>
    </div>
    <app-successful-payment *ngIf="paymentStatus === PaymentStatus.SUCCESSFUL"></app-successful-payment>
    <div class="bottom-actions">
        <div *ngIf="paymentStatus !== PaymentStatus.SUCCESSFUL" class="add-button" [ngClass]="{'disabled-btn disabled': form.invalid || isLoading}" (click)="onPay()">
            {{ 'ACTIONS.PAY' | translate }}
        </div>
        <div *ngIf="paymentStatus === PaymentStatus.SUCCESSFUL" class="add-button" (click)="closeModal()">
            {{ 'ACTIONS.CLOSE' | translate }}
        </div>
    </div>
</div>
