import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShipmentsService} from '../../services/shipments.service';
import {UsersService} from '../../../users/services/users.service';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

interface DropDownResponse {
  label: string;
  value: number;
}
@Component({
  selector: 'app-expenses-add',
  templateUrl: './expenses-add.component.html',
  styleUrls: ['./expenses-add.component.scss']
})
export class ExpensesAddComponent implements OnInit {

  public form: FormGroup;
  public userType: DropDownResponse[] = [];
  public selectedReceiverFilter = '';
  public expensesType: DropDownResponse[] = [];
  public isEditMode = false;
  public expenses;
  public currentLang;
  public addedExpenseType = false;

  constructor(private activeModal: NgbActiveModal,
              private translateService: TranslateService,
              private shipmentsService: ShipmentsService,
              private datePipe: DatePipe,
              private messageService: MessageService,
              private usersService: UsersService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.initUserType();
    this.initInfo();
    this.initExpensesType();
  }
  public closeModal() {
    this.activeModal.close();
  }
  public initInfo() {
    let info = <any>{
      holderId: '',
      moneyAmount: '',
      notes: '',
      expenseTypeId: '',
      holderName2 : null
    };
    if (this.isEditMode) {
      info = <any>{
        holderId: {label: this.expenses.holderName, value: this.expenses.holderId},
        moneyAmount: this.expenses.moneyAmount,
        notes: this.expenses.notes,
        expenseTypeId: {label: this.expenses.expenseTypeName, value: this.expenses.expenseTypeId},
        holderName2: this.expenses.holderName2
      };
    }
    this.form = this.formBuilder.group({
      holderId: ['', Validators.required],
      moneyAmount: ['', Validators.required],
      notes: [''],
      expenseTypeId: ['', Validators.required]
    });
    this.form.patchValue(info);
  }
  private transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  public choose() {
    const body = this.form.getRawValue();
    if (this.isEditMode) {
      const req = {
        holderId: body.holderId.value === undefined ? null : body.holderId.value,
        holderName2: body.holderId.value === undefined ? body.holderId : null,
        expenseTypeId: body.expenseTypeId.value === undefined ? body.expenseTypeId : body.expenseTypeId.value,
        accountantId: this.expenses.accountantId,
        notes: body.notes,
        moneyAmount: body.moneyAmount
      };
      this.shipmentsService.putExpenses(req, this.expenses.id).subscribe(response => {
        this.activeModal.close({isSuccess: true});
      }, (error) => {
        this.activeModal.close({isSuccess: false});
      });
      return;
    }
    this.activeModal.close({data: body});
  }

  initExpensesType(query = '') {
    this.shipmentsService.getExpensesType(query).subscribe((response: any) => {
      this.expensesType = response.map(
          (res) => {
            return {label: res.name, value: res.id};
          }
      );
    });
  }
  public getExpensesTypes(data , source = null) {
    const query = '?page=1&pageSize=100&search=' + data.element.inputEL.nativeElement.value;
    this.initExpensesType(query);
  }
  public getUserType(data) {
    const query = '?page=1&pageSize=100&search=' + data.query;
    this.initUserType(query);
  }
  initUserType(query = '') {
    if (query) {
      query += '&driverType=TYPICAL';
    } else {
      query += '?driverType=TYPICAL';
    }
    this.usersService.getUserDropDown(query).subscribe((response: any) => {
      this.userType = response.map(
          (res) => {
            return {label: res.name, value: res.id};
          }
      );
    });
  }

  public deleteExpenseType(data) {
    data.event.stopPropagation();
    if (data.expense.value) {
      this.shipmentsService.deleteExpensesTypes(data.expense.value).subscribe(
          (response: any) => {
            this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.EXPENSE_TYPE_DELETED_SUCCESSFULLY')});
            this.getExpensesTypes(data , 'delete');
          }
      );
    }
  }
}
