<p-toast  baseZIndex="1000"></p-toast>
<div class="returned-packages-view d-flex flex-column h-100">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner> </p-progressSpinner>
    </div>

    <div class="header-row-container">
        <div class="d-flex flex-row returned-packages-header">
            <div class="number-of-customers d-flex">
                <div class="label" *ngIf="!isBundle">
                    {{ 'ACCOUNTING.TOTAL_CUSTOMERS' | translate }} /
                    {{ 'ACCOUNTING.REPORTS' | translate }}
                </div>

                <div class="label" *ngIf="isBundle">
                    {{ 'ACCOUNTING.TOTAL_BUNDLES' | translate }}
                </div>
                <div style="width: 5px;"></div>
                <div class="badge-number" *ngIf="!isBundle">
                    <span>{{numberOfCustomers}} / {{totalRecords}}</span>
                </div>
                <div class="badge-number" *ngIf="isBundle">
                    <span>{{totalRecords}}</span>
                </div>
            </div>
            <div class="header-filters d-flex flex-row" style="flex-wrap: wrap; align-items: center;">
                <div *ngIf="deliverToSenderFlag && !this.isBundle" class="export-archived-btn cursor-pointer header-filter col-xs-6 export-button"
                     [ngClass]="{'disabled-btn2': isExporting}" (click)="downloadDeliveredToSender('PDF')">
                    <div class="print-icon">
                        <img *ngIf="!isExportingPdf" src="../../assets/icons/containers-management/print.svg" alt="" />
                        <i *ngIf="isExportingPdf" class="fas fa-spinner fa-pulse"></i>
                    </div>
                    <div style="width: 12px"></div>
                    <div class="sub-label d-flex" style="justify-content:center; align-items: center" align="center">
                        {{ 'ACTIONS.PRINT' | translate }}
                    </div>
                </div>
                <div class="hidden-sm-down" *ngIf="!inCar && !deliverToSenderFlag">
                    <div class="toggle-show-actions d-flex">
                        <div class="label">{{ 'MANAGE_SHIPMENTS.TOOLBAR' | translate }}</div>
                        <div style="width: 12px;"></div>
                        <div class="show-actions-switch">
                            <p-inputSwitch [(ngModel)]="showActions"></p-inputSwitch>
                        </div>
                    </div>
                </div>

               <div class="date-and-customer">
                   <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                   <div class="customer-filter d-flex">
                       <app-users-list-dropdown-component [isDriver]="false"
                                                          [isHub]="false"
                                                          [isPartner]="false"
                                                          [placeHolder]="'RETURNED_PACKAGES.CUSTOMER_NAME'"
                                                          (userChanged)="onCustomerFilterChange($event)"
                                                          [panelWidth]="120">
                       </app-users-list-dropdown-component>
                   </div>
                   <div *ngIf="inCar" class="customer-filter d-flex">
                       <app-users-list-dropdown-component [isDriver]="true"
                                                          [isHub]="false"
                                                          [isPartner]="false"
                                                          [placeHolder]="'RETURNED_PACKAGES.DRIVER_NAME'"
                                                          (userChanged)="onDriverFilter($event)"
                                                          [panelWidth]="120">
                       </app-users-list-dropdown-component>
                   </div>
               </div>
                <div class="types-list-container" *ngIf="!hideStatusTypeFilter">
                    <p-dropdown styleClass="date-filter-dropdown"
                                [(ngModel)]="selectedType"
                                [options]="typesList" filter="true"
                                (onChange)="onSearch()">
                    </p-dropdown>
                </div>

                <div class="search-container">
                    <i class="fas fa-search"></i>
                    <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                           [(ngModel)]="searchContent"
                           appInputDebounced
                           (inputDebounced)="onSearch()">
                </div>

                <div *ngIf="(deliverToSenderFlag || inCar )&& !this.isBundle" class="action-container d-flex">
                <div class="excel-button m-auto"
                         [ngClass]="{'disabled-btn disabled': isDownloading}"
                         (click)="downloadDeliveredToSender('CSV')">
                        <i class="fas fa-file-excel"></i>
                        {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hidden-sm-down" *ngIf="showActions && !inCar">
        <div *ngIf="showMoveActions" class="d-flex flex-wrap actions-container">
            <div class="action-container" *ngIf="receiveReturned">
                <div class="gradient-action action" (click)="receiveReturnedPackages()">
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RETURNED_PACKAGES' | translate}}</div>
                </div>
            </div>
            <div class="action-container" *ngIf="showReadByBarcode && !isBundle" (click)="readByBarcode()">
                <div class="gradient-action action">
                    <div class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.READ_BY_BARCODE' | translate}}</div>
                </div>
            </div>

            <div class="actions-splitter" *ngIf="(receiveReturned || showReadByBarcode) && !isBundle"></div>
            <div class="action-container" *ngIf="receivePackagesFromDrive">
                <div class="secondary-action-btn action" (click)="receivePackagesFromDriverOrPartner()">
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.FROM_DRIVER' | translate}}</div>
                </div>
            </div>
            <div class="action-container" *ngIf="receivePackagesPartner">
                <div class="secondary-action-btn action" (click)="receivePackagesFromDriverOrPartner(true)">
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.RECEIVE' | translate}}</div>
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.FROM_PARTNER' | translate}}</div>
                </div>
            </div>
            <div class="action-container" *ngIf="showPickWithDriver">
                <div class="secondary-action-btn action"
                     [ngClass]="{'disabled-btn': !isThereSelection || isPageLoading}"
                     (click)="pickWithDriver()">
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.PICK_RETURNED_PACKAGES' | translate}}</div>
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.ACTIONS.WITH_DRIVER' | translate}}</div>
                </div>
            </div>
            <div class="action-container" *ngIf="!hideDeliverToSenderBtn">
                <div class="secondary-action-btn action" (click)="confirmMessage()" [ngClass]="{'disabled-btn': !isThereSelection || isPageLoading}">
                    <div class="sub-label" align="center">{{'RETURNED_PACKAGES.DELIVER_TO_SENDER' | translate}}</div>
                </div>
            </div>
            <div class="action-container d-flex" *ngIf="!isBundle">
                <div class="excel-button m-auto"
                     [ngClass]="{'disabled-btn disabled': isDownloading}"
                     (click)="exportExcel()">
                    <i class="fas fa-file-excel"></i>
                    {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                </div>
            </div>
            <div class="action-container d-flex"  *ngIf="!isBundle">
                <div class="pdf-button m-auto"
                     [ngClass]="{'disabled-btn disabled': !isThereSelection}"
                     (click)="exportToPDF()">
                    <i class="fas fa-file-pdf"></i>
                    {{"ACTIONS.EXPORT_TO_PDF" | translate}}
                </div>
            </div>
        </div>

    </div>
    <div class="cod-mismatch-table flex-1 overflow-auto" #tableContainer>
        <app-generic-table [columns]="columnsDef" [config]="tableConfig" [externalFilter]="filterParams" #tt
                           [hidePagination]="false" [hideNoData]="true"
                           [customTemplates]="{checkbox, top, barcodeFilter, exportAction, printAction, collectAction, status}">
            <ng-template #top>
                <div class="select-shipment-checkbox">
                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                (onChange)="onToggleSelectAll($event)"></p-checkbox>
                </div>
            </ng-template>
            <ng-template #checkbox let-package>
                <div class="select-shipment-checkbox">
                    <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                (onChange)="onToggleSelection($event)"></p-checkbox>
                </div>
            </ng-template>
            <ng-template #barcodeFilter>
                <div class="barcode-filter" *ngIf="!isBundle">
                    <div class="label">{{'GENERAL.BARCODE' | translate }}</div>
                    <div class="values">
                        <div class="item"
                             (click)="setBarcodeFilter('ALL')"
                             [ngClass]="{'active-item': selectedBarcodeFilter === 'ALL', 'b-radius-right': selectedLanguage === 'ar' , 'b-radius-left': selectedLanguage === 'en'}">{{'ALL' | translate}}</div>
                        <div class="item flex-2d-center"
                             (click)="setBarcodeFilter('COLLECTED')"
                             [ngClass]="{'active-item': selectedBarcodeFilter === 'COLLECTED'}">{{'WITH' | translate}}</div>
                        <div class="item"
                             (click)="setBarcodeFilter('UNCOLLECTED')"
                             [ngClass]="{'active-item': selectedBarcodeFilter === 'UNCOLLECTED', 'b-radius-left': selectedLanguage === 'ar' , 'b-radius-right': selectedLanguage === 'en'}">{{'WITHOUT' | translate}}</div>
                    </div>
                </div>
                <ng-container *ngIf="isBundle">{{'GENERAL.BARCODE' | translate }}</ng-container>
            </ng-template>

            <ng-template #exportAction let-pkg>
                <div class="table-row-actions-container" (click)="$event.stopPropagation()">
                    <div class="edit-action action cursor-pointer" (click)="export(pkg,$event)">
                        <i class="fas fa-file-excel"></i>
                    </div>
                </div>
            </ng-template>
            <ng-template #printAction let-pkg>
                <div class="table-row-actions-container" (click)="$event.stopPropagation()">
                    <div class="edit-action action cursor-pointer"
                         (click)="print(pkg,$event, [{id: pkg.customerId, barcode: (pkg.customerBarcode || pkg.barcode || pkg.massReturnedPackagesReportBarcode)}])">
                        <i class="fas fa-print"></i>
                    </div>
                </div>
            </ng-template>
            <ng-template #collectAction let-pkg>
                <div class="table-row-actions-container" (click)="$event.stopPropagation()">
                    <div class="edit-action action cursor-pointer"
                         [ngClass]="{'disabled-btn': pkg.massReturnedPackagesReportBarcode}"
                         (click)="collect($event, pkg)"
                         pTooltip="{{'RETURNED_PACKAGES.COLLECTION_TOOLTIP' | translate }}"
                         tooltipPosition="top">
                        <i class="fas fa-window-restore"></i>
                    </div>
                </div>
            </ng-template>

            <ng-template #status let-package>
                <app-shipment-status [isEditEnabled]="false" [shipment]="package" [stopPropagation]='false'
                                     [isBundle]="true"></app-shipment-status>
                <div *ngIf="package.driverName">
                    <div *ngIf="package.driverName" class="pt-2">
                        {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                    </div>
                </div>
            </ng-template>

        </app-generic-table>
    </div>
</div>
