import {Component, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {SharedService} from '../../../shared/services/shared-service';
import {UsersService} from '../../../users/services/users.service';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UserService} from "../../../shared/services/user.service";
import * as moment from "moment";
import {DriverEarningsDetailsDialogComponent} from "./driver-earnings-details-dialog/driver-earnings-details-dialog.component";
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'app-drivers-earnings-report',
    templateUrl: './drivers-earnings-report.component.html',
    styleUrls: ['./drivers-earnings-report.component.scss']
})
export class DriversEarningsReportComponent implements OnInit {
    selectedDriverId: number;
    codTypes;
    dateRangPickerOptions: any = {};
    fromDate;
    toDate;
    search;
    isLoading = false;
    reportData: any[] = [];
    pageNumber = 0;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    totalRecords = 0;
    first = 0;
    isAllSelected = false;
    @ViewChild('table') table;
    @ViewChild('datepicker') datepicker;
    @ViewChild('userDropdown') userDropdown;
    isDownloading = false;
    createdByFilter = '';
    preRequest;

    constructor(private translate: TranslateService,
                private sharedService: SharedService,
                private datePipe: DatePipe,
                private shipmentsService: ShipmentsService,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private usersService: UsersService,
                private userService: UserService,
                private route: ActivatedRoute,
                private confirmationService: ConfirmationService,
                private dateRangePickerService: DateRangePickerService) {
    }

    ngOnInit() {
        this.setDatesFilters();
        this.setDateRangePickerOptions();
    }

    setDatesFilters() {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = this.transformDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()));
            this.toDate = this.transformDate(moment());
        }
    }

    initReport() {
        this.isLoading = true;
        if (this.preRequest) {
            this.preRequest.unsubscribe();
        }
        this.preRequest = (this.createdByFilter === 'MISMATCH' ? this.usersService.getDriversEarningsReportMismatch(this.createParams()) : this.usersService.getDriversEarningsReport(this.createParams())).subscribe(
            (res: any) => {
                this.reportData = res.data;
                this.totalRecords = res.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    onFilter() {
        this.pageNumber = 0;
        this.reportData = [];
        this.table.reset();
    }

    onSelectDriver(id) {
        this.selectedDriverId = (id === undefined ? '' : id);
        this.onFilter();
    }

    setDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions(0, true, true, false, true, false, 'MONTH').then(options => {
            this.dateRangPickerOptions = options;
            this.fromDate = this.transformDate(this.dateRangPickerOptions.startDate);
            this.toDate = this.transformDate(this.dateRangPickerOptions.endDate);
            setTimeout(() => {
                this.datepicker.nativeElement.value = this.translate.instant('GENERAL.DATE_RANGE_PICKER.BEFORE_1_MONTH');
            }, 30);

        });
    }

    onDateSelected(value) {
        const {start, end} = value;
        this.fromDate = new Date(start);
        this.toDate = new Date(end);
        this.onFilter();
        if ((start && end) || value.label) {
            if (value.label) {
                if (value.label === this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    createParams() {
        let params: any = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            pageSize: this.pageSize,
            page: (this.pageNumber + 1)
        };
        if (this.fromDate && this.toDate) {
            params = {
                ...params,
                fromDate: this.transformDate(this.fromDate),
                toDate: this.transformDate(this.toDate)
            };
        }
        if (this.search) {
            params['search'] = this.search;
        }
        if (this.selectedDriverId) {
            params['driverId'] = this.selectedDriverId;
        }

        params['driverType'] = 'TYPICAL';
        return params;
    }

    loadReportsLazy(event) {
        this.pageNumber = event.first / event.rows;
        this.pageSize = event.rows;
        this.first = event.first;
        this.initReport();
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }
        this.reportData.forEach((pkg: any) => {
            pkg.isSelected = selected;
        });
    }

    getSelectedPackages() {
        return this.reportData.filter(report => (report.isSelected));
    }
    openDriverEarningsDetails(report) {
        const modal = this.modalService.open(DriverEarningsDetailsDialogComponent,
            {
                backdrop: 'static',
                size: 'lg',
                windowClass: 'driver-check-in-list'
            });
        modal.componentInstance.driverId = this.createdByFilter === 'MISMATCH' ? report.id : report.driverId;
        modal.componentInstance.isMismatch = this.createdByFilter === 'MISMATCH';
        modal.componentInstance.fromDate = this.fromDate;
        modal.componentInstance.toDate = this.toDate;
    }

    printReport(type) {
        this.confirmationService.confirm({
            message: this.translate.instant('ACCOUNTING.DOWNLOAD_' + type + '_ALL_CONFIRMATION'),
            accept: () => {
                this.isDownloading = true;
                (this.createdByFilter === 'MISMATCH' ? this.usersService.downloadDriversEarningsReportMismatch(this.createParams(), type.toLowerCase()) : this.usersService.downloadDriversEarningsReport(this.createParams(), type.toLowerCase())).subscribe((response: any) => {
                    window.open(response.url, '_blank');
                    this.isDownloading = false;
                });
            },
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
        });
    }

    onDateTypeChanged() {
        this.onFilter();
    }

    setShowProductsAddByUser(s: string) {
        this.fromDate = this.toDate = null;
        this.setDatesFilters();
        this.selectedDriverId = null;
        this.createdByFilter = s;
        this.userDropdown.resetUser(true);
        this.onDateSelected({label:  this.translate.instant('GENERAL.DATE_RANGE_PICKER.BEFORE_1_MONTH'), start: this.fromDate, end: this.toDate});
    }
}
