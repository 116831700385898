import {UsersService} from '../../../users/services/users.service';
import {SharedService} from '../../../shared/services/shared-service';
import {UserService} from '../../../shared/services/user.service';
import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ACCOUNTANT_REPORT_ORDER_BY} from '../../../ticketing-system/services/constants';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService} from 'primeng/api';
import * as _ from 'lodash';
import {DELAYED_COD_REPORT_DATE_FILTERS_LIST} from '../../../shipment/services/constants';
declare let $;


@Component({
    selector: 'app-received-cod-without-custody-packages',
    templateUrl: './received-cod-without-custody-packages.component.html',
    styleUrls: ['./received-cod-without-custody-packages.component.scss']
})
export class ReceivedCodWithoutCustodyPackagesComponent implements OnInit, AfterViewInit {

    packages = [];
    currentLang = '';
    currency = '';
    isLoading = false;
    page = 0;
    fetchedData: any[];
    totalRecordsNo = 0;
    entityId;

    orderByOptions;
    selectedOrderByOption;
    dateRangPickerOptions;
    fromDate;
    toDate;
    isAllDate = false;
    searchContent = '';
    type;
    codStatuses;
    isLoadingExcel = false;
    deliveryDate = {
        value: undefined
    };
    selectedDateFilter = 'CREATED_DATE';
    dateFiltersList = [];
    private preSelectedDate;
    @ViewChild('datepicker') datepicker;
    constructor(private translateService: TranslateService,
                private activeModal: NgbActiveModal,
                private sharedService: SharedService,
                private datePipe: DatePipe,
                private dateRangePickerService: DateRangePickerService,
                public usersService: UsersService,
                private confirmationService: ConfirmationService,
                public userService: UserService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.currency = this.userService.getCurrency();
        // this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
        //     const label = this.translateService.instant('GENERAL.DATE_RANGE_PICKER.ALL');
        //     this.isAllDate = true;
        //     const [start, end] = options.ranges[label];
        //     this.fromDate = start;
        //     this.toDate = end;
        //     options.startDate = start;
        //     options.endDate = end;
        //     this.dateRangPickerOptions = options;
        //     this.preSelectedDate = {
        //         label,
        //         start,
        //         end
        //     };
        // });
        this.setDateFiltersList();
        this.getOrderByOptions();
        this.initShipments();
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
        }, 30);
    }


    createQuery() {
        let urlQuery = `?pageSize=20&page=${(this.page + 1)}&entityId=${this.entityId}&type=${this.type}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        if (this.selectedOrderByOption && this.selectedOrderByOption.value) {
            urlQuery += '&order-by=' + this.selectedOrderByOption.value;
        }
        if (this.selectedDateFilter && this.selectedDateFilter !== 'CREATED_DATE') {
            urlQuery += '&massCodDateFilterType=' + this.selectedDateFilter;
        }
        if (this.fromDate) {
            urlQuery += '&fromDate=' + this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            urlQuery += '&toDate=' + this.transformDate(this.toDate);
        }
        if (this.deliveryDate.value) {
            urlQuery += '&massCodDeliveryDate=' + this.transformDate(this.deliveryDate.value);
        }

        if (this.searchContent) {
            urlQuery += '&search=' + this.searchContent;
        }
        if (this.codStatuses && this.codStatuses.length) {
            urlQuery += '&codStatuses=' + this.codStatuses.concat(',');
        }

        if (this.isAllDate) {
            urlQuery += '&isIncludeNullDates=true';
        }

        return urlQuery;
    }

    initShipments(forceFetch = false) {
        if (!this.entityId) {
            return;
        }
        if (forceFetch) {
            this.packages = [];
            this.page = 0;
        }
        const urlQuery = this.createQuery();
        let fetchedShipments = [];
        if (this.packages.length && this.packages.length === this.totalRecordsNo) {
            return;
        }
        this.isLoading = true;
        this.usersService.getReceivedPackagesWithoutCustody(urlQuery).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                fetchedShipments = response.data;
                this.packages = [...this.packages, ...fetchedShipments];
                this.totalRecordsNo = response.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    loadLazyShipment() {
        this.initShipments();
    }

    @HostListener('scroll', ['$event'])
    onScrollTable(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop + 10 >= event.target.scrollHeight && this.packages.length !== this.totalRecordsNo && !this.isLoading) {
            if (this.page > 1 || this.packages.length) {
                this.page++;
                this.loadLazyShipment();
            }
        }
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.COPIED');
    }

    closeModal() {
        this.activeModal.close();
    }

    getCustody(pkg) {
        let custody = '';
        if (pkg.hubName) {
            custody = this.translateService.instant('ACCOUNTING.IN_HUB') + ' '  +  pkg.hubName;
        } else if (pkg.driverName) {
            custody = this.translateService.instant('ACCOUNTING.IN_CAR') + ' '  +  pkg.driverName;
        } else if (pkg.partnerName) {
            custody = this.translateService.instant('ACCOUNTING.WITH_PARTNER') + ' '  +  pkg.partnerName;
        }
        return custody;
    }

    getOrderByOptions() {
        const tempOrderBy = _.cloneDeep(ACCOUNTANT_REPORT_ORDER_BY);
        tempOrderBy.push('MASS_COD_DELIVERY_DATE_ASC', 'MASS_COD_DELIVERY_DATE_DESC');
        this.translateService.get(tempOrderBy).subscribe((labels) => {
            this.orderByOptions = tempOrderBy.map((value) => ({
                label: labels[value],
                value
            }));
            this.selectedOrderByOption = this.orderByOptions[0];
        });
    }

    onDateSelected(value) {
        setTimeout(() => {
            this.preSelectedDate = value;
            this.fromDate = new Date(value.start);
            this.toDate = new Date(value.end);
            this.isAllDate = value.label === this.translateService.instant('GENERAL.DATE_RANGE_PICKER.ALL');
            this.fillTextInDateInput(value);
            this.onSearch();
        });
    }

    onHideDateRangePicker($event: any) {
        setTimeout(() => {
            this.fillTextInDateInput(this.preSelectedDate);
        });
    }

    private fillTextInDateInput(value) {
        const {start, end} = value;
        if ((start && end) || value.label) {
            if (value.label && value.label !== this.dateRangPickerOptions.locale.customRangeLabel) {
                if (value.label === this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    exportReportToExcel() {

            this.confirmationService.confirm({
                message: this.translateService.instant('ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'),
                accept: () => {
                    this.isLoadingExcel = true;
                    const urlQuery = this.createQuery();
                    this.usersService.getExcelReceivedPackagesWithoutCustody(urlQuery).subscribe((response: any) => {
                        window.open(response.url, '_blank');
                        this.isLoadingExcel = false;
                    });
                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: this.translateService.instant('GENERAL.YES'),
                rejectLabel: this.translateService.instant('GENERAL.NO'),
            });
    }
    public resetField(name, refreshData = true) {
        this.deliveryDate.value = '';
        this.onSearch();
    }

    onSearch() {
        this.initShipments(true);
    }

    onShowCalender($event: any) {
        if ($event?.picker?.container?.length) {
            $($event.picker.container[0]).addClass('z-index-9999999999999999999');
        }
    }

    onDateFilterChange() {
        this.onSearch();
    }

    private setDateFiltersList() {
        this.dateFiltersList = ['CREATED_DATE', 'MASS_COD_DELIVERY_DATE', 'MASS_COD_RECEIVE_DATE'].map((value) => {
            return {label: this.translateService.instant(value), value};
        });
    }
}
