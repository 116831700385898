import {Injectable} from '@angular/core';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ParcelService {

    constructor(private httpClient: HttpClient) {
    }

    public getParcels(params = {}) {
        return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_PARCELS, {params});
    }

    public addParcel(body) {
        return this.httpClient.post(ADMINISTRATION_CONSTANTS.CREATE_PARCEL, body);
    }

    public editParcel(body, id) {
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.UPDATE_PARCEL.replace('{id}', id), body);
    }

    public deleteParcel(id) {
        return this.httpClient.delete(ADMINISTRATION_CONSTANTS.DELETE_PARCEL.replace('{id}', id));
    }

}
