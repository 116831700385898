<app-field-set-form [label]="label" class="g-calendar"
                    [cssStyle]="cssStyle"
                    [isFilter]="isFilter"
                    [filterActive]="customDefault ? !isDefaultValue() : !!value"
                    [formControlObj]="formControlObj"
                    [showLabel]="showLabel">
    <p-calendar [defaultDate]="defaultDate" *ngIf="!rangeTime"
                [selectionMode]="selectionMode"
                [style]="style"
                [styleClass]="styleClass"
                [inputStyle]="inputStyle"
                [inputStyleClass]="inputStyleClass"
                [inputId]="inputId"
                [name]="name"
                [placeholder]="placeholder | translate"
                [disabled]="disabled"
                [dateFormat]="dateFormat"
                [inline]="inline"
                [showOtherMonths]="showOtherMonths"
                [selectOtherMonths]="selectOtherMonths"
                [showIcon]="showIcon"
                [showOnFocus]="showOnFocus"
                [showWeek]="showWeek"
                [icon]="icon"
                [appendTo]="appendTo"
                [readonlyInput]="readonlyInput"
                [shortYearCutoff]="shortYearCutoff"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [disabledDates]="disabledDates"
                [disabledDays]="disabledDays"
                [monthNavigator]="monthNavigator"
                [yearNavigator]="yearNavigator"
                [yearRange]="yearRange"
                [showTime]="showTime"
                [hourFormat]="hourFormat"
                [locale]="locale"
                [timeOnly]="timeOnly"
                [timeSeparator]="timeSeparator"
                [dataType]="dataType"
                [required]="required"
                [tabindex]="tabindex"
                [ariaLabelledBy]="ariaLabelledBy"
                [iconAriaLabel]="iconAriaLabel"
                [showSeconds]="showSeconds"
                [stepHour]="stepHour"
                [stepMinute]="stepMinute"
                [stepSecond]="stepSecond"
                [maxDateCount]="maxDateCount"
                [showButtonBar]="showButtonBar"
                [todayButtonStyleClass]="todayButtonStyleClass"
                [clearButtonStyleClass]="clearButtonStyleClass"
                [baseZIndex]="baseZIndex"
                [autoZIndex]="autoZIndex"
                [panelStyleClass]="panelStyleClass"
                [panelStyle]="panelStyle"
                [keepInvalid]="keepInvalid"
                [hideOnDateTimeSelect]="hideOnDateTimeSelect"
                [numberOfMonths]="numberOfMonths"
                [view]="view"
                [multipleSeparator]="multipleSeparator"
                [rangeSeparator]="rangeSeparator"
                [touchUI]="touchUI"
                [focusTrap]="focusTrap"
                [showTransitionOptions]="showTransitionOptions"
                [hideTransitionOptions]="hideTransitionOptions"
                [firstDayOfWeek]="firstDayOfWeek"
                [(ngModel)]="value"
                (reset)="reset.emit()"
                (onClearClick)="whenClearClick.emit($event)"
                (onSelect)="whenSelect.emit($event)">
    </p-calendar>
    <div class="date-time-range-picker d-flex align-items-baseline" *ngIf="rangeTime">
        <input class="expected-delivery-date bg-transparent cursor-pointer border-0 p-inputtext pl-3"
                #dateTimeRangePicker
                #dateTimRangePopover="ngbPopover"
                placement="bottom"
                autoClose="outside"
                placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"
                container="body"
                [ngbPopover]="dateTimRangeDialog"
                [placement]="'auto'"
                [ngClass]="{'active-filter-button': value}"
                [(ngModel)]="valueView"/>
        <i *ngIf="customDefault ? !isDefaultValue() : !!value" [ngClass]="{'en-icon': currentLang === 'en'}" class="fas fa-times cursor-pointer" (click)="clearDateRangeTimeChanged()"></i>
        <i class="{{icon}} cursor-pointer" [ngClass]="{'en-icon': currentLang === 'en'}" (click)="dateTimeRangePicker.click()"></i>
    </div>
</app-field-set-form>

<ng-template #dateTimRangeDialog let-close="close">
    <div class="p-2 custom-date-picker-for-expected-delivery" style="min-width: 300px">
        <p-calendar
                styleClass="date-picker header-filter-background"
                [(ngModel)]="rangeTimeValue"
                (onSelect)="dateRangeTimeChanged('DATE', $event)"
                placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"
                dateFormat="dd/mm/yy"
                [inline]="true"
                [appendTo]="'body'"
        ></p-calendar>
        <ng-container>
            <div class="d-flex justify-content-between pt-2">
                <div>
                    <div class="expected-date-label">
                        {{'FROM_TIME'|translate}}
                    </div>
                    <div class="expected-date-value">
                        <p-calendar
                                styleClass="date-picker header-filter-background"
                                [(ngModel)]="value.from"
                                (onSelect)="dateRangeTimeChanged('FROM', $event)"
                                placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"
                                dateFormat="dd/mm/yy"
                                [inline]="true"
                                [timeOnly]="true"
                                [appendTo]="'body'"
                        ></p-calendar>
                    </div>
                </div>

                <div>
                    <div class="expected-date-label">
                        {{'TO_TIME'|translate}}
                    </div>
                    <div class="expected-date-value">
                        <p-calendar
                                styleClass="date-picker header-filter-background"
                                [(ngModel)]="value.to"
                                (onSelect)="dateRangeTimeChanged('TO', $event)"
                                placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"
                                dateFormat="dd/mm/yy"
                                [inline]="true"
                                [timeOnly]="true"
                                [appendTo]="'body'"
                        ></p-calendar>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="d-flex justify-content-end mt-3">
            <button (click)="whenSelect.emit(this.value)" class="p-button">{{ "ACTIONS.DONE" | translate }}</button>
        </div>
    </div>
</ng-template>
