import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContainersService} from '../services/containers.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from '../../shared/services/shared-service';
import {STORAGE_KEYS} from '../../shared/services/storage/storage.keys';
import {StorageService} from '../../shared/services/storage/storage.service';
import {DriversService} from '../../shared/services/drivers.service';
import {UsersService} from '../../users/services/users.service';
import {ShelveModel} from '../../shared/models/shelve.model';
import {HubsService} from '../../shared/services/hubs.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HubModel} from '../../shared/models/hub.model';
import {SHIPMENT_WITH_DRIVER_STATUS_LIST} from '../../shipment/services/constants';
import {UserService} from '../../shared/services/user.service';
import {ShipmentsValidationResultsComponent} from '../../shipment/shipments-validation-results/shipments-validation-results.component';

@Component({
    selector: 'app-carried-packages',
    templateUrl: './carried-packages.component.html',
    styleUrls: ['./carried-packages.component.scss']
})
export class CarriedPackagesComponent implements OnInit {

    @Input() containerId: number;
    @Input() shelveId: number;

    public packages = [];
    public currentLang = '';
    public currency = '';
    public isLoading = false;
    public page = 0;
    public containersPageNumber = 0;
    public driversPageNumber = 0;
    public shelvesPageNumber = 0;
    public containersList: any[] = [];
    public fetchedData: any[];
    public totalRecordsNo = 0;
    public driversTotalRecordsNo: number;
    public driversList: any = [];
    public selectedTab = 'CONTAINERS';
    public driverId: number;
    public isShelvePackages = false;
    public shelves: ShelveModel[] = [];
    public totalShelvesNo: number;
    public hub: HubModel;
    public hubId: number;
    public shelvesLoader = false;
    public printLoader = false;
    public selectedShipmentStatus = 'ALL';
    public shipmentStatusList;
    public isDetectDriverTrack = false;
    public isExporting = false;
    private hasMore = true;
    isHubPackages = false;
    isReceiveHubCod = false;
    hubs = [];
    fetchedHubs = [];
    hubsPageNumber = 0;
    totalHubsNo: number;
    isGettingHubs = false;
    hubCodType: string;
    isAllSelected = false;
    selectedPackagesSummery = {count: 0, costSum: 0, codSum: 0};
    totalPackagesSummery = {count: 0, costSum: 0, codSum: 0};
    barcodeInput = '';

    constructor(private route: ActivatedRoute,
                private containersService: ContainersService,
                private translateService: TranslateService,
                private activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private sharedService: SharedService,
                private storageService: StorageService,
                public driverService: DriversService,
                public usersService: UsersService,
                public userService: UserService,
                private hubsService: HubsService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        // this.userId = this.route.snapshot.paramMap.get('id');
        this.currentLang = this.translateService.currentLang;
        this.shipmentStatusList = this.setShipmentStatusList();
        this.currency = this.userService.getCurrency();
        this.initShipments();
        this.selectedTab = this.isDetectDriverTrack ? 'DRIVERS' : 'CONTAINERS';
        this.getList();
    }

    getList() {
        if (this.isHubPackages) {
            this.getHubs();
        } else if (this.isShelvePackages) {
            this.getShelves();
        } else {
            this.lazyLoadDrivers();
            if (!this.isDetectDriverTrack) {
                this.getContainersList();
            }
        }
    }

    @HostListener('scroll', ['$event'])
    onScrollHubsList(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.hubsPageNumber++;
            this.getHubs();
        }
    }

    getHubs() {
        if (this.hubs.length && this.hubs.length === this.totalHubsNo) {
            return;
        }
        let fromAll = true;
        this.isGettingHubs = true;
        let query = '?pageSize=20&page=' + (this.hubsPageNumber + 1);
        if (this.hubCodType === 'RECEIVED') {
            query += '&is-receive-cod-from-hub=true';
            fromAll = false;
        }
        this.hubsService.getHubs(query, '', false, fromAll, true).subscribe((res: any) => {
            const fetchedHubs = res.data;
            this.hubs = [...this.hubs, ...fetchedHubs];
            this.totalHubsNo = res.totalRecordsNo;
            this.isGettingHubs = false;
        }, error => {
            console.error(error);
            this.isGettingHubs = false;
        });
    }

    onSelectHub(hub) {
        this.hubId = hub.hubId;
        this.reset();
        this.initHubShipments();
    }

    initHubShipments() {
        const urlQuery = this.createQuery();
        let fetchedShipments = [];
        if (this.packages.length && this.packages.length === this.totalRecordsNo) {
            return;
        }
        this.isLoading = true;
        let responseAtt = 'data';
        let getReceivedCod;
        if (this.hubCodType === 'RECEIVED') {
            if (this.isReceiveHubCod) {
                getReceivedCod = this.hubsService.receiveHubCod(this.hubId, urlQuery);
            } else {
                getReceivedCod = this.hubsService.getHubReceivedCod(this.hubId, urlQuery);
                responseAtt = 'packages';
            }
        } else if (['EXPORTED', 'DELIVERED'].indexOf(this.hubCodType) !== -1) {
            getReceivedCod = this.hubsService.getHubExportedCod(this.hubId, urlQuery);
        }
        getReceivedCod.subscribe(
            (response: { data: any[], totalRecordsNo: number, totalCost: number, totalCod: number }) => {
                fetchedShipments = response[responseAtt];
                this.packages = [...this.packages, ...fetchedShipments];
                this.totalRecordsNo = response.totalRecordsNo;
                this.totalPackagesSummery = {count: response.totalRecordsNo, costSum: response.totalCost, codSum: response.totalCod };
                this.hasMore = this.packages && (this.packages.length < response.totalRecordsNo && response.data && response.data.length > 0);
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    public createQuery() {
        let urlQuery = '?pageSize=12&page=' + (this.page + 1);
        if (this.selectedShipmentStatus !== 'ALL') {
            urlQuery += '&status=' + this.selectedShipmentStatus;
        }
        if (this.isHubPackages && ['EXPORTED', 'DELIVERED'].indexOf(this.hubCodType)  !== -1) {
            urlQuery += '&status=' + this.hubCodType;
        }
        return urlQuery;
    }

    private initContainerShipments() {
        const urlQuery = this.createQuery();
        let fetchedDriverShipments = [];
        if (this.packages.length && this.packages.length === this.totalRecordsNo) {
            return;
        }
        this.isLoading = true;
        this.containersService.getContainerPackages(this.containerId, urlQuery).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                fetchedDriverShipments = response.data;
                this.packages = [...this.packages, ...fetchedDriverShipments];
                this.totalRecordsNo = response.totalRecordsNo;
                this.isLoading = false;
                this.hasMore = this.packages && (this.packages.length < response.totalRecordsNo && response.data && response.data.length > 0);
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    setPackagesOrderNumbers() {
        let index = 1;
        this.packages.forEach(
            (pkg) => {
                pkg.orderNumber = index++;
            }
        );
    }

    private initDriverShipments() {
        // const urlQuery = '?pageSize=12&page=' + (this.page + 1);
        let urlQuery = this.createQuery();
        urlQuery += '&driverType=TYPICAL';
        let fetchedContainerShipments = [];
        if (this.packages.length && this.packages.length === this.totalRecordsNo || !this.driverId) {
            return;
        }
        this.isLoading = true;
        this.usersService.getDriverPackages(this.driverId, urlQuery).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                fetchedContainerShipments = response.data;
                this.packages = [...this.packages, ...fetchedContainerShipments];
                this.totalRecordsNo = response.totalRecordsNo;
                this.isLoading = false;
                this.hasMore = this.packages && (this.packages.length < response.totalRecordsNo && response.data && response.data.length > 0);
                this.setPackagesOrderNumbers();
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }


    private initShelveShipments() {
        const urlQuery = this.createQuery();
        let fetchedShipments = [];
        if (this.packages.length && this.packages.length === this.totalRecordsNo) {
            return;
        }
        this.isLoading = true;
        this.hubsService.getShelvePackages(this.shelveId, urlQuery).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                fetchedShipments = response.data;
                this.packages = [...this.packages, ...fetchedShipments];
                this.totalRecordsNo = response.totalRecordsNo;
                this.hasMore = this.packages && (this.packages.length < response.totalRecordsNo && response.data && response.data.length > 0);
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    private initShipments() {
        if ((!this.containerId && !this.driverId && !this.shelveId) && (this.isHubPackages && !this.hubId)) {
            return;
        }
        if (this.isHubPackages) {
            this.initHubShipments();
        } else {
            if (this.isShelvePackages) {
                this.initShelveShipments();
            } else {
                return this.containerId ? this.initContainerShipments() : this.initDriverShipments();
            }
        }
    }

    public loadLazyShipment() {
        this.initShipments();
    }

    @HostListener('scroll', ['$event'])
    onScrollTable(event: any) {
        if (!this.hasMore || this.isLoading) {
            return;
        }
        if (event.target.offsetHeight + event.target.scrollTop + 10 >= event.target.scrollHeight) {
            if (this.page > 1 || this.packages.length) {
                this.page++;
                this.loadLazyShipment();
            }
        }
    }

    public onStatusChanged() {
        this.packages = [];
        this.page = 0;
        this.initShipments();
        // this.getPackages(true);
    }

    public setShipmentStatusList() {
        return SHIPMENT_WITH_DRIVER_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    public copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    public downloadPdf() {
        this.isLoading = true;
        this.actualDownloadPdf();
    }

    public actualDownloadPdf() {
        this.printLoader = true;
        if (this.isDetectDriverTrack) {
            this.confirmDetectDriverTrack(true, false);
        } else {
            this.containersService.printContainerPackages(this.containerId).subscribe((res: any) => {
                    window.open(res.url, '_blank');
                    this.printLoader = false;
                },
                error => {
                    console.error(error);
                    this.printLoader = false;
                });
        }
    }

    public onSetContainerId(id) {
        this.containerId = id;
        this.driverId = null;
        this.reset();
        this.initShipments();
    }

    public onSetDriverId(id) {
        this.driverId = id;
        this.containerId = null;
        this.reset();
        this.initShipments();
    }

    public onSetShelveId(id) {
        this.shelveId = id;
        this.packages = [];
        this.page = 0;
        // this.shelvesPageNumber = 0;
        this.initShipments();
    }

    reset() {
        this.packages = [];
        this.page = 0;
    }

    public setSelectedTab(tab) {
        this.reset();
        this.selectedTab = tab;
    }
    confirmReceiveCodFromHub() {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.RECEIVE_COD_FROM_HUB_CONFIRMATION'),
            accept: () => {
                const selectedPackages = this.packages.filter(pkg => (pkg.isSelected)).map(pkg => pkg.barcode);
                this.reset();
                this.isLoading = true;
                this.usersService.receiveCodFromHub(this.hubId, {barcodes: selectedPackages}).subscribe(
                    (res: any) => {
                        if (res.length) {
                            const modalDialog = this.modalService.open(
                                ShipmentsValidationResultsComponent,
                                {
                                    backdrop: 'static',
                                    windowClass: 'shipments-validation-results',
                                    size: 'lg'});
                            modalDialog.componentInstance.results = res;
                            modalDialog.componentInstance.label = 'ACCOUNTING.RECEIVE_COD_MENUITEM';
                        } else {
                            this.hubId = null;
                            this.initShipments();
                            this.messageService.add({severity: 'success', detail: this.translateService.instant('ACCOUNTING.IS_RECEIVED')});
                        }
                        this.isLoading = false;
                    }, error => {
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO')
        });

    }
    public onFinish() {
        if (this.isReceiveHubCod) {
            this.confirmReceiveCodFromHub();
        } else {
            if (!this.isDetectDriverTrack) {
                this.closeModal();
            } else {
                this.confirmDetectDriverTrack();
            }
        }

    }

    disableFinishButton() {
        if (this.isReceiveHubCod) {
            return !(this.packages.filter(pkg => (pkg.isSelected))).length || this.isLoading;
        } else {
            return !this.packages.length || this.printLoader || this.isExporting || this.isLoading;
        }
    }

    public exportToExcel() {
        this.isExporting = true;
        const exportToExcel = this.usersService.exportDriverPackagesToExcel(this.driverId, null, null,
            false, false, false, 'DRIVER_PACKAGE_ORDER');

        exportToExcel.subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isExporting = false;
                this.isLoading = false;
                this.driverId = null;
                this.reset();
                this.initShipments();
            },
            error => {
                console.error(error);
                this.isExporting = false;
            });
    }

    public confirmDetectDriverTrack(isPdf = false, isExcel = false) {
        this.isLoading = true;
        this.translateService.get(
            ['ALERTS.DETECT_DRIVER_TRACK_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    this.confirmationService.confirm({
                        message: data['ALERTS.DETECT_DRIVER_TRACK_CONFIRMATION'],
                        accept: () => {
                            this.detectDriverTrack(isPdf, isExcel);
                        },
                        reject: () => {
                            this.isLoading = false;
                            this.isExporting = false;
                            this.printLoader = false;
                        },
                        acceptLabel: data['GENERAL.YES'],
                        rejectLabel: data['GENERAL.NO']
                    });
                });
    }

    public detectDriverTrack(isPdf = false, isExcel = false) {
        const orderedPackages = this.packages.map(pkg => pkg.id);
        this.driverService.detectDriverTrack({ids: this.packages.map(pkg => pkg.id)}).subscribe(
            (res: any) => {
                this.translateService.get('ALERTS.DETECT_DRIVER_TRACK_SUCCESSFULLY').subscribe(
                    (translatedMessage) => {
                        if (isPdf) {
                            this.usersService.downloadDriverPackagesPdf(this.driverId, null, null, false,
                                false, false, 'DRIVER_PACKAGE_ORDER').subscribe((res: any) => {
                                    window.open(res.url, '_blank');
                                    this.printLoader = false;
                                    this.isLoading = false;
                                    this.driverId = null;
                                    this.reset();
                                    this.initShipments();
                                },
                                error => {
                                    console.error(error);
                                    this.printLoader = false;
                                    this.isLoading = false;
                                });

                        } else if (isExcel) {
                            this.exportToExcel();
                        } else {
                            this.closeModal();
                        }
                        this.messageService.add({severity: 'success', detail: translatedMessage});

                    }
                );
            }, error => {
                this.isLoading = false;
                this.isExporting = false;
                this.printLoader = false;
                console.error(error);
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }

    public getContainersList() {
        this.fetchedData = [];
        if (this.containersList.length && this.containersList.length === this.totalRecordsNo) {
            return;
        }
        const query = '?pageSize=20&page=' + (this.containersPageNumber + 1);

        this.containersService.getContainersList(query).subscribe(
            (response: { vehicles: any[], totalRecordsNo: number }) => {
                this.fetchedData = response.vehicles;
                this.containersList = [...this.containersList, ...this.fetchedData];
                this.totalRecordsNo = response.totalRecordsNo;
            }
        );
    }

    public loadLazyContainersList() {
        this.getContainersList();
    }

    @HostListener('scroll', ['$event'])
    onScrollContainersList(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            if (this.isShelvePackages) {
                this.shelvesPageNumber++;
                this.getShelves();
            } else {
                this.containersPageNumber++;
                this.loadLazyContainersList();
            }
        }
    }

    getDrivers(thirdParty = false) {
        // this.isLoadingDriversList = true;
        if (this.driversList.length && this.driversList.length === this.driversTotalRecordsNo) {
            return;
        }
        let query = '?pageSize=20&page=' + this.driversPageNumber;
        if (thirdParty) {
            query += '&driverType=THIRD_PARTY';
        } else {
            query += '&driverType=TYPICAL';
        }

        this.driverService.getNameAndIdDriversList(query).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                const fetchedDriverList = response.data;
                this.driversList = [...this.driversList, ...fetchedDriverList];
                this.driversTotalRecordsNo = response.totalRecordsNo;
                // this.isLoadingDriversList = false;
            }, error => {
                console.error(error);
                // this.isLoadingDriversList = false;
            });
    }

    public lazyLoadDrivers() {
        this.driversPageNumber++;
        this.getDrivers();
    }

    @HostListener('scroll', ['$event'])
    onScrollDriversList(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.lazyLoadDrivers();
        }
    }

    public getShelves() {

        if (this.shelves.length && this.shelves.length === this.totalShelvesNo) {
            return;
        }
        this.shelvesLoader = true;
        const query = '?pageSize=6&page=' + (this.shelvesPageNumber + 1);
        this.hubsService.getShelves(this.hubId, query).subscribe((res: any) => {
            const fetchedShelves = res.data;
            this.shelves = [...this.shelves, ...fetchedShelves];
            this.totalShelvesNo = res.totalRecordsNo;
            this.shelvesLoader = false;
        }, error => {
            console.error(error);
            this.shelvesLoader = false;
        });
    }

    movePackageElement(packages, fromIndex, toIndex) {
        if (toIndex === fromIndex || toIndex < 0 || toIndex > this.packages.length) {
            return packages;
        }

        const target = packages[fromIndex];
        const increment = toIndex < fromIndex ? -1 : 1;

        for (let i = fromIndex; i !== toIndex; i += increment) {
            packages[i] = packages[i + increment];
        }
        packages[toIndex] = target;
        return packages;
    }

    orderPackages(pkg) {
        this.movePackageElement(this.packages, this.packages.indexOf(pkg), pkg.orderNumber - 1);
        this.setPackagesOrderNumbers();
    }

    increaseDecreaseOrderNumber(sign, pkg) {
        pkg.orderNumber = sign === 'PLUS' ? pkg.orderNumber + 1 : pkg.orderNumber - 1;
        this.orderPackages(pkg);
    }

    onToggleSelectAll() {
        if (this.totalRecordsNo === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }
        this.packages.forEach((pkg: any) => {
            pkg.isSelected = selected;
        });
        this.setSummeryValues();
    }

    onToggleSelection() {
        const selectedPackages = this.packages.filter(pkg => (pkg.isSelected));
        if (selectedPackages) {
            this.isAllSelected = selectedPackages.length === this.packages.length;
        }
        this.setSummeryValues();

    }

    barcodeReader(barcodeInput) {
        if ((this.packages.map(pkg => (pkg.barcode))).indexOf(barcodeInput) === -1) {
            this.messageService.add({severity: 'error', detail: this.translateService.instant('ALERTS.UNAVAILABLE_BARCODE_NUMBER')});
            return;
        }
        const selectedPackages = this.packages.filter(pkg => (pkg.isSelected)).map(pkg => pkg.barcode);
        if (selectedPackages.indexOf(barcodeInput) !== -1) {
            this.messageService.add({severity: 'error', detail: this.translateService.instant('ALERTS.PACKAGE_ALREADY_SCANNED')});
            return ;
        }
        this.packages.forEach(
            pkg => {
                if (pkg.barcode === barcodeInput) {
                    pkg.isSelected = true;
                }
            }
        );
        this.barcodeInput = '';
        this.setSummeryValues();
    }

    setSummeryValues() {
        const selectedPackages = this.packages.filter(pkg => (pkg.isSelected));
        let costSum = 0, codSum = 0;
        selectedPackages.forEach(pkg => {
           costSum += pkg.cost;
            codSum += pkg.cod;
        });
        this.selectedPackagesSummery = {count: selectedPackages.length, costSum: costSum, codSum: codSum};
    }
}
