<div class="h-100 d-flex flex-column">
    <div class="top-actions">
        <div class="group-tabs-container">
            <div class="pending-tab cursor-pointer tab" routerLink="basic-info" routerLinkActive="selectedTab">
                {{'GENERAL.COMPANY_BASIC_INFO' | translate}}
            </div>
            <div class="accepted-tab cursor-pointer tab" routerLink="theme" routerLinkActive="selectedTab">
                {{'COMPANY.THEME' | translate}}
            </div>
        </div>
    </div>

    <div class="flex-1 overflow-auto">
        <router-outlet>
        </router-outlet>
    </div>
</div>
