import {Component} from '@angular/core';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {SaasService} from '../../services/saas.service';

@Component({
    selector: 'app-saas-theme',
    templateUrl: 'saas-theme.component.html',
    styleUrls: ['saas-theme.component.scss']
})
export class SaasThemeComponent {

    public spinnerState;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public pageNumber = 0;
    public totalRecords = 0;
    public colors = [];
    public pageSize = 10;
    public disableSave = false;
    constructor(private saasService: SaasService) {
        this.initColors();
    }

    initColors() {
        this.spinnerState = SpinnerState.LOADING;
        this.saasService.getColors().pipe().subscribe((data: Array<any>) => {
                this.colors = data && data.length > 0 ? data : this.getDefaultColors();
            },
            () => {
                this.spinnerState = SpinnerState.LOADED;
            },
            () => {
                this.spinnerState = SpinnerState.LOADED;
            });
    }

    public addColor() {
        this.colors.push({colorKey: 'New Color', colorValue: '#000000', description: ''});
        this.totalRecords = this.colors.length;
    }

    public save() {
        // this.spinnerState = SpinnerState.LOADING;
        // this.disableSave = true;
        // this.saasService.updateColors(this.colors).subscribe(data => {
        //     this.initColors();
        // },()=>{},()=>{
        //     this.disableSave = false;
        // });
    }

    public discardColor(color) {
        const index = this.colors.indexOf(color);
        if (index > -1) {
            this.colors.splice(index, 1);
        }
    }

    public getDefaultColors() {
        return [
            {colorKey: '--admin-light-text-color', colorValue: '#a2a2a2', description: 'description'},
            {colorKey: '--admin-dashboard-text-color', colorValue: '#a2a2a2', description: 'description'},
            {colorKey: '--admin-line-separator-color', colorValue: '#e4e4e4', description: 'description'},
            {colorKey: '--admin-add-shipment-field-border', colorValue: '#e4e4e4', description: 'description'},
            {colorKey: '--admin-email-titles-color', colorValue: 'rgba(0, 0, 0, 0.76)', description: 'description'},
            {colorKey: '--admin-loading-container-background', colorValue: 'rgba(0, 0, 0, 0.76)', description: 'description'},
            {colorKey: '--admin-label-text-color', colorValue: '#333333', description: 'description'},
            {colorKey: '--admin-field-input-text-color', colorValue: '#333333', description: 'description'},
            {colorKey: '--admin-edit-icon-hover', colorValue: '#ff', description: 'description'},
            {colorKey: '--admin-field-input-select-background', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-table-note-background', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-dialog-header-text-color', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-dialog-header-background', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-tab-background', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-active-tab-background', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-tab-color', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-search-logo-background', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-submit-button-background', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-button-text-color', colorValue: '#000', description: 'description'},
            {colorKey: '--admin-disabled-button-text-color', colorValue: '#000', description: 'description'},
            {colorKey: '--admin-disabled-button-background', colorValue: '#a9a9a9', description: 'description'},
            {colorKey: '--admin-btn-previous-background', colorValue: '#585656', description: 'description'},
            {colorKey: '--admin-btn-next-background', colorValue: '#3d8cc2', description: 'description'},
            {colorKey: '--admin-menu-icon-color', colorValue: '#2491ec', description: 'description'},
            {colorKey: '--admin-404-color', colorValue: '#395f82', description: 'description'},
            {colorKey: '--admin-menu-itme-active-background', colorValue: '#395f82', description: 'description'},
            {colorKey: '--admin-menu-item-text-color', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-menu-hover-bacground', colorValue: '#e4e4e4', description: 'description'},
            {colorKey: '--admin-header-menu-text-color', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-sub-menu-active-background', colorValue: '#395f82b3', description: 'description'},
            {colorKey: '--admin-main-page-background', colorValue: '#f3f3f3', description: 'description'},
            {colorKey: '--admin-sub-menu-background', colorValue: '#f3f3f3', description: 'description'},
            {colorKey: '--admin-success-color', colorValue: '#00bd9f', description: 'description'},
            {colorKey: '--admin-danger-color', colorValue: '#e94648', description: 'description'},
            {colorKey: '--admin-secondary-action-color', colorValue: '#24c2e1', description: 'description'},
            {colorKey: '--admin-dashboard-bottom-icon-color', colorValue: '#b3afaf', description: 'description'},
            {colorKey: '--admin-card-background', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-card-spinner-background', colorValue: '#788397', description: 'description'},
            {colorKey: '--admin-paginator-background', colorValue: '#f4f4f4', description: 'description'},
            {colorKey: '--admin-paginator-selected-background', colorValue: '#007ad9', description: 'description'},
            {colorKey: '--admin-paginator-selected-color', colorValue: '#ffffff', description: 'description'},
            {colorKey: '--admin-side-menu-background', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-header-backgrond', colorValue: '#fff', description: 'description'},
            {colorKey: '--admin-replay-email-border-color', colorValue: '#f1f1f1', description: 'description'},
            {colorKey: '--admin-email-replay-field-input-border', colorValue: '#d2d2d2', description: 'description'},
            {colorKey: '--admin-email-replay-field-input-background', colorValue: '#f6f7f9', description: 'description'},
            {colorKey: '--admin-link-color', colorValue: '#007ad9', description: 'description'},
            {colorKey: '--customer-header-modal-background', colorValue: '#3d8cc2', description: 'description'},
            {colorKey: '--customer-paginater-modal-background', colorValue: '#f4f4f4', description: 'description'},
            {colorKey: '--customer-load-bar-background', colorValue: '#fdba2c', description: 'description'},
            {colorKey: '--customer-load-bar-1-background', colorValue: '#3d8cc2', description: 'description'},
            {colorKey: '--customer-load-bar-2-bacground', colorValue: '#676cc2', description: 'description'},
            {colorKey: '--customer-load-bar-3-background', colorValue: '#51c2aa', description: 'description'},
            {colorKey: '--customer-form-button-border-color', colorValue: '#bbbbbb', description: 'description'},
            {colorKey: '--customer-form-button-color', colorValue: '#3d8cc2', description: 'description'},
            {colorKey: '--customer-form-disabled-button-background', colorValue: '#bdbdbd', description: 'description'},
            {colorKey: '--customer-download-background', colorValue: '#4dca54', description: 'description'},
            {colorKey: '--customer-login-search-field-background', colorValue: '#ffffff7a', description: 'description'},
            {colorKey: '--customer-user-profile-card-background', colorValue: '#fffdfd', description: 'description'},
            {colorKey: '--customer-user-profile-upload-background', colorValue: '#333333ba', description: 'description'},
            {colorKey: '--customer-inbox-email-background', colorValue: '#6c757d26', description: 'description'},
            {colorKey: '--customer-help-container-background', colorValue: '#ffffff', description: 'description'},
            {colorKey: '--customer-shipment-body-background', colorValue: '#f8f9faa1', description: 'description'},
            {colorKey: '--customer-button-barder-color', colorValue: '#3fa9e1', description: 'description'},
            {colorKey: '--customer-box-shadow-background-1', colorValue: 'rgba(0, 0, 0, 0.2)', description: 'description'},
            {colorKey: '--customer-box-shadow-background-2', colorValue: 'rgba(0, 0, 0, 0.14)', description: 'description'},
            {colorKey: '--customer-box-shadow-background-3', colorValue: 'rgba(0, 0, 0, 0.12)', description: 'description'},
            {colorKey: '--customer-home-alert-shadow', colorValue: '#2d794b', description: 'description'},
            {colorKey: '--customer-home-side-bar-border-color', colorValue: '#e0e0e0', description: 'description'},
            {colorKey: '--customer-home-search-placeholder-color', colorValue: '#b5b5b5', description: 'description'},
            {colorKey: '--customer-home-lert-success-background', colorValue: '#5ed490', description: 'description'},
            {
                colorKey: '--customer-home-lert-success-notification-close-border-color',
                colorValue: '#2d794b',
                description: 'description'
            },
            {colorKey: '--customer-home-alert-success-notification-close-color', colorValue: '#2d794b', description: 'description'},
            {colorKey: '--customer-home-alert-warning-background', colorValue: '#f56668', description: 'description'},
            {
                colorKey: '--customer-home-alert-warning-notification-close-border-color',
                colorValue: '#ba4849',
                description: 'description'
            },
            {colorKey: '--customer-home-alert-warning-notification-close-color', colorValue: '#ba4849', description: 'description'},
            {colorKey: '--customer-home-alert-info-background', colorValue: '#2e8bd8', description: 'description'},
            {
                colorKey: '--customer-home-alert-info-notification-close-border-color',
                colorValue: '#08345b',
                description: 'description'
            },
            {colorKey: '--customer-home-alert-danger-background', colorValue: '#e5a300', description: 'description'},
            {
                colorKey: '--customer-home-alert-danger-notification-close-border-color',
                colorValue: '#996d00',
                description: 'description'
            },
            {colorKey: '--customer-notifcation-icon-number-background', colorValue: 'indianred', description: 'description'},
            {colorKey: '--customer-notifcation-border-color', colorValue: '#cccccc', description: 'description'},
            {colorKey: '--customer-notifcation-background', colorValue: '#cedcf3', description: 'description'},
            {colorKey: '--customer-unreaded-notifcation-color', colorValue: '#333', description: 'description'},
            {colorKey: '--customer-user-options-color', colorValue: 'gray', description: 'description'},
            {colorKey: '--customer-unreaded-notifcation-background-shadow', colorValue: '#d6d8da4d', description: 'description'},
            {colorKey: '--customer-notifcation-empty-background', colorValue: '#edf2fa', description: 'description'},
            {colorKey: '--customer-dialog-header-color', colorValue: 'gray', description: 'description'},
            {colorKey: '--customer-dialog-header-border-color', colorValue: '#4091c7', description: 'description'},
            {colorKey: '--customer-dialog-body-color', colorValue: '#7b7a7a', description: 'description'},
            {colorKey: '--customer-inbox-replay-body-background', colorValue: '#f6f7f9', description: 'description'},
            {colorKey: '--customer-inbox-replay-body-border-color', colorValue: '#d2d2d2', description: 'description'},
            {
                colorKey: '--customer-inbox-message-list-container-background',
                colorValue: 'rgba(156, 156, 156, 0.1)',
                description: 'description'
            },
            {colorKey: '--customer-side-menu-number-background', colorValue: '#34495e', description: 'description'},
            {colorKey: '--customer-side-menu-container-hover-background', colorValue: 'lightgray', description: 'description'},
            {colorKey: '--customer-side-menu-title-hover', colorValue: '#a2a4c5', description: 'description'},
            {
                colorKey: '--customer-add-shipment-logo-background',
                colorValue: 'rgba(61, 140, 194, 0.11)',
                description: 'description'
            },
            {colorKey: '--customer-package-status-background', colorValue: '#ffffffc4', description: 'description'},
            {colorKey: '--customer-skeleton-line-background', colorValue: '#f6f6f694', description: 'description'},
            {colorKey: '--customer-skeleton-card-background', colorValue: '#f6f6f6', description: 'description'},
            {colorKey: '--customer-shipment-table-background', colorValue: '#f8f9faa1', description: 'description'},
            {colorKey: '--customer-shipment-table-border-color', colorValue: '#dedede', description: 'description'},
            {colorKey: '--customer-sign-up-sparoter-color', colorValue: 'rgba(138, 138, 138, 0.2)', description: 'description'},
            {colorKey: '--customer-demensions-field-input-color', colorValue: '#bce0fd', description: 'description'},
            {colorKey: '--customer-demensions-result-color', colorValue: '#2699fb', description: 'description'},
            {colorKey: '--customer-login-title-active-color', colorValue: '#585656', description: 'description'},
            {colorKey: '--customer-label-text-color', colorValue: 'gray', description: 'description'},
            {colorKey: '--customer-field-input-placeholder-color', colorValue: 'lightgray', description: 'description'},
            {colorKey: '--customer-table-mobile-card-background', colorValue: '#f9f9f9', description: 'description'}
        ];
    }
}
