import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {RoleGuard} from './role.guard';
import {AuthGuard} from './auth.guard';

@Injectable({providedIn: 'root'})
export class CheckLoginGuard implements CanActivate {
    constructor(private authenticationService: AuthenticationService,
                private roleGuard: RoleGuard,
                private authGuard: AuthGuard) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.authenticationService.isUserActive()) {
            return this.roleGuard.canActivate(route, state);
        } else {
            return this.authGuard.canActivate(route, state);
        }
    }
}
