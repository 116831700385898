<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="reset-password-container">
        <div class="row" style="justify-content: space-between;">
            <div class="title">
                <span>{{ 'GENERAL.ATTACHMENTS' | translate }}</span>
            </div>
            <div class="close-icon" (click)="closeModal()">
                <img src="../../assets/icons/close-x.svg" alt="" />
            </div>
        </div>
        <div></div>
        <div class="flex-1 input-fields">
            <div class="h-100 d-flex flex-column mt-5 mb-5" *ngIf="!isLoading">

                <div *ngFor="let att of attachments;let i = index" class="pt-2 pb-2 d-flex align-items-center">
                    <i class="fas fa-file-photo-o"></i>
                    <div class="name flex-1 cursor-pointer" (click)="openAttachment(att)">{{'GENERAL.ATTACHMENT' | translate}} {{i+1}}</div>
                    <div class="name cursor-pointer" style="color: red" (click)="deleteAttachment(att)"><i class="fas fa-trash"></i></div>
                </div>
                <div class="position-relative" *ngIf="!(attachments && attachments.length)">
                    <app-no-data-available-component>
                    </app-no-data-available-component>
                </div>
            </div>
            <div class="flex-2d-center" *ngIf="isLoading">
                <div class="m-5">
                    <i class="fas fa-spinner fa-pulse"></i>
                </div>
            </div>
        </div>
        <div class="row actions">
            <div class="cancel action-btn flex-2d-center" (click)="closeModal()">
                <span>{{ 'ACTIONS.CANCEL' | translate }}</span>
            </div>
        </div>
    </div>
</div>
