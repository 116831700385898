import { AbstractControl, ValidationErrors } from '@angular/forms';

export class InputAsStringValidator {
    static ignoreBlankSpace(control: AbstractControl): ValidationErrors | null {
        const regex =  /^\s+$/;
        const value = control.value as string;
        if (regex.test(value)) {
            return {ignoreBlankSpace : 'fill the field-input with a correct value'};
        }
        return null;
    }
}
