import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-no-data-available-component',
    templateUrl: 'no-data-available.component.html',
    styleUrls: ['no-data-available.component.scss']
})
export class NoDataAvailableComponent {
    @Input() message = 'ALERTS.NO_DATA_AVAILABLE';
}
