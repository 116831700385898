import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../users/services/users.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {setBarcodeReader} from '../../shared/services/helpers';
import {ShipmentsService} from '../services/shipments.service';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {playError} from '../../shared/behavior/sounds';
import {HubTitlePipe} from '../../shared/pipes/hub-title-pipe';

@Component({
    selector: 'app-unload-packages-table',
    templateUrl: './unload-packages-table.component.html',
    styleUrls: ['./unload-packages-table.component.scss']
})
export class UnloadPackagesTableComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public driverBarcode;
    public driverId = 0;
    public driverName = '';
    public hubId = 0;
    public hubName = '';
    public containerName = '';
    public codPackagesCount = 0;
    public codSum = 0;
    public initialCodSum = 0;
    public receivedCod = 0;
    public packages = [];
    public isScanning = false;
    public scannedPackages = [];
    public debounceUpdateCost;
    public isReading = false;
    public isCustody = false;
    public permissions = '';

    public pageNumber = 0;
    public totalRecords = 0;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private usersService: UsersService,
                private route: ActivatedRoute,
                private messageService: MessageService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private shipmentsService: ShipmentsService,
                private userService: UserService,
                private hubTitlePipe: HubTitlePipe,
                private rolesService: RolesService) {
    }

    ngOnInit() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.scannedPackages = [];
        this.isScanning = false;
        this.isLoading = true;
        this.route.queryParams.subscribe((queryParams) => {
            this.driverBarcode = queryParams.barcode;
        });
        this.isReading = true;
        setBarcodeReader((barcode) => {
            if (this.isReading) {
                this.barcodeReader(barcode);
            }
        });

    }

    ngOnDestroy(): void {
        this.isReading = false;
    }

    private barcodeReader(barcode) {
        if (!this.isScanning) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.getPackageByBarcode(barcode, 'ADMIN').subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.scanPackage(shipment);
                        this.isLoading = false;
                    }
                );
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    public getDriverPackages() {
        this.usersService.verifyDriverPackages(this.createParams(this.driverBarcode)).subscribe(
            (response: any) => { // redirect to  receive page
                this.driverName = response.driverName;
                this.packages = response.data;
                this.driverId = response.id;
                this.totalRecords = response.totalRecordsNo;
                this.isLoading = false;
            }
            , (error) => { // Driver not found
                this.isLoading = false;
            });
    }

    public createParams(barcode = '') {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1
        };
        if (barcode) {
            params['barcode'] = barcode;
        }
        return params;
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.getDriverPackages();
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    public startScanning() {
        this.isScanning = true;
    }

    public finishScanning() {
        const body = {barcode: this.driverBarcode};
        body['ids'] = this.scannedPackages;
        this.shipmentsService.unloadPackages(body).subscribe(
            (response) => {
                this.translateService.get(
                    'ALERTS.CHANGED_STATUS'
                )
                    .subscribe(
                        (message) => {
                            this.confirmationService.confirm({
                                message: message,
                                rejectVisible: false,
                                acceptLabel: 'OK'
                            });
                        }
                    );
                this.resetScanning(false);
            }, () => {
            }
        );
    }

    public resetScanning(showConfirm = true) {
        if (showConfirm) {
            this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.receivedCod = 0;
                        this.codSum = this.initialCodSum;
                        this.isScanning = false;
                        this.scannedPackages = [];
                        this.getDriverPackages();
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO'],
                });
            });
        } else {
            this.receivedCod = 0;
            this.codSum = this.initialCodSum;
            this.isScanning = false;
            this.scannedPackages = [];
            this.getDriverPackages();
        }
    }

    scanPackage(shipment) {
        if (shipment) {
            if (!this.scannedPackages.includes(shipment.id)) {
                this.receivedCod += shipment.cod;
                this.codSum -= shipment.cod;
                this.scannedPackages.unshift(shipment.id);
            }
            return;
        }
        if (this.driverId) {
            this.translateService.get(['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER'],
                    rejectVisible: false,
                    acceptLabel: 'OK'
                });
                return;
            });
        } else {
            this.translateService.get(['ACCOUNTING.PACKAGE_NOT_IN_HUB']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: this.hubTitlePipe.transform(res['ACCOUNTING.PACKAGE_NOT_IN_HUB']),
                    rejectVisible: false,
                    acceptLabel: 'OK'
                });
                return;
            });
        }
    }

    removePackage(shipment) {
        this.scannedPackages.splice(this.scannedPackages.indexOf(shipment.id), 1);
        this.receivedCod -= shipment.cod;
        this.codSum += shipment.cod;
    }

    isPackageScanned(id) {
        const index = this.scannedPackages.indexOf(id);
        return index !== -1;
    }

    enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.driverId = this.driverId;
        modal.componentInstance.isAdmin = true;
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.scanPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public downloadMultiplePackages() {
        this.isLoading = true;
        const selectedPackages = this.scannedPackages;
        this.shipmentsService.downloadMultiple(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

}
