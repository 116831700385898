import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PricingService} from '../shippings-rate/services/pricing.service';
import {PricingModel} from './pricing.model';
import {UserService} from '../shared/services/user.service';
import {Inplace} from 'primeng/inplace';
import {Fieldset} from 'primeng/fieldset';
import {ContainersService} from '../containers/services/containers.service';
import {ServiceTypesService} from '../administration/services/service-types.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ZonesService} from '../administration/services/zones.service';
import {InputFieldComponent} from '../shared/components/input-field/input-field.component';
import {ServiceTypeModel} from '../administration/models/service-type.model';
import {VehicleTypeModel} from '../shared/models/container.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ChooseExistingComponent} from '../shipment/choose-existing/choose-existing.component';
import {UserModel} from '../shared/models/user.model';
import {AuthenticationService} from '../shared/services/authentication.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'app-pricings',
    templateUrl: './pricings.component.html',
    styleUrls: ['./pricings.component.scss']
})
export class PricingsComponent implements OnInit {

    currentLang = 'en';
    isLoading = false;
    isUpdating = false;

    pricings: PricingModel[] = [];
    fetchedPricings: PricingModel[] = [];
    triggeredPricing: PricingModel;
    expandedRow: HTMLSpanElement;
    displayedInplace: Inplace;
    displayedInplaceValue;

    toggledFieldSet: Fieldset;

    serviceTypes: any[];
    serviceTypesNames: { [id: string]: string } = {};
    vehicleTypes: any[];
    searchContent = '';
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    isReceivingPointEnabled = false;
    isShowNewPricing = false;
    isEnableExportingBetweenHubs = false;

    newPricingForm: FormGroup;
    editRegionForm: FormGroup;

    incompletedPricingsForm: FormGroup;
    incompletedPricings: { [id: string]: AbstractControl };
    regionOptions: any;

    private newPricingOriginRegion: InputFieldComponent;
    typeId: number;
    bodyIdName: string;
    customerName: string;

    idQueryParam: string;
    typeName = 'COMPANY';
    calculateCostPerWeight = false;

    @ViewChild('newPricingOriginRegion')
    set newPricingOriginRegionRef(ref: InputFieldComponent) {
        if (ref) {
            this.newPricingOriginRegion = ref;
        }
    }

    constructor(private translateService: TranslateService,
                private pricingService: PricingService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private containersService: ContainersService,
                private serviceTypesService: ServiceTypesService,
                private formBuilder: FormBuilder,
                private zonesService: ZonesService,
                private changeDetectorRef: ChangeDetectorRef,
                private route: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isReceivingPointEnabled = this.userService.userInfo.isReceivingPointEnabled;
        this.calculateCostPerWeight = this.userService.userInfo.isEnablePricingPerkg;
        this.currentLang = this.translateService.currentLang;
        this.route.queryParams.subscribe((queryParams: {customerName: string, idQueryParam: string, type: string}) => {
            this.customerName = queryParams.customerName;
            this.idQueryParam = queryParams.idQueryParam;
            this.typeName = queryParams.type ? queryParams.type : 'COMPANY';
            this.bodyIdName = this.typeName === 'PRICING_LIST' ? 'entityId' : 'customerId';
        });
        this.route.params.subscribe(
            (params: Params) => {
                this.typeId = params.id ?  JSON.parse(params.id) : null;
                this.initVehicleTypes();
                this.initForms();
            }
        );
    }

    private initServiceTypes() {
        this.serviceTypesService.getServiceTypes('?page=1&pageSize=50&is-get-vehicle-types=true').subscribe(res => {
            this.serviceTypes = res['data'];
            this.serviceTypes.forEach(serviceType => {
                this.serviceTypesNames[serviceType.id.toString()] = this.currentLang === 'en' ? serviceType.name : serviceType.arabicName;
            });
            this.getPricings(true);
        }, (error) => {
            this.isLoading = false;
        });
    }

    private initVehicleTypes() {
        this.containersService.getVehicleTypes().subscribe((res: any[]) => {
            this.vehicleTypes = res;
            this.initServiceTypes();
        }, (error) => {
            this.isLoading = false;
        });
    }

    private initForms() {
        this.newPricingForm = this.formBuilder.group({
            originRegion: [null, Validators.required],
            destinationRegion: [null, Validators.required],
            price: [null, Validators.required],
            returnedPrice: [null, Validators.required],
            rejectionBeforeArrivalCost: [null, Validators.required],
            maxFreeWeight: [],
            additionalCostPerKg: [],
            receivingPointPrice: []
        });

        if (this.isReceivingPointEnabled) {
            this.newPricingForm.get('receivingPointPrice').setValidators(Validators.required);
        }
        if (this.calculateCostPerWeight) {
            this.newPricingForm.get('maxFreeWeight').setValidators([Validators.required, Validators.min(0)]);
            this.newPricingForm.get('additionalCostPerKg').setValidators([Validators.required, Validators.min(0)]);
        }
        this.editRegionForm = this.formBuilder.group({
            originRegion: [],
            destinationRegion: []
        });
    }

    onSearch() {
        this.getPricings(true);
    }

    onNewPricing(event) {
        if (this.toggledFieldSet) {
            // this.toggledFieldSet.collapse(event);
        }
        this.isShowNewPricing = true;
        this.changeDetectorRef.detectChanges();
        this.newPricingOriginRegion.scrollAndFocus();
    }

    onHideNewPricing() {
        this.newPricingForm.patchValue({});
        this.isShowNewPricing = false;
    }

    getPricings(forceFetch = false) {
        this.isLoading = true;
        const urlQuery = this.idQueryParam ?  `?${this.idQueryParam}=${this.typeId}` : '';
        this.pricingService.getServiceTypesPrices(urlQuery).subscribe(response => {
            this.fetchedPricings = response['defaultPrices'];
            if (!forceFetch) {
                this.pricings.push(...this.fetchedPricings);
            } else {
                this.pricings = this.fetchedPricings;
            }
            if (this.typeId) {
                const customerPricingsMap = {};
                this.pricings.forEach(pricing => {
                    if (!customerPricingsMap[pricing.originRegionId]) {
                        customerPricingsMap[pricing.originRegionId] = {};
                    }
                    customerPricingsMap[pricing.originRegionId][pricing.destinationRegionId] = true;
                });
                this.pricingService.getServiceTypesPrices().subscribe(defaultResponse => {
                    const defaultPricings = defaultResponse['defaultPrices'];
                    const defaultPricingsMap: {
                        [originRegionId: number]: {
                            [destinationRegionId: number]: {
                                [serviceTypeId: number]: {
                                    [vehicleTypeId: number]: PricingModel
                                }
                            }
                        }
                    } = {};
                    defaultPricings.forEach(pricing => {
                        if (!defaultPricingsMap[pricing.originRegionId]) {
                            defaultPricingsMap[pricing.originRegionId] = {};
                        }
                        if (!defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId]) {
                            defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId] = {};
                        }
                        if (pricing.serviceTypeIdToCompanyPricesMap) {
                            Object.keys(pricing.serviceTypeIdToCompanyPricesMap).forEach(serviceTypeId => {
                                if (!defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId][serviceTypeId]) {
                                    defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId][serviceTypeId] = {};
                                }
                                pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId].forEach(vehicleTypePricing => {
                                    defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId]
                                        [serviceTypeId][vehicleTypePricing.vehicleTypeId] = vehicleTypePricing;
                                });
                            });
                        }
                    });

                    this.pricings.push(...(defaultPricings.filter(pricing => {
                        return !(customerPricingsMap[pricing.originRegionId] &&
                            customerPricingsMap[pricing.originRegionId][pricing.destinationRegionId]);
                    })));

                    this.pricings.forEach(pricing => {
                        if (pricing.type === 'CUSTOMER') {
                            const defaultServiceTypesPricingsMap =
                                defaultPricingsMap ?
                                    defaultPricingsMap[pricing.originRegionId] ?
                                        defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId] ?
                                            defaultPricingsMap[pricing.originRegionId][pricing.destinationRegionId] :
                                            null :
                                        null :
                                    null;

                            if (defaultServiceTypesPricingsMap) {
                                if (!pricing.serviceTypeIdToCompanyPricesMap) {
                                    pricing.serviceTypeIdToCompanyPricesMap = {};
                                }
                                Object.entries(defaultServiceTypesPricingsMap).forEach(([serviceTypeId, defaultVehicleTypesPricingsMap]) => {
                                    if (pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId]) {
                                        Object.entries(defaultVehicleTypesPricingsMap).forEach(([vehicleTypeId, defaultVehicleTypePricing]) => {
                                            if (pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId].every(vehicleTypePricing => {
                                                return vehicleTypePricing.vehicleTypeId.toString() !== vehicleTypeId;
                                            })) {
                                                pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId].push(defaultVehicleTypePricing);
                                            }
                                        });
                                    } else {
                                        pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId] = Object.values(defaultVehicleTypesPricingsMap);
                                    }
                                });
                            }
                        }
                    });
                    this.filterPricings();
                    this.initIncompletedPricingsForm();
                }, (error) => {
                    this.isLoading = false;
                });
            } else {
                this.filterPricings();
                this.initIncompletedPricingsForm();
            }
        }, (error) => {
            this.isLoading = false;
        });
    }

    filterPricings() {
        this.pricings.forEach((pricing) => {

            if (pricing.serviceTypeIdToCompanyPricesMap) {

                Object.keys(pricing.serviceTypeIdToCompanyPricesMap).forEach(serviceTypeId => {
                    if (this.serviceTypes.every(serviceType => serviceType.id.toString() !== serviceTypeId)) {
                        delete pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId];
                    }
                });

                this.serviceTypes.forEach((serviceType: ServiceTypeModel) => {
                    const serviceTypePricings = pricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()];
                    if (serviceTypePricings) {
                        pricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()] =
                            serviceTypePricings.filter(vehicleTypePricing =>
                                serviceType.vehicleTypes.some(vehicleType => vehicleType.id === vehicleTypePricing.vehicleTypeId)
                            );
                        if (pricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()].length === 0) {
                            delete pricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()];
                        }
                    }
                });
            }
        });
    }

    triggerPanel(event, panel) {
        panel.toggle(event);
    }

    onEditPricing(event: MouseEvent, inplace: Inplace) {
        if (inplace.active) {
            inplace.deactivate(event);
        } else {
            inplace.activate(event);
        }
    }

    toggleFieldSet(event: MouseEvent, fieldset: Fieldset) {
        // fieldset.collapse(event);
        // fieldset.toggle(event);
    }

    setExpandedRow(newRow: HTMLSpanElement) {

        if (!this.incompletedPricingsForm) {
            this.initIncompletedPricingsForm();
        }

        if (this.expandedRow === newRow) {
            this.expandedRow = null;
            return;
        }

        if (this.expandedRow) {
            this.expandedRow.click();
        }

        this.expandedRow = newRow;
    }

    showServiceTypesPanel(event: MouseEvent, pricing: PricingModel, serviceTypesPanel: OverlayPanel) {
        this.triggeredPricing = pricing;
        serviceTypesPanel.toggle(event);
    }

    addServiceType(event, serviceType: ServiceTypeModel, panel: OverlayPanel) {
        if (!this.incompletedPricingsForm) {
            this.initIncompletedPricingsForm();
        }
        if (!this.triggeredPricing.serviceTypeIdToCompanyPricesMap) {
            this.triggeredPricing.serviceTypeIdToCompanyPricesMap = {};
        }
        this.triggeredPricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()] = [];
        const vehicleTypesPricing = this.incompletedPricingsForm
            .get(this.triggeredPricing.id.toString())
            .get(serviceType.id.toString())
            .get('vehicleTypesPricing') as FormArray;

        serviceType.vehicleTypes.forEach(vehicleType => {
            const vehicleForm = this.formBuilder.group({
                id: vehicleType.id,
                vehicleTypeName: [vehicleType.name],
                price: [null, [Validators.required, Validators.min(0)]],
                returnedPrice: [null, [Validators.required, Validators.min(0)]],
                rejectionBeforeArrivalCost: [null, [Validators.required, Validators.min(0)]],
                maxFreeWeight: [null],
                additionalCostPerKg: [null],
                receivingPointPrice: [null]
            });

            if (this.isReceivingPointEnabled) {
                vehicleForm.get('receivingPointPrice').setValidators([Validators.required, Validators.min(0)]);
            }
            if (this.calculateCostPerWeight) {
                vehicleForm.get('maxFreeWeight').setValidators([Validators.required, Validators.min(0)]);
                vehicleForm.get('additionalCostPerKg').setValidators([Validators.required, Validators.min(0)]);
            }
            vehicleForm.markAsDirty();
            vehicleForm.markAsTouched();
            vehicleForm.updateValueAndValidity();
            vehicleTypesPricing.push(vehicleForm);
        });
        this.changeDetectorRef.detectChanges();
        panel.hide();
    }


    onActivateInplace(event, inplace: Inplace, valueToBeEdited) {
        this.displayedInplaceValue = valueToBeEdited;

        if (this.displayedInplace && this.displayedInplace !== inplace) {
            this.displayedInplace.deactivate(event);
        }

        this.displayedInplace = inplace;
    }

    toggleInplace(event: any, inplace: Inplace, pricing?: PricingModel, pricingField?: string, saveChanges?) {
        if (inplace.active) {
            if (saveChanges && pricing[pricingField] !== this.displayedInplaceValue) {
                this.updatePricing(pricing, inplace, pricingField);
            } else {
                if (pricing) {
                    this.displayedInplaceValue = pricing[pricingField];
                }
                inplace.deactivate(event);
            }
        } else {
            inplace.activate(event);
        }
    }

    onActivateRegionInplace($event, inplace: Inplace, pricing: PricingModel, isOriginRegion = true) {
        if (isOriginRegion) {
            this.editRegionForm.get('originRegion').patchValue({
                label: pricing.originRegionName,
                value: pricing.originRegionId
            });
        } else {
            this.editRegionForm.get('destinationRegion').patchValue({
                label: pricing.destinationRegionName,
                value: pricing.destinationRegionId
            });
        }

        if (this.displayedInplace) {
            this.displayedInplace.deactivate(event);
        }

        this.displayedInplace = inplace;
    }

    toggleRegionInplace(event: any, inplace: Inplace, pricing?: PricingModel, isOrigin = true, saveChanges = false) {
        if (inplace.active) {
            if (saveChanges && (pricing.originRegionId !== this.editRegionForm.get('originRegion').value.value
                || pricing.destinationRegionId !== this.editRegionForm.get('destinationRegion').value.value)) {
                this.updatePricingRegion(pricing, inplace, isOrigin);
            } else {
                inplace.deactivate(event);
            }
        } else {
            inplace.activate(event);
        }
    }

    onDeactivateInplace(event: any, inplace: Inplace) {

    }

    onToggleFieldSet(event, fieldset: Fieldset) {
        if (this.toggledFieldSet && this.toggledFieldSet !== fieldset) {
            // this.toggledFieldSet.collapse(event);
        }

        this.toggledFieldSet = fieldset;
    }

    onDeletePricing(pricing: any) {

        this.confirmationService.confirm({
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
            message: this.translateService.instant('PRICING.ALERTS.CONFIRM_DELETE'),
            accept: () => {
                this.pricingService.deleteServicePricingList(pricing.id || pricing.key).subscribe(value => {
                    this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    this.pricings = [];
                    this.getPricings(true);
                });
            }
        });
    }

    onSavePricing(pricing?: PricingModel) {
        this.isLoading = true;
        let reqBody = {};
        if (pricing) {
            reqBody = {
                originRegionId: pricing.originRegionId,
                destinationRegionId: pricing.destinationRegionId,
                price: pricing.price,
                returnedPrice: pricing.returnedPrice,
                rejectionBeforeArrivalCost: pricing.rejectionBeforeArrivalCost,
                type: this.typeName
            };

            if (this.isReceivingPointEnabled) {
                reqBody['receivingPointPrice'] = pricing.receivingPointPrice;
            }
            if (this.calculateCostPerWeight) {
                reqBody['maxFreeWeight'] = pricing.maxFreeWeight;
                reqBody['additionalCostPerKg'] = pricing.additionalCostPerKg;
            }
        } else {
            const formData = this.newPricingForm.getRawValue();
            reqBody = {
                originRegionId: formData.originRegion.value.id,
                destinationRegionId: formData.destinationRegion.value.id,
                price: formData.price,
                returnedPrice: formData.returnedPrice,
                rejectionBeforeArrivalCost: formData.rejectionBeforeArrivalCost,
                type: this.typeName
            };

            if (this.isReceivingPointEnabled) {
                reqBody['receivingPointPrice'] = formData.receivingPointPrice;
            }
            if (this.calculateCostPerWeight) {
                reqBody['maxFreeWeight'] = formData.maxFreeWeight;
                reqBody['additionalCostPerKg'] = formData.additionalCostPerKg;
            }
        }

        if (this.typeId) {
            reqBody[this.bodyIdName] = this.typeId;
            this.pricingService.saveCustomerPrice(reqBody).subscribe((res) => {
                this.translateService.get('PRICING.ALERTS.ADDED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                this.onHideNewPricing();
                this.getPricings(true);
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
            });
        } else {
            this.pricingService.saveCompanyPrice(reqBody, 'COMPANY').subscribe((res) => {
                this.translateService.get('PRICING.ALERTS.ADDED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                this.onHideNewPricing();
                this.getPricings(true);
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
            });
        }
    }

    getRegions(query) {
        let getRegions = this.zonesService.getRegions(true, query);
        if (this.isEnableExportingBetweenHubs) {
            getRegions = this.zonesService.getCitiesList('?page=1&pageSize=20');
        }

        getRegions.subscribe(
            response => {
                this.regionOptions = response['data'].map(region => {
                    return {
                        label: this.currentLang === 'en' ? region.name : region.arabicName,
                        value: region
                    };
                });
            }
        );



            // this.zonesService.getRegions(true, query).subscribe(response => {
            //     this.regionOptions = response['data'].map(region => {
            //         return {
            //             label: this.currentLang === 'en' ? region.name : region.arabicName,
            //             value: region
            //         };
            //     });
            // });
    }

    private updatePricing(pricing: PricingModel, inplace: Inplace, pricingField: string) {
        this.isUpdating = true;
        const reqBody = {
            originRegionId: pricing.originRegionId,
            destinationRegionId: pricing.destinationRegionId,
            price: pricing.price,
            returnedPrice: pricing.returnedPrice,
            rejectionBeforeArrivalCost: pricing.rejectionBeforeArrivalCost,
            vehicleTypeId: pricing.vehicleTypeId,
            serviceTypeId: pricing.serviceTypeId,
            type: this.typeName
        };

        if (this.isReceivingPointEnabled) {
            reqBody['receivingPointPrice'] = pricing.receivingPointPrice;
        }
        if (this.calculateCostPerWeight) {
            reqBody['maxFreeWeight'] = pricing.maxFreeWeight;
            reqBody['additionalCostPerKg'] = pricing.additionalCostPerKg;
        }

        if (this.typeId) {
            reqBody[this.bodyIdName] = this.typeId;
        }

        reqBody[pricingField] = this.displayedInplaceValue;

        this.pricingService.editCustomerPrice(reqBody, pricing.id).subscribe(response => {
            this.translateService.get('PRICING.ALERTS.UPDATE_SUCCESSFULLY').subscribe(value => {
                this.messageService.add({
                    severity: 'success',
                    detail: value
                });
            });
            this.isUpdating = false;
            pricing[pricingField] = this.displayedInplaceValue;
            inplace.deactivate(event);
        }, (error) => {
            this.isUpdating = false;
        });
    }

    private updatePricingRegion(pricing: PricingModel, inplace: Inplace, isOrigin: boolean) {
        this.isUpdating = true;
        const reqBody = {
            originRegionId: pricing.originRegionId,
            destinationRegionId: pricing.destinationRegionId,
            price: pricing.price,
            returnedPrice: pricing.returnedPrice,
            rejectionBeforeArrivalCost: pricing.rejectionBeforeArrivalCost,
            type: this.typeName
        };

        if (this.isReceivingPointEnabled) {
            reqBody['receivingPointPrice'] = pricing.receivingPointPrice;
        }
        if (this.calculateCostPerWeight) {
            reqBody['maxFreeWeight'] = pricing.maxFreeWeight;
            reqBody['additionalCostPerKg'] = pricing.additionalCostPerKg;
        }

        if (this.typeId) {
            reqBody[this.bodyIdName] = this.typeId;
        }

        const regionFormDate = this.editRegionForm.getRawValue();

        if (isOrigin) {
            reqBody.originRegionId = regionFormDate.originRegion.value.id ? regionFormDate.originRegion.value.id : reqBody.originRegionId;
        } else {
            reqBody.destinationRegionId = regionFormDate.destinationRegion.value.id ? regionFormDate.destinationRegion.value.id : reqBody.destinationRegionId;
        }

        this.pricingService.editCustomerPrice(reqBody, pricing.id).subscribe(response => {
            this.translateService.get('PRICING.ALERTS.UPDATE_SUCCESSFULLY').subscribe(value => {
                this.messageService.add({
                    severity: 'success',
                    detail: value
                });
            });
            this.isUpdating = false;
            if (isOrigin) {
                pricing.originRegionName = regionFormDate.originRegion.label;
                pricing.originRegionId = regionFormDate.originRegion.value.id ? regionFormDate.originRegion.value.id : reqBody.originRegionId;
            } else {
                pricing.destinationRegionName = regionFormDate.destinationRegion.label;
                pricing.destinationRegionId = regionFormDate.destinationRegion.value.id ? regionFormDate.destinationRegion.value.id : reqBody.originRegionId;
            }
            inplace.deactivate(event);
        }, (error) => {
            this.isUpdating = false;
        });
    }

    getVehicleTypesForm(pricing, serviceTypeId): any {
        const pricingForm = this.incompletedPricingsForm.get(pricing.id.toString());
        if (pricingForm) {
            this.incompletedPricings = (pricingForm.get(serviceTypeId.toString()).get('vehicleTypesPricing') as FormGroup).controls;
        }
        this.incompletedPricings = null;
    }

    trackForm(index, item: AbstractControl) {
        return item.get('id').value;
    }

    private initIncompletedPricingsForm() {
        this.incompletedPricingsForm = this.formBuilder.group({});
        this.pricings.forEach((pricing) => {
            this.incompletedPricingsForm.addControl(pricing.id.toString(), this.formBuilder.group({}));
            this.serviceTypes.forEach((serviceType: ServiceTypeModel) => {
                const serviceTypeForm = this.formBuilder.group({
                    serviceTypeName: [serviceType.name],
                    vehicleTypesPricing: this.formBuilder.array([])
                });
                if (pricing.serviceTypeIdToCompanyPricesMap) {
                    const existingServiceTypePricings = pricing.serviceTypeIdToCompanyPricesMap[serviceType.id.toString()];
                    if (existingServiceTypePricings && existingServiceTypePricings.length) {
                        serviceType.vehicleTypes.forEach((vehicleType: VehicleTypeModel) => {
                            if (existingServiceTypePricings.every(vehicleTypePricing =>
                                vehicleTypePricing.vehicleTypeId !== vehicleType.id)) {

                                const vehicleForm = this.formBuilder.group({
                                    id: vehicleType.id,
                                    vehicleTypeName: [vehicleType.name],
                                    price: [null, [Validators.required, Validators.min(0)]],
                                    returnedPrice: [null, [Validators.required, Validators.min(0)]],
                                    rejectionBeforeArrivalCost: [null, [Validators.required, Validators.min(0)]],
                                    maxFreeWeight: [null],
                                    additionalCostPerKg: [null],
                                    receivingPointPrice: [null]
                                });

                                if (this.isReceivingPointEnabled) {
                                    vehicleForm.get('receivingPointPrice').setValidators([Validators.required, Validators.min(0)]);
                                }
                                if (this.calculateCostPerWeight) {
                                    vehicleForm.get('maxFreeWeight').setValidators([Validators.required, Validators.min(0)]);
                                    vehicleForm.get('additionalCostPerKg').setValidators([Validators.required, Validators.min(0)]);
                                }
                                vehicleForm.markAsDirty();
                                vehicleForm.markAsTouched();
                                vehicleForm.updateValueAndValidity();
                                (serviceTypeForm.get('vehicleTypesPricing') as FormArray).push(vehicleForm);
                            }
                        });
                    }
                }

                (this.incompletedPricingsForm.get(pricing.id.toString()) as FormGroup).addControl(serviceType.id.toString(), serviceTypeForm);
            });
        });
        this.isLoading = false;
    }

    onSubmitVehicleTypePricing(pricing: PricingModel, serviceTypeId: string, vehiclePricing: FormGroup | PricingModel, vehicleIndex: number) {
        this.isUpdating = true;
        let vehiclePricingData: any = {};
        if (vehiclePricing instanceof FormGroup) {
            const vehicleFormData = vehiclePricing.getRawValue();
            vehiclePricingData = {
                vehicleTypeId: vehicleFormData.id,
                vehicleTypeName: vehicleFormData.vehicleTypeName,
                price: vehicleFormData.price,
                returnedPrice: vehicleFormData.returnedPrice,
                rejectionBeforeArrivalCost: vehicleFormData.rejectionBeforeArrivalCost,
                additionalCostPerKg: vehicleFormData.additionalCostPerKg,
                maxFreeWeight: vehicleFormData.maxFreeWeight,
                receivingPointPrice: vehicleFormData.receivingPointPrice
            };
        } else {
            vehiclePricingData = vehiclePricing;
        }

        const reqBody = {
            originRegionId: pricing.originRegionId,
            destinationRegionId: pricing.destinationRegionId,
            serviceTypeId: parseInt(serviceTypeId, 10),
            vehicleTypeId: vehiclePricingData.vehicleTypeId,
            price: vehiclePricingData.price,
            returnedPrice: vehiclePricingData.returnedPrice,
            rejectionBeforeArrivalCost: vehiclePricingData.rejectionBeforeArrivalCost,
            type: this.typeName
        };

        if (this.isReceivingPointEnabled) {
            reqBody['receivingPointPrice'] = vehiclePricingData.receivingPointPrice;
        }
        if (this.calculateCostPerWeight) {
            reqBody['maxFreeWeight'] = vehiclePricingData.maxFreeWeight;
            reqBody['additionalCostPerKg'] = vehiclePricingData.additionalCostPerKg;
        }

        if (this.typeId) {
            reqBody[this.bodyIdName] = this.typeId;
            this.pricingService.saveCustomerPrice(reqBody).subscribe(response => {
                this.translateService.get('PRICING.ALERTS.ADDED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId].push({
                    vehicleTypeId: vehiclePricingData.vehicleTypeId,
                    vehicleTypeName: vehiclePricingData.vehicleTypeName,
                    id: response['id'],
                    serviceTypeId: parseInt(serviceTypeId, 10),
                    originRegionId: pricing.originRegionId,
                    destinationRegionId: pricing.destinationRegionId,
                    price: vehiclePricingData.price,
                    returnedPrice: vehiclePricingData.returnedPrice,
                    rejectionBeforeArrivalCost: vehiclePricingData.rejectionBeforeArrivalCost,
                    receivingPointPrice: vehiclePricingData.receivingPointPrice,
                    maxFreeWeight: vehiclePricingData.maxFreeWeight,
                    additionalCostPerKg: vehiclePricingData.additionalCostPerKg,
                    type: 'CUSTOMER'
                });
                if (vehiclePricing instanceof FormGroup) {
                    this.deleteVehicleForm(pricing, serviceTypeId, vehicleIndex);
                } else {
                    pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId].splice(vehicleIndex, 1);
                }
                this.isUpdating = false;
            }, (error) => {
                this.isUpdating = false;
            });
        } else {
            this.pricingService.saveCompanyPrice(reqBody, 'COMPANY').subscribe(response => {
                this.translateService.get('PRICING.ALERTS.ADDED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                pricing.serviceTypeIdToCompanyPricesMap[serviceTypeId].push({
                    vehicleTypeId: vehiclePricingData.vehicleTypeId,
                    vehicleTypeName: vehiclePricingData.vehicleTypeName,
                    id: response['id'],
                    serviceTypeId: parseInt(serviceTypeId, 10),
                    originRegionId: pricing.originRegionId,
                    destinationRegionId: pricing.destinationRegionId,
                    price: vehiclePricingData.price,
                    returnedPrice: vehiclePricingData.returnedPrice,
                    rejectionBeforeArrivalCost: vehiclePricingData.rejectionBeforeArrivalCost,
                    receivingPointPrice: vehiclePricingData.receivingPointPrice,
                    maxFreeWeight: vehiclePricingData.maxFreeWeight,
                    additionalCostPerKg: vehiclePricingData.additionalCostPerKg,
                    type: 'COMPANY'
                });
                this.deleteVehicleForm(pricing, serviceTypeId, vehicleIndex);
                this.isUpdating = false;
            }, (error) => {
                this.isUpdating = false;
            });
        }


    }

    private deleteVehicleForm(pricing: PricingModel, serviceTypeId: string, vehicleIndex: number) {
        const vehiclePricingArray = this.incompletedPricingsForm
            .get(pricing.id.toString())
            .get(serviceTypeId)
            .get('vehicleTypesPricing') as FormArray;

        vehiclePricingArray.removeAt(vehicleIndex);
    }

    onCustomerCustomizePricing(event: MouseEvent) {
        const modal = this.modalService.open(ChooseExistingComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.result.then(
            (data: { isSuccess: boolean, user: UserModel }) => {
                if (data.isSuccess) {
                    const user = data.user;
                    this.router.navigate([this.authenticationService.companyName + '/home/' + 'pricing-service-types/' + user.id], {
                        queryParams:
                            {
                                customerName: user.fullName + (user.businessName ? ` (${user.businessName})` : ''),
                                idQueryParam: 'customer-id',
                                type: 'CUSTOMER'
                            }
                    });
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    isDefaultPricing(pricing: PricingModel) {
        return this.typeId && pricing.type === 'COMPANY';
    }

    onSaveDefaultPricing(pricing: PricingModel) {
        this.onSavePricing(pricing);
    }
}
