import {Component, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../reports-constants';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'app-administrative-reports',
    templateUrl: './administrative-reports.component.html',
    styleUrls: ['./administrative-reports.component.scss']
})
export class AdministrativeReportsComponent implements OnInit {

    public report = '';

    public timePeriodReports = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.TIME_PERIOD_REPORTS;
    public timePeriodReportsAPI = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.TIME_PERIOD_REPORTS_API;

    public customersActivityReport = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.CUSTOMERS_ACTIVITY_REPORT;
    public customersActivityReportAPI = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.CUSTOMERS_ACTIVITY_REPORT_API;

    public activityReport = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.ACTIVITY_REPORT;
    public activityReportAPI = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.ACTIVITY_REPORT_API;

    constructor(private translate: TranslateService, private route: ActivatedRoute) {
        this.addTotal = this.addTotal.bind(this);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.report = params.report;
        });
    }

    public addTotal(response) {
        if (response && response.data && response.data.table) {
            const total = {
                month: '',
                lastYearPkgsCount: response.data.lastYearSum,
                thisYearPkgsCount: response.data.thisYearSum
            };
            this.translate.stream('ADMINISTRATION.TOTAL').subscribe((res: any) => {
                total.month = res;
            });
            response.data.table.push(total);
            return response.data.table;
        }
        return [];
    }
}

