<div [ngClass]="{'page-ltr': currentLang === 'en'}">

<div class="faq-container">
  <div class="d-flex faq-header mb-4">
    <div class="add-faq d-flex" *ngIf="isSuperAdmin"
         (click)="openAddFaqDialog()">
      <div class="icon">
        <img src="../../../assets/icons/plus.svg" alt=""/>
      </div>
      <div class="label">
        <span>{{'FAQ.ADD_FAQ' | translate}}</span>
      </div>
    </div>

    <div>
      <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..."
             [formControl]="searchControl"
             [(ngModel)]="searchContent">
    </div>

  </div>

  <div *ngIf="!faqData.length" class="system-faq-table-container">
    <app-no-data-available-component></app-no-data-available-component>
  </div>

  <div class="system-faq-table-container"
       (scroll)="onsScrollFaqTable($event)" #tableContainer>
    <div class="no-data" *ngIf="spinnerState == spinnerStates.EMPTY">
      <span>{{ 'ALERTS.NO_DATA_AVAILABLE' | translate }}</span>
    </div>

    <div class="card-item" *ngFor="let faq of faqData;" #cardItem>
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex flex-column cursor-pointer p-4 w-100">
          <div class="d-flex justify-content-between pb-3" (click)="toggleClass(cardItem, 'open')">
            <div class="d-flex align-items-center">
              <a class="question">{{'FAQ.QUESTION' | translate}}: {{currentLang == 'ar' ? faq.arabicQuestion : faq.question}}</a>
            </div>
            <div class="p-2">
              <i class="fas fa-chevron-right arrow-icon"></i>
            </div>
          </div>
          <div class="question-answer d-flex justify-content-between" #answerContainer>
            <div class="max-width-75 d-flex flex-column">
              <div class="answer-content">{{'FAQ.ANSWER' | translate}}: {{currentLang == 'ar' ? faq.arabicAnswer : faq.answer}}</div>
              <div class="question-date">{{'FAQ.DATE' | translate}}: {{ faq.createdDate | date : 'dd/MM/yyyy' }}</div>
            </div>
            <div class="d-flex justify-content-center align-items-center more-action-trigger" *ngIf="isSuperAdmin">
              <div class="cursor-pointer p-2"
                   (click)="triggerFaqDropdown($event, faqOptions, 0, faq)">
                <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-card" *ngIf="spinnerState == spinnerStates.LOADING">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div style="visibility: hidden" #loadingTrigger></div>
  </div>
</div>
  <p-overlayPanel #faqOptions [dismissable]="true" styleClass="faq-popover">
    <div class="row-action-container">
      <div
              class="action cursor-pointer more-action-item" (click)="editFaq()">
        <img class="edit-button" src="../../../assets/icons/manage_shipments/edit.svg"
             alt="icon"/>
        {{'FAQ.EDIT' | translate}}
      </div>
      <div
              class="action cursor-pointer more-action-item" (click)="removeFaq()">
        <img class="remove-button" src="../../../assets/icons/manage_shipments/remove.svg"
             alt="icon"/>
        {{'FAQ.REMOVE' | translate | packageTitle}}
      </div>
    </div>
  </p-overlayPanel>
</div>
