import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {MessageService} from 'primeng/api';
import {ApplicationStateService} from '../../services/application-state.service';
import {TranslateService} from '@ngx-translate/core';

declare var window: any;

@Component({
    selector: 'app-package-print-dialog-component',
    templateUrl: 'package-print-dialog.component.html',
    styleUrls: ['package-print-dialog.component.scss']
})
export class PackagePrintDialogComponent {
    @Input() showAWBModal: boolean;
    @Input() url: string;
    @Input() titleCode: string;
    @Input() showPrintButton: boolean;
    @Output() closeDialogEvent: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private shipmentService: ShipmentsService,
        private messagesService: MessageService,
        private translateService: TranslateService,
        private applicationStateService: ApplicationStateService
    ) {
    }

    public printReport() {
        if (this.applicationStateService.getIsMobileResolution() && window.webkit) { //send message to mobile webkit to download file
            window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({url: this.url, print: true}));
        } else { //Download normally
            this.shipmentService.getPackagePDFBlob(this.url)
                .subscribe(
                    (response) => { // download file
                        const blobUrl = URL.createObjectURL(response);
                        const iframe = document.createElement('iframe');
                        iframe.style.display = 'none';
                        iframe.src = blobUrl;
                        document.body.appendChild(iframe);
                        iframe.contentWindow.print();
                    });
        }
    }

    public downloadReport() {
        if (this.applicationStateService.getIsMobileResolution()) {
            if (window.webkit) { //send message to mobile webkit to download file
                window.open(this.url, '_blank');
                window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({url: this.url}));
            } else { //Browser on mobile download normally
                window.open(this.url, '_blank');
            }
        } else {
            window.open(this.url, '_blank');
        }
    }

    public copyURL() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.URL_COPIED_SUCCESSFULLY').subscribe(value => {
            this.messagesService.add({severity: 'success', detail: value});
        });
    }

    public onCloseDialog() {
        this.closeDialogEvent.emit(false);
    }
}
