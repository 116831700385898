<div class="close-icon" (click)="closeModal()"><img src="../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}" appBarcodeScanner (barcodeRead)="readBarcode($event)">
    <div class="row d-inline-flex main-container">
        <div class="side-container">
            <div class="title-container">
                <div class="label">
                    {{ (isReceiveContainer ? 'LCL.RECEIVE_CONTAINER_TITLE' : 'LCL.UNLOAD_CONTAINER_TITLE') | translate }}
                </div>
            </div>

            <div class="hub-select-container">
                <div class="hub-label">{{'LCL.CHOOSE_HUB' | translate | hubTitle}}</div>
                <ng-select [items]="hubs" appDebounceSelectQuery (inputDebounced)="searchHub($event)"
                           [loading]="isLoadingHubs" [(ngModel)]="selectedHub" (open)="getHubs()"
                           loadingText="{{ 'GENERAL.SEARCHING' | translate }}..."
                           notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}!"
                           placeholder="{{ 'LCL.CHOOSE_HUB' | translate | hubTitle }}"
                           bindLabel="name">

                </ng-select>
            </div>

            <div class="barcode-search">
                <img src="../../assets/icons/container.png" alt="">
                <input type="text" [(ngModel)]="containerBarcode" trimInput
                       [disabled]="isLoading"
                       (keydown.enter)="getContainerSubBundles(containerBarcode)"
                       placeholder="{{ 'LCL.ENTER_CONTAINER_BARCODE' | translate}}">
                <div class="barcode-action" *ngIf="containerBarcode" [ngClass]="{'disabled-btn disabled': isLoading}"
                     (click)="getContainerSubBundles(containerBarcode)">{{ 'ACTIONS.ADD' | translate }}</div>
            </div>
            <div class="barcode-search">
                <img src="../../assets/icons/barcode-scanner.svg" alt="">
                <input type="text" [(ngModel)]="inputBarcode" trimInput
                       [disabled]="isLoading" (keydown.enter)="readBarcode(inputBarcode)"
                       placeholder="{{'READ_BY_BARCODE.ENTER_NUMBERS_OF_BARCODES' | translate}}">
                <div class="barcode-action" *ngIf="inputBarcode" [ngClass]="{'disabled-btn disabled': isLoading}"
                     (click)="readBarcode(inputBarcode)">{{ 'ACTIONS.ADD' | translate }}</div>
            </div>

            <div class="barcodes-list-container">
                <div class="list-header">
                    {{ 'LCL.BARCODES_LIST' | translate }}
                    <span>{{ (this.packagesList ? packagesList.length : 0) }}</span>
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" (input)="filterPackages()" [(ngModel)]="barcodeSearch"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container">
                    <div class="barcode-list-item"
                         [ngClass]="{'list-item-selected': selectedPackage && pkg.id === selectedPackage.id}"
                         *ngFor="let pkg of filteredPackages"
                         (click)="selectPackage(pkg)">
                        <div>{{ pkg.barcode }}</div>
                    </div>
                </div>
            </div>

            <div class="package-details-container">
                <div class="list-header">
                    {{ 'LCL.PACKAGE_DETAILS' | translate | packageTitle }}
                </div>
                <div class="package-details-subcontainer" *ngIf="selectedPackage">
                    <table>
                        <tr>
                            <td>
                                {{ 'SHIPMENT.TABLE.NUMBER' | translate }}: <b>{{ selectedPackage.barcode }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'SHIPMENT.TABLE.CUSTOMER' | translate }}: <b>{{ selectedPackage.receiverName }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'LCL.TOTAL_ORDER_PRICE' | translate }}:
                                <b>{{ selectedPackage.cod | currencyValue : selectedPackage.currency}}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'ADD_SHIPMENT.TYPE_SHIPMENT' | translate | packageTitle}}:
                                <b>{{ selectedPackage.shipmentType | translate }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'SHIPMENT.TABLE.SERVICE_TYPE' | translate }}:
                                <b>{{ selectedPackage.serviceType | translate }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {{ 'SHIPMENT.EXPECTED_PICKUP_DATE' | translate }}:
                                <b>{{ selectedPackage.expectedPickupDate | date:'dd/MM/yyyy' }}</b>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="table-container">
            <p-table [value]="Object.values(items)" [loading]="isLoading || isReading"
                     class="entities-table-component"
                     [responsive]="true" scrollHeight="auto" styleClass="entities-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th>#</th>
                        <th>{{ 'LCL.TABLE.BARCODE' | translate }}</th>
                        <th>{{ 'LCL.TABLE.COMPANY_NAME' | translate }}</th>
                        <th>{{ 'LCL.TABLE.PRODUCT_NAME' | translate }}</th>
                        <th>{{ 'LCL.TABLE.DESCRIPTION' | translate }}</th>
                        <th>{{ 'LCL.TABLE.QUANTITY' | translate }}</th>
                        <th>{{ 'LCL.TABLE.BOX_NO' | translate }}</th>
                        <th>{{ 'LCL.TABLE.BOX_QUANTITY' | translate }}</th>
                        <th>{{ 'LCL.TABLE.LENGTH' | translate }}</th>
                        <th>{{ 'LCL.TABLE.WIDTH' | translate }}</th>
                        <th>{{ 'LCL.TABLE.HEIGHT' | translate }}</th>
                        <th>{{ 'LCL.TABLE.WEIGHT' | translate }}</th>
                        <th>{{ 'LCL.TABLE.CBM' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-index="rowIndex">
                    <tr [ngClass]="{'success-row': item.scanned}">
                        <td>{{ 1 + index }}</td>
                        <td tooltipZIndex="10000" pTooltip="{{ item.barcode }}">
                            {{ item.barcode }}
                        </td>
                        <td>
                            {{ item.companyName }}
                        </td>
                        <td>
                            {{ item.productName }}
                        </td>
                        <td>
                            {{ item.description }}
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.boxNo }}</td>
                        <td>{{ item.boxQuantity }}</td>
                        <td>{{ item.length }}</td>
                        <td>{{ item.width }}</td>
                        <td>{{ item.height }}</td>
                        <td>{{ item.weight }}</td>
                        <td>{{isNaN(item.width * item.length * item.height) ? '-' : (item.width * item.length * item.height / (100 * 100 * 100))}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <div class="empty-content">
                        <app-no-data-available-component
                                [message]="selectedPackage ? 'LCL.ALERTS.NO_ITEMS_FOUND' : 'LCL.ALERTS.SCAN_PACKAGES_TO_UNLOAD'">
                        </app-no-data-available-component>
                    </div>
                </ng-template>
            </p-table>

            <div class="bottom-actions">
                <div class="start-actions">

                </div>
                <div class="end-actions">
                    <div [ngClass]="{'disabled-btn disabled':
                        isLoading
                        || !selectedHub
                        || (totalItemsNo !== totalScannedItems && !isReceivingPkgsWithoutScanItems)}"
                         (click)="onSubmit()"
                         class="done-action">
                        <span>{{ (isReceiveContainer ? 'LCL.ACTIONS.RECEIVE' : 'LCL.ACTIONS.UNLOAD') | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
