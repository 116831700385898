import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PricingService} from '../shippings-rate/services/pricing.service';
import {ContainersService} from '../containers/services/containers.service';
import {ServiceTypesService} from '../administration/services/service-types.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ZonesService} from '../administration/services/zones.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TripsBetweenCitiesService} from './services/trips-between-cities.service';
import {DAYS} from './services/constants';
import {DatePipe} from '@angular/common';
import {AddTripFormComponent} from './components/add-trip-form/add-trip-form.component';
import {HubsService} from '../shared/services/hubs.service';

@Component({
    selector: 'app-trips-between-cities',
    templateUrl: './trips-between-cities.component.html',
    styleUrls: ['./trips-between-cities.component.scss']
})

export class TripsBetweenCitiesComponent implements OnInit {

    currentLang = '';
    isLoading = false;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    daysList = [];
    destinationsForm: FormGroup;
    tripForm: FormGroup;
    fromCity;
    toCity;

    trips: any[] = [];
    regionOptions: any;
    showNewTrip = false;


    showDetailsTable = true;
    filterFormOn = false;
    @ViewChild('tripsFiltersMenu') tripsFiltersMenu;
    filtersForm: FormGroup;

    addTripsForms = [];

    @ViewChild('addTripForm', {static: false})
    addTripForm: AddTripFormComponent;

    constructor(private translateService: TranslateService,
                private pricingService: PricingService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private containersService: ContainersService,
                private serviceTypesService: ServiceTypesService,
                private formBuilder: FormBuilder,
                private hubsService: HubsService,
                private zonesService: ZonesService,
                private changeDetectorRef: ChangeDetectorRef,
                private route: ActivatedRoute,
                private router: Router,
                private datePipe: DatePipe,
                private tripsBetweenCitiesService: TripsBetweenCitiesService) {
    }

    ngOnInit() {
        this.setDaysList();
        this.getTrips();
        this.currentLang = this.translateService.currentLang;
        this.initForms();
        this.initFiltersForm();

    }

    addSubTripAddForm() {
        this.addTripsForms.push(
            {form: this.formBuilder.group({
                    days: [null, Validators.required],
                    fromTime: [null, Validators.required],
                    toTime: [null, Validators.required],
                    cost: [null, Validators.required]
                })}
        );
    }

    getTrips() {
        this.isLoading = true;
        this.trips = [];
        this.tripsBetweenCitiesService.getTrips(this.createQuery()).subscribe(
            (res: any) => {
                this.trips = res;
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }


    private initForms() {
        this.addSubTripAddForm();
        this.destinationsForm = this.formBuilder.group({
            fromCity: [null, Validators.required],
            toCity: [null, Validators.required]
        });
    }


    getRegions(query) {
        let param = '?page=1&pageSize=20';
        if (query) {
            param += '&search=' + query;
        }
        const getRegions = this.hubsService.getHubs(param);
        getRegions.subscribe(
            response => {
                this.regionOptions = response['hubs'].map(region => {
                    return {label: region.name, value: region.id};
                });
            }
        );
    }


    setDaysList() {
        this.daysList = DAYS.map(
            (day) => {
                return {label: this.translateService.instant(`TRIPS_BETWEEN_CITIES.DAYS.${day}`), value: day};
            }
        );
    }


    addTrip(trip) {
        trip.isLoading  = true;
        const destinations = this.destinationsForm.getRawValue();
        const tripData = trip.form.getRawValue();
        const body = {
            fromCityId: destinations.fromCity.value.id,
            toCityId: destinations.toCity.value.id,
            fromTime: tripData.fromTime,
            toTime: tripData.toTime,
            days: tripData.days,
            cost: tripData.cost,
        };
        this.tripsBetweenCitiesService.addTrip(body).subscribe(
            () => {
                trip.isLoading = false;
                trip.addedDone = true;
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
                this.addSubTripAddForm();

            }, error => {
                trip.isLoading = false;
                console.error(error);
            }
        );
    }

    editSubTrip(destinationsForm, editForm, Id) {
        this.isLoading = true;
        const destinationsData = destinationsForm.getRawValue();
        const subTripData = editForm.getRawValue();
        const body = {
            fromCityId: destinationsData.fromCity.value,
            toCityId: destinationsData.toCity.value,
            fromTime: subTripData.fromTime,
            toTime: subTripData.toTime,
            days: subTripData.days,
            cost: subTripData.cost
        };

        this.tripsBetweenCitiesService.editTrip(body, Id).subscribe(
            () => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
            }, err => {
                this.isLoading = false;
                console.error(err);
            }
        );
    }

    deleteSubTrip(Id) {
        this.tripsBetweenCitiesService.deleteSubTrip(Id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                });
            }, error => {
                console.error(error);
            }
        );
    }
    showFiltersMenu(event) {
        this.tripsFiltersMenu.toggle(event);
        this.filterFormOn = true;
        event.stopPropagation();
    }
    onHideFiltersMenu() {
        this.filterFormOn = false;
    }

    initFiltersForm() {
        this.filtersForm = this.formBuilder.group({
            fromCity: '',
            toCity: '',
            days: [],
            fromTime: '',
            toTime: '',
            cost: ''
        });
    }

    resetFilters($event) {
        this.filtersForm.reset();
        this.getTrips();
        this.tripsFiltersMenu.hide($event);
    }

    applyFilters($event) {
        this.getTrips();
    }
    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
    }

    private createQuery() {
        if (!this.filtersForm) {
            return {};
        }
        const reqParams = this.filtersForm.getRawValue();
        const newReq: any = {};
        if (reqParams.fromCity && reqParams.fromCity.value) {
            newReq.fromCity = reqParams.fromCity.value.id;
        }
        if (reqParams.toCity && reqParams.toCity.value) {
            newReq.toCity = reqParams.toCity.value.id;
        }
        if (reqParams.days) {
            newReq.days = reqParams.days;
        }
        if (reqParams.cost || reqParams.cost === 0) {
            newReq.cost = reqParams.cost;
        }
        if (reqParams.fromTime) {
            newReq.fromTime = this.transformDate(reqParams.fromTime);
        }
        if (reqParams.toTime) {
            newReq.toTime = this.transformDate(reqParams.toTime);
        }
        return newReq;

    }

    deleteMainTrip(trip) {

        this.confirmationService.confirm({
            message: this.translateService.instant('TRIPS_BETWEEN_CITIES.ALERTS.DELETE_MAIN_TRIP_CONFIRMATION'),
            accept: () => {
                this.tripsBetweenCitiesService.deleteMainTrip(trip.id).subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                        });
                        this.getTrips();
                    }, err => {
                        console.error(err);
                    }
                );
            },
            reject: () => {

            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO')
        });
    }

    showAddTripForm(trip = null) {
        this.addTripForm.loadComponent(trip);
    }

    onCloseAddTripForm(event: boolean) {
        if (event) {
            this.getTrips();
        }
    }

    getDiffTimes(to, from) {
       let diff = (new Date(to).getTime() - new Date(from).getTime()) / (1000 * 3600);
       const diffHours = Math.floor(diff);
       const diffMinuts = (diff - diffHours) * 0.6;
       diff = diffHours + diffMinuts;
       return diff.toFixed(2);
    }
}
