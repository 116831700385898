import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TicketingSystemService} from '../../services/ticketing-system.service';
import {DatePipe} from '@angular/common';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {UsersService} from '../../../users/services/users.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import * as moment from 'moment';
import {TicketModel} from '../../models/ticket-model';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import * as Compressor from 'compressorjs';
import {SharedService} from '../../../shared/services/shared-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomConfirmationMessageComponent} from '../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {UserService} from '../../../shared/services/user.service';
import {environment} from '../../../../environments/environment';
import {CompatClient, Stomp} from '@stomp/stompjs';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';

@Component({
    selector: 'app-ticket-details',
    templateUrl: 'ticket-details.component.html',
    styleUrls: ['ticket-details.component.scss']
})
export class TicketDetailsComponent extends SubscriptionTrackerComponent implements OnInit, OnChanges, OnDestroy {
    isLoading = false;
    getTicketsLoader = false;
    spinnerState: SpinnerState;
    spinnerStates = SpinnerState;
    currentLang;
    isExternal = true;

    updateTicketForm: FormGroup;

    ticketTypesList: { value: string, label: string }[];
    // ticketStatusList: { value: string, label: string }[];
    ticketPrioritiesList: { value: string, label: string }[];

    selectedClient: any;
    selectedCategory: any;

    editCategory = false;
    editClient = false;

    usersList = [];
    categoriesList = [];

    @Input() display: boolean;
    @Input() triggeredTicketId!: number;

    @Output() closeWidget = new EventEmitter();
    @Output() ticketChange = new EventEmitter();
    @Output() triggeredTicketIdChange = new EventEmitter<number>();


    @ViewChild('clientName') clientName;
    @ViewChild('categoryName') categoryName;
    @ViewChild('replyTextArea') replyTextArea: ElementRef;
    @ViewChild('ticketsListOverLay') ticketsListOverLay;

    ticketDetailsInfo: any;

    isSubmittingForm = false;

    editTitleMode = false;
    editDescriptionMode = false;

    displayReplayArea = false;
    sendingReply = false;
    replyText = '';



    tickets: TicketModel[] = [];
    fetchedTickets: TicketModel[] = [];
    totalRecordsNo = 0;
    ticketsPage = 1;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;


    replyType: string;

    replyAttachmentsFiles: File [] = [];
    sliderPage = 1;
    sliderPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    sliderTickets: TicketModel[] = [];
    currentSliderIndex = 0;

    sliderCounter = 0;

    viewHistoryMode = false;

    minDate = new Date();
    webSocketConnection = undefined;
    webSocketSubscription = undefined;
    private stompClient: CompatClient;
    private socket: WebSocket;
    private isConnected = false;

    isAdmin;
    isCategoryAdmin;
    ticketStatus;

    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private ticketingSystemService: TicketingSystemService,
                private usersService: UsersService,
                private datePipe: DatePipe,
                private confirmationService: ConfirmationService,
                private messagesService: MessageService,
                private sharedService: SharedService,
                private userService: UserService,
                private changeDetectionRef: ChangeDetectorRef,
                private modalService: NgbModal) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        const webSocketUrl = environment.API_BASE_URL.replace('http', 'ws') + 'api/websocket';
        const socket = new WebSocket(webSocketUrl);
        const stompClient = Stomp.over(socket);
        this.stompClient = stompClient;
        this.socket = socket;
        this.webSocketConnection = socket;
        this.stompClient.connect({}, (frame) => {
            this.isConnected = true;
            this.initWebSocket();
        });
        const userInfo = this.userService.userInfo;
        if (userInfo) {
            this.isAdmin = userInfo.role === 'SUPER_ADMIN' || userInfo.role === 'ADMIN' || userInfo.role === 'TICKETING_SYSTEM_ADMIN';
        }
        this.ticketStatus = '';

    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes['triggeredTicketId']
            && changes['triggeredTicketId'].previousValue !== changes['triggeredTicketId'].currentValue)
            || (changes['display'] && changes['display'].currentValue === true)
        ) {
            this.getTicketDetails();
        }
    }


    onShow() {
        this.sliderTicketsList().then((res) => {});
    }

    initialTicket(ticketId) {
        this.triggeredTicketId = ticketId;
        this.triggeredTicketIdChange.emit(this.triggeredTicketId);
        this.getTicketDetails();
    }

    getTicketDetails() {
        if (!this.triggeredTicketId) {
            return;
        }
        this.isLoading = true;
        this.ticketingSystemService.getTicketDetails(this.triggeredTicketId).subscribe(
            (ticketDetails: any) => {
                this.ticketDetailsInfo = ticketDetails;
                this.isExternal = this.userService.userInfo.categoryId !== ticketDetails.categoryId;
                this.ticketStatus = ticketDetails.status;
                this.isCategoryAdmin = this.userService.userInfo.categoryId === ticketDetails.category.id &&
                    this.userService.userInfo.ticketingRole === 'CATEGORY_ADMIN';
                this.initDropDowns();
                this.initUpdateForm();
                this.isLoading = false;
                this.initWebSocket();
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    initUpdateForm() {
        this.updateTicketForm = this.formBuilder.group({
            categoryName: [this.ticketDetailsInfo.category.name, Validators.required],
            clientName: [this.getUserName(this.ticketDetailsInfo.assignee)],
            type: [this.ticketDetailsInfo.type],
            priority: [this.ticketDetailsInfo.priority, Validators.required],
            resolveDue: [this.ticketDetailsInfo.resolveDue ? new Date(this.ticketDetailsInfo.resolveDue) : null]
        });
        this.selectedCategory = {
            id: this.ticketDetailsInfo.category.id,
            name: this.ticketDetailsInfo.category.name,
            description: this.ticketDetailsInfo.category.description,
            level: this.ticketDetailsInfo.category.level,
            ticketsCount: this.ticketDetailsInfo.category.ticketsCount
        };
        this.selectedClient = {
            id: this.ticketDetailsInfo.assignee.id,
            firstName: this.ticketDetailsInfo.assignee.firstName,
            lastName: this.ticketDetailsInfo.assignee.lastName
        };
        this.updateTicketForm.updateValueAndValidity();
    }

    updateTicket(attachments = []) {
        this.isSubmittingForm = true;
        const data = this.updateTicketForm.getRawValue();
        const body = {
            id: this.ticketDetailsInfo.id,
            categoryId: this.selectedCategory.id,
            assigneeId: this.selectedClient.id,
            customerId: this.ticketDetailsInfo.customerId,
            type: data.type,
            priority: data.priority,
            resolveDue: data.resolveDue ? this.transformDate(moment(data.resolveDue, 'DD/MM/yyyy').toDate()) : null,
            attachments: attachments ? attachments : this.ticketDetailsInfo.attachments
        };
        this.ticketingSystemService.updateTicket(body).subscribe(
            (res: any) => {
                this.getTicketDetails();
                const message = this.translateService.instant('TICKETING_SYSTEM.ALARMS.UPDATE_FROM_TABLE_SUCCESSFULLY');
                this.messagesService.add({
                    severity: 'success',
                    detail: message.replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.TICKET'))
                });
                this.ticketChange.emit();
                this.isSubmittingForm = false;
            }, error => {
                console.error(error);
                this.isSubmittingForm = false;
            }
        );
    }
    allowDeleteTicket() {
        return this.userService.userInfo.role === 'SUPER_ADMIN' || this.userService.userInfo.role === 'ADMIN';
    }
    displayConfirmation(actionType: string) {
        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', backdropClass: 'custom-confirmation-message-backdrop', size: 'sm'});
        let title, confirmMessage, confirmButtonLabel, successMessage, failedMessage;
        let request;
        switch (actionType) {
            // case 'DELETE':
            //     title = 'TICKETING_SYSTEM.CONFIRMATION.DELETE_CONFIRMATION_TITLE';
            //     confirmMessage = 'TICKETING_SYSTEM.ALARMS.DELETE_TICKET_CONFIRMATION';
            //     confirmButtonLabel = 'TICKETING_SYSTEM.CONFIRMATION.ACTIONS.DELETE';
            //     successMessage = this.translateService
            //         .instant(
            //             'TICKETING_SYSTEM.ALARMS.DELETE_ATTRIBUTE_SUCCESSFULLY'
            //         ).replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.FORM.TICKET'));
            //     request = this.ticketingSystemService.deleteTicket(this.ticketDetailsInfo.id);
            //     break;
            // case 'CLOSE':
            //     title = 'TICKETING_SYSTEM.CONFIRMATION.CLOSE_CONFIRMATION_TITLE';
            //     confirmMessage = 'TICKETING_SYSTEM.ALARMS.CLOSE_TICKET_CONFIRMATION';
            //     confirmButtonLabel = 'TICKETING_SYSTEM.CONFIRMATION.ACTIONS.CLOSE';
            //     successMessage = this.translateService.instant(
            //         'TICKETING_SYSTEM.ALARMS.UPDATE_FROM_TABLE_SUCCESSFULLY'
            //     ).replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.TABLE.STATUS'));
            //     request = this.ticketingSystemService.updateTicketStatus(this.ticketDetailsInfo.id, {status: 'DONE'});
            //     break;
            case 'NEW':
                title = 'TICKETING_SYSTEM.CONFIRMATION.CHANGE_TICKET_STATUS';
                confirmMessage = 'TICKETING_SYSTEM.ALARMS.CHANGE_STATUS_NEW_CONFIRMATION';
                confirmButtonLabel = 'TICKETING_SYSTEM.CONFIRMATION.ACTIONS.CHANGE';
                successMessage = this.translateService.instant('ALERTS.DONE_SUCCESSFULLY');
                request = this.ticketingSystemService.updateTicketStatus(this.ticketDetailsInfo.id, {status: 'NEW'});
                break;
            case 'DONE':
                title = 'TICKETING_SYSTEM.CONFIRMATION.CHANGE_TICKET_STATUS';
                confirmMessage = 'TICKETING_SYSTEM.ALARMS.CHANGE_STATUS_DONE_CONFIRMATION';
                confirmButtonLabel = 'TICKETING_SYSTEM.CONFIRMATION.ACTIONS.CHANGE';
                successMessage = this.translateService.instant('ALERTS.DONE_SUCCESSFULLY');
                request = this.ticketingSystemService.updateTicketStatus(this.ticketDetailsInfo.id, {status: 'DONE'});
                break;
        }

        modal.componentInstance.title = title;
        modal.componentInstance.confirmMessage = confirmMessage;
        modal.componentInstance.confirmButtonLabel = confirmButtonLabel;
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    request.subscribe(
                        (res: any) => {
                            this.ticketChange.emit();
                            this.onClose();
                            this.messagesService.add({
                                severity: 'success',
                                detail: successMessage
                            });
                        }, error => {
                            console.error(error);
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    updateAttribute(attribute, value, replyId = null, replyType = '', attachments = []) {
        let updateAttr;
        let attributeLabel = attribute;
        const message = this.translateService.instant('TICKETING_SYSTEM.ALARMS.UPDATE_FROM_TABLE_SUCCESSFULLY');
        let body;
        switch (attribute) {
            case 'ISSUE_TITLE':
                body = {title: value};
                updateAttr = this.ticketingSystemService.updateTicketTitle(body, this.triggeredTicketId);
                break;
            case 'DESCRIPTION':
                body = {description: value};
                updateAttr = this.ticketingSystemService.updateTicketDescription(body, this.triggeredTicketId);
                break;
            case 'REPLY':
                body = {text: value, id: replyId, attachments: attachments};
                updateAttr = this.ticketingSystemService.updateTicketReply(body);
                attributeLabel = replyType;
                break;
        }
        updateAttr.subscribe(
            (res) => {
                // this.getTicketDetails();
                this.messagesService.add({
                    severity: 'success',
                    detail: message.replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.FORM.' + attributeLabel))
                });
            }
        );
    }

    convertToMb(size) {
        return Number((size / (1024 * 1024)).toFixed(2));
    }

    updateCardAttachments(event) {
        const attachments: File[] = event.attachments;

        const newUploadedAttachments = [];
        let addAttachmentsCount = 0;
        if (attachments.length) {
            const updateRequest = (Attachments) => {
                if (event.reply) {
                    const reply = event.reply;
                    this.updateAttribute('REPLY', reply.text, reply.id, reply.type, Attachments);
                } else {
                    this.updateTicket(Attachments);
                }
            };
            const compressionQuality = 0.3;
            const uploadFile = (formData, folder) => {
                this.ticketingSystemService.uploadFile(formData, folder).subscribe(
                    (res: any) => {
                        newUploadedAttachments.push(res.fileUrl);
                        addAttachmentsCount++;
                        if (addAttachmentsCount === attachments.length) {
                            const existAttachments = event.reply ? (event.reply.attachments ? event.reply.attachments : []) : this.ticketDetailsInfo.attachments;
                            const allAttachments = [...existAttachments, ...newUploadedAttachments];
                            updateRequest(allAttachments);
                        }
                    }, error => {
                        console.error(error);
                    }
                );
            };

            for (let i = 0; i < attachments.length; i++) {
                if (this.convertToMb(attachments[i].size) > 10) {
                    this.messagesService.add({severity: 'error', detail: 'uploaded file is too large'});
                    continue;
                }
                if (!attachments[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico|pdf|xlsx|xlsm|xlsb|xltx|csv|txt)$/i)) {
                    this.messagesService.add({severity: 'error', detail: 'Not Supported File Format'});
                    continue;
                }
                const formData = new FormData();
                if (attachments[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {

                    const comp = new Compressor.default(attachments[i], {
                        quality: compressionQuality,
                        success(compFile) {
                            formData.append('file', compFile);
                            uploadFile(formData, 'replies');
                        }
                    });
                } else {
                    formData.append('file', attachments[i]);
                    uploadFile(formData, 'replies');
                }
            }
        }

    }

    removeReply(replyId, replyType) {
        const body = {id: replyId};
        const message = this.translateService.instant('TICKETING_SYSTEM.ALARMS.DELETE_ATTRIBUTE_SUCCESSFULLY');
        this.ticketingSystemService.deleteTicketReply(body).subscribe(
            (res: any) => {
                this.messagesService.add({
                    severity: 'success',
                    detail: message.replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.FORM.' + replyType))
                });
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    confirmRemoveReply(replyId, replyType) {

        const confirmMessage = this.translateService.instant('TICKETING_SYSTEM.ALARMS.DELETE_ATTRIBUTE_CONFIRMATION');
        const titleMessage = this.translateService.instant('TICKETING_SYSTEM.CONFIRMATION.DELETE_ATTRIBUTE');

        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', backdropClass: 'custom-confirmation-message-backdrop',  size: 'sm'});

        modal.componentInstance.title = titleMessage.replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.FORM.' + replyType));
        modal.componentInstance.confirmMessage = confirmMessage.replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.FORM.' + replyType));
        modal.componentInstance.confirmButtonLabel = 'ACTIONS.DELETE';
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    this.isLoading = true;
                    this.removeReply(replyId, replyType);
                    this.getTicketDetails();
                } else { this.isLoading =  false; }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

        // this.confirmationService.confirm({
        //     message: message.replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.FORM.' + replyType)),
        //     accept: () => {
        //         this.isLoading = true;
        //         this.removeReply(replyId, replyType);
        //     },
        //     reject: () => {
        //         this.isLoading = false;
        //     },
        //     acceptLabel: this.translateService.instant('GENERAL.YES'),
        //     rejectLabel: this.translateService.instant('GENERAL.NO'),
        //     rejectVisible: true
        // });
    }

    deleteReplyAttachment(data, isTicketReply) {
        if (isTicketReply) {
            this.updateAttribute('REPLY', data.text, data.id, data.type, data.attachments);
        } else {
            this.updateTicket(data.attachments);
        }
    }

    getCategories($event) {
        const query = '?search=' + $event.query + '&isDropdown=true';
        this.ticketingSystemService.getAllCategories(query).subscribe(
            (response: any) => {
                this.categoriesList = response;
            }, (error) => {
                console.error(error);
            }
        );
    }

    chooseCategory($event) {
        const category = $event;
        this.updateFormValue('categoryName', category.name);
        this.selectedCategory = JSON.parse(JSON.stringify(category));
    }

    resetCategory() {
        this.selectedCategory = null;
        this.editCategory = false;
        this.updateFormValue('categoryName', '');
    }

    public editCategoryName() {
        if (!this.selectedCategory) {
            this.categoryName.focusInput();
            this.categoryName.search(null, '');
            return;
        }
        this.editCategory = true;
        this.categoryName.focusInput();
        const search_term = this.updateTicketForm.get('categoryName').value;
        this.categoryName.search(null, search_term);
    }

    getUserName(user) {
        return (user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : '');
    }

    getCreatedByName(user, customer) {
        return (user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : '') ||
            (customer.firstName && customer.lastName ? customer.firstName + ' ' + customer.lastName : '');
    }
    getClients($event) {
        let query = '?page=1&pageSize=100';
        if ($event.query) {
            query += '&search=' + $event.query;
        }
        if(this.selectedCategory && this.selectedCategory.id) {
            query += '&categoryId=' + this.selectedCategory.id;
        }
        this.usersService.getUserDropDown(query).subscribe(
            (response: any) => {
                this.usersList = response;
            }, (error) => {
                console.error(error);
            }
        );
    }

    chooseClient($event) {
        const user = $event;
        const userName = user.name;
        this.updateFormValue('clientName', userName);
        this.selectedClient = JSON.parse(JSON.stringify(user));
        this.editClient = false;
    }

    resetClient() {
        this.selectedClient = null;
        this.editClient = false;
        this.updateFormValue('clientName', '');
    }

    editClientName() {
        if (!this.selectedClient) {
            this.clientName.focusInput();
            this.clientName.search(null, '');
            return;
        }
        this.editClient = true;
        // this.clientName.focusInput();
        const search_term = this.updateTicketForm.get('clientName').value;
        this.clientName.search(null, search_term);
    }

    initDropDowns() {
        this.ticketTypesList = this.ticketingSystemService.setTicketTypes();
        // this.ticketStatusList = this.ticketingSystemService.setTicketStatus();
        this.ticketPrioritiesList = this.ticketingSystemService.setTicketPriorities();
    }

    public updateFormValue(controlName, value) {
        this.updateTicketForm.controls[controlName].setValue(value);
        this.updateTicketForm.controls[controlName].updateValueAndValidity();
    }

    showReply(type) {
        this.replyType = type;
        this.displayReplayArea = true;
        setTimeout(() => {
            this.replyTextArea.nativeElement.focus();
        }, 300);
    }

    hideReply() {
        this.displayReplayArea = false;
        this.replyText = '';
    }

    replyRequest() {
        if (this.replyAttachmentsFiles.length) {
            this.uploadReplyAttachments();
        } else {
            this.addReply();
        }
    }

    uploadReplyAttachments() {
        this.sendingReply = true;
        const replyAttachments = [];
        let addAttachmentsCount = 0;

        if (this.replyAttachmentsFiles.length) {
            const compressionQuality = 0.3;
            const uploadFile = (formData, folder) => {
                this.ticketingSystemService.uploadFile(formData, folder).subscribe(
                    (res: any) => {
                        replyAttachments.push(res.fileUrl);
                        addAttachmentsCount++;
                        if (addAttachmentsCount === this.replyAttachmentsFiles.length) {
                            this.addReply(replyAttachments);
                        }
                    }, error => {
                        console.error(error);
                    }
                );
            };

            for (let i = 0; i < this.replyAttachmentsFiles.length; i++) {
                if (this.convertToMb(this.replyAttachmentsFiles[i].size) > 10) {
                    this.messagesService.add({severity: 'error', detail: 'uploaded file is too large'});
                    continue;
                }
                if (!this.replyAttachmentsFiles[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico|pdf|xlsx|xlsm|xlsb|xltx|csv|txt)$/i)) {
                    this.messagesService.add({severity: 'error', detail: 'Not Supported File Format'});
                    continue;
                }
                const formData = new FormData();
                if (this.replyAttachmentsFiles[i].name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {

                    const comp = new Compressor.default(this.replyAttachmentsFiles[i], {
                        quality: compressionQuality,
                        success(compFile) {
                            formData.append('file', compFile);
                            uploadFile(formData, 'replies');
                        }
                    });
                } else {
                    formData.append('file', this.replyAttachmentsFiles[i]);
                    uploadFile(formData, 'replies');
                }
            }
        }
    }

    removeAttachment(attachment) {
        for (let i = 0; i < this.replyAttachmentsFiles.length; i++) {
            if (this.replyAttachmentsFiles[i] === attachment) {
                this.replyAttachmentsFiles.splice(i, 1);
            }
        }
    }

    addReply(attachment = []) {
        if (!this.replyText.length) {
            return;
        }
        const body = {ticketId: this.ticketDetailsInfo.id, text: this.replyText, type: this.replyType, attachments: attachment};
        this.ticketingSystemService.addReply(body).subscribe(
            (res: any) => {
                this.replyAttachmentsFiles = [];
                const message = this.translateService.instant('TICKETING_SYSTEM.ALARMS.REPLY_ADDED_SUCCESSFULLY');
                this.sendingReply = false;
                this.displayReplayArea = false;
                this.messagesService.add({
                    severity: 'success',
                    detail: message.replace(
                        '{TYPE}',
                        this.translateService.instant('TICKETING_SYSTEM.TICKET_DETAILS.ACTIONS.' + this.replyType))
                });
                this.replyText = '';
                this.getTicketDetails();
            }, error => {
                console.error(error);
                this.sendingReply = false;
            }
        );

    }

    onClose() {
        this.editTitleMode = false;
        this.editDescriptionMode = false;
        this.viewHistoryMode = false;
        this.closeWidget.emit(false);
    }

    transformDate(date, Dateformat = null) {
        const format = Dateformat ? Dateformat : 'yyyy-MM-dd';
        return this.datePipe.transform(new Date(date), format);
    }

    createSliderUrlQuery() {
        return `?pageSize=${this.sliderPageSize}&page=` + (this.sliderPage);
    }

    sliderTicketsList() {
        return new Promise((resolve, reject) => {
            const urlQuery = this.createSliderUrlQuery();
            this.ticketingSystemService.getTickets(urlQuery).subscribe(
                (response: { data: TicketModel[], totalRecordsNo: number }) => {
                    this.sliderTickets = response.data;
                    this.totalRecordsNo = response.totalRecordsNo;
                    // resolve();
                }, error => {
                    console.error(error);
                }
            );
        });

    }

    disableGoToArrow(prev: boolean) {
        if (prev) {
            return this.sliderPage === 1 && this.currentSliderIndex === 0;
        } else {
            return this.sliderCounter === (this.totalRecordsNo - 1);
        }
    }

    goToTicket(incremental) {
        this.currentSliderIndex += incremental;
        this.sliderCounter += incremental;
        const getTicketDetails = () => {
            if (this.sliderTickets[this.currentSliderIndex] !== undefined) {
                this.triggeredTicketId = this.sliderTickets[this.currentSliderIndex].id;
                this.triggeredTicketIdChange.emit(this.triggeredTicketId);
                this.getTicketDetails();
            }
        };

        if (incremental === 1) {
            if (this.currentSliderIndex === this.sliderPageSize) {
                this.sliderPage += 1;
                this.sliderTicketsList().then((res) => {
                    this.currentSliderIndex = 0;
                    getTicketDetails();
                });
                return;
            }
        } else {
            if (this.currentSliderIndex < 0) {
                this.sliderPage -= 1;
                this.sliderTicketsList().then((res) => {
                    this.currentSliderIndex = this.sliderPageSize - 1;
                    getTicketDetails();
                });
                return;
            }
        }
        getTicketDetails();
    }

    createQuery() {
        return `?pageSize=${this.pageSize}&page=` + (this.ticketsPage);
    }

    @HostListener('scroll', ['$event'])
    onScrollTicketsList(event) {
        if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight
            && (this.tickets.length !== this.totalRecordsNo) && !this.getTicketsLoader) {
            this.lazyLoadTickets();
        }
    }

    lazyLoadTickets() {
        if (!this.getTicketsLoader) {
            this.ticketsPage++;
            this.getTickets();
        }
    }


    public getTickets(reset = false) {
        if (reset) {
            this.tickets = [];
            this.ticketsPage = 1;
        }
        const urlQuery = this.createQuery();
        this.fetchedTickets = [];

        if (this.getTicketsLoader) {
            return;
        }

        this.getTicketsLoader = true;
        this.ticketingSystemService.getTickets(urlQuery).subscribe(
            (response: { data: TicketModel[], totalRecordsNo: number }) => {
                this.fetchedTickets = response.data;
                this.tickets = [...this.tickets, ...this.fetchedTickets];
                this.totalRecordsNo = response.totalRecordsNo;
                this.getTicketsLoader = false;
            }, error => {
                console.error(error);
                this.getTicketsLoader = false;
            }
        );
    }

    addAttachment(event: Event, fileChooser) {
        event.stopPropagation();
        fileChooser.click();
    }


    onFilesSelected($event: any) {
        for (let i = 0; i < $event.target.files.length; i++) {
            this.replyAttachmentsFiles.push($event.target.files[i]);
        }
    }

    toggleViewHistoryMode() {
        this.viewHistoryMode = !this.viewHistoryMode;
    }

    private initWebSocket() {
        if (this.webSocketSubscription) {
            this.webSocketSubscription.unsubscribe();
        }
        if (!this.isConnected) {
            return;
        }

        const subscription = this.stompClient.subscribe('/topic/customer-ticket-' + this.triggeredTicketId, (res: any) => {
            const body = new TextDecoder().decode(res._binaryBody);
            this.ticketDetailsInfo = JSON.parse(body);
            this.initDropDowns();
            this.initUpdateForm();
            this.changeDetectionRef.detectChanges();
        });
        this.webSocketSubscription = subscription;
        this.subscriptions.push(subscription);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.webSocketConnection.close();
        this.socket.close();
        this.stompClient.disconnect();
    }
}
