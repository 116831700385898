import {Component, OnInit} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {UsersService} from '../../users/services/users.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExpensesTypeAddComponent} from './expenses-type-add/expenses-type-add.component';
import {ExpensesAddComponent} from './expenses-add/expenses-add.component';
import {UserService} from '../../shared/services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {STORAGE_KEYS} from "../../shared/services/storage/storage.keys";
import {StorageService} from "../../shared/services/storage/storage.service";
import {MassCodReportHistoryComponent} from '../../administration/components/mass-cod-report-history/mass-cod-report-history.component';
import {ExpensesHistoryComponent} from '../../administration/components/expenses-history/expenses-history.component';

interface Expenses {
    id: number;
    expenseTypeId: number;
    holderId: number;
    accountantId: number;
    moneyAmount: number;
    notes: string;
    accountantName: string;
    holderName: string;
    expenseTypeName: string;
    createdDate: string;
    companyId: number;
}

interface DropDownResponse {
    label: string;
    value: number;
}

@Component({
    selector: 'app-expenses',
    templateUrl: './expenses.component.html',
    styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {
    public expenses: Expenses[] = [];
    public form: FormGroup;
    public expensesType: DropDownResponse[] = [];
    public userType: DropDownResponse[] = [];
    public selectedReceiverFilter = '';
    public searchContent = '';
    public selectedLanguage = '';
    public fromDate = null;
    public toDate = null;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public pageNumber = 0;
    public totalRecords = 0;
    public totalMoneyAmount = 0;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    public dateRangPickerOptions: any;

    currency = '';

    constructor(private datePipe: DatePipe,
                private translateService: TranslateService,
                private shipmentsService: ShipmentsService,
                private messageService: MessageService,
                private usersService: UsersService,
                private userServer: UserService,
                private storageService: StorageService,
                private modalService: NgbModal,
                private formBuilder: FormBuilder,
                private dateRangePickerService: DateRangePickerService
    ) {
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        this.initExpenses();
        this.initInfo();
        this.currency = this.getCurrency();
        this.userServer.getUserInfo();
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });
    }

    getCurrency() {
        if (this.userServer.userInfo && this.userServer.userInfo.currency) {
            return this.userServer.userInfo.currency;
        }
        return this.storageService.get(STORAGE_KEYS.CURRENCY);
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            expenseTypeId: [''],
            userId: ['']
        });
    }

    initExpenses() {
        this.spinnerState = SpinnerState.LOADING;
        this.shipmentsService.getExpenses(this.createParams()).subscribe((response: any) => {
            this.expenses = response.data;
            this.totalRecords = response.totalRecordsNo;
            this.spinnerState = SpinnerState.LOADED;
        }, () => {
            this.spinnerState = SpinnerState.LOADED;
        });
        this.shipmentsService.getTotalExpensesCost(this.createParams()).subscribe((response: any) => {
            this.totalMoneyAmount = response.sum;
        });
    }

    public loadExpensesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.initExpenses();
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public createParams(type = '') {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (this.selectedReceiverFilter !== '') {
            params['accountantId'] = this.selectedReceiverFilter;
        }
        if (this.form && this.form.value) {
            if (this.form.value.userId !== undefined && this.form.value.userId !== '') {
                params['holderId'] = this.form.value.userId.value;
            }
            if (this.form.value.expenseTypeId !== undefined && this.form.value.expenseTypeId !== '') {
                params['expenseTypeId'] = this.form.value.expenseTypeId.value;
            }
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (type !== '') {
            params['type'] = type;
        }
        return params;
    }

    public onSearch() {
        this.pageNumber = 0;
        this.initExpenses();
    }

    addExpensesType() {
        const modal = this.modalService.open(ExpensesTypeAddComponent, {backdrop: 'static', windowClass: 'md'});
        modal.result.then(
            (data: { name: string }) => {
                if (data !== undefined && data) {
                    const body = {name: data.name};
                    this.shipmentsService.addExpensesTypes(body).subscribe(
                        () => {
                            this.translateService.get('ALERTS.EXPENSES_APPROVED_SUCCESSFULLY')
                                .subscribe(
                                    (translateValue) => {
                                        this.messageService.add({severity: 'success', detail: translateValue});
                                    }
                                );
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    addExpenses() {
        const modal = this.modalService.open(ExpensesAddComponent, {
            backdrop: 'static',
            windowClass: 'edit-template',
            size: 'lg'
        });
        modal.result.then(
            (data: any) => {
                if (data !== undefined && data) {
                    const body = {
                        createdDate: this.transformDate(data.data.createdDate),
                        holderId: data.data.holderId.value !== undefined ? data.data.holderId.value : null,
                        holderName2: data.data.holderId.value === undefined ? data.data.holderId : null,
                        expenseTypeId: data.data.expenseTypeId.value,
                        accountantId: this.userServer.userInfo.id,
                        notes: data.data.notes,
                        moneyAmount: data.data.moneyAmount
                    };
                    this.shipmentsService.postExpenses(body).subscribe(() => {
                        this.initExpenses();
                    });
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public getExpensesTypes(data) {
        const query = '?page=1&pageSize=100&search=' + data.query;
        this.initExpensesType(query);
    }

    public getUserType(data) {
        const query = '?page=1&pageSize=100&search=' + data.query;
        this.initUserType(query);
    }

    initUserType(query = '') {
        if (query) {
            query += '&driverType=TYPICAL';
        } else {
            query += '?driverType=TYPICAL';
        }
        this.usersService.getUserDropDown(query).subscribe((response: any) => {
            this.userType = response.map(
                (res) => {
                    return {label: res.name, value: res.id};
                }
            );
        });
    }

    initExpensesType(query = '') {
        this.shipmentsService.getExpensesType(query).subscribe((response: any) => {
            this.expensesType = response.map(
                (res) => {
                    return {label: res.name, value: res.id};
                }
            );
        });
    }

    onReceiverSelectedChange(id) {
        this.selectedReceiverFilter = (id === undefined ? '' : id);
        this.initExpenses();
    }

    public updateExpenses(expenses) {
        const modal = this.modalService.open(ExpensesAddComponent, {
            backdrop: 'static',
            windowClass: 'edit-template',
            size: 'lg'
        });
        modal.componentInstance.expenses = expenses;
        modal.componentInstance.isEditMode = true;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data.isSuccess) {
                    this.initExpenses();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    showHistory($event, record) {
        $event.stopPropagation();
        const model = this.modalService.open(ExpensesHistoryComponent, {
            backdrop: 'static'
        });
        model.componentInstance.report = record;
    }

    printReport(type) {
        this.spinnerState = SpinnerState.LOADING;
        this.shipmentsService.printExpensesReport(this.createParams(type)).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.spinnerState = SpinnerState.LOADED;
                this.translateService.get(
                    'ALERTS.PRINT_PDF_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                        }
                    );
            },
            error => {
                console.log(error);
                this.spinnerState = SpinnerState.LOADED;
            });
    }

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.onSearch();
    }
}
