import {PackageModel} from '../../../shared/models/package.model';

export interface PackageTrackerModel {
    nodes: PackageStatusModel[];
    pkg: PackageModel;
}

export interface PackageStatusModel {
    name: string;
    deliveryDate: Date;
    isArrived: boolean;
    type?: string;
    image?: string;
    cssClass?: string;
}

export enum TrackingStatus {
    DETAILED,
    STATUS,
    ITEMS
}
