import {Component, Inject, ViewChild, EventEmitter, Input, Output, OnInit} from '@angular/core';
 import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Address} from '../../../shared/models/address.class';
import {ZonesService} from '../../../administration/services/zones.service';
import {CompaniesService} from '../../../companies/services/companies.service';
import {UserService} from '../../../shared/services/user.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {COMPANIES_CONSTANTS} from '../../../companies/companies.constants';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-new-component-component',
    templateUrl: 'sign-up.component.html',
    styleUrls: ['sign-up.component.scss']
})
export class SignUpComponent extends Address implements OnInit{
    private _isEdit;
    public form;
    public items;
    public activeIndex = 0;
    public isLoading;
    public isGuest;
    public isModal = false;
    public companyId;
    public companyInfo: any = {};
    public isSuperAdmin = false;
    public selectedLanguage;
    public translationLoaded = false;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    set isEdit(val) {
        this._isEdit = val;
        this.initForm();
    }

    get isEdit() {
        return this._isEdit;
    }
    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        this.translateService.get('SIGN_UP').subscribe(
            (translatedMessage) => {
                this.translationLoaded = true;
            }
        );
    }


    constructor(private formBuilder: FormBuilder,
                private companiesService: CompaniesService,
                private activeModal: NgbActiveModal,
                public translateService: TranslateService,
                public zonesService: ZonesService,
                private route: ActivatedRoute,
                private location: Location,
                private userService: UserService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private authenticationService: AuthenticationService) {
        super(zonesService, translateService, false); // route.snapshot.data['isAuth']
        this.isEdit = this.route.snapshot.data['isEdit'];
        this.isGuest = this.route.snapshot.data['isGuest'];
        this.isModal = this.route.snapshot.data['isModal'];
        this.initItems();

    }


    public initForm() {
         const  company = this.formBuilder.group({
                name: ['', [Validators.required, Validators.pattern(`^[^!%@$#&?=*^"'\-\/\+\\\n]*$`)]],
                email: ['', Validators.compose([
                    Validators.required,
                    Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')])],
                phone: ['', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]],
                registrationNumber: [''],
                address: this.formBuilder.group({
                    cityId: ['', Validators.required],
                    country: ['Palestine'],
                    regionId: ['', Validators.required],
                    villageId: ['', Validators.required],
                    addressLine1: ['', Validators.required]
                }),
                domain: ['', Validators.required],
            });

            this.form = this.formBuilder.group({
                company
            });
            const admin = this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.compose([
                    Validators.required,
                    Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')])],
                phone: ['', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]],
                address: this.formBuilder.group({
                    cityId: [this.countriesOptions[0].value, Validators.required],
                    country: ['Palestine'],
                    regionId: ['', Validators.required],
                    villageId: ['', Validators.required],
                    addressLine1: ['', Validators.required]
                })
            });
            this.form = this.formBuilder.group({
                captcha: ['', Validators.required],
                company,
                admin
            });
            this.setEvents(company, admin);
    }

    public closeModal() {
        this.activeModal.close();
    }

    private initItems() {
        this.items = [
            {label: 'GENERAL.COMPANY_INFO'},
            {label: 'GENERAL.ADMIN_INFO'},
        ];

        this.items.forEach(
            (item, index) => {
                this.translateService.get(item.label).toPromise().then(
                    (translation) => {
                        this.items[index].label = translation;
                    }
                );
            }
        );
    }

    public submit(event) {
        this.isLoading = true;
        const {company} = this.form.getRawValue();
        this.companiesService.signUpCompany({
            ...company
        }).subscribe((data: any) => {
            this.isLoading = false;
            if (this.isModal) {
                this.activeModal.close({isSuccess: true});
            }
        }, (error) => {
            let errorMessage = '';
            this.translateService.get(
                ['ALERTS.FAILED_TO_ADD_COMPANY']
            ).subscribe(
                (data) => {
                    errorMessage = data['ALERTS.FAILED_TO_ADD_COMPANY'];
                    this.confirmationService.confirm({
                        message: errorMessage + ': ' + error.error.error,
                        rejectVisible: false,
                        acceptLabel: 'OK'
                    });
                    this.isLoading = false;
                }
            );
        });
    }

    public setEvents(company, admin?) {
        this.initFormEvent(company.controls.address, 0, {
            country: '',
            region: 'regionId',
            city: 'cityId',
            village: 'villageId'
        });
        if (admin) {

            this.initFormEvent(admin.controls.address, 1, {
                country: '',
                region: 'regionId',
                city: 'cityId',
                village: 'villageId'
            });
        }
    }
}
