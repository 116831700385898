import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {APIS} from './shared_constants/apis';
import {AuthenticationService} from './authentication.service';
import {LOGIN_CONSTANTS} from '../../login/services/constants';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';


@Injectable({ providedIn: 'root' })
export class MessagingService {
    public token = '';
    public notificationNumber;
    public shipmentPath = '/home/manage-shipments';
    public inboxPath = '/home/inbox';
    public customersPath = '/home/manage-users/customers';

    constructor(
        private router: Router,
        private http: HttpClient,
        public alertService: AlertService,
        private angularFireMessaging: AngularFireMessaging,
        private authenticationService: AuthenticationService) {
        this.shipmentPath = this.authenticationService.companyName + this.shipmentPath;
        this.customersPath = this.authenticationService.companyName + this.customersPath;
        this.inboxPath = this.authenticationService.companyName + this.inboxPath;

    }

    public requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.token = token;
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    public receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                if (payload.notification) {
                    this.notificationNumber = payload.data.badge;
                    this.alertService.alert$.next({
                        title: payload.notification.title,
                        message: payload.notification.body,
                        action: () => {
                            this.handelNotification(payload.data.type);
                        },
                        severity: 'info'
                    });
                }
            });
    }

    public handelNotification(type, id = 0) {
        switch (type) {
            case 'CANCEL_PACKAGE':
                this.router.navigate([this.shipmentPath]);
                return;
            case 'APPROVE_PACKAGE':
                this.router.navigate([this.shipmentPath]);
                return;
            case 'ACCEPT_PACKAGE':
                this.router.navigate([this.shipmentPath]);
                return;
            case 'RETURN_PACKAGE':
                this.router.navigate([this.shipmentPath]);
                return;
            case 'DELIVER_PACKGE':
                this.router.navigate([this.shipmentPath]);
                return;
            case 'REJECT_COD' || 'CHANGE_COD':
                this.router.navigate([this.shipmentPath]);
                return;
            case 'RECEIVE_MESSAGE':
                this.router.navigate([this.inboxPath]);
                return;
            case 'POSTPONED_PACKAGES_REMINDER':
                this.router.navigate([this.shipmentPath], {queryParams: {status: 'POSTPONED_DELIVERY'}});
                return;
            case 'NEW_CUSTOMER':
                this.router.navigate([this.customersPath]);
        }
    }

    public getNotifications(searchParams?) {
        let url = APIS.GET_NOTIFICATIONS;
        if (searchParams) {
            url += '?page=' + searchParams.page + '&&pageSize=' + searchParams.pageSize;
        }
        return this.http.get(url);
    }

    public deletNotification(id) {
        return this.http.delete(APIS.DELETE_NOTIFICATION.replace('{notificationId}', id));
    }

    public setAsRead(id) {
        return this.http.put(APIS.SET_AS_READ.replace('{notificationId}', id), {});
    }

    public setAllAsRead() {
        return this.http.put(APIS.SET_ALL_AS_READ, {});
    }

    public numberOfUnreadNotification() {
        return this.http.get(APIS.NUMBER_OF_UNREAD_NOTIFICATIONS);
    }

    public subscribeToFireBase(token, id, uuid) {
        const body = {
            notificationToken: token,
            UUID: uuid,
            userId: id
        };
        return this.http.post(LOGIN_CONSTANTS.FIREBASE_SUBSCRIBE, body);
    }
}
