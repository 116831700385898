<div [ngClass]="{'page-ltr': currentLang === 'en'}" class="d-flex flex-column h-100">
    <div class="modify-address-dialog-header d-flex justify-content-between align-items-center">
        <div class="title">
            {{'ADD_SHIPMENTS.MODIFY_ADDRESS' | translate}}
        </div>

        <div class="close-icon" (click)="close({})">
            <img src="../../../assets/icons/close-x.svg" alt=""/>
        </div>
    </div>
    <div class="row modify-address-body flex-1">
        <div class="w-100">
            <div class="w-100 input-item auto-complete-field"
                 [ngClass]="{'has-error': (receiverAddress.touched && receiverAddress.errors)}">
                <label class="label">
                    {{'ADD_SHIPMENTS.RECEIVER_ADDRESS'| translate | addressSystem}}
                </label>
                <p-autoComplete [formControl]="receiverAddress"
                                [forceSelection]="true"
                                field="label"
                                [delay]="700"
                                class="w-100"
                                [inputStyle]="{'width':'100%'}"
                                [suggestions]="addressOptions"
                                (completeMethod)="getAddresses($event)"
                                (onSelect)="chooseReceiverAddress($event)"
                                styleClass="address-selection"
                >
                    <ng-template let-address pTemplate="item">
                        <div class="address-item">
                            <div class="store-name text-ellipsis">{{address.arabicName + ' - ' + address.cityName + ' - ' + address.regionName}}</div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
    </div>

    <div class="row actions action-container p-2 justify-content-end" style="width: 100%;">
        <div class="d-flex">

            <div class="cancel cursor-pointer p-2 mr-3 ml-3" *ngIf="!isLoading" (click)="close({})">
                <span>{{'ACTIONS.CANCEL' | translate}}</span>
            </div>
            <div *ngIf="isLoading" class="loading">
                <span><i class="fa fa-spinner fa-pulse"></i></span>
            </div>
            <div *ngIf="!isLoading" class="cursor-pointer modify-address-button done" (click)="modifyAddress()"
                 [ngClass]="{'disabled-btn disabled': !receiverAddress.valid}">
                <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</span>
            </div>
        </div>
    </div>

</div>
