import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {timer} from 'rxjs';
import {AutoComplete} from 'primeng/autocomplete';

@Component({
    selector: 'app-input-field-component',
    templateUrl: 'input-field.component.html',
    styleUrls: ['input-field.component.scss']
})
export class InputFieldComponent implements OnInit {
    @Input() controlName: string;
    @Input() placeholder = '';
    @Input() showAddBtn = true;
    @Input() label = '';
    @Input() isRequired = false;
    @Input() showAfterIcon = false;
    @Input() icon = 'fas fa-eye';
    @Input() controlType = 'text';
    @Input() form: FormGroup;
    @Input() options: { label: string, value: any }[];
    @Input() note = '';
    @Input() disabledDefaultOption = true;
    @Input() field = '';
    @Input() displayDropDown = false;
    @Input() forceSelection = false;
    @Input() searchSuggestions = [];
    @Input() dataKey = '';
    @Input() disabled = false;
    @Input() addedExpenseType = true;
    @Input() showCustomizedCaretDown = false;
    @Input() selectBackgroundColor: string;
    @Input() appendTo: string = null;
    @Input() debounceDelay = 700;
    @Input() autocompleteWidth = '300px';

    // Error Messages
    @Input() requiredErrorMessage = 'GENERAL.REQUIRED_VALIDATION';
    @Input() minLengthErrorMessage: string;
    @Input() minLength: number;

    @Output() onAutoCompleteClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onItemChosen: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Output() changed: EventEmitter<any> = new EventEmitter();
    @Output() onIconClicked: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteExpenseTypeClicked: EventEmitter<any> = new EventEmitter();
    @Output() onAddExpenceClicked: EventEmitter<any> = new EventEmitter();
    @Output() iconAction: EventEmitter<any> = new EventEmitter();

    @ViewChild('autoComplete', {static: false}) autoComplete: AutoComplete;
    @ViewChild('text', {static: false}) text: ElementRef;
    @ViewChild('autoCompleteWithActions', {static: false}) autoCompleteWithActions: AutoComplete;


    public search($event, isAutoCompleteWithActions): void {
        let emitObject;
        if (isAutoCompleteWithActions) {
            emitObject = {event: $event, element: this.autoCompleteWithActions};
        } else {
            emitObject = {query: $event.query};
        }
        this.onSearch.emit(emitObject);
    }

    public focus(): void {
        switch (this.controlType) {
            case 'text':
                this.text.nativeElement.focus();
                break;
            case 'autoComplete':
                this.autoComplete.inputEL.nativeElement.focus();
                break;
            case 'autoCompleteWithActions':
                this.autoCompleteWithActions.inputEL.nativeElement.focus();
                break;
        }
    }

    public scrollAndFocus(): void {
        switch (this.controlType) {
            case 'text':
                this.text.nativeElement.scrollIntoView();
                this.text.nativeElement.focus();
                break;
            case 'autoComplete':
                this.autoComplete.inputEL.nativeElement.scrollIntoView();
                this.autoComplete.inputEL.nativeElement.focus();
                break;
            case 'autoCompleteWithActions':
                this.autoCompleteWithActions.inputEL.nativeElement.scrollIntoView();
                this.autoCompleteWithActions.inputEL.nativeElement.focus();
                break;
        }
    }

    public itemChosen($event): void {
        this.onItemChosen.emit({item: $event});
        setTimeout(() => this.autoComplete.inputEL.nativeElement.value = $event[this.field]);
    }

    public onClear(): void {
        this.onAutoCompleteClear.emit({item: {value: null}});
    }

    public onBlurAutoComplete(): void {
        this.onBlur.emit({});
    }

    public iconClicked(): void {
        this.onIconClicked.emit({});
    }

    public deleteButton(event, expense): void {
        this.onDeleteExpenseTypeClicked.emit({element: this.autoCompleteWithActions, event: event, expense});
    }

    public addButton(): void {
        this.onAddExpenceClicked.emit({item: this.form.controls.expensesType.value});
    }

    public hasError(errorType: string): boolean {
        return this.form && this.form.controls[this.controlName].errors && this.form.controls[this.controlName].errors[errorType] &&
            (this.form.controls[this.controlName].dirty || this.form.controls[this.controlName].touched);
    }

    ngOnInit(): void {
        timer(40).subscribe(() => {
            if (this.autoComplete && this.autoComplete.dropdownButton) {
                this.autoComplete.dropdownButton.nativeElement.setAttribute('tabindex', -1);
            }
        });
    }
}
