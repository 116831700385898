<div class="h-100  single-report-page">
    <app-admin-report [columns]="customerReport"
                      [hasFilters]="true"
                      [statusFilter] = "true"
                      [filterHubs]="hideHubsFilter()"
                      [showDateTypeFilter] = "true"
                      [dateTypeFilterList] = "dateTypeFilterList"
                      [apiUrl]="reportAPI"
                      [rowDataUrl]="customerRawDataUrl"
                      [externalCustomerId]="customerId"
                      [disablePrintAll]="true"
                      reportTitle="ADMINISTRATION.CUSTOMER_PACKAGES_DETAILS_REPORT_TITLE">
        <ng-template let-hubId>
            <app-users-list-dropdown-component appendTo="body"
                                               [hubId]="hubId"
                                               (userChanged)="onCustomerSelected($event)">
            </app-users-list-dropdown-component>
        </ng-template>
    </app-admin-report>
</div>
