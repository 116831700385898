<div class="main-div">
    <div class="panell">
        <div class="search-results"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             (scrolled)="onScroll()">
            <p-header>
                <div class="clearfix" style="text-align: -webkit-left;">
                    <span class="ui-panel-title title">
                        {{title | translate | addressSystem}}
                    </span>
                    <div *ngIf = "isExportToExcel" class="export-btn-action action cursor-pointer col-xs-4" (click)="exportToExcel()" [ngClass]="{'disabled-btn disabled': isExporting}">
                        <div class="icon">
                            <i *ngIf="!isExporting" class="fas fa-file-excel"></i>
                            <i *ngIf="isExporting" class="fas fa-spinner fa-pulse"></i>
                        </div>
                        <div class="action-label">{{"SHIPMENT.EXCEL" | translate}}</div>
                    </div>
                </div>
               <div class="d-flex flex-column">
                   <div class="search-container" style="display: flex; flex: 1">
                       <div class="search-icon">
                           <i class="fas fa-search"></i>
                       </div>
                       <div class="search-input" style="width: 100%">
                           <input type="text" style="width: 100%" placeholder="{{'GENERAL.SEARCH' | translate}}..."
                                  [(ngModel)]="searchContent"
                                  appInputDebounced
                               [debounceTime]="1000"
                               (inputDebounced)="onSearchDebounced($event)">
                       </div>
                   </div>
                   <div *ngIf="allActions" class="d-flex all-actions">
                       <div class="all-action flex-2d-center add-all" (click)="selectAllVillages(true)">{{'GENERAL.SELECT_ALL' | translate}}</div>
                       <div class="all-action flex-2d-center delete-all" (click)="selectAllVillages(false)">{{'GENERAL.UNSELECT_ALL' | translate}}</div>
                   </div>
               </div>
            </p-header>
            <p-scrollPanel [style]="{height: '310px'}">
                <div id="submitting-container" *ngIf="isLoading">
                    <p-progressSpinner>
                    </p-progressSpinner>
                </div>
                <div class="zones-panel" *ngFor="let item of items; let i = index">
                    <div class="checkbox-item d-flex"
                         [ngClass]="{'selected-item': (item.id == selectedItem || highlitedItems.includes(item.id))}"
                         (click)="selectItem(item.id)">
                        <p-checkbox *ngIf="allowAssign" name="group1"
                                    class="checkbox-label"
                                    [value]="item.id"
                                    [(ngModel)]="selectedItems"
                                    (onChange)="itemCheckedStateChanged($event,item.id)"
                                    inputId="ny"></p-checkbox>
                        <span class="flex-1 checkbox-option">
                        {{showFullAddress ? (((selectedLanguage === 'en' || !showArabic) ? item.name : item.arabicName) + (' - ' +
                            item.cityName + ' - ' + item.regionName)) :
                            (selectedLanguage === 'en' || !showArabic ? item.name : item.arabicName)}}
                    </span>
                        <span class="" *ngIf="title === 'ADMINISTRATION.CITIES' && (showPrefix || showCityColor)" [tooltip]="'ADMINISTRATION.EDIT_CITY_PREFIX' | translate">
                            <div [style.backgroundColor]="item.color" class="d-flex cursor-pointer prefix-label" (click)="openEditPrefix($event, item)" >
                                <div style="">
                                    {{item.prefix}}
                                </div>
                            </div>
                        </span>
                    </div>
                    <p-inputSwitch *ngIf="receivingPointEnabled" pTooltip="{{ 'ADMINISTRATION.ASSIGN_AS_RECEIVING_POINT' | translate }}"
                                   tooltipPosition="top" (onChange)="changeIsReceivingPoint(item)" [(ngModel)]="item.isReceivingPoint"></p-inputSwitch>
                    <button *ngIf="locationEnabled" class="btn btn-sm pick-button" (click)="changeItemLocation(item)">
                        <i class="fas fa-map-marker"></i>
                    </button>
                    <button *ngIf="locationEnabled && isSupportVillageHub" class="btn btn-sm pick-button" (click)="changeLocationHub(item)">
                        {{ 'ADD_SHIPMENTS.CHOOSE_HUB' | translate | hubTitle}}
                    </button>

                    <div *ngIf="type === 'region' && isSuperAdmin" class="btn btn-sm pick-button" (click)="uploadFile(fileChooser)"
                         style="margin: auto;">
                        <i class="fas fa-file-import"></i>
                        <input type="file"
                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                               style="width: 0px; height: 0px; opacity: 0; position: absolute" #fileChooser
                               (change)="importCitiesAndVillages($event, item.id)">
                    </div>

                </div>
            </p-scrollPanel>
            <p-footer>
                <div *ngIf="allowEdit" class="action-buttons">
                    <div class="col">
                        <div class="row action-buttons-row">
                            <button *ngIf="allowAdd" pButton type="button" (click)="addNewZone()" icon="pi pi-plus"
                                    class="ui-button-success btn add-button"
                                    [disabled]="!(parentID || title==='ADMINISTRATION.REGIONS')">
                                {{'ACTIONS.ADD' | translate}}
                            </button>
                            <button *ngIf="allowMultipleSelection"
                                    pButton type="button"
                                    (click)="resetZones()"
                                    icon="pi pi-trash"
                                    class="ui-button-success btn add-button">
                                {{'ADMINISTRATION.SHOW_ALL' | translate}}
                            </button>
                            <button pButton type="button"
                                    [disabled]="selectedItems.length ==0 || !deleteURL || (!selectedItem && !highlitedItems.length)"
                                    (click)="deleteItem()"
                                    icon="pi pi-trash"
                                    class="ui-button-danger btn delete-button">
                                {{'GENERAL.DELETE' | translate}}
                            </button>
                        </div>
                        <div class="row mt-2">
                            <button pButton *ngIf="locationEnabled" [disabled]="checkedItems.length == 0"
                                    (click)="chooseLocations()"
                                    class="ui-button-info btn location-button">
                                {{'ADMINISTRATION.CHOOSE_LOCATIONS' | translate}}
                            </button>
                        </div>

                    </div>
                </div>
            </p-footer>
        </div>
    </div>
</div>

<ng-template #selectHub>
    <input class="d-none"/>
    <app-users-list-dropdown-component
                                       [isHub]="true"
                                       [user] = "villageHubId"
                                       (userChanged)="onHubSelected($event)"></app-users-list-dropdown-component>
</ng-template>
