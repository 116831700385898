import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-activity-report',
    templateUrl: './activity-report.component.html',
    styleUrls: ['./activity-report.component.css']
})
export class ActivityReportComponent {
    public report = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.ACTIVITY_REPORT;
    public reportAPI = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.ACTIVITY_REPORT_API;

    constructor(private translate: TranslateService) {
        this.addTotal = this.addTotal.bind(this);
    }

    public addTotal(response) {
       if (response && response.data && response.data.table) {
            const total = {
                month: 'total',
                lastYearPkgsCount: response.data.lastYearSum,
                thisYearPkgsCount: response.data.thisYearSum
            };
           this.translate.stream('ADMINISTRATION.TOTAL').subscribe((res: any) => {
               total.month = res;
           });
            response.data.table.push(total);
            return response.data.table;
        }
        return [];
    }

}
