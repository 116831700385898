import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qoutes',
  templateUrl: './qoutes.component.html',
  styleUrls: ['./qoutes.component.scss']
})
export class QoutesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
