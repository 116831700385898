import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RolesService} from '../services/roles/roles.service';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({providedIn: 'root'})
export class CompanyGuard implements CanActivate {

    constructor(private router: Router,
                private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        const observable = new Observable<boolean>(
            (observer) => {
                // get the user information to know his role.
                if (route.params['companyName'] === 'nulllogin' || route.params['companyName'] === 'null') {
                    this.router.navigate(['/login']);
                    observer.next(false);
                    return;
                }
                this.authenticationService.getCompanyId({name: route.params['companyName']}).then(
                    (company: any) => {
                        if (company.companyId) {
                            observer.next(true);
                        } else {
                            this.router.navigate(['404']);
                            observer.next(false);
                        }
                    }, () => {
                        this.router.navigate(['404']);
                        observer.next(false);
                    }
                );
            }
        );
        return observable;
    }
}
