<div class="branch-item-container">
    <div>
        <input type="checkbox" [(ngModel)]="selectedHubs[hub.id]"/>
    </div>
    <div class="row branch-information">
        <div class="branch-info col-12">
            <div class="title-address">
                <div class="branch-name">
                    {{hub.name}}
                </div>
                <div class="branch-address">
                    {{hub.address.addressLine1}}, {{hub.address.city}}
                </div>
            </div>
            <div class="extra-info">
                <div class="phone">
                    P: {{hub.phone}}
                    <span>
            <a href="tel:{{hub.phone}}">
              {{"ACTIONS.CALL" | translate}}
            </a>
          </span>
                </div>
                <div class="contact">
                    {{hub.contactPerson}}
                </div>
                <div class="number-shipments-container">
                    {{'BRANCHES.NUMBER_OF_PACKAGES'|translate | packageTitle}}:{{hub.packagesNumber}}
                </div>
            </div>
        </div>
        <div class="branch-logo col-12">
            <img [src]="hub?.barcodeImage" (click)="showBarcodeImage()"
                 *ngIf="hub?.barcodeImage">
            <img src="https://bootstraplogos.com/wp-content/uploads/edd/2018/07/logo-11.png"
                 *ngIf="!hub?.barcodeImage" class="default-logo">
            <div class="number-label">
                {{hub.barcode}}
            </div>
        </div>
        <div class="actions-buttons">
            <div class="call-button action-button cursor-pointer" (click)="modifyHub()">
                {{"ACTIONS.MODIFY" | translate}}
            </div>
            <div class="call-button action-button cursor-pointer" (click)="manageshelf()">
                {{"BRANCHES.SHELVES" | translate}}
            </div>
            <div class="remove-button action-button cursor-pointer" (click)="removeHub()">
                {{"ACTIONS.REMOVE" | translate}}
            </div>
        </div>
    </div>
</div>
