<div class="main-div">
    <p-toast [baseZIndex]="toastZIndex">
    </p-toast>
    <div class="header-container">
        {{'ADMINISTRATION.ADD_NEW_ZONE'| translate}}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
    </div>
    <ng-container>
        <div *ngIf="!addNew" class="row in-row">
            <div class="col-12">
                <select class="zones-list" (change)="zoneChosen($event)" [(ngModel)]="selectedZone">
                    <option *ngFor="let zone of zones" [ngValue]="zone.id">
                        {{selectedLanguage === 'en' ? zone.name : zone.arabicName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row in-row create-new-container">
            <p-checkbox [(ngModel)]="addNew" binary="true" label="{{'ADMINISTRATION.ADD_NEW'|translate}}"
                        (onChange)="onToggleCreateNew()"></p-checkbox>
        </div>
        <div *ngIf="addNew" class="row in-row">
            <div class="col-4">
                {{'ADMINISTRATION.ZONE_ENGLISH'| translate}}
            </div>
            <div class="col-6">
                <input type="text" [(ngModel)]="englishName">
            </div>
            <div class="col-4">
                {{'ADMINISTRATION.ZONE_ARABIC'| translate}}
            </div>
            <div class="col-6">
                <input type="text" [(ngModel)]="arabicName">
            </div>
        </div>
        <div class="row submit-btn">
            <input type="submit"
                   value="{{'BRANCHES.ADD' | translate}}"
                   class="cursor-pointer submit-int"
                   (click)="onSubmit()"
                   [ngClass]="{'disable': englishName.length===0 && arabicName.length===0 && selectedZone===null || this.isLoading}">
        </div>
    </ng-container>
</div>
