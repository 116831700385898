import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {UsersService} from '../../../users/services/users.service';
import {ContainerModel} from '../../models/container.model';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {AuthenticationService} from '../../services/authentication.service';
import {CustomizationCompanyService} from '../../services/customization-company.service';
import {Companies} from '../../../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-edit-cost',
    templateUrl: './edit-cost.component.html',
    styleUrls: ['./edit-cost.component.scss']
})
export class EditCostComponent implements OnInit {
    public debounceUpdateCost;
    @Input() package;
    @Input() attribute = 'cost';
    @Output() onResetEvent: EventEmitter<{}> =
        new EventEmitter<{}>();
    public currentLang = '';
    @Input() successUpdateMsg = 'ALERTS.COST_UPDATED_SUCCESSFULLY';
    @Input() hideZeroValue = false;
    @Input() isEdit = true;
    companyId: number;
    companies = Companies;
    constructor(private usersService: UsersService,
                private translateService: TranslateService,
                private messagesService: MessageService,
                private authenticationService: AuthenticationService,
                private shipmentsService: ShipmentsService,
                private customizationCompanyService: CustomizationCompanyService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.companyId = this.authenticationService.companyId;
        this.isEdit = this.isEdit && !this.customizationCompanyService.checkCompanies(this.companies.JETT);
    }

    public updatePackageCost(event, packageObject, add) {
        packageObject[this.attribute] = packageObject[this.attribute] + add;
        event.stopPropagation();
        this.editCost(packageObject);
    }

    public editCost(packageObject) {
        if (!this.debounceUpdateCost) {
            this.debounceUpdateCost = _.debounce(() => {
                const id = packageObject.id ? packageObject.id : packageObject.packageId;
                if (typeof packageObject[this.attribute] === undefined || packageObject[this.attribute] === null || packageObject[this.attribute] < 0) {
                    this.debounceUpdateCost.cancel();
                    this.debounceUpdateCost = null;
                    if (this.onResetEvent) {
                        this.onResetEvent.emit({});
                    }
                    return;
                }
                this.findEditFunction(packageObject).subscribe(() => {
                    this.translateService.get(this.successUpdateMsg).subscribe(
                        (message) => {
                            this.messagesService.add({severity: 'success', detail: message});
                        });
                }, error => {
                    console.error(error);
                });
                this.debounceUpdateCost.cancel();
                this.debounceUpdateCost = null;
            }, 2000);
        }
        this.debounceUpdateCost();
    }


    findEditFunction(packageObject) {
        const id = packageObject.id ? packageObject.id : packageObject.packageId;
        let edit;
        if (this.attribute == 'cost') {
            edit = this.usersService.editCodCost(id, packageObject[this.attribute]);
        } else if (this.attribute == 'hubCost') {
            edit = this.usersService.editHubCost(id, packageObject[this.attribute]);
        }  else if (this.attribute === 'driverEarning') {
            edit = this.usersService.editPackageDriverEarnings(id, packageObject[this.attribute]);
        } else { // cod
            edit =this.shipmentsService.editBundleCod(id, {cod: packageObject[this.attribute]});
        }
        return edit;
    }

}
