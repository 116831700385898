<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="create-new-hub-container">
        <div class="header d-inline-flex">
            <div class="title" *ngIf="!isEditMode">
                <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.ADD_NEW_BRANCH' | translate }}</span>
                <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.ADD_NEW_BRANCH' | translate }}</span>
                <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.NEW_WAREHOUSE' | translate }}</span>
            </div>
            <div class="title" *ngIf="isEditMode">
                <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</span>
                <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</span>
                <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.EDIT_WAREHOUSE' | translate }}</span>
            </div>
            <div class="flex-1"></div>
            <div class="close-icon" (click)="closeModal()">
                <img src="../../assets/icons/cancel.svg" alt="icon" />
            </div>
        </div>

        <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
            <p-progressSpinner> </p-progressSpinner>
        </div>

        <div id="submitting-container" *ngIf="isSubmitting">
            <p-progressSpinner> </p-progressSpinner>
        </div>

        <form [formGroup]="form" *ngIf="form" #formData>
            <div class="inputs-container">
                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <app-field-input [size]="3"
                                         [label]="getWarehouseBranchLabel()"
                                         [formControlObj]="form.get('name')"
                                         [formControlName]="'name'" />
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <app-field-input [size]="3"
                                         [label]="'BRANCHES_MANAGEMENT.TABLE.CONTACT'"
                                         [formControlObj]="form.get('contactPerson')"
                                         [formControlName]="'contactPerson'" />
                    </div>
                </div>
                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <app-phone-number-input [formGroupPass]="form"
                                                [label]="'BRANCHES_MANAGEMENT.TABLE.PHONE'"
                                                [formControlNamePass]="'phone'"
                                                [formControlPass]="form.get('phone')"></app-phone-number-input>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <app-drop-down [formControlObj]="form.get('type')"
                                       (whenChange)="onChangeHubType()"
                                       [formControlName]="'type'"
                                       [label]="isStations ? 'STATIONS_MANAGEMENT.TABLE.BRANCH_TYPE' : 'BRANCHES_MANAGEMENT.TABLE.BRANCH_TYPE'"
                                       [options]="hubTypes"></app-drop-down>
                    </div>
                </div>

                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <div class="input-label address-label">{{ 'BRANCHES_MANAGEMENT.TABLE.ADDRESS' | translate }}</div>
                        <div class="input-value address-input-value">
                            <app-input-field-component
                                    [form]="form"
                                    field="label"
                                    [dataKey]="'value'"
                                    [forceSelection]="true"
                                    [displayDropDown]="true"
                                    (onAutoCompleteClear)="updateZoneForm($event,'combined')"
                                    [searchSuggestions]="combinedOptions"
                                    (onSearch)="initVillage(0, 0,$event.query)"
                                    controlName="combined"
                                    [options]="combinedOptions"
                                    [isRequired]="true"
                                    controlType="autoComplete"
                            >
                            </app-input-field-component >
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <div class="input-label address-line1-label">{{ 'GENERAL.ADDRESS_LINE1' | translate }}</div>
                        <div class="input-value">
                            <input type="text" size="3" pInputText formControlName="addressLine1" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input" *ngIf="isWarehouse">
                        <div class="input-label container-type-label"
                             [ngClass]="isInvalidDirtyOrTouched('prefix') ? 'invalid-message' : ''">{{ 'GENERAL.PREFIX' | translate }}</div>
                        <div class="input-value">
                            <input [readonly]="isEditMode" type="text" size="3" pInputText formControlName="prefix" (keydown)="preventNotChar($event)"/>
                        </div>
                        <div class="invalid-message invalid-container">
                            <span *ngIf="isInvalidDirtyOrTouched('prefix', 'maxlength')">
                              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.PREFIX_NUMBER_MAX' | translate }}
                            </span>
                        </div>
                        <div class="invalid-message invalid-container">
                            <span *ngIf="isInvalidDirtyOrTouched('prefix', 'minlength')">
                              {{ 'FULFILMENT.WAREHOUSE.VALIDATION.PREFIX_NUMBER_MAX' | translate }}
                            </span>
                        </div>
                    </div>

                    <div class="row d-inline-flex row-input"
                         *ngIf="isEnableExportingBetweenHubs && form.get('type').value !== 'SUB' && !isWarehouse">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input d-flex justify-content-around">
                            <div class="switch-btn-label">{{'BRANCHES_MANAGEMENT.TABLE.IS_HIDE_SENDER_INFO_FOR_RECEIVING_HUB' | translate}}</div>
                            <div class="switch-btn-value">
                                <p-inputSwitch formControlName="isHideSenderInfoForReceivingHub"></p-inputSwitch>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input d-flex justify-content-around">
                            <div class="switch-btn-label">{{'BRANCHES_MANAGEMENT.TABLE.IS_PREVENT_RECEIVING_HUBS_FROM_MODIFYING_PACKAGES' | translate}}</div>
                            <div class="switch-btn-value">
                                <p-inputSwitch
                                        formControlName="isPreventReceivingHubsFromModifyingPackage"></p-inputSwitch>
                            </div>
                        </div>
                    </div>

                    <div class="row splitter-container" style="width: 100%;">
                        <div class="splitter"></div>
                    </div>
                </div>
                <div class="row bottom-actions">
                    <div class="row actions d-inline-flex">
                        <div class="cancel-action col-xs-12 d-flex" (click)="closeModal()">
                            <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.CANCEL' | translate}}</span>
                        </div>
                        <div class="done-action d-flex col-xs-12" (click)="onAddHub()" [ngClass]="{'disabled-btn disabled': !form.valid}">
                            <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
