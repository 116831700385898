import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, map, debounceTime} from 'rxjs/operators';

@Directive({
  selector: '[appInputDebounced]'
})
export class InputDebouncedDirective implements AfterViewInit, OnDestroy {

  @Input() debounceTime = 500;
  @Output() inputDebounced = new EventEmitter<string>();

  private inputSubscription: Subscription;

  constructor(public elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.inputSubscription = fromEvent(this.elementRef.nativeElement, 'input')
        .pipe(map((event: Event) => {
          return ((event.target as HTMLInputElement).value);
        }))
        .pipe(debounceTime(this.debounceTime))
        .pipe(distinctUntilChanged())
        .subscribe((data) => {
          this.inputDebounced.emit(data);
        });
  }

  ngOnDestroy() {
    this.inputSubscription.unsubscribe();
  }

}
