import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../../../users/services/users.service';
import {SHARED_CONSTANTS} from '../../../../shared/services/shared_constants/constants';
import {CarriedPackagesComponent} from '../../../../containers/carried-packages/carried-packages.component';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from '../../../../shared/services/shared-service';
import {ChoosePaymentTypeComponent} from '../../../../users/choose-payment-type/choose-payment-type.component';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DateRangePickerService} from '../../../../shared/services/date-range-picker.service';
import {ChooseHubDriverPartnerComponent} from "../../../../users/choose-hub-driver-partner/choose-hub-driver-partner.component";
import {AuthenticationService} from "../../../../shared/services/authentication.service";


@Component({
    selector: 'app-hubs-mass-cod-reports',
    templateUrl: './hubs-mass-cod-reports.component.html',
    styleUrls: ['./hubs-mass-cod-reports.component.scss']
})
export class HubsMassCodReportsComponent implements OnInit {
    dateRangPickerOptions: any = {};
    dateRange: any = {};
    isLoading = false;
    type = '';
    pageNumber = 0;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    first = 0;
    totalRecords = 0;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    massCodReports = [];
    currentLang;
    isAllSelected = false;
    fromDate;
    toDate;
    search;

    @ViewChild('datepicker') datepicker;

    constructor(private activatedRoute: ActivatedRoute,
                private translate: TranslateService,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal,
                private dateRangePickerService: DateRangePickerService,
                private datePipe: DatePipe,
                private router: Router,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private sharedService: SharedService,
                private usersService: UsersService) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.setPageType();
        this.setDateRangePickerOptions();
    }

    setPageType() {
        this.activatedRoute.url.subscribe(
            () => {
                this.type = this.activatedRoute.snapshot.firstChild.url[0].path.toUpperCase();
                this.reset();
                this.getHubsMassCodReports();
            }
        );
    }

    setDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions().then(
            options => {
                this.dateRangPickerOptions = options;
            }
        );
    }

    onDateSelected(value) {
        const {start, end} = value;
        this.fromDate = new Date(start);
        this.toDate = new Date(end);
        this.reset();
        this.getHubsMassCodReports();
    }
    onSearch() {
        this.reset();
        this.getHubsMassCodReports();
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    setPaginatorQueryParam() {
    }

    reset() {
        this.massCodReports = [];
        this.pageNumber = 0;
    }

    createQuery() {
        let query = `?pageSize=${this.pageSize}&page=${this.pageNumber + 1}`;
        if (this.type !== 'RECEIVED') {
            query += `&status=${this.type}`;
        }
        if (this.fromDate && this.toDate) {
            query += `&fromDate=${this.transformDate(this.fromDate)}&toDate=${this.transformDate(this.toDate)}`;
        }
        if (this.search) {
            query += `&search=${this.search}`;
        }
        return query;
    }

    loadReportsLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.first = $event.first;
    }

    getHubsMassCodReports() {
        this.isLoading = true;
        if (this.type === 'RECEIVED') {
            this.usersService.getHubsReceivedCod(this.createQuery()).subscribe(
                (res: any) => {
                    this.massCodReports = res.data;
                    this.totalRecords = res.totalRecordsNo;
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        } else { // EXPORTED or DELIVERED
            this.usersService.getHubsMassCodReports(this.createQuery()).subscribe(
                (res: any) => {
                    this.massCodReports = res.data;
                    this.totalRecords = res.totalRecordsNo;
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        }

    }

    receiveMassCodReports(hub = null) {
        let className = 'container-carried-packages';
        if (this.currentLang === 'en') {
            className = 'container-carried-packages-en';
        }
        const modal = this.modalService.open(CarriedPackagesComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isHubPackages = true;
        modal.componentInstance.hubCodType = this.type;
        if (hub) {
            const id = this.type === 'RECEIVED' ? hub.hubId : hub.id;
            modal.componentInstance.hubId = id;
        } else {
            modal.componentInstance.isReceiveHubCod = true;
        }
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    onToggleSelection() {
        const selectedPackages = this.massCodReports.filter(pkg => (pkg.isSelected));
        if (selectedPackages) {
            this.isAllSelected = selectedPackages.length === this.massCodReports.length;
        }
    }

    onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }
        this.massCodReports.forEach((pkg: any) => {
            pkg.isSelected = selected;
        });
    }

    exportCod(hub) {
        this.confirmationService.confirm({
            message: this.translate.instant('ALERTS.EXPORT_HUB_COD_CONFIRMATION'),
            accept: () => {
                hub.isExporting = true;
                this.usersService.exportHubCod(hub.hubId).subscribe(
                    (res: any) => {
                        this.reset();
                        this.getHubsMassCodReports();
                        hub.isExporting = false;
                    }, error => {
                        console.error(error);
                        hub.isExporting = false;
                    }
                );
            },
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
        });

    }

    printCod(hub) {
        hub.isPrinting = true;
        this.usersService.printHubCod(hub.id).subscribe(
            (res: any) => {
                window.open(res.url, '_blank');
                hub.isPrinting = false;
            }, error => {
                console.error(error);
                hub.isPrinting = false;
            }
        );
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    deliverCod(hub) {
        hub.isDelivering = true;
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.componentInstance.showPrintReport = true;
        modal.componentInstance.addAttachments = false;
        modal.componentInstance.massCodId = hub.id;
        const successMessage = 'ALERTS.MASS_PACKAGE_DELIVERED_SUCCESSFULLY';
        modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, printReport: boolean, transferFees: number, attachments?: File[] }) => {
                if (data.paymentType) {
                    this.usersService.deliverHubCod(hub.id, data.paymentType, data.documentNumber,
                        this.transformDate(data.deliveryDate), data.transferFees, data.attachments)
                        .subscribe(() => {
                            this.translate.get(successMessage)
                                .subscribe(
                                    (res: any) => {
                                        hub.isDelivering = false;
                                        this.messageService.add({severity: 'success', detail: res});
                                        this.reset();
                                        this.getHubsMassCodReports();
                                        if (data.printReport) {
                                            this.printCod(hub);
                                        }
                                    }
                                );
                        }, (err) => {
                            console.error(err);
                            hub.isDelivering = false;
                        });
                } else {
                    hub.isDelivering = false;
                }
            }
        ).catch(
            () => {
                hub.isDelivering = false;
            }
        );
    }

    addToHub() {
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent,
            {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.titleKey = 'ACCOUNTING.ADD_TO_HUB_CUSTODY';
        modal.componentInstance.showHubs = false;
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, hubId?: number, driverId: number }) => {
                if (status.isSuccess) {
                    if (status.barcode) {
                        this.router.navigate([this.authenticationService.companyName + '/home/hub-custody-receive/'
                        + status.barcode], {
                            queryParams: {isReceive: false, driverId: status.driverId}
                        });
                    }
                }
            }
        ).catch(
            () => {
            }
        );
    }

}
