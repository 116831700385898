import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage/storage.service';
import {AuthenticationService} from './authentication.service';
import {APIS} from './shared_constants/apis';
import {MenuItemModel} from '../../home/models/menu-item.model';
import {UserModel} from '../models/user.model';
import {RolesService} from './roles/roles.service';
import {LOCALE_CONSTANTS} from './locale_constants/constants';
import {STORAGE_KEYS} from './storage/storage.keys';
import {CUSTOM_CONSTANTS} from './custom_constants/constants';
import {COUNTRY_BOUNDARIES} from './locale_constants/country-boundary.constants';
import {COD_COLLECTION_METHOD, JETT_COD_COLLECTION_METHOD, updatesBadgeOp} from './shared_constants/constants';
import {ShipmentsService} from '../../shipment/services/shipments.service';
import {of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {InboxService} from '../../inbox/services/inbox.service';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import {AbstractControl} from '@angular/forms';

declare let L;

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public userInfo: UserModel;
    public isHubUserRole = false;
    private permissions;
    followUpPackageCount;
    unreadMessages;

    constructor(private http: HttpClient,
                private storageService: StorageService,
                private rolesService: RolesService,
                private shipmentsService: ShipmentsService,
                private translate: TranslateService,
                private authenticationService: AuthenticationService,
                private inboxService: InboxService
    ) {
    }



    getTranslateLanguageForExcels(lang) {
        const r = Math.random().toString(36).substring(7);
        return this.http.get('./assets/i18n/' + lang.toLowerCase() + '.json?cacheBuster=' + r);
    }


    private setMinAndMaxDigitsForNumberPhone(currency: string, companyId: number) {
        if (!this.userInfo.minPhoneNumberValidationDigits || !this.userInfo.maxPhoneNumberValidationDigits) {
            if (currency === 'BHD' || currency === 'KWD' || this.userInfo.isLcl) {
                this.userInfo.minPhoneNumberValidationDigits = 7;
                this.userInfo.maxPhoneNumberValidationDigits = 15;
            } else {
                this.userInfo.minPhoneNumberValidationDigits = 10;
                this.userInfo.maxPhoneNumberValidationDigits = 10;
                if (companyId === 144) { // for OTS company
                    this.userInfo.minPhoneNumberValidationDigits = 9;
                    this.userInfo.maxPhoneNumberValidationDigits = 14;
                }
            }
        }
    }

    getFollowUpPackageCount() {
        this.shipmentsService.getFollowUpPackagesCount().subscribe((res: number) => {
            this.followUpPackageCount = res;
        }, error => {
            console.error(error);
        });
    }

    getMembers(params) {
        return this.http.get(APIS.GET_MEMBERS, {params});
    }

    getUnreadMessagesCount() {
        this.inboxService.getUnreadMessages().subscribe((res: { messagesNumber: number }) => {
            this.unreadMessages = res.messagesNumber;
        }, error => {
            console.error(error);
        });
    }

    public getUserInfo(forceRequest = false): Promise<UserModel> {
        // if there is already user info, no need to request it.
        if (this.userInfo && !forceRequest) {
            this.setMinAndMaxDigitsForNumberPhone(this.userInfo.currency, this.userInfo.companyId);
            return Promise.resolve(this.userInfo);
        }

        // request the customer info, and save a version in this service.
        return this.http.get<UserModel>(APIS.GET_USER_INFO).toPromise().then(
            (response: any) => {
                // if there is no response, logout.
                if (!response) {
                    this.authenticationService.logout();
                    return null;
                }
                this.userInfo = response;
                this.isHubUserRole = ['HUB_ADMIN', 'HUB_CUSTOMER_CARE', 'HUB_OPERATION_MANAGER'].indexOf(this.userInfo.role) !== -1;
                this.setCompanyTypeParams();
                this.setMinAndMaxDigitsForNumberPhone(this.userInfo.currency, this.userInfo.companyId);
                this.initSystemUpdatesBadge();
                return response;
            }).catch(error => {
            this.authenticationService.logout();
            return error;
        });
    }

    public deleteUserInfo() {
        this.userInfo = null;
    }

    public getCompanyAddress() {
        return this.http.get(APIS.GET_COMPANY_ADDRESS);
    }

    public previewPdfTemplate (template) {
        const params = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            pdfTemplate: template
        }
        return this.http.get(APIS.PREVIEW_TEMPLATE, {params});
    }

    public getPDFBlob(url) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(url, {headers: headers, responseType: 'blob'});
    }

    public getUserMenuItems(companyName): Promise<MenuItemModel[]> {

        if (!this.userInfo) {
            return Promise.resolve([]);
        }
        let menuItems;
        if (companyName && this.userInfo.role === 'SUPER_ADMIN') {
            menuItems = this.rolesService.getAuthorizedMenuItems('SUPER_ADMIN_AS_ADMIN');
        } else {
            menuItems = this.rolesService.getAuthorizedMenuItems(this.userInfo.role);
        }
        this.removeNotSupportedItemsByAdmin(menuItems);
        this.handleMenuItem(null, menuItems, '');
        return Promise.resolve(menuItems);
    }

    public getUserRoles() {
        return [
            'Customer',
            'Admin',
            'Accountant',
            'Dispatcher',
            'Driver',
            'Handler',
            'Customer Care',
            'Operation Manager',
            'Hub Manager'
        ];
    }

    public getAdminRoles() {
        const roles = [
            'CUSTOMER_CARE',
            'DRIVER',
            'HANDLER',
            'ADMIN',
            'ACCOUNTANT',
            'OPERATION_MANAGER',
            'HUB_MANAGER',
            'MULTIPLE_HUBS_MANAGER',
            'DISPATCHER',
            'ACCOUNTING_MANAGER',
            'CLERK',
            'CUSTOMER_ACCOUNT_MANAGER'
        ];
        if (this.userInfo.isTicketingSystemEnabled) {
            roles.push('TICKETING_USER');
            roles.push('TICKETING_SYSTEM_ADMIN');
        }
        if (this.userInfo.isEnableExportingBetweenHubs) {
            roles.push(...['HUB_ADMIN', 'HUB_CUSTOMER_CARE', 'HUB_OPERATION_MANAGER', 'HUB_CLERK']);
        }
        return roles;
    }

    public getAccountingAndOperationManagerRoles() {
        return [
            'CUSTOMER_CARE',
            'DRIVER',
            'HANDLER',
            'HUB_MANAGER',
            'MULTIPLE_HUBS_MANAGER',
            'DISPATCHER',
        ];
    }

    public getCurrency() {
        if (this.userInfo && this.userInfo.currency) {
            return this.userInfo.currency;
        }
        return this.storageService.get(STORAGE_KEYS.CURRENCY);
    }

    public getPhonePattern(international = false, currency?, component?) {
        if (this.authenticationService.companyId === Companies.DRB || this.authenticationService.companyId === 209) {
            if ('NewUserComponent' === component && !international) {
                return new RegExp(CUSTOM_CONSTANTS.PHONE_PATTERN[Companies.DRB]);
            }
        }
        if (international || this.userInfo.isLcl) {
            if (currency && LOCALE_CONSTANTS.PHONE_PATTERN[currency]) {
                return new RegExp(LOCALE_CONSTANTS.PHONE_PATTERN[currency]);
            }
            return new RegExp(LOCALE_CONSTANTS.PHONE_PATTERN['INTERNATIONAL']);
        }
        if (!(this.authenticationService.companyId === Companies.DRB || this.authenticationService.companyId === 209) && CUSTOM_CONSTANTS.PHONE_PATTERN[this.authenticationService.companyId]) {
            return new RegExp(CUSTOM_CONSTANTS.PHONE_PATTERN[this.authenticationService.companyId]);
        }
        return new RegExp(LOCALE_CONSTANTS.PHONE_PATTERN[currency || this.getCurrency()]);
    }


    public checkPhonePattern(pattern, control: AbstractControl) {
        if (control && control.value) {
            let phone = control.value.toString();
            if (phone.replace(/\s/g, '').length !== phone.length) {
                phone = phone.replace(/\s/g, '');
            }
            if (phone.replace(/-/g, '').length !== phone.length) {
                phone = phone.replace(/-/g, '');
            }

            if (phone.length && pattern.test(phone)) {
               return null;
            } else {
               return {'pattern': true};
            }
        }

        return null;
    }

    public getInitialMapView() {
        if (LOCALE_CONSTANTS.MAP_INITIAL_VIEW[this.getCurrency()]) {
            return LOCALE_CONSTANTS.MAP_INITIAL_VIEW[this.getCurrency()];
        }
        return LOCALE_CONSTANTS.MAP_INITIAL_VIEW['NIS'];
    }

    public getCountryBoundaries() {
        const currency = this.getCurrency();
        if (COUNTRY_BOUNDARIES[currency]) {
            return L.latLngBounds(COUNTRY_BOUNDARIES[currency].map(([lng, lat]) => L.latLng(lat, lng)));
        }
        return null;
    }

    public changeUserInfo(reqBody) {
        return this.http.put(APIS.CHANGE_USER_INFO, reqBody);
    }


    removeNotSupportedItemsByAdmin(menuItems) {
        for (let i = 0; i < menuItems.length;) {
            if (this.rolesService.checkConfigurationForRoute(menuItems[i].role_id, this.userInfo)) {
                menuItems.splice(i, 1);
                continue;
            }
            if (menuItems[i].subItems) {
                this.removeNotSupportedItemsByAdmin(menuItems[i].subItems);
            }
            i++;
        }
    }

    private setCompanyTypeParams() {
        switch (this.userInfo.companyType) {
            case 'LCL':
                this.userInfo.isLcl = true;
                break;
            case 'TRUCKING':
                this.userInfo.isTrucking = true;
                break;
            case 'DISTRIBUTOR':
                this.userInfo.isDistributor = true;
                break;
            case 'CORPORATE':
                this.userInfo.isCorporate = true;
                break;
        }
    }

    public hasAdditionalAddressDetails() {
        return LOCALE_CONSTANTS.ADDITIONAL_ADDRESS.indexOf(this.getCurrency()) > -1;
    }
    getHubAdminRoles() {
        return [
            'HUB_OPERATION_MANAGER',
            'HUB_CUSTOMER_CARE',
            'DRIVER',
            'HANDLER',
        ];
    }
    getHubOperationManagerRoles() {
        return [
            'HUB_CUSTOMER_CARE',
            'DRIVER',
            'HANDLER',
        ];
    }
    getFulfillmentRoles() {
        return [
            'DRIVER',
        ];
    }

    getRules() {
        const useRole = this.userInfo.role;
        const isAdmin = useRole === 'ADMIN' || useRole === 'SUPER_ADMIN';
        let userRoles = [];

        if (isAdmin) {
            userRoles = this.getAdminRoles();
        } else if (useRole === 'HUB_ADMIN') {
            userRoles = this.getHubAdminRoles();
        } else if (useRole === 'HUB_OPERATION_MANAGER') {
            userRoles = this.getHubOperationManagerRoles();
        }  else if (useRole === 'FULFILLMENT_USER') {
            userRoles = this.getFulfillmentRoles();
        } else {
            userRoles = this.getAccountingAndOperationManagerRoles();
        }

        if (useRole === 'OPERATION_MANAGER') {
            userRoles.push('CLERK');
        }
        if (this.userInfo.isEnableExportingBetweenHubs && (useRole === 'HUB_ADMIN' || useRole === 'HUB_OPERATION_MANAGER')) {
            userRoles.push('HUB_CLERK');
        }
        if (this.userInfo.isFulfilmentEnabled) {
            userRoles.push('STOCKING_AND_PACKING_EMPLOYEE');
            userRoles.push('FULFILLMENT_USER');
        }
        let excludedRoles = [];
        if (this.userInfo.companyId === 209) { // sare3 company
            excludedRoles = ['HANDLER', 'CLERK'];
        }
        if (excludedRoles.length) {
            userRoles = userRoles.filter((role: string) => (excludedRoles.indexOf(role) === -1));
        }
        return userRoles;
    }
    isLoggedInAdmin() {
        return ['SUPER_ADMIN', 'SUPER_ADMIN_AS_ADMIN', 'ADMIN'].indexOf(this.userInfo.role) > -1;
    }

    isLoggedInCanRemovePackage(): boolean {
        if (this.userInfo && this.userInfo.isAdminOnlyDeletePackages) {
            return this.isLoggedInAdmin();
        }
        return true;
    }

    initSystemUpdatesBadge() {
        const permissions = this.rolesService.getUserPermissions('SYSTEM_UPDATES', this.userInfo.role);
        if (permissions && permissions.includes('SYSTEM_UPDATE_BADGE')) {
            this.http.get(APIS.UNSEEN_SYSTEM_UPDATES_FOR_USER).subscribe((res: any) => {
                updatesBadgeOp.next(res.unseenCount);
            });
        }
    }

    getSystemUpdateCounterAsObservable() {
        return updatesBadgeOp.asObservable();
    }

    getPostponedPackagesInCurrentDate() {
        const permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', this.userInfo.role);
        const params = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (permissions && permissions.includes('POSTPONED_PACKAGES_BANNER')) {
            return this.http.get(APIS.CHECK_POSTPONED_PACKAGAES, {params});
        }
    }

    getTransferToList() {
        // currently, will use this as static
        // for future BE will implement an API to get btns dynamically

        const result = [];
        if (this.userInfo && [111].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Speedline'}),
                    from: 'eagle',
                    to: 'speed-line',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Speedline'})
                }
            );
        }
        if (this.userInfo && [247].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Mughrabe'}),
                    from: 'pegaso',
                    to: 'mughrabi',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Mughrabe'})
                }
            );
        }
        if (this.userInfo && [41].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Tornado'}),
                    from: 'wasle',
                    to: 'tornado',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Tornado'})
                }
            );
        }
        if (this.userInfo && [243].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Oxygen'}),
                    from: 'boxender',
                    to: 'oxygen',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Oxygen'})
                }
            );
        }
        if (this.userInfo && [327].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Speedline'}),
                    from: 'space',
                    to: 'speed-line',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Speedline'})
                }
            );
        }
        if (this.userInfo && [247].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Autobahn'}),
                    from: 'pegaso',
                    to: 'autobahn',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Autobahn'})
                }
            );
        }
        if (this.userInfo && [257].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Olivery'}),
                    from: 'speed-line',
                    to: 'speed-line',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Olivery'})
                }
            );
        }
        if (this.userInfo && [15].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Autobahn'}),
                    from: 'gobox',
                    to: 'autobahn',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Autobahn'})
                }
            );
        }
        if (this.userInfo && [32].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Autobahn'}),
                    from: 'fleetfoot',
                    to: 'autobahn',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Autobahn'})
                }
            );
        }
        if (this.userInfo && [261].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Maestro'}),
                    from: 'hyper',
                    to: 'maestro',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Maestro'})
                }
            );
        }
        if (this.userInfo && [261].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Speed Line'}),
                    from: 'hyper',
                    to: 'speed-line',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Speed Line'})
                }
            );
        }
        if (this.userInfo && [94].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Buraq'}),
                    from: 'zigzag',
                    to: 'buraq',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Buraq'})
                }
            );
        }
        if (this.userInfo && [207].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Bosta'}),
                    from: 'eva',
                    to: 'bosta',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Bosta'})
                }
            );
        }
        if (this.userInfo && [264].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Urgent'}),
                    from: 'royal-speed',
                    to: 'urgent',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Urgent'})
                }
            );
        }
        if (this.userInfo && [235].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Bosta'}),
                    from: 'highway',
                    to: 'bosta',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Bosta'})
                }
            );
        }
        if (this.userInfo && [235].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Tornado'}),
                    from: 'highway',
                    to: 'tornado',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Tornado'})
                }
            );
        }
        if (this.userInfo && [34].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Alkhal'}),
                    from: 'hurryup',
                    to: 'alkhal',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Alkhal'})
                }
            );
        }
        if (this.userInfo && [243].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Lionwheel'}),
                    from: 'boxender',
                    to: 'lionwheel',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Lionwheel'})
                }
            );
        }
        if (this.userInfo && [330].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Autobahn'}),
                    from: 'snap',
                    to: 'autobahn',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Autobahn'})
                }
            );
        }
        if (this.userInfo && [328].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Alkhal'}),
                    from: 'rcl2',
                    to: 'alkhal',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Alkhal'})
                }
            );
        }
        if (this.userInfo && [287].indexOf(this.userInfo.companyId) >= 0) {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'Light Shipping'}),
                    from: 'goldline',
                    to: 'lightshipping',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'Light Shipping'})
                }
            );
        }
        if (this.getCurrency() === 'SAR') {
            result.push(
                {
                    label: this.translate.get('MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERIC_TRANSFER_TO', {to: 'J&T'}),
                    from: '',
                    to: '',
                    confirmation: this.translate.get('ALERTS.GENERIC_TRANSFER_TO_CONFIRMATION', {to: 'J&T'}),
                    customUrl: ':api/jnt/transfer'
                }
            );
        }

        return of(result);
    }

    private handleMenuItem(parent, menuItems, pathSoFar) {

        if (!menuItems) {
            return;
        }
        menuItems.forEach((item) => {
            item.parent = parent;
            item.pathSoFar = pathSoFar + (item.path ? ('/' + item.path) : '');
            this.handleMenuItem(item, item.subItems, item.pathSoFar);
        });
    }

    getCodCollectionMethods(): any[] {
        return this.userInfo?.companyId === Companies.JETT ? JETT_COD_COLLECTION_METHOD : COD_COLLECTION_METHOD;
    }
}
