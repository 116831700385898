<div class="h-100 single-report-page">
    <app-admin-report [columns]="driversReports"
                      [apiUrl]="driversReportsAPI"
                      [startFromIndex] = 1
                      [isPdfRemove]="false"
                      [hasFilters]= true
                      [filterCustomer]= "true"
                      [filterHubs]= "true"
                      [filterDrivers] = true
                      [filterDate] = false
                      [TYPE] = "'drivers_Reports'"
                      class="d-flex h-100"
                      [queryParams]="{'driverType': 'TYPICAL'}"
                      reportTitle="SHIPMENT.DRIVERS_REPORTS"></app-admin-report>
</div>
