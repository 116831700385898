<p-toast [baseZIndex]="99999999999"></p-toast>

<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
    <div class="header d-inline-flex justify-content-between">
        <ng-container>
            <div class="title">{{'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES_FOR_CUSTOMERS' | translate}}</div>
        </ng-container>


        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <form [formGroup]="form">
        <div class="inputs-container">
            <div class="row row-input mb-3">
                <div class="col-6 input">
                    <app-date-range-picker [label]="'ACCOUNTING.EXPORT_DATE'"
                                           [showLabel]="true"
                                           (onSelect)="onDateSelected($event)"></app-date-range-picker>
                </div>
                <div class="col-6 input">
                    <app-multi-select
                        [formControlObj]="form.get('customer')"
                        formControlName="customer"
                        [optionValue]="'customerId'"
                        (whenSelect)="chooseStore($event)"
                        (whenSelectAll)="chooseStore($event)"
                        (whenDeSelect)="chooseStore($event)"
                        (whenDeSelectAll)="chooseStore($event)"
                        [maxSelectedLabels]="1"
                        [options]="customersList"
                        [label]="'FULFILMENT.BIN.FORM.CUSTOMER_NAME'"
                        (whenClick)="!customersList.length ? getCustomers() : null">
                    </app-multi-select>
                </div>
            </div>
            <div class="row">
                <div class="data-table bins-data-table table-container d-flex flex-1">
                    <app-generic-table [columns]="columnsDef" [config]="tableConfig" [externalFilter]="filterParams"
                                       [hidePagination]="true" [hideNoData]="true" #table>
                    </app-generic-table>
                </div>
            </div>
        </div>

        <div class="bottom-actions">
            <div class="done-action" (click)="submitForm()" [ngClass]="{'disabled-btn disabled': !form.valid}">
                <span>{{'ACTIONS.CREATE' | translate}}</span>
            </div>
        </div>
    </form>
</div>
