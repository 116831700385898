import {Injectable} from '@angular/core';
import {STORAGE_KEYS} from './storage.keys';

@Injectable({ providedIn: 'root' })
export class StorageService {
    public set(key, value) {
        localStorage.setItem(key, value);
    }

    public get(key) {
        return localStorage.getItem(key);
    }

    public remove(key) {
        localStorage.removeItem(key);
    }

    public getLanguage() {
        const selectedLanguage = this.get(STORAGE_KEYS.LANGUAGE);

        // if there is no previous selected language, select the engilsh as default.
        if (selectedLanguage) {
            return selectedLanguage;
        }

        this.set(STORAGE_KEYS.LANGUAGE, STORAGE_KEYS.LANGUAGES.ARABIC);
        return STORAGE_KEYS.LANGUAGES.ARABIC;
    }
}
