import {UserService} from "../../../../shared/services/user.service";
import {Component, HostListener, OnInit, ViewChild} from "@angular/core";
import {DriversService} from "../../../../shared/services/drivers.service";
import {DatePipe} from "@angular/common";
import {DateRangePickerService} from "../../../../shared/services/date-range-picker.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {SharedService} from "../../../../shared/services/shared-service";
import {ConfirmationService} from 'primeng/api';
import {ShipmentsService} from '../../../../shipment/services/shipments.service';
import {UsersService} from '../../../../users/services/users.service';


declare let L;

@Component({
    selector: 'app-driver-earnings-details-dialog',
    templateUrl: './driver-earnings-details-dialog.component.html',
    styleUrls: ['./driver-earnings-details-dialog.component.scss']
})
export class DriverEarningsDetailsDialogComponent implements OnInit {
    currentLang: string;

    dataList: any[] = [];
    totalRecordsNo = 0;
    dateRangPickerOptions;
    fromDate;
    toDate;
    pageSize = 20;
    page = 0;
    isLoading = false;
    driverId;
    @ViewChild('datepicker') datepicker;
    isDownloading = false;
    dateTypesFilterOptions;
    selectedDateType: any = 'CREATED_DATE';
    isMismatch = false;


    constructor(private activeModal: NgbActiveModal,
                private driversService: DriversService,
                private shipmentsService: ShipmentsService,
                private userService: UserService,
                private usersService: UsersService,
                private datePipe: DatePipe,
                private sharedService: SharedService,
                private dateRangePickerService: DateRangePickerService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
         this.currentLang = this.translateService.currentLang;
         this.setDatePickerOptions();
         this.getDriversEarningsDetails(true);
        this.dateTypesFilterOptions = [
            {label: this.translateService.instant('SHIPMENT.CREATED_DATE'), value: 'CREATED_DATE'},
            {label: this.translateService.instant('SHIPMENT.DELIVERY_DATE'), value: 'DELIVERY_DATE '},
            {label: this.translateService.instant('SHIPMENT.FIRST_PICKUP_DATE'), value: 'FIRST_PICKUP_DATE'},
            {label: this.translateService.instant('SHIPMENT.RETURN_DATE'), value: 'RETURN_DATE '},
            {label: this.translateService.instant('SHIPMENT.POSTPONED_DATE'), value: 'POSTPONED_DATE '},
        ];
    }
    setDatePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions(3, true, true , false, false, true).then(options => {
            this.dateRangPickerOptions = options;
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    @HostListener('scroll', ['$event'])
    onTableScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyDriverEarningsDetails();
        }
    }

    private loadLazyDriverEarningsDetails() {
        this.page++;
        this.getDriversEarningsDetails();
    }

    onDateSelected(value) {
        const startDate = new Date(value.start);
        const endDate = new Date(value.end);
        this.fromDate = this.transformDate(startDate);
        this.toDate = this.transformDate(endDate);
        this.getDriversEarningsDetails(true);
        const {start, end} = value;
        if ((start && end) || value.label) {
            if (value.label) {
                if (value.label == this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }
    }

    initDates() {
        this.fromDate = this.transformDate(this.dateRangPickerOptions.startDate);
        this.toDate = this.transformDate(this.dateRangPickerOptions.endDate);
        this.getDriversEarningsDetails(true);
    }
    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }


    createParams() {
        let params: any = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            pageSize: this.pageSize, page: (this.page)
        };
        if (this.fromDate && this.toDate) {
            params = {
                ...params,
                fromDate: this.transformDate(this.fromDate),
                toDate: this.transformDate(this.toDate)
            }
        }
        if (this.selectedDateType) {
            params['dateFilter'] = typeof this.selectedDateType === 'string' ? this.selectedDateType : this.selectedDateType.value;
            params['datefilter'] = typeof this.selectedDateType === 'string' ? this.selectedDateType : this.selectedDateType.value;
        }
        return params;
    }
    private getDriversEarningsDetails(forceFetch = false) {
        if (this.totalRecordsNo === this.dataList.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.page = 1;
        }
        this.isLoading = true;
        const params = this.createParams();
        (this.isMismatch ? this.usersService.getCodPackages(this.driverId, params, 'MISMATCH_COD', false) : this.driversService.getDriversEarningsDetails(this.driverId, params)).subscribe(
            (response: { data: any[], totalRecordsNo: number }) => {
                const fetchedData = response.data;
                this.totalRecordsNo = response.totalRecordsNo;
                if (forceFetch) {
                    this.dataList = fetchedData;
                } else {
                    this.dataList.push(...fetchedData);
                }
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }
    printReport(type) {
        this.confirmationService.confirm({
            message: this.translateService.instant('ACCOUNTING.DOWNLOAD_' + type + '_ALL_CONFIRMATION'),
            accept: () => {
                this.isDownloading = true;
                this.driversService.downloadDriversEarningsDetails(this.driverId, this.createParams(), type.toLowerCase()).subscribe((response: any) => {
                    window.open(response.url, '_blank');
                    this.isDownloading = false;
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    onDateTypeChanged() {
        this.getDriversEarningsDetails(true);
    }
}
