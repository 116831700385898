import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PackageContentModel} from '../../../distributor-package-form/models/package-content.model';
import {PackageContentsService} from '../../services/package-contents.service';
import {PackageContentFormComponent} from './package-content-form/package-content-form.component';

@Component({
    selector: 'app-manage-package-contents',
    templateUrl: './manage-package-contents.component.html',
    styleUrls: ['./manage-package-contents.component.scss']
})
export class ManagePackageContentsComponent implements OnInit {
    currentLang: string;
    totalRecords = 0;
    searchContent: string;
    isLoading = false;
    packageContents: PackageContentModel[] = [];
    fetchedPackageContents: PackageContentModel[] = [];

    private currentPage = 1;
    private pageSize = 20;
    triggeredPackageContent: PackageContentModel;

    constructor(private translateService: TranslateService,
                private modalService: NgbModal,
                private packageContentsService: PackageContentsService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.getPackageContents(true);
    }

    getPackageContents(forceFetch = false) {
        if (this.packageContents.length === this.totalRecords && this.currentPage > 1 && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.currentPage = 1;
            this.packageContents = [];
        }
        const query = this.createQuery();
        this.isLoading = true;
        this.packageContentsService.getContentsList(query).subscribe((res: { data: PackageContentModel[], totalRecordsNo: number }) => {
            this.fetchedPackageContents = res.data;
            this.totalRecords = res.totalRecordsNo;
            if (forceFetch) {
                this.packageContents = this.fetchedPackageContents;
            } else {
                this.packageContents = [...this.packageContents, ...this.fetchedPackageContents];
            }
            this.isLoading = false;
        }, (error) => {
            this.isLoading = false;
        });
    }

    loadLazyPackageContents() {
        this.currentPage++;
        this.getPackageContents();
    }

    onSearch() {
        this.getPackageContents(true);
    }

    addPackageContent() {
        const modal = this.modalService.open(PackageContentFormComponent, {
            backdrop: 'static',
            size: 'lg'
        });

        modal.result.then(result => {
            if (result && result.isSuccess) {
                this.translateService.get('ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ALERTS.ADDED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                this.getPackageContents(true);
            }
        });
    }

    onScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadLazyPackageContents();
        }
    }

    private createQuery() {
        let query = '?pageSize=' + this.pageSize + '&page=' + this.currentPage;
        if (this.searchContent) {
            query += '&search=' + this.searchContent;
        }
        return query;
    }

    setPackageContent(content) {
        this.triggeredPackageContent = content;
    }

    editPackageContent(content: PackageContentModel, popover: NgbPopover) {
        popover.close();
        const modal = this.modalService.open(PackageContentFormComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.isEdit = true;
        modal.componentInstance.packageContent = content;

        modal.result.then(result => {
            if (result && result.isSuccess) {
                this.translateService.get('ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ALERTS.EDITED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                this.getPackageContents(true);
            }
        });
    }

    deletePackageContent(content: PackageContentModel, popover: NgbPopover) {
        popover.close();
        this.translateService.get([
            'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ALERTS.DELETE',
            'GENERAL.YES',
            'GENERAL.NO'
        ]).subscribe(values => {
            this.confirmationService.confirm({
                message: values['ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ALERTS.DELETE'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.packageContentsService.deletePackageContent(content.id).subscribe(res => {
                        this.translateService.get('ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ALERTS.DELETED_SUCCESSFULLY').subscribe(value => {
                            this.messageService.add({
                                severity: 'success',
                                detail: value
                            });
                        });
                        this.getPackageContents(true);
                    });
                }
            });
        });
    }
}
