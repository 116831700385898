import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SHARED_CONSTANTS} from '../../services/shared_constants/constants';
import {MessageService} from 'primeng/api';

declare let L;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, AfterViewInit {

    @Input()
    location;

    @Input()
    showInitialMarker = false;

    @Input()
    flyToMarkerAdded = false;

    @Input()
    defaultZoom = 8;

    @Input()
    multipleMarkers = false;

    @Input()
    readOnly = false;

    @Output()
    clickMap = new EventEmitter<{ lat: number; lng: number }>();

    @Input() showClose = true;

    private map;
    private mapMarkers = [];
    private searchInput;
    LatInput: number;
    lngInput: number;
    private address: any;
    private currentLang: string;
    private geocodeService;

    constructor(private userService: UserService,
                private activeModal: NgbActiveModal,
                private messageService: MessageService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }

    ngAfterViewInit() {
        this.translateService.get('GENERAL.SEARCH').subscribe(transValue => {
            this.initMap(transValue);
            setTimeout(() => {
                this.map.invalidateSize();
                if (this.location) {
                    this.map.flyTo([this.location.latlng.lat, this.location.latlng.lng], this.defaultZoom);
                    if (this.showInitialMarker) {
                        this.addMarker(this.location.latlng);
                    }
                }
            }, 300);
        });
    }

    private initMap(searchPlaceholder?) {
        const map = (this.translateService.currentLang === 'en') ? 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        let initialView;
        if (this.location && this.location.latlng) {
            initialView = {
                lat: this.location.latlng.lat,
                lng: this.location.latlng.lng,
                zoom: this.defaultZoom
            };
        } else {
            initialView = this.userService.getInitialMapView();
        }
        this.map = new L.map('modal-map', {
            fullScreenControl: true
        }).setView([initialView.lat, initialView.lng], initialView.zoom);
        this.map.invalidateSize();
        L.tileLayer(map, {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        this.map.crs = L.CRS.EPSG4326;

        const searchBounds = this.userService.getCountryBoundaries();

        if (!this.readOnly) {
            this.searchInput = new L.esri.Geocoding.geosearch({
                providers: [
                    L.esri.Geocoding.arcgisOnlineProvider({
                        // API Key to be passed to the ArcGIS Online Geocoding Service
                        apikey: SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY
                    })
                ],
                useMapBounds: false,
                placeholder: searchPlaceholder,
                searchBounds
            }).addTo(this.map);

            this.searchInput.on('results', (data) => {
                this.location = data.results[0];
                this.addMarker(this.location.latlng);
            });
            this.geocodeService = L.esri.Geocoding.geocodeService({
                apikey: SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY,
            });
            this.map.addEventListener('click', (event) => {
                this.geocodeService.reverse().latlng(event.latlng).language(this.currentLang).run((error, result) => {
                    if (error) {
                        return;
                    }
                    if (['Sea', 'Gulf', 'Bay', 'Ocean'].indexOf(result.address.Type) > -1) {

                        this.messageService.add({
                            severity: 'error',
                            detail: this.translateService.instant('ALERTS.SELECT_VALID_AREA')
                        });
                        return;
                    }
                    this.location = {
                        latlng: event.latlng
                    };

                    this.clickMap.emit(event.latlng);
                    this.addMarker(event.latlng);
                    this.address = {};
                    this.address = result;
                });
            });
        }
    }

    setLongLat() {
        if (!this.LatInput || !this.lngInput) {
            return;
        }
        this.location = {latlng: {lat: this.LatInput, lng: this.lngInput}};
        this.addMarker({lat: this.LatInput, lng: this.lngInput, zoom: this.defaultZoom});
        this.geocodeService.reverse().latlng(this.location.latlng).language(this.currentLang).run((error, result) => {
            if (error) {
                return;
            }
            this.address = result;
        });
        const map = (this.translateService.currentLang === 'en') ? 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.map.setView([this.LatInput, this.lngInput], this.defaultZoom);
        this.map.invalidateSize();
    }

    public addMarker(latlng: { lat: number, lng: number, zoom?: number }) {
        if (!this.multipleMarkers) {
            this.clearMarkers();
        }

        const icon = L.divIcon({
            html: `<div class="marker-icon"></div>`,
            iconSize: [30, 45], // size of the icon
            iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor
        });
        const newMarker = L.marker([latlng.lat, latlng.lng], {
            icon: icon
        }).addTo(this.map);
        this.mapMarkers.push(newMarker);
        if (this.flyToMarkerAdded) {
            this.map.flyTo(newMarker.getLatLng(), this.defaultZoom);
        }
        return newMarker;
    }

    public clearMarkers() {
        this.mapMarkers.forEach(marker => {
            marker.remove();
        });

        this.mapMarkers = [];
    }

    public closeModal() {
        this.activeModal.close();
    }

    saveLocation() {
        if (!this.mapMarkers.length) {
            this.activeModal.close({
                label: null,
                location: null
            });
        } else {
            if (this.multipleMarkers) {
                this.activeModal.close({
                    locations: this.mapMarkers.map(marker => marker.getLatLng()),
                    address: this.address
                });
            } else {
                this.activeModal.close({
                    label: this.location && this.location.properties ? this.location.properties.LongLabel : null,
                    location: this.location,
                    address: this.address
                });
            }
        }
    }

    reset() {
        this.clearMarkers();
        this.location = null;
    }
}
