import {Component} from '@angular/core';

@Component({
    selector: 'app-archived-shipments',
    templateUrl: 'archived-shipments.component.html',
    styleUrls: ['archived-shipments.component.scss']
})

export class ArchivedShipmentsComponent {
    constructor() {}
}
