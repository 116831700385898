<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="manage-shipment-view d-flex flex-column h-100">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
      <ng-template #SenderRegionDialog let-close="close">
          <app-customer-region-filter [isSender]="true" [senderData]="senderRegionData"
                                      (onCancel)="closeSenderFilter()"
                                      (onFinish)="onFilterSenderRegion($event)"></app-customer-region-filter>
      </ng-template>
    <div class="manage-shipment-header">
        <div class="dates-and-search-filter">
            <app-date-range-picker (onSelect)="selectDateRange($event)"></app-date-range-picker>
            <div class="hidden-md-up mobile-search-filter">
                <app-field-input placeholder="GENERAL.SEARCH"
                                 [(ngModel)]="searchContent"
                                 [showIcon]="true"
                                 [showLabel]="false"
                                 (input)="onSearch()">
                </app-field-input>
            </div>
        </div>

        <app-drop-down [(ngModel)]="selectedPickupStatus"
                       [options]="pickupStatusList" filter="true"
                       [autoDisplayFirst]="true"
                       [showClear]="false"
                       [showLabel]="false"
                       (whenChange)="onStatusChanged()">
        </app-drop-down>
        <app-users-list-dropdown-component *ngIf="(selectedPickupStatus==='REJECTED_BY_DRIVER'
                                                || selectedPickupStatus==='ASSIGNED_TO_DRIVER'
                                                || selectedPickupStatus==='ACCEPTED_BY_DRIVER'
                                                || selectedPickupStatus==='IN_CAR')"
                                           [isDriver]="true" [user]="driverID"
                                           (userChanged)="onDriverSelected($event)">
        </app-users-list-dropdown-component>
        <app-drop-down [(ngModel)]="pickupLine"
                       optionLabel="name"
                       placeholder="SHIPPING_LINE"
                       [showClear]="true"
                       [filter]="true"
                       [options]="pickupLines"
                       [showLabel]="false"
                       (whenChange)="onLineSelected()">
        </app-drop-down>
        <app-field-input placeholder="GENERAL.SEARCH"
                         class="width-170"
                         [(ngModel)]="searchContent"
                         [showIcon]="true"
                         icon="fas fa-search"
                         [showLabel]="false"
                         (input)="onSearch()">
        </app-field-input>
        <app-button class="button secondary form-button"
                    (click)="resetAllFilters()"
                    [label]="'ACTIONS.RESET_FILTERS'">
        </app-button>
        <app-button class="button secondary form-button"
                    (click)="openQuickAdd(null)"
                    [icon]="'fas fa-truck-loading'"
                    [label]="'SHIPMENT.ADD_PICKUP'">
        </app-button>
        <app-button class="button secondary form-button"
                     (click)="openQuickAdd(null, true)"
                    [icon]="'fas fa-truck-loading'"
                     [label]="'SHIPMENT.CONVERT_MULTI_PICKUPS'">
        </app-button>
     </div>
    <div class="shipments-table flex-1 overflow-auto">
        <p-table #shipmentsTable dataKey="id" #table [value]="pickups" [rowHover]="true"
                 styleClass="flex-table p-datatable-customers" [customSort]="true"
                 (onPage)="setPaginatorQueryParam($event)"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 currentPageReportTemplate="{totalRecords} entries" [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr class="filters-header">
                    <th style="">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                    [disabled]="selectedPickupStatus === pickupStatusAll"
                                    (onChange)="onToggleSelectAll()"></p-checkbox>
                    </th>
                    <th style="">
                        <span class="p-float-label">
                            <input id="barcode" type="text" pInputText [(ngModel)]="searchParameters.barcode.value"
                                   (input)="onSearch()" size="30" style="width: 100%"/>
                            <label for="barcode">{{'MANAGE_SHIPMENTS.BARCODE' | translate}}</label>
                        </span>
                    </th>
                    <th style="">{{"MANAGE_SHIPMENTS.FROM" | translate}}
                        <button pButton type="button" [label]="'ACTIONS.SELECT'|translate"
                                #senderRegionPopOver="ngbPopover"
                                placement="bottom-left"
                                autoClose="outside"
                                [ngbPopover]="SenderRegionDialog"
                                class="filter-button cursor-pointer"></button>
                    </th>
                    <th style="">{{"MANAGE_SHIPMENTS.BOOKED_ON" | translate}}</th>
                    <th style="">{{"MANAGE_SHIPMENTS.PICKUP_DATE" | translate}}</th>
                    <th style="">{{"MANAGE_COD_CUSTOMERS.BUSINESS_NAME" | translate}}</th>
                    <th style="">{{"MANAGE_COD_CUSTOMERS.CUSTOMER_NAME" | translate}}</th>
                    <th style="">{{"MANAGE_SHIPMENTS.STATUS" | translate}}</th>
                    <th style="">
                        <span class="p-float-label">
                            <input id="invoice" type="text" pInputText
                                   [(ngModel)]="searchParameters.invoiceNumber.value"
                                   (input)="onSearch()" size="30" style="width: 100%"/>
                            <label for="phone">{{'MANAGE_SHIPMENTS.INVOICE' | translate}}</label>
                        </span>
                    </th>
                    <th>
                        <div class="vehicle-type-filter d-flex">
                            <p-autoComplete type="text"
                                            [delay]="700"
                                            placeholder="{{'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.VEHICLE_TYPE' | translate}}"
                                            [(ngModel)]="selectedVehicleTypeName"
                                            [suggestions]="vehicleTypesList"
                                            (completeMethod)="getVehicleTypes($event)"
                                            (onSelect)="chooseVehicleType($event)"
                                            (onClear)="onClearVehicleTypeFilter()"
                                            styleClass="vehicle-type-filter"
                                            #vehicleTypeAC
                            >
                                <ng-template let-type pTemplate="item">
                                    <div class="store-item">
                                        <div class="store-name text-ellipsis">{{selectedLanguage === 'ar' ? type.arabicName : type.name}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </th>
                    <th style="">
                        <span class="p-float-label">
                            <input id="notes" type="text" pInputText [(ngModel)]="searchParameters.notes.value"
                                   (input)="onSearch()" size="30" style="width: 100%"/>
                            <label for="notes">{{'GENERAL.NOTES' | translate}}</label>
                        </span>
                    </th>
                    <th style=""><label>{{"SHIPMENT.CONVERT" | translate}}</label> </th>
                    <th style=""><label>{{"GENERAL.DELETE" | translate}}</label> </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pickup let-columns="columns">
                <tr>
                    <td style="">
                        <p-checkbox [(ngModel)]="pickup.isSelected"
                                    [disabled]="selectedPickupStatus === pickupStatusAll" binary="true"
                                    (onChange)="onToggleSelection()"></p-checkbox>
                    </td>
                    <td style="">
                       <div class="barcode-image">
                          <div>
                              <img height="50px" class="barcode-image cursor-pointer"
                                   [src]="pickup.barcodeImage">
                              <span class="barcode-label hover-display-parent">
                            <span>{{pickup.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display" (click)="copyText(pickup.barcode)"></i>
                        </span>
                          </div>
                       </div>
                    </td>
                    <td style=""> {{pickup.customerName}} </td>
                    <td style=""> {{pickup.createdDate | date:'dd/MM/yyyy'}} </td>
                    <td style=""> {{pickup.pickupDate | date:'dd/MM/yyyy'}} </td>
                    <td style=""> {{pickup.customerBusinessName}} </td>
                    <td style=""> {{pickup.customerName}} </td>
                    <td style=""> {{pickup.status | translate}}
                        <div>
                            <ng-container [ngSwitch]="pickup.status">
                                <!-- the same view can be shown in more than one case -->
                                <div *ngSwitchCase="'ASSIGNED_TO_DRIVER'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{pickup.driverName}}
                                </div>
                                <div *ngSwitchCase="'ACCEPTED_BY_DRIVER'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{pickup.driverName}}
                                </div>
                                <div *ngSwitchCase="'REJECTED_BY_DRIVER'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{pickup.driverName}}
                                </div>
                                <div *ngSwitchCase="'IN_CAR'">
                                    {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{pickup.driverName}}
                                </div>
                                <div *ngSwitchCase="'IN_HUB'">
                                    {{pickup.hubName}} {{(pickup.label ? "-" + pickup.label : "")}}
                                </div>
                                <span *ngSwitchDefault></span>
                            </ng-container>
                        </div>
                    </td>
                    <td style=""> {{pickup.invoiceNumber}} </td>
                    <td>{{pickup.vehicleTypeName}}</td>
                    <td style="">{{pickup.notes}}</td>
                    <td style="">
                     <div *ngIf="!showConvertToPackage(pickup.status)" class="table-row-actions-container" tooltipPosition="top"
                          pTooltip="{{'MANAGE_SHIPMENTS.DISABLED_CONVERT_TO_SHIPMENT_MESSAGE'| translate | hubTitle}}">
                      <i [ngClass]="{'disabled-button disabled': true}"
                         class="print-action action cursor-pointer fas fa-box"></i>
                     </div>
                        <div *ngIf="showConvertToPackage(pickup.status)" class="table-row-actions-container">
                            <i class="print-action action cursor-pointer fas fa-box"
                               (click)="openQuickAdd(pickup)"></i>
                        </div>
                     </td>
                    <td style="">
                        <div *ngIf="!allowDelete(pickup.status)" class="table-row-actions-container" tooltipPosition="top"
                             pTooltip="{{'MANAGE_SHIPMENTS.DISABLED_DELETE_PICKUP_MESSAGE'| translate}}">
                            <i [ngClass]="{'disabled-button disabled': true}"
                               class="delete-action action cursor-pointer fas fa-trash-alt"></i>
                        </div>
                        <div *ngIf="allowDelete(pickup.status)" class="table-row-actions-container">
                            <i class="delete-action action cursor-pointer fas fa-trash-alt"
                               (click)="removePickup(pickup.id)"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template *ngIf="selectedLanguage==='en'" pTemplate="paginatorleft">
                {{'SHIPMENT.NUMBER_OF_PACKAGES'|translate | packageTitle}}: {{totalRecords}}
            </ng-template>
            <ng-template *ngIf="selectedLanguage==='ar'" pTemplate="paginatorright">
                {{'SHIPMENT.NUMBER_OF_PACKAGES'|translate | packageTitle}}: {{totalRecords}}
            </ng-template>
        </p-table>
    </div>
    <div class="table-actions">
        <app-button class="button primary form-button"
                    (click)="receivePickupsFromDriver()"
                    icon="fas fa-pencil-alt"
                    [label]="'SHIPMENT.RECEIVE_PACKAGES_FROM_DRIVER'">
        </app-button>
        <app-button class="button primary form-button"
                    (click)="printPickups()"
                    [disable]="!isThereSelection || selectedPickupStatus === pickupStatusAll"
                    icon="fas fa-pencil-alt"
                    [label]="'SHIPMENT.PRINT_PICKUPS'">
        </app-button>
        <app-button class="button primary form-button"
                    (click)="assignPickupsToDriver()"
                    [disable]="!isThereSelection || selectedPickupStatus === pickupStatusAll"
                    icon="fas fa-pencil-alt"
                    [label]="'MANAGE_SHIPMENTS.ASSIGN_TO_DRIVER'">
        </app-button>
    </div>
</div>
