<p-toast></p-toast>

<div [ngClass]="{'page-ltr': currentLang === 'en'}">

    <div class="service-types-management-container">
        <div class="d-flex top-side">
            <div class="header">
                <div class="service-types-management-title">
                    <span>{{ 'SERVICE_TYPE.MANAGE_SERVICE_TYPES' | translate }}</span>
                </div>
            </div>

            <div class="actions">
                <div class="search-filter d-flex">
                    <div class="search-icon-2">
                        <i class="fas fa-search" [ngClass]="{'fa-rotate-90' : currentLang === 'ar'}"></i>
                    </div>
                    <div class="search-input">
                        <input type="text" placeholder="{{ 'GENERAL.SEARCH' | translate }}..." appInputDebounced
                               (inputDebounced)="onSearch()" [(ngModel)]="searchContent">
                    </div>
                </div>

                <div class="add-action" (click)="onNewServiceType()"
                     [ngClass]="{'disabled-btn disabled': isLoading}">
                    <div class="icon" *ngIf="!isLoading">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="icon" *ngIf="isLoading">
                        <i class="fas fa-spinner fa-pulse"></i>
                    </div>
                    <div class="label">
                        <span>{{ 'SERVICE_TYPE.ACTIONS.NEW' | translate }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="data-table service-types-data-table">
            <div class="table-body-container overflow-auto" (scroll)="onScroll($event)">
                <p-table [value]="serviceTypes" [responsive]="true" scrollHeight="auto"
                         styleClass="table-container data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{ 'SERVICE_TYPE.TABLE.NAME' | translate }}</th>
                            <th>{{ 'SERVICE_TYPE.TABLE.ARABIC_NAME' | translate }}</th>
                            <th>{{ 'SERVICE_TYPE.TABLE.EXPECTED_DELIVERY_DAYS' | translate }}</th>
                            <th>{{ 'SERVICE_TYPE.TABLE.EXPECTED_PICKUP_DAYS' | translate }}</th>
                            <th>{{ 'SERVICE_TYPE.TABLE.LAST_ORDER_TIME' | translate }}</th>
                            <th>{{ 'SERVICE_TYPE.TABLE.COLOR' | translate }}</th>
                            <th>{{ 'SERVICE_TYPE.TABLE.IS_SHOW_CHART' | translate }}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content">
                            <app-no-data-available-component></app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-serviceType>
                        <tr (click)="onShowServiceTypeDetails(serviceType)">
                            <td>
                                {{ serviceType.name }}
                            </td>
                            <td>
                                {{ serviceType.arabicName }}
                            </td>
                            <td>
                                {{ serviceType.expectedDeliveryHours }}
                            </td>
                            <td>
                                {{ serviceType.expectedPickupHours }}
                            </td>
                            <td>
                                {{ serviceType.lastOrderTimeDate | date: 'HH:mm' }}
                            </td>
                            <td><input type="color" [(ngModel)]="serviceType.color" (change)="updateServiceType(serviceType, 'color', true)"></td>
                            <td>
                                <div class="show-service-type-chart flex-2d-center">
                                    <div class="icon">
                                        <img src="../../assets/icons/pie-chart.png" alt="">
                                    </div>
                                    <div class="toggle-button">
                                        <p-inputSwitch [(ngModel)]="serviceType.showCustomerPkgStatsChart" (onChange)="updateServiceType(serviceType, 'showCustomerPkgStatsChart')"></p-inputSwitch>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row-actions">
                                    <img class="hidden-sm-down"
                                            (click)="setTriggeredServiceType(serviceType)"
                                            style="padding: 10px"
                                            placement="{{currentLang === 'en' ? 'left': 'right'}}"
                                            autoClose="outside"
                                            #rowActionsPopOver="ngbPopover"
                                            [ngbPopover]="rowActions"
                                            src="../../assets/icons/containers-management/menu.svg"
                                            alt=""
                                    />
                                    <img class="hidden-md-up"
                                         (click)="setTriggeredServiceType(serviceType)"
                                         placement="top"
                                         autoClose="outside"
                                         #rowActionsPopOver="ngbPopover"
                                         [ngbPopover]="rowActions"
                                         src="../../assets/icons/containers-management/menu.svg"
                                         alt=""
                                    />
                                </div>
                                <ng-template #rowActions let-close="close">
                                    <div class="row-actions-container">
                                        <div class="row action" (click)="editServiceType(triggeredServiceType, rowActionsPopOver)">
                                            <div class="icon">
                                                <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
                                            </div>
                                            <div class="row-action-hidden-space"></div>
                                            <div class="label">{{ 'SERVICE_TYPE.ACTIONS.EDIT' | translate }}</div>
                                        </div>
                                        <div class="row action remove-action" (click)="deleteServiceType(triggeredServiceType, rowActionsPopOver)">
                                            <div class="icon">
                                                <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
                                            </div>
                                            <div class="row-action-hidden-space"></div>
                                            <div class="label">{{ 'SERVICE_TYPE.ACTIONS.DELETE' | translate }}</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader [type]="1" *ngIf="isLoading"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
