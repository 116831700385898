export const LOGIN_CONSTANTS = {
    LOGIN: ':api/auth/user/login',
    SUPER_ADMIN_LOGIN: ':api/auth/user/super-admin/login',
    REGISTER: ':api/auth/customer/signup',
    VERIFY: ':api/auth/customer/verify',
    FORGET_PASSWORD: ':api/auth/user/forgotPassword',
    RESET_PASSWORD: ':api/auth/user/resetPassword',
    TRACKING_USER_PASSWORD: ':api/admin/users/{entityId}/track',
    TRACKING_CUSTOMER_PASSWORD: ':api/admin/customers/{entityId}/track',
    PASSWORD_CONFIRMATION: ':api/auth/user/password/check',
    FIREBASE_SUBSCRIBE: ':api/firebase-services/gps-updates/subscription'
};
