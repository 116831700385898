import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-driver-packages-summary-report',
    templateUrl: './driver-packages-summary-report.component.html',
    styleUrls: ['./driver-packages-summary-report.component.css']
})
export class DriverPackagesSummaryReportComponent {
    public driverReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.DRIVER_REPORT;
    public reportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.DRIVER_REPORT_API;

    constructor() {

    }
}
