import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SystemUpdatesRoutingModule} from './system-updates-routing.module';
import {SystemUpdatesComponent} from './components/system-updates/system-updates.component';
import {SharedModule} from '../shared/modules/shared.module';
import {AddUpdateDialogComponent} from './components/add-update-dialog/add-update-dialog.component';

import { UpdateInfoPdfViewerComponent } from './components/update-info-pdf-viewer/update-info-pdf-viewer.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DocumentsSystemUpdatesComponent} from './components/documents-system-updates/documents-system-updates.component';
import { SystemUpdatesV2Component } from './components/system-updates-v2/system-updates-v2.component';
import { VideoSystemUpdatesComponent } from './components/video-system-updates/video-system-updates.component';
import { VideoPlayerDialogComponent } from './components/video-player-dialog/video-player-dialog.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';

@NgModule({
    imports: [
        CommonModule,
        SystemUpdatesRoutingModule,
        SharedModule,
        
        InputTextareaModule,
        PdfViewerModule,
    ],
    declarations: [SystemUpdatesComponent, AddUpdateDialogComponent, UpdateInfoPdfViewerComponent, DocumentsSystemUpdatesComponent, SystemUpdatesV2Component, VideoSystemUpdatesComponent, VideoPlayerDialogComponent]
})
export class SystemUpdatesModule {
}
