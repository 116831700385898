import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {CalendarTypeView} from 'primeng/calendar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true
  }]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {
  @Output() whenClearClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();

  @Input() cssStyle = {};
  @Input() formControlObj: FormControl;
  @Input() showLabel = true;

  @Input() label: string;
  @Input() defaultDate: Date;
  @Input() selectionMode: 'single' | 'multiple' | 'range' | undefined = 'single';
  @Input() style: {[p: string]: any} | null | undefined;
  @Input() styleClass: string;
  @Input() inputStyle: {[p: string]: any} | null | undefined;
  @Input() inputStyleClass: string;
  @Input() inputId: string;
  @Input() name: string;
  @Input() placeholder = 'DASHBOARD.CUSTOMIZE';
  @Input() disabled =	false;
  @Input() dateFormat = 'dd/mm/yy';
  @Input() inline = false;
  @Input() showOtherMonths = true;
  @Input() selectOtherMonths = false;
  @Input() showIcon = true;
  @Input() showOnFocus = true;
  @Input() showWeek	= false;
  @Input() icon = 'pi pi-calendar';
  @Input() appendTo: any;
  @Input() readonlyInput: boolean;
  @Input() shortYearCutoff = '+10';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabledDates: Date[];
  @Input() disabledDays: number[];
  @Input() monthNavigator = false;
  @Input() yearNavigator = false;
  @Input() yearRange: string;
  @Input() showTime = false;
  @Input() hourFormat = '24';
  @Input() locale =	null;
  @Input() timeOnly	= false;
  @Input() timeSeparator =	':';
  @Input() dataType	 = 'date';
  @Input() required =	false;
  @Input() tabindex:	number;
  @Input() ariaLabelledBy: string;
  @Input() iconAriaLabel: string;
  @Input() showSeconds	=	false;
  @Input() stepHour	=	1;
  @Input() stepMinute	=	1;
  @Input() stepSecond	=	1;
  @Input() maxDateCount: number;
  @Input() showButtonBar = false;
  @Input() todayButtonStyleClass =	'p-secondary-button';
  @Input() clearButtonStyleClass = 'p-secondary-button';
  @Input() baseZIndex =	0;
  @Input() autoZIndex =	true;
  @Input() panelStyleClass: string;
  @Input() panelStyle =	null;
  @Input() keepInvalid = false;
  @Input() hideOnDateTimeSelect = true;
  @Input() numberOfMonths = 1;
  @Input() view: CalendarTypeView	= 'date';
  @Input() multipleSeparator = ',';
  @Input() rangeSeparator = '-';
  @Input() touchUI = false;
  @Input() focusTrap = true;
  @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  @Input() hideTransitionOptions	=	'.1s linear';
  @Input() firstDayOfWeek = 0;
  @Input() defaultValue: any = '';
  @Input() isFilter = false;
  @Input() rangeTime = false;
  @Input() customDefault = '';
  private _value: any;
  rangeTimeValue;
  valueView = '';
  currentLang;

  constructor(private datePipe: DatePipe,
              private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }

  ngOnInit(): void {
  }

  get value() {
    return this._value;
  }

  @Input() set value(val: any) {
    this._value = val;

    if (val && this.rangeTime) {
      if (val.from && val.to) {
        this.valueView = this.transformDate(val.from) + '  ' + this.datePipe.transform(val.from.getTime(), 'HH:mm') +
            '-' + this.datePipe.transform(val.to.getTime(), 'HH:mm');
      } else {
        this.valueView = '';
      }
    }
    if (this.propagateChange) {
      this.propagateChange(val);
    } else {
      this.whenSelect.emit(val);
    }
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  private propagateChange = (_: any) => {
  }

  dateRangeTimeChanged(type, event) {
    if (type === 'FROM') {
      this.value['from'] = new Date(event);
    } else if (type === 'TO') {
      this.value['to'] = new Date(event);
    } else if (type === 'DATE') {
      this.value['from'] = new Date(event);
      this.value['to'] = new Date(event.getTime() + (24 * 60 * 60 * 1000) - 1000);
    }
    this.valueView = this.transformDate(this.value.from) + '  ' + this.datePipe.transform(this.value.from.getTime(), 'HH:mm') +
    '-' + this.datePipe.transform(this.value.to.getTime(), 'HH:mm');
  }

  clearDateRangeTimeChanged() {
    this.resetField('from', false);
    this.resetField('to', true);
    this.valueView = '';
  }

  public resetField(name, refreshData = true) {
    this.value[name] = '';
    this.valueView = '';

    if (refreshData) {
      this.whenSelect.emit(true);
    }
  }

  private transformDate(date) {
    return this.datePipe.transform(date, 'yyyy/MM/dd');
  }

  isDefaultValue(): boolean {
    if (this.value && this.customDefault === 'object') {
      const keys1 = Object.keys(this.defaultValue);
      const keys2 = Object.keys(this.value);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (this.defaultValue[key] !== this.value[key]) {
          return false;
        }
      }
      return true;
    }
    return !this.value;
  }
}
