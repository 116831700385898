




<div class="h-100 d-flex flex-column" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="header-container" *ngIf="isModal">
        {{(isEdit ? 'COMPANY.EDIT_COMPANY' : 'COMPANY.ADD_COMPANY') | translate}}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
    </div>
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="h-100 d-flex flex-column">
        <div *ngIf="!isEdit" cstyle="padding: 0 40px;">
            <p-steps
                    [(activeIndex)]="activeIndex" styleClass="company-steps"
                    [model]="items"></p-steps>
        </div>
        <form [formGroup]="form" class="h-100 d-flex flex-column" style="background: var(--admin-main-page-background)">
            <ng-container [ngSwitch]="activeIndex">
                <div *ngSwitchCase="0" class="h-100 d-flex flex-column overflow-auto">
                    <div class="h-100 d-flex flex-column overflow-auto form-container">
                        <form [formGroup]="form.controls.company" class="d-flex flex-column">
                            <div class="sub-title">{{'GENERAL.COMPANY_BASIC_INFO' | translate}}:</div>
                            <div class="images-container">
                                <div *ngIf="isEdit" class="upload-image-container" style="text-align: center">
                                    <span>{{'COMPANY.UPLOAD_COMPANY_LOGO'|translate}}</span>
                                    <app-upload-image-component [api]="uploadImageApi" [(url)]="companyInfo.logo"
                                                                (urlChange)="setImage($event,'LOGO')"
                                                                [imageSize]="130"
                                                                [imageType]="'LOGO'"
                                                                [responsePathKey]="'LOGO'"
                                                                defaultLogo="assets/icons/company-default-logo.svg">
                                    </app-upload-image-component>
                                </div>
                                <div *ngIf="isEdit" class="upload-image-container" style="text-align: center">
                                    <span>{{'COMPANY.UPLOAD_LOGIN_LOGO'|translate}}</span>
                                    <app-upload-image-component [api]="uploadImageApi"
                                                                [(url)]="companyInfo.loginLogo"
                                                                [imageSize]="130"
                                                                [responsePathKey]="'LOGIN_LOGO'"
                                                                [imageType]="'LOGIN_LOGO'"
                                                                (urlChange)="setImage($event,'LOGIN_LOGO')"
                                                                defaultLogo="assets/icons/company-default-logo.svg">
                                    </app-upload-image-component>
                                </div>
                                <div *ngIf="isEdit" class="upload-image-container" style="text-align: center">
                                    <span>{{'COMPANY.UPLOAD_BACKGROUND'|translate}}</span>
                                    <app-upload-image-component [api]="uploadImageApi"
                                                                [(url)]="companyInfo.backgroundImage"
                                                                [imageSize]="130"
                                                                [responsePathKey]="'BACKGROUND_IMAGE'"
                                                                (urlChange)="setImage($event,'BACKGROUND_IMAGE')"
                                                                [imageType]="'BACKGROUND_IMAGE'"
                                                                defaultLogo="assets/icons/company-default-logo.svg">
                                    </app-upload-image-component>
                                </div>
                                <div *ngIf="isEdit" class="upload-image-container" style="text-align: center">
                                    <span>{{'COMPANY.UPLOAD_FAVICON'|translate}}</span>
                                    <app-upload-image-component [api]="uploadImageApi" [(url)]="companyInfo.favIcon"
                                                                (urlChange)="setImage($event,'FAV_ICON')"
                                                                [responsePathKey]="'FAV_ICON'"
                                                                [imageSize]="130"
                                                                [imageType]="'FAV_ICON'"
                                                                defaultLogo="assets/icons/company-default-logo.svg">
                                    </app-upload-image-component>
                                </div>
                                <div *ngIf="isEdit" class="upload-image-container" style="text-align: center">
                                    <span>{{'COMPANY.UPLOAD_WATERMARK'|translate}}</span>
                                    <app-upload-image-component [api]="uploadImageApi" [(url)]="companyInfo.watermark"
                                                                (urlChange)="setImage($event,'watermark')"
                                                                [responsePathKey]="'WATERMARK'"
                                                                [imageSize]="130"
                                                                [imageType]="'WATERMARK'"
                                                                defaultLogo="assets/icons/company-default-logo.svg">
                                    </app-upload-image-component>
                                </div>
                                <!--                                <div class="flex-1"></div>-->
                            </div>
                            <div class="d-flex flex-row" style="flex-wrap: wrap">
                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.COMPANY_NAME"
                                                           [isRequired]="true"
                                                           controlName="name"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.COMPANY_PREFIX"
                                                           [isRequired]="false"
                                                           controlName="prefix"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.EMAIL"
                                                           [isRequired]="true" controlName="email"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.PHONE"
                                                           [isRequired]="true" controlName="phone"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.SECOND_PHONE"
                                                           [isRequired]="false" controlName="phone2"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.WHATSAPP_NUMBER"
                                                           [isRequired]="false" controlName="whatsAppPhoneNumber"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.TOLL_FREE"
                                                           [isRequired]="false" controlName="tollFree"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.REGISTRATION_NUMBER"
                                                           controlName="registrationNumber" [isRequired]="false"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component *ngIf="!isModal && isVatEnabled" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.COMPANY_VAT"
                                                           [isRequired]="true"
                                                           controlName="vat"
                                                           [form]="form.controls.company">
                                </app-input-field-component >

                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="COMPANY.COMPANY_TYPE"
                                                           controlName="companyType" [isRequired]="true"
                                                           controlType="select"
                                                           [options]="companyTypeOptions"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                            </div>
                            <div class="sub-title">{{'GENERAL.COMPANY_ADDRESS' | translate}}:</div>
                            <div class="d-flex flex-row company-address-container" style="flex-wrap: wrap">
                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                           [form]="form.controls.company"
                                                           field="label" [dataKey]="'value'"
                                                           [forceSelection]="true"
                                                           [displayDropDown]="true"
                                                           [searchSuggestions]="combinedOptions"
                                                           (onAutoCompleteClear)="updateZoneForm($event,'villageId')"
                                                           (onSearch)="initVillage(0, 0,$event.query)"
                                                           controlName="combined" [options]="combinedOptions"
                                                           (onItemChosen)="onSelectAddress()"
                                                           [isRequired]="true" controlType="autoComplete">
                                </app-input-field-component>
                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.ADDRESS_LINE1"
                                                           controlName="addressLine1" [isRequired]="true"
                                                           [form]="form.controls.company.controls.address">
                                </app-input-field-component>
                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="COMPANY.COMPANY_NAME_IN_URL"
                                                           controlName="companyNameInUrl" [isRequired]="true"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="COMPANY.DOMAIN_NAME"
                                                           controlName="domain" [isRequired]="true"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.CURRENCY"
                                                           controlName="currency" [isRequired]="true"
                                                           controlType="select"
                                                           [options]="currencyOptions"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                           label="GENERAL.PKG_PDG_TEMPLATE"
                                                           controlName="packagePdfTemplate" [isRequired]="true"
                                                           controlType="select"
                                                           [showAfterIcon]="!isModal"
                                                           (iconAction)="previewPdfTemplate()"
                                                           [options]="pkgPdfTemplateOptions"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component label="COMPANY.ADDRESS_LANGUAGE_IN_PACKAGE"
                                                           class="col-lg-3 col-md-4 col-sm-6"
                                                           controlName="addressLanguageInPackagePdf" [isRequired]="true"
                                                           controlType="select"
                                                           [options]="languageOptions"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="COMPANY.WEBHOOK_URL"
                                                           controlName="webhookUrl" [isRequired]="false"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <app-input-field-component *ngIf="!isSuperAdmin"
                                                           class="col-lg-6 col-md-4 col-sm-6 textAreaAds"
                                                           label="COMPANY.ADS"
                                                           controlType="textarea"
                                                           controlName="ads" [isRequired]="false"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="COMPANY.CUSTOMER_ANDROID_APP"
                                                           controlName="customerAndoidUrl" [isRequired]="false"
                                                           [form]="form.controls.company">
                                </app-input-field-component>

                                <app-input-field-component *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6"
                                                           label="COMPANY.CUSTOMER_IOS_APP"
                                                           controlName="customerIosUrl" [isRequired]="false"
                                                           [form]="form.controls.company">
                                </app-input-field-component>
                                <div *ngIf="showBooleanSettings()" class="input-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ENABLE_GUEST_ADD_PACKAGE'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isGuestAddPackage"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.USE_BANK_TRANSFER'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isUseBankTransfer"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.SHOW_PHONE_IN_PACKAGE_REPORT'|translate }}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowPhoneInPackageReport"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.DRIVER_PICK_UNASSIGNED_PACKAGE'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isDriverPickupUnassignedPackage"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.EXECLUDE_SORT'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isExcludeSort"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ADMIN_ONLY_CHANGE_FROM_DELIVERED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAdminOnlyChangeFromDelivered"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.SHOW_DRIVER_NAME_IN_PACKAGE_TRACKING'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowDriverNameInPackageTracking"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.CHANGE_RECEIVED_SORTED_PACKAGE_STATUS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isChangeReceivedOrSortedPackageStatus"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.HUB_MANAGER_ADD_PACKAGE'|translate | hubTitle}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isHubManagerAddPackage"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.HIDE_PARTNER_SENDER'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isHidePartnerSender"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container" tooltipPosition="top"
                                             pTooltip="{{'COMPANY.ALLOW_NON_ADMIN_CHANGE_COST_TOOLTIP'| translate}}">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ALLOW_NON_ADMIN_CHANGE_COST'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isNonAdminChangeCost"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container" tooltipPosition="top"
                                             pTooltip="{{'COMPANY.ALLOW_NON_ADMIN_CHANGE_COST_TOOLTIP'| translate}}">
                                            <div class="package-switch-label">
                                                {{'COMPANY.PRINT_CASH_RECEIPT_WITH_MASS_REPORT'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPrintCashReceiptWithMassReport"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_HIDE_COST_FROM_RECIEVE_COD_REPORT'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isHideCostFromRecieveCodReport"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.PRINT_DRIVER_PROOF_WITH_RUNSHEET'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPrintProofReportWithDriverRunsheet"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.SHOW_BARCODE_IMAGE_IN_MASS_REPORT'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowBarcodeImageInMassReport"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ALLOW_OPERATION_MANAGER_ADD_CUSTOMER'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAllowOperationManagerAddCustomer"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ADD_CUSTOMER_FROM_QUICK_ADD_SHIPMENT_FORM'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAddCustomerFromQuickAddShipmentForm"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ALLOW_PARTNER_CHANGE_COD'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAllowPartnerChangeCod"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PICKUP_SUPPORTED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isPickupSupported"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SUPPORT_ADDING_DRAFT_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportAddingDraftPackagesByCustomers"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ALLOW_ALL_CUSTOMERS_TO_ADD_DRAFT_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isAllCustomersAddDraftPackages"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-label">
                                            {{'COMPANY.IS_SEPARATE_READY_COD_TO_DELIVER_AND_COD_AT_HAND'|translate}}
                                        </div>
                                        <div class="package-switch">
                                            <p-inputSwitch
                                                    formControlName="isSeperateReadyCodToDeliverandCodAtHand"></p-inputSwitch>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_CHANGE_EXPORTED_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isChangeExportedPackages"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DELIVER_PARTIAL_MASS_REPORT'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isDeliverPartialMassReport"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_USE_STICKER_PRINTER_FOR_AWB'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isUseStickerPrinterForAwb"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isEdit" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ALLOW_BILLING'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isAllowBilling"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isEdit" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DISABLE_SYSTEM'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isBlockBilling"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ALLOW_OPERATION_MANAGER_TO_RECEIVE_COD'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAllowOperationManagerReceiveCod"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ALLOW_DISPATCHER_TO_RECEIVE_COD'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAllowDispatcherReceiveCod"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PRINT_TWO_COPIES_FOR_SWAP'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPrintTwoCopiesForSwap"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PARTIAL_DELIVERY_ENABLED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPartialDeliveryEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_HIDE_DELIVERED_TO_SENDER_IN_CHANGE_STATUS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isHideDeliveredToSenderInChangeStatus"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SUPPORT_RECEIVE_WITHOUT_RELEASING_CUSTODY' | translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isSupportReceiveWithoutReleasingCustody"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DRIVER_EARNING_ENABLED' | translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isDriverEarningEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SUPPORT_PARCEL_TRACKING'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isSupportParcelTracking"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PRINT_MASS_COD_REPORT_ONCE_BY_ACCOUNTANT'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPrintMassCodReportOnceByAccountant"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DRIVER_CAN_REQUEST_COD_CHANGE'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isDriverCanRequestCodChange"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.SHOW_ADD_SHIPMENT_IN_SIDE_MENU'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowAddShipmentInSideMenu"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DRIVER_CAN_REQUEST_RETURN_PACKAGE'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isDriverCanReturnPackage"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.Is_BUNDLE_POD_ENABLED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isBundlePodEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ADMIN_CHANGE_COD_FOR_RETURNED_AND_DELIVERED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAdminChangeCodForReturnedAndDelivered"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_HIDE_DELIVERED_MASS_COD_REPORTS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isHideDeliveredMassCodReports"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_FULFILMENT_ENABLED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isFulfilmentEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_RECEIVING_POINT_ENABLED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isReceivingPointEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_FOLLOW_UPS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isShowFollowUps"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_CURRENT_REPORTS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isShowCurrentReports"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_DRIVER_LOCATION_IN_PACKAGE_TRACKING'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowDriverLocationInPackageTracking"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_MASS_COD_REPORTS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isShowMassCodReports"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DISABLE_COST_IN_ADD_PACKAGE'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isDisableCostInAddPackage"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_DRIVER_PICKUP_ACCEPTED_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isDriverPickupAcceptedPackages"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_DELAYED_COUNT_IN_CUSTOMER_DASHBOARD'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowDelayedCountInCustomerDashboard"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PROMPT_NOTE_FOR_DRIVER_IN_PACKAGE_DELIVERY'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPromptNoteForDriverInPackageDelivery"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_PAYMENT_TYPES_WHEN_DRIVER_DELIVER'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowPaymentTypesWhenDriverDeliver"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_CUSTOMERS_TO_HUB_MANAGER'|translate | hubTitle}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowCustomersToHubManager"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_NOTIFY_TO_CC_WHEN_FAILED_DELIVERY_PKG_AND_CHANGE_COD'|translate | hubTitle}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_AUTO_APPROVE_CUSTOMERS_AFTER_SIGNUP'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAutoApproveCustomersAfterSignup"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_DETAILED_PACKAGE_HISTORY_FOR_ALL_CUSTOMERS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isShowDetailedPackageHistoryForALLCustomers"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PRICING_PER_SERVICE_TYPE_ENABLED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPricingPerServiceTypeEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.HAS_ROUTE_OPTIMIZATION'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="hasRouteOptimization"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PICKUP_UNLISTED_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPickupUnListedPackagesByAdmin"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_TICKETING_SYSTEM_ENABLED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isTicketingSystemEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="customizationCompaniesService.checkCompanies(companies.SEND)" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_ARAMEX_TRANSFER'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isEnableAramexTransfer"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.ENABLE_MESSAGING_SYSTEM'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableMessaging"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ALLOW_ADDING_CUSTOMERS_SMS_GATEWAY'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAllowAddingCustomerSmsGateway"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_PACKAGE_FINGERPRINT_REQUIRED'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isPackageFingerprintRequired"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_FORCE_ADDING_INVOICE_NUMBER_WHEN_ADDING_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isForceAddingInvoiceNumberWhenAddingPackages"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ALLOW_ADDING_TELECOME_DEVICE'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAllowAddingTelecomeDevices"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_RECEIVING_PACKAGES_WITHOUT_SCAN_ITEMS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isReceivingPkgsWithoutScanItems"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_AUTO_ASSIGN_TO_SHIPPING_LINE_DRIVER'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch
                                                        formControlName="isAutoAssignToShippingLineDriver"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_AUTO_ASSIGN_DRIVERS_TO_PACKAGES'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch (onChange)="autoAssignDriverChanged($event)" [(ngModel)]="isAutoAssignDriversToPackages"
                                                        formControlName="isAutoAssignDriversToPkgs"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin && isAutoAssignDriversToPackages" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">

                                            <div class="flex-1">
                                                <app-input-field-component
                                                        controlName="autoAssignPackagesToDriversMethod"
                                                        label="ON_DEMAND.METHODS.AUTO_ASSIGN_PACKAGES_TO_DRIVER_METHOD"
                                                        controlType="select"
                                                        [isRequired]="true"
                                                        [options]="autoAssignPackagesToDriverMethodOptions"
                                                        [form]="form.controls.company">
                                                </app-input-field-component>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_AUTO_ASSIGN_TO_ONLINE_DRIVERS_ONLY'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch [disabled]="!isAutoAssignDriversToPackages"
                                                        formControlName="isAutoAssignToOnlineDriversOnly"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_AUTO_ASSIGN_FOR_ALL_CUSTOMERS'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch [disabled]="!isAutoAssignDriversToPackages"
                                                        formControlName="isEnableAutoAssignForAllCustomers">
                                                </p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SHOW_CUSTOMER_FIELD_IN_ADD_NEW_TICKET'|translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isShowCustomerFieldWhileAddingTicket"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_DELIVERY_VERIFICATION_PIN_CODE_FOR_PKGS' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableDeliveryVerificationPinCodeForPkgs"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLED_PIN_VERIFICATION_FOR_RETURNED_PACKAGES_OR_MASS_REPORTS' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnabledPinVerificationForReturnedPackagesOrMassReports"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_HIDE_PACKAGES_IN_DRIVER_APPS' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isHidePackagesInDriversApps"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_DELETING_CUSTOMER_ACCOUNT_BY_CUSTOMER' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableDeletingCustomerAccountByCustomer"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.is_support_international_phone_numbers' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportInternationalPhoneNumbers"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_EXCLUDE_SYSTEM_ZONES' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isExcludeSystemZones"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.isEnableExportingBetweenHubs' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableExportingBetweenHubs"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_BUNDLE_SUPPORTED' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isBundleSupported"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_BLACK_LIST_ENABLED' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isBlackListEnabled"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_CUSTOMER_COMMISSION_FEE' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableCustomerCommissionFee"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isSuperAdmin">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_IMILE_TRANSFER' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableImileTransfer"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_VAT' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableVat"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_CUSTOMER_WALLET' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isEnableCustomerWallet"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'MANAGE_COD_CUSTOMERS.INVOICES.ENABLE_BILLING_SYSTEM'|translate}}
                                            </div>
                                        </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isInvoicingEnabled" (onChange)="changeInvoiceConfigration('INVOICE')"></p-inputSwitch>
                                        </div>
                                    </div>
                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_ENABLE_MASS_INVOICE' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportMassInvoices" (onChange)="changeInvoiceConfigration('MASS_INVOICE')"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.PACKAGE_ADDITION_FEES' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportAdditionalFees"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SUPPORT_DELIVERING_ITEMS_PARTIALLY' |translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportDeliveringPackageItemsPartially"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SUPPORT_RETURNED_BUNDLE' | translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportReturnedBundles"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="isSuperAdmin" class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="package-switch-container">
                                            <div class="package-switch-label">
                                                {{'COMPANY.IS_SUPPORT_VILLAGE_HUB' | translate}}
                                            </div>
                                            <div class="package-switch">
                                                <p-inputSwitch formControlName="isSupportVillageHub"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </form>
                        <div class="flex-1 hidden-sm-down">

                        </div>
                        <div class="d-flex flex-row buttons-container" style="padding: 10px 0; background: var(--white)">
                            <div class="flex-1 hidden-sm-down"></div>
                            <button *ngIf="!isEdit" class="controller-button cursor-pointer next d-flex"
                                    [disabled]="form.controls.company.invalid"
                                    (click)="!form.controls.company.invalid && nextStep()" style="align-items: center">
                                <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.NEXT" | translate }}</span>
                                <i class="fas fa-chevron-right"></i>
                            </button>
                            <button *ngIf="isEdit" type="button" class="controller-button cursor-pointer next d-flex"
                                    (click)="submit()" [disabled]="form.invalid"
                                    style="align-items: center">
                                <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.SUBMIT" | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="1" class="h-100 d-flex flex-column overflow-auto">
                    <div class="h-100 d-flex flex-column overflow-auto">
                        <div class="h-100 d-flex flex-column overflow-auto">
                            <form [formGroup]="form.controls.admin" class="d-flex flex-column">
                                <div class="sub-title">{{'GENERAL.ADMIN_BASIC_INFO' | translate}}:</div>
                                <div class="d-flex flex-row" style="flex-wrap: wrap">
                                    <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                               label="GENERAL.FIRST_NAME"
                                                               [isRequired]="true"
                                                               controlName="firstName"
                                                               [form]="form.controls.admin">
                                    </app-input-field-component>

                                    <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                               label="GENERAL.LAST_NAME"
                                                               [isRequired]="true" controlName="lastName"
                                                               [form]="form.controls.admin">
                                    </app-input-field-component>

                                    <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.EMAIL"
                                                               [isRequired]="true" controlName="email"
                                                               [form]="form.controls.admin">
                                    </app-input-field-component>
                                    <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.PHONE"
                                                               [isRequired]="true" controlName="phone"
                                                               [form]="form.controls.admin">
                                    </app-input-field-component>
                                </div>
                                <div class="sub-title">{{'GENERAL.ADMIN_ADDRESS' | translate}}:</div>
                                <div class="d-flex flex-row" style="flex-wrap: wrap">
                                    <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                               label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                               [form]="form.controls.admin"
                                                               field="label" [dataKey]="'value'"
                                                               [forceSelection]="true"
                                                               [displayDropDown]="true"
                                                               [searchSuggestions]="combinedOptions"
                                                               (onAutoCompleteClear)="updateZoneFormAdmin($event,'villageId')"
                                                               (onSearch)="initVillage(0, 0,$event.query)"
                                                               controlName="combinedAdmin" [options]="combinedOptions"
                                                               (onItemChosen)="onSelectAddressAdmin()"
                                                               [isRequired]="true" controlType="autoComplete">
                                    </app-input-field-component>
                                    <app-input-field-component class="col-lg-3 col-md-4 col-sm-6"
                                                               label="GENERAL.ADDRESS_LINE1"
                                                               controlName="addressLine1" [isRequired]="true"
                                                               [form]="form.controls.admin.controls.address">
                                    </app-input-field-component>
                                </div>
                            </form>
                            <div style="padding: 0 15px">
                                <re-captcha
                                        formControlName="captcha"
                                        siteKey="6LfMb7UUAAAAALmLfJDZ1PoPOLgWDOVEX88obW0q"
                                ></re-captcha>
                            </div>
                        </div>
                        <div class="flex-1">

                        </div>
                    </div>
                    <div class="flex-1" style=" padding: 0 15px">

                    </div>
                    <div class="d-flex flex-row" style="padding: 10px 0; background: var(--white)">
                        <div class="controller-button cursor-pointer previous d-flex"
                             (click)="previousStep()" style="align-items: center">
                            <i class="fas fa-chevron-left"></i>
                            <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.PREVIOUS" | translate }}</span>
                        </div>
                        <div class="flex-1"></div>
                        <button type="button" class="controller-button cursor-pointer next d-flex"
                                (click)="submit()" [disabled]="form.invalid"
                                style="align-items: center">
                            <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.SUBMIT" | translate }}</span>
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
