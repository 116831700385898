export enum Currency {
    NIS = 'NIS',
    USD = 'USD',
    JOD = 'JOD',
    KWD = 'KWD',
    BHD = 'BHD',
    SAR = 'SAR',
    IQD = 'IQD',
    AED = 'AED',
    TRY = 'TRY',
    OMR = 'OMR',
    LYD = 'LYD',
    EGP = 'EGP',
    QAR = 'QAR'
}

export const COUNTRY_CODE_CURRENCY_MAP = {
    'PS': 'NIS',
    'AE': 'AED',
    'US': 'USD',
    'JO': 'JOD',
    'KW': 'KWD',
    'BH': 'BHD',
    'SA': 'SAR',
    'QA': 'QAR',
    'IQ': 'IQD',
    'TR': 'TRY',
    'OM': 'OMR',
    'LY': 'LYD',
    'EG': 'EGP'
};

export const LOCALE_CONSTANTS = {
    CURRENCIES: [
        {value: Currency.NIS, label: 'NIS'},
        {value: Currency.USD, label: 'USD'},
        {value: Currency.JOD, label: 'JOD'},
        {value: Currency.KWD, label: 'KWD'},
        {value: Currency.BHD, label: 'BHD'},
        {value: Currency.SAR, label: 'SAR'},
        {value: Currency.IQD, label: 'IQD'},
        {value: Currency.TRY, label: 'TRY'},
        {value: Currency.OMR, label: 'OMR'},
        {value: Currency.LYD, label: 'LYD'},
        {value: Currency.EGP, label: 'EGP'}
    ],
    PHONE_PATTERN: {
        NIS: /^((([0]{2}|\+)?972|([0]{2}|\+)?970)[ -]?)?(([0]?[5][0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{3})|([0]?[^015][ -]?[0-9]{7}))$/,
        JOD: /(^(\(([0\u0660]{2}|\+)?962\)|([0\u0660]{2}|\+)?962)[ -]?([56\u0665\u0666]\d{7}|[^56\u0665\u0666]\d{8})|^((?!(962|([0\u0660]{2}|\+)962))[0\u0660]?([56\u0665\u0666]\d{7}|[^56\u0665\u0666]\d{8})))$/,
        BHD: /(^(\((00|\+)?973\)|(00|\+)?973)[ -]?[0-9\u0660-\u0669]{8}|^((?!(973|(00|\+)973))[0-9\u0660-\u0669]{8}))$/,
        KWD: /^(([0]{2}|\+)?965[ -]?)?[569]\d{7}$/,
        IQD: /^((([0\u0660]{2}|\+)?964)?[0\u0660]?[\s-]?[7\u0667][0-9\u0660-\u0669]{2}[\s-]?[0-9\u0660-\u0669]{3}[\s-]?[0-9\u0660-\u0669]{4})$/,
        TRY: /^((00|\+|\u0660\u0660)(90|\u0069\u0660)[ -]?[0-9\u0660-\u0669]{3}[ -]?[0-9\u0660\u0669]{7}|(?!(90|0090|\+90))[0-9\u0660-\u0669]{3}[ -]?[0-9\u0660\u0669]{7})$/,
        SAR: /^((\(([0\u0660]{2}|\+)?(966|\u0669\u0666\u0666)\)|([0\u0660]{2}|\+)?(966|\u0669\u0666\u0666))[ -]?[15\u0661\u0665][0-9\u0060-\u0669]{8}|((?!(966|([0\u0660]{2}|\+)966))[0\u0660]?[15\u0661\u0665][0-9\u0660-\u0669]{8}))$/,
        OMR: /^((\+968|00968)?[7962](\d{7})$)/,
        QAR: /^(([0]{2}|\+)?974[ -]?)?\d{8}$/,
        LYD: /(^(09[1245])(\d{7,8}$))/,
        AED: /^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/,
        EGP: /^(\+201|01|00201|1)[0-9]{9}$/,
        INTERNATIONAL: /^\\+?(\\([0-9]{1,4}\\)|[0-9]{1,4}-|[0-9]{1,4})[0-9]{5}[0-9]{0,10}$/
    },
    MAP_INITIAL_VIEW: {
        'NIS': {
            lat: 31.9022106,
            lng: 35.2111022,
            zoom: 8
        },
        'JOD': {
            lat: 31.7185837,
            lng: 36.2055386,
            zoom: 8
        },
        'KWD': {
            lat: 29.3685288,
            lng: 47.5817882,
            zoom: 9
        },
        'BHD': {
            lat: 26.0584304,
            lng: 50.5453849,
            zoom: 10
        },
        'SAR': {
            lat: 24.0096415,
            lng: 43.7501391,
            zoom: 6
        },
        'IQD': {
            lat: 33.5386707,
            lng: 43.6902603,
            zoom: 6.5
        },
        'TRY': {
            lat: 39.0059555,
            lng: 35.7214276,
            zoom: 6
        },
        'OMR': {
            lat: 21.3967985,
            lng: 60.6512407,
            zoom: 6
        },
        'LYD': {
            lat: 27.5,
            lng: 19,
            zoom: 5.5
        },
        'EGP': {
            lat: 26.5,
            lng:  29.5,
            zoom: 6.5
        }
    },
    ADDITIONAL_ADDRESS: [
        'BHD'
    ],
    ADDRESS_TYPES: {
        BHD: 'number',
        DEFAULT: 'text'
    }
};
