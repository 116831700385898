import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {HubModel} from '../../../shared/models/hub.model';
import {ShelveModel} from '../../../shared/models/shelve.model';
import {CarriedPackagesComponent} from '../../../containers/carried-packages/carried-packages.component';
import {ConfirmationService} from 'primeng/api';
import {HubsService} from '../../../shared/services/hubs.service';
import {ShelveCitiesComponent} from './shelve-cities/shelve-cities.component';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {UserService} from '../../../shared/services/user.service';

@Component({
    selector: 'app-branch-card-component',
    templateUrl: 'branch-card.component.html',
    styleUrls: ['branch-card.component.scss']
})
export class BranchCardComponent implements OnInit {
    @ViewChild('rowActionsPopOver', {static: false}) rowActionsPopOver: NgbPopover;
    // Inputs
    @Input() hub: HubModel;
    @Input() permissions;
    @Input() shelve: ShelveModel;
    @Input() isShelve = false;
    @Input() isWarehouse = false;
    @Input() isStations = false;
    // Outputs
    @Output() onModifyHub: EventEmitter<{ hub: HubModel }> =
        new EventEmitter<{ hub: HubModel }>();
    @Output() onRemoveHub: EventEmitter<{ hub: HubModel }> =
        new EventEmitter<{ hub: HubModel }>();
    @Output() onClickShowDetails: EventEmitter<{ hub: HubModel }> =
        new EventEmitter<{ hub: HubModel }>();
    @Output() onCopyBarcode: EventEmitter<{barcode: string }> =
        new EventEmitter<{ barcode: string }>();
    @Output() onSelectShelf: EventEmitter<{shelve: ShelveModel, event: any }> =
        new EventEmitter<{ shelve: ShelveModel, event: any }>();
    @Output() onManageWarehouse: EventEmitter<{ hub: HubModel, type: string }> =
        new EventEmitter<{ hub: HubModel, type: string }>();

    @Output() onModifyShelve: EventEmitter<{ shelve: ShelveModel }> =
        new EventEmitter<{ shelve: ShelveModel }>();
    @Output() onRemoveShelve: EventEmitter<{ shelve: ShelveModel }> =
        new EventEmitter<{ shelve: ShelveModel }>();
    @Output() onRetrieve: EventEmitter<any> =
        new EventEmitter<any>();
    public currentLang = '';
    loadingCities = false;

    constructor(public router: Router,
                public authService: AuthenticationService,
                private translateService: TranslateService,
                private rolesService: RolesService,
                private userService: UserService,
                private modalService: NgbModal,
                private confirmationService: ConfirmationService,
                private hubsService: HubsService) {}
    ngOnInit() {
        if (!this.permissions) {
            this.permissions = this.rolesService.getUserPermissions('MANAGE_BRANCHES', this.userService.userInfo.role);
        }
        this.currentLang = this.translateService.currentLang;
    }
    public getAddress(address) {
        return address ?  address.village + ' - ' + address.city + ' - ' + address.region : '-';
    }
    public copyBarcode(barcode) {
        this.onCopyBarcode.emit({barcode: barcode });
    }
    public modify() {
        if (this.isShelve) {
            this.onModifyShelve.emit({shelve: this.shelve});
        } else {
            this.closeRowActionsPopOver();
            this.onModifyHub.emit({hub: this.hub});
        }
    }
    public remove() {
        if (this.isShelve) {
            this.onRemoveShelve.emit({shelve: this.shelve});
        } else  {
            this.onRemoveHub.emit({hub: this.hub});
        }
    }
    public showHubDetails() {
        this.onClickShowDetails.emit({hub: this.hub});
    }
    public selectShelf(event) {
        if (!this.isShelve) {
            return;
        }
        this.onSelectShelf.emit({shelve: this.shelve, event: event});
    }
    manageWarehouse(manageType) {
        this.onManageWarehouse.emit({hub: this.hub, type: manageType});
    }
    public closeRowActionsPopOver() {
        if (this.rowActionsPopOver && this.rowActionsPopOver.isOpen()) {
            this.rowActionsPopOver.close();
        }
    }

    public  getShelfCities() {
        this.loadingCities = true;
        this.hubsService.getShelveCities(this.shelve.id).subscribe(
            (res: any) => {
                this.loadingCities = false;
                const modal = this.modalService.open(ShelveCitiesComponent, <any>{
                    backdrop: 'static',
                    windowClass: 'shelve-cities-modal',
                    size: 'md',
                    backdropClass: 'shelve-cities-modal-backdrop'
                });
                modal.componentInstance.cities = res.map(city => city.name);
                modal.result.then().catch(
                    (error) => {}
                );
            }, error => {
                this.loadingCities = false;
                console.log(error);
            }
        );
    }
    public openShelvePackages() {
        let className = 'container-carried-packages';
        if (this.currentLang === 'en') {
            className = 'container-carried-packages-en';
        }
        const modal = this.modalService.open(CarriedPackagesComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl',
            backdropClass: 'container-carried-packages-backdrop'
        });
        modal.componentInstance.isShelvePackages = true;
        modal.componentInstance.shelveId = this.shelve.id;
        modal.componentInstance.hubId = this.hub.id;
        modal.componentInstance.hub = this.hub;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    retrieve() {
        this.onRetrieve.emit({hub: this.hub, shelve: this.shelve});
    }
}
