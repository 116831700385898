import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-pricing-list',
  templateUrl: './add-pricing-list.component.html',
  styleUrls: ['./add-pricing-list.component.scss']
})
export class AddPricingListComponent implements OnInit {
  public isLoading = false;
  public form;

  constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
              private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      priceListName: ['', Validators.required]
    });
  }
  public closeModal() {
    this.activeModal.close({isSuccess : false});
  }
  public onSaveButton() {
    const body = {
        priceListName: this.form.value.priceListName
    };
    this.activeModal.close({isSuccess : true, listName: body.priceListName});
  }
}
