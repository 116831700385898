import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductModel} from '../../shared/models/product.model';

@Component({
    selector: 'app-product-table-component',
    templateUrl: 'product-table.component.html',
    styleUrls: ['product-table.component.scss']
})
export class ProductTableComponent {
    // Inputs
    @Input() products: ProductModel[] = [];
    @Input() disabled = false;

    // Outputs
    @Output() productsChange: EventEmitter<ProductModel[]> = new EventEmitter<ProductModel[]>();
    @Output() onProductsChange: EventEmitter<{weight: number, cost: number}> = new EventEmitter<{weight: number, cost: number}>();

    public addProduct() {
        if (this.disabled) {
            return;
        }

        this.products.push({
            name: '',
            quantity: 1,
            weight: 1,
            eachCost: 1,
            totalCost: 1
        });
        this.onChange();
    }

    public productChange(product: ProductModel) {
        product.totalCost = product.quantity * product.eachCost;
        this.onChange();
    }

    public removeProduct(removedProduct: ProductModel) {
        this.products = this.products.filter((product) => {
            return product !== removedProduct;
        });
        this.onChange();
    }

    public onChange() {
        let totalWeight = 0;
        let totalCost = 0;
        this.products.forEach(
            (product) => {
                totalWeight += product.weight * product.quantity;
                totalCost += product.eachCost * product.quantity;
            }
        );
        this.onProductsChange.emit({weight: totalWeight, cost: totalCost});
    }
}
