import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-download-excel',
    templateUrl: './download-excel.component.html',
    styleUrls: ['./download-excel.component.scss']
})
export class DownloadExcelComponent implements OnInit {
    public isLoading = false;
    public fromPage = null;
    public isFileSelected = false;
    constructor(private translateService: TranslateService,
                private shipmentsService: ShipmentsService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    public uploadFile(fileChooser) {
        fileChooser.click();
    }

    public downloadExcelTemplate() {
        this.shipmentsService.downloadExcelTemplate(this.fromPage).subscribe((res: any) => {
            window.open(res.url, '_blank');
        });
    }

    public uploadExcel($event: any, invoiceSupplier = false, download = false) {
        this.isLoading = true;
        let successMessage = '';
        let errorMessage = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.FILE_UPLOADED_SUCCESSFULLY', 'ALERTS.FAILED_TO_UPLOAD_FILE', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    successMessage = data['ALERTS.FILE_UPLOADED_SUCCESSFULLY'];
                    errorMessage = data['ALERTS.FAILED_TO_UPLOAD_FILE'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        formData.append('file', selectedFile);
        $event.target.value = '';
        if (!selectedFile) {
            this.isLoading = false;
            return;
        }
        this.isFileSelected = true;
        if (selectedFile.name.toLowerCase().match(/.(xlsx)$/i) ) {
            this.shipmentsService.uploadExcel(formData, invoiceSupplier, this.fromPage).subscribe((data: any) => {
                if (!data || !data.error) {
                    this.messageService.add({severity: 'success', detail: successMessage});
                    this.isLoading = false;
                    this.closeModal();
                } else {
                    if (download) {
                        this.messageService.add({severity: 'error', detail: errorMessage});
                        this.isLoading = false;
                        this.download('errors.txt', data.error);
                        this.isLoading = false;
                    } else {
                        this.confirmationService.confirm({
                            message: (data.error),
                            rejectVisible: false,
                            accept: () => {
                            },
                            acceptLabel: 'ok'
                        });
                        this.isLoading = false;
                        this.closeModal();
                    }
                }
            }, error => {
                this.isLoading = false;
                this.closeModal();
            }
            );
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.isLoading = false;
            this.closeModal();
        }

    }

    private download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    public closeModal() {
        this.activeModal.close();
    }

}
