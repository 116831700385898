import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {UsersService} from '../../../users/services/users.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyNotificationsService} from '../../services/company-notifications.service';
import {STORAGE_KEYS} from '../../../shared/services/storage/storage.keys';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {UserService} from '../../../shared/services/user.service';


@Component({
    selector: 'app-sms-gateway-settings',
    templateUrl: './sms-gateway-settings.component.html',
    styleUrls: ['./sms-gateway-settings.component.scss']
})

export class SMSGatewaySettingsComponent implements OnInit {
    public isLoading = false;
    public currency;
    public form: FormGroup;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public currentSetting: {userName: string, sender: string, session: string}  = {userName: '', sender: '', session: ''};
    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                public usersService: UsersService,
                public userService: UserService,
                private storageService: StorageService,
                private companyNotificationsService: CompanyNotificationsService) {
    }

    ngOnInit() {
        this.currency =  this.userService.getCurrency();
        this.isLoading = false;
        this.getCurrentSettings();
    }

    public getCurrentSettings() {
        this.isLoading = true;
        this.companyNotificationsService.getSMSGateWayCurrentSettings().subscribe(
            (response: {userName: string, sender: string, session: string}) => {
                if (response) {
                    this.currentSetting.userName = response.userName;
                    this.currentSetting.sender = response.sender;
                    this.currentSetting.session = response.session;
                }
                this.initInfo();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }
    public closeModal() {
        this.activeModal.close({isSuccess: false});
    }

    public initInfo() {
         this.form = this.formBuilder.group({
            userName: [this.currentSetting.userName, Validators.required],
            sender: [this.currentSetting.sender, Validators.required],
            session: [this.currentSetting.session, Validators.required]
        });
    }

   public setSMSGatewaySettings() {
        this.isLoading = true;
        const data = this.form.getRawValue();
        const body = {
            userName: data.userName,
            sender:   data.sender,
            session:  data.session,
            name: this.getSmsGatewayName()
        };
        this.companyNotificationsService.setSMSGateWaySettings(body).subscribe(
            (res: any) => {
                this.isLoading = false;
                this.activeModal.close({isSuccess: true});
            }, error => {
                this.activeModal.close({isSuccess: false});
                this.isLoading = false;
            }
        );
    }
    getSmsGatewayName() {
        if (this.currency === 'NIS') {
            return 'HOT_SMS';
        }
        if (this.currency === 'BHD') {
            return 'GATEWAYAPI';
        }
        return 'RELEANS';
    }
}
