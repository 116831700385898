export const Companies =  {
    EPS: 4,
    LOGESTECHS: 1,
    SEND_GET: 154,
    JETT: 317,
    SAR: 256,
    SEND: 269,
    DRB: 251,
    MOHANAD: 22,
    SPRINT: 313,
    FAST_ACCESS: 316,
    WHEEL: 143,
    PLANEX_JO: 157,
    ABU_ALTAWSEEL: 158,
    BLINK: 234,
    RAWABI_BISAN: 293,
    BatumiExpress: 335,
    NAWEL: 299,
    W_DELIVERY: 339,
    PAL_POST: 62,
    SPEED_LIGHT_DELIVERY: 64,
    THE_TRANSPORTER_LOGISTICS: 275
};
