<p-toast [baseZIndex]="99999999999"></p-toast>

<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
    <div class="header d-inline-flex justify-content-between">
        <ng-container *ngIf="!isLocation">
            <div class="title" *ngIf="!isEditMode">{{'FULFILMENT.BIN.ACTIONS.RESERVE_BIN' | translate}}</div>
            <div class="title" *ngIf="isEditMode">{{'FULFILMENT.BIN.ACTIONS.UPDATE_RESERVE_BIN' | translate}}</div>
        </ng-container>

        <ng-container *ngIf="isLocation">
            <div class="title">{{'FULFILMENT.WAREHOUSE.ACTIONS.RESERVE_LOCATION' | translate}}</div>
        </ng-container>

        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <form [formGroup]="reserveBinForm">
        <div class="inputs-container">
            <div class="row row-input mb-3">
                <div class="col-6 input">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.BIN.FORM.CUSTOMER_NAME' | translate }}</div>
                    <div class="input-value" [ngClass]="{'has-error': (reserveBinForm.get('customer').touched && reserveBinForm.get('customer').errors)}">
                        <ng-select formControlName="customer"
                                   (change)="chooseStore()"
                                   [typeahead]="customersSubject"
                                   [loading]="customersLoading"
                                   [items]="customersList"
                                   (open)="!customersList.length ? getCustomers() : null"
                                   typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}">
                        </ng-select>
                    </div>
                </div>

                <div class="col-6 input" *ngIf="!bin || bin.type != 'BIN'">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.BIN.FORM.SKUS_NUMBER' | translate }}</div>
                    <div class="input-value">
                        <input type="number" formControlName="numberOfSku" size="3" pInputText />
                    </div>
                </div>
                <div *ngIf="bin && bin.type === 'BIN'" class="col-6 input" [ngClass]="{'disabled-btn disabled': !(reserveBinForm.get('customer').value && reserveBinForm.get('customer').value.value)}">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.ITEM.BIN_BARCODE' | translate }}</div>
                    <div class="input-value">
                        <ng-select (change)="chooseBin($event)"
                                   [typeahead]="binSubject"
                                   [loading]="binLoading"
                                   [items]="binList"
                                   (open)="getBins()"
                                   formControlName="binBarcode"
                                   [clearable]="true"
                                   typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                   placeholder="{{ 'FULFILMENT.SHIPPING_PLAN.CHOOSE_BIN_BARCODES' | translate }}">
                            <ng-template ng-label-tmp let-bin="item">
                                <div class="selected-store" *ngIf="bin">
                                    <ng-container>
                                        <div>
                                            {{ bin.barcode + ' (' + bin.label + ')' }}
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-bin="item">
                                <div class="store-name">{{ bin.barcode + ' (' + bin.label + ')' }}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row row-input" *ngIf="!isLocation">
                <div class="col-6 input" [ngClass]="{'disabled-btn disabled': !(reserveBinForm.get('customer').value && reserveBinForm.get('customer').value.value)}">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.BIN.FORM.SHIPPING_PLAN_NAME' | translate }}</div>
                    <div class="input-value" [ngClass]="{'has-error': (reserveBinForm.get('shippingPlan').touched && reserveBinForm.get('shippingPlan').errors)}">
                        <ng-select formControlName="shippingPlan"
                                   [typeahead]="shippingPlansSubject"
                                   [loading]="shippingPlansLoading"
                                   [items]="shippingPlansList"
                                   (open)="!shippingPlansList.length ? getShippingPlans() : null"
                                   typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}">
                        </ng-select>

                    </div>
                </div>

                <div class="col-6 input">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.SHIPPING_PLAN.NEW.WAREHOUSE_NAME' | translate }}</div>
                    <div class="input-value" [ngClass]="{'has-error': (reserveBinForm.get('hubId').touched && reserveBinForm.get('hubId').errors)}">
                        <p-dropdown
                                [disabled]="!isLocation"
                                [dropdownIcon]="!isLocation ? '':null"
                                [tabindex]="-1"
                                formControlName="hubId"
                                [options]="hubsOptions"
                                styleClass="hub-dropdown"
                                placeholder=" "></p-dropdown>

                    </div>
                </div>
            </div>

            <div class="row row-input" *ngIf="isLocation && (!bin || bin.type !== 'BIN')">
                <div class="col-6 input">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.BIN.FORM.NUMBER_OF_ITEMS' | translate }}</div>
                    <div class="input-value">
                        <input type="number" formControlName="numberOfItems" size="3" pInputText />
                    </div>
                </div>

                <div *ngIf="bin.type === 'BIN'" class="col-6 input" [ngClass]="{'disabled-btn disabled': !(reserveBinForm.get('customer').value && reserveBinForm.get('customer').value.value)}">
                    <div class="input-label container-type-label">{{ 'FULFILMENT.ITEM.BIN_BARCODE' | translate }}</div>
                    <div class="input-value">
                        <ng-select (change)="chooseBin($event)"
                                   [typeahead]="binSubject"
                                   [loading]="binLoading"
                                   [items]="binList"
                                   (open)="getBins()"
                                   formControlName="binBarcode"
                                   [clearable]="true"
                                   typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                   placeholder="{{ 'FULFILMENT.SHIPPING_PLAN.CHOOSE_BIN_BARCODES' | translate }}">
                            <ng-template ng-label-tmp let-bin="item">
                                <div class="selected-store" *ngIf="bin">
                                    <ng-container>
                                        <div>
                                            {{ bin.barcode + ' (' + bin.label + ')' }}
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-bin="item">
                                <div class="store-name">{{ bin.barcode + ' (' + bin.label + ')' }}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom-actions">
            <div class="done-action" (click)="submitForm()" [ngClass]="{'disabled-btn disabled': !reserveBinForm.valid}">
                <span>{{'ACTIONS.RESERVE' | translate}}</span>
            </div>
        </div>
    </form>
</div>
