<p-toast baseZIndex="1000">
</p-toast>
<div class="header">
    {{label | translate}}
    <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
</div>
<div class="add-shipment-container">
    <div id="submitting-container" *ngIf="isSubmitting">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form (keydown)="keyDownFunction($event)" *ngIf="form; else spinner" [formGroup]="form"
          (submit)="isPickup?addPickup():addShipment()">
        <div class="card-div">
            <div class="route-information row">
                <div class="col-12 sender-information ">
                    <div class="col-12">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{(checkIfBring() ? "QUICK_ADD.RECEIVER": "QUICK_ADD.SENDER") | translate}}
                            </div>
                            <div class="information-title-action travel-information-radio-button d-flex"
                                 style="align-items: center">
                                <div *ngIf="showCreatedDate() && !isEditMode">
                                    <app-input-field-component [note]="createdDate" [isRequired]="true"
                                                               [form]="form"
                                                               controlName="createdDate"
                                                               controlType="date" dateFormat="dd/mm/yy"
                                                               [disabledDefaultOption]="false">
                                    </app-input-field-component >
                                </div>

                              <div  *ngIf="showRadioButtons()">
                                  <div *ngIf="!isPickup">
                                      <input type="radio" [value]="'PRINT'" formControlName="printType"
                                             (change)="changeAddressType()">
                                      <span>{{"QUICK_ADD.PRINT" | translate}}</span>

                                  </div>
                                  <div>
                                      <input type="radio" [value]="'SAVE'" formControlName="printType"
                                             (change)="changeAddressType()">
                                      <span>{{"QUICK_ADD.SAVE" | translate}}</span>
                                  </div>
                                  <div>
                                      <input type="radio" [value]="'SAVE_AND_CONTINUE'" formControlName="printType"
                                             (change)="changeAddressType()">
                                      <span>{{"QUICK_ADD.SAVE_AND_CONTINUE" | translate}}</span>
                                  </div>
                              </div>
                            </div>
                        </div>
                        <div class="input-fields row">
                            <div class="col-6 col-lg-3 col-md-6" style="display: flex; align-items: flex-start">
                                <app-input-field-component [form]="form"
                                                           controlName="senderName" field="name"
                                                           [controlType]="'inputgroup'"
                                                           [searchSuggestions]="users"
                                                           (onSearch)="getCustomers($event)"
                                                           [forceSelection]="false"
                                                           [displayDropDown]="true"
                                                           (onItemChosen)="chooseSender($event)"
                                                           (onIconClicked)="chooseExistingMenu(false)"
                                                           label="QUICK_ADD.NAME" [isRequired]="true">
                                </app-input-field-component >
                                <div *ngIf="!isDisabled && !isEditMode && allowAddCustomerFromQuickAddShipmentForm()"
                                     class="add-shipment-button cursor-pointer"
                                     (click)="addCustomer()" style="height: 40px;margin-top: 41px">
                                    <i class="fas fa-plus"></i>
                                </div>
                            </div>
                            <app-input-field-component [form]="form" class="col-6 col-lg-2 col-md-6"
                                                       controlName="senderPhone" field="phone"
                                                       [controlType]="'autoComplete'"
                                                       [searchSuggestions]="users"
                                                       (onSearch)="getCustomers($event)"
                                                       [forceSelection]="false"
                                                       [displayDropDown]="true"
                                                       (onItemChosen)="chooseSender($event)"
                                                       label="QUICK_ADD.MOBILE" [isRequired]="true">
                            </app-input-field-component >
                            <app-input-field-component [form]="form" class="col-6 col-lg-2 col-md-6"
                                                       style="min-width: 195px;"
                                                       controlName="senderEmail" label="QUICK_ADD.EMAIL"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >

                            <app-input-field-component [form]="form" class="col-6 col-lg-2 col-md-6"
                                                       controlName="businessSenderName"
                                                       label="USERS.BUSINESS_NAME"
                                                       controlType="text">
                            </app-input-field-component >
                            <div *ngIf="isArabic && !isHubManager && !isEditMode && notRelatedToPickup()"
                                 class="from-hub-selection-container col-6 col-lg-1 col-md-6">
                                <div>
                                    {{'QUICK_ADD.HUB' | translate}}
                                </div>
                                <p-inputSwitch formControlName="addressFromIsHub" (onChange)="changeAddressType()"
                                               class="from-hub-toggle"></p-inputSwitch>
                                <div>
                                    {{"QUICK_ADD.CUSTOMER_ADDRESS" | translate }}
                                </div>
                            </div>
                            <div *ngIf="(!isArabic && !isHubManager) && !isPickup"
                                 class="from-hub-selection-container col-6 col-lg-1 col-md-6">
                                <div>
                                    {{"QUICK_ADD.CUSTOMER_ADDRESS" | translate }}
                                </div>
                                <p-inputSwitch formControlName="addressFromIsHub" (onChange)="changeAddressType()"
                                               class="from-hub-toggle"></p-inputSwitch>
                                <div>
                                    {{'QUICK_ADD.HUB' | translate}}
                                </div>
                            </div>
                            <app-input-field-component class="col-6 col-lg-2 col-md-6" style="max-width: 120px;"
                                                       *ngIf="(!isHubManager && form.value.addressFromIsHub) && !isEditMode && notRelatedToPickup()"
                                                       label="ADD_SHIPMENT.SENDER_HUB" [form]="form"
                                                       controlName="hubId" [disabledDefaultOption]="false"
                                                       [options]="hubsOptions"
                                                       [isRequired]="true" controlType="select">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-2 col-md-6" style="max-width: 120px;"
                                                       *ngIf="((!form.value.addressFromIsHub) && !isEditMode) || isPickup"
                                                       label="QUICK_ADD.DRIVER" [form]="form"
                                                       controlName="driverId" [disabledDefaultOption]="false"
                                                       [options]="driversOptions"
                                                       [isRequired]="false" controlType="select">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
                <div class="col-12 travel-information">
                    <div class="col-12">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{(checkIfBring() ? "QUICK_ADD.RECEIVER_ADDRESS": "QUICK_ADD.SENDER_ADDRESS") | translate}}
                            </div>
                        </div>
                        <div class="input-fields row">
                            <div *ngIf="notRelatedToPickup()" class="show-more-container col-6 col-lg-2 col-md-4">
                                <div class="controller-button show-more-button next cursor-pointer"
                                     (click)="toggleshowDetails(true)">
                                    <i *ngIf="!showDetailsSender"
                                       class="fas fa-plus receiver-more-option"></i>
                                    <i *ngIf="showDetailsSender"
                                       class="fas fa-minus receiver-more-option"></i>
                                    <span style="margin-left: 15px">
                                                    {{ (showDetailsSender ? "ACTIONS.LESS" : "ACTIONS.MORE") | translate }}
                                                    </span>
                                </div>
                            </div>
                            <app-input-field-component class="col-6 col-lg-4 col-md-5"
                                                       label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                       [form]="form" field="label" [dataKey]="'value'"
                                                       [forceSelection]="true" *ngIf="!showDetailsSender"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'originVillage')"
                                                       [searchSuggestions]="combinedOptions"
                                                       (onSearch)="initVillage(0, 0,$event.query)"
                                                       controlName="originCombined" [options]="combinedOptions"
                                                       [isRequired]="true" controlType="autoComplete">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-2 col-md-6"
                                                       label="ADD_SHIPMENT.SENDER_REGION"
                                                       [form]="form" field="label" [dataKey]="'value'"
                                                       [searchSuggestions]="regionOptions"
                                                       (onSearch)="initRegions($event.query)"
                                                       [forceSelection]="true" *ngIf="showDetailsSender"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'originRegion')"
                                                       controlName="originRegion" [options]="regionOptions"
                                                       [isRequired]="true" controlType="autoComplete">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-2 col-md-6"
                                                       label="ADD_SHIPMENT.SENDER_CITY"
                                                       [form]="form" field="label" [dataKey]="'value'"
                                                       (onSearch)="initCities(0, form.controls['originRegion'].value.value,$event.query)"
                                                       [forceSelection]="true" *ngIf="showDetailsSender"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'originCity')"
                                                       [searchSuggestions]="citiesOptions"
                                                       controlName="originCity" [options]="citiesOptions"
                                                       [isRequired]="true" controlType="autoComplete">
                            </app-input-field-component >

                            <app-input-field-component class="col-6 col-lg-2 col-md-6"
                                                       label="ADD_SHIPMENT.SENDER_VILLAGE"
                                                       [form]="form" field="label" [dataKey]="'value'"
                                                       [forceSelection]="true" *ngIf="showDetailsSender"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'originVillage')"
                                                       [searchSuggestions]="villageOptions"
                                                       (onSearch)="initVillage(0, form.controls['originCity']?.value?.value,$event.query)"
                                                       controlName="originVillage" [options]="villageOptions"
                                                       [isRequired]="true" controlType="autoComplete">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-3 col-md-6"
                                                       label="ADD_SHIPMENT.SENDER_STREET"
                                                       [form]="form"
                                                       controlName="originAddressLine1"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                            <div class="to-container cursor-pointer">
                                <div class="input-label">
                                    {{"QUICK_ADD.EXPECTED_DELIVERY_DATE" | translate}}
                                </div>
                                <i class="pi pi-calendar date-picker-icon" style="margin-top: 40px; margin-left: 10px"></i>
                                <p-calendar styleClass="date-picker" formControlName="expectedDeliveryDate"
                                            dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isPickup" class="col-lg-5 col-md-5 col-12 receiver-information ">
                    <div class="col-12">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{(checkIfBring()? "QUICK_ADD.SENDER": "QUICK_ADD.RECEIVER") | translate}}
                            </div>
                        </div>
                        <div class="input-fields row">
                            <app-input-field-component class="col-6"
                                                       label="QUICK_ADD.NAME" [form]="form"
                                                       controlName="receiverName" #receiverName
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                            <app-input-field-component class="col-6"
                                                       label="QUICK_ADD.MOBILE" [form]="form"
                                                       controlName="receiverPhone"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
                <div *ngIf="!isPickup" class="col-lg-7 col-md-7 col-12 travel-information">
                    <div class="col-12">
                        <div class="information-title-container">
                            <div class="information-title-label">
                                {{(checkIfBring() ? "QUICK_ADD.SENDER_ADDRESS": "QUICK_ADD.RECEIVER_ADDRESS") | translate}}
                            </div>
                        </div>
                        <div class="input-fields row">
                            <div class="show-more-container col-6 col-lg-3 col-md-6" style="height: 77px">
                                <div class="controller-button show-more-button next cursor-pointer"
                                     (click)="toggleshowDetails(false)">
                                    <i *ngIf="!showDetailsReceiver"
                                       class="fas fa-plus receiver-more-option"></i>
                                    <i *ngIf="showDetailsReceiver"
                                       class="fas fa-minus receiver-more-option"></i>
                                    <span style="margin-left: 15px">
                                                    {{ (showDetailsReceiver ? "ACTIONS.LESS" : "ACTIONS.MORE") | translate }}
                                                    </span>
                                </div>
                            </div>
                            <app-input-field-component class="col-6 col-lg-5 col-md-6"
                                                       label="ADD_SHIPMENT.COMBINED_ADDRESS"
                                                       [form]="form" field="label" [dataKey]="'value'"
                                                       [forceSelection]="true" *ngIf="!showDetailsReceiver"
                                                       [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationVillage')"
                                                       [searchSuggestions]="combinedOptions1"
                                                       (onSearch)="initVillage(1, 0,$event.query)"
                                                       controlName="destinationCombined" [options]="combinedOptions1"
                                                       (onItemChosen)="onSelectDestinationCombinedAddress()"
                                                       [isRequired]="true" controlType="autoComplete">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-3 col-md-6"
                                                       label="ADD_SHIPMENT.RECEIVER_REGION" [form]="form"
                                                       controlName="destinationRegion" [options]="regionOptions1"
                                                       [isRequired]="true" controlType="autoComplete"
                                                       (onSearch)="initRegions($event.query)"
                                                       field="label" [dataKey]="'value'" *ngIf="showDetailsReceiver"
                                                       [forceSelection]="true" [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationRegion')"
                                                       [searchSuggestions]="regionOptions1">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-3 col-md-6"
                                                       label="ADD_SHIPMENT.RECEIVER_CITY" [form]="form"
                                                       controlName="destinationCity" [options]="citiesOptions1"
                                                       [isRequired]="true" controlType="autoComplete"
                                                       field="label" [dataKey]="'value'" *ngIf="showDetailsReceiver"
                                                       (onSearch)="initCities(1, form.controls['destinationRegion'].value.value,$event.query)"
                                                       [forceSelection]="true" [displayDropDown]="true"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationCity')"
                                                       [searchSuggestions]="citiesOptions1">
                            </app-input-field-component >

                            <app-input-field-component class="col-6 col-lg-3 col-md-6"
                                                       label="ADD_SHIPMENT.RECEIVER_VILLAGE" [form]="form"
                                                       controlName="destinationVillage" [options]="villageOptions1"
                                                       [isRequired]="true" controlType="autoComplete"
                                                       field="label" [dataKey]="'value'" *ngIf="showDetailsReceiver"
                                                       (onAutoCompleteClear)="updateZoneForm($event,'destinationVillage')"
                                                       (onSearch)="initVillage(1, form.controls['destinationCity']?.value?.value,$event.query)"
                                                       [forceSelection]="true" [displayDropDown]="true"
                                                       [searchSuggestions]="villageOptions1">
                            </app-input-field-component >
                            <app-input-field-component class="col-6 col-lg-3 col-md-6" #destinationAddressLine1
                                                       label="ADD_SHIPMENT.RECEIVER_STREET" [form]="form"
                                                       controlName="destinationAddressLine1"
                                                       [isRequired]="true" controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div *ngIf="!isPickup" class="col-12 shipment-information">
                    <div class="information-title-container">
                        <div class="information-title-label">
                            {{"ADD_SHIPMENT.SHIPMENT_INFORMATION" | translate}}
                        </div>
                        <div class="information-title-action">
                        </div>
                    </div>
                    <div class="input-fields row">
                        <app-input-field-component class="col-6 col-md-2 col-lg-2" label="ADD_SHIPMENT.TYPE_SHIPMENT"
                                                   [isRequired]="false"
                                                   [form]="form" controlName="type" controlType="select"
                                                   [options]="shipmentTypesOptions">
                        </app-input-field-component >
                        <input *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                               style="margin-top: 53px"
                               type="radio" [value]="'COLLECT'"
                               name="swapOrBring"
                               formControlName="swapOrBring"
                               (change)="changeSwapOrBring()">
                        <app-input-field-component *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                                                   class="col-6 col-md-3 col-lg-2" label="ADD_SHIPMENT.TO_COLLECT_FROM_RECEIVER"
                                                   [isRequired]="true" [form]="form" controlName="toCollectFromReceiver"
                                                   controlType="number">
                        </app-input-field-component >
                        <input *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                               type="radio" [value]="'PAY'"
                               name="swapOrBring"
                               style="margin-top: 53px"
                               formControlName="swapOrBring"
                               (change)="changeSwapOrBring()">
                        <app-input-field-component *ngIf="form.controls.type.value === 'BRING' || form.controls.type.value === 'SWAP'"
                                                   class="col-6 col-md-3 col-lg-2" label="ADD_SHIPMENT.TO_PAY_TO_RECEIVER"
                                                   [isRequired]="true" [form]="form" controlName="toPayToReceiver"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-6 col-md-2 col-lg-2 cod-input" label="QUICK_ADD.COD"
                                                   *ngIf="form.controls.type.value === 'COD'"
                                                   [isRequired]="true" [form]="form" controlName="cod"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-6 col-md-3 col-lg-2" label="ADD_SHIPMENT.TOTAL_FREIGHT"
                                                   [form]="form" *ngIf="isAllowedEditCost()"
                                                   controlName="freight"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-6 col-md-2 col-lg-2" label="PRODUCT.QUANTITY"
                                                   [isRequired]="true"
                                                   [form]="form"
                                                   controlName="quantity"
                                                   controlType="number">
                        </app-input-field-component >
                        <app-input-field-component class="col-6 col-md-3 col-lg-2" label="ADD_SHIPMENT.INVOICE_NUMBER"
                                                   [isRequired]="false"
                                                   [form]="form"
                                                   controlName="invoice">
                        </app-input-field-component >
                        <app-input-field-component class="col-6 col-md-3 col-lg-2" label="PARCEL.PARCEL_TYPE"
                                                   [form]="form"
                                                   controlName="parcelId"
                                                   controlType="select" [disabledDefaultOption]="false"
                                                   [options]="parcelOptions">
                        </app-input-field-component >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 shipment-information" style="padding-bottom: 10px;">
                    <div class="input-fields row">
                        <div class="col-2">
                            <div class="notes-container">
                                <span>{{'GENERAL.NOTES' | translate}}</span>
                                <textarea formControlName="notes" placeholder="{{'GENERAL.NOTES' | translate}}..."
                                          value="">
                        </textarea>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="notes-container">
                                <span>{{'GENERAL.CONTENTS' | translate}}</span>
                                <textarea formControlName="description" placeholder="{{'GENERAL.CONTENTS' | translate}}..."
                                          value="">
                        </textarea>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="notes-container">
                                <span>{{'GENERAL.PRIVATE_NOTES' | translate}}</span>
                                <textarea formControlName="adminNotes" placeholder="{{'GENERAL.PRIVATE_NOTES' | translate}}..."
                                          value="">
                        </textarea>
                            </div>
                        </div>
                        <span *ngIf="!isPickup" class="col-lg-2 col-md-3 col-sm-6 d-flex align-items-center">
                        <p-checkbox label="{{'ADD_SHIPMENT.FRAGILE' | translate}}" value="isFragile"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                        <span *ngIf="!isPickup" class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center">
                        <p-checkbox label="{{'ADD_SHIPMENT.H&M' | translate}}" value="isflammable"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                    <span *ngIf="!isPickup" class="col-lg-2 col-md-3 col-sm-6 d-flex align-items-center">
                        <p-checkbox label="{{'ADD_SHIPMENT.NEED_PACKING' | translate}}" value="isNeedPackaging"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                    <span *ngIf="!isPickup && allowAddingPackageWithFingerprint()" class="col-lg-2 col-md-3 col-sm-6 d-flex align-items-center">
                        <p-checkbox label="{{'ADD_SHIPMENTS.FINGER_PRINT' | translate}}" value="isFingerprintRequired"
                                    [formControl]="form?.controls['extraOptions']"></p-checkbox>
                    </span>
                    </div>
                </div>
            </div>
           <div class="row" >
               <div class="package-image"  *ngIf="imageUrl">
                   <img [src]="imageUrl">
               </div>
           </div>
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="row form-buttons">
                        <div *ngIf="!isDisabled" class="col-3 add-shipment-button cursor-pointer"
                             (click)="isPickup?addPickup():addShipment(false)" type="submit"
                             [ngClass]="{'disable': !form.valid ||!checkOriginAddress()}">
                            <i class="far fa-save"></i>
                            {{submitLabel | translate}}
                        </div>
                        <div *ngIf="isMultiPickups"  class="col-3 add-shipment-button cursor-pointer"
                             (click)="nextPickup()" type="button"
                             [ngClass]="{'disable': !form.valid}">
                            <i class="far fa-save"></i>
                            {{'SHIPMENT.CONVERT_AND_CONTINUE' | translate}}
                        </div>
                        <div *ngIf="!isDisabled" class="col-3 reset-fields-button cursor-pointer" (click)="resetForm()">
                            <i class="fas fa-times"></i>
                            {{"ACTIONS.RESET_FIELDS" | translate}}
                        </div>
                        <div *ngIf="isDisabled" class="col-3 add-shipment-button cursor-pointer"
                             (click)=" activeModal.close({});">
                            <i class="fas fa-times"></i>
                            {{"ACTIONS.CLOSE" | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template #spinner>
        <div style="text-align: center;">
            <p-progressSpinner>
            </p-progressSpinner>
        </div>
    </ng-template>
</div>
