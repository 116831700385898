<div class="expenses-add d-flex flex-column" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="close-icon cursor-pointer">
        <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt=""/>
    </div>
    <div class="title">{{(isEditMode ? 'EXPENSES.EDIT_EXPENSE': 'EXPENSES.ADD_EXPENSES') | translate }}</div>

    <div class="contents-container">
        <div class="inputs d-flex flex-wrap justify-content-between w-100">
            <div class="input-item">
                <div class="label">{{'ACCOUNTING.EXPENSE_TYPE' | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form"
                                               controlName="expenseTypeId" field="label" [dataKey]="'value'"
                                               [controlType]="'autoCompleteWithActions'"
                                               [searchSuggestions]="expensesType"
                                               (onSearch)="getExpensesTypes($event)"
                                               (onDeleteExpenseTypeClicked) = "deleteExpenseType($event)"
                                               [displayDropDown]="true"
                                               [showAddBtn]="false"
                                               [forceSelection]="true"
                                               [isRequired]="true">
                    </app-input-field-component >
                </div>
            </div>
            <div class="input-item">
                <div class="label">{{'EXPENSES.BENEFICIARY' | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form"
                                               controlName="holderId" field="label" [dataKey]="'value'"
                                               [controlType]="'autoComplete'"
                                               [searchSuggestions]="userType"
                                               (onSearch)="getUserType($event)"
                                               [forceSelection]="false"
                                               [displayDropDown]="true"
                                               [isRequired]="true">
                    </app-input-field-component >
                </div>
            </div>
            <div class="input-item">
                <div class="label">{{'EXPENSES.VALUE' | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form"
                                               [isRequired]=true
                                               controlName="moneyAmount"
                                               controlType="number">
                    </app-input-field-component >
                </div>
            </div>
<!--            <div class="field-input-item">-->
<!--                <div class="label">{{'EXPENSES.DATE' | translate}}</div>-->
<!--                <div class="value">-->
<!--                    <i class="pi pi-calendar cursor-pointer"></i>-->
<!--                    <p-calendar styleClass="date-picker" formControlName="createdDate"-->
<!--                                dateFormat="dd/mm/yy"></p-calendar>-->
<!--                </div>-->
<!--            </div>-->
            <div class="input-item">
                <div class="label">{{'EXPENSES.NOTES' | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form"
                                               controlName="notes"
                                               controlType="text">
                    </app-input-field-component >
                </div>
            </div>
        </div>

        <div class="bottom-actions d-flex justify-content-end">
            <div class="reset-button cursor-pointer " (click)="closeModal()">
                {{"ACTIONS.CANCEL" | translate}}
            </div>
            <div class="add-button cursor-pointer "
                 (click)="choose()"
                 [ngClass]="{'disabled-button disabled': !form.valid}">
                {{'ACTIONS.OK' | translate }}
            </div>
        </div>
    </div>
</div>
