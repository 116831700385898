<div [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div class="card-container">
        <div class="card-title" *ngIf="!isTicketReply">
            <span *ngIf="!editTitleMode" (click)="activeEditTitleMode($event)">{{data.title}}</span>
            <div class="modify-title-container">
                <input *ngIf="editTitleMode" type="text" [(ngModel)]="data.title" (click)="stopPropagation($event)">
                <div *ngIf="editTitleMode" class="modify-buttons title-actions d-flex">
                    <div class="cancel cursor-pointer d-flex" (click)="cancelModifyTitle()">
                        <img src="../../../../../assets/icons/ticketing-system/ticket-details/cancel.png" alt="cancel">
                    </div>
                    <div class="confirm cursor-pointer d-flex" (click)="modifyTitle()">
                        <img src="../../../../../assets/icons/ticketing-system/ticket-details/confirm.png" alt="confirm">
                    </div>
                </div>
            </div>
        </div>
        <div class="ticket-card-info" [ngClass]="{'reply-card-info': isTicketReply}">
            <div class="card-details"
                 [ngClass]="{'reply-card-details': data.type === 'REPLY', 'note-card-details': data.type === 'NOTE'}">
                <div class="reply-actions edit-reply flex-2d-center" *ngIf="isTicketReply && isOwner()">
                    <div class="edit-reply reply-action cursor-pointer" (click)="activeEditDescriptionMode($event)">
                        <img src="../../../../../assets/icons/ticketing-system/ticket-details/edit.png" alt="edit">
                    </div>
                    <div class="remove-reply reply-action cursor-pointer flex-2d-center" (click)="removeReply()">
                        <img src="../../../../../assets/icons/ticketing-system/ticket-details/remove.png" alt="remove">
                    </div>
                </div>
                <div class="user-info-and-time d-flex">
                    <div class="user-image">
                        <app-upload-image-component
                                class="user-image-upload"
                                [showClear]="false"
                                [canUpload]="false"
                                [url]="getUserImage(isTicketReply ? data.owner : data.createdBy)"
                                [imageSize]="50"
                                [defaultLogo]="'../../../../assets/icons/ticketing-system/user-avatar.png'"
                        >
                        </app-upload-image-component>
                    </div>
                    <div class="name-and-time d-flex">
                        <div class="user-name d-flex">
                            <span class="user-name-value">{{getUserName(isTicketReply ? data.owner : data.createdBy)}}</span>
                            <ng-container *ngIf="isTicketReply">
                            <span class="user-replied"
                                  *ngIf="data.type === 'REPLY'">{{'TICKETING_SYSTEM.TICKET_DETAILS.REPLIED' | translate}}</span>
                                <span class="user-replied" *ngIf="data.type === 'NOTE'">{{'TICKETING_SYSTEM.TICKET_DETAILS.ADDED_NOTE' | translate}}</span>
                            </ng-container>
                        </div>
                        <div class="date-time d-flex">
                            <span class="string-value">{{ data.createdDate | elapsedTime}}</span>
                            <!--                        <span class="number-value">(Mon 2 Nov, 2021 at 10:30 AM)</span>-->
                            <span class="number-value time-value">(at {{transformDate(data.createdDate, true)}}</span>
                            <span class="number-value">{{transformDate(data.createdDate)}})</span>
                        </div>
                    </div>
                </div>
                <div class="card-description">
                    <ng-container>
                        <div class="reply-description">`
                            <p *ngIf="!editDescriptionMode" (click)="activeEditDescriptionMode($event)">
                                {{isTicketReply ? data.text : data.description}}
                            </p>
                        </div>
                        <div class="card-update-mode" *ngIf="editDescriptionMode">
                            <div class="description-text-area">
                             <textarea *ngIf="!isTicketReply" [(ngModel)]="data.description"
                                       (click)="stopPropagation($event)"
                             ></textarea>
                                <textarea *ngIf="isTicketReply" [(ngModel)]="data.text"
                                          (click)="stopPropagation($event)"
                                ></textarea>
                            </div>
                            <div class="card-actions-container d-flex" style="justify-content: space-between">

                                <div class="add-attachments flex-2d-center" (click)="addAttachment($event,fileChooser)">
                                    <img src="../../../../assets/icons/ticketing-system/add-attachment.png"
                                         alt=""/>
                                    <input type="file" accept="image/x-png,image/gif,image/jpeg,image/x-icon"
                                           style="width: 0; height: 0; opacity: 0; position: absolute" #fileChooser
                                           (click)="$event.stopPropagation()"
                                           (change)="onFilesSelected($event, data)" multiple>
                                </div>
                                <div *ngIf="editDescriptionMode" class="modify-buttons d-flex">
                                    <div class="cancel cursor-pointer d-flex" (click)="cancelModifyDescription()">
                                        <img src="../../../../../assets/icons/ticketing-system/ticket-details/cancel.png"
                                             alt="cancel">
                                    </div>
                                    <div class="confirm cursor-pointer d-flex" (click)="modifyDescription()"
                                         [ngClass]="{'disabled-btn disabled': !(isTicketReply ? (data && data.text && data.text.length) : (data && data.description && data.description.length))}">
                                        <img src="../../../../../assets/icons/ticketing-system/ticket-details/confirm.png"
                                             alt="confirm">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="data.attachments?.length" class="reply-attachments-container d-flex"
                         (click)="stopPropagation($event)">
                        <div class="attachment-item d-flex cursor-pointer" *ngFor="let replyAttachment of data.attachments">
                            <div class="attachment-info d-flex">
                                <div class="attachment-icon flex-2d-center">
                                    <img src="../../../../../assets/icons/ticketing-system/ticket-details/attachment-icon.png"
                                         alt="attachment icon">
                                </div>
                                <!--                            <img src="../../../../../assets/icons/ticketing-system/ticket-details/attachment-icon.png" alt="">-->
                                <div class="attachment-name-and-size d-flex">
                                    <div class="attachment-name" (click)="openAttachment(replyAttachment)">
                                        <!--                                    <span>صورة مرفقة.JPG</span>-->
                                        <span>{{getAttachmentName(replyAttachment)}}</span>
                                    </div>
<!--                                    <div class="attachment-size">-->
<!--                                        <span>(10 KB)</span>-->
<!--                                    </div>-->
                                </div>
                            </div>
                            <div class="remove-attachment flex-2d-center" (click)="removeCardAttachment(replyAttachment)">
                                <img src="../../../../../assets/icons/delete.svg" alt="delete">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
