<ng-template #changePackageStatusDialog>
    <app-change-package-status-component
            [shipment]="package"
            [isShowCurrentStatus]="false"
            (onFinish)="onChangeStatus(changePackageStatusPopOver)"
            (onCancel)="changePackageStatusPopOver.close()"
    >
    </app-change-package-status-component>
</ng-template>

<p-overlayPanel #mobileActions [dismissable]="true" styleClass="shipment-popover">
    <div *ngIf="package && package.massCodPackageId && !archivedMode" (click)="printMassPackagePdf()" class="history-action cursor-pointer" [ngClass]="{'history-action-en': currentLang === 'en'}">
        <i *ngIf="!printMassPackagesLoader" class="fas fa-print"></i>
        <i *ngIf="printMassPackagesLoader" class="fas fa-spinner"></i>
        {{'HISTORY.PRINT_MASS_COD_PACKAGE' |translate | packageTitle}}
    </div>
    <div class="history-action cursor-pointer" [ngClass]="{'history-action-en': currentLang === 'en'}" (click)="downloadPdfTracking(package.id)">
        <i *ngIf="!printLoader" class="print-action action cursor-pointer fas fa-print"></i>
        <i *ngIf="printLoader" class="fas fa-spinner"></i>
        <span>{{"ACTIONS.PRINT_TRACKING" | translate}}</span>
    </div>
    <div class="history-action cursor-pointer"
         [ngClass]="{'history-action-en': currentLang === 'en'}"
         *appPermission="permissions.includes('{EDIT_PACKAGE') && !isLcl && !archivedMode"
         (click)="onPostponePackage()">
        <i class="fas fa-clock"></i>
        <span>{{"SHIPMENT.POSTPONE_PACKAGE" | translate | packageTitle }}</span>
    </div>
    <div class="history-action cursor-pointer"
         [ngClass]="{'history-action-en': currentLang === 'en'}"
         *ngIf="isLcl && package?.packageItems?.length"
         (click)="openLclItems()">
        <i class="fa-solid fas fa-cubes"></i>
        <span>{{"ADD_SHIPMENT.LCL.SHOW_PACKAGE_ITEMS" | translate | packageTitle }}</span>
    </div>
    <div class="history-action cursor-pointer"
         [ngClass]="{'history-action-en': currentLang === 'en'}"
         *ngIf="!isLcl && package?.codMismatchId > 0"
         (click)="printingPackageReceiptVoucher()">
        <i *ngIf="!printLoader" class="print-action action cursor-pointer fas fa-print"></i>
        <i *ngIf="printLoader" class="fas fa-spinner"></i>
        <span>{{"SHIPMENT.PRINTING_PACKAGE_RECEIPT_VOUCHER" | translate | packageTitle }}</span>
    </div>
</p-overlayPanel>
<div [ngClass]="{'page-ltr': currentLang === 'en', 'page-rtl': currentLang === 'ar'}">
    <p-sidebar [(visible)]="isShow" (onHide)="onClose()" [dismissible]="false" styleClass="container-details-sidebar">
        <div class="loader-spinner" *ngIf="spinnerState === spinnerStates.LOADING">
            <span>
                <i class="fas fa-spinner fa-spin"></i>
            </span>
        </div>
        <div *ngIf="spinnerState === spinnerStates.FAILED" style="width: 100%; height: 100%;">
            <app-no-data-available-component></app-no-data-available-component>
        </div>
        <div (click)="onClose()" class="close-icon cursor-pointer">
            <img src="../../../assets/icons/track_package/close-package-tracker-icon.svg" alt="" />
        </div>
        <div class="" *ngIf="spinnerState === spinnerStates.LOADED">
            <div class="row tracker-container">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 sender-receiver-info" style="position: relative;">
                    <div class="row label">
                        <div style="width: 27px;"></div>
                        <div>{{'HISTORY.TITLE' | translate | packageTitle }}</div>
                    </div>
                    <div class="row d-inline-flex sender-info" [ngClass]="{'sender-info-en': currentLang === 'en'}">
                        <div class="pin-image justify-content-center" [ngClass]="{'pin-image-en': currentLang === 'en'}">
                            <img src="../../../assets/icons/track_package/pin-track-package.svg" alt="" />
                        </div>
                        <div class="col-9 icon-and-label" [ngClass]="{'icon-and-label-en': currentLang === 'en'}" style="background-color: #fff4e5; justify-content: flex-start !important;">
                            <div>{{'HISTORY.SENDER_INFO' | translate }}</div>
                        </div>
                        <div class="name-and-address" [ngClass]="{'name-and-address-en': currentLang === 'en'}">
                            <div class="name">
                                {{package?.senderFirstName}}
                                {{package?.senderMiddleName ? package?.senderMiddleName : ''}} {{package?.senderLastName}} {{package?.businessSenderName ? " (" + package?.businessSenderName + ")" : ''}}
                            </div>
                            <div class="address">
                                {{package?.originAddress.city }} - {{package?.originAddress.village }} - {{package?.originAddress.addressLine1}} {{package?.originAddress.addressLine2 ? "- " + package?.originAddress.addressLine2 : ''}}
                            </div>
                            <div class="phone-number-icon d-inline-flex">
                                <div class="number" [ngClass]="{'number-en': currentLang === 'en'}" style="background-color: #fef6e9;">
                                    {{ package?.senderPhone }}
                                </div>
                                <div class="icon" [ngClass]="{'icon-en' : currentLang === 'en'}">
                                    <i class="fas fa-phone" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row d-inline-flex sender-info" [ngClass]="{'receiver-info-en': currentLang === 'en'}">
                        <div class="pin-image justify-content-center" [ngClass]="{'pin-image-en': currentLang === 'en'}">
                            <img src="../../../assets/icons/track_package/pin-track-package.svg" alt="" />
                        </div>
                        <div class="col-9 icon-and-label" [ngClass]="{'icon-and-label-en': currentLang === 'en'}" style="background-color: #fff4e5;">{{'HISTORY.RECEIVER_INFO' | translate }}</div>
                        <div class="name-and-address" [ngClass]="{'name-and-address-en': currentLang === 'en'}">
                            <div class="name">
                                {{package?.receiverFirstName}}
                                {{package?.receiverMiddleName ? package?.receiverMiddleName : ''}}
                                {{package?.receiverLastName}}
                                {{package?.businessReceiverName ? " (" + package?.businessReceiverName + ")" : ''}}
                            </div>

                            <div class="address" style="min-height: 45px;">
                                {{package?.destinationAddress.city }} - {{package?.destinationAddress.village }} - {{package?.destinationAddress.addressLine1}} {{(package?.destinationAddress.addressLine2) ? ("- " +
                                package?.destinationAddress.addressLine2) : ''}}
                            </div>
                            <div class="phone-number-icon d-inline-flex">
                                <div class="number" [ngClass]="{'number-en': currentLang === 'en'}" style="background-color: #fef6e9;">
                                    {{ package?.receiverPhone }}
                                </div>
                                <div class="icon" [ngClass]="{'icon-en' : currentLang === 'en'}">
                                    <i class="fas fa-phone" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="imagesUrl.length" class="row d-inline-flex sender-info" [ngClass]="{'receiver-info-en': currentLang === 'en'}">
                        <div class="pin-image justify-content-center" [ngClass]="{'pin-image-en': currentLang === 'en'}">
                            <img src="../../../assets/icons/track_package/pin-track-package.svg" alt="" />
                        </div>
                        <div class="col-9 icon-and-label" [ngClass]="{'icon-and-label-en': currentLang === 'en'}" style="background-color: #fff4e5;">{{'GENERAL.ATTACHMENT' | translate }}</div>
                        <div class="image-slider-container"><app-image-slider [images]="imagesUrl" [showHeader]="false"></app-image-slider></div>
                    </div>
                    <div class="dot-circle" [ngClass]="{'dot-circle-en': currentLang === 'en'}"></div>
                </div>
                <div #packageInfo class="col-lg-8 col-md-8 col-sm-12 col-xs-12 package-info" style="background-color: #fef5e9; position: relative;">
                    <div class="hidden-sm-down">
                        <span class="package-dimension package_width ">{{package?.width}}</span>
                        <span class="package-dimension package_length">{{package?.length}}</span>
                        <span class="package-dimension package_height">{{package?.height}}</span>
                        <div class="package-icon" [ngClass]="{'package-icon-en': currentLang === 'en'}">
                            <img src="../../../assets/icons/track_package/package-box.svg" alt="" />
                        </div>
                    </div>
                    <div *ngIf="showHeaderActions" class="row" style="justify-content: end;">
                        <div class="col-3"></div>
                        <div class="col-9 no-padding" style="margin-bottom: 10px;">
                            <div class="d-inline-flex history-actions-row" [ngClass]="{'history-actions-row-en': currentLang === 'en'}">
                                <div *ngIf="package && package.massCodPackageId && !archivedMode" (click)="printMassPackagePdf()" class="history-action hidden-sm-down cursor-pointer" [ngClass]="{'history-action-en': currentLang === 'en'}">
                                    <i *ngIf="!printMassPackagesLoader" class="fas fa-print"></i>
                                    <i *ngIf="printMassPackagesLoader" class="fas fa-spinner"></i>
                                    {{'HISTORY.PRINT_MASS_COD_PACKAGE' |translate | packageTitle}}
                                </div>
                                <div class="history-action cursor-pointer" [ngClass]="{'history-action-en': currentLang === 'en'}" *appPermission="permissions.includes('{EDIT_PACKAGE') && !archivedMode" (click)="editPackage()">
                                    <i class="fas fa-pencil-alt"></i>
                                    <span class="hidden-sm-down">{{"ACTIONS.EDIT" | translate}}</span>
                                </div>
                                <div class="history-action cursor-pointer" [ngClass]="{'history-action-en': currentLang === 'en'}" (click)="downloadPdf(package.id)">
                                    <i *ngIf="!printLoader" class="print-action action cursor-pointer fas fa-print"></i>
                                    <i *ngIf="printLoader" class="fas fa-spinner"></i>
                                    <span class="hidden-sm-down">{{"ACTIONS.PRINT" | translate}}</span>
                                </div>
                                <div class="history-action hidden-sm-down cursor-pointer" [ngClass]="{'history-action-en': currentLang === 'en'}" (click)="downloadPdfTracking(package.id)">
                                    <i *ngIf="!printLoader" class="print-action action cursor-pointer fas fa-print"></i>
                                    <i *ngIf="printLoader" class="fas fa-spinner"></i>
                                    <span>{{"ACTIONS.PRINT_TRACKING" | translate}}</span>
                                </div>
                                <div
                                        class="history-action cursor-pointer edit-action"
                                        *appPermission="permissions.includes('{CHANGE_PACKAGE_STATUS') && !archivedMode"
                                        #changePackageStatusPopOver="ngbPopover"
                                        placement="bottom-left"
                                        autoClose="outside"
                                        [ngbPopover]="changePackageStatusDialog"
                                        [ngClass]="{'edit-action-en': currentLang === 'en'}"
                                >
                                    <i class="fas fa-exchange-alt"></i>
                                    <span class="hidden-sm-down">{{"SHIPMENT.CHANGE_PACKAGE_STATUS" | translate | packageTitle}}</span>
                                </div>
                                <div class="history-action hidden-sm-down cursor-pointer"
                                     [ngClass]="{'history-action-en': currentLang === 'en'}"
                                     *appPermission="permissions.includes('{EDIT_PACKAGE') && !isLcl && !archivedMode"
                                     (click)="onPostponePackage()">
                                    <i class="fas fa-clock"></i>
                                    <span>
                                        {{"SHIPMENT.POSTPONE_PACKAGE" | translate | packageTitle }}
                                    </span>
                                </div>
                                <div class="history-action hidden-sm-down cursor-pointer"
                                     [ngClass]="{'history-action-en': currentLang === 'en'}"
                                     *ngIf="isLcl && package?.packageItems?.length"
                                     (click)="openLclItems()">
                                    <i class="fa-solid fas fa-cubes"></i>
                                    <span>
                                        {{"ADD_SHIPMENT.LCL.SHOW_PACKAGE_ITEMS" | translate | packageTitle }}
                                    </span>
                                </div>

                                <div class="history-action hidden-sm-down cursor-pointer"
                                     [ngClass]="{'history-action-en': currentLang === 'en'}"
                                     *ngIf="!isLcl && package.codMismatchId > 0"
                                     (click)="printingPackageReceiptVoucher()">
                                    <i *ngIf="!printLoader" class="print-action action cursor-pointer fas fa-print"></i>
                                    <i *ngIf="printLoader" class="fas fa-spinner"></i>
                                    <span>
                                        {{"SHIPMENT.PRINTING_PACKAGE_RECEIPT_VOUCHER" | translate | packageTitle }}
                                    </span>
                                </div>
                                <div class="history-action cursor-pointer hidden-md-up flex-2d-center" (click)="triggerMobileActions($event, mobileActions)">
                                    <i class="fas fa-ellipsis-h"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #mainInfo class="main" [ngClass]="{'padding-left-95': currentLang === 'en'}">
                        <div class="details" [ngClass]="{'details-en' : currentLang === 'en'}">
                            <div class="info" [ngClass]="{'info-en': currentLang === 'en'}">
                                <table>
                                    <tr>
                                        <td class="table-td-label"><div>{{'SHIPMENT.TABLE.NUMBER' | translate | packageTitle}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="barcode-container barcode-number">
                                                <span class="number table-td-info item-barcode-number">#{{package?.barcode}}</span>
                                                <span *ngIf="package" class="copy cursor-pointer"><img src="../../../assets/icons/track_package/copy.svg" (click)="copyMessage(package.barcode)" alt="" /></span>
                                            </div>
                                        </td>
                                        <td style="width: 190px;">
                                            <img height="50px" class="barcode-image cursor-pointer hidden-sm-down" [src]="package.barcodeImage" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="table-td-label"><div>{{'SHIPMENT.TABLE.BOOKING_DATE' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info">
                                                    {{package?.createdDate | date:'dd-MM-yyyy'}} {{getLocalTime(package?.createdDate)}}
                                                </span>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="table-td-label"><div class="row d-flex">{{'ADMINISTRATION.COD' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <span class="table-td-info">
                                            {{(package && package.cod == 0 && showPrePaidInsteadOfZero ) ? ('BANK_TRANSFERS.PREPAID' | translate) : (package?.cod | currencyValue : package.currency) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="table-td-label"><div>{{'ADMINISTRATION.PRICE' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <span
                                                    class="table-td-info">{{(package && package.cost == 0 && showPrePaidInsteadOfZero) ? ('BANK_TRANSFERS.PREPAID' | translate) : (package?.cost | currencyValue : package.currency)}}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="isEnableCustomerCommissionFee">
                                        <td class="table-td-label"><div>{{'PRICING.COMMISSION_FEE' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <span class="table-td-info">
                                                {{package.commissionFee}}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="isHubUserRole">
                                        <td class="table-td-label"><div>{{'ADMINISTRATION.HUB_COST' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td><span class="table-td-info">{{package?.hubCost | currencyValue : package.currency }}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="table-td-label"><div>{{'SHIPMENT.TABLE.INVOICE' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td style="width: 210px;">
                                            <div class="row"><span class="table-td-info">{{package?.invoiceNumber}}</span></div>
                                        </td>
                                        <td *ngIf="package?.invoiceNumberBarcodeImage" style="width: 190px;">
                                            <img height="50px" class="barcode-image cursor-pointer hidden-sm-down" [src]="package?.invoiceNumberBarcodeImage" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="table-td-label"><div>{{'SHIPMENT.TABLE.NOTES' | translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row"><span class="table-td-info notes" style="font-family: almarai;">{{package?.notes}}</span></div>
                                        </td>
                                    </tr>

                                    <ng-container *ngIf="isSupportAdditionalFees">
                                        <tr>
                                            <td class="table-td-label">
                                                <div>{{'ADDITION_FEES.INSURANCE_STATE' | translate}}</div>
                                            </td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info notes"
                                                                       style="font-family: almarai;">{{(package?.isInsured ? 'ADDITION_FEES.INSURED' : 'ADDITION_FEES.NOT_INSURED')|translate}}</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr *ngIf="package?.isInsured">
                                            <td class="table-td-label">
                                                <div>{{'ADDITION_FEES.INSURANCE_VALUE' | translate}}</div>
                                            </td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info notes"
                                                                       style="font-family: almarai;">{{ (package?.insuranceFee  | number : digitFormat)}}</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="table-td-label">
                                                <div>{{'ADDITION_FEES.COD_VALUE' | translate}}</div>
                                            </td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info notes"
                                                                       style="font-family: almarai;">{{ (package?.additionalCodFee  | number : digitFormat)}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>

                                        <tr *ngIf="companiesWithCooling.indexOf(companyId) !== -1 && package && package.isNeedCooling">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENTS.NEEDS_TO'| translate}} {{'ADD_SHIPMENTS.COOLING'| translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row">
                                                    <div class="table-td-info notes" style="font-family: almarai;"> {{'GENERAL.YES'| translate}}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="companiesWithMeasurement.indexOf(companyId) !== -1 && package && package.isNeedMeasurement">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENTS.NEEDS_TO'| translate}} {{'ADD_SHIPMENTS.MEASUREMENT'| translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row">
                                                    <div class="table-td-info notes" style="font-family: almarai;"> {{'GENERAL.YES'| translate}}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="companiesWithPreview.indexOf(companyId) !== -1 && package && package.isNeedPreview">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENTS.NEEDS_TO'| translate}} {{'ADD_SHIPMENTS.PREVIEW'| translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row">
                                                    <div class="table-td-info notes" style="font-family: almarai;"> {{'GENERAL.YES'| translate}}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    <tr *ngIf="package.isDestructible">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.DESTRUCTIBLE'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="package.isPreventOpen">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.PREVENT_OPENING'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="package.isPreventMeasuring">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.PREVENT_MEASURING'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="package.isFragile">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.FRAGILE'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="package.isNeedPackaging">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.NEEDS_PACKAGING'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="package.isflammable">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.FLAIMABLE'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="package.isFingerprintRequired">
                                        <td class="table-td-label"><div>{{'ADD_SHIPMENTS.FINGER_PRINT'| translate}}</div></td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info notes" style="font-family: almarai;">{{'GENERAL.YES'| translate}}</span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="(isDistributor || isTrucking) && driverCheckIn">
                                        <td class="table-td-label">{{'SHIPMENT.TABLE.DRIVER_CHECK_IN' | translate}}</td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row driver-check-in">
                                                <span class="table-td-info" style="font-family: almarai;">
                                                    {{ driverCheckIn.driverName + ' - ' + (driverCheckIn.timestamp | date:'dd/MM/yyyy hh:mm') }}
                                                </span>
                                                <i class="fas fa-map-marker" (click)="showDriverCheckIn()"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="companiesHadStationsAndTrips.indexOf(companyId) !== -1 && package['trip'].tripNumber">
                                        <td class="table-td-label">{{'MANAGE_SHIPMENTS.TRIP_NUMBER' | translate}}</td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div>
                                                {{package['trip'].tripNumber}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="companiesHadStationsAndTrips.indexOf(companyId) !== -1 && package['fromHubName']">
                                        <td class="table-td-label">{{'SHIPMENT.TABLE.FROM_HUB' | translate}}</td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div>
                                                {{package['fromHubName']}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="companiesHadStationsAndTrips.indexOf(companyId) !== -1 && package['toHubName']">
                                        <td class="table-td-label">{{'SHIPMENT.TABLE.TO_HUB' | translate}}</td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div>
                                                {{package['toHubName']}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="note-td-label">{{'SHIPMENT.TABLE.STATUS' | translate}}</td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="status table-td-info" style="font-family: 'almarai';">
                                                <app-shipment-status *ngIf="package" [shipment]="package"> </app-shipment-status>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="isLcl">
                                        <td class="note-td-label">{{'LCL.PAYMENT_STATUS' | translate}}</td>
                                        <td class="td-width-5-percentage"></td>
                                        <td>
                                            <div class="row">
                                                <span class="table-td-info" style="font-family: almarai;">
                                                {{ ('LCL.' + package.lclPaymentStatus) | translate }}
                                                    <span *ngIf="package.paidAmount > 0">({{package.paidAmount | currencyValue : package.currency}})</span>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="showPackageDetails">
                                        <tr>
                                            <td class="table-td-label"><div>{{'MANAGE_SHIPMENTS.DELIVERY_DATE' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row">
                                                    <span class="table-td-info">
                                                        {{package?.deliveryDate | date:'dd/MM/yyyy'}} {{getLocalTime(package?.deliveryDate)}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-td-label"><div>{{'MANAGE_SHIPMENTS.POSTPONED_DATE' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row">
                                                    <span class="table-td-info">
                                                        {{package?.postponedDate | date:'dd/MM/yyyy'}} {{package?.postponedDeliveryDate | date:'dd/MM/yyyy'}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-td-label"><div>{{'HISTORY.WEIGHT' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info">{{package?.weight}}</span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-td-label">
                                                <div>{{(showStudentsNumber ? 'ADD_SHIPMENTS.STUDENTS_NUMBER' : 'PRODUCT.QUANTITY')|translate}}</div>
                                            </td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span
                                                        class="table-td-info">{{ package.quantity }}</span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-td-label"><div>{{'GENERAL.PRIVATE_NOTES'|translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package?.adminNotes}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="isJettCompany() && package?.orderId">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.SHIPMENT_METHOD'|translate | packageTitle}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{('JETT.' + package?.orderId) | translate}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!isDistributor">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.TYPE_SHIPMENT'|translate | packageTitle}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">
                                                    {{(package?.shipmentType === 'COD' && package?.isSenderPayCost && isJettCompany() ? 'COD_V2' : package?.shipmentType) | translate}}
                                                </span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.DESCRIPTION'|translate | packageTitle}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package?.description}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.billingAddress">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.BILLING_ADDRESS'|translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td class="billing-address">
                                                <div class="row">
                                                    <span class="table-td-info" style="font-family: almarai;">
                                                        {{currentLang === 'en' ? package.billingAddress.city : package.billingAddress.arabicCityName }} - {{currentLang === 'en' ? package.billingAddress.village :
                                                        package.billingAddress.arabicVillageName }} - {{package.billingAddress.addressLine1}} {{package.billingAddress.addressLine2 ? "- " + package.billingAddress.addressLine2 : ''}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.status==='RETURNED_BY_RECIPIENT' || package.status === 'COMPLETED'">
                                            <td class="table-td-label"><div>{{'MANAGE_SHIPMENTS.ORIGINAL_COD'|translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.originalCod}}</span></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.SUPPLIER_INVOICE'|translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.supplierInvoice}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="(companyId === 1 || companyId === 37) && package.payerName">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.PAYER_NAME'|translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.payerName}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="(companyId === 1 || companyId === 37) && package.payerPhone">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.PAYER_PHONE'|translate }}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.payerPhone}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="(companyId === 1 || companyId === 37) && package.additionalCost">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.ADDITIONAL_COST'|translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.additionalCost}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.serviceType && !isLcl">
                                            <td class="table-td-label"><div>{{'SHIPMENT.SERVICE_TYPE' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.serviceType | translate}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.serviceTypeName">
                                            <td class="table-td-label"><div>{{'SHIPMENT.SERVICE_TYPE' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.serviceTypeName}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.vehicleTypeName">
                                            <td class="table-td-label"><div>{{'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.VEHICLE_TYPE' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{package.vehicleTypeName}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.expectedPickupDate">
                                            <td class="table-td-label"><div>{{'SHIPMENT.EXPECTED_PICKUP_DATE' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">
                                                    {{companyId === 209 ? (package.expectedPickupDate | date:'h:mm a') : (package.expectedPickupDate | date:'dd/MM/yyyy')}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.isFingerprintRequired">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENTS.FINGER_PRINT' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{'GENERAL.YES' | translate}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.authorName">
                                            <td>{{'ADD_SHIPMENTS.SHIPMENT_CREATOR' | translate | packageTitle}}</td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>{{package.authorName}}</td>
                                        </tr>
                                        <tr *ngIf="package.paymentType">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.COD_COLLECTION_METHOD' | translate}}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info" style="font-family: almarai;">{{('COD_COLLECTION_METHODS_LIST.' + package.paymentType) | translate}}</span></div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="package.driverPhone">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.DRIVER_PHONE' | translate }}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info">{{package.driverPhone}}</span></div>
                                            </td>
                                        </tr>

                                        <tr *ngIf="package.thirdPartyTrackingNo">
                                            <td class="table-td-label"><div>{{'ADD_SHIPMENT.THIRD_PARTY_TRACKING_NO' | translate }}</div></td>
                                            <td class="td-width-5-percentage"></td>
                                            <td>
                                                <div class="row"><span class="table-td-info">{{package.thirdPartyTrackingNo}}</span></div>
                                            </td>
                                        </tr>
                                        <tr></tr>
                                    </ng-container>
                                </table>
                                <div class="show_more" (click)="onToggleDetails()">
                                    <span>
                                        {{'GENERAL.DETAILS' | translate}}
                                        <img src="../../../assets/icons/arrow_down.svg" alt="icon" *ngIf="!showPackageDetails" />
                                        <img src="../../../assets/icons/arrow_up.svg" alt="icon" *ngIf="showPackageDetails" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="package-tracking" [ngStyle]="{'height': detailsDivHeight + 'px' }">

                        <div class="d-flex toggle-tracking-view-container">
                            <p-selectButton [options]="trackingFormOptions" [(ngModel)]="selectedTrackingFormView">
                            </p-selectButton>
                        </div>
                        <ng-container *ngIf=" (selectedTrackingFormView == TrackingStatus.STATUS)">
                            <ng-container *ngIf="packageTracker">
                                <div class="time-chart">
                                    <div class="content">
                                        <div class="item" *ngFor="let route of packageTracker">
                                            <div class="date">
                                                <span class="hours"><b>{{route.changedBy}}</b></span>
                                                <br>
                                                <span class="days">{{route.createdDate | date:'yyyy-MM-dd'}}</span>
                                            </div>
                                            <div class="bubble"
                                                 [ngStyle]="{'border-color': getStatusColor(route.type)}"></div>
                                            <div class="label"
                                                 [ngStyle]="{'background-color': getStatusColor(route.type)}">{{route.packageTrackingStatus}}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!packageTracker">
                                <div style="text-align: center">
                                    <p-progressSpinner>
                                    </p-progressSpinner>
                                </div>
                            </ng-container>

                        </ng-container>
                        <table *ngIf="selectedTrackingFormView == TrackingStatus.DETAILED" class="tracking" [ngClass]="{'tracking-en': currentLang === 'en'}">
                            <table *ngFor="let shipment of packagesHistory">
                                <tr>
                                    <td class="empty-td" [ngClass]="{'empty-td-en' : currentLang === 'en'}"></td>
                                </tr>
                                <tr class="date-cell">
                                    <td class="driver-name-time not-empty" [ngClass]=" {'not-empty-en': currentLang === 'en'}"></td>
                                    <td class="td-25-space"></td>
                                    <td><div class="date" [ngClass]="{'date-en': currentLang === 'en'}">{{shipment.date | date:'dd/MM/yyyy'}}</div></td>
                                </tr>
                                <tr *ngFor="let packageHistory of shipment.actions">
                                    <td class="driver-name-time not-empty" [ngClass]="{'not-empty-en': currentLang === 'en'}">
                                        <div class="driver-name">{{packageHistory.userName}}</div>
                                        <div class="time" [ngClass]="{'time-en': currentLang === 'en'}">{{getLocalTime(packageHistory.time)}}</div>
                                    </td>
                                    <td class="td-25-space"></td>
                                    <div class="tracking-no-space">
                                    <td>
                                        <div class="d-flex"><span class="tracking-status" [ngClass]="{'tracking-status-en': currentLang === 'en'}">{{packageHistory.action}}</span></div>
                                    </td>

                                    <td *ngIf="packageHistory.pkgStatus != null">
                                        <div class="d-flex"><span class="pkg-status" [ngStyle]="{'background-color': getStatusColor(packageHistory.pkgStatus)}">{{packageHistory.pkgStatus | translate}}</span></div>
                                    </td>
                                    </div>
                                </tr>
                                <tr>
                                    <td class="not-empty" [ngClass]="{'not-empty-en': currentLang === 'en'}" style="height: 10px;"></td>
                                </tr>
                            </table>
                        </table>
                        <div *ngIf="selectedTrackingFormView == TrackingStatus.ITEMS" class="justify-all-center">
                            <div style="padding-top: 60px">

                                <div class="row p-2">
                                    <td class="col-4">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.NAME' | translate}}</td>
                                    <td class="col-4">{{'MANAGE_SHIPMENTS.COST' | translate}}</td>
                                    <td class="col-4">{{'MANAGE_SHIPMENTS.STATUS' | translate}}</td>
                                </div>
                                <div class="row p-2" *ngFor="let item of package?.packageItemsToDeliverList">

                                    <td class="col-4">
                                        {{item.name}}
                                    </td>
                                    <td class="col-4">
                                        {{item.cod | number: digitFormat}}
                                    </td>

                                    <td class="col-4" *ngIf="item.status != null">
                                        <div class="d-flex"><span class="pkg-status" [ngStyle]="{'background-color': getStatusColor(item.status)}">{{item.status | translate}}</span></div>
                                    </td>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>
<ng-container *ngIf="!isDistributor">
    <app-add-package-form-component *ngIf="displayAddPackage"
                                    [display]="displayAddPackage"
                                    [packageBarcode]="selectedPackageBarcode"
                                    (closeWidget)="onHideAddPackage($event)">
    </app-add-package-form-component>
</ng-container>

<ng-container *ngIf="isDistributor">
    <app-distributor-package-form *ngIf="displayAddPackage"
                                  [display]="displayAddPackage"
                                  [packageBarcode]="selectedPackageBarcode"
                                  (closeWidget)="onHideAddPackage($event)">

    </app-distributor-package-form>
</ng-container>
