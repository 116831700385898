<div class="system-updates-container" [ngClass]="{'page-ltr': selectedLanguage === 'en'}">
    <div class="d-flex system-updates-header mb-4 justify-content-between">
        <div class="d-flex" *ngIf="isSuperAdmin">
            <div class="add-update d-flex"
                 (click)="openAddUpdateDialog('PDF')">
                <div class="icon">
                    <img src="../../../assets/icons/plus.svg" alt=""/>
                </div>
                <div class="label">
                    <span>{{'SYSTEM_UPDATES.ADD_SYSTEM_UPDATE' | translate}}</span>
                </div>
            </div>
            <div class="pl-1 pr-1"></div>

            <div class="add-update d-flex"
                 (click)="openAddUpdateDialog('VIDEO')">
                <div class="icon">
                    <img src="../../../assets/icons/plus.svg" alt=""/>
                </div>
                <div class="label">
                    <span>{{'SYSTEM_UPDATES.ADD_SYSTEM_UPDATE_WITH_VIDEO' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between w-100">
            <div class="search-toggle cursor-pointer">
                <div class="manage-shipments-search-filter search-filter-container d-flex">
                    <div class="d-flex">
                        <div class="search-filter-toggle" (click)="toggleIsSearchContent(true)">
                            <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image"/>
                        </div>
                        <div class="search-input" *ngIf="isSearchContent">
                            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..."
                                   [(ngModel)]="searchContent"
                                   appInputDebounced (inputDebounced)="loadUpdatesLazy(true)"/>
                        </div>
                    </div>
                    <div *ngIf="isSearchContent" class="hide-search-filter" (click)="toggleIsSearchContent(false)">
                        <span>{{'ACTIONS.CANCEL' | translate}}</span>
                    </div>
                </div>
            </div>
            <ng-container *appPermission="permissions.includes('MARK_ALL_AS_SEEN')">
                <div class="add-update d-flex" [ngClass]="{'disabled-btn disabled': markAllAsSeenRequest || disableMarkAllAsRead}"
                     (click)="markAllAsSeen()">
                    <div class="label">
                        <span>{{'SYSTEM_UPDATES.MARK_ALL_AS_SEEN' | translate}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

   <div class="table-and-tabs-container d-flex flex-column">
       <div class="group-tabs-container">
           <div *ngFor="let tab of tabs" class="cursor-pointer tab" (click)="onTabChange(tab)" [ngClass]="{'selectedTab':selectedTab === tab}">
               {{('SYSTEM_UPDATES.TABS.' + tab) | translate}}
           </div>
       </div>
       <div class="system-updates-table-container hidden-sm-down"
            (scroll)="onsScrollUpdateTable($event)" #tableContainer>
           <p-table [value]="updatesData" [responsive]="true"
                    [scrollHeight]="'calc(100vh - 204px)'"
                    [lazy]="true"
                    (onLazyLoad)="loadUpdatesLazy(true)"
                    [rows]="pageSize"
                    [first]="10"
                    [totalRecords]="totalNumberOfUpdates"
                    scrollHeight="auto">
               <ng-template pTemplate="header">
                   <tr>
                       <th>
                           {{'SYSTEM_UPDATES.UPDATE_NAME' | translate}}
                       </th>
                       <th>
                           {{'SYSTEM_UPDATES.UPDATE_SUMMARY' | translate}}
                       </th>
                       <th>
                           {{'SYSTEM_UPDATES.DATE' | translate}}
                       </th>
                       <th style="width: 50px" *ngIf="isSuperAdmin">
                       </th>
                   </tr>
               </ng-template>
               <ng-template pTemplate="emptymessage">
                   <div class="empty-content" *ngIf="spinnerState == spinnerStates.EMPTY">
                       <app-no-data-available-component></app-no-data-available-component>
                   </div>
               </ng-template>
               <ng-template pTemplate="body" let-content let-rowIndex="rowIndex">
                   <tr [ngClass]="{'unseen-update': !content.isSeen}">
                       <td>

                           <a class="cursor-pointer update-name" (click)="openUpdateInfo(content)">
                               <i *ngIf="content.fileName" class="fas fa-file-pdf-o" aria-hidden="true"></i>

                               <img style="width: 16px;" *ngIf="content.videoLink" src="../../assets/icons/login/youtube.svg" />

                               <span>
                                   {{selectedLanguage === 'en' ? content.name : content.arabicName}}
                                </span>
                           </a>
                       </td>
                       <td>
                           <div>
                               {{selectedLanguage === 'en' ? content.summary : content.arabicSummary}}

                           </div>
                       </td>
                       <td>
                           {{ content.createdDate | date : 'dd/MM/yyyy' }}
                       </td>
                       <td *ngIf="isSuperAdmin">
                           <div class="d-flex justify-content-center">
                               <div class="cursor-pointer" style="width: 50px"
                                    (click)="triggerUpdateDropdown($event, updateOptions, rowIndex, content)">
                                   <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                               </div>
                           </div>
                       </td>
                   </tr>
               </ng-template>
               <ng-template pTemplate="footer">
                   <tr *ngIf="spinnerState === spinnerStates.LOADING">
                       <td [attr.colspan]="tableColumnCount">
                           <div class="update-table-loader">
                               <div class="loader">
                                   <app-skeleton-loader type="2" [repeatSingleLine]="updatesData.length === 0 ? 6 : 3"></app-skeleton-loader>
                               </div>
                           </div>
                       </td>
                   </tr>
                   <tr style="visibility: hidden">
                       <td style="border: 0" #loadingTrigger></td>
                   </tr>
               </ng-template>

           </p-table>

       </div>
   </div>


    <div class="system-updates-table-container hidden-md-up"
         (scroll)="onsScrollUpdateTable($event, true)" #tableContainer>
        <div class="empty-card" *ngIf="spinnerState == spinnerStates.LOADING">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div class="no-data" *ngIf="spinnerState == spinnerStates.EMPTY">
            <span>{{ 'ALERTS.NO_DATA_AVAILABLE' | translate }}</span>
        </div>

        <div class="card-item" *ngFor="let update of updatesData;">
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex flex-column cursor-pointer" (click)="openUpdateInfo(update)">
                    <div>{{'SYSTEM_UPDATES.UPDATE_NAME' | translate}}: <span class="cursor-pointer update-name">{{update.name}}</span></div>
                    <div>{{'SYSTEM_UPDATES.UPDATE_SUMMARY' | translate}}: {{update.summary}}</div>
                    <div>{{'SYSTEM_UPDATES.DATE' | translate}}: {{ update.createdDate | date : 'dd/MM/yyyy' }}</div>
                </div>
                    <div class="d-flex justify-content-center align-items-center" *ngIf="isSuperAdmin">
                        <div class="cursor-pointer" style="width: 50px"
                             (click)="triggerUpdateDropdown($event, updateOptions, 0, update)">
                            <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                        </div>
                    </div>
            </div>
        </div>

    </div>
    <p-overlayPanel #updateOptions [dismissable]="true" styleClass="update-popover">
        <div class="row-action-container">
            <div
                    class="action cursor-pointer more-action-item" (click)="editUpdate()">
                <img class="edit-button" src="../../../assets/icons/manage_shipments/edit.svg"
                     alt="icon"/>
                {{'SYSTEM_UPDATES.EDIT' | translate}}
            </div>
            <div
                    class="action cursor-pointer more-action-item" (click)="removeUpdate()">
                <img class="remove-button" src="../../../assets/icons/manage_shipments/remove.svg"
                     alt="icon"/>
                {{'SYSTEM_UPDATES.REMOVE' | translate | packageTitle}}
            </div>
        </div>
    </p-overlayPanel>
</div>
