import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TICKET_CONSTANTS, TICKET_PRIORITIES_LIST, TICKET_STATUS_LIST, TICKET_TYPES_LIST} from './constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TicketingSystemService {

    constructor(private httpClient: HttpClient, private translateService: TranslateService) {
    }

    public addNewTicket(body) {
        return this.httpClient.post(TICKET_CONSTANTS.TICKET_REQUEST, body);
    }

    public updateTicket(body) {
        return this.httpClient.put(TICKET_CONSTANTS.TICKET_REQUEST, body);
    }

    public deleteTicket(id) {
        return this.httpClient.delete(TICKET_CONSTANTS.DELETE_TICKET.replace('{ticketId}', id));
    }

    public updateTicketTitle(body, id) {
        return this.httpClient.put(TICKET_CONSTANTS.UPDATE_TICKET_TITLE.replace('{ticketId}', id), body);
    }

    public updateTicketDescription(body, id) {
        return this.httpClient.put(TICKET_CONSTANTS.UPDATE_TICKET_DESCRIPTION.replace('{ticketId}', id), body);
    }

    public addReply(body) {
        return this.httpClient.post(TICKET_CONSTANTS.ADD_REPLY, body);
    }

    public updateReply(body) {
        return this.httpClient.post(TICKET_CONSTANTS.ADD_REPLY, body);
    }

    public updateTicketReply(body) {
        return this.httpClient.put(TICKET_CONSTANTS.UPDATE_REPLY, body);
    }

    public deleteTicketReply(body) {
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}), body: body
        };
        return this.httpClient.delete(TICKET_CONSTANTS.DELETE_REPLY, httpOptions);
    }

    public getTickets(urlQuery, body = {}) {
        return this.httpClient.post(TICKET_CONSTANTS.GET_TICKETS + urlQuery, body);
    }

    public getTicketDetails(id) {
        return this.httpClient.get(TICKET_CONSTANTS.GET_TICKET_DETAILS.replace('{ticketId}', id));
    }

    public addCategory(body) {
        return this.httpClient.post(TICKET_CONSTANTS.CATEGORY_REQUEST, body);
    }

    public editCategory(body) {
        return this.httpClient.put(TICKET_CONSTANTS.CATEGORY_REQUEST, body);
    }

    public deleteCategory(id) {
        return this.httpClient.delete(TICKET_CONSTANTS.DELETE_CATEGORY.replace('{categoryId}', id));
    }
    public getUsersByCategoryId(id) {
        return this.httpClient.get(TICKET_CONSTANTS.GET_CATEGORY_BY_CATEGORY_ID.replace('{categoryId}', id));
    }

    public getCategories(query = '') {
        return this.httpClient.get(TICKET_CONSTANTS.GET_CATEGORIES + query);
    }

    public updateTicketStatus(id, body) {
        return this.httpClient.put(TICKET_CONSTANTS.UPDATE_TICKET_STATUS.replace('{ticketId}', id), body);
    }

    public updateTicketCategory(id, body) {
        return this.httpClient.put(TICKET_CONSTANTS.UPDATE_TICKET_CATEGORY.replace('{ticketId}', id), body);
    }

    public updateTicketClient(id, body) {
        return this.httpClient.put(TICKET_CONSTANTS.UPDATE_TICKET_CLIENT.replace('{ticketId}', id), body);
    }

    public getDashboardStats(query) {
        return this.httpClient.get(TICKET_CONSTANTS.GET_DASHBOARD_STATS + query);
    }

    public addGeneralNote(body) {
        return this.httpClient.post(TICKET_CONSTANTS.GENERAL_NOTE_REQUEST, body);
    }

    public editGeneralNote(body) {
        return this.httpClient.put(TICKET_CONSTANTS.GENERAL_NOTE_REQUEST, body);
    }

    public deleteGeneralNote(body) {
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}), body: body
        };
        return this.httpClient.delete(TICKET_CONSTANTS.GENERAL_NOTE_REQUEST, httpOptions);
    }

    public getPriorityColor(priority) {
        switch (priority) {
            case 'LOW':
                return '#0000FF';
                break;
            case 'NORMAL':
                return '#00AF4D';
                break;
            case 'HIGH':
                return '#FCA429';
                break;
            case 'CRITICAL':
                return '#F24645';
                break;
        }
    }

    public getAllCategories(query = '') {
        return this.httpClient.get(TICKET_CONSTANTS.GET_ALL_CATEGORIES + query);
    }

    public getGeneralNotes(query = '') {
        return this.httpClient.get(TICKET_CONSTANTS.GET_GENERAL_NOTES + query);
    }

    setTicketTypes() {
        return TICKET_TYPES_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    setTicketStatus() {
        return TICKET_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    setTicketPriorities() {
        return TICKET_PRIORITIES_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    uploadFile(body, folder) {
        return this.httpClient.post(TICKET_CONSTANTS.UPLOAD_FILE.replace('{folder}', folder), body);
    }
    getCategoriesByUserId(userId) {
        return this.httpClient.get(TICKET_CONSTANTS.GET_CATEGORIES_BY_USER_ID.replace('{userId}', userId));
    }
    getTicketTableColumns() {
        return this.httpClient.get(TICKET_CONSTANTS.GET_TICKETING_TABLE_COLUMNS);
    }
    updateTicketTableColumns(column: string, $event) {
        return this.httpClient.post(TICKET_CONSTANTS.GET_TICKETING_TABLE_COLUMNS, {},{params: {column}});
    }

    getHistory(tid) {
        return this.httpClient.get(TICKET_CONSTANTS.GET_TICKET_HISTORY, {params: {ticketId: tid}});
    }
}
