import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PricingService} from './services/pricing.service';
import {ZonesService} from '../administration/services/zones.service';
import {ChooseExistingComponent} from '../shipment/choose-existing/choose-existing.component';
import {UserModel} from '../shared/models/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../shared/services/authentication.service';
import {ShippingLinesService} from '../administration/services/shipping-lines.service';
import {SubscriptionTrackerComponent} from '../shared/behavior/subscription-tracker.component';
import {UserService} from '../shared/services/user.service';
import {ShippingRatesHistoryComponent} from './components/shipping-rates-history/shipping-rates-history.component';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {CustomizationCompanyService} from '../shared/services/customization-company.service';
import {Companies} from '../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-shippings-rate-component',
    templateUrl: 'shippings-rate.component.html',
    styleUrls: ['shippings-rate.component.scss']
})
export class ShippingsRateComponent extends SubscriptionTrackerComponent implements OnInit {
    regions = [];
    companyRegions = [];
    parcelPrices = [];
    public isDriverWorkArea = false;
    public isPricingList = false;
    public customerID = 0;
    public name = '';
    isEnableExportingBetweenHubs = false;
    isHubsPricingList = false;
    role = '';
    currentLang;
    isLoading = false;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    companies  = Companies;

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private userService: UserService,
        private pricingService: PricingService,
        private zonesService: ZonesService,
        private modalService: NgbModal,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private shippingLinesService: ShippingLinesService,
        public customizationCompanyService: CustomizationCompanyService
    ) {
        super();
        this.isDriverWorkArea = (this.route.snapshot.data['isDriverWorkArea'] === undefined
            ? false : this.route.snapshot.data['isDriverWorkArea']);
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isHubsPricingList = this.isEnableExportingBetweenHubs && this.role === 'HUB_ADMIN';

        this.route.queryParams.subscribe(
            (params: Params) => {
                if (params.isDriverWorkArea) {
                    this.isDriverWorkArea = true;
                }
                if (params.isPricingList) {
                    this.isPricingList = true;
                }
            }
        );
        this.route.params.subscribe(
            (params: Params) => {
                this.customerID = params.id;
                this.initRegions();
            }
        );
    }

    public customizePricing() {
        const modal = this.modalService.open(ChooseExistingComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        if (this.isDriverWorkArea) {
            modal.componentInstance.driverPricing = true;
        }
        modal.componentInstance.isHubsPricingList = this.isHubsPricingList;
        modal.result.then(
            (data: { isSuccess: boolean, user: UserModel }) => {
                if (data.isSuccess) {
                    const user = data.user;
                    if (this.isDriverWorkArea) {
                        this.router.navigate([this.authenticationService.companyName +
                        '/home/' + 'drivers-earnings/' + 'shipping-rates/' + user.id], {
                            queryParams: {
                                isDriverWorkArea: true
                            }});
                    } else {
                        this.router.navigate([this.authenticationService.companyName + '/home/' + 'shipping-rates/' + user.id]);
                    }
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public initRegions() {
        this.isLoading = true;
        let getRegion = this.zonesService.getRegions(true);
        if (this.isHubsPricingList || this.isDriverWorkArea) {
            getRegion = this.isHubsPricingList ? this.zonesService.getCitiesList('?page=1&pageSize=20') : this.shippingLinesService.getShippingLines('DRIVER_ZONE');
        }
        getRegion.subscribe(
            (response: any) => {
                this.regions = this.isDriverWorkArea? this.formatArray(response) : this.formatArray(response.data);
                this.initializeCompanyPrices();
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    private initializeCompanyPrices() {
        if (this.customerID) {
            if (this.isDriverWorkArea) {
                this.pricingService.getDriverPrices(this.customerID).subscribe((response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.changeInputForDriverPricing();
                    this.addDefaults();
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            } else if (this.isPricingList) {
                this.pricingService.getPricingListPrices(this.customerID).subscribe((response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.parcelPrices = this.fixParcelsResponse(response.parcelIdToCompanyPricesMap, response.parcelTypes);
                    this.name = response.customerName;
                    this.addDefaults();
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            } else if (this.isHubsPricingList) {
                this.pricingService.getHubPrices(this.customerID).subscribe((response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.parcelPrices = this.fixParcelsResponse(response.parcelIdToCompanyPricesMap, response.parcelTypes);
                    this.name = response.hubName;
                    this.addDefaults();
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            } else {
                this.pricingService.getCustomerPrices(this.customerID).subscribe((response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.parcelPrices = this.fixParcelsResponse(response.parcelIdToCompanyPricesMap, response.parcelTypes);
                    this.name = response.customerName;
                    this.addDefaults();
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            }
        } else {
            this.getDefaultCompanyPrices();
        }
    }

    public addDefaults() {
        if (this.isHubsPricingList) {
            this.pricingService.getCompanyPrices().subscribe(
                (response: any) => {
                    let defaultCompanyRegions = response.defaultPrices;
                    for (const initDefaults of defaultCompanyRegions) {
                        initDefaults.isNew = true;
                    }
                    for (const companyRegion of this.companyRegions) {
                        defaultCompanyRegions = defaultCompanyRegions.filter((defaultRegion) => {
                            return !(companyRegion.destinationCityId === defaultRegion.destinationCityId);
                        });
                    }
                    this.companyRegions = [...this.companyRegions, ...defaultCompanyRegions];
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        } else {
            if (this.isDriverWorkArea) {
                this.pricingService.getDriverPrices().subscribe((response: any) => {
                    let defaultCompanyRegions = response.defaultPrices;
                    for (let x = 0; x < defaultCompanyRegions.length; x++) {
                        defaultCompanyRegions[x].originRegionId = defaultCompanyRegions[x].originShippingLineId;
                        defaultCompanyRegions[x].destinationRegionId = defaultCompanyRegions[x].destinationShippingLineId;
                    }
                    for (const initDefaults of defaultCompanyRegions) {
                        initDefaults.isNew = true;
                    }
                    for (const companyRegion of this.companyRegions) {
                        defaultCompanyRegions = defaultCompanyRegions.filter((defaultRegion) => {
                            return !(companyRegion.originRegionId === defaultRegion.originRegionId &&
                                companyRegion.destinationRegionId === defaultRegion.destinationRegionId);
                        });
                    }
                    this.companyRegions = [...this.companyRegions, ...defaultCompanyRegions];
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            } else {
                this.pricingService.getCompanyPrices().subscribe((response: any) => {
                    let defaultCompanyRegions = response.defaultPrices;
                    for (const initDefaults of defaultCompanyRegions) {
                        initDefaults.isNew = true;
                    }
                    for (const companyRegion of this.companyRegions) {
                        defaultCompanyRegions = defaultCompanyRegions.filter((defaultRegion) => {
                            return !(companyRegion.originRegionId === defaultRegion.originRegionId &&
                                companyRegion.destinationRegionId === defaultRegion.destinationRegionId);
                        });
                    }
                    this.companyRegions = [...this.companyRegions, ...defaultCompanyRegions];
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            }
        }
    }

    private getDefaultCompanyPrices() {
        if (this.isHubsPricingList) {
            this.pricingService.getCompanyPrices().subscribe(
                (response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.parcelPrices = this.fixParcelsResponse(response.parcelIdToCompanyPricesMap, response.parcelTypes);
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );

        } else {
            if (this.isDriverWorkArea) {
                this.pricingService.getDriverPrices().subscribe((response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.changeInputForDriverPricing();
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            } else if (!this.isPricingList) {
                this.pricingService.getCompanyPrices().subscribe((response: any) => {
                    this.companyRegions = response.defaultPrices;
                    this.parcelPrices = this.fixParcelsResponse(response.parcelIdToCompanyPricesMap, response.parcelTypes);
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            }
        }
    }

    formatArray(options = []) {
        if (this.isDriverWorkArea) {
            return options.map(({id, name}) => {
                return {value: id, label: name};
            });
        }
        return options.map(({id, name, arabicName}) => {
            return {value: id, label: this.translateService.currentLang === 'en' ? name : arabicName};
        });
    }

    public onPriceChange($event: {}) {
        // this.initializeCompanyPrices();
    }

    private fixParcelsResponse(parcelsResponse, parcelTypes) {
        const fixedResponse = [];
        for (const parcel in parcelTypes) {
            let name = this.translateService.currentLang === 'en' ? parcelTypes[parcel].type : parcelTypes[parcel].arabicType;
            if (parcelTypes[parcel].arabicType == null) {
                name = parcelTypes[parcel].type;
            }
            fixedResponse.push({
                id: parcelTypes[parcel].id, name: name,
                parcels: parcelsResponse[parcelTypes[parcel].id]
            });
        }
        return fixedResponse;
    }
    changeInputForDriverPricing() {
        for (let x = 0; x < this.companyRegions.length; x++) {
            this.companyRegions[x].originRegionId = this.companyRegions[x].originShippingLineId;
            this.companyRegions[x].destinationRegionId = this.companyRegions[x].destinationShippingLineId;
        }
    }

    showHistory() {
        const modal = this.modalService.open(ShippingRatesHistoryComponent, <any>{
            backdrop: 'static',
            windowClass: this.currentLang === 'ar' ? 'container-carried-packages': 'container-carried-packages-en',
            size: <any>'xl'
        });
        modal.componentInstance.isHubPackages = true;
        modal.componentInstance.isReceiveHubCod = true;
    }

}
