import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ZonesService} from '../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {GeographyService} from '../../shared/services/geography.service';
import {Address} from '../../shared/models/address.class';
import {HubModel} from '../../shared/models/hub.model';
import {HubsService} from '../../shared/services/hubs.service';
import {UserService} from '../../shared/services/user.service';

@Component({
    selector: 'app-choose-customer',
    templateUrl: './choose-customer.component.html',
    styleUrls: ['./choose-customer.component.scss']
})
export class ChooseCustomerComponent extends Address implements OnInit {
    public isLoading = false;
    public customerId;
    public fromBundle = false;
    destinationTypes = ['HUB'];
    sourceTypes = ['HUB', 'CUSTOMER'];
    hideSelectOption;
    selectedDestinationType = 'HUB';
    selectedSourceType = 'HUB';
    hubsOptions: { value: string, label: string }[];
    hubsDestinationsOptions: { value: string, label: string }[];
    selectedHubId;
    selectedSourceHubId;
    selectedDestinationHubId;
    chooseSourceOnly = false;
    addressOptions: { value: string, label: string }[];
    selectedAddress;
    constructor(private activeModal: NgbActiveModal,
                private zonesService: ZonesService,
                private hubsService: HubsService,
                private userService: UserService,
                private geographyService: GeographyService,
                private translateService: TranslateService) {
        super(zonesService, translateService, true);
        this.allVillages = true;
    }

    ngOnInit() {
        this.isLoading = false;
        this.fetchHubs();
        this.fetchDestinationHubs();
    }

    public closeModal() {
        this.activeModal.close();
    }

    public customerSelected(partner) {
        this.customerId = partner;
    }

    public chooseCustomer() {
        if (this.fromBundle) {
            this.activeModal.close({
                isSuccess: true,
                result: {
                    sourceHubId: this.selectedSourceHubId,
                    customerId: this.customerId,
                    destinationHubId: this.selectedDestinationHubId,
                    destinationAddress: this.selectedAddress,
                }
            });
        } else {
            this.activeModal.close({isSuccess: true, customerId: this.customerId});
        }
    }

    onSelectDestinationType(type) {
        this.selectedDestinationType = type;
    }

    onSelectSourceType(type) {
        this.selectedSourceType = type;

        if (type === 'HUB') {
            this.destinationTypes = ['HUB'];
        } else {
            this.destinationTypes = ['HUB', 'ADDRESS'];
        }
    }

    onDestinationHubChange(event) {
        this.selectedDestinationHubId = event.value;
    }

    onSourceHubChange(event) {
        this.selectedSourceHubId = event.value;
    }
    chooseAddress(event) {
        this.selectedAddress = event;
    }

    fetchHubs() {
        this.isLoading = true;
        this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    fetchDestinationHubs() {
        this.isLoading = true;
        this.hubsService.getHubs('?page=1&pageSize=100', '', false, ['HUB_MANAGER', 'DISPATCHER', 'MULTIPLE_HUBS_MANAGER', 'OPERATION_MANAGER'].indexOf(this.userService.userInfo.role) !== -1).subscribe(
            (response: any) => {
                this.hubsDestinationsOptions = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

}
