import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {DatePipe} from '@angular/common';
import {CustomizationCompanyService} from '../../../shared/services/customization-company.service';
import {Companies} from '../../../../customCompanies/companies.pal-ship';
import {isNumeric} from '../../../shared/services/utilities.service';

@Component({
  selector: 'app-finish-scanning-v2',
  templateUrl: './finish-scanning-v2.component.html',
  styleUrls: ['./finish-scanning-v2.component.scss']
})
export class FinishScanningV2Component implements OnInit {
  public isLoading = false;
  public form: FormGroup;
  packages = [];
  public driverId = 0;
  public isDriverEarning = false;
  public totalDriversEarning = 0;
  public expectedCod = 0;
  public hubId = 0;
  public partnerId = 0;
  public mismatchId = 0;
  public isCustody = false;
  public isReceiveAll = false;
  public expensesTypeSuggestion = [];
  public addedExpenseType = false;
  public existingExpenseTypes: string[];
  public isEditMode = false;
  public mismatch;
  public receivedDate;
  public selectedAttachmentsFilter = 'ALL';
  public receiveInHubCustody;
  public params;

  @ViewChild('expenseType', {static: false}) expenseTypeEl;
  companies = Companies;

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              public translateService: TranslateService,
              private messageService: MessageService,
              private shipmentsService: ShipmentsService,
              private confirmationService: ConfirmationService,
              public customizationCompaniesService: CustomizationCompanyService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.isLoading = false;
    this.initInfo();
    this.getExistingExpensesTypes();
    if (this.mismatch) {
      this.receivedDate = this.mismatch.receiveDate;
    }
  }

  public closeModal() {
    this.activeModal.close();
  }

  public initInfo() {
    if (this.isEditMode) {
      this.form = this.formBuilder.group({
        cod: [this.mismatch.carriedCod, this.getCodValidators()],
        expenses: [this.mismatch.expenses, this.getCustomerRequiredValidation()],
        driverEarningSum: [this.mismatch.driverEarningSum],
        expensesType: this.mismatch.expensesType ? [{name: this.mismatch.expensesType}] : [''],
        printReport: [true],
        isReceived: [this.mismatch.isReceived],
        receiveDate: [this.datePipe.transform(this.mismatch.receiveDate, 'dd/MM/yyyy')]
      });
    } else  {
      this.form = this.formBuilder.group({
        cod: ['', this.getCodValidators()],
        expenses: ['', this.getCustomerRequiredValidation()],
        driverEarningSum: [this.totalDriversEarning],
        expensesType: ['', this.getCustomerRequiredValidation()],
        printReport: [true],
        isReceived: [true],
        receiveDate: [''],
      });
    }

  }
  public getExistingExpensesTypes() {
    this.shipmentsService.getExpensesTypes('').subscribe(
        (response: any) => {
          this.existingExpenseTypes = response.map(
              (expenseType) => {
                return expenseType.name;
              }
          );
        }
    );
  }
  public finishScanning() {
    const {cod, expenses, driverEarningSum, expensesType, printReport, isReceived} = this.form.getRawValue();
    let expensesTypeVal = expensesType;
    if (expensesType.name && expensesType.name !== 'undefined') {
      expensesTypeVal = expensesType.name;
    }
    let expenseTypeId = 0;
    if (expensesType !== undefined && expensesType) {
      expenseTypeId = expensesType.id;
    }
    const receiveDate = this.datePipe.transform(this.receivedDate, 'yyyy-MM-dd');
    this.isLoading = true;
    this.form.disable();

    if (this.isCustody || this.receiveInHubCustody) {
      this.addToCustody(cod, expenses, driverEarningSum, expensesTypeVal, expenseTypeId, printReport, isReceived, receiveDate);
      return;
    }
    if (this.mismatchId) {
      this.shipmentsService.handleCodMismatch(this.mismatchId, cod).subscribe(() => {
        this.translateService.get(
            'ALERTS.MISMATCH_HANDLED_SUCCESSFULLY'
        )
            .subscribe(
                (data) => {
                  this.activeModal.close({isSuccess: true});
                  this.confirmationService.confirm({
                    message: data,
                    rejectVisible: false,
                    acceptLabel: 'OK'
                  });
                }
            );
      }, () => {
        this.activeModal.close({isSuccess: false});

      });
    } else {
      let receivePackages = null;
      if (this.isEditMode) {
        receivePackages = this.shipmentsService.updateCodMismatch(
            this.mismatch.id, cod, this.driverId, this.hubId,
            this.partnerId, expenses, driverEarningSum, expensesTypeVal, expenseTypeId,
            printReport, isReceived, receiveDate);
      } else {
        if (this.isReceiveAll) {
          receivePackages = this.shipmentsService.receiveAll(
              cod, this.driverId, this.hubId, this.partnerId, expenses, driverEarningSum, expensesTypeVal, expenseTypeId,
              printReport, isReceived, receiveDate, this.selectedAttachmentsFilter, this.params);
        } else {
          receivePackages = this.shipmentsService.receivePackages(
              this.packages, cod, this.driverId, this.hubId,
              this.partnerId, expenses, driverEarningSum, expensesTypeVal, expenseTypeId,
              printReport, isReceived, receiveDate);
        }
      }

      receivePackages.subscribe((response) => {
        if (response && response.url) {
          window.open(response.url, '_blank');
        }
        if (response && response.error) {
          this.confirmationService.confirm({
            message: response.error,
            rejectVisible: false,
            acceptLabel: 'OK'
          });
          return;
        }
        let translateService =  this.translateService.get(
            'ALERTS.PACKAGES_RECEIVED_SUCCESSFULLY'
        );
        if (this.isEditMode) {
          translateService =  this.translateService.get(
              'ACCOUNTING.COD_UPDATE_SUCCESS_MESSAGE'
          );
        }

        translateService.subscribe(
            (data) => {
              this.activeModal.close({isSuccess: true, isReceiveAll: this.isReceiveAll});
              this.confirmationService.confirm({
                message: data,
                rejectVisible: false,
                acceptLabel: 'OK'
              });
            }
        );
      }, () => {
        this.activeModal.close({isSuccess: false});

      });

    }
  }

  public addToCustody(cod, expenses, driverEarningSum, expensesType, expenseTypeId, printReport, isReceived, receiveDate) {
    this.shipmentsService
        .addToCustody(this.packages,
            cod, this.driverId,
            expenses, expensesType, expenseTypeId, printReport, isReceived, receiveDate, this.isReceiveAll, this.receiveInHubCustody ? this.hubId : false).subscribe((response: any) => {
      if (response && response.url) {
        window.open(response.url, '_blank');
      }
      this.translateService.get(
          'ALERTS.PACKAGES_RECEIVED_SUCCESSFULLY'
      )
          .subscribe(
              (data) => {
                this.activeModal.close({isSuccess: true});
                this.confirmationService.confirm({
                  message: data,
                  rejectVisible: false,
                  acceptLabel: 'OK'
                });
              }
          );
    }, () => {
      this.activeModal.close({isSuccess: false});
    });
  }

  public togglePrintReport(event) {
    if (!event) {
      this.translateService.get(
          ['ALERTS.CONFIRM_CANCEL_PRINT_REPORT', 'GENERAL.YES', 'GENERAL.NO']
      )
          .subscribe(
              (data) => {
                this.confirmationService.confirm({
                  message: data['ALERTS.CONFIRM_CANCEL_PRINT_REPORT'],
                  acceptLabel: data['GENERAL.YES'],
                  rejectLabel: data['GENERAL.NO'],
                  reject: () => {
                    this.form.controls['printReport'].setValue(true);
                    this.form.controls['printReport'].updateValueAndValidity();
                  }
                });
              }
          );
    }
  }

  public getExpensesTypes(data , source = null) {
    this.addedExpenseType = false;
    const query = '?page=1&pageSize=100&search=' + data.element.inputEL.nativeElement.value;
    if (source === 'delete') {
      data.event.stopPropagation();
    }
    this.shipmentsService.getExpensesTypes(query).subscribe(
        (response: any) => {
          this.expensesTypeSuggestion = response;
          this.expensesTypeSuggestion = this.expensesTypeSuggestion.map((expensesTypes) => {
            expensesTypes['name'] = expensesTypes.name;
            expensesTypes['id'] = expensesTypes.id;
            return expensesTypes;
          });
        }, (error) => {
        }
    );
  }

  public deleteExpenseType(data) {
    data.event.stopPropagation();
    if (data.expense.id) {
      this.shipmentsService.deleteExpensesTypes(data.expense.id).subscribe(
          (response: any) => {
            this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.EXPENSE_TYPE_DELETED_SUCCESSFULLY')});
            this.getExpensesTypes(data , 'delete');
            this.getExistingExpensesTypes();
          }
      );
    }
  }

  public addExpenseType(expense) {
    let expenseName = expense.item;
    if (expense.item.name && expense.item.name !== undefined) {
      expenseName = expense.item.name;
    }

    if (this.existingExpenseTypes.indexOf(expenseName) !== -1) {
      this.translateService.get(['ACCOUNTING.EXIST_EXPENSE_TYPE_MESSAGE']
      ).subscribe((res: string) => {
        this.confirmationService.confirm({
          message: res['ACCOUNTING.EXIST_EXPENSE_TYPE_MESSAGE'],
          rejectVisible: false,
          acceptLabel: 'OK'
        });
      });
      return;
    }
    if (expenseName) {
      const body = {name: expenseName};
      this.shipmentsService.addExpensesTypes(body).subscribe(
          (response: any) => {
            this.existingExpenseTypes.push(expenseName);
            this.addedExpenseType = true;
          }
      );
    }
  }

  private getCodValidators() {
    const validators = [Validators.required];
    if (this.customizationCompaniesService.checkCompanies(this.companies.SPRINT)) {
      validators.push(Validators.max(this.expectedCod));
    }
    return validators;

  }

  isInvalidWithError(formName: string, errorName: string) {
    const formControl = this.form.get(formName);
    if (formControl && formControl.errors) {
      return this.isInvalidDirtyTouched(formName) && formControl.errors[errorName] !== undefined;
    } else {
      return false;
    }
  }

  isInvalidDirtyTouched(formName: string) {
    const formControl = this.form.get(formName);
    if (formControl) {
      return formControl.invalid && formControl.dirty && formControl.touched;
    } else {
      return false;
    }
  }

  private getCustomerRequiredValidation() {
    if (!this.customizationCompaniesService.checkCompanies(this.companies.SPRINT)) {
      return [];
    }

    return (control: FormControl) => {
      if (isNumeric(control.parent?.get('cod')?.value)) {
        if (control.parent?.get('cod').value < this.expectedCod) {
          return Validators.required(control);
        }
      }
      return null;
    };
  }

  changeCod($event) {
    Object.keys(this.form.controls).forEach(controlName => {
      const control = this.form.get(controlName);
      control.updateValueAndValidity();
      control.markAsTouched();
      control.markAsDirty();
    });
    this.form.updateValueAndValidity();
  }
}
