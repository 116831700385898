import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({providedIn: 'root'})
export class CustomizationCompanyService {
    constructor(private userService: UserService) {
    }

    getCompanyIds(company, ...other) {
        return [company, ...other];
    }

    checkCompanies(company, ...other) {
        if (other.length) {
            const companies = this.getCompanyIds(company, ...other);
            const currentCompanyId =  this.userService.userInfo.companyId;
            return companies.indexOf(currentCompanyId) !== -1;
        } else {
            const currentCompanyId =  this.userService.userInfo.companyId;
            return currentCompanyId === company;
        }
    }
}
