export const PARTNER_CONSTANTS = {
    GET_ACTIVE_PARTNERS: ':api/admin/companies/partners',
    GENERATE_PARTNER_CODE: ':api/admin/companies/partner-code',
    ADD_PARTNER: ':api/admin/companies/partner/link',
    GET_PARTNER_PACKAGES: ':api/admin/packages/partners',
    GET_PARTNER_COD_PACKAGES: ':api/accountant/partners/cod-packages',
    EXPORT_PARTNER_COD_PACKAGES_TO_EXCEL: ':api/accountant/partners/cod-packages/excel',
    ACTIVATE_PARTNER: ':api/admin/companies/partners/activate',
    GET_PARTNER_ACTIVATED: ':api/admin/companies/partners/{partnerId}/is-activated',
    EXPORT_PARTNERS_CSV: ':api/admin/packages/partners/csv',
    EXPORT_PARTNERS_EXCEL: ':api/admin/packages/partners/excel',
    CANCEL_PARTNER: ':api/admin/companies/partners/{partnerId}/cancel',
    GET_UNDELIVERED_PARTNER_PACKAGES: ':api/admin/packages/partners/cod/undelivered'
};
