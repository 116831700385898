
<ng-template #rowActions let-close="close">
    <div class="row-actions-container">
        <div class="row action" (click)="onModify()">
            <div class="icon" *ngIf="true">
                <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
            </div>
            <div class="loading" *ngIf="false">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT_CONTAINER' | translate}}</div>
        </div>
        <div class="row action" (click)="onPrint()">
            <div class="icon" *ngIf="!singlePrintLoader">
                <img src="../../assets/icons/containers-management/print.svg" alt="" />
            </div>
            <div class="loading" *ngIf="singlePrintLoader">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.PRINT_CONTAINER' | translate}}</div>
        </div>
        <div class="row action" (click)="getPackagesCount()">
            <div class="icon" *ngIf="!getPackagesCountLoader">
                <img src="../../assets/icons/containers-management/packages_number_black.svg" alt="" />
            </div>
            <div class="loading" *ngIf="getPackagesCountLoader">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.CONTAINERS_PACKAGES_COUNT' | translate | packageTitle}}</div>
        </div>
        <div class="row action remove-action" (click)="onRemove()">
            <div class="icon" *ngIf="!removeCardLoader">
                <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
            </div>
            <div class="loading" *ngIf="removeCardLoader">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.REMOVE_CONTAINER' | translate}}</div>
        </div>
    </div>
</ng-template>
<div class="container-card-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="container-item-container">
        <div class="container-item d-flex">
            <div class="checkbox-container" style="width: 65px; background-color: #FCA429; height: 100%">
                <div class="car-icon">
                    <img src="../../assets/icons/containers-management/car-icon.svg" alt="car-icon" />
                </div>
                <div class="checkbox cursor-pointer" [ngClass]="selectedContainers[container.id] ? 'checked' : ''">
                    <p-checkbox [(ngModel)]="selectedContainers[container.id]" binary="true" (onChange)="onToggleSelect($event)"> </p-checkbox>
                </div>
            </div>
            <div class="data">
                <div>
                    <div class="container-name">
                        <span>{{container.name}} ({{container.brand}})</span>
                    </div>
                    <div class="plate-no">
                        <span>{{container.plateNo}}</span>
                    </div>
                    <div class="container-barcode">
                        <span>#{{container.barcode}}</span>
                    </div>
                    <div class="container-type">
                        <span>{{container.vehicleTypeName}}</span>
                    </div>
                    <div class="driver-info d-flex">
                        <div class="driver-icon">
                            <img class="driver-icon" src="../../assets/icons/taxi-driver.png" alt="driver-Image"/>
                        </div>
                        <div style="width: 13px;"></div>
                        <div class="driver-name">
                            <span>{{getDriverName(container.driver)}}</span>
                        </div>
                    </div>
                    <div class="car-type-info d-flex align-items-baseline">
                        <div class="car-icon">
                            <img src="../../assets/icons/containers-management/vehicle-type.svg" alt="driver-Image"/>
                        </div>
                        <div style="width: 13px;"></div>
                        <div class="car-type-name">
                            <span>{{container.vehicleTypeName}}</span>
                        </div>
                    </div>
                </div>

                <div class="actions  d-flex">
                    <div class="carried-packages d-inline-flex cursor-pointer" (click)="onClickContainerCarriedPackages()">
                        <div class="number-of-packages-icon">
                            <img src="../../assets/icons/containers-management/packages_number.svg" alt="icon" />
                        </div>
                        <div class="number-of-packages-hidden-space"></div>
                        <div class="label">
                            <span>{{'CONTAINERS_MANAGEMENT.CARRIED_PACKAGES' | translate | packageTitle}}</span>
                        </div>
                    </div>
                    <div class="container-details d-inline-flex cursor-pointer" (click)="onClickContainerDetails()">
                        <div class="container-details-icon">
                            <img src="../../assets/icons/containers-management/vehicle_details.svg" alt="icon" />
                        </div>
                        <div class="container-details-hidden-space"></div>
                        <div class="label">
                            <span>{{'CONTAINERS_MANAGEMENT.CONTAINER_DETAILS' | translate}}</span>
                        </div>
                    </div>
                    <div class="dots-menu">
                        <img
                                placement="{{currentLang === 'en' ? 'left': 'right'}}"
                                autoClose="outside"
                                #rowActionsPopOver="ngbPopover"
                                [ngbPopover]="rowActions"
                                src="../../assets/icons/containers-management/menu.svg"
                                alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
