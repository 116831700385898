import {environment} from '../../environments/environment';

export const SYSTEM_UPDATES_CONSTANT = {
    ADD_SYSTEM_UPDATE: ':api/super-admin/companies/system-updates',
    ADD_SYSTEM_UPDATE_FILE: ':api/super-admin/companies/system-updates/file',
    UPDATE_SYSTEM_UPDATE: ':api/super-admin/companies/system-updates/{updateId}',
    REMOVE_SYSTEM_UPDATE: ':api/super-admin/companies/system-updates/{updateId}',
    GET_SYSTEM_UPDATE: ':api/super-admin/companies/system-updates',
    GET_RECENTLY_ADDED_DOCUMENTS: ':api/super-admin/companies/system-updates/recently-added',
    GET_SYSTEM_UPDATE_BY_ID: ':api/super-admin/companies/system-updates/{updateId}',
    GET_SYSTEM_UPDATE_BY_ID_FOR_ADMIN: ':api/admin/companies/system-updates/{updateId}',
    GET_SYSTEM_UPDATE_FOR_ADMIN: ':api/admin/companies/system-updates',
    MARK_ALL_SYSTEM_UPDATE_AS_SEEN: ':api/admin/companies/system-updates/unseen',


};

export const CATEGORIES_OPTIONS = [
    'WITHOUT',
    'RETURNED_PACKAGES',
    'PARTNERS',
    'ADMINISTRATION',
    'CURRENT_REPORTS',
    'MANAGE_USERS',
    'MANAGE_HUBS',
    'MANAGE_PACKAGES',
    'DASHBOARD',
    'ACCOUNTING'
];
