<div>

    <div class="header d-inline-flex mb-2">
        <div class="title">{{'ON_DEMAND.SHIPPING_LINES.MANAGE_DRIVERS' | translate}}</div>
        <div class="flex-1"></div>
        <div class="close-icon" (click)="closeModal()">
            <img src="../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>
    <div class="content">
        <p-pickList [source]="sourceDrivers" [target]="targetDrivers"
                    [sourceHeader]="'ON_DEMAND.ALL_DRIVERS' | translate"
                    [targetHeader]="'ON_DEMAND.SELECTED_DRIVERS'| translate"
                    [dragdrop]="true" (change)="targetUpdated($event)" [(ngModel)]="model"
                    [responsive]="true" [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}"
                    filterBy="name"
                    [sourceFilterPlaceholder]="'ON_DEMAND.SEARCH_BY_NAME' | translate"
                    [targetFilterPlaceholder]="'ON_DEMAND.SEARCH_BY_NAME' | translate">
            <ng-template let-driver pTemplate="item" let-index="rowIndex">
                <div class="driver-item">
                    <div class="image-container">
                        <img src="{{driver.image}}" [alt]="driver.name" class="driver-image"/>
                    </div>
                    <div class="driver-list-detail">
                        <h5 class="mb-2">{{driver.name}}</h5>
                    </div>
                    <div class="driver-list-action">
                        <h6 class="mb-2">{{getIndexOf(driver)}}</h6>
                    </div>
                </div>
            </ng-template>
        </p-pickList>
        <div class="spinner" *ngIf="spinnerState === SpinnerState.LOADING">
            <p-progressSpinner></p-progressSpinner>
        </div>

    </div>
    <div class="row bottom-actions m-3">
        <div class="actions d-inline-flex">
            <ng-container *ngIf="!isLoading">
                <div class="cancel-action d-inline-flex flex-2d-center" (click)="closeModal()">
                    <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.CANCEL' | translate}}</span>
                </div>
                <div class="splitter"></div>
                <div class="done-action flex-2d-center" (click)="submit()">
                    <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</span>
                </div>
            </ng-container>

            <div *ngIf="isLoading">
                <i class="fa-2x fa-pulse fa-spinner fas m-3"></i>
            </div>
        </div>
    </div>
</div>
