import {Component, OnInit} from '@angular/core';
import {ImportCustomersFromExcelService} from '../../services/import-customers-from-excel.service';
import {empty, from, of, Subject} from 'rxjs';
import {catchError, concatMap, debounceTime, distinctUntilChanged, share} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ZonesService} from '../../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {ADMINISTRATION_CONSTANTS} from '../../../administration/administration-constants';
import {EXCEL_IMPORT_CONSTANTS} from '../../../shipment/services/constants';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UsersService} from '../../services/users.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserStatus} from '../../../shared/enums/UserStatus';
import {SHARED_CONSTANTS, VALIDATION_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {ServiceTypesService} from '../../../administration/services/service-types.service';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {HubModel} from '../../../shared/models/hub.model';
import {HubsService} from '../../../shared/services/hubs.service';
import {PricingService} from '../../../shippings-rate/services/pricing.service';
import {Companies} from '../../../../customCompanies/companies.production';

@Component({
    selector: 'app-import-customers-from-excel',
    templateUrl: './import-customers-from-excel.component.html',
    styleUrls: ['./import-customers-from-excel.component.scss']
})
export class ImportCustomersFromExcelComponent extends SubscriptionTrackerComponent implements OnInit {
    customers = [];

    file;
    customersArray: FormArray;
    isLoading = true;
    isPrinting = false;
    addedCustomers = [];
    isGeneralVillageOptions = false;
    villageSearchObserver: Subject<any>;
    villageOptions = [];
    totalEnabledCustomers = 0;
    totalFailed = 0;
    totalSubmitted = 0;
    customersForm: FormGroup;
    userInfo;
    shipmentServicesOptions = [];
    currentLang;
    serviceTypesLoader = false;
    isDynamicServiceTypes;
    serviceTypesSubject = new Subject<string>();
    hubsOptions = [];
    customerTypes = [];
    pricingListType = [];
    public paymentTypeOptions: { value: string, label: string }[] = [];
    showFullForm = false;
    fullFormFor = [Companies.SPRINT, Companies.LOGESTECHS];
    serviceTypeFilled = false;
    enableLimit = true;
    pricingLimit = 0;
    hubsLimit = 0;
    serviceTypeLimit = 0;
    constructor(private importCustomersFromExcelService: ImportCustomersFromExcelService,
                private hubsService: HubsService,
                private userService: UserService,
                private pricingService: PricingService,
                private serviceTypesService: ServiceTypesService,
                private formBuilder: FormBuilder,
                private auth: AuthenticationService,
                private confirmationService: ConfirmationService,
                private translate: TranslateService,
                private usersService: UsersService,
                private activeModal: NgbActiveModal,
                private messageService: MessageService,
                private zonesService: ZonesService) {
        super();
    }

    ngOnInit(): void {
        this.userInfo = this.userService.userInfo;
        this.currentLang = this.translate.currentLang;
        this.isDynamicServiceTypes = this.userInfo.isPricingPerServiceTypeEnabled;
        this.showFullForm = this.fullFormFor.indexOf(this.userInfo.companyId) > -1;
        setTimeout(() => {
            this.importCustomersFromExcelService.importCustomers(this.file).subscribe(res => {
                this.handleCustomers(res);
            }, (error) => {
                this.messageService.add({severity: 'error', detail: this.translate.instant(error)});
                this.activeModal.close();
            });
        }, 300);
        this.initObservers();
        this.initTable();
        this.initServiceTypeOptions();
        this.fetchHubs();
        this.translateCustomerTypes();
        if (this.userInfo.isPricingPerServiceTypeEnabled) {
            this.subscriptions.push(this.serviceTypesSubject
                .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
                .pipe(distinctUntilChanged())
                .subscribe((search: string) => {
                    this.getDynamicServiceTypes(search);
                }));
        }
        this.translatePaymentType();


    }
    private translatePaymentType() {
        this.translate.get(['BANK_TRANSFERS.CASH', 'BANK_TRANSFERS.CHEQUE', 'BANK_TRANSFER', 'BANK_TRANSFERS.PREPAID',
            'BANK_TRANSFERS.DIGITAL_WALLET']).subscribe(
            (res) => {
                this.paymentTypeOptions = [
                    {value: 'CASH', label: res['BANK_TRANSFERS.CASH']},
                    {value: 'CHEQUE', label: res['BANK_TRANSFERS.CHEQUE']},
                    {value: 'BANK_TRANSFER', label: res['BANK_TRANSFER']},
                    {value: 'PREPAID', label: res['BANK_TRANSFERS.PREPAID']},
                    {value: 'DIGITAL_WALLET', label: res['BANK_TRANSFERS.DIGITAL_WALLET']}
                ];
            }
        );
    }

    private initTable() {
        this.customersForm = this.formBuilder.group({
            customers: this.formBuilder.array([])
        });
        this.customersArray = (this.customersForm.get('customers') as FormArray);
    }

    private handleCustomers(customers: []) {
        this.customers = customers;
        from(customers)
            .pipe(concatMap((customer) => this.addCustomer(customer)))
            .subscribe(
                () => {
                    this.getPricingLists({}, true);
                    setTimeout(() => {
                        this.enableLimit = false;
                        this.refillServiceType();

                    }, 300);
                    this.isLoading = false;
                }, (error) => {
                    this.isLoading = false;
                });
    }

    validateUserNameAndEmail(formControl) {
        let value = formControl.value;
        value = value.trim();
        if (/\s/.test(value)) {
            value = value.replace(/\s/, '');
        }

        formControl.setValue(value);
    }

    private addCustomer(customer: any) {
        const customerRow = this.formBuilder.group({
            enabled: [true],
            customerDetails: this.formBuilder.group({
                rowId: [customer.rowId],
                email: [customer.customerInfo.email, Validators.compose([
                    Validators.required,
                    Validators.minLength(VALIDATION_CONSTANTS.USERNAME_EMAIL_MIN_LENGTH)
                ])],
                name: [customer.customerInfo.name, Validators.required],
                phone: [customer.customerInfo.phone, [Validators.required,
                   this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.auth.companyId === 144)),
                    Validators.maxLength(14),
                    Validators.minLength(8)]],
                businessName: [customer.customerInfo.businessName],
                region: [customer.customerInfo.region],
                village: [customer.customerInfo.village, Validators.required],
                city: [customer.customerInfo.city],
                villageLoading: [false],
                villageName:  [customer.customerInfo.village],
                cityLoading: [false],
                addressLine1: [customer.customerInfo.addressLine1, Validators.required],
                password: [customer.customerInfo.password, Validators.compose([Validators.required, Validators.minLength(VALIDATION_CONSTANTS.PASSWORD_MIN_LENGTH),
                    Validators.pattern(VALIDATION_CONSTANTS.PASSWORD_PATTERN)])],
                submitted: [false],
                submitting: [false],
                failed: [false],
                hubId: [customer.customerInfo.hub],
                type: [false],
                paymentMethod: [false],
                pricingListId: [customer.customerInfo.pricingListId],
                optionalNumber: [customer.customerInfo.optionalNumber],
                serviceTypes: [customer.customerInfo.serviceTypes],
                errorMessage: [null]
            })
        });
        this.customersArray.push(customerRow);

        if (customer.customerInfo.village) {
            this.initCustomerVillage(customer, customerRow.get('customerDetails'));
        }
        const customerForm = customerRow.get('customerDetails') as FormGroup;

        for (const control in customerForm.controls) {
            customerForm.get(control).markAsDirty();
        }
        this.totalEnabledCustomers++;

        this.handleFullFormData(customerRow.get('customerDetails'), customer);

        return of(true);
    }

    private initCustomerVillage(customer, customerForm) {
        customerForm.get('villageName').patchValue(customer.customerInfo.village);
        customerForm.get('villageLoading').patchValue(true);
        const villageObservable = this.zonesService
            .getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES,
                this.createParams(customer.customerInfo.village), true);
        const villageSubject = new Subject();
        villageObservable.subscribe(
            (response: any) => {

                if (response.data.length <= EXCEL_IMPORT_CONSTANTS.MAX_OPTIONS_TO_SELECT_ADDRESS &&
                    response.data.length > 0) {
                    let matchIndex = 0;
                    response.data.some((village, index) => {
                        if (village.name.trim().toLowerCase() === customer.customerInfo.village.trim().toLowerCase()) {
                            matchIndex = index;
                            return true;
                        }
                        return false;
                    });
                    customerForm.get('village').patchValue(response.data[matchIndex]);
                    customerForm.get('villageName').patchValue(response.data[matchIndex].name);
                }
                customerForm.get('villageLoading').patchValue(false);
                customerForm.updateValueAndValidity();
                villageSubject.complete();
            }, () => {
                customerForm.get('villageLoading').patchValue(false);
                customerForm.updateValueAndValidity();
                villageSubject.complete();
            }
        );

        return villageSubject;
    }
    private createParams(search?: string, page = 1, pageSize = EXCEL_IMPORT_CONSTANTS.ADDRESS_PAGE_SIZE) {
        const params = {
            pageSize: pageSize,
            page: page,
        };

        if (search && search !== '') {
            params['search'] = search;
        }
        return params;
    }

    updateZoneForm($event, controlName, form) {
        form.controls[controlName].setValue(null);
        form.controls[controlName].updateValueAndValidity();
    }

    printAddedCustomers() {

    }

    public hide() {

        this.confirmationService.confirm({
            message: this.translate.instant('ACTIONS.CONFIRM_CANCEL'),
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
            accept: () => {
               this.activeModal.close({
                   addedCustomers: this.totalSubmitted
               });
            }
        });
    }

    hasError(form: any) {
        if (form.controls.customerDetails.errors) {
            return form.controls.customerDetails.errors.atLeastOne !== undefined
                && form.controls.customerDetails.errors.atLeastOne !== null;
        }
        return false;
    }


    public clearVillageOptions() {
        if (!this.isGeneralVillageOptions) {
            this.villageOptions = [];
        }
    }

    public getVillageOptions(customerForm: FormGroup) {
        if (!this.isGeneralVillageOptions) {
            customerForm.get('customerDetails').get('villageLoading').patchValue(true);
            this.zonesService.getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES,
                this.createParams('', 1, EXCEL_IMPORT_CONSTANTS.GENERAL_ADDRESS_PAGE_SIZE), true).subscribe(
                (response: any) => {
                    this.isGeneralVillageOptions = true;
                    this.villageOptions = response.data;
                    customerForm.get('customerDetails').get('villageLoading').patchValue(false);
                }, (error) => {
                    customerForm.get('customerDetails').get('villageLoading').patchValue(false);
                    console.error(error);
                }
            );
        }
    }

    private initObservers() {
        this.villageSearchObserver = new Subject();
        this.villageSearchObserver.subscribe(
            (data) => {
                if (typeof data === 'string' && data.length !== 0) {
                    const params = this.createParams(data);
                    this.zonesService
                        .getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES, params).subscribe(
                        (response: any) => {
                            this.villageOptions = response.data;
                            this.villageSearchObserver.next(true);
                        }
                    );
                } else if (data.length === 0) {
                    this.villageSearchObserver.next(true);
                }
            }, (error) => {
                console.error(error);
                this.villageSearchObserver.next(true);
            }
        );
    }

    public changeCustomersEnable(customer: FormGroup) {
        if (customer.get('enabled').value === true) {
            customer.get('customerDetails').enable();
            this.totalEnabledCustomers++;
        } else {
            customer.get('customerDetails').disable();
            this.totalEnabledCustomers--;
        }
    }

    submitForm() {
        this.translate.get([
            'ALERTS.CONFIRM_IMPORT_EXCEL',
            'GENERAL.YES', 'GENERAL.NO'
        ]).subscribe((values) => {
            this.confirmationService.confirm({
                message: this.translate.instant('ALERTS.CONFIRM_IMPORT_EXCEL'),
                acceptLabel: this.translate.instant('GENERAL.YES'),
                rejectLabel: this.translate.instant('GENERAL.NO'),
                accept: () => {
                    this.submitCustomers();
                }
            });
        });
    }

    public async submitCustomers() {
        const requestObservables = [];
        this.isLoading = true;
        this.totalFailed = 0;
        from(this.customersArray.controls.filter(control => {
            const controlGroup = control as FormGroup;
            const controlForm = controlGroup.get('customerDetails') as FormGroup;
            return controlForm.get('submitted').value === false && controlGroup.get('enabled').value === true;
        })).pipe(
            concatMap((row) => {
                const customerRow = row as FormGroup;
                const customerForm = customerRow.get('customerDetails') as FormGroup;
                if (customerForm.get('submitted').value === false && customerRow.get('enabled').value === true) {
                    customerForm.get('submitting').patchValue(true);
                    const requestObservable = this.usersService.addCustomer(this.getCustomer(customerForm)).pipe(share(),
                        catchError((error) => {
                            if (!customerForm.get('submitting').value) {
                                this.totalFailed++;
                            }
                            customerForm.get('errorMessage').patchValue(error.error.error);
                            customerForm.get('submitting').patchValue(false);
                            customerForm.get('failed').patchValue(true);
                            return empty();
                        }));
                    requestObservable.subscribe(
                        (res: any) => {
                            customerForm.get('submitting').patchValue(false);
                            customerForm.get('submitted').patchValue(true);
                            customerForm.get('failed').patchValue(false);
                            customerForm.get('errorMessage').patchValue(null);
                            customerRow.disable();
                            this.addedCustomers.push(res.id);
                            this.totalSubmitted++;
                            this.totalEnabledCustomers--;
                        }
                    );
                    requestObservables.push(requestObservable);
                    return requestObservable;
                }
            })
        ).subscribe(
            () => {
            }, () => {
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
                if (this.totalFailed === 0) {
                }
            }
        );
    }

    private getCustomer(shipmentForm: any) {
        this.isLoading = true;

        const data = shipmentForm.getRawValue();
        const nameArray = (data.name || '').trim().split(/\s+/);
        let fName;
        let lName;
        if (nameArray.length === 1) {
            fName = nameArray[0];
            lName = nameArray[0];
        } else if (nameArray.length > 1) {
            fName = nameArray.slice(0, nameArray.length - 1).join(' ');
            lName = nameArray[nameArray.length - 1];

        } else {
            fName = '.';
            lName = '.';
        }
        let body = {
            address: {
                addressLine1: data.addressLine1,
                cityId: data.village.cityId,
                city: data.village.cityName,
                country: data.village.country,
                villageId: data.village.id,
                village: data.village.name,
                regionId: data.village.regionId,
                region: data.village.regionName
            },
            cityId: data.city?.value,
            country: data.country,
            firstName: fName,
            lastName: lName,
            phone: data.phone,
            email: data.email,
            businessName: data.businessName,
            password: data.password,
            status: UserStatus.VERIFIED,
            verificationDate: new Date().toISOString()

        };
        if (this.showFullForm) {
            body = {
                ...body,
                ...{
                    hubId: data.hubId,
                    type: data.type,
                    paymentMethod: data.paymentMethod,
                    pricingListId: data.pricingListId?.value,
                    serviceTypes: data.serviceTypes,
                    optionalNumber: data.optionalNumber,
                }
            };
        }
        return body;
    }

    private initServiceTypeOptions() {
        if (!this.userInfo.isPricingPerServiceTypeEnabled) {
            this.shipmentServicesOptions = SHARED_CONSTANTS.STATIC_SERVICE_TYPES.GENERAL_SERVICE_TYPES.map(key => {
                return {
                    label: this.translate.instant(key),
                    value: key
                };
            });
            this.refillServiceType();
        } else {
            this.getDynamicServiceTypes();
        }
    }

    getDynamicServiceTypes(search = null) {
        if (this.enableLimit && this.serviceTypeLimit++ > 1) {
            return;
        }
        let query = '?page=1&pageSize=20';
        if (search) {
            query += '&search=' + search;
        }
        this.serviceTypesLoader = true;
        this.serviceTypesService.getServiceTypes(query).subscribe(
            (res: any) => {
                if (res.data.length) {
                    this.shipmentServicesOptions = res.data.map(item => ({label: this.currentLang === 'en' ? item.name : item.arabicName, value: item.id}));
                    // if (!this.isEdit && this.form && this.form.get('serviceTypes') && !this.shipmentTypesInitialValueFilled) {
                    //     this.form.get('serviceTypes').patchValue(this.shipmentServicesOptions.map(e => e.value));
                    //     this.shipmentTypesInitialValueFilled = true;
                    // }
                    this.refillServiceType();
                    this.serviceTypesLoader = false;

                }
            }, error => {
                this.serviceTypesLoader = false;
                console.error(error);
            }
        );
    }

    private fetchHubs() {
        if (this.enableLimit && this.hubsLimit++ > 1) {
            return;
        }
         this.hubsService.getHubs('?page=1&pageSize=100').subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
                this.refillHub();
            }, (error) => {
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    private translateCustomerTypes() {
        this.customerTypes = [
            {value: 'NORMAL', label: this.translate.instant('CUSTOMER_TYPES.NORMAL')},
            {value: 'EXPRESS', label: this.translate.instant('CUSTOMER_TYPES.EXPRESS')}
        ];
    }

    public getPricingLists(data, firstTime = false) {
        const query = {
            page: 1,
            pageSize: 100
        };
        if (data.query) {
            query['search'] = data.query;
        }
        this.initPricingLists(query, firstTime);
    }

    initPricingLists(query, firstTime = false) {
        if (this.enableLimit && this.pricingLimit++ > 1) {
            return;
        }
        this.pricingService.getPricingList(query).subscribe((response: {data: any, totalRecordsNo: number} ) => {
            this.pricingListType =  response.data.map(
                (res) => {
                    return {label: res.name, value: res.id};
                }
            );
            if (firstTime) {
                this.refillPricing();
            }
        }, () => {
        });
    }

    private handleFullFormData(form, customer) {
        if (!this.showFullForm) {
            return;
        }
        const type = customer.customerInfo.type;
        if (type) {
            const typeObject = this.customerTypes.find((value, index) => {
                return value.label.toLowerCase() === type.toString().toLowerCase();
            });
            form.get('type').patchValue(typeObject?.value);
        }

        const paymentMethod = customer.customerInfo.paymentMethod;
        if (paymentMethod) {
            const paymentObject = this.paymentTypeOptions.find((value, index) => {
                return value.label.toLowerCase() === paymentMethod.toString().toLowerCase();
            });
            form.get('paymentMethod').patchValue(paymentObject?.value);
        }
        console.log(customer);
    }

    private refillHub() {

        this.customersArray.controls.forEach(control => {
            control.get('customerDetails').get('hubId').setValidators(Validators.required);
            const hub = control.get('customerDetails').get('hubId').value;
            if (hub) {
                const hubObject = this.hubsOptions.find((value, index) => {
                    return value.label.toLowerCase() === hub.toString().toLowerCase();
                });
                control.get('customerDetails').get('hubId').patchValue(hubObject?.value);

            }
        });
    }

    private refillPricing() {

        this.customersArray.controls.forEach(control => {
            const pricing = control.get('customerDetails').get('pricingListId').value;
            if (pricing) {
                const pricingObject = this.pricingListType.find((value, index) => {
                    return value.label.toLowerCase() === pricing.toString().toLowerCase();
                });
                if (pricingObject) {
                    control.get('customerDetails').get('pricingListId').patchValue(pricingObject);
                }
            }
        });
    }

    private refillServiceType() {
        if (this.serviceTypeFilled) {
            return;
        }
        this.customersArray.controls.forEach(control => {
            const serviceTypes = (control.get('customerDetails').get('serviceTypes').value || '').split(',').map(a => a.trim().toLowerCase());
            if (serviceTypes) {
                const serviceTypesArr = this.shipmentServicesOptions.filter((value, index) => {
                    return serviceTypes.indexOf(value.label.trim().toLowerCase()) > -1;
                }).map(a => a.value);
                control.get('customerDetails').get('serviceTypes').patchValue(serviceTypesArr);
            }
            this.serviceTypeFilled = true;
        });
    }
}
