import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-field-error-message',
  templateUrl: './field-error-message.component.html',
  styleUrls: ['./field-error-message.component.scss']
})
export class FieldErrorMessageComponent {
  @Input() formControlObj: FormControl;
  @Input() disableField = false;
  object = Object;

  constructor(private translateService: TranslateService) {
  }

  getError(error: any) {
    if (error.required) {
      return this.translateService.instant('GENERAL.REQUIRED_VALIDATION');
    } else if (error.maxlength) {
      return this.translateService.instant('GENERAL.MAX_LENGTH', {data: error.maxlength.requiredLength});
    } else if (error.minlength) {
      return this.translateService.instant('GENERAL.MIN_LENGTH', {data: error.minlength.requiredLength});
    } else {
      return this.translateService.instant('GENERAL.INVALID_ERROR_MESSAGE');
    }
  }
}
