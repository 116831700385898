<div class="new-service-type-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
    <div class="header d-inline-flex justify-content-between">
        <div class="title" *ngIf="!isEdit">{{ 'SERVICE_TYPE.ACTIONS.NEW' | translate }}</div>
        <div class="title" *ngIf="isEdit">{{ 'SERVICE_TYPE.ACTIONS.EDIT' | translate }}</div>
        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <form [formGroup]="serviceTypeForm">

        <div class="inputs-container">
            <div class="row row-input">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label"
                         [ngClass]="isInvalidDirtyTouched('name') ? 'invalid-message' : ''">{{ 'SERVICE_TYPE.TABLE.NAME' | translate }}</div>
                    <div class="input-value">
                        <input type="text" size="3" pInputText formControlName="name"/>
                    </div>
                    <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('name', 'required')">
              {{ 'SERVICE_TYPE.VALIDATION.NAME_REQUIRED' | translate }}
            </span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label"
                         [ngClass]="isInvalidDirtyTouched('arabicName') ? 'invalid-message' : ''">{{ 'SERVICE_TYPE.TABLE.ARABIC_NAME' | translate }}</div>
                    <div class="input-value">
                        <input type="text" size="3" pInputText formControlName="arabicName"/>
                    </div>
                    <div class="invalid-message invalid-container">
                        <span *ngIf="isInvalidWithError('arabicName', 'required')">
                          {{ 'SERVICE_TYPE.VALIDATION.ARABIC_NAME_REQUIRED' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row row-input">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label"
                         [ngClass]="isInvalidDirtyTouched('expectedDeliveryHours') ? 'invalid-message' : ''">{{ 'SERVICE_TYPE.TABLE.EXPECTED_DELIVERY_DAYS' | translate }}</div>
                    <div class="input-value">
                        <input type="number" (change)="setExpectedPickupHoursValidators()" size="3" pInputText formControlName="expectedDeliveryHours"/>
                    </div>
                    <div class="invalid-message invalid-container">
                        <span *ngIf="isInvalidWithError('expectedDeliveryHours', 'required')">
                          {{ 'SERVICE_TYPE.VALIDATION.EXPECTED_DELIVERY_DAYS_REQUIRED' | translate }}
                        </span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label"
                         [ngClass]="(isInvalidDirtyTouched('expectedPickupHours') || hasError('expectedPickupHours', 'max')) ? 'invalid-message' : ''">{{'SERVICE_TYPE.TABLE.EXPECTED_PICKUP_DAYS' | translate}}</div>
                    <div class="input-value">
                        <input type="number" size="3" pInputText formControlName="expectedPickupHours"/>
                    </div>
                    <div class="invalid-message invalid-container">
                        <span *ngIf="isInvalidWithError('expectedPickupHours', 'required')">
                          {{ 'SERVICE_TYPE.VALIDATION.EXPECTED_PICKUP_DAYS_REQUIRED' | translate }}
                        </span>
                        <span *ngIf="isInvalidWithError('expectedPickupHours', 'max') || hasError('expectedPickupHours', 'max')">
                            {{ 'SERVICE_TYPE.VALIDATION.EXPECTED_PICKUP_DAYS_MAX' | translate }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row row-input">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label">
                        {{ 'SERVICE_TYPE.TABLE.VEHICLES_TYPES' | translate }}
                    </div>
                    <div class="input-value">
                        <p-multiSelect [options]="vehicleTypes"
                                       [autoZIndex]="true"
                                       [baseZIndex]="1100"
                                       formControlName="vehicleTypes"
                                       maxSelectedLabels="0"
                                       selectedItemsLabel="{0} {{'GENERAL.ITEMS' | translate}}"
                                       filterPlaceHolder="{{'GENERAL.SEARCH' | translate}}.."
                                       defaultLabel="">
                        </p-multiSelect>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input default-vehicle-type-input">
                    <div class="input-label insurance-expiry-label">{{'SERVICE_TYPE.TABLE.DEFAULT_VEHICLE_TYPE' | translate }}</div>
                    <div class="input-value row date-input">
                        <p-dropdown [options]="getSelectedVehicleTypes()"
                                       [autoZIndex]="true"
                                       [baseZIndex]="1100"
                                       placeholder="{{'SERVICE_TYPE.SELECT_DEFAULT_VEHICLE_TYPE' | translate}}"
                                       formControlName="defaultVehicleTypeId">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="row row-input">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label insurance-expiry-label">{{'SERVICE_TYPE.TABLE.LAST_ORDER_TIME' | translate }}</div>
                    <div class="input-value row date-input">
                        <p-calendar formControlName="lastOrderTime" [timeOnly]="true"></p-calendar>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input color-input-container">
                    <div class="input-label insurance-expiry-label">{{'SERVICE_TYPE.TABLE.COLOR' | translate }}</div>
                    <input type="color" formControlName="color">
                </div>
            </div>
        </div>

        <div class="bottom-actions">
            <div class="done-action" (click)="submitForm()"
                 [ngClass]="{'disabled-btn disabled': !serviceTypeForm.valid || isLoading}">
                <span>{{'ACTIONS.SUBMIT' | translate}}</span>
            </div>
        </div>
    </form>
</div>
