import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HubsService} from '../../shared/services/hubs.service';

@Component({
    selector: 'app-shelf-packages',
    templateUrl: './shelf-packages.component.html',
    styleUrls: ['./shelf-packages.component.scss']
})
export class ShelfPackagesComponent implements OnInit {
    public shelfId;
    public packagesAPI;

    constructor(private route: ActivatedRoute,
                private hubsService: HubsService) {
    }

    ngOnInit() {
        this.shelfId = this.route.snapshot.paramMap.get('id');
        this.packagesAPI = this.hubsService.getShelfPackagesAPI(this.shelfId);
    }

}
