<div class="main-container">
    <div class="header d-flex justify-content-between align-items-center">
        <div class="title">
            <span class="title-value title-bold-part">{{'SYSTEM_UPDATES.BREADCRUMB.PART_1' | translate}}</span>
            <span class="title-value title-bold-part mr-3 ml-3">{{'SYSTEM_UPDATES.BREADCRUMB.PART_2' | translate}}</span>
            <span class="title-value title-last-part">{{pdfName}}</span>
        </div>
        <div class="actions">
            <div class="print action d-flex" (click)="print()">
                <div class="icon">
                    <img src="../../../../assets/icons/print.svg" alt=""/>
                </div>
                <div class="label">{{'ACTIONS.PRINT' | translate}}</div>
            </div>
        </div>
    </div>

    <div class="bdf-viewer-container" *ngIf="!videoUrl || showAll || true">
        <div id="canvas_container" dir="ltr">
            <pdf-viewer [src]="pdfSrc"
                        [render-text]="true"
                        [original-size]="false"
                        [autoresize]="true"
            ></pdf-viewer>
        </div>
        <div class="pdf-controllers">
            <div class="d-flex justify-content-center">
                <div class="control-button zoom-in-button" (click)="incrementZoom(0.25)">
                </div>
                <div class="control-button zoom-out-button" (click)="incrementZoom(-0.25)">
                </div>
            </div>
        </div>
    </div>

    <div class="loader-container" *ngIf="showLoader">
        <div class="loading">
            <span><i class="fas fa-spinner fa-pulse"></i></span>
        </div>
    </div>


</div>
