import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {NewCompanyComponent} from './companies/new-company/new-company.component';
import {CompaniesComponent} from './companies/companies/companies.component';
import {COMPANY_ROUTES} from './companies/routes/company.routes';
import {RoleGuard} from './shared/guards/role.guard';
import {CompanyGuard} from './shared/guards/company.guard';
import {Page404Component} from './shared/components/404/404.component';
import {AccountComponent} from './account/account.component';
import {environment} from '../environments/environment';
import {CompanyBillingComponent} from './companies/company-billing/company-billing.component';
import {SystemUpdatesComponent} from './system-updates/components/system-updates/system-updates.component';
import {UpdateInfoPdfViewerComponent} from './system-updates/components/update-info-pdf-viewer/update-info-pdf-viewer.component';
import {FaqListComponent} from './faq/components/faq-list/faq-list.component';
import {SystemUpdatesV2Component} from './system-updates/components/system-updates-v2/system-updates-v2.component';
import {NgModule} from '@angular/core';
import {CheckLoginGuard} from './shared/guards/checklogin.guard';
import {CheckLoginComponent} from './shared/components/check-login/check-login.component';
import {SystemShuttingDownComponent} from './shared/components/system-shutting-down/system-shutting-down.component';

const defaultRoutes = [
    {
        path: '',
        redirectTo: 'redirect',
        pathMatch: 'full'
    },
    {
        path: 'redirect',
        component: CheckLoginComponent,
        canActivate: [CheckLoginGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        data: {
            isSuperAdmin: false
        }
    },
    {
        path: 'notify-shutdown',
        canActivate: [AuthGuard],
        component: SystemShuttingDownComponent
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    },
    {
        path: '**',
        component: CheckLoginComponent,
        canActivate: [CheckLoginGuard]
    }
];

// @ts-ignore
const routes: Routes = [
    ...(environment.isOneDomain ? [
        {
            path: '',
            redirectTo: 'redirect',
            pathMatch: 'full'
        },
        {
            path: 'redirect',
            component: CheckLoginComponent,
            canActivate: [CheckLoginGuard]
        },
        {
            path: 'login',
            component: LoginComponent,
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
            data: {
                isSuperAdmin: true
            }
        },
        {
            path: 'new-company',
            component: NewCompanyComponent,
            data: {
                isGuest: true,
                isModal: false,
            }
        },
        {
            path: 'super-admin',
            component: HomeComponent,
            canActivate: [AuthGuard],
            children: [
                {
                    path: '',
                    redirectTo: 'companies',
                    pathMatch: 'full'
                },
                {
                    path: 'companies',
                    component: CompaniesComponent,
                    canActivate: [RoleGuard],
                    children: COMPANY_ROUTES
                },
                {
                    path: 'company-management',
                    component: CompanyBillingComponent,
                    canActivate: [RoleGuard]
                },
                {
                    path: 'company-billing',
                    component: CompanyBillingComponent,
                    canActivate: [RoleGuard]
                },
                {
                    path: 'notification-history',
                    component: CompanyBillingComponent,
                    canActivate: [RoleGuard]
                },
                {
                    path: 'account-settings',
                    component: AccountComponent,
                    canActivate: [RoleGuard]
                },
                // {
                //     path: 'companies-payments',
                //     component: ManagePaymentsComponent,
                //     canActivate: [RoleGuard],
                //     data: {
                //         isSuperAdmin: true
                //     }
                // },
                {
                    path: 'system-updates',
                    canActivate: [RoleGuard],
                    children: [
                        {
                            path: '',
                            component: SystemUpdatesComponent,
                        },
                        {
                            path: ':id',
                            component: UpdateInfoPdfViewerComponent,
                        }
                    ]
                },
                {
                    path: 'documents-system-updates',
                    canActivate: [RoleGuard],
                    children: [
                        {
                            path: '',
                            component: SystemUpdatesV2Component,
                            data: {
                                documents: true
                            },
                        },
                        {
                            path: ':id',
                            component: UpdateInfoPdfViewerComponent,
                        }
                        // {
                        //     path: ':id',
                        //     component: UpdateInfoPdfViewerComponent,
                        // }
                    ]
                },
                {
                    path: 'videos-system-updates',
                    canActivate: [RoleGuard],
                    children: [
                        {
                            path: '',
                            component: SystemUpdatesV2Component,
                            data: {
                                videos: true
                            }
                        },
                        // {
                        //     path: ':id',
                        //     component: UpdateInfoPdfViewerComponent,
                        // }
                    ]
                },
                {
                    path: 'faq',
                    canActivate: [RoleGuard],
                    children: [
                        {
                            path: '',
                            component: FaqListComponent,
                        }
                    ]
                },
            ]
        },
        {
            path: ':companyName',
            canActivate: [CompanyGuard],
            children: defaultRoutes
        }
    ] : defaultRoutes),
    {
        path: '404',
        component: Page404Component,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
