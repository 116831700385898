<div class="hub-card d-flex">
    <div class="destination-dots-btn d-flex justify-content-between">
        <div class="destination font-weight-bold">الى فرع نابلس</div>
        <div class="dots-btn cursor-pointer">
            <img src="../../../../assets/icons/containers-management/menu.svg" alt=""/>
        </div>
    </div>

    <div class="data-item">
        <div class="font-weight-bold value">55</div>
        <div class="label">عدد الطرود</div>
    </div>

    <div class="summery d-flex justify-content-between">
        <div class="data-item">
            <div class="font-weight-bold value"><span class="currency">{{currency}}</span> 2,300</div>
            <div class="label">التحصيلات المستحقة من فرع رام الله</div>
        </div>

        <div class="data-item">
            <div class="font-weight-bold value"><span class="currency font-weight-bold">{{currency}}</span> 1,120</div>
            <div class="label">المبلغ المطلوب للدفع لفرع نابلس</div>
        </div>


    </div>
</div>
