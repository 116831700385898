import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HubsService} from '../../../shared/services/hubs.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeographyService} from '../../../shared/services/geography.service';
import {ZonesService} from '../../services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user.service';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {forkJoin} from 'rxjs';
import {ShipmentsService} from '../../../shipment/services/shipments.service';

@Component({
    selector: 'app-export-package-content-to',
    templateUrl: './export-package-content-to.component.html',
    styleUrls: ['./export-package-content-to.component.scss']
})
export class ExportPackageContentToComponent implements OnInit {
    public form: FormGroup;
    public isSubmitting = false;
    public isLoading = true;
    public hubTypes: { label: string, value: any } [];
    public isEditMode = false;
    public currentLang = '';
    public isWarehouse = false;
    public isEnableExportingBetweenHubs = false;
    selectedPrintType = [];
    checked = true;
    packageId;
    type;

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private geographyService: GeographyService,
        private translateService: TranslateService,
        private userService: UserService,
        private shipmentService: ShipmentsService
    ) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isLoading = false;
    }


    public onSubmit() {
        this.isSubmitting = true;

        const promises = [];
        this.selectedPrintType.map(value => {
            console.log(value);
            promises.push(this.shipmentService.exportPackageContentsReport(value, this.packageId, this.type));
        });

        forkJoin(promises).subscribe((response: any) => {

            response.map(value => {
                window.open(value.url, '_blank');
            });
            this.activeModal.close({isSuccess: true});
        }, (error) => {
            this.activeModal.close({isSuccess: true});
        });
    }

    public closeModal() {
        this.activeModal.close({isSuccess: false});
    }

    updateZoneForm($event, controlName) {
        this.form.controls[controlName].setValue(null);
        this.form.controls[controlName].updateValueAndValidity();
    }

    onChangeHubType() {
        if (this.form.get('type').value === 'SUB') {
            this.form.get('isHideSenderInfoForReceivingHub').reset();
            this.form.get('isPreventReceivingHubsFromModifyingPackage').reset();
        }
    }
}
