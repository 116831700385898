<div class="assign-to-container">
    <div id="submitting-container" *ngIf="isSubmitting">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="title-container row">
        <div class="title d-flex" style="position: relative; padding: 0">
            <div class="driver-icon">
                <img *ngIf="assignTo === 'driver'" src="../../assets/icons/manage_shipments/assign-driver.png" alt="" />
                <img *ngIf="assignTo === 'container'" src="../../assets/icons/manage_shipments/assign-to-container.png" alt="" />
            </div>
            <div class="label">
                <span *ngIf="assignTo === 'driver'">{{'MANAGE_SHIPMENTS.ASSIGN_TO_DRIVER' | translate}}</span>
                <span *ngIf="assignTo === 'container'">{{'MANAGE_SHIPMENTS.ASSIGN_TO_CONTAINER' | translate}}</span>
            </div>
        </div>
        <div class="close-icon cursor-pointer">
            <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt="" />
        </div>
    </div>
    <div class="search-bar">
        <div>
            <div class="search-icon">
                <i class="fas fa-search"></i>
            </div>
            <div class="search-input">
                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent"
                       (input)="onSearch()">
            </div>
        </div>
    </div>
    <div class="list-container">
        <ng-container *ngIf="spinnerState === spinnerStats.LOADED">
            <ng-container *ngIf="assignTo === 'driver'; else containersList">
                <ng-container *ngIf="displayedDrivers && displayedDrivers.length > 0; else emptyData">
                    <div class="assigner-container" *ngFor="let driver of displayedDrivers">
                        <div class="assigner-name">
                            {{driver.firstName}} {{driver.lastName}}, {{driver.phone}}
                        </div>
                        <div class="assigner-barcode">
                            #{{driver.barcode}}
                        </div>
                        <div class="assign-action cursor-pointer" (click)="onAssignDriver(driver)">
                            {{"ACTIONS.ASSIGN" | translate}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #containersList>
                <ng-container *ngIf="displayedContainers && displayedContainers.length > 0; else emptyData">
                    <div class="assigner-container" *ngFor="let container of displayedContainers">
                        <div class="assigner-name">
                            {{container.name}}, {{container.brand}}
                        </div>
                        <div class="assigner-barcode">
                            #{{container.barcode}}
                        </div>
                        <div class="assign-action cursor-pointer" (click)="onAssignContainer(container)">
                            {{"ACTIONS.ASSIGN" | translate}}
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="spinnerState === spinnerStats.LOADING">
            <div class="text-align: center">
                <p-progressSpinner>
                </p-progressSpinner>
            </div>
        </ng-container>
        <ng-container *ngIf="spinnerState === spinnerStats.EMPTY">
            <app-no-data-available-component>
            </app-no-data-available-component>
        </ng-container>
    </div>
</div>

<ng-template #emptyData>
    <app-no-data-available-component>
    </app-no-data-available-component>
</ng-template>
