<div class="choose-print-type" [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div *ngIf="!isModal" class="print-options-container">
        <div *ngFor="let printType of printTypes" class="print-option" (click)="choose(printType.value)">
            <div class="icon">
                <img src="{{getImageSrc(printType.imageSrc)}}" alt="" />
            </div>
            <div class="label">{{printType.label | translate}}</div>
        </div>
    </div>

    <div *ngIf="isModal" class="print-container">
        <div class="title-container row">
            <div class="title d-flex" style="position: relative; padding: 0;">
                <div class="print-icon">
                    <img src="../../assets/icons/printTypes/print-type-title.png" alt="" />
                </div>
                <div class="label">
                    <span>{{('SHIPMENT.CHOOSE_PRINT_TYPE') | translate }}</span>
                </div>
            </div>
            <div class="close-icon cursor-pointer">
                <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt="" />
            </div>
        </div>
        <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
            <p-progressSpinner> </p-progressSpinner>
        </div>
        <div class="input-fields">
            <div>
                <div class="inputs row switches-container">
                    <div class="d-flex print-type-switch" *ngFor="let printType of printTypes">
                        <div class="label">
                            <span class="print-radio-label">{{printType.label | translate}}</span>
                        </div>
                        <div class="value">
                            <p-inputSwitch [(ngModel)]="printType.isSelected" (onChange)="setPrintType(printType.value)"></p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="splitter-container row">
            <div class="splitter"></div>
        </div>
        <div class="row justify-content-end bottom-actions">
            <div class="reset-button cursor-pointer" (click)="closeModal()">
                {{"ACTIONS.CANCEL" | translate}}
            </div>
            <div class="add-button cursor-pointer" (click)="choose()" [ngClass]="{'disabled-button disabled': !printType || isLoading}">
                {{'ACTIONS.DONE' | translate }}
            </div>
        </div>
    </div>

</div>
