<p-toast></p-toast>
<div class="distributor-packages-contents-report h-100">
    <div class="header d-flex justify-content-between">
        <div class="page-header-title">
            <div class="title">
                {{(reportType === 'BY_BARCODE' ? 'DISTRIBUTOR_REPORTS.PACKAGES_CONTENTS' : 'DISTRIBUTOR_REPORTS.CUSTOMER_PACKAGES_CONTENTS') | translate}}
            </div>
            <div class="total-number-badge">{{totalRecordsNo}}</div>
        </div>
        <div class="filters d-flex justify-content-between align-items-center flex-wrap">
            <div class="customer">
                <app-users-list-dropdown-component
                        #customerFilter
                        [placeHolder]="'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME'"
                        [showResetButton]="true"
                        (userChanged)="onSelectCustomer($event)"
                ></app-users-list-dropdown-component>
            </div>
<!--            <div class="date date-filter">-->
<!--                <div class="date-range">-->
<!--                        <span (click)="datepicker.click()">-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </span>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                           [options]="dateRangPickerOptions" (selected)="onDateSelected($event)"/>-->
<!--                    <img class="arrow-down" src="../../../assets/icons/arrow-down.png">-->
<!--                </div>-->
<!--            </div>-->
            <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

            <div class="search main-search-filter">
                <i class="fas fa-search"></i>
                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." appInputDebounced
                       (inputDebounced)="onFilter()" [(ngModel)]="search">
            </div>
        </div>
    </div>
    <div class="main-table report-table">
        <p-table appPTableRtlFix dataKey="id" #table [value]="reportData"
                 paginatorDropdownAppendTo="body"
                 styleClass="package-contents-report-table flex-table"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecordsNo" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>

            <ng-template pTemplate="header">
<!--                <th>-->
<!--                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"-->
<!--                                (onChange)="onToggleSelectAll()">-->
<!--                    </p-checkbox>-->
<!--                </th>-->
                <th>{{(reportType === 'BY_BARCODE' ? 'BARCODE.TITLE' : 'ADMINISTRATION.CUSTOMER_NAME') | translate}}</th>
               <ng-container *ngIf="reportType === 'BY_BARCODE';else byCustomerHeaders">
                   <th>{{'ADMINISTRATION.CUSTOMER_NAME' | translate}}</th>
                   <th>{{'MANAGE_SHIPMENTS.BOOKED_ON' | translate}}</th>
               </ng-container>
                <ng-template #byCustomerHeaders>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.BOXES' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_BOXES' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.CARTOONS' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_CARTOONS' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.PACKAGES' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_PACKAGES' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.PALLETS' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_PALLETS' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.UNIT' | translate}}</th>
                    <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_UNIT' | translate}}</th>
                    <th>
                        <div>{{'DISTRIBUTOR_REPORTS.TABLE.WEIGHT' | translate}}</div>
                        <div>({{'UNITS.KG' | translate}})</div>
                    </th>
                    <th>
                        <div>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_WEIGHT' | translate}}</div>
                        <div>({{'UNITS.KG' | translate}})</div>
                    </th>
                </ng-template>
                <ng-container *ngIf="showExportButtons">
                    <th>{{'ACTIONS.EXPORT_TO_EXCEL' | translate}}</th>
                    <th>{{'ACTIONS.EXPORT_TO_PDF' | translate}}</th>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr (click)="openContentsDialog(report.packageId)">
<!--                    <td>-->
<!--                        <p-checkbox [(ngModel)]="report.isSelected" binary="true"-->
<!--                                    (click)="$event.stopPropagation()"-->
<!--                        ></p-checkbox>-->
<!--                    </td>-->
                    <td (click)="$event.stopPropagation()">
                        <ng-container *ngIf="reportType === 'BY_BARCODE'; else byCustomer">
                            <div class="barcode-container flex-2d-center flex-column">
                                <div class="barcode-value flex-2d-center">
                                    <div class="value">{{report.packageBarcode}}</div>
                                    <div class="copy-icon">
                                        <img src="../../assets/icons/track_package/copy.svg" alt=""
                                             (click)="copyMessage($event,null, report.packageBarcode)"
                                             data-toggle="tooltip" data-placement="top" title="Copy"
                                             class="copy_icon"/>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #byCustomer>
                            {{report.customerName}}
                        </ng-template>
                    </td>
                    <ng-container *ngIf="reportType === 'BY_BARCODE';else byCustomerData">
                        <td>{{report.customerName}}</td>
                        <td>{{report.createdDate | date:'dd/MM/yyyy'}}</td>
                    </ng-container>
                    <ng-template #byCustomerData>
                        <td>{{report.boxes}}</td>
                        <td>{{report.originalBoxes}}</td>
                        <td>{{report.cartons}}</td>
                        <td>{{report.originalCartons}}</td>
                        <td>{{report.packages}}</td>
                        <td>{{report.originalPackages}}</td>
                        <td>{{report.pallets}}</td>
                        <td>{{report.originalPallets}}</td>
                        <td>{{report.unit}}</td>
                        <td>{{report.originalUnit}}</td>
                        <td>{{report.weight}}</td>
                        <td>{{report.originalWeight}}</td>
                    </ng-template>
                    <ng-container *ngIf="showExportButtons">
                        <td>
                            <div class="row-action">
                                <div class="edit-action action cursor-pointer"
                                     (click)="exportToFile(report,$event,'excel')">
                                    <i class="fas fa-file-excel"></i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row-action">
                                <div class="edit-action action cursor-pointer"
                                     (click)="exportToFile(report,$event, 'pdf')">
                                    <i class="fas fa-file-pdf"></i>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
