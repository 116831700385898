export const ROLES = {
    MONITOR: {
        'COMPANIES': [],
        'COMPANY_MANAGEMENT': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
    },
    SUPER_ADMIN: {
        'COMPANIES': [],
        'COMPANY_MANAGEMENT': [],
        'SUPER_ADMIN_ACCOUNT_SETTINGS': [],
        'PRINTED_REPORTS': [],
        'PARTNERS_REPORTS': [],
        // 'COMPANIES_PAYMENTS': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],

    },
    SUPER_ADMIN_AS_ADMIN: {
        'DASHBOARD': [],
        'INBOX': [],
        'ADD_SHIPMENT': [],
        'CHANGE_STATUS': [],
        'PRINTED_REPORTS_NEW': [],
        'MANAGE_SHIPMENTS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'ASSIGN_TO_CONTAINER',
            'SWAP_SENDER_RECEIVER',
            'RECEIVE_FROM_DRIVER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'REMOVE_PACKAGE',
            'REMOVE_PACKAGES',
            'UNLISTED_PACKAGES',
            'APPROVE_PACKAGES',
            'EDIT_COST',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CANCEL',
            'RECEIVE_DRIVER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'RECEIVE_FROM_PARTNER',
            'CUSTOMIZE_COLUMNS'
        ],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'ACTIVE_CUSTOMERS_REPORT': [],
        'MANAGE_USERS': {
            'FINAL_DELETE': [],
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'REMOVE_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'VERIFY_CUSTOMER',
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS',
                'TRACKING_CUSTOMER_PASSWORD'
            ],
            'USER': [
                'ADD_USER',
                'EDIT_USER',
                'REMOVE_USER',
                'RESET_USER_PASSWORD',
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
                'CONFIRM_DRIVERS',
                'TRACKING_USER_PASSWORD'
            ]
        },
        'MANAGE_CONTAINERS': [],
        'MANAGE_BRANCHES': [
            'ADD',
            'EDIT',
            'DELETE'
        ],
        'FULFILMENT': [
            'SORT_ITEMS_INTO_BINS'
        ],
        'MANAGE_WAREHOUSES': [
            'ADD',
        ],
        'MANAGE_ORDERS': [],
        'WAREHOUSE_STOCKS_MANAGEMENT': [],
        'MANAGE_PRODUCTS': [],
        'MANAGE_ITEMS': [],
        'MANAGE_ITEMS_3RD_PARTY': [],
        'MANAGE_BINS': [],
        'MANAGE_SHIPPING_PLANS': [],
        'SHIPPING_RATES': [],
        'FULFILMENT_SHIPPING_RATES': [],
        'PRICING_SERVICE_TYPES': [],
        'DRIVERS_EARNINGS': [],
        'DRIVERS_EARNINGS_REPORT': [],
        'DRIVERS_EARNING': [],
        'PRICING_TYPE': [],
        'ADDITIONAL_FEES': [],
        'ADDITIONAL_FEES_INSURANCE': [],
        'ADDITIONAL_FEES_COD': [],
        'CUSTOMERS_PRICING': [],
        'DRIVERS_PRICING': [],
        'ADMINISTRATION': [],
        'ARCHIVED_SHIPMENTS': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'REMOVE_PACKAGE',
            'REMOVE_MULTIPLE_PACKAGES',
            'APPROVE_PACKAGES'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'MANAGE_COD_CUSTOMERS': [],
        'DAMAGED_PACKAGES': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'DRIVER_PACKAGES': [],
        'CONTAINER_PACKAGES': [],
        'SUMMARY_REPORTS': [],
        'ADMINISTRATIVE_REPORTS': [],
        'DETAILED_REPORTS': [],
        'DRIVER_ATTEMPTS_DETAILED_REPORTS': [],
        'TIME_BOUND_PACKAGES_DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'UNDELIVERED_PACKAGES_DETAILED_REPORTS': [],
        'SHIPPED_PACKAGES_DETAILED_REPORTS': [],
        'IDLE_CUSTOMERS_DETAILED_REPORTS': [],
        'DRIVER_PACKAGES_DETAILED_REPORT': [],
        'DELAYED_PACKAGES_REPORT': [],
        'COMPANY_SETTINGS': [],
        'COMPANY_CONFIGURATION': [],
        'MANAGE_PARCEL': [],
        'MANAGE_ZONES': [],
        'MANAGE_SERVICE_TYPES': [],
        'RECEIVE_COD': [],
        'DELAYED_COD_REPORTS': [],
        'SORT_COD': [],
        'EXPORTED_PACKAGES': [],
        'INVOICES': [],
        'MASS_INVOICES': [],
        'COD_PENDING_CUSTOMERS_APPROVAL': [],
        'DELIVERED_PACKAGES': [],
        'ARCHIVED_MASS_COD_REPORT': [],
        'ALL_COD_CUSTOMERS': [],
        'IN_CAR_PACKAGES': [],
        'COD_MISMATCH': [],
        'COD_MISMATCH_ALL_PACKAGES': [],
        'ACCOUNTING': [
            'MOB',
            'EXPORT_SELECTED',
            'EXPORT_COD_TO_EXCEL',
            'PRINT_DRIVER_REPORTS',
            'EXPORT_ALL',
            'CHANGE_STATUS_TO_EXPORTED',
            'SORT_COD',
            'SORT_ALL',
            'EXPORT_TO_EXCEL'
        ],
        'MANAGE_SHELVES': [],
        'SHIPPING_LINES': [],
        'BANK_TRANSFERS': [],
        'EXPENSES': [],
        'PARTNERS': [],
        'OUR_PARTNERS': [],
        'THIRD_PARTY': [],
        'PARTNERS_PACKAGES_REPORT': [],
        'ACTIVE_PARTNERS': [
            'CANCEL'
        ],
        'PENDING_PARTNERS': [],
        'PRINTED_REPORTS': [],
        'CUSTOMERS_WALLETS': [],
        'COMPANY_NOTIFICATIONS': [],
        'PENDING_APPROVAL': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'FINANCIAL_REPORT': [],
        'TICKETING_SYSTEM': [
            'SELECT_ANY_DEPARTMENT'
        ],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        // 'PAYMENTS': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [
            'SYSTEM_UPDATE_BADGE',
            'MARK_ALL_AS_SEEN'
        ],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'HUBS_ACCOUNTING_DASHBOARD': [],
        'TRIPS_BETWEEN_CITIES': [],
        'FULFILMENT_ACCOUNTING': [
            'FULFILMENT_SHIPPING_RATES',
            'ASN_FEES',
            'ORDERS_FEES',
            'INVOICES_FEES',
            'STORAGE_FEES'
        ],
        'FULFILMENT_RETURNED': [
            'REJECTED_ITEMS',
            'RETURNED_ITEMS',
            'DAMAGED_LOCATIONS',
        ],
        'REJECTED_ITEMS': [],
        'RETURNED_ITEMS': [],
        'DAMAGED_LOCATIONS': [],
        'ORDERS_FEES': [],
        'INVOICES_FEES': [],
        'ASN_FEES': [],
        'STORAGE_FEES': [],
    },
    ADMIN: {
        'DASHBOARD': [],
        'INBOX': [],
        'ADD_SHIPMENT': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'UPDATES_BANNER',
            'POSTPONED_PACKAGES_BANNER',
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'SWAP_SENDER_RECEIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'REMOVE_PACKAGE',
            'REMOVE_PACKAGES',
            'REMOVE_MULTIPLE_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'UNLISTED_PACKAGES',
            'APPROVE_PACKAGES',
            'EDIT_COST',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CANCEL',
            'RECEIVE_DRIVER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'RECEIVE_FROM_PARTNER',
            'CUSTOMIZE_COLUMNS'
        ],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'DRIVERS_EARNINGS': [],
        'DRIVERS_EARNINGS_REPORT': [],
        'DRIVERS_EARNING': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'ACTIVE_CUSTOMERS_REPORT': [],
        'MANAGE_USERS': {
            'FINAL_DELETE': [],
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'REMOVE_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'VERIFY_CUSTOMER',
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS',
                'TRACKING_CUSTOMER_PASSWORD'
            ],
            'USER': [
                'ADD_USER',
                'EDIT_USER',
                'REMOVE_USER',
                'RESET_USER_PASSWORD',
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
                'CONFIRM_DRIVERS',
                'TRACKING_USER_PASSWORD'
            ]
        },
        'MANAGE_CONTAINERS': [],
        'MANAGE_BRANCHES': [
            'ADD',
            'EDIT',
            'DELETE'
        ],
        'FULFILMENT': [
            'SORT_ITEMS_INTO_BINS'
        ],
        'MANAGE_WAREHOUSES': [
            'ADD',
        ],
        'MANAGE_ORDERS': [],
        'FULFILMENT_ACCOUNTING': [
            'FULFILMENT_SHIPPING_RATES',
            'ASN_FEES',
            'ORDERS_FEES',
            'INVOICES_FEES',
            'STORAGE_FEES'
        ],
        'FULFILMENT_RETURNED': [
            'REJECTED_ITEMS',
            'RETURNED_ITEMS',
            'DAMAGED_LOCATIONS',
        ],
        'REJECTED_ITEMS': [],
        'RETURNED_ITEMS': [],
        'DAMAGED_LOCATIONS': [],
        'ORDERS_FEES': [],
        'INVOICES_FEES': [],
        'ASN_FEES': [],
        'STORAGE_FEES': [],
        'WAREHOUSE_STOCKS_MANAGEMENT': [],
        'MANAGE_BINS': [],
        'MANAGE_PRODUCTS': [],
        'MANAGE_ITEMS': [],
        'MANAGE_ITEMS_3RD_PARTY': [],
        'MANAGE_SHIPPING_PLANS': [],
        'SHIPPING_RATES': [],
        'FULFILMENT_SHIPPING_RATES': [],
        'PRICING_SERVICE_TYPES': [],
        'PRICING_TYPE': [],
        'ADDITIONAL_FEES': [],
        'ADDITIONAL_FEES_INSURANCE': [],
        'ADDITIONAL_FEES_COD': [],
        'CUSTOMERS_PRICING': [],
        'DRIVERS_PRICING': [],
        'ADMINISTRATION': [],
        'ARCHIVED_SHIPMENTS': [],
        'RECEIVE_COD': [],
        'DELAYED_COD_REPORTS': [],
        'SORT_COD': [],
        'EXPORTED_PACKAGES': [],
        'INVOICES': [],
        'MASS_INVOICES': [],
        'COD_PENDING_CUSTOMERS_APPROVAL': [],
        'DELIVERED_PACKAGES': [],
        'ARCHIVED_MASS_COD_REPORT': [],
        'ALL_COD_CUSTOMERS': [],
        'IN_CAR_PACKAGES': [],
        'ACCOUNT_SETTINGS': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'MANAGE_COD_CUSTOMERS': [],
        'DAMAGED_PACKAGES': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'DRIVER_PACKAGES': [],
        'CONTAINER_PACKAGES': [],
        'SUMMARY_REPORTS': [],
        'ADMINISTRATIVE_REPORTS': [],
        'DETAILED_REPORTS': [],
        'DRIVER_ATTEMPTS_DETAILED_REPORTS': [],
        'TIME_BOUND_PACKAGES_DETAILED_REPORTS': [],
        'UNDELIVERED_PACKAGES_DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'SHIPPED_PACKAGES_DETAILED_REPORTS': [],
        'IDLE_CUSTOMERS_DETAILED_REPORTS': [],
        'DRIVER_PACKAGES_DETAILED_REPORT': [],
        'DELAYED_PACKAGES_REPORT': [],
        'COMPANY_SETTINGS': [],
        'COMPANY_CONFIGURATION': [],
        'MANAGE_PARCEL': [],
        'MANAGE_ZONES': [],
        'MANAGE_SERVICE_TYPES': [],
        'COD_MISMATCH': [],
        'COD_MISMATCH_ALL_PACKAGES': [],
        'ACCOUNTING': [
            'MOB',
            'EXPORT_SELECTED',
            'EXPORT_COD_TO_EXCEL',
            'PRINT_DRIVER_REPORTS',
            'FIND_PACKAGE_BY_BARCODE',
            'EXPORT_ALL',
            'CHANGE_STATUS_TO_EXPORTED',
            'RECEIVED_MONEY_AMOUNT',
            'TOTAL_MOB_MONEY',
            'SORT_COD',
            'SORT_ALL',
            'EXPORT_TO_EXCEL'
        ],
        'SHIPPING_LINES': [],
        'MANAGE_SHELVES': [],
        'BANK_TRANSFERS': [],
        'EXPENSES': [],
        'PARTNERS': [],
        'OUR_PARTNERS': [],
        'THIRD_PARTY': [],
        'PARTNERS_PACKAGES_REPORT': [],
        'ACTIVE_PARTNERS': [
            'CANCEL'
        ],
        'PENDING_PARTNERS': [],
        'PRINTED_REPORTS': [],
        'CUSTOMERS_WALLETS': [],
        'COMPANY_NOTIFICATIONS': [],
        'PENDING_APPROVAL': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'DELIVER_TO_SENDER': [],
        'FINANCIAL_REPORT': [],
        'TICKETING_SYSTEM': [
           'SELECT_ANY_DEPARTMENT'
        ],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        // 'PAYMENTS': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [
            'SYSTEM_UPDATE_BADGE',
            'MARK_ALL_AS_SEEN'
        ],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'HUBS_ACCOUNTING_DASHBOARD': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'TRIPS_BETWEEN_CITIES': []
    },
    DISPATCHER: {
        'CURRENT_REPORTS': [],
        'ACCOUNT': [],
        'DASHBOARD': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_CONTAINERS': [],
        'RECEIVE_COD': [],
        'ADMINISTRATION': [],
        'ACCOUNTING': ['RECEIVE_COD'],
        'MANAGE_SHIPMENTS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'ASSIGN_TO_CONTAINER',
            'RECEIVE_FROM_DRIVER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'CONDITIONAL_COST',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'RECEIVE_DRIVER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'RECEIVE_FROM_PARTNER'
        ],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'DRIVER_PACKAGES': [],
        'CONTAINER_PACKAGES': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS'
            ],
            'USER': [
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
            ],
        },
        'PENDING_APPROVAL': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    SALES: {
        'ACCOUNT_SETTINGS': [],
        'DASHBOARD': [],
        'INBOX': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS',
                'EDIT_CUSTOMER:name,password,phone,addressLine1,city,country,role,hubId'
            ]
        },
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    CUSTOMER_CARE: {
        'ACCOUNT_SETTINGS': [],
        'DASHBOARD': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'SHOW_CUSTOMERS',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_ARCHIVED_CUSTOMERS',
                'EDIT_CUSTOMER:name,password,phone,addressLine1,city,country,role,hubId'
            ],
            'DRIVERS': []
        },
        'ADD_SHIPMENT': [],
        'MANAGE_SHIPMENTS': [
            'POSTPONED_PACKAGES_BANNER',
            'SWAP_SENDER_RECEIVER',
            'UNLISTED_PACKAGES',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'APPROVE_PACKAGES',
            'APPROVE_COD_CHANGE',
            'CHANGE_STATUS',
            'CHANGE_PACKAGE_STATUS',
            'RECEIVE_FROM_DRIVER',
            'COD_REQUEST',
            'APPROVE_PACKAGES',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CONDITIONAL_COST',
            'RECEIVE_DRIVER',
            'RECEIVE_FROM_PARTNER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'PRINT_DRIVER_PACKAGES'
        ],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'EDIT_PACKAGE',
            'APPROVE_PACKAGES',
            'APPROVE_COD_CHANGE',
            'CHANGE_STATUS',
            'CHANGE_PACKAGE_STATUS',
            'COD_REQUEST',
            'APPROVE_PACKAGES'
        ],
        'ACCOUNTING': [
            'SORT_ALL',
            'EXPORT_TO_EXCEL'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'MANAGE_COD_CUSTOMERS': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'PARTNERS': [],
        'OUR_PARTNERS': [],
        'PARTNERS_PACKAGES_REPORT': [],
        'ACTIVE_PARTNERS': [],
        'PENDING_APPROVAL': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'DELIVER_TO_SENDER': []
    },
    HUB_CUSTOMER_CARE: {
        'ACCOUNT_SETTINGS': [],
        'DASHBOARD': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'SHOW_CUSTOMERS',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_ARCHIVED_CUSTOMERS',
                'EDIT_CUSTOMER:name,password,phone,addressLine1,city,country,role,hubId'
            ]
        },
        'ADD_SHIPMENT': [],
        'MANAGE_SHIPMENTS': [
            'UNLISTED_PACKAGES',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'APPROVE_PACKAGES',
            'APPROVE_COD_CHANGE',
            'CHANGE_STATUS',
            'CHANGE_PACKAGE_STATUS',
            'COD_REQUEST',
            'APPROVE_PACKAGES',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CONDITIONAL_COST',
            'RECEIVE_DRIVER',
            'RECEIVE_FROM_PARTNER',
            'PRINT_DRIVER_PACKAGES',
            'EXPORT_PACKAGE_TO_HUB'
        ],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'EDIT_PACKAGE',
            'APPROVE_PACKAGES',
            'APPROVE_COD_CHANGE',
            'CHANGE_STATUS',
            'CHANGE_PACKAGE_STATUS',
            'COD_REQUEST',
            'APPROVE_PACKAGES'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'MANAGE_COD_CUSTOMERS': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'PARTNERS': [],
        'OUR_PARTNERS': [],
        'PARTNERS_PACKAGES_REPORT': [],
        'ACTIVE_PARTNERS': [],
        'PENDING_APPROVAL': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'COD_MISMATCH_ALL_PACKAGES': [],
        'COD_MISMATCH': [
            'VIEW_HUB_COST'
        ],
    },
    ACCOUNTANT: {
        'ADD_SHIPMENT': [],
        'ACCOUNT_SETTINGS': [],
        'REPORTS': [],
        'ADMINISTRATION': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'VERIFY_CUSTOMER',
                'SHOW_CUSTOMERS',
            ]
        },
        'MANAGE_COD_CUSTOMERS': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'CUSTOMERS_WALLETS': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'PRINTED_REPORTS': [],
        'PRINTED_REPORTS_NEW': [],
        'RECEIVE_COD': [],
        'DELAYED_COD_REPORTS': [],
        'SORT_COD': [],
        'EXPORTED_PACKAGES': [],
        'INVOICES': [],
        'MASS_INVOICES': [],
        'COD_PENDING_CUSTOMERS_APPROVAL': [],
        'DRIVERS_EARNINGS': [],
        'DRIVERS_EARNINGS_REPORT': [],
        'DELIVERED_PACKAGES': [],
        'ARCHIVED_MASS_COD_REPORT': [],
        'ALL_COD_CUSTOMERS': [],
        'IN_CAR_PACKAGES': [],
        'COD_MISMATCH': [],
        'COD_MISMATCH_ALL_PACKAGES': [],
        'ACCOUNTING': [
            'MOB',
            'EXPORT_SELECTED',
            'EXPORT_COD_TO_EXCEL',
            'PRINT_DRIVER_REPORTS',
            'FIND_PACKAGE_BY_BARCODE',
            'EXPORT_ALL',
            'CHANGE_STATUS_TO_EXPORTED',
            'RECEIVED_MONEY_AMOUNT',
            'TOTAL_MOB_MONEY',
            'SORT_COD',
            'SORT_ALL',
            'EXPORT_TO_EXCEL'
        ],
        'EXPENSES': [],
        'SHIPPING_RATES': [],
        'FULFILMENT_SHIPPING_RATES': [],
        'CUSTOMERS_PRICING': [],
        'DRIVERS_PRICING': [],
        'DETAILED_REPORTS': [],
        'DRIVER_ATTEMPTS_DETAILED_REPORTS': [],
        'TIME_BOUND_PACKAGES_DETAILED_REPORTS': [],
        'UNDELIVERED_PACKAGES_DETAILED_REPORTS': [],
        'SHIPPED_PACKAGES_DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'IDLE_CUSTOMERS_DETAILED_REPORTS': [],
        'DRIVER_PACKAGES_DETAILED_REPORT': [],
        'DELAYED_PACKAGES_REPORT': [],
        'FINANCIAL_REPORT': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': []
    },
    OPERATION_MANAGER: {
        'DASHBOARD': [],
        'INBOX': [],
        'ADD_SHIPMENT': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'UNLISTED_PACKAGES',
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CANCEL',
            'CONDITIONAL_COST',
            'RECEIVE_DRIVER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'RECEIVE_FROM_PARTNER',
            'CUSTOMIZE_COLUMNS'
        ],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER:name,password,phone,addressLine1,city,country,role,hubId,pricingListId,combined',
                'SHOW_CUSTOMERS',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_ARCHIVED_CUSTOMERS'
            ],
            'USER': [
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'REMOVE_USER',
                'RESET_USER_PASSWORD',
                'ADD_USER',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
                'CONFIRM_DRIVERS'
            ]
        },
        'MANAGE_CONTAINERS': [],
        'MANAGE_BRANCHES': [
            'ADD',
            'EDIT'
        ],
        'ADMINISTRATION': [],
        'ARCHIVED_SHIPMENTS': [],
        'RECEIVE_COD': [],
        'DELAYED_COD_REPORTS': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'MANAGE_COD_CUSTOMERS': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'DRIVER_PACKAGES': [],
        'CONTAINER_PACKAGES': [],
        'SUMMARY_REPORTS': [],
        'ADMINISTRATIVE_REPORTS': [],
        'DETAILED_REPORTS': [],
        'DRIVER_ATTEMPTS_DETAILED_REPORTS': [],
        'TIME_BOUND_PACKAGES_DETAILED_REPORTS': [],
        'UNDELIVERED_PACKAGES_DETAILED_REPORTS': [],
        'SHIPPED_PACKAGES_DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'IDLE_CUSTOMERS_DETAILED_REPORTS': [],
        'DRIVER_PACKAGES_DETAILED_REPORT': [],
        'DELAYED_PACKAGES_REPORT': [],
        'MANAGE_PARCEL': [],
        'MANAGE_ZONES': [],
        'SHIPPING_LINES': [],
        'MANAGE_SHELVES': [],
        'PARTNERS': [],
        'OUR_PARTNERS': [],
        'THIRD_PARTY': [],
        'PARTNERS_PACKAGES_REPORT': [],
        'ACTIVE_PARTNERS': [],
        'ACCOUNTING': [
            'RECEIVE_COD',
            'EXPORT_TO_EXCEL'
        ],
        'PENDING_APPROVAL': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [
            'SYSTEM_UPDATE_BADGE',
            'MARK_ALL_AS_SEEN'
        ],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': []
    },
    HUB_OPERATION_MANAGER: {
        'DASHBOARD': [],
        'INBOX': [],
        'ADD_SHIPMENT': [],
        'CHANGE_STATUS': [],
        'DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'MANAGE_SHIPMENTS': [
            'UNLISTED_PACKAGES',
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CANCEL',
            'CONDITIONAL_COST',
            'RECEIVE_DRIVER',
            'RECEIVE_FROM_PARTNER',
            'EXPORT_PACKAGE_TO_HUB'
        ],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER:name,password,phone,addressLine1,city,country,role,hubId',
                'SHOW_CUSTOMERS',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_ARCHIVED_CUSTOMERS',
                'VERIFY_CUSTOMER'
            ],
            'USER': [
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'ADD_USER',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
            ]
        },
        'MANAGE_CONTAINERS': [],
        'MANAGE_BRANCHES': [
            'ADD',
            'EDIT'],
        'ADMINISTRATION': [],
        'ARCHIVED_SHIPMENTS': [],
        'DELAYED_COD_REPORTS': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES'
        ],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'MANAGE_COD_CUSTOMERS': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'DRIVER_PACKAGES': [],
        'CONTAINER_PACKAGES': [],
        'SUMMARY_REPORTS': [],
        'ADMINISTRATIVE_REPORTS': [],
        'DRIVER_PACKAGES_DETAILED_REPORT': [],
        'DELAYED_PACKAGES_REPORT': [],
        'MANAGE_PARCEL': [],
        'MANAGE_ZONES': [],
        'SHIPPING_LINES': [],
        'MANAGE_SHELVES': [],
        'PARTNERS': [],
        'OUR_PARTNERS': [],
        'PARTNERS_PACKAGES_REPORT': [],
        'ACTIVE_PARTNERS': [],
        'ACCOUNTING': [
            'RECEIVE_COD',
            'SORT_ALL',
            'EXPORT_TO_EXCEL'],
        'PENDING_APPROVAL': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'ACCOUNT_SETTINGS': [],
        'COD_MISMATCH': [
            'VIEW_HUB_COST'
        ],
        'COD_MISMATCH_ALL_PACKAGES': [],
    },
    HUB_MANAGER: {
        'ACCOUNT_SETTINGS': [],
        'REPORTS': [],
        'HUB_CUSTODY': [],
        'HUB_CUSTODY_RECEIVE': [],
        'ADMINISTRATION': [],
        'DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'DASHBOARD': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'MANAGE_CONTAINERS': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'CHANGE_PACKAGE_STATUS',
            'POSTPONED_PACKAGES_BANNER',
            'COD_REQUEST',
            'UNLOAD_PACKAGES',
            'BULK_UPDATE',
            'EXPORT_PACKAGES',
            'TRANSFER',
            'CANCEL',
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_DRIVER',
            'RECEIVE_FROM_PARTNER',
            'RECEIVE_FROM_DRIVER',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'EDIT_COST'
        ],
        'PENDING_APPROVAL': [],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'DRIVERS_REPORT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'CONTAINER_PACKAGES': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS'
            ],
            'USER': [
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
                'ADD_DRIVER'
            ]
        },
        'ACCOUNTING': [
            'SORT_ALL',
            'EXPORT_TO_EXCEL'],
        'MANAGE_BRANCHES': [],
        'UNLOAD_PACKAGES': [],
        'DRIVER_PACKAGES': [],
        'MANAGE_SHELVES': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    MULTIPLE_HUBS_MANAGER: {
        'ACCOUNT_SETTINGS': [],
        'REPORTS': [],
        'HUB_CUSTODY': [],
        'HUB_CUSTODY_RECEIVE': [],
        'ADMINISTRATION': [],
        'DASHBOARD': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES': [],
        'DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'RETURNED_BUNDLES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'MANAGE_CONTAINERS': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'CHANGE_PACKAGE_STATUS',
            'POSTPONED_PACKAGES_BANNER',
            'COD_REQUEST',
            'UNLOAD_PACKAGES',
            'BULK_UPDATE',
            'EXPORT_PACKAGES',
            'TRANSFER',
            'CANCEL',
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_DRIVER',
            'RECEIVE_FROM_PARTNER',
            'RECEIVE_FROM_DRIVER',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'EDIT_COST'
        ],
        'PENDING_APPROVAL': [],
        'PRINTED_REPORTS_NEW': [],
        'DRAFT_PICKUPS': [],
        'CONTAINERS_MANAGEMENT': [],
        'DRIVERS_REPORT': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'CONTAINER_PACKAGES': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS'
            ],
            'USER': [
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
                'ADD_DRIVER'
            ]
        },
        'ACCOUNTING': [
            'SORT_ALL',
            'EXPORT_TO_EXCEL'],
        'MANAGE_BRANCHES': [],
        'UNLOAD_PACKAGES': [],
        'DRIVER_PACKAGES': [],
        'MANAGE_SHELVES': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    HUB_ADMIN: {
        'ADD_SHIPMENT': [],
        'DRAFT_PICKUPS': [],
        'HUB_CUSTODY': [],
        'HUB_CUSTODY_RECEIVE': [],
        'CONTAINERS_MANAGEMENT': [],
        'DRIVERS_REPORT': [],
        'HUBS_REPORTS': [],
        'DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'CURRENT_REPORTS': [],
        'CONTAINER_PACKAGES': [],
        'ADMINISTRATION': [],
        'CUSTOMERS_PRICING': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS',
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'REMOVE_CUSTOMER',
                'VERIFY_CUSTOMER'
            ],
            'USER': [
                'ADD_USER',
                'EDIT_USER',
                'REMOVE_USER',
                'RESET_USER_PASSWORD',
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
            ]
        },
        'ACCOUNTING': [
            'SORT_ALL',
            'SORT_COD',
            'EXPORT_TO_EXCEL'],
        'MANAGE_BRANCHES': [
            'ADD',
            'EDIT',
            'DELETE'
        ],
        'UNLOAD_PACKAGES': [],
        'DRIVER_PACKAGES': [],
        'MANAGE_SHELVES': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
        'REPORTS': [],
        'DASHBOARD': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'MANAGE_CONTAINERS': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'POSTPONED_PACKAGES_BANNER',
            'CHANGE_PACKAGE_STATUS',
            'IMPORT_PACKAGES',
            'COD_REQUEST',
            'UNLOAD_PACKAGES',
            'BULK_UPDATE',
            'EXPORT_PACKAGES',
            'TRANSFER',
            'CANCEL',
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'RECEIVE_DRIVER',
            'RECEIVE_FROM_PARTNER',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'EDIT_COST',
            'EXPORT_PACKAGE_TO_HUB',
            'APPROVE_PACKAGES'
        ],
        'PRINTED_REPORTS_NEW': [],
        'EXPORT_RECEIVE_COD': [],
        'HUBS_ACCOUNTING_DASHBOARD': [],
        'SHIPPING_RATES': [],
        'FULFILMENT_SHIPPING_RATES': [],
        'PRICING_TYPE': [],
        'ADDITIONAL_FEES': [],
        'ADDITIONAL_FEES_INSURANCE': [],
        'ADDITIONAL_FEES_COD': [],
        'HUBS_MASS_COD_REPORTS': [],
        'RECEIVE_COD': [],
        'DELAYED_COD_REPORTS': [],
        'IN_CAR_PACKAGES': [],
        'EXPORTED_PACKAGES': [],
        'COD_MISMATCH': [
            'VIEW_HUB_COST'
        ],
        'COD_MISMATCH_ALL_PACKAGES': [],
        'SORT_COD': [],
        'EXPENSES': [],
        'DELIVERED_PACKAGES': [],
        'ARCHIVED_MASS_COD_REPORT': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'ACCOUNT_SETTINGS': []
    },
    ACCOUNTING_MANAGER: {
        'ADD_SHIPMENT': [],
        'DASHBOARD': [],
        'INBOX': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES',
            'EDIT_COST',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CANCEL',
            'RECEIVE_DRIVER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'RECEIVE_FROM_PARTNER'
        ],
        'PRINTED_REPORTS_NEW': [],
        'MANAGE_SHIPMENTS_STATUS': [
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'REMOVE_PACKAGE',
            'APPROVE_PACKAGES'
        ],
        'ACCOUNT_SETTINGS': [],
        'CONTAINERS_MANAGEMENT': [],
        'REPORTS': [],
        'ADMINISTRATION': [],
        'ARCHIVED_SHIPMENTS': [],
        'HUBS_REPORTS': [],
        'PARTNERS_REPORTS': [],
        'CURRENT_REPORTS': [],
        'DRIVERS_REPORT': [],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'SHOW_CUSTOMERS',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_ARCHIVED_CUSTOMERS'
            ],
            'USER': [
                'ADD_USER',
                'EDIT_USER',
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
            ]
        },
        'MANAGE_CONTAINERS': [],
        'CUSTOMERS_WALLETS': [],
        'MANAGE_COD_CUSTOMERS': [],
        'PACKAGES_CONTENTS_REPORT': [],
        'RECEIVED_COD_WITHOUT_CUSTODY_REPORT': [],
        'RECEIVE_COD': [],
        'DELAYED_COD_REPORTS': [],
        'SORT_COD': [],
        'EXPORTED_PACKAGES': [],
        'INVOICES': [],
        'MASS_INVOICES': [],
        'COD_PENDING_CUSTOMERS_APPROVAL': [],
        'DELIVERED_PACKAGES': [],
        'ARCHIVED_MASS_COD_REPORT': [],
        'ALL_COD_CUSTOMERS': [],
        'IN_CAR_PACKAGES': [],
        'DRIVERS_EARNINGS': [],
        'DRIVERS_EARNINGS_REPORT': [],
        'COD_MISMATCH': [],
        'COD_MISMATCH_ALL_PACKAGES': [],
        'ACCOUNTING': [
            'EXPORT_SELECTED',
            'PRINT_DRIVER_REPORTS',
            'EXPORT_ALL',
            'CHANGE_STATUS_TO_EXPORTED',
            'SORT_COD',
            'SORT_ALL',
            'EXPORT_COD_TO_EXCEL',
            'EXPORT_TO_EXCEL'
        ],
        'EXPENSES': [],
        'SHIPPING_RATES': [],
        'FULFILMENT_SHIPPING_RATES': [],
        'CUSTOMERS_PRICING': [],
        'DRIVERS_PRICING': [],
        'DETAILED_REPORTS': [],
        'DRIVER_ATTEMPTS_DETAILED_REPORTS': [],
        'TIME_BOUND_PACKAGES_DETAILED_REPORTS': [],
        'UNDELIVERED_PACKAGES_DETAILED_REPORTS': [],
        'SHIPPED_PACKAGES_DETAILED_REPORTS': [],
        'CUSTOMER_PACKAGES_DETAILED_REPORTS': [],
        'IDLE_CUSTOMERS_DETAILED_REPORTS': [],
        'DRIVER_PACKAGES_DETAILED_REPORT': [],
        'DELAYED_PACKAGES_REPORT': [],
        'PRINTED_REPORTS': [],
        'FINANCIAL_REPORT': [],
        'KNOWLEDGE_CENTER': [],
        'SYSTEM_UPDATES': [],
        'DOCUMENTS_SYSTEM_UPDATES': [],
        'VIDEOS_SYSTEM_UPDATES': [],
        'FAQ': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': []
    },
    CLERK: {
        'ADD_SHIPMENT': [],
        'CHANGE_STATUS': [],
        'MENU_RETURNED_PACKAGES': [],
        'RETURNED_PACKAGES_IN_CAR': [],
        'RETURNED_PACKAGES': [],
        'RETURNED_BUNDLES': [],
        'BRINGS_PACKAGES': [],
        'PARTIALLY_RETURNED_PACKAGES': [],
        'SWAPPED_PACKAGES': [],
        'ALL_RETURNED_PACKAGES': [],
        'DELIVER_TO_SENDER': [],
        'UNLOAD_RETURNED_PACKAGES': [],
        'RETURN_PACKAGES': [],
        'MANAGE_SHIPMENTS': [
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'IMPORT_PACKAGES',
            'ADD_BUNDLE',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'SWAP_SENDER_RECEIVER'
        ],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_CUSTOMERS',
            ]
        },
        'ACCOUNT_SETTINGS': [],
        'CONTAINERS_MANAGEMENT': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    HUB_CLERK: {
        'ADD_SHIPMENT': [],
        'CHANGE_STATUS': [],
        'MANAGE_SHIPMENTS': [
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'IMPORT_PACKAGES',
            'ADD_BUNDLE',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'SWAP_SENDER_RECEIVER'
        ],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'SHOW_CUSTOMERS',
            ]
        },
        'ACCOUNT_SETTINGS': [],
        'CONTAINERS_MANAGEMENT': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    TICKETING_USER: {
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'ACCOUNT_SETTINGS': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    TICKETING_SYSTEM_ADMIN: {
        'TICKETING_SYSTEM': [
            'SELECT_ANY_DEPARTMENT'
        ],
        'TECHNICAL_SUPPORT_TICKETS': [
            'ADD_USER'
        ],
        'MANAGE_CATEGORIES': []
    },
    CUSTOMER_ACCOUNT_MANAGER: {
        'ADD_SHIPMENT': [],
        'CONTAINERS_MANAGEMENT': [],
        'MANAGE_SHIPMENTS': [
            'CHANGE_PACKAGE_STATUS',
            'PRINT_DRIVER_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'EDIT_COST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'IMPORT_PACKAGES'
        ],
        'MANAGE_USERS': {
            'CUSTOMER': [
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS'
            ]
        },
        'ACCOUNT_SETTINGS': [],
        'TICKETING_SYSTEM': [],
        'TECHNICAL_SUPPORT_TICKETS': [],
        'MANAGE_CATEGORIES': [],
        'KNOWLEDGE_CENTER': [],
        'FAQ': [],
    },
    FULFILLMENT_USER: {
        'MANAGE_SHIPMENTS': [
            'UPDATES_BANNER',
            'POSTPONED_PACKAGES_BANNER',
            'CHANGE_PACKAGE_STATUS',
            'ASSIGN_TO_DRIVER',
            'PRINT_DRIVER_PACKAGES',
            'SWAP_SENDER_RECEIVER',
            'ASSIGN_TO_CONTAINER',
            'COD_REQUEST',
            'EDIT_PACKAGE',
            'ADD_BUNDLE',
            'REMOVE_PACKAGE',
            'REMOVE_PACKAGES',
            'REMOVE_MULTIPLE_PACKAGES',
            'RECEIVE_FROM_DRIVER',
            'UNLISTED_PACKAGES',
            'APPROVE_PACKAGES',
            'EDIT_COST',
            'IMPORT_PACKAGES',
            'EXPORT_PACKAGES',
            'BULK_UPDATE',
            'TRANSFER',
            'CANCEL',
            'RECEIVE_DRIVER',
            'SHOW_PENDING_APPROVAL_REQUESTS',
            'RECEIVE_FROM_PARTNER',
            'CUSTOMIZE_COLUMNS'
        ],
        'ACCOUNT_SETTINGS': [],
        'MANAGE_USERS': {
            'FINAL_DELETE': [],
            'CUSTOMER': [
                'ADD_CUSTOMER',
                'EDIT_CUSTOMER',
                'REMOVE_CUSTOMER',
                'RESET_CUSTOMER_PASSWORD',
                'VERIFY_CUSTOMER',
                'SHOW_CUSTOMERS',
                'SHOW_ARCHIVED_CUSTOMERS',
                'TRACKING_CUSTOMER_PASSWORD'
            ],
            'USER': [
                'ADD_USER',
                'EDIT_USER',
                'REMOVE_USER',
                'RESET_USER_PASSWORD',
                'VIEW_ONBOARD',
                'SHOW_USERS',
                'SHOW_ARCHIVED_USERS',
                'SHOW_DRIVERS',
                'SHOW_ARCHIVED_DRIVERS',
                'CONFIRM_DRIVERS',
                'TRACKING_USER_PASSWORD'
            ]
        },
        'FULFILMENT': [
            'SORT_ITEMS_INTO_BINS'
        ],
        'MANAGE_WAREHOUSES': [
            'ADD',
        ],
        'MANAGE_ORDERS': [],
        'WAREHOUSE_STOCKS_MANAGEMENT': [],
        'MANAGE_PRODUCTS': [],
        'MANAGE_ITEMS': [],
        'MANAGE_ITEMS_3RD_PARTY': [],
        'MANAGE_BINS': [],
        'MANAGE_SHIPPING_PLANS': [],
        'SHIPPING_RATES': [],
        'FULFILMENT_SHIPPING_RATES': [],
        'FULFILMENT_ACCOUNTING': [
            'FULFILMENT_SHIPPING_RATES',
            'ASN_FEES',
            'ORDERS_FEES',
            'INVOICES_FEES',
            'STORAGE_FEES'
        ],
        'FULFILMENT_RETURNED': [
            'REJECTED_ITEMS',
            'RETURNED_ITEMS',
            'DAMAGED_LOCATIONS',
        ],
        'REJECTED_ITEMS': [],
        'RETURNED_ITEMS': [],
        'DAMAGED_LOCATIONS': [],
        'ORDERS_FEES': [],
        'INVOICES_FEES': [],
        'ASN_FEES': [],
        'STORAGE_FEES': [],
    }
};


/**
 *
 * PERMISSIONS:
 *
 * 'USER_ROLE' : {
 *   ... SIDE_TABS
 * }
 *
 * Each User Role above contains the list of side menu items which he can see.
 * inside each side tab the list of functionality inside the tab the user can do.
 *
 *
 * DISPATCHER: {
 *    MANAGE_SHIPMENTS: [
 *       ASSIGN_TO_DRIVER,
 *       ASSIGN_TO_CONTAINER,
 *       EDIT_PACKAGE:...FIELDS CAN BE EDITED
 *    ]
 * }
 *
 *
 * in dispatcher role: the user can see Manage shipments tab only
 * inside Manage shipments tab, user can assign to driver and container,
 * in addition to edit package sender address, country and city.
 *
 *
 * SALES: {
 *    MANAGE_USERS: [
 *       CUSTOMER: [
 *         ADD_CUSTOMER,
 *         EDIT_CUSTOMER:ALL~EMAIL
 *       ]
 *    ]
 * }
 *
 *
 * SALES user can see manage users menu item,
 * inside the tab, he can see only customers tab
 * and do only: ADD_CUSTOMER, and EDIT_CUSTOMER (all feilds but email) functionality.
 *
 *
 *
 *
 *
 *
 *
 */

