import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ContainerModel} from '../../../shared/models/container.model';
import {DriverModel} from '../../../shared/models/driver.model';
import {ContainersService} from '../../services/containers.service';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-container-details.component',
    templateUrl: 'container-details.component.html',
    styleUrls: ['container-details.component.scss']
})
export class ContainerDetailsComponent implements OnInit {
    public currentLang = '';
    public getPackagesCountLoading = false;
    public deleteContainerLoading = false;
    @Input() isShow: boolean;
    @Input() container: ContainerModel;
    @Input() printLoader = false;

    @Output() onCloseContainerDetails = new EventEmitter();
    @Output() onModifyContainer: EventEmitter<{ container: ContainerModel }> =
        new EventEmitter<{ container: ContainerModel }>();
    @Output() printContainer: EventEmitter<{ container: ContainerModel }> =
        new EventEmitter<{ container: ContainerModel }>();
    @Output() removeContainer: EventEmitter<{ container: ContainerModel }> =
        new EventEmitter<{ container: ContainerModel }>();
    constructor(private containerService: ContainersService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService) {}
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }

    public getDriverName(driverObject: DriverModel) {
        return this.containerService.getDriverName(driverObject);
    }
    public onClose() {
        this.onCloseContainerDetails.emit(false);
    }
    public getPackagesCount(container) {
        // const query = this.createQuery();
        this.getPackagesCountLoading = true;
        this.containerService.getContainerPackagesNo(container.id).subscribe(
            (response: any) => {
                this.translateService.get(['MANAGE_CONTAINERS.NUMBER_OF_SHIPMENTS']
                ).subscribe((res: string) => {
                    this.confirmationService.confirm({
                        message: res['MANAGE_CONTAINERS.NUMBER_OF_SHIPMENTS'] + ' :' +  response.packagesNumber ,
                        rejectVisible: false,
                        acceptLabel: 'OK'
                    });
                    return;
                });
                this.getPackagesCountLoading = false;
            } , error => {
                this.getPackagesCountLoading = false;
                console.error(error);
            }
        );
    }
    public onRemoveContainer() {
        this.removeContainer.emit({container: this.container});
    }

    public onModify() {
        this.onClose();
        this.onModifyContainer.emit({container: this.container});
    }
    public onPrint() {
        this.printContainer.emit({container: this.container});
    }

    showLicenseExpired() {
        return new Date(this.container.licenseExpiryDate).getTime() < new Date().getTime();
    }

    showInsuranceExpired() {
        return new Date(this.container.insuranceExpiryDate).getTime() < new Date().getTime();
    }
}
