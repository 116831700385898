<ng-template #changePackageStatusDialog>
    <div>
        <app-change-package-status-component
                [isPrintReportAfterChangeStatus]="true"
                [shipments]="objectValues(scannedPackages)"
                [isShowCurrentStatus]="false"
                (onFinish)="closeChangePackageStatusPopOver(changePackageStatusPopOver, $event)"
                (onCancel)="closeChangePackageStatusPopOver(changePackageStatusPopOver, $event)"
        >
        </app-change-package-status-component>
    </div>
</ng-template>

<div class="close-icon" (click)="finish()"><img src="../../../assets/icons/close-icon.svg" alt=""/></div>
<!--<div [ngClass]="{'page-ltr': currentLang === 'en'}"-->
<!--     appBarcodeScanner (barcodeRead)="barcodeReader($event)">-->
<div [ngClass]="{'page-ltr': currentLang === 'en'}" appBarcodeScanner (barcodeRead)="barcodeReader($event)">
    <div class="row d-inline-flex main-container" [ngClass]="{'read-by-barcode-main-container': isReadByBarcode}">
        <div id="submitting-container" *ngIf="isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div *ngIf="isReceiverFromDriver || isReceiveReturnedPackages || isReceiveFromPartner" class="drivers-hubs-menu"
             [ngClass]="{'right-border': currentLang === 'en'}">
<!--            <ng-container *ngIf="!viewSingleCustomer">-->
            <div class="receive-driver-label row" [ngClass]="{'receive-driver-label-en' : currentLang === 'en'}">
                <label *ngIf="isReceiverFromDriver">{{'ADMINISTRATION.CATEGORY.CUSTOMERS' | translate | packageTitle}}
                    <span class="number-of-customers badge-danger" *ngIf="!viewSingleCustomer">{{numberOfCustomers}}</span></label>
            </div>
            <div class="drivers-container">
                <div class="search-drivers row">
                    <div class="search-input-value">
                        <input [disabled]="viewSingleCustomer" appInputDebounced (inputDebounced)="onSearchCustomer({query: driverSearch})"
                               [(ngModel)]="driverSearch"
                               class="search" placeholder="{{'GENERAL.SEARCH' | translate}}..." type="text"/>
                    </div>
                </div>
                <div class="drivers" [ngClass]="{'drivers-en': currentLang === 'en'}" (scroll)="scroll($event)">
                    <div *ngFor="let driver of storesList" class="driver row cursor-pointer"
                         (click)="onSelectCustomer(driver.id)"
                         [ngClass]="{'selected-driver': customerId === driver.id}">
                        <div class="driver-image margin-left-12" [ngClass]="{'margin-right-12': currentLang === 'en'}">
                            <img style="width: 100%;" class="driver-icon" src="../../../assets/icons/taxi-driver.png"
                                 alt="driver-Image"/>
                        </div>
                        <div class="driver-name"
                             [ngClass]="{'driver-name-en': currentLang === 'en'}">{{driver.fullName || driver.customerName}}</div>
                    </div>
                    <div class="spinner-stage w-100" *ngIf="loadingDrivers">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
            </div>
<!--            </ng-container>-->
<!--            <ng-container *ngIf="viewSingleCustomer">-->
<!--                <div class="d-flex flex-column single-customer-details">-->
<!--                    <div class="name">{{singleCustomerDetails.customerName}}</div>-->
<!--                    <div>{{singleCustomerDetails.customerCity}}{{singleCustomerDetails.customerVillage ? (' - ' + singleCustomerDetails.customerVillage) : ''}}</div>-->
<!--                    <div>{{singleCustomerDetails.customerPhone}}</div>-->
<!--                </div>-->
<!--            </ng-container>-->

        </div>
        <div class="drivers-hub-container">
            <div *ngIf="isReadByBarcode && !isUpdateReceiverInfo" class="row read-by-barcode-top-actions">
                <div class="top-button-action cursor-pointer approve-package"
                     *appPermission="permissions.includes('{APPROVE_PACKAGES')"
                     [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                     (click)="approvePackages()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.APPROVAL' | translate}}
                        <br/>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.OF_THE_PACKAGES' | translate | packageTitle}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer assign-to-driver"
                     *appPermission="permissions.includes('{ASSIGN_TO_DRIVER') && !isLcl"
                     [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                     (click)="assignToDriver()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN' | translate}}
                        <br/>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_DRIVER' | translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer assign-to-container"
                     *appPermission="permissions.includes('{ASSIGN_TO_CONTAINER') && !isLcl"
                     [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                     (click)="assignToContainer()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN' | translate}}
                        <br/>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_VEHICLE' | translate}}
                    </span>
                </div>
                <div class="change-status-popover">
                    <div
                            class="top-button-action cursor-pointer"
                            *appPermission="permissions.includes('{CHANGE_PACKAGE_STATUS')"
                            [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                            #changePackageStatusPopOver="ngbPopover"
                            placement="bottom-left"
                            autoClose="outside"
                            [ngbPopover]="changePackageStatusDialog"
                            [placement]="'auto'"
                    >
                        <span>
                            {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.CHANGE' | translate}}
                            <br/>
                            {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.STATUS' | translate}}
                        </span>
                    </div>
                </div>
                <div class="top-button-action cursor-pointer" (click)="openPrintDialog()"
                     [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT' | translate}}
                        <br/>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.REPORTS' | translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer export-to-partner" (click)="transferPackages()"
                     *appPermission="permissions.includes('{TRANSFER') && !isLcl"
                     [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT' | translate}}
                        <br/>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_PARTNER' | translate}}
                    </span>
                </div>

                <div class="top-button-action cursor-pointer export-to-partner" (click)="transferToStorm()"
                     *ngIf="companyId === 41 || companyId === 1"
                     [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}
                        <br/>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.STORM' | translate}}
                    </span>
                </div>

                <div *ngIf="isLcl" class="top-button-action cursor-pointer export-to-partner"
                     (click)="enterContainerNumber()"
                     [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'LCL.ACTIONS.ADD_TO_CONTAINER'| translate}}
                    </span>
                </div>

                <div *ngIf="companyId === 153" class="top-button-action cursor-pointer export-to-partner"
                     (click)="transferToMughrabe()"
                     [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_MUGHRABE'| translate}}
                    </span>
                </div>
            </div>
            <div class="header-filters filters-container d-flex flex-row">
                <div *ngIf="userRole !== 'DISPATCHER'" class="flex-1 d-flex" style="gap: 7px">
                    <div *ngIf="isHoldCustody" class="sort-button" (click)="sortPackage()"
                         [ngClass]="{'disabled-btn disabled': !isThereSelection }">
                        {{"ACCOUNTING.SORT_ALL_WITHOUT_CUSTODY" | translate}}
                    </div>
                    <div class="sort-button" (click)="showConfirmSortPkg($event)" *ngIf="receive && !isHoldCustody"
                         [ngClass]="{'disabled-btn disabled': !isThereSelection }">
                        {{"ACCOUNTING.SORT_ALL" | translate}}
                    </div>
                    <div *ngIf="receive && !isHoldCustody" class="sort-button" (click)="bulkSortByBarcode()">
                        {{"GENERAL.SORT_BULK_BARCODES" | translate}}
                    </div>

                    <div *ngIf="!export && showDeliverSelectedButton()" class="sort-button" (click)="deliverSelected()"
                         [ngClass]="{'disabled-btn disabled': !isThereSelection }">
                        {{"ACCOUNTING.DELIVER_SELECTED" | translate}}
                    </div>

                    <div *ngIf="!export && showPrintButton()" class="sort-button" (click)="downloadPdf()">
                        {{"ACTIONS.PRINT" | translate}}
                    </div>

                    <div *ngIf="export" class="sort-button" (click)="addBarcodesToSortedMass()">
                        {{"GENERAL.ADD_BULK_BARCODES" | translate}}
                    </div>

                    <div *ngIf="export"
                         class="sort-button"
                         [ngClass]="{'disabled-btn disabled': !isThereSelection}" (click)="partialExport()">
                        {{"ACCOUNTING.PARTIAL_EXPORT" | translate}}
                    </div>
                    <div *ngIf="export" class="sort-button shadow-none" (click)="openExcel()">
                        {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}
                    </div>
                </div>
                <div *ngIf="!isMassInvoice" class="align-items-center gap-7 d-flex flex-row">
                    <i class="fas fa-sort-amount-asc"></i>
                    <div class="dropdown-filters-container">
                        <div class="order-by">
                            <div class="order-input-value">
                                <ng-select
                                        styleClass="tickets-sort-by-list"
                                        [(ngModel)]="selectedOrderByOption"
                                        [clearable]="false"
                                        [items]="orderByOptions" filter="true"
                                        (change)="onChangeOrderBy()"
                                        [style]="{'width':'100%'}">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isMassInvoice" class="split"></div>
                <div class="align-items-center gap-7 d-flex flex-row">
                    <i *ngIf="!isMassInvoice" class="fas fa-filter"></i>
                    <div *ngIf="!isMassInvoice" class="dropdown-filters-container">
                        <div class="order-by">
                            <div class="order-input-value">
                                <ng-select
                                        styleClass="tickets-sort-by-list"
                                        [(ngModel)]="selectedDateFilter"
                                        [clearable]="false"
                                        [items]="dateFiltersList" filter="true"
                                        (change)="onDateFilterChange()"
                                        [style]="{'width':'100%', 'min-width': '100px'}">
                                </ng-select>
                            </div>
                        </div>
                    </div>

<!--                    <div class="date-picker-filter"-->
<!--                         [ngClass]="{'disabled-btn disabled': !selectedDateFilter || selectedDateFilter.value === 'NONE' }">-->
<!--                        <i class="fas fa-calendar" (click)="datepicker.click()"></i>-->
<!--                        <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                               #datepicker-->
<!--                               daterangepicker [options]="dateRangPickerOptions"-->
<!--                               (selected)="onDateSelected($event)"/>-->
<!--                        <i class="fas fa-caret-down"></i>-->
<!--                    </div>-->
                    <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                    <div class="search-container">
                        <img src="../../assets/icons/general/search.svg" alt="icon"/>
                        <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                               [(ngModel)]="searchContent" appInputDebounced (inputDebounced)="onSearch()"/>
                    </div>
                </div>

            </div>
            <div class="packages-table"
                 [ngClass]="isReadByBarcode ? (isUpdateReceiverInfo ? 'update-receiver-info-by-barcode-packages-table': 'read-by-barcode-packages-table') : ''">
                <div class="packages-table flex-1 overflow-auto">
                    <p-table [value]="packages" [responsive]="true"
                             class="overflow-auto w-100"
                             responsiveLayout="scroll"
                             [scrollable]="true"
                             scrollHeight="auto"
                             [lazy]="true"
                             styleClass="customers-table flex-table" [loading]="isLoading"
                             #table>

                        <ng-template pTemplate="emptymessage">
                            <div style="height: 200px" *ngIf="spinnerState != spinnerStates.LOADING">
                                <app-no-data-available-component></app-no-data-available-component>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="loadingbody">
                            <div style="height: 200px">
                                <app-no-data-available-component></app-no-data-available-component>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr *ngIf="!isMassInvoice">
                                <th *ngIf="sort || isHoldCustody || receive || (['EXPORT'].indexOf(type) > -1)"
                                    style="width: 4%;">
                                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                                (onChange)="onToggleSelectAll()"></p-checkbox>
                                </th>
                                <th style="width: 150px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                                <th *ngIf="isPendingCustomer || invoices || receive || sort ||export || (['IN_CAR', 'EXPORT'].indexOf(type) > -1)"
                                    style="width: 150px;">{{"ACCOUNTING.COD_BARCODE" | translate}}
                                </th>
                                <th>{{"MANAGE_SHIPMENTS.COST" | translate}}</th>
                                <th *ngIf="mismatch || ['DELIVERED'].indexOf(type) > -1"
                                    style="width: 80px"> {{"MANAGE_SHIPMENTS.COD" | translate}} </th>
                                <th *ngIf="isPendingCustomer || invoices || receive || sort || export || (['DELIVERED', 'EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{"MANAGE_SHIPMENTS.INVOICE" | translate}}</th>
                                <th *ngIf="isPendingCustomer || invoices||receive || sort || export || (['DELIVERED', 'EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{"ACCOUNTING.SENDER_ADDRESS" | translate}}</th>
                                <th>{{"MANAGE_COD_CUSTOMERS.SENDER_NAME" | translate}}</th>
                                <th>{{"MANAGE_COD_CUSTOMERS.RECEIVER_NAME" | translate}}</th>
                                <th>{{"ADD_SHIPMENT.RECEIVER_ADDRESS" | translate | addressSystem}}</th>
                                <th *ngIf="invoices||mismatch || ['DELIVERED'].indexOf(type) > -1">{{'MANAGE_SHIPMENTS.STATUS' | translate}}</th>
                                <th style="min-width: 200px"
                                    class="line-break-anywhere">{{"MANAGE_COD_CUSTOMERS.NOTES" | translate}}</th>
                                <th *ngIf="receive"> {{"ACCOUNTING.CREATED_DATE" | translate}}</th>
                                <th *ngIf="isHoldCustody"> {{"ACCOUNTING.CUSTODY" | translate}}</th>

                                <th *ngIf="receive">{{'ACCOUNTING.RECEIVE_DATE' | translate}}</th>
                                <th *ngIf="isPendingCustomer || invoices||export || sort || (['DELIVERED', 'EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{"MANAGE_SHIPMENTS.DELIVERY_DATE" | translate}}</th>
                                <th *ngIf="!isPendingCustomer && (type === 'COD_AT_HAND' || !type)&& !isBankTransfer">
                                    {{"MANAGE_COD_CUSTOMERS.COD_AT_HAND" | translate}}
                                </th>
                                <th *ngIf="currency === 'SAR'"> {{"ACCOUNTING.COD_FEES" | translate}}</th>

                                <th *ngIf="!isPendingCustomer && (type === 'COD_WITH_CUSTOMER' || !type) && !isBankTransfer">
                                    {{"MANAGE_COD_CUSTOMERS.COD_WITH_CUSTOMER" | translate}}
                                </th>
                                <th *ngIf="!isPendingCustomer && (type === 'FUTURE_COD' || !type) && !isBankTransfer">
                                    {{"MANAGE_COD_CUSTOMERS.COD_FUTURE" | translate}}
                                </th>
                                <th *ngIf="isPendingCustomer || invoices||receive||sort|| export || isBankTransfer || (['EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{"ADD_SHIPMENT.COD_ON_RECEIVE" | translate}}</th>
                                <th *ngIf="isPendingCustomer || invoices || isExport">{{'ACCOUNTING.EXPORT_DATE' | translate}}</th>
                                <th *ngIf="isPendingCustomer || sort || export || (['EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{'SHIPMENT.TABLE.STATUS' | translate}}</th>
                                <th *ngIf="export || ['DELIVERED', 'IN_CAR'].indexOf(type) > -1">{{'ACCOUNTING.SORTING_DATE' | translate}}</th>

                                <th *ngIf="(isPendingCustomer || invoices||receive || export || (['EXPORT', 'DELIVERED', 'IN_CAR'].indexOf(type) > -1) || sort || (sort && isDeliveredPage)) && this.userService.userInfo.isDriverEarningEnabled">
                                    {{"DRIVERS_EARNINGS.DRIVER_COST" | translate}}
                                </th>
                            </tr>
                            <tr *ngIf="isMassInvoice">
                                <th style="width: 4%;">
                                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                                (onChange)="onToggleSelectAll()"></p-checkbox>
                                </th>
                                <th style="width: 150px;"> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.BARCODE' | translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.PACKAGES_COUNT' | translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.DELIVERY_DATE'| translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.MASS_COD_REPORTS_TRANSFER_FEES_SUM'| translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.NET_COD'| translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.INSURANCE_FEES_SUM'| translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.ADDITIONAL_COD_FEES_SUM'| translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.COST_SUM'| translate}}</th>
                                <th> {{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.COD_SUM'| translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-package let-expanded="expanded">
                            <tr *ngIf="!isMassInvoice" style="min-height: 60px">
                                <td *ngIf="sort || isHoldCustody || receive || (['EXPORT'].indexOf(type) > -1)"
                                    style="width: 4%">
                                    <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                                (onChange)="onToggleSelection($event, package)"></p-checkbox>
                                </td>
                                <td style="width: 150px; text-align: center;" class="cursor-pointer">
                                    <div class="barcode-image-container flex-2d-center">
                                        <div>
                                            <span class="hover-display-parent cursor-pointer"
                                                  (click)="editPackage(package.barcode)">
                                            <span>{{package.barcode ? package.barcode : package.packageBarcode}}</span>
                                                <i class="fas fa-copy cursor-pointer hover-display"
                                                   (click)="copyMessage($event,null,package.barcode?package.barcode:package.packageBarcode)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="isPendingCustomer || invoices || (['IN_CAR'].indexOf(type) > -1)" style="width: 150px; text-align: center;" class="cursor-pointer">
                                    <div class="barcode-image-container flex-2d-center">
                                        <div>
                                            <span class="hover-display-parent cursor-pointer">
                                            <span>{{package.codBarcode}}</span>
                                                <i class="fas fa-copy cursor-pointer hover-display"
                                                   (click)="copyMessage($event,null,package.codBarcode)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="receive||sort||export || (type === 'EXPORT')"
                                    style="width: 150px; text-align: center;">
                                    <div class="barcode-image-container flex-2d-center">
                                        <div>
                                            <span class=" hover-display-parent">
                                            <span>{{package.codBarcode}}</span>
                                                <i class="fas fa-copy cursor-pointer hover-display"
                                                   (click)="copyMessage($event, null, package.codBarcode)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="!(receive||sort||export)||!enableEditCost">
                                    <span> {{(package.hasOwnProperty('cost') ? package.cost : package.packageCost) | number : digitFormat}} </span>
                                </td>
                                <td *ngIf="(receive||sort||export)&&enableEditCost" pEditableColumn
                                    [pEditableColumn]="package"
                                    [pEditableColumnField]="'cost'">
                                    <app-edit-cost [package]="package" (onResetEvent)="initPackages()"></app-edit-cost>
                                </td>
                                <td *ngIf="mismatch || ['DELIVERED'].indexOf(type) > -1" style="width: 80px">
                                    {{package.cod | number : digitFormat}}
                                </td>
                                <td *ngIf="isPendingCustomer || invoices || receive || sort||export || (['DELIVERED', 'EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{package.invoiceNumber}}</td>
                                <td *ngIf="isPendingCustomer || invoices||receive||sort||export || (['DELIVERED', 'EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{package.senderCity}}</td>
                                <td style="min-width: 150px">{{package.senderName}}</td>
                                <td>{{package.receiverName ? package.receiverName : package.receiver}}</td>
                                <td>{{package.destination ? package.destination : package.receiverCity}}</td>
                                <td *ngIf="invoices||mismatch || ['DELIVERED'].indexOf(type) > -1">{{package.status | translate}}</td>
                                <td style="min-width: 200px">{{package.notes}}</td>
                                <td *ngIf="receive">{{package.createdDate | date:'dd/MM/yyyy'}}</td>
                                <td *ngIf="isHoldCustody">{{getCustody(package)}}</td>
                                <td *ngIf="receive">{{package.receiveDate | date:'dd/MM/yyyy': utcOffset}}</td>
                                <td *ngIf="isPendingCustomer || invoices||export || sort || (['DELIVERED', 'EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{package.deliveryDate | date:'dd/MM/yyyy'}}</td>
                                <td *ngIf="!isPendingCustomer && (type === 'COD_AT_HAND' || !type)">{{package.codAtHand | number : digitFormat}}</td>
                                <td *ngIf="currency === 'SAR'"> {{ package.codFees}}</td>
                                <td *ngIf="!isPendingCustomer && (type === 'COD_WITH_CUSTOMER' || !type)">{{package.codWithCustomer | number : digitFormat}}</td>
                                <td *ngIf="!isPendingCustomer && (type === 'FUTURE_COD' || !type)">{{package.futureCod | number : digitFormat}}</td>
                                <td *ngIf="isPendingCustomer || invoices||receive||sort||export||isBankTransfer || (['EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{package.cod | number : digitFormat}}</td>
                                <td *ngIf="isPendingCustomer || invoices||isExport">{{package.exportDate | date:'dd/MM/yyyy'}}</td>
                                <td *ngIf="isPendingCustomer || sort||export || (['EXPORT', 'IN_CAR'].indexOf(type) > -1)">{{package.status }}</td>
                                <td *ngIf="sort || export || ['DELIVERED', 'IN_CAR'].indexOf(type) > -1">{{package.sortDate | date:'dd/MM/yyyy'}}</td>
                                <td *ngIf="(invoices||receive || export || (['EXPORT', 'DELIVERED', 'IN_CAR'].indexOf(type) > -1) || sort || (sort && isDeliveredPage)) && this.userService.userInfo.isDriverEarningEnabled">
                                    {{package.driverEarning | number : digitFormat}}
                                </td>
                            </tr>
                            <tr *ngIf="isMassInvoice">
                                <td style="width: 4%">
                                    <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                                (onChange)="onToggleSelection($event, package)"></p-checkbox>
                                </td>
                                <td style="width: 150px; text-align: center;" class="cursor-pointer">
                                    <div class="barcode-image-container flex-2d-center">
                                        <div>
                                            <span class="hover-display-parent cursor-pointer"
                                                  (click)="editPackage(package.massReportBarcode)">
                                            <span>{{package.massReportBarcode}}</span>
                                                <i class="fas fa-copy cursor-pointer hover-display"
                                                   (click)="copyMessage($event,null,package.massReportBarcode)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td> {{package.pkgsCount}} </td>
                                <td> {{package.massReportDeliveryDate | date: 'dd/MM/YYYY'}} </td>
                                <td> {{package.massCodReportsTransferFeesSum}} </td>
                                <td> {{package.netCod}} </td>
                                <td> {{package.insuranceFeesSum}} </td>
                                <td> {{package.additionalCodFeesSum}} </td>
                                <td> {{package.costSum}} </td>
                                <td> {{package.codSum}} </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <div class="row bottom-actions d-inline-flex justify-content-between"
                     *ngIf="isReadByBarcode && !isUpdateReceiverInfo">
                    <div *ngIf="objectValues(packages).length" class="number-of-scanned-packages d-flex">
                        <div class="icon">
                            <img src="../../../assets/icons/containers-management/packages_number.svg" alt=""/>
                        </div>
                        <div class="label">
                            <span>{{'RECEIVE_FROM_DRIVER.NUMBER_OF_SELECTED_PACKAGES' | translate | packageTitle}}</span>
                        </div>
                        <div class="number">
                            <span>{{objectValues(scannedPackages).length}}</span>
                        </div>
                    </div>
                    <div class="finish-read-by-barcode" (click)="finish()"
                         [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">{{'READ_BY_BARCODE.ACTIONS.RESET' | translate}}</div>
                </div>
                <div class="row bottom-actions"
                     *ngIf="!isMassInvoice && (isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages)">
                    <div class="flex-1">
                        <div class="bottom-values flex-1">
                            <p-table class="top-border-0" [value]="totalSatisfactionNumber">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th>{{"ACCOUNTING.TOTAL_PACKAGES" | translate}}</th>
                                        <th>{{"ACCOUNTING.COD_SUM" | translate}}</th>
                                        <th>{{"ACCOUNTING.TOTAL_COST_SUM" | translate}}</th>
                                        <th>{{"MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST" | translate}}</th>
                                        <th *ngIf="currency === 'SAR'">{{"ACCOUNTING.COD_FEES_SUM" | translate}}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowTemp>
                                    <tr>
                                        <td>{{"ACCOUNTING.ALL" | translate}}</td>
                                        <td>{{totalRecords | number : digitFormat}}</td>
                                        <td>{{(totalCod) | number : digitFormat | currencyValue}}</td>
                                        <td>{{(totalCostSum) | number : digitFormat | currencyValue}}</td>
                                        <td>{{(totalCodWithoutCost) | number : digitFormat | currencyValue}}</td>
                                        <td *ngIf="currency === 'SAR'">{{(totalCodFees) | number : digitFormat | currencyValue}}</td>
                                    </tr>
                                    <tr *ngIf="export || sort || (['RECEIVE','DELIVERED', 'EXPORT'].indexOf(type) > -1)">
                                        <td>{{"ACCOUNTING.SELECTED" | translate}}</td>
                                        <td>{{(isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages) ? objectValues(scannedPackages).length : objectValues(packages).length}}</td>
                                        <td>{{selectedCod | number : digitFormat | currencyValue}}</td>
                                        <td>{{selectedCostSum | number : digitFormat | currencyValue}}</td>
                                        <td>{{selectedCodWithoutCost | number : digitFormat | currencyValue}}</td>
                                        <td *ngIf="currency === 'SAR'">{{selectedCodFees | number : digitFormat | currencyValue}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container>
    <div class="position-fixed">
        <app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                        [packageBarcode]="selectedPackageBarcode"
                                        (closeWidget)="onHideAddPackage($event)">
        </app-add-package-form-component>
    </div>
</ng-container>
