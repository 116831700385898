import {Component} from '@angular/core';

@Component({
    selector: 'app-404-component',
    templateUrl: '404.component.html',
    styleUrls: ['404.component.scss']
})
export class Page404Component {

}
