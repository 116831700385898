import {Component, OnInit, ViewChild} from '@angular/core';
import {COD_TYPES, SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {SharedService} from '../../../shared/services/shared-service';
import {UsersService} from '../../../users/services/users.service';
import {DatePipe} from '@angular/common';
import {PrintPackageOptionsComponent} from '../../../users/print-package-options/print-package-options.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UserService} from '../../../shared/services/user.service';
import {ALL_MASS_COD_REPORT_DATE_TYPES} from '../../../shipment/services/constants';
import {MassCodReportHistoryComponent} from '../mass-cod-report-history/mass-cod-report-history.component';

@Component({
    selector: 'app-all-cod-customers',
    templateUrl: './all-cod-customers.component.html',
    styleUrls: ['./all-cod-customers.component.scss']
})
export class AllCodCustomersComponent implements OnInit {
    selectedCustomerId: number;
    codTypes;
    selectedCodType;
    dateRangPickerOptions: any = {};
    fromDate;
    toDate;
    search;
    isLoading = false;
    massCodReports = [];
    pageNumber = 0;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    totalRecords = 0;
    first = 0;
    isAllSelected = false;
    public selectedDateFilter;
    isEnableVat = false;

    @ViewChild('table') table;
    dateTypesList = [];
    currency = '';

    filterFormOn = false;
    @ViewChild('filtersFormOverLay') filtersFormOverLay;
    @ViewChild('userFilter') userFilter;
    showEmptyCODs = 'NOT_EMPTY';
    customerHubId: any;

    constructor(private translate: TranslateService,
                private sharedService: SharedService,
                private datePipe: DatePipe,
                private shipmentsService: ShipmentsService,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private usersService: UsersService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private dateRangePickerService: DateRangePickerService) {
    }

    ngOnInit() {
        this.currency = this.authenticationService.getCurrency();
        this.setDateRangePickerOptions();
        this.initCodTypesList();
        this.initDateTypeFilter();
        this.isEnableVat = this.userService.userInfo.isEnableVat;
    }

    initCodReport(event = this.showEmptyCODs) {
        this.isLoading = true;
        let request;
        if (event === 'NOT_EMPTY') {
            request = this.usersService.getAllCodCustomersReport(this.createQuery());
        } else if (event === 'EMPTY') {
            request = this.usersService.getAllEmptyCodCustomersReport(this.createQuery());
        }
        request.subscribe(
            (res: any) => {
                this.massCodReports = res.massCodPackages;
                this.totalRecords = res.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.log(error);
                this.isLoading = false;
            }
        );
    }

    onFilter() {
        this.pageNumber = 0;
        this.massCodReports = [];
        this.table.reset();
        this.initCodReport();
        this.filtersFormOverLay.hide();
    }

    resetFilters() {
        this.userFilter.selectedUser = null;
        this.selectedCustomerId = null;
        this.selectedCodType = null;
        this.onFilter();
    }

    onSelectCustomer(id) {
        this.selectedCustomerId = (id === undefined ? '' : id);
    }

    showFiltersMenu(event) {
        this.filtersFormOverLay.toggle(event);
        this.filterFormOn = true;
        event.stopPropagation();
    }

    initCodTypesList() {
         const codTypes = [];
         COD_TYPES.map(type => (codTypes[type] = type));

        if (!this.userService.userInfo.isEnableCustomerConfirmationForReceivingMassCodPkg) {
            delete codTypes['PENDING_CUSTOMER_RECEIVING_CONFIRMATION'];
        }
        if (!this.userService.userInfo.isInvoicingEnabled) {
            delete codTypes['INVOICE'];
        }

        this.codTypes = Object.values(codTypes).map(type => {
            return {label: this.translate.instant(`ACCOUNTING.ALL_COD_CUSTOMERS.COD_TYPES.${type}`), value: type};
        });
    }

    setDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions().then(
            options => {
                this.dateRangPickerOptions = options;
            }
        );
    }

    onDateSelected(value) {
        const {start, end} = value;
        this.fromDate = new Date(start);
        this.toDate = new Date(end);
        this.onFilter();
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    createQuery() {
        let query = `?pageSize=${this.pageSize}&page=${this.pageNumber + 1}`;
        if (this.fromDate && this.toDate) {
            query += `&fromDate=${this.transformDate(this.fromDate)}&toDate=${this.transformDate(this.toDate)}`;
        }
        if (this.search) {
            query += `&search=${this.search}`;
        }
        if (this.selectedCodType) {
            query += `&multipleStatuses=` + this.selectedCodType;
        }
        if (this.selectedCustomerId) {
            query += `&customerId=` + this.selectedCustomerId;
        }
        if (this.customerHubId) {
            query += `&customerHubId=` + this.customerHubId;
        }
        if (this.selectedDateFilter) {
            query += '&dateFilterType=' + this.selectedDateFilter;
            query += '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return query;
    }

    loadReportsLazy(event) {
        this.pageNumber = event.first / event.rows;
        this.pageSize = event.rows;
        this.first = event.first;
        this.initCodReport();
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    printReport(report, event) {
        event.stopPropagation();
        if (report.isPrinting) return;
        report.isPrinting = true;
        const modal = this.modalService.open(PrintPackageOptionsComponent, {
            backdrop: 'static',
            windowClass: 'verify-driver',
            size: 'lg'
        });
        modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93;
        modal.result.then(
            (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                if (status.isSuccess) {
                    report.isPrintedByAccountant = true;
                    this.actuallyPrintReport(report, status.printType, status.orderBy);
                } else {
                    report.isPrinting = false;
                }
            }
        ).catch(
            () => {
            }
        );
    }

    actuallyPrintReport(report, printType?: string, orderBy?) {
        const reportId = report.id;
        const datesFilters = {from: this.transformDate(this.fromDate),  to: this.transformDate(this.toDate)};
        this.shipmentsService.downloadMassPackagePdf(reportId, printType, orderBy, datesFilters, false, this.showEmptyCODs === 'EMPTY').subscribe((res: any) => {
                window.open(res.url, '_blank');
                report.isPrinting = false;
            },
            error => {
                console.log(error);
                this.isLoading = false;
            });
    }

    public printMultipleReports() {
        this.isLoading = true;
        const modal = this.modalService.open(PrintPackageOptionsComponent, {
            backdrop: 'static',
            windowClass: 'verify-driver',
            size: 'lg'
        });
        modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93;
        modal.result.then(
            (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                if (status.isSuccess) {
                    this.actuallyPrintAllReports(status.printType, status.orderBy);
                    this.getSelectedPackages().forEach(pkg => pkg.isPrintedByAccountant = true);
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            () => {
            }
        );
    }


    actuallyPrintAllReports(printType?: string, orderBy?) {
        this.isLoading = true;
        this.translate.get(
            'ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'
        ).subscribe(
            (message) => {
                this.messageService.add({severity: 'success', detail: message});
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleMassPackages(selectedPackages, printType, orderBy).subscribe(
            () => {
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }
        this.massCodReports.forEach((pkg: any) => {
            pkg.isSelected = selected;
        });
    }

    getSelectedPackages() {
        return this.massCodReports.filter(report => (report.isSelected));
    }

    printList() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleMassReports(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.getSelectedPackages().forEach(pkg => pkg.isPrintedByAccountant = true);
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    printSelectedPkgs(type, callBack?, singlePackage?) {
        const selectedPkgs = this.getSelectedPackages();
        const previouslyPrintedPkgs: any = selectedPkgs.filter(pkg => pkg.isPrintedByAccountant);
        if (previouslyPrintedPkgs && previouslyPrintedPkgs.length || type === 'single' && singlePackage && singlePackage.isPrintedByAccountant) {
            this.confirmationService.confirm({
                message: this.translate.instant(selectedPkgs.length > 1 && type !== 'single' ? 'ACCOUNTING.PREVIOUSLY_PRINTED_PACKAGES_REPORTS' :
                    'ACCOUNTING.SINGLE_PACKAGE_PREVIOUSLY_PRINTED_REPORTS'),
                accept: () => {
                    if (callBack) {
                        callBack.apply();
                    } else {
                        this.printPkgReport(type);
                    }
                },
                acceptLabel: this.translate.instant('GENERAL.YES'),
                rejectLabel: this.translate.instant('GENERAL.NO'),
            });
        } else {
            if (callBack) {
                callBack.apply();
            } else {
                this.printPkgReport(type);
            }
        }
    }

    printPkgReport(type) {
        switch (type) {
            case 'list':
                this.printList();
                break;
            case 'multiple':
                this.printMultipleReports();
                break;
        }
    }
    exportExcel() {
        this.translate.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
                accept: () => {
                    this.isLoading = true;
                    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
                    this.shipmentsService.downloadExcelForExportedCod({ids: selectedPackages}, false, this.showEmptyCODs === 'EMPTY').subscribe((response: any) => {
                        window.open(response.url, '_blank');
                        this.isLoading = false;
                    });
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    exportToExcel(report, event) {
        event.stopPropagation();
        if (report.isExporting) return;
        report.isExporting = true;
        this.shipmentsService.exportToExcel(report.id, {isEmptyReport: this.showEmptyCODs === 'EMPTY'}).subscribe((res: any) => {
            window.open(res.url, '_blank');
            report.isExporting = false;
        }, (error) => {
            console.log(error);
            report.isExporting = false;
        });
    }

    private initDateTypeFilter() {
        this.dateTypesList = ALL_MASS_COD_REPORT_DATE_TYPES.map((value) => {
            const label = this.translate.instant(value);
            return {label, value};
        });
    }

    showHistory($event, record) {
        $event.stopPropagation();
        const model = this.modalService.open(MassCodReportHistoryComponent, {
            backdrop: 'static'
        });

        model.componentInstance.report = record;
    }

    isShowCommissionFees() {
        return this.userService.userInfo.isEnableCustomerCommissionFee;
    }

    showEmptyCODsSelection(event: any) {
        this.showEmptyCODs = event;
        this.initCodReport(event);
    }

    onChangeHubMethod(event) {
        this.customerHubId = event;
        this.onFilter();
    }
}
