import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-add-package-form-item',
  templateUrl: './add-package-form-item.component.html',
  styleUrls: ['./add-package-form-item.component.scss']
})
export class AddPackageFormItemComponent {
  @Input() title: string;
  @Input() showIcon: boolean;
  @Input() pTooltip: any;
  @Input() tooltipPosition: any;
  @Input() icon: string;
  @Input() mainIcon: string;
  @Output() onIconClick: EventEmitter<any> = new EventEmitter<any>();
}
