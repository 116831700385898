import {Component, OnInit} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from '../../accounting-constants';
import {AccountingRowActionsSettings} from '../../models/row-actions-setting.model';
import {TemplateTopActionsSettingsModel} from '../../models/template-top-actions-settings.model';
import {AccountingTemplateFiltersModel} from '../../models/accounting-template-filters.model';
import {UserService} from '../../../shared/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-mass-invoices',
  templateUrl: './mass-invoices.component.html',
  styleUrls: ['./mass-invoices.component.scss']
})
export class MassInvoicesComponent  implements OnInit {

  title = ACCOUNTING_CONSTANTS.MASS_INVOICES.TITLE;
  info = ACCOUNTING_CONSTANTS.MASS_INVOICES.INFO;
  apiUrl = ACCOUNTING_CONSTANTS.MASS_INVOICES.API;
  rowActionsSettings: AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.MASS_INVOICES.ROW_ACTIONS_SETTINGS;
  topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.MASS_INVOICES.TOP_ACTIONS_SETTINGS;
  filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.MASS_INVOICES.FILTERS_SETTINGS;
  dateFiltersList = ACCOUNTING_CONSTANTS.MASS_INVOICES.DATE_FILTERS_LIST;
  constructor(private userService: UserService) {
  }

  ngOnInit() {

    const isEnableVat = this.userService.userInfo.isEnableVat;
    const info: [] = _.cloneDeep(ACCOUNTING_CONSTANTS.MASS_INVOICES.INFO);

    if (!isEnableVat) {
      info.splice(info.findIndex((value: any) => value.isVat), 1);

    }

    if (!this.userService.userInfo.isSupportAdditionalFees) {
      info.splice(info.findIndex((value: any) => value.isInsurance), 1);
      if (this.userService.getCurrency() !== 'SAR') {
        info.splice(info.findIndex((value: any) => value.isAdditionalFees), 1);
      }
    }

    this.info = info;
  }
}
