<ng-container *ngIf="menuItem.path; else popup">
    <a [ngStyle]="{'padding':  '15 ' + level + 'px' }"
         routerLink="{{menuItem?.path}}"
         [routerLinkActive]="(menuItem.subItems&&menuItem.subItems.length ?'':'active')"
         [queryParams]="menuItem?.params"
         class="item-container cursor-pointer menu-item-animation a-link">
        <div class="icon-container">
            <i class="{{menuItem?.icon}}"></i>
        </div>

        <div class="label-container">
            {{menuItem?.label | translate }}
        </div>

        <div *ngIf="menuItem.path === 'pending-approval'" class="pending-number">
            <span>{{userService.followUpPackageCount}}</span>
        </div>
        <div *ngIf="menuItem.path === 'inbox'" class="pending-number">
            <span>{{userService.unreadMessages}}</span>
        </div>

    </a>
</ng-container>
<ng-template #popup>
    <ng-container *ngIf="!menuItem.subItems; else menuList">
        <div class="item-container cursor-pointer menu-item-animation" (click)="openModal()">
            <div class="icon-container">
                <i class="{{menuItem?.icon}}"></i>
            </div>

            <div class="label-container">
                {{menuItem?.label | translate }}
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #menuList>
    <div class="d-flex flex-column">
        <div [ngStyle]="{'padding' :  '15px ' + level + 'px' }"
             [ngClass]="{'active' : hasActiveChild}"
             class="item-container cursor-pointer menu-item-animation" (click)="toggleMenuVisibility()">
            <div class="icon-container">
                <i class="{{menuItem?.icon}}"></i>
            </div>

            <div class="label-container flex-1">
                {{menuItem?.label | translate }}
            </div>
            <i *ngIf="menuItem.subItems" class="fas fa-angle-down menu-list-collapse-icon"
               [ngClass]="{'rotated-icon':show}" style="padding: 0 10px;margin-top: 4px;"
               aria-hidden="true"></i>
        </div>
        <div *ngIf="menuItem.subItems" class="sub-menu"
             [ngStyle]="{'max-height':show ? (getMenuHeight(menuItem) + 'px') : 0}">
            <app-menu-item-component *ngFor="let item of menuItem.subItems"
                                     [menuItem]="item"
                                     (subMenuShow)="onSubMenuShow($event)"
                                     [level]="(level + 10)"></app-menu-item-component>
        </div>
    </div>
</ng-template>
