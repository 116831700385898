import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService} from 'primeng/api';
import {ShippingLinesService} from '../../../administration/services/shipping-lines.service';
import {AddShippingLineComponent} from '../../../administration/components/add-shipping-line/add-shipping-line.component';
import {ADMINISTRATION_CONSTANTS} from '../../../administration/administration-constants';

@Component({
  selector: 'app-work-zone',
  templateUrl: './work-zone.component.html',
  styleUrls: ['./work-zone.component.scss']
})
export class WorkZoneComponent implements OnInit {
  public selectedWorkZoneLine = null;
  public workZoneLines = [];
  public selectedVillagesUrl = '';
  public selectedVillages = [];
  public regionsURL = ADMINISTRATION_CONSTANTS.ZONE_GET_REGIONS;
  public citiesURL = '';
  public villagesURL = '';
  public regionID = 0;
  public cityID = 0;
  public selectedShippingLineId = 0;
  public type = 'DRIVER_ZONE';

  constructor(private shippingLinesService: ShippingLinesService,
              private modalService: NgbModal,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.fetchShippingLines();
  }
  public onLineSelected() {
    const shippingLineId = this.selectedWorkZoneLine.id;
    this.selectedShippingLineId = shippingLineId;
    this.shippingLinesService.getShippingLine(shippingLineId).subscribe((response: any) => {
        this.selectedVillages = response.villages.map((village) => {
          return village.id;
        });
    });
    this.selectedVillagesUrl = ADMINISTRATION_CONSTANTS.EDIT_SHIPPING_LINE.replace(
        '{shippingLineId}', shippingLineId);
  }

  public editLine() {
    const shippingLineId = this.selectedWorkZoneLine.id;
    const modal = this.modalService.open(AddShippingLineComponent, {backdrop: 'static', windowClass: 'shipping-line', size: 'lg'});
    modal.componentInstance.type = this.type;
    modal.componentInstance.shippingLineId = shippingLineId;
    modal.result.then(
        (data: { isSuccess: boolean, name: string }) => {
          if (data.isSuccess) {
            this.fetchShippingLines(data.name);
            this.addNewLineCancel();
          }
        }
    ).catch(
        (error) => {
        }
    );
  }
  addNewLineButton() {
    const modal = this.modalService.open(AddShippingLineComponent, {backdrop: 'static', windowClass: 'shipping-line', size: 'lg'});
    modal.componentInstance.type = this.type;
    modal.result.then(
        (data: { isSuccess: boolean, name: string }) => {
          if (data.isSuccess) {
            this.fetchShippingLines(data.name);
            this.addNewLineCancel();
          }
        }
    ).catch(
        (error) => {
        }
    );
  }
  onVillageChecked($event: any) {
    const selectedShippingLineId = this.selectedWorkZoneLine.id;
    if ($event.checked) {
      this.shippingLinesService.addVillageToLine(selectedShippingLineId, $event.id, this.type).subscribe(() => {
        this.selectedVillages.push({id: $event.id});
        this.addNewLineCancel();
      }, (error) => {
        this.confirmationService.confirm({
          message: (error.error.error),
          rejectVisible: false,
          accept: () => {
          },
          acceptLabel: 'OK'
        });
        this.addNewLineCancel();
      });
    } else {
      this.shippingLinesService.removeVillageFromLine(selectedShippingLineId, $event.id, this.type).subscribe(() => {
        this.selectedVillages.splice(this.selectedVillages.indexOf($event.id), 1);
        this.addNewLineCancel();
      }, (error) => {
        this.addNewLineCancel();
      });
    }
  }
  onRegionChosen($event: any) {
    const id = $event.id;
    if (id) {
      this.citiesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_REGION_CITIES.replace('{regionId}', id);
      this.villagesURL = '';
      this.regionID = id;
    }
  }
  onCityChosen($event: any) {
    const id = $event.id;
    if (id) {
      this.villagesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_CITY_VILLAGES.replace('{cityId}', id);
      this.cityID = id;
    }
  }
  private fetchShippingLines(lineName = '') {
    const type = 'DRIVER_ZONE';
    this.shippingLinesService.getShippingLines(type).subscribe((response: any) => {
      this.workZoneLines = response;
      if (response && response.length) {
          if (lineName) {
            this.selectedWorkZoneLine = response.find(({name}) => name === lineName);
          } else {
            if (this.selectedWorkZoneLine === null) {
              this.selectedWorkZoneLine = response[0];
              this.selectedShippingLineId  = this.selectedWorkZoneLine.id;
            }
          }
        this.onLineSelected();
      }
    });
  }
  addNewLineCancel() {
    this.selectedVillages = [];
    this.onLineSelected();
  }
}
