<form *ngIf="form" [formGroup]="form">
    <div class="input-container">
        <div class="input-label" *ngIf="label != '' && controlType !== 'autoCompleteWithActions'" [ngClass]="{'error': form.controls[controlName].invalid &&
             (form.controls[controlName].dirty ||
              form.controls[controlName].touched)}">
            {{label | translate  | hubTitle }}
            <span *ngIf="isRequired" class="required">*</span>
        </div>
        <div class="input-label" style="margin-right: 17px;"
             *ngIf="label != '' && controlType === 'autoCompleteWithActions'" [ngClass]="{'error': form.controls[controlName].invalid &&
             (form.controls[controlName].dirty ||
              form.controls[controlName].touched)}">
            {{label | translate  }}
            <span *ngIf="isRequired" class="required">*</span>
        </div>
        <div class="input-field d-flex align-items-baseline">
            <ng-container [ngSwitch]="controlType">
                <ng-container *ngSwitchCase="'select'">
                    <select [disabled]="disabled" [ngClass]="{'errorIn': form.controls[controlName].invalid &&
                        (form.controls[controlName].dirty ||
                         form.controls[controlName].touched)}" class=" select-input text-input"
                            [formControlName]="controlName"
                            (change)="changed.emit($event)"
                            [style.backgroundColor]="selectBackgroundColor">
                        <!--html5 'select element' will select a default option incase we only have one option
                         this behavior is not reflected on the model value and will case a big issue where there is on option that selected and the model value is invalid
                         so the easy fix is to add a disabled empty option-->
                        <option [ngClass]="{'default-background-color': selectBackgroundColor}"
                                [disabled]="disabledDefaultOption"></option>
                        <option [ngClass]="{'default-background-color': selectBackgroundColor}"
                                *ngFor="let option of options; let i = index" [value]="option.value">
                            {{option.label | translate }}
                        </option>
                    </select>
                    <span class="note error" *ngIf="hasError('required')">
                        {{requiredErrorMessage | translate}}
                    </span>
                    <span class="note" *ngIf="note">{{note}}</span>
                    <span class="note" *ngIf="note">{{note}}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'textarea'">
                    <textarea [ngClass]="{'errorIn': form.controls[controlName].invalid &&
                        (form.controls[controlName].dirty ||
                         form.controls[controlName].touched)}" class=" select-input text-input"
                              [formControlName]="controlName" [placeholder]="placeholder"
                              (change)="changed.emit($event)" rows="10">
                        <!--html5 'select element' will select a default option incase we only have one option
                         this behavior is not reflected on the model value and will case a big issue where there is on option that selected and the model value is invalid
                         so the easy fix is to add a disabled empty option-->
                    </textarea>
                    <span class="note error" *ngIf="hasError('required')">
                        {{requiredErrorMessage | translate}}
                    </span>
                    <span class="note" *ngIf="note">{{note}}</span>
                    <span class="note" *ngIf="note">{{note}}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'autoComplete'">
                    <p-autoComplete [suggestions]="searchSuggestions"
                                    (completeMethod)="search($event , false)"
                                    (onSelect)="itemChosen($event)"
                                    [formControlName]="controlName"
                                    placeholder="{{placeholder | translate }}"
                                    [field]="field" #autoComplete
                                    [required]="isRequired"
                                    [disabled]="disabled"
                                    [ngClass]="{'auto-complete-item':displayDropDown}"
                                    [dropdown]="displayDropDown" (onClear)="onClear()"
                                    [forceSelection]="forceSelection"
                                    [delay]="debounceDelay"
                                    [dataKey]="dataKey" #receiverPhoneAutoComplete
                                    (onBlur)="onBlurAutoComplete()"
                                    appendTo="{{appendTo}}"
                                    class="w-100"
                                    styleClass="auto-complete-container">
                    </p-autoComplete>
                    <div *ngIf="showCustomizedCaretDown"
                         class="customized-caret-down" (click)="receiverPhoneAutoComplete.handleDropdownClick($event)">
                        <i class="fas fa-caret-down"></i></div>
                </ng-container>
                <ng-container *ngSwitchCase="'autoCompleteWithActions'" style="margin-right: 20px;">
                    <div class="p-inputgroup">
                        <p-autoComplete [suggestions]="searchSuggestions"
                                        (completeMethod)="search($event, true)"
                                        [formControlName]="controlName"
                                        placeholder="{{placeholder}}"
                                        [field]="field" #autoCompleteWithActions
                                        [required]="isRequired"
                                        [disabled]="disabled"
                                        [ngClass]="{'auto-complete-item':displayDropDown}"
                                        [dropdown]="displayDropDown" (onClear)="onClear()"
                                        [forceSelection]="forceSelection"
                                        [dataKey]="dataKey"
                                        [delay]="debounceDelay"
                                        appendTo="{{appendTo}}"
                                        (onBlur)="onBlurAutoComplete()"
                                        styleClass="auto-complete-container">
                            <ng-template let-expense pTemplate="item">
                                <div class="row">
                                    <div style="margin-left: 8px;">
                                        <i class="print-action action cursor-pointer fas fa-trash-alt"
                                           (click)="deleteButton($event , expense)"></i>
                                    </div>
                                    <div>{{expense.name||expense[field]}}</div>
                                </div>
                            </ng-template>

                        </p-autoComplete>
                        <ng-container *ngIf="showAddBtn">
                            <span *ngIf="!addedExpenseType" (click)="addButton()"
                                  class="p-inputgroup-addon cursor-pointer"><i
                                    class="fas fa-plus"></i></span>
                            <span *ngIf="addedExpenseType" class="p-inputgroup-addon add-success cursor-pointer"><i
                                    class="fas fa-check"></i></span>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'inputgroup'">
                    <div class="p-inputgroup">
                        <input pInputText *ngIf="controlName && !field" [ngClass]="{'errorIn': form.controls[controlName].invalid &&
             (form.controls[controlName].dirty ||
              form.controls[controlName].touched)}" [placeholder]="placeholder" [type]="controlType"
                               [formControlName]="controlName">
                        <input pInputText *ngIf="!controlName && !field" [placeholder]="placeholder"
                               [type]="controlType">
                        <p-autoComplete [suggestions]="searchSuggestions" *ngIf="field"
                                        (completeMethod)="search($event)"
                                        (onSelect)="itemChosen($event)"
                                        [formControlName]="controlName"
                                        placeholder="{{placeholder}}"
                                        [field]="field" #autoComplete
                                        [required]="isRequired"
                                        appendTo="{{appendTo}}"
                                        [delay]="debounceDelay"
                                        [ngClass]="{'auto-complete-item':displayDropDown}"
                                        [dropdown]="displayDropDown" (onClear)="onClear()"
                                        [forceSelection]="forceSelection"
                                        [dataKey]="dataKey" #receiverPhoneAutoComplete
                                        (onBlur)="onBlurAutoComplete()"
                                        styleClass="auto-complete-container">
                        </p-autoComplete>
                        <span (click)="iconClicked()" class="p-inputgroup-addon cursor-pointer"><i
                                class="fas fa-user"></i></span>
                    </div>
                    <span class="note error" *ngIf="hasError('required')">
                        {{requiredErrorMessage | translate}}
                    </span>

                    <span class="note" *ngIf="note">{{note}}</span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <input #text *ngIf="controlName" [ngClass]="{'errorIn': form.controls[controlName].invalid &&
             (form.controls[controlName].dirty ||
              form.controls[controlName].touched)}" class="text-input" [placeholder]="placeholder" [type]="controlType"
                           [formControlName]="controlName">
                    <input #text *ngIf="!controlName" class="text-input" [placeholder]="placeholder"
                           [type]="controlType">
                    <span class="note error" *ngIf="hasError('required')">
                        {{ requiredErrorMessage | translate}}
                    </span>
                    <span class="note error" *ngIf="hasError('minlength') && minLengthErrorMessage">
                        {{ minLengthErrorMessage | translate: {min: minLength}  }}
                    </span>
                    <span class="note" *ngIf="note">{{note}}</span>
                </ng-container>
            </ng-container>

            <i *ngIf="showAfterIcon" class="mx-1 {{icon}} cursor-pointer" (click)="iconAction.emit()"></i>
        </div>
    </div>
</form>
