import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-time-period-packages-administrative-report',
    templateUrl: './time-period-packages-administrative-report.component.html',
    styleUrls: ['./time-period-packages-administrative-report.component.css']
})
export class TimePeriodPackagesAdministrativeReportComponent {
    public timePeriodReports = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.TIME_PERIOD_REPORTS
    public timePeriodReportsAPI = REPORTS_CONSTANTS.ADMINISTRATIVE_REPORTS.TIME_PERIOD_REPORTS_API;


    constructor() {
    }
}
