import {Component, OnInit} from '@angular/core';
import {EmailModel} from './models/email.model';
import {SpinnerState} from '../shared/behavior/spinner-state.enum';
import {InboxService} from './services/inbox.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {SendMessageComponent} from './modals/send-message/send-message.component';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {
    /**
     * indicates if the message is opened or not
     * controls the appearance of message body section in UI.
     */
    public isEmailDetailsOpened = false;
    public selectedEmail: EmailModel;
    public pageNumber = 0;
    public totalRecords = 0;

    /**
     *
     */
    public emails: EmailModel[] = [];
    public showRead = 'ALL';

    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    public currentLang;

    constructor(
        private inboxService: InboxService,
        private modalService: NgbModal,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.fetchMessages();
    }

    public fetchMessages(filter = this.showRead) {
        this.inboxService.getInboxMessages(this.pageNumber).subscribe(
            (response: any) => {
                this.emails = response.messages;
                if (filter === 'UNREAD') {
                    this.emails = this.emails.filter(email => !email.isOpened);
                } else if (filter === 'READ') {
                    this.emails = this.emails.filter(email => email.isOpened);
                }
                this.totalRecords = response.totalRecordsNo;
                this.spinnerState = SpinnerState.LOADED;
                if (response && response.length === 0) {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
                //    TODO
            }, () => {
            }
        );
    }

    /**
     *
     */
    public openEmailDetails(email: EmailModel) {
        this.setEmailAsOpened(email);
        this.selectedEmail = email;
        this.isEmailDetailsOpened = true;
    }

    /**
     * send a request to backend if needed to set the email as opened.
     */
    public setEmailAsOpened(email: EmailModel) {
        if (!email.isOpened) {
            email.isOpened = true;
            this.inboxService.setEmailAsOpened(email).subscribe(
                (response) => {
                }, (error) => {
                    console.log(error);
                }
            );
        }
    }

    public onPagination($event) {
        this.pageNumber = $event.page;
        this.fetchMessages();
    }

    public sendMessage(ntnElement) {
        ntnElement.blur();
        const modal = this.modalService.open(SendMessageComponent, {backdrop : 'static', windowClass: 'md'});
        modal.result.then(
            (result) => {
                if (result.isSuccess) {
                    this.showRead = 'ALL';
                    this.fetchMessages(this.showRead);
                    this.messageService.add({severity: 'success', detail: this.translate.instant('INBOX.SENT_SUCCESSFULLY')});
                }
            }
        ).catch((error) => {
            // Popup closing.
            console.log(error);
        });
    }

    showUnreadMessages(value) {
        this.showRead = value;
        if (value === 'READ') {
            this.fetchMessages(value);
        } else if (value === 'UNREAD') {
            this.fetchMessages(value);
        } else {
            this.fetchMessages();
        }
    }
}
