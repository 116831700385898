import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DriversService} from '../../shared/services/drivers.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UsersService} from '../../users/services/users.service';
import {DriverRouteDialogComponent} from './driver-route-dialog/driver-route-dialog.component';
import {HubModel} from '../../shared/models/hub.model';
import {HubsService} from '../../shared/services/hubs.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UserService} from '../../shared/services/user.service';
import {RouteModel} from '../../shared/models/route.model';
import {AddressSystemPipe} from '../../shared/pipes/address-system.pipe';
import {UtilitiesService} from '../../shared/services/utilities.service';
import {FormBuilder, FormGroup} from '@angular/forms';
declare let L;

@Component({
    selector: 'app-optimized-routes',
    templateUrl: './optimized-routes.component.html',
    styleUrls: ['./optimized-routes.component.scss']
})
export class OptimizedRoutesComponent implements OnInit {
    currentLang: string;
    isLoading = false;
    isLoadingDrivers = false;

    fetchedPackages: any[] = [];
    packages: any[] = [];
    totalPackagesNo = 0;

    filterParams = {};

    drivers: { id: number, firstName: string, lastName: string, packagesCount: number, latitude: number, longitude: number }[] = [];
    hubs: { [id: number]: HubModel };
    fetchedDrivers: any[] = [];
    totalDriversNo = 0;
    selectedDriver: any;
    initialMapLocation: any;
    map: any;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    selectedDrivers = [];
    private route = {};
    locations = {};
    public routePolyline = {};
    public mapMarkers = {};

    // readonly COLORS = ['ff0000','ff8700','ffd300','deff0a','a1ff0a','0aff99','0aefff','147df5','580aff','be0aff']
    readonly COLORS = ['red','blue','yellow','purple','green','orange','brown','turquoise']//'Yellow','Chartreuse Traditional','Spring Bud',
    //     'Medium Spring Green','Electric Blue','Azure','Han Purple','Electric Purple']
    filterForm: FormGroup;
    driverData = [];
    driverWaitingForLocationSelect: boolean;
    isLoadingPackages = false;
    isLoadingRoute = false;
    diverStartLocations = {};
    searchInput: any;

    constructor(private activeModal: NgbActiveModal,
                private driversService: DriversService,
                private usersService: UsersService,
                private modalService: NgbModal,
                private userService: UserService,
                private hubsService: HubsService,
                private messageService: MessageService,
                private addressSystemPipe: AddressSystemPipe,
                private utilitiesService: UtilitiesService,
                private formBuilder: FormBuilder,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.initFilterParams();
        this.initFilterForm();

        this.getDrivers(true);
        setTimeout(()=>{
            this.initMap();

        }, 30)

    }

    initFilterForm() {
        this.filterForm = this.formBuilder.group({
            isShowDeliver: [true],
            isShowPickup: [false]
        });
        this.filterForm.valueChanges.subscribe(value => {
            const filterParams = this.filterForm.getRawValue();
            this.selectedDrivers.forEach((value) => {
                if (!filterParams.isShowDeliver && !filterParams.isShowPickup) {
                    this.removeMapLayers(value);

                } else {
                    this.drawMapMarkers(value);
                }
            });
            this.getDrivers(true);
            this.getPackages(true);
        });
    }

    private initFilterParams() {
        this.filterParams = {
            DRIVERS: {
                page: 1,
                pageSize: 20,
                search: '',
                driverType: 'TYPICAL'
            },
            PACKAGES: {
                page: 1,
                pageSize: 20,
                search: '',
                driverType: 'TYPICAL'
            }
        };
    }

    private createParams(filterParams): string {
        if (!filterParams) {
            return '';
        }
        const filterKeyValues = Object.entries(filterParams);
        let result = '?';
        const filterForm = this.filterForm.value;
        if (filterForm) {
            if (filterForm.isShowPickup && filterForm.isShowDeliver) {
                result += 'carried-type=ALL';
            } else if (filterForm.isShowPickup) {
                result += 'carried-type=PICKUP';
            } else if (filterForm.isShowDeliver) {
                result += 'carried-type=DELIVERY';
            }
        }

        filterKeyValues.forEach((keyValuePair, index) => {
            if (keyValuePair[1]) {
                    result += `&${keyValuePair[0]}=${keyValuePair[1]}`;
            }

        });

        return result;
    }

    private getDrivers(forceFetch = false) {
        this.fetchedDrivers = [];
        if (this.totalDriversNo === this.drivers.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams['DRIVERS'].page = 1;
        }
        this.isLoadingDrivers = true;
        this.driversService.getDriversWithPackagesCount(this.createParams(this.filterParams['DRIVERS'])).subscribe(
            (driversResponse: { data: { id: number, firstName: string, lastName: string, packagesCount: number, latitude: number, longitude: number }[], totalRecordsNo: number }) => {
                this.fetchedDrivers = driversResponse['data'];
                this.totalDriversNo = driversResponse['totalRecordsNo'];
                if (forceFetch) {
                    this.drivers = this.fetchedDrivers;
                } else {
                    this.drivers.push(...this.fetchedDrivers);
                }
                this.isLoadingDrivers = false;
            }, (error) => {
                this.isLoadingDrivers = false;
            }
        );
    }

    private getPackages(forceFetch = false) {
        this.fetchedPackages = [];
        if (this.totalPackagesNo === this.packages.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams['PACKAGES'].page = 1;
        }
        this.isLoading = true;
        this.isLoadingPackages = true;
        this.usersService.getDriversPackages( this.createParams(this.filterParams['PACKAGES']), {ids: this.selectedDrivers}).subscribe(
            (packagesResponse) => {
                this.isLoadingPackages = false;
                this.fetchedPackages = packagesResponse['data'];
                this.totalPackagesNo = packagesResponse['totalRecordsNo'];
                if (forceFetch) {
                    this.packages = this.fetchedPackages;
                } else {
                    this.packages.push(...this.fetchedPackages);
                }
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
            }
        );
    }

    closeModal() {
        this.activeModal.close();
    }

    onTableScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyPackages();
        }
    }

    searchDriver(search: string) {
        this.filterParams['DRIVERS'].search = search;
        this.getDrivers(true);
    }

    onDriversScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyDrivers();
        }
    }

    trackDriverById(index: number, item) {
        return item.id;
    }

    selectDriver(driver: { id: number; firstName: string; lastName: string; packagesCount: number }) {
        setTimeout(()=>{
            this.selectedDriver = driver;
            if(this.selectedDrivers.indexOf(driver.id) > -1){
                this.getPackages(true);
                this.driverWaitingForLocationSelect = true;
                // this.getRoute();
                this.translateService.get(
                    ['ROUTE_OPTIMIZATION.ALERTS.CHOOSE_STARTING_POINT_FOR_DRIVER', 'ACTIONS.OK']
                )
                    .subscribe(
                        (data) => {
                            const message = data['ROUTE_OPTIMIZATION.ALERTS.CHOOSE_STARTING_POINT_FOR_DRIVER'];
                            const yes = data['ACTIONS.OK'];
                            this.confirmationService.confirm({
                                message: message,
                                accept: () => {},
                                acceptLabel: yes,
                                rejectLabel: "",
                                rejectVisible: false
                            })
                        }
                    );


            } else {
                this.removeMapLayers(driver.id)
            }
        })
    }

    private loadLazyDrivers() {
        this.filterParams['DRIVERS'].page++;
        this.getDrivers();
    }

    private loadLazyPackages() {
        this.filterParams['PACKAGES'].page++;
        this.getPackages();
    }

    onShowDriverRoute() {
        const modal = this.modalService.open(DriverRouteDialogComponent, {
            size: 'sm',
            backdrop: 'static'
        });
        modal.componentInstance.driver = this.selectedDriver;
    }

    initMap() {
        this.initialMapLocation = this.userService.getInitialMapView();
        const mapTile = (this.translateService.currentLang === 'en') ?
            'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

        this.map = new L.map('map', {
            fullscreenControl: true
        });
        const searchBounds = this.userService.getCountryBoundaries();
        const searchPlaceholder = this.translateService.instant('GENERAL.SEARCH')
        const notAllDestinationAreShownWarning = this.translateService.instant('ROUTE_OPTIMIZATION.NOT_ALL_DESTINATION_ARE_SHOWN_WARNING')

        this.searchInput = new L.esri.Geocoding.geosearch({
            providers: [
                L.esri.Geocoding.arcgisOnlineProvider({
                    apikey: SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY
                })
            ],
            useMapBounds: false,
            placeholder: searchPlaceholder,
            searchBounds
        }).addTo(this.map);

        this.searchInput.on('results', (data) => {
            const location = data.results[0];
            this.diverStartLocations[this.selectedDriver.id] = {
                lat: location.latlng.lat,
                lng: location.latlng.lng
            };
            if(this.driverWaitingForLocationSelect) {
                this.getRoute();
                this.driverWaitingForLocationSelect = false;

            }
        });

        L.tileLayer(mapTile, {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.map);
        this.map.setView([this.initialMapLocation.lat, this.initialMapLocation.lng], this.initialMapLocation.zoom);
        this.map.addEventListener('click', (event) => {
            this.map.invalidateSize();
            this.diverStartLocations[this.selectedDriver.id] = {
                lat: event.latlng.lat,
                lng: event.latlng.lng
            };
            if(this.driverWaitingForLocationSelect) {
                this.getRoute();
                this.driverWaitingForLocationSelect = false;

            }
        });

    }


    private getRoute() {
        this.isLoading = true;
        const queryParams = {
            latStart: this.diverStartLocations[this.selectedDriver.id].lat,
            longStart: this.diverStartLocations[this.selectedDriver.id].lng
        };
        this.isLoadingRoute = true;
        this.driversService.getDriverOptimizedRoute(this.selectedDriver.id, queryParams).subscribe((driverRoute: RouteModel) => {
            this.isLoadingRoute = false;
            const selectedDriverId= this.selectedDriver.id
            this.driverData[selectedDriverId] = driverRoute;
            this.route[selectedDriverId] = driverRoute;
            if (this.route[selectedDriverId] && this.route[selectedDriverId].geometry) {
                this.drawMapMarkers(selectedDriverId);
            } else {
                this.translateService.get('ROUTE_OPTIMIZATION.ALERTS.NO_ROUTE').subscribe(translateValue => {
                    this.messageService.add({
                        severity: 'error',
                        detail: translateValue
                    });
                });
                this.isLoading = false;
            }
        }, () => {
            this.isLoading = false;
        });
    }

    private drawMapMarkers(selectedDriverId) {
        this.removeMapLayers(selectedDriverId);
        this.initLocations(selectedDriverId);

        if(!this.routePolyline[selectedDriverId]){
            this.routePolyline[selectedDriverId] = [];
        }
        const color = this.COLORS.shift();
        this.COLORS.push(color);
        this.routePolyline[selectedDriverId] = L.polyline(this.locations[selectedDriverId], {color, opacity:0.4, weight:7}).addTo(this.map);
        if(!this.routePolyline[selectedDriverId].getBounds().isValid()){
            return;
        }
        this.map.fitBounds(this.routePolyline[selectedDriverId].getBounds());

        this.translateService.get(['ROUTE_OPTIMIZATION.START', 'ROUTE_OPTIMIZATION.DRIVER_NAME', 'ROUTE_OPTIMIZATION.TOTAL_PACKAGES']).subscribe(translateValues => {
            if(!this.mapMarkers[selectedDriverId]) {
                this.mapMarkers[selectedDriverId] = [];
            }
            const driver = this.drivers.find( driver => driver.id == selectedDriverId )

            const itemsString = null;
            const items: any = this.getAttributeForFilter('Items', selectedDriverId, []).filter(item => {
                if (item.location.latitude !== 0 || item.location.longitude !== 0) {

                    const filterParams = this.filterForm.getRawValue();
                    if (filterParams && filterParams.isShowDeliver && filterParams.isShowPickup) {
                        return true;
                    } else if (filterParams && filterParams.isShowPickup) {
                        return item.status != 'SCANNED_BY_DRIVER_AND_IN_CAR';
                    } else if (filterParams && filterParams.isShowDeliver) {
                        return item.status == 'SCANNED_BY_DRIVER_AND_IN_CAR';
                    }
                }
                return false;
            });
            let totalPackages = items.length;

            const popupContent = `<div style="text-align: center">` +
                `<br><b>${translateValues['ROUTE_OPTIMIZATION.DRIVER_NAME']}: ${driver.firstName} ${driver.lastName}</b>`+
                    `<br><b>${translateValues['ROUTE_OPTIMIZATION.TOTAL_PACKAGES']}: ${totalPackages}</b></div>`;
            const popup = L.popup();
            popup.setContent(popupContent);

            const startIcon = L.divIcon({
                html: `<div class="marker-start-icon"><span>${translateValues['ROUTE_OPTIMIZATION.START']}</span></div>`,
                className: 'marker-container',
                iconSize: [40, 60], // size of the icon
                iconAnchor: [20, 60], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -60] // point from which the popup should open relative to the iconAnchor
            });
            const startMarker = L.marker([this.diverStartLocations[selectedDriverId].lat, this.diverStartLocations[selectedDriverId].lng],
                {icon: startIcon}).addTo(this.map).bindPopup(popup);



            this.routePolyline[selectedDriverId].bindTooltip(popupContent,{sticky: true, interactive: true})
            this.routePolyline[selectedDriverId].on('mouseover', function (e) {
                this.setStyle({opacity:0.8});
                this.bringToFront();
            });
            this.routePolyline[selectedDriverId].on('mouseout', function (e) {
                this.setStyle({opacity:0.4});
            });
            this.mapMarkers[selectedDriverId].push(startMarker);
        });

        const packagesCountWithLocations: {
            [villageId: number]: { lat: number, lng: number, sequence: number, count: number, villageName: string, status: string }
        } = {};
        let sequence = 1;
        const items : any = this.getAttributeForFilter("Items", selectedDriverId, [])
        items.forEach(
            (pkg) => {
                if (pkg.location.latitude !== 0 || pkg.location.longitude !== 0) {
                    if (!packagesCountWithLocations[pkg.destinationAddress.villageId]) {
                        packagesCountWithLocations[pkg.destinationAddress.villageId] = {
                            lat: pkg.location.latitude,
                            lng: pkg.location.longitude,
                            sequence: sequence++,
                            count: 1,
                            villageName: pkg.status === 'SCANNED_BY_DRIVER_AND_IN_CAR' ? this.currentLang === 'en'
                                    ? pkg.destinationAddress.village : pkg.destinationAddress.arabicVillageName
                                : this.currentLang === 'en' ? pkg.originAddress.village : pkg.originAddress.arabicVillageName,
                            status: pkg.status
                        };
                    } else {
                        packagesCountWithLocations[pkg.destinationAddress.villageId].count++;
                    }
                }
            }
        );

        this.translateService.get([
            'ROUTE_OPTIMIZATION.FILTER.DELIVER',
            'ROUTE_OPTIMIZATION.FILTER.PICKUP',
            'ROUTE_OPTIMIZATION.TOTAL_PACKAGES',
            'ROUTE_OPTIMIZATION.VILLAGE_NAME']).subscribe(translateValues => {
            translateValues['ROUTE_OPTIMIZATION.VILLAGE_NAME'] =
                this.addressSystemPipe.transform(translateValues['ROUTE_OPTIMIZATION.VILLAGE_NAME']);
            if(!this.mapMarkers[selectedDriverId])
                this.mapMarkers[selectedDriverId] = [];
            Object.values(packagesCountWithLocations).forEach((value) => {
                let statusClass;
                let filterText;
                switch (value.status) {
                    case 'SCANNED_BY_DRIVER_AND_IN_CAR':
                        statusClass = 'delivery-marker';
                        filterText = translateValues['ROUTE_OPTIMIZATION.FILTER.DELIVER'];
                        break;
                    default:
                        statusClass = 'pickup-marker';
                        filterText = translateValues['ROUTE_OPTIMIZATION.FILTER.PICKUP'];
                        break;
                }
                const carIcon = L.divIcon({
                    html: `<div class="marker-icon ${statusClass}"><span>${value.sequence}</span></div>`,
                    className: 'marker-container',
                    iconSize: [30, 45], // size of the icon
                    iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
                    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor
                });
                const popupContent = `<div style="text-align: center"><b>${filterText}</b>` +
                    `<br><b>${translateValues['ROUTE_OPTIMIZATION.TOTAL_PACKAGES']}: ${value.count}</b>` +
                    `<br><b>${translateValues['ROUTE_OPTIMIZATION.VILLAGE_NAME']}: ${value.villageName}</b></div>`;
                const popup = L.popup();
                popup.setContent(popupContent);
                const marker = L.marker([value.lat, value.lng],
                    {icon: carIcon}).addTo(this.map).bindPopup(popup);

                this.mapMarkers[selectedDriverId].push(marker);
            });
        });
        this.isLoading = false;
    }

    private removeMapLayers(selectedDriverId) {
        if(this.mapMarkers[selectedDriverId]) {
            this.mapMarkers[selectedDriverId].forEach(marker => {
                this.map.removeLayer(marker);
            });
        }

        if (this.routePolyline[selectedDriverId]) {
            this.map.removeLayer(this.routePolyline[selectedDriverId]);
        }

        this.mapMarkers[selectedDriverId] = [];
    }


    private initLocations(selectedDriverId) {
        let geometry = this.getAttributeForFilter("Geometry", selectedDriverId, "");
         this.locations[selectedDriverId] = this.utilitiesService.decodePathString(geometry||'');
    }

    getAttributeForFilter(attributeName = '', selectedDriverId, deafultValue) {
        let attribute = deafultValue;
        const filterParams = this.filterForm.getRawValue();
        if (filterParams && !filterParams.isShowDeliver && filterParams.isShowPickup) {
            attribute = this.route[selectedDriverId] ? this.route[selectedDriverId]['pickup' + attributeName] : null;
        } else if (filterParams && filterParams.isShowDeliver && !filterParams.isShowPickup) {
            attribute = this.route[selectedDriverId] ? this.route[selectedDriverId]['delivery' + attributeName] : null;

        } else if (filterParams && filterParams.isShowDeliver && filterParams.isShowPickup) {
            attribute = this.route[selectedDriverId] ? this.route[selectedDriverId][attributeName.toLocaleLowerCase()] : null;
        }
        return attribute;
    }

}
