import {Component, OnInit, ViewChild} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ShipmentsService} from '../services/shipments.service';
import {DatePipe} from '@angular/common';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {FinishScanningComponent} from '../finish-scanning/finish-scanning.component';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {UsersService} from '../../users/services/users.service';
import {HubsService} from '../../shared/services/hubs.service';
import {DriversService} from '../../shared/services/drivers.service';
import {UserService} from '../../shared/services/user.service';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import * as moment from 'moment';
import {MessageService} from 'primeng/api';
import {SharedService} from '../../shared/services/shared-service';

@Component({
    selector: 'app-cod-mismatch',
    templateUrl: './cod-mismatch.component.html',
    styleUrls: ['./cod-mismatch.component.scss']
})
export class CodMismatchComponent implements OnInit {

    public isLoading = false;
    public mismatches = [];
    public pageNumber = 0;
    public totalRecords = 0;
    public searchContent = '';
    public fromDate = null;
    public toDate = null;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public selectedLanguage = '';
    selectedFilter = '';
    selectedOption: string;
    selectedReceiverFilter = '';
    public ReceiverOptions: { value: string, label: string }[];
    public hubsOptions: { value: string, label: string }[];
    public partnersOptions: { value: string, label: string }[];
    public driversOptions: { value: string, label: string }[];
    public thirdPartyOptions: { value: string, label: string }[];
    public showOptions: { value: string, label: string }[];
    public filterOptions: { value: string, label: string }[];

    @ViewChild('table') table;
    @ViewChild('showCodDetailsPopOver') showCodDetailsPopOver: NgbPopover;
    @ViewChild('datepicker') datepicker;
    @ViewChild('userFilter') userFilter;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    public dateRangPickerOptions;
    private updateCalled = false;
    private previousLabel = true;
    public carriedCodByCollectionMethod: {};
    public carriedCodByCollectionMethodKeys: string[] = [];
    public showCODDetails = false;
    public currency = '';

    constructor(
        private router: Router,
        private shipmentsService: ShipmentsService,
        private datePipe: DatePipe,
        private modalService: NgbModal,
        public usersService: UsersService,
        public userService: UserService,
        private hubsService: HubsService,
        private driversService: DriversService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private dateRangePickerService: DateRangePickerService,
        private messageService: MessageService,
        private sharedService: SharedService) {
    }

    ngOnInit() {
        this.currency = this.userService.getCurrency();
        this.initfilterOptions();
        this.setReceiverFiltersList();
        this.selectedLanguage = this.translateService.currentLang;
        this.initHubs();
        this.initPartners();
        this.initDrivers();
        this.initDrivers(true);
        this.initDates();
    }
    onShowCalenderRangePicker($event: any) {
        if (!this.updateCalled && !!this.previousLabel) {
            $event.picker.setStartDate(moment());
            $event.picker.setEndDate(moment());
            this.updateCalled = true;
            $event.picker.updateCalendars();
        }
    }
    onHideCalenderRangePicker($event: any) {
        this.updateCalled = false;
    }

    private initfilterOptions() {
        this.filterOptions = [
            {label: this.translateService.instant('ACCOUNTING.FROM_DRIVER'), value: 'driverId'},
            {label: this.translateService.instant('ACCOUNTING.FROM_HUB'), value: 'hubId'},
            {label: this.translateService.instant('ACCOUNTING.FROM_PARTNER'), value: 'partnerId'},
            {label: this.translateService.instant('ACCOUNTING.WITHOUT'), value: 'NONE'}
        ];
        if (this.userService.userInfo.hasThirdParty) {
            this.filterOptions.unshift({label: this.translateService.instant('ACCOUNTING.FROM_THIRD_PARTY'), value: 'thirdPartyId'});
        }
        this.dateRangePickerService.getDateRangePickerOptions(3, true, true , false, true, false).then(options => {
            this.dateRangPickerOptions = options;
            setTimeout(() => {
                this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.all;
            }, 30);

        });

    }

    initDates(noDateRangeLabelValue = false) {
        const date = new Date();
        const date2 = new Date();
        this.fromDate = new Date(date.setFullYear(date.getFullYear() - 3, date.getMonth(), date.getDate()));
        this.toDate = new Date(date2.setDate(date2.getDate() + 1));
        if (noDateRangeLabelValue) {
            this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
        }
    }

    public initHubs() {
        return this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }
        );
    }

    public initDrivers(isThirdParty = false) {
        let query = '?page=1&pageSize=500';
        query += '&driverType=' + (isThirdParty ? 'THIRD_PARTY' : 'TYPICAL');
        return this.driversService.getDrivers(query).subscribe(
            (response: any) => {
                this.thirdPartyOptions = response.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {label: name, value: driver.id};
                    }
                );
            }
        );
    }

    public initPartners() {
        return this.usersService.getPartners('?status=ACTIVE').subscribe(
            (response: any) => {
                this.partnersOptions = response.data.map(
                    (partner) => {
                        return {label: partner.name, value: partner.id};
                    }
                );
            }
        );
    }

    public setReceiverFiltersList(param = {}) {
        return this.shipmentsService.getAccountantAndAdmin(param).subscribe(
            (response: any) => {
                this.ReceiverOptions = response.map(
                    (receiver) => {
                        return {label: receiver.name, value: receiver.id};
                    }
                );
            }
        );
    }

    public onSearch() {
        this.pageNumber = 0;
        this.table.reset();
    }

    public initMismatches() {
        this.spinnerState = SpinnerState.LOADING;
        const params = this.createParams();
        this.shipmentsService.getCODMismatches(params).subscribe(
            (response: {
                data: any[], totalRecordsNo: number
            }) => {
                this.mismatches = response.data;
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, () => {
                this.spinnerState = SpinnerState.EMPTY;
            }
        );

    }

    public copyMessage($event, barcode) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (this.selectedOption !== '' && this.selectedFilter !== '') {
            if (this.selectedOption !== 'thirdPartyId') {
                params[this.selectedOption] = this.selectedFilter;
            } else {
                params['driverId'] = this.selectedFilter;
            }
        }
        if (this.selectedReceiverFilter !== '') {
            params['receiverId'] = this.selectedReceiverFilter;
        }
        return params;
    }

    public loadMismatchesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.initMismatches();
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public handle(mismatch) {
        const modal = this.modalService.open(FinishScanningComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.mismatchId = mismatch.id;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.initMismatches();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public updateCodMismatch(mismatch) {
        event.stopPropagation();
        this.isLoading = true;
        const modal = this.modalService.open(FinishScanningComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.mismatch = mismatch;
        modal.componentInstance.isEditMode = true;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.initMismatches();
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                }
            }
        ).catch(
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public getLocalDate(dateTime) {
        if (dateTime) {
            return new Date(dateTime).toLocaleDateString();
        }
        return;
    }

    public goToCustomerCOD(mismatch) {
        const id = (mismatch.id) ? mismatch.id : mismatch.customerId;
        const params = {};
        const url = this.authenticationService.companyName + 'home/cod-mismatch/packages/' + id;
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        params['type'] = 'MISMATCH_COD';
        this.router.navigate([url], {
            queryParams: params
        });
    }

    public printPackage(mismatch, event) {
        event.stopPropagation();
        this.isLoading = true;
        this.actuallyPrintPackage(mismatch);
    }

    public onFilterSelectedChange(id) {
        this.selectedFilter = (id === undefined ? '' : id);
        this.initMismatches();
    }

    private actuallyPrintPackage(mismatch, printType?: string) {
        const pkgId = mismatch.id;
        this.shipmentsService.downloadMismatchPdf(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
            });
    }

    onClickRadioButton() {
        this.selectedFilter = '';
        this.userFilter.selectedUser = {};
        if (this.selectedOption === 'hubId') {
            this.showOptions = this.hubsOptions;
        } else if (this.selectedOption === 'partnerId') {
            this.showOptions = this.partnersOptions;
        } else if (this.selectedOption === 'driverId') {
            this.showOptions = this.driversOptions;
        } else if (this.selectedOption === 'thirdPartyId') {
            this.showOptions = this.thirdPartyOptions;
        } else {
            this.selectedOption = '';
            this.initMismatches();
        }
    }

    onReceiverSelectedChange(id) {
        this.selectedReceiverFilter = (id === undefined ? '' : id);
        this.initMismatches();
    }

    openExcel() {
        this.isLoading = true;
        this.shipmentsService.downloadExcelForRecipt(this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        });
    }

    public exportToExcel(mismatch, event) {
        event.stopPropagation();
        this.isLoading = true;
        const pkgId = mismatch.id;
        this.shipmentsService.downloadMismatchPdf(pkgId, 'EXCEL').subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
            });
    }

    fixFraction(num) {
        if (num === null || num === undefined) {
            return 0;
        }
        const dec = num.toString().split('.')[1];
        const len = dec && dec.length >= 2 ? 2 : 0;
        return Number(num).toFixed(len);
    }

    onDateSelected(value) {
        const startDate = new Date(value.start);
        const endDate = new Date(value.end);
        this.fromDate = startDate;
        this.toDate = endDate;
        // this.filterForm.controls.fromDate.setValue(startDate);
        // this.filterForm.controls.toDate.setValue(endDate);
        // this.filterForm.controls.fromDate.updateValueAndValidity();
        // this.filterForm.controls.toDate.updateValueAndValidity();
        // this.excludeDatesFilter = false;
        this.onSearch();
        // this.initShipments(true);
        this.previousLabel = value.label;
        const {start, end} = value;
        if ((start && end) || value.label) {
            if (value.label) {
                if (value.label == this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }

    }

    printMultiPackages() {
        this.isLoading = true;
        this.shipmentsService.printMultiCodMismatch(this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, error => {
            console.error(error);
            this.isLoading = false;
        });
    }

    initCODCollection(data) {
        this.carriedCodByCollectionMethod = data.carriedCodByCollectionMethod;
        this.showCODDetails = false;
        if (this.carriedCodByCollectionMethod) {
            this.carriedCodByCollectionMethodKeys = Object.keys(this.carriedCodByCollectionMethod);
        }
    }
}
