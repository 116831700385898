import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {UpdateModel} from '../../models/update.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {updatesBadgeOp} from '../../../shared/services/shared_constants/constants';
import {AddUpdateDialogComponent} from '../add-update-dialog/add-update-dialog.component';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Observable, timer} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {PrintPackageOptionsComponent} from '../../../users/print-package-options/print-package-options.component';
import {VideoPlayerDialogComponent} from '../video-player-dialog/video-player-dialog.component';

@Component({
  selector: 'app-video-system-updates',
  templateUrl: './video-system-updates.component.html',
  styleUrls: ['./video-system-updates.component.scss']
})
export class VideoSystemUpdatesComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {
  pageSize = 10;
  totalNumberOfUpdates: any;
  isSuperAdmin: boolean;
  private rowIndex: any;
  private update: any;
  private pageNumber = 0;
  public spinnerState = SpinnerState.LOADING;
  public tableColumnCount = 4;
  public updatesData: UpdateModel[] = [];
  public spinnerStates = SpinnerState;
  public selectedLanguage = 'ar';
  @ViewChild('loadingTrigger') loadingTrigger;
  @ViewChild('updateOptions') updateOptions;
  @ViewChild('tableContainer') tableContainer;
  @Input('filter') filter: Observable<any>;
  @Input('addedOrUpdateEvent') addedOrUpdateEvent: Observable<any>;
  @Output('totalChange') totalChange = new EventEmitter<any>();

  private hasMore: Boolean | boolean = true;
  private loadingUpdates: boolean;
  private editedUpdateIndex: number;
  private isMobileResolution: boolean;
  isSearchContent: any;
  searchContent: any;
  selectedTab = 'VIDEO';
  tabs = ['VIDEO', 'DOCUMENT'];
  permissions = '';
  markAllAsSeenRequest = false;
  disableMarkAllAsRead = false;
  recentlyAdded = [];
  categories = [
    {value: '', label: 'SYSTEM_UPDATES.DROPDOWN.ALL'},
    {value: 'RETURNED_PACKAGES', label: 'SYSTEM_UPDATES.DROPDOWN.RETURNED_PACKAGES'},
    {value: 'PARTNERS', label: 'SYSTEM_UPDATES.DROPDOWN.PARTNERS'},
    {value: 'ADMINISTRATION', label: 'SYSTEM_UPDATES.DROPDOWN.ADMINISTRATION'},
    {value: 'CURRENT_REPORTS', label: 'SYSTEM_UPDATES.DROPDOWN.CURRENT_REPORTS'},
    {value: 'MANAGE_USERS', label: 'SYSTEM_UPDATES.DROPDOWN.MANAGE_USERS'},
    {value: 'MANAGE_HUBS', label: 'SYSTEM_UPDATES.DROPDOWN.MANAGE_HUBS'},
    {value: 'MANAGE_PACKAGES', label: 'SYSTEM_UPDATES.DROPDOWN.MANAGE_PACKAGES'},
    {value: 'DASHBOARD', label: 'SYSTEM_UPDATES.DROPDOWN.DASHBOARD'},
    {value: 'ACCOUNTING', label: 'SYSTEM_UPDATES.DROPDOWN.ACCOUNTING'}
  ];
  selectedCategory = '';
  SpinnerState = SpinnerState;
  unseenVideos = 0;

  constructor(private modalService: NgbModal,
              private sanitizer: DomSanitizer,
              private authenticationService: AuthenticationService,
              private router: Router,
              public translateService: TranslateService,
              private confirmService: ConfirmationService,
              private systemUpdatesService: SystemUpdatesService,
              private userService: UserService,
              private rolesService: RolesService,
              private route: ActivatedRoute,
              private applicationStateService: ApplicationStateService) {
    super();
    this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
    this.tableColumnCount = this.isSuperAdmin ? 4 : 3;
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.permissions = this.rolesService.getUserPermissions('SYSTEM_UPDATES', this.userService.userInfo.role);
  }

  ngOnInit() {
    this.selectedLanguage = this.translateService.currentLang;
    updatesBadgeOp.subscribe(val => {
      this.disableMarkAllAsRead = val === 0;
      this.unseenVideos = val;
    });
    if (this.filter) {
      this.subscriptions.push(this.filter.subscribe(filter => {
        this.searchContent = filter.search;
        this.loadUpdatesLazy(true);
      }));
    } else {
      this.loadUpdatesLazy(true);
    }
    if (this.addedOrUpdateEvent) {
      this.subscriptions.push(this.addedOrUpdateEvent.subscribe(val => {
        this.recentlyAdded = [];
        this.loadUpdatesLazy(true);
      }));
    }
  }

  loadUpdatesLazy(forceFetch = false) {
    if (forceFetch) {
      this.pageNumber = 0;
      this.updatesData = [];
      // this.recentlyAdded = [];
    }
    const params = {
      pageSize: this.pageSize,
      page: this.pageNumber + 1,
      type: this.selectedTab,
    };
    if (this.searchContent) {
      params['search'] = this.searchContent;
    }

    if (this.selectedCategory) {
      params['category'] = this.selectedCategory;
    }

    let editedPage;
    if (this.editedUpdateIndex > -1) {
      editedPage = Math.floor(this.editedUpdateIndex / 10);
      params.page = editedPage;
    }
    const tableBodyScrollTopBeforeAddingValue = this.tableContainer && this.tableContainer.nativeElement ?
        this.tableContainer.nativeElement.scrollTop : 0;
    this.spinnerState = SpinnerState.LOADING;
    this.systemUpdatesService.loadSystemUpdate(params, this.isSuperAdmin).subscribe((res: any) => {
          this.totalNumberOfUpdates = res.totalRecordsNo;
          this.totalChange.emit(this.totalNumberOfUpdates);
          if (this.editedUpdateIndex > -1) {
            this.updatesData.splice(editedPage, 10);
            this.updatesData.splice(editedPage, 0, ...res.data);
            this.editedUpdateIndex = -1;
          } else {
            this.updatesData.push(...res.data);
            this.hasMore = this.updatesData && (this.updatesData.length < res.totalRecordsNo);
          }

          this.spinnerState = SpinnerState.LOADED;
          if (this.updatesData.length === 0) {
            this.spinnerState = SpinnerState.EMPTY;
          }
          if(this.recentlyAdded.length == 0) {
            this.recentlyAdded = this.updatesData.slice(0, 4);
          }

          if (this.tableContainer && this.tableContainer.nativeElement) {
            setTimeout(() => {
              this.tableContainer.nativeElement.scrollTop = tableBodyScrollTopBeforeAddingValue;
              this.loadingUpdates = false;
            }, 30);
          }

        }, (error) => {
          this.spinnerState = SpinnerState.FAILED;
        }
    );
  }

  getURL(data) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedLanguage === 'ar' ? data.arabicVideoLink : data.videoLink);
  }

  openVideoPlayer(video: UpdateModel) {
    const modal = this.modalService.open(VideoPlayerDialogComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.data = video;
  }

  getThumbnailImage(video: UpdateModel) {
    const videoId = this.selectedLanguage === 'ar' ? video.arabicUpdateUrl : video.updateUrl;
    const url = `https://vumbnail.com/${videoId}.jpg`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoId ? videoId : url);
  }

  selectCategory(category) {
    this.selectedCategory = category.value;
    this.loadUpdatesLazy(true);
  }

  @HostListener('scroll', ['$event'])
  onsScrollUpdateTable(event, isMobile = false) {

    this.handleUpdatesLoading(event);

  }

  private handleUpdatesLoading(event) {
    if (this.loadingUpdates || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
      return;
    }

    if (event.target.offsetHeight + event.target.scrollTop + 20 > event.target.scrollHeight) {
      this.pageNumber++;
      this.loadUpdatesLazy();
      this.loadingUpdates = true;

    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  editUpdate() {
    this.updateOptions.hide();
    const modal = this.modalService.open(AddUpdateDialogComponent, <any>{
      size: 'md',
      data: this.update
    });
    this.editedUpdateIndex = this.updatesData.findIndex((value) => {
      return value.id === this.update.id;
    });
    modal.componentInstance.update = this.update;
    modal.result.then(result => {
      if (result && result.added) {
        this.recentlyAdded = [];
        this.loadUpdatesLazy(true);
      }
    });

  }

  removeUpdate() {
    this.updateOptions.hide();
    this.translateService.get(
        ['ALERTS.CONFIRM_DELETE_UPDATE', 'GENERAL.YES', 'GENERAL.NO']
    )
        .subscribe(
            (data) => {
              const message = data['ALERTS.CONFIRM_DELETE_UPDATE'];
              const yes = data['GENERAL.YES'];
              const no = data['GENERAL.NO'];
              this.confirmService.confirm({
                message: message,
                accept: () => {
                  this.systemUpdatesService.removeSystemUpdate(this.update.id).subscribe(r => {
                    this.recentlyAdded = [];
                    this.loadUpdatesLazy(true);
                  });
                },
                reject: () => {

                },
                acceptLabel: yes,
                rejectLabel: no
              });
            }
        );

  }

  triggerUpdateDropdown($event: MouseEvent, dropdown: OverlayPanel, update) {
    this.update = update;
    dropdown.toggle($event);
    $event.stopPropagation();
  }
}
