import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ReportsService {

    constructor(private httpClient: HttpClient) {
    }

    public formatUndeliveredPackagesData(response) {
        if (response.data && response.data.length > 0) {
            response.data.forEach(row => {
                row.deliveryZone = `${row.destinationAddress.country}, ${row.destinationAddress.region}, ${row.destinationAddress.city}`;
            });
        }
        return response.data;
    }

}
