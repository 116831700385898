<div [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div class="main-container" *ngIf="!isLoading">
        <div class="ticket-title">
            <span>{{data.title}}</span>
        </div>
        <div class="history-details-section">
            <div class="history-items-list-container">
<!--                <div  class="item-container d-flex">-->
<!--                    <div class="user-image">-->
<!--                        <app-upload-image-component-->
<!--                                class="user-image-upload"-->
<!--                                [showClear]="false"-->
<!--                                [canUpload]="false"-->
<!--                                [imageSize]="50"-->
<!--                                [defaultLogo]="'../../../../assets/icons/ticketing-system/user-avatar.png'"-->
<!--                        >-->
<!--                        </app-upload-image-component>-->
<!--                    </div>-->
<!--                    <div class="item-info-container d-flex">-->
<!--                        <div class="user-name log-label">{{data.createdBy.firstName + ' ' + data.createdBy.lastName}}</div>-->
<!--                        <div class="log-info d-flex">-->
<!--                            <div class="log-item d-flex">-->
<!--                                <div class="log-text log-label">{{'TICKETING_SYSTEM.CREATED_DATE_TITLE' | translate}}</div>-->
<!--                                <div class="log-date-and-time">{{data.createdDate | date:'dd/MM/yyyy HH:mm'}}</div>-->
<!--                            </div>-->
<!--                            <div class="first-log-details d-flex">-->
<!--                                <div class="log-label">{{'TICKETING_SYSTEM.STATUS_AS' | translate}}  </div>-->
<!--                                <div class="log-info-tag log-label">{{data.status | translate}}</div>-->

<!--                                <div class="log-label">{{'TICKETING_SYSTEM.AND_PRIORITY' | translate}}</div>-->
<!--                                <div class="log-info-tag log-label">{{data.priority | translate}}</div>-->

<!--                                <div class="log-label">{{'TICKETING_SYSTEM.AND_CATEGORY' | translate}}</div>-->
<!--                                <div class="log-info-tag log-label"> {{data.category.name}}</div>-->

<!--                                <ng-container *ngIf="data && data.assignee">-->
<!--                                    <div class="log-label">{{'TICKETING_SYSTEM.AND_ASSIGNEE' | translate}}</div>-->
<!--                                    <div class="log-info-tag log-label">{{data?.assignee?.firstName + ' ' + data?.assignee?.lastName}}</div>-->
<!--                                </ng-container>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="item-container d-flex" *ngFor="let log of actions">
                    <div class="user-image">
                        <app-upload-image-component
                                class="user-image-upload"
                                [showClear]="false"
                                [canUpload]="false"
                                [imageSize]="50"
                                [defaultLogo]="'../../../../assets/icons/ticketing-system/user-avatar.png'"
                        >
                        </app-upload-image-component>
                    </div>
                    <div class="item-info-container d-flex">
                        <div class="user-name log-label">{{log.userName}}</div>
                        <div class="log-info d-flex">
                            <div class="log-item d-flex">
                                <div class="log-text log-label">{{log.action}}</div>
                                <div class="log-date-and-time">{{log.time | date:'dd/MM/yyyy HH:mm'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="end-history-point">
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="loading-container" *ngIf="isLoading">
        <i class="fas fa-spinner fa-pulse"></i>
    </div>
</div>
