import {Component, EventEmitter, Input, Output} from '@angular/core';
import {budget} from '@angular/fire/compat/remote-config';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() class: string;
  @Input() icon: string;
  @Input() iconPlace = 'before';
  @Input() badge: any;
  @Input() disable = false;
  @Input() label: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
}
