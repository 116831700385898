import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';

import * as moment from 'moment';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {ChoosePaymentTypeComponent} from '../../../users/choose-payment-type/choose-payment-type.component';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {UsersService} from '../../../users/services/users.service';
import {UserService} from '../../../shared/services/user.service';
import {BulkUpdateComponent} from '../../../shipment/bulk-update/bulk-update.component';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {AddShipmentComponent} from '../../../shipment/add-shipment/add-shipment.component';
import {EXPORTED_DATE_FILTERS_LIST} from '../../../shipment/services/constants';
import {ACCOUNTANT_REPORT_ORDER_BY} from '../../../ticketing-system/services/constants';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {SharedService} from '../../../shared/services/shared-service';

@Component({
    selector: 'app-cod-customer',
    templateUrl: './cod-customer.component.html',
    styleUrls: ['./cod-customer.component.scss']
})
export class CodCustomerComponent implements OnInit {
    /**
     *
     */
    public packages: any[];

    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public customerId;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;

    public pageNumber = 0;
    public totalRecords = 0;
    public costSum = 0;
    public codSum = 0;
    public searchContent = '';
    public fromDate;
    public toDate;
    public type;
    public debounceUpdateCost;
    public sort = false;
    public export = false;
    public receive = false;
    public inCar = false;
    public mismatch = false;
    public selectedLanguage = '';
    public isBankTransfer = false;
    public withoutType = false;
    public enableEditCost = false;
    public first = 0; // firstElement in paginator
    public params = {};
    public isAllSelected = false;
    public isThereSelection = false;
    @ViewChild('table') table;
    public selectedDateFilter = '';
    public dateFiltersList;
    public isDeliveredPage = false;
    public isInCarPage = false;
    public isHoldCustody = false;
    public codSumWithoutCost = 0;
    public totalCost = 0;
    public totalCOD = 0;
    public selectedPkgCost = 0;
    public selectedPkgCod = 0;
    public selectedPkgCodWithoutCost = 0;
    public selectedPkgNumber = 0;
    public customerName = '';
    public holdCustodyBy = '';
    public entityName = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
    public digitFormat = '1.0-2';
    public displayAddPackage = false;
    public selectedPackageBarcode = null;
    public dateRangPickerOptions;
    public orderByOptions;
    public selectedOrderByOption;

    showSumValues = false;
    customExport;
    utcOffset;
    showHubCost: any;
    private userInfo: any;
    permissions;
    isArchived;
    isShowCodFees = false;

    constructor(private router: Router,
                private usersService: UsersService,
                private confirmService: ConfirmationService,
                private messageService: MessageService,
                public translateService: TranslateService,
                private modalService: NgbModal,
                private rolesService: RolesService,
                private route: ActivatedRoute,
                private datePipe: DatePipe,
                private shipmentsService: ShipmentsService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private applicationStateService: ApplicationStateService,
                private sharedService: SharedService,
                private dateRangePickerService: DateRangePickerService) {
    }

    ngOnInit() {
        this.userInfo = this.userService.userInfo;
        this.isShowCodFees = this.userInfo.currency === 'SAR';
        this.permissions = this.rolesService.getUserPermissions('COD_MISMATCH', this.userInfo.role);
        this.getOrderByOptions();
        this.utcOffset = this.sharedService.getUtcOffset();
        this.customerId = this.route.snapshot.paramMap.get('id');
        this.selectedLanguage = this.translateService.currentLang;
        this.route.queryParams.subscribe(
            (params: Params) => {
                this.params = params;
                if (params.fromDate) {
                    this.fromDate = new Date(params.fromDate);
                }
                if (params.toDate) {
                    this.toDate = new Date(params.toDate);
                }
                if (params.page && params.page === 'IN_CAR') {
                    this.inCar = true;
                }
                if (params.is_hold_custody) {
                    this.isHoldCustody = params.is_hold_custody;
                    if (params.by) {
                        this.holdCustodyBy = params.by;
                    }
                }
                if (params.type) {
                    this.type = params.type;
                    if (this.type === 'RECEIVE') {
                        this.receive = true;
                    } else {
                        if (this.type === 'SORT') {
                            this.sort = true;
                        } else {
                            if (this.type === 'EXPORT') {
                                this.export = true;
                            } else {
                                // if (this.type === 'IN_CAR') {
                                //     console.log('sssssss');
                                //     this.inCar = true;
                                //     this.sort = true;
                                // }
                                if (this.type === 'MISMATCH_COD') {
                                    this.mismatch = true;
                                    this.showHubCost = this.userInfo.role === 'HUB_ADMIN' && this.userInfo.isEnableExportingBetweenHubs;
                                }
                            }
                        }
                    }
                } else {
                    this.withoutType = true;
                }
                if (params.isTransfer) {
                    this.isBankTransfer = true;
                }
                if (params.first) {
                    this.first = params.first;
                }
                if (params.pageSize) {
                    this.pageSize = params.pageSize;
                }
                if (params.search) {
                    this.searchContent = params.search;
                }
                if (params.page && params.page === 'DELIVERED') {
                    this.isDeliveredPage = true;
                }
                if (params.isArchived) {
                    this.isArchived = params.isArchived;
                }
                this.dateFiltersList = this.setDateFiltersList();
                this.initDateFilter(params.dateFilterType, params.fromDate, params.toDate);
            }
        );
        this.route.url.subscribe((data: any) => {
            const enableEditCost = data.find(element => element.path === 'receive-cod' || element.path === 'sort-cod');
            if (enableEditCost !== undefined) {
                this.enableEditCost = true;
            }
        });
        this.customExport = (this.route.snapshot.data['customExport'] === undefined
            ? false : this.route.snapshot.data['customExport']);
    }

    /**
     * Initialize the permissions allowed by the logged in user.
     */

    /**
     *
     */
    private initPackages() {
        this.spinnerState = SpinnerState.LOADING;
        const params = this.createParams();
        if (this.isBankTransfer) { // Bank transfer COD
            this.usersService.getTransferCODPackages(this.customerId, params).subscribe(
                (response: { data?: any[], totalRecordsNo: number }) => {
                    this.packages = response.data;
                    this.totalRecords = response.totalRecordsNo;
                    if (this.totalRecords > 0) {
                        this.spinnerState = SpinnerState.LOADED;
                    } else {
                        this.spinnerState = SpinnerState.EMPTY;
                    }
                    this.isThereSelection = false;
                }
            );
        } else {
            this.usersService.getCodPackages(this.customerId, params, this.type, this.isHoldCustody).subscribe(
                (response: { codpackages?: any[], data?: any[], totalRecordsNo: number, costSum: number, codSum: number, name: string }) => {
                    this.packages = response.codpackages ? response.codpackages : response.data;
                    this.totalRecords = response.totalRecordsNo;
                    this.entityName = response.name;
                    if (this.isHoldCustody) {
                        this.packages = response.data;
                    }
                    if (this.type !== 'RECEIVE' && this.type !== 'SORT' && this.type !== 'EXPORT' && this.type !== 'MISMATCH_COD') {
                        this.codSum = response.codSum;
                        this.costSum = response.costSum;
                        this.totalCOD = response.codSum;
                        this.codSumWithoutCost = response.codSum - response.costSum;
                        this.totalCost = response.costSum;
                    }

                    if (this.totalRecords > 0) {
                        this.spinnerState = SpinnerState.LOADED;
                    } else {
                        this.spinnerState = SpinnerState.EMPTY;
                    }
                    this.isThereSelection = false;
                }
            );
        }
        if (!this.isHoldCustody) {
            this.initCustomerSumValues();
        }
        this.selectedPkgNumber = 0;
        this.selectedPkgCodWithoutCost = 0;
        this.selectedPkgCost = 0;
        this.selectedPkgCod = 0;
    }

    private initCustomerSumValues() {
        const params = {};
        let getSumValues;
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            params['dateFilterType'] = this.selectedDateFilter;
        } else {
            delete params['dateFilterType'];
        }
        if (this.isArchived && this.isArchived == 'true') {
            params['isArchived'] = true;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        } else {
            delete params['fromDate'];
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        } else {
            delete params['toDate'];
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else {
            delete params['timezone'];
        }

        if (this.receive) {
            params['status'] = '';
            params['customerId'] = this.customerId;
            if (!this.isHoldCustody) {
                getSumValues = this.usersService.getReciveCustomerSumValues(params);
            }
        } else {
            if (this.sort) {
                if (this.inCar) {
                    params['status'] = 'WITH_DRIVER';
                } else if (this.isDeliveredPage) {
                    params['status'] = 'DELIVERED';
                } else {
                    params['status'] = 'SORTED_BY_ACCOUNTANT';
                }
                // params['status'] = this.isDeliveredPage ? 'DELIVERED' : 'SORTED_BY_ACCOUNTANT';
                params['massCodPackageId'] = this.customerId;
                getSumValues = this.usersService.getCostumersSumValues(params);
            } else if (this.export) {
                params['status'] = 'EXPORTED_BY_ACCOUNTANT';
                params['massCodPackageId'] = this.customerId;
                getSumValues = this.usersService.getCostumersSumValues(params);
            }
        }
        if (getSumValues) {
            getSumValues.subscribe(
                (response: any) => {
                    this.totalRecords = response.totalPackagesNumber;
                    this.codSumWithoutCost = response.totalCodWithoutCost;
                    this.totalCOD = response.totalCod;
                    this.totalCost = response.totalCost;
                    this.customerName = response.customerName;
                    this.selectedPkgNumber = 0;
                    this.selectedPkgCodWithoutCost = 0;
                    this.selectedPkgCost = 0;
                    this.selectedPkgCod = 0;
                }
            );
        }
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };
        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }

        if (!this.sort && !this.export && !this.receive && !this.isBankTransfer && !this.mismatch && this.customerId) {
            params['customerId'] = this.customerId;
        }
        if (!this.sort && !this.export && !this.receive && this.type && !this.mismatch) {
            params['type'] = this.type;
        }
        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            params['dateFilterType'] = this.selectedDateFilter;
            params['utcOffset'] = moment().utcOffset().toString();
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (this.isHoldCustody) {
            params['type'] = this.holdCustodyBy;
            params['entityId'] = this.customerId;
        }
        if (this.selectedOrderByOption) {
            params['order-by'] = this.selectedOrderByOption.value;
        }

        if(this.isArchived) {
            params['isArchived'] = this.isArchived;
        }
        return params;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.first = $event.first;
        this.initPackages();
    }


    public onSearch() {
        this.pageNumber = 0;
        this.first = 0;
        this.setQueryParams();
        this.table.reset();
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    downloadPdf() {
        this.isLoading = true;
        const pkgId = this.customerId;
        this.shipmentsService.downloadMassPackagePdf(pkgId,  null, null,  null,  this.isArchived).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.isLoading = false;
            });
    }

    public showPackage(barcode) {
        this.displayAddPackage = true;
        this.selectedPackageBarcode = barcode;
    }

    public openPackageBarcode(id) {
        const modal = this.modalService.open(AddShipmentComponent, {
            backdrop: 'static',
            windowClass: 'create-new-package',
        });
        modal.componentInstance.pkgId = id;
        modal.result.then(
            () => {
            }
        ).catch(
            (error) => {
            }
        );
    }

    public setPaginatorQueryParam() {
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParams = {...this.params};
        if (this.searchContent) {
            queryParams['search'] = this.searchContent;
        } else {
            delete queryParams['search'];
        }

        if (this.selectedDateFilter && this.selectedDateFilter !== 'NONE') {
            queryParams['dateFilterType'] = this.selectedDateFilter;
        } else {
            delete queryParams['dateFilterType'];
        }

        if (this.fromDate) {
            queryParams['fromDate'] = this.fromDate;
        } else {
            delete queryParams['fromDate'];
        }

        if (this.toDate) {
            queryParams['toDate'] = this.toDate;
        } else {
            delete queryParams['toDate'];
        }

        if (this.checkNeedsReset()) {
            this.first = 0;
            this.pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
        }
        queryParams['first'] = this.first;
        queryParams['pageSize'] = this.pageSize;
        this.router.navigate([], {queryParams: queryParams}).then(() => {

        });
    }

    private checkNeedsReset() {
        const queryParams = {...this.params};
        let boolFromDate = false;
        let boolToDate = false;
        if (queryParams['fromDate'] !== undefined && this.fromDate) {
            boolFromDate = new Date(this.fromDate).getTime() !== new Date(queryParams['fromDate']).getTime();
        }
        if (queryParams['toDate'] !== undefined && this.toDate) {
            boolToDate = new Date(this.toDate).getTime() !== new Date(queryParams['toDate']).getTime();
        }
        return (queryParams['search'] !== undefined && this.searchContent !== queryParams['search']) ||
            boolFromDate || boolToDate;
    }

    public onToggleSelectAll(event) {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }

        this.isThereSelection = false;
        this.packages.forEach((pkg: any) => {
            if (selected) {
                pkg.isSelected = selected;
                this.isThereSelection = true;

            } else {
                pkg.isSelected = selected;
            }
        });
        this.getSumValuesOfSelectedPackages();
    }

    public onToggleSelection(event) {
        const selectedPackages = this.getSelectedPackages();
        if (selectedPackages) {
            this.isThereSelection = selectedPackages.length !== 0;
            this.isAllSelected = selectedPackages.length === this.packages.length;
        }
        this.getSumValuesOfSelectedPackages();
    }

    private getSelectedPackages() {
        const selectedPackages = [];
        if (!this.packages) {
            return;
        }

        this.packages.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }

    public getSeletecPackagesCODSum() {
        const packages = this.getSelectedPackages();
        let codSum = 0;
        let costSum = 0;
        if (packages) {
            for (const shipment of packages) {
                codSum += shipment.cod;
                costSum += shipment.cost;
            }
        }
        return codSum - costSum;
    }

    public deliverSelected() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.codPackageId);
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, transferFees: number }) => {
                if (data.paymentType) {
                    const body = {
                        ids: selectedPackages, paymentType: data.paymentType
                    };
                    if (data.documentNumber) {
                        body['documentNumber'] = data.documentNumber;
                    }
                    if (data.deliveryDate) {
                        body['deliveryDate'] = this.transformDate(data.deliveryDate);
                    }
                    if (data.transferFees) {
                        body['transferFees'] = data.transferFees;
                    }
                    this.usersService.deliverSelected(this.customerId, body).subscribe((res: any) => {
                        this.translateService.get(
                            'ALERTS.PACKAGES_DELIVERED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (message) => {
                                    this.messageService.add({severity: 'success', detail: message});
                                    this.initPackages();
                                }
                            );
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            });
    }

    public partialExport() {
        this.isLoading = true;
        this.translateService.get(['ALERTS.PARTIAL_CONFIRM', 'GENERAL.YES', 'GENERAL.NO']).subscribe((res: any) => {
            this.confirmService.confirm({
                message: res['ALERTS.PARTIAL_CONFIRM'],
                reject: () => {
                    this.isLoading = false;
                },
                accept: () => {
                    this.partialExportSend();
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public partialExportSend() {
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.codPackageId);
        const body = {
            ids: selectedPackages
        };
        this.usersService.partialDeliver(this.customerId, body).subscribe((res: any) => {
            this.partialExportedSuccessfully(res);
        }, () => {
            this.isLoading = false;
        });
    }

    public setDateFiltersList() {
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
            if (this.fromDate) {
                this.dateRangPickerOptions.startDate = moment(this.fromDate);
            }
            if (this.toDate) {
                this.dateRangPickerOptions.endDate = moment(this.toDate);
            }
        });

        return EXPORTED_DATE_FILTERS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    private resetDates() {
        this.fromDate = this.toDate = '';
    }

    private setDates(fromDate, toDate) {
        if (fromDate) {
            this.fromDate = fromDate;
        }
        if (toDate) {
            this.toDate = toDate;
        }
    }

    public initDateFilter(dateFilterType, fromDate, toDate) {
        if (dateFilterType !== undefined && EXPORTED_DATE_FILTERS_LIST.includes(dateFilterType)) {
            this.selectedDateFilter = dateFilterType;
        } else {
            if (this.export || this.isDeliveredPage) {
                this.selectedDateFilter = 'NONE';
                this.resetDates();
            } else {
                if (this.sort) {
                    this.selectedDateFilter = 'SORT_DATE';
                } else if (this.receive) {
                    this.selectedDateFilter = 'RECEIVE_DATE';
                } else {
                    this.selectedDateFilter = 'CREATED_DATE';
                }
                this.setDates(fromDate, toDate);
            }
        }

    }

    public onDateFilterChange() {

        if (this.selectedDateFilter === 'NONE') {
            this.fromDate = '';
            this.toDate = '';
            this.setQueryParams();
            this.initPackages();
            if (!this.isHoldCustody) {
                this.initCustomerSumValues();
            }
        } else {
            if (this.fromDate || this.toDate) {
                this.setQueryParams();
                this.initPackages();
                if (!this.isHoldCustody) {
                    this.initCustomerSumValues();
                }
            }
        }

    }

    public resetSelectedPkgSumValues() {
        this.selectedPkgCost = 0;
        this.selectedPkgCod = 0;
        this.selectedPkgCodWithoutCost = 0;
    }

    public getSumValuesOfSelectedPackages() {
        this.resetSelectedPkgSumValues();
        const selectedPackages = this.getSelectedPackages();
        this.selectedPkgNumber = selectedPackages.length;
        selectedPackages.forEach(
            (pkg) => {
                this.selectedPkgCost += pkg.cost;
                this.selectedPkgCod += pkg.cod;
            }
        );
        this.selectedPkgCodWithoutCost = this.selectedPkgCod - this.selectedPkgCost;
    }

    public showDeliverSelectedButton() {
        return this.export && this.userService.userInfo.isDeliverPartialMassReport;
    }

    public addBarcodsToSoretedMass() {
        const modal = this.modalService.open(BulkUpdateComponent, {backdrop: 'static', windowClass: 'create-new-container', size: 'lg'});
        modal.componentInstance.isChangeStatus = false;
        modal.componentInstance.isReceive = true;
        modal.componentInstance.isCodMassPackage = false;
        modal.componentInstance.exportBulkBarcodes = true;
        modal.componentInstance.customerId = this.customerId;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                // console.log(status.isSuccess);
                let successMessage = '';
                let errorMessage = '';
                this.translateService.get(
                    ['ALERTS.BULK_EXPORT_SUCCESSFUL', 'ALERTS.BULK_EXPORT_FAILED']
                ).subscribe(
                    (data) => {
                        successMessage = data['ALERTS.BULK_EXPORT_SUCCESSFUL'];
                        errorMessage = data['ALERTS.BULK_EXPORT_FAILED'];
                    }
                );
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: successMessage});
                    this.initPackages();
                    if (!this.isHoldCustody) {
                        this.initCustomerSumValues();
                    }
                } else {
                    this.messageService.add({severity: 'error', detail: errorMessage});
                }
            }
        ).catch(
            (error) => {
            }
        );

    }
    public bulkSortByBarcode() {
        const modal = this.modalService.open(BulkUpdateComponent, {backdrop: 'static', windowClass: 'create-new-container', size: 'lg'});
        modal.componentInstance.isChangeStatus = false;
        modal.componentInstance.isReceive = true;
        modal.componentInstance.isCodMassPackage = false;
        modal.componentInstance.exportBulkBarcodes = false;
        modal.componentInstance.receiveBulkBarcodes = true;
        modal.componentInstance.title = 'GENERAL.SORT_BULK_BARCODES';
        modal.componentInstance.customerId = this.customerId;
        let successMessage = '';
        let errorMessage = '';
        this.translateService.get(
            ['ALERTS.BULK_COD_SORT_SUCCESSFUL', 'ALERTS.BULK_COD_SORT_FAILED']
        ).subscribe(
            (data) => {
                successMessage = data['ALERTS.BULK_COD_SORT_SUCCESSFUL'];
                errorMessage = data['ALERTS.BULK_COD_SORT_FAILED'];
            }
        );
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: successMessage});
                    this.initPackages();
                    if (!this.isHoldCustody) {
                        this.initCustomerSumValues();
                    }
                }
            }
        ).catch(
            (error) => {
                this.messageService.add({severity: 'error', detail: errorMessage});
            }
        );

    }
    public sortPackage(event) {
        event.stopPropagation();
        this.isLoading = true;
        let pkgIds = [];
        if (this.getSelectedPackages().length > 0) {
            // console.log(this.getSelectedPackages());
            pkgIds = this.getSelectedPackages().map(pkg => pkg.barcode);
        }
        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        if (this.isHoldCustody) {
            this.shipmentsService.receivePackagesWithoutReleasingCustody(pkgIds).subscribe((res: any) => {
                    this.translateService.get(
                        'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                                this.initPackages();
                                if (!this.isHoldCustody) {
                                    this.initCustomerSumValues();
                                }
                            }
                        );
                    this.isLoading = false;
                },
                error => {
                    this.isLoading = false;
                });
        } else {
            this.shipmentsService.sortPackages(pkgIds, this.customerId).subscribe((res: any) => {
                    this.translateService.get(
                        'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                                this.initPackages();
                                this.initCustomerSumValues();
                            }
                        );
                    this.isLoading = false;
                },
                error => {
                    this.isLoading = false;
                });
        }
    }

    openExcel() {
        this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
                accept: () => {
                    this.isLoading = true;
                    (this.customExport ? this.shipmentsService.exportCodReportForCustomer(this.customerId, this.createParams()) : this.shipmentsService.exportToExcel(this.customerId)).subscribe((res: any) => {
                        window.open(res.url, '_blank');
                        this.isLoading = false;
                    },( error ) => {
                        this.isLoading = false;
                    });
                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });

    }

    public onHideAddPackage(event) {
        this.displayAddPackage = event;
        this.selectedPackageBarcode = null;
    }

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.onSearch();
    }
    getOrderByOptions() {
        this.translateService.get(ACCOUNTANT_REPORT_ORDER_BY).subscribe((labels) => {
            this.orderByOptions = ACCOUNTANT_REPORT_ORDER_BY.map((value) => ({
                label: labels[value],
                value
            }));
            this.selectedOrderByOption = this.orderByOptions[0];
        });
    }
    onChangeOrderBy() {
        this.initPackages();
        if (!this.isHoldCustody) {
            this.initCustomerSumValues();
        }
    }

    triggerMobileActions(event, dropdown) {
        dropdown.toggle(event);
    }
    isMobile() {
        return this.applicationStateService.getIsMobileResolution();
    }
    getCustody(pkg) {
        let custody = '';
        if (pkg.hubName) {
            custody = this.translateService.instant('ACCOUNTING.IN_HUB') + ' '  +  pkg.hubName;
        } else if (pkg.driverName) {
            custody = this.translateService.instant('ACCOUNTING.IN_CAR') + ' '  +  pkg.driverName;
        } else if (pkg.partnerName) {
            custody = this.translateService.instant('ACCOUNTING.WITH_PARTNER') + ' '  +  pkg.partnerName;
        }
        return custody;
    }

    isShowCommissionFees() {
        return this.userInfo.isEnableCustomerCommissionFee && (this.receive || this.sort || this.export);
    }

    private partialExportedSuccessfully(pkg) {
        this.confirmService.confirm({
            message: this.translateService.instant('ALERTS.PRINT_MASS_COD_REPORT_CONFIRMATION'),
            reject: () => {
                this.isLoading = false;
            },
            accept: () => {
                this.shipmentsService.downloadMassPackagePdf(pkg.id,  null, null,  null,  this.isArchived).subscribe((res: any) => {
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translateService.instant('ALERTS.PACKAGES_EXPORTED_SUCCESSFULLY')
                    });
                    this.isLoading = false;
                    window.open(res.url, '_blank');
                    this.initPackages();
                    if (!this.isHoldCustody) {
                        this.initCustomerSumValues();
                    }
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    private test(res) {

    }
}
