import {Component, OnInit, ViewChild} from '@angular/core';
import {ShipmentsService} from '../services/shipments.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {HubTypes} from '../../shared/services/shared_constants/enums';

@Component({
    selector: 'app-export-shipments',
    templateUrl: './export-shipments.component.html',
    styleUrls: ['./export-shipments.component.scss']
})
export class ExportShipmentsComponent implements OnInit {
    isLoading = false;
    currentLang: string;
    selectedTab = 'PARTNER';
    id;
    isEnableExportingBetweenHubs;
    isAuthorizedExportToHub = false;

    @ViewChild('userFilter') userFilter;
    HubTypes = HubTypes;

    constructor(private shipmentsService: ShipmentsService,
                private userService: UserService,
                private rolesService: RolesService,
                private translate: TranslateService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isAuthorizedExportToHub = (this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', this.userService.userInfo.role).includes('EXPORT_PACKAGE_TO_HUB'));
        if (this.userService.isHubUserRole) {
            this.selectedTab = 'HUB';
        }
    }

    onChangeTab(tab) {
        this.userFilter.selectedUser = null;
        this.id = null;
        this.selectedTab = tab;
    }

    onUserFilterChanged(id) {
        this.id = id;
    }

    public closeModal(result = null) {
        this.activeModal.close(result);
    }

}
