export interface IExcelShipmentModel {
    codCollectionMethod?: any;
    senderName?: string;
    senderBusinessName?: string;
    businessReceiverName?: string;
    senderEmail?: string;
    receiverName?: string;
    receiverMobile?: string;
    receiverMobile2?: string;
    receiverVillage?: string;
    receiverCity?: string;
    receiverStreetAddress?: string;
    receiverBuilding?: string;
    receiverFlat?: string;
    notes?: string;
    cod?: number;
    shipmentType?: string;
    serviceType?: string;
    vehicleType?: string;
    invoiceNumber?: string;
    supplierInvoice?: string;
    description?: string;
    quantity?: number;
    codCollectionType?: string;
}

export class ExcelShipmentModel implements IExcelShipmentModel {
    constructor(
        public senderEmail?: string,
        public senderName?: string,
        public senderBusinessName?: string,
        public receiverName?: string,
        public receiverMobile?: string,
        public receiverVillage?: string,
        public businessReceiverName?: string,
        public receiverCity?: string,
        public receiverStreetAddress?: string,
        public receiverBuilding?: string,
        public receiverFlat?: string,
        public notes?: string,
        public cod?: number,
        public shipmentType?: string,
        public serviceType?: string,
        public vehicleType?: string,
        public invoiceNumber?: string,
        public supplierInvoice?: string,
        public description?: string,
        public codCollectionMethod?: string,
        public codCollectionType?: string
    ) {
    }
}

export interface IExcelShipmentRow {
    rowId: number;
    shipmentInfo: IExcelShipmentModel;
}

export function getNumericHeaders(): string[] {
    const numericHeaders = [];
    const keys = Object.keys(new ExcelShipmentModel()).length;
    for (let i = 0; i < keys; ++i) {
        numericHeaders.push(`${i}`);
    }
    numericHeaders.push(`${numericHeaders.length}`);
    return numericHeaders;
}

export function getNumericHeadersForNaouri(keys): string[] {
    const numericHeaders = [];
    for (let i = 0; i < keys; ++i) {
        numericHeaders.push(`${i}`);
    }
    numericHeaders.push(`${numericHeaders.length}`);
    return numericHeaders;
}
