import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HubsService} from '../../../shared/services/hubs.service';
import {DatePipe} from '@angular/common';
import {DriversService} from '../../../shared/services/drivers.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContainersService} from '../../../containers/services/containers.service';
import {UsersService} from '../../../users/services/users.service';
import {UserService} from '../../../shared/services/user.service';
import {ZonesService} from '../../services/zones.service';

@Component({
    selector: 'app-edit-city-prefix-dialog',
    templateUrl: './edit-city-prefix-dialog.component.html',
    styleUrls: ['./edit-city-prefix-dialog.component.scss']
})
export class EditCityPrefixDialogComponent implements OnInit {
    currentLang: string;
    public isLoading = false;
    public isSubmitting = false;
    public showPrefix = false;
    public showCityColor = false;
    form;
    city;

    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private hubService: HubsService,
                private datePipe: DatePipe,
                private driversService: DriversService,
                public translateService: TranslateService,
                public messageService: MessageService,
                private activeModal: NgbActiveModal,
                public zonesService: ZonesService,
                private containersService: ContainersService,
                private confirmationService: ConfirmationService,
                private usersService: UsersService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        this.currentLang = this.translateService.currentLang;
        this.initForm();

    }

    public closeModal() {
        this.activeModal.close();
    }

    private initForm() {
        this.form = this.formBuilder.group({
            prefix: [this.city.prefix],
            color: [this.city.color]
        });
    }

    public addContainer() {
        if (this.form.invalid) {
            return;
        }
        this.isSubmitting = true;
        this.submitForm();
    }


    private submitForm() {
        const body = this.form.getRawValue();
        this.zonesService.updateCityPrefix(this.city, body).subscribe(res => {
                this.messageService.add({severity: 'success', detail: this.translateService.instant('ADMINISTRATION.CITY_PREFIX_UPDATED_SUCCESSFULLY')});
                this.city.prefix = body.prefix;
                this.city.color = body.color;
                this.activeModal.close();
                this.isSubmitting = false;
            },
            error => {
                this.isSubmitting = false;
            });
    }
}
