import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-field-set-form',
  templateUrl: './field-set-form.component.html',
  styleUrls: ['./field-set-form.component.scss']
})
export class FieldSetFormComponent implements OnInit {
  @Input() cssStyle = {};
  @Input() label: string;
  @Input() isFilter = false;
  @Input() filterActive = false;
  @Input() showLabel = true;
  @Input() disabled = false;
  @Input() formControlObj: FormControl;

  constructor() { }

  ngOnInit(): void {
  }

}
