import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-shipped-packages-detailed-report',
    templateUrl: './shipped-packages-detailed-report.component.html',
    styleUrls: ['./shipped-packages-detailed-report.component.css']
})
export class ShippedPackagesDetailedReportComponent {
    public shippedPackagesReport = REPORTS_CONSTANTS.DETAILED_REPORTS.SHIPPED_PACKAGES_REPORT;
    public shippedPackagesReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.SHIPPED_PACKAGES_REPORT_API;

    constructor() {

    }
}
