import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {UsersService} from '../../../users/services/users.service';
@Component({
    selector: 'app-image-slider',
    templateUrl: 'image-slider.component.html',
    styleUrls: ['image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {
    @Input() images: string[] = [];
    @Input() showHeader = true;
    public isModal = false;
    public currentLang = '';
    public url = null;
    public isLoading = false;
    public customerId;
    public isSignature = false;
    public showActions = false;
    constructor(private translateService: TranslateService,
                private activeModal: NgbActiveModal,
                private messageService: MessageService,
                private usersService: UsersService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        if (!this.images.length) {
            this.images.push('../../assets/icons/noImage.jpg');
        }
        this.addExistCustomerImage();
    }

    public addExistCustomerImage() {
        if (this.url !== null && this.url !== undefined) {
            this.images.push(this.url);
        }
    }

    public onFileSelected($event: any) {
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        if (!selectedFile) {
            return;
        }
        this.isLoading  = true;
        formData.append('file', selectedFile);
        if (selectedFile.name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {
            this.usersService.addCustomerAttachment(this.customerId, formData).subscribe(
                (response: any) => {
                    this.url = response.fileUrl;
                    this.images = [];
                    this.images.push(response.fileUrl);
                    this.isLoading  = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
            this.isLoading = false;
        }
    }
    public addImage(fileChooser) {
        if (this.isRemoveMode()) {
            this.removeImage();

        } else {
            fileChooser.click();
        }
    }
    public isRemoveMode() {
        return this.images.length;
    }
    public removeImage() {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', null);
        this.usersService.addCustomerAttachment(this.customerId, formData).subscribe(
            (response: any) => {
            this.images = [];
            this.isLoading = false;
        }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }
    public closeModal() {
        this.activeModal.close();
    }
}
