import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APIS} from './shared_constants/apis';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GeographyService {
    constructor(
        private http: HttpClient
    ) {}

    public getCountries() {
        return of([{value: 'Palestine', label: 'Palestine'}]);
    }

    public getCities() {
        const url = APIS.GET_CITIES.replace('{countryName}', 'Palestine');
        return this.http.get(url);
    }
    public getRegions(country) {
        return this.http.get(APIS.GET_REGIONS + '?country=' + country);
    }

    public getRegionsCities(region) {
        return this.http.get(APIS.GET_REGIONS_CITIES + '?region=' + region);
    }

    public getvillages(region, isReceivingPoint = false) {
        let url = APIS.GET_VILLAGES + '?page=1&pageSize=100&search=' + region;
        if (isReceivingPoint) {
            url += '&is-receiving-point=' + true;
        }
        return this.http.get(url);
    }

}
