import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {UsersService} from '../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {HubsService} from '../../shared/services/hubs.service';
import {UserService} from '../../shared/services/user.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DriversService} from '../../shared/services/drivers.service';
import {HubTitlePipe} from '../../shared/pipes/hub-title-pipe';

@Component({
    selector: 'app-choose-hub-driver-partner',
    templateUrl: './choose-hub-driver-partner.component.html',
    styleUrls: ['./choose-hub-driver-partner.component.scss']
})
export class ChooseHubDriverPartnerComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public form: FormGroup;
    public selectionOptions: { label: string, value: string }[] = [];
    public hubsOptions: { value: string, label: string }[];
    public partnersOptions: { value: string, label: string }[];
    public driversOptions: { value: string, label: string }[] = [];
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public showHubs = true;
    public showPartners = false;
    public showDrivers = true;
    public isVerifyDriverPackages = false;
    public isPickWithDriver = false;
    public titleKey: string;
    public searchSubject: Subject<string>;
    public searchSubscription: Subscription;

    constructor(private activeModal: NgbActiveModal,
                @Inject(FormBuilder) private formBuilder: FormBuilder,
                public usersService: UsersService,
                private translateService: TranslateService,
                private messageService: MessageService,
                private hubsService: HubsService,
                private hubTitlePipe: HubTitlePipe,
                private userService: UserService,
                private driversService: DriversService) {
    }

    ngOnInit() {
        this.getHubs();
        this.getPartners();
        this.getDrivers();
        this.initInfo();
        this.initSubjects();
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }

    private initSubjects() {
        this.searchSubject = new Subject<string>();
        this.searchSubscription = this.searchSubject
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .subscribe((search) => {
                const searchType = this.form.getRawValue().source;
                switch (searchType) {
                    case 'DRIVER':
                        this.getDrivers(search);
                        break;
                    case 'HUB':
                        this.getHubs(search);
                        break;
                    case 'PARTNER':
                        this.getPartners(search);
                        break;
                }
            });
    }

    private getHubs(search?: string) {
        this.isLoading = true;
        this.hubsService.getHubs('?page=1&pageSize=100', search).subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    private getPartners(search?: string) {
        this.isLoading = true;
        this.usersService.getPartners('?status=ACTIVE', search).subscribe(
            (response: any) => {
                this.partnersOptions = response.data.map(
                    (partner) => {
                        return {label: partner.name, value: partner.id};
                    }
                );
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    private getDrivers(search?: string) {
        this.isLoading = true;
        this.driversService.getDrivers(search ? `?search=${search}` : '').subscribe(
            (response: any) => {
                this.driversOptions = response.map(user => {
                    return {
                        label: user.firstName + (user.middleName ? (' ' + user.middleName + ' ') : ' ') + user.lastName,
                        value: user.id,
                    };
                });
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }

    private initInfo() {
        this.form = this.formBuilder.group({
            driverId: ['', Validators.required],
            hubId: [''],
            partnerId: [''],
            source: this.showDrivers ? 'DRIVER' : 'HUB',
        });

        this.form.get('source').valueChanges.subscribe((newValue) => {
            this.changeReceiveType(newValue);
        });

        this.translateService.get([
            'ACCOUNTING.FROM_DRIVER',
            'ACCOUNTING.FROM_HUB',
            'ACCOUNTING.FROM_PARTNER'
        ]).subscribe(values => {
            if (this.showDrivers) {
                this.selectionOptions.push({
                    label: values['ACCOUNTING.FROM_DRIVER'],
                    value: 'DRIVER'
                });
            }
            if (this.showHubs) {
                this.selectionOptions.push({
                    label: this.hubTitlePipe.transform(values['ACCOUNTING.FROM_HUB']),
                    value: 'HUB'
                });
            }
            if (this.showPartners) {
                this.selectionOptions.push({
                    label: values['ACCOUNTING.FROM_PARTNER'],
                    value: 'PARTNER'
                });
            }
        });
    }

    onSubmit() {
        if (this.form.value.source === 'HUB') {
            this.activeModal.close({isSuccess: true, hubId: this.form.value.hubId});
            return;
        }
        if (this.form.value.source === 'PARTNER') {
            this.activeModal.close({isSuccess: true, partnerId: this.form.value.partnerId});
            return;
        }
        const formValue = this.form.getRawValue();
        this.isLoading = true;
        if (formValue.driverId) {
            this.usersService.getDriver(formValue.driverId).subscribe(
                (response: { barcode: any }) => { // redirect to  receive page
                    this.translateService.get('ALERTS.DRIVER_VERIFIED_SUCCESSFULLY')
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                                this.activeModal.close({
                                    isSuccess: true,
                                    barcode: response.barcode,
                                    driverId: formValue.driverId
                                });
                            }
                        );
                }, () => { // Driver not found
                    this.isLoading = false;
                    this.translateService.get('ALERTS.DRIVER_VERIFY_FAILED')
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'error', detail: data});
                                this.isLoading = false;
                            }
                        );
                });
        }
    }

    public changeReceiveType(newValue) {
        switch (newValue) {
            case 'DRIVER':
                this.form.controls['driverId'].setValidators([Validators.required]);
                this.form.controls['hubId'].setValidators([]);
                this.form.controls['hubId'].setValue(undefined);
                this.form.controls['partnerId'].setValidators([]);
                this.form.controls['partnerId'].setValue(undefined);
                this.form.controls['hubId'].updateValueAndValidity();
                this.form.controls['partnerId'].updateValueAndValidity();
                this.form.controls['driverId'].updateValueAndValidity();
                break;
            case 'HUB':
                this.form.controls['hubId'].setValidators([Validators.required]);
                this.form.controls['hubId'].setValue(this.userService.userInfo.hubId);
                this.form.controls['driverId'].setValidators([]);
                this.form.controls['driverId'].setValue(undefined);
                this.form.controls['partnerId'].setValidators([]);
                this.form.controls['partnerId'].setValue(undefined);
                this.form.controls['hubId'].updateValueAndValidity();
                this.form.controls['partnerId'].updateValueAndValidity();
                this.form.controls['driverId'].updateValueAndValidity();
                break;
            case 'PARTNER':
                this.form.controls['partnerId'].setValidators([Validators.required]);
                this.form.controls['hubId'].setValidators([]);
                this.form.controls['hubId'].setValue(undefined);
                this.form.controls['driverId'].setValidators([]);
                this.form.controls['driverId'].setValue(undefined);
                this.form.controls['hubId'].updateValueAndValidity();
                this.form.controls['partnerId'].updateValueAndValidity();
                this.form.controls['driverId'].updateValueAndValidity();
                break;
        }
        this.form.updateValueAndValidity();
    }
}
