import {Injectable, Injector} from '@angular/core';
import {SHARED_CONSTANTS} from '../shared_constants/constants';
import {ROLES} from './roles.constants';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import * as _ from 'lodash';
import {AuthenticationService} from '../authentication.service';
import {CustomizationCompanyService} from '../customization-company.service';
import {Companies} from '../../../../customCompanies/companies.pal-ship';

@Injectable({ providedIn: 'root' })
export class RolesService {
    private customizationCompanyService: CustomizationCompanyService;

    constructor(
        private shipmentService: ShipmentsService,
        private injector: Injector,
        private authenticationService: AuthenticationService
    ) {
    }

    public getAuthorizedMenuItems(role) {
        const menuItems =  _.cloneDeep(Object.values(_.omit(SHARED_CONSTANTS.MENU_TABS, [])));
        const authorizedMenuItems = ROLES[role];

        // In case of ADMIN OR OPERATIONS MANAGE, no need for filtration.
        if (authorizedMenuItems.ALL) {
            return menuItems;
        }

        // Add authorized menu items to Set.
        const tempSet = new Set<string>();
        for (const menuItem in authorizedMenuItems) {
            if (authorizedMenuItems[menuItem]) {
                tempSet.add(menuItem);
            }
        }
        this.customizationCompanyService = this.injector.get(CustomizationCompanyService);

        this.filterMenuItems(menuItems, tempSet);
        return menuItems;
    }

    public filterMenuItems(menuItems: Array<any>, tempSet, index = 0) {
        if (!menuItems || !menuItems[index]) {
            return;
        }

        if (tempSet.has(menuItems[index].role_id)) {
            this.filterMenuItems(menuItems[index].subItems, tempSet);
            index++;
        } else {
            menuItems.splice(index, 1);
        }
        this.filterMenuItems(menuItems, tempSet, index);
    }

    public isAuthorizedPath(path, userInfo) {
        /**
         * convert the path into menu Id,
         * menuId = capitalized path, and '_' instead of '-'.
         * @type {string}
         */
        const route = path.split('/')[0];
        let menuId = route.toUpperCase().replace(/-/g, '_');
        if (menuId == 'SHIPMENTS') {
            menuId = 'MANAGE_SHIPMENTS';
        }
        const authorizedMenuItems = ROLES[userInfo.role];

        // ***************// SPECIAL CASES //*************** //

        /** 1. if the user was allowed to edit package. **/
        if (path === 'add-shipment' && this.shipmentService.canAccessEditPackage) {
            return true;
        }

        // work around to enable the super admin to access all the routes
        if (userInfo.role === 'SUPER_ADMIN') {
            return true;
        }

        // ************************************************* //

        // check if menu is in the authorized menuItems Set.

        for (const menuItem in authorizedMenuItems) {
            if (menuItem === menuId) {
                if (this.checkConfigurationForRoute(menuItem, userInfo)) {
                    return false;
                }
                return true;
            }
        }
        return false;
    }

    checkConfigurationForRoute(menuItem, userInfo) {
        if (!this.customizationCompanyService) {
            this.customizationCompanyService = this.injector.get(CustomizationCompanyService);
        }
        const ADMIN_ROLES = ['ADMIN', 'SUPER_ADMIN', 'SUPER_ADMIN_AS_ADMIN', 'TICKETING_SYSTEM_ADMIN'];
        return (
            (menuItem === 'DRIVERS_EARNINGS' && !userInfo.isDriverEarningEnabled)
            || (menuItem === 'DRAFT_PICKUPS' && !userInfo.isPickupSupported)
            || (menuItem === 'THIRD_PARTY' && !userInfo.hasThirdParty && userInfo.role !== 'SUPER_ADMIN')
            || (userInfo.role === 'OPERATION_MANAGER' && menuItem === 'ACCOUNTING'
                && !userInfo.isAllowOperationManagerReceiveCod)
            || (userInfo.role === 'DISPATCHER' && menuItem === 'ACCOUNTING'
                && !userInfo.isAllowDispatcherReceiveCod)
            || (userInfo.role === 'DISPATCHER' && userInfo.isMapViewOnly && menuItem !== 'DASHBOARD')
            || (userInfo.role === 'DISPATCHER' && menuItem === 'ADMINISTRATION'
                && !userInfo.isAllowDispatcherReceiveCod)
            || (menuItem === 'ADD_SHIPMENT' && !userInfo.isShowAddShipmentInSideMenu)
            || (menuItem === 'MASS_INVOICES' && !userInfo.isSupportMassInvoices)
            || (menuItem === 'PRINTED_REPORTS_NEW' && !this.customizationCompanyService.checkCompanies(Companies.WHEEL, Companies.PLANEX_JO))
            || ((menuItem === 'PARTIALLY_RETURNED_PACKAGES' ||
                menuItem === 'RETURNED_PACKAGES' ||
                menuItem === 'SWAPPED_PACKAGES' ||
                menuItem === 'BRINGS_PACKAGES' || menuItem === 'RETURNED_PACKAGES_IN_CAR') && userInfo.isSupportReturnedBundles)
            || menuItem === 'RETURNED_BUNDLES' && !userInfo.isSupportReturnedBundles
            || (menuItem === 'DELIVERED_PACKAGES' && userInfo.isHideDeliveredMassCodReports)
            || (menuItem === 'PRINTED_REPORTS' && this.customizationCompanyService.checkCompanies(Companies.PLANEX_JO))
            || (menuItem === 'CUSTOMERS_WALLETS' && !userInfo.isEnableCustomerWallet)
            || (menuItem === 'FULFILMENT' && !userInfo.isFulfilmentEnabled)
            || (menuItem === 'PENDING_APPROVAL' && !userInfo.isShowFollowUps)
            || (menuItem === 'CURRENT_REPORTS' && !userInfo.isShowCurrentReports)
            || (menuItem === 'TICKETING_SYSTEM' && !userInfo.isTicketingSystemEnabled)
            || (menuItem === 'ADDITIONAL_FEES' && !userInfo.isSupportAdditionalFees)
            || (menuItem === 'INBOX' && !userInfo.isEnableMessaging)
            || (menuItem === 'TRIPS_BETWEEN_CITIES' && [256, 22] .indexOf(this.authenticationService.companyId) === -1)
            || (menuItem === 'MANAGE_USERS' && !userInfo.isAllowClerkToAddCustomers && (userInfo.role === 'CLERK' || userInfo.role === 'HUB_CLERK'))
            || (menuItem === 'MENU_RETURNED_PACKAGES' && !userInfo.isReturnPackages && (userInfo.role === 'CLERK'))
            || (menuItem === 'PENDING_APPROVAL' && (userInfo.role === 'HUB_MANAGER'||userInfo.role === 'MULTIPLE_HUBS_MANAGER') && !this.customizationCompanyService.checkCompanies(Companies.SPRINT))
            || (['HUB_CUSTODY', 'ADMINISTRATION'].indexOf(menuItem) > -1 && ['HUB_OPERATION_MANAGER', 'HUB_CUSTOMER_CARE'].indexOf(userInfo.role) !== -1)
            || (['HUB_CUSTODY'].indexOf(menuItem) > -1 && ['HUB_ADMIN'].indexOf(userInfo.role) !== -1)
            || (menuItem === 'CONTAINERS_MANAGEMENT' && !userInfo.isLcl)
            || (menuItem === 'INVOICES' && !userInfo.isInvoicingEnabled)
            || (menuItem === 'PACKAGES_CONTENTS_REPORT' && !userInfo.isDistributor)
            || (menuItem === 'COD_PENDING_CUSTOMERS_APPROVAL' && !userInfo.isEnableCustomerConfirmationForReceivingMassCodPkg)
            || (menuItem === 'BANK_TRANSFERS' && !userInfo.isUseBankTransfer)
            || (userInfo.isTrucking && ['MENU_RETURNED_PACKAGES', 'PARTNERS', 'INBOX'].indexOf(menuItem) >= 0)
            || ((menuItem === 'PRICING_SERVICE_TYPES' && !userInfo.isPricingPerServiceTypeEnabled)
                || ((menuItem === 'TICKETING_SYSTEM' && (userInfo.role !== 'TICKETING_USER' && !userInfo.isEnabledAsTicketingUser && (ADMIN_ROLES.indexOf(userInfo.role) < 0 ))))
                || ((menuItem === 'MANAGE_CATEGORIES' && (ADMIN_ROLES.indexOf(userInfo.role) < 0 )))
                || (menuItem === 'SHIPPING_RATES' && userInfo.isPricingPerServiceTypeEnabled))
            || ((userInfo.isDistributor || userInfo.isLcl) && ['MENU_RETURNED_PACKAGES', 'ACCOUNTING', 'PARTNERS', 'INBOX', 'MANAGE_COD_CUSTOMERS'].indexOf(menuItem) >= 0)
            || ((!userInfo.isDistributor) && ['DAMAGED_PACKAGES'].indexOf(menuItem) >= 0)
            || (userInfo.role === 'CUSTOMER_CARE' && menuItem === 'MENU_RETURNED_PACKAGES' && !userInfo.isReturnPackages));
    }


    /**
     * return array of edited fields in a section.
     * @param permissions
     * @param section
     */
    public getEditedFields(permissions, section) {
        // split the permissions over the section to get the fields.
        // TODO: change this code with consistent one.
        try {
            const fields = permissions.split(`{${section}:`)[1].split('}')[0];
            return fields;
        } catch (e) {
            return undefined;
        }
    }

    public getIsAuthorizedAction(section, userRole) {
        const menuItems = ROLES[userRole];
        return section in menuItems;
    }

    /**
     * Return String contains the permissions.
     * ex: EDIT_PACKAGE:ADD_USER:REMOVE_CONTAINER
     * @param section
     * @param userRole
     */
    public getUserPermissions(section, userRole) {
        const menuItems = ROLES[userRole];
        const permissions = menuItems[section];
        return this.calculateObjectPermissions(permissions, '');
    }

    /**
     * Calculate the hierarchy permissions recursively.
     * ex:
     *
     * SALES: {
     *   'MANAGE_USERS': {
     *     'CUSTOMER': [
     *        'ADD_CUSTOMER',
     *        'EDIT_CUSTOMER:ALL~EMAIL',
     *      ]
     *   },
     * }
     *
     * Sales can see MANAGE_USERS tab,
     * MANAGE_USERS permission is an object, which means,
     * there is another tabs hierarchy,
     * Sales can see only CUSTOMER tab,
     * inside the customer tab the user can
     * ADD_CUSTOMER and EDIT_CUSTOMER: all fields except email.
     */
    private calculateObjectPermissions(permissionObject, permissionsString) {
        // Recursive end point.
        if (permissionObject instanceof Array) {
            return this.calculateArrayPermissions(permissionObject);
        }

        // iterate over object properties.
        for (const permission in permissionObject) {
            if (permissionObject[permission]) {
                const childPermissions = `${this.calculateObjectPermissions(permissionObject[permission], permissionsString)}`;
                permissionsString += `{${permission}-${childPermissions}}`;
            }
        }

        return permissionsString;
    }

    /**
     * Calculate the permissions found in array.
     * directly returns the summation text of the permissions.
     * ex: {ADD_CUSTOMER}{EDIT_CUSTOMER}
     *
     * @param permissionArray
     */
    private calculateArrayPermissions(permissionArray) {
        let result = '';
        permissionArray.forEach(
            (permission) => {
                result += `{${permission}}`;
            }
        );
        return result;
    }
}
