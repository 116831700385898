import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../services/wallet.service';

@Component({
  selector: 'app-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['./wallet-list.component.scss']
})
export class WalletListComponent implements OnInit {
  searchContent: any;
  isLoading = false;
  wallets = [];
  isSearchContent: any;
  displayDetails: any;
  preRequest;
  totalRecords = 0;

  constructor(private walletService: WalletService) {
  }

  ngOnInit(): void {
    this.loadWallets();
  }

  onSearchDebounced($event: string) {

  }

  private loadWallets() {
    const params = {
      page: 1,
      pageSize: 100
    };
    if (this.searchContent) {
      params['search'] = this.searchContent;
    }
    this.isLoading = true;
    if (this.preRequest) {
      this.preRequest.unsubscribe();
    }
    this.preRequest = this.walletService.getAllWallets(params).subscribe((value: any) => {
      this.isLoading = false;
      this.wallets.push(...value.data);
      this.totalRecords = this.wallets.length
    });
  }

  public toggleIsSearchContent(value) {
    this.isSearchContent = value;
    if (!value) {
      this.searchContent = '';
      this.onSearch();
    }
  }

  onSearch() {
    this.wallets = [];
    this.loadWallets();
  }

  refreshData($event: any) {
    this.onSearch();
  }
}
