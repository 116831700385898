<div [ngClass]="{'page-ltr': currentLang === 'en'}" style="z-index: 1111111">
    <div class="add-new-user-container">
        <div class="row" style="justify-content: space-between; padding-bottom: 20px !important;">
            <div class="title">
                <span>{{getHeaderTitle() | translate}}</span>
            </div>
            <div class="close-icon" (click)="exitAddNewUser()">
                <img src="../../../assets/icons/close-x.svg" alt=""/>
            </div>
        </div>
        <ng-container *ngIf="isLoading;else loaded">
            <div style="text-align: center;">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </ng-container>

        <ng-template #loaded>
            <div class="flex-1 input-fields">
                <div class="h-100 d-flex flex-column">
                    <form *ngIf="form" class="add-user-form row" [formGroup]="form" (submit)="onAddUser()">
                        <div class="inputs-container d-flex flex-wrap">
                            <div class="input-item">
                                <div class="first-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.FIRST_NAME' | translate}}</div>
                                    <div class="input-value">
                                        <input id="f-name-input" type="text" size="30" pInputText
                                               formControlName="firstName"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('firstName')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.FIRST_NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="last-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.FAMILY_NAME' | translate}}</div>
                                    <div class="input-value">
                                        <input id="l-name-input" type="text" size="30" pInputText
                                               formControlName="lastName"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('lastName')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.LAST_NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>


                            <div class="input-item phone-number-container">
                                <div class="input-filed-container" [ngClass]="{'disabled-btn disabled': isEdit}">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.PHONE' | translate }}</div>
                                    <app-phone-number-input [formGroupPass]="form" [formControlNamePass]="'phone'"
                                                            [formControlPass]="form.get('phone')"></app-phone-number-input>
                                    <div>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('phone', 'required')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_REQUIRED' | translate  }}
                                        </div>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('phone', 'pattern')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_PATTERN' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="input-item phone-number-container">
                                <ng-container *ngIf="showPhone2">
                                    <div class="input-filed-container d-flex">
                                        <div class="flex-1">
                                            <div class="input-label">{{(companyId === 222 && type === 'customer' ? 'USERS_MANAGEMENT.ADD_USER_FORM.TALABY_PHONE' : 'USERS_MANAGEMENT.ADD_USER_FORM.PHONE') | translate }}
                                                2
                                            </div>
                                            <app-phone-number-input [formGroupPass]="form"
                                                                    [formControlNamePass]="'phone2'"
                                                                    [formControlPass]="form.get('phone2')"></app-phone-number-input>
                                            <div>
                                                <div class="invalid-message"
                                                     *ngIf="isInvalidDirtyOrTouched('phone2', 'required')">
                                                    {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_REQUIRED' | translate  }}
                                                </div>
                                                <div class="invalid-message"
                                                     *ngIf="isInvalidDirtyOrTouched('phone2', 'pattern')">
                                                    {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_PATTERN' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="remove-phone d-flex align-items-center cursor-pointer"
                                             (click)="toggleExtraPhone()">
                                            <img class="pl-3 pr-3" src="../../assets/icons/add_package/remove.svg"
                                                 alt="">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!showPhone2">
                                    <div class="add-phone align-items-center d-flex h-100 cursor-pointer">
                                        <img class=" pl-3 pr-3" src="../../assets/icons/add_package/add_phone.svg"
                                             class="active"
                                             (click)="toggleExtraPhone()">
                                    </div>

                                </ng-container>
                            </div>

                        </div>

                        <div class="row actions" style="width: 100%;" [ngClass]="{'actions-user': type !== 'customer'}">
                            <div class="cancel cursor-pointer" (click)="exitAddNewUser()">
                                <span>{{'ACTIONS.CANCEL' | translate}}</span>
                            </div>
                            <div *ngIf="isLoading" class="loading">
                                <span><i class="fa fa-spinner fa-pulse"></i></span>
                            </div>
                            <div *ngIf="!isLoading"
                                 class="{{doneActionStyleClass +  ' cursor-pointer' + ' add-user-btn'}}"
                                 (click)="onAddUser()"
                                 [ngClass]="{'disabled-btn disabled': !form?.valid || disableForm}">
                                <span *ngIf="!isEdit">{{'ACTIONS.DONE' | translate}}</span>
                                <span *ngIf="isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
    </div>
</div>
