import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HubsService} from '../../shared/services/hubs.service';
import {DatePipe} from '@angular/common';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContainerModel} from '../../shared/models/container.model';
import {DriversService} from '../../shared/services/drivers.service';
import {ContainersService} from '../services/containers.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UsersService} from '../../users/services/users.service';
import {UserService} from '../../shared/services/user.service';
import * as moment from 'moment';
import {VALIDATION_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {FuelType} from '../../shared/enums/vehicle.enum';
import {FieldValidation} from '../../shared/components/form-components/field-input/field-input.component';


@Component({
    selector: 'app-new-container',
    templateUrl: 'new-container.html',
    styleUrls: ['new-container.scss']
})
export class NewContainerComponent implements OnInit {
    // Editted container.
    @Input() container: ContainerModel;
    public modifiedContainer: ContainerModel;

    public form: FormGroup;
    public hubsOptions: { label: string, value: any }[] = [];
    public drivers: { label: string, value: any }[] = [];
    public vehicleTypes: { label: string, value: any }[] = [];
    public fuelTypes: { label: string, value: any }[] = [];
    public isLoading = true;
    public isSubmitting = false;
    public isEditMode = false;
    public oldDriverId;
    public currentLang;
    public driverId: number;

    VALIDATION_CONSTANTS = VALIDATION_CONSTANTS;
    vehicleModels = [];
    vehicleBrands = [];
    private loadedCarsData: JSON;

    constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
                private hubService: HubsService,
                private datePipe: DatePipe,
                private driversService: DriversService,
                public translateService: TranslateService,
                public messageService: MessageService,
                private activeModal: NgbActiveModal,
                private  containersService: ContainersService,
                private confirmationService: ConfirmationService,
                private usersService: UsersService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        if (this.container) {
            this.isEditMode = true;
            this.modifiedContainer = {
                ...this.container,
                insuranceExpiryDate: this.container.insuranceExpiryDate ? new Date(this.container.insuranceExpiryDate) : null,
                licenseExpiryDate: this.container.licenseExpiryDate ? new Date(this.container.licenseExpiryDate) : null,
            };
        }

        this.initOptions();
        this.initVehicleBrands();
    }

    private initOptions() {
        this.isLoading = true;
        this.fuelTypes = [
            {label: this.translateService.instant('CONTAINERS_MANAGEMENT.VEHICLE_FUEL.TYPES.' + FuelType.DIESEL), value: FuelType.DIESEL},
            {label: this.translateService.instant('CONTAINERS_MANAGEMENT.VEHICLE_FUEL.TYPES.' + FuelType.GAS), value: FuelType.GAS},
            {label: this.translateService.instant('CONTAINERS_MANAGEMENT.VEHICLE_FUEL.TYPES.' + FuelType.HYBRID), value: FuelType.HYBRID},
            {label: this.translateService.instant('CONTAINERS_MANAGEMENT.VEHICLE_FUEL.TYPES.' + FuelType.ELECTRIC), value: FuelType.ELECTRIC}
        ];
        this.hubService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
                this.initVehicleTypes();
            }
        );

    }

    private initVehicleTypes() {
        this.containersService.getVehicleTypes().subscribe((response: any[]) => {
            this.vehicleTypes = response.map(vehicleType => {
                return {
                    label: this.currentLang === 'en' ? vehicleType.name : vehicleType.arabicName,
                    value: vehicleType.id
                };
            });
            this.isLoading = false;
            this.initForm();
        });
    }

    private initForm() {
        let info = {
            brand: null,
            name: null,
            plateNo: '',
            insuranceExpiryDate: null,
            licenseExpiryDate: null,
            volume: 1,
            // origin: this.hubsOptions[0] ? this.hubsOptions[0].value : null,
            // destination: this.hubsOptions[0] ? this.hubsOptions[0].value : null,
            // agentName: '',
            // workZone: '',
            driverId: null,
            vehicleTypeId: null,
            fuelType: null
        };

        if (this.isEditMode) {
            this.oldDriverId = this.container.driverId;
            const driverObj = this.modifiedContainer.driverId ? this.modifiedContainer.driver : null;
            info = <any>{
                brand: this.modifiedContainer.brand,
                name: this.modifiedContainer.name,
                plateNo: this.modifiedContainer.plateNo,
                insuranceExpiryDate: this.transformDate(new Date(this.modifiedContainer.insuranceExpiryDate).toLocaleDateString(), 'dd/MM/yyyy'),
                licenseExpiryDate: this.transformDate(new Date(this.modifiedContainer.licenseExpiryDate).toLocaleDateString(), 'dd/MM/yyyy'),
                volume: this.modifiedContainer.volume,
                // origin: this.modifiedContainer.originHubId,
                // destination: this.modifiedContainer.destinationHubId,
                // agentName: this.modifiedContainer.agentName,
                // workZone: this.modifiedContainer.workZone,
                vehicleTypeId: this.modifiedContainer.vehicleTypeId,
                fuelType: this.modifiedContainer.fuelType
            };
            if (driverObj) {
                info.driverId = {label: driverObj.firstName + ' ' + driverObj.lastName, value: this.modifiedContainer.driverId};
            }
        }

        this.form = this.formBuilder.group({
            brand: [info.brand, Validators.required],
            name: [info.name, Validators.required],
            plateNo: [info.plateNo, Validators.compose(
                [Validators.required, Validators.minLength(VALIDATION_CONSTANTS.PLATE_NO_MIN_LENGTH)])],
            insuranceExpiryDate: [info.insuranceExpiryDate, Validators.required],
            licenseExpiryDate: [info.licenseExpiryDate, Validators.required],
            volume: [info.volume, Validators.required],
            // originHubId: [info.origin, Validators.required],
            // destinationHubId: [info.destination, Validators.required],
            // agentName: [info.agentName, Validators.required],
            // workZone: [info.workZone, Validators.required],
            driverId: [info.driverId],
            vehicleTypeId: [info.vehicleTypeId],
            fuelType: [info.fuelType, Validators.required]
        });
        // this.form.controls.destinationHubId.setValue(info.destination);
        // this.form.controls.originHubId.setValue(info.origin);
        if (this.userService.userInfo.role === 'DISPATCHER') {
            this.form.controls['driverId'].disable();
        }
    }

    public addContainer() {
        if (this.form.invalid) {
            return;
        }
        this.isSubmitting = true;
        this.submitForm();
    }

    public submitForm() {
        if (!this.form.valid) {
            return;
        }

        this.isSubmitting = true;
        const reqBody = this.form.getRawValue();
        reqBody.driverId = reqBody.driverId ? reqBody.driverId.value : undefined;
        reqBody.hubId = reqBody.originHubId;
        reqBody.insuranceExpiryDate = this.transformDate(moment(reqBody.insuranceExpiryDate, 'DD/MM/yyyy').toDate());
        reqBody.licenseExpiryDate = this.transformDate(moment(reqBody.licenseExpiryDate, 'DD/MM/yyyy').toDate());

        if (this.isEditMode) {
            this.submitModification(reqBody);
            return;
        }

        this.containersService.newContainer(reqBody).subscribe(
            (response) => {
                this.activeModal.close({isSuccess: true});
            }, (error) => {
                this.isSubmitting = false;
            }
        );
    }

    public submitModification(reqBody) {
        const editBody = Object.assign(this.modifiedContainer, reqBody);
        this.containersService.updateContainer(this.modifiedContainer.id, editBody).subscribe(
            (response) => {
                this.activeModal.close({isSuccess: true});
            }, (error) => {
                this.isSubmitting = false;
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }

    alertChangingDriver($event) {
        const driverId = this.form.value.driverId.value;
        const driverIdControl = this.form.controls.driverId;
        driverIdControl.disable();

        this.usersService.getDriver(driverId).subscribe((driver: any) => {
            if (this.container && driver.vehicle && driver.vehicle.id !== this.container.id) {
                const {brand, name, plateNo} = driver.vehicle;
                this.translateService.get(['CONTAINERS.CHANGE_DRIVER_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'], {
                    brand: brand,
                    name: name,
                    plateNo: plateNo
                }).subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['CONTAINERS.CHANGE_DRIVER_CONFIRMATION'],
                        reject: () => {
                            this.form.controls['driverId'].setValue(this.oldDriverId);
                        },
                        acceptLabel: res['GENERAL.YES'],
                        rejectLabel: res['GENERAL.NO'],
                    });
                });
            } else if (!this.isEditMode && driver.vehicle && driver.vehicle.id) {
                this.translateService.get(['USERS.CHANGE_VEHICLE_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'],
                    {name: driver.firstName}).subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['USERS.CHANGE_VEHICLE_CONFIRMATION'],
                        reject: () => {
                            this.form.controls['driverId'].setValue(this.oldDriverId);
                        },
                        acceptLabel: res['GENERAL.YES'],
                        rejectLabel: res['GENERAL.NO'],
                    });
                });
            } else if (this.isEditMode && this.container && this.container.driverId) {
                let message = '';
                let yes = '';
                let no = '';
                this.translateService.get(
                    ['ALERTS.CHANGE_DRIVER', 'GENERAL.YES', 'GENERAL.NO']
                )
                    .subscribe(
                        (data) => {
                            message = data['ALERTS.CHANGE_DRIVER'];
                            yes = data['GENERAL.YES'];
                            no = data['GENERAL.NO'];
                        }
                    );
                this.confirmationService.confirm({
                    message: message,
                    reject: () => {
                        this.form.controls['driverId'].setValue(this.oldDriverId);
                    },
                    acceptLabel: yes,
                    rejectLabel: no
                });
            }
            driverIdControl.enable();
        }, (error: any) => {
            driverIdControl.enable();
        });
    }
    private transformDate(date, Dateformat = null) {
        const format = Dateformat ? Dateformat : 'yyyy-MM-dd';
        return this.datePipe.transform(date, format);
    }

    alertChangingVehicleType($event) {

    }

    getDrivers(event) {
        let query = '?page=1&pageSize=20&driverType=TYPICAL';
        if (event.query) {
            query += '&search=' + event.query;
        }
        return this.driversService.getDrivers(query).subscribe(
            (res: any) => {
                this.drivers = res.map(
                    (driver) => {
                        const name = driver.firstName + ' ' + driver.lastName;
                        return {label: name, value: driver.id};
                    }
                );
            });
    }

    onChangeBrand($event: any) {
        if ($event && $event.value) {
            this.vehicleModels = [];
            for (const v of this.loadedCarsData[$event.value]) {
                this.vehicleModels.push(
                    {label: v, value: v}
                );
            }
        } else {
            this.form.get('name').reset();
        }
    }

    private initVehicleBrands() {
        this.containersService.getVehicleBrands().subscribe((res: JSON) => {
            this.loadedCarsData = res;
            // tslint:disable-next-line:forin
            for (const re in res) {
                this.vehicleBrands.push(
                    {label: re, value: re}
                );
            }
            if (this.isEditMode) {
                this.onChangeBrand({value: this.container.brand});
            }
        });
    }
    isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
        const formControl = this.form.get(formControlName);
        if (formControl) {
            return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
                : formControl.invalid && (formControl.touched || formControl.dirty);
        }
        return false;
    }

    protected readonly FieldValidation = FieldValidation;
}
