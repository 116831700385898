<div class="user-list-dropdown" [ngClass]="{'page-ltr': currentLang === 'en', 'hide-dropdown-btn': hideDropdownWhenSelected && selectedUser}">
    <ng-container *ngIf="!showOldAutoComplete">
        <app-auto-complete [style]="{width:panelWidth}"
                           [suggestions]="users"
                           [(ngModel)]="selectedUser"
                           (onClear)="chooseUser()"
                           [inputStyleClass]="inputStyleClass"
                           placeholder="{{innerPlaceHolder | translate  | hubTitle}}"
                           (onSelect)="chooseUser($event)"
                           [dropdown]="true"
                           [field]="'label'"
                           [dataKey]="'value'"
                           (completeMethod)="search($event)"
                           [isForm]="isForm"
                           [label]="label"
                           [disabled]="isDisabled"
                           [isFilter]="isFilter"
                           [showLabel]="showLabel"
                           [customizeDefaultValue]="customizeDefaultValue"
                           [fieldDisabled]="isDisabled">
        </app-auto-complete>
    </ng-container>
    <ng-container *ngIf="showOldAutoComplete">
        <p-autoComplete [style]="{width:panelWidth}"
                        [suggestions]="users"
                        [(ngModel)]="selectedUser"
                        (onClear)="chooseUser()"
                        styleClass="wid100"
                        [inputStyleClass]="inputStyleClass"
                        placeholder="{{innerPlaceHolder | translate  | hubTitle}}"
                        (onSelect)="chooseUser()"
                        [dropdown]="true"
                        filter="true" [field]="'label'" [dataKey]="'value'" (completeMethod)="search($event)"
                        class="auto-complete-item" [appendTo]="appendTo" [disabled]="isDisabled">
        </p-autoComplete>
    </ng-container>
    <i *ngIf="selectedUser && getObjectKeys(selectedUser).length && showResetButton" class="fas fa-times cursor-pointer" (click)="resetUser()"></i>
</div>
