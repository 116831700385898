import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APIS} from './shared_constants/apis';
import {of} from 'rxjs';
import {CONTAINERS_CONSTANTS} from '../../containers/services/constants';
import * as moment from 'moment';
import {HubModel} from '../models/hub.model';

@Injectable({ providedIn: 'root' })
export class HubsService {

    constructor(
        private http: HttpClient
    ) {
    }

    public getHubs(query = '?page=1&pageSize=100', search?: string, fromAddPackage = false, getAll = false, receiveCodFromHub = false) {
        // TODO update the page stuff.
        if (search) {
            query += `&search=${search}`;
        }
        if (fromAddPackage) {
            query += '&is-adding-package=true';
        }
        if (getAll) {
            if (query.length) {
                query += '&is-get-all-hubs=true';
            } else {
                query += '?is-get-all-hubs=true';
            }
        }
        return this.http.get((receiveCodFromHub ? APIS.LOAD_HUBS_RECEIVED_COD : APIS.GET_HUBS) + query);
    }

    public getHub(id) {
        return this.http.get(APIS.GET_HUB.replace('{hubId}', id));
    }

    public createHub(reqBody) {
        return this.http.post(APIS.NEW_HUB, reqBody);
    }

    public removeHub(id) {
        const url = APIS.REMOVE_HUB.replace('{hubId}', id + '');
        return this.http.delete(url);
    }

    public getHubTypes() {
        return of(['MAIN', 'SUB']);
    }

    public updateHub(id, reqBody) {
        const url = APIS.UPDATE_HUB.replace('{hubId}', id + '');
        return this.http.put(url, reqBody);
    }

    public getShelves(id, query) {
        const url = APIS.GET_SHELF.replace('{hubId}', id) + query;
        return this.http.get(url);
    }

    public addShelf(id, body) {
        return this.http.post(APIS.ADD_NEW_SHELF.replace('{hubId}', id), body);
    }

    public editShelf(id, body) {
        return this.http.put(APIS.EDIT_SHELF.replace('{shelfId}', id), body);
    }

    public deleteShelf(id) {
        return this.http.delete(APIS.DELETE_SHELF.replace('{shelfId}', id));
    }

    public printHubs(ids, archived = false) {
        const url = APIS.PRINT_HUBS;
        return this.http.post(url, {ids}, {
            params: {
                utcOffset: moment().utcOffset().toString(),
                'is-deleted': archived
            }
        });
    }

    public printShelves(ids) {
        const url = APIS.PRINT_SHELVES;
        return this.http.post(url, {ids} , {
            params: {
                utcOffset: moment().utcOffset().toString()
            }
        });

    }

    public getShelfPackagesAPI(id) {
        return APIS.GET_SHELF_PACKAGES.replace('{shelfId}', id);
    }
    public getShelvePackages(id, URLQuery) {
        return  this.http.get(this.getShelfPackagesAPI(id) + URLQuery);
    }
    public getShelveCities(id) {
        return  this.http.get(APIS.GET_SHELVE_CITIES.replace('{shelfId}', id));
    }
    getHubShipment(id, query) {
        return  this.http.get((APIS.GET_HUB_SHIPMENTS.replace('{hubId}', id)) + query);
    }


    receiveHubCod(id, query) {
        return this.http.get((APIS.RECEIVE_HUB_COD.replace('{hubId}', id)) + query);
    }
    getHubReceivedCod(id, query) {
        return  this.http.get((APIS.GET_HUBS_RECEIVED_COD.replace('{hubId}', id)) + query);
    }
    getHubExportedCod(id, query) {
        return  this.http.get((APIS.GET_HUBS_EXPORTED_COD.replace('{massReportId}', id)) + query);
    }

    public getAllHubs(params) {
        // query += '&is-get-all=true';
        return this.http.get(APIS.GET_HUBS , {params});
    }

    retrieve(hub: HubModel) {
        const url = APIS.RETRIEVE_HUB.replace('{hubId}', hub.id + '');
        return this.http.delete(url);
    }

    public exportHubCodPackagesToExcel(body, params) {
        return this.http.post(APIS.EXPORT_HUB_COD_PACKAGES_TO_EXCEL, body, {params});

    }
}
