<div class="print-package-options d-flex flex-column" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="close-icon cursor-pointer">
        <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt=""/>
    </div>
    <div class="title">{{(isShowPrintType ? 'ACCOUNTING.CHOOSE_REPORT_TYPE' : 'ALERTS.CAUTION') | translate }}</div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div *ngIf="!isShowPrintType" class="col-12">
                <div class="row d-flex">
                        <span>{{"ALERTS.CONFIRM_PRINT_EXPORTED_PACKAGES" | translate}}</span>
                </div>
            </div>

            <div *ngIf="isShowPrintType" class="print-options w-100 d-flex justify-content-center">
                <p-selectButton
                        [options]="printOptions"
                        formControlName="PrintType">
                </p-selectButton>
            </div>
            <div *ngIf="!isShowPrintType" class="col-12 print-package-options-container">
                <div class="col-12 driver-input">
                    <div class="input-label driver-label">{{'TICKETING_SYSTEM.SORT_BY' | translate}}</div>
                    <div class="input-value">
                        <ng-select
                                styleClass="tickets-sort-by-list"
                                formControlName="selectedOrderByOption"
                                [items]="orderByOptions" filter="true"
                                [style]="{'width':'100%'}">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-actions d-flex justify-content-end">
            <div class="reset-button cursor-pointer " (click)="closeModal()">
                {{"ACTIONS.CANCEL" | translate}}
            </div>
            <div class="add-button cursor-pointer "
                 (click)="printReports()"
                 [ngClass]="{'disable': !form.valid || isLoading}">
                {{'ACTIONS.PRINT' | translate }}
            </div>
        </div>
    </form>
</div>
