import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'addressFormat'})
export class AddressFormatPipePipe implements PipeTransform {
    transform(value: any, isFull = true): string {
        if (!value) {
            return '';
        }
        if (isFull) {
            return this.getFullAddress(value);
        }
        return this.getAddress(value);
    }

    getAddress({country, region, city, village, addressLine1, addressLine2}) {
        const values = [village, addressLine1, addressLine2];
        return values.filter((val) => {
            return val && val !== '';
        }).join(', ');
    }

    getFullAddress({country, region, city, village, addressLine1, addressLine2}) {
        const values = [country, region, city, village, addressLine1, addressLine2];
        return values.filter((val) => {
            return val && val !== '';
        }).join(', ');
    }
}