import {Injectable} from '@angular/core';
import {LoginSubmitInterface} from '../models/login-submit.interface';
import {HttpClient} from '@angular/common/http';
import {LOGIN_CONSTANTS} from './constants';
import {Observable} from 'rxjs';
import {LoginResponseInterface} from '../models/login-response.interface';
import {RegisterSubmitInterface} from '../models/register-submit.interface';
import {RegisterResponseInterface} from '../models/register-response.interface';
import {VerificationSubmitInterface} from '../models/verification-submit.interface';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {environment} from '../../../environments/environment';
import {UUID} from 'angular2-uuid';
import {MessagingService} from '../../shared/services/messaging.service';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class LoginService {

    constructor(
        private http: HttpClient,
        public messagingService: MessagingService,
        private authenticationService: AuthenticationService
    ) {
    }

    public login(loginData: LoginSubmitInterface, isSuperAdmin): Observable<LoginResponseInterface> {
        loginData.device = {operatingSystem: window.navigator.platform, UUID: UUID.UUID(), notificationToken: this.messagingService.token};
        return this.http.post<LoginResponseInterface>(isSuperAdmin ? LOGIN_CONSTANTS.SUPER_ADMIN_LOGIN : LOGIN_CONSTANTS.LOGIN, loginData);
    }

    public register(registerData: RegisterSubmitInterface): Observable<RegisterResponseInterface> {
        return this.http.post<RegisterResponseInterface>(LOGIN_CONSTANTS.REGISTER, {...registerData, /* Remove this */ hubId: 1});
    }

    // TODO change any to response object.
    public verifyEmail(verifyData: VerificationSubmitInterface): Observable<any> {
        return this.http.post(LOGIN_CONSTANTS.VERIFY, verifyData);
    }

    // TODO change any to response object.
    public forgetPassword(email: string, companyName: any): Observable<any> {
        const reqBody = {
            email: email,
            url: environment.FRONT_END_BASE_URL + '/' + companyName + 'login/reset-password'
        };
        return this.http.post(LOGIN_CONSTANTS.FORGET_PASSWORD, reqBody);
    }

    public resetPassword(reqBody) {
        return this.http.post(LOGIN_CONSTANTS.RESET_PASSWORD, reqBody);
    }
    public passwordConfirmation(body) {
        return this.http.put(LOGIN_CONSTANTS.PASSWORD_CONFIRMATION, body);
    }

    public trackingUserPassword(id, query) {
        const url = LOGIN_CONSTANTS.TRACKING_USER_PASSWORD.replace('{entityId}', id + '');
        return this.http.get(url, query);
    }

    public trackingCustomerPassword(id, query) {
        const url = LOGIN_CONSTANTS.TRACKING_CUSTOMER_PASSWORD.replace('{entityId}', id + '');
        return this.http.get(url, query);
    }
}
