<!--<p-toast [baseZIndex]="toastZIndex"></p-toast>-->
<div class="close-icon" (click)="closeModal()"><img src="../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="filters-container w-100 justify-content-between">
        <div class="page-header-title">
            <div class="title">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.DRIVER_PACKAGES' | translate}}</div>
            <div class="total-number-badge">{{totalRecordsNo}}</div>
        </div>
        <div class="d-flex">
            <div class="excel-button"
                 *ngIf="!isMismatch"
                 [ngClass]="{'disabled-btn disabled': isDownloading}"
                 (click)="printReport('EXCEL')">
                <i class="fas fa-file-excel"></i>
                {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
            </div>
<!--            <div class="pdf-button"-->
<!--                 [ngClass]="{'disabled-btn disabled': isDownloading}"-->
<!--                 (click)="printReport('PDF')">-->
<!--                <i class="fas fa-file-pdf"></i>-->
<!--                {{"ACTIONS.EXPORT_TO_PDF" | translate}}-->
<!--            </div>-->
            <p-dropdown [options]="dateTypesFilterOptions" styleClass="shipment-type"
                        [(ngModel)]="selectedDateType" (onChange)="onDateTypeChanged()"
                        dataKey="value" optionLabel="label"></p-dropdown>

<!--            <div class="data-search header-filter date-range-filter">-->
<!--                            <span (click)="datepicker.click()">-->
<!--                                <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                            </span>-->
<!--                <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                       [options]="dateRangPickerOptions" (selected)="onDateSelected($event)"/>-->
<!--                &lt;!&ndash;                    <img class="arrow-down"  src="../../../assets/icons/arrow-down.png">&ndash;&gt;-->
<!--            </div>-->
            <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

        </div>
    </div>
    <div class="row d-inline-flex main-container">

        <div class="map-table-container flex-1">
            <div class="table-container drivers-earnings-details-table-container"
                 (scroll)="onTableScroll($event)">
                <p-table [value]="dataList" [loading]="isLoading" class="entities-table-component"
                         [responsive]="true" scrollHeight="auto" styleClass="entities-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'MANAGE_SHIPMENTS.BARCODE' | translate}}</th>
                            <th>{{'ADMINISTRATION.DRIVER_REPORTS.INVOICE_NUMBER' | translate}}</th>
                            <th>{{'MANAGE_SHIPMENTS.BOOKED_ON' | translate}}</th>
                            <th>{{'MANAGE_SHIPMENTS.DELIVERY_DATE' | translate}}</th>
                            <th>{{'ADMINISTRATION.PACKAGE_RETURN_DATE' | translate}}</th>
                            <th>{{'MANAGE_SHIPMENTS.COD' | translate}}</th>
                            <th>{{'MANAGE_SHIPMENTS.COST' | translate}}</th>
                            <th>{{'DRIVERS_EARNINGS.DRIVER_COST' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>
                                <div class="d-flex barcode-container flex-2d-center">
                                    <div class="barcode-value">{{item.barcode}}</div>
                                    <div class="copy-icon">
                                        <img src="../../assets/icons/track_package/copy.svg" alt=""
                                             (click)="copyMessage($event,null, item.barcode)"
                                             data-toggle="tooltip" data-placement="top" title="Copy"
                                             class="copy_icon"/>
                                    </div>
                                </div>
                            </td>
                            <td>{{item.invoiceNumber}}</td>
                            <td>{{item.createdDate | date: 'yyyy-MM-dd'}}</td>
                            <td>{{item.deliveryDate | date: 'yyyy-MM-dd'}}</td>
                            <td>{{item.returnDate | date: 'yyyy-MM-dd'}}</td>
                            <td>{{item.cod | number: '1.0-2' }}</td>
                            <td>{{item.cost | number: '1.0-2' }}</td>
                            <td>{{item.driverEarning | number: '1.0-2' }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
