import {NgModule} from '@angular/core';
import {AddShipmentComponent} from './add-shipment/add-shipment.component';
import {SharedModule} from '../shared/modules/shared.module';

import {ManageShipmentsComponent} from './manage-shipments/manage-shipments.component';
import {TableModule} from 'primeng/table';
import {ShipmentsService} from './services/shipments.service';
import {RouterModule} from '@angular/router';
import {ToastModule} from 'primeng/toast';
import {AssignPackageComponent} from './assign-package/assign-package.component';
import {ContainersService} from '../containers/services/containers.service';
import {DatePipe} from '@angular/common';
import {ChooseExistingComponent} from './choose-existing/choose-existing.component';
import {ProductTableComponent} from './products-table/product-table.component';
import {CodRequestChangeComponent} from './cod-request-change/cod-request-change.component';
import {PostponePackageComponent} from './postpone-package/postpone-package.component';
import {ReceivePackagesTableComponent} from './receive-packages-table/receive-packages-table.component';
import {EnterBarcodeComponent} from './enter-barcode/enter-barcode.component';
import {FinishScanningComponent} from './finish-scanning/finish-scanning.component';
import {SortPackagesTableComponent} from './sort-packages-table/sort-packages-table.component';
import {ChangeStatusPackagesTableComponent} from './change-status-packages-table/change-status-packages-table.component';
import {CodMismatchComponent} from './cod-mismatch/cod-mismatch.component';
import {PackageHistoryComponent} from './package-history/package-history.component';
import {BulkUpdateComponent} from './bulk-update/bulk-update.component';
import {BulkUpdateBarcodesComponent} from './bulk-update-barcodes/bulk-update-barcodes.component';
import {ExportComponent} from './export/export.component';
import {HubsCustodyComponent} from './hubs-custody/hubs-custody.component';
import {UnloadPackagesTableComponent} from './unload-packages-table/unload-packages-table.component';
import {ReturnedPackagesComponent} from './returned-packages/returned-packages.component';
import {ReturnPackagesComponent} from './return-packages/return-packages.component';
import {UnloadReturnedPackagesComponent} from './unload-returned-packages/unload-returned-packages.component';
import {AddShipmentQuickComponent} from './add-shipment-quick/add-shipment-quick.component';
import {FaliureHistoryComponent} from './faliure-history/faliure-history.component';
import {ChoosePrintTypeComponent} from './choose-print-type/choose-print-type.component';
import {DownloadExcelComponent} from './download-excel/download-excel.component';
import {BulkPrintComponent} from './bulk-print/bulk-print.component';
import {ChooseDriverComponent} from './choose-driver/choose-driver.component';
import {CustomerRegionFilterComponent} from './customer-region-filter/customer-region-filter.component';
import {ReceiveDriverComponent} from './receive-driver/receive-driver.component';
import {ChooseHubAndDriverComponent} from './choose-hub-and-driver/choose-hub-and-driver.component';
import {DraftPickupsComponent} from './draft-pickups/draft-pickups.component';
import {
    ReceiveDriverAndReadByBarcodeComponent
} from './recieve-driver-and-read-by-barcode/receive-driver-and-read-by-barcode.component';
import {ExpensesComponent} from './expenses/expenses.component';
import {ExpensesTypeAddComponent} from './expenses/expenses-type-add/expenses-type-add.component';
import {ExpensesAddComponent} from './expenses/expenses-add/expenses-add.component';
import {DelayedPackagesReportComponent} from './delayed-packages-report/delayed-packages-report.component';
import {ReportConfirmationComponent} from './receive-driver/report-confirmation/report-confirmation.component';
import {ShipmentTrackerComponent} from './shipment-tracker/shipment-tracker.component';
import {AddPackageFormModule} from '../add-package-form/add-package-form.module';
import {EditDriverEarningComponent} from './edit-driver-earning/edit-driver-earning.component';
import {ExcelValidationDialogComponent} from './excel-validation-dialog/excel-validation-dialog.component';
import {ConfirmMessageComponent} from '../shared/components/confirm-message/confirm-message.component';
import {OptimizedRoutesComponent} from './optimized-routes/optimized-routes.component';
import {DriverRouteDialogComponent} from './optimized-routes/driver-route-dialog/driver-route-dialog.component';
import {ScanUnlistedPackagesComponent} from './scan-unlisted-packages/scan-unlisted-packages.component';
import {AddPackageAttachmentsComponent} from './add-package-attachments/add-package-attachments.component';
import {ReceivePackingListComponent} from './receive-packing-list/receive-packing-list.component';
import {DistributorPackageFormModule} from '../distributor-package-form/distributor-package-form.module';
import {ChangePackageStatusService} from './services/change-package-status.service';
import {UnloadContainerComponent} from '../unload-container/unload-container.component';
import {LclContainersManagementComponent} from '../lcl-containers-management/lcl-containers-management.component';
import {UpdateReceiverAddressComponent} from './update-receiver-address/update-receiver-address.component';
import {ReceiveInHubCustodyComponent} from './receive-in-hub-custody/receive-in-hub-custody.component';
import {ShipmentsValidationResultsComponent} from './shipments-validation-results/shipments-validation-results.component';
import {PaginatorModule} from 'primeng/paginator';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {CardModule} from 'primeng/card';
import {ExportShipmentsComponent} from './export-shipments/export-shipments.component';
import {CodMismatchAllPackageComponent} from './cod-mismatch-all-package/cod-mismatch-all-package.component';
import {CancelJntPrintDialogComponent} from './cancel-jnt-print-dialog/cancel-jnt-print-dialog.component';
import {CustomerWeightFilterComponent} from './customer-weight-filter/customer-weight-filter.component';
import {SelectShelfDialogComponent} from './receive-driver/select-shelf-dialog/select-shelf-dialog.component';
// import {ReturnedBundlesComponent} from './returned-bundles/returned-bundles.component';
import {ReturnedPackagesComponent as ReturnedBundlesComponent} from './returned-packages/returned-packages.component';

@NgModule({
    declarations: [
        AddShipmentComponent,
        ManageShipmentsComponent,
        AssignPackageComponent,
        ChooseExistingComponent,
        ProductTableComponent,
        CodRequestChangeComponent,
        PostponePackageComponent,
        ReceivePackagesTableComponent,
        EnterBarcodeComponent,
        FinishScanningComponent,
        SortPackagesTableComponent,
        ChangeStatusPackagesTableComponent,
        CodMismatchComponent,
        PackageHistoryComponent,
        BulkUpdateComponent,
        BulkUpdateBarcodesComponent,
        ExportComponent,
        HubsCustodyComponent,
        UnloadPackagesTableComponent,
        ReturnedPackagesComponent,
        ReturnPackagesComponent,
        UnloadReturnedPackagesComponent,
        AddShipmentQuickComponent,
        FaliureHistoryComponent,
        ChoosePrintTypeComponent,
        DownloadExcelComponent,
        BulkPrintComponent,
        ChooseDriverComponent,
        CustomerRegionFilterComponent,
        ReceiveDriverComponent,
        ChooseHubAndDriverComponent,
        DraftPickupsComponent,
        ExpensesComponent,
        ExpensesTypeAddComponent,
        ExpensesAddComponent,
        ReportConfirmationComponent,
        DelayedPackagesReportComponent,
        ReceiveDriverAndReadByBarcodeComponent,
        ShipmentTrackerComponent,
        EditDriverEarningComponent,
        ExcelValidationDialogComponent,
        ConfirmMessageComponent,
        OptimizedRoutesComponent,
        DriverRouteDialogComponent,
        ScanUnlistedPackagesComponent,
        AddPackageAttachmentsComponent,
        ReceivePackingListComponent,
        UnloadContainerComponent,
        LclContainersManagementComponent,
        UpdateReceiverAddressComponent,
        ReceiveInHubCustodyComponent,
        ShipmentsValidationResultsComponent,
        ExportShipmentsComponent,
        CodMismatchAllPackageComponent,
        CancelJntPrintDialogComponent,
        CustomerWeightFilterComponent,
        SelectShelfDialogComponent,
        ReturnedBundlesComponent
    ],
    exports: [
        AddShipmentComponent,
        ManageShipmentsComponent,
        ShipmentTrackerComponent,
        EditDriverEarningComponent
    ],
    imports: [
        SharedModule,
        TableModule,
        RouterModule,
        ToastModule,
        AddPackageFormModule,
        DistributorPackageFormModule,
        PaginatorModule,
        ScrollPanelModule,
        CardModule,
    ],
    providers: [
        ShipmentsService,
        ChangePackageStatusService,
        ContainersService,
        DatePipe
    ]
})
export class ShipmentModule {
}
