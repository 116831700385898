import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../users/services/users.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {FinishScanningComponent} from '../finish-scanning/finish-scanning.component';
import {setBarcodeReader} from '../../shared/services/helpers';
import {ShipmentsService} from '../services/shipments.service';
import {UserService} from '../../shared/services/user.service';
import {BulkUpdateComponent} from '../bulk-update/bulk-update.component';
import {AddShipmentComponent} from '../add-shipment/add-shipment.component';
import {RolesService} from '../../shared/services/roles/roles.service';
import {PartnersService} from '../../partners/partners.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {AddShipmentQuickComponent} from '../add-shipment-quick/add-shipment-quick.component';
import {playError} from '../../shared/behavior/sounds';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {ImageSliderComponent} from '../../shared/components/image-slider/image-slider.component';
import {HubTitlePipe} from '../../shared/pipes/hub-title-pipe';
import {UpdateReceiverAddressComponent} from '../update-receiver-address/update-receiver-address.component';
import {ReceiveInHubCustodyComponent} from '../receive-in-hub-custody/receive-in-hub-custody.component';

@Component({
    selector: 'app-receive-packages-table',
    templateUrl: './receive-packages-table.component.html',
    styleUrls: ['./receive-packages-table.component.scss']
})
export class ReceivePackagesTableComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public driverBarcode;
    public driverId = 0;
    public driverName = '';
    public hubId = 0;
    public partnerId = 0;
    public hubName = '';
    public partnerName = '';
    public containerName = '';
    public codPackagesCount = 0;
    public codWithOutPartnerCost = 0;
    public codSum = 0;
    public hubCustomersCod = 0;
    public initialCodSum = 0;
    public receivedCod = 0;
    public driverEarningSum = 0;
    public codCustody = 0;
    public totalDeliveredPackages = 0;
    public totalReturnedPackages = 0;
    public totalPartiallyDeliveredPackages = 0;
    public totalSwappedPackages = 0;
    public totalBroughtPackages = 0;
    public packages = [];
    public isScanning = false;
    public scannedPackages = [];
    public debounceUpdateCost;
    public isReading = false;
    public isCustody = false;
    public isDriverEarningEnabled = false;
    public permissions = '';
    public selectedCustomerFilter = '';
    public selectedDateFilter = '';
    public currentLang;
    public fromDate;
    public toDate;
    public pageNumber = 0;
    public totalRecords = 0;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public scannedCODBarcodes = [];
    public customerFiltersList = [];
    private lazyLoadCount = 0;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    public searchContent = '';
    public dateRangPickerOptions;
    public selectedAttachmentsFilter = 'ALL';
    public isTrackingPackage;
    public triggeredDropdownShipment: any = {};
    public selectedPackageBarcode: number;
    public displayAddPackage = false;
    public userRole = '';
    isCodEditable = false;
    companyId: number;
    showReceiverAddressInSeparatedCell = false;
    private isReceiveInHubCustody: boolean;
    isHubUserRole = false;
    isReceive = true;
    isExporting = false;
    constructor(private usersService: UsersService,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthenticationService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private shipmentsService: ShipmentsService,
                private authenticationService: AuthenticationService,
                private userService: UserService,
                private rolesService: RolesService,
                private datePipe: DatePipe,
                private hubTitlePipe: HubTitlePipe,
                private dateRangePickerService: DateRangePickerService,
                private partnersService: PartnersService) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.isReceive) {
                this.isReceive = params.isReceive === 'true';
            }
        })
        this.companyId = this.authenticationService.companyId;
        this.currentLang = this.translateService.currentLang;
        this.isHubUserRole = this.userService.isHubUserRole;
        this.setCustomerFiltersList();
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.userRole = role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.scannedPackages = [];
        this.isScanning = false;
        this.isLoading = true;
        this.isDriverEarningEnabled = this.userService.userInfo.isDriverEarningEnabled;
        if(this.companyId == 4){
            this.isCodEditable = true;
            this.showReceiverAddressInSeparatedCell = true;
        }
        this.route.url.subscribe((data) => {
            if (data[0].path === 'hub-custody') {
                this.isCustody = true;
            }
        });
        this.route.params.subscribe(
            (params: Params) => {
                this.route.queryParams.subscribe((queryParams) => {
                    if (queryParams.is_hub) {
                        this.hubId = params.barcode;
                    } else {
                        if (queryParams.is_partner) {
                            this.partnerId = params.barcode;
                        } else {
                            this.driverBarcode = params.barcode;
                        }
                    }
                    if (queryParams.driverId) {
                        this.driverId = queryParams.driverId;
                    }
                    this.receivedCod = 0;
                    this.driverEarningSum = 0;
                });
            }
        );
        this.isReading = true;
        setBarcodeReader((barcode) => {
            if (this.isReading) {
                this.barcodeReader(barcode);
            }
        });
    }

    ngOnDestroy(): void {
        this.isReading = false;
    }

    private barcodeReader(barcode) {
        if (!this.isScanning) {
            return;
        }
        this.isLoading = true;
        const params = {};
        if (this.hubId) {
            params['hubId'] = this.hubId;
        }
        this.shipmentsService.getPackageByBarcode(barcode, 'ACCOUNTANT', this.driverId, false, params).subscribe((shipment) => {
            this.translateService.get('ALERTS.BARCODE_SCANNED_SUCCESSFULLY')
                .subscribe(
                    () => {
                        this.scanPackage(shipment);
                        this.isLoading = false;
                    }
                );
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    private setCodSum(lazyLoadCount, from, codSum) {
        if (from === 'loadLazy' && lazyLoadCount === 1) {
            this.codSum = codSum;
        } else if (from !== 'loadLazy' && from !== 'update') {
            this.codSum = codSum;
        }
    }

    public getDriverPackages(from = '') {
        this.isLoading = true;
        if (from === 'loadLazy') {
            this.lazyLoadCount++;
        }
        if (this.hubId) {
            this.usersService.getHubPackages(this.createParams()).subscribe(
                (response: any) => { // redirect to  receive page
                    this.hubName = response.hubName;
                    this.codPackagesCount = response.codPackagesCount;
                    this.setCodSum(this.lazyLoadCount, from, response.codSum);
                    this.hubCustomersCod = response.hubCustomersCod;
                    this.codCustody = response.codCustody;
                    this.initialCodSum = response.codSum;
                    this.packages = response.packages;
                    this.isLoading = false;
                    this.setSumValues(response.packageTrackingStatusCount);
                }
                , () => { // Driver not found
                    this.isLoading = false;
                });
        } else {
            if (this.partnerId) {
                this.partnersService.getPartnerCODPackages(this.createParams()).subscribe(
                    (response: any) => { // redirect to  receive page
                        this.partnerName = response.partnerName;
                        this.codPackagesCount = response.codPackagesCount;
                        this.setCodSum(this.lazyLoadCount, from, response.codSum);
                        this.initialCodSum = response.codSum;
                        this.hubCustomersCod = response.hubCustomersCod
                        this.packages = response.packages;
                        this.isLoading = false;
                        this.setSumValues(response.packageTrackingStatusCount);
                    }
                    , () => { // Driver not found
                        this.isLoading = false;
                    });
            } else {
                this.usersService.verifyDriver(this.createParams(this.driverBarcode, true)).subscribe(
                    (response: any) => { // redirect to  receive page
                        this.driverName = response.firstName + ' ' + (response.middileName ? response.middileName : '')
                            + ' ' + response.lastName;
                        this.hubName = response.hubName;
                        this.containerName = response.vehicle ? (response.vehicle.brand + '-' + response.vehicle.plateNo) : '';
                        this.codPackagesCount = response.codPackagesCount;
                        this.hubCustomersCod = response.hubCustomersCod
                        this.setCodSum(this.lazyLoadCount, from, response.codSum);
                        this.setSumValues(response.packageTrackingStatusCount);
                        this.initialCodSum = response.codSum;
                        this.packages = response.driverPkgs;
                        this.driverId = response.id;
                        this.isLoading = false;
                    }
                    , () => { // Driver not found
                        this.isLoading = false;
                    });
            }
        }
    }

    public confirmReceiveAll() {
        this.translateService.get(['ACCOUNTING.RECEIVE_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.RECEIVE_ALL_CONFIRMATION'],
                accept: () => {
                    this.finishScanning(true);
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public createParams(barcode = '', setIsReceive = false) {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1
        };
        if (barcode) {
            params['barcode'] = barcode;
        }
        if (this.hubId) {
            params['hubId'] = this.hubId;
        }
        if (this.driverId) {
            params['id'] = this.driverId;
        }
        if (this.partnerId) {
            params['partnerId'] = this.partnerId;
        }
        if (this.selectedCustomerFilter !== '') {
            params['customerId'] = this.selectedCustomerFilter;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        if (this.searchContent) {
            params['search'] = this.searchContent;
        }
        if (this.selectedAttachmentsFilter !== 'ALL') {
           params['isOnlyAttachmentPackages'] = (this.selectedAttachmentsFilter === 'WITH');
        }
        params['utcOffset'] = moment().utcOffset().toString();
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        if (setIsReceive && this.isReceive) {
            params['isReceive'] = true;
        }
        return params;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.getDriverPackages('loadLazy');
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    public startScanning() {
        this.isScanning = true;
    }

    public bulkReceive() {
        const modal = this.modalService.open(BulkUpdateComponent, {
            backdrop: 'static',
            windowClass: 'bulk-update-component',
            backdropClass: 'bulk-update-component-backdrop',
            size: 'lg'});
        modal.componentInstance.isChangeStatus = false;
        modal.componentInstance.isReceive = true;
        modal.componentInstance.driverId = this.driverId;
        modal.componentInstance.hubId = this.hubId;
        modal.componentInstance.partnerId = this.partnerId;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                let successMessage = '';
                let errorMessage = '';
                this.translateService.get(
                    ['ALERTS.BULK_RECEIVE_SUCCESSFUL', 'ALERTS.BULK_RECEIVE_FAILED']
                ).subscribe(
                    (data) => {
                        successMessage = data['ALERTS.BULK_RECEIVE_SUCCESSFUL'];
                        errorMessage = data['ALERTS.BULK_RECEIVE_FAILED'];
                    }
                );
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: successMessage});
                    this.getDriverPackages();
                } else {
                    this.messageService.add({severity: 'error', detail: errorMessage});
                }
            }
        ).catch(
            () => {
            }
        );

    }

    public finishScanning(isReceiveAll: boolean) {
        // set to all on receive all
        if (isReceiveAll) {
            this.receivedCod = this.codSum;
        }
        const modal = this.modalService.open(FinishScanningComponent, <any>{
            backdrop: 'static',
            size: 'md',
            windowClass: 'enter-barcode-popover'
        });
        if (this.driverName && this.userService.userInfo.isDriverEarningEnabled) {
            modal.componentInstance.isDriverEarning = true;
            modal.componentInstance.totalDriversEarning = this.driverEarningSum;
        }
        modal.componentInstance.expectedCod = this.receivedCod;
        modal.componentInstance.isReceive = true;
        modal.componentInstance.packages = this.scannedPackages;
        modal.componentInstance.driverId = this.driverId;
        modal.componentInstance.hubId = this.hubId;
        modal.componentInstance.partnerId = this.partnerId;
        modal.componentInstance.receiveInHubCustody = this.isReceiveInHubCustody;
        modal.componentInstance.isCustody = this.isCustody;
        modal.componentInstance.isReceiveAll = isReceiveAll;
        modal.componentInstance.selectedAttachmentsFilter = this.selectedAttachmentsFilter;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.receivedCod = 0;
                    this.driverEarningSum = 0;
                    this.codSum = this.initialCodSum;
                    this.isScanning = false;
                    this.scannedPackages = [];
                    this.getDriverPackages();
                } else {
                    // reset to 0 on cancel
                    if (isReceiveAll) {
                        this.receivedCod = 0;
                        this.driverEarningSum = 0;
                    }
                }
            }
        ).catch(
            (error) => {
                // reset to 0 on cancel
                if (isReceiveAll) {
                    this.receivedCod = 0;
                    this.driverEarningSum = 0;
                }
                console.error(error);
            }
        );

    }

    public resetScanning() {
        this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
                accept: () => {
                    this.receivedCod = 0;
                    this.driverEarningSum = 0;
                    this.codSum = this.initialCodSum;
                    this.isScanning = false;
                    this.scannedPackages = [];
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    scanPackage(shipment) {
        if (shipment) {

            if (!this.scannedCODBarcodes.includes(shipment.codBarcode)) {
                this.scannedCODBarcodes.unshift(shipment.codBarcode);
            }

            if (!this.scannedPackages.includes(shipment.barcode)
                && !this.scannedCODBarcodes.includes(shipment.barcode)) {
                this.receivedCod = Number((this.receivedCod + shipment.cod).toFixed(2));
                this.codWithOutPartnerCost += (shipment.cod - shipment.partnerPackageCost);
                this.driverEarningSum = Number((this.driverEarningSum + shipment.driverEarning).toFixed(2));
                this.codSum = Number((this.codSum - shipment.cod).toFixed(2));
                this.scannedPackages.unshift(shipment.barcode);
            }
            return;
        }
        if (this.driverId) {
            this.translateService.get(['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER'],
                    rejectVisible: false,
                    acceptLabel: 'OK'
                });
                return;
            });
        } else {
            this.translateService.get(['ACCOUNTING.PACKAGE_NOT_IN_HUB']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: this.hubTitlePipe.transform(res['ACCOUNTING.PACKAGE_NOT_IN_HUB']),
                    rejectVisible: false,
                    acceptLabel: 'OK'
                });
                return;
            });
        }
    }

    removePackage(shipment) {
        let index = this.scannedPackages.indexOf(shipment.barcode);
        if (index === -1) {
            index = this.scannedPackages.indexOf(shipment.codBarcode);
        }
        this.scannedPackages.splice(index, 1);
        this.receivedCod = Number((this.receivedCod - shipment.cod).toFixed(2));
        this.driverEarningSum = Number((this.driverEarningSum - shipment.driverEarning).toFixed(2));
        this.codWithOutPartnerCost -= (shipment.cod - shipment.partnerPackageCost);
        this.codSum = Number((this.codSum + shipment.cod).toFixed(2));
    }

    isPackageScanned(barcode, codBarcode) {
        const indexOfBarcode = this.scannedPackages.indexOf(barcode);
        const indexOfCodBarcode = this.scannedPackages.indexOf(codBarcode);
        return indexOfBarcode !== -1 || indexOfCodBarcode !== -1;
    }

    enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.driverId = this.driverId;
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.scanPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    public trackShipment(shipment = null) {
        if (shipment.isBundle) {
            this.goToBundle(shipment.id);
        } else {
            if (shipment) {
                this.triggeredDropdownShipment = shipment;
            }
            this.isTrackingPackage = true;
        }
    }
    public goToBundle(packageId) {
        this.navigateTo('change-status', {isBundle : true, bundleId: packageId});
    }
    public navigateTo(navigateTo, queryParams = {}) {
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }
    public closeTracker($event) {
        this.isTrackingPackage = $event;
    }
    isCustomerAccountManager() {
        return this.userRole === 'CUSTOMER_ACCOUNT_MANAGER';
    }
    public openPackageBarcode(id) {
        const modal = this.modalService.open(AddShipmentComponent, {
            backdrop: 'static',
            windowClass: 'create-new-package',
        });
        modal.componentInstance.pkgId = id;
        modal.result.then(
            () => {
            }
        ).catch(
            () => {
            }
        );
    }

    public setReceivedCod(receivedCodBeforeUpdate, indexOfUpdatedPkg) {
        this.receivedCod += this.packages[indexOfUpdatedPkg].cod - receivedCodBeforeUpdate;
    }
    public editPackage(barcode) {
        this.selectedPackageBarcode = barcode;
        this.displayAddPackage = true;
    }

    public onHideAddPackage (event) {
        this.displayAddPackage = event;
        this.selectedPackageBarcode = null;
        this.onSearch();
    }

    public openQuickAdd(pkg) {
        const modal = this.modalService.open(AddShipmentQuickComponent, {
            backdrop: 'static',
            windowClass: 'create-new-package',
            size: 'lg'
        });
        modal.componentInstance.pkgId = pkg.id;
        modal.componentInstance.isEditMode = true;
        const receivedCodBeforeUpdate = pkg.cod;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data.isSuccess) {
                    this.getDriverPackages('update');
                    setTimeout(() => {
                        this.setReceivedCod(receivedCodBeforeUpdate, this.packages.findIndex(updatedPkg => updatedPkg.id === pkg.id));
                    }, 1000);

                }
            }
        ).catch(
            () => {
            }
        );

    }

    public showHubCodCustody() {
        return this.hubId && this.authService.companyId === 98;
    }

    public onCustomerSelected(customerId) {
        this.selectedCustomerFilter = customerId;
        this.getDriverPackages();
    }

    public setCustomerFiltersList() {
        return this.customerFiltersList.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    changeColorForPartialDeliveryOrSwapsPackage(pkg) {
        return pkg.isPartiallyDelivered || pkg.shipmentType === 'SWAP';
    }
    changeColorOfDraftPackages(isDraftPackageWithoutReceiverInfo) {
        return this.isDraftPackagesEnable() && isDraftPackageWithoutReceiverInfo;
    }
    isDraftPackagesEnable() {
        return this.authService.companyId === 4 || this.authService.companyId === 1;
    }
    PartialDeliveryOrSwapPackage(pkg) {
        if (pkg.isPartiallyDelivered) {
            return 'PARTIALLY_DELIVERED';
        } else if (pkg.shipmentType === 'SWAP') {
            return 'SWAPPED';
        }
    }

    public onSearch() {
        this.pageNumber = 0;
        this.getDriverPackages();
    }

    private setSumValues(values) {
        if (values) {
            this.totalReturnedPackages = values.totalReturnedPackages;
            this.totalPartiallyDeliveredPackages = values.totalPartiallyDeliveredPackages;
            this.totalDeliveredPackages = values.totalDeliveredPackages;
            this.totalSwappedPackages = values.totalSwappedPackages;
            this.totalBroughtPackages = values.totalBroughtPackages;
        }
    }

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.onSearch();
    }
    public stopProp(event) {
        event.stopPropagation();
    }
    public openImages(event, pkgId) {
        this.stopProp(event);
        this.shipmentsService.getPackageProofList(pkgId).subscribe((res: any) => {
            const modal = this.modalService.open(ImageSliderComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.isModal = true;
            if (res == null || res.urls === undefined) {
                res = {'urls': []};
            }
            modal.componentInstance.images = res.urls;
        });
    }
    setAttachmentFilter(filter: string, initShipments = true) {
        this.selectedAttachmentsFilter = filter;
        if (initShipments) {
            this.onSearch();
        }
    }

    openUpdateDestinationAddress(pkg) {
        const modal = this.modalService.open(UpdateReceiverAddressComponent, {backdrop: 'static', windowClass: 'update-destination-address'});
        modal.componentInstance.originPackage = pkg;

        modal.result.then(res => {
            if (res && res.address && res.address.arabicName) {
                pkg.destinationVillage = res.address.arabicName;
                pkg.cost = res.cost;
            }
        });
    }

    receiveInHubCustody() {
        const modal = this.modalService.open(ReceiveInHubCustodyComponent, {});
        modal.result.then(
            (data ) => {
                if (data && data.isSuccess) {
                    this.hubId = data.hubId;
                    this.hubName = data.hubName;
                    this.isScanning = true;
                    this.isReceiveInHubCustody = true;


                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    exportExcel() {
        this.isExporting = true;
        this.partnersService.exportPartnerCodPackagesToExcel({},this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isExporting = false;
        }, error => {
            this.isExporting = false;
        });
    }
}
