import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {CustomConfirmationMessageComponent} from '../../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../../shared/services/user.service';

@Component({
    selector: 'app-ticket-card',
    templateUrl: 'ticket-card.component.html',
    styleUrls: ['ticket-card.component.scss']
})
export class TicketCardComponent implements OnInit {
    selectedLang;

    @Input() isTicketReply = false;
    @Input() data: any;

    @Output() updateTitle: EventEmitter<{ title: string }> = new EventEmitter();
    @Output() updateDescription: EventEmitter<any> = new EventEmitter();
    @Output() deleteReply: EventEmitter<{ replyId: number, replyType: string }> = new EventEmitter();
    @Output() deleteCardAttachment: EventEmitter<{ reply: any }> = new EventEmitter();
    @Output() updateAttachments: EventEmitter<{}> = new EventEmitter();

    @Input() editTitleMode = false;
    @Input() editDescriptionMode = false;

    currentIssueTitle: string;
    currentDescription: string;

    AttachmentsFiles: File[] = [];

    constructor(private translateService: TranslateService,
                private modalService: NgbModal,
                private userService: UserService,
                private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.selectedLang = this.translateService.currentLang;
        this.deactivateEditAttributesMode();
        this.setCurrentTitleAndDescription();

    }

    setCurrentTitleAndDescription() {
        this.currentIssueTitle = this.data.title;
        this.currentDescription = this.isTicketReply ? this.data.text : this.data.description;
    }

    activeEditTitleMode(event) {
        if (this.isOwner()) {
            this.stopPropagation(event);
            this.editTitleMode = true;
        }
    }

    activeEditDescriptionMode(event) {
        if (this.isOwner()) {
            this.stopPropagation(event);
            this.editDescriptionMode = true;
        }
    }

    removeCardAttachment(attachment) {
        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', size: 'sm'});

        modal.componentInstance.title = 'TICKETING_SYSTEM.CONFIRMATION.DELETE_ATTACHMENT';
        modal.componentInstance.confirmMessage = 'TICKETING_SYSTEM.ALARMS.DELETE_ATTACHMENT_CONFIRMATION';
        modal.componentInstance.confirmButtonLabel = 'ACTIONS.DELETE';
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    this.data.attachments.splice(this.data.attachments.indexOf(attachment), 1);
                    this.deleteCardAttachment.emit(this.data);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }
    getAttachmentName(attachment) {
      const  attachmentUrlParts = attachment.split('/');
      return attachmentUrlParts[attachmentUrlParts.length - 1];
    }
    openAttachment(attachment) {
        window.open(attachment, '_blank');
    }

    deactivateEditAttributesMode() {
        this.editTitleMode = false;
        this.editDescriptionMode = false;
    }

    modifyTitle() {
        if (this.currentIssueTitle === this.data.title) {
            return;
        }
        this.updateTitle.emit({title: this.data.title});
        this.currentIssueTitle = this.data.title;
        this.editTitleMode = false;
    }

    cancelModifyTitle() {
        this.data.title = this.currentIssueTitle;
        this.editTitleMode = false;
    }

    removeReply() {

        this.deleteReply.emit({replyId: this.data.id, replyType: this.data.type});
    }

    modifyDescription() {
        if (this.currentDescription === (this.isTicketReply ? this.data.text : this.data.description)) {
            return;
        }

        const value = this.isTicketReply ? {
            description: this.data.text,
            replyId: this.data.id,
            replyType: this.data.type
        } : {description: this.data.description};
        this.updateDescription.emit(value);
        this.currentDescription = this.isTicketReply ? this.data.text : this.data.description;
        this.editDescriptionMode = false;
    }

    cancelModifyDescription() {
        if (this.isTicketReply) {
            this.data.text = this.currentDescription;
        } else {
            this.data.description = this.currentDescription;
        }
        this.editDescriptionMode = false;
    }

    addAttachment(event, fileChooser) {
        event.stopPropagation();
        fileChooser.click();
    }

    onFilesSelected($event: any, data) {
        for (let i = 0; i < $event.target.files.length; i++) {
            this.AttachmentsFiles.push($event.target.files[i]);
        }
        const value = this.isTicketReply ? {attachments: this.AttachmentsFiles, reply: data} : {attachments: this.AttachmentsFiles};
        this.updateAttachments.emit(value);
    }
    stopPropagation(event) {
        event.stopPropagation();
    }

    getUserName(user) {
        if (user.firstName && user.lastName) {
            return user.firstName + ' ' + user.lastName;
        }
    }

    getUserImage(user) {
        return user.imageUrl;

    }

    isOwner() {
        return this.userService.userInfo.id === (this.isTicketReply ? this.data.ownerId : this.data.createdById);
    }

    transformDate(date, getTime = false) {
        return getTime ? new Date(date).toLocaleTimeString() : this.datePipe.transform(date, 'dd/MM/yyyy');
    }
}
