<div class="choose-driver-container">
  <div class="header">
    <div style="display: inline-block;">
      {{('ACTIONS.VERIFY') | translate }}
    </div>
    <i class="fas fa-times cursor-pointer" style="float: right;" (click)="closeModal()"></i>
  </div>
  <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
    <p-progressSpinner> </p-progressSpinner>
  </div>
  <div class="input-fields row">
    <div class="col-12 partner-container">
      <div class="inputs row">
        <span style="font-size: 14px;">{{text | translate}}</span>
        <div *ngIf = "isPrintReport" class="col-6 in-row runsheetCheckbox create-new-container" style="margin-top: 10px;">
          <p-checkbox [(ngModel)]="printReport" binary="true" label="{{'RETURNED_PACKAGES.PRINT_RETURNED_PACKAGES'|translate}}"></p-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="row col-12">
    <div class="flex-1"></div>
    <div style="padding: 10px 30px;">
      <div class="add-button cursor-pointer" (click)="confirm()" [ngClass]="{'disabled-button disabled': isLoading}">
        <i class="far fa-save"></i>
        {{'GENERAL.YES' | translate }}
      </div>
      <div class="reset-button cursor-pointer" (click)="closeModal()">
        <i class="fas fa-times"></i>
        {{"GENERAL.NO" | translate}}
      </div>
    </div>
  </div>
</div>
