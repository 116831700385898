<p-sidebar [(visible)]="display"
           (onHide)="onClose()"
           [dismissible]="false"
           #AddPackageSideBar
           [baseZIndex]="2"
           styleClass="add-package-sidebar">

    <div class="spinner-drape" *ngIf="isLoading === spinnerStates.LOADING">
        <i class="fas fa-spinner fa-pulse"></i>
    </div>

    <form [formGroup]="addPackageForm" *ngIf="isLoading === spinnerStates.LOADED" [ngClass]="{'page-ltr': selectedLanguage === 'en', 'page-rtl': selectedLanguage === 'ar'}">
        <div class="add-package">
            <div class="white-section">
                <div class="row">
                    <div class="col-6">
                        <div class="page-title">
                            <span *ngIf="!packageBarcode">{{'ADD_SHIPMENTS.ADD_ORDER' | translate | packageTitle }}</span>
                            <span *ngIf="packageBarcode">{{'ADD_SHIPMENTS.EDIT_ORDER' | translate | packageTitle }}</span>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="showCreatedDate()">
                        <div class="input-item"
                             [ngClass]="{'has-error': (addPackageForm.get('createdDate').touched && addPackageForm.get('createdDate').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.CREATED_DATE' | translate}}
                            </label>
                            <p-calendar formControlName="createdDate" styleClass="date-picker" icon="pi pi-calendar"
                                        [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                        </div>
                    </div>
                </div>

                <div class="space-md"></div>

                <div class="page-content" style="background-color: #FFFFFF">
                    <div class="row">
                        <div class="col-9 col-sm-6">
                            <div class="form-title">
                                <div class="icon sender-location contain-image"></div>
                                <div class="d-flex justify-content-between">
                                    <span>{{'ADD_SHIPMENTS.SENDER_INFORMATION' | translate}}</span>
                                 </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="refresh-souq" #actualTarget>
                                <img src="../../assets/icons/add_package/change_address.svg" (click)="showSenderInfo($event)">
                            </div>
                        </div>
                    </div>

                    <div class="space-md"></div>

                    <div class="row">
                        <div class="col-6">
                            <div class="sender-info">
                                <img src="../../assets/icons/add_package/souq_icon.png">
                                <div *ngIf="this.addPackageForm.get('senderInfo').value">
                                    <div class="sender-title">{{this.addPackageForm.get('senderInfo').value.fullName}} {{this.addPackageForm.get('senderInfo').value.businessName ? ' - ' + this.addPackageForm.get('senderInfo').value.businessName : ''}}</div>
                                    <div *ngIf="!addPackageForm.controls['pickupPoint'].value" class="details">{{this.addPackageForm.get('senderInfo').value.address.label}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="space-md"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="main-store"
                                 [ngClass]="{'has-error': (addPackageForm.get('pickupPoint').touched && addPackageForm.get('pickupPoint').errors)}">
                                <ng-select formControlName="pickupPoint"
                                           [typeahead]="pickupPointSubject"
                                           (change)="setAddressLineValidator('pickupPointAddressLine1')"
                                           [loading]="pickupPointsLoading"
                                           [items]="combinedOptions"
                                           (open)=" !combinedOptions ? initVillage(0, null): null"
                                           typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                           loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                           notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                           placeholder="{{ 'ADD_SHIPMENTS.PICKUP_POINT' | translate }}">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="addPackageForm.controls['pickupPoint'].value">
                            <div class="input-item address-line1"
                                 [ngClass]="{'has-error': (addPackageForm.get('pickupPointAddressLine1').touched && addPackageForm.get('pickupPointAddressLine1').errors)}">
                                <textarea formControlName="pickupPointAddressLine1" placeholder="{{'ADD_SHIPMENTS.ADDRESS_DETAILS' | translate | addressSystem}}" type="text"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="space-md"></div>
                    <div class="row reciever-info-container">
                        <div class="col-8 col-sm-6">
                            <div class="form-title">
                                <div class="icon reciever-info contain-image"></div>
                                <span>{{'ADD_SHIPMENTS.RECEIVER_INFORMATION' | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="space-md"></div>

                    <div class="row">
                        <div class="col-md-8">
                            <div class="main-store"
                                 [ngClass]="{'has-error': (addPackageForm.get('receiverInfo').touched && addPackageForm.get('receiverInfo').errors)}">
                                <ng-select formControlName="receiverInfo"
                                           class="receiver-info-distributor"
                                           [typeahead]="receiverSubject"
                                           [loading]="receiverLoading"
                                           [items]="storesList"
                                           (open)="!storesList.length ? getStores() : null"
                                           typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                           loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                           notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                           placeholder="{{ 'ADD_SHIPMENTS.STORE_NAME_PLACEHOLDER' | translate}}">
                                    <ng-template ng-label-tmp let-store="item">
                                        <div class="selected-store" *ngIf="store">
                                            <img src="../../assets/icons/add_package/souq_icon.png">
                                            <div>
                                                <div class="store-name">{{ store.fullName + (store.businessName ? ' (' + store.businessName + ')' : '') }}</div>
                                                <div *ngIf="store.address && !addPackageForm.controls['deliveryPoint'].value">{{ store.address.village }} - {{ store.address.city }} - {{ store.address.region }}</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-store="item" let-index="index">
                                        <div>{{ store.fullName + (store.businessName ? ' - ' + store.businessName : '') }}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="space-md"></div>

                    <div class="row">
                        <!--                        delivery point field-input-->
                        <div class="col-md-8">
                            <div class="main-store"
                                 [ngClass]="{'has-error': (addPackageForm.get('deliveryPoint').touched && addPackageForm.get('deliveryPoint').errors)}">
                                <ng-select formControlName="deliveryPoint"
                                           [typeahead]="deliveryPointSubject"
                                           [loading]="deliveryPointsLoading"
                                           [items]="combinedOptions"
                                           (change)="setAddressLineValidator('deliveryPointAddressLine1')"
                                           (open)=" !combinedOptions ? initVillage(0, null): null"
                                           typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                           loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                           notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                           placeholder="{{ 'ADD_SHIPMENTS.DELIVERY_POINT' | translate }}">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="addPackageForm.controls['deliveryPoint'].value">
                                <div class="input-item address-line1"
                                     [ngClass]="{'has-error': (addPackageForm.get('deliveryPointAddressLine1').touched && addPackageForm.get('deliveryPointAddressLine1').errors)}">
                                    <input formControlName="deliveryPointAddressLine1" placeholder="{{'ADD_SHIPMENTS.ADDRESS_DETAILS' | translate | addressSystem}}" type="text">
                                </div>
                        </div>
                    </div>
                    <div class="space-md"></div>

                    <div class="row">
                        <div class="col-9 col-sm-6">
                            <div class="form-title">
                                <div class="icon get-cash contain-image"></div>
                                <span>{{'ADD_SHIPMENTS.PAYMENT' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mid-space"></div>
                    <div class="row">
                        <div class="col-6">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('cost').touched && addPackageForm.get('cost').errors)}">
                                <label class="label">
                                    {{ 'ADD_SHIPMENTS.SHIPMENT_COST'| translate}}
                                </label>
                                <input formControlName="cost" type="number" appInputPositiveNumberDirective placeholder="" min="0">
                            </div>
                        </div>
                    </div>

                    <div class="space-md"></div>

                    <div class="row">
                        <div class="col-12 p-0">
                            <div class="form-title">
                                <div class="icon service-details contain-image"></div>
                                <span>{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.MENU_ITEM_LABEL_ORDER' | translate | packageTitle}}</span>
                            </div>
                            <div class="space-md"></div>
                            <ng-container *ngIf="showStudentsNumber">
                                <div class="row mb-2">
                                    <div class="col-6">
                                        <div class="input-item"
                                             [ngClass]="{'has-error': (addPackageForm.get('quantity').touched && addPackageForm.get('quantity').errors)}">
                                            <label class="label">
                                                {{ 'ADD_SHIPMENTS.STUDENTS_NUMBER'| translate}}
                                            </label>
                                            <input formControlName="quantity" type="number" appInputPositiveNumberDirective
                                                   placeholder="" min="0">
                                        </div>
                                    </div>

                                    <div class="col-6" *ngIf="isEditMode">
                                        <div class="add-business-tab">
                                            <button (click)="showOriginal = false" [tabIndex]="-1"
                                                    [ngClass]="{'active': !showOriginal}">
                                                {{"DISTRIBUTOR_REPORTS.TABLE.ORIGINAL" | translate}}
                                            </button>
                                            <button (click)="showOriginal = true" [tabIndex]="-1"
                                                    [ngClass]="{'active': showOriginal}">
                                                {{"DISTRIBUTOR_REPORTS.TABLE.ACTUAL" | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="space-md"></div>
                            </ng-container>
                            <div class="package-content-container col-12">
                                <app-package-contents-list [showOriginalInEdit]="isEditMode" [showAvailable]="showOriginal || !isEditMode"
                                                           (selectedContents)="setPackageContents($event)"
                                                           [currentPackageContents]="selectedPackageContents"></app-package-contents-list>
                            </div>
                        </div>
                    </div>

                    <div class="mid-space"></div>

                    <div class="row">
                        <div class="col-6">
                        </div>
                    </div>
                    <div class="space-md"></div>
                </div>
            </div>
            <div class="yellow-section">

                <div class="row">
                    <div class="col-9 col-9">
                        <div class="form-title">
                            <div class="icon service-details contain-image"></div>
                            <span>{{'ADD_SHIPMENTS.ORDER_DETAILS' | translate | packageTitle}}</span>
                        </div>
                    </div>
                </div>

                <div class="space-md"></div>

                <div class="row">
                    <div class="col-12">
                        <div class="input-item note-section"
                             [ngClass]="{'has-error': (addPackageForm.get('notes').touched && addPackageForm.get('notes').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.NOTES' | translate}}
                            </label>
                            <textarea formControlName="notes" (focus)="preventSubmitWhenPressEnter = true"
                                      (blur)="preventSubmitWhenPressEnter = false" cols="30" rows="10"></textarea>
                        </div>
                    </div>
                </div>

                <div class="space-md"></div>

                <!--                <div class="small-space"></div>-->


                <div class="submit-container"
                     [ngClass]="{'buzz-company': companyId === 79, 'ghost-company': companyId == 20, 'vee-company': companyId == 139}">
                    <button type="submit" class="submit" *ngIf="!isSubmittingPackage" (click)="submitForm()"
                            [ngClass]="{'disabled disabled-btn': attachmentsUploader?.loading}">
                        <img src="../../assets/icons/add_package/send.svg" alt="">
                        <span *ngIf="!packageBarcode">{{'ADD_SHIPMENTS.SEND_PACKAGE' | translate | packageTitle}}</span>
                        <span *ngIf="packageBarcode">{{'ADD_SHIPMENTS.EDIT_ORDER' | translate | packageTitle}}</span>
                    </button>
                    <i *ngIf="isSubmittingPackage" class="fas fa-spinner fa-pulse"></i>
                </div>

                <div class="mid-space"></div>

                <div class="add-more-packages" *ngIf="!isEditMode">
                    <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.PRINT' | translate }}</span>
                    <p-inputSwitch formControlName="directPrint"
                                   (onChange)="selectSavingOption('directPrint')"></p-inputSwitch>
                </div>
                <div class="add-more-packages" *ngIf="!isEditMode">
                    <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.SAVE' | translate }}</span>
                    <p-inputSwitch formControlName="onlySave"
                                   (onChange)="selectSavingOption('onlySave')"></p-inputSwitch>
                </div>
                <div class="add-more-packages" *ngIf="!isEditMode" style="padding-bottom: 20px">
                    <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.SAVE_AND_CONTINUE' | translate }}</span>
                    <p-inputSwitch formControlName="saveAndContinue"
                                   (onChange)="selectSavingOption('saveAndContinue')"></p-inputSwitch>
                </div>
                <div class="mid-space"></div>
                <div class="mid-space"></div>
                <div class="mid-space-mobile"></div>
            </div>
        </div>
    </form>
    <!--  Sender Address Book -->

    <p-overlayPanel #SenderAddressBook
                    styleClass="sender-address-panel"
                    [dismissable]="true"
                    (onHide)="onHideSenderAddressBook()"
                    [showCloseIcon]="false">
        <div class="text-center" *ngIf="isLoadingSenderAddressBook == spinnerStates.LOADING">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div *ngIf="isLoadingSenderAddressBook !== spinnerStates.LOADING">


            <div *ngIf="selectedTab == 'MAIN_TAB'" style="display: flex; flex-direction: column;">
                <div class="title">
                    <div class="label">{{'USER_INFORMATION.SENDER_ADDRESSES' | translate}}</div>
                    <div class="add-new-address" (click)="toggleSelectedTab()">
                        <img src="../../assets/icons/add_package/add.svg">
                        <span class="add-address-label label" >{{'ADD_SHIPMENTS.ADD_ADDRESS' | translate}}</span>
                    </div>
                </div>

                <div class="empty-msg" *ngIf="isLoadingSenderAddressBook == spinnerStates.EMPTY">
                    {{'USER_INFORMATION.NO_ADDRESSES' | translate}}
                </div>

                <div class="row" *ngFor="let address of senderAddresses;let i = index">
                    <div class="col-1">
                        <p-radioButton name="selectedAddress"
                                       [value]="i"
                                       [(ngModel)]="tempSelectedSenderAddressIndex">
                        </p-radioButton>
                    </div>
                    <div class="col-8">
                        <div class="address-details">
                            <div>{{address.fullName}} {{address.businessName? ' - ' + address.businessName: ''}}</div>
                            <div>{{address?.address?.region}} - {{address?.address?.city}} - {{address?.address?.village}}</div>
                            <div>{{address.phone}}</div>
                        </div>
                    </div>
                    <div class="col-1">
                        <img src="../../assets/icons/add_package/edit.svg"
                             class="cursor-pointer"
                             (click)="showEditAddress(i)">
                    </div>
                    <div class="col-1">
                        <img src="../../assets/icons/add_package/remove.svg"
                             *ngIf="!address.isRemovingSenderAddress"
                             class="cursor-pointer"
                             (click)="removeAddress(i)">
                        <i class="fas fa-spinner fa-pulse" *ngIf="address.isRemovingSenderAddress"></i>
                    </div>
                </div>

                <br>

                <div class="footer-buttons">
                    <div class="row">
                        <div class="col"><button class="btn-grid" (click)="setNewSenderInfo()">{{ 'ACTIONS.DONE' | translate }}</button></div>
                        <div class="col"><span class="btn-outline" (click)="closeSenderInfo()">{{ 'ACTIONS.CANCEL' | translate }}</span></div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedTab == 'SECONDARY_TAB'">
                <form [formGroup]="senderLocationForm">
                    <div class="secondary-title">
                        <span class="back-btn" (click)="toggleSelectedTab()">
                            <i *ngIf="isArabic" class="fas fa-chevron-right"></i>
                            <i *ngIf="!isArabic" class="fas fa-chevron-left"></i>
                            {{ 'ACTIONS.BACK' | translate }}</span>
                        <span *ngIf="isEditAddressBook">{{'ADD_SHIPMENTS.EDIT_ADDRESS' | translate}}</span>
                        <span *ngIf="!isEditAddressBook">{{'ADD_SHIPMENTS.ADD_ADDRESS' | translate}}</span>
                    </div>

                    <div>

                        <div class="input-item"
                             [ngClass]="{'has-error': (senderLocationForm.get('fullName').touched && senderLocationForm.get('fullName').errors)}">
                            <label class="label">
                                {{'USER_INFORMATION.SENDER_NAME' | translate}}
                            </label>
                            <input formControlName="fullName" type="text" placeholder="" min="1">
                        </div>

                        <div class="small-space"></div>

                        <div class="input-item auto-complete-field"
                             [ngClass]="{'has-error': (senderLocationForm.get('address').touched && senderLocationForm.get('address').errors)}">
                            <label class="label">
                                {{'ADMINISTRATION.ADDRESS' | translate}}
                            </label>
                            <app-input-field-component [form]="senderLocationForm"
                                                       controlName="address"
                                                       [dataKey]="'value'"
                                                       controlType="autoComplete"
                                                       field="label"
                                                       appendTo="body"
                                                       [searchSuggestions]="combinedOptions"
                                                       [options]="combinedOptions"
                                                       (onSearch)="initVillage(0, null, $event.query)"
                                                       [forceSelection]="true"
                                                       [className]="'style-free-field-input'"
                                                       [displayDropDown]="true">
                            </app-input-field-component >
                        </div>

                        <div class="small-space"></div>

                        <div class="input-item"
                             [ngClass]="{'has-error': (senderLocationForm.get('addressLine1').touched && senderLocationForm.get('addressLine1').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.ADDRESS_DETAILS' | translate}}
                            </label>
                            <input formControlName="addressLine1" type="text" placeholder="" min="1">
                        </div>

                        <div class="small-space"></div>
                        <div class="input-item"
                             [ngClass]="{'has-error': (senderLocationForm.get('phone1').touched && senderLocationForm.get('phone1').errors)}">
                            <label class="label">
                                {{'ADMINISTRATION.PHONE_NUMBER' | translate }}
                            </label>
                            <input formControlName="phone1" type="text" placeholder="" min="1">
                        </div>

                        <div class="small-space"></div>

                        <div class="input-item"
                             [ngClass]="{'has-error': (senderLocationForm.get('businessName').touched && senderLocationForm.get('businessName').errors)}">
                            <label class="label">
                                {{'USER_INFORMATION.COMERCIAL_NAME' | translate}}
                            </label>
                            <input formControlName="businessName" type="text" placeholder="" min="1">
                        </div>

                        <div class="small-space"></div>

                        <div class="input-item"
                             [ngClass]="{'has-error': (senderLocationForm.get('email').touched && senderLocationForm.get('email').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.EMAIL' | translate}}
                            </label>
                            <input formControlName="email" type="text" placeholder="" min="1">
                        </div>
                    </div>

                    <div class="space-md"></div>

                    <div class="footer-buttons">
                        <div class="row" *ngIf="!isSubmittingSenderAddress">
                            <div class="col"><button class="btn-grid" (click)="addEditSenderAddress()">{{ 'ACTIONS.DONE' | translate }}</button></div>
                            <div class="col"><span class="btn-outline" (click)="closeSenderInfo()">{{ 'ACTIONS.CANCEL' | translate }}</span></div>
                        </div>
                        <div class="text-center" *ngIf="isSubmittingSenderAddress">
                            <i class="fas fa-spinner fa-pulse"></i>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </p-overlayPanel>

</p-sidebar>
