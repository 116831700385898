import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FADE_ANIMATION} from '../../../shared/animations/fade-animation';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-image-with-label-view-component',
    templateUrl: 'image-with-label-view.component.html',
    styleUrls: ['image-with-label-view.component.scss'],
    animations: [FADE_ANIMATION]
})
export  class ImageWithLabelViewComponent implements OnInit{
    selectedDriver: string;
    drivers: any[] = [];
    @Input() list: {lable: string} [] = [];
    @Input() headerLabel: string;
    @Input() isDriver: boolean;
    @Input() isLoading: boolean;
    public i = 0;
    // public isLoading = false;
    public currentLang;

    constructor(private translateService: TranslateService) {}
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }
}
