<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <p-sidebar position="{{currentLang === 'en' ? 'right' : 'left'}}" [(visible)]="isShow" (onHide)="onClose()" [dismissible]="false" styleClass="container-details-sidebar">
        <div class="main-container">
            <div class="header">
                <div class="header-title row">
                    <div class="title">{{'CONTAINERS_MANAGEMENT.CONTAINER_DETAILS' | translate}}</div>
                </div>
                <div class="container-details-actions row">
                    <div class="action update-action d-inline-flex" (click)="onModify()">
                        <div class="icon">
                            <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
                        </div>
                        <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</div>
                    </div>
                    <div class="action print-action d-inline-flex" (click)="onPrint()">
                        <div class="icon" *ngIf="!printLoader">
                            <img src="../../assets/icons/containers-management/print.svg" alt="" />
                        </div>
                        <div class="loading" *ngIf="printLoader">
                            <i class="fas fa-spinner fa-pulse"></i>
                        </div>
                        <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.PRINT' | translate}}</div>
                    </div>
                    <div class="action packages-count-action d-inline-flex" (click)="getPackagesCount(container)">
                        <div class="icon" *ngIf="!getPackagesCountLoading">
                            <img src="../../assets/icons/containers-management/packages_number_black.svg" alt="" />
                        </div>
                        <div class="loading" *ngIf="getPackagesCountLoading">
                            <i class="fas fa-spinner fa-pulse"></i>
                        </div>
                        <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.CONTAINERS_PACKAGES_COUNT' | translate | packageTitle}}</div>
                    </div>
                    <div class="action remove-action d-inline-flex" (click)="onRemoveContainer()">
                        <div class="icon" *ngIf="!deleteContainerLoading">
                            <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
                        </div>
                        <div class="loading" *ngIf="deleteContainerLoading">
                            <i class="fas fa-spinner fa-pulse"></i>
                        </div>
                        <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.REMOVE_CONTAINER' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="details-container">
                <div class="details-table">
                    <table>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_BRAND' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value">{{container?.brand}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_MODEL' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value">{{container?.name}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.WORK_ZONE' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value">{{container?.workZone}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.PLATE_NO' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value plate-no">{{container?.plateNo}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_BARCODE' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value container-barcode">#{{container?.barcode}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.DRIVER_NAME' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value">{{getDriverName(container?.driver)}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.VEHICLE_TYPE' | translate}}</td>
                            <td class="td-space"></td>
                            <td class="td-value">{{container?.vehicleTypeName}}</td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="dates-container">
                <div class="dates-table">
                    <table>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.INSURANCE_EXPIRY_DATE' | translate}}</td>
                            <td class="td-value date">{{ container?.insuranceExpiryDate | date:'dd/MM/yyyy' }}</td>
                            <td class="td-label" *ngIf="container && container.insuranceExpiryDate && showInsuranceExpired()">
                                <div class="notes d-inline-flex">
                                    <div class="icon">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </div>
                                    <div class="date-note-hidden-space"></div>
                                    <div class="label">{{'CONTAINERS_MANAGEMENT.INSURANCE_EXPIRED' | translate}}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-label">{{'CONTAINERS_MANAGEMENT.LICENSE_EXPIRY_DATE' | translate}}</td>
                            <td class="td-value date">{{ container?.licenseExpiryDate | date:'dd/MM/yyyy' }}</td>
                            <td class="td-label" *ngIf="container && container.licenseExpiryDate && showLicenseExpired()">
                                <div class="notes d-inline-flex">
                                    <div class="icon">
                                        <i *ngIf="true" class="fas fa-times-circle"></i>
                                    </div>
                                    <div class="date-note-hidden-space"></div>
                                    <div class="label">{{'CONTAINERS_MANAGEMENT.LICENSE_EXPIRED' | translate}}</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>
