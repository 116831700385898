import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {PackageModel} from '../../../shared/models/package.model';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {
  EXPORTED_DATE_FILTERS_LIST,
  SHIPMENT_STATUS_ALL,
  SHIPMENT_STATUS_LIST,
  SHIPMENT_WITH_DRIVER_STATUS_LIST
} from '../../../shipment/services/constants';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {UsersService} from '../../../users/services/users.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HubsService} from '../../../shared/services/hubs.service';
import {DriversService} from '../../../shared/services/drivers.service';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {SharedService} from '../../../shared/services/shared-service';
import {playError} from '../../../shared/behavior/sounds';
import {EnterBarcodeComponent} from '../../../shipment/enter-barcode/enter-barcode.component';
import {ReportConfirmationComponent} from '../../../shipment/receive-driver/report-confirmation/report-confirmation.component';
import {ChangePackageStatusComponent} from '../../../shipment/change-package-status/change-package-status.component';
import {FaliureHistoryComponent} from '../../../shipment/faliure-history/faliure-history.component';
import {AssignPackageComponent} from '../../../shipment/assign-package/assign-package.component';
import {ChoosePrintTypeComponent} from '../../../shipment/choose-print-type/choose-print-type.component';
import {ACCOUNTANT_REPORT_ORDER_BY} from '../../../ticketing-system/services/constants';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import * as moment from 'moment';
import {ChooseHubDriverPartnerComponent} from '../../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {BulkUpdateComponent} from '../../../shipment/bulk-update/bulk-update.component';
import {ChoosePaymentTypeComponent} from '../../../users/choose-payment-type/choose-payment-type.component';
import {DatePipe} from '@angular/common';
import {ColumnDef, COLUMNS_TYPE} from '../../../shared/components/generic-table/generic-table.component';
import {AccountingRowActionsSettings} from '../../models/row-actions-setting.model';
import {TemplateTopActionsSettingsModel} from '../../models/template-top-actions-settings.model';
import {AccountingTemplateFiltersModel} from '../../models/accounting-template-filters.model';
import {FULFILMENT_CONSTANTS} from '../../../fulfilment/services/constants';

@Component({
  selector: 'app-sort-cod',
  templateUrl: './sort-cod.component.html',
  styleUrls: ['./sort-cod.component.scss']
})
export class SortCodComponent implements OnInit, AfterViewInit{

  public driverId;
  public partnerId;
  public hubId;

  public isLoading = false;
  public isScanning = false;
  public isReading = false;
  public totalPackages = 0;
  public scannedPackages: { [barcode: number]: PackageModel } = {};
  public packages: any = [];
  public originalPackages: { [barcode: number]: PackageModel } = {};
  public triggeredPackage: any;
  private fetchedData: any[] = [];
  public permissions = '';
  public pageNumber = 0;
  public pageSize = 15;
  public hubsPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
  public PartnersListPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
  public driverName = '';
  public currentLang;
  public hubs: { id: number, name: string }[] = [];
  public hubsPageIndex = 1;
  public hubSearch = '';
  public partnersListPageIndex = 1;
  public totalHubsNo: number;
  public totalPartnersNo: number;
  public drivers: { id: number, name: string, countOfCarriedPackages: number }[] = [];
  public driversPageIndex = 1;
  public driverSearch = '';
  public totalDriversNo: number;
  public spinnerState = SpinnerState.EMPTY;
  public spinnerStates = SpinnerState;
  public isAllSelected = false;
  public selectedShipmentStatus = 'ALL';
  public selectedStatusFilterList: any[] = [];
  public statusFilterList;
  public isThereSelection = false;
  public continueReceiveFromDriver = true;
  public isReceiverFromDriver = true;
  public isReadByBarcode = false;
  public isReceiveReturnedPackages = false;
  public isReceiveFromPartner = false;
  public noDataAvailableMessage = 'RECEIVE_FROM_DRIVER.CHOOSE_HUB_AND_DRIVER_ALERT';
  public enteredBarcode: any;
  public shipmentStatusAll = SHIPMENT_STATUS_ALL;
  public currency = '';
  public partnersList: any[] = [];
  public loadingDrivers = false;
  public loadingHubs = false;
  public loadingPartners = false;
  public isReturnedWith = false;
  public isUpdateReceiverInfo = false;
  public isLcl: boolean;
  public isReturned = false;
  public sort = false;
  public isPendingCustomer = false;
  public isExport = false;
  public export = false;
  public receive = true;
  public inCar = false;
  public mismatch = false;
  public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
  public totalRecords = 0;
  public first = 0; // firstElement in paginator
  public withoutType = false;
  public isBankTransfer = false;
  public params = {};
  public fromDate;
  public toDate;
  private packagesSubscription: Subscription;
  private customerQuery = null;

  @ViewChild('changePackageStatusPopOver') changePackageStatusPopOver: NgbPopover;
  @ViewChild('table') table;

  companyId;
  storesList = [];
  numberOfCustomers = 0;
  public orderByOptions;
  public selectedOrderByOption;
  public selectedDateFilter;
  public dateFiltersList;
  searchContent: any;
  public dateRangPickerOptions;
  isHoldCustody = false;
  type;
  public isDeliveredPage = false;
  public holdCustodyBy = '';
  enableEditCost = true;
  totalCod = 0 ;
  selectedCod = 0;
  totalCostSum = 0;
  selectedCostSum = 0;
  totalCodWithoutCost = 0;
  totalCodFees = 0;
  selectedCodWithoutCost = 0;
  selectedCodFees = 0;
  customerId = 0;
  selectedPackageBarcode;
  displayAddPackage;
  private lazyLoadCount = 1;
  private customExport = false;
  utcOffset: any = 0;
  private stopReadingByBarcode: boolean;
  private loadEntitiesRequest: any;
  loadSpecificCustomer = false;
  viewSingleCustomer = false;
  singleCustomerDetails = false;

  sumStatus = '';
  selectedDateFilterCustomer = null;
  invoices;
  totalSatisfactionNumber = [1];
  public userRole: string;
  previousRequests = [];

  isMassInvoice = false;

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private datePipe: DatePipe,
              private usersService: UsersService,
              private shipmentsService: ShipmentsService,
              private rolesService: RolesService,
              private translateService: TranslateService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private hubsService: HubsService,
              private driversService: DriversService,
              private storageService: StorageService,
              private dateRangePickerService: DateRangePickerService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.utcOffset = this.sharedService.getUtcOffset();
    this.currentLang = this.translateService.currentLang;
    this.isLcl = this.userService.userInfo.isLcl;
    this.userRole = this.userService.userInfo.role;
    this.currency = this.userService.getCurrency();
    const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
    this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
    if (this.isReturnedWith) {
      this.selectedShipmentStatus = 'RETURNED_BY_RECIPIENT';
    }
    if (this.isReadByBarcode) { // read by barcode
      this.noDataAvailableMessage = 'READ_BY_BARCODE.START_READ_BY_BARCODE';
    }

    if (this.isReadByBarcode) {
      this.setStatusFilterList();
    }
    this.isReading = true;
    this.companyId = this.userService.userInfo.companyId;
    if (this.viewSingleCustomer) {
      this.initCustomerDetails();
    } else {
      this.loadCustomersLazy({query: this.driverSearch});
    }
    this.getOrderByOptions();
    this.initRoutesParams();
    this.getPackages();

  }


  getCustody(pkg) {
    let custody = '';
    if (pkg.hubName) {
      custody = this.translateService.instant('ACCOUNTING.IN_HUB') + ' '  +  pkg.hubName;
    } else if (pkg.driverName) {
      custody = this.translateService.instant('ACCOUNTING.IN_CAR') + ' '  +  pkg.driverName;
    } else if (pkg.partnerName) {
      custody = this.translateService.instant('ACCOUNTING.WITH_PARTNER') + ' '  +  pkg.partnerName;
    }
    return custody;
  }

  onStatusFilter() {
    this.packages = [];
    if (this.selectedStatusFilterList.length  === this.statusFilterList.length && this.statusFilterList.length) {
      // if select all no need to filter
      this.packages = this.originalPackages;
    } else {
      Object.keys(this.originalPackages).forEach((key: string) => {
        if (this.selectedStatusFilterList.indexOf(this.originalPackages[key].status) !== -1) {
          this.packages[key] = this.originalPackages[key];
        }
      });
    }
  }

  public resetPackages(partialReset = true) {
    if (partialReset) {
      Object.keys(this.scannedPackages).forEach(key => {
        delete this.packages[key];
        delete this.originalPackages[key];
      });
      this.scannedPackages = [];
      return;
    }
    this.packages = [];
    this.originalPackages = [];
    this.scannedPackages = [];
  }

  finish() {
    this.confirmationService.confirm({
      message: this.receive ? this.translateService.instant('ACCOUNTING.RESET_SORTING_CONFIRMATION') : this.translateService.instant('ACCOUNTING.END_PROCESS'),
      accept: () => {
        this.closeModal();
      },
      acceptLabel: this.translateService.instant('GENERAL.YES'),
      rejectLabel: this.translateService.instant('GENERAL.NO')
    });
  }

  public closeModal() {
    this.activeModal.close();
  }

  setStatusFilterList() {
    this.statusFilterList =  SHIPMENT_STATUS_LIST.map((value) => {
      let label = value;
      this.translateService.get(value)
          .subscribe((data) => {
            label = data;
          });
      return {label, value};
    });
    const allIndex = this.statusFilterList.findIndex(status => (status.value === this.shipmentStatusAll));
    if (allIndex !== -1) { // remove 'ALL' item for multi select filter
      this.statusFilterList.splice(allIndex, 1);
    }
    this.statusFilterList.map(status => {
      this.selectedStatusFilterList.push(status.value);
    });
  }

  public onSelectCustomer(customerId) {
    if (this.viewSingleCustomer) {
      return;
    }
    this.customerId = customerId;
    this.packages = [];
    this.pageNumber = 0;
    this.initPackages();
  }

  public onToggleSelectAll() {
    let selected = false;
    this.selectedCod = 0;
    this.selectedCostSum = 0;
    this.selectedCodFees = 0;
    if (this.isAllSelected) {
      selected = true;
      this.isThereSelection = true;
    } else {
      this.isThereSelection = false;
      this.scannedPackages = {};
    }
    Object.values(this.packages).forEach((pkg: PackageModel) => {
      pkg.isSelected = selected;
      if (pkg.isSelected) {
        this.scannedPackages[pkg.barcode] = pkg;
        this.selectedCod += pkg.cod;
        this.selectedCostSum += pkg.cost;
        this.selectedCodFees += pkg.codFees;
      }
    });

    this.selectedCodWithoutCost = this.selectedCod - this.selectedCostSum;
  }

  public onToggleSelection(event, pkg) {
    if (event && event.originalEvent) {
        event.originalEvent.stopPropagation();
    }

    if (pkg.isSelected) {
      if (!this.scannedPackages[pkg.barcode]) {
        this.scannedPackages[pkg.barcode] = pkg;
      }
      this.selectedCod += pkg.cod;
      this.selectedCodFees += pkg.codFees;
      this.selectedCostSum += pkg.cost;

    } else {
      this.cancelScanPackage(pkg);
    }

    const selectedPackages = this.getSelectedPackages();
    this.isThereSelection = selectedPackages.length !== 0;
    this.isAllSelected = selectedPackages.length === Object.values(this.packages).length && this.isThereSelection;
    this.selectedCodWithoutCost = this.selectedCod - this.selectedCostSum;
  }

  public copyMessage($event, list?, barcode?) {
    $event.stopPropagation();
    this.sharedService.copyMessage($event, list, barcode, 'ALERTS.COPIED');
  }

  public onStatusChanged() {
    this.packages = {};
    this.pageNumber = 0;
    this.getPackages(true);
  }

  public createQuery() {
    let result = `?pageSize=${this.pageSize}&page=` + (this.pageNumber + 1);
    if (this.selectedShipmentStatus !== 'ALL') {
      result += '&status=' + this.selectedShipmentStatus;
    }
    return result;
  }

  public barcodeReader(barcode) {

    if (this.stopReadingByBarcode) {
      return;
    }
    if (this.isPackageExist({barcode})) {
      const pkg = this.packages[this.getPackageIndex({barcode})];
      if(pkg.isSelected){
        this.getExistPackageMessage();
        return;
      }
      pkg.isSelected = true;
      this.onToggleSelection({}, pkg);
      this.showSuccessMessage();
    }else{
      //todo getbkg by api
      this.showErrorMessage();

    }
  }

  public searchBarcodesList(searchValue) {
    for (const pkgBarcode in this.originalPackages) {
      if (!this.originalPackages[pkgBarcode].barcode.includes(searchValue)) {
        this.packages[pkgBarcode] = undefined;
        delete this.packages[pkgBarcode];
      } else if (!this.packages[pkgBarcode]) {
        this.packages[pkgBarcode] = this.originalPackages[pkgBarcode];
      }
    }
  }

  removeFromBarcodesList(pkg) {
    delete this.packages[pkg.barcode];
    delete this.originalPackages[pkg.barcode];
    delete this.scannedPackages[pkg.barcode];
  }

  public onClearSearchBarcodesList(search) {
    this.packages = this.originalPackages;
    this.searchBarcodesList(search);
  }

  public scanBarcode(barcode) {
    if (barcode === '' || barcode === null || barcode === undefined) {
      return;
    }
    if (this.packages[barcode]) {
      this.getExistPackageMessage();
      this.enteredBarcode = null;
      return;
    }
    this.isLoading = true;
    this.shipmentsService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true).subscribe((pkg: any) => {
      this.translateService.get('ALERTS.BARCODE_SCANNED_SUCCESSFULLY')
          .subscribe(
              () => {
                this.scanPackage(pkg);
                this.isLoading = false;
                this.enteredBarcode = null;
              }
          );
    }, () => {
      playError();
      this.enteredBarcode = null;
      this.isLoading = false;
    });
  }

  public showSuccessMessage() {
    this.translateService.get('ALERTS.PACKAGE_SCANNED_SUCCESSFULLY').subscribe((res: any) => {
      this.messageService.add({
        severity: 'success',
        detail: res
      });
    });
  }

  public showErrorMessage() {
    playError();
    this.translateService.get('ALERTS.PACKAGE_SCANNED_FAILED').subscribe((res: any) => {
      this.messageService.add({
        severity: 'error',
        detail: res
      });
    });
  }

  public getExistPackageMessage() {
    this.translateService.get('ALERTS.PACKAGE_ALREADY_SCANNED').subscribe((res: any) => {
      this.messageService.add({
        severity: 'info',
        detail: res
      });
    });
  }

  public scanDriverPackage(pkg) {
    if (pkg) {
      if (this.isReceiveFromPartner || this.isReceiverFromDriver) {
        if (this.scannedPackages[pkg.barcode]) {
          this.getExistPackageMessage();
        } else if (this.packages[pkg.barcode]) {
          this.packages[pkg.barcode].isSelected = true;
          this.scannedPackages[pkg.barcode] = this.packages[pkg.barcode];
          this.showSuccessMessage();
        } else {
          pkg.isSelected = true;
          this.scannedPackages[pkg.barcode] = pkg;
          this.showSuccessMessage();
        }
      } else if (this.isReadByBarcode || this.isReceiveReturnedPackages) {
        if (!this.packages[pkg.barcode]) {
          pkg.isSelected = true;
          this.packages[pkg.barcode] = pkg;
          if (this.isReceiveReturnedPackages) {
            this.scannedPackages[pkg.barcode] = pkg;
          }
        } else {
          this.getExistPackageMessage();
        }
      }
    }
  }

  scanPackage(pkg) {
    this.packages[pkg.barcode] = pkg;
    if (this.isReadByBarcode) {
      pkg.isSelected = true;
      this.originalPackages[pkg.barcode] = pkg;
      this.scannedPackages[pkg.barcode] = pkg;
      this.isAllSelected = Object.values(this.scannedPackages).length  === Object.values(this.packages).length;
    }
  }

  public isPackageScanned(pkg) {
    return this.scannedPackages[pkg.barcode] !== undefined;
  }

  public cancelScanPackage(pkg) {
    pkg.isSelected = false;
    this.scannedPackages[pkg.barcode] = undefined;
    delete this.scannedPackages[pkg.barcode];
    this.selectedCod -= pkg.cod;
    this.selectedCodFees -= pkg.codFees;
    this.selectedCostSum -= pkg.cost;
  }

  removePackage(pkg) {
    delete this.packages[pkg.barcode];
    delete this.scannedPackages[pkg.barcode];
    delete this.originalPackages[pkg.barcode];
  }

  public startScanning() {
    this.isScanning = true;
  }

  public resetScanning(showConfirmation = true) {
    if (!showConfirmation) {
      this.isScanning = false;
      this.packages = {};
      this.scannedPackages = {};
    } else {
      this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
      ).subscribe((res: any) => {
        this.confirmationService.confirm({
          message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
          accept: () => {
            this.isScanning = false;
            this.packages = {};
            this.scannedPackages = {};
            this.driverId = null;
            this.partnerId = null;
          },
          reject: () => {

          },
          acceptLabel: res['GENERAL.YES'],
          rejectLabel: res['GENERAL.NO'],
        });
      });
    }
  }

  enterBarcode() {
    const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
    modal.componentInstance.isCarried = true;
    if (this.isReceiveFromPartner) {
      modal.componentInstance.isReceivePartnerPackage = true;
      modal.componentInstance.partnerId = this.partnerId;
    } else if (this.isReceiverFromDriver) {
      modal.componentInstance.isReceiverFromDriver = true;
      modal.componentInstance.driverId = this.driverId;
    } else if (this.isReceiveReturnedPackages) {
      modal.componentInstance.isReturned = true;
    }
    modal.result.then(
        (data: { isSuccess: boolean, package: any }) => {
          if (data && data.isSuccess) {
            this.scanDriverPackage(data.package);
          }
        }
    ).catch(
        (error) => {
          console.error(error);
        }
    );

  }

  public receivePackages(printType = null) {
    this.isLoading = true;
    this.shipmentsService.receiveDriverPackages(
        this.driverId, this.hubId, Object.values(this.scannedPackages).map(pkg => pkg.id), printType).subscribe((data: any) => {
      if (data !== undefined && data && data.url !== undefined) {
        window.open(data.url, '_blank');
      }
      this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS', 'ACTIONS.OK']
      ).subscribe((res: any) => {
        this.confirmationService.confirm({
          message: res['ALERTS.RECEIVE_FROM_DRIVER_SUCCESS'],
          rejectVisible: false,
          acceptLabel: res['ACTIONS.OK'],
        });
        this.isLoading = false;
        this.scannedPackages = {};
        this.getPackages(true);
      });
    }, (error) => {
      this.translateService.get(['ALERTS.RECEIVE_FROM_DRIVER_FAILED', 'ACTIONS.OK']
      ).subscribe((res: any) => {
        this.confirmationService.confirm({
          message: res['ACCOUNTING.RECEIVE_FROM_DRIVER_FAILED'] + ': ' + error.err.err,
          rejectVisible: false,
          acceptLabel: res['ACTIONS.OK'],
        });
        this.isLoading = false;
      });

    });
  }

  public onChangePackageStatus(pkg = null) {
    const modal = this.modalService.open(ChangePackageStatusComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
    modal.componentInstance.isModal = true;
    if (pkg) {
      modal.componentInstance.shipment = pkg;
    } else {
      modal.componentInstance.shipments = this.getSelectedPackages();
    }
    modal.result.then(
        (data: { isSuccess: boolean }) => {
          if (data && data.isSuccess) {
            this.translateService.get(
                'ALERTS.CHANGED_STATUS'
            )
                .subscribe(
                    (message) => {
                      this.messageService.add({severity: 'success', detail: message});
                    }
                );
            this.getPackages(true);
          }
        }
    ).catch(
        (error) => {
          console.error(error);
        }
    );
  }

  public openMessagesHistory(id) {
    const modal = this.modalService.open(FaliureHistoryComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
    modal.componentInstance.shipmentId = id;
  }

  private getSelectedPackages() {
    const selectedPackages = [];
    if (!this.packages) {
      return;
    }
    Object.values(this.packages).forEach(
        (pkg: any) => {
          if (pkg.isSelected) {
            selectedPackages.push(pkg);
          }
        }
    );
    return selectedPackages;
  }

  public isAllowedEditCost() {
    return (this.permissions.includes('{EDIT_COST')) ||
        (this.permissions.includes('{CONDITIONAL_COST') && this.userService.userInfo.isNonAdminChangeCost);
  }

  public getPackages(isFromFilter = false, isEdit = false, from = 'default') {
    this.initPackages(false, from);
    return;
  }

  public loadPackagesLazy() {
    this.getPackages(false, false, 'lazy');
  }

  public onScrollTable(event) {
    if (this.spinnerState === SpinnerState.LOADING) {
      return;
    }
    if (Object.keys(this.packages).length === this.totalRecords) {
      return;
    }
    if (event.target.scrollTop > 0 && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
      this.pageNumber++;
      this.loadPackagesLazy();
    }
  }

  public approvePackages() {
    this.isLoading = true;
    const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
    this.shipmentsService.approvePackages(selectedPackages).subscribe(
        () => {
          this.translateService.get(['ALERTS.PACKAGES_APPROVED', 'ACTIONS.OK']).subscribe(
              (data) => {
                this.confirmationService.confirm({
                  message: data['ALERTS.PACKAGES_APPROVED'],
                  rejectVisible: false,
                  acceptLabel: data['ACTIONS.OK']
                });
              }
          );
          this.isLoading = false;
          this.resetPackages();
        }, (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  private assignPackageTo(assignTo: 'driver' | 'container') {
    if (Object.keys(this.scannedPackages).length > 0) {
      const modal = this.modalService.open(AssignPackageComponent, <any>{
        backdrop: 'static',
        windowClass: 'assign-package-to-modal',
        size: 'md'
      });
      modal.componentInstance.assignTo = assignTo;
      modal.componentInstance.ids = Object.values(this.scannedPackages).map(pkg => pkg.id);
      modal.result.then(
          (result: { isSuccess: boolean }) => {
            if (result.isSuccess) {
              this.translateService.get(['ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY', 'ACTIONS.OK']).subscribe(
                  (data) => {
                    this.confirmationService.confirm({
                      message: data['ALERTS.PACKAGE_ASSIGNED_SUCCESSFULLY'],
                      rejectVisible: false,
                      acceptLabel: data['ACTIONS.OK']
                    });
                  }
              );
              this.resetPackages();
            }
          }
      ).catch(
          () => {
          }
      );
    }
  }

  public assignToDriver() {
    this.assignPackageTo('driver');
  }

  public assignToContainer() {
    this.assignPackageTo('container');
  }

  public downloadMultiplePackages(printType = null) {
    if (!this.scannedPackages || !Object.keys(this.scannedPackages).length) {
      return;
    }
    this.isLoading = true;
    const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
    this.shipmentsService.downloadMultiple(selectedPackages, printType).subscribe(
        (response: any) => {
          window.open(response.url, '_blank');
          this.isLoading = false;
        }, (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  public printRunSheet(isWithSignature = false) {
    this.isLoading = true;
    const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
    this.shipmentsService.downloadMultipleChangeStatus(selectedPackages.reverse(), null, isWithSignature).subscribe(
        (response: any) => {
          window.open(response.url, '_blank');
          this.isLoading = false;
        }, (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  public openPrintDialog() {
    const modal = this.modalService.open(ChoosePrintTypeComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
    modal.componentInstance.isModal = true;
    modal.result.then(
        (data: { type: any }) => {
          if (data) {
            if (data.type === 'RUNSHEET') {
              this.printRunSheet();
            } else if (data.type === 'RUNSHEET_WITH_SIGNATURE') {
              this.printRunSheet(true);
            } else {
              this.downloadMultiplePackages(data.type);
            }
          }
        }
    ).catch(
        (error) => {
          console.error(error);
        }
    );
  }

  private download(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public transferPackages() {
    this.isLoading = true;
    const selectedPackages = Object.values(this.scannedPackages).map(pkg => pkg.id);
    const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
    modal.result.then((data: { isSuccess: boolean, partnerId?: number }) => {
          if (data && data.isSuccess) {
            // Call transfer API
            this.shipmentsService.transferPackageByIds(data.partnerId, selectedPackages).subscribe(() => {
              this.translateService.get(['ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY', 'ACTIONS.OK']).subscribe((message) => {
                this.confirmationService.confirm({
                  message: message['ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY'],
                  rejectVisible: false,
                  acceptLabel: message['ACTIONS.OK']
                });
              });
              this.isLoading = false;
              this.resetPackages();
            }, (err) => {
              this.download('errors.txt', err.error.error);
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }
        }, () => {
          this.isLoading = false;
        }
    ).catch(
        () => {
        }
    );
  }

  public closeChangePackageStatusPopOver(popOver, event) {
    popOver.close();
    if (event.isSuccess) {
      this.translateService.get(['ALERTS.CHANGED_STATUS', 'ACTIONS.OK']).subscribe(
          (data) => {
            this.confirmationService.confirm({
              message: data['ALERTS.CHANGED_STATUS'],
              rejectVisible: false,
              acceptLabel: data['ACTIONS.OK']
            });
          }
      );
      this.resetPackages();
    }
  }

  public assignTriggeredPackage(shipment) {
    this.triggeredPackage = shipment;
  }

  public disableFinishScanningBtn() {
    let length;
    length = Object.keys(this.scannedPackages).length;
    return !length;
  }

  public disableResetScanningBtn() {
    if (this.isReceiverFromDriver) {
      return !Object.keys(this.scannedPackages).length;
    } else {
      return !Object.keys(this.packages).length;
    }
  }

  public actualReceivePartnerPackages() {
    this.isLoading = true;
    const body = {
      ids: Object.values(this.scannedPackages).map(pkg => pkg.id),
      hubId: this.hubId,
      isReceiveFromPartner: true
    };
    this.shipmentsService.receivePartnerPackages(body).subscribe((data: any) => {
      if (data !== undefined && data && data.url !== undefined) {
        window.open(data.url, '_blank');
      }
      this.isLoading = false;
      this.translateService.get(['ALERTS.RECEIVE_FROM_PARTNER_SUCCESS', 'ACTIONS.OK']
      ).subscribe((res: any) => {
        this.confirmationService.confirm({
          message: res['ALERTS.RECEIVE_FROM_PARTNER_SUCCESS'],
          rejectVisible: false,
          acceptLabel: res['ACTIONS.OK'],
        });
        this.isScanning = false;
        this.scannedPackages = {};
        this.getPackages(true);
      });
    }, (error) => {
      this.isLoading = false;
      this.translateService.get(['ALERTS.RECEIVE_FROM_PARTNER_FAILED', 'ACTIONS.OK']).subscribe((res: any) => {
        this.confirmationService.confirm({
          message: res['ACCOUNTING.RECEIVE_FROM_PARTNER_FAILED'] + ': ' + error.err.err,
          rejectVisible: false,
          acceptLabel: res['ACTIONS.OK'],
        });
        this.isScanning = false;
      });
    });
  }

  public changeReceiverPhone(receiverPhone, pkgId) {
    if (this.userService.getPhonePattern(this.userService.userInfo.companyId === 144).test(receiverPhone)) {
      const body = {};
      body['receiverPhone'] = receiverPhone;
      this.shipmentsService.editReceiverInfo(pkgId, body, 'PHONE').subscribe(() => {
        this.translateService.get('ALERTS.RECEIVER_PHONE_SUCCESSFULLY').subscribe(
            (res) => {
              this.messageService.add({severity: 'success', detail: res});
            }
        );
      }, () => {
      });
    } else {
      this.translateService.get('ALERTS.RECEIVER_PHONE_ERROR').subscribe(
          (res) => {
            this.messageService.add({severity: 'error', detail: res});
          }
      );
    }
  }

  public changeReceiverName(receiverName, pkgId) {
    if (receiverName && receiverName !== '') {
      const body = {};
      body['receiverName'] = receiverName;
      this.shipmentsService.editReceiverInfo(pkgId, body, 'NAME').subscribe(() => {
        this.translateService.get('ALERTS.RECEIVER_NAME_SUCCESSFULLY').subscribe(
            (res) => {
              this.messageService.add({severity: 'success', detail: res});
            }
        );
      });
    }
  }

  private packagesListToMap(packagesList: PackageModel[]) {
    const map = {};
    packagesList.forEach(pkg => {
      map[pkg.barcode] = pkg;
      if (this.scannedPackages[pkg.barcode]) {
        map[pkg.barcode].isSelected = true;
      }
    });
    return map;
  }

  private pushPackagesToMap(packagesList: PackageModel[], packagesMap: { [barcode: number]: PackageModel }) {
    packagesList.forEach(pkg => {
      packagesMap[pkg.barcode] = pkg;
      if (this.scannedPackages[pkg.barcode]) {
        packagesMap[pkg.barcode].isSelected = true;
      }
    });
  }

  trackPackageById(index: number, item: PackageModel) {
    return item.id;
  }

  objectValues(obj: any) {
    return Object.values(obj);
  }

  enterContainerNumber() {
    this.isLoading = true;
    const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
    modal.componentInstance.isAddToContainer = true;
    modal.result.then(
        (data: { containerNumber: number}) => {
          if (data && data.containerNumber) {
            this.getContainerInfo(data.containerNumber);
          } else {
            this.isLoading = false;
          }
        }
    ).catch(
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }
  getContainerInfo(containerNumber) {
    this.shipmentsService.getContainerInfo(containerNumber).subscribe(
        (container: any) => {
          if (container.id) {
            this.addPackagesToContainer(container.id);
          } else {
            this.isLoading = false;
          }
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );

  }

  addPackagesToContainer(containerId) {
    this.shipmentsService.addPackagesToContainer(containerId, {ids: Object.values(this.packages).map((pkg: any) => pkg.id)}).subscribe(
        (res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('LCL.ALERTS.ITEM_ADDED_TO_CONTAINER_SUCCESSFULLY')
          });
          this.resetPackages();
          this.isLoading = false;
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  unloadFromContainer(packageId) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.shipmentsService.unloadFromContainer(packageId).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('LCL.ALERTS.ITEM_UNLOADED_SUCCESSFULLY')
          });
          this.resetPackages();
          this.isLoading = false;
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );

  }

  transferToStorm() {
    this.isLoading = true;
    const body = {ids: Object.values(this.packages).map((pkg: any) => pkg.id)};
    this.confirmationService.confirm({
      message: this.translateService.instant('ALERTS.TRANSFER_TO_STORM_CONFIRMATION'),
      accept: () => {
        this.shipmentsService.transferToStorm(body).subscribe(
            () => {
              this.resetPackages();
              this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
            }, (error) => {
              console.error(error);
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            }
        );
      },
      reject: () => {
        this.isLoading = false;
      },
      acceptLabel: this.translateService.instant('GENERAL.YES'),
      rejectLabel: this.translateService.instant('GENERAL.NO'),
    });
  }

  transferToMughrabe() {
    this.isLoading = true;
    const body = {ids: this.getSelectedPackages().map(pkg => pkg.id)};
    this.confirmationService.confirm({
      message: this.translateService.instant('ALERTS.TRANSFER_TO_MUGHRABE_CONFIRMATION'),
      accept: () => {
        this.shipmentsService.transferToMughrabe(body).subscribe(
            () => {
              this.resetPackages();
              this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
            }, (error) => {
              console.error(error);
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            }
        );
      },
      reject: () => {
        this.isLoading = false;
      },
      acceptLabel: this.translateService.instant('GENERAL.YES'),
      rejectLabel: this.translateService.instant('GENERAL.NO'),
    });
  }

  public loadCustomersLazy($event) {

    this.loadingDrivers = true;
    const query = `?page=${this.lazyLoadCount}&pageSize=30&search=` + ($event.query ? $event.query : '');
    let ob;
    let isSpecific = false;
    if (this.type === 'EXPORT') {
      ob = this.usersService.getCodCustomers({page: this.lazyLoadCount, search: ($event.query ? $event.query : '')}, 'SORT');
    } else {
      if (this.loadSpecificCustomer) {
        ob = this.usersService.getCustomer(this.customerId);
        isSpecific = true;
        this.loadSpecificCustomer = false;
      } else {
        ob = this.usersService.getCustomers(query);
      }
    }
    if (this.loadEntitiesRequest) {
      this.loadEntitiesRequest.unsubscribe();
    }
    this.loadEntitiesRequest = ob.subscribe(
        (response: any) => {
          this.loadingDrivers = false;
          const list = this.type === 'EXPORT' ? response.massCodPackages : isSpecific ? [response] : response.customers;
          this.storesList.push(...list);
          this.numberOfCustomers = isSpecific ? 1 : response.totalRecordsNo;
        }, (error) => {
          this.loadingDrivers = false;
          console.error(error);
        }
    );
  }

  getOrderByOptions() {
    this.translateService.get(ACCOUNTANT_REPORT_ORDER_BY).subscribe((labels) => {
      this.orderByOptions = ACCOUNTANT_REPORT_ORDER_BY.map((value) => ({
        label: labels[value],
        value
      }));
      this.selectedOrderByOption = this.orderByOptions[0];
    });
  }

  onChangeOrderBy() {
    this.packages = [];
    this.pageNumber = 0;
    this.initPackages();
  }

  onDateFilterChange() {
    this.packages = [];
    this.pageNumber = 0;
    this.initPackages();

  }

  onSearch() {
    this.packages = [];
    this.pageNumber = 0;
    this.initPackages();
  }

  onDateSelected($event: any) {
    this.packages = [];
    this.pageNumber = 0;
    this.fromDate = new Date($event.start);
    this.toDate = new Date($event.end);
    this.initPackages();
  }
  public setPaginatorQueryParam() {
    this.setQueryParams();
  }

  private setQueryParams() {
    const queryParams = {};
    if (this.searchContent) {
      queryParams['search'] = this.searchContent;
    } else {
      delete queryParams['search'];
    }

    if (this.selectedDateFilter && this.selectedDateFilter.value !== 'NONE') {
      queryParams['dateFilterType'] = this.selectedDateFilter.value;
    } else {
      delete queryParams['dateFilterType'];
    }
    queryParams['first'] = this.first;
    queryParams['pageSize'] = this.pageSize;

  }
  showConfirmSortPkg(event) {
    event.stopPropagation();
    this.translateService.get(['ACCOUNTING.FINISH_SORTING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'])
        .subscribe((res: any) => {
          this.confirmationService.confirm({
            message: res['ACCOUNTING.FINISH_SORTING_CONFIRMATION'],
            accept: () => {
              this.sortPackage();
            },
            acceptLabel: res['GENERAL.YES'],
            rejectLabel: res['GENERAL.NO']
          });
        });
  }
  public sortPackage() {
    this.isLoading = true;
    let pkgIds = [];
    if (this.getSelectedPackages().length > 0) {
      pkgIds = this.getSelectedPackages().map(pkg => pkg.barcode);
    }
    if (pkgIds.length === 0) {
      return;
    }
    this.isAllSelected = false;
    if (this.isHoldCustody) {
      this.shipmentsService.receivePackagesWithoutReleasingCustody(pkgIds).subscribe((res: any) => {
            this.translateService.get(
                'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                      this.messageService.add({severity: 'success', detail: data});
                    }
                );
            this.isLoading = false;
            this.packages = [];
            this.pageNumber = 0;
            this.initPackages();
          },
          error => {
            this.isLoading = false;
          });
    } else {
      this.shipmentsService.sortPackages(pkgIds, this.customerId).subscribe((res: any) => {
            this.translateService.get(
                'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                      this.messageService.add({severity: 'success', detail: data});
                    }
                );
            this.isLoading = false;
            this.packages = [];
            this.pageNumber = 0;
            this.initPackages();

          },
          error => {
            this.isLoading = false;
          });
    }
  }

  private initRoutesParams() {
    this.route.queryParams.subscribe(
        (params: Params) => {
          this.params = params;
          if (params.fromDate) {
            this.fromDate = new Date(params.fromDate);
          }
          if (params.toDate) {
            this.toDate = new Date(params.toDate);
          }
          if (params.page && params.page === 'IN_CAR') {
            this.inCar = true;
          }
          if (params.is_hold_custody) {
            this.isHoldCustody = params.is_hold_custody;
            if (params.by) {
              this.holdCustodyBy = params.by;
            }
          }
          // this.type = 'RECEIVE';
          if (this.type === 'RECEIVE') {
            this.receive = true;
          }
          if (params.isTransfer) {
            this.isBankTransfer = true;
          }
          if (params.first) {
            this.first = params.first;
          }
          if (params.pageSize) {
            this.pageSize = params.pageSize;
          }
          if (params.page && params.page === 'DELIVERED') {
            this.isDeliveredPage = true;
          }
          this.dateFiltersList = this.setDateFiltersList();
          // this.initDateFilter(params.dateFilterType, params.fromDate, params.toDate);
        }
    );
  }
  public setDateFiltersList() {
    this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
      this.dateRangPickerOptions = options;

      if (this.fromDate) {
        this.dateRangPickerOptions.startDate = new Date(this.fromDate);
      }
      if (this.toDate) {
        this.dateRangPickerOptions.endDate = new Date(this.toDate);
      }
    });

    const list = EXPORTED_DATE_FILTERS_LIST.map((value) => {
      let label = value;
      this.translateService.get(value)
          .subscribe((data) => {
            label = data;
          });
      return {label, value};
    });

    const i = list.findIndex(l => l.value === this.selectedDateFilterCustomer);
    this.selectedDateFilter = this.selectedDateFilterCustomer ? list[i] : list[0];

    return list;
  }
  public initDateFilter(dateFilterType, fromDate, toDate) {
    if (dateFilterType !== undefined && EXPORTED_DATE_FILTERS_LIST.includes(dateFilterType)) {
      this.selectedDateFilter = dateFilterType;
    } else {
      if (this.export || this.isDeliveredPage) {
        this.selectedDateFilter = 'NONE';
      } else {
        if (this.sort) {
          this.selectedDateFilter = 'SORT_DATE';
        } else if (this.receive) {
          this.selectedDateFilter = 'RECEIVE_DATE';
        } else {
          this.selectedDateFilter = 'CREATED_DATE';
        }
      }
    }

  }

  private initPackages(forceFetch = false, from = 'default') {
    this.spinnerState = SpinnerState.LOADING;
    if (forceFetch) {
      this.packages = [];
      this.pageNumber = 0;
    }
    if (from !== 'lazy') {
      this.selectedCod = 0;
      this.selectedCostSum = 0;
      this.selectedCodFees = 0;
      this.selectedCodWithoutCost = 0;
      this.scannedPackages = [];
      this.isThereSelection = false;
    }
    if (this.pageNumber === 0) {
      this.unsubscribePreviousReqs();
    }
    const params = this.createParams();
    let getSumValues;
    if (this.isBankTransfer) { // Bank transfer COD
      this.previousRequests.push(this.usersService.getTransferCODPackages(this.customerId, params).subscribe(
          (response: { data?: any[], totalRecordsNo: number }) => {
            this.packages = response.data;
            if (this.totalRecords > 0) {
              this.spinnerState = SpinnerState.LOADED;
            } else {
              this.spinnerState = SpinnerState.EMPTY;
            }
          }
      ));
    } else if (this.isMassInvoice) {
      this.usersService.getMassInvoiceDetails(this.customerId, params).subscribe(
          (response: any) => {
            this.packages = response.data;
            if (this.totalRecords > 0) {
              this.spinnerState = SpinnerState.LOADED;
            } else {
              this.spinnerState = SpinnerState.EMPTY;
            }
          }
      );
    } else {
      this.previousRequests.push(this.usersService.getCodPackages(this.customerId, params, this.type, this.isHoldCustody).subscribe(
          (response: { codpackages?: any[], data?: any[], totalRecordsNo: number, costSum: number, codSum: number, name: string } | any) => {
            this.packages.push(...(response.codpackages ? response.codpackages : response.data));
            if (this.totalRecords > 0) {
              this.spinnerState = SpinnerState.LOADED;
            } else {
              this.spinnerState = SpinnerState.EMPTY;
            }
            if (this.isHoldCustody) {
              this.getTotals();
            }
          }
      ));
      if (!this.isHoldCustody) {
        if (this.type === 'RECEIVE') {
          getSumValues = this.usersService.getReciveCustomerSumValues(params);
        } else {
          const params1 = {};
          if (this.sort) {
            params1['status'] = 'SORTED_BY_ACCOUNTANT';
          } else {
            params1['status'] = this.sumStatus;
          }
          params1['massCodPackageId'] = this.customerId;
          params1['dateFilterType'] = params['dateFilterType'];
          params1['fromDate'] = params['fromDate'];
          params1['order-by'] = params['order-by'];
          params1['timezone'] = params['timezone'];
          params1['toDate'] = params['toDate'];
          params1['search'] = params['search'];
          for (const key in params1) {
            if (!params1[key]) {
              delete params1[key];
            }
          }
          getSumValues = this.usersService.getCostumersSumValues(params1);

        }
        // if (this.export && this.type !== 'EXPORT') {
        //   const params1 = {};
        //   params1['status'] = 'SORTED_BY_ACCOUNTANT';
        //   params1['massCodPackageId'] = this.customerId;
        //   getSumValues = this.usersService.getCostumersSumValues(params1);
        // } else {
        //   getSumValues = this.usersService.getReciveCustomerSumValues(params);
        // }
        this.previousRequests.push(getSumValues.subscribe((response: any) => {
          this.totalRecords = response.totalPackagesNumber;
          this.totalCodWithoutCost = response.totalCodWithoutCost;
          this.totalCodFees = response.codFeesSum;
          this.totalCod = response.totalCod;
          this.totalCostSum = response.totalCost;
          if (from !== 'lazy') {
            this.selectedCod = 0;
            this.selectedCostSum = 0;
            this.selectedCodFees = 0;
            this.selectedCodWithoutCost = 0;
          }
        }));
      }
    }
  }

  getTotals() {
    this.usersService.getReciveCustomerSumValues({customerId: this.customerId, isWithoutReleasingCustody: 1}).subscribe( (response: any) => {
      this.totalRecords = response.totalPackagesNumber;
      this.totalCodWithoutCost = response.totalCodWithoutCost;
      this.totalCodFees = response.codFeesSum;
      this.totalCod = response.totalCod;
      this.totalCostSum = response.totalCost;
    });
  }

  showDeliverSelectedButton() {
    return this.type === 'EXPORT' && this.userService.userInfo.isDeliverPartialMassReport;
  }
  showPrintButton() {
    // return false;
    // keep the next line commented maybe will need it
    return ['EXPORT', 'DELIVERED'].indexOf(this.type) > -1;
  }

  downloadPdf() {
    this.isLoading = true;
    const pkgId = this.customerId;
    this.shipmentsService.downloadMassPackagePdf(pkgId).subscribe((res: any) => {
          window.open(res.url, '_blank');
          this.isLoading = false;
        },
        error => {
          console.error(error);
          this.isLoading = false;
        });
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  deliverSelected() {
    this.isLoading = true;
    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.codPackageId);
    const modal = this.modalService.open(ChoosePaymentTypeComponent,
        {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
    modal.result.then(
        (data: { paymentType: any, documentNumber: any, deliveryDate: any, transferFees: number }) => {
          if (data.paymentType) {
            const body = {
              ids: selectedPackages, paymentType: data.paymentType
            };
            if (data.documentNumber) {
              body['documentNumber'] = data.documentNumber;
            }
            if (data.deliveryDate) {
              body['deliveryDate'] = this.transformDate(data.deliveryDate);
            }
            if (data.transferFees) {
              body['transferFees'] = data.transferFees;
            }
            this.usersService.deliverSelected(this.customerId, body).subscribe((res: any) => {
              this.translateService.get(
                  'ALERTS.PACKAGES_DELIVERED_SUCCESSFULLY'
              )
                  .subscribe(
                      (message) => {
                        this.messageService.add({severity: 'success', detail: message});
                        this.packages = [];
                        this.pageNumber = 0;
                        this.initPackages(true);
                      }
                  );
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            });
          } else {
            this.isLoading = false;
          }
        });
  }

  public createParams() {
    const params = {
      pageSize: this.pageSize,
      page: this.pageNumber + 1,
    };

    if (this.searchContent) {
      params['search'] = this.searchContent;
    }
    if ( this.customerId) {
      params['customerId'] = this.customerId;
    }
    if (this.type) {
      params['type'] = this.type;
    }
    if (this.isMassInvoice || (this.selectedDateFilter && this.selectedDateFilter.value !== 'NONE')) {
      if(!this.isMassInvoice) {
        params['dateFilterType'] = this.selectedDateFilter.value;
      }
      params['utcOffset'] = moment().utcOffset().toString();
      if (this.fromDate || this.toDate) {
        params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      if (this.fromDate) {
        params['fromDate'] = this.transformDate(this.fromDate);
      }
      if (this.toDate) {
        params['toDate'] = this.transformDate(this.toDate);
      }
    }

    if (this.isHoldCustody) {
      params['type'] = this.holdCustodyBy;
      params['entityId'] = this.customerId;
    }
    if (this.selectedOrderByOption) {
      params['order-by'] = this.selectedOrderByOption.value;
    }
    return params;
  }

  public editPackage(barcode) {
    this.selectedPackageBarcode = barcode;
    this.displayAddPackage = true;
  }

  public onHideAddPackage (event) {
    this.displayAddPackage = event;
    this.selectedPackageBarcode = null;
    this.onSearch();
  }

  scroll($event: any) {
    if ($event.target.offsetHeight + $event.target.scrollTop + 10 >= $event.target.scrollHeight && !this.loadingDrivers) {
      if (this.storesList && this.storesList.length >= this.numberOfCustomers) {
        return;
      }
      this.lazyLoadCount++;
      this.loadCustomersLazy({query: this.driverSearch});
    }
  }

  onSearchCustomer(param: { query: string }) {
    this.lazyLoadCount = 1;
    this.storesList = [];
    this.loadCustomersLazy(param);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.table && this.table.wrapperViewChild) {
        const el = this.table.wrapperViewChild.nativeElement;
        el.onscroll = (event) => {
          this.onScrollTable(event);
        };
      }
    }, 300);
  }

  public addBarcodesToSortedMass() {
    const modal = this.modalService.open(BulkUpdateComponent, {backdrop: 'static', windowClass: 'create-new-container', size: 'lg'});
    modal.componentInstance.isChangeStatus = false;
    modal.componentInstance.isReceive = true;
    modal.componentInstance.isCodMassPackage = false;
    modal.componentInstance.exportBulkBarcodes = true;
    modal.componentInstance.customerId = this.customerId;
    this.stopReadingByBarcode = true;
    modal.result.then(
        (status: { isSuccess: boolean }) => {
          this.stopReadingByBarcode = false;
          // console.log(status.isSuccess);
          let successMessage = '';
          let errorMessage = '';
          this.translateService.get(
              ['ALERTS.BULK_EXPORT_SUCCESSFUL', 'ALERTS.BULK_EXPORT_FAILED']
          ).subscribe(
              (data) => {
                successMessage = data['ALERTS.BULK_EXPORT_SUCCESSFUL'];
                errorMessage = data['ALERTS.BULK_EXPORT_FAILED'];
              }
          );
          if (status.isSuccess) {
            this.messageService.add({severity: 'success', detail: successMessage});
            if (!this.isHoldCustody) {
              // this.initCustomerSumValues();
            }
            this.packages = [];
            this.pageNumber = 0;
            this.initPackages();
          } else {
            // this.messageService.add({severity: 'error', detail: errorMessage});
          }
        }
    ).catch(
        (error) => {
          this.stopReadingByBarcode = false;
        }
    );

  }
  public partialExport() {
    this.isLoading = true;
    this.translateService.get(['ALERTS.PARTIAL_CONFIRM', 'GENERAL.YES', 'GENERAL.NO']).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: res['ALERTS.PARTIAL_CONFIRM'],
        reject: () => {
          this.isLoading = false;
        },
        accept: () => {
          this.partialExportSend();
        },
        acceptLabel: res['GENERAL.YES'],
        rejectLabel: res['GENERAL.NO'],
      });
    });
  }
  openExcel() {
    this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
    ).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
        accept: () => {
          this.isLoading = true;
          const packageIds = this.getSelectedPackages().map(selected => selected.id);
          (this.customExport ? this.shipmentsService.exportCodReportForCustomer(this.customerId, {})
                              : this.shipmentsService.exportToExcel(this.customerId, { packageIds })).subscribe(
              (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
              }, (error) => {
                this.isLoading = false;
              });
        },
        reject: () => {
          this.isLoading = false;
        },
        acceptLabel: res['GENERAL.YES'],
        rejectLabel: res['GENERAL.NO'],
      });
    });

  }

  private partialExportedSuccessfully(pkg) {
    this.confirmationService.confirm({
      message: this.translateService.instant('ALERTS.PRINT_MASS_COD_REPORT_CONFIRMATION'),
      reject: () => {
        this.isLoading = false;
        this.initPackages(true);
      },
      accept: () => {
        this.shipmentsService.downloadMassPackagePdf(pkg.id).subscribe((res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('ALERTS.PACKAGES_EXPORTED_SUCCESSFULLY')
          });
          this.isLoading = false;
          window.open(res.url, '_blank');
          this.initPackages(true);
        });
      },
      acceptLabel: this.translateService.instant('GENERAL.YES'),
      rejectLabel: this.translateService.instant('GENERAL.NO'),
    });
  }

  public partialExportSend() {
    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.codPackageId);
    const body = {
      ids: selectedPackages
    };
    this.usersService.partialDeliver(this.customerId, body).subscribe((res: any) => {
      this.partialExportedSuccessfully(res);
    }, () => {
      this.isLoading = false;
    });
  }
  public bulkSortByBarcode() {
    const modal = this.modalService.open(BulkUpdateComponent, {backdrop: 'static', windowClass: 'create-new-container', size: 'lg'});
    modal.componentInstance.isChangeStatus = false;
    modal.componentInstance.isReceive = true;
    modal.componentInstance.isCodMassPackage = false;
    modal.componentInstance.exportBulkBarcodes = false;
    modal.componentInstance.receiveBulkBarcodes = true;
    modal.componentInstance.title = 'GENERAL.SORT_BULK_BARCODES';
    modal.componentInstance.customerId = this.customerId;
    this.stopReadingByBarcode = true;
    let successMessage = '';
    let errorMessage = '';
    this.translateService.get(
        ['ALERTS.BULK_COD_SORT_SUCCESSFUL', 'ALERTS.BULK_COD_SORT_FAILED']
    ).subscribe(
        (data) => {
          successMessage = data['ALERTS.BULK_COD_SORT_SUCCESSFUL'];
          errorMessage = data['ALERTS.BULK_COD_SORT_FAILED'];
        }
    );
    modal.result.then(
        (status: { isSuccess: boolean }) => {
          if (status.isSuccess) {
            // this.messageService.add({severity: 'success', detail: successMessage});
            this.isLoading = false;
            this.packages = [];
            this.pageNumber = 0;
            this.initPackages();
            this.stopReadingByBarcode = false;
          }
        }
    ).catch(
        (error) => {
          this.messageService.add({severity: 'error', detail: errorMessage});
        }
    );

  }
  isPackageExist(pkg) {
    return !!this.packages.find((p) => {
      return p.barcode === pkg.barcode;
    });
  }

  getPackageIndex(pkg) {
    const index =  this.packages.findIndex((p) => {
      return p.barcode === pkg.barcode;
    });
    return index;
  }

  private getUnSelectedPackages(driverPkgs) {
    return driverPkgs.filter(dpkg => !this.packages.find(pkg => pkg.barcode === dpkg.barcode));
  }

  private initCustomerDetails() {
    // this.usersService.getCustomer(this.customerId).subscribe((res) => {
      this.storesList = [this.singleCustomerDetails];
    // });
  }

  getTotalCod(isSelected?) {
    let sum = 0;
    this.objectValues(this.scannedPackages).forEach((value: any) => {
      sum += value.cod;
    });
    return sum;
  }

  getTotalCodWithoutCost() {
    let sum = 0;
    this.objectValues(this.scannedPackages).forEach((value: any) => {
      sum += value.cod - value.cost;
    });
    return sum;
  }

  getTotalCostSum() {
    let sum = 0;
    this.objectValues(this.scannedPackages).forEach((value: any) => {
      sum += value.cost;
    });
    return sum;  }

  private unsubscribePreviousReqs() {
    this.previousRequests.forEach(req => {
      req.unsubscribe();
    });
    this.previousRequests = [];
  }
}
