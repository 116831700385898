import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/modules/shared.module';
import {ManageCompaniesComponent} from './manage-companies/manage-companies.component';
import {NewCompanyComponent} from './new-company/new-company.component';
import {CompaniesComponent} from './companies/companies.component';
import {CompanyBillingComponent} from './company-billing/company-billing.component';
import {ChooseDateAndNumberComponent} from '../shared/components/choose-date-and-number/choose-date-and-number.component';
import {CompanyBillingPaymentComponent} from './company-billing/company-billing-payment/company-billing-payment.component';
import {RouterModule} from '@angular/router';
import {ToastModule} from 'primeng/toast';
import { CompanyConfigurationNewComponent } from './company-configuration-new/company-configuration-new.component';

@NgModule({
    declarations: [
        ManageCompaniesComponent,
        NewCompanyComponent,
        CompaniesComponent,
        CompanyBillingComponent,
        ChooseDateAndNumberComponent,
        CompanyBillingPaymentComponent,
        CompanyConfigurationNewComponent
    ],
    imports: [
        SharedModule,
        
        RouterModule,
        ToastModule
    ],
    exports: [
        ManageCompaniesComponent
    ]
})
export class CompaniesModule {
}
