import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {VehicleTypeModel} from '../../shared/models/container.model';
import {Inplace} from 'primeng/inplace';
import {ContainersService} from '../services/containers.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-vehicle-types',
    templateUrl: './vehicle-types.component.html',
    styleUrls: ['./vehicle-types.component.scss']
})
export class VehicleTypesComponent implements OnInit {

    vehicleTypes = [];
    isShowNewVehicleType = false;
    isLoading = false;
    newVehicleTypeName = '';
    newVehicleTypeArabicName = '';

    @ViewChild('newVehicleTypeNameInput')
    newVehicleTypeNameInput;

    toggledInplace: Inplace;

    constructor(private containersService: ContainersService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private tranlsateService: TranslateService) {
    }

    ngOnInit() {
        this.getVehicleTypes();
    }

    public getVehicleTypes() {
        this.isLoading = true;
        this.containersService.getVehicleTypes().subscribe((response: any[]) => {
            this.vehicleTypes = response.map(vehicleType => {
                return {
                    id: vehicleType.id,
                    name: vehicleType.name,
                    arabicName: vehicleType.arabicName,
                    newName: vehicleType.name,
                    newArabicName: vehicleType.arabicName
                };
            });
            this.isLoading = false;
        }, (error) => {
            this.isLoading = false;
        });
    }

    toggleInplace(event, inplace: Inplace, vehicleType?, saveChange = false, arabicInplace?: Inplace) {
        if (inplace.active) {
            if (saveChange) {
                if (vehicleType.name !== vehicleType.newName || vehicleType.arabicName !== vehicleType.newArabicName) {
                    this.isLoading = true;
                    this.containersService.editVehicleType(vehicleType.id, {
                        name: vehicleType.newName,
                        arabicName: vehicleType.newArabicName
                    }).subscribe(response => {
                        inplace.deactivate(event);
                        if (arabicInplace) {
                            arabicInplace.deactivate(event);
                        }
                        this.getVehicleTypes();
                    }, (error) => {
                        this.isLoading = false;
                    });
                } else {
                    inplace.deactivate(event);
                }
            } else if (vehicleType) {
                vehicleType.newName = vehicleType.name;
                vehicleType.newArabicName = vehicleType.arabicName;
                inplace.deactivate(event);
            }
        } else {
            inplace.activate(event);
        }
    }

    deleteVehicleType(vehicleType) {
        this.tranlsateService.get(['CONTAINERS_MANAGEMENT.VEHICLE_TYPES.CONFIRM_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
            this.confirmationService.confirm({
                message: values['CONTAINERS_MANAGEMENT.VEHICLE_TYPES.CONFIRM_DELETE'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.isLoading = true;
                    this.containersService.deleteVehicleType(vehicleType.id).subscribe(response => {
                        this.getVehicleTypes();
                        this.tranlsateService.get('ALERTS.VEHICLE_TYPE_DELETED_SUCCESSFULLY').subscribe(value => {
                            this.messageService.add({
                                severity: 'success',
                                detail: value
                            });
                        });
                    }, (error) => {
                        this.isLoading = false;
                    });
                }
            });
        });
    }

    showNewVehicleType(event) {
        this.newVehicleTypeName = '';
        this.newVehicleTypeArabicName = '';
        this.isShowNewVehicleType = true;
        this.newVehicleTypeNameInput.nativeElement.focus();
        if (this.toggledInplace) {
            this.toggledInplace.deactivate(event);
        }
    }

    cancelNewVehicleType() {
        this.isShowNewVehicleType = false;
    }

    submitNewVehicleType() {
        this.isLoading = true;
        this.containersService.newVehicleType({
            name: this.newVehicleTypeName,
            arabicName: this.newVehicleTypeArabicName
        }).subscribe(response => {
            this.getVehicleTypes();
            this.isShowNewVehicleType = false;
        }, (error) => {
            this.isLoading = false;
        });
    }

    activateInplace(activatedInplace: Inplace, event) {
        if (this.toggledInplace) {
            this.toggledInplace.deactivate(event);
        }

        this.toggledInplace = activatedInplace;
        this.cancelNewVehicleType();
    }

    onEdit(event: MouseEvent, inplace: Inplace, arabicInplace: Inplace, vehicleType) {
        this.toggleInplace(event, inplace, vehicleType);
        this.toggleInplace(event, arabicInplace, vehicleType);
    }
}
