import {Component, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {forkJoin} from 'rxjs';
import {DashboardService} from './services/dashboard.service';
import {FADE_ANIMATION} from '../shared/animations/fade-animation';
import {Router} from '@angular/router';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {UserService} from '../shared/services/user.service';
import {DriversService} from '../shared/services/drivers.service';
import {AuthenticationService} from '../shared/services/authentication.service';
import {MessagingService} from '../shared/services/messaging.service';
import {CompaniesService} from '../companies/services/companies.service';
import {DashboardCardsModel} from './models/dashboard-card.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateRangePickerService} from '../shared/services/date-range-picker.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UsersService} from '../users/services/users.service';
import {ROUTE_SHARED, SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {ZonesService} from '../administration/services/zones.service';
import {StorageService} from '../shared/services/storage/storage.service';
import {ShipmentsService} from '../shipment/services/shipments.service';
import {SubscriptionTrackerComponent} from '../shared/behavior/subscription-tracker.component';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationStateService} from '../shared/services/application-state.service';
import {SystemUpdatesService} from '../system-updates/services/system-updates.service';
import * as moment from 'moment';
import {FirebaseApp} from '@angular/fire/compat';

declare let L;

@Component({
    selector: 'app-dashboard-component',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.scss'],
    animations: [FADE_ANIMATION]
})
export class DashboardComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {
    public currentLang = '';
    public topStatsCards: any = [];

    public fromDate: Date;
    public toDate: Date;
    public permissions = '';
    fDrivers: any[] = [];
    selectedDriver: string;
    gps: any;
    drivers: any[] = [];
    hubDrivers: any[] = [];
    items: any[];
    item: string;

    /**
     * Firebase database ref.
     */
    public database;

    /**
     * LeafletJS map ref.
     */
    public map;
    public mapMarkers = [];
    public isNotify = false;
    public isBlock = false;
    public notificationMessage: string;
    public isLoading = false;
    public dashboardCards: DashboardCardsModel;
    public filterForm: FormGroup;
    public listOfBestCustomers: { lable: string }[] = [];
    public listOfBestDrivers: { lable: string }[] = [];
    public deliveredPercentageToAddedPkgs: { monthName: string, percentage: number }[] = [];
    public shippingPerVillages: { index: number, cost: number, lable: string } [] = [];
    public excludeDatesFilter = false;
    public dateRangPickerOptions: any = {};
    public isShowDeliveredToAllPackagesRatio = false;
    public isShowIncome = false;
    public isShowShippingPerCity = false;
    public isShowDriversEvaluation = false;
    private readonly barWidth = 15;
    public getIncomePeriodFilter = 'MONTHLY';
    public deliveredToAllPackagesRatioPeriodFilter = 'MONTHLY';
    public shippingPerCityPeriodFilter = 'MONTHLY';
    public driversEvaluationPeriodFilter = 'MONTHLY';
    public highestProfitRate = '0';
    public profitPercentageRate = '0';
    @ViewChild('template') templateRef: TemplateRef<any>;
    @ViewChild('customersChart ') customersChart;
    @ViewChild('dateEl ') dateEl;
    public customersList: any[] = [];
    public driversMultiSelectList: any[] = [];
    public citiesList: any[] = [];
    public selectedCustomers: { id: number, name: string }[] = [];
    public selectedDeliveredToAddedCustomers: { id: number, name: string }[] = [];
    public selectedDriversMultiSelect: { id: number, name: string }[] = [];
    public selectedCities: { id: number, name: string }[] = [];
    public getIncomeDateFilter: Date = new Date(this.getDateBeforeNumberOfDays(7));
    public deliveredToAllPackageRatioDateFilter: Date = new Date(this.getDateBeforeNumberOfDays(7));
    public listOfBestDriverDateFilter: Date = new Date(this.getDateBeforeNumberOfDays(7));
    public shippingPerCityDateFilter: Date = new Date(this.getDateBeforeNumberOfDays(7));
    public yearDateFilter: Date = new Date();
    public monthDateFilter: Date = new Date();
    public weekDateFilter: Date = new Date();
    public currentPicker = '';
    private PAGE_SIZE = 10;
    public driverListPageIndex = 1;
    public pageIndex = 1;
    public debounceUpdateCost;
    public driversList: { id: number, name: string, countOfCarriedPackages: number }[] = [];
    public isLoadingDriversList = false;
    public isLoadingGetIncome = false;
    public isLoadingDeliveredToAddedPackages = false;
    public isLoadingShippingPerCity = false;
    // public isLoadingShippingVillage = false;
    public isLoadingListOfBestDrivers = false;
    public isLazyLoadDeliveredToAddedPkgs = false;
    public isLazyLoadShippingPerCity = false;
    public isLazyLoadDriversEvaluation = false;
    public multiSelectFilterSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 0,
        allowSearchFilter: true,
        clearSearchFilter: true,
        enableCheckAll: true,
        selectAllText: 'تحديد الكل',
        unSelectAllText: 'إلغاء تحديد الكل',
        noDataAvailablePlaceholderText: 'لا يوجد بيانات',
        searchPlaceholderText: 'ابحث'
    };
    public initialChartsLoadedIds = [];
    // public dashboardData: DashboardStatsModel[] = [];

    ////////////
    public wholePageLoading = true;
    public formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    @ViewChild('getIncomePopOver') getIncomePopOver: NgbPopover;
    @ViewChild('deliveredToAllPackagesDatePopOver') deliveredToAllPackagesDatePopOver: NgbPopover;
    @ViewChild('shippingPerCityPopOver') shippingPerCityPopOver: NgbPopover;
    @ViewChild('driversEvaluation') driversEvaluation: NgbPopover;
    private observer: MutationObserver;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    selectedDriversStatus = 'ALL';
    onlineDrivers: number;
    offlineDrivers: number;
    isGettingDriversList = false;
    markAllAsSeenRequest = false;
    public companyHadStations = [256, 22];
    public companyId;

    constructor(@Inject(FormBuilder) private formsBuilder: FormBuilder,
                private dashboardService: DashboardService,
                private shipmentsService: ShipmentsService,
                private router: Router,
                private app: FirebaseApp,
                private datePipe: DatePipe,
                public driverService: DriversService,
                private modalService: NgbModal,
                private userService: UserService,
                private messagingService: MessagingService,
                private translateService: TranslateService,
                private authenticationService: AuthenticationService,
                private dateRangePickerService: DateRangePickerService,
                private companyService: CompaniesService,
                private messageService: MessageService,
                private usersService: UsersService,
                private zonesService: ZonesService,
                private applicationStateService: ApplicationStateService,
                private confirmationService: ConfirmationService,
                private systemUpdatesService: SystemUpdatesService,
                private storageService: StorageService) {
        super();
    }

    /*Months*/
    public readonly months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    public getIncomeChartData: any[] = [];
    public getIncomeChartColumns;
    getIncomeChartOptions = {
        tooltip: {isHtml: true},
        bar: {groupWidth: this.barWidth},
        colors: ['#FCA429'],
        axes: {
            y: {
                distance: {
                    label: 'Leads'
                },
                brightness: {
                    side: 'right',
                    label: 'Value (INR)'
                }
            }
        },
        vAxis: {format: 'decimal'},
        hAxis: {
            slantedText: true,
        },
    };


    // grouped column chart

    public deliveredToAllPackageRatioChartData: any[] = [];
    public deliveredToAllPackageColumns: any[] = [];
    deliveredToAllPackagesChartOptions = {
        tooltip: {isHtml: true},
        bar: {groupWidth: this.barWidth},
        colors: ['#2ED47A', '#FCA429']
    };
    /*Bar Chart*/

    public shippingPerCityChartData: any[] = [];
    public shippingPerCityColumns: any[] = [];
    shippingPerCityChartOptions = {
        tooltip: {isHtml: true},
        bar: {groupWidth: this.barWidth},
        colors: ['#2ED47A']
    };

    // stacked bar chart
    public driversEvaluationChartData: any[] = [];
    public driversEvaluationColumn: any[] = [];
    driversEvaluationChartOptions = {
        tooltip: {isHtml: true},
        bar: {groupWidth: this.barWidth},
        colors: ['#2ED47A', '#FCA429', '#F7685B'],
        hAxis: {},
        vAxis: {
            minValue: 0
        },
        isStacked: true
    };
    public currency = '';
    public mapDriverId = null;
    isNotifyNewUpdate = false;
    public isMapViewOnly: boolean;

    ngOnInit() {
        this.isMapViewOnly = this.userService.userInfo.isMapViewOnly;
        this.companyId = this.authenticationService.companyId;
        if (this.userService.userInfo.role === 'TICKETING_SYSTEM_ADMIN') {
            this.navigateTo('ticketing-system');
            return;
        }
        if (this.userService.userInfo.role === 'FULFILLMENT_USER') {
            this.navigateTo(['fulfilment/manage-warehouses']);
            return;
        }
        this.currentLang = this.translateService.currentLang;
        this.translateService.onLangChange.subscribe((newLang) => {
            this.currentLang = newLang.lang;
            this.getTranslations();
        });
        this.currency = this.userService.getCurrency();
        this.initForm();
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
            options.startDate = moment(this.fromDate);
            options.endDate = moment(this.toDate);
        });

        const promises = [
            this.usersService.getCustomersDropDown(''),
            this.userService.getUserInfo()
        ];
        this.subscriptions.push(forkJoin(promises).subscribe((response) => {
            const customersList = response[0];
            const userInfo = response[1];
            this.wholePageLoading = false;
            this.handleUserInfo(userInfo);
            this.setCustomersList(customersList);
            this.subscriptions.push(this.shipmentsService.shipmentAddedEvent.subscribe(
                (isShipmentAdded) => {
                    if (isShipmentAdded) {
                        this.handleUserInfo(userInfo);
                    }
                }
            ));
        }, (error) => {
            this.wholePageLoading = false;
        }));


        this.filterForm = this.formsBuilder.group({
            searchText: [''],
            fromDate: [this.fromDate],
            toDate: [this.toDate]
        });

        this.observer = new MutationObserver(this.addRadiusForChartBars);
        this.getTranslations();
        this.userService.getSystemUpdateCounterAsObservable().subscribe(value => {
            if (value) {
                this.isNotifyNewUpdate = true;
                this.markAllAsSeenRequest = false;

            } else {
                this.isNotifyNewUpdate = false;
            }
        });
        // observer.observe()
        // this.getIncome();
        // this.getDeliveredToAllPackagesChartData();
    }

    public getTranslations() {
        this.translateService.get([
            'GENERAL.SELECT_ALL',
            'GENERAL.UNSELECT_ALL',
            'GENERAL.SEARCH',
            'ALERTS.NO_DATA_AVAILABLE',
            'DASHBOARD.CHART_DESCRIPTION_LABELS.ADDED_PACKAGES',
            'DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED',
            'DASHBOARD.INCOME',
            'GENERAL.CITY',
            // Drivers Evalution
            'DASHBOARD.CHART_DESCRIPTION_LABELS.RETURNED',
            'DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED',
            'DASHBOARD.CHART_DESCRIPTION_LABELS.PICKED_PACKAGES'
        ]).subscribe(values => {
            this.multiSelectFilterSettings = {
                singleSelection: false,
                idField: 'id',
                textField: 'name',
                itemsShowLimit: 0,
                allowSearchFilter: true,
                clearSearchFilter: true,
                enableCheckAll: true,
                selectAllText: values['GENERAL.SELECT_ALL'],
                unSelectAllText: values['GENERAL.UNSELECT_ALL'],
                noDataAvailablePlaceholderText: values['ALERTS.NO_DATA_AVAILABLE'],
                searchPlaceholderText: values['GENERAL.SEARCH']
            };

            this.getIncomeChartColumns = ['Year', values['DASHBOARD.INCOME']];

            this.deliveredToAllPackageColumns = [
                'Year',
                values['DASHBOARD.CHART_DESCRIPTION_LABELS.ADDED_PACKAGES'],
                values['DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED']
            ];

            this.shippingPerCityColumns = ['Year', values['GENERAL.CITY']];

            this.driversEvaluationColumn = [
                'Year',
                values['DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED'],
                values['DASHBOARD.CHART_DESCRIPTION_LABELS.PICKED_PACKAGES'],
                values['DASHBOARD.CHART_DESCRIPTION_LABELS.RETURNED']
            ];
        });
    }

    setDriversStatus(status) {
        this.selectedDriversStatus = status;
        this.initDriversListener();
    }

    public handleUserInfo(userInfo) {
        this.permissions = userInfo.role;
        if (this.permissions === 'CLERK' || this.permissions === 'HUB_CLERK' || this.permissions === 'CUSTOMER_ACCOUNT_MANAGER') {
            this.navigateTo('manage-shipments');
            return;
        }
        if (this.permissions === 'ACCOUNTANT') {
            this.navigateTo('receive-cod');
            return;
        }
        if (this.permissions === 'TICKETING_USER') {
            this.navigateTo('ticketing-system');
            return;
        }
        if (this.permissions === 'ADMIN' || this.permissions === 'SUPER_ADMIN') {
            this.dashboardService.getDashboardSettings().subscribe(
                (dashboardSettings) => {
                    this.setDashboardSettingsFlags(dashboardSettings);
                }
            );
        }

        if (this.permissions !== 'ACCOUNTING_MANAGER') {
            this.getCards();
        }
        this.initDates();
        this.initStats(); // map
        this.firebaseSubscribe();
        this.getCompanyBillingNotification();
    }

    @HostListener('scroll', ['$event'])
    onScrollMainPage(event: any) {
        this.handleChartsLoading(event);
    }

    public handleChartsLoading(event: any) {
        // Handle Deliverd to all package ratio chart.
        if (this.isShowDeliveredToAllPackagesRatio &&
            this.initialChartsLoadedIds.indexOf('Delivered_Ratio') === -1) {

            const deliveredRatioChart = document.getElementById('Delivered_Ratio');

            if (deliveredRatioChart && event.target.offsetHeight + event.target.scrollTop > deliveredRatioChart.offsetTop) {
                this.initialChartsLoadedIds.push('Delivered_Ratio');
                this.setDateToFirstMonthDay('DELIVERED_TO_ALL_PACKAGES_RATIO');
                this.getDeliveredToAllPackagesChartData();
                this.getDeliveredPercentageToAddedPkgs();
            }

        }

        // Handle Shipping per city chart
        if (this.isShowShippingPerCity &&
            this.initialChartsLoadedIds.indexOf('Shipping_Per_City') === -1) {

            const deliveredRatioChart = document.getElementById('Shipping_Per_City');

            if (event.target.offsetHeight + event.target.scrollTop > deliveredRatioChart.offsetTop) {
                this.initialChartsLoadedIds.push('Shipping_Per_City');
                this.setDateToFirstMonthDay('SHIPPING_PER_CITY');
                this.getShippingPerCity();
                this.getShippingPerVillages();
            }
        }

        // Handle Best Drivers Chart
        if (this.isShowDriversEvaluation &&
            this.initialChartsLoadedIds.indexOf('Best_Drivers') === -1) {

            const deliveredRatioChart = document.getElementById('Best_Drivers');

            if (deliveredRatioChart && event.target.offsetHeight + event.target.scrollTop > deliveredRatioChart.offsetTop) {
                this.initialChartsLoadedIds.push('Best_Drivers');
                this.setDateToFirstMonthDay('DRIVERS_EVALUATION');
                this.getListOfBestDrivers();
            }
        }
    }

    attachChartObserver(id) {
        this.addRadiusForChartBars();
        this.observer.observe(document.getElementById(id), {
            childList: true,
            subtree: true
        });
    }

    addRadiusForChartBars() {
        document.querySelectorAll('rect[fill="#2ed47a"], rect[fill="#F7685B"], rect[fill="#f7685b"], rect[fill="#fca429"], rect[fill="#FCA429"]').forEach((item) => {
            item.setAttribute('rx', '10px');
        });
    }

    private transformDate(date, type) {
        let FormattedDate;
        switch (type) {
            case 'FULL':
            case 'WEEK':
                FormattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
                break;
            case 'MONTH':
                FormattedDate = this.datePipe.transform(date, 'yyyy-MM');
                break;
            case 'YEAR':
                FormattedDate = this.datePipe.transform(date, 'yyyy');
                break;
        }
        return FormattedDate;
    }

    private setDashboardSettingsFlags(data) {
        this.isShowDeliveredToAllPackagesRatio = data.isShowDeliveredToAllPackagesRatio;
        this.isShowDriversEvaluation = data.isShowDriversEvaluation;
        this.isShowIncome = data.isShowIncome;
        this.isShowShippingPerCity = data.isShowShippingPerCity;
        if (this.isShowIncome) {
            this.setDateToFirstMonthDay('INCOME');
            this.getIncome();
            this.getListOfBestCustomers();
        }
    }

    public createQuery(from = '') {
        let result = '';

        switch (from) {
            case 'INCOME' :
                result += '?type=' + this.getIncomePeriodFilter;
                result += '&startDate=' + this.transformDate(this.getIncomeDateFilter, 'FULL');
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO' :
                result += '?type=' + this.deliveredToAllPackagesRatioPeriodFilter;
                result += '&startDate=' + this.transformDate(this.deliveredToAllPackageRatioDateFilter, 'FULL');
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO_PERCENTAGE' :
                result += '?type=' + 'YEARLY';
                result += '&startDate=' + this.transformDate(this.deliveredToAllPackageRatioDateFilter, 'FULL');
                break;
            case 'SHIPPING_PER_CITY':
            case 'SHIPPING_PER_VILLAGE':
                result += '?type=' + this.shippingPerCityPeriodFilter;
                result += '&startDate=' + this.transformDate(this.shippingPerCityDateFilter, 'FULL');
                break;
            case 'DRIVERS_EVALUATION':
                result += '?type=' + this.driversEvaluationPeriodFilter;
                result += '&startDate=' + this.transformDate(this.listOfBestDriverDateFilter, 'FULL');
                result += '&driverType=TYPICAL';
                break;
            default:
                break;
        }
        result += '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        return result;
    }

    public getIncome() {
        if (!this.isShowIncome) {
            return;
        }
        this.isLoadingGetIncome = true;
        const customerIncome: any [] = [];
        const urlQuery = this.createQuery('INCOME');
        const body = {};
        if (this.selectedCustomers.length) {
            body['customersId'] = this.selectedCustomers.map(customer => customer.id);
        }
        this.translateService.get('DASHBOARD.INCOME').subscribe(
            (getIncomeLabel) => {
                this.getIncomeChartColumns = ['Year', getIncomeLabel];
            }
        );
        this.dashboardService.getCustomerIncome(urlQuery, body).subscribe(
            (response: any) => {
                if (response.length) {
                    response.forEach(
                        (store) => {
                            let label = '';
                            switch (this.getIncomePeriodFilter) {
                                case 'YEARLY':
                                    label = this.months[store.index - 1];
                                    break;
                                case 'MONTHLY':
                                    label = store.index;
                                    break;
                                case 'WEEKLY':
                                    label = store.lable;
                                    break;
                            }
                            const dataElement = [label, store ? store.cost : 0];
                            customerIncome.push(dataElement);
                        }
                    );
                } else {
                    const dataElement = ['', 0];
                    customerIncome.push(dataElement);
                }
                this.getIncomeChartData = customerIncome;
                this.isLoadingGetIncome = false;
            }, error => {
                console.error(error);
                this.isLoadingGetIncome = false;
            }
        );
    }

    public onSelectYear(dateEl = null) {
        const firstDayOfYear = '-01-01';


        switch (this.currentPicker) {
            case 'INCOME' :
                this.getIncomeDateFilter = new Date(dateEl.currentYear + firstDayOfYear);
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO' :
                this.deliveredToAllPackageRatioDateFilter = new Date(dateEl.currentYear + firstDayOfYear);
                break;
            case 'SHIPPING_PER_CITY' :
                this.shippingPerCityDateFilter = new Date(dateEl.currentYear + firstDayOfYear);
                break;
            case 'DRIVERS_EVALUATION' :
                this.listOfBestDriverDateFilter = new Date(dateEl.currentYear + firstDayOfYear);
                break;
        }

        if (this.getIncomePopOver && this.getIncomePopOver.isOpen()) {
            this.getIncomePopOver.close();
        }
        if (this.deliveredToAllPackagesDatePopOver && this.deliveredToAllPackagesDatePopOver.isOpen()) {
            this.deliveredToAllPackagesDatePopOver.close();
        }
        if (this.shippingPerCityPopOver && this.shippingPerCityPopOver.isOpen()) {
            this.shippingPerCityPopOver.close();
        }
        if (this.driversEvaluation && this.driversEvaluation.isOpen()) {
            this.driversEvaluation.close();
        }

        this.onSelectDateFilter();
    }

    public onSelectMonth(isMonth: boolean) {
        switch (this.currentPicker) {
            case 'INCOME' :
                this.getIncomeDateFilter = isMonth ? this.monthDateFilter : this.weekDateFilter;
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO' :
                this.deliveredToAllPackageRatioDateFilter = isMonth ? this.monthDateFilter : this.weekDateFilter;
                break;
            case 'SHIPPING_PER_CITY' :
                this.shippingPerCityDateFilter = isMonth ? this.monthDateFilter : this.weekDateFilter;
                break;
            case 'DRIVERS_EVALUATION' :
                this.listOfBestDriverDateFilter = isMonth ? this.monthDateFilter : this.weekDateFilter;
                break;
        }

        this.onSelectDateFilter();
    }

    private initForm() {
        const date = new Date();
        const date2 = new Date();
        this.fromDate = new Date(date.setDate(date.getDate() - 1));
        this.toDate = new Date(date2.setDate(date2.getDate()));
        this.filterForm = this.formsBuilder.group({
            searchText: [''],
            fromDate: [this.fromDate],
            toDate: [this.toDate]
        });
    }

    public getDeliveredPercentageToAddedPkgs() {
        if (!this.isShowDeliveredToAllPackagesRatio) {
            return;
        }

        const urlQuery = this.createQuery('DELIVERED_TO_ALL_PACKAGES_RATIO_PERCENTAGE');
        this.deliveredPercentageToAddedPkgs = [];
        this.dashboardService.getDeliveredToAllPackagesChartData(urlQuery, {}).subscribe(
            (response: any) => {
                response.forEach(
                    (item) => {
                        this.deliveredPercentageToAddedPkgs.push({
                            monthName: this.months[item.index - 1],
                            percentage: item.countDeliveredPackages === 0
                            || item.countAllPackages === 0 ? 0 :
                                Number(((item.countDeliveredPackages / item.countAllPackages) * 100).toFixed(2))
                        });
                    }
                );
            }, error => {
                console.error(error);
            }
        );
    }

    public onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.filterForm.controls.fromDate.setValue(this.fromDate);
        this.filterForm.controls.toDate.setValue(this.toDate);
        this.filterForm.controls.fromDate.updateValueAndValidity();
        this.filterForm.controls.toDate.updateValueAndValidity();
        this.excludeDatesFilter = false;
        this.getCards();
    }

    public getShippingPerVillages() {
        if (!this.isShowShippingPerCity) {
            return;
        }

        this.isLoadingShippingPerCity = true;
        // this.isLoadingShippingVillage = true;
        const urlQuery = this.createQuery('SHIPPING_PER_VILLAGE');
        this.dashboardService.getShippingPerVillages(urlQuery).subscribe(
            (response: { index: number, cost: number, lable: string } []) => {
                this.shippingPerVillages = response;
                this.isLoadingShippingPerCity = false;
            }, error => {
                console.error(error);
                this.isLoadingShippingPerCity = false;
            }
        );
    }

    private getProfitPercentageRate(listOfProfits: any) {
        let totalProfitPercentageRate = 0;
        for (let i = 0; i < listOfProfits.length; i++) {
            totalProfitPercentageRate += listOfProfits[i].cost;
        }
        return totalProfitPercentageRate > 0 ? totalProfitPercentageRate / listOfProfits.length : 0;
    }

    public getListOfBestCustomers() {
        if (!this.isShowIncome) {
            return;
        }

        this.isLoadingGetIncome = true;
        const urlQuery = this.createQuery('INCOME');
        this.dashboardService.getListOfBestCustomers(urlQuery).subscribe(
            (response: { cost: number, lable: string } []) => {
                this.listOfBestCustomers = response;
                this.highestProfitRate = response[0] ? this.formatter.format(response[0].cost) : '0';
                this.profitPercentageRate = this.formatter.format(this.getProfitPercentageRate(response));
                this.isLoadingGetIncome = false;
            }, error => {
                console.error(error);
                this.isLoadingGetIncome = false;
            }
        );
    }

    public getListOfBestDrivers() {
        if (!this.isShowDriversEvaluation) {
            return;
        }

        this.isLoadingListOfBestDrivers = true;
        const body = {};
        if (this.selectedDriversMultiSelect.length) {
            body['driverId'] = this.selectedDriversMultiSelect.map(driver => driver.id);
        }
        const bestOfDriversList: any[] = [];
        this.listOfBestDrivers = [];
        const urlQuery = this.createQuery('DRIVERS_EVALUATION');
        this.translateService.get(
            [
                'DASHBOARD.CHART_DESCRIPTION_LABELS.RETURNED',
                'DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED',
                'DASHBOARD.CHART_DESCRIPTION_LABELS.PICKED_PACKAGES'
            ]
        )
            .subscribe(
                (data) => {
                    this.driversEvaluationColumn = [
                        'Year',
                        data['DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED'],
                        data['DASHBOARD.CHART_DESCRIPTION_LABELS.PICKED_PACKAGES'],
                        data['DASHBOARD.CHART_DESCRIPTION_LABELS.RETURNED']
                    ];
                }
            );
        this.dashboardService.getListOfBestDrivers(urlQuery, body).subscribe(
            (response: any []) => {
                if (response.length) {
                    response.forEach(
                        (driver) => {
                            this.listOfBestDrivers.push({lable: driver.lable});
                            const dataElement = [
                                driver.lable, driver.countDeliveredPackage, driver.countPickedUpPackage, driver.countReturnedPackage
                            ];
                            bestOfDriversList.push(dataElement);
                        }
                    );
                } else {
                    const dataElement = ['', 0, 0, 0];
                    bestOfDriversList.push(dataElement);
                }
                this.driversEvaluationChartData = bestOfDriversList;
                this.isLoadingListOfBestDrivers = false;
            }, error => {
                this.isLoadingListOfBestDrivers = false;
                console.error(error);
            }
        );
    }

    private firebaseSubscribe() {
        this.getToken();
        // this.app.messaging().onTokenRefresh(() => {
        //     this.getToken();
        // });
    }

    public getCompanyBillingNotification() {
        this.companyService.getCompanyBillingNotification().subscribe(
            (response: { isNotify: boolean, isBlock: boolean, notificationMessage: string }) => {
                this.isNotify = response.isNotify;
                this.isBlock = response.isBlock;
                this.notificationMessage = response.notificationMessage;
            }, error => {
                console.error(error);
            }
        );
    }

    private getToken() {
        this.app.messaging().getToken().then((refreshedToken) => {
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            // send token to server
            this.messagingService.subscribeToFireBase(refreshedToken, this.userService.userInfo.id,
                this.authenticationService.getDeviceUUID()).subscribe(() => {
            });
        }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
        });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyDriversListener();
    }

    private initDates() {
        this.toDate = new Date();
        this.fromDate = new Date(this.toDate.getTime() - (60 * 60 * 24 * 1000));
    }

    private initMap() {
        const initalMapLocation = this.userService.getInitialMapView();
        const map = (this.translateService.currentLang === 'en') ? 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.map = new L.map('map', {
            fullscreenControl: true
        }).setView([initalMapLocation.lat, initalMapLocation.lng], initalMapLocation.zoom);
        L.tileLayer(map, {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        // Drivers info only relevant for admin and dispatcher
        this.initDriversListeners();
    }

    private initDriversListener() {
        this.drivers =  [];
        this.fDrivers = [];
        let query = '?driverType=TYPICAL';
        if (this.selectedDriversStatus !== 'ALL') {
            query += `&is-online=${this.selectedDriversStatus === 'ONLINE'}`;
        }
        this.isGettingDriversList =  true;

        this.driverService.getDriversLocations(query).subscribe((data: any) => {
            data.locations.forEach((driver) => {
                    const name = driver.driverName;
                    this.drivers.push({
                        label: name,
                        value: driver.driverId
                    });
                    this.fDrivers.push(driver);
            });
           if (this.selectedDriversStatus === 'ALL') {
               this.offlineDrivers = data.offlineDriversNo;
               this.onlineDrivers = data.onlineDriversNo;
           }
            this.driversChange(this.fDrivers);
           this.isGettingDriversList = false;
        }, error => {
            console.error(error);
            this.isGettingDriversList = false;
        });
        // this.app.messaging().onMessage((payload) => {
        //     const locationData = JSON.parse(payload.data.locationData);
        //     // this.userService.u
        //     if (this.fDrivers) {
        //         // remove old entry
        //         if (locationData.companyId && this.authenticationService.companyId &&
        //             this.authenticationService.companyId === locationData.companyId) {
        //             let driverName = '';
        //             this.fDrivers = this.fDrivers.filter((driver) => {
        //                 if (driver.driverId === locationData.driverId) {
        //                     driverName = driver.driverName;
        //                     return false;
        //                 } else {
        //                     return true;
        //                 }
        //             });
        //             locationData['driverName'] = driverName;
        //             this.fDrivers.push(locationData);
        //             this.driversChange(this.fDrivers);
        //         }
        //     }
        // });
    }

    private initDriversListenerHubManager() {
        this.driverService.getDriversLocations().subscribe((data: any) => {
            data.locations.forEach((driver) => {
                    const name = driver.driverName;
                    this.drivers.push({
                        label: name,
                        value: driver.driverId
                    });
                    this.fDrivers.push(driver);
            });
            this.driversChange(this.fDrivers);
        });
        // this.app.messaging().onMessage((payload) => {
        //     const locationData = JSON.parse(payload.data.locationData);
        //     // this.userService.u
        //     if (this.fDrivers) {
        //         // remove old entry
        //         if (locationData.companyId && this.authenticationService.companyId &&
        //             this.authenticationService.companyId === locationData.companyId && locationData.driverId in this.hubDrivers) {
        //             let driverName = '';
        //             this.fDrivers = this.fDrivers.filter((driver) => {
        //                 if (driver.driverId === locationData.driverId) {
        //                     driverName = driver.driverName;
        //                     return false;
        //                 } else {
        //                     return true;
        //                 }
        //             });
        //             locationData['driverName'] = driverName;
        //             this.fDrivers.push(locationData);
        //             this.driversChange(this.fDrivers);
        //         }
        //     }
        // });
    }

    private destroyDriversListener() {
        if (this.database) {
            this.database.goOffline();
        }
    }

    private removeMapMarkers() {
        this.mapMarkers.forEach(
            (mapMarker) => {
                this.map.removeLayer(mapMarker);
            }
        );
    }

    private driversChange(driversData: any) {

        this.removeMapMarkers();
        const carOffIcon = L.icon({
            iconUrl: '../../assets/icons/car_map_off.svg',

            iconSize:     [40, 60], // size of the icon
            // shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [22, 25], // point of the icon which will correspond to marker's location
            // shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -20] // point from which the popup should open relative to the iconAnchor
        });
        const carOnIcon = L.icon({
            iconUrl: '../../assets/icons/car_map_on.svg',

            iconSize:     [40, 60], // size of the icon
            // shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [22, 25], // point of the icon which will correspond to marker's location
            // shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -20] // point from which the popup should open relative to the iconAnchor
        });
        Object.keys(driversData).forEach(
            (key) => {
                if (this.mapDriverId && this.mapDriverId === driversData[key].id) {
                    const driverData = driversData[key];
                    let marker;
                    marker = L.marker([driverData.latitude, driverData.longitude],
                        {icon: driverData.isDriverOnline ? carOnIcon : carOffIcon}).addTo(this.map).bindPopup(driverData.driverName + '');
                    this.mapMarkers.push(marker);
                    return;
                } else {
                    const driverData = driversData[key];
                    let marker;
                    marker = L.marker([driverData.latitude, driverData.longitude],
                        {icon: driverData.isDriverOnline ? carOnIcon : carOffIcon}).addTo(this.map).bindPopup(driverData.driverName + '');
                    this.mapMarkers.push(marker);
                }
            }
        );
    }

    private initStats() {
        setTimeout(() => {
            this.initMap();
        }, 1500);
    }

    public navigateTo(navigateTo, queryParams = {}) {
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }

    public getCards() {

        this.topStatsCards = [];
        const params = {
            fromDate: this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'),
            toDate: this.datePipe.transform(this.toDate, 'yyyy-MM-dd'),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        this.dashboardService.getCards(params).subscribe((res: any) => {
                Object.keys(res).forEach(
                    (key) => {
                        this.topStatsCards[key] = {
                            status: key,
                            count: res[key],
                            isCod: false
                        };
                    }
                );
            }
            , error => {
                console.error(error);
            },
            () => {
            }
        );
    }

    public getChartWidth(elementId: string, isShow: boolean) {
        if (isShow) {
            const chartDiv = document.getElementById(elementId).getBoundingClientRect();
            return chartDiv.width;
        }

    }

    getChartHeight() {
        if (this.applicationStateService.getIsMobileResolution()) {
            return 350;
        }
        return 540;
    }

    public getGetIncomeChartHeight(elementId: string, isShow: boolean) {
        if (isShow) {
            const chartDiv = document.getElementById(elementId).getBoundingClientRect();
            if (this.applicationStateService.getIsMobileResolution()) {
                return (chartDiv.height) - 160;
            }
            return (chartDiv.height) - 122;
        }
    }

    public onDriverChangeed() {
        Object.keys(this.fDrivers).forEach(
            (key) => {
                if (this.fDrivers[key].driverId === this.selectedDriver) {
                    const markerBounds = L.latLngBounds([L.latLng(this.fDrivers[key].latitude, this.fDrivers[key].longitude)]);
                    this.map.fitBounds(markerBounds);
                }
            });

    }

    public onSearch() {
        this.getCards();
    }

    public openCardsDetails(card) {
        this.router.navigate([this.authenticationService.companyName + ROUTE_SHARED.HOME_SHIPMENT],
            {
                queryParams: {
                    status: card,
                    fromDate: this.transformDate(this.fromDate, 'FULL'),
                    toDate: this.transformDate(this.toDate, 'FULL')
                }
            });
        return;
    }

    public customize(type) {
        if (type === 'INCOME' && this.isShowIncome) {
            this.getIncome();
            this.getListOfBestCustomers();
        }

        this.dashboardService.modifyDashboardSettings(type).subscribe(
            (response: any) => {
                this.translateService.get(
                    'UPDATED'
                ).subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                    }
                );
            }, error => {
                console.error(error);
            }
        );
    }

    setCustomersList(list) {
        this.customersList = list.map(user => {
            return {
                label: user.firstName,
                value: user.id,
            };
        });
    }

    public setDateToFirstYearDay(type) {
        switch (type) {
            case 'INCOME':
                this.getIncomeDateFilter = new Date(new Date(this.getIncomeDateFilter).getFullYear() + '-01-01');
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO':
                this.deliveredToAllPackageRatioDateFilter = new Date(new Date(this.deliveredToAllPackageRatioDateFilter).getFullYear() + '-01-01');
                break;
            case 'SHIPPING_PER_CITY':
                this.shippingPerCityDateFilter = new Date(new Date(this.shippingPerCityDateFilter).getFullYear() + '-01-01');
                break;
            case 'DRIVERS_EVALUATION':
                this.listOfBestDriverDateFilter = new Date(new Date(this.listOfBestDriverDateFilter).getFullYear() + '-01-01');
                break;
        }
    }

    public setDateToFirstMonthDay(type) {
        const date = new Date();
        switch (type) {
            case 'INCOME':
                this.getIncomeDateFilter = new Date(date.getFullYear(), date.getMonth());
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO':
                this.deliveredToAllPackageRatioDateFilter = new Date(date.getFullYear(), date.getMonth());
                break;
            case 'SHIPPING_PER_CITY':
                this.shippingPerCityDateFilter = new Date(date.getFullYear(), date.getMonth());
                break;
            case 'DRIVERS_EVALUATION':
                this.listOfBestDriverDateFilter = new Date(date.getFullYear(), date.getMonth());
                break;
        }
    }

    public setDateToWeeklyDay(type) {
        switch (type) {
            case 'INCOME':
                this.getIncomeDateFilter = new Date(this.getDateBeforeNumberOfDays(7));
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO':
                this.deliveredToAllPackageRatioDateFilter = new Date(this.getDateBeforeNumberOfDays(7));
                break;
            case 'SHIPPING_PER_CITY':
                this.shippingPerCityDateFilter = new Date(this.getDateBeforeNumberOfDays(7));
                break;
            case 'DRIVERS_EVALUATION':
                this.listOfBestDriverDateFilter = new Date(this.getDateBeforeNumberOfDays(7));
                break;
        }
    }

    public setPeriodFilter(filter, type) {
        if (filter === 'YEARLY') {
            this.setDateToFirstYearDay(type);
        } else if (filter === 'MONTHLY') {
            this.setDateToFirstMonthDay(type);
        } else if (filter === 'WEEKLY') {
            this.setDateToWeeklyDay(type);
        }

        switch (type) {
            case 'INCOME':
                this.getIncomePeriodFilter = filter;
                this.getIncome();
                this.getListOfBestCustomers();
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO':
                this.deliveredToAllPackagesRatioPeriodFilter = filter;
                this.getDeliveredToAllPackagesChartData();
                // this.getDeliveredPercentageToAddedPkgs();
                break;
            case 'SHIPPING_PER_CITY':
                this.shippingPerCityPeriodFilter = filter;
                this.getShippingPerCity();
                this.getShippingPerVillages();
                break;
            case 'DRIVERS_EVALUATION':
                this.driversEvaluationPeriodFilter = filter;
                this.getListOfBestDrivers();
                break;
        }
    }

    public getDeliveredToAllPackagesChartData() {
        if (!this.isShowDeliveredToAllPackagesRatio) {
            return;
        }
        this.isLoadingDeliveredToAddedPackages = true;
        const body = {};
        if (this.selectedDeliveredToAddedCustomers.length) {
            body['customersId'] = this.selectedDeliveredToAddedCustomers.map(customer => customer.id);
        }
        const deliveredToAllPackagesRatio: any[] = [];
        this.translateService.get(
            ['DASHBOARD.CHART_DESCRIPTION_LABELS.ADDED_PACKAGES', 'DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED']
        ).subscribe(
            (data) => {
                this.deliveredToAllPackageColumns = [
                    'Year',
                    data['DASHBOARD.CHART_DESCRIPTION_LABELS.ADDED_PACKAGES'],
                    data['DASHBOARD.CHART_DESCRIPTION_LABELS.DELIVERED']
                ];
            }
        );
        const urlQuery = this.createQuery('DELIVERED_TO_ALL_PACKAGES_RATIO');
        this.dashboardService.getDeliveredToAllPackagesChartData(urlQuery, body).subscribe(
            (response: any) => {
                if (response.length) {
                    response.forEach(
                        (item) => {
                            let label = '';
                            switch (this.deliveredToAllPackagesRatioPeriodFilter) {
                                case 'YEARLY' :
                                    label = this.months[item.index - 1];
                                    break;
                                case 'MONTHLY' :
                                    label = item.index;
                                    break;
                                case 'WEEKLY' :
                                    label = item.lable;
                                    break;
                            }
                            const dataElement = [label, item.countAllPackages, item.countDeliveredPackages];
                            deliveredToAllPackagesRatio.push(dataElement);
                        }
                    );
                } else {
                    const dataElement = ['', 0, 0];
                    deliveredToAllPackagesRatio.push(dataElement);
                }
                this.deliveredToAllPackageRatioChartData = deliveredToAllPackagesRatio;
                this.isLoadingDeliveredToAddedPackages = false;
            }, error => {
                console.error(error);
                this.isLoadingDeliveredToAddedPackages = false;
            }
        );
    }

    public getShippingPerCity() {
        if (!this.isShowShippingPerCity) {
            return;
        }
        this.isLoadingShippingPerCity = true;
        const body = {};
        if (this.selectedCities.length) {
            body['cityId'] = this.selectedCities.map(city => city.id);
        }
        const shippingPerCity: any[] = [];
        const urlQuery = this.createQuery('SHIPPING_PER_CITY');
        this.translateService.get('GENERAL.CITY').subscribe(
            (shippingPerCityLabel) => {
                this.shippingPerCityColumns = ['Year', shippingPerCityLabel];
            }
        );
        this.dashboardService.getShippingPerCity(urlQuery, body).subscribe(
            (response: any) => {
                if (response.length) {
                    response.forEach(
                        (item) => {
                            const dataElement = [item.lable, item.cost];
                            shippingPerCity.push(dataElement);
                        }
                    );
                } else {
                    const dataElement = ['', 0];
                    shippingPerCity.push(dataElement);
                }
                this.shippingPerCityChartData = shippingPerCity;
                this.isLoadingShippingPerCity = false;
            }, error => {
                console.error(error);
                this.isLoadingShippingPerCity = false;
            }
        );
    }

    public onSelectDateFilter() {
        switch (this.currentPicker) {
            case 'INCOME' :
                this.getIncome();
                this.getListOfBestCustomers();
                break;
            case 'DELIVERED_TO_ALL_PACKAGES_RATIO' :
                this.getDeliveredToAllPackagesChartData();
                this.getDeliveredPercentageToAddedPkgs();
                break;
            case 'SHIPPING_PER_CITY' :
                this.getShippingPerCity();
                this.getShippingPerVillages();
                break;
            case 'DRIVERS_EVALUATION' :
                this.getListOfBestDrivers();
                break;
        }
        if (this.getIncomePopOver && this.getIncomePopOver.isOpen()) {
            this.getIncomePopOver.close();
        }
        if (this.deliveredToAllPackagesDatePopOver && this.deliveredToAllPackagesDatePopOver.isOpen()) {
            this.deliveredToAllPackagesDatePopOver.close();
        }
        if (this.shippingPerCityPopOver && this.shippingPerCityPopOver.isOpen()) {
            this.shippingPerCityPopOver.close();
        }
        if (this.driversEvaluation && this.driversEvaluation.isOpen()) {
            this.driversEvaluation.close();
        }
    }

    public setCurrentPicker(currentPicker) {
        this.currentPicker = currentPicker;
    }

    public syncCurrentPickerWithNgModel(type, value) {
        switch (type) {
            case 'YEAR':
                this.yearDateFilter = value;
                break;
            case 'MONTH':
                this.monthDateFilter = value;
                break;
            case 'WEEK':
                this.weekDateFilter = value;
                break;
        }
    }

    public getDateBeforeNumberOfDays(numberOfDays) {
        return new Date().setDate(new Date().getDate() - numberOfDays);
    }

    public filterCustomers(customersEl) {
        this.getCustomers(customersEl.filter.text);
    }

    public filterDrivers(customersEl) {
        this.getDriversList(customersEl.filter.text);
    }

    public filterCities(customersEl) {
        this.getCities(customersEl.filter.text);
    }

    public onClickCustomersList() {
        this.getCustomers();
    }

    public onClickDriversMultiSelect() {
        this.getDriversList();
    }

    public onSelectGetIncomeCustomer(selectAll = false) {
        if (selectAll) {
            setTimeout(() => {
                this.getIncome();
            }, 1000);
        }
        this.getIncome();
    }

    public onSelectDeliveredToAddedCustomer(selectAll = false) {
        if (selectAll) {
            setTimeout(() => {
                this.getDeliveredToAllPackagesChartData();
            }, 1000);
        }
        this.getDeliveredToAllPackagesChartData();
    }

    public onClickCitiesMultiSelect() {
        this.getCities();
    }

    public onSelectDrivers(selectAll = false) {
        if (selectAll) {
            setTimeout(() => {
                this.getListOfBestDrivers();
            }, 1000);
        } else {
            this.getListOfBestDrivers();
        }
    }

    public onSelectCities(selectAll = false) {
        if (selectAll) {
            setTimeout(() => {
                this.getShippingPerCity();
            }, 1000);
        } else {
            this.getShippingPerCity();
        }
    }

    public getCustomers(search?: string) {
        // this.spinnerState = SpinnerState.LOADING;
        const query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        this.usersService.getCustomersDropDown(query).subscribe(
            (response: any) => {
                const newList = response.map(customer => {
                    return this.getCustomerObject(customer);
                });
                this.customersList = this.selectedCustomers.concat(newList.filter((item) => this.selectedCustomers.map(a => a.id).indexOf(item.id) < 0));
            }, (err) => {
                console.error(err);
            }
        );

    }

    public getCustomerObject(user) {
        return {
            name: user.firstName + (user.middleName ? (' ' + user.middleName + ' ') : ' ') + user.lastName +
                (user.businessName ? (' (' + user.businessName + ')') : ''),
            id: user.id,
        };
    }

    public getDriverObject(driver) {
        return {
            name: driver.firstName + ' ' + driver.lastName,
            id: driver.id,
        };
    }

    public getCityObject(city) {
        const name = this.translateService.currentLang === 'en' ? city.name : city.arabicName;
        return {
            name: name,
            id: city.id,
        };
    }

    public getDriversList(search?: string) {

        let query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        query += '&driverType=TYPICAL';
        this.driverService.getDrivers(query).subscribe(
            (response: any) => {
                const newList = response.map(driver => {
                    return this.getDriverObject(driver);
                });
                this.driversMultiSelectList = this.selectedDriversMultiSelect.concat(newList.filter((item) => this.selectedDriversMultiSelect.map(a => a.id).indexOf(item.id) < 0));
            }, (err) => {
                console.error(err);
            }
        );
    }

    public getCities(search?: string) {

        const query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        this.zonesService.getCitiesList(query).subscribe(
            (response: any) => {
                const newList = response.data.map(city => {
                    return this.getCityObject(city);
                });
                this.citiesList = this.selectedCities.concat(newList.filter((item) => this.selectedCities.map(a => a.id).indexOf(item.id) < 0));
            }, (err) => {
                console.error(err);
            }
        );
    }

    initDriversListeners() {
        if (['DISPATCHER', 'ADMIN', 'SUPER_ADMIN', 'OPERATION_MANAGER', 'ACCOUNTING_MANAGER', 'CUSTOMER_CARE', 'HUB_MANAGER', 'MULTIPLE_HUBS_MANAGER', 'HUB_ADMIN', 'HUB_OPERATION_MANAGER'].indexOf(this.permissions) !== -1) {
            this.initDriversListener();
        }
    }

    resetDriversFilter() {
        this.mapDriverId = null;
        this.initDriversListeners();
    }

    public onMapDriverSelected() {

        Object.keys(this.fDrivers).forEach(
            (key) => {
                if (this.fDrivers[key].driverId === this.mapDriverId) {
                    this.driversChange([this.fDrivers[key]]);
                    const markerBounds = L.latLngBounds([L.latLng(this.fDrivers[key].latitude, this.fDrivers[key].longitude)]);
                    this.map.fitBounds(markerBounds);
                }
            });

    }
    public openExcel(type) {
        this.translateService.get(['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'],
                accept: () => {
                    if (!this.isShowDeliveredToAllPackagesRatio) {
                        return;
                    }
                    this.isLoadingDeliveredToAddedPackages = true;
                    const body = {};
                    if (this.selectedDeliveredToAddedCustomers.length) {
                        body['customersId'] = this.selectedDeliveredToAddedCustomers.map(customer => customer.id);
                    }
                    if (this.selectedDriversMultiSelect.length) {
                        body['driverId'] = this.selectedDriversMultiSelect.map(driver => driver.id);
                    }
                    const urlQuery = this.createQuery(type);
                    let apiUrl;
                    switch (type) {
                        case 'INCOME' :
                            apiUrl = this.dashboardService.exportToExcelIncomeAverage(urlQuery, body);
                            break;
                        case 'DELIVERED_TO_ALL_PACKAGES_RATIO' :
                            apiUrl = this.dashboardService.exportToExcelDeliveredToAllPackagesRatio(urlQuery, body);
                            break;
                        case 'SHIPPING_PER_CITY':
                            apiUrl = this.dashboardService.exportToExcelShippingPerCity(urlQuery, body);
                            break;
                        case 'DRIVERS_EVALUATION':
                            apiUrl = this.dashboardService.exportToExcelDriversEvaluation(urlQuery, body);
                            break;
                    }
                    apiUrl.subscribe((response: any) => {
                        window.open(response.url, '_blank');
                        this.isLoadingDeliveredToAddedPackages = false;
                    });
                },
                reject: () => {
                    this.isLoadingDeliveredToAddedPackages = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    clearAllUpdatesNotification() {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.MARK_ALL_AS_SEEN_CONFIRMATION'),
            accept: () => {
                this.markAllAsSeenRequest = true;
                this.systemUpdatesService.markAllAsSeen().subscribe(res => {
                    this.userService.initSystemUpdatesBadge();
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }
}
