import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TRIPS_BETWEEN_CITIES_CONSTANTS} from './constants';

@Injectable({ providedIn: 'root' })
export class TripsBetweenCitiesService {

    constructor(
        private http: HttpClient
    ) {
    }

    addTrip(body) {
        return this.http.post(TRIPS_BETWEEN_CITIES_CONSTANTS.ADD_SUB_TRIP + '?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, body);
    }
    editTrip(subTripId, body) {
        return this.http.put(TRIPS_BETWEEN_CITIES_CONSTANTS.UPDATE_SUB_TRIP.replace('{tripId}', subTripId), body);
    }
    getTrips(params) {
       return this.http.get(TRIPS_BETWEEN_CITIES_CONSTANTS.MAIN_TRIPS, {params});
    }

    deleteMainTrip(tripId) {
        return this.http.delete(TRIPS_BETWEEN_CITIES_CONSTANTS.DELETE_MAIN_TRIP.replace('{tripId}', tripId));
    }

    deleteSubTrip(Id) {
        return this.http.delete(TRIPS_BETWEEN_CITIES_CONSTANTS.DELETE_SUB_TRIP.replace('{tripId}', Id));

    }


    addMainTrip(body) {
        return this.http.post(TRIPS_BETWEEN_CITIES_CONSTANTS.ADD_MAIN_TRIP, body);
    }
    updateMainTrip(tripId, body) {
        return this.http.put(TRIPS_BETWEEN_CITIES_CONSTANTS.UPDATE_MAIN_TRIP.replace('{tripId}', tripId), body);
    }
}
