import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InboxComponent} from './inbox.component';
import { EmailComponent } from './email/email.component';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { EmailReplyComponent } from './email-reply/email-reply.component';
import { InboxDropdownComponent } from './modals/inbox-dropdown/inbox-dropdown.component';
import {InboxService} from './services/inbox.service';
import {SharedModule} from '../shared/modules/shared.module';

import { SendMessageComponent } from './modals/send-message/send-message.component';
import { FormsModule } from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {AccordionModule} from 'primeng/accordion';
import {EllipsisModule} from 'ngx-ellipsis';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [
        InboxComponent,
        EmailComponent,
        EmailDetailsComponent,
        EmailReplyComponent,
        InboxDropdownComponent,
        SendMessageComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        
        FormsModule,
        ToastModule,
        AccordionModule,
        EllipsisModule,
        TooltipModule
    ],
    exports: [
        InboxComponent
    ]
})
export class InboxModule {
}
