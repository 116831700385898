import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {FADE_ANIMATION} from '../../shared/animations/fade-animation';
import {HubsService} from '../../shared/services/hubs.service';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {Paginator} from 'primeng/paginator';
import {HubModel} from '../../shared/models/hub.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewHubComponent} from '../new-hub/new-hub.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ManageShelfComponent} from '../manage-shelf/manage-shelf.component';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {SharedService} from '../../shared/services/shared-service';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ManageWarehouseComponent} from '../../fulfilment/warehouses/manage-warehouse/manage-warehouse.component';
import {FulfilmentService} from '../../fulfilment/services/fulfilment.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {HubTitlePipe} from '../../shared/pipes/hub-title-pipe';

@Component({
    selector: 'app-manage-branches-component',
    templateUrl: 'branches-management.component.html',
    styleUrls: ['branches-management.component.scss'],
    animations: [FADE_ANIMATION]
})
export class BranchesManagementComponent implements OnInit {
    public hubs: HubModel[] = [];
    @ViewChild('paginator', {static: false}) paginator: Paginator;

    public currentPage = 0;
    public totalRecords = 0;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;
    public selectedHubs = {};
    public permissions = '';
    public viewMode = 'CARDS_MODE';
    public currentLang;
    public triggeredHub: HubModel;
    public isAllSelected = false;
    public printLoader = false;
    public isLoading = false;
    public fetchedHubs: HubModel[] = [];
    public pageSize = this.viewMode === 'TABLE_MODE' ? 20 : 16;
    public isShowHubDetail = false;
    public searchContent = '';
    public debounceUpdateCost;
    public isWarehouse = false;

    public isSearchContent = false;

    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    archivedBranches = false;
    companyHadStationsInsteadOfBranches = [256, 22]; //SAR for Production and mohanad for testing
    public companyId: number;

    constructor(
        private hubsService: HubsService,
        private modalService: NgbModal,
        private messageService: MessageService,
        public translateService: TranslateService,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private rolesService: RolesService,
        private sharedService: SharedService,
        private hubTitlePipe: HubTitlePipe,
        private router: Router,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private fulfilmentService: FulfilmentService
    ) {
    }

    ngOnInit() {
        this.companyId = this.authenticationService.companyId;
        if (this.route.snapshot.data['isWarehouse']) {
            this.isWarehouse = this.route.snapshot.data['isWarehouse'];
        }
        this.initUserPermissions();
        this.currentLang = this.translateService.currentLang;
        this.loadLazyHubs();
    }

    public onSetShowMode(viewMode) {
        this.viewMode = viewMode;
        this.pageSize = viewMode === 'TABLE_MODE' ? 20 : 16;
        this.hubs = [];
        this.currentPage = 1;
        if (this.isWarehouse) {
            this.fetchWarehouses();
        } else {
            this.fetchHubs();
        }
    }

    public getAddress(address) {
        return address.village + ' - ' + address.city + ' - ' + address.region;
    }

    public copyMessage($event, list?, hubNumber?) {
        this.sharedService.copyMessage($event, null, hubNumber, 'تم نسخ باركود الفرع بنجاح');
    }

    public setTriggeredHub(hub: HubModel) {
        this.triggeredHub = hub;
    }

    private createQuery() {
        let query = '?pageSize=' + this.pageSize + '&page=' + (this.currentPage);
        if (this.searchContent) {
            query += '&search=' + this.searchContent;
        }
        if (this.archivedBranches && !this.isWarehouse) {
            query += '&is-deleted=true';
        }
        return query;
    }

    public onSearch() {
        if (!this.debounceUpdateCost) {
            this.debounceUpdateCost = _.debounce(() => {
                this.currentPage = 0;
                this.hubs = [];
                this.loadLazyHubs();
            }, 1000);
        }
        this.debounceUpdateCost();
    }

    resetHubs() {
        this.hubs = [];
        this.currentPage = 0;
    }

    public onNewHub() {
        const modal = this.modalService.open(NewHubComponent, <any>{backdrop: 'static', windowClass: 'new-hub-container', size: 'md'});
        modal.componentInstance.isWarehouse = this.isWarehouse;
        modal.componentInstance.isStations = this.companyHadStationsInsteadOfBranches.indexOf(this.companyId) !== -1;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    const message = this.translateService.instant(this.isWarehouse ? 'ALERTS.WAREHOUSE_ADDED_SUCCESSFULLY' : 'ALERTS.HUB_ADDED_SUCCESSFULLY');
                    this.messageService.add({severity: 'success', detail: message});
                    this.resetHubs();
                    this.loadLazyHubs();
                    // this.paginator.changePage(0);
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public removeHub(hub: HubModel) {
        let message = '';
        let successMessage = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.REMOVE_IT', 'GENERAL.YES', 'GENERAL.NO', 'ALERTS.HUB_DELETED_SUCCESSFULLY']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.REMOVE_IT'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                    successMessage = this.hubTitlePipe.transform(data['ALERTS.HUB_DELETED_SUCCESSFULLY']);
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.hubsService.removeHub(hub.id).subscribe(
                    (response: any) => {
                        this.messageService.add({severity: 'success', detail: successMessage});
                        this.resetHubs();
                        this.loadLazyHubs();
                        // this.hubs.splice(this.hubs.indexOf(hub), 1);
                    }, (err) => {
                        console.error(err);
                    }
                );
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    public modifyHub(hub: HubModel) {
        const modal = this.modalService.open(NewHubComponent, <any>{backdrop: 'static', windowClass: 'new-hub-container', size: 'md'});
        modal.componentInstance.hub = hub;
        modal.componentInstance.isWarehouse = this.isWarehouse;
        modal.componentInstance.isStations = this.companyHadStationsInsteadOfBranches.indexOf(this.companyId) !== -1;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    const message = this.translateService.instant(this.isWarehouse ? 'ALERTS.WAREHOUSE_EDITED_SUCCESSFULLY' : 'ALERTS.HUB_EDITED_SUCCESSFULLY');
                    this.messageService.add({severity: 'success', detail: message});
                }
            }, err => {
                console.error(err);
            }).catch(
            (err) => {
                console.error(err);
            }
        );
    }

    public manageSelf($event: { hub: HubModel }) {
        const modal = this.modalService.open(ManageShelfComponent, <any>{
            backdrop: 'static',
            windowClass: 'flex-modal new-shelf-container',
            size: 'md'
        });
        modal.componentInstance.hub = $event.hub;
    }

    public onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }

        this.hubs.forEach((pkg: any) => {
            pkg.isSelected = this.isAllSelected;
        });
    }

    public getSelectedHubs() {
        const selectedHubs = [];
        if (!this.hubs) {
            return [];
        }

        this.hubs.forEach(
            (hub: HubModel) => {
                if (hub.isSelected) {
                    selectedHubs.push(hub);
                }
            }
        );
        return selectedHubs;
    }

    public print() {
        this.printLoader = true;
        const selectedHubs = this.getSelectedHubs().map(hub => hub.id);
        this.hubsService.printHubs(selectedHubs, this.archivedBranches).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.printLoader = false;
        }, err => {
            console.error(err);
            this.printLoader = false;
        });
    }

    private fetchHubs() {
        const query = this.createQuery();
        this.fetchedHubs = [];
        if (this.hubs.length === this.totalRecords && this.currentPage > 1) {
            return;
        }
        this.isLoading = true;
        this.spinnerState = SpinnerState.LOADING;
        this.hubsService.getHubs(query).subscribe(
            (response: any) => {
                this.fetchedHubs = response.hubs;
                this.hubs = [...this.hubs, ...this.fetchedHubs];
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
            }
        );
    }

    private fetchWarehouses() {
        const query = this.createQuery();
        this.fetchedHubs = [];
        if (this.hubs.length === this.totalRecords && this.currentPage > 1) {
            return;
        }
        this.isLoading = true;
        this.spinnerState = SpinnerState.LOADING;
        this.fulfilmentService.getWarehouses(query).subscribe(
            (response: any) => {
                this.fetchedHubs = response.hubs;
                this.hubs = [...this.hubs, ...this.fetchedHubs];
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
            }
        );
    }

    public loadLazyHubs() {
        if (!this.isLoading) {
            this.currentPage++;
            if (this.isWarehouse) {
                this.fetchWarehouses();
            } else {
                this.fetchHubs();
            }
        }
    }

    @HostListener('scroll', ['$event'])
    onScrollTable(event: any) {
        // event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5) {
            this.loadLazyHubs();
        }
    }

    public showHubDetails(hub) {
        this.triggeredHub = hub;
        this.isShowHubDetail = true;
    }

    public onHideHubDetails(event) {
        this.isShowHubDetail = event;
    }

    public manageWarehouse(event) {
        const modal = this.modalService.open(ManageWarehouseComponent, {
            backdrop: 'static',
            windowClass: 'manage-warehouse',
            size: 'lg'
        });
        modal.componentInstance.selectedWarehouse = event.hub;
        if (event.type !== 'AREAS' && event.type !== 'ROWS') {
            modal.componentInstance.customColumns = ['CHECKBOX', 'LOCATION_BARCODE', 'LOCATION_LABEL', 'BIN_BARCODE', 'LOCATION_TYPE'];
        }
        // modal.componentInstance.warehouses = this.hubs;
        modal.componentInstance.type = event.type;
    }
    public toggleIsSearchContent(value) {
        this.isSearchContent = value;
    }

    initUserPermissions() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions(this.isWarehouse ? 'MANAGE_WAREHOUSES' : 'MANAGE_BRANCHES', role);
    }

    getTabName() {
        if (this.companyHadStationsInsteadOfBranches.indexOf(this.companyId) === -1) {
            return this.archivedBranches ? 'BRANCHES_MANAGEMENT.ARCHIVED_BRANCHES' : 'BRANCHES_MANAGEMENT.BRANCHES';
        } else {
            return this.archivedBranches ? 'STATIONS_MANAGEMENT.ARCHIVED_BRANCHES' : 'STATIONS_MANAGEMENT.BRANCHES';
        }
    }

    onChangeTab(archived) {
        this.archivedBranches = archived;
        this.currentPage = 0;
        this.hubs = [];
        this.loadLazyHubs();
    }
    public onRetrieveHub($event: any) {
        this.retrieveHub($event.hub, $event.complete);
    }

    public retrieveHub(hub: HubModel, complete?: any) {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.RETRIEVE_HUB'),
            accept: () => {
                this.hubsService.retrieve(hub).subscribe(
                    (response: any) => {
                        this.onChangeTab(true);
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.HUB_RETRIEVED_SUCCESSFULLY')});
                    }, (error) => {
                        const errorMessage = error.error ? error.error.error : 'Something went wrong';
                        console.error(error);
                        if (complete) {
                            complete();
                        }
                    }, () => {
                        if (complete) {
                            complete();
                        }
                    }
                );
            },
            reject: () => {
                complete();
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });

    }

}
