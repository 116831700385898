import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ParcelService} from '../../../services/parcel.service';

@Component({
    selector: 'app-new-parcel',
    templateUrl: './new-parcel.component.html',
    styleUrls: ['./new-parcel.component.scss']
})
export class NewParcelComponent implements OnInit {
    public isEdit = false;
    public userRolesOptions: { label: string, value: any }[];
    public hubsOptions: { label: string, value: any }[];
    public containersOptions: { label: string, value: any }[];
    public activeDrivers = false;
    public isLoading = false;
    public isSubmitting = false;
    public disableForm;
    public form: FormGroup;
    public parcel: any = {};

    constructor(private formsBuilder: FormBuilder,
                private activeModal: NgbActiveModal,
                private parcelService: ParcelService) {

    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.form = this.formsBuilder.group({
            type: ['', Validators.required],
            arabicType: ['', Validators.required],
            length: ['', Validators.required],
            width: ['', Validators.required],
            weight: ['', Validators.required],
            height: ['', Validators.required]
        });
        const {type, arabicType, length, width, weight, height} = this.parcel;
        if (this.parcel) {
            this.form.patchValue({type, arabicType, length, width, weight, height});
        }
    }

    onAddParcel() {
        let subscription;
        if (this.parcel.id) {
            subscription = this.parcelService.editParcel(this.form.value, this.parcel.id);
        } else {
            subscription = this.parcelService.addParcel(this.form.value);
        }
        subscription.subscribe( (response) => {
            this.activeModal.close({isSuccess: true});
        }, (error) => {
            this.activeModal.close({isSuccess: false, error: error.error});
        });
    }

    public exitModal() {
        this.activeModal.close();
    }
}
