import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    HIDE_LOGESTECHS_LOGO_FOR = [256, 263];
    ADD_CUSTOMIZED_BACKGROUND = [256];
    companyId;
    hideFooterDetails = false;
    public selectedLanguage;
    public isSignUp = false;
    companyLogo: any;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
      this.checkIfLogedIn();
      this.companyId = this.authenticationService.companyId;
      this.companyLogo = this.authenticationService.getCompanyLogo();
      this.hideFooterDetails = this.HIDE_LOGESTECHS_LOGO_FOR.indexOf(this.companyId) > -1;
      this.selectedLanguage = this.translateService.currentLang;
      const urlParts = this.router.url.split('/');
      this.isSignUp = urlParts[2] && urlParts[2] === 'signup' || urlParts[3] && urlParts[3] === 'signup';
  }

    /**
     * if there is a user, go to home page.
     */

    public signUp() {
        const modal = this.modalService.open(SignUpComponent, {
            backdrop: 'static',
            windowClass: 'flex-modal',
            size: 'lg'
        });
        modal.componentInstance.isModal = true;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                let message = '';
                this.translateService.get(
                    ['ALERTS.COMPANY_ADDED_SUCCESSFULLY']
                ).subscribe(
                    (data) => {
                        message = data['ALERTS.COMPANY_ADDED_SUCCESSFULLY'];
                    }
                );
                this.messageService.add({severity: 'success', detail: message});
            }
        ).catch(
            (error) => {
            }
        );
    }
    public onLangChange() {
        this.translateService.resetLang(this.selectedLanguage);
        this.translateService.use(this.selectedLanguage);
    }

    public redirectTo(siteName) {
        switch (siteName) {
            case 'LINKED_IN':
                window.open('https://www.linkedin.com/company/logestechs/', '_blank');
                break;

            case 'INSTAGRAM':
                window.open('https://www.instagram.com/logestechs_/', '_blank');
                break;

            case 'FACEBOOK':
                window.open('https://www.facebook.com/LogesTechs', '_blank');
                break;
        }

    }
    private checkIfLogedIn() {
        const isActive = this.authenticationService.isUserActive();
        if (isActive) {
            const companyName = this.authenticationService.companyName || this.authenticationService.getCompanyName();
            if (this.route.snapshot.data['isSuperAdmin']) {
                this.router.navigate(['super-admin']);
            } else {
                this.router.navigate([`${companyName}home`]);
            }
        }
    }
}
