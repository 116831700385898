import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {setBarcodeReader} from '../../shared/services/helpers';
import {UserService} from '../../shared/services/user.service';
import {UsersService} from '../../users/services/users.service';
import {BulkUpdateComponent} from '../bulk-update/bulk-update.component';
import {playError} from '../../shared/behavior/sounds';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-sort-packages-table',
    templateUrl: './sort-packages-table.component.html',
    styleUrls: ['./sort-packages-table.component.scss']
})
export class SortPackagesTableComponent implements OnInit, OnDestroy {

    public isSorting = false;
    public isLoading = false;
    private customerID = 0;
    public sortedPackages = [];
    public isReading = false;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;

    constructor(private route: ActivatedRoute,
                public router: Router,
                private modalService: NgbModal,
                private messageService: MessageService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private shipmentsService: ShipmentsService,
                private authService: AuthenticationService,
                private userService: UserService,
                private usersService: UsersService) {

    }

    ngOnInit() {
        this.route.params.subscribe(
            (params: Params) => {
                this.customerID = params.id;
            }
        );
        this.isReading = true;
        setBarcodeReader((barcode) => {
            if (this.isReading) {
                this.barcodeReader(barcode);
            }
        });
    }

    private barcodeReader(barcode) {
        if (!this.isSorting) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.getPackageByBarcode(barcode, '', this.customerID).subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.sortPackage(shipment);
                        this.isLoading = false;
                    }
                );
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    public startSorting() {
        this.isSorting = true;
    }

    public removePackage(shipment) {
        for (const oldShipment in this.sortedPackages) {
            if (this.sortedPackages[oldShipment].barcode === shipment.barcode) {
                this.sortedPackages.splice(parseInt(oldShipment, 0), 1);
            }
        }
    }

    public sortPackage(shipment) {
        for (const oldShipment in this.sortedPackages) {
            if (this.sortedPackages[oldShipment].barcode === shipment.barcode) {
                return;
            }
        }
        this.sortedPackages.unshift(shipment);
    }

    public enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.customerID = this.customerID;
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.sortPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public resetSorting() {
        this.translateService.get(['ACCOUNTING.RESET_SORTING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.RESET_SORTING_CONFIRMATION'],
                accept: () => {
                    this.isSorting = false;
                    this.sortedPackages = [];
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public finishSorting() {
        this.translateService.get(['ACCOUNTING.FINISH_SORTING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'])
            .subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.FINISH_SORTING_CONFIRMATION'],
                    accept: () => {
                        this.sendSortedPackages();
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO']
                });
            });
    }

    public sendSortedPackages() {
        this.isLoading = true;
        const sortedPackages = this.sortedPackages.map(shipment => shipment.barcode);
        this.shipmentsService.sortPackages(sortedPackages, this.customerID).subscribe(() => {
            this.translateService.get(
                'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.confirmationService.confirm({
                            message: data,
                            rejectVisible: false,
                            acceptLabel: 'OK',
                            accept: () => {
                                this.router.navigate([this.authService.companyName + 'home/sort-cod']);
                            }
                        });
                    }
                );
        }, () => {
            this.isLoading = false;

        });
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    public bulkSort() {
        const modal = this.modalService.open(BulkUpdateComponent, {backdrop: 'static', windowClass: 'create-new-container', size: 'lg'});
        modal.componentInstance.isChangeStatus = false;
        modal.componentInstance.isReceive = false;
        modal.componentInstance.customerId = this.customerID;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                let successMessage = '';
                let errorMessage = '';
                this.translateService.get(
                    ['ALERTS.BULK_SORT_SUCCESSFUL', 'ALERTS.BULK_SORT_FAILED']
                ).subscribe(
                    (data) => {
                        successMessage = data['ALERTS.BULK_SORT_SUCCESSFUL'];
                        errorMessage = data['ALERTS.BULK_SORT_FAILED'];
                    }
                );
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: successMessage});
                } else {
                    this.messageService.add({severity: 'error', detail: errorMessage});
                }
            }
        ).catch(
            (error) => {
            }
        );

    }

    ngOnDestroy(): void {
        this.isReading = false;
    }

}
