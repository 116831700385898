import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SpinnerState} from '../../../../shared/behavior/spinner-state.enum';
import {PackageModel} from '../../../../shared/models/package.model';
import {DriverCheckInModel} from '../../../../shared/models/driver-check-in.model';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {ShipmentsService} from '../../../../shipment/services/shipments.service';
import {UserService} from '../../../../shared/services/user.service';
import {RolesService} from '../../../../shared/services/roles/roles.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UserModel} from '../../../../shared/models/user.model';
import {SubscriptionTrackerComponent} from '../../../../shared/behavior/subscription-tracker.component';
import {FulfilmentService} from '../../../../fulfilment/services/fulfilment.service';
import {WalletService} from '../../../services/wallet.service';

@Component({
  selector: 'app-wallet-transactions',
  templateUrl: './wallet-transactions.component.html',
  styleUrls: ['./wallet-transactions.component.scss']
})
export class WalletTransactionsComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() display = false;
  @Input() wallet: any = {};
  selectedLanguage = '';
  public spinnerStates = SpinnerState;
  public spinnerState = SpinnerState.LOADING;
  public currentLang = '';
  public permissions = '';
  public packagesHistory = [];
  public package: PackageModel;
  public pkgStatus = '';
  public detailsDivHeight: number;
  public printLoader = false;
  public currency = '';
  public displayAddPackage = false;
  public selectedPackageBarcode;
  public isLcl = false;
  public isDistributor = false;
  public isTrucking = false;
  public companyId;

  @Input() packageBarcode;
  @Input() packageId: number;
  @Input() isShow: boolean;

  @Output() onCloseTracker: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('packageInfo', {static: false}) packageInfo: any;
  @ViewChild('mainInfo', {static: false}) mainInfo: any;
  @ViewChild('changePackageStatusPopOver', {static: false}) changePackageStatusPopOver: NgbPopover;

  public packageTracker: any;


  SpinnerState = SpinnerState;
  digitFormat = '1.0-2';

  constructor(
      private route: ActivatedRoute,
      private auth: AuthenticationService,
      private translateService: TranslateService,
      private shipmentsService: ShipmentsService,
      private modalService: NgbModal,
      private userService: UserService,
      private rolesService: RolesService,
      private messageService: MessageService,
      private walletService: WalletService,
  ) {
    super();
  }

  ngOnInit() {
    this.userService.getUserInfo(true).then(
        (userInfo: UserModel) => {
          this.companyId = userInfo.companyId;
          this.currency = this.userService.getCurrency();
        }
    );
    this.currentLang = this.translateService.currentLang;
    const sub = this.translateService.onLangChange.subscribe(() => {
      this.currentLang = this.translateService.currentLang;
    });
    this.addSubscription(sub);

    this.getPackagesHistory();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
        (changes['packageBarcode'] && changes['packageBarcode'].previousValue !== changes['packageBarcode'].currentValue) ||
        (changes['isShow'] && changes['isShow'].currentValue === true)
    ) {
      this.getPackagesHistory();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onClose();
  }

  public onClose() {
    this.packageTracker = null;
    this.onCloseTracker.emit(false);
  }
  getPackagesHistory() {
    if (!this.packageBarcode && !this.packageId) {
      return;
    }
    let getPackageHistory;

    getPackageHistory = this.walletService.getTransactionHistory(this.wallet.id);


    this.spinnerState = SpinnerState.LOADING;
    getPackageHistory.subscribe((response: any) => {
      this.packagesHistory = response.data;
      this.package = response.pkg;
      this.pkgStatus = response.massPackageStatus;
      this.spinnerState = SpinnerState.LOADED;
    }, () => {
      this.spinnerState = SpinnerState.FAILED;
    });
  }

  public copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.translateService.get('ALERTS.COPIED').subscribe(
        (response) => {
          this.messageService.add({severity: 'success', detail: response});
        }
    );
  }

  public getLocalTime (dateTime) {
    if (dateTime) {
      return new Date(dateTime).toLocaleTimeString();
    }
    return;
  }


  public onHideAddPackage (event) {
    this.displayAddPackage = event;
    this.selectedPackageBarcode = null;
  }



  public getStatusColor(type = '') {
    return this.shipmentsService.getShipmentStatusColor(type.toUpperCase());
  }

}
