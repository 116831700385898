import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {COMPANIES_CONSTANTS} from '../companies.constants';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {SHIPMENT_CONSTANTS} from '../../shipment/services/constants';

@Injectable({providedIn: 'root'})
export class CompaniesService {

    constructor(private httpClient: HttpClient,
                private authenticationService: AuthenticationService) {
    }
    public getCompanyBillings(query) {
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANY_BILLING + query);
    }
    public getNotificationHistory(query) {
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANIES_NOTIFICATION_HISTORY + query);
    }

    public getCompanisBillingPayments(query) {
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANY_BILLING_PAYMENTS + query);
    }
    public addCompanyBillingCost(companyId, billingCost: number) {
        const body = {
            billingCost: Number(billingCost)
        };
        return this.httpClient.put(COMPANIES_CONSTANTS.ADD_COMPANY_BILLING_COST.replace('{companyId}', companyId), body);
    }
    public setAccountablePackages(id, val) {
        const body = {
            packagesNumber: Number(val)
        };
        return this.httpClient.put(COMPANIES_CONSTANTS.SET_ACCOUNTABLE_PACKAGES.replace('{companyBillingId}', id), body);
    }
    public addCompanyBillingPayment(companyPaymentId, paidAmount: number) {
        const body = {
             paidCost: Number(paidAmount),
         };
        return this.httpClient.put(COMPANIES_CONSTANTS.ADD_COMPANY_BILLING_PAYMENT.replace('{companyPaymentId}', companyPaymentId), body);
    }
    public startCompanyBilling(startNotifcationDate, daysNumber) {
        const body = {
            startNotificationsDate: startNotifcationDate,
            daysNumber: daysNumber
        };
        return this.httpClient.post(COMPANIES_CONSTANTS.START_COMPANY_BILLING, body);
    }
    public addBillingPaymentForSpecificCompany(companyId , paymentDate, paymentAmount) {
        const body = {
            companyId: companyId,
            date: paymentDate,
            billingCost: paymentAmount,
            companyName: 'Tariq'
        };
        return this.httpClient.post(COMPANIES_CONSTANTS.ADD_BILLING_PAYMENT_FOR_SPECIFIC_COMPANY, body);
    }
    public updateNotificationSetting(id , startNotificationDate , daysNumber) {
        const body = {
            startNotificationDate: startNotificationDate,
            daysNumber: daysNumber
        };
        return this.httpClient.put(COMPANIES_CONSTANTS.UPDATE_NOTIFICATION_SETTINGS.replace('{notificationSettingId}', id), body);
    }
    public notifyCompany(companyId, isNotify) {
        const body = {
            isNotify: isNotify
        };
        return this.httpClient.put(COMPANIES_CONSTANTS.SET_COMPANY_IS_NOTIFY.replace('{companyId}', companyId), body);
    }
    public modifyBilling(companyId, val, status) {
        const reqParam = '?action=' + status + '&value=' + val;
        return this.httpClient.put(COMPANIES_CONSTANTS.SET_COMPANY_IS_BILLING.replace('{companyId}', companyId) + reqParam, {});
    }
    public setCompanyTempDisableDate(tempDisableDate, companyId) {
        const body = {
            tempDisableDate: tempDisableDate
        };
        return this.httpClient.put(COMPANIES_CONSTANTS.SET_COMPANY_BILLING_TEMP_DISABLE_DATE.replace('{companyId}', companyId), body);
    }
    public addCompanyPayment(companyId, paidAmount: number, paymentDate ) {
        const body = {
            paidCost: paidAmount,
            paymentDate: paymentDate,
            companyId: companyId
        };
        return this.httpClient.post(COMPANIES_CONSTANTS.ADD_COMPANY_PAYMENT, body);
    }
    public deleteCompanyPayment(paymentId) {
        return this.httpClient.delete(COMPANIES_CONSTANTS.DELETE_COMPANY_PAYMENT.replace('{paymentId}', paymentId));
    }

    public resetIsPaidCompany(companyBillingId) {
        return this.httpClient.put(COMPANIES_CONSTANTS.RESET_COMPANY_IS_PAID.replace('{companyBillingId}', companyBillingId), {});
    }
    public getCompanyBillingNotification() {
        const query = '?timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANY_BILLING_NOTIFICATION + query);
    }
    public getCompanies(params) {
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANIES, {params});
    }

    public exportCompanies(params) {
        return this.httpClient.get(COMPANIES_CONSTANTS.EXPORT_COMPANIES, {params});
    }

    public getCompany() {
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANY);
    }
    public getCompaniesDropdown(query = '') {
        return this.httpClient.get(COMPANIES_CONSTANTS.GET_COMPANIES_DROPDOWN + query);
    }

    public submitCompany(body, queryParamy = null) {
        return this.httpClient.post(COMPANIES_CONSTANTS.SUBMIT_COMPANIES, body, {params: queryParamy});
    }

    public signUpCompany(body, queryParamy = null) {
        return this.httpClient.post(COMPANIES_CONSTANTS.SIGN_UP_COMPANY, body, {params: queryParamy});
    }

    public submitCompanyConfiguration(body) {
        return this.httpClient.put(COMPANIES_CONSTANTS.SUBMIT_COMPANY_CONFIGURATION, body);
    }

    public uploadLogo(formData) {
        return this.httpClient.post(COMPANIES_CONSTANTS.UPLOAD_lOGO, formData, {});
    }

    public editCompany(companyId, company) {
        const isSuperAdmin = this.authenticationService.getIsSuperAdmin();
        if (isSuperAdmin) {
            return this.httpClient.put(COMPANIES_CONSTANTS.UPDATE_COMPANY.replace('{companyId}', companyId), company);
        }
        return this.httpClient.put(COMPANIES_CONSTANTS.ADMIN_UPDATE_COMPANY, company);
    }

    public deleteCompany(companyId) {
        return this.httpClient.delete(COMPANIES_CONSTANTS.DELETE_COMPANY.replace('{companyId}', companyId));
    }

    public changeCompanyStatus(companyId, status) {
        return this.httpClient.put(COMPANIES_CONSTANTS.CHANGE_STATUS.replace('{companyId}', companyId) + '?new-status=' + status,
            {});
    }
    public changeCompanyDriverSms(status, val) {
        let url = COMPANIES_CONSTANTS.MODIFY_SMS_SETTING;
        url += '?event=' + status;
        url += '&value=' + val;
        return this.httpClient.post(url, {});
    }
    public billingPdf(billingId) {
        return this.httpClient.get(COMPANIES_CONSTANTS.BILLING_PDF_REPORT.replace('{billingId}', billingId));
    }
    public companyPaymentExcel(query) {
        return this.httpClient.get(COMPANIES_CONSTANTS.COMPANY_PAYMENT_EXCEL + query);
    }

    public ExportReturnedBeforeArrivalExcel(query) {
        return this.httpClient.get(COMPANIES_CONSTANTS.EXPORT_RETURNED_BEFORE_ARRIVAL + query);
    }
}
