import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FADE_ANIMATION} from '../../../shared/animations/fade-animation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../../users/services/users.service';
import {DriversService} from '../../../shared/services/drivers.service';
import * as _ from 'lodash';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user.service';
import {ExportComponent} from '../../../shipment/export/export.component';
import {DASHBOARD_CONSTANT, DashboardService} from '../../services/dashboard.service';


@Component({
    selector: 'app-drivers-list-component',
    templateUrl: 'drivers-list.component.html',
    styleUrls: ['drivers-list.component.scss'],
    animations: [FADE_ANIMATION]
})
export  class DriversListComponent implements OnInit{
    selectedDriver: string;
    drivers: any[] = [];
    public isLoading = false;
    public isLoadingDriversList = false;
    public totalRecords;
    public i = 0;
    public printPackagesLoaders: any[] = [];
    public driversListWithPackages: {id: number, name: string, countOfCarriedPackages: number, deliveryRate: number}[] = [];
    public AllDriversList: {id: number, name: string, countOfCarriedPackages: number, deliveryRate: number}[] = [];
    @Output() onSearch: EventEmitter<{}> = new EventEmitter<{}>();
    public driverListPageIndex = 1;
    private PAGE_SIZE = 10;
    private forceGetDrivers;
    public debounceUpdateCost;
    public selectedAttachmentsFilter = 'WITH_PACKAGES';
    currentLang = 'en';

    constructor(private modalService: NgbModal,
                private usersService: UsersService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private translate: TranslateService,
                private driverService: DriversService,
                private dashboardService: DashboardService) {
        this.onSearchDriversList = _.debounce(this.onSearchDriversList, 1000);
    }
    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        if (['CLERK', 'HUB_CLERK', 'CUSTOMER_ACCOUNT_MANAGER', 'ACCOUNTANT', 'TICKETING_USER', 'TICKETING_SYSTEM_ADMIN'].indexOf(this.userService.userInfo.role) === -1) {
            this.getDrivers();
        }
    }

    public printDriverPackages(driverId) {
        // app permission => this.permissions.includes('{PRINT_DRIVER_PACKAGES') && !this.partnerId
        if (this.isLoading) {
            return;
        }
        this.confirmationService.confirm({
            message: this.translate.instant('ALERTS.CONFIRM_PRINT_DRIVER_PKGS'),
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
            accept: () => {
                this.isLoading = true;
                this.printPackagesLoaders[driverId] = true;
                this.usersService.downloadDriverPackagesPdf(driverId).subscribe((res: any) => {
                        window.open(res.url, '_blank');
                        this.isLoading = false;
                        this.printPackagesLoaders[driverId] = false;
                    },
                    error => {
                        console.error(error);
                        this.isLoading = false;
                    });
            }
        });
    }

    public onSearchDrivers(searchValue) {
        this.onSearch.emit({search: searchValue});
    }

    public onSearchDriversList(searchValue) {
        this.forceGetDrivers = true;
        this.driverListPageIndex = 0;
        this.lazyLoadDrivers(searchValue);
    }
    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.lazyLoadDrivers();
        }
    }

    public lazyLoadDrivers(search?: string) {
        this.driverListPageIndex++;
        if (this.selectedAttachmentsFilter === 'WITHOUT_PACKAGES') {
            this.getAllDrivers(search);
        } else if (this.selectedAttachmentsFilter === 'WITH_PACKAGES') {
            this.getDrivers(search);
        }
    }

    getDrivers(search?: string, thirdParty = false) {
        if (this.driversListWithPackages.length) {
            if (this.driversListWithPackages.length === this.totalRecords && !this.forceGetDrivers) {
                return;
            }
        }
        this.isLoadingDriversList = true;
        let query = '?pageSize=' + this.PAGE_SIZE + '&page=' + this.driverListPageIndex;
        if (search) {
            query += '&search=' + search;
        }
        query += '&driverType=TYPICAL';

        this.driverService.getDriversWithPackagesCount(query).subscribe(
            (response: any) => {
                const fetchedDriverList = response.data.map(driver => {
                    const name = driver.firstName + ' ' + driver.lastName;
                    return {id: driver.id, name: name, countOfCarriedPackages: driver.packagesCount, deliveryRate: driver.deliveryRate};
                });
                this.driversListWithPackages = (this.driverListPageIndex > 1) ? [...this.driversListWithPackages, ...fetchedDriverList] : fetchedDriverList;
                this.totalRecords = response.totalRecordsNo;
                this.forceGetDrivers = false;
                this.isLoadingDriversList = false;
            }, error => {
                console.error(error);
                this.forceGetDrivers = false;
                this.isLoadingDriversList = false;
            });
    }

    getAllDrivers(search?: string, thirdParty = false) {
        if (this.AllDriversList.length) {
            if (this.AllDriversList.length === this.totalRecords && !this.forceGetDrivers) {
                return;
            }
        }
        this.isLoadingDriversList = true;
        let query = '?pageSize=' + this.PAGE_SIZE + '&page=' + this.driverListPageIndex;
        if (search) {
            query += '&search=' + search;
        }
        query += '&driverType=TYPICAL';

        this.driverService.getDriversWithoutPackagesCount(query).subscribe(
            (response: any) => {
                const fetchedDriverList = response.data.map(driver => {
                    return {id: driver.id, name: driver.name};
                });
                this.AllDriversList = (this.driverListPageIndex > 1) ? [...this.AllDriversList, ...fetchedDriverList] : fetchedDriverList;
                this.totalRecords = response.totalRecordsNo;
                this.forceGetDrivers = false;
                this.isLoadingDriversList = false;
            }, error => {
                console.error(error);
                this.forceGetDrivers = false;
                this.isLoadingDriversList = false;
            });
    }

    driversType(type) {
        this.selectedAttachmentsFilter = type;
        if (type === 'WITHOUT_PACKAGES') {
            this.driversListWithPackages = [];
            this.getAllDrivers();
        } else if (type === 'WITH_PACKAGES') {
            this.AllDriversList = [];
            this.getDrivers();
        }
    }

    public PrintPdfExcel(driverId) {
        const params = {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone};
        const modal = this.modalService.open(ExportComponent, <any>{size: 'lg', windowClass: 'bulk-update-popover'});
        modal.componentInstance.showPDF = true;
        modal.result.then((data: { type: string }) => {
            let url;
                if (data.type === 'PDF') {
                    url = DASHBOARD_CONSTANT.API.EXPORT_PDF_DRIVERS.replace('{driverId}', driverId);
                } else if (data.type === 'EXCEL') {
                    url = DASHBOARD_CONSTANT.API.EXPORT_EXCEL_DRIVERS.replace('{driverId}', driverId);
                }
                this.isLoading = true;
                this.dashboardService.exportDrivers(url, params).subscribe((res: any) => {
                        window.open(res.url, '_blank');
                        data.type = '';
                    },
                    error => {
                        console.error(error);
                        this.isLoading = false;
                    });
    });
    }
}
