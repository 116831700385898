<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="cod-mismatch-view table-container d-flex flex-column h-100 w-100" [ngClass]="TYPE">
    <div *ngIf="hasFilters" class="d-flex flex-row filters-container">
        <div *ngIf="tableName" class="flex-1">
            {{tableName | translate | packageTitle }}
        </div>
        <ng-container *ngTemplateOutlet="filter; context: {$implicit: hubId}"></ng-container>
        <div *ngIf="statusFilter && selectedShipmentStatus==='RETURNED_BY_RECIPIENT' && TYPE !== 'driver-attempts-report'">
            <p-checkbox label="{{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER' | translate}}" binary="true"
                        [(ngModel)]="isDeliveredToSender" (onChange)="onStatusChanged()"></p-checkbox>
        </div>
        <div *ngIf="statusFilter && selectedShipmentStatus==='RETURNED_BY_RECIPIENT' && TYPE !== 'driver-attempts-report'">
            <p-checkbox label="{{'MANAGE_SHIPMENTS.COMPLETED_PACKAGES' | translate | packageTitle}}" binary="true"
                        [(ngModel)]="isCompleted" (onChange)="onStatusChanged()"></p-checkbox>
        </div>
        <app-drop-down *ngIf="statusFilter"
                [(ngModel)]="selectedShipmentStatus"
                [options]="shipmentStatusList"
                [showClear]="false"
                [showLabel]="false"
                (whenChange)="onStatusChanged()">
        </app-drop-down>
        <app-users-list-dropdown-component *ngIf="filterHubs"
                                           [isHub]="true"
                                           [user] = "hubId === '' || hubId === null || hubId === undefined? undefined: hubId"
                                           (userChanged)="onHubSelected($event)"></app-users-list-dropdown-component>
        <app-multi-select *ngIf="filterDrivers" #customersEl
                          placeholder="ADMINISTRATION.DRIVER_LIST_DROPDOWN_PLACEHOLDER"
                          [maxSelectedLabels]="1"
                          [options]="drivers"
                          [showLabel]="false"
                          [(ngModel)]="selectedDriver"
                          (whenClose)="getDrivers($event)"
                          (whenSelect)="onSelectGetIncomeCustomer()"
                          (whenSelectAll)="onSelectGetIncomeCustomer(true)"
                          (whenDeSelectAll)="onSelectGetIncomeCustomer()"
                          (whenDeSelect)="onSelectGetIncomeCustomer()"
                          (whenFilterChange)="getDrivers($event, customersEl)">
        </app-multi-select>
        <app-field-input *ngIf="filterBarcode"
                         [(ngModel)]="barcodeSearch"
                         class="width-170"
                         [showLabel]="false"
                         [showIcon]="true"
                         [icon]="'fas fa-barcode'"
                         (input)="debouncedSearch()"
                         placeholder="{{'SHIPMENT.SEARCH_BY_BARCODE' | translate}}..."></app-field-input>
        <app-users-list-dropdown-component *ngIf="filterCustomer" (userChanged)="onCustomerSelected($event)"></app-users-list-dropdown-component>
        <app-users-list-dropdown-component *ngIf="filterPartners" [isPartner]="true"
                                           [user] = "partnerId === '' || partnerId === null || partnerId === undefined? undefined: partnerId"
                                           (userChanged)="onPartnerSelected($event)"></app-users-list-dropdown-component>
        <app-drop-down *ngIf="delayedAndCreatedDate"
                      [(ngModel)]="selectedDateFilter"
                      [options]="dateFiltersList" filter="true"
                      [showClear]="false"
                      [showLabel]="false"
                      (whenChange)="initShipments()">
        </app-drop-down>
        <app-drop-down *ngIf="showDateTypeFilter"
                      [options]="dateTypeFilterList"
                      [(ngModel)]="selectedDateTypeFilter"
                      [showClear]="false"
                      [showLabel]="false"
                      (whenChange)="onSearch()"></app-drop-down>
        <app-calendar *ngIf="isIdleCustomerReport && filterDate" (whenClearClick)="onSearch()"
                      [label]="'ADMINISTRATION.IDLE_CUSTOMER_REPORT.SINCE'"
                      [(ngModel)]="fromDate"
                      [showIcon]="true"
                      [maxDate]="toDate"
                      (whenSelect)="onSearch()"
                      appendTo="body"
                      placeholder="{{'ADMINISTRATION.IDLE_CUSTOMER_REPORT.SINCE' | translate}}"
                      dateFormat="dd/mm/yy"></app-calendar>
        <app-date-range-picker *ngIf="!isIdleCustomerReport && filterDate"
                               (onSelect)="selectRangeDate($event)"></app-date-range-picker>
        <app-field-input  *ngIf="!isRemoveSearch"
                    class="width-170"
                    [showIcon]="true"
                    [icon]="'fas fa-search'"
                    [(ngModel)]="searchContent"
                    [showLabel]="false"
                    appInputDebounced
                    (inputDebounced)="debounceOnSearch()"
                    (input)="debounceOnSearch()"
                    placeholder="{{'GENERAL.SEARCH' | translate}}..."></app-field-input>
    </div>
    <div class="d-flex flex-row ui-panel-titlebar">
        <i *ngIf="TYPE === 'drivers_popup' || TYPE === 'hubs_popup' || TYPE === 'partners_popup' || TYPE === 'packages_by_destination_city' || TYPE === 'active-customer-popup'" class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
        <div class="flex-1">
            <span>{{reportTitle | translate | packageTitle | hubTitle}}</span>
        </div>
        <div *ngIf="reportTitle === 'ADMINISTRATION.ZONE_TO_PACKAGES_SUMMARY_REPORT_TITLE'" class="raw-data-container">
            <div class="field-checkbox d-flex align-items-center mt-1">
                <p-checkbox id='hubId' [(ngModel)]="inHub" [binary]="true" (onChange)="onToggleInHubSelection($event)"></p-checkbox>
                <label for="hubId" class="mb-0 pl-2 pr-3">{{'MANAGE_SHIPMENTS.IN_HUB_PACKAGES' | translate}}</label>
            </div>
        </div>

        <div *ngIf="showExportSwappedDelivered" pTooltip="{{'DRIVERS_REPORTS.EXPORT_SWAPPED_DELIVERED_PACKAGES' | translate}}">
            <button class="fas fa-file-excel icon fa-2x" style="color: white" (click)="exportSwappedOrDeliveredToExcel()"
                    [disabled]="!apiUrl || spinnerState === spinnerStates.LOADING"></button>
        </div>
        <div *ngIf="showExportSwappedDelivered" style="width: 15px"></div>
        <div *ngIf="rowDataUrl" style="width: 15px"></div>

        <div *ngIf="rowDataUrl" class="raw-data-container">
            <button class="fas fa-2x fa-table icon" style="color: white" (click)="isPostRowData? postRawData(): getRawData()"
                    [disabled]="!apiUrl || spinnerState === spinnerStates.LOADING"></button>
        </div>
        <div *ngIf="rowDataUrl" style="width: 15px"></div>
        <div *ngIf="isExportToExcel">
            <button class="fas fa-2x fa-table icon" style="color: white" (click)="exportToExcel()"
                    [disabled]="!apiUrl || spinnerState === spinnerStates.LOADING"></button>
        </div>
        <div *ngIf="isExportToExcel" style="width: 15px"></div>
        <div *ngIf="!isIdleCustomerReport && isPdfRemove">
            <button class="fas fa-2x fa-print icon" style="color: white" (click)="downloadPdf()"
                    [disabled]="!apiUrl || spinnerState === spinnerStates.LOADING"></button>
        </div>
        <div *ngIf="!isIdleCustomerReport" style="width:15px"></div>
        <div *ngIf="expandLink">
            <i class="fas fa-2x fa-angle-double-right icon-to-rotated cursor-pointer" [routerLink]="expandLink"
               [queryParams]="queryParams"></i>
        </div>
    </div>
    <div class="admin-report-table overflow-auto">
        <p-table #table dataKey="id" [value]="records" styleClass="admin-report"
                 [rowsPerPageOptions]="rowsPerPageOptions"
                 [resizableColumns]="true" [autoLayout]="true"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [columns]="columns"
                 [paginatorDropdownAppendTo]="'body'">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf="TYPE === 'drivers_Reports'" style="font-size: 13px;">
                    <th rowspan="2" style="text-align: center; width: 110px">{{columns[0].header | translate | packageTitle }}</th>
                    <th colspan="3" style="text-align: center">{{"DRIVERS_REPORTS.PICKUPS" | translate | packageTitle}}</th>
                    <th *ngIf="TYPE === 'drivers_Reports'" colspan="9" style="text-align: center">{{"DRIVERS_REPORTS.SHIPMENTS" | translate | packageTitle}}</th>
                    <th colspan="1000" style="text-align: center"></th>
                </tr>
                <tr *ngIf="TYPE === 'hubs_Reports'" style="font-size: 13px;">
                    <th rowspan="2" style="text-align: center">{{columns[0].header | translate | packageTitle }}</th>
                    <th colspan="1" style="text-align: center">{{"DRIVERS_REPORTS.PICKUPS" | translate | packageTitle}}</th>
                    <th colspan="7" style="text-align: center">{{"DRIVERS_REPORTS.SHIPMENTS" | translate | packageTitle}}</th>
                </tr>
                <tr *ngIf="TYPE === 'partners_Reports'" style="font-size: 13px;">
                    <th rowspan="2" style="text-align: center">{{columns[0].header | translate | packageTitle }}</th>
                    <th colspan="6" style="text-align: center">{{"DRIVERS_REPORTS.SHIPMENTS" | translate | packageTitle}}</th>
                    <th colspan="2" style="text-align: center"></th>
                </tr>
                <tr *ngIf="TYPE === 'activate-customers-report'">
                    <th rowspan="2" style="text-align: center; width: 110px">{{columns[0].header | translate | packageTitle }}</th>
                    <th colspan="3" style="text-align: center">{{"DRIVERS_REPORTS.PICKUPS" | translate | packageTitle}}</th>
                    <th *ngIf="TYPE === 'activate-customers-report'" colspan="10" style="text-align: center">{{"DRIVERS_REPORTS.SHIPMENTS" | translate | packageTitle}}</th>
                </tr>
                <tr style="font-size: 13px;">
                    <th *ngFor="let col of columns | slice: startFromIndex; let i = index" pResizableColumn pReorderableColumn>
                        <span *ngIf="col.type !== 'notesWithFilter'">{{col.header | translate | packageTitle }}</span>
                        <div *ngIf="col.type === 'notesWithFilter'" style="text-align: center;">
                            <p-checkbox binary="true"
                                        [(ngModel)]="isNotesAdded" (onChange)="onSearch()"></p-checkbox>
                            <span style="margin-right: 3px">{{'GENERAL.NOTES' | translate}}</span>
                        </div>
                    </th>
                    <th *ngIf="isNotDelivered" style="text-align: center;width: 60px;">{{"ACCOUNTING.DELIVER" | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-columns="columns">
                <tr     [ngClass]= "(isCursorPointer() ? 'cursor-pointer' : '')" style="font-size: 13px;">
                    <td *ngFor="let col of columns" (click)="onReportClick(row, col)" [ngClass]="{'cursor-pointer': (!!col.click)}">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchCase="'date'">
                                {{row[col.field] | date:'dd/MM/yyyy'}}  {{getLocalTime(row[col.field])}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'concat'">
                                <ng-container *ngFor="let field of col.field">
                                    {{getConcat(row,field)}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'deliveryRoute'">
                                <app-track-package-line-component
                                        [nodes]="row[field]"></app-track-package-line-component>
                            </ng-container>
                            <!--<ng-container *ngSwitchCase="'currency'">
                                {{row[col.field]}}
                            </ng-container>-->
                            <ng-container *ngSwitchCase="'concatKeys'">
                                <ng-container>
                                    {{getDeepData(row, col.field)}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'attachment'">
                                <div *ngIf="!row[col.field]" class="attachments-btn">
                                    <img (click)="!isAttachmentOpened ? openAttachment($event, row.packageId) : null"
                                         src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <div *ngIf="col.type === 'manageShipments' && col.field === 'status'"> {{row[col.field] | translate | packageTitle }}
                                    <div>
                                        <ng-container [ngSwitch]="row.status">
                                            <!-- the same view can be shown in more than one case -->
                                            <div *ngSwitchCase="'TRANSFERRED_OUT'">
                                                <div>
                                                    {{'PARTNER.PARTNER_NAME' | translate}}: {{row.partnerName}}
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">
                                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{row.driverName}}
                                            </div>
                                            <div *ngSwitchCase="'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'">
                                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{row.driverName}}
                                            </div>
                                            <div *ngSwitchCase="'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'">
                                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{row.driverName}}
                                            </div>
                                            <div *ngSwitchCase="'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'">
                                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{row.driverName}}
                                            </div>
                                            <div *ngSwitchCase="'SCANNED_BY_HANDLER_AND_UNLOADED'">
                                                {{row.hubName}} {{(row.label ? "-" + row.label : "")}}
                                            </div>
                                            <div *ngSwitchCase="'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'">
                                                {{row.hubName}} {{(row.label ? "-" + row.label : "")}}
                                            </div>
                                            <div *ngSwitchCase="'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'">
                                                {{row.hubName}} {{(row.label ? "-" + row.label : "")}}
                                            </div>
                                            <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                                <ng-container [ngSwitch]="row.returnedPackageLocation">
                                                    <div *ngSwitchCase="'IN_HUB'">
                                                        <br>
                                                        {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}
                                                        : {{row.hubName}} {{(row.label ? "-" + row.label : "")}}
                                                    </div>
                                                    <div *ngSwitchCase="'IN_CAR'">
                                                        <br>
                                                        {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{row.driverName}}
                                                    </div>
                                                    <div *ngSwitchCase="'DELIVERED_TO_SENDER'">
                                                        <br>
                                                        {{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER'|translate}}
                                                    </div>
                                                    <span *ngSwitchDefault></span>
                                                </ng-container>
                                            </div>
                                            <span *ngSwitchDefault></span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div *ngIf="TYPE !== 'driver-attempts-report' && col.field !== 'adminNotes' && col.type !== 'manageShipments'">{{row[col.field]}}</div>
                                <div *ngIf="TYPE === 'driver-attempts-report'">{{(row[col.field]) | translate}}</div>
                                <div *ngIf="col.field === 'adminNotes' && col.type !== 'manageShipments' && col.status !== 'DRIVER_NAME'" class="row" style="align-items: center;">
                                    <div class="col-sm-10">
                                        {{row[col.field] | translate | packageTitle}}
                                    </div>
                                    <div class="edit-action-comment cursor-pointer col-sm-2"
                                         (click)="onPostponePackage(true, row)">
                                        <i class="far fa-comment-dots"></i>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td *ngIf="isNotDelivered">
                        <div class="actions-container">
                            <div class="edit-action action cursor-pointer" (click)="deliverPackage(row)">
                                <i class="fas fa-file-export"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template *ngIf="TYPE === 'drivers_Reports' || TYPE === 'hubs_Reports' || TYPE === 'partners_Reports' || forceViewTotal" pTemplate="footer">
                <tr [ngClass]="{'totals-tr': TYPE === 'drivers_Reports'}">
                    <td [ngClass]="{'totals-td': TYPE === 'drivers_Reports'}">{{'GENERAL.TOTALS' | translate | packageTitle}}</td>
                    <td *ngFor="let col of columns | slice: startFromIndex + columnsToSkipInTotal; let i = index"> {{this.totalValues !== undefined ? this.totalValues[col.field] : 0}}</td>
                </tr>
            </ng-template>

<!--            <ng-template *ngIf="TYPE === 'drivers_Reports' || TYPE === 'hubs_Reports' || TYPE === 'partners_Reports'" pTemplate="footer">-->
<!--                <tr [ngClass]="{'totals-tr': TYPE === 'drivers_Reports'}" *ngIf="TYPE === 'drivers_Reports' || TYPE === 'hubs_Reports' || TYPE === 'partners_Reports'" style="font-size: 13px;">-->
<!--                    <td [ngClass]="{'totals-td': TYPE === 'drivers_Reports'}">{{'GENERAL.TOTALS' | translate}}</td>-->
<!--                    <td *ngFor="let col of columns | slice: startFromIndex; let i = index"> {{this.totalValues !== undefined ? this.totalValues[col.field] : 0}}</td>-->
<!--                </tr>-->
<!--            </ng-template>-->
        </p-table>
    </div>
    <div *ngIf="(statics && statics.length)|| (APIStatistics && APIStatistics.length)"
         class="header-filters d-flex flex-row">
        <div *ngFor="let stat of statics" class="cod-title flex-1 ng-star-inserted">{{stat.title|translate | packageTitle  }}
            : {{data ? data[stat.val] : null}}</div>
        <div *ngFor="let stat of APIStatistics" class="cod-title flex-1 ng-star-inserted">
            <div *ngIf="apiStatisticsResults && apiStatisticsResults[stat.val]">
                {{stat.title|translate | packageTitle  }}
                : {{apiStatisticsResults ? apiStatisticsResults[stat.val] : null}}
            </div>
        </div>
    </div>
</div>
