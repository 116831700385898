import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
    BUNDLE_STATUS_LIST,
    HUB_MANAGER_STATUS_LIST,
    JETT_SHIPMENT_STATUS_LIST,
    LCL_SHIPMENT_STATUS_LIST,
    SAR_SHIPMENT_STATUS_LIST,
    SHIPMENT_CONSTANTS,
    SHIPMENT_STATUS_LIST,
    SPECIAL_STATUS_FOR_HUBS_ACCOUNTING
} from './constants';
import {UserService} from '../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable()
export class ChangePackageStatusService {
    /**
     * Flag used to allow the user to access New Package view.
     * the same view is used to edit Package.
     * some users are allowed to edit package but forbidden to "new Package" view.
     * @type {boolean}
     */
    sarCompany = [256, 22];
    jettCompany = [236, 317];
    constructor(private httpClient: HttpClient,
                private translateService: TranslateService,
                private userService: UserService) {
    }

    public getPackageStats() {
        return this.httpClient.get(SHIPMENT_CONSTANTS.GET_PACKAGE_STATS);
    }
    public getPackageStatusList(userRole: string = '', excludeStatus: string[] = [], bundle?) {
        let StatusList: any[];
        let statusListConstant: any[];
        let translateParent = '';
        let specialStatusesList = [];
        if (this.userService.userInfo.isLcl) {
            statusListConstant = LCL_SHIPMENT_STATUS_LIST;
            translateParent = 'LCL.STATUS_LIST.';
        } else if (bundle) {
            statusListConstant = BUNDLE_STATUS_LIST;
            translateParent = 'BUNDLES_STATUS.';
        } else {
            if (this.jettCompany.indexOf(this.userService.userInfo.companyId) !== -1 || this.jettCompany.indexOf(this.userService.userInfo.companyId) !== -1) {
                statusListConstant = JETT_SHIPMENT_STATUS_LIST;
            } else if (userRole === 'HUB_MANAGER' || userRole === 'MULTIPLE_HUBS_MANAGER') {
                statusListConstant = HUB_MANAGER_STATUS_LIST;
            } else if (this.sarCompany.indexOf(this.userService.userInfo.companyId) !== -1) {
                statusListConstant = SAR_SHIPMENT_STATUS_LIST;
            } else {
                statusListConstant = SHIPMENT_STATUS_LIST;
            }
        }

        if (this.userService.userInfo.isEnableExportingBetweenHubs && !bundle) {
            specialStatusesList = SPECIAL_STATUS_FOR_HUBS_ACCOUNTING;
        }

        statusListConstant = _.cloneDeep(statusListConstant);

         StatusList = specialStatusesList.length ?  statusListConstant.concat(...specialStatusesList) : statusListConstant;
        if (excludeStatus.length) {
            StatusList = StatusList.filter((status) => {
                if (excludeStatus.indexOf(status) === -1) {
                    return status;
                }
            });
        }

        if (!excludeStatus.filter(status => status === 'EXPORTED_TO_THIRD_PARTY').length && this.userService.userInfo.hasThirdParty || bundle) {
            StatusList.push('EXPORTED_TO_THIRD_PARTY');
        }

        return StatusList.map(status => {
            return {label: this.translateService.instant(`${translateParent}${status}`), value: status};
        });
    }
}
