<div class="shared-close-icon" (click)="closeModal()"><img src="../../../../assets/icons/close-icon.svg" alt=""/></div>
<div class="choose-existing-container">
    <div id="submitting-container" *ngIf="isSubmitting">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="header">
        <div class="title">
            {{ (driverPricing ? 'USERS.CHOOSE_DRIVER' : (isHubsPricingList ? 'ADMINISTRATION.HUB_LIST_DROPDOWN_PLACEHOLDER': 'USERS.CHOOSE_CUSTOMER')) | translate }}
        </div>
        <div class="search-container">
            <i class="fas fa-search"></i>
            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                   [(ngModel)]="searchContent"
                   (input)="onSearch()">
        </div>
    </div>
    <div class="list-container">
        <ng-container *ngIf="spinnerState === spinnerStats.LOADED">
            <ng-container *ngIf="users && users.length > 0; else emptyData">
                <div class="assigner-container" *ngFor="let user of users" (click)="chooseUser(user)">
                    <ng-container *ngIf="!isHubsPricingList">
                        <div class="assigner-name" *ngIf="!isFullName">
                            {{user.firstName }}&nbsp; <span
                                *ngIf="user.middleName">{{user.middleName}}&nbsp;</span>{{user.lastName}}, {{user.phone}}
                            <span *ngIf="user.businessName">({{user.businessName}})</span>
                        </div>
                        <div class=" assigner-name" *ngIf="isFullName">
                            {{user.customerName }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isHubsPricingList">
                        <div class=" assigner-name">
                            {{user.name}}
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="spinnerState === spinnerStats.LOADING">
            <div class="text-align: center">
                <p-progressSpinner>
                </p-progressSpinner>
            </div>
        </ng-container>
        <ng-container *ngIf="spinnerState === spinnerStats.EMPTY">
            <app-no-data-available-component>
            </app-no-data-available-component>
        </ng-container>
    </div>
</div>

<ng-template #emptyData>
    <app-no-data-available-component>
    </app-no-data-available-component>
</ng-template>
