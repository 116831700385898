import {Component} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-idle-customers-report',
    templateUrl: './idle-customers-report.component.html',
    styleUrls: ['./idle-customers-report.component.scss']
})
export class IdleCustomersReportComponent {
    public idleCustomersReport = REPORTS_CONSTANTS.DETAILED_REPORTS.IDLE_CUSTOMER_REPORT;
    public reportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.IDLE_CUSTOMERS_REPORT_API;
    public IdleCustomerRawDataUrl = REPORTS_CONSTANTS.DETAILED_REPORTS.IDLE_CUSTOMER_RAW_DATA_API;

    constructor() {}
}
