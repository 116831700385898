<p-toast></p-toast>
<div class="all-cod-customers h-100">

    <p-overlayPanel #filtersFormOverLay
                    class="filters-menu-panel"
                    [dismissable]="true"
                    (onHide)="filterFormOn = false"
                    [showCloseIcon]="false">
        <div class="filters-menu-container">
            <div class="filters-header">
                <div class="filters-menu-title">
                    <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                </div>
            </div>
            <div class="filters-inputs-group d-flex flex-column">

                <div class="filter-item w-100">
                    <div class="filter-label">{{'SHIPMENT.TABLE.STATUS' | translate}}</div>
                    <div class="filter-value w-100">
                        <ng-select [items]="codTypes"
                                   bindValue="value"
                                   [(ngModel)]="selectedCodType"
                                   [multiple]="true"
                                   [closeOnSelect]="false"
                                   [dropdownPosition]="'bottom'"
                        >
                        </ng-select>
                    </div>
                </div>

                <div class="filter-item w-100">
                    <div class="filter-label">{{'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME' | translate}}</div>
                    <div class="filter-value w-100">
                        <app-users-list-dropdown-component
                            #userFilter
                            [placeHolder]="' '"
                            [showResetButton]="true"
                            (userChanged)="onSelectCustomer($event)"
                        ></app-users-list-dropdown-component>
                    </div>
                </div>
            </div>
            <div class="filters-menu-actions">
                <div class="reset-action filter-menu-action" (click)="resetFilters()">
                    <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                </div>
                <div class="apply-filters-action filter-menu-action" (click)="onFilter()">
                    <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                </div>
            </div>
        </div>
    </p-overlayPanel>

    <div class="header d-flex justify-content-between flex-wrap">
        <div class="header-title page-header-title">
            <div class="title">{{'ACCOUNTING.ALL_COD_CUSTOMERS.TITLE' | translate}}</div>
            <div class="total-number-badge">{{totalRecords}}</div>
        </div>
        <div class="actions-and-filter d-flex flex-wrap align-items-center">
            <div class="filters d-flex justify-content-between align-items-center flex-wrap">
                <div class="add-business-tab">
                    <button (click)="showEmptyCODsSelection('EMPTY')"
                            [ngClass]="{'active': showEmptyCODs === 'EMPTY'}">
                        <div>{{ 'ACCOUNTING.ALL_COD_CUSTOMERS.SHOW_EMPTY_CODS' | translate }}</div>
                    </button>
                    <button (click)="showEmptyCODsSelection('NOT_EMPTY')"
                            [ngClass]="{'active': showEmptyCODs === 'NOT_EMPTY'}">
                        <div>{{ 'ACCOUNTING.ALL_COD_CUSTOMERS.SHOW_NOT_EMPTY_CODS' | translate }}</div>
                    </button>
                </div>
                <div class="toggle-filters-form-btn filter">
                    <div class="icon" (click)="showFiltersMenu($event)">
                        <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                        <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                    </div>
                </div>
                <div class="date-type">
                    <ng-select [items]="dateTypesList"
                               placeholder="{{ 'ACCOUNTING.CHOOSE_DATE_TYPE' | translate }}"
                               bindValue="value"
                               [(ngModel)]="selectedDateFilter"
                               (change)="onFilter()"
                               [closeOnSelect]="true"
                               [dropdownPosition]="'bottom'"
                    >
                    </ng-select>
                </div>
<!--                <div class="date date-filter">-->
<!--                    <div class="date-range">-->
<!--                        <span (click)="datepicker.click()">-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </span>-->
<!--                        <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                               [options]="dateRangPickerOptions" (selected)="onDateSelected($event)"/>-->
<!--                        <img class="arrow-down" src="../../../assets/icons/arrow-down.png">-->
<!--                    </div>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                <div class="search main-search-filter">
                    <i class="fas fa-search"></i>
                    <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." appInputDebounced
                           (inputDebounced)="onFilter()" [(ngModel)]="search">
                </div>
            </div>
        </div>
    </div>

    <div class="header-actions">
        <div class="action-button secondary-action-btn"
             [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length}"
             (click)="printSelectedPkgs('multiple')">
            {{"SHIPMENT.PRINT_MULTIPLE" | translate}}
        </div>
        <div class="action-button secondary-action-btn" *ngIf="showEmptyCODs !== 'EMPTY'"
             [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length}"
             (click)="printList()">
            {{"ACCOUNTING.PRINT_LIST" | translate}}
        </div>

        <div class="action-button secondary-action-btn" *ngIf="showEmptyCODs !== 'EMPTY'"
             [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length}"
             (click)="exportExcel()">
            {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}
        </div>
    </div>

    <div class="main-table all-cod-table">
        <p-table appPTableRtlFix dataKey="id" #table [value]="massCodReports"
                 paginatorDropdownAppendTo="body"
                 styleClass="all-mass-cod-reports-table flex-table"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>

            <ng-template pTemplate="header">
                <th>
                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                (onChange)="onToggleSelectAll()">
                    </p-checkbox>
                </th>
                <th>{{'BARCODE.TITLE' | translate}}</th>
                <th>{{'ADMINISTRATION.CUSTOMER_NAME' | translate}}</th>
                <th>{{'ACCOUNTING.CUSTOMER_ADDRESS' | translate}}</th>
                <th>
                    <app-users-list-dropdown-component [isHub]="true"
                                                       #hubFilter
                                                       label="ACCOUNTING.CUSTOMER_HUB"
                                                       [showLabel]="true"
                                                       [isFilter]="true"
                                                       [panelWidth]="'100%'"
                                                       (userChanged)="onChangeHubMethod($event)"></app-users-list-dropdown-component>
                </th>
                <th *ngIf="currency === 'SAR'">{{'ACCOUNTING.COD_FEES' | translate}}</th>
                <th>{{'SHIPMENT.NUMBER_OF_PACKAGES' | translate}}</th>
                <th>{{'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST' | translate}}</th>
                <th>{{'ACCOUNTING.TOTAL_COST_SUM' | translate}}</th>
                <th>{{'ACCOUNTING.TOTAL_COD' | translate}}</th>
                <th *ngIf="isEnableVat">{{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.VAT_VALUE' | translate}}</th>
                <th *ngIf="isEnableVat">{{'MANAGE_COD_CUSTOMERS.MASS_INVOICES.NET_COD' | translate}}</th>
                <th *ngIf="isShowCommissionFees()">{{"PRICING.COMMISSION_FEE" | translate}}</th>
                <th>{{'SHIPMENT.TABLE.STATUS' | translate}}</th>
                <th>{{'ACCOUNTING.EXPORTED_BY' | translate}}</th>
                <th>{{'ACTIONS.PRINT' | translate}}</th>
                <th>{{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}</th>
                <th> {{"MANAGE_COD_CUSTOMERS.HISTORY" | translate}}</th>

            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr>
                    <td>
                        <p-checkbox [(ngModel)]="report.isSelected" binary="true"
                                    (click)="$event.stopPropagation()"
                        ></p-checkbox>
                    </td>
                    <td (click)="$event.stopPropagation()">
                        <div class="barcode-container flex-2d-center flex-column">
                            <div class="barcode-value flex-2d-center">
                                <div class="value">{{report.barcode}}</div>
                                <div class="copy-icon">
                                    <img src="../../assets/icons/track_package/copy.svg" alt=""
                                         (click)="copyMessage($event,null, report.barcode)"
                                         data-toggle="tooltip" data-placement="top" title="Copy"
                                         class="copy_icon"/>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{{report.customerName}}</td>
                    <td>{{report.customerCity}}</td>
                    <td>{{report.customerHubName}}</td>
                    <td *ngIf="currency === 'SAR'">{{report.codFees}}</td>
                    <td>{{report.codPackagesNumber}}</td>
                    <td>{{report.totalCodWithoutCost}}</td>
                    <td>{{report.totalCost}}</td>
                    <td>{{report.totalCod}}</td>
                    <td *ngIf="isEnableVat">{{report.vatSum}}</td>
                    <td *ngIf="isEnableVat"> {{ report.netSum}}</td>
                    <td *ngIf="isShowCommissionFees()">{{report.commissionFee}}</td>
                    <td>
                        <ng-container *ngIf="report.status">
                            {{('ACCOUNTING.ALL_COD_CUSTOMERS.COD_TYPES.' + report.status) | translate}}
                        </ng-container>
                    </td>
                    <td>{{report.exportedBy}}</td>
                    <td>
                        <div class="flex-2d-center">
                            <div [ngClass]="{'row-action': !report.isPrinting}"
                                 (click)="printSelectedPkgs('single', printReport.bind(this,report, $event), report)">
                                <i *ngIf="!report.isPrinting" class="fas fa-print"></i>
                                <i *ngIf="report.isPrinting" class="fas fa-spinner fa-pulse"></i>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="flex-2d-center">
                            <div [ngClass]="{'row-action': !report.isExporting}"
                                 (click)="exportToExcel(report,$event)">
                                <i *ngIf="!report.isExporting" class="fas fa-file-excel"></i>
                                <i *ngIf="report.isExporting" class="fas fa-spinner fa-pulse"></i>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="flex-2d-center">
                            <div class="row-action"
                                 (click)="showHistory($event, report)">
                                <i class="fas fa-history"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
