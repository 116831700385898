import { Component, OnInit } from '@angular/core';
import {ThirdPartyService} from '../../Services/third-party.service';
import {ThirdParty} from '../../Models/third-party';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ThirdPartyDialogComponent} from '../third-party-dialog/third-party-dialog.component';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';

@Component({
  selector: 'app-third-party-list',
  templateUrl: './third-party-list.component.html',
  styleUrls: ['./third-party-list.component.scss']
})
export class ThirdPartyListComponent implements OnInit {
  public cards: ThirdParty[] = [];
  public canAddNewThirdParty = false;

  constructor(private thirdPartyService: ThirdPartyService,
              private modalService: NgbModal,
              private messageService: MessageService,
              private authenticationService: AuthenticationService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
      this.canAddNewThirdParty = this.authenticationService.getIsSuperAdmin();
      this.fetchThirdParty();
  }

  private fetchThirdParty() {
    this.thirdPartyService.getThirdParties().subscribe(res => {
      this.cards = res.users;
    })
  }

  addNewThirdParty() {
    let modal = this.modalService.open(
        ThirdPartyDialogComponent,
          <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
          }
      );

    modal.result.then(
        (status) => {
          if (status.isSuccess) {
            this.translateService.get('ALERTS.THIRD_PARTY_ADDED_SUCCESSFULLY').subscribe(
                (data) => {
                  this.messageService.add({severity: 'success', detail: data});
                  this.fetchThirdParty();
                }
            );
          }
      }
    ).catch((error) => {
    });
  }

}
