import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/modules/shared.module';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {TreeTableModule} from 'primeng/treetable';
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TripsBetweenCitiesComponent} from './trips-between-cities.component';
import {AddTripFormComponent} from './components/add-trip-form/add-trip-form.component';
import {TripsBetweenCitiesV1Component} from './trips-between-cities-v1/trips-between-cities-v1.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        TableModule,
        ToastModule,
        InputTextModule,
        TreeTableModule,
        AccordionModule,
        FieldsetModule,
        DropdownModule,
        OverlayPanelModule
    ],
    declarations: [TripsBetweenCitiesComponent, TripsBetweenCitiesV1Component,  AddTripFormComponent]
})
export class TripsBetweenCitiesModule {
}
