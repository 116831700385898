<app-field-set-form [label]="label"
                    [cssStyle]="cssStyle"
                    class="date-range-picker"
                    [formControlObj]="formControlObj"
                    [showLabel]="showLabel">
    <div class="picker-input" (click)="onPickRange($event, rangePickerPanel)">
        <i class="fas fa-calendar"></i>
        <span class="flex-1" [innerHtml]="getDate()"></span>
        <i class="fas fa-caret-down"></i>
    </div>

    <p-overlayPanel [styleClass]="'range-date-picker-panel'" #rangePickerPanel>
        <div class="range-picker-container" [formGroup]="dateRangeForm">
            <div class="d-flex flex-column justify-content-between">
                <div class="range-option-group">
                    <div class="field-checkbox d-flex align-items-baseline" *ngFor="let option of pickerOptionsRanges">
                        <p-radioButton name="newPickerOption" [value]="option.key"
                                       [inputId]="option.key" formControlName="newPickerOption"></p-radioButton>
                        <label [for]="option.key" class="ml-2">{{ option.label }}</label>
                    </div>
                </div>
                <div class="actions d-flex">
                    <div class="submit-action" (click)="submit($event, rangePickerPanel)"
                         [ngClass]="{'disabled-btn disabled': enableSubmitinCustomDateRange !== 2}"
                         *ngIf="dateRangeForm.get('newPickerOption').value === 'CUSTOM'">
                        {{ 'ACTIONS.DONE' | translate }}
                    </div>
                    <div class="cancel-action" (click)="cancel($event, rangePickerPanel)"
                         *ngIf="dateRangeForm.get('newPickerOption').value === 'CUSTOM'">
                        {{ 'ACTIONS.CANCEL' | translate }}
                    </div>
                </div>
            </div>
            <div class="range-picker-calendar"
                 [ngClass]="{'disabled': dateRangeForm.get('newPickerOption').value !== 'CUSTOM'}">
                <p-calendar #calendar [inline]="true" selectionMode="range" showButtonBar="true"
                            (onShow)="onShow.emit($event)"
                            [disabled]="dateRangeForm.get('newPickerOption').value !== 'CUSTOM'"
                            (onSelect)="selected($event)"
                            (onClose)="onClose.emit($event)"
                            [numberOfMonths]="2" formControlName="newRange">
                </p-calendar>
            </div>
        </div>
    </p-overlayPanel>
</app-field-set-form>
