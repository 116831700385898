import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShipmentsService} from '../services/shipments.service';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {ActivatedRoute, Params} from '@angular/router';
import {setBarcodeReader} from '../../shared/services/helpers';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {playError} from '../../shared/behavior/sounds';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-unload-returned-packages',
    templateUrl: './unload-returned-packages.component.html',
    styleUrls: ['./unload-returned-packages.component.scss']
})
export class UnloadReturnedPackagesComponent implements OnInit, OnDestroy {
    public isScanning = false;
    public isLoading = false;
    public packages = [];
    public isReading = false;
    public id = '';
    public permissions = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private shipmentsService: ShipmentsService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private userService: UserService,
                private rolesService: RolesService) {
    }

    ngOnInit() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.isReading = true;
        this.route.queryParams.subscribe(
            (params: Params) => {
                if (params.id) {
                    this.id = params.id;
                }
            });
        setBarcodeReader((barcode) => {
            if (this.isReading) {
                this.barcodeReader(barcode);
            }
        });
    }

    ngOnDestroy(): void {
        this.isReading = false;
    }

    public startScanning() {
        this.isScanning = true;
    }

    public downloadMultiplePackages() {
        if (!this.packages || !this.packages.length) {
            return;
        }
        this.isLoading = true;
        const selectedPackages = this.packages.map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleChangeStatus(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    public resetScanning(showConfirm = true) {
        if (showConfirm) {
            this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.isScanning = false;
                        this.packages = [];
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO'],
                });
            });
        } else {
            this.isScanning = false;
            this.packages = [];
        }
    }

    public enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isReturned = true;
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.selectPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public selectPackage(shipment) {
        for (const oldShipment in this.packages) {
            if (this.packages[oldShipment].barcode === shipment.barcode) {
                return;
            }
        }
        this.packages.unshift(shipment);
    }

    public finishScanning() {
        if (!this.packages || !this.packages.length) {
            return;
        }
        this.translateService.get(['ACCOUNTING.FINISH_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'])
            .subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.FINISH_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.unloadPackages();
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO']
                });
            });
    }

    public unloadPackages() {
        const body = this.id ? {hubId: this.id} : {};
        body['ids'] = this.packages.map((shipment) => {
            return shipment.id;
        });
        this.shipmentsService.unloadReturnedPackages(body).subscribe(
            (response) => {
                this.translateService.get(
                    'ALERTS.CHANGED_STATUS'
                )
                    .subscribe(
                        (message) => {
                            this.confirmationService.confirm({
                                message: message,
                                rejectVisible: false,
                                acceptLabel: 'OK'
                            });
                        }
                    );
                this.resetScanning(false);
            }, () => {
            }
        );
    }

    private barcodeReader(barcode) {
        if (!this.isScanning) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.getReturnedPakcageByBarcode(barcode).subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.selectPackage(shipment);
                        this.isLoading = false;
                    }
                );
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    public removePackage(shipment) {
        this.packages.splice(this.packages.indexOf(shipment), 1);
    }

}
