<p-toast [baseZIndex]="toastZIndex"></p-toast>
<ng-template #rowActions let-close="close">
    <div class="row-actions-container" [ngSwitch]="type">
        <div class="row action" (click)="editEntity(triggeredEntity)">
            <div class="icon">
                <img class="edit-button" src="../../../../assets/icons/containers-management/edit.svg" alt="icon"/>
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngSwitchCase="'AREAS'" class="label">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.EDIT_AREA' | translate }}</div>
            <div *ngSwitchCase="'ROWS'" class="label">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.EDIT_ROW' | translate }}</div>
        </div>
    </div>
</ng-template>

<ng-template #rowActions2 let-close="close">
    <div class="row-actions-container" [ngSwitch]="type">
        <div class="row action cursor-pointer" (click)="reserveLocation()">
                <div class="icon"><i class="fas fa-book"></i></div>
                <div class="label">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.RESERVE_LOCATION' | translate }}</div>
        </div>
    </div>
</ng-template>

<div class="close-icon" (click)="closeModal()"><img src="../../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}" [ngSwitch]="type">

    <div class="row d-inline-flex main-container">

        <div class="side-container">
            <div class="row title-container">
                <div class="label">
                    {{ ('FULFILMENT.WAREHOUSE.MANAGE' | translate) + ' ' + ('FULFILMENT.WAREHOUSE.' + type + '.TITLE' | translate) }}
                </div>
            </div>

            <div *ngSwitchCase="type === 'AREAS' || type === 'ROWS' ? type : ''"
                 class="hubs-list-container" [ngClass]="type === 'ROWS' ? 'list-container-2' : ''">
                <div class="list-header">
                    {{ 'FULFILMENT.WAREHOUSE.FILTERS.CHOOSE_WAREHOUSE' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchWarehouse($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onWarehousesScroll($event)">
                    <div class="row list-item" *ngFor="let warehouse of warehouses; trackBy: trackWarehouseById"
                         (click)="selectWarehouse(warehouse)"
                         [ngClass]="selectedWarehouse && warehouse.id === selectedWarehouse.id ? 'list-item-selected' : ''"
                    >
                        <div class="list-name">{{warehouse.name}}</div>
                    </div>
                </div>
                <div *ngIf="isLoadingWarehouses" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>

            <div *ngSwitchCase="type === 'LOCATIONS' || type === 'ROWS' ? type : ''"
                 class="areas-list-container list-container-2"
                 [ngClass]="type === 'LOCATIONS' ? 'list-container-3' : ''">
                <div class="list-header" tooltipPosition="top" tooltipEvent="focus" tabindex="-1"
                     pTooltip="{{ 'FULFILMENT.WAREHOUSE.ALERTS.CHOOSE_AREA' | translate }}" tooltipStyleClass="alert-tooltip"
                     [appendTo]="chooseAreaHeader" [tooltipDisabled]="selectedArea" #chooseAreaHeader>
                    {{ 'FULFILMENT.WAREHOUSE.FILTERS.CHOOSE_AREA' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchArea($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onAreasScroll($event)">
                    <div class="row list-item"
                         [ngClass]="selectedArea && area.id === selectedArea.id ? 'list-item-selected' : ''"
                         *ngFor="let area of areas; trackBy: trackAreaById" (click)="selectArea(area)">
                        <div class="list-name">{{area.label}} ({{area.name}})</div>
                    </div>
                </div>
                <div *ngIf="isLoadingAreas || isLoadingPrint" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>


            <div *ngSwitchCase="'LOCATIONS'" class="rows-list-container list-container-3">
                <div class="list-header">
                    {{ 'FULFILMENT.WAREHOUSE.FILTERS.CHOOSE_ROW' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchRow($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onRowsScroll($event)">
                    <div class="row list-item"
                         [ngClass]="selectedRow && row.id === selectedRow.id ? 'list-item-selected' : ''"
                         *ngFor="let row of rows; trackBy: trackRowById" (click)="selectRow(row)">
                        <div class="list-name">{{row.label}} ({{row.name}})</div>
                    </div>
                </div>
                <div *ngIf="isLoadingRows || isLoadingPrint" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>

            <div *ngSwitchCase="'LOCATIONS'" class="shelves-list-container list-container-3">
                <div class="list-header">
                    {{ 'FULFILMENT.WAREHOUSE.FILTERS.CHOOSE_SHELF' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchShelf($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onShelvesScroll($event)">
                    <div class="row list-item"
                         [ngClass]="selectedShelf && shelf.id === selectedShelf.id ? 'list-item-selected' : ''"
                         *ngFor="let shelf of shelves; trackBy: trackShelfById" (click)="selectShelf(shelf)">
                        <div class="list-name">{{ (shelf.hubColumnLabel ? shelf.hubColumnLabel + ' - ' : '') + shelf.label}}</div>
                    </div>
                </div>
                <div *ngIf="isLoadingShelves || isLoadingPrint" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>
        </div>

        <div class="table-container">
            <div class="list-loader main-loader" *ngIf="isLoadingPrint">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <p-table *ngIf="!customColumns || customColumns.length <= 0" [value]="tableValues" [loading]="isLoading" class="entities-table-component"
                     [responsive]="true" scrollHeight="auto" styleClass="entities-table"
                     (scroll)="onTableScroll($event)">
                <ng-template pTemplate="header">
                    <tr *ngIf="!customColumns || customColumns.length <= 0">
                        <!--            <th class="table-header-selection"><p-checkbox></p-checkbox></th>-->
                        <th *ngSwitchCase="'LOCATIONS'"
                            class="table-header-barcode">{{ 'FULFILMENT.WAREHOUSE.LOCATIONS.BARCODE' | translate }}</th>
                        <th *ngSwitchCase="'AREAS'" class="table-header-name">
                            <span>{{ 'FULFILMENT.WAREHOUSE.AREAS.NAME' | translate }}</span>
                        </th>
                        <th class="table-header-label">
                            <span *ngSwitchCase="'AREAS'">{{ 'FULFILMENT.WAREHOUSE.AREAS.LABEL' | translate }}</span>
                            <span *ngSwitchCase="'ROWS'">{{ 'FULFILMENT.WAREHOUSE.ROWS.LABEL' | translate }}</span>
                            <span *ngSwitchCase="'LOCATIONS'">{{ 'FULFILMENT.WAREHOUSE.LOCATIONS.LABEL' | translate }}</span>
                        </th>
                        <th *ngSwitchCase="'ROWS'"
                            class="table-header-columns">{{ 'FULFILMENT.WAREHOUSE.TOTAL_COLUMNS' | translate }}</th>
                        <th *ngSwitchCase="'ROWS'"
                            class="table-header-shelves">{{ 'FULFILMENT.WAREHOUSE.TOTAL_SHELVES' | translate }}</th>
                        <th *ngSwitchCase="'ROWS'"
                            class="table-header-locations">{{ 'FULFILMENT.WAREHOUSE.TOTAL_LOCATIONS' | translate }}</th>
                        <th *ngSwitchCase="'LOCATIONS'">
                            {{ 'FULFILMENT.WAREHOUSE.LOCATIONS.BIN_BARCODE' | translate }}
                        </th>
                        <th *ngSwitchCase="'AREAS'">{{ 'FULFILMENT.WAREHOUSE.AREAS.ZONE_TYPE' | translate }}</th>
                        <th *ngIf="false" class="table-header-actions"></th>
                        <th *ngIf="type === 'ROWS'">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.ROW_DETAILS' | translate }}</th>
                        <th *ngSwitchCase="'LOCATIONS'">
                            <div class="header-filter">
                                    <span class="status-title"
                                          [ngClass]="{'active-status-title': selectedType !== 'ALL' && selectedType !== ''}">{{'FULFILMENT.WAREHOUSE.LOCATION_TYPE' | translate}}</span>
                                <div class="manage-shipment-status-filter"
                                     [ngClass]="{'active-status-filter': selectedType !== 'ALL' && selectedType !== ''}"
                                     style="z-index: 3 !important;">
                                    <p-dropdown styleClass="shipment-status-dropdown"
                                                [(ngModel)]="selectedType" [options]="locationTypeOptions"
                                                [appendTo]="'body'"
                                                filter="true" (onChange)="getLocations(true)">
                                        <ng-template let-status
                                                     pTemplate="selectedItem">{{ status.label | translate }}</ng-template>
                                        <ng-template let-status
                                                     pTemplate="item">{{ status.label | translate }}</ng-template>
                                    </p-dropdown>
                                </div>
                            </div>

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr *ngIf="!customColumns || customColumns.length <= 0">
                        <!--            <td><p-checkbox></p-checkbox></td>-->
                        <td *ngSwitchCase="'LOCATIONS'">
                            <div>
                                <img [src]="item.barcodeImage" alt="{{ item.barcode }}">
                                <div class="hover-display-parent">
                                    <span class="px-2">{{ item.barcode }}</span>
                                    <i class="fas fa-copy cursor-pointer hover-display" (click)="copyText($event, item.barcode)"></i>
                                </div>
                            </div>

                        </td>
                        <td *ngSwitchCase="'AREAS'">{{ item.name }}</td>
                        <td>{{ item.label }}</td>
                        <td *ngSwitchCase="'ROWS'">{{ item.totalCols }}</td>
                        <td *ngSwitchCase="'ROWS'">{{ item.totalShelves }}</td>
                        <td *ngSwitchCase="'ROWS'">{{ item.totalLocations }}</td>
                        <td *ngSwitchCase="'LOCATIONS'">
                            <div *ngIf="item.binBarcode">
                                <img [src]="item.binBarcodeImage" [alt]="item.binBarcode">
                                <div class="hover-display-parent">
                                    <span class="px-2">{{ item.binBarcode }}</span>
                                    <i class="fas fa-copy cursor-pointer hover-display" (click)="copyText($event, item.binBarcode)"></i>
                                </div>
                            </div>
                        </td>
                        <td *ngSwitchCase="'AREAS'">{{('FULFILMENT.WAREHOUSE.AREAS.ZONE_TYPES_LIST.' + item.zone) | translate}}</td>
                        <td *ngIf="false"
                            class="cursor-pointer">
                            <img src="../../../../assets/icons/tripple_dots.svg"
                                 (click)="setTriggeredEntity(item)"
                                 autoClose="outside"
                                 placement="{{currentLang === 'en' ? 'left': 'right'}}"
                                 #rowActionsPopOver="ngbPopover"
                                 [ngbPopover]="rowActions"
                                 alt="actions">
                        </td>
                        <td *ngIf="type === 'ROWS'">
                            <span class="cursor-pointer" [ngClass]="{'disabled-btn disabled': disableEye}" (click)="openRowDialog(item)">
                                        <i class="fas fa-eye fa-2x"></i>
                            </span>
                        </td>

                        <td *ngSwitchCase="'LOCATIONS'" >
                            {{ (item.type ? 'FULFILMENT.WAREHOUSE.LOCATION_TYPES.' + item.type : 'BIN') | translate }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <app-no-data-available-component [message]="selectedRow || selectedShelf || type !== 'LOCATIONS'
                        ? 'ALERTS.NO_DATA_AVAILABLE' : selectedArea ? 'FULFILMENT.WAREHOUSE.ALERTS.CHOOSE_ROW_SHELF'
                        : 'FULFILMENT.WAREHOUSE.ALERTS.CHOOSE_AREA'"></app-no-data-available-component>
                </ng-template>
            </p-table>
            <app-generic-table style="height: calc(100% - 100px);" *ngIf="customColumns && customColumns.length > 0"
                               [columns]="columnsDef" [config]="tableConfig" #table [customTemplates]="{barcodeStatus, checkbox}">
                <ng-template #barcodeStatus let-item>
                    <span [ngClass]="{'reserved-badge':item.status === 'RESERVED','not-reserved-badge': item.status !== 'RESERVED'}"
                          class="text-badge">{{(item.status === 'RESERVED' ? 'FULFILMENT.BIN.TABLE.RESERVED' : 'FULFILMENT.BIN.TABLE.NOT_RESERVED') | translate}} </span>
                </ng-template>
                <ng-template #checkbox let-item>
                    <p-checkbox [(ngModel)]="item.isSelected" binary="true"
                                (onChange)="onToggleSelection()"></p-checkbox>
                </ng-template>

            </app-generic-table>
            <div class="bottom-actions">
                <div class="start-actions flex-1">
                    <div class="cod-title" *ngIf="showTotalLocations">
                        <b>{{totalLocationsNo | number : digitFormat}}</b>
                        <br>
                        {{"FULFILMENT.WAREHOUSE.TOTAL_LOCATIONS" | translate}}
                    </div>


                    <div class="cod-title" *ngIf="type !== 'AREAS' && type !== 'ROWS'">
                        <b>{{binLocationsCount | number : digitFormat}}</b>
                        <br>
                        {{"FULFILMENT.WAREHOUSE.BIN_LOCATIONS" | translate}}
                    </div>

                    <div class="cod-title" *ngIf="type !== 'AREAS' && type !== 'ROWS'">
                        <b>{{shelfLocationsCount | number : digitFormat}}</b>
                        <br>
                        {{"FULFILMENT.WAREHOUSE.SHELF_LOCATIONS" | translate}}
                    </div>

                    <div class="cod-title" *ngIf="type !== 'AREAS'&&type !== 'ROWS'">
                        <b>{{palletLocationsCount | number : digitFormat}}</b>
                        <br>
                        {{"FULFILMENT.WAREHOUSE.PALLET_LOCATIONS" | translate}}
                    </div>
                </div>
                <div class="end-actions">

                    <div *ngSwitchCase="'AREAS'" [ngClass]="{'disabled-btn disabled': !selectedWarehouse || isLoading}"
                         class="done-action" (click)="newEntity()">
                        <span>{{ 'FULFILMENT.WAREHOUSE.ACTIONS.NEW_AREA' | translate }}</span>
                    </div>
                    <div *ngSwitchCase="'LOCATIONS'"
                         [ngClass]="{'disabled-btn disabled': !selectedArea || !selectedRow || isLoading || locations.length === 0}"
                         class="done-action" (click)="openPrintDialog()">
                        <span>{{ 'FULFILMENT.WAREHOUSE.ACTIONS.PRINT_LOCATIONS_BARCODES' | translate }}</span>
                    </div>
                    <div *ngSwitchCase="'ROWS'" [ngClass]="{'disabled-btn disabled': isLoading}"
                         class="done-action" (click)="newEntity()">
                        <span>{{ 'FULFILMENT.WAREHOUSE.ACTIONS.NEW_ROWS' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<p-toast position="bottom-left" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center d-flex">
                <span class="ml-2 mr-w"><i class="fas fa-spinner fa-pulse"></i></span>
                <h5>{{message.summary}}</h5>
            </div>
        </div>
    </ng-template>
</p-toast>
