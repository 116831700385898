<p-toast [baseZIndex]="toastZIndex"></p-toast>
<p-overlayPanel #importExportPanelOptions class="import-export-menu">
    <div class="import-export-overlay">
        <div  class="import-export-action export-action d-flex" (click)="downloadExcelTemplate()">
            <div class = "label">
                <i *ngIf="!downloadTemplateLoader" class="far fa-save"></i>
                <i *ngIf="downloadTemplateLoader" class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="label">
                <span style="padding-right: 10px;">{{"ACTIONS.DOWNLOAD_EXCEL_TEMPLATE" | translate}}</span>
            </div>
        </div>
        <div  class="import-export-action d-flex" (click)="uploadFile(fileChooser)">
            <div class = "label">
                <i *ngIf="!uploadExcelLoader" class="fas fa-file-csv"></i>
                <i *ngIf="uploadExcelLoader" class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="label">
                <span style="padding-right: 10px;">{{"SHIPMENT.IMPORT_FROM_EXCEL" | translate}}</span>
            </div>
            <input type="file"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   style="width: 0; height: 0; opacity: 0; position: absolute" #fileChooser
                   (change)="uploadExcel($event,false,true, true)">
        </div>
    </div>
</p-overlayPanel>
<!-- tabs List-->
<ng-template #tabsList>
    <div class="row-tabs-container">
        <div class="tab customers-tab" *ngIf="canShow('CUSTOMERS') && hubManagerConfig()" (click)="onChangeTab('customers')">{{ 'USERS_MANAGEMENT.CUSTOMERS_TAB' | translate }}</div>
        <div class="tab archived-customers-tab" *ngIf="canShow('ARCHIVED_CUSTOMERS') && hubManagerConfig()" (click)="onChangeTab('archived')">{{ 'USERS_MANAGEMENT.ARCHIVED_CUSTOMERS_TAB' | translate }}</div>
        <div class="splitter" *ngIf="canShow('USERS')"></div>
        <div class="tab users-tab" *ngIf="canShow('USERS')" (click)="onChangeTab('users')">{{ 'USERS_MANAGEMENT.USERS_TAB' | translate }}</div>
        <div class="tab users-tab" *ngIf="canShow('ARCHIVED_USERS')" (click)="onChangeTab('archived-users')">{{ 'USERS_MANAGEMENT.ARCHIVED_USERS_TAB' | translate }}</div>
        <div class="splitter" *ngIf="showDriverTab"></div>
        <div class="tab users-tab" *ngIf="showDriverTab" (click)="onChangeTab('drivers')">{{ 'USERS_MANAGEMENT.DRIVERS' | translate }}</div>
        <div class="splitter" *ngIf="canShow('DRIVERS')"></div>
        <div class="tab users-tab" *ngIf="canShow('DRIVERS')" (click)="onChangeTab('drivers')">{{ 'USERS_MANAGEMENT.DRIVERS_TAB' | translate }}</div>
        <div class="tab users-tab" *ngIf="canShow('ARCHIVED_DRIVERS')" (click)="onChangeTab('archived-drivers')">{{ 'USERS_MANAGEMENT.ARCHIVED_DRIVERS_TAB' | translate }}</div>

    </div>
</ng-template>

<!--row actions-->
<ng-template #rowActions>
    <div class="row-actions-container">
        <div class="row action" *ngIf="canEdit()" (click)="manageUser(triggeredUser)">
            <div class="icon">
                <img class="margin-left-10 edit-button" src="../../../assets/icons/containers-management/edit.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngIf="isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.EDIT_CUSTOMER' | translate }}</div>
            <div *ngIf="!isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.EDIT_USER' | translate }}</div>
        </div>
        <div class="row action" *ngIf="canResetPassword()" (click)="resetPassword(triggeredUser)">
            <div class="icon">
                <img class="margin-left-10 edit-button" src="../../../assets/icons/padlock.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{ 'AUTH.CHANGE_PASSWORD' | translate }}</div>
        </div>
        <div class="row action remove-action" *ngIf="canRemoveUser()" (click)="removeUser(triggeredUser)">
            <div class="icon">
                <img src="../../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngIf="isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.DELETE_CUSTOMER' | translate }}</div>
            <div *ngIf="!isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.DELETE_USER' | translate }}</div>
        </div>
        <div class="row action" *ngIf="canAddAttachments(triggeredUser)" (click)="showAttachment(triggeredUser)">
            <div class="icon">
                <img src="../../../assets/icons/attachments.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ADD_ATTACHMENTS' | translate }}</div>
        </div>
        <div class="row action" *ngIf="showExcel()" (click)="downloadExcel(triggeredUser)">
            <div class="icon">
                <img *ngIf="!downloadExcelLoader" src="../../../assets/icons/excel.svg" alt="icon" />
                <i *ngIf="downloadExcelLoader" class="fas fa-spinner fa-spin"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{ 'ACTIONS.DOWNLOAD_EXCEL_TEMPLATE' | translate }}</div>
        </div>
    </div>
</ng-template>
<div [ngClass]="{'page-ltr': selectedLanguage === 'en'}">
    <p-overlayPanel #exportPanelOptions styleClass="export-menu" >
        <div class="export-list">
            <div class="export-option" (click)="exportToPdf($event)">
                <div class="icon">
                    <i *ngIf="!isLoadingReport" class="fas fa-file-pdf"></i>
                    <i class="fas fa-spinner fa-pulse" *ngIf="isLoadingReport"></i>
                </div>
                <div class="label">
                    <span>{{'ACTIONS.EXPORT_TO_PDF' | translate}}</span>
                </div>
            </div>
            <div class="export-option" (click)="exportToExcel($event)">
                <div class="icon">
                    <img src="../../../assets/icons/track_package/xls.svg" alt=""
                         *ngIf="!isLoadingExcelReport">
                    <i class="fas fa-spinner fa-pulse" *ngIf="isLoadingExcelReport"></i>
                </div>
                <div class="label">
                    <span>{{'ACTIONS.EXPORT_TO_EXCEL' | translate}}</span>
                </div>
            </div>
        </div>

    </p-overlayPanel>
    <div class="users-management-container" [@simpleFadeAnimation]="'in'">
        <div class="container-details-container overflow-auto">
            <app-user-details
                    [isShow]="isShowUserDetails"
                    [apiPath]="apiPath"
                    [user]="triggeredUser"
                    [canEdit]="canEdit()"
                    (onUpdateUser)="manageUser($event.user)"
                    [canRemove]="canRemoveUser()"
                    (onRemoveUser)="removeUser($event.user)"
                    (onRemoveUserForever)="removeUserForever($event.user)"
                    [deleteLoader]="deleteLoader"
                    [canResetPassword]="canResetPassword()"
                    (onResetPassword)="resetPassword($event.user)"
                    (onCloseUserDetails)="onHideUserDetails($event)"
                    class="component"
            >
            </app-user-details>
        </div>
        <div class="d-flex top-side">
            <div class="d-inline-flex header">
                <div class="d-inline-flex sort-by">
                    <div class="header-title">{{ 'USERS_MANAGEMENT.TITLE' | translate }}</div>
                    <div class="sort-by-hidden-space"></div>
                    <div class="tabs-list-container header-btn">
                        <div class="tabs-list d-inline-flex" placement="bottom" autoClose="outside" [ngbPopover]="tabsList">
                            <div class="current-tab">{{getTabName() | translate }}</div>
                            <div class="tab-list-hidden-space"></div>
                            <div class="total-number-badge"><span>{{totalRecords}}</span></div>
                            <div style="width: 10px;"></div>
                            <div class="caret-down">
                                <i class="fas fa-caret-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header-hidden-space"></div>

            </div>
            <div class="flex-1"></div>
            <div *ngIf="canAddUser() && (selectedTab === 'customers' || selectedTab === 'drivers') && userRole !='FULFILLMENT_USER'"
                 class="show-new-requests d-inline-flex header-btn">
                <div class="row show-new-requests-btn">
                    <div class="label">{{ 'USERS_MANAGEMENT.NEW_REQUESTS' | translate }}</div>
                    <div class="total-number-badge new-requests-badge">
                        <span>{{newRequestsTotalNo}}</span>
                    </div>
                    <div class="show-new-requests-switch" [ngClass]="{'disabled-toggle-switch': !isShowNewRequests}">
                        <p-inputSwitch (onChange)="toggleShowNewRequests($event.checked)"></p-inputSwitch>
                    </div>
                </div>
            </div>
            <div class="show-new-requests" *ngIf="selectedTab === 'customers'">
                <div class="row show-new-requests-btn">
                    <div class="label">{{ 'USERS_MANAGEMENT.SHOW_CUSTOMERS_HAS_MEMBERS_ONLY' | translate }}</div>
                    <div class="show-new-requests-switch"
                         [ngClass]="{'disabled-toggle-switch': !isShowSubCustomerOnly}">
                        <p-inputSwitch (onChange)="toggleShowHasSubCustomersOnly($event.checked)"></p-inputSwitch>
                    </div>
                </div>
            </div>
            <div *ngIf="canAddUser() && selectedTab === 'customers'" class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>
            <div class="top-action" *ngIf="canAddUser() && selectedTab === 'customers'" (click)="triggerImportExportMenu($event, importExportPanelOptions)">
                <div class="print-icon">
                    <img src="../../../assets/icons/track_package/xls.svg" alt="" />
                </div>
                <div class="print-hidden-space hidden-sm-down"></div>
                <div class="label hidden-sm-down">{{'ACTIONS.IMPORT_EXCEL' | translate}}</div>
            </div>

            <div *ngIf="selectedTab === 'drivers'" class="top-action" (click)="openDriversTimeTracking($event)">
                <div class="print-icon">
                    <i class="fas fa-clock"></i>
                </div>
                <div class="print-hidden-space hidden-sm-down"></div>
                <div class="label hidden-sm-down">{{'DRIVERS_TIME_TRACKING.TITLE'| translate}}</div>
            </div>
            <div class="import-export-btn header-filter col-xs-6" *ngIf="isAdmin"
                 (click)="triggerImportExportMenu($event, exportPanelOptions)">
                <div>
                    <img style="position: relative; left: 5px;" src="../../../assets/icons/track_package/xls.svg"
                         alt=""/>
                    <span>{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}</span>
                    <img class="arrow-down"  src="../../../assets/icons/arrow-down.png">

                </div>
            </div>

            <div class="flat-button" *ngIf="canAddUser() && isDistributor && selectedTab === 'users'" (click)="showDriverCheckIns()">
                <i class="fas fa-address-book-o"></i>
                <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.SHOW_DRIVER_CHECK_IN_LIST' | translate }}</div>
            </div>
            <div class="top-side-hidden-space"></div>
            <div class="top-action" *ngIf="canAddUser()" (click)="createUser()">
                <div class="icon">
                    <img src="../../../assets/icons/plus.svg" alt="" />
                </div>
                <div class="print-hidden-space"></div>
                <div *ngIf="isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.ADD_CUSTOMER' | translate }}</div>
                <div *ngIf="!isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.ADD_USER' | translate }}</div>
            </div>
            <div class="top-action" *ngIf="canAddDriver()" (click)="createUser()">
                <div class="icon">
                    <img src="../../../assets/icons/plus.svg" alt="" />
                </div>
                <div class="print-hidden-space"></div>
                <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.ADD_DRIVER' | translate }}</div>

            </div>
            <div *ngIf="canAddUser()" class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>
<!--            <div *ngIf="canAddUser() && selectedTab === 'customers'" class="sort-by-print-splitter"></div>-->

           <div class="show-mode-container hidden-sm-down">
               <div class="d-inline-flex show-mode header-btn">
                   <div class="top-side-label show-mode-label">{{'CONTAINERS_MANAGEMENT.VIEW_MODE' | translate}}</div>
                   <div class="show-mode-hidden-space"></div>
                   <div class="d-inline-flex show-mode-options">
                       <div class="table-mode mode" [ngClass]="{'active-view-mode': viewMode === 'TABLE_MODE'}" (click)="onSetShowMode('TABLE_MODE')">
                           <img *ngIf="viewMode === 'TABLE_MODE'" src="../../../assets/icons/containers-management/list_selected.svg" alt="icon" />
                           <img *ngIf="viewMode === 'CARDS_MODE'" src="../../../assets/icons/containers-management/list_unselected.svg" alt="icon" />
                       </div>
                       <div class="cards-mode mode" [ngClass]="{'active-view-mode': viewMode === 'CARDS_MODE'}" (click)="onSetShowMode('CARDS_MODE')">
                           <img *ngIf="viewMode === 'CARDS_MODE'" src="../../../assets/icons/containers-management/grid_selected.svg" alt="icon" />
                           <img *ngIf="viewMode === 'TABLE_MODE'" src="../../../assets/icons/containers-management/grid_unselected.svg" alt="icon" />
                       </div>
                   </div>
               </div>
           </div>
<!--            <div *ngIf="selectedTab === 'users' || selectedTab === 'archived-users'" class="user-role-filter">-->
<!--                <p-multiSelect-->
<!--                        [options]="userRolesOptions"-->
<!--                        [filter]="false"-->
<!--                        defaultLabel="{{ 'USERS_MANAGEMENT.ADD_USER_FORM.USER_ROLE' | translate }}"-->
<!--                        selectedItemsLabel="{{ '{0} ' + ( 'GENERAL.ITEMS' | translate) }}"-->
<!--                        (onChange)="onChangeUserRolesFilter($event)"-->
<!--                        [(ngModel)]="selectedUserRoles"-->
<!--                >-->
<!--                </p-multiSelect>-->
<!--            </div>-->
            <div class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>

            <div *ngIf="!isHubCustomerCare && selectedTab === 'customers' && viewMode === 'CARDS_MODE'">

                <app-users-list-dropdown-component [isHub]="true" [user]="hubFilter"
                                                   [isGetAllHubs]="true"
                                                   [panelWidth]="'100%'"
                                                   (userChanged)="onHubSelected($event)"></app-users-list-dropdown-component>

            </div>

            <div *ngIf="(selectedTab === 'users' || selectedTab === 'archived-users' ) && !isSearchContent" class="user-role-filter">
                <p-dropdown
                        [showClear]="true"
                        placeholder="{{ 'USERS_MANAGEMENT.ADD_USER_FORM.USER_ROLE' | translate }}"
                        (onChange)="onChangeUserRolesFilter($event)" [filter]="false"
                        [options]="userRolesOptions" [(ngModel)]="selectedUserRole">
                </p-dropdown>
            </div>
            <div class="search-toggle cursor-pointer">
                <div class="manage-shipments-search-filter search-filter-container d-flex">
                    <div class="d-flex">
                        <div class="search-filter-toggle" (click)="toggleIsSearchContent(true)">
                            <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image" />
                        </div>
                        <div class="search-input"  *ngIf="isSearchContent">
                            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent" (input)="onSearch()" />
                        </div>
                    </div>
                    <div *ngIf="isSearchContent" class="hide-search-filter" (click)="toggleIsSearchContent(false)" >
                        <span>{{'ACTIONS.CANCEL' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="new-requests-container" *ngIf="isShowNewRequests">
            <app-cards-slider-component [isCustomer]="isCustomerOrArchived()" [method]="this.apiPath" [tabRoleParam]="tabRoleParam" [onStatusUpdate]="onSearch.bind(this)"></app-cards-slider-component>
        </div>
        <div class="data-table users-data-table" *ngIf="viewMode === 'TABLE_MODE'">
            <div class="containers-body flex-1 overflow-auto" (scroll)="onScrollTable($event)">
                <p-table [value]="users" [responsive]="true" scrollHeight="auto" styleClass="containers-table data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngIf="isCustomerOrArchived()">{{ 'USERS_MANAGEMENT.TABLE.CUSTOMER_NAME' | translate  }}</th>
                            <th *ngIf="!isCustomerOrArchived()">{{ 'USERS_MANAGEMENT.TABLE.USER_NAME' | translate }}</th>
                            <th *ngIf="!isCustomerOrArchived()">{{ 'USERS_MANAGEMENT.TABLE.BARCODE' | translate | packageTitle}}</th>
                            <th *ngIf="isCustomerOrArchived()">{{ 'USERS_MANAGEMENT.TABLE.STORE_NAME' | translate }}</th>
                            <th>{{ 'USERS_MANAGEMENT.TABLE.PHONE' | translate  }}</th>
                            <th>{{ 'USERS_MANAGEMENT.TABLE.EMAIL' | translate }}</th>
                            <th *ngIf="isCustomerOrArchived()">{{ 'USERS_MANAGEMENT.TABLE.ADDRESS' | translate }}</th>
                            <th *ngIf="!isCustomerOrArchived()">{{ 'USERS_MANAGEMENT.TABLE.USER_ROLE' | translate }}</th>
                            <th *ngIf="isCustomerOrArchived() && !isCustomerAccountManager()">{{ 'USERS_MANAGEMENT.TABLE.APPROVE_PACKAGES' | translate | packageTitle}}</th>
                            <th *ngIf="isArchived()"></th>
                            <th *ngIf="isCustomerOrArchived()">

                                <div class="header-filter" *ngIf="!isHubCustomerCare">
                                    <span class="table-status-title"
                                      [ngClass]="{'active': hubFilter}">{{"USERS_MANAGEMENT.TABLE.HUB" | translate | hubTitle}}</span>
                                    <div class="manage-shipment-status-filter select-hub-container"
                                         [ngClass]="{'active-shipment-status-filter': false}"
                                         style="z-index: 3 !important;">
                                        <app-users-list-dropdown-component [isHub]="true" [user]="hubFilter"
                                                                           [isGetAllHubs]="true"
                                                                           [panelWidth]="'100%'"
                                                                           (userChanged)="onHubSelected($event)"></app-users-list-dropdown-component>
                                    </div>
                                </div>

                                <ng-container *ngIf="isHubCustomerCare">
                                    {{"USERS_MANAGEMENT.TABLE.HUB" | translate | hubTitle}}
                                </ng-container>
                            </th>
                            <th *ngIf="showMoreActions()" style="width: 40px;"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div *ngIf="!isLoading" class="empty-content">
                            <app-no-data-available-component></app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-user>
                        <tr class="shipment-row" (click)="showUserDetails(user)">
                            <td>
                                <div class="d-inline-flex align-items-center" appStopPropagation>
                                    <div class="user-image">
                                        <app-upload-image-component
                                                class="user-image-upload"
                                                [showClear]="false"
                                                [(url)]="user.imageUrl"
                                                [imageSize]="50"
                                                [canUpload]="!isArchived()"
                                                (fileChange)="uploadUserImage(user, $event)"
                                                [defaultLogo]="'../../../../assets/icons/user-avatar.svg'"
                                        >
                                        </app-upload-image-component>
                                    </div>
                                    <div class="user-full-name-hidden-space"></div>
                                    <div class="td-text customer-name" (click)="showUserDetails(user)">
                                        {{user.firstName}} {{user.middleName}} {{user.lastName}}
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="!isCustomerOrArchived()">
                                <div class="barcode">{{user.barcode}}</div>
                            </td>
                            <td *ngIf="isCustomerOrArchived()">
                                <div class="td-text business-name">{{user.businessName}}</div>
                            </td>
                            <td>
                                <div class="phone-number">
                                    {{ user.phone }}
                                    <!--                                    <a href="tel:+{{user.phone}} "> {{ user.phone }} </a>-->
                                </div>
                            </td>
                            <td>
                                <div class="td-text user-name">{{user.email}}</div>
                            </td>
                            <td *ngIf="isCustomerOrArchived()">
                                <div class="td-text address">{{getAddress(user.address)}}</div>
                            </td>
                            <td *ngIf="!isCustomerOrArchived()">
                                <div class="user-role-container row justify-content-center">
                                    <div class="user-role">
                                        <span>{{user.role | translate }}</span>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="isCustomerOrArchived() && !isCustomerAccountManager()">
                                <div class="disable-packages-container d-inline-flex">
                                    <div class="disable-packages-label">{{ 'USERS_MANAGEMENT.TABLE.APPROVE_PACKAGES' | translate | packageTitle}}</div>
                                    <div class="disable-packages-hidden-space"></div>
                                    <div class="switch-btn" [ngClass]="{'disabled-toggle-switch': !user.isAutoApprovePackages}">
                                        <p-inputSwitch [(ngModel)]="user.isAutoApprovePackages" (onChange)="autoApproveUser($event.checked, user)"></p-inputSwitch>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="isArchived()">
                                <div class="d-inline-flex user-details-container" (click)="onRetrieveUser({user: user, complete: null})">
                                    <div class="user-retrieve-icon">
                                        <img class="margin-left-10 edit-button" src="../../../assets/icons/user_retrieve.svg" alt="icon" />
                                    </div>
                                    <div class="user-details-hidden-space"></div>
                                    <div *ngIf="isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.RETRIEVE_CUSTOMER' | translate }}</div>
                                    <div *ngIf="!isCustomerOrArchived()" class="label">{{ 'USERS_MANAGEMENT.ACTIONS.RETRIEVE_USER' | translate }}</div>
                                </div>
                            </td>
                            <td *ngIf="isCustomerOrArchived()">
                                <div class="td-text user-name">{{user.hubName}}</div>
                            </td>
                            <td *ngIf="showMoreActions()" appStopPropagation>
                                <div class="row-actions">
                                    <img
                                            style="padding: 10px;"
                                            (click)="setTriggeredUser(user)"
                                            placement="{{selectedLanguage === 'en' ? 'left': 'right'}}"
                                            autoClose="outside"
                                            #rowActionsPopOver="ngbPopover"
                                            [ngbPopover]="rowActions"
                                            src="../../../assets/icons/containers-management/menu.svg"
                                            alt=""
                                    />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader *ngIf="isLoading" [type]="1"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="cards-view-mode-container" *ngIf="viewMode === 'CARDS_MODE'">
            <div class="row" style="height: 100%; position: relative;">
                <div class="users-cards-container">
                    <div *ngIf="users.length || isLoading" class="user-item row" (scroll)="onScrollTable($event)">
                        <ng-container *ngIf="users.length">
                            <app-user-card-component
                                    *ngFor="let user of users"
                                    [isCustomer]="isCustomerOrArchived()"
                                    [selectedTab]="selectedTab"
                                    [isArchived]="isArchived()"
                                    [user]="user"
                                    [canResetPassword]="canResetPassword()"
                                    [canTrackingPassword]="canTrackingPassword()"
                                    [canEdit]="canEdit()"
                                    [canAddAttachment]="canAddAttachments(user)"
                                    [canDownloadExcelTemplate]="showExcel()"
                                    [downloadExcelLoader]="downloadExcelLoader"
                                    [canRemove]="canRemoveUser()"
                                    (onAutoApproveUser)="autoApproveUser($event.checked, $event.user)"
                                    (onShowDetails)="showUserDetails($event.user)"
                                    (onResetPassword)="onResetPassword($event)"
                                    (onEditUser)="onManageUser($event)"
                                    (onEditHubs)="onEditHubs($event)"
                                    (onAddAttachment)="showAttachment($event.user)"
                                    (onDownloadExcelTemplate)="downloadExcel($event.user)"
                                    (onRemoveUser)="onRemoveUser($event)"
                                    (onRemoveUserForever)="removeUserForever($event.user)"
                                    (onRetrieve)="onRetrieveUser({user: $event.user, complete: null})"
                                    (uploadUserImage)="uploadUserImage($event.user, $event.imageFormData)"
                                    (showDriverCheckIn)="showDriverCheckIns($event.user)"
                            >
                            </app-user-card-component>
                        </ng-container>

                        <ng-container *ngIf="isLoading">
                            <app-skeleton-loader *ngFor="let loader of [0, 1, 2, 3, 4, 5, 6, 7, 8]" [isSingleLoader]="true" [type]="0"></app-skeleton-loader>
                        </ng-container>
                        <ng-container *ngIf="!users.length && !isLoading">
                            <app-no-data-available-component></app-no-data-available-component>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #selectHubs>
    <app-multi-select #customersEl
                      placeholder="ADD_SHIPMENTS.CHOOSE_HUB"
                      [options]="hubs"
                      [showLabel]="false"
                      [(ngModel)]="selectedHub"
                      (whenClose)="getHubs($event)"
                      (whenSelect)="onSelectGetIncomeCustomer()"
                      (whenSelectAll)="onSelectGetIncomeCustomer(true)"
                      (whenDeSelectAll)="onSelectGetIncomeCustomer()"
                      (whenDeSelect)="onSelectGetIncomeCustomer()"
                      (whenFilterChange)="getHubs($event, customersEl)">
    </app-multi-select>
</ng-template>
