import {Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {TicketingSystemService} from '../../services/ticketing-system.service';
import {TranslateService} from '@ngx-translate/core';
import {TicketModel} from '../../models/ticket-model';
import {DatePipe} from '@angular/common';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {UsersService} from '../../../users/services/users.service';
import {
    ORDER_BY_LIST,
    TICKET_PRIORITIES_LIST,
    TICKET_REPLAY_STATUS_LIST,
    TICKET_STATUS_LIST,
    TICKET_TYPES_LIST
} from '../../services/constants';
import {CustomDropdownListModel} from '../../../shared/models/custom-dropdown-list.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CustomConfirmationMessageComponent} from '../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {NewUserComponent} from '../../../users/create-new-user/new-user.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {interval} from 'rxjs';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';

@Component({
    selector: 'app-technical-support-tickets',
    templateUrl: 'technical-support-tickets.component.html',
    styleUrls: ['technical-support-tickets.component.scss']
})
export class TechnicalSupportTicketsComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {
    readonly columnsDef = [
        {label: 'TICKETING_SYSTEM.TABLE.CREATED_BY', id: 'createdBy'},
        {label: 'TICKETING_SYSTEM.TABLE.CREATED_DATE', id: 'createdDate'},
        // {label: 'TICKETING_SYSTEM.DUE_DATE', id: 'resolveDue'},
        {label: 'TICKETING_SYSTEM.TABLE.CATEGORY', id: 'category'},
        {label: 'TICKETING_SYSTEM.TABLE.CLIENT', id: 'assignee'},
        // {label: 'TICKETING_SYSTEM.TABLE.TYPE', id: 'type'},
        {label: 'TICKETING_SYSTEM.TABLE.PRIORITY', id: 'priority'},
        {label: 'TICKETING_SYSTEM.TABLE.STATUS', id: 'status'},
        {label: 'TICKETING_SYSTEM.TABLE.REPLAY_STATUS', id: 'replyStatus'}
    ];
    dateFilterForm: FormGroup;
    fromDate: Date;
    toDate: Date;
    dateRangPickerOptions;

    page = 1;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;


    selectedLang: string;
    isLoading = false;

    displayAddTicket = false;

    tickets: TicketModel[] = [];
    fetchedTickets: TicketModel[] = [];
    totalRecordsNo = 0;

    filtersForm: FormGroup;
    filterFormOn = false;

    usersList = [];
    selectedClient: any;
    editClient = false;

    storesList: [];
    selectedStore: any;
    editStore = false;
    getUsersLoader = false;

    categoriesList = [];
    selectedCategory: any;
    editCategory = false;
    getCategoriesLoader = false;

    ticketTypesList: { value: string, label: string }[];
    ticketStatusList: { value: string, label: string }[];
    ticketReplayStatusList: { value: string, label: string }[];
    ticketPrioritiesList: { value: string, label: string }[];

    noneDropDownValue = 'NULL';

    // for update from table cell
    categoriesOptions: CustomDropdownListModel[] = [];
    storesOptions: CustomDropdownListModel[] = [];
    // prioritiesOptions: CustomDropdownListModel[];
    // statusOptions: CustomDropdownListModel[];

    orderByOptions;
    selectedOrderByOption = 'createdDate';

    displayTicketDetails = false;
    triggeredTicketId: number;

    selectedCreatedBy: any = null;
    permissions = '';

    @ViewChild('clientName') clientName;
    @ViewChild('storeName') storeName;
    @ViewChild('categoryName') categoryName;
    @ViewChild('ticketsFiltersMenu') ticketsFiltersMenu;
    @ViewChild('createdByFilter') createdByFilter;
    @ViewChild('assigneeFilter') assigneeFilter;

    selectedTicketStatus = [];
    selectedTicketReplayStatus = [];
    editCreatedByFilter = false;
    editAssigneeFilter = false;
    isShowCustomerFieldWhileAddingTicket;
    tableCustomization = {};
    selectedLanguage = '';
    isAdmin = false;
    newTickets = 0;
    isExternal = true;
    userCategoryId: any;
    selectedAssignee = null;
    private sourceCategoryId: any;
    private categoryId: any;
    private fromCard: boolean;
    @ViewChild('datepicker') datepicker;
    public label = '';
    public newTicketsNumber = 0;

    constructor(@Inject(FormBuilder) private formsBuilder: FormBuilder,
                private dateRangePickerService: DateRangePickerService,
                private ticketingSystemService: TicketingSystemService,
                private translateService: TranslateService,
                private datePipe: DatePipe,
                private usersService: UsersService,
                private userService: UserService,
                private roleService: RolesService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private route: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private router: Router,
                private modalService: NgbModal) {
        super();
        this.selectedLanguage = this.translateService.currentLang;

    }

    ngOnInit() {
        this.userCategoryId = this.userService.userInfo.categoryId;
        this.isShowCustomerFieldWhileAddingTicket = this.userService.userInfo.isShowCustomerFieldWhileAddingTicket;
        this.selectedLang = this.translateService.currentLang;
        this.isAdmin = ['TICKETING_SYSTEM_ADMIN', 'SUPER_ADMIN', 'SUPER_ADMIN_AS_ADMIN', 'ADMIN'].indexOf(this.userService.userInfo.role) > -1;
        this.fetchUserInfo();
        this.getDateRangePickerOptions();
        this.initDatesFilterForm();
        this.initFiltersForm();
        this.route.queryParams.subscribe(
            (params: Params) => {
                this.fromCard =  params.fromCard;
                if (!this.isAdmin && params.fromCard != 'true') {
                    this.fromCard = true;
                    const name = this.userService.userInfo.firstName + ' ' + this.userService.userInfo.lastName;
                    this.selectedAssignee = {
                        id: this.userService.userInfo.id,
                        name: name,
                        label: name,
                        value: this.userService.userInfo.id,
                    };
                }
                if (params.fromDate || params.toDate) {
                    this.onDateSelected({start: params.fromDate, end: params.toDate}, true);
                    setTimeout(() => {
                        if (this.datepicker) {
                            this.datepicker.nativeElement.value = this.transformDate(this.fromDate) +
                                ' - ' + this.transformDate(this.toDate);
                        }
                    }, 300);
                }
                if (params.sourceCategoryId && !isNaN(params.sourceCategoryId)) {
                    this.sourceCategoryId = params.sourceCategoryId;
                }
                if (params.categoryId && !isNaN(params.categoryId)) {
                    this.categoryId = params.categoryId;
                }
                if (params.ticketStatusList) {
                    let ticketStatusList;
                    if (!Array.isArray(params.ticketStatusList)) {
                        ticketStatusList = [params.ticketStatusList];
                    } else {
                        ticketStatusList = params.ticketStatusList;
                    }
                    this.selectedTicketStatus = [];
                    for (const status of ticketStatusList) {
                        if (status === 'NEWTICKETS' || status === 'NEW') {
                            this.selectedTicketStatus.push(this.ticketStatusList[0].value);
                        } else if (status === 'INPROGRESSTICKETS' || status === 'INPROGRESS') {
                            this.selectedTicketStatus.push(this.ticketStatusList[1].value);
                        } else if (status === 'RESOLVEDTICKETS' || status === 'DONE') {
                            this.selectedTicketStatus.push(this.ticketStatusList[2].value);
                        }
                    }
                    this.label = params.ticketStatusList[0];
                }
                if (params.ticketReplayStatusList) {
                    let ticketReplayStatusList;
                    if (!Array.isArray(params.ticketReplayStatusList)) {
                        ticketReplayStatusList = [params.ticketReplayStatusList];
                    } else {
                        ticketReplayStatusList = params.ticketReplayStatusList;
                    }
                    this.selectedTicketReplayStatus = [];
                    for (const status of ticketReplayStatusList) {
                        if (status === 'NEW_REPLAY') {
                            this.selectedTicketReplayStatus.push(this.ticketReplayStatusList[0].value);
                        } else if (status === 'REPLIED') {
                            this.selectedTicketReplayStatus.push(this.ticketReplayStatusList[1].value);
                        } else if (status === 'NO_REPLAY') {
                            this.selectedTicketReplayStatus.push(this.ticketReplayStatusList[2].value);
                        }
                    }
                }
            }
        );
        this.getTickets();
        this.getColumnsConfig();

        this.subscriptions.push(interval(1000 * 60 * 10).subscribe(res => {
            this.lazyLoadTickets(true);
        }));
    }
    private fetchUserInfo() {
        this.userService.getUserInfo().then(
            (user) => {
                this.initPermissions();
            }
        );
    }
    initPermissions() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.roleService.getUserPermissions('MANAGE_USERS', role) || this.roleService.getUserPermissions('TECHNICAL_SUPPORT_TICKETS', role);
    }
    initDropDowns() {
        this.ticketTypesList = this.setTicketTypes();
        this.ticketStatusList = this.setTicketStatus();
        this.ticketReplayStatusList = this.setTicketReplayStatus();
        // this.selectedTicketStatus = [this.ticketStatusList[0].value];
        this.ticketPrioritiesList = this.setTicketPriorities();
        this.orderByOptions = this.setOrderByOptions();
    }

    prepareListWithNoneValue(list: string [], originalList: string []) {
        list.unshift('NONE');
        originalList.forEach((status: string) => {
            list.push(status);
        });
    }

    setOrderByOptions() {
        return ORDER_BY_LIST.map((value) => {
            let label = value.toUpperCase();
            this.translateService.get(value.toUpperCase())
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    setTicketPriorities() {
        const list = [];
        this.prepareListWithNoneValue(list, TICKET_PRIORITIES_LIST);
        return list.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    setTicketStatus() {
        const list = [];
        // this.prepareListWithNoneValue(list, TICKET_STATUS_LIST);
        return TICKET_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }
    setTicketReplayStatus() {
        return TICKET_REPLAY_STATUS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    setTicketTypes() {
        const list = [];
        this.prepareListWithNoneValue(list, TICKET_TYPES_LIST);
        return list.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    createBodyParams() {
        const result: any = {};
        if (this.selectedTicketStatus.length) {
            result['ticketStatusList'] = this.selectedTicketStatus.map(status => status ? status.value ? status.value : status : status);
        }
        if (this.selectedTicketReplayStatus.length) {
            result['ticketReplayStatusList'] = this.selectedTicketReplayStatus.map(status => status ? status.value ? status.value : status : status);
        }
        if (this.categoryId) {
            result['categoryId'] = this.categoryId;
        }
        if (this.sourceCategoryId) {
            result['sourceCategoryId'] = this.sourceCategoryId;
        }
        if (this.fromCard) {
            result['fromCard'] = this.fromCard;
        }
        if (this.fromDate) {
            result['fromDate'] = this.fromDate;
        }
        if (this.toDate) {
            result['toDate'] = this.toDate;
        }
        return result;

    }

    createQuery() {
        let filterData;

        if (this.filtersForm) {
            filterData = this.filtersForm.getRawValue();
        }


        let result = `?pageSize=${this.pageSize}&page=` + (this.page);

        if (this.dateFilterForm) {
            if (this.fromDate) {
                result += '&fromDate=' + this.transformDate(this.fromDate, true);
            }
            if (this.toDate) {
                result += '&toDate=' + this.transformDate(this.toDate, true);
            }
        }

        if (this.isAdmin && this.selectedClient) {
            result += '&assigneeId=' + this.selectedClient.id;
        }
        if (this.selectedStore) {
            result += '&customerId=' + this.selectedStore.id;
        }
        if (this.selectedCategory) {
            result += '&categoryId=' + this.selectedCategory.id;
        }
        if (this.selectedOrderByOption) {
            result += '&sortBy=' + this.selectedOrderByOption;
        }
        if (this.selectedCreatedBy && this.selectedCreatedBy.value) {
            result += '&createdById=' + this.selectedCreatedBy.value;
        }
        if (filterData) {
            if (filterData.priority && filterData.priority !== this.noneDropDownValue) {
                result += '&priority=' + filterData.priority;
            }
            // if (filterData.status && filterData.status !== this.noneDropDownValue) {
            //     result += '&status=' + filterData.status;
            // }
            if (filterData.type && filterData.type !== this.noneDropDownValue) {
                result += '&type=' + filterData.type;
            }
        }
        if (this.selectedAssignee && this.selectedAssignee.value) {
            result += '&assigneeId=' + this.selectedAssignee.value;
        }
        if (this.sourceCategoryId) {
            result += '&sourceCategoryId=' + this.sourceCategoryId;
        }
        if (this.categoryId) {
            result += '&categoryId=' + this.categoryId;
        }
        return result;
    }

    initDatesFilterForm() {
        const date = new Date();
        const date2 = new Date();
        this.fromDate = new Date(date.setFullYear(date.getFullYear() - 3, date.getMonth(), date.getDate()));
        this.toDate = new Date(date2.setDate(date2.getDate() + 1));
        this.dateFilterForm = this.formsBuilder.group({
            searchText: [''],
            fromDate: [this.fromDate],
            toDate: [this.toDate]
        });
    }

    onDateSelected(value, ignoreLoad = false) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.dateFilterForm.controls.fromDate.setValue(this.fromDate);
        this.dateFilterForm.controls.toDate.setValue(this.toDate);
        this.dateFilterForm.controls.fromDate.updateValueAndValidity();
        this.dateFilterForm.controls.toDate.updateValueAndValidity();
        if (!ignoreLoad) {
            this.lazyLoadTickets(true);
        }
    }

    getDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions(3).then(options => {
            this.dateRangPickerOptions = options;
        });
    }

    lazyLoadTickets(isFromFilter = false) {
        if (isFromFilter) {
            this.page = 0;
        }
        if (!this.isLoading) {
            this.page++;
            this.getTickets(isFromFilter);
        }
    }
    getDashboardStats() {
        const urlQuery = this.createQuery();
        this.ticketingSystemService.getDashboardStats(urlQuery).subscribe(
            (stats: any) => {
                this.newTickets = stats.newTickets;
            }, error => {
                console.error(error);
            }
        );
    }
    public getTickets(isFromFilter = false) {
        if (this.isLoading) {
            return;
        }
        if (isFromFilter) {
            this.tickets = [];
            this.page = 1;
        }
        const urlQuery = this.createQuery();
        const body = this.createBodyParams();
        this.navigateTo('technical-support-tickets', body);
        this.fetchedTickets = [];
        this.isLoading = true;
        if (this.page === 1) {
            this.getDashboardStats();
        }
        this.ticketingSystemService.getTickets(urlQuery, body).subscribe(
            (response: { data: TicketModel[], totalRecordsNo: number }) => {
                this.fetchedTickets = response.data;
                this.tickets = [...this.tickets, ...this.fetchedTickets];
                this.newTicketsNumber = this.tickets.filter(ticket => ticket.status === 'NEW').length;
                this.totalRecordsNo = response.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    @HostListener('scroll', ['$event'])
    onScrollTable(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 5
            && (this.tickets.length !== this.totalRecordsNo) && !this.isLoading) {
            this.lazyLoadTickets();
        }
    }

    showAddTicket() {
        this.displayAddTicket = true;
    }

    onHideAddTicket(event) {
        this.displayAddTicket = event;
    }

    onHideTicketDetails(event) {
        this.displayTicketDetails = event;
        this.displayTicketDetails = false;
    }

    showFiltersMenu(event) {

        this.ticketsFiltersMenu.toggle(event);
        this.filterFormOn = true;
        event.stopPropagation();
    }

    onHideFiltersMenu() {
        this.filterFormOn = false;
    }

    getUserName(user) {
        return user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : this.translateService.instant('TICKETING_SYSTEM.TABLE.NOT_ASSIGNED');
    }

    initFiltersForm() {
        this.initDropDowns();
        this.filtersForm = this.formsBuilder.group({
            clientName: [''],
            storeName: [''],
            categoryName: [''],
            type: [''],
            // status: [''],
            priority: ['']
        });
    }

    resetFilters() {
        this.filtersForm.reset();
        // this.dateFilterForm.reset();
        this.selectedStore = null;
        this.selectedClient = null;
        this.selectedCategory = null;
        this.lazyLoadTickets(true);
    }

    updateFormValue(controlName, value) {
        this.filtersForm.controls[controlName].setValue(value);
        this.filtersForm.controls[controlName].updateValueAndValidity();
    }

    public editClientName() {
        if (!this.selectedClient) {
            this.clientName.focusInput();
            this.clientName.search(null, '');
            return;
        }
        this.editClient = true;
        this.clientName.focusInput();
        const search_term = this.filtersForm.get('clientName').value;
        this.clientName.search(null, search_term);
    }

    public editStoreName() {
        if (!this.selectedStore) {
            this.storeName.focusInput();
            this.storeName.search(null, '');
            return;
        }
        this.editStore = true;
        this.storeName.focusInput();
        const search_term = this.filtersForm.get('storeName').value;
        this.storeName.search(null, search_term);
    }

    public editCategoryName() {
        if (!this.selectedCategory) {
            this.categoryName.focusInput();
            this.categoryName.search(null, '');
            return;
        }
        this.editCategory = true;
        this.categoryName.focusInput();
        const search_term = this.filtersForm.get('categoryName').value;
        this.categoryName.search(null, search_term);
    }

    chooseClient($event) {
        const user = $event;
        const userName = user.firstName + ' ' + user.lastName;
        this.updateFormValue('clientName', userName);
        this.selectedClient = JSON.parse(JSON.stringify(user));
    }

    chooseCategory($event) {
        const category = $event;
        this.updateFormValue('categoryName', category.name);
        this.selectedCategory = JSON.parse(JSON.stringify(category));
    }

    getClients($event) {
        const query = '?page=1&pageSize=100&search=' + $event.query;
        this.usersService.getUsers(query).subscribe(
            (response: any) => {
                this.usersList = response.users;
            }, (error) => {
                console.error(error);
            }
        );
    }

    getCategories($event) {
        const query = '?search=' + $event.query + '&isDropdown=true';
        this.ticketingSystemService.getAllCategories(query).subscribe(
            (response: any) => {
                this.categoriesList = response;
            }, (error) => {
                console.error(error);
            }
        );
    }

    getStores($event) {
        const query = '?page=1&pageSize=20&search=' + $event.query;
        this.usersService.getCustomers(query).subscribe(
            (response: any) => {
                this.storesList = response.customers;
            }, (error) => {
                console.error(error);
            }
        );
    }

    public chooseStore($event) {
        const store = $event;
        this.updateFormValue('storeName', store.fullName);
        this.selectedStore = JSON.parse(JSON.stringify(store));
        this.selectedStore['address'].label = this.selectedStore['address'].village + ' - ' + this.selectedStore['address'].city +
            ' - ' + this.selectedStore['address'].region;
        this.editStore = false;
    }

    transformDate(date, filterDateValue = false, fullDate = false) {
        let format = 'dd/MM/yyyy';

        if (filterDateValue) {
            format = 'yyyy-MM-dd';
        }
        if (fullDate) {
            format = format + ' HH:mm';
        }
        return this.datePipe.transform(date, format);
    }

    stopProp(event) {
        event.stopPropagation();
    }

    confirmAction(translateKey) {
        const message = this.translateService.instant(
            'TICKETING_SYSTEM.ALARMS.UPDATE_FROM_TABLE_CONFIRMATION'
        ).replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.TABLE.STATUS'));
    }

    updateAttributeRequest(id, event, Attribute) {
        const message  = this.translateService.instant(
            'TICKETING_SYSTEM.ALARMS.UPDATE_FROM_TABLE_SUCCESSFULLY'
        ).replace('{ATTRIBUTE}', this.translateService.instant('TICKETING_SYSTEM.TABLE.' + Attribute));
        let request, body;

        switch (Attribute) {
            case 'STATUS':
                body = {status: event.value};
                request = this.ticketingSystemService.updateTicketStatus(id, body);
                break;
            case 'CATEGORY':
                body = {categoryId: event.value};
                request = this.ticketingSystemService.updateTicketCategory(id, body);
                break;
            case 'CLIENT':
                body =  {assigneeId: event.value};
                request = this.ticketingSystemService.updateTicketClient(id, body);
                break;
        }

        request.subscribe(
            (res: any) => {
                this.messageService.add({severity: 'success', detail: message});
                this.lazyLoadTickets(true);
            }, error => {
                console.error(error);
            }
        );
    }


    updateFromTable(id, event, Attribute) {
        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', backdropClass: 'custom-confirmation-message-backdrop', size: 'sm'});

        const attributeLabel = this.translateService.instant('TICKETING_SYSTEM.TABLE.' + Attribute);
        const title = this.translateService.instant('TICKETING_SYSTEM.CONFIRMATION.EDIT_ATTRIBUTE').replace('{ATTRIBUTE}', attributeLabel);
        const confirmMessage = this.translateService.instant(
            'TICKETING_SYSTEM.ALARMS.UPDATE_FROM_TABLE_CONFIRMATION'
        ).replace('{ATTRIBUTE}', attributeLabel);
        const confirmButtonLabel = 'ACTIONS.VERIFY';

        modal.componentInstance.title = title;
        modal.componentInstance.confirmMessage = confirmMessage;
        modal.componentInstance.confirmButtonLabel = confirmButtonLabel;
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    this.updateAttributeRequest(id, event, Attribute);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    getUsersOptions($event, categoryId = 0) {
        let query = '?page=1&pageSize=100&role=TICKETING_USER';
        if ($event && $event.query) {
            query += '&search=' + $event.query;
        }
        if (categoryId) {
            query += '&categoryId=' + categoryId;
        } else if (!this.categoryId && this.userCategoryId) {
            query += '&categoryId=' + this.userCategoryId;
        }
        this.getUsersLoader = true;
        this.usersService.getUserDropDown(query).subscribe(
            (response: any) => {
                this.storesOptions = [];
                response.forEach((customer) => {
                    this.storesOptions.push({label: customer.name, value: customer.id});
                });
                this.getUsersLoader = false;
            }, (error) => {
                console.error(error);
                this.getUsersLoader = false;
            }
        );
    }

    getCategoriesOptions($event) {
        let query = '';
        if ($event && $event.query) {
            query = '?search=' + $event.query;
        }
        query += '&isDropdown=true';
        this.getCategoriesLoader = true;
        this.ticketingSystemService.getAllCategories(query).subscribe(
            (response: any) => {
                this.categoriesOptions = [];
                response.forEach((category) => {
                    this.categoriesOptions.push({label: category.name, value: category.id});
                });
                this.getCategoriesLoader = false;
            }, (error) => {
                console.error(error);
                this.getCategoriesLoader = false;
            }
        );
    }

    getSelectedCategory(id) {
        const selectedCategory = this.categoriesOptions.filter((categories: CustomDropdownListModel) => categories.value === id)[0];
        if (selectedCategory) {
            return {label: selectedCategory.label, value: selectedCategory.value};
        }
    }

    getPriorityColor(priority) {
       return this.ticketingSystemService.getPriorityColor(priority);
    }

    showTicketDetails(ticketId) {
        this.triggeredTicketId = ticketId;
        this.displayTicketDetails = true;
    }

    closeTicketDetails(event) {
        this.displayTicketDetails = event;
    }

    chooseCreatedByFilter(event) {
        this.selectedCreatedBy = JSON.parse(JSON.stringify(event));
        this.getTickets(true);
    }
    clearCreatedByFilter() {
        this.selectedCreatedBy = null;
        this.getTickets(true);
    }

     openCreatedByFilterList() {
        if (!this.selectedCreatedBy) {
            this.createdByFilter.focusInput();
            this.createdByFilter.search(null, '');
            return;
        }
        this.editCreatedByFilter = true;
    }
     openAssigneeFilterList() {
        // if (!this.selectedAssignee) {
            this.assigneeFilter.focusInput();
            this.assigneeFilter.search(null, '');
            return;
        // }
        // this.editAssigneeFilter = true;
    }

    canAddUser(): boolean {
        if (this.userService.userInfo.role === 'OPERATION_MANAGER') {
            return this.checkIsAllowOperationManagerAddCustomer()
                && this.permissions.includes('{ADD_USER');
        } else if (this.userService.userInfo.role === 'TICKETING_USER' && this.userService.userInfo.ticketingRole === 'CATEGORY_ADMIN') {
            return true;
        } else {
            return this.permissions.includes('{ADD_USER');
        }
    }
    checkIsAllowOperationManagerAddCustomer() {
        return this.userService.userInfo.isAllowOperationManagerAddCustomer;
    }

    createUser() {
        const modal = this.modalService.open(
            NewUserComponent,
            <any>{
                backdrop: 'static',
                windowClass: 'create-new-user',
                size: 'md'
            }
        );

        modal.componentInstance.type = 'user';
        modal.componentInstance.userRole = 'TICKETING_USER';
        modal.componentInstance.isAdmin = this.isAdmin;
        modal.componentInstance.doneActionStyleClass = 'ticketing-gradient-action';
        modal.result.then(
            (status) => {
                if (status.isSuccess) {
                    this.translateService.get(
                        'ALERTS.USER_ADDED_SUCCESSFULLY'
                    )
                        .subscribe(
                            (data) => {
                                this.messageService.add({severity: 'success', detail: data});
                                // this.reset();
                                // this.fetchUsers(true);
                            }
                        );
                }
            }
        ).catch((error) => {
        });
    }
    public navigateTo(navigateTo, queryParams = {}) {
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }

    customizeTable(column: string, $event) {
        this.ticketingSystemService.updateTicketTableColumns(column, $event).subscribe((res: any) => {
        });

    }

    private getColumnsConfig() {
        this.ticketingSystemService.getTicketTableColumns().subscribe((res: any) => {
            if (res && res.columns) {
                res.columns.forEach(column => {
                    this.tableCustomization[column] = true;
                });

            }
        });
    }

    chooseAssigneeFilter($event: any) {
        this.selectedAssignee = JSON.parse(JSON.stringify($event));
        this.getTickets(true);
    }

    clearAssigneeFilter() {
        this.selectedAssignee = null;
        this.getTickets(true);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
