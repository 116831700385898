<p-overlayPanel #tripsFiltersMenu
                class="tickets-filters-menu"
                [styleClass]="'tickets-filters-menu-parent'"
                [dismissable]="true"
                (onHide)="onHideFiltersMenu()"
                [showCloseIcon]="false">
    <form [formGroup]="filtersForm">
        <div class="tickets-filters-menu-container">
            <div class="filters-header">
                <div class="filters-menu-title">
                    <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                </div>
            </div>
            <div class="filters-inputs-group">
                <div class="data-item">
                    <div class="label lable-for-filter">
                        <span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_STATION' | translate}}</span></div>
                    <div class="value">
                        <app-input-field-component
                                [form]="filtersForm"
                                field="label"
                                [dataKey]="'value'"
                                [forceSelection]="true"
                                [displayDropDown]="true"
                                [searchSuggestions]="regionOptions"
                                (onSearch)="getRegions($event.query)"
                                controlName="fromCity"
                                [options]="regionOptions"
                                [isRequired]="true"
                                [appendTo]="'body'"
                                controlType="autoComplete">
                        </app-input-field-component >
                    </div>
                </div>

                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_STATION' | translate}}</span>
                    </div>
                    <div class="value">
                        <app-input-field-component
                                [form]="filtersForm"
                                field="label"
                                [dataKey]="'value'"
                                [forceSelection]="true"
                                [displayDropDown]="true"
                                [searchSuggestions]="regionOptions"
                                (onSearch)="getRegions($event.query)"
                                controlName="toCity"
                                [options]="regionOptions"
                                [isRequired]="true"
                                [appendTo]="'body'"
                                controlType="autoComplete">
                        </app-input-field-component>
                    </div>
                </div>


                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.DAYS' | translate}}</span>
                    </div>
                    <div class="value day-filter">
                        <p-multiSelect [options]="daysList"
                                       formControlName="days"
                                       #selectedDaysDropDown
                                       maxSelectedLabels="0"
                                       selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                       [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                       defaultLabel=""
                                       optionLabel="label"
                                       optionValue="value">
                            <ng-template let-day pTemplate="item">
                                {{ day.label | translate }}
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>

                <div class="data-item">
                    <div class="label lable-for-filter">
                        <span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_TIME' | translate}}</span></div>
                    <div class="value day-filter" [formGroup]="filtersForm">
                        <p-calendar formControlName="fromTime" showTime="true" [timeOnly]="true"
                                    [hourFormat]="24"></p-calendar>
                    </div>
                </div>
                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_TIME' | translate}}</span>
                    </div>
                    <div class="value day-filter" [formGroup]="filtersForm">
                        <p-calendar formControlName="toTime" showTime="true" [timeOnly]="true"
                                    [hourFormat]="24"></p-calendar>
                    </div>
                </div>
                <div class="data-item">
                    <div class="label lable-for-filter "><span> {{'TRIPS_BETWEEN_CITIES.FORM.PRICE' | translate}}</span>
                    </div>
                    <div class="value day-filter w-100" [formGroup]="filtersForm">
                        <input class="cost-input p-inputtext w-100" [formControlName]="'cost'" type="number"/>
                    </div>
                </div>
            </div>

            <div class="filters-menu-actions">
                <div class="reset-action filter-menu-action" (click)="resetFilters($event)">
                    <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                </div>
                <div class="apply-filters-action filter-menu-action" (click)="applyFilters($event)">
                    <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                </div>
            </div>

        </div>

    </form>
</p-overlayPanel>

<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="trips-between-cities h-100">
    <div class="header mb-2 d-flex justify-content-between align-items-center">
        <div class="page-header-title">
            <div class="title">{{'ADMINISTRATION.TRIPS_BETWEEN_CITIES' | translate}}</div>
            <!--            <div class="total-number-badge">15</div>-->
        </div>
        <div class="actions-filters-container d-flex">
            <div class="filters">
                <div class="toggle-filters-btn">
                    <div class="icon" (click)="showFiltersMenu($event)">
                        <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                        <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                    </div>
                </div>
            </div>
            <div class="actions d-flex">
                <div class="add-trip action d-flex" (click)="showAddTripForm()">
                    <div class="icon">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="label">
                        {{'TRIPS_BETWEEN_CITIES.ADD_NEW_TRIP' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="trips">
        <div *ngIf="isLoading" class="trips-loader">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>


        <div class="trips-list-container d-flex flex-column w-100 justify-content-center align-items-center">
            <div class="list-header">
                <div *ngIf="currentLang === 'ar'" class="title d-flex">{{'TRIPS_BETWEEN_CITIES.TABLE' | translate}}
                    <span>{{'TRIPS_BETWEEN_CITIES.TRIPS' | translate}} </span></div>
                <div *ngIf="currentLang === 'en'" class="title d-flex">
                    <span>{{'TRIPS_BETWEEN_CITIES.TRIPS' | translate}} </span> {{'TRIPS_BETWEEN_CITIES.TABLE' | translate}}
                </div>
                <!--                <div class="title-desc">-->
                <!--                    {{'TRIPS_BETWEEN_CITIES.TITLE_DESC' | translate}}-->
                <!--                </div>-->
            </div>

            <div class="trip-cards-container w-100">
                <div *ngFor="let trip of trips" class="trip-card">
                    <div class="main-data d-flex justify-content-between mb-5">
                        <div class="from-city destination d-flex justify-content-around">
                        <div class="trip-number d-flex">
                            <div class="title">
                                {{'TRIPS_BETWEEN_CITIES.FORM.TRIP_NUMBER' | translate}}
                            </div>
                            <div class="label badge">{{trip.tripNumber}}</div>
                        </div>
                            <div class="capacity d-flex">
                                <div class="title">
                                    {{'TRIPS_BETWEEN_CITIES.FORM.CAPACITY' | translate}}
                                </div>
                                <div class="label badge">{{trip.mainTripCapacity}}</div>
                            </div>
                        </div>
                        <div class="show-btn main-trip-btn flex-2d-center cursor-pointer"
                             (click)="trip.showDetails = !trip.showDetails">
                            <div class="label">{{'TRIPS_BETWEEN_CITIES.FORM.DETAILS' | translate}}</div>
                            <div class="icon">
                                <i *ngIf="!trip.showDetails" class="fas fa-angle-double-down"></i>
                                <i *ngIf="trip.showDetails" class="fas fa-angle-double-up"></i>
                            </div>
                        </div>

                        <div class="show-btn main-trip-btn edit-btn flex-2d-center cursor-pointer"
                             (click)="showAddTripForm(trip)">
                            <div class="icon">
                                <i class="fas fa-edit"></i>
                            </div>
                        </div>

                        <div class="main-trip-btn delete-btn flex-2d-center cursor-pointer"
                             (click)="deleteMainTrip(trip)">
                            <div class="icon">
                                <i class="fas fa-trash-alt"></i>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="trip.showDetails">
                        <div class="sub-trips-cards-list d-flex flex-column justify-content-center align-items-center">
                            <div *ngFor="let subTrip of trip.citiiesTripsTimeSchedules"
                                 class="sub-trip-card justify-content-between d-flex w-100">
                                <div class="trip-number-parcel-container d-flex">
                                    <div class="trip-number info-item">
                                        <div class="value">{{subTrip.capacity}}</div>
                                        <div class="label">{{'TRIPS_BETWEEN_CITIES.FORM.CAPACITY' | translate}}</div>
                                    </div>
                                    <div *ngIf="subTrip.parcelTypeName" class="parcel-type info-item">
                                        <div class="value">{{subTrip.parcelTypeName}}</div>
                                        <div class="label">{{'TRIPS_BETWEEN_CITIES.FORM.SHIPMENT_TYPE' | translate}}</div>
                                    </div>
                                </div>
                                <div class="details w-100">
                                    <div class="info-details justify-content-between d-flex">
                                        <div class="info-item from-time">
                                            <div class="value"> {{subTrip.fromTime | date: 'HH:mm'}}</div>
                                            <!--                                          <div class="label">HAF</div>-->
                                            <div class="from-city flex-2d-center">
                                                {{subTrip.fromHubName}}
                                            </div>
                                        </div>
                                        <div class="info-item info d-flex justify-content-center">
                                            <div class="label">مباشر</div>
                                        </div>
                                        <div class="info-item to-time">
                                            <div class="value">{{subTrip.toTime | date: 'HH:mm'}}</div>
                                            <div class="to-city flex-2d-center">
                                                {{subTrip.toHubName}}
                                            </div>
                                            <!--                                          <div class="label">ABQ</div>-->
                                        </div>
                                    </div>
                                    <div class="total-time d-flex justify-content-center w-100">
                                        <div class="icon">
                                            <i class="fas fa-clock"></i>
                                        </div>
                                        <div class="label">{{(getDiffTimes(subTrip.toTime, subTrip.fromTime))}}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>


<app-add-trip-form #addTripForm
                   (closeWidget)="onCloseAddTripForm($event)">
</app-add-trip-form>
