import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ServiceTypeModel} from '../../models/service-type.model';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {NewServiceTypeComponent} from './new-service-type/new-service-type.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ServiceTypesService} from '../../services/service-types.service';
import * as moment from 'moment';

@Component({
    selector: 'app-manage-service-types',
    templateUrl: './manage-service-types.component.html',
    styleUrls: ['./manage-service-types.component.scss']
})
export class ManageServiceTypesComponent implements OnInit {
    currentLang: string;
    totalRecords = 0;
    searchContent: string;
    isLoading = false;
    serviceTypes: ServiceTypeModel[] = [];
    fetchedServiceTypes: ServiceTypeModel[] = [];

    private currentPage = 1;
    private pageSize = 20;
    triggeredServiceType: ServiceTypeModel;

    constructor(private translateService: TranslateService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private serviceTypesService: ServiceTypesService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.getServiceTypes(true);
    }

    getServiceTypes(forceFetch = false) {
        if (this.serviceTypes.length === this.totalRecords && this.currentPage > 1 && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.currentPage = 1;
            this.serviceTypes = [];
        }
        const query = this.createQuery();
        this.isLoading = true;
        this.serviceTypesService.getServiceTypes(query).subscribe((res: any[]) => {
            this.fetchedServiceTypes = res['data'].map(serviceType => {
                const value = {
                    ...serviceType,
                    vehicleTypesIds: serviceType.vehicleTypes ? serviceType.vehicleTypes.map(vehicleType => vehicleType.id) : []
                };
                if (serviceType.lastOrderTime) {
                    value.lastOrderTimeDate = moment(serviceType.lastOrderTime, 'HH:mm').toDate();
                }
                return value;
            });
            this.totalRecords = res['totalRecordsNo'];
            if (forceFetch) {
                this.serviceTypes = this.fetchedServiceTypes;
            } else {
                this.serviceTypes = [...this.serviceTypes, ...this.fetchedServiceTypes];
            }
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

    loadLazyServiceTypes() {
        this.currentPage++;
        this.getServiceTypes();
    }

    onSearch() {
        this.getServiceTypes(true);
    }

    onNewServiceType() {
        const modal = this.modalService.open(NewServiceTypeComponent, {
            backdrop: 'static',
            size: 'lg'
        });

        modal.result.then(result => {
            if (result && result.isSuccess) {
                this.translateService.get('SERVICE_TYPE.ALERTS.SERVICE_TYPE_ADDED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                this.getServiceTypes(true);
            }
        });
    }

    onScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadLazyServiceTypes();
        }
    }

    onShowServiceTypeDetails(serviceType) {

    }

    private createQuery() {
        let query = '?pageSize=' + this.pageSize + '&page=' + this.currentPage;
        if (this.searchContent) {
            query += '&search=' + this.searchContent;
        }
        return query;
    }

    setTriggeredServiceType(serviceType) {
        this.triggeredServiceType = serviceType;
    }

    editServiceType(serviceType: ServiceTypeModel, popover: NgbPopover) {
        popover.close();
        const modal = this.modalService.open(NewServiceTypeComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.isEdit = true;
        modal.componentInstance.serviceType = serviceType;

        modal.result.then(result => {
            if (result && result.isSuccess) {
                this.translateService.get('SERVICE_TYPE.ALERTS.SERVICE_TYPE_EDITED_SUCCESSFULLY').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
                this.getServiceTypes(true);
            }
        });
    }

    deleteServiceType(serviceType: ServiceTypeModel, popover: NgbPopover) {
        popover.close();
        this.translateService.get(['SERVICE_TYPE.ALERTS.CONFIRM_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
            this.confirmationService.confirm({
                message: values['SERVICE_TYPE.ALERTS.CONFIRM_DELETE'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.serviceTypesService.deleteServiceType(serviceType.id).subscribe(() => {
                        this.translateService.get('SERVICE_TYPE.ALERTS.SERVICE_TYPE_DELETED_SUCCESSFULLY').subscribe(value => {
                            this.messageService.add({
                                severity: 'success',
                                detail: value
                            });
                        });
                        this.getServiceTypes(true);
                    });
                }
            });
        });
    }

    updateServiceType(serviceType, attr, showConfirmation = false) {
        let successMessage =  serviceType.showCustomerPkgStatsChart
            ? 'SERVICE_TYPE.ALERTS.SHOW_SERVICE_TYPE_CHART_ENABLED_SUCCESSFULLY'
            : 'SERVICE_TYPE.ALERTS.SHOW_SERVICE_TYPE_CHART_DISABLED_SUCCESSFULLY';
        if (attr === 'color') {
            successMessage = 'SERVICE_TYPE.ALERTS.COLOR_UPDATED_SUCCESSFULLY';
        }
        const confirmationMessage = 'SERVICE_TYPE.ALERTS.UPDATE_COLOR_CONFIRMATION';
        if (showConfirmation) {
            this.confirmationService.confirm({
                message: this.translateService.instant(confirmationMessage),
                accept: () => {
                    this.actualUpdateServiceType(serviceType, successMessage);
                },
                acceptLabel: this.translateService.instant('GENERAL.YES'),
                rejectLabel: this.translateService.instant('GENERAL.NO')
            });

        } else {
            this.actualUpdateServiceType(serviceType, successMessage);
        }

    }
    actualUpdateServiceType(serviceType, successMessage) {
        this.serviceTypesService.editServiceType(serviceType.id, serviceType).subscribe(() => {
            this.isLoading = false;
            this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant(successMessage)
            });
        }, () => {
            this.isLoading = false;
        });
    }
    setServiceTypeColor(serviceType) {
    }
}
