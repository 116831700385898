<div class="h-100 single-report-page">
  <app-admin-report [columns]="financialReportInfo"
                    [apiUrl]="financialReportAPI"
                    [isPdfRemove]="false"
                    [isExportToExcel]="true"
                    [hasFilters]= true
                    [filterDate] = true
                    [filterCustomer] = true
                    [showDateTypeFilter]= "true"
                    [dateTypeFilterList]="financialReportDateType"
                    [isRemoveSearch] = true
                    [TYPE] = "'financial_report'"
                    reportTitle="FINANCIAL_REPORT.FINANCIAL_REPORT"></app-admin-report>
</div>
