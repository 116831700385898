<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="reset-password-container">
        <div class="row" style="justify-content: space-between;">
            <div class="title">
                <span>{{ (isPasswordConfirmation ? 'AUTH.CONFIRM_PASSWORD': 'AUTH.CHANGE_PASSWORD') | translate }}</span>
            </div>
            <div class="close-icon" (click)="closeModal()">
                <img src="../../assets/icons/close-x.svg" alt="" />
            </div>
        </div>
        <div></div>
        <div class="row icon-name">
            <div class="icon">
                <img [src]="imageUrl?imageUrl:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTBZMuk4s3xE4oXZNZ6TNCbFeEhQY4Xmh13j8FWN2eBzGM41yy'" />
            </div>
            <div style="width: 14px;"></div>
            <div class="name"><span>{{userName? userName : ''}}</span></div>
        </div>
        <div class="flex-1 input-fields">
            <div class="h-100 d-flex flex-column">
                <ng-container *ngIf="!isPasswordConfirmation">
                    <form [formGroup]="form" class="d-flex flex-column">
                        <app-input [label]="'AUTH.NEW_PASSWORD'"
                                   formControlName="password"
                                   [formControlObj]="form.get('password')"></app-input>

                        <app-input [label]="'AUTH.CONFIRM_PASSWORD'"
                                   formControlName="confirmPassword"
                                   [formControlObj]="form.get('confirmPassword')"></app-input>
                    </form>
                </ng-container>
                <ng-container *ngIf="isPasswordConfirmation">
                    <form [formGroup]="confirmPasswordForm" class="d-flex flex-column">
                        <div class="input">
                            <div class="input-label">{{ 'AUTH.CONFIRM_PASSWORD' | translate }}</div>
                            <app-input-field-component class="col-12" [isRequired]="true" controlType="text" controlName="password" errorMessage="ERRORS.CONFIRM_PASSWORD" [form]="confirmPasswordForm"> </app-input-field-component>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
        <div class="row actions">
            <div class="cancel action-btn flex-2d-center" (click)="closeModal()">
                <span>{{ 'ACTIONS.CANCEL' | translate }}</span>
            </div>
            <div *ngIf="isLoading" class="loading">
                <span><i class="fas fa-spinner fa-pulse"></i></span>
            </div>
            <div *ngIf="!isLoading" class="done action-btn flex-2d-center" (click)="submit()" [ngClass]="{'disabled-btn disabled': disableSubmit()}">
                <span>{{ 'ACTIONS.DONE' | translate }}</span>
            </div>
        </div>
    </div>
</div>
