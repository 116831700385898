<div class="main-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="card-container" *ngFor="let item of list">
        <table>
            <ng-container *ngFor="let attr of getObjectKeys(item)">
                <tr *ngIf="keysObj[attr]">
                    <td>
                        <div class="icon"><img src="{{keysObj[attr].icon | translate}}"/></div>
                    </td>
                    <td class="label">{{keysObj[attr].label | translate }}</td>
                    <td>
                        <div class="value">{{handleValue(attr, item[attr])}}</div>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
