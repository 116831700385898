<p-toast [baseZIndex]="99999999999"></p-toast>

<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
    <div class="header d-inline-flex justify-content-between">
        <ng-container>
            <div class="title">{{topLabel | translate}}</div>
        </ng-container>


        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="asn-fees-container p-2">
        <ng-container
                *ngTemplateOutlet="body">
        </ng-container>
    </div>
    <div class="row bottom-actions d-flex" *ngIf="!hideBottom">
        <div class="cancel-btn cursor-pointer" (click)="cancelCLicked()">
            <span>{{cancelLabel | translate}}</span>
        </div>
        <div class="done-btn add-button d-flex cursor-pointer" style="" (click)="doneCLicked()" [ngClass]="{'disabled-btn disabled': disableDoneOb | async}">
            <div class="label done-btn-label">{{doneLabel | translate}}</div>
        </div>
    </div>
</div>
