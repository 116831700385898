import {Component, OnInit} from '@angular/core';
import {TicketingSystemService} from '../../services/ticketing-system.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CategoryFormComponent} from './category-form/category-form.component';
import {CategoryModel} from '../../models/category-model';
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
    selector: 'app-categories-management',
    templateUrl: 'categories-management.component.html',
    styleUrls: ['categories-management.component.scss']
})
export class CategoriesManagementComponent implements OnInit {
    selectedLang;
    categories: CategoryModel[] = [];
    isLoading = false;
    searchContent = '';
    activeSearch = false;

    constructor(private ticketingSystemService: TicketingSystemService,
                private translateService: TranslateService,
                private modalService: NgbModal,
                private messageService: MessageService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.selectedLang = this.translateService.currentLang;
        this.getCategories();
    }

    getCategories() {
        this.isLoading = true;
        let query = '';
        if (this.searchContent.length) {
             query = '?search=' + this.searchContent;
        }
        this.ticketingSystemService.getAllCategories(query).subscribe(
            (categories: CategoryModel[]) => {
                this.categories = categories;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    openCategoryForm(category = null) {
        const modal = this.modalService.open(CategoryFormComponent, {
            backdrop: 'static',
            windowClass: 'ticket-category-form',
        });
        if (category) {
            modal.componentInstance.category = category;
            modal.componentInstance.isEditMode = true;
        }
        modal.result.then(
            (result) => {
                if (result && result.isSuccess) {
                    this.translateService.get(result.message).subscribe(
                        (message) => {
                            this.messageService.add({severity: 'success', detail: message});
                            this.getCategories();
                        }
                    );
                }
            }
        );
    }

    getDefaultUserName(defaultUser) {
        return defaultUser.firstName + ' ' + defaultUser.lastName;
    }

    deleteCategory(categoryId) {
        this.ticketingSystemService.deleteCategory(categoryId).subscribe(
            (res: any) => {
                this.translateService.get('TICKETING_SYSTEM.CATEGORIES.ALARMS.CATEGORY_DELETED_SUCCESSFULLY').subscribe(
                    (message) => {
                        this.messageService.add({severity: 'success', detail: message});
                        this.getCategories();
                    }
                );
            }, error => {
                console.error(error);
            }
        );
    }

    confirmDeleteCategory(categoryId) {
        this.translateService.get(
            ['GENERAL.YES',
                'GENERAL.NO',
                'TICKETING_SYSTEM.CATEGORIES.ALARMS.DELETE_CATEGORY_CONFIRMATION'
            ]
        ).subscribe(
            (messages) => {
                this.confirmationService.confirm({
                    message: messages['TICKETING_SYSTEM.CATEGORIES.ALARMS.DELETE_CATEGORY_CONFIRMATION'],
                    accept: () => {
                        this.deleteCategory(categoryId);
                    },
                    acceptLabel: messages['GENERAL.YES'],
                    rejectLabel: messages['GENERAL.NO']
                });
            }
        );
    }
}
