<div class="close-icon" (click)="closeModal()"><img src="../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}" appBarcodeScanner (barcodeRead)="readBarcode($event)">
    <div class="row d-inline-flex main-container">
        <div class="side-container">
            <div class="side-sub-container">
                <div class="title-container">
                    <div class="label">
                        {{ (isCreateContainer ? 'LCL.CREATE_CONTAINER_TITLE' : 'LCL.RECEIVE_PACKING_LIST') | translate }}
                    </div>
                </div>

                <ng-container *ngIf="isCreateContainer">
                    <div class="hub-select-container source-hub">
                        <div class="hub-label flex-2d-center">{{'LCL.SOURCE_HUB' | translate | hubTitle}}</div>
                        <ng-select [items]="hubs" appDebounceSelectQuery (inputDebounced)="searchHub($event)"
                                   [loading]="isLoadingHubs" [(ngModel)]="selectedHub"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}..."
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}!"
                                   placeholder="{{ 'LCL.ALERTS.CHOOSE_SOURCE_HUB' | translate | hubTitle}}"
                                   bindLabel="name">

                        </ng-select>
                    </div>

                    <div class="hub-select-container source-hub">
                        <div class="hub-label flex-2d-center">{{'LCL.DESTINATION_HUB' | translate}}</div>
                        <ng-select [items]="hubs" appDebounceSelectQuery (inputDebounced)="getDestinationHubs($event)"
                                   [loading]="isLoadingHubs" [(ngModel)]="selectedDestinationHub"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}..."
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}!"
                                   placeholder="{{ 'LCL.ALERTS.CHOOSE_DESTINATION_HUB' | translate | hubTitle}}"
                                   bindLabel="name">

                        </ng-select>
                    </div>

                    <div class="w-100 hub-select-container source-hub">
                        <div class="hub-label flex-2d-center">{{'LCL.CONTAINER_SIZE' | translate | hubTitle}}</div>
                        <ng-select [items]="containerSizes" [(ngModel)]="containerSize"
                                   placeholder="{{ 'LCL.CHOOSE_CONTAINER_SIZE' | translate | hubTitle}}"
                                   bindValue="value"
                                   bindLabel="name">
                        </ng-select>
                    </div>

                    <div class="barcode-search">
                        <img src="../../../assets/icons/container.png" alt="">
                        <input type="text" [(ngModel)]="containerNumber" trimInput
                               [disabled]="isLoading"
                               placeholder="{{'LCL.ENTER_CONTAINER_NUMBER' | translate}}">
                    </div>
                </ng-container>
                <div class="barcode-search">
                    <img src="../../../assets/icons/barcode-scanner.svg" alt="">
                    <input type="text" [(ngModel)]="inputBarcode" trimInput
                           [disabled]="isLoading" (keydown.enter)="readBarcode(inputBarcode)"
                           placeholder="{{'READ_BY_BARCODE.ENTER_NUMBERS_OF_BARCODES' | translate}}">
                    <div class="barcode-action" *ngIf="inputBarcode" [ngClass]="{'disabled-btn disabled': isLoading}"
                         (click)="readBarcode(inputBarcode)">{{ 'ACTIONS.ADD' | translate }}</div>
                </div>

                <div *ngIf="isCreateContainer" class="barcodes-list-container">
                    <div class="list-header">
                        {{ 'LCL.BARCODES_LIST' | translate }}
                        <span>{{ (this.filteredPackages ? filteredPackages.length : 0) }}</span>
                    </div>
                    <div class="search-container">
                        <input type="text" class="search-field" (input)="filterPackages()" [(ngModel)]="barcodeSearch" trimInput
                               placeholder="{{'GENERAL.SEARCH' | translate}}...">
                    </div>
                    <div class="list-container">
                        <div class="barcode-list-item"
                             [ngClass]="{'list-item-selected': selectedPackage && pkg.id === selectedPackage.id,
                            'incomplete-item': pkg.packageItems && pkg.packageItems.length !== packages[pkg.barcode].scannedItems,
                            'disabled-btn disabled': isLoading}"
                             *ngFor="let pkg of filteredPackages; trackBy: trackPackageById"
                             (click)="selectPackage(pkg)">
                            <div>
                                <i class="fas fa-exclamation" *ngIf="pkg.packageItems && pkg.packageItems.length !== packages[pkg.barcode].scannedItems"></i>
                                <i class="fas fa-check" *ngIf="pkg.packageItems && pkg.packageItems.length === packages[pkg.barcode].scannedItems"></i>
                                {{ pkg.barcode }}
                            </div>
                            <div class="list-item-actions">
                                <span class="items-badge">{{ pkg.packageItems ? pkg.packageItems.length : 0 }}</span>
                                <i class="fas fa-trash" (click)="deletePackage(pkg)"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="package-details-container">
                    <div class="list-header">
                        {{ 'LCL.PACKAGE_DETAILS' | translate | packageTitle }}
                    </div>
                    <div class="package-details-subcontainer" *ngIf="selectedPackage">
                        <table>
                            <tr>
                                <td>
                                    {{ 'SHIPMENT.TABLE.NUMBER' | translate }}: <b>{{ selectedPackage.barcode }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'SHIPMENT.TABLE.CUSTOMER' | translate }}: <b>{{ selectedPackage.receiverName }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'LCL.TOTAL_ORDER_PRICE' | translate }}:
                                    <b>{{ selectedPackage.cod | currencyValue : selectedPackage.currency }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'ADD_SHIPMENT.TYPE_SHIPMENT' | translate | packageTitle}}:
                                    <b>{{ selectedPackage.shipmentType | translate }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'SHIPMENT.TABLE.SERVICE_TYPE' | translate }}:
                                    <b>{{ selectedPackage.serviceType | translate }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'SHIPMENT.EXPECTED_PICKUP_DATE' | translate }}:
                                    <b>{{ selectedPackage.expectedPickupDate | date:'dd/MM/yyyy' }}</b>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div *ngIf="!isCreateContainer" class="hubs-list-container">
                    <div class="list-header">
                        {{ 'LCL.CHOOSE_HUB' | translate | hubTitle }}
                    </div>
                    <div class="search-container">
                        <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchHub($event)"
                               placeholder="{{'GENERAL.SEARCH' | translate}}...">
                    </div>
                    <div class="list-container" (scroll)="onHubsScroll($event)">
                        <div class="list-item"
                             [ngClass]="selectedHub && hub.id === selectedHub.id ? 'list-item-selected' : ''"
                             *ngFor="let hub of hubs; trackBy: trackHubById"
                             (click)="selectHub(hub)">
                            <div class="list-name">
                                {{ hub.name }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLoadingHubs || isLoading" class="list-loader">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-container">
            <p-table [value]="Object.values(items)" [loading]="isLoading || isReading"
                     class="entities-table-component"
                     [responsive]="true" scrollHeight="auto" styleClass="entities-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th>#</th>
                        <th>{{ 'LCL.TABLE.BARCODE' | translate }}</th>
                        <th>{{ 'LCL.TABLE.COMPANY_NAME' | translate }}</th>
                        <th>{{ 'LCL.TABLE.PRODUCT_NAME' | translate }}</th>
                        <th>{{ 'LCL.TABLE.DESCRIPTION' | translate }}</th>
                        <th>{{ 'LCL.TABLE.QUANTITY' | translate }}</th>
                        <th>{{ 'LCL.TABLE.BOX_NO' | translate }}</th>
                        <th>{{ 'LCL.TABLE.BOX_QUANTITY' | translate }}</th>
                        <th>{{ 'LCL.TABLE.LENGTH' | translate }}</th>
                        <th>{{ 'LCL.TABLE.WIDTH' | translate }}</th>
                        <th>{{ 'LCL.TABLE.HEIGHT' | translate }}</th>
                        <th>{{ 'LCL.TABLE.WEIGHT' | translate }}</th>
                        <th>{{ 'LCL.TABLE.CBM' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item  let-index="rowIndex">
                    <tr [ngClass]="{'success-row': item.scanned}">
                        <td>{{ 1 + index }}</td>
                        <td tooltipZIndex="10000" pTooltip="{{ item.barcode }}">
                            {{ item.barcode }}
                        </td>
                        <td>
                            {{ item.companyName }}
                        </td>
                        <td>
                            {{ item.productName }}
                        </td>
                        <td>
                            {{ item.description }}
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.boxNo }}</td>
                        <td>{{ item.boxQuantity }}</td>
                        <td>{{ item.length }}</td>
                        <td>{{ item.width }}</td>
                        <td>{{ item.height }}</td>
                        <td>{{ item.weight }}</td>
                        <td>{{isNaN(item.width * item.length * item.height) ? '-' : (item.width * item.length * item.height / (100 * 100 * 100))}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <div class="empty-content">
                        <app-no-data-available-component
                                [message]="isCreateContainer ? selectedPackage ? 'LCL.ALERTS.NO_ITEMS_FOUND' : 'LCL.ALERTS.SCAN_PACKAGES'
                                    : selectedPackage ? 'LCL.ALERTS.NO_ITEMS_FOUND' : 'LCL.ALERTS.SCAN_PACKAGE'">
                        </app-no-data-available-component>
                    </div>
                </ng-template>
            </p-table>

            <div class="bottom-actions">
                <div class="start-actions">
                    <div class="scanned-items">
                        {{ 'LCL.TOTAL_SCANNED_ITEMS' | translate }}
                        <span class="scanned-items-badge">
                            {{ totalScannedItems }}
                        </span>
                    </div>
                </div>
                <div class="end-actions">
                    <div [ngClass]="{'disabled-btn disabled': disableSubmitButton()}"
                         pTooltip="{{ 'LCL.ALERTS.SCAN_ITEMS' | translate }}"
                         tooltipZIndex="10000"
                         tooltipPosition="top"
                         [tooltipDisabled]="disableSubmitTooltip()"
                         (click)="onSubmit()"
                         class="done-action">
                        <span>{{ 'ACTIONS.SUBMIT' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
