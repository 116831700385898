export const CONTAINERS_CONSTANTS = {
    APIs: {
        GET_CONTAINERS: ':api/admin/containers',
        GET_CONTAINERS_LIST: ':api/admin/containers/dropdown',
        GET_CONTAINER_COUNT: ':api/admin/containers/{containerId}/packages-number',
        REMOVE_CONTAINER: ':api/admin/containers/{containerId}/delete',
        NEW_CONTAINER: ':api/admin/containers/new',
        ASSIGN_PACKAGE_TO_CONTAINER: ':api/admin/packages/assign/containers/{containerId}',
        UPDATE_CONTAINER: ':api/admin/containers/{containerId}/modify',
        GET_ASSIGN_CONTAINER: ':api/admin/containers-with-drivers',
        CONTAINER_PACKAGES: ':api/admin/vehicles/{vehicleId}/onboard-packages',
        PRINT_CONTAINERS: ':api/admin/reports/vechiles-cards/pdf',
        PRINT_CONTAINER_PACKAGES: ':api/admin/vehicles/{vehicleId}/packages-pdf-report',
        GET_VEHICLE_TYPES: ':api/admin/vehicle-types',
        GET_VEHICLE_BRANDS: 'assets/api/cars.json',
        NEW_VEHICLE_TYPE: ':api/admin/vehicle-type',
        EDIT_VEHICLE_TYPE: ':api/admin/vehicle-type/{vehicleTypeId}',
        DELETE_VEHICLE_TYPE: ':api/admin/vehicle-type/{vehicleTypeId}'
    },
    ROUTES: {
        CONTAINER_PACKAGES: 'home/container-packages'
    }
};
