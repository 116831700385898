import {Component, Inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, Validators} from '@angular/forms';
import {FaqModel} from '../../models/faq.model';
import {FaqService} from '../../services/faq.service';

@Component({
  selector: 'app-add-faq-dialog',
  templateUrl: './add-faq-dialog.component.html',
  styleUrls: ['./add-faq-dialog.component.scss']
})
export class AddFaqDialogComponent implements OnInit {

  form: any;
  @Input() faq: FaqModel;
  isLoading: any;
  isEdit = false;
  currentLang;

  constructor(private activeModal: NgbActiveModal,
              private translate: TranslateService,
              private faqService: FaqService,
              @Inject(FormBuilder) private formsBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;

    this.form = this.formsBuilder.group({
      answer: ['', Validators.required],
      arabicQuestion: ['', Validators.required],
      arabicAnswer: ['', Validators.required],
      question: ['', Validators.required]
    });
    if (this.faq) {
      this.isEdit = true;
      this.form.patchValue(this.faq);
    }
  }


  close(result = false) {
    this.activeModal.close({added: result});
  }

  isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
    const formControl = this.form.get(formControlName);
    if (formControl) {
      return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
          : formControl.invalid && (formControl.touched || formControl.dirty);
    }
    return false;
  }

  addUpdate() {
    this.isLoading = true;
    const formData = this.form.value;
    if (this.isEdit) {
      this.faqService.updateFaq(formData, this.faq.id).subscribe(res => {
        this.close(true);
      }, (error => {
        this.isLoading = false;
      }));
    } else {
      this.faqService.createFaq(formData).subscribe(res => {
        this.close(true);
      }, (error) => {
        this.isLoading = false;

      });
    }
  }
}
