<p-toast></p-toast>
<div class="hubs-mass-cod-reports d-flex flex-column h-100">
    <div class="header d-flex justify-content-between flex-wrap">
        <div class="title d-flex">
            {{(type === 'RECEIVED' ? 'ACCOUNTING.RECEIVED_COD' : (type === 'EXPORTED' ? 'ACCOUNTING.EXPORTED_COD_MENUITEM' : 'ACCOUNTING.DELIVERED_COD_MENUITEM')) | translate}}
        <span class="total-records-number-badge">{{totalRecords}}</span>
        </div>
        <div class="filters-and-actions d-flex flex-wrap">
            <div *ngIf="type === 'RECEIVED'" class="actions d-flex">
                <div class="receive-action action d-flex justify-content-center align-items-center cursor-pointer"
                     (click)="receiveMassCodReports()">
                    {{'ACCOUNTING.RECEIVE' | translate}}
                </div>
                <div class="add-to-hub-custody action secondary-action" (click)="addToHub()">
                    {{'ACCOUNTING.ADD_TO_HUB_CUSTODY' | translate | hubTitle}}
                </div>
            </div>
        <div class="filters d-flex align-items-center">
<!--            <div class="date-range-filter filter-item">-->
<!--                        <span (click)="datepicker.click()">-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </span>-->
<!--                <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                       [options]="dateRangPickerOptions" (selected)="onDateSelected($event)"/>-->
<!--                <img class="arrow-down"  src="../../../assets/icons/arrow-down.png">-->
<!--            </div>-->
            <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

            <div class="search-filter d-flex filter-item">
                <img src="../../../assets/icons/search.png" alt="search-Image" />
                <input appInputDebounced
                       placeholder="{{'GENERAL.SEARCH' | translate}}"
                       [(ngModel)]="search"
                       (inputDebounced)="onSearch()" type="text">
            </div>
        </div>
        </div>
    </div>
    <div class="table-container h-100">
        <p-table #customersTable appPTableRtlFix dataKey="id" #table [value]="massCodReports"
                 paginatorDropdownAppendTo="body"
                 styleClass="hubs-mass-cod-reports-table flex-table" (onPage)="setPaginatorQueryParam()"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>

            <ng-template pTemplate="header">
                <!--                <th>-->
                <!--                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"-->
                <!--                                (onChange)="onToggleSelectAll($event)">-->
                <!--                    </p-checkbox>-->
                <!--                </th>-->
                <th>{{'BARCODE.TITLE' | translate}}</th>
                <th>
                    {{'MANAGE_SHIPMENTS.HUB_NAME' | translate}}
                </th>
                <th>{{'ACCOUNTING.RECEIVED_COD_PACKAGES' | translate}}</th>
                <th>{{'ACCOUNTING.RECEIVED_COD' | translate}}</th>
                <th>{{'ADMINISTRATION.COST_SUM' | translate}}</th>
                <th>{{'MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST' | translate}}</th>
                <th>{{(type === 'RECEIVED' ? 'ACCOUNTING.EXPORT' : 'ACTIONS.PRINT') | translate}}</th>
                <th *ngIf="type === 'EXPORTED'">{{'ACCOUNTING.DELIVER'| translate}}</th>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr (click)="receiveMassCodReports(report)">
                    <!--                    <td>-->
                    <!--                        <p-checkbox [(ngModel)]="report.isSelected" binary="true"-->
                    <!--                                    (onChange)="onToggleSelection($event)" (click)="$event.stopPropagation()"-->
                    <!--                        ></p-checkbox>-->
                    <!--                    </td>-->
                    <td (click)="$event.stopPropagation()">
                        <div class="barcode-container d-flex justify-content-center">
                            <div class="value">{{report.barcode}}</div>
                            <div class="copy-icon">
                                <img src="../../assets/icons/track_package/copy.svg" alt=""
                                     (click)="copyMessage($event,null, report.barcode)"
                                     data-toggle="tooltip" data-placement="top" title="Copy"
                                     class="copy_icon"/>
                            </div>
                        </div>
                    </td>
                    <td>{{report.hubName}}</td>
                    <td>{{report.receivedCodPackagesNumber}}</td>
                    <td>{{report.totalReceivedCod}}</td>
                    <td>{{report.costSum}}</td>
                    <td>{{report.totalCodWithoutCost}}</td>
                    <td (click)="$event.stopPropagation()">
                        <div *ngIf="type === 'RECEIVED'" [ngClass]="{'row-action': !report.isExporting}"
                             (click)="exportCod(report)">
                            <i *ngIf="!report.isExporting" class="fas fa-file-export"></i>
                            <i *ngIf="report.isExporting" class="fas fa-spinner fa-pulse"></i>
                        </div>
                        <div *ngIf="type !== 'RECEIVED'" [ngClass]="{'row-action': !report.isPrinting}"
                             (click)="printCod(report)">
                            <i *ngIf="!report.isPrinting" class="fas fa-print"></i>
                            <i *ngIf="report.isPrinting" class="fas fa-spinner fa-pulse"></i>
                        </div>
                    </td>
                    <td (click)="$event.stopPropagation()"
                        *ngIf="type === 'EXPORTED'">
                        <div [ngClass]="{'row-action': !report.isDelivering}" (click)="deliverCod(report)">
                            <i *ngIf="!report.isDelivering" class="fas fa-file-export"></i>
                            <i *ngIf="report.isDelivering" class="fas fa-spinner fa-pulse"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>
