import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HubsService} from '../../../shared/services/hubs.service';
import {GeographyService} from '../../../shared/services/geography.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HubModel} from '../../../shared/models/hub.model';
import {Address} from '../../../shared/models/address.class';
import {ZonesService} from '../../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-new-shelf',
    templateUrl: './new-shelf.component.html',
    styleUrls: ['./new-shelf.component.scss']
})
export class NewShelfComponent extends Address implements OnInit {
    public form: FormGroup;
    public isLoading = true;
    // public citiesOptions: { label: string, value: any }[];
    public regionsOptions: { label: string, value: any }[];
    public isEditMode = false;
    public shelf;
    public isSafe = false;
    @Input() hub: HubModel = null;
    public currentLang = '';

    constructor(private formBuilder: FormBuilder,
                private hubService: HubsService,
                private activeModal: NgbActiveModal,
                private geographyService: GeographyService,
                private zonesService: ZonesService,
                private translateService: TranslateService) {
        super(zonesService, translateService, true, true);
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        if (this.shelf != null) {
            this.isEditMode = true;
        }
        this.initForm();
        this.isLoading = false;
    }

    public validateForm() {
        if (this.form.controls['isSafe'].value) {
            this.form.controls.destinationCityIds.clearValidators();
            this.form.controls.destinationRegionId.clearValidators();
            this.form.controls.destinationCityIds.setValue(null);
            this.form.controls.destinationRegionId.setValue(null);
        } else {
            this.form.controls.destinationCityIds.setValidators(Validators.required);
            this.form.controls.destinationRegionId.setValidators(Validators.required);
        }
        this.form.controls.destinationCityIds.updateValueAndValidity();
        this.form.controls.destinationRegionId.updateValueAndValidity();
    }
    private initForm() {
        let info = {
            destinationCityIds: '',
            destinationRegionId: '',
            isSafe: false,
            description: '',
            label: ''
        };
        if (this.isEditMode) {
            this.disableRest = true;
            info = <any>{
                destinationCityIds: this.shelf.destinationCityIds,
                destinationRegionId: this.shelf.destinationRegionId,
                isSafe: this.shelf.isSafe,
                description: this.shelf.description,
                label: this.shelf.label

            };
            this.isSafe = this.shelf.isSafe;
        }
        this.form = this.formBuilder.group({
            destinationCityIds: [''],
            destinationRegionId: [''],
            isSafe: [''],
            description: [''],
            label: ['', Validators.required]
        });
        this.initFormWithOutVillageEvent(this.form, 0, {
            country: '',
            region: 'destinationRegionId',
            city: 'destinationCityIds'
        });
        this.form.patchValue(info);
        this.validateForm();
        this.disableRest = false;
    }

    private validateIdsInputs() {
        if (this.form.controls.destinationRegionId.value === '') {
            this.form.controls.destinationRegionId.setValue(null);
        }
        if (this.form.controls.destinationCityIds.value === '') {
            this.form.controls.destinationCityIds.setValue(null);
        }
    }


    public onAddShelf() {
        if (!this.form.valid) {
            return;
        }
        this.isLoading = true;
        this.validateIdsInputs();
        const data = this.form.getRawValue();
        if (this.isEditMode) {
            this.editShelf(data);
            return;
        }

        this.hubService.addShelf(this.hub.id, data).subscribe(
            (response: any) => {
                this.activeModal.close({isSuccess: true});
                this.isLoading = false;
            }, (error) => {
                this.activeModal.close({isSuccess: false});
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    public editShelf(data) {
        this.hubService.editShelf(this.shelf.id, data).subscribe(
            (response: any) => {
                this.activeModal.close({isSuccess: true});
            }, (error) => {
                this.activeModal.close({isSuccess: false});
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }
}
