<!--<p-toast [baseZIndex]="toastZIndex"></p-toast>-->
<div class="close-icon" (click)="closeModal()"><img src="../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}" >
    <div class="row d-inline-flex main-container">
        <div class="side-container">
            <div class="filter-options" [formGroup]="filterForm">
                <p-toggleButton formControlName="isShowDeliver"
                                offLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.DELIVER' | translate }}"
                                onLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.DELIVER' | translate }}">
                </p-toggleButton>
                <p-toggleButton formControlName="isShowPickup"
                                offLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.PICKUP' | translate }}"
                                onLabel="{{ 'ROUTE_OPTIMIZATION.FILTER.PICKUP' | translate }}">

                </p-toggleButton>
            </div>

            <div class="row title-container">
                <div class="label">
                    {{ 'ROUTE_OPTIMIZATION.TITLE' | translate }}
                </div>
            </div>

            <div class="drivers-list-container">
                <div class="list-header">
                    {{ 'ROUTE_OPTIMIZATION.FILTER.CHOOSE_DRIVER' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchDriver($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onDriversScroll($event)">
                    <div class="list-item"
                         [ngClass]="selectedDrivers && selectedDrivers.indexOf(driver.id) > -1 ? 'list-item-selected' : ''"
                         *ngFor="let driver of drivers; trackBy: trackDriverById">
                        <label class="list-item w-100 p-0 m-0">
                            <div class="d-flex">
                                <p-checkbox class="pl-1 pr-1" [value]="driver.id" [(ngModel)]="selectedDrivers" (onChange)="selectDriver(driver)"></p-checkbox>

                                <div class="list-name">
                                    {{driver.firstName + ' ' + driver.lastName}}
                                </div>
                            </div>
                            <span class="list-badge">
                            {{ driver.packagesCount + ' ' + ((driver.packagesCount === 1 ?
                                'ROUTE_OPTIMIZATION.PACKAGE' : 'ROUTE_OPTIMIZATION.PACKAGES') | translate) }}
                        </span>
                        </label>
                    </div>
                </div>
                <div *ngIf="isLoadingDrivers" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>
        </div>
        <div class="map-table-container flex-1">
            <div *ngIf="isLoadingRoute" class="map-route-loader">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div style="height: 50%;position: relative">
                <div id="map"></div>
                <div class="warning-tile-container">
                    <div class="warning-tile">{{'ROUTE_OPTIMIZATION.NOT_ALL_DESTINATION_ARE_SHOWN_WARNING' | translate}}</div>
                </div>
            </div>
            <div class="table-container">
            <p-table [value]="packages" [loading]="isLoadingPackages" class="entities-table-component"
                     [responsive]="true" scrollHeight="auto" styleClass="entities-table"
                     (scroll)="onTableScroll($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.BARCODE' | translate | packageTitle}}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.CUSTOMER_NAME' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.RECEIVER_NAME' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.COST' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.COD' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.SENDER_ADDRESS' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.RECEIVER_ADDRESS' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.CREATED_DATE' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.DRIVER_NAME' | translate }}</th>
                        <th>{{ 'ROUTE_OPTIMIZATION.TABLE.STATUS' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pkg>
                    <tr>
                        <td>{{ pkg.barcode }}</td>
                        <td>{{ pkg.customerName }}</td>
                        <td>{{ pkg.receiverFirstName }}</td>
                        <td><b>{{ pkg.cost | currencyValue : pkg.currency }}</b></td>
                        <td><b>{{ pkg.cod | currencyValue : pkg.currency}}</b></td>
                        <td>
                            <span>
                                {{pkg.originAddress.city }} - {{pkg.originAddress.village }}
                                - {{pkg.originAddress.addressLine1}} {{(pkg.originAddress.addressLine2) ? ("- " + pkg.originAddress.addressLine2) : ''}}
                            </span>
                        </td>
                        <td>
                            <span>
                                    {{pkg.destinationAddress.city }} - {{pkg.destinationAddress.village }}
                                - {{pkg.destinationAddress.addressLine1}} {{pkg.destinationAddress.addressLine2 ? "- " +
                                pkg.destinationAddress.addressLine2 : ''}}
                            </span>
                        </td>
                        <td>{{ pkg.createdDate | date:'dd/MM/yyyy' }}</td>
                        <td>{{ pkg.driverName }}</td>
                        <td>
                            <app-shipment-status [isEditEnabled]="false" [shipment]="pkg"></app-shipment-status>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="bottom-actions">
                <div class="start-actions">

                </div>
                <div class="end-actions">
                    <div [ngClass]="{'disabled-btn disabled': isLoading || !packages.length}"
                         class="done-action" (click)="onShowDriverRoute()">
                        <span>{{ 'ROUTE_OPTIMIZATION.ACTIONS.SHOW_ROUTE' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
