
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/modules/shared.module';
import {AccountComponent} from './account.component';

@NgModule({
    declarations: [
        AccountComponent
    ],
    exports: [
        AccountComponent
    ],
    imports: [
        SharedModule
    ]
})
export class AccountModule {
}
