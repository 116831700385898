import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShipmentsService} from '../services/shipments.service';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';

@Component({
    selector: 'app-bulk-print',
    templateUrl: './bulk-print.component.html',
    styleUrls: ['./bulk-print.component.scss']
})
export class BulkPrintComponent implements OnInit {
    public isLoading = false;
    public form;

    constructor(private activeModal: NgbActiveModal,
                private shipmentService: ShipmentsService,
                private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private confirmService: ConfirmationService) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            barcodes: [null, Validators.compose([Validators.required])],
            printType: ['REPORT', Validators.required]
        });
    }

    public closeModal() {
        this.activeModal.close();
    }

    public printPackages() {
        this.isLoading = true;
        this.shipmentService.printByBarcodes(this.form.value.barcodes, this.form.value.printType).subscribe((data: any) => {
            this.isLoading = false;
            window.open(data.url, '_blank');
            this.closeModal();
        }, (error) => {
            this.translateService.get(
                ['ALERTS.FAILED_TO_GENERATE_REPORT']
            )
                .subscribe(
                    (data) => {
                        const message = data['ALERTS.FAILED_TO_GENERATE_REPORT'];
                        const ok = data['GENERAL.OK'];
                        this.confirmService.confirm({
                            message: message + ': ' + error.error.error,
                            rejectVisible: false,
                            acceptLabel: ok,
                            accept: () => {
                                this.isLoading = false;
                            }
                        });
                    }
                );
            console.log(error);
        });
    }

}
