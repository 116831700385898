import {Component, OnInit} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {SaasService} from '../services/saas.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ChooseExistingComponent} from '../../shipment/choose-existing/choose-existing.component';
import {UserModel} from '../../shared/models/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EnterValueComponent} from '../../shared/components/enter-value/enter-value.component';
import {DASHBOARD_CONSTANT} from '../../dashboard/services/dashboard.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-bank-transfers',
    templateUrl: './bank-transfers.component.html',
    styleUrls: ['./bank-transfers.component.scss']
})
export class BankTransfersComponent implements OnInit {

    public isLoading = false;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;

    public pageNumber = 0;
    public totalRecords = 0;
    public transfers: any[];
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private saasService: SaasService,
                private router: Router,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    public initTransfersLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.initTransfers();
    }

    private initTransfers() {
        this.isLoading = true;
        const params = this.createParams();
        this.saasService.getBankTransfers(params).subscribe((response: any) => {
            this.transfers = response.data;
            this.totalRecords = response.totalRecordsNo;
            this.isLoading = false;

        }, () => {
            this.isLoading = false;
        });

    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };
        return params;
    }

    public approveTransfer(transfer, event) {
        event.stopPropagation();
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.APPROVE_TRANSFER', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.APPROVE_TRANSFER'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );

        const modal = this.modalService.open(EnterValueComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
        modal.componentInstance.title = 'BANK_TRANSFERS.APPROVE_TRANSFER';
        modal.componentInstance.inputLabel = 'BANK_TRANSFERS.ENTER_RECEIVED_AMOUNT';
        modal.result.then(
            (data: { finished: boolean, value: number }) => {
                if (data.finished) {
                    this.saasService.approveTransfer(transfer.id, data.value).subscribe(
                        (response) => {
                            this.translateService.get(
                                'ALERTS.TRANSFER_APPROVED_SUCCESSFULLY'
                            )
                                .subscribe(
                                    (data) => {
                                        this.messageService.add({severity: 'success', detail: data});
                                        this.initTransfers();
                                    }
                                );
                        }, (err) => {
                            this.confirmationService.confirm({
                                message: (err.error.error),
                                rejectVisible: false,
                                accept: () => {
                                },
                                acceptLabel: 'OK'
                            });
                        }
                    );
                }
            });
    }

    public rejectTransfer(transfer) {
        event.stopPropagation();
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.REJECT_TRANSFER', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.REJECT_TRANSFER'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.saasService.rejectTransfer(transfer.id).subscribe(
                    (response) => {
                        this.translateService.get(
                            'ALERTS.TRANSFER_REJECTED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                    this.initTransfers();
                                }
                            );
                    }, (err) => {
                        this.confirmationService.confirm({
                            message: (err.error.error),
                            rejectVisible: false,
                            accept: () => {
                            },
                            acceptLabel: 'OK'
                        });
                    }
                );
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    goToCOD(transfer) {
        const id = transfer.id;
        let url = this.authenticationService.companyName + 'home/bank-transfers/' + id;
        this.router.navigate([url], {
            queryParams: {
                isTransfer: true
            }
        });
    }

}
