<p-toast></p-toast>

<div [ngClass]="{'page-ltr': currentLang === 'en'}">

    <div class="payments-management-container">
        <div class="d-flex top-side">
            <div class="header">
                <div class="payments-management-title">
                    <span>{{ 'PAYMENT.TITLE' | translate }}</span>
                </div>
                <div class="total-number-badge">
                    <span>{{ totalPaymentsNo }}</span>
                </div>
            </div>

            <div class="actions">
<!--                <div class="date-picker-filter" (click)="datepicker.click()">-->
<!--                    <i class="fas fa-calendar"></i>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                           #datepicker-->
<!--                           daterangepicker [options]="dateRangPickerOptions"-->
<!--                           (selected)="onDateSelected($event)"/>-->
<!--                    <i class="fas fa-caret-down"></i>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                <div class="search-container">
                    <i class="fas fa-search"></i>
                    <input pInputText type="text" [(ngModel)]="searchContent" appInputDebounced
                           (inputDebounced)="getPayments(true)">
                </div>

                <div *ngIf="!isSuperAdmin" class="add-button" (click)="onNewPayment()"
                     [ngClass]="{'disabled-btn disabled': isLoading}">
                    <div class="icon">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="label">
                        <span>{{ 'PAYMENT.ACTIONS.NEW' | translate }}</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="data-table payments-data-table">
            <div class="table-body-container overflow-auto" (scroll)="onScroll($event)" #tableContainer>
                <p-table [value]="payments" [responsive]="true" scrollHeight="auto"
                         styleClass="table-container data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngIf="isSuperAdmin">
                                <div class="header-select-filter" [ngClass]="selectedCompany ? 'has-value' : ''">
                                    <span>{{ 'PAYMENT.TABLE.COMPANY_NAME' | translate }}</span>
                                    <ng-select
                                            [(ngModel)]="selectedCompany"
                                            (change)="getPayments(true)"
                                            [typeahead]="companySearchSubject"
                                            [loading]="isLoadingCompanies"
                                            bindLabel="name"
                                            [items]="companies">

                                    </ng-select>
                                </div>
                            </th>
                            <th>{{ 'PAYMENT.TABLE.PAYER_NAME' | translate }}</th>
                            <th>{{ 'PAYMENT.TABLE.PAYMENT_DATE' | translate }}</th>
                            <th>{{ 'PAYMENT.TABLE.PAYMENT_STATUS' | translate }}</th>
                            <th>{{ 'PAYMENT.TABLE.STRIPE_AMOUNT' | translate }}</th>
                            <th>{{ 'PAYMENT.TABLE.ORIGINAL_AMOUNT' | translate }}</th>
                            <th>{{ 'PAYMENT.TABLE.PROCESS_DATE' | translate }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content">
                            <app-no-data-available-component></app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-payment>
                        <tr>
                            <td *ngIf="isSuperAdmin">{{ payment.companyName }}</td>
                            <td>{{ payment.payeerName }}</td>
                            <td>{{ payment.createdDate | date:'dd/MM/yyyy' }}</td>
                            <td>
                                <div *ngIf="payment.paymentStatus" class="payment-status flex-2d-center">
                                    <span [ngStyle]="{'background-color': getStatusColor(payment.paymentStatus)}">{{ ('PAYMENT.PAYMENT_STATUSES.' + payment.paymentStatus) | translate}}</span>
                                </div>
                            </td>
                            <td>{{payment.stripeAmount + ' ' + payment.currency}}</td>
                            <td>{{payment.originalAmount + ' ' + payment.currency}}</td>
                            <td>{{payment.processDate | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader [type]="1" *ngIf="isLoading"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
