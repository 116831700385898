import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {PricingService} from '../../services/pricing.service';
import {CONTAINERS_CONSTANTS} from '../../../containers/services/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user.service';
import {ConfirmationService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddPricingListComponent} from '../pricing-lists/add-pricing-list/add-pricing-list.component';
import {AdminReportComponent} from '../../../shared/components/admin-report/admin-report.component';
import {REPORTS_CONSTANTS} from '../../../administration/reports-constants';
import {AddCustomersListComponent} from '../pricing-lists/add-customers-list/add-customers-list.component';
import {HubsService} from '../../../shared/services/hubs.service';

@Component({
    selector: 'app-customers-pricing-table',
    templateUrl: './customers-pricing-table.component.html',
    styleUrls: ['./customers-pricing-table.component.scss']
})
export class CustomersPricingTableComponent implements OnInit {

    public isLoading = false;
    public search = '';
    public page = 0;
    public totalRecords = 0;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public customers = [];
    public selectedLanguage = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    @Input() title = 'PRICING.CUSTOMERS_PRICING_TABLE';
    @Input() pricingLists = false;
    public driverPricing = false;
    isHubsPricing = false;
    isEnableExportingBetweenHubs = false;
    role = '';

    @ViewChild('table', {static: false}) table;

    constructor(private pricingService: PricingService,
                private router: Router,
                private hubsService: HubsService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private authService: AuthenticationService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.route.url.subscribe((data) => {
            if (data && data.length > 0 && data[0].path === 'drivers-pricing') {
                this.driverPricing = true;
            }
        });
        this.role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isHubsPricing = this.isEnableExportingBetweenHubs && this.role === 'HUB_ADMIN';
        this.selectedLanguage = this.translateService.currentLang;
        this.isLoading = true;
    }

    private createQuery() {
        const {pageSize, page} = this;
        const param = <any>{pageSize, page};

        if (this.search !== '') {
            param.search = this.search;
        }
        // if (this.isHubsPricing) {
        //     param['is-get-all-hubs'] = true;
        // }
        param['driverType'] = 'TYPICAL';
        param.page += 1;
        return param;
    }

    private initCustomers() {
        if (this.isHubsPricing) {
            this.pricingService.getHubsPrices(this.createQuery()).subscribe(
                (response: any) => {
                    this.customers = response;
                    this.totalRecords = response.totalRecordsNo;
                    this.isLoading = false;
                }
            );
        } else {
            if (this.driverPricing) {
                this.pricingService.getPrivateDriverPricing(this.createQuery()).subscribe((response: any) => {
                    this.customers = response.data;
                    this.totalRecords = response.totalRecordsNo;
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            } else if (this.pricingLists) {
                this.pricingService.getPricingList(this.createQuery()).subscribe((response: {data: any, totalRecordsNo: number} ) => {
                    this.customers = response.data;
                    this.totalRecords = response.totalRecordsNo;
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            } else {
                this.pricingService.getCustomersPrices(this.createQuery()).subscribe((response: any) => {
                    this.customers = response;
                    this.totalRecords = response.totalRecordsNo;
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            }
        }
    }

    public onSearch() {
        this.page = 0;
        this.table.reset();
    }

    public loadCustomersLazy($event) {
        this.isLoading = true;
        this.page = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.initCustomers();
    }

    public printPrice(customer) {
        customer.isPrinting = true;
        this.pricingService.exportPriceToExcel(customer.customerId).subscribe((res: any) => {
            window.open(res.url, '_blank');
            customer.isPrinting = false;
        }, error => {
            customer.isPrinting = false;
        });
    }

    public tableHeader() {
        if (this.isHubsPricing) {
            return 'BRANCHES.TITLE';
        } else {
            if (this.driverPricing) {
                return 'ADMINISTRATION.DRIVER_NAME';
            } else if (this.pricingLists) {
                return 'PRICING.LIST_NAME';
            } else {
                return 'PRICING.CUSTOMER_NAME';
            }
        }
    }

    public viewPrice(customer) {
        let name, id , idQueryParam, type;
        if (this.isHubsPricing) {
            name = customer.customerName;
            id = customer.hubId;
            idQueryParam = 'customer-id';
            type = 'CUSTOMER';
        } else {
            if (customer.customerId) {
                name = customer.customerName;
                id = customer.customerId;
                idQueryParam = 'customer-id';
                type = 'CUSTOMER';
            } else {
                name = customer.name + ' ' + this.getBusinessName(customer.businessName);
                id = customer.id;
                idQueryParam = 'pricing-list-id';
                type = 'PRICING_LIST';
            }
        }

        if (this.isHubsPricing) {
            this.router.navigate([this.authService.companyName + 'home/shipping-rates/' + id]);
        } else {
            if (this.driverPricing) {
                this.router.navigate([this.authService.companyName +
                '/home/' + 'drivers-earnings/' + 'shipping-rates/' + id], {
                    queryParams: {
                        isDriverWorkArea: true
                    }});
            } else {
                if (this.userService.userInfo.isPricingPerServiceTypeEnabled) {
                    this.router.navigate([this.authService.companyName + 'home/pricing-service-types/' + id],
                        { queryParams: { customerName: `${name}`, idQueryParam: idQueryParam, type: type }});

                }  else if (this.pricingLists) {
                    this.router.navigate([this.authService.companyName + 'home/shipping-rates/' + customer.id],
                        {
                            queryParams: {
                                isPricingList: true
                            }
                        });
                } else {
                    this.router.navigate([this.authService.companyName + 'home/shipping-rates/' + customer.customerId]);
                }
            }
        }


    }
    public getBusinessName(businessName) {
        return businessName ? '(' + businessName + ')' : '';
    }
    public deleteCustomerPricing(customer) {
        this.isLoading = true;
        const message = this.isHubsPricing ? 'ALERTS.DELETE_HUB_PRICING' : (this.pricingLists ? 'ALERTS.DELETE_PRICING_LIST' : 'ALERTS.DELETE_CUSTOMER_PRICING');
        const id = this.isHubsPricing ? customer.hubId: (customer.customerId ? customer.customerId: customer.id)
        this.confirmationService.confirm({
            message: this.translateService.instant(message),
            accept: () => {
                let deletePricingList = this.pricingService.deleteCustomerPrice(id);
                if (this.isHubsPricing) {
                    deletePricingList = this.pricingService.deleteHubPrice(id);
                } else if (this.pricingLists) {
                    deletePricingList = this.pricingService.deletePricingList(id);
                }
                deletePricingList.subscribe(
                    () => {
                        this.initCustomers();
                    }, error => {
                        console.log(error);
                        this.isLoading = false;
                    }
                );
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
            reject: () => {
                this.isLoading = false;
            }
        });
    }

    public customizePricing() {
        const modal = this.modalService.open(AddPricingListComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.result.then(
            (data: { isSuccess: boolean, listName?: string }) => {
                if (data.isSuccess) {
                    this.pricingService.addPricingList(data.listName).subscribe((response: any) => {
                        this.router.navigate([this.authService.companyName + 'home/shipping-rates/' + response],
                            {queryParams: {
                                    isPricingList: true
                                }});
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public showCustomers(pricingListId) {
        const modal = this.modalService.open(AddCustomersListComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.pricingListId = pricingListId;
    }
}
