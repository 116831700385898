import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {
    StripeCardElement,
    StripeElements,
    StripeElementStyle
} from '@stripe/stripe-js';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PaymentsService} from '../../../administration/services/payments.service';
import {StripeCardElementChangeEvent} from '@stripe/stripe-js/types/stripe-js/elements/card';

@Component({
    selector: 'app-payment-card-information',
    templateUrl: './payment-card-information.component.html',
    styleUrls: ['./payment-card-information.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PaymentCardInformationComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => PaymentCardInformationComponent),
        }
    ]
})
export class PaymentCardInformationComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    private stripeElements: StripeElements;
    private cardElement: StripeCardElement;
    private isValid = false;

    @Input()
    clientSecret: string;

    onChange: any = () => {
    };
    onTouch: any = () => {
    };

    constructor(private paymentsService: PaymentsService) {
    }

    set value(value: StripeCardElement) {
        if (value) {
            this.cardElement = value;
            this.onChange(value);
            this.onTouch(value);
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.stripeElements = this.paymentsService.stripe.elements({
            appearance: {
                theme: 'stripe',
                variables: {
                    colorPrimary: '#0570de',
                    colorBackground: '#ffffff',
                    colorText: '#30313d',
                    colorDanger: '#df1b41',
                    fontFamily: 'Alamari, system-ui, sans-serif',
                    spacingUnit: '2px',
                    borderRadius: '4px',
                    // See all possible variables below
                }
            }
        });

        const style: StripeElementStyle = {
            base: {
                color: '#32325d',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                },
                lineHeight: '18px'
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        this.cardElement = this.stripeElements.create('card', {
            style,
            hidePostalCode: true
        });

        this.cardElement.mount('#card');
        this.cardElement.on('change', (event: StripeCardElementChangeEvent) => {
            if (event.error) {

            }
            this.isValid = event.complete;
            this.value = this.cardElement;
        });

        this.value = this.cardElement;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: StripeCardElement): void {
        if (value == null) {
            this.cardElement.clear();
        }

        this.value = value;
    }

    validate() {
        if (this.isValid) {
            return false;
        }
        const invalid = {};

        if (!this.isValid) {
            invalid['card'] = true;
        }
        return invalid;
    }

}
