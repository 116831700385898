import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Pipe({
    name: 'elapsedTime'
})
export class ElapsedTimePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(value: string, args?: any): any {
        const date = new Date(value).getTime();
        const beforeLabel = this.translateService.instant('ELAPSED_TIME.BEFORE');
        const seconds = Math.floor(((new Date().getTime()) - date) / 1000);
        let elapsedLabel;

        let interval = seconds / 31536000;

        if (interval >= 1) {
            elapsedLabel = interval === 1 ? this.translateService.instant('ELAPSED_TIME.YEAR') : this.translateService.instant('ELAPSED_TIME.YEARS');
            return beforeLabel + ' ' +  Math.floor(interval) + ' ' + elapsedLabel;
        }
        interval = seconds / 2592000;
        if (interval >= 1) {
            elapsedLabel = interval === 1 ? this.translateService.instant('ELAPSED_TIME.MONTH') : this.translateService.instant('ELAPSED_TIME.MONTHS');
            return beforeLabel + ' ' +  Math.floor(interval) + ' ' +  elapsedLabel;
        }
        interval = seconds / 86400;
        if (interval >= 1) {
            elapsedLabel = interval === 1 ? this.translateService.instant('ELAPSED_TIME.DAY') : this.translateService.instant('ELAPSED_TIME.DAYS');
            return beforeLabel + ' ' + Math.floor(interval) + ' ' + elapsedLabel;
        }
        interval = seconds / 3600;
        if (interval >= 1) {
            elapsedLabel = interval === 1 ? this.translateService.instant('ELAPSED_TIME.HOUR') : this.translateService.instant('ELAPSED_TIME.HOURS');
            return beforeLabel + ' ' + Math.floor(interval) + ' ' + elapsedLabel;
        }
        interval = seconds / 60;
        if (interval >= 1) {
            elapsedLabel = interval === 1 ? this.translateService.instant('ELAPSED_TIME.MINUTE') : this.translateService.instant('ELAPSED_TIME.MINUTES');
            return beforeLabel + ' ' +  Math.floor(interval) + ' ' + elapsedLabel;
        }
        return (Math.floor(seconds) > 0 ? beforeLabel + ' ' + Math.floor(seconds) + ' ' + this.translateService.instant('ELAPSED_TIME.SECONDS') : this.translateService.instant('ELAPSED_TIME.NOW'));
    }
}

