<p-sidebar [(visible)]="display"
           (onHide)="onClose(false)"
           [dismissible]="false"
           #newTripSideBarr
           styleClass="new-trip-form-sidebar">

    <div class="add-trip-form d-flex flex-column h-100" [ngClass]="{'page-ltr': currentLang === 'en'}"
         style="width: 1400px;">
        <div class="sections-container h-100">
            <div class="section main-destinations" [formGroup]="mainTripInfoForm">
                <div class="section-header d-flex">
                    <div class="section-icon">
                        <img src="../../../../assets/icons/add_package/sender_location.svg" alt="">
                    </div>
                    <div class="section-title">
                        {{'TRIPS_BETWEEN_CITIES.FORM.INFO' | translate}}
                    </div>
                </div>
                <div class="section-contents d-flex justify-content-between">

                    <div class="from-city destination">
                        <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TRIP_NUMBER' | translate}}</span></div>
                        <div class="value">
                            <input type="number" appInputPositiveNumberDirective [allowDecimals]="false"
                                   formControlName="tripNumber">
                        </div>
                    </div>
                    <div class="to-city destination">
                        <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.CAPACITY' | translate}}</span></div>
                        <div class="value">
                            <input type="number" appInputPositiveNumberDirective [allowDecimals]="false"
                                   formControlName="mainTripCapacity">
                        </div>
                    </div>

                    <div class="flex-2d-center ml-3 mr-3">
                        <div [ngClass]="{'disabled-button disabled': mainTripInfoForm.invalid || isLoading}"
                             class="add-main-trip cursor-pointer" (click)="addMainTrip()">
                            {{(isEditMainTrip ? 'ACTIONS.EDIT' : 'ACTIONS.ADD') | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="section main-destinations">
                <div class="section-header d-flex justify-content-between align-items-center">
                    <div class="section-icon">
                        <img src="../../../../assets/icons/add_package/sender_location.svg" alt="">
                    </div>
                    <div class="section-title">
                        {{'TRIPS_BETWEEN_CITIES.FORM.DETAILS' | translate}}
                    </div>

                    <div *ngIf="isEditMode && showAddSubTripBtn" class="show-add-sub-trip-btn" (click)="addSubTripAddForm()">
                        <i class="fas fa-plus"></i>
                    </div>
                </div>
                <div class="section-contents sub-trips">
                    <div class="d-flex flex-column" *ngFor="let trip of addTripsForms;index as i"
                         [formGroup]="trip.form">
                        <div class="trip-actions w-100 d-flex justify-content-end">
                            <div *ngIf="!trip.addedDone && !trip.id" class="add-trip action flex-2d-center"
                                 [ngClass]="{'disabled-button disabled': trip.isLoading || trip.form.invalid || mainTripInfoForm.invalid}"
                                 (click)="addTrip(trip)">{{'ACTIONS.ADD' | translate}}</div>

                            <div *ngIf="trip.id" class="edit-trip action flex-2d-center"
                                 [ngClass]="{'disabled-button disabled': !trip.id && (trip.isLoading || trip.form.invalid || mainTripInfoForm.invalid)}"
                                 (click)="addTrip(trip)">{{'ACTIONS.EDIT' | translate}}</div>

                            <div *ngIf="trip.id" class="delete-trip action flex-2d-center"
                                 [ngClass]="{'disabled-button disabled': !trip.id && (trip.isLoading || mainTripInfoForm.invalid)}"
                                 (click)="deleteSubTrip(trip.id, i)">{{'ACTIONS.DELETE' | translate}}</div>
                        </div>
                        <div class="d-flex flex-wrap align-items-center justify-content-center sub-trip-info-container">
                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('days').touched: true) && trip.form.get('days').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.DAYS' | translate}}</span></div>
                                <div class="value">
                                    <p-multiSelect [options]="daysList"
                                                   [formControlName]="'days'"
                                                   #selectedDaysDropDown
                                                   maxSelectedLabels="0"
                                                   selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                                   [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                                   defaultLabel=""
                                                   optionLabel="label"
                                                   appendTo="body"
                                                   optionValue="value">
                                        <ng-template let-day pTemplate="item">
                                            {{ day.label | translate }}
                                        </ng-template>
                                    </p-multiSelect>
                                </div>
                            </div>


                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('fromHub').touched: true) && trip.form.get('fromHub').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_STATION' | translate}}</span>
                                </div>
                                <div class="value">

                                    <app-input-field-component
                                            [form]="trip.form"
                                            field="label"
                                            [dataKey]="'value'"
                                            [forceSelection]="true"
                                            [displayDropDown]="true"
                                            [searchSuggestions]="regionOptions"
                                            (onSearch)="getRegions($event.query)"
                                            controlName="fromHub"
                                            [options]="regionOptions"
                                            [isRequired]="true"
                                            [appendTo]="'body'"
                                            controlType="autoComplete">
                                    </app-input-field-component >
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('toHub').touched: true) && trip.form.get('toHub').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_STATION' | translate}}</span>
                                </div>
                                <div class="value">
                                    <app-input-field-component
                                            [form]="trip.form"
                                            field="label"
                                            [dataKey]="'value'"
                                            [forceSelection]="true"
                                            [displayDropDown]="true"
                                            [searchSuggestions]="regionOptions"
                                            (onSearch)="getRegions($event.query)"
                                            controlName="toHub"
                                            [options]="regionOptions"
                                            [isRequired]="true"
                                            [appendTo]="'body'"
                                            controlType="autoComplete">
                                    </app-input-field-component >
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('validFrom').touched: true) && trip.form.get('validFrom').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.VALID_FROM' | translate}}</span>
                                </div>
                                <div class="value">

                                    <p-calendar [formControlName]="'validFrom'" appendTo="body"
                                                dateFormat="dd/mm/yy"></p-calendar>
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('validTo').touched: true) && trip.form.get('validTo').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.VALID_TO' | translate}}</span>
                                </div>
                                <div class="value">
                                    <p-calendar [formControlName]="'validTo'"
                                                [minDate]="trip.form.get('validFrom').value" appendTo="body"
                                                dateFormat="dd/mm/yy"></p-calendar>
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('fromTime').touched: true) && trip.form.get('fromTime').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_TIME' | translate}}</span>
                                </div>
                                <div class="value">
                                    <p-calendar [formControlName]="'fromTime'" showTime="true" appendTo="body"
                                                [timeOnly]="true"
                                                [hourFormat]="12"></p-calendar>
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('toTime').touched: true) && trip.form.get('toTime').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_TIME' | translate}}</span>
                                </div>
                                <div class="value">
                                    <p-calendar [formControlName]="'toTime'" showTime="true" appendTo="body"
                                                [timeOnly]="true"
                                                [hourFormat]="12"></p-calendar>
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('cost').touched: true) && trip.form.get('cost').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.PRICE' | translate}}</span></div>
                                <div class="value">
                                    <input [formControlName]="'cost'" type="number"/>
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('capacity').touched: true) && trip.form.get('capacity').errors)}">
                                <div class="label"><span>{{'TRIPS_BETWEEN_CITIES.FORM.CAPACITY' | translate}}</span>
                                </div>
                                <div class="value">
                                    <input [formControlName]="'capacity'" type="number"/>
                                </div>
                            </div>

                            <div class="sub-trip-input"
                                 [ngClass]="{'has-error': ((!trip.id ? trip.form.get('parcelType').touched: true) && trip.form.get('parcelType').errors)}">
                                <div class="label">
                                    <span>{{'TRIPS_BETWEEN_CITIES.FORM.SHIPMENT_TYPE' | translate}}</span>
                                </div>
                                <div class="value">
                                    <p-dropdown [options]="parcelsOptions"
                                                [formControlName]="'parcelType'"
                                                #selectedParcelsDropDown
                                                maxSelectedLabels="0"
                                                selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                                filterPlaceHolder="{{'GENERAL.SEARCH' | translate}}"
                                                defaultLabel=""
                                                optionLabel="label"
                                                optionValue="value">
                                        <ng-template let-parcel pTemplate="item">
                                            {{ parcel.label | translate }}
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                            <div id="new-sub-trip"></div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="bottom-actions d-flex justify-content-end">
            <div class="done" (click)="onClose(true)">{{'ACTIONS.DONE' | translate}}</div>

        </div>
    </div>
</p-sidebar>
