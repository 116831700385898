import { Component, OnInit } from '@angular/core';
import {REPORTS_CONSTANTS} from '../administration/reports-constants';

@Component({
  selector: 'app-financial-report',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.scss']
})
export class FinancialReportComponent implements OnInit {
  public financialReportInfo = REPORTS_CONSTANTS.FINANCIAL_REPORT.FINANCIAL_REPORT_INFO;
  public financialReportAPI = REPORTS_CONSTANTS.FINANCIAL_REPORT.FINANCIAL_REPORT_API;
  public financialReportDateType = REPORTS_CONSTANTS.FINANCIAL_REPORT.DATE_TYPE_FILTER_LIST;
  reportAPI: any;
  constructor() { }

  ngOnInit() {
  }

}
