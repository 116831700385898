<p-cellEditor>
  <ng-template pTemplate="input">
    <input style="color: #00bd9f" type="number" [(ngModel)]="package.driverEarning"
           (ngModelChange)="editCost(package)">
  </ng-template>
  <ng-template pTemplate="output">
    <div class="d-flex flex-row" style="align-items: center;">
      <div>
        {{package.driverEarning}}
      </div>
      <div style="width: 5px"></div>
      <div class="d-flex flex-column">
        <a style="cursor: pointer; height: 14px"
           (click)="updatePackageCost($event, package, +1)" aria-hidden="true">
          <i class="fas fa-sort-asc" aria-hidden="true"></i>
        </a>
        <a style="cursor: pointer; height: 14px"
           (click)="updatePackageCost($event, package, -1)" aria-hidden="true">
          <i style="margin-top: 0;" class="fas fa-sort-desc"
             aria-hidden="true"></i>
        </a>
      </div>
    </div>

  </ng-template>
</p-cellEditor>
