import {AutoAssignPackagesToDriversMethod} from '../shared/enums/AutoAssignPackagesToDriversMethod';

export const COMPANIES_CONSTANTS = {
    GET_COMPANIES: ':api/super-admin/companies',
    GET_COMPANY_BILLING: ':api/super-admin/companies/billing',
    GET_COMPANY_BILLING_PAYMENTS: ':api/super-admin/companies/payment',
    GET_COMPANIES_NOTIFICATION_HISTORY: ':api/super-admin/companies/billing-notification',
    GET_COMPANY_BILLING_NOTIFICATION: ':api/admin/companies/billing-notification',
    ADD_COMPANY_BILLING_COST: ':api/super-admin/companies/billing-cost/{companyId}',
    ADD_COMPANY_BILLING_PAYMENT: ':api/super-admin/companies/payment/{companyPaymentId}',
    SET_COMPANY_BILLING_TEMP_DISABLE_DATE : ':api/super-admin/companies/billing-temp-date/{companyId}',
    ADD_COMPANY_PAYMENT: ':api/super-admin/companies/payment',
    DELETE_COMPANY_PAYMENT: ':api/super-admin/companies/payment/{paymentId}',
    RESET_COMPANY_IS_PAID: ':api/super-admin/companies/billing/{companyBillingId}/is-paid/reset',
    SET_ACCOUNTABLE_PACKAGES: ':api/super-admin/companies/billing/{companyBillingId}/packages-number',
    SET_COMPANY_IS_NOTIFY: ':api/super-admin/companies/billing-notify/{companyId}',
    SET_COMPANY_IS_BILLING: ':api/super-admin/companies/{companyId}/billing-status',
    START_COMPANY_BILLING: ':api/super-admin/companies/billing',
    ADD_BILLING_PAYMENT_FOR_SPECIFIC_COMPANY: ':api/super-admin/companies/company-billing',
    UPDATE_NOTIFICATION_SETTINGS: ':api/super-admin/companies/billing-notification/{notificationSettingId}',
    EXPORT_COMPANIES: ':api/super-admin/companies/excel',
    SUBMIT_COMPANIES: ':api/super-admin/companies/new',
    SUBMIT_COMPANY_CONFIGURATION: ':api/admin/companies/modify-configurations',
    GUEST_SUBMIT_COMPANIES: ':api/guests/companies/new',
    SIGN_UP_COMPANY: ':api/companies/signup',
    UPLOAD_lOGO: ':api/admin/companies/upload-logo',
    DELETE_COMPANY: ':api/super-admin/companies/{companyId}/delete',
    UPDATE_COMPANY: ':api/admin/companies/modify',
    ADMIN_UPDATE_COMPANY: ':api/admin/companies/modify',
    CHANGE_STATUS: ':api/super-admin/companies/{companyId}/change-status',
    BILLING_PDF_REPORT: ':api/super-admin/companies/billing/{billingId}/pdf',
    COMPANY_PAYMENT_EXCEL: ':api/super-admin/companies/payment/excel',
    EXPORT_RETURNED_BEFORE_ARRIVAL: ':api/super-admin/companies/billing/returned-before-arrival/excel',
    MODIFY_SMS_SETTING: ':api/admin/companies/notification-sms-setting',
    GET_COMPANY: ':api/admin/companies/company',
    GET_COMPANIES_DROPDOWN: ':api/super-admin/companies/drop-down',
    UPLOAD_WATERMARK: ':api/admin/companies/upload-image',
};

export const CURRENCIES_LIST = [
    'ALL',
    'NIS',
    'JOD',
    'KWD',
    'BHD',
    'SAR',
    'IQD',
    'OMR',
    'LYD',
    'TRY',
    'EGP'
];

export const AUTO_ASSIGN_PACKAGES_TO_DRIVER_METHOD_OPTIONS = [
    {
      label: 'ON_DEMAND.METHODS.BY_DRIVER_DISTANCE',
      value: AutoAssignPackagesToDriversMethod.BY_DRIVER_DISTANCE
    },
    {
      label: 'ON_DEMAND.METHODS.BY_DRIVER_PRIORITY',
      value: AutoAssignPackagesToDriversMethod.BY_DRIVER_PRIORITY
    },
    {
      label: 'ON_DEMAND.METHODS.BY_DRIVER_DISTANCE_AND_SHIPPING_LINE',
      value: AutoAssignPackagesToDriversMethod.BY_DRIVER_DISTANCE_AND_SHIPPING_LINE
    },
    {
      label: 'ON_DEMAND.METHODS.SINGLE_SHIPPING_LINE_DRIVER',
      value: AutoAssignPackagesToDriversMethod.SINGLE_SHIPPING_LINE_DRIVER
    }
];
