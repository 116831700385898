import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NewUserComponent} from '../../../users/create-new-user/new-user.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddUpdateDialogComponent} from '../add-update-dialog/add-update-dialog.component';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {UpdateModel} from '../../models/update.model';
import {timer} from 'rxjs';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {OverlayPanel} from 'primeng/overlaypanel';
import {CreateSupplierComponent} from '../../../add-package-form/create-supplier/create-supplier.component';
import {UserService} from '../../../shared/services/user.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {updatesBadgeOp} from '../../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-system-updates',
    templateUrl: './system-updates.component.html',
    styleUrls: ['./system-updates.component.scss']
})
export class SystemUpdatesComponent extends SubscriptionTrackerComponent implements OnInit {
    pageSize = 10;
    totalNumberOfUpdates: any;
    isSuperAdmin: boolean;
    private rowIndex: any;
    private update: any;
    private pageNumber = 0;
    public spinnerState = SpinnerState.LOADING;
    public tableColumnCount = 4;
    public updatesData: UpdateModel[] = [];
    public spinnerStates = SpinnerState;
    public selectedLanguage = 'ar';
    @ViewChild('loadingTrigger') loadingTrigger;
    @ViewChild('tableContainer') tableContainer;

    private hasMore: Boolean | boolean = true;
    private loadingUpdates: boolean;
    private editedUpdateIndex: number;
    private isMobileResolution: boolean;
    isSearchContent: any;
    searchContent: any;
    selectedTab = 'VIDEO';
    tabs = ['VIDEO', 'DOCUMENT'];
    permissions = '';
    markAllAsSeenRequest = false;
    disableMarkAllAsRead = false;

    constructor(private modalService: NgbModal,
                private authenticationService: AuthenticationService,
                private router: Router,
                public translateService: TranslateService,
                private confirmService: ConfirmationService,
                private systemUpdatesService: SystemUpdatesService,
                private userService: UserService,
                private rolesService: RolesService,
                private route: ActivatedRoute,
                private applicationStateService: ApplicationStateService) {
        super();
        this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
        this.tableColumnCount = this.isSuperAdmin ? 4 : 3;
        this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
        this.permissions = this.rolesService.getUserPermissions('SYSTEM_UPDATES', this.userService.userInfo.role);
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        updatesBadgeOp.subscribe(val => {
            this.disableMarkAllAsRead = val === 0;
        });
    }

    openAddUpdateDialog(type) {

        const modal = this.modalService.open(AddUpdateDialogComponent, <any>{
            size: 'md'
        });
        modal.componentInstance.type = type;

        modal.result.then(result => {
            if (result && result.added) {
                this.loadUpdatesLazy(true);
            }
        });

    }

    @HostListener('scroll', ['$event'])
    onsScrollUpdateTable(event, isMobile = false) {
        if (isMobile) {
            this.handleMobileUpdatesLoading(event);
        } else {
            this.handleUpdatesLoading(event);
        }
    }

    loadUpdatesLazy(forceFetch = false) {
        if (forceFetch) {
            this.pageNumber = 0;
            this.updatesData = [];
            this.handleFillingTheScreen();
        }
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
            type: this.selectedTab
        };
        if (this.searchContent) {
            params['search'] = this.searchContent;
        }

        let editedPage;
        if (this.editedUpdateIndex > -1) {
            editedPage = Math.floor(this.editedUpdateIndex / 10);
            params.page = editedPage;
        }
        const tableBodyScrollTopBeforeAddingValue = this.tableContainer && this.tableContainer.nativeElement ? this.tableContainer.nativeElement.scrollTop : 0;
        this.spinnerState = SpinnerState.LOADING;
        this.systemUpdatesService.loadSystemUpdate(params, this.isSuperAdmin).subscribe((res: any) => {
                this.totalNumberOfUpdates = res.totalRecordsNo;
                if (this.editedUpdateIndex > -1) {
                    this.updatesData.splice(editedPage, 10);
                    this.updatesData.splice(editedPage, 0, ...res.data);
                    this.editedUpdateIndex = -1;
                } else {
                    this.updatesData.push(...res.data);
                    this.hasMore = this.updatesData && (this.updatesData.length < res.totalRecordsNo);
                }

                this.spinnerState = SpinnerState.LOADED;
                if (this.updatesData.length === 0) {
                    this.spinnerState = SpinnerState.EMPTY;
                }

                if (this.tableContainer && this.tableContainer.nativeElement) {
                    setTimeout(() => {
                        this.tableContainer.nativeElement.scrollTop = tableBodyScrollTopBeforeAddingValue;
                        this.loadingUpdates = false;
                    }, 30);
                }

            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    editUpdate() {
        const modal = this.modalService.open(AddUpdateDialogComponent, <any>{
            size: 'md',
            data: this.update
        });
        this.editedUpdateIndex = this.updatesData.findIndex((value) => {
            return value.id === this.update.id;
        });
        modal.componentInstance.update = this.update;
        modal.result.then(result => {
            if (result && result.added) {
                this.loadUpdatesLazy(false);
            }
        });

    }

    removeUpdate() {
        this.translateService.get(
            ['ALERTS.CONFIRM_DELETE_UPDATE', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    const message = data['ALERTS.CONFIRM_DELETE_UPDATE'];
                    const yes = data['GENERAL.YES'];
                    const no = data['GENERAL.NO'];
                    this.confirmService.confirm({
                        message: message,
                        accept: () => {
                            this.systemUpdatesService.removeSystemUpdate(this.update.id).subscribe(r => {
                                this.loadUpdatesLazy(true);
                            });
                        },
                        reject: () => {

                        },
                        acceptLabel: yes,
                        rejectLabel: no
                    });
                }
            );

    }

    triggerUpdateDropdown($event: MouseEvent, dropdown: OverlayPanel, rowIndex, update) {
        this.rowIndex = rowIndex;
        this.update = update;
        dropdown.toggle($event);
    }

    openUpdateInfo(update) {
        this.router.navigate([update.id], {relativeTo: this.route});
    }

    markAllAsSeen() {
        this.markAllAsSeenRequest = true;
        this.systemUpdatesService.markAllAsSeen().subscribe(res => {
            this.loadUpdatesLazy(true);
            this.userService.initSystemUpdatesBadge();
            this.markAllAsSeenRequest = true;
        });
    }

    private handleUpdatesLoading(event) {
        if (this.loadingUpdates || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
            return;
        }


        if (!this.loadingTrigger || !this.loadingTrigger.nativeElement) {
            return;
        }
        const loading_trigger_offset = this.loadingTrigger.nativeElement.offsetTop;

        if (event.target.offsetHeight + event.target.scrollTop > loading_trigger_offset) {
            this.pageNumber++;
            this.loadUpdatesLazy();
            this.loadingUpdates = true;

        }
    }

    private handleFillingTheScreen() {
        if (this.applicationStateService.getIsMobileResolution()) {
            return;
        }
        const ob = timer(1000, 1000);
        let stopTimer = false;
        const subscribe = ob.subscribe(res => {
            if (this.hasMore) {
                const hasVerticalScrollbar = this.tableContainer.nativeElement.scrollHeight > this.tableContainer.nativeElement.clientHeight;

                if (!hasVerticalScrollbar) {
                    if (this.spinnerState === SpinnerState.LOADED) {
                        this.pageNumber++;
                        this.loadUpdatesLazy(false);
                    }
                } else {
                    stopTimer = true;
                }
            } else {
                stopTimer = true;
            }
            if (stopTimer) {
                subscribe.unsubscribe();
            }
        });
        this.addSubscription(subscribe);
    }

    private handleMobileUpdatesLoading(event) {
        if (this.loadingUpdates || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
            return;
        }

        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 20) {
            this.pageNumber++;
            this.loadUpdatesLazy(false);
            this.loadingUpdates = true;
        }
    }

    toggleIsSearchContent(value: boolean) {
        this.isSearchContent = value;
    }

    onTabChange(tab: string) {
        this.selectedTab = tab;
        this.loadUpdatesLazy(true);
    }
}
