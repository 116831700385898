<div class=" first-section d-flex p-5">
    <p-table [value]="storeToStorePrices?.details">
        <ng-template pTemplate="header">
            <tr>
                <th>{{'JETT.WEIGHT' | translate}}</th>
                <th colspan="2"> {{'JETT.STORE_TO_STORE' | translate}} </th>
            </tr>
            <tr>
                <th rowspan="2"> {{'JETT.CHARGEABLE_WEIGHT' | translate}} </th>
                <th colspan="2"> {{'JETT.SELLING_PRICE' | translate}} </th>
            </tr>
            <tr>
                <th> {{'JETT.WITH_VAT' | translate}} </th>
                <th> {{'JETT.NO_VAT' | translate}} </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-price>
            <tr>
                <td>{{price.fromWeight}} - {{price.toWeight}}</td>
                <td>{{price.withVat}}</td>
                <td>{{price.noVat}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td>{{'JETT.EVERY' |translate}} {{storeToStorePrices.extraWeightValue}} {{'JETT.EXTRA_WEIGHT'|translate}}</td>
                <td>{{storeToStorePrices.extraWeightChargeWithVat}}</td>
                <td>{{storeToStorePrices.extraWeightCharge}}</td>
            </tr>
        </ng-template>

    </p-table>

    <p-table [value]="storeToDoorAPrices?.details">
        <ng-template pTemplate="header">
            <tr>
                <th colspan="2"> {{'JETT.STORE_TO_DOOR_A' | translate}} </th>
            </tr>
            <tr>
                <th colspan="2"> {{'JETT.SELLING_PRICE' | translate}} </th>
            </tr>
            <tr>
                <th> {{'JETT.WITH_VAT' | translate}} </th>
                <th> {{'JETT.NO_VAT' | translate}} </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-price>
            <tr>
                <td>{{price.withVat}}</td>
                <td>{{price.noVat}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td>{{storeToDoorAPrices.extraWeightChargeWithVat}}</td>
                <td>{{storeToDoorAPrices.extraWeightCharge}}</td>
            </tr>
        </ng-template>
    </p-table>

    <p-table [value]="storeToDoorBPrices?.details">
        <ng-template pTemplate="header">
            <tr>
                <th colspan="2"> {{'JETT.STORE_TO_DOOR_B' | translate}} </th>
            </tr>
            <tr>
                <th colspan="2"> {{'JETT.SELLING_PRICE' | translate}} </th>
            </tr>
            <tr>
                <th> {{'JETT.WITH_VAT' | translate}} </th>
                <th> {{'JETT.NO_VAT' | translate}} </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-price>
            <tr>
                <td>{{price.withVat}}</td>
                <td>{{price.noVat}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td>{{storeToDoorBPrices.extraWeightChargeWithVat}}</td>
                <td>{{storeToDoorBPrices.extraWeightCharge}}</td>
            </tr>
        </ng-template>
    </p-table>

</div>
<div class="d-flex p-5">
    <p-table class="col-12" [value]="outboundPrice?.details">
        <ng-template pTemplate="header">
            <tr>
                <th>{{'JETT.WEIGHT' | translate}}</th>
                <th colspan="2"> {{'JETT.OUTBOUND' | translate}} </th>
            </tr>
            <tr>
                <th rowspan="2"> {{'JETT.CHARGEABLE_WEIGHT' | translate}} </th>
                <th colspan="2"> {{'JETT.SELLING_PRICE' | translate}} </th>
            </tr>
            <tr>
                <th> {{'JETT.WITH_VAT' | translate}} </th>
                <th> {{'JETT.NO_VAT' | translate}} </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-price>
            <tr>
                <td>{{price.fromWeight}} - {{price.toWeight}}</td>
                <td>{{price.withVat}}</td>
                <td>{{price.noVat}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td>{{'JETT.EVERY' |translate}} {{outboundPrice.extraWeightValue}} {{'JETT.EXTRA_WEIGHT'|translate}}</td>
                <td>{{outboundPrice.extraWeightChargeWithVat}}</td>
                <td>{{outboundPrice.extraWeightCharge}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
