<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <p-sidebar position="{{currentLang === 'en' ? 'right' : 'left'}}" [(visible)]="isShow" (onHide)="onClose()" [dismissible]="false" styleClass="user-details-sidebar">
        <div class="main-user">
            <div class="header" [ngClass]="{'archived-header': isArchived}">
                <div class="header-title row">
                    <div class="title" *ngIf="!isUser">{{ 'USERS_MANAGEMENT.CUSTOMER_DETAILS' | translate }}</div>
                    <div class="title" *ngIf="isUser">{{ 'USERS_MANAGEMENT.USER_DETAILS' | translate }}</div>
                </div>
                <div class="header-actions">
                    <div class="user-image">
                        <app-upload-image-component *ngIf="user" [(url)]="user.imageUrl" [api]="userImageApi + '?userId=' + this.user.id"
                                                    [imageSize]="100"
                                                    [canUpload]="false"
                                                    [defaultLogo]="'../../../../assets/icons/user-avatar.svg'">

                        </app-upload-image-component>
                    </div>

                    <div class="user-details-actions row">
                        <div class="action update-action d-inline-flex" *ngIf="canEdit" (click)="onUpdate()">
                            <div class="icon" *ngIf="true">
                                <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
                            </div>
                            <div class="loading" *ngIf="false">
                                <i class="fas fa-spinner fa-pulse"></i>
                            </div>
                            <div class="action-hidden-space"></div>
                            <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.EDIT_INFO' | translate }}</div>
                        </div>
                        <div class="action print-action d-inline-flex" *ngIf="canResetPassword" (click)="resetPassword()">
                            <div class="icon" *ngIf="true">
                                <img src="../../assets/icons/padlock.svg" alt="" />
                            </div>
                            <div class="loading" *ngIf="false">
                                <i class="fas fa-spinner fa-pulse"></i>
                            </div>
                            <div class="action-hidden-space"></div>
                            <div class="label">{{ 'AUTH.CHANGE_PASSWORD' | translate }}</div>
                        </div>
                        <div class="action remove-action d-inline-flex" *ngIf="canRemove" (click)="onRemove()">
                            <div class="icon" *ngIf="!deleteLoader">
                                <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
                            </div>
                            <div class="loading" *ngIf="deleteLoader">
                                <i class="fas fa-spinner fa-pulse"></i>
                            </div>
                            <div class="action-hidden-space"></div>
                            <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.DELETE_ACCOUNT' | translate }}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="details-user">
                <div class="details-table" [ngClass]="{'user-details-table': isUser}">
                    <table>
                        <tr>
                            <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.FIRST_NAME' | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value">{{user?.firstName}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.FAMILY_NAME' | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value">{{user?.lastName}}</td>
                            <td></td>
                        </tr>
                        <tr *ngIf="!isUser">
                            <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.BUSINESS_NAME' | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value">{{user?.businessName}}</td>
                            <td></td>
                        </tr>
                        <tr *ngIf="!isUser">
                            <td class="td-label"><span>{{ (isLcl ? 'USERS_MANAGEMENT.ADD_USER_FORM.VAT_NUMBER' : 'USERS_MANAGEMENT.ADD_USER_FORM.GOV_REGISTRATION_NUMBER') | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value plate-no">{{user?.authorizedGovRegistrationNumber}}</td>
                            <td></td>
                        </tr>
                        <tr *ngIf="isUser">
                            <td class="td-label"><span>{{ 'USERS_MANAGEMENT.TABLE.BARCODE' | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value plate-no">{{user?.barcode}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label"><span>{{ (isLcl ? 'USERS_MANAGEMENT.ADD_USER_FORM.USER_CODE' : 'USERS_MANAGEMENT.ADD_USER_FORM.EMAIL') | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value user-name">{{user?.email}}</td>
                            <td></td>
                        </tr>
                        <tr *ngIf="!isUser">
                            <td class="td-label"><span>{{ ('USERS_MANAGEMENT.ADD_USER_FORM.OPTIONAL_NUMBER') | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value user-name">{{user?.optionalNumber}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.PHONE' | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value">
                                <div class="phone-number-container d-inline-flex">
                                    <div class="phone-number">{{user?.phone}}</div>
                                    <div class="phone-number-hidden-space"></div>
                                    <div class="phone-icon">
                                        <img src="../../assets/icons/phone-call-orange.svg" alt="icon" />
                                    </div>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ADDRESS' | translate }}</span></td>
                            <td class="td-space"></td>
                            <td class="td-value">{{getAddress(user?.address)}}</td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="user && !isUser && !isCustomerAccountManager() && user.customerFulfillmentType != 'FULFILLMENT'" class="toggle-btn-container">
                <div class="dates-table">
                    <table>
                        <tr *ngIf="showAddDraftPackagesSetting()">
                            <td>
                                <div class="toggle-btn">
                                    <p-inputSwitch [ngClass]="{'disabled-toggle-switch': !user.isAddDraftPackages}"
                                                   (onChange)="setCustomerConfiguration('ADD_DRAFT_PACKAGES', user.isAddDraftPackages)"
                                                   [(ngModel)]="user.isAddDraftPackages">
                                    </p-inputSwitch>
                                </div>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-toggle-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ADD_PACKAGES_DRAFTS' | translate }}</span></td>
                        </tr>
                        <tr *ngIf="showRequiredAttachmentsSetting()">
                            <td>
                                <div class="toggle-btn">
                                    <p-inputSwitch [ngClass]="{'disabled-toggle-switch': !user.isAllowNoAttachment}"
                                                   (onChange)="setCustomerConfiguration('ALLOW_LOGIN_WITHOUT_ATTACHMENT', user.isAllowNoAttachment)"
                                                   [(ngModel)]="user.isAllowNoAttachment">
                                    </p-inputSwitch>
                                </div>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-toggle-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ATTACHMENTS_REQUIRED' | translate }}</span></td>
                        </tr>
                        <tr *ngIf="!isLcl">
                            <td>
                                <div class="toggle-btn">
                                    <p-inputSwitch [ngClass]="{'disabled-toggle-switch': !user.isShowSenderAddressInPackageReport}"
                                                   (onChange)="setCustomerConfiguration('SHOW_SENDER_ADDRESS_IN_PACKAGE_PDF', user.isShowSenderAddressInPackageReport)"
                                                   [(ngModel)]="user.isShowSenderAddressInPackageReport">
                                    </p-inputSwitch>
                                </div>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-toggle-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.SHOW_SENDER_ADDRESS' | translate }}</span></td>
                        </tr>
                        <tr *ngIf="showIsBilledSwitchButton()">
                            <td>
                                <div class="toggle-btn">
                                    <p-inputSwitch [ngClass]="{'disabled-toggle-switch': !user.isBilled}"
                                                   (onChange)="setCustomerConfiguration('ENABLE_INVOICING', user.isBilled)"
                                                   [(ngModel)]="user.isBilled">
                                    </p-inputSwitch>
                                </div>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-toggle-label"><span>{{ 'MANAGE_COD_CUSTOMERS.INVOICES.ENABLE_BILLING_SYSTEM' | translate }}</span></td>
                        </tr>
                        <tr *ngIf="!isLcl">
                            <td>
                                <div class="toggle-btn">
                                    <p-inputSwitch [ngClass]="{'disabled-toggle-switch': !user.isHideCostForMembers}"
                                                   (onChange)="setCustomerConfiguration('HIDE_COST_FOR_MEMBERS', user.isHideCostForMembers)"
                                                   [(ngModel)]="user.isHideCostForMembers">
                                    </p-inputSwitch>
                                </div>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-toggle-label"><span>{{'USERS_MANAGEMENT.ADD_USER_FORM.HIDE_COST_FOR_MEMBERS' | translate }}</span></td>
                        </tr>

                        <tr>
                            <td>
                                <div class="toggle-btn">
                                    <p-inputSwitch [ngClass]="{'disabled-toggle-switch': !user.isShowSenderNameInPackageReport}"
                                                   (onChange)="setCustomerConfiguration('SHOW_SENDER_NAME_IN_PACKAGE_PDF', user.isShowSenderNameInPackageReport)"
                                                   [(ngModel)]="user.isShowSenderNameInPackageReport">
                                    </p-inputSwitch>
                                </div>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-toggle-label"><span>{{'USERS_MANAGEMENT.ADD_USER_FORM.IS_SHOW_SENDER_NAME_IN_PACKAGE_REPORT' | translate }}</span></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="role-and-hub-container" *ngIf="isUser">
                <table>
                    <tr>
                        <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.USER_ROLE' | translate }}</span></td>
                        <td class="role-and-hub-hidden-td"></td>
                        <td class="td-value">
                            <div class="tag role-name">
                                <span>{{user?.role | translate }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="user?.role != 'MULTIPLE_HUBS_MANAGER'">
                        <td class="td-label"><span>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.HUB' | translate }}</span></td>
                        <td class="role-and-hub-hidden-td"></td>
                        <td class="td-value">
                            <div class="tag hub-name">
                                <span>{{user?.hubName}}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </p-sidebar>
</div>
