<div id="submitting-container" *ngIf="isLoading">
    <p-progressSpinner>
    </p-progressSpinner>
</div>
<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="edit-template-container" style="overflow-x: hidden; height: 100%;">
    <div class="d-flex row" style="justify-content: space-between">
        <div style="flex: 1 ; max-width: 130px;" class="cod-title">
            {{'COMPANY.SMS'|translate}}
        </div>
        <div class="header-message-container" style="text-align: center; margin-top: 10px;">
            {{headerMessage | translate}}
            <p *ngIf="(this.currency !== 'NIS' && this.currency !== 'BHD')"><a href="https://platform.releans.com/">https://platform.releans.com/</a></p>
            <p *ngIf="this.currency === 'BHD'"><a href="https://gatewayapi.com/">https://gatewayapi.com</a></p>

        </div>

        <div class="action-button add-button cursor-pointer add-sms-setting-btn"
             (click)="addSettingsOfSMS()">
            <i class="fas fa-pencil-alt"></i>
            {{"COMPANY.ADD_SMS_SETTINGS_BUTTON" | translate}} {{title | translate}}
        </div>
    </div>
    <div class="row justify-content-center align-items-center"><span>{{('COMPANY.SMS_BALANCE' | translate) + SMSBalance}}</span></div>
    <div class="input-fields">
        <div class="form-container">
            <div class="row">
                <div class="col-12" style="margin-bottom: 20px;">
                    <div class="inputs row radio-container">
                        <div class="d-flex">
                            <div>
                                <p-radioButton [(ngModel)]="SMSType" [value]="'CUSTOMERS'" (ngModelChange)="getTemplate()"></p-radioButton>
                            </div>
                            <div style="width: 12px"></div>
                            <div><span class="type-radio-label">{{"COMPANY.SEND_MESSAGE_TO_CUSTOMERS" | translate}}</span></div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <p-radioButton [(ngModel)]="SMSType" [value]="'DRIVERS'" (ngModelChange)="getTemplate()"></p-radioButton>
                            </div>
                            <div style="width: 12px"></div>
                            <div>
                                <span class="type-radio-label">{{"COMPANY.SEND_MESSAGE_TO_RECEIVER_OF_DRIVER_PACKAGES" | translate | packageTitle}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="SMSType === 'DRIVERS'" class="col-6 col-lg-2  col-md-4 col-sm-6 col-xs-6" style="margin-bottom: 10px;">
                    <app-users-list-dropdown-component [isDriver]="true" [user]="driverId"
                                                       (userChanged)="onDriverSelected($event)">
                    </app-users-list-dropdown-component>
                </div>
                <div [ngClass]="{'customers-list-en': currentLang === 'en'}" *ngIf="SMSType === 'CUSTOMERS'" class="col-12 row" style=" min-width:400px;margin-right:14px!important;margin-bottom: 20px!important; justify-content: flex-start!important;">

                    <div class="row customers-list-container" style="margin-top: 7px; justify-content: flex-start">
                        <div style="width: 250px;" [ngClass]="{'disabled-button disabled': isSendToAllCustomer}">
                            <ng-multiselect-dropdown
                                    #customersEl
                                    placeholder="{{'ADMINISTRATION.CUSTOMER_LIST_DROPDOWN_PLACEHOLDER' | translate}}"
                                    (click)="onClickCustomersList()"
                                    [settings]="customerDropdownSettings"
                                    [data]="customers"
                                    [(ngModel)]="selectedCustomers"
                                    (onFilterChange)="filterCustomers(customersEl)">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="d-flex customers-list-chkbx">
                            <div>
                                <p-checkbox class="send-to-all-label" binary="true"
                                            [(ngModel)]="isSendToAllCustomer" (onChange)="onToggleSendToAll()"></p-checkbox>
                            </div>
                            <div style="width: 8px"></div>
                            <div>
                                {{'COMPANY.SEND_TO_ALL_CUSTOMERS' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="row">
                <div class="col-12" style="margin: auto">
                    <textarea #textArea [(ngModel)]="templateText" class="template-textarea" rows="4" style="width: 100%" placeholder="{{'COMPANY.SMS_TEXTAREA_PLACEHOLDER' | translate}}">
                    </textarea>
                    <div class="tags-container">
                        <div *ngFor="let siteTag of siteTags" class="site-tag-container cursor-pointer"
                             (click)="setTag(siteTag)">
                            {{siteTag}}
                        </div>
                    </div>
                </div>
            </div>
         <div class="row">
            <div class="row col-12">
                <div class="flex-1"></div>
                <div style="padding: 10px 30px">
                    <div style="font-size: 14px;" class="add-button cursor-pointer"
                         (click)="confirmSendSMS()"
                         [ngClass]="{'disabled-button disabled': isDisableSendSMSBtn()}">
                        <i class="fas fa-paper-plane"></i>
                        {{'ACTIONS.SUBMIT' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
