import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PricingModel} from '../../../pricings/pricing.model';
import {Inplace} from 'primeng/inplace';
import {Fieldset} from 'primeng/fieldset';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InputFieldComponent} from '../../../shared/components/input-field/input-field.component';
import {TranslateService} from '@ngx-translate/core';
import {PricingService} from '../../../shippings-rate/services/pricing.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContainersService} from '../../../containers/services/containers.service';
import {ServiceTypesService} from '../../../administration/services/service-types.service';
import {ZonesService} from '../../../administration/services/zones.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {UserService} from '../../../shared/services/user.service';
import {ServiceTypeModel} from '../../../administration/models/service-type.model';
import {OverlayPanel} from 'primeng/overlaypanel';
import {VehicleTypeModel} from '../../../shared/models/container.model';

import {CreateAdditionalFeeComponent} from '../create-additional-fee/create-additional-fee.component';
import {ChooseExistingComponent} from '../../../shipment/choose-existing/choose-existing.component';
import {UserModel} from '../../../shared/models/user.model';
import {AddCustomersListComponent} from '../../../shippings-rate/components/pricing-lists/add-customers-list/add-customers-list.component';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {QUERY_PARAMS_FILTERS} from '../../../shipment/services/storages/manage-shipments-storage.keys';
export enum AdditionalFeesType {
  INSURANCE = 'INSURANCE',
  COD = 'COD'
}
@Component({
  selector: 'app-additional-fees',
  templateUrl: './additional-fees.component.html',
  styleUrls: ['./additional-fees.component.scss']
})
export class AdditionalFeesComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {

  currentLang = 'en';
  isLoading = false;
  isUpdating = false;

  pricing: PricingModel[] = [];
  fetchedPricing;
  triggeredPricing: PricingModel;
  expandedRow: HTMLSpanElement;
  displayedInplace: Inplace;
  displayedInplaceValue;

  toggledFieldSet: Fieldset;

  serviceTypes: any[];
  serviceTypesNames: { [id: string]: string } = {};
  vehicleTypes: any[];
  searchContent = '';
  toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
  isReceivingPointEnabled = false;
  isShowNewPricing = false;
  isEnableExportingBetweenHubs = false;

  newPricingForm: FormGroup;
  editRegionForm: FormGroup;

  incompletePricingForm: FormGroup;
  uncompletedPricing: { [id: string]: AbstractControl };
  regionOptions: any;

  private newPricingOriginRegion: InputFieldComponent;
  typeId: number;
  bodyIdName: string;
  customerName: string;

  idQueryParam: string;
  typeName = 'COMPANY';
  calculateCostPerWeight = false;
  type = AdditionalFeesType.INSURANCE;
  feeTypeOptions = [];
  newRangeForm;
  showAddNewRange = false;
  totalRecords;

  @ViewChild('newPricingOriginRegion')
  set newPricingOriginRegionRef(ref: InputFieldComponent) {
    if (ref) {
      this.newPricingOriginRegion = ref;
    }
  }

  constructor(private translateService: TranslateService,
              private pricingService: PricingService,
              private confirmationService: ConfirmationService,
              private modalService: NgbModal,
              private messageService: MessageService,
              private containersService: ContainersService,
              private serviceTypesService: ServiceTypesService,
              private formBuilder: FormBuilder,
              private zonesService: ZonesService,
              private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
    this.isReceivingPointEnabled = this.userService.userInfo.isReceivingPointEnabled;
    this.calculateCostPerWeight = this.userService.userInfo.isEnablePricingPerkg;
    this.currentLang = this.translateService.currentLang;
    this.route.queryParams.subscribe((queryParams: {customerName: string, idQueryParam: string, type: string}) => {
      this.customerName = queryParams.customerName;
      this.idQueryParam = queryParams.idQueryParam;
      this.typeName = queryParams.type ? queryParams.type : 'COMPANY';
      this.bodyIdName = this.typeName === 'PRICING_LIST' ? 'entityId' : 'customerId';
    });
    this.addSubscription(this.route.params.subscribe(
        (params: Params) => {
          this.typeId = params.id ? JSON.parse(params.id) : null;
          this.initForms();
        }
    ));
    this.addSubscription(this.route.data.subscribe(value => {
      this.type = value.type;
      this.getPricing(true);
    }));
    this.initFeeTypeOptions();
    this.initNewRangeForm();
  }
  private initForms() {
    this.newPricingForm = this.formBuilder.group({
      name: [null, Validators.required],
    });
    this.editRegionForm = this.formBuilder.group({
      name: [],
      destinationRegion: []
    });
  }

  onSearch() {
    this.getPricing(true);
  }

  onNewPricing(event) {
    if (this.toggledFieldSet) {
      this.toggledFieldSet.collapse();
    }
    this.isShowNewPricing = true;
    this.changeDetectorRef.detectChanges();
    this.newPricingOriginRegion.scrollAndFocus();
  }

  onHideNewPricing() {
    this.newPricingForm.patchValue({});
    this.isShowNewPricing = false;
  }

  getPricing(forceFetch = false) {
    this.isLoading = true;
    this.pricingService.getAdditionalFees({type: this.type}).subscribe(response => {
      this.isLoading = false;
      this.fetchedPricing = response;
      if (!forceFetch) {
        this.pricing.push(...this.fetchedPricing);
      } else {
        this.pricing = this.fetchedPricing;
      }
      this.totalRecords = this.pricing.length;
    }, (error) => {
      this.isLoading = false;
    });
  }
  setExpandedRow(newRow: HTMLSpanElement) {

    if (this.expandedRow === newRow) {
      this.expandedRow = null;
      return;
    }

    if (this.expandedRow) {
      this.expandedRow.click();
    }
    this.showAddNewRange = false;
    this.newRangeForm.reset();

    this.expandedRow = newRow;
  }

  showAddAdditionalFee(event: MouseEvent, pricing) {
    this.showAddNewRange = true;
  }
  onActivateInplace(event, inplace: Inplace, valueToBeEdited) {
    this.displayedInplaceValue = valueToBeEdited;

    if (this.displayedInplace && this.displayedInplace !== inplace) {
      this.displayedInplace.deactivate(event);
    }

    this.displayedInplace = inplace;
  }

  toggleInplace(event: any, inplace: Inplace, pricing?: PricingModel, pricingField?: string, saveChanges?, isFee?) {
    if (inplace.active) {
      if (saveChanges) {
        this.updatePricing(pricing, inplace, pricingField, isFee);
      } else {
        if (pricing) {
          this.displayedInplaceValue = pricing[pricingField];
        }
        inplace.deactivate(event);
      }
    } else {
      inplace.activate(event);
    }
  }


  onDeletePricing(pricing: any) {
    this.translateService.get(['PRICING.ALERTS.CONFIRM_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
      this.confirmationService.confirm({
        acceptLabel: values['GENERAL.YES'],
        rejectLabel: values['GENERAL.NO'],
        message: values['PRICING.ALERTS.CONFIRM_DELETE'],
        accept: () => {

        }
      });
    });
  }

  onSavePricing(pricing?) {
    this.isLoading = true;
    let reqBody = {};
    if (pricing) {
      reqBody = {
        name: pricing.name,
        type: this.type
      };
    } else {
      const formData = this.newPricingForm.getRawValue();
      reqBody = {
        name: formData.name,
        type: this.type
      };
    }

    this.pricingService.saveNewAdditionFee(reqBody).subscribe((res) => {
      this.messageService.add({
        severity: 'success',
        detail: this.translateService.instant('PRICING.ALERTS.ADDED_SUCCESSFULLY')
      });
      this.onHideNewPricing();
      this.getPricing(true);
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
  }
  private updatePricing(pricing, inplace: Inplace, pricingField: string, isFee?) {
    this.isUpdating = true;
    const reqBody = {
      ...pricing,
      type: this.type,
    };
    reqBody[pricingField] = this.displayedInplaceValue;
    if (!isFee && reqBody.calculationType === 'FIXED') {
      reqBody.value = reqBody.percentage;
    }
    (isFee ? this.pricingService.editAdditionFees(reqBody, pricing.id) : this.pricingService.editAdditionFeesDetail(reqBody, pricing.id)).subscribe(response => {
      this.translateService.get('PRICING.ALERTS.UPDATE_SUCCESSFULLY').subscribe(value => {
        this.messageService.add({
          severity: 'success',
          detail: value
        });
      });
      this.isUpdating = false;
      pricing[pricingField] = this.displayedInplaceValue;
      if (!isFee && reqBody.calculationType === 'FIXED') {
        pricing.value = reqBody.percentage;
      }
      inplace.deactivate(event);
    }, (error) => {
      this.isUpdating = false;
    });
  }
  private deleteVehicleForm(pricing: PricingModel, serviceTypeId: string, vehicleIndex: number) {
    const vehiclePricingArray = this.incompletePricingForm
        .get(pricing.id.toString())
        .get(serviceTypeId)
        .get('vehicleTypesPricing') as FormArray;

    vehiclePricingArray.removeAt(vehicleIndex);
  }

  onCustomerCustomizePricing(event: MouseEvent) {
    const modal = this.modalService.open(ChooseExistingComponent, {backdrop: 'static', windowClass: 'choose-customer', size: 'lg'});
    modal.result.then(
        (data: { isSuccess: boolean, user: UserModel }) => {
          if (data.isSuccess) {
            const user = data.user;
            this.router.navigate([this.authenticationService.companyName + '/home/' + 'pricing-service-types/' + user.id], {
              queryParams:
                  {
                    customerName: user.fullName + (user.businessName ? ` (${user.businessName})` : ''),
                    idQueryParam: 'customer-id',
                    type: 'CUSTOMER'
                  }
            });
          }
        }
    ).catch(
        (error) => {
        }
    );
  }

  showCustomers(user: any) {
    const modal = this.modalService.open(AddCustomersListComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.additionFeeId = user.id;
    modal.componentInstance.isAdditionFees = true;
  }

  private initFeeTypeOptions() {
    this.feeTypeOptions = [
      {
        label: this.translateService.instant('ADDITION_FEES.FIXED'),
        value: 'FIXED'

      },
      {
        label: this.translateService.instant('ADDITION_FEES.PERCENTAGE'),
        value: 'PERCENTAGE'
      }
    ];
  }

  private initNewRangeForm() {
    this.newRangeForm = this.formBuilder.group({
      minCod: [null, [Validators.required, Validators.min(0)]],
      maxCod: [null, [Validators.required, Validators.min(0)]],
      calculationType: [null, [Validators.required, Validators.min(0)]],
      percentage: [null],
    });
  }

  onSubmitAddRange(pricing: any) {
    const reqBody = this.newRangeForm.getRawValue();
    if (reqBody.calculationType === 'FIXED') {
      reqBody.value = reqBody.percentage;
    }
    this.pricingService.saveAdditionalFeeRange(pricing.id, reqBody).subscribe(response => {
      this.translateService.get('PRICING.ALERTS.ADDED_SUCCESSFULLY').subscribe(value => {
        this.messageService.add({
          severity: 'success',
          detail: value
        });
        this.showAddNewRange = false;
        this.newRangeForm.reset();
        this.getPricing(true);
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  handleValidation($event: any, newRangeForm) {
    if ($event.value === 'PERCENTAGE') {
      newRangeForm.get('percentage').setValidators([Validators.max(100)]);
    } else {
      newRangeForm.get('percentage').setValidators([]);
    }
  }
}

