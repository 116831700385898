import {Component, Input, Output, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {UtilitiesService} from '../../services/utilities.service';

@Component({
    selector: 'app-upload-image-component',
    templateUrl: 'upload-image.component.html',
    styleUrls: ['upload-image.component.scss']
})
export class UploadImageComponent {
    @Input() api;
    @Input() url;
    @Input() defaultLogo;
    @Input() responsePathKey;
    @Input() showClear = true;
    @Input() canUpload = true;
    @Input() imageSize = 120;
    @Input() imageWidth;
    @Input() imageHeight;
    @Input() imageShape = 'CIRCLE';
    @Input() imageType;
    @Output() urlChange: EventEmitter<any> = new EventEmitter();
    @Output() fileChange: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient,
                private messageService: MessageService,
                private utilitiesService: UtilitiesService) {
    }

    addImage(event: Event, fileChooser) {
        if (this.canUpload) {
            event.stopPropagation();
            fileChooser.click();
        }
    }

    public onFileSelected($event: any) {
        const formData = new FormData();
        const selectedFile = $event.target.files[0];
        if (!selectedFile) {
            return;
        }
        formData.append('file', selectedFile);
        if (selectedFile.name.toLowerCase().match(/.(jpg|jpeg|png|gif|ico)$/i)) {
            if (this.api) {
                this.http.post(this.api, formData, {params: {companyImageType: this.imageType}}).subscribe((res: any) => {
                    if (this.responsePathKey) {
                        this.url = res[this.responsePathKey] + this.utilitiesService.getImageRandomReload();
                        this.urlChange.emit(res[this.responsePathKey]);
                    } else {
                        this.url = res.logo_path + this.utilitiesService.getImageRandomReload();
                        this.urlChange.emit(res.logo_path ? res.logo_path : (res.imageUrl ? res.imageUrl : null));
                    }
                }, error => {
                    console.log(error);
                });
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onload = () => {
                    this.url = reader.result;
                };
                this.fileChange.emit(formData);
            }

        } else {
            this.messageService.add({severity: 'error', detail: 'unsupported file format'});
        }
    }
    onClear(event: Event) {
        event.stopPropagation();
        const formData = new FormData();
        formData.append('file', null);
        this.http.post(this.api, formData, {params: {companyImageType: this.imageType}}).subscribe((res: any) => {
            this.url = null;
        }, error => {
            console.error(error);
        });
    }
}
