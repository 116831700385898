import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-custom-confirmation-message',
    templateUrl: './custom-confirmation-message.component.html',
    styleUrls: ['./custom-confirmation-message.component.scss']
})
export class CustomConfirmationMessageComponent implements OnInit {

    @Input() title: string;
    @Input() confirmMessage: string;
    @Input() confirmButtonLabel: string;
    @Input() switchButtonLabel = '';
    @Input() styleClass = '';

    currentLang: string;
    switchButton = false;


    constructor(private activeModal: NgbActiveModal, private translateService: TranslateService) { }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }

    public closeModal(confirm: boolean) {
        const result = {confirm: confirm};
        if (this.switchButtonLabel.length) {
            result['switchButtonValue'] = this.switchButton;
        }
        this.activeModal.close(result);
    }
}
