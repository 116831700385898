<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="d-flex flex-column h-100" [ngClass]="{'page-ltr': selectedLanguage === 'en'}">

    <p-overlayPanel #filtersFormOverLay
                    class="filters-menu-panel"
                    [dismissable]="true"
                    (onHide)="filterFormOn = false"
                    [showCloseIcon]="false">
        <div class="filters-menu-container">
            <div class="filters-header">
                <div class="filters-menu-title">
                    <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                </div>
            </div>
            <div class="filters-inputs-group d-flex flex-column">
                <div *ngIf="receiveWithoutReleasingCustody" class="filter-item w-100">
                    <div class="filter-label">{{'CONTAINERS_MANAGEMENT.ORDER_BY' | translate}}</div>
                    <div class="filter-value w-100">
                        <p-dropdown [options]="custodyTypeList"
                                    [(ngModel)]="optionCustody"
                                    (onChange)="onCustodyTypeChange($event)"
                                    optionLabel='label'
                                    filter="true">
                            <ng-template pTemplate="item" let-item>
                                {{item.label | translate}}
                            </ng-template>
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.label | translate}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>


                <div class="filter-item w-100">
                    <div class="filter-label">{{'CUSTOMER_TYPES.CUSTOMER_TYPE' | translate}}</div>
                    <div class="filter-value w-100">
                        <p-dropdown [options]="customerTypes"
                                    [(ngModel)]="customerType"
                                    optionLabel='label'>
                            <ng-template pTemplate="item" let-item>
                                {{item.label | translate}}
                            </ng-template>
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.label | translate}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <div *ngIf="!receive || receiveWithoutReleasingCustody" class="filter-item w-100">
                    <div class="filter-label">{{('MANAGE_COD_CUSTOMERS.' + optionCustody.value + '_NAME') | translate}}</div>
                    <div class="filter-value w-100">
                        <app-users-list-dropdown-component
                            #selectedEntityComponent
                            [isDriver]="optionCustody.value==='DRIVER'"
                            [isHub]="optionCustody.value==='HUB'"
                            [isPartner]="optionCustody.value==='PARTNER'"
                            [placeHolder]="' '"
                            (userChanged)="onFilterSelectedChange($event)"
                            [isDisabled]="optionCustody.value === ''"
                            [selectedUser]="selectedEntity"
                        ></app-users-list-dropdown-component>
                    </div>
                </div>
                <div class="filter-item w-100">
                    <div class="filter-label">{{'PRICING.TOTAL_REPORT_GREATER_THAN' | translate}}</div>
                    <div class="filter-value w-100">
                        <input type="number" pInputText
                               [(ngModel)]="totalGreaterThan">
                    </div>
                </div>
                <div class="filter-item w-100">
                    <div class="filter-item w-100">
                        <div class="filter-label">{{'PRICING.TOTAL_REPORT_LESS_THAN' | translate}}</div>
                        <div class="filter-value w-100">
                            <input type="number" pInputText
                                   [(ngModel)]="totalLessThan">
                        </div>
                    </div>
                </div>
            </div>
            <div class="filters-menu-actions">
                <div class="reset-action filter-menu-action" (click)="resetFilters()">
                    <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                </div>
                <div class="apply-filters-action filter-menu-action" (click)="onSearch()">
                    <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                </div>
            </div>
        </div>
    </p-overlayPanel>

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div>

        <div class="header d-flex justify-content-between">
            <div class="header-title page-header-title">
                <span *ngIf="receiveWithoutReleasingCustody">
                    <i (click)="returnFromCustody()" class="fas fa-arrow-right cursor-pointer"></i>
                </span>
                <div class="title">
                   {{(sort ? "ACCOUNTING.SORT_COD" : (receiveWithoutReleasingCustody ? "ACCOUNTING.RECEIVE_WITHOUT_RELEASING_CUSTODY" : "ACCOUNTING.RECEIVE_COD_MENUITEM"))  | translate}}
                </div>
            </div>

            <div class="filters d-flex align-items-center">
                <div class="dropdown-filters-container">
                    <ng-container *appPermission="permissions.includes('FIND_PACKAGE_BY_BARCODE')">
                        <div *ngIf="receive"
                             class="package-history" tooltipPosition="top"
                             pTooltip="{{'SHIPMENT.FIND_PACKAGE_BY_BARCODE_TOOLTIP'| translate}}"
                             [ngClass]="{'padding-left-40': currentLanguage === 'en'}"
                             (click)="findPackageByBarcode()">
                            <div class="">
                                <img style="width: 30px ;height: 30px" src="../../../assets/icons/find-package.png">
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *appPermission="permissions.includes('MOB')">
                        <div *ngIf="receive && mobAvailable"
                             class="action-button add-button"
                             (click)="mob()">
                            {{"ACCOUNTING.MOB" | translate}}
                        </div>
                    </ng-container>

                    <div *ngIf="userRole !== 'DISPATCHER' && receive && isSupportReceiveWithoutReleasingCustody && !receiveWithoutReleasingCustody"
                         class="action-button add-button"
                         (click)="onCustodyTypeChange(null)">
                        {{"ACCOUNTING.RECEIVE_WITHOUT_RELEASING_CUSTODY" | translate}}
                    </div>

                    <div *ngIf="receive && !receiveWithoutReleasingCustody"
                         class="receive-btn btn-sm"
                         (click)="receivePackages()">
                        <span>
                            {{"ACCOUNTING.RECEIVE" | translate}}
                        </span>
                    </div>
                    <div
                            *ngIf="receive && permissions.includes('SORT_ALL')"
                            class="receive-btn btn-sm"
                            [ngClass]="{'disabled-btn disabled': (!isThereSelection ), 'sort-button': receive}"
                            (click)="sortPackage(customers, $event)">
                        {{"ACCOUNTING.SORT" | translate}}
                    </div>
                    <div *ngIf="sort && !mobAvailable"
                         class="action-button add-button"
                         (click)="sortPackages()">
                        <i class="fas fa-check color-white"></i>
                        {{"ACCOUNTING.SORT" | translate}}
                    </div>

                    <div *ngIf="(sort) && (permissions.includes('EXPORT_SELECTED'))"
                         class="excel-button"
                         (click)="openExcel()">
                        {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                    </div>

                    <div *ngIf="receive && (permissions.includes('EXPORT_COD_TO_EXCEL'))" class="excel-button btn-small"
                         (click)="exportReceivedCodToExcel()">
                        <i class="fas fa-file-excel"></i>
                        {{"ACCOUNTING.EXPORT" | translate}}
                    </div>

                    <ng-container *appPermission="permissions.includes('EXPORT_SELECTED')">
                        <div *ngIf="sort"
                             class="action-button secondary-action-btn"
                             (click)="exportPackage(customers,$event)"
                             [ngClass]="{'disabled-button disabled': !isThereSelection}">
                            {{"ACCOUNTING.EXPORT_ALL" | translate}}
                        </div>
                    </ng-container>

                    <div *ngIf="(sort && !receiveWithoutReleasingCustody && receive && excludeSort && isOperationManger) || (sort && receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER')"
                         class="action-button add-button"
                         (click)="sortPackage(customers, $event)"
                         [ngClass]="{'disabled-button disabled': !isThereSelection}">
                        {{"ACCOUNTING.EXPORT_ALL_NEW" | translate}}
                    </div>
                    <div class="filter-separator"></div>
                    <div class="toggle-filters-form-btn filter">
                        <div class="icon" (click)="showFiltersMenu($event)">
                            <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                            <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                        </div>
                    </div>
                    <div *ngIf="(receive && !receiveWithoutReleasingCustody) || sort"
                         class="date-label position-relative">
                        <img class="sort-type-icon" src="../../../assets/icons/manage_shipments/sender_receiver.svg"
                             alt=""/>
                        <p-dropdown styleClass="date-filter-dropdown"
                                    [(ngModel)]="selectedDateFilter"
                                    [options]="dateFiltersList" filter="true"
                                    [dropdownIcon]="'pi pi-caret-down'"
                                    (onChange)="onDateFilterChange()">
                        </p-dropdown>
                    </div>
                    <div class="date-filter">

<!--                        <div class="date-picker-filter">-->
<!--                            <img src="../../assets/icons/calendar.svg" (click)="datepicker.click()" alt="icon"/>-->
<!--                            <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                                   #datepicker-->
<!--                                   daterangepicker [options]="dateRangPickerOptions"-->
<!--                                   (selected)="onDateSelected($event)"/>-->
<!--                            <i class="fas fa-caret-down"></i>-->
<!--                        </div>-->
                        <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                    </div>
                    <div class="search-container" *ngIf="showSearchContainer">
                        <img src="../../assets/icons/general/search.svg" alt="icon"/>
                        <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                               [(ngModel)]="searchContent"
                               appInputDebounced
                               (inputDebounced)="onSearch()">
                        <i class="fas fa-times cursor-pointer" (click)="closeSearchField()"></i>

                    </div>

                    <div class="toggle-search-container" *ngIf="!showSearchContainer"
                         (click)="showSearchContainer=!showSearchContainer">
                        <img src="../../assets/icons/general/search.svg" alt="icon"/>

                    </div>
                </div>

            </div>
        </div>

        <div class="mobile-header-actions hidden-md-up">
            <ng-container *appPermission="permissions.includes('FIND_PACKAGE_BY_BARCODE')">
                <div *ngIf="receive"
                     class="package-history" tooltipPosition="top"
                     pTooltip="{{'SHIPMENT.FIND_PACKAGE_BY_BARCODE_TOOLTIP'| translate}}"
                     (click)="findPackageByBarcode()">
                    <div class="">
                        <img style="width: 30px ;height: 30px" src="../../../assets/icons/find-package.png">
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="header-actions hidden-sm-down hidden-sm-up">
            <div *appPermission="permissions.includes('PRINT_DRIVER_REPORTS')"
                 class="action-button add-button"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                 (click)="printDriverMassReports()">
                <i class="fas fa-print"></i>
                {{"ACCOUNTING.PRINT_DRIVER_REPORTS" | translate}}
            </div>

            <ng-container *appPermission="permissions.includes('FIND_PACKAGE_BY_BARCODE')">
                <div *ngIf="receive"
                     class="package-history" tooltipPosition="top"
                     pTooltip="{{'SHIPMENT.FIND_PACKAGE_BY_BARCODE_TOOLTIP'| translate}}"
                     [ngClass]="{'padding-left-40': currentLanguage === 'en'}"
                     (click)="findPackageByBarcode()">
                    <div class="">
                        <img style="width: 30px ;height: 30px" src="../../../assets/icons/find-package.png">
                    </div>
                </div>
            </ng-container>
            <ng-container *appPermission="permissions.includes('MOB')">
                <div *ngIf="receive && mobAvailable"
                     class="action-button add-button"
                     (click)="mob()">
                    {{"ACCOUNTING.MOB" | translate}}
                </div>
            </ng-container>

            <div *ngIf="!status && !receiveWithoutReleasingCustody" class="action-button"
                 (click)="exportToPdf()">
                <div class="pdf-button">
                    <i class="fas fa-file-pdf"></i>
                    {{"ACTIONS.EXPORT_TO_PDF" | translate}}
                </div>
            </div>

            <div *ngIf="!status && !receiveWithoutReleasingCustody" class="action-button"
                 (click)="exportCodToExcel()">
                <div class="pdf-button">
                    <i class="fas fa-file-excel"></i>
                    {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                </div>
            </div>


            <div *ngIf="(!receiveWithoutReleasingCustody && receive && excludeSort && isOperationManger) || (receiveWithoutReleasingCustody && optionCustody.value === 'CUSTOMER')"
                 class="action-button add-button"
                 (click)="sortPackage(customers, $event)"
                 [ngClass]="{'disabled-button disabled': !isThereSelection}">
                <i class="fas fa-pencil-alt"></i>
                {{"ACCOUNTING.SORT_ALL" | translate}}
                44444
            </div>

            <div *appPermission="permissions.includes('EXPORT_ALL')"
                 class="action-button add-button"
                 (click)="exportPackage(customers,$event)"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                <i class="fas fa-pencil-alt"></i>
                {{"ACCOUNTING.EXPORT_ALL" | translate}}
            </div>

            <div *appPermission="permissions.includes('CHANGE_STATUS_TO_EXPORTED')"
                 class="action-button add-button"
                 (click)="changeStatusToExported(customers)"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE'}">
                <i class="fas fa-pencil-alt"></i>
                {{"ACCOUNTING.CHANGE_STATUS_TO_EXPORTED" | translate}}
            </div>

            <div *ngIf="receive && isSupportReceiveWithoutReleasingCustody && !receiveWithoutReleasingCustody"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                 class="action-button add-button"
                 (click)="onCustodyTypeChange(null)">
                {{"ACCOUNTING.RECEIVE_WITHOUT_RELEASING_CUSTODY" | translate}}
            </div>

            <div *ngIf="receive && !receiveWithoutReleasingCustody"
                 [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                 class="action-button add-button"
                 (click)="receivePackages()">
                <i class="fas fa-check"></i>
                {{"ACCOUNTING.RECEIVE" | translate}}
            </div>

            <div *ngIf="sort" class="action-button add-button"
                 (click)="pickWithDriver()"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                <i class="fas fa-truck"></i>
                {{"ACCOUNTING.PICK_WITH_DRIVER" | translate}}
            </div>

            <div *ngIf="sort " class="action-button add-button"
                 (click)="deliverMultiplePackages()"
                 [ngClass]="{'disabled-button disabled': !isThereSelection || selectedDateFilter !== 'NONE' }">
                <i class="fas fa-check"></i>
                {{("ACCOUNTING.DELIVER") | translate}}
            </div>


            <div *ngIf="sort && !mobAvailable"
                 class="action-button add-button"
                 [ngClass]="{'disabled-btn disabled': selectedDateFilter !== 'NONE'}"
                 (click)="sortPackages()">
                <i class="fas fa-check"></i>
                {{"ACCOUNTING.SORT" | translate}}
            </div>
        </div>

    </div>
    <div class="cod-customers-table flex-1 overflow-auto hidden-sm-down">
        <p-table #customersTable appPTableRtlFix dataKey="id" #table [value]="customers"
                 paginatorDropdownAppendTo="body"
                 styleClass="customers-table flex-table" (onPage)="setPaginatorQueryParam()"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadCustomersLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th
                            style="width: 4%">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                    (onChange)="onToggleSelectAll()"></p-checkbox>
                    </th>
                    <th>
                        <div>
                            {{(receiveWithoutReleasingCustody ? "MANAGE_COD_CUSTOMERS." + optionCustody.value + "_NAME" : "MANAGE_COD_CUSTOMERS.CUSTOMER_NAME") | translate}}
                        </div>
                    </th>
                    <th *ngIf="sort" style="width: 150px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                    <th>{{(receiveWithoutReleasingCustody ? "ACCOUNTING.ADDRESS" : "ACCOUNTING.CUSTOMER_ADDRESS")| translate }}</th>
                    <th>
                        <app-users-list-dropdown-component [isHub]="true" [user]="customerHubId"
                                                           #hubFilter
                                                           label="ACCOUNTING.CUSTOMER_HUB"
                                                           [showLabel]="true"
                                                           [isFilter]="true"
                                                           [panelWidth]="'100%'"
                                                           (userChanged)="onChangeHubMethod($event)"></app-users-list-dropdown-component>

                    </th>
                    <th *ngIf="receive || sort">
                        <app-drop-down [(ngModel)]="paymentMethod" optionLabel="label"
                                       [autoDisplayFirst]="true"
                                       [label]="'ACCOUNTING.PAYMENT_TYPE'"
                                       [showClear]="false"
                                       [options]="paymentsMethod"
                                       [isFilter]="true"
                                       (whenChange)="onChangePaymentMethod()">
                        </app-drop-down>
                    </th>
                    <th>{{"MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST" | translate}}</th>
                    <th>
                        {{"MANAGE_COD_CUSTOMERS.COST_SUM" | translate}}
                    </th>
                    <th (click)="sortByCodSum('COD_SUM')" class="cursor-pointer">
                        <div class="d-flex align-items-center">
                            <span>{{"MANAGE_COD_CUSTOMERS.COD_SUM" | translate}}</span>
                            <i *ngIf="receive && !sort" class="sort-icon fas {{sortType === 'ASC' ? 'fa-sort-asc' : sortType === 'DESC' ? 'fa-sort-desc' : 'fa-sort'}}"></i>
                        </div>
                    </th>
                    <th *ngIf="isEnableVat"> {{"MANAGE_COD_CUSTOMERS.MASS_INVOICES.VAT_VALUE" | translate}}</th>
                    <th *ngIf="isEnableVat"> {{"MANAGE_COD_CUSTOMERS.MASS_INVOICES.NET_COD" | translate}}</th>
                    <th *ngIf="showCodFees"> {{"ACCOUNTING.COD_FEES" | translate}}</th>

                    <th *ngIf="receive">{{'ACCOUNTING.NUMBER_OF_PACKAGES' | translate}}</th>
                    <th *ngIf="sort">{{"ACCOUNTING.SORTED_COD_PACKAGES" | translate}}</th>
                    <th class="icon-cell">{{"ACCOUNTING.PROFIT_PERCENTAGE" | translate}}</th>


                    <th *ngIf="sort">{{"ACCOUNTING.EXPORT" | translate}}</th>
                    <th *ngIf="receive && permissions.includes('SORT_COD')"
                        class="icon-cell">{{"ACCOUNTING.SORT" | translate}}</th>

                    <th *ngIf="receive && permissions.includes('EXPORT_TO_EXCEL')" class="icon-cell"> {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-expanded="expanded">
                <tr class="cursor-pointer" (click)="goToCustomerCOD(customer)">
                    <td style="width: 4%">
                        <p-checkbox [(ngModel)]="customer.isSelected" binary="true"
                                    (onChange)="onToggleSelection()" (click)="stopProp($event)"
                        ></p-checkbox>
                    </td>
                    <td>
                        <div class="d-flex align-items-center font-weight-bold text-align-start">
                            <div class="customer-image ml-3 mr-3"
                                 style="{{'background-image:url(' + '../../assets/icons/taxi-driver.png' + ')'}}"></div>
                            {{(receiveWithoutReleasingCustody ? customer.name : customer.customerName)}}
                        </div>
                    </td>
                    <td *ngIf="sort" style="width: 150px; text-align: center">
                        <div class="barcode-image-container flex-2d-center">
                            <div>
                                <span class="hover-display-parent">
                            <span>{{sort ? customer.barcode : customer.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display"
                               (click)="copyText(customer.barcode);$event.stopPropagation()"></i>
                        </span>
                            </div>
                        </div>
                    </td>
                    <td> {{customer.city ? customer.city : customer.customerCity }}</td>
                    <td> {{customer.customerHubName}} </td>
                    <td *ngIf="receive || sort">
                        {{customer.paymentType|translate}}
                    </td>
                    <td> {{customer.totalCodWithoutCost | number : digitFormat}}</td>
                    <td *ngIf="receive"> {{customer.costSum | number : digitFormat}}</td>
                    <td *ngIf="sort"> {{customer.totalCost | number : digitFormat}}</td>
                    <td *ngIf="receive"> {{customer.totalReceivedCod | number : digitFormat}}</td>
                    <td *ngIf="sort"> {{customer.totalCod | number : digitFormat}}</td>
                    <td *ngIf="isEnableVat"> {{customer.vatSum | number : digitFormat}}</td>
                    <td *ngIf="isEnableVat"> {{customer.netSum | number : digitFormat}}</td>
                    <td *ngIf="showCodFees">{{customer.codFees}}</td>
                    <td *ngIf="receive"> {{customer.receivedCodPackagesNumber | number : digitFormat}}</td>
                    <td *ngIf="sort"> {{customer.codPackagesNumber | number : digitFormat}}</td>
                    <td class="profit-percentage-cell">
                        <div class="table-row-actions-container">
                            <p-progressBar [showValue]='false'
                                           value="{{((customer.totalCodWithoutCost/(sort? customer.totalCod : customer.totalReceivedCod))*100 | number: '1.0-0')}}"
                                           class="pl-1 pr-1"></p-progressBar>
                            <span class="value">
                                {{
                                ((sort ? customer.totalCod : customer.totalReceivedCod) === 0 ?
                                    0 :
                                    ((customer.totalCodWithoutCost / (sort? customer.totalCod : customer.totalReceivedCod)) * 100)) | number: '1.0-0'
                                }}
                                %
                            </span>
                        </div>
                    </td>
                    <td *ngIf="sort">
                        <div
                                class="table-row-actions-container"
                                (click)="exportPackage(customer,$event)">
                            <div [ngClass]="{'disabled-button disabled': selectedDateFilter !== 'NONE'}"
                                 class="cursor-pointer">
                                <i class="fa fas fal fa-file-alt fa-2x"></i>
                            </div>
                        </div>
                    </td>


                    <td *ngIf="receive && permissions.includes('SORT_COD')"
                        class="icon-cell">
                        <div class="table-row-actions-container"
                             (click)="sortPackage(customer,$event)">
                            <div class="cursor-pointer"
                                 [ngClass]="{'disabled-button disabled': (selectedDateFilter !== 'NONE' && !receiveWithoutReleasingCustody)}">
                                <div class="ml-3 mr-3"
                                     style="width: 30px;height: 30px;background-image:url(../../assets/icons/conveyor-svgrepo-com.svg)"></div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="receive && permissions.includes('EXPORT_TO_EXCEL')" class="icon-cell">
                        <div class="table-row-actions-container">
                            <div class="edit-action cursor-pointer"
                                 (click)="exportToExcel(customer,$event, receive)">
                                <i class="fas fa-file-excel"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <!--    <div class="mobile-cards-list hidden-md-up">-->
    <!--        <app-mobile-cards-list [list]="customers" [keysObj]="getMobileKeys()"></app-mobile-cards-list>-->
    <!--        <div class="paginator-container">-->
    <!--            <p-paginator-->
    <!--                #paginator-->
    <!--                [rows]="12"-->
    <!--                [totalRecords]="totalRecords"-->
    <!--                (onPageChange)="onPaginate($event.page)"></p-paginator>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="bottom-values">
        <div *ngIf="(sort || receive)" class="cod-title">
            <b>{{totalRecords | number : digitFormat}}</b>
            <br>
            {{(receiveWithoutReleasingCustody ? "ACCOUNTING.TOTAL_" + optionCustody.value + "S" : "ACCOUNTING.TOTAL_CUSTOMERS") | translate}}
            :
        </div>
        <div *ngIf="!receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalPackages | number : digitFormat}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_PACKAGES" | translate}}
        </div>

        <div *ngIf="(receive || sort ) && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalCostSum | number : digitFormat | currencyValue}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_COST_SUM" | translate}}
        </div>
        <div *ngIf="(receive || sort) && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalCodWithoutCost | number : digitFormat | currencyValue}}</b>
            <br>
            {{"MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST" | translate}}
        </div>
        <div *ngIf="(sort || receive) && !receiveWithoutReleasingCustody" class="cod-title">
            <b>{{totalCod | number : digitFormat | currencyValue}}</b>
            <br>
            {{"ACCOUNTING.TOTAL_COD" | translate}}
        </div>
        <ng-container *appPermission="permissions.includes('RECEIVED_MONEY_AMOUNT')">
            <div *ngIf="receive && mobAvailable && !receiveWithoutReleasingCustody"
                 class="cod-title">
                <b>{{receivedMoney | number : digitFormat | currencyValue}}</b>
                <br>
                {{"BANK_TRANSFERS.RECEIVED_MONEY_AMOUNT" | translate}}
            </div>
        </ng-container>
        <ng-container *appPermission="permissions.includes('TOTAL_MOB_MONEY')">
            <div *ngIf="receive && mobAvailable && !receiveWithoutReleasingCustody"
                 class="cod-title">
                <b>{{mobReceivedMoney | number : digitFormat | currencyValue}}</b>
                <br>
                {{"BANK_TRANSFERS.TOTAL_MOB_MONEY" | translate}}
            </div>
        </ng-container>
        <div *ngIf="isSupportReceiveWithoutReleasingCustody && (sort)"
             class="cod-title">
            <b>{{totalCostWithoutReceiveCustody | number : digitFormat | currencyValue}}</b>
            <br>
            {{"BANK_TRANSFERS.TOTAL_RECEIVE_WITHOUT_CUSTODY" | translate}}
        </div>

        <div *ngIf="showCodFees" class="cod-title">
            <b>{{totalCODFees | number : digitFormat | currencyValue}}</b>
            <br>
            {{"ACCOUNTING.COD_FEES_SUM" | translate}}
        </div>
    </div>
</div>
