<p-toast></p-toast>
<div class="manage-lines-container existing-line-container">
  <div class="lines-dropdown">
    <p-dropdown styleClass="shipping-lines-dropdown" [style]="{'width':'100%'}"
                [(ngModel)]="selectedWorkZoneLine" optionLabel="name"
                [panelStyle]="{}" [options]="workZoneLines" filter="true"
                (onChange)="onLineSelected()">
    </p-dropdown>
  </div>
    <div class="add-button cursor-pointer" (click)="editLine()">
      <i class="fas fa-plus"></i>
      {{"SHIPPING_LINES.EDIT_WORK_ZONE" | translate }}
    </div>

    <div class="add-button cursor-pointer" (click)="addNewLineButton()">
      <i class="fas fa-plus"></i>
      {{"SHIPPING_LINES.NEW_WORK_ZONE" | translate }}
    </div>
  <div class="col-lg-3 col-md-2 col-sm-6 col-12"></div>
</div>
<div class="row zones-container">
  <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
    <app-zones [dataURL]="selectedVillagesUrl" [selectedItemsList]="selectedVillages"
               (onItemChecked)="onVillageChecked($event)" [showArabic]="false"
               [title]="'SHIPPING_LINES.SELECTED_VILLAGES'" [allowEdit]="false"
               [allowAssign]="true" dataKey="villages"></app-zones>
  </div>
  <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
    <app-zones [dataURL]="regionsURL" (onItemChosen)="onRegionChosen($event)"
               [title]="'ADMINISTRATION.REGIONS'" [allowEdit]="false"
               [allowAssign]="false"></app-zones>
  </div>
  <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
    <app-zones [dataURL]="citiesURL" (onItemChosen)="onCityChosen($event)" [parentID]="regionID"
               [title]="'ADMINISTRATION.CITIES'" [allowEdit]="false"
               [allowAssign]="false"></app-zones>
  </div>
  <div class="col-md-3 col-sm-6 col-xs-12 zones-component-container">
    <app-zones [dataURL]="villagesURL" [parentID]="cityID" [title]="'ADMINISTRATION.VILLAGES'"
               [selectedItemsList]="selectedVillages"
               [allActions] = "true"
               [cityId] = "cityID"
               [shippingLineId] = "selectedShippingLineId"
               (onSelectAll)="onLineSelected()"
               (onItemChecked)="onVillageChecked($event)"
               [allowEdit]="false"></app-zones>
  </div>
</div>
