<div class="view">
    <div class=" header-label" [ngClass]="{'header-label-en': currentLang === 'en'}">{{headerLabel | translate}}</div>
    <div *ngIf="list.length">
        <div class="item view-{{(i+1) + ''}}" *ngFor="let item of list; let i = index">
            <div class="icon" [ngClass]="{'driver': isDriver}" [ngStyle]="{'background-image': isDriver ? 'url(' + '../../assets/icons/taxi-driver.png' + ')' : 'url(' + '../../assets/icons/dashboard/shop.svg' + ')'}"></div>
            <div class="name" title="{{item?.lable}}">{{item?.lable}}</div>
        </div>
    </div>
    <div *ngIf="!list.length && !isLoading">
        <div class="row no-data-available justify-content-center">{{'ALERTS.NO_DATA_AVAILABLE' | translate}}</div>
    </div>
</div>

