import {Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InboxService} from '../../services/inbox.service';

@Component({
    selector: 'app-send-message',
    templateUrl: './send-message.component.html',
    styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {

    /**
     *
     */
    public contactUsForm: FormGroup;

    /**
     *
     */
    public isSubmitting = false;
    public users = [];
    public customers = [];
    public selectedIds = [];
    public selectedLabels = [];
    public selectedType = 'Users';
    public isUsers = true;

    public searchTerm = '';
    public pageIndex = 1;

    private PAGE_SIZE = 10;
    @ViewChild('usersRadio', {static: false}) usersRadio: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private inboxService: InboxService,
        public activeModal: NgbActiveModal
    ) {
    }

    ngOnInit() {
        this.initForm();
        this.initUsers();
        this.initCustomers();
    }

    private initForm() {
        this.contactUsForm = this.formBuilder.group({
            title: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            content: ['', Validators.required]
        });
    }

    onSendFeedback() {
        this.isSubmitting = true;
        const body = {
            message: {
                header: this.contactUsForm.controls['title'].value,
                body: this.contactUsForm.controls['content'].value
            },
            idsWrapper: {
                ids: this.selectedIds
            }
        };
        if (this.isUsers) {
            this.inboxService.sendToUsers(body).subscribe(
                (response) => {
                    this.activeModal.close({isSuccess: true});
                }, (error) => {
                    this.activeModal.close({isSuccess: false});
                }, () => {
                    this.isSubmitting = false;
                }
            );
        } else {
            this.inboxService.sendToCustomers(body).subscribe(
                (response) => {
                    this.activeModal.close({isSuccess: true});
                }, (error) => {
                    this.activeModal.close({isSuccess: false});
                }, () => {
                    this.isSubmitting = false;
                }
            );
        }

    }

    search(event) {
        if (event.term !== this.searchTerm) {
            this.searchTerm = event.term;
            this.pageIndex = 1;
            this.selectedLabels = [];
            this.fetchData();
        }
    }

    public lazyLoadUsers() {
        this.pageIndex++;
        this.fetchData();
    }

    fetchData() {
        if (this.isUsers) {
            this.initUsers();
        } else {
            this.initCustomers();
        }
    }

    private initUsers() {
        let query = '?pageSize=' + this.PAGE_SIZE + '&page=' + this.pageIndex + '&driverType=TYPICAL';
        if (this.searchTerm) {
            query += '&search=' + this.searchTerm;
        }
        this.inboxService.getAllUsers(query).subscribe((res: any) => {
                const array = res;
                const newUsers = array.map(user => {
                    const name = user.fullName;
                    return {label: name, value: user.id};
                });
                this.users = (this.pageIndex > 1) ? [...this.users, ...newUsers] : newUsers;
                const newSelectedLabels = array.map(user => {
                    const name = user.fullName;
                    return {label: name, value: user.id};
                });
                this.selectedLabels = (this.pageIndex > 1) ? [...this.selectedLabels, ...newSelectedLabels] : newSelectedLabels;
            },
            error => {
                console.log(error);
            });
    }

    private initCustomers() {
        let query = '?pageSize=' + this.PAGE_SIZE + '&page=' + this.pageIndex;
        if (this.searchTerm) {
            query += '&search=' + this.searchTerm;
        }
        this.inboxService.getAllCustomers(query).subscribe((res: any) => {
                const array = res;
                const newCustomers = array.map(user => {
                    const name = user.fullName;
                    return {label: name, value: user.id};
                });
                this.customers = (this.pageIndex > 1) ? [...this.customers, ...newCustomers] : newCustomers;
                if (!this.isUsers) {
                    const newSelectedLabels = array.map(user => {
                        const name = user.fullName;
                        return {label: name, value: user.id};
                    });
                    this.selectedLabels = (this.pageIndex > 1) ? [...this.selectedLabels, ...newSelectedLabels] : newSelectedLabels;
                }
            },
            error => {
                console.log(error);
            });
    }

    public changeUsers() {
        this.selectedIds = [];
        this.searchTerm = '';
        this.pageIndex = 1;
        if (this.selectedType === 'Users') {
            this.selectedLabels = this.users;
            this.isUsers = true;
        } else {
            this.selectedLabels = this.customers;
            this.isUsers = false;
        }
    }

}
