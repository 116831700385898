import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

export enum FieldType {
  INPUT_TEXT = 'INPUT_TEXT',
  TEXT_AREA = 'TEXT_AREA'
}

export enum FieldValidation {
  NUMBER = 'NUMBER',
  DECIMAL = 'DECIMAL',
  ALPHANUMERIC = 'ALPHANUMERIC',
  ALPHANUMERIC_WITH_SOME_CHARACTER = 'ALPHANUMERIC_WITH_SOME_CHARACTER'
}

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FieldInputComponent),
    multi: true
  }]
})
export class FieldInputComponent implements OnInit, ControlValueAccessor  {

  @Output() whenChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() input: EventEmitter<any> = new EventEmitter<any>();
  @Output() iconClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() cssStyle = {};
  @Input() style: string;
  @Input() label: string;
  @Input() id: string;
  @Input() placeholder: string;
  @Input() fieldType = FieldType.INPUT_TEXT;
  @Input() inputType = 'text';
  @Input() formControlObj: FormControl | AbstractControl;
  @Input() inputValidation: string;
  @Input() fieldDisabled = false;
  @Input() showLabel = true;
  @Input() size = 1000;
  @Input() icon: string;
  @Input() showIcon = false;
  @Input() iconPlace = 'before'; // before text or after
  @Input() isFilter = false;
  @Input() cols = 30;
  @Input() rows = 10;

  private _value: any;
  public  currentLang: string;
  public fieldTypeEnum = FieldType;

  constructor(private translateService: TranslateService) {
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {
  }

  onBlur(event) {
    this.formControlObj?.markAsTouched();
    this.whenBlur.emit(event);
  }

  private propagateChange = (_: any) => {
  }

  get value() {
    return this._value;
  }

  @Input() set value(val: any) {
    this._value = val;
    if (this.propagateChange) {
      this.propagateChange(val);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {
    this.value = value;
  }

  inputValidator(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const inp = String.fromCharCode(event.keyCode);

    switch (this.inputValidation) {
      case FieldValidation.NUMBER:
        if ((charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
        }
        return true;

      case  FieldValidation.DECIMAL:
        if (charCode !== 46 && charCode > 31
            && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
        }
        return true;

      case  FieldValidation.ALPHANUMERIC:
        if (/[a-zA-Z0-9]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }

      case FieldValidation.ALPHANUMERIC_WITH_SOME_CHARACTER:
        if (/[a-zA-Z0-9-_ ]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
    }
  }
}



