import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-undelivered-packages-summary-report',
    templateUrl: './undelivered-packages-summary-report.component.html',
    styleUrls: ['./undelivered-packages-summary-report.component.css']
})
export class UndelivredPackagesSummaryReportComponent {
    public undeliveredPackagesReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.UNDELIVERED_PACKAGES_REPORT;
    public undeliveredPackagesReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.UNDELIVERED_PACKAGES_REPORT_API

    constructor() {

    }
}
