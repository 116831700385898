<div class="shelve-cities-container" [ngClass]="{'page-ltr': currentLang === 'en'}">

    <div class="title-container row">
        <div class="title d-flex" style="position: relative; padding: 0">
            <div class="city-icon">
                <img src="../../assets/icons/cities.png" alt="" />
            </div>
            <div class="label">
                <span>{{'ADMINISTRATION.CITIES' | translate}}</span>
            </div>
        </div>
        <div class="close-icon cursor-pointer">
            <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt="" />
        </div>
    </div>
    <div class="list-cities-container">
        <ul>
            <li *ngFor="let city of cities">{{city}}</li>
        </ul>
    </div>
    <div class="footer row"></div>
</div>
