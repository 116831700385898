<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="delayed-cod-reports-container" [@simpleFadeAnimation]="'in'">

        <p-overlayPanel #filtersFormOverLay
                        class="filters-menu-panel"
                        [dismissable]="true"
                        (onHide)="filterFormOn = false"
                        [showCloseIcon]="false">
            <div class="filters-menu-container">
                <div class="filters-header">
                    <div class="filters-menu-title">
                        <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                    </div>
                </div>
                <div class="filters-inputs-group d-flex flex-column">
                    <div class="filter-item w-100">
                        <div class="filter-label">{{'ACCOUNTING.NUMBER_OF_DELIVERY_DAYS' | translate}}</div>
                        <div class="filter-value w-100">
                            <div class="day-numbers">
                                <div class="plus flex-2d-center cursor-pointer" (click)="updateReceivedDaysNumber(1)"><i
                                        class="fas fa-plus"></i></div>
                                <input type="number" [(ngModel)]="delayedDaysNo"
                                       [disabled]="true">
                                <div class="minus flex-2d-center cursor-pointer" (click)="updateReceivedDaysNumber(-1)">
                                    <i class="fas fa-minus"></i></div>
                            </div>
                        </div>
                    </div>

                    <div class="filter-item w-100">
                        <div class="filter-label">{{'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME' | translate}}</div>
                        <div class="filter-value w-100">
                            <app-users-list-dropdown-component
                                #userFilter
                                [isDriver]="false"
                                [isHub]="false"
                                [isPartner]="false"
                                [placeHolder]="' '"
                                (userChanged)="onSelectCustomer($event)"
                            ></app-users-list-dropdown-component>
                        </div>
                    </div>
                </div>
                <div class="filters-menu-actions">
                    <div class="reset-action filter-menu-action" (click)="resetFilters()">
                        <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                    </div>
                    <div class="apply-filters-action filter-menu-action" (click)="onApplyFilter()">
                        <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                    </div>
                </div>
            </div>
        </p-overlayPanel>
        <div class="d-flex top-side justify-content-between">
            <div class="d-inline-flex header">
                <div class="title">
                    <span>{{'ACCOUNTING.DELAYED_COD_REPORTS' | translate}}</span>
                </div>
                <div class="total-number-badge"><span>{{totalRecords}}</span></div>
            </div>

            <div class="filters d-flex">
                <div class="toggle-filters-form-btn filter">
                    <div class="icon" (click)="showFiltersMenu($event)">
                        <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                        <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                    </div>
                </div>

                <div class="date-filter-container">
                    <div class="date-filter-type">
                        <p-dropdown styleClass="date-filter-dropdown"
                                    [(ngModel)]="selectedDateFilter"
                                    [options]="dateFiltersList" filter="true"
                                    (onChange)="onDateFilterChange()">
                        </p-dropdown>
                    </div>

<!--                    <div class="date-picker-filter"-->
<!--                         [ngClass]="{'disabled-btn disabled': !selectedDateFilter || selectedDateFilter === 'NONE' }">-->
<!--                        <img class="accounting-date-picker-icon" src="../../../../assets/icons/calendar.svg" (click)="datepicker.click()" alt="icon"/>-->
<!--                        <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                               #datepicker-->
<!--                               daterangepicker [options]="dateRangPickerOptions"-->
<!--                               (selected)="onDateSelected($event)"/>-->
<!--                        <i class="fa fa-caret-down"></i>-->
<!--                    </div>-->
                    <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>
                </div>
                <div class="search-toggle cursor-pointer">
                    <div class="manage-shipments-search-filter search-filter-container d-flex">
                        <div class="d-flex">
                            <div class="search-filter-toggle" (click)="isSearchContent = true">
                                <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image"/>
                            </div>
                            <div class="search-input" *ngIf="isSearchContent">
                                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..."
                                       [(ngModel)]="searchContent" (input)="loadLazyReports(true)"/>
                            </div>
                        </div>
                        <div *ngIf="isSearchContent" class="hide-search-filter" (click)="isSearchContent = false">
                            <span>{{'ACTIONS.CANCEL' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-table reports-data-table">
            <div class="reports-body flex-1 overflow-auto" (scroll)="onScrollTable($event)">
                <p-table [value]="reports" [responsive]="true" scrollHeight="auto"
                         styleClass="delayed-reports-table data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <!--                            <th>-->
                            <!--                                <p-checkbox [(ngModel)]="isAllSelected" binary="true"-->
                            <!--                                            (onChange)="onToggleSelectAll()">-->
                            <!--                                </p-checkbox>-->
                            <!--                            </th>-->
                            <th class="barcode-th">{{'ACCOUNTING.PACKAGE_BARCODE' | translate}}</th>
                            <th class="barcode-th">{{'ACCOUNTING.COD_BARCODE' | translate}}</th>
                            <th>{{'MANAGE_SHIPMENTS.COST' | translate}}</th>
                            <th>{{'ADD_SHIPMENT.INVOICE_NUMBER' | translate}}</th>
                            <th>{{'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME' | translate}}</th>
                            <th class="status-dropdown">
                                <app-users-list-dropdown-component [isDriver]="true" [user]="driverId"
                                                                   [label]="'DASHBOARD.SELECT_DRIVER'"
                                                                   [isFilter]="true"
                                                                   [showLabel]="true"
                                                                   [isForm]="false"
                                                                   [panelWidth]="'100%'"
                                                                   (userChanged)="onSelectDriver($event)">
                                </app-users-list-dropdown-component>
                            </th>
                            <th>{{'ADD_SHIPMENT.RECEIVER_NAME' | translate}}</th>
                            <th>{{'ADD_SHIPMENT.RECEIVER_ADDRESS' | translate}}</th>
                            <th>{{'MANAGE_SHIPMENTS.NOTES' | translate}}</th>
                            <th>{{'ADD_SHIPMENT.COD_ON_RECEIVE' | translate}}</th>
                            <th>{{'DRIVERS_EARNINGS.DRIVER_COST' | translate}}</th>
                            <th *ngIf="isShowCommissionFees()">{{"PRICING.COMMISSION_FEE" | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content">
                            <app-no-data-available-component></app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-report>
                        <tr [ngClass]="{'scanned-row':report.isSelected}"
                            (click)="trackShipment(report)">
                            <!--                            <td>-->
                            <!--                                <p-checkbox [(ngModel)]="report.isSelected" binary="true"></p-checkbox>-->
                            <!--                            </td>-->
                            <td class="barcode-th">
                                <div class="barcode-image-container">
                                    <div class="barcode-number">
                                        <span>{{report.barcode}}</span>
                                        <div class="copy-icon" (click)="copyMessage($event, report.barcode)">
                                            <img src="../../../../assets/icons/track_package/copy.svg" alt="copy-icon"/>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="barcode-th">
                                <div class="barcode-image-container">
                                    <div class="barcode-number">
                                        <span>{{report.codBarcode}}</span>
                                        <div class="copy-icon" (click)="copyMessage($event, report.codBarcode)">
                                            <img src="../../../../assets/icons/track_package/copy.svg" alt="copy-icon"/>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{report.cost}}</td>
                            <td>{{report.invoiceNumber}}</td>
                            <td>
                                <div>
                                    {{report.customerName}}
                                </div>
                                <div>
                                    {{report.customerBusinessName == null ? "" : '('}}{{report.customerBusinessName}}{{report.customerBusinessName == null ? "" : ')'}}
                                </div>
                            </td>
                            <td>{{report.driverName}}</td>
                            <td>{{report.receiverFirstName + ' ' + report.receiverLastName}}</td>
                            <td>{{report.destination ? report.destination : report.receiverCity}}</td>
                            <td>{{report.notes}}</td>
                            <td>{{report.cod}}</td>
                            <td>{{report.driverEarning}}</td>
                            <td *ngIf="isShowCommissionFees()">{{report.commissionFee}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader *ngIf="isLoading" [type]="1"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<app-shipment-tracker [isShow]="isTrackingPackage" (onCloseTracker)="closeTracker($event)"
                      [packageBarcode]="triggeredDropdownShipment?.barcode"
                      [showHeaderActions]="!isCustomerAccountManager()"
                      class="component">
</app-shipment-tracker>
