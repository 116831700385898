import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-customer-weight-filter',
    templateUrl: './customer-weight-filter.component.html',
    styleUrls: ['./customer-weight-filter.component.scss']
})
export class CustomerWeightFilterComponent implements OnInit {
    public isLoading = false;
    @Input() isWeight = true;
    public form: FormGroup;

    @Output() onFinish: EventEmitter<{}> = new EventEmitter<{}>();
    @Output() onCancel: EventEmitter<{}> = new EventEmitter<{}>();
    @Input() weightData: { weightFrom: number, weightTo: number };

    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                public translateService: TranslateService
    ) {}

    ngOnInit() {
        this.isLoading = false;
        this.initInfo();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            weightFrom: this.weightData.weightFrom,
            weightTo: this.weightData.weightTo,
        });
    }

    public finish() {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.getRawValue();
        this.onFinish.emit({
            weightFrom: data.weightFrom,
            weightTo: data.weightTo,
        });

    }

    public onClose() {
        this.onCancel.emit();
    }
}
