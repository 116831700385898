import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {THIRD_PARTY_CONSTANTS} from './constants';
import {Observable, of} from 'rxjs';
import {USERS_CONSTANTS} from '../../users/services/constants';
import {SHIPMENT_CONSTANTS} from '../../shipment/services/constants';

@Injectable({ providedIn: 'root' })
export class ThirdPartyService {
    constructor(private http: HttpClient) {
    }

    getThirdParties(para?): Observable<any>{
        const params = para || {};
        params['driverType'] = 'THIRD_PARTY';
        return this.http.get(USERS_CONSTANTS.GET_USERS, {params});
    }
}
