import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-system-shutting-down',
  templateUrl: './system-shutting-down.component.html',
  styleUrls: ['./system-shutting-down.component.scss']
})
export class SystemShuttingDownComponent implements OnInit {

  constructor(private router: Router,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  public goToDashboard() {
    this.router.navigate([this.authenticationService.companyName + 'home/']);
  }

}
