import {Component, ViewChild, EventEmitter, Input, Output, OnInit} from '@angular/core';

@Component({
    selector: 'app-manage-company',
    templateUrl: 'manage-company.component.html',
    styleUrls: ['manage-company.component.scss']
})
export class ManageCompanyComponent {

    constructor() {
    }
}
