<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="cod-mismatch-view d-flex flex-column h-100">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="main-header-container d-flex justify-content-between">
        <div class="page-header-title">
            <div class="title"> {{"ACCOUNTING.COD_MISMATCH" | translate}}</div>
            <div class="total-number-badge">{{totalRecords}}</div>
        </div>
        <div class="filters d-flex">
            <div class="filter from-filter d-flex">
                <div class="options px-2">
                    <app-drop-down [options]="filterOptions"
                                  [(ngModel)]="selectedOption"
                                  (whenChange)="onClickRadioButton()"
                                  [showLabel]="false"
                                  [showClear]="false">
                    </app-drop-down>
                </div>
                <div class="user-filter">
                    <app-users-list-dropdown-component
                        #userFilter
                        [isDriver]="selectedOption ==='driverId' || selectedOption === 'thirdPartyId'"
                        [isThirdParty]="selectedOption === 'thirdPartyId'"
                        [isHub]="selectedOption ==='hubId'"
                        [isPartner]="selectedOption ==='partnerId'"
                        [innerPlaceHolder]="'MANAGE_SHIPMENTS.'+ (selectedOption && selectedOption !== 'NONE' ? selectedOption.substring(0, selectedOption.length - 2).toUpperCase() : 'WITHOUT')"
                        (userChanged)="onFilterSelectedChange($event)"
                        [isDisabled]="!selectedOption"
                    ></app-users-list-dropdown-component>
                </div>
            </div>
            <app-date-range-picker (onSelect)="onDateSelected($event)"
                                   (onShow)="onShowCalenderRangePicker($event)"
                                   (onClose)="onHideCalenderRangePicker($event)"></app-date-range-picker>
            <app-field-input class="width-170"
                       [showIcon]="true"
                       [showLabel]="false"
                       icon="fas fa-search"
                       placeholder="{{'GENERAL.SEARCH' | translate}}..."
                       [(ngModel)]="searchContent"
                       appInputDebounced
                       (inputDebounced)="onSearch()"> </app-field-input>

        </div>
    </div>
    <div class="cod-mismatch-header-actions d-flex align-items-center flex-wrap">
        <div class="secondary-actions d-flex">
            <div class="action secondary-action-btn"
                 (click)="openExcel()">
                {{"EXPENSES.EXPORT_TO_EXCEL" | translate}}
            </div>
        </div>
        <div class="secondary-actions d-flex">
            <div class="action secondary-action-btn"
                 [ngClass]="{'disabled disabled-btn': !fromDate || !mismatches.length}"
                 (click)="printMultiPackages()">
                {{"EXPENSES.EXPORT_TO_PDF" | translate}}
            </div>
        </div>

        <div class="d-flex flex-1 justify-content-end" >
            <i routerLink='../cod-mismatch-all-packages' class="fas fa-2x fa-angle-double-right icon-to-rotated cursor-pointer"></i>
        </div>
    </div>

    <div class="cod-mismatch-table flex-1 overflow-auto">
        <p-table #mismatchesTable appPTableRtlFix dataKey="id" #table [value]="mismatches"
                 styleClass="customers-table flex-table" paginatorDropdownAppendTo="body"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadMismatchesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 150px;">{{'GENERAL.BARCODE' | translate}}</th>
                    <th>{{"ACCOUNTING.HOLDER_NAME" | translate}}</th>
                    <th>
                        <div class="header-filter">
                            <div class="label">{{'USER_INFORMATION.RECEIVER_NAME' | translate}}</div>
                            <div class="value">
                                <app-users-list-dropdown-component
                                        [isCodMismatchCustomer]="true"
                                        [placeHolder]="' '"
                                        (userChanged)="onReceiverSelectedChange($event)"
                                        [panelWidth]="90"
                                ></app-users-list-dropdown-component>
                            </div>
                        </div>
                    </th>
                    <th>{{"ACCOUNTING.CARRIED_COD" | translate}}</th>
                    <th>{{"ACCOUNTING.EXPECTED_COD" | translate}}</th>
                    <th>{{"PARTNER.PARTNER_COST" | translate}}</th>
                    <th>{{"ACCOUNTING.EXPENSES" | translate}}</th>
                    <th>{{"ACCOUNTING.EXPENSE_TYPE" | translate}}</th>
                    <th *ngIf="this.userService.userInfo.isDriverEarningEnabled">{{"ACCOUNTING.DRIVER_EARNING" | translate}}</th>
                    <th>{{"ACCOUNTING.RECEIVE_DATE" | translate}}</th>
                    <th>{{"ACCOUNTING.EXPECTED_COST" | translate}}</th>
                    <th *ngIf="currency === 'SAR'">{{"ACCOUNTING.COD_FEES" | translate}}</th>
                    <th style="width: 7%"> {{"MANAGE_CONTAINERS.PRINT" | translate}}</th>
                    <th *ngIf="userService.userInfo.role !== 'ACCOUNTING_MANAGER'">{{"ACTIONS.EDIT" |translate}}</th>
                    <th style="width: 7%"> {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-mismatch let-expanded="expanded">
                <tr class="cursor-pointer" (click)="goToCustomerCOD(mismatch)">
                    <td style="width: 150px; text-align: center;" class="cursor-pointer">
                        <div class="barcode-image-container flex-2d-center" *ngIf="mismatch.barcode">
                            <div>
                                <img
                                        height="50px"
                                        class="barcode-image"
                                        [src]="mismatch.barcodeImageUrl"
                                />
                                <span class="barcode-label hover-display-parent">
                                <span>{{mismatch.barcode}}</span>
                                <i class="fas fa-copy cursor-pointer hover-display"
                                   (click)="copyMessage($event, mismatch.barcode)"></i>
                            </span>
                            </div>
                        </div>
                    </td>
                    <td>{{mismatch.holderName}}</td>
                    <td style="width: 160px;">{{mismatch.receiverName}}</td>
                    <td>{{fixFraction(mismatch.carriedCod)}}</td>
                    <td>
                        <span>{{fixFraction(mismatch.expectedCod)}}</span>
                        <span *ngIf="mismatch.carriedCodByCollectionMethod !== null"
                              class="cod-details"
                              style="position: relative"
                              [ngbPopover]="showCodDetailsDialog"
                              #showCodDetailsPopOver="ngbPopover"
                              triggers="mouseenter:mouseleave"
                              placement="top"
                              autoClose="outside"
                              container="body"
                              (mouseenter)="initCODCollection(mismatch)">
                            {{'GENERAL.DETAILS' | translate}}
                        </span>
                    </td>
                    <td>{{fixFraction(mismatch.partnerCost)}}</td>
                    <td>{{mismatch.expenses}}</td>
                    <td>{{mismatch.expensesType}}</td>
                    <td *ngIf="this.userService.userInfo.isDriverEarningEnabled">{{mismatch.driverEarning}}</td>
                    <td>{{getLocalDate(mismatch.createdDate)  | date:'dd/MM/yyyy'}}</td>
                    <td>{{mismatch.cost}}</td>
                    <td *ngIf="currency === 'SAR'">{{mismatch.codFees}}</td>
                    <td style="width: 7%">
                        <div class="table-row-actions-container">
                            <div
                                    class="table-action action cursor-pointer" (click)="printPackage(mismatch,$event)">
                                <i class="fas fa-print"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="userService.userInfo.role !== 'ACCOUNTING_MANAGER'">
                        <div class="table-row-actions-container">
                            <div class="table-action action cursor-pointer" (click)="updateCodMismatch(mismatch)">
                                <i class="fas fa-edit"></i>
                            </div>
                        </div>
                    </td>
                    <td style="width: 7%">
                        <div class="table-row-actions-container">
                            <div class="table-action action cursor-pointer"
                                 (click)="exportToExcel(mismatch, $event)">
                                <i class="fas fa-file-excel"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<ng-template #showCodDetailsDialog>
    <div style="display: flex; flex-direction: column; overflow: auto !important;max-height: 50vh">
        <div class="d-flex justify-content-between my-2" *ngFor="let collection of carriedCodByCollectionMethodKeys">
            <b class="flex-nowrap mx-2">{{'BANK_TRANSFERS.' + collection | translate}}: </b> {{carriedCodByCollectionMethod[collection] + ' ' + currency}}
        </div>
    </div>
</ng-template>
