import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ItemModel} from '../../../administration/models/item.model';
import {ZonesService} from '../../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {ADMINISTRATION_CONSTANTS} from '../../../administration/administration-constants';
import {SHARED_CONSTANTS} from '../../services/shared_constants/constants';
import {UserService} from '../../services/user.service';
import {Paginator} from 'primeng/paginator';

declare let L;

@Component({
    selector: 'app-choose-zone-location',
    templateUrl: './choose-zone-location.component.html',
    styleUrls: ['./choose-zone-location.component.scss']
})
export class ChooseZoneLocationComponent implements OnInit {

    selectedItem: ItemModel;

    @ViewChild('itemsPaginator')
    itemsPaginator: Paginator;

    items: ItemModel[] = [];
    dataUrl: string;

    loading = true;
    multipleItems = false;
    lazyFetching = false;
    dataPageSize = ADMINISTRATION_CONSTANTS.ZONES_PAGE_SIZE;
    initialMapView;

    public map;
    public mapMarker;
    public searchResults;

    public selectedLanguage = 'en';
    public searchInput;

    MAP_ZOOM = 12;
    INIT_MAP_ZOOM = 8;

    constructor(protected activeModal: NgbActiveModal,
                protected zonesService: ZonesService,
                protected userService: UserService,
                protected translateService: TranslateService) {
    }

    ngOnInit() {
        this.loading = true;
        if (this.multipleItems) {
            this.selectedItem = this.items[0];
        }
        this.selectedLanguage = this.translateService.currentLang;
        this.translateService.get('GENERAL.SEARCH').subscribe(
            (value) => {
                setTimeout(() => {
                    this.initMap(value);
                }, 500);
            }
        );
    }

    initMap(searchPlaceholder?) {
        this.initialMapView = this.userService.getInitialMapView();
        const map = (this.translateService.currentLang === 'en') ? 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.map = new L.map('map', {
            center: [this.selectedItem.latitude ? this.selectedItem.latitude : this.initialMapView.lat,
                this.selectedItem.longitude ? this.selectedItem.longitude : this.initialMapView.lng],
            zoom: this.selectedItem.latitude ? this.INIT_MAP_ZOOM : this.initialMapView.zoom
        });

        L.tileLayer(map, {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        if (this.selectedItem.latitude && this.selectedItem.longitude) {
            this.mapMarker = L.marker([this.selectedItem.latitude, this.selectedItem.longitude], this.MAP_ZOOM).addTo(this.map);
        }

        this.searchInput = new L.esri.Geocoding.geosearch({
            providers: [
                L.esri.Geocoding.arcgisOnlineProvider({
                    // API Key to be passed to the ArcGIS Online Geocoding Service
                    apikey: SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY
                })
            ],
            useMapBounds: false,
            placeholder: searchPlaceholder
        }).addTo(this.map);

        this.searchResults = L.layerGroup().addTo(this.map);

        this.searchInput.on('results', (data) => {
            this.searchResults.clearLayers();
            this.map.invalidateSize();
            if (this.mapMarker) {
                this.mapMarker.remove();
            }
            this.mapMarker = L.marker(data.results[0].latlng).addTo(this.map);
            this.searchResults.addLayer(this.mapMarker);
        });

        this.map.crs = L.CRS.EPSG4326;

        this.map.addEventListener('click', (event) => {
            this.map.invalidateSize();
            if (this.mapMarker) {
                this.mapMarker.remove();
            }
            this.mapMarker = L.marker([event.latlng.lat, event.latlng.lng], 8).addTo(this.map);
            // this.map.flyTo(this.mapMarker.getLatLng(), 8);
            this.map.flyTo(this.mapMarker.getLatLng(), this.MAP_ZOOM);
        });
        this.loading = false;
    }

    paginate(event) {
        this.map.invalidateSize();
        this.selectedItem = this.items[event.first];
        if (this.mapMarker) {
            this.mapMarker.remove();
            this.mapMarker = null;
        }
        if (this.selectedItem.latitude && this.selectedItem.longitude) {
            this.mapMarker = L.marker([this.selectedItem.latitude, this.selectedItem.longitude], this.MAP_ZOOM).addTo(this.map);
            this.map.flyTo(this.mapMarker.getLatLng(), this.MAP_ZOOM);
        } else {
            this.map.flyTo([this.initialMapView.lat, this.initialMapView.lng], this.initialMapView.zoom);
        }

        console.log(event);
        if (this.lazyFetching && (event.page + 1) === event.pageCount && (event.page + 1) % this.dataPageSize === 0) {
            this.loading = true;
            this.fetchZones(((event.page + 1) / this.dataPageSize) + 1);
        }
    }

    fetchZones(page) {
        const params = this.createParams(this.dataPageSize, page);
        this.zonesService.getZones(this.dataUrl, params).subscribe(
            (response: any) => {
                this.items.push(...response.data);
                this.loading = false;
            }, () => {
                this.loading = false;
            }
        );
    }

    createParams(pageSize, pageNumber) {
        return {
            pageSize: pageSize,
            page: pageNumber,
        };
    }

    save(goToNext: boolean) {
        this.loading = true;
        this.zonesService.setVillageLocation(this.selectedItem.id, this.mapMarker.getLatLng()).subscribe(
            () => {
                this.selectedItem.longitude = this.mapMarker.getLatLng().lng;
                this.selectedItem.latitude = this.mapMarker.getLatLng().lat;
                this.loading = false;
                if (!this.multipleItems) {
                    this.activeModal.close({isSuccess: true});
                }

                if (goToNext) {
                    this.itemsPaginator.changePage(this.itemsPaginator.getPage() + 1);
                }
            }, () => {
                this.loading = false;
            }
        );
    }

    cancel() {
        this.activeModal.dismiss();
    }

}
