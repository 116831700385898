import { Component, OnInit } from '@angular/core';
import {HubModel} from '../../../shared/models/hub.model';
import {FulfilmentService} from '../../services/fulfilment.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {WarehouseAreaModel} from '../../models/warehouse.model';
import {FULFILMENT_CONSTANTS} from '../../services/constants';

@Component({
  selector: 'app-new-area',
  templateUrl: './new-area.component.html',
  styleUrls: ['./new-area.component.scss']
})
export class NewAreaComponent implements OnInit {

  warehouse: HubModel;
  areaForm: FormGroup;
  currentLang = 'en';
  area: WarehouseAreaModel;

  isEditMode = false;
  isLoading = false;
  zoneTypes = [];



  constructor(private fulfilmentService: FulfilmentService,
              private activeModal: NgbActiveModal,
              private translateService: TranslateService,
              private messageService: MessageService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.setZoneTypes();
    this.initForm(this.area);
  }

  private initForm(area?) {
    this.areaForm = this.formBuilder.group({
      name: [area ? area.name : '', Validators.required],
      zone: [area ? area.zone : 'DRY', Validators.required],
      palletSize: [area ? area.palletSize : '1', Validators.required],
      warehouseName: [this.warehouse.name, Validators.required]
    });
  }

  submitForm() {
    this.isLoading = true;
    const formData = this.areaForm.getRawValue();
    this.fulfilmentService.newArea(this.warehouse.id, {
      name: formData['name'],
      zone: formData['zone'],
      palletSize: formData['palletSize']
    }).subscribe(
        (response) => {
          this.translateService.get(this.isEditMode ? 'ALERTS.AREA_EDITED_SUCCESSFULLY' : 'ALERTS.AREA_CREATED_SUCCESSFULLY')
              .subscribe(
                  (translateValue) => {
                    this.isLoading = false;
                    this.messageService.add({severity: 'success', detail: translateValue});
                    this.closeModal(true);
                  }
              );
        }, (error) => {
          this.isLoading = false;
          console.error(error);
        }
    );
  }

  closeModal(isSuccess?) {
    if (isSuccess) {
      this.activeModal.close({isSuccess: isSuccess});
    }
    this.activeModal.close();
  }

  setZoneTypes() {
    this.zoneTypes =  FULFILMENT_CONSTANTS.ZONE_TYPES.map((zone) => {
      return {label: this.translateService.instant(`FULFILMENT.WAREHOUSE.AREAS.ZONE_TYPES_LIST.${zone}`), value: zone};
    });
  }
}
