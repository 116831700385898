import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HubModel} from '../../shared/models/hub.model';
import {FormGroup} from '@angular/forms';
import {NewHubComponent} from '../new-hub/new-hub.component';
import {HubsService} from '../../shared/services/hubs.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Paginator} from 'primeng/paginator';
import {NewShelfComponent} from './new-shelf/new-shelf.component';

@Component({
    selector: 'app-manage-shelf',
    templateUrl: './manage-shelf.component.html',
    styleUrls: ['./manage-shelf.component.scss']
})
export class ManageShelfComponent implements OnInit {

    @Input() hub: HubModel;
    @Input() shelf;
    @ViewChild('paginator', {static: false}) paginator: Paginator;

    public currentPage = 0;
    public totalRecords = 0;
    public isSubmitting = false;
    public isLoading = true;
    public shelves = [];
    public selectedShelves = {};

    constructor(public activeModal: NgbActiveModal,
                private hubsService: HubsService,
                private modalService: NgbModal,
                private messageService: MessageService,
                public translateService: TranslateService,
                private confirmationService: ConfirmationService) {
    }

    ngOnInit() {
        this.getShelves();
    }

    public getShelves(same?) {
        let query = this.createQuery();

        if (same) {
            query = this.sameQuery();
        }
        this.hubsService.getShelves(this.hub.id, query).subscribe((res: any) => {
            this.shelves = res.data;
            this.totalRecords = res.totalRecordsNo;
            this.isLoading = false;
            this.initializePrint();
        }, error => {
            console.log(error);
            this.isLoading = false;
        });
    }

    public removeShelf($event: any) {
        let message = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.REMOVE_IT', 'GENERAL.YES', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.REMOVE_IT'];
                    yes = data['GENERAL.YES'];
                    no = data['GENERAL.NO'];
                }
            );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.hubsService.deleteShelf($event.shelf.id).subscribe(
                    (response) => {
                        this.messageService.add({severity: 'success', detail: 'shelf Deleted Successfully'});
                        this.getShelves();
                        this.paginator.changePage(0);
                    }, (error) => {
                        console.error(error);
                    }
                );
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    public onPaginate(page) {
        this.currentPage = page;
        this.getShelves();
    }

    private createQuery() {
        const query = '?pageSize=6&page=' + (this.currentPage + 1);
        return query;
    }

    private sameQuery() {
        const query = '?pageSize=6&page=' + (this.currentPage);
        return query;
    }

    public modifyShelf($event: { shelf }) {
        const modal = this.modalService.open(NewShelfComponent, <any>{backdrop: 'static', windowClass: 'add-shelf-container',
            backdropClass: 'add-shelf-container-backdrop',
            size: 'md'});
        modal.componentInstance.shelf = $event.shelf;
        modal.componentInstance.hub = this.hub;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    this.messageService.add({severity: 'success', detail: 'Shelf Edited Successfully'});
                    this.getShelves();
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public onNewShelf($event: { shelf }) {
        const modal = this.modalService.open(NewShelfComponent, <any>{backdrop: 'static', windowClass: 'add-shelf-container', size: 'md'});
        modal.componentInstance.hub = this.hub;
        modal.result.then(
            (response) => {
                if (response.isSuccess) {
                    this.messageService.add({severity: 'success', detail: 'Shelf Added Successfully'});
                    this.getShelves();
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public closeModal() {
        this.activeModal.close();
    }

    public initializePrint() {
        for (const shelf in this.shelves) {
            this.selectedShelves[this.shelves[shelf].id] = true;
        }
    }

    public print() {
        const shelvesToPrint = [];
        const shelves = Object.keys(this.selectedShelves);
        for (const shelf of shelves) {
            if (this.selectedShelves[shelf]) {
                shelvesToPrint.push(shelf);
            }
        }

        this.hubsService.printShelves(shelvesToPrint).subscribe((res: any) => {
            window.open(res.url, '_blank');
        });
    }

    public checkSelectedShelves(): boolean {
        return this.selectedShelves && Object.keys(this.selectedShelves).length > 0;
    }

}
