<div class="customer-region-container d-flex flex-column">
    <div class="title">{{(isSender ? 'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.SENDER_TITLE': 'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.RECEIVER_TITLE') | translate}}</div>
    <form [formGroup]="form" *ngIf="form" (keyup.enter)="finish()">
        <div class="customer-region-contents d-flex flex-column">
            <div class="input-item-container d-flex">
                <div class="label">{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.NAME' | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form" controlName="customer"
                                               controlType="text" *ngIf="isSender">
                    </app-input-field-component >
                    <app-input-field-component [form]="form" controlName="customer"
                                               controlType="text" *ngIf="!isSender">
                    </app-input-field-component >
                </div>
            </div>

            <div class="input-item-container d-flex">
                <div class="label">{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.REGION' | translate}}</div>
                <div class="value">
                    <app-multi-select *ngIf="isSender"
                                      [formControlObj]="form.get('originRegion')"
                                      formControlName="originRegion"
                                      [showLabel]="false" #originRegion
                                      (whenFilterChange)="initRegions($event.query)"
                                      (whenChange)="updateZoneForm($event.value,'originRegion');
                                                     updateZoneForm(null,'originCity');
                                                     updateZoneForm(null,'originVillage');
                                                     originCityView.resetOptions();
                                                     originVillageView.resetOptions();
                                                     form.get('originRegion').value?.length === 1 ? initCities(0, form.get('originRegion').value[0], '') : ''"
                                      [options]="regionOptions">
                    </app-multi-select>
                    <app-multi-select *ngIf="!isSender"
                                      [formControlObj]="form.get('destinationRegion')"
                                      formControlName="destinationRegion"
                                      [showLabel]="false" #destinationRegion
                                      (whenFilterChange)="initRegions($event.query)"
                                      (whenChange)="updateZoneForm($event.value,'destinationRegion');
                                                     updateZoneForm(null,'destinationCity');
                                                     updateZoneForm(null,'destinationVillage');
                                                     destinationCityView.resetOptions();
                                                     destinationVillageView.resetOptions();
                                                     form.get('destinationRegion').value?.length === 1 ? initCities(1, form.get('destinationRegion').value[0], '') : ''"
                                      [options]="regionOptions1">
                    </app-multi-select>
                </div>
            </div>


            <div class="input-item-container d-flex">
                <div class="label">{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.CITY' | translate}}</div>
                <div class="value">
                    <app-multi-select *ngIf="isSender"
                                      [formControlObj]="form.get('originCity')"
                                      formControlName="originCity"
                                      [showLabel]="false" #originCity
                                      [disabled]="!form.get('originRegion').value || form.get('originRegion').value?.length > 1"
                                      (whenFilterChange)="initCities(0, form.get('originRegion').value[0],$event.query)"
                                      (whenChange)="updateZoneForm($event.value,'originCity');
                                                     updateZoneForm(null,'originVillage');
                                                     originVillageView.resetOptions();
                                                     form.get('originCity').value?.length === 1 ? initVillage(0, form.get('originCity').value[0], '') : ''"
                                      [options]="citiesOptions">
                    </app-multi-select>
                    <app-multi-select *ngIf="!isSender"
                                      [formControlObj]="form.get('destinationCity')"
                                      formControlName="destinationCity"
                                      [showLabel]="false" #destinationCity
                                      [disabled]="!form.get('destinationRegion').value || form.get('destinationRegion').value?.length > 1"
                                      (whenFilterChange)="initCities(1, form.get('destinationRegion').value[0],$event.query)"
                                      (whenChange)="updateZoneForm($event.value,'destinationCity');
                                                   updateZoneForm(null,'destinationVillage');
                                                   destinationVillageView.resetOptions();
                                                   form.get('destinationCity').value?.length === 1 ? initVillage(1, form.get('destinationCity').value[0], '') : ''"
                                      [options]="citiesOptions1">
                    </app-multi-select>
                </div>
            </div>

            <div class="input-item-container d-flex">
                <div class="label">{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.VILLAGE' | translate}}</div>
                <div class="value">
                    <app-multi-select *ngIf="isSender"
                                      [formControlObj]="form.get('originVillage')"
                                      formControlName="originVillage"
                                      [showLabel]="false" #originVillage
                                      [disabled]="!form.get('originCity').value || form.get('originCity').value?.length > 1"
                                      (whenFilterChange)="initVillage(0, form.get('originCity').value[0],$event.query)"
                                      (whenChange)="updateZoneForm($event.value,'originVillage');
                                                   form.get('originCity').value?.length === 1 ? initVillage(0, form.get('originCity').value[0], '') : ''"
                                      [options]="villageOptions">
                    </app-multi-select>
                    <app-multi-select *ngIf="!isSender"
                                      [formControlObj]="form.get('destinationVillage')"
                                      formControlName="destinationVillage"
                                      [showLabel]="false" #destinationVillage
                                      [disabled]="!form.get('destinationCity').value || form.get('destinationCity').value?.length > 1"
                                      (whenFilterChange)="initVillage(1, form.get('destinationCity').value[0],$event.query)"
                                      (whenChange)="updateZoneForm($event.value,'destinationVillage');
                                                   form.get('destinationCity').value?.length === 1 ? initVillage(1, form.get('destinationCity').value[0], '') : ''"
                                      [options]="villageOptions1">
                    </app-multi-select>
                </div>
            </div>
            <div class="bottom-actions d-flex justify-content-end w-100">
                <div (click)="onClose()" class="cancel-btn d-inline-flex justify-content-center">
                    <span>{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.CANCEL' | translate}}</span>
                </div>
                <div style="width: 8px"></div>
                <div class="done-btn d-inline-flex justify-content-center"
                     [ngClass]="{'disabled-button disabled': !form.valid}"
                     (click)="finish()">
                    <span>{{'MANAGE_SHIPMENTS.SENDER_RECEIVER_DIALOG.DONE' | translate}}</span>
                </div>
            </div>
        </div>
    </form>
</div>
