import {Component, Input, OnInit} from '@angular/core';
import {PackageModel} from '../../shared/models/package.model';
import {FormBuilder, Validators} from '@angular/forms';
import {ShipmentsService} from '../services/shipments.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancel-jnt-print-dialog',
  templateUrl: './cancel-jnt-print-dialog.component.html',
  styleUrls: ['./cancel-jnt-print-dialog.component.scss']
})
export class CancelJntPrintDialogComponent implements OnInit {
  @Input() shipment: PackageModel;
  title = 'SHIPMENT.CANCEL_JNT';
  public form;
  public isLoading = false;

  constructor(private formBuilder: FormBuilder,
              private shipmentService: ShipmentsService,
              private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initForm();
  }

  public initForm() {
      this.form = this.formBuilder.group({
        reason: ['', Validators.required]
      });
  }

  public onSaveButton() {
    const body = { reason: this.form.value.reason };
    this.isLoading = true;
    this.shipmentService.cancelJNT(this.shipment.id, body).subscribe(
        (response: any) => {
          this.activeModal.close({isSuccess: true});
        },
        (error) => {
          this.activeModal.close({isSuccess: false});
        }
    );
  }

  public closeModal() {
    this.activeModal.close();
  }

}
