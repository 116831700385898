import {ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {RolesService} from '../../shared/services/roles/roles.service';
import {UserService} from '../../shared/services/user.service';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {setBarcodeReader} from '../../shared/services/helpers';
import {playError} from '../../shared/behavior/sounds';
import {ShipmentModel} from '../../shared/models/shipment.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {DateRangePickerService} from '../../shared/services/date-range-picker.service';
import {SharedService} from '../../shared/services/shared-service';
import {ChooseHubDriverPartnerComponent} from '../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {ConfirmMessageComponent} from '../../shared/components/confirm-message/confirm-message.component';
import {RETURNED_TYPES_LIST} from '../services/constants';
import {ReceiveDriverAndReadByBarcodeComponent} from '../recieve-driver-and-read-by-barcode/receive-driver-and-read-by-barcode.component';
import {RETURNED_PACKAGES_SORT_BY} from '../../ticketing-system/services/constants';
import {Companies} from '../../../customCompanies/companies.pal-ship';
import * as moment from 'moment';
import {ChooseCustomerComponent} from '../../partners/choose-customer/choose-customer.component';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';

@Component({
    selector: 'app-return-packages',
    templateUrl: './return-packages.component.html',
    styleUrls: ['./return-packages.component.scss']
})
export class ReturnPackagesComponent implements OnInit, OnDestroy {
    isSupportReturnedBundles;
    companies = Companies;
    constructor(@Inject(FormBuilder) private formsBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private shipmentsService: ShipmentsService,
                private datePipe: DatePipe,
                private messageService: MessageService,
                private rolesService: RolesService,
                private userService: UserService,
                private confirmationService: ConfirmationService,
                private modalService: NgbModal,
                private authenticationService: AuthenticationService,
                private dateRangePickerService: DateRangePickerService,
                private changeDetectorRef: ChangeDetectorRef,
                public customizationCompaniesService: CustomizationCompanyService,
                private sharedService: SharedService) {
    }

    @ViewChild('returnedTable') returnedTable;

    public id;
    public isLoading = false;
    public fromDate;
    public toDate;
    public selectedLanguage = '';
    public searchContent = '';
    public pageNumber = 1;
    public totalRecords;
    // public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public pageSize = SHARED_CONSTANTS.LAZY_LOAD_TABLE_DEFAULT_PAGE_SIZE;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public returnedPackages = [];
    public permissions = '';

    public isAllSelected = false;
    public isThereSelection = false;
    public isScanning = false;
    public scannedPackages = [];
    public isReading = false;
    public isPrinting = false;
    public showActions = true;
    public datesFilterForm: FormGroup;
    public dateRangPickerOptions;
    public inCar = false;
    public withoutCustomerFilter = false;
    public deliverToSender = false;
    public isBundle = false;
    public isPageLoading = false;
    public customerId;
    public driverId;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    isTrackingPackage = false;
    isExportingExcel = false;
    triggeredShipment: any = {};

    selectedType = 'ALL';
    typesList: any;
    private partiallyDelivered: any;
    @ViewChild('senderRegionPopOver') senderRegionPopOver: NgbPopover;
    private searchParameters: any;
    public senderRegionData: {customerName: string, originRegion: any, originCity: any, originVillage: any } = {
        customerName: null,
        originRegion: null,
        originCity: null,
        originVillage: null};
    allReturned = false;
    @ViewChild('tableContainer') tableContainer;
    @ViewChild('filtersMenu') filtersMenu;
    private hasMore = true;
    private isSelecting: boolean;
    private muteSound = false;
    filterMenuOpen = false;
    public formInitiated = false;
    public hasThirdParty = false;
    sortByList = [];
    selectedBarcode;
    isWithMassReturnedBarcode = true;
    showPartiallyExport = false;
    public selectedHub;

    protected readonly Companies = Companies;

    ngOnInit() {
        this.inCar = (this.route.snapshot.data['inCar'] === undefined
            ? false : this.route.snapshot.data['inCar']);
        this.deliverToSender = (this.route.snapshot.data['deliverToSender'] === undefined
            ? false : this.route.snapshot.data['deliverToSender']);
        this.isBundle = !!this.route.snapshot.data['bundle'];

        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.selectedLanguage = this.translateService.currentLang;
        this.hasThirdParty = this.userService.userInfo.hasThirdParty;
        this.isSupportReturnedBundles = this.userService.userInfo.isSupportReturnedBundles;
        this.route.params.subscribe(
            (params: Params) => {
                this.id = params.id;
                this.customerId = this.id;
            }
        );
        this.route.queryParams.subscribe((params: Params) => {
            if (params.withoutCustomerFilter) {
                this.withoutCustomerFilter = params.withoutCustomerFilter;
            }
            if (params.driverId) {
                this.driverId = params.driverId;
            }

            if (params.isWithMassReturnedBarcode) {
                this.isWithMassReturnedBarcode = params.isWithMassReturnedBarcode;
            }

            if (params.showPartiallyExport) {
                this.showPartiallyExport = params.showPartiallyExport;
            }
            if (params.barcode) {
                this.selectedBarcode = params.barcode;
            }
        });
        this.initSortByList();
        this.initSearchParams();
        this.extractQueryFilter();
        this.scannedPackages = [];
        this.initSelectedType();
        this.typesList = this.setTypesList();
        this.initDatesFilterForm();
        this.setDateRangePickerOptions();
        this.isReading = true;
        this.initPackages();
    }

    initSortByList() {
        this.sortByList =  RETURNED_PACKAGES_SORT_BY.map((value) => {
            const label = this.translateService.instant(value);
            return {label, value};
        });
    }

    public onShipmentsTypeChanged() {
        this.reset();
        this.initPackages();
    }

    public setDateRangePickerOptions() {
        this.dateRangePickerService.getDateRangePickerOptions(3, false, false, true, true, true, 'MONTH').then(options => {
            this.dateRangPickerOptions = options;

            this.route.queryParams.subscribe(params => {
                if (this.authenticationService.companyId === 93) {
                    this.datesFilterForm.patchValue({fromDate: new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 20))});
                    this.dateRangPickerOptions.startDate = new Date(new Date().getTime() - (60 * 60 * 24 * 1000 * 20));
                } else {
                    if (params.fromDate) {
                        this.datesFilterForm.patchValue({fromDate: new Date(params.fromDate)});
                        this.dateRangPickerOptions.startDate = new Date(params.fromDate);
                    }
                }
                if (params.toDate) {
                    this.datesFilterForm.patchValue({toDate: new Date(params.toDate)});
                    this.dateRangPickerOptions.endDate = new Date(params.toDate);
                }
            });

        });
    }
    private initDatesFilterForm() {
        const date = new Date();
        let fromDate: Date = new Date(date.setFullYear(date.getFullYear(), date.getMonth() - 1, date.getDate()));
        if(this.fromDate){
            fromDate = new Date(this.fromDate);
        }
        let toDate = new Date();
        if(this.fromDate){
            toDate = new Date(this.toDate);
        }
        this.datesFilterForm = this.formsBuilder.group({
            searchText: [''],
            selectedShipmentsType: [{value: false}],
            fromDate: [fromDate],
            toDate: [toDate],
            sortBy: [{value: 'CREATED_DATE'}]
        });

        this.formInitiated = true;
    }
    public onDateSelected(value) {
        this.reset();
        const startDate = new Date(value.start);
        const endDate = new Date(value.end);
        this.datesFilterForm.controls.fromDate.setValue(startDate);
        this.datesFilterForm.controls.toDate.setValue(endDate);
        this.datesFilterForm.controls.fromDate.updateValueAndValidity();
        this.datesFilterForm.controls.toDate.updateValueAndValidity();
        this.onSearch();
    }

    ngOnDestroy(): void {
        this.isReading = false;
    }
    public reset() {
        this.returnedPackages = [];
        this.totalRecords = null;
        this.hasMore = true;
        this.pageNumber = 0;
        this.isThereSelection = false;
    }
    public onCustomerFilterChange(id) {
        this.customerId = (id === undefined ? null  : id);
        this.reset();
        this.lazyLoadPackages();
    }
    public createQuery() {
        let urlQuery = ('?pageSize=' + this.pageSize + '&page=' +
            this.pageNumber);
        if (this.inCar) {
            urlQuery += '&isReturnedWithDriver=true';
        }
        if (this.deliverToSender) {
            urlQuery += '&isDeliveredToSender=true';
        }
        if (this.partiallyDelivered) {
            urlQuery += '&isPartiallyDelivered=true';
        }
        if (this.searchContent !== '') {
            urlQuery += '&search=' + this.searchContent;
        }
        if (this.selectedType && this.selectedType !== 'ALL') {
            urlQuery += '&type=' + this.selectedType;
        }
        if (this.datesFilterForm) {
            if (this.datesFilterForm.controls.fromDate.value) {
                urlQuery += '&fromDate=' + this.transformDate(this.datesFilterForm.controls.fromDate.value);
            }
            if (this.datesFilterForm.controls.toDate.value) {
                urlQuery += '&toDate=' +  this.transformDate(this.datesFilterForm.controls.toDate.value);
            }
            if (this.datesFilterForm.controls.fromDate.value || this.datesFilterForm.controls.toDate.value) {
                urlQuery += '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
            }
            const data = this.datesFilterForm.getRawValue();
            urlQuery += '&sortByType=' + data.sortBy.value;
        }

        if (this.customerId !== undefined && this.customerId !== null) {
                urlQuery += '&customerId=' + this.customerId;
        }
        if (this.driverId !== undefined && this.driverId !== null) {
            urlQuery += '&driverId=' + this.driverId;
        }
        if (this.selectedBarcode) {
            urlQuery += '&barcode=' + this.selectedBarcode;
        }
        if (this.isWithMassReturnedBarcode) {
            urlQuery += '&isWithMassReturnedBarcode=' + this.isWithMassReturnedBarcode;
        }
        if (this.selectedHub) {
            urlQuery += '&hubId=' + this.selectedHub;
        }

        return urlQuery;
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber,
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.datesFilterForm) {
            if (this.datesFilterForm.controls.fromDate.value) {
                params['fromDate'] =  this.transformDate(this.datesFilterForm.controls.fromDate.value);
            }
            if (this.datesFilterForm.controls.toDate.value) {
                params['toDate'] =  this.transformDate(this.datesFilterForm.controls.toDate.value);
            }
        }
        if (params['fromDate'] || params['toDate']) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        if (this.customerId !== undefined && this.customerId !== null) {
            params['customerId'] = this.customerId;
        }
        if (this.driverId !== undefined && this.driverId !== null) {
            params['driverId'] = this.driverId;
        }
        params['isDeliveredToSender'] = false;
        params['status'] = 'RETURNED_BY_RECIPIENT';
        if (this.selectedType && this.selectedType !== 'ALL') {
            params['type'] = this.selectedType;
        }
        if (this.selectedBarcode) {
            params['barcode'] = this.selectedBarcode;
        }
        return params;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public onSearch() {
        this.pageNumber = 1;
        this.returnedPackages = [];
        this.totalRecords = null;
        this.hasMore = true;
        this.isThereSelection = false;
        this.initPackages();
    }

    public onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }

        this.isThereSelection = false;
        this.returnedPackages.forEach((pkg: any) => {
            if (selected) {
                pkg.isSelected = selected;
                this.isThereSelection = true;

            } else {
                pkg.isSelected = selected;
            }
        });
    }

    public onToggleSelection(event) {
        const selectedPackages = this.getSelectedPackages();
        if (selectedPackages) {
            this.isThereSelection = selectedPackages.length !== 0;
            this.isAllSelected = selectedPackages.length === this.returnedPackages.length;
        }
    }

    private getSelectedPackages() {
        const selectedPackages = [];
        if (!this.returnedPackages) {
            return;
        }

        this.returnedPackages.forEach(
            (pkg) => {
                if (pkg.isSelected) {
                    selectedPackages.push(pkg);
                }
            }
        );
        return selectedPackages;
    }


    public unloadPackages(isScannedPkgs) {
        this.confirmMessage(isScannedPkgs);
    }
    public printReport() {
        this.isPrinting = true;
        const pkgs = this.getSelectedPackages().map((pkg) => {
            return pkg.id;
        });
        this.shipmentsService.downloadMultipleChangeStatus(pkgs, this.id).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isPrinting = false;
        }, () => {
            this.isPrinting = false;
        });
    }
    public pickWithDriver() {
        this.isPageLoading = true;
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.titleKey = 'ACCOUNTING.PICK_WITH_DRIVER';
        modal.componentInstance.showHubs = false;
        modal.componentInstance.isPickWithDriver = true;
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, driverId?: number}) => {
                if (status.isSuccess) {
                    const body = status.driverId ? {driverId: status.driverId} : {};
                    body['ids'] = this.getSelectedPackages().map((pkg) => {
                        return pkg.id;
                    });
                    this.shipmentsService.unloadReturnedPackages(body).subscribe(
                        (response) => {
                            this.translateService.get(
                                'ALERTS.PICK_PACKAGES_BY_DRIVER'
                            )
                                .subscribe(
                                    (message) => {
                                        this.confirmationService.confirm({
                                            message: this.userService.getCurrency() === 'SAR' ?  message.replace('الرواجع', 'الرجيع') : message,
                                            rejectVisible: false,
                                            acceptLabel: 'ok'
                                        });
                                    }
                                );
                            this.isPageLoading = false;
                            this.reset();
                            this.initPackages();
                        }, () => {
                            this.isPageLoading = false;
                        }
                    );
                }
            }
        ).catch(
            (error) => {
                this.isPageLoading = false;
            }
        );
    }
    public actualUnloadPackages(isScannedPkgs) {
        this.isLoading = true;
        let packages;
        if (isScannedPkgs) {
            packages = this.scannedPackages;
        } else {
            packages = this.getSelectedPackages().map((pkg) => {
                return pkg.id;
            });
        }
        this.shipmentsService.deliverCustomerReturnedPackages({ids: packages}).subscribe(() => {
            this.translateService.get(
                'ALERTS.PACKAGES_DELIVERED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        if (isScannedPkgs) {
                            this.printReturnedPackages(packages);
                        } else {
                            this.isLoading = false;
                            this.isAllSelected = false;
                            this.isThereSelection = false;
                            this.isScanning = false;
                            this.reset();
                            this.initPackages();
                        }
                    }
                );
        }, () => {
            this.isLoading = false;
            this.isScanning = false;
        });

    }
    public startScanning() {
        this.isScanning = true;
    }
    isPackageScanned(id) {
         return this.scannedPackages.indexOf(id) !== -1 ;
    }
    removePackage(shipment) {
        const index = this.scannedPackages.indexOf(shipment.id);
        this.scannedPackages.splice(index, 1);
    }
    scanPackage(shipment) {
        if (shipment) {
            if (!this.scannedPackages.includes(shipment.id)) {
                this.scannedPackages.unshift(shipment.id);
            }
            return;
        }
        this.translateService.get(['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ACCOUNTING.PACKAGE_NOT_WITH_DRIVER'],
                rejectVisible: false,
                acceptLabel: 'OK'
            });
            return;
        });
    }
    public resetScanning() {
        this.translateService.get(['RETURNED_PACKAGES.RESET_SCANNING', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['RETURNED_PACKAGES.RESET_SCANNING'],
                accept: () => {
                    this.isScanning = false;
                    this.scannedPackages = [];
                },
                reject: () => {

                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }
    enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isReturned = true;
        modal.result.then(
            (data: { isSuccess: boolean, package: any }) => {
                if (data && data.isSuccess) {
                    this.scanPackage(data.package);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    private barcodeReader(barcode) {
        if (!this.isScanning && !this.isSelecting) {
            return;
        }
        this.isLoading = true;
        this.shipmentsService.getReturnedPackageByBarcodeWithParams({barcode, 'is-in-hub': true}).subscribe((shipment: ShipmentModel) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            ).subscribe(
                (data) => {
                    if (this.isScanning) {
                        this.scanPackage(shipment);
                    } else if (this.isSelecting) {
                        this.selectPackage(shipment);
                    }
                    this.isLoading = false;
                }
                );
        }, () => {
            if (!this.muteSound) {
                playError();
            }
            this.isLoading = false;
        });
    }
    public printReturnedPackages(pkgs) {
        this.isLoading = true;
        this.translateService.get(['RETURNED_PACKAGES.PRINT_RETURNED_PACKAGES_MESSAGE', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: this.userService.getCurrency() === 'SAR' ?  res['RETURNED_PACKAGES.PRINT_RETURNED_PACKAGES_MESSAGE'].replace('الطرود المرجعة', 'الرجيع') : res['RETURNED_PACKAGES.PRINT_RETURNED_PACKAGES_MESSAGE'],
                accept: () => {
                    this.isScanning = false;
                    this.isLoading = false;
                    this.downloadMultiplePackages(pkgs);
                    this.scannedPackages = [];
                    this.initPackages();
                },
                reject: () => {
                    this.isScanning = false;
                    this.isLoading = false;
                    this.initPackages();
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    private downloadMultiplePackages(Pkgs) {
        if (!Pkgs || !Pkgs.length) {
            return;
        }

        this.isLoading = true;
         this.shipmentsService.downloadMultipleChangeStatus(Pkgs , this.id).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }
    public copyMessage($event, list?, barcode?, type?) {
        this.translateService.get('ALERTS.BARCODE_COPIED_SUCCESSFULLY').subscribe(value => {
            this.sharedService.copyMessage($event, null, barcode, value);
        });
    }


    public lazyLoadPackages() {
        // this.hubsPageIndex++;
        this.pageNumber++;
        this.initPackages();
    }
    @HostListener('scroll', ['$event'])
    onScrollTable(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 5) && !this.isLoading) {
            this.lazyLoadPackages();
        }
    }
    public initPackages() {
        if (this.isLoading || !this.hasMore) {
            return;
        }
        this.isLoading = true;
        let getPackages;
        const searchQueryList = this.prepareFiltersList();
        if ((this.inCar ||  this.deliverToSender || this.customerId || this.partiallyDelivered) || this.allReturned) {
            const urlQuery = this.createQuery();
            getPackages = this.shipmentsService.getReturnedPackagesWithoutCustomer(urlQuery, searchQueryList);
        } else {
            const params = this.createParams();
            getPackages = this.shipmentsService.getReturnedPackages(params);
        }
        const tableBodyScrollTopBeforeAddingValue = this.tableContainer && this.tableContainer.nativeElement ? this.tableContainer.nativeElement.scrollTop : 0;
        if (this.returnedPackages.length !== this.totalRecords) {
            getPackages.subscribe((response: any) => {
                const pkgToAdd = (response.pkgs || []).filter(pkg => {
                    const selectedPackage = this.returnedPackages.find(value => {
                        return pkg.barcode === value.barcode;
                    });
                    return !selectedPackage;
                });
                this.returnedPackages = [...this.returnedPackages, ...pkgToAdd];
                this.totalRecords = response.totalRecordsNo;
                if (!response || !response.pkgs || !response.pkgs.length) {
                    this.hasMore = false;
                }
                if (this.tableContainer && this.tableContainer.nativeElement) {
                    setTimeout(() => {
                        this.tableContainer.nativeElement.scrollTop = tableBodyScrollTopBeforeAddingValue - 10;
                        this.isLoading = false;
                        this.changeDetectorRef.detectChanges();
                    }, 30);
                } else {
                    this.isLoading = false;
                }

            }, error => {
                this.isLoading = false;
                console.error(error);
            });
        } else { this.isLoading = false; }
    }

    public confirmMessage(isScannedPkgs) {
        this.isPageLoading = true;
        const modal = this.modalService.open(ConfirmMessageComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.text = 'RETURNED_PACKAGES.DELIVER_TO_SENDER_MESSAGE';
        modal.result.then(
            (status: { isSuccess: boolean, isPrintReport: boolean }) => {
                if (status.isSuccess) {
                    if (status.isPrintReport) {
                        let packages;
                        if (isScannedPkgs) {
                            packages = this.scannedPackages;
                        } else {
                            packages = this.getSelectedPackages().map((pkg) => {
                                return pkg.id;
                            });
                        }
                        this.downloadMultiplePackages(packages);
                        this.scannedPackages = [];
                    }
                    this.actualUnloadPackages(isScannedPkgs);
                }
                this.isPageLoading = false;
            }
        ).catch(
            (error) => {
                this.isPageLoading = false;
            }
        );
    }
    trackShipment(shipment) {
        if (shipment) {
            this.triggeredShipment = shipment;
        }
        this.isTrackingPackage = true;
    }
    closeTracker($event) {
        this.isTrackingPackage = $event;
    }

    setTypesList() {
        return RETURNED_TYPES_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    private initSelectedType() {
        const partiallyDelivered = !!this.route.snapshot.data['partiallyDelivered'];
        const brings = !!this.route.snapshot.data['brings'];
        const swapping = !!this.route.snapshot.data['swapping'];
        const allReturned = !!this.route.snapshot.data['allReturned'];
        if (partiallyDelivered) {
            this.selectedType = '';
            this.partiallyDelivered = true;
        } else if (brings) {
            this.selectedType = 'PICKUP';
        } else if (swapping) {
            this.selectedType = 'EXCHANGE';
        } else if (allReturned) {
            this.allReturned = true;
            this.isSelecting = true;
        }
    }
    public receiveReturnedPackages() {
        let className = 'receive-driver-and-read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'receive-driver-and-read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        this.muteSound = true;
        modal.componentInstance.isReceiveReturnedPackages = true;
        modal.componentInstance.noDataAvailableMessage = 'RECEIVE_FROM_DRIVER.CHOOSE_HUB_ALERT';
        modal.componentInstance.isReturned = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
                this.muteSound = false;
            }
        ).catch(
            (error) => {
            }
        );
    }
    public receivePackagesFromDriverOrPartner(isFromPartner = false, isFromThirdParty = false) {
        let className = 'receive-driver-and-read-by-barcode';
        if (this.selectedLanguage === 'en') {
            className = 'receive-driver-and-read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        if (isFromPartner) {
            modal.componentInstance.isReceiveFromPartner = true;
        } else {
            modal.componentInstance.isReceiverFromDriver = true;
        }
        modal.componentInstance.isFromThirdParty = isFromThirdParty;

        modal.componentInstance.isReturnedWith = true;
        modal.componentInstance.isReturned = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {}
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    extractQueryFilter() {
        const params: any = this.route.snapshot.queryParams;
        const fillFiltersFromQuery = params.fillFiltersFromQuery;
        this.fromDate = params.fromDate || this.fromDate;
        this.toDate = params.toDate || this.toDate;

        if (fillFiltersFromQuery) {
            this.inCar = params.inCar == 'true';
            this.deliverToSender = params.deliverToSender == 'true';
            this.partiallyDelivered = params.partiallyDelivered == 'true';
            this.selectedType = params.selectedType;
        }
    }

    public closeSenderFilter() {
        this.senderRegionPopOver.close();
    }
    public onFilterSenderReceiverRegion(data) {
            if (data.customer) {
                this.searchParameters['receiverName'] = {'value': data.customer};
            } else {
                delete this.searchParameters['receiverName'];
            }

            if (data.regionId) {
                this.searchParameters['originRegionId'] = data.regionId;
            } else {
                delete this.searchParameters['originRegionId'];
            }

            if (data.cityId) {
                this.searchParameters['originCityId'] = data.cityId;
            } else {
                delete this.searchParameters['originCityId'];
            }

            if (data.villageId) {
                this.searchParameters['originVillageId'] = data.villageId;
            } else {
                delete this.searchParameters['originVillageId'];
            }
            this.senderRegionData.customerName = this.searchParameters['receiverName'] ? this.searchParameters['receiverName'].value : '';
            this.senderRegionData.originRegion = this.searchParameters['originRegionId'];
            this.senderRegionData.originCity = this.searchParameters['originCityId'];
            this.senderRegionData.originVillage = this.searchParameters['originVillageId'];
            if (this.senderRegionPopOver && this.senderRegionPopOver.isOpen()) {
                this.senderRegionPopOver.close();
            }
        this.onSearch();

    }
    public prepareFiltersList() {
        const filtersList = [];
        for (const [key, value] of Object.entries(this.searchParameters)) {
            if (value['value']) {
                if (key.includes('Date')) {
                    // @ts-ignore
                    filtersList.push({fieldName: key, ...value, value: this.transformDate(value['value'])});
                } else {
                    if (value['value'] !== 'ALL') {
                        // @ts-ignore
                        filtersList.push({fieldName: key, ...value});
                    }
                }
            }
        }
        return filtersList;
    }

    public initSearchParams() {
        this.searchParameters = {
            barcode: {
                value: ''
            },
            receiverPhone: {
                value: ''
            },
            deliveryDate: {
                value: ''
            },
            expectedDeliveryDate: {
                value: ''
            },
            expectedPickupDate: {
                value: ''
            },
            invoiceNumber: {
                value: ''
            },
            postponedDeliveryDate: {
                value: ''
            },
            createdDate: {
                value: ''
            },
            firstPickupDate : {
                value: ''
            },
            notes: {
                value: ''
            },
            serviceType: {
                value: 'ALL'
            },
            isCostEqualZero : {
                value: false
            },
            driverId: {
                value: 0
            },
            codDriverId: {
                value: 0
            },
            codHubId: {
                value: 0
            },
            serviceTypeId: {
                value: 0
            },
            description: {
                value: ''
            }
        };
    }

    public onSelectedHub (hubId) {
        this.selectedHub = hubId;
        this.onSearch();
    }

    public readByBarcodeScanner(barcode) {
        const selectedPackage = this.returnedPackages.find(value => {
            return barcode === value.barcode;
        });
        if (selectedPackage) {
            if (selectedPackage.isSelected) {
                this.getExistPackageMessage();
                return;
            }
            selectedPackage.isSelected = true;
            const packageIndex = this.returnedPackages.findIndex(value => {
                return barcode === value.barcode;
            });
            this.returnedPackages.splice(packageIndex, 1);
            this.returnedPackages = [selectedPackage, ...this.returnedPackages];
            this.onToggleSelection({});
            this.showSuccessMessage();
            return;
        }

        this.barcodeReader(barcode);
    }

    private selectPackage(shipment: any) {
        shipment.isSelected = true;
        const selectedPackage = this.returnedPackages.find(value => {
            return shipment.barcode === value.barcode;
        });
        if (!selectedPackage) {
            this.returnedPackages = [shipment, ...this.returnedPackages];
            this.onToggleSelection({});
            this.showSuccessMessage();
        }
    }

    public getExistPackageMessage() {
        this.messageService.add({
            severity: 'info',
            detail: this.translateService.instant('ALERTS.PACKAGE_ALREADY_SCANNED')
        });
    }

    public showSuccessMessage() {
        this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('ALERTS.PACKAGE_SCANNED_SUCCESSFULLY')
        });
    }

    showFiltersMenu(event) {
        event.stopPropagation();
        this.filtersMenu.toggle(event);
        this.filterMenuOpen = true;
    }

    onHideFiltersMenu() {
        this.filterMenuOpen = false;
    }

    resetAllFilters() {
        this.datesFilterForm.controls.sortBy.setValue({value: 'CREATED_DATE'});
        this.initPackages();
    }

    exportExcel() {
        this.confirmationService.confirm({
            message: this.translateService.instant('ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'),
            accept: () => {
                this.isExportingExcel = true;
                this.shipmentsService.exportExcelReceivingPackages({searchQueryList: this.prepareFiltersList(), ids: this.getSelectedPackages().map(pkg => pkg.id)} , this.createParams()).subscribe((response: any) => {
                    window.open(response.url, '_blank');
                    this.isExportingExcel = false;
                }, error => {
                    this.isExportingExcel = false;
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    partialExport() {
        this.isLoading = true;
        this.translateService.get(['ALERTS.PARTIAL_CONFIRM', 'GENERAL.YES', 'GENERAL.NO']).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.PARTIAL_CONFIRM'],
                reject: () => {
                    this.isLoading = false;
                },
                accept: () => {
                    this.partialExportSend();
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }

    public partialExportSend() {
        const selectedPackages = this.getSelectedPackages();
        const body = {
            ids: selectedPackages.map(pkg => pkg.id)
        };
        this.shipmentsService.partiallyExport(this.customerId, body).subscribe((res: any) => {
            this.partialExportedSuccessfully(res);
        }, () => {
            this.isLoading = false;
        });
    }

    private partialExportedSuccessfully(pkg) {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.PRINT_RETURN_PACKAGE_PARTIALLY'),
            reject: () => {
                this.isLoading = false;
                this.onSearch();
            },
            accept: () => {
                const body = [{
                    id: pkg.customerId,
                    barcode: pkg.barcode
                }];

                const params = <any>{
                    utcOffset: moment().utcOffset().toString(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                };
                if (this.selectedType && this.selectedType !== 'ALL') {
                    params['type'] = this.selectedType;
                }
                if (this.inCar) {
                    params['isReturnedWithDriver'] = true;
                    params['driverId'] = this.driverId;
                }
                if (this.partiallyDelivered) {
                    params['isPartiallyDelivered'] = true;
                }

                if (this.deliverToSender) {
                    params['isDeliveredToSender'] = true;
                } else {
                    params['returnPackageStatus'] =  this.inCar ? 'DRIVER' : 'HUB';
                }
                this.shipmentsService.printCustomerReturnedPackages(body,  params).subscribe((res: any) => {
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translateService.instant('ALERTS.PACKAGES_EXPORTED_SUCCESSFULLY')
                    });
                    this.isLoading = false;
                    window.open(res.url, '_blank');

                }, (err) => {
                    console.error(err);
                }, ()=> {
                    this.onSearch();
                });
            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    createBundle() {
        const modal = this.modalService.open(ChooseCustomerComponent, <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
        });
        modal.componentInstance.chooseSourceOnly = true;
        modal.componentInstance.selectedSourceType = 'CUSTOMER';
        modal.componentInstance.hideSelectOption = true;
        modal.result.then(
            (status) => {
                console.log(status);
                if (status.isSuccess) {
                    this.navigateTo('change-status', {isBundle: true, customerId: status.customerId, isReturned: true});

                } else {// error adding customer
                }
            });
    }

    createBundleFromSelected() {
        this.collectBundle();
    }

    public collectBundle() {
        const modal = this.modalService.open(ChooseCustomerComponent, <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
        });
        modal.componentInstance.fromBundle = true;
        modal.componentInstance.selectedSourceType = 'HUB';
        modal.componentInstance.selectedDestinationType = 'CUSTOMER';
        modal.componentInstance.hideSelectOption = true;
        modal.componentInstance.chooseSourceOnly = true;
        modal.result.then(
            (status) => {
                if (status.isSuccess && status.result) {
                    const data = status.result;
                    const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
                    const body = {ids: selectedPackages};
                    if (data.sourceHubId) {
                        body['sourceHubId'] = data.sourceHubId;
                        body['destinationHubId'] = data.destinationHubId;
                        body['customerId'] = this.customerId;
                    } else {
                        body['customerId'] = this.customerId;

                        if (data.destinationHubId) {
                            body['destinationHubId'] = data.destinationHubId;
                        } else {
                            body['destinationAddress'] = data.destinationAddress;
                        }
                    }
                    this.isLoading = true;
                    (this.shipmentsService.createBundleForReturned(body)).subscribe(
                        (response: any) => {
                            this.initPackages();
                            this.isLoading = false;
                            this.trackShipment(response);
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        }
                    );
                }
            });
    }


    public navigateTo(navigateTo, queryParams = {}) {
        this.router.navigate([this.authenticationService.companyName + '/home/' + navigateTo],
            {queryParams: queryParams});
    }
}
