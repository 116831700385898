<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="technical-support-tickets-container" [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <p-overlayPanel #ticketsFiltersMenu
                    class="tickets-filters-menu"
                    [dismissable]="true"
                    (onHide)="onHideFiltersMenu()"
                    [showCloseIcon]="false">
        <form [formGroup]="filtersForm">
            <div class="tickets-filters-menu-container">
                <div class="filters-header">
                    <div class="filters-menu-title">
                        <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                    </div>
                </div>
                <div class="filters-inputs-group">
                    <div class="auto-complete-filter-input-container" *ngIf="isAdmin">
                        <div class="auto-complete-filter-name d-flex">
                            <div class="auto-complete-filter-title">
                                <span>{{'TICKETING_SYSTEM.TABLE.CLIENT' | translate}}</span>
                            </div>
                            <div class="auto-complete-drop-down flex-1">
                                <p-autoComplete formControlName="clientName"
                                                type="text"
                                                [forceSelection]="true"
                                                [suggestions]="usersList"
                                                (completeMethod)="getClients($event)"
                                                (onSelect)="chooseClient($event)"
                                                styleClass="auto-complete-filter-selection"
                                                #clientName
                                >
                                    <ng-template let-client pTemplate="item">
                                        <div class="store-item">
                                            <div class="store-name text-ellipsis">{{getUserName(client)}}</div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                            <div class="close-edit-icons">
                                <i class="fas fa-chevron-down arrow-icon" *ngIf="!editClient"
                                   (click)="editClientName()"></i>
                                <i class="fas fa-times close-refresh-soup" *ngIf="editClient"
                                   (click)="editClient = false"></i>
                            </div>
                        </div>
                    </div>

                    <div class="auto-complete-filter-input-container">
                        <div class="auto-complete-filter-name d-flex">
                            <div class="auto-complete-filter-title">
                                <span>{{'TICKETING_SYSTEM.TABLE.CATEGORY' | translate}}</span>
                            </div>
                            <div class="auto-complete-drop-down flex-1">
                                <p-autoComplete formControlName="categoryName"
                                                type="text"
                                                [forceSelection]="true"
                                                [suggestions]="categoriesList"
                                                (completeMethod)="getCategories($event)"
                                                (onSelect)="chooseCategory($event)"
                                                styleClass="auto-complete-filter-selection"
                                                #categoryName
                                >
                                    <ng-template let-category pTemplate="item">
                                        <div class="store-item">
                                            <div class="store-name text-ellipsis">{{category.name}}</div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                            <div class="close-edit-icons">
                                <i class="fas fa-chevron-down arrow-icon" *ngIf="!editCategory"
                                   (click)="editCategoryName()"></i>
                                <i class="fas fa-times close-refresh-soup" *ngIf="editCategory"
                                   (click)="editCategory = false"></i>
                            </div>
                        </div>
                    </div>
                    <div class="ticket-client-input input-field-container">
                        <div class="label">
                            <span>{{'TICKETING_SYSTEM.TABLE.PRIORITY' | translate}}</span>
                        </div>
                        <div class="input">
                            <p-dropdown
                                    formControlName="priority"
                                    [options]="ticketPrioritiesList"
                                    styleClass="tickets-table-filter-drop-down"
                                    [style]="{'width':'100%'}"
                                    filter="true">
                            </p-dropdown>
                        </div>
                    </div>

<!--                    <div class="ticket-client-field-input field-input-field-container">-->
<!--                        <div class="label">-->
<!--                            <span>{{'TICKETING_SYSTEM.TABLE.STATUS' | translate}}</span>-->
<!--                        </div>-->
<!--                        <div class="field-input">-->
<!--                            <p-dropdown formControlName="status"-->
<!--                                        styleClass="tickets-table-filter-drop-down"-->
<!--                                        [options]="ticketStatusList"-->
<!--                                        [style]="{'width':'100%'}"-->
<!--                                        filter="true">-->
<!--                            </p-dropdown>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="auto-complete-filter-input-container">
                        <div class="auto-complete-filter-name d-flex">
                            <div class="auto-complete-filter-title">
                                <span>{{'TICKETING_SYSTEM.TABLE.CUSTOMER_NAME' | translate}}</span>
                            </div>
                            <div class="auto-complete-drop-down flex-1">
                                <p-autoComplete formControlName="storeName"
                                                type="text"
                                                [forceSelection]="true"
                                                [suggestions]="storesList"
                                                (completeMethod)="getStores($event)"
                                                (onSelect)="chooseStore($event)"
                                                styleClass="auto-complete-filter-selection"
                                                #storeName
                                >
                                    <ng-template let-customer pTemplate="item">
                                        <div class="store-item">
                                            <div class="store-name text-ellipsis">{{customer.fullName}}{{customer.businessName ? ' - ' + customer.businessName : ''}}</div>
                                            <div class="phone-number">{{customer.phone1}}</div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                            <div class="close-edit-icons">
                                <i class="fas fa-chevron-down arrow-icon" *ngIf="!editStore"
                                   (click)="editStoreName()"></i>
                                <i class="fas fa-times close-refresh-soup" *ngIf="editStore"
                                   (click)="editStore = false"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="filters-menu-actions">
                    <div class="reset-action filter-menu-action" (click)="resetFilters()">
                        <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                    </div>
                    <div class="apply-filters-action filter-menu-action" (click)="lazyLoadTickets(true)">
                        <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                    </div>
                </div>

            </div>

        </form>
    </p-overlayPanel>
    <div class="header-content">
        <div class="title-container d-flex">
            <div class="title-label">
                <span>{{'TICKETING_SYSTEM.TECHNICAL_SUPPORT_TICKETS_TITLE' | translate}}</span>
            </div>
            <div class="tickets-count">
                <span>{{totalRecordsNo}}</span>
            </div>

            <div class="new-tickets-count flex-2d-center" *ngIf="label === 'ALLTICKETS' || newTicketsNumber">
                <div class="label">{{'TICKETING_SYSTEM.NEW' | translate}}</div>
                <div class="value">{{newTicketsNumber}}</div>
            </div>
        </div>

        <div class="filters-and-action-container d-flex">
            <div class="add-ticket-action d-flex" (click)="showAddTicket()">
                <div class="icon">
                    <img src="../../assets/icons/ticketing-system/add-ticket.png" alt=""/>
                </div>
                <div class="label">
                    <span>{{'TICKETING_SYSTEM.ADD_TICKET' | translate}}</span>
                </div>
            </div>
            <div class="add-ticketing-user d-flex"
                 *ngIf="canAddUser()"
                 (click)="createUser()">
                <div class="icon">
                    <img src="../../../assets/icons/plus.svg" alt="" />
                </div>
                <div class="label">
                    <span>{{'TICKETING_SYSTEM.ADD_TICKETING_USER' | translate}}</span>
                </div>
            </div>
            <div class="splitter-container hidden-sm-down">
                <div class="splitter"></div>
            </div>
            <div class="order-by d-flex">
                <div class="order-by-label">
                    <span>{{'TICKETING_SYSTEM.SORT_BY' | translate}}</span>
                </div>
                <div class="order-by-list">
                    <p-dropdown
                            styleClass="tickets-sort-by-list"
                            [(ngModel)]="selectedOrderByOption"
                            [options]="orderByOptions" filter="true"
                            (onChange)="lazyLoadTickets(true)"
                            [style]="{'width':'100%'}"
                            filter="true">
                    </p-dropdown>
                </div>
            </div>
            <div class="date-range-filter">
                <form [formGroup]="dateFilterForm" class="d-flex flex-row filter">
<!--                    <div class="data-search date-picker-filter cursor-pointer">-->
<!--                              <span class="date-icon">-->
<!--                                <img src="../../assets/icons/manage_shipments/calendar.svg" alt="">-->
<!--                            </span>-->
<!--                        <span class="lg lg-calendar" (click)="datepicker.click()"></span>-->
<!--                        <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                               #datepicker-->
<!--                               daterangepicker [options]="dateRangPickerOptions"-->
<!--                               (selected)="onDateSelected($event)"/>-->
<!--                        <i class="fas fa-caret-down"></i>-->
<!--                    </div>-->
                    <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>
                </form>
            </div>
            <div class="toggle-filters-btn">
                <div class="icon" (click)="showFiltersMenu($event)">
                    <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                    <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                </div>
            </div>
            <div class="toggle-filters-btn">

            <div class="row customization-button-content" [ngbPopover]="customizePopOver" [container]="'body'" [placement]="'auto'" [autoClose]="'outside'">
                <div><img src="../../../assets/icons/dashboard-customization-icon.svg" alt=""/></div>
                <div class="customize-btn-label">{{'DASHBOARD.CUSTOMIZE' | translate}}</div>
                <div class="icon">
                    <i class="fas fa-caret-down"></i>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="main-container">
        <div class="tickets-table">
            <div class="tickets-body overflow-auto flex-1" (scroll)="onScrollTable($event)">
                <p-table [value]="tickets" scrollHeight="auto"
                         [resizableColumns]="true" [autoLayout]="true"
                         styleClass="tickets-table data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <!--                            <th class="select-ticket-checkbox checkbox-field-input">-->
                            <!--                                <p-checkbox></p-checkbox>-->
                            <!--                            </th>-->
                            <th class="user-image-th"></th>

                            <th *ngIf="tableCustomization['createdBy']" class="created-by-name-th auto-complete-filter-th">
                                <div class="d-flex justify-content-center">
                                    <div class="auto-complete-filter d-flex" [ngClass]="{'active-auto-complete-filter': selectedCreatedBy !== null}">
                                    <div class="auto-complete-filter-label flex-2d-center">
                                        <span>{{'TICKETING_SYSTEM.TABLE.CREATED_BY' | translate}}</span>
                                    </div>
                                    <p-autoComplete type="text"
                                                    [forceSelection]="true"
                                                    [delay]="700"
                                                    [ngModel]="selectedCreatedBy?.label"
                                                    [suggestions]="storesOptions"
                                                    (completeMethod)="getUsersOptions($event)"
                                                    (onSelect)="chooseCreatedByFilter($event)"
                                                    (onClear)="clearCreatedByFilter()"
                                                    styleClass="auto-complete-filter"
                                                    appendTo="body"
                                                    #createdByFilter
                                    >
                                        <ng-template let-createdBy pTemplate="item">
                                            <div class="store-item">
                                                <div class="store-name text-ellipsis">{{createdBy.label}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <div class="close-edit-icons cursor-pointer">
                                        <i class="fas fa-chevron-down arrow-icon" *ngIf="!editClient"
                                           (click)="openCreatedByFilterList()"></i>
                                        <i class="fas fa-times close-refresh-soup" *ngIf="editClient"
                                           (click)="editCreatedByFilter = false"></i>
                                    </div>
                                </div>
                                </div>
                            </th>
                            <th *ngIf="isShowCustomerFieldWhileAddingTicket" class="user-image-th"></th>
                            <th *ngIf="isShowCustomerFieldWhileAddingTicket" class="user-name-th"><span
                                    class="th-label ">{{'TICKETING_SYSTEM.TABLE.NAME' | translate}}</span></th>
                            <th class="issue-title-th"><span
                                    class="th-label">{{'TICKETING_SYSTEM.TABLE.ISSUE_TITLE' | translate}}</span></th>
                            <!--                            <th class="ticket-tag-th"></th>-->
                            <th *ngIf="tableCustomization['createdDate']" class="created-date-th"><span
                                    class="th-label">{{'TICKETING_SYSTEM.TABLE.CREATED_DATE' | translate}}</span></th>
                            <th *ngIf="tableCustomization['category']" class="section-group-th ticket-drop-down-th">{{'TICKETING_SYSTEM.TABLE.SENDER_CATEGORY' | translate}}</th>
                            <th *ngIf="tableCustomization['assignee']" class="customer-list-th ticket-drop-down-th">
                                <div class="d-flex justify-content-center">
                                    <div class="auto-complete-filter d-flex" [ngClass]="{'active-auto-complete-filter': selectedAssignee !== null}">
                                        <div class="auto-complete-filter-label flex-2d-center">
                                            <span>{{'TICKETING_SYSTEM.TABLE.CLIENT' | translate}}</span>
                                        </div>
                                        <p-autoComplete type="text"
                                                        [forceSelection]="true"
                                                        [delay]="700"
                                                        [ngModel]="selectedAssignee?.label"
                                                        [suggestions]="storesOptions"
                                                        (completeMethod)="getUsersOptions($event)"
                                                        (onSelect)="chooseAssigneeFilter($event)"
                                                        (onClear)="clearAssigneeFilter()"
                                                        styleClass="auto-complete-filter"
                                                        appendTo="body"
                                                        #assigneeFilter
                                        >
                                            <ng-template let-createdBy pTemplate="item">
                                                <div class="store-item">
                                                    <div class="store-name text-ellipsis">{{createdBy.label}}</div>
                                                </div>
                                            </ng-template>
                                        </p-autoComplete>
                                        <div class="close-edit-icons cursor-pointer">
                                            <i class="fas fa-chevron-down arrow-icon" *ngIf="!editClient"
                                               (click)="openAssigneeFilterList()"></i>
                                            <i class="fas fa-times close-refresh-soup" *ngIf="editClient"
                                               (click)="editAssigneeFilter = false"></i>
                                        </div>
                                    </div>
                                </div>

                            </th>
                            <th *ngIf="tableCustomization['priority']" class="priority-th"><span
                                    class="th-label">{{'TICKETING_SYSTEM.TABLE.PRIORITY' | translate}}</span></th>

                            <th *ngIf="tableCustomization['status']" class="header-multiselect status-list-th ticket-drop-down-th">
                                <div class="status-filter-container">
                                    <span class="status-title">{{"TICKETING_SYSTEM.TABLE.STATUS" | translate}}</span>
                                    <p-multiSelect [options]="ticketStatusList"
                                                   [(ngModel)]="selectedTicketStatus"
                                                   (onChange)="getTickets(true)"
                                                   [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                                   appendTo="body"
                                                   defaultLabel=""
                                                   optionLabel="label"
                                                   optionValue="value">
                                        <ng-template let-shipmentCategory pTemplate="item">
                                            {{ shipmentCategory.label | translate }}
                                        </ng-template>
                                    </p-multiSelect>
                                </div>
                            </th>
                            <th *ngIf="tableCustomization['replyStatus']" class="header-multiselect status-list-th ticket-drop-down-th">
                                <div class="status-filter-container">
                                    <span class="status-title">{{"TICKETING_SYSTEM.TABLE.REPLAY_STATUS" | translate}}</span>
                                    <p-multiSelect [options]="ticketReplayStatusList"
                                                   [(ngModel)]="selectedTicketReplayStatus"
                                                   (onChange)="getTickets(true)"
                                                   appendTo="body"
                                                   maxSelectedLabels="2"
                                                   selectedItemsLabel="{{ '{2} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                                   defaultLabel=""
                                                   optionLabel="label"
                                                   optionValue="value">
                                        <ng-template let-shipmentCategory pTemplate="item">
                                            {{ shipmentCategory.label | translate }}
                                        </ng-template>
                                    </p-multiSelect>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <!--                    <ng-template pTemplate="emptymessage">-->
                    <!--                        <div *ngIf="!isLoading" class="empty-content">-->
                    <!--                            <app-no-data-available-component></app-no-data-available-component>-->
                    <!--                        </div>-->
                    <!--                    </ng-template>-->
                    <ng-template pTemplate="body" let-ticket>
                        <tr class="ticket-row" [ngClass]="{'new-ticket-row': ticket.status === 'NEW'}" (click)="showTicketDetails(ticket.id)">
                            <!--                            <td class="select-ticket-checkbox checkbox-field-input">-->
                            <!--                                <p-checkbox></p-checkbox>-->
                            <!--                            </td>-->
                            <td><i *ngIf="ticket.categoryId == userCategoryId" [tooltip]="'TICKETING_SYSTEM.INTERNAL_TICKET' | translate" class="fa fa-duotone fa-flag"></i></td>
                            <td *ngIf="tableCustomization['createdBy']" class="created-by-name-td">
                                <div class="user-name-container td-string-value created-by-name">
                                    <span>{{getUserName(ticket.createdBy)}}</span>
                                </div>
                            </td>
                            <td *ngIf="isShowCustomerFieldWhileAddingTicket" class="user-image-td">
                                <div class="user-image-container">
                                    <app-upload-image-component
                                            class="user-image-upload"
                                            [showClear]="false"
                                            [canUpload]="false"
                                            [url]="ticket.customer.imageUrl"
                                            [imageSize]="40"
                                            [defaultLogo]="'../../../../assets/icons/ticketing-system/user-avatar.png'"
                                    >
                                    </app-upload-image-component>
                                </div>
                            </td>
                            <td *ngIf="isShowCustomerFieldWhileAddingTicket" class="user-name-td">
                                <div class="user-name-container td-string-value">
                                    <span>{{getUserName(ticket.customer)}}</span>
                                </div>
                            </td>
                            <td class="issue-title-td">
                                <div class="issue-title-container td-string-value">
                                    <p>{{ticket.title}}</p>
                                </div>
                            </td>
<!--                            <td class="ticket-tag-td">-->
<!--                                <div class="ticket-tag-container">-->
<!--                                    <div class="tag">-->
<!--                                        <span>جديدة</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
                            <td *ngIf="tableCustomization['createdDate']" class="created-date-td">
                                <div class="created-date">
                                    <span>{{transformDate(ticket.createdDate, false, true)}} </span>
                                </div>
                            </td>
                            <td *ngIf="tableCustomization['category']" class="section-group-td ticket-drop-down-td" (click)="stopProp($event)">
                                <div class="section-group-container ticket-drop-down">
                                    <app-custom-drop-down-list
                                            [disabled]="true"
                                            [styleClass]="'custom-drop-down-options'"
                                            [isSearchable]=true
                                            [selectedValue]="ticket.sourceCategory.id"
                                            [selectedLabel]="ticket.sourceCategory.name"
                                            [isLoading]="getCategoriesLoader"
                                            (onShowList)="getCategoriesOptions($event)"
                                            (search)="getCategoriesOptions($event)"
                                            (selectOption)="updateFromTable(ticket.id, $event, 'CATEGORY')"
                                            [list]="categoriesOptions">
                                    </app-custom-drop-down-list>
                                </div>
                            </td>
                            <td *ngIf="tableCustomization['assignee']" class="customer-list-td ticket-drop-down-td" (click)="stopProp($event)">
                                <div class="customer-list-container ticket-drop-down">
                                    <app-custom-drop-down-list
                                            [disabled]="ticket.categoryId != userCategoryId"
                                            [styleClass]="'custom-drop-down-options'"
                                            [isSearchable]=true
                                            [selectedValue]="ticket.assigneeId"
                                            [selectedLabel]="getUserName(ticket.assignee)"
                                            (onShowList)="getUsersOptions($event, ticket.categoryId)"
                                            (search)="getUsersOptions($event, ticket.categoryId)"
                                            [isLoading]="getUsersLoader"
                                            (selectOption)="updateFromTable(ticket.id, $event, 'CLIENT')"
                                            [list]="storesOptions">
                                    </app-custom-drop-down-list>
                                </div>
                            </td>
                            <td *ngIf="tableCustomization['priority']" class="priority-td">
                                <div class="priority-container">
                                    <div class="point"
                                         tooltipPosition="top"
                                         pTooltip="{{ticket.priority | translate}}">
                                        <span [style.backgroundColor]="getPriorityColor(ticket.priority)"></span>
                                    </div>
                                    <div class="td-value">
                                        <span>{{ticket.priority | translate}}</span>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="tableCustomization['status']" class="status-list-td ticket-drop-down-td" (click)="stopProp($event)">
                                <div class="status-list-container ticket-drop-down">
                                    <app-custom-drop-down-list
                                            [disabled]="ticket.categoryId != userCategoryId || (ticket.status == 'DONE' && !isAdmin)"
                                            [list]="ticketStatusList"
                                            [selectedValue]="ticket.status"
                                            (selectOption)="updateFromTable(ticket.id, $event, 'STATUS')"
                                            [styleClass]="'tickets-status-options'">
                                    </app-custom-drop-down-list>
                                </div>
                            </td>
                            <td *ngIf="tableCustomization['replyStatus']">
                                <div>
                                    <span>{{ticket.replyStatus | translate}}</span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader [type]="1" *ngIf="isLoading"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<app-add-ticket-form *ngIf="displayAddTicket"
                     [display]="displayAddTicket"
                     (completeAddTicket)="lazyLoadTickets(true)"
                     (closeWidget)="onHideAddTicket($event)">
</app-add-ticket-form>

<app-ticket-details
        [display]="displayTicketDetails"
        [(triggeredTicketId)]="triggeredTicketId"
        (ticketChange)="lazyLoadTickets(true)"
        (closeWidget)="closeTicketDetails($event)">
</app-ticket-details>

<ng-template #customizePopOver let-close="close">
    <div style="padding: 1rem;">
    <ng-container *ngFor="let column of columnsDef">
        <div class="manage-shipment-customize-switch switch-button ticketing-switch">
            <p-inputSwitch [(ngModel)]="tableCustomization[column.id]"
                           (onChange)="customizeTable(column.id, $event)"></p-inputSwitch>
            <span class="customize-item-label"
                  [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{(column.label) | translate}}</span>
        </div>
    </ng-container>
    </div>
</ng-template>
