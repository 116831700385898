import {Component, OnInit} from '@angular/core';
import {ShipmentsService} from '../services/shipments.service';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {AddShipmentQuickComponent} from '../add-shipment-quick/add-shipment-quick.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PostponePackageComponent} from '../postpone-package/postpone-package.component';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import { Companies } from 'src/customCompanies/companies.pal-ship';

@Component({
    selector: 'app-package-history',
    templateUrl: './package-history.component.html',
    styleUrls: ['./package-history.component.scss']
})
export class PackageHistoryComponent implements OnInit {
    public packagesHistory = [];
    private id = 0;
    public signatureImage = '';
    public deliveryProofUrlList = [];
    public signatureUrl = '';
    public package = null;
    public pkgStatus = '';
    public permissions = '';
    selectedLanguage = 'ar';
    public byBarcode = false;
    public isLoading = false;
    public companyId;
    public role = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private shipmentsService: ShipmentsService,
                private route: ActivatedRoute,
                private translateService: TranslateService,
                private messageService: MessageService,
                private userService: UserService,
                private rolesService: RolesService,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        this.isLoading = true;
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.companyId = this.userService.userInfo.companyId;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.route.params.subscribe(
            (params: Params) => {
                this.id = params.id;
                this.getPackagesHistory();

            }
        );
    }

    getPackagesHistory() {
        this.shipmentsService.getPackageHistory(this.id).subscribe((response: any) => {
            this.packagesHistory = response.items;
            this.package = response.pkg;
            this.pkgStatus = response.massPackageStatus;
            this.getPackageSignature(response.pkg.id);
        }, error1 => {
            this.isLoading = false;
        });
    }

    getPackageSignature(id) {
        this.shipmentsService.getPackageSigniture(id).subscribe((response: any) => {
            if (response !== null) {
                if (response.signatureUrl !== undefined && response.signatureUrl !== null) {
                    this.signatureImage = response.signatureUrl;
                }
                if (response.deliveryProofUrlList !== undefined && response.deliveryProofUrlList !== null) {
                    this.deliveryProofUrlList = response.deliveryProofUrlList;
                }
            }
            this.isLoading = false;
        }, error1 => {
            this.isLoading = false;
        });
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get(
            'ALERTS.COPIED'
        )
            .subscribe(
                (data) => {
                    this.messageService.add({severity: 'success', detail: data});
                }
            );
    }

    public printMassPackagePdf() {
        this.isLoading = true;
        this.shipmentsService.downloadMassPackagePdf(this.package.massCodPackageId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            });
    }
    public getLocalTime (dateTime) {
        if (dateTime) {
            return new Date(dateTime).toLocaleTimeString();
        }
        return;
    }
    public downloadPdf(pkgId: number) {
        this.isLoading = true;
        this.shipmentsService.downloadPdf(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            });
    }
    public editPackage() {
        const modal = this.modalService.open(AddShipmentQuickComponent, {
            backdrop: 'static',
            windowClass: 'create-new-package',
            size: 'lg'
        });
        modal.componentInstance.pkgId = this.package.id;
        modal.componentInstance.isEditMode = true;

        modal.result.then(
            (data: {isSuccess: boolean}) => {
                if (data.isSuccess) {
                    this.getPackagesHistory();
                }
            }
        );
    }
    public onPostponePackage() {
        const modal = this.modalService.open(PostponePackageComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.shipment = this.package;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.getPackagesHistory();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }
    public onChangePackageStatus() {
        const modal = this.modalService.open(ChangePackageStatusComponent,
            <any>{size: 'md', windowClass: 'package-status-popover', backdrop: 'static'});
        modal.componentInstance.shipment = this.package;
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.getPackagesHistory();
                }
            }, err => {
                console.error(err);
            }
        ).catch(
            (caughtError) => {
                console.error(caughtError);
            }
        );
    }

}
