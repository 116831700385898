export const ADMINISTRATION_CONSTANTS = {
    ZONES_PAGE_SIZE: 1000,
    GET_SYSTEM_VILLAGES: ':api/addresses/villages',
    GET_REGIONS: ':api/admin/addresses/regions',
    GET_REGION_CITIES: ':api/admin/addresses/regions/{regionId}/cities',
    GET_CITY_VILLAGES: ':api/admin/addresses/cities/{cityId}/villages',
    ZONE_GET_REGIONS: ':api/addresses/regions',
    ZONE_GET_REGION_CITIES: ':api/addresses/regions/{regionId}/cities',
    ZONE_GET_CITY_VILLAGES: ':api/addresses/cities/{cityId}/villages',
    ZONE_GET_SELECTED_COMPANY_VILLAGES: ':api/addresses/villages',
    ZONE_GET_ALL_REGIONS: ':api/addresses/all-regions',
    ZONE_GET_REGION_ALL_CITIES: ':api/addresses/regions/{regionId}/all-cities',
    ZONE_GET_CITY_ALL_VILLAGES: ':api/addresses/cities/{cityId}/all-villages',
    ADD_NEW_REGION: ':api/admin/addresses/regions/new',
    ADD_NEW_CITY: ':api/admin/addresses/cities/new',
    ADD_NEW_VILLAGE: ':api/admin/addresses/villages/new',
    GET_CITIES_NOT_ASSIGNED: ':api/admin/addresses/cities/not-assigned',
    GET_VILLAGES_NOT_ASSIGNED: ':api/admin/addresses/villages/not-assigned',
    ASSIGN_CITY: ':api/admin/addresses/regions/{regionId}/cities/{id}/assign',
    ASSIGN_VILLAGE: ':api/admin/addresses/cities/{cityId}/villages/{id}/assign',
    SELECT_REGION: ':api/admin/addresses/regions/{id}/select',
    UNSELECT_REGION: ':api/admin/addresses/regions/{id}/un-select',
    SELECT_CITY: ':api/admin/addresses/cities/{id}/select',
    UNSELECT_CITY: ':api/admin/addresses/cities/{id}/un-select',
    SELECT_VILLAGE: ':api/admin/addresses/villages/{id}/select',
    UNSELECT_VILLAGE: ':api/admin/addresses/villages/{id}/un-select',
    UNASSIGN_CITY: ':api/admin/addresses/cities/{id}/unassign',
    UNASSIGN_VILLAGE: ':api/admin/addresses/villages/{id}/unassign',
    VILLAGE_LOCATION: ':api/admin/addresses/villages/{id}/location',
    DELETE_REGION: ':api/admin/addresses/regions/{id}/delete',
    GET_COMPANY_THEME: ':api/admin/companies/company/theme',
    CREATE_PARCEL: ':api/admin/packages/parcels/new',
    GET_PARCELS: ':api/admin/packages/parcels',
    DELETE_PARCEL: ':api/admin/packages/parcels/{id}/delete',
    UPDATE_PARCEL: ':api/admin/packages/parcels/{id}/update',
    GET_SHIPPING_LINES: ':api/admin/addresses/shipping-lines',
    EDIT_SHIPPING_LINE: ':api/admin/addresses/shipping-lines/{shippingLineId}',
    ADD_SHIPPING_LINE: ':api/admin/addresses/shipping-lines',
    ADD_VILLAGE_TO_LINE: ':api/admin/addresses/shipping-lines/{shippingLineId}/{villageId}',
    GET_BANK_TRANSFERS: ':api/admin/bank-transfers',
    APPROVE_MOB: ':api/admin/mobs/{mobId}/approve',
    REJECT_MOB: ':api/admin/mobs/{mobId}/reject',
    UNASSIGN_MULTIPLE_VILLAGE: ':api/admin/addresses/villages/unassign',
    UNASSIGN_MULTIPLE_CITIES: ':api/admin/addresses/cities/unassign',
    CHANGE_MODEM_STATUS: ':api/sms-modems/manager/modems/{modem-id}/status',
    GET_CITIES: ':api/addresses/cities',
    SET_DRIVER_ID_FO_SHIPPING_LINE: ':api/admin/addresses/shipping-lines/{shippingLineId}/drivers/{driverId}/set',
    CHANGE_VILLAGE_IS_RECEIVING_POINT: ':api/admin/addresses/villages/{villageId}/set-as-receiving-point',
    EXPORT_VILLAGES_TO_EXCEL: ':api/admin/addresses/villages/excel',
    // Service Types Constants
    GET_SERVICE_TYPES: ':api/admin/service-types',
    GET_SERVICE_TYPES_DROPDOWN: ':api/admin/service-types/dropdown',
    GET_VEHICLE_TYPES: ':api/admin/service-types/{serviceTypeId}/vehicle-types',
    NEW_SERVICE_TYPE: ':api/admin/service-type',
    EDIT_SERVICE_TYPE: ':api/admin/service-type/{serviceTypeId}',
    DELETE_SERVICE_TYPE: ':api/admin/service-type/{serviceTypeId}',
    GET_ALL_VEHICLE_TYPES: ':api/admin/vehicle-types',
    GET_PACKAGE_CONTENTS_LIST: ':api/admin/contents/item',
    NEW_PACKAGE_CONTENT: ':api/admin/contents/item',
    EDIT_PACKAGE_CONTENT: ':api/admin/contents/item/{contentItemId}',
    DELETE_PACKAGE_CONTENT: ':api/admin/contents/item/{contentItemId}',
    GET_PAYMENTS: ':api/payment/intent',
    NEW_PAYMENT_INTENT: ':api/payment/intent',
    UPDATE_CITY_PREFIX: ':api/admin/addresses/cities/{cityId}',
    ADD_ALL_VILLAGES_TO_CITY: ':api/admin/addresses/shipping-lines/{shippingLineId}/cities/{cityId}/villages/selection',
    ADD_ALL_VILLAGES_TO_CITY_FOR_MANAGE_ZONES: ':api/admin/addresses/cities/{cityId}/villages/selection',
    IMPORT_FROM_EXCEL: ':api/super-admin/companies/{companyId}/regions/{regionId}/zones/import',
    GET_ZONE_HUB: ':api/admin/addresses/villages/{id}/hub',
    UPDATE_ZONE_HUB: ':api/admin/addresses/villages/{id}/hub',
    WALLET: {
        GET_ALL_WALLETS: ':api/accountant/customer/wallet',
        GET_WALLET_HISTORY: ':api/accountant/customer/wallet/{id}/transaction',
        CREATE_RECEIPT: ':api/accountant/customer/receipt',
        UPDATE_RECEIPT: ':api/accountant/customer/receipt/{id}',
    }
};
