import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserModel} from '../../../shared/models/user.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../../users/services/users.service';
import {UserService} from '../../../shared/services/user.service';
import {Address} from '../../../shared/models/address.class';
import {ZonesService} from '../../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {CategoryModel} from '../../../ticketing-system/models/category-model';
import {SHARED_CONSTANTS, THIRD_PARTY, VALIDATION_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {ServiceTypesService} from '../../../administration/services/service-types.service';
import {HubsService} from '../../../shared/services/hubs.service';
import {ContainersService} from '../../../containers/services/containers.service';
import {TicketingSystemService} from '../../../ticketing-system/services/ticketing-system.service';
import {GeographyService} from '../../../shared/services/geography.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {PricingService} from '../../../shippings-rate/services/pricing.service';
import {HubModel} from '../../../shared/models/hub.model';

@Component({
  selector: 'app-third-party-dialog',
  templateUrl: './third-party-dialog.component.html',
  styleUrls: ['./third-party-dialog.component.scss']
})
export class ThirdPartyDialogComponent  extends Address implements OnInit {
  public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;

  public isEdit = false;
  public hubsOptions: { label: string, value: any }[];
  public categories: CategoryModel [] = [];
  public selectedCategory = [];
  public isLoading = true;
  public isSubmitting = false;
  public form: FormGroup;
  public disableForm;
  public isSuperAdmin = false;
  public currentLang;
  public isLcl;
  public userRole: string;
  public doneActionStyleClass = 'done';
  isEnableAutoAssignForAllCustomers = false;
  userInfo: any;

  thirdPartyTypes: any[] = [];
  isHubUserRole = false;


  @Input() type = 'USER';
  @Input() user: UserModel;
  @Input() editedFields = 'all';
  showPhone2 = false;

  constructor(
      private userService: UserService,
      private fulfilmentService: FulfilmentService,
      private serviceTypesService: ServiceTypesService,
      private usersService: UsersService,
      private hubsService: HubsService,
      private containersService: ContainersService,
      @Inject(FormBuilder) private formsBuilder: FormBuilder,
      private activeModal: NgbActiveModal,
      private ticketingSystemService: TicketingSystemService,
      private geographyService: GeographyService,
      private containerService: ContainersService,
      private confirmationService: ConfirmationService,
      private translate: TranslateService,
      private translateService: TranslateService,
      private storageService: StorageService,
      private pricingService: PricingService,
      private zonesService: ZonesService
  ) {
    super(zonesService, translate, true);
    this.allVillages = true;
  }

  ngOnInit() {
    this.isHubUserRole = this.userService.isHubUserRole;
    this.userInfo = this.userService.userInfo;
    this.isEnableAutoAssignForAllCustomers = this.userInfo.isEnableAutoAssignForAllCustomers;

    this.currentLang = this.translate.currentLang;
    this.fetchHubs(false);
    this.getThirdParty();
  }

  private getThirdParty() {
    this.translate.get(THIRD_PARTY).subscribe(values => {
      this.thirdPartyTypes = Object.keys(values).map(translationKey => {
        return {
          label: values[translationKey],
          value: translationKey
        };
      });
    });
  }

  updateFormValue(controlName, value) {
    this.form.controls[controlName].setValue(value);
    this.form.controls[controlName].updateValueAndValidity();
    this.form.controls[controlName].markAsTouched();
  }

  private initForm() {
    let info = {
      firstName: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      phone2: '',
      password: this.generatePassword(),
      role: 'DRIVER',
      addressLine1: '',
      addressLine2: '',
      village: '',
      city: '',
      region: '',
      combined: '',
      country: 'Palestine',
      hubId: '',
      thirdPartyType: '',
      middleName: '',
      businessName: '',
      authorizedGovRegistrationNumber: '',
      expectedMonthlyPackagesNumber: '',
      vehicleId: '',
      isAutoApprovePackages: false,
      isAddDraftPackages: false,
      isAllowAddBundles: false,
      isShowMemberPackagesOnly: false,
      isShowSenderAddressInPackageReport: false,
      isBilled: false,
      isHideCostForMembers: false,
      isAllowNoAttachment: false,
      isAllowClerkToAddCustomers: false,
      isSwapSenderAndReceiver: false,
      isReturnPackages: false,
      isAllowClerkChangeStatus: false,
      isDeliverPackages: true,
      isPostponePackages: true,
      isDeliverReturnedPackagesToSender: true,
      isDeliverMassCodPackages: true,
      isBlocked: false,
      isDriverCanFailPackageDisabled: false,
      isSignatureOnPackageDeliveryDisabled: false,
      isDriverPickupPackagesByScanDisabled: false,
      isHideReceiverInfo: false,
      isShowPackagesInDriverApp: false,
      isAllowDriverRejectingOrders: true,
      isPickupFulfillmentPackages: true,
      isAllowAddingPackagesOnAllHub: true,
      isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: false,
      isMapViewOnly: false,
      isAllowAddingPackages: false,
      isShowCurrentReports: false,
      isEnabledAsTicketingUser: false,
      isHideSenderInfo: false,
      isShowSenderName: false,
      isShowSenderPhone: false,
      isTelecom: false,
      webhookUrl: '',
      paymentMethod: 'CASH',
      iban: '',
      pricingListId: '',
      categoryId: null,
      optionalNumber: '',
    };

    if (this.isEdit) {
      this.showPhone2 = !!this.user.phone2;

      info = <any>{
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        phone: this.user.phone,
        phone2: this.user.phone2,
        addressLine1: this.user.address.addressLine1,
        addressLine2: this.user.address.addressLine2,
        village: this.user.address.villageId,
        city: this.user.address.cityId,
        region: this.user.address.regionId,
        name: this.user.name,
        password: this.generatePassword(),
        hub: {
          value: this.user.address.villageId,
          label: this.user.address.village + '-' + this.user.address.city + '-' +
              this.user.address.region
        },
        country: this.user.address.country,
        role: 'DRIVER',
        hubId: this.user && this.user.hubId ? this.user.hubId : '',
        type: this.user.type,
        thirdPartyType: this.user.thirdPartyType,
        middleName: this.user.middleName,
        authorizedGovRegistrationNumber: this.user.authorizedGovRegistrationNumber,
        expectedMonthlyPackagesNumber: this.user.expectedMonthlyPackagesNumber,
        businessName: this.user.businessName,
        isAutoApprovePackages: this.user.isAutoApprovePackages,
        isShowSenderAddressInPackageReport: this.user.isShowSenderAddressInPackageReport,
        isBilled: this.user.isBilled,
        isHideCostForMembers: this.user.isHideCostForMembers,
        isTelecom: this.user.isTelecom,
        isAllowNoAttachment: this.user.isAllowNoAttachment,
        isReturnPackages: this.user.isReturnPackages,
        isAllowClerkChangeStatus: this.user.isAllowClerkChangeStatus,
        isAllowClerkToAddCustomers: this.user.isAllowClerkToAddCustomers,
        isSwapSenderAndReceiver: this.user.isSwapSenderAndReceiver,
        isDeliverPackages: this.user.isDeliverPackages,
        isDeliverReturnedPackagesToSender: this.user.isDeliverReturnedPackagesToSender,
        isDeliverMassCodPackages: this.user.isDeliverMassCodPackages,
        isBlocked: this.user.isBlocked,
        isDriverCanFailPackageDisabled: this.user.isDriverCanFailPackageDisabled,
        isSignatureOnPackageDeliveryDisabled: this.user.isSignatureOnPackageDeliveryDisabled,
        isDriverPickupPackagesByScanDisabled: this.user.isDriverPickupPackagesByScanDisabled,
        isHideSenderInfo: this.user.isHideSenderInfo,
        isShowSenderPhone: this.user.isShowSenderPhone,
        isShowSenderName: this.user.isShowSenderName,
        isHideReceiverInfo: this.user.isHideReceiverInfo,
        isShowPackagesInDriverApp: this.user.isShowPackagesInDriverApp,
        isAllowDriverRejectingOrders: this.user.isAllowDriverRejectingOrders,
        isPickupFulfillmentPackages: this.user.isPickupFulfillmentPackages,
        isAllowAddingPackagesOnAllHub: this.user.isAllowAddingPackagesOnAllHub,
        isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: this.user.isDisableNotifyCCWhenDeliveryPkgAndChangeCOD,
        isMapViewOnly: this.user.isMapViewOnly,
        isAllowAddingPackages: this.user.isAllowAddingPackages,
        isShowCurrentReports: this.user.isShowCurrentReports,
        isEnabledAsTicketingUser: this.user.isEnabledAsTicketingUser,
        isPostponePackages: this.user.isPostponePackages,
        isAddDraftPackages: this.user.isAddDraftPackages,
        isAllowAddBundles: this.user.isAllowAddBundles,
        isShowMemberPackagesOnly: this.user.isShowMemberPackagesOnly,
        webhookUrl: this.user.webhookUrl ? this.user.webhookUrl : '',
        paymentMethod: this.user.paymentMethod,
        iban: this.user.iban,
        pricingListId: {value: this.user.pricingListId, label: this.user.pricingListName},
        categoryId: this.selectedCategory,
        isAutoAssignPkgsToDriver: this.user.isAutoAssignPkgsToDriver || this.isEnableAutoAssignForAllCustomers,
        isEnableConfirmationForReceivingMassCodPkg: this.user.isEnableConfirmationForReceivingMassCodPkg,
        isShowCodSumInCustomerPortalCodReports: this.user.isShowCodSumInCustomerPortalCodReports,
        isShowSenderNameInPackageReport: this.user.isShowSenderNameInPackageReport,
        serviceTypes: this.user.serviceTypes,
        optionalNumber: this.user.optionalNumber,
        customerFulfillmentType: this.user.customerFulfillmentType
      };
    }

    this.form = this.formsBuilder.group({
      firstName: [{value: '', disabled: !this.canEdit('name')}, Validators.required],
      lastName: [{value: '', disabled: !this.canEdit('name')}, Validators.required],
      email: [{value: '', disabled: !this.canEdit('email')}, Validators.compose([
        Validators.required,
        Validators.minLength(VALIDATION_CONSTANTS.USERNAME_EMAIL_MIN_LENGTH)
      ])],
      password: this.isEdit ? [{value: '', disabled: !this.canEdit('password')}] :
          [{value: '', disabled: !this.canEdit('password')},
            Validators.compose([Validators.required,
                                         Validators.minLength(VALIDATION_CONSTANTS.PASSWORD_MIN_LENGTH),
                                         Validators.pattern(VALIDATION_CONSTANTS.PASSWORD_PATTERN)])],
      phone: [{value: '', disabled: !this.canEdit('phone')}, [
        Validators.required,
       this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
      phone2: [{value: '', disabled: !this.canEdit('phone2')}, [
       this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
      addressLine1: [{value: '', disabled: !this.canEdit('addressLine1')}, Validators.required],
      addressLine2: [{value: '', disabled: !this.canEdit('addressLine1')}],
      city: [{value: '', disabled: !this.canEdit('city')}, Validators.required],
      region: [{value: '', disabled: !this.canEdit('region')}, Validators.required],
      village: [{value: '', disabled: !this.canEdit('village')}, Validators.required],
      combined: [{value: '', disabled: !this.canEdit('combined')}, Validators.required],
      country: [{value: '', disabled: !this.canEdit('country')}],
      role: [{value: '', disabled: !this.canEdit('role')}, Validators.required],
      hubId: [{value: '', disabled: !this.canEdit('name')}],
      thirdPartyType: [{value: ''}, Validators.required],
      middleName: [{value: '', disabled: !this.canEdit('name')}],
      authorizedGovRegistrationNumber: [{value: '', disabled: !this.canEdit('name')}],
      expectedMonthlyPackagesNumber: [{value: ''}],
      businessName: [{value: '', disabled: !this.canEdit('name')}, Validators.minLength(3)],
      vehicleId: [{value: '', disabled: !this.canEdit('vehicleId')}],
      isAutoApprovePackages: [{value: false, disabled: !this.canEdit('isAutoApprovePackages')}],
      isAddDraftPackages: [{value: false, disabled: !this.canEdit('isAddDraftPackages')}],
      isAllowAddBundles: [{value: false, disabled: !this.canEdit('isAllowAddBundles')}],
      isShowMemberPackagesOnly: [{value: false, disabled: !this.canEdit('isShowMemberPackagesOnly')}],
      isShowSenderAddressInPackageReport: [{value: false, disabled: !this.canEdit('isShowSenderAddressInPackageReport')}],
      isBilled: [{value: false, disabled: !this.canEdit('isBilled')}],
      isHideCostForMembers: [{value: false, disabled: !this.canEdit('isHideCostForMembers')}],
      isTelecom: [{value: false, disabled: !this.canEdit('isTelecom')}],
      isAllowNoAttachment: [{value: false, disabled: !this.canEdit('isAllowNoAttachment')}],
      isReturnPackages: [{value: true, disabled: !this.canEdit('isReturnPackages')}],
      isAllowClerkChangeStatus: [{value: true, disabled: !this.canEdit('isAllowClerkChangeStatus')}],
      isAllowClerkToAddCustomers: [{value: true, disabled: !this.canEdit('isAllowClerkToAddCustomers')}],
      isSwapSenderAndReceiver: [{value: false, disabled: !this.canEdit('isSwapSenderAndReceiver')}],
      isPostponePackages: [{value: true, disabled: !this.canEdit('isPostponePackages')}],
      isDeliverPackages: [{value: true, disabled: !this.canEdit('isDeliverPackages')}],
      isDeliverReturnedPackagesToSender: [{value: true, disabled: !this.canEdit('isDeliverReturnedPackagesToSender')}],
      isDeliverMassCodPackages: [{value: true, disabled: !this.canEdit('isDeliverMassCodPackages')}],
      isBlocked: [{value: false, disabled: !this.canEdit('isBlocked')}],
      isDriverCanFailPackageDisabled: [{value: false, disabled: !this.canEdit('isDriverCanFailPackageDisabled')}],
      isSignatureOnPackageDeliveryDisabled: [{value: false, disabled: !this.canEdit('isSignatureOnPackageDeliveryDisabled')}],
      isDriverPickupPackagesByScanDisabled: [{value: false, disabled: !this.canEdit('isDriverPickupPackagesByScanDisabled')}],
      isHideReceiverInfo: [{value: true, disabled: !this.canEdit('isHideReceiverInfo')}],
      isShowPackagesInDriverApp: [{value: true, disabled: !this.canEdit('isShowPackagesInDriverApp')}],
      isAllowDriverRejectingOrders: [{value: true, disabled: !this.canEdit('isAllowDriverRejectingOrders')}],
      isPickupFulfillmentPackages: [{value: this.userInfo.isFulfilmentEnabled, disabled: !this.canEdit('isPickupFulfillmentPackages')}],
      isAllowAddingPackagesOnAllHub: [{value: true, disabled: !this.canEdit('isAllowAddingPackagesOnAllHub')}],
      isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: [{value: true, disabled: !this.canEdit('isDisableNotifyCCWhenDeliveryPkgAndChangeCOD')}],
      isMapViewOnly: [{value: true, disabled: !this.canEdit('isMapViewOnly')}],
      isAllowAddingPackages: [{value: false, disabled: !this.canEdit('isAllowAddingPackages')}],
      isShowCurrentReports: [{value: false, disabled: !this.canEdit('isShowCurrentReports')}],
      isEnabledAsTicketingUser: [{value: true, disabled: !this.canEdit('isEnabledAsTicketingUser')}],
      isHideSenderInfo: [{value: true, disabled: !this.canEdit('isHideSenderInfo')}],
      isShowSenderName: [{value: true, disabled: !this.canEdit('isShowSenderName')}],
      isShowSenderPhone: [{value: true, disabled: !this.canEdit('isShowSenderPhone')}],
      webhookUrl: [{value: '', disabled: !this.canEdit('webhookUrl')}],
      paymentMethod: [{value: 'CASH', disabled: !this.canEdit('paymentMethod')}],
      iban: [{value: '', disabled: !this.canEdit('iban')}],
      pricingListId: [{value: '', disabled: !this.canEdit('pricingListId') || this.userInfo.role === 'CUSTOMER_CARE'}],
      categoryId: [{value: [], disabled: !this.canEdit('categoryId')}],
      isAutoAssignPkgsToDriver: [{value: false, disabled: !this.canEdit('isAllowNoAttachment')}],
      isShowSenderNameInPackageReport: [{value: true, disabled: !this.canEdit('isShowSenderNameInPackageReport')}],
      isEnableConfirmationForReceivingMassCodPkg: [{value: false, disabled: !this.canEdit('isEnableConfirmationForReceivingMassCodPkg')}],
      isShowCodSumInCustomerPortalCodReports: [{value: true, disabled: !this.canEdit('isShowCodSumInCustomerPortalCodReports')}],
      type: [this.type === 'customer' && !this.isEdit ? 'NORMAL' : ''],
      optionalNumber: [{value: ''}, Validators.pattern(`^[a-zA-Z0-9_.-]*$`)]
    });

    this.form.controls.country.setValue('Palestine');
    this.form.controls.role.setValue('DRIVER');
    this.form.controls.password.setValue(this.generatePassword());

    this.initFormEvent(this.form, 0, {
      country: '',
      region: 'region',
      city: 'city',
      village: 'village',
      combined: 'combined'
    });

    // this.selectedVehicle.id = this.form.get('vehicleId').value;
    this.form.patchValue(info);
    // console.log(this.form.get('vehicleId').value);
    if (this.isEdit) {  // city and village values have to be reset after resiogn to fix form validity on edit
      this.form.patchValue({
        combined: {
          cityId: this.user.address.cityId,
          cityName: this.user.address.city,
          label: this.user.address.village + '-' + this.user.address.city + '-' +
              this.user.address.region,
          regionId: this.user.address.regionId,
          regionName: this.user.address.region,
          villageId: this.user.address.villageId,
          villageName: this.user.address.village,
          value: this.user.address.villageId
        }
      });
    }
  }
  canEdit(controlName) {
    // Special case
    if (this.isEdit && (controlName === 'email' || controlName === 'password')) {
      // Disable the email always if it was editting mode.
      // No one can edit the email.
      return false;
    }

    if (this.editedFields === 'all') {
      return true;
    }

    return this.editedFields.includes(controlName);
  }

  private fetchHubs(getWarehousesOnly = false, initForm = true) {
    this.isLoading = initForm;
    let observable = this.hubsService.getHubs('?page=1&pageSize=100');
    if (getWarehousesOnly) {
      const params = {
        page: 1,
        pageSize: 100
      };
      observable = this.fulfilmentService.getWarehousesNew(params);
    }
    observable.subscribe(
        (response: any) => {
          this.hubsOptions = response.hubs.map(
              (hub: HubModel) => {
                return {label: hub.name, value: hub.id};
              }
          );

          if (initForm) {
            this.finishLoading();
          }
        }, (error) => {
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        }
    );
  }

  private finishLoading() {
    if (this.hubsOptions) {
      this.isLoading = false;
      this.initForm();
      // this.disableRest = true;
      // this.setDefaultRegionId();
      // this.disableRest = false;
    }
  }
  public exitAddNewUser() {
    this.activeModal.close();
  }

  public onAddUser() {
    this.isLoading = true;

    const data = this.form.getRawValue();
    const body = {
      address: {
        addressLine1: data.addressLine1,
        cityId: data.city.value,
        city: data.city.label,
        country: data.country,
        villageId: data.village.value,
        village: data.village.label,
        addressLine2: data.addressLine2,
        regionId: data.region.value,
        region: data.region.label
      },
      cityId: data.city.value,
      country: data.country,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      phone2: data.phone2,
      email: data.email,
      hubId: data.hubId,
      role: data.role,
      thirdPartyType: data.thirdPartyType,
      isAddDraftPackages: data.isAddDraftPackages,
      isAllowAddBundles: data.isAllowAddBundles,
      isShowMemberPackagesOnly: data.isShowMemberPackagesOnly,
      authorizedGovRegistrationNumber: data.authorizedGovRegistrationNumber,
      expectedMonthlyPackagesNumber: data.expectedMonthlyPackagesNumber,
      businessName: data.businessName,
      isAutoApprovePackages: data.isAutoApprovePackages,
      isShowSenderAddressInPackageReport: data.isShowSenderAddressInPackageReport,
      isBilled: data.isBilled,
      isHideCostForMembers: data.isHideCostForMembers,
      isTelecom: data.isTelecom,
      isAllowNoAttachment: data.isAllowNoAttachment,
      isReturnPackages: data.isReturnPackages,
      isAllowClerkToAddCustomers: data.isAllowClerkToAddCustomers,
      isAllowClerkChangeStatus: data.isAllowClerkChangeStatus,
      isSwapSenderAndReceiver: data.isSwapSenderAndReceiver,
      isPostponePackages: data.isPostponePackages,
      isDeliverPackages: data.isDeliverPackages,
      isDeliverReturnedPackagesToSender: data.isDeliverReturnedPackagesToSender,
      isDeliverMassCodPackages: data.isDeliverMassCodPackages,
      isBlocked: data.isBlocked,
      isDriverCanFailPackageDisabled: data.isDriverCanFailPackageDisabled,
      isSignatureOnPackageDeliveryDisabled: data.isSignatureOnPackageDeliveryDisabled,
      isDriverPickupPackagesByScanDisabled: data.isDriverPickupPackagesByScanDisabled,
      isHideSenderInfo: data.isHideSenderInfo,
      isShowSenderPhone: data.isShowSenderPhone,
      isShowSenderName: data.isShowSenderName,
      isHideReceiverInfo: data.isHideReceiverInfo,
      isShowPackagesInDriverApp: data.isShowPackagesInDriverApp,
      isAllowDriverRejectingOrders: data.isAllowDriverRejectingOrders,
      isPickupFulfillmentPackages: data.isPickupFulfillmentPackages,
      isAllowAddingPackagesOnAllHub: data.isAllowAddingPackagesOnAllHub,
      isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: data.isDisableNotifyCCWhenDeliveryPkgAndChangeCOD,
      isMapViewOnly: data.isMapViewOnly,
      isAllowAddingPackages: data.isAllowAddingPackages,
      isShowCurrentReports: data.isShowCurrentReports,
      isEnabledAsTicketingUser: data.isEnabledAsTicketingUser,
      paymentMethod: data.paymentMethod,
      iban: data.iban,
      pricingListId: data.pricingListId ? data.pricingListId.value : null,
      isAutoAssignPkgsToDriver: data.isAutoAssignPkgsToDriver,
      isShowSenderNameInPackageReport: data.isShowSenderNameInPackageReport,
      isEnableConfirmationForReceivingMassCodPkg: data.isEnableConfirmationForReceivingMassCodPkg,
      isShowCodSumInCustomerPortalCodReports: data.isShowCodSumInCustomerPortalCodReports,
      type: data.type,
      serviceTypes: data.serviceTypes,
      optionalNumber: data.optionalNumber
    };

      body['password'] = data.password;

    this.usersService.addUser(body).subscribe(
        (response) => {
          this.activeModal.close({isSuccess: true});
        }, (err) => {
          this.isLoading = false;
        }, () => {
        }
    );
  }

  updateZoneForm($event, controlName) {
    this.form.controls[controlName].setValue(null);
    this.form.controls[controlName].updateValueAndValidity();
  }
  public getCategories(search: string, isEdit = false) {
    const query = '?page=1&pageSize=100&search=' + search;
    this.ticketingSystemService.getAllCategories(query).subscribe(
        (categories: CategoryModel[]) => {
          this.categories = categories;
          if (isEdit) {
            this.getCategoryIdsByUserId(this.user.id);
          }
        }, (error) => {
          console.error(error);
        }
    );
  }


  isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
    const formControl = this.form.get(formControlName);
    if (formControl) {
      return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
          : formControl.invalid && (formControl.touched || formControl.dirty);
    }
    return false;
  }

  isInvalidDirtyAndTouched(formControlName: string, errorType?: string) {
    const formControl = this.form.get(formControlName);
    if (formControl) {
      return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
          : formControl.invalid && formControl.touched && formControl.dirty;
    }
    return false;
  }

  public getCategoryIdsByUserId(userId) {
    this.ticketingSystemService.getCategoriesByUserId(userId).subscribe((response: any) => {
      if (response && response.categoryId) {
        this.selectedCategory = response.categoryId;
        this.form.controls['categoryId'].setValue(this.selectedCategory);
      }
    });
  }

  validateUserNameAndEmail(value: string) {
    value = value.trim();
    if (/\s/.test(value) ) {
      value =  value.replace(/\s/, '');
    }
    this.form.get('email').reset();
    this.updateFormValue('email', value);
  }

  toggleExtraPhone() {
    this.showPhone2 = !this.showPhone2;
    if (!this.showPhone2) {
      this.form.get('phone2').patchValue('');
      this.form.get('phone2').setValidators([]);
    } else {
      this.form.get('phone2').setValidators([Validators.compose([Validators.required,
       this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())])]);
    }
    this.form.get('phone2').updateValueAndValidity();
    this.form.get('phone2').markAsTouched();
  }


  private generatePassword(): string {
    const chars: string = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
  }

}
