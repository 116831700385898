<p-toast [baseZIndex]="toastZIndex"></p-toast>

<div class="shipments-table flex-column" style="margin-top: 15px;">
    <div class="d-flex flex-row" style="height: 30px">
        <div class="flex-1"></div>
        <div *ngIf="canPrintPdf" class="print-action action cursor-pointer" (click)="downloadPdf()"
             style="margin-right: 10px">
            <i class="fas fa-2x fa-print"></i>
        </div>
    </div>

    <div class="flex-1 overflow-auto" *ngIf="false">
        <p-table #shipmentsTable dataKey="id" #table [value]="packages" styleClass="packages-table flex-table"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadPackagesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="10" [totalRecords]="totalRecords">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th> {{"MANAGE_SHIPMENTS.BARCODE" | translate | packageTitle}} </th>
                    <th style="width: 50px"> {{"MANAGE_SHIPMENTS.COST" | translate}} </th>
                    <th style="width: 50px"> {{"COD" | translate}} </th>
                    <th> {{"MANAGE_SHIPMENTS.CUSTOMER" | translate}} </th>
                    <th>{{"MANAGE_SHIPMENTS.FROM" | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.TO" | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.BOOKED_ON" | translate}}</th>
                    <th>{{"MANAGE_SHIPMENTS.STATUS" | translate}}</th>
                    <th> {{"MANAGE_SHIPMENTS.POSTPONED_DATE" | translate}} </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr>
                    <td style="text-align: center">
                        <img height="50px" class="barcode-image" [src]="package.barcodeImage"
                             onerror="this.style.display='none'">
                        <span class="barcode-label hover-display-parent">
                            <span>{{package.barcode}}</span>
                            <i class="fas fa-copy cursor-pointer hover-display" (click)="copyText(package.barcode)"></i>
                        </span>
                    </td>
                    <td style="width: 50px;">
                        <span style="color: #00bd9f"> {{package.cost}} </span>
                    </td>
                    <td style="width: 50px;">
                        <span style="color: #00bd9f"> {{package.cod}} </span>
                    </td>
                    <td> {{package.customerName}} </td>
                    <td> {{package.originAddress.city }} - {{package.originAddress.village }}
                        - {{package.originAddress.addressLine1}} </td>
                    <td> {{package.destinationAddress.city }} - {{package.destinationAddress.village }}
                        - {{package.destinationAddress.addressLine1}} </td>
                    <td> {{package.createdDate | date:'dd/MM/yyyy HH:mm'}} </td>
                    <td> {{package.status | translate}}</td>
                    <td>{{package.postponedDeliveryDate | date:'dd/MM/yyyy HH:mm'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
