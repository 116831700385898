import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ZonesService} from '../../services/zones.service';
import {ItemModel} from '../../models/item.model';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {ADMINISTRATION_CONSTANTS} from '../../administration-constants';
import {TranslateService} from '@ngx-translate/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';

@Component({
    selector: 'app-add-zone',
    templateUrl: './add-zone.component.html',
    styleUrls: ['./add-zone.component.scss']
})
export class AddZoneComponent implements OnInit {
    public addNew = false;
    public englishName = '';
    public arabicName = '';
    public zones = [];
    public isLoading = false;
    public selectedZone = null;
    public selectedLanguage = 'en';

    @Input() addURL: string;
    @Input() parentID: number;
    @Input() zonesURL: string;
    @Input() addParentKey = '';
    @Input() assignURL: string;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private activeModal: NgbActiveModal, private zonesService: ZonesService,  public translateService: TranslateService) {
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        this.addNew = false;
        this.getZones();
    }

    public closeModal() {
        this.activeModal.close();
    }

    public getZones() {
        if (this.zonesURL) {
            this.isLoading = true;
            const query = this.createParams();
            this.zonesService.getZones(this.zonesURL, query).subscribe(
                (response: { data: ItemModel[], totalRecordsNo: number }) => {
                    this.isLoading = false;
                    this.zones = response.data;
                }, (error) => {
                    this.isLoading = false;
                }, () => {
                }
            );
        }

    }

    public createParams() {
        const params = {
            pageSize: ADMINISTRATION_CONSTANTS.ZONES_PAGE_SIZE,
            page: 1,
        };
        return params;
    }

    public onSubmit() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        let body = {
            name: (this.addNew) ? this.englishName : this.selectedZone.name,
            arabicName: (this.addNew) ? this.arabicName : this.selectedZone.arabicName
        };
        if (this.addParentKey) {
            body[this.addParentKey] = this.parentID;
        }
        if (this.addNew) {
            this.zonesService.addZone(this.addURL, body).subscribe(res => {
                this.isLoading = false;
                this.activeModal.close({isSuccess: true});
            }, error => {
                this.isLoading = false;
            });
        } else {
            this.zonesService.assignZone(this.assignURL.replace('{id}', this.selectedZone)).subscribe(res => {
                this.isLoading = false;
                this.activeModal.close({isSuccess: true});
            }, error => {
                this.isLoading = false;
            });
        }
    }

    public onToggleCreateNew() {

    }

    public zoneChosen($event) {

    }

}
