<div class="modal-header">
    <h4>{{ ('ADMINISTRATION.CHOOSE_LOCATION' | translate) + " - " +
        (selectedLanguage == 'en' ? selectedItem.name : selectedItem.arabicName) }}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>

<div class="modal-body">
    <p-card>
        <div class="col">
            <div class="map-container">
                <div id="submitting-container" *ngIf="loading">
                    <p-progressSpinner></p-progressSpinner>
                </div>

                <div id="map"></div>
            </div>
        </div>
    </p-card>
    <p-paginator #itemsPaginator *ngIf="multipleItems" [rows]="1" [totalRecords]="this.items.length"
                 (onPageChange)="paginate($event)"></p-paginator>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
        <i class="fas fa-times"></i>
        {{ 'ACTIONS.CLOSE' | translate }}
    </button>

    <button id="confirm-edit-credentials" data-cy="entityConfirmEditButton"
            (click)="save(false)" [disabled]="!this.mapMarker"
            type="submit" class="btn btn-primary mr-2 ml-2">
        <i class="fas fa-save"></i>
        {{ 'ACTIONS.SAVE' | translate }}
    </button>

    <button id="confirm-edit-next-credentials" data-cy="entityConfirmEditNextButton"
            *ngIf="multipleItems" (click)="save(true)"
            [disabled]="!this.mapMarker"
            type="button" class="btn btn-success ml-2">
        <i class="fas fa-save"></i>
        {{ 'ACTIONS.SAVE_AND_CONTINUE' | translate }}
    </button>
</div>
