import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApplicationStateService {

    private readonly isMobileResolution: boolean;

    constructor() {
        this.isMobileResolution = window.innerWidth < 768;
    }

    public getIsMobileResolution(): boolean {
        return this.isMobileResolution;
    }

    public isOpenedFromMobile(): boolean {
        let isMobile;
        isMobile = navigator.userAgent.match(/Android/i) ||
                   navigator.userAgent.match(/BlackBerry/i) ||
                   navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
                   navigator.userAgent.match(/Opera Mini/i) ||
                   navigator.userAgent.match(/IEMobile/i);

        return isMobile;
    }
}
