import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-enter-value',
    templateUrl: './enter-value.component.html',
    styleUrls: ['./enter-value.component.scss']
})
export class EnterValueComponent implements OnInit {
    public title = '';
    public inputLabel = '';

    public isLoading = false;
    public form;

    constructor(private formBuilder: FormBuilder,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.initInfo();
    }

    public initInfo() {
        this.form = this.formBuilder.group({
            num: ['', Validators.required],
        });

    }

    public finish() {
        const {num} = this.form.getRawValue();
        this.activeModal.close({finished: true, value: num});
    }

    public closeModal() {
        this.activeModal.close();
        this.activeModal.close({finished: false});
    }

}
