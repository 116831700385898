import {Injectable} from '@angular/core';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class SaasService {

    constructor(private httpClient: HttpClient) {
    }

    public getColors() {
        return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_COMPANY_THEME);
    }

    public updateColors(companyThemes) {
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.GET_COMPANY_THEME, {companyThemes});
    }

    public getBankTransfers(params) {
        return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_BANK_TRANSFERS, {params: params});
    }

    public approveTransfer(mobId, received) {
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.APPROVE_MOB.replace('{mobId}', mobId), {receivedMoney: received});
    }

    public rejectTransfer(mobId) {
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.REJECT_MOB.replace('{mobId}', mobId), {});
    }
    public editModemStatus(modemId) {
        return this.httpClient.put(ADMINISTRATION_CONSTANTS.CHANGE_MODEM_STATUS.replace('{modem-id}', modemId), {});
    }

}
