import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {environment} from '../../../environments/environment';
import {AlertService} from '../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../services/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    public apiURL = environment.API_BASE_URL;

    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private activeModal: NgbModal,
        private userService: UserService
    ) {
    }

    /**
     * @return Observable<HttpEvent<any>>
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addToken(request);
        return next.handle(request)
        // add error handling
            .pipe(
                catchError(
                    (error: any, caught: Observable<HttpEvent<any>>) => {
                        if (error.status === 401 && !request.url.includes('login')) {
                            this.handleAuthError();
                            // if you've caught / handled the error, you don't
                            // want to rethrow it unless you also want
                            // downstream consumers to have to handle it as
                            // well.
                            return of(error);
                        } else {
                            this.alertService.alertError(error);
                        }
                        throw error;
                    }
                ),
            );
    }

    /**
     * Handle API authentication errors.
     */
    private handleAuthError() {
        const companyName = this.auth.companyName || this.auth.getCompanyName();
        const isSuperAdmin = this.auth.getIsSuperAdmin();
        this.activeModal.dismissAll();
        this.userService.deleteUserInfo();
        localStorage.clear();
        // navigate back to the login page
        if (isSuperAdmin) {
            this.router.navigate(['/login']);
        } else {
            this.router.navigate([`${companyName}/login`]);
        }
    }

    /**
     * Add stored auth token to request headers.
     * @return HttpRequest<any> - the modified request
     * @param request
     */
    private addToken(request: HttpRequest<any>): HttpRequest<any> {
        const newOptions = {
            url: request.url,
            setHeaders: {
                'Authorization-Token': '',
                'languageCode': this.translateService.currentLang || 'en'
            }
        };

        if (this.auth.companyId) {
            newOptions.setHeaders['company-id'] = `${this.auth.companyId}`;
        }

        // add server prefix for API calls only.
        if (request.url.startsWith(':')) {
            newOptions.url = this.getBaseUrl(request.url);
        }

        // Don't Attach Token or Base_URL when its file request.
        if (request.url.endsWith('.pdf')) {
            return request;
        }

        const token: string = this.auth.getUserAuthToken();
        if (token) {
            newOptions.setHeaders['Authorization-Token'] = `${token}`;
        }

        return request.clone(newOptions);
    }

    /**
     * Attach the server API URL to each request.
     * @param url
     */
    private getBaseUrl(url: string) {
        return this.apiURL + url.substring(1);
    }
}
