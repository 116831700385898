import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './shared/services/storage/storage.service';
import {STORAGE_KEYS} from './shared/services/storage/storage.keys';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {AuthenticationService} from './shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionTrackerComponent} from './shared/behavior/subscription-tracker.component';
import {PrimeNGConfig} from 'primeng/api';
import {CURRENCIES_WITH_CUSTOM_TRANSLATIONS} from './inbox/services/constant';
import {RolesService} from './shared/services/roles/roles.service';
import {UserService} from './shared/services/user.service';
import {Companies} from '../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionTrackerComponent {
    private initLanguage: boolean;

    constructor(
        private storageService: StorageService,
        private translate: TranslateService,
        private primeNgConfig: PrimeNGConfig,
        private title: Title,
        private authenticationService: AuthenticationService,
        private router: Router) {
            super();
            this.initAppLanguage();
            this.title.setTitle(environment.TITLE);
    }

    /**
     * Set the Initial Language.
     * according the previous selected language.
     */
    public initAppLanguage() {
        this.initLanguage = true;
        const language = this.storageService.getLanguage();
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        this.setLanguageClasses(language);
        const subscription = this.translate.onLangChange.subscribe(
            (newLang) => {

                this.translate.get('primeng').subscribe(res => this.primeNgConfig.setTranslation(res));
                // get the current language from the storage
                const currLang = this.storageService.getLanguage();

                // change the application direction.
                if (newLang.lang !== currLang) {
                    this.setLanguageClasses(newLang.lang);
                }
                if (!this.initLanguage) {
                    if (this.authenticationService.companyName === undefined ||
                        this.authenticationService.companyName === null ||
                        this.authenticationService.companyName === '') {
                        this.router.navigate([this.authenticationService.companyName + 'super-admin/']);
                    } else {
                        this.router.navigate([this.authenticationService.companyName + 'home/']);
                    }
                }
                // save the new language in the storage.
                this.storageService.set(STORAGE_KEYS.LANGUAGE, newLang.lang);
                this.initLanguage = false;
                this.handleDataForCompany();
            }
        );
        this.subscriptions.push(subscription);
        this.translate.get('primeng').subscribe(res =>  {
            return this.primeNgConfig.setTranslation(res)
        });
    }

    private setLanguageClasses(lang) {
        if (lang === STORAGE_KEYS.LANGUAGES.ARABIC) {
            document.body.style.direction = 'rtl';
            document.body.classList.add('page-rtl');
            document.body.classList.remove('page-ltr');
        } else {
            document.body.style.direction = 'ltr';
            document.body.classList.add('page-ltr');
            document.body.classList.remove('page-rtl');
        }
    }

    private handleDataForCompany() {
        const currentLang = this.translate.currentLang;
        if (CURRENCIES_WITH_CUSTOM_TRANSLATIONS.indexOf(this.authenticationService.getCurrency()) > -1) {
            this.authenticationService.getLanguageForCurrency(currentLang).subscribe(res => {
                if (res.primeng) {
                    this.primeNgConfig.setTranslation(res.primeng);
                }

                this.translate.setTranslation(currentLang, res, true);
            }, (error) => {

            });

        }
    }
}
