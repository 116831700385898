<app-input-field-component *ngIf="!sentOnce" [form]="form" label="SHIPMENT.ENTER_BARCODE_LIST"
                           controlName="barcodes" controlType="textarea"
                           [isRequired]="true" styleClass="ui-table-customers">
</app-input-field-component >
<p-table [columns]="cols" *ngIf="sentOnce" #dt [value]="barcodes" [loading]="isLoading" [responsive]="true"
         dataKey="barcode" [scrollable]="true" scrollHeight="500px"
         [rowHover]="true" [globalFilterFields]="['barcode','status']" [resizableColumns]="true">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="barcode"> {{"MANAGE_SHIPMENTS.BARCODE" | translate}}
                <p-sortIcon field="barcode"></p-sortIcon>
            </th>
            <th pSortableColumn="status"> {{"MANAGE_SHIPMENTS.STATUS" | translate}}
                <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th> {{"MANAGE_SHIPMENTS.REASON_OF_FAILURE" | translate}}
            </th>
        </tr>
        <tr>
            <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'barcode', 'startsWith')"
                       placeholder="{{'SHIPMENT.SEARCH_BY_BARCODE'|translate}}" class="ui-column-filter">
            </th>
            <th>
                <p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'status', 'equals')"
                            styleClass="ui-column-filter" [showClear]="true" appendTo="body"
                            placeholder="{{'SHIPMENT.SEARCH_BY_STATUS'|translate}}">
                    <ng-template let-option pTemplate="item">
                        <span>{{option.label | translate}}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="selectedItem">
                        <span>{{option.label | translate}}</span>
                    </ng-template>
                </p-dropdown>
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-barcode let-expanded="expanded">
        <tr>
            <td pEditableColumn class="cursor-pointer">
                <p-cellEditor *ngIf="!finishUpdating">
                    <ng-template pTemplate="input">
                        <input type="text" [(ngModel)]="barcode.barcode">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{barcode.barcode}}
                    </ng-template>
                </p-cellEditor>
                <span *ngIf="finishUpdating">
          {{barcode.barcode}}
        </span>
            </td>
            <td [ngStyle]="{'background-color': ['FAILED', 'UNCORRECT_BARCODE'].includes(barcode.status) ? 'red' : '#B7D8B7' }">
                {{barcode.status | translate}}
            </td>
            <td>
                {{barcode.note | translate}}
            </td>
        </tr>
    </ng-template>


</p-table>
