import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllWallets(params) {
    // return of({
    //   data: [{
    //
    //   }, {}, {}, {}, {}, {}, {}]
    // });
    return this.httpClient.get(ADMINISTRATION_CONSTANTS.WALLET.GET_ALL_WALLETS, {params});
  }

    getTransactionHistory(id: any) {
      return this.httpClient.get(ADMINISTRATION_CONSTANTS.WALLET.GET_WALLET_HISTORY.replace('{id}', id));

    }

  createReceipt(body) {
    return this.httpClient.post(ADMINISTRATION_CONSTANTS.WALLET.CREATE_RECEIPT, body);
  }
  updateReceipt(id, body) {
    return this.httpClient.put(ADMINISTRATION_CONSTANTS.WALLET.UPDATE_RECEIPT.replace('{id}', id), body);
  }
}
