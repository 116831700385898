import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SaasService} from '../../administration/services/saas.service';
import {CompanyNotificationsService} from '../services/company-notifications.service';

@Component({
    selector: 'app-edit-notification-template',
    templateUrl: './edit-notification-template.component.html',
    styleUrls: ['./edit-notification-template.component.scss']
})

export class EditNotificationTemplateComponent implements OnInit {

    public templateText = '';
    public siteTags = [];
    @ViewChild('textArea', {static: false}) textArea: ElementRef;
    constructor(private activeModal: NgbActiveModal,
                private companyNotificationsService: CompanyNotificationsService) {
    }

    ngOnInit() {
        this.getSiteTags();
    }

    public closeModal() {
        this.activeModal.close({success: false});
    }

    public finishEditing() {
        this.activeModal.close({success: true, template: this.templateText});
    }

    public setTag(tag) {
        this.templateText += tag;
    }

    public getSiteTags() {
        this.companyNotificationsService.getSiteTags().subscribe((data: any[]) => {
            this.siteTags = data;
        });
    }
}
