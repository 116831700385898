import {Component, OnInit} from '@angular/core';
import {ServiceTypeModel} from '../../../models/service-type.model';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServiceTypesService} from '../../../services/service-types.service';
import {ContainersService} from '../../../../containers/services/containers.service';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Component({
    selector: 'app-new-service-type',
    templateUrl: './new-service-type.component.html',
    styleUrls: ['./new-service-type.component.scss']
})
export class NewServiceTypeComponent implements OnInit {

    public isEdit = false;
    public serviceType: ServiceTypeModel;
    currentLang: string;
    isLoading = false;
    serviceTypeForm: FormGroup;
    vehicleTypes: any[] = [];
    color = '';

    constructor(private translateService: TranslateService,
                private formBuilder: FormBuilder,
                private datePipe: DatePipe,
                private serviceTypesService: ServiceTypesService,
                private containersService: ContainersService,
                private activeModal: NgbActiveModal) {
    }
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.initForm();
        this.getVehicleTypes();
    }

    getVehicleTypes() {
        this.containersService.getVehicleTypes().subscribe((response: any[]) => {
            this.vehicleTypes = response.map((vehicleType) => {
                return {
                    label: vehicleType.name,
                    value: vehicleType.id,
                    isDefault: false
                };
            });
        });
    }

    initForm() {
        this.serviceTypeForm = this.formBuilder.group({
            name: ['', Validators.required],
            arabicName: ['', Validators.required],
            expectedDeliveryHours: [0, [Validators.required, Validators.min(0)]],
            expectedPickupHours: [0],
            vehicleTypes: [],
            defaultVehicleTypeId: [],
            lastOrderTime: [],
            color: ['#ffffff']
        });

        if (this.isEdit) {
            this.serviceTypeForm.patchValue(this.serviceType);
            if (this.serviceType.lastOrderTime) {
                const lastOrderTime = moment(this.serviceType.lastOrderTime, 'HH:mm').toDate();
                this.serviceTypeForm.get('lastOrderTime').patchValue(lastOrderTime);
            }
            if (this.serviceTypeForm.get('vehicleTypes').value && this.serviceTypeForm.get('vehicleTypes').value.length) {
                const defaultVehicleType = (this.serviceTypeForm.get('vehicleTypes').value.filter(vehicleType => vehicleType.isDefault));
                if (defaultVehicleType.length) {
                    this.serviceTypeForm.get('defaultVehicleTypeId').patchValue(defaultVehicleType[0].id);
                }
                this.serviceTypeForm.get('vehicleTypes').patchValue(this.serviceTypeForm.get('vehicleTypes').value.map(vehicleType => vehicleType.id));
            }
        }
        this.setExpectedPickupHoursValidators();
    }
    getSelectedVehicleTypes() {
        if (this.serviceTypeForm.get('vehicleTypes').value) {
          return  this.vehicleTypes.filter(vehicle => {
                return this.serviceTypeForm.get('vehicleTypes').value.indexOf(vehicle.value) !== -1;
            });
        }
    }
    setExpectedPickupHoursValidators() {
        this.serviceTypeForm.get('expectedPickupHours').setValidators(
            [
                Validators.required,
                Validators.min(0),
                Validators.max(this.serviceTypeForm.get('expectedDeliveryHours').value)
            ]);
        this.serviceTypeForm.get('expectedPickupHours').updateValueAndValidity();
    }

    closeModal(isSuccess?) {
        this.activeModal.close({
            isSuccess: isSuccess
        });
    }

    submitForm() {
        this.isLoading = true;
        const formValue = this.serviceTypeForm.getRawValue();
        let vehicleTypes = [];
        if (formValue.lastOrderTime) {
            const lastOrderTime = (formValue.lastOrderTime) as Date;
            formValue.lastOrderTime = lastOrderTime.toTimeString().split(' ')[0];
        }

        if (formValue.vehicleTypes && formValue.vehicleTypes.length) {
             vehicleTypes =  formValue.vehicleTypes.map(
                vehicle => { return {id: vehicle, isDefault: vehicle === formValue.defaultVehicleTypeId};
                });
        }
        if (this.isEdit) {
            const body =  {...formValue, vehicleTypes: vehicleTypes};
            this.serviceTypesService.editServiceType(this.serviceType.id, body).subscribe(() => {
                this.isLoading = false;
                this.closeModal(true);
            }, () => {
                this.isLoading = false;
            });
        } else {
            const body =  {...formValue, vehicleTypes: vehicleTypes};
            this.serviceTypesService.newServiceType(body).subscribe(() => {
                this.isLoading = false;
                this.closeModal(true);
            }, () => {
                this.isLoading = false;
            });
        }
    }

    isInvalidDirtyTouched(formName: string) {
        const formControl = this.serviceTypeForm.get(formName);
        if (formControl) {
            return formControl.invalid && formControl.dirty && formControl.touched;
        } else {
            return false;
        }
    }

    isInvalidWithError(formName: string, errorName: string) {
        const formControl = this.serviceTypeForm.get(formName);
        if (formControl && formControl.errors) {
            return this.isInvalidDirtyTouched(formName) && formControl.errors[errorName] !== undefined;
        } else {
            return false;
        }
    }

    hasError(formName: string, errorName: string) {
        const formControl = this.serviceTypeForm.get(formName);
        if (formControl && formControl.errors) {
            return formControl.errors[errorName] !== undefined;
        } else {
            return false;
        }
    }
}
