import {Component, Inject, ViewChild, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {SpinnerState} from '../../behavior/spinner-state.enum';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {PasswordValidation} from '../../behavior/password-match.validator';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {LoginService} from '../../../login/services/login.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit{
    public form;
    public confirmPasswordForm;
    @Input() apiURl;
    @Input() imageUrl;
    @Input() userName;

    public isLoading;
    public currentLang;

    isPasswordConfirmation = false;

    constructor(private formBuilder: FormBuilder,
                private activeModal: NgbActiveModal,
                public translateService: TranslateService,
                private httpClient: HttpClient,
                private loginService: LoginService,
                private messageService: MessageService) {
    }
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.initForm();
    }

    initForm() {
        if (this.isPasswordConfirmation) {
            this.confirmPasswordForm = this.formBuilder.group({
                password: ['', Validators.required]
            });
        } else {
            this.form = this.formBuilder.group({
                password: ['', Validators.compose([Validators.required, Validators.pattern(/((?=.*[A-Za-z])(?=.*[0-9])(?=.{6,}))/)])],
                confirmPassword: ['', Validators.required],
            }, {
                validator: PasswordValidation.MatchPassword
            });
        }

    }

    public closeModal() {
        this.activeModal.close();
    }

    public submit() {
        if (this.isPasswordConfirmation) {
            this.isLoading = true;
            this.loginService.passwordConfirmation({password: this.confirmPasswordForm.getRawValue().password}).subscribe(
                () => {
                    this.activeModal.close({valid: true});
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        } else {
            if (this.apiURl) {
                this.isLoading = true;
                const newPassword = this.form.value.password;
                this.httpClient.put(this.apiURl, {newPassword}).subscribe(() => {
                    this.isLoading = false;
                    this.translateService.get('ALERTS.PASSWORD_CHANGED_SUCCESSFULLY').subscribe(
                        (translatedMessage) => {
                            this.messageService.add({severity: 'success', detail: translatedMessage});
                        });
                    this.closeModal();
                }, (error) => {
                    console.error(error);
                    this.isLoading = false;
                });
            }
        }

    }

    disableSubmit() {
      return  this.isPasswordConfirmation ? !this.confirmPasswordForm.valid : !this.form.valid;
    }
}
