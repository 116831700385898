<div class="main-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="close-icon cursor-pointer" (click)="closeModal()">
        <img src="../../../../../assets/icons/cancel.svg" alt="icon" />
    </div>
    <div class="header d-flex">
        <label>{{label | translate}}</label>
    </div>
    <div class="details">
        <table>
          <tr>
            <th>{{'SHIPMENT.TABLE.NUMBER' | translate}}</th>
            <th>{{'SHIPMENT.TABLE.STATUS' | translate}}</th>
            <th>{{'SHIPMENT.TABLE.NOTES' | translate}}</th>
          </tr>
            <tr *ngFor="let result of results">
                <td>
                    <div class="barcode">
                        <div class="value">{{result.barcode}}</div>
                        <div class="copy-icon cursor-pointer" (click)="copyMessage($event, result.barcode)">
                            <img src="../../../../assets/icons/track_package/copy.svg" alt="copy-icon" />
                        </div>
                    </div>
                </td>
                <td>{{result.status | translate}}</td>
                <td>{{result.note}}</td>
            </tr>
        </table>
    </div>
    <div class="footer">
        <div class="action cursor-pointer" (click)="closeModal()">{{'ACTIONS.CLOSE' | translate}}</div>
    </div>
</div>
