import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CONTAINERS_CONSTANTS} from './constants';
import * as moment from 'moment';
import {DriverModel} from '../../shared/models/driver.model';

@Injectable({ providedIn: 'root' })
export class ContainersService {

    constructor(
        private http: HttpClient
    ) {
    }

    public fetchContainers(query) {
        return this.http.get(CONTAINERS_CONSTANTS.APIs.GET_CONTAINERS + query);
    }

    public getContainersList(query) {
        return this.http.get(CONTAINERS_CONSTANTS.APIs.GET_CONTAINERS_LIST + query);
    }

    public getContainerPackagesNo(id) {
        return this.http.get(CONTAINERS_CONSTANTS.APIs.GET_CONTAINER_COUNT.replace('{containerId}', id));
    }

    public fetchAssignedContainers(query) {
        return this.http.get(CONTAINERS_CONSTANTS.APIs.GET_ASSIGN_CONTAINER + query);
    }

    public removeContainer(containerId) {
        const url = CONTAINERS_CONSTANTS.APIs.REMOVE_CONTAINER.replace('{containerId}', containerId);
        return this.http.delete(url);
    }

    public newContainer(reqBody) {
        return this.http.post(CONTAINERS_CONSTANTS.APIs.NEW_CONTAINER, reqBody);
    }

    public assignToContainer(containerId, reqBody) {
        const url = CONTAINERS_CONSTANTS.APIs.ASSIGN_PACKAGE_TO_CONTAINER.replace('{containerId}', containerId);
        return this.http.put(url, reqBody);
    }

    public updateContainer(id, body) {
        const url = CONTAINERS_CONSTANTS.APIs.UPDATE_CONTAINER.replace('{containerId}', id + '');
        return this.http.put(url, body);
    }

    public getContainerPackagesAPI(vehicleId) {
        return CONTAINERS_CONSTANTS.APIs.CONTAINER_PACKAGES.replace('{vehicleId}', vehicleId);
    }

    public getContainerPackages(vehicleId, urlQuery) {
        const url = CONTAINERS_CONSTANTS.APIs.CONTAINER_PACKAGES.replace('{vehicleId}', vehicleId);
        return this.http.get(url + urlQuery, {});
    }

    public printContainers(ids) {
        const url = CONTAINERS_CONSTANTS.APIs.PRINT_CONTAINERS;
        return this.http.post(url, {ids}, {
            params: {
                utcOffset: moment().utcOffset().toString()
            }
        });
    }

    public printContainerPackages(id) {
        const url = CONTAINERS_CONSTANTS.APIs.PRINT_CONTAINER_PACKAGES.replace('{vehicleId}', id);
        return this.http.get(url, {
            params: {
                utcOffset: moment().utcOffset().toString()
            }
        });
    }

    public getDriverName(driverObject: DriverModel) {
        if (!driverObject) {
            return;
        }
        return (driverObject.firstName ? driverObject.firstName : '') + ' '
        + (driverObject.lastName ? driverObject.lastName : '');
    }

    public getVehicleTypes() {
        return this.http.get(CONTAINERS_CONSTANTS.APIs.GET_VEHICLE_TYPES);
    }

    public getVehicleBrands() {
        return this.http.get(CONTAINERS_CONSTANTS.APIs.GET_VEHICLE_BRANDS);
    }

    public newVehicleType(reqBody) {
        return this.http.post(CONTAINERS_CONSTANTS.APIs.NEW_VEHICLE_TYPE, reqBody);
    }

    public editVehicleType(vehicleTypeId, reqBody) {
        const url = CONTAINERS_CONSTANTS.APIs.EDIT_VEHICLE_TYPE.replace('{vehicleTypeId}', vehicleTypeId);
        return this.http.put(url, reqBody);
    }

    public deleteVehicleType(vehicleTypeId) {
        const url = CONTAINERS_CONSTANTS.APIs.DELETE_VEHICLE_TYPE.replace('{vehicleTypeId}', vehicleTypeId);
        return this.http.delete(url);
    }
}
