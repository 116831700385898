import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {PricingService} from '../../services/pricing.service';


@Component({
    selector: 'app-shipping-rates-history',
    templateUrl: 'shipping-rates-history.component.html',
    styleUrls: ['shipping-rates-history.component.scss']
})
export class ShippingRatesHistoryComponent implements OnInit {
    currentLang;
    currency;
    historyData = [];
    isLoading = false;
    page = 0;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    first = 0;
    totalRecords = 0;

    @ViewChild('table') table;

    constructor(private translateService: TranslateService,
                private activeModal: NgbActiveModal,
                private pricingService: PricingService,
                public userService: UserService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.currency = this.userService.getCurrency();
    }

    reset() {
        this.table.reset();
        this.historyData = [];
        this.page = 0;
    }

    public closeModal() {
        this.activeModal.close();
    }

    createParams() {
        const params = {pageSize: this.pageSize, page: this.page + 1};
        return params;
    }

    getHistoryData() {
        this.isLoading = true;
        this.pricingService.getShippingRatesHistory(this.createParams()).subscribe(
            (res: any) => {
                this.historyData = res.data;
                this.totalRecords = res.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }
    loadReportsLazy(event) {
        this.page = event.first / event.rows;
        this.pageSize = event.rows;
        this.first = event.first;
        this.getHistoryData();
    }

    getLocalTime (dateTime) {
        if (!dateTime) return;
        return new Date(dateTime).toLocaleTimeString();

    }
}
