<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="dashboard-container overflow-auto" [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div class="header-and-actions-container d-flex justify-content-between">
        <div class="header-label">
            <span>{{'TICKETING_SYSTEM.DASHBOARD.TITLE' | translate}}</span>
        </div>
        <div class="dashboard-actions d-flex">
            <div class="dashboard-action d-flex" (click)="showAddTicket()">
                <div class="icon">
                    <img src="../../assets/icons/ticketing-system/add-ticket.png" alt=""/>
                </div>
                <div class="label">
                    <span>{{'TICKETING_SYSTEM.ADD_TICKET' | translate}}</span>
                </div>
            </div>
            <form [formGroup]="filterForm" class="d-flex flex-row filter">
                <p-dropdown *ngIf="permissions.includes('SELECT_ANY_DEPARTMENT')"
                            formControlName="categoryName"
                            type="text"
                            [options]="categoriesDropDownList"
                            (onChange)="onCategoryNameChanged($event)"
                            dataKey="name" optionLabel="name">
                </p-dropdown>
<!--                <div class="data-search date-picker-filter cursor-pointer">-->
<!--                              <span class="date-icon">-->
<!--                                <img src="../../assets/icons/manage_shipments/calendar.svg" alt="">-->
<!--                            </span>-->
<!--                    <span class="lg lg-calendar" (click)="datepicker.click()"></span>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                           #datepicker-->
<!--                           daterangepicker [options]="dateRangPickerOptions"-->
<!--                           (selected)="onDateSelected($event)"/>-->
<!--                    <i class="fas fa-caret-down"></i>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

            </form>
        </div>
    </div>
    <div class="contents-container">
        <div class="cards-group row d-flex justify-content-center">
            <ng-container *ngIf="gettingStats">
                <div class="card-item-container no-padding d-flex col-lg-2 col-md-2 col-sm-4 col-6"
                     *ngFor="let card of [1,2,3,4,5,6]">
                    <app-skeleton-loader [isSingleLoader]="true" [dashboardCard]="true"
                                         [type]="0"></app-skeleton-loader>
                </div>
            </ng-container>
            <ng-container *ngIf="!gettingStats">
                <div *ngFor="let card of dashboardCards"
                     class="card-item-container no-padding d-flex col-sm-4 col-6" [ngClass]="permissions.includes('SELECT_ANY_DEPARTMENT') && categoryFilterSelected === -1 ? 'col-lg-3  col-md-3' :  'col-lg-2 col-md-2'">
                    <div class="card-item" (click)="navigateTo('technical-support-tickets', {}, card.label)">
                        <div class="card-label">
                            <span>{{card.label | translate}}</span>
                        </div>
                        <div class="card-number">
                            <span>{{card.count}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <!--        <div class="achievement-container d-flex">-->
        <!--            <div class="header-and-filters row d-flex">-->
        <!--                <div class="header-title">-->
        <!--                    <span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.TITLE' | translate}}</span>-->
        <!--                </div>-->
        <!--                <div class="filters-container d-flex">-->
        <!--                    <div class="filter-item active-filter-item"><span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.FILTERS.DAY' | translate}}</span></div>-->
        <!--                    <div class="filter-item"><span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.FILTERS.WEEK' | translate}}</span></div>-->
        <!--                    <div class="filter-item"><span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.FILTERS.YEAR' | translate}}</span></div>-->
        <!--                    <div class="filter-item"><span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.FILTERS.MONTH' | translate}}</span></div>-->
        <!--                </div>-->
        <!--            </div>-->

        <!--            <div class="chart-container">-->
        <!--                <div class="row d-flex">-->
        <!--                    <div class="chart-diagram flex-1">-->
        <!--                        <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>-->
        <!--                    </div>-->
        <!--                    <div class="chart-description">-->
        <!--                        <div class="received-and-solved d-flex">-->
        <!--                            <div class="received-container d-flex">-->
        <!--                                <div class="marker">-->
        <!--                                </div>-->
        <!--                                <div class="received-done">-->
        <!--                                    <div class="received-and-solved-label">-->
        <!--                                        <span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.RECEIVED_DONE' | translate}}</span>-->
        <!--                                    </div>-->
        <!--                                    <div class="received-and-solved-count received-count">-->
        <!--                                        <span>33</span>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <div class="solved-container d-flex">-->
        <!--                                <div class="marker">-->
        <!--                                </div>-->
        <!--                                <div class="solved-done">-->
        <!--                                    <div class="received-and-solved-label">-->
        <!--                                        <span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.RESOLVED_DONE' | translate}}</span>-->
        <!--                                    </div>-->
        <!--                                    <div class="received-and-solved-count solved-count">-->
        <!--                                        <span>25</span>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="description-rates-container">-->
        <!--                            <div class="response-rate">-->
        <!--                                <div class="chart-description-title">-->
        <!--                                    <span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.RESPONSE_RATE' | translate}}</span>-->
        <!--                                </div>-->
        <!--                                <div class="response-rate-value">-->
        <!--                                    <span>30m 10s</span>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <div class="completion-rate">-->
        <!--                                <div class="chart-description-title">-->
        <!--                                    <span>{{'TICKETING_SYSTEM.DASHBOARD.ACHIEVEMENT_SECTION.COMPLETION_RATE' | translate}}</span>-->
        <!--                                </div>-->
        <!--                                <div class="rate-value d-flex">-->
        <!--                                    <div class="value-number">-->
        <!--                                        <span>75%</span>-->
        <!--                                    </div>-->
        <!--                                    <div class="rate-progress-bar">-->
        <!--                                        <span [ngStyle]="{'width':  '75' + '%' }"></span>-->
        <!--                                    </div>-->

        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="cards-of-details row">
            <!--            <div class="customer-satisfaction-rate-card card-container col-lg-4 col-md-12 col-sm-12 col-xs-12 no-padding">-->
            <!--                <div class="card-header-container">-->
            <!--                    <div class="description-card-title">-->
            <!--                        <span>نسبة رضا الزبائن</span>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="progress-bars-and-total d-flex">-->
            <!--                    <div class="customer-satisfaction-rate-progress-bars flex-1">-->
            <!--                       <div class="status-container">-->
            <!--                           <div class="percentage-value-container d-flex">-->
            <!--                               <div class="icon">-->
            <!--                                   <img src="../../assets/icons/ticketing-system/happy.png" alt="" />-->
            <!--                               </div>-->
            <!--                               <div class="value-label">-->
            <!--                                   <div class="value"><span>88%</span></div>-->
            <!--                                   <div class="label"><span>راضي</span></div>-->
            <!--                               </div>-->
            <!--                           </div>-->
            <!--                           <div class="status-progress-bar">-->
            <!--                               <div class="status-percentage-progress-bar happy-progress-bar">-->
            <!--                                   <span  [ngStyle]="{'width':  '88' + '%' }"></span>-->
            <!--                               </div>-->
            <!--                           </div>-->
            <!--                       </div>-->
            <!--                        <div class="status-container">-->
            <!--                            <div class="percentage-value-container d-flex">-->
            <!--                                <div class="icon">-->
            <!--                                    <img src="../../assets/icons/ticketing-system/normal-face.png" alt="" />-->
            <!--                                </div>-->
            <!--                                <div class="value-label">-->
            <!--                                    <div class="value"><span>6%</span></div>-->
            <!--                                    <div class="label"><span>عادي</span></div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                            <div class="status-progress-bar">-->
            <!--                                <div class="status-percentage-progress-bar normal-progress-bar">-->
            <!--                                    <span  [ngStyle]="{'width':  '6' + '%' }"></span>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="status-container">-->
            <!--                            <div class="percentage-value-container d-flex">-->
            <!--                                <div class="icon">-->
            <!--                                    <img src="../../assets/icons/ticketing-system/sad-face.png" alt="" />-->
            <!--                                </div>-->
            <!--                                <div class="value-label">-->
            <!--                                    <div class="value"><span>6%</span></div>-->
            <!--                                    <div class="label"><span>غير راضي</span></div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                            <div class="status-progress-bar">-->
            <!--                                <div class="status-percentage-progress-bar sad-progress-bar">-->
            <!--                                    <span  [ngStyle]="{'width':  '6' + '%' }"></span>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->

            <!--                    </div>-->
            <!--                    <div class="total-number-container d-flex">-->
            <!--                        <div class="total-number">-->
            <!--                            <div class="label">-->
            <!--                                <span>العدد الكلي</span>-->
            <!--                            </div>-->
            <!--                            <div class="number">-->
            <!--                                <span>260</span>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="opened-support-ticket-card card-container col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding">
                <div class="card-header-container">
                    <div class="description-card-title">
                        <span>{{'TICKETING_SYSTEM.DASHBOARD.OPENED_SUPPORT_TICKETS.TITLE' | translate}}</span>
                    </div>
                    <div class="details-btn d-flex">
                        <div class="details-btn-label" (click)="navigateTo('technical-support-tickets')">
                            <span>{{'TICKETING_SYSTEM.DASHBOARD.OPENED_SUPPORT_TICKETS.DETAILS' | translate}}</span>
                        </div>
                        <div class="caret-icon">
                            <img *ngIf="selectedLang === 'ar'" src="../../assets/icons/login/left-arrow.svg">
                            <img *ngIf="selectedLang === 'en'" src="../../assets/icons/login/right-arrow.svg">
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="!gettingCategories">
                    <ng-container *ngIf="!categories.length">
                        <div class="no-data-available sections-container flex-2d-center">
                            <div class="message">
                                <span>{{'TICKETING_SYSTEM.DASHBOARD.OPENED_SUPPORT_TICKETS.NO_DATA_MESSAGE' | translate}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="categories.length">
                        <div class="sections-container">
                            <div class="row d-flex section-item" *ngFor="let category of categories">
                                <div class="section-label no-padding">
                                    <span>{{category.name}}</span>
                                </div>
                                <div class="tickets-count-container no-padding">
                                    <div class="icon">
                                        <img src="../../assets/icons/ticketing-system/ticket.png" alt=""/>
                                    </div>
                                    <div class="value">
                                        <span>{{category.ticketsCount}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </ng-container>
                <ng-container *ngIf="gettingCategories">
                    <div class="sections-container">
                        <div class="row d-flex section-item" *ngFor="let category of [1,2,3,4,5,6,7,8]">
                            <app-skeleton-loader [type]="2"></app-skeleton-loader>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="reminders-and-notes-card card-container col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding">
                <div class="card-header-container">
                    <div class="description-card-title">
                        <span>{{'TICKETING_SYSTEM.DASHBOARD.GENERAL_NOTES.TITLE' | translate}}</span>
                    </div>
                    <form [formGroup]="noteFilterForm" class="d-none flex-row filter">
<!--                        <div class="data-search date-picker-filter cursor-pointer">-->
<!--                              <span class="date-icon">-->
<!--                                <img src="../../assets/icons/manage_shipments/calendar.svg" alt="">-->
<!--                            </span>-->
<!--                            <span class="lg lg-calendar" (click)="noteDatepicker.click()"></span>-->
<!--                            <field-input type="text" name="notedaterangeInput" class="custom-date-range"-->
<!--                                   #noteDatepicker-->
<!--                                   daterangepicker [options]="dateRangPickerOptions"-->
<!--                                   (selected)="onNoteDateSelected($event)"/>-->
<!--                            <i class="fas fa-caret-down"></i>-->
<!--                        </div>-->
                        <app-date-range-picker (onSelect)="onNoteDateSelected($event)"></app-date-range-picker>

                    </form>
                    <div class="add-note-btn d-flex" (click)="openGeneralNoteForm()">
                        <div class="icon">
                            <img src="../../assets/icons/ticketing-system/plus.png" alt=""/>
                        </div>
                        <div class="label">
                            <span>{{'TICKETING_SYSTEM.DASHBOARD.GENERAL_NOTES.ADD_NOTE' | translate}}</span></div>
                    </div>
                </div>
                <div class="notes-list" (scroll)="scrollGeneralNotes($event)">
                    <div class="notes-container d-flex" *ngFor="let note of generalNotes">
                        <div class="note-info d-flex">
                            <!--                           <div class="note-check-box">-->
                            <!--                               <p-checkbox></p-checkbox>-->
                            <!--                           </div>-->
                            <div class="label-and-date">
                                <div class="label-value"><span>{{note.text}}</span></div>
                                <div class="label-value"><span>{{note.noteType | translate}}</span></div>
                                <div class="date-details d-flex">
                                    <div class="date-container d-flex">
                                        <div class="icon">
                                            <img src="../../assets/icons/manage_shipments/calendar.svg" alt="">
                                        </div>
                                        <div class="date-value"><span>{{transformDate(note.dueDate)}}</span>
                                        </div>
                                    </div>
                                    <!--                                            <div class="splitter">|</div>-->
                                    <!--                                            <div class="days-description"><span>اليوم</span></div>-->
                                </div>
                            </div>
                        </div>
                        <div class="note-actions d-flex">
                            <div class="update-note note-action cursor-pointer"
                                 (click)="openGeneralNoteForm(note)">
                                <img src="../../assets/icons/ticketing-system/categories-management/edit.png"
                                     alt=""/>
                            </div>
                            <div class="delete-note note-action cursor-pointer"
                                 (click)="deleteGeneralNote(note.id)">
                                <img src="../../assets/icons/ticketing-system/categories-management/delete.png"
                                     alt=""/>
                            </div>
                        </div>
                    </div>

                    <div class="loader-skel" *ngIf="gettingGeneralNotes">
                        <div class="notes-container d-flex" *ngFor="let note of [1,2,3,4]">
                            <app-skeleton-loader [isSingleLoader]="true"
                                                 [dashboardCard]="true"
                                                 [type]="0">
                            </app-skeleton-loader>
                        </div>
                    </div>
                    <div class="no-data-available  flex-2d-center" *ngIf="!generalNotes.length && !gettingGeneralNotes">
                        <div class="message">
                            <span>{{'TICKETING_SYSTEM.GENERAL_NOTES.NO_DATA_AVAILABLE' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-add-ticket-form *ngIf="displayAddTicket"
                     [display]="displayAddTicket"
                     (closeWidget)="onHideAddTicket($event)">
</app-add-ticket-form>
