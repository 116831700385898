import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {setBarcodeReader} from '../../shared/services/helpers';
import {EnterBarcodeComponent} from '../enter-barcode/enter-barcode.component';
import {ChangePackageStatusComponent} from '../change-package-status/change-package-status.component';
import {UserService} from '../../shared/services/user.service';
import {UsersService} from '../../users/services/users.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {UtilitiesService} from '../../shared/services/utilities.service';
import {playError} from '../../shared/behavior/sounds';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {ChooseCustomerComponent} from '../../partners/choose-customer/choose-customer.component';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ExportShipmentsComponent} from '../export-shipments/export-shipments.component';

@Component({
    selector: 'app-change-status-packages-table',
    templateUrl: './change-status-packages-table.component.html',
    styleUrls: ['./change-status-packages-table.component.scss']
})
export class ChangeStatusPackagesTableComponent implements OnInit, OnDestroy {

    public isScanning = false;
    public isLoading = false;
    public packages = [];
    public isReading = false;
    public barcode = '';
    public permissions = '';

    public isTransfer = false;
    public isCancel = false;
    public partnerId = 0;
    public spinnerState = SpinnerState.EMPTY;
    public spinnerStates = SpinnerState;
    public isBundle = false;
    public isReturned = false;
    public customerId = false;
    public bundleId = null;
    public bundleCod = 0;

    public isTrackingPackage;
    public triggeredShipment: any = {};

    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    public isLcl = false;
    displayAddPackage = false;
    selectedPackageBarcode: number;
    public isEnableExportingBetweenHubs = false;

    isAllSelected = false

    constructor(private route: ActivatedRoute,
                public router: Router,
                private modalService: NgbModal,
                private messageService: MessageService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private shipmentsService: ShipmentsService,
                private authService: AuthenticationService,
                private usersService: UsersService,
                private userService: UserService,
                private rolesService: RolesService,
                private utilitiesService: UtilitiesService) {
    }

    ngOnInit() {
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.permissions = this.rolesService.getUserPermissions('MANAGE_SHIPMENTS', role);
        this.isEnableExportingBetweenHubs = this.userService.userInfo.isEnableExportingBetweenHubs;
        this.isReading = true;
        this.route.queryParams.subscribe(
            (params: Params) => {
                if (params.isBundle) {
                    this.isBundle = true;
                    this.isReturned = !!params.isReturned;
                    this.customerId = params.customerId;
                    this.bundleId = params.bundleId ? params.bundleId : null;
                    this.isLcl = this.userService.userInfo.isLcl;
                    if (this.bundleId) {
                        this.getBundleCod();
                    }
                } else {
                    if (params.barcode) {
                        this.barcode = params.barcode;
                    }
                    if (params.is_transfer) {
                        this.isTransfer = true;
                    }

                    if (params.is_cancel) {
                        this.isCancel = true;
                        this.partnerId = params.partner_id;
                    }
                }
            });
        setBarcodeReader((barcode) => {
            if (this.isReading) {
                this.barcodeReader(barcode);
            }
        });
        this.getBundles();
    }

    public getBundleCod() {
        this.shipmentsService.getBundleCod(this.bundleId).subscribe(
            (response: any) => {
                this.bundleCod = response.cod;
            }, error => {
                console.error(error);
            }
        );
    }

    private barcodeReader(barcode) {
        if (!this.isScanning) {
            return;
        }
        this.isLoading = true;
        if (this.partnerId) {
            this.shipmentsService.getPackageByBarcodeCancel(barcode, this.partnerId).subscribe((shipment) => {
                this.translateService.get(
                    'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.selectPackage(shipment);
                            this.isLoading = false;
                        }
                    );
            }, () => {
                playError();
                this.isLoading = false;
            });
        } else {
            this.shipmentsService.getPackageByBarcode(barcode, 'ADMIN', null, true).subscribe((shipment) => {
                this.translateService.get(
                    'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.selectPackage(shipment);
                            this.isLoading = false;
                        }
                    );
            }, () => {
                playError();
                this.isLoading = false;
            });
        }
    }

    public getBundles() {
        if (!this.bundleId || !this.isBundle) {
            return;
        }
        this.spinnerState = SpinnerState.LOADING;
        this.shipmentsService.getBundles(this.bundleId).subscribe(
            (response: any) => {
                this.packages = response.pkgs;
                if (response.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, error => {
                console.error(error);
            }
        );
    }

    public startScanning() {
        this.isScanning = true;
    }

    public removePackage(shipment) {
        this.packages.splice(this.packages.indexOf(shipment), 1);
    }

    public selectPackage(shipment) {
        for (const oldShipment in this.packages) {
            if (this.packages[oldShipment].barcode === shipment.barcode) {
                if (this.isBundle && this.bundleId && !this.packages[oldShipment].isSelected) {
                    this.packages[oldShipment].isSelected = true;
                    this.messageService.add({
                        severity: 'success',
                        detail: this.translateService.instant('ALERTS.BARCODE_SCANNED_SUCCESSFULLY')
                    });
                    return;
                }
                // Display already scanned popup
                this.translateService.get(['ALERTS.PACKAGE_ALREADY_SCANNED']
                ).subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['ALERTS.PACKAGE_ALREADY_SCANNED'],
                        accept: () => {
                        },
                        reject: () => {

                        },
                        acceptLabel: 'OK',
                        rejectVisible: false
                    });
                });
                return;
            }
        }
        shipment.isSelected = true;
        this.packages.unshift(shipment);
    }

    public addToBundles(pkg) {
        this.isLoading = true;
        this.shipmentsService.addBundle(this.bundleId, pkg.id).subscribe(
            (response: any) => {
                this.getBundles();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    public enterBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isAdmin = true;
        modal.componentInstance.partnerId = this.partnerId;
        modal.componentInstance.isGetPackageStatus = true;
        if (this.isReturned && this.isBundle) {
            modal.componentInstance.onlyReturnedBundle = true;
        }
        modal.result.then(
            (data: any) => {
                if (data && data.isSuccess) {
                    if (this.isReturned && this.isBundle) {
                        this.scanReturnedPkgForBundle(data.barcode);
                        return;
                    }
                    if (this.bundleId) {
                        this.addToBundles(data.package);
                    } else {
                        this.selectPackage(data.package);
                    }
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );

    }

    public resetScanning(showConfirm = true) {
        if (showConfirm) {
            this.translateService.get(['ACCOUNTING.RESET_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO']
            ).subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.RESET_SCANNING_CONFIRMATION'],
                    accept: () => {
                        this.isScanning = false;
                        this.packages = [];
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO'],
                });
            });
        } else {
            this.isScanning = false;
            this.packages = [];
        }
    }

    public trackShipment(shipment = null) {
        if (shipment) {
            this.triggeredShipment = shipment;
        }
        this.isTrackingPackage = true;
    }

    public closeTracker($event) {
        this.isTrackingPackage = $event;
    }

    public collectBundle() {
        const modal = this.modalService.open(ChooseCustomerComponent, <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
        });
        modal.componentInstance.fromBundle = true;
        if (this.isBundle && this.isReturned) {
            modal.componentInstance.selectedSourceType = 'HUB';
            modal.componentInstance.selectedDestinationType = 'CUSTOMER';
            modal.componentInstance.hideSelectOption = true;
            modal.componentInstance.chooseSourceOnly = true;
        }
        modal.result.then(
            (status) => {
                if (status.isSuccess && status.result) {
                    const data = status.result;
                    const selectedPackages = this.packages.map(pkg => pkg.id);
                    const body = {ids: selectedPackages};
                    if (data.sourceHubId) {
                        body['sourceHubId'] = data.sourceHubId;
                        body['destinationHubId'] = data.destinationHubId;
                        if (this.isReturned && this.isBundle) {
                            body['customerId'] = this.customerId;
                        }
                    } else {
                        body['customerId'] = data.customerId;

                        if (data.destinationHubId) {
                            body['destinationHubId'] = data.destinationHubId;
                        } else {
                            body['destinationAddress'] = data.destinationAddress;
                        }
                    }
                    this.isLoading = true;
                    (this.isReturned && this.isBundle ? this.shipmentsService.createBundleForReturned(body) : this.shipmentsService.createBundle(body)).subscribe(
                        (response: any) => {
                            this.packages = [];
                            this.isLoading = false;
                            this.trackShipment(response);
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        }
                    );
                }
            });
    }

    actualRemovePackageFromBundle(pkgId) {
        this.shipmentsService.removePackageFromBundle(this.bundleId, pkgId).subscribe(
            (response: any) => {
                this.translateService.get(['ALERTS.PACKAGE_DELETED_FROM_BUNDLE_SUCCESSFULLY'])
                    .subscribe((res: any) => {
                        this.getBundles();
                        this.isLoading = false;
                        this.messageService.add({severity: 'success', detail: res['ALERTS.PACKAGE_DELETED_FROM_BUNDLE_SUCCESSFULLY']});
                    });
            }, error => {
                this.isLoading = false;
                console.error(error);
            }
        );

    }

    public removePackageFromBundle(pkgId) {
        if (!this.bundleId || !this.isBundle) {
            return;
        }
        this.isLoading = true;
        this.translateService.get(['ALERTS.CONFIRM_REMOVE_PACKAGE_FROM_BUNDLE', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.CONFIRM_REMOVE_PACKAGE_FROM_BUNDLE'],
                accept: () => {
                    this.actualRemovePackageFromBundle(pkgId);
                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }


    public finishScanning() {
        if (!this.packages || !this.packages.length) {
            return;
        }
        if (this.isBundle) {
            this.translateService.get(['ACCOUNTING.FINISH_COLLECT_BUNDLE', 'GENERAL.YES', 'GENERAL.NO'])
                .subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['ACCOUNTING.FINISH_COLLECT_BUNDLE'],
                        accept: () => {
                            this.collectBundle();
                        },
                        reject: () => {

                        },
                        acceptLabel: res['GENERAL.YES'],
                        rejectLabel: res['GENERAL.NO']
                    });
                });
        } else {
            this.translateService.get(['ACCOUNTING.FINISH_SCANNING_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'])
                .subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['ACCOUNTING.FINISH_SCANNING_CONFIRMATION'],
                        accept: () => {
                            if (this.isTransfer) {
                                this.exportPackagesTo();
                            } else {
                                if (this.isCancel) {
                                    this.cancelPackages();
                                } else {
                                    this.changePackageStatus();
                                }
                            }
                        },
                        reject: () => {

                        },
                        acceptLabel: res['GENERAL.YES'],
                        rejectLabel: res['GENERAL.NO']
                    });
                });
        }

    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    transformToDistributor(result) {
        if (this.userService.userInfo.isDistributor && result) {
            result = result.replace(/(Shipment|Package)/, 'Order');
            result = result.replace(/(shipment|package)/, 'order');
            result = result.replace('طرد', 'طلبية');
            result = result.replace('شحنة', 'طلبية');
            result = result.replace('الطرد', 'الطلبية');
            result = result.replace('طرود', 'طلبيات');
            result = result.replace('شحنات', 'طلبيات');
        }
        return result;
    }

    exportPackagesTo() {
        const selectedPackages = this.packages.map(pkg => pkg.id);
        const modal = this.modalService.open(ExportShipmentsComponent, {backdrop: 'static', windowClass: 'export-packages-to', size: 'lg'});
        modal.result.then(
            (result: { id?: number, type?: string }) => {
                if (result && result.id) {
                    this.isLoading = true;
                    let exportPackages = this.shipmentsService.transferPackageByIds(result.id, selectedPackages); // export to partner api
                    if (result.type === 'HUB') { // call transfer to hub api
                        exportPackages = this.shipmentsService.transferPackageByIds(result.id, selectedPackages);
                    }
                    exportPackages.subscribe(() => {
                        const toastMessage = result.type === 'PARTNER' ? 'ALERTS.PACKAGES_TRANSFERRED_SUCCESSFULLY' : 'ALERTS.PACKAGES_TRANSFERRED_TO_HUB_SUCCESSFULLY';
                        this.messageService.add({
                            severity: 'success',
                            detail: this.transformToDistributor(this.translateService.instant(toastMessage))
                        });
                        this.isLoading = false;
                        this.resetScanning(false);
                    }, (err) => {
                        this.download('errors.txt', err.error.error);
                        this.isLoading = false;
                    });
                }
            }
        );
    }

    public cancelPackages() {
        this.isLoading = true;
        const selectedPackages = this.packages.map(pkg => pkg.id);
        // Call transfer API
        this.shipmentsService.cancelPackageByIds(this.partnerId, selectedPackages).subscribe(() => {
            this.translateService.get('ALERTS.PACKAGES_CANCELLED_SUCCESSFULLY').subscribe((data) => {
                this.confirmationService.confirm({
                    message: data,
                    rejectVisible: false,
                    acceptLabel: 'OK'
                });
                this.resetScanning(false);
            });
            this.isLoading = false;
        }, (err) => {
            this.utilitiesService.download('errors.txt', err.error.error);
            this.isLoading = false;
        });
    }

    public changePackageStatus() {
        if (this.barcode) {
            this.unloadPackages();
            return;
        }
        const modal = this.modalService.open(ChangePackageStatusComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.shipments = this.packages;
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.CHANGED_STATUS'
                    )
                        .subscribe(
                            (message) => {
                                this.confirmationService.confirm({
                                    message: message,
                                    rejectVisible: false,
                                    acceptLabel: 'OK'
                                });
                            }
                        );
                    this.resetScanning(false);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

    public downloadMultiplePackages() {
        if (!this.packages || !this.packages.length) {
            return;
        }

        this.isLoading = true;
        const selectedPackages = this.packages.map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleChangeStatus(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    ngOnDestroy(): void {
        this.isReading = false;
    }

    public unloadPackages() {
        const body = {barcode: this.barcode};
        body['ids'] = this.packages.map((shipment) => {
            return shipment.id;
        });
        this.shipmentsService.unloadPackages(body).subscribe(
            (response) => {
                this.translateService.get(
                    'ALERTS.CHANGED_STATUS'
                )
                    .subscribe(
                        (message) => {
                            this.confirmationService.confirm({
                                message: message,
                                rejectVisible: false,
                                acceptLabel: 'OK'
                            });
                        }
                    );
                this.resetScanning(false);
            }, () => {
            }
        );
    }

    public editPackage(pkg) {
        this.selectedPackageBarcode = pkg.barcode;
        this.displayAddPackage = true;
    }

    public onHideAddPackage(event) {
        this.displayAddPackage = event;
        this.selectedPackageBarcode = null;
    }

    public showTransferToThirdPart() {
        return (this.authService.companyId === 94) && this.isScanning;
    }

    public transferToThirdPart() {
        if (!this.packages || !this.packages.length) {
            return;
        }
        let message = '';
        let successMessage = '';
        let yes = '';
        let no = '';
        this.translateService.get(
            ['ALERTS.CONFIRM_TRANSFER_TO_THIRD_PART', 'GENERAL.YES', 'GENERAL.NO', 'ALERTS.PACKAGES_EXPORTED_SUCCESSFULLY']
        ).subscribe(
            (data) => {
                message = data['ALERTS.CONFIRM_TRANSFER_TO_THIRD_PART'];
                yes = data['GENERAL.YES'];
                no = data['GENERAL.NO'];
                successMessage = data['ALERTS.PACKAGES_EXPORTED_SUCCESSFULLY'];
            }
        );
        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.isLoading = true;
                const selectedPackages = this.packages.map(pkg => pkg.id);
                this.shipmentsService.transferToThirdPart(selectedPackages).subscribe((response: any) => {
                    this.isLoading = false;
                    this.messageService.add({severity: 'success', detail: successMessage});
                }, () => {
                    this.isLoading = false;
                });
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: yes,
            rejectLabel: no
        });
    }

    public onChangeBundleCod() {
        if (!this.bundleCod || !this.bundleId) {
            return;
        }

        const body = {cod: this.bundleCod};
        this.shipmentsService.editBundleCod(this.bundleId, body).subscribe(
            (response: any) => {
                this.translateService.get('UPDATED').subscribe(
                    (res: any) => {
                        this.getBundleCod();
                        this.messageService.add({severity: 'success', detail: res});
                    }
                );
            }, error => {
                console.error(error);
            }
        );
    }


    onToggleAllSelection() {
        this.packages.forEach(
            (pkg) => {
                pkg.isSelected = this.isAllSelected;
            }
        )
    }

    getSelectedPackages() {
        return this.packages.filter(pkg => (pkg.isSelected));
    }

    onToggleSelection() {
        this.isAllSelected = this.getSelectedPackages().length === this.packages.length;
    }

    extractBundle() {
        this.confirmationService.confirm({
            message: this.translateService.instant('BUNDLE.ARE_YOU_SURE_YOU_WANT_TO_EXTRACT_THE_SELECTED_PACKAGES_FROM_THE_BUNDLE'),
            accept: () => {
                this.isLoading = true;
                const body = {ids: this.getSelectedPackages().map(pkg => (pkg.id))};
                this.shipmentsService.extractBundle(this.bundleId, body).subscribe(
                    () => {
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                        this.isLoading = false;
                        this.resetScanning(false);
                        this.getBundles();
                    }, error => {
                        console.error(error);
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {

            },
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
        });
    }

    private scanReturnedPkgForBundle(barcode) {
        this.shipmentsService.getReturnedPackageByBarcodeForBundle(barcode, this.customerId).subscribe((shipment) => {
            this.translateService.get(
                'ALERTS.BARCODE_SCANNED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.selectPackage(shipment);
                        this.isLoading = false;
                    }
                );
        }, () => {
            playError();
            this.isLoading = false;
        });
    }

    selectPackageByBarcode() {
        const modal = this.modalService.open(EnterBarcodeComponent, <any>{size: 'md', windowClass: 'enter-barcode-popover'});
        modal.componentInstance.isAdmin = true;

        modal.result.then(
            (data: any) => {
                if (data && data.isSuccess) {
                    if (data.package.bundleId == this.bundleId) {
                        this.selectPackage(data.package);
                    } else {
                        this.messageService.add({
                            severity: 'error',
                            detail: this.translateService.instant('ALERTS.PACKAGE_SCANNED_FAILED')
                        });
                    }
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    barcodeReaderForBundlesOnly(barcode: string) {
        if (this.isBundle && this.bundleId) {
            this.shipmentsService.getPackageByBarcode(barcode, this.userService.userInfo.role, null, true).subscribe((shipment) => {
                    this.selectPackage(shipment);
                    this.isLoading = false;
                }
            );
        }
    }
}
