import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PackageContentsService} from '../../administration/services/package-contents.service';
import {PackageContentModel} from '../models/package-content.model';

@Component({
    selector: 'app-package-contents-list',
    templateUrl: './package-contents-list.component.html',
    styleUrls: ['./package-contents-list.component.scss']
})
export class PackageContentsListComponent implements OnInit , OnChanges {
    currentLang = '';
    @Input() currentPackageContents: PackageContentModel[];
    currentPackageIds: number[] = [];
    @Input() isTrucking = false;
    @Input() showOriginalInEdit = false;
    @Output() selectedContents: EventEmitter<{contents: PackageContentModel[]}> = new EventEmitter<{contents: PackageContentModel[]}>();

    packageContents: any[] = [];
    @Input() showAvailable = true;
    constructor(private translateService: TranslateService,
                private packageContentsService: PackageContentsService) {}

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.currentPackageIds = this.currentPackageContents.map(content => content.id);
        this.initPackageContents();
    }
    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
    }

    initPackageContents() {
        this.packageContentsService.getContentsList('?pageSize=100').subscribe(
            (contents: {data: PackageContentModel[]}) => {
                this.packageContents = contents.data.map(content => {
                    const existContent = (this.currentPackageIds.indexOf(content.id) > -1);
                    if (existContent) {
                        content  =  this.currentPackageContents.find(packageContent => packageContent.id === content.id);
                    }
                    if (this.isTrucking) {
                        return {
                            isSelected: existContent ,
                            contentItemId: content.id,
                            name: this.currentLang === 'en' ? content.name : content.arabicName,
                            packages: content.packageContentInfo ? content.packageContentInfo.packages : 0,
                        };
                    }
                    const body:any = {
                        isSelected: existContent ,
                        contentItemId: content.id,
                        name: this.currentLang === 'en' ? content.name : content.arabicName,
                        unit: content.packageContentInfo ? content.packageContentInfo.unit : 0,
                        weight: content.packageContentInfo ? content.packageContentInfo.weight : 0,
                        packages: content.packageContentInfo ? content.packageContentInfo.packages : 0,
                        boxes: content.packageContentInfo ? content.packageContentInfo.boxes : 0,
                        pallets: content.packageContentInfo ? content.packageContentInfo.pallets : 0,
                        cartons: content.packageContentInfo ? content.packageContentInfo.cartons : 0,
                    };
                    if (this.showOriginalInEdit) {
                        body.originalUnit = content.packageContentInfo ? content.packageContentInfo.originalUnit : 0;
                        body.originalWeight = content.packageContentInfo ? content.packageContentInfo.originalWeight : 0;
                        body.originalPackages = content.packageContentInfo ? content.packageContentInfo.originalPackages : 0;
                        body.originalBoxes = content.packageContentInfo ? content.packageContentInfo.originalBoxes : 0;
                        body.originalPallets = content.packageContentInfo ? content.packageContentInfo.originalPallets : 0;
                        body.originalCartons = content.packageContentInfo ? content.packageContentInfo.originalCartons : 0;
                    }
                    return body;
                });
                this.setSelectedContent();
            }
        );
    }
    getSelectedContents() {
        return [] = this.packageContents.filter(content => {
            return content.isSelected === true;
        });
    }
    setSelectedContent() {
        this.selectedContents.emit({
            contents:  this.packageContents.filter(content => {
                return content.isSelected === true;
            })
        });
    }

    resetContentValue(content: any) {
        if (content && !content.isSelected) {
            content.boxes = 0;
            content.cartons = 0;
            content.packages = 0;
            content.pallets = 0;
            content.unit = 0;
            content.weight = 0;
        }
    }
}
