import { Component, OnInit } from '@angular/core';
import {REPORTS_CONSTANTS} from '../../administration/reports-constants';

@Component({
  selector: 'app-active-customer-reports',
  templateUrl: './active-customer-reports.component.html',
  styleUrls: ['./active-customer-reports.component.scss']
})
export class ActiveCustomerReportsComponent implements OnInit {

  public customersReports = REPORTS_CONSTANTS.ACTIVE_CUSTOMERS_REPORTS.INFO;
  public customersReportsAPI = REPORTS_CONSTANTS.ACTIVE_CUSTOMERS_REPORTS.API;
  constructor() { }

  ngOnInit(): void {
  }

}
