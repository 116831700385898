<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="create-new-shelve-container">
        <div class="row justify-content-end">
            <div class="close-icon" (click)="closeModal()">
            <img src="../../assets/icons/cancel.svg" alt="icon" />
        </div>
        </div>
        <div class="header d-inline-flex">
            <div class="title" *ngIf="!isEditMode">{{ 'SHELVES_MANAGEMENT.ADD_NEW_SHELF' | translate }}</div>
            <div class="title" *ngIf="isEditMode">{{ 'SHELVES_MANAGEMENT.ACTIONS.EDIT_SHELF' | translate }}</div>
        </div>
        <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
            <p-progressSpinner> </p-progressSpinner>
        </div>

        <form [formGroup]="form" *ngIf="form">
            <div class="inputs-container">
                <div class="row justify-content-end is-safe-container">
                    <div class="is-safe-input d-flex">
                        <div class="is-safe-label">{{ 'SHELVES_MANAGEMENT.SAFE' | translate }}</div>
                        <div class="is-safe-toggle" [ngClass]="{'disabled-toggle-switch': !form?.controls['isSafe'].value}">
                            <p-inputSwitch [formControl]="form?.controls['isSafe']" (onChange)="validateForm()">

                            </p-inputSwitch></div>
                    </div>
                </div>
                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <div class="input-label shelve-name-label">{{ 'SHELVES_MANAGEMENT.SHELF_NAME' | translate }}</div>
                        <div class="input-value">
                            <app-input-field-component controlName="label" [form]="form"
                                                       controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
                <div class="row d-inline-flex row-input">
                    <div *ngIf="!this.form.controls['isSafe'].value" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <div class="input-label destination-label">{{ 'SHELVES_MANAGEMENT.DESTINATION_ADDRESS' | translate }}</div>
                        <div class="input-value">
                            <app-input-field-component controlName="destinationRegionId" [form]="form"
                                                       controlType="select" [options]="regionOptions">
                            </app-input-field-component >
                        </div>
                    </div>

                    <div *ngIf="!this.form.controls['isSafe'].value" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <div class="input-label destination-label-2">{{ 'SHELVES_MANAGEMENT.DESTINATION_ADDRESS' | translate }}</div>
                        <div class="input-value">
                            <p-multiSelect
                                    [options]="citiesOptions"
                                    formControlName = "destinationCityIds"
                                    maxSelectedLabels="0"
                                    selectedItemsLabel="{0} {{'GENERAL.ITEMS' | translate}}"
                                    filterPlaceHolder="{{'MANAGE_SHIPMENTS.SEARCH' | translate}}.."
                                    defaultLabel=""
                            >
                            </p-multiSelect>
<!--                            <app-field-input-field-component  controlName="destinationCityId" [form]="form"-->
<!--                                                       controlType="select" [options]="citiesOptions">-->
<!--                            </app-field-input-field-component >-->
                        </div>
                    </div>
                    <div *ngIf="this.form.controls['isSafe'].value" class="col-12 input">
                        <div class="input-label destination-label">{{ 'SHELVES_MANAGEMENT.DESCRIPTION' | translate }}</div>
                        <div class="input-value">
                            <app-input-field-component controlName="description" [form]="form"
                                                       controlType="text">
                            </app-input-field-component >
                        </div>
                    </div>
                </div>
                <div class="row splitter-container">
                    <div class="splitter"></div>
                </div>
                <div class="row bottom-actions">
                    <div class="row actions d-inline-flex">
                        <div class="cancel-action d-flex  col-xs-12" (click)="closeModal()">
                            <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.CANCEL' | translate}}</span>
                        </div>
                        <div class="splitter hidden-sm-down"></div>
                        <div class="done-action d-flex  col-xs-12" (click)="onAddShelf()" [ngClass]="{'disabled-btn disabled': !form.valid}">
                            <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>



























<!--<div class="new-hub-container">-->
<!--    <ng-container *ngIf="isLoading; else loaded">-->
<!--        <div style="text-align: center">-->
<!--            <p-progressSpinner>-->
<!--            </p-progressSpinner>-->
<!--        </div>-->
<!--    </ng-container>-->

<!--    <ng-template #loaded>-->
<!--        <div class="header-container">-->
<!--            {{"BRANCHES.SHELF_DETAILS" | translate}}-->
<!--            <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>-->
<!--        </div>-->
<!--        <ng-container *ngIf="!isLoading">-->
<!--            <form style="margin-top: 30px" [formGroup]="form" *ngIf="form" (submit)="onAddShelf()">-->
<!--                <div class="row label-container">-->
<!--                    <div class="col-4">-->
<!--                        {{"BRANCHES.NAME" | translate}}-->
<!--                    </div>-->
<!--                    <div class="col-8">-->
<!--                        <app-field-input-field-component  controlName="label" [form]="form"-->
<!--                                                   controlType="text">-->
<!--                        </app-field-input-field-component >-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row" *ngIf="isSafe">-->
<!--                    <div class="col-4">-->
<!--                        {{"MANAGE_CONTAINERS.DESCRIPTION" | translate}}-->
<!--                    </div>-->
<!--                    <div class="col-8">-->
<!--                        <app-field-input-field-component  controlName="description" [form]="form"-->
<!--                                                   controlType="text">-->
<!--                        </app-field-input-field-component >-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row" *ngIf="!isSafe">-->
<!--                    <div class="col-4">-->
<!--                        {{"MANAGE_CONTAINERS.DESTINATION" | translate}}-->
<!--                    </div>-->
<!--                    <div class="col-4">-->
<!--                        <app-field-input-field-component  controlName="destinationRegionId" [form]="form"-->
<!--                                                   controlType="select" [options]="regionOptions">-->
<!--                        </app-field-input-field-component >-->
<!--                    </div>-->
<!--                    <div class="col-4">-->

<!--                        <app-field-input-field-component  controlName="destinationCityId" [form]="form"-->
<!--                                                   controlType="select" [options]="citiesOptions">-->
<!--                        </app-field-input-field-component >-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row safe-container" *ngIf="!isEditMode">-->
<!--                    <div class="col-4">-->
<!--                        <p-checkbox label="{{'BRANCHES.IS_SAFE' | translate}}" [(ngModel)]="isSafe" binary="true"-->
<!--                                    [formControl]="form?.controls['isSafe']"></p-checkbox>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row submit-btn">-->
<!--                    <field-input type="submit"-->
<!--                           value="{{('BRANCHES.' + (isEditMode ? 'SAVE' : 'ADD')) | translate}}"-->
<!--                           class="cursor-pointer submit-int"-->
<!--                           [ngClass]="{'disable': !form.valid}">-->
<!--                </div>-->
<!--            </form>-->

<!--        </ng-container>-->
<!--    </ng-template>-->
<!--</div>-->
