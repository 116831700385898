import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appInputPositiveNumberDirective]'
})
export class AppInputPositiveNumberDirective implements AfterViewInit {

    private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab'];

    @Input() allowDecimals = true;

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {
        if (!this.allowDecimals) {
            this.regex = new RegExp(/^-?[0-9]+$/g);
        }
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
