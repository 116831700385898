<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="main-spinner-container" *ngIf="wholePageLoading">
    <div class="spinner-stage">
        <div class="dot-flashing"></div>
    </div>
</div>
 <div class="dashboard-container overflow-auto" [@simpleFadeAnimation]="'in'" (scroll)="onScrollMainPage($event)" [ngClass]="{'page-ltr': currentLang === 'en'}">
<!--    <div class="overflow-auto" *ngIf="permissions==='DISPATCHER' || permissions==='ADMIN' || permissions==='SUPER_ADMIN' || permissions==='HUB_MANAGER' || permissions==='ACCOUNTING_MANAGER' || permissions === 'OPERATION_MANAGER' || permissions==='CUSTOMER_CARE'">-->
    <div class="overflow-auto">
        <div *ngIf="isNotify" class = "notify-message-container">
            <div class="notify-message">
                <span>{{notificationMessage}}</span>
            </div>
        </div>

        <div *ngIf="isNotifyNewUpdate && permissions==='ADMIN'"  [ngClass]="{'ar-message': currentLang === 'ar'}" class="p-messages">
            <div class="p-message p-message-warn">
                <div class="p-message-wrapper d-flex justify-content-between update-banner">
                    <div>
                        <span>{{'ALERTS.NEW_UPDATES_ADDED_STR_1' | translate }}</span>
                        <span class="text-underline cursor-pointer ml-1 mr-1" (click)="navigateTo('documents-system-updates')"> {{'ALERTS.NEW_UPDATES_ADDED_STR_2' | translate}}</span>
                        <span>{{'ALERTS.NEW_UPDATES_ADDED_STR_3' | translate }}</span>
                    </div>
                    <div class="p-message-close d-flex align-items-center justify-content-center cursor-pointer" [ngClass]="{'disabled-btn': markAllAsSeenRequest}" (click)="clearAllUpdatesNotification()">
                        <i class="fas fa-close"></i>
                    </div>
                </div>
            </div>
        </div>
        
        <ng-template #customizeDashboardContentPop let-close="close">
            <div style="height: 10px"></div>
            <div><span class="customization-header-label">{{'DASHBOARD.CUSTOMIZE_CARDS_AND_INFO' | translate}}</span></div>
            <div style="height: 10px"></div>
            <div class="dashboard-customization-switch" [ngClass]="{'disabled-dashboard-customization-switch':!isShowDeliveredToAllPackagesRatio}">
                <div class="dashboard-switch-item">
                    <p-inputSwitch [(ngModel)]=isShowDeliveredToAllPackagesRatio (onChange)="customize('DELIVERED_TO_ALL_PACKAGES_RATIO')"></p-inputSwitch>
                </div>
                <div class="dashboard-switch-item-label">
                    <span class="customize-item-label">{{'DASHBOARD.IS_SHOW_DELIVERED_TO_ALL_PACKAGES_RATIO' | translate | packageTitle}}</span>
                </div>
            </div>
            <div class="dashboard-customization-switch" [ngClass]="{'disabled-dashboard-customization-switch':!isShowIncome}">
                <div class="dashboard-switch-item">
                    <p-inputSwitch [(ngModel)]=isShowIncome (onChange)="customize('INCOME')"></p-inputSwitch>
                </div>
                <div class="dashboard-switch-item-label">
                    <span class="customize-item-label">{{'DASHBOARD.IS_SHOW_INCOME' | translate}}</span>
            </div>
            </div>
            <div class="dashboard-customization-switch" [ngClass]="{'disabled-dashboard-customization-switch':!isShowShippingPerCity}">
                <div class="dashboard-switch-item">
                    <p-inputSwitch [(ngModel)]=isShowShippingPerCity (onChange)="customize('SHIPPING_PER_CITY')"></p-inputSwitch>
                </div>
                <div class="dashboard-switch-item-label">
                    <span class="customize-item-label">{{'DASHBOARD.IS_SHOW_SHIPPING_PER_CITY' | translate}}</span>
                </div>
                </div>
            <div class="dashboard-customization-switch" [ngClass]="{'disabled-dashboard-customization-switch':!isShowDriversEvaluation}">
                <div class="dashboard-switch-item">
                    <p-inputSwitch [(ngModel)]=isShowDriversEvaluation (onChange)="customize('DRIVERS_EVALUATION')"></p-inputSwitch>
                </div>
                <div class="dashboard-switch-item-label">
                    <span class="customize-item-label">{{'DASHBOARD.IS_SHOW_DRIVERS_EVALUATION' | translate}}</span>
                </div>
            </div>
        </ng-template>
        <ng-template #getIncomeDateFilterOverLay let-close="close">
            <div class="dashboard-year-picker">
                <p-calendar  #dateEl [inline]="true" [(ngModel)]="yearDateFilter" view="month" dateFormat="yy" [yearNavigator]="true" yearRange="2000:2030" ></p-calendar>
                <div style="height: 10px"></div>
                <div class="row justify-content-center year-picker-done-btn year-filter-btn" (click)="onSelectYear(dateEl)"><div class="label">{{'DASHBOARD.DONE' | translate}}</div></div>
            </div>
        </ng-template>

        <ng-template #monthPicker>
            <p-calendar #month [(ngModel)]="monthDateFilter" view="month" dateFormat="yy/mm" [yearNavigator]="true" yearRange="2000:2030" ></p-calendar>
            <div style="height: 10px"></div>
            <div class="row justify-content-center year-picker-done-btn" (click)="onSelectMonth(true)"><div class="label">{{'DASHBOARD.DONE' | translate}}</div></div>
        </ng-template>

        <ng-template #weekPicker>
            <p-calendar #Week [(ngModel)]="weekDateFilter" dateFormat="yy/mm/dd"></p-calendar>
            <div style="height: 10px"></div>
            <div class="row justify-content-center year-picker-done-btn" (click)="onSelectMonth(false)"><div class="label">{{'DASHBOARD.DONE' | translate}}</div></div>
        </ng-template>
        <div class="dashboard-content">
            <div *ngIf="!isLoading">
                <div class="dashboard-title d-flex">
                    <div class="flex-1">
                        <span class="main-title">{{'DASHBOARD.TITLE'| translate}}</span>
                    </div>
                    <div style="width: 10px"></div>
                    <app-date-range-picker *ngIf="!isMapViewOnly" (onSelect)="onDateSelected($event)"></app-date-range-picker>
                    <div *ngIf="permissions==='ADMIN' || permissions==='SUPER_ADMIN'" class="customization-button">
                        <app-field-set-form [showLabel]="false">
                            <div class="d-flex align-items-end customization-button-content cursor-pointer" placement="{{currentLang === 'en' ? 'bottom-right' : 'bottom-left'}}" autoClose="outside"  [ngbPopover]="customizeDashboardContentPop">
                                <div><img src="../../assets/icons/dashboard-customization-icon.svg" alt=""></div>
                                <div>{{'DASHBOARD.CUSTOMIZE' | translate}}</div>
                                <div class="customization-caret-down"><i class="fas fa-caret-down"></i></div>
                            </div>
                        </app-field-set-form>

                    </div>
                </div>
            </div>
        </div>
        <div class="main-cards" *ngIf="!isMapViewOnly && permissions !== 'ACCOUNTING_MANAGER'">
            <div class="row">
                <div class="col-md-2 col-xs-2 no-left-padding">
                    <div class="cash-card card1" (click)="openCardsDetails('PENDING_CUSTOMER_CARE_APPROVAL')">
                        <div class="card-title">{{'PENDING_CUSTOMER_CARE_APPROVAL' | translate}}</div>
                        <div class="amount">{{topStatsCards['PENDING_CUSTOMER_CARE_APPROVAL'] ? topStatsCards['PENDING_CUSTOMER_CARE_APPROVAL'].count : 0}}</div>
                    </div>
                </div>
                <div class="col-md-2 col-xs-2 no-left-padding">
                    <div class="cash-card card2" (click)="openCardsDetails('APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER')">
                        <div class="card-title">{{'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER' | translate}}</div>
                        <div class="amount">{{topStatsCards['APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER']? topStatsCards['APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER'].count : 0}}</div>
                    </div>
                </div>
                <div class="col-md-2 col-xs-2 no-left-padding">
                    <div class="cash-card card3" (click)="openCardsDetails('SCANNED_BY_DRIVER_AND_IN_CAR')">
                        <div class="card-title">{{'SCANNED_BY_DRIVER_AND_IN_CAR' | translate}}</div>
                        <div class="amount">{{topStatsCards['SCANNED_BY_DRIVER_AND_IN_CAR'] ? topStatsCards['SCANNED_BY_DRIVER_AND_IN_CAR'].count : 0}}</div>
                    </div>
                </div>
                <div class="col-md-2 col-xs-2 no-left-padding">
                    <div class="cash-card card2" (click)="openCardsDetails('RETURNED_BY_RECIPIENT')">
                        <div class="card-title">{{'RETURNED_BY_RECIPIENT' | translate}}</div>
                        <div class="amount">{{topStatsCards['RETURNED_BY_RECIPIENT'] ? topStatsCards['RETURNED_BY_RECIPIENT'].count : 0}}</div>
                    </div>
                </div>
                <div class="col-md-2 col-xs-2 no-left-padding">
                    <div class="cash-card card2" (click)="openCardsDetails('POSTPONED_DELIVERY')">
                        <div class="card-title">{{'POSTPONED_DELIVERY' | translate}}</div>
                        <div class="amount">{{topStatsCards['POSTPONED_DELIVERY']? topStatsCards['POSTPONED_DELIVERY'].count : 0}}</div>
                    </div>
                </div>
                <div class="col-md-2 col-xs-2 no-left-padding" *ngIf="companyHadStations.indexOf(companyId) === -1">
                    <div class="cash-card card4" (click)="openCardsDetails('COMPLETED')">
                        <div class="card-title">{{'COMPLETED' | translate}}</div>
                        <div class="amount">{{topStatsCards['COMPLETED'] ? topStatsCards['COMPLETED'].count : 0}}</div>
                    </div>
                </div>
                <div class="col-md-2 col-xs-2 no-left-padding" *ngIf="companyHadStations.indexOf(companyId) !== -1">
                    <div class="cash-card card4" (click)="openCardsDetails('SCANNED_BY_HANDLER_AND_UNLOADED')">
                        <div class="card-title">{{'SCANNED_BY_HANDLER_AND_UNLOADED' | translate}}</div>
                        <div class="amount">{{topStatsCards['SCANNED_BY_HANDLER_AND_UNLOADED'] ? topStatsCards['SCANNED_BY_HANDLER_AND_UNLOADED'].count : 0}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="map-section">
            <div class="secondary-tickets-container" *ngIf="!isMapViewOnly && companyHadStations.indexOf(companyId) === -1">
                <div class="vertical-cards-group d-flex">
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('SCANNED_BY_HANDLER_AND_UNLOADED')">
                        <div class="card-title">{{'SCANNED_BY_HANDLER_AND_UNLOADED' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['SCANNED_BY_HANDLER_AND_UNLOADED'] ? topStatsCards['SCANNED_BY_HANDLER_AND_UNLOADED'].count : 0}}</div>
                    </div>
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('BROUGHT')">
                        <div class="card-title">{{'BROUGHT' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['BROUGHT'] ? topStatsCards['BROUGHT'].count : 0}}</div>
                    </div>
                </div>
                <div class="vertical-cards-group d-flex">
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('DELIVERED_TO_RECIPIENT')">
                        <div class="card-title">{{'DELIVERED_TO_RECIPIENT' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['DELIVERED_TO_RECIPIENT'] ? topStatsCards['DELIVERED_TO_RECIPIENT'].count : 0}}</div>
                    </div>
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('SWAPPED')">
                        <div class="card-title">{{'SWAPPED' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['SWAPPED'] ? topStatsCards['SWAPPED'].count : 0 }}</div>
                    </div>
                </div>

                <div class="vertical-cards-group d-flex">
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('TRANSFERRED_OUT')">
                        <div class="card-title">{{'TRANSFERRED_OUT' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['TRANSFERRED_OUT'] ? topStatsCards['TRANSFERRED_OUT'].count : 0}}</div>
                    </div>

                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL')">
                        <div class="card-title">{{'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'] ? topStatsCards['ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'].count : 0}}</div>
                    </div>
                </div>
                <div class="separator hidden-sm-down">&nbsp;</div>
                <div class="vertical-cards-group d-flex">
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP')">
                        <div class="card-title">{{'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'] ? topStatsCards['ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'].count : 0}}</div>
                    </div>
                    <div class="vertical-card cursor-pointer" (click)="openCardsDetails('REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT')">
                        <div class="card-title">{{'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT' | translate}}</div>
                        <div class="vertical-card-amount">{{topStatsCards['REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'] ? topStatsCards['REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'].count : 0}}</div>
                    </div>
                </div>
            </div >

            <div class="no-padding map">
                <div class="map-header-title">{{'DASHBOARD.MAP_TITLE' | translate}}</div>

                <div class="map-drivers-filter">
                    <div class="online-status">
                        <div class="cursor-pointer"
                             (click)="setDriversStatus('ONLINE')"
                             [ngClass]="{'active-driver-status': selectedDriversStatus === 'ONLINE'}">{{'DASHBOARD.DRIVERS_STATUS_LIST.ONLINE' | translate}} <span *ngIf="onlineDrivers"> ({{onlineDrivers}})</span></div>
                        <div class="cursor-pointer"
                             (click)="setDriversStatus('OFFLINE')"
                             [ngClass]="{'active-driver-status': selectedDriversStatus === 'OFFLINE'}">{{'DASHBOARD.DRIVERS_STATUS_LIST.OFFLINE' | translate}} <span *ngIf="offlineDrivers">({{offlineDrivers}})</span></div>
                        <div class="cursor-pointer"
                             (click)="setDriversStatus('ALL')"
                             [ngClass]="{'active-driver-status': selectedDriversStatus === 'ALL'}">{{'DASHBOARD.DRIVERS_STATUS_LIST.ALL' | translate}} <span *ngIf="onlineDrivers && offlineDrivers">({{onlineDrivers + offlineDrivers}})</span></div>
                    </div>
                    <div class="driver-list-container">
                        <i *ngIf="isGettingDriversList" class="fas fa-circle-notch fa-spin driver-list-loader"></i>
                        <i *ngIf="mapDriverId" (click)="resetDriversFilter()" class="fa fa-times clear-drivers-filter cursor-pointer"></i>
                        <p-dropdown [options]="drivers" [(ngModel)]="mapDriverId"
                                    [disabled]="isGettingDriversList"
                                    filter="true"
                                    placeholder="{{'DASHBOARD.SELECT_DRIVER' | translate}}"
                                    (onChange)="onMapDriverSelected()" [style]="{'min-width':'175px'}">
                            <ng-template let-group pTemplate="group">
                                <span class="group-margin">{{group.label}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>

                </div>
                <div id="map">
                </div>
            </div>
            <div *ngIf="!isMapViewOnly && isLoadingDriversList" style="height: 100%!important; min-height: 54rem; overflow-y: scroll" class="col-lg-3 col-md-3 col-sm-12 col-xs-12  no-padding driver-list">
                <div style="background-color: #D0E7EE" class="col-12 text-center dashboard-loader">
                    <span class="loader drivers-list-loader"><i class="fas fa-spinner fa-spin"></i></span>
                </div>
            </div>
            <div *ngIf="!isMapViewOnly && !isLoadingDriversList" style="" class="no-padding driver-list">
                <div>
                    <app-drivers-list-component></app-drivers-list-component>
                </div>
            </div>
        </div>
        <div *ngIf="!isMapViewOnly && isShowIncome && (permissions==='ADMIN' || permissions==='SUPER_ADMIN')" class="main-chart" id="Income_Average">
            <div class="loading-section" *ngIf="isLoadingGetIncome">
                <span><i class="fas fa-spinner fa-pulse"></i></span>
            </div>
            <div class="diagram-section stat-1">
                <div class="row customer-chart-label">
                    <span class="customer-char-title" [ngClass]="{'customer-char-title-en' : currentLang === 'en'}">{{'DASHBOARD.INCOME_AVERAGE' | translate}}</span>
                    <div class="excel-button"
                         (click)="openExcel('INCOME')">
                        <i class="fas fa-file-excel"></i>
                        {{"EXCEL" | translate}}
                    </div>
                </div>
                <div class="row period-date-filter" style="background-color: #FFFFFF;">
                    <div class="top-chart-filters">
                        <div class="dashboard-multiselect-filter" [ngClass]="{'dashboard-multiselect-filter-en': currentLang === 'en'}">
                            <span *ngIf="selectedCustomers.length" class="multiSelect-filter-on-select-label">{{selectedCustomers.length}} {{'GENERAL.ITEMS' | translate}}</span>
                            <i *ngIf="!selectedCustomers.length" class="fas fa-caret-down multiSelect-filter-caret-down-on-unselected"></i>
                            <i *ngIf="selectedCustomers.length" class="fas fa-caret-down multiSelect-filter-caret-down"></i>
                            <ng-multiselect-dropdown
                                #customersEl
                                placeholder="{{'ADMINISTRATION.CUSTOMER_LIST_DROPDOWN_PLACEHOLDER' | translate }}"
                                (click)="onClickCustomersList()"
                                [settings]="multiSelectFilterSettings"
                                [data]="customersList"
                                [(ngModel)]="selectedCustomers"
                                (onSelect)="onSelectGetIncomeCustomer()"
                                (onDeSelect)="onSelectGetIncomeCustomer()"
                                (onSelectAll)="onSelectGetIncomeCustomer(true)"
                                (onDeSelectAll)="onSelectGetIncomeCustomer()"
                                (onFilterChange)="filterCustomers(customersEl)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="hidden-space hidden-sm-down"></div>
                    <div class="chart-filter">
                        <div [ngClass]="{'active-period-filter': getIncomePeriodFilter === 'MONTHLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('MONTHLY', 'INCOME')"><span>{{ 'DASHBOARD.MONTHLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': getIncomePeriodFilter === 'YEARLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('YEARLY', 'INCOME')"><span>{{ 'DASHBOARD.YEARLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': getIncomePeriodFilter === 'WEEKLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('WEEKLY', 'INCOME')"><span>{{ 'DASHBOARD.WEEKLY' | translate }}</span></div>
                        <div class="splitter hidden-sm-down"></div>
                        <div *ngIf="getIncomePeriodFilter === 'YEARLY'" class="year-dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('INCOME'); syncCurrentPickerWithNgModel('YEAR', getIncomeDateFilter)" #getIncomePopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="getIncomeDateFilterOverLay">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(getIncomeDateFilter, 'YEAR')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="getIncomePeriodFilter === 'MONTHLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('INCOME'); syncCurrentPickerWithNgModel('MONTH', getIncomeDateFilter)" #getIncomePopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="monthPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(getIncomeDateFilter, 'MONTH')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="getIncomePeriodFilter === 'WEEKLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('INCOME'); syncCurrentPickerWithNgModel('WEEK', getIncomeDateFilter)" #getIncomePopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="weekPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(getIncomeDateFilter, 'WEEK')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="get-income-chart" *ngIf="!isLoadingGetIncome">
                    <google-chart [type]="'ColumnChart'"
                                  [data]="getIncomeChartData"
                                  [columns]="getIncomeChartColumns"
                                  [options]="getIncomeChartOptions"
                                  (ready)="attachChartObserver('Income_Average')"
                                  [width]="getChartWidth('get-income-chart', isShowIncome)"
                                  [height]="getGetIncomeChartHeight('get-income-left-col-height', isShowIncome)">
                    </google-chart>
                </div>
            </div>

            <div class="statistics-section stat-1" id="get-income-left-col-height" style="background-color: #FEF8EE">
                <div><app-image-with-label-view-component style="width: 100%" [isDriver]="false" [list]="listOfBestCustomers" [headerLabel]="'DASHBOARD.HIGHEST_INCOME_FROM_STORES'"></app-image-with-label-view-component></div>
                <hr style="width: 80%">
                <div class="row">
                    <div class="main" style="margin-top: 20px">
                        <div>{{'DASHBOARD.THE_HIGHEST_PROFIT_RATE' | translate}}</div>
                        <div class="amount" [ngClass]="{'amount-en': currentLang === 'en'}">
                            {{highestProfitRate}}
                            <span class="currency" *ngIf="currency !== 'NIS'">{{currency}}</span>
                            <span class="currency" *ngIf="currency === 'NIS'"><i class="fas fa-shekel-sign"></i></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="main">
                        <div>{{'DASHBOARD.PROFIT_PERCENTAGE_RATE' | translate}}</div>
                        <div class="amount" [ngClass]="{'amount-en': currentLang === 'en'}">
                            {{profitPercentageRate}}
                            <span class="currency" *ngIf="currency !== 'NIS'">{{currency}}</span>
                            <span class="currency" *ngIf="currency === 'NIS'"><i class="fas fa-shekel-sign"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isMapViewOnly && isShowDeliveredToAllPackagesRatio && (permissions==='ADMIN' || permissions==='SUPER_ADMIN')" class="main-chart" id="Delivered_Ratio" style="margin-bottom: 20px!important;">
            <div class="loading-section" *ngIf="isLoadingDeliveredToAddedPackages">
                <span><i class="fas fa-spinner fa-pulse"></i></span>
            </div>
            <div class="diagram-section stat-2">
                <div class="row customer-chart-label">
                    <span class="customer-char-title" [ngClass]="{'customer-char-title-en': currentLang === 'en'}">{{'DASHBOARD.RATE_OF_ADDED_TO_DELIVERED_PACKAGES' | translate  | packageTitle}}</span>
                    <div class="excel-button"
                         (click)="openExcel('DELIVERED_TO_ALL_PACKAGES_RATIO')">
                        <i class="fas fa-file-excel"></i>
                        {{"EXCEL" | translate}}
                    </div>
                </div>
                <div class="row" style="background-color: #FFFFFF;">
                    <div class="top-chart-filters">
                        <div class="dashboard-multiselect-filter" [ngClass]="{'dashboard-multiselect-filter-en': currentLang === 'en'}">
                            <span *ngIf="selectedDeliveredToAddedCustomers.length" class="multiSelect-filter-on-select-label">{{selectedDeliveredToAddedCustomers.length}} {{'GENERAL.ITEMS' | translate}}</span>
                            <i *ngIf="selectedDeliveredToAddedCustomers.length" class="fas fa-caret-down multiSelect-filter-caret-down"></i>
                            <i *ngIf="!selectedDeliveredToAddedCustomers.length" class="fas fa-caret-down multiSelect-filter-caret-down-on-unselected"></i>
                            <ng-multiselect-dropdown
                                #customers
                                placeholder="{{'ADMINISTRATION.CUSTOMER_LIST_DROPDOWN_PLACEHOLDER' | translate }}"
                                (click)="onClickCustomersList()"
                                [settings]="multiSelectFilterSettings"
                                [data]="customersList"
                                [(ngModel)]="selectedDeliveredToAddedCustomers"
                                (onSelect)="onSelectDeliveredToAddedCustomer()"
                                (onDeSelect)="onSelectDeliveredToAddedCustomer()"
                                (onSelectAll)="onSelectDeliveredToAddedCustomer(true)"
                                (onDeSelectAll)="onSelectDeliveredToAddedCustomer()"
                                (onFilterChange)="filterCustomers(customers)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="hidden-space"></div>
                    <div class="chart-filter">
                        <div [ngClass]="{'active-period-filter': deliveredToAllPackagesRatioPeriodFilter === 'MONTHLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('MONTHLY', 'DELIVERED_TO_ALL_PACKAGES_RATIO')"><span>{{ 'DASHBOARD.MONTHLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': deliveredToAllPackagesRatioPeriodFilter === 'YEARLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('YEARLY', 'DELIVERED_TO_ALL_PACKAGES_RATIO')"><span>{{ 'DASHBOARD.YEARLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': deliveredToAllPackagesRatioPeriodFilter === 'WEEKLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('WEEKLY', 'DELIVERED_TO_ALL_PACKAGES_RATIO')"><span>{{ 'DASHBOARD.WEEKLY' | translate }}</span></div>
                        <div class="splitter hidden-sm-down"></div>
                        <div *ngIf="deliveredToAllPackagesRatioPeriodFilter === 'YEARLY'" class="year-dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('DELIVERED_TO_ALL_PACKAGES_RATIO'); syncCurrentPickerWithNgModel('YEAR', deliveredToAllPackageRatioDateFilter)" #deliveredToAllPackagesDatePopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="getIncomeDateFilterOverLay">
                                <img src="../../assets/icons/dashboard/calendar.svg">
                                {{transformDate(deliveredToAllPackageRatioDateFilter, 'YEAR')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="deliveredToAllPackagesRatioPeriodFilter === 'MONTHLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('DELIVERED_TO_ALL_PACKAGES_RATIO'); syncCurrentPickerWithNgModel('MONTH', deliveredToAllPackageRatioDateFilter)" #deliveredToAllPackagesDatePopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="monthPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg">
                                {{transformDate(deliveredToAllPackageRatioDateFilter, 'MONTH')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="deliveredToAllPackagesRatioPeriodFilter === 'WEEKLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('DELIVERED_TO_ALL_PACKAGES_RATIO'); syncCurrentPickerWithNgModel('WEEK', deliveredToAllPackageRatioDateFilter)" #deliveredToAllPackagesDatePopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="weekPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg">
                                {{transformDate(deliveredToAllPackageRatioDateFilter, 'WEEK')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="delivered-to-all-packages-chart" *ngIf="!isLoadingDeliveredToAddedPackages">
                    <google-chart [style]="{width: '100%'}"
                                  [type]="'ColumnChart'"
                                  [data]="deliveredToAllPackageRatioChartData"
                                  [columns]="deliveredToAllPackageColumns"
                                  [options]="deliveredToAllPackagesChartOptions"
                                  (ready)="attachChartObserver('Delivered_Ratio')"
                                  [width]="getChartWidth('delivered-to-all-packages-chart', isShowDeliveredToAllPackagesRatio)"
                                  [height]="getChartHeight()">
                    </google-chart>
                </div>
            </div>
            <div class="statistics-section stat-2">
                <div *ngFor="let month of deliveredPercentageToAddedPkgs" class="month-rate row">
                    <div class="month-name"><label style="font-size: 20px!important;">{{month.monthName}}</label></div>
                    <div class="arrow-sign"><span></span></div>
                    <div class="rate"><label>{{month.percentage}}%</label></div>
                </div>
            </div>
        </div>
        <div *ngIf="!isMapViewOnly && isShowShippingPerCity && (permissions==='ADMIN' || permissions==='SUPER_ADMIN')" class="main-chart" id="Shipping_Per_City">
            <div class="loading-section" *ngIf="isLoadingShippingPerCity">
                <span><i class="fas fa-spinner fa-pulse"></i></span>
            </div>
            <div class="diagram-section">
                <div class="row customer-chart-label">
                    <span class="customer-char-title" [ngClass]="{'customer-char-title-en': currentLang === 'en'}">{{'DASHBOARD.SHIPPING_PER_CITY' |  translate}}</span>
                    <div class="excel-button"
                         (click)="openExcel('SHIPPING_PER_CITY')">
                        <i class="fas fa-file-excel"></i>
                        {{"EXCEL" | translate}}
                    </div>
                </div>
                <div class="row" style="background-color: #FFFFFF;">
                    <div class="top-chart-filters">
                        <div class="dashboard-multiselect-filter" [ngClass]="{'dashboard-multiselect-filter-en': currentLang === 'en'}">
                            <span *ngIf="selectedCities.length" class="multiSelect-filter-on-select-label">{{selectedCities.length}} {{'GENERAL.ITEMS' | translate}}</span>
                            <i *ngIf="selectedCities.length" class="fas fa-caret-down multiSelect-filter-caret-down"></i>
                            <i *ngIf="!selectedCities.length" class="fas fa-caret-down multiSelect-filter-caret-down-on-unselected"></i>
                            <ng-multiselect-dropdown
                                #citiesMultiSelectEl
                                placeholder="{{'DASHBOARD.SELECT_CITY' | translate}}"
                                (click)="onClickCitiesMultiSelect()"
                                [settings]="multiSelectFilterSettings"
                                [data]="citiesList"
                                [(ngModel)]="selectedCities"
                                (onSelect)="onSelectCities()"
                                (onDeSelect)="onSelectCities()"
                                (onSelectAll)="onSelectCities(true)"
                                (onDeSelectAll)="onSelectCities()"
                                (onFilterChange)="filterCities(citiesMultiSelectEl)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="hidden-space"></div>
                    <div class="chart-filter">
                        <div [ngClass]="{'active-period-filter': shippingPerCityPeriodFilter === 'MONTHLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('MONTHLY', 'SHIPPING_PER_CITY')"><span>{{ 'DASHBOARD.MONTHLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': shippingPerCityPeriodFilter === 'YEARLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('YEARLY', 'SHIPPING_PER_CITY')"><span>{{ 'DASHBOARD.YEARLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': shippingPerCityPeriodFilter === 'WEEKLY'}" class="period-filter cursor-pointer" (click)="setPeriodFilter('WEEKLY', 'SHIPPING_PER_CITY')"><span>{{ 'DASHBOARD.WEEKLY' | translate }}</span></div>
                        <div class="splitter hidden-sm-down"></div>
                        <div *ngIf="shippingPerCityPeriodFilter === 'YEARLY'" class="year-dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('SHIPPING_PER_CITY'); syncCurrentPickerWithNgModel('YEAR', shippingPerCityDateFilter)" #shippingPerCityPopOver="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="getIncomeDateFilterOverLay">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(shippingPerCityDateFilter, 'YEAR')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                            <!--                    <p-calendar #aaaa [(ngModel)]="shippingPerCityDateFilter"   (onYearChange)="sayHi(aaaa)" (onSelect)="onSelectDateFilter('SHIPPING_PER_CITY')" showButtonBar="true" view="month" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="2000:2030" ></p-calendar>-->
                        </div>
                        <div *ngIf="shippingPerCityPeriodFilter === 'MONTHLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('SHIPPING_PER_CITY'); syncCurrentPickerWithNgModel('MONTH', shippingPerCityDateFilter)" #shippingPerCity="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="monthPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(shippingPerCityDateFilter, 'MONTH')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="shippingPerCityPeriodFilter === 'WEEKLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('SHIPPING_PER_CITY'); syncCurrentPickerWithNgModel('WEEK', shippingPerCityDateFilter)" #shippingPerCity="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="weekPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(shippingPerCityDateFilter, 'WEEK')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="shipping-per-city-chart" *ngIf="!isLoadingShippingPerCity">
                    <google-chart [style]="{width: '100%'}"
                                  [type]="'BarChart'"
                                  [data]="shippingPerCityChartData"
                                  [columns]="shippingPerCityColumns"
                                  [options]="shippingPerCityChartOptions"
                                  (ready)="attachChartObserver('Shipping_Per_City')"
                                  [width]="getChartWidth('shipping-per-city-chart', isShowShippingPerCity)"
                                  [height]="getChartHeight()">
                    </google-chart>
                </div>
            </div>
<!--            <div *ngIf="isLoadingShippingVillage" class="statistics-section based-on-villages">-->
<!--                <div class="col-12 text-center dashboard-loader">-->
<!--                    <span class="loader list-of-best-customers-loader"><i class="fas fa-spinner fa-spin"></i></span>-->
<!--                </div>-->
<!--            </div>-->
            <div class="statistics-section based-on-villages">
                <div class="row based-on-villages-label">
                    <div class="header-label">{{'DASHBOARD.SHIPPING_PER_VILLAGE' | translate | addressSystem}}</div>
                </div>
                <div *ngIf="!shippingPerVillages.length && !isLoadingShippingPerCity">
                    <div class="row no-data-available justify-content-center">{{'ALERTS.NO_DATA_AVAILABLE' | translate}}</div>
                </div>

                <div *ngFor="let village of shippingPerVillages; let i = index" class="row village-row village-{{(i+1) % 3}}">
                    <div class="col-sm-7 village-name">{{village.lable}}</div>
                    <div class="col-sm-5 village-packages-number"><span class="number">{{village.cost + '  '}}</span> <span class="package-label">{{'DASHBOARD.PACKAGE' | translate}}</span></div>
                </div>
            </div>
        </div>
        <div *ngIf="!isMapViewOnly && isShowDriversEvaluation && (permissions==='ADMIN' || permissions==='SUPER_ADMIN')" class="main-chart" id="Best_Drivers">
            <div class="loading-section" *ngIf="isLoadingListOfBestDrivers">
                <span><i class="fas fa-spinner fa-pulse"></i></span>
            </div>
            <div class="diagram-section">
                <div class="row customer-chart-label">
                    <span class="customer-char-title" [ngClass]="{'customer-char-title-en': currentLang === 'en'}">{{'DASHBOARD.DRIVERS_EVALUATION' | translate}}</span>
                    <div class="excel-button"
                         (click)="openExcel('DRIVERS_EVALUATION')">
                        <i class="fas fa-file-excel"></i>
                        {{"EXCEL" | translate}}
                    </div>
                </div>
                <div class="row" style="background-color: #FFFFFF;">
                    <div class="top-chart-filters">
                        <div class="dashboard-multiselect-filter" [ngClass]="{'dashboard-multiselect-filter-en': currentLang === 'en'}">
                            <span *ngIf="selectedDriversMultiSelect.length" class="multiSelect-filter-on-select-label">{{selectedDriversMultiSelect.length}} {{'GENERAL.ITEMS' | translate}}</span>
                            <i *ngIf="selectedDriversMultiSelect.length" class="fas fa-caret-down multiSelect-filter-caret-down"></i>
                            <i *ngIf="!selectedDriversMultiSelect.length" class="fas fa-caret-down multiSelect-filter-caret-down-on-unselected"></i>
                            <ng-multiselect-dropdown
                                #driversMultiSelectEl
                                placeholder="{{'DASHBOARD.SELECT_DRIVER' | translate}}"
                                (click)="onClickDriversMultiSelect()"
                                [settings]="multiSelectFilterSettings"
                                [data]="driversMultiSelectList"
                                [(ngModel)]="selectedDriversMultiSelect"
                                (onSelect)="onSelectDrivers()"
                                (onDeSelect)="onSelectDrivers()"
                                (onSelectAll)="onSelectDrivers(true)"
                                (onDeSelectAll)="onSelectDrivers()"
                                (onFilterChange)="filterDrivers(driversMultiSelectEl)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="hidden-space"></div>
                    <div class="chart-filter">
                        <div [ngClass]="{'active-period-filter': driversEvaluationPeriodFilter === 'MONTHLY'}" class="period-filter cursor-pointer" (click)="syncCurrentPickerWithNgModel('MONTH', listOfBestDriverDateFilter); setPeriodFilter('MONTHLY', 'DRIVERS_EVALUATION')"><span>{{ 'DASHBOARD.MONTHLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': driversEvaluationPeriodFilter === 'YEARLY'}" class="period-filter cursor-pointer" (click)="syncCurrentPickerWithNgModel('YEAR', listOfBestDriverDateFilter); setPeriodFilter('YEARLY', 'DRIVERS_EVALUATION')"><span>{{ 'DASHBOARD.YEARLY' | translate }}</span></div>
                        <div [ngClass]="{'active-period-filter': driversEvaluationPeriodFilter === 'WEEKLY'}" class="period-filter cursor-pointer" (click)="syncCurrentPickerWithNgModel('WEEK', listOfBestDriverDateFilter); setPeriodFilter('WEEKLY', 'DRIVERS_EVALUATION')"><span>{{ 'DASHBOARD.WEEKLY' | translate }}</span></div>
                        <div class="splitter"></div>
                        <div *ngIf="driversEvaluationPeriodFilter === 'YEARLY'" class="year-dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('DRIVERS_EVALUATION'); syncCurrentPickerWithNgModel('YEAR', listOfBestDriverDateFilter)" #driversEvaluation="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="getIncomeDateFilterOverLay">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(listOfBestDriverDateFilter, 'YEAR')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="driversEvaluationPeriodFilter === 'MONTHLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('DRIVERS_EVALUATION'); syncCurrentPickerWithNgModel('MONTH', listOfBestDriverDateFilter)" #driversEvaluation="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="monthPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(listOfBestDriverDateFilter, 'MONTH')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                        <div *ngIf="driversEvaluationPeriodFilter === 'WEEKLY'" class="income-month-filter dashboard-date-filter">
                            <div class="row year-picker cursor-pointer" (click)="setCurrentPicker('DRIVERS_EVALUATION'); syncCurrentPickerWithNgModel('WEEK', listOfBestDriverDateFilter)" #driversEvaluation="ngbPopover" placement="bottom-left" autoClose="outside" [ngbPopover]="weekPicker">
                                <img src="../../assets/icons/dashboard/calendar.svg" alt="">
                                {{transformDate(listOfBestDriverDateFilter, 'WEEK')}}
                                <i class="fas fa-caret-down year-picker-caret-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="driver-evaluation-chart" *ngIf="!isLoadingListOfBestDrivers">
                    <google-chart [type]="'BarChart'"
                                  [data]="driversEvaluationChartData"
                                  [columns]="driversEvaluationColumn"
                                  [options]="driversEvaluationChartOptions"
                                  (ready)="attachChartObserver('Best_Drivers')"
                                  [width]="getChartWidth('driver-evaluation-chart', isShowDriversEvaluation)"
                                  [height]="getChartHeight()">
                    </google-chart>
                </div>
            </div>
            <div  class="statistics-section driver-evaluation-list" style="background-color: #F9F0F1">
                <div><app-image-with-label-view-component [isDriver]="true" [list]="listOfBestDrivers" [headerLabel]="'DASHBOARD.BEST_DRIVERS'" [isLoading]="isLoadingListOfBestDrivers"></app-image-with-label-view-component></div>
            </div>
        </div>
</div>
 </div>
