<p-toast></p-toast>

<div class="distributor-packages-contents-report h-100">
    <div class="header d-flex justify-content-between">
        <div class="page-header-title">
            <div class="title">
                {{'DISTRIBUTOR_REPORTS.DAMAGED_PACKAGES_REPORT' | translate}}
            </div>
            <div class="total-number-badge">{{totalRecordsNo}}</div>
        </div>
        <div class="filters d-flex justify-content-between align-items-center flex-wrap">
            <div class="excel-button"
                 [ngClass]="{'disabled-btn disabled': isDownloading}"
                 (click)="exportExcel()">
                <i class="fas fa-file-excel"></i>
                {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
            </div>
            <div class="pdf-button"
                 [ngClass]="{'disabled-btn disabled': isDownloading}"
                 (click)="printReport('PDF')">
                <i class="fas fa-file-pdf"></i>
                {{"ACTIONS.EXPORT_TO_PDF" | translate}}
            </div>
            <div class="customer">
                <app-users-list-dropdown-component
                    #customerFilter
                    [placeHolder]="'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME'"
                    [showResetButton]="true"
                    (userChanged)="onSelectCustomer($event)"
                    [hideDropdownWhenSelected]="true"
                ></app-users-list-dropdown-component>
            </div>
<!--            <div class="date date-filter">-->
<!--                <div class="date-range">-->
<!--                        <span (click)="datepicker.click()">-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </span>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range" #datepicker daterangepicker-->
<!--                           [options]="dateRangPickerOptions" (selected)="onDateSelected($event)"/>-->
<!--                    <img class="arrow-down" src="../../../assets/icons/arrow-down.png">-->
<!--                </div>-->
<!--            </div>-->
            <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

            <div class="search main-search-filter">
                <i class="fas fa-search"></i>
                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." appInputDebounced
                       (inputDebounced)="onFilter()" [(ngModel)]="search">
            </div>
        </div>
    </div>
    <div class="main-table report-table">
        <p-table appPTableRtlFix dataKey="id" #table [value]="reportData"
                 paginatorDropdownAppendTo="body"
                 styleClass="package-contents-report-table flex-table"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecordsNo" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>

            <ng-template pTemplate="header">
                <th style="width: 200px;">{{'DISTRIBUTOR_REPORTS.TABLE.BARCODE' | translate}}</th>
                <th>{{'DISTRIBUTOR_REPORTS.TABLE.CUSTOMER_NAME' | translate}}</th>
                <th>
                    <ng-container>
                    <div class="flex-2d-center status-filter-container">
                        <div class="header-filter w-100">
                                    <span class="shipment-status-title"
                                          [ngClass]="{'active-status-title': selectedReturnedTypeStatus !== 'ALL'}">{{'DISTRIBUTOR_REPORTS.TABLE.RETURNED_REASON' | translate}}</span>
                            <div class="manage-shipment-status-filter"
                                 [ngClass]="{'active-shipment-status-filter': selectedReturnedTypeStatus !== 'ALL'}"
                                 style="z-index: 3 !important;">
                                <p-dropdown styleClass="shipment-status-dropdown"
                                            [(ngModel)]="selectedReturnedTypeStatus" [options]="returnedTypesList"
                                            [appendTo]="'body'"
                                            filter="true" (onChange)="onStatusChanged()">
                                    <ng-template let-status
                                                 pTemplate="selectedItem">{{ status.label | translate }}</ng-template>
                                    <ng-template let-status
                                                 pTemplate="item">{{ status.label | translate }}</ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </ng-container>
                </th>
                <th>{{'DISTRIBUTOR_REPORTS.TABLE.PACKAGE_TYPE' | translate}}</th>
                <th>{{'DISTRIBUTOR_REPORTS.TABLE.CONTENT_TYPE' | translate}}</th>
                <th>{{'DISTRIBUTOR_REPORTS.TABLE.SIZE' | translate}}</th>
                <th>{{'DISTRIBUTOR_REPORTS.TABLE.QUANTITY' | translate}}</th>
                <th class="status-dropdown status-th">
                    <ng-container>
                        <div class="flex-2d-center status-filter-container">
                            <div class="header-filter w-100">
                                    <span class="shipment-status-title"
                                          [ngClass]="{'active-status-title': selectedShipmentStatus !== 'ALL'}">{{"SHIPMENT.TABLE.STATUS" | translate}}</span>
                                <div class="manage-shipment-status-filter"
                                     [ngClass]="{'active-shipment-status-filter': selectedShipmentStatus !== 'ALL'}"
                                     style="z-index: 3 !important;">
                                    <p-dropdown styleClass="shipment-status-dropdown"
                                                [(ngModel)]="selectedShipmentStatus" [options]="shipmentStatusList"
                                                [appendTo]="'body'"
                                                filter="true" (onChange)="onStatusChanged()">
                                        <ng-template let-status
                                                     pTemplate="selectedItem">{{ status.label | translate }}</ng-template>
                                        <ng-template let-status
                                                     pTemplate="item">{{ status.label | translate }}</ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                            <div *ngIf="selectedShipmentStatus === 'SCANNED_BY_DRIVER_AND_IN_CAR'"
                                 class="is-closed-packages-filter d-flex justify-content-center">
                                <div class="label flex-2d-center">{{'FAILED' | translate}}</div>
                                <div class="filter flex-2d-center">
                                    <p-inputSwitch [(ngModel)]="isFailed"
                                                   (onChange)="initShipments(true)"></p-inputSwitch>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </th>
                <th>{{'DISTRIBUTOR_REPORTS.TABLE.CREATED_DATE' | translate}}</th>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr (click)="openContentsDialog(report.packageId)">
                    <td class="barcode-number default">
                        <div class="d-flex" style="gap: 5px; justify-content:center;">
                            <span>#{{report.barcode}}</span>
                            <img src="../../../assets/icons/track_package/copy.svg" alt=""
                                 (click)="copyMessage($event,null, report.barcode)" data-toggle="tooltip"
                                 data-placement="top" title="Copy" class="copy_icon"/>
                        </div>
                    </td>
                    <td>{{report.customerName}}</td>
                    <td>{{report.type | translate}}</td>
                    <td>
                        {{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.BOXES' | translate}} : {{report.boxes}}<br>
                        <ng-container *ngIf="report.type === 'EMPTY'">
                            {{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.PALLETS' | translate}} : {{report.pallets}}
                        </ng-container>
                    </td>
                    <td>{{report.contentItemName}}</td>
                    <td>{{report.size | translate}}</td>
                    <td>{{report.calculatedQuantity}}</td>

                    <td>
                        <app-shipment-status [isEditEnabled]="false" [shipment]="report">
                        </app-shipment-status>
                    </td>

                    <td>{{report.createdDate | date:'dd/MM/yyyy'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
