import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShipmentsService} from '../services/shipments.service';
import {SharedService} from '../../shared/services/shared-service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-shipments-validation-results',
    templateUrl: './shipments-validation-results.component.html',
    styleUrls: ['./shipments-validation-results.component.scss']
})
export class ShipmentsValidationResultsComponent implements OnInit {
    results: any[] = [];
    label = '';
    currentLang;
    constructor(private activeModal: NgbActiveModal,
                private shipmentService: ShipmentsService,
                private sharedService: SharedService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
    }

    copyMessage($event, barcode) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.COPIED');
    }

     closeModal() {
        this.activeModal.close();
    }
}
