import {FormGroup, ValidationErrors} from '@angular/forms';

export function atLeastOneOf(...fields: string[]) {
    return (fg: FormGroup): ValidationErrors | null => {
        return fields.some(fieldName => {
            const fieldControl = fg.get(fieldName);
            if (fieldControl && typeof fieldControl.value === 'string') {
                return fieldControl.value.length > 0;
            }
            return fieldControl && fieldControl.value !== null && fieldControl.value !== undefined;
        })
            ? null
            : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
    };
}
