export function setBarcodeReader(callbackFunction) {
    let newUPC = '';
    document.addEventListener('keydown', function (e) {
        const textInput = e.key || String.fromCharCode(e.keyCode);
        const a: any = e.target;
        const targetName = a.localName;
        if (targetName !== 'input' &&
            (textInput === 'Enter' || textInput === 'Tab' || textInput.match(/^[a-zA-Z0-9]*[/]*[-]?[a-zA-Z0-9]*$/))) {
            if (textInput === 'Enter' || textInput === 'Tab') {
                if (newUPC.startsWith('|')) {
                    newUPC = newUPC.substring(1);
                }
                // newUPC = newUPC.replace(/\D/g, '');
                callbackFunction(newUPC);
                newUPC = '';
            }
            if (textInput && textInput.length === 1) {
                newUPC = newUPC + textInput;
            }
        }
    });
}

export function createQueryParams(filterParams) {
    if (!filterParams) {
        return '';
    }
    const filterKeyValues = Object.entries(filterParams);
    let result = '';
    filterKeyValues.forEach((keyValuePair, index) => {
        if (keyValuePair[1]) {
            if (result) {
                result += `&${keyValuePair[0]}=${keyValuePair[1]}`;
            } else {
                result += `?${keyValuePair[0]}=${keyValuePair[1]}`;
            }
        }

    });
    return result;
}
