import {Injectable} from '@angular/core';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ZonesService {

    constructor(private httpClient: HttpClient) {
    }

    public getZones(url, params, useCache = false) {
        const headers = useCache ? {
            'use-cache': 'true'
        } : {};
        return this.httpClient.get(url, {params, headers});
    }

    public addZone(url, data) {
        return this.httpClient.post(url, data);
    }

    public assignZone(url) {
        return this.httpClient.put(url, {});
    }

    public selectZone(url) {
        return this.httpClient.put(url, {});
    }

    public unassignZone(url, ids) {
        return this.httpClient.put(url, {ids});
    }

    public unassignMultipleZones(url, ids) {
        return this.httpClient.put(url, {ids});
    }

    public deleteRegion(url) {
        return this.httpClient.delete(url);
    }

    public getRegions(isAuth = true, search = '') {
        const url = isAuth ? ADMINISTRATION_CONSTANTS.ZONE_GET_REGIONS : ADMINISTRATION_CONSTANTS.ZONE_GET_ALL_REGIONS;
        const params = <any>{
            pageSize: ADMINISTRATION_CONSTANTS.ZONES_PAGE_SIZE,
            page: 1
        };
        if (search) {
            params['search'] = search;
        }
        return this.httpClient.get(url, {params: params});
    }

    public getCities(regionId, isAuth = true, search = '') {
        let url = isAuth ? ADMINISTRATION_CONSTANTS.ZONE_GET_REGION_CITIES : ADMINISTRATION_CONSTANTS.ZONE_GET_REGION_ALL_CITIES;
        url = url.replace('{regionId}', regionId);
        const params = <any>{
            pageSize: ADMINISTRATION_CONSTANTS.ZONES_PAGE_SIZE,
            page: 1
        };
        if (search) {
            params['search'] = search;
        }
        return this.httpClient.get(url, {params: params});
    }

    public getVillages(cityId, isAuth = true, allVillages = false, search = '') {
        let url;
        const params = <any>{
            pageSize: ADMINISTRATION_CONSTANTS.ZONES_PAGE_SIZE,
            page: 1
        };
        if (!cityId && allVillages) {
            url = ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES;
        } else {
            url = isAuth ? ADMINISTRATION_CONSTANTS.ZONE_GET_CITY_VILLAGES : ADMINISTRATION_CONSTANTS.ZONE_GET_CITY_ALL_VILLAGES;
            url = url.replace('{cityId}', cityId);
        }
        if (search) {
            params['search'] = search;
        }
        return this.httpClient.get(url, {params: params});
    }

    public getCitiesList(params, useCache = false) {
        const headers = useCache ? {
            'use-cache': 'true'
        } : {};
        if (typeof params === 'string') {
            return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_CITIES + params, {headers});
        } else {
            return this.httpClient.get(ADMINISTRATION_CONSTANTS.GET_CITIES, {params, headers});
        }
    }

    public setVillageLocation(villageId, location) {
        let url = ADMINISTRATION_CONSTANTS.VILLAGE_LOCATION;
        url = url.replace('{id}', villageId);

        const params = {
            longitude: location.lng,
            latitude: location.lat
        };

        return this.httpClient.put(url, params);
    }

    public changeVillageIsReceivingPoint(villageId) {
        const url = ADMINISTRATION_CONSTANTS.CHANGE_VILLAGE_IS_RECEIVING_POINT.replace('{villageId}', villageId);
        return this.httpClient.put(url, {});
    }
    public exportExcelVillages() {
        const url = ADMINISTRATION_CONSTANTS.EXPORT_VILLAGES_TO_EXCEL;
        return this.httpClient.get(url, {});
    }
    public getZoneHub(id) {
        const url = ADMINISTRATION_CONSTANTS.GET_ZONE_HUB.replace('{id}', id);
        return this.httpClient.get(url, {});
    }

    public updateVillageHub(hubId, body) {
        const url = ADMINISTRATION_CONSTANTS.UPDATE_ZONE_HUB.replace('{id}', hubId);
        return this.httpClient.put(url, body);
    }

    updateCityPrefix(city, body: { prefix: any, color }) {
        const url = ADMINISTRATION_CONSTANTS.UPDATE_CITY_PREFIX.replace('{cityId}', city.id);
        return this.httpClient.put(url, body);
    }
    addAllVillagesToCity(shippingLineId = null, cityId, selection) {
        let url = ADMINISTRATION_CONSTANTS.ADD_ALL_VILLAGES_TO_CITY_FOR_MANAGE_ZONES.replace('{cityId}', cityId);
        if (shippingLineId) {
            url = ADMINISTRATION_CONSTANTS.ADD_ALL_VILLAGES_TO_CITY.replace('{shippingLineId}', shippingLineId).replace('{cityId}', cityId);
        }
        url += '?select=' + selection;
        return this.httpClient.put(url, {});
    }
    public importCitiesAndVillagesFormExcel(formData, regionId, companyId) {
        let url = ADMINISTRATION_CONSTANTS.IMPORT_FROM_EXCEL.replace('{companyId}', companyId);
        url = url.replace('{regionId}', regionId);
        return this.httpClient.post(url, formData);
    }
}
