import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-horizontal-spinner-component',
    templateUrl: 'horizontal-spinner-component.html',
    styleUrls: ['horizontal-spinner-component.scss']
})
export class HorizontalSpinnerComponent {
    /**
     * Controls the appearance of the spinner.
     */
    @Input() display = false;
}
