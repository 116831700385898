<ng-container>
    <div class="row">
        <div class="col-9 col-sm-6">
            <div class="form-title">
                <i [class]="mainIcon"></i>
                <div class="d-flex justify-content-between">
                    <span> {{title | translate}}</span>
                    <i *ngIf="showIcon" [pTooltip]="pTooltip" [tooltipPosition]="tooltipPosition" [class]="icon" (click)="onIconClick.emit($event)"></i>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<!--<app-add-package-form-item [title]="swapSenderAndReceiver ? 'ADD_SHIPMENTS.RECEIVER_INFORMATION' : 'ADD_SHIPMENTS.SENDER_INFORMATION'"-->
<!--                           [showIcon]="showAddCustomer() && !isLcl"-->
<!--                           [icon]="'fas fa-2x fa-plus-circle add-customer-icon'"-->
<!--                           [pTooltip]="'QUICK_ADD.ADD_CUSTOMER'"-->
<!--                           [tooltipPosition]="'top'"-->
<!--                           [mainIcon]="'fas fa-map-marker-alt'"-->
<!--                           (onIconClick)="addCustomer()">-->
<!--</app-add-package-form-item>-->
