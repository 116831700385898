<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="pricings-management-container">
        <div class="d-flex top-side">
            <div class="header">
                <div class="pricings-management-title d-flex">
                    <span>{{ ('ACCOUNTING.ADDITIONAL_FEES.' + type) | translate }}</span>
                    <span>
                        <div class="total-number-badge mr-2 ml-2">
                             <span>{{ totalRecords }}</span>
                        </div>
                    </span>
                </div>

                <div *ngIf="customerName" class="customer-badge">
                    <span>{{ (typeName | translate) + ': ' + customerName }}</span>
                </div>
            </div>

            <div class="actions">
                <div class="action main-action" (click)="onNewPricing($event)">
                    <div class="icon">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="label">
                        <span>{{ ('ADDITION_FEES.ADD_FEES_'+type) | translate }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="data-table pricings-data-table">
            <div class="table-body-container overflow-auto">
                <p-table [value]="pricing" scrollHeight="auto" dataKey="id" [loading]="isUpdating"
                         styleClass="table-container data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="row-toggler-th"></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content" *ngIf="!isShowNewPricing">
                            <app-no-data-available-component></app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-pricing let-expanded="expanded">
                        <tr>
                            <td>
                                <span class="row-toggler" #rowToggler
                                      [pRowToggler]="pricing" (click)="setExpandedRow(rowToggler)">
                                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : currentLang === 'en' ?
                                        'pi pi-chevron-right' : 'pi pi-chevron-left'"></i>
                                </span>
                            </td>
                            <td >
                                <div class="row-toggler-container">
                                    <p-inplace #originRegionInplace
                                               (onActivate)="onActivateInplace($event, originRegionInplace, pricing.name)">
                                    <span pInplaceDisplay>
                                        {{ pricing.name }}
                                    </span>
                                        <span pInplaceContent>
                                         <input (keydown.escape)="toggleInplace($event, originRegionInplace)"
                                                (keydown.enter)="toggleInplace($event, originRegionInplace, null, 'name', true, true)"
                                                type="text" pInputText [(ngModel)]="displayedInplaceValue">
                                        <span class="inplace-actions">
                                            <i class="fas fa-check" (click)="toggleInplace($event, originRegionInplace, pricing, 'name', true, true)"></i>
                                            <i class="fas fa-times" (click)="toggleInplace($event, originRegionInplace)"></i>
                                        </span>
                                    </span>
                                    </p-inplace>
                                </div>
                            </td>
                            <td  class="card-action cursor-pointer" (click)="showCustomers(pricing)">
                                <span>{{ 'PRICING.ACTIONS.VIEW_CUSTOMERS' | translate }}</span>
                                <i class="fas fa-pencil-alt"></i>
                            </td>
                            <td>
                                <div class="row-actions">
                                    <!--                                    <i class="fas fa-trash" (click)="onDeletePricing(pricing)"></i>-->
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr *ngIf="isShowNewPricing">
                            <td [attr.colspan]="(isReceivingPointEnabled ? 8 : 7)+(calculateCostPerWeight? 2 : 0)">
                                <div class="new-pricing-header">
                                    {{ ('ADDITION_FEES.NEW_PRICING') | translate }}
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="isShowNewPricing" [formGroup]="newPricingForm">
                            <td></td>
                            <td colspan="2">
                                <div class="new-pricing-field-label">
                                    {{ 'ACCOUNTING.ADDITIONAL_FEES.NAME' | translate }}
                                </div>
                                <app-input-field-component
                                    #newPricingOriginRegion
                                    [form]="newPricingForm"
                                    controlName="name"
                                    [isRequired]="true"
                                    controlType="text"
                                >
                                </app-input-field-component>
                            </td>
                            <td>
                                <div class="row-actions">
                                    <i [ngClass]="{'disabled-btn disabled': newPricingForm.invalid}" class="fas fa-save" (click)="onSavePricing()"></i>
                                    <i class="fas fa-times" (click)="onHideNewPricing()"></i>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-pricing>
                        <tr class="service-types-pricings">
                            <td [attr.colspan]="(isReceivingPointEnabled ? 8 : 7)+(calculateCostPerWeight? 2 : 0)">
                                <div>
                                        <div class="sub-table-container">
                                            <p-table [value]=" pricing.additionalPackageFeesDetailsList">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>{{ 'ADDITION_FEES.MIN_COD' | translate }}</th>
                                                        <th>{{ 'ADDITION_FEES.MAX_COD' | translate }}</th>
                                                        <th>{{ 'ADDITION_FEES.TYPE' | translate }}</th>
                                                        <th>{{ 'ADDITION_FEES.VALUE' | translate }}</th>
                                                        <th></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-vehicleTypePricing let-vehicleIndex="rowIndex">
                                                    <tr>
                                                        <td>
                                                            <p-inplace #vehiclePrice
                                                                       (onActivate)="onActivateInplace($event,vehiclePrice,vehicleTypePricing.minCod)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.minCod }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehiclePrice)"
                                                                           [max]="newRangeForm.value.maxCod"
                                                                           (keydown.enter)="toggleInplace($event, vehiclePrice, vehicleTypePricing, 'minCod', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehiclePrice, vehicleTypePricing, 'minCod', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehiclePrice)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td>
                                                            <p-inplace #vehicleReturnedPrice
                                                                       (onActivate)="onActivateInplace($event,vehicleReturnedPrice,vehicleTypePricing.maxCod)">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.maxCod }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input (keydown.escape)="toggleInplace($event, vehicleReturnedPrice)"  [min]="newRangeForm.value.minCod"
                                                                           (keydown.enter)="toggleInplace($event, vehicleReturnedPrice, vehicleTypePricing, 'maxCod', true)"
                                                                           type="number" pInputText [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check" (click)="toggleInplace($event, vehicleReturnedPrice, vehicleTypePricing, 'maxCod', true)"></i>
                                                                        <i class="fas fa-times" (click)="toggleInplace($event, vehicleReturnedPrice)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td>
                                                            <p-inplace #vehicleRejectionBeforeArrivalCost
                                                                       (onActivate)="onActivateInplace($event,vehicleRejectionBeforeArrivalCost,vehicleTypePricing.calculationType)">
                                                                <span pInplaceDisplay>
                                                                    {{ ('ADDITION_FEES.'+vehicleTypePricing.calculationType) | translate }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <p-dropdown
                                                                            (onChange)="handleValidation($event, newRangeForm)"
                                                                            appendTo="body"
                                                                            [(ngModel)]="displayedInplaceValue"
                                                                            [placeholder]="'ACTIONS.SELECT_TYPE' | translate"
                                                                            [options]="feeTypeOptions">

                                                                    </p-dropdown>
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check"
                                                                           (click)="toggleInplace($event, vehicleRejectionBeforeArrivalCost, vehicleTypePricing, 'calculationType', true)"></i>
                                                                        <i class="fas fa-times"
                                                                           (click)="toggleInplace($event, vehicleRejectionBeforeArrivalCost, vehicleTypePricing)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>
                                                        <td>
                                                            <p-inplace #vehicleReceivingPointPrice
                                                                       (onActivate)="onActivateInplace($event,vehicleReceivingPointPrice,vehicleTypePricing.calculationType === 'FIXED' ?  vehicleTypePricing.value : vehicleTypePricing.percentage )">
                                                                <span pInplaceDisplay>
                                                                    {{ vehicleTypePricing.calculationType === 'FIXED' ?  vehicleTypePricing.value : vehicleTypePricing.percentage }}
                                                                </span>
                                                                <span pInplaceContent>
                                                                    <input type="number" (keydown.escape)="toggleInplace($event, vehicleReceivingPointPrice)" [max]="100" step=".01"
                                                                           (keydown.enter)="toggleInplace($event, vehicleReceivingPointPrice, vehicleTypePricing, 'percentage', true)"
                                                                           pInputText
                                                                           [(ngModel)]="displayedInplaceValue">
                                                                    <span class="inplace-actions">
                                                                        <i class="fas fa-check"
                                                                           [ngClass]="{'disabled-btn disabled': vehicleTypePricing.calculationType === 'PERCENTAGE' ? displayedInplaceValue >= 100 : false}"
                                                                           (click)="toggleInplace($event, vehicleReceivingPointPrice, vehicleTypePricing, 'percentage', true)"></i>
                                                                        <i class="fas fa-times"
                                                                           (click)="toggleInplace($event, vehicleReceivingPointPrice, vehicleTypePricing)"></i>
                                                                    </span>
                                                                </span>
                                                            </p-inplace>
                                                        </td>

                                                        <td>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="footer">
                                                    <tr [formGroup]="newRangeForm" *ngIf="showAddNewRange">
                                                        <td>
                                                            <input type="number" pInputText formControlName="minCod" [max]="newRangeForm.value.maxCod">
                                                        </td>
                                                        <td>
                                                            <input type="number" pInputText formControlName="maxCod" [min]="newRangeForm.value.minCod">
                                                        </td>

                                                        <td>
                                                            <p-dropdown
                                                                    appendTo="body"
                                                                    formControlName="calculationType"
                                                                    (onChange)="handleValidation($event, newRangeForm)"
                                                                    [placeholder]="'ACTIONS.SELECT_TYPE' | translate"
                                                                    [options]="feeTypeOptions">
                                                            </p-dropdown>
                                                        </td>
                                                        <td>
                                                            <input type="number" pInputText formControlName="percentage" step=".01">
                                                        </td>
                                                        <td>
                                                            <i class="fas fa-save submit-icon" [ngClass]="{'disabled-btn disabled': newRangeForm.invalid}"
                                                               (click)="onSubmitAddRange(pricing)"></i>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
<!--                                    </p-fieldset>-->
                                </div>
                                <div class="service-types-toggler action-anim" (click)="showAddAdditionalFee($event, pricing)">
                                    {{ 'ADDITION_FEES.NEW_PRICING' | translate }}
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader [type]="1" *ngIf="isLoading"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

