<div class="h-100 d-flex flex-column">
  <div class="top-actions">
    <div class="group-tabs-container">
      <div class="cursor-pointer tab" routerLink="customers-pricing" routerLinkActive="selectedTab">
        {{'PRICING.CUSTOMERS_PRICING_TABLE' | translate}}
      </div>
      <div *ngIf="!isHubsPricing" class="cursor-pointer tab" routerLink="pricing-lists" routerLinkActive="selectedTab">
        {{'PRICING.PRICING_LISTS' | translate}}
      </div>
    </div>
  </div>
  <div class="flex-1 overflow-auto">
    <router-outlet>
    </router-outlet>
  </div>
</div>
