import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EditNotificationTemplateComponent} from '../edit-notification-template/edit-notification-template.component';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CompanyNotificationsService} from '../services/company-notifications.service';
import {UserService} from '../../shared/services/user.service';
import {CompaniesService} from '../../companies/services/companies.service';

@Component({
    selector: 'app-notifications-settings',
    templateUrl: './notifications-settings.component.html',
    styleUrls: ['./notifications-settings.component.scss']
})

export class NotificationsSettingsComponent implements OnInit {

    public spinnerState;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public events = [];
    public isArabic = false;
    public userInfo;
    public selectedTab = '';
    public isSendSmsToReceiversWhenDriverDeliver = false;
    public isSendSmsToSenderWhenDriverDeliver = false;
    public isSendSmsToReceiverstoShareLocation = false;
    public isSendSmsToReceiversWhenDriverPickup = false;
    public isSendSmsToReceiversWhenRetuningPackages = false;
    public isSendSmsToReceiversWhenPostponingPackages = false;
    public isSendSmsToReceiversWhenDriverFail = false;

    constructor(private companyNotificationsService: CompanyNotificationsService,
                private translateService: TranslateService,
                private messageService: MessageService,
                private modalService: NgbModal,
                private router: Router,
                private route: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private confirmationService: ConfirmationService,
                private userService: UserService,
                private companiesService: CompaniesService
                ) {}


    ngOnInit() {
        // this.selectedTab = this.route.routeConfig.path;
        this.isArabic = this.translateService.currentLang === 'ar';
        this.getNotificationEvents();
        this.userInfoApi();
    }


    public getNotificationEvents() {
        this.spinnerState = SpinnerState.LOADING;
        this.companyNotificationsService.getNotificationSettings().subscribe((data: any) => {
            this.events = data;
            this.spinnerState = SpinnerState.LOADED;
        }, (error) => {
            this.spinnerState = SpinnerState.LOADED;
        });
    }


    public onEventUpdate(template, settingId) {
        this.isLoading = true;
        this.companyNotificationsService.updateEvents(template, settingId).subscribe((events) => {
            this.translateService.get(
                'ALERTS.EVENTS_UPDATED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.isLoading = false;
                        this.getNotificationEvents();
                        this.userInfoApi();
                    }
                );

        }, (error) => {
            this.isLoading = false;
            this.getNotificationEvents();
        });

    }


    public editNotificationSetting(event, settingId) {
        this.isLoading = true;
        const modal = this.modalService.open(EditNotificationTemplateComponent, {
            backdrop: 'static',
            windowClass: 'edit-template',
            size: 'lg'

        });
        modal.componentInstance.templateText = event.template;
        modal.result.then(
            (status: any) => {
                if (status.success) {
                    this.onEventUpdate(status.template, settingId);
                } else {
                    this.isLoading = false;
                }
            }
        );
    }

    public confirmModify(str, ev) {
        this.translateService.get(['COMPANY.CONFIRM_MODIFY', 'GENERAL.YES', 'GENERAL.NO']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['COMPANY.CONFIRM_MODIFY'],
                accept: () => {
                    this.companiesService.changeCompanyDriverSms(str, ev.checked).subscribe(res => {
                    }, error => {
                        if (str === 'PICKUP') {
                            this.isSendSmsToReceiversWhenDriverPickup = !this.isSendSmsToReceiversWhenDriverPickup;
                        } else if (str === 'DELIVER') {
                            this.isSendSmsToReceiversWhenDriverDeliver = !this.isSendSmsToReceiversWhenDriverDeliver;
                        } else if (str === 'DELIVER_WITH_SEND_SMS_TO_SENDER') {
                            this.isSendSmsToSenderWhenDriverDeliver = !this.isSendSmsToSenderWhenDriverDeliver;
                        } else if (str === 'SEND_TO_SHARE_LOCATION') {
                            this.isSendSmsToReceiverstoShareLocation = !this.isSendSmsToReceiverstoShareLocation;
                        } else if (str === 'RETURN') {
                            this.isSendSmsToReceiversWhenRetuningPackages = !this.isSendSmsToReceiversWhenRetuningPackages;
                        } else if (str === 'POSTPONE') {
                            this.isSendSmsToReceiversWhenPostponingPackages = !this.isSendSmsToReceiversWhenPostponingPackages;
                        } else if (str === 'FAIL') {
                            this.isSendSmsToReceiversWhenDriverFail = !this.isSendSmsToReceiversWhenDriverFail;
                        }
                    });
                },
                reject: () => {
                    if (str === 'PICKUP') {
                        this.isSendSmsToReceiversWhenDriverPickup = !this.isSendSmsToReceiversWhenDriverPickup;
                    } else if (str === 'DELIVER') {
                        this.isSendSmsToReceiversWhenDriverDeliver = !this.isSendSmsToReceiversWhenDriverDeliver;
                    } else if (str === 'DELIVER_WITH_SEND_SMS_TO_SENDER') {
                        this.isSendSmsToSenderWhenDriverDeliver = !this.isSendSmsToSenderWhenDriverDeliver;
                    } else if (str === 'SEND_TO_SHARE_LOCATION') {
                        this.isSendSmsToReceiverstoShareLocation = !this.isSendSmsToReceiverstoShareLocation;
                    } else if (str === 'RETURN') {
                        this.isSendSmsToReceiversWhenRetuningPackages = !this.isSendSmsToReceiversWhenRetuningPackages;
                    } else if (str === 'POSTPONE') {
                        this.isSendSmsToReceiversWhenPostponingPackages = !this.isSendSmsToReceiversWhenPostponingPackages;
                    } else if (str === 'POSTPONE') {
                        this.isSendSmsToReceiversWhenDriverFail = !this.isSendSmsToReceiversWhenDriverFail;
                    }
                },
                acceptLabel: res['GENERAL.YES'],
                rejectLabel: res['GENERAL.NO'],
            });
        });
    }
    userInfoApi() {
       this.userService.getUserInfo(true).then(
            (response: any) => {
                this.isSendSmsToReceiversWhenDriverDeliver = response.isSendSmsToReceiversWhenDriverDeliver;
                this.isSendSmsToSenderWhenDriverDeliver = response.isSendSmsToSenderWhenDriverDeliver;
                this.isSendSmsToReceiverstoShareLocation = response.isSendSmsToReceiverstoShareLocation;
                this.isSendSmsToReceiversWhenDriverPickup = response.isSendSmsToReceiversWhenDriverPickup;
                this.isSendSmsToReceiversWhenRetuningPackages = response.isSendSmsToReceiversWhenDriverReturn;
                this.isSendSmsToReceiversWhenPostponingPackages = response.isSendSmsToReceiversWhenDriverPostpone;
                this.isSendSmsToReceiversWhenDriverFail = response.isSendSmsToReceiversWhenDriverFail;
            }
            ).catch(error => {
        });
    }
}
