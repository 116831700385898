<p-dialog #validationDialog
          [closable]="false"
          [modal]="true"
          [visible]="true"
          [draggable]="false"
          [minY]="0"
          [baseZIndex]="10000002"
          [maximizable]="false" styleClass="excel-validation-dialog">

    <p-toast [baseZIndex]="100000000"></p-toast>
    <p-header>
        <div *ngIf="errorMessage" class="alert alert-danger ml-2 mr-2" role="alert">
            {{ (errorMessage | translate) + ' ' + totalFailed }}
        </div>

        <div *ngIf="successMessage" class="alert alert-success ml-2 mr-2" role="alert">
            {{ (successMessage | translate) + ' ' + totalSubmitted }}
        </div>
    </p-header>


    <div>
        <p-table #importTable [paginator]="true" [rows]="10" [loading]="isLoading"
                 [value]="shipmentsArray.controls">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 140px !important;" colspan="7">#</th>
                    <th style="min-width: 240px !important;"
                        colspan="12">{{ 'ADD_SHIPMENT.SENDER_INFORMATION' | translate }}</th>
                    <th [style.min-width.px]="hasAdditionalAddressDetails ? 1360 : 960"
                        [attr.colspan]="hasAdditionalAddressDetails ? 86 : 76">{{ 'ADD_SHIPMENT.RECEIVER_INFORMATION' | translate }}</th>
                    <th [style.min-width.px]="isShowDynamicServiceTypes ? 1360 : 1200"
                        [attr.colspan]="(isShowDynamicServiceTypes ? 86 : 78) + (showCodFees ? 4: 0)+(showWeight ? 4: 0)">{{ 'ADD_SHIPMENT.SHIPMENT_INFORMATION' | translate }}</th>
                    <th style="min-width: 400px !important;"
                        colspan="30">{{ 'ADD_SHIPMENT.SENDER_INFORMATION' | translate }}</th>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th colspan="4">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.ROW_ID' | translate }}</th>
                    <th colspan="12">{{ 'ADD_SHIPMENT.SENDER_EMAIL' | translate }}</th>

                    <th colspan="10">{{ 'USERS.FULL_NAME' | translate }}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.RECEIVER_BUSINESS_NAME' | translate }}</th>
                    <th colspan="8">{{ 'ADD_SHIPMENT.RECEIVER_PHONE' | translate }}</th>
                    <th colspan="8">{{ 'ADD_SHIPMENT.EXCEL_RECEIVER_PHONE2' | translate }}</th>
                    <th colspan="10">
                        {{ 'ADD_SHIPMENT.RECEIVER_CITY' | translate }}
                        <div class="th-hint">
                            {{ 'ADD_SHIPMENT.EXCEL_HEADERS.CITY_OR_VILLAGE' | translate | addressSystem }}
                        </div>
                    </th>
                    <th colspan="10">
                        {{ 'ADD_SHIPMENT.EXCEL_HEADERS.RECEIVER_VILLAGE' | translate | addressSystem }}
                        <div class="th-hint">
                            {{ 'ADD_SHIPMENT.EXCEL_HEADERS.CITY_OR_VILLAGE' | translate | addressSystem }}
                        </div>
                    </th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.RECEIVER_STREET' | translate }}</th>
                    <th *ngIf="showAddressLine2" colspan="10">{{ 'ADD_SHIPMENT.RECEIVER_STREET' | translate }} 2</th>

                    <th *ngIf="hasAdditionalAddressDetails" colspan="10">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.RECEIVER_BUILDING' | translate }}</th>
                    <th *ngIf="hasAdditionalAddressDetails" colspan="10">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.RECEIVER_FLAT' | translate }}</th>

                    <th colspan="4">{{ 'ADD_SHIPMENT.COD_COLLECTION_METHOD' | translate }}</th>
                    <th *ngIf="showCodFees" colspan="4"> {{"ADD_SHIPMENTS.COD_CASH_ON_DELIVERY_FEES" | translate}}</th>
                    <th colspan="4" *ngIf="showWeight">{{ 'FULFILMENT.PRODUCT.TABLE.WEIGHT' | translate }}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.INVOICE_NUMBER' | translate }}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.SUPPLIER_INVOICE' | translate }}</th>
                    <th colspan="10">{{ 'GENERAL.NOTES' | translate }}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.DESCRIPTION' | translate }}</th>
                    <th colspan="4">{{ 'ADD_SHIPMENT.QUANTITY' | translate }}</th>
                    <th colspan="8">{{ 'ADD_SHIPMENT.TYPE_SHIPMENT' | translate | packageTitle}}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.COD_COLLECTION_TYPE' | translate | packageTitle}}</th>
                    <th colspan="4">{{ 'ADD_SHIPMENT.COD' | translate }}</th>
                    <th colspan="8">{{ 'ADD_SHIPMENT.SERVICE_TYPE' | translate }}</th>
                    <th *ngIf="isShowDynamicServiceTypes" colspan="8">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.VEHICLE_TYPE' | translate }}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.SENDER_NAME' | translate }}</th>
                    <th colspan="10">{{ 'ADD_SHIPMENT.EXCEL_HEADERS.SENDER_BUSINESS_NAME' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-shipment let-rowIndex="rowIndex">
                <tr class="table-row"
                    [ngClass]="(shipment.invalid && (shipment.touched || shipment.dirty)) || shipment.get('shipmentDetails').get('failed').value ? 'invalid-row' :
                        shipment.get('shipmentDetails').get('submitted').value ? 'valid-row' : ''">

                    <td colspan="3" [formGroup]="shipment">
                        <p-checkbox (onChange)="changeShipmentEnable(shipment)" formControlName="enabled"
                                    binary="true"></p-checkbox>
                    </td>

                    <td colspan="4" [formGroup]="shipment.get('shipmentDetails')">
                        <span>
                    {{ shipment.get('shipmentDetails').get('rowId').value }}
                            <i class="fas fa-info-circle fa-2x error-icon"
                               *ngIf="shipment.get('shipmentDetails').get('failed').value"
                               pTooltip="{{ shipment.get('shipmentDetails').get('errorMessage').value }}"
                               tooltipPosition="top"
                               [tooltipZIndex]="100000000"
                            ></i>
                        </span>
                        <div *ngIf="shipment.get('shipmentDetails').get('submitting').value">
                            <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                        </div>
                    </td>

                    <td colspan="12">
                        <div [formGroup]="shipment.get('shipmentDetails')">
                            <ng-select class="village-select excel-dialog-ng-select"
                                       appDebounceSelectQuery
                                       formControlName="customerInfo"
                                       loadingText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.CUSTOMER_LOADING_PLACEHOLDER' | translate }}"
                                       placeholder="{{shipment.get('shipmentDetails').get('senderEmail').value
                                            ? shipment.get('shipmentDetails').get('senderEmail').value
                                            : ('ADD_SHIPMENT.EXCEL_IMPORT.CUSTOMER_SEARCH_PLACEHOLDER' | translate) }}"
                                       notFoundText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.CUSTOMER_SEARCH_PLACEHOLDER' | translate }}"
                                       appendTo="body" [items]="customerOptions"
                                       bindLabel="email" (close)="clearCustomerOptions()"
                                       (open)="getCustomerOptions(shipment)"
                                       [loading]="shipment.get('shipmentDetails').get('customerLoading').value"
                                       [formLoading]="shipment.get('shipmentDetails').get('customerLoading')"
                                       [debounceTime]="700" [observer]="customerSearchObserver"
                                       (change)="getCustomerServiceOptions(shipment);getCustomerShipmentOptions(shipment)"

                            >
                                <ng-option
                                        [value]="item"
                                        *ngFor="let item of customerOptions">
                                    {{ item.email }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="receiverName">
                    </td>
                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="businessReceiverName">
                    </td>

                    <td colspan="8" [formGroup]="shipment.get('shipmentDetails')">
<!--                        <field-input type="text" class="form-control" formControlName="receiverMobile">-->
                        <app-phone-number-input [formGroupPass]="shipment.get('shipmentDetails')" [formControlNamePass]="'receiverMobile'" [formControlPass]="shipment.get('shipmentDetails').get('receiverMobile')" cssClassPass="add-package"></app-phone-number-input>
                    </td>

                    <td colspan="8" [formGroup]="shipment.get('shipmentDetails')">
                        <!--                        <field-input type="text" class="form-control" formControlName="receiverMobile">-->
                        <app-phone-number-input [formGroupPass]="shipment.get('shipmentDetails')" [formControlNamePass]="'receiverMobile2'" [formControlPass]="shipment.get('shipmentDetails').get('receiverMobile2')" cssClassPass="add-package"></app-phone-number-input>
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <ng-select class="village-select excel-dialog-ng-select" appDebounceSelectQuery
                                   [ngClass]="{'error-input': hasError(shipment)}"
                                   notFoundText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.ADDRESS_SEARCH_PLACEHOLDER' | translate | addressSystem }}"
                                   loadingText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.ADDRESS_LOADING_PLACEHOLDER' | translate }}"
                                   formControlName="receiverCity" [items]="cityOptions"
                                   [loading]="shipment.get('shipmentDetails').get('cityLoading').value"
                                   appendTo="body" bindLabel="name" (close)="clearCityOptions()"
                                   (open)="getCityOptions(shipment)"
                                   [formLoading]="shipment.get('shipmentDetails').get('cityLoading')"
                                   [debounceTime]="1000" [observer]="citySearchObserver">
                            <ng-option
                                    [value]="item"
                                    *ngFor="let item of cityOptions">
                                {{ item.name }}
                            </ng-option>
                        </ng-select>
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <ng-select class="village-select excel-dialog-ng-select" appDebounceSelectQuery
                                   [ngClass]="{'error-input': hasError(shipment)}"
                                   notFoundText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.ADDRESS_SEARCH_PLACEHOLDER' | translate | addressSystem }}"
                                   loadingText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.ADDRESS_LOADING_PLACEHOLDER' | translate }}"
                                   formControlName="receiverVillage" [items]="villageOptions"
                                   [loading]="shipment.get('shipmentDetails').get('villageLoading').value"
                                   appendTo="body" bindLabel="name" (close)="clearVillageOptions()"
                                   (open)="getVillageOptions(shipment)"
                                   [formLoading]="shipment.get('shipmentDetails').get('villageLoading')"
                                   [debounceTime]="1000" [observer]="villageSearchObserver">
                            <ng-option
                                    [value]="item"
                                    *ngFor="let item of villageOptions">
                                {{ item.name }}
                            </ng-option>
                        </ng-select>
                    </td>
                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="receiverStreetAddress">
                    </td>

                    <td colspan="10" *ngIf="showAddressLine2" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="receiverStreetAddress2">
                    </td>

                    <td colspan="10" *ngIf="hasAdditionalAddressDetails" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="receiverBuilding">
                    </td>

                    <td colspan="10" *ngIf="hasAdditionalAddressDetails" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="receiverFlat">
                    </td>

                    <td colspan="4" [formGroup]="shipment.get('shipmentDetails')">
                        <p-dropdown
                                formControlName="codCollectionMethod"
                                [options]="codCollectionMethods"
                                styleClass="dropdown-container"
                                appendTo="body"
                                panelStyleClass="z-index-9999999999999999999"
                                baseZIndex=""
                                placeholder="{{ 'ACTIONS.SELECT_METHOD' | translate }}">
                        </p-dropdown>
                    </td>
                    <td colspan="4" *ngIf="showCodFees" [formGroup]="shipment.get('shipmentDetails')" [ngClass]="{'disabled-btn': shipment?.get('shipmentDetails')?.get('shipmentType')?.value !== 'COD'}">
                        <input formControlName="codFees" type="number" step="any" class="form-control" placeholder="" min="0" [ngClass]="{'disabled-btn': shipment?.get('shipmentDetails')?.get('shipmentType')?.value !== 'COD'}" [disabled]="shipment?.get('shipmentDetails')?.get('shipmentType')?.value !== 'COD'">
                    </td>
                    <td *ngIf="showWeight" colspan="4" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="number" step="any" class="form-control" formControlName="weight">
                    </td>
                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="invoiceNumber">
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="supplierInvoice">
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="notes">
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="description">
                    </td>

                    <td colspan="4" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="number" class="form-control" formControlName="quantity">
                    </td>

                    <td colspan="8" [formGroup]="shipment.get('shipmentDetails')" style="min-width: 120px">
                        <ng-select formControlName="shipmentType"
                                   [searchable]="false" appendTo="body"
                                   class="village-select excel-dialog-ng-select"
                                   bindValue="value"
                                   (change)="setCodFees(shipment);shipmentTypeChanged($event, shipment)"
                                   [items]="userShipmentOptionMap[shipment.value.shipmentDetails?.customerInfo?.id] || shipmentTypesOptions">
                            <ng-option
                                    [value]="item"
                                    *ngFor="let item of userShipmentOptionMap[shipment.value.shipmentDetails?.customerInfo?.id] || shipmentTypesOptions">
                                {{ item.label }}
                            </ng-option>
                        </ng-select>
                    </td>
                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')" style="min-width: 120px">
                        <ng-select formControlName="codCollectionType"
                                   [style.visibility]="['BRING', 'SWAP'].indexOf(shipment.get('shipmentDetails')?.get('shipmentType')?.value) === -1 ? 'hidden' : ''"
                                   [searchable]="false" appendTo="body"
                                   class="village-select excel-dialog-ng-select"
                                   bindValue="value"
                                   [items]="collectionTypes">
                            <ng-option
                                    [value]="item.value"
                                    *ngFor="let item of collectionTypes">
                                {{ item.label }}
                            </ng-option>
                        </ng-select>
                    </td>

                    <td colspan="4" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="number" step="any" class="form-control" formControlName="cod">
                    </td>

                    <td *ngIf="!isShowDynamicServiceTypes" colspan="8" [formGroup]="shipment.get('shipmentDetails')"
                        style="min-width: 120px">
                        <ng-select formControlName="serviceType"
                                   [searchable]="false" appendTo="body"
                                   class="excel-dialog-ng-select"
                                   bindValue="value"
                                   [items]="userServiceOptionMap[shipment.value.shipmentDetails?.customerInfo?.id] || shipmentServicesOptions">
                            <ng-option
                                    [value]="item"
                                    *ngFor="let item of userServiceOptionMap[shipment.value.shipmentDetails?.customerInfo?.id] || shipmentServicesOptions">
                                {{ item.label }}
                            </ng-option>
                        </ng-select>
                    </td>
                    <td *ngIf="isShowDynamicServiceTypes" colspan="8" [formGroup]="shipment.get('shipmentDetails')">
                        <ng-select
                                [items]="customersServiceTypesOptions[shipment.value.shipmentDetails?.customerInfo?.id]"
                                appendTo="body"
                                notFoundText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.SERVICE_TYPE_SEARCH_PLACEHOLDER' | translate }}"
                                loadingText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.SERVICE_TYPE_LOADING_PLACEHOLDER' | translate }}"
                                placeholder="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.SERVICE_TYPE_PLACEHOLDER' | translate }}"
                                formControlName="serviceType" class="village-select excel-dialog-ng-select"
                                (change)="onChangeServiceType(shipment.get('shipmentDetails'), false)"
                                (inputDebounced)="getServiceTypes($event, shipment.get('shipmentDetails'))"
                                [loading]="shipment.get('shipmentDetails').get('serviceTypeLoading').value"
                                [bindLabel]="currentLang === 'en' ? 'name' : 'arabicName'"
                                appDebounceSelectQuery>
                        </ng-select>
                    </td>

                    <td *ngIf="isShowDynamicServiceTypes" colspan="8" [formGroup]="shipment.get('shipmentDetails')">
                        <ng-select [bindLabel]="currentLang === 'en' ? 'name' : 'arabicName'"
                                   class="village-select excel-dialog-ng-select"
                                   notFoundText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.VEHICLE_TYPE_NOT_FOUND' | translate }}"
                                   loadingText="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.VEHICLE_TYPE_LOADING_PLACEHOLDER' | translate }}"
                                   placeholder="{{ 'ADD_SHIPMENT.EXCEL_IMPORT.VEHICLE_TYPE_PLACEHOLDER' | translate }}"
                                   formControlName="vehicleType" appendTo="body" [searchable]="false"
                                   [loading]="shipment.get('shipmentDetails').get('vehicleTypeLoading').value"
                                   [items]="shipment.get('shipmentDetails').get('serviceType').value ?
                        vehicleTypesOptions[shipment.get('shipmentDetails').get('serviceType').value.id] : []">
                            <ng-option [value]="item"
                                       *ngFor="let item of vehicleTypesOptions[shipment
                                    .get('shipmentDetails')
                                    .get('serviceType').value ? shipment.get('shipmentDetails').get('serviceType').value.id
                                        : 0]; trackBy: trackVehicleTypeById">
                                {{ currentLang == 'en' ? item.name : item.arabicName }}
                            </ng-option>
                        </ng-select>
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="senderName">
                    </td>

                    <td colspan="10" [formGroup]="shipment.get('shipmentDetails')">
                        <input type="text" class="form-control" formControlName="businessSenderName">
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <button [disabled]="isLoading" [ngClass]="{'disabled-btn' : isLoading}"
                (click)="hide()">
            <i class="fas fa-times ml-1 mr-1"></i>{{ 'ACTIONS.CANCEL' | translate }}
        </button>
        <button [disabled]="isLoading" [ngClass]="{'disabled-btn' : isLoading || !addedPackages.length}"
                (click)="printAddedPackages()">
            <i *ngIf="isPrinting" class="fas fa-spinner fa-spin ml-1 mr-1 text-white"></i>
            <i class="fas fa-print ml-1 mr-1" *ngIf="!isPrinting"></i>{{ 'ACTIONS.PRINT' | translate }}
        </button>
        <button *ngIf="isBundle" class="add-to-driver-container action-btn cursor-pointer"
                [ngClass]="{'disabled-button disabled': (totalSubmitted === 0) || isLoading}" (click)="addToBundle()">

            {{("SHIPMENT.ADD_BUNDLE") | translate}}
        </button>
        <button [disabled]="shipmentsArray.invalid || shipmentsArray.disabled ||
                  isLoading || shipmentsArray.controls.length === 0 || totalEnabledShipments === 0"
                [ngClass]="shipmentsArray.invalid || shipmentsArray.disabled ||
                  isLoading || shipmentsArray.controls.length === 0 || totalEnabledShipments === 0 ? 'disabled-btn' : ''"
                (click)="submitForm()"><i class="fas fa-save ml-1 mr-1 "></i>{{ 'ACTIONS.SUBMIT' | translate }}</button>
    </p-footer>
</p-dialog>

