import { Component, OnInit } from '@angular/core';
export enum TABS  {
  WALLET = 'WALLET',
  RECEIPT = 'RECEIPT'
}

@Component({
  selector: 'app-customers-wallets',
  templateUrl: './customers-wallets.component.html',
  styleUrls: ['./customers-wallets.component.scss']
})
export class CustomersWalletsComponent implements OnInit {
  selectedTab = TABS.WALLET;
  TABS = TABS;


  constructor() { }

  ngOnInit(): void {
  }

  onTabChange(tab: any) {
    this.selectedTab = tab;
  }
}
