<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="edit-prefix-container">
        <div class="header d-inline-flex">
            <div class="title">{{'ADMINISTRATION.EDIT_CITY_PREFIX' | translate}}</div>
            <div class="flex-1"></div>
            <div class="close-icon" (click)="closeModal()" [ngClass]="{'disabled-btn disabled': isLoading}">
                <img src="../../assets/icons/cancel.svg" alt="icon" />
            </div>
        </div>

        <form [formGroup]="form" *ngIf="form">
            <div class="inputs-prefix">
                <div class="row d-inline-flex row-input">
                    <div class="col-6 input" *ngIf="showPrefix">
                        <div class="input-label prefix-label">{{'ADMINISTRATION.CITY_PREFIX' | translate}}</div>
                        <div class="input-value">
                            <app-input-field-component
                                    [form]="form"
                                    controlName="prefix"
                                    [isRequired]="true"
                                    requiredErrorMessage="GENERAL.REQUIRED_VALIDATION"
                                    controlType="text"></app-input-field-component >
                        </div>
                    </div>
                    <div class="col-6 input" *ngIf="showCityColor">
                        <div class="input-label prefix-label">{{'ADMINISTRATION.CITY_COLOR' | translate}}</div>
                        <div class="input-value d-flex w-100 city-color-container flex-2d-center">
                           <div class="city-color w-100">
                               <input list="colors" type="color"  formControlName="color"/>
                               <datalist id="colors">
                               </datalist>
                           </div>
                        </div>
                    </div>
                </div>
                <div class="inputs row">
                    <div class="row bottom-actions">
                        <div class="d-flex justify-content-end loading-section pl-5 pr-5 w-100" *ngIf="isSubmitting">
                            <span><i class="fas fa-spinner fa-pulse"></i></span>
                        </div>
                        <div *ngIf="!isSubmitting" class="actions d-inline-flex">
                            <div class="cancel-action d-inline-flex flex-2d-center" (click)="closeModal()">
                                <span>{{'ACTIONS.CANCEL' | translate}}</span>
                            </div>
                            <div class="splitter"></div>
                            <div class="done-action flex-2d-center" (click)="addContainer()" [ngClass]="{'disabled-btn disabled': !form.get('prefix').value && !form.get('color').value}">
                                <span>{{'ACTIONS.DONE' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
