<!--<p-toast [baseZIndex]="toastZIndex"></p-toast>-->
<div class="close-icon" (click)="closeModal()"><img src="../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="row d-inline-flex main-container">
        <div class="side-container">
            <div class="row title-container">
                <div class="label">
                    {{ (!isDriversTimeTracking ? 'DRIVER_CHECK_IN.TITLE' : 'DRIVERS_TIME_TRACKING.TITLE' )| translate }}
                </div>
            </div>

            <div class="drivers-list-container">
                <div class="list-header">
                    {{ 'DRIVER_CHECK_IN.FILTER.CHOOSE_DRIVER' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchDriver($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onDriversScroll($event)">
                    <div class="list-item"
                         [ngClass]="selectedDriver && driver.id === selectedDriver.id ? 'list-item-selected' : ''"
                         *ngFor="let driver of drivers; trackBy: trackDriverById" (click)="selectDriver(driver)">
                        <div class="list-name">
                            {{driver.firstName + ' ' + driver.lastName}}
                        </div>
                    </div>
                </div>
                <div *ngIf="isLoadingDrivers || isLoading" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>
        </div>
        <div class="map-table-container flex-1">
            <div *ngIf="!isDriversTimeTracking" id="map"></div>
            <app-date-range-picker *ngIf="isDriversTimeTracking"
                                   class="d-flex justify-content-end mt-2"
                                   (onSelect)="onDateSelected($event)"></app-date-range-picker>
            <div class="table-container"
                 [ngClass]="{'drivers-time-tracking-table-container': isDriversTimeTracking}"
                 (scroll)="onTableScroll($event)">
                <p-table [value]="dataList" [loading]="isLoading" class="entities-table-component"
                         [responsive]="true" scrollHeight="auto" styleClass="entities-table">
                    <ng-template pTemplate="header">
                        <tr>
                           <ng-container *ngIf="!isDriversTimeTracking else driversTimeTrackingHeader">
                               <th>{{ 'DRIVER_CHECK_IN.TABLE.BARCODE' | translate | packageTitle}}</th>
                               <th>{{ 'DRIVER_CHECK_IN.TABLE.CUSTOMER_NAME' | translate }}</th>
                               <th>{{ 'DRIVER_CHECK_IN.TABLE.CHECK_IN_DATE' | translate }}</th>
                               <th>{{ 'DRIVER_CHECK_IN.TABLE.CHECK_IN_TIME' | translate }}</th>
                           </ng-container>
                            <ng-template #driversTimeTrackingHeader>
                                <th>{{'DRIVERS_TIME_TRACKING.TABLE.DRIVER_NAME' | translate}}</th>
                                <th>{{'DRIVERS_TIME_TRACKING.TABLE.START' | translate}}</th>
                                <th>{{'DRIVERS_TIME_TRACKING.TABLE.END' | translate}}</th>
                                <th>{{'DRIVERS_TIME_TRACKING.TABLE.DURATION' | translate}}</th>
                            </ng-template>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr (click)="selectCheckIn(item)"
                            [ngClass]="{'selected-row': !isDriversTimeTracking && selectedCheckIn && selectedCheckIn.id === item.id}">
                            <ng-container *ngIf="!isDriversTimeTracking else driversTimeTrackingData">
                                <td>{{ item.barcode }}</td>
                                <td>{{ item.customerName }}</td>
                                <td>{{ item.timestamp | date:'dd-MM-yyyy' }}</td>
                                <td>{{ item.timestamp | date:'hh:mm' }}</td>
                            </ng-container>
                            <ng-template #driversTimeTrackingData>
                                <td>{{item.driverName}}</td>
                                <td class="date">{{item.start | date:'dd-MM-yyyy hh:mm a'}}</td>
                                <td class="date">{{item.end | date:'dd-MM-yyyy hh:mm a'}}</td>
                                <td>{{item.startToEndDiffInHours}}</td>
                            </ng-template>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
