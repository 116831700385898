<div class="failure-container">
    <div class="header">
        {{('SHIPMENT.FAILURES_HISTORY') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div class="row messages-container">
        <div class="col-12 failure-list-container">
            <p-scrollPanel [style]="{width: '100%', height: '400px'}" styleClass="cards-scroll">
                <p-card *ngFor="let history of failuresHistory" class="history-card">
                    <div class="card-flex">
                        <p>{{'SHIPMENT.AUTHOR'|translate}}:  </p>
                        <span>{{history.author | translate}}</span>
                    </div>
                    <div class="card-flex">
                        <p>{{'SHIPMENT.DATE' | translate}}:  </p>
                        <span>{{history.createdDate|date:'dd/MM/yyyy'}}</span>
                    </div>
                    <div *ngIf="history.driverName" class="card-flex">
                        <p>{{'SHIPMENT.DRIVER'|translate}}:  </p>
                        <span>{{history.driverName}}</span>
                    </div>
                    <div class="card-flex">
                        <p>{{'SHIPMENT.NOTE'|translate}}:  </p>
                        <span>{{history.note}}</span>
                    </div>
                </p-card>
            </p-scrollPanel>
        </div>
    </div>
</div>
