<app-field-set-form [label]="label"
                    [cssStyle]="cssStyle"
                    [isFilter]="isFilter"
                    [filterActive]="value && value !== defaultValue"
                    [formControlObj]="formControlObj"
                    [showLabel]="showLabel">
    <p-dropdown [options]="options"
                [id]="id"
                [style]="{'width':'100%'}"
                [disabled]="fieldDisabled"
                [filter]="filter"
                [filterBy]="dropdownFilterBy"
                (onChange)="change($event)"
                [showClear]="showClear"
                [(ngModel)]="value"
                [dataKey]="datakey"
                [optionValue]="optionValue"
                [placeholder]="placeholder | translate"
                [optionLabel]="optionLabel"
                [appendTo]="appendTo"
                [autoDisplayFirst]="autoDisplayFirst">
        <ng-template *ngIf="selectedItemTemplate" let-dropdownItem pTemplate="selectedItem">
            <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: dropdownItem}">
            </ng-container>
        </ng-template>
        <ng-template *ngIf="itemTemplate" let-dropdownItem pTemplate="item">
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: dropdownItem}">
            </ng-container>
        </ng-template>
    </p-dropdown>
</app-field-set-form>
