import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {SpinnerState} from '../shared/behavior/spinner-state.enum';
import {UserService} from '../shared/services/user.service';
import {forkJoin, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ShipmentsService} from '../shipment/services/shipments.service';
import {GeographyService} from '../shared/services/geography.service';
import {UtilitiesService} from '../shared/services/utilities.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../shared/services/authentication.service';
import {Router} from '@angular/router';
import {UsersService} from '../users/services/users.service';
import {HubsService} from '../shared/services/hubs.service';
import {ParcelService} from '../administration/services/parcel.service';
import {DriversService} from '../shared/services/drivers.service';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ServiceTypesService} from '../administration/services/service-types.service';
import {Address} from '../shared/models/address.class';
import {FileUploaderComponent} from '../shared/components/file-uploader/file-uploader.component';
import {UserModel} from '../shared/models/user.model';
import {PackageContentModel} from './models/package-content.model';
import {ZonesService} from '../administration/services/zones.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';

@Component({
    selector: 'app-distributor-package-form',
    templateUrl: './distributor-package-form.component.html',
    styleUrls: ['./distributor-package-form.component.scss']
})
export class DistributorPackageFormComponent extends Address implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() display: boolean;
    @Input() packageBarcode: number;
    @Output() closeWidget = new EventEmitter();
    @Output() showAWBDialogEvent = new EventEmitter();
    @Output() showPackageDetailsEvent = new EventEmitter();
    @ViewChild('SenderAddressBook') senderAddressBook;
    @ViewChild('AddPackageSideBar') addPackageSideBar;
    @ViewChild('StoreName') storeName;
    @ViewChild('attachmentsUploader') attachmentsUploader: FileUploaderComponent;

    public isLoading: SpinnerState;
    public spinnerStates = SpinnerState;
    public companyInfo: UserModel;
    public companyId;
    public selectedReceiverAddress: any;
    public isArabic = false;
    public selectedTab = 'MAIN_TAB';
    public addPackageForm: FormGroup;
    public editedPackageId: number;
    public isEditMode: boolean;
    public isSubmittingPackage: boolean;
    public isSubmittingSenderAddress: boolean;
    public isRemovingSenderAddress: boolean;
    public isEditAddressBook: boolean;
    public addExtraPackages: boolean;
    public editAddressId: number;
    public addressOptions: { value: string, label: string }[];
    public storesList = [];
    public shipmentTypesOptions: { value: string, label: string }[];
    public shipmentServicesOptions: { value: string, label: string }[];
    public packagesIds = [];
    public preventSubmitWhenPressEnter = false;
    public swapOrBring = 'COLLECT';
    public deliveryPrice = -1;
    public editSenderAddress = false;
    public directPrint;
    public onlySave;
    public saveAndContinue;
    public hasSavedPackages = false;
    public hasAdditionalAddressDetails = false;

    public packageContents: PackageContentModel[] = [];
    public selectedPackageContents: PackageContentModel[] = [];

    public isLoadingSenderAddressBook: SpinnerState = SpinnerState.LOADING;
    public senderAddresses: any[];
    public senderLocationForm: FormGroup;
    public tempSelectedSenderAddressIndex = -1;
    public receiverSubject = new Subject<string>();
    public pickupPointSubject = new Subject<string>();
    public deliveryPointSubject = new Subject<string>();
    public receiverLoading = false;
    public pickupPointsLoading = false;
    public deliveryPointsLoading = false;
    public showReceiverAddress = true;
    showStudentsNumber = false;
    showOriginal = false;
    selectedLanguage;

    constructor(
        private geographyService: GeographyService,
        private usersService: UsersService,
        private userService: UserService,
        private utilitiesService: UtilitiesService,
        private translate: TranslateService,
        private shipmentService: ShipmentsService,
        private serviceTypesService: ServiceTypesService,
        private parcelService: ParcelService,
        private messageService: MessageService,
        private auth: AuthenticationService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private hubsService: HubsService,
        private driversService: DriversService,
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private zonesService: ZonesService,
        @Inject(FormBuilder) private formBuilder: FormBuilder
    ) {
        super(zonesService, translate, true);
        this.allVillages = true;
    }
    ngOnInit() {
        this.selectedLanguage = this.translate.currentLang;
        this.companyInfo = this.userService.userInfo;
        this.companyInfo.address = {};
        this.companyInfo.name = this.auth._companyName;
        this.companyId = this.auth.companyId;
        this.showStudentsNumber = [185, 246].indexOf(this.companyId) >= 0;
        this.isArabic = this.translate.currentLang === 'ar';
        this.initAddPackageForm();
        this.createSenderLocationForm();
        this.init();
        this.addConfirmationBeforeClose();
    }
    ngOnDestroy() {
        this.receiverSubject.unsubscribe();
        this.pickupPointSubject.unsubscribe();
        this.deliveryPointSubject.unsubscribe();
    }

    ngOnChanges() {
    }

    ngAfterViewInit() {
    }

// SENDER ADDRESS BOOK FORM
    public createSenderLocationForm() {

        this.senderLocationForm = this.formBuilder.group({
            fullName: ['', [Validators.required]],
            phone1: ['', [Validators.required,
               this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144)),
                Validators.maxLength(14),
                Validators.minLength(8)]],
            businessName: [''],
            email: ['', [
                Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$')]],
            address: ['', Validators.required],
            addressLine1: ['', Validators.required]
        });
    }

    public addEditSenderAddress() {

        if (this.senderLocationForm.invalid) {
            this.utilitiesService.validateAllFormFields(this.senderLocationForm);
            return;
        }

        this.isSubmittingSenderAddress = true;

        const data = this.senderLocationForm.getRawValue();
        const body = {
            phone1: data.phone1,
            addressId: data['address'].id,
            address: {
                regionId: data['address'].regionId,
                cityId: data['address'].cityId,
                villageId: data['address'].value,
                addressLine1: data['addressLine1']
            },
            fullName: data.fullName,
            email: data.email,
            businessName: data.businessName,
            type: 'SENDER'
        };

        if (!this.isEditAddressBook) { // Add new
            this.shipmentService.AddSenderAddressBook(body).subscribe(
                () => {
                    this.toggleSelectedTab();
                    this.loadSenderAddressBooks();
                    this.isSubmittingSenderAddress = false;
                }, (error) => {
                    this.isSubmittingSenderAddress = false;
                    console.error(error);
                }, () => {
                    this.isSubmittingSenderAddress = false;
                }
            );
        } else { // Edit existing
            this.shipmentService.editSenderAddressBook(this.editAddressId, body).subscribe(
                () => {
                    this.toggleSelectedTab();
                    this.loadSenderAddressBooks();
                    this.isSubmittingSenderAddress = false;
                }, (error) => {
                    this.isSubmittingSenderAddress = false;
                    console.error(error);
                }, () => {
                    this.isSubmittingSenderAddress = false;
                }
            );
        }

    }

    public removeAddress(addressIndex) {
        this.translate.get(['ALERTS.CONFIRM_ADDRESS_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
            this.confirmationService.confirm({
                message: values['ALERTS.CONFIRM_ADDRESS_DELETE'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    const addressId = this.senderAddresses[addressIndex].id;
                    this.senderAddresses[addressIndex].isRemovingSenderAddress = true;

                    this.shipmentService.removeSenderAddressBook(addressId).subscribe(
                        (response: any) => {
                            this.loadSenderAddressBooks();
                        }, (error) => {
                            console.error(error);
                        }, () => {
                            this.senderAddresses[addressIndex].isRemovingSenderAddress = false;
                        }
                    );
                }
            });
        });
    }

    public showEditAddress(addressIndex) {
        this.selectedTab = 'SECONDARY_TAB';
        this.isEditAddressBook = true;
        const data = this.senderAddresses[addressIndex];
        this.editAddressId = data.id;
        this.senderLocationForm.patchValue({
            fullName: data.fullName,
            phone1: data.phone1,
            businessName: data.businessName,
            email: data.email,
            address: {
                value: data.address.villageId,
                label: data.address.village + ' - ' + data.address.city + ' - ' + data.address.region,
                cityId: data.address.cityId,
                regionId: data.address.regionId,
                country: data.address.country
            },
            addressLine1: data.address.addressLine1
        });
    }

    public onHideSenderAddressBook() {
        this.senderLocationForm.reset();
        this.isEditAddressBook = false;
    }

    public toggleSelectedTab() {
        if (this.selectedTab === 'MAIN_TAB') {
            this.senderLocationForm.reset();
            this.selectedTab = 'SECONDARY_TAB';
        } else {
            this.selectedTab = 'MAIN_TAB';
            this.isEditAddressBook = false;
        }
    }

    public setNewSenderInfo() {
        if (this.tempSelectedSenderAddressIndex >= 0) {
            this.fillSenderInfo(this.senderAddresses[this.tempSelectedSenderAddressIndex]);
            this.senderAddressBook.hide();
        }
    }

    public closeSenderInfo() {
        this.senderAddressBook.hide();
    }

    public init() {
        this.isLoading = SpinnerState.LOADING;
        const promises = [
            this.userService.getCompanyAddress()
        ];

        if (this.packageBarcode) {
            this.isEditMode = true;
            promises.push(this.shipmentService.getPackageHistoryByBarcode(this.packageBarcode));
        }

        forkJoin(promises).subscribe(
            (response: any) => {
                this.companyInfo.address = response[0];
                this.fillSenderInfo();

                if (this.isEditMode) {
                    const packageDetails = response[1].pkg;
                    this.editedPackageId = packageDetails.id;
                    this.preFillData(packageDetails);
                }

                this.companyId = this.auth.companyId;
                this.isLoading = SpinnerState.LOADED;
            }, (error) => {
                console.error(error);
                this.isLoading = SpinnerState.FAILED;
            }
        );

        this.receiverSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.getStores({query: search});
            });
        this.pickupPointSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.pickupPointsLoading = true;
                this.initVillage(0, null, search).then(
                    () => {
                        this.pickupPointsLoading = false;
                    }
                );
            });
        this.deliveryPointSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.deliveryPointsLoading = true;
                this.initVillage(0, null, search).then(
                    () => {
                        this.deliveryPointsLoading = false;
                    }
                );
            });
    }

    public showSenderInfo($event) {
        this.selectedTab = 'MAIN_TAB';
        this.senderAddressBook.toggle($event);
        this.loadSenderAddressBooks();
    }

    public loadSenderAddressBooks() {
        this.isLoadingSenderAddressBook = SpinnerState.LOADING;
        const query = '?page=1&pageSize=100000&type=SENDER';
        this.usersService.getSenderAddressBook(query).subscribe(
            (response: any) => {
                this.senderAddresses = response;
                if (this.senderAddresses && this.senderAddresses.length) {
                    this.isLoadingSenderAddressBook = SpinnerState.LOADED;
                } else {
                    this.isLoadingSenderAddressBook = SpinnerState.EMPTY;
                }
            }, (error) => {
                console.error(error);
                this.isLoadingSenderAddressBook = SpinnerState.FAILED;
            }
        );
    }

    @HostListener('document:keydown.escape', ['$event']) handleEscButton(event: KeyboardEvent) {
        if (this.addPackageSideBar.visible) {
            this.addPackageSideBar.close(event);
        }
    }

    @HostListener('document:keydown.enter', ['$event']) handleEnterButton(event: KeyboardEvent) {
        if (this.addPackageSideBar.visible && !this.preventSubmitWhenPressEnter) {
            this.submitForm();
        }
    }

    // override close method to allow showing confirmation popup
    public addConfirmationBeforeClose() {
        this.addPackageSideBar.close = (event) => {
            this.addPackageSideBar.preventVisibleChangePropagation = true;
            event.preventDefault();
            return this.confirmationService.confirm({
                message: this.translate.instant('ACTIONS.CONFIRM_CANCEL'),
                acceptLabel: this.translate.instant('GENERAL.YES'),
                rejectLabel: this.translate.instant('GENERAL.NO'),
                accept: () => {
                    this.addPackageSideBar.hide();
                    this.addPackageSideBar.visibleChange.emit(false);
                }
            });
        };
    }

    public onClose() {
        this.closeWidget.emit(false);
        if (this.hasSavedPackages) {
            this.shipmentService.shipmentAdded();
            this.hasSavedPackages = false;
        }
        this.clearFormData();
        if (this.saveAndContinue) {
            this.showAWBDialogEvent.emit(JSON.parse(JSON.stringify(this.packagesIds)));
        } else if (this.directPrint) {
            const id = this.packagesIds[0];
            this.printReport(id);
        }

        this.packagesIds = [];
        this.deliveryPrice = -1;
        this.isSubmittingPackage = false;
        this.showOriginal = false;
    }

    public clearFormData() {
        this.addPackageForm.get('receiverInfo').reset();
        this.addPackageForm.get('cost').reset();
        this.addPackageForm.get('quantity').reset();
        this.addPackageForm.get('notes').reset();
        this.packageBarcode = null;
    }

    public printReport(pkgId) {
        if (!pkgId) {
            return;
        }
        this.shipmentService.downloadPdf(pkgId).subscribe((res: any) => {
                this.shipmentService.getPackagePDFBlob(res.url)
                    .subscribe(
                        (response) => { // download file
                            const blobUrl = URL.createObjectURL(response);
                            const iframe = document.createElement('iframe');
                            iframe.style.display = 'none';
                            iframe.src = blobUrl;
                            document.body.appendChild(iframe);
                            iframe.contentWindow.print();
                        });
            },
            error => {
                console.log(error);
            });
    }

    public getCreatedDate() {
        if (this.companyId === 64 && !this.isEditMode) {
            return new Date();
        } else {
            return null;
        }
    }

    public initAddPackageForm() {
        this.addPackageForm = this.formBuilder.group({
            senderInfo: [null, Validators.required],
            receiverInfo: [null, Validators.required],
            pickupPoint: [null],
            pickupPointAddressLine1: [null],
            deliveryPoint: [null],
            deliveryPointAddressLine1: [null],
            packageContents: [],
            notes: [''],
            cost: [''],
            quantity: [''],
            createdDate: [this.getCreatedDate()],
            directPrint: [true],
            onlySave: [false],
            saveAndContinue: [false],
        });
    }

    public setAddressLineValidator(controlName) {
        const addressControlName = controlName === 'deliveryPointAddressLine1' ? 'deliveryPoint' : 'pickupPoint';
        if (this.addPackageForm.controls[addressControlName].value) {
            this.addPackageForm.controls[controlName].setValidators(Validators.required);
        } else {
            this.addPackageForm.controls[controlName].clearValidators();
        }
        this.addPackageForm.controls[controlName].updateValueAndValidity();
    }

    private preFillData(data) {
        let companyCityName = 'city';
        let companyVillageName = 'village';
        let companyRegionName = 'region';
        if (this.isArabic) {
            companyCityName = 'arabicCityName';
            companyVillageName = 'arabicVillageName';
            companyRegionName = 'arabicRegionName';
        }
        this.addPackageForm.patchValue({
            senderInfo: {
                firstName: data.senderFirstName,
                lastName: data.senderLastName,
                fullName: `${data.senderFirstName} ${data.senderLastName}`,
                phone: data.senderPhone,
                phone2: data.senderPhone2,
                businessName: data.senderBusinessName,
                address: {
                    villageId: this.companyInfo.address.villageId,
                    label: this.companyInfo.address[companyVillageName] + ' - ' + this.companyInfo.address[companyCityName] + ' - ' + this.companyInfo.address[companyRegionName],
                    cityId: this.companyInfo.address.cityId,
                    regionId: this.companyInfo.address.regionId,
                    country: this.companyInfo.address.country
                }
            },
            receiverInfo: {
                id: data.customerId,
                firstName: data.receiverFirstName,
                lastName: data.receiverLastName,
                fullName: `${data.receiverFirstName} ${data.receiverLastName}`,
                businessName: data.businessReceiverName,
                phone: data.receiverPhone,
                phone2: data.receiverPhone2,
                authorizedGovRegistrationNumber: data.receiverAuthorizedGovRegistrationNumber,
                address: {
                    villageId: data.destinationAddress.villageId,
                    village: data.destinationAddress.village,
                    label: data.destinationAddress.village + ' - ' + data.destinationAddress.city + ' - ' + data.destinationAddress.region,
                    cityId: data.destinationAddress.cityId,
                    city: data.destinationAddress.city,
                    regionId: data.destinationAddress.regionId,
                    region: data.destinationAddress.region,
                    country: data.destinationAddress.country,
                    addressLine1: data.destinationAddress.addressLine1
                }
            },
            pickupPoint: {
                ...data.originAddress,
                label: data.originAddress.village + ' - ' + data.originAddress.city + ' - ' + data.originAddress.region
            },
            pickupPointAddressLine1: data.originAddress.addressLine1,
            deliveryPoint: {
                ...data.destinationAddress,
                label: data.destinationAddress.village + ' - ' + data.destinationAddress.city + ' - ' + data.destinationAddress.region
            },
            deliveryPointAddressLine1: data.destinationAddress.addressLine1,
            packageContents: data.packageContents,
            notes: data.notes,
            cost: data.cost,
            quantity: data.quantity,
            createdDate: data.createdDate
        });

        if (data.packageContents) {
            this.selectedPackageContents = data.packageContents;
        }
    }

    setDate(dateFormControl, numberOfDays) {
        const date = new Date();
        date.setDate(date.getDate() + numberOfDays);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.addPackageForm.controls[dateFormControl].setValue(new Date(date));
        this.addPackageForm.controls[dateFormControl].updateValueAndValidity();
    }

    public getStores(event?) {
        let query = '?page=1&pageSize=20';

        if (event && event.query) {
            query += `&search=${event.query}`;
        }
        this.receiverLoading = true;

        this.usersService.getCustomers(query).subscribe(
            (response: any) => {
                this.storesList = response.customers;
                this.receiverLoading = false;
            }, () => {
                this.receiverLoading = false;
            }
        );
    }

    public chooseStore() {
        this.getDeliveryPrice();
    }

    public updateFormValue(controlName, value) {
        this.addPackageForm.controls[controlName].setValue(value);
        this.addPackageForm.controls[controlName].updateValueAndValidity();
    }

    public getDeliveryPrice() {
        const selectedStoreInfo = this.addPackageForm.get('storeInfo').value;
        if (!selectedStoreInfo || !this.selectedReceiverAddress) {
            return;
        }
        this.deliveryPrice = -1;
        const queryParams = {
            originRegionId: selectedStoreInfo.address.regionId,
            destinationRegionId: this.selectedReceiverAddress.regionId,
            customerId: selectedStoreInfo.id
        };

        this.shipmentService.getDeliveryPrice(queryParams).subscribe(
            (response: any) => {
                this.deliveryPrice = response.cost;
                this.updateFormValue('freight', this.deliveryPrice);
                this.addPackageForm.get('freight').updateValueAndValidity();
            }, (error) => {
                console.error(error);
            }
        );
    }

    public editStoreName() {

        if (!this.addPackageForm.get('storeInfo').value) {
            this.storeName.focusInput();
            this.storeName.search(null, '');
            return;
        }

        this.editSenderAddress = true;
        this.storeName.focusInput();
        const searchTerm = this.addPackageForm.get('storeName').value;
        this.storeName.search(null, searchTerm);
    }

    public showCreatedDate() {
        return this.companyId === 64 && !this.isEditMode;
    }

    public selectSavingOption(inputName) {
        switch (inputName) {
            case 'directPrint':
                this.addPackageForm.patchValue({
                    ['directPrint']: true,
                    ['onlySave']: false,
                    ['saveAndContinue']: false
                });
                break;
            case 'onlySave':
                this.addPackageForm.patchValue({
                    ['directPrint']: false,
                    ['onlySave']: true,
                    ['saveAndContinue']: false
                });
                break;
            case 'saveAndContinue':
                this.addPackageForm.patchValue({
                    ['directPrint']: false,
                    ['onlySave']: false,
                    ['saveAndContinue']: true
                });
                break;
        }
    }

    public submitForm() {

        if (this.isSubmittingPackage) {
            return;
        }

        this.isSubmittingPackage = true;

        if (this.addPackageForm.invalid) {
            this.utilitiesService.validateAllFormFields(this.addPackageForm);
            this.isSubmittingPackage = false;
            return;
        }

        const data = this.addPackageForm.getRawValue();
        const body = {
            pkg: {
                // Sender
                senderName: data.senderInfo.fullName,
                senderPhone: data.senderInfo.phone,
                senderPhone2: data.senderInfo.phone2,
                businessSenderName: data.senderInfo.businessName,

                // Reciever
                customerId: data.receiverInfo.id,
                receiverName: data.receiverInfo.fullName,
                businessReceiverName: data.receiverInfo.businessName,
                receiverAuthorizedGovRegistrationNumber: data.receiverInfo.receiverAuthorizedGovRegistrationNumber,
                receiverPhone: data.receiverInfo.phone,
                receiverPhone2: data.receiverInfo.phone2,

                // pakcage details
                shipmentType: 'REGULAR',
                cost: data.cost !== '' ? data.cost : null,
                quantity: data.quantity !== '' ? data.quantity : null,
                notes: data.notes,
                createdDate: data.createdDate,
                packageContent: data.packageContents
            },
            originAddress: {
                country: 'Palestine',
                regionId: data.senderInfo.address.regionId,
                cityId: data.senderInfo.address.cityId,
                villageId: data.senderInfo.address.villageId,
                addressLine1: data.senderInfo.address.addressLine1
            },
            destinationAddress: {
                country: 'Palestine',
                regionId: data.receiverInfo.address.regionId,
                cityId: data.receiverInfo.address.cityId,
                villageId: data.receiverInfo.address.villageId,
                addressLine1: data.receiverInfo.address.addressLine1,
                longitude: data.receiverInfo.address.longitude,
                latitude: data.receiverInfo.address.latitude
            }
        };
        if (this.addPackageForm.controls['pickupPoint'].value) {
            const pickupPoint = this.addPackageForm.controls['pickupPoint'].value;
            body['originAddress'] = {
                country: 'Palestine',
                regionId: pickupPoint.regionId,
                cityId: pickupPoint.cityId,
                villageId: pickupPoint.villageId,
                addressLine1: data.pickupPointAddressLine1
            };
        }

        if (this.addPackageForm.controls['deliveryPoint'].value) {
            const deliveryPoint = this.addPackageForm.controls['deliveryPoint'].value;
            body['destinationAddress'] = {
                country: 'Palestine',
                regionId: deliveryPoint.regionId,
                cityId: deliveryPoint.cityId,
                villageId: deliveryPoint.villageId,
                addressLine1: data.deliveryPointAddressLine1,
                longitude: data.longitude,
                latitude: data.latitude
            };
        }

        if (this.isEditMode) {
            const editBody = {...body.pkg};
            editBody['destinationAddress'] = body.destinationAddress;
            editBody['originAddress'] = body.originAddress;
            this.shipmentService.editShipment(editBody, this.editedPackageId).subscribe(
                (response) => {
                    this.deliveryPrice = -1;
                    this.translate.get('ALERTS.SHIPMENT_EDITED_SUCCESSFULLY').subscribe(
                        (translateValue) => {
                            this.messageService.add({severity: 'success', detail: translateValue});
                        }
                    );
                    this.clearFormData();
                    this.addPackageSideBar.hide();
                    this.packagesIds.push(this.editedPackageId);
                    this.shipmentService.shipmentAdded(this.isEditMode);
                    this.isSubmittingPackage = false;
                }, (error) => {
                    console.error(error);
                    this.isSubmittingPackage = false;
                }
            );
        } else {
            this.shipmentService.addNewShipment(body).subscribe(
                (response) => {
                    this.translate.get('ALERTS.SHIPMENT_ADDED_SUCCESSFULLY').subscribe(
                        (translateValue) => {
                            this.messageService.add({severity: 'success', detail: translateValue});
                        }
                    );
                    this.postAddPackage(data, response);
                }, (error) => {
                    console.error(error);
                    this.isSubmittingPackage = false;
                }
            );

        }
    }

    postAddPackage(data, response) {
        this.clearFormData();
        this.packagesIds.push(response['id']);
        this.directPrint = data.directPrint;
        this.onlySave = data.onlySave;
        this.saveAndContinue = data.saveAndContinue;
        if (!data.saveAndContinue) {
            this.addPackageSideBar.hide();
            this.shipmentService.shipmentAdded();
        } else {
            this.addPackageForm.patchValue({
                directPrint: this.directPrint,
                onlySave: this.onlySave,
                saveAndContinue: this.saveAndContinue
            });
            this.hasSavedPackages = true;
        }
        this.deliveryPrice = -1;
        this.isSubmittingPackage = false;
    }

    fillSenderInfo(address = null) {
        const regionName = this.isArabic ? this.companyInfo.address.arabicRegionName : this.companyInfo.address.region;
        const cityName = this.isArabic ? this.companyInfo.address.arabicCityName : this.companyInfo.address.city;
        const villageName = this.isArabic ? this.companyInfo.address.arabicVillageName : this.companyInfo.address.village;
        this.addPackageForm.get('senderInfo').patchValue({
            firstName: address ? address.firstName : this.companyInfo.name,
            lastName: address ? address.lastName : '',
            fullName: address ? address.fullName : this.companyInfo.name,
            businessName: address ? address.businessName : this.companyInfo.businessName,
            phone: address ? address.phone1 : this.companyInfo.phone,
            address: {
                label: address ? `${address.address.village} - ${address.address.city} - ${address.address.region}` : `${villageName} - ${cityName} - ${regionName}`,
                villageId: address ? address.address.villageId : this.companyInfo.address.villageId,
                cityId: address ? address.address.cityId : this.companyInfo.address.cityId,
                regionId: address ? address.address.regionId : this.companyInfo.address.regionId,
                country: address ? address.address.country : this.companyInfo.country,
                addressLine1: address ? address.address.addressLine1 : this.companyInfo.address.addressLine1
            }
        });
    }

    setPackageContents(event) {
        this.addPackageForm.get('packageContents').patchValue(event.contents);
    }
}
