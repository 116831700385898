import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {HubsService} from '../../../shared/services/hubs.service';
import {UsersService} from '../../../users/services/users.service';
import {MessageService} from 'primeng/api';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {ColumnDef, COLUMNS_TYPE, GenericTableComponent} from '../../../shared/components/generic-table/generic-table.component';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {ShipmentsService} from '../../../shipment/services/shipments.service';

@Component({
  selector: 'app-create-invoice-for-multi-customer',
  templateUrl: './create-invoice-for-multi-customer.component.html',
  styleUrls: ['./create-invoice-for-multi-customer.component.scss']
})
export class CreateInvoiceForMultiCustomerComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {

  form: FormGroup;
  currentLang = 'en';
  isLoading = false;


  customersLoading = false;
  customersSubject = new Subject<string>();
  customersList = [];


  dateRangPickerOptions;
  @ViewChild('datepicker') datepicker;
  @ViewChild('table') table : GenericTableComponent;
  filterParams = {};
  columnsDef: ColumnDef[];
  tableConfig: { url: string | any, preRequestHandler: any };
  customers = [];
  private startDate;
  private endDate;
  private resetPreSelected = false;

  constructor(private fulfilmentService: FulfilmentService,
              private activeModal: NgbActiveModal,
              private translateService: TranslateService,
              private hubsService: HubsService,
              private usersService: UsersService,
              private messageService: MessageService,
              private datePipe: DatePipe,
              private shipmentService: ShipmentsService,
              private dateRangePickerService: DateRangePickerService,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;

    this.subscriptions.push(this.customersSubject
        .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
        .pipe(distinctUntilChanged())
        .subscribe((search: string) => {
          this.getCustomers({query: search});
        }));

    this.initColumnDef();

    this.tableConfig = {
      url: false,
      preRequestHandler: (req: {
        params: any; body: any; url: any;
      }) => {
      }
    };
    this.initForm();
    this.setDateRangePickerOptions();
  }

  private initColumnDef() {
    this.columnsDef = [
      {
        header: 'ADMINISTRATION.CUSTOMER_NAME',
        field: 'customerName',
        tdType: COLUMNS_TYPE.NORMAL
      },
      {
        header: 'MANAGE_COD_CUSTOMERS.COD_SUM',
        field: 'totalCod',
        tdType: COLUMNS_TYPE.NORMAL
      },
      {
        header: 'MANAGE_COD_CUSTOMERS.COST_SUM',
        field: 'totalCost',
        tdType: COLUMNS_TYPE.NORMAL
      },
      {
        header: 'ACCOUNTING.MASS_REPORTS_NUMBER',
        field: 'massCodPkgsNumber',
        tdType: COLUMNS_TYPE.NORMAL
      },
      {
        header: 'ACCOUNTING.SORTED_COD_PACKAGES',
        field: 'packagesNumber',
        tdType: COLUMNS_TYPE.NORMAL
      }
    ];
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getCustomers(event?) {
    const params = {
      page: 1,
      pageSize: 100,
      utcOffset: moment().utcOffset().toString(),
      isInvoice: true
    };

    if (event && event.query) {
      params['search'] = event.query;
    }
    if (this.startDate) {
      params['fromDate'] = this.transformDate(this.startDate);
    }
    if (this.endDate) {
      params['toDate'] = this.transformDate(this.endDate);
    }
    this.customersLoading = true;
    this.usersService.getCustomersDropDownForInvoice(params).subscribe(
        (customers: any) => {
          this.customersList = customers.map((item) => {
            return {
              ...item,
              label: item.customerName + ' (' + item.massCodPkgsNumber + ' ' + (this.translateService.instant('ACCOUNTING.MASS_REPORTS_NUMBER')) + ')'
            };
          });

          if (this.resetPreSelected) {
            this.handlePreSelectedCustomers();
            this.resetPreSelected = false;
          }
          this.customersLoading = false;
        }, () => {
          this.customersLoading = false;
        }
    );
  }
  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  private initForm() {
    this.form = this.formBuilder.group({
      customer: ['', Validators.required],
    });
  }


  submitForm() {
    this.isLoading = true;
    const formData = this.form.getRawValue();
    const body = {
      ids: formData.customer,
    };
    const params = {};

    if (this.startDate) {
      params['fromDate'] = this.transformDate(this.startDate);
    }
    if (this.endDate) {
      params['toDate'] = this.transformDate(this.endDate);
    }

    this.shipmentService.createInvoices(body, params).subscribe(
        () => {
          this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
          this.activeModal.close({isSuccess: true});
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );

  }

  closeModal(isSuccess?) {
    if (isSuccess) {
      this.activeModal.close({isSuccess: isSuccess});
    }
    this.activeModal.close();
  }

  chooseStore($event) {
    this.customers = this.form.getRawValue().customer.map(id => this.customersList.find(customer => customer.customerId === id));
    this.table.setData(this.customers);
  }


  onDateSelected($event: any) {
    this.startDate = $event.start;
    this.endDate = $event.end;
    this.resetPreSelected = true;
    this.getCustomers();
  }

  setDateRangePickerOptions() {
    this.dateRangePickerService.getDateRangePickerOptions(3).then(
        options => {
          this.dateRangPickerOptions = options;
        }
    );
  }


  private handlePreSelectedCustomers() {
    this.customers = this.form.getRawValue().customer || [];
    const newCustomers = this.customersList.filter(cl => {
      return !!this.customers.find(value => {
        return cl.customerId === value.customerId;
      });
    });
    this.customers = newCustomers;
    this.form.get('customer').patchValue(this.customers);
    this.table.setData(this.customers);
  }
}
