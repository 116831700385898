<p-toast></p-toast>
<div class="close-icon" (click)="closeModal()"><img src="../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}" appBarcodeScanner (barcodeRead)="barcodeReader($event)">
    <div class="row d-inline-flex main-container">
        <!--        <div id="submitting-container" *ngIf="isLoading">-->
        <!--            <p-progressSpinner> </p-progressSpinner>-->
        <!--        </div>-->
        <div *ngIf="hubCodType === 'RECEIVED' || !hubCodType" class="containers-and-drivers"
             [ngClass]="{'right-border': currentLang === 'en'}">
            <ng-container *ngIf="!isHubPackages; else hubsList">
                <div *ngIf="!isShelvePackages" class="carried-packages-title row">
                    <div *ngIf="!isDetectDriverTrack"
                         class="label">{{'CONTAINERS_MANAGEMENT.CARRIED_PACKAGES' | translate | packageTitle}}</div>
                    <div *ngIf="isDetectDriverTrack"
                         class="label">{{'CONTAINERS_MANAGEMENT.DETECT_DRIVER_TRACK' | translate}}</div>
                </div>
                <div class="shelve-packages-title-container">
                    <div *ngIf="isShelvePackages" class="carried-packages-title d-flex">
                        <div class="label">{{ 'BRANCHES.PACKAGES' | translate | packageTitle}}</div>
                        <div class="shelve-title-hidden-space"></div>
                        <div class="packages-count-badge">
                            <span>{{totalRecordsNo}}</span>
                        </div>
                    </div>
                    <div *ngIf="isShelvePackages" class="shelve-hub-name">
                        <span>{{ ('BRANCHES.BRANCH' | translate) + ': ' + hub.name }}</span>
                    </div>
                </div>
                <div *ngIf="!isShelvePackages" class="containers-drivers-list">
                    <div *ngIf="!isDetectDriverTrack" class="tabs-row">
                        <div class="tabs row">
                            <div class="tab containers-tab d-inline-flex col-6" (click)="setSelectedTab('CONTAINERS')"
                                 [ngClass]="{'is-containers-tab': selectedTab === 'CONTAINERS'}">
                                {{'CONTAINERS_MANAGEMENT.VEHICLES' | translate}}
                            </div>
                            <div class="tab drivers-tab d-inline-flex col-6" (click)="setSelectedTab('DRIVERS')"
                                 [ngClass]="{'is-drivers-tab': selectedTab === 'DRIVERS'}">
                                {{'CONTAINERS_MANAGEMENT.DRIVERS' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isDetectDriverTrack" class="choose-driver">
                        <span>{{'DASHBOARD.SELECT_DRIVER' | translate}}</span>
                    </div>
                    <div class="list-container" (scroll)="onScrollContainersList($event)">
                        <div *ngIf="selectedTab === 'CONTAINERS' && containersList.length">
                            <div class="row list-item" (click)="onSetContainerId(container.id)"
                                 [ngClass]="{'selected-container-driver': containerId === container.id}"
                                 *ngFor="let container of containersList">
                                <div class="driver-container-name">{{container.name}} ({{container.brand}})</div>
                            </div>
                        </div>
                        <div *ngIf="!containersList.length && !isDetectDriverTrack"
                             class="containers-drivers-loader-container">
                            <div class="loader"><i class="fas fa-spinner fa-spin"></i></div>
                        </div>
                        <div *ngIf="selectedTab === 'DRIVERS' && driversList.length">
                            <div class="row list-item" (click)="onSetDriverId(driver.id)"
                                 [ngClass]="{'selected-container-driver': driverId === driver.id}"
                                 *ngFor="let driver of driversList">
                                <div class="driver-container-name">{{driver.name}}</div>
                            </div>
                        </div>
                        <div *ngIf="!driversList.length" class="containers-drivers-loader-container">
                            <div class="loader"><i class="fas fa-spinner fa-spin"></i></div>
                        </div>
                    </div>
                </div>

                <!--shelves list-->
                <div *ngIf="isShelvePackages" class="containers-drivers-list shelves-list-container">
                    <div class="list-container" (scroll)="onScrollContainersList($event)">
                        <div *ngIf="shelves.length">
                            <div class="shelve-item">
                                <div class="row"
                                     (click)="onSetShelveId(shelve.id)"
                                     *ngFor="let shelve of shelves">
                                    <div class="shelve-info-container"
                                         [ngClass]="{'selected-shelve': shelveId === shelve.id}">
                                        <div class="shelve-name">
                                            <span>{{shelve.label}}</span>
                                        </div>
                                        <div class="shelve-barcode-container shelve-info  d-flex">
                                            <div class="barcode-icon">
                                                <img src="../../assets/icons/colored-barcode-scanner.svg" alt="icon"/>
                                            </div>
                                            <div style="width: 7px"></div>
                                            <div class="shelve-barcode">
                                                <span>{{shelve.barcode}}</span>
                                            </div>
                                        </div>
                                        <div class="address-container d-flex">
                                            <div class="address-icon">
                                                <img src="../../assets/icons/pin.svg" alt="icon"/>
                                            </div>
                                            <div style="width: 7px"></div>
                                            <div class="address-label">
                                                <span>{{shelve.destinationRegion ? shelve.destinationRegion : '' + ' ' + (shelve.destinationCity ? shelve.destinationCity : '')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--                            <ng-container *ngIf="shelvesLoader || true">-->
                                <!--                                <app-skeleton-loader *ngFor="let line of [0,1,]" [isSingleLoader]="true" [type]="2"></app-skeleton-loader>-->
                                <!--                            </ng-container>-->
                            </div>
                        </div>
                        <div *ngIf="!shelves.length" class="containers-drivers-loader-container">
                            <div class="loader"><i class="fas fa-spinner fa-spin"></i></div>
                        </div>
                        <div *ngIf="shelvesLoader" class="shelve-item lazy-load-shelves-loader">
                            <div class="loader"><i class="fas fa-spinner fa-spin"></i></div>
                        </div>
                    </div>
                </div>

                <!--      end shelves list      -->
            </ng-container>

            <ng-template #hubsList>
                <div class="hubs-container d-flex flex-column h-100">
                    <div class="d-flex align-items-center enter-barcode" *ngIf="isReceiveHubCod">
                        <div class="enter-barcode-title">
                            {{'RECEIVE_FROM_DRIVER.ENTER_BARCODE' | translate}}
                        </div>
                        <div class="icon">
                            <img src="../../../assets/icons/barcode-scanner.svg" alt="">
                        </div>
                        <div class="barcode-input">
                            <input type="text" [(ngModel)]="barcodeInput">
                        </div>
                        <div class="enter-barcode-btn flex-2d-center cursor-pointer"
                             [ngClass]="{'disabled-btn disabled':!barcodeInput.length}" (click)="barcodeReader(barcodeInput)">
                            {{'ACTIONS.ADD' | translate}}
                        </div>
                    </div>
                  <div class="hubs-list-container">
                      <div class="hubs-title d-flex">{{'BRANCHES.TITLE' | translate}}</div>
                      <div class="list" (scroll)="onScrollHubsList($event)">

                          <div class="hub-item cursor-pointer" *ngFor="let hub of hubs">
                              <div class="hub-name d-flex" (click)="onSelectHub(hub)"
                                   [ngClass]="{'selected-hub': hubId === hub.hubId}">
                                  <div class="icon">
                                      <i class="fas fa-building"></i>
                                  </div>
                                  <div class="name">{{hub.hubName}}</div>
                              </div>
                          </div>
                          <ng-container *ngIf="isGettingHubs">
                              <div class="hub-loader" *ngFor="let category of [1,2,3,4,5,6,7,8]">
                                  <app-skeleton-loader [type]="2"></app-skeleton-loader>
                              </div>
                          </ng-container>
                      </div>
                  </div>

                </div>
            </ng-template>

        </div>
        <div>
            <div class="packages-table">
                <div class="shipment-body" [ngClass]="{'receive-hub-cod-shipment-body': isHubPackages}" (scroll)="onScrollTable($event)">
                    <div class="h-100">
                        <p-table [reorderableColumns]="true" class="d-flex h-100" responsiveLayout="scroll"
                                 [value]="packages" [autoLayout]="true"  styleClass="shipments-table receive-from-driver-table"
                                 [ngClass]="{'hubs-packages-table': isHubPackages}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th *ngIf="isReceiveHubCod">
                                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                                    (onChange)="onToggleSelectAll($event)">
                                        </p-checkbox>
                                    </th>
                                    <th *ngIf="isDetectDriverTrack"></th>
<!--                                    <th pReorderableColumn></th>-->
                                    <th pReorderableColumn>{{"SHIPMENT.TABLE.NUMBER" | translate | packageTitle}}</th>
                                    <th *ngIf="!isHubPackages"
                                        pReorderableColumn>{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.CUSTOMER_NAME" | translate }}</th>
                                    <th pReorderableColumn>
                                        {{"MANAGE_SHIPMENTS.COST" | translate}}
                                        <span class="currency" *ngIf="currency !== 'NIS'">{{currency}}</span>
                                        <span *ngIf="currency === 'NIS'" style="font-size: 10px !important;"><i
                                                class="fas fa-shekel-sign"></i></span>
                                    </th>
                                    <th pReorderableColumn>
                                        {{"MANAGE_SHIPMENTS.COD" | translate}}
                                        <span class="currency" *ngIf="currency !== 'NIS'">{{currency}}</span>
                                        <span *ngIf="currency === 'NIS'" style="font-size: 10px !important;"><i
                                                class="fas fa-shekel-sign"></i></span>
                                    </th>
                                    <th pReorderableColumn>{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.FROM" | translate}}</th>
                                    <th pReorderableColumn>{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.TO" | translate}}</th>
                                    <th *ngIf="!isHubPackages"
                                        pReorderableColumn>{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.BOOKING_DATE" | translate}}</th>
                                    <th *ngIf="!isHubPackages" pReorderableColumn class="status-dropdown">
                                        <app-drop-down  appendTo="body"
                                                        [label]="'SHIPMENT.TABLE.STATUS'"
                                                        [isFilter]="true"
                                                [(ngModel)]="selectedShipmentStatus"
                                                [options]="shipmentStatusList"
                                                filter="true"
                                                (whenChange)="onStatusChanged()">
                                        </app-drop-down>
                                    </th>
                                    <th *ngIf="!isHubPackages"
                                        pReorderableColumn>{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.POSTPONED_DATE" | translate}}</th>
                                    <th *ngIf="!isHubPackages" pReorderableColumn></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <div class="empty-content" *ngIf="!isLoading">
                                    <app-no-data-available-component></app-no-data-available-component>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="body" let-package let-index="rowIndex">
                                <tr class="shipment-row" [ngClass]="{'scanned-row': package.isSelected}" [pReorderableRow]="index">
                                    <td *ngIf="isReceiveHubCod">
                                        <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                                    (onChange)="onToggleSelection($event)"
                                                    (click)="$event.stopPropagation()">
                                        </p-checkbox>
                                    </td>
                                    <td *ngIf="isDetectDriverTrack">
                                        <div class="d-flex order-package-container">
                                            <div class="drag-and-order-icon">
                                                <span class="pi pi-bars" pReorderableRowHandle></span>
                                            </div>
                                            <div class="input-order-number d-flex">
                                                <div class="plus"
                                                     (click)="increaseDecreaseOrderNumber('PLUS', package)">+
                                                </div>
                                                <div class="order-input">
                                                    <input type="number" appInputDebounced
                                                           (inputDebounced)="orderPackages(package)"
                                                           [(ngModel)]="package.orderNumber">
                                                </div>
                                                <div class="minus"
                                                     (click)="increaseDecreaseOrderNumber('MINUS', package)">-
                                                </div>
                                            </div>
                                        </div>
                                    </td>
<!--                                    <td></td>-->
                                    <td class="barcode-number">
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon">
                                                    <img src="../../assets/icons/package_card/box.svg" alt="">
                                                </div>
                                                <div class="label">{{"SHIPMENT.TABLE.NUMBER" | translate | packageTitle}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="d-inline-flex">
                                                    <div class="item-barcode-number barcode-value">
                                                        #{{package.barcode}}
                                                    </div>
                                                    <div style="width: 10px;"></div>
                                                    <div>
                                                        <img src="../../assets/icons/track_package/copy.svg" alt=""
                                                             (click)="copyMessage($event,null, package.barcode)"
                                                             data-toggle="tooltip" data-placement="top" title="Copy"
                                                             class="copy_icon"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="!isHubPackages">
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon">
                                                    <img src="../../assets/icons/package_card/user.svg" alt="">
                                                </div>
                                                <div class="label">{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.CUSTOMER_NAME" | translate }}</div>
                                            </div>
                                            <div class="value">
                                                <div class="text-value">{{package.customerName}}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon"><img src="../../assets/icons/package_card/invoice.svg"
                                                                       alt=""></div>
                                                <div class="label">{{"MANAGE_SHIPMENTS.COST" | translate}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="number-value">
                                                    {{package.cost}}
                                                    <span class="currency"
                                                          *ngIf="currency !== 'NIS'">{{currency}}</span>
                                                    <span *ngIf="currency === 'NIS'"
                                                          style="font-size: 10px !important;"><i
                                                            class="fas fa-shekel-sign"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon"><img src="../../assets/icons/package_card/invoice.svg"
                                                                       alt=""></div>
                                                <div class="label">{{"MANAGE_SHIPMENTS.COD" | translate}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="number-value">
                                                    {{package.cod}}
                                                    <span class="currency"
                                                          *ngIf="currency !== 'NIS'">{{currency}}</span>
                                                    <span *ngIf="currency === 'NIS'"
                                                          style="font-size: 10px !important;"><i
                                                            class="fas fa-shekel-sign"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon">
                                                    <img src="../../assets/icons/package_card/location.svg" alt="">
                                                </div>
                                                <div class="label">
                                                    {{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.FROM" | translate}}
                                                </div>
                                            </div>
                                            <div class="value">
                                                <div class="text-value">
                                                    <ng-container *ngIf="isHubPackages; else defaultOrigin">
                                                        {{package.senderCity}}
                                                    </ng-container>
                                                    <ng-template #defaultOrigin>
                                                        {{package.originAddress.city }}
                                                        - {{package.originAddress.village }}
                                                        - {{package.originAddress.addressLine1}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon"><img
                                                        src="../../assets/icons/package_card/location.svg" alt=""></div>
                                                <div class="label">{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.TO" | translate}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="text-value">
                                                    <ng-container *ngIf="isHubPackages; else defaultDestination">
                                                        {{package.receiverCity}}
                                                    </ng-container>
                                                    <ng-template #defaultDestination>
                                                        {{package.destinationAddress.city }}
                                                        - {{package.destinationAddress.village }}
                                                        - {{package.destinationAddress.addressLine1}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="!isHubPackages">
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon"><img
                                                        src="../../assets/icons/package_card/calendar.svg" alt=""></div>
                                                <div class="label">{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.BOOKING_DATE" | translate}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="date-value">
                                                    {{package.createdDate | date:'dd/MM/yyyy HH:mm'}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="!isHubPackages">
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon"></div>
                                                <div class="label">{{"SHIPMENT.TABLE.STATUS" | translate}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="d-inline-flex">
                                                    <!--                                        <span class="ui-column-title">{{"SHIPMENT.STATUS" | translate}}</span>-->
                                                    <app-shipment-status [shipment]="package"
                                                                         [width]="144"></app-shipment-status>
                                                </div>
                                                <div>
                                                    <ng-container [ngSwitch]="package.status">
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">
                                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}
                                                            : {{package.driverName}}
                                                        </div>
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'">
                                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}
                                                            : {{package.driverName}}
                                                        </div>
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'">
                                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}
                                                            : {{package.driverName}}
                                                        </div>
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'">
                                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}
                                                            : {{package.driverName}}
                                                        </div>
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'SCANNED_BY_HANDLER_AND_UNLOADED'">
                                                            {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                                        </div>
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'">
                                                            {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                                        </div>
                                                        <div class="driver-hub-name"
                                                             [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                             *ngSwitchCase="'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'">
                                                            {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                                        </div>
                                                        <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                                            <ng-container [ngSwitch]="package.returnedPackageLocation">
                                                                <div class="driver-hub-name"
                                                                     [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                                     *ngSwitchCase="'IN_HUB'">
                                                                    {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}
                                                                    : {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                                                </div>
                                                                <div class="driver-hub-name"
                                                                     [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                                     *ngSwitchCase="'IN_CAR'">
                                                                    {{'ADMINISTRATION.DRIVER_NAME'|translate}}
                                                                    : {{package.driverName}}
                                                                </div>
                                                                <div class="driver-hub-name"
                                                                     [ngClass]="{'margin-right-30': currentLang === 'en'}"
                                                                     *ngSwitchCase="'DELIVERED_TO_SENDER'">
                                                                    {{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER'|translate}}
                                                                </div>
                                                                <span *ngSwitchDefault></span>
                                                            </ng-container>
                                                        </div>
                                                        <span *ngSwitchDefault></span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="!isHubPackages">
                                        <div class="data-item-container">
                                            <div class="mobile-label hidden-md-up">
                                                <div class="icon"><img
                                                        src="../../assets/icons/package_card/calendar.svg" alt=""></div>
                                                <div class="label">{{"CONTAINERS_MANAGEMENT.CARRIED_PACKAGES_TABLE_HEADER.POSTPONED_DATE" | translate}}</div>
                                            </div>
                                            <div class="value">
                                                <div class="date-value">
                                                    {{package.postponedDeliveryDate | date:'dd/MM/yyyy HH:mm'}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="!isHubPackages"></td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="isLoading">
                                    <td [attr.colspan]="1000">
                                        <div class="shipment-table-loader">
                                            <div class="loader drivers-list-loader">
                                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                            </div>
                                            <div class="loader" *ngIf="packages.length === 0">
                                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                                <app-skeleton-loader type="2"></app-skeleton-loader>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="row bottom-actions">
                    <div class="actions d-inline-flex" [ngClass] ="{'receive-hub-cod-actions': isHubPackages}">
                        <div *ngIf="containerId || isDetectDriverTrack"
                             class="print-action flex-2d-center d-inline-flex"
                             [ngClass]="{'disabled-btn disabled': !packages.length || isExporting || printLoader || isLoading}"
                             (click)="downloadPdf()">
                            <div class="icon">
                                <img *ngIf="!printLoader" src="../../assets/icons/containers-management/print.svg"/>
                                <i *ngIf="printLoader" class="fas fa-spinner fa-pulse"></i>
                            </div>
                            <div class="print-action-hidden-space"></div>
                            <div class="label">{{'QUICK_ADD.CLOSE_OPTIONS.PRINT' | translate}}</div>
                        </div>
                        <div *ngIf="containerId" class="splitter"></div>
                        <div *ngIf="isDetectDriverTrack" class="export-btn-action action cursor-pointer col-xs-12"
                             (click)="confirmDetectDriverTrack(false, true)"
                             [ngClass]="{'disabled-btn disabled': !packages.length || printLoader || isExporting || isLoading}">
                            <div class="icon">
                                <i *ngIf="!isExporting" class="fas fa-file-excel"></i>
                                <i *ngIf="isExporting" class="fas fa-spinner fa-pulse"></i>
                            </div>
                            <div class="action-label">{{"ACTIONS.SAVE_AND_EXPORT" | translate}}</div>
                        </div>

                        <div class="summery-values w-100" *ngIf="isReceiveHubCod">
                            <div class="summery-group w-50">
                                <div class="summery-group-title">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.TOTAL_TITLE' | translate}}</div>
                                <div class="summery-group-block">
                                    <div class="summery-item">
                                        <div class="summery-item-label">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.COUNT' | translate}}</div>
                                        <div class="summery-value-number-badge">{{totalPackagesSummery.count}}</div>
                                    </div>
                                    <div class="summery-item">
                                        <div class="summery-item-label">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.COD_SUM' | translate}}</div>
                                        <div class="summery-value-number-badge">{{totalPackagesSummery.codSum}}</div>
                                    </div>
                                </div>
                                <div class="summery-group-block">
                                    <div class="summery-item">
                                        <div class="summery-item-label">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.COST_SUM' | translate}}</div>
                                        <div class="summery-value-number-badge">{{totalPackagesSummery.costSum}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="summery-group w-50">
                                <div class="summery-group-title">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.SCANNED_TITLE' | translate}}</div>
                                <div class="summery-group-block">
                                    <div class="summery-item">
                                        <div class="summery-item-label">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.COUNT' | translate}}</div>
                                        <div class="summery-value-number-badge">{{selectedPackagesSummery.count}}</div>
                                    </div>
                                    <div class="summery-item">
                                        <div class="summery-item-label">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.COD_SUM' | translate}}</div>
                                        <div class="summery-value-number-badge">{{selectedPackagesSummery.codSum}}</div>
                                    </div>
                                </div>
                                <div class="summery-group-block">
                                    <div class="summery-item">
                                        <div class="summery-item-label">{{'ACCOUNTING.HUBS_ACCOUNTING_SUMMERY.TOTAL_AND_SCANNED_PACKAGES.COST_SUM' | translate}}</div>
                                        <div class="summery-value-number-badge">{{selectedPackagesSummery.costSum}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isDetectDriverTrack || isReceiveHubCod" class="splitter"></div>
                        <div class="done-action flex-2d-center"
                             [ngClass]="{'disabled-btn disabled': disableFinishButton()}"
                             (click)="onFinish()">{{(isReceiveHubCod ? 'ACCOUNTING.RECEIVE': 'CONTAINERS_MANAGEMENT.ACTIONS.DONE') | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
