import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {UsersService} from '../../users/services/users.service';

@Component({
  selector: 'app-edit-driver-earning',
  templateUrl: './edit-driver-earning.component.html',
  styleUrls: ['./edit-driver-earning.component.scss']
})
export class EditDriverEarningComponent implements OnInit {
  public debounceUpdateDriverEarning;
  @Input() package;
  @Output() onResetEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onEditEvent: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(private usersService: UsersService) {
  }

  ngOnInit() {
  }

  public updatePackageCost(event, packageObject, add) {
    packageObject.driverEarning = packageObject.driverEarning + add;
    event.stopPropagation();
    this.editCost(packageObject);
  }

  public editCost(packageObject) {
    if (!this.debounceUpdateDriverEarning) {
      this.debounceUpdateDriverEarning = _.debounce(() => {
        const id = packageObject.id ? packageObject.id : packageObject.packageId;
        if (typeof packageObject.driverEarning === undefined || packageObject.driverEarning === null || packageObject.driverEarning < 0) {
          this.debounceUpdateDriverEarning.cancel();
          this.debounceUpdateDriverEarning = null;
          if (this.onResetEvent) {
            this.onResetEvent.emit({});
          }
          return;
        }
        this.usersService.editDriverEarning(id, packageObject.driverEarning).subscribe(() => {
          this.onEditEvent.emit();
        });
        this.debounceUpdateDriverEarning.cancel();
        this.debounceUpdateDriverEarning = null;
      }, 2000);
    }
    this.debounceUpdateDriverEarning();
  }
}
