<div class="change-package-status-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{title | translate}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal()"></i>
        </div>
    </div>
    <div class="body">
        <form [formGroup]="form">
            <div *ngIf="!notesOnly"  class="d-flex flex-column">

               <div class="drivers-container">
                   <p-autoComplete formControlName="driverId"
                                   type="text"
                                   placeholder="{{'QUICK_ADD.DRIVER' | translate}}"
                                   field="label"
                                   [forceSelection]="true"
                                   [required]="true"
                                   [dropdown]="true"
                                   [suggestions]="drivers"
                                   (completeMethod)="getDrivers($event)"
                                   #driversAc
                   >
                       <ng-template let-driver pTemplate="item">
                           <div class="store-item">
                               <div class="text-ellipsis">{{driver.label}}</div>
                           </div>
                       </ng-template>
                   </p-autoComplete>
               </div>
                <span class="input-label">
                   {{'MANAGE_SHIPMENTS.POSTPONED_DATE' | translate}}
                </span>
                <div class="input-field">
                    <p-calendar formControlName="postponedDate" [minDate]="today" styleClass="d-flex"
                                [inputStyle]="{width: '100%'}" dateFormat="dd/mm/yy"></p-calendar>
                </div>
            </div>
            <app-input-field-component [form]="form" label="GENERAL.NOTES"
                                       controlName="note"
                                       [isRequired]="true">
            </app-input-field-component >
        </form>
    </div>
    <div class="footer d-flex bottom-actions-container">
        <div class="flex-1"></div>
        <input class="btn btn-sm button cancel-button" type="submit" (click)="closeModal()"
               value="{{'ACTIONS.CANCEL' | translate}}">
        <button class="btn-sm action-btn" type="submit" (click)="saveButton()"
                [ngClass]="{'disabled-btn disabled': form.invalid}">
            {{'ACTIONS.SAVE' | translate}}
        </button>
    </div>
