import {Component, HostListener, OnInit} from '@angular/core';
import {STORAGE_KEYS} from '../../../../shared/services/storage/storage.keys';
import {UserService} from '../../../../shared/services/user.service';
import {StorageService} from '../../../../shared/services/storage/storage.service';
import {HubModel} from '../../../../shared/models/hub.model';
import {HubsService} from '../../../../shared/services/hubs.service';

@Component({
    selector: 'app-hubs-accounting-dashboard',
    templateUrl: './hubs-accounting-dashboard.component.html',
    styleUrls: ['./hubs-accounting-dashboard.component.scss']
})
export class HubsAccountingDashboardComponent implements OnInit {
    dateRangPickerOptions = {};
    dateRange: any = {};
    currency = '';
    cities = [
        {cityName: 'رام الله'},
        {cityName: 'نابلس'},
        {cityName: 'طولكرم'},
        {cityName: 'جنين'},
        {cityName: 'الخليل'},
        {cityName: 'بيت لحم'},
        {cityName: 'اريحا'},
        {cityName: 'سلفيت'}
    ];
    selectedHub: HubModel;
    isGettingHubs = false;
    isGettingExportedPackages = false;
    isGettingImportedPackages = false;
    isGettingSummery = false;

    params = {
        hubs: {
            pageSize: 20,
            page: 1
        }
    };

    fetchedHubs: HubModel[] = [];
    hubs: HubModel[] = [];
    totalHubs = 0;

    constructor(private userService: UserService,
                private hubsService: HubsService,
                private storageService: StorageService) {
    }

    ngOnInit() {
        this.currency = this.getCurrency();
        this.fetchHubs();



        // for static  loaders
        this.isGettingExportedPackages = this.isGettingImportedPackages = this.isGettingSummery =  true;
        setTimeout(() => {
            this.isGettingExportedPackages = this.isGettingImportedPackages = this.isGettingSummery = false;
        }, 3000);
    }

    getCurrency() {
        if (this.userService.userInfo && this.userService.userInfo.currency) {
            return this.userService.userInfo.currency;
        }
        return this.storageService.get(STORAGE_KEYS.CURRENCY);
    }

    onShowCalenderRangePicker(e) {
    }

    onHideCalenderRangePicker(e) {
    }

    onDateSelected(e, dateRange) {
    }


    @HostListener('scroll', ['$event'])
    onLazyLoadHubs(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadLazyHubs();
        }
    }

    loadLazyHubs() {
        if (!this.isGettingHubs) {
            this.params.hubs.page++;
            this.fetchHubs();
        }
    }

    fetchHubs() {
        this.fetchedHubs = [];
        if (this.hubs.length === this.totalHubs && this.params.hubs.page > 1) {
            return;
        }
        this.isGettingHubs = true;
        let query = '';
        Object.entries(this.params.hubs).forEach(
            (keyVal, index) => {
                const sign = index === 0 ? '?' : '&';
                query += `${sign}${keyVal[0]}=${keyVal[1]}`;
            }
        );
        this.hubsService.getHubs(query).subscribe(
            (response: any) => {
                this.fetchedHubs = response.hubs;
                this.hubs = [...this.hubs, ...this.fetchedHubs];
                this.totalHubs = response.totalRecordsNo;
                this.isGettingHubs = false;
            }, (error) => {
                this.isGettingHubs = false;
            }
        );
    }

    onSelectHub(hub: HubModel) {
        this.selectedHub = hub;
    }

}
