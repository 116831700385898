<div class="row skeleton-container" [ngClass]="isSingleLoader ? 'single-container' : ''"
     [ngSwitch]="type" *ngIf="display">

    <ng-container *ngSwitchCase="skeletonTypes.CARD">
        <!-- display 3 cards-->
        <ng-container>
            <ng-container *ngFor="let card of (isSingleLoader ? [0] : [0, 1, 2, 3, 4, 5])"  >
                <div [ngClass]="isSingleLoader ? 'col p-0' : 'col-sm-6 col-xs-12 col-md-4 col-lg-4' ">
                    <div class="card-container" [ngClass]="isSingleLoader ? 'single-card' : ''">
                        <div *ngIf="!dashboardCard" class="card-side">
                            <div class="loader-avatar"></div>
                        </div>
                        <div class="card-content">
                            <div class="content-info">
                                <div class="main-title">
                                </div>
                                <div class="sub-title">
                                </div>
                                <ng-container *ngIf="!dashboardCard">
                                    <div class="text-1"></div>
                                    <div class="text-2"></div>
                                    <div class="text-3"></div>
                                </ng-container>

                            </div>
                            <ng-container *ngIf="!dashboardCard">
                                <div class="content-actions">
                                    <div class="action-1"></div>
                                    <div class="action-2"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="skeletonTypes.LINES">
        <div class="col-12 line" *ngFor="let line of [0, 1, 2, 3, 4, 5]">
            <div class="line-container">
                <div class="tiny-part"></div>
                <div class="first-part"></div>
                <div class="second-part"></div>
                <div class="first-part"></div>
                <div class="second-part"></div>
                <div class="first-part"></div>
                <div class="second-part"></div>
                <div class="third-part"></div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="skeletonTypes.SINGLE_LINE">

        <div [ngClass]="{'w-100': true, 'single-line-container':repeatSingleLine > 1}">
            <div class="line-container full-width" *ngFor="let i of Array(repeatSingleLine).fill(0)">
                <div class="full-single-line">
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.DOCUMENT">
        <div class="document-container w-100">
            <div class="icon-container flex-2d-center justify-content-center">
                <img src="../../../../assets/icons/document-pdf.png" alt=""/>
            </div>
            <div class="line-container full-width" *ngFor="let i of [1, 2, 3]">
                <div class="full-single-line">
                </div>
            </div>
        </div>
    </ng-container>
</div>
