import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../users/services/users.service';
import {DatePipe} from '@angular/common';
import {Calendar} from 'primeng/calendar';
import {DRIVER_REPORT_ORDER_BY} from '../../ticketing-system/services/constants';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-choose-driver',
    templateUrl: './choose-driver.component.html',
    styleUrls: ['./choose-driver.component.scss']
})
export class ChooseDriverComponent implements OnInit {
    public isLoading = false;
    public driverId;
    public initialUser;
    public fromDate;
    public toDate;
    public showDates = true;
    public showSortBy = true;
    public showConfig = true;
    public isPickWithDriver = false;
    public buttonLabel = 'ACTIONS.PRINT';
    public isExportInCarPkgs = false;
    public withoutSenderPhone = false;
    public withoutReturnedPackages = false;
    public withoutSenderName = false;
    public withPickupDate = false;
    public withSupplierInvoice = false;
    public withPartnerName = false;
    public withReceiverName = false;
    public isExporting = false;
    public selectedOrderByOption = 'VILLAGES_ALPHABETICALLY';
    public orderByOptions;

    public printType = 'HORIZONTAL_TEMPLATE';
    @ViewChild('fromDateCalendar') fromDateCalendar: Calendar;
    @ViewChild('toDateCalendar') toDateCalendar: Calendar;

    currentLang;
    showExportToExcel = true;
    isAsnDriver = false;

    constructor(private activeModal: NgbActiveModal,
                private usersService: UsersService,
                private translateService: TranslateService,
                private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isLoading = false;
        if (this.isPickWithDriver) {
            this.buttonLabel = 'ACCOUNTING.PICK_WITH_DRIVER';
        }
        this.orderByOptions = this.setOrderByOptions();
    }
    setOrderByOptions() {
        return DRIVER_REPORT_ORDER_BY.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }
    chooseSortByOption($event) {
    }
    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.isLoading = false;
            this.activeModal.close({isSuccess: false});
        }
    }
    public closeModal() {
        this.isLoading = false;
        this.activeModal.close({isSuccess: false});
    }
    public driverSelected(partner) {
        this.driverId = partner;
    }
    public exportToExcel() {
        // this.isLoading = true;
        this.isExporting = true;
        let exportToExcel = this.usersService.exportDriverPackagesToExcel(this.driverId, this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'),
            this.datePipe.transform(this.toDate, 'yyyy-MM-dd'), this.withoutSenderName, this.withoutSenderPhone,
            this.withoutReturnedPackages, this.selectedOrderByOption);
        if (this.isExportInCarPkgs) {
            exportToExcel =  this.usersService.exportDriverInCarPackagesToExcel(this.driverId);
        }
        exportToExcel.subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isExporting = false;
                this.activeModal.close({isSuccess: false});
            },
            error => {
                console.log(error);
                this.isExporting = false;
                this.activeModal.close({isSuccess: false});
            });
    }
    public chooseDriver() {
        if (this.showDates) {
            this.fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
            this.toDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
            this.activeModal.close({isSuccess: true, driverId: this.driverId, fromDate: this.fromDate, toDate: this.toDate,
                withoutSenderPhone: this.withoutSenderPhone, withoutSenderName: this.withoutSenderName,
                withoutReturnedPackages: this.withoutReturnedPackages, printType: this.printType, sortBy: this.selectedOrderByOption,
                withPickupDate: this.withPickupDate, withSupplierInvoice: this.withSupplierInvoice, withReceiverName: this.withReceiverName,
                withPartnerName: this.withPartnerName});
        } else {
            this.activeModal.close({isSuccess: true, driverId: this.driverId});
        }
    }

    public openDateCalendar(dateType: string) {
        const calendar = dateType === 'fromDate' ? this.fromDateCalendar : this.toDateCalendar;
        calendar.showOverlay();
    }
}
