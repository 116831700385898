import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/modules/shared.module';
import {NewHubComponent} from './new-hub/new-hub.component';
import {ManageShelfComponent} from './manage-shelf/manage-shelf.component';
import {ShelfItemComponent} from './manage-shelf/shelf-item/shelf-item.component';
import {NewShelfComponent} from './manage-shelf/new-shelf/new-shelf.component';
import { ShelfPackagesComponent } from './shelf-packages/shelf-packages.component';
import {BranchesManagementComponent} from './branches-management/branches-management.component';
import {BranchCardComponent} from './branches-management/branch-card/branch-card.component';
import {BranchDetailsComponent} from './manage-branches/branch-details/branch-details.component';
import {ShelvesManagementComponent} from './manage-branches/branch-details/shelves-management/shelves-management.component';
import {ShelveCitiesComponent} from './branches-management/branch-card/shelve-cities/shelve-cities.component';
import {ToastModule} from 'primeng/toast';
import {BranchItemComponent} from './manage-branches/branch-item/branch-item.component';

@NgModule({
    declarations: [
        BranchItemComponent,
        BranchesManagementComponent,
        NewHubComponent,
        ManageShelfComponent,
        ShelfItemComponent,
        NewShelfComponent,
        ShelfPackagesComponent,
        BranchCardComponent,
        BranchDetailsComponent,
        ShelvesManagementComponent,
        ShelveCitiesComponent
    ],
    imports: [
        SharedModule,
        
        ToastModule
    ]
})
export class BranchesModule {
}
