<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <form [formGroup]="form">
        <div class="d-flex status-container">
            <div class="label">{{'CHANGE_PACKAGE_STATUS.CHANGE_STATUS_TO' | translate}}</div>
            <div class="hidden-space"></div>
            <div class="flex-1 status">
                <app-input-field-component [form]="form" (changed)="updateForm(form.value.status)"
                                           controlName="status"
                                           controlType="select"
                                           [selectBackgroundColor]="getBackgroundColor(form.value.status)"
                                           [options]="statusOptions"
                                           [isRequired]="true">
                </app-input-field-component>
            </div>
        </div>
        <div *ngIf="companyHadStations.indexOf(companyId) === -1 && !isLcl && requireIsFailedSelection.indexOf(form.value.status) !== -1"
             class="d-flex status-container failed-package-container"
             style="margin-top: 10px;">
            <div class="label failed-package-label">{{'MANAGE_SHIPMENTS.FAILED_PACKAGE' | translate}}</div>
            <div class="d-inline-flex failed-package-switch" style="width: 100%">
                <div [ngClass]="{'disabled-toggle-switch': !form.value['isFailed']}">
                    <p-inputSwitch formControlName="isFailed"></p-inputSwitch>
                </div>
                <div class="switch-number-of-retries-hidden-space"></div>
                <div class="d-flex"
                     *ngIf="requireIsFailedSelection.indexOf(form.value.status) !== -1 && form.value['isFailed']">
                    <div>
                        <app-input-field-component [form]="form"
                                                   placeholder="{{'MANAGE_SHIPMENTS.NUMBER_OF_RETRIES' | translate}}"
                                                   controlName="failuresNumber"
                                                   [isRequired]="true">
                        </app-input-field-component>
                        <div class="top-label">{{'MANAGE_SHIPMENTS.NUMBER_OF_RETRIES' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!this.isHideDeliveredToSenderInChangeStatus && requireIsDeliveredSelection.indexOf(form.value.status) !== -1"
             class="d-flex status-container is-delivered-to-sender-package-container"
             style="margin-top: 10px;">
            <div class="label is-delivered-to-sender-label">{{'CHANGE_PACKAGE_STATUS.DELIVERED_TO_SENDER' | translate}}</div>
            <div class="d-inline-flex is-delivered-to-sender-switch" style="width: 100%">
                <div [ngClass]="{'disabled-toggle-switch': !form.value['isDelivered']}">
                    <p-inputSwitch formControlName="isDelivered"
                                   (onChange)="updateForm(form.value.status)"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div *ngIf="isSaudiCompanies && isToFinalDestinationStatus.indexOf(form.value.status) !== -1"
             class="d-flex status-container is-delivered-to-sender-package-container"
             style="margin-top: 10px;">
            <div class="label is-delivered-to-sender-label">{{'CHANGE_PACKAGE_STATUS.IS_TO_FINAL_DESTINATION' | translate}}</div>
            <div class="d-inline-flex is-delivered-to-sender-switch" style="width: 100%">
                <div [ngClass]="{'disabled-toggle-switch': !form.value['isToFinalDestination']}">
                    <p-inputSwitch formControlName="isToFinalDestination"
                                   (onChange)="updateForm(form.value.status)"></p-inputSwitch>
                </div>
            </div>
        </div>
        <div *ngIf="requirePostponedDeliveryDate.indexOf(form.value.status) !== -1"
             class="d-flex flex-column postponedDate-container">
            <div class="label postponedDate-label">
                {{'MANAGE_SHIPMENTS.POSTPONED_DATE' | translate}}
            </div>
            <div class="input-field">
                <p-calendar appStopPropagation formControlName="postponedDate" [minDate]="today" styleClass="d-flex"
                            [inline]="true"
                            [inputStyle]="{width: '100%'}" dateFormat="dd/mm/yy"></p-calendar>
            </div>
        </div>
        <div *ngIf="requirePostponedDeliveryDate.indexOf(form.value.status) !== -1 || (form.get('isFailed').value && form.get('status').value === 'SCANNED_BY_DRIVER_AND_IN_CAR')" class="d-flex notes-container">
            <div class="label">{{'GENERAL.NOTES' | translate}} <span *ngIf="!form.get('isFailed').value" class="required-star">*</span></div>
            <div class="notes-hidden-space"></div>
            <div class="flex-1 notes">
                <app-input-field-component [form]="form"
                                           controlName="notes"
                                           [isRequired]="!form.get('isFailed').value">
                </app-input-field-component>
            </div>
        </div>
        <div *ngIf="requireDeliveryDate.indexOf(form.value.status) !== -1 &&
                    (form.value.status!=='RETURNED_BY_RECIPIENT' ||  (form.value.status==='RETURNED_BY_RECIPIENT' &&
                    form.value['isDelivered']))"
             class="d-flex flex-column deliveryDate-container">
            <div class="label postponedDate-label">
                {{'MANAGE_SHIPMENTS.DELIVERY_DATE' | translate}}
            </div>
            <div class="input-field">
                <p-calendar appStopPropagation formControlName="deliveryDate" styleClass="d-flex" inline="true" [showTime]="true"
                            [inputStyle]="{width: '100%'}" dateFormat="dd/mm/yy HH:mm" [maxDate]="maxDate"></p-calendar>
            </div>
        </div>
        <div class="d-flex flex-row hub-driver-switch-container"
             *ngIf="(!customizationCompaniesService.checkCompanies(Companies.JETT) || !(removeHubsAndDrivers.indexOf(form.value.status) !== -1 && customizationCompaniesService.checkCompanies(Companies.JETT) && shipment.isSenderPayCost) || (removeHubsAndDrivers.indexOf(form.value.status) === -1 && customizationCompaniesService.checkCompanies(Companies.JETT)))
             && (companyHadStations.indexOf(companyId) === -1 && !isLcl && (requireDeliveryDate.indexOf(form.value.status) !== -1 ||
            requirePostponedDeliveryDate.indexOf(form.value.status) !== -1
            || requireHubDriverSelection.indexOf(form.value.status)!==-1) && (form.value.status!=='RETURNED_BY_RECIPIENT' ||
            (form.value.status==='RETURNED_BY_RECIPIENT' && form.value['isDelivered'] === false)) || form.value.status === 'PARTIALLY_DELIVERED')">
            <div class="label">
                {{"SHIPMENT.DELIVERED_TO" | translate}}
            </div>
            <div class="delivered-to-hidden-space"></div>
            <div class="flex-1 row hub-driver-switch">
                <div class="hub-driver hub-btn" [ngClass]="{'selected-hub-driver': form.value['deliveredType'] === 'hub'}"
                     (click)="setDeliveredTypeTo('hub')">{{'CHANGE_PACKAGE_STATUS.HUB' | translate}}</div>
                <div class="hub-driver-splitter"></div>
                <div class="hub-driver driver-btn"
                     [ngClass]="{'selected-hub-driver': form.value['deliveredType'] === 'driver'}"
                     (click)="setDeliveredTypeTo('driver')">{{'CHANGE_PACKAGE_STATUS.DRIVER' | translate}}</div>
            </div>
        </div>
        <!--        </div>-->
        <div *ngIf="(!customizationCompaniesService.checkCompanies(Companies.JETT) || !(removeHubsAndDrivers.indexOf(form.value.status) !== -1 && customizationCompaniesService.checkCompanies(Companies.JETT) && shipment.isSenderPayCost) || (removeHubsAndDrivers.indexOf(form.value.status) === -1 && customizationCompaniesService.checkCompanies(Companies.JETT)))
             && companyHadStations.indexOf(companyId) === -1 && !isLcl && (requireDriver.indexOf(form.value.status) !== -1 || (requireDeliveryDate.indexOf(form.value.status) !== -1 && form.value['deliveredType'] == 'driver' )
                || (requirePostponedDeliveryDate.indexOf(form.value.status) !== -1 && form.value['deliveredType'] == 'driver')
                || (requireHubDriverSelection.indexOf(form.value.status) !== -1 && form.value['deliveredType'] == 'driver'))
                && (form.value.status!=='RETURNED_BY_RECIPIENT' || (form.value.status==='RETURNED_BY_RECIPIENT' && form.value['isDelivered'] === false))"
             class="d-flex driver-container mb-5">
            <div class="label">{{'CHANGE_PACKAGE_STATUS.DRIVER_NAME' | translate}}</div>
            <div class="driver-hidden-space"></div>
            <div class="drivers-drop-down">
                <div class="driver flex-1">
                    <p-autoComplete formControlName="driverId"
                                    type="text"
                                    field="label"
                                    [forceSelection]="true"
                                    (onSelect)="hideDriversList()"
                                    [dropdown]="true"
                                    [suggestions]="drivers"
                                    (completeMethod)="getDrivers($event)"
                                    #driversAc
                    >
                        <ng-template let-driver pTemplate="item">
                            <div class="store-item">
                                <div class="text-ellipsis">{{driver.label}}</div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
        </div>
        <div *ngIf="(!customizationCompaniesService.checkCompanies(Companies.JETT) || !(removeHubsAndDrivers.indexOf(form.value.status) !== -1 && customizationCompaniesService.checkCompanies(Companies.JETT) && shipment.isSenderPayCost) || (removeHubsAndDrivers.indexOf(form.value.status) === -1 && customizationCompaniesService.checkCompanies(Companies.JETT)))
             && ((requireHub.indexOf(form.value.status) !== -1  || (requireDeliveryDate.indexOf(form.value.status) !== -1 && form.value['deliveredType'] == 'hub')
                 || (requirePostponedDeliveryDate.indexOf(form.value.status) !== -1 && form.value['deliveredType'] == 'hub'
                 || (requireHubDriverSelection.indexOf(form.value.status) !== -1 && form.value['deliveredType'] == 'hub'))) &&
                 (form.value.status!=='RETURNED_BY_RECIPIENT' ||
            (form.value.status==='RETURNED_BY_RECIPIENT' && form.value['isDelivered'] === false)))"
             class="d-flex hub-container mb-5">
            <div class="label">{{'CHANGE_PACKAGE_STATUS.HUB_NAME' | translate | hubTitle}}</div>
            <div class="hub-hidden-space"></div>
            <div class="hub flex-1">
                <app-input-field-component [form]="form" (changed)="getShelves(form.value['hubId'])"
                                           controlName="hubId"
                                           [options]="hubsOptions"
                                           controlType="select"
                                           [isRequired]="companyHadStations.indexOf(this.companyId) === -1">
                </app-input-field-component>
            </div>
        </div>
        <div *ngIf="isSupportDeliveringPackageItemsPartially && ['PARTIALLY_DELIVERED'].indexOf(form.value.status) > -1 && shipment?.packageItemsToDeliverList?.length">
            <label style="font-size: .9em; color: rgb(128,128,128);margin-top: 20px">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.PLEASE_SELECT_PACKAGE_CONTENTS' | translate}}
            </label>
            <div>
                <div class="row" *ngFor="let content of shipment?.packageItemsToDeliverList">
                    <div class="">
                        <input [checked]="content.isSelected" type="checkbox" (change)="onChange(content,$event)">
                    </div>
                    <div style="padding-inline-start: 10px;">
                        {{content.name}}
                    </div>
                    <div class="">
                        ({{content.cod | number : digitFormat | currencyValue}})
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="(requireHubDriverSelection.indexOf(form.value.status) !== -1 || form.value.status === 'PARTIALLY_DELIVERED') && !form.value['isDelivered']"
             class="d-flex flex-column note-radio-btn-container">
            <label style="font-size: .9em; color: rgb(128,128,128);margin-top: 20px">{{'GENERAL.NOTES' | translate}}
                <span class="required" style="color: red;"> *</span>
            </label>
            <div class="d-flex flex-column" style="margin-bottom: 15px"
                 *ngIf="((form.value.status==='RETURNED_BY_RECIPIENT' && form.value['isDelivered'] === false))">
                <div *ngFor="let note of enumNote" class="d-inline-flex">
                    <div>
                        <input formControlName="failureReasonStatus" value="{{note.value}}" type="radio"
                               name="failureReasonStatus" (click)="onClickRadioButton(note)">
                    </div>
                    <div style="width: 15px"></div>
                    <div class="label">{{note.label}}</div>
                </div>
            </div>
            <input style="color: var(--admin-input-text-color);
                      border: 1px solid var(--admin-add-shipment-field-border);
                      border-radius: 5px;
                      width: 100%;"
                   type="text"
                   formControlName="notes" required>
            <div class="d-inline-flex receiver-paid-cost"
                 *ngIf="((form.value.status==='RETURNED_BY_RECIPIENT' && form.value['isDelivered'] === false))">
                <div>
                    <input formControlName="isReceiverPaidCost" type="checkbox" id="receiverPaidCost"
                           name="receiverPaidCost">
                </div>
                <div style="width: 15px"></div>
                <div class="label">{{'MANAGE_SHIPMENTS.IS_RECEIVER_PAID_COST' | translate}}</div>
            </div>
        </div>
        <div class="d-flex shelf-container" *ngIf="requireShelf.indexOf(form.value.status) !== -1">
            <div class="label">{{'CHANGE_PACKAGE_STATUS.SHELF_NAME' | translate}}</div>
            <div class="shelf-hidden-space"></div>
            <div class="shelf flex-1">
                <app-input-field-component [form]="form"
                                           controlName="shelfId"
                                           [options]="shelves"
                                           controlType="select"
                                           [isRequired]="true">
                </app-input-field-component>
            </div>
        </div>
        <div *ngIf="requirePostponedDeliveryDate.indexOf(form.value.status) !== -1 &&  form.value.status !=='POSTPONED_DELIVERY'"
             class="d-flex notes-container">
            <div class="label">{{'GENERAL.NOTES' | translate}}</div>
            <div class="notes-hidden-space"></div>
            <div class="flex-1 notes">
                <app-input-field-component [form]="form"
                                           controlName="notes"
                                           [isRequired]="true">
                </app-input-field-component>
            </div>
        </div>
        <div *ngIf="requireHubDriverSelection.indexOf(form.value.status) !== -1 &&
            !((form.value.status==='RETURNED_BY_RECIPIENT' && form.value['isDelivered'] === false)) && form.value.status !== 'PARTIALLY_DELIVERED'"
             class="d-flex notes-container">
            <div class="label">{{'GENERAL.NOTES' | translate}}</div>
            <div class="notes-hidden-space"></div>
            <div class="flex-1 notes">
                <app-input-field-component
                        [form]="form"
                        controlName="notes"
                        [isRequired]="true">
                </app-input-field-component>
            </div>
        </div>

        <div *ngIf="['DELIVERED_TO_RECIPIENT'].indexOf(form.value.status) > -1 && customizationCompaniesService.checkCompanies(Companies.JETT)"
             class="d-flex notes-container">
            <div class="label">{{'GENERAL.DELIVER_CONFIRM' | translate}}</div>
            <div class="notes-hidden-space"></div>
            <div class="flex-1 notes">
                <div class="uploaded-attachments-list flex-column d-flex w-100">
                    <div class="uploaded-attachment d-flex cursor-pointer justify-content-between w-100"  *ngIf="attachment">
                        <div class="attachment-type-and-size d-flex">
                            <div class="attachment-type">
                                <span>{{attachment.name}}</span>
                            </div>
                            <div class="attachment-size">
                                <span>({{attachment.size}} KB)</span>
                            </div>
                        </div>
                        <div class="remove-attachment" (click)="removeAttachment(attachment)">
                            <img src="../../assets/icons/ticketing-system/ticket-details/x-icon.png"
                                 alt=""/>
                        </div>
                    </div>
                    <div class="icon cursor-pointer" style="position: initial;top:initial;width: 20px" *ngIf="!attachment">
                        <img (click)="fileChooser.click()" src="../../../../assets/icons/attachments.svg" alt="icon"  style="margin: 0" class="w-100"/>
                    </div>
                    <input type="file" accept="image/*" multiple (change)="fileChange($event)" class="d-none" #fileChooser>
                </div>
            </div>
        </div>
    </form>
</div>
