<div class="receive-packages-container" [ngClass]="{'page-ltr': translateService.currentLang === 'en'}">
    <div class="header">
        <div class="modal-title">
            {{ (mismatchId ? 'ACCOUNTING.RECEIVE_PACKAGES' : 'ACCOUNTING.COD_MISMATCH')  | translate }}
        </div>
        <div class="close-icon" (click)="closeModal()" [ngClass]="{'disabled-button disabled': isLoading}">
            <img src="../../../assets/icons/cancel.svg" alt="icon" />
        </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner d-flex justify-content-center position-absolute w-100 z-index-1000000 text-align-center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row" [ngClass]="{'pt-0-important': isDriverEarning}">
            <div class="col-12 mb-4" *ngIf="isDriverEarning">
                <div class="row">
                    <div class="col-6 expected-cod">{{('ACCOUNTING.EXPECTED_COD') | translate }}: {{expectedCod | decimalDigits: 2}}</div>
                </div>

            </div>

            <div class="col-12 packages-container mb-4">
                <div class="row">
                    <div class="col-6 driver-input">
                        <div class="input-label">{{('ACCOUNTING.RECEIVED_COD')  | translate}}</div>
                        <div class="input-value">
                            <app-input-field-component class="w-100" [form]="form" controlName="cod" [isRequired]="true"
                                                       controlType="number"></app-input-field-component >
                        </div>
                    </div>
                    <div class="col-6 expected-cod" *ngIf="!isDriverEarning">{{('ACCOUNTING.EXPECTED_COD') | translate }}: {{expectedCod | decimalDigits: 2}}</div>

                    <div *ngIf="isDriverEarning" class="col-6 driver-input">
                        <div class="input-label">{{('DRIVERS_EARNINGS.TOTAL_DRIVER_COST') | translate }}</div>
                        <div class="input-value">
                            <app-input-field-component class="w-100" [form]="form" controlName="driverEarningSum" [isRequired]="false"
                                                       controlType="number"></app-input-field-component>
                        </div>
                    </div>

                </div>

            </div>
            <div class="col-12">
                <div class="row">
                <div class="col-6 margin-top-minus-5px">
                    <div *ngIf="!mismatchId" class="w-100 packages-container">
                        <div class="w-100 driver-input">
                            <div class="input-label">{{('ACCOUNTING.EXPENSES')  | translate}}</div>
                            <div class="input-value">
                                <app-input-field-component [form]="form" controlName="expenses" [isRequired]="false"
                                                           controlType="number"></app-input-field-component >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row d-inline-flex"
                         [ngClass]="{'expense-type-en': translateService.currentLang === 'en'}">
                        <div class="w-100 driver-input">
                            <div class="input-label">{{('ACCOUNTING.EXPENSE_TYPE')  | translate}}</div>
                            <div class="input-value">
                                <app-input-field-component [form]="form"
                                                           controlName="expensesType" field="name"
                                                           autocompleteWidth="initial"
                                                           [controlType]="'autoCompleteWithActions'"
                                                           [searchSuggestions]="expensesTypeSuggestion"
                                                           (onSearch)="getExpensesTypes($event)"
                                                           [forceSelection]="false"
                                                           [displayDropDown]="true" #expenseType
                                                           [addedExpenseType]="addedExpenseType"
                                                           (onDeleteExpenseTypeClicked)="deleteExpenseType($event)"
                                                           (onAddExpenceClicked)="addExpenseType($event)"
                                                           [isRequired]="false">
                                </app-input-field-component>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="row is-print-report">
            <div *ngIf="!mismatchId && !isEditMode" class="d-flex align-items-center">
                <p-inputSwitch [formControl]="form?.controls['printReport']"></p-inputSwitch>
                <div class="p-1"></div>
                <label class="is-print-report-label">{{'ACCOUNTING.PRINT_RECEIVE_REPORT'|translate}}</label>
            </div>
        </div>
        <div class="row w-100 pl-5 pr-5">
            <div class="col-12 h-separator">

            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div class="action-container" style="padding: 10px 30px">
                <div class="add-button cursor-pointer "
                     (click)="finishScanning()"
                     [ngClass]="{'disabled-button disabled': !form.valid || isLoading}">
                    <i class="far fa-save"></i>
                    {{'ACTIONS.VERIFY' | translate }}
                </div>
                <div class="reset-button cursor-pointer " [ngClass]="{'disabled-button disabled': isLoading}" (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
