import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ServiceTypesService {

    constructor(private http: HttpClient) {
    }

    public getServiceTypes(query) {
        return this.http.get(ADMINISTRATION_CONSTANTS.GET_SERVICE_TYPES + query);
    }
    public getServiceTypesDropdown(query, useCache = false) {
        const headers = useCache ? {
            'use-cache': 'true'
        } : {};
        return this.http.get(ADMINISTRATION_CONSTANTS.GET_SERVICE_TYPES_DROPDOWN + query + '&utcOffset=' + moment().utcOffset().toString(),
            {headers});
    }
    public getVehicleTypes(id, query, useCache = false) {
        const headers = useCache ? {
            'use-cache': 'true'
        } : {};
        return this.http.get(ADMINISTRATION_CONSTANTS.GET_VEHICLE_TYPES.replace('{serviceTypeId}', id) + query, {headers});
    }
    public getAllVehicleTypes(query) {
        return this.http.get(ADMINISTRATION_CONSTANTS.GET_ALL_VEHICLE_TYPES + query);
    }

    public newServiceType(reqBody) {
        return this.http.post(ADMINISTRATION_CONSTANTS.NEW_SERVICE_TYPE, reqBody);
    }

    public editServiceType(serviceTypeId, reqBody) {
        const url = ADMINISTRATION_CONSTANTS.EDIT_SERVICE_TYPE.replace('{serviceTypeId}', serviceTypeId);
        return this.http.put(url, reqBody);
    }

    public deleteServiceType(serviceTypeId) {
        const url = ADMINISTRATION_CONSTANTS.DELETE_SERVICE_TYPE.replace('{serviceTypeId}', serviceTypeId);
        return this.http.delete(url);
    }
}
