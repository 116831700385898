import {Component, OnInit, ViewChild} from '@angular/core';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {SharedService} from '../../../shared/services/shared-service';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {PackageContentsDialogComponent} from '../distributor-packages-contents-report/package-contents/package-contents-dialog.component';
import {ChangePackageStatusService} from '../../../shipment/services/change-package-status.service';
import {UserService} from '../../../shared/services/user.service';
import {DISTRIBUTOR_RETURNED_AND_EMPTY_STATUS, DISTRIBUTOR_RETURNED_TYPES} from '../../../shipment/services/constants';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-damaged-packages-report',
  templateUrl: './damaged-packages-report.component.html',
  styleUrls: ['./damaged-packages-report.component.scss']
})
export class DamagedPackagesReportComponent extends SubscriptionTrackerComponent implements OnInit {
  reportType = '';
  currentLang;
  totalRecordsNo = 0;
  selectedCustomerId: number;
  dateRangPickerOptions: any = {};
  fromDate;
  toDate;
  search;
  reportData = [];
  isLoading = false;
  pageNumber = 0;
  pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
  first = 0;
  rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
  isAllSelected = false;
  public selectedShipmentStatus = 'ALL';
  public selectedReturnedTypeStatus = 'ALL';

  @ViewChild('table') table;
  @ViewChild('customerFilter') customerFilter;
  shipmentStatusList;
  returnedTypesList;
  private userRole: string;
  isFailed: any;
  isDownloading = false;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal,
              private datePipe: DatePipe,
              private changePackageStatusService: ChangePackageStatusService,
              private router: Router,
              private shipmentService: ShipmentsService,
              private sharedService: SharedService,
              private dateRangePickerService: DateRangePickerService,
              private confirmationService: ConfirmationService,
              private userService: UserService,
              private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.setReportType();
    this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
      this.dateRangPickerOptions = options;
    });
    const subscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd))
        .subscribe(e => {
          this.resetFilters();
          this.onFilter();
        });
    this.shipmentStatusList = this.setShipmentStatus();
    this.returnedTypesList = this.setReturnedTypesList();
    this.subscriptions.push(subscription);
    const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
    this.userRole = role;
  }

  resetFilters() {
    this.selectedCustomerId = null;
    this.customerFilter.selectedUser = null;
    this.fromDate = null;
    this.toDate = null;
    this.search = null;
  }

  setReportType() {
    this.route.queryParams.subscribe(
        (res) => {
          this.reportType = res.reportType;
        }, error => {
          console.error(error);
        }
    );
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  createParams() {
    let params: any = {page: this.pageNumber + 1, pageSize: this.pageSize};
    if (this.selectedCustomerId) {
      params['customerId'] = this.selectedCustomerId;
    }
    params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.fromDate && this.toDate) {
      params = {
        ...params,
        fromDate: this.transformDate(this.fromDate),
        toDate: this.transformDate(this.toDate),
      };
    }
    if (this.search) {
      params['search'] = this.search;
    }
    if (this.selectedReturnedTypeStatus !== 'ALL') {
      params['type'] = this.selectedReturnedTypeStatus;
    }
    if (this.selectedShipmentStatus !== 'ALL') {
      params['status'] = this.selectedShipmentStatus;
    }
    return params;
  }

  onFilter() {
    this.pageNumber = 0;
    this.reportData = [];
    this.table.reset();
  }
  initReport() {
    this.isLoading = true;
    this.shipmentService.getDistributorPackagesReport(this.createParams()).subscribe(
        (res: any) => {
          this.reportData = res.data;
          console.log(res);
          this.totalRecordsNo = res.totalRecordsNo;
          this.isLoading = false;
        }, error => {
          console.error(error);
          this.isLoading = false;
        }
    );
  }

  onSelectCustomer(id) {
    this.selectedCustomerId = (id === undefined ? '' : id);
    this.onFilter();
  }

  onDateSelected(value) {
    this.fromDate = new Date(value.start);
    this.toDate = new Date(value.end);
    this.onFilter();
  }

  loadReportsLazy(event) {
    this.pageNumber = event.first / event.rows;
    this.pageSize = event.rows;
    this.first = event.first;
    this.initReport();
  }

  onToggleSelectAll() {
    if (this.totalRecordsNo === 0) {
      return;
    }
    let selected = false;
    if (this.isAllSelected) {
      selected = true;
    }
    this.reportData.forEach((item: any) => {
      item.isSelected = selected;
    });
  }

  copyMessage($event, list?, barcode?) {
    this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
  }

  openContentsDialog(pkgId) {
    if (this.reportType === 'BY_CUSTOMER' || !pkgId) { return; }
    const modal = this.modalService.open(PackageContentsDialogComponent, {
      backdrop: 'static',
      size: <any>'xl'
    });
    modal.componentInstance.packageId = pkgId;
  }


  onStatusChanged() {
    this.onFilter();
  }

  public setShipmentStatus() {
    return DISTRIBUTOR_RETURNED_AND_EMPTY_STATUS.map(status => {
      return {label: this.translate.instant(`${status}`), value: status};
    });
  }

  public setReturnedTypesList() {
    return DISTRIBUTOR_RETURNED_TYPES.map(status => {
      return {label: this.translate.instant(`${status}`), value: status};
    });
  }

  initShipments(b: boolean) {
    
  }

  exportExcel() {
    this.confirmationService.confirm({
      message: this.translate.instant('ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'),
      accept: () => {
        this.isDownloading = true;
        this.shipmentService.getDistributorFileForReport(this.createParams(), 'excel').subscribe((response: any) => {
          window.open(response.url, '_blank');
          this.isDownloading = false;
        });
      },
      acceptLabel: this.translate.instant('GENERAL.YES'),
      rejectLabel: this.translate.instant('GENERAL.NO'),
    });
  }

  printReport(type) {
    this.confirmationService.confirm({
      message: this.translate.instant('ACCOUNTING.DOWNLOAD_' + type + '_ALL_CONFIRMATION'),
      accept: () => {
        this.isDownloading = true;
        this.shipmentService.getDistributorFileForReport(this.createParams(), type.toLowerCase()).subscribe((response: any) => {
          window.open(response.url, '_blank');
          this.isDownloading = false;
        });
      },
      acceptLabel: this.translate.instant('GENERAL.YES'),
      rejectLabel: this.translate.instant('GENERAL.NO'),
    });
  }
}
