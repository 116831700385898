import {Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropDownComponent),
    multi: true
  }]
})

export class DropDownComponent implements OnInit, ControlValueAccessor {
  @Output() whenChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() options: any[];
  @Input() id: string;
  @Input() placeholder: string;
  @Input() fieldDisabled = false;
  // @Input() dropdownEnableFilter = false;
  @Input() showClear = true;
  @Input() dropdownFilterBy = 'label';
  @Input() dropdownNameField = 'name';
  @Input() formControlObj: FormControl;
  @Input() formControl: FormControl;
  @Input() cssStyle = {};
  @Input() label: string;
  @Input() showLabel = true;
  @Input() optionLabel = 'label';
  @Input() optionValue = 'value';
  @Input() filter = false;
  @Input() datakey: any ;
  @Input() autoDisplayFirst = false;
  @Input() defaultValue: any = 'ALL';
  @Input() isFilter = false;
  @Input() appendTo = 'body';
  @Input() selectedItemTemplate: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  private _value: any;

  constructor() { }

  ngOnInit(): void {
  }

  change(event) {
    this.whenChange.emit(event);
    this.formControlObj?.markAsTouched();
  }

  get value() {
    return this._value;
  }

  @Input() set value(val: any) {
    if (val && typeof val === 'object' && !Object.keys(val).length) {
      return;
    }
    this._value = val;
    if (this.propagateChange) {
      this.propagateChange(val);
    } else {
      this.whenChange.emit(val);
    }
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
    if (typeof fn === 'object' && !Object.keys(fn).length) {
      return;
    }
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  private propagateChange = (_: any) => {
  }
}
