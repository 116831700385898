import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UsersService} from '../../../../users/services/users.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {SHARED_CONSTANTS} from '../../../../shared/services/shared_constants/constants';
import {Table} from 'primeng/table';

@Component({
    selector: 'app-add-customers-list',
    templateUrl: './add-customers-list.component.html',
    styleUrls: ['./add-customers-list.component.scss']
})
export class AddCustomersListComponent implements OnInit {

    pricingListId: number;
    customerAccountManagerId: number;
    customers: any[] = [];
    totalRecords = 0;
    paginationOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    defaultPageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;

    form: FormGroup;
    customersList: any[] = [];
    private filterParams = {};
    isLoadingCustomers = false;
    isLoading = false;
    isCustomerAccountManage = false;

    @ViewChild('customersTable')
    customersTable: Table;
    allSelected = false;
    totalRecordsNo = 0;

    isAdditionFees = false;
    additionFeeId;

    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private confirmationService: ConfirmationService,
                private usersService: UsersService,
                private messageService: MessageService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.initForm();
        this.initFilterParams();
        this.getCustomers();
        if (this.isCustomerAccountManage) {
            this.getCustomerAccountMangerCustomers();
        }
        if (this.isAdditionFees) {
            this.getAdditionFeeCustomers();
        } else {
            this.getPricingListCustomers();
        }
    }

    private createParams(filterParams) {
        let result = '?';

        if (this.isCustomerAccountManage) {
            result += 'isWithoutCustomerAccountManager=true';
        }
        if (!filterParams) {
            return result;
        }
        const filterKeyValues = Object.entries(filterParams);
        filterKeyValues.forEach((keyValuePair, index) => {
            if (keyValuePair[1]) {
                result += `&${keyValuePair[0]}=${keyValuePair[1]}`;
            }
        });
        return result;
    }

    private initForm() {
        this.form = this.formBuilder.group({
            customersIds: []
        });
    }

    private initFilterParams() {
        this.filterParams = {
            SELECT_CUSTOMERS: {
                page: 1,
                pageSize: 20,
                search: ''
            },
            CUSTOMERS: {
                page: 1,
                pageSize: this.defaultPageSize,
                search: ''
            }
        };
    }

    closeModal() {
        this.activeModal.close();
    }

    private getCustomers() {
        this.isLoadingCustomers = true;
        this.usersService.getCustomers(this.createParams(this.filterParams['SELECT_CUSTOMERS'])).subscribe((customersResponse) => {
            this.customersList = customersResponse['customers'];
            if (!this.totalRecordsNo) {
                this.totalRecordsNo = customersResponse['totalRecordsNo'];
            }
            this.isLoadingCustomers = false;
        }, () => {
            this.isLoadingCustomers = false;
        });
    }

    searchCustomer(search: string) {
        this.filterParams['SELECT_CUSTOMERS'].search = search;
        this.getCustomers();
    }

    onAddCustomers() {
        this.isLoading = true;
        if (this.isCustomerAccountManage) {
            this.usersService.addCustomersToCustomerAccountManagerId(!this.allSelected ? this.form.get('customersIds').value : [], this.customerAccountManagerId,
                {allCustomers : this.allSelected})
                .subscribe((() => {
                    this.translateService.get('PRICING.ALERTS.CUSTOMERS_ADDED_TO_CUSTOMER_ACCOUNT_SUCCESSFULLY').subscribe(transValue => {
                        this.messageService.add({
                            severity: 'success',
                            detail: transValue
                        });
                        this.form.get('customersIds').patchValue([]);
                        this.onClearAll();
                        this.customersTable.reset();
                    });
                }), () => {
                    this.isLoading = false;
                });
        } else if (this.isAdditionFees) {
            this.usersService.addCustomersToAdditionFee(!this.allSelected ? this.form.get('customersIds').value : [], this.additionFeeId,
                {allCustomers : this.allSelected})
                .subscribe((() => {
                    this.translateService.get('PRICING.ALERTS.CUSTOMERS_ADDED_TO_CUSTOMER_ACCOUNT_SUCCESSFULLY').subscribe(transValue => {
                        this.messageService.add({
                            severity: 'success',
                            detail: transValue
                        });
                        this.form.get('customersIds').patchValue([]);
                        this.onClearAll();
                        this.customersTable.reset();
                    });
                }), () => {
                    this.isLoading = false;
                });
        } else {
            this.usersService.addCustomersToPricingListId(this.form.get('customersIds').value, this.pricingListId).subscribe((() => {
                this.translateService.get('PRICING.ALERTS.CUSTOMERS_ADDED_SUCCESSFULLY').subscribe(transValue => {
                    this.messageService.add({
                        severity: 'success',
                        detail: transValue
                    });
                    this.form.get('customersIds').patchValue([]);
                    this.customersTable.reset();
                });
            }), () => {
                this.isLoading = false;
            });
        }
    }

    private getPricingListCustomers() {
        this.isLoading = true;
        this.usersService.getCustomersWithPricingList(this.pricingListId, this.filterParams['CUSTOMERS'])
            .subscribe((response: any) => {
                this.customers = response.customers;
                this.totalRecords = response.totalRecordsNo;
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }

    private getCustomerAccountMangerCustomers() {
        this.isLoading = true;
        this.usersService.getCustomersWithCustomerAccountManger(this.customerAccountManagerId, this.filterParams['CUSTOMERS'])
            .subscribe((response: any) => {
                this.customers = response.customers;
                this.totalRecords = response.totalRecordsNo;
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }
    private getAdditionFeeCustomers() {
        this.isLoading = true;
        this.usersService.getCustomersWithAdditionFees(this.additionFeeId, this.filterParams['CUSTOMERS'])
            .subscribe((response: any) => {
                this.customers = response;
                this.totalRecords = response.length;
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }

    deletePricing(customer: any) {
        const body = {};
        this.isLoading = true;
        body['pricingTypeId'] = null;
        this.usersService.modifyPricingListId(customer.id, body).subscribe(() => {
            this.getPricingListCustomers();
        }, () => {
            this.isLoading = false;
        });
    }

    deleteCustomerAccountManager(customer: any) {
        this.translateService.get(['PRICING.ALERTS.CONFIRM_CUSTOMER_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
            this.confirmationService.confirm({
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                message: values['PRICING.ALERTS.CONFIRM_CUSTOMER_DELETE'],
                accept: () => {
                    this.isLoading = true;
                    this.usersService.deleteCustomerAccountManagerId(customer.id).subscribe(() => {
                        this.getCustomerAccountMangerCustomers();
                    }, () => {
                        this.isLoading = false;
                    });
                }
            });
        });

    }
    deleteCustomerAdditionFee(customer: any) {
        this.translateService.get(['PRICING.ALERTS.CONFIRM_CUSTOMER_DELETE', 'GENERAL.YES', 'GENERAL.NO']).subscribe(values => {
            this.confirmationService.confirm({
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                message: values['PRICING.ALERTS.CONFIRM_CUSTOMER_DELETE'],
                accept: () => {
                    this.isLoading = true;
                    this.usersService.deleteCustomerAdditionFee(this.additionFeeId, customer.id).subscribe(() => {
                        this.getAdditionFeeCustomers();
                    }, () => {
                        this.isLoading = false;
                    });
                }
            });
        });

    }

    deleteCustomer(customer: any) {
        if (this.isCustomerAccountManage) {
            this.deleteCustomerAccountManager(customer);
        }else if (this.isAdditionFees) {
            this.deleteCustomerAdditionFee(customer);
        } else {
            this.deletePricing(customer);
        }
    }

    loadLazyCustomers(event: any) {
        this.filterParams['CUSTOMERS'].pageSize = event.rows;
        this.filterParams['CUSTOMERS'].page = Math.floor(event.first / event.rows) + 1;
        if (this.isCustomerAccountManage) {
            this.getCustomerAccountMangerCustomers();
        }else if (this.isAdditionFees) {
            this.getAdditionFeeCustomers();
        } else {
            this.getPricingListCustomers();
        }
    }

    public onSelectAll() {
        this.form.get('customersIds').patchValue(['all']);
        this.customersList = [{id: 'all', fullName: this.translateService.instant('ALL') + '(' + this.totalRecordsNo + ')'}];
        this.allSelected = true;
    }

    public onClearAll() {
        this.form.get('customersIds').patchValue([]);
        this.allSelected = false;
        this.customersList = [];
        this.getCustomers();

    }

    onChange($event) {
        if (this.allSelected) {
            this.onClearAll();
        }
    }
}
