import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../shared/services/shared_constants/constants';
import {UsersService} from '../users/services/users.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {
    ReceivedCodWithoutCustodyPackagesComponent
} from './components/received-cod-without-custody-packages/received-cod-without-custody-packages.component';
import {
    COD_STATUS_LIST_WITHOUT_RELEASING_CUSTODY,
    COD_STATUS_LIST_WITHOUT_RELEASING_CUSTODY_CUSTOMER_CONFIRMATION_ENABLED
} from '../shipment/services/constants';
import {UserService} from '../shared/services/user.service';
import {DateRangePickerService} from '../shared/services/date-range-picker.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-received-cod-without-custody-report',
    templateUrl: './received-cod-without-custody-report.component.html',
    styleUrls: ['./received-cod-without-custody-report.component.scss']
})
export class ReceivedCodWithoutCustodyReportComponent implements OnInit, AfterViewInit {
    typesList: {label: string, value: string}[];
    selectedType: any;
    isLoading = false;
    totalRecords = 0;
    first = 0;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    pageNumber = 0;
    customerTypes: {value: string, label: string}[];
    customerType = 'ALL';
    data: any[] = [];
    searchContent = '';
    userId;
    currentLang;
    @ViewChild('userFilter') userFilter;
    @ViewChild('datepicker') datepicker;

    public fromDate;
    public toDate;

    public multiSelectFilterSettings = {};
    public codStatusList: any[] = [];
    public selectedMultiSelectCodStatus: { label: string, value: string }[] = [];
    dateRangPickerOptions;
    preSelectedDate;
    selectedDateType: any = 'CREATED_DATE';
    dateTypesFilterOptions;
    deliveryDate = {
        value: undefined
    };

    constructor(private usersService: UsersService,
                private translateService: TranslateService,
                private dateRangePickerService: DateRangePickerService,
                private datePipe: DatePipe,
                private userService: UserService,
                private modalService: NgbModal) {

        this.typesList = [
            {label: this.translateService.instant('GENERAL.BY_CUSTOMERS'), value: 'CUSTOMER'},
            {label: this.translateService.instant('GENERAL.BY_HUB'), value: 'HUB'},
            {label: this.translateService.instant('GENERAL.BY_DRIVER'), value: 'DRIVER'},
            {label: this.translateService.instant('GENERAL.BY_PARTNER'), value: 'PARTNER'}
        ];

        this.customerTypes = [
            {value: 'ALL', label: 'ALL'},
            {value: 'NORMAL', label: this.translateService.instant('CUSTOMER_TYPES.NORMAL')},
            {value: 'EXPRESS', label: this.translateService.instant('CUSTOMER_TYPES.EXPRESS')}
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
        }, 30);
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.selectedType = this.typesList[0].value;
        this.setCodStatusDropDownSettings();
        this.codStatusList = this.getCodStatus();
        this.loadReportData();
        this.dateTypesFilterOptions = [
            {label: this.translateService.instant('SHIPMENT.CREATED_DATE'), value: 'CREATED_DATE'},
            {label: this.translateService.instant('SHIPMENT.DELIVERY_DATE'), value: 'DELIVERY_DATE'},
            {label: this.translateService.instant('SHIPMENT.FIRST_PICKUP_DATE'), value: 'FIRST_PICKUP_DATE'},
            {label: this.translateService.instant('SHIPMENT.RETURN_DATE'), value: 'RETURN_DATE'},
            {label: this.translateService.instant('SHIPMENT.POSTPONED_DATE'), value: 'POSTPONED_DATE'},
            {label: this.translateService.instant('LAST_STATUS_DATE'), value: 'LAST_STATUS_DATE'},
        ];
    }

    onDateSelected(value) {
        setTimeout(() => {
            this.preSelectedDate = value;
            this.fromDate = new Date(value.start);
            this.toDate = new Date(value.end);
            this.fillTextInDateInput(value);
            this.onSearch();
        });
    }

    setPaginatorQueryParam($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.first = $event.first;
        this.loadReportData();
    }

    createQueryParams() {

        const query = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
            type: this.selectedType
        };
        if (this.searchContent !== '') {
            query['search'] = this.searchContent;
        }
        if (this.userId) {
            query['entityId'] = this.userId;
        }
        if (this.customerType && this.customerType  !== 'ALL') {
            query['customerType'] = this.customerType;
        }
        if (this.selectedMultiSelectCodStatus && this.selectedMultiSelectCodStatus.length && this.selectedMultiSelectCodStatus.length < this.codStatusList.length) {
            query['codStatuses'] = this.selectedMultiSelectCodStatus.join(',');
        }
        if (this.fromDate) {
            query['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            query['toDate'] = this.transformDate(this.toDate);
        }
        if (this.deliveryDate.value) {
            query['codDeliveryDate'] = this.transformDate(this.deliveryDate.value);
        }
        if (this.selectedDateType) {
            query['dateFilter'] = typeof this.selectedDateType === 'string' ? this.selectedDateType : this.selectedDateType.value;
        }
        query['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return query;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    loadReportData(forceFetch = false) {
        this.isLoading = true;

        if (forceFetch) {
            this.pageNumber = 0;
        }

        this.usersService.getReceivedCodWithoutCustody(this.createQueryParams()).subscribe(
            (res: any) => {
                this.data = res.codCustomers;
                this.totalRecords = res.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    openDetails(id) {
        let className = 'container-carried-packages';
        if (this.currentLang === 'en') {
            className = 'container-carried-packages-en';
        }
        const modal = this.modalService.open(ReceivedCodWithoutCustodyPackagesComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.entityId = id;
        modal.componentInstance.type = this.selectedType;
        if (this.selectedMultiSelectCodStatus && this.selectedMultiSelectCodStatus.length < this.codStatusList.length) {
            modal.componentInstance.codStatuses = this.selectedMultiSelectCodStatus;
        }
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number, hubId?: number }) => {
                if (status.isSuccess) {
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    onTypeChanged() {
        this.userFilter.selectedUser = null;
        this.userId = null;
        this.pageNumber = 0;
        this.loadReportData();
    }

    onUserFilterChanged(id) {
        this.userId = id;
        this.pageNumber = 0;
        this.loadReportData();
    }

    onSearch() {
        this.pageNumber = 0;
        this.loadReportData();
    }

    public setCodStatusDropDownSettings() {
        this.multiSelectFilterSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'label',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: true,
            selectAllText: this.translateService.instant('GENERAL.SELECT_ALL'),
            unSelectAllText: this.translateService.instant('GENERAL.UNSELECT_ALL')
        };
    }

    private getCodStatus() {
        const list = [...COD_STATUS_LIST_WITHOUT_RELEASING_CUSTODY];
        if (this.userService.userInfo.isEnableCustomerConfirmationForReceivingMassCodPkg) {
            list.push(...COD_STATUS_LIST_WITHOUT_RELEASING_CUSTODY_CUSTOMER_CONFIRMATION_ENABLED);
        }
        return list.map((value) => {
            return {label: this.translateService.instant(value === 'WITH_DRIVER' ? 'PICKED_WITH_DRIVER' : value), value};
        });
    }

    public onSelectCodStatus() {
        this.pageNumber = 0;
        this.loadReportData();
    }

    onHideDateRangePicker($event: any) {
        setTimeout(() => {
            this.fillTextInDateInput(this.preSelectedDate);
        });
    }

    private fillTextInDateInput(value) {
        const {start, end} = value;
        if ((start && end) || value.label) {
            if (value.label && value.label !== this.dateRangPickerOptions.locale.customRangeLabel) {
                if (value.label === this.dateRangPickerOptions.locale.all) {
                    this.datepicker.nativeElement.value = this.dateRangPickerOptions.locale.noValueSelected;
                } else {
                    this.datepicker.nativeElement.value = value.label;
                }
            } else {
                this.datepicker.nativeElement.value = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
            }
        }
    }

    public resetField(name, refreshData = true) {
        this.deliveryDate.value = '';
        this.onSearch();
    }
}
