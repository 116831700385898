<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="h-100 d-flex flex-column">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
        <div class="header-filters top-header d-flex flex-row">
            <div class="flex-1">
                {{"COMPANY.COMPANY_ACCOUNTING" | translate}}
            </div>
            <div *ngIf="isBilling" style="margin-top: 9px;">
                <div class="excel-button"
                     (click)="openExcel()">
                    <i class="fas fa-file-excel"></i>
                    {{ 'ACTIONS.EXPORT_TO_EXCEL' | translate}}
                </div>
            </div>
            <div *ngIf="isBilling || isManagement" class="search">
                <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..."
                       [(ngModel)]="searchContent"
                       (input)="onSearch()">
            </div>
            <div *ngIf="isManagement" class="date-filter" style="min-width: 120px; margin-top: 10px;">
                <label class="date-label">{{'COMPANY.FILTER_BY_MONTH' | translate}} :</label>
                <i class="pi pi-calendar date-picker-icon"></i>
                <p-calendar view="month" dateFormat="mm/yy"
                            styleClass="date-picker" [(ngModel)]="date" (onSelect)="onSearch()"
                            placeholder="{{'COMPANY.FILTER_BY_MONTH' | translate}}"></p-calendar>
            </div>
            <div *ngIf="isManagement"  class="edit-action action cursor-pointer"
                 style="margin-top: 14px;"
                 (click)="exportReturnedBeforeArrivalPackages()">
                <i class="fas fa-file-excel"></i>
                {{ 'COMPANY.EXPORT_RETURNED_BEFORE_ARRIVAL_PACKAGES' | translate}}
            </div>
            <div *ngIf="isManagement" class="edit-action action cursor-pointer" style="margin-top: 14px;"
                (click)="startCompanyBilling()">
                {{'COMPANY.COMPANY_BILLING_SETTINGS' |translate}}
                <i class="fas fa-pencil-alt"></i>
            </div>
            <div *ngIf="isManagement" class="edit-action action cursor-pointer" style="margin-top: 14px;"
                 (click)="addCompanyBillingPayment()"
                [ngClass]="{'disabled-button disabled': isDisableAddCompany()}">
                {{'COMPANY.ADD_COMPANY_BILLING_PAYMENT' |translate}}
                <i class="fas fa-plus"></i>
            </div>
            <div *ngIf="isManagement" class="date-filter"  style="min-width: 120px; margin-top: 10px;">
                <label class="date-label">{{'COMPANY.START_NOTIFICATION_DATE' | translate}} :</label>
                <i class="pi pi-calendar date-picker-icon"></i>
                <p-calendar [disabled]="true" styleClass="date-picker"   [(ngModel)]="startDate"
                            placeholder="{{'COMPANY.START_DATE' | translate}}"
                            dateFormat="dd/mm/yy"></p-calendar>
            </div>

    </div>


        <div class="top-actions">
            <div class="tabs-container">
                <div class="company-tab cursor-pointer"
                     [ngClass]="{'selectedTab': selectedTab === 'company-management'}" (click)="onChangeTab('company-management')">
                    {{'ADMINISTRATION.ADMINISTRATIVE' | translate}}
                </div>
                <div class="system-users-tab cursor-pointer"
                     [ngClass]="{'selectedTab': selectedTab === 'company-billing'}" (click)="onChangeTab('company-billing')">
                    {{'ACCOUNTING.COD_MISMATCH' | translate}}
                </div>
                <div class="system-users-tab cursor-pointer"
                     [ngClass]="{'selectedTab': selectedTab === 'notification-history'}" (click)="onChangeTab('notification-history')">
                    {{'COMPANY.NOTIFICATION_HISTORY' | translate}}
                </div>
            </div>
        </div>
    <div class="cod-mismatch-table flex-1">
        <p-table  dataKey="id" #table [value]="companies"
                 styleClass="customers-table flex-table"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadCompaniesLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="isBilling || isManagement">{{"GENERAL.COMPANY_NAME" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.AMOUNT" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.ACCOUNTABLE_PACKAGES" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.PAYMENT_DONE" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.TEMPORARILY_DISABLE_DATE" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.IS_CHANCE_GIVEN" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.IS_NOTIFY" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.IS_BLOCKED" | translate}}</th>
                    <th *ngIf="isManagement">{{"COMPANY.IS_ALLOW_BILLING" | translate}}</th>
                    <th *ngIf="isBilling">{{"COMPANY.PAID_AMOUNT" | translate}}</th>
                    <th *ngIf="isBilling">{{"COMPANY.PAYMENT_DATE" | translate}}</th>
                    <th *ngIf="isHistory">{{"COMPANY.START_NOTIFICATION_DATE" | translate}}</th>
                    <th *ngIf="isHistory">{{"COMPANY.CHANCE_DAYS_NUMBER" | translate}}</th>
                    <th *ngIf="isHistory" style="width: 7%">{{"ACTIONS.EDIT" | translate}}</th>
                    <th *ngIf="isBilling" style="width: 7%">{{"GENERAL.DELETE" | translate}}</th>
                    <th *ngIf="isManagement"
                        style="width: 7%"> {{"MANAGE_CONTAINERS.PRINT" | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company let-expanded="expanded">
                <tr tooltipPosition="top"
                    pTooltip="{{getTooltipMessage(company.isAllowBilling, company.isBlockBilling)| translate}}"  [tooltipDisabled]="!isDisable(company.isAllowBilling, company.isBlockBilling)">
                    <td *ngIf="isBilling || isManagement">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{'GENERAL.COMPANY_NAME' | translate}}</div>
                            <div class="value">{{company.companyName}}</div>
                        </div>
                    </td>
                    <td *ngIf="isManagement">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"COMPANY.AMOUNT" | translate}}
                            </div>
                            <div class="value">
                                <input style="width: 100%;" value="{{company.billingCost}}" #billingCost (change)="submitAmount(false, company.id, billingCost.value)" pInputText type="number"/>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isManagement">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"COMPANY.ACCOUNTABLE_PACKAGES" | translate}}
                            </div>
                            <div class="value">
                                <input style="width: 100%;" value="{{company.packagesNumber}}" #packagesNumber (change)="setAccountablePackage( company.id, packagesNumber.value)" pInputText type="number"/>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isManagement">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"COMPANY.PAYMENT_DONE" | translate}}
                            </div>
                            <div class="value">
                                <p-inputSwitch #isPaidDoneChkBx [(ngModel)]="company.isPaid" (onChange)="toggleDisable('isPaidDoneChkBx',isPaidDoneChkBx, company.id)"> </p-inputSwitch>
                            </div>
                        </div>
                    </td>
                     <td *ngIf="isManagement">
                         <div class="data-item-container">
                             <div class="label hidden-md-up">
                                 {{"COMPANY.TEMPORARILY_DISABLE_DATE" | translate}}
                             </div>
                             <div class="value">
                                 <input #tempDisableDate [disabled]="isDisable(company.isAllowBilling, company.isBlockBilling)"class="form-control" [ngClass]="{'empty-date': !this.company.tempDisableDate}" type="date" placeholder="" (change)="setCompanyTempDisableDate(tempDisableDate, company.id)" (click)="transformDate(company.tempDisableDate)" value="{{transformDate(getLocalDate(company.tempDisableDate))}}" id="example-date-input">
                             </div>
                         </div>
                     </td>
                    <td *ngIf="isManagement">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"COMPANY.IS_CHANCE_GIVEN" | translate}}
                            </div>
                            <div class="value">
                                {{(company.tempDisableDate? 'GENERAL.YES' : 'GENERAL.NO') | translate}}
                            </div>
                        </div>

                    </td>
                     <td *ngIf="isManagement">
                         <div class="data-item-container">
                             <div class="label hidden-md-up">
                                 {{"COMPANY.IS_NOTIFY" | translate}}
                             </div>
                             <div class="value">
                                 <p-inputSwitch [disabled]="isDisable(company.isAllowBilling, company.isBlockBilling)" #isNotify  [(ngModel)]="company.isNotify"  (onChange)="toggleDisable('isNotifyChkBx',isNotify, company.id)"></p-inputSwitch>
                             </div>
                         </div>
                     </td>
                     <td *ngIf="isManagement">
                         <div class="data-item-container">
                             <div class="label hidden-md-up">
                                 {{"COMPANY.IS_BLOCKED" | translate}}
                             </div>
                             <div class="value">
                                 <p-inputSwitch #isBlock  [(ngModel)]="company.isBlockBilling"  (onChange)="toggleDisable('BLOCK_BILLING',isBlock, company.companyId)"></p-inputSwitch>
                             </div>
                         </div>
                     </td>
                     <td *ngIf="isManagement">
                         <div class="data-item-container">
                             <div class="label hidden-md-up">
                                 {{"COMPANY.IS_ALLOW_BILLING" | translate}}
                             </div>
                             <div class="value">
                                 <p-inputSwitch #isAllowBilling  [(ngModel)]="company.isAllowBilling"  (onChange)="toggleDisable('ALLOW_BILLING',isAllowBilling, company.companyId)"></p-inputSwitch>
                             </div>
                         </div>
                     </td>
                    <td *ngIf="isBilling">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"COMPANY.PAID_AMOUNT" | translate}}
                            </div>
                            <div class="value">
                                <input style="width: 100%;" value="{{company.paidCost}}" #paidAmount (change)="submitAmount(true, company.id , paidAmount.value)"  pInputText type="number"/>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isBilling">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.PAYMENT_DATE" | translate}}</div>
                            <div class="value">{{company.paymentDate | date:'dd/MM/yyyy'}}</div>
                        </div>
                    </td>
                    <td *ngIf="isHistory">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.START_NOTIFICATION_DATE" | translate}}</div>
                            <div class="value"> {{company.startNotificationDate | date:'dd/MM/yyyy'}}</div>
                        </div>
                       </td>
                    <td *ngIf="isHistory">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">{{"COMPANY.CHANCE_DAYS_NUMBER" | translate}}</div>
                            <div class="value">{{company.daysNumber}}</div>
                        </div>
                    </td>
                    <td *ngIf="isHistory" style="width: 7%">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"ACTIONS.EDIT" | translate}}
                            </div>
                            <div class="value">
                                <div class="table-row-actions-container">
                                    <div class="edit-action action cursor-pointer" (click)="updateNotificationSetting(company)">
                                        <i class="fas fa-edit"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isBilling" style="width: 7%">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"GENERAL.DELETE" | translate}}
                            </div>
                            <div class="value">
                                <div class="table-row-actions-container">
                                    <div class="delete-action action cursor-pointer" (click)="deleteCompanyPayment(company.id)">
                                        <i class="fas fa-trash-alt"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isManagement" style="width: 7%">
                        <div class="data-item-container">
                            <div class="label hidden-md-up">
                                {{"MANAGE_CONTAINERS.PRINT" | translate}}
                            </div>
                            <div class="value">
                                <div class="table-row-actions-container">
                                    <div class="edit-action action cursor-pointer" (click)="printReport(company.id, $event)">
                                        <i class="fas fa-print"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="header-filters d-flex flex-row"
         style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
        <div class="cod-title flex-1">
            {{totalRecordLabel| translate}}: {{totalRecords}}
        </div>
    </div>
</div>



