import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShipmentsService} from '../../../../shipment/services/shipments.service';

@Component({
    selector: 'app-package-contents-dialog',
    templateUrl: './package-contents-dialog.component.html',
    styleUrls: ['./package-contents-dialog.component.scss']
})
export class PackageContentsDialogComponent implements OnInit {
    currentLang = '';
    totalRecordsNo = 0;
    dataList: any[] = [];
    packageId;
    filterParams: any = {
        page: 1,
        pageSize: 20
    };
    isLoading = false;


    constructor(private translate: TranslateService,
                private shipmentService: ShipmentsService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.filterParams.packageId = this.packageId;
        this.getContents(true);
    }


    getContents(forceFetch = false) {
        if (this.totalRecordsNo === this.dataList.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams.page = 1;
        }
        this.isLoading = true;
        this.shipmentService.getPackageContentsReport('BY_BARCODE', this.filterParams).subscribe(
            (res: any) => {
                const fetchedData = res.data;
                this.totalRecordsNo = res.totalRecordsNo;
                if (forceFetch) {
                    this.dataList = fetchedData;
                } else {
                    this.dataList.push(...fetchedData);
                }
                this.isLoading = false;
            }, error => {
                console.error(error);
            }
        );
    }

    loadLazyContents() {
        this.filterParams.page++;
        this.getContents();
    }

    @HostListener('scroll', ['$event'])
    onTableScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyContents();
        }
    }

    closeModal() {
        this.activeModal.close();
    }

}
