import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {StateCardModel} from '../models/state-card.model';
import {DashboardStatsModel} from '../models/dashboard-stats.model';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    private dashboardStats$: BehaviorSubject<DashboardStatsModel> =
        new BehaviorSubject<DashboardStatsModel>({topCardStats: [], businessStats: [], bottomCardsStats: []});

    constructor(private http: HttpClient) {
    }

    public getDashboardStats() {
        return this.http.get(DASHBOARD_CONSTANT.API.GET_DASHBOARD_DATA, {});
    }

    public fetchDashboardStats() {
        of([
            {
                label: 'DASHBOARD.CARDS.TOTAL_BOOKING',
                value: '0',
            },
            {
                label: 'DASHBOARD.CARDS.SHIPPED',
                value: '73',
            },
            {
                label: 'DASHBOARD.CARDS.DELIVERED',
                value: '6',
            },
            {
                label: 'DASHBOARD.CARDS.IN_PROGRESS',
                value: 71,
            },
            {
                label: 'DASHBOARD.CARDS.TOTAL_CUSTOMERS',
                value: 14
            },
            {
                label: 'BRANCHES.TITLE',
                value: 1
            },
            {
                label: 'USERS.ACTIVE_USERS',
                value: 2
            },
            {
                label: 'GENERAL.EXPIRY_DATE',
                value: new Date()
            },
            {
                label: 'GENERAL.PAYMENT_GATEWAY',
                value: 'Yes'
            },
            {
                label: 'GENERAL.TOTAL_REVENUE',
                value: 'USD 1423$'
            },
            {
                label: 'GENERAL.PAYMENT_STATUS',
                value: 'Active'
            },
            {
                label: 'CONTAINERS.DRIVERS.TITLE',
                value: 2
            }
        ]).subscribe(
            (data: StateCardModel[]) => {
                const businessCards: StateCardModel[] = [];
                const topCards: StateCardModel[] = [];
                const bottomCards: StateCardModel[] = [];

                // Pre process the data before display
                data.forEach(
                    (state: StateCardModel) => {
                        switch (state.label) {
                            case 'DASHBOARD.CARDS.TOTAL_BOOKING':
                                state.backgroundColor = '#9c53b1';
                                state.icon = 'fas fa-globe-americas';
                                topCards.push(state);
                                break;
                            case 'DASHBOARD.CARDS.SHIPPED':
                                state.backgroundColor = '#ff4529';
                                state.icon = 'fas fa-exchange-alt';
                                topCards.push(state);
                                break;
                            case 'DASHBOARD.CARDS.DELIVERED':
                                state.backgroundColor = '#33599d';
                                state.icon = 'fas fa-check-circle';
                                topCards.push(state);
                                break;
                            case 'DASHBOARD.CARDS.IN_PROGRESS':
                                state.backgroundColor = '#9c53b1';
                                state.icon = 'fas fa-plane';
                                topCards.push(state);
                                break;
                            case 'DASHBOARD.CARDS.TOTAL_CUSTOMERS':
                                state.icon = 'fas fa-network-wired';
                                businessCards.push(state);
                                bottomCards.push(state);
                                break;
                            case 'BRANCHES.TITLE':
                                state.icon = 'fas fa-code-branch';
                                businessCards.push(state);
                                bottomCards.push(state);
                                break;
                            case 'USERS.ACTIVE_USERS':
                                state.icon = 'fas fa-lock';
                                businessCards.push(state);
                                bottomCards.push(state);
                                break;
                            case 'CONTAINERS.DRIVERS_TITLE':
                                state.icon = 'fas fa-users';
                                businessCards.push(state);
                                bottomCards.push(state);
                                break;
                            default:
                                businessCards.push(state);
                                break;
                        }
                    }
                );

                this.dashboardStats$.next({
                    businessStats: businessCards,
                    topCardStats: topCards,
                    bottomCardsStats: bottomCards
                });
            }
        );
    }

    public getCards(params) {
        return this.http.get(DASHBOARD_CONSTANT.API.GET_CARDS, {params: params});
    }

    public getCodCards(params) {
        return this.http.get(DASHBOARD_CONSTANT.API.GET_COD_CARDS, {params: params});
    }

    public getListOfBestCustomers(queryUrl) {
        return this.http.post(DASHBOARD_CONSTANT.API.GET_LIST_OF_BEST_CUSTOMERS + queryUrl, {});
    }
    public getListOfBestDrivers(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.GET_LIST_OF_BEST_DRIVERS + queryUrl, body);
    }
    public getDeliveredToAllPackagesChartData(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.GET_DELIVERED_PERCENTAGE_TO_ADDED_PACKAGES + queryUrl, body);
    }
    public exportToExcelIncomeAverage(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.EXPORT_CUSTOMER_INCOME + queryUrl, body);
    }

    public exportToExcelShippingPerCity(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.EXPORT_SHIPPING_PER_CITY + queryUrl, body);
    }
    public exportToExcelDriversEvaluation(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.EXPORT_LIST_OF_BEST_DRIVERS + queryUrl, body);
    }
    public exportToExcelDeliveredToAllPackagesRatio(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.EXPORT_DELIVERED_PERCENTAGE_TO_ADDED_PACKAGES + queryUrl, body);
    }
    public getShippingPerCity(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.GET_SHIPPING_PER_CITY + queryUrl, body);
    }
    public getShippingPerVillages(queryUrl) {
        return this.http.post(DASHBOARD_CONSTANT.API.GET_SHIPPING_PER_VILLAGES + queryUrl, {});
    }
    public getDashboardSettings() {
        return this.http.get(DASHBOARD_CONSTANT.API.GET_DASHBOARD_SETTINGS, {});
    }
    public modifyDashboardSettings(type) {
        return this.http.post(DASHBOARD_CONSTANT.API.MODIFY_DASHBOARD_SETTINGS + '?type=' +  type, {});
    }
    public getCustomerIncome(queryUrl, body) {
        return this.http.post(DASHBOARD_CONSTANT.API.GET_CUSTOMER_INCOME + queryUrl, body);
    }
    public exportDrivers(queryUrl, params) {
        return this.http.get(queryUrl, {params});
    }
    // public getDeliveredToAllPackagesRatio(queryUrl) {
    //     return this.http.post(DASHBOARD_CONSTANT.API.GET_CUSTOMER_INCOME + queryUrl, {});
    // }
}

export const DASHBOARD_CONSTANT = {
    API: {
        GET_CARDS: ':api/admin/dashboard/packages/v2/cards',
        GET_COD_CARDS: ':api/admin/customers/cod/cards',
        GET_DASHBOARD_DATA: ':api/admin/dashboard/data',
        GET_LIST_OF_BEST_CUSTOMERS: ':api/admin/dashboard/best-income',
        EXPORT_LIST_OF_BEST_CUSTOMERS: ':api/admin/dashboard/best-income/excel',
        GET_LIST_OF_BEST_DRIVERS: ':api/admin/dashboard/drivers-evaluation',
        EXPORT_LIST_OF_BEST_DRIVERS: ':api/admin/dashboard/drivers-evaluation/excel',
        GET_DELIVERED_PERCENTAGE_TO_ADDED_PACKAGES: ':api/admin/dashboard/deliverd-to-all-packages',
        EXPORT_DELIVERED_PERCENTAGE_TO_ADDED_PACKAGES: ':api/admin/dashboard/deliverd-to-all-packages/excel',
        GET_SHIPPING_PER_CITY: ':api/admin/dashboard/shipping-per-city',
        EXPORT_SHIPPING_PER_CITY: ':api/admin/dashboard/shipping-per-city/excel',
        GET_SHIPPING_PER_VILLAGES: ':api/admin/dashboard/shipping-per-village',
        MODIFY_DASHBOARD_SETTINGS: ':api/admin/dashboard/settings',
        GET_CUSTOMER_INCOME: ':api/admin/dashboard/income',
        EXPORT_CUSTOMER_INCOME: ':api/admin/dashboard/income/excel',
        GET_DELIVERED_TO_ALL_PACKAGES_RATIO: ':api/admin/dashboard/deliverd-to-all-packages',
        GET_DASHBOARD_SETTINGS: ':api/admin/dashboard/settings',
        EXPORT_PDF_DRIVERS: ':api/admin/drivers/{driverId}/packages-pdf-report',
        EXPORT_EXCEL_DRIVERS: ':api/admin/drivers/{driverId}/packages-excel-report'
    },
    ROUTE: {
        HOME_SHIPMENT_STATUS: 'home/manage-shipments',
        HOME_COD_CUSTOMER: 'home/manage-cod-customers',
        HUB_CUSTODY: 'home/hub-custody'

    }
};
