<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="package-history-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div *ngIf="role !== 'ACCOUNTANT'" class="row d-flex">
        <h3 class="package-history-header" style="flex: 1">
            {{'HISTORY.PACKAGE_HISTORY'|translate}}
        </h3>
        <div *ngIf="package && package.massCodPackageId" class="history-button cursor-pointer"
             (click)="printMassPackagePdf()">{{'HISTORY.PRINT_MASS_COD_PACKAGE' |translate}}
            <i class="print-action action cursor-pointer fas fa-print"></i>
        </div>
        <div class="history-button cursor-pointer"
             *appPermission="permissions.includes('{EDIT_PACKAGE')"
        (click)="editPackage()">
            {{"ACTIONS.EDIT" | translate}}
            <i class="fas fa-pencil-alt"></i>
        </div>
        <div class="history-button cursor-pointer"
        (click)="downloadPdf(package.id)">
            {{"ACTIONS.PRINT" | translate}}
            <i class="print-action action cursor-pointer fas fa-print"></i>
        </div>
        <div class="history-button cursor-pointer"
             *appPermission="permissions.includes('{CHANGE_PACKAGE_STATUS')"
              (click)="onChangePackageStatus()">
            {{"SHIPMENT.CHANGE_PACKAGE_STATUS" | translate}}
            <i class="fas fa-exchange-alt"></i>
        </div>
        <div class="history-button cursor-pointer"
             *appPermission="permissions.includes('{EDIT_PACKAGE')"
              (click)="onPostponePackage()">
            {{"SHIPMENT.POSTPONE_PACKAGE" | translate}}
            <i class="fas fa-clock"></i>
        </div>
    </div>
    <div *ngIf="package" class="package-history-details-container">
        <div *ngIf="package.status==='RETURNED_BY_RECIPIENT'" class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.FROM'|translate}}:  </span>
            <span>{{package.receiverFirstName}} {{package.receiverMiddleName ? package.receiverMiddleName : ''}}
                {{package.receiverLastName}}<br>
                {{package.destinationAddress.city }}
                - {{package.destinationAddress.village }}
                - {{package.destinationAddress.addressLine1}}
                {{package.destinationAddress.addressLine2 ? "- " + package.destinationAddress.addressLine2 : ''}}<br>
                <div class="phone-section">
                    <img class="phone-image" src="../../../assets/icons/phone.svg">
                      <p>
                          <a href="tel:+{{package?.receiverPhone}} "> {{ package?.receiverPhone }} </a>
                      </p>
                </div>
            </span>
        </div>
        <div *ngIf="package.status!=='RETURNED_BY_RECIPIENT'" class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.FROM'|translate}}:  </span>
            <span>{{package.senderFirstName}} {{package.senderMiddleName ? package.senderMiddleName : ''}}
                {{package.senderLastName}}<br>
                {{package.originAddress.city }}
                - {{package.originAddress.village }}
                - {{package.originAddress.addressLine1}}
                {{package.originAddress.addressLine2 ? "- " + package.originAddress.addressLine2 : ''}}<br>
                <div class="phone-section">
                    <img class="phone-image" src="../../../assets/icons/phone.svg">
                      <p>
                          <a href="tel:+{{package?.senderPhone}} "> {{ package?.senderPhone }} </a>
                      </p>
                </div>
            </span>
        </div>
        <div *ngIf="package.status==='RETURNED_BY_RECIPIENT'" class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.TO'|translate}}:  </span>
            <span>{{package.senderFirstName}} {{package.senderMiddleName ? package.senderMiddleName : ''}}
                {{package.senderLastName}}<br>
                {{package.originAddress.city }} - {{package.originAddress.village }}
                - {{package.originAddress.addressLine1}}
                {{(package.originAddress.addressLine2) ? ("- " + package.originAddress.addressLine2) : ''}}<br>
                <div class="phone-section">
                    <img class="phone-image" src="../../../assets/icons/phone.svg">
                      <p>
                          <a href="tel:+{{package?.senderPhone}} "> {{ package?.senderPhone }} </a>
                      </p>
                </div>
            </span>
        </div>
        <div *ngIf="package.status!=='RETURNED_BY_RECIPIENT'" class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.TO'|translate}}:  </span>
            <span>{{package.receiverFirstName}} {{package.receiverMiddleName ? package.receiverMiddleName : ''}}
                {{package.receiverLastName}}<br>
                {{package.destinationAddress.city }} - {{package.destinationAddress.village }}
                - {{package.destinationAddress.addressLine1}}
                {{(package.destinationAddress.addressLine2) ? ("- " + package.destinationAddress.addressLine2) : ''}}<br>
                <div class="phone-section">
                    <img class="phone-image" src="../../../assets/icons/phone.svg">
                      <p>
                          <a href="tel:+{{package?.receiverPhone}} "> {{ package?.receiverPhone }} </a>
                      </p>
                </div>
            </span>
        </div>
        <div class="details-item-container">
            {{'MANAGE_SHIPMENTS.BARCODE'|translate}}
            <img height="50px" class="barcode-image cursor-pointer"
                 [src]="package.barcodeImage">
            <span class="barcode-label hover-display-parent">
          <span>{{package.barcode}}</span>
            <i class="fas fa-copy cursor-pointer hover-display" (click)="copyText(package.barcode)"></i>
          </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.BOOKED_ON'|translate}}:  </span>
            <span>
                {{package.createdDate | date:'dd/MM/yyyy'}}  {{getLocalTime(package.createdDate)}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.DELIVERY_DATE'|translate}}:  </span>
            <span>
                {{package.deliveryDate | date:'dd/MM/yyyy'}}  {{getLocalTime(package.deliveryDate)}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.POSTPONED_DATE'|translate}}:  </span>
            <span>
                {{package.postponedDate | date:'dd/MM/yyyy HH:mm'}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.STATUS'|translate}}:  </span>
            <span>
                {{package.status | translate}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.COD'|translate}}:  </span>
            <span>
                {{package.cod}}
            </span>
        </div>
        <div class="details-item-container" *appPermission="permissions.includes('{EDIT_COST')">
            <span>{{'MANAGE_SHIPMENTS.COST'|translate}}:  </span>
            <span>
                {{package.cost}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.DIMENSIONS'|translate}}:  </span>
            <span>
                {{package.length}}X{{package.width}}X{{package.height}}X{{package.weight}}kg
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'PRODUCT.QUANTITY'|translate}}:  </span>
            <span>
                {{ package.quantity }}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.INVOICE'|translate}}:  </span>
            <span>
                {{package.invoiceNumber}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.NOTES'|translate}}:  </span>
            <span>
                {{package.notes}}
            </span>
        </div>

        <div class="details-item-container">
            <span>{{'ADD_SHIPMENT.TYPE_SHIPMENT'|translate | packageTitle}}:  </span>
            <span>
                {{package.shipmentType | translate}}
            </span>
        </div>
        <div class="details-item-container">
            <span>{{'ADD_SHIPMENT.DESCRIPTION'|translate}}:  </span>
            <span>
                {{package.description | translate}}
            </span>
        </div>
        <div *ngIf="package.billingAddress" class="details-item-container">
            <span>{{'ADD_SHIPMENT.BILLING_ADDRESS'|translate}}:  </span>
            <span style="color: #007ad9">
                {{selectedLanguage === 'en' ? package.billingAddress.city : package.billingAddress.arabicCityName }}
                - {{selectedLanguage === 'en' ? package.billingAddress.village : package.billingAddress.arabicVillageName }}
                - {{package.billingAddress.addressLine1}}
                {{package.billingAddress.addressLine2 ? "- " + package.billingAddress.addressLine2 : ''}}
            </span>
        </div>
        <div *ngIf="pkgStatus" class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.MASS_PACKAGE_STATUS'|translate}}:  </span>
            <span>
                {{pkgStatus}}
            </span>
        </div>
        <div *ngIf="package.status==='RETURNED_BY_RECIPIENT'"class="details-item-container">
            <span>{{'MANAGE_SHIPMENTS.ORIGINAL_COD'|translate}}:  </span>
            <span>
                {{package.originalCod}}
            </span>
        </div>
        <div *ngIf="package.supplierInvoice" class="details-item-container">
            <span>{{'ADD_SHIPMENT.SUPPLIER_INVOICE'|translate}}:  </span>
            <span>
                {{package.supplierInvoice}}
            </span>
        </div>
        <div *ngIf="(companyId === 1 || companyId === 37) && package.payerName" class="details-item-container">
            <span>{{'ADD_SHIPMENT.PAYER_NAME'|translate}}:  </span>
            <span>
                {{package.payerName}}
            </span>
        </div>
        <div *ngIf="(companyId === 1 || companyId === 37) && package.payerPhone" class="details-item-container">
            <span>{{'ADD_SHIPMENT.PAYER_PHONE'|translate }}:  </span>
            <span>
                {{package.payerPhone}}
            </span>
        </div>
        <div *ngIf="(companyId === 1 || companyId === 37) && package.additionalCost" class="details-item-container">
            <span>{{'ADD_SHIPMENT.ADDITIONAL_COST'|translate}}:  </span>
            <span>
                {{package.additionalCost}}
            </span>
        </div>
        <div *ngIf="package.adminNotes" class="details-item-container">
            <span>{{'GENERAL.PRIVATE_NOTES'|translate}}:  </span>
            <span>
                {{package.adminNotes}}
            </span>
        </div>
        <div *ngIf="package.isFingerprintRequired" class="details-item-container">
            <span>{{'ADD_SHIPMENTS.FINGER_PRINT' | translate}}:  </span>
            <span>
                {{'GENERAL.YES' | translate}}
            </span>
        </div>
        <div class="details-item-container">
            <span></span>
            <span></span>
        </div>
    </div>
    <div *ngFor="let shipment of packagesHistory" class="package-history-item-container">
        <div class="package-history-header row">
            <span class="package-date">
                {{shipment.date | date}}
            </span>
            <span class="username-header">
                {{'HISTORY.USER_NAME'|translate}}
            </span>
            <span class="time-header">
                {{'HISTORY.TIME'|translate}}
            </span>
        </div>
        <div *ngFor="let packageHistory of shipment.actions" class="package-history row">
            <span class="history-id border-bottom-line">
                {{packageHistory.id}}
            </span>
            <span class="history-action border-bottom-line">
                {{packageHistory.action}}
            </span>
            <span class="history-username border-bottom-line">
                {{packageHistory.userName}}
            </span>
            <span class="history-time border-bottom-line">
                {{getLocalTime(packageHistory.time)}}
            </span>
        </div>
    </div>
    <div class="row justify-content-center">
        <div *ngIf="signatureImage" class="image-signature-container">
            <img class="image-signature" [src]="signatureImage">
        </div>
        <div *ngIf="deliveryProofUrlList[0]" class="image-signature-container">
            <img class="image-signature" [src]="deliveryProofUrlList[0]">
        </div>
        <div *ngIf="deliveryProofUrlList[1]" class="image-signature-container">
            <img class="image-signature" [src]="deliveryProofUrlList[1]">
        </div>
        <div *ngIf="deliveryProofUrlList[2]" class="image-signature-container">
            <img class="image-signature" [src]="deliveryProofUrlList[2]">
        </div>
        <div *ngIf="deliveryProofUrlList[3]" class="image-signature-container">
            <img class="image-signature" [src]="deliveryProofUrlList[3]">
        </div>
    </div>
</div>
