<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="customers-pricing-table-view d-flex flex-column h-100">

    <!--<div id="submitting-container" *ngIf="isLoading">-->
    <!--<p-progressSpinner>-->
    <!--</p-progressSpinner>-->
    <!--</div>-->

    <div>
        <div class="header-filters d-flex flex-row">
            <div class="pricing-title flex-1">
                {{ title | translate }}
            </div>
            <div *ngIf="pricingLists" class="customize-pricing">
                <div class="add-button cursor-pointer" (click)="customizePricing()">
                    <i class="fas fa-plus"></i>
                    {{ 'PRICING.ACTIONS.ADD_PRICING_LIST' | translate }}
                </div>
            </div>
            <div class="">
                <div class="search-bar">
                    <div class="search-icon">
                        <i class="fas fa-search" [ngClass]="{'fa-rotate-90': selectedLanguage==='ar'}"></i>
                    </div>
                    <div class="search-input">
                        <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="search"
                               appInputDebounced
                               (inputDebounced)="onSearch()">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="customers-pricing-table flex-1 overflow-auto">
        <p-table #customersTable appPTableRtlFix dataKey="id" #table [value]="customers"
                 styleClass="customers-table flex-table" paginatorDropdownAppendTo="body"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadCustomersLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ tableHeader() | translate }}</th>
                    <th *ngIf="!pricingLists && !driverPricing">{{ 'PRICING.ACTIONS.EXPORT_TO_EXCEL'|translate }}</th>
                    <th>{{ 'PRICING.ACTIONS.VIEW'|translate }}</th>
                    <th *ngIf="!driverPricing">{{ 'GENERAL.DELETE'|translate }}</th>
                    <th *ngIf="pricingLists">{{ 'PRICING.ACTIONS.VIEW_CUSTOMERS'|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-expanded="expanded">
                <tr>
                    <ng-container *ngIf="isHubsPricing">
                        <td>{{customer.hubName}}</td>
                    </ng-container>
                   <ng-container *ngIf="!isHubsPricing">
                       <td *ngIf="!driverPricing && !pricingLists">{{customer.customerName}} {{getBusinessName(customer.businessName)}}</td>
                       <td *ngIf="driverPricing || pricingLists">{{customer.name}}</td>
                   </ng-container>
                    <td *ngIf="!pricingLists && !driverPricing">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer">
                                <div *ngIf="!customer.isPrinting" (click)="printPrice(customer)" class="p-1">
                                    <i class="fas fa-file-excel" ></i>
                                </div>
                                <div *ngIf="customer.isPrinting">
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer" (click)="viewPrice(customer)">
                                <i class="fas fa-eye"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="!driverPricing">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer"
                                 (click)="deleteCustomerPricing(customer)">
                                <i class="fas fa-trash"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="pricingLists">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer" (click)="showCustomers(customer.id)">
                                <i class="fas fa-pencil-alt"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
