<div class="detailed-reports-container">
    <div class="detailed-report" *ngIf="report==='customer-packages-detailed-reports'">
        <app-admin-report [columns]="customerReport"
                          [isPdfRemove]="false"
                          expandLink="customer-packages-detailed-report"
                          [apiUrl]="customerReportAPI"
                          reportTitle="ADMINISTRATION.CUSTOMER_PACKAGES_DETAILS_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="detailed-report" *ngIf="report==='shipped-packages-detailed-reports'">
        <app-admin-report [columns]="shippedPackagesReport"
                          expandLink="shipped-packages-detailed-report"
                          [apiUrl]="shippedPackagesReportAPI"
                          [filterDate]="true"
                          [hasFilters]="true"
                          [isRemoveSearch]="true"
                          reportTitle="ADMINISTRATION.SHIPPED_PACKAGES_DETAILED_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="detailed-report" *ngIf="report==='undelivered-packages-detailed-reports'">
        <app-admin-report [columns]="undeliveredPackagesReport"
                          expandLink="undelivered-packages-detailed-report"
                          [dataInterceptor]="formatUndeliveredPackagesData"
                          [apiUrl]="undeliveredPackagesReportAPI"
                          [filterDate]="true"
                          [hasFilters]="true"
                          [isRemoveSearch]="true"
                          reportTitle="ADMINISTRATION.UNDELIVERED_PACKAGES_DETAILED_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="detailed-report" *ngIf="report==='time-bound-packages-detailed-report'">
        <app-admin-report [columns]="timeBoundPackagesReport"
                          [apiUrl]="timeBoundPackagesReportAPI"
                          [rowDataUrl] = "fawDataUrl"
                          [hasFilters]="true"
                          [isPdfRemove] = "false"
                          [dateFilterType] = "'EXPECTED_DELIVERY_DATE'"
                          [isRemoveSearch]="false"
                          reportTitle="ADMINISTRATION.TIME_BOUND_PACKAGES_DETAILED_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="detailed-report" *ngIf="report === 'driver-attempts-report'">
        <app-admin-report [columns]="driverAttemptsReport"
                          [TYPE]="'driver-attempts-report'"
                          [apiUrl]="driverAttemptsReportAPI"
                          [APIStatistics]="driverAttemptsReportStatistics"
                          [rowDataUrl] = "driverAttemptsReportExcel"
                          [filterDrivers]="true"
                          [multipleDriversFilter]="true"
                          [statusFilter]="true"
                          [hasFilters]="true"
                          [isPdfRemove] = "false"
                          [filterDate]="false"
                          [filterBarcode]="true"
                          [isRemoveSearch]="true"
                          [isIdleCustomerReport]="true"
                          [queryParams]="{'driverType': 'TYPICAL'}"
                          reportTitle="ADMINISTRATION.DRIVER_ATTEMPTS_REPORT"></app-admin-report>
    </div>
</div>
