import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PricingService} from '../../services/pricing.service';
import {ZonesService} from '../../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {UserService} from '../../../shared/services/user.service';
import {ResetPasswordComponent} from '../../../shared/components/reset-password/reset-password.component';
import {USERS_CONSTANTS} from '../../../users/services/constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-region-regular-prices-table',
    templateUrl: './region-regular-prices-table.component.html',
    styleUrls: ['./region-regular-prices-table.component.scss']
})
export class RegionRegularPricesTableComponent implements OnInit {
    @Input() regions = [];
    @Input() companyRegions = [];
    @Input() customerID = 0;
    @Input() parcelID = 0;
    @Input() pricingList = false;
    @Output() onRegionPriceChange: EventEmitter<{}> = new EventEmitter<{}>();
    @Input() driverPricing = false;
    @Input() isHubsPricingList = false;

    isReceivingPointEnabled: boolean;
    isEnableCustomerCommissionFee = false;
    isEnableBringPrice = false;
    confirmedBefore = false;
    isPasswordConfirmationRequiredToUpdateTheShippingRate = false;
    public calculateCostPerWeight = false;


    constructor(private pricingService: PricingService, private  zonesService: ZonesService,
                private modalService: NgbModal,
                private addressTranslateServer: TranslateService, private translateService: TranslateService,
                private messageService: MessageService, private userService: UserService) {
    }

    ngOnInit() {
        this.companyRegions.forEach((companyRegion) => {
            companyRegion['isLoading'] = false;
        });
        this.isReceivingPointEnabled = this.userService.userInfo.isReceivingPointEnabled;
        this.calculateCostPerWeight = this.userService.userInfo.isEnablePricingPerkg;

        this.isEnableCustomerCommissionFee = this.userService.userInfo.isEnableCustomerCommissionFee;
        this.isEnableBringPrice = this.userService.userInfo.isEnableBringPrice;
        this.isPasswordConfirmationRequiredToUpdateTheShippingRate = this.userService.userInfo.isPasswordConfirmationRequiredToUpdateTheShippingRate;
        this.confirmedBefore = false;
    }

    actualRemoveOrUpdateRegionCost(region, index) {
        if (region.isModified) {
            this.markPackageModified(region);
        } else {
            region.isLoading = true;
            if (this.driverPricing) {
                this.pricingService.deleteDriverPrices(region.id).subscribe(() => {
                    this.companyRegions.splice(index, 1);
                    this.onRegionPriceChange.emit({});
                });
            } else {
                this.pricingService.deleteCompanyPrice(region.id).subscribe(() => {
                    region.isLoading = false;
                    this.companyRegions.splice(index, 1 );
                    this.onRegionPriceChange.emit({});
                }, (error) => {
                    region.isLoading = false;
                });
            }
        }
    }


    public removeOrUpdateRegionCost(region, index) {
       if (this.isPasswordConfirmationRequiredToUpdateTheShippingRate && !this.confirmedBefore) {
           const modal = this.modalService.open(
               ResetPasswordComponent, <any>{backdrop: 'static', windowClass: 'reset-password-modal', size: 'sm'});
           modal.componentInstance.isPasswordConfirmation = true;
           modal.result.then(
               (result) => {
                  if (result && result.valid) {
                      this.confirmedBefore = true;
                     this.actualRemoveOrUpdateRegionCost(region, index);
                  }
               }
           );
       } else {
           this.actualRemoveOrUpdateRegionCost(region, index);
       }
    }


    actualSaveRegionCost(region) {
        region.isLoading = true;
        let companyPrice = {};
        if (this.isHubsPricingList) {
            companyPrice = {
                destinationCityId: region.destinationCityId,
                price: region.price,
                returnedPrice: region.returnedPrice,
                rejectionBeforeArrivalCost: region.rejectionBeforeArrivalCost
            };
        } else {
            if (this.driverPricing) {
                companyPrice = {
                    originShippingLineId: region.originRegionId,
                    destinationShippingLineId: region.destinationRegionId,
                    price: region.price,
                    returnedPrice: region.returnedPrice,
                    rejectionBeforeArrivalCost: region.rejectionBeforeArrivalCost
                };
            } else {
                companyPrice = {
                    originRegionId: region.originRegionId,
                    destinationRegionId: region.destinationRegionId,
                    price: region.price,
                    returnedPrice: region.returnedPrice,
                    rejectionBeforeArrivalCost: region.rejectionBeforeArrivalCost,
                    type: this.pricingList ? 'PRICING_LIST' : this.customerID ? 'CUSTOMER' : 'COMPANY'
                };
            }
        }

        if (this.calculateCostPerWeight) {
            companyPrice['maxFreeWeight'] = region.maxFreeWeight;
            companyPrice['additionalCostPerKg'] = region.additionalCostPerKg;
        }


        if (this.isReceivingPointEnabled) {
            companyPrice['deliveryCostToTheReceivingPoint'] = region.deliveryCostToTheReceivingPoint;
        }
        if (this.isEnableCustomerCommissionFee) {
            companyPrice['commissionFee'] = region.commissionFee;
        }

        if (this.isEnableBringPrice) {
            companyPrice['bringPrice'] = region.bringPrice;
        }
        if (this.customerID) {
            if (this.isHubsPricingList) {
                companyPrice['fromHubId'] = this.customerID;
            } else if (this.pricingList) {
                companyPrice['entityId'] = this.customerID;
            } else {
                companyPrice['customerId'] = this.customerID;
            }
        }
        if (this.parcelID) {
            companyPrice['parcelTypeId'] = this.parcelID;
        }

        if (region.isNew) {

            if (this.customerID && !this.pricingList) {
                if (this.driverPricing) {
                    this.saveDriverPricing(companyPrice, region, this.customerID);
                } else {
                    this.pricingService.saveCustomerPrice(companyPrice).subscribe((response) => {
                        this.translateService.get(
                            'ALERTS.REGION_PRICE_ADDED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                    delete region['isNew'];
                                    delete region['isModified'];
                                    region.isLoading = false;
                                    this.onRegionPriceChange.emit({});
                                }
                            );
                    }, (error) => {
                        region.isLoading = false;
                    });
                }

            } else {
                if (this.driverPricing) {
                    this.saveDriverPricing(companyPrice, region);
                } else {
                    this.pricingService.saveCompanyPrice(companyPrice, this.pricingList ? 'PRICING_LIST' : 'COMPANY').subscribe((response) => {
                        this.translateService.get(
                            'ALERTS.REGION_PRICE_ADDED_SUCCESSFULLY'
                        )
                            .subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                    delete region['isNew'];
                                    delete region['isModified'];
                                    region.isLoading = false;
                                    this.onRegionPriceChange.emit({});
                                }
                            );
                    }, (error) => {
                        region.isLoading = false;
                    });
                }
            }
        } else {
            if (this.driverPricing) {
                this.editDriverPricing(companyPrice, region);
            } else {
                this.editRegionCost(companyPrice, region);
            }
        }
    }
    public saveRegionCost(region) {
        if (this.isPasswordConfirmationRequiredToUpdateTheShippingRate && !this.confirmedBefore) {
            const modal = this.modalService.open(
                ResetPasswordComponent, <any>{backdrop: 'static', windowClass: 'reset-password-modal', size: 'sm'});
            modal.componentInstance.isPasswordConfirmation = true;
            modal.result.then(
                (result) => {
                    if (result && result.valid) {
                        this.confirmedBefore = true;
                        this.actualSaveRegionCost(region);
                    }
                }
            );
        } else {
            this.actualSaveRegionCost(region);
        }

    }
    private editDriverPricing(driverEarning, region) {
        this.pricingService.putDriverPrice(driverEarning, region.id).subscribe(() => {
            this.translateService.get(
                'USER_INFORMATION.EDITED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        region.isLoading = false;
                        delete region['isModified'];
                    }
                );
        }, error => {
            console.error(error);
            region.isLoading = false;
        });
    }
    private saveDriverPricing(driverEarning, region = null, driverId = 0) {
        this.pricingService.postDriverPrices(driverEarning, driverId ? driverId : region.id).subscribe((response) => {
            this.translateService.get(
                'ALERTS.REGION_PRICE_ADDED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        if (region != null) {
                            delete region['isNew'];
                            delete region['isModified'];
                            region.isLoading = false;
                        }
                        driverEarning.id = response;
                        this.onRegionPriceChange.emit({});
                    }
                );
        }, error => {
            console.error(error);
            region.isLoading = false;
        });
    }
    private editRegionCost(regionCost, region) {
        this.pricingService.editCustomerPrice(regionCost, region.id).subscribe((response) => {
            this.translateService.get(
                'ALERTS.REGION_PRICE_ADDED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        delete region['isModified'];
                        region.isLoading = false;
                        // this.onRegionPriceChange.emit({});
                    }
                );
        }, (error) => {
            region.isLoading = false;
        });
    }

    public addRegionCost() {
        const regionCost = {
            price: 0,
            deliveryCostToTheReceivingPoint: 0,
            commissionFee: 0,
            returnedPrice: 0,
            bringPrice: 0,
            rejectionBeforeArrivalCost: 0,
            isNew: true,
            isLoading: false
        };
        if (this.isHubsPricingList) {
            regionCost['destinationCityId'] = this.regions[0].value;
        } else {
            regionCost['originRegionId'] = this.regions[0].value;
            regionCost['destinationRegionId'] = this.regions[0].value;
        }
        this.companyRegions.push(regionCost);
    }
    public markAsEdited(region, index) {
        this.companyRegions.splice(index, 1 );
        region.isModified = true;
        this.companyRegions.splice(index, 0, region);
    }

    public modifyRegionCost(regionCost) {
        if (!regionCost.isNew && regionCost.isModified) {

        }
    }

    markPackageModified(regionCost) {
        if (!regionCost['isNew']) {
            this.saveRegionCost(regionCost);
        }
    }
}
