<p-toast></p-toast>
<div class="company-config-container p-3">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="mr-3 ml-3">
        {{'ADMINISTRATION.COMPANY_CONFIGURATION' | translate}}
    </div>
    <div class="d-flex view-container">
        <div class="h-100 m-2 border-top-category">
            <div class="category-container ">
                <ng-container *ngFor="let category of categoriesDetail">
                    <div *ngIf="!category.hidden" (click)="setSelectedCategory(category)" class="category-nav"
                         [ngClass]="{'active': category.name == selectedCategory.name}">
                        {{category.label | translate | packageTitle}}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="h-100 m-2 border-top-category flex-1">

            <div class="items-container flex-1">
                <form [formGroup]="form" class="d-flex flex-column">
                    <ng-container>
                        <div class="h-100 d-flex flex-column overflow-auto">
                            <div class="h-100 d-flex flex-column overflow-auto">
                                <form [formGroup]="form.controls.company" class="d-flex flex-column">

                                    <ng-container *ngFor="let checkbox of checkBoxInfo">
                                        <div class="package-switch-container"
                                             [ngClass]="{'hidden': (isHidden(checkbox)) || checkbox.category != selectedCategory.name || (checkbox.customVisibility && checkbox.customVisibility())}">
                                            <div class="row">
                                                <p-inputSwitch *ngIf="!checkbox.type" class="d-flex mt-2"
                                                               (onChange)="triggerChange($event, checkbox.controller); (checkbox.onChange ? checkbox.onChange($event) : {})"
                                                               [formControlName]="checkbox.controller"></p-inputSwitch>

                                                <input style="width: 3rem" *ngIf="checkbox.type == 'number'"
                                                       type="number"
                                                       (change)="triggerStringChange($event, checkbox.controller)"
                                                       [formControlName]="checkbox.controller"/>

                                                <div class="label-container flex-1">
                                                    <div class="label-span">{{checkbox.label | translate  | packageTitle}}</div>
                                                    <div class="label-description">{{(checkbox.subLabel || "")  | translate  | packageTitle}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </form>
                            </div>
                        </div>
                    </ng-container>
                </form>
                <div class="action-container">
                    <button type="button" class="controller-button cursor-pointer next d-flex"
                            [ngClass]="{'disabled-btn' : numberOfChanges == 0 || form.invalid}"
                            (click)="submit($event)" [disabled]="!canSubmit()"
                            style="align-items: center">
                        <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.SAVE" | translate }}</span>
                    </button>
                    <div class="cancel-action" [ngClass]="{'disabled-btn' : numberOfChanges == 0}"
                         (click)="restBtnClicked($event)"
                         style="align-items: center">
                        <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.RESET_SETTINGS" | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
