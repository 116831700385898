<p-overlayPanel #tripsFiltersMenu
                class="tickets-filters-menu"
                [styleClass]="'tickets-filters-menu-parent'"
                [dismissable]="true"
                (onHide)="onHideFiltersMenu()"
                [showCloseIcon]="false">
    <form [formGroup]="filtersForm">
        <div class="tickets-filters-menu-container">
            <div class="filters-header">
                <div class="filters-menu-title">
                    <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                </div>
            </div>
            <div class="filters-inputs-group">
                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_CITY' | translate}}</span></div>
                    <div class="value">
                        <app-input-field-component
                                [form]="filtersForm"
                                field="label"
                                [dataKey]="'value'"
                                [forceSelection]="true"
                                [displayDropDown]="true"
                                [searchSuggestions]="regionOptions"
                                (onSearch)="getRegions($event.query)"
                                controlName="fromCity"
                                [options]="regionOptions"
                                [isRequired]="true"
                                [appendTo]="'body'"
                                controlType="autoComplete">
                        </app-input-field-component >
                    </div>
                </div>

                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_CITY' | translate}}</span></div>
                    <div class="value">
                        <app-input-field-component
                                [form]="filtersForm"
                                field="label"
                                [dataKey]="'value'"
                                [forceSelection]="true"
                                [displayDropDown]="true"
                                [searchSuggestions]="regionOptions"
                                (onSearch)="getRegions($event.query)"
                                controlName="toCity"
                                [options]="regionOptions"
                                [isRequired]="true"
                                [appendTo]="'body'"
                                controlType="autoComplete">
                        </app-input-field-component >
                    </div>
                </div>


                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.DAYS' | translate}}</span></div>
                    <div class="value day-filter">
                        <p-multiSelect [options]="daysList"
                                       formControlName="days"
                                       #selectedDaysDropDown
                                       maxSelectedLabels="0"
                                       selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                       [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                       defaultLabel=""
                                       optionLabel="label"
                                       optionValue="value">
                            <ng-template let-day pTemplate="item">
                                {{ day.label | translate }}
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>

                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_TIME' | translate}}</span></div>
                    <div class="value day-filter" [formGroup]="filtersForm">
                        <p-calendar formControlName="fromTime" showTime="true" [timeOnly]="true" [hourFormat]="24"></p-calendar>
                    </div>
                </div>
                <div class="data-item">
                    <div class="label lable-for-filter"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_TIME' | translate}}</span></div>
                    <div class="value day-filter" [formGroup]="filtersForm">
                        <p-calendar formControlName="toTime" showTime="true" [timeOnly]="true" [hourFormat]="24"></p-calendar>
                    </div>
                </div>
                <div class="data-item">
                    <div class="label lable-for-filter "><span> {{'TRIPS_BETWEEN_CITIES.FORM.PRICE' | translate}}</span></div>
                    <div class="value day-filter w-100" [formGroup]="filtersForm">
                        <input class="cost-input p-inputtext w-100" [formControlName]="'cost'" type="number"/>
                    </div>
                </div>
            </div>

            <div class="filters-menu-actions">
                <div class="reset-action filter-menu-action" (click)="resetFilters($event)">
                    <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                </div>
                <div class="apply-filters-action filter-menu-action" (click)="applyFilters($event)">
                    <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                </div>
            </div>

        </div>

    </form>
</p-overlayPanel>

<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="trips-between-cities h-100">
    <div class="header mb-2 d-flex justify-content-between align-items-center">
        <div class="page-header-title">
            <div class="title">{{'ADMINISTRATION.TRIPS_BETWEEN_CITIES_V1' | translate}}</div>
            <!--            <div class="total-number-badge">15</div>-->
        </div>
        <div class="actions-filters-container d-flex">
            <div class="filters">
                <div class="toggle-filters-btn">
                    <div class="icon" (click)="showFiltersMenu($event)">
                        <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                        <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                    </div>
                </div>
            </div>
            <div class="actions d-flex">
                <div class="add-trip action d-flex" (click)="showNewTrip = true">
                    <div class="icon">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="label">
                        {{'TRIPS_BETWEEN_CITIES.ADD_NEW_TRIP' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="trips">
        <div *ngIf="isLoading" class="trips-loader">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <ng-container *ngIf="trips.length">
            <div *ngFor="let trip of trips" class="trip-container d-flex w-100">
                <div class="data-container justify-content-center w-100">
                    <div class="data-values justify-content-between align-items-center d-flex">
                        <div></div>
                        <div class="d-flex main-trip-data">
                            <div class="data-item">
                                <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_CITY' | translate}}</span></div>
                                <div class="value">
                                    <app-input-field-component
                                            [form]="trip.destinationsForm"
                                            field="label"
                                            [dataKey]="'value'"
                                            [forceSelection]="true"
                                            [displayDropDown]="true"
                                            [searchSuggestions]="regionOptions"
                                            (onSearch)="getRegions($event.query)"
                                            controlName="fromCity"
                                            [options]="regionOptions"
                                            [isRequired]="true"
                                            [appendTo]="'body'"
                                            controlType="autoComplete">
                                    </app-input-field-component >
                                </div>
                            </div>
                            <div class="data-item">
                                <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_CITY' | translate}}</span></div>
                                <div class="value">
                                    <app-input-field-component
                                            [form]="trip.destinationsForm"
                                            field="label"
                                            [dataKey]="'value'"
                                            [forceSelection]="true"
                                            [displayDropDown]="true"
                                            [searchSuggestions]="regionOptions"
                                            (onSearch)="getRegions($event.query)"
                                            controlName="toCity"
                                            [options]="regionOptions"
                                            [isRequired]="true"
                                            [appendTo]="'body'"
                                            controlType="autoComplete">
                                    </app-input-field-component >
                                </div>
                            </div>
                            <div class="data-item cursor-pointer" (click)="trip.showDetails = !trip.showDetails">
                                <img src="../../assets/icons/arrow_down.svg" alt="">
                                {{(trip.showDetails ? 'TRIPS_BETWEEN_CITIES.HIDE_DETAILS': 'TRIPS_BETWEEN_CITIES.SHOW_DETAILS' )| translate}}
                            </div>
                        </div>
                        <div class="delete-trip" (click)="deleteMainTrip(trip.destinationsForm)">
                            <i class="fas fa-trash"></i>
                        </div>
                    </div>
                    <div style="height: 30px;"></div>
                    <ng-container *ngIf="trip.showDetails">
                        <div class="details-table d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let subTrip of trip.trips" [formGroup]="subTrip.subTripForm">
                                    <td  [ngClass]="{'has-error': (subTrip.subTripForm.get('tripName').touched && subTrip.subTripForm.get('tripName').errors)}">
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TRIP_NUMBER' | translate}}</span></div>
                                        <div class="value"><input [formControlName]="'tripName'" type="text"/></div>
                                    </td>
                                    <td>
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.DAYS' | translate}}</span></div>
                                        <div class="value">
                                            <p-multiSelect [options]="daysList"
                                                           formControlName="days"
                                                           #selectedDaysDropDown
                                                           maxSelectedLabels="0"
                                                           selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                                           [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                                           defaultLabel=""
                                                           optionLabel="label"
                                                           optionValue="value">
                                                <ng-template let-day pTemplate="item">
                                                    {{ day.label | translate }}
                                                </ng-template>
                                            </p-multiSelect>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_TIME' | translate}}</span></div>
                                        <div class="value">
                                            <p-calendar [formControlName]="'fromTime'" showTime="true" [timeOnly]="true" [hourFormat]="24"></p-calendar>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_TIME' | translate}}</span></div>
                                        <div class="value">
                                            <p-calendar [formControlName]="'toTime'" showTime="true" [timeOnly]="true" [hourFormat]="24"></p-calendar>
                                        </div>

                                    </td>
                                    <td>
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.PRICE' | translate}}</span></div>
                                        <div class="value"><input [formControlName]="'cost'" type="number"/></div>
                                    </td>
                                    <td  [ngClass]="{'has-error': (subTrip.subTripForm.get('capacity').touched && subTrip.subTripForm.get('capacity').errors)}">
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.CAPACITY' | translate}}</span></div>
                                        <div class="value"><input [formControlName]="'capacity'" type="text"/></div>
                                    </td>

                                    <td  [ngClass]="{'has-error': (subTrip.subTripForm.get('parcelType').touched && subTrip.subTripForm.get('parcelType').errors)}">
                                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.SHIPMENT_TYPE' | translate}}</span></div>
                                        <div class="value">
                                            <p-dropdown [options]="parcelsOptions"
                                                        [formControlName]="'parcelType'"
                                                        #selectedParcelsDropDown
                                                        maxSelectedLabels="0"
                                                        selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                                        [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                                        defaultLabel=""
                                                        optionLabel="label"
                                                        optionValue="value">
                                                <ng-template let-parcel pTemplate="item">
                                                    {{ parcel.label | translate }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="sub-trip-actions d-flex">
                                            <div class="add-action action flex-2d-center" (click)="editSubTrip(trip.destinationsForm, subTrip.subTripForm, subTrip.id)"><i class="fas fa-edit"></i></div>
                                            <div class="delete-trip action flex-2d-center" (click)="deleteSubTrip(subTrip.id)"><i class="fas fa-trash"></i></div>
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>



        <div *ngIf="showNewTrip" class="trip-container new-trip d-flex w-100">
            <div class="data-container justify-content-center w-100">
                <div class="data-values justify-content-center align-items-center d-flex">
                    <div class="data-item">
                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_CITY' | translate}}</span></div>
                        <div class="value">
                            <app-input-field-component
                                    [form]="destinationsForm"
                                    field="label"
                                    [dataKey]="'value'"
                                    [forceSelection]="true"
                                    [displayDropDown]="true"
                                    [searchSuggestions]="regionOptions"
                                    (onSearch)="getRegions($event.query)"
                                    controlName="fromCity"
                                    [options]="regionOptions"
                                    [isRequired]="true"
                                    [appendTo]="'body'"
                                    controlType="autoComplete">
                            </app-input-field-component >
                        </div>
                    </div>
                    <div class="data-item">
                        <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_CITY' | translate}}</span></div>
                        <div class="value">
                            <app-input-field-component
                                    [form]="destinationsForm"
                                    field="label"
                                    [dataKey]="'value'"
                                    [forceSelection]="true"
                                    [displayDropDown]="true"
                                    [searchSuggestions]="regionOptions"
                                    (onSearch)="getRegions($event.query)"
                                    controlName="toCity"
                                    [options]="regionOptions"
                                    [isRequired]="true"
                                    [appendTo]="'body'"
                                    controlType="autoComplete">
                            </app-input-field-component >
                        </div>
                    </div>

                </div>
                <div style="height: 30px;"></div>
                <ng-container *ngIf="showDetailsTable">
                    <div class="details-table d-flex justify-content-center">
                        <table>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let trip of addTripsForms" [formGroup]="trip.form">
                                <td>
                                    <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.DAYS' | translate}}</span></div>
                                    <div class="value">
                                        <p-multiSelect [options]="daysList"
                                                       [formControlName]="'days'"
                                                       #selectedDaysDropDown
                                                       maxSelectedLabels="0"
                                                       selectedItemsLabel="{{ '{0} ' + ('GENERAL.ELEMENTS' | translate) }}"
                                                       [filterPlaceHolder]="'GENERAL.SEARCH' | translate"
                                                       defaultLabel=""
                                                       optionLabel="label"
                                                       optionValue="value">
                                            <ng-template let-day pTemplate="item">
                                                {{ day.label | translate }}
                                            </ng-template>
                                        </p-multiSelect>
                                    </div>
                                </td>
                                <td>
                                    <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.FROM_TIME' | translate}}</span></div>
                                    <div class="value">
                                        <p-calendar [formControlName]="'fromTime'" showTime="true" [timeOnly]="true" [hourFormat]="12"></p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.TO_TIME' | translate}}</span></div>
                                    <div class="value">
                                        <p-calendar [formControlName]="'toTime'" showTime="true" [timeOnly]="true" [hourFormat]="12"></p-calendar>
                                    </div>

                                </td>
                                <td>
                                    <div class="label d-flex justify-content-center"><span>{{'TRIPS_BETWEEN_CITIES.FORM.PRICE' | translate}}</span></div>
                                    <div class="value"><input [formControlName]="'cost'" type="number"/></div>
                                </td>
                                <td>
                                    <div class="add-action action flex-2d-center" (click)="addTrip(trip.form)"><i class="fas fa-plus"></i></div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
