<div class="main-container" [formGroup]="form" *ngIf="form">
    <div class="header">
        <div class="modal-title">
            {{ titleKey | translate }}
        </div>
        <div class="close-icon" (click)="closeModal()">
            <img src="../../../assets/icons/cancel.svg" alt="icon" />
        </div>
    </div>

    <p-selectButton [options]="selectionOptions"
                    *ngIf="selectionOptions.length !== 1"
                    formControlName="source">

    </p-selectButton>

    <div class="select-container">
        <ng-select *ngIf="form.value.source === 'DRIVER'"
                   formControlName="driverId"
                   bindLabel="label" bindValue="value"
                   placeholder="{{ 'SEARCH_PLACEHOLDERS.DRIVER' | translate }}"
                   notFoundText="{{ 'NOT_FOUND_PLACEHOLDERS.DRIVER' | translate }}"
                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}..."
                   typeToSearchText="{{ 'SEARCH_PLACEHOLDERS.DRIVER' | translate }}"
                   [loading]="isLoading"
                   [typeahead]="searchSubject"
                   [items]="driversOptions">

        </ng-select>
        <ng-select *ngIf="form.value.source === 'HUB'"
                   formControlName="hubId"
                   bindLabel="label" bindValue="value"
                   placeholder="{{ 'SEARCH_PLACEHOLDERS.HUB' | translate }}"
                   notFoundText="{{ 'NOT_FOUND_PLACEHOLDERS.HUB' | translate }}"
                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}..."
                   typeToSearchText="{{ 'SEARCH_PLACEHOLDERS.HUB' | translate }}"
                   [loading]="isLoading"
                   [typeahead]="searchSubject"
                   [items]="hubsOptions">

        </ng-select>
        <ng-select *ngIf="form.value.source === 'PARTNER'"
                   formControlName="partnerId"
                   bindLabel="label" bindValue="value"
                   placeholder="{{ 'SEARCH_PLACEHOLDERS.PARTNER' | translate }}"
                   notFoundText="{{ 'NOT_FOUND_PLACEHOLDERS.PARTNER' | translate }}"
                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}..."
                   typeToSearchText="{{ 'SEARCH_PLACEHOLDERS.PARTNER' | translate }}"
                   [loading]="isLoading"
                   [typeahead]="searchSubject"
                   [items]="partnersOptions">

        </ng-select>
    </div>
    <div class="bottom-actions">
        <div class="reset-button" (click)="closeModal()">
            <i class="fas fa-times"></i>
            {{"ACTIONS.CANCEL" | translate}}
        </div>
        <div class="add-button"
             (click)="onSubmit()"
             [ngClass]="{'disabled-btn disabled': form.invalid || isLoading}">
            <i class="fas fa-check"></i>
            {{(isPickWithDriver ? 'ACCOUNTING.PICK_WITH_DRIVER': 'ACCOUNTING.START_RECEIVING') | translate }}
        </div>
    </div>
</div>
