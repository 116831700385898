<div class="form-group-2" [ngStyle]="cssStyle" [ngClass]="[isFilter ? 'filter' : 'normal', filterActive ? 'filter-active' : '']">
    <div style="width: 100%" class="field-with-validation">
        <p-fieldset [legend]="label | translate" [styleClass]="disabled ? 'disable' : ''" [ngClass]="{'has-error': formControlObj && formControlObj.touched && formControlObj.errors,
                                                            'no-label': !showLabel}">
            <ng-content></ng-content>
        </p-fieldset>
        <app-field-error-message *ngIf="formControlObj" [formControlObj]="formControlObj"
                                 [disableField]="disabled"></app-field-error-message>
    </div>
</div>
