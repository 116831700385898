import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PaymentModel} from './payment.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewPaymentComponent} from './new-payment/new-payment.component';
import {PaymentsService} from '../../services/payments.service';
import {ActivatedRoute} from '@angular/router';
import {CompaniesService} from '../../../companies/services/companies.service';
import {Subject, timer} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {DatePipe} from '@angular/common';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';

@Component({
    selector: 'app-manage-payments',
    templateUrl: './manage-payments.component.html',
    styleUrls: ['./manage-payments.component.scss']
})
export class ManagePaymentsComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {

    isSuperAdmin = false;
    currentLang: string;
    searchContent: string;
    isLoading = false;
    payments: PaymentModel[] = [];
    filterParams: {page: number, pageSize: number, search?: string, fromDate?: string, toDate?: string, companyId?: number} = {
        page: 1,
        pageSize: 10
    };

    isLoadingCompanies = false;
    companies: { value: any, label: string }[];
    selectedCompany;
    companySearchSubject: Subject<string>;

    totalPaymentsNo = 0;
    hasMore = true;
    private currentPage = 1;

    @ViewChild('tableContainer') tableContainer;
    dateRangPickerOptions: any;


    constructor(private translateService: TranslateService,
                private companiesService: CompaniesService,
                private activatedRoute: ActivatedRoute,
                private modalService: NgbModal,
                private applicationStateService: ApplicationStateService,
                private paymentsService: PaymentsService,
                private dateRangePickerService: DateRangePickerService,
                private datePipe: DatePipe) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.activatedRoute.data.subscribe((routeData) => {
            this.isSuperAdmin = routeData && routeData.isSuperAdmin;
        });

        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });

        if (this.isSuperAdmin) {
            this.companySearchSubject = new Subject<string>();
            this.companySearchSubject
                .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
                .pipe(distinctUntilChanged())
                .subscribe((companySearch: string) => {
                    this.getCompanies(companySearch);
                });
            this.getCompanies();
        }

        this.getPayments(true);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }


    onNewPayment() {
        const modal = this.modalService.open(NewPaymentComponent, {
            backdrop: 'static',
            windowClass: 'new-payment'
        });

        modal.result.then(() => {
            this.getPayments(true);
        });
    }

    onScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadLazyPayments();
        }
    }

    private loadLazyPayments() {
        this.currentPage++;
        this.getPayments();
    }

    getPayments(forceFetch = false) {
        if (this.payments.length === this.totalPaymentsNo && this.currentPage > 1 && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.currentPage = 1;
            this.payments = [];
            this.handleFillingTheScreen();
        }
        this.isLoading = true;
        this.filterParams.page = this.currentPage;
        if (this.searchContent) {
            this.filterParams.search = this.searchContent;
        }
        if (this.selectedCompany) {
            this.filterParams.companyId = this.selectedCompany.id;
        }
        const tableBodyScrollTopBeforeAddingValue = this.tableContainer && this.tableContainer.nativeElement ? this.tableContainer.nativeElement.scrollTop : 0;
        this.paymentsService.getPayments(this.filterParams).subscribe((res: any) => {
            const fetchedPayments = res['data'];
            this.totalPaymentsNo = res['totalRecordsNo'];
            if (forceFetch) {
                this.payments = fetchedPayments;
            } else {
                this.payments.push(...fetchedPayments);
                this.hasMore = this.payments && (this.payments.length < res.totalRecordsNo);

            }

            if (this.tableContainer && this.tableContainer.nativeElement) {
                setTimeout(() => {
                    this.tableContainer.nativeElement.scrollTop = tableBodyScrollTopBeforeAddingValue;
                    this.isLoading = false;
                }, 30);
            } else {
                this.isLoading = false;
            }
        }, () => {
            this.isLoading = false;
        });
    }

    getCompanies(search?: string) {
        this.isLoadingCompanies = true;
        const params = {
            page: 1,
            pageSize: 10,
            status: 'ENABLED,DISABLED'
        };
        if (search) {
            params['search'] = search;
        }
        this.companiesService.getCompanies(params).subscribe((response: { data: any[], totalRecordsNo: number }) => {
            this.companies = response.data;
            this.isLoadingCompanies = false;
        }, () => {
            this.isLoadingCompanies = false;
        });
    }
    getStatusColor(status) {
        return this.paymentsService.getStatusColor(status);
    }
    private handleFillingTheScreen() {
        if (this.applicationStateService.getIsMobileResolution()) {
            return;
        }
        const ob = timer(1000, 1000);
        let stopTimer = false;
        const subscribe = ob.subscribe(res => {
            if (this.hasMore) {
                const hasVerticalScrollbar = this.tableContainer.nativeElement.scrollHeight > this.tableContainer.nativeElement.clientHeight;

                if (!hasVerticalScrollbar) {
                    if (!this.isLoading) {
                        this.currentPage++;
                        this.getPayments(false);
                    }
                } else {
                    stopTimer = true;
                }
            } else {
                stopTimer = true;
            }
            if (stopTimer) {
                subscribe.unsubscribe();
            }
        });
        this.addSubscription(subscribe);
    }

    onDateSelected(value: any) {
        this.filterParams.fromDate = this.datePipe.transform(new Date(value.start), 'yyyy-MM-dd');
        this.filterParams.toDate = this.datePipe.transform(new Date(value.end), 'yyyy-MM-dd');
        this.getPayments(true);
    }
}
