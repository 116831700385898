<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="manage-zones-container">
    <div class="zones-components-container">
        <div class="row">
            <div [ngClass]="{'col-md-12 col-lg-12' : isExcludeSystemZones, 'col-md-9 col-lg-9': !isExcludeSystemZones}">
                <p-card>
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="regionsURL" (onItemChosen)="onRegionChosen($event)"
                                       [selectURL]="selectRegion" #regions [type] = "'region'"
                                       [title]="'ADMINISTRATION.REGIONS'" [addURL]="addRegionURL"
                                       [unselectURL]="unselectRegion" [deleteURL]="deleteRegionURL"></app-zones>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="citiesURL" (onItemChosen)="onCityChosen($event)" [parentID]="regionID"
                                       [title]="'ADMINISTRATION.CITIES'" [addURL]="addCityURL"
                                       [assignURL]="assignCityURL" #cities
                                       [zonesURL]="getCitiesUnassigned" [addParentKey]="'regionId'"
                                       [selectURL]="selectCity"
                                       [unselectURL]="unselectCity" [deleteURL]="unassignMultipleCitiesURL"></app-zones>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 zones-component-container">
                            <app-zones [dataURL]="villagesURL" (onItemChosen)="onVillageChosen($event)"
                                       [parentID]="cityID" [receivingPointEnabled]="isReceivingPointEnabled"
                                       [title]="'ADMINISTRATION.VILLAGES'" [addURL]="addVillageURL"
                                       [assignURL]="assignVillageURL" [locationEnabled]="true"
                                       [zonesURL]="getVillagesUnassigned" [addParentKey]="'cityId'"
                                       [selectURL]="selectVillage" [locationURL]="villageLocation"
                                       [unselectURL]="unselectVillage"
                                       [allActions] = "true"
                                       [cityId] = "cityID"
                                       [isManageZones] = "true"
                                       (onSelectAll)="onCityChosen({id: cityID})"
                                       [deleteURL]="unassignMultipleVillagesURL"></app-zones>
                        </div>
                    </div>
                </p-card>
            </div>
            <div *ngIf="!isExcludeSystemZones" class="col-md-3 col-12 zones-component-container" style="margin-top: 15px;">
                <app-zones [dataURL]="allVillagesURL" (onItemChosen)="onVillageChosen($event)"
                           (onResetZones)="onResetZones()" [locationEnabled]="true"
                           [locationURL]="villageLocation" [receivingPointEnabled]="isReceivingPointEnabled"
                           [title]="'ADMINISTRATION.ALL_VILLAGES'" [allowAdd]="false" [allowMultipleSelection]="true"
                           [selectURL]="selectVillage" [unselectURL]="unselectVillage" [showFullAddress]="true"
                           [deleteURL]="unassignMultipleVillagesURL" [allowAssign]="false"
                           [isExportToExcel]="true"></app-zones>
            </div>
        </div>
    </div>
</div>

