import {Component, OnInit, ViewChild} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UsersService} from '../services/users.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ShipmentsService} from '../../shipment/services/shipments.service';
import {DatePipe} from '@angular/common';
@Component({
    selector: 'app-printed-reports',
    templateUrl: './printed-reports.component.html',
    styleUrls: ['./printed-reports.component.scss']
})
export class PrintedReportsComponent implements OnInit{
    /**
     *
     */
    public reports: any;

    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public selectedPackages;
    public isAllSelected = false;
    public isThereSelection = false;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    permissions = '';
    public first = 0; // firstElement in paginator

    public pageNumber = 0;
    public totalRecords = 0;
    public selectedLanguage = '';
    public searchContent = '';
    public fromDate;
    public toDate;
    public params = {};
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    @ViewChild('table', {static: false}) table;

    constructor(
        private router: Router,
        private usersService: UsersService,
        private shipmentsService: ShipmentsService,
        private confirmService: ConfirmationService,
        private messageService: MessageService,
        private translateService: TranslateService,
        private datePipe: DatePipe
        ) {
    }
    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
    }

    private initPrintedReports() {
        this.spinnerState = SpinnerState.LOADING;
        const params = this.createParams();
        this.usersService.getPrintedReports(params).subscribe(
            (response: any) => {
                this.reports = response.data;
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                    this.isThereSelection = false;
                }
            }, error => {
                console.error(error);
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    public loadCustomersLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.first = $event.first;
        this.initPrintedReports();
    }

    public onSearch() {
        this.pageNumber = 0;
        this.first = 0;
        this.setQueryParams();
        this.table.reset();
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }

        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return params;
    }
    public deleteReports(event) {
        event.stopPropagation();
        this.isLoading = true;
        let ids = [];

        if (this.getSelectedReports().length > 0) {
                ids = this.getSelectedReports().map(report => report.id);
        }

        if (ids.length === 0) {
            return;
        }
        this.isAllSelected = false;
        this.usersService.deletePrintedReports(ids).subscribe(() => {
            this.translateService.get(
                'ALERTS.PRINTED_REPORTS_DELETED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                        this.isLoading = false;
                        this.initPrintedReports();
                     }
                );
        }, () => {
            this.isLoading = false;

        });
    }
    public printReport(url) {
        window.open(url, '_blank');
    }

    public onToggleSelectAll(event) {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }

        this.isThereSelection = false;
        this.reports.forEach((report: any) => {
            if (selected) {
                report.isSelected = selected;
                this.isThereSelection = true;

            } else {
                report.isSelected = selected;
            }
        });
    }

    public onToggleSelection(event) {
        const selectedReports = this.getSelectedReports();
        if (selectedReports) {
            this.isThereSelection = selectedReports.length !== 0;
            this.isAllSelected = selectedReports.length === this.reports.length;
        }
    }

    private getSelectedReports() {
        const selectedReports = [];
        if (!this.reports) {
            return;
        }

        this.reports.forEach(
            (report) => {
                if (report.isSelected) {
                    selectedReports.push(report);
                }
            }
        );
        return selectedReports;
    }

    public setPaginatorQueryParam() {
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParams = {...this.params};
        if (this.searchContent) {
            queryParams['search'] = this.searchContent;
        } else { delete queryParams['search']; }

        if (this.fromDate) {
            queryParams['fromDate'] = this.fromDate;
        } else { delete queryParams['fromDate']; }
        if (this.toDate) {
            queryParams['toDate'] = this.toDate;
        } else { delete queryParams['toDate']; }
        queryParams['first'] = this.first;
        queryParams['pageSize'] = this.pageSize;
        this.router.navigate([], {queryParams: queryParams}).then(() => {

        });
    }

    public stopProp(event) {
        event.stopPropagation();
    }
}
