import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../shared/services/user.service';
import {HubsService} from '../../shared/services/hubs.service';
import {HubModel} from '../../shared/models/hub.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../services/users.service';
import {UserModel} from '../../shared/models/user.model';
import {GeographyService} from '../../shared/services/geography.service';
import {ContainersService} from '../../containers/services/containers.service';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Address} from '../../shared/models/address.class';
import {ZonesService} from '../../administration/services/zones.service';
import {USERS_CONSTANTS} from '../services/constants';
import {PricingService} from '../../shippings-rate/services/pricing.service';
import {SHARED_CONSTANTS, VALIDATION_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {TicketingSystemService} from '../../ticketing-system/services/ticketing-system.service';
import {CategoryModel} from '../../ticketing-system/models/category-model';
import {StorageService} from '../../shared/services/storage/storage.service';
import {ServiceTypesService} from '../../administration/services/service-types.service';
import {of, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FulfilmentService} from '../../fulfilment/services/fulfilment.service';

@Component({
    selector: 'app-new-user-component',
    templateUrl: 'new-user.component.html',
    styleUrls: ['new-user.component.scss']
})
export class NewUserComponent extends Address implements OnInit, OnDestroy {

    public isEdit = false;
    public userRolesOptions: { label: string, value: any }[] = [];
    public hubsOptions: { label: string, value: any }[];
    public containersOptions: { label: string, value: any }[];
    public pricingListType: { label: string, value: any }[];
    public categories: CategoryModel [] = [];
    public selectedCategory = [];
    public isLoading = true;
    public isSubmitting = false;
    public form: FormGroup;
    public lastVehicalId;
    public disableForm;
    public isSuperAdmin = false;
    public currentLang;
    public userImageApi;
    public isLcl;
    public userRole: string;
    public isAllowAddingTelecomeDevices = false;
    public doneActionStyleClass = 'done';
    vehicles: any[] = [];
    selectedVehicle: any;
    editVehicle = false;
    isHubUserRole = false;

    loggedInUserRole;
    public paymentTypeOptions: { value: string, label: string }[] = [
        {value: 'CASH', label: 'CASH'},
        {value: 'BANK_TRANSFER', label: 'BANK_TRANSFER'},
        {value: 'CHEQUE', label: 'CHEQUE'},
        {value: 'PREPAID', label: 'PREPAID'},
        {value: 'DIGITAL_WALLET', label: 'DIGITAL_WALLET'},
        {value: 'CARD', label: 'CARD'}
    ];

    public customerTypes: { value: string, label: string }[] = [];

    @Input() type;
    @Input() user: UserModel;
    @Input() editedFields = 'all';

    @ViewChild('vehiclesAc') vehiclesAc;
    @ViewChild('fileChooser') fileChooser;
    isAutoAssignDriversToPackages = false;
    isEnableAutoAssignForAllCustomers = false;
    isEnableCustomerConfirmationForReceivingMassCodPkg = false;
    shipmentServicesOptions = [];
    shipmentTypesInitialValueFilled = false;
    shipmentTypesOptions;
    accessTypeOptions;
    companyId: number;
    selectedIds: any;
    isDynamicServiceTypes = true;
    serviceTypesSubject = new Subject<string>();
    serviceTypesLoader = false;
    subscriptions: Subscription[] = [];
    showCodFees = false;
    expectedMonthlyPackagesNumberList: {label: string, value: string} [] = [];
    companiesWithoutShipmentTypes = [209]; // sare3 company
    userInfo: any;
    isSupportInternationalPhoneNumbers;
    hideEditUserRole = false;
    PASSWORD_MIN_LENGTH = VALIDATION_CONSTANTS.PASSWORD_MIN_LENGTH;
    showPhone2 = false;
    isAllCustomersAddBundles = false;
    isTicketingSystemEnabled = false;
    attachments: any;
    files = [];
    isAllowDriverToReturnPackages;
    isAdmin = false;
    isFulfilmentEnabled = false;
    warehousesOptions: { label: string, value: any }[] = [];

    self;
    constructor(
        private userService: UserService,
        private fulfilmentService: FulfilmentService,
        private serviceTypesService: ServiceTypesService,
        private usersService: UsersService,
        private hubsService: HubsService,
        private containersService: ContainersService,
        @Inject(FormBuilder) private formsBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private ticketingSystemService: TicketingSystemService,
        private geographyService: GeographyService,
        private containerService: ContainersService,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private translateService: TranslateService,
        private storageService: StorageService,
        private pricingService: PricingService,
        private zonesService: ZonesService
    ) {
        super(zonesService, translate, true);
        this.allVillages = true;
        this.userInfo = this.userService.userInfo;
        this.isTicketingSystemEnabled = this.userInfo.isTicketingSystemEnabled;
        this.isAutoAssignDriversToPackages = this.userInfo.isAutoAssignDriversToPkgs;
        this.isEnableAutoAssignForAllCustomers = this.userInfo.isEnableAutoAssignForAllCustomers;
        this.isEnableCustomerConfirmationForReceivingMassCodPkg = this.userInfo.isEnableCustomerConfirmationForReceivingMassCodPkg;
        this.isDynamicServiceTypes = this.userInfo.isPricingPerServiceTypeEnabled;
        // this.isSupportInternationalPhoneNumbers = this.userInfo.isSupportInternationalPhoneNumbers;
        this.isAllowDriverToReturnPackages = this.userInfo.isDriverCanReturnPackage;
        this.isSupportInternationalPhoneNumbers = this.userInfo.isSupportInternationalPhoneNumbers;
        this.isFulfilmentEnabled = this.userInfo.isFulfilmentEnabled;
        this.self = this;
    }

    ngOnInit() {
        this.loggedInUserRole = this.userInfo.role;
        this.isHubUserRole = this.userService.isHubUserRole;
        this.showCodFees = this.type === 'customer' && this.userService.getCurrency() === 'SAR' && !this.userService.userInfo.isSupportAdditionalFees;
        this.currentLang = this.translate.currentLang;
        this.isSuperAdmin = this.userInfo.role === 'SUPER_ADMIN';
        this.isLcl = this.userInfo.isLcl;
        this.companyId = this.userInfo.companyId;
        this.isAllCustomersAddBundles = this.userInfo.isAllCustomersAddBundles;

        this.isAllowAddingTelecomeDevices = this.userInfo.isAllowAddingTelecomeDevices;
        if (this.user) {
            this.isEdit = true;
            this.lastVehicalId = this.user.vehicleId;
            this.userImageApi = USERS_CONSTANTS.UPLOAD_IMAGE_URL + '?id=' + this.user.id + '&userType=' + this.type.toUpperCase();
            this.hideEditUserRole = this.isHubUserRole && this.userInfo.id === this.user.id;
        }
        this.fetchUserRolesOptions();

        if (['user', 'customer'].indexOf(this.type) !== -1) {
            this.fetchHubs(this.isEdit && this.user.role === 'STOCKING_AND_PACKING_EMPLOYEE');
            // this.initContainers();
            if (this.type === 'user') {
                this.getVehicles(null, true);
            }
        } else {
            this.isLoading = false;
            this.initForm();
        }
        if (this.type === 'customer') {
            this.translatePaymentType();
            this.translateCustomerTypes();
            this.initServiceTypeOptions();
            this.initShipmentTypeOptions();
            this.initAccessTypeOptions();
            this.setExpectedMonthlyPackagesNumberList();
        }
        if (this.userInfo.isPricingPerServiceTypeEnabled) {
            this.subscriptions.push(this.serviceTypesSubject
                .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
                .pipe(distinctUntilChanged())
                .subscribe((search: string) => {
                    this.getDynamicServiceTypes(search);
                }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            if (subscription instanceof Subscription) {
                subscription.unsubscribe();
            }
        });
    }

    setExpectedMonthlyPackagesNumberList() {
        this.expectedMonthlyPackagesNumberList = SHARED_CONSTANTS.EXPECTED_MONTHLY_PACKAGES_NUMBER.map(item => ({
            label: this.translateService.instant(`USERS_MANAGEMENT.EXPECTED_PACKAGES_NUMBER_LIST.${item}`),
            value: item
        }));
    }
    public getVehicles(event = null, isInit = false) {
        let  query = '?pageSize=20&page=1&onlyAvailable=true';
        if (event && event.query) {
            query += '&search=' + event.query;
        }
        if (this.isEdit) {
            query += '&driverId=' + this.user.id;
        }
        if (this.userRole === 'TICKETING_USER' || this.userRole === 'TICKETING_ADMIN_SYSTEM') {
            return;
        }
        this.containerService.fetchContainers(query).subscribe(
            (response: { vehicles: any[], totalRecordsNo: number }) => {

                        this.vehicles = response.vehicles.map((vehicleType) => {
                            return {
                                label: `${vehicleType.name  + ' - ' +  vehicleType.brand + ' (' + vehicleType.plateNo + ' )'}`,
                                value: vehicleType.id
                            };
                        });
                if (isInit) {
                    this.finishLoading();
                }
            }, (error) => {
                console.error(error);
            }
        );

    }
    chooseVehicle($event) {
        const vehicle = $event;
        const userName = vehicle.label ;
        this.updateFormValue('vehicleId', userName);
        this.selectedVehicle = JSON.parse(JSON.stringify(vehicle));
    }
    resetVehicle() {
        this.selectedVehicle = null;
        this.editVehicle = false;
        this.updateFormValue('vehicleId', '');
    }
    updateFormValue(controlName, value) {
        this.form.controls[controlName].setValue(value);
        this.form.controls[controlName].updateValueAndValidity();
        this.form.controls[controlName].markAsTouched();
    }

    private initContainers() {
        this.containerService.fetchContainers('?page=1&pageSize=500').subscribe(
            (response: any) => {
                this.containersOptions = response.vehicles.map((item) => {
                    return {value: item.id, label: item.name + '-' + item.plateNo};
                });
                this.finishLoading();
            }, (error) => {
            }, () => {
                this.isLoading = false;
            }
        );
    }
    private translatePaymentType() {
        this.translateService.get(['BANK_TRANSFERS.CASH', 'BANK_TRANSFERS.CHEQUE', 'BANK_TRANSFER', 'BANK_TRANSFERS.PREPAID',
        'BANK_TRANSFERS.DIGITAL_WALLET', 'BANK_TRANSFERS.CARD']).subscribe(
            (res) => {
                this.paymentTypeOptions = [
                    {value: 'CASH', label: res['BANK_TRANSFERS.CASH']},
                    {value: 'CHEQUE', label: res['BANK_TRANSFERS.CHEQUE']},
                    {value: 'BANK_TRANSFER', label: res['BANK_TRANSFER']},
                    {value: 'PREPAID', label: res['BANK_TRANSFERS.PREPAID']},
                    {value: 'DIGITAL_WALLET', label: res['BANK_TRANSFERS.DIGITAL_WALLET']},
                    {value: 'CARD', label: res['BANK_TRANSFERS.CARD']}
                ];
            }
        );
    }

    private translateCustomerTypes() {
        this.customerTypes = [
            {value: 'NORMAL', label: this.translateService.instant('CUSTOMER_TYPES.NORMAL')},
            {value: 'EXPRESS', label: this.translateService.instant('CUSTOMER_TYPES.EXPRESS')}
        ];
    }
    private initForm() {
        let info = {
            name: '',
            middleName: '',
            firstName: '',
            lastName: '',
            businessName: '',
            authorizedGovRegistrationNumber: '',
            expectedMonthlyPackagesNumber: '',
            email: '',
            password: '',
            addressLine1: '',
            addressLine2: '',
            village: '',
            phone: '',
            phone2: '',
            vehicleId: '',
            city: '',
            region: '',
            combined: '',
            country: 'Palestine',
            role: this.userRole ? this.userRole : '',
            hubId: '',
            isAutoApprovePackages: false,
            isAddDraftPackages: false,
            isAllowAddBundles: false,
            isShowMemberPackagesOnly: false,
            isShowSenderAddressInPackageReport: false,
            isBilled: false,
            isHideCostForMembers: false,
            isAllowNoAttachment: false,
            isAllowClerkToAddCustomers: false,
            isSwapSenderAndReceiver: false,
            isReturnPackages: false,
            isAllowClerkChangeStatus: false,
            isDeliverPackages: true,
            isPostponePackages: true,
            isDeliverReturnedPackagesToSender: true,
            isDeliverMassCodPackages: true,
            isBlocked: false,
            isDriverCanFailPackageDisabled: false,
            isSignatureOnPackageDeliveryDisabled: false,
            isDriverPickupPackagesByScanDisabled: false,
            isHideReceiverInfo: false,
            isShowPackagesInDriverApp: false,
            isAllowDriverRejectingOrders: true,
            isPickupFulfillmentPackages: true,
            isAllowAddingPackagesOnAllHub: true,
            isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: false,
            isMapViewOnly: false,
            isAllowAddingPackages: false,
            isShowCurrentReports: false,
            isEnabledAsTicketingUser: false,
            isHideSenderInfo: false,
            isShowSenderName: false,
            isShowSenderPhone: false,
            isTelecom: false,
            webhookUrl: '',
            paymentMethod: 'CASH',
            iban: '',
            pricingListId: '',
            categoryId: null,
            warehouseId: null,
            optionalNumber: '',
        };

        if (this.isEdit) {
            if (this.user.role === 'TICKETING_USER' || this.user.isEnabledAsTicketingUser) {
                this.getCategories('', true);
            }
            this.showPhone2 = !!this.user.phone2;

            info = <any>{
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                middleName: this.user.middleName,
                authorizedGovRegistrationNumber: this.user.authorizedGovRegistrationNumber,
                expectedMonthlyPackagesNumber: this.user.expectedMonthlyPackagesNumber,
                businessName: this.user.businessName,
                name: this.user.name,
                email: this.user.email,
                password: '*******',
                addressLine1: this.user.address.addressLine1,
                addressLine2: this.user.address.addressLine2,
                village: this.user.address.villageId,
                phone: this.user.phone,
                phone2: this.user.phone2,
                city: this.user.address.cityId,
                region: this.user.address.regionId,
                hub: {
                        value: this.user.address.villageId,
                        label: this.user.address.village + '-' + this.user.address.city + '-' +
                            this.user.address.region
                    },
                country: this.user.address.country,
                role: this.user.role,
                hubId: this.user && this.user.hubId ? this.user.hubId : '',
                isAutoApprovePackages: this.user.isAutoApprovePackages,
                isShowSenderAddressInPackageReport: this.user.isShowSenderAddressInPackageReport,
                isBilled: this.user.isBilled,
                isHideCostForMembers: this.user.isHideCostForMembers,
                isTelecom: this.user.isTelecom,
                isAllowNoAttachment: this.user.isAllowNoAttachment,
                isReturnPackages: this.user.isReturnPackages,
                isAllowClerkChangeStatus: this.user.isAllowClerkChangeStatus,
                isAllowClerkToAddCustomers: this.user.isAllowClerkToAddCustomers,
                isSwapSenderAndReceiver: this.user.isSwapSenderAndReceiver,
                isDeliverPackages: this.user.isDeliverPackages,
                isDeliverReturnedPackagesToSender: this.user.isDeliverReturnedPackagesToSender,
                isDeliverMassCodPackages: this.user.isDeliverMassCodPackages,
                isBlocked: this.user.isBlocked,
                isDriverCanFailPackageDisabled: this.user.isDriverCanFailPackageDisabled,
                isSignatureOnPackageDeliveryDisabled: this.user.isSignatureOnPackageDeliveryDisabled,
                isDriverPickupPackagesByScanDisabled: this.user.isDriverPickupPackagesByScanDisabled,
                isHideSenderInfo: this.user.isHideSenderInfo,
                isShowSenderPhone: this.user.isShowSenderPhone,
                isShowSenderName: this.user.isShowSenderName,
                isHideReceiverInfo: this.user.isHideReceiverInfo,
                isShowPackagesInDriverApp: this.user.isShowPackagesInDriverApp,
                isAllowDriverRejectingOrders: this.user.isAllowDriverRejectingOrders,
                isPickupFulfillmentPackages: this.user.isPickupFulfillmentPackages,
                isAllowAddingPackagesOnAllHub: this.user.isAllowAddingPackagesOnAllHub,
                isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: this.user.isDisableNotifyCCWhenDeliveryPkgAndChangeCOD,
                isMapViewOnly: this.user.isMapViewOnly,
                isAllowAddingPackages: this.user.isAllowAddingPackages,
                isShowCurrentReports: this.user.isShowCurrentReports,
                isEnabledAsTicketingUser: this.user.isEnabledAsTicketingUser,
                isPostponePackages: this.user.isPostponePackages,
                isAddDraftPackages: this.user.isAddDraftPackages,
                isAllowAddBundles: this.user.isAllowAddBundles,
                isShowMemberPackagesOnly: this.user.isShowMemberPackagesOnly,
                webhookUrl: this.user.webhookUrl ? this.user.webhookUrl : '',
                paymentMethod: this.user.paymentMethod,
                iban: this.user.iban,
                pricingListId: {value: this.user.pricingListId, label: this.user.pricingListName},
                categoryId: this.selectedCategory,
                isAutoAssignPkgsToDriver: this.user.isAutoAssignPkgsToDriver || this.isEnableAutoAssignForAllCustomers,
                isEnableConfirmationForReceivingMassCodPkg: this.user.isEnableConfirmationForReceivingMassCodPkg,
                isShowCodSumInCustomerPortalCodReports: this.user.isShowCodSumInCustomerPortalCodReports,
                isShowSenderNameInPackageReport: this.user.isShowSenderNameInPackageReport,
                type: this.user.type,
                serviceTypes: !this.isDynamicServiceTypes ? this.user.serviceTypes : [],
                optionalNumber: this.user.optionalNumber,
                customerFulfillmentType: this.user.customerFulfillmentType,
                warehouseId: this.user.warehouseId
            };
            if (this.showShipmentTypesInput()) {
                info['shipmentTypes'] = this.user.shipmentTypes;
            }
        }

        this.form = this.formsBuilder.group({
            firstName: [{value: '', disabled: !this.canEdit('name')}, Validators.required],
            lastName: [{value: '', disabled: !this.canEdit('name')}, Validators.required],
            middleName: [{value: '', disabled: !this.canEdit('name')}],
            authorizedGovRegistrationNumber: [{value: '', disabled: !this.canEdit('name')}],
            expectedMonthlyPackagesNumber: [{value: ''}],
            businessName: [{value: '', disabled: !this.canEdit('name')}, Validators.minLength(3)],
            email: [{value: '', disabled: !this.canEdit('email')}, Validators.compose([
                Validators.required,
                Validators.minLength(VALIDATION_CONSTANTS.USERNAME_EMAIL_MIN_LENGTH)
            ])],
            password: this.isEdit ? [{value: '', disabled: !this.canEdit('password')},
            ] : [{
                value: '',
                disabled: !this.canEdit('password')
            }, Validators.compose([Validators.required, Validators.minLength(VALIDATION_CONSTANTS.PASSWORD_MIN_LENGTH),
                Validators.pattern(VALIDATION_CONSTANTS.PASSWORD_PATTERN)])],
            phone: [{value: '', disabled: !this.canEdit('phone')}, [
                Validators.required,
               this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
            phone2: [{value: '', disabled: !this.canEdit('phone2')}, [
               this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
            addressLine1: [{value: '', disabled: !this.canEdit('addressLine1')}, Validators.required],
            addressLine2: [{value: '', disabled: !this.canEdit('addressLine1')}],
            city: [{value: '', disabled: !this.canEdit('city')}, Validators.required],
            region: [{value: '', disabled: !this.canEdit('region')}, Validators.required],
            village: [{value: '', disabled: !this.canEdit('village')}, Validators.required],
            combined: [{value: '', disabled: !this.canEdit('combined')}, Validators.required],
            country: [{value: '', disabled: !this.canEdit('country')}],
            role: [{value: '', disabled: !this.canEdit('role')}],
            hubId: [{value: '', disabled: !this.canEdit('name')}, Validators.required],
            vehicleId: [{value: '', disabled: !this.canEdit('vehicleId')}],
            isAutoApprovePackages: [{value: false, disabled: !this.canEdit('isAutoApprovePackages')}],
            isAddDraftPackages: [{value: false, disabled: !this.canEdit('isAddDraftPackages')}],
            isAllowAddBundles: [{value: false, disabled: !this.canEdit('isAllowAddBundles')}],
            isShowMemberPackagesOnly: [{value: false, disabled: !this.canEdit('isShowMemberPackagesOnly')}],
            isShowSenderAddressInPackageReport: [{value: false, disabled: !this.canEdit('isShowSenderAddressInPackageReport')}],
            isBilled: [{value: false, disabled: !this.canEdit('isBilled')}],
            isHideCostForMembers: [{value: false, disabled: !this.canEdit('isHideCostForMembers')}],
            isTelecom: [{value: false, disabled: !this.canEdit('isTelecom')}],
            isAllowNoAttachment: [{value: false, disabled: !this.canEdit('isAllowNoAttachment')}],
            isReturnPackages: [{value: true, disabled: !this.canEdit('isReturnPackages')}],
            isAllowClerkChangeStatus: [{value: true, disabled: !this.canEdit('isAllowClerkChangeStatus')}],
            isAllowClerkToAddCustomers: [{value: true, disabled: !this.canEdit('isAllowClerkToAddCustomers')}],
            isSwapSenderAndReceiver: [{value: false, disabled: !this.canEdit('isSwapSenderAndReceiver')}],
            isPostponePackages: [{value: true, disabled: !this.canEdit('isPostponePackages')}],
            isDeliverPackages: [{value: true, disabled: !this.canEdit('isDeliverPackages')}],
            isDeliverReturnedPackagesToSender: [{value: true, disabled: !this.canEdit('isDeliverReturnedPackagesToSender')}],
            isDeliverMassCodPackages: [{value: true, disabled: !this.canEdit('isDeliverMassCodPackages')}],
            isBlocked: [{value: false, disabled: !this.canEdit('isBlocked')}],
            isDriverCanFailPackageDisabled: [{value: false, disabled: !this.canEdit('isDriverCanFailPackageDisabled')}],
            isSignatureOnPackageDeliveryDisabled: [{value: false, disabled: !this.canEdit('isSignatureOnPackageDeliveryDisabled')}],
            isDriverPickupPackagesByScanDisabled: [{value: false, disabled: !this.canEdit('isDriverPickupPackagesByScanDisabled')}],
            isHideReceiverInfo: [{value: true, disabled: !this.canEdit('isHideReceiverInfo')}],
            isShowPackagesInDriverApp: [{value: true, disabled: !this.canEdit('isShowPackagesInDriverApp')}],
            isAllowDriverRejectingOrders: [{value: true, disabled: !this.canEdit('isAllowDriverRejectingOrders')}],
            isPickupFulfillmentPackages: [{value: this.userInfo.isFulfilmentEnabled, disabled: !this.canEdit('isPickupFulfillmentPackages')}],
            isAllowAddingPackagesOnAllHub: [{value: true, disabled: !this.canEdit('isAllowAddingPackagesOnAllHub')}],
            isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: [{value: true, disabled: !this.canEdit('isDisableNotifyCCWhenDeliveryPkgAndChangeCOD')}],
            isMapViewOnly: [{value: true, disabled: !this.canEdit('isMapViewOnly')}],
            isAllowAddingPackages: [{value: false, disabled: !this.canEdit('isAllowAddingPackages')}],
            isShowCurrentReports: [{value: false, disabled: !this.canEdit('isShowCurrentReports')}],
            isEnabledAsTicketingUser: [{value: true, disabled: !this.canEdit('isEnabledAsTicketingUser')}],
            isHideSenderInfo: [{value: true, disabled: !this.canEdit('isHideSenderInfo')}],
            isShowSenderName: [{value: true, disabled: !this.canEdit('isShowSenderName')}],
            isShowSenderPhone: [{value: true, disabled: !this.canEdit('isShowSenderPhone')}],
            webhookUrl: [{value: '', disabled: !this.canEdit('webhookUrl')}],
            paymentMethod: [{value: 'CASH', disabled: !this.canEdit('paymentMethod')}],
            iban: [{value: '', disabled: !this.canEdit('iban')}],
            pricingListId: [{value: '', disabled: !this.canEdit('pricingListId') || this.userInfo.role === 'CUSTOMER_CARE'}],
            categoryId: [{value: [], disabled: !this.canEdit('categoryId')}],
            isAutoAssignPkgsToDriver: [{value: false, disabled: !this.canEdit('isAllowNoAttachment')}],
            isShowSenderNameInPackageReport: [{value: true, disabled: !this.canEdit('isShowSenderNameInPackageReport')}],
            isEnableConfirmationForReceivingMassCodPkg: [{value: false, disabled: !this.canEdit('isEnableConfirmationForReceivingMassCodPkg')}],
            isShowCodSumInCustomerPortalCodReports: [{value: true, disabled: !this.canEdit('isShowCodSumInCustomerPortalCodReports')}],
            type: [this.type === 'customer' && !this.isEdit ? 'NORMAL' : ''],
            optionalNumber: [{value: ''}, Validators.pattern(`^[a-zA-Z0-9_.-]*$`)]
        });
        if (info.role === 'DRIVER') {
            this.form.controls.vehicleId.setValidators([Validators.required]);
            this.form.controls.vehicleId.updateValueAndValidity();
        }

        this.form.controls.country.setValue('Palestine');
        if (this.type === 'user') {
            this.form.controls.role.setValidators(Validators.required);
            this.form.controls.role.updateValueAndValidity();
            if (!this.isHubUserRole && info.role !== 'FULFILLMENT_USER') {
                this.form.controls.hubId.setValidators(Validators.required);
                this.form.controls.hubId.updateValueAndValidity();
            } else {
                this.form.controls.hubId.removeValidators(Validators.required);
                this.form.controls.hubId.updateValueAndValidity();
            }
        }
        if (this.loggedInUserRole === 'HUB_ADMIN') {
            this.form.controls.hubId.removeValidators(Validators.required);
            this.form.controls.hubId.updateValueAndValidity();
        }
        if (this.showShipmentTypesInput()) {
            this.form.addControl('shipmentTypes', this.formsBuilder.control(this.shipmentTypesOptions.map(e => e.value), Validators.required));
        }


        if (this.type === 'customer') {
            this.form.addControl('serviceTypes', this.formsBuilder.control('', Validators.required));

            if (this.userInfo.isFulfilmentEnabled) {
                this.form.addControl('customerFulfillmentType', this.formsBuilder.control('', Validators.required));
                this.form.addControl('warehouseId', this.formsBuilder.control(''));
                this.getWarehouses('', true, info.warehouseId);
            }
        }

        if (!this.isEdit && this.form && this.form.get('serviceTypes') && this.shipmentTypesOptions && !this.shipmentTypesInitialValueFilled) {
            this.form.get('serviceTypes').patchValue(this.shipmentServicesOptions.map(e => e.value));
            this.shipmentTypesInitialValueFilled = true;
        }
        if (this.companyId === 222 && this.type === 'customer') { // talaby company
            this.form.get('email').setValidators([Validators.required, Validators.email,
                Validators.minLength(VALIDATION_CONSTANTS.USERNAME_EMAIL_MIN_LENGTH)]);
        } else {
            this.form.get('email').setValidators([Validators.required,
                Validators.minLength(VALIDATION_CONSTANTS.USERNAME_EMAIL_MIN_LENGTH)]);
        }
        this.form.get('email').updateValueAndValidity();
        if (this.showCodFees) {
            this.form.addControl('codFees', this.formsBuilder.control(''));
            if (this.isEdit) {
                info['codFees'] = this.user.codFees;
            }
        }

        this.initFormEvent(this.form, 0, {
            country: '',
            region: 'region',
            city: 'city',
            village: 'village',
            combined: 'combined'
        });

        // this.selectedVehicle.id = this.form.get('vehicleId').value;
        this.form.patchValue(info);
        // console.log(this.form.get('vehicleId').value);
        if (this.isEdit) {  // city and village values have to be reset after resiogn to fix form validity on edit
            this.form.patchValue({
                combined: {
                    cityId: this.user.address.cityId,
                    cityName: this.user.address.city,
                    label: this.user.address.village + '-' + this.user.address.city + '-' +
                            this.user.address.region
                    ,
                    regionId: this.user.address.regionId,
                    regionName: this.user.address.region,
                    villageId: this.user.address.villageId,
                    villageName: this.user.address.village,
                    value: this.user.address.villageId
                }
            });
            if (this.form.get('role').value === 'DRIVER') {
                this.form.controls.vehicleId.setValidators([Validators.required]);
                this.form.controls.vehicleId.updateValueAndValidity();
                if (this.user.vehicle) {
                    const vehicle = {value: this.user.vehicle.id,
                        label: `${this.user.vehicle.name  + ' - ' +  this.user.vehicle.brand + ' (' + this.user.vehicle.plateNo + ' )'}`
                    };
                    this.chooseVehicle(vehicle);
                }
            }
            if (this.type === 'customer' && this.user?.serviceTypeList && this.isEdit) {
                const dynamicServiceTypeValues = this.user.serviceTypeList.map(serviceType => (serviceType.id));
                this.form.controls['serviceTypes'].patchValue(dynamicServiceTypeValues);
            }
        }
    }
    canEdit(controlName) {
        // Special case
        if (this.isEdit && (controlName === 'email' || controlName === 'password')) {
            // Disable the email always if it was editting mode.
            // No one can edit the email.
            return false;
        }

        if (this.editedFields === 'all') {
            return true;
        }

        return this.editedFields.includes(controlName);
    }

    private fetchUserRolesOptions() {
        const userRoles = this.userService.getRules();
        this.translate.get(userRoles).subscribe(
            (rolesTranslation) => {
                for (const roleKey in rolesTranslation) {
                    this.userRolesOptions.push(
                        {
                            label: this.transformBasedOnCurrency(rolesTranslation[roleKey]),
                            value: roleKey
                        }
                    );
                }
            }
        );
    }

    private fetchHubs(getWarehousesOnly = false, initForm = true) {
        this.isLoading = initForm;
        let observable = this.hubsService.getHubs('?page=1&pageSize=100');
        if (getWarehousesOnly) {
            const params = {
                page: 1,
                pageSize: 100
            };
            observable = this.fulfilmentService.getWarehousesNew(params);
        }
        observable.subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id};
                    }
                );

                if (initForm) {
                    this.finishLoading();
                }
            }, (error) => {
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }
        );
    }

    private finishLoading() {
        if (this.hubsOptions) {
            this.isLoading = false;
            this.initForm();
            // this.disableRest = true;
            // this.setDefaultRegionId();
            // this.disableRest = false;
        }
    }

    showIsBilledSwitchSetting() {
        return this.userInfo.isInvoicingEnabled || this.userInfo.isSupportMassInvoices;
    }
    showAddDraftPackagesSetting() {
        return this.userInfo.isSupportAddingDraftPackagesByCustomers;
    }
    showRequiredAttachmentsSetting() {
        return this.userInfo.isAllowAddingAttachment;
    }

    public exitAddNewUser() {
        this.activeModal.close();
    }

    private onAddCustomer(data) {
        delete data.role;

        this.usersService.addCustomer(data).subscribe(
            (response: any) => {
                this.uploadAttachments(response);
            }, (error) => {
                this.activeModal.close({isSuccess: false, error: error.error.error});
                this.isLoading = false;
            }, () => {
            }
        );
    }

    public onAddUser() {
        this.isLoading = true;

        const data = this.form.getRawValue();
        const body = {
            address: {
                addressLine1: data.addressLine1,
                cityId: data.city.value,
                city: data.city.label,
                country: data.country,
                villageId: data.village.value,
                village: data.village.label,
                addressLine2: data.addressLine2,
                regionId: data.region.value,
                region: data.region.label
            },
            cityId: data.city.value,
            country: data.country,
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            phone: data.phone,
            phone2: data.phone2,
            email: data.email,
            isAddDraftPackages: data.isAddDraftPackages,
            isAllowAddBundles: data.isAllowAddBundles,
            isShowMemberPackagesOnly: data.isShowMemberPackagesOnly,
            authorizedGovRegistrationNumber: data.authorizedGovRegistrationNumber,
            expectedMonthlyPackagesNumber: data.expectedMonthlyPackagesNumber,
            businessName: data.businessName,
            hubId: data.hubId,
            role: data.role.replace(' ', '_').toUpperCase(),
            isAutoApprovePackages: data.isAutoApprovePackages,
            isShowSenderAddressInPackageReport: data.isShowSenderAddressInPackageReport,
            isBilled: data.isBilled,
            isHideCostForMembers: data.isHideCostForMembers,
            isTelecom: data.isTelecom,
            isAllowNoAttachment: data.isAllowNoAttachment,
            isReturnPackages: data.isReturnPackages,
            isAllowClerkToAddCustomers: data.isAllowClerkToAddCustomers,
            isAllowClerkChangeStatus: data.isAllowClerkChangeStatus,
            isSwapSenderAndReceiver: data.isSwapSenderAndReceiver,
            isPostponePackages: data.isPostponePackages,
            isDeliverPackages: data.isDeliverPackages,
            isDeliverReturnedPackagesToSender: data.isDeliverReturnedPackagesToSender,
            isDeliverMassCodPackages: data.isDeliverMassCodPackages,
            isBlocked: data.isBlocked,
            isDriverCanFailPackageDisabled: data.isDriverCanFailPackageDisabled,
            isSignatureOnPackageDeliveryDisabled: data.isSignatureOnPackageDeliveryDisabled,
            isDriverPickupPackagesByScanDisabled: data.isDriverPickupPackagesByScanDisabled,
            isHideSenderInfo: data.isHideSenderInfo,
            isShowSenderPhone: data.isShowSenderPhone,
            isShowSenderName: data.isShowSenderName,
            isHideReceiverInfo: data.isHideReceiverInfo,
            isShowPackagesInDriverApp: data.isShowPackagesInDriverApp,
            isAllowDriverRejectingOrders: data.isAllowDriverRejectingOrders,
            isPickupFulfillmentPackages: data.isPickupFulfillmentPackages,
            isAllowAddingPackagesOnAllHub: data.isAllowAddingPackagesOnAllHub,
            isDisableNotifyCCWhenDeliveryPkgAndChangeCOD: data.isDisableNotifyCCWhenDeliveryPkgAndChangeCOD,
            isMapViewOnly: data.isMapViewOnly,
            isAllowAddingPackages: data.isAllowAddingPackages,
            isShowCurrentReports: data.isShowCurrentReports,
            isEnabledAsTicketingUser: data.isEnabledAsTicketingUser,
            paymentMethod: data.paymentMethod,
            iban: data.iban,
            pricingListId: data.pricingListId ? data.pricingListId.value : null,
            isAutoAssignPkgsToDriver: data.isAutoAssignPkgsToDriver,
            isShowSenderNameInPackageReport: data.isShowSenderNameInPackageReport,
            isEnableConfirmationForReceivingMassCodPkg: data.isEnableConfirmationForReceivingMassCodPkg,
            isShowCodSumInCustomerPortalCodReports: data.isShowCodSumInCustomerPortalCodReports,
            type: data.type,
            serviceTypes: data.serviceTypes,
            optionalNumber: data.optionalNumber
        };

        if (this.showShipmentTypesInput()) {
            body['shipmentTypes'] = data.shipmentTypes;
        }
        if (data.categoryId && ((data.role === 'TICKETING_USER') || (data.isEnabledAsTicketingUser))) {
            body['categoryId'] = data.categoryId;
        } else {
            delete body['categoryId'];
        }

        if (data.role === 'FULFILLMENT_USER') {
            delete body['hubId'];
        }
        if (this.showCodFees) {
            body['codFees'] = data.codFees ? data.codFees : 0;
        }

       if (this.form.get('role').value === 'DRIVER') {
           if (this.selectedVehicle && this.type === 'user') {
               body['vehicleId'] = this.selectedVehicle ? this.selectedVehicle.value : null;
               body['vehicle'] = this.selectedVehicle ? this.selectedVehicle : null;
           } else {
               delete body['vehicleId'];
               delete body['vehicle'];
           }
       } else {
           delete body['vehicleId'];
           delete body['vehicle'];
       }

        if (this.form.get('role').value !== 'CUSTOMER_CARE') {
            body['isSwapSenderAndReceiver'] = false;

        }
        if (this.isCustomerFulfillment()) {
            body['customerFulfillmentType'] = this.form.get('customerFulfillmentType').value;
            body['warehouseId'] = this.form.get('warehouseId').value?.value;
        }

        if (this.isSuperAdmin && this.type === 'customer' && this.isEdit) {
            body['webhookUrl'] = data['webhookUrl'];
        }

        if (this.type === 'customer' && body.serviceTypes.length && this.isDynamicServiceTypes) {
            body.serviceTypes = body.serviceTypes.map(serviceType => ({id: serviceType}));
            body['serviceTypeList'] = body.serviceTypes;
            delete body['serviceTypes'];
        }
        if (this.isEdit) {
            this.editUser(body);
            return;
        } else {
            body['password'] = data.password;
        }

        if (this.type === 'customer') {
            this.onAddCustomer(body);
            return;
        }

        this.usersService.addUser(body).subscribe(
            (response) => {
                this.activeModal.close({isSuccess: true});
            }, (error) => {
                this.isLoading = false;
            }, () => {
            }
        );
    }

    public checkIsDriver($event) {
        if ($event.target.value === 'DRIVER') {
            this.form.get('isEnabledAsTicketingUser').patchValue(false);
            this.form.controls.vehicleId.setValidators([Validators.required]);
            this.form.get('isReturnPackages').patchValue(true);
            this.form.controls.vehicleId.updateValueAndValidity();
        } else {
            this.form.controls.vehicleId.setValidators([]);
            this.form.controls.vehicleId.updateValueAndValidity();
            this.resetVehicle();
        }
        if ($event.target.value === 'FULFILLMENT_USER') {
            this.form.controls.hubId.setValidators([]);
            this.form.controls.hubId.updateValueAndValidity();
        } else if (this.loggedInUserRole !== 'HUB_ADMIN') {
            this.form.controls.hubId.setValidators([Validators.required]);
            this.form.controls.hubId.updateValueAndValidity();
        }
        if (this.form.get('role').value === 'TICKETING_USER') {
            this.form.get('isEnabledAsTicketingUser').patchValue(false);
        }
        if (this.form.get('role').value === 'CUSTOMER_CARE') {
            this.form.get('isReturnPackages').patchValue(false);
        }
        if (this.form.get('role').value === 'CLERK') {
            this.form.get('isReturnPackages').patchValue(false);
        }
        this.SetGetWareHouseOnly();
    }

    public editUser(data) {
        const reqBody = Object.assign(this.user, data);
        this.usersService.editUser(this.user, reqBody).subscribe(
            (response) => {
                if (this.type === 'customer') {
                    this.uploadAttachments(this.user);
                } else {
                    this.activeModal.close({isSuccess: true});
                }
            }, (error) => {
                this.isLoading = false;
            }
        );
    }

    public onCarChanged() {
        const vehicleId = this.form.value.vehicleId,
            vehicleIdControl = this.form.controls.vehicleId;

        vehicleIdControl.disable();
        this.disableForm = true;

        this.usersService.getVehicle(vehicleId).subscribe((vehicle: any) => {
            if (vehicle.driver && !(this.user && this.user.id === vehicle.driver.id)) {
                const {firstName, middleName, lastName} = vehicle.driver;
                const driverName = `${firstName}${middleName ? (' ' + middleName) : ''} ${lastName}`;

                this.translate.get(['USERS.CHANGE_VEHICLE_CONFIRMATION', 'GENERAL.YES', 'GENERAL.NO'], {name: driverName})
                    .subscribe((res: any) => {
                    this.confirmationService.confirm({
                        message: res['USERS.CHANGE_VEHICLE_CONFIRMATION'],
                        accept: () => {
                            this.lastVehicalId = this.form.value.vehicleId;
                        },
                        reject: () => {
                            vehicleIdControl.setValue(this.lastVehicalId);
                        },
                        acceptLabel: res['GENERAL.YES'],
                        rejectLabel: res['GENERAL.NO'],
                    });
                });
            } else {
                this.lastVehicalId = vehicleId;
            }
            vehicleIdControl.enable();
            this.disableForm = false;
        }, () => {
            vehicleIdControl.setValue(this.lastVehicalId);
            vehicleIdControl.enable();
            this.disableForm = false;
        });
    }

    updateZoneForm($event, controlName) {
        this.form.controls[controlName].setValue(null);
        this.form.controls[controlName].updateValueAndValidity();
    }
    public getHeaderTitle() {
        let title;
        if (this.type === 'user') {
            if (this.isEdit) {
                title = this.loggedInUserRole === 'HUB_MANAGER' || this.loggedInUserRole === 'MULTIPLE_HUBS_MANAGER' ? 'USERS_MANAGEMENT.ACTIONS.EDIT_DRIVER' : 'USERS_MANAGEMENT.ACTIONS.EDIT_USER';
            } else {
                title = this.loggedInUserRole === 'HUB_MANAGER'||this.loggedInUserRole === 'MULTIPLE_HUBS_MANAGER' ? 'USERS_MANAGEMENT.ACTIONS.ADD_DRIVER' : 'USERS_MANAGEMENT.ACTIONS.ADD_USER';
            }
        } else { // customer
            title = this.isEdit ? 'USERS_MANAGEMENT.ACTIONS.EDIT_CUSTOMER' : 'USERS_MANAGEMENT.ACTIONS.ADD_CUSTOMER';
        }
        return title;
    }
    public getPricingLists(data) {
        const query = {
            page: 1,
            pageSize: 100
        };
        if (data.query) {
            query['search'] = data.query;
        }
        this.initPricingLists(query);
    }
    public getCategories(search: string, isEdit = false) {
        const query = '?page=1&pageSize=100&search=' + search;
        this.ticketingSystemService.getAllCategories(query).subscribe(
            (categories: CategoryModel[]) => {
                this.categories = categories;
                if (isEdit) {
                    this.getCategoryIdsByUserId(this.user.id);
                }
            }, (error) => {
                console.error(error);
            }
        );
    }

    isCustomerFulfillment() {
        return this.type === 'customer' && this.userInfo.isFulfilmentEnabled;
    }

    initPricingLists(query) {
        this.pricingService.getPricingList(query).subscribe((response: {data: any, totalRecordsNo: number} ) => {
            this.pricingListType =  response.data.map(
                (res) => {
                    return {label: res.name, value: res.id};
                }
            );
        }, () => {
        });
    }
    onItemSelected() {
    }

    isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
        const formControl = this.form.get(formControlName);
        if (formControl) {
            return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
                : formControl.invalid && (formControl.touched || formControl.dirty);
        }
        return false;
    }

    isInvalidDirtyAndTouched(formControlName: string, errorType?: string) {
        const formControl = this.form.get(formControlName);
        if (formControl) {
            return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
                : formControl.invalid && formControl.touched && formControl.dirty;
        }
        return false;
    }
    isAllowAddingPackagesOnAllHub() {
        return this.type === 'user' && this.form.controls['role'].value !== 'DRIVER'
            && this.form.controls['role'].value !== 'TICKETING_USER' &&
            this.form.controls['role'].value !== 'TICKETING_SYSTEM_ADMIN' &&
            this.form.controls['role'].value !== 'HANDLER' &&
            this.form.controls['role'].value !== 'STOCKING_AND_PACKING_EMPLOYEE' &&
            this.form.controls['role'].value !== 'FULFILLMENT_USER' &&
            this.form.controls['role'].value !== 'HUB_MANAGER' &&
            this.form.controls['role'].value !== 'MULTIPLE_HUBS_MANAGER' &&
            this.form.controls['role'].value !== 'HUB_OPERATION_MANAGER' &&
            this.form.controls['role'].value !== 'HUB_CUSTOMER_CARE' &&
            this.form.controls['role'].value !== 'HUB_ADMIN' &&
            this.form.controls['role'].value !== 'HUB_CLERK' &&
            this.form.controls['role'].value !== '';
    }
    isNotifyCCWhenDeliveryPackageAndChangeCod() {
        return  this.form.controls['role'].value === 'CUSTOMER_CARE'
            && this.userInfo.isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD;
    }

    public getCategoryIdsByUserId(userId) {
        this.ticketingSystemService.getCategoriesByUserId(userId).subscribe((response: any) => {
            if (response && response.categoryId) {
                this.selectedCategory = response.categoryId;
                this.form.controls['categoryId'].setValue(this.selectedCategory);
            }
        });
    }

    showVehicles() {
        return this.type === 'user' && this.form.get('role').value === 'DRIVER';
    }

    editVehicleInput() {
        if (!this.selectedVehicle) {
            this.vehiclesAc.focusInput();
            this.vehiclesAc.search(null, '');
            return;
        }
        this.editVehicle = true;
        this.vehiclesAc.focusInput();
        const search_term = this.form.get('vehicleId').value;
        this.vehiclesAc.search(null, search_term);
    }
    public transformBasedOnCurrency(result: string) {
        if (this.userService.getCurrency() === 'SAR') {
            result = result.replace('زبون', 'عميل');
            result = result.replace('زبائن', 'عملاء');
            result = result.replace('المشتغل المرخص', 'السجل التجاري');
        }
        return result;
    }
    validateUserNameAndEmail(value: string) {
        if (!value.length || (this.companyId === 222 && this.type === 'customer')) {
            return;
        }
        value = value.trim();
        if (/\s/.test(value) ) {
            value =  value.replace(/\s/, '');
        }
        this.form.get('email').reset();
        this.updateFormValue('email', value);
    }

    getEmailLabel() {
        if ((this.companyId === 222 && this.type === 'customer') || this.isLcl) { // customer view for talaby company or lcl
            return 'USERS_MANAGEMENT.ADD_USER_FORM.NEW_EMAIL';
        } else {
            return 'USERS_MANAGEMENT.ADD_USER_FORM.EMAIL';
        }
    }
    isHidePackagesInDriversApps() {
        return this.userInfo.isHidePackagesInDriversApps;
    }

    private initServiceTypeOptions() {
        if (!this.userInfo.isPricingPerServiceTypeEnabled) {
            if (this.companyId === 139) { // vee company
                this.translate.get( SHARED_CONSTANTS.STATIC_SERVICE_TYPES.VEE_SERVICE_TYPES).subscribe(values => {
                    this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                        return {
                            label: values[translationKey],
                            value: translationKey
                        };
                    });
                });
            } else {
                this.translate.get(this.isLcl ? SHARED_CONSTANTS.STATIC_SERVICE_TYPES.LCL_SERVICE_TYPES
                    : SHARED_CONSTANTS.STATIC_SERVICE_TYPES.GENERAL_SERVICE_TYPES).subscribe(values => {
                    this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                        return {
                            label: values[translationKey],
                            value: translationKey
                        };
                    });
                });
            }
            if (!this.isEdit && this.form && this.form.get('serviceTypes') && !this.shipmentTypesInitialValueFilled) {
                this.form.get('serviceTypes').patchValue(this.shipmentServicesOptions.map(e => e.value));
                this.shipmentTypesInitialValueFilled = true;
            }
        } else {
            this.getDynamicServiceTypes();
        }
    }
    getDynamicServiceTypes(search = null) {
        let query = '?page=1&pageSize=20';
        if (search) {
            query += '&search=' + search;
        }
        this.serviceTypesLoader = true;
        this.serviceTypesService.getServiceTypes(query).subscribe(
            (res: any) => {
                if (res.data.length) {
                    this.shipmentServicesOptions = res.data.map(item => ({label: this.currentLang === 'en' ? item.name : item.arabicName, value: item.id}));
                    if (!this.isEdit && this.form && this.form.get('serviceTypes') && !this.shipmentTypesInitialValueFilled) {
                        this.form.get('serviceTypes').patchValue(this.shipmentServicesOptions.map(e => e.value));
                        this.shipmentTypesInitialValueFilled = true;
                    }
                }
                this.serviceTypesLoader = false;
            }, error => {
                console.error(error);
                this.serviceTypesLoader = false;
            }
        );
    }
    private initShipmentTypeOptions() {

            this.translate.get(this.isLcl ? SHARED_CONSTANTS.SHIPMENT_TYPES.LCL_SHIPMENT_TYPES
                : SHARED_CONSTANTS.SHIPMENT_TYPES.GENERAL_SHIPMENT_TYPES).subscribe(values => {
                this.shipmentTypesOptions = Object.keys(values).map(translationKey => {
                    return {
                        label: values[translationKey],
                        value: translationKey
                    };
                });
            });

    }

    private initAccessTypeOptions() {
        this.translate.get(this.userInfo.role === 'FULFILLMENT_USER' ? ['FULFILLMENT'] : SHARED_CONSTANTS.ACCESS_TYPE).subscribe(values => {
            this.accessTypeOptions = Object.keys(values).map(translationKey => {
                return {
                    label: values[translationKey],
                    value: translationKey
                };
            });
        });

    }

    showShipmentTypesInput() {
        return this.companiesWithoutShipmentTypes.indexOf(this.companyId) === -1 && this.type === 'customer';
    }

    toggleExtraPhone() {
        this.showPhone2 = !this.showPhone2;
        if (!this.showPhone2) {
            this.form.get('phone2').patchValue('');
            this.form.get('phone2').setValidators([]);
        } else {
            this.form.get('phone2').setValidators([Validators.compose([Validators.required,
               this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())])]);
        }
        this.form.get('phone2').updateValueAndValidity();
        this.form.get('phone2').markAsTouched();
    }

    isAllowToCreateBundles() {
        return this.userInfo.isBundleSupported;
    }

    fileChange($event: Event) {
        // @ts-ignore
        for (let i = 0; i < $event.target.files.length; i++) {
            if (this.files.length < 5) {
                // @ts-ignore
                this.files.push($event.target.files[i]);
            }
        }
    }

    uploadAttachments(customer) {
        if (!this.files.length) {
            this.activeModal.close({isSuccess: true, id: customer.id, customer: customer});
            return;
        }

        const data = new FormData();
        for (let x = 0; x < this.files.length; x++) {
            data.append('files', this.files[x]);
        }
        this.usersService.uploadUserAttachments(customer.id, data).subscribe(
            (response: any) => {
                this.activeModal.close({isSuccess: true, id: customer.id, customer: customer});
            }, (error) => {
                this.activeModal.close({isSuccess: false, error: error.error.error});
            }, () => {
            }
        );
    }

    removeAttachment(attachment: any) {
        for (let i = 0; i < this.files.length; i++) {
            if (this.files[i] === attachment) {
                this.files.splice(i, 1);
            }
        }
        this.fileChooser.nativeElement.value = '';
    }

    SetGetWareHouseOnly() {
        this.form.get('hubId').patchValue('');
        this.form.get('hubId').updateValueAndValidity();
        this.fetchHubs(this.isFulfilmentEnabled && this.form.get('role').value === 'STOCKING_AND_PACKING_EMPLOYEE', false);
    }

    showWarehousesOnly() {
        return this.isFulfilmentEnabled && this.form.get('role').value === 'DRIVER' || this.form.get('role').value === 'HANDLER';
    }
    disablePricingListForCustomers() {
        return this.userInfo.role === 'HUB_CUSTOMER_CARE' && this.type === 'customer';
    }
    onClearWarehouses(event) {
        this.form.get('warehouseId').setValue(null);
        this.form.get('warehouseId').updateValueAndValidity();
    }
    getWarehouses(search?, setInitialValue = false, initialValue = 0) {
        const params = {
            page: 1,
            pageSize: 100
        };
        if (search) {
            params[search] = search;
        }
        this.fulfilmentService.getWarehousesNew(params).subscribe(
            (warehousesResponse) => {
                this.warehousesOptions = warehousesResponse['hubs'].map(warehosue => {
                    if (setInitialValue && warehosue.id === initialValue) {
                        setTimeout(() => {
                            this.form.get('warehouseId').patchValue({label: warehosue.name, value: warehosue.id});
                        });

                    }
                    return {label: warehosue.name, value: warehosue.id};
                });
            }
        );
    }

}

