import {AuthenticationService} from './authentication.service';
import {UtilitiesService} from './utilities.service';
import {StorageService} from './storage/storage.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {GeographyService} from './geography.service';
import {HubsService} from './hubs.service';
import {DriversService} from './drivers.service';
import {RolesService} from './roles/roles.service';
import {AlertService} from './alert.service';
import {DateRangePickerService} from './date-range-picker.service';
import {MessagingService} from './messaging.service';
import {LoginService} from '../../login/services/login.service';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {SafeStylePipe} from '../pipes/safe-style.pipe';
import {AddressSystemPipe} from '../pipes/address-system.pipe';
import {ParseServerConstantPipe} from '../pipes/parse-server-constant';
import {AddressFormatPipePipe} from '../pipes/address-format.pipe';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyValuePipe} from '../pipes/currency-value.pipe';
import {UserService} from './user.service';

export const APP_SERVICES = [
    ConfirmationService,
    MessageService,
    DatePipe,
    CurrencyValuePipe,
    NgbActiveModal,
    DateRangePickerService,
    UserService
];
