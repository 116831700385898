import {Component} from '@angular/core';

@Component({
    selector: 'app-company-notifications',
    templateUrl: 'company-notifications.component.html',
    styleUrls: ['company-notifications.component.scss']
})
export class CompanyNotificationsComponent{

    constructor() {
    }
}
