<ng-template #changePackageStatusDialog>
    <div>
        <app-change-package-status-component
                [isPrintReportAfterChangeStatus] = "true"
                [shipments]="objectValues(scannedPackages)"
                [isShowCurrentStatus]="false"
                (onFinish)="closeChangePackageStatusPopOver(changePackageStatusPopOver, $event)"
                (onCancel)="closeChangePackageStatusPopOver(changePackageStatusPopOver, $event)"
        >
        </app-change-package-status-component>
    </div>
</ng-template>

<div class="close-icon" (click)="finish(true)"><img src="../../../assets/icons/close-icon.svg" alt="" /></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}"
     appBarcodeScanner (barcodeRead)="barcodeReader($event)">
    <div class="row d-inline-flex main-container" [ngClass]="{'read-by-barcode-main-container': isReadByBarcode}">
        <div *ngIf="isReceiverFromDriver || isReceiveReturnedPackages || isReceiveFromPartner" class="drivers-hubs-menu" [ngClass]="{'right-border': currentLang === 'en'}">
            <div class="receive-driver-label row" [ngClass]="{'receive-driver-label-en' : currentLang === 'en'}">
                <label *ngIf="isReceiverFromDriver">{{'ACCOUNTING.RECEIVE_COD_MENUITEM' | translate | packageTitle}} {{ 'GENERAL.FROM' | translate }}</label>
            </div>

            <div>
                <p-selectButton [options]="selectionOptions" styleClass="d-flex"
                                *ngIf="selectionOptions.length !== 1"
                                [(ngModel)]="sourceType"
                                (onChange)="confirmFinishScanning(resetAndLoadEntities.bind(this, $event))">

                </p-selectButton>
            </div>
            <div class="drivers-container">
                <div class="select-option-label">

                    <div class="position-absolute z-index-1000000 label-text">
                    {{'primeng.choose' | translate}} {{(sourceType == 'DRIVER' ? "ACCOUNTING.DRIVER" : sourceType == 'PARTNER' ?
                    "ACCOUNTING.PARTNER" : sourceType == 'HUB' ? "ACCOUNTING.HUB" : "") | translate}}
                    </div>
                    <div class="background-removal"></div>

                </div>
                <div class="search-drivers row">
                    <div class="search-input-value">
                        <img src="../../assets/icons/general/search.svg" alt="icon" />
                        <input appInputDebounced debounceTime="100" (inputDebounced)="confirmFinishScanning(resetAndLoadEntities.bind(this, $event))" [(ngModel)]="driverSearch"
                               class="search" placeholder="{{'GENERAL.SEARCH' | translate}}..." type="text" />
                    </div>
                </div>
                <div class="drivers position-relative" [ngClass]="{'drivers-en': currentLang === 'en'}" (scroll)="scroll($event)">
                    <div *ngIf="isLoadingEntities" class="d-flex w-100 h-100 justify-content-center align-items-center position-absolute">
                        <p-progressSpinner> </p-progressSpinner>
                    </div>
                    <div *ngFor="let entity of entities" class="driver row cursor-pointer d-flex" (click)="confirmFinishScanning(onSelectCustomer.bind(this,entity))" [ngClass]="{'selected-driver': customerId == entity.id}">
                        <div class="driver-image">
                            <img class="driver-icon w-100" src="../../../assets/icons/taxi-driver.png" alt="driver-Image" />
                        </div>
                        <div class="driver-name flex-1" [ngClass]="{'driver-name-en': currentLang === 'en'}">{{entity.name}}</div>
                        <div class="driver-carried-packages d-inline-flex" [ngClass]="{'driver-carried-packages-en': currentLang === 'en'}">
                            <div class="number">
                                <div class="number">{{(entity.total | number: '.0-2') | currencyValue}}</div>
                            </div>
<!--                            <div class="label">{{'RECEIVE_FROM_DRIVER.PACKAGE' | translate  | packageTitle}}</div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="drivers-hub-container">
            <div *ngIf="isReadByBarcode && !isUpdateReceiverInfo" class="row read-by-barcode-top-actions">
                <div class="top-button-action cursor-pointer approve-package" *appPermission="permissions.includes('{APPROVE_PACKAGES')" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}" (click)="approvePackages()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.APPROVAL' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.OF_THE_PACKAGES' | translate | packageTitle}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer assign-to-driver" *appPermission="permissions.includes('{ASSIGN_TO_DRIVER') && !isLcl" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}" (click)="assignToDriver()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_DRIVER' | translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer assign-to-container" *appPermission="permissions.includes('{ASSIGN_TO_CONTAINER') && !isLcl" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}" (click)="assignToContainer()">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_VEHICLE' | translate}}
                    </span>
                </div>
                <div class="change-status-popover">
                    <div
                            class="top-button-action cursor-pointer"
                            *appPermission="permissions.includes('{CHANGE_PACKAGE_STATUS')"
                            [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}"
                            #changePackageStatusPopOver="ngbPopover"
                            placement="bottom-left"
                            autoClose="outside"
                            [ngbPopover]="changePackageStatusDialog"
                            [placement]="'auto'"
                    >
                        <span>
                            {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.CHANGE' | translate}}
                            <br />
                            {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.STATUS' | translate}}
                        </span>
                    </div>
                </div>
                <div class="top-button-action cursor-pointer" (click)="openPrintDialog()" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.REPORTS' | translate}}
                    </span>
                </div>
                <div class="top-button-action cursor-pointer export-to-partner" (click)="transferPackages()" *appPermission="permissions.includes('{TRANSFER') && !isLcl" [ngClass]="{'disabled-btn disabled': !objectValues(scannedPackages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TO_PARTNER' | translate}}
                    </span>
                </div>

                <div class="top-button-action cursor-pointer export-to-partner" (click)="transferToStorm()" *ngIf="companyId === 41 || companyId === 1" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}
                        <br />
                        {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.STORM' | translate}}
                    </span>
                </div>

                <div *ngIf="isLcl" class="top-button-action cursor-pointer export-to-partner" (click)="enterContainerNumber()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'LCL.ACTIONS.ADD_TO_CONTAINER'| translate}}
                    </span>
                </div>

                <div *ngIf="companyId === 153" class="top-button-action cursor-pointer export-to-partner" (click)="transferToMughrabe()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">
                    <span>
                      {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_MUGHRABE'| translate}}
                    </span>
                </div>
            </div>
            <div class="header-filters filters-container d-flex flex-row">
                <div class="flex-1">
                    <div class="header-actions d-flex" *ngIf="selectedEntity">
                        <div class="title">
                            {{(sourceType == 'DRIVER' ?  (customizationCompanyService.checkCompanies(Companies.JETT) ? "SHIPMENT.EMPLOYEE" : "SHIPMENT.DRIVER") : sourceType == 'PARTNER' ?
                            "PARTNER.PARTNER_NAME" : sourceType == 'HUB' ? "MANAGE_COD_CUSTOMERS.HUB_NAME" : "") | translate}}
                            : <span style="font-size: 16px!important;"> {{entityName}}</span>
                        </div>

                        <div class="title"  *ngIf="sourceType == 'DRIVER'">
                            <i class="fas fas fa-20px pr-2 pl-2 ml-2 mr-2 fa-map-marker-alt"></i> {{entityAddress}}
                        </div>

                        <div class="title" *ngIf="sourceType == 'DRIVER'">
                            <i class="fas fa-car fa-20px pr-2 pl-2 ml-2 mr-2"></i> {{entityVehicle}}
                        </div>
                    </div>
                </div>
                <div class="input-item">
                    <div class="expected-monthly-packages-number input-filed-container dataFiltration">
                        <div class="input-value">
                            <p-dropdown [options]="dateFilterOptions"
                                        [(ngModel)]="selectedDateTypeFilter"
                                        [autoZIndex]="true"
                                        [baseZIndex]="1100"
                                        placeholder="{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}"
                                        (onChange)="onChangeDateTypeFilter()">
                            </p-dropdown>
                        </div>
                    </div>
                </div>

<!--                <div class="date-picker-filter">-->
<!--                    <img src="../../assets/icons/general/calendar.svg" alt="icon"  (click)="datepicker.click()"/>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                           #datepicker-->
<!--                           daterangepicker [options]="dateRangPickerOptions"-->
<!--                           (applyDaterangepicker)="onDatePickerHide($event.picker)"/>-->
<!--                    <i class="fas fa-caret-down"></i>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

                <div class="search-container">
                    <img src="../../assets/icons/general/search.svg" alt="icon" />
                    <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                           [(ngModel)]="searchContent" appInputDebounced (inputDebounced)="onSearch()"/>
                </div>
            </div>

            <div class="packages-table" [ngClass]="isReadByBarcode ? (isUpdateReceiverInfo ? 'update-receiver-info-by-barcode-packages-table': 'read-by-barcode-packages-table') : ''">
                <div class="row bottom-actions top-actions" *ngIf="isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages">
                    <div class="flex-1 flex-grow-1 flex-fill">
                        <div class="top-values flex-1">
                            <p-table [value]="totalSatisfactionNumber">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th>{{"ACCOUNTING.COD_SUM" | translate}}</th>
                                        <th *ngIf="isHubUserRole">{{"ACCOUNTING.HUB_CUSTOMERS_COD" | translate}}</th>
                                        <th>{{"ACCOUNTING.TOTAL_PACKAGES" | translate}}</th>
                                        <th>{{"ACCOUNTING.TOTAL_DELIVERED_PACKAGES" | translate}}</th>
                                        <th>{{"ACCOUNTING.RETURNED_PACKAGES_BEFORE_ARRIVAL" | translate}}</th>
                                        <th>{{"ACCOUNTING.RETURNED_PACKAGES_AFTER_ARRIVAL" | translate}}</th>
                                        <th>{{"ACCOUNTING.TOTAL_PARTIALLY_DELIVERED_PACKAGES" | translate}}</th>
                                        <th>{{"ACCOUNTING.TOTAL_SWAPPED_PACKAGES" | translate}}</th>
                                        <th>{{"ACCOUNTING.TOTAL_BROUGHT_PACKAGES" | translate}}</th>
                                        <th *ngIf="driverName && isDriverEarningEnabled">{{"DRIVERS_EARNINGS.TOTAL_DRIVER_COST" | translate}}</th>
                                        <th *ngIf="sourceType === 'PARTNER'">{{"ACCOUNTING.COD_WITHOUT_PARTNER_COST" | translate}}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowTemp>
                                    <tr>
                                        <td>{{"ACCOUNTING.ALL" | translate}}</td>
                                        <td>
                                            <span>{{selectedEntity ? ((sourceType === 'PARTNER' ? allCodWithOutPartnerCost - receivedCod : totalCod) | number : digitFormat | currencyValue) : '-'}}</span>
                                            <span *ngIf="codCollectionMethodKeys.length" class="cod-details" (click)="showAllDetails = !showAllDetails">
                                                {{(showAllDetails ? 'GENERAL.HIDE' :'GENERAL.DETAILS') | translate}}
                                            </span>
                                        </td>
                                        <td *ngIf="isHubUserRole">{{hubCustomersCod | decimalDigits: 3 }}</td>
                                        <td>{{selectedEntity ? (totalPackages | number : digitFormat) : '-'}}</td>
                                        <td>{{totalDeliveredPackages}}</td>
                                        <td>{{returnedPackageBeforeArrival}}</td>
                                        <td>{{returnedPackageAfterArrival}}</td>
                                        <td>{{totalPartiallyDeliveredPackages}}</td>
                                        <td>{{totalSwappedPackages}}</td>
                                        <td>{{totalBroughtPackages}}</td>
                                        <td *ngIf="driverName && isDriverEarningEnabled">{{totalDriverCost}}</td>
                                        <td *ngIf="sourceType === 'PARTNER'">{{allCodWithOutPartnerCost | number : digitFormat | currencyValue}}</td>
                                    </tr>
                                    <tr *ngIf="showAllDetails" class="extended-row">
                                        <td>{{"ACCOUNTING.COD_DETAILS" | translate}}</td>
                                        <td colspan="7">
                                            <div class="d-flex">
                                                <div class="ml-5" *ngFor="let collection of codCollectionMethodKeys">
                                                    <b>{{'BANK_TRANSFERS.' + collection | translate}}: </b> {{codCollectionMethod[collection] + ' ' + currency}}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="isHubUserRole"></td>
                                        <td *ngIf="driverName && isDriverEarningEnabled"></td>
                                        <td *ngIf="sourceType === 'PARTNER'"></td>
                                    </tr>
                                    <tr>
                                        <td>{{"ACCOUNTING.SELECTED" | translate}}</td>
                                        <td>
                                            <span>{{selectedEntity ? (receivedCod | number : digitFormat | currencyValue) : '-'}}</span>
                                            <span *ngIf="codCollectionMethodKeys.length" class="cod-details" (click)="showSelectedDetails = !showSelectedDetails">
                                                {{(showSelectedDetails ? 'GENERAL.HIDE' :'GENERAL.DETAILS') | translate}}
                                            </span>
                                        </td>
                                        <td *ngIf="isHubUserRole"> </td>
                                        <td>{{(isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages) ? objectValues(scannedPackages).length : objectValues(packages).length}}</td>
                                        <td>{{selectedDeliveredPackages}}</td>
                                        <td>{{selectedReturnedPackagesBeforeArrival}}</td>
                                        <td>{{selectedReturnedPackagesAfterArrival}}</td>
                                        <td>{{selectedPartiallyDeliveredPackages}}</td>
                                        <td>{{selectedSwappedPackages}}</td>
                                        <td>{{selectedBroughtPackages}}</td>
                                        <td *ngIf="driverName && isDriverEarningEnabled">{{driverEarningSum}}</td>
                                        <td *ngIf="sourceType === 'PARTNER'">{{selectedEntity ? (selectCodWithOutPartnerCost | number : digitFormat | currencyValue) : '-'}}</td>
                                    </tr>
                                    <tr *ngIf="showSelectedDetails" class="extended-row">
                                        <td>{{"ACCOUNTING.COD_DETAILS" | translate}}</td>
                                        <td colspan="7">
                                            <div class="d-flex">
                                                <div class="ml-5" *ngFor="let collection of codCollectionMethodKeys">
                                                    <b>{{'BANK_TRANSFERS.' + collection | translate}}: </b> {{codSelectedCollectionMethod[collection] + ' ' + currency}}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="isHubUserRole"></td>
                                        <td *ngIf="driverName && isDriverEarningEnabled"></td>
                                        <td *ngIf="sourceType === 'PARTNER'"></td>
                                    </tr>

                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="packages-table flex-1 overflow-auto">
                    <p-table [value]="packages" [responsive]="true"
                             class="overflow-auto w-100 h-100"
                             responsiveLayout="scroll"
                             [scrollable]="true"
                             scrollHeight="auto"
                             [lazy]="true"
                             styleClass="customers-table flex-table" [loading]="isLoading"
                             #table>

                        <ng-template pTemplate="emptymessage">
                            <app-no-data-available-component *ngIf="customerId"></app-no-data-available-component>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th  style="width: 4%;">
                                    <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                                (onChange)="onToggleSelectAll()"></p-checkbox>
                                </th>
                                <th style="width: 150px;">{{"MANAGE_SHIPMENTS.BARCODE" | translate}}</th>
                                <th *ngIf="" style="width: 150px;">{{"ACCOUNTING.COD_BARCODE" | translate}}</th>
                                <th>{{"MANAGE_COD_CUSTOMERS.RECEIVER_NAME" | translate}}</th>
                                <th> {{"ADD_SHIPMENT.RECEIVER_PHONE" | translate }}</th>
                                <th>{{"MANAGE_COD_CUSTOMERS.SENDER_NAME" | translate}}</th>
                                <th >{{"MANAGE_SHIPMENTS.INVOICE" | translate}}</th>
                                <th style="width: 80px"> {{"MANAGE_SHIPMENTS.COD" | translate}} </th>
                                <th *ngIf="sourceType === 'PARTNER'"> {{"PARTNER.PARTNER_COST" | translate}}
                                <th>{{"MANAGE_SHIPMENTS.COST" | translate}}</th>
                                <th *ngIf="showCodFees"> {{"ACCOUNTING.COD_FEES" | translate}}</th>
                                <th class="status-dropdown status-th">
                                    <app-drop-down [(ngModel)]="selectedShipmentStatus"
                                                  [isFilter]="true"
                                                  [label]="'SHIPMENT.TABLE.STATUS'"
                                                  [selectedItemTemplate]="selectedItemShipmentStatus"
                                                  [itemTemplate]="itemShipmentStatus"
                                                  [options]="shipmentStatusList"
                                                  [showClear]="false"
                                                  [appendTo]="'body'"
                                                  filter="true" (whenChange)="onStatusChanged()">
                                        <ng-template #selectedItemShipmentStatus let-status
                                                     pTemplate="selectedItem">{{ status.label | translate }}</ng-template>
                                        <ng-template #itemShipmentStatus let-status
                                                     pTemplate="item">{{ status.label | translate }}</ng-template>
                                    </app-drop-down>
                                </th>
                                <th style="width: 150px;">
                                    <app-drop-down
                                            [(ngModel)]="selectedPaymentMethod" optionValue="value"
                                            [options]="paymentsMethod" filter="true"
                                            [showClear]="false"
                                            (whenChange)="onChangePaymentMethod()"
                                            [isFilter]="true"
                                            [label]="'ACCOUNTING.PAYMENT_TYPE'"
                                            appendTo="body">
                                    </app-drop-down>
                                </th>
                                <th *ngIf="driverName && isDriverEarningEnabled || true" style="width: 7%"> {{"DRIVERS_EARNINGS.DRIVER_COST" | translate}}</th>

                                <th *ngIf="isDraftPackagesEnable() || true" class="th-attachments">
                                    <div class="check-filter">
                                        <div class="d-flex justify-content-center">
                                            <div class="label">{{ 'GENERAL.ATTACHMENTS' | translate }}</div>
                                        </div>
                                        <div class="values">
                                            <div class="item"
                                                 (click)="setAttachmentFilter('ALL')"
                                                 [ngClass]="{'active-item': selectedAttachmentsFilter === 'ALL','b-radius-right': currentLang === 'ar' , 'b-radius-left': currentLang === 'en'}">{{'ALL' | translate}}</div>
                                            <div class="item flex-2d-center"
                                                 (click)="setAttachmentFilter('WITH')"
                                                 [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                            <div class="item"
                                                 (click)="setAttachmentFilter('WITHOUT')"
                                                 [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITHOUT','b-radius-left': currentLang === 'ar' , 'b-radius-right': currentLang === 'en'}">{{'WITHOUT' | translate}}</div>
                                        </div>
                                    </div>
                                </th>

                                <th style="width: 7%"> {{"MANAGE_SHIPMENTS.IS_PARTIAL_DELIVERY" | translate}}</th>
                                <th style="width: 7%"> {{"MANAGE_SHIPMENTS.NOTES" | translate}}</th>
                                <th style="width: 7%"> {{"ACTIONS.UPDATE" | translate}}</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-package let-expanded="expanded">
                            <tr style="min-height: 60px" [ngClass]="{'scanned-row': isPackageScanned(package),
                                                        'change-color': changeColorForPartialDeliveryOrSwapsPackage(package),
                                                        'cod-received-without-custody-color': package.codReceivedWithoutCustody,
                                                        'is-draft-background-color' : changeColorOfDraftPackages(package.isDraftPackageWithoutReceiverInfo)}"
                                tooltipPosition='top'
                                [tooltipDisabled]="!changeColorForPartialDeliveryOrSwapsPackage(package)"
                                pTooltip="{{PartialDeliveryOrSwapPackage(package)| translate}}">
                                <td style="width: 4%">
                                    <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                                (onChange)="onToggleSelection(package)"></p-checkbox>
                                </td>
                                <td style="width: 150px;" class="text-align-center cursor-pointer">
                                    <div class="barcode-image-container flex-2d-center">
                                        <div>
                                            <span class="hover-display-parent">
                                            <span>{{package.barcode ? package.barcode : package.packageBarcode}}</span>
                                                <i class="fas fa-copy cursor-pointer hover-display"
                                                   (click)="copyMessage($event,null,package.barcode?package.barcode:package.packageBarcode)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>{{package.fullReceiverName ? package.fullReceiverName : package.receiver}}
                                    <div class="append-address">
                                        <b>{{'ADMINISTRATION.ADDRESS' | translate}}:</b> {{package.destinationVillage + ' - ' + package.destinationAddressLine1}}
                                    </div>
                                </td>
                                <td>{{package.receiverPhone}}</td>
                                <td>{{package.fullSenderName}}</td>
                                <td >{{package.invoiceNumber}}</td>
                                <td style="width: 80px">
                                    {{package.cod | number : digitFormat}}
                                </td>

                                <td *ngIf="sourceType === 'PARTNER'"> {{package.partnerPackageCost}}</td>
                                <td *ngIf="enableEditCost" pEditableColumn [pEditableColumn]="package"
                                    [pEditableColumnField]="'cost'">
                                    <app-edit-cost [package]="package" (onResetEvent)="initPackages()"></app-edit-cost>
                                </td>
                                <td *ngIf="showCodFees"> {{package.codFees}}</td>

                                <td style="width: 150px;text-align: center">
                                    <app-shipment-status [isEditEnabled]="false" [shipment]="package"></app-shipment-status>
                                </td>
                                <td>
                                    {{package.paymentType | translate}}
                                </td>
                                <td style="width: 7%" *appPermission="permissions.includes('{EDIT_COST') && driverName && isDriverEarningEnabled || true"
                                    pEditableColumn [pEditableColumn]="package"
                                    [pEditableColumnField]="'driverEarning'">
                                    <div class="flex-2d-center">
                                        <app-edit-driver-earning [package]="package" (onEditEvent)="initPackages()"></app-edit-driver-earning>
                                    </div>
                                </td>

                                <td *ngIf="isDraftPackagesEnable() || true" style="width: 115px">
                                    <div *ngIf="package.isAttachmentExist" class="attachments-btn">
                                        <img (click)="openImages($event, package.id)"
                                             src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                                    </div>
                                </td>

                                <td style="width: 7%">{{package.isPartiallyDelivered ? ('MANAGE_SHIPMENTS.IS_PARTIAL_DELIVERY' | translate) : ''}}</td>
                                <td style="min-width: 200px">{{package.notes}}</td>
                                <td style="width: 7%">
                                    <div class="table-row-actions-container">
                                        <div class="edit-action action cursor-pointer" (click)="editPackage(package.barcode)">
                                            <i class="fas fa-edit"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row bottom-actions d-inline-flex justify-content-between" *ngIf="isReadByBarcode && !isUpdateReceiverInfo">
                    <div *ngIf="objectValues(packages).length" class="number-of-scanned-packages d-flex">
                        <div class="icon">
                            <img src="../../../assets/icons/containers-management/packages_number.svg" alt="" />
                        </div>
                        <div class="label"><span>{{'RECEIVE_FROM_DRIVER.NUMBER_OF_SELECTED_PACKAGES' | translate | packageTitle}}</span></div>
                        <div class="number">
                            <span>{{objectValues(scannedPackages).length}}</span>
                        </div>
                    </div>
                    <div class="finish-read-by-barcode" (click)="finish()" [ngClass]="{'disabled-btn disabled': !objectValues(packages).length}">{{'READ_BY_BARCODE.ACTIONS.RESET' | translate}}</div>
                </div>
                <div class="row bottom-actions" *ngIf="isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages">

                    <div class="enter-by-barcode cursor-pointer p-0"
                         [ngClass]="{'disabled-btn': !objectValues(packages).length && (!isReceiveReturnedPackages)}"
                         (click)="enterBarcode()">
                        <span>{{'RECEIVE_FROM_DRIVER.ENTER_BARCODE' | translate | packageTitle}}</span>
                        <img style="width: 50px;margin-inline: 15px;" src="../../../assets/icons/barcode-scanner.png" alt="" />
                    </div>
                    <div class="bottom-left d-inline-flex flex-row-reverse flex-grow-1">
                        <div [ngClass]="{'disabled-btn': !isThereSelection}">
                            <div (click)="finishScanning()" class="receive-done d-inline-flex">
                                <div style="width: 40px;"></div>
                                <div class="receive-done-label">{{'RECEIVE_FROM_DRIVER.RECEIVE' | translate}}</div>
                                <span class="number" [ngClass]="{'number-en': currentLang === 'en'}" style="color: black; font-family: 'roboto bold';">
                                    {{isReceiverFromDriver || isReceiveFromPartner || isReceiveReturnedPackages ? objectValues(scannedPackages).length : objectValues(packages).length}} <span style="width: 3px !important;"></span>
                                    <span style="font-family: 'almarai light';">{{'RECEIVE_FROM_DRIVER.PACKAGE' | translate | packageTitle}}</span>
                                </span>
                                <div class="packages-number" [ngClass]="{'packages-number-en': currentLang === 'en'}"></div>
                            </div>
                        </div>
                        <div [ngClass]="{'disabled-btn': !isThereSelection}" *ngIf="sourceType === 'DRIVER'" class="secondary-action-btn cursor-pointer" (click)="receiveInHubCustody()">
                            {{"ACCOUNTING.RECEIVE_IN_HUB_CUSTODY" | translate}}
                        </div>
                        <div class="secondary-action-btn cursor-pointer"
                             [ngClass]="{'disabled-btn disabled': !customerId}" (click)="bulkReceive()">
                            {{"ACCOUNTING.BULK_RECEIVE" | translate}}
                        </div>
                        <div  class="secondary-action-btn cursor-pointer" [ngClass]="{'disabled-btn disabled': !packages.length}" (click)="confirmReceiveAll()">
                            {{"ACCOUNTING.RECEIVE_ALL" | translate}}
                        </div>

                        <div class="secondary-action-btn cursor-pointer"
                             (click)="confirmFinishScanning(resetScanning.bind(this))">
                            {{"ACCOUNTING.RESET" | translate}}
                        </div>
                        <div *ngIf="selectedEntity" class="excel-button"
                             [ngClass]="{'disabled-btn': isExporting}"
                             (click)="exportExcel()">
                            <i class="fas fa-file-excel"></i>
                            {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container>
    <div class="position-fixed">
    <app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                    [packageBarcode]="selectedPackageBarcode"
                                    (closeWidget)="onHideAddPackage($event)">
    </app-add-package-form-component>
    </div>
</ng-container>
