import {BehaviorSubject} from 'rxjs';

export const updatesBadgeOp = new BehaviorSubject(0);

export const SHARED_CONSTANTS = {
    ADMIN_DEBOUNCED_SEARCH_PERIOD: 500,
    TABLE_PAGE_SIZES: [10, 20, 50, 100],
    TABLE_DEFAULT_PAGE_SIZE: 10,
    LAZY_LOAD_TABLE_DEFAULT_PAGE_SIZE: 14,
    USERS_GRID_DEFAULT_PAGE_SIZE: 12,
    LEAFLET_SEARCH_API_KEY: 'AAPK0e5c2d08bd894f52bbb651a2aa978fefCXoJGwGgTypPHIgxssSUA-bPV1QTQuVNy0okrkrnFVjqTf4oDt8V_UeuhLvaafUH',
    STRIPE_PUBLISHABLE_KEY: {
        development: 'pk_test_51KPt3DA8Xrm7AKTDLkca9YyxbSkYijBVHOdCAWG6fjy9nG9wliHsysFIcGCvdq7DdZwHXH8xZ6SvqMEht44onKZ000bi9M25Xa',
        production: 'pk_test_51KPt3DA8Xrm7AKTDLkca9YyxbSkYijBVHOdCAWG6fjy9nG9wliHsysFIcGCvdq7DdZwHXH8xZ6SvqMEht44onKZ000bi9M25Xa'
    },
    TOAST_Z_INDEX: 99999999900000,
    MENU_TABS: [
        {
            icon: 'fa fa-building',
            label: 'COMPANY.TITLE',
            path: 'companies',
            role_id: 'COMPANIES'
        },
        {
            icon: 'fa fa-building',
            label: 'COMPANY.COMPANY_ACCOUNTING',
            path: 'company-management',
            role_id: 'COMPANY_MANAGEMENT'
        },
        {
            imgSrc: 'dashboard.svg',
            label: 'DASHBOARD.TITLE',
            path: 'dashboard',
            role_id: 'DASHBOARD'
        },
        {
            icon: 'fas fa-plus',
            label: 'SHIPMENT.ADD',
            path: 'add-shipment',
            role_id: 'ADD_SHIPMENT'
        },
        {
            imgSrc: 'manage_packages.svg',
            label: 'SHIPMENT.MANAGE',
            path: 'manage-shipments',
            role_id: 'MANAGE_SHIPMENTS'
        },
        {
            imgSrc: 'manage_packages.svg',
            label: 'LCL.CONTAINERS_MANAGEMENT.TITLE',
            path: 'containers-management',
            role_id: 'CONTAINERS_MANAGEMENT'
        },
        {
            icon: 'fas fa-boxes',
            label: 'GENERAL.PENDING_APPROVAL',
            path: 'pending-approval',
            role_id: 'PENDING_APPROVAL'
        },
        {
            imgSrc: 'returns_report.svg',
            label: 'GENERAL.MENU_RETURNED_PACKAGES',
            role_id: 'MENU_RETURNED_PACKAGES',
            subItems: [
                {
                    label: 'MANAGE_SHIPMENTS.RECEIVING_SCREEN',
                    path: 'all-returned-packages',
                    role_id: 'ALL_RETURNED_PACKAGES'
                },
                {
                    label: 'MANAGE_SHIPMENTS.RECEIVING_SCREEN',
                    path: 'shipments',
                    role_id: 'ALL_RETURNED_PACKAGES',
                    fakePath: true
                },
                {
                    label: 'MANAGE_SHIPMENTS.RETURNED',
                    path: 'returned-packages',
                    role_id: 'RETURNED_PACKAGES'
                },
                {
                    label: 'MANAGE_SHIPMENTS.RETURNED_BUNDLES',
                    path: 'returned-bundles',
                    role_id: 'RETURNED_BUNDLES'
                },
                {
                    label: 'MANAGE_SHIPMENTS.RETURNED_PACKAGES_FOR_SWAPPING',
                    path: 'swapped-packages',
                    role_id: 'SWAPPED_PACKAGES'
                },
                {
                    label: 'MANAGE_SHIPMENTS.RETURNED_PARTIALLY',
                    path: 'partially-returned-packages',
                    role_id: 'PARTIALLY_RETURNED_PACKAGES',
                },
                {
                    label: 'MANAGE_SHIPMENTS.BRINGS_PACKAGES',
                    path: 'brings-packages',
                    role_id: 'BRINGS_PACKAGES',
                },
                {
                    label: 'MANAGE_SHIPMENTS.WITH_DRIVER',
                    path: 'returned-packages-in-car',
                    role_id: 'RETURNED_PACKAGES_IN_CAR',
                },
                {
                    label: 'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER',
                    path: 'deliver-to-sender',
                    role_id: 'DELIVER_TO_SENDER',
                }
            ]
        },
        {
            icon: 'fas fa-chart-bar',
            label: 'GENERAL.CURRENT_REPORTS',
            role_id: 'CURRENT_REPORTS',
            subItems: [
                {
                    label: 'SHIPMENT.DRIVERS_REPORTS',
                    path: 'drivers-report',
                    role_id: 'DRIVERS_REPORT'
                },
                {
                    label: 'ADMINISTRATION.HUBS_REPORTS',
                    path: 'hubs-reports',
                    role_id: 'HUBS_REPORTS',
                },
                {
                    label: 'ADMINISTRATION.PARTNERS_REPORTS',
                    path: 'partners-reports',
                    role_id: 'PARTNERS_REPORTS',
                },
                {
                    label: 'SHIPMENT.ACTIVE_CUSTOMERS_REPORTS',
                    path: 'active-customers-report',
                    role_id: 'ACTIVE_CUSTOMERS_REPORT'
                }
            ]
        },
        {
            imgSrc: 'incompleted_orders.svg',
            label: 'SHIPMENT.DRAFT_PICKUPS',
            path: 'draft-pickups',
            role_id: 'DRAFT_PICKUPS'
        },
        {
            imgSrc: 'manage_vehicles.svg',
            label: 'CONTAINERS.MANAGE',
            path: 'manage-containers',
            role_id: 'MANAGE_CONTAINERS',
        },
        {
            imgSrc: 'manage_users.svg',
            label: 'USERS.MANAGE',
            path: 'manage-users',
            role_id: 'MANAGE_USERS'
        },
        {
            imgSrc: 'manage_branches.svg',
            label: 'BRANCHES.MANAGE',
            path: 'manage-branches',
            role_id: 'MANAGE_BRANCHES'
        },
        {
            imgSrc: 'inventory-management.png',
            label: 'FULFILMENT.TITLE',
            role_id: 'FULFILMENT',
            subItems: [
                {
                    label: 'FULFILMENT.WAREHOUSE.TITLE',
                    path: 'fulfilment/manage-warehouses',
                    role_id: 'MANAGE_WAREHOUSES'
                },
                {
                    label: 'FULFILMENT.WAREHOUSE.WAREHOUSE_STOCKS_MANAGEMENT',
                    path: 'fulfilment/warehouse-stocks-management',
                    role_id: 'WAREHOUSE_STOCKS_MANAGEMENT'
                },
                {
                    label: 'FULFILMENT.PRODUCT.TITLE',
                    path: 'fulfilment/manage-products',
                    role_id: 'MANAGE_PRODUCTS'
                },
                {
                    label: 'FULFILMENT.BIN.TITLE',
                    path: 'fulfilment/manage-bins',
                    role_id: 'MANAGE_BINS'
                },
                {
                    label: 'FULFILMENT.SHIPPING_PLAN.TITLE',
                    path: 'fulfilment/manage-shipping-plans',
                    role_id: 'MANAGE_SHIPPING_PLANS'
                },
                {
                    label: 'FULFILMENT.ITEM.TITLE',
                    path: 'fulfilment/manage-items',
                    role_id: 'MANAGE_ITEMS'
                },
                // {
                //     label: 'FULFILMENT.ITEM.ZID_TITLE',
                //     path: 'fulfilment/manage-items-3rd-party',
                //     role_id: 'MANAGE_ITEMS_3RD_PARTY'
                // },
                {
                    label: 'FULFILMENT.ORDER.MANAGE_ORDERS',
                    path: 'fulfilment/manage-orders',
                    role_id: 'MANAGE_ORDERS'
                },
                {
                    label: 'FULFILMENT.ACCOUNTING.TITLE',
                    role_id: 'FULFILMENT_ACCOUNTING',
                    subItems: [
                        {
                            label: 'FULFILMENT.ACCOUNTING.PRICING.TITLE',
                            path: 'fulfilment/fulfilment-accounting/fulfilment-shipping-rates',
                            role_id: 'FULFILMENT_SHIPPING_RATES'
                        },
                        {
                            label: 'FULFILMENT.ACCOUNTING.ASN_FEES.TITLE',
                            path: 'fulfilment/fulfilment-accounting/asn-fees',
                            role_id: 'ASN_FEES'
                        },
                        {
                            label: 'FULFILMENT.ACCOUNTING.STORAGE_FEES.TITLE',
                            path: 'fulfilment/fulfilment-accounting/storage-fees',
                            role_id: 'ASN_FEES'
                        },
                        {
                            label: 'FULFILMENT.ACCOUNTING.ORDERS_FEES.TITLE',
                            path: 'fulfilment/fulfilment-accounting/orders-fees',
                            role_id: 'ORDERS_FEES'
                        },
                        {
                            label: 'FULFILMENT.ACCOUNTING.INVOICES_FEES.TITLE',
                            path: 'fulfilment/fulfilment-accounting/invoices-fees',
                            role_id: 'INVOICES_FEES'
                        }
                    ]
                },
                {
                    label: 'FULFILMENT.RETURNED.TITLE',
                    role_id: 'FULFILMENT_RETURNED',
                    subItems: [
                        {
                            label: 'FULFILMENT.RETURNED.REJECTED.TITLE',
                            path: 'fulfilment/fulfilment-returned/rejected-items',
                            role_id: 'REJECTED_ITEMS'
                        },
                        {
                            label: 'FULFILMENT.RETURNED.RETURNED.TITLE',
                            path: 'fulfilment/fulfilment-returned/returned-items',
                            role_id: 'RETURNED_ITEMS'
                        },
                        {
                            label: 'FULFILMENT.RETURNED.DAMAGED.TITLE',
                            path: 'fulfilment/fulfilment-returned/damaged-locations',
                            role_id: 'DAMAGED_LOCATIONS'
                        },
                    ]
                }
            ]
        },
        {
            imgSrc: 'partners.svg',
            label: 'PARTNER.PARTNERS',
            role_id: 'PARTNERS',
            subItems: [
                {
                    label: 'PARTNER.OUR_PARTNERS',
                    role_id: 'OUR_PARTNERS',
                    subItems: [
                        {
                            label: 'PARTNER.PENDING_PARTNERS',
                            path: 'partners/pending-partners',
                            role_id: 'PENDING_PARTNERS'
                        },
                        {
                            label: 'PARTNER.ACTIVE_PARTNERS',
                            path: 'partners/active-partners',
                            role_id: 'ACTIVE_PARTNERS'
                        },
                        {
                            label: 'PARTNER.UNRECEIVED_PARTNER_PACKAGES',
                            path: 'partners/partners-packages-report',
                            role_id: 'PARTNERS_PACKAGES_REPORT'
                        }
                    ]
                },
                {
                    imgSrc: '3rd-party.svg',
                    label: 'THIRD_PARTY.THIRD_PARTY',
                    path: 'partners/third-party',
                    role_id: 'THIRD_PARTY'
                }
            ]
        },
        {
            icon: 'far fa-paper-plane',
            label: 'INBOX.INBOX',
            path: 'inbox',
            role_id: 'INBOX'
        },
        // {
        //   icon: 'fas fa-folder-open',
        //   label: 'REPORT.DOWNLOAD',
        //   path: 'download-report'
        // },
        {
            label: 'ACCOUNTING.PRINTED_REPORTS',
            icon: 'fa-chart-bar fas',
            path: 'administration/printed-reports-new',
            role_id: 'PRINTED_REPORTS_NEW'
        },
        {
            imgSrc: 'accounting.svg',
            label: 'ACCOUNTING.ACCOUNTING',
            role_id: 'ACCOUNTING',
            subItems: [
                {
                    label: 'ACCOUNTING.HUBS_MASS_COD_REPORTS',
                    role_id: 'HUBS_MASS_COD_REPORTS',
                    subItems: [
                        {
                            label: 'ACCOUNTING.RECEIVED_COD',
                            path: 'administration/hubs-mass-cod-reports/received',
                            role_id: 'HUBS_MASS_COD_REPORTS'
                        },
                        {
                            label: 'ACCOUNTING.EXPORTED_COD_MENUITEM',
                            path: 'administration/hubs-mass-cod-reports/exported',
                            role_id: 'HUBS_MASS_COD_REPORTS'
                        },
                        {
                            label: 'ACCOUNTING.DELIVERED_COD_MENUITEM',
                            path: 'administration/hubs-mass-cod-reports/delivered',
                            role_id: 'HUBS_MASS_COD_REPORTS'
                        }
                    ]
                },
                {
                    label: 'ACCOUNTING.HUB_CUSTODY',
                    path: 'administration/hub-custody',
                    role_id: 'HUB_CUSTODY'
                },
                {
                    label: 'ACCOUNTING.RECEIVE_COD_MENUITEM',
                    path: 'administration/receive-cod',
                    role_id: 'RECEIVE_COD'
                },
                {
                    label: 'ACCOUNTING.SORT_COD_MENUITEM',
                    path: 'administration/sort-cod',
                    role_id: 'SORT_COD',
                },
                {
                    label: 'ACCOUNTING.EXPORTED_COD_MENUITEM',
                    path: 'administration/exported-packages',
                    role_id: 'EXPORTED_PACKAGES',
                },
                {
                    label: 'MANAGE_COD_CUSTOMERS.INVOICES.INVOICES',
                    path: 'administration/invoices',
                    role_id: 'INVOICES',
                },
                {
                    label: 'MANAGE_COD_CUSTOMERS.MASS_INVOICES.TITLE',
                    path: 'administration/mass-invoices',
                    role_id: 'MASS_INVOICES',
                },
                {
                    label: 'MANAGE_COD_CUSTOMERS.COD_PENDING_CUSTOMERS_APPROVALS',
                    path: 'cod-pending-customers-approval',
                    role_id: 'COD_PENDING_CUSTOMERS_APPROVAL',
                },
                {
                    label: 'ACCOUNTING.DELIVERED_COD_MENUITEM',
                    path: 'administration/delivered-packages',
                    role_id: 'DELIVERED_PACKAGES',
                },
                {
                    label: 'ACCOUNTING.ALL_COD_CUSTOMERS.TITLE',
                    path: 'administration/all-cod-customers',
                    role_id: 'ALL_COD_CUSTOMERS',
                },
                {
                    label: 'ACCOUNTING.IN_CAR_COD_MENUITEM',
                    path: 'administration/in-car-packages',
                    role_id: 'IN_CAR_PACKAGES',
                },
                {
                    label: 'ACCOUNTING.COD_MISMATCH',
                    path: 'administration/cod-mismatch',
                    role_id: 'COD_MISMATCH',
                },
                {
                    label: 'ACCOUNTING.DELAYED_COD_REPORTS',
                    path: 'delayed-cod-reports',
                    role_id: 'DELAYED_COD_REPORTS',
                },
                {
                    label: 'ACCOUNTING.EXPENSES',
                    path: 'administration/expenses',
                    role_id: 'EXPENSES',
                },
                {
                    label: 'FINANCIAL_REPORT.FINANCIAL_REPORT',
                    path: 'administration/financial-report',
                    role_id: 'FINANCIAL_REPORT',
                },
                {
                    label: 'BANK_TRANSFERS.BANK_TRANSFERS',
                    path: 'administration/bank-transfers',
                    role_id: 'BANK_TRANSFERS'
                },
                {
                    label: 'SHIPPING.RATES',
                    path: 'administration/shipping-rates',
                    role_id: 'SHIPPING_RATES'
                },
                {
                    label: 'SHIPPING.RATES',
                    path: 'administration/pricing-service-types',
                    role_id: 'PRICING_SERVICE_TYPES'
                },
                {
                    label: 'PRICING.CUSTOMER_PRICING',
                    path: 'administration/pricing-type',
                    role_id: 'PRICING_TYPE'
                },
                {
                    label: 'ACCOUNTING.ADDITIONAL_FEES.TITLE',
                    role_id: 'ADDITIONAL_FEES',
                    subItems: [
                        {
                            label: 'ACCOUNTING.ADDITIONAL_FEES.COD',
                            path: 'administration/additional-fees-cod',
                            role_id: 'ADDITIONAL_FEES_COD'
                        },
                        {
                            label: 'ACCOUNTING.ADDITIONAL_FEES.INSURANCE',
                            path: 'administration/additional-fees-insurance',
                            role_id: 'ADDITIONAL_FEES_INSURANCE'
                        }
                    ]
                },
                {
                    label: 'ACCOUNTING.PRINTED_REPORTS',
                    path: 'administration/printed-reports',
                    role_id: 'PRINTED_REPORTS'
                },
                {
                    label: 'ACCOUNTING.WALLET.CUSTOMERS_WALLET',
                    path: 'administration/customers-wallets',
                    role_id: 'CUSTOMERS_WALLETS'
                },
                // {
                //     label: 'HUBS_ACCOUNTING_DASHBOARD.TITLE',
                //     path: 'administration/hubs-accounting-dashboard',
                //     role_id: 'HUBS_ACCOUNTING_DASHBOARD'
                // },
                {
                    label: 'SHIPPING.DRIVERS_EARNINGS',
                    role_id: 'DRIVERS_EARNINGS',
                    subItems: [
                        {
                            label: 'SHIPPING.DRIVERS_EARNINGS',
                            path: 'administration/drivers-earnings',
                            role_id: 'DRIVERS_EARNINGS'
                        },
                        {
                            label: 'DRIVERS_EARNINGS.PRIVATE_DRIVER_EARNINGS',
                            path: 'administration/drivers-pricing',
                            role_id: 'DRIVERS_PRICING'
                        },
                        {
                            label: 'DRIVERS_EARNINGS.DRIVERS_EARNINGS_REPORT',
                            path: 'administration/drivers-earnings-report',
                            role_id: 'DRIVERS_EARNINGS_REPORT'
                        }
                    ]
                }
            ]
        },
        {
            imgSrc: 'management.svg',
            label: 'ADMINISTRATION.ADMINISTRATIVE',
            role_id: 'ADMINISTRATION',
            subItems: [
                {
                    label: 'DISTRIBUTOR_REPORTS.MENU_ITEM_TITLE',
                    role_id: 'PACKAGES_CONTENTS_REPORT',
                    subItems: [
                        {
                            label: 'DISTRIBUTOR_REPORTS.PACKAGES_CONTENTS',
                            path: 'administration/packages-contents-report',
                            params: {
                                reportType: 'BY_BARCODE'
                            },
                            role_id: 'PACKAGES_CONTENTS_REPORT',
                        },
                        {
                            label: 'DISTRIBUTOR_REPORTS.CUSTOMER_PACKAGES_CONTENTS',
                            path: 'administration/packages-contents-report',
                            params: {
                                reportType: 'BY_CUSTOMER'
                            },
                            role_id: 'PACKAGES_CONTENTS_REPORT',
                        },
                        {
                            icon: 'fas fa-project-diagram',
                            label: 'ADMINISTRATION.DAMAGED_PACKAGES_REPORT',
                            path: 'administration/damaged-packages',
                            role_id: 'DAMAGED_PACKAGES'
                        }
                    ]
                },
                {
                    label: 'ADMINISTRATION.COD_REPORTS',
                    role_id: 'MANAGE_COD_CUSTOMERS',
                    subItems: [
                        {
                            label: 'ADMINISTRATION.ALL_COD_REPORTS',
                            path: 'administration/manage-cod-customers',
                            params: {
                                status: ''
                            },
                            role_id: 'MANAGE_COD_CUSTOMERS',
                        },
                        {
                            label: 'ADMINISTRATION.FUTURE_COD_REPORTS',
                            path: 'administration/manage-cod-customers',
                            params: {
                                status: 'FUTURE_COD'
                            },
                            role_id: 'MANAGE_COD_CUSTOMERS',
                        },
                        {
                            label: 'ADMINISTRATION.COD_WITH_CUSTOMER_REPORTS',
                            path: 'administration/manage-cod-customers',
                            params: {
                                status: 'COD_WITH_CUSTOMER'
                            },
                            role_id: 'MANAGE_COD_CUSTOMERS',
                        },
                        {
                            label: 'ADMINISTRATION.COD_AT_HAND_REPORTS',
                            path: 'administration/manage-cod-customers',
                            params: {
                                status: 'COD_AT_HAND'
                            },
                            role_id: 'MANAGE_COD_CUSTOMERS',
                        },
                        {
                            label: 'ADMINISTRATION.RECEIVED_COD_WITHOUT_CUSTODY',
                            path: 'received-cod-without-custody-report',
                            role_id: 'RECEIVED_COD_WITHOUT_CUSTODY_REPORT',
                        }
                    ]
                },
                {
                    icon: 'fas fa-project-diagram',
                    label: 'ADMINISTRATION.MANAGE_ZONES',
                    path: 'administration/manage-zones',
                    role_id: 'MANAGE_ZONES'
                },
                {
                    icon: 'fas fa-hand-holding-usd',
                    label: 'SHIPPING_LINES.SHIPPING_LINES',
                    path: 'administration/shipping-lines',
                    role_id: 'SHIPPING_LINES'
                },
                {
                    icon: 'fas fa-hand-holding-usd',
                    label: 'ADMINISTRATION.MANAGE_SERVICE_TYPES',
                    path: 'administration/service-types',
                    role_id: 'MANAGE_SERVICE_TYPES'
                },
                {
                    icon: 'fas fa-hand-holding-usd',
                    label: 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.MENU_ITEM_LABEL',
                    path: 'administration/package-contents',
                    role_id: 'MANAGE_SERVICE_TYPES'
                },
                {
                    icon: 'fas fa-chart-bar',
                    label: 'ADMINISTRATION.DETAILED_REPORTS',
                    role_id: 'DETAILED_REPORTS',
                    subItems: [
                        {
                            label: 'ADMINISTRATION.CUSTOMER_PACKAGES_DETAILS_REPORT_TITLE',
                            path: 'administration/detailed-reports',
                            params: {
                                report: 'customer-packages-detailed-reports'
                            },
                            role_id: 'CUSTOMER_PACKAGES_DETAILED_REPORTS',
                        },
                        {
                            label: 'ADMINISTRATION.IDLE_CUSTOMER_REPORT_TITLE',
                            path: 'administration/detailed-reports/idle-customers-report',
                            role_id: 'IDLE_CUSTOMERS_DETAILED_REPORTS',
                        },
                        {
                            label: 'ADMINISTRATION.DRIVER_PACKAGES_DETAILED_REPORT_TITLE',
                            path: 'administration/detailed-reports/driver-packages-detailed-report',
                            role_id: 'DRIVER_PACKAGES_DETAILED_REPORT',
                        },
                        {
                            label: 'ADMINISTRATION.DELAYED_PACKAGES_REPORT_TITLE',
                            path: 'administration/detailed-reports/delayed-packages-report',
                            role_id: 'DELAYED_PACKAGES_REPORT',
                        },
                        {
                            label: 'ADMINISTRATION.SHIPPED_PACKAGES_DETAILED_REPORT_TITLE',
                            path: 'administration/detailed-reports',
                            params: {
                                report: 'shipped-packages-detailed-reports'
                            },
                            role_id: 'SHIPPED_PACKAGES_DETAILED_REPORTS',
                        },
                        {
                            label: 'ADMINISTRATION.UNDELIVERED_PACKAGES_DETAILED_REPORT_TITLE',
                            path: 'administration/detailed-reports',
                            params: {
                                report: 'undelivered-packages-detailed-reports'
                            },
                            role_id: 'UNDELIVERED_PACKAGES_DETAILED_REPORTS',
                        },
                        {
                            label: 'ADMINISTRATION.TIME_BOUND_PACKAGES_DETAILED_REPORT_TITLE',
                            path: 'detailed-reports',
                            params: {
                                report: 'time-bound-packages-detailed-report'
                            },
                            role_id: 'TIME_BOUND_PACKAGES_DETAILED_REPORTS',
                        },
                        {
                            label: 'ADMINISTRATION.DRIVER_ATTEMPTS_REPORT',
                            path: 'administration/detailed-reports',
                            params: {
                                report: 'driver-attempts-report'
                            },
                            role_id: 'DRIVER_ATTEMPTS_DETAILED_REPORTS',
                        }
                    ]
                },
                {
                    icon: 'fas fa-line-chart',
                    label: 'ADMINISTRATION.SUMMARY_REPORTS',
                    role_id: 'SUMMARY_REPORTS',
                    subItems: [
                        {
                            label: 'ADMINISTRATION.CUSTOMER_PACKAGES_SUMMARY_REPORT_TITLE',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'customer-packages-summary-reports'
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.DRIVER_PACKAGES_SUMMARY_REPORT_TITLE',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'driver-packages-summary-reports'
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.ZONE_FROM_PACKAGES_SUMMARY_REPORT_TITLE',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'zones-from-packages-summary-reports'
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.ZONE_TO_PACKAGES_SUMMARY_REPORT_TITLE',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'zones-to-packages-summary-reports'
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.CITY_TO_DELIVERED_COD_SUM',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'city-to-cod-summary-reports',
                                isDelivered: true
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.CITY_TO_UNDELIVERED_COD_SUM',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'city-to-cod-summary-reports',
                                isDelivered: false
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.ZONE_PACKAGES_SUMMARY_REPORT_TITLE',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'zones-packages-summary-reports'
                            },
                            role_id: 'SUMMARY_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.UNDELIVERED_PACKAGES_SUMMARY_REPORT_TITLE',
                            path: 'administration/summary-reports',
                            params: {
                                report: 'undelivered-packages-summary-reports'
                            },
                            role_id: 'SUMMARY_REPORTS'
                        }
                    ]
                },
                {
                    icon: 'fas fa-chart-pie',
                    label: 'ADMINISTRATION.ADMINISTRATIVE_REPORTS',
                    role_id: 'ADMINISTRATIVE_REPORTS',
                    subItems: [
                        {
                            label: 'ADMINISTRATION.TIME_PERIOD_PACKAGES_ADMINISTRATIVE_REPORT_TITLE',
                            path: 'administration/administrative-reports',
                            params: {
                                report: 'time-period-packages-administrative-report'
                            },
                            role_id: 'ADMINISTRATIVE_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.CUSTOMER_ACTIVITY_ADMINISTRATIVE_REPORT_TITLE',
                            path: 'administration/administrative-reports',
                            params: {
                                report: 'customer-activity-administrative-reports'
                            },
                            role_id: 'ADMINISTRATIVE_REPORTS'
                        },
                        {
                            label: 'ADMINISTRATION.ACTIVITY_ADMINISTRATIVE_REPORT_TITLE',
                            path: 'administration/administrative-reports',
                            params: {
                                report: 'activity-administrative-reports'
                            },
                            role_id: 'ADMINISTRATIVE_REPORTS'
                        }
                    ]
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'ADMINISTRATION.ARCHIVED_SHIPMENTS',
                    path: 'archived-shipments',
                    role_id: 'ARCHIVED_SHIPMENTS'
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'ADMINISTRATION.ARCHIVED_MASS_COD_REPORT',
                    path: 'administration/archived-mass-cod-report',
                    role_id: 'ARCHIVED_MASS_COD_REPORT'
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'ACCOUNT.SETTINGS',
                    path: 'administration/account-settings',
                    role_id: 'ACCOUNT_SETTINGS'
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'PARCEL.MANAGE',
                    path: 'administration/manage-parcel',
                    role_id: 'MANAGE_PARCEL'
                },
                {
                    label: 'ADMINISTRATION.PAYMENTS',
                    path: 'administration/payments',
                    role_id: 'PAYMENTS'
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'ADMINISTRATION.COMPANY_SETTINGS',
                    path: 'administration/company-settings',
                    role_id: 'COMPANY_SETTINGS'
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'ADMINISTRATION.COMPANY_CONFIGURATION',
                    path: 'administration/company-configuration',
                    role_id: 'COMPANY_CONFIGURATION'
                },
                {
                    icon: 'fas fa-user-cog',
                    label: 'ADMINISTRATION.TRIPS_BETWEEN_CITIES',
                    path: 'administration/trips-between-cities',
                    role_id: 'TRIPS_BETWEEN_CITIES'
                }
            ]
        },
        {
            icon: 'fas fa-chart-bar',
            label: 'GENERAL.TICKETING_SYSTEM',
            role_id: 'TICKETING_SYSTEM',
            subItems: [
                {
                    label: 'TICKETING_SYSTEM.DASHBOARD.DASHBOARD',
                    path: 'ticketing-system',
                    role_id: 'TICKETING_SYSTEM'
                },
                {
                    label: 'TICKETING_SYSTEM.TECHNICAL_SUPPORT_TICKETS',
                    path: 'technical-support-tickets',
                    role_id: 'TICKETING_SYSTEM'
                },
                {
                    label: 'TICKETING_SYSTEM.CATEGORIES.CATEGORIES_MANAGEMENT',
                    path: 'manage-categories',
                    role_id: 'MANAGE_CATEGORIES'
                }
            ]
        },
        {
            icon: 'fas fa-bell',
            label: 'COMPANY.NOTIFICATIONS',
            path: 'company-notifications',
            role_id: 'COMPANY_NOTIFICATIONS'
        },
        {
            icon: 'fas fa-user-cog',
            label: 'ACCOUNT.SETTINGS',
            path: 'account-settings',
            role_id: 'SUPER_ADMIN_ACCOUNT_SETTINGS'
        },
        {
            icon: 'fa fa-money',
            label: 'ADMINISTRATION.PAYMENTS',
            path: 'companies-payments',
            role_id: 'COMPANIES_PAYMENTS'
        },
        {
            icon: 'fa fa-question-circle',
            label: 'GENERAL.KNOWLEDGE_CENTER',
            role_id: 'KNOWLEDGE_CENTER',
            showBadge: true,
            badgeOp: updatesBadgeOp.asObservable(),
            subItems: [
                // {
                //     icon: 'fa fa-wrench',
                //     label: 'SYSTEM_UPDATES.SYSTEM_UPDATES_MENU',
                //     path: 'system-updates',
                //     role_id: 'SYSTEM_UPDATES',
                //     showBadge: true,
                //     badgeOp: updatesBadgeOp.asObservable()
                // },
                {
                    icon: 'fa fa-wrench',
                    label: 'SYSTEM_UPDATES.VIDEOS_SYSTEM_UPDATES',
                    path: 'videos-system-updates',
                    role_id: 'VIDEOS_SYSTEM_UPDATES'
                },
                {
                    icon: 'fa fa-wrench',
                    label: 'SYSTEM_UPDATES.DOCUMENTS_SYSTEM_UPDATES',
                    path: 'documents-system-updates',
                    role_id: 'DOCUMENTS_SYSTEM_UPDATES'
                },
                {
                    icon: 'fa fa-question-circle',
                    label: 'FAQ.FAQ_MENU',
                    path: 'faq',
                    role_id: 'FAQ'
                },
            ]
        }
    ],
    STATIC_SERVICE_TYPES: {
        GENERAL_SERVICE_TYPES: [
            'STANDARD', 'EXPRESS', 'THREE_TO_FIVE_DAYS'
        ],
        VEE_SERVICE_TYPES: ['STANDARD'],
        ALHADAF_SERVICE_TYPES: ['STANDARD'],
        LCL_SERVICE_TYPES: [
            'AIR', 'SEA', 'EXPRESS'
        ]
    },
    SHIPMENT_TYPES: {
        GENERAL_SHIPMENT_TYPES: [
            'REGULAR', 'COD', 'SWAP', 'BRING',
        ],
        FULFILMENT_ORDER_SHIPMENT_TYPES: [
            'REGULAR', 'COD'
        ],
        SARE3_SHIPMENT_TYPES: [
            'REGULAR', 'COD'
        ],
        LCL_SHIPMENT_TYPES: [
            'COD', 'NON_COD', 'PAID_COD'
        ],
        TRUCKING_SHIPMENT_TYPES: [
            'BRING', 'COD'
        ],
        JETT_STORE_TO_STORE: [
            'COD_V2'
        ],
        JETT_OTHER_SHIPMENTS_TYPES: [
            'COD',
            'COD_V2',
            'SWAP',
            'BRING'
        ],
        JETT_SHIPMENT_TYPES: [
            'STORE_TO_STORE',
            'STORE_TO_DOOR_A',
            'STORE_TO_DOOR_B',
            'OUTBOUND'
        ]
    },
    PAYMENT_METHODS: [
        'ALL',
        'CASH',
        'CHEQUE',
        'BANK_TRANSFER',
        'PREPAID',
        'DIGITAL_WALLET',
        'CARD'
    ],
    SOURCE_TYPES_SAR: [
        'ZID',
        'SALLA',
        'OTO',
        'TOROD',
        'SHOPIFY',
        'PARTNER',
        'MANUAL',
        'FULFILLMENT',
        'EXCEL',
    ],
    SOURCE_TYPES: [
        'PARTNER',
        'MANUAL',
        'FULFILLMENT',
        'EXCEL',
    ],
    CUSTOMER_TYPES: [
        'NORMAL',
        'EXPRESS'
    ],
    ACCESS_TYPE: [
        'ALL',
        'LAST_MILE',
        'FULFILLMENT'
    ],
    EXPECTED_MONTHLY_PACKAGES_NUMBER: ['1-50', '51-200', 'MORE']
};
export const THIRD_PARTY= ['ARAMEX', 'IMILE', 'JNT', 'LIONWHEEL', 'RED_BOX', 'SMB', 'TOROD', 'HOLOL'];
export const COD_COLLECTION_METHOD = ['CASH', 'CHEQUE', 'BANK_TRANSFER', 'PREPAID', 'DIGITAL_WALLET', 'CARD'];
export const JETT_COD_COLLECTION_METHOD = ['CASH', 'CARD'];
export const PRINT_OPTIONS = ['NET_REPORT', 'WITHOUT_COST', 'WITHOUT_COD'];
export const COD_TYPES = ['WITH_DRIVER', 'DELIVERED', 'EXPORTED_BY_ACCOUNTANT', 'INVOICE', 'PENDING_CUSTOMER_RECEIVING_CONFIRMATION'];
export const COMPANIES_TYPES = ['LAST_MILE', 'LCL', 'DISTRIBUTOR', 'TRUCKING', 'CORPORATE'];
export const COMPANIES_WITH_CUSTOM_TRANSLATIONS = ['sare3', 'gds', 'deliverypoint', 'sar', 'mohanad', 'yaqoutlogistics', 'jett', 'sprint'];
export const ROUTE_SHARED = {
    // HOME_SHIPMENT: 'home/manage-shipment-new',
    HOME_SHIPMENT: 'home/manage-shipments',
};
export const VALIDATION_CONSTANTS = {
    PLATE_NO_MIN_LENGTH: 6,
    PASSWORD_PATTERN: /(?=.*[a-zA-Z])(?=.*[0-9]).{6,}/,
    PASSWORD_MIN_LENGTH: 6,
    USERNAME_EMAIL_PATTERN: /^([A-Za-z0-9-_.]{6,}|[A-Za-z0-9-_.]+@[A-Za-z0-9]+(\.[a-z]{2,})?)$/,
    USERNAME_EMAIL_MIN_LENGTH: 6
};

export const BARCODE_PATTERN = {
    PACKAGE: /^1[0-9]{11}$/,
    LCL_ITEM: /^4[0-9]{11}$/
};
