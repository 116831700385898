import {Component, OnInit} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {UserModel} from '../../shared/models/user.model';
import {UsersService} from '../../users/services/users.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {USERS_CONSTANTS} from '../../users/services/constants';
import {HubsService} from "../../shared/services/hubs.service";

@Component({
    selector: 'app-choose-existing-component',
    templateUrl: 'choose-existing.component.html',
    styleUrls: ['choose-existing.component.scss']
})
export class ChooseExistingComponent implements OnInit {
    public isSubmitting = false;
    public searchContent = '';
    public spinnerState = SpinnerState.LOADING;
    public spinnerStats = SpinnerState;
    public users: UserModel[];
    public customersURL = USERS_CONSTANTS.GET_CUSTOMERS;
    public customersKey = 'customers';
    public isFullName = false;
    private PAGE_SIZE = 100000;
    public driverPricing = false;
    public driverURL = USERS_CONSTANTS.GET_DRIVERS;
    isHubsPricingList = false;


    constructor(
        private usersService: UsersService,
        private hubsService: HubsService,
        private activeModal: NgbActiveModal
    ) {
    }

    ngOnInit() {
        this.getCustomers();
    }

    getCustomers(search?: string, thirdParty = false) {
        this.spinnerState = SpinnerState.LOADING;
        let query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=20';
        if (this.isHubsPricingList) {
            this.getHubs(query);
        } else {
            query+= '&status=VERIFIED';
            if (this.driverPricing) {
                if (thirdParty) {
                    query += '&driverType=THIRD_PARTY';
                } else {
                    query += '&driverType=TYPICAL';
                }
                this.usersService.getDrivers(query, this.driverURL).subscribe(
                    (response: any) => {
                        this.users = response;
                        if (this.users && this.users.length) {
                            this.spinnerState = SpinnerState.LOADED;
                        } else {
                            this.spinnerState = SpinnerState.EMPTY;
                        }
                    }, () => {
                        this.spinnerState = SpinnerState.FAILED;
                    }
                );
            } else {
                this.usersService.getCustomers(query, this.customersURL).subscribe(
                    (response: any) => {
                        this.users = response[this.customersKey];
                        if (this.users && this.users.length) {
                            this.spinnerState = SpinnerState.LOADED;
                        } else {
                            this.spinnerState = SpinnerState.EMPTY;
                        }
                    }, () => {
                        this.spinnerState = SpinnerState.FAILED;
                    }
                );
            }
        }

    }

    getHubs(query: string) {
        this.spinnerState = SpinnerState.LOADING;
        // const query = (search) ? '?page=1&pageSize=' + this.PAGE_SIZE + '&search=' + search : '?page=1&pageSize=' + this.PAGE_SIZE;
        this.hubsService.getHubs(query, '', false, this.isHubsPricingList).subscribe(
            (response: any) => {
                this.users = response.hubs;
                // this.users = response.hubs.map(hub => {
                //     return {
                //         label: hub.name,
                //         value: hub.id,
                //     };
                // });
                if (this.users && this.users.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }


    public onSearch() {
        this.spinnerState = SpinnerState.LOADING;
        this.getCustomers(this.searchContent);
    }

    public chooseUser(user: UserModel) {
        this.activeModal.close({isSuccess: true, user: user});
    }

    public closeModal() {
        this.activeModal.close();
    }
}
