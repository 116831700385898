<p-sidebar  [(visible)]="display"
            (onHide)="onClose()"
            [dismissible]="false"
            #AddPackageSideBar
            appendTo="body"
            styleClass="wallet-sidebar"
            [baseZIndex]="2"
>
    <div class="loader-spinner" *ngIf="spinnerState === spinnerStates.LOADING">
            <span>
                <i class="fas fa-spinner fa-spin"></i>
            </span>
    </div>
    <div *ngIf="spinnerState === spinnerStates.FAILED" style="width: 100%; height: 100%;">
        <app-no-data-available-component></app-no-data-available-component>
    </div>
    <div (click)="onClose()" class="close-icon cursor-pointer">
        <img src="../../../assets/icons/track_package/close-package-tracker-icon.svg" alt="" />
    </div>
    <div class="" *ngIf="spinnerState === spinnerStates.LOADED">
        <div class="row tracker-container">
            <div #packageInfo class=" col-12 package-info" style="background-color: #fef5e9; position: relative;">
                <div class="hidden-sm-down">
                    <div class="package-icon" [ngClass]="{'package-icon-en': currentLang === 'en'}">
                        <img class="icon w-100" src="../../../assets/icons/general/transaction.svg" alt="">
                    </div>
                </div>
                <div #mainInfo class="main" [ngClass]="{'padding-left-95': currentLang === 'en'}">
                    <div class="details" [ngClass]="{'details-en' : currentLang === 'en'}">
                        <div class="info" [ngClass]="{'info-en': currentLang === 'en'}">
                            <table>
                                <tr>
                                    <td class="table-td-label">
                                        <img class="driver-icon" src="../../../assets/icons/taxi-driver.png" alt="">
                                    </td>
                                    <td class="td-width-5-percentage"></td>
                                    <td>
                                        <div class="row"><span class="table-td-info notes" style="font-family: almarai;">{{wallet?.customerName}}</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="table-td-label">
                                        <img class="driver-icon" src="../../../assets/icons/general/fiat_money.svg" alt="">
                                    </td>
                                    <td class="td-width-5-percentage"></td>
                                    <td>
                                        <div class="row"><span class="table-td-info notes" style="font-family: almarai;">{{wallet?.amount| number : digitFormat | currencyValue}}</span></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="package-tracking" [ngStyle]="{'height': detailsDivHeight + 'px' }">
                    <table class="tracking" [ngClass]="{'tracking-en': currentLang === 'en'}">
                        <table *ngFor="let shipment of packagesHistory">
                            <tr>
                                <td class="empty-td" [ngClass]="{'empty-td-en' : currentLang === 'en'}"></td>
                            </tr>
                            <tr class="date-cell">
                                <td class="driver-name-time not-empty" [ngClass]=" {'not-empty-en': currentLang === 'en'}"></td>
                                <td class="td-25-space"></td>
                                <td><div class="date" [ngClass]="{'date-en': currentLang === 'en'}">{{shipment.date | date:'dd/MM/yyyy'}}</div></td>
                            </tr>
                            <tr>
                                <td class="driver-name-time not-empty" [ngClass]="{'not-empty-en': currentLang === 'en'}">
                                    <div class="driver-name">{{shipment.createdBy}}</div>
                                    <div class="time" [ngClass]="{'time-en': currentLang === 'en'}">{{getLocalTime(shipment.createdDate)}}</div>
                                </td>
                                <td class="td-25-space"></td>
                                <div class="tracking-no-space">
                                    <td>
                                        <div class="d-flex"><span class="{{shipment.type }} tracking-status"
                                                                  [ngClass]="{'tracking-status-en': currentLang === 'en'}">{{shipment.msg}}</span>
                                        </div>
                                    </td>
                                </div>
                            </tr>
                            <tr>
                                <td class="not-empty" [ngClass]="{'not-empty-en': currentLang === 'en'}" style="height: 10px;"></td>
                            </tr>
                        </table>
                    </table>
                </div>
            </div>
        </div>
    </div>


</p-sidebar>
