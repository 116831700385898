import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-zones-from-packages-summary-report',
    templateUrl: './zones-from-packages-summary-report.component.html',
    styleUrls: ['./zones-from-packages-summary-report.component.css']
})
export class ZonesFromPackagesSummaryReportComponent {
    public zonesFromReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_FROM_REPORT;
    public zonesFromReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_FROM_REPORT_API;

    constructor() {
    }
}
