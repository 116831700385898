import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RouteModel} from '../../../shared/models/route.model';
import {UtilitiesService} from '../../../shared/services/utilities.service';
import {UserService} from '../../../shared/services/user.service';
import {AddressSystemPipe} from '../../../shared/pipes/address-system.pipe';
import {DriversService} from '../../../shared/services/drivers.service';
import {MessageService} from 'primeng/api';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {FormBuilder, FormGroup} from '@angular/forms';

declare let L;

@Component({
    selector: 'app-driver-route-dialog',
    templateUrl: './driver-route-dialog.component.html',
    styleUrls: ['./driver-route-dialog.component.scss']
})
export class DriverRouteDialogComponent implements OnInit {
    currentLang: string;
    isLoading = false;
    route: RouteModel;
    locations = [];
    startLocation: {lat: number, lng: number};
    driver: any;

    public map;
    public mapMarkers = [];
    public routePolyline;
    private searchInput: any;

    filterForm: FormGroup;

    constructor(private translateService: TranslateService,
                private utilitiesService: UtilitiesService,
                private userService: UserService,
                private driversService: DriversService,
                private addressSystemPipe: AddressSystemPipe,
                private messageService: MessageService,
                private formBuilder: FormBuilder,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.currentLang = this.translateService.currentLang;
        this.initFilterForm();
        setTimeout(() => {
            this.translateService.get('GENERAL.SEARCH').subscribe(searchPlaceholder => {
                this.initMap(searchPlaceholder);
            });
        }, 1000);
    }

    initFilterForm() {
        this.filterForm = this.formBuilder.group({
            isShowDeliver: [true],
            isShowPickup: [false]
        });
        this.filterForm.valueChanges.subscribe(value => {
            if ((value.isShowDeliver || value.isShowPickup) && this.startLocation) {
                this.getRoute();
            }
        });
    }

    initMap(searchPlaceholder?) {
        const initialMapLocation = this.userService.getInitialMapView();
        const map = (this.translateService.currentLang === 'en') ? 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png' :
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.map = new L.map('map', {
            fullscreenControl: true
        });

        this.map.setView([initialMapLocation.lat, initialMapLocation.lng], initialMapLocation.zoom);

        L.tileLayer(map, {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.map.crs = L.CRS.EPSG4326;
        this.map.addEventListener('click', (event) => {
            this.map.invalidateSize();
            this.startLocation = {
                lat: event.latlng.lat,
                lng: event.latlng.lng
            };
            this.getRoute();
            // this.mapMarker = L.marker([event.latlng.lat, event.latlng.lng], 8).addTo(this.map);
            // this.map.flyTo(this.mapMarker.getLatLng(), 8);
            // this.map.flyTo(this.mapMarker.getLatLng(), this.MAP_ZOOM);
        });

        this.searchInput = new L.esri.Geocoding.geosearch({
            providers: [
                L.esri.Geocoding.arcgisOnlineProvider({
                    // API Key to be passed to the ArcGIS Online Geocoding Service
                    apikey: SHARED_CONSTANTS.LEAFLET_SEARCH_API_KEY
                })
            ],
            useMapBounds: false,
            placeholder: searchPlaceholder
        }).addTo(this.map);

        // this.searchResults = L.layerGroup().addTo(this.map);

        this.searchInput.on('results', (data) => {
            this.startLocation = {
                lat: data.results[0].latlng.lat,
                lng: data.results[0].latlng.lng
            };
            this.getRoute();
            // this.searchResults.clearLayers();
            // this.mapMarker = L.marker(data.results[0].latlng).addTo(this.map);
            // this.searchResults.addLayer(this.mapMarker);
        });

        if (this.driver.longitude && this.driver.latitude) {
            this.startLocation = {
                lat: this.driver.latitude,
                lng: this.driver.longitude
            };
            this.getRoute();
        }
        this.isLoading = false;
    }

    cancel() {
        this.activeModal.close();
    }

    private initLocations() {
        this.locations = this.utilitiesService.decodePathString(this.route.geometry);
    }

    private removeMapLayers() {
        this.mapMarkers.forEach(marker => {
            this.map.removeLayer(marker);
        });

        if (this.routePolyline) {
            this.map.removeLayer(this.routePolyline);
        }

        this.mapMarkers = [];
    }

    private drawMapMarkers() {
        this.removeMapLayers();
        this.initLocations();

        this.routePolyline = L.polyline(this.locations, {color: 'red'}).addTo(this.map);

        this.map.fitBounds(this.routePolyline.getBounds());

        this.translateService.get('ROUTE_OPTIMIZATION.START').subscribe(value => {
            const startIcon = L.divIcon({
                html: `<div class="marker-start-icon"><span>${value}</span></div>`,
                className: 'marker-container',
                iconSize: [40, 60], // size of the icon
                iconAnchor: [20, 60], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -60] // point from which the popup should open relative to the iconAnchor
            });
            const startMarker = L.marker([this.startLocation.lat, this.startLocation.lng],
                {icon: startIcon}).addTo(this.map);

            this.mapMarkers.push(startMarker);
        });

        const packagesCountWithLocations: {
            [villageId: number]: { lat: number, lng: number, sequence: number, count: number, villageName: string, status: string }
        } = {};
        let sequence = 1;
        this.route.items.forEach(
            (pkg) => {
                if (pkg.location.latitude !== 0 || pkg.location.longitude !== 0) {
                    if (!packagesCountWithLocations[pkg.destinationAddress.villageId]) {
                        packagesCountWithLocations[pkg.destinationAddress.villageId] = {
                            lat: pkg.location.latitude,
                            lng: pkg.location.longitude,
                            sequence: sequence++,
                            count: 1,
                            villageName: pkg.status === 'SCANNED_BY_DRIVER_AND_IN_CAR' ? this.currentLang === 'en'
                                ? pkg.destinationAddress.village : pkg.destinationAddress.arabicVillageName
                                : this.currentLang === 'en' ? pkg.originAddress.village : pkg.originAddress.arabicVillageName,
                            status: pkg.status
                        };
                    } else {
                        packagesCountWithLocations[pkg.destinationAddress.villageId].count++;
                    }
                }
            }
        );

        this.translateService.get([
            'ROUTE_OPTIMIZATION.FILTER.DELIVER',
            'ROUTE_OPTIMIZATION.FILTER.PICKUP',
            'ROUTE_OPTIMIZATION.TOTAL_PACKAGES',
            'ROUTE_OPTIMIZATION.VILLAGE_NAME']).subscribe(translateValues => {
            translateValues['ROUTE_OPTIMIZATION.VILLAGE_NAME'] =
                this.addressSystemPipe.transform(translateValues['ROUTE_OPTIMIZATION.VILLAGE_NAME']);

            Object.values(packagesCountWithLocations).forEach((value) => {
                let statusClass;
                let filterText;
                switch (value.status) {
                    case 'SCANNED_BY_DRIVER_AND_IN_CAR':
                        statusClass = 'delivery-marker';
                        filterText = translateValues['ROUTE_OPTIMIZATION.FILTER.DELIVER'];
                        break;
                    default:
                        statusClass = 'pickup-marker';
                        filterText = translateValues['ROUTE_OPTIMIZATION.FILTER.PICKUP'];
                        break;
                }
                const carIcon = L.divIcon({
                    html: `<div class="marker-icon ${statusClass}"><span>${value.sequence}</span></div>`,
                    className: 'marker-container',
                    iconSize: [30, 45], // size of the icon
                    iconAnchor: [15, 45], // point of the icon which will correspond to marker's location
                    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor
                });
                const popupContent = `<div style="text-align: center"><b>${filterText}</b>` +
                    `<br><b>${translateValues['ROUTE_OPTIMIZATION.TOTAL_PACKAGES']}: ${value.count}</b>` +
                    `<br><b>${translateValues['ROUTE_OPTIMIZATION.VILLAGE_NAME']}: ${value.villageName}</b></div>`;
                const popup = L.popup();
                popup.setContent(popupContent);
                const marker = L.marker([value.lat, value.lng],
                    {icon: carIcon}).addTo(this.map).bindPopup(popup);

                this.mapMarkers.push(marker);
            });
        });
        this.isLoading = false;
    }

    private getRoute() {
        this.isLoading = true;
        const queryParams = this.getQueryParams();
        this.driversService.getDriverOptimizedRoute(this.driver.id, queryParams).subscribe((driverRoute: RouteModel) => {
            this.route = driverRoute;
            if (this.route && this.route.geometry) {
                this.drawMapMarkers();
            } else {
                this.translateService.get('ROUTE_OPTIMIZATION.ALERTS.NO_ROUTE').subscribe(translateValue => {
                    this.messageService.add({
                        severity: 'error',
                        detail: translateValue
                    });
                });
                this.isLoading = false;
            }
        }, () => {
            this.isLoading = false;
        });
    }

    private getQueryParams() {
        const params = {
            latStart: this.startLocation.lat,
            longStart: this.startLocation.lng
        };

        const filterParams = this.filterForm.getRawValue();
        if (filterParams.isShowPickup && filterParams.isShowDeliver) {
            params['type'] = 'ALL';
        } else if (filterParams.isShowDeliver) {
            params['type'] = 'DELIVERY';
        } else if (filterParams.isShowPickup) {
            params['type'] = 'PICKUP';
        }
        return params;
    }
}
