<ng-template #changePackageStatusDialog>
    <div style="width: 380px;overflow: auto !important;max-height: 50vh">
        <app-change-package-status-component appStopPropagation [shipment]="shipment" [isShowCurrentStatus]="false"
                                             (onFinish)="changePackageStatusPopOver.close()"
                                             [propertyName]="propertyName"
                                             (onCancel)="changePackageStatusPopOver.close()">
        </app-change-package-status-component>
    </div>
</ng-template>
<div class="d-flex align-items-stretch"
     [ngClass]="isEditEnabled ? 'justify-content-between' : 'justify-content-center'">
    <div class="shipment-table-status" [style.width.px]="width" [ngClass]="isEditEnabled ? 'status-with-edit' : ''"
         [ngStyle]="{'background-color': getBackgroundColor(getStatus())}">
        {{(isLcl ? 'LCL.STATUS_LIST.' : '') + getStatus() | translate | packageTitle}}
    </div>
    <div style="position: relative"
         *ngIf="isEditEnabled"
         #changePackageStatusPopOver="ngbPopover"
         [placement]="'right'+customPlacement"
         autoClose="outside"
         container="body"
         class="edit-icon"
         (click)="onEditClick($event)"
         [ngStyle]="{'border-color' : getBackgroundColor(getStatus()),
                          'color': getBackgroundColor(getStatus())}"
         [ngbPopover]="changePackageStatusDialog"
         popoverClass="receiver-sender-name">
        <span tooltipPosition="top"
              pTooltip="{{ ('MANAGE_SHIPMENTS.ACTIONS_LABEL.CHANGE' | translate) + ' ' + ('MANAGE_SHIPMENTS.ACTIONS_LABEL.STATUS' | translate) }}">
            <i class="fas fa-pen"></i>
        </span>
    </div>
</div>
