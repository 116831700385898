import {Injectable} from '@angular/core';
import {EmailModel} from '../models/email.model';
import {HttpClient} from '@angular/common/http';
import {INBOX_CONSTANTS} from './constant';

@Injectable({providedIn: 'root'})
export class InboxService {
    constructor(
        private http: HttpClient
    ) {
    }

    public getInboxMessages(pageNumber) {
        const queryParams = {
            pageSize: INBOX_CONSTANTS.PAGE_SIZE + '',
            // add 1 to page number, the server starts from 1 instead of 0.
            page: (pageNumber + 1) + ''
        };
        return this.http.get(INBOX_CONSTANTS.GET_EMAILS, {params: queryParams});
    }

    /**
     *
     */
    public setEmailAsOpened(email: EmailModel) {
        const url = INBOX_CONSTANTS.SET_EMAIL_OPENED.replace('{emailId}', email.id + '');
        return this.http.put(url, {});
    }

    /**
     *
     * @param emailId
     */
    public getEmailReplies(emailId) {
        const url = INBOX_CONSTANTS.GET_EMAIL_REPLIES.replace('{msgId}', emailId);
        return this.http.get(url);
    }

    public sendReply(emailId, body) {
        const url = INBOX_CONSTANTS.SEND_REPLY.replace('{messageId}', emailId);
        return this.http.post(url, {body});
    }

    public getAllUsers(query: string) {
        return this.http.get(INBOX_CONSTANTS.GET_ALL_USERS + query);
    }

    public getAllCustomers(query: string) {
        return this.http.get(INBOX_CONSTANTS.GET_ALL_CUSTOMERS + query);
    }

    public sendToCustomers(body) {
        return this.http.post(INBOX_CONSTANTS.SEND_MESSAGE_TO_CUSTOMERS, body);
    }

    public sendToUsers(body) {
        return this.http.post(INBOX_CONSTANTS.SEND_MESSAGE_TO_USERS, body);
    }

    public getUnreadMessages() {
        return this.http.get(INBOX_CONSTANTS.GET_MESSAGES_COUNT);
    }
}
