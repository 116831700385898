<div class="map-container">
    <div class="long-lat-container" *ngIf="!readOnly">
        <div class="long-lat-input">
            <input type="number" placeholder="Latitude" [(ngModel)]="LatInput">
            <input type="number" placeholder="Longitude" [(ngModel)]="lngInput">
        </div>
        <div class="save-action" (click)="setLongLat()" [ngClass]="{'disabled-button disabled': !this.LatInput || !this.lngInput}">
            <span>{{ 'MANAGE_SHIPMENTS.ACTIONS_LABEL.DETECT' | translate }}</span>
            <i class="fas fa-check"></i>
        </div>
    </div>

    <div class="header" *ngIf="showClose">
        <div class="close-action" (click)="closeModal()">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <div id="modal-map"></div>
    <div class="footer" *ngIf="!readOnly">
        <div class="reset-action" (click)="reset()">
            <span>{{ 'ACTIONS.RESET' | translate }}</span>
            <i class="fas fa-repeat"></i>
        </div>
        <div class="save-action" (click)="saveLocation()">
            <span>{{ 'ACTIONS.SAVE' | translate }}</span>
            <i class="fas fa-check"></i>
        </div>
    </div>
</div>

