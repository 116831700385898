import {Component, Input, OnInit} from '@angular/core';
import {SkeletonLoaderTypes} from './models/skeleton-loader.enum';

@Component({
    selector: 'app-skeleton-loader',
    templateUrl: 'skeleton-loader.component.html',
    styleUrls: ['skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
    /**
     * The type of the skeleton loader.
     */
    @Input() type: SkeletonLoaderTypes;

    /**
     * controls the appearance of the spinner.
     */
    @Input() display: boolean = true;

    @Input() isSingleLoader = false;
    @Input() dashboardCard = false;
    @Input() repeatSingleLine = 1;

    /**
     *
     * @type {SkeletonLoaderTypes}
     */
    readonly skeletonTypes = SkeletonLoaderTypes;

    readonly Array = Array;
}
