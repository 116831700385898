<div class="h-100 single-report-page">
    <app-admin-report [columns]="customersReports"
                      [apiUrl]="customersReportsAPI"
                      [startFromIndex] = 1
                      [isPdfRemove]="false"
                      [hasFilters]= true
                      [filterCustomer]= "true"
                      [filterHubs]= "true"
                      [filterDrivers] = "true"
                      [filterDate] = "true"
                      setDefaultDate="MONTH"
                      [TYPE] = "'activate-customers-report'"
                      class="d-flex h-100"
                      reportTitle="SHIPMENT.ACTIVE_CUSTOMERS_REPORTS"></app-admin-report>
</div>
