import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective {
    isViewed = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    @Input() set appPermission(condition: boolean) {
        if (condition && !this.isViewed) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isViewed = true;
        } else if (!condition && this.isViewed) {
            this.viewContainer.clear();
            this.isViewed = false;
        }
    }
}
