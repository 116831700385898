import {Component, OnInit, ViewChild} from '@angular/core';
import {CodCustomer} from '../../shared/models/cod-customer';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {ChooseHubDriverPartnerComponent} from '../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../users/services/users.service';
import {ShipmentsService} from '../services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RolesService} from '../../shared/services/roles/roles.service';
import {DatePipe} from '@angular/common';
import {UserService} from '../../shared/services/user.service';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Component({
    selector: 'app-hubs-custody',
    templateUrl: './hubs-custody.component.html',
    styleUrls: ['./hubs-custody.component.scss']
})
export class HubsCustodyComponent implements OnInit {

    public customers: CodCustomer[];

    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public isLoading = false;
    public customerId;
    // is all selected;
    public isAllSelected = false;
    public isThereSelection = false;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    permissions = '';

    public pageNumber = 0;
    public totalRecords = 0;
    public searchContent = '';
    public fromDate;
    public toDate;
    public status;
    public receive = false;
    public sort = false;
    public totalCod = 0;
    public selectedLanguage = '';
    public packages: any[];
    public receivedMoney = 0;
    @ViewChild('table', {static: false}) table;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;

    constructor(private router: Router,
                private usersService: UsersService,
                private shipmentsService: ShipmentsService,
                private confirmService: ConfirmationService,
                private messageService: MessageService,
                public translateService: TranslateService,
                private modalService: NgbModal,
                private rolesService: RolesService,
                private route: ActivatedRoute,
                private datePipe: DatePipe,
                private userService: UserService,
                private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
    }

    private initPackages() {
        this.spinnerState = SpinnerState.LOADING;
        const params = this.createParams();
        this.shipmentsService.getCustodyPackages(params).subscribe(
            (response: { codpackages?: any[], data?: any[], totalRecordsNo: number, codSum: number }) => {
                this.packages = response.codpackages ? response.codpackages : response.data;
                this.receivedMoney = response.codSum;
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }
        );
    }

    public createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
        };

        if (this.searchContent !== '') {
            params['search'] = this.searchContent;
        }
        if (this.fromDate) {
            params['fromDate'] = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            params['toDate'] = this.transformDate(this.toDate);
        }
        if (this.fromDate || this.toDate) {
            params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return params;
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public loadPackagesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        this.initPackages();
    }

    public addToHub() {
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent,
            {backdrop: 'static', windowClass: 'verify-driver', size: 'lg'});
        modal.componentInstance.titleKey = 'ACCOUNTING.ADD_TO_HUB_CUSTODY';
        modal.componentInstance.showHubs = false;
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, hubId?: number }) => {
                if (status.isSuccess) {
                    if (status.barcode) {
                        this.router.navigate([this.authenticationService.companyName + '/home/hub-custody/'
                        + status.barcode], {
                            queryParams: {isReceive: false}
                        });
                    }
                }
            }
        ).catch(
            () => {
            }
        );
    }

    public onSearch() {
        this.pageNumber = 0;
        this.table.reset();
    }

    public copyText(barcode) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = barcode;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.translateService.get('ALERTS.COPIED').subscribe(
            (translateValue) => {
                this.messageService.add({severity: 'success', detail: translateValue});
            }
        );
    }

}
