<div class="choose-payment-type d-flex flex-column" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="close-icon cursor-pointer">
        <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt=""/>
    </div>
    <div class="title">{{('BANK_TRANSFERS.CHOOSE_PAYMENT_TYPE') | translate }}</div>
    <div class="contents-container d-flex flex-column">
        <form [formGroup]="form" *ngIf="form">
            <div class="types d-flex justify-content-center">
                <p-selectButton
                        [options]="paymentTypes"
                        (onChange)="resetData()"
                        formControlName="paymentType">
                </p-selectButton>
            </div>
            <div class="inputs-container d-flex flex-wrap justify-content-between">
                <div *ngIf="showAddAttachments()" class="input-item w-100">
                    <div class="input-label"></div>
                    <div class="input-value">
                        <div class="row attachments-container">
                            <app-file-uploader [compressed]="true" [maxFiles]="2"
                                               #attachmentsUploader></app-file-uploader>
                        </div>
                    </div>
                </div>
                <div *ngIf="form.value.paymentType !== 'CASH'"
                     class="input-item">
                    <div class="input-label">{{'BANK_TRANSFERS.DOCUMENT_NUMBER' | translate}}</div>
                    <div class="input-value">
                        <app-input-field-component
                                [form]="form"
                                controlName="documentNumber"
                                [isRequired]="form.value.paymentType !== 'CASH'"
                                controlType="text">
                        </app-input-field-component >
                    </div>
                </div>
                <div *ngIf="form.value.paymentType === 'BANK_TRANSFER' || form.value.paymentType === 'DIGITAL_WALLET' || form.value.paymentType === 'CASH'"
                     class="input-item flex-1">
                    <div class="input-label">{{'BANK_TRANSFERS.TRANSFER_FEES' | translate}}</div>
                    <div class="input-value">
                        <app-input-field-component
                                [form]="form"
                                controlName="transferFees"
                                controlType="number">
                        </app-input-field-component >
                    </div>
                </div>
                <div class="input-item" [ngClass]="{'w-100': form.value.paymentType !== 'CHEQUE'}">
                    <div class="input-label">{{(isInvoices ? "COMPANY.PAYMENT_DATE" : 'MANAGE_SHIPMENTS.DELIVERY_DATE') | translate}}</div>
                    <div class="input-value">
                        <p-calendar formControlName="deliveryDate" styleClass="d-flex"
                                    [inputStyle]="{width: '100%'}" [showTime]="true" dateFormat="dd/mm/yy">
                        </p-calendar>
                    </div>
                </div>

                <div *ngIf="showPrintReport" class="inline-input d-flex w-100 align-items-center">
                    <div class="input-label">{{(isInvoices ? 'ACCOUNTING.PRINT_DELIVER_REPORT' : 'ACCOUNTING.PRINT_DELIVER_REPORT')|translate}}</div>
                    <div class="input-value switch-button-container">
                        <p-inputSwitch [formControl]="form?.controls['printReport']"
                                       (onChange)="togglePrintReport($event)">
                        </p-inputSwitch>
                    </div>
                </div>
            </div>
        </form>
        <div class="bottom-actions d-flex justify-content-end">
            <div class="reset-button cursor-pointer " (click)="closeModal()">
                {{"ACTIONS.CANCEL" | translate}}
            </div>
            <div class="add-button cursor-pointer "
                 (click)="finish()"
                 [ngClass]="{'disabled-button disabled': !form.valid}">
                {{'ACTIONS.DONE' | translate }}
            </div>
        </div>
    </div>

</div>


<div class="mob-container">
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
</div>
