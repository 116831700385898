import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../../login/services/login.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UsersService} from '../../../users/services/users.service';

@Component({
    selector: 'app-attachments-view',
    templateUrl: './attachments-view.component.html',
    styleUrls: ['./attachments-view.component.scss']
})
export class AttachmentsViewComponent implements OnInit {
    public currentLang;
    public user;
    attachments: string[] = [];
    isLoading = false;
    constructor(private formBuilder: FormBuilder,
                private activeModal: NgbActiveModal,
                public translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private usersService: UsersService) {
    }

    ngOnInit(): void {
        this.currentLang = this.translateService.currentLang;
        this.loadAttachments();
    }

    public closeModal() {
        this.activeModal.close();
    }


    openAttachment(att: string) {
        window.open(att, '_blank');
    }

    deleteAttachment(att: string) {
        this.confirmationService.confirm({
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
            message: this.translateService.instant('ACTIONS.CONFIRM_DELETE_ATTACHMENTS'),
            accept: () => {
                this.isLoading = true;
                this.usersService.deleteUserAttachments(this.user.id, att).subscribe((res: any) => {
                    this.loadAttachments();
                });
            }
        });
    }

    private loadAttachments() {
        this.isLoading = true;
        this.usersService.getUserAttachments(this.user.id).subscribe((res: any) => {
            this.isLoading = false;
            this.attachments = res.data;
        });
    }
}
