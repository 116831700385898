import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {UserService} from '../services/user.service';

@Pipe({
    name: 'packageTitle'
})
@Injectable()
export class PackageTitlePipe implements PipeTransform {

    constructor(private userService: UserService) {
    }
    public transform(value: string, args?: any): any {
        let result = value;
        if (!result) {
            return result;
        }
        if (this.userService.userInfo.isLcl) {
            result = result.replace(/(Shipment|Package)/, 'Order');
            result = result.replace(/(shipment|package)/, 'order');
            result = result.replace('طرد', 'طلبية');
            result = result.replace('طرود', 'طلبيات');
        } else if (this.userService.userInfo.isTrucking) {
            result = result.replace('Package', 'Shipment');
            result = result.replace('package', 'shipment');
            result = result.replace('طرد جديد', 'شحنة جديدة');
            result = result.replace('طرد', 'شحنة');
            result = result.replace('طرود', 'شحنات');
        } else if (this.userService.userInfo.isDistributor) {
            result = this.transformToDistributor(result);
        } else {
            switch (this.userService.userInfo.companyId) {
                case 209:
                    result = result.replace(/(Shipment|Package)/, 'Order');
                    result = result.replace(/(shipment|package)/, 'order');
                    result = result.replace('طرد', 'طلب');
                    result = result.replace('طرود', 'طلبات');
                    break;
            }
        }
        return result;
    }
    public transformToDistributor(result) {
        result = result.replace(/(Shipment|Package)/, 'Order');
        result = result.replace(/(shipment|package)/, 'order');
        result = result.replace('طرد جديد', 'طلبية جديدة');
        result = result.replace('طرد', 'طلبية');
        result = result.replace('الطرد', 'الطلبية');
        result = result.replace('طرود', 'طلبيات');
        result = result.replace('الشحنة', 'الطلبية');
        return result;
    }
}
