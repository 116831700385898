import {Component, OnInit} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from "../../accounting-constants";
import {TemplateTopActionsSettingsModel} from "../../models/template-top-actions-settings.model";
import {AccountingTemplateFiltersModel} from "../../models/accounting-template-filters.model";
@Component({
    selector: 'app-cod-pending-customers-approval',
    templateUrl: './cod-pending-customers-approval.component.html',
    styleUrls: ['./cod-pending-customers-approval.component.scss']
})
export class CodPendingCustomersApprovalComponent implements OnInit {

    title = ACCOUNTING_CONSTANTS.COD_PENDING_CUSTOMERS_APPROVAL.TITLE;
    info = ACCOUNTING_CONSTANTS.COD_PENDING_CUSTOMERS_APPROVAL.INFO;
    apiUrl = ACCOUNTING_CONSTANTS.COD_PENDING_CUSTOMERS_APPROVAL.API;
    AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.COD_PENDING_CUSTOMERS_APPROVAL.ROW_ACTIONS_SETTINGS;
    topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.COD_PENDING_CUSTOMERS_APPROVAL.TOP_ACTIONS_SETTINGS
    filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.COD_PENDING_CUSTOMERS_APPROVAL.FILTERS_SETTINGS;
    constructor() {
    }

    ngOnInit() {

    }
}
