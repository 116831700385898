import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ChangeLocationsTypeDialogComponent
} from '../../../../fulfilment/warehouse-stocks-management/components/change-locations-type-dialog/change-locations-type-dialog.component';
import {AddReceiptDialogComponent} from '../add-receipt-dialog/add-receipt-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss']
})
export class WalletCardComponent implements OnInit {

  @Input() wallet;
  @Output() refreshData = new EventEmitter<any>();
  digitFormat = '1.0-2';
  displayDetails: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openTransactions() {
    this.displayDetails = true;
  }
  onCloseTracker($event: any) {
    this.displayDetails = false;
  }

  openAddReceipt(wallet) {
    const modal = this.modalService.open(AddReceiptDialogComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.data = {
      customer: {
        fullName: wallet.customerName,
        id: wallet.customerId
      }
    };
    modal.componentInstance.disableCustomer = true;

    modal.result.then(
        (value) => {
          if (value && value.isSuccess) {
            this.refreshData.emit(value);
          }
        }
    ).catch(
        (error) => {
          console.error(error);
        }
    );
  }
}
