import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {DatePipe} from '@angular/common';
import {SharedService} from '../../../shared/services/shared-service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {filter} from 'rxjs/operators';
import {PackageContentsDialogComponent} from './package-contents/package-contents-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExportPackageContentToComponent} from '../export-package-content-to/export-package-content-to.component';

@Component({
    selector: 'app-distributor-packages-contents-report',
    templateUrl: './distributor-packages-contents-report.component.html',
    styleUrls: ['./distributor-packages-contents-report.component.scss']
})
export class DistributorPackagesContentsReportComponent extends SubscriptionTrackerComponent implements OnInit {
    reportType = '';
    currentLang;
    totalRecordsNo = 0;
    selectedCustomerId: number;
    dateRangPickerOptions: any = {};
    fromDate;
    toDate;
    search;
    reportData = [];
    isLoading = false;
    pageNumber = 0;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    first = 0;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    isAllSelected = false;

    @ViewChild('table') table;
    @ViewChild('customerFilter') customerFilter;
    showExportButtons = false;

    constructor(private route: ActivatedRoute,
                private modalService: NgbModal,
                private datePipe: DatePipe,
                private router: Router,
                private shipmentService: ShipmentsService,
                private sharedService: SharedService,
                private dateRangePickerService: DateRangePickerService,
                private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
        this.setReportType();
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });
        const subscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(e => {
              this.resetFilters();
              this.onFilter();
            });
        this.subscriptions.push(subscription);
    }

    resetFilters() {
        this.selectedCustomerId = null;
        this.customerFilter.selectedUser = null;
        this.fromDate = null;
        this.toDate = null;
        this.search = null;
    }

    setReportType() {
        this.route.queryParams.subscribe(
            (res) => {
                this.reportType = res.reportType;
                this.showExportButtons = this.reportType === 'BY_BARCODE';
            }, error => {
                console.error(error);
            }
        );
    }

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    createParams() {
        let params: any = {page: this.pageNumber + 1, pageSize: this.pageSize};
        if (this.selectedCustomerId) {
            params['customerId'] = this.selectedCustomerId;
        }
        if (this.fromDate && this.toDate) {
            params = {
                ...params,
                fromDate: this.transformDate(this.fromDate),
                toDate: this.transformDate(this.toDate),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };
        }
        if (this.search) {
            params['search'] = this.search;
        }
        return params;
    }

    onFilter() {
        this.pageNumber = 0;
        this.reportData = [];
        this.table.reset();
    }
    initReport() {
        this.isLoading = true;
        this.shipmentService.getPackageContentsReport(this.reportType, this.createParams()).subscribe(
            (res: any) => {
                this.reportData = res.data;
                this.totalRecordsNo = res.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    onSelectCustomer(id) {
        this.selectedCustomerId = (id === undefined ? '' : id);
        this.onFilter();
    }

    onDateSelected(value) {
        this.fromDate = new Date(value.start);
        this.toDate = new Date(value.end);
        this.onFilter();
    }

    loadReportsLazy(event) {
        this.pageNumber = event.first / event.rows;
        this.pageSize = event.rows;
        this.first = event.first;
        this.initReport();
    }

    onToggleSelectAll() {
        if (this.totalRecordsNo === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }
        this.reportData.forEach((item: any) => {
            item.isSelected = selected;
        });
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.DONE_SUCCESSFULLY');
    }

    openContentsDialog(pkgId) {
        if (this.reportType === 'BY_CUSTOMER' || !pkgId) { return; }
        const modal = this.modalService.open(PackageContentsDialogComponent, {
            backdrop: 'static',
            size: <any>'xl'
        });
        modal.componentInstance.packageId = pkgId;
    }
    exportToFile(report: any, $event: MouseEvent, type: string) {
        $event.stopPropagation();
        const modal = this.modalService.open(ExportPackageContentToComponent, {});
        modal.componentInstance.packageId = report.packageId;
        modal.componentInstance.type = type;

    }
}
