import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CompaniesService} from '../services/companies.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ZonesService} from '../../administration/services/zones.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {UserService} from '../../shared/services/user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {AutoAssignPackagesToDriversMethod} from '../../shared/enums/AutoAssignPackagesToDriversMethod';

@Component({
  selector: 'app-company-configuration-new',
  templateUrl: './company-configuration-new.component.html',
  styleUrls: ['./company-configuration-new.component.scss']
})
export class CompanyConfigurationNewComponent implements OnInit {
  categoriesDetail;
  selectedCategory = {name: 'packages'};

  public form;
  public isLoading = false;
  public companyId;
  public currency;
  public companyInfo: any = {};
  public isSuperAdmin = false;
  isAutoAssignDriversToPackages = false;
  isTicketingSystemEnabled = false;
  checkBoxInfo;
  pipe: any;
  changesMap = {};
  numberOfChanges = 0;
  isEnableDeliveryVerificationPinCodeForPkgs = false;
  private autoAssignPackagesToDriversMethod: AutoAssignPackagesToDriversMethod;

  constructor(@Inject(FormBuilder) private formBuilder: FormBuilder,
              private companiesService: CompaniesService,
              private activeModal: NgbActiveModal,
              public translateService: TranslateService,
              public zonesService: ZonesService,
              private route: ActivatedRoute,
              private location: Location,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private messageService: MessageService,
              private changeDetectorRef: ChangeDetectorRef,
              private confirmationService: ConfirmationService) {
    this.isAutoAssignDriversToPackages = this.userService.userInfo.isAutoAssignDriversToPkgs;
    this.autoAssignPackagesToDriversMethod = this.userService.userInfo.autoAssignPackagesToDriversMethod;
    this.isTicketingSystemEnabled = this.userService.userInfo.isTicketingSystemEnabled;
    this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
    this.isEnableDeliveryVerificationPinCodeForPkgs = this.userService.userInfo.isEnableDeliveryVerificationPinCodeForPkgs;
    this.companyId = this.userService.userInfo.companyId;
    this.currency =  this.userService.userInfo.currency;
  }

  public ngOnInit() {
    this.initCategories();
    this.initCheckbox();
    this.initForm();
  }
  public initForm() {
    const company = this.formBuilder.group({
      isGuestAddPackage: [false],
      isUseBankTransfer: [false],
      isShowPhoneInPackageReport: [false],
      isUseStickerPrinterForAwb: [false],
      isDriverPickupUnassignedPackage: [false],
      isPreventDriversScanningPostponedPackages: [false],
      isAllowDriversToViewAttachments: [false],
      isAdminOnlyChangeFromDelivered: [false],
      isShowDriverNameInPackageTracking: [false],
      isChangeReceivedOrSortedPackageStatus: [false],
      isPrintCashReceiptWithMassReport: [false],
      isHideCostFromRecieveCodReport: [false],
      isPrintProofReportWithDriverRunsheet: [false],
      isHideDeliveredMassCodReports: [false],
      isForceAllCustomersToAddAttachment: [false],
      isShowCodSumValuesInCustomerDetailedReport: [false],
      isAllowAddingAttachment: [false],
      isHidePartnerSenderParent: [false],
      isHidePartnerSender: [false],
      isHidePartnerSenderAddress: [false],
      isNonAdminChangeCost: [false],
      isExcludeSort: [false],
      isShowBarcodeImageInMassReport: [false],
      isEnableBringPrice: [false],
      isAllowOperationManagerAddCustomer: [false],
      isAddCustomerFromQuickAddShipmentForm: [false],
      isAllowPartnerChangeCod: [false],
      isSeperateReadyCodToDeliverandCodAtHand: [false],
      isPrintMassCodReportOnceByAccountant: [false],
      isAdminChangeCodForReturnedAndDelivered: [false],
      isChangeExportedPackages : [false],
      isDeliverPartialMassReport: [false],
      isAllowOperationManagerReceiveCod: [false],
      isAllowDispatcherReceiveCod: [false],
      isPrintTwoCopiesForSwap: [false],
      isSupportAddingDraftPackagesByCustomers: [false],
      isAllCustomersAddDraftPackages: [false],
      isPreventCustomersToAddPickupPackagesWhenNoCash: [false],
      isSupportReceiveWithoutReleasingCustody: [false],
      isPartialDeliveryEnabled: [false],
      isHideDeliveredToSenderInChangeStatus: [false],
      isDriverCanRequestCodChange: [false],
      isDriverCanReturnPackage: [false],
      isBundlePodEnabled: [false],
      isDriverEarningEnabled: [false],
      isFulfilmentEnabled: [false],
      isPickupUnListedPackagesByAdmin: [false],
      isPackageFingerprintRequired: [false],
      isShowAddShipmentInSideMenu: [false],
      isCorporate: [false],
      isTrucking: [false],
      isDistributor: [false],
      isShowDriverLocationInPackageTracking: [false],
      isShowDriverPhoneInCustomerPackageTracking: [false],
      isDriverCanFailPackageDisabled: [false],
      isSignatureOnPackageDeliveryDisabled: [false],
      isDriverPickupPackagesByScanDisabled: [false],
      isLcl: [false],
      isShowMassCodReports: [false],
      isDisableCostInAddPackage: [false],
      hasRouteOptimization: [false],
      isDriverPickupAcceptedPackages: [false],
      isShowDelayedCountInCustomerDashboard: [false],
      isPromptNoteForDriverInPackageDelivery: [false],
      isForceAddingInvoiceNumberWhenAddingPackages: [false],
      isReceivingPkgsWithoutScanItems: [false],
      isAutoAssignDriversToPkgs: [false],
      isAutoAssignToOnlineDriversOnly: [false],
      isEnableAutoAssignForAllCustomers : [false],
      isAutoAssignToShippingLineDriver : [false],
      isHidePackagesInDriversApps : [false],
      isShowCustomerFieldWhileAddingTicket : [false],
      isShowPaymentTypesWhenDriverDeliver : [false],
      isAutoApproveCustomersAfterSignup : [false],
      isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD : [false],
      isEnableCustomersNotifyAdminsToDeliverCod : [false],
      isShowDetailedPackageHistoryForALLCustomers : [false],
      isShowCustomersToHubManager : [false],
      isEnableDeliveryVerificationPinCodeForPkgsWithCodGreaterThan: [],
      deliveryVerificationPinCodeExpirationInMinutesForPkgs: [1, []],
      isForceDriversAttachDraftPackage: [],
      isEnableCustomerConfirmationForReceivingMassCodPkg: [false],
      isDriverProveDeliveryByScanBarcode: [false],
      isAllowCustomersToEditPendingPackagesOnly: [false],
      isAllCustomersAddBundles: [false],
      isShareRecipientForAllCompanyCustomers: [false],
      isAllowDriverChangePackageType: [false],
      isAllowDriversOutOfServiceAnytime: [false],
      isHideBarcodeImageInCodMismatchReport: [false],
      isPasswordConfirmationRequiredToUpdateTheShippingRate: [false],
      isForceDriversToSelectReturnReason: [false],
      isEnableGenerateBarcodeImageForInvoiceNumber: [false],
      isAllowAdminToChangeStatusOfExportedToHubPkgs: [false],
      isAdminOnlyDeletePackages: [false],
      isDriverSignupEnabled: [false],
      isForceChangingCodAndCostBeforeReceivingCod: [false],
      isShowCityPrefixInAwb: [false],
      isShowQrCodeInReports: [false],
      isShowCityColor: [false],
      isDescriptionMandatoryInAddPackageForm: [false],
      isPrintAwbCopiesAsPackageQuantity: [false],
      isHideDetailInAddingPackage: [false],
      isAdminOnlyChangeToPartiallyDelivered: [false],
      maxAttemptsOfAutoAssigningPkgToDrivers: [1, []],
      waitingTimeForDriversToAcceptAutoAssignedPkgs: [1, []],
      isAllowClerkChangeStatus: [false],
      isShowCodCollectionMethodWhenReceivingCod: [false],
      isEnablePricingPerkg: [false],
      isAddSerialInvoiceNumberForNewPackages: [false],
      isEnableDeliveryVerificationPinCodeForReturnedBundles: [false],
    });
    if (this.isEnableDeliveryVerificationPinCodeForPkgs) {
      company.get('deliveryVerificationPinCodeExpirationInMinutesForPkgs').setValidators([Validators.min(2), Validators.required]);
      company.get('deliveryVerificationPinCodeExpirationInMinutesForPkgs').updateValueAndValidity();
    }

    if (this.isAutoAssignDriversToPackages && this.autoAssignPackagesToDriversMethod != AutoAssignPackagesToDriversMethod.SINGLE_SHIPPING_LINE_DRIVER) {
      this.addValidatorsToController(company.get('maxAttemptsOfAutoAssigningPkgToDrivers'), [Validators.min(1), Validators.max(10), Validators.required]);
      this.addValidatorsToController(company.get('waitingTimeForDriversToAcceptAutoAssignedPkgs'), [Validators.min(1), Validators.max(30), Validators.required]);

    }

    this.form = this.formBuilder.group({
      company
    });
    this.getCompany();
    this.form = this.formBuilder.group({ company });
    this.form.controls.forEach(x => x.markAsUntouched());

  }

  public submit(event) {
    const { company} = this.form.getRawValue();
    this.isLoading = true;

    this.companiesService.submitCompanyConfiguration({ ...company }).subscribe((data) => {
      this.userService.getUserInfo(true).then(value => {
        this.isLoading = false;
        this.companyInfo = company;
        this.changesMap = {};
        this.numberOfChanges = 0;
        this.messageService.add({severity: 'success', detail: this.translateService.instant('USER_INFORMATION.EDITED_SUCCESSFULLY')});
      });
    }, (error) => {
      let errorMessage = '';
      this.translateService.get(
          ['ALERTS.FAILED_TO_ADD_COMPANY']
      ).subscribe(
          (data) => {
            errorMessage = data['ALERTS.FAILED_TO_ADD_COMPANY'];
            this.confirmationService.confirm({
              message: errorMessage + ': ' + error.error.error,
              rejectVisible: false,
              acceptLabel: 'OK'
            });
            this.isLoading = false;
          }
      );
    });
  }

  public getCompany() {
    this.isLoading = true;
    return this.companiesService.getCompany().subscribe((company: any) => {
      this.fillFormData(company);
      this.companyInfo = company;
    }, () => {
    }, () => {
      this.isLoading = false;
    });
  }

  public canSubmit() {
    return  (this.userService.userInfo.role === 'SUPER_ADMIN' || this.userService.userInfo.role === 'ADMIN');
  }

  setSelectedCategory(category) {
    this.selectedCategory = category;
    this.changeDetectorRef.detectChanges();
  }

  private initCheckbox() {
    this.checkBoxInfo = [
      {
        controller : 'isGuestAddPackage',
        label: 'COMPANY.ENABLE_GUEST_ADD_PACKAGE',
        hidden: false,
        category: 'packages',
      },
      {
        controller : 'isShowPhoneInPackageReport',
        label: 'COMPANY.SHOW_PHONE_IN_PACKAGE_REPORT',
        subLabel: 'COMPANY_CONFIGURATION.SHOW_PHONE_IN_PACKAGE_REPORT_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isUseStickerPrinterForAwb',
        label: 'COMPANY.IS_USE_STICKER_PRINTER_FOR_AWB',
        subLabel: 'COMPANY_CONFIGURATION.IS_USE_STICKER_PRINTER_FOR_AWB_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isDriverPickupUnassignedPackage',
        label: 'COMPANY.DRIVER_PICK_UNASSIGNED_PACKAGE',
        subLabel: 'COMPANY_CONFIGURATION.DRIVER_PICK_UNASSIGNED_PACKAGE_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isPreventDriversScanningPostponedPackages',
        label: 'COMPANY.PREVENT_DRIVERS_SCANNING_POSTPONED_PACKAGES',
        subLabel: 'COMPANY_CONFIGURATION.PREVENT_DRIVERS_SCANNING_POSTPONED_PACKAGES_DETAILS',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isAllowDriversToViewAttachments',
        label: 'COMPANY.ALLOW_DRIVER_TO_ACCESS_PACKAGE_ATTACHMENTS',
        subLabel: 'COMPANY_CONFIGURATION.ALLOW_DRIVER_TO_ACCESS_PACKAGE_ATTACHMENTS_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isShowDriverPhoneInCustomerPackageTracking',
        label: 'COMPANY.SHOW_DRIVER_PHONE_IN_CUSTOMER_PACKAGE_TRACKING',
        subLabel: 'COMPANY_CONFIGURATION.SHOW_DRIVER_PHONE_IN_CUSTOMER_PACKAGE_TRACKING_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isDriverCanFailPackageDisabled',
        label: 'USERS_MANAGEMENT.ADD_USER_FORM.IS_DRIVER_CAN_FAIL_PACKAGE_DISABLED',
        // subLabel: '',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isSignatureOnPackageDeliveryDisabled',
        label: 'USERS_MANAGEMENT.ADD_USER_FORM.IS_SIGNATURE_ON_PACKAGE_DELIVERY_DISABLED',
        // subLabel: '',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isDriverPickupPackagesByScanDisabled',
        label: 'USERS_MANAGEMENT.ADD_USER_FORM.IS_DRIVER_PICKUP_PACKAGES_BY_SCAN_DISABLED',
        // subLabel: '',
        hidden: false,
        category: 'driver'
      },
      // {
      //   controller : 'isHubManagerAddPackage',
      //   label: 'COMPANY.HUB_MANAGER_ADD_PACKAGE',
      //   hidden: false,
      //   category: 'packages'
      // },
      {
        controller : 'isNonAdminChangeCost',
        label: 'COMPANY.ALLOW_NON_ADMIN_CHANGE_COST',
        subLabel: 'COMPANY_CONFIGURATION.ALLOW_NON_ADMIN_CHANGE_COST_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isAdminOnlyChangeFromDelivered',
        label: 'COMPANY.ADMIN_ONLY_CHANGE_FROM_DELIVERED',
        subLabel: 'COMPANY_CONFIGURATION.ADMIN_ONLY_CHANGE_FROM_DELIVERED_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isChangeReceivedOrSortedPackageStatus',
        label: 'COMPANY.CHANGE_RECEIVED_SORTED_PACKAGE_STATUS',
        subLabel: 'COMPANY_CONFIGURATION.CHANGE_RECEIVED_SORTED_PACKAGE_STATUS_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isAllowPartnerChangeCod',
        label: 'COMPANY.ALLOW_PARTNER_CHANGE_COD',
        subLabel: 'COMPANY_CONFIGURATION.ALLOW_PARTNER_CHANGE_COD_DETAIL',
        hidden: false,
        category: 'partner'
      },
      {
        controller: 'isHidePartnerSenderParent',
        label: 'COMPANY.HIDE_PARTNER_SENDER',
        subLabel: 'COMPANY_CONFIGURATION.HIDE_PARTNER_SENDER_DETAIL',
        hidden: false,
        category: 'partner',
        isParent: true,
        onChange: ($event) => {
          const fg = this.form.controls.company;
          fg.get('isHidePartnerSender').patchValue($event.checked);
          fg.get('isHidePartnerSenderAddress').patchValue($event.checked);
        },
      },
      {
        controller: 'isHidePartnerSender',
        label: 'COMPANY.HIDE_PARTNER_SENDER_NAME',
        hidden: false,
        category: 'partner',
        onChange: ($event) => {
          const fg = this.form.controls.company;
          fg.get('isHidePartnerSenderParent').patchValue(fg.get('isHidePartnerSender').value || fg.get('isHidePartnerSenderAddress').value);
        },
        customVisibility: () => {
          if (this.form.controls.company.get('isHidePartnerSenderParent')) {
            return !this.form.controls.company.get('isHidePartnerSenderParent').value;
          } else {
            return true;
          }
        }
      },
      {
        controller: 'isHidePartnerSenderAddress',
        label: 'COMPANY.HIDE_PARTNER_SENDER_ADDRESS',
        hidden: false,
        category: 'partner',
        onChange: ($event) => {
          const fg = this.form.controls.company;
          fg.get('isHidePartnerSenderParent').patchValue(fg.get('isHidePartnerSender').value || fg.get('isHidePartnerSenderAddress').value);
        },
        customVisibility: () => {
          if (this.form.controls.company.get('isHidePartnerSenderParent')) {
            return !this.form.controls.company.get('isHidePartnerSenderParent').value;
          } else {
            return true;
          }
        }
      },
      {
        controller : 'isAllCustomersAddDraftPackages',
        label: 'COMPANY.IS_ALLOW_ALL_CUSTOMERS_TO_ADD_DRAFT_PACKAGES',
        subLabel: 'COMPANY_CONFIGURATION.IS_ALLOW_ALL_CUSTOMERS_TO_ADD_DRAFT_PACKAGES_DETAIL',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isSeperateReadyCodToDeliverandCodAtHand',
        label: 'COMPANY.IS_SEPARATE_READY_COD_TO_DELIVER_AND_COD_AT_HAND',
        subLabel: 'COMPANY_CONFIGURATION.IS_SEPARATE_READY_COD_TO_DELIVER_AND_COD_AT_HAND_DETAIL',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isPreventCustomersToAddPickupPackagesWhenNoCash',
        label: 'COMPANY.IS_PREVENT_CUSTOMERS_TO_ADD_PICKUP_PACKAGES_WHEN_NO_CASH',
        subLabel: 'COMPANY_CONFIGURATION.IS_PREVENT_CUSTOMERS_TO_ADD_PICKUP_PACKAGES_WHEN_NO_CASH_DETAIL',
        hidden: !this.userService.userInfo.isEnableCustomerWallet,
        category: 'customer'
      },{
        controller : 'isChangeExportedPackages',
        label: 'COMPANY.IS_CHANGE_EXPORTED_PACKAGES',
        subLabel: 'COMPANY_CONFIGURATION.IS_CHANGE_EXPORTED_PACKAGES_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isAllowOperationManagerReceiveCod',
        label: 'COMPANY.IS_ALLOW_OPERATION_MANAGER_TO_RECEIVE_COD',
        subLabel: 'COMPANY_CONFIGURATION.IS_ALLOW_OPERATION_MANAGER_TO_RECEIVE_COD_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isAllowDispatcherReceiveCod',
        label: 'COMPANY.IS_ALLOW_DISPATCHER_TO_RECEIVE_COD',
        subLabel: 'COMPANY_CONFIGURATION.IS_ALLOW_DISPATCHER_TO_RECEIVE_COD_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isSupportReceiveWithoutReleasingCustody',
        label: 'COMPANY.IS_SUPPORT_RECEIVE_WITHOUT_RELEASING_CUSTODY',
        hidden: !this.isSuperAdmin,
        category: 'packages'
      },      {
        controller : 'isPartialDeliveryEnabled',
        label: 'COMPANY.IS_PARTIAL_DELIVERY_ENABLED',
        subLabel: 'COMPANY_CONFIGURATION.IS_PARTIAL_DELIVERY_ENABLED_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isHideDeliveredToSenderInChangeStatus',
        label: 'COMPANY.IS_HIDE_DELIVERED_TO_SENDER_IN_CHANGE_STATUS',
        subLabel: 'COMPANY_CONFIGURATION.IS_HIDE_DELIVERED_TO_SENDER_IN_CHANGE_STATUS_DETAIL',
        hidden: false,
        category: 'packages'
      },
      // {
      //   controller : 'isPickupSupported',
      //   label: 'isPickupSupported',
      //   hidden: false,
      //   category: 'packages'
      // },
      {
        controller : 'isAdminChangeCodForReturnedAndDelivered',
        label: 'COMPANY.IS_ADMIN_CHANGE_COD_FOR_RETURNED_AND_DELIVERED',
        subLabel: 'COMPANY_CONFIGURATION.IS_ADMIN_CHANGE_COD_FOR_RETURNED_AND_DELIVERED_DETAIL',
        hidden: false,
        category: 'attachments'
      },      {
        controller : 'isShowAddShipmentInSideMenu',
        label: 'COMPANY.SHOW_ADD_SHIPMENT_IN_SIDE_MENU',
        subLabel: 'COMPANY_CONFIGURATION.SHOW_ADD_SHIPMENT_IN_SIDE_MENU_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isBundlePodEnabled',
        label: 'COMPANY.Is_BUNDLE_POD_ENABLED',
        hidden: !this.isSuperAdmin,
        category: 'packages'
      },
      {
        controller : 'isFulfilmentEnabled',
        label: 'COMPANY.IS_FULFILMENT_ENABLED',
        hidden: !this.isSuperAdmin,
        category: 'packages'
      },
      {
        controller : 'isDisableCostInAddPackage',
        label: 'COMPANY.IS_DISABLE_COST_IN_ADD_PACKAGE',
        subLabel: 'COMPANY_CONFIGURATION.IS_DISABLE_COST_IN_ADD_PACKAGE_DETAIL',
        hidden: false,
        category: 'packages'
      },      {
        controller : 'isPickupUnListedPackagesByAdmin',
        label: 'COMPANY.IS_PICKUP_UNLISTED_PACKAGES',
        hidden: !this.isSuperAdmin,
        category: 'packages'
      },
      {
        controller : 'isPackageFingerprintRequired',
        label: 'COMPANY.IS_PACKAGE_FINGERPRINT_REQUIRED',
        hidden: !this.isSuperAdmin,
        category: 'packages'
      },
      {
        controller : 'isDriverPickupAcceptedPackages',
        label: 'COMPANY.IS_DRIVER_PICKUP_ACCEPTED_PACKAGES',
        subLabel: 'COMPANY_CONFIGURATION.IS_DRIVER_PICKUP_ACCEPTED_PACKAGES_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isForceAddingInvoiceNumberWhenAddingPackages',
        label: 'COMPANY.IS_FORCE_ADDING_INVOICE_NUMBER_WHEN_ADDING_PACKAGES',
        subLabel: 'COMPANY_CONFIGURATION.IS_FORCE_ADDING_INVOICE_NUMBER_WHEN_ADDING_PACKAGES_DETAIL',
        hidden: false,
        category: 'packages'
      },      {
        controller : 'isReceivingPkgsWithoutScanItems',
        label: 'COMPANY.IS_RECEIVING_PACKAGES_WITHOUT_SCAN_ITEMS',
        subLabel: 'COMPANY_CONFIGURATION.IS_RECEIVING_PACKAGES_WITHOUT_SCAN_ITEMS_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isUseBankTransfer',
        label: 'COMPANY.USE_BANK_TRANSFER',
        subLabel: 'COMPANY_CONFIGURATION.USE_BANK_TRANSFER_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isAllowOperationManagerAddCustomer',
        label: 'COMPANY.ALLOW_OPERATION_MANAGER_ADD_CUSTOMER',
        subLabel: 'COMPANY_CONFIGURATION.ALLOW_OPERATION_MANAGER_ADD_CUSTOMER_DETAIL',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isAddCustomerFromQuickAddShipmentForm',
        label: 'COMPANY.ADD_CUSTOMER_FROM_QUICK_ADD_SHIPMENT_FORM',
        subLabel: 'COMPANY_CONFIGURATION.ADD_CUSTOMER_FROM_QUICK_ADD_SHIPMENT_FORM_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isSupportAddingDraftPackagesByCustomers',
        label: 'COMPANY.IS_SUPPORT_ADDING_DRAFT_PACKAGES',
        subLabel: 'COMPANY_CONFIGURATION.IS_SUPPORT_ADDING_DRAFT_PACKAGES_DETAIL',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isShowDelayedCountInCustomerDashboard',
        label: 'COMPANY.IS_SHOW_DELAYED_COUNT_IN_CUSTOMER_DASHBOARD',
        subLabel: 'COMPANY_CONFIGURATION.IS_SHOW_DELAYED_COUNT_IN_CUSTOMER_DASHBOARD_DETAIL',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isEnableAutoAssignForAllCustomers',
        label: 'COMPANY.IS_ENABLE_AUTO_ASSIGN_FOR_ALL_CUSTOMERS',
        hidden: !(this.isSuperAdmin || this.isAutoAssignDriversToPackages),
        category: 'customer'
      },
      {
        controller : 'isAutoAssignToShippingLineDriver',
        label: 'COMPANY.IS_AUTO_ASSIGN_TO_SHIPPING_LINE_DRIVER',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isHidePackagesInDriversApps',
        label: 'COMPANY.IS_HIDE_PACKAGES_IN_DRIVER_APPS',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isShowCustomerFieldWhileAddingTicket',
        label: 'COMPANY.IS_SHOW_CUSTOMER_FIELD_IN_ADD_NEW_TICKET',
        hidden: !(this.isSuperAdmin || this.isTicketingSystemEnabled),
        category: 'customerSupport'
      },
      {
        controller : 'isAutoApproveCustomersAfterSignup',
        label: 'COMPANY.IS_AUTO_APPROVE_CUSTOMERS_AFTER_SIGNUP',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD',
        label: 'COMPANY.IS_NOTIFY_TO_CC_WHEN_FAILED_DELIVERY_PKG_AND_CHANGE_COD',
        subLabel: 'COMPANY_CONFIGURATION.CUSTOMER_CARE_AND_HUB_CUSTOMER_CARE_WILL_RECEIVE_NOTIFICATIONS_FOR_ANY_FAILED_PACKAGE_AND_COD_CHANGE',
        hidden: (!this.userService.userInfo.isEnableExportingBetweenHubs),
        category: 'customer'
      },
      {
        controller : 'isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD',
        label: 'COMPANY.IS_NOTIFY_TO_CC_WHEN_FAILED_DELIVERY_PKG_AND_CHANGE_COD',
        subLabel: 'COMPANY_CONFIGURATION.CUSTOMER_CARE_WILL_RECEIVE_NOTIFICATIONS_FOR_ANY_FAILED_PACKAGE_AND_COD_CHANGE',
        hidden: (this.userService.userInfo.isEnableExportingBetweenHubs),
        category: 'customer'
      },
      {
        controller : 'isShowDetailedPackageHistoryForALLCustomers',
        label: 'COMPANY.IS_SHOW_DETAILED_PACKAGE_HISTORY_FOR_ALL_CUSTOMERS',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isShowCustomersToHubManager',
        label: 'COMPANY.IS_SHOW_CUSTOMERS_TO_HUB_MANAGER',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isShowDriverNameInPackageTracking',
        label: 'COMPANY.SHOW_DRIVER_NAME_IN_PACKAGE_TRACKING',
        subLabel: 'COMPANY_CONFIGURATION.SHOW_DRIVER_NAME_IN_PACKAGE_TRACKING_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isDriverCanRequestCodChange',
        label: 'COMPANY.IS_DRIVER_CAN_REQUEST_COD_CHANGE',
        subLabel: 'COMPANY_CONFIGURATION.IS_DRIVER_CAN_REQUEST_COD_CHANGE_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isDriverCanReturnPackage',
        label: 'COMPANY.IS_DRIVER_CAN_REQUEST_RETURN_PACKAGE',
        subLabel: 'COMPANY_CONFIGURATION.IS_DRIVER_CAN_REQUEST_RETURN_PACKAGE_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isDriverEarningEnabled',
        label: 'COMPANY.IS_DRIVER_EARNING_ENABLED',
        hidden: !this.isSuperAdmin,
        category: 'driver'
      },
      {
        controller : 'isShowDriverLocationInPackageTracking',
        label: 'COMPANY.IS_SHOW_DRIVER_LOCATION_IN_PACKAGE_TRACKING',
        hidden: !this.isSuperAdmin,
        category: 'driver'
      },
      {
        controller : 'hasRouteOptimization',
        label: 'COMPANY.HAS_ROUTE_OPTIMIZATION',
        hidden: !this.isSuperAdmin,
        category: 'driver'
      },
      {
        controller : 'isPromptNoteForDriverInPackageDelivery',
        label: 'COMPANY.IS_PROMPT_NOTE_FOR_DRIVER_IN_PACKAGE_DELIVERY',
        subLabel: 'COMPANY_CONFIGURATION.IS_PROMPT_NOTE_FOR_DRIVER_IN_PACKAGE_DELIVERY_DETAIL',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isAutoAssignDriversToPkgs',
        label: 'COMPANY.IS_AUTO_ASSIGN_DRIVERS_TO_PACKAGES',
        hidden: !this.isSuperAdmin,
        category: 'driver'
      },
      {
        controller : 'isAutoAssignToOnlineDriversOnly',
        label: 'COMPANY.IS_AUTO_ASSIGN_TO_ONLINE_DRIVERS_ONLY',
        hidden: !(this.isSuperAdmin || this.isAutoAssignDriversToPackages),
        category: 'driver'
      },
      {
        controller : 'isShowPaymentTypesWhenDriverDeliver',
        label: 'COMPANY.IS_SHOW_PAYMENT_TYPES_WHEN_DRIVER_DELIVER',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isPrintCashReceiptWithMassReport',
        label: 'COMPANY.PRINT_CASH_RECEIPT_WITH_MASS_REPORT',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isHideCostFromRecieveCodReport',
        label: 'COMPANY.IS_HIDE_COST_FROM_RECIEVE_COD_REPORT',
        subLabel: 'COMPANY_CONFIGURATION.IS_HIDE_COST_FROM_RECIEVE_COD_REPORT_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isPrintProofReportWithDriverRunsheet',
        label: 'COMPANY.PRINT_DRIVER_PROOF_WITH_RUNSHEET',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isHideDeliveredMassCodReports',
        label: 'isHideDeliveredMassCodReports',
        hidden: true,
        category: 'attachments'
      },
      {
        controller : 'isForceAllCustomersToAddAttachment',
        label: 'COMPANY.IS_FORCE_ALL_CUSTOMERS_TO_ADD_ATTACHMENT',
        subLabel: 'COMPANY_CONFIGURATION.IS_FORCE_ALL_CUSTOMERS_TO_ADD_ATTACHMENT_DETAIL',
        hidden: this.isSuperAdmin,
        category: 'customer'
      },
      {
        controller : 'isShowCodSumValuesInCustomerDetailedReport',
        label: 'COMPANY.SHOW_COD_SUM_VALUES_IN_CUSTOMER_DETAILED_REPORT',
        subLabel: 'COMPANY_CONFIGURATION.SHOW_COD_SUM_VALUES_IN_CUSTOMER_DETAILED_REPORT_DETAIL',
        hidden: this.isSuperAdmin,
        category: 'customer'
      },
      {
        controller : 'isAllowAddingAttachment',
        label: 'COMPANY.IS_ALLOW_ADDING_ATTACHMENT',
        subLabel: 'COMPANY_CONFIGURATION.IS_ALLOW_ADDING_ATTACHMENT_DETAIL',
        hidden: this.isSuperAdmin,
        category: 'customer'
      },
      {
        controller : 'isShowBarcodeImageInMassReport',
        label: 'COMPANY.SHOW_BARCODE_IMAGE_IN_MASS_REPORT',
        subLabel: 'COMPANY_CONFIGURATION.SHOW_BARCODE_IMAGE_IN_MASS_REPORT_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isEnableBringPrice',
        label: 'COMPANY.IS_ALLOWED_BRING_PRICE',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isExcludeSort',
        label: 'COMPANY.EXECLUDE_SORT',
        subLabel: 'COMPANY_CONFIGURATION.EXECLUDE_SORT_DETAIL',
        hidden: true,
        category: 'attachments'
      },
      {
        controller : 'isDeliverPartialMassReport',
        label: 'COMPANY.IS_DELIVER_PARTIAL_MASS_REPORT',
        subLabel: 'COMPANY_CONFIGURATION.IS_DELIVER_PARTIAL_MASS_REPORT_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isPrintTwoCopiesForSwap',
        label: 'COMPANY.IS_PRINT_TWO_COPIES_FOR_SWAP',
        subLabel: 'COMPANY_CONFIGURATION.IS_PRINT_TWO_COPIES_FOR_SWAP_DETAIL',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isPrintMassCodReportOnceByAccountant',
        label: 'COMPANY.IS_PRINT_MASS_COD_REPORT_ONCE_BY_ACCOUNTANT',
        subLabel: 'COMPANY_CONFIGURATION.IS_PRINT_MASS_COD_REPORT_ONCE_BY_ACCOUNTANT_DETAIL',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isShowMassCodReports',
        label: 'COMPANY.IS_SHOW_MASS_COD_REPORTS',
        subLabel: 'COMPANY_CONFIGURATION.IS_SHOW_MASS_COD_REPORTS_DETAIL',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isCorporate',
        label: 'COMPANY.IS_CORPORATE',
        hidden: !this.isSuperAdmin,
        category: 'general'
      },
      {
        controller : 'isTrucking',
        label: 'COMPANY.IS_TRUCKING',
        hidden: !this.isSuperAdmin,
        category: 'general'
      },
      {
        controller : 'isDistributor',
        label: 'COMPANY.IS_DISTRIBUTOR',
        hidden: !this.isSuperAdmin,
        category: 'general'
      },
      {
        controller : 'isLcl',
        label: 'COMPANY.IS_LCL',
        hidden: !this.isSuperAdmin,
        category: 'general'
      },
      {
        controller : 'isEnableDeliveryVerificationPinCodeForPkgsWithCodGreaterThan',
        label: 'COMPANY.IS_ENABLE_DELIVERY_VERIFICATION_PIN_CODE_FOR_PKGS_WITH_COD_Greater_THAN',
        hidden: !(this.isEnableDeliveryVerificationPinCodeForPkgs),
        category: 'driver',
        type: 'number'
      },
      {
        controller : 'deliveryVerificationPinCodeExpirationInMinutesForPkgs',
        label: 'COMPANY.DELIVERY_VERIFICATION_PIN_CODE_EXPIRATION_IN_MINUTES_FOR_PKGS',
        hidden: () => {
          return !(this.isEnableDeliveryVerificationPinCodeForPkgs) && !this.form.value.company.isEnableDeliveryVerificationPinCodeForReturnedBundles;
        },
        category: 'driver',
        type: 'number',
        min: '2'
      },
      {
        controller : 'maxAttemptsOfAutoAssigningPkgToDrivers',
        label: 'COMPANY.MAX_ATTEMPTS_OF_AUTO_ASSIGNING_PKG_TO_DRIVERS',
        hidden: !(this.isAutoAssignDriversToPackages && this.autoAssignPackagesToDriversMethod != AutoAssignPackagesToDriversMethod.SINGLE_SHIPPING_LINE_DRIVER),
        category: 'driver',
        type: 'number',
        min: '1',
        max: '10'
      },
      {
        controller : 'waitingTimeForDriversToAcceptAutoAssignedPkgs',
        label: 'COMPANY.WAITING_TIME_FOR_DRIVERS_TO_ACCEPT_AUTO_ASSIGNED_PKGS',
        hidden: !(this.isAutoAssignDriversToPackages && this.autoAssignPackagesToDriversMethod != AutoAssignPackagesToDriversMethod.SINGLE_SHIPPING_LINE_DRIVER),
        category: 'driver',
        type: 'number',
        min: '1',
        max: '30'
      },
      {
        controller : 'isForceDriversAttachDraftPackage',
        label: 'COMPANY.IS_FORCE_DRIVERS_ATTACH_DRAFT_PACKAGE',
        subLabel: 'COMPANY.IS_FORCE_DRIVERS_ATTACH_DRAFT_PACKAGE',
        hidden: !this.isEpsDraftPackagesEnable(),
        category: 'driver'
      },
      {
        controller : 'isDriverSignupEnabled',
        label: 'COMPANY.IS_DRIVER_SIGNUP_ENABLED',
        hidden: false,
        category: 'driver'
      },
      {
        controller: 'isEnableCustomersNotifyAdminsToDeliverCod',
        label: 'COMPANY.IS_ENABLE_CUSTOMER_NOTIFY_ADMINS_TO_DELIVER_COD',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isEnableCustomerConfirmationForReceivingMassCodPkg',
        label: 'COMPANY.IS_ENABLE_CUSTOMER_CONFIRMATION_FOR_RECEIVING_MASS_COD_PKG',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isAllowCustomersToEditPendingPackagesOnly',
        label: 'COMPANY.IS_ALLOW_CUSTOMERS_TO_EDIT_PENDING_PACKAGES_ONLY',
        hidden: false,
        category: 'customer'
      },
      {
        controller : 'isAllCustomersAddBundles',
        label: 'COMPANY.IS_ALL_CUSTOMERS_ADD_BUNDLES',
        hidden: !this.userService.userInfo.isBundleSupported,
        category: 'customer'
      },
      {
        controller : 'isShareRecipientForAllCompanyCustomers',
        label: 'COMPANY.IS_SHARE_RECIPIENT_FOR_ALL_COMPANY_CUSTOMERS',
        hidden: !this.userService.userInfo.isBlackListEnabled,
        category: 'customer'
      },
      {
        controller : 'isEnableGenerateBarcodeImageForInvoiceNumber',
        label: 'COMPANY.IS_ENABLE_GENERATE_BARCODE_IMAGE_INVOICE_NUMBER',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isForceChangingCodAndCostBeforeReceivingCod',
        label: 'COMPANY.IS_FORCE_CHANGING_COD_AND_COST_BEFORE_RECEIVING_COD',
        hidden: !(this.companyId == 4 || this.companyId ==1),
        category: 'packages'
      },
      {
        controller : 'isAllowAdminToChangeStatusOfExportedToHubPkgs',
        label: 'COMPANY.IS_ALLOW_ADMIN_TO_CHANGES_STATUS_OF_EXPORTED_TO_HUB_PACKAGES',
        hidden: !this.userService.userInfo.isEnableExportingBetweenHubs,
        category: 'packages'
      },
      {
        controller : 'isAdminOnlyDeletePackages',
        label: 'COMPANY.IS_ADMIN_ONLY_DELETE_PACKAGES',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isHideBarcodeImageInCodMismatchReport',
        label: 'COMPANY.IS_HIDE_BARCODE_IMAGE_IN_COD_MISMATCH_REPORT',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isPasswordConfirmationRequiredToUpdateTheShippingRate',
        label: 'COMPANY.IS_PASSWORD_CONFIRMATION_REQUIRED_TO_UPDATE_THE_SHIPPING_RATE',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isForceDriversToSelectReturnReason',
        label: 'COMPANY.IS_FORCE_DRIVERS_TO_SELECT_RETURN_REASON',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isAllowDriverChangePackageType',
        label: 'COMPANY.IS_ALLOW_DRIVER_CHANGE_PACKAGE_TYPE',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isAllowDriversOutOfServiceAnytime',
        label: 'COMPANY.IS_ALLOW_DRIVERS_OUT_OF_SERVICE_ANYTIME',
        hidden: false,
        category: 'driver'
      },
      {
        controller : 'isShowCityPrefixInAwb',
        label: 'COMPANY.IS_SHOW_CITY_PREFIX_IN_AWB',
        hidden: false,
        category: 'packages'
      },
      {
        controller: 'isShowQrCodeInReports',
        label: 'COMPANY.IS_ADD_QR_CODE_TO_AWB',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isShowCityColor',
        label: 'COMPANY.IS_SHOW_CITY_COLOR',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isDescriptionMandatoryInAddPackageForm',
        label: 'COMPANY.IS_DESCRIPTION_MANDATORY_IN_ADD_PACKAGE_FORM',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isPrintAwbCopiesAsPackageQuantity',
        label: 'COMPANY.IS_PRINT_AWB_COPIES_AS_PACKAGE_QUANTITY',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isHideDetailInAddingPackage',
        label: 'COMPANY.IS_HIDE_DETAIL_IN_ADDING_PACKAGE',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isAdminOnlyChangeToPartiallyDelivered',
        label: 'COMPANY.IS_ADMIN_ONLY_CHANGE_TO_PARTIALLY_DELIVERED',
        hidden: false,
        category: 'packages'
      },
      {
        controller : 'isAllowClerkChangeStatus',
        label: 'COMPANY.IS_CLERK_CAN_CHANGE_THE_PACKAGE_STATUS',
        hidden: this.companyId !== 256 && this.companyId !== 22,
        category: 'packages'
      },
      {
        controller : 'isShowCodCollectionMethodWhenReceivingCod',
        label: 'COMPANY.IS_SHOW_COD_COLLECTION_METHOD_WHEN_RECEIVING_COD',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isAddSerialInvoiceNumberForNewPackages',
        label: 'COMPANY.ADD_SERIAL_INVOICE_NUMBER_FOR_NEW_PACKAGES',
        category: 'packages'
      },
      {
        controller : 'isEnableDeliveryVerificationPinCodeForReturnedBundles',
        label: 'COMPANY.IS_ENABLE_DELIVERY_VERIFICATION_PIN_CODE_FOR_RETURNED_BUNDLES',
        category: 'packages',
        hidden: !this.userService.userInfo.isSupportReturnedBundles
      },
      {
        controller : 'isEnablePricingPerkg',
        label: 'COMPANY.IS_WEIGHT_USED_IN_COST_CALCULATION',
        hidden: false,
        category: 'attachments'
      },
      {
        controller : 'isDriverProveDeliveryByScanBarcode',
        label: 'COMPANY_CONFIGURATION.IS_DRIVER_PROVE_DELIVERY_BY_SCAN_BARCODE',
        subLabel: 'COMPANY_CONFIGURATION.IS_DRIVER_PROVE_DELIVERY_BY_SCAN_BARCODE_DETAIL',
        category: 'driver'
      }
    ];
  }

  private initCategories() {
    this.categoriesDetail =  [
      {
        label: 'ADMINISTRATION.CATEGORY.GENERAL',
        name: 'general',
        hidden: !this.isSuperAdmin,
      },
      {
        label: 'ADMINISTRATION.CATEGORY.PACKAGES',
        name: 'packages',
      },
      {
        label: 'ADMINISTRATION.CATEGORY.DRIVERS',
        name: 'driver',
      },
      {
        label: 'ADMINISTRATION.CATEGORY.CUSTOMERS',
        name: 'customer',
      },
      {
        label: 'ADMINISTRATION.CATEGORY.ATTACHMENTS',
        name: 'attachments',
      },
      {
        label: 'ADMINISTRATION.CATEGORY.PARTNER',
        name: 'partner',
      },
      {
        label: 'ADMINISTRATION.CATEGORY.CUSTOMER_SUPPORT',
        name: 'customerSupport',
        hidden: !this.userService.userInfo.isTicketingSystemEnabled
      }
    ];
  }

  restBtnClicked($event: MouseEvent) {
    this.translateService.get(['GENERAL.YES', 'GENERAL.NO', 'ACTIONS.IGNORE_CHANGES']).subscribe(values => {
      this.confirmationService.confirm({
        acceptLabel: values['GENERAL.YES'],
        rejectLabel: values['GENERAL.NO'],
        message: values['ACTIONS.IGNORE_CHANGES'],
        accept: () => {
          this.fillFormData(this.companyInfo);
          this.changesMap = {};
          this.numberOfChanges = 0;
        }
      });
    });
  }

  private fillFormData(company: any) {
    const {
      isGuestAddPackage,
      isUseBankTransfer, isShowPhoneInPackageReport, isUseStickerPrinterForAwb, isDriverPickupUnassignedPackage, isPreventDriversScanningPostponedPackages, isAllowDriversToViewAttachments, isExcludeSort,
      isAdminOnlyChangeFromDelivered, isShowDriverNameInPackageTracking, isPrintCashReceiptWithMassReport,
      isPrintProofReportWithDriverRunsheet, isChangeReceivedOrSortedPackageStatus, isHubManagerAddPackage, isNonAdminChangeCost,
      isShowBarcodeImageInMassReport, isEnableBringPrice, isAllowOperationManagerAddCustomer, isAddCustomerFromQuickAddShipmentForm,
      isAllowPartnerChangeCod, isHidePartnerSenderParent, isHidePartnerSender, isHidePartnerSenderAddress, isSeperateReadyCodToDeliverandCodAtHand, isChangeExportedPackages,
      isDeliverPartialMassReport, isSupportAddingDraftPackagesByCustomers, isAllCustomersAddDraftPackages,
      isPreventCustomersToAddPickupPackagesWhenNoCash,
      isPrintMassCodReportOnceByAccountant, isHideCostFromRecieveCodReport, isHideDeliveredMassCodReports,
      isAllowOperationManagerReceiveCod, isPartialDeliveryEnabled, isHideDeliveredToSenderInChangeStatus,
      isSupportReceiveWithoutReleasingCustody, isPickupSupported, isAllowDispatcherReceiveCod,
      isPrintTwoCopiesForSwap, isForceAllCustomersToAddAttachment, isShowAddShipmentInSideMenu,
      isAllowAddingAttachment, isShowCodSumValuesInCustomerDetailedReport, isBundlePodEnabled, isDriverCanRequestCodChange,
      isDriverCanReturnPackage, isAdminChangeCodForReturnedAndDelivered, isDriverEarningEnabled,
      isFulfilmentEnabled, isCorporate, isTrucking, isDistributor, isShowMassCodReports, isDisableCostInAddPackage,
      hasRouteOptimization, isLcl, isPickupUnListedPackagesByAdmin, isDriverPickupAcceptedPackages,
      isShowDriverLocationInPackageTracking, isShowDriverPhoneInCustomerPackageTracking, isDriverCanFailPackageDisabled,
      isSignatureOnPackageDeliveryDisabled, isDriverPickupPackagesByScanDisabled,
      isShowDelayedCountInCustomerDashboard, isPromptNoteForDriverInPackageDelivery, isPackageFingerprintRequired,
      isForceAddingInvoiceNumberWhenAddingPackages, isReceivingPkgsWithoutScanItems, isAutoAssignDriversToPkgs,
      isAutoAssignToOnlineDriversOnly, isEnableAutoAssignForAllCustomers, isShowCustomerFieldWhileAddingTicket,
      isShowPaymentTypesWhenDriverDeliver, isAutoApproveCustomersAfterSignup, isShowCustomersToHubManager,
      isShowDetailedPackageHistoryForALLCustomers, isEnableDeliveryVerificationPinCodeForPkgsWithCodGreaterThan,
      deliveryVerificationPinCodeExpirationInMinutesForPkgs, isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD, isDriverSignupEnabled,
      isAutoAssignToShippingLineDriver, isHidePackagesInDriversApps, isForceDriversAttachDraftPackage, isDriverProveDeliveryByScanBarcode, isAllowDriverChangePackageType,
      isEnableCustomerConfirmationForReceivingMassCodPkg, isAllowCustomersToEditPendingPackagesOnly, isAllCustomersAddBundles, isShareRecipientForAllCompanyCustomers, isEnableCustomersNotifyAdminsToDeliverCod,
      isHideBarcodeImageInCodMismatchReport, isPasswordConfirmationRequiredToUpdateTheShippingRate, isForceDriversToSelectReturnReason, isEnableGenerateBarcodeImageForInvoiceNumber,
      isForceChangingCodAndCostBeforeReceivingCod, isAllowAdminToChangeStatusOfExportedToHubPkgs, isAdminOnlyDeletePackages,
      isAllowDriversOutOfServiceAnytime, isShowCityPrefixInAwb, isShowCityColor, isDescriptionMandatoryInAddPackageForm,
      isShowQrCodeInReports, isHideDetailInAddingPackage, isPrintAwbCopiesAsPackageQuantity, maxAttemptsOfAutoAssigningPkgToDrivers,
      waitingTimeForDriversToAcceptAutoAssignedPkgs, isAdminOnlyChangeToPartiallyDelivered, isAllowClerkChangeStatus, isShowCodCollectionMethodWhenReceivingCod, isEnablePricingPerkg,
      isAddSerialInvoiceNumberForNewPackages, isEnableDeliveryVerificationPinCodeForReturnedBundles
    } = company;
    this.form.patchValue({
      company: {
        isGuestAddPackage,
        isUseBankTransfer,
        isShowPhoneInPackageReport,
        isUseStickerPrinterForAwb,
        isDriverPickupUnassignedPackage,
        isPreventDriversScanningPostponedPackages,
        isAllowDriversToViewAttachments,
        isHubManagerAddPackage,
        isNonAdminChangeCost,
        isAdminOnlyChangeFromDelivered,
        isShowDriverNameInPackageTracking,
        isChangeReceivedOrSortedPackageStatus,
        isPrintCashReceiptWithMassReport,
        isHideCostFromRecieveCodReport,
        isPrintProofReportWithDriverRunsheet,
        isHideDeliveredMassCodReports,
        isForceAllCustomersToAddAttachment,
        isShowCodSumValuesInCustomerDetailedReport,
        isAllowAddingAttachment,
        isExcludeSort,
        isShowBarcodeImageInMassReport,
        isEnableBringPrice,
        isAllowOperationManagerAddCustomer,
        isAddCustomerFromQuickAddShipmentForm,
        isAllowPartnerChangeCod,
        isHidePartnerSenderParent : isHidePartnerSender || isHidePartnerSenderAddress,
        isHidePartnerSender,
        isHidePartnerSenderAddress,
        isSupportAddingDraftPackagesByCustomers,
        isAllCustomersAddDraftPackages,
        isPreventCustomersToAddPickupPackagesWhenNoCash,
        isSeperateReadyCodToDeliverandCodAtHand,
        isChangeExportedPackages,
        isDeliverPartialMassReport,
        isAllowOperationManagerReceiveCod,
        isAllowDispatcherReceiveCod,
        isPrintTwoCopiesForSwap,
        isSupportReceiveWithoutReleasingCustody,
        isPartialDeliveryEnabled,
        isHideDeliveredToSenderInChangeStatus,
        isPickupSupported,
        isPrintMassCodReportOnceByAccountant,
        isAdminChangeCodForReturnedAndDelivered,
        isDriverCanRequestCodChange,
        isDriverCanReturnPackage,
        isShowAddShipmentInSideMenu,
        isBundlePodEnabled,
        isDriverEarningEnabled,
        isFulfilmentEnabled,
        isCorporate,
        isTrucking,
        isDistributor,
        isShowDriverLocationInPackageTracking,
        isShowDriverPhoneInCustomerPackageTracking,
        isDriverCanFailPackageDisabled,
        isSignatureOnPackageDeliveryDisabled,
        isDriverPickupPackagesByScanDisabled,
        isLcl,
        isShowMassCodReports,
        isDisableCostInAddPackage,
        hasRouteOptimization,
        isPickupUnListedPackagesByAdmin,
        isPackageFingerprintRequired,
        isDriverPickupAcceptedPackages,
        isShowDelayedCountInCustomerDashboard,
        isPromptNoteForDriverInPackageDelivery,
        isForceAddingInvoiceNumberWhenAddingPackages,
        isReceivingPkgsWithoutScanItems,
        isAutoAssignDriversToPkgs,
        isAutoAssignToOnlineDriversOnly,
        isEnableAutoAssignForAllCustomers,
        isAutoAssignToShippingLineDriver,
        isHidePackagesInDriversApps,
        isShowCustomerFieldWhileAddingTicket,
        isShowPaymentTypesWhenDriverDeliver,
        isAutoApproveCustomersAfterSignup,
        isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD,
        isEnableCustomersNotifyAdminsToDeliverCod,
        isShowDetailedPackageHistoryForALLCustomers,
        isShowCustomersToHubManager,
        isAllowCustomersToEditPendingPackagesOnly,
        isEnableDeliveryVerificationPinCodeForPkgsWithCodGreaterThan,
        deliveryVerificationPinCodeExpirationInMinutesForPkgs,
        isForceDriversAttachDraftPackage,
        isDriverProveDeliveryByScanBarcode,
        isAllowDriverChangePackageType,
        isAllowDriversOutOfServiceAnytime,
        isShowCityPrefixInAwb,
        isShowQrCodeInReports,
        isShowCityColor,
        isDescriptionMandatoryInAddPackageForm,
        isPrintAwbCopiesAsPackageQuantity,
        isHideDetailInAddingPackage,
        isAdminOnlyChangeToPartiallyDelivered,
        isDriverSignupEnabled,
        isEnableCustomerConfirmationForReceivingMassCodPkg,
        isAllCustomersAddBundles,
        isShareRecipientForAllCompanyCustomers,
        isHideBarcodeImageInCodMismatchReport,
        isPasswordConfirmationRequiredToUpdateTheShippingRate,
        isForceDriversToSelectReturnReason,
        isEnableGenerateBarcodeImageForInvoiceNumber,
        isForceChangingCodAndCostBeforeReceivingCod,
        isAllowAdminToChangeStatusOfExportedToHubPkgs,
        isAdminOnlyDeletePackages,
        isAllowClerkChangeStatus,
        isShowCodCollectionMethodWhenReceivingCod,
        isEnablePricingPerkg,
        isAddSerialInvoiceNumberForNewPackages,
        isEnableDeliveryVerificationPinCodeForReturnedBundles,
        maxAttemptsOfAutoAssigningPkgToDrivers : (Math.max(maxAttemptsOfAutoAssigningPkgToDrivers, 1)),
        waitingTimeForDriversToAcceptAutoAssignedPkgs : (Math.max(waitingTimeForDriversToAcceptAutoAssignedPkgs, 1)),
      }
    });
    if (this.isEnableDeliveryVerificationPinCodeForPkgs) {
      this.form.controls.company.get('deliveryVerificationPinCodeExpirationInMinutesForPkgs').patchValue(company.deliveryVerificationPinCodeExpirationInMinutesForPkgs ? company.deliveryVerificationPinCodeExpirationInMinutesForPkgs : 2);
      this.form.controls.company.get('deliveryVerificationPinCodeExpirationInMinutesForPkgs').updateValueAndValidity();
    }
  }

  triggerChange($event, controleName) {
    if (this.changesMap[controleName] === true || this.changesMap[controleName] === false) {
      delete this.changesMap[controleName];
    } else {
      this.changesMap[controleName] = $event.checked;
    }
    this.numberOfChanges = Object.keys(this.changesMap).length;
  }
  triggerStringChange($event, controleName) {
    this.changesMap[controleName] = $event.value;
    this.numberOfChanges = Object.keys(this.changesMap).length;
  }
  isEpsDraftPackagesEnable() {
    return (this.userService.userInfo.companyId === 4 || this.userService.userInfo.companyId === 1);
  }
  private addValidatorsToController(controller, validatorFns) {
    controller.setValidators(validatorFns);
    controller.updateValueAndValidity();
  }

  isHidden(checkbox: any) {
    if (checkbox) {
      switch (typeof checkbox.hidden) {
        case 'boolean':
          return checkbox.hidden;
        case 'function':
          return checkbox.hidden();
      }
    }
    return false;
  }
}
