<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="main-container">
            <p-table [value]="packageContents" responsiveLayout="scroll" (focusout)="setSelectedContent()">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.NAME' | translate}}</th>
<!--                        <ng-container *ngIf="showAvailable">-->
                            <th *ngIf="!isTrucking">
                                <div>{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.WEIGHT' | translate}}</div>
                                <div>({{'UNITS.KG' | translate}})</div>
                            </th>
                            <th *ngIf="!isTrucking">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.PALLETS' | translate}}</th>
                            <th *ngIf="!isTrucking">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.CARTONS' | translate}}</th>
                            <th *ngIf="!isTrucking">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.BOXES' | translate}}</th>
                            <th *ngIf="!isTrucking">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.PACKAGES' | translate}}</th>
                            <th *ngIf="isTrucking">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.QUANTITY' | translate}}</th>
                            <th *ngIf="!isTrucking">{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.TABLE.UNIT' | translate}}</th>
<!--                        </ng-container>-->
<!--                        <ng-container *ngIf="showOriginalInEdit && !isTrucking && !showAvailable">-->
<!--                            <th>
                                <div>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_WEIGHT' | translate}}</div>
                                <div>({{'UNITS.KG' | translate}})</div>
                            </th>-->
<!--                            <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_PALLETS' | translate}}</th>-->
<!--                            <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_CARTOONS' | translate}}</th>-->
<!--                            <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_BOXES' | translate}}</th>-->
<!--                            <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_PACKAGES' | translate}}</th>-->
<!--                            <th>{{'DISTRIBUTOR_REPORTS.TABLE.ORIGINAL_UNIT' | translate}}</th>-->
<!--                        </ng-container>-->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-content>
                    <tr (click)="setSelectedContent()">
                        <td>
                            <p-checkbox [(ngModel)]="content.isSelected" [binary]="true" (onChange)="resetContentValue(content)" [disabled]="showOriginalInEdit && showAvailable"></p-checkbox>
                        </td>
                        <td>
                            {{content.name}}
                        </td>
                        <ng-container *ngIf="showAvailable">

                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected || showOriginalInEdit" *ngIf="!isTrucking">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective
                                               [(ngModel)]="content.weight">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.weight}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn *ngIf="!isTrucking" [pEditableColumnDisabled]="!content.isSelected || showOriginalInEdit">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective
                                               [(ngModel)]="content.pallets">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.pallets}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td pEditableColumn *ngIf="!isTrucking" [pEditableColumnDisabled]="!content.isSelected || showOriginalInEdit">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective
                                               [(ngModel)]="content.cartons">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.cartons}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn *ngIf="!isTrucking" [pEditableColumnDisabled]="!content.isSelected || showOriginalInEdit">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective
                                               [(ngModel)]="content.boxes">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.boxes}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected || showOriginalInEdit">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective
                                               [(ngModel)]="content.packages">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.packages}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn *ngIf="!isTrucking" [pEditableColumnDisabled]="!content.isSelected || showOriginalInEdit">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective
                                               [(ngModel)]="content.unit" required>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.unit}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                        </ng-container>
                        <ng-container *ngIf="showOriginalInEdit && !isTrucking && !showAvailable">
                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective [(ngModel)]="content.originalWeight">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.originalWeight}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective [(ngModel)]="content.originalPallets">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.originalPallets}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective [(ngModel)]="content.originalCartons">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.originalCartons}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective [(ngModel)]="content.originalBoxes">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.originalBoxes}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input  pInputText type="number" appInputPositiveNumberDirective [(ngModel)]="content.originalPackages">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.originalPackages}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn [pEditableColumnDisabled]="!content.isSelected">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" appInputPositiveNumberDirective [(ngModel)]="content.originalUnit" required>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <span class="output">{{content.originalUnit}}</span>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </ng-container>

                    </tr>
                </ng-template>
            </p-table>
    </div>
</div>
