import {Component, OnDestroy, OnInit} from '@angular/core';
import {FulfilmentService} from '../../services/fulfilment.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {UserStatus} from '../../../shared/enums/UserStatus';
import {UsersService} from '../../../users/services/users.service';
import {HubsService} from '../../../shared/services/hubs.service';

@Component({
    selector: 'app-reserve-bin-dialog',
    templateUrl: './reserve-bin-dialog.component.html',
    styleUrls: ['./reserve-bin-dialog.component.scss']
})
export class ReserveBinDialogComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {

    reserveBinForm: FormGroup;
    currentLang = 'en';
    isEditMode = false;
    isLoading = false;
    bin: any;
    isLocation;


    customersLoading = false;
    customersSubject = new Subject<string>();
    customersList = [];

    shippingPlansLoading = false;
    shippingPlansSubject = new Subject<string>();
    shippingPlansList = [];
    shippingPlansListOriginal = [];

    hubsOptions: { value: string, label: string }[];
    binLoading;
    binList = [];
    binSubject = new Subject<string>();

    constructor(private fulfilmentService: FulfilmentService,
                private activeModal: NgbActiveModal,
                private translateService: TranslateService,
                private hubsService: HubsService,
                private usersService: UsersService,
                private messageService: MessageService,
                private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isEditMode = this.bin.isReserved;
        this.subscriptions.push(this.customersSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.getCustomers({query: search});
            }));

        this.subscriptions.push(this.shippingPlansSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.getShippingPlans({query: search});
            }));
        this.initForm();
        this.initWareHousesList();

        this.subscriptions.push(this.binSubject
            .pipe(debounceTime(SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD))
            .pipe(distinctUntilChanged())
            .subscribe((search: string) => {
                this.getBins({query: search});
            }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getCustomers(event?) {
        let query = '?page=1&pageSize=100&status=' + UserStatus.VERIFIED;

        if (event && event.query) {
            query += `&search=${event.query}`;
        }
        query += '&isFulfillment=true';
        this.customersLoading = true;
        this.usersService.getCustomersDropDown(query).subscribe(
            (customers: any) => {
                this.customersList = customers.map(customer => ({label: `${customer.fullNameFromNameFields} (${customer.businessName})`, value: customer.id}));
                this.customersLoading = false;
            }, () => {
                this.customersLoading = false;
            }
        );
    }

    getShippingPlans(event?) {
        const query: any = {
            page: 1,
            pageSize: 100,
        };
        if (event && event.query) {
            query.search = event.query;
        }
        if (this.reserveBinForm.get('customer').value) {
            query.customerId = this.reserveBinForm.get('customer').value.value;
        }
        if (!this.isLocation) {
            query.warehouseId = this.bin.hubId;
        }
        this.fulfilmentService.getShippingPlans(query).subscribe(
            (res: any) => {
                this.shippingPlansList = res.data.map(shippingPlan => ({label: shippingPlan.barcode, value: shippingPlan.id}));
                this.shippingPlansListOriginal = res.data;
            }, error => {
                console.error(error);
            }
        );
    }

    private initForm() {
        let info = {customer: null, numberOfSku: null, shippingPlan: null, hubId: null, binBarcode: null, numberOfItems: null};
        if (this.isEditMode) {
            info = {
                customer: {label: this.bin.reservedTo, value: this.bin.customerId},
                numberOfSku: this.bin.numberOfSku,
                shippingPlan: {label: this.bin.shippingPlanBarcode, value: this.bin.shippingPlanId},
                hubId: this.bin.hubId,
                binBarcode: this.bin.binBarcode,
                numberOfItems: this.bin.numberOfItems
            };
        }
        this.reserveBinForm = this.formBuilder.group({
            customer: [info.customer, Validators.required],
            numberOfSku: [info.numberOfSku, (this.isLocation && this.bin.type !== 'BIN' ? [Validators.required] : [])],
            shippingPlan: [info.shippingPlan],
            hubId: [info.hubId, (this.isLocation ? [] : Validators.required)],
            binBarcode: [info.binBarcode],
            numberOfItems: [ info.numberOfItems, (this.isLocation && this.bin.type !== 'BIN' ? [Validators.required] : [])],
        });
    }

    initWareHousesList() {
        if (!this.isLocation) {
            this.hubsOptions = [{label: this.bin.warehouseName, value: this.bin.hubId}];
            this.reserveBinForm.get('hubId').patchValue(this.hubsOptions[0].value);
        }
    }

    submitForm() {
        this.isLoading = true;
        const formData = this.reserveBinForm.getRawValue();
        const body = {customerId: formData.customer.value,
            numberOfSku: formData['numberOfSku'],
            numberOfItems: formData['numberOfItems'],
            binBarcode: formData['binBarcode'],
        };
        if (formData['hubId']) {
            body['hubId'] = formData['hubId'];
        }
        if (body.binBarcode && body.binBarcode.barcode) {
            body.binBarcode = body.binBarcode.barcode;
            body['barcode'] = body.binBarcode;
        } else {
            delete body.binBarcode;
        }
        if (formData.shippingPlan) {
            body['shippingPlanId'] = formData.shippingPlan.value;
        }
        for (const key in body) {
            // @ts-ignore
            // tslint:disable-next-line:triple-equals
            if (!body[key] && body[key] != 0) {
                delete body[key];
            }
        }
        let reserveBinReq = this.fulfilmentService.reserveBin(this.bin.id, body);
        if (this.isLocation) {
            reserveBinReq = this.fulfilmentService.reserveLocation(this.bin.id, body);
        }
        if (this.isEditMode) {
            reserveBinReq = this.fulfilmentService.reserveBinUpdate(this.bin.id, body);

        }
        reserveBinReq.subscribe(
            () => {
                this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                this.activeModal.close({isSuccess: true});
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );

    }

    closeModal(isSuccess?) {
        if (isSuccess) {
            this.activeModal.close({isSuccess: isSuccess});
        }
        this.activeModal.close();
    }

    chooseStore() {
        this.reserveBinForm.get('shippingPlan').patchValue(null);
        this.reserveBinForm.get('binBarcode').patchValue(null);
        this.getShippingPlans();
    }


    chooseBin(b) {
        console.log(b);
    }

    public getBins(event?) {
        const query = {
            page: 1,
            pageSize: 10,
            warehouseId: this.bin.hubId,
            search: (event && event.query) ? event.query : '',
            forReserveLocations: true
        };
        if (this.reserveBinForm.get('customer')?.value?.value) {
            query['customerId'] = this.reserveBinForm.get('customer')?.value?.value;
        }
        this.binLoading = true;
        this.fulfilmentService.getBins(query).subscribe(
            (response: any) => {
                this.binList = response.data;
                this.binLoading = false;
            }, () => {
                this.binLoading = false;
            }
        );
    }
}
