<p-overlayPanel styleClass="without-padding" #mapChoice>
    <div>
        <div class="map-items" (click)="showMaps('NORMAL')">
            <i class="fas fas fa-map-marker-alt"></i>
            <span>Long & Lat</span>
        </div>
        <div class="map-items" (click)="showMaps('What3World')">
            <img class="icon" src="../../assets/icons/w3w_symbol.svg" alt="icon" />
            <span>what 3 words</span>
        </div>
    </div>
</p-overlayPanel>
<p-sidebar [(visible)]="display"
           (onHide)="onClose()"
           [dismissible]="false"
           #AddPackageSideBar
           appendTo="body"
           [baseZIndex]="2"
           styleClass="{{getSidebarClassName()}}">

    <div class="spinner-drape" *ngIf="isLoading === spinnerStates.LOADING">
        <i class="fas fa-spinner fa-pulse"></i>
    </div>

    <form [formGroup]="addPackageForm" *ngIf="isLoading === spinnerStates.LOADED" class="add-package-form">
        <div class="add-package" >
            <div class="white-section">
                <div class="row">
                    <div class="col-6">
                        <div class="page-title">
                            <span *ngIf="!packageBarcode">{{'ADD_SHIPMENTS.ADD_SHIPMENT' | translate | packageTitle }}</span>
                            <span *ngIf="packageBarcode">{{'ADD_SHIPMENTS.EDIT_PACKAGE' | translate | packageTitle }}</span>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="showCreatedDate()">
                        <div class="input-item"
                             [ngClass]="{'has-error': (addPackageForm.get('createdDate').touched && addPackageForm.get('createdDate').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.CREATED_DATE' | translate}}
                            </label>
                            <p-calendar formControlName="createdDate" styleClass="date-picker" icon="pi pi-calendar" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                        </div>
                    </div>
                </div>

                <div class="space-md"></div>

                <div class="page-content" style="background-color: #FFFFFF">

                    <ng-container *ngIf="isTrucking">
                        <div class="row">
                            <div class="col-9 col-sm-6">
                                <div class="form-title">
                                    <div class="icon get-cash contain-image"></div>
                                    <!--                                    <span>{{'ADD_SHIPMENTS.PAYMENT' | translate}}</span>-->
                                    <span>{{'ADD_SHIPMENTS.SERVICE' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="mid-space"></div>

                        <div class="row">
                            <div class="col-6">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('paymentType').touched && addPackageForm.get('paymentType').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.SERVICE' | translate}}
                                    </label>
                                    <p-dropdown
                                            formControlName="paymentType"
                                            [options]="shipmentTypesOptions"
                                            styleClass="dropdown-container"
                                            (onChange)="onTypeChanged($event)"
                                            placeholder="{{ 'ACTIONS.SELECT_TYPE' | translate }}"></p-dropdown>
                                </div>
                            </div>
                            <div class="swap-section">
                                <div class="row">
                                    <div class="col-sm-6" *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'">
                                        <input *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"
                                               type="radio"
                                               [value]="'COLLECT'"
                                               checked
                                               name="swapOrBring"
                                               formControlName="swapOrBring"
                                               (change)="changeSwapOrBring()">
                                        <div class="input-item"
                                             [ngStyle]="{'background': this.swapOrBring === 'PAY' ? 'gainsboro' : 'white'}"
                                             [ngClass]="{'has-error': (addPackageForm.get('toCollectFromReceiver').touched && addPackageForm.get('toCollectFromReceiver').errors)}">
                                            <label class="label">
                                                {{'ADD_SHIPMENTS.TO_COLLECT_FROM_RECEIVER' | translate}}
                                            </label>
                                            <input formControlName="toCollectFromReceiver" type="number" placeholder="" min="0">
                                        </div>
                                    </div>
                                    <div class="col-sm-6" *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'">
                                        <input *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"
                                               type="radio"
                                               [value]="'PAY'"
                                               name="swapOrBring"
                                               formControlName="swapOrBring"
                                               (change)="changeSwapOrBring()">
                                        <div class="input-item"
                                             [ngStyle]="{'background': this.swapOrBring === 'COLLECT' ? 'gainsboro' : 'white'}"
                                             [ngClass]="{'has-error': (addPackageForm.get('toPayToReceiver').touched && addPackageForm.get('toPayToReceiver').errors)}">
                                            <label class="label">
                                                {{'ADD_SHIPMENTS.TO_PAY_TO_RECEIVER' | translate}}
                                            </label>
                                            <input formControlName="toPayToReceiver" type="number" placeholder="" min="0">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6"
                                 *ngIf="['COD', 'NON_COD', 'COD_V2'].indexOf(addPackageForm.controls.paymentType.value) !== -1">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('cod').touched && addPackageForm.get('cod').errors)}">
                                    <label class="label">
                                        {{ (isLcl ? 'ADD_SHIPMENTS.TOTAL_ORDER_PRICE' : 'ADD_SHIPMENTS.COD') | translate}}
                                    </label>
                                    <input formControlName="cod" type="number" placeholder="">
                                </div>
                                <div class="delivery-cost"
                                     *ngIf="deliveryPrice !== -1">{{(isLcl ? 'ADD_SHIPMENTS.SHIPMENT_PRICE' : 'ADD_SHIPMENTS.DELIVERY_PRICE') | translate}}
                                    : {{deliveryPrice}}</div>
                            </div>

                            <div class="col-6"
                                 *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"></div>
                            <div class="space-md"
                                 *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"></div>
                        </div>

                        <div class="space-md"></div>

                    </ng-container>
                    <div class="row">
                        <div class="col-9 col-sm-6">
                            <div class="form-title">
                                <div class="icon sender-location contain-image"></div>
                                <div class="d-flex justify-content-between">
                                    <span *ngIf="!swapSenderAndReceiver">{{'ADD_SHIPMENTS.SENDER_INFORMATION' | translate}}</span>
                                    <span *ngIf="swapSenderAndReceiver">{{'ADD_SHIPMENTS.RECEIVER_INFORMATION' | translate}}</span>
                                    <i *ngIf="showAddCustomer() && !isLcl" pTooltip="{{ 'QUICK_ADD.ADD_CUSTOMER' | translate}}" tooltipPosition="top"
                                       class="fas fa-2x fa-plus-circle add-customer-icon" (click)="addCustomer()"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isLcl" class="space-md"></div>

                    <div *ngIf="isLcl" class="row">
                        <div class="col-10">
                            <div class="sender-info">
                                <img src="../../assets/icons/add_package/souq_icon.png">
                                <div>
                                    <div class="sender-title">{{senderInfoLcl.senderName}} {{senderInfoLcl.businessSenderName? ' - ' + senderInfoLcl.businessSenderName: ''}}</div>
                                    <div class="details">{{senderInfoLcl.address.label}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isLcl" class="space-md"></div>

                    <div *ngIf="isLcl" class="row reciever-info-container">
                        <div class="col-8 col-sm-6">
                            <div class="form-title">
                                <div class="icon reciever-info contain-image"></div>
                                <div class="d-flex justify-content-between">
                                    <span>{{'ADD_SHIPMENTS.RECEIVER_INFORMATION' | translate}}</span>
                                    <i *ngIf="showAddCustomer()" pTooltip="{{ 'QUICK_ADD.ADD_CUSTOMER' | translate }}" tooltipPosition="top"
                                       class="fas fa-2x fa-plus-circle add-customer-icon" (click)="addCustomer()"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="space-md"></div>

                    <div class="row">
                        <div class="col-md-7">
                            <div class="main-store"
                                 [ngClass]="{'has-error': (addPackageForm.get('customerInfo').touched && addPackageForm.get('customerInfo').errors)}">
                                <label *ngIf="!customizationCompaniesService.checkCompanies(companies.JETT)">
                                    <span>{{'ADD_SHIPMENTS.STORE_NAME' | translate}}</span>
                                </label>
                                <ng-select formControlName="customerInfo"
                                           (change)="chooseStore(true)"
                                           [typeahead]="receiverSubject"
                                           [loading]="receiverLoading"
                                           [items]="storesList"
                                           (open)="!storesList.length ? getStores() : null"
                                           typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                           loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                           notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                           placeholder="{{ 'ADD_SHIPMENTS.STORE_NAME_PLACEHOLDER' | translate}}">
                                    <ng-template ng-label-tmp let-store="item">
                                        <div class="selected-store" *ngIf="store">
                                            <img src="../../assets/icons/add_package/souq_icon.png">
                                            <ng-container *ngIf="!isTrucking">
                                                <div>
                                                    <div class="store-name">{{ store.fullName + (store.businessName ? ' (' + store.businessName + ')' : '') }}</div>
                                                    <div *ngIf="store.address">{{ store.address.village }} - {{ store.address.city }} - {{ store.address.region }}</div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="isTrucking">
                                                <div>
                                                    <div class="store-name">{{ store.businessName ? store.businessName : store.fullName }}</div>
                                                    <div *ngIf="store.address">{{ store.address.village }} - {{ store.address.city }} - {{ store.address.region }}</div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-store="item">
                                        <ng-container *ngIf="!isTrucking">
                                            <div>{{ store.fullName + (store.businessName ? ' - ' + store.businessName : '') }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="isTrucking">
                                            <div>{{ store.businessName ? store.businessName : store.fullName }}</div>
                                        </ng-container>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="space-md hidden-md-up"></div>
                        </div>
                        <div *ngIf="!isJettCompany() && !isLcl && !isTrucking" class="col-md-5" [ngClass]="{'package-location-tabs-container-edit-mode': isEditMode || !isCloning}">
                            <div *ngIf="isCloning || !isEditMode" class="package-location-tabs">
                                <span (click)="changePackageLocation(packageLocations.HUB)"
                                        [ngClass]="{'active': packageLocation == packageLocations.HUB, 'active-driver': packageLocation == packageLocations.DRIVER}">
                                    {{ 'ADD_SHIPMENTS.IN_HUB' | translate | hubTitle }}
                                </span>
                                <span (click)="changePackageLocation(packageLocations.DRIVER)"
                                        [ngClass]="{'active': packageLocation == packageLocations.DRIVER, 'active-driver': packageLocation == packageLocations.DRIVER}">
                                    {{ 'ADD_SHIPMENTS.WITH_DRIVER' | translate }}
                                </span>
                                <span (click)="changePackageLocation(packageLocations.CUSTOMER)"
                                        [ngClass]="{'active': packageLocation == packageLocations.CUSTOMER, 'active-driver': packageLocation == packageLocations.DRIVER}">
                                    {{ 'ADD_SHIPMENTS.FROM_CUSTOMER_ADDRESS' | translate | addressSystem}}
                                </span>
                            </div>
                            <div class="pakcage-location-field">
                                <div class="input-item driver-input"
                                     *ngIf="packageLocation == packageLocations.HUB && (isCloning || !isEditMode)"
                                     [ngClass]="{'has-error': (addPackageForm.get('hubId').touched && addPackageForm.get('hubId').errors)}">
                                    <p-autoComplete formControlName="hubId"
                                                    type="text"
                                                    placeholder="{{'ADD_SHIPMENTS.CHOOSE_HUB' | translate | hubTitle}}"
                                                    field="label"
                                                    [dataKey]="'value'"
                                                    [tabindex]="-1"
                                                    [forceSelection]="true"
                                                    [dropdown]="true"
                                                    [suggestions]="hubsOptions"
                                                    (completeMethod)="getHubs($event)"
                                    ></p-autoComplete>
                                </div>
                                <div class="input-item driver-input"
                                     *ngIf="packageLocation == packageLocations.DRIVER"
                                     [ngClass]="{'has-error': (addPackageForm.get('driverId').touched && addPackageForm.get('driverId').errors)}">

                                    <p-autoComplete formControlName="driverId"
                                                    type="text"
                                                    placeholder="{{'ADD_SHIPMENTS.CHOOSE_DRIVER' | translate}}"
                                                    field="label"
                                                    [tabindex]="-1"
                                                    [forceSelection]="true"
                                                    [dropdown]="true"
                                                    [suggestions]="driversOptions"
                                                    (completeMethod)="getDrivers($event)"
                                                    #driversAc
                                    >
                                        <ng-template let-driver pTemplate="item">
                                            <div class="store-item">
                                                <div class="text-ellipsis">{{driver.label}}</div>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="space-md"></div>
                    <div *ngIf="isJettCompany()">
                        <div class="row">
                            <app-drop-down [options]="jettShipmentType"
                                          [label]="'SHIPMENT.TABLE.SHIPMENT_METHOD'"
                                          formControlName = "orderId"
                                          [formControlObj]="addPackageForm.get('orderId')"
                                          [appendTo]="'body'"
                                          [dropdownNameField]="'name'"
                                          (whenChange)="changeOrderId()"
                                          class="col-6"
                                          [showClear]="false"></app-drop-down>
                        </div>

                        <div class="row">
                            <app-drop-down [options]="fromCityList"
                                          class="col-6"
                                          [label]="'SHIPMENT.TABLE.FROM_HUB'"
                                          [placeholder]="'SHIPMENT.TABLE.FROM_HUB'"
                                          formControlName = "fromHub"
                                          #fromDropdown
                                          [dropdownNameField]="'value'"
                                          (whenChange)="getToHubsList()"
                                          [formControlObj]="addPackageForm.get('fromHub')"
                                          [formControl]="addPackageForm.get('fromHub')"
                                          [showClear]="true"></app-drop-down>

                            <app-drop-down [options]="toCityList"
                                          *ngIf="addPackageForm.controls.orderId && addPackageForm.controls.orderId.value !== 'STORE_TO_DOOR_B'"
                                          class="col-6"
                                          [label]="'SHIPMENT.TABLE.TO_HUB'"
                                          [placeholder]="'SHIPMENT.TABLE.TO_HUB'"
                                          formControlName = "toHub"
                                          (whenChange)="changeJettToHub($event)"
                                          [dropdownNameField]="'value'"
                                          [formControlObj]="addPackageForm.get('toHub')"
                                          [showClear]="true"></app-drop-down>
                        </div>

                    </div>

                    <div *ngIf="!isLcl" class="space-md"></div>


                    <div *ngIf="!isLcl" class="row reciever-info-container">
                        <div class="col-8 col-sm-6">
                            <div class="form-title">
                                <div class="icon reciever-info contain-image"></div>
                                <div class="d-flex justify-content-between receiver-info-label">
                                    <span *ngIf="!swapSenderAndReceiver">{{'ADD_SHIPMENTS.RECEIVER_INFORMATION' | translate}}</span>
                                    <span *ngIf="swapSenderAndReceiver">{{'ADD_SHIPMENTS.SENDER_INFORMATION' | translate}}</span>
                                    <img *ngIf="isTrucking" src="../../assets/icons/add_package/change_address.svg" (click)="showReceiverInfo($event)">
                                </div>
<!--                                <span *ngIf="!swapSenderAndReceiver">{{'ADD_SHIPMENTS.RECEIVER_INFORMATION' | translate}}</span>-->
<!--                                <span *ngIf="swapSenderAndReceiver">{{'ADD_SHIPMENTS.SENDER_INFORMATION' | translate}}</span>-->
                            </div>
                        </div>
                        <div *ngIf="companyId != 209" class="col-2 col-sm-6 business-selection" >
                            <div class="add-business-tab">
                                <button (click)="isReceiverHasBusiness = false" [tabIndex]="-1"
                                        [ngClass]="{'active': !isReceiverHasBusiness}">
                                    <img src="../../assets/icons/add_package/user_unselected.svg" *ngIf="isReceiverHasBusiness">
                                    <img src="../../assets/icons/add_package/user.svg" *ngIf="!isReceiverHasBusiness">
                                </button>
                                <button (click)="isReceiverHasBusiness = true" [tabIndex]="-1"
                                        [ngClass]="{'active': isReceiverHasBusiness}">
                                    <img src="../../assets/icons/add_package/company.svg" *ngIf="!isReceiverHasBusiness">
                                    <img src="../../assets/icons/add_package/company_icon.svg" *ngIf="isReceiverHasBusiness">
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isLcl" class="mid-space"></div>

                    <div *ngIf="!isLcl" class="row">
                        <div class="col-sm-6" *ngIf="!isReceiverNameNotRequired()">
                            <div class="input-item d-flex"
                                 [ngClass]="{'has-error': (addPackageForm.get('receiverName').touched && addPackageForm.get('receiverName').errors)}">
                                <label class="label">
                                    <span *ngIf="!swapSenderAndReceiver">{{'ADD_SHIPMENTS.RECEIVER_NAME' | translate}}</span>
                                    <span *ngIf="swapSenderAndReceiver">{{'ADD_SHIPMENTS.SENDER_NAME' | translate}}</span>
                                </label>
                                <input type="text" formControlName="receiverName">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="phone-input">
                                <div class="input-item auto-complete-field"
                                     [ngClass]="{'has-error': (addPackageForm.get('receiverPhone').touched && addPackageForm.get('receiverPhone').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.MOBILE_NUMBER' | translate }}
                                    </label>
                                    <app-phone-number-input #phoneNumberInputComponent [formGroupPass]="addPackageForm"
                                                            [formControlNamePass]="'receiverPhone'"
                                                            [formControlPass]="addPackageForm.get('receiverPhone')"
                                                            cssClassPass="add-package"></app-phone-number-input>
                                </div>
                                <div class="add-phone">
                                    <img src="../../assets/icons/add_package/add_phone.svg"
                                         [ngClass]="{'active': !showExtraPhoneField}"
                                         (click)="toggleExtraPhone()">
                                    <img src="../../assets/icons/add_package/add_phone_disabled.svg"
                                         [ngClass]="{'active': showExtraPhoneField}">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="small-space" *ngIf="showExtraPhoneField && !isLcl"></div>

                    <div class="row" *ngIf="showExtraPhoneField && !isLcl">
                        <div class="col-12">
                            <div class="phone-input">
                                <div class="input-item auto-complete-field d-flex"
                                     [ngClass]="{'has-error': (addPackageForm.get('receiverPhone2').touched && addPackageForm.get('receiverPhone2').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.EXTRA_PHONE_NUMBER' | translate }}
                                    </label>
                                    <app-phone-number-input #phoneNumberInputComponent [formGroupPass]="addPackageForm"
                                                            [formControlNamePass]="'receiverPhone2'"
                                                            [formControlPass]="addPackageForm.get('receiverPhone2')"
                                                            cssClassPass="add-package"></app-phone-number-input>
                                </div>
                                <div class="remove-phone" (click)="toggleExtraPhone()">
                                    <img src="../../assets/icons/add_package/remove.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mid-space" *ngIf="isReceiverHasBusiness && !isLcl"></div>

                    <div class="row" *ngIf="isReceiverHasBusiness && !isLcl">
                        <div class="col-6">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('receiverBusinessName').touched && addPackageForm.get('receiverBusinessName').errors)}">
                                <label class="label">
                                    {{'ADD_SHIPMENTS.COMPANY_NAME' | translate}}
                                </label>
                                <input formControlName="receiverBusinessName" type="text" placeholder="" min="1">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('receiverAuthorizedGovRegistrationNumber').touched && addPackageForm.get('receiverAuthorizedGovRegistrationNumber').errors)}">
                                <label class="label">
                                    {{( isLcl ? 'ADD_SHIPMENTS.VAT_NUMBER' : 'ADD_SHIPMENTS.REGISTRATION_NUMBER_OPTIONAL') | translate}}
                                </label>
                                <input formControlName="receiverAuthorizedGovRegistrationNumber" type="number" placeholder="" min="1">
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isLcl" class="mid-space"></div>

                    <div *ngIf="!isLcl" class="row">
                        <div class="col-6" *ngIf="addressType === 'text'">
                            <app-auto-complete formControlName="receiverAddress"
                                               [label]="swapSenderAndReceiver ? 'ADD_SHIPMENTS.SENDER_ADDRESS' : (addPackageForm.get('isDeliverToReceivingPoint').value ? 'ADD_SHIPMENTS.RECEIVING_POINT' : (companyId === 221 ? 'ADD_SHIPMENTS.ALHADAF_RECEIVER_ADDRESS' : 'ADD_SHIPMENTS.RECEIVER_ADDRESS'))"
                                               [forceSelection]="true"
                                               [fieldDisabled]="isJettCompany() && addPackageForm.controls.orderId && addPackageForm.controls.orderId.value === 'STORE_TO_STORE'"
                                               field="label"
                                               [delay]="700"
                                               [suggestions]="addressOptions"
                                               (completeMethod)="getAddresses($event)"
                                               (onSelect)="chooseReceiverAddress($event)"
                                               (onClear)="clearDestinationHub($event)"
                                               [formControlObj]="addPackageForm.get('receiverAddress')"
                                               styleClass="address-selection">
                            </app-auto-complete>
                            <div class="hub-village-label" *ngIf="isSupportVillageHub && villageHub">{{"USERS.HUB" | translate}} : {{villageHub ? villageHub.name : ''}}</div>
                        </div>
                        <div class="col-6" *ngIf="addressType === 'number'">
                            <div class="input-item auto-complete-field"
                                 [ngClass]="{'has-error': (addPackageForm.get('receiverAddress').touched && addPackageForm.get('receiverAddress').errors)}">
                                <label class="label">
                                    <span *ngIf="!swapSenderAndReceiver">
                                        {{ (addPackageForm.get('isDeliverToReceivingPoint').value ? 'ADD_SHIPMENTS.RECEIVING_POINT' : (companyId === 221 ? 'ADD_SHIPMENTS.ALHADAF_RECEIVER_ADDRESS' : 'ADD_SHIPMENTS.RECEIVER_ADDRESS')) | translate | addressSystem}}
                                    </span>
                                    <span *ngIf="swapSenderAndReceiver">{{'ADD_SHIPMENTS.SENDER_ADDRESS' | translate}}</span>
                                </label>
                                <p-autoComplete formControlName="receiverAddress"
                                                [forceSelection]="true"
                                                field="label"
                                                (appNumericInput)="getAddresses($event)"
                                                [delay]="700"
                                                [suggestions]="addressOptions"
                                                (onSelect)="chooseReceiverAddress($event)"
                                                styleClass="address-selection"
                                >
                                    <ng-template let-address pTemplate="item">
                                        <div class="address-item">
                                            <div class="store-name text-ellipsis">{{address.arabicName + ' - ' + address.cityName + ' - ' + address.regionName}}</div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                        </div>

                        <div class="col-6" *ngIf="!hasRouteOptimization">
                                <app-field-input formControlName="receiverAddressLine1"
                                           [formControlObj]="addPackageForm.get('receiverAddressLine1')" [label]="(companyId === 221 ? 'GENERAL.CITY': 'ADD_SHIPMENTS.ADDRESS_DETAILS')"
                                           [fieldDisabled]="isJettCompany() && addPackageForm.controls.orderId && addPackageForm.controls.orderId.value === 'STORE_TO_STORE'">
                                </app-field-input>
                        </div>

                        <div class="col-6" *ngIf="hasRouteOptimization">
                            <div class="input-item auto-complete-field input-item-with-action"
                                 [ngClass]="{'has-error': (addPackageForm.get('receiverAddressLine1').touched && addPackageForm.get('receiverAddressLine1').errors)}">
                                <label class="label">
                                    {{(companyId === 221? 'GENERAL.CITY': 'ADD_SHIPMENTS.ADDRESS_DETAILS') | translate | addressSystem}}
                                </label>
                                <p-autoComplete formControlName="receiverAddressLine1"
                                                field="label"
                                                [delay]="700"
                                                [suggestions]="locationOptions"
                                                (completeMethod)="onGeocodeSearch($event.query)"
                                                (onSelect)="onSelectGeocodeSuggestion()"
                                                styleClass="address-selection"
                                >
                                    <ng-template let-address pTemplate="item">
                                        <div class="address-item">
                                            <div class="store-name text-ellipsis">{{address.label}}</div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                                <div class="location-action" [ngClass]="{'location-chosen': isLocationChosen()}">
                                    <i class="fas fa-chevron-down" *ngIf="chooseMaps" (click)="triggerPanel($event, mapChoice)"></i>
                                    <i class="fas fas fa-map-marker-alt" *ngIf="!chooseMaps" (click)="showMap()"></i>
                                </div>
                            </div>
                            <div *ngIf="isLocationChosen()">
                                {{ 'ADD_SHIPMENTS.LOCATION_CHOSEN' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="mid-space" *ngIf="hasAdditionalAddressDetails && !isLcl"></div>

                    <div class="row" *ngIf="hasAdditionalAddressDetails && !isLcl">
                        <div class="col-6">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('building').touched && addPackageForm.get('building').errors)}">
                                <label class="label">
                                    {{'ADD_SHIPMENTS.BUILDING' | translate | addressSystem}}
                                </label>
                                <input formControlName="building" type="text" placeholder="" min="1">
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('flat').touched && addPackageForm.get('flat').errors)}">
                                <label class="label">
                                    {{'ADD_SHIPMENTS.FLAT_OFFICE' | translate | addressSystem}}
                                </label>
                                <input formControlName="flat" type="text" placeholder="" min="1">
                            </div>
                        </div>
                    </div>

                    <div class="space-md" *ngIf="!swapSenderAndReceiver"></div>

                    <div class="row" *ngIf="isReceivingPointEnabled && !swapSenderAndReceiver && !isLcl">
                        <div class="col is-receiving-point flex-start">
                            <p-inputSwitch formControlName="isDeliverToReceivingPoint" [(ngModel)]="isDeliverToReceivingPoint"
                                           (onChange)="updateFormValue('receiverAddress', null)"></p-inputSwitch>
                            <span>
                                {{ 'ADD_SHIPMENT.DELIVER_TO_RECEIVING_POINT' | translate }}
                            </span>
                        </div>
                    </div>

                    <div class="mid-space"></div>
                    <ng-container *ngIf="companiesWithStations.indexOf(companyId) !== -1">
                        <div class="space-md"></div>
                        <div class="row">
                            <div class="col-9 col-sm-6">
                                <div class="form-title">
                                    <div class="icon stations contain-image"></div>
                                    <span>{{'ADD_SHIPMENTS.STATIONS' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="mid-space"></div>
                        <div class="row">
                            <div class="col-6">
                                <app-auto-complete formControlName="fromHub"
                                                   field="label"
                                                   [delay]="700"
                                                   [suggestions]="fromCityList"
                                                   (onSelect)="getSuggestionTripNumber()"
                                                   (completeMethod)="getFromHubsList($event)"
                                                   [formControlObj]="addPackageForm.get('fromHub')"
                                                   [label]="'ADD_SHIPMENTS.FROM_CITY'"
                                                   #fromHub>

                                </app-auto-complete>
                            </div>
                            <div class="col-6">
                                <app-auto-complete formControlName="toHub"
                                                   field="label"
                                                   [delay]="700"
                                                   [suggestions]="fromCityList"
                                                   (onSelect)="getSuggestionTripNumber()"
                                                   (completeMethod)="getToHubsList($event)"
                                                   [formControlObj]="addPackageForm.get('toHub')"
                                                   [label]="'ADD_SHIPMENTS.TO_CITY'"
                                                   #toHub>
                                </app-auto-complete>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('expectedPickupDate').touched && addPackageForm.get('expectedPickupDate').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.EXPECTED_PICKUP_DATE' | translate}}
                                    </label>
                                    <p-calendar showButtonBar="true"
                                                appendTo="body"
                                                [showTime]="true"
                                                [timeOnly]="companiesWithDeliveryAndPickupTime.indexOf(companyId) !== -1"
                                                time formControlName="expectedPickupDate"
                                                styleClass="date-picker" icon="pi pi-calendar"
                                                (onClose)="getSuggestionTripNumber()"
                                                [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('trip').touched && addPackageForm.get('trip').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.TRIP_NUMBER' | translate}}
                                    </label>
                                    <p-dropdown
                                            formControlName="trip"
                                            [options]="tripNumbersList"
                                            [disabled]="!addPackageForm.get('expectedPickupDate').value || !addPackageForm.get('toHub').value || !addPackageForm.get('fromHub').value"
                                            styleClass="dropdown-container"
                                            placeholder="{{ 'ACTIONS.SELECT_TRIP_NUMBER' | translate }}"></p-dropdown>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                    <div class="space-md"></div>


                    <ng-container *ngIf="!isTrucking">
                        <div class="space-md"></div>
                        <div class="row">
                            <div class="col-9 col-sm-6">
                                <div class="form-title">
                                    <div class="icon get-cash contain-image"></div>
                                    <span>{{'ADD_SHIPMENTS.PAYMENT' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="mid-space"></div>

                        <div class="row">
                            <div class="col-6">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('paymentType').touched && addPackageForm.get('paymentType').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.SHIPMENT_TYPE' | translate}}
                                    </label>
                                    <p-dropdown
                                            formControlName="paymentType"
                                            [options]="shipmentTypesOptions"
                                            styleClass="dropdown-container"
                                            (onChange)="onTypeChanged($event)"
                                            placeholder="{{ 'ACTIONS.SELECT_TYPE' | translate }}"></p-dropdown>
                                </div>
                                <div class="delivery-cost"
                                     *ngIf="deliveryPrice !== -1">{{(isLcl ? 'ADD_SHIPMENTS.SHIPMENT_PRICE' : 'ADD_SHIPMENTS.DELIVERY_PRICE') | translate}}
                                    : {{deliveryPrice}}</div>
                            </div>

                            <div class="col-6" *ngIf="isLcl">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('currency').touched && addPackageForm.get('currency').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.CURRENCY' | translate}}
                                    </label>
                                    <p-dropdown
                                            formControlName="currency"
                                            [options]="currencies"
                                            styleClass="dropdown-container"
                                            placeholder="{{ 'ACTIONS.SELECT_CURRENCY' | translate }}">
                                        <ng-template let-currency pTemplate="item">
                                            <div class="country-item">
                                                <div>{{"CURRENCY.LONG_LABEL."+currency.label | translate}}</div>
                                            </div>
                                        </ng-template>

                                        <ng-template pTemplate="selectedItem">
                                            <div class="country-item" *ngIf="addPackageForm.controls.currency">
                                                <div>{{"CURRENCY.LONG_LABEL."+addPackageForm.controls.currency.value | translate}}</div>
                                            </div>
                                        </ng-template>

                                    </p-dropdown>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="['REGULAR'].indexOf(addPackageForm.controls.paymentType.value) !== -1 ||  (['SWAP', 'BRING'].indexOf(addPackageForm.controls.paymentType.value) !== -1 && customizationCompaniesService.checkCompanies(companies.EPS))">
                                <div class="check-answer">
                                    <p-checkbox [formControl]="addPackageForm.controls.isReceiverPayCost"
                                                [binary]="true"></p-checkbox>
                                    <span>{{'ADD_SHIPMENTS.CUSTOMER_WILL_PAY' | translate}}</span>
                                </div>
                            </div>

                            <div class="col-6"
                                 *ngIf="addPackageForm.controls.paymentType.value === 'COD' || addPackageForm.controls.paymentType.value === 'NON_COD' || addPackageForm.controls.paymentType.value === 'PAID_COD'">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('cod').touched && addPackageForm.get('cod').errors)}">
                                    <label class="label">
                                        {{ (isLcl ? 'ADD_SHIPMENTS.TOTAL_ORDER_PRICE' : 'ADD_SHIPMENTS.COD') | translate}}
                                    </label>
                                    <input formControlName="cod" type="number" placeholder="" (change)="updateInsuranceCod($event)">
                                </div>
                            </div>
                            <div class="col-6"
                                 *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"></div>
                            <div class="space-md"
                                 *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"></div>

                        </div>
                        <div class="swap-section">
                            <div class="row">
                                <div class="col-sm-6" *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'">
                                    <input *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"
                                           type="radio"
                                           [value]="'COLLECT'"
                                           checked
                                           name="swapOrBring"
                                           formControlName="swapOrBring"
                                           (change)="changeSwapOrBring()">
                                    <div class="input-item"
                                         [ngStyle]="{'background': this.swapOrBring === 'PAY' ? 'gainsboro' : 'white'}"
                                         [ngClass]="{'has-error': (addPackageForm.get('toCollectFromReceiver').touched && addPackageForm.get('toCollectFromReceiver').errors)}">
                                        <label class="label">
                                            {{'ADD_SHIPMENTS.TO_COLLECT_FROM_RECEIVER' | translate}}
                                        </label>
                                        <input formControlName="toCollectFromReceiver" type="number" placeholder="" min="0">
                                    </div>
                                </div>
                                <div class="col-sm-6" *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'">
                                    <input *ngIf="addPackageForm.controls.paymentType.value === 'SWAP' || addPackageForm.controls.paymentType.value === 'BRING'"
                                           type="radio"
                                           [value]="'PAY'"
                                           name="swapOrBring"
                                           formControlName="swapOrBring"
                                           (change)="changeSwapOrBring()">
                                    <div class="input-item"
                                         [ngStyle]="{'background': this.swapOrBring === 'COLLECT' ? 'gainsboro' : 'white'}"
                                         [ngClass]="{'has-error': (addPackageForm.get('toPayToReceiver').touched && addPackageForm.get('toPayToReceiver').errors)}">
                                        <label class="label">
                                            {{'ADD_SHIPMENTS.TO_PAY_TO_RECEIVER' | translate}}
                                        </label>
                                        <input formControlName="toPayToReceiver" type="number" placeholder="" min="0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>



                    <div class="space-md"></div>

                    <ng-container *ngIf="!isTrucking">
                        <div class="row">
                            <div class="col-6">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('freight').touched && addPackageForm.get('freight').errors)}">
                                    <label class="label">
                                        {{ (isLcl ? 'ADD_SHIPMENTS.SHIPMENT_COST' : 'ADD_SHIPMENTS.FREIGHT_COST') | translate}}
                                    </label>
                                    <input formControlName="freight" type="number" placeholder="" min="0">
                                </div>
                                <!--                            <div class="delivery-cost" *ngIf="deliveryPrice !== -1">{{'ADD_SHIPMENTS.DELIVERY_PRICE' | translate}}: {{deliveryPrice}}</div>-->
                            </div>
                            <div class="col-6" *ngIf="companiesWithOutCodCollectionMethod.indexOf(companyId) === -1">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('codCollectionMethod').touched && addPackageForm.get('codCollectionMethod').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENT.COD_COLLECTION_METHOD'| translate}}
                                    </label>
                                    <p-dropdown
                                            formControlName="codCollectionMethod"
                                            [options]="codCollectionMethods"
                                            styleClass="dropdown-container"
                                            placeholder="{{ 'ACTIONS.SELECT_METHOD' | translate }}">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="addPackageForm.controls.paymentType.value === 'COD' && showCodFees">
                                <div class="input-item"
                                     [ngClass]="{'has-error': (addPackageForm.get('codFees').touched && addPackageForm.get('codFees').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.COD_CASH_ON_DELIVERY_FEES'| translate}}
                                    </label>
                                    <input formControlName="codFees" type="number" placeholder="" min="0">
                                </div>
                            </div>

                            <div class="col-6" *ngIf="showTotalCOD()">
                                <div class="font-weight-bolder mt-2">
                                    <span> {{'ADD_SHIPMENTS.TOTAL_COD' | translate }}: </span>
                                    <span *ngIf="swapOrBring === 'COLLECT'"> {{addPackageForm.get('freight').value + addPackageForm.get('toCollectFromReceiver').value}} </span>
                                    <span *ngIf="swapOrBring === 'PAY'"> {{addPackageForm.get('freight').value - addPackageForm.get('toPayToReceiver').value}} </span>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="customizationCompaniesService.checkCompanies(companies.JETT)">
                                <div class="check-answer">
                                    <p-checkbox [formControl]="addPackageForm.controls.isSenderPayCost"
                                                [disabled]="isJettCompany() && (addPackageForm.controls.orderId.value === 'STORE_TO_STORE' || addPackageForm.controls.paymentType.value === 'COD_V2' || addPackageForm.controls.paymentType.value === 'COD')"
                                                [binary]="true"></p-checkbox>
                                    <span>{{'ADD_SHIPMENTS.SENDER_WILL_PAY' | translate}}</span>
                                </div>
                            </div>

                        </div>

                        <ng-container
                                *ngIf="isSupportAdditionalFees && addPackageForm.controls.paymentType.value === 'COD'">
                            <div class="row">
                                <div class="col-6 mt-2">
                                    <label class="label">
                                        {{('ADDITION_FEES.COD_VALUE' | translate) + ' : ' + (codValue  | number : digitFormat)}}
                                    </label>
                                </div>
                                <div class="col-3 mt-2">

                                    <div class="row">
                                        <div class="col is-receiving-point flex-start">
                                            <p-inputSwitch formControlName="isInsured"
                                                           (onChange)="updateInsuranceCod($event)"></p-inputSwitch>
                                            <span>
                                                {{ 'ADDITION_FEES.ENABLE_INSURANCE' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 mt-2"
                                     *ngIf="addPackageForm.controls.isInsured.value">
                                    <label class="label">
                                        {{('ADDITION_FEES.INSURANCE_VALUE'| translate) + ' : ' + (insuranceValue  | number : digitFormat)}}
                                    </label>
                                </div>
                            </div>

                        </ng-container>

                        <div class="space-md"></div>

<!--                        service type section -->

                        <div class="row">
                            <div class="col-9 col-sm-6">
                                <div class="form-title">
                                    <div class="icon service-details contain-image"></div>
                                    <span>{{'ADD_SHIPMENTS.SERVICE_AND_ORDER' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="mid-space"></div>

                        <div *ngIf="!isLcl" class="row">
                            <ng-container  *ngIf="companyId == 64 ; then invoiceTemplate; else serviceTypeTemplate">
                            </ng-container>
                        </div>
                        <div class="space-md"></div>
                    </ng-container>


                    <div class="row" [ngClass]="{'flex-row-reverse': companiesWithDeliveryAndPickupTime.indexOf(companyId) !== -1}">
                        <div class="col-6" *ngIf="!isLcl">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('expectedDeliveryDate').touched && addPackageForm.get('expectedDeliveryDate').errors)}">
                                <label class="label">
                                    {{'ADD_SHIPMENTS.EXPECTED_ARRIVAL' | translate}}
                                </label>
                                <p-calendar showButtonBar="true"
                                            panelStyleClass="add-package-calender"
                                            appendTo="body" [showTime]="true"
                                            [timeOnly]="companiesWithDeliveryAndPickupTime.indexOf(companyId) !== -1"
                                            formControlName="expectedDeliveryDate" styleClass="date-picker"
                                            icon="pi pi-calendar" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                        </div>
                        <div class="col-6" *ngIf="companiesWithStations.indexOf(companyId) === -1">
                            <div class="input-item"
                                 [ngClass]="{'has-error': (addPackageForm.get('expectedPickupDate').touched && addPackageForm.get('expectedPickupDate').errors)}">
                                <label class="label">
                                    {{'ADD_SHIPMENTS.EXPECTED_PICKUP_DATE' | translate}}
                                </label>
                                <p-calendar showButtonBar="true" [timeOnly]="companiesWithDeliveryAndPickupTime.indexOf(companyId) !== -1"
                                            appendTo="body"
                                            time formControlName="expectedPickupDate" styleClass="date-picker" icon="pi pi-calendar" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                        </div>
                    </div>

                    <div class="space-md"></div>
                    <div class="row">
                        <ng-container  *ngIf="companyId == 64 ; then serviceTypeTemplate; else invoiceTemplate">
                        </ng-container>

                    </div>

                    <ng-container  *ngIf="isHideDetailInAddingPackage">
                        <app-field-input  formControlName="notes"
                                    [fieldType]="FieldType.TEXT_AREA"
                                    [formControlObj]="addPackageForm.get('notes')"
                                    [label]="'ADD_SHIPMENTS.NOTES'"
                                    (whenFocus)="preventSubmitWhenPressEnter = true"
                                    (whenBlur)="preventSubmitWhenPressEnter = false"></app-field-input>
                    </ng-container>

                    <div class="space-md"></div>
                    <div class="space-md"></div>
                    <ng-container *ngIf="isHideDetailInAddingPackage">
                        <div class="submit-container" [ngClass]="{'buzz-company': companyId === 79, 'ghost-company': companyId == 20, 'vee-company': companyId == 139}">
                            <button type="submit" class="submit" *ngIf="!isSubmittingPackage" (click)="submitForm()"  [ngClass]="{'disabled disabled-btn': attachmentsUploader?.loading}">
                                <img src="../../assets/icons/add_package/send.svg" alt="">
                                <span *ngIf="!packageBarcode">{{'ADD_SHIPMENTS.SEND_PACKAGE' | translate | packageTitle}}</span>
                                <span *ngIf="packageBarcode">{{'ADD_SHIPMENTS.EDIT_PACKAGE' | translate | packageTitle}}</span>
                            </button>
                            <i *ngIf="isSubmittingPackage" class="fas fa-spinner fa-pulse"></i>
                        </div>

                        <div class="mid-space"></div>

                        <div class="add-more-packages" *ngIf="(isCloning || !isEditMode) && !isLcl">
                            <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.PRINT' | translate }}</span>
                            <p-inputSwitch formControlName="directPrint" (onChange)="selectSavingOption('directPrint')"></p-inputSwitch>
                        </div>
                        <div class="add-more-packages" *ngIf="isCloning || !isEditMode">
                            <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.SAVE' | translate }}</span>
                            <p-inputSwitch formControlName="onlySave" (onChange)="selectSavingOption('onlySave')"></p-inputSwitch>
                        </div>
                        <div class="add-more-packages" *ngIf="(isCloning || !isEditMode) && !isLcl" style="padding-bottom: 20px">
                            <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.SAVE_AND_CONTINUE' | translate }}</span>
                            <p-inputSwitch formControlName="saveAndContinue" (onChange)="selectSavingOption('saveAndContinue')"></p-inputSwitch>
                        </div>
                    </ng-container>

                    <div class="space-md"></div>

                    <div *ngIf="isLcl" class="row">
                        <div class="col-9 col-sm-6">
                            <div class="form-title">
                                <div class="icon attachments-details contain-image">
                                    <i class="fas fa-paperclip"></i>
                                </div>
                                <span>{{'ADD_SHIPMENTS.ATTACHMENTS' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isLcl" class="mid-space"></div>

                    <div class="row" *ngIf="isLcl">
                        <app-file-uploader [isLcl]="isLcl" #attachmentsUploader></app-file-uploader>
                    </div>

                    <div class="space-md" *ngIf="isTrucking"></div>

                    <div class="row" *ngIf="isTrucking">
                        <div class="col-12">
                            <div class="form-title">
                                <div class="icon service-details contain-image"></div>
                                <span>{{'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.MENU_ITEM_LABEL' | translate | packageTitle}}</span>
                            </div>
                            <div class="space-md"></div>
                            <div class="package-content-container">
                                <app-package-contents-list [isTrucking]="true" (selectedContents)="setPackageContents($event)" [currentPackageContents]="selectedPackageContents"></app-package-contents-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="yellow-section" *ngIf="!isHideDetailInAddingPackage">

                <div class="row">
                    <div class="col-9 col-9">
                        <div class="form-title">
                            <div class="icon service-details contain-image"></div>
                            <span>{{'ADD_SHIPMENTS.PACKAGE_DETAILS' | translate | packageTitle}}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLcl" class="space-md"></div>

                <div *ngIf="isLcl" class="row">
                    <div class="col-6">
                        <div class="action-lcl-items" (click)="addLclItems()">{{ 'ADD_SHIPMENT.LCL.ADD_ITEMS' | translate }}</div>
                    </div>
                </div>

                <div class="space-md"></div>

                <div *ngIf="!isLcl && !isTrucking" class="row align-items-baseline">
                    <div class="col-6">
                        <div class="input-item package-numbers"
                             [ngClass]="{'has-error': (addPackageForm.get('quantity').touched && addPackageForm.get('quantity').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.NUM_OF_PACKAGES' | translate | packageTitle}}
                            </label>
                            <div class="input-disabled-container">
                                <div class="drape"></div>
                                <input formControlName="quantity" type="number" value="0" min="0">
                            </div>
                            <button class="add-btn" type="button" (click)="increasePackageCount('quantity')"><i class="fas fa-plus"></i></button>
                            <button class="subtract-btn" type="button" (click)="decreasePackageCount('quantity')"><i class="fas fa-minus"></i></button>
                        </div>
                    </div>
                    <div class="col-6">
<!--                        <div class="field-input-item"-->
<!--                             [ngClass]="{'has-error': (addPackageForm.get('parcelId').touched && addPackageForm.get('parcelId').errors)}">-->
<!--                            <p-dropdown-->
<!--                                    [(ngModel)]="parcelTypeId"-->
<!--                                    formControlName="parcelId"-->
<!--                                    [options]="parcelsOptions"-->
<!--                                    [showClear]="true"-->
<!--                                    (onChange)="getDeliveryPrice()"-->
<!--                                    styleClass="parcel-dropdown"-->
<!--                                    placeholder="{{ 'ADD_SHIPMENTS.CHOOSE_PARCEL_TYPE' | translate | packageTitle}}"></p-dropdown>-->
<!--                        </div>-->

                        <app-drop-down
                                [(ngModel)]="parcelTypeId"
                                formControlName="parcelId"
                                [formControlObj]="addPackageForm.get('parcelId')"
                                [showLabel]="false"
                                [options]="parcelsOptions"
                                (whenChange)="getDeliveryPrice()"
                                placeholder="{{ 'ADD_SHIPMENTS.CHOOSE_PARCEL_TYPE' | translate | packageTitle}}"></app-drop-down>
                    </div>
                </div>

                <div *ngIf="!isLcl" class="space-md"></div>

                <div class="row">
                    <div class="col-12">
                        <app-field-input  formControlName="notes"
                                    [fieldType]="FieldType.TEXT_AREA"
                                    [formControlObj]="addPackageForm.get('notes')"
                                    [rows]="6"
                                    [label]="'ADD_SHIPMENTS.NOTES'"
                                    (whenFocus)="preventSubmitWhenPressEnter = true"
                                    (whenBlur)="preventSubmitWhenPressEnter = false"></app-field-input>

                    </div>
                </div>

                <div class="space-md"></div>


                <div *ngIf="!isLcl && !isSupportDeliveringPackageItemsPartially" class="row">
                    <div class="col">
                        <div class="input-item note-section"
                             [ngClass]="{'has-error': (addPackageForm.get('description').touched && addPackageForm.get('description').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.DESCRIPTIONS' | translate | packageTitle}}
                            </label>
                            <textarea class="small" formControlName="description" (focus)="preventSubmitWhenPressEnter = true"
                                      (blur)="preventSubmitWhenPressEnter = false" cols="30" rows="3"></textarea>

                        </div>
                        <div class="invalid-message invalid-container">
                            <span *ngIf="isInvalidWithError('description', 'maxlength')">
                            <span>
                              {{ 'GENERAL.ROW_MAX_LENGTH' | translate: { max: 200} }}
                            </span>
                            </span>

                            <span *ngIf="isInvalidWithError('description', 'required')">
                            <span>
                              {{ 'GENERAL.REQUIRED_VALIDATION' | translate}}
                            </span>
                            </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="!isLcl && !isSupportDeliveringPackageItemsPartially" class="space-md"></div>

                <div *ngIf="isSupportDeliveringPackageItemsPartially" class="row">
                    <div class="col">
                        <div class="input-item note-section"
                             [ngClass]="{'has-error': (addPackageForm.get('description').touched && addPackageForm.get('description').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.DESCRIPTIONS_REQUIRED' | translate | packageTitle}}
                            </label>
                            <app-package-content-last-mile (selectedContents)="setPackageContents($event)" [currentPackageContents]="selectedPackageContents" #pc></app-package-content-last-mile>
                        </div>
                        <div class="invalid-message invalid-container">
                        </div>
                    </div>
                </div>

                <div *ngIf="isSupportDeliveringPackageItemsPartially" class="space-md"></div>

                <div class="row">
                    <div class="col">
                        <app-field-input formControlName="weight"
                                   [formControlObj]="addPackageForm.get('weight')"
                                   (whenFocus)="changePreventSubmitWhenPressEnter(true)"
                                   [inputValidation]="fieldValidation.DECIMAL"
                                   [label]="'ADD_SHIPMENTS.PACKAGE_WEIGHT'"
                                   (whenBlur)="changePreventSubmitWhenPressEnter(false)"
                                   (whenChange)="calculateCostPerWeight ? getDeliveryPrice() : null"></app-field-input>
                    </div>
                </div>
                <div class="row" *ngIf="isJettCompany()">
                    <div class="col">
                        <app-field-input formControlName="width"
                                   [formControlObj]="addPackageForm.get('width')"
                                   (whenFocus)="changePreventSubmitWhenPressEnter(true)"
                                   [inputValidation]="fieldValidation.DECIMAL"
                                   [label]="'ADD_SHIPMENTS.PACKAGE_WIDTH'"
                                   (whenBlur)="changePreventSubmitWhenPressEnter(false)" (whenChange)="getDeliveryPrice()"></app-field-input>
                    </div>
                </div>

                <div class="row" *ngIf="isJettCompany()">
                    <div class="col">
                        <app-field-input formControlName="height"
                                   [formControlObj]="addPackageForm.get('height')"
                                   (whenFocus)="changePreventSubmitWhenPressEnter(true)"
                                   [inputValidation]="fieldValidation.DECIMAL"
                                   [label]="'ADD_SHIPMENTS.PACKAGE_HEIGHT'"
                                   (whenBlur)="changePreventSubmitWhenPressEnter(false)" (whenChange)="getDeliveryPrice()"></app-field-input>
                    </div>
                </div>

                <div class="row" *ngIf="isJettCompany()">
                    <div class="col">
                        <app-field-input formControlName="length"
                                   [formControlObj]="addPackageForm.get('length')"
                                   (whenFocus)="changePreventSubmitWhenPressEnter(true)"
                                   [inputValidation]="fieldValidation.DECIMAL"
                                   [label]="'ADD_SHIPMENTS.PACKAGE_LENGTH'"
                                   (whenBlur)="changePreventSubmitWhenPressEnter(false)" (whenChange)="getDeliveryPrice()"></app-field-input>
                    </div>
                </div>

                <div class="row" *ngIf="isJettCompany()">
                    <div class="col-12 font-weight-bolder mt-2">
                        <span> {{'ADD_SHIPMENTS.VOLUMETRIC_WEIGHT' | translate }}: </span>
                        <span> {{addPackageForm.get('width').value * addPackageForm.get('length').value * addPackageForm.get('height').value / 5000}} </span>
                    </div>
                </div>



                <div *ngIf="!isLcl" class="space-md"></div>

                <div *ngIf="!isLcl" class="row" style="margin-top: 10px">
                    <div class="col">
                        <div class="input-item note-section"
                             [ngClass]="{'has-error': (addPackageForm.get('adminNotes').touched && addPackageForm.get('adminNotes').errors)}">
                            <label class="label">
                                {{'ADD_SHIPMENTS.PRIVATE_NOTES' | translate}}
                            </label>
                            <input formControlName="adminNotes" (focus)="preventSubmitWhenPressEnter = true"
                                   (blur)="preventSubmitWhenPressEnter = false">
                        </div>
                    </div>
                </div>
                <div *ngIf="!isLcl" class="row my-3">
                    <div class="col add-attachment">
                        <div class="user-name input-filed-container" style="min-height: 30px">
                            <div class="input-label" [ngClass]="{'d-flex justify-content-between': files.length}"><div>{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ADD_ATTACHMENTS' | translate }}</div>
                                <div *ngIf="files.length" class="uploaded-attachments-number flex-2d-center pl-4 pr-4">

                                </div>
                            </div>
                            <div class="input-value">
                                <div class="uploaded-attachments-container d-flex" *ngIf="files.length">
                                    <div class="view-add-reply-attachments d-flex" *ngIf="!files.length">
                                        <div class="uploaded-attachments-number flex-2d-center">
                                            <div class="attachments-label">
                                                <span>{{'GENERAL.ATTACHMENTS' | translate}}</span>
                                            </div>
                                            <div class="attachments-number flex-2d-center">
                                                <span>{{files.length}}</span>
                                            </div>

                                        </div>
                                        <div class="flex-1 attachment-line-container">
                                            <div class="attachment-line"></div>
                                        </div>
                                    </div>
                                    <div class="uploaded-attachments-list flex-column d-flex w-100">
                                        <div class="uploaded-attachment d-flex cursor-pointer justify-content-between w-100" *ngFor="let attachment of files">
                                            <div class="attachment-type-and-size d-flex">
                                                <div class="attachment-type">
                                                    <span>{{attachment.name}}</span>
                                                </div>
                                                <div class="attachment-size">
                                                    <span>({{attachment.size}} KB)</span>
                                                </div>
                                            </div>
                                            <div class="remove-attachment" (click)="removeAttachment(attachment)">
                                                <img src="../../assets/icons/ticketing-system/ticket-details/x-icon.png"
                                                     alt=""/>
                                            </div>
                                        </div>
                                        <div class="icon" style="position: initial;top:initial" *ngIf="files.length < (5 - numberOfFiles) && files.length > 0">
                                            <img (click)="fileChooser.click()" src="../../../../assets/icons/attachments.svg" alt="icon"  style="margin: 0"/>
                                        </div>

                                    </div>
                                </div>
                                <div class="middle" *ngIf="!files.length">
                                    <!--                                                <field-input pInputText  [ngModel]="attachments"/>-->
                                    <div class="icon">
                                        <img *ngIf="!isEditMode" (click)="fileChooser.click()" src="../../../../assets/icons/attachments.svg" alt="icon" />
                                        <img *ngIf="isEditMode && 5-numberOfFiles > 0" (click)="fileChooser.click()" src="../../../../assets/icons/attachments.svg" alt="icon" [pTooltip]="cannotAddFiles[1]" tooltipPosition="top" />
                                        <img *ngIf="isEditMode && files.length >= 5 - numberOfFiles" src="../../../../assets/icons/attachments.svg" alt="icon" [pTooltip]="cannotAddFiles[0]" tooltipPosition="top" />
                                    </div>
                                </div>

                                <input type="file" accept="image/*" multiple (change)="fileChange($event)" class="d-none" #fileChooser>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!isLcl" class="space-md"></div>

                <div class="row">
                    <div class="col-2">
                        <p-inputSwitch formControlName="isFragile"></p-inputSwitch>
                    </div>
                    <div class="col-4">
                        {{'ADD_SHIPMENTS.FRAGILE' | translate}}
                    </div>

                    <div class="col-2">
                        <p-inputSwitch formControlName="isNeedPackaging"></p-inputSwitch>
                    </div>
                    <div class="col-4">
                        {{'ADD_SHIPMENTS.NEEDS_PACKAGING' | translate}}
                    </div>
                </div>
                <div class="small-space"></div>
                <div class="row">
                    <div class="col-2">
                        <p-inputSwitch formControlName="isflammable"></p-inputSwitch>
                    </div>
                    <div class="col-4">
                        {{'ADD_SHIPMENTS.FLAIMABLE' | translate}}
                    </div>
                    <div *ngIf="allowAddingPackageWithFingerprint()" class="col-2">
                        <p-inputSwitch formControlName="isFingerprintRequired"></p-inputSwitch>
                    </div>
                    <div *ngIf="allowAddingPackageWithFingerprint()" class="col-4">
                        {{'ADD_SHIPMENTS.FINGER_PRINT' | translate}}
                    </div>
                </div>
                <div class="small-space"></div>

                <div class="row">
                    <ng-container *ngIf="companiesWithCooling.indexOf(companyId) !== -1">

                        <div class="col-2">
                            <p-inputSwitch formControlName="isNeedCooling"></p-inputSwitch>
                        </div>
                        <div class="col-4">
                            {{'ADD_SHIPMENTS.COOLING' | translate}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="companiesWithMeasurement.indexOf(companyId) !== -1">

                        <div class="col-2">
                            <p-inputSwitch formControlName="isNeedMeasurement"></p-inputSwitch>
                        </div>
                        <div class="col-4">
                            {{'ADD_SHIPMENTS.MEASUREMENT' | translate}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="companiesWithPreview.indexOf(companyId) !== -1">

                        <div class="col-2">
                            <p-inputSwitch formControlName="isNeedPreview"></p-inputSwitch>
                        </div>
                        <div class="col-4">
                            {{'ADD_SHIPMENTS.PREVIEW' | translate}}
                        </div>
                    </ng-container>
                    <ng-container>

                        <div class="col-2">
                            <p-inputSwitch formControlName="isDestructible"></p-inputSwitch>
                        </div>
                        <div class="col-4">
                            {{'ADD_SHIPMENTS.DESTRUCTIBLE' | translate}}
                        </div>
                    </ng-container>
                    <ng-container>

                        <div class="col-2">
                            <p-inputSwitch formControlName="isPreventOpen"></p-inputSwitch>
                        </div>
                        <div class="col-4">
                            {{'ADD_SHIPMENTS.PREVENT_OPENING' | translate}}
                        </div>
                    </ng-container>
                    <ng-container>

                        <div class="col-2">
                            <p-inputSwitch formControlName="isPreventMeasuring"></p-inputSwitch>
                        </div>
                        <div class="col-4">
                            {{'ADD_SHIPMENTS.PREVENT_MEASURING' | translate}}
                        </div>
                    </ng-container>
                </div>
                <div class="submit-container" [ngClass]="{'buzz-company': companyId === 79, 'ghost-company': companyId == 20, 'vee-company': companyId == 139}">
                    <button type="submit" class="submit" *ngIf="!isSubmittingPackage" (click)="submitForm()"  [ngClass]="{'disabled disabled-btn': attachmentsUploader?.loading}">
                        <img src="../../assets/icons/add_package/send.svg" alt="">
                        <span *ngIf="!packageBarcode">{{'ADD_SHIPMENTS.SEND_PACKAGE' | translate | packageTitle}}</span>
                        <span *ngIf="packageBarcode">{{'ADD_SHIPMENTS.EDIT_PACKAGE' | translate | packageTitle}}</span>
                    </button>
                    <i *ngIf="isSubmittingPackage" class="fas fa-spinner fa-pulse"></i>
                </div>

                <div class="mid-space"></div>

                <div class="add-more-packages" *ngIf="(isCloning || !isEditMode) && !isLcl && !(companyId === 64)">
                    <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.PRINT' | translate }}</span>
                    <p-inputSwitch formControlName="directPrint" (onChange)="selectSavingOption('directPrint')"></p-inputSwitch>
                </div>
                <div class="add-more-packages" *ngIf="isCloning || !isEditMode">
                    <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.SAVE' | translate }}</span>
                    <p-inputSwitch formControlName="onlySave" (onChange)="selectSavingOption('onlySave')"></p-inputSwitch>
                </div>
                <div class="add-more-packages" *ngIf="(isCloning || !isEditMode) && !isLcl" style="padding-bottom: 20px">
                    <span>{{ 'QUICK_ADD.CLOSE_OPTIONS.SAVE_AND_CONTINUE' | translate }}</span>
                    <p-inputSwitch formControlName="saveAndContinue" (onChange)="selectSavingOption('saveAndContinue')"></p-inputSwitch>
                </div>
                <div class="mid-space"></div>
                <div class="mid-space"></div>
                <div class="mid-space-mobile"></div>
            </div>
            <!--  Sender Address Book -->
            <p-overlayPanel #receiverAddressBook
                            class="receiver-address-panel"
                            [dismissable]="true"
                            (onHide)="onHideReceiverAddressBook()"
                            [showCloseIcon]="false">
                <div class="text-center" *ngIf="isLoadingReceiverAddressBook == spinnerStates.LOADING">
                    <i class="fas fa-spinner fa-spin"></i>
                </div>
                <div *ngIf="isLoadingReceiverAddressBook !== spinnerStates.LOADING">


                    <div *ngIf="selectedTab == 'MAIN_TAB'" style="display: flex; flex-direction: column;">
                        <div class="title">
                            <div class="label" *ngIf="swapSenderAndReceiver">{{'USER_INFORMATION.SENDER_ADDRESSES' | translate}}</div>
                            <div class="label" *ngIf="!swapSenderAndReceiver">{{'USER_INFORMATION.RECEIVER_ADDRESSES' | translate}}</div>
                            <div class="add-new-address">
                                <img src="../../assets/icons/add_package/add.svg">
                                <span class="add-address-label label" (click)="toggleSelectedTab()">{{'ADD_SHIPMENTS.ADD_ADDRESS' | translate}}</span>
                            </div>
                        </div>

                        <div class="empty-msg" *ngIf="isLoadingReceiverAddressBook == spinnerStates.EMPTY">
                            {{'USER_INFORMATION.NO_ADDRESSES' | translate}}
                        </div>

                        <div class="row" *ngFor="let address of receiverAddresses;let i = index">
                            <div class="col-1">
                                <p-radioButton name="selectedAddress"
                                               [ngModelOptions]="{standalone: true}"
                                               [value]="i"
                                               [(ngModel)]="tempSelectedReceiverAddressIndex">
                                </p-radioButton>
                            </div>
                            <div class="col-8">
                                <div class="address-details">
                                    <div>{{address.firstName}} {{address.businessName? ' - ' + address.businessName: ''}}</div>
                                    <div>{{address?.address?.region}} - {{address?.address?.city}} - {{address?.address?.village}}</div>
                                    <div>{{address.phone}}</div>
                                </div>
                            </div>
                            <div class="col-1">
                                <img src="../../assets/icons/add_package/edit.svg"
                                     class="cursor-pointer"
                                     (click)="showEditAddress(i)">
                            </div>
                            <div class="col-1">
                                <img src="../../assets/icons/add_package/remove.svg"
                                     *ngIf="!address.isRemovingSenderAddress"
                                     class="cursor-pointer"
                                     (click)="removeAddress(i)">
                                <i class="fas fa-spinner fa-pulse" *ngIf="address.isRemovingSenderAddress"></i>
                            </div>
                        </div>
                        <br>
                        <div class="footer-buttons">
                            <div class="row">
                                <div class="col"><button class="btn-grid" (click)="setNewReceiverInfo()">{{ 'ACTIONS.DONE' | translate }}</button></div>
                                <div class="col"><span class="btn-outline" (click)="closeReceiverInfo()">{{ 'ACTIONS.CANCEL' | translate }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedTab == 'SECONDARY_TAB'">
                        <form [formGroup]="receiverLocationForm">
                            <div class="secondary-title">
                        <span class="back-btn" (click)="toggleSelectedTab()">
                            <i *ngIf="isArabic" class="fas fa-chevron-right"></i>
                            <i *ngIf="!isArabic" class="fas fa-chevron-left"></i>
                            {{ 'ACTIONS.BACK' | translate }}
                        </span>
                                <span *ngIf="isEditAddressBook">{{'ADD_SHIPMENTS.EDIT_ADDRESS' | translate}}</span>
                                <span *ngIf="!isEditAddressBook">{{'ADD_SHIPMENTS.ADD_ADDRESS' | translate}}</span>
                            </div>

                            <div>

                                <div class="input-item"
                                     [ngClass]="{'has-error': (receiverLocationForm.get('firstName').touched && receiverLocationForm.get('firstName').errors)}">
                                    <label class="label" *ngIf="swapSenderAndReceiver">{{'USER_INFORMATION.SENDER_NAME' | translate}}</label>
                                    <label class="label" *ngIf="!swapSenderAndReceiver">{{'USER_INFORMATION.RECEIVER_NAME' | translate}}</label>
                                    <input formControlName="firstName" type="text" placeholder="" min="1">
                                </div>

                                <div class="small-space"></div>

                                <div class="input-item auto-complete-field receiver-address"
                                     [ngClass]="{'has-error': (receiverLocationForm.get('address').touched && receiverLocationForm.get('address').errors)}">
                                    <label class="label">
                                        {{'ADMINISTRATION.ADDRESS' | translate}}
                                    </label>
                                    <app-input-field-component [form]="receiverLocationForm"
                                                               controlName="address"
                                                               [dataKey]="'value'"
                                                               controlType="autoComplete"
                                                               field="label"
                                                               [searchSuggestions]="combinedOptions"
                                                               [options]="combinedOptions"
                                                               (onSearch)="initVillage(0, null, $event.query)"
                                                               [forceSelection]="true"
                                                               [className]="'style-free-field-input'"
                                                               [displayDropDown]="true">
                                    </app-input-field-component >
                                </div>

                                <div class="small-space"></div>

                                <div class="input-item"
                                     [ngClass]="{'has-error': (receiverLocationForm.get('addressLine1').touched && receiverLocationForm.get('addressLine1').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.ADDRESS_DETAILS' | translate}}
                                    </label>
                                    <input formControlName="addressLine1" type="text" placeholder="" min="1">
                                </div>

                                <div class="small-space"></div>
                                <div class="input-item"
                                     [ngClass]="{'has-error': (receiverLocationForm.get('phone1').touched && receiverLocationForm.get('phone1').errors)}">
                                    <label class="label">
                                        {{'ADMINISTRATION.PHONE_NUMBER' | translate }}
                                    </label>
                                    <input formControlName="phone1" type="text" placeholder="" min="1">
                                </div>

                                <div class="small-space"></div>

                                <div class="input-item"
                                     [ngClass]="{'has-error': (receiverLocationForm.get('businessName').touched && receiverLocationForm.get('businessName').errors)}">
                                    <label class="label">
                                        {{'USER_INFORMATION.COMERCIAL_NAME' | translate}}
                                    </label>
                                    <input formControlName="businessName" type="text" placeholder="" min="1">
                                </div>

                                <div class="small-space"></div>

                                <div class="input-item"
                                     [ngClass]="{'has-error': (receiverLocationForm.get('email').touched && receiverLocationForm.get('email').errors)}">
                                    <label class="label">
                                        {{'ADD_SHIPMENTS.EMAIL' | translate}}
                                    </label>
                                    <input formControlName="email" type="text" placeholder="" min="1">
                                </div>
                            </div>

                            <div class="space-md"></div>

                            <div class="footer-buttons">
                                <div class="row" *ngIf="!isSubmittingSenderAddress">
                                    <div class="col"><button class="btn-grid" (click)="addEditReceiverAddress()">{{ 'ACTIONS.DONE' | translate }}</button></div>
                                    <div class="col"><span class="btn-outline" (click)="closeReceiverInfo()">{{ 'ACTIONS.CANCEL' | translate }}</span></div>
                                </div>
                                <div class="text-center" *ngIf="isSubmittingSenderAddress">
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </p-overlayPanel>
        </div>
    </form>
</p-sidebar>

<ng-template #invoiceTemplate>
    <div class="col-6">
        <div class="input-item invoice-number-container"
             [ngClass]="{'has-error': (addPackageForm.get('invoiceNumber').touched && addPackageForm.get('invoiceNumber').errors)}">
            <label class="label">
                {{'ADD_SHIPMENTS.INVOICE_NUMBER' | translate}}
            </label>
            <input [formControl]="addPackageForm.get('invoiceNumber')" type="text" placeholder="" min="1">
        </div>
    </div>
</ng-template>

<ng-template #serviceTypeTemplate>
    <div class="col-6">
        <div class="input-item"
             [ngClass]="{'has-error': (addPackageForm.get('serviceType').touched && addPackageForm.get('serviceType').errors)}">
            <label class="label">
                {{'ADD_SHIPMENTS.SERVICE_TYPE' | translate}}
            </label>
            <p-dropdown *ngIf="!isPricingPerServiceTypeEnabled"
                        [formControl]="addPackageForm.get('service')"
                        [options]="shipmentServicesOptions"
                        (onChange)="onServiceTypeChange($event)"
                        styleClass="select-input-new-class"
                        placeholder="{{ 'ACTIONS.SELECT_SERVICE' | translate }}"></p-dropdown>
            <p-autoComplete *ngIf="isPricingPerServiceTypeEnabled" [formControl]="addPackageForm.get('serviceType')"
                            type="text"
                            [forceSelection]="true"
                            [delay]="700"
                            [disabled]="!addPackageForm.get('customerInfo').value"
                            [suggestions]="serviceTypesAcList"
                            (completeMethod)="getServiceTypes($event)"
                            (onSelect)="chooseServiceType($event, true, true)"
                            styleClass="souq-selection"
                            #serviceTypeAC
            >
                <ng-template let-serviceType pTemplate="item">
                    <div class="store-item">
                        <div class="store-name text-ellipsis">{{isArabic? serviceType.arabicName : serviceType.name}}</div>
                    </div>
                </ng-template>
            </p-autoComplete>
            <div *ngIf="isPricingPerServiceTypeEnabled && addPackageForm.get('customerInfo').value">
                <i class="fas fa-chevron-down arrow-icon" *ngIf="!editServiceType" (click)="editSelectedServiceType()"></i>
                <i class="fas fa-times close-refresh-soup" *ngIf="editServiceType" (click)="resetServiceType()"></i>
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="showVehicleTypesList">
        <div class="input-item"
             [ngClass]="{'has-error': (addPackageForm.get('vehicleType').touched && addPackageForm.get('vehicleType').errors)}">
            <label class="label">
                {{'ADD_SHIPMENTS.VEHICLE_TYPE' | translate}}
            </label>
            <p-autoComplete [formControl]="addPackageForm.get('vehicleType')"
                            type="text"
                            [forceSelection]="true"
                            [delay]="700"
                            [suggestions]="vehicleTypesList"
                            (completeMethod)="getVehicleTypes($event)"
                            (onSelect)="chooseVehicleType($event, true)"
                            styleClass="souq-selection"
                            #vehicleTypeAC
            >
                <ng-template let-type pTemplate="item">
                    <div class="store-item">
                        <div class="store-name text-ellipsis">{{type.name}}</div>
                    </div>
                </ng-template>
            </p-autoComplete>
            <div>
                <i class="fas fa-chevron-down arrow-icon" *ngIf="!editVehicleType" (click)="editSelectedVehicleType()"></i>
                <i class="fas fa-times close-refresh-soup" *ngIf="editVehicleType" (click)="resetVehicleType()"></i>
            </div>
        </div>
    </div>
</ng-template>
