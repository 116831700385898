import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from '../../dashboard/dashboard.component';
import {AddShipmentComponent} from '../../shipment/add-shipment/add-shipment.component';
import {ManageShipmentsComponent} from '../../shipment/manage-shipments/manage-shipments.component';
import {LclContainersManagementComponent} from '../../lcl-containers-management/lcl-containers-management.component';
import {ReturnedPackagesComponent} from '../../shipment/returned-packages/returned-packages.component';
import {PendingApprovalComponent} from '../../pending-approval/pending-approval.component';
import {DraftPickupsComponent} from '../../shipment/draft-pickups/draft-pickups.component';
import {HubsReportsComponent} from '../../current-reports/hubs-reports/hubs-reports.component';
import {PartnersReportsComponent} from '../../current-reports/partners-reports/partners-reports.component';
import {DriversReportComponent} from '../../current-reports/drivers-reports/drivers-report.component';
import {FinancialReportComponent} from '../../financial-report/financial-report.component';
import {ReceiveDriverComponent} from '../../shipment/receive-driver/receive-driver.component';
import {UnloadReturnedPackagesComponent} from '../../shipment/unload-returned-packages/unload-returned-packages.component';
import {ReturnPackagesComponent} from '../../shipment/return-packages/return-packages.component';
import {PackageHistoryComponent} from '../../shipment/package-history/package-history.component';
import {ChangeStatusPackagesTableComponent} from '../../shipment/change-status-packages-table/change-status-packages-table.component';
import {UnloadPackagesTableComponent} from '../../shipment/unload-packages-table/unload-packages-table.component';
import {BranchesManagementComponent} from '../../branches/branches-management/branches-management.component';
import {ShelfPackagesComponent} from '../../branches/shelf-packages/shelf-packages.component';
import {ContainersManagementComponent} from '../../containers/containers-management/containers-management.component';
import {UsersManagementComponent} from '../../users/users-management/users-management.component';
import {PricingsComponent} from '../../pricings/pricings.component';
import {ShippingsRateComponent} from '../../shippings-rate/shippings-rate.component';
import {PricingTypeComponent} from '../../shippings-rate/components/pricing-type/pricing-type.component';
import {CustomersPricingTableComponent} from '../../shippings-rate/components/customers-pricing-table/customers-pricing-table.component';
import {PricingListsComponent} from '../../shippings-rate/components/pricing-lists/pricing-lists.component';
import {ShippingLinesComponent} from '../../administration/components/shipping-lines/shipping-lines.component';
import {ArchivedShipmentsComponent} from '../../administration/components/archeived-shipments/archived-shipments.component';
import {AccountComponent} from '../../account/account.component';
import {ReceivePackagesTableComponent} from '../../shipment/receive-packages-table/receive-packages-table.component';
import {HubsCustodyComponent} from '../../shipment/hubs-custody/hubs-custody.component';
import {SortPackagesTableComponent} from '../../shipment/sort-packages-table/sort-packages-table.component';
import {PrintedReportsComponent} from '../../users/printed-reports/printed-reports.component';
import {CodMismatchComponent} from '../../shipment/cod-mismatch/cod-mismatch.component';
import {DelayedCodReportComponent} from '../../users/delayed-cod-report/delayed-cod-report.component';
import {ExpensesComponent} from '../../shipment/expenses/expenses.component';
import {RoleGuard} from '../../shared/guards/role.guard';
import {InboxComponent} from '../../inbox/inbox.component';
import {ManageZonesComponent} from '../../administration/components/manage-zones/manage-zones.component';
import {ManageServiceTypesComponent} from '../../administration/components/manage-service-types/manage-service-types.component';
import {ManagePackageContentsComponent} from '../../administration/components/manage-package-contents/manage-package-contents.component';
import {DetailedReportsComponent} from '../../administration/components/detailed-reports/detailed-reports.component';
import {
    CustomerPackagesDetailedReportComponent
} from '../../administration/components/detailed-reports/customer-packages-detailed-report/customer-packages-detailed-report.component';
import {
    IdleCustomersReportComponent
} from '../../administration/components/detailed-reports/idle-customers-report/idle-customers-report.component';
import {DelayedPackagesReportComponent} from '../../shipment/delayed-packages-report/delayed-packages-report.component';
import {
    DriverPackagesDetailedReportComponent
} from '../../administration/components/detailed-reports/driver-packages-detailed-report/driver-packages-detailed-report.component';
import {SummaryReportsComponent} from '../../administration/components/summary-reports/summary-reports.component';
import {AdministrativeReportsComponent} from '../../administration/components/administrative-reports/administrative-reports.component';
import {BankTransfersComponent} from '../../administration/bank-transfers/bank-transfers.component';
import {DriversEarningsComponent} from '../../shippings-rate/components/drivers-earnings/drivers-earnings.component';
import {WorkZoneComponent} from '../../shippings-rate/components/work-zone/work-zone.component';
import {DriverPackagesComponent} from '../../users/driver-packages/driver-packages.component';
import {ContainerPackagesComponent} from '../../containers/container-packages/container-packages.component';
import {NewCompanyComponent} from '../../companies/new-company/new-company.component';
import {SaasThemeComponent} from '../../administration/components/saas-theme/saas-theme.component';
import {ManageCompanyComponent} from '../../administration/components/manage-company/manage-company.component';
import {NotificationsSettingsComponent} from '../../company-notifications/notifications-settings/notifications-settings.component';
import {ManageParcelComponent} from '../../administration/components/manage-parcel/manage-parcel.component';
import {CompanySmsComponent} from '../../company-notifications/company-sms/company-sms.component';
import {CompanyConfigurationNewComponent} from '../../companies/company-configuration-new/company-configuration-new.component';
import {CompanyNotificationsComponent} from '../../company-notifications/company-notifications.component';
import {TicketingSystemDashboardComponent} from '../../ticketing-system/components/dashboard/ticketing-system-dashboard.component';
import {
    TechnicalSupportTicketsComponent
} from '../../ticketing-system/components/technical-support-tickets/technical-support-tickets.component';
import {CategoriesManagementComponent} from '../../ticketing-system/components/categories-management/categories-management.component';
import {SystemUpdatesComponent} from '../../system-updates/components/system-updates/system-updates.component';
import {UpdateInfoPdfViewerComponent} from '../../system-updates/components/update-info-pdf-viewer/update-info-pdf-viewer.component';
import {FaqListComponent} from '../../faq/components/faq-list/faq-list.component';
import {
    ReceivedCodWithoutCustodyReportComponent
} from '../../received-cod-without-custody-report/received-cod-without-custody-report.component';
import {CodCustomersComponent} from '../../administration/components/cod-customers/cod-customers.component';
import {CodCustomerComponent} from '../../administration/components/cod-customer/cod-customer.component';
import {
    CustomerPackagesSummaryReportComponent
} from '../../administration/components/summary-reports/customer-packages-summary-report/customer-packages-summary-report.component';
import {
    CityToUndeliveredCodSummaryReportComponent
} from '../../administration/components/summary-reports/city-to-undelivered-cod-summary-report/city-to-undelivered-cod-summary-report.component';
import {SystemUpdatesV2Component} from '../../system-updates/components/system-updates-v2/system-updates-v2.component';
import {
    CodPendingCustomersApprovalComponent
} from '../../administration/components/cod-pending-customers-approval/cod-pending-customers-approval.component';
import {ActiveCustomerReportsComponent} from '../../current-reports/active-customer-reports/active-customer-reports.component';
import {NgModule} from '@angular/core';
import {ThirdPartyListComponent} from '../../third-parties/components/third-party-list/third-party-list.component';
// import {ReturnedBundlesComponent} from '../../shipment/returned-bundles/returned-bundles.component';
import {ReturnedPackagesComponent as ReturnedBundlesComponent} from '../../shipment/returned-packages/returned-packages.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardComponent
    },
    {
        path: 'inbox',
        component: InboxComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'add-shipment',
        component: AddShipmentComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-shipments',
        component: ManageShipmentsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'containers-management',
        component: LclContainersManagementComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'returned-packages',
        component: ReturnedPackagesComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'returned-bundles',
        component: ReturnedBundlesComponent,
        canActivate: [RoleGuard],
        data: {
            bundle: true
        }
    },
    {
        path: 'pending-approval',
        component: PendingApprovalComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'draft-pickups',
        component: DraftPickupsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'hubs-reports',
        component: HubsReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'hubs-reports/:id',
        component: HubsReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'partners-reports',
        component: PartnersReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'partners/third-party',
        component: ThirdPartyListComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'partners-reports/:id',
        component: PartnersReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'active-customers-report',
        component: ActiveCustomerReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'active-customers-report/:id',
        component: ActiveCustomerReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'drivers-report',
        component: DriversReportComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'drivers-report/:id',
        component: DriversReportComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'received-cod-without-custody-report',
        component: ReceivedCodWithoutCustodyReportComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'financial-report',
        component: FinancialReportComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'draft-pickups/receive-driver',
        component: ReceiveDriverComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-shipments/unload-returned-packages',
        component: UnloadReturnedPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'returned-packages/unload-returned-packages',
        component: UnloadReturnedPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'shipments/returned-packages',
        component: ReturnedPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'shipments/returned-packages/:id',
        component: ReturnPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'returned-packages-in-car',
        component: ReturnedPackagesComponent,
        data: {
            inCar: true,
        },
        canActivate: [RoleGuard],
    },
    {
        path: 'deliver-to-sender',
        component: ReturnedPackagesComponent,
        data: {
            deliverToSender: true,
        },
        canActivate: [RoleGuard],
    },
    {
        path: 'partially-returned-packages',
        component: ReturnedPackagesComponent,
        data: {
            partiallyDelivered: true,
        },
        canActivate: [RoleGuard],
    },
    {
        path: 'swapped-packages',
        component: ReturnedPackagesComponent,
        data: {
            swapping: true,
        },
        canActivate: [RoleGuard],
    },
    {
        path: 'all-returned-packages',
        component: ReturnPackagesComponent,
        data: {
            allReturned : true
        },
        canActivate: [RoleGuard],
    },
    {
        path: 'brings-packages',
        component: ReturnedPackagesComponent,
        data: {
            brings: true,
        },
        canActivate: [RoleGuard],
    },
    {
        path: 'manage-shipments/receive-driver',
        component: ReceiveDriverComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-driver',
        component: ReceiveDriverComponent,
        // canActivate: [RoleGuard],
    },
    {
        path: 'receive-from-partner',
        component: ReceiveDriverComponent,
        // canActivate: [RoleGuard],
    },
    {
        path: 'manage-shipments/receive-from-partner',
        component: ReceiveDriverComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-shipments/:id',
        component: PackageHistoryComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'receive-cod/package-history/:id',
        component: PackageHistoryComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'change-status',
        component: ChangeStatusPackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'unload-packages',
        component: UnloadPackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-branches',
        component: BranchesManagementComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'fulfilment',
        loadChildren:  () => import('./../../fulfilment/fulfilment.module').then(m => m.FulfilmentModule),
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'branches-management',
    //     component: BranchesManagementComponent,
    //     // canActivate: [RoleGuard]
    // },
    {
        path: 'manage-shelves/:id',
        component: ShelfPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-containers',
        component: ContainersManagementComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-users',
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                redirectTo: 'users',
                pathMatch: 'full'
            },
            {
                path: 'customers',
                component: UsersManagementComponent,
            },
            {
                path: 'users',
                component: UsersManagementComponent
            },
            {
                path: 'archived',
                component: UsersManagementComponent
            },
            {
                path: 'archived-users',
                component: UsersManagementComponent
            },
            {
                path: 'drivers',
                component: UsersManagementComponent
            },
            {
                path: 'archived-drivers',
                component: UsersManagementComponent
            }
        ]
    },
    // {
    //     path: 'manage-containers',
    //     component: ManageContainersComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'manage-users',
    //     canActivate: [RoleGuard],
    //     children: [
    //         {
    //             path: '',
    //             redirectTo: 'users',
    //             pathMatch: 'full'
    //         },
    //         {
    //             path: 'customers',
    //             component: ManageUsersComponent,
    //         },
    //         {
    //             path: 'users',
    //             component: ManageUsersComponent
    //         },
    //         {
    //             path: 'archived',
    //             component: ManageUsersComponent
    //         }
    //     ]
    // },
    // {
    //     path: 'active-partners',
    //     component: ActivePartnersComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'active-partners/:id',
    //     component: ManageShipmentsComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'pending-partners',
    //     component: PendingPartnersComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'partners',
        loadChildren: () => import('./../../partners/partners.module').then(m => m.PartnersModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'administration',
        loadChildren: () => import('./../../administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [RoleGuard]
    },
    {
        path : 'pricing-service-types',
        component: PricingsComponent,
        canActivate: [RoleGuard],
    },
    {
        path : 'pricing-service-types/:id',
        component: PricingsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'shipping-rates',
        component: ShippingsRateComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'shipping-rates/:id',
        component: ShippingsRateComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'drivers-earnings/shipping-rates/:id',
        component: ShippingsRateComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'pricing-type',
        canActivate: [RoleGuard],
        component: PricingTypeComponent,
        children: [
            {
                path: '',
                redirectTo: 'customers-pricing',
                pathMatch: 'full'
            },
            {
                path: 'customers-pricing',
                component: CustomersPricingTableComponent,
                canActivate: [RoleGuard],
            },
            {
                path: 'pricing-lists',
                component: PricingListsComponent,
                canActivate: [RoleGuard],
            }
        ]
    },
    {
        path: 'drivers-pricing',
        component: CustomersPricingTableComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'shipping-lines',
        component: ShippingLinesComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'archived-shipments',
        component: ArchivedShipmentsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'account-settings',
        component: AccountComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-cod-customers',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'manage-cod-customers/:id',
        component: CodCustomerComponent,
        data: {
            customExport: true
        },
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-cod',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-cod/packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-cod/:barcode',
        component: ReceivePackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'hub-custody',
        component: HubsCustodyComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'hub-custody/:barcode',
        component: ReceivePackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'hub-custody-receive/:barcode',
        component: ReceivePackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'sort-cod',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'sort-cod/:id',
        component: SortPackagesTableComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'sort-cod/packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'exported-packages',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'exported-packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'invoices',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'invoices/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-pending-customers-approval',
        component: CodPendingCustomersApprovalComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-pending-customers-approval',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'delivered-packages',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'delivered-packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'in-car-packages',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'receive-without-custody',
        component: CodCustomersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'in-car-packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-mismatch/packages/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'printed-reports',
        component: PrintedReportsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'cod-mismatch',
        component: CodMismatchComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'delayed-cod-reports',
        component:  DelayedCodReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'expenses',
        component: ExpensesComponent,
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'administration/payments',
    //     component: ManagePaymentsComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'administration/zones',
        component: ManageZonesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'administration/service-types',
        component: ManageServiceTypesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'administration/package-contents',
        component: ManagePackageContentsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports',
        component: DetailedReportsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'detailed-reports/customer-packages-detailed-report',
        component: CustomerPackagesDetailedReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'detailed-reports/idle-customers-report',
        component: IdleCustomersReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'delayed-packages-report',
        component: DelayedPackagesReportComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'driver-packages-detailed-report',
        component: DriverPackagesDetailedReportComponent,
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'detailed-reports/shipped-packages-detailed-report',
    //     component: ShippedPackagesDetailedReportComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'detailed-reports/undelivered-packages-detailed-report',
    //     component: UndeliveredPackagesDetailedReportComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'summary-reports',
        component: SummaryReportsComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'summary-reports/customer-packages-summary-report',
        component: CustomerPackagesSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'summary-reports/driver-packages-summary-report',
    //     component: DriverPackagesSummaryReportComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'summary-reports/zones-from-packages-summary-report',
    //     component: ZonesFromPackagesSummaryReportComponent,
    //     canActivate: [RoleGuard]
    // // },
    // {
    //     path: 'summary-reports/zones-to-packages-summary-report',
    //     component: ZonesToPackagesSummaryReportComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'summary-reports/city-to-cod-summary-report',
        component: CityToUndeliveredCodSummaryReportComponent,
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'summary-reports/zones-packages-summary-report',
    //     component: ZonesPackagesSummaryReportComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'summary-reports/undelivered-packages-summary-report',
    //     component: UndelivredPackagesSummaryReportComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'administrative-reports',
        component: AdministrativeReportsComponent,
        canActivate: [RoleGuard]
    },
    // {
    //     path: 'administrative-reports/time-period-packages-administrative-report',
    //     component: TimePeriodPackagesAdministrativeReportComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'administrative-reports/customer-activity-administrative-report',
    //     component: CustomerActivityReportComponent,
    //     canActivate: [RoleGuard]
    // },
    // {
    //     path: 'administrative-reports/activity-administrative-report',
    //     component: ActivityReportComponent,
    //     canActivate: [RoleGuard]
    // },
    {
        path: 'bank-transfers',
        component: BankTransfersComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'bank-transfers/:id',
        component: CodCustomerComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'drivers-earnings',
        component: DriversEarningsComponent,
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                redirectTo: 'work-zone',
                pathMatch: 'full'
            },
            {
                path: 'shipping-rates',
                component: ShippingsRateComponent,
                data: {
                    isDriverWorkArea: true,
                }
            },
            {
                path: 'work-zone',
                component: WorkZoneComponent
            }
        ]
    },
    {
        path: 'driver-packages/:id',
        component: DriverPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'container-packages/:id',
        component: ContainerPackagesComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'company-settings',
        canActivate: [RoleGuard],
        component: ManageCompanyComponent,
        children: [
            {
                path: '',
                redirectTo: 'basic-info',
                pathMatch: 'full'
            },
            {
                path: 'basic-info',
                component: NewCompanyComponent,
                data: {
                    isEdit: true,
                    isGuest: false,
                    isModal: false,
                    isAuth: true,
                }
            },
            {
                path: 'theme',
                component: SaasThemeComponent,
            },
            {
                path: 'notification-settings',
                component: NotificationsSettingsComponent
            }
        ]
    },
    {
        path: 'manage-parcel',
        component: ManageParcelComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'company-configuration',
        component: CompanyConfigurationNewComponent,
        canActivate: [RoleGuard]
    },
    {
        path: 'company-notifications',
        component: CompanyNotificationsComponent,
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                redirectTo: 'notifications-settings',
                pathMatch: 'full'
            },
            {
                path: 'notifications-settings',
                component: NotificationsSettingsComponent,
            },
            {
                path: 'company-sms',
                component: CompanySmsComponent,
            }
        ]
    },
    {
        path: 'ticketing-system',
        component: TicketingSystemDashboardComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'technical-support-tickets',
        component: TechnicalSupportTicketsComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'manage-categories',
        component: CategoriesManagementComponent,
        canActivate: [RoleGuard],
    },
    {
        path: 'system-updates',
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                component: SystemUpdatesComponent,
            },
            {
                path: ':id',
                component: UpdateInfoPdfViewerComponent,
            }
        ]
    },
    {
        path: 'documents-system-updates',
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                component: SystemUpdatesV2Component,
                data: {
                    documents: true
                },
            },
            {
                path: ':id',
                component: UpdateInfoPdfViewerComponent,
            }
            // {
            //     path: ':id',
            //     component: UpdateInfoPdfViewerComponent,
            // }
        ]
    },
    {
        path: 'videos-system-updates',
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                component: SystemUpdatesV2Component,
                data: {
                    videos: true
                }
            },
            // {
            //     path: ':id',
            //     component: UpdateInfoPdfViewerComponent,
            // }
        ]
    },
    {
        path: 'faq',
        canActivate: [RoleGuard],
        children: [
            {
                path: '',
                component: FaqListComponent,
            }
        ]
    },
    // {
    //     path: 'ticketing-system',
    //     component: TicketingSystemComponent,
    //     canActivate: [RoleGuard],
    //     children: [
    //         {
    //             path: '',
    //             redirectTo: 'dashboard',
    //             pathMatch: 'full'
    //         },
    //         {
    //             path: 'dashboard',
    //             component: TicketingSystemDashboardComponent,
    //         }
    //     ]
    // }
    // {
    //     path: 'containers-management',
    //     component: ContainersManagementComponent
    // }
    // {
    //     path: 'users-management',
    //     // canActivate: [RoleGuard],
    //     children: [
    //         {
    //             path: '',
    //             redirectTo: 'users',
    //             pathMatch: 'full'
    //         },
    //         {
    //             path: 'customers',
    //             component: UsersManagementComponent,
    //         },
    //         {
    //             path: 'archived',
    //             component: UsersManagementComponent
    //         },
    //         {
    //             path: 'users',
    //             component: UsersManagementComponent
    //         },
    //         {
    //             path: 'archived-users',
    //             component: UsersManagementComponent
    //         }
    //     ]
    // }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [RoleGuard]
})
export class HomeRoutingModule {
}

