<div class="d-flex main-container">
    <div class="first-side">

        <img class="wallet-img" src="../../../assets/icons/general/wallet.svg" alt="">
    </div>
    <div class="second-tab">
        <div class="detail-item mb-2">
            <img class="driver-icon" src="../../../assets/icons/taxi-driver.png" alt="">
            <div class="detail-text">
                {{wallet.customerName}}
            </div>
        </div>

        <div class="detail-item">
            <img class="driver-icon" src="../../../assets/icons/general/fiat_money.svg" alt="">
            <div class="detail-text">
                {{wallet.amount| number : digitFormat | currencyValue}}
            </div>
        </div>
        <div class="bottom-actions">
            <div class="bottom-btn" (click)="openTransactions()">
                <img class="icon" src="../../../assets/icons/general/transaction.svg" alt="">
                <div class="text">
                    {{'ACCOUNTING.WALLET.TRANSACTIONS' | translate}}
                </div>
            </div>

            <div class="bottom-btn" (click)="openAddReceipt(wallet)">
                <img class="icon" src="../../../assets/icons/general/add-file-1.svg" alt="">
                <div class="text">
                    {{'ACCOUNTING.WALLET.ADD_RECEIPT' | translate}}
                </div>
            </div>

        </div>
    </div>
</div>

<ng-container *ngIf="displayDetails">
    <app-wallet-transactions [display]="displayDetails" [wallet]="wallet" [packageBarcode]="'EEE130614000287'" (onCloseTracker)="onCloseTracker($event)">

    </app-wallet-transactions>
</ng-container>
