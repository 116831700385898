<p-toast [baseZIndex]="toastZIndex">
</p-toast>

<div class="reports-view d-flex flex-column h-100">

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>


    <div class="header-filters d-flex flex-wrap">
        <div class="page-header-title d-flex">
            <div class="header-title">
                {{"ACCOUNTING.REPORTS_NUMBER" | translate}}
            </div>
            <div class="total-number-badge">
                {{totalRecords}}
            </div>
        </div>
       <div class="filters-actions d-flex flex-wrap">
           <div class="header-group d-flex flex-wrap">
               <div class="filter date-filter-item">
                   <i class="pi pi-calendar date-picker-icon"></i>
                   <p-calendar styleClass="date-picker"
                               [(ngModel)]="toDate"
                               appendTo="body"
                               (onSelect)="onSearch()"
                               placeholder="{{'MANAGE_SHIPMENTS.TO' | translate}}"
                               dateFormat="dd/mm/yy"></p-calendar>
               </div>

               <div class="filter date-filter-item">
                   <i class="pi pi-calendar date-picker-icon"></i>
                   <p-calendar styleClass="date-picker"
                               appendTo="body"
                               [(ngModel)]="fromDate"
                               (onSelect)="onSearch()"
                               placeholder="{{'MANAGE_SHIPMENTS.FROM' | translate}}"
                               dateFormat="dd/mm/yy"></p-calendar>
               </div>

               <div class="filter search main-search-filter">
                   <i class="fas fa-search"></i>
                   <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent"
                          appInputDebounced
                          (inputDebounced)="onSearch()">
               </div>
           </div>
           <div class="printed-report-action flex-2d-center cursor-pointer"
                [ngClass]="{'disabled-btn disabled': !isThereSelection}"
                (click)="deleteReports($event)">
               <i class="fas fa-pencil-alt"></i>
               {{"GENERAL.DELETE" | translate}}
           </div>
       </div>
    </div>

    <div class="reports-table flex-1 overflow-auto">
        <p-table  appPTableRtlFix dataKey="id" #table [value]="reports" paginatorDropdownAppendTo="body"
                 styleClass="flex-table" (onPage)="setPaginatorQueryParam()"
                 [loading]="spinnerState === spinnerStates.LOADING" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadCustomersLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions" [first]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4%">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                    (onChange)="onToggleSelectAll($event)">

                        </p-checkbox>
                    </th>
                    <th> {{'ACCOUNTING.REPORT_NAME' | translate}}</th>
                    <th> {{'ACCOUNTING.PRINT_DATE'| translate}}</th>
                    <th> {{'ACCOUNTING.REPORT_STATUS' | translate}}</th>
                 </tr>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr class="cursor-pointer">
                    <td style="width: 4%">
                        <p-checkbox [(ngModel)]="report.isSelected" binary="true"
                                    (onChange)="onToggleSelection($event)" (click)="stopProp($event)">
                        </p-checkbox>
                    </td>
                    <td>{{report.name}}</td>
                    <td>{{report.createdDate | date:'dd/MM/yyyy'}}</td>
                    <td *ngIf="report.status === 'SUCCESS'">
                        <div class="table-row-actions-container">
                            <div class="print-action action cursor-pointer"
                                 (click)="printReport(report.url)">
                                <i class="fas fa-print"></i>
                                {{report.status | translate}}
                            </div>
                        </div>
                    </td>
                    <td *ngIf="report.status !== 'SUCCESS'">
                        <div class="table-row-actions-container">
                            <div class="report-status action cursor-pointer">
                                {{report.status | translate}}...
                            </div>
                       </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
