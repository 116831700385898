<div class="new-package-content-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="header d-inline-flex justify-content-between">
        <div class="title" *ngIf="!isEdit">{{ 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ACTIONS.ADD' | translate }}</div>
        <div class="title"
             *ngIf="isEdit">{{ 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.ACTIONS.EDIT' | translate }}</div>
        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <form [formGroup]="packageContentForm">
        <div class="inputs-container">
            <div class="row row-input">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label"
                         [ngClass]="isInvalidDirtyTouched('name') ? 'invalid-message' : ''">
                        {{ 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.FORM.NAME' | translate }}
                    </div>
                    <div class="input-value">
                        <input type="text" size="3" pInputText formControlName="name"/>
                    </div>
                    <div class="invalid-message invalid-container">
            <span *ngIf="isInvalidWithError('name', 'required')">
              {{ 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.VALIDATION.NAME_REQUIRED' | translate }}
            </span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                    <div class="input-label container-type-label"
                         [ngClass]="isInvalidDirtyTouched('arabicName') ? 'invalid-message' : ''">{{ 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.FORM.ARABIC_NAME' | translate }}</div>
                    <div class="input-value">
                        <input type="text" size="3" pInputText formControlName="arabicName"/>
                    </div>
                    <div class="invalid-message invalid-container">
                        <span *ngIf="isInvalidWithError('arabicName', 'required')">
                          {{ 'ADMINISTRATION.MANAGE_PACKAGE_CONTENTS.VALIDATION.ARABIC_NAME_REQUIRED' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-actions">
            <div class="done-action" (click)="submitForm()"
                 [ngClass]="{'disabled-btn disabled': !packageContentForm.valid || isLoading}">
                <span>{{'ACTIONS.SUBMIT' | translate}}</span>
            </div>
        </div>
    </form>
</div>
