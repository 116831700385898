<p-toast [baseZIndex]="99999999999"></p-toast>

<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
    <div class="header d-inline-flex justify-content-between">
        <div class="title">{{'GENERAL.CHANGE_STATUS' | translate}}</div>

        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <form [formGroup]="form">
        <div class="inputs-container">
            <div class="row row-input mb-2 mt-1">
                <div class="col-12 input">
                    <div class="input-value dropdown-container" [ngClass]="{'has-error': (form.get('hubId').touched && form.get('hubId').errors)}">

                        <app-drop-down [options]="hubList"
                                      class="col-6"
                                      [label]="'CHANGE_PACKAGE_STATUS.HUB_NAME'"
                                      formControlName = "hubId"
                                      (whenChange)="hubChange($event)"
                                      [showClear]="false"
                                      placeholde="''"
                                      [dropdownNameField]="'value'"
                                      [formControlObj]="form.get('hubId')"
                                      [formControl]="form.get('hubId')"></app-drop-down>


                    </div>
                </div>

            </div>
            <div class="row row-input mb-2 mt-1">
                <div class="col-12 input">
                    <div class="input-value dropdown-container" [ngClass]="{'has-error': (form.get('shelfId').touched && form.get('shelfId').errors)}">

                        <app-drop-down [options]="shelves"
                                      class="col-6"
                                      [label]="'CHANGE_PACKAGE_STATUS.SHELF_NAME'"
                                      formControlName = "shelfId"
                                      [dropdownNameField]="'value'"
                                      [formControlObj]="form.get('shelfId')"
                                      [formControl]="form.get('shelfId')"
                                      [showClear]="true"></app-drop-down>


                    </div>
                </div>

            </div>
        </div>

        <div class="bottom-actions">
            <div class="done-action" (click)="submitForm()" [ngClass]="{'disabled-btn disabled': !form.valid || isLoading}">
                <span>{{ 'ACTIONS.DONE' | translate }}</span>
            </div>
        </div>
    </form>
</div>
