import {Component, OnInit} from '@angular/core';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {DriversService} from '../../../shared/services/drivers.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShippingLinesService} from '../../services/shipping-lines.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-manage-shipping-line-driver',
    templateUrl: './manage-shipping-line-driver.component.html',
    styleUrls: ['./manage-shipping-line-driver.component.scss']
})
export class ManageShippingLineDriverComponent implements OnInit {

    sourceDrivers;

    oldTarget = [];
    targetDrivers = [];
    spinnerState: SpinnerState;
    SpinnerState = SpinnerState;
    shippingLineId;
    shippingLine;
    model: any;
    isLoading = false;

    constructor(private driversList: DriversService,
                private messageService: MessageService,
                public translateService: TranslateService,
                private shippingLinesService: ShippingLinesService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {

        this.getDrivers('');
        this.targetDrivers = [];
    }

    getDrivers(search = '') {
        this.spinnerState = SpinnerState.LOADING;
        let query = '?search=' + search;
        query += '&driverType=TYPICAL';

        this.driversList.getDrivers(query).subscribe(
            (response: any) => {
                const drivers = response.map(driver => {
                    return {
                        name: driver.firstName + (driver.middleName ? (' ' + driver.middleName + ' ') : ' ') + driver.lastName,
                        value: driver.id,
                        id: driver.id,
                        image: '../../assets/icons/taxi-driver.png',
                        price: Math.floor(Math.random() * 100),
                        inventoryStatus: 'inventoryStatus'
                    };
                });
                if (drivers && drivers.length) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
                this.sourceDrivers = drivers;
                this.handleOldDriver();

            }, (error) => {
                this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    targetUpdated($event: any) {
    }

    closeModal(isSuccess = false) {
        this.activeModal.close({isSuccess});

    }

    submit() {
        let index = 1;
        const drivers = this.targetDrivers.map(d => {
            return {
                'id': d.id,
                'priority': index++
            };
        });
        const body = this.shippingLine;
        body.drivers = drivers;
        this.isLoading = true;
        this.shippingLinesService.editShipmentLine(this.shippingLineId, body, '').subscribe(res => {
            this.isLoading = false;

            this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant('ALERTS.SHIPPING_LINE_EDITED_SUCCESSFULLY')
            });
            this.closeModal(true);
        }, error => {
            this.isLoading = false;

        });
    }

    getIndexOf(driver: any) {
        const index = this.targetDrivers.findIndex(td => td.id === driver.id);
        return index < 0 ? '' : index + 1;
    }

    private handleOldDriver() {
        this.oldTarget = _.sortBy(this.oldTarget, ['priority']);
        this.oldTarget.forEach(o => {
            const driver = this.sourceDrivers.find(sd => sd.id == o.id);
            if (driver) {
                this.targetDrivers.push(driver);
            } else {
                this.targetDrivers.push(o);

            }
        });
        this.sourceDrivers = this.sourceDrivers.filter(sd => this.targetDrivers.indexOf(sd) < 0);

    }
}
