import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-driver-packages-detailed-report',
    templateUrl: './driver-packages-detailed-report.component.html',
    styleUrls: ['./driver-packages-detailed-report.component.scss']
})
export class DriverPackagesDetailedReportComponent {
    public driverReports = REPORTS_CONSTANTS.DETAILED_REPORTS.DRIVER_REPORTS;
    public reportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_DRIVER_REPORT_API;
    constructor() {
    }
    onDriverSelected(driverId) {
        this.reportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_DRIVER_REPORT_API.replace('{driverId}', driverId);
    }
}
