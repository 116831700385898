import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FULFILMENT_CONSTANTS} from './constants';
import {FulfilmentItemModel} from '../models/item.model';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FulfilmentService {
    private orderAddedEvent = new EventEmitter<any>();
    private asnAddedEvent = new EventEmitter<any>();

    constructor(private http: HttpClient) {
    }

    public getWarehouses(query) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_WAREHOUSES + query;
        return this.http.get(url);
    }

    public getWarehousesNew(params) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_WAREHOUSES;
        return this.http.get(url, {params: params});
    }

    public getWarehousesRates(params) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_WAREHOUSES_RATES;
        return this.http.get(url, {params});
    }

    public newWarehouse(reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.NEW_WAREHOUSE;
        return this.http.post(url, reqBody);
    }

    public createOrder(reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.CREATE_ORDER;
        return this.http.post(url, reqBody);
    }

    public exportExcelOrder(url, query) {
        return this.http.get(url, {params: query});
    }

    public updateOrder(reqBody, id) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_ORDER.replace('{id}', id);
        return this.http.put(url, reqBody);
    }

    public logTime(shippingPlanId, sortingHours) {
        const url = FULFILMENT_CONSTANTS.APIS.LOG_SHIPPING_PLANE_TIME.replace('{shippingPlanId}', shippingPlanId);
        return this.http.put(url, {}, {params: {sortingHours}});
    }

    public sortItemIntoBin(itemBarcode, binId, shippingPlanId, customerId, isToIntegration = false, warehouseId?, source?, productId?) {
        let url = '';
        let reqBody: any = {
            barcode: itemBarcode,
            customerId: customerId
        };
        let params = {};
        if (shippingPlanId) {
            params['shippingPlanId'] = shippingPlanId;
            reqBody['shippingPlanId'] = shippingPlanId;
        }
        if (isToIntegration) {
            reqBody = {};
            url = FULFILMENT_CONSTANTS.APIS.SORT_ZID_ITEM_INTO.replace('{productId}', productId)
                .replace('{customerId}', customerId)
                .replace('{source}', source)
                .replace('{warehouseId}', warehouseId);
            params = {
                binId: binId,
                barcode: itemBarcode
            };

        } else {
            url = FULFILMENT_CONSTANTS.APIS.SORT_ITEM_INTO_BIN.replace('{binId}', binId);
        }
        return this.http.put(url, reqBody, {params});
    }

    public sortItemIntoLocation(itemBarcode, locationId, shippingPlanId, customerId, isToIntegration = false, warehouseId?, source?, productId?) {
        let url = '';
        let reqBody: any = {
            itemBarcode: itemBarcode,
            shippingPlanId: shippingPlanId,
            customerId: customerId
        };
        let params: any = {
            shippingPlanId
        };
        if (isToIntegration) {
            reqBody = {};
            url = FULFILMENT_CONSTANTS.APIS.SORT_ZID_ITEM_INTO.replace('{productId}', productId)
                .replace('{customerId}', customerId)
                .replace('{source}', source)
                .replace('{warehouseId}', warehouseId);
            params = {
                locationId: locationId,
                barcode: itemBarcode
            };

        } else {
            url = FULFILMENT_CONSTANTS.APIS.SORT_ITEM_INTO_LOCATION.replace('{locationId}', locationId);
        }

        return this.http.put(url, reqBody, {params});
    }

    public orderAddedSuccessfully(order) {
        this.orderAddedEvent.emit(order);
    }

    public getOrderAddedAsObservable(): Observable<any> {
        return this.orderAddedEvent.asObservable();
    }
    public asnAddedSuccessfully(order) {
        this.asnAddedEvent.emit(order);
    }

    public getAsnAddedAsObservable(): Observable<any> {
        return this.orderAddedEvent.asObservable();
    }

    public updateWarehouse(id, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_WAREHOUSE.replace('{hubId}', id + '');
        return this.http.put(url, reqBody);
    }

    public updateWarehousePricing(id, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_WAREHOUSE_PRICING.replace('{id}', id + '');
        return this.http.put(url, reqBody);
    }

    public getAreas(query, hubId) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_AREAS.replace('{hubId}', hubId) + query;
        return this.http.get(url);
    }

    public getOrder(orderId, query = '') {
        const url = FULFILMENT_CONSTANTS.APIS.GET_ORDER_API.replace('{orderId}', orderId) + query;
        return this.http.get(url);
    }

    public newArea(hubId, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.NEW_AREA.replace('{hubId}', hubId);
        return this.http.post(url, reqBody);
    }

    public getRows(query, hubId, areaId?) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_ROWS.replace('{hubId}', hubId) + query;
        if (areaId) {
            url += '&areaId=' + areaId;
        }
        return this.http.get(url);
    }

    public newRowWithLocations(areaId, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.NEW_ROW.replace('{areaId}', areaId);
        return this.http.post(url, reqBody);
    }

    public getShelves(query, hubId, areaId?, rowId?) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_SHELVES.replace('{hubId}', hubId) + query;
        if (areaId) {
            url += '&areaId=' + areaId;
        }
        if (rowId) {
            url += '&rowId=' + rowId;
        }
        return this.http.get(url);
    }

    public getLocations(query, hubId, areaId?, rowId?, shelfId?) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_LOCATIONS.replace('{hubId}', hubId) + query;
        if (shelfId) {
            url += '&shelfId=' + shelfId;
        } else if (rowId) {
            url += '&rowId=' + rowId;
        } else if (areaId) {
            url += '&areaId=' + areaId;
        }
        return this.http.get(url);
    }


    public getLocationsWithStats(query, hubId, areaId?, rowId?, shelfId?) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_LOCATIONS_WITH_STATS.replace('{hubId}', hubId) + query;
        if (shelfId) {
            url += '&shelfId=' + shelfId;
        } else if (rowId) {
            url += '&rowId=' + rowId;
        } else if (areaId) {
            url += '&areaId=' + areaId;
        }

        return this.http.get(url);
    }

    public getLocationsNew(params, hubId, areaId?, rowId?, shelfId?) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_LOCATIONS.replace('{hubId}', hubId);
        if (shelfId) {
            params['shelfId'] = shelfId;
        } else if (rowId) {
            params['rowId'] = rowId;
        } else if (areaId) {
            params['areaId'] = areaId;
        }
        return this.http.get(url, {params});
    }

    public printLocations(rowId, params) {
        const url = FULFILMENT_CONSTANTS.APIS.PRINT_LOCATIONS.replace('{rowId}', rowId);
        return this.http.get(url, {params});
    }

    public getProducts(query, onlyActiveProducts = false) {
        if (onlyActiveProducts) {
            query += '&isForShippingPlan=true';
        }
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_PRODUCTS + query);
    }

    public getProductsNew(params, onlyActiveProducts = false, isActivated = true) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_PRODUCTS;
        if (onlyActiveProducts) {
            params['isForShippingPlan'] = onlyActiveProducts;
        }
        if (isActivated) {
            params['isActivated'] = isActivated;
        }

        return this.http.get(url, {params});
    }

    public newProduct(reqBody) {
        return this.http.post(FULFILMENT_CONSTANTS.APIS.NEW_PRODUCT, reqBody);
    }

    public updateProduct(productId, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_PRODUCT.replace('{productId}', productId);
        return this.http.put(url, reqBody);
    }

    public downloadProductsExcelTemplate() {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE);
    }

    public exportProductsAsExcel(query) {
        const url = FULFILMENT_CONSTANTS.APIS.EXPORT_EXCEL_PRODUCT;
        return this.http.get(url, {params: query});
    }

    public getItems(query, body, productId?) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_PRODUCTS_ITEMS + query;
        if (productId) {
            url += '?productId=' + productId;
        }
        return this.http.post(url, body);
    }

    public getItemsForOrder(params) {
        params['isForOrders'] = true;
        const url = FULFILMENT_CONSTANTS.APIS.GET_PRODUCTS;
        return this.http.get(url, {params: params});
    }

    public newItem(reqBody) {
        if (reqBody.status !== 'REJECTED') {
            delete reqBody.rejectReasonKey;
            delete reqBody.rejectReason;
        }
        return this.http.post(FULFILMENT_CONSTANTS.APIS.NEW_ITEM, reqBody, {params: {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}});
    }

    public updateItem(itemId, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_ITEM.replace('{itemId}', itemId);
        return this.http.put(url, reqBody);
    }

    public printItemsBarcodes(item: FulfilmentItemModel, type = '') {
        const params = {
            warehouseId: item.warehouseId,
            productId: item.productId,
            customerId: item.customerId,

        }
        if (type) {
            params['type'] = type;
        }
        if (item.expiryDate) {
            params['expiryDate'] = item.expiryDate.toString().split('T')[0];
        }
        return this.http.get(FULFILMENT_CONSTANTS.APIS.PRINT_ITEMS_BARCODES, {params});
    }
    public printZidItemsBarcodes(item, type = '') {
        const params = {
            warehouseId: item.warehouseId,
            productId: item.productId,
            customerId: item.customerId,

        };
        if (type) {
            params['type'] = type;
        }
        if (item.expiryDate) {
            params['expiryDate'] = item.expiryDate.toString().split('T')[0];
        }
        const url = FULFILMENT_CONSTANTS.APIS.PRINT_ZID_ITEMS_BARCODES.replace('{source}', item.integrationSource).replace('{warehouseId}', item.warehouseId).replace('{productId}', item.productId).replace('{customerId}', item.customerId);

        return this.http.get(url , {params});
    }

    public downloadItemsExcelTemplate() {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.DOWNLOAD_ITEMS_EXCEL_TEMPLATE);
    }

    public getBins(params, isTotes = false) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_BINS;
        if (isTotes) {
            url = FULFILMENT_CONSTANTS.APIS.GET_TOTES;
        }
        return this.http.get(url, {params});
    }

    public getBinByBarcode(barcode) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_BIN;
        return this.http.get(url, {params: {barcode}});
    }

    public getToteByBarcode(toteId) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_TOTE.replace('{toteId}', toteId);
        return this.http.get(url);
    }

    public scanBin(barcode, body) {
        const url = FULFILMENT_CONSTANTS.APIS.SCAN_BIN.replace('{barcode}', barcode);
        return this.http.post(url, body, {});
    }

    public checkAvailability(barcode, body) {
        const url = FULFILMENT_CONSTANTS.APIS.CHECK_AVAILABILITY.replace('{barcode}', barcode);
        return this.http.post(url, body, {});
    }

    public checkLocationAvailability(barcode, body) {
        const url = FULFILMENT_CONSTANTS.APIS.CHECK_LOCATION_AVAILABILITY.replace('{barcode}', barcode);
        return this.http.post(url, body, {});
    }

    public getBinItems(binId, params) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_BIN_ITEMS.replace('{binId}', binId), {params});
    }

    public newBins(isTotes = false, reqBody) {
        const url = isTotes ? FULFILMENT_CONSTANTS.APIS.NEW_TOTES : FULFILMENT_CONSTANTS.APIS.NEW_BINS;
        return this.http.post(url, reqBody);
    }

    public printBinsBarcodes(from, to = null, type = 'A4', prefix, isTote) {
        const url = FULFILMENT_CONSTANTS.APIS.PRINT_BINS_BARCODES;
        const params = {
            from: from,
            type: type,
            prefix: prefix,
            isTote: isTote
        };
        if (to) {
            params['to'] = to;
        }


        return this.http.get(url, {params});
    }

    public printBinBarcode(binId, type = 'A4') {
        const url = FULFILMENT_CONSTANTS.APIS.PRINT_BIN_BARCODE.replace('{binId}', binId) + '?type=' + type;
        return this.http.get(url);
    }

    public getLowestBinBarcode(isTote, warehouseId) {
        const params = {};
        if (isTote) {
            params['isTote'] = true;
        } else {
            params['isBin'] = true;
        }
        if (warehouseId) {
            params['warehouseId'] = warehouseId;
        }
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_LOWEST_BIN_BARCODE, {params});
    }

    public getShippingPlans(params) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLANS, {params});
    }

    public getShippingPlanStatusColor(shippingPlanStatus) {
        const shippingPlansColors = {
            RECEIVED: '#2ED47A',
            CLOSED: '#B3404A',
            DEFAULT: '#ccc',
            PARTIALLY_RECEIVED: '#FF914D',
            AWAITING_PICKUP: '#B772B8',
            ASSIGNED_TO_DRIVER: '#ccc',
            PICKED_UP: '#00E2FF',
            ARRIVED_AT_DESTINATION: '#E96479',
            REJECTED: '#EC7063'
        };
        return shippingPlansColors[shippingPlanStatus] ?? shippingPlansColors['DEFAULT'];
    }

    public newShippingPlan(reqBody) {
        return this.http.post(FULFILMENT_CONSTANTS.APIS.NEW_SHIPPING_PLAN, reqBody);
    }

    public editShippingPlan(shippingPlanId, reqBody) {
        const url = FULFILMENT_CONSTANTS.APIS.EDIT_SHIPPING_PLAN.replace('{shippingPlanId}', shippingPlanId);
        return this.http.put(url, reqBody);
    }

    public getDriversWithShippingPlans() {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_DRIVERS_WITH_SHIPPING_PLANS);
    }

    public getShippingPlansForDriver(driverId) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLANS_FOR_DRIVER + '?driverId=' + driverId;
        return this.http.get(url);
    }

    public getShippingPlan(shippingPlanId) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLAN + '?id=' + shippingPlanId;
        return this.http.get(url);
    }

    public getShippingPlanDetails(shippingPlanId?, shippingPlanBarcode?) {

        const url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLAN_HISTORY;
        const params = {};
        if (shippingPlanId) {
            params['id'] = shippingPlanId;
        }
        if (shippingPlanBarcode) {
            params['barcode'] = shippingPlanBarcode;
        }
        return this.http.get(url, {params});
    }

    public getOrderDetails(shippingPlanId?, shippingPlanBarcode?) {

        const url = FULFILMENT_CONSTANTS.APIS.GET_ORDER_HISTORY;
        const params = {};
        if (shippingPlanId) {
            params['id'] = shippingPlanId;
        }
        if (shippingPlanBarcode) {
            params['barcode'] = shippingPlanBarcode;
        }
        return this.http.get(url, {params});
    }

    public getShippingPlanByBarcode(shippingPlanBarcode) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLAN + '?barcode=' + shippingPlanBarcode;
    }

    public updateProductActivation(id, isActivated) {
        return this.http.post(
            FULFILMENT_CONSTANTS.APIS.UPDATE_PRODUCT_ACTIVATION.replace('{productId}', id) + '?isActivated=' + !isActivated,
            {}
        );
    }

    reserveBinUpdate(id, body) {
        const url = FULFILMENT_CONSTANTS.APIS.RESERVE_BIN.replace('{binId}', id);
        return this.http.put(url, body);
    }

    reserveBin(id, body) {
        const url = FULFILMENT_CONSTANTS.APIS.RESERVE_BIN.replace('{binId}', id);
        return this.http.post(url, body);
    }


    reserveLocation(id, body) {
        const url = FULFILMENT_CONSTANTS.APIS.RESERVE_LOCATION.replace('{locationId}', id);
        return this.http.post(url, body);
    }


    downloadShippingPlanBarcode(shippingPlanId, type?) {
        let url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLAN_BARCODE.replace('{shippingPlanId}', shippingPlanId) + `?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.http.get(url);
    }
    downloadShippingPlanProductBarcode(shippingPlanId, productId, expiryDate) {
        const params = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (expiryDate) {
            params['expiryDate'] = expiryDate;
        }
        const url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLAN_PRODUCT_BARCODE.replace('{shippingPlanId}', shippingPlanId).replace('{productId}', productId);
        return this.http.get(url, {params});
    }
    getShippingPlanItemsStatus(shippingPlanId) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_SHIPPING_PLAN_ITEMS_STATUS.replace('{shippingPlanId}', shippingPlanId);
        return this.http.get(url);
    }

    printShippingPlanPolicyWithItemsBarcodes(shippingPlanId, type?) {
        let url = FULFILMENT_CONSTANTS.APIS.PRINT_SHIPPING_PLAN_POLICY_WITH_ITEMS_BARCODES + `?id=${shippingPlanId}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.http.get(url);
    }


    printShippingPlanPolicy(shippingPlanId, type?) {
        let url = FULFILMENT_CONSTANTS.APIS.PRINT_SHIPPING_PLAN_POLICY + `?id=${shippingPlanId}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.http.get(url);
    }

    getFulFilmentOrderByBarcode(orderBarcode) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_ORDER_BY_ID.replace('{id}', orderBarcode));
    }

    getHubsRowsData(rowId) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_HUB_ROWS_DATA.replace('{rowId}', rowId));
    }

    public getWareHouseStocks(params) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_WAREHOUSE_STOCKS, {params});
    }

    printAllItemsBarcodes(binId, type?) {
        let url = FULFILMENT_CONSTANTS.APIS.PRINT_BIN_ITEMS_BARCODES.replace('{binId}', binId);
        if (type) {
            url += `?type=${type}`;
        }
        return this.http.get(url);
    }

    printSingleBinItemBarcode(binId, itemBarcode, type?) {
        let url = FULFILMENT_CONSTANTS.APIS.PRINT_BIN_ITEMS_BARCODES.replace('{binId}', binId) + `?from=${itemBarcode}&to=${itemBarcode}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.http.get(url);
    }

    sortBinIntoLocation(locationBarcode, binBarcode) {
        const params = {barcode: binBarcode};
        const url = FULFILMENT_CONSTANTS.APIS.SORT_BIN_INTO_LOCATION.replace('{locationBarcode}', locationBarcode);
        return this.http.put(url, {}, {params});
    }

    rejectItemFromBin(itemBarcode, binId, shippingPlanId, body) {
        const url = FULFILMENT_CONSTANTS.APIS.REJECT_ITEM_FROM_BIN.replace('{binId}', binId);
        const reqBody = {
            barcode: itemBarcode,
            rejectReason: body.note,
            rejectReasonKey: body.reason
        };
        const params = {
            shippingPlanId
        };
        return this.http.put(url, reqBody, {params});
    }
    rejectItemFromLocation(itemBarcode, locationId, shippingPlanId, body) {
        const url = FULFILMENT_CONSTANTS.APIS.REJECT_ITEM_FROM_LOCATION.replace('{locationId}', locationId);
        const reqBody = {
            barcode: itemBarcode,
            rejectReason: body.note,
            rejectReasonKey: body.reason
        };
        const params = {
            shippingPlanId
        };
        return this.http.put(url, reqBody, {params});
    }

    assignShippingPlanToDriver(driverId, shippingPlanId) {
        const url = FULFILMENT_CONSTANTS.APIS.ASSIGN_SHIPPING_PLAN_TO_DRIVER.replace('{shippingPlanId}', shippingPlanId);
        return this.http.put(url, {}, {params: {driverId}});
    }

    getTotalLocations(warehouseId, params) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_TOTAL_LOCATIONS.replace('{hubId}', warehouseId), {params});
    }

    getInventoryItemDetail(params: { productId: any; warehouseId: any; customerId: any }) {
        return this.http.get(FULFILMENT_CONSTANTS.APIS.GET_INVENTORY_ITEM_DETAILS, {params});
    }

    removeOrder(id) {
        const url = FULFILMENT_CONSTANTS.APIS.DELETE_ORDER.replace('{id}', id);
        return this.http.delete(url);
    }
    removeAsn(id) {
        const url = FULFILMENT_CONSTANTS.APIS.DELETE_ASN.replace('{id}', id);
        return this.http.delete(url);
    }

    changeLocationsType(locationsIDs: any[], type, warehouseId) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_LOCATION_TYPE.replace('{hubId}', warehouseId + '');
        const params = {
            locationsIDs,
            type
        };
        return this.http.put(url, {}, {params});
    }

    public exportExcelBinsTotes(query, type: string) {
        let url;
        if (type === 'TOTES') {
           url = FULFILMENT_CONSTANTS.APIS.EXPORT_EXCEL_TOTES;
        } else {
        url = FULFILMENT_CONSTANTS.APIS.EXPORT_EXCEL_BINS;
        }
        return this.http.get(url, {params: query});
    }

    public exportExcelItems(params, body) {
        const url = FULFILMENT_CONSTANTS.APIS.EXPORT_EXCEL_PRODUCTS_ITEMS;
        return this.http.post(url , body, {params});
    }

    public exportExcelShippingPlan(params) {
        const url = FULFILMENT_CONSTANTS.APIS.EXPORT_EXCEL_SHIPPING_PLANS;
        return this.http.get(url,  {params});
    }

    public receiveShippingPlan(asnsIds) {
        const url = FULFILMENT_CONSTANTS.APIS.RECEIVE_SHIPPING_PLANS;
        return this.http.put(url , {}, {params: {asnsIds}});
    }

    packItem(barcode, toteId, orderId) {
        const url = FULFILMENT_CONSTANTS.APIS.PACK_ORDER_ITEM.replace('{orderId}', orderId);
        const params = {
            barcode,
            toteId
        };
        return this.http.get(url, {params});
    }

    packOrder(fulfillmentOrderId, parcelTypeId) {
        const url = FULFILMENT_CONSTANTS.APIS.PACK_ORDER;
        const params = {
            fulfillmentOrderId,
            parcelTypeId
        };
        return this.http.put(url, {}, {params});
    }

    changeLocationDamaged(id, storageType, shelfId, warehouseId) {
        const url = FULFILMENT_CONSTANTS.APIS.UPDATE_LOCATION_DAMAGED_TYPE.replace('{hubId}', warehouseId + '');
        const params = {
            type: storageType,
            shelfId,
            locationsIds: [id]
        };
        return this.http.put(url, {locationsIds: [id]}, {params});
    }

    changeWarehouseForZid(newWarehouseId, order) {
        const url = FULFILMENT_CONSTANTS.APIS.CHANGE_ZID_ITEMS_WAREHOUSE.replace('{source}', order.integrationSource)
            .replace('{warehouseId}', order.warehouseId)
            .replace('{productId}', order.productId)
            .replace('{customerId}', order.customerId);

        return this.http.put(url, {}, {params: {newWarehouseId}});
    }

    downloadFile(type: string, url, params) {
        return this.http.get(url + '/' + type, {params});
    }

    getUnpickedItems(orderId) {
        const url = FULFILMENT_CONSTANTS.APIS.GET_UNPICKED_ITEMS.replace('{orderId}', orderId);
        return this.http.get(url);
    }

    pickItem(barcode, totId, orderId) {
        const url = FULFILMENT_CONSTANTS.APIS.PICK_ORDER.replace('{toteId}', totId);
        const params = {
            orderId,
        };
        return this.http.put(url, {barcode}, {params});
    }
}
