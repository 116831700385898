import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {SpinnerState} from '../../../shared/behavior/spinner-state.enum';
import {Subscription, timer} from 'rxjs';
import {FaqService} from '../../services/faq.service';
import {FaqModel} from '../../models/faq.model';
import {AddFaqDialogComponent} from '../add-faq-dialog/add-faq-dialog.component';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent extends SubscriptionTrackerComponent implements OnInit {
  pageSize = 10;
  totalNumberOfFaq: any;
  isSuperAdmin: boolean;
  isMobileResolution: boolean;
  @ViewChild('loadingTrigger') loadingTrigger;
  @ViewChild('tableContainer') tableContainer;

  private hasMore: Boolean | boolean = true;
  private loadingFaq: boolean;
  public spinnerState = SpinnerState.LOADING;
  private pageNumber = 0;
  public faqData: FaqModel[] = [];
  private editedFaqIndex: number;
  searchControl: FormControl;


  public spinnerStates = SpinnerState;
  private faq: any;
  currentLang;
  searchContent = '';
  private oldSubscription: Subscription;

  constructor(private modalService: NgbModal,
              private authenticationService: AuthenticationService,
              private router: Router,
              public translateService: TranslateService,
              private confirmService: ConfirmationService,
              private faqService: FaqService,
              private route: ActivatedRoute,
              private applicationStateService: ApplicationStateService) {
    super();
    this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.searchControl = new FormControl();
    this.subscriptions.push(this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      this.loadFaqLazy(true);
    }));
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.loadFaqLazy(true);
  }
  openAddFaqDialog(faq = null) {

    const modal = this.modalService.open(AddFaqDialogComponent, <any>{
      size: 'md',
      backdrop: 'static',
    });
    modal.result.then(result => {
      if (result && result.added) {
        this.loadFaqLazy(true);
      }
    });
  }

  @HostListener('scroll', ['$event'])
  onsScrollFaqTable(event, isMobile = false) {
    if (isMobile) {
      this.handleMobileFaqLoading(event);
    } else {
      this.handleFaqLoading(event);
    }
  }

  private handleMobileFaqLoading(event) {
    if (this.loadingFaq || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
      return;
    }

    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 20) {
      this.pageNumber++;
      this.loadFaqLazy(false);
      this.loadingFaq = true;
    }
  }

  private handleFaqLoading(event) {
    if (this.loadingFaq || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
      return;
    }


    if (!this.loadingTrigger || !this.loadingTrigger.nativeElement) {
      return;
    }
    const loading_trigger_offset = this.loadingTrigger.nativeElement.offsetTop;

    if (event.target.offsetHeight + event.target.scrollTop >= loading_trigger_offset - 20) {
      this.pageNumber++;
      this.loadFaqLazy();
      this.loadingFaq = true;

    }
  }

  loadFaqLazy(forceFetch = false, keepOldCall = false) {
    if (forceFetch) {
      this.pageNumber = 0;
      this.faqData = [];
      this.handleFillingTheScreen();
    }
    const params = {
      pageSize: this.pageSize,
      page: this.pageNumber + 1,
      languageCode: this.currentLang,
      search: this.searchContent || ''
    };

    let editedPage;
    if (this.editedFaqIndex > -1) {
      editedPage = Math.floor(this.editedFaqIndex / 10);
      params.page = editedPage;
    }
    const tableBodyScrollTopBeforeAddingValue = this.tableContainer &&
    this.tableContainer.nativeElement ? this.tableContainer.nativeElement.scrollTop : 0;
    this.spinnerState = SpinnerState.LOADING;
    if (!keepOldCall && this.oldSubscription && this.oldSubscription.unsubscribe) {
      this.oldSubscription.unsubscribe();
    }
    this.oldSubscription = this.faqService.loadFaq(params, this.isSuperAdmin).subscribe((res: any) => {
          this.totalNumberOfFaq = res.total;
          if (this.editedFaqIndex > -1) {
            this.faqData.splice(editedPage, 10);
            this.faqData.splice(editedPage, 0, ...res.data);
            this.editedFaqIndex = -1;
          } else {
            this.faqData.push(...res.data);
            this.hasMore = this.faqData && (this.faqData.length < res.totalRecordsNo);
          }

          this.spinnerState = SpinnerState.LOADED;
          if (this.faqData.length === 0) {
            this.spinnerState = SpinnerState.EMPTY;
          }

          if (this.tableContainer && this.tableContainer.nativeElement) {
            setTimeout(() => {
              this.tableContainer.nativeElement.scrollTop = tableBodyScrollTopBeforeAddingValue;
              this.loadingFaq = false;
            }, 30);
          }

        }, (error) => {
          this.spinnerState = SpinnerState.FAILED;
        }
    );
  }

  private handleFillingTheScreen() {
    if (this.applicationStateService.getIsMobileResolution()) {
      return;
    }
    const ob = timer(1000, 1000);
    let stopTimer = false;
    const subscribe = ob.subscribe(res => {
      if (this.hasMore) {
        const hasVerticalScrollbar = this.tableContainer.nativeElement.scrollHeight > this.tableContainer.nativeElement.clientHeight;

        if (!hasVerticalScrollbar) {
          if (this.spinnerState === SpinnerState.LOADED) {
            this.pageNumber++;
            this.loadFaqLazy(false, true);
          }
        } else {
          stopTimer = true;
        }
      } else {
        stopTimer = true;
      }
      if (stopTimer) {
        subscribe.unsubscribe();
      }
    });
    this.addSubscription(subscribe);
  }


    triggerFaqDropdown($event: MouseEvent, dropdown: OverlayPanel, rowIndex, faq) {
        this.faq = faq;
        dropdown.toggle($event);
    }

  removeFaq() {
    this.translateService.get(
        ['ALERTS.CONFIRM_DELETE_FAQ', 'GENERAL.YES', 'GENERAL.NO']
    )
        .subscribe(
            (data) => {
              const message = data['ALERTS.CONFIRM_DELETE_FAQ'];
              const yes = data['GENERAL.YES'];
              const no = data['GENERAL.NO'];
              this.confirmService.confirm({
                message: message,
                accept: () => {
                  this.faqService.removeFaq(this.faq.id).subscribe(r => {
                    this.loadFaqLazy(true);
                  });
                },
                reject: () => {

                },
                acceptLabel: yes,
                rejectLabel: no
              });
            }
        );

  }

  editFaq() {
    const modal = this.modalService.open(AddFaqDialogComponent, <any>{
      size: 'md',
      data: this.faq,
      backdrop: 'static',
    });
    this.editedFaqIndex = this.faqData.findIndex((value) => {
      return value.id === this.faq.id;
    });
    modal.componentInstance.faq = this.faq;
    modal.result.then(result => {
      if (result && result.added) {
        this.loadFaqLazy(false);
      }
    });

  }

  toggleClass(cardItem: HTMLDivElement, className: string) {
      cardItem.classList.toggle(className);
  }
}
