import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GeographyService} from '../../shared/services/geography.service';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-update-receiver-address',
    templateUrl: './update-receiver-address.component.html',
    styleUrls: ['./update-receiver-address.component.scss']
})
export class UpdateReceiverAddressComponent implements OnInit {

    receiverAddress = new FormControl();
    public addressOptions: { value: string, label: string }[];
    currentLang: any;
    isLoading = false;
    public originPackage;
    private selectedReceiverAddress: any;

    constructor(private geographyService: GeographyService, private translate: TranslateService,
                private shipmentService: ShipmentsService, private activeModal: NgbActiveModal
    ) {
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;

        this.shipmentService.getPackageHistoryByBarcode(this.originPackage.barcode).subscribe((res: any) => {
            const receiverAddress = res.pkg.destinationAddress;
            this.receiverAddress.setValue(
                {
                    value: receiverAddress.villageId,
                    label: `${receiverAddress.village} - ${receiverAddress.city} - ${receiverAddress.region}`,
                    cityId: receiverAddress.cityId,
                    regionId: receiverAddress.regionId,
                    country: receiverAddress.country
                });
        });

    }

    public getAddresses(event) {
        let query;
        if (event && event.query) {
            query = event.query;
        } else {
            query = event;
        }
        this.geographyService.getvillages(query).subscribe(
            (response: any) => {
                this.addressOptions = response.data.map(address => {
                    const label = address.arabicName + ' - ' + address.cityName + ' - ' + address.regionName;
                    return {
                        ...address,
                        label: label
                    };
                });
            }, (error) => {
                console.error(error);
            }
        );

    }

    public chooseReceiverAddress(address) {

        this.selectedReceiverAddress = JSON.parse(JSON.stringify(address));
    }

    close(res) {
        this.activeModal.close(res);
    }

    modifyAddress() {
        if (!this.selectedReceiverAddress){
            this.close({});
        }
        const body = {
            ...this.receiverAddress.value,
            villageId: this.receiverAddress.value.id
        };
        this.isLoading = true;
        this.shipmentService.modifyPackageDestinationAddress(body, this.originPackage.id).subscribe((res: any) => {
            this.isLoading = false;
            this.close({
                cost: res.cost,
                address: {
                    ...this.selectedReceiverAddress
                }
            });
        },(error) => {
            this.isLoading = false;
        });

    }
}
