import {Component, Inject, OnInit} from '@angular/core';
import {HubsService} from '../../shared/services/hubs.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ShipmentsService} from '../services/shipments.service';

@Component({
    selector: 'app-receive-in-hub-custody',
    templateUrl: './receive-in-hub-custody.component.html',
    styleUrls: ['./receive-in-hub-custody.component.css']
})
export class ReceiveInHubCustodyComponent implements OnInit {
    form: any;
    hubsOptions;
    isLoading = false;
    public packages: [];

    constructor(private hubsService: HubsService,
                private activeModal: NgbActiveModal,
                private shipmentsService: ShipmentsService,
                @Inject(FormBuilder) private formBuilder: FormBuilder) {

    }

    ngOnInit() {
        this.initializeForm();
        this.initHubs();
    }

    public initHubs() {
        return this.hubsService.getHubs().subscribe(
            (response: any) => {
                this.hubsOptions = response.hubs.map(
                    (hub) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
            }
        );
    }


    private initializeForm() {

        this.form = this.formBuilder.group({
            hubId: [null, Validators.required],
        });
    }

    public closeModal(result?) {
        this.activeModal.close(result);
    }

    updatePackages() {

        // const body = {ids: this.packages || []};
        // console.log(this.form.getRawValue().hubId);
        // this.shipmentsService.receiveInHubCustody(this.form.getRawValue().hubId, body).subscribe(value => {
        //     this.isLoading = true;
        const hubId =  this.form.getRawValue().hubId;
        const hub = this.hubsOptions.find(val => {
            return val.value == hubId;
        });
        this.closeModal({hubId, isSuccess: true, hubName : hub.label});
        // }, error => {
        //     this.isLoading = false;
        // });
    }
}
