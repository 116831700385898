import {AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UsersService} from '../../../users/services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {SpinnerState} from '../../behavior/spinner-state.enum';
import {HttpClient} from '@angular/common/http';
import {SHARED_CONSTANTS} from '../../services/shared_constants/constants';
import {SharedService} from '../../services/shared-service';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {timer} from 'rxjs';
import {ApplicationStateService} from '../../services/application-state.service';
import {SubscriptionTrackerComponent} from '../../behavior/subscription-tracker.component';
import * as _ from 'lodash';
import {DatePipe} from '@angular/common';
import {MultiSelectComponent} from '../form-components/multi-select/multi-select.component';


export enum COLUMNS_TYPE {
    NORMAL = 1,
    WITH_SEARCH_FILTER = 2,
    WITH_MULTI_SELECT_FILTER = 3,
    STATUS = 4,
    ACTIONS = 5,
    BARCODE_WITH_IMAGE = 6,
    BARCODE = 7,
    CUSTOM = 8,
    DROPDOWN_FILTER = 9,
    CUSTOM_TEMPLATE = 10,
    DATE = 11,
    WITH_MULTI_SELECT_FILTER_WAREHOUSE = 12,
    DATE_WITH_RANGE_FILTER = 13
}

export class ColumnDef {
    header: any;
    field?: any;
    enableFilter ? = false;
    columnType?: COLUMNS_TYPE = COLUMNS_TYPE.NORMAL;
    headerFilterPlaceholder?: string;
    multiSelectFilterSettings?: any;
    tdType?: COLUMNS_TYPE;
    click?;
    allowCopy?: boolean;
    prefix?: string;
    postfix?: string;
    getHTML?;
    clickEvent?;
    tdClass?;
    actions?;
    options?;
    templateName?: string;
    templateHeaderName?: string;
    isWarehouses?;
    style?;
    hidden?;
    isCurrency?;
    footer?;
    dateFormat?;
    canShow?;
}

@Component({
    selector: 'app-generic-table',
    templateUrl: './generic-table.component.html',
    styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent extends SubscriptionTrackerComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

    @Input() columns: ColumnDef[];
    @Input() config;
    @Input() externalFilter;
    @Input() customTemplates;
    @Input() hidePagination = false;
    @Input() hideNoData = false;
    value = [];
    filter = {};
    filterList = {};
    columnsType = COLUMNS_TYPE;
    currentLang: string;
    pageNumber: number;
    private hasMore;
    data: any[];
    spinnerState: SpinnerState;
    private getDataSubscription: any;
    pageSize = 10;
    loading = false;
    spinnerStates = SpinnerState;
    numberOfColumns = 0;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    totalRecords = 0;
    selectedRow: any;
    selectedCol: any;
    lastRequest: any;
    lazyLoading = false;
    @ViewChild('ptable') ptable;
    @ViewChild('shipmentOptions') shipmentOptions;
    private response;

    constructor(
        private usersService: UsersService,
        private http: HttpClient,
        private fulfilmentService: FulfilmentService,
        public translateService: TranslateService,
        private changeDetectionRef: ChangeDetectorRef,
        private sharedService: SharedService,
        private datePipe: DatePipe,
        private applicationStateService: ApplicationStateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.currentLang = this.translateService.currentLang;
        this.initColumns();
        this.initConfig();
    }

    filterChanged() {
        this.initData(true);
    }

    public refreshTableData() {
        this.initData(true);
    }

    private initConfig() {
        if (this.config.loadingType === 'LAZY') {
            this.hidePagination = true;
            this.lazyLoading = true;
        }
    }

    private initColumns() {
        this.numberOfColumns = 0;
        this.columns.forEach(col => {
            this.numberOfColumns++;
            if (!col.columnType) {
                col.columnType = COLUMNS_TYPE.NORMAL;
            }
            if (col.columnType === COLUMNS_TYPE.DROPDOWN_FILTER) {
                if (!col.options) {
                    col.options = [];
                }
            }
            if (col.columnType === COLUMNS_TYPE.STATUS) {
                if (!col.field) {
                    col.field = 'status';
                }
            }
            if (col.columnType === COLUMNS_TYPE.DATE) {
                if (!col.dateFormat) {
                    col.dateFormat = 'dd/MM/yyyy';
                }
            }
            if (!col.prefix) {
                col.prefix = '';
            }
            if (!col.postfix) {
                col.postfix = '';
            }
            if (!col.tdClass) {
                col.tdClass = '';
            }
            if (!col.tdType) {
                col.tdType = COLUMNS_TYPE.NORMAL;
            }
            if (col.columnType === COLUMNS_TYPE.WITH_MULTI_SELECT_FILTER) {
                this.filter[col.field] = [];
                col.multiSelectFilterSettings = {
                    ...{
                        singleSelection: false,
                        idField: 'id',
                        textField: 'name',
                        itemsShowLimit: 0,
                        allowSearchFilter: true,
                        enableCheckAll: false,
                        searchPlaceholderText: this.translateService.instant('GENERAL.SEARCH'),
                        noFilteredDataAvailablePlaceholderText: this.translateService.instant('GENERAL.NO_RESULTS_FOUND'),
                    }, ...(col.multiSelectFilterSettings || {})
                };
                this.filterList[col.field] = [];
                this.getCustomers(col.field);
            } else if (col.columnType === COLUMNS_TYPE.WITH_MULTI_SELECT_FILTER_WAREHOUSE) {
                col.multiSelectFilterSettings = {
                    ...{
                        singleSelection: true,
                        idField: 'id',
                        textField: 'name',
                        itemsShowLimit: 0,
                        allowSearchFilter: true,
                        enableCheckAll: false,
                        searchPlaceholderText: this.translateService.instant('GENERAL.SEARCH'),
                        noFilteredDataAvailablePlaceholderText: this.translateService.instant('GENERAL.NO_RESULTS_FOUND'),
                    }, ...(col.multiSelectFilterSettings || {})
                };
                this.filter[col.field] = [];
                this.filterList[col.field] = [];
                this.getWarehouses(col.field);
            } else {
                if (col.columnType === COLUMNS_TYPE.DATE_WITH_RANGE_FILTER) {
                    this.filter[col.field] = '';

                } else {
                    this.filter[col.field] = '';
                }
            }
        });

    }


    onSelectMultiSelectFilter(field: string | HTMLElement, selectAll = false) {
        this.initData(true);
    }

    resetFilter(field: string) {
        this.filter[field] = [];
        this.refreshTableData();
    }

    filterCities(field, multiSelect: MultiSelectComponent) {
        this.getCustomers(field, multiSelect.filter + '');
    }

    getCustomers(field, search?: string) {
        // this.setCustomerDropDownSettings();
        const query = (search) ? '?page=1&pageSize=10&search=' + search : '?page=1&pageSize=10';
        this.usersService.getCustomersDropDown(query).subscribe(
            (response: any) => {
                if (response && response.length) {
                    const newList = response.map(customer => {
                        return this.getCustomerObject(customer);
                    });
                    this.filterList[field] = this.filter[field].concat(newList.filter((item) => this.filter[field]
                        .map(a => a.id).indexOf(item.id) < 0));
                }
            }, (error) => {
                console.error(error);
            }
        );

    }

    public getCustomerObject(customer) {
        return {
            name: customer.firstName + ' ' + customer.lastName + (customer.businessName ? ` (${customer.businessName})` : ''),
            id: customer.id,
        };
    }

    ngAfterViewInit(): void {
        if (this.lazyLoading) {
            setTimeout(() => {
                if (this.ptable && this.ptable.wrapperViewChild) {
                    const el = this.ptable.wrapperViewChild.nativeElement;
                    el.onscroll = (event) => {
                        this.handleScrollLoading(event, false);
                        event.stopPropagation();
                    };
                }
            }, 300);
        }
    }

    public initData(isFromFilter = false) {
        if (isFromFilter) {
            if (this.ptable?.first) {
                this.ptable.first = 0;
            }
            this.pageNumber = 1;
            this.hasMore = true;
            this.data = [];
            this.value = [];
        }
        if (this.lazyLoading && this.pageNumber === 1) {
            this.handleFillingTheScreen();
        }

        if (this.getDataSubscription) {
            this.getDataSubscription.unsubscribe();
        }
        this.spinnerState = SpinnerState.LOADING;
        if (!this.lazyLoading) {
            this.value = [];
        }
        const params = {
            page: this.pageNumber,
            pageSize: this.pageSize,
        };
        if (this.externalFilter) {
            for (const paramsKey in this.externalFilter) {
                if (this.externalFilter[paramsKey]) {
                    params[paramsKey] = this.externalFilter[paramsKey];
                }
            }
        }
        Object.keys(this.filter).forEach(
            (filterKey: string) => {
                const val = this.filter[filterKey];
                if (val && val !== 'ALL') {
                    if (Array.isArray(val)) {
                        if (filterKey.endsWith('Date')) {
                            if (val[0]) {
                                params[filterKey + 'From'] = this.transformDate(val[0]);
                            }
                            if (val[1]) {
                                params[filterKey + 'To'] = this.transformDate(val[1]);
                            } else if (val[0]) {
                                params[filterKey + 'To'] = this.transformDate(val[0]);
                            }
                        } else {
                            // need to make it dynamic
                            params[filterKey.replace('Name', 'Id')] = val.map(o => {
                                if (o.id) {
                                    return o.id;
                                }
                                return o;
                            });
                        }
                    } else if (filterKey.endsWith('Date')) {
                        // params[filterKey] = this.transformDate(this.filterParams[filterKey]);
                    } else {
                        params[filterKey] = val;
                    }
                }
            }
        );
        params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const preparedData = {
            params,
            body: {},
            url: this.config.url,
        };
        if (this.config.preRequestHandler) {

            this.config.preRequestHandler(preparedData);
        }

        if (preparedData.url) {
            this.lastRequest = preparedData;
            this.getDataSubscription = this.http.request(this.config.method || 'get', preparedData.url, {
                params: preparedData.params,
                body: preparedData.body
            }).subscribe(
                (response: any) => {
                    if (this.config.prepareResponse) {
                        response = this.config.prepareResponse(response);
                    }
                    this.response = response;
                    let tableBodyScrollTopBeforeAddingValue = 0;
                    if (this.lazyLoading) {
                        tableBodyScrollTopBeforeAddingValue = document.getElementsByClassName('generic-table-body')[0]
                            .getElementsByClassName('p-datatable-wrapper')[0].scrollTop;
                    }
                    if (response.data && response.data.length < this.pageSize) {
                        this.hasMore = false;
                    }
                    this.totalRecords = response.totalRecordsNo;
                    this.spinnerState = SpinnerState.LOADED;
                    if (this.lazyLoading) {
                        document.getElementsByClassName('generic-table-body')[0]
                            .getElementsByClassName('p-datatable-wrapper')[0]
                            .scrollTop = tableBodyScrollTopBeforeAddingValue - 100;
                        this.changeDetectionRef.detectChanges();
                        this.value.push(...response.data);

                    } else {
                        this.value = response.data;
                    }
                    this.loading = false;
                    if (this.config.postRequestHandler) {
                        this.config.postRequestHandler(response);
                    }
                }, (err) => {
                    if (!this.lazyLoading) {
                        this.value = [];
                    }
                    this.hasMore = false;
                    this.spinnerState = SpinnerState.LOADED;
                    console.error(err);
                });
        } else {
            this.loading = false;
            this.value = [];
            this.spinnerState = SpinnerState.LOADED;
        }
    }

    setPaginatorQueryParam($event) {
        this.pageSize = $event.rows;
        this.pageNumber = $event.first / $event.rows + 1;
        this.initData();


    }

    ngAfterContentInit(): void {
        this.initData(true);
    }

    public stopProp(event) {
        event.stopPropagation();
    }

    triggerAction(event: string) {
        if (this.selectedCol && this.selectedCol.click) {
            this.selectedCol.click(this.selectedRow);
        }
    }

    triggerActionsDropdown($event, dropdown, row, col) {
        this.selectedRow = row;
        this.selectedCol = col;
        dropdown.toggle($event);
    }

    copyMessage(event, str) {

        this.sharedService.copyMessage(event, null, str, this.translateService.instant('ALERTS.COPIED'));
    }

    getVisibleCols(columns: any) {
        return columns && columns.length ? columns.filter(c => !c.hidden) : [];
    }

    filterWarehouses(field: any, multiSelect: MultiSelectComponent) {
        // general component
        this.getWarehouses(field, multiSelect.filter + '');
    }

    getWarehouses(field, search?: string) {
        const params = {
            page: 1,
            pageSize: 100
        };
        if (search) {
            params['search'] = search;
        }
        this.fulfilmentService.getWarehousesNew(params).subscribe(
            (response: any) => {
                if (response && response.hubs.length) {
                    const newList = response.hubs.map(warehouse => {
                        return this.getWarehouseObject(warehouse);
                    });
                    this.filterList[field] = this.filter[field].concat(newList.filter((item) => this.filter[field]
                        .map(a => a.id).indexOf(item.id) < 0));
                }
            }, () => {

            }
        );
    }

    public getWarehouseObject(warehouse) {
        return {
            name: warehouse.name,
            id: warehouse.id
        };
    }

    setData(customers: any[]) {
        this.value = customers;
    }

    public handleScrollLoading(event: any, getCount) {
        // Handle load packages lazy.

        if (this.loading || !this.hasMore || this.spinnerState === SpinnerState.LOADING) {
            return;
        }
        // offsetHeight:541
        // scrollHeight:605
        // scrollTop:61
        const target = event.target;
        if (target.offsetHeight + target.scrollTop > target.scrollHeight - 5) {
            this.loading = true;
            this.pageNumber++;
            this.initData();
        }
    }

    private handleFillingTheScreen() {
        if (this.applicationStateService.getIsMobileResolution()) {
            return;
        }
        const ob = timer(1000, 1000);
        let stopTimer = false;
        const subscribe = ob.subscribe(res => {
            if (this.hasMore) {

                const hasVerticalScrollbar = (this.ptable.wrapperViewChild.nativeElement.scrollHeight - 20) >
                    this.ptable.wrapperViewChild.nativeElement.clientHeight;

                if (!hasVerticalScrollbar) {
                    if (this.spinnerState === this.spinnerStates.LOADED) {
                        this.pageNumber++;
                        this.initData();
                    }
                } else {
                    stopTimer = true;
                }
            } else {
                stopTimer = true;
            }
            if (stopTimer) {
                subscribe.unsubscribe();
            }
            this.subscriptions.push(subscribe);
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getLastRequest() {
        return _.cloneDeep(this.lastRequest);
    }

    trClicked($event, row) {
        this.shipmentOptions.hide();
        this.stopProp($event);
        if (this.config.trClickEvent) {
            this.config.trClickEvent(row);
        }
    }

    getNormalText(col: any, rowData: any) {
        return (this.isValueExist(col.prefix) ? col.prefix : '') +
            (this.isValueExist(rowData[col.field]) ? rowData[col.field] : '') +
            (this.isValueExist(col.postfix) ? col.postfix : '');
    }

    stopPropAndCall($event, col: any, rowData: any) {
        this.shipmentOptions.hide();
        this.stopProp($event);
        col.clickEvent(rowData);
    }

    private isValueExist(value) {
        return value || (typeof value === 'number' && isFinite(value));
    }

    getFooter(col: any) {
        if (typeof col.footer === 'function') {
            return col.footer(this.response);
        } else if (typeof col.footer === 'string') {
            return col.footer;
        }
    }
    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    onTdClick(col: any, rowData: any, $event: MouseEvent) {
        this.stopProp($event);
        if (col && col.onClick) {
            col.onClick(rowData);
        }
    }
}
