export const INBOX_CONSTANTS = {
    GET_EMAILS: ':api/admin/inbox',
    PAGE_SIZE: 5,
    SET_EMAIL_OPENED: ':api/admin/messages/{emailId}/set-as-opened',
    GET_EMAIL_REPLIES: ':api/admin/messages/{msgId}/replies',
    SEND_REPLY: ':api/admin/messages/{messageId}/reply-to',
    GET_ALL_USERS: ':api/admin/all-users',
    GET_ALL_CUSTOMERS: ':api/admin/all-customers',
    SEND_MESSAGE_TO_USERS: ':api/admin/messages/send-to-users',
    SEND_MESSAGE_TO_CUSTOMERS: ':api/admin/messages/send-to-customers',
    GET_MESSAGES_COUNT: ':api/admin/messages/unread'
};
export const CURRENCIES_WITH_CUSTOM_TRANSLATIONS = ['SAR', 'EGP'];
