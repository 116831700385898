import { Component, OnInit } from '@angular/core';
import {REPORTS_CONSTANTS} from '../../administration/reports-constants';

@Component({
  selector: 'app-partners-reports',
  templateUrl: './partners-reports.component.html',
  styleUrls: ['./partners-reports.component.scss']
})
export class PartnersReportsComponent implements OnInit {
  public partnersReports = REPORTS_CONSTANTS.PARTNER_REPORTS.PARTNER_REPORTS_INFO;
  public partnersReportsAPI = REPORTS_CONSTANTS.PARTNER_REPORTS.PARTNER_REPORTS_API;
  constructor() { }

  ngOnInit() {
  }

}
