<div class="email-details-container" [ngClass]="{'opened': email?.isOpened, 'email-rtl': lang === 'ar', 'email-ltr': lang === 'en'}">
  <div class="email-header-container">
    <div class="header">
      {{email?.header}}
    </div>
    <div class="sent-date-container">
      <div class="date">
        {{email?.createdDate | date:'EEEE d, MMM hh:mm'}}
      </div>
    </div>
  </div>

  <div class="email-info">
    <div class="from-container">
      <div class="from">
        <b class="from-label">{{'GENERAL.FROM' | translate}}:</b>{{email?.senderName}}
      </div>
      <div class="to">
        <b class="to-label">{{'GENERAL.TO' | translate}}:</b>
        <span *ngFor="let receiver of email.receiverNames; let i = index">
          {{receiver}}
          <ng-container *ngIf="i !== email.receiverNames.length - 1">,</ng-container>
        </span>
      </div>
    </div>
  </div>

  <div class="body-container">
    <div class="body">
      {{email?.body}}
    </div>
  </div>

  <div class="replies-container">
    <div *ngIf="spinnerState === spinnerStats.HOLD" class="view-all-replies cursor-pointer" (click)="getAllReplies()">
      view all replies
      <i class="fas fa-angle-double-down"></i>
    </div>
    <div class="spinner" *ngIf="spinnerState === spinnerStats.LOADING">
      <p-progressSpinner [style]="{'width': '100px'}"></p-progressSpinner>
    </div>
    <div class="replies-list" *ngIf="spinnerState === spinnerStats.LOADED">
      <app-email-reply *ngFor="let reply of emailReplies" [emailReply]="reply">
      </app-email-reply>
    </div>
  </div>

  <div class="send-reply-container">
    <div class="reply-input flex-1">
            <textarea
                    placeholder="{{'INBOX.TYPE_YOUR_MESSAGE' |translate}}..."
                    [(ngModel)]="replyContent"
                    [disabled]="isSendingReply"
                    (keypress)="onKeypress($event)"
                    class="reply-body"></textarea>
      <div class="spinner-container" *ngIf="isSendingReply" >
        <i class="fas fa-spinner fa-spin" style="font-size:24px"></i>
      </div>
    </div>
    <div class="reply-button" (click)="sendReply()">
      <img src="../../../assets/icons/inbox/send-message.svg" class="cursor-pointer">
    </div>
  </div>
</div>
