import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-customer-packages-summary-report',
    templateUrl: './customer-packages-summary-report.component.html',
    styleUrls: ['./customer-packages-summary-report.component.css']
})
export class CustomerPackagesSummaryReportComponent {
    public customerReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.CUSTOMER_REPORT;
    public customerReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.CUSTOMER_REPORT_API;
    public customerReportStatistics = REPORTS_CONSTANTS.SUMMARY_REPORTS.CUSTOMER_REPORTS_STATISTICS;

    constructor() {

    }
}
