<div class="export-container">
    <div class="header" *ngIf="showCSV">
        {{('SHIPMENT.EXPORT_PACKAGES') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
    </div>
    <div class="header" *ngIf="showPDF">
        {{('SHIPMENT.PRINT_PACKAGES') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>
    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div class="col-12">
                <div class="row" style="margin-bottom: 20px!important;">
                    {{('SHIPMENT.CHOOSE_EXPORT_TYPE') | translate }}
                </div>
                <div class="inputs row" style="padding: 15px">
                    <div class="flex-1" *ngIf="showCSV">
                        <input type="radio" formControlName="type" value="CSV">
                        <span>{{"SHIPMENT.CSV" | translate}}</span>

                    </div>
                    <div class="flex-1" *ngIf="showPDF">
                        <input type="radio" formControlName="type" value="PDF" >
                        <span>{{"SHIPMENT.PDF" | translate}}</span>

                    </div>
                    <div style="margin-left: 20px;" class="flex-1">
                        <input type="radio" formControlName="type" value="EXCEL" >
                        <span>{{"SHIPMENT.EXCEL" | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div style="padding: 10px 30px">
                <div class="add-button cursor-pointer "
                     (click)="exportExcel()"
                     [ngClass]="{'disabled-button disabled': !form.valid}">
                    <i class="far fa-save"></i>
                    {{'ACCOUNTING.EXPORT' | translate }}
                </div>
                <div class="reset-button cursor-pointer " (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
