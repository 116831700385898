import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {DriversService} from '../../shared/services/drivers.service';
import {UsersService} from '../../users/services/users.service';
import {ShipmentsService} from '../services/shipments.service';
import {empty, from} from 'rxjs';
import {catchError, concatMap, share} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';

@Component({
    selector: 'app-scan-unlisted-packages',
    templateUrl: './scan-unlisted-packages.component.html',
    styleUrls: ['./scan-unlisted-packages.component.scss']
})
export class ScanUnlistedPackagesComponent implements OnInit {
    isReading = false;
    isLoading = false;

    currentLang: string;
    selectedDriver: any;
    drivers: any[] = [];
    isLoadingDrivers = false;

    packages: {[barcode: string]: {invoiceNumber: string, customerName: string, driverName: string}} = {};
    companyAddress: any;

    selectedCustomer: any;
    customers: any[] = [];
    fetchedCustomers: any[] = [];
    totalCustomersNo = 0;
    isLoadingCustomers = false;

    filterParams = {};

    Object = Object;

    constructor(private activeModal: NgbActiveModal,
                private translateService: TranslateService,
                private messageService: MessageService,
                private driversService: DriversService,
                private usersService: UsersService,
                private userService: UserService,
                private shipmentsService: ShipmentsService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.initFilterParams();
        this.getDrivers();
        this.getCustomers(true);
        this.getCompanyAddress();
    }

    private initFilterParams() {
        this.filterParams = {
            DRIVERS: {
                search: ''
            },
            CUSTOMERS: {
                page: 1,
                pageSize: 10,
                search: ''
            }
        };
    }

    private createParams(filterParams) {
        if (!filterParams) {
            return '';
        }
        const filterKeyValues = Object.entries(filterParams);
        let result = '';
        filterKeyValues.forEach((keyValuePair, index) => {
            if (keyValuePair[1]) {
                if (index === 0) {
                    result += `?${keyValuePair[0]}=${keyValuePair[1]}`;
                } else {
                    result += `&${keyValuePair[0]}=${keyValuePair[1]}`;
                }
            }

        });
        return result;
    }

    readBarcode(barcode) {
        this.isLoading = true;
        if (this.packages[barcode]) {
            this.translateService.get('ALERTS.BARCODE_ALREADY_SCANNED').subscribe(transValue => {
                this.messageService.add({
                    severity: 'warn',
                    detail: transValue
                });
            });
        } else if (!this.selectedCustomer || !this.selectedDriver) {
            this.translateService.get('UNLISTED_PACKAGES.ALERTS.CHOOSE_DRIVER_CUSTOMER').subscribe(transValue => {
                this.messageService.add({
                    severity: 'error',
                    detail: transValue
                });
            });
        } else {
            this.packages[barcode] = {
                invoiceNumber: barcode,
                customerName: this.getCustomerFullName(this.selectedCustomer),
                driverName: `${this.selectedDriver.firstName} ${this.selectedDriver.lastName}`
            };
        }
        this.isLoading = false;
    }

    closeModal() {
        this.activeModal.close();
    }

    private getDrivers() {
        this.isLoadingDrivers = true;
        this.driversService.getDrivers(this.createParams(this.filterParams['DRIVERS'])).subscribe(
            (driversResponse: any[]) => {
                this.drivers = driversResponse;
                this.isLoadingDrivers = false;
            }, () => {
                this.isLoadingDrivers = false;
            }
        );
    }

    searchDriver(search: string) {
        this.filterParams['DRIVERS'].search = search;
        this.getDrivers();
    }

    trackDriverById(index: number, item: any) {
        return item.id;
    }

    selectDriver(driver: any) {
        this.selectedDriver = driver;
    }

    private getCustomers(forceFetch = false) {
        this.fetchedCustomers = [];
        if (this.totalCustomersNo === this.customers.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filterParams['CUSTOMERS'].page = 1;
        }
        this.isLoadingCustomers = true;
        this.usersService.getCustomers(this.createParams(this.filterParams['CUSTOMERS'])).subscribe((customersResponse) => {
            this.fetchedCustomers = customersResponse['customers'];
            this.totalCustomersNo = customersResponse['totalRecordsNo'];
            if (forceFetch) {
                this.customers = this.fetchedCustomers;
            } else {
                this.customers.push(...this.fetchedCustomers);
            }
            this.isLoadingCustomers = false;
        }, () => {
            this.isLoadingCustomers = false;
        });
    }

    private loadLazyCustomers() {
        this.filterParams['CUSTOMERS'].page++;
        this.getCustomers();
    }

    searchCustomer(search: string) {
        this.filterParams['CUSTOMERS'].search = search;
        this.getCustomers(true);
    }

    onCustomersScroll(scrollEvent) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyCustomers();
        }
    }

    selectCustomer(customer: any) {
        this.selectedCustomer = customer;
    }

    trackCustomerById(index: number, item) {
        return item.id;
    }

    private getCustomerFullName(customer) {
        let name = customer.firstName;
        if (customer.lastName) {
            name += ` ${customer.lastName}`;
        }
        if (customer.businessName) {
            name += ` (${customer.businessName})`;
        }
        return name;
    }

    deletePackage(pkg: any) {
        delete this.packages[pkg[0]];
    }

    onSubmitPackages() {
        if (!this.isLoading) {
            this.isLoading = true;
            from(Object.values(this.packages).map((pkgInfo) => {
                return {
                    pkg: {
                        senderName: this.selectedCustomer.fullName,
                        senderEmail: this.selectedCustomer.email,
                        senderPhone: this.selectedCustomer.phone,
                        senderPhone2: this.selectedCustomer.phone1,
                        businessSenderName: this.selectedCustomer.businessName,

                        receiverName: this.userService.userInfo.firstName + ' ' + this.userService.userInfo.lastName,
                        businessReceiverName: this.userService.userInfo.businessName,
                        receiverAuthorizedGovRegistrationNumber: this.userService.userInfo.authorizedGovRegistrationNumber,
                        receiverPhone: this.userService.userInfo.phone,

                        shipmentType: 'COD',
                        invoiceNumber: pkgInfo.invoiceNumber,
                        driverId: this.selectedDriver.id
                    }, originAddress: {
                        country: 'Palestine',
                        regionId: this.selectedCustomer.address.regionId,
                        cityId: this.selectedCustomer.address.cityId,
                        villageId: this.selectedCustomer.address.villageId,
                        addressLine1: this.selectedCustomer.address.addressLine1
                    }, destinationAddress: {
                        country: 'Palestine',
                        regionId: this.companyAddress.regionId,
                        cityId: this.companyAddress.cityId,
                        villageId: this.companyAddress.villageId,
                        addressLine1: this.companyAddress.addressLine1,
                    }
                };
            })).pipe(
                concatMap((pkg) => {
                    const requestObservable = this.shipmentsService.addNewShipment(pkg).pipe(share(),
                        catchError((error) => {
                            this.packages[pkg.pkg.invoiceNumber]['error'] = true;
                            console.log(error);
                            this.packages[pkg.pkg.invoiceNumber]['errorMessage'] = error.error.error;
                            return empty();
                        }));
                    requestObservable.subscribe(() => {
                        this.packages[pkg.pkg.invoiceNumber]['success'] = true;
                    });
                    return requestObservable;
                })
            ).subscribe(() => {
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
        }
    }

    private getCompanyAddress() {
        this.userService.getCompanyAddress().subscribe(response => {
            this.companyAddress = response;
        });
    }
}
