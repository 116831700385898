<div [ngClass]="{'page-ltr': selectedLanguage === 'en'}" *ngIf="translationLoaded">
    <!-- sign up Form Section -->
    <div class="sign-up-container forms-container">
        <!-- Spinner -->
        <app-horizontal-spinner-component [display]="isLoading"> </app-horizontal-spinner-component>
        <!-- Toasts -->
        <p-toast [baseZIndex]="toastZIndex"></p-toast>
        <div class="sign-up-html">
            <div class="row sign-up-title-container">
                <div class="sign-up-title">
                    <span>{{'SIGN_UP_FORM.TITLE' | translate}}</span>
                </div>
                <div class="return-to-login d-flex">
                    <div class="return-link">
                        <a routerLink="">{{'SIGN_UP_FORM.BACK' | translate}}</a>
                    </div>
                    <div style="width: 5px;"></div>
                    <div class="caret-icon">
                        <img *ngIf="selectedLanguage === 'ar'" src="../../assets/icons/login/left-arrow.svg" />
                        <img *ngIf="selectedLanguage === 'en'" src="../../assets/icons/login/right-arrow.svg" />
                    </div>
                </div>
            </div>
            <div class="sign-up-form-container">
                <!-- send Email -->
                <div class="forget-password-form">
                    <form [formGroup]="form" (submit)="submit($event)">
                        <div class="form-sign-up">
                            <form [formGroup]="form.controls.company" class="d-flex flex-column">
                                <div class="group-with-header">
                                    <div class="group-header row account-info d-flex">
                                        <div class="group-label">
                                            <span>{{'SIGN_UP_FORM.ACCOUNT_INFO.TITLE' | translate}}</span>
                                        </div>
                                        <div style="width: 15px;"></div>
                                        <div class="group-line-container col-lg-7 col-md-7 col-sm-12">
                                            <hr />
                                        </div>
                                    </div>
                                    <div class="row group">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.ACCOUNT_INFO.EMAIL' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component [isRequired]="true" controlName="email" [form]="form.controls.company"> </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.ACCOUNT_INFO.PASSWORD' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component [isRequired]="true" controlName="email" [form]="form.controls.company"> </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group-with-header">
                                    <div class="group-header row account-info d-flex">
                                        <div class="group-label">
                                            <span>{{'SIGN_UP_FORM.COMPANY_INFO.TITLE' | translate}}</span>
                                        </div>
                                        <div style="width: 15px;"></div>
                                        <div class="group-line-container col-lg-7 col-md-7 col-sm-12">
                                            <hr />
                                        </div>
                                    </div>
                                    <div class="row group">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.COMPANY_INFO.COMPANY_NAME' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component [isRequired]="true" controlName="name" [form]="form.controls.company"> </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.COMPANY_INFO.REGISTRATION_NUMBER' | translate }}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component controlName="registrationNumber" [isRequired]="false" [form]="form.controls.company"> </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row group">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.COMPANY_INFO.DOMAIN' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component controlName="domain" [isRequired]="true" [form]="form.controls.company"> </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="group-with-header">
                                    <div class="group-header row account-info d-flex">
                                        <div class="group-label">
                                            <span>{{'SIGN_UP_FORM.COMPANY_ADDRESS.TITLE' | translate}}</span>
                                        </div>
                                        <div style="width: 15px;"></div>
                                        <div class="group-line-container col-lg-7 col-md-7 col-sm-12">
                                            <hr />
                                        </div>
                                    </div>
                                    <div class="row group">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.COMPANY_ADDRESS.REGION' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component controlName="regionId" controlType="select" [isRequired]="true" [options]="regionOptions" [form]="form.controls.company.controls.address">
                                                    </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.COMPANY_ADDRESS.CITY' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component [isRequired]="true" controlName="cityId" controlType="select" [options]="citiesOptions" [form]="form.controls.company.controls.address">
                                                    </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row group">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12">{{'SIGN_UP_FORM.COMPANY_ADDRESS.VILLAGE' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component [isRequired]="true" controlName="villageId" controlType="select" [options]="villageOptions" [form]="form.controls.company.controls.address">
                                                    </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row input-row">
                                                <div class="label col-lg-4 col-md-4 col-sm-12 label">{{'SIGN_UP_FORM.COMPANY_ADDRESS.ADDRESS_LINE' | translate}}</div>
                                                <div class="col-lg-8 col-md-8 col-sm-12">
                                                    <app-input-field-component controlName="addressLine1" [isRequired]="true" [form]="form.controls.company.controls.address"> </app-input-field-component >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row submit-container">
                                    <div class="col-lg-10 col-md-10 col-sm-12 submit-btn" [ngClass]="{'disabled-btn disabled': !form.valid || isLoading}" (click)="submit($event)">
                                        <span>{{'SIGN_UP_FORM.SIGN_UP' | translate}}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="h-100 d-flex flex-column">-->
<!--    <div id="submitting-container" *ngIf="isLoading">-->
<!--        <p-progressSpinner>-->
<!--        </p-progressSpinner>-->
<!--    </div>-->
<!--    <div class="h-100 d-flex flex-column">-->
<!--        <form [formGroup]="form" class="h-100 d-flex flex-column" style="background: var(&#45;&#45;admin-main-page-background)">-->
<!--            <ng-container >-->
<!--                <div class="h-100 d-flex flex-column overflow-auto">-->
<!--                    <div class="h-100 d-flex flex-column overflow-auto">-->
<!--                        <form [formGroup]="form.controls.company" class="d-flex flex-column">-->
<!--                            <div class="sub-title">{{'GENERAL.COMPANY_BASIC_INFO' | translate}}:</div>-->
<!--                            <div class="d-flex flex-row" style="padding: 20px 15px">-->
<!--                                <div class="flex-1"></div>-->
<!--                            </div>-->
<!--                            <div class="d-flex flex-row" style="flex-wrap: wrap">-->
<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6"-->
<!--                                                           label="GENERAL.COMPANY_NAME"-->
<!--                                                           [isRequired]="true"-->
<!--                                                           controlName="name"-->
<!--                                                           [form]="form.controls.company">-->
<!--                                </app-field-input-field-component >-->

<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.EMAIL"-->
<!--                                                           [isRequired]="true" controlName="email"-->
<!--                                                           [form]="form.controls.company">-->
<!--                                </app-field-input-field-component >-->

<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.PHONE"-->
<!--                                                           [isRequired]="true" controlName="phone"-->
<!--                                                           [form]="form.controls.company">-->
<!--                                </app-field-input-field-component >-->
<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6"-->
<!--                                                           label="GENERAL.REGISTRATION_NUMBER"-->
<!--                                                           controlName="registrationNumber" [isRequired]="false"-->
<!--                                                           [form]="form.controls.company">-->
<!--                                </app-field-input-field-component >-->
<!--                            </div>-->
<!--                            <div class="sub-title">{{'GENERAL.COMPANY_ADDRESS' | translate}}:</div>-->
<!--                            <div class="d-flex flex-row" style="flex-wrap: wrap">-->
<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.REGION"-->
<!--                                                           controlName="regionId" controlType="select"-->
<!--                                                           [isRequired]="true"-->
<!--                                                           [options]="regionOptions"-->
<!--                                                           [form]="form.controls.company.controls.address">-->
<!--                                </app-field-input-field-component >-->

<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.CITY"-->
<!--                                                           [isRequired]="true" controlName="cityId" controlType="select"-->
<!--                                                           [options]="citiesOptions"-->
<!--                                                           [form]="form.controls.company.controls.address">-->
<!--                                </app-field-input-field-component >-->

<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6" label="GENERAL.VILLAGE"-->
<!--                                                           [isRequired]="true" controlName="villageId"-->
<!--                                                           controlType="select"-->
<!--                                                           [options]="villageOptions"-->
<!--                                                           [form]="form.controls.company.controls.address">-->
<!--                                </app-field-input-field-component >-->

<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6"-->
<!--                                                           label="GENERAL.ADDRESS_LINE1"-->
<!--                                                           controlName="addressLine1" [isRequired]="true"-->
<!--                                                           [form]="form.controls.company.controls.address">-->
<!--                                </app-field-input-field-component >-->
<!--                                <app-field-input-field-component  class="col-lg-3 col-md-4 col-sm-6"-->
<!--                                                           label="COMPANY.DOMAIN_NAME"-->
<!--                                                           controlName="domain" [isRequired]="true"-->
<!--                                                           [form]="form.controls.company">-->
<!--                                </app-field-input-field-component >-->
<!--                                </div>-->
<!--                        </form>-->
<!--                        <div class="flex-1">-->

<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="d-flex flex-row" style="padding: 10px 0; background: var(&#45;&#45;white)">-->
<!--                        <div class="flex-1"></div>-->
<!--                        <button type="button" class="controller-button cursor-pointer next d-flex"-->
<!--                                (click)="submit($event)"-->
<!--                                [disabled]="!this.form.controls.company.valid"-->
<!--                                style="align-items: center">-->
<!--                            <span style="padding: 0 20px;height: 28px;">{{ "ACTIONS.SUBMIT" | translate }}</span>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-container>-->
<!--        </form>-->
<!--    </div>-->
<!--</div>-->
