<div class="mob-container">
    <div class="header">
        {{('ACCOUNTING.MOB_DETAILED') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div class="col-12">
                <div class="inputs row">
                    <app-input-field-component class="col-6"
                                               label="BANK_TRANSFERS.CASH" [form]="form"
                                               controlName="cash"
                                               [isRequired]="true" controlType="number">
                    </app-input-field-component >
                </div>
                <div class="inputs row">
                    <app-input-field-component class="col-6"
                                               label="BANK_TRANSFERS.BANK_TRANSFER" [form]="form"
                                               controlName="bankTransfer" (change)="changeDocumentRequired()"
                                               [isRequired]="false" controlType="number">
                    </app-input-field-component >
                    <app-input-field-component class="col-6"
                                               label="BANK_TRANSFERS.DOCUMENT_NUMBER" [form]="form"
                                               controlName="bankTransferDocumentNumber"
                                               [isRequired]="form.value.bankTransfer !== 0"
                                               controlType="text">
                    </app-input-field-component >
                </div>
                <div class="inputs row">
                    <app-input-field-component class="col-6"
                                               label="BANK_TRANSFERS.CHEQUE" [form]="form"
                                               controlName="cheque" (change)="changeDocumentRequired()"
                                               [isRequired]="false" controlType="number">
                    </app-input-field-component >
                    <app-input-field-component class="col-6"
                                               label="BANK_TRANSFERS.DOCUMENT_NUMBER" [form]="form"
                                               controlName="chequeDocumentNumber"
                                               [isRequired]="form.value.cheque !== 0"
                                               controlType="text">
                    </app-input-field-component >
                </div>
            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div style="padding: 10px 30px">
                <div class="add-button cursor-pointer "
                     (click)="mob()"
                     [ngClass]="{'disabled-button disabled': !form.valid || isLoading}">
                    <i class="far fa-save"></i>
                    {{'ACTIONS.OK' | translate }}
                </div>
                <div class="reset-button cursor-pointer " (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
