<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
  <div class="header d-inline-flex justify-content-between">
    <div class="title" *ngIf="!isEditMode">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.NEW_AREA' | translate }}</div>
    <div class="title" *ngIf="isEditMode">{{ 'FULFILMENT.WAREHOUSE.ACTIONS.EDIT_AREA' | translate }}</div>
    <div class="close-icon" (click)="closeModal()">
      <img src="../../../../assets/icons/cancel.svg" alt="icon" />
    </div>
  </div>

  <div class="loader" *ngIf="isLoading">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <form [formGroup]="areaForm">

    <div class="inputs-container">
      <div class="row row-input">
        <div class="col-6 input">
          <div class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.AREAS.NAME' | translate }}</div>
          <div class="input-value">
            <input type="text" size="3" pInputText formControlName="name" />
          </div>
        </div>

        <div class="col-6 input">
          <div class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME' | translate }}</div>
          <div class="input-value">
            <input type="text" size="3" pInputText readonly formControlName="warehouseName" disabled />
          </div>
        </div>
      </div>
      <div class="row row-input">
        <div class="col-6 input">
          <div class="input-label container-type-label">{{ 'FULFILMENT.WAREHOUSE.AREAS.ZONE_TYPE' | translate }}</div>
          <div class="input-value">

            <p-dropdown styleClass="area-form-drop-down"
                        formControlName="zone"
                        [options]="zoneTypes" filter="true">
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-actions">
      <div class="done-action" (click)="submitForm()" [ngClass]="{'disabled-btn disabled': !areaForm.valid}">
        <span>{{'ACTIONS.SUBMIT' | translate}}</span>
      </div>
    </div>
  </form>
</div>
