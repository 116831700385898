<div class="h-100 d-flex flex-column">
    <div class="top-actions">
        <div class="d-flex justify-content-between">
            <div class="tabs-container">
                <div class="pending-tab cursor-pointer" routerLink="pending" routerLinkActive="selectedTab">
                    {{'COMPANY.PENDING' | translate}}
                </div>
                <div class="accepted-tab cursor-pointer" routerLink="accepted" routerLinkActive="selectedTab">
                    {{'COMPANY.ACCEPTED' | translate}}
                </div>
            </div>

            <div class="d-flex actions">
                <button type="button" class="btn btn-primary mb-2" (click)="editZonesLocations()">
                    {{ 'GENERAL.SYSTEM_LOCATIONS' | translate }}
                </button>
                <div class="d-flex mb-2">
                    <ng-select class="village-select" appDebounceSelectQuery
                               [(ngModel)]="selectedItem"
                               (click)="selectZoneToChange()"
                               [debounceTime]="1000" [observer]="searchVillagesObserver">
                        <ng-option
                                [value]="item"
                                *ngFor="let item of selectionItems">
                            {{ item.name }}
                        </ng-option>
                    </ng-select>
                    <button class="btn btn-sm btn-primary pick-button"
                            (click)="openChooseZoneLocation(!this.selectedItem, true)"
                            [disabled]="!this.selectedItem && this.selectionItems.length === 0">
                        <i class="fas fa-map-marker"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-1 overflow-auto">
        <router-outlet>
        </router-outlet>
    </div>
</div>
