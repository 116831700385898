<div class="main-container">
  <div class="header">
    <div class="title">{{'SUPPLIER.ADD_SUPPLIER'| translate }}</div>
  </div>

  <div class="flex-1 input-fields add-supplier-body">
    <div class="h-100 d-flex flex-column">
      <form *ngIf="form" class="add-user-form row" [formGroup]="form">
        <div class="row input-row">
          <div class="col-lg-6 col-sm-12 first-name input-filed-container no-padding">
            <div class="label">{{'SUPPLIER.BRAND_NAME' | translate}}</div>
            <div class="input-value">
              <input id="f-name-input" type="text" size="30" pInputText formControlName="brandName" />
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('brandName')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
          <div class="no-padding col-lg-6 col-sm-12 col-sm-12 last-name input-filed-container">
            <div class="label">{{'SUPPLIER.TRADE_NAME' | translate}}</div>
            <div class="input-value">
              <input id="l-name-input" type="text" size="30" pInputText formControlName="tradeName" />
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('tradeName')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-lg-6 col-sm-12 first-name input-filed-container no-padding">
            <div class="label">{{'SUPPLIER.ADDRESS' | translate}}</div>
            <div class="input-value">
              <input type="text" size="30" pInputText formControlName="address" />
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('address')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
          <div class="no-padding col-lg-6 col-sm-12 col-sm-12 last-name input-filed-container">
            <div class="label">{{'SUPPLIER.VAT_NUMBER' | translate}}</div>
            <div class="input-value">
              <input type="text" size="30" pInputText formControlName="vatNumber" />
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('vatNumber')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-lg-6 col-sm-12 first-name input-filed-container no-padding">
            <div class="label">{{'SUPPLIER.PHONE' | translate}}</div>
            <div class="input-value">
              <input type="text" size="30" pInputText formControlName="phone" />
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('phone')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
          <div class="no-padding col-lg-6 col-sm-12 col-sm-12 last-name input-filed-container">
            <div class="label">{{'SUPPLIER.PHONE_2' | translate}}</div>
            <div class="input-value">
              <input type="text" size="30" pInputText formControlName="phone2" />
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('phone2')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>


  <div class="footer">
    <div *ngIf="!isLoading" class="action-cancel" (click)="onCancel()" >
      {{ 'ACTIONS.CANCEL' | translate }}
    </div>
    <div *ngIf="isLoading" class="loading">
    <span><i class="fa fa-spinner fa-pulse"></i></span>
  </div>
    <div *ngIf="!isLoading" class="action-submit" (click)="onSubmit()" [ngClass]="{'disabled-btn disabled': !form.valid}">
      {{ 'ACTIONS.DONE' | translate }}
    </div>
  </div>
</div>
