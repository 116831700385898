<div [ngClass]="{'page-ltr': currentLang === 'en'}" style="z-index: 1111111">
    <div class="add-new-user-container">
        <div class="row" style="justify-content: space-between; padding-bottom: 20px !important;">
            <div class="title">
                <span>{{'THIRD_PARTY.ADD_NEW_THIRD_PART' | translate}}</span>
            </div>
            <div class="close-icon" (click)="exitAddNewUser()">
                <img src="../../../assets/icons/close-x.svg" alt=""/>
            </div>
        </div>
        <ng-container *ngIf="isLoading;else loaded">
            <div style="text-align: center;">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </ng-container>

        <ng-template #loaded>
            <div class="flex-1 input-fields">
                <div class="h-100 d-flex flex-column">
                    <form *ngIf="form" class="add-user-form row" [formGroup]="form" (submit)="onAddUser()">
                        <div class="inputs-container d-flex flex-wrap">
                            <div class="input-item">
                                <div class="first-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.FIRST_NAME' | translate}}</div>
                                    <div class="input-value">
                                        <input id="f-name-input" type="text" size="30" pInputText
                                               formControlName="firstName"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('firstName')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.FIRST_NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="last-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.FAMILY_NAME' | translate}}</div>
                                    <div class="input-value">
                                        <input id="l-name-input" type="text" size="30" pInputText
                                               formControlName="lastName"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('lastName')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.LAST_NAME_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="user-name input-filed-container">
                                    <div class="input-label">
                                        {{'USERS_MANAGEMENT.ADD_USER_FORM.EMAIL' | translate}}
                                    </div>
                                    <div class="input-value">
                                        <input (keyup)="validateUserNameAndEmail(form.get('email').value)"
                                               id="email-input" type="email" size="30" pInputText
                                               formControlName="email"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('email', 'required')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.EMAIL_REQUIRED' | translate }}
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyAndTouched('email', 'minlength')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.EMAIL_MIN_LENGTH' | translate:{min: form.get('email').getError('minlength').requiredLength} }}
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="user-name input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.HUB' | translate}}</div>
                                    <div class="input-value password-value">
                                        <app-input-field-component [isRequired]="true" controlName="hubId"
                                                                   [form]="form" controlType="select"
                                                                   [options]="hubsOptions"></app-input-field-component >
                                    </div>
                                </div>
                            </div>
                            <div class="input-item phone-number-container">
                                <div class="input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.PHONE' | translate }}</div>
                                    <app-phone-number-input [formGroupPass]="form" [formControlNamePass]="'phone'"
                                                            [formControlPass]="form.get('phone')"></app-phone-number-input>
                                    <div>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('phone', 'required')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_REQUIRED' | translate  }}
                                        </div>
                                        <div class="invalid-message"
                                             *ngIf="isInvalidDirtyOrTouched('phone', 'pattern')">
                                            {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_PATTERN' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-item phone-number-container">
                                <ng-container *ngIf="showPhone2">
                                    <div class="input-filed-container d-flex">
                                        <div class="flex-1">
                                            <div class="input-label">{{ 'USERS_MANAGEMENT.ADD_USER_FORM.PHONE' | translate }}
                                                2
                                            </div>
                                            <app-phone-number-input [formGroupPass]="form"
                                                                    [formControlNamePass]="'phone2'"
                                                                    [formControlPass]="form.get('phone2')"></app-phone-number-input>
                                            <div>
                                                <div class="invalid-message"
                                                     *ngIf="isInvalidDirtyOrTouched('phone2', 'required')">
                                                    {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_REQUIRED' | translate  }}
                                                </div>
                                                <div class="invalid-message"
                                                     *ngIf="isInvalidDirtyOrTouched('phone2', 'pattern')">
                                                    {{ 'USERS_MANAGEMENT.VALIDATION.PHONE_PATTERN' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="remove-phone d-flex align-items-center cursor-pointer"
                                             (click)="toggleExtraPhone()">
                                            <img class="pl-3 pr-3" src="../../assets/icons/add_package/remove.svg"
                                                 alt="">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!showPhone2">
                                    <div class="add-phone align-items-center d-flex h-100 cursor-pointer">
                                        <img class=" pl-3 pr-3" src="../../assets/icons/add_package/add_phone.svg"
                                             class="active"
                                             (click)="toggleExtraPhone()">
                                    </div>

                                </ng-container>
                            </div>
                            <div class="input-item">
                                <app-drop-down [options]="thirdPartyTypes"
                                              [label]="'SHIPMENT.TABLE.SHIPMENT_METHOD'"
                                              formControlName = "thirdPartyType"
                                              [formControlObj]="form.get('thirdPartyType')"
                                              [dropdownNameField]="'name'"
                                              [showClear]="false"></app-drop-down>
                            </div>
                            <div class="input-item">
                                <div class="address input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.ADDRESS' | translate}}</div>
                                    <div class="input-value address-input-value">
                                        <app-input-field-component
                                                [form]="form"
                                                field="label"
                                                [dataKey]="'value'"
                                                [forceSelection]="true"
                                                [displayDropDown]="true"
                                                (onAutoCompleteClear)="updateZoneForm($event,'village')"
                                                [searchSuggestions]="combinedOptions"
                                                (onSearch)="initVillage(0, 0,$event.query)"
                                                controlName="combined"
                                                [options]="combinedOptions"
                                                [isRequired]="true"
                                                controlType="autoComplete"
                                        >
                                        </app-input-field-component >
                                    </div>
                                </div>
                            </div>
                            <div class="input-item">
                                <div class="address-line1 input-filed-container">
                                    <div class="input-label">{{'USERS_MANAGEMENT.ADD_USER_FORM.ADDRESS_DETAILS' | translate}}</div>
                                    <div class="input-value password-value">
                                        <input id="address-input2" type="text" size="30" pInputText
                                               formControlName="addressLine1"/>
                                    </div>
                                    <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('addressLine1')">
                                        {{ 'USERS_MANAGEMENT.VALIDATION.ADDRESS_LINE_REQUIRED' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row splitter-container w-100 mt-5">
                            <div class="splitter"></div>
                        </div>
                        <div class="row actions actions-user" style="width: 100%;">
                            <div class="cancel cursor-pointer" (click)="exitAddNewUser()">
                                <span>{{'ACTIONS.CANCEL' | translate}}</span>
                            </div>
                            <div *ngIf="isLoading" class="loading">
                                <span><i class="fa fa-spinner fa-pulse"></i></span>
                            </div>
                            <div *ngIf="!isLoading"
                                 class="{{doneActionStyleClass +  ' cursor-pointer' + ' add-user-btn'}}"
                                 (click)="onAddUser()"
                                 [ngClass]="{'disabled-btn disabled': !form?.valid || disableForm}">
                                <span *ngIf="!isEdit">{{'ACTIONS.DONE' | translate}}</span>
                                <span *ngIf="isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<p-toast [baseZIndex]="toastZIndex">
</p-toast>
