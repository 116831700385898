import {Component, OnInit, ViewChild} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {CompaniesService} from '../services/companies.service';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {UserService} from '../../shared/services/user.service';
import {RolesService} from '../../shared/services/roles/roles.service';
import {ItemModel} from '../../administration/models/item.model';
import {ZonesService} from '../../administration/services/zones.service';
import {ADMINISTRATION_CONSTANTS} from '../../administration/administration-constants';
import {ChooseZoneLocationComponent} from '../../shared/components/choose-zone-location/choose-zone-location.component';
import {Observer} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-companies-component',
    templateUrl: 'companies.component.html',
    styleUrls: ['companies.component.scss']
})
export class CompaniesComponent implements OnInit {
    @ViewChild('table', {static: false}) table;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public companies;
    public pageNumber = 0;
    public totalRecords = 0;
    public pageSize = 10;
    public searchContent = '';
    public fromDate;
    public toDate;

    // To be moved to addresses configuration panel
    selectedItem: ItemModel;
    previousSelectedItem: ItemModel;
    selectionItems: ItemModel[] = [];
    items: ItemModel[] = [];
    zonesUrl = ADMINISTRATION_CONSTANTS.GET_SYSTEM_VILLAGES;
    ZONES_PAGE_SIZE = 30;
    searchVillagesObserver: any;

    constructor(private companiesService: CompaniesService,
                public datePipe: DatePipe,
                public modalService: NgbModal,
                public messageService: MessageService,
                public userService: UserService,
                public roleService: RolesService,
                public translateService: TranslateService,
                public zonesService: ZonesService) {

    }

    ngOnInit(): void {
        this.searchVillagesObserver = {
            next: (data: any) => {
                if (data.length !== 0) {
                    const params = this.createParams(data);
                    this.zonesService
                        .getZones(this.zonesUrl, params).subscribe(
                        (response: any) => {
                            this.selectionItems = response.data;
                        }
                    );
                }
            }
        };
    }

    // To be moved to addresses configuration panel
    fetchZones() {
        const params = this.createParams();
        return this.zonesService.getZones(this.zonesUrl, params);
    }

    public createParams(search?: string) {
        const params = {
            pageSize: this.ZONES_PAGE_SIZE,
            page: 1,
        };

        if (search && search !== '') {
            params['search'] = search;
        }
        return params;
    }

    editZonesLocations() {
        if (this.items.length === 0) {
            this.fetchZones().subscribe(
                (response: any) => {
                    this.items = response.data;
                    this.openChooseZoneLocation(true, false);
                }, (error) => {

                }
            );
        } else {
            this.openChooseZoneLocation(true, false);
        }
    }

    selectZoneToChange() {
        if (this.selectedItem && this.selectedItem !== this.previousSelectedItem) {
            this.previousSelectedItem = this.selectedItem;
            this.openChooseZoneLocation(false, false);
        }
    }

    openChooseZoneLocation(multiple: boolean, onlySelected: boolean) {
        const modal = this.modalService.open(ChooseZoneLocationComponent, <any>{
            backdrop: 'static',
            size: 'md'
        });
        if (multiple) {
            modal.componentInstance.multipleItems = true;
            modal.componentInstance.dataPageSize = this.ZONES_PAGE_SIZE;
            modal.componentInstance.dataUrl = this.zonesUrl;
            if (onlySelected) {
                modal.componentInstance.lazyFetching = false;
                modal.componentInstance.items = this.selectionItems;
            } else {
                modal.componentInstance.lazyFetching = true;
                modal.componentInstance.items = this.items;
            }
        } else {
            modal.componentInstance.selectedItem = this.selectedItem;
        }

        modal.result.then((response) => {
            if (response.isSuccess) {
                this.translateService.get('VILLAGE_LOCATION_SET_SUCCESSFULLY').subscribe(
                    (value) => {
                        this.messageService.add({severity: 'success', detail: value});
                    }
                );
            }
        }, (reason => {

        }));
    }

}
