<div class="main-container">
  <div class="header">
    <div class="action" (click)="showNewVehicleType($event)">
      <i class="fas fa-plus"></i>
      <span>{{ 'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.NEW' | translate }}</span>
    </div>
    <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
  </div>
  <div class="list-container" *ngIf="vehicleTypes.length > 0 || isShowNewVehicleType">

    <div class="labels-header">
      <span>{{ 'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.NAME' | translate }}</span>
      <span>{{ 'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.ARABIC_NAME' | translate }}</span>
    </div>

    <div class="vehicle-type-item" [ngClass]="{'invisible-item': !isShowNewVehicleType}">
      <input #newVehicleTypeNameInput type="text" class="inplace-input" pInputText [(ngModel)]="newVehicleTypeName">
      <input type="text" class="inplace-input" pInputText [(ngModel)]="newVehicleTypeArabicName">
      <div class="item-actions">
        <div class="edit-action"  (click)="submitNewVehicleType()">
          <i class="fas fa-check"></i>
        </div>
        <div class="edit-action"  (click)="cancelNewVehicleType()">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>

    <div *ngFor="let vehicleType of vehicleTypes" class="vehicle-type-item">

      <div class="inplace-container">
        <p-inplace #inplace (onActivate)="activateInplace(inplace, $event)">
          <span pInplaceDisplay>{{ vehicleType.name }}</span>
          <span pInplaceContent>
          <input class="inplace-input" (keydown.enter)="toggleInplace($event, inplace, vehicleType, true)"
                 (keydown.escape)="toggleInplace($event, inplace, vehicleType)"
                 type="text" pInputText [(ngModel)]="vehicleType.newName">
        </span>
        </p-inplace>
      </div>

      <div class="inplace-container">
        <p-inplace #arabicInplace (onActivate)="activateInplace(arabicInplace, $event)">
        <span pInplaceDisplay>
          {{ vehicleType.arabicName }}
        </span>
          <span pInplaceContent>
          <input class="inplace-input" (keydown.enter)="toggleInplace($event, arabicInplace, vehicleType, true)"
                 (keydown.escape)="toggleInplace($event, arabicInplace, vehicleType)"
                 type="text" pInputText [(ngModel)]="vehicleType.newArabicName">
        </span>
        </p-inplace>
      </div>


      <div class="item-actions" [ngClass]="{'disabled-actions': isLoading}">
        <div class="edit-action" *ngIf="inplace.active" (click)="toggleInplace($event, inplace, vehicleType, true, arabicInplace)">
          <i class="fas fa-check"></i>
        </div>
        <div class="edit-action" (click)="onEdit($event, inplace, arabicInplace, vehicleType)">
          <i class="fas fa-times" *ngIf="inplace.active"></i>
          <i class="fas fa-pen" *ngIf="!inplace.active"></i>
        </div>
        <div class="delete-action" *ngIf="!inplace.active" (click)="deleteVehicleType(vehicleType)">
          <i class="fas fa-trash"></i>
        </div>
      </div>
    </div>
  </div>
</div>
