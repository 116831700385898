<div class="enter-value-container">
  <div class="header">
    {{title | translate }}
    <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

  </div>
  <form [formGroup]="form" *ngIf="form">
    <div class="input-fields row">
      <div class="col-12 packages-container">
        <div class="inputs row">
          <app-input-field-component class="col-12" label="{{inputLabel}}" [form]="form"
                                     controlName="num"
                                     [isRequired]="true" controlType="number">
          </app-input-field-component >
        </div>
      </div>
    </div>
    <div class="row col-12">
      <div class="flex-1"></div>
      <div style="padding: 10px 30px">
        <div class="add-button cursor-pointer "
             (click)="finish()"
             [ngClass]="{'disable': !form.valid || isLoading}">
          <i class="far fa-save"></i>
          {{'ACTIONS.OK' | translate }}
        </div>
        <div class="reset-button cursor-pointer " (click)="closeModal()">
          <i class="fas fa-times"></i>
          {{"ACTIONS.CANCEL" | translate}}
        </div>
      </div>
    </div>
  </form>
</div>
