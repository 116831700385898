import {Component, Input, OnInit} from '@angular/core';
import {EmailModel} from '../models/email.model';
import {UserService} from '../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
    @Input() email: EmailModel;
    @Input() class;
    public receiverName: string;
    constructor(private userService: UserService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.receiverName = this.email.receiverNames.reduce((fullReceiver: string, receiver: string): string => {
            return fullReceiver + ', ' + receiver;
        });
    }

    getSenderName(){
        if (this.userService.userInfo.id === this.email.senderId) {

            return `${this.translate.instant('GENERAL.TO')}: ${ this.receiverName }`;
        } else {
            return this.email.senderName;
        }
    }

}
