<div [ngClass]="{'page-ltr': selectedLanguage === 'en'}">
    <div class="main-container">
        <div class="login-container h-100" [ngClass]="{'sign-up-container': isSignUp}">
            <div class="header row">
                <div  *ngIf="HIDE_LOGESTECHS_LOGO_FOR.indexOf(companyId) === -1" class="col-lg-2 col-md-2 col-sm-12 logestechs-logo">
                    <img src="../../assets/icons/login/logestechs-logo.svg" />
                </div>
                <div class="col-lg-10 col-md-10 col-sm-12 d-flex line-language" [ngClass]="{'col-lg-12 col-md-12 col-sm-12': HIDE_LOGESTECHS_LOGO_FOR.indexOf(companyId) > -1}">
                    <div class="line-container">
                        <div class="line">
                            <hr />
                        </div>
                    </div>
                    <div class="company-logo" *ngIf="(companyLogo | async)">
                        <img [src]="companyLogo|async"/>
                    </div>
                    <div class="pl-4" *ngIf="!(companyLogo | async)">
                    </div>
                    <div>
                        <select class="login-language-selector" [(ngModel)]="selectedLanguage" (change)="onLangChange()">
                            <option value="ar">
                                {{'GENERAL.ARABIC'|translate}}
                            </option>
                            <option value="en">
                                {{'GENERAL.ENGLISH'|translate}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" [ngClass]="{'d-flex justify-content-center': HIDE_LOGESTECHS_LOGO_FOR.indexOf(companyId) > -1, 'customized-sar-company-background': ADD_CUSTOMIZED_BACKGROUND.indexOf(companyId) > -1}" *ngIf="!isSignUp">
                <div *ngIf="HIDE_LOGESTECHS_LOGO_FOR.indexOf(companyId) === -1" class="col-lg-6 col-md-4 col-sm-12 main-image-container hidden-xs">
                    <div class="main-image"></div>
                </div>
                <div class="col-lg-6 col-md-8 col-sm-12 outlet-container" [ngClass]="{'customized-company-outlet-container': HIDE_LOGESTECHS_LOGO_FOR.indexOf(companyId) > -1}">
                    <router-outlet> </router-outlet>
                </div>
            </div>
            <div class="row" *ngIf="isSignUp">
                <div class="col-lg-5 col-md-5 col-sm-12 main-image-container hidden-xs">
                    <div class="sign-up-main-page"></div>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12" style="padding: 0;">
                    <router-outlet> </router-outlet>
                </div>
            </div>
        </div>
        <div class="footer row" [ngClass]="{'visibility-hidden': hideFooterDetails}">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="d-flex contact-us-links">
                    <div class="message-icon-container icon">
                        <div class="message-icon">
                            <img src="../../assets/icons/login/footer-message-icon.svg" />
                        </div>
                    </div>
                    <div class="before-splitter-hidden-space"></div>
                    <div class="splitter"></div>
                    <div class="after-splitter-hidden-space"></div>
                    <div class="social-media-links d-flex">
                        <div class="social-media-icon cursor-pointer" (click)="redirectTo('LINKED_IN')">
                            <img src="../../assets/icons/login/linkedIn-icon.svg" />
                        </div>
                        <div style="width: 24px;"></div>
                        <div class="social-media-icon cursor-pointer" (click)="redirectTo('INSTAGRAM')">
                            <img src="../../assets/icons/login/Instagram-icon.svg" />
                        </div>
                        <div style="width: 24px;"></div>
                        <div class="social-media-icon cursor-pointer" (click)="redirectTo('FACEBOOK')">
                            <img src="../../assets/icons/login/facebook-icon.svg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 other-links d-flex" style="justify-content: flex-end;">
                <div class="row">
                    <div class="d-flex links-group-container">
                        <div class="links-group">
                            <div class="link-item">
                                <span>Pricing </span>
                            </div>
                            <div class="link-item">
                                <span>Integrations</span>
                            </div>
                            <div class="link-item">
                                <span>API</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex links-group-container">
                        <div class="links-group">
                            <div class="link-item">
                                <span>Last Mile Delivery</span>
                            </div>
                            <div class="link-item">
                                <span>LogesTechs Eat</span>
                            </div>
                            <div class="link-item">
                                <span>LogesTechs Corporate</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex links-group-container">
                        <div class="links-group">
                            <div class="link-item">
                                <span>FAQs</span>
                            </div>
                            <div class="link-item">
                                <span>Resources Center</span>
                            </div>
                            <div class="link-item">
                                <span>Blog</span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex links-group-container last-group">
                        <div class="links-group">
                            <div class="link-item">
                                <span>Privacy Policy</span>
                            </div>
                            <div class="link-item">
                                <span>Terms of Use</span>
                            </div>
                            <div class="link-item">
                                <span>Sitemap</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row copy-rights">
            <span [ngClass]="{'visibility-hidden': hideFooterDetails}">© Copyright LogesTechs 2023. All Right Reserved.</span>
        </div>
    </div>
</div>
