import {Component, OnInit, ViewChild} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CompaniesService} from '../services/companies.service';
import {ChooseDateAndNumberComponent} from '../../shared/components/choose-date-and-number/choose-date-and-number.component';
import {CompanyBillingPaymentComponent} from './company-billing-payment/company-billing-payment.component';

@Component({
    selector: 'app-company-billing',
    templateUrl: './company-billing.component.html',
    styleUrls: ['./company-billing.component.scss']
})
export class CompanyBillingComponent implements OnInit {

    public isLoading = false;
    public companies = [];
    public pageNumber = 0;
    public totalRecords = 0;
    public searchContent = '';
    public startDate;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;

    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public selectedLanguage = '';
    public selectedTab = '';
    @ViewChild('table', {static: false}) table;
    public isBilling = false;
    public isManagement = false;
    public isHistory = false;
    public date = null;
    public totalRecordLabel = '';
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    constructor(private datePipe: DatePipe,
                private modalService: NgbModal,
                private translateService: TranslateService,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthenticationService,
                private confirmationService: ConfirmationService,
                private companyService: CompaniesService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.selectedTab = this.route.routeConfig.path;
        this.setSelectedPage();
        this.selectedLanguage = this.translateService.currentLang;
        this.date  = new Date();
    }
    public setSelectedPage() {
        switch (this.selectedTab) {
            case 'company-billing' :
                this.isBilling = true;
                this.totalRecordLabel = 'COMPANY.NUMBER_OF_PAYMENTS';
                break;
            case 'company-management' :
                this.isManagement = true;
                this.totalRecordLabel = 'COMPANY.NUMBER_OF_COMPANIES';
                break;
            case 'notification-history' :
                this.isHistory = true;
                this.totalRecordLabel = 'COMPANY.NUMBER_OF_NOTIFICATIONS';
                break;
        }
    }
    public initCompanies() {
        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery();
        let getCompaniesData;
        if (this.isBilling) {
            getCompaniesData = this.companyService.getCompanisBillingPayments(URLQuery);
        } else if (this.isManagement) {
            getCompaniesData = this.companyService.getCompanyBillings(URLQuery);
        }
        getCompaniesData.subscribe(
            (response: any) => {
                this.companies = response.data;
                if (!this.isBilling && response.startNotificationDate) {
                    this.startDate = new Date(response.startNotificationDate);
                }
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, () => {
                this.spinnerState = SpinnerState.EMPTY;
            }
        );
    }
    public getNotificationHistory() {
        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery();
        this.companyService.getNotificationHistory(URLQuery).subscribe(
            (response: any
            ) => {
                this.companies = response.data;
                this.totalRecords = response.totalRecordsNo;
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, () => {
                this.spinnerState = SpinnerState.EMPTY;
            }
        );

    }
    public getTooltipMessage(isAllowBilling: boolean , isBlockBilling: boolean) {
         if (!this.isDisable(isAllowBilling , isBlockBilling)) { return; }
        if (!isAllowBilling) {
            return  'COMPANY.NOT_ENABLED_COMPANY_BILLING_MESSAGE';
        } else if (isBlockBilling) {
            return 'COMPANY.BLOCK_COMPANY_BILLING_MESSAGE';
        }
     }
    public onSearch() {
        this.pageNumber = 0;
        this.table.reset();
    }

    public loadCompaniesLazy($event) {
        this.pageNumber = $event.first / $event.rows;
        this.pageSize = $event.rows;
        if (this.isBilling || this.isManagement) {
            this.initCompanies();
        } else {
            this.getNotificationHistory();
        }
    }
    public createQuery() {
        let result = `?pageSize=${this.pageSize}&page=` + (this.pageNumber + 1);
        if (this.searchContent !== '') {
            result += '&search=' +  this.searchContent;
        }
        if (this.date) {
            result += '&date=' + this.datePipe.transform(this.date, 'yyyy-MM-dd');
            result += '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return result;
    }

    public onChangeTab(path) {
        this.router.navigate(['super-admin/' + path]);
    }
    public addCompanyPayment(id, checkBoxEl) {
        this.isLoading = true;
        const resetCompanyIsPaid = this.companyService.resetIsPaidCompany(id);
        if (!checkBoxEl.modelValue) {
            resetCompanyIsPaid.subscribe(
                (res: any) => {
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
            return;
        } else {
            const modal = this.modalService.open(ChooseDateAndNumberComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.headerLabel = 'COMPANY.ADD_COMPANY_PAYMENT';
            modal.componentInstance.dateLabel = 'COMPANY.PAYMENT_DATE';
            modal.componentInstance.numberLabel = 'COMPANY.PAID_AMOUNT';
            modal.result.then(
                (data: { isSuccess: true, date: string, number: number }) => {
                    this.isLoading = false;
                    if (data.isSuccess) {
                        this.companyService.addCompanyPayment(id , data.number , data.date).subscribe(
                            (res: any) => {
                                this.isLoading = false
                            }, error => {
                                console.error(error);
                                this.isLoading  = false;
                            }
                        );
                    } else {
                        checkBoxEl.modelValue = false;
                        resetCompanyIsPaid.subscribe(
                            (res: any) => {
                                this.isLoading = false;
                            }, error => {
                                console.error(error);
                                this.isLoading = false;
                            }
                        );
                    }
                }
            ).catch(
                (error) => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
        }

    }
    public toggleIsNotifyCompany(companyId, checkBoxEl) {
        this.translateService.get(['ALERTS.CONFIRM_NOTIFY_COMPANY', 'ALERTS.CONFIRM_NOT_NOTIFY_COMPANY', 'ACTIONS.OK', 'ACTIONS.CANCEL']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: checkBoxEl.modelValue ?  res['ALERTS.CONFIRM_NOTIFY_COMPANY'] : res['ALERTS.CONFIRM_NOT_NOTIFY_COMPANY'],
                accept: () => {
                    this.isLoading = true;
                        this.companyService.notifyCompany(companyId, checkBoxEl.modelValue).subscribe(
                            (response: any) => {
                                this.isLoading = false;
                            }, error => {
                                console.error(error);
                                this.isLoading = false;
                            }
                        );
                },
                reject: () => {
                    checkBoxEl.modelValue = !checkBoxEl.modelValue;
                },
                acceptLabel: res['ACTIONS.OK'],
                rejectLabel:  res['ACTIONS.CANCEL'],
                rejectVisible: true
            });
        });
    }
    public toggleIsBlockBillingCompany(companyId, checkBoxEl, status) {
        this.translateService.get(['ALERTS.CONFIRM_BLOCK_COMPANY', 'ALERTS.CONFIRM_BLOCK_BILLING_COMPANY', 'ACTIONS.OK', 'ACTIONS.CANCEL']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: status === 'BLOCK_BILLING' ?  res['ALERTS.CONFIRM_BLOCK_COMPANY'] : res['ALERTS.CONFIRM_BLOCK_BILLING_COMPANY'],
                accept: () => {
                    this.isLoading = true;
                    this.companyService.modifyBilling(companyId, checkBoxEl.modelValue, status).subscribe(
                        (response: any) => {
                            this.isLoading = false;
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        }
                    );
                },
                reject: () => {
                    checkBoxEl.modelValue = !checkBoxEl.modelValue;
                },
                acceptLabel: res['ACTIONS.OK'],
                rejectLabel:  res['ACTIONS.CANCEL'],
                rejectVisible: true
            });
        });
    }
    public toggleDisable(checkBoxType, checkBoxEl , companyId) {
        switch (checkBoxType) {
            case 'isPaidDoneChkBx':
                    this.addCompanyPayment(companyId, checkBoxEl);
                break;
            case 'isNotifyChkBx':
                this.toggleIsNotifyCompany(companyId, checkBoxEl);
                break;
            case 'BLOCK_BILLING':
                this.toggleIsBlockBillingCompany(companyId, checkBoxEl, checkBoxType);
                break;
            case 'ALLOW_BILLING':
                this.toggleIsBlockBillingCompany(companyId, checkBoxEl, checkBoxType);
                break;
        }
    }

    public submitAmount(isPaidAmount: boolean, Id: number, amount) {
        let addCompanyPayment;
        let translateService;
        if (isPaidAmount) {
            addCompanyPayment = this.companyService.addCompanyBillingPayment(Id, amount);
            translateService = this.translateService.get(
                'ALERTS.UPDATE_COMPANY_BILLING_PAYMENT_SUCCESSFULLY'
            );
        } else {
            addCompanyPayment = this.companyService.addCompanyBillingCost(Id, amount);
            translateService = this.translateService.get(
                'ALERTS.UPDATE_COMPANY_BILLING_COST_SUCCESSFULLY'
            );
        }
        this.translateService.get(['ALERTS.CONFIRM_ADD_REQUIRED_AMOUNT_FROM_COMPANY', 'ACTIONS.OK', 'ACTIONS.CANCEL']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.CONFIRM_ADD_REQUIRED_AMOUNT_FROM_COMPANY'],
                accept: () => {
                    this.isLoading = true;
                    addCompanyPayment.subscribe(
                        (response) => {
                            translateService.subscribe(
                                (data) => {
                                    this.messageService.add({severity: 'success', detail: data});
                                }
                            );
                            this.isLoading = false;
                        }, error => {
                            this.isLoading = false;
                            console.error((error));
                        }
                    );
                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: res['ACTIONS.OK'],
                rejectLabel:  res['ACTIONS.CANCEL'],
                rejectVisible: true
            });
        });
    }


    setAccountablePackage = (id, val) => {
        this.confirmationService.confirm({
            message: this.translateService.instant('ALERTS.CONFIRM_SET_ACCOUNTABLE_PACKAGES'),
            accept: () => {
                this.isLoading = true;
                this.companyService.setAccountablePackages(id, val).subscribe(
                    () => {
                        this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                        this.isLoading = false;
                    }, error => {
                        this.isLoading = false;
                        console.error((error));
                    }
                );
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translateService.instant('ACTIONS.OK'),
            rejectLabel:  this.translateService.instant('ACTIONS.CANCEL'),
            rejectVisible: true
        });
    }

    public startCompanyBilling() {
        this.isLoading = true;
        const modal = this.modalService.open(ChooseDateAndNumberComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.headerLabel = 'COMPANY.COMPANY_BILLING_SETTINGS';
        modal.componentInstance.dateLabel = 'COMPANY.START_BILLING_DATE';
        modal.componentInstance.numberLabel = 'COMPANY.CHANCE_DAYS_NUMBER';
        modal.result.then(
            (data: { isSuccess: true, date: string, number: string }) => {
                if (data.isSuccess) {
                    this.companyService.startCompanyBilling(data.date, String(data.number)).subscribe(
                        (response: any) => {
                            this.translateService.get(
                                'ALERTS.START_COMPANY_BILLING'
                            ).subscribe(
                                (res) => {
                                    this.messageService.add({severity: 'success', detail: res});
                                }
                            );
                            this.initCompanies();
                            this.isLoading = false;
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        }
                    );
                } else { this.isLoading = false; }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public transformDate(date) {
        if (date) {
            return this.datePipe.transform(date , 'yyyy-MM-dd');
        }
    }
    public setCompanyTempDisableDate(tempDisableDate, companyId) {
        if (!tempDisableDate.value) {
            return;
        }

        this.translateService.get(['ALERTS.TEMP_DISABLE_DATE_CONFIRMATION', 'ACTIONS.OK', 'ACTIONS.CANCEL']
        ).subscribe((res: any) => {
            this.confirmationService.confirm({
                message: res['ALERTS.TEMP_DISABLE_DATE_CONFIRMATION'],
                accept: () => {
                    this.isLoading = true;
                        this.companyService.setCompanyTempDisableDate(tempDisableDate.value, companyId).subscribe(
                            (response: any) => {
                                this.isLoading = false;
                                this.translateService.get(
                                    'ALERTS.COMPANY_TEMP_DISABLE_DATE_UPDATED_SUCCESSFULLY'
                                ).subscribe(
                                    (data) => {
                                        this.messageService.add({severity: 'success', detail: data});
                                    }
                                );
                                this.initCompanies();
                            }, error => {
                                console.error(error);
                                this.isLoading = false;
                            }
                        );

                },
                reject: () => {
                    this.isLoading = false;
                },
                acceptLabel: res['ACTIONS.OK'],
                rejectLabel:  res['ACTIONS.CANCEL'],
                rejectVisible: true
            });
        });
    }
    public updateNotificationSetting(model) {
        this.isLoading = true;
        const modal = this.modalService.open(ChooseDateAndNumberComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.headerLabel = 'COMPANY.COMPANY_BILLING_SETTINGS';
        modal.componentInstance.dateLabel = 'COMPANY.START_BILLING_DATE';
        modal.componentInstance.numberLabel = 'COMPANY.CHANCE_DAYS_NUMBER';
        modal.componentInstance.isEditMode = true;
        modal.componentInstance.model = model;
        modal.result.then(
            (data: { isSuccess: true, date: string, number: string }) => {
                if (data.isSuccess) {
                    this.companyService
                        .updateNotificationSetting(model.id, data.date, data.number).subscribe(
                        (response: any) => {
                            this.translateService.get(
                                'UPDATED'
                            ).subscribe(
                                (res) => {
                                    this.messageService.add({severity: 'success', detail: res});
                                }
                            );
                            this.getNotificationHistory();
                            this.isLoading = false;
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        }
                    );
                } else { this.isLoading = false; }
            }
        ).catch(
            (error) => {
                this.isLoading = false;
            }
        );
    }

    public deleteCompanyPayment(id) {
        this.isLoading = true;
        this.companyService.deleteCompanyPayment(id).subscribe(
                  (response: any) => {
                         this.translateService.get('DELETED').subscribe(
                                (res) => {
                                    this.messageService.add({severity: 'success', detail: res});
                                }
                            );
                            this.initCompanies();
                            this.isLoading = false;
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        });
     }
    public isDisable(isAllowBilling: boolean , isBlockBilling: boolean){
        return !isAllowBilling || isBlockBilling;
    }
    public getLocalDate(date) {
        if (date) {
            return new Date(date).toLocaleDateString();
        }
    }
    public addCompanyBillingPayment() {
        const modal = this.modalService.open(CompanyBillingPaymentComponent, <any>{
            backdrop: 'static',
            size: 'md'
        });
        modal.componentInstance.startNotificationDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
        modal.result.then(
            (data: { isSuccess: true, date: string, number: number }) => {
                if (data.isSuccess) {
                    this.initCompanies();
                    this.isLoading = false;
                } else { this.isLoading = false; }
            }
        ).catch(
            (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }
    public isDisableAddCompany() {
        return this.startDate === ''
            || this.startDate === null
            || this.startDate === undefined;
    }
    private printReport(companyId, $event) {
        event.stopPropagation();
        this.isLoading = true;
        this.companyService.billingPdf(companyId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.isLoading = false;
            });
    }
    openExcel() {
        this.isLoading = true;
        this.companyService.companyPaymentExcel(this.createQuery()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            console.error(error);
        });
    }

    exportReturnedBeforeArrivalPackages() {
        this.isLoading = true;
        this.companyService.ExportReturnedBeforeArrivalExcel(this.createQuery()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            console.error(error);
        });
    }
}
