import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Calendar} from 'primeng/calendar';
import {DatePipe} from '@angular/common';
import {DateRangePickerService} from '../../services/date-range-picker.service';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
    @Input() label: string;
    @Input() cssStyle = {};
    @Input() formControlObj: FormControl;
    @Input() showLabel = false;

    enableSubmitinCustomDateRange = 0;

    dateRangeForm: FormGroup;
    pickerOptionsRanges;

    @Output() onSelect = new EventEmitter<{ start: Date, end: Date }>();
    @Output() onShow = new EventEmitter<{ start: Date, end: Date }>();
    @Output() onClose = new EventEmitter<{ start: Date, end: Date }>();

    @ViewChild('calendar')
    calendar: Calendar;

    @ViewChild('rangePickerPanel')
    rangePickerPanel: OverlayPanel;

    constructor(private formBuilder: FormBuilder,
                private dateRangePickerService: DateRangePickerService,
                private datePipe: DatePipe) {
    }

    ngOnInit(): void {
        this.initForm();
        this.dateRangePickerService.getDateRangePickerRanges(false).then(ranges => {
            this.pickerOptionsRanges = ranges;
        });
    }

    selected($event) {
        if(this.enableSubmitinCustomDateRange === 2) {
            this.enableSubmitinCustomDateRange = 0;
        }
        this.enableSubmitinCustomDateRange ++;
        this.onSelect.emit($event);
    }

    initForm() {
        const currentDate = new Date();
        this.dateRangeForm = this.formBuilder.group({
            range: [[new Date(), new Date()]],
            newRange: [[new Date(), new Date()]],
            pickerOption: ['TODAY'],
            newPickerOption: ['TODAY'],
        });

        this.dateRangeForm.get('newPickerOption').valueChanges.subscribe(newOption => {
            this.onOptionChange(newOption);
        });
    }

    onPickRange(event, panel: OverlayPanel) {
        panel.toggle(event);
    }

    submit(event, panel: OverlayPanel) {
        this.dateRangeForm.get('range').patchValue(this.dateRangeForm.get('newRange').value);
        this.dateRangeForm.get('pickerOption').patchValue(this.dateRangeForm.get('newPickerOption').value);
        panel.hide();
        this.onSelect.emit({
            start: this.dateRangeForm.get('range').value[0],
            end: this.dateRangeForm.get('range').value[1]
        });
    }

    cancel(event, panel: OverlayPanel) {
        this.dateRangeForm.get('newRange').patchValue(this.dateRangeForm.get('range').value);
        this.dateRangeForm.get('newPickerOption').patchValue(this.dateRangeForm.get('pickerOption').value);
        panel.hide();
    }

    onOptionChange(newOption) {
        if (newOption !== 'CUSTOM') {
            this.enableSubmitinCustomDateRange = 0;
            this.dateRangeForm.get('pickerOption').patchValue(this.dateRangeForm.get('newPickerOption').value);
            const date = this.pickerOptionsRanges.filter(range => range.key === newOption)[0].date;
            this.dateRangeForm.get('range').patchValue([new Date(date[0]), new Date(date[1])]);
            this.dateRangeForm.get('newRange').patchValue([new Date(date[0]), new Date(date[1])]);
            this.onSelect.emit({
                start: this.dateRangeForm.get('range').value[0],
                end: this.dateRangeForm.get('range').value[1]
            });
            this.rangePickerPanel.hide();
        }
    }

    getDate() {
        if (this.dateRangeForm && this.dateRangeForm.controls.pickerOption.value !== 'ALL') {
            let date = this.datePipe.transform(this.dateRangeForm.get('range').value[0], 'dd/MM/yyyy');
            if (this.dateRangeForm.get('range').value[1]) {
                date += ' - ' + this.datePipe.transform(this.dateRangeForm.get('range').value[1], 'dd/MM/yyyy');
            }
            return date;
        }
        return this.pickerOptionsRanges ? this.pickerOptionsRanges.filter(range => range.key === 'ALL')[0].label : null;
    }
}
