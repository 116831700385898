<p-toast [baseZIndex]="99999999999"></p-toast>

<div class="new-area-container" [ngClass]="currentLang === 'en' ? 'page-ltr' : ''">
    <div class="header d-inline-flex justify-content-between">
        <div class="title">{{(this.isEdit ? 'ACCOUNTING.WALLET.EDIT_RECEIPT'  : 'ACCOUNTING.WALLET.ADD_RECEIPT') | translate}}</div>

        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../assets/icons/cancel.svg" alt="icon"/>
        </div>
    </div>

    <div class="loader" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <form [formGroup]="form">
        <div class="inputs-container">
            <div class="row row-input mb-2 mt-3">


                <div class="col-6 input auto-complete-wrapper">
                    <div class="input-label container-type-label">{{ 'ACCOUNTING.WALLET.CUSTOMER_NAME' | translate }}</div>
                    <ng-select formControlName="customer"
                               [ngClass]="{'disabled-btn disabled':disableCustomer || isEdit}"
                               [disabled]="disableCustomer || isEdit"
                               [typeahead]="storesSubject"
                               [loading]="storesLoading"
                               [items]="storeOptions"
                               (change)="onStoreChanged()"
                               (open)="!storeOptions.length ? getStores() : null"
                               typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                               loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                               notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                               placeholder=" ">
                        <ng-template ng-label-tmp let-store="item">
                            <div class="selected-store" *ngIf="store">
                                <ng-container>
                                    <div>{{ store.fullName + (store.businessName ? ' - ' + store.businessName : '') }}</div>
                                </ng-container>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-store="item">
                            <ng-container>
                                <div>{{ store.fullName + (store.businessName ? ' - ' + store.businessName : '') }}</div>
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="col-6 input">
                    <div class="input-label container-type-label">{{ 'ACCOUNTING.WALLET.AMOUNT' | translate }}</div>
                    <div class="input-value dropdown-container" [ngClass]="{'has-error': (form.get('amount').touched && form.get('amount').errors)}">
                        <input type="number"  (wheel)="preventWheelDefault($event)" appInputPositiveNumberDirective [formControlName]="'amount'">
                    </div>
                </div>

                <div class="col-6 input">
                    <div class="input-label container-type-label">{{ 'ACCOUNTING.WALLET.PAYMENT_TYPE' | translate }}</div>
                    <div class="input-value dropdown-container" [ngClass]="{'has-error': (form.get('paymentType').touched && form.get('paymentType').errors)}">

                        <p-dropdown
                                class="d-flex w-100"
                                formControlName="paymentType"
                                [options]="paymentTypes"
                                styleClass="flex-1"
                                placeholder=" "></p-dropdown>


                    </div>
                </div>

            </div>
        </div>

        <div class="bottom-actions">
            <div class="done-action" (click)="submitForm()" [ngClass]="{'disabled-btn disabled': !form.valid || isLoading}">
                <span>{{'ACTIONS.SUBMIT' | translate}}</span>
            </div>
        </div>
    </form>
</div>
