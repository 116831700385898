export interface AddressControls {
    country: string;
    region: string;
    city: string;
    village: string;
    combined?: string;
}
export interface AddressWithoutVillageControls {
    country: string;
    region: string;
    city: string;
    combined?: string;
}
enum addressType {
    country,
    region,
    city,
    village,
    combined
}

export class Address {
    public originRegionOptions;
    public originCityOptions;
    public originVillageOptions;
    public originCityOptions1;
    public originVillageOptions1;
    public originCombinedOptions;
    public originCombinedOptions1;
    public citiesOptions: { value: string, label: string }[];
    public citiesOptions1: { value: string, label: string }[];
    public countriesOptions: { value: string, label: string }[];
    public regionOptions: { value: string, label: string }[];
    public regionOptions1: { value: string, label: string }[];
    public villageOptions: { value: string, label: string }[];
    public villageOptions1: { value: string, label: string }[];
    public combinedOptions: { value: string, label: string, villageId?: number, villageName?: string }[];
    public combinedOptions1: { value: string, label: string }[];
    public initRegionsPromise;
    public disableRest = false;
    public addressForm;
    public allVillages = false;
    constructor(private addressService,
                private addressTranslateServer,
                private isAuthAddress = false,
                private isNeedRegions = false) {
        this.initCountries();
        if (isNeedRegions) {
            this.initRegions();
        }
        this.addressTranslateServer.onLangChange.subscribe((newLang) => {
            this.regionOptions = this.formatArray(this.originRegionOptions);
            this.regionOptions1 = this.formatArray(this.originRegionOptions);
            this.citiesOptions = this.formatArray(this.originCityOptions);
            this.citiesOptions1 = this.formatArray(this.originCityOptions1);
            this.villageOptions = this.formatArray(this.originVillageOptions);
            this.villageOptions1 = this.formatArray(this.originVillageOptions1);
            this.combinedOptions = this.formatArray(this.originCombinedOptions, true);
            this.combinedOptions1 = this.formatArray(this.originCombinedOptions1, true);
            this.initCountries();
        });
    }

    private initCountries() {
        this.countriesOptions = [{
            value: 'Palestine',
            label: this.addressTranslateServer.currentLang === 'en' ? 'Palestine' : 'فلسطين'
        }];
    }

    public initRegions(search = '') {
        this.initRegionsPromise = this.addressService.getRegions(this.isAuthAddress, search).toPromise().then(
            ({data}) => {
                this.originRegionOptions = data;
                this.regionOptions = this.formatArray(this.originRegionOptions);
                this.regionOptions1 = this.formatArray(this.originRegionOptions);
                // const westBankRegion = data.find(element => element.name === 'West Bank');
                // if (this.form) {
                //     if (this.form.controls['originRegion'] !== undefined &&
                //         (this.form.controls['originRegion'].value === '' || this.form.controls['originRegion'].value === null)) {
                //         this.form.controls['originRegion'].setValue(this.formatArray([westBankRegion])[0]);
                //     }
                //     if (this.form.controls['destinationRegion'] !== undefined &&
                //         (this.form.controls['destinationRegion'].value === '' || this.form.controls['destinationRegion'].value === null)) {
                //         this.form.controls['destinationRegion'].setValue(this.formatArray([westBankRegion])[0]);
                //     }
                //     if (this.form.controls['region'] !== undefined &&
                //         (this.form.controls['region'].value === '' || this.form.controls['region'].value === null)) {
                //         this.form.controls['region'].setValue(this.formatArray([westBankRegion])[0]);
                //     }
                // }

            }, (error) => {

            });
    }

    setDefaultRegionId() {
        const westBankRegion = this.regionOptions.find(element => element.label === 'West Bank' || element.label === 'الضفة الغربية');
        if (this.addressForm.controls['region'] !== undefined &&
            (this.addressForm.controls['region'].value === '' || this.addressForm.controls['region'].value === null)) {
            this.addressForm.controls['region'].setValue(westBankRegion.value);
        }
    }


    public initCities(id, regionId, search = '') {
        if (!regionId) {
            return Promise.resolve();
        }

        return this.addressService.getCities(regionId, this.isAuthAddress, search).toPromise().then(
            ({data}) => {
                if (id === 0) {
                    this.originCityOptions = data;
                    this.citiesOptions = this.formatArray(this.originCityOptions);
                } else {
                    this.originCityOptions1 = data;
                    this.citiesOptions1 = this.formatArray(this.originCityOptions1);
                }

            }, (error) => {

            }
        );
    }

    public initVillage(id, cityId, search = '') {
        return this.addressService.getVillages(cityId, this.isAuthAddress, this.allVillages, search).toPromise().then(
            ({data}) => {
                const combinedOptions = [];
                for (const item of data) {
                    combinedOptions.push({
                        ...item,
                        combinedName: (this.addressTranslateServer.currentLang === 'en' ? item.name : item.arabicName) + ' - ' +
                            item.cityName + ' - ' + item.regionName
                    });
                }
                if (id === 0) {
                    this.originVillageOptions = data;
                    this.villageOptions = this.formatArray(this.originVillageOptions);
                    this.originCombinedOptions = combinedOptions;
                    this.combinedOptions = this.formatArray(this.originCombinedOptions, true);
                } else {
                    this.originVillageOptions1 = data;
                    this.villageOptions1 = this.formatArray(this.originVillageOptions1);
                    this.originCombinedOptions1 = combinedOptions;
                    this.combinedOptions1 = this.formatArray(this.originCombinedOptions1, true);
                }
            }, (error) => {

            }
        );
    }

    formatArray(options = [], isCombined = false) {
        return options.map(({id, name, arabicName, ...data}) => {
            let body = <any>{value: '', label: ''};
            if (isCombined) {
                body = {
                    value: id, label: data.combinedName, villageId: id,
                    villageName: this.addressTranslateServer.currentLang === 'en' ? name : arabicName
                };
            } else {
                body = {value: id, label: this.addressTranslateServer.currentLang === 'en' ? name : arabicName};
            }
            if (data.regionName) {
                body['regionName'] = data.regionName;
                body['regionId'] = data.regionId;
            }
            if (data.cityName) {
                body['cityName'] = data.cityName;
                body['cityId'] = data.cityId;
            }
            return body;
        });
    }

    initFormEvent(form, id, control: AddressControls) {
        // add change listener for all address controls
        if (form.controls[control.country]) {
            form.controls[control.country].valueChanges.subscribe(data => {
                if (form.controls[control.country].disabled) {
                    return;
                }
                // this.initRegions();
                if (!this.disableRest) {
                    this.addressResetForm(form, addressType.region, control, id);
                }
            });
        }

        if (form.controls[control.village]) {
            form.controls[control.village].valueChanges.subscribe(data => {
                this.disableRest = true;
                if (data && data.regionId && data.regionName) {
                    form.controls[control.region].setValue({label: data.regionName, value: data.regionId});
                }
                if (data && data.cityId && data.cityName) {
                    form.controls[control.city].setValue({label: data.cityName, value: data.cityId});
                }
                // Needed for combined categories
                if (data && data.villageId && data.villageName) {
                    form.controls[control.village].setValue({label: data.villageName, value: data.villageId});
                }
                if (form.controls[control.combined] && data && data.value && data.label && data.cityId && data.cityName &&
                    data.regionId && data.regionName) {
                    form.controls[control.combined].setValue({
                        label: data.label + '-' + data.cityName + '-' + data.regionName,
                        value: data.value
                    });
                }
                this.disableRest = false;
            });
        }
        if (form.controls[control.combined]) {
            form.controls[control.combined].valueChanges.subscribe(data => {
                this.disableRest = true;
                if (data && data.regionId && data.regionName) {
                    form.controls[control.region].setValue({label: data.regionName, value: data.regionId});
                }
                if (data && data.cityId && data.cityName) {
                    form.controls[control.city].setValue({label: data.cityName, value: data.cityId});
                }
                // Needed for combined categories
                if (data && data.villageId && data.villageName) {
                    form.controls[control.village].setValue({label: data.villageName, value: data.villageId});
                }
                this.disableRest = false;
            });
        }
    }

    addressResetForm(form, type, control, id) {
        if (form.controls[control.village]) {
            form.controls[control.village].setValue(null);
        }
        if (id === 0) {
            this.villageOptions = this.originVillageOptions = [];
        } else {
            this.villageOptions1 = this.originVillageOptions1 = [];
        }
        if (type === addressType.village) {
            return;
        }


        form.controls[control.city].setValue(null);
        if (id === 0) {
            this.citiesOptions = this.originCityOptions = [];
        } else {
            this.citiesOptions1 = this.originCityOptions1 = [];
        }
        if (type === addressType.city) {
            return;
        }


        form.controls[control.region].setValue(null);
        if (id === 0) {
            this.regionOptions = this.originRegionOptions = [];
        } else {
            this.regionOptions1 = this.originRegionOptions = [];
        }
        if (form.controls[control.combined]) {
            form.controls[control.combined].setValue(null);
        }
        if (id === 0) {
            this.combinedOptions = this.originCombinedOptions = [];
        } else {
            this.combinedOptions1 = this.originCombinedOptions1 = [];
        }
        if (type === addressType.region) {
            return;
        }

        form.controls[control.country].setValue(null);

    }

    initFormWithOutVillageEvent(form, id, control: AddressWithoutVillageControls) {
        // add change listener for all address controls
        if (form.controls[control.country]) {
            form.controls[control.country].valueChanges.subscribe(data => {
                if (form.controls[control.country].disabled) {
                    return;
                }
                this.initRegions();
                if (!this.disableRest) {
                    this.addressResetForm(form, addressType.region, control, id);
                }
            });
        }

        if (form.controls[control.region]) {
            form.controls[control.region].valueChanges.subscribe(data => {
                if (form.controls[control.region].disabled) {
                    return;
                }
                let regionId = null;
                if (data) {
                    if (data.item) { // For auto Complete
                        regionId = data.item.value ? data.item.value : data.item;
                    } else {
                        regionId = data.value ? data.value : data;
                    }
                }
                this.initCities(id, regionId);
                if (!this.disableRest) {
                    this.addressResetForm(form, addressType.city, control, id);
                }
            });
        }
        if (form.controls[control.combined]) {
            form.controls[control.combined].valueChanges.subscribe(data => {
                this.disableRest = true;
                if (data && data.regionId && data.regionName) {
                    form.controls[control.region].setValue({label: data.regionName, value: data.regionId});
                }
                if (data && data.cityId && data.cityName) {
                    form.controls[control.city].setValue({label: data.cityName, value: data.cityId});
                }
                this.disableRest = false;
            });
        }
    }
}
