import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {LclSupplierService} from '../services/lcl-supplier.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-create-supplier',
    templateUrl: './create-supplier.component.html',
    styleUrls: ['./create-supplier.component.scss']
})
export class CreateSupplierComponent implements OnInit {

    event;
    form: any;
    isLoading: any;

    initialBrandName = '';
    constructor(@Inject(FormBuilder) private formsBuilder: FormBuilder, private lclSupplierService: LclSupplierService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.form = this.formsBuilder.group({
            address: [''],
            brandName: [this.initialBrandName, Validators.required],
            phone: [''],
            phone2: [''],
            tradeName: ['', Validators.required],
            vatNumber: ['']
        });
    }

    isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
        const formControl = this.form.get(formControlName);
        if (formControl) {
            return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
                : formControl.invalid && (formControl.touched || formControl.dirty);
        }
        return false;
    }

    onCancel() {
        this.closeDialog();
    }

    onSubmit() {
        this.isLoading = true;
        this.lclSupplierService.createSupplier(this.form.value).subscribe(res => {
            this.closeDialog({created: true});
        }, error => {
            this.isLoading = false;
        });

    }

    private closeDialog(result?: any) {
        this.activeModal.close(result);
    }
}
