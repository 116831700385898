import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';
import {of} from 'rxjs/index';
import {PaymentIntentModel} from '../components/manage-payments/payment-intent.model';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import {environment} from '../../../environments/environment';

@Injectable()
export class PaymentsService {

    private _stripe: Stripe;

    constructor(private http: HttpClient) {
        loadStripe(environment.production
            ? SHARED_CONSTANTS.STRIPE_PUBLISHABLE_KEY.production
            : SHARED_CONSTANTS.STRIPE_PUBLISHABLE_KEY.development).then((stripe) => {
            this.stripe = stripe;
        });
    }

    set stripe(stripeObject: Stripe) {
        if (!this._stripe) {
            this._stripe = stripeObject;
        }
    }

    get stripe() {
        return this._stripe;
    }

    getPayments(params: any) {
        return this.http.get(ADMINISTRATION_CONSTANTS.GET_PAYMENTS, {params});
    }

    newPaymentIntent(paymentIntent: PaymentIntentModel) {
        return this.http.post(ADMINISTRATION_CONSTANTS.NEW_PAYMENT_INTENT, paymentIntent);
    }
    getStatusColor(status) {
        switch (status) {
            case 'PENDING':
                return '#B772B8';
            case 'FAILED':
                return '#F24645';
            case 'SUCCESSFUL':
                return '#2ED47A';
        }
    }
}
