import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LCL_CONSTANTS} from '../Lcl-constants';

@Injectable({
    providedIn: 'root'
})
export class LclSupplierService {

    constructor(private http: HttpClient) {
    }

    getLclSupplier(params): Observable<any> {
        return this.http.get(LCL_CONSTANTS.GET_LCL_SUPPLIER, {params});
    }

    createSupplier(body) {
        return this.http.post(LCL_CONSTANTS.CREATE_LCL_SUPPLIER, body);

    }
}
