<div [ngClass]="{'page-ltr': currentLang === 'en'}">
  <div class="add-update-dialog-header d-flex justify-content-between align-items-center">
    <div class="title">
      {{'FAQ.ADD_FAQ' | translate}}
    </div>

    <div class="close-icon" (click)="close()">
      <img src="../../../assets/icons/close-x.svg" alt=""/>
    </div>
  </div>
  <div class="flex-1 input-fields add-update-body">
    <div class="h-100 d-flex flex-column">
      <form *ngIf="form" class="add-user-form row" [formGroup]="form">

        <div class="row input-row w-100 mb-5">
          <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
            <div class="label">{{'FAQ.ADD_ENGLISH_QUESTION' | translate}}</div>
            <div class="input-value">
              <input class="w-100" type="text" pInputText formControlName="question"/>
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('question')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>
        <div class="row input-row w-100 mb-5">
          <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
            <div class="label">{{'FAQ.ADD_ARABIC_QUESTION' | translate}}</div>
            <div class="input-value">
              <input class="w-100" type="text" pInputText formControlName="arabicQuestion"/>
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('arabicQuestion')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>
        <div class="row input-row w-100 mb-5">
          <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
            <div class="label">{{'FAQ.ADD_ENGLISH_ANSWER' | translate}}</div>
            <div class="input-value">
              <textarea class="w-100" [rows]="5" [cols]="30" pInputTextarea formControlName="answer"
                        autoResize="true"></textarea>
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('answer')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>
        <div class="row input-row w-100 mb-5">
          <div class="no-padding col-lg-12 col-sm-12 phone-number input-filed-container">
            <div class="label">{{'FAQ.ADD_ARABIC_ANSWER' | translate}}</div>
            <div class="input-value">
              <textarea class="w-100" [rows]="5" [cols]="30" pInputTextarea formControlName="arabicAnswer"
                        autoResize="true"></textarea>
            </div>
            <div class="invalid-message" *ngIf="isInvalidDirtyOrTouched('arabicAnswer')">
              {{ 'GENERAL.REQUIRED_VALIDATION' | translate }}
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
  <div class="row actions action-container" style="width: 100%;">
    <div class="d-flex">
      <div *ngIf="!isLoading" class="cursor-pointer add-update-button done" (click)="addUpdate()"
           [ngClass]="{'disabled-btn disabled': !form?.valid}">
        <span *ngIf="!isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</span>
        <span *ngIf="isEdit">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT' | translate}}</span>
      </div>
    </div>
  </div>
</div>
