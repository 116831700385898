<div class="h-100 d-flex flex-column">
    <div class="header-container">
        {{(isEdit ? 'COMPANY.EDIT_PARCEL' : 'COMPANY.ADD_PARCEL') | translate | packageTitle}}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="exitModal()"></i>
    </div>
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="h-100 d-flex flex-column">
        <form *ngIf="form" class="add-user-form row" [formGroup]="form" (submit)="onAddParcel()">

            <div class="row col-12">
                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="PARCEL.PARCEL_TYPE"
                                           [isRequired]="true"
                                           controlName="type"
                                           [form]="form">
                </app-input-field-component >

                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="PARCEL.PARCEL_ARABIC_TYPE"
                                           [isRequired]="true"
                                           controlName="arabicType"
                                           [form]="form">
                </app-input-field-component >

                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="ADD_SHIPMENT.LENGTH"
                                           [isRequired]="true"
                                           controlName="length"
                                           controlType="number"
                                           [form]="form">
                </app-input-field-component >

                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="ADD_SHIPMENT.WIDTH"
                                           [isRequired]="true"
                                           controlName="width"
                                           controlType="number"
                                           [form]="form">
                </app-input-field-component >

                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="PRODUCT.WEIGHT"
                                           [isRequired]="true"
                                           controlName="weight"
                                           controlType="number"
                                           [form]="form">
                </app-input-field-component >

                <app-input-field-component class="col-lg-3 col-md-4 col-sm-6" label="ADD_SHIPMENT.HEIGHT"
                                           [isRequired]="true"
                                           controlName="height"
                                           controlType="number"
                                           [form]="form">
                </app-input-field-component >
            </div>

            <div class="d-flex flex-row w-100 p-2">
                <div class="flex-1"></div>
                <div class="col-lg-4 col-md-4 col-sm-9">
                    <button class="add-button submit-button action-button" [disabled]="form.invalid">
                        {{'ACTIONS.SAVE'|translate}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
