import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter, Input,
    OnDestroy,
    Optional,
    Output
} from '@angular/core';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {AutoComplete} from 'primeng/autocomplete';

/**
 * A directive for HTMLInput elements and InputFieldComponent
 * that accepts only numeric values in field-input
 * but shows any normal text when its not set by 'field-input' event
 * @emits appNumericInput event carries the result field-input after filtering
 */
@Directive({
    selector: '[appNumericInput]'
})
export class NumericInputDirective implements AfterViewInit, OnDestroy {
    @Output() appNumericInput = new EventEmitter<string>();
    @Input() numericInputDebounce = 700;

    private debouncer = new Subject<string>();

    private inputSubscription: Subscription;

    constructor(public element: ElementRef,
                @Optional() public autoComplete: AutoComplete) {
        this.debouncer
            .pipe(debounceTime(this.numericInputDebounce))
            .subscribe((value => {
            this.appNumericInput.emit(value);
        }));
    }

    ngAfterViewInit() {
        if (this.element.nativeElement instanceof HTMLInputElement) {
            this.inputSubscription = fromEvent(this.element.nativeElement, 'input')
                .pipe(map((event: Event) => {
                    return ((event.target as HTMLInputElement).value);
                }))
                .subscribe((data) => {
                    if (data) {
                        data = data.replace(/[^0-9]/, '');
                        (this.element.nativeElement as HTMLInputElement).value = data.replace(/[^0-9]/, '');
                    }
                    this.debouncer.next(data);
                });
        } else if (this.autoComplete) {
            this.inputSubscription = fromEvent(this.autoComplete.inputEL.nativeElement, 'input')
                .pipe(map((event: Event) => {
                    return ((event.target as HTMLInputElement).value);
                }))
                .subscribe((data) => {
                    if (data) {
                        data = data.replace(/[^0-9]/, '');
                        this.autoComplete.inputEL.nativeElement.value = data.replace(/[^0-9]/, '');
                    }
                    if (data && isFinite(Number(data)) && !isNaN(Number(data))) {
                        this.debouncer.next(data);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.inputSubscription.unsubscribe();
        this.debouncer.unsubscribe();
    }
}
