<div class="main-container" [ngClass]="{'page-ltr': currentLang === 'en', 'page-rtl': currentLang === 'ar'}">
    <div class="header">
        <div class="header-title">{{'ADMINISTRATION.RECEIVED_COD_WITHOUT_CUSTODY' | translate}}</div>
        <div class="filters">
                <app-drop-down [showLabel]="false"
                              [showClear]="false"
                             [options]="typesList"
                             [(ngModel)]="selectedType"
                             optionLabel='label'
                             (whenChange)="onTypeChanged()">
            </app-drop-down>

            <app-users-list-dropdown-component
                    #userFilter
                    [isDriver]="selectedType==='DRIVER'"
                    [isHub]="selectedType==='HUB'"
                    [isPartner]="selectedType==='PARTNER'"
                    [innerPlaceHolder]="'MANAGE_COD_CUSTOMERS.' + selectedType + '_NAME'"
                    (userChanged)="onUserFilterChanged($event)"
                    [isDisabled]="selectedType === ''"
            ></app-users-list-dropdown-component>

            <app-drop-down [showLabel]="false"
                         [options]="customerTypes"
                         [(ngModel)]="customerType"
                          [showClear]="false"
                         optionLabel='label'
                         [placeholder]="'CUSTOMER_TYPES.CUSTOMER_TYPE' | translate"
                         (whenChange)="onSearch()">
            </app-drop-down>

            <app-multi-select [showLabel]="false"
                    [options]="codStatusList"
                    [(ngModel)]="selectedMultiSelectCodStatus"
                    (whenSelect)="onSelectCodStatus()"
                    (whenSelectAll)="onSelectCodStatus()"
                    (whenDeSelect)="onSelectCodStatus()"
                    (whenDeSelectAll)="onSelectCodStatus()"
                    maxSelectedLabels="1"
                    selectedItemsLabel="{{ '{1} ' + ('GENERAL.ITEMS' | translate) }}"
                    filterPlaceHolder="{{'MANAGE_SHIPMENTS.SEARCH' | translate}}..">
            </app-multi-select>
            <app-field-input icon="fas fa-search"
                       class="width-170"
                       [showIcon]="true"
                       [showLabel]="false"
                       placeholder="{{'GENERAL.SEARCH' | translate}}..."
                       [(ngModel)]="searchContent"
                       appInputDebounced
                       (inputDebounced)="onSearch()"></app-field-input>
        </div>
    </div>

    <div class="table-container d-flex h-100">
        <p-table #customersTable appPTableRtlFix dataKey="id" #table [value]="data"
                 paginatorDropdownAppendTo="body"
                 styleClass="customers-table"
                 class="flex-1"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 (onPage)="setPaginatorQueryParam($event)"
                 [lazy]="true" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th> {{"MANAGE_COD_CUSTOMERS." + selectedType + "_NAME" | translate}}</th>
                    <th *ngIf="selectedType === 'CUSTOMER'"> {{"ACCOUNTING.CUSTOMER_ADDRESS" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.COD_WITHOUT_COST" | translate}}</th>
                    <th> {{"MANAGE_COD_CUSTOMERS.COD_SUM" | translate}}</th>
                    <th> {{'ACCOUNTING.RECEIVED_COD_PACKAGES_CUSTODY' | translate}}</th>
<!--                    <th class="header-multiselect data-filter delivery-date-th">-->
<!--                        <div class="manage-shipment-date-filter"-->
<!--                             [ngClass]="{'manage-shipment-active-date-filter' : deliveryDate.value}">-->
<!--                                <span class="receiver-phone date-filter-label"-->
<!--                                      [ngClass]="{'active-receiver-phone': deliveryDate.value}">{{'MANAGE_SHIPMENTS.DELIVERY_DATE' | translate}}</span>-->
<!--                            <i *ngIf="deliveryDate.value"-->
<!--                               class="fas fa-times date-picker-icon cursor-pointer"-->
<!--                               (click)="resetField('deliveryDate')"></i>-->
<!--                            <p-calendar-->
<!--                                    styleClass="date-picker header-filter-background"-->
<!--                                    [(ngModel)]="deliveryDate.value"-->
<!--                                    (onSelect)="onSearch()"-->
<!--                                    placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"-->
<!--                                    dateFormat="dd/mm/yy"-->
<!--                                    [appendTo]="'body'"-->
<!--                            ></p-calendar>-->
<!--                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                        </div>-->
<!--                    </th>-->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr class="cursor-pointer" (click)="openDetails(customer.id)">
                    <td>{{customer.name}}</td>
                    <td *ngIf="selectedType === 'CUSTOMER'">{{customer.city}}</td>
                    <td>{{customer.totalCodWithoutCost}}</td>
                    <td>{{customer.totalReceivedCod}}</td>
                    <td>{{customer.receivedCodPackagesNumber}}</td>
<!--                    <td >{{customer.deliveryDate | date:'dd/MM/yyyy HH:mm'}}</td>-->
                </tr>
            </ng-template>
            <div class="paginator-container">
                <p-paginator
                    #paginator
                    [rows]="12"
                    [first]="first"
                    [totalRecords]="totalRecords"></p-paginator>
            </div>
        </p-table>

    </div>
</div>
