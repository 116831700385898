import {NgModule} from '@angular/core';
import {TicketingSystemService} from './services/ticketing-system.service';
import {TicketingSystemDashboardComponent} from './components/dashboard/ticketing-system-dashboard.component';
import {SharedModule} from '../shared/modules/shared.module';
import {CheckboxModule} from 'primeng/checkbox';
import {ChartModule} from 'primeng/chart';
import {TechnicalSupportTicketsComponent} from './components/technical-support-tickets/technical-support-tickets.component';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';

import {AddTicketFormComponent} from './components/add-ticket-form/add-ticket-form.component';

import {CategoriesManagementComponent} from './components/categories-management/categories-management.component';
import {CategoryFormComponent} from './components/categories-management/category-form/category-form.component';
import {ToastModule} from 'primeng/toast';
import {TicketDetailsComponent} from './components/ticket-details/ticket-details.component';
import {TicketCardComponent} from './components/ticket-details/ticket-card/ticket-card.component';
import {GeneralNoteFormComponent} from './components/dashboard/general-note-form/general-note-form.component';
import {TicketHistoryComponent} from './components/ticket-details/ticket-history/ticket-history.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SidebarModule} from 'primeng/sidebar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {CalendarModule} from 'primeng/calendar';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
    declarations: [
        AddTicketFormComponent,
        TechnicalSupportTicketsComponent,
        TicketingSystemDashboardComponent,
        GeneralNoteFormComponent,
        CategoriesManagementComponent,
        CategoryFormComponent,
        TicketDetailsComponent,
        TicketCardComponent,
        TicketHistoryComponent
    ],
    exports: [
        TicketDetailsComponent
    ],
    imports: [
        SharedModule,
        CheckboxModule,
        ChartModule,
        DropdownModule,
        TableModule,
        SidebarModule,
        InputTextareaModule,
        MultiSelectModule,
        InputSwitchModule,
        OverlayPanelModule,
        CalendarModule,
        ToastModule,
        TooltipModule
    ],
    providers: [TicketingSystemService]
})
export class TicketingSystemModule {}
