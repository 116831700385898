<div class="main-container">
    <div class="header">
        <div class="title">
            {{ (markAsLclPaid ? 'LCL.ACTIONS.MARK_AS_LCL_PAID'
                : 'USERS_MANAGEMENT.ADD_USER_FORM.ADD_ATTACHMENTS') | translate }}
        </div>
    </div>
    <div *ngIf="markAsLclPaid" class="row input-row">
        <div class="col-lg-6 col-sm-12 first-name input-filed-container no-padding">
            <div class="label">{{'COMPANY.AMOUNT' | translate}}</div>
            <div class="input-value">
                <input type="number" size="30" [(ngModel)]="amount"/>
            </div>
        </div>
    </div>
    <div class="uploader-container">
        <app-file-uploader [disabled]="isLoading" [loading]="isLoading" #uploaderComponent></app-file-uploader>
    </div>

    <div class="bottom-actions">
        <div class="cancel-action" [ngClass]="{'disabled disabled-btn': uploaderComponent.loading || isLoading}" (click)="close()">
            {{ 'ACTIONS.CANCEL' | translate }}
        </div>
        <div class="submit-action"
             [ngClass]="{'disabled disabled-btn': uploaderComponent.loading || isLoading ||
                !uploaderComponent.files.length || (markAsLclPaid && !amount)}"
             (click)="onSubmit()">
            {{ (markAsLclPaid ? 'LCL.ACTIONS.MARK_AS_LCL_PAID' : 'ACTIONS.SUBMIT') | translate }}
        </div>
    </div>
</div>
