import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {REPORTS_CONSTANTS} from '../../reports-constants';
import {ReportsService} from '../../services/reports';

@Component({
    selector: 'app-detailed-reports',
    templateUrl: './detailed-reports.component.html',
    styleUrls: ['./detailed-reports.component.scss']
})
export class DetailedReportsComponent implements OnInit {
    public report = '';
    public customerReport = REPORTS_CONSTANTS.DETAILED_REPORTS.CUSTOMER_REPORT;
    public customerReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_CUSTOMERS_REPORT_API;
    public customerRawDataUrl = REPORTS_CONSTANTS.DETAILED_REPORTS.DETAILED_CUSTOMER_RAW_DATA_API;

    public shippedPackagesReport = REPORTS_CONSTANTS.DETAILED_REPORTS.SHIPPED_PACKAGES_REPORT;
    public shippedPackagesReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.SHIPPED_PACKAGES_REPORT_API;

    public undeliveredPackagesReport = REPORTS_CONSTANTS.DETAILED_REPORTS.UNDELIVERED_PACKAGES_REPORT;
    public undeliveredPackagesReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.UNDELIVERED_PACKAGES_REPORT_API;

    public timeBoundPackagesReport = REPORTS_CONSTANTS.DETAILED_REPORTS.TIME_BOUND_PACKAGES_REPORT;
    public timeBoundPackagesReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.TIME_BOUND_PACKAGES_REPORT_API;
    public driverAttemptsReport = REPORTS_CONSTANTS.DETAILED_REPORTS.DRIVER_ATTEMPTS_REPORT;
    public driverAttemptsReportAPI = REPORTS_CONSTANTS.DETAILED_REPORTS.DRIVER_ATTEMPTS_REPORT_API;
    public fawDataUrl = REPORTS_CONSTANTS.DETAILED_REPORTS.TIME_BOUND_PACKAGES_EXCEL;
    public driverAttemptsReportExcel = REPORTS_CONSTANTS.DETAILED_REPORTS.DRIVER_ATTEMPTS_REPORT_EXCEL;
    public driverAttemptsReportStatistics = REPORTS_CONSTANTS.DETAILED_REPORTS.DRIVER_ATTEMPTS_REPORT_COUNT;
    public formatUndeliveredPackagesData;

    constructor(public reportsService: ReportsService,
                private route: ActivatedRoute) {
        this.formatUndeliveredPackagesData = this.reportsService.formatUndeliveredPackagesData.bind(this);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.report = params.report;
        });
    }
}
