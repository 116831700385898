<div class="choose-customer-container">
    <div class="header">
        <div style="display: inline-block;">
            {{(fromBundle ? 'SHIPMENT.CHOOSE_CUSTOMER_AND_RECEIVER': 'PARTNER.CHOOSE_CUSTOMER') | translate }}
        </div>
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="input-fields row">
        <div class="col-12 partner-container">
            <div class="inputs row">
                <div class="col-12" style="min-height: 60px">
                    <title></title>
                    <div class="types-container d-flex" *ngIf="!hideSelectOption">
                        <ng-container *ngIf="!hideSelectOption">
                            <div *ngFor="let type of sourceTypes" class="type cursor-pointer"
                                 (click)="onSelectSourceType(type)"
                                 [ngClass]="{'active-type': selectedSourceType === type}">
                                {{('SHIPMENT.BUNDLE_SOURCE_TYPES.' + type)| translate}}
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="selectedSourceType === 'HUB'" class="hub w-100">
                        <p-dropdown
                                [tabindex]="-1"
                                [(ngModel)]="selectedSourceHubId"
                                [options]="hubsOptions"
                                (onChange)="onSourceHubChange($event)"
                                styleClass="w-100"
                                placeholder="{{ 'ADD_SHIPMENTS.CHOOSE_HUB' | translate | hubTitle}}">
                        </p-dropdown>
                    </div>
                    <div *ngIf="selectedSourceType === 'CUSTOMER'" class="customer w-100">
                        <app-users-list-dropdown-component [inputStyleClass]="'width95'"
                                                           (userChanged)="customerSelected($event)">
                        </app-users-list-dropdown-component>
                    </div>
                </div>
                <div *ngIf="fromBundle && !chooseSourceOnly" class="col-12 partner-container">
                    <div class="destination-type w-100">
                        <div class="types-container d-flex" >
                            <ng-container *ngIf="!hideSelectOption">
                                <div *ngFor="let type of destinationTypes" class="type cursor-pointer"
                                     (click)="onSelectDestinationType(type)"
                                     [ngClass]="{'active-type': selectedDestinationType === type}">
                                    {{('SHIPMENT.BUNDLE_DESTINATION_TYPES.' + type)| translate}}
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="selectedDestinationType === 'CUSTOMER'" class="customer w-100">
                            <app-users-list-dropdown-component [inputStyleClass]="'width95'"
                                                               (userChanged)="customerSelected($event)">
                            </app-users-list-dropdown-component>
                        </div>
                        <div *ngIf="selectedDestinationType === 'HUB'" class="hub w-100">
                            <p-dropdown
                                    [tabindex]="-1"
                                    [(ngModel)]="selectedDestinationHubId"
                                    [options]="hubsDestinationsOptions"
                                    (onChange)="onDestinationHubChange($event)"
                                    styleClass="w-100"
                                    placeholder="{{ 'ADD_SHIPMENTS.CHOOSE_HUB' | translate | hubTitle}}">
                            </p-dropdown>
                        </div>
                        <div *ngIf="selectedDestinationType === 'ADDRESS'" class="address w-100">
                            <p-autoComplete
                                            [forceSelection]="true"
                                            field="label"
                                            [delay]="700"
                                            placeholder="{{'ADD_SHIPMENT.COMBINED_ADDRESS' | translate}}"
                                            [suggestions]="combinedOptions"
                                            (completeMethod)="initVillage(0, 0,$event.query)"
                                            (onSelect)="chooseAddress($event)"
                                            styleClass="w-100"
                            >
                            </p-autoComplete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div class="flex-1"></div>
        <div style="padding: 10px 30px">
            <div class="add-button cursor-pointer "
                 (click)="chooseCustomer()"
                 [ngClass]="{'disabled-btn disabled': !((customerId && (selectedAddress || selectedDestinationHubId)) ||
                                                       (selectedSourceHubId && (selectedDestinationHubId || customerId && hideSelectOption)) ||
                                                       ((selectedSourceHubId || customerId) && chooseSourceOnly))}">
                {{'ACTIONS.DONE' | translate}}
            </div>
            <div class="reset-button cursor-pointer " (click)="closeModal()">
                <i class="fas fa-times"></i>
                {{"ACTIONS.CANCEL" | translate}}
            </div>
        </div>
    </div>
</div>
