export const REPORTS_CONSTANTS = {
    DETAILED_REPORTS: {
        DETAILED_CUSTOMER_REPORT_API: ':api/admin/reports/customers/{customerId}/detailed',
        DETAILED_CUSTOMERS_REPORT_API: ':api/admin/reports/customers/detailed',
        DETAILED_CUSTOMER_RAW_DATA_API: ':api/admin/reports/raw-data/excel',
        CUSTOMER_REPORT: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADMINISTRATION.CUSTOMER_NAME', field: 'customerName', type: 'customerName'},
            {header: 'ADMINISTRATION.RECEIVER_NAME', field: ['receiverFirstName', 'receiverLastName'], type: 'concat'},
            {header: 'ADMINISTRATION.RECEIVER_PHONE', field: 'receiverPhone', type: 'String'},
            {header: 'ADMINISTRATION.PRICE', field: 'cost'},
            {header: 'ADMINISTRATION.COD', field: 'cod'},
            {header: 'ADMINISTRATION.PACKAGE_DATE', field: 'createdDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_PICKUP_DATE', field: 'pickupDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_DATE_BEFORE_PICKUP', field: 'dateBeforePickUp', type: 'string'},
            {header: 'ADMINISTRATION.PACKAGE_DELIVERY_DATE', field: 'deliveryDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_COD_DATE', field: 'deliveryCodDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_BEFORE_RECEIVE_COD', field: 'dateBeforeReceiveCod', type: 'string'},
        ],
        IDLE_CUSTOMERS_REPORT_API: ':api/admin/reports/customers/idle',
        IDLE_CUSTOMER_RAW_DATA_API: ':api/admin/reports/customers/idle/excel',
        IDLE_CUSTOMER_REPORT: [
            {header: 'ADMINISTRATION.IDLE_CUSTOMER_REPORT.CUSTOMER_NAME', field: 'name'},
            {header: 'ADMINISTRATION.IDLE_CUSTOMER_REPORT.PHONE', field: 'phone'},
            {header: 'ADMINISTRATION.IDLE_CUSTOMER_REPORT.ADDRESS', field: 'address'},
        ],
        DETAILED_DRIVER_REPORT_API: ':api/admin/reports/drivers/detailed',
        DRIVER_REPORTS: [
            {
                HEADER: 'PICKED_PACKAGES',
                ENUM_VALUE: 'PICKUP',
                COLUMNS: [
                    {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DATE', field: 'createdDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.DRIVER_PICKUP_DATE', field: 'historyDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_NAME', field: ['senderFirstName', 'senderLastName'], type: 'concat'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_CITY', field: ['originAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COD', field: 'cod'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COST', field: 'cost'},
                    {
                        header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_NAME',
                        field: ['receiverFirstName', 'receiverLastName'],
                        type: 'concat'
                    },
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_CITY', field: ['destinationAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_PHONE', field: 'receiverPhone'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.INVOICE_NUMBER', field: 'invoiceNumber'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.DRIVER_NAME', field: 'driverName'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.NOTES', field: 'notes', type: 'notesWithFilter'}
                ],
                STATISTICS: [
                    {title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_PACKAGES', val: 'totalRecordsNo'},
                ],
                API_STATISTICS: [
                    {
                        title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_COD',
                        api: ':api/admin/reports/drivers/{driverId}/cod-sum',
                        replaceValue: 'driverId',
                        val: 'packagesCountSum'
                    }
                ]
            },
            {
                HEADER: 'DELIVERED_PACKAGES',
                ENUM_VALUE: 'DELIVER',
                COLUMNS: [
                    {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DATE', field: 'createdDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DELIVERY_DATE', field: 'historyDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_NAME', field: ['senderFirstName', 'senderLastName'], type: 'concat'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_CITY', field: ['originAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COD', field: 'cod'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COST', field: 'cost'},
                    {
                        header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_NAME',
                        field: ['receiverFirstName', 'receiverLastName'],
                        type: 'concat'
                    },
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_CITY', field: ['destinationAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.INVOICE_NUMBER', field: 'invoiceNumber'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.NOTES', field: 'notes', type: 'notesWithFilter'}
                ],
                STATISTICS: [
                    {title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_PACKAGES', val: 'totalRecordsNo'},
                ],
                API_STATISTICS: [
                    {
                        title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_COD',
                        api: ':api/admin/reports/drivers/{driverId}/cod-sum',
                        replaceValue: 'driverId',
                        val: 'packagesCountSum'
                    }
                ]
            },
            {
                HEADER: 'DELIVERED_COD',
                ENUM_VALUE: 'RECEIVE_COD',
                COLUMNS: [
                    {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DATE', field: 'createdDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COD_DELIVERY_DATE', field: 'historyDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_NAME', field: ['senderFirstName', 'senderLastName'], type: 'concat'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_CITY', field: ['originAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COD', field: 'cod'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COST', field: 'cost'},
                    {
                        header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_NAME',
                        field: ['receiverFirstName', 'receiverLastName'],
                        type: 'concat'
                    },
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_CITY', field: ['destinationAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.INVOICE_NUMBER', field: 'invoiceNumber'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.NOTES', field: 'notes', type: 'notesWithFilter'}
                ],
                STATISTICS: [
                    {title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_PACKAGES', val: 'totalRecordsNo'},
                ],
                API_STATISTICS: [
                    {
                        title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_COD',
                        api: ':api/admin/reports/drivers/{driverId}/cod-sum',
                        replaceValue: 'driverId',
                        val: 'packagesCountSum'
                    }
                ]
            },
            {
                HEADER: 'RETURNED_PACKAGES',
                ENUM_VALUE: 'RETURNED_PACKAGES',
                COLUMNS: [
                    {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DATE', field: 'createdDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COD_DELIVERY_DATE', field: 'historyDate', type: 'date'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_NAME', field: ['senderFirstName', 'senderLastName'], type: 'concat'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.SENDER_CITY', field: ['originAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COD', field: 'cod'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.COST', field: 'cost'},
                    {
                        header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_NAME',
                        field: ['receiverFirstName', 'receiverLastName'],
                        type: 'concat'
                    },
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.RECEIVER_CITY', field: ['destinationAddress', 'city'], type: 'concatKeys'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.INVOICE_NUMBER', field: 'invoiceNumber'},
                    {header: 'ADMINISTRATION.DRIVER_REPORTS.NOTES', field: 'notes', type: 'notesWithFilter'}
                ],
                STATISTICS: [
                    {title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_PACKAGES', val: 'totalRecordsNo'},
                ]
            }

        ],
        SHIPPED_PACKAGES_REPORT_API: ':api/admin/reports/packages/delivered',
        SHIPPED_PACKAGES_REPORT: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            /*{header: 'ADMINISTRATION.SHIPMENT_NUMBER', field: 'shipmentId'},*/
            {header: 'ADMINISTRATION.PACKAGE_PICKUP_DATE', field: 'createdDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_DELIVERY_DATE', field: 'deliveryDate', type: 'date'},
            /*{header: 'ADMINISTRATION.DRIVER_NAME', field: 'driverName'},*/
            {header: 'ADMINISTRATION.RECEIVER_NAME', field: ['receiverFirstName', 'receiverLastName'], type: 'concat'}
        ],
        UNDELIVERED_PACKAGES_REPORT_API: ':api/admin/reports/packages/undelivered',
        UNDELIVERED_PACKAGES_REPORT: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADMINISTRATION.PACKAGE_PICKUP_DATE', field: 'createdDate', type: 'date'},
            {header: 'ADMINISTRATION.NUMBER_OF_DAYS', field: 'numberOfDaysFromPickupUntilNow'},
            {header: 'ADMINISTRATION.DELIVERY_ZONE', field: 'deliveryZone'},
            {header: 'ADMINISTRATION.ADDRESS', field: 'address'},
            {header: 'ADMINISTRATION.PHONE_NUMBER', field: 'phone'},
            {header: 'ADMINISTRATION.MOBILE_NUMBER', field: 'mobile'}
        ],
        DELAYED_PACKAGES_EXCEL: ':api/admin/packages/excel?status=DELAYED',
        DELAYED_PACKAGES_REPORT_API: ':api/admin/packages?status=DELAYED&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone,
        DELAYED_PACKAGES_REPORT: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADMINISTRATION.RECEIVER_NAME', field: ['receiverFirstName', 'receiverLastName'], type: 'concat'},
            {header: 'ADD_SHIPMENT.RECEIVER_ADDRESS', field: ['destinationAddress.city', 'destinationAddress.village', 'destinationAddress.addressLine1'], type: 'concat'},
            {header: 'ADMINISTRATION.DRIVER_REPORTS.PACKAGE_DATE', field: 'createdDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_PICKUP_DATE', field: 'firstPickupDate', type: 'date'},
            {header: 'ADMINISTRATION.PACKAGE_RETURN_DATE', field: 'returnDate', type: 'date'},
            {header: 'DELAYED_DATE', field: 'delayedDuration'},
            {header: 'MANAGE_SHIPMENTS.STATUS', field: 'status'},
        ], DELAYED_PACKAGES_REPORT_STATISTICS: [
            {title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_PACKAGES', val: 'totalRecordsNo'},
        ],
        TIME_BOUND_PACKAGES_REPORT_API: ':api/admin/reports/customers/detailed',
        DRIVER_ATTEMPTS_REPORT_API: ':api/admin/reports/drivers/delivery-attempts',
        DRIVER_ATTEMPTS_REPORT_EXCEL: ':api/admin/reports/drivers/delivery-attempts/excel',
        DRIVER_ATTEMPTS_REPORT_COUNT: [
            {
                title: 'ADMINISTRATION.DRIVER_REPORTS.TOTAL_PACKAGES',
                api: ':api/admin/reports/drivers/delivery-attempts/count',
                val: 'packagesCountSum'
            }
        ],
        TIME_BOUND_PACKAGES_EXCEL: ':api/admin/reports/time-bound-packages/excel',
        DATE_TYPE_FILTER_LIST: ['CREATED_DATE', 'DELIVERY_DATE', 'RETURN_DATE', 'POSTPONED_DATE'],
        TIME_BOUND_PACKAGES_REPORT: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADMINISTRATION.CUSTOMER_NAME', field: 'customerName', type: 'customerName'},
            {header: 'ADMINISTRATION.RECEIVER_NAME', field: ['receiverFirstName', 'receiverLastName'], type: 'concat'},
            {header: 'ADMINISTRATION.RECEIVER_PHONE', field: 'receiverPhone', type: 'String'},
            {header: 'ADMINISTRATION.PRICE', field: 'cost'},
            {header: 'ADMINISTRATION.COD', field: 'cod'},
            {header: 'ADMINISTRATION.ADDRESS', field: 'toAddress'},
            {header: 'ADMINISTRATION.PACKAGE_DATE', field: 'createdDate', type: 'date'},
            {header: 'MANAGE_SHIPMENTS.EXPECTED_DELIVERY_DATE', field: 'expectedDeliveryDate', type: 'date'}
        ],
        DRIVER_ATTEMPTS_REPORT: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'packageBarcode'},
            {header: 'MANAGE_SHIPMENTS.STATUS', field: 'packageStatus'},
            {header: 'ADMINISTRATION.PACKAGE_DATE', field: 'packageCreatedDate', type: 'date'},
            {header: 'ADMINISTRATION.RECEIVER_NAME', field: 'packageReceiverName'},
            {header: 'DRIVERS_REPORTS.DRIVER_NAME', field: 'packageDriverName', status: 'DRIVER_NAME'},
            {header: 'DRIVERS_REPORTS.NUMBER_OF_ATTEMPTS_CALL', field: 'mobileCallsNumber'},
            {header: 'DRIVERS_REPORTS.NUMBER_OF_ATTEMPTS_MESSAGING', field: 'mobileSmsNumber'},
            {header: 'DRIVERS_REPORTS.NUMBER_OF_WHATSAPP_ATTEMPTS_MESSAGING', field: 'whatsappSmsNumber'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'packageNotes'},
            {header: 'GENERAL.ATTACHMENTS', field: 'isAttachmentExist', type: 'attachment'},

        ]
    },
    PACKAGES_BY_DESTINATION_CITY:{
        REPORTS_API: ':api/admin/reports/packages/by-destination-city/{cityId}',
    },
    DRIVERS_REPORTS_ON_MOUSE_CLICK: {
        DRIVERS_REPORTS_API: ':api/admin/packages/drivers/{driverId}/packages',
        DRIVERS_REPORTS_INFO: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADD_SHIPMENT.SENDER_NAME', field: 'senderName'},
            // {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADD_SHIPMENT.RECEIVER_NAME', field: 'receiverName'},
            {header: 'ADD_SHIPMENT.RECEIVER_PHONE', field: 'receiverPhone'},
            {header: 'ADD_SHIPMENT.RECEIVER_ADDRESS', field: ['destinationCity', 'destinationVillage', 'destinationAddressLine1'], type: 'concat'},
            {header: 'ADD_SHIPMENT.INVOICE_NUMBER', field: 'invoiceNumber'},
            {header: 'MANAGE_SHIPMENTS.STATUS', field: 'status'},
            {header: 'MANAGE_SHIPMENTS.COD', field: 'cod'},
            {header: 'MANAGE_SHIPMENTS.COST', field: 'cost'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'notes'},
            {header: 'MANAGE_SHIPMENTS.ADMIN_NOTES', field: 'adminNotes'},
        ],
        DRIVERS_REPORTS_INFO_WITHOUT_STATUS : [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADD_SHIPMENT.SENDER_NAME', field: 'senderName'},
            // {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADD_SHIPMENT.RECEIVER_NAME', field: 'receiverName'},
            {header: 'ADD_SHIPMENT.RECEIVER_PHONE', field: 'receiverPhone'},
            {header: 'ADD_SHIPMENT.RECEIVER_ADDRESS', field: ['destinationCity', 'destinationVillage', 'destinationAddressLine1'], type: 'concat'},
            {header: 'ADD_SHIPMENT.INVOICE_NUMBER', field: 'invoiceNumber'},
            {header: 'MANAGE_SHIPMENTS.COD', field: 'cod'},
            {header: 'MANAGE_SHIPMENTS.COST', field: 'cost'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'notes'},
            {header: 'MANAGE_SHIPMENTS.ADMIN_NOTES', field: 'adminNotes'},
        ]
    },
    PICKUP_REPORTS_ON_MOUSE_CLICK_PICKUP: {
        PICKUP_REPORTS_PICKUP_API: ':api/admin/pickups',
        PICKUP_REPORTS_INFO: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'MANAGE_COD_CUSTOMERS.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADMINISTRATION.PHONE_NUMBER', field: 'customerPhone'},
            {header: 'ADD_SHIPMENT.INVOICE_NUMBER', field: 'invoiceNumber'},
            {header: 'ADMINISTRATION.PACKAGE_DATE', field: 'createdDate', type: 'date'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'notes'},
        ]
    },
    DRIVERS_REPORTS: {
        DRIVERS_REPORTS_API: ':api/admin/reports/drivers/info/summary',
        DRIVERS_REPORTS_INFO: [
            {header: 'DRIVERS_REPORTS.DRIVER_NAME', field: 'driverName', status: 'DRIVER_NAME'},
            {header: 'DRIVERS_REPORTS.ASSIGNED', field: 'assignedPickupsNo', status: 'ASSIGNED_TO_DRIVER'},
            {header: 'DRIVERS_REPORTS.ACCEPTED', field: 'acceptedPickupsNo', status: 'ACCEPTED_BY_DRIVER'},
            {header: 'DRIVERS_REPORTS.DONE', field: 'pickedPickupsNo', status: 'IN_CAR'},
            {header: 'DRIVERS_REPORTS.PENDING_PICKUP', field: 'acceptedByDriver', status: 'PENDING_PICKUP'},
            {header: 'DRIVERS_REPORTS.IN_CAR', field: 'pickedPackagesNo', status: 'PICKED'},
            {header: 'DRIVERS_REPORTS.RETURNED', field: 'returnedPackagesNo', status: 'RETURNED'},
            {header: 'DRIVERS_REPORTS.POSTPONED', field: 'postponedPackagesNo', status: 'POSTPONED'},
            {header: 'DRIVERS_REPORTS.STUCK', field: 'failedPackagesNo', status: 'FAILED'},
            {header: 'DRIVERS_REPORTS.DELIVERED', field: 'deliverePackagesNo', status: 'DELIVERED'},
            {header: 'DRIVERS_REPORTS.PENDING', field: 'pickedPackagesNo', status: 'PICKED'},
            {header: 'DRIVERS_REPORTS.ASSIGN_TO_DRIVER', field: 'assignToDriverNo', status: 'ASSIGN_TO_DRIVER'},
            {header: 'DRIVERS_REPORTS.NOT_UPDATED', field: 'notUpdatedPackagesNo', status: 'NOT_UPDATED'},
            {header: 'DRIVERS_REPORTS.NOT_RECEIVED', field: 'notReceivedCod', status: 'DELIVERED'},
            {header: 'DRIVERS_REPORTS.PICKED_MASS_COD_SUM', field: 'totalCodWithoutCost', status: 'MASS_REPORT'}
        ]
    },
    ACTIVE_CUSTOMERS_REPORTS: {
        API: ':api/admin/reports/customers/info/summary',
        INFO: [
            {header: 'DRIVERS_REPORTS.CUSTOMER_NAME', field: 'customerName', status: ''},
            {header: 'DRIVERS_REPORTS.ASSIGNED', field: 'assignedPickupsNo', status: ''},
            {header: 'DRIVERS_REPORTS.ACCEPTED', field: 'acceptedPickupsNo', status: ''},
            {header: 'DRIVERS_REPORTS.DONE', field: 'pickedPickupsNo', status: ''},
            {header: 'DRIVERS_REPORTS.PENDING_PACKAGES_NUMBER', field: 'pendingPackagesNo', status: 'PENDING'},
            {header: 'DRIVERS_REPORTS.APPROVED_PACKAGES_NUMBER', field: 'approvedPackagesNo', status: 'APPROVED'},
            {header: 'DRIVERS_REPORTS.PENDING_PICKUP', field: 'acceptedByDriver', status: 'ACCEPTED'},
            {header: 'DRIVERS_REPORTS.IN_CAR', field: 'pickedPackagesNo', status: 'PICKED'},
            {header: 'DRIVERS_REPORTS.RETURNED', field: 'returnedPackagesNo', status: 'RETURNED'},
            {header: 'DRIVERS_REPORTS.POSTPONED', field: 'postponedPackagesNo', status: 'POSTPONED'},
            {header: 'DRIVERS_REPORTS.STUCK', field: 'failedPackagesNo', status: 'FAILED'},
            {header: 'DRIVERS_REPORTS.DELIVERED', field: 'deliverePackagesNo', status: 'DELIVERED'},
            {header: 'DRIVERS_REPORTS.IN_HUB', field: 'inHubPackagesNo', status: 'IN_HUB'},
            {header: 'DRIVERS_REPORTS.NOT_UPDATED', field: 'notUpdatedPackagesNo', status: ''},

        ]
    },
    ACTIVE_CUSTOMERS_ON_MOUSE_CLICK: {
        API: ':api/admin/packages/customers/{customerId}/packages',
        INFO: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADD_SHIPMENT.SENDER_NAME', field: 'senderName'},
            {header: 'ADD_SHIPMENT.RECEIVER_NAME', field: 'receiverName'},
            {header: 'ADD_SHIPMENT.RECEIVER_PHONE', field: 'receiverPhone'},
            {header: 'ADD_SHIPMENT.INVOICE_NUMBER', field: 'invoiceNumber'},
            {header: 'MANAGE_SHIPMENTS.STATUS', field: 'status'},
            {header: 'MANAGE_SHIPMENTS.COD', field: 'cod'},
            {header: 'MANAGE_SHIPMENTS.COST', field: 'cost'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'notes'},
            {header: 'MANAGE_SHIPMENTS.ADMIN_NOTES', field: 'adminNotes'},
        ]
    },
    HUBS_REPORTS: {
        HUBS_REPORTS_API: ':api/admin/reports/hubs/info/summary',
        HUB_REPORTS_INFO: [
            {header: 'HUBS_REPORTS.HUB_NAME', field: 'hubName', status: ''},
            {header: 'HUBS_REPORTS.IN_HUB', field: 'inHubPickupsNo', status: 'IN_HUB_PICKUP'},
            {header: 'HUBS_REPORTS.IN_HUB', field: 'inHubPackagesNo', status: 'IN_HUB'},
            {header: 'HUBS_REPORTS.ON_SHELVES', field: 'onShelvesPackagesNo', status: 'ON_SHELF'},
            {header: 'HUBS_REPORTS.DELIVERED', field: 'deliverPackagesNo', status: 'DELIVERED'},
            {header: 'HUBS_REPORTS.RETURNED', field: 'returnedPackagesNo', status: 'RETURNED'},
            {header: 'HUBS_REPORTS.NOT_UPDATED', field: 'notUpdatedPackagesNo', status: 'NOT_UPDATED'},
            {header: 'HUBS_REPORTS.POSTPONED_IN_HUB', field: 'postponedPackagesNo', status: 'POSTPONED_IN_HUB'},
            {header: 'HUBS_REPORTS.TOTAL_COD_IN_HUB', field: 'totalCodInHub', status: 'TOTAL_COD_IN_HUB'}
        ]
    },
    PARTNER_REPORTS: {
        PARTNER_REPORTS_API: ':api/admin/reports/partners/info/summary',
        PARTNER_REPORTS_INFO: [
            {header: 'PARTNERS_REPORTS.PARTNER_NAME', field: 'partnerName', status: ''},
            {header: 'PARTNERS_REPORTS.APPENDING', field: 'appending', status: 'PENDING'},
            {header: 'PARTNERS_REPORTS.IN_CAR', field: 'inCar', status: 'IN_CAR'},
            {header: 'PARTNERS_REPORTS.IN_HUB', field: 'inHub', status: 'IN_HUB'},
            {header: 'PARTNERS_REPORTS.RETURNED', field: 'returned', status: 'RETURNED'},
            {header: 'PARTNERS_REPORTS.POSTPONED', field: 'postponed', status: 'POSTPONED'},
            {header: 'PARTNERS_REPORTS.DELIVERED', field: 'delivered', status: 'DELIVERED'},
            {header: 'PARTNERS_REPORTS.PICKED_COD', field: 'pickedCod', status: 'PICKED_COD'},
            {header: 'PARTNERS_REPORTS.TOTAL_NOT_RECEIVE_COD', field: 'notReceivedCod', status: 'NOT_RECEIVED_COD'}
        ]
    },
    HUBS_REPORTS_ON_MOUSE_CLICK: {
        HUBS_REPORTS_API: ':api/admin/packages/hubs/{hubId}/packages',
        HUBS_REPORTS_INFO: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADD_SHIPMENT.SENDER_NAME', field: 'senderName'},
            {header: 'ADD_SHIPMENT.RECEIVER_NAME', field: 'receiverName'},
            {header: 'ADD_SHIPMENT.RECEIVER_PHONE', field: 'receiverPhone'},
            {header: 'ADD_SHIPMENT.INVOICE_NUMBER', field: 'invoiceNumber'},
            {header: 'MANAGE_SHIPMENTS.STATUS', field: 'status'},
            {header: 'MANAGE_SHIPMENTS.COD', field: 'cod'},
            {header: 'MANAGE_SHIPMENTS.COST', field: 'cost'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'notes'},
            {header: 'MANAGE_SHIPMENTS.ADMIN_NOTES', field: 'adminNotes'},
        ]
    },
    PARTNERS_REPORTS_ON_MOUSE_CLICK: {
        PARTNERS_REPORTS_API: ':api/admin/packages/partners/{partnerId}/packages',
        PARTNERS_REPORTS_INFO: [
            {header: 'MANAGE_SHIPMENTS.BARCODE', field: 'barcode'},
            {header: 'ADD_SHIPMENT.SENDER_NAME', field: 'senderName'},
            {header: 'ADD_SHIPMENT.RECEIVER_NAME', field: 'receiverName'},
            {header: 'ADD_SHIPMENT.RECEIVER_PHONE', field: 'receiverPhone'},
            {header: 'ADD_SHIPMENT.INVOICE_NUMBER', field: 'invoiceNumber'},
            {header: 'MANAGE_SHIPMENTS.STATUS', field: 'status'},
            {header: 'MANAGE_SHIPMENTS.COD', field: 'cod'},
            {header: 'MANAGE_SHIPMENTS.COST', field: 'cost'},
            {header: 'MANAGE_SHIPMENTS.NOTES', field: 'notes'},
            {header: 'MANAGE_SHIPMENTS.ADMIN_NOTES', field: 'adminNotes'},
        ]
    },
    FINANCIAL_REPORT: {
        FINANCIAL_REPORT_API: ':api/accountant/financial-report',
        FINANCIAL_REPORT_INFO: [
            {header: 'FINANCIAL_REPORT.TYPE', field: 'type'},
            {header: 'FINANCIAL_REPORT.VALUE', field: 'value'},
        ],
        DATE_TYPE_FILTER_LIST: ['CREATED_DATE', 'RECEIVE_DATE', 'DELIVERY_DATE'],
    },
    SUMMARY_REPORTS: {
        CUSTOMER_REPORT_API: ':api/admin/reports/customers/summary',
        CUSTOMER_REPORT: [
            {header: 'ADMINISTRATION.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADMINISTRATION.NUMBER_OF_PACKAGES', field: 'packagesCount'},
            {header: 'ADMINISTRATION.RETURNED_PACKAGES', field: 'returnedPackagesCount'},
            {header: 'ADMINISTRATION.DELIVERED_PACKAGES', field: 'deliveredPackagesCount'},
            {header: 'ADMINISTRATION.UNSHIPPED_PACKAGES', field: 'unshippedPackagesCount'},
            {header: 'ADMINISTRATION.PRICE', field: 'cost'}
        ],
        CUSTOMER_REPORTS_STATISTICS: [
            {title: 'ADMINISTRATION.NUMBER_OF_PACKAGES', val: 'packagesCountSum'},
            {title: 'MANAGE_COD_CUSTOMERS.COST_SUM', val: 'costSum'},
            {title: 'ADMINISTRATION.RETURNED_PACKAGES', val: 'returnedPackagesCountSum'},
            {title: 'ADMINISTRATION.DELIVERED_PACKAGES', val: 'deliveredPackagesCountSum'},
            {title: 'ADMINISTRATION.UNSHIPPED_PACKAGES', val: 'unshippedPackagesCountSum'}
        ],
        DRIVER_REPORT_API: ':api/admin/reports/drivers/summary',
        DRIVER_REPORT: [
            {header: 'ADMINISTRATION.DRIVER_NAME', field: 'driverName'},
            {header: 'ADMINISTRATION.NUMBER_OF_PACKAGES', field: 'packagesCount'},
            {header: 'ADMINISTRATION.PRICE', field: 'cost'}
        ],
        CITY_UNDELIVERED_TO_COD_SUM_REPORT_API: ':api/admin/reports/cod/summary',
        CITY_UNDELIVERED_TO_COD_SUM_REPORT: [
            {header: 'ADMINISTRATION.DESTINATION_CITY_REGION', field: 'city'},
            {header: 'ADMINISTRATION.COD_SUM', field: 'cod'},
            {header: 'ADMINISTRATION.COST_SUM', field: 'cost'}
        ],
        CITY_UNDELIVERED_TO_COD_SUM_REPORT_STATISTICS: [
            {title: 'ADMINISTRATION.COD_SUM', val: 'codSum'},
            {title: 'ADMINISTRATION.COST_SUM', val: 'costSum'}
        ],
        ZONES_SHIPPED_TO_REPORT_API: ':api/admin/reports/packages/by-destination-city',
        ZONES_SHIPPED_TO_REPORT: [
            {header: 'ADMINISTRATION.DESTINATION_CITY_REGION', field: 'city'},
            {header: 'ADMINISTRATION.NUMBER_OF_PACKAGES_SHIPPED_TO', field: 'packagesCount'},
            {header: 'ADMINISTRATION.PRICE', field: 'cost'}
        ],
        ZONES_SHIPPED_FROM_REPORT_API: ':api/admin/reports/packages/by-origin-city',
        ZONES_SHIPPED_FROM_REPORT: [
            {header: 'ADMINISTRATION.CITY_REGION', field: 'city'},
            {header: 'ADMINISTRATION.NUMBER_OF_PACKAGES_SHIPPED_FROM', field: 'packagesCount'},
            {header: 'ADMINISTRATION.PRICE', field: 'cost'}
        ],
        ZONES_REPORT_API: ':api/admin/reports/packages/undeliverd/by-destination-city',
        ZONES_REPORT: [
            {header: 'ADMINISTRATION.CITY_REGION', field: 'city'},
            {header: 'ADMINISTRATION.NUMBER_OF_PACKAGES', field: 'packagesCount'}
        ],
        UNDELIVERED_PACKAGES_REPORT_API: ':api/admin/reports/customers/undelivered/summary',
        UNDELIVERED_PACKAGES_REPORT: [
            {header: 'ADMINISTRATION.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADMINISTRATION.NUMBER_OF_UNDELIVERED_PACKAGES', field: 'packagesCount'}
        ]
    },
    ADMINISTRATIVE_REPORTS: {
        TIME_PERIOD_REPORTS_API: ':api/admin/reports/customers/average-days-to-deliver/administrative',
        TIME_PERIOD_REPORTS: [
            {header: 'ADMINISTRATION.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADMINISTRATION.CITY_REGION', field: 'city'},
            {header: 'ADMINISTRATION.AVERAGE_TIME_PERIOD_IN_DAYS', field: 'averageNumberOfDays'}
        ],
        CUSTOMER_ACTIVITY_REPORT_API: ':api/admin/reports/customers/{customerId}/administrative/activity',
        CUSTOMERS_ACTIVITY_REPORT_API: ':api/admin/reports/customers/administrative/activity',
        CUSTOMERS_ACTIVITY_REPORT: [
            {header: 'ADMINISTRATION.CUSTOMER_NAME', field: 'customerName'},
            {header: 'ADMINISTRATION.MONTH', field: 'month'},
            {header: 'ADMINISTRATION.NUMBER_OF_PACKAGES', field: 'packagesCount'},
            {header: 'ADMINISTRATION.REVENUE', field: 'cost'},
        ],
        ACTIVITY_REPORT_API: ':api/admin/reports/company/administrative/activity',
        ACTIVITY_REPORT: [
            {header: 'ADMINISTRATION.MONTH', field: 'month'},
            {header: 'ADMINISTRATION.THIS_YEAR', field: 'thisYearPkgsCount'},
            {header: 'ADMINISTRATION.lAST_YEAR', field: 'lastYearPkgsCount'},
        ]
    },

};
