<div class="notifications-list">
    <div class="main-header">
        <div class="text-align-start">
            <div class="title">{{ 'COMPANY.NOTIFICATIONS' | translate }}</div>
        </div>
        <div class="text-align-end">
            <span class="action"
               *ngIf="notifications.length > 0 && !isLoadingNotification && !isSettingAllAsRead"
               (click)="onMarkAllAsRead()">{{ 'ACTIONS.READ_ALL_NOTIFICATIONS' | translate }}</span>
            <i class="fas fa-spinner fa-pulse" *ngIf="isSettingAllAsRead"></i>
        </div>
    </div>
    <div class="loading-container" *ngIf="isLoadingNotification">
        <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div *ngIf="notifications.length == 0 && !isLoadingNotification" class="empty-msg">
        لا يوجد تنبيهات
    </div>
    <div class="notifications-sub-container">
        <ul class="notification-options" *ngIf="notifications.length > 0 && !isLoadingNotification">
            <li class="notification-item" *ngFor="let item of notifications; trackBy: trackNotificationById"
                [ngClass]="{'read' : item?.isRead}"
            >
                <div class="d-flex">
                    <div>
                        <div class="company-logo contain-image" [ngStyle]="{'background-image': 'url(' + logo + ')'}">
                        </div>
                    </div>
                    <div class="text-container">
                        <div class="notification-body">{{!isEnglishSelected && item.titleArabic ? item?.bodyArabic : item?.body}}</div>
                        <div class="notification-date">
                            {{ item?.createdDate | date:'EEEE d, MMM hh:mm'}}
                        </div>
                    </div>
                    <div>
                        <i class="fas fa-trash delete-icon cursor-pointer" (click)="deleteNotification(item, i)"></i>
                    </div>
                </div>
            </li>
        </ul>
        <div class="load-more" *ngIf="hasMoreNotification">
            <button (click)="loadMoreNotifications()" *ngIf="notifications.length > 0 && !isLoadingNotification && !isLoadingMoreNotification">
                {{ 'ACTIONS.SHOW_MORE' | translate }}
            </button>
            <div class="load-more-spinner" *ngIf="isLoadingMoreNotification">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
        </div>
    </div>
</div>
