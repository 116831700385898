<p-toast></p-toast>
<div class="close-icon" (click)="closeModal()"><img src="../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="row d-inline-flex main-container">
        <div class="d-flex flex-column w-100 table-container">
            <div class="shipment-body mass-cod-history-table">
                <p-table appPTableRtlFix dataKey="id" #table [value]="historyData"
                         paginatorDropdownAppendTo="body"
                         styleClass="h-100 history-table"
                         [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                         [lazy]="true" (onLazyLoad)="loadReportsLazy($event)" [paginator]="true"
                         [responsive]="true"
                         [rows]="pageSize" [totalRecords]="totalRecords"
                         [rowsPerPageOptions]="rowsPerPageOptions"
                         [(first)]="first">
                    <ng-template pTemplate="emptymessage">
                        <app-no-data-available-component>
                        </app-no-data-available-component>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <th>{{'HISTORY.USER_NAME' | translate}}</th>
                        <th>{{'ADMINISTRATION.DRIVER_REPORTS.NOTES' | translate}}</th>
                        <th>{{'SHIPMENT.DATE' | translate}}</th>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-expanded="expanded">
                        <tr>
                            <td>
                                <div class="text-value">
                                    {{item.userName}}
                                </div>
                            </td>
                            <td>
                                <div class="text-value">
                                    {{(currentLang === 'en' ? item.note : item.arabicNote)}}
                                </div>
                            </td>
                            <td>
                                <div class="date-value">
                                    {{item?.createdDate | date:'dd-MM-yyyy'}} {{getLocalTime(item?.createdDate)}}
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="row bottom-actions">
                <div class="actions d-inline-flex">
                    <div class="done-action flex-2d-center"
                         (click)="closeModal()">{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
