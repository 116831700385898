import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SHIPPING_RATES_CONSTANTS} from '../shipping-rates-constants';

@Injectable({
    providedIn: 'root'
})
export class PricingService {

    constructor(private http: HttpClient) {
    }

    public getJettPrices() {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_JETT_PRICES);
    }

    public getCompanyPrices() {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_COMPANY_PRICES);
    }

    public getCustomerPrices(customerID) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_CUSTOMER_PRICES.replace('{customerId}', customerID));
    }

    public exportPriceToExcel(customerID) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.EXPORT_CUSTOMER_PRICES.replace('{customerId}', customerID),
            {params: {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}});
    }

    public saveCompanyPrice(companyPrice, type) {
        return this.http.post(type === 'COMPANY' ?
            SHIPPING_RATES_CONSTANTS.SAVE_COMPANY_PRICE : SHIPPING_RATES_CONSTANTS.SAVE_PRICING_LIST_PRICE , companyPrice);
    }

    public saveCustomerPrice(companyPrice) {
        return this.http.post(SHIPPING_RATES_CONSTANTS.SAVE_CUSTOMER_PRICE, companyPrice);
    }
    public saveAdditionalFeeRange(feeId, range) {
        return this.http.post(SHIPPING_RATES_CONSTANTS.SAVE_ADDITION_FEE_RANGE.replace('{feeId}', feeId), range);
    }
    public saveNewAdditionFee(companyPrice) {
        return this.http.post(SHIPPING_RATES_CONSTANTS.SAVE_ADDITION_FEE, companyPrice);
    }
    public deleteCustomerPrice(customerId) {
        return this.http.delete(SHIPPING_RATES_CONSTANTS.DELETE_CUSTOMER_PRICE.replace('{customerId}', customerId, ), {});
    }
    public deleteHubPrice(hubId) {
        return this.http.delete(SHIPPING_RATES_CONSTANTS.DELETE_HUB_PRICE.replace('{hubId}', hubId), {});
    }
    public deletePricingList(pricingListId) {
        return this.http.delete(SHIPPING_RATES_CONSTANTS.DELETE_PRICE_LIST.replace('{pricingListId}', pricingListId, ), {});
    }
    public deleteServicePricingList(priceId) {
        return this.http.delete(SHIPPING_RATES_CONSTANTS.DELETE_SERVICE_PRICE.replace('{priceId}', priceId, ), {});
    }

    public editCustomerPrice(companyPrice, id) {
        return this.http.put(SHIPPING_RATES_CONSTANTS.EDIT_COMPANY_PRICE.replace('{priceId}', id), companyPrice);
    }

    public editAdditionFees(price, id) {
        return this.http.put(SHIPPING_RATES_CONSTANTS.EDIT_ADDITION_FEE.replace('{feeId}', id), price);
    }
    public editAdditionFeesDetail(price, id) {
        return this.http.put(SHIPPING_RATES_CONSTANTS.EDIT_ADDITION_FEE_DETAILS.replace('{detailId}', id), price);
    }

    public deleteCompanyPrice(priceId) {
        return this.http.delete(SHIPPING_RATES_CONSTANTS.DELETE_COMPANY_PRICE.replace('{priceId}', priceId), {});
    }
    public deleteDriverPrices(driverEarningId) {
        return this.http.delete(SHIPPING_RATES_CONSTANTS.DELETE_DRIVER_EARNING_BY_ID
            .replace('{driverEarningId}', driverEarningId));
    }
    public getCustomersPrices(params) {
        const url = SHIPPING_RATES_CONSTANTS.GET_CUSTOMERS_PRICES;
        return this.http.get(url, {params});
    }

    public getDriverPrices(driverID = 0, search = '') {
        let url = SHIPPING_RATES_CONSTANTS.GET_DRIVER_EARNING;
        if (search !== '') {
            url += '?search=' + search;
        }
        if (driverID !== 0) {
            if (search === '') {
                url += '?driverId=' + driverID;
            } else {
                url += '&driverId=' + driverID;
            }
        }
        return this.http.get(url);
    }

    public getPricingList(params, url = SHIPPING_RATES_CONSTANTS.GET_PRICING_LIST) {
        return this.http.get(url, {params});
    }
    public getPrivateDriverPricing(params) {
        const url = SHIPPING_RATES_CONSTANTS.GET_DRIVERS_PRIVATE_PRICING;
        return this.http.get(url, {params});
    }
    public addPricingList(name) {
        const url = SHIPPING_RATES_CONSTANTS.ADD_PRICING_LIST;
        return this.http.post(url, {name : name});
    }

    public getPricingListPrices(pricingListId) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_PRICING_LIST_PRICES.replace('{pricingListId}', pricingListId));
    }
    public getHubPrices(id, query = '') {
        return this.http.get((SHIPPING_RATES_CONSTANTS.GET_HUB_PRICING_LIST.replace('{hubId}', id)) + query);
    }

    getHubsPrices(param = {}) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_HUBS_PRICES, param);
    }

    public getServiceTypesPrices(urlQuery?) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_SERVICE_TYPES_PRICES + (urlQuery ? urlQuery : ''));
    }

    public getAdditionalFees(params?) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_ADDITIONAL_FEES, {params});
    }
    public postDriverPrices(driverEarning, driverID = 0) {
        let url = SHIPPING_RATES_CONSTANTS.GET_DRIVER_EARNING;
        if (driverID !== 0) {
            url += '?driverId=' + driverID;
        }
        return this.http.post(url, driverEarning);
    }

    public putDriverPrice(driverEarning, driverEarningId) {
        return this.http.put(SHIPPING_RATES_CONSTANTS.PUT_DRIVER_EARNING_BY_ID
            .replace('{driverEarningId}', driverEarningId), driverEarning);
    }

    public getShippingRatesHistory(params) {
        return this.http.get(SHIPPING_RATES_CONSTANTS.GET_SHIPPING_RATES_HISTORY, {params});
    }
}
