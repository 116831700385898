<div class="choose-hub-and-driver-container">
    <div class="header">
        <i class="fas fa-times cursor-pointer" style="float: right;" (click)="closeModal()"></i>
        <div>
            {{(isFromPartner? 'MANAGE_SHIPMENTS.CHOOSE_HUB_AND_PARTNER' : 'MANAGE_SHIPMENTS.CHOOSE_HUB_AND_DRIVER' ) | translate | hubTitle}}
        </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
        <p-progressSpinner> </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div class="col-12 driver-barcode-container">
                <div class="inputs row">
                    <app-input-field-component label="MANAGE_SHIPMENTS.RECEIVED_HUB" [form]="form" controlName="hubId" class="col-12" [options]="hubsOptions" [isRequired]="true" controlType="select"> </app-input-field-component >
<div *ngIf="!isFromPartner" class="drivers-container">
    <div  class="col-12 drivers-list">
        <!--                        <app-field-input-field-component  class="col-6 col-lg-2 col-md-6" label="QUICK_ADD.DRIVER" [form]="form" controlName="driverId" [options]="driversOptions" [isRequired]="true" controlType="select">-->
        <!--                        </app-field-input-field-component >-->
        <p-autoComplete formControlName="driverId"
                        type="text"
                        placeholder="{{'QUICK_ADD.DRIVER' | translate}}"
                        field="label"
                        [forceSelection]="true"
                        [dropdown]="true"
                        [suggestions]="driversOptions"
                        (completeMethod)="getDrivers($event)"
                        #driversAc
        >
            <ng-template let-driver pTemplate="item">
                <div class="store-item">
                    <div class="text-ellipsis">{{driver.label}}</div>
                </div>
            </ng-template>
        </p-autoComplete>
    </div>
</div>
                    <div *ngIf="isFromPartner" class="col-12 partner-list">
                        <app-users-list-dropdown-component [isPartner]="true" controlName="partnerId" [inputStyleClass]="'width95'" (userChanged)="partnerSelected($event)"> </app-users-list-dropdown-component>
                    </div>
                    <div *ngIf="!isPickup && !isFromPartner && !isFromReturnPackageTab" style="margin-top: 30px;" class="col-12">
                        <p style="white-space: pre-wrap;">
                            {{"MANAGE_SHIPMENTS.CHOOSE_HUB_AND_DRIVER_Note" | translate | hubTitle}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div style="padding: 10px 30px;">
                <div class="add-button cursor-pointer" (click)="receiveFromDriver()" [ngClass]="{'disabled-button disabled': notValidForm()}">
                    <i class="far fa-save"></i>
                    {{'ACCOUNTING.START_SCANNING' | translate }}
                </div>
                <div class="reset-button cursor-pointer" (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
