import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Address} from '../../shared/models/address.class';
import {ZonesService} from '../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {MultiSelectComponent} from '../../shared/components/form-components/multi-select/multi-select.component';

@Component({
    selector: 'app-customer-region-filter',
    templateUrl: './customer-region-filter.component.html',
    styleUrls: ['./customer-region-filter.component.scss']
})
export class CustomerRegionFilterComponent extends Address implements OnInit {
    public isLoading = false;
    @Input() isSender = true;
    public form: FormGroup;
    public customerName = '';
    public originRegion = null;
    public originCity = null;
    public destinationRegion = null;
    public destinationCity = null;
    public originVillage = null;
    public destinationVillage = null;

    @Output() onFinish:  EventEmitter<{}> = new EventEmitter<{}>();
    @Output() onCancel:  EventEmitter<{}> = new EventEmitter<{}>();

    @Input() senderData: {customerName: string, originRegion: any, originCity: any, originVillage: any };
    @Input() receiverData: {customerName: string, destinationRegion: any, destinationCity: any, destinationVillage: any };

    @ViewChild('originRegion') originRegionView: MultiSelectComponent;
    @ViewChild('originCity') originCityView: MultiSelectComponent;
    @ViewChild('originVillage') originVillageView: MultiSelectComponent;
    @ViewChild('destinationRegion') destinationRegionView: MultiSelectComponent;
    @ViewChild('destinationCity') destinationCityView: MultiSelectComponent;
    @ViewChild('destinationVillage') destinationVillageView: MultiSelectComponent;

    constructor(private activeModal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private zonesService: ZonesService,
                public translateService: TranslateService
    ) {
        super(zonesService, translateService, true);
    }

    ngOnInit() {
        this.isLoading = false;
        this.initInfo();
        this.initRegions();
        if (this.senderData?.originCity?.length) {
            this.initCities(0, this.senderData.originRegion[0], '');

            if (this.senderData.originVillage?.length) {
                this.initVillage(0, this.senderData.originCity[0], '');
            }
        } else if (this.receiverData?.destinationCity?.length) {
            this.initCities(0, this.receiverData.destinationRegion[0], '');

            if (this.receiverData.destinationVillage?.length) {
                this.initVillage(0, this.receiverData.destinationCity[0], '');
            }
        }
    }

    public initInfo() {
        let originRegion = [];
        let originCity = [];
        let originVillage = [];
        let customerName = '';

        let receiverName = '';
        let destinationRegion = null;
        let destinationCity = null;
        let destinationVillage = null;
        if (this.senderData) {
            originRegion = this.senderData.originRegion ? this.senderData.originRegion : null;
            originCity = this.senderData.originCity ? this.senderData.originCity : null;
            originVillage = this.senderData.originVillage ? this.senderData.originVillage : null;
            customerName = this.senderData.customerName ? this.senderData.customerName : null;

        }
        if (this.receiverData) {
            receiverName = this.receiverData.customerName;
            destinationRegion = this.receiverData.destinationRegion;
            destinationCity = this.receiverData.destinationCity;
            destinationVillage = this.receiverData.destinationVillage;
        }
        this.form = this.formBuilder.group({
            originRegion: [originRegion],
            originCity: [originCity],
            originVillage: [originVillage],
            destinationRegion: [destinationRegion],
            destinationCity: [destinationCity],
            destinationVillage: [destinationVillage],
            customer: this.isSender ? customerName : receiverName
        });
        this.initFormEvent(this.form, 0, {
            country: '',
            region: 'originRegion',
            city: 'originCity',
            village: 'originVillage'
        });

        this.initFormEvent(this.form, 1, {
            country: '',
            region: 'destinationRegion',
            city: 'destinationCity',
            village: 'destinationVillage'
        });
    }

    public finish() {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.getRawValue();
        if (this.isSender) {
            this.onFinish.emit({
                customer: data.customer,
                regionId: data.originRegion,
                cityId: data.originCity,
                villageId: data.originVillage
            });
        } else {
            this.onFinish.emit({
                customer: data.customer,
                regionId: data.destinationRegion,
                cityId: data.destinationCity,
                villageId: data.destinationVillage
            });
        }

    }

    updateZoneForm($event, controlName) {
        this.form.controls[controlName].setValue($event);
        this.form.controls[controlName].updateValueAndValidity();
    }
    public onClose() {
        this.onCancel.emit();
    }

    test() {
        console.log(this.form.controls['destinationRegion'].value);
    }
}
