import {Component, ContentChild, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SpinnerState} from './../../behavior/spinner-state.enum';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {QueryInterface} from '../../models/query.interface';
import {SHARED_CONSTANTS} from '../../services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {DELAYED_DATE_FILTERS_LIST, FINANCIAL_REPORT_LIST, FINANCIAL_REPORT_MAP} from '../../../shipment/services/constants';
import {MessageService} from 'primeng/api';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {REPORTS_CONSTANTS} from '../../../administration/reports-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {PostponePackageComponent} from '../../../shipment/postpone-package/postpone-package.component';
import {UserService} from '../../services/user.service';
import {ChangePackageStatusService} from '../../../shipment/services/change-package-status.service';
import {ImageSliderComponent} from '../image-slider/image-slider.component';
import {DriversService} from '../../services/drivers.service';

@Component({
    selector: 'app-admin-report',
    templateUrl: './admin-report.component.html',
    styleUrls: ['./admin-report.component.scss']
})
export class AdminReportComponent implements OnInit {
    @Input() startFromIndex = 0;
    @Input() columns;
    @Input() hasFilters = false;
    @Input() expandLink;
    @Input() TYPE = '';
    @Input() reportTitle;
    @Input() dataInterceptor;
    @Input() statics = [];
    @Input() APIStatistics = [];
    @Input() filterCustomer = false;
    @Input() filterDrivers = false;
    @Input() filterBarcode = false;
    @Input() multipleDriversFilter = false;
    @Input() filterHubs = false;
    @Input() filterPartners = false;
    @Input() filterDate = true;
    @Input() queryParams = {};
    @Input() isExportToExcel = false;
    @Input() isPdfRemove = true;
    @Input() rowDataUrl = '';
    @Input() externalCustomerId = '';
    @Input() isIdleCustomerReport = false;
    @Input() statusFilter = false;
    @Input() postApi = false;
    @Input() isNotDelivered = false;
    @Input() disablePrintAll = false;
    @Input() delayedAndCreatedDate = false;
    @Input() isCount = false;
    @Input() isRemoveSearch = false;
    @Input() dateFilterType = null;
    @Input() isPostRowData = false;
    @Input() columnsToSkipInTotal = 0;
    @Input() calculateTotalLocally = false;
    @Input() forceViewTotal = false;
    @Input() showDateTypeFilter = false;
    @Input() dateTypeFilterList = [];
    @Input() setDefaultDate: string;
    @ViewChild('table') table;
    @ContentChild(TemplateRef) filter: TemplateRef<any>;
    selectedDateTypeFilter;
    public isDeliveredToSender;
    public isCompleted;
    public isNotesAdded = false;
    public dateFiltersList;
    public selectedDateFilter;
    public popup_field;
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    showExportSwappedDelivered = false;

    @Input()
    set apiUrl(val) {
        if (val) {
            this._apiUrl = val;
            this.onSearch();
        }
    }

    get apiUrl() {
        return this._apiUrl;
    }

    public records;
    public data;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public pageNumber = 0;
    public totalRecords = 0;
    public tableName;
    public fromDate;
    public toDate;
    public searchContent = '';
    public barcodeSearch = '';
    public customerId = '';
    public driverId = '';
    public partnerId = '';
    public hubId = '';
    public _apiUrl;
    public pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    public debouncedSearch;
    public rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    public selectedShipmentStatus = 'ALL';
    public shipmentStatusList;
    public userRole = '';
    public totalValues;
    public apiStatisticsResults = {};
    inHub = false;
    public isAttachmentOpened = false;
    public customerDropdownSettings = {};
    public drivers = [];
    public selectedDriver;
    constructor(private httpClient: HttpClient,
                private datePipe: DatePipe,
                private activeModal: NgbActiveModal,
                public translateService: TranslateService,
                private modalService: NgbModal,
                public messageService: MessageService,
                private router: Router,
                private userService: UserService,
                private changePackageStatusService: ChangePackageStatusService,
                private athuenticationService: AuthenticationService,
                private shipmentsService: ShipmentsService,
                private route: ActivatedRoute,
                private driversList: DriversService) {
    }

    public loadPackagesLazy($event) {
        this.pageSize = $event.rows;
        this.pageNumber = $event.first / ($event.rows || this.pageSize);
        this.initShipments($event.rows || this.pageSize);
        this.getAPIStatisticsResults();
    }


    public setCustomerDropDownSettings() {
        this.customerDropdownSettings =  {
            singleSelection: false,
            idField: 'value',
            textField: 'label',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: this.translateService.instant('GENERAL.SEARCH'),
            noFilteredDataAvailablePlaceholderText: this.translateService.instant('GENERAL.NO_RESULTS_FOUND'),
        };
    }

    ngOnInit() {
        if (this.isIdleCustomerReport) {
            this.fromDate = this.getDateBeforeSpecificDays(7);
        }
        if (this.statusFilter) {
            this.shipmentStatusList = this.setShipmentStatus();
        }
        if (this.delayedAndCreatedDate) {
            this.dateFiltersList = this.setDateFiltersList();
        }
        if (this.route.snapshot.paramMap.get('id')) {
            if (this.route.snapshot.url[0].path === 'drivers-report') {
                this.driverId = this.route.snapshot.paramMap.get('id');
            } else if (this.route.snapshot.url[0].path === 'hubs-reports') {
                this.hubId = this.route.snapshot.paramMap.get('id');
            } else if (this.route.snapshot.url[0].path === 'partners-reports') {
                this.partnerId = this.route.snapshot.paramMap.get('id');
            }
        }
        if (this.TYPE === 'financial_report') {
            const currentDate = new Date();
            this.fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
        }
        if (this.showDateTypeFilter) {
            this.setDateTypeFilter();
        }
        this.showExportSwappedDelivered = this.TYPE === 'DELIVERED_PACKAGES' &&
            (this.userService.userInfo.companyId === 153 || this.userService.userInfo.companyId === 1);
        this.setCustomerDropDownSettings();
        this.getDrivers();
        if (this.setDefaultDate === 'MONTH') {
            const now = new Date();
            this.toDate = now;
            if (now.getMonth() === 1) {
                this.fromDate = new Date(now.getFullYear() - 1, 12, now.getDate());
            } else {
                this.fromDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
            }
        }
    }

    getDrivers(search?: string, select?) {
        // this.spinnerState = SpinnerState.LOADING;
        const query = (search) ? '?page=1&pageSize=' + this.pageSize + '&search=' + search + '&driverType=TYPICAL' : '?page=1&pageSize=' + this.pageSize + '&driverType=TYPICAL';
        this.driversList.getDrivers(query).subscribe(
            (response: any) => {
                if (response && response.length) {
                    this.drivers = response.map(user => {
                        return {
                            label: user.firstName + (user.middleName ? (' ' + user.middleName + ' ') : ' ') + user.lastName,
                            value: user.id,
                        };
                    });
                    setTimeout(() => {
                        if (select && select.pMultiSelect && select.pMultiSelect.cd) {
                            select.pMultiSelect.activateFilter();
                            select.pMultiSelect.cd.detectChanges();
                        }
                    }, 300);
                    if (this.drivers && this.drivers.length) {
                        // this.spinnerState = SpinnerState.LOADED;
                    } else {
                        // this.spinnerState = SpinnerState.EMPTY;
                    }
                }
            }, (error) => {
                // this.spinnerState = SpinnerState.FAILED;
            }
        );
    }

    public setShipmentStatus() {
        return this.changePackageStatusService.getPackageStatusList(this.userRole);
    }

    initShipments(pageSize = this.pageSize) {

        if (!this.apiUrl || this.pageSize === 0) {
            this.spinnerState = SpinnerState.LOADED;
            return;
        }

        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery(false, pageSize);
        if (this.postApi) {
            this.httpClient.post(this.apiUrl, {}, {params: <any>URLQuery}).subscribe(
                (response: { data?: any[], pkgs?: any[], totalRecordsNo?: number }) => {
                    if (this.dataInterceptor) {
                        this.records = this.dataInterceptor(response);
                    } else {
                        this.records = response.data !== undefined ? response.data : response.pkgs;
                        this.data = response;
                    }
                    this.totalRecords = response.totalRecordsNo;
                    if (this.totalRecords > 0) {
                        this.spinnerState = SpinnerState.LOADED;
                    } else {
                        this.spinnerState = SpinnerState.EMPTY;
                    }

                }, (error) => {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            );
        } else {
            this.httpClient.get(this.apiUrl, {params: <any>URLQuery}).subscribe(
                (response: any) => {
                  if (this.TYPE === 'active-customer-popup' || this.TYPE === 'drivers_popup' || this.TYPE === 'hubs_popup' || this.TYPE === 'partners_popup' || this.TYPE === 'driver-attempts-report') {
                        if (this.TYPE === 'active-customer-popup' || this.TYPE === 'partners_popup') {
                            this.records = response.data;
                            if (this.popup_field === 'pickedCod' || this.popup_field === 'notReceivedCod') {
                                this.totalRecords = response.totalRecordsNo;
                            }
                        } else {
                            this.records = response;
                        }
                        this.spinnerState = SpinnerState.EMPTY;
                    } else {
                        if (this.dataInterceptor) {
                            this.records = this.dataInterceptor(response);
                        } else {
                            if (this.TYPE === 'financial_report') {
                                this.records = FINANCIAL_REPORT_LIST.map((value) => {
                                    let label = FINANCIAL_REPORT_MAP[value].label;
                                    this.translateService.get('FINANCIAL_REPORT.' + FINANCIAL_REPORT_MAP[value].label)
                                        .subscribe((data) => {
                                            label = data;
                                        });
                                    return {type: label, value: response[value]};
                                });
                                console.log(this.records);
                            } else {
                                this.records = response.data ? response.data : response.pkgs;
                                this.data = response;
                                this.totalValues = this.getTotalValues(response);
                            }
                        }
                        this.totalRecords = response.totalRecordsNo;
                        if (this.totalRecords > 0) {
                            this.spinnerState = SpinnerState.LOADED;
                        } else {
                            this.spinnerState = SpinnerState.EMPTY;
                        }
                        if (this.statusFilter && this.selectedShipmentStatus === 'RETURNED_BY_RECIPIENT'
                            && response.moneyInfo !== undefined) {
                            this.statics = [{val: 'totalCost', title: 'ADMINISTRATION.COST_SUM'}
                                , {val: 'totalCod', title: 'ADMINISTRATION.COD_SUM'}];
                            this.data['totalCost'] = response.moneyInfo.totalCost;
                            this.data['totalCod'] = response.moneyInfo.totalCod;
                        }

                    }
                }, (error) => {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            );
        }
    }

    public createQuery(isPdf?, rows = this.pageSize) {
        const query: QueryInterface = <QueryInterface>{};
        if (!isPdf) {
            query.pageSize = rows;
            query.page = this.pageNumber + 1;
        } else {
            query.utcOffset = moment().utcOffset().toString();
        }
        if (this.queryParams) {
            // tslint:disable-next-line:forin
            for (const q in this.queryParams) {
                query[q] = this.queryParams[q];
            }
        }

        if (this.searchContent !== '') {
            query.search = this.searchContent;
        }
        if (this.selectedDateTypeFilter) {
            query.dateFilterType = this.selectedDateTypeFilter;
        }
        if (this.fromDate) {
            if (this.isIdleCustomerReport) {
                query['start-idle-date'] = this.transformDate(this.fromDate);
            } else {
                query.fromDate = this.transformDate(this.fromDate);
            }
        }
        if (this.toDate) {
            query.toDate = this.transformDate(this.toDate);
        }
        query['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.customerId) {
            query.customerId = this.customerId;
        }
        if (this.driverId) {
            if (this.TYPE !== 'driver-attempts-report') {
                query.driverId = this.driverId;
            } else {
                query['userIds'] = this.driverId;
            }
        }
        if (this.hubId) {
            query.hubId = this.hubId;
        }
        if (this.partnerId) {
            query.partnerId = this.partnerId;
        }
        if (this.externalCustomerId) {
            query.customerId = this.externalCustomerId;
        }
        if (this.statusFilter && this.selectedShipmentStatus !== 'ALL') {
            if (this.TYPE !== 'driver-attempts-report') {
                query['status'] = this.selectedShipmentStatus;
            } else {
                query['pkgStatusList'] = this.selectedShipmentStatus;
            }
        }
        if (this.dateFilterType) {
            query['dateFilterType'] = this.dateFilterType;
        }
        if (this.isDeliveredToSender && this.isDeliveredToSender !== '') {
            query['isDeliveredToSender'] = this.isDeliveredToSender;
        }
        if (this.isCompleted && this.isCompleted !== '') {
            query['isCompleted'] = this.isCompleted;
        }
        if (this.isNotesAdded) {
            query['isNotesAdded'] = this.isNotesAdded;
        }
        if (this.selectedDateFilter === 'ADMINISTRATION.PACKAGE_PICKUP_DATE') {
            query['dateFilter'] = 'FIRST_PICKUP_DATE';
        }
        if (this.selectedDateFilter === 'ADMINISTRATION.PACKAGE_RETURN_DATE') {
            query['dateFilter'] = 'RETURN_DATE';
        }
        if (this.isCount) {
            query['isCount'] = true;
        }
        if (this.inHub) {
            query['in-hub'] = true;
        }
        if (this.barcodeSearch) {
            query['search'] = this.barcodeSearch;
        }
        return query;
    }

    public onSelectGetIncomeCustomer(selectAll = false) {
        if (selectAll) {
            setTimeout(() => {
                this.onDriverSelected(this.selectedDriver);
            }, 1000);
        }
        this.onDriverSelected(this.selectedDriver);
    }

    onCustomerSelected(customerId) {
        this.customerId = customerId;
        this.onSearch();
    }

    public onDriverSelected(driverId) {
        this.driverId = driverId;
        this.onSearch();
    }

    public onHubSelected(hubId) {
        this.hubId = hubId;
        this.onSearch();
        // if (this.TYPE === 'hubs_Reports') {
        //     this.router.navigate([this.athuenticationService.companyName + '/home/hubs-reports' +
        //     (hubId === undefined ? '' : '/' + hubId)]);
        // }
    }

    public onPartnerSelected(partnerId) {
        this.partnerId = partnerId;
        this.onSearch();
        // if (this.TYPE === 'partners_Reports') {
        //     this.router.navigate([this.athuenticationService.companyName + '/home/partners-reports' +
        //     (partnerId === undefined ? '' : '/' + partnerId)]);
        // }
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public selectRangeDate(event: { start: any, end: any }){
        this.fromDate = event.start;
        this.toDate = event.end;
        this.onSearch();
    }

    public onSearch() {
        this.pageNumber = 0;
        if (this.table && this.table.reset) {
            this.table.reset();
        }
    }

    public debounceOnSearch() {
        if (this.debouncedSearch) {
            this.debouncedSearch.cancel();
        }
        this.debouncedSearch = _.debounce(this.onSearch, SHARED_CONSTANTS.ADMIN_DEBOUNCED_SEARCH_PERIOD);
        this.debouncedSearch();
    }

    public downloadPdf() {
        if ((this.externalCustomerId === '' || this.externalCustomerId.toString() === '0') && this.disablePrintAll) {
            this.translateService.get(
                'ALERTS.PLEASE_SELECT_CUSTOMER'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'error', detail: data});
                    }
                );
            return;
        }
        this.spinnerState = SpinnerState.LOADING;
        this.getPdfURL().subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.spinnerState = SpinnerState.LOADED;
                this.translateService.get(
                    'ALERTS.PRINT_PDF_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                        }
                    );
            },
            error => {
                console.log(error);
                this.spinnerState = SpinnerState.LOADED;
                // this.messageService.add({severity: 'error', detail: error});
            });
    }

    public exportToExcel() {
        this.spinnerState = SpinnerState.LOADING;
        this.getExcelURL().subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.spinnerState = SpinnerState.LOADED;
                this.translateService.get(
                    'ALERTS.EXPORTED_TO_EXCEL_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                        }
                    );
            },
            error => {
                console.log(error);
                this.spinnerState = SpinnerState.LOADED;
            });
    }

    public exportSwappedOrDeliveredToExcel() {
        this.spinnerState = SpinnerState.LOADING;
        this.getExcelURL('isByHistory=true').subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.spinnerState = SpinnerState.LOADED;
                this.translateService.get(
                    'ALERTS.EXPORTED_TO_EXCEL_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                        }
                    );
            },
            error => {
                console.log(error);
                this.spinnerState = SpinnerState.LOADED;
            });
    }

    public getRawData() {
        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery(true);
        this.httpClient.get(this.rowDataUrl, {
            params: <any>URLQuery
        }).subscribe((res: any) => {
            this.spinnerState = SpinnerState.LOADED;
            window.open(res.url, '_blank');
        }, error => {
            this.spinnerState = SpinnerState.LOADED;
        });
    }

    public postRawData() {
        this.spinnerState = SpinnerState.LOADING;
        const URLQuery = this.createQuery(true);
        this.httpClient.post(this.rowDataUrl, {}, {params: <any>URLQuery}).subscribe((res: any) => {
            this.spinnerState = SpinnerState.LOADED;
            window.open(res.url, '_blank');
        }, error => {
            this.spinnerState = SpinnerState.LOADED;
        });
    }

    public getPdfURL() {
        const URLQuery = this.createQuery(true);
        let urlParts = [this.apiUrl, ''];
        if (this.apiUrl && this.apiUrl.indexOf('?') >= 0) {
            urlParts = this.apiUrl.split('?');
        }
        const url = urlParts[0] + '/pdf?' + urlParts[1];
        if (this.postApi) {
            return this.httpClient.post(url, {}, {
                params: <any>URLQuery
            });
        } else {
            return this.httpClient.get(url, {
                params: <any>URLQuery
            });
        }
    }

    public getExcelURL(appendParam = '') {
        const URLQuery = this.createQuery(true);
        let urlParts = [this.apiUrl, ''];
        if (this.apiUrl && this.apiUrl.indexOf('?') >= 0) {
            urlParts = this.apiUrl.split('?');
        }
        let url = urlParts[0] + '/excel?' + urlParts[1];
        if (appendParam) {
            url += '&' + appendParam;
        }
        if (this.postApi) {
            return this.httpClient.post(url, {}, {
                params: <any>URLQuery
            });
        } else {
            return this.httpClient.get(url, {
                params: <any>URLQuery
            });
        }
    }

    public getDeepData(row, keys) {
        let data = row[keys[0]];
        for (const key in keys) {
            if (key !== '0') {
                data = data[keys[key]];
            }
        }
        return data;
    }

    public getAPIStatisticsResults() {
        this.apiStatisticsResults = [];
        this.APIStatistics.map((statistic) => {
            const URLQuery = this.createQuery(false, this.pageSize);
            let url = statistic.api;
            if (statistic.replaceValue) {
                if (this.queryParams[statistic.replaceValue]) {
                    url = url.replace('{' + statistic.replaceValue + '}', this.queryParams[statistic.replaceValue]);
                } else {
                    if (typeof this[statistic.replaceValue] === 'object' && this[statistic.replaceValue].length === 0) {
                        return;
                    }
                    if (this[statistic.replaceValue]) {
                        url = url.replace('{' + statistic.replaceValue + '}', this[statistic.replaceValue]);
                    } else {
                        return;
                    }
                }
            }
            this.httpClient.get(url, {params: <any>URLQuery}).subscribe(
                (response) => {
                    this.apiStatisticsResults[statistic.val] = response;
                    if (this.TYPE === 'driver-attempts-report') {
                        this.totalRecords = response as number;
                    }
                }, (error) => {
                }
            );
        });
    }

    public getLocalTime(dateTime) {
        if (dateTime) {
            return new Date(dateTime).toLocaleTimeString();
        }
        return;
    }

    private getDateBeforeSpecificDays(daysNumber) {
        return new Date(Date.now() - (daysNumber * 24 * 60 * 60 * 1000));
    }

    onStatusChanged() {
        this.initShipments();
        if (this.TYPE === 'driver-attempts-report') {
            this.getAPIStatisticsResults();
        }
    }

    public closeModal() {
        this.activeModal.close();
    }

    onReportClick(row, col) {
        if (this.TYPE === 'drivers_Reports' && col.status !== '' && col.status !== 'MASS_REPORT' && col.status !== 'DRIVER_NAME') {
            const modal = this.modalService.open(AdminReportComponent, <any>{
                backdrop: 'static',
                size: 'lg',
                windowClass: <any>'driverPopUp'
            });
            if (col.status === 'ASSIGNED_TO_DRIVER' || col.status === 'ACCEPTED_BY_DRIVER' || col.status === 'IN_CAR') {
                modal.componentInstance.columns = REPORTS_CONSTANTS.PICKUP_REPORTS_ON_MOUSE_CLICK_PICKUP.PICKUP_REPORTS_INFO;
                modal.componentInstance.apiUrl = REPORTS_CONSTANTS.PICKUP_REPORTS_ON_MOUSE_CLICK_PICKUP.PICKUP_REPORTS_PICKUP_API
                    + '?status=' + col.status;
                modal.componentInstance.postApi = true;
                modal.componentInstance.isExportToExcel = true;
            } else {
                if (col.field === 'notReceivedCod' || col.field === 'deliverePackagesNo') {
                    modal.componentInstance.columns = REPORTS_CONSTANTS.DRIVERS_REPORTS_ON_MOUSE_CLICK.DRIVERS_REPORTS_INFO_WITHOUT_STATUS;
                } else {
                    modal.componentInstance.columns =  REPORTS_CONSTANTS.DRIVERS_REPORTS_ON_MOUSE_CLICK.DRIVERS_REPORTS_INFO;
                }
                modal.componentInstance.apiUrl = REPORTS_CONSTANTS.DRIVERS_REPORTS_ON_MOUSE_CLICK.DRIVERS_REPORTS_API
                    .replace('{driverId}', row.driverId) + '?status=' + col.status;
                if (col.status === 'PICKED') {
                    modal.componentInstance.isNotDelivered = true;
                }
            }
            if (['PENDING_PICKUP', 'PICKED', 'RETURNED', 'POSTPONED', 'FAILED', 'DELIVERED', 'NOT_UPDATED'].indexOf(col.status) > -1) {
                modal.componentInstance.isExportToExcel = true;
            }
            modal.componentInstance.isPdfRemove = true;
            modal.componentInstance.filterDate = false;
            modal.componentInstance.hasFilters = false;
            modal.componentInstance.filterDrivers = false;
            modal.componentInstance.TYPE = 'drivers_popup';
            modal.componentInstance.reportTitle = col.header;
            modal.componentInstance.totalRecords = row[col.field];
            modal.componentInstance.driverId = row.driverId;
            modal.componentInstance.customerId = this.customerId;
            modal.result.then(
                (data: { isSuccess: boolean }) => {
                    this.onSearch();
                }
            ).catch(
                (error) => {
                    console.error(error);
                }
            );
        } else if (this.TYPE === 'hubs_Reports' && col.status !== '') {
            const modal = this.modalService.open(AdminReportComponent, <any>{
                backdrop: 'static',
                size: 'lg',
                windowClass: <any>'driverPopUp'
            });
            if (col.status === 'IN_HUB_PICKUP') {
                modal.componentInstance.columns = REPORTS_CONSTANTS.PICKUP_REPORTS_ON_MOUSE_CLICK_PICKUP.PICKUP_REPORTS_INFO;
                modal.componentInstance.apiUrl = REPORTS_CONSTANTS.PICKUP_REPORTS_ON_MOUSE_CLICK_PICKUP.PICKUP_REPORTS_PICKUP_API
                    + '?status=IN_HUB';
                modal.componentInstance.postApi = true;
            } else {
                modal.componentInstance.columns = REPORTS_CONSTANTS.HUBS_REPORTS_ON_MOUSE_CLICK.HUBS_REPORTS_INFO;
                modal.componentInstance.apiUrl = REPORTS_CONSTANTS.HUBS_REPORTS_ON_MOUSE_CLICK.HUBS_REPORTS_API
                    .replace('{hubId}', row.hubId) + '?status=' + col.status;
            }
            modal.componentInstance.isPdfRemove = true;
            modal.componentInstance.isExportToExcel = true;
            modal.componentInstance.filterDate = false;
            modal.componentInstance.hasFilters = false;
            modal.componentInstance.filterDrivers = false;
            modal.componentInstance.TYPE = 'hubs_popup';
            modal.componentInstance.reportTitle = col.header;
            modal.componentInstance.totalRecords = row[col.field];
            modal.componentInstance.hubId = row.hubId;
            modal.result.then(
                (data: { isSuccess: boolean }) => {
                    this.onSearch();
                }
            ).catch(
                (error) => {
                    console.error(error);
                }
            );
        }
        else if (this.TYPE === 'partners_Reports' && col.status !== '') {
            const modal = this.modalService.open(AdminReportComponent, <any>{
                backdrop: 'static',
                size: 'lg',
                windowClass: <any>'driverPopUp'
            });
            modal.componentInstance.columns = REPORTS_CONSTANTS.PARTNERS_REPORTS_ON_MOUSE_CLICK.PARTNERS_REPORTS_INFO;
            modal.componentInstance.apiUrl = REPORTS_CONSTANTS.PARTNERS_REPORTS_ON_MOUSE_CLICK.PARTNERS_REPORTS_API
                .replace('{partnerId}', row.partnerId) + '?status=' + col.status;

            modal.componentInstance.isPdfRemove = true;
            modal.componentInstance.isExportToExcel = true;
            modal.componentInstance.filterDate = false;
            modal.componentInstance.hasFilters = false;
            modal.componentInstance.filterDrivers = false;
            modal.componentInstance.TYPE = 'partners_popup';
            modal.componentInstance.reportTitle = col.header;
            modal.componentInstance.popup_field = col.field;
            modal.componentInstance.totalRecords = row[col.field];
            modal.componentInstance.partnerId = row.partnerId;
            modal.result.then(
                (data: { isSuccess: boolean }) => {
                    this.onSearch();
                }
            ).catch(
                (error) => {
                    console.error(error);
                }
            );
        }
        else if (this.reportTitle === 'ADMINISTRATION.ZONE_TO_PACKAGES_SUMMARY_REPORT_TITLE' && this.inHub) {
            const modal = this.modalService.open(AdminReportComponent, <any>{
                backdrop: 'static',
                size: 'lg',
                windowClass: <any>'driverPopUp'
            });
                modal.componentInstance.columns = REPORTS_CONSTANTS.DRIVERS_REPORTS_ON_MOUSE_CLICK.DRIVERS_REPORTS_INFO;
                modal.componentInstance.apiUrl = REPORTS_CONSTANTS.PACKAGES_BY_DESTINATION_CITY.REPORTS_API
                    .replace('{cityId}', row.cityId) + '?in-hub=true';

            modal.componentInstance.isPdfRemove = false;
            modal.componentInstance.filterDate = false;
            modal.componentInstance.hasFilters = false;
            modal.componentInstance.filterDrivers = false;
            modal.componentInstance.TYPE = 'packages_by_destination_city';
            modal.componentInstance.reportTitle = col.header;
            modal.componentInstance.totalRecords = row[col.field];
            modal.componentInstance.driverId = row.driverId;
            modal.componentInstance.customerId = this.customerId;
        } else if (this.TYPE === 'activate-customers-report' && col.status !== '') {
            const modal = this.modalService.open(AdminReportComponent, <any>{
                backdrop: 'static',
                size: 'lg',
                windowClass: <any>'driverPopUp'
            });
            modal.componentInstance.columns = REPORTS_CONSTANTS.ACTIVE_CUSTOMERS_ON_MOUSE_CLICK.INFO;
            modal.componentInstance.apiUrl = REPORTS_CONSTANTS.ACTIVE_CUSTOMERS_ON_MOUSE_CLICK.API
                .replace('{customerId}', row.customerId) + '?status=' + col.status;

            modal.componentInstance.isPdfRemove = this.isPdfRemove;
            modal.componentInstance.isExportToExcel = true;
            modal.componentInstance.filterDate = false;
            modal.componentInstance.hasFilters = false;
            modal.componentInstance.filterDrivers = false;
            modal.componentInstance.TYPE = 'active-customer-popup';
            modal.componentInstance.reportTitle = col.header;
            modal.componentInstance.totalRecords = row[col.field];
            modal.componentInstance.customerId = row.customerId;
            modal.componentInstance.fromDate = this.fromDate;
            modal.componentInstance.toDate = this.toDate;
            modal.componentInstance.hubId = this.hubId;
            modal.componentInstance.driverId = this.driverId;
            modal.result.then(
                (data: { isSuccess: boolean }) => {
                    this.onSearch();
                }
            ).catch(
                (error) => {
                    console.error(error);
                }
            );
        }
        if (col.click) {
            col.click(row);
        }
    }

    deliverPackage($event) {
        this.spinnerState = SpinnerState.LOADING;
        const body = {};
        body['deliveryDate'] = this.transformDate(new Date());
        body['driverId'] = this.driverId;
        body['status'] = 'DELIVERED_TO_RECIPIENT';
        body['ids'] = [$event.id];
        this.shipmentsService.changePackageStatus(body).subscribe(res => {
            this.translateService.get(
                'ALERTS.MASS_PACKAGE_DELIVERED_SUCCESSFULLY'
            )
                .subscribe(
                    (data) => {
                        this.messageService.add({severity: 'success', detail: data});
                    }
                );
            this.spinnerState = SpinnerState.LOADED;
            this.onSearch();
        }, error => {
            this.spinnerState = SpinnerState.LOADED;
        });
    }

    public onPostponePackage(isJustNotes = false, $event) {
        const pkg = $event;
        const modal = this.modalService.open(PostponePackageComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.shipment = pkg;
        if (isJustNotes) {
            modal.componentInstance.notesOnly = true;
            modal.componentInstance.title = 'SHIPMENT.NOTES_PRIVATE_PACKAGE';
            modal.componentInstance.isAdminNotes = true;
        }
        modal.result.then(
            (data: { isSuccess: boolean }) => {
                if (data && data.isSuccess) {
                    this.translateService.get(
                        'ALERTS.ADD_PACKAGE_PRIVATE_NOTES_SUCCESSFULLY'
                    )
                        .subscribe(
                            (message) => {
                                this.messageService.add({severity: 'success', detail: message});
                            }
                        );
                    this.initShipments();
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    printReport(row) {
        console.log(row);
    }

    isCursorPointer() {
        return this.TYPE === 'drivers_Reports' || this.TYPE === 'hubs_Reports' || this.TYPE === 'partners_Reports' || this.TYPE === 'activate-customers-report' ||
            this.reportTitle === 'ADMINISTRATION.ZONE_TO_PACKAGES_SUMMARY_REPORT_TITLE' && this.inHub;
    }

    public setDateFiltersList() {
        return DELAYED_DATE_FILTERS_LIST.map((value) => {
            let label = value;
            this.translateService.get(value)
                .subscribe((data) => {
                    label = data;
                });
            label = this.transformToDistributor(label);
            return {label, value};
        });
    }

    colWidthStyle(field) {
        if (field === 'cod' || field === 'cost') {
            return '60px';
        }
        if (field === 'adminNotes') {
            return '120%';
        }
        if (field === 'invoiceNumber') {
            return '120px';
        }
        if (field === 'driverName') {
            return '110px';
        }
        return '100%';
    }

    fontType(row) {
        if (row && (row.type === 'Profit'
            || row.type === 'الربح (مجموع تكاليف توصيل الطرود المنقولة - مجموع المصاريف - مجموع أجور النقل)')) {
            return 'bold';
        }
        return 'normal';
    }

    public transformToDistributor(result) {
        if (this.userService.userInfo.isDistributor && result) {
            result = result.replace(/(Shipment|Package)/, 'Order');
            result = result.replace(/(shipment|package)/, 'order');
            result = result.replace('طرد', 'طلبية');
            result = result.replace('الطرد', 'الطلبية');
            result = result.replace('طرود', 'طلبيات');
        }
        return result;
    }

    getTotalValues(response: any) {
        if (this.calculateTotalLocally && response && response.data) {
            const newTotal = {};
            this.columns.forEach(c => {
                newTotal[c.field] = 0;
            });
            response.data.forEach(r => {
                this.columns.map(c => {
                    newTotal[c.field] += r[c.field];
                });
            });
            return newTotal;
        }
        return response.total;
    }

    getConcat(row: any, field: any) {
        return _.get(row, field);
    }

    setDateTypeFilter() {
        this.dateTypeFilterList = this.dateTypeFilterList.map(filter =>  {
            return {label: this.translateService.instant(`SHIPMENT.${filter}`), value: filter};
        });
        this.selectedDateTypeFilter = 'CREATED_DATE';
    }

    openAttachment(event , pkgId) {
        this.isAttachmentOpened = true;
        event.stopPropagation();
        this.shipmentsService.getPackageProofList(pkgId).subscribe((res: any) => {
            const modal = this.modalService.open(ImageSliderComponent, <any>{
                backdrop: 'static',
                windowClass: 'assign-package-to-modal',
                size: 'md'
            });
            modal.componentInstance.isModal = true;
            if (res == null || res.urls === undefined) {
                res.urls = [];
            }
            modal.componentInstance.images = res.urls;

            modal.result.then(() => {
                this.isAttachmentOpened = false;
            });
        });
    }

    onToggleInHubSelection($event: any) {
        this.onSearch();
    }
}
