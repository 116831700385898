import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
                private authenticationService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (localStorage.getItem('token')) {
            // logged in so return true
            return true;
        }
        const companyName = this.authenticationService.companyName;
        // not logged in so redirect to login page
        if (companyName) {
            this.router.navigate([`${companyName}login`]);
        } else {
            this.router.navigate(['/login']);
        }
        return false;
    }
}
