import {Component, OnInit} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from '../../accounting-constants';
import {AccountingRowActionsSettings} from '../../models/row-actions-setting.model';
import {TemplateTopActionsSettingsModel} from '../../models/template-top-actions-settings.model';
import {AccountingTemplateFiltersModel} from '../../models/accounting-template-filters.model';
import {ActivatedRoute, Params, Route} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-mass-details-invoices',
    templateUrl: './mass-details-invoices.component.html',
    styleUrls: ['./mass-details-invoices.component.scss']
})
export class MassDetailsInvoicesComponent implements OnInit {
    public id;
    title = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.TITLE;
    info = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.INFO;
    apiUrl;
    rowActionsSettings: AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.ROW_ACTIONS_SETTINGS;
    topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.TOP_ACTIONS_SETTINGS;
    filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.FILTERS_SETTINGS;
    dateFiltersList = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.DATE_FILTERS_LIST;

    constructor(private route: ActivatedRoute, private userService: UserService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.apiUrl = ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.API.replace('{id}', this.id);
    }

    ngOnInit(): void {
        const info = _.cloneDeep(ACCOUNTING_CONSTANTS.MASS_INVOICES_DETAILS.INFO);
        if (!this.userService.userInfo.isSupportAdditionalFees) {
            info.splice(info.findIndex((value: any) => value.isInsurance), 1);
            if (this.userService.getCurrency() !== 'SAR') {
                info.splice(info.findIndex((value: any) => value.isAdditionalFees), 1);
            }
        }
        this.info = info;
    }
}
