import { Pipe, PipeTransform } from '@angular/core';
import {UserService} from '../services/user.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'addressSystem'
})
export class AddressSystemPipe implements PipeTransform {
public currentLang;
  constructor(private userService: UserService,
              private translateService: TranslateService) {
    this.currentLang = this.translateService.currentLang;
  }

  transform(value: string, args?: any): any {
    let result = '';
    // Add currencies in the switch case
    if (this.currentLang === 'en') {
      switch (this.userService.getCurrency()) {
        case 'BHD':
          result = value.replace('Regions', 'Municipalities');
          result = result.replace('regions', 'municipalities');
          result = result.replace(/Region[^s]/, 'Municipality');
          result = result.replace(/region[^s]/, 'municipality');
          result = result.replace('Village', 'Block');
          result = result.replace('village', 'block');
          result = result.replace('Customer', 'Vendor');
          result = result.replace('customer', 'vendor');
          result = result.replace('Receiver Address', 'Block Number');
          result = result.replace('Address Details', 'Road');
          break;
        case 'OMR':
          result = value.replace('Regions', 'Governorates');
          result = value.replace('Region', 'Governorate');
          result = result.replace('Cities', 'states');
          result = result.replace('City', 'state');
          break;
        case 'NIS':
        case 'JOD':
        default:
          return value;
      }
    } else {
      switch (this.userService.getCurrency()) {
        case 'BHD':
          result = value.replace('المناطق', 'البلديات');
          result = result.replace('القرى', 'المجمعات');
          result = result.replace('وصف العنوان', 'الشارع');
          break;
        case 'SAR':
          result = value.replace('قرية', 'حي');
          result = result.replace('قرى', 'أحياء');
          break;
        case 'OMR':
          result = value.replace('المناطق', 'المحافظات');
          result = result.replace('المنطقة', 'المحافظة');
          result = result.replace('المدن', 'الولايات');
          result = result.replace('المدينة', 'الولاية');
          break;
        case 'NIS':
        case 'JOD':
        default:
          return value;
      }
    }

    return result;
  }

}
