import {
    ComponentFactoryResolver,
    ElementRef,
    Directive,
    Input,
    Output,
    AfterViewInit,
    EventEmitter,
} from '@angular/core';

@Directive({
    selector: '[appPTableRowsCalculator]'
})
export class PTableRowsCalculatorDirective implements AfterViewInit {
    @Input() rowHeight = 27.2;
    @Input() pageSize;
    @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
    public paginatorHeight = 33;
    public headerHeight = 27.3;

    constructor(private hostElement: ElementRef) {
    }

    ngAfterViewInit() {
        const pageSize = (this.hostElement.nativeElement.clientHeight - this.paginatorHeight - this.headerHeight) / this.rowHeight;
        this.pageSize = pageSize < 10 ? 10 : Math.floor(pageSize);
        this.pageSizeChange.emit(this.pageSize);
    }
}
