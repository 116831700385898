import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'decimalDigits'
})
export class DecimalDigitsPipe implements PipeTransform {

    transform(value: string | number, maxDigits: number, fixedDigits = false): any {
        if (!value) {
            return value;
        }
        if (typeof value === 'number') {
            if (!maxDigits) {
                maxDigits = 2;
            }
            value = value.toFixed(maxDigits);
            value = value.toString();
        }
        if (!fixedDigits) {
            value = value.replace(/\.?0+$/, '');
        }
        return value;
    }

}
