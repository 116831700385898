import {Component, OnDestroy} from '@angular/core';

@Component({
    template: ''
})
export class SubscriptionTrackerComponent implements OnDestroy {
    /**
     * The list of subscriptions tracked.
     */
    subscriptions: any[] = [];

    /**
     * Abstract method, used to unsubscribe the subscription.
     */
    destroySubscriptions() {
        this.subscriptions.forEach(
            (subscription) => {
                if (subscription && subscription.unsubscribe) {
                    subscription.unsubscribe();
                }
            });
    }

    addSubscription(subsctiption) {
        this.subscriptions.push(subsctiption);
    }

    ngOnDestroy() {
        this.destroySubscriptions();
    }
}
