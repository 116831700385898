import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ShipmentsService} from '../shipment/services/shipments.service';
import {UserService} from '../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent implements OnInit, AfterViewInit {
    private userInfo;
  constructor(private shipmentsService: ShipmentsService,
              private userService: UserService,
              private route: ActivatedRoute,
              private translate: TranslateService) {
      this.userInfo = this.userService.userInfo;
  }
  public selectedTab = {label: 'ALL', status: 'ALL'};
  public translationLoaded = false;
  public tabsList = [
      {label: 'ALL', status: 'ALL'},
      {label: 'PENDING_CUSTOMER_CARE_APPROVAL', status: 'PENDING_CUSTOMER_CARE_APPROVAL'},
      {label: 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER', status: 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER'},
      {label: 'CANCELLED', status: 'CANCELLED'},
      {label: 'DELIVERED_TO_RECIPIENT', status: 'DELIVERED_TO_RECIPIENT'},
      {label: 'RETURNED_BY_RECIPIENT', status: 'RETURNED_BY_RECIPIENT'},
      {label: 'POSTPONED_DELIVERY', status: 'POSTPONED_DELIVERY'},
      {label: 'RESOLVED_FAILURE', status: 'RESOLVED_FAILURE'},
      {label: 'UNRESOLVED_FAILURE', status: 'UNRESOLVED_FAILURE'},
      {label: 'PACKAGES_BASED_ON_RECIPIENT_CITY', status: 'PACKAGES_BASED_ON_RECIPIENT_CITY'},
      {label: 'DELAYED', status: 'DELAYED'},
      {label: 'DELETED', status: 'DELETED'}
  ];

    ngOnInit() {
        if (this.userInfo.isBundleSupported) {
            this.tabsList.push({label: 'BUNDLES', status: 'BUNDLES'});
        }
        this.tabsList.push({label: 'SCANNED_BY_DRIVER_AND_IN_CAR', status: 'SCANNED_BY_DRIVER_AND_IN_CAR'});
        this.userService.getFollowUpPackageCount();
    }

    public onTabChange(str) {
        this.selectedTab = str;
    }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe(
            (params: Params) => {
                if (params.status) {
                    this.onTabChange(this.tabsList.filter(tab => tab.status === params.status)[0]);
                }
            }
        );
    }
}
