<div class="detailed-reports-container">
  <div class="detailed-report">
    <app-admin-report [columns]="delayedReports"
                      [isPdfRemove]="false"
                      [apiUrl]="delayedReportAPI"
                      [postApi] = true
                      [filterDate] = true
                      [hasFilters] = true
                      [delayedAndCreatedDate] = true
                      [isCount] = true
                      [statics] = "delayedReportStatics"
                      [rowDataUrl]="IdleCustomerRawDataUrl"
                      [isIdleCustomerReport]="false"
                      [isPostRowData] = true
                      reportTitle="ADMINISTRATION.DELAYED_PACKAGES_REPORT_TITLE"></app-admin-report>
  </div>
</div>


<app-shipment-tracker [isShow]="isTrackingPackage" (onCloseTracker)="closeTracker($event)"
                      [packageBarcode]="triggeredShipment?.barcode"
                      class="component">
</app-shipment-tracker>
