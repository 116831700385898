import { Component, OnInit } from '@angular/core';
import {REPORTS_CONSTANTS} from '../../administration/reports-constants';

@Component({
  selector: 'app-drivers-report',
  templateUrl: './drivers-report.component.html',
  styleUrls: ['./drivers-report.component.scss']
})
export class DriversReportComponent implements OnInit {

  public driversReports = REPORTS_CONSTANTS.DRIVERS_REPORTS.DRIVERS_REPORTS_INFO;
  public driversReportsAPI = REPORTS_CONSTANTS.DRIVERS_REPORTS.DRIVERS_REPORTS_API;
  constructor() { }
  ngOnInit() {
  }
}
