import {Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {AutoComplete} from 'primeng/autocomplete';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutoCompleteComponent),
    multi: true
  }]
})
export class AutoCompleteComponent implements OnInit, ControlValueAccessor {
  @ViewChild('autoCompleteComponent') autoCompleteComponent: AutoComplete;

  // in Terms & Defs we need to update with the selected section
  @Output() SelectedValueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() completeMethod: EventEmitter<any> = new EventEmitter<any>();

  @Input() field = 'name';
  @Input() data: any[];
  @Input() url: string;
  @Input() multiple = false;
  @Input() isRequired: boolean;
  @Input() fieldDisabled: boolean;
  @Input() id: string;
  @Input() searchKey = 'search';
  @Input() label: string;
  @Input() extraQueryParams; // in some cases -> we need some extra params in addition of searchKey param
  @Input() allowFetchData = true; // for some cases -> we don't need to load the data
  @Input() placeholder = 'GENERAL.SEARCH_OR_SELECT';
  @Input() forceSelection = false; // autocomplete only accepting values from the suggestions.
  @Input() formControlObj: FormControl;
  @Input() defaultValue: any = 'ALL';
  @Input() selectedItemTemplate: TemplateRef<any>;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() showLabel = true;
  @Input() cssStyle = {};
  @Input() isViewMode = false;
  @Input() appendTo = null;
  @Input() delay: number;
  @Input() suggestions: { value: string, label: string }[];
  @Input() dropdown = true;
  @Input() inputStyleClass: string;
  @Input() dataKey: string;
  @Input() isForm = true;
  @Input() isFilter = false;
  @Input() customizeDefaultValue = false;
  _selectedItem: any;

  constructor(private httpService: HttpClient) {
  }

  ngOnInit() {
  }

  get selectedItem() {
    return this._selectedItem;
  }

  @Input() set selectedItem(val: any) {
    this._selectedItem = val;
    if (this.propagateChange) {
      this.propagateChange(val);
    } else {
      this.onSelect.emit(val);
    }
  }

  complete(searchValue: string) {
    this.completeMethod.emit({query: searchValue});
  }

  private propagateChange = (_: any) => {};

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.selectedItem = value;
  }

  select(event) {
    this.onSelect.emit(event);
  }

  clear() {
    this.selectedItem = [];
    this.onClear.emit();
  }
}
