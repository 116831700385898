import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContainersService} from '../services/containers.service';

@Component({
    selector: 'app-container-packages',
    templateUrl: './container-packages.component.html',
    styleUrls: ['./container-packages.component.scss']
})
export class ContainerPackagesComponent implements OnInit {

    public userId;
    public packagesAPI;

    constructor(private route: ActivatedRoute,
                private containersService: ContainersService) {
    }

    ngOnInit() {
        this.userId = this.route.snapshot.paramMap.get('id');
        this.packagesAPI = this.containersService.getContainerPackagesAPI(this.userId);
    }

    public downloadPdf() {
        this.containersService.printContainerPackages(this.userId).subscribe((res: any) => {
                window.open(res.url, '_blank');
            },
            error => {
                console.log(error);
            });
    }

}
