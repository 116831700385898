export const COUNTRY_BOUNDARIES = {
    'NIS': [
        [34.2081642150879, 33.349422523214415],
        [35.49618244171143, 29.611259700447583]
    ],
    'JOD': [
        [39.04632938700013, 32.30849416100003],
        [39.235775187000115, 32.35285837800009],
        [39.25634240700009, 32.34267812100012],
        [39.27112186700009, 32.311956482000085],
        [39.29199914500015, 32.24451873800007],
        [39.26647098800004, 32.212866923000064],
        [39.14616825300004, 32.12584381100001],
        [39.14637496000012, 32.1181440230001],
        [39.13624637800007, 32.11535349600007],
        [39.11681604000006, 32.10289947500007],
        [38.99806359900009, 32.006936341000085],
        [38.963440389000084, 31.994482320000117],
        [38.963440389000084, 31.994327291000076],
        [38.96333703600004, 31.994378967000074],
        [38.84975223800012, 31.966318664000056],
        [38.624856405000116, 31.910869853000094],
        [38.40016727700004, 31.855369365000115],
        [38.175374796000085, 31.79992055200006],
        [37.98652246000006, 31.753358384000137],
        [37.95047896300008, 31.744471741000098],
        [37.76141217300011, 31.69612049300011],
        [37.70274214700004, 31.681116435000064],
        [37.45500533100005, 31.617709452000042],
        [37.207268514000134, 31.55435414700011],
        [36.99791151900007, 31.50081395500007],
        [36.959531698000035, 31.490998840000117],
        [37.08965295400009, 31.37007598900007],
        [37.21977421100013, 31.249101461000095],
        [37.221722009000075, 31.24729212900013],
        [37.34989546700007, 31.128230286000047],
        [37.48001672400005, 31.00725575800007],
        [37.48311730900008, 31.004103495000038],
        [37.48632124900007, 31.000899557000054],
        [37.4894218340001, 30.99769561800008],
        [37.49262577300004, 30.994491679000106],
        [37.60228316200005, 30.883232321000094],
        [37.71204390500009, 30.77202463800006],
        [37.82190800000012, 30.660868632000074],
        [37.93156538900013, 30.549660950000145],
        [37.98107141100007, 30.499483134000116],
        [37.98138146900004, 30.498811340000117],
        [37.980968058000144, 30.498397929000106],
        [37.98003788200009, 30.498087871000052],
        [37.97331994700005, 30.494522196000077],
        [37.900146118000066, 30.459072164000133],
        [37.77932662000006, 30.400419414000083],
        [37.67070275900005, 30.347606100000064],
        [37.647551718000045, 30.330862936000102],
        [37.63452925600012, 30.312776184000086],
        [37.60517704200004, 30.25071278900012],
        [37.56921024600007, 30.174955139000133],
        [37.5361373290001, 30.105295309000013],
        [37.4916955970001, 30.01119252600006],
        [37.47019820100007, 29.994552714000122],
        [37.352375936000044, 29.973313701000052],
        [37.21853397600012, 29.949129130000074],
        [37.075803670000084, 29.923290915000052],
        [36.93193648300013, 29.89724599200008],
        [36.84294966600004, 29.881226299000048],
        [36.756236613000056, 29.86551666300005],
        [36.728744751000136, 29.8535277310001],
        [36.704870239000115, 29.831151836000117],
        [36.64957645700008, 29.749399720000014],
        [36.603584432000105, 29.681471049000066],
        [36.54126265500008, 29.589409485000147],
        [36.47708052600012, 29.49460907000008],
        [36.3999792890001, 29.438901876000045],
        [36.28370731600012, 29.354850159000108],
        [36.17797733600008, 29.27836903900004],
        [36.06945682800006, 29.200027568000053],
        [36.04382531800013, 29.190880840000148],
        [36.016436808000094, 29.189950664],
        [35.912463827000124, 29.20568613700007],
        [35.797225382000136, 29.22307525700009],
        [35.740250622000076, 29.231730714000037],
        [35.622042277000105, 29.24968861900004],
        [35.47362756400008, 29.272064515000125],
        [35.33461796000012, 29.29314849900004],
        [35.179071899000064, 29.31666127500003],
        [35.060319458000095, 29.33469635000003],
        [34.949385116681015, 29.35168582654734],
        [34.94939212300011, 29.351711330000114],
        [34.96241295700008, 29.359767971000053],
        [34.96900475400014, 29.450832424000055],
        [34.97608483200008, 29.477036851],
        [34.99781334700009, 29.517971096000025],
        [34.996836785000085, 29.53388092700004],
        [34.97608483200008, 29.552150783000116],
        [34.96241295700008, 29.552150783000116],
        [34.961599155000044, 29.55540599200006],
        [34.961599155000044, 29.558091539000145],
        [34.96021569100009, 29.55951569200002],
        [34.95558027684632, 29.558987092750044],
        [34.959860474000095, 29.586205547000077],
        [34.966991822000125, 29.608116354000117],
        [34.98032434100014, 29.62700409000007],
        [34.98983280400006, 29.65196380700013],
        [34.995103801000084, 29.708161927000106],
        [35.00254520700008, 29.73309580500009],
        [35.04895064200008, 29.842313945000058],
        [35.053188110000065, 29.862622782000074],
        [35.05411828600012, 29.923394267000077],
        [35.06145634000018, 29.957345683000113],
        [35.065383748000045, 29.96597564700005],
        [35.07034468600011, 29.973727112000063],
        [35.07406538900011, 29.982563782000057],
        [35.074685506000094, 29.994604391000053],
        [35.086261027000035, 30.03403350900004],
        [35.129049113000065, 30.089740702000086],
        [35.14527551200007, 30.12338206000004],
        [35.14527551200007, 30.154904684000087],
        [35.124811645000136, 30.216089579000112],
        [35.12522505700014, 30.244666647000088],
        [35.13235640500005, 30.261874898000144],
        [35.14176151500004, 30.313964743000014],
        [35.147755981000046, 30.326470439000104],
        [35.154473918000065, 30.336754049],
        [35.15995162000007, 30.347502747000107],
        [35.162122029000045, 30.361403707000107],
        [35.1593315020001, 30.375614726000066],
        [35.1449654540001, 30.395871887000055],
        [35.140004517000136, 30.40615549700007],
        [35.140004517000136, 30.430185038000076],
        [35.15736779700006, 30.470854391000046],
        [35.162122029000045, 30.494677226000107],
        [35.205323527000076, 30.617098694000077],
        [35.2638212490001, 30.719779765000084],
        [35.26388190400013, 30.719966986000117],
        [35.27157271300007, 30.743705953000074],
        [35.276120239000136, 30.768975728000044],
        [35.27953088400005, 30.7802411910001],
        [35.28614546700004, 30.792333476000096],
        [35.29389693200005, 30.800188293000105],
        [35.31084680200007, 30.81331410700008],
        [35.31663456200005, 30.822822571000074],
        [35.320045206000145, 30.844940084000086],
        [35.319528442000035, 30.867315979000068],
        [35.32221561700004, 30.889950256000105],
        [35.33492802000012, 30.912584534000047],
        [35.34711028400005, 30.922709635000075],
        [35.374098755000034, 30.945140686000087],
        [35.38526086400009, 30.963279114000045],
        [35.385157511000045, 30.99464670800006],
        [35.39156538900011, 31.023947246000034],
        [35.438487590000136, 31.1037356570001],
        [35.44324182100013, 31.132209371000044],
        [35.43621382700013, 31.159546204000065],
        [35.42133101400009, 31.18450592100004],
        [35.41068566900009, 31.2046080530001],
        [35.40117720600011, 31.23029123900008],
        [35.395699504000106, 31.25767974900012],
        [35.40820520000011, 31.282019349000052],
        [35.42226119000014, 31.30299998000004],
        [35.42391483500006, 31.324600728000092],
        [35.416473429000064, 31.33183542900008],
        [35.435076945000105, 31.360619202000095],
        [35.452853637000146, 31.400823466000105],
        [35.456884400000035, 31.423509420000073],
        [35.4571275560001, 31.433524394000045],
        [35.45853804500007, 31.49161895800009],
        [35.45812463400006, 31.491929016000057],
        [35.45874475100004, 31.491567281000073],
        [35.45915816300004, 31.491877340000144],
        [35.45905481000011, 31.492807516000113],
        [35.45874475100004, 31.494409485000034],
        [35.46422245300005, 31.56856516500008],
        [35.48013879400014, 31.641118876000093],
        [35.50247856700008, 31.68536038900004],
        [35.527577758000064, 31.735066630000034],
        [35.55941044100007, 31.76534901900007],
        [35.53832645600005, 31.819299215000115],
        [35.53832645600005, 31.826740621000056],
        [35.54907515400009, 31.83919464100012],
        [35.524683879000065, 31.91924143500006],
        [35.527474406000124, 31.927354635000086],
        [35.53367557700011, 31.93030019200009],
        [35.5406002200001, 31.93200551400014],
        [35.54514774600011, 31.93660471600009],
        [35.545871216000194, 31.944562887000046],
        [35.53998010300012, 31.955621643000057],
        [35.53832645600005, 31.96394154900011],
        [35.53770634000011, 31.97758412700003],
        [35.535535929000105, 31.98822947300002],
        [35.524683879000065, 32.01169057200008],
        [35.52282352700007, 32.05783762700011],
        [35.528301229000135, 32.07509755500013],
        [35.54514774600011, 32.08682810500011],
        [35.53460575300005, 32.09923044900009],
        [35.53522587100014, 32.110805969000126],
        [35.55196903500007, 32.13519724500014],
        [35.546698038000045, 32.14160512300009],
        [35.546698038000045, 32.14703114900007],
        [35.55124556500016, 32.151526998000094],
        [35.55941044100007, 32.15509267200005],
        [35.55941044100007, 32.16253407900007],
        [35.55517297300014, 32.17439382],
        [35.55956241700005, 32.190370872000074],
        [35.572536255000045, 32.237594096000066],
        [35.55941044100007, 32.237594096000066],
        [35.561064087000034, 32.24314931200007],
        [35.56375126100005, 32.24681833900007],
        [35.56757531800014, 32.249505514000106],
        [35.572536255000045, 32.25190846800007],
        [35.56457808400012, 32.26358734200005],
        [35.56085738100006, 32.2826301070001],
        [35.56116744000008, 32.301698711000085],
        [35.5656116130001, 32.31335174500006],
        [35.556516560000034, 32.32820872000008],
        [35.557343384000035, 32.358206889000144],
        [35.55196903500007, 32.36794789600009],
        [35.55196903500007, 32.374820862000064],
        [35.55941044100007, 32.374820862000064],
        [35.55941044100007, 32.36794789600009],
        [35.5656116130001, 32.36794789600009],
        [35.563957967000135, 32.37704294900007],
        [35.560960734000105, 32.38471689900004],
        [35.556826620000095, 32.390918071000044],
        [35.55196903500007, 32.3952847300001],
        [35.549591919000136, 32.3985403450001],
        [35.54514774600011, 32.409573263000084],
        [35.55486291500006, 32.41117523200003],
        [35.5591003830001, 32.41396575900009],
        [35.558170207000046, 32.417996522000095],
        [35.55196903500007, 32.423241679000114],
        [35.55196903500007, 32.42944285100009],
        [35.55941044100007, 32.42944285100009],
        [35.55786014900008, 32.43411956800006],
        [35.55620650200012, 32.434791362000055],
        [35.554139445000146, 32.43445546500007],
        [35.55196903500007, 32.43621246400005],
        [35.5656116130001, 32.443705546000075],
        [35.55941044100007, 32.45052683500012],
        [35.56623173000014, 32.45311065700011],
        [35.572536255000045, 32.456728007],
        [35.572536255000045, 32.46419525200014],
        [35.568092082000135, 32.46497039800005],
        [35.56540490700013, 32.46631398500011],
        [35.563131144000124, 32.46817433700002],
        [35.55941044100007, 32.470396423000125],
        [35.56127079200007, 32.477088522000145],
        [35.56499149500013, 32.4839098110001],
        [35.57098596200012, 32.48920664500014],
        [35.5799776610001, 32.49148040800014],
        [35.5799776610001, 32.49773325600006],
        [35.57057255000012, 32.506027324000115],
        [35.568505493000146, 32.510264791000054],
        [35.561374145000116, 32.51917897600009],
        [35.557240031000106, 32.519334005000104],
        [35.55196903500007, 32.51881724100008],
        [35.55196903500007, 32.52558685300002],
        [35.5656116130001, 32.52558685300002],
        [35.56282108600004, 32.53202056900008],
        [35.55941044100007, 32.55294952400007],
        [35.5656116130001, 32.546102397000084],
        [35.57015913900011, 32.5568252570001],
        [35.572536255000045, 32.56039093000007],
        [35.57387984200011, 32.556747742000084],
        [35.57439660600005, 32.554396464000064],
        [35.575843546000044, 32.554964905000105],
        [35.5799776610001, 32.56039093000007],
        [35.57439660600005, 32.57276743600005],
        [35.57129602100014, 32.598553975000044],
        [35.5656116130001, 32.6075456750001],
        [35.5656116130001, 32.61501292000011],
        [35.572536255000045, 32.61501292000011],
        [35.572536255000045, 32.621239930000115],
        [35.564061320000064, 32.625477397000054],
        [35.56003055800005, 32.63268626000013],
        [35.560547323000094, 32.640902812000064],
        [35.56271773300011, 32.64421010400005],
        [35.56984908000004, 32.646768087000055],
        [35.578737427000135, 32.65343434700006],
        [35.593826945000046, 32.67035837800006],
        [35.61222375500006, 32.6815463260001],
        [35.612333850000084, 32.68153519700007],
        [35.635994913000104, 32.679143372000055],
        [35.65201460800006, 32.68617136600005],
        [35.68519087700008, 32.71123443700006],
        [35.740174601000035, 32.74053497300011],
        [35.757434530000126, 32.744281514000136],
        [35.75759034900011, 32.744346858000114],
        [35.76384240700014, 32.746968690000074],
        [35.769733520000045, 32.748053894000066],
        [35.77490116400014, 32.74727874800007],
        [35.77913863100008, 32.74451405800011],
        [35.77913863100008, 32.7444623830001],
        [35.77903527800015, 32.744359030000055],
        [35.77903527800015, 32.744281514000136],
        [35.78823368400009, 32.73441131700005],
        [35.89572066300008, 32.71327565600012],
        [35.90522912600005, 32.70857310000014],
        [35.92248905400004, 32.69376780200011],
        [35.927449992000106, 32.69237253900013],
        [35.94036910000011, 32.69250172900007],
        [35.94419315600004, 32.69077056900011],
        [35.94574344900008, 32.684104310000095],
        [35.944399862000125, 32.67761891700013],
        [35.941195922000134, 32.67353647900012],
        [35.937475219000135, 32.67400156700006],
        [35.94657027200009, 32.66444142700004],
        [35.955355265000094, 32.657439271000044],
        [35.96579390500011, 32.65436452300011],
        [35.98026330600004, 32.65661244800012],
        [36.003621053000074, 32.65508799200009],
        [36.00827193200007, 32.64371917800011],
        [36.00527469900004, 32.62669179300008],
        [36.00599816900012, 32.60790741000008],
        [36.0154032800001, 32.591164246000034],
        [36.060465128000146, 32.533260803000104],
        [36.06604618300014, 32.52160776800014],
        [36.06625288900011, 32.51731862400007],
        [36.06987023900007, 32.51659515400007],
        [36.081905549000055, 32.5162647730001],
        [36.096225220000065, 32.515871684000075],
        [36.13322554600012, 32.52010915100004],
        [36.139530070000035, 32.519540710000086],
        [36.149865356000134, 32.51613006700006],
        [36.15585982200014, 32.515199891000094],
        [36.16082076000009, 32.517215271000055],
        [36.17218957500012, 32.52592275000012],
        [36.17735721800011, 32.52731801400009],
        [36.18820926900014, 32.52227956100012],
        [36.22076542100007, 32.4945809940001],
        [36.2852576090001, 32.45693471300007],
        [36.37310754400016, 32.38642222100012],
        [36.387887004000106, 32.379316712000076],
        [36.40762740000008, 32.37422658300008],
        [36.463954712000145, 32.36939483700007],
        [36.48018111100009, 32.36079071100005],
        [36.51668392700009, 32.35701418200003],
        [36.65350386500006, 32.342858988000074],
        [36.68957401500006, 32.31965627100007],
        [36.70693729600009, 32.32833791100012],
        [36.72864139800009, 32.32779530900007],
        [36.79251346800004, 32.31353261400011],
        [36.80656945800007, 32.313041687000066],
        [36.819385213000146, 32.31678822900008],
        [36.980098918000124, 32.41003835100011],
        [37.133164510000086, 32.49447764100006],
        [37.133164510000086, 32.494529318000076],
        [37.13337121600006, 32.494529318000076],
        [37.13337121600006, 32.4945809940001],
        [37.244062134000046, 32.554396464000064],
        [37.41521447800005, 32.64712982200004],
        [37.494605674000184, 32.690055623],
        [37.58667688000014, 32.739837342000044],
        [37.75803592900007, 32.83251902300012],
        [37.92939497900005, 32.92532989600005],
        [38.056725708000045, 32.99429209500008],
        [38.056725708000045, 32.994343770000086],
        [38.23087528500014, 33.086301982000066],
        [38.31574206300013, 33.1311800630001],
        [38.52956506300012, 33.24425099700008],
        [38.77451135200005, 33.371685079000116],
        [38.82102014200012, 33.22903228800004],
        [38.862567993000084, 33.10071970600009],
        [38.89719120300009, 32.994343770000086],
        [38.942769816000066, 32.85233693400011],
        [38.99000207500012, 32.70557586700002],
        [39.05718143800004, 32.496596374000035],
        [38.97997684800009, 32.476054993000076],
        [38.97863326000004, 32.475693258000064],
        [38.978219849000084, 32.47496978800007],
        [38.97863326000004, 32.473729554000045],
        [38.97997684800009, 32.47210174600008],
        [39.02875939900014, 32.32833791100012],
        [39.03620080600007, 32.31335174500006],
        [39.04632938700013, 32.30849416100003]
    ],
    'BHD': [
        [50.55160566500007, 26.194240627000028],
        [50.59473717500009, 26.160305080000057],
        [50.60487053991403, 26.17473159438859],
        [50.61989816690732, 26.185465613669578],
        [50.626338578475895, 26.170437986676205],
        [50.63277899004447, 26.14682314425815],
        [50.645659813181624, 26.145749742329997],
        [50.635506518265515, 26.134382379256323],
        [50.63492579390066, 26.115694488343337],
        [50.61475670700014, 26.11115143400005],
        [50.62061608200008, 26.052964585000012],
        [50.61841881600009, 25.964748440000022],
        [50.60352623800014, 25.854559637000108],
        [50.59449303500014, 25.83270905200007],
        [50.56544030000009, 25.78969961100003],
        [50.565277540000125, 25.789536851000065],
        [50.55860436300014, 25.81976959800008],
        [50.547211134000065, 25.852932033000087],
        [50.53142337300005, 25.885239976000037],
        [50.51140384200005, 25.913031317000105],
        [50.46778405000009, 25.957586981000077],
        [50.46045983200008, 25.985256252000084],
        [50.46045983200008, 25.985296942000076],
        [50.47999108200014, 26.018866278000104],
        [50.485443556000064, 26.03343333500004],
        [50.490896030000044, 26.048000393000052],
        [50.490896030000044, 26.048041083000044],
        [50.485199415000125, 26.085638739000046],
        [50.469981316000144, 26.1226260440001],
        [50.45297285200007, 26.149562893000052],
        [50.44890384200005, 26.16160716400006],
        [50.449554884000065, 26.17796458500007],
        [50.46119225400008, 26.22524648600003],
        [50.46851647200009, 26.23688385600005],
        [50.48145592500009, 26.242580471000025],
        [50.48161868600005, 26.242539781000033],
        [50.48259524800005, 26.242539781000033],
        [50.503916863000086, 26.24201080900005],
        [50.51636803500014, 26.239691473000107],
        [50.532399936000104, 26.23309967700004],
        [50.54542076900009, 26.227728583000072],
        [50.54550214900007, 26.227769273000064],
        [50.58668053500014, 26.24249909100004],
        [50.59327233200014, 26.23859284100004],
        [50.59742272200009, 26.225531317000076],
        [50.60474694100009, 26.216498114000075],
        [50.60694420700008, 26.212388414000117],
        [50.60914147200003, 26.208319403000047],
        [50.60914147200003, 26.208278713000055],
        [50.60352623800014, 26.197658596000025],
        [50.59408613400012, 26.195298570000062],
        [50.56560306100005, 26.198879299000055],
        [50.55160566500007, 26.194240627000028]
    ],
    'SAR': [
        [39.2152424, 32.1704563],
        [38.9955159, 31.9935932],
        [36.9960042, 31.4890674],
        [37.99576, 30.5002354],
        [37.6661702, 30.3486597],
        [37.4903889, 29.9972415],
        [36.7323323, 29.8639463],
        [36.5016194, 29.4821222],
        [36.0841389, 29.2043961],
        [34.9525471, 29.3577179],
        [34.4361897, 27.7948213],
        [42.0597729, 16.187005],
        [42.9826245, 16.5243399],
        [43.334187, 17.5746723],
        [43.6849429, 17.3734564],
        [47.0192934, 16.9430656],
        [47.4767824, 17.1231499],
        [48.1789029, 18.2224899],
        [49.1017545, 18.6185789],
        [52.0241178, 19.0137475],
        [54.9904264, 20.0285029],
        [55.6715787, 22.078946],
        [55.2101529, 22.7289941],
        [52.5294889, 22.9922018],
        [51.496774, 24.2403778],
        [48.4206021, 28.5878255],
        [47.6076139, 28.5685299],
        [47.40986, 29.0114308],
        [44.6852506, 29.2225858],
        [42.0914893, 31.1232277],
        [40.3776221, 32.0217824],
        [39.2152424, 32.1704563]
    ]
};
