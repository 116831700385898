import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Dialog} from 'primeng/dialog';
import {IExcelShipmentRow} from '../../shared/models/excel-shipment.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {combineLatest, empty, from, Subject, Subscription} from 'rxjs';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {EXCEL_IMPORT_CONSTANTS} from '../services/constants';
import {ShipmentsExcelService} from '../services/shipments-excel.service';
import {catchError, concatAll, concatMap, share} from 'rxjs/operators';
import {Table} from 'primeng/table';
import {UserService} from '../../shared/services/user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentsService} from '../services/shipments.service';
import {ZonesService} from '../../administration/services/zones.service';
import {ADMINISTRATION_CONSTANTS} from '../../administration/administration-constants';
import {UsersService} from '../../users/services/users.service';
import {atLeastOneOf} from '../../shared/behavior/at-least-one.validator';
import {VehicleTypeModel} from '../../shared/models/container.model';
import {ServiceTypeModel} from '../../administration/models/service-type.model';
import {ServiceTypesService} from '../../administration/services/service-types.service';
import {SHARED_CONSTANTS} from '../../shared/services/shared_constants/constants';
import * as _ from 'lodash';
import {ChooseCustomerComponent} from '../../partners/choose-customer/choose-customer.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Companies} from '../../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-excel-validation-dialog',
    templateUrl: './excel-validation-dialog.component.html',
    styleUrls: ['./excel-validation-dialog.component.scss']
})
export class ExcelValidationDialogComponent implements OnInit {
    @ViewChild('validationDialog', {static: false})
    validationDialog: Dialog;

    @ViewChild('importTable', {static: false})
    importTable: Table;

    @Output() closeDialogEvent: EventEmitter<boolean> = new EventEmitter();

    @Input() isBundle;
    public isLoading = false;
    public fromPage = null;
    public isForPackages = false;
    currentLang: string;
    errorMessage: string;
    successMessage: string;
    totalEnabledShipments = 0;
    totalSubmitted = 0;
    totalFailed = 0;

    public shipmentServicesOptions: { value: string, label: string }[] = [];
    public shipmentTypesOptions: { value: string, label: string }[] = [];

    shipmentsForm: FormGroup;
    shipmentsArray: FormArray;
    shipments: IExcelShipmentRow[] = [];
    hasAdditionalAddressDetails = false;

    isShowDynamicServiceTypes = false;
    serviceTypesOptions = [];
    customersServiceTypesOptions: { [customerId: number]: any[] } = {};
    vehicleTypesOptions: { [serviceTypeId: number]: VehicleTypeModel[] } = {};

    importSubscription: Subscription;
    customerSearchObserver: Subject<any>;
    villageSearchObserver: Subject<any>;
    citySearchObserver: Subject<any>;

    customerOptions = [];
    villageOptions = [];
    cityOptions = [];
    isGeneralCustomerOptions = false;
    isGeneralVillageOptions = false;
    isGeneralCityOptions = false;
    private companyId: number;
    private isLcl: boolean;
    userServiceOptionMap = {};
    userShipmentOptionMap = {};
    collectionTypes = [];
    private isPricingPerServiceTypeEnabled: boolean;
    private isTrucking: boolean;
    customersIdsList = [];
    addedPackages = [];
    isPrinting = false;
    showCodFees = false;
    showWeight = false;
    showAddressLine2 = false;
    codCollectionMethods;

    constructor(private formBuilder: FormBuilder,
                private auth: AuthenticationService,
                private modalService: NgbModal,
                private usersService: UsersService,
                private userService: UserService,
                private zonesService: ZonesService,
                private shipmentsExcelService: ShipmentsExcelService,
                private shipmentsService: ShipmentsService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private serviceTypesService: ServiceTypesService) {

        this.collectionTypes = [
            {
                label: this.translateService.instant('ADD_SHIPMENTS.TO_COLLECT_FROM_RECEIVER'),
                value: 'COLLECT'
            },
            {
                label: this.translateService.instant('ADD_SHIPMENTS.TO_PAY_TO_RECEIVER'),
                value: 'PAY'
            },
        ];
    }

    ngOnInit() {
        this.companyId = this.userService.userInfo.companyId;
        this.isLcl = this.userService.userInfo.isLcl;
        this.isTrucking = this.userService.userInfo.isTrucking;
        this.hasAdditionalAddressDetails = this.userService.hasAdditionalAddressDetails();
        this.isShowDynamicServiceTypes = this.userService.userInfo.isPricingPerServiceTypeEnabled;
        this.currentLang = this.translateService.currentLang;
        this.isPricingPerServiceTypeEnabled = this.userService.userInfo.isPricingPerServiceTypeEnabled;
        this.showCodFees = this.userService.getCurrency() === 'SAR';
        this.showAddressLine2 = [255, 1].indexOf(this.companyId) >= 0;
        this.showWeight = [Companies.SPRINT, Companies.LOGESTECHS].indexOf(this.companyId) >= 0;

        if (this.isShowDynamicServiceTypes) {
            this.getServiceTypes();
        } else {
            this.initShipmentServices(undefined);
        }
        this.initCodCollectionMethod();
        this.initShipmentTypes();
        this.initTable();
        this.initObservers();
    }

    private initTable() {
        this.shipmentsForm = this.formBuilder.group({
            shipments: this.formBuilder.array([])
        });
        this.shipmentsArray = (this.shipmentsForm.get('shipments') as FormArray);
    }

    private initObservers() {
        this.customerSearchObserver = new Subject();
        this.customerSearchObserver.subscribe(
            (data) => {
                if (typeof data === 'string' && data.length !== 0) {
                    const params = this.createParams(data);
                    this.usersService
                        .searchCustomers(params, false, true).subscribe(
                        (response: any) => {
                            this.customerOptions = response.customers;
                            this.customerSearchObserver.next(true);
                        }
                    );
                } else if (data.length === 0) {
                    this.customerSearchObserver.next(true);
                }
            }, (error) => {
                console.error(error);
                this.customerSearchObserver.next(true);
            }
        );

        this.villageSearchObserver = new Subject();
        this.villageSearchObserver.subscribe(
            (data) => {
                if (typeof data === 'string' && data.length !== 0) {
                    const params = this.createParams(data);
                    this.zonesService
                        .getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES, params).subscribe(
                        (response: any) => {
                            this.villageOptions = response.data;
                            this.villageSearchObserver.next(true);
                        }
                    );
                } else if (data.length === 0) {
                    this.villageSearchObserver.next(true);
                }
            }, (error) => {
                console.error(error);
                this.villageSearchObserver.next(true);
            }
        );

        this.citySearchObserver = new Subject();
        this.citySearchObserver.subscribe(
            (data) => {
                if (typeof data === 'string' && data.length !== 0) {
                    const params = this.createParams(data);
                    this.zonesService
                        .getCitiesList(params).subscribe(
                        (response: any) => {
                            this.cityOptions = response.data;
                            this.citySearchObserver.next(true);
                        }
                    );
                } else if (data.length === 0) {
                    this.citySearchObserver.next(true);
                }
            }, (error) => {
                console.error(error);
                this.citySearchObserver.next(true);
            }
        );
    }

    private addShipment(shipment: IExcelShipmentRow): Subject<any> {
        this.isLoading = true;
        const addShipmentSubject = new Subject();
        const shipmentRow = this.formBuilder.group({
            enabled: [true],
            shipmentDetails: this.formBuilder.group({
                rowId: [shipment.rowId],
                senderEmail: [shipment.shipmentInfo.senderEmail],
                senderName: [shipment.shipmentInfo.senderName],
                businessSenderName: [shipment.shipmentInfo.senderBusinessName],
                customerInfo: [null, Validators.required],
                customerLoading: [false],
                receiverName: [shipment.shipmentInfo.receiverName, Validators.required],
                businessReceiverName: [shipment.shipmentInfo.businessReceiverName],
                receiverMobile: [shipment.shipmentInfo.receiverMobile, [Validators.required,
                    this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144)),
                    Validators.maxLength(14),
                    Validators.minLength(8)]],
                receiverMobile2: [shipment.shipmentInfo.receiverMobile2, [
                    this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern(this.companyId === 144)),
                    Validators.maxLength(14),
                    Validators.minLength(8)]],
                notes: [shipment.shipmentInfo.notes],
                receiverVillage: [null],
                receiverCity: [null],
                receiverVillageName: [''],
                receiverCityName: [''],
                villageLoading: [false],
                cityLoading: [false],
                receiverStreetAddress: [shipment.shipmentInfo.receiverStreetAddress, Validators.required],
                receiverStreetAddress2: [''],
                receiverBuilding: [shipment.shipmentInfo.receiverBuilding],
                receiverFlat: [shipment.shipmentInfo.receiverFlat],
                cod: [shipment.shipmentInfo.cod, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?(\.)?(0|[0-9]\d*)$/)]],
                shipmentType: [shipment.shipmentInfo.shipmentType, Validators.required],
                serviceType: [shipment.shipmentInfo.serviceType, Validators.required],
                invoiceNumber: [shipment.shipmentInfo.invoiceNumber],
                supplierInvoice: [shipment.shipmentInfo.supplierInvoice],
                description: [shipment.shipmentInfo.description],
                quantity: [shipment.shipmentInfo.quantity],
                submitted: [false],
                submitting: [false],
                failed: [false],
                errorMessage: [null],
                codCollectionMethod: [shipment.shipmentInfo.codCollectionMethod],
                codCollectionType: [shipment.shipmentInfo.codCollectionType]
            }, {validator: atLeastOneOf('receiverVillage', 'receiverCity')})
        });

        const shipmentForm = shipmentRow.get('shipmentDetails') as FormGroup;
        const initialRequestsObservables = [];

        if (this.isShowDynamicServiceTypes) {
            shipmentForm.get('serviceType').setValidators([Validators.required]);
            shipmentForm.get('serviceType').updateValueAndValidity();
            shipmentForm.addControl('vehicleType', this.formBuilder.control([]));
            shipmentForm.addControl('serviceTypeLoading', this.formBuilder.control([]));
            shipmentForm.addControl('vehicleTypeLoading', this.formBuilder.control([]));

            shipmentForm.get('vehicleType').patchValue(shipment.shipmentInfo.vehicleType);
            shipmentForm.get('serviceTypeLoading').patchValue(false);
            shipmentForm.get('vehicleTypeLoading').patchValue(false);
        }

        if (shipment.shipmentInfo.receiverVillage) {
            initialRequestsObservables.push(this.initShipmentVillage(shipment, shipmentForm));
        }

        if (this.showCodFees) {
            shipmentForm.addControl('codFees', this.formBuilder.control('0'));
        }
        if (this.showWeight) {
            shipmentForm.addControl('weight', this.formBuilder.control(shipment.shipmentInfo['weight']));
        }

        if (shipment.shipmentInfo.receiverCity) {
            initialRequestsObservables.push(this.initShipmentCity(shipment, shipmentForm));
        }

        if (shipment.shipmentInfo.senderEmail) {
            initialRequestsObservables.push(this.initShipmentCustomer(shipment, shipmentForm));
        }

        // if (shipment.shipmentInfo.serviceType && this.isShowDynamicServiceTypes) {
        //     initialRequestsObservables.push(this.initShipmentServiceType(shipment, shipmentForm));
        // }

        combineLatest(initialRequestsObservables).pipe(concatAll()).subscribe(
            null, (error) => {
                console.error(error);
            }, () => {
                this.importTable.reset();
                addShipmentSubject.complete();
            }
        );

        for (const control in shipmentForm.controls) {
            shipmentForm.get(control).markAsDirty();
        }
        this.shipmentsArray.push(shipmentRow);
        this.totalEnabledShipments++;
        return addShipmentSubject;
    }

    private initShipmentVillage(shipment: IExcelShipmentRow, shipmentForm: FormGroup) {
        shipmentForm.get('receiverVillageName').patchValue(shipment.shipmentInfo.receiverVillage);
        shipmentForm.get('villageLoading').patchValue(true);
        const villageObservable = this.zonesService
            .getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES,
                this.createParams(shipment.shipmentInfo.receiverVillage), true);
        const villageSubject = new Subject();
        villageObservable.subscribe(
            (response: any) => {
                if (response.data.length <= EXCEL_IMPORT_CONSTANTS.MAX_OPTIONS_TO_SELECT_ADDRESS &&
                    response.data.length > 0) {
                    let matchIndex = 0;
                    response.data.some((village, index) => {
                        if (village.name.trim().toLowerCase() === shipment.shipmentInfo.receiverVillage.trim().toLowerCase()) {
                            matchIndex = index;
                            return true;
                        }
                        return false;
                    });
                    shipmentForm.get('receiverVillage').patchValue(response.data[matchIndex]);
                    shipmentForm.get('receiverVillageName').patchValue(response.data[matchIndex].name);
                } else if (this.showAddressLine2) {
                    this.patchVillageFromCity(shipmentForm, shipment);
                }
                shipmentForm.get('villageLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
                villageSubject.complete();
            }, () => {
                shipmentForm.get('villageLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
                villageSubject.complete();
            }
        );

        return villageSubject;
    }

    private initShipmentCity(shipment: IExcelShipmentRow, shipmentForm: FormGroup) {
        shipmentForm.get('receiverCityName').patchValue(shipment.shipmentInfo.receiverVillage);
        shipmentForm.get('cityLoading').patchValue(true);
        const cityObservable = this.zonesService
            .getCitiesList(this.createParams(shipment.shipmentInfo.receiverCity), true);
        const citySubject = new Subject();
        cityObservable.subscribe(
            (response: any) => {
                if (response.data.length <= EXCEL_IMPORT_CONSTANTS.MAX_OPTIONS_TO_SELECT_ADDRESS &&
                    response.data.length > 0) {
                    let matchIndex = 0;
                    response.data.some((city, index) => {
                        if (city.name.trim().toLowerCase() === shipment.shipmentInfo.receiverCity.trim().toLowerCase()) {
                            matchIndex = index;
                            return true;
                        }
                        return false;
                    });
                    shipmentForm.get('receiverCity').patchValue(response.data[matchIndex]);
                    shipmentForm.get('receiverCityName').patchValue(response.data[matchIndex].name);
                }
                shipmentForm.get('cityLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
                citySubject.complete();
            }, () => {
                shipmentForm.get('cityLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
                citySubject.complete();
            }
        );

        return citySubject;
    }

    private initShipmentCustomer(shipment: IExcelShipmentRow, shipmentForm: FormGroup) {
        shipmentForm.get('customerLoading').patchValue(true);
        const customerObservable = this.usersService
            .searchCustomers(this.createParams(shipment.shipmentInfo.senderEmail), true, true);
        const customerSubject = new Subject();
        customerObservable.subscribe(
            (response: any) => {
                let customerId;
                if (response.customers.length <= EXCEL_IMPORT_CONSTANTS.MAX_OPTIONS_TO_SELECT_ADDRESS &&
                    response.customers.length > 0) {
                    let matchIndex = 0;
                    response.customers.some((customer, index) => {
                        if (customer.email.trim() === shipment.shipmentInfo.senderEmail.trim()) {
                            matchIndex = index;
                            return true;
                        }
                        return false;
                    });
                    shipmentForm.get('customerInfo').patchValue(response.customers[matchIndex]);
                    this.initServiceTypesForCustomer(response.customers[matchIndex]);
                    customerId = response.customers[matchIndex].id;
                    this.initShipmentTypesForCustomer(response.customers[matchIndex]);
                    let shipmentTypeStr = shipmentForm.get('shipmentType').value;
                    if (this.userShipmentOptionMap[customerId]) {

                        const arOptions = ['مدفوع مسبقا', 'تحصيل عند الاستلام', 'تبديل', 'إحضار', 'Bring'];
                        const enOptions = ['Prepaid', 'COD', 'Exchange', 'Pick-up', 'Bring'];
                        const mapSet = {
                            'Bring': this.currentLang === 'ar' ? 'إحضار' : 'Pick-up',
                        };
                        if (this.currentLang === 'ar') {
                            mapSet['Prepaid'] = 'مدفوع مسبقا';
                            mapSet['COD'] = 'تحصيل عند الاستلام';
                            mapSet['Exchange'] = 'تبديل';
                            mapSet['Pick-up'] = 'إحضار';
                            mapSet['Bring'] = 'إحضار';
                        } else {
                            mapSet['مدفوع مسبقا'] = 'Prepaid';
                            mapSet['تحصيل عند الاستلام'] = 'COD';
                            mapSet['تبديل'] = 'Exchange';
                            mapSet['إحضار'] = 'Pick-up';
                        }

                        const options = this.userShipmentOptionMap[customerId].map(v => v.label);
                        shipmentTypeStr = this.findMostSimilarText(shipmentTypeStr, [...options, ...(this.currentLang === 'ar' ? enOptions : arOptions)]);
                        if (mapSet[shipmentTypeStr]) {
                            shipmentTypeStr = mapSet[shipmentTypeStr];
                        }

                        const shipmentType = this.userShipmentOptionMap[customerId].find(v => v.label === shipmentTypeStr || v.value === shipmentTypeStr);
                        shipmentForm.get('shipmentType').patchValue(shipmentType?.value);
                        this.getCodCollectionMethod(shipmentForm);
                        if (['BRING', 'SWAP'].indexOf(shipmentType?.value) !== -1) {
                            this.getCodCollectionType(shipmentForm);
                        }
                    }
                    shipmentForm.get('senderEmail').patchValue(response.customers[matchIndex].email);
                    if (this.showCodFees) {
                        shipmentForm.get('codFees').patchValue(response.customers[matchIndex].codFees);
                    }
                    shipmentForm.get('senderEmail').patchValue(response.customers[matchIndex].email);
                    this.customersIdsList[`row_id_${shipment.rowId}`] = response.customers[matchIndex].id;
                    this.customersServiceTypesOptions[response.customers[matchIndex].id] = response.customers[matchIndex].serviceTypeList;
                }
                shipmentForm.get('customerLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
                customerSubject.complete();
                if (shipment.shipmentInfo.serviceType && this.isShowDynamicServiceTypes) {
                    this.getServiceTypes('', shipmentForm, customerId);
                } else {
                    const list = (this.customersServiceTypesOptions[customerId] || this.shipmentServicesOptions || []).filter(serviceType => serviceType.label.trim().toLowerCase() === shipment.shipmentInfo.serviceType.toLowerCase().trim()
                        || serviceType.value.trim().toLowerCase() === shipment.shipmentInfo.serviceType.toLowerCase().trim());
                    if (list.length > 0) {
                        shipmentForm.get('serviceType').patchValue(list[0].value);
                    } else {
                        shipmentForm.get('serviceType').patchValue(null);
                    }
                }
            }, () => {
                shipmentForm.get('customerLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
                customerSubject.complete();
            }
        );

        return customerSubject;
    }

    private initShipmentServiceType(shipment: IExcelShipmentRow, shipmentForm: FormGroup) {
        const customerId = this.customersIdsList[`row_id_${shipment.rowId}`];
        if (!customerId) {
            return;
        }
        const list = this.customersServiceTypesOptions[customerId].filter(serviceType => serviceType.name.trim().toLowerCase() === shipment.shipmentInfo.serviceType.toLowerCase().trim()
            || serviceType.arabicName.trim().toLowerCase() === shipment.shipmentInfo.serviceType.toLowerCase().trim());
        if (list.length > 0) {
            shipmentForm.get('serviceType').patchValue(list[0]);
            shipmentForm.get('vehicleType').setValidators(Validators.required);
            this.serviceTypesService.getVehicleTypes(
                list[0].id, '?page=1&pageSize=50', true).subscribe((res: VehicleTypeModel[]) => {
                this.vehicleTypesOptions[list[0].id] = res;
                if (res.length > 0) {
                    let vehicleTypeMatchIndex = 0;
                    if (shipment.shipmentInfo.vehicleType) {
                        res.some((vehicleType, index) => {
                            if (vehicleType.name.trim().toLowerCase() === shipment.shipmentInfo.vehicleType.trim().toLowerCase() ||
                                vehicleType.arabicName.trim() === shipment.shipmentInfo.vehicleType.trim()) {
                                vehicleTypeMatchIndex = index;
                                return true;
                            }
                            return false;
                        });
                    }
                    shipmentForm.get('vehicleType').patchValue(res[vehicleTypeMatchIndex]);
                } else {
                    shipmentForm.get('vehicleType').patchValue(null);
                    shipmentForm.get('vehicleType').updateValueAndValidity();
                }
            }, (error) => {
                console.error(error);
            });
        } else {
            shipmentForm.get('serviceType').patchValue(null);
            shipmentForm.get('serviceType').updateValueAndValidity();
            shipmentForm.get('vehicleType').patchValue(null);
            shipmentForm.get('vehicleType').updateValueAndValidity();
        }
    }

    private createParams(search?: string, page = 1, pageSize = EXCEL_IMPORT_CONSTANTS.ADDRESS_PAGE_SIZE) {
        const params = {
            pageSize: pageSize,
            page: page,
        };

        if (search && search !== '') {
            params['search'] = search;
        }
        return params;
    }

    private getPackage(shipmentForm) {
        if (shipmentForm) {
            let shipmentData = {
                pkg: {
                    customerId: shipmentForm.get('customerInfo').value.id,
                    senderEmail: shipmentForm.get('customerInfo').value.email,
                    receiverName: shipmentForm.get('receiverName').value,
                    senderName: shipmentForm.get('senderName').value.length ? shipmentForm.get('senderName').value : shipmentForm.get('customerInfo').value.fullName,
                    businessReceiverName: shipmentForm.get('businessReceiverName').value,
                    receiverPhone: shipmentForm.get('receiverMobile').value,
                    receiverPhone2: shipmentForm.get('receiverMobile2').value,
                    senderPhone: shipmentForm.get('customerInfo').value.phone1,
                    senderPhone2: shipmentForm.get('customerInfo').value.phone2,
                    notes: shipmentForm.get('notes').value,
                    shipmentType: shipmentForm.get('shipmentType').value,
                    invoiceNumber: shipmentForm.get('invoiceNumber').value,
                    supplierInvoice: shipmentForm.get('supplierInvoice').value,
                    description: shipmentForm.get('description').value,
                    quantity: shipmentForm.get('quantity').value || 1,
                    codFees: this.showCodFees ? shipmentForm.get('codFees').value || 0 : 0,
                    weight: this.showWeight ? shipmentForm.get('weight').value || 0 : 0,
                    paymentType: shipmentForm.get('codCollectionMethod').value
                }, destinationAddress: {
                    villageId: shipmentForm.get('receiverVillage').value ? shipmentForm.get('receiverVillage').value.id : null,
                    cityId: shipmentForm.get('receiverVillage').value
                        ? shipmentForm.get('receiverVillage').value.cityId
                        : shipmentForm.get('receiverCity').value.id,
                    regionId: shipmentForm.get('receiverVillage').value
                        ? shipmentForm.get('receiverVillage').value.regionId
                        : shipmentForm.get('receiverCity').value.regionId,
                    addressLine1: shipmentForm.get('receiverStreetAddress').value,
                    buildingNo: shipmentForm.get('receiverBuilding').value,
                    floorNo: shipmentForm.get('receiverFlat').value
                }, originAddress: {
                    villageId: shipmentForm.get('customerInfo').value.address.villageId,
                    cityId: shipmentForm.get('customerInfo').value.address.cityId,
                    regionId: shipmentForm.get('customerInfo').value.address.regionId,
                    addressLine1: shipmentForm.get('customerInfo').value.address.addressLine1,
                    addressLine2: shipmentForm.get('customerInfo').value.address.addressLine2,
                    buildingNo: shipmentForm.get('customerInfo').value.address.buildingNo,
                    floorNo: shipmentForm.get('customerInfo').value.address.floorNo
                }
            };

            if (this.isShowDynamicServiceTypes && shipmentForm.get('serviceType').value) {
                if (shipmentForm.get('serviceType').value) {
                    shipmentData.pkg['serviceTypeId'] = shipmentForm.get('serviceType').value.id;
                    shipmentData.pkg['vehicleTypeId'] = shipmentForm.get('vehicleType').value.id;
                }
            } else {
                shipmentData.pkg['serviceType'] = shipmentForm.get('serviceType').value || 'STANDARD';
            }
            if (this.showAddressLine2) {
                shipmentData.destinationAddress['addressLine2'] = shipmentForm.get('receiverStreetAddress2').value;
            }

            let senderName = shipmentForm.get('senderName').value;
            if (senderName && typeof senderName === 'string') {
                senderName = senderName.trim().split(' ');
                shipmentData.pkg['senderFirstName'] = senderName[0];
                shipmentData.pkg['senderLastName'] = senderName.length > 1 ? senderName[1] : '.';
                senderName.splice(0, 2);
                senderName.forEach((namePart) => {
                    shipmentData.pkg['senderLastName'] += ' ' + namePart;
                });
            }

            if (shipmentForm.get('businessSenderName').value) {
                shipmentData.pkg['businessSenderName'] = shipmentForm.get('businessSenderName').value;
            }
            if (['BRING', 'SWAP'].indexOf(shipmentForm.get('shipmentType').value) !== -1) {
                if (shipmentForm.get('codCollectionType').value === 'PAY') {
                    shipmentData.pkg['toPayToReceiver'] = shipmentForm.get('cod').value;
                } else {
                    shipmentData.pkg['toCollectFromReceiver'] = shipmentForm.get('cod').value;
                }
            } else {
                shipmentData.pkg['cod'] = shipmentForm.get('cod').value;
            }
            if (['BRING'].indexOf(shipmentForm.get('shipmentType').value) !== -1) {
                shipmentData = this.swapSenderDataAndRecieverData(shipmentData);
            }
            return shipmentData;
        }

        return {};
    }

    private initShipmentServices(customerId) {
        if (this.companyId === 139) { // vee company
            this.translateService.get(SHARED_CONSTANTS.STATIC_SERVICE_TYPES.VEE_SERVICE_TYPES).subscribe(values => {
                this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                    return {
                        label: values[translationKey],
                        value: translationKey
                    };
                });
            });
        } else {
            this.translateService.get(this.isLcl ? SHARED_CONSTANTS.STATIC_SERVICE_TYPES.LCL_SERVICE_TYPES
                : SHARED_CONSTANTS.STATIC_SERVICE_TYPES.GENERAL_SERVICE_TYPES).subscribe(values => {
                this.shipmentServicesOptions = Object.keys(values).map(translationKey => {
                    return {
                        label: values[translationKey],
                        value: translationKey
                    };
                });
            });
        }
        if (customerId) {
            this.userServiceOptionMap[customerId] = _.clone(this.shipmentServicesOptions);
        }
    }


    private initShipmentTypes() {
        let ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.GENERAL_SHIPMENT_TYPES;
        if (this.isLcl) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.LCL_SHIPMENT_TYPES;
        } else if (this.isTrucking) {
            ShipmentTypes = SHARED_CONSTANTS.SHIPMENT_TYPES.TRUCKING_SHIPMENT_TYPES;
        }
        this.translateService.get(ShipmentTypes.map(type => this.isTrucking ? `TRUCKING.SHIPMENT_TYPE.${type}` : type))
            .subscribe(values => {
                this.shipmentTypesOptions = ShipmentTypes.map(type => {
                    return {
                        label: values[this.isTrucking ? `TRUCKING.SHIPMENT_TYPE.${type}` : type],
                        value: type
                    };
                });
            });
    }

    private clearShipmentsForm() {
        const formArrayLength = this.shipmentsArray.controls.length;
        for (let i = 0; i < formArrayLength; ++i) {
            this.shipmentsArray.removeAt(0);
        }
    }

    private downloadExcelErrors() {
        const errorShipments = [];
        this.shipmentsArray.controls.map(
            (shipmentControl: FormGroup) => {
                if (shipmentControl.invalid) {
                    errorShipments.push(shipmentControl.value.shipmentDetails);
                }
            }
        );
        if (errorShipments.length > 0) {
            this.shipmentsExcelService.exportShipments(errorShipments);
        }
    }

    public hide() {
        this.translateService.get([
            'ACTIONS.CONFIRM_CANCEL',
            'GENERAL.YES', 'GENERAL.NO'
        ]).subscribe((values) => {
            this.confirmationService.confirm({
                message: values['ACTIONS.CONFIRM_CANCEL'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.clearShipmentsForm();
                    if (this.successMessage) {
                        this.shipmentsService.shipmentAdded();
                    }
                    this.closeDialogEvent.emit(false);
                }
            });
        });
    }

    trimSpacesFromExcel(shipments) {
        shipments.forEach(shipment => {
            Object.keys(shipment.shipmentInfo).forEach(key => {
                if (shipment.shipmentInfo[key] !== null && typeof shipment.shipmentInfo[key] === 'string') {
                    shipment.shipmentInfo[key] = shipment.shipmentInfo[key].trim();
                }
            });
        });
    }

    public loadDialog(shipments?: IExcelShipmentRow[]) {
        this.errorMessage = undefined;
        this.clearShipmentsForm();
        this.trimSpacesFromExcel(shipments);
        this.shipments = shipments;
        this.isLoading = true;
        from(shipments)
            .pipe(concatMap((shipment) => this.addShipment(shipment)))
            .pipe(concatAll())
            .subscribe(
                () => {
                }, (error) => {
                    this.errorMessage = error;
                    this.isLoading = false;
                }, () => {
                    this.importTable.reset();
                    this.isLoading = false;
                    this.downloadExcelErrors();
                }
            );

        this.shipmentsForm.updateValueAndValidity();
        this.shipmentsForm.markAsDirty();
        this.importTable.reset();
    }

    public clearCityOptions() {
        if (!this.isGeneralCityOptions) {
            this.cityOptions = [];
        }
    }

    public getCityOptions(shipmentForm: FormGroup) {
        if (!this.isGeneralCityOptions) {
            shipmentForm.get('shipmentDetails').get('cityLoading').patchValue(true);
            this.zonesService.getCitiesList(this.createParams('', 1, EXCEL_IMPORT_CONSTANTS.GENERAL_ADDRESS_PAGE_SIZE)).subscribe(
                (response: any) => {
                    this.isGeneralCityOptions = true;
                    this.cityOptions = response.data;
                    shipmentForm.get('shipmentDetails').get('cityLoading').patchValue(false);
                }, (error) => {
                    shipmentForm.get('shipmentDetails').get('cityLoading').patchValue(false);
                    console.error(error);
                }
            );
        }
    }

    public clearVillageOptions() {
        if (!this.isGeneralVillageOptions) {
            this.villageOptions = [];
        }
    }

    hasError(form: any) {
        if (form.controls.shipmentDetails.errors) {
            return form.controls.shipmentDetails.errors.atLeastOne !== undefined && form.controls.shipmentDetails.errors.atLeastOne !== null;
        }
        return false;
    }

    public getVillageOptions(shipmentForm: FormGroup) {
        if (!this.isGeneralVillageOptions) {
            shipmentForm.get('shipmentDetails').get('villageLoading').patchValue(true);
            const params = this.createParams('', 1, EXCEL_IMPORT_CONSTANTS.GENERAL_ADDRESS_PAGE_SIZE);
            if (shipmentForm?.value?.shipmentDetails?.receiverCity?.id) {
                params['cityId'] = shipmentForm?.value?.shipmentDetails?.receiverCity?.id;
            }
            this.zonesService.getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES,
                params).subscribe(
                (response: any) => {
                    this.isGeneralVillageOptions = true;
                    this.villageOptions = response.data;
                    shipmentForm.get('shipmentDetails').get('villageLoading').patchValue(false);
                }, (error) => {
                    shipmentForm.get('shipmentDetails').get('villageLoading').patchValue(false);
                    console.error(error);
                }
            );
        }
    }

    public clearCustomerOptions() {
        if (!this.isGeneralCustomerOptions) {
            this.customerOptions = [];
        }
    }

    public getCustomerOptions(shipmentForm: FormGroup) {
        if (!this.isGeneralCustomerOptions) {
            shipmentForm.get('shipmentDetails').get('customerLoading').patchValue(true);
            this.usersService
                .searchCustomers(this.createParams('', 1, EXCEL_IMPORT_CONSTANTS.GENERAL_CUSTOMER_PAGE_SIZE), false, true).subscribe(
                (response: any) => {
                    this.isGeneralCustomerOptions = true;
                    this.customerOptions = response.customers;
                    shipmentForm.get('shipmentDetails').get('customerLoading').patchValue(false);
                }, (error) => {
                    shipmentForm.get('shipmentDetails').get('customerLoading').patchValue(false);
                    console.error(error);
                }
            );
        }
    }

    public swapSenderDataAndRecieverData(data: any) {
        const swappedData = JSON.parse(JSON.stringify(data));

        // Reciver
        swappedData['pkg']['receiverName'] = data.pkg.senderFirstName + ' ' + data.pkg.senderLastName;
        swappedData['pkg']['receiverFirstName'] = data.pkg.senderFirstName;
        swappedData['pkg']['receiverLastName'] = data.pkg.senderLastName;
        swappedData['pkg']['businessReceiverName'] = data.pkg.businessSenderName ? data.pkg.businessSenderName : '';
        swappedData['pkg']['receiverPhone'] = data.pkg.senderPhone;
        swappedData['pkg']['receiverPhone2'] = data.pkg.senderPhone2;
        swappedData['pkg']['receiverAuthorizedGovRegistrationNumber'] = data.pkg.senderAuthorizedGovRegistrationNumber;

        // Sender
        swappedData['pkg']['senderName'] = data.pkg.receiverName;
        swappedData['pkg']['senderFirstName'] = data.pkg.receiverFirstName;
        swappedData['pkg']['senderLastName'] = data.pkg.receiverLastName;
        swappedData['pkg']['senderEmail'] = data.pkg.email;
        swappedData['pkg']['senderPhone'] = data.pkg.receiverPhone;
        swappedData['pkg']['senderPhone2'] = data.pkg.receiverPhone2;
        swappedData['pkg']['businessSenderName'] = data.pkg.businessReceiverName ? data.pkg.businessReceiverName : '';
        swappedData['pkg']['senderAuthorizedGovRegistrationNumber'] = data.pkg.receiverAuthorizedGovRegistrationNumber;

        // origin address
        swappedData['originAddress'] = {...data.destinationAddress};

        // origin address
        swappedData['destinationAddress'] = {...data.originAddress};

        return swappedData;
    }

    public changeShipmentEnable(shipment: FormGroup) {
        if (shipment.get('enabled').value === true) {
            shipment.get('shipmentDetails').enable();
            this.totalEnabledShipments++;
        } else {
            shipment.get('shipmentDetails').disable();
            this.totalEnabledShipments--;
        }
    }

    submitForm() {
        this.translateService.get([
            'ALERTS.CONFIRM_IMPORT_EXCEL',
            'GENERAL.YES', 'GENERAL.NO'
        ]).subscribe((values) => {
            this.confirmationService.confirm({
                message: values['ALERTS.CONFIRM_IMPORT_EXCEL'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.submitPackages();
                }
            });
        });
    }

    public async submitPackages() {
        const requestObservables = [];
        this.isLoading = true;
        this.totalFailed = 0;

        let addMessage = '';
        let errorMessage = '';
        this.translateService.get(
            ['ALERTS.EXCEL_IMPORT_SUCCESSFULLY', 'ALERTS.EXCEL_IMPORT_ERRORS']
        )
            .subscribe(
                (data) => {
                    addMessage = data['ALERTS.EXCEL_IMPORT_SUCCESSFULLY'];
                    errorMessage = data['ALERTS.EXCEL_IMPORT_ERRORS'];
                }
            );

        from(this.shipmentsArray.controls.filter(control => {
            const controlGroup = control as FormGroup;
            const controlForm = controlGroup.get('shipmentDetails') as FormGroup;
            return controlForm.get('submitted').value === false && controlGroup.get('enabled').value === true;
        })).pipe(
            concatMap((row) => {
                const shipmentRow = row as FormGroup;
                const shipmentForm = shipmentRow.get('shipmentDetails') as FormGroup;
                if (shipmentForm.get('submitted').value === false && shipmentRow.get('enabled').value === true) {
                    shipmentForm.get('submitting').patchValue(true);
                    const requestObservable = this.shipmentsService.requestPackageSummarized(this.getPackage(shipmentForm)).pipe(share(),
                        catchError((error) => {
                            if (!this.errorMessage) {
                                this.errorMessage = errorMessage;
                            }
                            if (!shipmentForm.get('submitting').value) {
                                this.totalFailed++;
                            }
                            shipmentForm.get('errorMessage').patchValue(error.error.error);
                            shipmentForm.get('submitting').patchValue(false);
                            shipmentForm.get('failed').patchValue(true);
                            return empty();
                        }));
                    requestObservable.subscribe(
                        (res: any) => {
                            if (!this.successMessage) {
                                this.successMessage = addMessage;
                            }
                            shipmentForm.get('submitting').patchValue(false);
                            shipmentForm.get('submitted').patchValue(true);
                            shipmentForm.get('failed').patchValue(false);
                            shipmentForm.get('errorMessage').patchValue(null);
                            shipmentRow.disable();
                            this.addedPackages.push(res.id);
                            this.totalSubmitted++;
                            this.totalEnabledShipments--;
                        }
                    );
                    requestObservables.push(requestObservable);
                    return requestObservable;
                }
            })
        ).subscribe(
            () => {
            }, () => {
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
                if (this.totalFailed === 0) {
                    this.errorMessage = undefined;
                }
            }
        );
    }

    public getServiceTypes(search?, shipmentForm?: FormGroup, cid?) {
        let customerId = null;
        let query;
        if (!cid) {
            if (!shipmentForm || !shipmentForm.get('customerInfo').value.id) {
                return;
            }
            customerId = shipmentForm.get('customerInfo').value.id;
        } else {
            customerId = cid;
        }

        shipmentForm.get('serviceTypeLoading').patchValue(true);
        query = '?page=1&pageSize=50' + '&customerId=' + customerId;
        if (search) {
            query += '&search=' + search;
        }
        this.serviceTypesService.getServiceTypesDropdown(query).subscribe(
            (res: ServiceTypeModel[]) => {
                this.customersServiceTypesOptions[customerId] = res;
                if (typeof shipmentForm.get('serviceType').value == 'string') {
                    const serviceTypeStr = shipmentForm.get('serviceType').value;
                    const list = this.customersServiceTypesOptions[customerId].filter(serviceType => serviceType.name.trim().toLowerCase() === serviceTypeStr.toLowerCase().trim()
                        || serviceType.arabicName.trim().toLowerCase() === serviceTypeStr.toLowerCase().trim());
                    if (list.length > 0) {
                        shipmentForm.get('serviceType').patchValue(list[0]);
                        this.onChangeServiceType(shipmentForm, true);
                    } else {
                        shipmentForm.get('serviceType').patchValue(null);
                    }
                }
                if (shipmentForm) {
                    shipmentForm.get('serviceTypeLoading').patchValue(false);
                }
            }, error => {
                if (shipmentForm) {
                    shipmentForm.get('serviceTypeLoading').patchValue(false);
                }
                console.error(error);
            }
        );
    }

    trackVehicleTypeById(index: number, item: VehicleTypeModel) {
        return item.id;
    }

    onChangeServiceType(shipmentForm: FormGroup, patchNewValue) {
        const newServiceType = shipmentForm.get('serviceType').value;
        if (newServiceType) {
            shipmentForm.get('vehicleType').setValidators([Validators.required]);
            // shipmentForm.get('vehicleType').patchValue(null);
            shipmentForm.get('vehicleType').updateValueAndValidity();
            if (!this.vehicleTypesOptions[newServiceType.id]) {
                shipmentForm.get('vehicleTypeLoading').patchValue(true);
                this.serviceTypesService.getVehicleTypes(newServiceType.id, '?page=1&pageSize=50')
                    .subscribe((response: VehicleTypeModel[]) => {
                        this.vehicleTypesOptions[newServiceType.id] = response;
                        shipmentForm.get('vehicleTypeLoading').patchValue(false);
                        if (patchNewValue) {
                            this.handleFillVehicleType(shipmentForm, response);
                        } else {
                            shipmentForm.get('vehicleType').patchValue(null);
                        }
                    }, () => {
                        shipmentForm.get('vehicleTypeLoading').patchValue(false);
                    });
            }
        } else {
            shipmentForm.get('vehicleType').clearValidators();
            shipmentForm.get('vehicleType').patchValue(null);
            shipmentForm.get('vehicleType').updateValueAndValidity();
        }
    }

    handleFillVehicleType(shipmentForm, allVehicleTypes) {
        if (typeof shipmentForm.get('vehicleType').value == 'string') {

            let vehicleTypeMatchIndex = -1;
            const vehicleTypeStr = shipmentForm.get('vehicleType').value;
            allVehicleTypes.some((vehicleType, index) => {
                if (vehicleType.name.trim().toLowerCase() === vehicleTypeStr.trim().toLowerCase() ||
                    vehicleType.arabicName.trim() === shipmentForm.get('vehicleType').value.trim()) {
                    vehicleTypeMatchIndex = index;
                    return true;
                }
                return false;
            });
            shipmentForm.get('vehicleType').patchValue(allVehicleTypes[vehicleTypeMatchIndex]);
        }
    }

    getCustomerServiceOptions(shipment: any) {
        shipment.get('shipmentDetails').get('serviceType').patchValue(null);

        let customer: any = {};
        if (shipment && shipment.value && shipment.value.shipmentDetails && shipment.value.shipmentDetails.customerInfo) {
            customer = shipment.value.shipmentDetails.customerInfo;
        }
        let options = [];
        if (!this.isLcl && !this.isPricingPerServiceTypeEnabled) {
            if (customer.serviceTypes) {
                this.translateService.get(customer.serviceTypes).subscribe(values => {
                    const userOptions = Object.keys(values).map(translationKey => {
                        return {
                            label: values[translationKey],
                            value: translationKey
                        };
                    });
                    options = userOptions;
                });
            } else {
                options = this.shipmentServicesOptions;
            }
        }
        this.userServiceOptionMap[customer.id] = options;
        // return options;
    }


    getCustomerShipmentOptions(shipment: any) {
        shipment.get('shipmentDetails').get('shipmentType').patchValue(null);

        let customer: any = {};
        if (shipment && shipment.value && shipment.value.shipmentDetails && shipment.value.shipmentDetails.customerInfo) {
            customer = shipment.value.shipmentDetails.customerInfo;
        }
        let options = [];
        if (this.isShowDynamicServiceTypes) {
            // shipment.get('serviceTypeLoading').patchValue(true);
            const customerId = customer.id;
            if (!customerId) {
                return;
            }
            let query = '?page=1&pageSize=50' + '&customerId=' + customerId;
            this.serviceTypesService.getServiceTypesDropdown(query).subscribe(
                (res: ServiceTypeModel[]) => {
                    // this.serviceTypesOptions = res;
                    options = this.shipmentTypesOptions;
                    this.userServiceOptionMap[customer.id] = res;
                    this.customersServiceTypesOptions[customer.id] = res;
                    this.userShipmentOptionMap[customer.id] = options;

                    if (shipment) {
                        shipment.get('serviceTypeLoading').patchValue(false);
                    }
                }, error => {
                    if (shipment) {
                        shipment.get('serviceTypeLoading').patchValue(false);
                    }
                }
            );
        } else if (!this.isLcl) {
            if (customer && customer.shipmentTypes) {
                this.translateService.get(customer.shipmentTypes).subscribe(values => {
                    options = Object.keys(values).map(translationKey => {
                        return {
                            label: values[translationKey],
                            value: translationKey
                        };
                    });
                });
            } else {
                options = this.shipmentTypesOptions;
            }
        }
        this.userShipmentOptionMap[customer.id] = options;
    }

    printAddedPackages() {
        this.isPrinting = true;
        this.shipmentsService.downloadMultiple(this.addedPackages).subscribe(
            (res: { url: string }) => {
                this.isPrinting = false;
                window.open(res.url, '_blank');
                this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});

            }, error => {
                this.isPrinting = false;
                console.error(error);
            }
        );
    }

    shipmentTypeChanged($event: any, shipment: any) {
        if ($event.value != 'COD') {
            shipment.get('shipmentDetails')?.get('codFees')?.patchValue('0');
        }
        if (['BRING', 'SWAP'].indexOf($event.value) !== -1) {
            shipment.get('shipmentDetails').get('codCollectionType').patchValue('COLLECT');
            shipment.get('shipmentDetails').get('codCollectionType').updateValueAndValidity();
        }

    }

    private initServiceTypesForCustomer(customer: any) {
        const customerId = customer.id;
        let shipmentServicesOptions = customer.serviceTypes;

        if (customerId && shipmentServicesOptions && shipmentServicesOptions.length) {
            shipmentServicesOptions = (shipmentServicesOptions).map(translationKey => {
                return {
                    label: this.translateService.instant(translationKey),
                    value: translationKey
                };
            });

            this.userServiceOptionMap[customerId] = shipmentServicesOptions;
        }
    }

    private initShipmentTypesForCustomer(customer: any) {
        const customerId = customer.id;
        let shipmentTypesOptions = customer.shipmentTypes;

        if (customerId && shipmentTypesOptions && shipmentTypesOptions.length) {
            shipmentTypesOptions = (shipmentTypesOptions).map(translationKey => {
                return {
                    label: this.translateService.instant(translationKey),
                    value: translationKey
                };
            });

            this.userShipmentOptionMap[customerId] = shipmentTypesOptions;
        }
    }

    private patchVillageFromCity(shipmentForm: FormGroup, shipment) {

        shipmentForm.get('receiverStreetAddress2').patchValue(shipmentForm.get('receiverStreetAddress').value);
        shipmentForm.get('receiverStreetAddress').patchValue(shipment.shipmentInfo.receiverVillage);
        shipmentForm.get('receiverVillageName').patchValue(shipment.shipmentInfo.receiverCity);
        shipmentForm.get('villageLoading').patchValue(true);
        const villageObservable = this.zonesService
            .getZones(ADMINISTRATION_CONSTANTS.ZONE_GET_SELECTED_COMPANY_VILLAGES,
                this.createParams(shipment.shipmentInfo.receiverCity), true);
        villageObservable.subscribe(
            (response: any) => {
                if (response.data.length <= EXCEL_IMPORT_CONSTANTS.MAX_OPTIONS_TO_SELECT_ADDRESS &&
                    response.data.length > 0) {
                    let matchIndex = 0;
                    response.data.some((village, index) => {
                        if (village.name.trim().toLowerCase() === shipment.shipmentInfo.receiverCity.trim().toLowerCase()) {
                            matchIndex = index;
                            return true;
                        }
                        return false;
                    });
                    shipmentForm.get('receiverVillage').patchValue(response.data[matchIndex]);
                }
                shipmentForm.get('villageLoading').patchValue(false);
                shipmentForm.updateValueAndValidity();
            }
        );
    }

    private initCodCollectionMethod() {
        console.log(this.codCollectionMethods);
        this.codCollectionMethods = this.userService.getCodCollectionMethods().map(type => {
            return {
                label: this.translateService.instant(`COD_COLLECTION_METHODS_LIST.${type}`),
                value: type
            };
        });
    }

    private getCodCollectionMethod(shipmentForm: any) {
        let shipmentTypeStr = shipmentForm.get('codCollectionMethod').value;
        const arOptions = [
            'نقدا',
            'شيك',
            'حوالة بنكية',
            'دفع مسبق',
            'محفظة الكترونية',
            'بطاقة إئتمانية'
        ];
        const enOptions = [
            'Cash',
            'Cheque',
            'Bank Transfer',
            'Prepaid',
            'Digital Wallet',
            'Card Payment'
        ];
        const mapSet = {};
        if (this.currentLang === 'ar') {
            mapSet['Cash'] = 'نقدا';
            mapSet['Cheque'] = 'شيك';
            mapSet['Bank Transfer'] = 'حوالة بنكية';
            mapSet['Prepaid'] = 'دفع مسبق';
            mapSet['Digital Wallet'] = 'محفظة الكترونية';
            mapSet['Card Payment'] = 'طاقة إئتمانية';
        } else {
            mapSet['نقدا'] = 'Cash';
            mapSet['شيك'] = 'Cheque';
            mapSet['حوالة بنكية'] = 'Bank Transfer';
            mapSet['دفع مسبق'] = 'Prepaid';
            mapSet['محفظة الكترونية'] = 'Digital Wallet';
            mapSet['طاقة إئتمانية'] = 'Card Payment';
        }

        const options = this.codCollectionMethods.map(v => v.label);
        shipmentTypeStr = this.findMostSimilarText(shipmentTypeStr, [...options, ...(this.currentLang === 'ar' ? enOptions : arOptions)]);
        if (mapSet[shipmentTypeStr]) {
            shipmentTypeStr = mapSet[shipmentTypeStr];
        }

        const shipmentType = this.codCollectionMethods.find(v => v.label === shipmentTypeStr || v.value === shipmentTypeStr);
        shipmentForm.get('codCollectionMethod').patchValue(shipmentType?.value);
    }

    private getCodCollectionType(shipmentForm: any) {
        let shipmentTypeStr = shipmentForm.get('codCollectionType').value;
        const arOptions = [
            'التحصيل من المستلم',
            'الدفع للمستلم'
        ];
        const enOptions = [
            'Collect From Receiver',
            'Pay For Receiver'
        ];
        const mapSet = {};
        if (this.currentLang === 'ar') {
            mapSet['Collect From Receiver'] = 'التحصيل من المستلم';
            mapSet['Pay For Receiver'] = 'الدفع للمستلم';
        } else {
            mapSet['التحصيل من المستلم'] = 'Collect From Receiver';
            mapSet['الدفع للمستلم'] = 'Pay For Receiver';
        }

        const options = this.collectionTypes.map(v => v.label);
        shipmentTypeStr = this.findMostSimilarText(shipmentTypeStr, [...options, ...(this.currentLang === 'ar' ? enOptions : arOptions)])
        ;
        if (mapSet[shipmentTypeStr]) {
            shipmentTypeStr = mapSet[shipmentTypeStr];
        }

        const shipmentType = this.collectionTypes.find(v => v.label === shipmentTypeStr || v.value === shipmentTypeStr);
        shipmentForm.get('codCollectionType').patchValue(shipmentType?.value);
    }

    setCodFees(shipment: any) {
        const val = shipment.get('shipmentDetails').value;
        if (this.showCodFees && val.shipmentType === 'COD' && val.customerInfo) {
            shipment.get('shipmentDetails').get('codFees').patchValue(val.customerInfo.codFees);
        }
    }

    addToBundle() {
        this.translateService.get(['ACCOUNTING.FINISH_COLLECT_BUNDLE', 'GENERAL.YES', 'GENERAL.NO'])
            .subscribe((res: any) => {
                this.confirmationService.confirm({
                    message: res['ACCOUNTING.FINISH_COLLECT_BUNDLE'],
                    accept: () => {
                        this.collectBundle();
                    },
                    reject: () => {

                    },
                    acceptLabel: res['GENERAL.YES'],
                    rejectLabel: res['GENERAL.NO']
                });
            });
    }

    public collectBundle() {
        const modal = this.modalService.open(ChooseCustomerComponent, <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
        });
        modal.componentInstance.fromBundle = true;
        modal.result.then(
            (status) => {
                if (status.isSuccess && status.result) {
                    const data = status.result;
                    const body = {ids: this.addedPackages};
                    if (data.sourceHubId) {
                        body['sourceHubId'] = data.sourceHubId;
                        body['destinationHubId'] = data.destinationHubId;
                    } else {
                        body['customerId'] = data.customerId;

                        if (data.destinationHubId) {
                            body['destinationHubId'] = data.destinationHubId;
                        } else {
                            body['destinationAddress'] = data.destinationAddress;
                        }
                    }
                    this.isLoading = true;
                    this.shipmentsService.createBundle(body).subscribe(
                        (response: any) => {
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')
                            });
                            this.isLoading = false;
                        }, error => {
                            console.error(error);
                            this.isLoading = false;
                        }
                    );
                }
            });
    }

    findMostSimilarText(inputText, options) {
        let minDistance = Infinity;
        let mostSimilarText = '';

        for (const option of options) {
            const distance = this.jaroWinklerDistance(inputText, option);
            if (distance < minDistance) {
                minDistance = distance;
                mostSimilarText = option;
            }
        }

        return mostSimilarText;
    }

    calculateLevenshteinDistance(str1, str2) {
        const m = str1.length;
        const n = str2.length;
        const dp = new Array(m + 1).fill(null).map(() => new Array(n + 1).fill(0));

        for (let i = 1; i <= m; i++) {
            dp[i][0] = i;
        }

        for (let j = 1; j <= n; j++) {
            dp[0][j] = j;
        }

        for (let i = 1; i <= m; i++) {
            for (let j = 1; j <= n; j++) {
                const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
                dp[i][j] = Math.min(
                    dp[i - 1][j] + 1,
                    dp[i][j - 1] + 1,
                    dp[i - 1][j - 1] + cost
                );
            }
        }
        return dp[m][n];
    }

    jaroWinklerDistance(str1, str2) {
        const maxLength = Math.max(str1.length, str2.length);

        if (maxLength === 0) {
            return 1.0; // Both strings are empty, so they are considered identical.
        }

        // Define the matching distance threshold.
        const matchThreshold = Math.floor(maxLength / 2) - 1;

        // Create arrays to hold matching characters.
        const matches1 = new Array(str1.length).fill(false);
        const matches2 = new Array(str2.length).fill(false);

        // Count the number of matching characters.
        let matchCount = 0;
        for (let i = 0; i < str1.length; i++) {
            for (let j = Math.max(0, i - matchThreshold); j < Math.min(str2.length, i + matchThreshold + 1); j++) {
                if (!matches2[j] && str1[i].toLowerCase() === str2[j].toLowerCase()) {
                    matches1[i] = true;
                    matches2[j] = true;
                    matchCount++;
                    break;
                }
            }
        }

        if (matchCount === 0) {
            return Infinity; // No matches found.
        }

        // Count transpositions (swapped, but matching characters).
        let transpositions = 0;
        let k = 0;
        for (let i = 0; i < str1.length; i++) {
            if (matches1[i]) {
                while (!matches2[k]) {
                    k++;
                }
                if (str1[i] !== str2[k]) {
                    transpositions++;
                }
                k++;
            }
        }

        // Calculate Jaro distance.
        const jaroDistance = (matchCount / str1.length + matchCount / str2.length + (matchCount - transpositions / 2) / matchCount) / 3;

        // Calculate Jaro-Winkler distance.
        const scalingFactor = 0.1; // Typically set to 0.1
        const prefixLength = Math.min(4, str1.length);

        return 1 / jaroDistance;
    }
}
