import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-alert-component',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  private readonly ALERT_DURATION = 20000;

  /**
   * The list of alerts.
   * alertTypes: 'warning, success, danger, info'
   */
  public alerts: any = [];

  /**
   * Subscription of alertChange.
   * to be destroyed later.
   */
  private alertSubscription;

  /**
   * Track the testing Alert IntervalID to be destroyed later.
   */
  private testingAlertsInterval;

  constructor(
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.initAlertListener();
  }

  private initAlertListener() {
    this.alertSubscription = this.alertService.alert$.subscribe(
      (alert: any) => {
        setTimeout(() => {
            alert.state = 'init';
        }, 0);
        this.alerts.push(alert);
        this.setAlertDuration(alert);
      }
    );
  }

  private setAlertDuration(alert) {
    setTimeout(() => {
      this.removeAlert(alert);
    }, this.ALERT_DURATION);
  }

  ngOnDestroy() {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }

  public removeAlert(alert, $event?) {
    if ($event) {
      $event.stopPropagation();
    }

    alert.state = 'out';
    setTimeout(() => {
      this.alerts = this.alerts.filter(tempAlert => tempAlert !== alert);
    }, 700);
  }
}
