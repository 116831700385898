import {Component, OnInit} from '@angular/core';
import {ShippingLinesService} from '../../services/shipping-lines.service';
import {ADMINISTRATION_CONSTANTS} from '../../administration-constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddShippingLineComponent} from '../add-shipping-line/add-shipping-line.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {ManageShippingLineDriverComponent} from '../manage-shipping-line-driver/manage-shipping-line-driver.component';
import {UserService} from '../../../shared/services/user.service';
declare let $;
@Component({
    selector: 'app-shipping-lines',
    templateUrl: './shipping-lines.component.html',
    styleUrls: ['./shipping-lines.component.scss']
})
export class ShippingLinesComponent implements OnInit {
    public addingNewLine = false;
    public newLineName = '';
    public expectedDeliveryDaysNumber = null;
    public selectedSenderShipmentLine = null;
    public selectedReceiverShipmentLine = null;
    public senderShipmentLines = [];
    public receiverShipmentLines = [];
    public selectedIndex = 0;
    public type = 'SENDER';

    public selectedVillagesUrl = '';
    public regionsURL = ADMINISTRATION_CONSTANTS.ZONE_GET_REGIONS;
    public regionID: number = 0;
    public cityID: number = 0;
    public citiesURL = '';
    public villagesURL = '';
    public selectedSenderVillages = [];
    public selectedReceiverVillages = [];
    public driver = {};
    public toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    isAllowAddingMoreThanOneDriverToShippingLine;
    private drivers: any;
    private shippingLineId: any;
    private shippingLine: any;
    loadingShippingLine = false;
    private selectedLanguage: string;
    constructor(private shippingLinesService: ShippingLinesService,
                private modalService: NgbModal,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private userService: UserService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.selectedLanguage = this.translateService.currentLang;
        this.isAllowAddingMoreThanOneDriverToShippingLine = this.userService.userInfo.isAllowAddingMoreThanOneDriverToShippingLine;
        this.fetchShippingLines();
    }

    private fetchShippingLines(lineName = '') {
        this.loadingShippingLine = true;
        const type = this.selectedIndex ? 'RECEIVER' : 'SENDER';
        this.shippingLinesService.getShippingLines(type).subscribe((response: any) => {
            this.loadingShippingLine = false;
            if (this.selectedIndex === 0) {
                this.senderShipmentLines = response;
            } else {
                this.receiverShipmentLines = response;
            }
            if (response && response.length) {
                if (this.selectedIndex === 0) {
                    if (lineName) {
                        this.selectedSenderShipmentLine = response.find(({name}) => name === lineName);
                    } else {
                        if (this.selectedSenderShipmentLine === null) {
                            this.selectedSenderShipmentLine = response[0];
                        }
                    }
                } else {
                    if (lineName) {
                        this.selectedReceiverShipmentLine = response.find(({name}) => name === lineName);
                    } else {
                        if (this.selectedReceiverShipmentLine === null) {
                            this.selectedReceiverShipmentLine = response[0];
                        }
                    }
                }
                this.onLineSelected();
            }
        });
    }

    public onTabChange(event) {
        this.selectedIndex = event.index;
        this.type = this.selectedIndex ? 'RECEIVER' : 'SENDER';
        this.fetchShippingLines();
    }

    addNewLineButton() {
        const modal = this.modalService.open(AddShippingLineComponent, {backdrop: 'static', windowClass: 'shipping-line', size: 'lg'});
        modal.componentInstance.type = this.type;
        modal.result.then(
            (data: { isSuccess: boolean, name: string }) => {
                if (data.isSuccess) {
                    this.fetchShippingLines(data.name);
                    this.addNewLineCancel();
                }
            }
        ).catch(
            (error) => {
            }
        );

    }

    addNewLineCancel() {
        if (this.selectedIndex) {
            this.selectedReceiverVillages = [];
        } else {
            this.selectedSenderVillages = [];
        }
        this.onLineSelected();
    }

    public editLine() {
        const shippingLineId = this.selectedIndex ? this.selectedReceiverShipmentLine.id : this.selectedSenderShipmentLine.id;
        const modal = this.modalService.open(AddShippingLineComponent, {backdrop: 'static', windowClass: 'shipping-line', size: 'lg'});
        modal.componentInstance.type = this.type;
        modal.componentInstance.shippingLineId = shippingLineId;
        modal.result.then(
            (data: { isSuccess: boolean, name: string }) => {
                if (data.isSuccess) {
                    this.fetchShippingLines(data.name);
                    this.addNewLineCancel();
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    public onLineSelected() {
        this.driver = null;
        this.loadingShippingLine = true;
        const shippingLineId = this.selectedIndex ? this.selectedReceiverShipmentLine.id : this.selectedSenderShipmentLine.id;
        this.shippingLinesService.getShippingLine(shippingLineId).subscribe((response: any) => {
            this.loadingShippingLine = false;
            if (response.drivers && response.drivers.length) {
                const driver = response.drivers[0];
                this.driver = {
                    label: driver.firstName + ' ' + driver.lastName,
                    value: response.id
                };
            }

            if (this.selectedIndex) {
                this.selectedReceiverVillages = response.villages.map((village) => {
                    return village.id;
                });
                this.expectedDeliveryDaysNumber = response.expectedDeliveryDaysNumber;
            } else {

                if(this.isAllowAddingMoreThanOneDriverToShippingLine) {
                    this.drivers = response.drivers;
                    this.shippingLineId = shippingLineId;
                    this.shippingLine = response;
                }

                this.selectedSenderVillages = response.villages.map((village) => {
                    return village.id;
                });
            }
        });
        this.selectedVillagesUrl = ADMINISTRATION_CONSTANTS.EDIT_SHIPPING_LINE.replace(
            '{shippingLineId}', shippingLineId);
    }

    onRegionChosen($event: any) {
        const id = $event.id;
        if (id) {
            this.citiesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_REGION_CITIES.replace('{regionId}', id);
            this.villagesURL = '';
            this.regionID = id;
        }
    }

    onCityChosen($event: any) {
        const id = $event.id;
        if (id) {
            this.villagesURL = ADMINISTRATION_CONSTANTS.ZONE_GET_CITY_VILLAGES.replace('{cityId}', id);
            this.cityID = id;
        }
    }

    onVillageChecked($event: any) {
        const selectedShippingLineId = this.selectedIndex ? this.selectedReceiverShipmentLine.id : this.selectedSenderShipmentLine.id;
        if ($event.checked) {
            this.shippingLinesService.addVillageToLine(selectedShippingLineId, $event.id, this.type).subscribe(() => {
                if (this.selectedIndex) {
                    this.selectedReceiverVillages.push({id: $event.id});
                } else {
                    this.selectedSenderVillages.push({id: $event.id});
                }
                this.addNewLineCancel();
            }, (error) => {
                this.confirmationService.confirm({
                    message: (error.error.error),
                    rejectVisible: false,
                    accept: () => {
                    },
                    acceptLabel: 'OK'
                });
                this.addNewLineCancel();
            });
        } else {
            this.shippingLinesService.removeVillageFromLine(selectedShippingLineId, $event.id, this.type).subscribe(() => {
                if (this.selectedIndex) {
                    this.selectedReceiverVillages.splice(this.selectedReceiverVillages.indexOf($event.id), 1);
                } else {
                    this.selectedSenderVillages.splice(this.selectedSenderVillages.indexOf($event.id), 1);
                }
                this.addNewLineCancel();
            }, (error) => {
                this.addNewLineCancel();
            });
        }
    }
    public  onDriverSelected($event) {
        let message = '';
        let yes = '';
        this.translateService.get(
            ['ALERTS.ASSIGN_DRIVER_ID_TO_SHIPPING_LINE', 'ALERTS.YES_ASSIGN_DRIVER_ID_TO_SHIPPING_LINE', 'GENERAL.NO']
        )
            .subscribe(
                (data) => {
                    message = data['ALERTS.ASSIGN_DRIVER_ID_TO_SHIPPING_LINE'];
                    yes = data['ALERTS.YES_ASSIGN_DRIVER_ID_TO_SHIPPING_LINE'];
                }
            );
        this.confirmationService.confirm({
            message: (message),
            rejectVisible: false,
            accept: () => {
                const body = this.selectedSenderShipmentLine;
                if ($event) {
                    body.drivers = [{'id': $event, 'priority': 1}];
                } else {
                    body.drivers = [];
                }
                this.shippingLinesService.editShipmentLine(this.selectedSenderShipmentLine.id, body, '').subscribe(res => {
                    this.messageService.add({severity: 'success', detail: yes});
                });
            },
            acceptLabel: 'OK'
        });

    }

    openManageDriverForShippingLine() {
        const modal = this.modalService.open(ManageShippingLineDriverComponent, {
            backdrop: 'static',
            size: 'xl'
        });

        modal.componentInstance.oldTarget = this.drivers;
        modal.componentInstance.shippingLineId = this.shippingLineId;
        modal.componentInstance.shippingLine = this.shippingLine;
        modal.result.then((res => {
            if (res && res.isSuccess) {
                this.fetchShippingLines(this.selectedIndex ? this.selectedReceiverShipmentLine.name : this.selectedSenderShipmentLine.name);
            }
        }));
    }

    handlePosition(dropdown: any) {
        setTimeout(() => {
            const panel = $('.dropdown-fix-position')[0];
            if (this.selectedLanguage === 'ar') {
                $(panel).addClass('right-0 left-initial');
            }
        }, 30);

    }
}
