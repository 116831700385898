import {Component} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from "../accounting-constants";
import {TemplateTopActionsSettingsModel} from "../models/template-top-actions-settings.model";
import {AccountingTemplateFiltersModel} from "../models/accounting-template-filters.model";
@Component({
    selector: 'delivered-cod',
    templateUrl: './delivered-cod.component.html',
    styleUrls: ['./delivered-cod.component.scss']
})
export class DeliveredCodComponent {
    title = ACCOUNTING_CONSTANTS.DELIVERED_COD.TITLE;
    info = ACCOUNTING_CONSTANTS.DELIVERED_COD.INFO;
    apiUrl = ACCOUNTING_CONSTANTS.DELIVERED_COD.API;
    AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.DELIVERED_COD.ROW_ACTIONS_SETTINGS;
    topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.DELIVERED_COD.TOP_ACTIONS_SETTINGS
    filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.DELIVERED_COD.FILTERS_SETTINGS;
    dateFiltersList = ACCOUNTING_CONSTANTS.DELIVERED_COD.DATE_FILTERS_LIST;
}
