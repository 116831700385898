import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContainersService} from '../../../containers/services/containers.service';
import {UserModel} from '../../../shared/models/user.model';
import {USERS_CONSTANTS} from '../../services/constants';
import {UsersService} from '../../services/users.service';
import {UserService} from '../../../shared/services/user.service';

@Component({
    selector: 'app-user-details.component',
    templateUrl: 'user-details.component.html',
    styleUrls: ['user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
    public currentLang: string;
    public isUser: boolean;
    public isArchived: boolean;
    public userImageApi;
    public isLcl: boolean;

    @Input() isShow: boolean;
    @Input() user: UserModel;
    @Input() canEdit: boolean;
    @Input() canResetPassword: boolean;
    @Input() canRemove: boolean;
    @Input() deleteLoader = false;
    @Input() _apiPath: string;
    @Input()
    set apiPath(val) {
        if (val) {
            this._apiPath = val;
            this.isUser = this.apiPath === 'getUsers' || this.apiPath === 'getArchivedUsers';
            this.isArchived = this.apiPath === 'getArchivedUsers' || this.apiPath === 'getArchivedCustomers';        }
    }

    get apiPath() {
        return this._apiPath;
    }

    @Output() onCloseUserDetails = new EventEmitter();
    @Output() onUpdateUser = new EventEmitter();
    @Output() onRemoveUser = new EventEmitter();
    @Output() onResetPassword = new EventEmitter();


    constructor(private containerService: ContainersService,
                private translateService: TranslateService,
                private messageService: MessageService,
                private userService: UserService,
                private usersService: UsersService) {}
    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isLcl = this.userService.userInfo.isLcl;
        this.isUser = this.apiPath === 'getUsers' || this.apiPath === 'getArchivedUsers';
        this.userImageApi = USERS_CONSTANTS.UPLOAD_IMAGE_URL;
        this.isArchived = this.apiPath === 'getArchivedUsers' || this.apiPath === 'getArchivedCustomers';
    }
    public onClose() {
        this.onCloseUserDetails.emit(false);
    }
    public getAddress(address) {
        if (!address) {
            return;
        }
        return address.village + ' - ' + address.city + ' - ' + address.region;
    }

    public onUpdate() {
        this.onClose();
        this.onUpdateUser.emit({user: this.user});
    }
    public onRemove() {
        this.onRemoveUser.emit({user: this.user});
    }
    public resetPassword() {
        this.onClose();
        this.onResetPassword.emit({user: this.user});
    }
    public setCustomerConfiguration(configurationType, value) {
        this.usersService.setCustomerConfiguration(this.user.id, configurationType).subscribe(
            (response: any) => {
                const messageComplement = value ? '_ENABLED_SUCCESSFULLY' : '_DISABLED_SUCCESSFULLY';
                this.translateService.get('ALERTS.' + configurationType + messageComplement).subscribe(
                    (translatedMessage) => {
                        this.messageService.add({severity: 'success', detail: translatedMessage});
                    }
                );
        }, error => {
                console.error(error);
            }
        );

    }

    showIsBilledSwitchButton() {
        return this.userService.userInfo.isInvoicingEnabled;
    }
    showAddDraftPackagesSetting() {
        return this.userService.userInfo.isSupportAddingDraftPackagesByCustomers;
    }
    showRequiredAttachmentsSetting() {
        return this.userService.userInfo.isAllowAddingAttachment;
    }
    isCustomerAccountManager() {
        return this.userService.userInfo.role === 'CUSTOMER_ACCOUNT_MANAGER';
    }
}
