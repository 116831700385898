import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SearchEventModel} from './models/search-event.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-search-component',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit {
    public selectedLanguage;
    /**
     *
     */
    @Output() onSearch: EventEmitter<SearchEventModel> = new EventEmitter<SearchEventModel>();

    constructor(private translateService: TranslateService) {

    }

    public onInputChange(value) {
        this.onSearch.emit({value});
    }

    ngOnInit(): void {
        this.selectedLanguage = this.translateService.currentLang;
    }
}
