export const FULFILMENT_CONSTANTS = {
    APIS: {
        GET_ORDERS_API: ':api/admin/fulfillment/orders',
        GET_ORDER_API: ':api/admin/fulfillment/order/{orderId}',
        GET_UNPICKED_ITEMS: ':api/admin/fulfillment/orders/{orderId}/unPick-item',
        EXPORT_ORDERS_EXCEL_API: ':api/admin/fulfillment/orders/excel',
        GET_WAREHOUSES: ':api/admin/hubs/warehouses',
        NEW_WAREHOUSE: ':api/admin/hubs/new',
        UPDATE_WAREHOUSE: ':api/admin/hubs/{hubId}/modify',
        UPDATE_WAREHOUSE_PRICING: ':api/admin/fulfillment/fulfillment-rates/{id}',
        GET_AREAS: ':api/admin/fulfillment/hubs/{hubId}/areas',
        NEW_AREA: ':api/admin/fulfillment/hubs/{hubId}/areas',
        GET_ROWS: ':api/admin/fulfillment/hubs/{hubId}/rows',
        NEW_ROW: ':api/admin/fulfillment/hubs/areas/{areaId}/rows',
        GET_SHELVES: ':api/admin/hubs/{hubId}/shelves',
        GET_LOCATIONS: ':api/admin/fulfillment/hubs/{hubId}/locations',
        GET_LOCATIONS_WITH_STATS: ':api/admin/fulfillment/hubs/{hubId}/locations/with-stats',
        PRINT_LOCATIONS: ':api/admin/fulfillment/rows/{rowId}/locations/barcodes/pdf',
        GET_PRODUCTS: ':api/admin/fulfillment/products',
        EXPORT_EXCEL_PRODUCT: ':api/admin/fulfillment/products/excel',
        GET_ASN_FEES: ':api/admin/fulfillment/shipping-plan/fees',
        GET_ASN_FEES_DETAILS: ':api/admin/fulfillment/shipping-plan/fees/details',
        GET_STORAGE_FEES: ':api/admin/fulfillment/storage/fees',
        GET_STORAGE_FEES_DETAILS: ':api/admin/fulfillment/storage/fees/details',
        NEW_PRODUCT: ':api/admin/fulfillment/products',
        UPDATE_PRODUCT: ':api/admin/fulfillment/products/{productId}',
        UPLOAD_PRODUCT_IMAGE: ':api/admin/fulfillment/products/{productId}/upload-image',
        UPLOAD_ITEM_IMAGE: ':api/admin/fulfillment/items/{itemId}/upload-image',
        DOWNLOAD_PRODUCTS_EXCEL_TEMPLATE: ':api/guests/import-products-template',
        GET_ITEMS: ':api/admin/fulfillment/items',
        GET_PRODUCTS_ITEMS: ':api/admin/fulfillment/productItems',
        GET_SELLABLE_ITEMS_DETAILS: ':api/admin/fulfillment/sellable-items',
        GET_ZID_PRODUCTS_ITEMS: ':api/admin/fulfillment/product-items/integration',
        GET_ZID_INVENTORY_ITEMS: ':api/admin/fulfillment/product-items/integration/{source}/warehouse/{warehouseId}/customer/{customerId}/product/{productId}',
        EXPORT_EXCEL_PRODUCTS_ITEMS: ':api/admin/fulfillment/productItems/excel',
        NEW_ITEM: ':api/admin/fulfillment/items',
        UPDATE_ITEM: ':api/admin/fulfillment/items/{itemId}',
        PRINT_ITEMS_BARCODES: ':api/admin/fulfillment/items/barcodes/pdf',
        PRINT_ZID_ITEMS_BARCODES: ':api/admin/fulfillment/product-items/integration/{source}/warehouse/{warehouseId}/customer/{customerId}/product/{productId}/pdf',
        CHANGE_ZID_ITEMS_WAREHOUSE: ':api/admin/fulfillment/product-items/integration/{source}/warehouse/{warehouseId}/customer/{customerId}/product/{productId}',
        DOWNLOAD_ITEMS_EXCEL_TEMPLATE: ':api/guests/import-items-template',
        GET_BINS: ':api/admin/fulfillment/bins',
        EXPORT_EXCEL_BINS: ':api/admin/fulfillment/bins/excel',
        EXPORT_EXCEL_TOTES: ':api/admin/fulfillment/totes/excel',
        GET_BIN: ':api/admin/fulfillment/hub/bin',
        GET_TOTE: ':api/admin/fulfillment/{toteId}/tote-detail',
        GET_TOTES: ':api/admin/fulfillment/totes',
        SCAN_BIN: ':api/admin/fulfillment/bins/{barcode}/reserve',
        CHECK_AVAILABILITY: ':api/admin/fulfillment/bins/{barcode}/check-availability',
        CHECK_LOCATION_AVAILABILITY: ':api/admin/fulfillment/locations/{barcode}/check-availability',
        GET_BIN_ITEMS: ':api/admin/fulfillment/bins/{binId}/items',
        NEW_BINS: ':api/admin/fulfillment/bins',
        NEW_TOTES: ':api/admin/fulfillment/totes',
        PRINT_BINS_BARCODES: ':api/admin/fulfillment/bins/barcode/pdf',
        PRINT_BIN_BARCODE: ':api/admin/fulfillment/bins/{binId}/barcode/pdf',
        GET_LOWEST_BIN_BARCODE: ':api/admin/fulfillment/bins/lowest-barcode',
        GET_SHIPPING_PLANS: ':api/admin/fulfillment/shipping-plan/list',
        EXPORT_EXCEL_SHIPPING_PLANS: ':api/admin/fulfillment/shipping-plan/excel',
        RECEIVE_SHIPPING_PLANS: ':api/admin/fulfillment/shipping-plan/receive',
        PACK_ORDER_ITEM: ':api/admin/fulfillment/orders/{orderId}/picked-item',
        PACK_ORDER: ':api/admin/fulfillment/fulfillment-order/pack',
        PICK_ORDER: ':api/admin/fulfillment/hub/totes/{toteId}/order-items/sort',
        GET_SHIPPING_PLAN: ':api/admin/fulfillment/shipping-plan',
        GET_SHIPPING_PLAN_HISTORY: ':api/admin/fulfillment/shipping-plan/history',
        GET_ORDER_HISTORY: ':api/admin/fulfillment/order/history',
        NEW_SHIPPING_PLAN: ':api/admin/fulfillment/shipping-plan',
        EDIT_SHIPPING_PLAN: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}',
        GET_SHIPPING_PLAN_BY_ID: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}',
        GET_DRIVERS_WITH_SHIPPING_PLANS: ':api/admin/fulfillment/drivers/by-shipping-plans',
        GET_SHIPPING_PLANS_FOR_DRIVER: ':api/admin/fulfillment/shipping-plans',
        UPDATE_PRODUCT_ACTIVATION: ':api/admin/fulfillment/products/{productId}/update-activation',
        RESERVE_BIN: ':api/admin/fulfillment/bins/{binId}',
        RESERVE_LOCATION: ':api/admin/fulfillment/locations/{locationId}',
        GET_SHIPPING_PLAN_BARCODE: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}/items-barcode/pdf',
        GET_SHIPPING_PLAN_PRODUCT_BARCODE: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}/product/{productId}/pdf',
        GET_SHIPPING_PLAN_ITEMS_STATUS: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}/items/status',
        CREATE_ORDER: ':api/admin/fulfillment/order',
        UPDATE_ORDER: ':api/admin/fulfillment/order/{id}',
        SORT_ITEM_INTO_BIN: ':api/admin/fulfillment/bins/{binId}/shipping-items/sort',
        SORT_ITEM_INTO_LOCATION: ':api/admin/fulfillment/locations/{locationId}/items/sort',
        SORT_ZID_ITEM_INTO: ':api/admin/fulfillment/product-items/integration/{source}/warehouse/{warehouseId}/customer/{customerId}/product/{productId}/sort',
        REJECT_ITEM_FROM_BIN: ':api/admin/fulfillment/bins/{binId}/shipping-items/reject',
        REJECT_ITEM_FROM_LOCATION: ':api/admin/fulfillment/locations/{locationId}/shipping-items/reject',
        GET_ORDER_BY_ID: ':api/admin/fulfillment/order/{id}',
        GET_WAREHOUSE_STOCKS: ':api/admin/hubs/warehouse-stocks',
        PRINT_SHIPPING_PLAN_POLICY_WITH_ITEMS_BARCODES: ':api/admin/fulfillment/shipping-plan/barcodes/pdf',
        PRINT_SHIPPING_PLAN_POLICY: ':api/admin/fulfillment/shipping-plan/pdf',
        PRINT_BIN_ITEMS_BARCODES: ':api/admin/fulfillment/bin/{binId}/items-barcode/pdf',
        GET_HUB_ROWS_DATA: ':api/admin/fulfillment/hubs/rows/{rowId}',
        SORT_BIN_INTO_LOCATION: ':api/admin/fulfillment/hub/locations/{locationBarcode}/bins/sort',
        GET_WAREHOUSES_RATES: ':api/admin/fulfillment/fulfillment-rates',
        ASSIGN_SHIPPING_PLAN_TO_DRIVER: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}/assign-driver',
        GET_TOTAL_LOCATIONS: ':api/admin/fulfillment/hubs/{hubId}/location-count',
        UPDATE_LOCATION_TYPE: ':api/admin/fulfillment/hubs/{hubId}/locations/type',
        UPDATE_LOCATION_DAMAGED_TYPE: ':api/admin/fulfillment/hubs/{hubId}/locations/items-type',
        DELETE_ORDER: ':api/admin/fulfillment/order/{id}',
        DELETE_ASN: ':api/admin/fulfillment/shipping-plan/{id}',
        LOG_SHIPPING_PLANE_TIME: ':api/admin/fulfillment/shipping-plan/{shippingPlanId}/sorting-hours',
        GET_INVENTORY_ITEM_DETAILS: ':api/admin/fulfillment/items/details',
        GET_ORDERS_FEES_DETAILS: ':api/admin/fulfillment/orders/fees/details',
        GET_ORDERS_FEES: ':api/admin/fulfillment/orders/fees',
        GET_INVOICES_FEES: ':api/admin/fulfillment/invoices',
        DOWNLOAD_EXCEL_TEMPLATE: ':api/guests/import-excel-template'

    },
    VALIDATION: {
        MAX_COLUMNS: 30,
        MAX_COLUMNS_FAST_ACCESS: 60,
        MAX_SHELVES: 20,
        MAX_SHELVES_FAST_ACCESS: 30,
        MAX_LOCATIONS: 20,
        MIN_COLUMNS: 1,
        MIN_SHELVES: 1,
        MIN_LOCATIONS: 1
    },
    EXCEL_IMPORT: {
        PRODUCT_SELECT_LIMIT: 10,
        ITEM_SELECT_LIMIT: 10,
        WAREHOUSE_SELECT_LIMIT: 10
    },
    BARCODE_PRINT_TYPES: [
        {
            label: 'FULFILMENT.WAREHOUSE.ACTIONS.PRINT_STICKER',
            value: 'STICKER'
        },
        {
            label: 'FULFILMENT.WAREHOUSE.ACTIONS.PRINT_A4',
            value: 'A4'
        }
    ],
    STORAGE_TYPES: [
        'BIN',
        'PALLET',
        'SHELF'
    ],
    ZONE_TYPES: [
        'DRY',
        'REFRIGERATOR',
        'FREEZER',
        'FLAMMABLE',
        'SECURED'
    ],
    SHIPMENT_TYPES_LIST: [
        'BOXES',
        'PALLETS',
        'CONTAINER'
    ],
    PACKING_TYPES_LIST: [
        'SINGLE',
        'MULTIPLE',
        'ALL_TOGETHER'
    ],
    BRING_METHODS_LIST: [
        'BY_CUSTOMER',
        'BY_COMPANY'
    ],
    AVAILABILITY_FILTER_LIST: [
        'ALL',
        'RESERVED',
        'AVAILABLE'
    ],
    STATUS: [
        'AWAITING_PICKUP',
        'ASSIGNED_TO_DRIVER',
        'PICKED_UP',
        'ARRIVED_AT_DESTINATION',
        'PARTIALLY_RECEIVED',
        'RECEIVED',
        'REJECTED',
    ],
    LOCATION_TYPES: [
        'BIN',
        'PALLET',
        'SHELF'
    ],
    REJECT_ITEMS_REASON: [
        'DAMAGED',
        'WRONG_COLOR',
        'WRONG_ITEM',
        'WRONG_SKU',
        'OTHER',
    ]
};
