<p-toast [baseZIndex]="toastZIndex">
</p-toast>
<div class="change-status-table-view d-flex flex-column h-100"  appBarcodeScanner (barcodeRead)="barcodeReaderForBundlesOnly($event)">>

    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <div>
        <div class="change-header d-flex flex-row"
             style="align-items: center;flex-wrap: wrap; justify-content: flex-end;">
        </div>
    </div>
    <div class="container-details-container overflow-auto">
        <app-shipment-tracker [isShow]="isTrackingPackage" (onCloseTracker)="closeTracker($event)" [packageBarcode]="triggeredShipment?.barcode" class="component"> </app-shipment-tracker>
    </div>
    <div class="package-status-table flex-1 overflow-auto">
        <p-table #packagesTable dataKey="id" #table [value]="packages"
                 styleClass="package-status-table  flex-table" [scrollable]="true" scrollHeight="auto"
                 [responsive]="true"
                 [loading]="spinnerState === spinnerStates.LOADING">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="isBundle && bundleId">
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true" (onChange)="onToggleAllSelection()"> </p-checkbox>
                    </th>
                    <th style="width: 150px;"> {{"MANAGE_SHIPMENTS.BARCODE" | translate | packageTitle}}</th>
                    <th style="width: 160px;"> {{"MANAGE_SHIPMENTS.STATUS" | translate}}</th>
                    <th *appPermission="permissions.includes('{EDIT_COST')"
                        style="width: 120px"> {{(isLcl ? 'LCL.SHIPMENT_TABLE.COST': 'MANAGE_SHIPMENTS.COST') | translate | currencyValue}} </th>
                    <th>{{"ADD_SHIPMENT.INVOICE_NUMBER" | translate}}</th>
                    <th *ngIf="!isBundle && !bundleId">{{"ADD_SHIPMENT.SENDER_CITY" | translate}}</th>
                    <th *ngIf="!isBundle && !bundleId">{{"ADD_SHIPMENT.RECEIVER_CITY" | translate}}</th>
                    <th *ngIf="!isBundle && !bundleId">{{"MANAGE_SHIPMENTS.TO" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.SENDER_NAME" | translate}}</th>
                    <th>{{"ADD_SHIPMENT.RECEIVER_NAME" | translate}}</th>
                    <th style="overflow-wrap: break-word">{{"MANAGE_COD_CUSTOMERS.NOTES" | translate}}</th>
                    <th>{{(isLcl ? 'LCL.SHIPMENT_TABLE.COD': 'MANAGE_SHIPMENTS.COD') | translate | currencyValue}}</th>
                    <th *ngIf="isBundle">{{"PRODUCT.QUANTITY" | translate}}</th>
                    <th style="width: 7%">{{"ACTIONS.EDIT" | translate}}</th>
                    <th *ngIf="bundleId" style="width: 7%">{{"ACTIONS.REMOVE" | translate}}</th>
                    <th *ngIf="isScanning">{{"ACCOUNTING.REMOVE_PACKAGE"|translate | packageTitle}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-package let-expanded="expanded">
                <tr>
                    <td *ngIf="isBundle && bundleId">
                        <p-checkbox [(ngModel)]="package.isSelected" binary="true" (onChange)="onToggleSelection($event)"></p-checkbox>
                    </td>
                    <td style="width: 150px; text-align: center">
                        <img class="barcode-image" [src]="package.barcodeImage">
                        <span class="package-barcode">
                            <span>{{package.barcode}}</span>
                            <span class="copy-icon">
                                    <img src="../../../assets/icons/package_card/copy.svg" alt=""
                                         (click)="copyText(package.barcode)" data-toggle="tooltip"
                                         data-placement="top" title="Copy"/>
                                </span>
                        </span>
                    </td>
                    <td style="width: 160px;">
                        <app-shipment-status [shipment]="package"></app-shipment-status>
                    </td>
                    <td *appPermission="permissions.includes('{EDIT_COST')" pEditableColumn style="width: 120px;"
                        [pEditableColumn]="package"
                        [pEditableColumnField]="'cost'">
                        <app-edit-cost [package]="package"></app-edit-cost>
                    </td>
                    <td>{{package.invoiceNumber}}</td>
                    <td *ngIf="!isBundle && !bundleId">{{package.senderCity}}</td>
                    <td *ngIf="!isBundle && !bundleId">{{package.receiverCity}}</td>
                    <td *ngIf="!isBundle && !bundleId">{{package.receiverCity}}</td>
                    <td>{{package.senderFirstName + " " + ((package.senderMiddleName) ? package.senderMiddleName : "") +
                    " " + package.senderLastName}}</td>
                    <td>{{package.receiverFirstName + " " + ((package.receiverMiddleName) ? package.receiverMiddleName : "") +
                    " " + package.receiverLastName}}</td>
                    <td>{{package.notes}}</td>
                    <td>{{package.cod}}</td>
                    <td *ngIf="isBundle">{{package.quantity}}</td>
                    <td style="width: 7%">
                        <div class="table-row-actions-container">
                            <div class="edit-action action cursor-pointer" (click)="editPackage(package)">
                                <i class="fas fa-edit"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="bundleId" style="width: 7%">
                        <div class="table-row-actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackageFromBundle(package.id)">
                                <i class="fas fa-trash"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isScanning">
                        <div class="table-row-actions-container">
                            <div class="remove-action action cursor-pointer" (click)="removePackage(package)">
                                <i class="fas fa-minus-circle"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="row">
        <div *ngIf="(isBundle || bundleId)" class="col-3 change-bundle-cod">
            <div class="cod" *ngIf="isBundle && !bundleId">
                <span>
                   <label>{{'SHIPMENT.BUNDLE_COD_MESSAGE' | translate | packageTitle}}</label>
                </span>
            </div>
            <div *ngIf="bundleId" class="cod">
                <span class="p-float-label">
                            <input id="bundle-cod" type="number" pInputText
                                   [(ngModel)] = "bundleCod"
                                   (change)="onChangeBundleCod()"
                                   size="30" style="width: 100%"/>
                            <label for="bundle-cod">{{'ACCOUNTING.CHANGE_COD' | translate}}</label>
                        </span>
            </div>
        </div>
        <div class=" col-9 table-actions" [ngClass]="{'col-12': !(isBundle && bundleId)}">

            <div style="flex: 1" *ngIf="isScanning">
                {{'SHIPMENT.NUMBER_OF_SCANNED_PACKAGES'|translate}}: {{packages.length}}
            </div>
            <div *ngIf="showTransferToThirdPart()" class="add-to-container action-btn green cursor-pointer" (click)="transferToThirdPart()">
                {{"MANAGE_SHIPMENTS.TRANSFER_TO_THIRD_PART" | translate}}
            </div>
            <div *ngIf="!isScanning && !bundleId" class="add-to-container action-btn cursor-pointer" (click)="startScanning()">
                {{(isBundle? "ACCOUNTING.START_BUNDLING": "ACCOUNTING.START_SCANNING") | translate}}
            </div>
            <div *ngIf="isScanning" class="add-to-container action-btn cursor-pointer"
                 (click)="downloadMultiplePackages()"
                 [ngClass]="{'disabled-button disabled': (!isScanning || !packages.length)}">
                <i class="fas fa-pencil-alt"></i>
                {{"SHIPMENT.PRINT_PACKAGES" | translate}}
            </div>
            <div *ngIf="isScanning" class="add-to-driver-container red cursor-pointer" (click)="resetScanning()">
                {{"ACCOUNTING.RESET" | translate}}
            </div>

            <div class="d-flex align-items-center justify-content-start bottom-right" *ngIf="this.bundleId">
                <div class="enter-by-barcode cursor-pointer p-0"
                     (click)="selectPackageByBarcode()">
                    <span>{{'RECEIVE_FROM_DRIVER.ENTER_BARCODE' | translate | packageTitle}}</span>
                    <img style="width: 50px;margin-inline: 15px;"
                         src="../../../assets/icons/barcode-scanner.png" alt=""/>
                </div>

            </div>

            <div *ngIf="isScanning || bundleId" class="add-to-driver-container action-btn cursor-pointer" (click)="enterBarcode()">
                {{(isBundle && bundleId? "SHIPMENT.ADD_PACKAGE_TO_BUNDLE" : "ACCOUNTING.ENTER_BARCODE") | translate}}
            </div>

            <div *ngIf="isBundle && bundleId"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length}"
                 class="add-to-driver-container action-btn cursor-pointer" (click)="extractBundle()">
                {{ 'SHIPMENT.EXTRACT_BUNDLE'| translate}}
            </div>
            <div *ngIf="(isScanning && !isTransfer && !isCancel) || (isBundle && !bundleId)" class="add-to-driver-container action-btn cursor-pointer"
                 [ngClass]="{'disabled-button disabled': (packages && !packages.length)}" (click)="finishScanning()">
                {{(isBundle ? "SHIPMENT.ADD_BUNDLE"  : "SHIPMENT.CHANGE_PACKAGE_STATUS" ) | translate}}
            </div>
            <div *ngIf="isScanning &&isTransfer" class="add-to-driver-container action-btn cursor-pointer"
                 [ngClass]="{'disabled-button disabled': (packages && !packages.length)}" (click)="finishScanning()">
                <span>{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO' | translate}}</span>
                <span *ngIf="!isEnableExportingBetweenHubs">{{' ' + ('GENERAL.PARTNER' | translate)}}</span>
            </div>
            <div *ngIf="isScanning &&isCancel" class="add-to-driver-container action-btn cursor-pointer"
                 [ngClass]="{'disabled-button disabled': (packages && !packages.length)}" (click)="finishScanning()">
                {{"PARTNER.CANCEL_PACKAGES" | translate}}
            </div>
        </div>
    </div>

</div>

<app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                [packageBarcode]="selectedPackageBarcode"
                                (closeWidget)="onHideAddPackage($event)">
</app-add-package-form-component>
