import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'app-city-to-undelivered-cod-summary-report',
    templateUrl: './city-to-undelivered-cod-summary-report.component.html',
    styleUrls: ['./city-to-undelivered-cod-summary-report.component.css']
})
export class CityToUndeliveredCodSummaryReportComponent implements OnInit {
    public cityToReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.CITY_UNDELIVERED_TO_COD_SUM_REPORT;
    public cityToReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.CITY_UNDELIVERED_TO_COD_SUM_REPORT_API;
    public cityToStatistics = REPORTS_CONSTANTS.SUMMARY_REPORTS.CITY_UNDELIVERED_TO_COD_SUM_REPORT_STATISTICS;
    public isDelivered = '';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.isDelivered = params.isDelivered;
        });
    }
}
