
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/modules/shared.module';
import {ShippingsRateComponent} from './shippings-rate.component';
import {PricingCategoriesComponent} from './components/pricing-categories/pricing-categories.component';
import {ShippingMechanismsComponent} from './components/shipping-mechanisms/shipping-mechanisms.component';
import {ShipmentTypesComponent} from './components/shipment-types/shipment-types.component';
import {QoutesComponent} from './components/qoutes/qoutes.component';
import {RouterModule} from '@angular/router';
import { RegionRegularPricesTableComponent } from './components/region-regular-prices-table/region-regular-prices-table.component';
import { CustomersPricingTableComponent } from './components/customers-pricing-table/customers-pricing-table.component';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {DriversEarningsComponent} from './components/drivers-earnings/drivers-earnings.component';
import {WorkZoneComponent} from './components/work-zone/work-zone.component';
import {PricingTypeComponent} from './components/pricing-type/pricing-type.component';
import {PricingListsComponent} from './components/pricing-lists/pricing-lists.component';
import {AddPricingListComponent} from './components/pricing-lists/add-pricing-list/add-pricing-list.component';
import { AddCustomersListComponent } from './components/pricing-lists/add-customers-list/add-customers-list.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AccordionModule} from 'primeng/accordion';
import {AdministrationModule} from '../administration/administration.module';
import {ShippingRatesHistoryComponent} from './components/shipping-rates-history/shipping-rates-history.component';
import { DefaultShippingRateComponent } from './components/default-shipping-rate/default-shipping-rate.component';
import { JettShippingRateComponent } from './components/jett-shipping-rate/jett-shipping-rate.component';

@NgModule({
    declarations: [
        ShippingsRateComponent,
        PricingCategoriesComponent,
        ShippingMechanismsComponent,
        ShipmentTypesComponent,
        QoutesComponent,
        RegionRegularPricesTableComponent,
        CustomersPricingTableComponent,
        DriversEarningsComponent,
        WorkZoneComponent,
        PricingTypeComponent,
        PricingListsComponent,
        AddPricingListComponent,
        AddCustomersListComponent,
        ShippingRatesHistoryComponent,
        DefaultShippingRateComponent,
        JettShippingRateComponent
    ],
    exports: [
        ShippingsRateComponent
    ],
    imports: [
        SharedModule,
        
        RouterModule,
        ToastModule,
        ProgressSpinnerModule,
        TableModule,
        // AdministrationModule,
        AccordionModule,
        AdministrationModule,
    ]
})
export class ShippingsRateModule {
}
