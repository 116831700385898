<div class="change-package-status-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{title | translate}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal()"></i>
        </div>
    </div>
    <div class="body">
        <form [formGroup]="form">
            <app-input-field-component [form]="form" label="GENERAL.REASON"
                                       controlName="reason"
                                       [isRequired]="true">
            </app-input-field-component >
        </form>
    </div>
    <div class="footer d-flex bottom-actions-container">
        <div class="flex-1"></div>
        <input class="btn btn-sm button cancel-button" type="submit" (click)="closeModal()"
               value="{{'ACTIONS.CANCEL' | translate}}">
        <button class="btn-sm action-btn" type="submit" (click)="onSaveButton()"
                [ngClass]="{'disabled-btn disabled': form.invalid}">
            {{'ACTIONS.SAVE' | translate}}
        </button>
    </div>
</div>
