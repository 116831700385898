<div class="h-100 single-report-page">
  <app-admin-report [columns]="hubsReports"
                    [apiUrl]="hubsReportsAPI"
                    [startFromIndex] = 1
                    [isPdfRemove]="false"
                    [hasFilters]= true
                    [filterHubs] = true
                    [filterDate] = false
                    [TYPE] = "'hubs_Reports'"
                    reportTitle="ADMINISTRATION.HUBS_REPORTS"></app-admin-report>
</div>
