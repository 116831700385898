export const environment = {
    production: true,
    FRONT_END_BASE_URL: 'http://' + window.location.hostname,
    API_BASE_URL: 'https://' + '//apis.pal-ship.com/saas/',
    TITLE: 'LogesTechs',
    isOneDomain: true,
    companyId: 0,
    firebase: {
        appId: '1:122047361434:web:b29acbae788500e1024a23',
        authDomain: 'loges-techs.firebaseapp.com',
        apiKey: 'AIzaSyAMpsLeFEgE8ecg9QYytjflvHCTTifYBXo',
        databaseURL: 'https://loges-techs.firebaseio.com',
        projectId: 'loges-techs',
        storageBucket: 'loges-techs.appspot.com',
        messagingSenderId: '122047361434'
    },
    version: '$BITBUCKET_BUILD_NUMBER'
};
