import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import * as XLSX from 'xlsx';
import {getNumericHeaders} from '../../shared/models/excel-shipment.model';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {isArray} from 'chart.js/helpers';

@Injectable({
  providedIn: 'root'
})
export class ImportCustomersFromExcelService {

  private fileReader = new FileReader();

  private englishHeaders;
  private arabicHeaders;

  private readSubject = new Subject<any>();

  private requiredFields = [
    'senderEmail',
    'receiverName',
    'receiverMobile',
    ['receiverVillage', 'receiverCity'],
    'receiverStreetAddress',
    'cod'
  ];

  constructor(protected translateService: TranslateService,
              private userService: UserService) {
    this.initHeadersTranslations();

    this.fileReader.onload = (e) => {
      const arrayBuffer = this.fileReader.result;
      const data = new Uint8Array(arrayBuffer as ArrayBuffer);
      const arr = [];
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.parseWorkCustomers(workbook.Sheets[first_sheet_name]);
    };

  }

  public importCustomers(file): Subject<[]> {
    this.fileReader.readAsArrayBuffer(file);
    return this.readSubject;
  }
  private subjectError(error: string) {
    this.readSubject.error(error);
    this.readSubject.unsubscribe();
    this.readSubject = new Subject();
  }

  private parseWorkCustomers(worksheet) {
    const Customers = [];
    const headerMap = {};

    const sheetJSON = XLSX.utils.sheet_to_json(worksheet, {header: getNumericHeaders(), raw: true, blankrows: true});
    const nonBlankSheetJSON = XLSX.utils.sheet_to_json(worksheet, {header: getNumericHeaders(), raw: true});

    if (nonBlankSheetJSON.length <= 1) {
      this.subjectError('ALERTS.EXCEL_IMPORT_NO_DATA');
      return;
    } else if (Object.keys(nonBlankSheetJSON[0]).length < this.requiredFields.length
        && Object.keys(nonBlankSheetJSON[1]).length < this.requiredFields.length ) {
      this.subjectError('ALERTS.EXCEL_IMPORT_INVALID_FORMAT');
      return;
    } else {

      let headerFound = false;
      for (const key of Object.keys(nonBlankSheetJSON[0])) {
        let fieldName = this.getFieldName(nonBlankSheetJSON[0][`${key}`]);
        fieldName = this.getFieldName(nonBlankSheetJSON[1][`${key}`]);
        if (fieldName !== 'undefined') {
          headerMap[fieldName] = key;
          headerFound = true;
        }
      }

      for (let i = 3; i < sheetJSON.length; ++i) {
        if (Object.keys(sheetJSON[i]).length > 1) {
          Customers.push({
            rowId: (i + 1),
            customerInfo: {
              name: sheetJSON[i][headerMap['name']] ? String(sheetJSON[i][headerMap['name']]) : null,
              email: sheetJSON[i][headerMap['email']] ? String(sheetJSON[i][headerMap['email']]) : null,
              phone: sheetJSON[i][headerMap['phone']],
              businessName: sheetJSON[i][headerMap['businessName']],


              region: sheetJSON[i][headerMap['region']] ? String(sheetJSON[i][headerMap['region']]) : null,
              city: sheetJSON[i][headerMap['city']] ? String(sheetJSON[i][headerMap['city']]) : null,
              village: sheetJSON[i][headerMap['village']] ? String(sheetJSON[i][headerMap['village']]) : null,
              addressLine1: sheetJSON[i][headerMap['addressLine1']] ? String(sheetJSON[i][headerMap['addressLine1']]) : null,
              password: sheetJSON[i][headerMap['password']] ? String(sheetJSON[i][headerMap['password']]) : null,
              type: sheetJSON[i][headerMap['type']] ? String(sheetJSON[i][headerMap['type']]) : null,
              hub: sheetJSON[i][headerMap['hub']] ? String(sheetJSON[i][headerMap['hub']]) : null,
              paymentMethod: sheetJSON[i][headerMap['paymentMethod']] ? String(sheetJSON[i][headerMap['paymentMethod']]) : null,
              pricingListId: sheetJSON[i][headerMap['pricingListId']] ? String(sheetJSON[i][headerMap['pricingListId']]) : null,
              serviceTypes: sheetJSON[i][headerMap['serviceTypes']] ? String(sheetJSON[i][headerMap['serviceTypes']]) : null,
              optionalNumber: sheetJSON[i][headerMap['optionalNumber']] ? String(sheetJSON[i][headerMap['optionalNumber']]) : null,
            }
          });
        }
      }

      if (Customers.length === 0) {
        this.subjectError('ALERTS.EXCEL_IMPORT_NO_DATA');
      } else {
        this.readSubject.next(Customers);
      }
    }
  }
  private getFieldName(header: string): string {
    if (typeof header !== 'string') {
      return 'undefined';
    }
    switch (header.toLowerCase().trim()) {
      case this.englishHeaders['CUSTOMER_NAME'].toLowerCase().trim():
      case this.arabicHeaders['CUSTOMER_NAME']:
        return 'name';
      case this.englishHeaders['EMAIL'].toLowerCase().trim():
      case this.arabicHeaders['EMAIL'].toLowerCase().trim():
        return 'email';
      case this.englishHeaders['PHONE'].toLowerCase().trim():
      case this.arabicHeaders['PHONE'].toLowerCase().trim():
        return 'phone';
      case this.englishHeaders['BUSINESS_NAME'].toLowerCase().trim():
      case this.arabicHeaders['BUSINESS_NAME'].toLowerCase().trim():
        return 'businessName';
      case this.englishHeaders['REGION'].toLowerCase().trim():
      case this.arabicHeaders['REGION'].toLowerCase().trim():
        return 'region';
      case this.englishHeaders['CITY'].toLowerCase().trim():
      case this.arabicHeaders['CITY'].toLowerCase().trim():
        return 'city';
      case this.englishHeaders['VILLAGE'].toLowerCase().trim():
      case this.arabicHeaders['VILLAGE'].toLowerCase().trim():
        return 'village';
      case this.englishHeaders['ADDRESS_LINE1'].toLowerCase().trim():
      case this.arabicHeaders['ADDRESS_LINE1'].toLowerCase().trim():
        return 'addressLine1';
      case this.englishHeaders['PASSWORD'].toLowerCase().trim():
      case this.arabicHeaders['PASSWORD'].toLowerCase().trim():
        return 'password';

      case this.englishHeaders['TYPE'].toLowerCase().trim():
      case this.arabicHeaders['TYPE'].toLowerCase().trim():
        return 'type';

      case this.englishHeaders['HUB'].toLowerCase().trim():
      case this.arabicHeaders['HUB'].toLowerCase().trim():
        return 'hub';
      case this.englishHeaders['PAYMENT_METHOD'].toLowerCase().trim():
      case this.arabicHeaders['PAYMENT_METHOD'].toLowerCase().trim():
        return 'paymentMethod';

      case this.englishHeaders['PRICING_LISTS'].toLowerCase().trim():
      case this.arabicHeaders['PRICING_LISTS'].toLowerCase().trim():
        return 'pricingListId';

      case this.englishHeaders['SERVICE_TYPE'].toLowerCase().trim():
      case this.arabicHeaders['SERVICE_TYPE'].toLowerCase().trim():
        return 'serviceTypes';
      case this.englishHeaders['OPTIONAL_NUMBER'].toLowerCase().trim():
      case this.arabicHeaders['OPTIONAL_NUMBER'].toLowerCase().trim():
        return 'optionalNumber';
      default:
        return 'undefined';
    }
  }


  private initHeadersTranslations() {
    this.userService.getTranslateLanguageForExcels('en').subscribe(
        (data: any) => {
          this.englishHeaders = data.USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER;
        }
    );

    this.userService.getTranslateLanguageForExcels('ar').subscribe(
        (data: any) => {
          this.arabicHeaders = data.USERS_MANAGEMENT.ADD_USER_FORM.EXCEL_HEADER;
        }
    );
  }

  private isHeaderValid(headerMap) {
    if (Object.keys(headerMap).length < this.requiredFields.length) {
      return false;
    }
    let isValid = true;
    this.requiredFields.forEach(field => {
      if (typeof field === 'string') {
        isValid = isValid && (headerMap[field] !== undefined);
      } else if (isArray(field)) {
        isValid = isValid && field.some(subField => {
          return headerMap[subField] !== undefined;
        });
      }
    });
    return isValid;
  }
}
