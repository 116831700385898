<div class="main-container">
    <div class="header">
        <div class="title">
            {{ 'PRICING.ACTIONS.VIEW_CUSTOMERS' | translate }}
        </div>
        <div class="close-icon" (click)="closeModal()">
            <img src="../../../../../assets/icons/cancel.svg" alt="icon" />
        </div>
    </div>
    <div class="table-container">
        <p-table [value]="customers" [loading]="isLoading" [paginator]="true" [lazy]="true" (onLazyLoad)="loadLazyCustomers($event)"
                 [rowsPerPageOptions]="paginationOptions" [scrollable]="true" [rows]="defaultPageSize" #customersTable
                 [totalRecords]="totalRecords">
            <ng-template pTemplate="header">
                <tr style="text-align: center;">
                    <th>{{ 'PRICING.TABLE.CUSTOMER_NAME' | translate  }}</th>
                    <th>{{ 'PRICING.TABLE.CUSTOMER_BUSINESS_NAME' | translate }}</th>
                    <th>{{ 'ACTIONS.DELETE' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td>{{ isAdditionFees ? (customer.name):(customer.firstName + ' ' + customer.lastName) }}</td>
                    <td>{{ customer.businessName }}</td>
                    <td>
                        <i (click)="deleteCustomer(customer)" class="fas fa-trash"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr [formGroup]="form">
                    <td colspan="3">
                        <ng-select appendTo="body" [loading]="isLoadingCustomers" appDebounceSelectQuery
                                   [items]="customersList" bindValue="id" bindLabel="fullName" [multiple]="true"
                                   [closeOnSelect]="false" (change)="onChange($event)"
                                   loadingText="{{ 'PRICING.ALERTS.CUSTOMER_SEARCHING' | translate }}"
                                   notFoundText="{{ 'PRICING.ALERTS.NO_CUSTOMERS_FOUND' | translate }}"
                                   placeholder="{{ 'PRICING.ALERTS.CUSTOMER_SEARCH_PLACEHOLDER' | translate }}"
                                   (inputDebounced)="searchCustomer($event)" formControlName="customersIds">
                            <ng-template ng-header-tmp>
<!--                                keep this maybe will use it later-->
<!--                                [ngClass]="{'disabled disabled-btn': !(customersList && customersList.length)}"-->
                                <div *ngIf="!isAdditionFees">
                                    <button class="btn btn-link"
                                            (click)="onSelectAll()">{{'GENERAL.SELECT_ALL' | translate}}
                                    </button>
                                    <button class="btn btn-link"
                                            (click)="onClearAll()">{{'GENERAL.UNSELECT_ALL' | translate}}
                                    </button>
                                </div>

                            </ng-template>
                        </ng-select>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="bottom-actions">
        <div class="add-action" (click)="onAddCustomers()"
             [ngClass]="{'disabled disabled-btn': !form.get('customersIds').value || form.get('customersIds').value.length === 0}">
            {{ 'PRICING.ACTIONS.ADD_CUSTOMERS' | translate }}
        </div>
    </div>
</div>
