import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {ShipmentsService} from '../services/shipments.service';

@Component({
    selector: 'app-faliure-history',
    templateUrl: './faliure-history.component.html',
    styleUrls: ['./faliure-history.component.scss']
})
export class FaliureHistoryComponent implements OnInit {
    public shipmentId;
    public isLoading = false;
    public failuresHistory = [];

    constructor(private shipmentService: ShipmentsService,
                public activeModal: NgbActiveModal,
                private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.fetchFailuresHistory();
    }

    public closeModal() {
        this.activeModal.close();
    }

    public fetchFailuresHistory() {
        this.shipmentService.getFaliuresHistory(this.shipmentId).subscribe((data: any) => {
            this.failuresHistory = data;
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

}
