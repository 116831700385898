import {AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output} from '@angular/core';
import {fromEvent, Observer, Subject, Subscription} from 'rxjs';
import {NgSelectComponent} from '@ng-select/ng-select';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';

@Directive({
    selector: '[appDebounceSelectQuery]'
})
export class DebounceSelectQueryDirective implements OnDestroy, AfterViewInit {
    @Input() debounceTime = 700;
    @Input() observer: Observer<any>;
    @Input() formLoading: FormControl;
    @Output() inputDebounced: EventEmitter<any> = new EventEmitter();

    selectComponent: NgSelectComponent;
    searchInput: HTMLInputElement;
    selectQuerySubscription: Subscription;
    queryResultSubscription: Subscription;

    constructor(public element: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.searchInput = this.element.nativeElement.firstElementChild.firstElementChild.lastElementChild.firstElementChild;
        this.selectComponent = (this.element.nativeElement as NgSelectComponent);

        this.selectQuerySubscription = fromEvent(this.searchInput, 'input')
            .pipe(map((event: Event) => {
                return ((event.target as HTMLInputElement).value);
            }))
            .pipe(debounceTime(this.debounceTime))
            .pipe(distinctUntilChanged())
            .subscribe((data) => {
                if (this.formLoading) {
                    this.formLoading.patchValue(true);
                }
                if (this.observer) {
                    this.observer.next(data);
                }
                this.inputDebounced.emit(data);
            });

        if (this.observer instanceof Subject) {
            this.queryResultSubscription = this.observer.subscribe(
                (result) => {
                    if (result === true && this.formLoading) {
                        this.formLoading.patchValue(false);
                    }
                }
            );
        }
    }

    ngOnDestroy() {
        this.selectQuerySubscription.unsubscribe();
        if (this.queryResultSubscription) {
            this.queryResultSubscription.unsubscribe();
        }
    }

}
