<div class="upload-image-profile"
     [style.width.px]="imageWidth ? imageWidth : imageSize" [style.height.px]="imageHeight ? imageHeight : imageSize">
    <div *ngIf="showClear && canUpload && url" class="add-to-container cursor-pointer"
         style="position: absolute; z-index: 200; opacity: 80%;"
         (click)="onClear($event)">
        <i class="fas fa-times-circle"></i>
    </div>
    <div class="upload-image" [ngClass]="{'rectangle-image': imageShape === 'RECT'}"
         [style.backgroundImage]="'url('+ (url || defaultLogo) + ')'">
        <div *ngIf="canUpload" class="upload-image-middle" [ngClass]="canUpload ? 'can-upload-image' : ''"
             (click)="addImage($event, fileChooser)">
            <div class="upload-image-text" [ngClass]="canUpload ? 'cursor-pointer' : ''">
                <img style="width: 25% ;height: 25%"
                     src="assets\icons\camera-solid.svg">
                {{ 'ACTIONS.UPLOAD' | translate }}
            </div>
        </div>
        <input type="file" accept="image/x-png,image/gif,image/jpeg,image/x-icon"
               style="width: 0; height: 0; opacity: 0; position: absolute" #fileChooser
               (click)="$event.stopPropagation()"
               (change)="onFileSelected($event)">
    </div>
</div>
