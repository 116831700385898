import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {UserService} from '../../../shared/services/user.service';
import {SystemUpdatesService} from '../../services/system-updates.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateModel} from '../../models/update.model';

@Component({
  selector: 'app-video-player-dialog',
  templateUrl: './video-player-dialog.component.html',
  styleUrls: ['./video-player-dialog.component.scss']
})
export class VideoPlayerDialogComponent implements OnInit {
  pdfSrc = '';
  zoom = 1.0;
  private isSuperAdmin: boolean;
  videoUrl;
  language;
  showAll = true;
  showLoader = true;
  @Input() data;


  constructor(private router: Router,
              private sanitizer: DomSanitizer,
              private userService: UserService,
              private activeModal: NgbActiveModal,
              private route: ActivatedRoute, private systemUpdatesService: SystemUpdatesService,
              private authenticationService: AuthenticationService,
              private translate: TranslateService) {
    this.isSuperAdmin = this.authenticationService.getIsSuperAdmin();
    this.language = this.translate.currentLang;
  }


  ngOnInit(): void {
    const url = `https://player.vimeo.com/video/${this.language === 'ar' ? this.data.arabicVideoLink : this.data.videoLink}&color=ffffff&title=0&byline=0&portrait=0`;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  hideSpinner() {
    this.showLoader = false;
    this.systemUpdatesService.loadSystemUpdateById(this.data.id, this.isSuperAdmin).subscribe((res: UpdateModel) => {
      this.userService.initSystemUpdatesBadge();
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
