import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-track-package-line-component',
    templateUrl: 'track-package-line.component.html',
    styleUrls: ['track-package-line.component.scss']
})
export class TrackPackageLineComponent implements OnInit {
    @Input() nodes: Array<any>;
    @Input() displayDetails = true;

    ngOnInit() {
        if (this.nodes && this.nodes.length > 0) {
            for (let i = 0; i < this.nodes.length; i++) {
                if (this.nodes[i].isArrived) {
                    this.nodes[i].image = 'success.png';
                } else if (i === this.nodes.length - 1) {
                    this.nodes[i].image = 'airplane.png';
                } else {
                    this.nodes[i].image = 'package-box.png';
                    this.nodes[i].cssClass = 'circle-background';
                }
            }
        }
    }
}
