export interface PaymentModel {
    id: number;
    companyName: string;
    payeeName: string;
    paymentDate: Date;
    paymentStatus: string;
    amount: number;
    currency: string;
    stripeAmount: number;
    originalAmount: number;
    processDate: string;
    createdDate: string;
}

export enum PaymentStatus {
    DRAFT,
    SUCCESSFUL,
    FAILED
}
