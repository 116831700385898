<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="create-new-container-container">
        <div class="header d-inline-flex">
            <div class="title">{{(isEditMode ? 'CONTAINERS_MANAGEMENT.ACTIONS.EDIT_CONTAINER': 'CONTAINERS_MANAGEMENT.ACTIONS.ADD_NEW_CONTAINER') | translate}}</div>
            <div class="flex-1"></div>
            <div class="close-icon" (click)="closeModal()">
                <img src="../../assets/icons/cancel.svg" alt="icon" />
            </div>
        </div>

        <div *ngIf="isLoading" class="loading-spinner" style="text-align: center;">
            <p-progressSpinner> </p-progressSpinner>
        </div>

        <div id="submitting-container" *ngIf="isSubmitting">
            <p-progressSpinner> </p-progressSpinner>
        </div>

        <form [formGroup]="form" *ngIf="form">
            <div class="inputs-container">
                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-sm">
                            <app-drop-down
                                    placeholder="{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_BRAND' | translate}}"
                                    [label]="'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_BRAND'"
                                    [options]="vehicleBrands"
                                    formControlName="brand"
                                    [showClear]="true"
                                    (whenChange)="onChangeBrand($event)">
                            </app-drop-down>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-sm">
                        <app-drop-down
                                [disabled]="!(form && form.get('brand')?.value)"
                                placeholder="{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_MODEL' | translate}}"
                                [label]="'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_MODEL'"
                                [style]="{'width':'100%', 'height': '54px', 'padding': '10px 0px'}"
                                [options]="vehicleModels"
                                formControlName="name"
                                [showClear]="true">
                        </app-drop-down>
                    </div>
                </div>
                <div class="inputs row">
                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <app-field-input  formControlName="volume"
                                    [formControlObj]="form.get('volume')"
                                    [label]="'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CAPACITY'"
                                    [inputValidation]="FieldValidation.DECIMAL"></app-field-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-sm">
                        <app-field-input formControlName="plateNo"
                                   [formControlObj]="form.get('plateNo')"
                                   [label]="'CONTAINERS_MANAGEMENT.TABLE_HEADERS.PLATE_NO'"
                        ></app-field-input>
                    </div>
                </div>
                <div class="row row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                            <app-auto-complete formControlName="driverId"
                                               [formControlObj]="form.get('driverId')"
                                               placeholder="{{'ADMINISTRATION.DRIVER_LIST_DROPDOWN_PLACEHOLDER' | translate}}"
                                               [label]="'CONTAINERS_MANAGEMENT.TABLE_HEADERS.THE_DRIVER'"
                                                field="label"
                                                [forceSelection]="true"
                                                [dropdown]="true"
                                                [suggestions]="drivers"
                                                (onSelect)="alertChangingDriver($event)"
                                                (completeMethod)="getDrivers($event)">
                                <ng-template let-driver pTemplate="item">
                                    <div class="store-item">
                                        <div class="text-ellipsis">{{driver.label}}</div>
                                    </div>
                                </ng-template>
                            </app-auto-complete>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-sm">
                        <app-drop-down
                                [label]="'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.VEHICLE_TYPE'"
                                placeholder="{{'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.DROPDOWN_PLACEHOLDER' | translate}}"
                                [style]="{'width':'100%', 'height': '54px', 'padding': '10px 0px'}"
                                [options]="vehicleTypes"
                                formControlName="vehicleTypeId"
                                [formControlObj]="form.get('vehicleTypeId')"
                                [showClear]="true"
                                (whenChange)="alertChangingVehicleType($event)">
                        </app-drop-down>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-sm">
                            <app-drop-down
                                    [label]="'CONTAINERS_MANAGEMENT.VEHICLE_FUEL.LABEL'"
                                    placeholder="{{'CONTAINERS_MANAGEMENT.VEHICLE_FUEL.DROPDOWN_PLACEHOLDER' | translate}}"
                                    [style]="{'width':'100%', 'height': '54px', 'padding': '10px 0px'}"
                                    [options]="fuelTypes"
                                    formControlName="fuelType"
                                    [formControlObj]="form.get('fuelType')"
                                    [showClear]="true">
                            </app-drop-down>
                    </div>
                </div>

                <div class="row d-inline-flex row-input">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <app-calendar formControlName="insuranceExpiryDate"
                                      [formControlObj]="form.get('insuranceExpiryDate')"
                                      [label]="'CONTAINERS_MANAGEMENT.INSURANCE_EXPIRY_DATE'"
                                      dateFormat="dd/mm/yy"></app-calendar>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 input">
                        <app-calendar [label]="'CONTAINERS_MANAGEMENT.LICENSE_EXPIRY_DATE'"
                                      formControlName="licenseExpiryDate"
                                      [formControlObj]="form.get('licenseExpiryDate')"
                                      dateFormat="dd/mm/yy"></app-calendar>
                    </div>
                </div>
                <div class="row bottom-actions">
                    <div class="actions d-inline-flex">
                        <div class="cancel-action d-inline-flex flex-2d-center" (click)="closeModal()">
                            <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.CANCEL' | translate}}</span>
                        </div>
                        <div class="splitter"></div>
                        <div class="done-action flex-2d-center" (click)="addContainer()" [ngClass]="{'disabled-btn disabled': !form.valid}">
                            <span>{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
