<!--row actions-->
<ng-template #rowActions>
    <div class="row-actions-container">
        <ng-container *ngIf="!isArchived">
            <div class="row action" *ngIf="canEdit" (click)="editUser()">
                <div class="icon">
                    <img class="margin-left-10 edit-button"
                         src="../../../../assets/icons/containers-management/edit.svg" alt="icon"/>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label"
                     *ngIf="isCustomer">{{ 'USERS_MANAGEMENT.ACTIONS.EDIT_CUSTOMER' | translate }}</div>
                <div class="label" *ngIf="!isCustomer">{{ 'USERS_MANAGEMENT.ACTIONS.EDIT_USER' | translate }}</div>
            </div>
<!--             && user.role === ''-->
            <div class="row action" *ngIf="canEdit && user?.role === 'MULTIPLE_HUBS_MANAGER'" (click)="editHubs()">
                <div class="icon">
                    <img class="margin-left-10 edit-button"
                         src="../../../../assets/icons/containers-management/edit.svg" alt="icon"/>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.EDIT_HUBS' | translate }}</div>
            </div>
            <div class="row action" *ngIf="canResetPassword" (click)="resetPassword()">
                <div class="icon">
                    <img class="margin-left-10 edit-button" src="../../../../assets/icons/padlock.svg" alt="icon"/>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label">{{ 'AUTH.CHANGE_PASSWORD' | translate }}</div>
            </div>
            <div class="row action" *ngIf="canTrackingPassword" (click)="trackingPassword()">
                <div class="icon">
                    <img class="margin-left-10 edit-button" src="../../../../assets/icons/padlock.svg" alt="icon"/>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.TRACKING_PASSWORD' | translate }}</div>
            </div>
            <div class="row action remove-action" *ngIf="canRemove" (click)="removeUser()">
                <div class="icon">
                    <img src="../../../../assets/icons/containers-management/remove_vechile.svg" alt="icon"/>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label" *ngIf="isCustomer">{{ 'USERS_MANAGEMENT.ACTIONS.DELETE_CUSTOMER' | translate }}</div>
                <div class="label" *ngIf="!isCustomer">{{ 'USERS_MANAGEMENT.ACTIONS.DELETE_USER' | translate }}</div>
            </div>
            <div class="row action" *ngIf="canAddAttachment" (click)="addCustomerAttachment()">
                <div class="icon">
                    <img src="../../../../assets/icons/attachments.svg" alt="icon"/>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label">{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ADD_ATTACHMENTS' | translate }}</div>
            </div>
            <div class="row action" *ngIf="canDownloadExcelTemplate" (click)="downloadExcelTemplate()">
                <div class="icon">
                    <img *ngIf="!downloadExcelLoader" src="../../../../assets/icons/excel.svg" alt="icon"/>
                    <i *ngIf="downloadExcelLoader" class="fas fa-spinner fa-spin"></i>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label">{{ 'ACTIONS.DOWNLOAD_EXCEL_TEMPLATE' | translate }}</div>
            </div>
            <div class="row action remove-action" *ngIf="isCustomer" (click)="openAttachments()">
                <div class="icon">
                    <i class="fas fa-file-pdf"></i>
                </div>
                <div class="row-action-hidden-space"></div>
                <div class="label">{{ 'USERS_MANAGEMENT.ADD_USER_FORM.ATTACHMENTS' | translate }}</div>

            </div>
        </ng-container>
        <div class="row action remove-action" *ngIf="canRemove && isArchived" (click)="removeUserForever()">
            <div class="icon">
                <img src="../../../../assets/icons/containers-management/remove_vechile.svg" alt="icon"/>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{ 'USERS_MANAGEMENT.ACTIONS.FINAL_DELETE' | translate }}</div>
        </div>
    </div>
</ng-template>

<div class="user-card-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="user-item-container">
        <div *ngIf="user.isDeleting" class="main-spinner-container">
            <div class="spinner-stage">
                <div class="dot-flashing"></div>
            </div>
        </div>
        <div *ngIf="!user.isDeleting" class="user-item d-flex">
            <div class="checkbox-container d-flex flex-column align-items-center"
                 style="width: 65px; background-color: #fca429; height: 100%;">
                <app-upload-image-component class="user-image-upload" [showClear]="false"
                                            [(url)]="user.imageUrl"
                                            [imageSize]="50"
                                            [canUpload]="!isArchived"
                                            (fileChange)="onUploadImage($event)"
                                            [defaultLogo]="'../../../../assets/icons/user-avatar-regular.svg'">

                </app-upload-image-component>

                <div *ngIf="user.hasSubCustomers" class="sub-customers cursor-pointer"
                     pTooltip="{{'MEMBER.SHOW_SUB_CUSTOMERS' | translate}}"
                     (click)="displayCustomers()">
                    <img src="../../../../assets/icons/sub-customer.svg" alt="icon"/>
                </div>
            </div>
            <div class="data">
                <div>
                    <div class="row justify-content-between">
                        <div class="user-full-name col-10">
                            <span>{{user.firstName}} {{user.lastName}}</span>
                        </div>

                        <div *ngIf="isArchived" class="user-retrieve-icon col-2" (click)="retrieve()">
                            <img class="margin-left-10 edit-button"
                                 pTooltip="{{ (isCustomer ? 'ACTIONS.RETRIEVE_CUSTOMER' : 'ACTIONS.RETRIEVE_USER') | translate }}"
                                 tooltipPosition="top"
                                 src="../../../../assets/icons/user_retrieve.svg" alt="icon" />
                        </div>
                    </div>

                    <div *ngIf="isCustomer" class="business-name">
                        <span>{{user.businessName? user.businessName : '-'}}</span>
                    </div>
                    <div class="role-and-hub-tags d-inline-flex" *ngIf="!isCustomer">
                        <div class="tag role-name">
                            <span>{{user.role | translate }}</span>
                        </div>
                        <div style="width: 7px;"></div>
                        <div class="tag hub-name" *ngIf="user.role != 'MULTIPLE_HUBS_MANAGER'">
                            <span>{{user?.hubName}}</span>
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="user-info row">
                            <div class="icon">
                                <img src="../../../../assets/icons/email.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value user-name"><span>{{user.email}}</span></div>
                        </div>

                        <div class="user-info row" *ngIf="customizationCompanyService.checkCompanies(companies.SPRINT, companies.LOGESTECHS) && isCustomer && user.qrCode">
                            <div class="icon">
                                <img src="../../../../assets/icons/branches-management/barcode-scanner.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value user-name"><span>{{user.qrCode}}</span></div>
                            <div class="copy-icon cursor-pointer" (click)="copyBarcode($event, user.qrCode)">
                                <img src="../../../../assets/icons/track_package/copy.svg" alt="copy-icon" />
                            </div>
                        </div>
                        <div class="user-info row">
                            <div class="icon">
                                <img src="../../../../assets/icons/phone-call.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value phone-number"><span>{{user.phone}}</span></div>
                        </div>
                        <div class="user-info address-row row">
                            <div class="icon">
                                <img src="../../../../assets/icons/pin.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value address"><span>{{getAddress(user.address)}}</span></div>
                        </div>
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="actions">
                        <div *ngIf="!isNewRequest" class="card-action cursor-pointer" (click)="showUserDetails()">
                            <i class="fas fa-address-card"></i>
                            <span *ngIf="isCustomer">{{ 'USERS_MANAGEMENT.CUSTOMER_DETAILS' | translate }}</span>
                            <span *ngIf="!isCustomer">{{ 'USERS_MANAGEMENT.USER_DETAILS' | translate }}</span>
                        </div>
                        <div *ngIf="user.role === 'DRIVER' && !isNewRequest" class="card-action cursor-pointer" (click)="onShowDriverCheckIn()">
                            <i class="fas fa-address-book-o"></i>
                            <span>{{ 'USERS_MANAGEMENT.ACTIONS.SHOW_DRIVER_CHECK_IN' | translate }}</span>
                        </div>
                        <div *ngIf="user.role === 'CUSTOMER_ACCOUNT_MANAGER'" class="card-action cursor-pointer" (click)="showCustomers(user)">
                            <i class="fas fa-pencil-alt"></i>
                            <span>{{ 'PRICING.ACTIONS.VIEW_CUSTOMERS' | translate }}</span>
                        </div>
                        <div *ngIf="isCustomer||isDriver">
                            <ng-container
                                    *appPermission="permissions.includes(isCustomer ? '{VERIFY_CUSTOMER' : (isDriver ? 'CONFIRM_DRIVERS' : ''))">
                                <div *ngIf="isNewRequest" class="card-action cursor-pointer" (click)="onVerifyUser()">
                                    <i class="fas fa-check"></i>
                                    <div class="label">
                                        <span>{{ ('USERS_MANAGEMENT.ACTIONS.VERIFY_' + (isDriver ? 'DRIVER' : 'CUSTOMER')) | translate }}</span>
                                    </div>
                                </div>
                                <div *ngIf="isNewRequest" class="card-action cursor-pointer btn-danger ml-1 mr-1"
                                     (click)="removeUser()">
                                    <i class="fas fa-times"></i>
                                    <div class="label">
                                        <span>{{ ('USERS_MANAGEMENT.ACTIONS.REJECT_' + (isDriver ? 'DRIVER' : 'CUSTOMER')) | translate }}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="!isNewRequest && !isCustomerAccountManager()" class="card-action switch-action">
                                <span>{{ 'USERS_MANAGEMENT.TABLE.APPROVE_PACKAGES' | translate }}</span>
                                <div class="approve-package-switch" [ngClass]="{'disabled-toggle-switch': !user.isAutoApprovePackages}">
                                    <p-inputSwitch [(ngModel)]="user.isAutoApprovePackages" (onChange)="autoApproveUser($event.checked)"></p-inputSwitch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(!isArchived || canDeleteForever) && !isNewRequest && !isCustomerAccountManager() && canEditRoles()"
                         class="dots-menu">
                        <img placement="{{currentLang === 'en' ? 'left': 'right'}}" autoClose="outside"
                             #rowActionsPopOver="ngbPopover" [ngbPopover]="rowActions"
                             src="../../../../assets/icons/containers-management/menu.svg" alt=""/>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
