<!--row actions-->
<ng-template #rowActions let-close="close">
    <div class="row-actions-container">
        <div class="row action" (click)="modify()">
            <div class="icon">
                <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngIf="!isShelve" class="label">
                <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</span>
                <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</span>
                <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.EDIT_WAREHOUSE' | translate }}</span>
            </div>
            <div *ngIf="isShelve" class="label">{{ 'SHELVES_MANAGEMENT.ACTIONS.EDIT_SHELF' | translate }}</div>
        </div>
        <div *ngIf="!isWarehouse && permissions.includes('DELETE')" class="row action remove-action" (click)="remove()">
            <div class="icon">
                <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div *ngIf="!isShelve" class="label">
                <span *ngIf="!isStations">{{ 'BRANCHES_MANAGEMENT.ACTIONS.DELETE_BRANCH' | translate }}</span>
                <span *ngIf="isStations">{{ 'STATIONS_MANAGEMENT.ACTIONS.DELETE_BRANCH' | translate }}</span>
            </div>
            <div *ngIf="isShelve" class="label">{{ 'SHELVES_MANAGEMENT.ACTIONS.DELETE_SHELF' | translate }}</div>
        </div>
    </div>
</ng-template>


<div class="hub-card-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="hub-item-container">
        <div class="hub-item d-flex">
            <div class="checkbox-container">
                <div class="branch-image">
                    <img *ngIf="!isWarehouse" src="../../../../assets/icons/branches-management/branch-icon.svg" alt="car-icon" />
                    <img *ngIf="isWarehouse" src="../../../../assets/icons/branches-management/warehouse-icon.png" alt="car-icon" />
                </div>
                <div [ngClass]="(isShelve? shelve.isSelected : hub.isSelected) ? 'checked' : ''" class="hub-checkbox" style="display: flex; justify-content: center;">
                    <p-checkbox binary="true" (onChange)="selectShelf($event)" [(ngModel)]="isShelve? shelve.isSelected : hub.isSelected"> </p-checkbox>
                </div>
            </div>
            <div class="data">
                <div>
                    <div class="row justify-content-between">

                        <div class="user-full-name col-10">
                            <div class="hub-name">
                                <span>{{isShelve ? shelve?.label : hub?.name}}</span>
                            </div>
                        </div>

                            <div *ngIf="hub.isDeleted" class="hub-retrieve-icon col-2" (click)="retrieve()">
                                <img class="margin-left-10 edit-button"
                                     pTooltip="{{'ACTIONS.RETRIEVE_HUB' | translate }}"
                                     tooltipPosition="top"
                                     src="../../../../assets/icons/user_retrieve.svg" alt="icon"/>
                            </div>
                    </div>
                    <div *ngIf="!isShelve && !isWarehouse" class="packages-count-container d-inline-flex">
                        <div class="packages-count-label-and-data packages-count-label">
                            <span>{{ 'BRANCHES_MANAGEMENT.TABLE.NUMBER_OF_PACKAGES' | translate | packageTitle}}</span>
                        </div>
                        <div style="width: 8px;"></div>
                        <div class="packages-count-label-and-data tag packages-count">
                            <span>{{hub?.packagesNumber}}</span>
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="hub-info row">
                            <div class="icon">
                                <img src="../../../../assets/icons/branches-management/barcode-scanner.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value barcode-number"><span>#{{isShelve? shelve?.barcode : hub?.barcode}}</span></div>
                            <div style="width: 12px;"></div>
                            <div class="copy-icon" (click)="copyBarcode(isShelve? shelve?.barcode : hub?.barcode)">
                                <img src="../../../../assets/icons/track_package/copy.svg" alt="copy-icon" />
                            </div>
                        </div>
                        <div *ngIf="!isShelve" class="hub-info row">
                            <div class="icon">
                                <img src="../../../../assets/icons/branches-management/phone.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value phone-number"><span>{{hub.phone}}</span></div>
                        </div>
                        <div class="hub-info address-row row">
                            <div class="icon">
                                <img src="../../../../assets/icons/branches-management/location.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value address">
                                <span>
                                    {{isShelve? (shelve?.destinationRegion? shelve.destinationRegion : '') : getAddress(hub.address)}}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="isShelve" class="hub-info address-row row" [ngClass]="{'disabled-button disabled': loadingCities}">
                            <div class="icon">
                                <img src="../../../../assets/icons/branches-management/location.svg" alt="icon" />
                            </div>
                            <div style="width: 12px;"></div>
                            <div class="value address cursor-pointer" (click)="getShelfCities()">
                                <span class="show-cities">
                                   {{'ADMINISTRATION.CITIES' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="actions d-flex">
                    <div class="d-flex main-actions">
                        <div *ngIf="!isShelve && !isWarehouse" class="hub-details d-inline-flex cursor-pointer" (click)="showHubDetails()">
                            <div class="hub-details-icon">
                                <img src="../../../../assets/icons/containers-management/vehicle_details.svg" alt="icon" />
                            </div>
                            <div class="hub-details-hidden-space"></div>
                            <div class="label">
                                <span *ngIf="!isStations">{{ 'BRANCHES_MANAGEMENT.BRANCH_DETAILS' | translate }}</span>
                                <span *ngIf="isStations">{{ 'STATIONS_MANAGEMENT.BRANCH_DETAILS' | translate }}</span>
                            </div>
                        </div>
                        <div *ngIf="isWarehouse" class="hub-details d-inline-flex cursor-pointer warehouse" (click)="showHubDetails()">
                            <div class="hub-details-icon">
                                <img src="../../../../assets/icons/containers-management/vehicle_details.svg" alt="icon" />
                            </div>
                            <div class="hub-details-hidden-space"></div>
                            <div class="label">
                                <span>{{ 'GENERAL.DETAILS' | translate }}</span>
                            </div>
                        </div>
                        <div *ngIf="isWarehouse" class="hub-details d-inline-flex cursor-pointer warehouse" (click)="manageWarehouse('AREAS')">
                            <div class="hub-details-icon">
                                <img src="../../../../assets/icons/branches-management/warehouse.png" alt="icon" />
                            </div>
                            <div class="hub-details-hidden-space"></div>
                            <div class="label">
                                <span>{{ 'FULFILMENT.WAREHOUSE.AREAS.TITLE' | translate }}</span>
                            </div>
                        </div>
                        <div *ngIf="isWarehouse" class="hub-details d-inline-flex cursor-pointer warehouse" (click)="manageWarehouse('ROWS')">
                            <div class="hub-details-icon">
                                <img src="../../../../assets/icons/branches-management/row.png" alt="icon" />
                            </div>
                            <div class="hub-details-hidden-space"></div>
                            <div class="label">
                                <span>{{ 'FULFILMENT.WAREHOUSE.ROWS.TITLE' | translate }}</span>
                            </div>
                        </div>
                        <div *ngIf="isWarehouse" class="hub-details d-inline-flex cursor-pointer warehouse" (click)="manageWarehouse('LOCATIONS')">
                            <div class="hub-details-icon">
                                <img src="../../../../assets/icons/branches-management/locations.png" alt="icon" />
                            </div>
                            <div class="hub-details-hidden-space"></div>
                            <div class="label">
                                <span>{{ 'FULFILMENT.WAREHOUSE.LOCATIONS.TITLE' | translate }}</span>
                            </div>
                        </div>
                        <div *ngIf="isShelve" class="hub-details shelve-packages-count-container d-inline-flex cursor-pointer" (click)="openShelvePackages()">
                            <div class="hub-details-icon">
                                <img src="../../../../assets/icons/containers-management/vehicle_details.svg" alt="icon" />
                            </div>
                            <div class="hub-details-hidden-space"></div>
                            <div class="label">
                                <span>{{ 'BRANCHES_MANAGEMENT.PACKAGES' | translate | packageTitle}}</span>
                            </div>
                            <div style="width: 13px;"></div>
                            <div class="shelve-packages-count-badge">
                                <span>{{shelve?.currentPackagesNo}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1" style="min-width: 12px;"></div>
                    <div class="dots-menu" *ngIf="!(hub && hub.isDeleted)">
                        <img placement="{{currentLang === 'en' ? 'left': 'right'}}" autoClose="outside" #rowActionsPopOver="ngbPopover" [ngbPopover]="rowActions" src="../../../../assets/icons/containers-management/menu.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
