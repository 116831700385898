<div class="main-container">
    <div class="header">
        <div class="title">{{ (viewOnly ? 'ADD_SHIPMENT.LCL.PACKAGE_ITEMS' : 'ADD_SHIPMENT.LCL.ADD_ITEMS') | translate }}</div>
    </div>

    <div class="table-container">
        <p-table [value]="items" [responsive]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th class="th-xsm">#</th>
                    <th class="th-md">{{ 'ADD_SHIPMENT.LCL.SUPPLIER' | translate }}</th>
                    <th class="th-md">{{ 'ADD_SHIPMENT.LCL.PRODUCT_NAME' | translate }}</th>
                    <th class="th-md">{{ 'ADD_SHIPMENT.LCL.DESCRIPTION' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.QUANTITY' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.BOX_NO' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.BOX_QUANTITY' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.WIDTH' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.LENGTH' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.HEIGHT' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.WEIGHT' | translate }}</th>
                    <th class="th-sm">{{ 'ADD_SHIPMENT.LCL.CBM' | translate }}</th>
                    <th class="th-xsm"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
                <tr>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.NUMBER_OF_ITEMS' | translate }}</span>
                        {{1 + index}}
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.COMPANY_NAME' | translate }}</span>
                        <p-inplace #brandNameInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, brandNameInplace, item.supplier.brandName)">
                                                    <span pInplaceDisplay>
                                                        <ng-container *ngIf="item?.supplier?.brandName">
                                                            {{ item.supplier && item.supplier.brandName ? item.supplier.brandName  : "-" }}
                                                        </ng-container>
                                                        <ng-container *ngIf="item?.supplier?.value?.brandName">
                                                                {{item.supplier.value.brandName}}
                                                        </ng-container>
                                                    </span>
                            <span pInplaceContent>
                                <ng-select
                                        class="col-9 d-inline-block"
                                        (change)="chooseSupplier($event, item)"
                                        [typeahead]="supplierSubject"
                                        [loading]="supplierLoading"
                                        [items]="supplierList"
                                        [(ngModel)]="triggerredInplaceValue"
                                        (open)="!supplierList.length ? getSuppliers() : null"
                                        typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                        loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                        [appendTo]="'body'"
                                        (keydown.enter)="onSaveInplace($event, item, 'brandName', true)"
                                        (keydown.escape)="onCancelInplace($event)"
                                        notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                        placeholder="{{ 'ADD_SHIPMENT.LCL.SUPPLIER' | translate }}">
                                 </ng-select>

                                <i class="fas fa-check"
                                   [ngClass]="{'disabled disabled-btn': !triggerredInplaceValue}"
                                   (click)="onSaveInplace($event, item, 'brandName', true)"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>

                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.PRODUCT_NAME' | translate }}</span>
                        <p-inplace #productNameInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, productNameInplace, item.productName)">
                            <span pInplaceDisplay>
                                {{ item.productName ? item.productName : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-9" pInputText type="text"
                                       (keydown.enter)="onSaveInplace($event, item, 'productName', true)"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" [ngClass]="{'disabled disabled-btn': !triggerredInplaceValue}"
                                   (click)="onSaveInplace($event, item, 'productName', true)"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.DESCRIPTION' | translate }}</span>
                        <p-inplace #descriptionInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, descriptionInplace, item.description)">
                            <span pInplaceDisplay>
                                {{ item.description ? item.description : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-9" pInputText type="text"
                                       (keydown.enter)="onSaveInplace($event, item, 'description')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'description')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.QUANTITY' | translate }}</span>
                        <p-inplace #quantityInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, quantityInplace, item.quantity)">
                            <span pInplaceDisplay>
                                {{ item.quantity ? item.quantity : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'quantity', true)"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" [ngClass]="{'disabled disabled-btn': !triggerredInplaceValue}"
                                   (click)="onSaveInplace($event, item, 'quantity', true)"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.BOX_NO' | translate }}</span>
                        <p-inplace #boxNoInplace
                                   [disabled]="true"
                                   (onActivate)="onActivateInplace($event, boxNoInplace, item.boxRange)">
                            <span pInplaceDisplay>
                                {{ item.boxRange ? item.boxRange : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'boxRange')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'boxRange')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.BOX_QUANTITY' | translate }}</span>
                        <p-inplace #boxQuantityInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, boxQuantityInplace, item.boxQuantity)">
                            <span pInplaceDisplay>
                                {{ item.boxQuantity ? item.boxQuantity : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'boxQuantity')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'boxQuantity')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.WIDTH' | translate }}</span>
                        <p-inplace #widthInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, widthInplace, item.width)">
                            <span pInplaceDisplay>
                                {{ item.width ? item.width : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'width')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'width')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.LENGTH' | translate }}</span>
                        <p-inplace #lengthInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, lengthInplace, item.length)">
                            <span pInplaceDisplay>
                                {{ item.length ? item.length : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'length')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'length')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.HEIGHT' | translate }}</span>
                        <p-inplace #heightInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, heightInplace, item.height)">
                            <span pInplaceDisplay>
                                {{ item.height ? item.height : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'height')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'height')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.WEIGHT' | translate }}</span>
                        <p-inplace #weightInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, weightInplace, item.weight)">
                            <span pInplaceDisplay>
                                {{ item.weight ? item.weight : '-' }}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'weight')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'weight')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.CBM' | translate }}</span>
                        <p-inplace #cbmInplace
                                   [disabled]="viewOnly"
                                   (onActivate)="onActivateInplace($event, cbmInplace, item.cbm)">
                            <span pInplaceDisplay>
                                {{isNaN(item.width * item.length * item.height) ? '-' : (item.width * item.length * item.height / (100 * 100 * 100))}}
                            </span>
                            <span pInplaceContent>
                                <input class="col-6" pInputText type="number"
                                       (keydown.enter)="onSaveInplace($event, item, 'cbm')"
                                       (keydown.escape)="onCancelInplace($event)"
                                       [(ngModel)]="triggerredInplaceValue">
                                <i class="fas fa-check" (click)="onSaveInplace($event, item, 'cbm')"></i>
                                <i class="fas fa-times" (click)="onCancelInplace($event)"></i>
                            </span>
                        </p-inplace></td>
                    <td>
                        <i *ngIf="!viewOnly" class="fas fa-trash" (click)="onDeleteItem(item, index)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr  *ngIf="!viewOnly" [formGroup]="newItemForm">
                    <td>
                    </td>
<!--                    <td>-->
<!--                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.COMPANY_NAME' | translate }}</span>-->
<!--                        <field-input pInputText type="text" formControlName="brandName">-->
<!--                    </td>-->

                    <td class="d-flex align-items-center">
                        <ng-select formControlName="supplier"
                                   [required]="true"
                                   [class.invalid-brand-name]="newItemForm.controls.supplier.touched && newItemForm.controls.supplier.invalid"
                                   class="flex-1"
                                   #supplierElement
                                   [typeahead]="supplierSubject"
                                   [loading]="supplierLoading"
                                   [items]="supplierList"
                                   (open)="!supplierList.length ? getSuppliers() : null"
                                   typeToSearchText="{{ 'GENERAL.SEARCH' | translate }}"
                                   loadingText="{{ 'GENERAL.SEARCHING' | translate }}"
                                   [appendTo]="'body'"
                                   notFoundText="{{ 'GENERAL.NO_RESULTS_FOUND' | translate }}"
                                   placeholder="{{ 'ADD_SHIPMENT.LCL.SUPPLIER' | translate }}">
                        </ng-select>
                        <i class="fas fa-2x fa-plus-circle add-supplier-icon" (click)="CreateNewSupplier('')"></i>
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.PRODUCT_NAME' | translate }}</span>
                        <input pInputText type="text" formControlName="productName">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.DESCRIPTION' | translate }}</span>
                        <input pInputText type="text" formControlName="description">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.QUANTITY' | translate }}</span>
                        <input pInputText type="number" formControlName="quantity">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.BOX_NO' | translate }}</span>
                        <input pInputText type="text" formControlName="boxRange">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.BOX_QUANTITY' | translate }}</span>
                        <input pInputText type="number" formControlName="boxQuantity" (change)="setBoxNo()">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.WIDTH' | translate }}</span>
                        <input pInputText type="number" formControlName="width" [(ngModel)]="width">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.LENGTH' | translate }}</span>
                        <input pInputText type="number" formControlName="length" [(ngModel)]="length">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.HEIGHT' | translate }}</span>
                        <input pInputText type="number" formControlName="height" [(ngModel)]="height">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.WEIGHT' | translate }}</span>
                        <input pInputText type="number" formControlName="weight">
                    </td>
                    <td>
                        <span class="ui-column-title">{{ 'ADD_SHIPMENT.LCL.CBM' | translate }}</span>
                        <div>{{isNaN(width * length * height) ? '-' : (width * length * height / (100*100*100))}}</div>
                    </td>
                    <td>
                        <i class="fas fa-save" [ngClass]="{'disabled disabled-btn': newItemForm.invalid}"
                           (click)="onSaveItem()"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="footer">
        <div class="action-cancel" (click)="onCancel()" *ngIf="!viewOnly">
            {{ 'ACTIONS.CANCEL' | translate }}
        </div>
        <div class="action-submit" (click)="onSubmit()">
            {{ 'ACTIONS.DONE' | translate }}
        </div>
    </div>

</div>
