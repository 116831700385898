<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <p-sidebar position="{{currentLang === 'en' ? 'right' : 'left'}}" [(visible)]="isShow" (onHide)="onClose()" [dismissible]="false" styleClass="hub-details-sidebar">
        <div class="main-container">
            <div class="header">
                <div class="header-title row">
                    <div class="title">
                        <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.BRANCH_DETAILS' | translate }}</span>
                        <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.BRANCH_DETAILS' | translate }}</span>
                        <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.WAREHOUSE_DETAILS' | translate }}</span>
                    </div>
                </div>
                <div class="hub-details-actions row">
                    <div class="action update-action d-inline-flex" (click)="modifyHub()">
                        <div class="icon">
                            <img src="../../assets/icons/containers-management/edit.svg" alt="icon" />
                        </div>
                        <div class="action-hidden-space"></div>
                        <div class="label">
                            <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</span>
                            <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.ACTIONS.EDIT_BRANCH' | translate }}</span>
                            <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.EDIT_WAREHOUSE' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="!isWarehouse && permissions.includes('DELETE')" class="action remove-action d-inline-flex" (click)="removeHub()">
                        <div class="icon" *ngIf="!deleteHubLoader">
                            <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
                        </div>
                        <div class="loading" *ngIf="deleteHubLoader">
                            <i class="fas fa-spinner fa-pulse"></i>
                        </div>
                        <div class="action-hidden-space"></div>
                        <div class="label" *ngIf="!isStations">{{ 'BRANCHES_MANAGEMENT.ACTIONS.DELETE_BRANCH' | translate }}</div>
                        <div class="label" *ngIf="isStations">{{ 'STATIONS_MANAGEMENT.ACTIONS.DELETE_BRANCH' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="details-container">
                <div class="details-table">
                    <table>
                        <tr>
                            <td class="td-label">
                                <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.TABLE.BRANCH_NAME' | translate }}</span>
                                <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.TABLE.BRANCH_NAME' | translate }}</span>
                                <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME' | translate }}</span>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-value">{{hub?.name}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="td-label">
                                <span *ngIf="!isWarehouse && !isStations">{{ 'BRANCHES_MANAGEMENT.TABLE.BRANCH_BARCODE' | translate }}</span>
                                <span *ngIf="!isWarehouse && isStations">{{ 'STATIONS_MANAGEMENT.TABLE.BRANCH_BARCODE' | translate }}</span>
                                <span *ngIf="isWarehouse">{{ 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_BARCODE' | translate }}</span>
                            </td>
                            <td class="td-space"></td>
                            <td class="td-value">
                                <div class="d-inline-flex">
                                    <div class="hub-barcode">#{{hub?.barcode}}</div>
                                    <div style="width: 12px;"></div>
                                    <div class="copy-icon cursor-pointer" (click)="copyBarcode(hub?.barcode)">
                                        <img src="../../assets/icons/track_package/copy.svg" alt="copy-icon" />
                                    </div>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        <ng-container *ngIf="showTableDetails">
                            <tr>
                                <td class="td-label">{{ 'BRANCHES_MANAGEMENT.TABLE.PHONE' | translate  }}</td>
                                <td class="td-space"></td>
                                <td class="td-value phone-number">{{hub?.phone}}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="td-label">{{ 'BRANCHES_MANAGEMENT.TABLE.BRANCH_TYPE' | translate }}</td>
                                <td class="td-space"></td>
                                <td class="td-value">{{hub?.type | translate}}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="td-label">{{ 'BRANCHES_MANAGEMENT.TABLE.ADDRESS' | translate }}</td>
                                <td class="td-space"></td>
                                <td class="td-value">{{getAddress(hub?.address)}}</td>
                                <td></td>
                            </tr>
                            <tr *ngIf="!isWarehouse">
                                <td class="td-label">{{ 'BRANCHES_MANAGEMENT.TABLE.NUMBER_OF_PACKAGES' | translate | packageTitle}}</td>
                                <td class="td-space"></td>
                                <td class="td-value packages-count d-flex">
                                    <div class="count-container">
                                        <span>{{hub?.packagesNumber}}</span>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <tr *ngIf="isWarehouse">
                                <td class="td-label">{{ 'GENERAL.PREFIX' | translate | packageTitle}}</td>
                                <td class="td-space"></td>
                                <td class="td-value">{{hub?.prefix}}</td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </table>
                    <div *ngIf="!isWarehouse" class="show_more" (click)="onToggleDetails()">
                        <span>
                            {{'GENERAL.DETAILS' | translate}}
                            <img src="../../assets/icons/arrow_down.svg" alt="icon" *ngIf="!showTableDetails" />
                            <img src="../../assets/icons/arrow_up.svg" alt="icon" *ngIf="showTableDetails" />
                        </span>
                    </div>
                </div>
            </div>
            <div class="row splitter-container">
                <div class="splitter"></div>
            </div>
            <div *ngIf="!isWarehouse" class="shelves-list-container">
                <app-shelves-management (copyShelveBarcode)="copyBarcode($event.barcode)" [isShowFullHubDetails]="showTableDetails" [hub]="hub? hub : null"></app-shelves-management>
            </div>
        </div>
    </p-sidebar>
</div>
