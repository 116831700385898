<ng-template #changePackageStatusDialog>
    <div style="width: 380px;overflow: auto !important;max-height: 450px">
        <app-change-package-status-component
                [shipments]="getSelectedPackages()"
                [isShowCurrentStatus]="false"
                (onFinish)="closeChangePackageStatusPopOver(changePackageStatusPopOver, true)"
                (onCancel)="closeChangePackageStatusPopOver(changePackageStatusPopOver, false)"
        >
        </app-change-package-status-component>
    </div>
</ng-template>
<div [ngClass]="{'page-ltr': selectedLanguage === 'en', 'page-rtl': selectedLanguage === 'ar'}">

    <p-overlayPanel #resolveFailurePanel [dismissable]="true" styleClass="change-cod-menu">
        <input type="file"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               #fileChooserWithValidation
               (change)="uploadExcel($event, false, true, true)">
      <div class="resolve-failed-container d-flex flex-column">
          <div class="change-cod">
              <div class="title">{{'MANAGE_SHIPMENTS.RESOLVE_FAILED_PACKAGE_DIALOG.FIX_FAILED' | translate}}</div>
          </div>
          <div class="input-container">
              <div class="input-item">
                  <label class="label" [ngClass]="{'failed-message-label-ltr': selectedLanguage === 'en'}">
                      {{'MANAGE_SHIPMENTS.RESOLVE_FAILED_PACKAGE_DIALOG.FAILED_MESSAGE' | translate}}
                  </label>
                  <textarea [(ngModel)]="resolveMsg" id="" cols="15" rows="10"></textarea>
              </div>
          </div>
          <div class="text-center m-b-10">
              <button class="submit-button" (click)="resolveFailedPackage()" *ngIf="!isSendingMsg">{{ 'MANAGE_SHIPMENTS.RESOLVE_FAILED_PACKAGE_DIALOG.RESOLVE_REQUEST' | translate }}</button>
              <i class="fas fa-spinner fa-spin" *ngIf="isSendingMsg"></i>
          </div>
          <div class="text-center m-b-10">
              <button class="close-button" (click)="resolveMsg = '';resolveFailurePanel.hide()" *ngIf="!isSendingMsg">{{ 'ACTIONS.CLOSE' | translate }}</button>
          </div>
      </div>
    </p-overlayPanel>

    <div class="container-details-container overflow-auto">
        <app-shipment-tracker [isShow]="isTrackingPackage"
                              [archivedMode]="archivedMode"
                              (onCloseTracker)="closeTracker($event)"
                              [packageBarcode]="triggeredDropdownShipment?.barcode"
                              [showHeaderActions] = "!isCustomerAccountManager()"
                              class="component"></app-shipment-tracker>
    </div>

    <div *ngIf="translationLoaded" class="shipment-container" style="overflow-x: hidden !important;">
        <p-overlayPanel #shipmentOptions [dismissable]="true" styleClass="shipment-popover" [appendTo]="'body'" [autoZIndex] = "true">
            <div class="row-action-container">
                <div *ngIf="!triggeredDropdownShipment.isFailureResolved && triggeredDropdownShipment.isFailed && ['SCANNED_BY_HANDLER_AND_UNLOADED', 'SCANNED_BY_DRIVER_AND_IN_CAR'].indexOf(triggeredDropdownShipment.status) !== -1"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked(); notifyResolveCodPanel(triggeredDropdownShipment.id)">
                    <img class="edit-button" src="../../../assets/icons/manage_shipments/alarm-notify.svg"
                         alt="icon"/>
                    {{'MANAGE_SHIPMENTS.RESOLVE_FAILED_PACKAGE_NOTIFICATION' | translate}}
                </div>
                <div *appPermission="permissions.includes('{EDIT_PACKAGE') && !triggeredDropdownShipment.isFailureResolved && triggeredDropdownShipment.isFailed && !partnerId"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();showResolveCodPanel($event)">
                    <img class="edit-button" src="../../../assets/icons/manage_shipments/edit.svg"
                         alt="icon"/>
                    {{'MANAGE_SHIPMENTS.RESOLVE_FAILED_PACKAGE' | translate}}
                </div>

                <div *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();editPackage()">
                    <img class="edit-button" src="../../../assets/icons/manage_shipments/edit.svg"
                         alt="icon"/>
                    {{'MANAGE_SHIPMENTS.EDIT' | translate}}
                </div>
                <div *appPermission="permissions.includes('{REMOVE_PACKAGE') && !partnerId && canRemovePkgs"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();removePackage()">
                    <img class="remove-button" src="../../../assets/icons/manage_shipments/remove.svg"
                         alt="icon"/>
                    {{'MANAGE_SHIPMENTS.DELETE_PACKAGE' | translate | packageTitle}}
                </div>
                <div *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId && !isLcl"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();onPostponePackage()">
                    <i class="fas fa-clock"></i>
                    {{'MANAGE_SHIPMENTS.POSTPONE_PACKAGE' | translate | packageTitle}}
                </div>
                <div *appPermission="permissions.includes('{EDIT_PACKAGE')" class="action cursor-pointer more-action-item"
                     (click)="shipmentOptionClicked();trackShipment(triggeredDropdownShipment)">
                    <i class="fas fa-history"></i>
                    {{'MANAGE_SHIPMENTS.SHOW_PACKAGE_HISTORY' | translate | packageTitle}}
                </div>
                <div *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();onPostponePackage(true)">
                    <i class="fas fa-sticky-note"></i>
                    {{'MANAGE_SHIPMENTS.ADD_NOTE' | translate}}
                </div>
                <div *appPermission="permissions.includes('{SWAP_SENDER_RECEIVER') && (userRole === 'CUSTOMER_CARE' ? !!userInfo.isSwapSenderAndReceiver : true) && !partnerId && !isLcl"
                     class="action cursor-pointer more-action-item"
                     (click)="shipmentOptionClicked();swapTheSenderAndReceiver(triggeredDropdownShipment)">
                    <img class="edit-button" src="../../assets/icons/manage_shipments/swap.png" alt=""/>
                    {{'MANAGE_SHIPMENTS.SWAP_RECEIVER_SENDER' | translate}}
                </div>
                <div *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId && allowAddAttachments()"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();addAttachments()">
                    <i class="fas fa-paperclip"></i>
                    {{'USERS_MANAGEMENT.ADD_USER_FORM.ADD_ATTACHMENTS' | translate}}
                </div>
                <div *appPermission="permissions.includes('{EDIT_PACKAGE') && isLcl"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();addAttachments(true)">
                    <i class="fas fa-money"></i>
                    {{ 'LCL.ACTIONS.MARK_AS_LCL_PAID' | translate}}
                </div>

                <div *appPermission="isShowSendPinCodeAction(triggeredDropdownShipment)"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();generateVerificationPinCode(triggeredDropdownShipment.id)">
                    <img class="edit-button" src="../../assets/icons/manage_shipments/pin-code.png" alt=""/>
                    {{ 'MANAGE_SHIPMENTS.GENERATE_VERIFICATION_PIN_CODE' | translate}}
                </div>
                <div *ngIf="isLcl"
                     class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();printPackage(triggeredDropdownShipment)">
                    <i *ngIf="triggeredDropdownShipment.printLoader" class="fas fa-spinner"></i>
                    <img *ngIf="!triggeredDropdownShipment.printLoader" class="edit-button" src="../../assets/icons/print.svg" alt=""/>
                    {{ 'ACTIONS.PRINT' | translate}}
                </div>
<!--                put the condition if bundle config is enabled-->
                <div *ngIf="triggeredDropdownShipment.isBundle" class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();addPackageToBundle(triggeredDropdownShipment.id)">
                    <i *ngIf="triggeredDropdownShipment.printLoader" class="fas fa-spinner"></i>
                    <img class="edit-button"
                         src="../../../assets/icons/manage_shipments/edit.svg"
                         alt="icon"/>
                    {{ 'MANAGE_SHIPMENTS.ADD_PACKAGE_TO_THE_BUNDLE' | translate}}
                </div>
                <div *ngIf="triggeredDropdownShipment.integrationDestination == 'JNT'" class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();printJNT()">
                    <i *ngIf="triggeredDropdownShipment.printLoader" class="fas fa-spinner"></i>
                    <img *ngIf="!triggeredDropdownShipment.printLoader" class="edit-button" src="../../assets/icons/print.svg" alt=""/>
                    {{ 'ACTIONS.PRINT_JNT' | translate}}
                </div>
                <div *ngIf="triggeredDropdownShipment.integrationDestination == 'JNT'" class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();cancelJNT()">
                    <i class="fas fa-close"></i>
                    {{ 'ACTIONS.CANCEL_JNT' | translate}}
                </div>
                <div *ngIf="triggeredDropdownShipment.integrationDestination == 'ARAMEX'" class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();printAramex()">
                    <i *ngIf="triggeredDropdownShipment.printLoader" class="fas fa-spinner"></i>
                    <img *ngIf="!triggeredDropdownShipment.printLoader" class="edit-button" src="../../assets/icons/print.svg" alt=""/>
                    {{ 'ACTIONS.PRINT_ARAMEX' | translate}}
                </div>
                <div class="action cursor-pointer more-action-item" (click)="shipmentOptionClicked();editPackage(true)" *ngIf="!(userInfo?.isDistributor) && (!isJettCompany() || (isJettCompany() && userRole !== 'CLERK'))">
                    <i class="fas fa-copy"></i>
                    {{ 'MANAGE_SHIPMENTS.CLONE_PACKAGE' | translate}}
                </div>
            </div>
        </p-overlayPanel>
        <p-overlayPanel #printPackagesOptions class="print-menu">
            <app-choose-print-type
                    (choosePrintType)="printPackagesBasedType($event, printPackagesOptions)"></app-choose-print-type>
        </p-overlayPanel>

        <p-overlayPanel #exportPanelOptions styleClass="export-menu" >
            <div>
                <!--            <div *appPermission="permissions.includes('{IMPORT_PACKAGES') && !partnerId" class="export-option" (click)="uploadFile(fileChooser)">-->
                <!--                <img src="../../../assets/icons/track_package/xls.svg" alt="" *ngIf="!isUploadingExcel" />-->
                <!--                <i class="fas fa-spinner fa-pulse" *ngIf="isUploadingExcel"></i>-->
                <!--                <span style="padding-right: 10px;">{{"ACTIONS.IMPORT_EXCEL" | translate}}</span>-->
                <!--                <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" #fileChooser (change)="uploadExcel($event,false,true)" />-->
                <!--            </div>-->
                <div *appPermission="permissions.includes('{EXPORT_PACKAGES')" class="export-option"
                     (click)="downloadCSV()">
                    <div class="icon">
                        <img src="../../../assets/icons/track_package/xls.svg" alt="" *ngIf="!isExportingExcel"/>
                        <i class="fas fa-spinner fa-pulse" *ngIf="isExportingExcel"></i>
                    </div>
                    <div class="label">
                        <span>{{'ACTIONS.EXPORT' | translate}} Excel</span>
                    </div>
                </div>
                <!--            <div class="export-option" (click)="downloadExcelTemplate()">-->
                <!--                <img src="../../../assets/icons/track_package/xls.svg" alt="" *ngIf="!isDownloadingExcelTemplate" />-->
                <!--                <i class="fas fa-spinner fa-pulse" *ngIf="isDownloadingExcelTemplate"></i>-->
                <!--                <span style="padding-right: 10px !important;">{{'ACTIONS.DOWNLOAD_EXCEL_TEMPLATE' | translate}}</span>-->
                <!--            </div>-->
                <div class="export-option" *appPermission="permissions.includes('{IMPORT_PACKAGES') && !partnerId"
                     (click)="uploadFile(fileChooserWithValidation)">
                    <div class="icon">
                        <img src="../../../assets/icons/track_package/xls.svg" alt="" *ngIf="!isUploadingExcel">
                        <i class="fas fa-spinner fa-pulse" *ngIf="isUploadingExcel"></i>
                    </div>
                    <div class="label">
                        <span>{{"ACTIONS.IMPORT_FROM_EXCEL_WITH_VALIDATION" | translate}}</span>
                    </div>
                </div>
                <div class="export-option" (click)="downloadExcelTemplate(true)">
                    <div class="icon">
                        <img src="../../../assets/icons/track_package/xls.svg" alt=""
                             *ngIf="!isDownloadingExcelTemplate">
                        <i class="fas fa-spinner fa-pulse" *ngIf="isDownloadingExcelTemplate"></i>
                    </div>
                    <div class="label">
                        <span>{{'ACTIONS.DOWNLOAD_TEMPLATE_SUMMARIZED' | translate}}</span>
                    </div>
                </div>
            </div>
        </p-overlayPanel>
        <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               style="width: 0px; height: 0px; opacity: 0; position: absolute;" #fileChooserShipments
               (change)="uploadExceltoImport($event,true,true)"/>
        <ng-template #moreMenu let-close="close">
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId"
                        (click)="onPostponePackage(true, true)"
                        [label]="'MANAGE_SHIPMENTS.ADD_SPECIAL_NOTE'"
                        [disable]="!isThereSelection">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{RECEIVE_FROM_PARTNER') && !isLcl"
                        (onClick)="receivePackagesFromDriverOrPartner(true)"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.RECEIVE_PACKAGES_FROM_PARTNER'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{ADD_BUNDLE') && !partnerId && !isLcl && isBundleSupported"
                        (onClick)="goToChangePackageStatus()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.ADD_BUNDLE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{ADD_BUNDLE') && !partnerId && !isLcl && isBundleSupported"
                        (onClick)="uploadFileForBundles(fileChooserWithValidation)"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.ADD_BUNDLE_FROM_EXCEL'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId"
                        (onClick)="importTwoColumnExcel()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.EDIT_INVOICE_OF_SENDER'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{BULK_UPDATE') && !partnerId"
                        (onClick)="bulkUpdate()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.UPDATE_BULK'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{REMOVE_PACKAGES') && !partnerId"
                        (onClick)="deleteSelectedPackages()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.DELETE_SELECTED_SHIPMENTS'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{UNLISTED_PACKAGES') && !partnerId && isPickupUnlistedPackagesEnabled"
                        (onClick)="scanUnlistedPackages()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.SCAN_UNLISTED_PACKAGES'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId"
                        (onClick)="updateReceiverInfo()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.UPDATE_RECEIVER_INFO'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{EDIT_PACKAGE') && isTransferOutEnable() && !partnerId"
                        (onClick)="transferToThirdPart()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.TRANSFER_TO_THIRD_PART'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN_AS_ADMIN') && !partnerId"
                        (onClick)="importShipmentCosts()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.UPDATE_COST_BY_BARCODE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="currency === 'SAR'"
                        (onClick)="transferToOto()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_OTO'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 41 || companyId === 199"
                        (onClick)="transferToStorm()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_STORM'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 153"
                        (onClick)="transferToMughrabe()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_MUGHRABE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 160"
                        (onClick)="transferToOustol()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_OUSTOL'">
            </app-button>
            <ng-container *ngFor="let btn of transferToList">
                <app-button class="button secondary form-button"
                            *ngIf="companyId === 160"
                            (onClick)="genericTransferTo(btn)"
                            [disable]="!isThereSelection"
                            [label]="(btn.label | async)">
                </app-button>
            </ng-container>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 160"
                        (onClick)="transferToAutobahn()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_AUTOBAHN'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 236"
                        (onClick)="transferToAlkhal()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_ALKHAL'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="customizationCompaniesService.checkCompanies(companies.SEND)"
                        (onClick)="transferToAramex()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_ARAMEX'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 41"
                        (onClick)="transferToStorm()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_STORM'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 93"
                        (onClick)="transferToSpeedLine()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_SPEED_LINE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 225 || companyId === 199"
                        (onClick)="transferToSonic()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_SONIC'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 24"
                        (onClick)="transferToEveryWhere()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_EVERYWHERE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="isTransferToHiExpressEnable()"
                        (onClick)="transferToToHiExpress()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_HI_EXPRESS'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="isTransferToAmanEnabled()"
                        (onClick)="transferToToAman()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_AMAN'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="userInfo && userInfo.isEnableImileTransfer"
                        (onClick)="transferToIMile()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_IMILE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="userInfo && customizationCompaniesService.checkCompanies(companies.SEND, companies.LOGESTECHS)"
                        (onClick)="transferToSMB()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_SMB'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="userInfo && customizationCompaniesService.checkCompanies(companies.FAST_ACCESS, companies.LOGESTECHS)"
                        (onClick)="transferToTorod()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_TOROD'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId === 254"
                        (onClick)="transferToToHawk()"
                        [disable]="!isThereSelection"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.TRANSFER_TO_HAWK'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="isJettCompany()"
                        (onClick)="printCustodyPackages()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_CUSTODY'">
            </app-button>
        </ng-template>
        <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               style="width: 0px; height: 0px; opacity: 0; position: absolute;" #fileChooserShipmentCosts
               (change)="editCostFromExcel($event, true)"/>

        <!--        mobile more actions list-->
        <ng-template #mobileMoreMenu let-close="close">
            <div class="mobile-more-actions-container"></div>
            <div class="action-container"
                 *appPermission="permissions.includes('{PRINT_DRIVER_PACKAGES') && !this.partnerId"
                 (click)="printDriverPackages()"
                 [ngClass]="{'disabled-button disabled': isBlock}">
                <div class="manage-shipment-action action">
                    <div class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_DRIVER_PACKAGES'| translate | packageTitle}}</div>
                </div>
            </div>
            <div
                    *appPermission="permissions.includes('{APPROVE_PACKAGES') && !partnerId"
                    [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedShipmentStatus === shipmentStatusAll}"
                    (click)="approvePackages()"
                    class="action-container"
            >
                <div class="manage-shipment-action action">
                    <div class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.APPROVE_THE_PACKAGES'| translate | packageTitle}}</div>
                </div>
            </div>
            <div class="action-container" *ngIf="companyId !== 256 && companyId !== 22 && !partnerId">
                <div
                        class="manage-shipment-action action"
                        *appPermission="permissions.includes('{ASSIGN_TO_DRIVER')"
                        [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedShipmentStatus === shipmentStatusAll}"
                        (click)="assignToDriver()">
                    <div class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN_TO_DRIVER'| translate}}</div>
                </div>
            </div>
            <div
                    class="action-container"
                    *appPermission="permissions.includes('{TRANSFER') && !partnerId && !isLcl"
                    [ngClass]="{'disabled-btn disabled': !isThereSelection || selectedShipmentStatus === shipmentStatusAll}"
                    (click)="exportPackagesTo()">
                <div class="manage-shipment-action action">
                    <div *ngIf="isEnableExportingBetweenHubs" class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_SHIPMENT_TO'| translate}}</div>
                    <div *ngIf="!isEnableExportingBetweenHubs" class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO_PARTNER'| translate}}</div>
                </div>
            </div>
            <!--            partner special buttons-->
            <div class="action-container"
                 *appPermission="permissions.includes('{CANCEL') && !!partnerId"
                    [ngClass]="{'disabled-button disabled': !isThereSelection ||
                                                            (selectedShipmentStatus !== 'PENDING_CUSTOMER_CARE_APPROVAL' && selectedShipmentStatus !== 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER' && selectedShipmentStatus !== 'CANCELLED')}"
                    (click)="cancelPackages()">
                <div class="manage-shipment-action action">
                    <div class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.CANCEL_PACKAGES'| translate}}</div>
                </div>
            </div>
        </ng-template>
        <!--        -->
        <ng-template #customizePopOver let-close="close">
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowPrice }">
                <p-inputSwitch [(ngModel)]="isShowPrice" (onChange)="tableCustomization('COST')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.COST' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowCOD }">
                <p-inputSwitch [(ngModel)]="isShowCOD" (onChange)="tableCustomization('COD')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.COD' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowSource }">
                <p-inputSwitch [(ngModel)]="isShowSource" (onChange)="tableCustomization('INTEGRATION_SOURCE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.INTEGRATION_SOURCE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowCustomer }">
                <p-inputSwitch [(ngModel)]="isShowCustomer" (onChange)="tableCustomization('CUSTOMER')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.CUSTOMER' | translate }}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowReceiverPhone }">
                <p-inputSwitch [(ngModel)]="isShowReceiverPhone"
                               (onChange)="tableCustomization('RECEIVER_PHONE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.RECEIVER_PHONE' | translate }}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowSender }">
                <p-inputSwitch [(ngModel)]="isShowSender" (onChange)="tableCustomization('SENDER')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.SENDER' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowWeight }">
                <p-inputSwitch [(ngModel)]="isShowWeight" (onChange)="tableCustomization('WEIGHT')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'HISTORY.WEIGHT' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowDriverEarning }" *ngIf="isDriverEarningEnabled">
                <p-inputSwitch [(ngModel)]="isShowDriverEarning" (onChange)="tableCustomization('DRIVER_EARNING')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'HISTORY.DRIVER_COST' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowThirdPartyTrackingNo }">
                <p-inputSwitch [(ngModel)]="isShowThirdPartyTrackingNo" (onChange)="tableCustomization('THIRD_PARTY_TRACKING_NO')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.THIRD_PARTY_TRACKING_NUMBER' | translate}}</span>
            </div>
            <div *ngIf="!isLcl" class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowReceiver }">
                <p-inputSwitch [(ngModel)]="isShowReceiver" (onChange)="tableCustomization('RECEIVER')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.RECEIVER' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowServiceType }">
                <p-inputSwitch [(ngModel)]="isShowServiceType"
                               (onChange)="tableCustomization('SERVICE_TYPE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.SERVICE_TYPE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowCustomerType }">
                <p-inputSwitch [(ngModel)]="isShowCustomerType" (onChange)="tableCustomization('CUSTOMER_TYPE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'HISTORY.CUSTOMER_TYPE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowBookedOnDate }">
                <p-inputSwitch [(ngModel)]="isShowBookedOnDate"
                               (onChange)="tableCustomization('CREATED_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.BOOKING_DATE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowFirstPickupDate }">
                <p-inputSwitch [(ngModel)]="isShowFirstPickupDate"
                               (onChange)="tableCustomization('FIRST_PICKUP_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{(isLcl ? 'LCL.SHIPMENT_TABLE.FIRST_PICKUP_DATE': 'SHIPMENT.TABLE.FIRST_PICKUP_DATE') | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowDeliveryDate }">
                <p-inputSwitch [(ngModel)]="isShowDeliveryDate"
                               (onChange)="tableCustomization('DELIVERY_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.DELIVERY_DATE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowNotes }">
                <p-inputSwitch [(ngModel)]="isShowNotes" (onChange)="tableCustomization('NOTES')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.NOTES' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowPostponedDate }">
                <p-inputSwitch [(ngModel)]="isShowPostponedDate"
                               (onChange)="tableCustomization('POSTPONED_DELIVERY_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.POSTPONE_DATE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowReceiverLocation }">
                <p-inputSwitch [(ngModel)]="isShowReceiverLocation"
                               (onChange)="tableCustomization('RECEIVER_LOCATION')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'GENERAL.RECEIVER_LOCATION' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch': !isShowDeliveryLocation }">
                <p-inputSwitch [(ngModel)]="isShowDeliveryLocation"
                               (onChange)="tableCustomization('DELIVERY_LOCATION')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'GENERAL.DELIVERY_LOCATION' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowAttachments }">
                <p-inputSwitch [(ngModel)]="isShowAttachments"
                               (onChange)="tableCustomization('ATTACHMENT')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'GENERAL.ATTACHMENT' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowInvoice }">
                <p-inputSwitch [(ngModel)]="isShowInvoice"
                               (onChange)="tableCustomization('INVOICE_NUMBER')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'SHIPMENT.TABLE.INVOICE' | translate}}</span>
            </div>
            <div *ngIf="!isLcl" class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowVehicleType }">
                <p-inputSwitch [(ngModel)]="isShowVehicleType"
                               (onChange)="tableCustomization('VEHICLE_TYPE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.VEHICLE_TYPE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowExpectedDeliveryDate }">
                <p-inputSwitch [(ngModel)]="isShowExpectedDeliveryDate"
                               (onChange)="tableCustomization('EXPECTED_DELIVERY_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.EXPECTED_DELIVERY_DATE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button" *ngIf="isLcl || companyHadTripNumbers.indexOf(companyId) !== -1"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowExpectedPickupDate }">
                <p-inputSwitch [(ngModel)]="isShowExpectedPickupDate"
                               (onChange)="tableCustomization('EXPECTED_PICKUP_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.EXPECTED_PICKUP_DATE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowCodStatus }">
                <p-inputSwitch [(ngModel)]="isShowCodStatus"
                               (onChange)="tableCustomization('COD_STATUS')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{(isLcl ? 'LCL.SHIPMENT_TABLE.COD_STATUS' : 'MANAGE_SHIPMENTS.COD_STATUS') | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowSignature }">
                <p-inputSwitch [(ngModel)]="isShowSignature"
                               (onChange)="tableCustomization('SIGNATURE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.SIGNATURE' | translate}}</span>
            </div>
            <div *ngIf="!isLcl" class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowOriginalCod }">
                <p-inputSwitch [(ngModel)]="isShowOriginalCod"
                               (onChange)="tableCustomization('ORIGINAL_COD')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.ORIGINAL_COD' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowDescription }">
                <p-inputSwitch [(ngModel)]="isShowDescription"
                               (onChange)="tableCustomization('DESCRIPTION')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'ADD_SHIPMENT.DESCRIPTION' | translate}}</span>
            </div>

            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowLastStatusDate }">
                <p-inputSwitch [(ngModel)]="isShowLastStatusDate"
                               (onChange)="tableCustomization('LAST_STATUS_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.LAST_ACTION_DATE' | translate}}</span>
            </div>
            <div class="manage-shipment-customize-switch switch-button"
                 [ngClass]="{'manage-shipment-disabled-customize-switch':!isShowLastPickupDate }">
                <p-inputSwitch [(ngModel)]="isShowLastPickupDate"
                               (onChange)="tableCustomization('LAST_PICKUP_DATE')"></p-inputSwitch>
                <span class="customize-item-label"
                      [ngClass]="{'customize-item-label-en': selectedLanguage === 'en'}">{{'MANAGE_SHIPMENTS.LAST_PICKUP_DATE' | translate}}</span>
            </div>
        </ng-template>

        <ng-template #PackageWeightDialog let-close="close">
            <app-customer-weight-filter [weightData]="packageWeightData"
                                        (onCancel)="closePackageWeightFilter()"
                                        (onFinish)="onFilterWeight($event, true)"></app-customer-weight-filter>
        </ng-template>

        <ng-template #CustomerRegionDialog let-close="close">
            <app-customer-region-filter [isSender]="true" [senderData]="senderRegionData"
                                        (onCancel)="closeSenderReceiverFilter(true)"
                                        (onFinish)="onFilterSenderReceiverRegion($event, true)"></app-customer-region-filter>
        </ng-template>
        <ng-template #ReceiverRegionDialog let-close="close">
            <app-customer-region-filter [isSender]="false" [receiverData]="receiverRegionData"
                                        (onCancel)="closeSenderReceiverFilter(false)"
                                        (onFinish)="onFilterSenderReceiverRegion($event, false)"></app-customer-region-filter>
        </ng-template>
        <div id="submitting-container" *ngIf="isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div>
            <p-toast [style]="{marginTop: '20px'}" [baseZIndex]="toastZIndex" *ngIf="enableToast"></p-toast>
        </div>
        <div *ngIf="!isFollowUpType && isNotifyNewUpdate && permissions.includes('UPDATES_BANNER')"  [ngClass]="{'ar-message': selectedLanguage === 'ar'}" class="p-messages">
            <div class="p-message p-message-warn">
                <div class="p-message-wrapper d-flex justify-content-between update-banner">
                    <div>
                        <span>{{'ALERTS.NEW_UPDATES_ADDED_STR_1' | translate }}</span>
                        <span class="text-underline cursor-pointer ml-1 mr-1" (click)="navigateTo('documents-system-updates')"> {{'ALERTS.NEW_UPDATES_ADDED_STR_2' | translate}}</span>
                        <span>{{'ALERTS.NEW_UPDATES_ADDED_STR_3' | translate }}</span>
                    </div>
                    <div class="p-message-close d-flex align-items-center justify-content-center cursor-pointer" [ngClass]="{'disabled-btn': markAllAsSeenRequest}" (click)="clearAllUpdatesNotification()">
                        <i class="fas fa-close"></i>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isFollowUpType && isPostponedPackagesInCurrentDate && permissions.includes('POSTPONED_PACKAGES_BANNER')" [ngClass]="{'ar-message': selectedLanguage === 'ar'}" class="p-messages">
           <div class="p-message p-message-info">
               <div class=" p-message-wrapper">
                   <span>{{('ALERTS.POSTPONED_PACKAGES' | translate) + ', '}}</span>
                   <span class="cursor-pointer text-underline ml-1 mr-1" (click)="navigateTo('pending-approval', {status: 'POSTPONED_DELIVERY'})">{{'ALERTS.CLICK_HERE_TO_CONTINUE' | translate }}</span>
               </div>
           </div>
        </div>

        <div class="shipment-header hidden-sm-down d-flex flex-row">
            <div class="header-filter total-number-container">
                <div class="title" *ngIf="!isFollowUpType">
                    {{(archivedMode ? 'ADMINISTRATION.ARCHIVED_SHIPMENTS' : 'MANAGE_SHIPMENTS.TITLE') | translate | packageTitle}}
                </div>
                <div class="title" *ngIf="isFollowUpType">
                    {{(archivedMode ? 'ADMINISTRATION.ARCHIVED_SHIPMENTS' : 'GENERAL.PENDING_APPROVAL') | translate | packageTitle}}
                </div>
                <div class="badge">
                    <div class="d-flex">
                        <span *ngIf="countResponse">{{totalRecords}}</span>
                        <span *ngIf="!countResponse">{{'-'}}</span>
                        <span class="selected-packages-separator" *ngIf="getSelectedPackages().length"></span>
                        <span *ngIf="getSelectedPackages().length">{{getSelectedPackages().length}}</span>
                    </div>
                </div>
            </div>
            <div class="badge-hidden-space"></div>
            <div></div>

            <div class="main-filters">
                <ng-container *ngIf="!archivedMode">
                    <div class="refresh-btn cursor-pointer d-flex align-items-center mb-2 pb-1" (click)="initShipments(true)">
                        <img src="../../../assets/icons/manage_shipments/refresh.png" alt=""/>
                    </div>

                    <app-field-set-form [showLabel]="false">
                        <div class="d-flex align-items-center min-width-120 justify-content-around" [ngClass]="{'toggle-toolbar-disabled': !isShowTopActionButtons}">
                            <span class="show-tools-toggle">{{'MANAGE_SHIPMENTS.TOOLBAR' | translate}}</span>
                            <div class="package-switch">
                                <p-inputSwitch [(ngModel)]="isShowTopActionButtons"></p-inputSwitch>
                            </div>
                        </div>
                    </app-field-set-form>
                    <app-field-set-form [showLabel]="false" *ngIf="!isSearchContent">
                        <div class="align-items-center d-flex justify-content-between p-1 px-2 min-width-120" (click)="triggerImportExportMenu($event, exportPanelOptions)">
                            <img src="../../../assets/icons/track_package/xls.svg"
                                 alt=""/>
                            <span>{{'ACTIONS.IMPORT_EXPORT' | translate}}</span>
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </app-field-set-form>

                </ng-container>

                <div class="d-flex align-items-center" *ngIf="archivedMode">
                    <div class="excel-button cursor-pointer" style="height: 30px;margin-top: -5px;"
                         (click)="downloadCSV()">
                        <i class="fas fa-file-excel"></i>
                        {{"ACTIONS.EXPORT_TO_EXCEL" | translate}}
                    </div>
                </div>
                <app-drop-down *ngIf="formInitiated"
                               [options]="dateTypesFilterOptions"
                               [showLabel]="false"
                               [formControl]="filterForm.get('selectedDateType')"
                               (whenChange)="onShipmentsTypeChanged()"
                               [showClear]="false"
                               [datakey]="'value'"
                               [optionLabel]="'label'"></app-drop-down>

                <app-date-range-picker (onSelect)="onDateSelected($event, dateRange)"></app-date-range-picker>

                <div class="toggle-filters-btn">
                    <div class="icon" (click)="showFiltersMenu($event)">
                        <img *ngIf="!filterMenuOpen" src="../../../../assets/icons/filter-icon.png"/>
                        <img *ngIf="filterMenuOpen" src="../../../../assets/icons/active-filter.png"/>
                    </div>
                </div>
                <app-field-set-form class="cursor-pointer customization-button"
                     *appPermission="permissions.includes('CUSTOMIZE_COLUMNS')"
                     [ngClass]="{'customization-button-en' :selectedLanguage === 'en'}" [showLabel]="false">
                    <div class="customization-button-content"
                         placement="{{selectedLanguage === 'en' ? 'bottom-right': 'bottom-left'}}" autoClose="outside"
                         [ngbPopover]="customizePopOver"
                         popoverTitle="{{'MANAGE_SHIPMENTS.SHOW_HIDE_COLUMNS' | translate}}">
                        <div><img src="../../../assets/icons/dashboard-customization-icon.svg" alt=""/></div>
                        <div class="customize-btn-label">{{'DASHBOARD.CUSTOMIZE' | translate}}</div>
                        <div class="customize-btn-caret-down"><img class="arrow-down"  src="../../../assets/icons/arrow-down.png"></div>
                    </div>
                </app-field-set-form>
            </div>

            <div class="search-toggle cursor-pointer">
                <div class="manage-shipments-search-filter search-filter-container d-flex">
                    <div class="d-flex">
                        <div class="search-filter-toggle" (click)="toggleIsSearchContent(true)">
                            <img style="width: 100%;" src="../../../assets/icons/search.png" alt="search-Image"/>
                            <!--                            <i class="fas fa-search" [ngClass]="{'fa-rotate-90': selectedLanguage==='ar'}"></i>-->
                        </div>
                        <div class="search-input" *ngIf="isSearchContent">
                            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..."
                                   [(ngModel)]="searchContent" (input)="onSearch()"/>
                        </div>
                    </div>
                    <div *ngIf="isSearchContent" class="hide-search-filter" (click)="toggleIsSearchContent(false)">
                        <span>{{'ACTIONS.CANCEL' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isShowTopActionButtons && !archivedMode" class="d-flex flex-wrap actions-container hidden-sm-down">

            <app-button class="button primary form-button"
                        *appPermission="permissions.includes('{CANCEL') && !!partnerId"
                        (onClick)="goToCancel()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.CANCEL_BY_BARCODE'">
            </app-button>
            <app-button class="button primary form-button"
                        *appPermission="permissions.includes('{IMPORT_PACKAGES') && this.isLcl && !this.partnerId"
                        (onClick)="receivePackingList()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.RECEIVE_PACKING_LIST'">
            </app-button>
            <app-button class="button primary form-button"
                        *appPermission="permissions.includes('{IMPORT_PACKAGES') && this.isLcl && !this.partnerId"
                        (onClick)="changeContainerStatus()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.UNLOAD_CONTAINER'">
            </app-button>
            <app-button class="button primary form-button"
                        *appPermission="permissions.includes('{IMPORT_PACKAGES') && this.isLcl && !this.partnerId"
                        (onClick)="changeContainerStatus(true)"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.RECEIVE_CONTAINER'">
            </app-button>
            <app-button class="button primary form-button"
                        *appPermission="permissions.includes('{RECEIVE_FROM_DRIVER') && !this.partnerId && !isLcl"
                        (onClick)="receivePackagesFromDriverOrPartner()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.RECEIVE_PACKAGES_FROM_DRIVER'">
            </app-button>
            <app-button class="button primary form-button"
                        *appPermission="permissions.includes('{PRINT_DRIVER_PACKAGES') && userRole !== 'CUSTOMER_ACCOUNT_MANAGER' && !this.partnerId && !isLcl && companyId !== 22 && companyId !== 256"
                        (onClick)="printDriverPackages()"
                        [disable]="isBlock"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_DRIVER_PACKAGES'">
            </app-button>
            <app-button class="button primary form-button"
                        *ngIf="!partnerId && (userRole !== 'CLERK' || ((companyId === 22 || companyId === 256) && userRole === 'CLERK')) && userRole !== 'HUB_CLERK' && userRole !== 'CUSTOMER_ACCOUNT_MANAGER'"
                        (onClick)="readByBarcode()"
                        [disable]="isBlock"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.READ_BY_BARCODE'">
            </app-button>
            <div class="actions-splitter"></div>
            <app-button class="button secondary form-button"
                        *ngIf="companyId !== 22 && companyId !== 256"
                        (onClick)="onGenerateRouteOptimization()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.GENERATE_OPTIMIZED_ROUTE'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{APPROVE_PACKAGES') && !partnerId"
                        (onClick)="approvePackages()"
                        [disable]="!isThereSelection || selectedShipmentStatus === shipmentStatusAll"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.APPROVE_THE_PACKAGES'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="(this.permissions.includes('{CHANGE_PACKAGE_STATUS') && !partnerId) || canEditShipmentStatus()"
                        [disable]="!getSelectedPackages().length || isBlock || selectedShipmentStatus === shipmentStatusAll"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.CHANGE_STATUS'"
                        #changePackageStatusPopOver="ngbPopover"
                        placement="bottom-left"
                        autoClose="outside"
                        [ngbPopover]="!getSelectedPackages().length || isBlock || selectedShipmentStatus === shipmentStatusAll ? null : changePackageStatusDialog">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="companyId !== 256 && companyId !== 22 && !partnerId && !isLcl"
                        (onClick)="assignToDriver()"
                        [disable]="!isThereSelection || selectedShipmentStatus === shipmentStatusAll"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.ASSIGN_TO_DRIVER'">
            </app-button>
            <app-button class="button secondary form-button"
                        (onClick)="triggerImportExportMenu($event, printPackagesOptions)"
                        [disable]="!isThereSelection || isLoading"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_REPORT'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{EDIT_PACKAGE') && !partnerId && isLcl"
                        (onClick)="createLCLContainer()"
                        [disable]="!isThereSelection || isLoading"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.CREATE_CONTAINER'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="(permissions.includes('{TRANSFER') || customizationCompaniesService.checkCompanies(companies.JETT) && userRole === 'CLERK') && !partnerId && !isLcl "
                        (onClick)="exportPackagesTo()"
                        [disable]="!isThereSelection || selectedShipmentStatus === shipmentStatusAll"
                        [label]="isEnableExportingBetweenHubs ? 'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_SHIPMENT_TO' : 'MANAGE_SHIPMENTS.ACTIONS_LABEL.EXPORT_TO_PARTNER'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="permissions.includes('{CANCEL') && !!partnerId"
                        (onClick)="cancelPackages()"
                        [disable]="!isThereSelection ||
                                 (selectedShipmentStatus !== 'PENDING_CUSTOMER_CARE_APPROVAL' &&
                                 selectedShipmentStatus !== 'APPROVED_BY_CUSTOMER_CARE_AND_WAITING_FOR_DISPATCHER' &&
                                 selectedShipmentStatus !== 'CANCELLED')"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.CANCEL_PACKAGES'">
            </app-button>
            <app-button class="button secondary form-button"
                        *appPermission="userRole !== 'CLERK' && userRole !== 'HUB_CLERK' && userRole !== 'CUSTOMER_ACCOUNT_MANAGER' && !partnerId && !isLcl && companyId !== 22 && companyId !== 256"
                        (onClick)="detectDriverTrack()"
                        [label]="'MANAGE_SHIPMENTS.ACTIONS_LABEL.SET_DRIVER_TRACK'">
            </app-button>
            <app-button class="button secondary form-button"
                        *ngIf="!isCustomerAccountManager()"
                        placement="bottom-start" autoClose="true" #moreActionsPopOver="ngbPopover"
                        [ngbPopover]="moreMenu"
                        [icon]="'fas fa-ellipsis-h'"
                        popoverClass="more-popover">
            </app-button>
        </div>
        <div class="shipment-body flex-1 hidden-sm-down"
             [ngClass]="{'shipment-body-on-hide-tools' : !isShowTopActionButtons}"
             (scroll)="onsScrollShipmentTable($event)">
            <p-table
                    class="overflow-auto w-100"
                    (scroll)="onsScrollShipmentTable($event)"
                    columnResizeMode="expand"
                    responsiveLayout="scroll"
                    [value]="shipmentsData"
                    #table
                    [rowHover]="true"
                    [customSort]="true"
                    [scrollable]="true"
                    scrollHeight="auto"
                    [lazy]="true"
                    (onLazyLoad)="loadPackagesLazy()"
                    [rows]="pageSize"
                    [totalRecords]="totalRecords"
                    currentPageReportTemplate="{totalRecords} entries"
                    styleClass="shipments-table flex-table p-datatable-customers"
                    [tableStyle]="{'border-spacing:': '10px;'}">
                <ng-template pTemplate="header">
                    <tr [ngClass]="{'tr-border': !isShowTopActionButtons}">
                        <th *ngIf="!archivedMode" class="barcode-th">
                            <div class="check-all-box">
                                <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                            (onChange)="onToggleSelectAll()"></p-checkbox>
                            </div>
                        </th>
                        <th *ngIf="isShowInvoice && companyId === 221" class="header-multiselect invoice-th">
                            <app-field-input [label] ="'MANAGE_SHIPMENTS.INVOICE'"
                                       #invoice
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.invoiceNumber.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th class="header-multiselect barcode-number-th">
                            <app-field-input [label] ="((isLcl && isFromLclContainersManagement) ? 'LCL.SHIPMENT_TABLE.CONTAINER_NUMBER' : 'SHIPMENT.TABLE.NUMBER')"
                                       #barcode
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.barcode.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       size="30"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th *ngIf="isShowSource" class="cod-th">
                            <app-drop-down [label]="'MANAGE_SHIPMENTS.INTEGRATION_SOURCE'"
                                           [isFilter]="true"
                                           [showClear]="false"
                                           [(ngModel)]="searchParameters.sourceType.value"
                                           [options]="sourceTypesList"
                                           (whenChange)="onShipmentsTypeChanged()"
                                           [filter]="true">
                            </app-drop-down>
                        </th>

                        <th *ngIf="isShowWeight" class="header-multiselect sender-th">
                            <app-field-set-form [label]="'MANAGE_SHIPMENTS.WEIGHT'"
                                                [isFilter]="true"
                                                [filterActive]=" isActiveWeighFilter()"
                                                [showLabel]="true">
                                <div class="filter-button cursor-pointer"
                                        #packageWeightPopOver="ngbPopover"
                                        placement="bottom-center"
                                        autoClose="true"
                                        [ngbPopover]="PackageWeightDialog"
                                        [ngClass]="{'active-filter-button': isActiveWeighFilter()}">
                                    <div class="customize-filter">
                                        <i [ngClass]="{'icon-customize-en': selectedLanguage === 'en'}" class="fas fa-pencil-alt"></i>
                                        <div class="customize-label"
                                             [ngClass]="{'customize-label-en': selectedLanguage === 'en'}">{{(isActiveWeighFilter() ? 'MANAGE_SHIPMENTS.ACTIVE_FILTER' : 'DASHBOARD.CUSTOMIZE') | translate}}</div>
                                    </div>
                                </div>
                            </app-field-set-form>

                        </th>

                        <th *ngIf="isShowWeight && customizationCompaniesService.checkCompanies(companies.JETT)">
                            <div class="default-filter">
                                {{'ADD_SHIPMENTS.VOLUMETRIC_WEIGHT' | translate}}
                            </div>
                        </th>
                        <th *ngIf="isShowThirdPartyTrackingNo" class="header-multiselect sender-th">
                            <app-field-input [label] ="'SHIPMENT.TABLE.THIRD_PARTY_TRACKING_NUMBER'"
                                       #thirdPartyTrackingNo
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.thirdPartyTrackingNo.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       size="30"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th *ngIf="customizationCompaniesService.checkCompanies(companies.JETT)" class="default-header customer-th">
                            <app-drop-down [label]="'MANAGE_SHIPMENTS.SHIPMENT_METHOD'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="selectedShipmentMethod.value"
                                          [options]="shipmentMethodList"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="onShipmentMethodSelected($event)"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>
                        <!---->

                        <th *ngIf="isShowPrice && !partnerId" class="default">
                            <app-field-input [label] ="(isLcl ? 'LCL.SHIPMENT_TABLE.COST': 'MANAGE_SHIPMENTS.COST')"
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.cost.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       [inputType]="'number'"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th *ngIf="isEnableExportingBetweenHubs && isHubUserRole" class="cod-th">
                            <div class="default-filter">
                                {{'MANAGE_SHIPMENTS.HUB_COST' | translate | currencyValue}}
                            </div>
                        </th>
                        <th *ngIf="isShowCOD" class="cod-th">
                            <div class="default-filter">
                                <ng-container *ngIf="isLcl">
                                    {{'LCL.SHIPMENT_TABLE.COD' | translate}}
                                </ng-container>
                                <ng-container *ngIf="!isLcl">
                                    {{'MANAGE_SHIPMENTS.COD' | translate | currencyValue}}
                                </ng-container>
                            </div>
                        </th>

                        <th *ngIf="isDriverEarningEnabled && isShowDriverEarning" class="default">
                            <app-field-input [label] ="'MANAGE_SHIPMENTS.DRIVER_COST'"
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.driverEarning.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       [inputType]="'number'"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
<!--                        special columns for lcl-->
                        <ng-container *ngIf="isLcl">
                            <th class="cod-th">{{'LCL.SHIPMENT_TABLE.SUPPLIERS' | translate}}</th>
                            <th class="cod-th">{{'LCL.SHIPMENT_TABLE.PAID_AMOUNT' | translate}}</th>
                            <th class="cod-th">{{'LCL.SHIPMENT_TABLE.TO_BE_PAID' | translate}}</th>
                        </ng-container>
<!--                        -->
                        <th *ngIf="isShowCustomer" class="default-header customer-th">
                            <app-multi-select [label]="'MANAGE_SHIPMENTS.CUSTOMER'"
                                              [isFilter]="true"
                                              [(ngModel)]="selectedMultiSelectCustomers"
                                              [options]="customersList"
                                              [optionLabel]="'name'"
                                              [optionValue]="'id'"
                                              [maxSelectedLabels]="0"
                                              (reset)="resetCustomersFilter()"
                                              (whenSelect)="onSelectCustomers()"
                                              (whenDeSelect)="onSelectCustomers()"
                                              (whenSelectAll)="onSelectCustomers(true)"
                                              (whenDeSelectAll)="onSelectCustomers()"
                                              (whenFilterChange)="filterCities($event)"
                                              [placeholder]="'ADMINISTRATION.CUSTOMER_LIST_DROPDOWN_PLACEHOLDER'"
                                              [filter]="true">
                            </app-multi-select>
                        </th>
                        <th *ngIf="isShowReceiverPhone" class="header-multiselect receiver-phone-th">
                            <app-field-input [label] ="(isLcl ? 'LCL.SHIPMENT_TABLE.RECEIVER_PHONE': 'ADD_SHIPMENT.RECEIVER_PHONE2')"
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.receiverPhone.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       [size]="30"
                                       [inputType]="'number'"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th *ngIf="companyHadTripNumbers.indexOf(companyId) !== -1" class="default-header customer-th">
                            <app-drop-down [label]="'MANAGE_SHIPMENTS.TRIP_NUMBER'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="selectedTrip.value"
                                          [options]="TripList"
                                          [autoDisplayFirst]="true"
                                          [defaultValue]="0"
                                          (whenChange)="onTripIdSelected($event)"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>
                        <th *ngIf="isShowSender" class="header-multiselect sender-th">
                            <app-field-set-form [label]="'SHIPMENT.TABLE.SENDER'"
                                                [isFilter]="true"
                                                [filterActive]=" isActiveSenderOrReceiverFilter(true)"
                                                [showLabel]="true">
                                <div class="filter-button cursor-pointer"
                                     #senderRegionPopOver="ngbPopover"
                                      placement="bottom-center"
                                      autoClose="outside"
                                      container="body"
                                      [ngbPopover]="CustomerRegionDialog"
                                     [ngClass]="{'active-filter-button': isActiveSenderOrReceiverFilter(true)}">
                                    <div class="customize-filter">
                                        <i [ngClass]="{'icon-customize-en': selectedLanguage === 'en'}" class="fas fa-pencil-alt"></i>
                                        <div class="customize-label" [ngClass]="{'customize-label-en': selectedLanguage === 'en'}">
                                            {{(isActiveSenderOrReceiverFilter(1) ? 'MANAGE_SHIPMENTS.ACTIVE_FILTER' : 'DASHBOARD.CUSTOMIZE') | translate}}
                                        </div>
                                    </div>
                                </div>
                            </app-field-set-form>
                        </th>
                        <th *ngIf="isShowReceiver" class="header-multiselect receiver-th">
                            <app-field-set-form [label]="'SHIPMENT.TABLE.RECEIVER'"
                                                [isFilter]="true"
                                                [filterActive]=" isActiveSenderOrReceiverFilter(false)"
                                                [showLabel]="true">
                                <div class="filter-button cursor-pointer"
                                     #senderRegionPopOver="ngbPopover"
                                     placement="bottom-center"
                                     autoClose="outside"
                                     container="body"
                                     [ngbPopover]="ReceiverRegionDialog"
                                     [ngClass]="{'active-filter-button': isActiveSenderOrReceiverFilter(false)}">
                                    <div class="customize-filter">
                                        <i [ngClass]="{'icon-customize-en': selectedLanguage === 'en'}" class="fas fa-pencil-alt"></i>
                                        <div class="customize-label" [ngClass]="{'customize-label-en': selectedLanguage === 'en'}">
                                            {{(isActiveSenderOrReceiverFilter(false) ? 'MANAGE_SHIPMENTS.ACTIVE_FILTER' : 'DASHBOARD.CUSTOMIZE') | translate}}
                                        </div>
                                    </div>
                                </div>
                            </app-field-set-form>
                        </th>
                        <th class="status-dropdown status-th" *ngIf="!isDeleted  && !archivedMode">
                            <ng-container *ngIf="isFollowUpType !== 'pending'; else NotesWithoutFilter">
                                <div class="d-flex">
                                    <app-drop-down [label]="'SHIPMENT.TABLE.STATUS'"
                                                  class="flex-1"
                                                  [isFilter]="true"
                                                  [showClear]="false"
                                                  [(ngModel)]="selectedShipmentStatus"
                                                  [options]="showPkgsPerReceiverCity ? pkgsPerReceiverCityStatusList : shipmentStatusList"
                                                  (whenChange)="onStatusChanged()"
                                                  [filter]="true"
                                    ></app-drop-down>
                                    <div *ngIf="['SCANNED_BY_DRIVER_AND_IN_CAR', 'SCANNED_BY_HANDLER_AND_UNLOADED'].indexOf(selectedShipmentStatus) !== -1" class="d-flex flex-column justify-content-center px-1">
                                        <div class="label flex-2d-center">{{'FAILED' | translate}}</div>
                                        <div class="filter flex-2d-center">
                                            <p-inputSwitch [(ngModel)]="isFailed"
                                                           (onChange)="initShipments(true)">
                                            </p-inputSwitch>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #NotesWithoutFilter>
                                <div class="default-filter">
                                    {{"SHIPMENT.TABLE.STATUS" | translate}}
                                </div>
                            </ng-template>
                        </th>

<!--                        failed status-->
                        <th *ngIf="isShowFailedStatus()" class="default-header status-th">
                            <app-drop-down [label]="'SHIPMENT.TABLE.FAILED_STATUS'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="selectedFailedStatus"
                                          [options]="failedStatusList"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="onFailedStatusChanged()"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>

                        <th *ngIf="showPkgsPerReceiverCity">
                            <app-auto-complete [label]="'MANAGE_SHIPMENTS.RECEIVER_CITY'"
                                               [isFilter]="true"
                                               [(ngModel)]="selectedReceiverCityName"
                                               [itemTemplate]="receiverItemCityName"
                                               [selectedItemTemplate]="receiverSelectedItemCityName"
                                               [suggestions]="receiverCityList"
                                               (completeMethod)="getReceiverCity($event)"
                                               (onSelect)="chooseReceiverCity($event)"
                                               (onClear)="onClearReceiverCity()">
                                <ng-template #receiverItemCityName let-receiverCity pTemplate="item">
                                    <div class="store-item">
                                        <div class="store-name text-ellipsis">{{selectedLanguage === 'ar' ? receiverCity.arabicName : receiverCity.name}}</div>
                                    </div>
                                </ng-template>
                                <ng-template #receiverSelectedItemCityName let-receiverCity pTemplate="selectedItem">
                                    <div class="store-item">
                                        <div class="store-name text-ellipsis">{{selectedLanguage === 'ar' ? receiverCity.arabicName : receiverCity.name}}</div>
                                    </div>
                                </ng-template>
                            </app-auto-complete>
                        </th>

                        <th *ngIf="selectedShipmentStatus === 'TRANSFERRED_OUT'" class="default-header status-th">
                            <app-users-list-dropdown-component [isPartner]="true"
                                                               [panelWidth]="'100%'" [inputStyleClass]="'width95'"
                                                               [isFilter]="true"
                                                               [label]="'PARTNER.PARTNER_NAME'"
                                                               [showLabel]="true"
                                                               (userChanged)="partnerSelected($event)">
                            </app-users-list-dropdown-component>
                        </th>

                        <th *ngIf="selectedShipmentStatus === 'COMPLETED'" class="default-header status-th">
                            <app-drop-down [label]="'MANAGE_SHIPMENTS.COMPLETED_SHIPMENT_STATUS'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="selectedCompletedShipmentStatus"
                                          [options]="completedShipmentStatusList"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="initShipments(true)"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>

                        <th *ngIf="showReturnedShipmentStatus()" class="default-header status-th">
                            <app-drop-down [label]="'MANAGE_SHIPMENTS.RETURNED_SHIPMENT_STATUS'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="selectedReturnedShipmentStatus"
                                          [options]="returnedShipmentStatusList"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="initShipments(true)"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>


                        <th *ngIf="showHubs()" class="default-header" style="padding-bottom: 10px;">
                                <app-users-list-dropdown-component [isHub]="true" [user]="hubId"
                                                                   #hubFilter
                                                                   [label]="(customizationCompaniesService.checkCompanies(companies.JETT) ? 'MANAGE_SHIPMENTS.STATION_NAME' : 'CHANGE_PACKAGE_STATUS.HUB_NAME')"
                                                                   [showLabel]="true"
                                                                   [isFilter]="true"
                                                                   [customizeDefaultValue]="!!(hubId || this.searchParameters.codHubId.value || this.searchParameters.exportedHubId.value)"
                                                                   [isGetAllHubs]="(['EXPORTED_TO_HUB', 'RECEIVED_IN_HUB'].indexOf(selectedShipmentStatus) > -1)"
                                                                   [panelWidth]="'100%'"
                                                                   (userChanged)="onHubSelected($event)"></app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="showtoHub()" class="default-header" style="padding-bottom: 10px;">
                                <app-users-list-dropdown-component [isHub]="true" [user]="toHubId"
                                                                   #hubFilter
                                                                   label="MANAGE_SHIPMENTS.DESTINATION_HUB"
                                                                   [showLabel]="true"
                                                                   [isFilter]="true"
                                                                   [customizeDefaultValue]="!!(hubId || this.searchParameters.codHubId.value || this.searchParameters.exportedHubId.value)"
                                                                   [isGetAllHubs]="(['EXPORTED_TO_HUB', 'RECEIVED_IN_HUB'].indexOf(selectedShipmentStatus) > -1)"
                                                                   [panelWidth]="'100%'"
                                                                   (userChanged)="ontoHubSelected($event)"></app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="customizationCompaniesService.checkCompanies(companies.JETT) || (customizationCompaniesService.checkCompanies(companies.SPRINT) && isFollowUpType && isFollowUpType === 'BUNDLES')">
                            <app-users-list-dropdown-component [isHub]="true" [user]="fromHub"
                                                               [label]="(customizationCompaniesService.checkCompanies(companies.SPRINT) ? 'ACCOUNTING.FROM_HUB' : 'TRIPS_BETWEEN_CITIES.FORM.FROM_STATION')"
                                                               [showLabel]="true"
                                                               [isFilter]="true"
                                                               #hubFilter
                                                               [isGetAllHubs]="isJettCompany()"
                                                               [panelWidth]="'100%'"
                                                               (userChanged)="onFromHubSelected($event)"></app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="(customizationCompaniesService.checkCompanies(companies.JETT) && selectedShipmentMethod.value !== 'STORE_TO_DOOR_B')|| (customizationCompaniesService.checkCompanies(companies.SPRINT) && isFollowUpType && isFollowUpType === 'BUNDLES')">
                            <app-users-list-dropdown-component [isHub]="true" [user]="toHub"
                                                               #hubFilter
                                                               [label]="customizationCompaniesService.checkCompanies(companies.SPRINT) ? 'ACCOUNTING.TO_HUB':'TRIPS_BETWEEN_CITIES.FORM.TO_STATION'"
                                                               [showLabel]="true"
                                                               [isFilter]="true"
                                                               [isGetAllHubs]="isJettCompany()"
                                                               [panelWidth]="'100%'"
                                                               (userChanged)="onToHubSelected($event)"></app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="showPickupTypeFilter()" class="default-header" style="padding-bottom: 10px;">
                            <app-drop-down [label]="'MANAGE_SHIPMENTS.PICKUP_TYPE'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="selectedPickupType"
                                          [options]="pickupTypeList"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="initShipments(true)"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>
                        <th *ngIf="showDriversFilter()" class="default-header" style="padding-bottom: 10px;">
                            <app-users-list-dropdown-component [isDriver]="true" [user]="searchParameters.driverId.value"
                                                               [panelWidth]="'100%'"
                                                               [label]="(selectedShipmentStatus === 'EXPORTED_TO_THIRD_PARTY' ? 'ADMINISTRATION.THIRD_PARTY_LIST_DROPDOWN_PLACEHOLDER' : 'DASHBOARD.SELECT_DRIVER')"
                                                               [showLabel]="true"
                                                               [isFilter]="true"
                                                               [notOnlyTypical]="selectedShipmentStatus === 'RETURNED_BY_RECIPIENT' || selectedShipmentStatus === 'DELIVERED_TO_RECIPIENT'"
                                                               [isThirdParty]="selectedShipmentStatus === 'EXPORTED_TO_THIRD_PARTY'"
                                                               (userChanged)="onDriverSelected($event)">
                            </app-users-list-dropdown-component>
                        </th>

                        <th *ngIf="showShelfFilter()" class="default-header" style="padding-bottom: 10px;">
                            <app-users-list-dropdown-component [isOnShelf]="true" [user]="shelfId"
                                                               [label]="'DASHBOARD.SELECT_SHELF'"
                                                               [showLabel]="true"
                                                               [isFilter]="true"
                                                               [panelWidth]="'100%'"
                                                               [hubId]="hubId"
                                                               (userChanged)="onShelfSelected($event)">
                            </app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="showDeliveredByFilter()" class="default-header" style="padding-bottom: 10px;">
                            <app-users-list-dropdown-component #deliveredByFilter [isDriver]="true"
                                                               [showLabel]="true"
                                                               [label]="'DASHBOARD.SELECT_DRIVER'"
                                                               [isFilter]="true"
                                                               [user]="searchParameters.deliveredBy.value"
                                                               [panelWidth]="'100%'"
                                                               (userChanged)="onDeliveredBySelected($event)">
                            </app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="isShowInvoice && companyId !== 221" class="header-multiselect invoice-th">
                            <app-field-input [label] ="'MANAGE_SHIPMENTS.INVOICE'"
                                       [isFilter]="true"
                                       [icon]="'fas fa-search'"
                                       [(ngModel)]="searchParameters.invoiceNumber.value"
                                       [placeholder]="'GENERAL.SEARCH'"
                                       [showIcon]="true"
                                       [size]="30"
                                       [inputType]="'text'"
                                       (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th class="auto-complete-filter-th" *ngIf="isShowVehicleType">
                            <app-auto-complete [label]="'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.VEHICLE_TYPE'"
                                               [isFilter]="true"
                                               [isForm]="false"
                                               [(ngModel)]="selectedVehicleType"
                                               [suggestions]="vehicleTypesList"
                                               [itemTemplate]="vehicleItem"
                                               [field]="selectedLanguage === 'ar' ? 'arabicName' : 'name'"
                                               (completeMethod)="getVehicleTypes($event)"
                                               (onSelect)="chooseVehicleType($event)"
                                               (onClear)="onClearVehicleTypeFilter()">
                                <ng-template #vehicleItem let-type pTemplate="item">
                                    <div class="store-item">
                                        <div class="store-name text-ellipsis">{{selectedLanguage === 'ar' ? type.arabicName : type.name}}</div>
                                    </div>
                                </ng-template>
                            </app-auto-complete>
                        </th>
                        <th *ngIf="isShowCodStatus && !isLcl" class="default-header status-th">
                            <app-multi-select [label]="'MANAGE_SHIPMENTS.COD_STATUS'"
                                              [isFilter]="true"
                                              [(ngModel)]="selectedMultiSelectCodStatus"
                                              [options]="codStatusList"
                                              [maxSelectedLabels]="0"
                                              (reset)="resetCustomersFilter()"
                                              (whenSelect)="onSelectCustomers(false, true)"
                                              (whenDeSelect)="onSelectCustomers(false, true)"
                                              (whenSelectAll)="onSelectCustomers(true)"
                                              (whenDeSelectAll)="onSelectCustomers()"
                                              (whenClick)="onClickCodStatusMultiSelect()"
                                              [placeholder]="'MANAGE_SHIPMENTS.ALL'">
                            </app-multi-select>
                        </th>
                        <th *ngIf="isShowCodStatus && isLcl" class="">
                                <span>{{'LCL.SHIPMENT_TABLE.COD_STATUS' | translate}}</span>
                        </th>
                        <th *ngIf="showHubsCodStatusFilter()" class="default-header" style="padding-bottom: 10px;">
                            <app-users-list-dropdown-component [isHub]="true" [user]="searchParameters.codHubId.value"
                                                               [panelWidth]="'100%'"
                                                               [label]="'CHANGE_PACKAGE_STATUS.HUB_NAME'"
                                                               [showLabel]="true"
                                                               [isFilter]="true"
                                                               (userChanged)="onHubSelected($event, true)"></app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="showDriversCodStatusFilter()" class="default-header" style="padding-bottom: 10px;">
                                <app-users-list-dropdown-component [isDriver]="true" [user]="searchParameters.codDriverId.value"
                                                                   [panelWidth]="'100%'"
                                                                   [label]="'DASHBOARD.SELECT_DRIVER'"
                                                                   [showLabel]="true"
                                                                   [isFilter]="true"
                                                                   (userChanged)="onDriverSelected($event, true)">
                                </app-users-list-dropdown-component>
                        </th>
                        <th *ngIf="isShowServiceType" class="default-header status-th">
                            <ng-container *ngIf="!userService.userInfo.isPricingPerServiceTypeEnabled; else dynamicServiceTypes">
                                <app-drop-down [label]="'MANAGE_SHIPMENTS.SERVICE_TYPE'"
                                              [isFilter]="true"
                                              [showClear]="false"
                                              [(ngModel)]="searchParameters.serviceType.value"
                                              [options]="serviceTypesList"
                                              [autoDisplayFirst]="true"
                                              (whenChange)="onStatusChanged()"
                                              [filter]="true"
                                ></app-drop-down>
                            </ng-container>
                            <ng-template #dynamicServiceTypes>
                                <ng-container *ngIf="userService.userInfo.isPricingPerServiceTypeEnabled">
                                    <app-auto-complete [label]="'MANAGE_SHIPMENTS.SERVICE_TYPE'"
                                                       [isFilter]="true"
                                                       [isForm]="false"
                                                       [(ngModel)]="selectedServiceType"
                                                       [suggestions]="serviceTypesAcList"
                                                       [itemTemplate]="serviceTypeItem"
                                                       [field]="selectedLanguage === 'ar' ? 'arabicName' : 'name'"
                                                       (completeMethod)="getServiceTypes($event)"
                                                       (onSelect)="chooseServiceType($event)"
                                                       (onClear)="onClearServiceTypeFilter()">
                                        <ng-template #serviceTypeItem let-serviceType pTemplate="item">
                                            <div class="store-item">
                                                <div class="store-name text-ellipsis">{{selectedLanguage === 'ar' ? serviceType.arabicName : serviceType.name}}</div>
                                            </div>
                                        </ng-template>
                                    </app-auto-complete>
                                </ng-container>
                            </ng-template>
                        </th>
                        <th *ngIf="isShowShipmentType" class="default-header status-th">
                            <app-drop-down [label]="'ADD_SHIPMENTS.PAYMENT_METHOD'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="searchParameters.shipmentType.value"
                                          [options]="shipmentTypesList"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="onStatusChanged()"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>
<!---->
                        <th *ngIf="isShowCustomerType" class="default-header status-th">
                            <app-drop-down [label]="'ADD_SHIPMENTS.CUSTOMER_TYPE'"
                                          [isFilter]="true"
                                          [showClear]="false"
                                          [(ngModel)]="searchParameters.customerType.value"
                                          [options]="customerTypes"
                                          [autoDisplayFirst]="true"
                                          (whenChange)="onStatusChanged()"
                                          [filter]="true"
                            ></app-drop-down>
                        </th>

                        <th *ngIf="isShowDescription" class="header-multiselect receiver-phone-th">
                            <app-field-input [label] ="'ADD_SHIPMENT.DESCRIPTION'"
                                             [isFilter]="true"
                                             [icon]="'fas fa-search'"
                                             [(ngModel)]="searchParameters.description.value"
                                             placeholder="GENERAL.SEARCH"
                                             [showIcon]="true"
                                             [size]="30"
                                             [inputType]="'text'"
                                             (input)="initShipments(true)">
                            </app-field-input>
                        </th>
                        <th *ngIf="isShowBookedOnDate" class="header-multiselect booked-on-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.BOOKED_ON'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.createdDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('createdDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>
                        <th *ngIf="isShowFirstPickupDate" class="header-multiselect booked-on-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="(isLcl ? 'LCL.SHIPMENT_TABLE.FIRST_PICKUP_DATE': 'SHIPMENT.TABLE.FIRST_PICKUP_DATE')"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.firstPickupDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('firstPickupDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>
                        <th *ngIf="isShowDeliveryDate" class="header-multiselect data-filter delivery-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.DELIVERY_DATE'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.deliveryDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('deliveryDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>
                        <th *ngIf="isShowExpectedDeliveryDate"
                            class="header-multiselect data-filter expected-delivery-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.EXPECTED_DELIVERY_DATE'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.expectedDeliveryDate"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          [defaultValue]="{from: '', to: ''}"
                                          [rangeTime]="true"
                                          [customDefault]="'object'"
                                          (reset)="resetField('expectedDeliveryDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>

                        <th *ngIf="isShowLastStatusDate"
                            class="header-multiselect data-filter expected-delivery-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.LAST_ACTION_DATE'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.lastStatusDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('lastStatusDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>
                        <th *ngIf="isShowLastPickupDate"
                            class="header-multiselect data-filter expected-delivery-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.LAST_PICKUP_DATE'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.pickupDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('pickupDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>

                        <th *ngIf="isShowExpectedPickupDate && (isLcl||companyHadTripNumbers.indexOf(companyId) !== -1)"
                            class="header-multiselect data-filter expected-pickup-date-th">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.EXPECTED_PICKUP_DATE'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.expectedPickupDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('expectedPickupDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>

                        <th *ngIf="isShowPostponedDate" class="header-multiselect data-filter th-postponed-date"
                            style="width: 6%;">
                            <app-calendar showButtonBar="true"
                                          (whenClearClick)="initShipments(true)"
                                          [label]="'MANAGE_SHIPMENTS.POSTPONED_DATE'"
                                          [isFilter]="true"
                                          [(ngModel)]="searchParameters.postponedDeliveryDate.value"
                                          [maxDate]="toDate"
                                          (whenSelect)="initShipments(true)"
                                          (reset)="resetField('postponedDeliveryDate')"
                                          appendTo="body">
                            </app-calendar>
                        </th>
                        <th *ngIf="isShowNotes" class="th-notes">
                            <div class="d-flex flex-column">
                                <div class="default-filter">
                                    {{'GENERAL.NOTES' | translate}}
                                </div>
                                <div class="toggle-btn mt-2">
                                    <p-inputSwitch [(ngModel)]="isNotesAdded"
                                                   (onChange)="initShipments(true)"></p-inputSwitch>
                                </div>
                            </div>
                        </th>

                        <th *ngIf="isShowReceiverLocation" class="th-attachments">
                            <div class="check-filter">
                                <div class="d-flex justify-content-center">
                                    <span class="label">{{ 'GENERAL.RECEIVER_LOCATION' | translate }}</span>
                                </div>
                                <div class="values">
                                    <div class="item"
                                         (click)="setLocationFilter('ALL')"
                                         [ngClass]="{'active-item': selectedLocationFilter === 'ALL','b-radius-right': selectedLanguage === 'ar' , 'b-radius-left': selectedLanguage === 'en'}">{{'ALL' | translate}}</div>
                                    <div class="item flex-2d-center"
                                         (click)="setLocationFilter('WITH')"
                                         [ngClass]="{'active-item': selectedLocationFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                    <div class="item"
                                         (click)="setLocationFilter('WITHOUT')"
                                         [ngClass]="{'active-item': selectedLocationFilter === 'WITHOUT','b-radius-left': selectedLanguage === 'ar' , 'b-radius-right': selectedLanguage === 'en'}">{{'WITHOUT' | translate}}</div>
                                </div>
                            </div>
                        </th>

                        <th *ngIf="isShowDeliveryLocation" class="th-attachments">
                            <div class="check-filter">
                                <div class="d-flex justify-content-center">
                                    <span class="label">{{ 'GENERAL.DELIVERY_LOCATION' | translate }}</span>
                                </div>
                                <div class="values">
                                    <div class="item"
                                         (click)="setDeliveryLocationFilter('ALL')"
                                         [ngClass]="{'active-item': selectedDeliveryLocationFilter === 'ALL','b-radius-right': selectedLanguage === 'ar' , 'b-radius-left': selectedLanguage === 'en'}">{{'ALL' | translate}}</div>
                                    <div class="item flex-2d-center"
                                         (click)="setDeliveryLocationFilter('WITH')"
                                         [ngClass]="{'active-item': selectedDeliveryLocationFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                    <div class="item"
                                         (click)="setDeliveryLocationFilter('WITHOUT')"
                                         [ngClass]="{'active-item': selectedDeliveryLocationFilter === 'WITHOUT','b-radius-left': selectedLanguage === 'ar' , 'b-radius-right': selectedLanguage === 'en'}">{{'WITHOUT' | translate}}</div>
                                </div>
                            </div>
                        </th>

                        <th *ngIf="isShowAttachments" class="th-attachments">
                                <div class="check-filter">
                                    <div class="d-flex justify-content-center">
                                        <span class="label">{{ 'GENERAL.ATTACHMENTS' | translate }}</span>
                                    </div>
                                    <div class="values">
                                        <div class="item"
                                             (click)="setAttachmentFilter('ALL')"
                                             [ngClass]="{'active-item': selectedAttachmentsFilter === 'ALL','b-radius-right': selectedLanguage === 'ar' , 'b-radius-left': selectedLanguage === 'en'}">{{'ALL' | translate}}</div>
                                        <div class="item flex-2d-center"
                                             (click)="setAttachmentFilter('WITH')"
                                             [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                        <div class="item"
                                             (click)="setAttachmentFilter('WITHOUT')"
                                             [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITHOUT','b-radius-left': selectedLanguage === 'ar' , 'b-radius-right': selectedLanguage === 'en'}">{{'WITHOUT' | translate}}</div>
                                    </div>
                                </div>
                        </th>
                        <th *ngIf="isShowSignature" class="th-attachments">
                            <div class="check-filter">
                                <div class="d-flex justify-content-center">
                                    <span class="label">{{ 'MANAGE_SHIPMENTS.SIGNATURE' | translate }}</span>
                                </div>
                                <div class="values">
                                    <div class="item"
                                         (click)="setSignatureFilter('ALL')"
                                         [ngClass]="{'active-item': selectedSignatureFilter === 'ALL','b-radius-right': selectedLanguage === 'ar' , 'b-radius-left': selectedLanguage === 'en'}">{{'ALL' | translate}}</div>
                                    <div class="item flex-2d-center" (click)="setSignatureFilter('WITH')" [ngClass]="{'active-item': selectedSignatureFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                    <div class="item"
                                         (click)="setSignatureFilter('WITHOUT')"
                                         [ngClass]="{'active-item': selectedSignatureFilter === 'WITHOUT','b-radius-left': selectedLanguage === 'ar' , 'b-radius-right': selectedLanguage === 'en'}">{{'WITHOUT' | translate}}</div>
                                </div>
                            </div>
                        </th>
                        <th *ngIf="isShowOriginalCod" class="th-attachments">
                            <div class="d-inline-flex">
                                <div class="default-filter">
                                    {{ 'MANAGE_SHIPMENTS.ORIGINAL_COD' | translate }}
                                </div>
                            </div>
                        </th>
                        <th class="reset-filters-header">
                            <div class="reset-btn  cursor-pointer" (click)="resetAllFilters()">
                                <div class="row justify-content-center">
                                    <div class="refresh-icon">
                                        <img src="../../../assets/icons/manage_shipments/refresh.svg" alt=""/>
                                    </div>
                                    <div style="width: 5px;"></div>
                                    <div class="reset-filters-label">{{'MANAGE_SHIPMENTS.RESET_FILTERS' | translate}}</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <div class="empty-content">
                        <app-no-data-available-component></app-no-data-available-component>
                    </div>
                </ng-template>
                <ng-template pTemplate="body" let-package let-rowIndex="rowIndex">
                    <tr class="shipment-row"
                        [ngClass]="{'scanned-row':package.isSelected && !package.isBundle , 'bundle-row': package.isBundle,  'is-draft-background-color' : changeColorOfDraftPackages(package.isDraftPackageWithoutReceiverInfo)}"
                        (click)="trackShipment(package)">
                        <td *ngIf="!archivedMode" (click)="stopProp($event)" id="table_body_id">
                            <p-checkbox [(ngModel)]="package.isSelected" binary="true"
                                        (onChange)="onToggleSelection()"></p-checkbox>
                            <span *ngIf="package.flagToManagement" class="red-flag">
                                <i class="delete-red-flag fas fa-times-circle" (click)="removeRedFlag(package)"></i>
                                <i class="fas fa-flag"></i>
                            </span>
                            <ng-container *appPermission="permissions.includes('{COD_REQUEST')">
                                <div class="cod-change-request-container">
                                    <span *ngIf="package?.codChangeRequest && package?.codChangeRequest.status === 'PENDING'"
                                          (click)="onToggleCodRequest(package)" class="cod-request">
                                        {{'SHIPMENT.COD_REQUEST' | translate}}
                                    </span>
                                </div>
                            </ng-container>
                        </td>
                        <td class="number-data" *ngIf="isShowInvoice && companyId === 221">{{package.invoiceNumber}}</td>
                        <td class="barcode-number default">
                            <div class="d-flex" style="gap: 5px; justify-content:center;">
                                <span class="direction-ltr">#{{package.barcode}}</span>
                                <img src="../../../assets/icons/track_package/copy.svg" alt=""
                                     (click)="copyMessage($event,null, package.barcode)" data-toggle="tooltip"
                                     data-placement="top" title="Copy" class="copy_icon"/>
                            </div>
                        </td>
                        <td *ngIf="isShowSource" (click)="$event.stopPropagation();">
                            <div>
                                {{('INTEGRATION_SOURCE.' + package.integrationSource) | translate :{default: (package.integrationSource ? package.integrationSource : '')} }}
                            </div>
                        </td>
                        <td *ngIf="isShowWeight" (click)="$event.stopPropagation();">
                            <div>
                                <span class="cod-data">{{package.weight}}</span>
                            </div>
                        </td>
                        <td *ngIf="isShowWeight && customizationCompaniesService.checkCompanies(companies.JETT)" (click)="$event.stopPropagation();">
                            <div>
                                <span class="cod-data">{{(package.width * package.length * package.height / 5000) | number: '1.0-2' }}</span>
                            </div>
                        </td>
                        <td *ngIf="isShowThirdPartyTrackingNo" (click)="$event.stopPropagation();">
                            <div>
                                <span class="cod-data">{{package.thirdPartyTrackingNo}}</span>
                            </div>
                        </td>
                        <td *ngIf="customizationCompaniesService.checkCompanies(companies.JETT)">{{ package.orderId ? ('JETT.' + package.orderId | translate) : null }}</td>

                        <td *ngIf="isShowPrice && !partnerId" (click)="stopProp($event)" class="default" pEditableColumn
                            style="width: 80px;" [pEditableColumn]="package" [pEditableColumnField]="'cost'">
                                <app-edit-cost *ngIf="isAllowedEditCost(package)"
                                               [hideZeroValue]="notifyUnsetCost(package)" [package]="package"
                                               (onResetEvent)="initShipments()"></app-edit-cost>
                                <div *ngIf="!isAllowedEditCost(package)">
                                    <span class="cod-data">{{package.cost | currencyValue : (isFromLclContainersManagement ? ' ': package.currency)}}</span>
                                </div>
                        </td>

                        <td *ngIf="isEnableExportingBetweenHubs && isHubUserRole"
                            (click)="stopProp($event)" class="default"
                            [pEditableColumn]="package">
                            <div class="hub-cost flex-2d-center w-100">
                                <app-edit-cost *ngIf="!archivedMode"
                                               [package]="package"
                                               [attribute]="'hubCost'"
                                               successUpdateMsg="ALERTS.HUB_COST_UPDATED_SUCCESSFULLY"
                                               (onResetEvent)="initShipments()"></app-edit-cost>
                            </div>
                            <div *ngIf="archivedMode">
                                <span class="cod-data">{{package.hubCost | currencyValue : package.currency}}</span>
                            </div>
                        </td>

                        <td *ngIf="isShowCOD" pEditableColumn (click)="$event.stopPropagation();" >
                            <div>
                            <div *ngIf="userRole == 'CUSTOMER_ACCOUNT_MANAGER' && !this.partnerId && !isLcl" class="cod-data">
                                <app-edit-cost [package]="package" [attribute]="'cod'" successUpdateMsg="ALERTS.COD_UPDATED_SUCCESSFULLY"></app-edit-cost>

                            </div>
                            <div *ngIf="userRole != 'CUSTOMER_ACCOUNT_MANAGER' || this.partnerId || isLcl" class="cod-data" style="display: inline-block;min-width: 80px;">
                                {{package.cod | currencyValue : (isFromLclContainersManagement ? ' ' : package.currency)}}
                            </div>
                            </div>
                        </td>
                        <ng-container *ngIf="isDriverEarningEnabled && isShowDriverEarning">
                            <td pEditableColumn *ngIf="customizationCompaniesService.checkCompanies(companies.ABU_ALTAWSEEL)" (click)="$event.stopPropagation()">
                                <app-edit-cost [package]="package" [attribute]="'driverEarning'" successUpdateMsg="ALERTS.DRIVER_EARNING_UPDATED_SUCCESSFULLY"></app-edit-cost>
                            </td>
                            <td *ngIf="!customizationCompaniesService.checkCompanies(companies.ABU_ALTAWSEEL)">
                                {{package.driverEarning | currencyValue : package.currency}}
                            </td>
                        </ng-container>
<!--                        special columns for lcl-->
                        <ng-container *ngIf="isLcl">
                            <td [pTooltip]="getSuppliers(package.suppliers)">{{getSuppliers(package.suppliers)}}</td>
                            <td>{{package.paidAmount}}</td>
                            <td>{{package.toBePaid}}</td>
                        </ng-container>
<!--                        -->
                        <td *ngIf="isShowCustomer">
                            <span *ngIf="!isTrucking">{{package.customerName}}</span>
                            <span *ngIf="isTrucking">{{package.customerBusinessName ? package.customerBusinessName : package.customerName}}</span>
                        </td>
                        <td *ngIf="isShowReceiverPhone"
                            style="font-size: 12px; font-family: Helvetica; line-height: 14px; letter-spacing: 0.22px; color: #4e4e4e;">{{ package.receiverPhone }}</td>
                        <td *ngIf="companyHadTripNumbers.indexOf(companyId) !== -1">{{package.trip.tripNumber}}</td>
                        <td *ngIf="isShowSender">
                            <div class="receiver-sender-name"
                                 [ngClass]="{'receiver-sender-name-en': selectedLanguage === 'en'}">
                                <b *ngIf="!isTrucking"> {{package.senderFirstName}} {{package.senderMiddleName}} {{package.senderLastName}}</b>
                                <b *ngIf="isTrucking"> {{package.businessSenderName ? package.businessSenderName : (package.senderFirstName + ' ' + package.senderLastName)}}</b>
                                <br/>
                                <span style="font-family: 'almarai light';">
                                    {{package.originAddress.city }} - {{package.originAddress.village }}
                                    - {{package.originAddress.addressLine1}} {{(package.originAddress.addressLine2) ? ("- " + package.originAddress.addressLine2) : ''}}
                                </span>
                            </div>
                        </td>
                        <td *ngIf="isShowReceiver">
                            <div class="receiver-sender-name"
                                 [ngClass]="{'receiver-sender-name-en': selectedLanguage === 'en'}">
                                <b *ngIf="!isTrucking">{{package.receiverFirstName}} {{package.receiverMiddleName}} {{package.receiverLastName}}</b>
                                <b *ngIf="isTrucking">{{package.businessReceiverName ? package.businessReceiverName : package.receiverFirstName + ' ' + package.receiverLastName}}</b>
                                <br/>
                                <span style="font-family: 'almarai light';">
                                    {{package.destinationAddress.city }} - {{package.destinationAddress.village }}
                                    - {{package.destinationAddress.addressLine1}} {{package.destinationAddress.addressLine2 ? "- " +
                                    package.destinationAddress.addressLine2 : ''}}
                                </span>
                            </div>
                        </td>
                        <td (click)="$event.stopPropagation();" *ngIf="!isDeleted && !archivedMode">
                            <app-shipment-status [isEditEnabled]="canEditShipmentStatus() && !(package.isBundle && customizationCompaniesService.checkCompanies(companies.SPRINT))" [shipment]="package" [stopPropagation]='false' [customPlacement]="(shipmentsData.length>3 && (shipmentsData.length-rowIndex) <= 3)? '-bottom': ''"
                                                 (editStatus)="onChangePackageStatus(package)" [isBundle]="isFollowUpType"></app-shipment-status>
                            <div *ngIf="!partnerId" style="margin-top: 10px !important;">
                                <ng-container *ngIf="package.status === 'CREATED' && customizationCompaniesService.checkCompanies(companies.SPRINT) && isBundlesFollowUps && package.driverName">
                                    {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{package.driverName}}
                                </ng-container>
                                <ng-container [ngSwitch]="package.status">
                                    <!-- the same view can be shown in more than one case -->
                                    <div *ngSwitchCase="'TRANSFERRED_OUT'">
                                        <div *ngIf="!partnerId">
                                            {{'PARTNER.PARTNER_NAME' | translate}}: {{package.partnerName}}
                                        </div>
                                    </div>
                                    <ng-container *ngIf="companyHadTripNumbers.indexOf(companyId) === -1 && (package.driverName || package.partnerType)">
                                        <div *ngSwitchCase="'SCANNED_BY_DRIVER_AND_IN_CAR'">
                                            <div *ngIf="package.driverName">
                                                {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                            </div>
                                            <div *ngIf="!partnerId && package.partnerType">
                                                {{'PARTNER.PARTNER_NAME' | translate}}: {{package.partnerType}}
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="'ACCEPTED_BY_DRIVER_AND_PENDING_PICKUP'">
                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                        </div>
                                        <div *ngSwitchCase="'ASSIGNED_TO_DRIVER_AND_PENDING_APPROVAL'">
                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                        </div>
                                        <div *ngSwitchCase="'REJECTED_BY_DRIVER_AND_PENDING_MANGEMENT'">
                                            {{'ADMINISTRATION.DRIVER_NAME' | translate}}: {{package.driverName}}
                                        </div>
                                    </ng-container>
                                    <div *ngSwitchCase="package.status === 'SCANNED_BY_HANDLER_AND_UNLOADED' ||
                                            package.status === 'RECEIVED_IN_HUB' || package.status === 'EXPORTED_TO_HUB' ? package.status : ''">
                                        <div *ngIf="!showHubs() && companyHadTripNumbers.indexOf(companyId) === -1">
                                            {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                        </div>
                                        <div *ngIf="companyHadTripNumbers.indexOf(companyId) !== -1">
                                            {{'MANAGE_SHIPMENTS.STATION_NAME' | translate | hubTitle}}
                                            : {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY'">
                                        {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                    </div>
                                    <div *ngSwitchCase="'OPENED_ISSUE_AND_WAITING_FOR_MANAGEMENT'">
                                        {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                    </div>
                                    <div *ngSwitchCase="'POSTPONED_DELIVERY'">
                                        <div *ngIf="package.hubName">
                                            {{(companyHadTripNumbers.indexOf(companyId) !== -1 ? 'MANAGE_SHIPMENTS.STATION_NAME' :'MANAGE_SHIPMENTS.HUB_NAME') |translate | hubTitle}}
                                            : {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                        </div>
                                        <div *ngIf="package.driverName">
                                            {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{package.driverName}}
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'RETURNED_BY_RECIPIENT'">
                                        <ng-container [ngSwitch]="package.returnedPackageLocation">
                                            <div *ngSwitchCase="'IN_HUB'">
                                                {{'MANAGE_SHIPMENTS.HUB_NAME'|translate | hubTitle}}
                                                : {{package.hubName}} {{(package.label ? "-" + package.label : "")}}
                                            </div>
                                            <div *ngSwitchCase="'IN_CAR'">
                                                {{'ADMINISTRATION.DRIVER_NAME'|translate}}: {{package.driverName}}
                                            </div>
                                            <div *ngSwitchCase="'DELIVERED_TO_SENDER'">
                                                {{'MANAGE_SHIPMENTS.DELIVERED_TO_SENDER'|translate}}
                                            </div>
                                            <div *ngSwitchCase="'WITH_PARTNER'">
                                                {{'ACCOUNTING.WITH_PARTNER'|translate}}
                                            </div>
                                            <span *ngSwitchDefault></span>
                                        </ng-container>
                                        <div *ngIf="package.partnerId">
                                            {{'PARTNER.PARTNER_NAME' | translate }} : {{ package.partnerName }}
                                        </div>
                                    </div>
                                    <span *ngSwitchDefault></span>
                                </ng-container>
                            </div>
                            <div *ngIf="package.isFailed" class="cursor-pointer failed-package"
                                 (click)="trackShipment(package)">
                                <div>{{'SHIPMENT.FAILED'| translate}}</div>
                                <div>
                                    {{'SHIPMENT.NUMBER_OF_ATTEMPTS'|translate}} : {{package.failuresNumber}}
                                </div>
                            </div>
                            <div *ngIf="isToFinalDestinationStatus.indexOf(package.status) !== -1 && package.isToFinalDestination">
                                {{'SHIPMENT.TO_FINAL_DESTINATION'|translate}}
                            </div>
                            <div *ngIf="customizationCompaniesService.checkCompanies(companies.SPRINT) && package.bundleId">
                                {{"SHIPMENT.ADDED_TO_BUNDLE"| translate}}
                            </div>
                        </td>
<!--                        failed status-->
                        <td *ngIf="isShowFailedStatus()"></td>
                        <td *ngIf="selectedShipmentStatus === 'TRANSFERRED_OUT'">
                            {{package.partnerName}}
                        </td>

                        <td  *ngIf="showPkgsPerReceiverCity">
                            {{package.destinationAddress.city}}
                        </td>
                        <td *ngIf="selectedShipmentStatus === 'COMPLETED'">{{package.CompletedStatus | translate}}</td>
                        <td *ngIf="showReturnedShipmentStatus()">{{package.returnedStatus | translate}}</td>
                        <td *ngIf="showHubs()" class="th-notes">{{package.hubName}}</td>
                        <td *ngIf="showtoHub()" class="th-notes">{{package.toHubName}}</td>
                        <td *ngIf="customizationCompaniesService.checkCompanies(companies.JETT) || (customizationCompaniesService.checkCompanies(companies.SPRINT) && isFollowUpType && isFollowUpType === 'BUNDLES')" class="th-fromHub">{{package.fromHubName ? package.fromHubName : (getFromHub(package))}}</td>
                        <td *ngIf="customizationCompaniesService.checkCompanies(companies.JETT) || (customizationCompaniesService.checkCompanies(companies.SPRINT) && isFollowUpType && isFollowUpType === 'BUNDLES')" class="th-toHub">{{package.toHubName}}</td>
                        <td *ngIf="showPickupTypeFilter()" class="th-notes">{{'MANAGE_SHIPMENTS.PICKUP_TYPE_LIST.VALUE.' + package.pickupType | translate}}</td>
                        <td *ngIf="showDriversFilter()" class="th-notes">{{package.driverName}}</td>
                        <td *ngIf="showShelfFilter()" class="th-notes">{{package.label}}</td>
                        <td *ngIf="showDeliveredByFilter()" class="th-notes">{{package.deliveredByDriver}}</td>
                        <td class="number-data" *ngIf="isShowInvoice && companyId !== 221">{{package.invoiceNumber}}</td>
                        <td class="auto-complete-filter-th" *ngIf="isShowVehicleType">{{package.vehicleTypeName}}</td>
                        <td *ngIf="isShowCodStatus && !isLcl">
                            {{(package.codStatus === 'SCANNED_BY_HANDLER_AND_UNLOADED'
                                    ? 'IN_HUB' : (package.codStatus === 'DELIVERED_TO_RECIPIENT'
                                         ? 'COMPLETED' : package.codStatus)) | translate}}</td>
                        <td *ngIf="isShowCodStatus && isLcl">
                            {{('LCL.' + package.lclPaymentStatus)  | translate}}
                        </td>
                        <td *ngIf="showHubsCodStatusFilter()"
                            class="th-notes">{{package.codHubName}} {{(package.label ? "-" + package.label : "")}}</td>
                        <td *ngIf="showDriversCodStatusFilter()" class="th-notes">{{package.codDriverName}}</td>
                        <td *ngIf="isShowServiceType">
                            <div *ngIf="!userService.userInfo.isPricingPerServiceTypeEnabled">{{package.serviceType | translate}}</div>
                            <div *ngIf="userService.userInfo.isPricingPerServiceTypeEnabled">{{package.serviceTypeName}}</div>
                        </td>
                        <td *ngIf="isShowShipmentType">
                            <div >{{(package.shipmentType === 'COD' && package.isSenderPayCost && isJettCompany() ? 'COD_V2' : package.shipmentType) | translate}}</div>
                        </td>
                        <td *ngIf="isShowCustomerType">
                            <div >{{(package.customerType?"CUSTOMER_TYPES."+package.customerType:null) | translate}}</div>
                        </td>
                        <td *ngIf="isShowDescription"
                            class="th-notes limit-chars"
                            pTooltip="{{package.description | translate }}"
                            tooltipPosition="top">{{package.description}}</td>
                        <td *ngIf="isShowBookedOnDate">
                            <span *ngIf="customizationCompaniesService.checkCompanies(companies.JETT); else otherCompanies" class="date-value">{{package.createdDate | date:'dd/MM/yyyy HH:mm'}}</span>
                            <ng-template #otherCompanies class="date-value">{{package.createdDate | date:'dd/MM/yyyy'}}</ng-template>
                        </td>
                        <td *ngIf="isShowFirstPickupDate"><span
                                class="date-value">{{package.firstPickupDate  | date:'dd/MM/yyyy'}}</span></td>
                        <td *ngIf="isShowDeliveryDate">{{package.deliveryDate | date:'dd/MM/yyyy HH:mm'}}</td>
                        <td *ngIf="isShowExpectedDeliveryDate">{{package.expectedDeliveryDate | date:'dd/MM/yyyy HH:mm'  }}</td>
                        <td *ngIf="isShowLastStatusDate">{{package.lastStatusDate | date:'dd/MM/yyyy HH:mm'  }}</td>
                        <td *ngIf="isShowLastPickupDate">{{package.pickupDate | date:'dd/MM/yyyy'  }}</td>
                        <td *ngIf="isShowExpectedPickupDate && (isLcl||companyHadTripNumbers.indexOf(companyId) !== -1)">{{package.expectedPickupDate | date:'dd/MM/yyyy HH:mm'  }}</td>
                        <td *ngIf="isShowPostponedDate">{{package.postponedDeliveryDate | date:'dd/MM/yyyy HH:mm'}}</td>
                        <td *ngIf="isShowNotes"
                            class="th-notes limit-chars"
                            pTooltip="{{package.notes | translate }}"
                            tooltipPosition="top">
                            {{package.notes}}</td>
                        <td *ngIf="isShowReceiverLocation">
                            <div *ngIf="isShowLocation(package)" class="attachments-btn">
                                <img (click)="openMap($event, package.destinationAddress)"
                                     src="../../../assets/icons/manage_shipments/pin-_2_.svg" alt=""/>
                            </div>
                        </td>

                        <td *ngIf="isShowDeliveryLocation">
                            <div *ngIf="isShowDeliveredLocation(package)" class="attachments-btn">
                                <img (click)="openMap($event, package.deliveryLocation)"
                                     src="../../../assets/icons/manage_shipments/pin-_2_.svg" alt=""/>
                            </div>
                        </td>

                        <td *ngIf="isShowAttachments">
                            <div *ngIf="package.isAttachmentExist" class="attachments-btn">
                                <img (click)="openImages($event, package.id)"
                                     src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                            </div>
                        </td>
                        <td *ngIf="isShowSignature">
                            <div *ngIf="package.isSignatureExist"
                                 class="edit-action action cursor-pointer attachments-btn"
                                 style="font-size: 17px;"
                                 (click)="openSignatureImages($event, package.id)">
                                <i class="fas fa-eye"></i>
                            </div>
                        </td>
                        <td *ngIf="isShowOriginalCod">
                            <span class="cod-data">{{package.originalCod}}</span>
                            <span class="currency" *ngIf="currency !== 'NIS'"
                                  style="!important; margin: 5px !important;">{{currency}}</span>
                            <span *ngIf="currency === 'NIS'"
                                  style="font-size: 10px !important; margin: 5px !important;"><i
                                    class="fas fa-shekel-sign"></i></span>
                        </td>
                        <td (click)="stopProp($event)">
                            <div *ngIf="!isDeleted && !isCustomerAccountManager() && !archivedMode"
                                 (click)="triggerShipmentDropdown($event, shipmentOptions, package, rowIndex)">
                                <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="spinnerState === spinnerStates.LOADING">
                        <td [attr.colspan]="tableColumnCount">
                            <div class="shipment-table-loader">
                                <div class="loader drivers-list-loader">
                                    <app-skeleton-loader type="2"></app-skeleton-loader>
                                    <app-skeleton-loader type="2"></app-skeleton-loader>
                                    <app-skeleton-loader type="2"></app-skeleton-loader>
                                </div>
                                <div class="loader" *ngIf="shipmentsData.length === 0">
                                    <app-skeleton-loader type="2"></app-skeleton-loader>
                                    <app-skeleton-loader type="2"></app-skeleton-loader>
                                    <app-skeleton-loader type="2"></app-skeleton-loader>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style="visibility: hidden">
                        <td style="border: 0" id="loading_trigger"></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!--    Mobile Screen -->
        <div class="shipment-cards-container h-100 hidden-md-up">
            <div class="mobile-header row d-flex">
                <div class="title d-flex">
                    <div class="label">{{'MANAGE_SHIPMENTS.TITLE' | translate | packageTitle}}</div>
                    <div class="badge">
                        <div class="d-flex mobile-total-records" *ngIf="countResponse || partnerId">
                            <span>{{totalRecords}}</span>
                            <span class="selected-packages-separator" *ngIf="getSelectedPackages().length"></span>
                            <span *ngIf="getSelectedPackages().length">{{getSelectedPackages().length}}</span>
                        </div>
                        <span *ngIf="!countResponse && !partnerId">-</span>
                    </div>
                </div>
                <div class="mobile-date-picker">
                    <div class="mobile-date-range-filter">
                        <app-date-range-picker  (onSelect)="onDateSelected($event, dateRange)"></app-date-range-picker>
                    </div>
                </div>
            </div>
            <div class="mobile-actions-container d-flex">
                <div class="print-reports-action d-flex"
                     [ngClass]="{'disabled-btn disabled': !isThereSelection}"
                     (click)="triggerImportExportMenu($event, printPackagesOptions)">
                    <div class="label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.PRINT_REPORTS'| translate}}</div>
                    <div class="icon">
                        <img src="../../../assets/icons/print.svg" alt=""/>

                    </div>
                </div>
                <div class="mobile-change-status-dialog d-flex">
                    <div    class="mobile-more-actions-button  d-flex"
                            *appPermission="this.permissions.includes('{CHANGE_PACKAGE_STATUS') && !partnerId && (getSelectedPackages().length && !archivedMode)"
                            [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length || isBlock || selectedShipmentStatus === shipmentStatusAll}"
                            #changePackageStatusPopOver="ngbPopover"
                            placement="bottom-left"
                            autoClose="true"
                            [ngbPopover]="changePackageStatusDialog">
                        <div class="sub-label">{{'MANAGE_SHIPMENTS.ACTIONS_LABEL.CHANGE_STATUS'| translate}}</div>
                    </div>
                </div>
                <div *ngIf="getSelectedPackages().length && !archivedMode" class="mobile-more-actions-button d-flex"
                     placement="bottom"
                     autoClose="true"
                     #mobileMoreActionsPopOver="ngbPopover"
                     [ngbPopover]="mobileMoreMenu">
                    <div class="label">{{'ACTIONS.MORE' | translate}}</div>
                    <div class="icon">
                        <i class="fas fa-caret-down"></i>
                    </div>

                </div>
            </div>
            <div class="filter">
                <div class="input-search mobile-search-filter">
                    <span class="search-icon" (click)="initShipments(true)"></span>
                    <input type="text" [(ngModel)]="mobileSearchText" class="search-input" [(ngModel)]="searchContent"
                           (input)="onSearch()" placeholder='{{"GENERAL.SEARCH" | translate}}'/>
                    <span class="fas fa-times close-icon" *ngIf="mobileSearchText" (click)="clearSearch()"></span>
                </div>
                <div class="mobile-status-filter">
                    <p-dropdown styleClass="shipment-status-dropdown" [(ngModel)]="selectedShipmentStatus"
                                [options]="shipmentStatusList" filter="true" (onChange)="onStatusChanged()">
                        <ng-template let-status pTemplate="selectedItem">{{ status.label | translate }}</ng-template>
                        <ng-template let-status pTemplate="item">{{ status.label | translate }}</ng-template>
                    </p-dropdown>
                </div>


            </div>
            <div class="empty-card" *ngIf="loadingPackages">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="no-data" *ngIf="spinnerState == spinnerStates.EMPTY">
                <span>{{ 'ALERTS.NO_DATA_AVAILABLE' | translate }}</span>
            </div>
            <div class="packages-cards-container" (scroll)="onsScrollShipmentTable($event, true)">
                <div class="card-item" *ngFor="let shipment of shipmentsData;let rowIndex = index">
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/box.svg" alt=""/>
                            </td>
                            <td>
                                <span (click)="trackShipment(shipment.barcode)">#{{shipment.barcode}}</span>
                                <span class="copy-icon">
                                    <img src="../../../assets/icons/package_card/copy.svg" alt=""
                                         (click)="copyMessage($event,null, shipment.barcode)" data-toggle="tooltip"
                                         data-placement="top" title="Copy" class="copy_icon"/>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/user.svg" alt=""/>
                            </td>
                            <td>
                                <span *ngIf="shipment.customerStatusType != 'RETURNED'">{{shipment?.receiverFirstName + " " + (shipment.receiverMiddleName ? shipment?.receiverMiddleName : "") + " " + shipment?.receiverLastName}}</span>
                                <span *ngIf="shipment.customerStatusType == 'RETURNED'">
                                    {{shipment?.senderFirstName + " " + (shipment.senderMiddleName ? shipment?.senderMiddleName : "") + " " + shipment?.senderLastName}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/user.svg" alt=""/>
                            </td>
                            <td>
                                <span>{{shipment?.customerName}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/calendar.svg" alt=""/>
                            </td>
                            <td>
                                <span>{{shipment.createdDate | date: 'yyyy-MM-dd'}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/location.svg" alt=""/>
                            </td>
                            <td><span>{{shipment.destinationAddress.city }} - {{shipment.destinationAddress.village }}
                                - {{shipment.destinationAddress.addressLine1}} {{shipment.destinationAddress.addressLine2 ? "- " +
                                    shipment.destinationAddress.addressLine2 : ''}}</span></td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/invoice.svg" alt=""/>
                            </td>
                            <td>
                                <span>{{shipment.invoiceNumber ? shipment.invoiceNumber : "--"}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="../../../assets/icons/package_card/chat.svg" alt=""/>
                            </td>
                            <td>
                                <span>{{shipment.notes ? shipment.notes : "--"}}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="corner">
                        <app-shipment-status [shipment]="shipment"></app-shipment-status>
                        <div class="cod">
                            <span><b>{{shipment.cod}} {{currency}}</b></span>
                            <br/>
                            <span>{{"SHIPMENT.TABLE.COD" | translate }}</span>
                        </div>
                        <div class="price">
                            <span><b>{{shipment.cost}} {{currency}}</b></span>
                            <br/>
                            <span>{{"SHIPMENT.TABLE.COST" | translate}}</span>
                        </div>
                    </div>
                    <div class="tripple-menu"
                         (click)="triggerShipmentDropdown($event, shipmentOptions, shipment, rowIndex)"
                         *ngIf="['CANCELLED', 'RETURNED'].indexOf(shipment.customerStatusType) < 0 && !archivedMode">
                        <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                    </div>
                    <div class="check-box-container">
                        <p-checkbox [(ngModel)]="shipment.isSelected" binary="true"
                                    (onChange)="onToggleSelection()"></p-checkbox>
                    </div>
                </div>

            </div>
        </div>
        <!--        <div class="mid-space-mobile"></div>-->
    </div>
</div>

<ng-container *ngIf="!defaultAddPageForm">
    <app-distributor-package-form *ngIf="displayAddPackage" [display]="displayAddPackage"
                               (showAWBDialogEvent)="showAWBDialog($event)" [packageBarcode]="selectedPackageBarcode"
                               (closeWidget)="onHideAddPackage($event)">
    </app-distributor-package-form>
</ng-container>
<ng-container *ngIf="defaultAddPageForm">
    <app-add-package-form-component *ngIf="displayAddPackage" [display]="displayAddPackage"
                                    [isCloning]="isClonePackage"
                                    (showAWBDialogEvent)="showAWBDialog($event)"
                                    [packageBarcode]="selectedPackageBarcode"
                                    (closeWidget)="onHideAddPackage($event)">
    </app-add-package-form-component>
</ng-container>
<app-package-print-dialog-component [showAWBModal]="showAWBPrintModal" [url]="linkAWB" [titleCode]="titleCode"
                                    [showPrintButton]="showPrintPackagesButton"
                                    (closeDialogEvent)="onHidePrintAWB($event)"></app-package-print-dialog-component>

<!-- Excel Validation -->
<app-excel-validation-dialog *ngIf="displayExcelValidation"
                             (closeDialogEvent)="onCloseExcelValidationDialog()"
                             [isBundle]="isBundleImport"
                             #validationDialog>

</app-excel-validation-dialog>

<p-overlayPanel #filtersMenu
                class="tickets-filters-menu"
                [dismissable]="true"
                (onHide)="onHideFiltersMenu()"
                [showCloseIcon]="false">
    <div class="filter-container" *ngIf="formInitiated" [ngClass]="{'page-ltr': selectedLanguage === 'en'}">
        <div class="filters-menu-actions">
            <div class="reset-action filter-menu-action mb-4">
                <span>{{'GENERAL.FILTER_INFORMATION_BY' | translate}}</span>
            </div>
        </div>
        <div  class="d-flex flex-column filter filters-group">
            <div [formGroup]="filterForm">
            <div class="filter-item" *ngIf="showShipmentTypes && !archivedMode" >
                <label class="label">
                    {{'ADD_SHIPMENT.SHIPMENT_TYPE' | translate}}
                </label>
                <p-dropdown [options]="shipmentTypes" styleClass="shipment-type"
                            formControlName="selectedShipmentsType" (onChange)="onShipmentsTypeChanged()"
                            dataKey="value"
                            optionLabel="label"></p-dropdown>
            </div>

                <div class="filter-item" *ngIf="customizationCompaniesService.checkCompanies(companies.SPRINT) && !archivedMode">
                    <label class="label">
                        {{'SHIPMENT.BUNDLE_STATUS' | translate}}
                    </label>
                    <p-dropdown [options]="shipmentBundleStatuses" styleClass="shipment-type"
                                formControlName="bundleType" (onChange)="onShipmentsTypeChanged()"
                                dataKey="value"
                                optionLabel="label"></p-dropdown>
                </div>

            <div class="filter-item" *ngIf="isHubUserRole" >
                <label class="label">
                    {{'ADD_SHIPMENT.SHIPMENT_TYPE' | translate}}
                </label>
                <p-dropdown [options]="exportingHubPackagesFilterList"
                            styleClass="shipment-type"
                            formControlName="exportingHubPackagesFilter" (onChange)="initShipments(true)"
                            dataKey="value" optionLabel="label"></p-dropdown>
            </div>

            <div class="filter-item">
                <label class="label">
                    {{'GENERAL.SORT_BY' | translate}}
                </label>
                <p-dropdown [options]="sortByList" styleClass="shipment-type"
                            formControlName="sortBy" (onChange)="onShipmentsTypeChanged()"
                            dataKey="value" optionLabel="label"></p-dropdown>
            </div>
            </div>

            <div class="filter-item">
                <label class="label">
                    {{'SHIPPING_LINE' | translate}}
                </label>
                <div class="data-search header-filter sender-header-filter col-xs-5">
                    <!--                sender-->
                    <img class="shipment-line-icon" src="../../../assets/icons/manage_shipments/sender_receiver.svg"
                         alt=""/>
                    <p-multiSelect [options]="shipmentLines" [(ngModel)]="selectedSenderShipmentLine"
                                   (onChange)="onShippingLineChanged()"
                                   (onPanelShow)="onSelectedCategoriesPanelShown()"
                                   #selectedCategoriesDropDown
                                   maxSelectedLabels="0"
                                   selectedItemsLabel="{{ '{0} ' + ('GENERAL.ITEMS' | translate) }}"
                                   filterPlaceHolder="{{'MANAGE_SHIPMENTS.SEARCH' | translate}}.."
                                   defaultLabel="{{'SHIPPING_LINE' | translate}}">

                    </p-multiSelect>
                </div>
            </div>
            <div class="filter-item">
                <label class="label">
                    {{'DESTINATION_SHIPPING_LINE' | translate}}
                </label>
                <div class="data-search header-filter col-xs-6">
                    <img class="shipment-line-icon" src="../../../assets/icons/manage_shipments/sender_receiver.svg"
                         alt=""/>
                    <p-multiSelect
                            [options]="DestinationShipmentLines"
                            [(ngModel)]="selectedReceiverShipmentLine"
                            (onChange)="onShippingLineChanged()"
                            (onPanelShow)="onSelectedCategoriesPanelShown()"
                            #selectedCategoriesDropDown
                            maxSelectedLabels="0"
                            selectedItemsLabel="{{ '{0} ' + ('GENERAL.ITEMS' | translate) }}"
                            filterPlaceHolder="{{'MANAGE_SHIPMENTS.SEARCH' | translate}}.."
                            defaultLabel="{{'DESTINATION_SHIPPING_LINE' | translate}}"
                    >
                    </p-multiSelect>
                </div>
            </div>

        </div>
        <div class="filters-menu-actions">
            <div class="reset-action filter-menu-action" (click)="resetAllFilters()">
                <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
            </div>
        </div>
    </div>

</p-overlayPanel>
