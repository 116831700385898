<div class="h-100 generic-table-body" [ngClass]="{'page-ltr': currentLang === 'en', 'page-rtl': currentLang === 'ar'}">
    <p-table [columns]="columns" [value]="value" paginatorDropdownAppendTo="body"
             (onPage)="setPaginatorQueryParam($event)"
             [paginator]="!hidePagination" [responsive]="true" [responsiveLayout]="'scroll'" [scrollable]="true"
             scrollDirection="horizontal" #ptable
             [(rows)]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions" [lazy]="true"
    >
        <!--    <ng-template pTemplate="caption">-->
        <!--    </ng-template>-->
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of getVisibleCols(columns)">

                    <div class="header-filter" *ngIf="col.columnType == columnsType.DROPDOWN_FILTER">
                                    <span class="status-title d-inline-block"
                                          [ngClass]="{'active-status-title': filter[col.field] !== 'ALL' && filter[col.field] !== ''}">{{col.header | translate}}</span>
                        <div class="manage-shipment-status-filter"
                             [ngClass]="{'active-status-filter': filter[col.field] !== 'ALL' && filter[col.field] !== ''}"
                             style="z-index: 3 !important;">
                            <p-dropdown styleClass="shipment-status-dropdown"
                                        [(ngModel)]="filter[col.field]" [options]="col.options"
                                        [appendTo]="'body'"
                                        filter="true" (onChange)="onSelectMultiSelectFilter(col.field)">
                                <ng-template let-status
                                             pTemplate="selectedItem">{{ status.label | translate }}</ng-template>
                                <ng-template let-status
                                             pTemplate="item">{{ status.label | translate }}</ng-template>
                            </p-dropdown>
                        </div>
                    </div>

                <div *ngIf="col.columnType == columnsType.NORMAL || col.columnType == columnsType.BARCODE_WITH_IMAGE || col.columnType == columnsType.BARCODE" [ngStyle]="col.style">
                    {{col.header | translate}}
                </div>
                    <ng-container *ngIf="col.columnType == columnsType.CUSTOM_TEMPLATE">
                        <ng-container
                                *ngTemplateOutlet="customTemplates[col.templateHeaderName];context: {$implicit: {}}">
                        </ng-container>
                    </ng-container>
                <div *ngIf="col.columnType == columnsType.DATE_WITH_RANGE_FILTER">
                    <div class="manage-shipment-date-filter d-flex justify-content-center"
                         [ngClass]="{'manage-shipment-active-date-filter' : !!filter[col.field]}">
                        <div class="position-relative" style="width: fit-content;">
                                <span class="receiver-phone date-filter-label"
                                      [ngClass]="{'active-receiver-phone': !!filter[col.field]}">{{col.header | translate}}</span>
                            <i *ngIf="!!filter[col.field]"
                               class="fas fa-times date-picker-icon cursor-pointer"
                               (click)="resetFilter(col.field); filter[col.field] = ''"></i>
                            <p-calendar
                                    styleClass="date-picker header-filter-background"
                                    [(ngModel)]="filter[col.field]"
                                    (onSelect)="filterChanged()"
                                    selectionMode="range"
                                    placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"
                                    dateFormat="dd/mm/yy"
                                    [appendTo]="'body'"
                            ></p-calendar>
                            <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>
                        </div>
                    </div>
                </div>

                <div class="filter" *ngIf="col.columnType == columnsType.WITH_SEARCH_FILTER" [ngStyle]="col.style">
                    <div class="filter-label">
                        {{col.header | translate}}
                    </div>
                    <div class="filter-value">
                        <input appInputDebounced (inputDebounced)="filterChanged()"
                               [(ngModel)]="filter[col.field]" [placeholder]="'GENERAL.SEARCH' | translate">
                    </div>
                </div>
                <div class="header-filter" *ngIf="col.columnType == columnsType.WITH_MULTI_SELECT_FILTER" style="min-width: 150px">
                                <span class="status-title"
                                      [ngClass]="{'active-status-title': filter[col.field].length}">{{col.header | translate}}</span>
                        <i *ngIf="filter[col.field].length" class="fas fa-times reset-customer-filter cursor-pointer"
                           (click)="resetFilter(col.field)"></i>
                        <div class="manage-shipment-status-filter"
                             [ngClass]="{'manage-shipment-active-status-filter': filter[col.field].length}">
                            <ng-multiselect-dropdown
                                #multiSelect
                                placeholder="{{col.headerFilterPlaceholder | translate}}"
                                [settings]="col.multiSelectFilterSettings"
                                [data]="filterList[col.field]"
                                [(ngModel)]="filter[col.field]"
                                (onSelect)="onSelectMultiSelectFilter(col.field)"
                                (onDropDownClose)="filterCities(col.field, multiSelect)"
                                (onDeSelect)="onSelectMultiSelectFilter(col.field)"
                                (onSelectAll)="onSelectMultiSelectFilter(col.field, true)"
                                (onDeSelectAll)="onSelectMultiSelectFilter(col.field)"
                                (onFilterChange)="filterCities(col.field, multiSelect)"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="header-filter" *ngIf="col.columnType == columnsType.WITH_MULTI_SELECT_FILTER_WAREHOUSE"
                         style="min-width: 150px">
                                <span class="status-title"
                                      [ngClass]="{'active-status-title': filter[col.field].length}">
                                  {{ 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME' | translate }}</span>
                        <i *ngIf="filter[col.field].length" class="fas fa-times reset-customer-filter cursor-pointer"
                           (click)="resetFilter(col.field)"></i>
                        <div class="manage-shipment-status-filter"
                             [ngClass]="{'manage-shipment-active-status-filter': filter[col.field].length}">
                            <ng-multiselect-dropdown
                                #warehouseMultiSelect
                                placeholder="{{'ADMINISTRATION.WAREHOUSE_LIST_DROPDOWN_PLACEHOLDER' | translate }}"
                                [settings]="col.multiSelectFilterSettings"
                                [data]="filterList[col.field]"
                                [(ngModel)]="filter[col.field]"
                                (onSelect)="onSelectMultiSelectFilter(col.field)"
                                (onDeSelect)="onSelectMultiSelectFilter(col.field)"
                                (onSelectAll)="onSelectMultiSelectFilter(col.field, true)"
                                (onDeSelectAll)="onSelectMultiSelectFilter(col.field)"
                                (onFilterChange)="filterWarehouses(col.field, warehouseMultiSelect)"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr (click)="trClicked($event, rowData)" [ngClass]="{'cursor-pointer' : config.trClickEvent}">
                <td *ngFor="let col of getVisibleCols(columns)" [ngClass]="col.tdClass"
                    (click)="col.clickEvent ? stopPropAndCall($event, col, rowData) : ''">
                    <ng-container *ngIf="col.tdType == columnsType.CUSTOM_TEMPLATE">
                        <ng-container
                                *ngTemplateOutlet="customTemplates[col.templateName];context: {$implicit: rowData}">

                        </ng-container>
                    </ng-container>
                    <div *ngIf="col.tdType == columnsType.CUSTOM" [innerHTML]="col.getHTML(rowData)">
                    </div>
                    <ng-container *ngIf="col.tdType == columnsType.NORMAL">
                        <div class="d-flex" style="gap: 5px; justify-content:center;" (click)="onTdClick(col, rowData, $event)">
                            <span>{{col.isCurrency ? (getNormalText(col, rowData) | currencyValue) : (getNormalText(col, rowData) | translate)}}</span>
                            <img src="../../../assets/icons/track_package/copy.svg" alt=""
                                 *ngIf="col.allowCopy && !!rowData[col.field]"
                                 (click)="stopProp($event);copyMessage($event,rowData[col.field])" data-toggle="tooltip"
                                 data-placement="top" title="Copy" class="copy_icon"/>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="col.tdType == columnsType.DATE">
                        <div class="d-flex" style="gap: 5px; justify-content:center;">
                            <span>{{rowData[col.field] | date:col.dateFormat }}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="col.tdType == columnsType.BARCODE_WITH_IMAGE">
                        <div class="flex-2d-center flex-column">
                            <img [src]="rowData[col.field+'Image']" alt="{{ rowData[col.field] }}">
                            <div class="d-flex">
                                <div class="barcode">{{ rowData[col.field] }}</div>
                                <div class="copy-barcode ml-2 mr-2" *ngIf="rowData[col.field]">
                                    <img src="../../../assets/icons/package_card/copy.svg" alt=""
                                         (click)="copyMessage($event, rowData[col.field])" data-toggle="tooltip"
                                         data-placement="top" title="Copy" class="copy_icon"/>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="col.tdType == columnsType.BARCODE">
                        <div class="flex-2d-center flex-column">
                            <div class="d-flex">
                                <div class="barcode">{{ rowData[col.field] }}</div>
                                <div class="copy-barcode ml-2 mr-2" *ngIf="rowData[col.field]">
                                    <img src="../../../assets/icons/package_card/copy.svg" alt=""
                                         (click)="copyMessage($event, rowData[col.field])" data-toggle="tooltip"
                                         data-placement="top" title="Copy" class="copy_icon"/>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="col.tdType == columnsType.STATUS">
                        <app-shipment-status [isEditEnabled]="false" [shipment]="rowData" [propertyName]="col.field"
                                             [stopPropagation]='false'></app-shipment-status>
                    </ng-container>
                    <ng-container *ngIf="col.tdType == columnsType.ACTIONS">
                        <div style="float: left; padding: 0 10px;" class="cursor-pointer" *ngIf="col.canShow ? col.canShow(rowData) : true"
                             (click)="stopProp($event);triggerActionsDropdown($event, shipmentOptions, rowData, col)">
                            <img src="../../../assets/icons/tripple_dots.svg" alt=""/>
                        </div>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
            <tr *ngIf="config && config.showFooter">
                <td *ngFor="let col of getVisibleCols(columns)">
                    {{getFooter(col)}}
                </td>
            </tr>
            <tr *ngIf="spinnerState === spinnerStates.LOADING">
                <td *ngIf="!lazyLoading" [attr.colspan]="numberOfColumns">
                    <div class="table-loader">
                        <div class="loader">
                            <app-skeleton-loader type="2" [repeatSingleLine]="6"></app-skeleton-loader>
                        </div>
                    </div>
                </td>

                <td *ngIf="lazyLoading" [attr.colspan]="numberOfColumns">
                    <div class="shipment-table-loader">
                        <div class="loader drivers-list-loader">
                            <app-skeleton-loader type="2"></app-skeleton-loader>
                            <app-skeleton-loader type="2"></app-skeleton-loader>
                            <app-skeleton-loader type="2"></app-skeleton-loader>
                        </div>
                        <div class="loader" *ngIf="value.length === 0">
                            <app-skeleton-loader type="2"></app-skeleton-loader>
                            <app-skeleton-loader type="2"></app-skeleton-loader>
                            <app-skeleton-loader type="2"></app-skeleton-loader>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <div class="empty-content" *ngIf="!hideNoData">
                <app-no-data-available-component *ngIf="spinnerState != spinnerStates.LOADING"
                                                 style="height: 200px; display: flex"></app-no-data-available-component>
            </div>
        </ng-template>
        <ng-template pTemplate="summary">
        </ng-template>
    </p-table>
</div>

<p-overlayPanel #shipmentOptions [dismissable]="true" styleClass="generic-table-popover" [appendTo]="'body'"
                [autoZIndex]="true">
    <div class="row-action-container" *ngIf="selectedCol && selectedCol.actions && selectedCol.actions.length">

<!--        <ng-container *ngFor="let ev of selectedCol.actions">-->
<!--            <ng-container *ngIf="!ev.hide || !ev.hide(selectedRow)">-->
<!--                <div class="action cursor-pointer more-action-item mb-2 mt-2"-->
<!--                     [ngClass]="{'disabled-btn disabled': ev.disable && ev.disable(selectedRow)}"-->
<!--                     (click)="shipmentOptions.hide();ev.click(selectedRow)" *ngIf="!ev.hide || !ev.hide(selectedRow)">-->
<!--                    <img *ngIf="ev.image" class="edit-button" src="{{ev.image}}"-->
<!--                         alt="icon"/>-->
<!--                    <div *ngIf="ev.icon" class="icon"><i class="fas {{ev.icon}}"></i></div>-->

<!--                    {{(ev.dynamicLabel ? ev.dynamicLabel(selectedRow) : ev.label) | translate}}-->
<!--                </div>-->
<!--            </ng-container>-->
<!--        </ng-container>-->
        <ng-container *ngFor="let ev of selectedCol.actions">
            <div class="action cursor-pointer more-action-item mb-2 mt-2"
                 [ngClass]="{'disabled-btn disabled': ev.disable && ev.disable(selectedRow)}"
                 (click)="!ev.showLoading ? shipmentOptions.hide() : '';ev.click(selectedRow)"
                 *ngIf="!ev.hide || !ev.hide(selectedRow)">
                <ng-container *ngIf="(!ev.showLoading || !selectedRow[ev.loadingPropName]); else loading">
                    <img *ngIf="ev.image" class="edit-button" src="{{ev.image}}"
                         alt="icon"/>
                    <div *ngIf="ev.icon" class="icon"><i class="fas {{ev.icon}}"></i></div>
                </ng-container>
                <ng-template #loading>
                    <div class="icon"><i class="fas fa-spinner fa-pulse"></i></div>
                </ng-template>
                {{(ev.dynamicLabel ? ev.dynamicLabel(selectedRow) : ev.label) | translate}}
            </div>
        </ng-container>
    </div>
</p-overlayPanel>
