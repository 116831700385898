<div class="regions-container">
    <div class="regions-list">
        <table>
            <tr>
                <th *ngIf="!isHubsPricingList">
                    {{"PRICING.FROM" | translate }}
                </th>
                <th>
                    {{(isHubsPricingList ? "PRICING.TO_CITY": "PRICING.TO") | translate }}
                </th>
                <th>
                    {{"PRICING.DELIVER_COST" | translate }}
                </th>
                <th>
                    {{"PRICING.RETURNED_PRICE" | translate }}
                </th>
                <th>
                    {{"PRICING.REJECTION_BEFORE_ARRIVAL_COST" | translate }}
                     <i  class="fas fa-question-circle" tooltipPosition="top"
                        pTooltip="{{'PRICING.REJECTION_BEFORE_ARRIVAL_COST_MESSAGE'| translate}}" ></i>
                 </th>
                <th *ngIf="isReceivingPointEnabled">
                    {{"PRICING.RECEIVING_POINT_DELIVER_COST" | translate }}
                </th>
                <th *ngIf="isEnableCustomerCommissionFee">
                    {{"PRICING.COMMISSION_FEE" | translate }}
                </th>
                <th *ngIf="isEnableBringPrice">
                    {{"PRICING.BRING_PRICE" | translate }}
                </th>
                <th *ngIf="calculateCostPerWeight">
                    {{"PRICING.THRESHOLD_WEIGHT" | translate }}
                </th>
                <th *ngIf="calculateCostPerWeight">
                    {{"PRICING.COST_PER_KG" | translate }}
                </th>
                <th class="pricing-action-header">
                    {{"PRICING.ACTION" | translate }}
                </th>
            </tr>
            <tr *ngFor="let region of companyRegions;index as i" [ngClass]="{'new-region': region.isNew || region.isModified}">
                <td *ngIf="!isHubsPricingList">
                    <p-dropdown styleClass="shipment-status-dropdown" [style]="{'width':'150px'}"
                                [(ngModel)]="region.originRegionId" appendTo="body"
                                (onChange)="markPackageModified(region)"
                                [panelStyle]="{'width':'400px'}" [options]="regions" filter="true">
                    </p-dropdown>
                </td>
                <td>
                    <p-dropdown *ngIf="isHubsPricingList" styleClass="shipment-status-dropdown" [style]="{'width':'150px'}"
                                [(ngModel)]="region.destinationCityId" appendTo="body"
                                (onChange)="markPackageModified(region)"
                                [panelStyle]="{'width':'400px'}" [options]="regions" filter="true">
                    </p-dropdown>
                    <p-dropdown *ngIf="!isHubsPricingList" styleClass="shipment-status-dropdown" [style]="{'width':'150px'}"
                                [(ngModel)]="region.destinationRegionId" appendTo="body"
                                (onChange)="markPackageModified(region)"
                                [panelStyle]="{'width':'400px'}" [options]="regions" filter="true">
                    </p-dropdown>

                </td>
                <td>
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.price"
                           (change)="markAsEdited(region, i)">
                </td>
                <td>
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.returnedPrice"
                           (change)="markAsEdited(region, i)">
                </td>
                <td>
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.rejectionBeforeArrivalCost"
                           (change)="markAsEdited(region, i)">
                </td>
                <td *ngIf="isReceivingPointEnabled">
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.deliveryCostToTheReceivingPoint"
                           (change)="markAsEdited(region, i)">
                </td>
                <td *ngIf="isEnableCustomerCommissionFee">
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.commissionFee"
                           (change)="markAsEdited(region, i)">
                </td>
                <td *ngIf="isEnableBringPrice">
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.bringPrice"
                           (change)="markAsEdited(region, i)">
                </td>
                <td *ngIf="calculateCostPerWeight">
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.maxFreeWeight"
                           (change)="markAsEdited(region, i)">
                </td>
                <td *ngIf="calculateCostPerWeight">
                    <input class="text-input" type="number" min="1" [(ngModel)]="region.additionalCostPerKg"
                           (change)="markAsEdited(region, i)">
                </td>
                <td class="remove-button-container">
                    <div *ngIf="!region.isNew" class="remove-button cursor-pointer" (click)="removeOrUpdateRegionCost(region, i)">
                        <span>
                            <i class="fas fa-minus"></i>
                            {{(region.isModified ? "ACTIONS.EDIT" : "ACTIONS.REMOVE") | translate }}
                        </span>
                        <div *ngIf="region.isLoading">
                            <p-progressSpinner></p-progressSpinner>
                        </div>
                    </div>
                    <div *ngIf="region.isNew" class="remove-button cursor-pointer"
                         (click)="saveRegionCost(region)">
                        <span>
                            <i class="fas fa-plus"></i>
                            {{"ACTIONS.SAVE" | translate }}
                        </span>
                        <div *ngIf="region.isLoading">
                            <p-progressSpinner></p-progressSpinner>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="add-new-region">
        <div class="add-button cursor-pointer" (click)="addRegionCost()">
            <i class="fas fa-plus"></i>
            {{(!driverPricing ? "PRICING.ADD_REGION" : "PRICING.ADD_PRICE") | translate }}
        </div>
    </div>
</div>
