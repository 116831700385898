<p-toast></p-toast>
<div class="close-icon" (click)="closeModal()"><img src="../../assets/icons/close-icon.svg" alt="" /></div>
<div [ngClass]="{'page-ltr': currentLang === 'en', 'page-rtl': currentLang === 'ar'}">
<div class="main-container">
        <div class="header d-flex justify-content-end">
            <div class="filters d-flex gap-10">
                <div class="order-by d-flex gap-10 align-items-center">
                    <div
                         [ngClass]="{'disabled-button disabled': isLoadingExcel}"
                         class="excel-button"
                         (click)="exportReportToExcel()">
                        <i class="fas fa-file-excel"></i>
                        {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}
                    </div>

                    <div class="align-items-center gap-7 d-flex flex-row">
                        <i class="fas fa-sort-amount-asc"></i>
                        <p-dropdown styleClass="date-filter-dropdown"
                                    [(ngModel)]="selectedOrderByOption"
                                    [options]="orderByOptions" filter="true"
                                    (onChange)="onDateFilterChange()">
                        </p-dropdown>
                    </div>
                </div>
                <div class="split"></div>
                <div class="align-items-center gap-7 d-flex flex-row">
                    <i class="fas fa-filter"></i>
                    <p-dropdown styleClass="date-filter-dropdown"
                                [(ngModel)]="selectedDateFilter"
                                [options]="dateFiltersList" filter="true"
                                (onChange)="onDateFilterChange()">
                    </p-dropdown>
                </div>
<!--                <div class="date-picker-filter">-->
<!--                    <i class="fa fa-calendar" (click)="datepicker.click()"></i>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                           #datepicker-->
<!--                           daterangepicker [options]="dateRangPickerOptions"-->
<!--                           (hideDaterangepicker)="onHideDateRangePicker($event)"-->
<!--                           (applyDaterangepicker)="onHideDateRangePicker($event)"-->
<!--                           (showCalendarDaterangepicker)="onShowCalender($event)"-->
<!--                           (showDaterangepicker)="onShowCalender($event)"-->
<!--                           (selected)="onDateSelected($event)"/>-->
<!--                    <i class="fa fa-caret-down"></i>-->

<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>
                <div class="search-container">
                    <i class="fas fa-search"></i>
                    <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." pInputText
                           [(ngModel)]="searchContent" appInputDebounced (inputDebounced)="initShipments(true)"/>
                </div>
            </div>
            </div>
        <div>
            <div class="packages-table">
                <div class="shipment-body hidden-sm-down" (scroll)="onScrollTable($event)">
                    <p-table responsiveLayout="scroll" [value]="packages"
                             [responsive]="true" scrollHeight="auto" styleClass="shipments-table receive-from-driver-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'ACCOUNTING.PACKAGE_BARCODE' | translate}}</th>
                                <th>{{'ACCOUNTING.COD_BARCODE' | translate}}</th>
                                <th>{{'ADD_SHIPMENTS.PRICE' | translate}}</th>
                                <th>{{'ADD_SHIPMENT.INVOICE_NUMBER' | translate}}</th>
                                <th>{{'ACCOUNTING.SENDER_ADDRESS' | translate}}</th>
                                <th>{{'ADD_SHIPMENT.SENDER_NAME' | translate}}</th>
                                <th>{{'ADD_SHIPMENT.RECEIVER_NAME' | translate}}</th>
                                <th>{{'ADD_SHIPMENT.RECEIVER_ADDRESS' | translate}}</th>
                                <th>{{'GENERAL.NOTES' | translate}}</th>
                                <th>{{'ADD_SHIPMENT.COD_ON_RECEIVE' | translate}}</th>
                                <th>{{'ACCOUNTING.CUSTODY' | translate}}</th>
                                <th>{{'DRIVERS_EARNINGS.DRIVER_COST' | translate}}</th>
                                <th>{{'ACCOUNTING.RECEIVE_DATE' | translate}}</th>
                                <th>{{'ADMINISTRATION.DRIVER_REPORTS.COD_DELIVERY_DATE' | translate}}</th>
<!--                                <th class="header-multiselect data-filter delivery-date-th" style="min-width: 150px">-->
<!--                                    <div class="manage-shipment-date-filter"-->
<!--                                         [ngClass]="{'manage-shipment-active-date-filter' : deliveryDate.value}">-->
<!--                                <span class="receiver-phone date-filter-label"-->
<!--                                      [ngClass]="{'active-receiver-phone': deliveryDate.value}">{{'ADMINISTRATION.DRIVER_REPORTS.COD_DELIVERY_DATE' | translate}}</span>-->
<!--                                        <i *ngIf="deliveryDate.value"-->
<!--                                           class="fas fa-times date-picker-icon cursor-pointer"-->
<!--                                           (click)="resetField('deliveryDate')"></i>-->
<!--                                        <p-calendar-->
<!--                                                styleClass="date-picker header-filter-background"-->
<!--                                                [(ngModel)]="deliveryDate.value"-->
<!--                                                (onSelect)="onSearch()"-->
<!--                                                placeholder="{{'DASHBOARD.CUSTOMIZE' | translate}}"-->
<!--                                                dateFormat="dd/mm/yy"-->
<!--                                                [appendTo]="'body'"-->
<!--                                        ></p-calendar>-->
<!--                                        <img src="../../../assets/icons/manage_shipments/calendar.svg" alt=""/>-->
<!--                                    </div>-->
<!--                                </th>-->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <div class="empty-content">
                                <app-no-data-available-component> </app-no-data-available-component>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="body" let-package let-index="rowIndex">
                            <tr class="shipment-row">
                                <td class="barcode-number default">
                                    <div class="d-flex barcode-container" style="gap: 5px; justify-content:center;">
                                        <span>#{{package.barcode}}</span>
                                        <img src="../../../assets/icons/track_package/copy.svg" alt=""
                                             (click)="copyMessage($event,null, package.barcode)" data-toggle="tooltip"
                                             data-placement="top" title="Copy" class="copy_icon"/>
                                    </div>
                                </td>
                                <td class="barcode-number default">
                                    <div class="d-flex" style="gap: 5px; justify-content:center;">
                                        <span>#{{package.codBarcode}}</span>
                                        <img src="../../../assets/icons/track_package/copy.svg" alt=""
                                             (click)="copyMessage($event,null, package.codBarcode)" data-toggle="tooltip"
                                             data-placement="top" title="Copy" class="copy_icon"/>
                                    </div>
                                </td>
                                <td>{{package.cost + ' ' + currency}}</td>
                                <td>{{package.invoiceNumber}}</td>
                                <td>{{package?.senderCity}}</td>
                                <td>{{package?.senderFirstName + ' ' + package?.senderLastName}}</td>
                                <td>{{package?.receiverFirstName + ' ' + package?.receiverLastName}}</td>
                                <td>{{package.receiverCity}}</td>
                                <td>{{package.notes}}</td>
                                <td>{{package.cod + ' ' + currency}}</td>
                                <td>{{getCustody(package)}}</td>
                                <td>{{package.driverEarning + ' ' + currency}}</td>
                                <td >{{package.receiveDate | date:'dd/MM/yyyy HH:mm'}}</td>
                                <td >{{package.massCodDeliveryDate | date:'dd/MM/yyyy HH:mm'}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="isLoading">
                                <td [attr.colspan]="14">
                                    <div class="shipment-table-loader">
                                        <div class="loader drivers-list-loader">
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                        </div>
                                        <div class="loader" *ngIf="packages.length === 0">
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                            <app-skeleton-loader type="2"></app-skeleton-loader>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="row bottom-actions">
                    <div class="actions d-inline-flex">
                        <div class="done-action"
                             (click)="closeModal()">{{'CONTAINERS_MANAGEMENT.ACTIONS.DONE' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
