import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {TranslateService} from '@ngx-translate/core';
import {ACCOUNTING_CONSTANTS} from '../../accounting-constants';
import {
    DELIVERED_PACKAGES_DATE_FILTERS_LIST,
    EXPORTED_DATE_FILTERS_LIST,
    EXPORTED_PACKAGES_DATE_FILTERS_LIST
} from '../../../shipment/services/constants';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {ChooseDriverComponent} from '../../../shipment/choose-driver/choose-driver.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../../users/services/users.service';
import {MobComponent} from '../../../users/mob/mob.component';
import {ShipmentsService} from '../../../shipment/services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ChooseHubDriverPartnerComponent} from '../../../users/choose-hub-driver-partner/choose-hub-driver-partner.component';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {ChoosePaymentTypeComponent} from '../../../users/choose-payment-type/choose-payment-type.component';
import {PrintPackageOptionsComponent} from '../../../users/print-package-options/print-package-options.component';
import {ChooseExistingComponent} from '../../../shipment/choose-existing/choose-existing.component';
import {USERS_CONSTANTS} from '../../../users/services/constants';
import {
    CustomConfirmationMessageComponent
} from '../../../shared/components/custom-confirmation-message/custom-confirmation-message.component';
import {UserService} from '../../../shared/services/user.service';
import {DatePipe} from '@angular/common';
import {ImageSliderComponent} from '../../../shared/components/image-slider/image-slider.component';
import {AccountingRowActionsSettings} from '../../models/row-actions-setting.model';
import {TemplateTopActionsSettingsModel} from '../../models/template-top-actions-settings.model';
import {AccountingTemplateFiltersModel} from '../../models/accounting-template-filters.model';
import {STORAGE_KEYS} from '../../../shared/services/storage/storage.keys';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {RolesService} from '../../../shared/services/roles/roles.service';
import {CompaniesService} from '../../../companies/services/companies.service';
import {SortCodComponent} from '../sort-cod/sort-cod.component';
import {SharedService} from '../../../shared/services/shared-service';
import {MassCodReportHistoryComponent} from '../mass-cod-report-history/mass-cod-report-history.component';
import {DateRangePickerService} from '../../../shared/services/date-range-picker.service';
import {CreateInvoiceForMultiCustomerComponent} from '../create-invoice-for-multi-customer/create-invoice-for-multi-customer.component';
import {
    ReceiveDriverAndReadByBarcodeComponent
} from '../../../shipment/recieve-driver-and-read-by-barcode/receive-driver-and-read-by-barcode.component';

@Component({
    selector: 'app-accounting-template',
    templateUrl: './accounting-template.component.html',
    styleUrls: ['./accounting-template.component.scss']
})
export class AccountingTemplateComponent implements OnInit {
    isLoading = false;
    currentLang;
    currency = '';
    codList = [];
    totalRecords = 0;
    rowsPerPageOptions = SHARED_CONSTANTS.TABLE_PAGE_SIZES;
    pageNumber = 0;
    pageSize = SHARED_CONSTANTS.TABLE_DEFAULT_PAGE_SIZE;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    first = 0;
    isAllSelected = false;


    filterFormOn = false;
    @ViewChild('filtersFormOverLay') filtersFormOverLay;
    custodyTypeList = ACCOUNTING_CONSTANTS.CUSTODY_TYPES_LIST;
    optionCustody = this.custodyTypeList[0];
    selectedEntity = '';
    selectedDriver = '';
    customerTypes = ACCOUNTING_CONSTANTS.CUSTOMERS_TYPES_LIST;
    dateRangPickerOptions;
    params: {
        dateFilterType: { value: 'NONE' },
        type: { label: string, value: string },
        paymentMethod: string,
        customerHubId: string,
        customerId: string,
        driverId: string,
        customerType: { label: string, value: string },
        threshold: string,
        terminus: string,
        fromDate: string,
        toDate: string,
        search: string,
        status?: string
    };

    _apiUrl;
    @Input() tableInfo;
    @Input() title;

    permissions = '';
    userRole = '';

    @Input() type: string;


    isOnlyShowSignature = false;

    // use rowActionsSettings to customize row actions
    @Input() rowActionsSettings: AccountingRowActionsSettings;
    // to customize top actions
    @Input() topActionsSettings: TemplateTopActionsSettingsModel;
    // to customize filters
    @Input() filtersSettings: AccountingTemplateFiltersModel;
    @Input() userSumValueAPI = true;
    @Input() listValueKey = 'massCodPackages';

    @Input() dateFiltersList: any[] = EXPORTED_DATE_FILTERS_LIST;

    @ViewChild('userFilter') userFilter;
    @ViewChild('table') table;

    mobAvailable = false;
    isHubUserRole = false;
    isSupportReceiveWithoutReleasingCustody = false;

    sumValues = [];

    paymentMethod: { label: string, value: any };
    paymentsMethod;

    digitFormat = '1.0-2';
    selectedSignatureFilter = 'ALL';
    selectedAttachmentsFilter = 'ALL';

    invoiceTypes = [];
    selectedInvoicesType = 'ALL';


    constructor(private translate: TranslateService,
                private modalService: NgbModal,
                private datePipe: DatePipe,
                private dateRangePickerService: DateRangePickerService,
                private rolesService: RolesService,
                private companyService: CompaniesService,
                private storageService: StorageService,
                private userService: UserService,
                private sharedService: SharedService,
                private confirmationService: ConfirmationService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private messageService: MessageService,
                private shipmentsService: ShipmentsService,
                private usersService: UsersService,
                private httpClient: HttpClient) {
    }


    @Input()
    set apiUrl(val) {
        if (val) {
            this._apiUrl = val;
        }
    }

    get apiUrl() {
        return this._apiUrl;
    }
    userInfo: any;
    isEnableVat: boolean;

    ngOnInit() {
        this.initParams();
        this.initInvoiceTypesList();
        this.userInfo = this.userService.userInfo;
        this.currentLang = this.translate.currentLang;
        this.initUserPermissions();
        this.isHubUserRole = this.userService.isHubUserRole;
        this.currency = this.getCurrency();
        this.mobAvailable = this.userService.userInfo.isUseBankTransfer;
        this.isEnableVat = this.userService.userInfo.isEnableVat;
        this.isSupportReceiveWithoutReleasingCustody = this.userService.userInfo.isSupportReceiveWithoutReleasingCustody;
        this.setDateFiltersList();
        this.paymentsMethod = this.getPaymentsMethod();
        this.dateRangePickerService.getDateRangePickerOptions(0).then(options => {
            this.dateRangPickerOptions = options;
        });
    }

    getPaymentsMethod() {
        return [
            'ALL',
            'CASH',
            'CHEQUE',
            'BANK_TRANSFER',
            'PREPAID',
            'DIGITAL_WALLET',
            'CARD'
        ].map((value) => {
            let label = value;
            this.translate.get(value)
                .subscribe((data) => {
                    label = data;
                });
            return {label, value};
        });
    }

    initParams() {
        this.params = {
            dateFilterType: {value: 'NONE'},
            type: this.custodyTypeList[0],
            customerId: '',
            driverId: '',
            customerType: null,
            paymentMethod: '',
            customerHubId: '',
            threshold: '',
            terminus: '',
            fromDate: '',
            toDate: '',
            search: ''
        };
    }

    resetFilters() {
        if (this.userFilter.selectedUser) {
            this.userFilter.selectedUser = null;
        }
        if (this.paymentMethod) {
            this.paymentMethod = null;
        }
        this.initParams();
        this.onFilter();
    }

    initUserPermissions() {
        // send the page name, to get the user permissions.
        const role = this.userService.userInfo.role === 'SUPER_ADMIN' ? 'SUPER_ADMIN_AS_ADMIN' : this.userService.userInfo.role;
        this.userRole = role;
        this.permissions = this.rolesService.getUserPermissions('ACCOUNTING', role);
    }

    getCurrency() {
        if (this.userService.userInfo && this.userService.userInfo.currency) {
            return this.userService.userInfo.currency;
        }
        return this.storageService.get(STORAGE_KEYS.CURRENCY);
    }

    isShowToggleFilterMenu() {
        return (Object.values(this.filtersSettings).filter(setting => !!setting)).length > 0;
    }

    isShowTopActions() {
        return (Object.values(this.topActionsSettings).filter(setting => !!setting)).length > 0;
    }

    showFiltersMenu(event) {
        this.filtersFormOverLay.toggle(event);
        this.filterFormOn = true;
        event.stopPropagation();
    }

    onFilter() {
        this.getData();
        if (this.userSumValueAPI) {
            this.getSumValues();
        }
        this.filtersFormOverLay.hide();
    }

    onFilterSelectedChange(id) {
        this.params.customerId = (id === undefined ? '' : id);
    }
    onSelectedDriverChange(id) {
        this.params.driverId = (id === undefined ? '' : id);
    }

    onDateSelected(value) {
        this.params.fromDate = this.transformDate(new Date(value.start), false);
        this.params.toDate = this.transformDate(new Date(value.end), false);
        this.onFilter();
    }

    onToggleSelectAll() {
        if (this.totalRecords === 0) {
            return;
        }
        let selected = false;
        if (this.isAllSelected) {
            selected = true;
        }
        this.codList.forEach((pkg: any) => {
            pkg.isSelected = selected;
        });
    }

    loadCodListLazy(e) {
        this.pageNumber = e.first / e.rows;
        this.pageSize = e.rows;
        this.first = e.first;
        this.getData();
        if (this.userSumValueAPI) {
            this.getSumValues();
        }
    }

    setDateFiltersList() {
        if (this.type === 'EXPORTED_BY_ACCOUNTANT') {
            this.dateFiltersList = EXPORTED_PACKAGES_DATE_FILTERS_LIST;
        } else if (['WITH_DRIVER', 'DELIVERED'].indexOf(this.type) > -1) {
            this.dateFiltersList = DELIVERED_PACKAGES_DATE_FILTERS_LIST;
        }
        this.dateFiltersList =  this.dateFiltersList.map((value) => {
            return {label: this.translate.instant(value), value: value};
        });
    }

    getData() {
        this.isLoading = true;
        this.httpClient.get(this._apiUrl, {
            params: this.createParams()
        }).subscribe(
            (res: any) => {
                this.codList = res[this.listValueKey];
                this.totalRecords = res.totalRecordsNo;
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    getSumValues() {
        this.httpClient.get(
            USERS_CONSTANTS.GET_CUSTOMERS_SUM_VALUES + `?status=${this.type}`, {params: this.createParams()}
        ).subscribe(res => {
            const unsortedValuesObject = res;
            const valuesKeysOrder = ['totalMassCodPackagesNumber', 'totalPackagesNumber', 'totalCost', 'totalCodWithoutCost', 'totalCod', 'codSumWithoutReleasingCustody'];
            const sortedValuesObject = {};
            valuesKeysOrder.forEach(key => {
                if (unsortedValuesObject.hasOwnProperty(key)) {
                    sortedValuesObject[key] = unsortedValuesObject[key];
                }
            });
            Object.keys(unsortedValuesObject).forEach(key => {
                if (!sortedValuesObject.hasOwnProperty(key)) {
                    sortedValuesObject[key] = unsortedValuesObject[key];
                }
            });
            this.sumValues = [];
                Object.keys(sortedValuesObject).forEach(key => {
                    if (res[key] !== null && key !== 'totalRecordsNo' && typeof res[key] !== 'boolean' && (key.toUpperCase() !== 'COMMISSIONFEE' || this.userInfo.isEnableCustomerCommissionFee)) {
                        this.sumValues.push({
                            label: `ACCOUNTING.ACCOUNTING_TEMPLATE.SUM_VALUES.${key.toUpperCase()}`,
                            value: res[key]
                        });
                    }
                });
            }, error => {
                console.error(error);
            });
        }

    getDeepData(row, keys) {
        let data = row[keys[0]];
        for (const key in keys) {
            if (key !== '0') {
                data = data[keys[key]];
            }
        }
        return data;
    }

    getConcat(row: any, field: any) {
        return _.get(row, field);
    }

    getLocalTime(dateTime) {
        if (dateTime) {
            return new Date(dateTime).toLocaleTimeString();
        }
        return;
    }

    reset() {
        this.codList = [];
        this.pageNumber = 0;
    }

    onDateFilterChange() {
        this.reset();
        this.getData();
        if (this.userSumValueAPI) {
            this.getSumValues();
        }
    }

    printDriverMassReports() {
        this.isLoading = true;
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.componentInstance.showDates = false;
        modal.componentInstance.showSortBy = false;
        modal.componentInstance.showConfig = false;
        modal.componentInstance.isExportInCarPkgs = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number }) => {
                if (status.isSuccess) {
                    this.usersService.printDriverMassReports(status.driverId).subscribe((res: any) => {
                            window.open(res.url, '_blank');
                            this.isLoading = false;
                        },
                        error => {
                            console.error(error);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        );
    }


    mob() {
        const modal = this.modalService.open(MobComponent, {
            backdrop: 'static',
            windowClass: 'choose-customer',
            size: 'lg'
        });
        modal.result.then((status: { isSuccess: boolean }) => {
            if (status.isSuccess) {
                // here
            }
        });
    }

    createParams() {
        const params = {
            pageSize: this.pageSize,
            page: this.pageNumber + 1,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        for (const param in this.params) {
            if (this.params[param] && typeof this.params[param] === 'object' && this.params[param].value) {
                if (['ALL', 'NONE'].indexOf(this.params[param].value) === -1) {
                    params[param] = this.params[param].value;
                }
            } else {
                if (this.params[param] && ['ALL', 'NONE'].indexOf(this.params[param]) === -1) {
                    params[param] = this.params[param];
                }
            }
        }
        if (this.selectedSignatureFilter !== 'ALL') {
            params['isOnlyShowSignature'] = this.selectedSignatureFilter === 'WITH';
        }
        if (this.selectedAttachmentsFilter !== 'ALL') {
            params['isOnlyAttachmentPackages'] = (this.selectedAttachmentsFilter === 'WITH');
        }

        // will be handle following commented filters
        // if (this.sortByType) {
        //     params['sortByType'] = this.sortByType;
        // }
        // if (this.paymentMethod && this.paymentMethod.value !== 'ALL') {
        //     params['paymentMethod'] = this.paymentMethod.value;
        // }

        return params;
    }

    exportToPdf() {
        this.isLoading = true;
        this.usersService.exportToPdf(this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

    exportCodToExcel() {
        this.isLoading = true;
        this.usersService.exportCodToExcel(this.createParams()).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

    getSelectedPackages() {
        return this.codList.filter(cod => cod.isSelected);
    }

    sortPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let pkgIds = [];
        if (shipment.customerId) {
            pkgIds.push(shipment.customerId);
        } else {
            if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.customerId);
            }
        }

        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        const params = {};
        this.shipmentsService.sortUserPackages(pkgIds, params, this.type === 'PENDING_CUSTOMER_RECEIVING_CONFIRMATION').subscribe(() => {
                this.translate.get(
                    'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'success', detail: data});
                            this.getData();
                            if (this.userSumValueAPI) {
                                this.getSumValues();
                            }
                        }
                    );
                this.isLoading = false;
            },
            error => {
                console.error(error);
                this.translate.get(
                    'ALERTS.PACKAGES_SORTED_SUCCESSFULLY'
                )
                    .subscribe(
                        (data) => {
                            this.messageService.add({severity: 'error', detail: data});
                            this.getData();
                            if (this.userSumValueAPI) {
                                this.getSumValues();
                            }
                        }
                    );
                this.isLoading = false;
            });
    }

    exportPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let pkgIds = [];
        if (shipment.id) {
            pkgIds.push(shipment.id);
        } else {
            if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
            }
        }

        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        this.usersService.exportMassPakcage(pkgIds).subscribe(() => {
            this.messageService.add({
                severity: 'success',
                detail: this.translate.instant('ALERTS.MASS_PACKAGE_EXPORTED_SUCCESSFULLY')
            });
            this.isLoading = false;
            this.getData();
            if (this.userSumValueAPI) {
                this.getSumValues();
            }
        }, (error) => {
            console.error(error);
            this.isLoading = false;

        });
    }

    changeStatusToExported(shipment) {
        this.isLoading = true;
        let pkgIds = [];
        if (shipment.id) {
            pkgIds.push(shipment.id);
        } else {
            if (this.getSelectedPackages().length > 0) {
                pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
            }
        }

        if (pkgIds.length === 0) {
            return;
        }
        this.isAllSelected = false;
        this.usersService.changeStatusToExported(pkgIds).subscribe(() => {
            this.messageService.add({severity: 'success', detail: this.translate.instant('ALERTS.CHANGED_STATUS')});
            this.isLoading = false;
            this.getData();
            if (this.userSumValueAPI) {
                this.getSumValues();
            }
        }, (error) => {
            console.error(error);
            this.isLoading = false;
        });
    }

    onCustodyTypeChange(val) {
        this.codList = [];
        this.optionCustody = (val === null ? this.custodyTypeList[0] : val.value);
        this.initParams();
        this.getData();
        if (this.userSumValueAPI) {
            this.getSumValues();
        }
    }

    verifyDriver() {
        const modal = this.modalService.open(ChooseHubDriverPartnerComponent, {
            backdrop: 'static',
            windowClass: 'verify-driver',
            size: 'lg'
        });
        modal.componentInstance.showPartners = true;
        modal.componentInstance.titleKey = 'ACCOUNTING.RECEIVE_COD_MENUITEM';
        modal.result.then(
            (status: { isSuccess: boolean, barcode?: number, hubId?: number, partnerId?: number }) => {
                if (status.isSuccess) {
                    if (status.barcode) {
                        this.router.navigate([this.authenticationService.companyName + '/home/receive-cod/'
                        + status.barcode]);
                    } else {
                        if (status.hubId) {
                            this.router.navigate([this.authenticationService.companyName + '/home/receive-cod/'
                            + status.hubId], {queryParams: {'is_hub': true}});
                        } else { // partner
                            this.router.navigate([this.authenticationService.companyName + '/home/receive-cod/'
                            + status.partnerId], {queryParams: {'is_partner': true}});
                        }
                    }
                }
            }
        );
    }

    pickWithDriver() {
        this.isLoading = true;
        let pkgIds = [];
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.componentInstance.showDates = false;
        modal.componentInstance.showSortBy = false;
        modal.componentInstance.showConfig = false;
        modal.componentInstance.isPickWithDriver = true;
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number }) => {
                this.isLoading = false;
                if (status.isSuccess) {
                    if (this.getSelectedPackages().length > 0) {
                        pkgIds = this.getSelectedPackages().map(pkg => pkg.id);
                    }
                    this.usersService.pickWithDriver(status.driverId, pkgIds).subscribe(() => {
                        this.getData();
                        if (this.userSumValueAPI) {
                            this.getSumValues();
                        }
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            }
        );
    }

    deliverMultiplePackages() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-payment-type-dialog', size: 'md'});
        modal.componentInstance.isInvoices = this.type === 'INVOICE';
        const successMessage = this.type === 'INVOICE' ? (selectedPackages.length > 1 ? 'ALERTS.INVOICES_PAID_SUCCESSFULLY' : 'ALERTS.INVOICE_PAID_SUCCESSFULLY') : 'ALERTS.MASS_COD_REPORT_DELIVERED_SUCCESSFULLY';
        modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, transferFees: number }) => {
                if (data.paymentType) {
                    this.usersService
                        .deliverMassPackages(selectedPackages, data.paymentType, data.documentNumber, data.deliveryDate, data.transferFees)
                        .subscribe(() => {
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translate.instant(successMessage)
                            });
                            this.isLoading = false;
                            this.getData();
                            if (this.userSumValueAPI) {
                                this.getSumValues();
                            }
                        }, (err) => {
                            console.error(err);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
                this.isAllSelected = false;
            }
        );
    }

    downloadMultiplePackages(all = false) {
        this.isLoading = true;
        if (this.type = 'EXPORTED_BY_ACCOUNTANT') {
            const modal = this.modalService.open(PrintPackageOptionsComponent, {
                backdrop: 'static',
                windowClass: 'verify-driver',
                size: 'lg'
            });
            modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93;
            modal.result.then(
                (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                    if (status.isSuccess) {
                        if (all) {
                            this.DownloadAllPackages(status.printType, status.orderBy);
                        } else {
                            this.actuallyDownloadPackages(status.printType, status.orderBy);
                        }
                    } else {
                        this.isLoading = false;
                    }
                }
            );
        } else {
            if (all) {
                this.DownloadAllPackages();
            } else {
                this.actuallyDownloadPackages();
            }
        }
    }

    printSelectedPkgs(type, callBack?, singlePackage?) {
        const selectedPkgs = this.getSelectedPackages();
        const previouslyPrintedPkgs: any = selectedPkgs.filter(pkg => pkg.isPrintedByAccountant);
        if (previouslyPrintedPkgs && previouslyPrintedPkgs.length || type === 'single' && singlePackage && singlePackage.isPrintedByAccountant) {
            this.confirmationService.confirm({
                message: this.translate.instant(selectedPkgs.length > 1 && type !== 'single' ? 'ACCOUNTING.PREVIOUSLY_PRINTED_PACKAGES_REPORTS' :
                    'ACCOUNTING.SINGLE_PACKAGE_PREVIOUSLY_PRINTED_REPORTS'),
                accept: () => {
                    if (callBack) {
                        callBack.apply();
                    } else {
                        this.printPkgReport(type);
                    }
                },
                acceptLabel: this.translate.instant('GENERAL.YES'),
                rejectLabel: this.translate.instant('GENERAL.NO'),
            });
        } else {
            if (callBack) {
                callBack.apply();
            } else {
                this.printPkgReport(type);
            }
        }
    }

    printPkgReport(type) {
        switch (type) {
            case 'list':
                this.printList();
                break;
            case 'multiple':
                this.downloadMultiplePackages();
                break;
        }
    }

    DownloadAllPackages(printType?: string, orderBy?) {
        this.messageService.add({
            severity: 'success',
            detail: this.translate.instant('ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE'),
            life: 10000
        });
        this.shipmentsService.downloadAllMassPackages(printType).subscribe(
            () => {
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }


    actuallyDownloadPackages(printType?: string, orderBy?) {
        this.isLoading = true;
        this.messageService.add({
            severity: 'success',
            detail: this.translate.instant('ALERTS.PRINT_MULTI_REPORTS_WAITING_MESSAGE')
        });
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleMassPackages(selectedPackages, printType, orderBy).subscribe(
            () => {
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    sortPackages() {
        const modal = this.modalService.open(ChooseExistingComponent, {
            backdrop: 'static',
            windowClass: 'choose-customer',
            size: 'lg'
        });
        modal.componentInstance.customersURL = USERS_CONSTANTS.GET_RECEIVED_COD_CUSTOMERS;
        modal.componentInstance.customersKey = 'codCustomers';
        modal.componentInstance.isFullName = true;
        modal.result.then(
            (status: { isSuccess: boolean, user: any }) => {
                if (status.isSuccess) {
                    this.router.navigate([this.authenticationService.companyName + 'home/sort-cod/'
                    + status.user.customerId]);
                }
            }
        );
    }

    printList() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.downloadMultipleMassReports(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    PrintEnvelope() {
        this.isLoading = true;
        const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
        this.shipmentsService.printAsEnvelope(selectedPackages).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    createInvoiceConfirmation(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        let title, confirmationMessage;
        if (shipment) {
            title = 'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICE';
            confirmationMessage = 'MANAGE_COD_CUSTOMERS.INVOICES.ALARMS.CREATE_INVOICE_CONFIRMATION';
        } else {
            title = 'MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES';
            confirmationMessage = 'MANAGE_COD_CUSTOMERS.INVOICES.ALARMS.CREATE_MULTIPLE_INVOICE_CONFIRMATION';
        }

        const modal = this.modalService.open(CustomConfirmationMessageComponent,
            <any>{backdrop: 'static', windowClass: 'custom-confirmation-message', size: 'sm'});

        modal.componentInstance.title = title;
        modal.componentInstance.confirmMessage = confirmationMessage;
        modal.componentInstance.confirmButtonLabel = 'ACTIONS.CREATE';
        modal.componentInstance.styleClass = 'logestechs-theme';
        if (shipment) {
            modal.componentInstance.switchButtonLabel = 'ACTIONS.PRINT';
        }
        modal.result.then(
            (response) => {
                if (response.confirm) {
                    // create invoice api;
                    this.createInvoice(shipment, event, response.switchButtonValue);

                } else {
                    this.isLoading = false;
                }
            }
        );
    }

    createInvoiceForCustomer() {
        const modal = this.modalService.open(CreateInvoiceForMultiCustomerComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.bin = {};
        // modal.componentInstance.isEditMode = this.triggeredBin.isReserved;
        modal.result.then(
            (value) => {
                if (value && value.isSuccess) {
                    this.getData();
                    if (this.userSumValueAPI) {
                        this.getSumValues();
                    }
                }
            }
        );
    }

    createInvoice(shipment, event, isPrint = false) {
        const ids = shipment ? [shipment.id] : this.getSelectedPackages().map(pkg => pkg.id);
        const body = {ids: ids};

        this.usersService.createInvoice(body).subscribe(
            () => {
                this.getData();
                this.isAllSelected = false;
                this.isLoading = false;
                if (isPrint) {
                    this.printPackage(shipment, event);
                }
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }


    actuallyPrintPackage(shipment, printType?: string, orderBy?) {
        const pkgId = shipment.id;
        if (this.type === 'MASS_INVOICE') {
            this.shipmentsService.downloadMassInvoicePackagePdf(pkgId, printType, orderBy).subscribe((res: any) => {
                    window.open(res.url, '_blank');
                    this.isLoading = false;
                },
                error => {
                    console.error(error);
                    this.isLoading = false;
                });
        } else {
            this.shipmentsService.downloadMassPackagePdf(pkgId, printType, orderBy).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.error(error);
                this.isLoading = false;
            });
        }
    }

    printPackage(shipment, event) {
        event.stopPropagation();
        this.isLoading = true;
        if (['EXPORTED_BY_ACCOUNTANT', 'WITH_DRIVER', 'DELIVERED'].indexOf(this.type) > -1) {
            const modal = this.modalService.open(PrintPackageOptionsComponent, {
                backdrop: 'static',
                windowClass: 'verify-driver',
                size: 'lg'
            });
            modal.componentInstance.isShowPrintType = this.authenticationService.companyId === 93 || shipment.isAddToMassInvoice;
            modal.result.then(
                (status: { isSuccess: boolean, printType: string, orderBy?: string }) => {
                    if (status.isSuccess) {
                        this.actuallyPrintPackage(shipment, status.printType, status.orderBy);
                    } else {
                        this.isLoading = false;
                    }
                }
            );
        } else {
            this.actuallyPrintPackage(shipment);
        }
    }

    openExcel() {
        this.confirmationService.confirm({
            message: this.translate.instant('ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'),
            accept: () => {
                this.isLoading = true;
                const selectedPackages = this.getSelectedPackages().map(pkg => pkg.id);
                const params = this.createParams();
                params['ids'] = selectedPackages;

                let exportedPackages = this.shipmentsService.downloadExcelForSortCod(params);
                if (['EXPORTED_BY_ACCOUNTANT', 'INVOICE', 'DELIVERED', 'PENDING_CUSTOMER_RECEIVING_CONFIRMATION'].indexOf(this.type) > -1) {
                    exportedPackages = this.shipmentsService.downloadExcelForExportedCod({ids: selectedPackages});
                }
                exportedPackages.subscribe((response: any) => {
                    window.open(response.url, '_blank');
                    this.isLoading = false;
                });
            },
            reject: () => {
                this.isLoading = false;
            },
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO')
        });
    }

    disableCreateMultipleInvoices() {
        const selectedPackages = this.getSelectedPackages() ? this.getSelectedPackages() : [];
        let enabledCustomersBilling = false;
        if (selectedPackages.length) {
            let customersWithOutBilling = [];
            customersWithOutBilling = this.getSelectedPackages().filter(customer => customer.isCustomerBilled === false);
            enabledCustomersBilling = customersWithOutBilling.length ? false : true;
        }
        return !enabledCustomersBilling || !this.getSelectedPackages().length || this.params.dateFilterType.value !== 'NONE';
    }

    exportCustomersCodToExcel() {

        this.confirmationService.confirm({
            message: this.translate.instant('ACCOUNTING.DOWNLOAD_EXCEL_ALL_CONFIRMATION'),
            accept: () => {
                this.isLoading = true;
                const params = {};
                if (this.params.fromDate && this.params.toDate) {
                    params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    params['fromDate'] = this.transformDate(this.params.fromDate);
                    params['toDate'] = this.transformDate(this.params.toDate);
                }

                this.shipmentsService.exportCustomersCod(params).subscribe((response: any) => {
                    window.open(response.url, '_blank');
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
        });
    }

    transformDate(date, hadTime = false) {
        if (hadTime) {
            return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
        } else {
            return this.datePipe.transform(date, 'yyyy-MM-dd');
        }
    }

    showCreateInvoice() {
        return this.userService.userInfo.isInvoicingEnabled;
    }

    onToggleSelection() {
        const selectedPackages = this.codList.filter(pkg => (pkg.isSelected));
        if (selectedPackages) {
            this.isAllSelected = selectedPackages.length === this.codList.length;
        }
    }

    copyMessage($event, list?, barcode?) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }

    isSignatureExist(deliveryProofUrlList) {
        return deliveryProofUrlList && deliveryProofUrlList.length > 0;
    }

    openSignatureImages($event, deliveryProofUrlList) {
        $event.stopPropagation();
        const modal = this.modalService.open(ImageSliderComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.isModal = true;
        modal.componentInstance.isSignature = true;
        modal.componentInstance.images = deliveryProofUrlList;
    }
    public openImages(event, pkg) {
        event.stopPropagation();
        const modal = this.modalService.open(ImageSliderComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.isModal = true;
        modal.componentInstance.images = JSON.parse(pkg.deliveryProofUrlJson).urls;
    }

    preDeliverPackage(shipment, event) {
        if (shipment.isAddToMassInvoice) {
            this.confirmationService.confirm({
                message: this.translate.instant('ACCOUNTING.DELIVERY_MASS_INVOICE_PACKAGES_REPORT'),
                reject: () => {
                   this.deliverPackage(shipment, event, true);
                },
                accept: () => {
                  this.deliverPackage(shipment, event, false);
                },
                acceptLabel: this.translate.instant('ACCOUNTING.ACCEPT_DELIVERY_MASS_INVOICE_PACKAGES_REPORT'),
                rejectLabel: this.translate.instant('ACCOUNTING.REJECT_DELIVERY_MASS_INVOICE_PACKAGES_REPORT'),
            });
        } else {
            this.deliverPackage(shipment, event);
        }
    }

    deliverPackage(shipment, event, isAddToMassInvoice = false) {
        this.isLoading = true;
        event.stopPropagation();
        const modal = this.modalService.open(ChoosePaymentTypeComponent,
            {backdrop: 'static', windowClass: 'choose-payment-type-dialog', size: 'md'});
        modal.componentInstance.showPrintReport = true;
        modal.componentInstance.isInvoices = this.type === 'INVOICE';
        modal.componentInstance.addAttachments = true;
        modal.componentInstance.massCodId = shipment.id;
        const successMessage = this.type === 'INVOICE' ? 'ALERTS.INVOICE_PAID_SUCCESSFULLY' : 'ALERTS.MASS_PACKAGE_DELIVERED_SUCCESSFULLY';

        modal.result.then(
            (data: { paymentType: any, documentNumber: any, deliveryDate: any, printReport: boolean, transferFees: number, attachments?: File[] }) => {
                if (data.paymentType) {
                    this.usersService.deliverMassPackage(shipment.id, isAddToMassInvoice, data.paymentType, data.documentNumber,
                        this.transformDate(data.deliveryDate, true), data.transferFees, data.attachments)
                        .subscribe(() => {
                            this.isLoading = false;
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translate.instant(successMessage)
                            });
                            this.getData();
                            if (this.userSumValueAPI) {
                                this.getSumValues();
                            }
                            if (data.printReport) {
                                this.printPackage(shipment, event);
                            }
                        }, (err) => {
                            console.error(err);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        );
    }

    exportToExcel(shipment, event, receive = false) {
        event.stopPropagation();
        this.isLoading = true;
        if (receive) {
            this.shipmentsService.exportToExcelForReceivedCod(shipment.customerId, this.createParams()).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
                console.error(error);
            });
        } else {
            const pkgId = shipment.id;
            this.shipmentsService.exportToExcel(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
                console.error(error);
            });
        }
    }

    showCurrency(item: { label: string, value: string }) {
        return !item.label.includes('NUMBER');
    }

    goToCustomerCOD(customer) {
        let type = '';
        switch (this.type) {
            case 'EXPORTED_BY_ACCOUNTANT':
                type = 'EXPORT';
                break;
            case 'WITH_DRIVER':
                type = 'IN_CAR';
                break;
            default:
                type = this.type;
        }
            const modal = this.modalService.open(SortCodComponent, {
                size: 'xl'
            });
            modal.componentInstance.customerId = customer.id;
            modal.componentInstance.isExport = type === 'EXPORT';
            modal.componentInstance.viewSingleCustomer = true;
            modal.componentInstance.singleCustomerDetails = customer;
            modal.componentInstance.isPendingCustomer = this.type === 'PENDING_CUSTOMER_RECEIVING_CONFIRMATION';
            modal.componentInstance.isMassInvoice = type === 'MASS_INVOICE';
            modal.componentInstance.receive = false;
            modal.componentInstance.sumStatus = this.type;
            if (this.params.fromDate) {
                modal.componentInstance.fromDate = this.params.fromDate;
                modal.componentInstance.selectedDateFilterCustomer = this.params.dateFilterType.value && this.params.dateFilterType.value !== 'NONE' ? this.params.dateFilterType.value : 'CREATED_DATE';
            }
            if (this.params.toDate) {
                modal.componentInstance.toDate = this.params.toDate;
            }

            if (type.length) {
                modal.componentInstance.type = type;
            }
            if (type === 'INVOICE') {
                modal.componentInstance.invoices = true;
            }

            modal.result.then(result => {
                this.getData();
                if (this.userSumValueAPI && this.type !== 'MASS_INVOICE') {
                    this.getSumValues();
                }
            });
    }

    showHistory($event, record) {
        $event.stopPropagation();
        const model = this.modalService.open(MassCodReportHistoryComponent, {
            backdrop: 'static'
        });
        model.componentInstance.report = record;
    }

    printAsEnvelopPkg(event) {
        const pkgId = event.id;
        this.shipmentsService.printAsEnvelope(pkgId).subscribe((res: any) => {
                window.open(res.url, '_blank');
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.isLoading = false;
            });
    }

    paidPkg(event) {
        this.confirmationService.confirm({
            message: this.translate.instant('ACCOUNTING.PAY_MASS_INVOICE_PACKAGES_REPORT'),
            accept: () => {
                this.isLoading = true;
                const pkgId = event.id;
                const params = {
                    pageSize: this.pageSize,
                    page: this.pageNumber + 1,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                };

                this.shipmentsService.payMassInvoice(pkgId, params).subscribe((res: any) => {
                    this.printPaidPkg(res.id);
                    this.isLoading = false;
                    this.getData();
                    this.messageService.add({severity: 'success', detail:  this.translate.instant('ALERTS.MASS_INVOICE_PAID_SUCCESSFULLY')});
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });

            },
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
        });
    }

    printPaidPkg(id) {
        this.confirmationService.confirm({
            message: this.translate.instant('ACCOUNTING.PRINT_PAY_MASS_INVOICE_PACKAGES_REPORT'),
            accept: () => {
                this.isLoading = true;
                this.shipmentsService.downloadMassInvoicePackagePdf(id).subscribe((res: any) => {
                    this.isLoading = false;
                }, error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
            acceptLabel: this.translate.instant('GENERAL.YES'),
            rejectLabel: this.translate.instant('GENERAL.NO'),
        });
    }

    onChangePaymentMethod() {
        this.params.paymentMethod = this.paymentMethod.value;
        this.onFilter();
    }

    setSignatureFilter(filter: string) {
        this.selectedSignatureFilter = filter;
        this.getData();
    }
    setAttachmentFilter(filter: string) {
        this.selectedAttachmentsFilter = filter;
        this.getData();
    }

    public readByBarcode() {
        let className = 'read-by-barcode';
        if (this.currentLang === 'en') {
            className = 'read-by-barcode-en';
        }
        const modal = this.modalService.open(ReceiveDriverAndReadByBarcodeComponent, <any>{
            backdrop: 'static',
            windowClass: className,
            size: <any>'xl'
        });
        modal.componentInstance.isReadByBarcode = true;
        modal.componentInstance.showPickWithDriver = this.topActionsSettings.pickWithDriver;
        modal.componentInstance.showCreateInvoice = this.topActionsSettings.createInvoice && this.showCreateInvoice();
        modal.componentInstance.showDeliver = this.topActionsSettings.deliver;
        modal.componentInstance.exportCODModal = true;
        modal.componentInstance.type = this.type;
        modal.componentInstance.tableInfo = this.tableInfo;

        modal.result.then(
            (status: any) => {
               this.getData();
            }
        ).catch(
            () => {
            }
        );
    }


    private initInvoiceTypesList() {
        this.invoiceTypes = ['ALL', 'PAID', 'UNPAID'].map(value => {
            return {
                value: value,
                label: this.translate.instant(value)
            };
        });
    }

    onInvoicesTypeSelected(event) {
        this.params.status = event;
    }

    onChangeHubMethod(event) {
        this.params.customerHubId = event;
        this.onFilter();
    }

    openDriverDialog() {
        const modal = this.modalService.open(ChooseDriverComponent, <any>{
            backdrop: 'static',
            windowClass: 'assign-package-to-modal',
            size: 'md'
        });
        modal.componentInstance.showHubs = false;
        modal.componentInstance.showDates = false;
        modal.componentInstance.showSortBy = false;
        modal.componentInstance.showConfig = false;
        modal.componentInstance.showExportToExcel = false;
        modal.componentInstance.isExportInCarPkgs = false;
        modal.componentInstance.buttonLabel = 'MANAGE_SHIPMENTS.ASSIGN_TO_DRIVER';
        modal.result.then(
            (status: { isSuccess: boolean, driverId?: number }) => {
                if (status.isSuccess) {
                    this.shipmentsService.changeDriver(status.driverId, this.getSelectedPackages().map(pkg => pkg.id)).subscribe(
                        res => {
                            this.getData();
                            this.messageService.add({
                                severity: 'success',
                                detail: this.translate.instant('ALERTS.DRIVER_CHANGED_SUCCESSFULLY')
                            });
                            this.isLoading = false;

                        }, err => {
                            console.error(err);
                        }
                    );
                } else {
                }
            }
        ).catch(
            () => {
            }
        );
    }
}
