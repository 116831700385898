
<ng-container>
<div class="input-value w-100 national-phone-input" *ngIf="!internationalPhone">
    <app-field-input id="phone-input"
                     [label]="label"
                     size="30"
                     [formControl]="formControlPass"
                     (whenChange)="onValueChange($event)"/>
</div>

<ng-container class="input-value" *ngIf="internationalPhone">
    <app-field-set-form [label]="label"
                        class="col-12 p-0"
                        [filterActive]="!!value"
                        [formControlObj]="form.get('phone')">
        <ngx-intl-tel-input
                [class]="cssClassPass"
                class="d-flex align-items-center"
                [ngClass]="{'page-ltr': selectedLanguage === 'en', 'ng-invalid': formControlPass?.errors}"
                #intlTelInput
                [cssClass]="'field-input-value'"
                [preferredCountries]="[CountryISO.Palestine, CountryISO.Jordan,CountryISO.SaudiArabia,CountryISO.Turkey, CountryISO.Kuwait , CountryISO.Bahrain, CountryISO.Libya,CountryISO.Oman]"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.All]"
                [selectFirstCountry]="false"
                (focusout)="focusout()"
                [selectedCountryISO]="selectedCountry"
                [phoneValidation]="false"
                (ngModelChange)="onValueChange($event)"
                (countryChange)="onCountryChanged($event)"
                [formControl]="form.get('phone')"
        ></ngx-intl-tel-input>
    </app-field-set-form>


</ng-container>
</ng-container>
