<div class="close-icon" (click)="closeModal()"><img src="../../../../assets/icons/close-icon.svg" alt=""/></div>
<div [ngClass]="{'page-ltr': currentLang === 'en'}" appBarcodeScanner (barcodeRead)="readBarcode($event)">
    <div class="row d-inline-flex main-container">
        <div class="side-container">
            <div class="title-container">
                <div class="label">
                    {{ 'UNLISTED_PACKAGES.TITLE' | translate }}
                </div>
            </div>

            <div class="customers-list-container">
                <div class="list-header">
                    {{ 'UNLISTED_PACKAGES.CHOOSE_CUSTOMER' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchCustomer($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container" (scroll)="onCustomersScroll($event)">
                    <div class="list-item"
                         [ngClass]="selectedCustomer && customer.id === selectedCustomer.id ? 'list-item-selected' : ''"
                         *ngFor="let customer of customers; trackBy: trackCustomerById" (click)="selectCustomer(customer)">
                        <div class="list-name">
                            {{customer.firstName + ' ' + customer.lastName + (customer.businessName ? ' (' + customer.businessName + ')' : '')}}
                        </div>
                    </div>
                </div>
                <div *ngIf="isLoadingCustomers || isLoading" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>

            <div class="drivers-list-container">
                <div class="list-header">
                    {{ 'UNLISTED_PACKAGES.CHOOSE_DRIVER' | translate }}
                </div>
                <div class="search-container">
                    <input type="text" class="search-field" appInputDebounced (inputDebounced)="searchDriver($event)"
                           placeholder="{{'GENERAL.SEARCH' | translate}}...">
                </div>
                <div class="list-container">
                    <div class="list-item"
                         [ngClass]="selectedDriver && driver.id === selectedDriver.id ? 'list-item-selected' : ''"
                         *ngFor="let driver of drivers; trackBy: trackDriverById" (click)="selectDriver(driver)">
                        <div class="list-name">
                            {{driver.firstName + ' ' + driver.lastName}}
                        </div>
                    </div>
                </div>
                <div *ngIf="isLoadingDrivers || isLoading" class="list-loader">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            </div>
        </div>

        <div class="table-container">
            <p-table [value]="Object.entries(packages)" [loading]="isLoading || isReading" class="entities-table-component"
                     [responsive]="true" scrollHeight="auto" styleClass="entities-table">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'UNLISTED_PACKAGES.TABLE.INVOICE_NUMBER' | translate }}</th>
                        <th>{{ 'UNLISTED_PACKAGES.TABLE.CUSTOMER' | translate }}</th>
                        <th>{{ 'UNLISTED_PACKAGES.TABLE.DRIVER' | translate }}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-package>
                    <tr [ngClass]="package[1].success ? 'success-row' : package[1].error ? 'error-row' : ''">
                        <td>
                            <i class="fas fa-info-circle error-icon" *ngIf="package[1].error"
                               tooltipPosition="top"
                               [tooltipZIndex]="100000000"
                               pTooltip="{{ package[1].errorMessage }}"></i>
                            {{ package[0] }}
                        </td>
                        <td>
                            {{ package[1].customerName }}
                        </td>
                        <td>
                            {{ package[1].driverName }}
                        </td>
                        <td>
                            <i class="fas fa-trash" (click)="deletePackage(package)" [ngClass]="{'disabled disabled-btn': package[1].error || package[1].success}"></i>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="bottom-actions">
                <div class="start-actions">

                </div>
                <div class="end-actions">
                    <div [ngClass]="{'disabled-btn disabled': isLoading || !Object.keys(packages).length}"
                         class="done-action" (click)="onSubmitPackages()">
                        <span>{{ 'UNLISTED_PACKAGES.ACTIONS.SUBMIT' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
