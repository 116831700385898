import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SubscriptionTrackerComponent} from '../../behavior/subscription-tracker.component';
import {FormGroup} from '@angular/forms';
import {ColumnDef, COLUMNS_TYPE, GenericTableComponent} from '../generic-table/generic-table.component';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyValuePipe} from '../../pipes/currency-value.pipe';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-generic-dialog',
    templateUrl: './generic-dialog.component.html',
    styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {

    form: FormGroup;
    currentLang = 'en';
    isLoading = false;
    asnFee;
    dateRangPickerOptions;
    @ViewChild('datepicker') datepicker;
    @ViewChild('table') table: GenericTableComponent;
    filterParams = {};
    columnsDef: ColumnDef[];
    tableConfig;
    isThereSelection = false;
    numberOfSelected = 0;
    selected = [];
    body: any;
    topLabel = 'FULFILMENT.ACCOUNTING.ASN_FEES.DETAILS';
    doneLabel = 'ACTIONS.DONE';
    cancelLabel = 'ACTIONS.CANCEL';
    hideBottom;
    onClose;
    onDone;
    disableDone = new BehaviorSubject(false);
    disableDoneOb;

    constructor(private fulfilmentService: FulfilmentService,
                private activeModal: NgbActiveModal,
                private currencyValuePipe: CurrencyValuePipe,
                private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this.disableDoneOb = this.disableDone.asObservable();
        this.currentLang = this.translateService.currentLang;
        this.initColumnDef();

        this.tableConfig = {
            url: `:api/admin/fulfillment/items/by-status`,
            preRequestHandler: (req: {
                params: any; body: any; url: any;
            }) => {
                if (this.asnFee) {
                    const date = new Date(this.asnFee.createdDate);
                    req.params['locationId'] = this.asnFee.id;
                    req.params['customerId'] = this.asnFee.customerId;
                    req.params['status'] = 'REJECTED';

                }
            },
            showFooter: false
        };

    }

    private initColumnDef() {
        this.columnsDef = [

            {
                header: 'FULFILMENT.ACCOUNTING.ASN_FEES.ASN_FEES_NUMBER',
                field: 'barcode',
                allowCopy: true
            },
            {
                header: 'FULFILMENT.RETURNED.REJECTED.ITEM_NAME',
                field: 'name',
            },
            {
                header: 'FULFILMENT.ITEM.TABLE.SKU',
                field: 'sku',
            },
            {
                header: 'FULFILMENT.RETURNED.RETURNED.RETURNED_REASON',
                field: 'rejectedReason',
                tdType: COLUMNS_TYPE.CUSTOM,
                getHTML: (row) => {
                    return this.translateService.instant('FULFILMENT.ITEM.REJECTION_REASONS.' + row.rejectReasonKey);
                    // return this.translateService.instant(`FULFILMENT.BIN.AVAILABILITY_FILTER_LIST.${row.status}`);
                }
            },
            {
                header: 'FULFILMENT.RETURNED.REJECTED.RETURN_DATE',
                field: 'createdDate',
                tdType: COLUMNS_TYPE.DATE,
            }
        ];

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    closeModal(isSuccess?) {
        if (isSuccess) {
            this.activeModal.close({isSuccess: isSuccess});
        }
        this.activeModal.close();
    }

    public onToggleSelection($event = null) {
        if (event) {
            event.stopPropagation();
        }

        const selectedPackages = this.getSelected();

        this.isThereSelection = selectedPackages.length !== 0;
        this.numberOfSelected = selectedPackages.length;
        this.selected = selectedPackages;
    }

    private getSelected() {
        const selectedPackages = [];
        const orders = this.table.value;
        if (!orders) {
            return [];
        }

        orders.forEach(
            (plane: any) => {
                if (plane.isSelected) {
                    selectedPackages.push(plane);
                }
            }
        );
        return selectedPackages;
    }

    takeAnAction(action) {
        this.isLoading = true;

    }

    doneCLicked() {

        if (this.onDone) {
            this.onDone().subscribe(res => {
                if (res) {
                    this.closeModal(true);
                } else {
                }
            });
        } else {
            this.closeModal();

        }
    }

    cancelCLicked() {
        this.closeModal();
    }
}
