import {Component, Input, OnInit} from '@angular/core';
import {ThirdParty} from '../../Models/third-party';

@Component({
  selector: 'app-third-party-card',
  templateUrl: './third-party-card.component.html',
  styleUrls: ['./third-party-card.component.scss']
})
export class ThirdPartyCardComponent implements OnInit {
  @Input() card: ThirdParty;
  constructor() { }

  ngOnInit(): void {
  }

}
