import {Component, Input, OnInit} from '@angular/core';
import {REPORTS_CONSTANTS} from '../../../reports-constants';

@Component({
    selector: 'app-zones-to-packages-summary-report',
    templateUrl: './zones-to-packages-summary-report.component.html',
    styleUrls: ['./zones-to-packages-summary-report.component.css']
})
export class ZonesToPackagesSummaryReportComponent {
    public zonesToReport = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_TO_REPORT;
    public zonesToReportAPI = REPORTS_CONSTANTS.SUMMARY_REPORTS.ZONES_SHIPPED_TO_REPORT_API;

    constructor() {
    }
}
