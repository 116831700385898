import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMINISTRATION_CONSTANTS} from '../administration-constants';

@Injectable()
export class PackageContentsService {

    constructor(private http: HttpClient) {}

    public getContentsList(query) {
        return this.http.get(ADMINISTRATION_CONSTANTS.GET_PACKAGE_CONTENTS_LIST + query);
    }
    public addPackageContent(body) {
        return this.http.post(ADMINISTRATION_CONSTANTS.NEW_PACKAGE_CONTENT, body);
    }

    public editPackageContent(contentId, body) {
        return this.http.put(ADMINISTRATION_CONSTANTS.EDIT_PACKAGE_CONTENT.replace('{contentItemId}', contentId), body);
    }
    public deletePackageContent(contentId) {
        const url = ADMINISTRATION_CONSTANTS.DELETE_PACKAGE_CONTENT.replace('{contentItemId}', contentId);
        return this.http.delete(url);
    }
}
