import { Component, OnInit } from '@angular/core';
import {REPORTS_CONSTANTS} from '../../administration/reports-constants';

@Component({
  selector: 'app-hubs-reports',
  templateUrl: './hubs-reports.component.html',
  styleUrls: ['./hubs-reports.component.scss']
})
export class HubsReportsComponent implements OnInit {
  public hubsReports = REPORTS_CONSTANTS.HUBS_REPORTS.HUB_REPORTS_INFO;
  public hubsReportsAPI = REPORTS_CONSTANTS.HUBS_REPORTS.HUBS_REPORTS_API;
  constructor() { }

  ngOnInit() {
  }

}
