export const USERS_CONSTANTS = {
    GET_USERS: ':api/admin/users',
    GET_CUSTOMERS: ':api/admin/customers',
    GET_CUSTOMERS_DROP_DOWN: ':api/admin/customers/drop-down',
    GET_CUSTOMERS_DROP_DOWN_FOR_INVOICE: ':api/accountant/customers/exported',
    GET_CUSTOMERS_LIST: ':api/admin/customers/list',
    GET_CUSTOMERS_FOR_ADDITION_FEE: ':api/accountant/pricing/additional-package-fees/{feeId}/customers',
    GET_INSURANCE_VALUE: ':api/admin/packages/additional-fees',
    GET_COD_MISMATCH_CUSTOMERS_DROP_DOWN: ':api/admin/users/cod-receivers',
    GET_CUSTOMER: ':api/admin/customers/{customerId}',
    UPLOAD_CUSTOMER_ATTACHMENTS: ':api/admin/customers/{customerId}/attachments',
    UPLOAD_PACKAGE_ATTACHMENTS: ':api/admin/packages/{packageId}/add-attachment',
    GET_UPLOAD_CUSTOMER_ATTACHMENTS: ':api/admin/customers/{customerId}/attachments',
    GET_PRINTED_REPORTS: ':api/admin/printed-reports',
    DELETE_PRINTED_REPORTS: ':api/admin/printed-reports/delete',
    GET_DRIVERS: ':api/admin/drivers',
    GET_ARCHIVED_USERS: ':api/admin/customers/deleted',
    REMOVE_USER: ':api/admin/users/{userId}/delete',
    REMOVE_USER_FOREVER: ':api/admin/users/{userId}/deleteUser',
    REMOVE_CUSTOMER: ':api/admin/customers/{userId}/delete',
    REMOVE_CUSTOMER_FOREVER: ':api/admin/customers/{userId}/deleteCustomer',
    RETRIEVE_CUSTOMER: ':api/admin/customers/{userId}/un-delete',
    RETRIEVE_USER: ':api/admin/users/{userId}/un-delete',
    VERIFY_CUSTOMER: ':api/admin/customers/{customerId}/verify',
    ADD_USER: ':api/admin/users/new',
    EDIT_USER: ':api/admin/users/{userId}/modify',
    EDIT_CUSTOMER_STATUS: ':api/admin/customers/{userId}/status',
    EDIT_USER_STATUS: ':api/admin/users/{userId}/status',
    EDIT_CUSTOMER: ':api/admin/customers/{userId}/modify',
    ADD_CUSTOMER: ':api/admin/customers/new',
    GET_COD_CUSTOMERS: ':api/admin/customers/cod',
    GET_ALL_CUSTOMERS_COD: ':api/accountant/mass-packages/multiple',
    GET_ALL_EMPTY_CUSTOMERS_COD: ':api/accountant/mass-packages/multiple/empty',
    GET_CUSTOMERS_SUM_VALUES: ':api/accountant/mass-packages/sum-values',
    GET_CUSTOMER_MISMATCH_PACKAGES: ':api/accountant/cod-mismatch/{codMismatchId}/packages',
    GET_RECEIVED_COD_CUSTOMERS: ':api/accountant/customers/received-cod',
    GET_RECEIVED_COD_CUSTOMERS_WITHOUT_CUSTODY: ':api/accountant/customers/not-received-cod',
    GET_SORTED_COD_CUSTOMERS: ':api/accountant/sorted/mass-packages',
    GET_EXPORTED_COD_CUSTOMERS: ':api/accountant/mass-packages/exported',
    GET_MASS_INVOICES_REPORT: ':api/accountant/mass-invoices',
    GET_DELIVERED_COD_CUSTOMERS: ':api/accountant/mass-packages/delivered',
    GET_IN_CAR_COD_CUSTOMERS: ':api/accountant/mass-packages/in-car',
    GET_CUSTOMER_COD_PACKAGES: ':api/admin/customers/{customerId}/cod',
    GET_CUSTOMER_SUM_VALUES: ':api/accountant/received/sum-values',
    GET_CUSTOMER_SUM_VALUES_WITHOUT_RELEASING_CUSTODY: ':api/accountant/packages/not-received-cod',
    GET_RECEIVED_COD_PACKAGES: ':api/accountant/customers/{customerId}/received-cod',
    GET_SORTED_COD_PACKAGES: ':api/accountant/sorted/mass-packages/{customerId}/cod-packages',
    VERIFY_DRIVER: ':api/accountant/drivers/verify',
    VERIFY_DRIVER_PACKAGES: ':api/hub-manager/drivers/verify',
    GET_HUB_PACKAGES: ':api/accountant/hubs/cod-packages',
    EDIT_COD_COST: ':api/admin/packages/{packageId}/edit-cost',
    EDIT_PACKAGES_DRIVER_EARNING: ':api/admin/packages/{packageId}/driver-earning',
    EDIT_HUB_COST: ':api/admin/packages/{packageId}/hub-cost',
    EDIT_DRIVER_EARNING: ':api/accountant/packages/{packageId}/driver-earning',
    GET_VEHICLE: ':api/admin/containers/{containerId}',
    GET_DRIVER: ':api/admin/users/{userId}',
    DRIVER_PACKAGES: ':api/admin/drivers/{driverId}/carried-packages',
    DRIVERS_PACKAGES: ':api/admin/drivers/carried-packages',
    DRIVER_PICKUPS: ':api/admin/pickups/drivers/{driverId}',
    PARTNER_PACKAGES: ':api/admin/packages/partners/{partnerId}/returned',
    DRIVER_PACKAGES_PDF: ':api/admin/drivers/{driverId}/packages-pdf-report',
    DRIVER_PACKAGES_EXCEL: ':api/admin/drivers/{driverId}/packages-excel-report',
    DRIVER_IN_CAR_PACKAGES_EXCEL: ':api/accountant/drivers/{driverId}/mass-reports/excel',
    DRIVER_PICKUPS_PDF: ':api/admin/pickups/pdf',
    DRIVER_MASS_REPORTS_PDF: ':api/accountant/drivers/{driverId}/mass-reports/pdf',
    CHANGE_USER_PASSWORD: ':api/admin/users/{userId}/changePassword',
    CHANGE_CUSTOMER_PASSWORD: ':api/admin/customers/{customerId}/changePassword',
    GET_DELAYED_COD_REPORT: ':api/accountant/packages/delayed-cod',
    GET_RECEIVED_COD_WITHOUT_CUSTODY: ':api/admin/reports/cod/without-custody',
    GET_RECEIVED_PACKAGES_WITHOUT_CUSTODY: ':api/accountant/packages/received-without-custody',
    GET_EXCEL_RECEIVED_PACKAGES_WITHOUT_CUSTODY: ':api/accountant/packages/received-without-custody/excel',
    GET_DRIVERS_EARNINGS_REPORT: ':api/accountant/drivers-earning/report',
    GET_DRIVERS_EARNINGS_REPORT_MISMATCH: ':api/accountant/drivers-earning/received/report',
    ROUTES: {
        DRIVER_PACKAGES: 'home/driver-packages'
    },
    EXPORT_MASS_PACKAGE: ':api/accountant/mass-packages/export',
    CHANGE_STATUS_TO_EXPORTED: ':api/accountant/mass-packages/change-status',
    DELIVER_MASS_PACKAGE: ':api/accountant/mass-packages/{massPackageId}/deliver',
    MOB: ':api/accountant/mob',
    GET_TRANSFER_COD_PACKAGES: ':api/accountant/bank-transfers/{​​bankTransferId}/cod-packages',
    AUTO_APPROVE_CUSTOMER: ':api/admin/customers/{customerId}/auto-approve',
    GET_PARTNERS: ':api/admin/companies/partners',
    GET_ENTITIES_FOR_COD: ':api/accountant/carried-cod/{entityType}',
    DELIVER_MASS_PACKAGES: ':api/accountant/mass-packages/deliver',
    EXPORT_COD_PDF: ':api/admin/customers/cod/pdf',
    DOWNLOAD_EXCEL_PACKAGE_FOR_CUSTOMER: ':api/admin/customers/{customerId}/import-excel-template',
    DELIVER_SELECTED: ':api/accountant/mass-packages/{massPackageId}/partial-deliver',
    DELIVER_PARTIAL: ':api/accountant/mass-packages/{massPackageId}/partial-export',
    UPLOAD_ATTACHMENT: ':api/admin/customers/{customerId}/attachment',
    UPLOAD_IMAGE_URL: ':api/admin/users/upload-image-url',
    CUSTOMER_CONFIGURATIONS: ':api/admin/customers/{customerId}/configurations',
    ADD_CUSTOMERS_PRICING_LIST_ID: ':api/admin/customers/{pricingListId}',
    ADD_CUSTOMERS_CUSTOMER_ACCOUNT_MANAGER_ID: ':api/admin/customers/customer-account-manager/{customerAccountManagerId}',
    ADD_CUSTOMERS_CUSTOMER_ADDITION_FEE: ':api/accountant/pricing/additional-package-fees/{feeId}/customers',
    MODIFY_PRICING_LIST_ID: ':api/admin/customers/{customerId}/pricing-list',
    MODIFY_CUSTOMER_ACCOUNT_ID: ':api/admin/customers/{customerId}/customer-account-manager',
    MODIFY_CUSTOMER_ADDITION_FEE: ':api/accountant/pricing/additional-package-fees/{feeId}/customers/{customerId}',
    CREATE_INVOICE: ':api/accountant/mass-packages/invoice/create',
    SENDER_ADDRESS_BOOK: ':api/admin/ship/address-books',
    GET_ADDRESS_BOOK: ':api/admin/ship/address-books',
    UPLOAD_DELIVER_COD_ATTACHMENTS: ':api/accountant/mass-packages/{massCodPackageId}/upload',
    EXPORT_COD_EXCEL: ':api/admin/customers/cod/excel',
    EXPORT_CUSTOMERS_PDF: ':api/admin/customers/pdf',
    EXPORT_CUSTOMERS_EXCEL: ':api/admin/customers/excel',
    EXPORT_USERS_PDF: ':api/admin/users/pdf',
    EXPORT_USERS_EXCEL: ':api/admin/users/excel',
    GET_HUBS_MASS_COD_REPORT: ':api/accountant/hub-mass-reports',
    GET_HUBS_RECEIVED_COD: ':api/accountant/hubs/exported/received-cod',
    EXPORT_HUB_COD: ':api/accountant/hub-mass-packages/{fromHubId}/export',
    DELIVER_HUB_COD: ':api/accountant/hub-mass-packages/{hubMassPackageId}/deliver',
    PRINT_HUB_COD: ':api/accountant/hub-mass-reports/{hubMassCodReportId}/print',
    RECEIVE_COD_FROM_HUB: ':api/accountant/cod/exported-to-hub/hubs/{nextHubId}/receive',
    GET_USERS_FOR_CATEGORY: ':api/ticketing/category/users/all',
    GET_MASS_INVOICES_PACKAGES: `:api/accountant/mass-invoices/{id}/mass-reports`,
    GET_USER_HUBS: `:api/admin/users/multiple-hubs-manager/{userId}/hubs`,
    UPDATE_USER_HUBS: `:api/admin/users/multiple-hubs-manager/{userId}/hubs`,
};
