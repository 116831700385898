import {Component, OnInit} from '@angular/core';
import {PackageModel} from '../shared/models/package.model';
import {LclItemModel} from '../add-package-form/add-lcl-items/lcl-item.model';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../shared/services/user.service';
import {ShipmentsService} from '../shipment/services/shipments.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HubModel} from '../shared/models/hub.model';
import {createQueryParams} from '../shared/services/helpers';
import {HubsService} from '../shared/services/hubs.service';

@Component({
    selector: 'app-unload-container',
    templateUrl: './unload-container.component.html',
    styleUrls: ['./unload-container.component.scss']
})
export class UnloadContainerComponent implements OnInit {
    currentLang: string;
    isReading = false;
    isLoading = false;


    selectedPackage: PackageModel;

    Object = Object;
    items: { [barcode: number]: LclItemModel } = {};
    totalItemsNo = 0;
    totalScannedItems = 0;
    inputBarcode: string;
    containerBarcode: string;

    isCreateContainer = false;
    packages: { [barcode: string]: { pkg: PackageModel, scannedItems: number } } = {};
    filteredPackages: any[] = [];
    barcodeSearch: string;
    isReceivingPkgsWithoutScanItems = false;
    packagesList: any[] = [];
    containerId: number;
    isReceiveContainer = false;

    hubs: HubModel[] = [];
    selectedHub: HubModel;
    isLoadingHubs = false;

    filterParams = {
        HUBS: {
            page: 1,
            pageSize: 20,
            search: ''
        }
    };
    isNaN: any = isNaN;

    constructor(private translateService: TranslateService,
                private shipmentService: ShipmentsService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private userService: UserService,
                private modalService: NgbModal,
                private hubsService: HubsService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isReceivingPkgsWithoutScanItems = this.userService.userInfo.isReceivingPkgsWithoutScanItems;
    }

    public getContainerSubBundles(containerBarcode) {
        this.isLoading = true;
        this.shipmentService.getContainerSubBundles(containerBarcode).subscribe(
            (res: any) => {
                this.reset();
                this.containerId = res.id;
                this.packagesList = res.subBundles;
                if (!this.packagesList.length) {
                    this.translateService.get('LCL.ALERTS.NO_PACKAGES_FOUND').subscribe(value => {
                        this.messageService.add({
                            severity: 'warn',
                            detail: value
                        });
                    });
                }
                this.filteredPackages = this.packagesList;
                this.packagesList.forEach((pkg) => {
                    this.totalItemsNo += pkg.packageItems ? pkg.packageItems.length : 0;
                });
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    closeModal() {
        this.translateService.get(['GENERAL.YES', 'GENERAL.NO', 'ACTIONS.CONFIRM_CANCEL']).subscribe(values => {
            this.confirmationService.confirm({
                message: values['ACTIONS.CONFIRM_CANCEL'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.activeModal.close();
                }
            });
        });
    }

    readBarcode(barcode: string) {
        if (this.isReading) {
            return;
        }
        this.isReading = true;
        this.readItemBarcode(barcode);
    }

    readItemBarcode(barcode: string) {
        if (this.items[barcode]) {
            if (this.items[barcode].scanned) {
                this.translateService.get('LCL.ALERTS.ITEM_ALREADY_SCANNED').subscribe(value => {
                    this.messageService.add({
                        severity: 'success',
                        detail: value
                    });
                });
            } else {
                this.items[barcode].scanned = true;
                this.totalScannedItems++;
            }
        } else {
            this.translateService.get('LCL.ALERTS.ITEM_NOT_FOUND').subscribe(value => {
                this.messageService.add({
                    severity: 'error',
                    detail: value
                });
            });
        }
        this.isReading = false;
        this.inputBarcode = '';
    }

    onSubmit() {
        if (!this.containerId) {
            return;
        }
        const message = this.isReceiveContainer ? 'LCL.ALERTS.RECEIVE_CONTAINER_CONFIRMATION' : 'LCL.ALERTS.UNLOAD_CONTAINER_CONFIRMATION';
        this.confirmationService.confirm({
            message: this.translateService.instant(message),
            acceptLabel: this.translateService.instant('GENERAL.YES'),
            rejectLabel: this.translateService.instant('GENERAL.NO'),
            accept: () => {
                this.changeContainerStatus();
            }
        });
    }

    changeContainerStatus() {
        this.isLoading = true;
        const status = this.isReceiveContainer ? 'SCANNED_BY_HANDLER_AND_UNLOADED' : 'CONTAINER_UNLOADED_AND_CLEARED_OUT';
        this.shipmentService.changePackageStatus({status, ids: [this.containerId], hubId: this.selectedHub.id}).subscribe(
            () => {
                const successMessage = this.isReceiveContainer
                    ? 'LCL.ALERTS.CONTAINER_RECEIVED_SUCCESSFULLY'
                    : 'LCL.ALERTS.CONTAINER_UNLOADED_SUCCESSFULLY';
                this.messageService.add({
                    severity: 'success',
                    detail: this.translateService.instant(successMessage)
                });
                this.isLoading = false;
                this.shipmentService.shipmentAdded(true);
                this.reset();
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    reset() {
        this.containerId = null;
        this.packagesList = [];
        this.filteredPackages = [];
        this.items = {};
        this.selectedPackage = null;
        this.containerBarcode = '';
        this.inputBarcode = '';
        this.barcodeSearch = '';
        this.totalItemsNo = 0;
        this.totalScannedItems = 0;
    }


    selectPackage(pkg: PackageModel) {
        this.selectedPackage = pkg;
        this.items = {};
        if (this.selectedPackage.packageItems) {
            this.selectedPackage.packageItems.forEach((packageItem) => {
                this.items[packageItem.barcode] = packageItem;
            });
        }
    }

    filterPackages() {
        if (this.barcodeSearch) {
            this.filteredPackages = Object.values(this.packagesList)
                .filter(pkg => pkg.barcode.startsWith(this.barcodeSearch));
        } else {
            this.filteredPackages = Object.values(this.packagesList);
        }
    }

    searchHub(search) {
        this.filterParams.HUBS.search = search;
        this.getHubs(true);
    }

    getHubs(forceFetch = false) {
        if (!forceFetch && this.hubs.length) {
            return;
        }
        this.isLoadingHubs = true;
        this.hubsService.getHubs(createQueryParams(this.filterParams['HUBS'])).subscribe(
            (hubsResponse: any) => {
                this.hubs = hubsResponse['hubs'];
                this.isLoadingHubs = false;
            }, () => {
                this.isLoadingHubs = false;
            }
        );
    }
}
