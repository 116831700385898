<div class="customer-weight-container d-flex flex-column">
    <div class="title">{{('MANAGE_SHIPMENTS.WEIGHT_DIALOG.WEIGHT_TITLE') | translate}}</div>
    <form [formGroup]="form" *ngIf="form" (keyup.enter)="finish()">
        <div class="customer-weight-contents d-flex flex-column">
            <div class="input-item-container d-flex">
                <div class="label">{{('MANAGE_SHIPMENTS.WEIGHT_DIALOG.FROM') | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form" controlName="weightFrom"
                                               controlType="text" *ngIf="isWeight">
                    </app-input-field-component >
                </div>
            </div>

            <div class="input-item-container d-flex">
                <div class="label">{{('MANAGE_SHIPMENTS.WEIGHT_DIALOG.TO') | translate}}</div>
                <div class="value">
                    <app-input-field-component [form]="form" controlName="weightTo"
                                               controlType="text" *ngIf="isWeight">
                    </app-input-field-component >
                </div>
            </div>


            <div class="bottom-actions d-flex justify-content-end w-100">
                <div (click)="onClose()" class="cancel-btn d-inline-flex justify-content-center">
                    <span>{{'MANAGE_SHIPMENTS.WEIGHT_DIALOG.CANCEL' | translate}}</span>
                </div>
                <div style="width: 8px"></div>
                <div class="done-btn d-inline-flex justify-content-center"
                     [ngClass]="{'disabled-button disabled': !form.valid}"
                     (click)="finish()">
                    <span>{{'MANAGE_SHIPMENTS.WEIGHT_DIALOG.DONE' | translate}}</span>
                </div>
            </div>
        </div>
    </form>
</div>
