<div class="administration-reports-container">
    <div class="administration-report" *ngIf="report==='time-period-packages-administrative-report'">
        <app-admin-report [columns]="timePeriodReports" expandLink="time-period-packages-administrative-report"
                          [apiUrl]="timePeriodReportsAPI"
                          reportTitle="ADMINISTRATION.TIME_PERIOD_PACKAGES_ADMINISTRATIVE_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="administration-report" *ngIf="report==='customer-activity-administrative-reports'">
        <app-admin-report [columns]="customersActivityReport" expandLink="customer-activity-administrative-report"
                          [apiUrl]="customersActivityReportAPI"
                          reportTitle="ADMINISTRATION.CUSTOMER_ACTIVITY_ADMINISTRATIVE_REPORT_TITLE"></app-admin-report>
    </div>
    <div class="administration-report" *ngIf="report==='activity-administrative-reports'">
        <app-admin-report [columns]="activityReport" expandLink="activity-administrative-report"
                          [apiUrl]="activityReportAPI" [dataInterceptor]="addTotal"
                          [isPdfRemove]="false"
                          reportTitle="ADMINISTRATION.ACTIVITY_ADMINISTRATIVE_REPORT_TITLE"></app-admin-report>
    </div>
</div>
