import {Component, ViewChild, OnInit} from '@angular/core';
import {SpinnerState} from '../../shared/behavior/spinner-state.enum';
import {CompaniesService} from '../services/companies.service';
import {DatePipe} from '@angular/common';
import {NewCompanyComponent} from '../new-company/new-company.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {UserService} from '../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from '../../shared/services/shared-service';

@Component({
    selector: 'app-manage-companies-component',
    templateUrl: 'manage-companies.component.html',
    styleUrls: ['manage-companies.component.scss']
})
export class ManageCompaniesComponent implements OnInit {
    @ViewChild('table', {static: false}) table;
    public spinnerState = SpinnerState.LOADING;
    public spinnerStates = SpinnerState;
    public companies;
    public page = 1;
    public totalRecords = 0;
    public totalPlacedPackagesNo = 0;
    public activeCompaniesNumber = 0;
    public pageSize = 100;
    public searchContent = '';
    public fromDate;
    public toDate;
    public isPending;
    public showRejected;
    public isDisabled = false;
    public selectedCurrencyFilter: string;
    public currenciesList;

    constructor(private companiesService: CompaniesService,
                public datePipe: DatePipe,
                private translateService: TranslateService,
                private sharedService: SharedService,
                public modalService: NgbModal,
                public messageService: MessageService,
                private route: ActivatedRoute,
                private authenticationService: AuthenticationService,
                private userService: UserService) {

        this.isPending = this.route.snapshot.data['isPending'];
        this.page = 1;
    }

    ngOnInit() {
        this.initDates();
        this.setCurrenciesList();
    }

    public setCurrenciesList() {
        const currenciesList = this.sharedService.getCurrenciesList();
        this.translateService.get(currenciesList).subscribe(currencies => {
            this.currenciesList = currenciesList.map(currency => {
                return {
                    label: `${currencies[currency]}`,
                    value: currency
                };
            });
        });
    }
    private initDates() {
        this.toDate = new Date();
        this.fromDate = new Date();
    }

    initShipments() {
        this.spinnerState = SpinnerState.LOADING;
        const parm = this.createQuery();
        this.companiesService.getCompanies(parm).subscribe(
            (response: { data: any[], totalRecordsNo: number, totalPlacedPackagesNo: number, activeCompaniesNumber: number }) => {
                this.companies = response.data;
                this.totalRecords = response.totalRecordsNo;
                this.totalPlacedPackagesNo = response.totalPlacedPackagesNo;
                this.activeCompaniesNumber = response.activeCompaniesNumber;
                this.companies.map(company => {
                    company.link = `../../../${(company.companyNameInUrl || '').toLowerCase().replace(/ /g, '-')}/home/dashboard`;
                });
                if (this.totalRecords > 0) {
                    this.spinnerState = SpinnerState.LOADED;
                } else {
                    this.spinnerState = SpinnerState.EMPTY;
                }
            }, error => {
                this.spinnerState = SpinnerState.LOADED;
            }
        );
    }

    public resetDate() {
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        this.fromDate = null;
        this.toDate = null;
        this.initShipments();
    }

    public createQuery() {
        const {pageSize, page} = this;
        const parm = <any>{pageSize, page};

        if (this.searchContent !== '') {
            parm.search = this.searchContent;
        }
        if (this.fromDate) {
            parm.fromDate = this.transformDate(this.fromDate);
        }
        if (this.toDate) {
            parm.toDate = this.transformDate(this.toDate);
        }

        if (this.fromDate || this.toDate) {
            parm['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        if (this.selectedCurrencyFilter && this.selectedCurrencyFilter !== 'ALL') {
            parm.currency = this.selectedCurrencyFilter;
        }
        if (this.isPending) {
            if (this.showRejected) {
                parm.status = 'PENDING,REJECTED';
            } else {
                parm.status = 'PENDING';
            }
        } else {
            parm.status = this.isDisabled ? 'ENABLED,DISABLED' : 'ENABLED';
        }
        return parm;
    }

    public loadPackagesLazy($event) {
        this.page = $event.first / $event.rows + 1;
        this.pageSize = $event.rows;
        this.initShipments();
    }

    private transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    public onSearch() {
        this.page = 1;
        this.table.reset();
    }

    public addNewCompany() {
        const modal = this.modalService.open(NewCompanyComponent, {
            backdrop: 'static',
            windowClass: 'flex-modal',
            size: 'lg'
        });
        modal.componentInstance.isModal = true;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                if (status.isSuccess) {
                    this.messageService.add({severity: 'success', detail: 'Container Updated Successfully'});
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    discardCompany({id}) {
        this.companiesService.changeCompanyStatus(id, 'REJECTED').subscribe(data => {
            this.onSearch();
        });
    }

    approveCompany({id}) {
        this.companiesService.changeCompanyStatus(id, 'ENABLED').subscribe(data => {
            this.onSearch();
        });
    }

    enableCompany(isEnable, {id}) {
        const status = isEnable ? 'ENABLED' : 'DISABLED';
        this.companiesService.changeCompanyStatus(id, status).subscribe(data => {

        });
    }

    editCompany(company) {
        if (!company.address) {
            company.address = {};
        }
        const {
            address: {cityId, regionId, villageId, country, addressLine1}, name, email, phone, id, logo,
            isGuestAddPackage, backgroundImage, favIcon, domain
        } = company;
        let {isUseBankTransfer} = company;
        if (isUseBankTransfer === undefined) {
            isUseBankTransfer = false;
        }
        let {isUseStickerPrinterForAwb} = company;
        if (isUseStickerPrinterForAwb === undefined) {
            isUseStickerPrinterForAwb = false;
        }
        let {isAllowOperationManagerReceiveCod} = company;
        if (isAllowOperationManagerReceiveCod === undefined) {
            isAllowOperationManagerReceiveCod = false;
        }
        let {isAllowDispatcherReceiveCod} = company;
        if (isAllowDispatcherReceiveCod === undefined) {
            isAllowDispatcherReceiveCod = false;
        }
        let {isPrintTwoCopiesForSwap} = company;
        if (isPrintTwoCopiesForSwap === undefined) {
            isPrintTwoCopiesForSwap = false;
        }
        let {isSupportParcelTracking} = company;
        if (isSupportParcelTracking === undefined) {
            isSupportParcelTracking = false;
        }
        let {isPartialDeliveryEnabled} = company;
        if (isPartialDeliveryEnabled === undefined) {
            isPartialDeliveryEnabled = false;
        }
        let {isHideDeliveredToSenderInChangeStatus} = company;
        if (isHideDeliveredToSenderInChangeStatus === undefined) {
            isHideDeliveredToSenderInChangeStatus = false;
        }
        let {isSupportReceiveWithoutReleasingCustody} = company;
        if (isSupportReceiveWithoutReleasingCustody === undefined) {
            isSupportReceiveWithoutReleasingCustody = false;
        }
        let {isShowPhoneInPackageReport} = company;
        if (isShowPhoneInPackageReport === undefined) {
            isShowPhoneInPackageReport = false;
        }
        let {webhookUrl} = company;
        if (webhookUrl === undefined) {
            webhookUrl = '';
        }
        let {companyNameInUrl} = company;
        if (companyNameInUrl === undefined) {
            companyNameInUrl = '';
        }
        let {isDriverPickupUnassignedPackage} = company;
        if (isDriverPickupUnassignedPackage === undefined) {
            isDriverPickupUnassignedPackage = false;
        }
        let {isAdminOnlyChangeFromDelivered} = company;
        if (isAdminOnlyChangeFromDelivered === undefined) {
            isAdminOnlyChangeFromDelivered = false;
        }
        let {isShowDriverNameInPackageTracking} = company;
        if (isShowDriverNameInPackageTracking === undefined) {
            isShowDriverNameInPackageTracking = false;
        }
        let {isHubManagerAddPackage} = company;
        if (isHubManagerAddPackage === undefined) {
            isHubManagerAddPackage = false;
        }
        let {isNonAdminChangeCost} = company;
        if (isNonAdminChangeCost === undefined) {
            isNonAdminChangeCost = false;
        }
        let {isShowBarcodeImageInMassReport} = company;
        if (isShowBarcodeImageInMassReport === undefined) {
            isShowBarcodeImageInMassReport = false;
        }
        let {isChangeReceivedOrSortedPackageStatus} = company;
        if (isChangeReceivedOrSortedPackageStatus === undefined) {
            isChangeReceivedOrSortedPackageStatus = false;
        }
        let {isPrintCashReceiptWithMassReport} = company;
        if (isPrintCashReceiptWithMassReport === undefined) {
            isPrintCashReceiptWithMassReport = false;
        }
        let {isHideCostFromRecieveCodReport} = company;
        if (isHideCostFromRecieveCodReport === undefined) {
            isHideCostFromRecieveCodReport = false;
        }
        let {isPrintMassCodReportOnceByAccountant} = company;
        if (isPrintMassCodReportOnceByAccountant === undefined) {
            isPrintMassCodReportOnceByAccountant = false;
        }
        let {isDriverCanRequestCodChange} = company;
        if (isDriverCanRequestCodChange === undefined) {
            isDriverCanRequestCodChange = false;
        }
        let {isDriverCanReturnPackage} = company;
        if (isDriverCanReturnPackage === undefined) {
            isDriverCanReturnPackage = false;
        }
        let {isBundlePodEnabled} = company;
        if (isBundlePodEnabled === undefined) {
            isBundlePodEnabled = false;
        }
        let {isAdminChangeCodForReturnedAndDelivered} = company;
        if (isAdminChangeCodForReturnedAndDelivered === undefined) {
            isAdminChangeCodForReturnedAndDelivered = false;
        }
        let {isPrintProofReportWithDriverRunsheet} = company;
        if (isPrintProofReportWithDriverRunsheet === undefined) {
            isPrintProofReportWithDriverRunsheet = false;
        }
        let {isHidePartnerSender} = company;
        if (isHidePartnerSender === undefined) {
            isHidePartnerSender = false;
        }
        let {isAllowOperationManagerAddCustomer} = company;
        if (isAllowOperationManagerAddCustomer === undefined) {
            isAllowOperationManagerAddCustomer = false;
        }
        let {isAddCustomerFromQuickAddShipmentForm} = company;
        if (isAddCustomerFromQuickAddShipmentForm === undefined) {
            isAddCustomerFromQuickAddShipmentForm = false;
        }
        let {isDeliverPartialMassReport} = company;
        if (isDeliverPartialMassReport === undefined) {
            isDeliverPartialMassReport = false;
        }
        let {isAllowBilling} = company;
        if (isAllowBilling === undefined) {
            isAllowBilling = false;
        }
        let {isBlockBilling} = company;
        if (isBlockBilling === undefined) {
            isBlockBilling = false;
        }
        let {isAllowPartnerChangeCod} = company;
        if (isAllowPartnerChangeCod === undefined) {
            isAllowPartnerChangeCod = false;
        }
        let {isPickupSupported} = company;
        if (isPickupSupported === undefined) {
            isPickupSupported = false;
        }
        let {isSeperateReadyCodToDeliverandCodAtHand} = company;
        if (isSeperateReadyCodToDeliverandCodAtHand === undefined) {
            isSeperateReadyCodToDeliverandCodAtHand = false;
        }
        let {isChangeExportedPackages} = company;
        if (isChangeExportedPackages === undefined) {
            isChangeExportedPackages = false;
        }
        let {addressLanguageInPackagePdf} = company;
        if (addressLanguageInPackagePdf === undefined) {
            addressLanguageInPackagePdf = false;
        } else {
            if (addressLanguageInPackagePdf === 'ARABIC') {
                addressLanguageInPackagePdf = true;
            } else {
                addressLanguageInPackagePdf = false;
            }
        }
        let {isExcludeSort} = company;
        if (isExcludeSort === undefined) {
            isExcludeSort = false;
        }
        let {registrationNumber} = company;
        this.authenticationService.companyId = id;
        if (registrationNumber === undefined) {
            registrationNumber = null;
        }
        let {currency} = company;
        if (currency === undefined) {
            currency = null;
        }
        let {packagePdfTemplate} = company;
        if (packagePdfTemplate === undefined) {
            packagePdfTemplate = null;
        }
        let {isShowAddShipmentInSideMenu} = company;
        if (isShowAddShipmentInSideMenu === undefined) {
            isShowAddShipmentInSideMenu = false;
        }
        let {isHideDeliveredMassCodReports} = company;
        if (isHideDeliveredMassCodReports === undefined) {
            isHideDeliveredMassCodReports = false;
        }
        let {isDriverEarningEnabled} = company;
        if (isDriverEarningEnabled === undefined) {
            isDriverEarningEnabled = false;
        }
        let {isFulfilmentEnabled} = company;
        if (isFulfilmentEnabled === undefined) {
            isFulfilmentEnabled = false;
        }
        let {isReceivingPointEnabled} = company;
        if (isReceivingPointEnabled === undefined) {
            isReceivingPointEnabled = false;
        }
        let {isCorporate} = company;
        if (isCorporate === undefined) {
            isCorporate = false;
        }
        let {isTrucking} = company;
        if (isTrucking === undefined) {
            isTrucking = false;
        }
        let {isDistributor} = company;
        if (isDistributor === undefined) {
            isDistributor = false;
        }
        let {isShowDriverLocationInPackageTracking} = company;
        if (isShowDriverLocationInPackageTracking === undefined) {
            isShowDriverLocationInPackageTracking = false;
        }
        let {hasRouteOptimization} = company;
        if (hasRouteOptimization === undefined) {
            hasRouteOptimization = false;
        }
        let {isLcl} = company;
        if (isLcl === undefined) {
            isLcl = false;
        }
        let {isPickupUnListedPackagesByAdmin} = company;
        if (isPickupUnListedPackagesByAdmin === undefined) {
            isPickupUnListedPackagesByAdmin = false;
        }
        let {isPackageFingerprintRequired} = company;
        if (isPackageFingerprintRequired === undefined) {
            isPackageFingerprintRequired = false;
        }
        let {isShowFollowUps} = company;
        if (isShowFollowUps === undefined) {
            isShowFollowUps = false;
        }
        let {isShowCurrentReports} = company;
        if (isShowCurrentReports === undefined) {
            isShowCurrentReports = false;
        }
        let {isShowMassCodReports} = company;
        if (isShowMassCodReports === undefined) {
            isShowMassCodReports = false;
        }
        let {isDisableCostInAddPackage} = company;
        if (isDisableCostInAddPackage === undefined) {
            isDisableCostInAddPackage = false;
        }
        let {isDriverPickupAcceptedPackages} = company;
        if (isDriverPickupAcceptedPackages === undefined) {
            isDriverPickupAcceptedPackages = false;
        }
        let {isShowDelayedCountInCustomerDashboard} = company;
        if (isShowDelayedCountInCustomerDashboard === undefined) {
            isShowDelayedCountInCustomerDashboard = false;
        }
        let {isPromptNoteForDriverInPackageDelivery} = company;
        if (isPromptNoteForDriverInPackageDelivery === undefined) {
            isPromptNoteForDriverInPackageDelivery = false;
        }
        let {isForceAddingInvoiceNumberWhenAddingPackages} = company;
        if (isForceAddingInvoiceNumberWhenAddingPackages === undefined) {
            isForceAddingInvoiceNumberWhenAddingPackages = false;
        }
        let {isAllowAddingTelecomeDevices} = company;
        if (isAllowAddingTelecomeDevices === undefined) {
            isAllowAddingTelecomeDevices = false;
        }
        let {isReceivingPkgsWithoutScanItems} = company;
        if (isReceivingPkgsWithoutScanItems === undefined) {
            isReceivingPkgsWithoutScanItems = false;
        }
        let {isAutoAssignToShippingLineDriver} = company;
        if (isAutoAssignToShippingLineDriver === undefined) {
            isAutoAssignToShippingLineDriver = false;
        }
        let {isTicketingSystemEnabled} = company;
        if (isTicketingSystemEnabled === undefined) {
            isTicketingSystemEnabled = false;
        }
        let {isInvoicingEnabled} = company;
        if (isInvoicingEnabled === undefined) {
            isInvoicingEnabled = false;
        }
        let {isAllowAddingCustomerSmsGateway} = company;
        if (isAllowAddingCustomerSmsGateway === undefined) {
            isAllowAddingCustomerSmsGateway = false;
        }
        let {isShowPaymentTypesWhenDriverDeliver} = company;
        if (isShowPaymentTypesWhenDriverDeliver === undefined) {
            isShowPaymentTypesWhenDriverDeliver = false;
        }
        let {isAutoApproveCustomersAfterSignup} = company;
        if (isAutoApproveCustomersAfterSignup === undefined) {
            isAutoApproveCustomersAfterSignup = false;
        }
        let {isShowCustomersToHubManager} = company;
        if (isShowCustomersToHubManager === undefined) {
            isShowCustomersToHubManager = false;
        }
        let {isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD} = company;
        if (isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD === undefined) {
            isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD = false;
        }
        let {isEnableCustomersNotifyAdminsToDeliverCod} = company;
        if (isEnableCustomersNotifyAdminsToDeliverCod === undefined) {
            isEnableCustomersNotifyAdminsToDeliverCod = false;
        }
        let {isShowDetailedPackageHistoryForALLCustomers} = company;
        if (isShowDetailedPackageHistoryForALLCustomers === undefined) {
            isShowDetailedPackageHistoryForALLCustomers = false;
        }
        let {isForceDriversAttachDraftPackage} = company;
        if (isForceDriversAttachDraftPackage === undefined) {
            isForceDriversAttachDraftPackage = false;
        }
        let {ads} = company;
        if (ads === undefined) {
            ads = false;
        }
        const modal = this.modalService.open(NewCompanyComponent, {
            backdrop: 'static',
            windowClass: 'flex-modal',
            size: 'lg'
        });
        modal.componentInstance.isModal = true;
        modal.componentInstance.companyInfo = company;
        modal.componentInstance.isEdit = true;
        modal.componentInstance.form.patchValue({
            company: {
                address: {cityId, regionId, villageId, country, addressLine1},
                name,
                email,
                phone,
                registrationNumber,
                currency,
                packagePdfTemplate,
                isGuestAddPackage,
                domain,
                ads,
                webhookUrl,
                companyNameInUrl,
                isUseBankTransfer,
                isShowPhoneInPackageReport,
                isUseStickerPrinterForAwb,
                isDriverPickupUnassignedPackage,
                isAdminOnlyChangeFromDelivered,
                isShowDriverNameInPackageTracking,
                isChangeReceivedOrSortedPackageStatus,
                isPrintCashReceiptWithMassReport,
                isHideCostFromRecieveCodReport,
                isPrintMassCodReportOnceByAccountant,
                isAdminChangeCodForReturnedAndDelivered,
                isDriverCanRequestCodChange,
                isDriverCanReturnPackage,
                isBundlePodEnabled,
                isPrintProofReportWithDriverRunsheet,
                isHidePartnerSender,
                isAllowOperationManagerAddCustomer,
                isAddCustomerFromQuickAddShipmentForm,
                isAllowPartnerChangeCod,
                isHubManagerAddPackage,
                isNonAdminChangeCost,
                isExcludeSort,
                addressLanguageInPackagePdf,
                isShowBarcodeImageInMassReport,
                isPickupSupported,
                isSeperateReadyCodToDeliverandCodAtHand,
                isChangeExportedPackages,
                isDeliverPartialMassReport,
                isAllowBilling,
                isBlockBilling,
                isAllowOperationManagerReceiveCod,
                isAllowDispatcherReceiveCod,
                isPrintTwoCopiesForSwap,
                isSupportParcelTracking,
                isPartialDeliveryEnabled,
                isHideDeliveredToSenderInChangeStatus,
                isSupportReceiveWithoutReleasingCustody,
                isHideDeliveredMassCodReports,
                isDriverEarningEnabled,
                isFulfilmentEnabled,
                isShowAddShipmentInSideMenu,
                isReceivingPointEnabled,
                isCorporate,
                isTrucking,
                isDistributor,
                isShowDriverLocationInPackageTracking,
                isLcl,
                isShowFollowUps,
                isShowCurrentReports,
                isShowMassCodReports,
                isDisableCostInAddPackage,
                hasRouteOptimization,
                isPickupUnListedPackagesByAdmin,
                isPackageFingerprintRequired,
                isDriverPickupAcceptedPackages,
                isTicketingSystemEnabled,
                isInvoicingEnabled,
                isShowDelayedCountInCustomerDashboard,
                isPromptNoteForDriverInPackageDelivery,
                isForceAddingInvoiceNumberWhenAddingPackages,
                isAllowAddingTelecomeDevices,
                isReceivingPkgsWithoutScanItems,
                isAutoAssignToShippingLineDriver,
                isAllowAddingCustomerSmsGateway,
                isShowPaymentTypesWhenDriverDeliver,
                isAutoApproveCustomersAfterSignup,
                isShowDetailedPackageHistoryForALLCustomers,
                isShowCustomersToHubManager,
                isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD,
                isForceDriversAttachDraftPackage,
                isEnableCustomersNotifyAdminsToDeliverCod
            }
        });
        modal.componentInstance.companyId = id;
        modal.result.then(
            (status: { isSuccess: boolean }) => {
                if (status.isSuccess) {
                    this.onSearch();
                    this.messageService.add({severity: 'success', detail: 'Container Updated Successfully'});
                }
            }
        ).catch(
            (error) => {
            }
        );
    }
    public exportCompanies() {
        this.spinnerState = SpinnerState.LOADING;
        const parm = this.createQuery();
        this.companiesService.exportCompanies(parm).subscribe(
            (response: any) => {
                window.open(response.url, '_blank');
                this.spinnerState = SpinnerState.EMPTY;
            }
        );
    }
    public isMonitor() {
        return this.userService.userInfo.role === 'MONITOR';
    }
}
