<p-toast></p-toast>
<div class="categories-management-container" [ngClass]="{'page-ltr': selectedLang === 'en'}">
    <div class="header-content d-flex">
        <div class="title-container d-flex">
            <div class="title-label">
                <span>{{'TICKETING_SYSTEM.CATEGORIES.CATEGORIES_MANAGEMENT_TITLE' | translate}}</span>
            </div>
            <div class="total-records-count flex-2d-center">
                <span *ngIf="categories.length !== -1">{{categories.length}}</span>
                <span *ngIf="categories.length === -1">-</span>
            </div>
        </div>
        <div class="action-and-search-filter-container d-flex">
            <div class="add-category-action d-flex cursor-pointer" (click)="openCategoryForm()">
                <div class="action-icon flex-2d-center">
                    <img src="../../assets/icons/ticketing-system/categories-management/add-category.png" alt=""/>
                </div>
                <div class="action-label flex-2d-center">
                    <span> {{'TICKETING_SYSTEM.CATEGORIES.ADD_CATEGORY_GROUP' | translate}}</span>
                </div>
            </div>
            <div class="d-flex">
                <div *ngIf="activeSearch" class="search-filter-input">
                    <input type="text"
                           placeholder="{{'GENERAL.SEARCH' | translate}}..."
                           [(ngModel)]="searchContent"
                           appInputDebounced
                           (inputDebounced)="getCategories()"/>
                </div>
                <div class="search-filter-icon flex-2d-center cursor-pointer" (click)="activeSearch = !activeSearch">
                    <img src="../../assets/icons/ticketing-system/categories-management/search-icon.svg" alt=""/>
                </div>
            </div>
        </div>
    </div>

    <div class="main-container">
        <div class="categories-table">
            <div class="categories-body overflow-auto flex-1">
                <p-table [value]="categories" [responsive]="true" scrollHeight="auto"
                         styleClass="categories-table data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="select-category-checkbox checkbox-input">
<!--                                <p-checkbox></p-checkbox>-->
                            </th>
                            <th class="category-name-th">{{'TICKETING_SYSTEM.CATEGORIES.TABLE.CATEGORY_NAME' | translate}}</th>
                            <th class="description-th">{{'TICKETING_SYSTEM.CATEGORIES.TABLE.DESCRIPTION' | translate}}</th>
                            <th class="client-name-th">{{'TICKETING_SYSTEM.CATEGORIES.TABLE.CLIENT_COUNT' | translate}}</th>
                            <th class="tickets-count-th">{{'TICKETING_SYSTEM.CATEGORIES.TABLE.TICKETS_COUNT' | translate}}</th>
                            <th></th>
                            <th class="categories-actions-th"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-category>
                        <tr class="category-row">
                            <td class="select-category-checkbox checkbox-input">
                                <div class="checkbox-container hidden-sm-down">
<!--                                    <div class="flex-2d-center">-->
<!--                                        <p-checkbox></p-checkbox>-->
<!--                                    </div>-->
                                    <div class="group-icon-container flex-2d-center">
                                        <img src="../../assets/icons/ticketing-system/categories-management/group-icon.png"
                                             alt=""/>
                                    </div>
                                </div>
                            </td>
                            <td class="category-name-td value-font-style">{{category.name}}</td>
                            <td class="description-td">
                                <div class="description-container value-font-style">
                                    <!--                                    <p>لوريم إيبسوم هو ببساطة نص شكلي ويُستخدم في صناعات المطابع ودور النشر..</p>-->
                                    <p>{{category.description}}</p>
                                </div>
                            </td>
                            <td class="tickets-count-td">
                                <div class="count-number flex-2d-center">
                                    <div class="value-number flex-2d-center">
                                        <span>{{category.usersCount}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="tickets-count-td">
                                <div class="tickets-count-container flex-2d-center">
                                    <div class="count-number flex-2d-center">
                                        <div class="icon flex-2d-center">
                                            <img src="../../assets/icons/ticketing-system/ticket.png" alt=""/>
                                        </div>
                                        <div class="value-number flex-2d-center">
                                            <span>{{category.ticketsCount}}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td class="categories-actions-td">
                                <div class="categories-actions-container">
                                    <div class="categories-actions flex-2d-center">
                                        <div class="edit-action category-action" (click)="openCategoryForm(category)">
                                            <img src="../../assets/icons/ticketing-system/categories-management/edit.png"
                                                 alt=""/>
                                        </div>
                                        <div class="delete-action category-action"
                                             (click)="confirmDeleteCategory(category.id)">
                                            <img src="../../assets/icons/ticketing-system/categories-management/delete.png"
                                                 alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div *ngIf="isLoading">
                            <app-skeleton-loader *ngFor="let loaderLine of [0,1,2,3,4,5,6,7,8,9]"
                                                 [type]="2">
                            </app-skeleton-loader>
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
