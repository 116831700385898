import {Component, OnInit} from '@angular/core';
import {ACCOUNTING_CONSTANTS} from "../../accounting-constants";
import {TemplateTopActionsSettingsModel} from "../../models/template-top-actions-settings.model";
import {AccountingTemplateFiltersModel} from "../../models/accounting-template-filters.model";
@Component({
    selector: 'app-in-car-cod',
    templateUrl: './in-car-cod.component.html',
    styleUrls: ['./in-car-cod.component.scss']
})
export class InCarCodComponent {

    title = ACCOUNTING_CONSTANTS.IN_CAR_COD.TITLE;
    info = ACCOUNTING_CONSTANTS.IN_CAR_COD.INFO;
    apiUrl = ACCOUNTING_CONSTANTS.IN_CAR_COD.API;
    AccountingRowActionsSettings = ACCOUNTING_CONSTANTS.IN_CAR_COD.ROW_ACTIONS_SETTINGS;
    topActionsSettings: TemplateTopActionsSettingsModel = ACCOUNTING_CONSTANTS.IN_CAR_COD.TOP_ACTIONS_SETTINGS;
    filtersSettings: AccountingTemplateFiltersModel = ACCOUNTING_CONSTANTS.IN_CAR_COD.FILTERS_SETTINGS;
}
