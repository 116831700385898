import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubscriptionTrackerComponent} from '../../../shared/behavior/subscription-tracker.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FulfilmentService} from '../../../fulfilment/services/fulfilment.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {HubsService} from '../../../shared/services/hubs.service';
import {UsersService} from '../../../users/services/users.service';
import {MessageService} from 'primeng/api';
import {FULFILMENT_CONSTANTS} from '../../../fulfilment/services/constants';
import {HubModel} from '../../../shared/models/hub.model';
import {ShipmentsService} from '../../services/shipments.service';

@Component({
    selector: 'app-select-shelf-dialog',
    templateUrl: './select-shelf-dialog.component.html',
    styleUrls: ['./select-shelf-dialog.component.scss']
})
export class SelectShelfDialogComponent extends SubscriptionTrackerComponent implements OnInit, OnDestroy {
    form: FormGroup;
    currentLang = 'en';
    isLoading = false;
    rejectReasons = [];
    shippingPlaneId;
    barcode;
    selectedBin;
    selectedLocation;
    hubList = [];
    shelves = [];
    public shipments = [];

    constructor(private fulfilmentService: FulfilmentService,
                private activeModal: NgbActiveModal,
                private shipmentService: ShipmentsService,
                private translateService: TranslateService,
                private hubsService: HubsService,
                private usersService: UsersService,
                private messageService: MessageService,
                private formBuilder: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.initForm();
        this.getHubsList();
        // this.getShelfList();
        this.initLocationTypes();
    }

    private initLocationTypes() {
        this.rejectReasons = FULFILMENT_CONSTANTS.REJECT_ITEMS_REASON.map(l => {
            return {
                label: this.translateService.instant('FULFILMENT.ITEM.REJECTION_REASONS.' + l),
                value: l,
            };
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private initForm() {
        this.form = this.formBuilder.group({
            hubId: [{}, Validators.required],
            shelfId: [{}, Validators.required],
        });
    }

    submitForm() {
        this.isLoading = true;
        const body = this.form.getRawValue();
        body['ids'] = this.shipments.map((shipment) => {
            return shipment.id;
        });
        body['status'] = 'MOVED_TO_SHELF_AND_OUT_OF_HANDLER_CUSTODY';
        this.shipmentService.changePackageStatus(body).subscribe(
            (response: any) => {

                this.translateService.get('ALERTS.CHANGED_STATUS').subscribe(
                    (message) => {
                        this.messageService.add({severity: 'success', detail: message});
                    }
                );

                this.isLoading = false;


                this.activeModal.close({isSuccess: true});
            }, error => {
                console.error(error);
                this.isLoading = false;
            }
        );
    }

    private removeItem(barcode: any, body) {
        if (this.selectedBin) {
            this.fulfilmentService.rejectItemFromBin(barcode, this.selectedBin.id, this.shippingPlaneId, body).subscribe((val: any) => {
                    this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    this.activeModal.close({res: val, isSuccess: true});
                }, error => {
                    this.isLoading = false;
                }
            );
        } else if (this.selectedLocation) {
            this.fulfilmentService.rejectItemFromLocation(barcode, this.selectedLocation.id, this.shippingPlaneId, body).subscribe((val: any) => {
                    this.messageService.add({severity: 'success', detail: this.translateService.instant('ALERTS.DONE_SUCCESSFULLY')});
                    this.activeModal.close({res: val, isSuccess: true});
                }, error => {
                    this.isLoading = false;
                }
            );
        }
    }

    closeModal(isSuccess?) {
        if (isSuccess) {
            this.activeModal.close({isSuccess: isSuccess});
        }
        this.activeModal.close();
    }


    getHubsList(event?) {
        const query = '?page=1&pageSize=202';

        this.hubsService.getHubs(query).subscribe(
            (response: any) => {
                this.hubList = response.hubs.map(
                    (hub: HubModel) => {
                        return {label: hub.name, value: hub.id};
                    }
                );
                // this.hubChange(this.hubList[0]);
            }, (error) => {
            }
        );
    }


    public getShelfList(hubId) {
        this.hubsService.getShelves(hubId, '?page=1&pageSize=500').subscribe((res: any) => {
            this.shelves = res.data.map(shelf => {
                const name = [shelf.destinationRegion, shelf.destinationCity, shelf.label].filter(label => !!label).join(', ');
                return {label: name, value: shelf.id};
            });
        });
    }

    hubChange($event: any) {
        this.getShelfList($event?.value);
    }

}
