<div class="change-package-status-container">
    <div id="submitting-container" *ngIf="isLoading">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>
    <div class="dialog-header">
        <div class="dialog-title">
            {{'PRICING.ACTIONS.ADD_PRICING_LIST' | translate}}
        </div>
        <div class="close-icon">
            <i class="fas fa-times cursor-pointer" (click)="closeModal()"></i>
        </div>
    </div>
    <div class="body">
        <form [formGroup]="form">
            <div class="d-flex flex-column">
                <app-input-field-component label="PRICING.LIST_NAME"
                                           [form]="form"
                                           controlName="priceListName"
                                           [isRequired]="true">
                </app-input-field-component >
            </div>
        </form>
    </div>
    <div class="footer">
        <div class="reset-button cursor-pointer" (click)="closeModal()">
            {{'ACTIONS.CANCEL' | translate}}
        </div>
        <div class="add-button cursor-pointer" (click)="onSaveButton()"
             [ngClass]="{'disabled disabled-btn': form.invalid}">
            {{'PRICING.ACTIONS.SAVE_LIST' | translate}}
        </div>
    </div>
</div>
