<div class="enter-barcode-container">
    <div class="header">
        {{('SHIPPING_LINES.NEW_LINE') | translate }}
        <i class="fas fa-times cursor-pointer" style="float: right" (click)="closeModal()"></i>

    </div>
    <div *ngIf="isLoading" class="loading-spinner" style="text-align: center">
        <p-progressSpinner>
        </p-progressSpinner>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div class="col-12 package-barcode-container">
                <div class="inputs row">
                    <app-input-field-component class="col-12" label="SHIPPING_LINES.LINE_NAME" [form]="form"
                                               controlName="name"
                                               [isRequired]="true" controlType="text">
                    </app-input-field-component >
                    <app-input-field-component *ngIf="type === 'RECEIVER'" class="col-12" label="SHIPPING_LINES.EXPECTED_DELIVERY_DAYS_NUMBER"
                                               [form]="form"
                                               controlName="expectedDeliveryDaysNumber"
                                               [isRequired]="false" controlType="text">
                    </app-input-field-component >
                </div>
            </div>
        </div>
        <div class="row col-12">
            <div class="flex-1"></div>
            <div style="padding: 10px 30px">
                <div *ngIf="!shippingLineId" class="add-button cursor-pointer "
                     (click)="createLine()"
                     [ngClass]="{'disable': !form.valid || isLoading}">
                    <i class="far fa-save"></i>
                    {{'ACTIONS.OK' | translate }}
                </div>
                <div *ngIf="shippingLineId" class="add-button cursor-pointer "
                     (click)="editLine()"
                     [ngClass]="{'disable': !form.valid || isLoading}">
                    <i class="far fa-save"></i>
                    {{'ACTIONS.EDIT' | translate }}
                </div>
                <div class="cancel-button cursor-pointer " (click)="closeModal()">
                    <i class="fas fa-times"></i>
                    {{"ACTIONS.CANCEL" | translate}}
                </div>
            </div>
        </div>
    </form>
</div>
