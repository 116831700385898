export const TICKET_CONSTANTS = {
    TICKET_REQUEST: ':api/ticketing/ticket',
    GET_TICKETS: ':api/ticketing/ticket/all',
    DELETE_TICKET: ':api/ticketing/ticket/{ticketId}',
    GET_TICKET_DETAILS: ':api/ticketing/ticket/{ticketId}',
    CATEGORY_REQUEST: ':api/ticketing/category',
    DELETE_CATEGORY: ':api/ticketing/category/{categoryId}',
    GET_CATEGORY_BY_CATEGORY_ID: ':api/ticketing/category/{categoryId}/users',
    GET_CATEGORIES: ':api/ticket/categories',
    GET_ALL_CATEGORIES: ':api/ticketing/category/all',
    UPDATE_TICKET_STATUS: ':api/ticketing/ticket/{ticketId}/status',
    UPDATE_TICKET_CATEGORY: ':api/ticketing/ticket/{ticketId}/category',
    UPDATE_TICKET_CLIENT: ':api/ticketing/ticket/{ticketId}/assignee',
    UPDATE_TICKET_TITLE: ':api/ticketing/ticket/{ticketId}/title',
    UPDATE_TICKET_DESCRIPTION: ':api/ticketing/ticket/{ticketId}/description',
    ADD_REPLY: ':api/ticketing/reply',
    UPDATE_REPLY: ':api/ticketing/reply/text',
    DELETE_REPLY: ':api/ticketing/reply',
    UPLOAD_FILE: ':api/ticketing/files/{folder}/upload',
    GET_CATEGORIES_BY_USER_ID: ':api/ticketing/category/user/{userId}',
    GET_TICKETING_TABLE_COLUMNS: ':api/ticketing/ticket/settings',
    GET_DASHBOARD_STATS: ':api/ticketing/dashboard/stats',
    GET_GENERAL_NOTES: ':api/ticketing/note/all',
    GET_TICKET_HISTORY: ':api/ticketing/ticket/history',
    GENERAL_NOTE_REQUEST: ':api/ticketing/note'

};

export const TICKET_TYPE_ALL = 'ALL';

export const TICKET_TYPES_LIST = [
    'PROBLEM',
    'ENHANCEMENT',
    'REQUEST'
];

export const NOTE_TYPES_LIST = [
    'PUBLIC',
    'PRIVATE'
];

export const TICKET_STATUS_LIST = [
    'NEW',
    'INPROGRESS',
    'DONE'
];

export const TICKET_REPLAY_STATUS_LIST = [
    'NEW_REPLAY',
    'REPLIED',
    'NO_REPLAY'
];

export const TICKET_PRIORITIES_LIST = [
    'LOW',
    'NORMAL',
    'HIGH',
    'CRITICAL'
];
export const ORDER_BY_LIST = [
    'createdDate',
    'status',
    'priority',
    'title'
];
export const DRIVER_REPORT_ORDER_BY = [
    'CREATED_DATE',
    'VILLAGES_ALPHABETICALLY',
    'DRIVER_PACKAGE_ORDER',
    'PICKUP_DATE'
];

export const ACCOUNTANT_REPORT_ORDER_BY = [
    'CREATED_DATE',
    'INVOICE_NUMBER_ASC',
    'INVOICE_NUMBER_DESC',
    'SORT_DATE',
    'RECEIVE_DATE',
    'LAST_STATUS_DATE'
];

export const RETURNED_PACKAGES_SORT_BY = [
    'CREATED_DATE',
    'RETURN_RECEIVE_DATE_DESC',
    'RETURN_RECEIVE_DATE_ASC',
    'INVOICE_NUMBER_DESC',
    'INVOICE_NUMBER_ASC'
];
