import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PackageContentModel} from '../../../../distributor-package-form/models/package-content.model';
import {PackageContentsService} from '../../../services/package-contents.service';

@Component({
    selector: 'app-package-content-form',
    templateUrl: './package-content-form.component.html',
    styleUrls: ['./package-content-form.component.scss']
})
export class PackageContentFormComponent implements OnInit {

    public isEdit = false;
    public packageContent: PackageContentModel;
    currentLang: string;
    isLoading = false;
    packageContentForm: FormGroup;

    constructor(private translateService: TranslateService,
                private formBuilder: FormBuilder,
                private packageContentsService: PackageContentsService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.initForm();
    }

    initForm() {
        this.packageContentForm = this.formBuilder.group({
            name: ['', Validators.required],
            arabicName: ['', Validators.required]
        });

        if (this.isEdit) {
            this.packageContentForm.patchValue(this.packageContent);
        }
    }


    closeModal(isSuccess?) {
        this.activeModal.close({
            isSuccess: isSuccess
        });
    }

    submitForm() {
        this.isLoading = true;
        if (this.isEdit) {
            this.packageContentsService.editPackageContent(this.packageContent.id, this.packageContentForm.getRawValue()).subscribe(res => {
                this.isLoading = false;
                this.closeModal(true);
            }, (error) => {
                this.isLoading = false;
            });
        } else {
            this.packageContentsService.addPackageContent(this.packageContentForm.getRawValue()).subscribe(res => {
                this.isLoading = false;
                this.closeModal(true);
            }, (error) => {
                this.isLoading = false;
            });
        }
    }

    isInvalidDirtyTouched(formName: string) {
        const formControl = this.packageContentForm.get(formName);
        if (formControl) {
            return formControl.invalid && formControl.dirty && formControl.touched;
        } else {
            return false;
        }
    }

    isInvalidWithError(formName: string, errorName: string) {
        const formControl = this.packageContentForm.get(formName);
        if (formControl && formControl.errors) {
            return this.isInvalidDirtyTouched(formName) && formControl.errors[errorName] !== undefined;
        } else {
            return false;
        }
    }
}
