import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SYSTEM_UPDATES_CONSTANT} from '../system-updates-constant';
import {of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SystemUpdatesService {

    constructor(private http: HttpClient) {
    }

    createSystemUpdate(reqBody) {
        return this.http.post(SYSTEM_UPDATES_CONSTANT.ADD_SYSTEM_UPDATE, reqBody);
    }

    updateSystemUpdate(id, reqBody) {
        return this.http.put(SYSTEM_UPDATES_CONSTANT.UPDATE_SYSTEM_UPDATE.replace('{updateId}', id), reqBody);
    }

    removeSystemUpdate(id) {
        return this.http.delete(SYSTEM_UPDATES_CONSTANT.REMOVE_SYSTEM_UPDATE.replace('{updateId}', id));
    }

    loadSystemUpdate(params: any, isSuperAdmin = false) {
        return this.http.get((isSuperAdmin ? SYSTEM_UPDATES_CONSTANT.GET_SYSTEM_UPDATE :
            SYSTEM_UPDATES_CONSTANT.GET_SYSTEM_UPDATE_FOR_ADMIN), {params});
    }

    getRecentlyAdded() {
        return this.http.get(SYSTEM_UPDATES_CONSTANT.GET_RECENTLY_ADDED_DOCUMENTS + '?type=DOCUMENT', {});
    }

    loadSystemUpdateById(id, isSuperAdmin = false) {
        return this.http.get((isSuperAdmin ? SYSTEM_UPDATES_CONSTANT.GET_SYSTEM_UPDATE_BY_ID :
            SYSTEM_UPDATES_CONSTANT.GET_SYSTEM_UPDATE_BY_ID_FOR_ADMIN).replace('{updateId}', id));
    }

    markAllAsSeen() {
        return this.http.put(SYSTEM_UPDATES_CONSTANT.MARK_ALL_SYSTEM_UPDATE_AS_SEEN, {});

    }

    uploadUpdateFile(file: any) {
        if (file && typeof file === 'object') {
            const formData = new FormData();
            formData.append('form', file);
            return this.http.post(SYSTEM_UPDATES_CONSTANT.ADD_SYSTEM_UPDATE_FILE, formData);
        }
        return of({url: ''});
    }
}
