import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-bulk-update-barcodes',
    templateUrl: './bulk-update-barcodes.component.html',
    styleUrls: ['./bulk-update-barcodes.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BulkUpdateBarcodesComponent implements OnInit {
    @Input() form;
    @Input() sentOnce = false;
    @Input() isLoading = false;
    @Input() barcodes = [];
    @Input() finishUpdating = false;

    public statuses = [];
    public cols = [];

    constructor() {
    }

    ngOnInit() {
        this.statuses = [
            {label: 'FAILED', value: 'FAILED'},
            {label: 'UPDATED', value: 'UPDATED'},
            {label: 'CORRECT_BARCODE', value: 'CORRECT_BARCODE'},
            {label: 'CORRECT_INVOICE_NUMBER', value: 'CORRECT_INVOICE_NUMBER'},
            {label: 'UNCORRECT_BARCODE', value: 'UNCORRECT_BARCODE'}
        ];
        this.cols = [
            {field: 'barcode', header: 'Barcode', width: '60%'},
            {field: 'status', header: 'Status', width: '40%'}
        ];
    }

}
