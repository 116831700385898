import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HubModel} from '../../../shared/models/hub.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {WarehouseAreaModel, WarehouseLocationModel, WarehouseRowModel} from '../../models/warehouse.model';
import {FulfilmentService} from '../../services/fulfilment.service';
import {NewAreaComponent} from '../new-area/new-area.component';
import {NewRowComponent} from '../new-row/new-row.component';
import {ShelveModel} from '../../../shared/models/shelve.model';
import {ChoosePrintTypeComponent} from '../../../shipment/choose-print-type/choose-print-type.component';
import {FULFILMENT_CONSTANTS} from '../../services/constants';
import {SHARED_CONSTANTS} from '../../../shared/services/shared_constants/constants';
import {ReserveBinDialogComponent} from '../../bins/reserve-bin-dialog/reserve-bin-dialog.component';
import * as _ from 'lodash';
import {SharedService} from '../../../shared/services/shared-service';
import {ColumnDef, COLUMNS_TYPE} from '../../../shared/components/generic-table/generic-table.component';

@Component({
    selector: 'app-manage-warehouse',
    templateUrl: './manage-warehouse.component.html',
    styleUrls: ['./manage-warehouse.component.scss']
})
export class ManageWarehouseComponent implements OnInit {

    selectedWarehouse: HubModel;
    warehouses: HubModel[] = [];
    fetchedWarehouses: HubModel[];
    isLoadingWarehouses = false;
    totalWarehousesNo = 0;

    selectedArea: WarehouseAreaModel;
    areas: WarehouseAreaModel[] = [];
    fetchedAreas: WarehouseAreaModel[];
    isLoadingAreas = false;
    totalAreasNo = 0;

    selectedRow: WarehouseRowModel;
    rows: WarehouseRowModel[] = [];
    fetchedRows: WarehouseRowModel[];
    isLoadingRows = false;
    totalRowsNo = 0;

    selectedShelf: ShelveModel;
    shelves: ShelveModel[] = [];
    fetchedShelves: ShelveModel[];
    isLoadingShelves = false;
    totalShelvesNo = 0;

    fetchedLocations: WarehouseLocationModel[];
    locations: WarehouseLocationModel[] = [];
    totalLocationsNo = 0;
    binLocationsCount = 0;
    shelfLocationsCount = 0;
    palletLocationsCount = 0;
    isLoadingPrint = false;

    triggeredEntity;
    type: string;
    tableValues = [];
    filtersParams = {};

    currentLang = 'en';
    isLoading = false;

    @ViewChild('chooseAreaHeader', {static: false})
    chooseAreaHeader: ElementRef;

    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    customColumns = [];
    showTotalLocations = false;
    public digitFormat = '1.0-2';
    disableEye = false;
    warehouseStocksType;
    selectedType = 'ALL';
    locationTypeOptions = [];

    isStocksWarehouse = false;
    previousRowsRequest;
    previousAreasRequest;
    previousShelvesRequest;
    previousWarehousesRequest;
    previousLocationsRequest;
    previousTotalsRequest;
    tableConfig;
    columnsDef: ColumnDef[];
    @ViewChild('table')
    table;
    locationStatuses;
    isThereSelection;
    numberOfSelected = 0;
    selectedLocations = [];

    constructor(private activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private translateService: TranslateService,
                private fulfilmentService: FulfilmentService,
                private sharedService: SharedService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.currentLang = this.translateService.currentLang;
        this.initFilters();
        this.initLocationTypes();
        this.initLocationStatuses();
        this.initTableValues();
        this.getTotalLocations();
        this.getLocations();
        this.initTable();
        this.isLoading = false;
    }

    private initFilters() {
        this.filtersParams = {
            WAREHOUSES: {
                page: 1,
                pageSize: 20,
                search: ''
            },
            AREAS: {
                page: 1,
                pageSize: 10,
                search: ''
            },
            ROWS: {
                page: 1,
                pageSize: 10,
                search: ''
            },
            SHELVES: {
                page: 1,
                pageSize: 10,
                search: ''
            },
            LOCATIONS: {
                page: 1,
                pageSize: 20
            }
        };

        switch (this.type) {
            case 'AREAS':
                this.getWarehouses(true);
                break;
            case 'ROWS':
                this.getWarehouses(true);
                this.getAreas(true);
                break;
            case 'LOCATIONS':
                this.getAreas(true);
                break;
        }
    }

    private initTableValues() {
        switch (this.type) {
            case 'AREAS':
                this.getAreas(true);
                break;
            case 'ROWS':
                this.getRows(true);
                break;
            case 'LOCATIONS':
                this.tableValues = this.locations;
                break;
        }
    }

    private getWarehouses(forceFetch = false) {
        this.fetchedWarehouses = [];
        if (this.totalWarehousesNo === this.warehouses.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filtersParams['WAREHOUSES'].page = 1;
        }
        this.isLoadingWarehouses = true;
        if (this.previousWarehousesRequest) {
            this.previousWarehousesRequest.unsubscribe();
        }
        this.previousWarehousesRequest = this.fulfilmentService.getWarehouses(this.createSearchParams(
            this.filtersParams['WAREHOUSES'].page, this.filtersParams['WAREHOUSES'].pageSize, this.filtersParams['WAREHOUSES'].search
        )).subscribe(
            (warehousesResponse) => {
                this.fetchedWarehouses = warehousesResponse['hubs'];
                this.totalWarehousesNo = warehousesResponse['totalRecordsNo'];
                if (forceFetch) {
                    this.warehouses = this.fetchedWarehouses;
                } else {
                    this.warehouses = [...this.warehouses, ...this.fetchedWarehouses];
                }
                this.isLoadingWarehouses = false;
            }, (error) => {
                this.isLoadingWarehouses = false;
                console.error(error);
            }
        );
    }

    private getAreas(forceFetch = false) {
        this.fetchedAreas = [];
        if (this.totalAreasNo === this.areas.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filtersParams['AREAS'].page = 1;
        }
        this.isLoading = true;
        this.isLoadingAreas = true;
        if (this.previousAreasRequest) {
            this.previousAreasRequest.unsubscribe();
        }
        this.previousAreasRequest = this.fulfilmentService.getAreas(this.createSearchParams(
            this.filtersParams['AREAS'].page, this.filtersParams['AREAS'].pageSize, this.filtersParams['AREAS'].search
        ), this.selectedWarehouse.id).subscribe(
            (areasResponse: any) => {
                this.totalAreasNo = areasResponse['totalRecordsNo'];
                areasResponse['data'].forEach(area => {
                    this.fetchedAreas.push({
                        id: area.id,
                        hubId: area.hubId,
                        name: area.name,
                        label: area.label,
                        zone: area.zone
                    });
                });
                if (forceFetch) {
                    this.areas = this.fetchedAreas;
                } else {
                    this.areas = [...this.areas, ...this.fetchedAreas];
                }
                if (this.type === 'AREAS') {
                    this.tableValues = this.areas;
                }
                this.isLoadingAreas = false;
                this.isLoading = false;
            }, (error) => {
                this.isLoadingAreas = false;
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    private getRows(forceFetch = false) {
        this.fetchedRows = [];
        if (this.totalRowsNo === this.rows.length && !forceFetch) {
            return;
        }
        if (forceFetch) {
            this.filtersParams['ROWS'].page = 1;
        }
        this.isLoading = true;
        this.isLoadingRows = true;

        let query = this.createSearchParams(
            this.filtersParams['ROWS'].page, this.filtersParams['ROWS'].pageSize, this.filtersParams['ROWS'].search
        );

        if (this.type === 'ROWS' && !this.isStocksWarehouse) {
            query += '&isGetLocationDetails=true';
        }
        if (this.previousRowsRequest) {
            this.previousRowsRequest.unsubscribe();
        }

        this.previousRowsRequest = this.fulfilmentService.getRows(query, this.selectedWarehouse.id, this.selectedArea ? this.selectedArea.id : null).subscribe(
            (rowsResponse) => {
                this.totalRowsNo = rowsResponse['totalRecordsNo'];
                rowsResponse['data'].forEach(row => {
                    this.fetchedRows.push({
                        id: row.id,
                        name: row.name,
                        label: row.label,
                        areaId: row.areaId,
                        areaName: row.areaName,
                        totalLocations: row.locationsNumber,
                        totalShelves: row.shelvesNumber,
                        totalCols: row.columnsNumber,
                        locationType: row.locationType
                    });
                });
                if (forceFetch) {
                    this.rows = this.fetchedRows;
                } else {
                    this.rows = [...this.rows, ...this.fetchedRows];
                }
                if (this.type === 'ROWS') {
                    this.tableValues = this.rows;
                }
                this.isLoadingRows = false;
                this.isLoading = false;
            }, (error) => {
                this.isLoadingRows = false;
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    private getShelves(forceFetch = false) {
        this.fetchedShelves = [];
        if (this.shelves.length === this.totalShelvesNo && !forceFetch)  {
            return;
        }
        if (forceFetch) {
            this.filtersParams['SHELVES'].page = 1;
        }
        this.isLoadingShelves = true;
        this.isLoading = true;
        if (this.previousShelvesRequest) {
            this.previousShelvesRequest.unsubscribe();
        }
        this.previousShelvesRequest = this.fulfilmentService.getShelves(this.createSearchParams(
            this.filtersParams['SHELVES'].page, this.filtersParams['SHELVES'].pageSize, this.filtersParams['SHELVES'].search
        ), this.selectedWarehouse.id, this.selectedArea ? this.selectedArea.id : null, this.selectedRow ? this.selectedRow.id : null).subscribe(
            (shelvesResponse) => {
                this.totalShelvesNo = shelvesResponse['totalRecordsNo'];
                this.fetchedShelves = shelvesResponse['data'];
                if (forceFetch) {
                    this.shelves = this.fetchedShelves;
                } else {
                    this.shelves = [...this.shelves, ...this.fetchedShelves];
                }
                this.isLoadingShelves = false;
                this.isLoading = false;
            }, (error) => {
                this.isLoadingShelves = false;
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    private getLocations(forceFetch = false) {
        if (this.customColumns && this.customColumns.length > 0) {
            if (forceFetch) {
                this.table.refreshTableData();

            }
            return;
        }
        this.fetchedLocations = [];
        if (this.locations.length === this.totalLocationsNo && !forceFetch)  {
            return;
        }
        if (forceFetch) {
            this.filtersParams['LOCATIONS'].page = 1;
        }
        this.isLoading = true;
        let request = this.fulfilmentService.getLocations(this.createSearchParams(
                this.filtersParams['LOCATIONS'].page, this.filtersParams['LOCATIONS'].pageSize, this.filtersParams['LOCATIONS'].search, this.warehouseStocksType
            ),
            this.selectedWarehouse.id,
            this.selectedArea ? this.selectedArea.id : null,
            this.selectedRow ? this.selectedRow.id : null,
            this.selectedShelf ? this.selectedShelf.id : null);
        if (this.isStocksWarehouse) {
            request = this.fulfilmentService.getLocationsWithStats(this.createSearchParams(
                    this.filtersParams['LOCATIONS'].page, this.filtersParams['LOCATIONS'].pageSize, this.filtersParams['LOCATIONS'].search, this.warehouseStocksType
                ),
                this.selectedWarehouse.id,
                this.selectedArea ? this.selectedArea.id : null,
                this.selectedRow ? this.selectedRow.id : null,
                this.selectedShelf ? this.selectedShelf.id : null);
        }

        if (this.previousLocationsRequest) {
            this.previousLocationsRequest.unsubscribe();
        }
        this.previousLocationsRequest = request.subscribe(
            (locationsResponse) => {
                this.fetchedLocations = locationsResponse['data'];
                if (forceFetch) {
                    this.locations = this.fetchedLocations;
                } else {
                    this.locations = [...this.locations, ...this.fetchedLocations];
                }
                this.tableValues = this.locations;
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
                console.error(error);
            }
        );
    }

    loadLazyWarehouses() {
        this.filtersParams['WAREHOUSES'].page++;
        this.getWarehouses();
    }

    copyText($event, barcode) {
        this.sharedService.copyMessage($event, null, barcode, 'ALERTS.BARCODE_COPIED_SUCCESSFULLY');
    }
    loadLazyAreas() {
        this.filtersParams['AREAS'].page++;
        this.getAreas();
    }

    loadLazyRows() {
        this.filtersParams['ROWS'].page++;
        this.getRows();
    }

    loadLazyShelves() {
        this.filtersParams['SHELVES'].page++;
        this.getShelves();
    }

    loadLazyLocations() {
        this.filtersParams['LOCATIONS'].page++;
        this.getLocations();
    }

    private createSearchParams(page, pageSize, search?, isReserved?) {
        let params = `?page=${page}&pageSize=${pageSize}`;
        if (search) {
            params += `&search=${search}`;
        }
        if (isReserved === 'AVAILABLE') {
            params += '&isReserved=false';
        } else if (isReserved === 'RESERVED') {
            params += '&isReserved=true';
        }
        if (this.selectedType && this.selectedType !== 'ALL') {
            params += '&locationType=' + this.selectedType;
        }

        return params;
    }

    private createSearchParamsObject(search?, isReserved?) {
        const params = {};
        if (search) {
            params['search'] = search;
        }
        if (isReserved === 'AVAILABLE') {
            params['isReserved'] = false;
            params['status'] = isReserved;
        } else if (isReserved === 'RESERVED') {
            params ['isReserved'] = true;
            params['status'] = isReserved;
        } else if (isReserved === 'DAMAGED') {
            params['isRejected'] = true;
        }
        if (this.selectedType && this.selectedType !== 'ALL') {
            params['locationType'] = this.selectedType;
        }


        if (this.selectedArea) {
            params['areaId'] = this.selectedArea.id;
        }
        if (this.selectedRow) {
            params['rowId'] = this.selectedRow.id;
        }
        if (this.selectedShelf) {
            params['shelfId'] = this.selectedShelf.id;
        }
        return params;
    }

    setTriggeredEntity(entity) {
        this.triggeredEntity = entity;
    }

    reserveLocation() {
        const modal = this.modalService.open(ReserveBinDialogComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.bin = this.triggeredEntity;
        modal.componentInstance.isLocation = true;
        // modal.componentInstance.isEditMode = this.triggeredBin.isReserved;
        modal.result.then(
            (value) => {
                if (value && value.isSuccess) {
                    this.getLocations(true);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    newEntity() {
        switch (this.type) {
            case 'AREAS':
                this.createNewArea();
                break;
            case 'ROWS':
                if (!this.selectedArea) {
                    this.chooseAreaHeader.nativeElement.focus();
                    return;
                }
                this.createNewRow();
                break;
        }
    }

    editEntity(entity) {
        switch (this.type) {
            case 'AREAS':
                this.editArea(entity);
                break;
            case 'ROWS':
                this.editRow(entity);
                break;
        }
    }

    editArea(area) {
        const modal = this.modalService.open(NewAreaComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.warehouse = this.selectedWarehouse;
        modal.componentInstance.area = area;
        modal.componentInstance.isEditMode = true;
        modal.result.then(
            (value) => {
                if (value && value.isSuccess) {
                    this.getAreas(true);
                }
            }
        );
    }

    editRow(row) {
        const modal = this.modalService.open(NewRowComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.warehouse = this.selectedWarehouse;
        modal.componentInstance.area = this.selectedArea;
        modal.componentInstance.row = row;
        modal.componentInstance.isEditMode = true;
        modal.result.then(
            (value) => {
                if (value && value.isSuccess) {
                    this.getRows(true);
                }
            }
        );
    }

    createNewArea() {
        const modal = this.modalService.open(NewAreaComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.warehouse = this.selectedWarehouse;
        modal.result.then(
            (value) => {
                if (value && value.isSuccess) {
                    this.getAreas(true);
                }
            }
        );
    }

    createNewRow() {
        const modal = this.modalService.open(NewRowComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.warehouse = this.selectedWarehouse;
        modal.componentInstance.area = this.selectedArea;
        modal.componentInstance.rowCallback = this.getRows.bind(this);
        modal.result.then(
            (value) => {
            }
        );
    }

    searchWarehouse(search) {
        this.filtersParams['WAREHOUSES'].search = search;
        this.getWarehouses(true);
    }

    searchArea(search) {
        this.filtersParams['AREAS'].search = search;
        this.getAreas(true);
    }

    searchRow(search) {
        this.filtersParams['ROWS'].search = search;
        this.getRows(true);
    }

    searchShelf(search) {
        this.filtersParams['SHELVES'].search = search;
        this.getShelves(true);
    }

    selectWarehouse(warehouse) {
        this.selectedWarehouse = warehouse;
        this.clearTable();
        this.getAreas(true);
        if (this.type === 'ROWS') {
            this.selectedArea = null;
            this.getRows(true);
        }
    }

    selectArea(area) {
        this.selectedArea = area;
        this.getRows(true);
        if (this.type === 'LOCATIONS') {
            this.selectedRow = null;
            this.selectedShelf = null;
            this.clearTable();
            this.getShelves(true);
            this.getLocations(true);
            this.getTotalLocations();
        }
    }

    selectRow(row) {
        this.selectedRow = row;
        this.selectedShelf = null;
        this.selectedType = null;
        this.clearTable();
        this.getShelves(true);
        this.getLocations(true);
        this.getTotalLocations();
    }

    selectShelf(shelf) {
        if (this.selectedShelf && this.selectedShelf === shelf) {
            return;
        }
        this.selectedType = null;
        this.selectedShelf = shelf;
        this.getLocations(true);
        this.getTotalLocations();

    }

    trackAreaById(index: number, item: WarehouseAreaModel) {
        return item.id;
    }

    trackWarehouseById(index: number, item: HubModel) {
        return item.id;
    }

    trackShelfById(index: number, item: ShelveModel) {
        return item.id;
    }

    trackRowById(index: number, item: WarehouseRowModel) {
        return item.id;
    }

    onWarehousesScroll(scrollEvent: any) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyWarehouses();
        }
    }

    onAreasScroll(scrollEvent: any) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyAreas();
        }
    }

    onRowsScroll(scrollEvent: any) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyRows();
        }
    }

    onShelvesScroll(scrollEvent: any) {
        if (scrollEvent.target.offsetHeight + scrollEvent.target.scrollTop >= scrollEvent.target.scrollHeight) {
            this.loadLazyShelves();
        }
    }

    onTableScroll(scrollEvent: any) {
        if (scrollEvent.target.scrollHeight - scrollEvent.target.scrollTop === scrollEvent.target.clientHeight) {
            switch (this.type) {
                case 'AREAS':
                    this.loadLazyAreas();
                    break;
                case 'ROWS':
                    this.loadLazyRows();
                    break;
                case 'LOCATIONS':
                    this.loadLazyLocations();
                    break;
            }
        }
    }

    clearTable() {
        switch (this.type) {
            case 'AREAS':
                this.areas = [];
                break;
            case 'ROWS':
                this.rows = [];
                this.selectedArea = null;
                break;
            case 'LOCATIONS':
                this.locations = [];
                break;
        }
        this.tableValues = [];
    }

    printBarcodes(type?) {
        this.isLoadingPrint = true;
        const params = this.createSearchParamsObject('', this.warehouseStocksType);
        params['type'] = type;
        if (this.isThereSelection) {
            params['ids'] = this.selectedLocations.map(e => e.id);
        }
        this.fulfilmentService.printLocations(this.selectedRow.id, params).subscribe((res: any) => {
            window.open(res.url, '_blank');
            this.isLoadingPrint = false;
        }, err => {
            console.error(err);
            this.isLoadingPrint = false;
        });
    }

    openPrintDialog() {
        const modal = this.modalService.open(ChoosePrintTypeComponent, <any>{size: 'md', windowClass: 'package-status-popover'});
        modal.componentInstance.printTypes = FULFILMENT_CONSTANTS.BARCODE_PRINT_TYPES;
        modal.componentInstance.isModal = true;
        modal.result.then(
            (data: { type: any }) => {
                if (data) {
                    this.printBarcodes(data.type);
                }
            }
        ).catch(
            (error) => {
                console.error(error);
            }
        );
    }

    closeModal() {
        this.activeModal.close();
    }

    openRowDialog(item) {
        item = _.clone(item);
        this.disableEye = true;
        this.fulfilmentService.getHubsRowsData(item.id).subscribe(
            (response: any) => {
                this.disableEye = false;
                const modal = this.modalService.open(NewRowComponent, {
                    backdrop: 'static',
                    size: 'lg'
                });
                item.totalCols = response.columnsNumber;
                item.totalShelves = response.shelvesNumber;
                item.totalLocations = response.locationsNumber;
                modal.componentInstance.row = item;
                modal.componentInstance.warehouse = this.selectedWarehouse;
                modal.componentInstance.area = {
                    id: item.areaId,
                    name: item.areaName
                };
                modal.componentInstance.viewOnly = true;
                modal.result.then(
                    (value) => {
                        if (value && value.isSuccess) {
                            this.getRows(true);
                        }
                    }
                );
            },
            () => {
            }
        );

    }

    private getTotalLocations(params?) {
        if (!params) {
            params = {};
        }
        if (this.selectedArea && this.selectedArea.id) {
            params['areaId'] = this.selectedArea.id;
        }
        if (this.selectedRow && this.selectedRow.id) {
            params['rowId'] = this.selectedRow.id;
        }
        if (this.selectedShelf && this.selectedShelf.id) {
            params['shelfId'] = this.selectedShelf.id;
        }
        if (this.warehouseStocksType === 'AVAILABLE') {
            params['isReserved'] = false;
        } else if (this.warehouseStocksType === 'RESERVED') {
            params['isReserved'] = true;
        }
        if (this.previousTotalsRequest) {
            this.previousTotalsRequest.unsubscribe();
        }
        this.previousTotalsRequest = this.fulfilmentService.getTotalLocations(this.selectedWarehouse.id, params).subscribe((value: any) => {
            this.totalLocationsNo = value.allLocationsCount;
            this.binLocationsCount = value.binLocationsCount;
            this.shelfLocationsCount = value.shelfLocationsCount;
            this.palletLocationsCount = value.palletLocationsCount;

        });
    }

    private initLocationTypes() {
        this.locationTypeOptions = FULFILMENT_CONSTANTS.LOCATION_TYPES.map(l => {
            return {
                label: this.translateService.instant('FULFILMENT.WAREHOUSE.LOCATION_TYPES.' + l),
                value: l,
            };
        });
        this.locationTypeOptions.unshift({
            value: '',
            label: this.translateService.instant('ALL')
        });
    }

    private initLocationStatuses() {
        this.locationStatuses = FULFILMENT_CONSTANTS.AVAILABILITY_FILTER_LIST.filter(x => x !== 'ALL').map(l => {
            return {
                label: this.translateService.instant('FULFILMENT.BIN.AVAILABILITY_FILTER_LIST.' + l),
                value: l,
            };
        });
        this.locationStatuses.unshift({
            value: '',
            label: this.translateService.instant('ALL')
        });
    }
    private initColumnDef() {
        this.columnsDef = [
            {
                header: '',
                field: 'isSelected',
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                templateName: 'checkbox',
                style: {
                    'max-width': '50px'
                }
            },
            {
                header: 'FULFILMENT.WAREHOUSE.LOCATIONS.BARCODE',
                field: 'barcode',
                hidden: this.customColumns.indexOf('LOCATION_BARCODE') < 0,
                tdType: COLUMNS_TYPE.BARCODE_WITH_IMAGE,
                columnType: COLUMNS_TYPE.WITH_SEARCH_FILTER
            },
            {
                header: 'FULFILMENT.WAREHOUSE.LOCATIONS.LABEL',
                field: 'label',
                hidden: this.customColumns.indexOf('LOCATION_LABEL') < 0,
            },
            {
                header: 'FULFILMENT.WAREHOUSE.LOCATIONS.BARCODE_STATUS',
                field: 'status',
                hidden: this.customColumns.indexOf('LOCATION_AVAILABILITY') < 0,
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                templateName: 'barcodeStatus',
                columnType: COLUMNS_TYPE.DROPDOWN_FILTER,
                options: this.locationStatuses
            },
            {
                header: 'FULFILMENT.WAREHOUSE.LOCATION_TYPE',
                hidden: this.customColumns.indexOf('LOCATION_TYPE') < 0,
                field: 'locationType',
                columnType: COLUMNS_TYPE.DROPDOWN_FILTER,
                options: this.locationTypeOptions,
                tdType: COLUMNS_TYPE.CUSTOM,
                getHTML: (row) => {
                    return this.translateService.instant((row.type ? 'FULFILMENT.WAREHOUSE.LOCATION_TYPES.' + row.type : 'BIN'));
                }
            },

            {
                header: 'FULFILMENT.ACCOUNTING.ASN_FEES.CUSTOMER_NAME',
                field: 'customerName',
                hidden: this.customColumns.indexOf('CUSTOMER_NAME') < 0,
                columnType: COLUMNS_TYPE.WITH_MULTI_SELECT_FILTER,
            },
            {
                header: 'FULFILMENT.WAREHOUSE.LOCATIONS.BIN_BARCODE',
                field: 'binBarcode',
                hidden: this.customColumns.indexOf('BIN_BARCODE') < 0,
                tdType: COLUMNS_TYPE.BARCODE_WITH_IMAGE,
                columnType: COLUMNS_TYPE.WITH_SEARCH_FILTER
            },
            {
                header: 'FULFILMENT.ITEM.TABLE.NUMBER_OF_SKUS',
                field: 'numberOfSkus',
                hidden: this.customColumns.indexOf('SKU_NUMBER') < 0,
            },
            {
                header: 'FULFILMENT.WAREHOUSE.TOTAL_NUMBER_OF_ITEM',
                field: 'totalItems',
                hidden: this.customColumns.indexOf('TOTAL_NUMBER_OF_ITEM') < 0,
            }
        ];

    }

    private initTable() {
        this.initColumnDef();
        this.tableConfig = {
            url: false,
            loadingType: 'LAZY',
            preRequestHandler: (req: {
                params: any; body: any; url: any;
            }) => {
                this.isLoading = true;
                if (this.isStocksWarehouse) {
                    req.url = FULFILMENT_CONSTANTS.APIS.GET_LOCATIONS_WITH_STATS.replace('{hubId}', this.selectedWarehouse.id + '');
                } else {
                    req.url = FULFILMENT_CONSTANTS.APIS.GET_LOCATIONS.replace('{hubId}', this.selectedWarehouse.id + '');
                }
                req.params = {...req.params, ...this.createSearchParamsObject('', this.warehouseStocksType)};
                if (req.params['page'] === 1) {
                    this.getTotalLocations(req.params);
                }
            },
            postRequestHandler: (res) => {
                this.locations = this.table.value;
                this.isLoading = false;
                this.onToggleSelection();
            }
        };
    }
    public onToggleSelection($event = null) {
        if (event) {
            event.stopPropagation();
        }

        const selectedLocations = this.getSelected();

        this.isThereSelection = selectedLocations.length !== 0;
        this.numberOfSelected = selectedLocations.length;
        this.selectedLocations = selectedLocations;
    }
    private getSelected() {
        const selectedLocations = [];
        const orders = this.table.value;
        if (!orders) {
            return [];
        }

        orders.forEach(
            (plane: any) => {
                if (plane.isSelected) {
                    selectedLocations.push(plane);
                }
            }
        );
        return selectedLocations;
    }
}
