import {Component, OnInit} from '@angular/core';
import {CompaniesService} from '../services/companies.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Address} from '../../shared/models/address.class';
import {ZonesService} from '../../administration/services/zones.service';
import {ActivatedRoute} from '@angular/router';
import {AUTO_ASSIGN_PACKAGES_TO_DRIVER_METHOD_OPTIONS, COMPANIES_CONSTANTS} from '../companies.constants';
import {Location} from '@angular/common';
import {UserService} from '../../shared/services/user.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LOCALE_CONSTANTS} from '../../shared/services/locale_constants/constants';
import {COMPANIES_TYPES} from '../../shared/services/shared_constants/constants';
import {CustomizationCompanyService} from '../../shared/services/customization-company.service';
import {Companies} from '../../../customCompanies/companies.pal-ship';

@Component({
    selector: 'app-new-company-component',
    templateUrl: 'new-company.component.html',
    styleUrls: ['new-company.component.scss']
})
export class NewCompanyComponent extends Address implements OnInit {
    private _isEdit;
    public form;
    public currentLang;
    public items;
    public activeIndex = 0;
    public isLoading;
    public isGuest;
    public isModal = false;
    public companyId;
    public companyInfo: any = {};
    public isSuperAdmin = false;
    public uploadImageApi = COMPANIES_CONSTANTS.UPLOAD_WATERMARK;
    public currencyOptions = LOCALE_CONSTANTS.CURRENCIES;
    public companyTypeOptions: {label: string, value: string}[] = [];
    public languageOptions: { value: string, label: string }[] = [
        {
            value: 'ENGLISH',
            label: 'English'
        },
        {
            value: 'ARABIC',
            label: 'العربية'
        }
    ];
    public pkgPdfTemplateOptions: { value: string, label: string }[];
    isAutoAssignDriversToPackages: any;
    isVatEnabled = false;
    autoAssignPackagesToDriverMethodOptions = AUTO_ASSIGN_PACKAGES_TO_DRIVER_METHOD_OPTIONS;
    public companies = Companies;
    private companyConfigrations;
    set isEdit(val) {
        this._isEdit = val;
    }

    get isEdit() {
        return this._isEdit;
    }

    constructor(private formBuilder: FormBuilder,
                private companiesService: CompaniesService,
                private activeModal: NgbActiveModal,
                public translateService: TranslateService,
                public zonesService: ZonesService,
                private route: ActivatedRoute,
                private location: Location,
                private userService: UserService,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                public customizationCompaniesService: CustomizationCompanyService) {
        super(zonesService, translateService, false); // route.snapshot.data['isAuth']
        this.allVillages = true;
        this.isEdit = this.route.snapshot.data['isEdit'];
        this.isGuest = this.route.snapshot.data['isGuest'];
        this.isModal = this.route.snapshot.data['isModal'];
        this.initCompaniesTypes();
        this.initItems();
    }


    public initForm() {
        this.currentLang = this.translateService.currentLang;
        this.isSuperAdmin = (this.userService.userInfo.role === 'SUPER_ADMIN' || this.isMonitor());
        this.isVatEnabled = this.userService.userInfo.isEnableVat;
        this.getLabels();
        let company;
        if (this.isSuperAdmin) {
            company = this.formBuilder.group({
                name: ['', [Validators.required, Validators.pattern(`^[^!%@$#&?=*^"'\-\/\+\\\n]*$`)]],
                prefix: ['', [Validators.pattern('^[a-zA-Z]{3}')]],
                email: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(6)])],
                phone: ['', Validators.required],
                phone2: [''],
                whatsAppPhoneNumber: [''],
                tollFree: [''],
                registrationNumber: [''],
                currency: ['', [Validators.required]],
                companyType: ['LAST_MILE', Validators.required],
                packagePdfTemplate: ['', [Validators.required]],
                isGuestAddPackage: [false],
                isHideDeliveredMassCodReports: [false],
                isUseBankTransfer: [false],
                isShowPhoneInPackageReport: [true],
                isUseStickerPrinterForAwb: [false],
                isDriverPickupUnassignedPackage: [true],
                isHubManagerAddPackage: [true],
                isNonAdminChangeCost: [false],
                addressLanguageInPackagePdf: ['', Validators.required],
                isAdminOnlyChangeFromDelivered: [false],
                isShowDriverNameInPackageTracking: [false],
                isChangeReceivedOrSortedPackageStatus: [false],
                isPrintCashReceiptWithMassReport: [true],
                isHideCostFromRecieveCodReport: [false],
                isPrintMassCodReportOnceByAccountant: [false],
                isAdminChangeCodForReturnedAndDelivered: [false],
                isDriverCanRequestCodChange: [true],
                isDriverCanReturnPackage: [true],
                isBundlePodEnabled: [false],
                isPrintProofReportWithDriverRunsheet: [true],
                isHidePartnerSender: [false],
                isExcludeSort: [true],
                isShowBarcodeImageInMassReport: [false],
                isAllowOperationManagerAddCustomer: [true],
                isAddCustomerFromQuickAddShipmentForm: [true],
                isAllowPartnerChangeCod: [false],
                isPickupSupported: [false],
                isSupportAddingDraftPackagesByCustomers: [false],
                isAllCustomersAddDraftPackages: [false],
                isSeperateReadyCodToDeliverandCodAtHand: [false],
                isChangeExportedPackages: [false],
                isDeliverPartialMassReport: [true],
                isAllowBilling: [false],
                isBlockBilling: [false],
                isAllowOperationManagerReceiveCod: [false],
                isAllowDispatcherReceiveCod: [false],
                isShowAddShipmentInSideMenu: [false],
                isPrintTwoCopiesForSwap: [true],
                isSupportParcelTracking: [false],
                isSupportReceiveWithoutReleasingCustody: [false],
                isPartialDeliveryEnabled: [true],
                isHideDeliveredToSenderInChangeStatus: [true],
                isDriverEarningEnabled: [false],
                isEnableCustomerWallet: [false],
                isSupportAdditionalFees: [false],
                isSupportDeliveringPackageItemsPartially: [false],
                isSupportReturnedBundles: [false],
                isSupportVillageHub: [false],
                isSupportMassInvoices : [false],
                isFulfilmentEnabled: [false],
                isPickupUnListedPackagesByAdmin: [false],
                isPackageFingerprintRequired: [false],
                isReceivingPointEnabled: [false],
                isShowDriverLocationInPackageTracking: [false],
                isShowFollowUps: [false],
                isShowCurrentReports: [false],
                isShowMassCodReports: [true],
                isDisableCostInAddPackage: [false],
                isPricingPerServiceTypeEnabled: [false],
                isDriverPickupAcceptedPackages: [false],
                isShowDelayedCountInCustomerDashboard: [false],
                isPromptNoteForDriverInPackageDelivery: [false],
                isForceAddingInvoiceNumberWhenAddingPackages: [false],
                isAllowAddingTelecomeDevices: [false],
                isReceivingPkgsWithoutScanItems: [false],
                isAutoAssignToShippingLineDriver: [false],
                hasRouteOptimization: [false],
                isTicketingSystemEnabled: [false],
                isEnableAramexTransfer: [false],
                isInvoicingEnabled: [false],
                isEnableMessaging: [false],
                isAllowAddingCustomerSmsGateway: [false],
                isShowPaymentTypesWhenDriverDeliver: [false],
                isAutoApproveCustomersAfterSignup: [false],
                isShowDetailedPackageHistoryForALLCustomers: [false],
                isShowCustomersToHubManager: [false],
                isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD: [false],
                isEnableCustomersNotifyAdminsToDeliverCod: [false],
                isBundleSupported: [false],
                isEnableCustomerCommissionFee: [false],
                address: this.formBuilder.group({
                    cityId: ['', Validators.required],
                    country: ['Palestine'],
                    regionId: ['', Validators.required],
                    villageId: ['', Validators.required],
                    addressLine1: ['', Validators.required],
                }),
                combined: [''],
                domain: ['.logestechs.com', Validators.required],
                webhookUrl: [''],
                customerAndoidUrl: [''],
                customerIosUrl: [''],
                companyNameInUrl: ['', Validators.required],
                isAutoAssignDriversToPkgs: [false],
                isAutoAssignToOnlineDriversOnly: [false],
                isEnableAutoAssignForAllCustomers : [false],
                isEnableDeliveryVerificationPinCodeForPkgs : [false],
                isEnabledPinVerificationForReturnedPackagesOrMassReports : [false],
                isBlackListEnabled : [false],
                isEnableImileTransfer : [false],
                isHidePackagesInDriversApps : [false],
                isEnableDeletingCustomerAccountByCustomer : [false],
                isShowCustomerFieldWhileAddingTicket : [false],
                isSupportInternationalPhoneNumbers : [false],
                isExcludeSystemZones : [false],
                isEnableExportingBetweenHubs : [false],
                isSupportInternationalShipping : [false],
                autoAssignPackagesToDriversMethod: [null],
                isEnableVat: [false]
            });
        } else {
            company = this.formBuilder.group({
                name: ['', [Validators.required, Validators.pattern(`^[^!%@$#&?=*^"'\-\/\+\\\n]*$`)]],
                prefix: ['', [Validators.pattern('^[a-zA-Z]{3}')]],
                email: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(6)])],
                phone: ['', Validators.required],
                phone2: [''],
                whatsAppPhoneNumber: [''],
                tollFree: [''],
                registrationNumber: [''],
                packagePdfTemplate: ['', [Validators.required]],
                isGuestAddPackage: [false],
                isUseBankTransfer: [false],
                isShowPhoneInPackageReport: [false],
                isUseStickerPrinterForAwb: [false],
                isDriverPickupUnassignedPackage: [false],
                addressLanguageInPackagePdf: ['', Validators.required],
                isAdminOnlyChangeFromDelivered: [false],
                isShowDriverNameInPackageTracking: [false],
                isChangeReceivedOrSortedPackageStatus: [false],
                isPrintCashReceiptWithMassReport: [false],
                isHideCostFromRecieveCodReport: [false],
                isPrintMassCodReportOnceByAccountant: [false],
                isAdminChangeCodForReturnedAndDelivered: [false],
                isDriverCanRequestCodChange: [false],
                isDriverCanReturnPackage: [false],
                isBundlePodEnabled: [false],
                isPrintProofReportWithDriverRunsheet: [false],
                isHidePartnerSender: [false],
                isNonAdminChangeCost: [false],
                isExcludeSort: [false],
                isShowBarcodeImageInMassReport: [false],
                isAllowOperationManagerAddCustomer: [false],
                isAddCustomerFromQuickAddShipmentForm: [false],
                isAllowPartnerChangeCod: [false],
                isPickupSupported: [false],
                isAllCustomersAddDraftPackages: [false],
                isSupportAddingDraftPackagesByCustomers: [false],
                isSeperateReadyCodToDeliverandCodAtHand: [false],
                isChangeExportedPackages: [false],
                isDeliverPartialMassReport: [false],
                isAllowOperationManagerReceiveCod: [false],
                isAllowDispatcherReceiveCod: [false],
                isShowAddShipmentInSideMenu: [false],
                isPrintTwoCopiesForSwap: [false],
                isDriverEarningEnabled: [false],
                isEnableCustomerWallet: [false],
                isSupportAdditionalFees: [false],
                isSupportDeliveringPackageItemsPartially: [false],
                isSupportReturnedBundles: [false],
                isSupportVillageHub: [false],
                isSupportMassInvoices : [false],
                isShowMassCodReports: [false],
                isDisableCostInAddPackage: [false],
                isFulfilmentEnabled: [false],
                isPickupUnListedPackagesByAdmin: [false],
                isPackageFingerprintRequired: [false],
                hasRouteOptimization: [false],
                isDriverPickupAcceptedPackages: [false],
                isShowDelayedCountInCustomerDashboard: [false],
                isPromptNoteForDriverInPackageDelivery: [false],
                isForceAddingInvoiceNumberWhenAddingPackages: [false],
                isAllowAddingTelecomeDevices: [false],
                isReceivingPkgsWithoutScanItems: [false],
                isAutoAssignToShippingLineDriver: [false],
                isShowPaymentTypesWhenDriverDeliver: [false],
                isAutoApproveCustomersAfterSignup: [false],
                isShowDetailedPackageHistoryForALLCustomers: [false],
                isShowCustomersToHubManager: [false],
                isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD: [false],
                isSupportInternationalPhoneNumbers: [false],
                isExcludeSystemZones: [false],
                isEnableExportingBetweenHubs: [false],
                isSupportInternationalShipping: [false],
                isEnableCustomersNotifyAdminsToDeliverCod: [false],
                isBundleSupported: [false],
                isEnableCustomerCommissionFee: [false],
                isSupportParcelTracking: [],
                address: this.formBuilder.group({
                    cityId: ['', Validators.required],
                    country: ['Palestine'],
                    regionId: ['', Validators.required],
                    villageId: ['', Validators.required],
                    addressLine1: ['', Validators.required],
                }),
                combined: [''],
                ads: ['']
            });
        }
        if (!this.isModal && this.isVatEnabled) {
            company.addControl('vat', this.formBuilder.control('', [Validators.required, Validators.min(0), Validators.max(100)]));
        }
        if (this.isEdit) {
            this.disableRest = true;
            this.form = this.formBuilder.group({
                company
            });
            this.setEvents(company);
            this.getCompany();
        } else {
            const admin = this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(6)])],
                phone: ['', Validators.required],
                address: this.formBuilder.group({
                    cityId: [this.countriesOptions[0].value, Validators.required],
                    country: ['Palestine'],
                    regionId: ['', Validators.required],
                    villageId: ['', Validators.required],
                    addressLine1: ['', Validators.required]
                }),
                combinedAdmin: ['']
            });
            this.form = this.formBuilder.group({
                captcha: ['', Validators.required],
                company,
                admin
            });
            this.setEvents(company, admin);
        }
    }

    public closeModal() {
        this.activeModal.close();
    }

    private initItems() {
        this.items = [
            {label: 'GENERAL.COMPANY_INFO'},
            {label: 'GENERAL.ADMIN_INFO'},
        ];

        this.items.forEach(
            (item, index) => {
                this.translateService.get(item.label).toPromise().then(
                    (translation) => {
                        this.items[index].label = translation;
                    }
                );
            }
        );
    }


    public nextStep() {
        if (this.activeIndex < 1) {
            this.activeIndex++;
        }
    }

    public previousStep() {
        if (this.activeIndex > 0) {
            this.activeIndex--;
        }
    }

    public submit() {
        const {captcha, company, admin} = this.form.getRawValue();
        this.isLoading = true;
        const confirmPath = this.getConfirmPath() + '/' + company.name + '/login/reset-password';
        const params = {'verify-url': confirmPath};
        if (!this.isAutoAssignDriversToPackages) {
            company.autoAssignPackagesToDriversMethod = 'NONE';
        }

        if (this.isEdit) {
            Object.keys(company).forEach(key => {
                this.companyConfigrations[key] = company[key];
            })
            this.companiesService.editCompany(this.companyId, {...this.companyConfigrations}).subscribe(() => {
                if (this.isModal) {
                    this.activeModal.close({isSuccess: true});
                } else {
                    this.isLoading = false;
                }
            }, () => {
                let errorMessage = '';
                this.translateService.get(
                    ['COMPANY.FAILED_TO_ADD_COMPANY', 'ALERTS.BULK_RECEIVE_FAILED']
                ).subscribe(
                    (data) => {
                        errorMessage = data['COMPANY.FAILED_TO_ADD_COMPANY'];
                    }
                );
                this.messageService.add({severity: 'error', detail: errorMessage});
                this.isLoading = false;

            });
        } else {
            this.companiesService.submitCompany({
                captcha, ...company,
                admin
            }, params).subscribe(() => {
                if (this.isModal) {
                    this.activeModal.close({isSuccess: true});
                }
            }, (error) => {
                let errorMessage = '';
                this.translateService.get(
                    ['ALERTS.FAILED_TO_ADD_COMPANY']
                ).subscribe(
                    (data) => {
                        errorMessage = data['ALERTS.FAILED_TO_ADD_COMPANY'];
                        this.confirmationService.confirm({
                            message: errorMessage + ': ' + error.error.error,
                            rejectVisible: false,
                            acceptLabel: 'OK'
                        });
                        this.isLoading = false;
                    }
                );

            });
        }
    }

    public setEvents(company, admin?) {
        this.initFormEvent(company.controls.address, 0, {
            country: '',
            region: 'regionId',
            city: 'cityId',
            village: 'villageId'
        });
        if (admin) {

            this.initFormEvent(admin.controls.address, 1, {
                country: '',
                region: 'regionId',
                city: 'cityId',
                village: 'villageId'
            });
        }
    }

    private getConfirmPath() {
        const angularRoute = this.location.path();
        const url = window.location.href;
        return url.replace(angularRoute, '');
    }

    public getCompany() {
        this.isLoading = true;
        return this.companiesService.getCompany().subscribe((company: any) => {
            this.companyConfigrations = company;

            const {
                address, name, prefix, vat, email, phone, registrationNumber, customerIosUrl, customerAndoidUrl, currency, phone2,
                whatsAppPhoneNumber, tollFree, companyType,
                packagePdfTemplate, isGuestAddPackage, addressLanguageInPackagePdf,
                isUseBankTransfer, isShowPhoneInPackageReport, isUseStickerPrinterForAwb, isDriverPickupUnassignedPackage, isExcludeSort,
                isAdminOnlyChangeFromDelivered, isShowDriverNameInPackageTracking, isPrintCashReceiptWithMassReport,
                isChangeReceivedOrSortedPackageStatus, isHubManagerAddPackage, isNonAdminChangeCost, isShowBarcodeImageInMassReport,
                isAllowOperationManagerAddCustomer, isAddCustomerFromQuickAddShipmentForm, isAllowPartnerChangeCod, isHidePartnerSender,
                isPickupSupported, isAllCustomersAddDraftPackages,  isSupportAddingDraftPackagesByCustomers,
                isPrintProofReportWithDriverRunsheet, isHideDeliveredMassCodReports, isSupportReceiveWithoutReleasingCustody,
                isPrintMassCodReportOnceByAccountant, isHideCostFromRecieveCodReport, isShowAddShipmentInSideMenu,
                isSeperateReadyCodToDeliverandCodAtHand, isChangeExportedPackages, isDeliverPartialMassReport, isAllowBilling,
                isBlockBilling, isAllowOperationManagerReceiveCod, isSupportParcelTracking, isPrintTwoCopiesForSwap,
                companyNameInUrl, domain, ads, isDriverCanRequestCodChange, isAdminChangeCodForReturnedAndDelivered,
                isDriverCanReturnPackage, isBundlePodEnabled, isDriverEarningEnabled, isEnableCustomerWallet, isSupportAdditionalFees, isSupportDeliveringPackageItemsPartially, isSupportReturnedBundles, isSupportVillageHub, isSupportMassInvoices , isFulfilmentEnabled, isReceivingPointEnabled,
                isShowFollowUps, isShowCurrentReports, isShowMassCodReports, isDisableCostInAddPackage, isPricingPerServiceTypeEnabled,
                isHideDeliveredToSenderInChangeStatus, hasRouteOptimization, isPickupUnListedPackagesByAdmin,
                isDriverPickupAcceptedPackages, isAllowDispatcherReceiveCod, isTicketingSystemEnabled, isEnableAramexTransfer,
                isInvoicingEnabled, isEnableMessaging, isShowDriverLocationInPackageTracking, isShowDelayedCountInCustomerDashboard,
                isPromptNoteForDriverInPackageDelivery, isAllowAddingCustomerSmsGateway, isPackageFingerprintRequired,
                isForceAddingInvoiceNumberWhenAddingPackages, isReceivingPkgsWithoutScanItems,
                isAllowAddingTelecomeDevices, isAutoAssignDriversToPkgs, isAutoAssignToOnlineDriversOnly,
                isEnableAutoAssignForAllCustomers, isShowCustomerFieldWhileAddingTicket, isShowPaymentTypesWhenDriverDeliver,
                isAutoApproveCustomersAfterSignup, isShowCustomersToHubManager, isShowDetailedPackageHistoryForALLCustomers,
                isAutoAssignToShippingLineDriver, isEnableDeliveryVerificationPinCodeForPkgs,
                isEnabledPinVerificationForReturnedPackagesOrMassReports,
                isHidePackagesInDriversApps, isEnableDeletingCustomerAccountByCustomer,
                isSupportInternationalPhoneNumbers, isSupportInternationalShipping,
                isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD, isEnableCustomersNotifyAdminsToDeliverCod,
                isBundleSupported, isBlackListEnabled,
                isEnableCustomerCommissionFee, isExcludeSystemZones, isEnableExportingBetweenHubs, isEnableImileTransfer,
                autoAssignPackagesToDriversMethod, isEnableVat

            } = company;
            this.disableRest = true;
            this.form.patchValue({
                company: {
                    address: address || {},
                    name,
                    prefix,
                    vat,
                    email,
                    phone,
                    phone2,
                    whatsAppPhoneNumber,
                    tollFree,
                    domain,
                    registrationNumber,
                    customerIosUrl,
                    customerAndoidUrl,
                    currency,
                    companyType,
                    packagePdfTemplate,
                    isHideDeliveredMassCodReports,
                    isGuestAddPackage,
                    isUseBankTransfer,
                    isShowPhoneInPackageReport,
                    isUseStickerPrinterForAwb,
                    isDriverPickupUnassignedPackage,
                    isHubManagerAddPackage,
                    isNonAdminChangeCost,
                    addressLanguageInPackagePdf,
                    isAdminOnlyChangeFromDelivered,
                    isShowDriverNameInPackageTracking,
                    isChangeReceivedOrSortedPackageStatus,
                    isPrintCashReceiptWithMassReport,
                    isHideCostFromRecieveCodReport,
                    isPrintProofReportWithDriverRunsheet,
                    isSupportReceiveWithoutReleasingCustody,
                    isExcludeSort,
                    companyNameInUrl,
                    isShowBarcodeImageInMassReport,
                    isAllowOperationManagerAddCustomer,
                    isAddCustomerFromQuickAddShipmentForm,
                    isAllowPartnerChangeCod,
                    isHidePartnerSender,
                    isPickupSupported,
                    isAllCustomersAddDraftPackages,
                    isSupportAddingDraftPackagesByCustomers,
                    isSeperateReadyCodToDeliverandCodAtHand,
                    isChangeExportedPackages,
                    isDeliverPartialMassReport,
                    isPrintMassCodReportOnceByAccountant,
                    isAdminChangeCodForReturnedAndDelivered,
                    isDriverCanRequestCodChange,
                    isDriverCanReturnPackage,
                    isBundlePodEnabled,
                    isAllowBilling,
                    isBlockBilling,
                    isAllowOperationManagerReceiveCod,
                    isAllowDispatcherReceiveCod,
                    isShowAddShipmentInSideMenu,
                    isPrintTwoCopiesForSwap,
                    isSupportParcelTracking,
                    ads,
                    isDriverEarningEnabled,
                    isEnableCustomerWallet,
                    isSupportAdditionalFees,
                    isSupportDeliveringPackageItemsPartially,
                    isSupportReturnedBundles,
                    isSupportVillageHub,
                    isSupportMassInvoices ,
                    isFulfilmentEnabled,
                    isReceivingPointEnabled,
                    isShowDriverLocationInPackageTracking,
                    isShowFollowUps,
                    isHideDeliveredToSenderInChangeStatus,
                    isShowCurrentReports,
                    isShowMassCodReports,
                    isDisableCostInAddPackage,
                    isPricingPerServiceTypeEnabled,
                    hasRouteOptimization,
                    isPickupUnListedPackagesByAdmin,
                    isPackageFingerprintRequired,
                    isDriverPickupAcceptedPackages,
                    isShowDelayedCountInCustomerDashboard,
                    isPromptNoteForDriverInPackageDelivery,
                    isForceAddingInvoiceNumberWhenAddingPackages,
                    isReceivingPkgsWithoutScanItems,
                    isAutoAssignToShippingLineDriver,
                    isTicketingSystemEnabled,
                    isEnableAramexTransfer,
                    isInvoicingEnabled,
                    isEnableMessaging,
                    isAllowAddingCustomerSmsGateway,
                    isAllowAddingTelecomeDevices,
                    isAutoAssignDriversToPkgs,
                    isAutoAssignToOnlineDriversOnly,
                    autoAssignPackagesToDriversMethod,
                    isEnableAutoAssignForAllCustomers,
                    isShowPaymentTypesWhenDriverDeliver,
                    isShowCustomerFieldWhileAddingTicket,
                    isEnableDeliveryVerificationPinCodeForPkgs,
                    isEnabledPinVerificationForReturnedPackagesOrMassReports,
                    isBlackListEnabled,
                    isHidePackagesInDriversApps,
                    isEnableDeletingCustomerAccountByCustomer,
                    isSupportInternationalPhoneNumbers,
                    isExcludeSystemZones,
                    isEnableExportingBetweenHubs,
                    isSupportInternationalShipping,
                    isAutoApproveCustomersAfterSignup,
                    isShowDetailedPackageHistoryForALLCustomers,
                    isShowCustomersToHubManager,
                    isNotifyToCCWhenFailedDeliveryPkgAndChangeCOD,
                    isEnableCustomersNotifyAdminsToDeliverCod,
                    isBundleSupported,
                    isEnableImileTransfer,
                    isEnableCustomerCommissionFee,
                    isEnableVat
                }
            });
            this.isAutoAssignDriversToPackages = isAutoAssignDriversToPkgs;
            if (isAutoAssignDriversToPkgs && this.form.controls.company.controls.autoAssignPackagesToDriversMethod) {
                this.form.controls.company.controls.autoAssignPackagesToDriversMethod.setValidators([Validators.required]);
            }
            this.form.patchValue({
                    company: {
                        address: {
                            regionId: address.regionId,
                            villageId: address.villageId,
                            cityId: address.cityId
                        }
                    }
                }
            );
            this.form.controls.company.patchValue({
                combined: {
                    value: address.villageId,
                    label: address.village + '-' + address.city + '-' +
                        address.region
                }
            });
            this.form.patchValue({
                    company: {
                        address: {
                            villageId: address.villageId
                        }
                    }
                }
            );
            this.companyInfo = company;
            this.disableRest = false;
        }, () => {
        }, () => {
            this.isLoading = false;
        });
    }

    public showBooleanSettings() {
        return !(this.userService.userInfo.role === 'SUPER_ADMIN' || this.userService.userInfo.role === 'ADMIN')
            || this.isModal;
    }

    public getLabels() {
        const templates = ['FIRST_TEMPLATE', 'SECOND_TEMPLATE', 'THIRD_TEMPLATE', 'FOURTH_TEMPLATE', 'FIFTH_TEMPLATE', 'SIXTH_TEMPLATE', 'SEVEN_TEMPLATE'];
        if (this.userService.userInfo.isDistributor) {
            templates.push('DISTRIBUTOR_TEMPLATE');
        }
        if (this.userService.userInfo.isTrucking) {
            templates.push('TRUCKING_TEMPLATE');
        }
        this.translateService.get(templates.map(temp => `TEMPLATE.${temp}`)).subscribe(
            (values) => {
                this.pkgPdfTemplateOptions = templates.map(template => {
                    return {
                        label: values[`TEMPLATE.${template}`],
                        value: (template === 'TRUCKING_TEMPLATE' ? 'DISTRIBUTOR_TEMPLATE' : template)
                    };
                });
            }
        );
    }
    updateZoneForm($event, controlName) {
        this.form.controls.company.controls.address.controls[controlName].setValue(null);
        this.form.controls.company.controls.address.controls[controlName].updateValueAndValidity();
    }
    updateZoneFormAdmin($event, controlName) {
        this.form.controls.admin.controls.address.controls[controlName].setValue(null);
        this.form.controls.admin.controls.address.controls[controlName].updateValueAndValidity();
    }
    onSelectAddress() {
        const {company} = this.form.getRawValue();
        this.form.controls.company.controls.address.controls['regionId'].setValue(company.combined.regionId);
        this.form.controls.company.controls.address.controls['cityId'].setValue(company.combined.cityId);
        this.form.controls.company.controls.address.controls['villageId'].setValue(company.combined.villageId);
    }
    onSelectAddressAdmin() {
        const {admin} = this.form.getRawValue();
        this.form.controls.admin.controls.address.controls['regionId'].setValue(admin.combinedAdmin.regionId);
        this.form.controls.admin.controls.address.controls['cityId'].setValue(admin.combinedAdmin.cityId);
        this.form.controls.admin.controls.address.controls['villageId'].setValue(admin.combinedAdmin.villageId);
    }

    private initCompaniesTypes() {
        this.translateService.get(COMPANIES_TYPES.map(type => `COMPANY.TYPE.${type}`)).subscribe(values => {
            this.companyTypeOptions = COMPANIES_TYPES.map(type => ({
                label: values[`COMPANY.TYPE.${type}`],
                value: type
            }));
        });
    }
    public isMonitor() {
        return this.userService.userInfo.role === 'MONITOR';
    }

    autoAssignDriverChanged($event: any) {
        if (!$event.checked) {
            this.form.controls.company.controls.isAutoAssignToOnlineDriversOnly.setValue(false);
            this.form.controls.company.controls.isEnableAutoAssignForAllCustomers.setValue(false);
            this.form.controls.company.controls.autoAssignPackagesToDriversMethod.setValidators([]);
        } else {
            this.form.controls.company.controls.autoAssignPackagesToDriversMethod.setValidators([Validators.required]);
            this.form.updateValueAndValidity();
        }
        this.form.controls.company.controls.autoAssignPackagesToDriversMethod.setValue(null);

    }

    public changeInvoiceConfigration(type) {
        if (type === 'MASS_INVOICE') {
            if (this.form.controls.company.controls.isSupportMassInvoices.value === true) {
                this.form.controls.company.controls.isInvoicingEnabled.setValue(false);
            }
        } else if (type === 'INVOICE') {
            if (this.form.controls.company.controls.isInvoicingEnabled.value === true) {
                this.form.controls.company.controls.isSupportMassInvoices.setValue(false);
            }
        }
    }

    public previewPdfTemplate () {
        this.isLoading = true;
        const template = this.form.controls.company.controls.packagePdfTemplate.value;
        this.userService.previewPdfTemplate(template).subscribe((res: any) => {
            this.userService.getPDFBlob(res.url).subscribe(response => {
                const blobUrl = URL.createObjectURL(response);
                const iframe = document.createElement('iframe');
                iframe.src = blobUrl;
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
                this.isLoading = false;
            }, err => {
                console.error(err);
                this.isLoading = false;
            });

        }, err => {
            console.error(err);
            this.isLoading = false;
        });

    }

    ngOnInit(): void {
        this.initForm();
    }
}
