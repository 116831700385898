import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {share, tap} from 'rxjs/operators';

const DEFAULT_CACHE_VALIDATION_PERIOD_MS = 1000 * 30;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    private cache: Map<string, HttpResponse<any>> = new Map();
    private cacheInvalidationCallbacks: Map<string, number> = new Map();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req);
        // const useCache = req.headers.get('use-cache');
        // if (req.method !== 'GET' || !useCache || useCache !== 'true') {
        //     return next.handle(req);
        // }
        //
        // if (req.headers.get('reset-cache')) {
        //     this.cache.delete(req.urlWithParams);
        // }
        //
        // if (this.cacheInvalidationCallbacks.get(req.urlWithParams)) {
        //     clearTimeout(this.cacheInvalidationCallbacks.get(req.urlWithParams));
        //     this.cacheInvalidationCallbacks.delete(req.urlWithParams);
        // }
        //
        // const cacheValidationMs = req.headers.get('cache-validation-ms');
        // if (cacheValidationMs && typeof cacheValidationMs === 'number') {
        //     this.cacheInvalidationCallbacks.set(req.urlWithParams, setTimeout(() => {
        //         this.cache.delete(req.urlWithParams);
        //     }, cacheValidationMs));
        //     req.headers.delete('cache-validation-ms');
        // } else {
        //     this.cacheInvalidationCallbacks.set(req.urlWithParams, setTimeout(() => {
        //         this.cache.delete(req.urlWithParams);
        //     }, DEFAULT_CACHE_VALIDATION_PERIOD_MS));
        // }
        //
        // const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams);
        // if (cachedResponse) {
        //     return of(cachedResponse.clone());
        // } else {
        //     req.headers.delete('use-cache');
        //     return next.handle(req).pipe(tap((stateEvent) => {
        //         if (stateEvent instanceof HttpResponse && stateEvent.status === 200) {
        //             this.cache.set(req.urlWithParams, stateEvent.clone());
        //         }
        //     }), share());
        // }
    }
}
