import { Component, OnInit } from '@angular/core';
import {PricingService} from '../../services/pricing.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-jett-shipping-rate',
  templateUrl: './jett-shipping-rate.component.html',
  styleUrls: ['./jett-shipping-rate.component.scss']
})
export class JettShippingRateComponent implements OnInit {
  storeToStorePrices: any;
  storeToDoorAPrices: any;
  storeToDoorBPrices: any;
  outboundPrice: any;

  constructor(private pricingService: PricingService) { }

  ngOnInit(): void {
    this.pricingService.getJettPrices().subscribe((response: any) => {
      this.storeToStorePrices = response[0];
      this.storeToDoorAPrices = response[1];
      this.storeToDoorBPrices = response[2];
      this.outboundPrice = response[3];
    });
  }
}
