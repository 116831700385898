import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HubsService} from '../../shared/services/hubs.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeographyService} from '../../shared/services/geography.service';
import {HubModel} from '../../shared/models/hub.model';
import {Address} from '../../shared/models/address.class';
import {ZonesService} from '../../administration/services/zones.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../shared/services/user.service';
import {FulfilmentService} from '../../fulfilment/services/fulfilment.service';

@Component({
    selector: 'app-new-hub-component',
    templateUrl: 'new-hub.component.html',
    styleUrls: ['new-hub.component.scss']
})
export class NewHubComponent extends Address implements OnInit {
    @Input() hub: HubModel;

    public form: FormGroup;
    public isSubmitting = false;
    public isLoading = true;
    public hubTypes: { label: string, value: any } [];
    public isEditMode = false;
    public currentLang = '';
    public isWarehouse = false;
    public isStations = false;
    public isEnableExportingBetweenHubs = false;

    constructor(
        private formBuilder: FormBuilder,
        private hubService: HubsService,
        private activeModal: NgbActiveModal,
        private geographyService: GeographyService,
        private zonesService: ZonesService,
        private translateService: TranslateService,
        private userService: UserService,
        private fulfilmentService: FulfilmentService
    ) {
        super(zonesService, translateService, true);
        this.allVillages = true;
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.isEnableExportingBetweenHubs  = this.userService.userInfo.isEnableExportingBetweenHubs;
        if (this.hub) {
            this.isEditMode = true;
        }

        this.initForm();
        this.initHubTypes();
        this.isLoading = false;
    }

    private initHubTypes() {
        this.hubService.getHubTypes().subscribe(
            (response) => {
                this.hubTypes = response.map(
                    (type) => {
                        return {label: type, value: type};
                    }
                );
                if (!this.isEditMode) {
                    this.form.controls.type.setValue(this.hubTypes[0].value);
                    this.form.controls.type.updateValueAndValidity();
                }
            }
        );
    }

    private initForm() {
        let info = {
            name: '',
            phone: '',
            type: '',
            contactPerson: '',
            addressLine1: '',
            city: '',
            country: '',
            region: '',
            combined: '',
            village: '',
            addressLine2: '',
            prefix: '',
            isHideSenderInfoForReceivingHub: null,
            isPreventReceivingHubsFromModifyingPackage: null
        };

        if (this.isEditMode) {
            info = <any>{
                name: this.hub.name,
                phone: this.hub.phone,
                type: this.hub.type,
                isHideSenderInfoForReceivingHub: this.hub.isHideSenderInfoForReceivingHub,
                isPreventReceivingHubsFromModifyingPackage: this.hub.isPreventReceivingHubsFromModifyingPackage,
                contactPerson: this.hub.contactPerson,
                addressLine1: this.hub.address.addressLine1,
                addressLine2: this.hub.address.addressLine2,
                prefix: this.hub.prefix,
                city: this.hub.address.cityId,
                country: this.hub.address.country,
                region: this.hub.address.regionId,
                village: this.hub.address.villageId
            };
        }

        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            phone: ['', [Validators.required,this.userService.checkPhonePattern.bind(this, this.userService.getPhonePattern())]],
            type: ['', Validators.required],
            contactPerson: ['', Validators.required],
            addressLine1: ['', Validators.required],
            addressLine2: [''],
            city: ['', Validators.required],
            country: ['Palestine'],
            region: ['', Validators.required],
            combined: ['', Validators.required],
            village: ['', Validators.required],
            isHideSenderInfoForReceivingHub: [false],
            isPreventReceivingHubsFromModifyingPackage: [false]
        });

        if (this.isWarehouse) {
            this.form.addControl('prefix', this.formBuilder.control('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]));
        }

        this.initFormEvent(this.form, 0, {
            country: '',
            region: 'region',
            city: 'city',
            village: 'village',
            combined: 'combined'
        });
        this.disableRest = true;
        this.form.patchValue(info);
        if (this.isEditMode) {  // city and village values have to be reset after resiogn to fix form validity on edit
            this.form.patchValue({
                combined: {
                    cityId: this.hub.address.cityId,
                    cityName: this.hub.address.city,
                    label: this.hub.address.village + '-' + this.hub.address.city + '-' +
                        this.hub.address.region
                    ,
                    prefix: this.hub.prefix,
                    regionId: this.hub.address.regionId,
                    regionName: this.hub.address.region,
                    villageId: this.hub.address.villageId,
                    villageName: this.hub.address.village,
                    value: this.hub.address.villageId
                }
            });
        }
        this.disableRest = false;
    }


    public onAddHub() {
        if (!this.form.valid) {
            return;
        }

        this.isSubmitting = true;
        const data = this.form.getRawValue();
        const reqBody = {
            name: data.name,
            phone: data.phone,
            type: data.type,
            isHideSenderInfoForReceivingHub: data.isHideSenderInfoForReceivingHub,
            isPreventReceivingHubsFromModifyingPackage: data.isPreventReceivingHubsFromModifyingPackage,
            contactPerson: data.contactPerson,
            prefix: data.prefix,
            address: {
                addressLine1: data.addressLine1,
                cityId: data.city.value,
                city: data.city.label,
                country: data.country,
                villageId: data.village.value,
                village: data.village.label,
                addressLine2: data.addressLine2,
                regionId: data.region.value,
                region: data.region.label
            },
            isWarehouse: this.isWarehouse
        };

        if (this.isEditMode) {
            this.editHub(reqBody);
            return;
        }

        if (this.isWarehouse) {
            this.fulfilmentService.newWarehouse(reqBody).subscribe(
                (response) => {
                    this.activeModal.close({isSuccess: true});
                }, (error) => {
                    this.isSubmitting = false;
                }
            );
        } else {
            this.hubService.createHub(reqBody).subscribe(
                (response) => {
                    this.activeModal.close({isSuccess: true});
                }, (error) => {
                    this.isSubmitting = false;
                }
            );
        }
    }

    public editHub(reqBody) {
        const editBody = Object.assign(this.hub, reqBody);
        if (this.isWarehouse) {
            this.fulfilmentService.updateWarehouse(this.hub.id, editBody).subscribe(
                (response) => {
                    this.activeModal.close({isSuccess: true});
                }, (error) => {
                    this.isSubmitting = false;
                }
            );
        } else {
            this.hubService.updateHub(this.hub.id, editBody).subscribe(
                (response) => {
                    this.activeModal.close({isSuccess: true});
                }, (error) => {
                    this.isSubmitting = false;
                }
            );
        }
    }

    public closeModal() {
        this.activeModal.close({isSuccess: false});
    }
    updateZoneForm($event, controlName) {
        this.form.controls[controlName].setValue(null);
        this.form.controls[controlName].updateValueAndValidity();
    }
    onChangeHubType() {
       if (this.form.get('type').value === 'SUB') {
           this.form.get('isHideSenderInfoForReceivingHub').reset();
           this.form.get('isPreventReceivingHubsFromModifyingPackage').reset();
       }
    }

    isInvalidDirtyOrTouched(formControlName: string, errorType?: string) {
        const formControl = this.form.get(formControlName);
        if (formControl) {
            return errorType ? formControl.hasError(errorType) && formControl.invalid && (formControl.touched || formControl.dirty)
                : formControl.invalid && (formControl.touched || formControl.dirty);
        }
        return false;
    }
    preventNotChar($event: KeyboardEvent) {
        const key = $event.key;
        if (RegExp(/^\p{L}/, 'u').test(String(key)) || (!isNaN(Number(key)) && isFinite(Number(key)))) {
            return true;
        } else {
            $event.stopPropagation();
            $event.preventDefault();
        }
    }

    getWarehouseBranchLabel() {
        if (!this.isWarehouse && !this.isStations) {
            return 'BRANCHES_MANAGEMENT.TABLE.BRANCH_NAME';
        } else if (!this.isWarehouse && this.isStations) {
            return 'STATIONS_MANAGEMENT.TABLE.BRANCH_NAME';
        } else if (this.isWarehouse) {
            return 'FULFILMENT.WAREHOUSE.TABLE.WAREHOUSE_NAME'
        }

        return null;
    }
}
