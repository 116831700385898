import {Component, OnInit, ViewChild} from '@angular/core';
import {ADMINISTRATION_CONSTANTS} from '../../administration-constants';
import {ParcelService} from '../../services/parcel.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewParcelComponent} from './new-parcel/new-parcel.component';
import {TranslateService} from '@ngx-translate/core';
import {COLUMNS_TYPE} from '../../../shared/components/generic-table/generic-table.component';

@Component({
    selector: 'app-manage-parcel',
    templateUrl: './manage-parcel.component.html',
    styleUrls: ['./manage-parcel.component.scss']
})
export class ManageParcelComponent implements OnInit {
    tableConfig;
    columnsDef;
    @ViewChild('table') table;
    constructor(public parcelService: ParcelService,
                private modalService: NgbModal,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        this.initColumnDef();
        this.tableConfig = {
            url: ADMINISTRATION_CONSTANTS.GET_PARCELS
        };
    }

    private initColumnDef() {
        this.columnsDef = [
            {
                header: 'PARCEL.PARCEL_TYPE',
                field: 'type',
                tdType: COLUMNS_TYPE.CUSTOM,
                getHTML: (parcel) => {
                    return this.translateService.currentLang === 'ar' ? parcel.arabicType : parcel.type;
                }
            },
            {
                header: 'ADD_SHIPMENT.LENGTH',
                field: 'length',
            },
            {
                header: 'ADD_SHIPMENT.WIDTH',
                field: 'width',
            },
            {
                header: 'ADD_SHIPMENT.HEIGHT',
                field: 'height',
            },
            {
                header: 'PRODUCT.WEIGHT',
                field: 'weight',
            },
            {
                header: '',
                field: 'setting',
                tdType: COLUMNS_TYPE.CUSTOM_TEMPLATE,
                templateName: 'setting'
            }
        ];
    }
    public parcelModal(parcel?) {
        const modal = this.modalService.open(NewParcelComponent, <any>{
            backdrop: 'static',
            windowClass: 'create-new-user',
            size: 'md'
        });
        if (parcel) {
            modal.componentInstance.parcel = parcel;
        }

        modal.result.then(
            (status) => {
                if (status.isSuccess) {
                    this.table.refreshTableData();
                }
            }
        ).catch(
            (error) => {
            }
        );
    }

    deleteParcel(parcel) {
        this.parcelService.deleteParcel(parcel.id).subscribe(data => {
            this.table.refreshTableData();
        });
    }
}
