<div *ngIf="translationLoaded" class="side-bar-menu-container" [ngClass]="{'d-none': item.fakePath}">
    <ng-container *ngIf="item.path; else menuList">
        <a  class="row item-container cursor-pointer"
            (click)="selectItem(item)"
            [routerLink]="item.path"
            [queryParams]="item?.params"
            [routerLinkActive]="getRouterLinkActive(isSubItem)"
            #itemLinkRef>
            <span class="icon-name">
                <span *ngIf="!isSubItem && (item.imgSrc || item.icon)" class="icon">
                    <img *ngIf="item.imgSrc" src="{{getImageSrc(item.imgSrc)}}" alt="" />
                    <i *ngIf="!item.imgSrc" class="{{item?.icon}}"></i>
                </span>

                <span class="title" [ngClass]="{'sub-item-title': isSubItem}">
                    {{item?.label | translate | packageTitle  | hubTitle | addressSystem}}
                </span>
                <span *ngIf="item.path === 'pending-approval'" class="pending-number">
                    <span>{{userService.followUpPackageCount}}</span>
                </span>
                <div *ngIf="item.path === 'inbox'" class="pending-number">
                    <span>{{userService.unreadMessages}}</span>
                </div>
                <span *ngIf="item.showBadge" class="pending-number">
                    <span>{{item.badgeOp | async}}</span>
                </span>
            </span>
        </a>
    </ng-container>

    <ng-template #menuList>
        <div class="d-flex flex-column">
            <!--             [attr.style]="(getPaddingStyles() | safeStyle)"-->
            <!--        <div [attr.style]="('padding:' + 15 + level + 'px !important') | safeStyle"-->
            <div [ngClass]="{'active' : hasActiveChild}" class="item-container cursor-pointer menu-item-animation" (click)="toggleMenuVisibility()">
            <span class="icon-name">
                <span class="icon" *ngIf="!isSubItem && (item.imgSrc || item.icon)">
                    <img *ngIf="item.imgSrc" src="{{getImageSrc(item.imgSrc)}}" alt="" />
                    <i *ngIf="!item.imgSrc" class="{{item?.icon}}"></i>
                </span>
                <span class="title">
                    {{item?.label | translate | packageTitle  | hubTitle}}
                </span>
                <span *ngIf="item.showBadge" class="pending-number main-list">
                    <span>{{item.badgeOp | async}}</span>
                </span>
                <i *ngIf="item.subItems" class="fas fa-caret-down menu-list-collapse-icon" [ngClass]="{'fa-caret-up':show}" aria-hidden="true"></i>
            </span>

                <span *ngIf="item?.badge > 0" class="badge" [ngClass]="{'high': item?.badge > 9, 'low': item?.badge <= 9}">
                <span class="number" *ngIf="item?.badge !== -1">
                    {{item?.badge}}
                </span>
            </span>
            </div>
            <div *ngIf="item.subItems" class="sub-menu" [ngStyle]="{'max-height':show ? (calculateSubItemsHeight(item) + 'px') : 0}">
                <div class="sub-menu-items" style="border-inline-start: 3px solid #F7685B">
                    <app-side-menu-item-component #subItemsRef [isSubItem]="true" *ngFor="let item of item.subItems" [item]="item" (onSelectSubMenu)="onSubMenuShow($event)" [level]="level+10"> </app-side-menu-item-component>
                </div>
            </div>
        </div>
    </ng-template>

</div>
