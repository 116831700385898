<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="inbox-container" >
  <div class="inbox-header">
    <div class="inbox-title">
      {{"INBOX.INBOX" | translate}}
    </div>
    <div class="contact-us cursor-pointer" #btn (click)="sendMessage(btn)">
      {{"INBOX.SEND_MESSAGE" | translate}}
    </div>
  </div>

  <div class="inbox-body">
    <div class="row emails-container">

      <div class="col-3 messages-list-container" [ngClass]="currentLang === 'ar' ? 'left-border' : 'right-border'">
        <ng-container *ngIf="spinnerState === spinnerStates.LOADED">
          <div>
            <div class="read-unread-filter">
                <div class="values">
                  <div class="item"
                       (click)="showUnreadMessages('ALL')"
                       [ngClass]="{'active-item': showRead === 'ALL','b-radius-right': currentLang === 'ar' , 'b-radius-left': currentLang === 'en'}">{{'ALL' | translate}}</div>
                  <div class="item flex-2d-center"
                       (click)="showUnreadMessages('READ')"
                       [ngClass]="{'active-item': showRead === 'READ'}">{{'INBOX.READ' | translate}}</div>
                  <div class="item"
                       (click)="showUnreadMessages('UNREAD')"
                       [ngClass]="{'active-item': showRead === 'UNREAD','b-radius-left': currentLang === 'ar' , 'b-radius-right': currentLang === 'en'}">{{'INBOX.UNREAD' | translate}}</div>
                </div>
              </div>
            <app-email  *ngFor="let email of emails" [email]="email" (click)="openEmailDetails(email)" [class]="selectedEmail?.id === email?.id ? 'selected' : ''"></app-email>
            <div *ngIf="!emails.length" class="d-flex justify-content-center mt-4">{{'INBOX.NO_MESSAGES' | translate}}</div>
          </div>
          <div class="pagination-container">
            <p-paginator
                    styleClass="shipment-paginator"
                    (onPageChange)="onPagination($event)"
                    [first]="0" [rows]="5" [totalRecords]="totalRecords"></p-paginator>
          </div>
        </ng-container>
        <ng-container *ngIf="spinnerState === spinnerStates.LOADING">
          <app-skeleton-loader [type]="1"></app-skeleton-loader>
        </ng-container>
        <ng-container *ngIf="spinnerState === spinnerStates.EMPTY">
          <app-no-data-available-component>
          </app-no-data-available-component>
        </ng-container>
      </div>
      <div class="col-9 message-container" *ngIf="isEmailDetailsOpened">
        <app-email-details [email]="selectedEmail">
        </app-email-details>
      </div>
      <div class="col-9 message-empty-container" *ngIf="!isEmailDetailsOpened">
        {{'GENERAL.UNSELECTED_EMAIL' | translate}}
      </div>
    </div>
  </div>
</div>
