<div class="h-100 d-flex flex-column">
  <div class="top-actions">
    <div class="group-tabs-container">
      <div class="cursor-pointer tab" routerLink="work-zone" routerLinkActive="selectedTab">
        {{'DRIVERS_EARNINGS.WORK_ZONES' | translate}}
      </div>
      <div class="cursor-pointer tab" routerLink="shipping-rates" routerLinkActive="selectedTab">
        {{'DRIVERS_EARNINGS.DRIVER_EARNINGS' | translate}}
      </div>
    </div>
  </div>

  <div class="flex-1 overflow-auto">
    <router-outlet>
    </router-outlet>
  </div>
</div>
