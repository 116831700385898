<p-toast [baseZIndex]="toastZIndex"></p-toast>
<div class="accounting-template h-100" [ngClass]="{'page-ltr': currentLang === 'en'}">

    <p-overlayPanel #filtersFormOverLay
                    class="filters-menu-panel"
                    [dismissable]="true"
                    (onHide)="filterFormOn = false"
                    [showCloseIcon]="false">
        <div class="filters-menu-container">
            <div class="filters-header">
                <div class="filters-menu-title">
                    <span>{{'TICKETING_SYSTEM.FILTER_FORM_TITLE' | translate}}</span>
                </div>
            </div>
            <div class="filters-inputs-group d-flex flex-column">

<!--                <div *ngIf="filtersSettings.showTypeFilter" class="filter-item w-100">-->
<!--                    <div class="filter-label">{{'CONTAINERS_MANAGEMENT.ORDER_BY' | translate}}</div>-->
<!--                    <div class="filter-value w-100">-->
<!--                        <p-dropdown [options]="custodyTypeList"-->
<!--                                    [(ngModel)]="params.type"-->
<!--                                    optionLabel='label'-->
<!--                                    filter="true">-->
<!--                            <ng-template pTemplate="item" let-item>-->
<!--                                {{item.label | translate}}-->
<!--                            </ng-template>-->
<!--                            <ng-template let-selectedItem pTemplate="selectedItem">-->
<!--                                {{selectedItem.label | translate}}-->
<!--                            </ng-template>-->
<!--                        </p-dropdown>-->
<!--                    </div>-->
<!--                </div>-->
                <div *ngIf="filtersSettings.showDriverFilter">
                    <app-users-list-dropdown-component
                            [isDriver]="true"
                            [label]="('MANAGE_COD_CUSTOMERS.DRIVER_NAME')"
                            (userChanged)="onSelectedDriverChange($event)"
                            [selectedUser]="selectedDriver"
                    ></app-users-list-dropdown-component>
                </div>
                <div *ngIf="filtersSettings.showCustomerFilter">
                    <app-users-list-dropdown-component
                            [label]="('MANAGE_COD_CUSTOMERS.' + params.type.value + '_NAME')"
                            #userFilter
                            [isDriver]="params.type.value==='DRIVER'"
                            [isHub]="params.type.value==='HUB'"
                            [isPartner]="params.type.value==='PARTNER'"
                            [placeHolder]="' '"
                            [isDisabled]="!params.type.value"
                            (userChanged)="onFilterSelectedChange($event)"
                            [showResetButton]="true"
                            [selectedUser]="selectedEntity"
                    ></app-users-list-dropdown-component>
                </div>

                <div *ngIf="filtersSettings.showStatusInvoiceFilter">
                    <app-drop-down [options]="invoiceTypes"
                                  [label]="'MANAGE_COD_CUSTOMERS.MASS_INVOICES.STATUS'"
                                  [appendTo]="'body'"
                                  [(ngModel)]="selectedInvoicesType"
                                  (whenChange)="onInvoicesTypeSelected($event)"
                                  [showClear]="false"></app-drop-down>
                </div>

                <div *ngIf="filtersSettings.showCustomerTypeFilter" class="filter-item w-100">
                    <div class="filter-label">{{'CUSTOMER_TYPES.CUSTOMER_TYPE' | translate}}</div>
                    <div class="filter-value w-100">
                        <p-dropdown [options]="customerTypes"
                                    [(ngModel)]="params.customerType"
                                    optionLabel='label'>
                            <ng-template pTemplate="item" let-item>
                                {{item.label | translate}}
                            </ng-template>
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.label | translate}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <ng-container *ngIf="filtersSettings.showCodValueFilter">
                    <div class="filter-item w-100">
                        <div class="filter-label">{{'PRICING.TOTAL_REPORT_GREATER_THAN' | translate}}</div>
                        <div class="filter-value w-100">
                            <input type="number" pInputText
                                   [(ngModel)]="params.threshold">
                        </div>
                    </div>

                    <div class="filter-item w-100">
                        <div class="filter-label">{{'PRICING.TOTAL_REPORT_LESS_THAN' | translate}}</div>
                        <div class="filter-value w-100">
                            <input type="number" pInputText
                                   [(ngModel)]="params.terminus">
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="filters-menu-actions">
                <div class="reset-action filter-menu-action" (click)="resetFilters()">
                    <span>{{'TICKETING_SYSTEM.RESET' | translate}}</span>
                </div>
                <div class="apply-filters-action filter-menu-action" (click)="onFilter()">
                    <span>{{'TICKETING_SYSTEM.APPLY' | translate}}</span>
                </div>
            </div>
        </div>
    </p-overlayPanel>

    <div class="header d-flex justify-content-between">
        <div class="header-title page-header-title">
            <div class="title">{{title | translate}}</div>
            <div class="total-number-badge">{{totalRecords}}</div>
        </div>
        <div class="filters d-flex align-items-center">
            <div *ngIf="isShowToggleFilterMenu()" class="toggle-filters-form-btn filter">
                <div class="icon" (click)="showFiltersMenu($event)">
                    <img *ngIf="!filterFormOn" src="../../../../assets/icons/filter-icon.png"/>
                    <img *ngIf="filterFormOn" src="../../../../assets/icons/active-filter.png"/>
                </div>
            </div>
            <div class="filter">
                <p-dropdown [(ngModel)]="params.dateFilterType.value"
                            placeholder="{{ 'ACCOUNTING.CHOOSE_DATE_TYPE' | translate }}"
                            [options]="dateFiltersList" filter="true"
                            (onChange)="onDateFilterChange()">
                </p-dropdown>
            </div>
            <div class="filter">
<!--                <div class="date-picker-filter">-->
<!--                    <img src="../../../../assets/icons/calendar.svg" (click)="datepicker.click()" alt="icon"/>-->
<!--                    <field-input type="text" name="daterangeInput" class="custom-date-range"-->
<!--                           #datepicker-->
<!--                           daterangepicker [options]="dateRangPickerOptions"-->
<!--                           (selected)="onDateSelected($event)"/>-->
<!--                    <i class="fas fa-caret-down"></i>-->
<!--                </div>-->
                <app-date-range-picker (onSelect)="onDateSelected($event)"></app-date-range-picker>

            </div>
            <div class="filter search-filter-container d-flex">
                <div class="icon">
                    <img src="../../assets/icons/general/search.svg" alt="">
                </div>
                <div class="value">
                    <input appInputDebounced
                           (inputDebounced)="onFilter()"
                           [(ngModel)]="params.search"
                           placeholder="{{'GENERAL.SEARCH' | translate}}..."
                           type="text">
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="isShowTopActions()" class="accounting-actions actions-container d-flex align-items-center flex-wrap">
        <div *ngIf="topActionsSettings.showReadByBarcode || topActionsSettings.pickWithDriver || topActionsSettings.deliver || (topActionsSettings.printDriverReports && permissions.includes('{PRINT_DRIVER_REPORTS'))"
             class="main-actions d-flex">
            <div class="gradient-action action"
                 *ngIf="topActionsSettings.showReadByBarcode"
                 (click)="readByBarcode()">
                {{'MANAGE_SHIPMENTS.ACTIONS_LABEL.READ_BY_BARCODE' | translate}}
            </div>
            <div *ngIf="topActionsSettings.pickWithDriver" class="action gradient-action"
                 (click)="pickWithDriver()"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE' }">
                {{"ACCOUNTING.PICK_WITH_DRIVER" | translate}}
            </div>
            <div *ngIf="topActionsSettings.deliver" class="action gradient-action"
                 (click)="deliverMultiplePackages()"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE' }">
                {{(type === 'INVOICE' ? "MANAGE_COD_CUSTOMERS.INVOICES.PAY_THE_BILLS" : "ACCOUNTING.DELIVER") | translate}}
            </div>
            <div *ngIf=" topActionsSettings.printDriverReports &&  permissions.includes('{PRINT_DRIVER_REPORTS')"
                 class="action gradient-action"
                 [ngClass]="{'disabled-button disabled': params.dateFilterType.value !== 'NONE'}"
                 (click)="printDriverMassReports()">
                {{"ACCOUNTING.PRINT_DRIVER_REPORTS" | translate}}
            </div>
        </div>

        <div class="secondary-actions d-flex">
            <div *ngIf="topActionsSettings.exportToExcel" class="action secondary-action-btn"
                 (click)="exportCodToExcel()">
                {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}
            </div>

            <div *ngIf="topActionsSettings.sortSelectedCod && permissions.includes('SORT_ALL')"
                 class="action secondary-action-btn"
                 (click)="sortPackage(codList, $event)"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length}">
                {{"ACCOUNTING.SORT_ALL" | translate}}
            </div>

            <div *ngIf="topActionsSettings.exportCod && permissions.includes('EXPORT_ALL')"
                 class="action secondary-action-btn"
                 (click)="exportPackage(codList,$event)"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE'}">
                {{"ACCOUNTING.EXPORT_ALL" | translate}}
            </div>

            <div *ngIf="topActionsSettings.changeStatusToExported && permissions.includes('CHANGE_STATUS_TO_EXPORTED')"
                 class="action secondary-action-btn"
                 (click)="changeStatusToExported(codList)"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE'}">
                {{"ACCOUNTING.CHANGE_STATUS_TO_EXPORTED" | translate}}
            </div>

            <div *ngIf="topActionsSettings.receiveWithOutReleasingCustody && isSupportReceiveWithoutReleasingCustody"
                 [ngClass]="{'disabled-button disabled': params.dateFilterType.value !== 'NONE'}"
                 class="action secondary-action-btn"
                 (click)="onCustodyTypeChange(null)">
                {{"ACCOUNTING.RECEIVE_WITHOUT_RELEASING_CUSTODY" | translate}}
            </div>

            <div *ngIf="topActionsSettings.receive"
                 [ngClass]="{'disabled-button disabled': params.dateFilterType.value !== 'NONE'}"
                 class="action secondary-action-btn"
                 (click)="verifyDriver()">
                {{"ACCOUNTING.RECEIVE" | translate}}
            </div>

            <div *ngIf="topActionsSettings.printMultiple && !isHubUserRole" class="action secondary-action-btn"
                 (click)="printSelectedPkgs('multiple')"
                 [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE'}">
                {{"SHIPMENT.PRINT_MULTIPLE" | translate}}
            </div>

            <div *ngIf="topActionsSettings.printAll && !isHubUserRole" class="action secondary-action-btn"
                 (click)="downloadMultiplePackages(true)"
                 [ngClass]="{'disabled-btn disabled': params.dateFilterType.value !=='NONE'}">
                {{"SHIPMENT.PRINT_ALL" | translate}}
            </div>

            <div *ngIf="topActionsSettings.verifyDriver" class="action secondary-action-btn"
                 (click)="verifyDriver()">
                {{"ACCOUNTING.HUB_CUSTODY" | translate | hubTitle}}
            </div>

            <div *ngIf="topActionsSettings.sortCod && !mobAvailable"
                 class="action secondary-action-btn"
                 [ngClass]="{'disabled-btn disabled': params.dateFilterType.value !== 'NONE'}"
                 (click)="sortPackages()">
                {{"ACCOUNTING.SORT" | translate}}
            </div>

            <div *ngIf="topActionsSettings.printList" class="action secondary-action-btn"
                 (click)="printList()"
                 [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE' }">
                {{"ACCOUNTING.PRINT_LIST" | translate}}
            </div>
            <div *ngIf="topActionsSettings.printEnvelope" class="action secondary-action-btn"
                 (click)="PrintEnvelope()"
                 [ngClass]="{'disabled-btn disabled': !getSelectedPackages().length || params.dateFilterType.value !== 'NONE' }">
                {{"ACCOUNTING.PRINT_ENVELOPE" | translate}}
            </div>

            <div *ngIf="topActionsSettings.createInvoice && showCreateInvoice()"
                 class="action secondary-action-btn"
                 (click)="createInvoiceConfirmation(null, $event)"
                 [ngClass]="{'disabled-btn disabled': disableCreateMultipleInvoices() }">
                {{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES" | translate}}
            </div>

            <div *ngIf="topActionsSettings.exportPerCustomer"
                 class="action secondary-action-btn"
                 (click)="exportCustomersCodToExcel()">
                {{"ACCOUNTING.EXPORT_PER_CUSTOMERS" | translate}}
            </div>

            <div *ngIf="topActionsSettings.exportSelectedToExcel && permissions.includes('EXPORT_COD_TO_EXCEL')"
                 class="action secondary-action-btn"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length}"
                 (click)="openExcel()">
                {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}
            </div>

            <div *ngIf="topActionsSettings.createMob && mobAvailable && permissions.includes('MOB')"
                 class="action secondary-action-btn"
                 (click)="mob()">
                {{"ACCOUNTING.MOB" | translate}}
            </div>
            <div *ngIf="topActionsSettings.exportToPdf" class="action secondary-action-btn"
                 (click)="exportToPdf()">
                {{"ACTIONS.EXPORT_TO_PDF" | translate}}
            </div>
            <div *ngIf="topActionsSettings.changeDriver" class="action secondary-action-btn"
                 (click)="openDriverDialog()"
                 [ngClass]="{'disabled-button disabled': !getSelectedPackages().length}">
                {{"ACTIONS.CHANGE_DRIVER" | translate}}
            </div>
            <div *ngIf="topActionsSettings.createInvoiceForCustomers && showCreateInvoice() && userInfo.isInvoicingEnabled"
                 class="action secondary-action-btn"
                 (click)="createInvoiceForCustomer()">
                {{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICES_FOR_CUSTOMERS" | translate}}
            </div>

        </div>
    </div>
    <div class="main-table accounting-template-table-container d-flex">
        <p-table appPTableRtlFix dataKey="id" #table [value]="codList"
                 paginatorDropdownAppendTo="body"
                 styleClass="accounting-template-table flex-table"
                 [loading]="isLoading" [scrollable]="true" scrollHeight="auto"
                 [lazy]="true" (onLazyLoad)="loadCodListLazy($event)" [paginator]="true" [responsive]="true"
                 [rows]="pageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPageOptions"
                 [(first)]="first">
            <ng-template pTemplate="emptymessage">
                <app-no-data-available-component>
                </app-no-data-available-component>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <p-checkbox [(ngModel)]="isAllSelected" binary="true"
                                    (onChange)="onToggleSelectAll()">
                        </p-checkbox>
                    </th>
                    <th *ngFor="let col of tableInfo">
                        <ng-container *ngIf="col.withFilter">
                            <div *ngIf="col.field === 'paymentType'">
                                <div class="th-filter-container">
                                    <div class="filter-label">{{'ACCOUNTING.PAYMENT_TYPE'|translate}}</div>
                                    <p-dropdown styleClass="th-dropdown-filter"
                                                [(ngModel)]="paymentMethod" optionLabel="label"
                                                [options]="paymentsMethod" filter="true"
                                                (onChange)="onChangePaymentMethod()">
                                    </p-dropdown>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="col.hubFilter">
                            <app-users-list-dropdown-component [isHub]="true"
                                                               #hubFilter
                                                               label="ACCOUNTING.CUSTOMER_HUB"
                                                               [showLabel]="true"
                                                               [isFilter]="true"
                                                               [panelWidth]="'100%'"
                                                               (userChanged)="onChangeHubMethod($event)"></app-users-list-dropdown-component>
                        </ng-container>
                        <ng-container *ngIf="!col.withFilter && !col.hubFilter">
                            {{col.header | translate}}
                        </ng-container>
                    </th>
                    <th *ngIf="currency === 'SAR'"> {{"ACCOUNTING.COD_FEES" | translate}}</th>
                    <th *ngIf="isEnableVat"> {{"MANAGE_COD_CUSTOMERS.MASS_INVOICES.VAT_VALUE" | translate}}</th>
                    <th *ngIf="isEnableVat"> {{"MANAGE_COD_CUSTOMERS.MASS_INVOICES.NET_COD" | translate}}</th>

                    <th *ngIf="rowActionsSettings.exportCod"> {{"ACCOUNTING.EXPORT" | translate}}</th>
                    <th *ngIf="rowActionsSettings.deliverCod"> {{(type === 'INVOICE' ? "MANAGE_COD_CUSTOMERS.INVOICES.PAY_THE_BILL" : "ACCOUNTING.DELIVER") | translate}}</th>
                    <th *ngIf="rowActionsSettings.attachments">
                        <div class="attachments-filter">
                            <div class="label">{{ 'GENERAL.ATTACHMENTS' | translate }}</div>
                            <div class="values">
                                <div class="item"
                                     (click)="setAttachmentFilter('ALL')"
                                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'ALL','b-radius-right': currentLang === 'ar' , 'b-radius-left': currentLang === 'en'}">{{'ALL' | translate}}</div>
                                <div class="item flex-2d-center" (click)="setAttachmentFilter('WITH')" [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                <div class="item"
                                     (click)="setAttachmentFilter('WITHOUT')"
                                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITHOUT','b-radius-left': currentLang === 'ar' , 'b-radius-right': currentLang === 'en'}">{{'WITHOUT' | translate}}</div>
                            </div>
                        </div>
                    </th>

                    <th *ngIf="rowActionsSettings.signature">
                        <div class="attachments-filter">
                            <div class="label">{{ 'MANAGE_SHIPMENTS.SIGNATURE' | translate }}</div>
                            <div class="values">
                                <div class="item"
                                     (click)="setSignatureFilter('ALL')"
                                     [ngClass]="{'active-item': selectedSignatureFilter === 'ALL','b-radius-right': currentLang === 'ar' , 'b-radius-left': currentLang === 'en'}">{{'ALL' | translate}}</div>
                                <div class="item flex-2d-center" (click)="setSignatureFilter('WITH')" [ngClass]="{'active-item': selectedSignatureFilter === 'WITH'}">{{'WITH' | translate}}</div>
                                <div class="item"
                                     (click)="setSignatureFilter('WITHOUT')"
                                     [ngClass]="{'active-item': selectedSignatureFilter === 'WITHOUT','b-radius-left': currentLang === 'ar' , 'b-radius-right': currentLang === 'en'}">{{'WITHOUT' | translate}}</div>
                            </div>
                        </div>
                    </th>
<!--                    <th *ngIf="rowActionsSettings.attachments">-->
<!--                        <div class="d-flex attachments-header">-->
<!--                            <div>{{ 'ADD_SHIPMENTS.ATTACHMENTS' | translate }}</div>-->
<!--&lt;!&ndash;                            <div>&ndash;&gt;-->
<!--&lt;!&ndash;                                <p-inputSwitch [(ngModel)]="isOnlyShowSignature"&ndash;&gt;-->
<!--&lt;!&ndash;                                               (onChange)="getData()">&ndash;&gt;-->
<!--&lt;!&ndash;                                </p-inputSwitch>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </th>-->
                    <th *ngIf="rowActionsSettings.print"> {{"MANAGE_CONTAINERS.PRINT" | translate}}</th>
                    <th *ngIf="rowActionsSettings.printAsEnvelop"> {{"MANAGE_CONTAINERS.PRINT_ENVELOPE" | translate}}</th>
                    <th *ngIf="rowActionsSettings.pay"> {{"MANAGE_CONTAINERS.PAY" | translate}}</th>
                    <th *ngIf="rowActionsSettings.sortCod"> {{"ACCOUNTING.SORT" | translate}}</th>
                    <th *ngIf="rowActionsSettings.exportExcel"> {{"ACCOUNTING.EXPORT_TO_EXCEL" | translate}}</th>
                    <th *ngIf="rowActionsSettings.createInvoice && userInfo.isInvoicingEnabled"> {{"MANAGE_COD_CUSTOMERS.INVOICES.CREATE_INVOICE" | translate}}</th>
                    <th *ngIf="rowActionsSettings.history"> {{"MANAGE_COD_CUSTOMERS.HISTORY" | translate}}</th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr (click)="goToCustomerCOD(report)">
                    <td>
                        <p-checkbox [(ngModel)]="report.isSelected" binary="true"
                                    (onChange)="onToggleSelection()" (click)="$event.stopPropagation()"
                        ></p-checkbox>
                    </td>
                    <td *ngFor="let col of tableInfo">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchCase="'barcode'">
                                <div class="d-flex barcode-container justify-content-center">
                                    <div class="barcode-value">{{report[col.field]}}</div>
                                    <div class="copy-icon">
                                        <img src="../../assets/icons/track_package/copy.svg" alt=""
                                             (click)="copyMessage($event,null, report[col.field])"
                                             data-toggle="tooltip" data-placement="top" title="Copy"
                                             class="copy_icon"/>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'dateTime'">
                                {{report[col.field] | date:'dd/MM/yyyy'}}  {{getLocalTime(report[col.field])}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                {{report[col.field] | date:'dd/MM/yyyy'}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'concat'">
                                <ng-container *ngFor="let field of col.field">
                                    {{getConcat(report, field)}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'translate'">
                                {{report[col.field] | translate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'concatKeys'">
                                <ng-container>
                                    {{getDeepData(report, col.field)}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{report[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>

                    <td *ngIf="currency === 'SAR'"> {{ report.codFees}}</td>
                    <td *ngIf="isEnableVat"> {{ report.vatSum}}</td>
                    <td *ngIf="isEnableVat"> {{ report.netSum}}</td>

                    <td *ngIf="rowActionsSettings.exportCod">
                        <div class="row-action"
                             [tooltipDisabled]="params.dateFilterType.value ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': params.dateFilterType.value !== 'NONE'}"
                                 class="edit-action action cursor-pointer" (click)="exportPackage(report,$event)">
                                <i class="fas fa-file-export"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.deliverCod">
                        <div class="row-action"
                             [tooltipDisabled]="params.dateFilterType.value ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': params.dateFilterType.value !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="$event.stopPropagation();preDeliverPackage(report,$event)">
                                <i class="fas fa-file-export"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.attachments">
                        <div *ngIf="report.deliveryProofUrlJson" class="row-action">
                            <img (click)="openImages($event, report)"
                                 src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.signature">
                        <div *ngIf="isSignatureExist(report.deliveryProofUrlList)" class="row-action">
                            <img (click)="openSignatureImages($event, report.deliveryProofUrlList)"
                                 src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>
                        </div>
                    </td>

<!--                    <td *ngIf="rowActionsSettings.attachments">-->
<!--                        <div *ngIf="isSignatureExist(report.deliveryProofUrlList)" class="row-action">-->
<!--                            <img (click)="openSignatureImages($event, report.deliveryProofUrlList)"-->
<!--                                 src="../../../assets/icons/manage_shipments/attachment_orange.svg" alt=""/>-->
<!--                        </div>-->
<!--                    </td>-->
                    <td *ngIf="rowActionsSettings.print">
                        <div class="row-action"
                             [tooltipDisabled]="params.dateFilterType.value ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': params.dateFilterType.value !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="$event.stopPropagation();printSelectedPkgs('single', printPackage.bind(this,report, $event), report)">
                                <i class="fas fa-print"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.printAsEnvelop">
                        <div class="row-action"
                             [tooltipDisabled]="params.dateFilterType.value ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': params.dateFilterType.value !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="$event.stopPropagation();printAsEnvelopPkg(report)">
                                <i class="fas fa-print"></i>
                            </div>
                        </div>
                    </td>

                    <td *ngIf="rowActionsSettings.pay">
                        <div class="row-action" *ngIf="report.status !== 'PAID'"
                             [tooltipDisabled]="params.dateFilterType.value ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div [ngClass]="{'disabled-button disabled': params.dateFilterType.value !=='NONE'}"
                                 class="edit-action action cursor-pointer" (click)="$event.stopPropagation();paidPkg(report)">
                                <i class="fas fa-dollar-sign"></i>
                            </div>
                        </div>
                    </td>

                    <td *ngIf="rowActionsSettings.sortCod">
                        <div class="row-action"
                             [tooltipDisabled]="params.dateFilterType.value ==='NONE'"
                             tooltipPosition="right"
                             pTooltip="{{'MANAGE_COD_CUSTOMERS.DISABLED_ACTIONS_MESSAGE'| translate}}">
                            <div class="edit-action action cursor-pointer"
                                 [ngClass]="{'disabled-button disabled': (params.dateFilterType.value !== 'NONE')}"
                                 (click)="sortPackage(report,$event)">
                                <i class="fas fa-bookmark"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.exportExcel">
                        <div class="row-action">
                            <div class="edit-action action cursor-pointer"
                                 (click)="exportToExcel(report,$event)">
                                <i class="fas fa-file-excel"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.createInvoice && userInfo.isInvoicingEnabled">
                        <div class="row-action"
                             [ngClass]="{'disabled-btn disabled': !report.isCustomerBilled}">
                            <div class="edit-action action cursor-pointer"
                                 (click)="createInvoiceConfirmation(report,$event)">
                                <i class="fas fa-file-invoice"></i>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowActionsSettings.history">
                        <div class="row-action">
                            <div class="edit-action action cursor-pointer"
                                 (click)="showHistory($event, report)">
                                    <i class="fas fa-history"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="sum-values d-flex flex-wrap justify-content-around">
        <div *ngFor="let item of sumValues" class="sum-value-item flex-2d-center flex-column">
            <div class="value"><span *ngIf="showCurrency(item)" class="currency">{{currency}}</span>{{item.value | number : digitFormat}}</div>
            <div class="label">{{item.label | translate}}</div>
        </div>
    </div>
</div>
