import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Currency} from '../../../../shared/services/locale_constants/constants';
import {PaymentsService} from '../../../services/payments.service';
import {SHARED_CONSTANTS} from '../../../../shared/services/shared_constants/constants';
import {PaymentIntentResult} from '@stripe/stripe-js';
import {PaymentStatus} from '../payment.model';

@Component({
    selector: 'app-new-payment',
    templateUrl: './new-payment.component.html',
    styleUrls: ['./new-payment.component.scss']
})
export class NewPaymentComponent implements OnInit {

    readonly PaymentStatus = PaymentStatus;

    form: FormGroup;
    currencies: { label: string, value: string }[] = [];
    isLoading = false;
    paymentStatus = PaymentStatus.DRAFT;
    toastZIndex = SHARED_CONSTANTS.TOAST_Z_INDEX;
    clientSecret: string;
    errorMessage: string;

    constructor(private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private paymentsService: PaymentsService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            cardElement: ['', Validators.required],
            amount: ['', [Validators.required, Validators.min(1)]],
            currency: ['', Validators.required]
        });

        // Add extra currencies here
        const availableCurrencies = Object.keys(Currency).filter(
            currency => [Currency.USD]
                .includes(currency as Currency));

        this.translateService.get(availableCurrencies.map(c => `CURRENCY.LONG_LABEL.${c}`)).subscribe(values => {
            this.form.get('currency').patchValue({
                value: Currency.USD,
                label: Currency.USD + ' - ' + values[`CURRENCY.LONG_LABEL.${Currency.USD}`]
            });
            this.form.get('currency').disable();

            this.currencies = availableCurrencies.map(currency => {
                return {
                    label: currency + ' - ' + values[`CURRENCY.LONG_LABEL.${currency}`],
                    value: currency
                };
            });
        });
    }

    onPay() {
        if (this.form.invalid || this.isLoading) {
            return;
        }
        this.translateService.get([
            'PAYMENT.ALERTS.CONFIRM_PAYMENT',
            'PAYMENT.ALERTS.FAILED',
            'PAYMENT.ALERTS.PERFORMED_SUCCESSFULLY',
            'GENERAL.YES',
            'GENERAL.NO'
        ]).subscribe(values => {
            this.confirmationService.confirm({
                message: values['PAYMENT.ALERTS.CONFIRM_PAYMENT'],
                acceptLabel: values['GENERAL.YES'],
                rejectLabel: values['GENERAL.NO'],
                accept: () => {
                    this.isLoading = true;
                    const formValue = this.form.getRawValue();
                    this.paymentsService.newPaymentIntent({
                        amount: formValue.amount,
                        currency: formValue.currency.value
                    }).subscribe((response: { clientSecret: string }) => {
                        this.clientSecret = response.clientSecret;
                        this.paymentsService.stripe.confirmCardPayment(this.clientSecret, {
                            payment_method: {
                                card: formValue.cardElement,
                            }
                        }).then((result: PaymentIntentResult) => {
                            this.isLoading = false;

                            if (result.paymentIntent) {
                                this.messageService.add({
                                    severity: 'success',
                                    detail: values['PAYMENT.ALERTS.PERFORMED_SUCCESSFULLY']
                                });
                                this.paymentStatus = PaymentStatus.SUCCESSFUL;
                                this.resetForm();
                            } else if (result.error) {
                                this.messageService.add({
                                    severity: 'error',
                                    detail: values['PAYMENT.ALERTS.FAILED']
                                });
                                this.paymentStatus = PaymentStatus.FAILED;
                                /**
                                 * Payment errors codes and decline codes are added to the JSON translation files as an uppercase key
                                 * Refer to Stripe docs to obtain these codes: https://stripe.com/docs/testing#declined-payments
                                 */
                                if (result.error.decline_code) {
                                    this.errorMessage = `PAYMENT.ALERTS.${result.error.decline_code.toUpperCase()}`;
                                } else if (result.error.code) {
                                    this.errorMessage = `PAYMENT.ALERTS.${result.error.code.toUpperCase()}`;
                                } else {
                                    this.errorMessage = '';
                                }
                            }
                        }).catch((error) => {
                            console.error(error);
                            this.isLoading = false;
                        });
                    }, () => {
                        this.isLoading = false;
                    });
                }
            });
        });
    }

    resetForm() {
        this.form.get('cardElement').reset();
        this.form.get('amount').reset();
        this.errorMessage = '';
    }

    closeModal() {
        this.activeModal.close();
    }
}
