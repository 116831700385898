<div class="tracking-line-container">
    <div *ngFor="let node of nodes" class="icon-container">
        <div class="circle-container" pTooltip="{{node.name}}" tooltipPosition="top">
            <img src="../../assets/icons/{{node?.image}}" *ngIf="node?.image">
        </div>
        <div class="line-container">
            <div class="line">
            </div>
        </div>
    </div>
</div>