import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/modules/shared.module';

import {EditNotificationTemplateComponent} from './edit-notification-template/edit-notification-template.component';
import {NotificationsSettingsComponent} from './notifications-settings/notifications-settings.component';
import {CompanySmsComponent} from './company-sms/company-sms.component';
import {RouterModule} from '@angular/router';
import {CompanyNotificationsComponent} from './company-notifications.component';
import {SMSGatewaySettingsComponent} from './company-sms/sms-gateway-settings/sms-gateway-settings.component';
import {ToastModule} from 'primeng/toast';
@NgModule({
    declarations: [
        CompanyNotificationsComponent,
        NotificationsSettingsComponent,
        EditNotificationTemplateComponent,
        CompanySmsComponent,
        SMSGatewaySettingsComponent
    ],
    exports: [],
    imports: [
        SharedModule,
        
        RouterModule,
        ToastModule,
    ]
})
export class CompanyNotificationsModule {
}
