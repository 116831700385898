import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-report-confirmation',
  templateUrl: './report-confirmation.component.html',
  styleUrls: ['./report-confirmation.component.scss']
})
export class ReportConfirmationComponent implements OnInit {
  public printType = 'NONE';
  public printOptions = [];
  constructor(private activeModal: NgbActiveModal,
              private translateService: TranslateService) { }
  ngOnInit() {
    this.translateService.get([
        'GENERAL.WITHOUT_PRINT',
        'GENERAL.PRINT_ONCE',
        'GENERAL.PRINT_TWICE'
    ]).subscribe((translateValues) => {
      this.printOptions = [
        {
          label: translateValues['GENERAL.WITHOUT_PRINT'],
          value: 'NONE'
        },
        {
          label: translateValues['GENERAL.PRINT_ONCE'],
          value: 'ONE_COPY'
        },
        {
          label: translateValues['GENERAL.PRINT_TWICE'],
          value: 'TWO_COPIES'
        }
      ];
    });
  }
  public closeModal() {
    this.activeModal.close({printType: ''});
  }
  public choose() {
    this.activeModal.close({printType: this.printType});
  }
}
