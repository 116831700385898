<p-toast [baseZIndex]="toastZIndex"> </p-toast>
<ng-template #rowActions let-close="close">
    <div class="row-actions-container">
        <div class="row action" (click)="onModifyContainer({container: triggeredContainer})">
            <div class="icon">
                <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/edit.svg" alt="icon" />
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.EDIT_CONTAINER' | translate}}</div>
        </div>
        <div class="row action" (click)="print({container: triggeredContainer})">
            <div class="icon" *ngIf="!singlePrintLoader">
                <img src="../../assets/icons/containers-management/print.svg" alt="" />
            </div>
            <div class="loading" *ngIf="singlePrintLoader">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.PRINT_CONTAINER' | translate}}</div>
        </div>
        <div class="row action" (click)="getPackagesCount({container: triggeredContainer})">
            <div class="icon" *ngIf="!getPackagesCountLoader">
                <img src="../../assets/icons/containers-management/packages_number_black.svg" alt="" />
            </div>
            <div class="loading" *ngIf="getPackagesCountLoader">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.CONTAINERS_PACKAGES_COUNT' | translate}}</div>
        </div>
        <div class="row action remove-action" (click)="onRemoveContainer({container: triggeredContainer})">
            <div class="icon" *ngIf="!deleteContainerLoader">
                <img src="../../assets/icons/containers-management/remove_vechile.svg" alt="icon" />
            </div>
            <div class="loading" *ngIf="deleteContainerLoader">
                <i class="fas fa-spinner fa-pulse"></i>
            </div>
            <div class="row-action-hidden-space"></div>
            <div class="label">{{'CONTAINERS_MANAGEMENT.ACTIONS.REMOVE_CONTAINER' | translate}}</div>
        </div>
    </div>
</ng-template>
<div [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="containers-management-container" [@simpleFadeAnimation]="'in'">
        <div class="container-details-container overflow-auto">
            <app-container-details [isShow]="isShowContainerDetails"
                                   (onModifyContainer)="onModifyContainer($event)"
                                   (printContainer)="print($event)"
                                   (removeContainer) = "onRemoveContainer($event)"
                                   [printLoader]="singlePrintLoader"
                                   [container]="triggeredContainer" (onCloseContainerDetails)="onHideContainerDetails($event)" class="component"> </app-container-details>
        </div>
        <div class="d-flex top-side">
            <div class="d-inline-flex header">
                <div class="containers-management-title">{{'CONTAINERS_MANAGEMENT.CONTAINERS_MANAGEMENT_TITLE' | translate}}</div>
                <div class="header-hidden-space"></div>
                <div class="total-number-badge"><span>{{totalRecords}}</span></div>
            </div>
            <div *ngIf="!isSearchContent" class="flex-1 hidden-sm-down"></div>

            <div class="top-action"
                 [ngClass]="{'disabled-btn disabled': (!(getSelectedContainers().length ) && !selectedCount)}"
                 (click)="print()">
                <div *ngIf="!printLoader" class="print-icon">
                    <img src="../../assets/icons/containers-management/print.svg" alt="" />
                </div>
                <div class="loading" *ngIf="printLoader">
                    <i class="fas fa-spinner fa-pulse"></i>
                </div>
                <div class="label hidden-sm-down" >{{'CONTAINERS_MANAGEMENT.ACTIONS.PRINT' | translate}}</div>
            </div>
            <div class="print-hidden-space"></div>
            <div class="top-action" (click)="createNewContainer()">
                <div class="icon">
                    <img src="../../assets/icons/plus.svg" alt="" />
                </div>
                <div class="label" >{{'CONTAINERS_MANAGEMENT.ACTIONS.ADD_CONTAINER' | translate}}</div>
            </div>
            <div class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>
         <div class="show-mode-container hidden-sm-down">
             <div class="d-inline-flex show-mode">
                 <div class="top-side-label show-mode-label">{{'CONTAINERS_MANAGEMENT.VIEW_MODE' | translate}}</div>
                 <div class="show-mode-hidden-space"></div>
                 <div class="d-inline-flex show-mode-options">
                     <div class="table-mode mode" [ngClass]="{'active-view-mode': viewMode === 'TABLE_MODE'}" (click)="onSetShowMode('TABLE_MODE')">
                         <img *ngIf="viewMode === 'TABLE_MODE'" src="../../assets/icons/containers-management/list_selected.svg" alt="icon" />
                         <img *ngIf="viewMode === 'CARDS_MODE'" src="../../assets/icons/containers-management/list_unselected.svg" alt="icon" />
                     </div>
                     <div class="cards-mode mode" [ngClass]="{'active-view-mode': viewMode === 'CARDS_MODE'}" (click)="onSetShowMode('CARDS_MODE')">
                         <img *ngIf="viewMode === 'CARDS_MODE'" src="../../assets/icons/containers-management/grid_selected.svg" alt="icon" />
                         <img *ngIf="viewMode === 'TABLE_MODE'" src="../../assets/icons/containers-management/grid_unselected.svg" alt="icon" />
                     </div>
                     <div class="show-mode-splitter"></div>
                 </div>
             </div>
         </div>

            <div class="top-side-hidden-space"></div>
            <div *ngIf="!isSearchContent" class="d-inline-flex sort-by">
                <div class="top-side-label sort-by-label">{{'CONTAINERS_MANAGEMENT.ORDER_BY' | translate}}</div>
                <div class="sort-by-hidden-space"></div>
                <div>
                    <div class="sort-by-btn header-filter col-xs-6">
                        <p-dropdown styleClass="containers-sort-by-list" [style]="{'width':'90%'}" [(ngModel)]="orderBy" [options]="orderByList" filter="true" (onChange)="onSortByChanged()"> </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="splitter-container hidden-sm-down">
                <div class="split"></div>
            </div>
            <div class="search-toggle cursor-pointer">
                <div class="manage-shipments-search-filter search-filter-container d-flex">
                    <div class="d-flex">
                        <div class="search-filter-toggle" (click)="toggleIsSearchContent(true)">
                            <img style="width: 100%;" src="../../assets/icons/search.png" alt="search-Image" />
                            <!--                            <i class="fas fa-search" [ngClass]="{'fa-rotate-90': selectedLanguage==='ar'}"></i>-->
                        </div>
                        <div class="search-input"  *ngIf="isSearchContent">
                            <input type="text" placeholder="{{'GENERAL.SEARCH' | translate}}..." [(ngModel)]="searchContent" (input)="onSearch()" />
                        </div>
                    </div>
                    <div *ngIf="isSearchContent" class="hide-search-filter" (click)="toggleIsSearchContent(false)" >
                        <span>{{'ACTIONS.CANCEL' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-table containers-data-table" *ngIf="viewMode === 'TABLE_MODE'">
            <div class="top-action table-view-vehicle-type" #showVehicleTypesAction (click)="showVehicleTypes($event, vehicleTypesPanel)">
                <div class="vehicle-type-icon">
                    <img src="../../../assets/icons/containers-management/vehicle-type.svg" alt="">
                </div>
                {{ 'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.TITLE' | translate }}
                <i class="fas fa-caret-down"></i>
            </div>
            <div class="containers-body flex-1 overflow-auto" (scroll)="onScrollTable($event)">
                <p-table [value]="containers"
                         [responsive]="true"
                         scrollHeight="auto"
                         styleClass="containers-table data-p-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                <p-checkbox [(ngModel)]="isAllSelected" binary="true" (onChange)="onToggleSelectAll()"> </p-checkbox>
                            </th>
                            <th style="width: 140px">{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_BRAND' | translate}}</th>
                            <th>{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_MODEL' | translate}}</th>
                            <th>{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.WORK_ZONE' | translate}}</th>
                            <th>{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.PLATE_NO' | translate}}</th>
                            <th>{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.CONTAINER_BARCODE' | translate}}</th>
                            <th>{{'CONTAINERS_MANAGEMENT.TABLE_HEADERS.DRIVER_NAME' | translate}}</th>
                            <th>{{'CONTAINERS_MANAGEMENT.CARRIED_PACKAGES' | translate | packageTitle}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <div class="empty-content">
                            <app-no-data-available-component> </app-no-data-available-component>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body" let-container>
                        <tr class="shipment-row" [ngClass]="{'scanned-row':container.isSelected}" (click)="showContainerDetails(container)">
                            <td (click)="stopProp($event)">
                                <p-checkbox [(ngModel)]="container.isSelected" binary="true" (click)="stopProp($event)"> </p-checkbox>
                            </td>
                            <td>
                                <div class="d-inline-flex container-type">
                                    <div class="type-name td-text">{{container.name}}</div>
                                    <div class="container-type-hidden-space"></div>
                                    <div class="insurance-date">
                                        <i *ngIf="false" class="fas fa-exclamation-triangle"></i>
                                        <i style="margin-right: 40px" tooltipPosition="left" pTooltip=" 23  June, 2021 انتهى تآمين المركبة بتاريخ" *ngIf="true" class="fas fa-times-circle"></i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="td-text">{{container.brand}}</div>
                            </td>
                            <td>
                                <div class="td-text">{{container.workZone}}</div>
                            </td>
                            <td>{{container.plateNo}}</td>
                            <td>
                                <div class="d-inline-flex container-number">
                                    <div class="number">#{{container.barcode}}</div>
                                    <div class="container-number-hidden-space"></div>
                                    <div class="copy-icon cursor-pointer">
                                        <img src="../../assets/icons/track_package/copy.svg" alt="" (click)="copyMessage($event,null, 100919003663)" data-toggle="tooltip" data-placement="top" title="Copy" class="copy_icon" />
                                    </div>
                                </div>
                            </td>
                            <td>{{getDriverName(container.driver)}}</td>
                            <td (click)="stopProp($event)">
                                <div class="d-inline-flex number-of-packages-container-detail" (click)="openCarriedPackagesDialog(container.id)">
                                    <div class="number-of-packages-icon">
                                        <img class="margin-left-10 edit-button" src="../../assets/icons/containers-management/packages_number.svg" alt="icon" />
                                    </div>
                                </div>
                            </td>
                            <td (click)="stopProp($event)">
                                <div class="row-actions">
                                    <img
                                            (click)="setTriggeredContainer(container)"
                                            style="padding: 15px;"
                                            placement="{{currentLang === 'en' ? 'left': 'right'}}"
                                            autoClose="outside"
                                            #rowActionsPopOver="ngbPopover"
                                            [ngbPopover]="rowActions"
                                            src="../../assets/icons/containers-management/menu.svg"
                                            alt=""
                                    />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-skeleton-loader *ngIf="isLoading" [type]="1"></app-skeleton-loader>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="cards-view-mode-container" *ngIf="viewMode === 'CARDS_MODE'">
            <div class="row" style="height: 100%;position: relative;">
                <div class="containers-list-container">
                    <div class="select-all-toggle-row">
                        <div class="row select-all-container">
                            <div class="select-all-checkBox">
                                <p-checkbox id="select-all-checkbox"
                                            binary="true"
                                            [ngModel]="selectedCount == containers.length"
                                            (ngModelChange)="onToggleSelectAllContainersCards($event)">
                                </p-checkbox>
                            </div>
                            <div style="width: 20px"></div>
                            <div class="select-all-label">
                                <span>{{'GENERAL.SELECT_ALL' | translate}}</span>
                            </div>
                        </div>
                        <div class="top-action" #showVehicleTypesAction (click)="showVehicleTypes($event, vehicleTypesPanel)">
                            <div class="vehicle-type-icon">
                                <img src="../../../assets/icons/containers-management/vehicle-type.svg" alt="">
                            </div>
                            {{ 'CONTAINERS_MANAGEMENT.VEHICLE_TYPES.TITLE' | translate }}
                            <i class="fas fa-caret-down"></i>
                        </div>
                    </div>

                    <div  class="containers-list row" (scroll)="onScrollTable($event)">
                        <ng-container *ngIf="containers.length">
                            <app-container-item-component
                                    [selectedContainers]="selectedContainers"
                                    *ngFor="let container of containers" [container]="container"
                                    (onPrintContainer)="print($event)"
                                    (onClickCarriedPackages)="openCarriedPackagesDialog(container.id)"
                                    (onClickDetails)="showContainerDetails($event)"
                                    (onGetCount) = "getPackagesCount($event)"
                                    (onModifyContainer)="onModifyContainer($event)"
                                    (onRemoveContainer)="onRemoveContainer($event)"
                                    [removeCardLoader]="deleteContainerLoader"
                                    [getPackagesCountLoader]="getPackagesCountLoader"
                                    [singlePrintLoader]="singlePrintLoader"
                                    (onToggleSelectState)="onToggleChildSelect($event)">
                            </app-container-item-component>
                        </ng-container>

                        <ng-container *ngIf="isLoading">
                            <app-skeleton-loader *ngFor="let loader of [0, 1, 2, 3, 4, 5, 6, 7, 8]" [isSingleLoader]="true" [type]="0"></app-skeleton-loader>
                        </ng-container>
                        <ng-container *ngIf="!containers.length && !isLoading">
                            <app-no-data-available-component></app-no-data-available-component>

                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--</div>-->
<!--<div class="vehicle-types-panel">-->
    <p-overlayPanel [style]="{'max-width': '100%'}" class="vehicle-types-panel" #vehicleTypesPanel appendTo="body">
        <app-vehicle-types></app-vehicle-types>
    </p-overlayPanel>
<!--</div>-->

