import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import * as Compressor from 'compressorjs';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {

    @Input()
    multiple = true;

    @Input()
    compressed = true;

    @Input()
    acceptType = 'image/*';

    @Input()
    maxFileSizeBytes = 10000000;

    @Input()
    disabled = false;

    @Input()
    maxFiles = 5;

    @Input()
    loading = false;

    @Input()
    isLcl = false;

    @ViewChild('uploader')
    uploader: FileUpload;

    compressedFiles: (File | Blob)[] = [];

    private compressionQuality = 0.3;
    private previousFilesNumber = 0;
    uploadProgress = 0;
    uploadProgressOb = new BehaviorSubject(0);

    constructor(private translateService: TranslateService,
                private changeDetectionRef: ChangeDetectorRef,
                private messageService: MessageService) {
    }

    public get files() {
        if (this.compressed) {
            return this.compressedFiles;
        }
        return this.uploader.files;
    }

    public clearUploader() {
        this.uploader.clear();
    }

    onSelectImages(event) {
        this.loading = true;
        if (this.uploader.files.length > this.maxFiles) {
            this.translateService.get('ALERTS.UPLOAD_MAX_NUMBER', {max: this.maxFiles}).subscribe(transValue => {
                this.messageService.add({
                    severity: 'error',
                    detail: transValue
                });
            });
            this.uploader.files.splice(this.previousFilesNumber);
        }
        this.uploadProgress = 0;
        this.updateUploaderProgressOb();

        if (this.compressed && this.uploader.files.length) {
            this.compressedFiles = [];
            const compFiles = this.compressedFiles;
            const filesLength = this.uploader.files.length;
            const updateProgress = () => {
                this.uploadProgress += Math.round((1 / this.uploader.files.length) * 100);
                this.updateUploaderProgressOb();
            };
            const disableLoading = () => {
                this.uploadProgress = 100;
                this.loading = false;
                this.updateUploaderProgressOb();

            };
            this.uploader.files.forEach(file => {
                const comp = new Compressor.default(file, {
                    quality: this.compressionQuality,
                    success(result) {
                        compFiles.push(result);
                        updateProgress();
                        if (compFiles.length === filesLength) {
                            disableLoading();
                        }
                    }
                });
            });
        } else {
            this.loading = false;
        }

        this.previousFilesNumber = this.uploader.files.length;
    }

    onClear() {
        this.compressedFiles = [];
        this.previousFilesNumber = 0;
        this.uploadProgress = 0;
        this.updateUploaderProgressOb();
    }

    onRemoveImage(event: any) {
        const index = this.uploader.files.indexOf(event.file);
        this.compressedFiles.splice(index, 1);
        this.previousFilesNumber--;
        if (this.previousFilesNumber === 0) {
            this.uploadProgress = 0;
            this.updateUploaderProgressOb();
        }
    }

    private updateUploaderProgressOb() {
        this.changeDetectionRef.detectChanges();
    }
}
