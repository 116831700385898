<div class="drivers-container" style="height: 560px!important;">
    <div class="row justify-content-center">
        <div class="column driver-list-header">
            <div class="driver-label">{{'DASHBOARD.ALL_DRIVERS' | translate}}</div>
        </div>
    </div>
    <div class="row justify-content-center mb-2">
        <div class="attachments-filter">
            <div class="values">
                <div class="item first-item"
                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITH_PACKAGES','b-radius-right': currentLang === 'ar' , 'b-radius-left': currentLang === 'en'}"
                     (click)="driversType('WITH_PACKAGES')">{{'DASHBOARD.DRIVERS_STATUS_LIST.WITH_PACKAGES' | translate}}</div>
                <div class="item last-item"
                     [ngClass]="{'active-item': selectedAttachmentsFilter === 'WITHOUT_PACKAGES','b-radius-left': currentLang === 'ar' , 'b-radius-right': currentLang === 'en'}"
                     (click)="driversType('WITHOUT_PACKAGES')">{{'DASHBOARD.DRIVERS_STATUS_LIST.WITHOUT_PACKAGES' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <input #searchDrivers (input)="onSearchDriversList(searchDrivers.value)" class="search" placeholder="{{'GENERAL.SEARCH' | translate}}..." type="text">
    </div>
    <div *ngIf="isLoadingDriversList" class="drivers-data" (scroll)="onScroll($event)">
        <div style="background-color: #D0E7EE" class="col-12 text-center dashboard-loader">
            <span class="loader drivers-list-loader"><i class="fas fa-spinner fa-spin"></i></span>
        </div>
    </div>
    <div class="drivers-data" (scroll)="onScroll($event)">
        <ng-container *ngIf="selectedAttachmentsFilter === 'WITH_PACKAGES'">
            <div *ngFor="let driver of driversListWithPackages" class="row drivers-list">
                <div class="col1">
                    <img style="width: 100%;" class="driver-icon" src="../../assets/icons/taxi-driver.png" alt="driver-Image"/>
                </div>
                <div class="name-and-carried-packages-container d-inline-flex">
                    <div class="col2">
                        <div class="row driver-name">{{driver.name}}</div>
                        <div tooltipPosition="top"
                             pTooltip="{{'ALERTS.PLEASE_WAIT'| translate}}"
                             [tooltipDisabled]="!isLoading"
                             *ngIf="!printPackagesLoaders[driver.id]"
                             [ngClass]="{'disabled': isLoading}"

                             (click)="PrintPdfExcel(driver.id)"
                             class="row justify-content-center cursor-pointer show-driver-details"><span>{{'MANAGE_SHIPMENTS.DRIVER_PACKAGES' | translate | packageTitle}}</span></div>
                        <div *ngIf="printPackagesLoaders[driver.id]" class="row justify-content-center">
                            <div class="col-12 text-center">
                                <span class="loader"><i class="fas fa-spinner fa-pulse"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col2">
                        <div class="driver-stats-container">
                            <div class="row driver-carried-packages justify-content-end">
                                <div class="packages-count">{{driver.countOfCarriedPackages}}</div>
                                <div class="package-label">{{'DASHBOARD.PACKAGE' | translate | packageTitle}}</div>
                            </div>
                            <div *ngIf="driver.deliveryRate >= 0" class="driver-delivery-rate cursor-pointer flex-2d-center"
                                 pTooltip="{{ 'DASHBOARD.DRIVER_DELIVERY_RATE_TOOLTIP' | translate }}"
                                 tooltipPosition="top">
                                <img class="driver-icon" src="../../assets/icons/delivery-rate.png"/>
                                <div class="ratio-value flex-2d-center">
                                    <span>{{(driver.deliveryRate).toFixed(1)}}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>
        <ng-container *ngIf="selectedAttachmentsFilter === 'WITHOUT_PACKAGES'">
            <div *ngFor="let driver of AllDriversList" class="row drivers-list">
                <div class="col1">
                    <img style="width: 100%;" class="driver-icon" src="../../assets/icons/taxi-driver.png" alt="driver-Image"/>
                </div>
                <div class="align-items-center name-and-carried-packages-container d-inline-flex">
                    <div class="col2">
                        <div class="row driver-name">{{driver.name}}</div>
                    </div>
                </div>

            </div>
        </ng-container>
    </div>
</div>
