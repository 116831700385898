import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {PackageContentModel} from '../../distributor-package-form/models/package-content.model';
import {TranslateService} from '@ngx-translate/core';
import {PackageContentsService} from '../../administration/services/package-contents.service';
import {of} from 'rxjs';

@Component({
    selector: 'app-package-content-last-mile',
    templateUrl: './package-content-last-mile.component.html',
    styleUrls: ['./package-content-last-mile.component.scss']
})
export class PackageContentLastMileComponent implements OnInit, OnChanges {
    currentLang = '';
    @Input() currentPackageContents: PackageContentModel[] = [];
    currentPackageIds: number[] = [];
    @Output() selectedContents: EventEmitter<{ contents: PackageContentModel[] }> = new EventEmitter<{ contents: PackageContentModel[] }>();

    packageContents: any[] = [];
    @Input() showAvailable = true;
    @ViewChild('myForm') form;

    constructor(private translateService: TranslateService,
                private packageContentsService: PackageContentsService) {
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
        this.currentPackageIds = this.currentPackageContents.map(content => content.id);
        this.initPackageContents();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
    }

    initPackageContents() {
        of(this.currentPackageContents?.length? this.currentPackageContents : []).subscribe(
            (contents: any) => {
                this.packageContents = contents;
            }
        );
    }

    addPackageContent() {
        this.packageContents.push(
            {
                name: '',
                cod: ''
            }
        );
    }
    deleteItem(index, content: any) {
        this.packageContents.splice(index, 1);
    }

    markAllAsTouched() {
        Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].markAsTouched();
        });
    }
}
