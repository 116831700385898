<div class="enter-barcode-container" [ngClass]="{'page-ltr': currentLang === 'en'}">
    <div class="title-container row">
        <div class="title d-flex" style="position: relative; padding: 0;">
            <div class="driver-icon">
                <img src="../../assets/icons/manage_shipments/assign-driver.png" alt="" />
            </div>
            <div class="label">
                <span>{{(isAddToContainer ? 'LCL.ENTER_CONTAINER_BARCODE' : 'ACCOUNTING.ENTER_BARCODE') | translate }}</span>
            </div>
        </div>
        <div class="close-icon cursor-pointer">
            <img (click)="closeModal()" src="../../assets/icons/cancel.svg" alt="" />
        </div>
    </div>
    <div class="main-spinner-container" *ngIf="isLoading">
        <div class="spinner-stage">
            <div class="dot-flashing"></div>
        </div>
    </div>
    <form (keydown.enter)="scanBarcode()" [formGroup]="form" *ngIf="form">
        <div class="input-fields row">
            <div class="row row-input">
                <div class="col-12 driver-input">
                    <div class="input-label">{{(isBin ? 'FULFILMENT.ITEM.ITEM_BARCODE': (isAddToContainer ? 'LCL.CONTAINER_BARCODE' : 'ACCOUNTING.PACKAGE_BARCODE'))  | translate}}</div>
                    <div class="input-value">
                        <app-input-field-component class="col-12" [form]="form" controlName="barcode" [isRequired]="true" controlType="text"> </app-input-field-component >
                    </div>
                </div>
            </div>
        </div>
        <div class="row bottom-actions d-flex">
            <div class="cancel-btn" (click)="closeModal()">
                <span>{{"ACTIONS.CANCEL" | translate}}</span>
            </div>
            <div class="done-btn add-button d-flex cursor-pointer" style="" (click)="scanBarcode()" [ngClass]="{'disabled-btn disabled': !form.valid || isLoading}">
                <div class="label done-btn-label">{{'ACTIONS.SCAN' | translate }}</div>
            </div>
        </div>
    </form>
</div>
