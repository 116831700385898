<div class="h-100  single-report-page">
    <div class="header-title">{{'ADMINISTRATION.DRIVER_REPORTS.TITLE' | translate}}</div>
    <p-tabView>
        <p-tabPanel *ngFor="let driverReport of driverReports; let i = index" [cache]="false"
                    [header]="'ADMINISTRATION.DRIVER_REPORTS.'+driverReport.HEADER|translate | packageTitle"
                    [selected]="i === 0">
            <ng-template pTemplate="content">
                <app-admin-report [columns]="driverReport.COLUMNS" [hasFilters]="true" [apiUrl]="reportAPI"
                                  [reportTitle]="'ADMINISTRATION.DRIVER_REPORTS.'+driverReport.HEADER"
                                  [TYPE] = driverReport.HEADER
                                  [isExportToExcel]="true" [statics]="driverReport.STATISTICS"
                                  [APIStatistics]="driverReport.API_STATISTICS"
                                  [queryParams]="{'type':driverReport.ENUM_VALUE, 'driverType': 'TYPICAL'}" [filterDrivers]="true">
                </app-admin-report>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>
