<div class="reply-container"  [ngClass]="{'send-by-me' : isMe}">
  <div class="email-info">
    <div class="from-container">
      <div class="from">
        <b class="from-label">{{'GENERAL.FROM' | translate}}:</b>{{emailReply?.senderName}}
      </div>
    </div>

    <div class="sent-date-container">
      <div class="date">
        {{emailReply?.createdDate | date:'EEEE d, MMM hh:mm'}}
      </div>
    </div>
  </div>
  <div class="body-container">
    <div class="body">
      {{emailReply?.body}}
    </div>
  </div>
</div>
